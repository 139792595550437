import { FGEmpty, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { EntiteCentreApi, FcbEntitecentreDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterCheckboxInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

interface ICentreGlobalDetailPage {
  idCentreGlobal: number;
  editMode: boolean;
}

export const CentreGlobalDetailPage: React.FunctionComponent<ICentreGlobalDetailPage> = ({
  idCentreGlobal,
  editMode
}) => {
  const { t } = useTl();
  const api = useApiService(EntiteCentreApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idCentreGlobal <= 0
        ? FcbEntitecentreDtoFromJSON({ idProjetFinancement: 0 })
        : api.entiteCentreGet({ id: +idCentreGlobal }),
    saveApiFn: d => api.entiteCentreSave({ FcbEntitecentreDto: d }),
    onSavedRoute: d => `${ERoutes.centreGlobal}/${d.identite}/detail/edit`,
    deleteApiFn: d => api.entiteCentreDelete({ id: d.identite }),
    onDeletedRoute: () => ERoutes.centreGlobal
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      nom: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={editMode}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(ERoutes.centreGlobal)}
        onDelete={deleteItem}
        showDeleteButton={+idCentreGlobal > 0}
        saving={saving}
        deleting={deleting}
      >
        <FieldGroup columns={2}>
          <FGTextInput name="nom" label={t(ETLCodes.Nom)} maxLength={50} />
          <FGEmpty />
          <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
