/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeStatutMultipleDto
 */
export interface ChangeStatutMultipleDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof ChangeStatutMultipleDto
     */
    demandes?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeStatutMultipleDto
     */
    codeStatut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeStatutMultipleDto
     */
    remarqueRefus?: string | null;
}

/**
 * Check if a given object implements the ChangeStatutMultipleDto interface.
 */
export function instanceOfChangeStatutMultipleDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChangeStatutMultipleDtoFromJSON(json: any): ChangeStatutMultipleDto {
    return ChangeStatutMultipleDtoFromJSONTyped(json, false);
}

export function ChangeStatutMultipleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeStatutMultipleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'demandes': !exists(json, 'demandes') ? undefined : json['demandes'],
        'codeStatut': !exists(json, 'codeStatut') ? undefined : json['codeStatut'],
        'remarqueRefus': !exists(json, 'remarqueRefus') ? undefined : json['remarqueRefus'],
    };
}

export function ChangeStatutMultipleDtoToJSON(value?: ChangeStatutMultipleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'demandes': value.demandes,
        'codeStatut': value.codeStatut,
        'remarqueRefus': value.remarqueRefus,
    };
}

