import { Dialog } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";
import { ISiegeSocialSelectorProps, SiegeSocialSelector } from ".";
import { useTl } from "../../../hooks";
import { ETLCodes } from "../../../locales";

const StyledDialog = styled(Dialog)`
  width: 1100px;
  height: auto;
  background-color: white;
`;

export interface ISiegeSocialSelectorDialogProps extends ISiegeSocialSelectorProps {
  onClosed: (selectedSiegeSocialIds?: number[]) => void;
  selectorDialogOpen: boolean;
  canAdd?: boolean;
}

export const SiegeSocialSelectorDialog: React.FunctionComponent<ISiegeSocialSelectorDialogProps> = ({
  onClosed,
  selectorDialogOpen,
  ...otherProps
}) => {
  const { t } = useTl();

  return (
    <StyledDialog
      title={t(ETLCodes.SearchSiegeSocials)}
      isOpen={selectorDialogOpen}
      enforceFocus={false}
      onClose={() => onClosed(null)}
    >
      <SiegeSocialSelector {...otherProps} />
    </StyledDialog>
  );
};
