import * as React from "react";
import { useTl, useApiService } from "../../../../../hooks";
import { useHistory, useParams } from "react-router";
import { useApiEffect, IDataTableColumn } from "nsitools-react";
import { ETLCodes } from "../../../../../locales";
import { WSelector } from "../../../../../components/selector/WSelector";
import { ERoutes } from "../../../../../AppRouter";
import { CodePostalApi, ServiceTutelleLocaliteApi } from "../../../../../api";
import { useDialog } from "../../../../../contexts";

export interface IServiceTutelleLocaliteLieeSelectorProps {}

export const ServiceTutelleLocaliteLieeSelector: React.FunctionComponent<IServiceTutelleLocaliteLieeSelectorProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const cpApi = useApiService(CodePostalApi);
  const stApi = useApiService(ServiceTutelleLocaliteApi);
  const { id } = useParams<{ id: string }>();
  const dialogContext = useDialog();

  const [data, loading] = useApiEffect(() =>
    stApi.serviceTutelleLocaliteGetCodePostalServiceTutelle({ idServiceTutelle: +id })
  );

  const columns = React.useMemo<IDataTableColumn[]>(() => {
    return [
      {
        fieldName: "codePostal",
        header: () => t(ETLCodes.CodePostal)
      },
      {
        fieldName: "commune",
        header: () => t(ETLCodes.Commune)
      },
      {
        fieldName: "localite",
        header: () => t(ETLCodes.Localite)
      }
    ];
  }, [t]);

  return (
    !loading && (
      <WSelector
        linkedIds={data.idcodePostaux}
        columns={columns}
        idField="idcodePostal"
        tlDataPrefix="CodePostalSearchCriterias"
        title={t(ETLCodes.LocaliteLiee)}
        searchIdsFunc={sObj => cpApi.codePostalSearchCodePostalIds({ CodePostalSearch: sObj })}
        getCriteriasFunction={() => cpApi.codePostalGetSearchCriterias({ includeListsValues: true })}
        searchFunction={sObj => cpApi.codePostalBaseSearch({ CodePostalSearch: sObj })}
        onSave={async newIds => {
          await stApi.serviceTutelleLocaliteSave({
            FcbServiceTutelleCodePostalDto: { idserviceTutelle: +id, idcodePostaux: newIds }
          });

          history.push(`${ERoutes.serviceTutelle}/${id}/Localites`);
        }}
        onCancel={() => history.push(`${ERoutes.serviceTutelle}/${id}/Localites`)}
        dialogContext={dialogContext}
        sortKeys={{ codePostal: "ASC" }}
      />
    )
  );
};
