import { Tab, Tabs } from "@blueprintjs/core";
import * as React from "react";
import { DoublonFormateurAdministratif, DoublonFormateurProfessionnel, DoublonFormateurSignaletique } from ".";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IDoublonFormateurPageProps {}

export const DoublonFormateurPage: React.FunctionComponent<IDoublonFormateurPageProps> = props => {
  const { t } = useTl();
  const [tab, setTab] = React.useState("signaletique");

  return (
    <Tabs
      id="TabsDoublonFormateur"
      onChange={newTabId => setTab(newTabId + "")}
      selectedTabId={tab}
      renderActiveTabPanelOnly
      vertical
    >
      <Tab id="signaletique" title={t(ETLCodes.Signaletique)} panel={<DoublonFormateurSignaletique />} />
      <Tab id="administratif" title={t(ETLCodes.Administratif)} panel={<DoublonFormateurAdministratif />} />
      <Tab id="professionnel" title={t(ETLCodes.ActivitesProfessionelles)} panel={<DoublonFormateurProfessionnel />} />
    </Tabs>
  );
};
