/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParcoursFormationEditDto
 */
export interface ParcoursFormationEditDto {
    /**
     * 
     * @type {number}
     * @memberof ParcoursFormationEditDto
     */
    idApprenant?: number;
    /**
     * 
     * @type {Date}
     * @memberof ParcoursFormationEditDto
     */
    dateEntree?: Date;
    /**
     * 
     * @type {number}
     * @memberof ParcoursFormationEditDto
     */
    idCategorieStatut?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParcoursFormationEditDto
     */
    idStatut?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParcoursFormationEditDto
     */
    iddureeInoccupation?: number | null;
}

/**
 * Check if a given object implements the ParcoursFormationEditDto interface.
 */
export function instanceOfParcoursFormationEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ParcoursFormationEditDtoFromJSON(json: any): ParcoursFormationEditDto {
    return ParcoursFormationEditDtoFromJSONTyped(json, false);
}

export function ParcoursFormationEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParcoursFormationEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idApprenant': !exists(json, 'idApprenant') ? undefined : json['idApprenant'],
        'dateEntree': !exists(json, 'dateEntree') ? undefined : (new Date(json['dateEntree'])),
        'idCategorieStatut': !exists(json, 'idCategorieStatut') ? undefined : json['idCategorieStatut'],
        'idStatut': !exists(json, 'idStatut') ? undefined : json['idStatut'],
        'iddureeInoccupation': !exists(json, 'iddureeInoccupation') ? undefined : json['iddureeInoccupation'],
    };
}

export function ParcoursFormationEditDtoToJSON(value?: ParcoursFormationEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idApprenant': value.idApprenant,
        'dateEntree': value.dateEntree === undefined ? undefined : (value.dateEntree.toISOString()),
        'idCategorieStatut': value.idCategorieStatut,
        'idStatut': value.idStatut,
        'iddureeInoccupation': value.iddureeInoccupation,
    };
}

