/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IAggregateResult } from './IAggregateResult';
import {
    IAggregateResultFromJSON,
    IAggregateResultFromJSONTyped,
    IAggregateResultToJSON,
} from './IAggregateResult';
import type { OffreDemandeGridDto } from './OffreDemandeGridDto';
import {
    OffreDemandeGridDtoFromJSON,
    OffreDemandeGridDtoFromJSONTyped,
    OffreDemandeGridDtoToJSON,
} from './OffreDemandeGridDto';

/**
 * 
 * @export
 * @interface OffreDemandeGridDtoPaginatedResults
 */
export interface OffreDemandeGridDtoPaginatedResults {
    /**
     * 
     * @type {number}
     * @memberof OffreDemandeGridDtoPaginatedResults
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<OffreDemandeGridDto>}
     * @memberof OffreDemandeGridDtoPaginatedResults
     */
    results?: Array<OffreDemandeGridDto> | null;
    /**
     * 
     * @type {Array<IAggregateResult>}
     * @memberof OffreDemandeGridDtoPaginatedResults
     */
    aggregateResults?: Array<IAggregateResult> | null;
}

/**
 * Check if a given object implements the OffreDemandeGridDtoPaginatedResults interface.
 */
export function instanceOfOffreDemandeGridDtoPaginatedResults(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OffreDemandeGridDtoPaginatedResultsFromJSON(json: any): OffreDemandeGridDtoPaginatedResults {
    return OffreDemandeGridDtoPaginatedResultsFromJSONTyped(json, false);
}

export function OffreDemandeGridDtoPaginatedResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OffreDemandeGridDtoPaginatedResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'results': !exists(json, 'results') ? undefined : (json['results'] === null ? null : (json['results'] as Array<any>).map(OffreDemandeGridDtoFromJSON)),
        'aggregateResults': !exists(json, 'aggregateResults') ? undefined : (json['aggregateResults'] === null ? null : (json['aggregateResults'] as Array<any>).map(IAggregateResultFromJSON)),
    };
}

export function OffreDemandeGridDtoPaginatedResultsToJSON(value?: OffreDemandeGridDtoPaginatedResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'results': value.results === undefined ? undefined : (value.results === null ? null : (value.results as Array<any>).map(OffreDemandeGridDtoToJSON)),
        'aggregateResults': value.aggregateResults === undefined ? undefined : (value.aggregateResults === null ? null : (value.aggregateResults as Array<any>).map(IAggregateResultToJSON)),
    };
}

