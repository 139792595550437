import { Tab, Tabs } from "@blueprintjs/core";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { InscriptionSuspensionSwitch } from "..";
import { ERoutes } from "../../../../../AppRouter";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { ChangementClasseSwitch } from "./changementClasse/ChangementClasseSwitch";
import { DispenseSwitch } from "./dispense/DispenseSwitch";
import { Inscription } from "./Inscription";

export interface IApprenantInscriptionDetailProps {}

export const ApprenantInscriptionDetail: React.FunctionComponent<IApprenantInscriptionDetailProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id, inscriptionId, tabInscription, stateInscription = "view" } = useParams<{
    id: string;
    inscriptionId: string;
    tabInscription: string;
    stateInscription: string;
  }>();
  const idApprenant = React.useMemo(() => +id, [id]);
  const idInscription = React.useMemo(() => +inscriptionId, [inscriptionId]);

  return (
    <Tabs
      id="TabsApprenant"
      onChange={newTabId => {
        if (newTabId === "detail") {
          history.push(
            `${ERoutes.apprenant}/${idApprenant}/inscription/${idInscription}/${newTabId}/${
              stateInscription !== "0" ? stateInscription : "view"
            }`
          );
        } else {
          history.push(`${ERoutes.apprenant}/${idApprenant}/inscription/${idInscription}/${newTabId}`);
        }
      }}
      selectedTabId={tabInscription}
      renderActiveTabPanelOnly
      vertical
    >
      <Tab id="detail" title={t(ETLCodes.Detail)} panel={<Inscription />} panelClassName="full-width-panel" />
      <Tab
        id="dispense"
        title={t(ETLCodes.DispensesMatieres)}
        panel={<DispenseSwitch />}
        panelClassName="full-width-panel"
        disabled={+inscriptionId <= 0}
      />
      <Tab
        id="changement"
        title={t(ETLCodes.ChangementClasse)}
        panel={<ChangementClasseSwitch />}
        panelClassName="full-width-panel"
        disabled={+inscriptionId <= 0}
      />
      <Tab
        id="suspension"
        title={t(ETLCodes.Suspensions)}
        panel={<InscriptionSuspensionSwitch />}
        panelClassName="full-width-panel"
        disabled={+inscriptionId <= 0}
      />
    </Tabs>
  );
};
