/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FcbRechercheClasseHoraireRemplacementSimultaneDto } from './FcbRechercheClasseHoraireRemplacementSimultaneDto';
import {
    FcbRechercheClasseHoraireRemplacementSimultaneDtoFromJSON,
    FcbRechercheClasseHoraireRemplacementSimultaneDtoFromJSONTyped,
    FcbRechercheClasseHoraireRemplacementSimultaneDtoToJSON,
} from './FcbRechercheClasseHoraireRemplacementSimultaneDto';
import type { IAggregateResult } from './IAggregateResult';
import {
    IAggregateResultFromJSON,
    IAggregateResultFromJSONTyped,
    IAggregateResultToJSON,
} from './IAggregateResult';

/**
 * 
 * @export
 * @interface FcbRechercheClasseHoraireRemplacementSimultaneDtoPaginatedResults
 */
export interface FcbRechercheClasseHoraireRemplacementSimultaneDtoPaginatedResults {
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseHoraireRemplacementSimultaneDtoPaginatedResults
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<FcbRechercheClasseHoraireRemplacementSimultaneDto>}
     * @memberof FcbRechercheClasseHoraireRemplacementSimultaneDtoPaginatedResults
     */
    results?: Array<FcbRechercheClasseHoraireRemplacementSimultaneDto> | null;
    /**
     * 
     * @type {Array<IAggregateResult>}
     * @memberof FcbRechercheClasseHoraireRemplacementSimultaneDtoPaginatedResults
     */
    aggregateResults?: Array<IAggregateResult> | null;
}

/**
 * Check if a given object implements the FcbRechercheClasseHoraireRemplacementSimultaneDtoPaginatedResults interface.
 */
export function instanceOfFcbRechercheClasseHoraireRemplacementSimultaneDtoPaginatedResults(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRechercheClasseHoraireRemplacementSimultaneDtoPaginatedResultsFromJSON(json: any): FcbRechercheClasseHoraireRemplacementSimultaneDtoPaginatedResults {
    return FcbRechercheClasseHoraireRemplacementSimultaneDtoPaginatedResultsFromJSONTyped(json, false);
}

export function FcbRechercheClasseHoraireRemplacementSimultaneDtoPaginatedResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRechercheClasseHoraireRemplacementSimultaneDtoPaginatedResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'results': !exists(json, 'results') ? undefined : (json['results'] === null ? null : (json['results'] as Array<any>).map(FcbRechercheClasseHoraireRemplacementSimultaneDtoFromJSON)),
        'aggregateResults': !exists(json, 'aggregateResults') ? undefined : (json['aggregateResults'] === null ? null : (json['aggregateResults'] as Array<any>).map(IAggregateResultFromJSON)),
    };
}

export function FcbRechercheClasseHoraireRemplacementSimultaneDtoPaginatedResultsToJSON(value?: FcbRechercheClasseHoraireRemplacementSimultaneDtoPaginatedResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'results': value.results === undefined ? undefined : (value.results === null ? null : (value.results as Array<any>).map(FcbRechercheClasseHoraireRemplacementSimultaneDtoToJSON)),
        'aggregateResults': value.aggregateResults === undefined ? undefined : (value.aggregateResults === null ? null : (value.aggregateResults as Array<any>).map(IAggregateResultToJSON)),
    };
}

