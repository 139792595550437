/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbPlanLocalisationDto,
  FcbRecherchePlanLocalisationClasseDtoPaginatedResults,
  FcbRecherchePlanLocalisationDtoPaginatedResults,
  FilterCriteriaInfo,
  PlanLocalisationClasseSearch,
  PlanLocalisationSearch,
  SelectItem,
} from '../models/index';
import {
    FcbPlanLocalisationDtoFromJSON,
    FcbPlanLocalisationDtoToJSON,
    FcbRecherchePlanLocalisationClasseDtoPaginatedResultsFromJSON,
    FcbRecherchePlanLocalisationClasseDtoPaginatedResultsToJSON,
    FcbRecherchePlanLocalisationDtoPaginatedResultsFromJSON,
    FcbRecherchePlanLocalisationDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    PlanLocalisationClasseSearchFromJSON,
    PlanLocalisationClasseSearchToJSON,
    PlanLocalisationSearchFromJSON,
    PlanLocalisationSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface PlanLocalisationBaseSearchRequest {
    PlanLocalisationSearch?: PlanLocalisationSearch;
}

export interface PlanLocalisationDeleteRequest {
    id?: number;
}

export interface PlanLocalisationGetRequest {
    id?: number;
}

export interface PlanLocalisationGetDisplayNameRequest {
    id?: number;
}

export interface PlanLocalisationGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface PlanLocalisationGetSelectItemsRequest {
    searchField: string;
}

export interface PlanLocalisationLockClasseRequest {
    idPlan?: number;
}

export interface PlanLocalisationSaveRequest {
    FcbPlanLocalisationDto?: FcbPlanLocalisationDto;
}

export interface PlanLocalisationSearchClassesRequest {
    PlanLocalisationClasseSearch?: PlanLocalisationClasseSearch;
}

export interface PlanLocalisationToValidatePlanRequest {
    idPlan?: number;
}

export interface PlanLocalisationUnlockClasseRequest {
    idPlan?: number;
}

export interface PlanLocalisationValidatePlanRequest {
    idPlan?: number;
}

/**
 * 
 */
export class PlanLocalisationApi extends runtime.BaseAPI {

    /**
     */
    async planLocalisationBaseSearchRaw(requestParameters: PlanLocalisationBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRecherchePlanLocalisationDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PlanLocalisation/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlanLocalisationSearchToJSON(requestParameters.PlanLocalisationSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRecherchePlanLocalisationDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async planLocalisationBaseSearch(requestParameters: PlanLocalisationBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRecherchePlanLocalisationDtoPaginatedResults> {
        const response = await this.planLocalisationBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async planLocalisationDeleteRaw(requestParameters: PlanLocalisationDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PlanLocalisation`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async planLocalisationDelete(requestParameters: PlanLocalisationDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.planLocalisationDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async planLocalisationGetRaw(requestParameters: PlanLocalisationGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbPlanLocalisationDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PlanLocalisation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbPlanLocalisationDtoFromJSON(jsonValue));
    }

    /**
     */
    async planLocalisationGet(requestParameters: PlanLocalisationGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbPlanLocalisationDto> {
        const response = await this.planLocalisationGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async planLocalisationGetDisplayNameRaw(requestParameters: PlanLocalisationGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PlanLocalisation/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async planLocalisationGetDisplayName(requestParameters: PlanLocalisationGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.planLocalisationGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async planLocalisationGetSearchCriteriasRaw(requestParameters: PlanLocalisationGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PlanLocalisation/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async planLocalisationGetSearchCriterias(requestParameters: PlanLocalisationGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.planLocalisationGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async planLocalisationGetSelectItemsRaw(requestParameters: PlanLocalisationGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling planLocalisationGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PlanLocalisation/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async planLocalisationGetSelectItems(requestParameters: PlanLocalisationGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.planLocalisationGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async planLocalisationLockClasseRaw(requestParameters: PlanLocalisationLockClasseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.idPlan !== undefined) {
            queryParameters['idPlan'] = requestParameters.idPlan;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PlanLocalisation/LockClasse`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async planLocalisationLockClasse(requestParameters: PlanLocalisationLockClasseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.planLocalisationLockClasseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async planLocalisationSaveRaw(requestParameters: PlanLocalisationSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbPlanLocalisationDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PlanLocalisation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbPlanLocalisationDtoToJSON(requestParameters.FcbPlanLocalisationDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbPlanLocalisationDtoFromJSON(jsonValue));
    }

    /**
     */
    async planLocalisationSave(requestParameters: PlanLocalisationSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbPlanLocalisationDto> {
        const response = await this.planLocalisationSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async planLocalisationSearchClassesRaw(requestParameters: PlanLocalisationSearchClassesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRecherchePlanLocalisationClasseDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PlanLocalisation/SearchClasses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlanLocalisationClasseSearchToJSON(requestParameters.PlanLocalisationClasseSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRecherchePlanLocalisationClasseDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async planLocalisationSearchClasses(requestParameters: PlanLocalisationSearchClassesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRecherchePlanLocalisationClasseDtoPaginatedResults> {
        const response = await this.planLocalisationSearchClassesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async planLocalisationToValidatePlanRaw(requestParameters: PlanLocalisationToValidatePlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.idPlan !== undefined) {
            queryParameters['idPlan'] = requestParameters.idPlan;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PlanLocalisation/ToValidatePlan`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async planLocalisationToValidatePlan(requestParameters: PlanLocalisationToValidatePlanRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.planLocalisationToValidatePlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async planLocalisationUnlockClasseRaw(requestParameters: PlanLocalisationUnlockClasseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.idPlan !== undefined) {
            queryParameters['idPlan'] = requestParameters.idPlan;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PlanLocalisation/UnlockClasse`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async planLocalisationUnlockClasse(requestParameters: PlanLocalisationUnlockClasseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.planLocalisationUnlockClasseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async planLocalisationValidatePlanRaw(requestParameters: PlanLocalisationValidatePlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.idPlan !== undefined) {
            queryParameters['idPlan'] = requestParameters.idPlan;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PlanLocalisation/ValidatePlan`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async planLocalisationValidatePlan(requestParameters: PlanLocalisationValidatePlanRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.planLocalisationValidatePlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
