/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRechercheRemplacementDto
 */
export interface FcbRechercheRemplacementDto {
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheRemplacementDto
     */
    idremplacement?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheRemplacementDto
     */
    remplace?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRechercheRemplacementDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRechercheRemplacementDto
     */
    dateFin?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheRemplacementDto
     */
    idTypeRemplacement?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheRemplacementDto
     */
    typeRemplacement?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheRemplacementDto
     */
    idLegitimite?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheRemplacementDto
     */
    legitimite?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRechercheRemplacementDto
     */
    dateDebutSearch?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheRemplacementDto
     */
    anneeAcademique?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheRemplacementDto
     */
    iduser?: number | null;
}

/**
 * Check if a given object implements the FcbRechercheRemplacementDto interface.
 */
export function instanceOfFcbRechercheRemplacementDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRechercheRemplacementDtoFromJSON(json: any): FcbRechercheRemplacementDto {
    return FcbRechercheRemplacementDtoFromJSONTyped(json, false);
}

export function FcbRechercheRemplacementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRechercheRemplacementDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idremplacement': !exists(json, 'idremplacement') ? undefined : json['idremplacement'],
        'remplace': !exists(json, 'remplace') ? undefined : json['remplace'],
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
        'idTypeRemplacement': !exists(json, 'idTypeRemplacement') ? undefined : json['idTypeRemplacement'],
        'typeRemplacement': !exists(json, 'typeRemplacement') ? undefined : json['typeRemplacement'],
        'idLegitimite': !exists(json, 'idLegitimite') ? undefined : json['idLegitimite'],
        'legitimite': !exists(json, 'legitimite') ? undefined : json['legitimite'],
        'dateDebutSearch': !exists(json, 'dateDebutSearch') ? undefined : (json['dateDebutSearch'] === null ? null : new Date(json['dateDebutSearch'])),
        'anneeAcademique': !exists(json, 'anneeAcademique') ? undefined : json['anneeAcademique'],
        'iduser': !exists(json, 'iduser') ? undefined : json['iduser'],
    };
}

export function FcbRechercheRemplacementDtoToJSON(value?: FcbRechercheRemplacementDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idremplacement': value.idremplacement,
        'remplace': value.remplace,
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
        'idTypeRemplacement': value.idTypeRemplacement,
        'typeRemplacement': value.typeRemplacement,
        'idLegitimite': value.idLegitimite,
        'legitimite': value.legitimite,
        'dateDebutSearch': value.dateDebutSearch === undefined ? undefined : (value.dateDebutSearch === null ? null : value.dateDebutSearch.toISOString()),
        'anneeAcademique': value.anneeAcademique,
        'iduser': value.iduser,
    };
}

