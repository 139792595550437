/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailDto } from './EmailDto';
import {
    EmailDtoFromJSON,
    EmailDtoFromJSONTyped,
    EmailDtoToJSON,
} from './EmailDto';
import type { TelephoneDto } from './TelephoneDto';
import {
    TelephoneDtoFromJSON,
    TelephoneDtoFromJSONTyped,
    TelephoneDtoToJSON,
} from './TelephoneDto';

/**
 * 
 * @export
 * @interface FcbConseillerPedagogiqueGridDto
 */
export interface FcbConseillerPedagogiqueGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbConseillerPedagogiqueGridDto
     */
    idconseillerPedagogique?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbConseillerPedagogiqueGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbConseillerPedagogiqueGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof FcbConseillerPedagogiqueGridDto
     */
    telephone?: TelephoneDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof FcbConseillerPedagogiqueGridDto
     */
    gsm?: TelephoneDto;
    /**
     * 
     * @type {EmailDto}
     * @memberof FcbConseillerPedagogiqueGridDto
     */
    email?: EmailDto;
    /**
     * 
     * @type {string}
     * @memberof FcbConseillerPedagogiqueGridDto
     */
    fax?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbConseillerPedagogiqueGridDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbConseillerPedagogiqueGridDto
     */
    dateDeces?: Date | null;
}

/**
 * Check if a given object implements the FcbConseillerPedagogiqueGridDto interface.
 */
export function instanceOfFcbConseillerPedagogiqueGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbConseillerPedagogiqueGridDtoFromJSON(json: any): FcbConseillerPedagogiqueGridDto {
    return FcbConseillerPedagogiqueGridDtoFromJSONTyped(json, false);
}

export function FcbConseillerPedagogiqueGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbConseillerPedagogiqueGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idconseillerPedagogique': !exists(json, 'idconseillerPedagogique') ? undefined : json['idconseillerPedagogique'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'telephone': !exists(json, 'telephone') ? undefined : TelephoneDtoFromJSON(json['telephone']),
        'gsm': !exists(json, 'gsm') ? undefined : TelephoneDtoFromJSON(json['gsm']),
        'email': !exists(json, 'email') ? undefined : EmailDtoFromJSON(json['email']),
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'dateDeces': !exists(json, 'dateDeces') ? undefined : (json['dateDeces'] === null ? null : new Date(json['dateDeces'])),
    };
}

export function FcbConseillerPedagogiqueGridDtoToJSON(value?: FcbConseillerPedagogiqueGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idconseillerPedagogique': value.idconseillerPedagogique,
        'nom': value.nom,
        'prenom': value.prenom,
        'telephone': TelephoneDtoToJSON(value.telephone),
        'gsm': TelephoneDtoToJSON(value.gsm),
        'email': EmailDtoToJSON(value.email),
        'fax': value.fax,
        'actif': value.actif,
        'dateDeces': value.dateDeces === undefined ? undefined : (value.dateDeces === null ? null : value.dateDeces.toISOString()),
    };
}

