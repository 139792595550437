import { FGEmpty, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { FcbTypeCoursDtoFromJSON, TypeCoursApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterCheckboxInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface ITypeCoursDetailPageProps {
  codeTypeCours: string;
  editMode: boolean;
}

export const TypeCoursDetailPage: React.FunctionComponent<ITypeCoursDetailPageProps> = ({
  codeTypeCours,
  editMode
}) => {
  const { t } = useTl();

  const api = useApiService(TypeCoursApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () => (codeTypeCours === "0" ? FcbTypeCoursDtoFromJSON({}) : api.typeCoursGet({ code: codeTypeCours })),
    saveApiFn: d => api.typeCoursSave({ FcbTypeCoursDto: d }),
    onSavedRoute: d => `${ERoutes.typeCours}/${d.code}/detail/edit`,
    deleteApiFn: d => api.typeCoursDelete({ code: d.code }),
    onDeletedRoute: () => ERoutes.typeCours
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      code: Yup.string()
        .required(t(ETLCodes.Required))
        .max(1, t(ETLCodes.TypeCours_CharacterLimitCode))
        .test("code-not-exists", t(ETLCodes.CodeExistsError), async value => {
          if (value === codeTypeCours) return true;
          const validationError = await api.typeCoursCodeExists({ code: value });
          return validationError.isValid;
        }),
      libelle: Yup.string()
        .max(50, t(ETLCodes.TypeCours_CharacterLimitLibelle))
        .required(t(ETLCodes.Required))
        .test("libelle-not-exists", t(ETLCodes.CodeExistsError), async value => {
          if (value === data.libelle) return true;
          const validationError = await api.typeCoursLibelleExists({ code: codeTypeCours, libelle: value });
          return validationError.isValid;
        })
    });
  }, [api, codeTypeCours, t, data]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={editMode}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.typeCours)}
      onDelete={deleteItem}
      showDeleteButton={codeTypeCours !== "0"}
      saving={saving}
      deleting={deleting}
    >
      <FieldGroup columns={2}>
        <FGTextInput name="code" maxLength={1} label={t(ETLCodes.Code)} />
        <FGTextInput name="libelle" maxLength={50} label={t(ETLCodes.Libelle)} />
        <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
        <FGEmpty />
        <FGWalterCheckboxInput name="acquisIfapme" label={t(ETLCodes.AcquisIfapme)} />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
