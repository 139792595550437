import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { FormikProps } from "formik";
import { FGEmpty, FGMaskInput, FGNumberInput, FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { FcbReferentielDto, FcbReferentielDtoFromJSON, ReferentielApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../../components";
import { FGEuroMaskInput } from "../../../../../components/formGenerator/FGEuroMaskInput";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

interface IReferentielDetailPageProps {
  editMode: boolean;
  idReferentiel: number;
  copyId: number;
}

export const ReferentielDetailPage: React.FunctionComponent<IReferentielDetailPageProps> = ({
  editMode,
  idReferentiel,
  copyId
}) => {
  const { t } = useTl();
  const api = useApiService(ReferentielApi);
  const history = useHistory();
  const formikRef = React.useRef<FormikProps<FcbReferentielDto>>();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () =>
          copyId
            ? api.referentielGet({ id: +copyId })
            : +idReferentiel <= 0
            ? FcbReferentielDtoFromJSON({ idReferentiel: 0, test: false })
            : api.referentielGet({ id: +idReferentiel }),
        saveApiFn: (d: any) => api.referentielSave({ SaveReferentielDto: { ...d, copyId } }),
        onSavedRoute: d => `${ERoutes.referentiel}/${d.idreferentiel}/detail/edit`,
        deleteApiFn: d => api.referentielDelete({ id: d.idreferentiel }),
        onDeletedRoute: () => ERoutes.referentiel
      }),
      [api, copyId, idReferentiel]
    )
  );

  React.useEffect(() => {
    if (copyId && data && data.idreferentiel === +copyId) {
      data.idreferentiel = idReferentiel;
    }
  }, [copyId, idReferentiel, data, formikRef]);

  const [metier, loadingMetier] = useReferential(a => a.referentialGetMetier(), true, []);
  const [degre, loadingDegre] = useReferential(a => a.referentialGetDegre(), false, []);
  const [typeReussite, loadingTypeReussite] = useReferential(a => a.referentialGetTypeReussite(), true, []);
  const [typeBulletin, loadingTypeBulletin] = useReferential(a => a.referentialGetTypesBulletin(), true, []);
  // const [certifications, loadingCertifications] = useReferential(a => a.referentialGetCertifications(), true, []);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idmetier: Yup.number().required(t(ETLCodes.Required)),
      iddegre: Yup.string().required(t(ETLCodes.Required)),
      date: Yup.date().required(t(ETLCodes.Required)),
      pcreport: Yup.number().max(100, t(ETLCodes.NumberTooHigh_100)),
      pcreussite: Yup.number().max(100, t(ETLCodes.NumberTooHigh_100))
    });
  }, [t]);

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={editMode}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(ERoutes.referentiel)}
        onDelete={deleteItem}
        showDeleteButton={+idReferentiel > 0}
        saving={saving}
        deleting={deleting}
        formikInnerRef={i => (formikRef.current = i)}
        forceEnableSave={true}
      >
        <FieldGroup columns={2}>
          <FGTextInput readonly={true} name="codereferentiel" label={t(ETLCodes.Code)} maxLength={2} />
          <FGWalterCheckboxInput readonly={idReferentiel > 0} name="test" label={t(ETLCodes.IsTest)} />
          <FGWalterDateMaskInput name="date" label={t(ETLCodes.Date)} />
          <FGWalterSelectInput
            items={metier}
            readonly={idReferentiel > 0}
            loading={loadingMetier}
            name="idmetier"
            label={t(ETLCodes.Metier)}
          />

          <FGWalterSelectInput
            readonly={idReferentiel > 0}
            items={degre}
            loading={loadingDegre}
            name="iddegre"
            label={t(ETLCodes.Degres)}
          />
          <FGTextInput name="description" label={t(ETLCodes.Description)} maxLength={150} forceCase="upper" />
          <FGNumberInput max={9999999999} name="nbheures" label={t(ETLCodes.NbHeure)} />
          <FGTextInput readonly={true} name="heureReelle" label={t(ETLCodes.NbHeureReelle)} />
          <FGMaskInput
            cleaveOptions={{ numericOnly: true, blocks: [10] }}
            readonly={idReferentiel > 0}
            name="nbseances"
            label={t(ETLCodes.NbSceances)}
          />
          <FGEuroMaskInput disabled={idReferentiel > 0} name="coutmatiere" label={t(ETLCodes.CoutMatiere)} />
          <FGMaskInput
            cleaveOptions={{ numericOnly: true, blocks: [10] }}
            readonly={idReferentiel > 0}
            name="nbcandidat"
            label={t(ETLCodes.NbCandidat)}
          />
          <FGNumberInput
            max={100}
            readonly={idReferentiel > 0}
            name="pcreport"
            label={t(ETLCodes.SeuilReport)}
            rightElement={<Icon icon={IconNames.PERCENTAGE} />}
          />
          <FGNumberInput
            max={100}
            readonly={idReferentiel > 0}
            name="pcreussite"
            label={t(ETLCodes.SeuilReussite)}
            rightElement={<Icon icon={IconNames.PERCENTAGE} />}
          />
          <FGWalterSelectInput
            items={typeReussite}
            loading={loadingTypeReussite}
            name="idtypeReussite"
            label={t(ETLCodes.TypeReussite)}
            readonly={idReferentiel > 0}
          />
          <FGTextAreaInput name="remarque" label={t(ETLCodes.Remarque)} />
          <FGWalterCheckboxInput name="deliberee" label={t(ETLCodes.ADeliberee)} />
          <FGEmpty />
          <FGWalterSelectInput
            name="idtypeBulletin"
            label={t(ETLCodes.TypeBulletin)}
            items={typeBulletin}
            loading={loadingTypeBulletin}
            readonly={data.linkedToClasses}
          />
          <FGEmpty />
          <FGWalterCheckboxInput name="e8E9" label={t(ETLCodes.E8E9)} />
          <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
          {/* <FGWalterSelectInput
            name="idcertification"
            label={t(ETLCodes.Certification)}
            items={certifications}
            loading={loadingCertifications}
          /> */}
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
