import { Button, Card, Divider, Intent } from "@blueprintjs/core";
import * as React from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import styled from "styled-components";

import { CardAttribute } from ".";
import { CardData } from "../contexts";
import { useTl } from "../hooks";
import { ETLCodes } from "../locales";

export interface IBeIdCardProps {
  card: CardData;
}

const PhotoImage = styled.img`
  object-fit: fill;
  width: 120px;
  border-radius: 10px;
  border: 1px solid lightgray;
`;

const InfoSection = styled.div``;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MyCard = styled(Card)`
  padding: 5px;
  background-color: #d9ffd9;
  display: flex;
  flex-direction: row;
`;

export const BeIdCard: React.FunctionComponent<IBeIdCardProps> = ({ card }) => {
  const { t } = useTl();
  if (!card) return null;
  return (
    <>
      <MyCard>
        <InfoSection>
          <RowContainer>
            <CardAttribute label={t(ETLCodes.Nom)} value={card.surname}></CardAttribute>
            <CardAttribute label={t(ETLCodes.FirstNames)} value={card.firstNames}></CardAttribute>
          </RowContainer>
          <RowContainer>
            <CardAttribute label={t(ETLCodes.LocationAndBirthDate)} value={card.locationAndBirthDate}></CardAttribute>
            <CardAttribute label={t(ETLCodes.Genre)} value={card?.gender}></CardAttribute>
          </RowContainer>
          <RowContainer>
            <CardAttribute label={t(ETLCodes.Nationalite)} value={card?.nationality}></CardAttribute>
            <CardAttribute label={t(ETLCodes.CardNumber)} value={card.cardNumber}></CardAttribute>
          </RowContainer>
          <RowContainer>
            <CardAttribute label={t(ETLCodes.NumeroNational)} value={card.nationalNumber}></CardAttribute>
            <CardAttribute label={t(ETLCodes.ValidityDates)} value={card.validityDates}></CardAttribute>
          </RowContainer>
        </InfoSection>
        <PhotoImage src={`data:image/jpg;base64,${card.photoB64}`} alt="id-card" />
      </MyCard>
      <Grid fluid style={{ paddingTop: "10px" }}>
        <Row>
          <Col xs={6}>
            <Button text={t(ETLCodes.ConsulterFiche)} style={{ width: "100%" }} />
          </Col>
          <Col xs={6}>
            <Button text={t(ETLCodes.MiseAJourFiche)} style={{ width: "100%" }} />
          </Col>
        </Row>
        <Row style={{ paddingTop: "5px" }}>
          <Col xs={6}>
            <Button text={t(ETLCodes.EncoderRetard)} style={{ width: "100%" }} />
          </Col>
          <Col xs={6}>
            <Button text={t(ETLCodes.ConsulterHoraire)} style={{ width: "100%" }} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col xs={6}>
            <Button text={t(ETLCodes.CreerApprenant)} style={{ width: "100%" }} intent={Intent.PRIMARY} />
          </Col>
          <Col xs={6}>
            <Button text={t(ETLCodes.CreerFormateur)} style={{ width: "100%" }} intent={Intent.PRIMARY} />
          </Col>
        </Row>
      </Grid>
    </>
  );
};
