import { Classes, Colors, Dialog } from "@blueprintjs/core";
import { startOfToday } from "date-fns";
import { FGTextAreaInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import * as Yup from "yup";

import { FichierApi, SuiviApprenantActionEditDto, SuiviApprenantActionEditDtoFromJSON } from "../../../../../../api";
import {
  FGWalterDateMaskInput,
  FGWalterFileInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../../../components";
import { useApiService, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

export interface SuiviApprenantActionDialogProps {
  dto: SuiviApprenantActionEditDto;
  isOpen: boolean;
  onClose: (toSave?: SuiviApprenantActionEditDto) => void;
  enveloppeId: number;
  editMode: boolean;
  editable: boolean;
}

export const SuiviApprenantActionDialog: React.FunctionComponent<SuiviApprenantActionDialogProps> = ({
  isOpen,
  onClose,
  editMode,
  editable,
  dto
}) => {
  const { t } = useTl();
  const [typeAction, taLoading] = useReferential(a => a.referentialGetTypeSuiviActions(), true, []);
  const [collaborateurCentres, ccLoading] = useReferential(a =>
    a.referentialGetSameCentreUsers({ currentId: dto?.idcollaborateur ?? 0 })
  );

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      dateEcheance: Yup.date()
        .nullable()
        .min(startOfToday(), t(ETLCodes.DateCannotBeInPast))
        .required(t(ETLCodes.Required)),
      idtypeSuiviAction: Yup.number().required(t(ETLCodes.Required)),
      idcollaborateur: Yup.number().required(t(ETLCodes.Required))
    });
  }, [t]);

  const api = useApiService(FichierApi);
  const downloadFn = React.useCallback(async () => await api.fichierDownload({ id: dto?.idfichier }), [
    api,
    dto?.idfichier
  ]);

  return (
    <Dialog
      isOpen={isOpen}
      isCloseButtonShown={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={false}
      title={t(ETLCodes.Action)}
      onClose={() => onClose()}
      style={{ padding: "0.5rem", width: 800, backgroundColor: Colors.WHITE }}
      enforceFocus={false}
    >
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={dto ?? SuiviApprenantActionEditDtoFromJSON({})}
          saving={false}
          deleting={true}
          onSubmit={onClose}
          editMode={editMode}
          formatDate="dd/MM/yyyy"
          loading={false}
          validationSchema={FormSchema}
          editable={editable}
        >
          <FieldGroup>
            <FGWalterDateMaskInput name="dateEcheance" label={t(ETLCodes.DateEcheance)} showCalendar={false} />
            <FGWalterSelectInput
              name="idtypeSuiviAction"
              loading={taLoading}
              items={typeAction}
              label={t(ETLCodes.TypeAction)}
            />
            <FGWalterSelectInput
              name="idcollaborateur"
              loading={ccLoading}
              items={collaborateurCentres}
              label={t(ETLCodes.ResponsableAction)}
            />
            <FGTextAreaInput name="remarque" label={t(ETLCodes.RemarqueAction)} />
            <FGWalterFileInput name="fichier" label={t(ETLCodes.Document)} downloadFn={dto?.idfichier && downloadFn} />
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </Dialog>
  );
};
