/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ExportExcelInfoDto,
  ExportSearch,
  FcbExportDetailDto,
  FcbExportGridDtoPaginatedResults,
  FileDownloadDto,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    ExportExcelInfoDtoFromJSON,
    ExportExcelInfoDtoToJSON,
    ExportSearchFromJSON,
    ExportSearchToJSON,
    FcbExportDetailDtoFromJSON,
    FcbExportDetailDtoToJSON,
    FcbExportGridDtoPaginatedResultsFromJSON,
    FcbExportGridDtoPaginatedResultsToJSON,
    FileDownloadDtoFromJSON,
    FileDownloadDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface ExportUtilitaireBaseSearchRequest {
    ExportSearch?: ExportSearch;
}

export interface ExportUtilitaireExportExcelRequest {
    ExportExcelInfoDto?: ExportExcelInfoDto;
}

export interface ExportUtilitaireGetExportWithParamsRequest {
    idExport?: number;
}

export interface ExportUtilitaireGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ExportUtilitaireGetSelectItemsRequest {
    searchField: string;
}

/**
 * 
 */
export class ExportUtilitaireApi extends runtime.BaseAPI {

    /**
     */
    async exportUtilitaireBaseSearchRaw(requestParameters: ExportUtilitaireBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbExportGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportUtilitaire/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportSearchToJSON(requestParameters.ExportSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbExportGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async exportUtilitaireBaseSearch(requestParameters: ExportUtilitaireBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbExportGridDtoPaginatedResults> {
        const response = await this.exportUtilitaireBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportUtilitaireExportExcelRaw(requestParameters: ExportUtilitaireExportExcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportUtilitaire/exportExcel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportExcelInfoDtoToJSON(requestParameters.ExportExcelInfoDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async exportUtilitaireExportExcel(requestParameters: ExportUtilitaireExportExcelRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.exportUtilitaireExportExcelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportUtilitaireGetExportWithParamsRaw(requestParameters: ExportUtilitaireGetExportWithParamsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbExportDetailDto>> {
        const queryParameters: any = {};

        if (requestParameters.idExport !== undefined) {
            queryParameters['idExport'] = requestParameters.idExport;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportUtilitaire/exportWithParam`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbExportDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async exportUtilitaireGetExportWithParams(requestParameters: ExportUtilitaireGetExportWithParamsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbExportDetailDto> {
        const response = await this.exportUtilitaireGetExportWithParamsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportUtilitaireGetSearchCriteriasRaw(requestParameters: ExportUtilitaireGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportUtilitaire/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async exportUtilitaireGetSearchCriterias(requestParameters: ExportUtilitaireGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.exportUtilitaireGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportUtilitaireGetSelectItemsRaw(requestParameters: ExportUtilitaireGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling exportUtilitaireGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportUtilitaire/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async exportUtilitaireGetSelectItems(requestParameters: ExportUtilitaireGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.exportUtilitaireGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
