import { Button, ButtonGroup, ButtonProps } from "@blueprintjs/core";
import React, { Children, isValidElement } from "react";
import styled from "styled-components";

type VoidFunction = () => void;

type PdfViewerToolbarProps = {
  onToggleThumbnails?: VoidFunction;
  onDownload?: VoidFunction;
  onZoomIn?: VoidFunction;
  onZoomOut?: VoidFunction;
  onRotateRight?: VoidFunction;
  onRotateLeft?: VoidFunction;
  onPrint?: VoidFunction;
  fitToHeight?: VoidFunction;
  onNext?: VoidFunction;
  onPrevious?: VoidFunction;
  canPrevious?: boolean;
  canNext?: boolean;
};

const ToolbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  > .bp4-button,
  .bp4-button-group {
    margin-right: 0.25rem;
  }
`;

const OptionalButton = (props: ButtonProps) => {
  if (props.onClick) return <Button {...props} />;
  return null;
};

const OptionalButtonGroup = styled(ButtonGroup)`
  :empty {
    display: none;
  }
`;

export default ({
  onToggleThumbnails,
  onDownload,
  onZoomIn,
  onZoomOut,
  onRotateRight,
  onRotateLeft,
  onPrint,
  fitToHeight,
  onNext,
  onPrevious,
  canPrevious = true,
  canNext = true
}: PdfViewerToolbarProps) => (
  <ToolbarContainer>
    <OptionalButton icon={"menu"} onClick={onToggleThumbnails} />
    <OptionalButtonGroup>
      <OptionalButton icon={"zoom-in"} onClick={onZoomIn} />
      <OptionalButton icon={"zoom-out"} onClick={onZoomOut} />
      <OptionalButton icon={"zoom-to-fit"} onClick={fitToHeight} />
    </OptionalButtonGroup>

    <OptionalButtonGroup>
      <OptionalButton icon={"repeat"} onClick={onRotateRight} />
      <OptionalButton icon={"reset"} onClick={onRotateLeft} />
    </OptionalButtonGroup>

    <OptionalButtonGroup>
      <OptionalButton icon={"download"} onClick={onDownload} />
      <OptionalButton icon={"print"} onClick={onPrint} />
    </OptionalButtonGroup>
    <OptionalButtonGroup>
      <OptionalButton icon={"chevron-left"} onClick={onPrevious} disabled={!canPrevious} />
      <OptionalButton icon={"chevron-right"} onClick={onNext} disabled={!canNext} />
    </OptionalButtonGroup>
  </ToolbarContainer>
);
