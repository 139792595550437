import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";

import { ClasseApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { ClasseAgrementLocalisationSwitch } from "./agrementLocalisation/ClasseAgrementLocalisationSwitch";
import { ClasseApprenantList } from "./apprenant/ClasseApprenantList";
import { ClasseCoherenceHorairePage } from "./coherenceHoraire/ClasseCoherenceHorairePage";
import { CopieClassePage } from "./copieClasse/CopieClassePage";
import { ClasseDetail } from "./detail/ClasseDetail";
import { ExamenPratiqueItemPage } from "./examen/ExamenPratiqueItemPage";
import { ClasseHorairePage } from "./horaire";
import { ClasseJureItemPage } from "./jure/ClasseJureItemPage";
import { ClasseMatiereSwitch } from "./matiere/ClasseMatiereSwitch";

export interface IClasseItemPageProps {}

export const ClasseItemPage: React.FunctionComponent<IClasseItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const api = useApiService(ClasseApi);

  const fetchSocieteExterneUnauthorized = React.useCallback(async () => {
    return await api.classeSocieteExterneUnauthorized({ idClasse: +id });
  }, [api, id]);
  const { data: societeExterneUnauthorized } = useQuery(
    ["societe-externe-unauthorize-classe", id],
    fetchSocieteExterneUnauthorized,
    { cacheTime: 0 }
  );
  React.useEffect(() => {
    if (societeExterneUnauthorized?.value) {
      history.push(ERoutes.unauthorized);
    }
  }, [history, societeExterneUnauthorized]);

  const [displayName] = useApiEffect(() => api.classeGetDisplayName({ id: +id }), [id]);
  const [jure] = useApiEffect(() => api.classeIsJure({ id: +id }), [id]);

  return (
    <PageBase
      breadCrumbs={React.useMemo(() => [{ text: t(ETLCodes.Classes), route: ERoutes.classe }, { text: displayName }], [
        displayName,
        t
      ])}
    >
      <ProtectedTabs
        id="TabsClasse"
        onChange={newTabId => history.push(`${ERoutes.classe}/${id}/${newTabId}/${state}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.classe} />
        <Tab id="detail" title={t(ETLCodes.Detail)} panel={<ClasseDetail />} disabled={tab === "copie"} />
        <Tab
          id="agrement"
          title={t(ETLCodes.AgrementLocalisation)}
          disabled={+id <= 0 || tab === "copie"}
          panel={<ClasseAgrementLocalisationSwitch />}
        />
        <Tab
          id="matiere"
          title={t(ETLCodes.Matieres)}
          disabled={+id <= 0 || tab === "copie"}
          panel={<ClasseMatiereSwitch />}
        />
        <Tab
          id="horaire"
          title={t(ETLCodes.Horaire)}
          disabled={+id <= 0 || tab === "copie"}
          panel={<ClasseHorairePage />}
        />
        <Tab
          id="coherencesHoraire"
          title={t(ETLCodes.CoherenceHoraire)}
          panel={<ClasseCoherenceHorairePage classeId={+id} />}
          disabled={+id <= 0 || tab === "copie"}
        />
        <Tab
          id="apprenants"
          title={t(ETLCodes.ListeDesApprenants)}
          disabled={+id <= 0 || tab === "copie"}
          panel={<ClasseApprenantList />}
        />
        <Tab
          id="examen"
          title={t(ETLCodes.ExamenPratique)}
          disabled={+id <= 0 || tab === "copie"}
          panel={<ExamenPratiqueItemPage />}
        />

        <Tab
          id="jure"
          title={t(ETLCodes.Jures)}
          disabled={+id <= 0 || !jure?.value || tab === "copie"}
          panel={<ClasseJureItemPage />}
        />
        {tab === "copie" && (
          <Tab
            id="copie"
            title={t(ETLCodes.CopieClasse)}
            disabled={tab !== "copie"}
            panel={<CopieClassePage idClasse={+id} state={state}></CopieClassePage>}
          />
        )}
      </ProtectedTabs>
    </PageBase>
  );
};
