/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbSmsSuiviGridDto
 */
export interface FcbSmsSuiviGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbSmsSuiviGridDto
     */
    idlot?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbSmsSuiviGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbSmsSuiviGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbSmsSuiviGridDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbSmsSuiviGridDto
     */
    numero?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbSmsSuiviGridDto
     */
    statut?: string | null;
}

/**
 * Check if a given object implements the FcbSmsSuiviGridDto interface.
 */
export function instanceOfFcbSmsSuiviGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbSmsSuiviGridDtoFromJSON(json: any): FcbSmsSuiviGridDto {
    return FcbSmsSuiviGridDtoFromJSONTyped(json, false);
}

export function FcbSmsSuiviGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbSmsSuiviGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idlot': !exists(json, 'idlot') ? undefined : json['idlot'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'numero': !exists(json, 'numero') ? undefined : json['numero'],
        'statut': !exists(json, 'statut') ? undefined : json['statut'],
    };
}

export function FcbSmsSuiviGridDtoToJSON(value?: FcbSmsSuiviGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idlot': value.idlot,
        'nom': value.nom,
        'prenom': value.prenom,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'numero': value.numero,
        'statut': value.statut,
    };
}

