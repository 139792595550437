import { Loading, useApiEffect } from "nsitools-react";
import * as React from "react";

import { ApprenantBulletinDetail } from ".";
import { BulletinApprenantTypeApi } from "../../../../../../api";
import { useEventsContext } from "../../../../../../contexts";
import { useApiService } from "../../../../../../hooks";

export interface IApprenantBulletinDetailListProps {
  idApprenant: number;
  idTypeCours: string;
  anneeScolaire: string;
}

export const ApprenantBulletinDetailList: React.FunctionComponent<IApprenantBulletinDetailListProps> = ({
  idApprenant,
  anneeScolaire,
  idTypeCours
}) => {
  const api = useApiService(BulletinApprenantTypeApi);

  const getBulletinType = React.useCallback(() => {
    return api.bulletinApprenantTypeGetBulletinType({
      idApprenant: idApprenant,
      anneeScolaire: anneeScolaire,
      idTypeCours: idTypeCours
    });
  }, [anneeScolaire, api, idApprenant, idTypeCours]);

  const [data, loading, , refresh] = useApiEffect(getBulletinType, [idApprenant, anneeScolaire, idTypeCours]);

  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();

  React.useEffect(() => {
    subscribeToEvent("DELIBERATION_REFRESH", refresh);
    return () => {
      unsubscribeEvent("DELIBERATION_REFRESH", refresh);
    };
  }, [refresh, subscribeToEvent, unsubscribeEvent]);

  return (
    <Loading isLoading={loading}>
      {data &&
        data.map(d => (
          <ApprenantBulletinDetail
            bulletin={d}
            key={`${d.idbulletinApprenantType}_${d.isSecondeSession ? "2" : "1"}`}
          ></ApprenantBulletinDetail>
        ))}
    </Loading>
  );
};
