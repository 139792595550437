/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbParcoursCreaDetailDto
 */
export interface FcbParcoursCreaDetailDto {
    /**
     * 
     * @type {number}
     * @memberof FcbParcoursCreaDetailDto
     */
    idparcoursCrea?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbParcoursCreaDetailDto
     */
    idapprenant?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbParcoursCreaDetailDto
     */
    idcentre?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbParcoursCreaDetailDto
     */
    idstatutCrea?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbParcoursCreaDetailDto
     */
    idcategorieSpecifiqueCrea?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbParcoursCreaDetailDto
     */
    idnotorieteType?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbParcoursCreaDetailDto
     */
    idnotorieteSousType?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbParcoursCreaDetailDto
     */
    notorieteChampLibre?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbParcoursCreaDetailDto
     */
    idsecteurCrea?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbParcoursCreaDetailDto
     */
    datePrevisionnelle?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbParcoursCreaDetailDto
     */
    dateLancement?: Date | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FcbParcoursCreaDetailDto
     */
    ambassadeursCrea?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof FcbParcoursCreaDetailDto
     */
    numeroEntreprise?: string | null;
}

/**
 * Check if a given object implements the FcbParcoursCreaDetailDto interface.
 */
export function instanceOfFcbParcoursCreaDetailDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbParcoursCreaDetailDtoFromJSON(json: any): FcbParcoursCreaDetailDto {
    return FcbParcoursCreaDetailDtoFromJSONTyped(json, false);
}

export function FcbParcoursCreaDetailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbParcoursCreaDetailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idparcoursCrea': !exists(json, 'idparcoursCrea') ? undefined : json['idparcoursCrea'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'idstatutCrea': !exists(json, 'idstatutCrea') ? undefined : json['idstatutCrea'],
        'idcategorieSpecifiqueCrea': !exists(json, 'idcategorieSpecifiqueCrea') ? undefined : json['idcategorieSpecifiqueCrea'],
        'idnotorieteType': !exists(json, 'idnotorieteType') ? undefined : json['idnotorieteType'],
        'idnotorieteSousType': !exists(json, 'idnotorieteSousType') ? undefined : json['idnotorieteSousType'],
        'notorieteChampLibre': !exists(json, 'notorieteChampLibre') ? undefined : json['notorieteChampLibre'],
        'idsecteurCrea': !exists(json, 'idsecteurCrea') ? undefined : json['idsecteurCrea'],
        'datePrevisionnelle': !exists(json, 'datePrevisionnelle') ? undefined : (json['datePrevisionnelle'] === null ? null : new Date(json['datePrevisionnelle'])),
        'dateLancement': !exists(json, 'dateLancement') ? undefined : (json['dateLancement'] === null ? null : new Date(json['dateLancement'])),
        'ambassadeursCrea': !exists(json, 'ambassadeursCrea') ? undefined : json['ambassadeursCrea'],
        'numeroEntreprise': !exists(json, 'numeroEntreprise') ? undefined : json['numeroEntreprise'],
    };
}

export function FcbParcoursCreaDetailDtoToJSON(value?: FcbParcoursCreaDetailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idparcoursCrea': value.idparcoursCrea,
        'idapprenant': value.idapprenant,
        'idcentre': value.idcentre,
        'idstatutCrea': value.idstatutCrea,
        'idcategorieSpecifiqueCrea': value.idcategorieSpecifiqueCrea,
        'idnotorieteType': value.idnotorieteType,
        'idnotorieteSousType': value.idnotorieteSousType,
        'notorieteChampLibre': value.notorieteChampLibre,
        'idsecteurCrea': value.idsecteurCrea,
        'datePrevisionnelle': value.datePrevisionnelle === undefined ? undefined : (value.datePrevisionnelle === null ? null : value.datePrevisionnelle.toISOString()),
        'dateLancement': value.dateLancement === undefined ? undefined : (value.dateLancement === null ? null : value.dateLancement.toISOString()),
        'ambassadeursCrea': value.ambassadeursCrea,
        'numeroEntreprise': value.numeroEntreprise,
    };
}

