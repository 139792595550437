/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EValidationError {
    ClasseMatiereSaveContratNotNullFormateurNull = 'ClasseMatiereSaveContratNotNullFormateurNull',
    ClasseMatiereSaveContratFormateurIncompatible = 'ClasseMatiereSaveContratFormateurIncompatible',
    ClasseMatiereSaveTravailRequired = 'ClasseMatiereSaveTravailRequired',
    ClasseMatiereSaveExamenRequired = 'ClasseMatiereSaveExamenRequired',
    ClasseMatiereSaveRemplacementExisting = 'ClasseMatiereSaveRemplacementExisting',
    ClasseMatiereSaveExportGapExisting = 'ClasseMatiereSaveExportGapExisting',
    QueryMustStartWithSelect = 'QueryMustStartWithSelect',
    QueryParamNotOpened = 'QueryParamNotOpened',
    QueryParamNotClosed = 'QueryParamNotClosed',
    QueryInvalid = 'QueryInvalid',
    RemplacementClasseHoraireLinkedToGap = 'RemplacementClasseHoraireLinkedToGap'
}


export function EValidationErrorFromJSON(json: any): EValidationError {
    return EValidationErrorFromJSONTyped(json, false);
}

export function EValidationErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): EValidationError {
    return json as EValidationError;
}

export function EValidationErrorToJSON(value?: EValidationError | null): any {
    return value as any;
}

