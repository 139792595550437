import * as React from "react";
import { Switch, Route } from "react-router";
import { ERoutes } from "../../../../../AppRouter";
import {
  ActiviteProfessionnelleTitrePage,
  ActiviteProfessionelleDiplomePage,
  ActiviteProfessionnelleProfessionPage
} from "./item";
import { ActiviteProfessionnellesListPage } from "./ActiviteProfessionnellesListPage";

export interface IActiviteProfessionelleSwitchProps {}

export const ActiviteProfessionelleSwitch: React.FunctionComponent<IActiviteProfessionelleSwitchProps> = () => {
  return (
    <Switch>
      <Route path={`${ERoutes.formateur}/:id/:tab`} component={ActiviteProfessionnellesListPage} exact />
      <Route
        path={`${ERoutes.formateur}/:id/:tab/Profession/:idProfession/:state`}
        component={ActiviteProfessionnelleProfessionPage}
        exact
      />
      <Route
        path={`${ERoutes.formateur}/:id/:tab/Titre/:idTitreFormateur/:state`}
        component={ActiviteProfessionnelleTitrePage}
        exact
      />
      <Route
        path={`${ERoutes.formateur}/:id/:tab/Diplome/:idDiplome/:state`}
        component={ActiviteProfessionelleDiplomePage}
        exact
      />
    </Switch>
  );
};
