/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AcquisScolaireSearch,
  FcbAcquisScolaireDto,
  FcbAcquisScolaireGridDtoPaginatedResults,
  FcbApprenantDto,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    AcquisScolaireSearchFromJSON,
    AcquisScolaireSearchToJSON,
    FcbAcquisScolaireDtoFromJSON,
    FcbAcquisScolaireDtoToJSON,
    FcbAcquisScolaireGridDtoPaginatedResultsFromJSON,
    FcbAcquisScolaireGridDtoPaginatedResultsToJSON,
    FcbApprenantDtoFromJSON,
    FcbApprenantDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface AcquisScolaireBaseSearchRequest {
    AcquisScolaireSearch?: AcquisScolaireSearch;
}

export interface AcquisScolaireDeleteRequest {
    id?: number;
}

export interface AcquisScolaireGetRequest {
    id?: number;
}

export interface AcquisScolaireGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface AcquisScolaireGetSelectItemsRequest {
    searchField: string;
}

export interface AcquisScolaireSaveRequest {
    FcbAcquisScolaireDto?: FcbAcquisScolaireDto;
}

/**
 * 
 */
export class AcquisScolaireApi extends runtime.BaseAPI {

    /**
     */
    async acquisScolaireBaseSearchRaw(requestParameters: AcquisScolaireBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbAcquisScolaireGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaire/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AcquisScolaireSearchToJSON(requestParameters.AcquisScolaireSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbAcquisScolaireGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireBaseSearch(requestParameters: AcquisScolaireBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbAcquisScolaireGridDtoPaginatedResults> {
        const response = await this.acquisScolaireBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireDeleteRaw(requestParameters: AcquisScolaireDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaire`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async acquisScolaireDelete(requestParameters: AcquisScolaireDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.acquisScolaireDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireGetRaw(requestParameters: AcquisScolaireGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbAcquisScolaireDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaire`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbAcquisScolaireDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireGet(requestParameters: AcquisScolaireGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbAcquisScolaireDto> {
        const response = await this.acquisScolaireGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireGetSearchCriteriasRaw(requestParameters: AcquisScolaireGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaire/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async acquisScolaireGetSearchCriterias(requestParameters: AcquisScolaireGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.acquisScolaireGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireGetSelectItemsRaw(requestParameters: AcquisScolaireGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling acquisScolaireGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaire/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async acquisScolaireGetSelectItems(requestParameters: AcquisScolaireGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.acquisScolaireGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireSaveRaw(requestParameters: AcquisScolaireSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbApprenantDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaire`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbAcquisScolaireDtoToJSON(requestParameters.FcbAcquisScolaireDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbApprenantDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireSave(requestParameters: AcquisScolaireSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbApprenantDto> {
        const response = await this.acquisScolaireSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
