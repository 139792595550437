import { FGEmpty, FGMaskInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { FcbPersonnelTutelleDtoFromJSON, PersonnelTutelleApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterCheckboxInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

interface IDelegueTutelleDetailPage {
  idDelegueTutelle: number;
  editMode: boolean;
}

export const DelegueTutelleDetailPage: React.FunctionComponent<IDelegueTutelleDetailPage> = ({
  idDelegueTutelle,
  editMode
}) => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(PersonnelTutelleApi);

  const [sexe, loadingSexe] = useReferential(a => a.referentialGetSexe(), false, []);
  const [serviceTutelle, loadingServiceTutelle] = useReferential(a => a.referentialGetServiceTutelle({userFilter: false}), true, []);

  const { data, loading, deleteItem, saveItem, deleting, saving } = useCrudApi({
    getApiFn: () =>
      idDelegueTutelle <= 0
        ? FcbPersonnelTutelleDtoFromJSON({ idDelegueTutelle: 0 })
        : api.personnelTutelleGet({ id: idDelegueTutelle }),
    saveApiFn: d => api.personnelTutelleSave({ FcbPersonnelTutelleDto: d }),
    deleteApiFn: d => api.personnelTutelleDelete({ id: d.iddelegueTutelle }),
    onDeletedRoute: () => ERoutes.delegueTutelle,
    onSavedRoute: d => `${ERoutes.delegueTutelle}/${d.iddelegueTutelle}/detail/edit`
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idserviceTutelle: Yup.number()
        .required(t(ETLCodes.Required))
        .notOneOf([-1, 0], t(ETLCodes.Required)),
      codesexe: Yup.string().required(t(ETLCodes.Required)),
      code: Yup.string().required(t(ETLCodes.Required)),
      nom: Yup.string().required(t(ETLCodes.Required)),
      email: Yup.string().email(t(ETLCodes.InvalidEmail)),
      gsm: Yup.string().test("gsm-regex", t(ETLCodes.ErrorPhoneRegex), function(value) {
        if (value) {
          const regex = /^[+\d/\-.]*$/gm;
          const match = value.match(regex);
          if (!match) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      })
    });
  }, [t]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={editMode}
      validationSchema={FormSchema}
      loading={loading}
      onDelete={deleteItem}
      deleting={deleting}
      minLabelWidth={200}
      saving={saving}
      showDeleteButton={idDelegueTutelle > 0}
      onCancel={() => history.push(ERoutes.delegueTutelle)}
    >
      <FieldGroup columns={2}>
        <FGTextInput name="code" label={t(ETLCodes.PersonnelTutelleItemPageCode)} maxLength={4} forceCase="upper" />
        <FGEmpty />
        <FGTextInput name="nom" label={t(ETLCodes.PersonnelTutelleItemPageNom)} maxLength={100} forceCase="upper" />
        <FGTextInput name="prenom" label={t(ETLCodes.PersonnelTutelleItemPagePrenom)} maxLength={100} />
        <FGWalterSelectInput
          name="codesexe"
          label={t(ETLCodes.PersonnelTutelleItemPageGenre)}
          loading={loadingSexe}
          items={sexe}
        />
        <FGMaskInput
          name="registreNational"
          label={t(ETLCodes.NumeroNational)}
          cleaveOptions={{ delimiters: [".", ".", "-", "."], blocks: [2, 2, 2, 3, 2] }}
        />
        <FGTextInput name="gsm" label={t(ETLCodes.PersonnelTutelleItemPageGsm)} maxLength={20} />
        <FGTextInput name="email" label={t(ETLCodes.PersonnelTutelleItemPageEmail)} maxLength={100} />
        <FGWalterSelectInput
          name="idserviceTutelle"
          label={t(ETLCodes.PersonnelTutelleItemPageServiceTutelle)}
          loading={loadingServiceTutelle}
          items={serviceTutelle}
        />
        <FGWalterCheckboxInput name="independant" label={t(ETLCodes.PersonnelTutelleItemPageIndependant)} />
        <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
