/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AnneeScolaireSearch,
  BooleanDto,
  FcbAnneeScolaireDetailDto,
  FcbAnneeScolaireDto,
  FcbAnneeScolaireDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    AnneeScolaireSearchFromJSON,
    AnneeScolaireSearchToJSON,
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FcbAnneeScolaireDetailDtoFromJSON,
    FcbAnneeScolaireDetailDtoToJSON,
    FcbAnneeScolaireDtoFromJSON,
    FcbAnneeScolaireDtoToJSON,
    FcbAnneeScolaireDtoPaginatedResultsFromJSON,
    FcbAnneeScolaireDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface AnneeScolaireBaseSearchRequest {
    AnneeScolaireSearch?: AnneeScolaireSearch;
}

export interface AnneeScolaireDeleteRequest {
    id?: string;
}

export interface AnneeScolaireExistsRequest {
    anneeDebut?: string;
    anneeFin?: string;
}

export interface AnneeScolaireGetRequest {
    id?: string;
}

export interface AnneeScolaireGetDisplayNameRequest {
    id?: string;
}

export interface AnneeScolaireGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface AnneeScolaireGetSelectItemsRequest {
    searchField: string;
}

export interface AnneeScolaireIsDeletableRequest {
    id?: string;
}

export interface AnneeScolaireSaveRequest {
    FcbAnneeScolaireDetailDto?: FcbAnneeScolaireDetailDto;
}

/**
 * 
 */
export class AnneeScolaireApi extends runtime.BaseAPI {

    /**
     */
    async anneeScolaireBaseSearchRaw(requestParameters: AnneeScolaireBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbAnneeScolaireDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AnneeScolaire/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnneeScolaireSearchToJSON(requestParameters.AnneeScolaireSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbAnneeScolaireDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async anneeScolaireBaseSearch(requestParameters: AnneeScolaireBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbAnneeScolaireDtoPaginatedResults> {
        const response = await this.anneeScolaireBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async anneeScolaireDeleteRaw(requestParameters: AnneeScolaireDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AnneeScolaire`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async anneeScolaireDelete(requestParameters: AnneeScolaireDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.anneeScolaireDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async anneeScolaireExistsRaw(requestParameters: AnneeScolaireExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.anneeDebut !== undefined) {
            queryParameters['anneeDebut'] = requestParameters.anneeDebut;
        }

        if (requestParameters.anneeFin !== undefined) {
            queryParameters['anneeFin'] = requestParameters.anneeFin;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AnneeScolaire/Exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async anneeScolaireExists(requestParameters: AnneeScolaireExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.anneeScolaireExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async anneeScolaireGetRaw(requestParameters: AnneeScolaireGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbAnneeScolaireDetailDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AnneeScolaire/get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbAnneeScolaireDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async anneeScolaireGet(requestParameters: AnneeScolaireGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbAnneeScolaireDetailDto> {
        const response = await this.anneeScolaireGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async anneeScolaireGetCurrentRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbAnneeScolaireDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AnneeScolaire/getCurrent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbAnneeScolaireDtoFromJSON(jsonValue));
    }

    /**
     */
    async anneeScolaireGetCurrent(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbAnneeScolaireDto> {
        const response = await this.anneeScolaireGetCurrentRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async anneeScolaireGetDisplayNameRaw(requestParameters: AnneeScolaireGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AnneeScolaire/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async anneeScolaireGetDisplayName(requestParameters: AnneeScolaireGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.anneeScolaireGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async anneeScolaireGetSearchCriteriasRaw(requestParameters: AnneeScolaireGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AnneeScolaire/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async anneeScolaireGetSearchCriterias(requestParameters: AnneeScolaireGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.anneeScolaireGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async anneeScolaireGetSelectItemsRaw(requestParameters: AnneeScolaireGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling anneeScolaireGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AnneeScolaire/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async anneeScolaireGetSelectItems(requestParameters: AnneeScolaireGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.anneeScolaireGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async anneeScolaireIsDeletableRaw(requestParameters: AnneeScolaireIsDeletableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AnneeScolaire/IsDeletable`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async anneeScolaireIsDeletable(requestParameters: AnneeScolaireIsDeletableRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.anneeScolaireIsDeletableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async anneeScolaireSaveRaw(requestParameters: AnneeScolaireSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbAnneeScolaireDetailDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AnneeScolaire`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbAnneeScolaireDetailDtoToJSON(requestParameters.FcbAnneeScolaireDetailDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbAnneeScolaireDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async anneeScolaireSave(requestParameters: AnneeScolaireSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbAnneeScolaireDetailDto> {
        const response = await this.anneeScolaireSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
