/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileUploadDto } from './FileUploadDto';
import {
    FileUploadDtoFromJSON,
    FileUploadDtoFromJSONTyped,
    FileUploadDtoToJSON,
} from './FileUploadDto';

/**
 * 
 * @export
 * @interface SiegeLieuSuiviFichierUploadDto
 */
export interface SiegeLieuSuiviFichierUploadDto {
    /**
     * 
     * @type {FileUploadDto}
     * @memberof SiegeLieuSuiviFichierUploadDto
     */
    file?: FileUploadDto;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviFichierUploadDto
     */
    idsiegeLieuSuiviFichier?: number;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviFichierUploadDto
     */
    idsiegeSocialLieuFormationSuivi?: number;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviFichierUploadDto
     */
    idfichier?: number;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviFichierUploadDto
     */
    idtypeSuiviFichier?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeLieuSuiviFichierUploadDto
     */
    fileName?: string | null;
}

/**
 * Check if a given object implements the SiegeLieuSuiviFichierUploadDto interface.
 */
export function instanceOfSiegeLieuSuiviFichierUploadDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SiegeLieuSuiviFichierUploadDtoFromJSON(json: any): SiegeLieuSuiviFichierUploadDto {
    return SiegeLieuSuiviFichierUploadDtoFromJSONTyped(json, false);
}

export function SiegeLieuSuiviFichierUploadDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiegeLieuSuiviFichierUploadDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'file': !exists(json, 'file') ? undefined : FileUploadDtoFromJSON(json['file']),
        'idsiegeLieuSuiviFichier': !exists(json, 'idsiegeLieuSuiviFichier') ? undefined : json['idsiegeLieuSuiviFichier'],
        'idsiegeSocialLieuFormationSuivi': !exists(json, 'idsiegeSocialLieuFormationSuivi') ? undefined : json['idsiegeSocialLieuFormationSuivi'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'idtypeSuiviFichier': !exists(json, 'idtypeSuiviFichier') ? undefined : json['idtypeSuiviFichier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
    };
}

export function SiegeLieuSuiviFichierUploadDtoToJSON(value?: SiegeLieuSuiviFichierUploadDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'file': FileUploadDtoToJSON(value.file),
        'idsiegeLieuSuiviFichier': value.idsiegeLieuSuiviFichier,
        'idsiegeSocialLieuFormationSuivi': value.idsiegeSocialLieuFormationSuivi,
        'idfichier': value.idfichier,
        'idtypeSuiviFichier': value.idtypeSuiviFichier,
        'fileName': value.fileName,
    };
}

