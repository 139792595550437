/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ERapportHopeType } from './ERapportHopeType';
import {
    ERapportHopeTypeFromJSON,
    ERapportHopeTypeFromJSONTyped,
    ERapportHopeTypeToJSON,
} from './ERapportHopeType';
import type { ErrorDetails } from './ErrorDetails';
import {
    ErrorDetailsFromJSON,
    ErrorDetailsFromJSONTyped,
    ErrorDetailsToJSON,
} from './ErrorDetails';
import type { ResultError } from './ResultError';
import {
    ResultErrorFromJSON,
    ResultErrorFromJSONTyped,
    ResultErrorToJSON,
} from './ResultError';

/**
 * 
 * @export
 * @interface ExposedTypes
 */
export interface ExposedTypes {
    /**
     * 
     * @type {ErrorDetails}
     * @memberof ExposedTypes
     */
    errorDetails?: ErrorDetails;
    /**
     * 
     * @type {ResultError}
     * @memberof ExposedTypes
     */
    errorResult?: ResultError;
    /**
     * 
     * @type {Array<ResultError>}
     * @memberof ExposedTypes
     */
    resultErrors?: Array<ResultError> | null;
    /**
     * 
     * @type {ERapportHopeType}
     * @memberof ExposedTypes
     */
    rapportTypes?: ERapportHopeType;
}

/**
 * Check if a given object implements the ExposedTypes interface.
 */
export function instanceOfExposedTypes(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExposedTypesFromJSON(json: any): ExposedTypes {
    return ExposedTypesFromJSONTyped(json, false);
}

export function ExposedTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExposedTypes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errorDetails': !exists(json, 'errorDetails') ? undefined : ErrorDetailsFromJSON(json['errorDetails']),
        'errorResult': !exists(json, 'errorResult') ? undefined : ResultErrorFromJSON(json['errorResult']),
        'resultErrors': !exists(json, 'resultErrors') ? undefined : (json['resultErrors'] === null ? null : (json['resultErrors'] as Array<any>).map(ResultErrorFromJSON)),
        'rapportTypes': !exists(json, 'rapportTypes') ? undefined : ERapportHopeTypeFromJSON(json['rapportTypes']),
    };
}

export function ExposedTypesToJSON(value?: ExposedTypes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errorDetails': ErrorDetailsToJSON(value.errorDetails),
        'errorResult': ResultErrorToJSON(value.errorResult),
        'resultErrors': value.resultErrors === undefined ? undefined : (value.resultErrors === null ? null : (value.resultErrors as Array<any>).map(ResultErrorToJSON)),
        'rapportTypes': ERapportHopeTypeToJSON(value.rapportTypes),
    };
}

