/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRechercheAgrementLocalisationDto
 */
export interface FcbRechercheAgrementLocalisationDto {
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheAgrementLocalisationDto
     */
    idplanAgrement?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheAgrementLocalisationDto
     */
    anneeAcademique?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheAgrementLocalisationDto
     */
    centre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheAgrementLocalisationDto
     */
    dateValidation?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheAgrementLocalisationDto
     */
    nbauditeurAgree?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheAgrementLocalisationDto
     */
    statutLocalisationAgrement?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheAgrementLocalisationDto
     */
    iduser?: number | null;
}

/**
 * Check if a given object implements the FcbRechercheAgrementLocalisationDto interface.
 */
export function instanceOfFcbRechercheAgrementLocalisationDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRechercheAgrementLocalisationDtoFromJSON(json: any): FcbRechercheAgrementLocalisationDto {
    return FcbRechercheAgrementLocalisationDtoFromJSONTyped(json, false);
}

export function FcbRechercheAgrementLocalisationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRechercheAgrementLocalisationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idplanAgrement': !exists(json, 'idplanAgrement') ? undefined : json['idplanAgrement'],
        'anneeAcademique': !exists(json, 'anneeAcademique') ? undefined : json['anneeAcademique'],
        'centre': !exists(json, 'centre') ? undefined : json['centre'],
        'dateValidation': !exists(json, 'dateValidation') ? undefined : json['dateValidation'],
        'nbauditeurAgree': !exists(json, 'nbauditeurAgree') ? undefined : json['nbauditeurAgree'],
        'statutLocalisationAgrement': !exists(json, 'statutLocalisationAgrement') ? undefined : json['statutLocalisationAgrement'],
        'iduser': !exists(json, 'iduser') ? undefined : json['iduser'],
    };
}

export function FcbRechercheAgrementLocalisationDtoToJSON(value?: FcbRechercheAgrementLocalisationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idplanAgrement': value.idplanAgrement,
        'anneeAcademique': value.anneeAcademique,
        'centre': value.centre,
        'dateValidation': value.dateValidation,
        'nbauditeurAgree': value.nbauditeurAgree,
        'statutLocalisationAgrement': value.statutLocalisationAgrement,
        'iduser': value.iduser,
    };
}

