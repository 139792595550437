/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbClasseHoraireRemplacementDto
 */
export interface FcbClasseHoraireRemplacementDto {
    /**
     * 
     * @type {number}
     * @memberof FcbClasseHoraireRemplacementDto
     */
    idremplacement?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseHoraireRemplacementDto
     */
    idclasseHoraire?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseHoraireRemplacementDto
     */
    idremplacant?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseHoraireRemplacementDto
     */
    reporte?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseHoraireRemplacementDto
     */
    idformateurLocalisationContrat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseHoraireRemplacementDto
     */
    idformateurLocalisationContratRemplace?: number | null;
}

/**
 * Check if a given object implements the FcbClasseHoraireRemplacementDto interface.
 */
export function instanceOfFcbClasseHoraireRemplacementDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbClasseHoraireRemplacementDtoFromJSON(json: any): FcbClasseHoraireRemplacementDto {
    return FcbClasseHoraireRemplacementDtoFromJSONTyped(json, false);
}

export function FcbClasseHoraireRemplacementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbClasseHoraireRemplacementDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idremplacement': !exists(json, 'idremplacement') ? undefined : json['idremplacement'],
        'idclasseHoraire': !exists(json, 'idclasseHoraire') ? undefined : json['idclasseHoraire'],
        'idremplacant': !exists(json, 'idremplacant') ? undefined : json['idremplacant'],
        'reporte': !exists(json, 'reporte') ? undefined : json['reporte'],
        'idformateurLocalisationContrat': !exists(json, 'idformateurLocalisationContrat') ? undefined : json['idformateurLocalisationContrat'],
        'idformateurLocalisationContratRemplace': !exists(json, 'idformateurLocalisationContratRemplace') ? undefined : json['idformateurLocalisationContratRemplace'],
    };
}

export function FcbClasseHoraireRemplacementDtoToJSON(value?: FcbClasseHoraireRemplacementDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idremplacement': value.idremplacement,
        'idclasseHoraire': value.idclasseHoraire,
        'idremplacant': value.idremplacant,
        'reporte': value.reporte,
        'idformateurLocalisationContrat': value.idformateurLocalisationContrat,
        'idformateurLocalisationContratRemplace': value.idformateurLocalisationContratRemplace,
    };
}

