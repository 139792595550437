/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbDegreDto
 */
export interface FcbDegreDto {
    /**
     * 
     * @type {string}
     * @memberof FcbDegreDto
     */
    idDegre?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDegreDto
     */
    idStade?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbDegreDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDegreDto
     */
    creationUser?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FcbDegreDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbDegreDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDegreDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbDegreDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDegreDto
     */
    degreUniqueId?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbDegreDto
     */
    displayCode?: string | null;
}

/**
 * Check if a given object implements the FcbDegreDto interface.
 */
export function instanceOfFcbDegreDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbDegreDtoFromJSON(json: any): FcbDegreDto {
    return FcbDegreDtoFromJSONTyped(json, false);
}

export function FcbDegreDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbDegreDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idDegre': !exists(json, 'idDegre') ? undefined : json['idDegre'],
        'idStade': !exists(json, 'idStade') ? undefined : json['idStade'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'degreUniqueId': !exists(json, 'degreUniqueId') ? undefined : json['degreUniqueId'],
        'displayCode': !exists(json, 'displayCode') ? undefined : json['displayCode'],
    };
}

export function FcbDegreDtoToJSON(value?: FcbDegreDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idDegre': value.idDegre,
        'idStade': value.idStade,
        'libelle': value.libelle,
        'creationUser': value.creationUser,
        'actif': value.actif,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'degreUniqueId': value.degreUniqueId,
        'displayCode': value.displayCode,
    };
}

