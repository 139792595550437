import * as React from "react";
import { ICustomButtonProps } from "./ICustomButtonProps";
import { Intent, Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ETLCodes } from "../../locales/ETLCodes";
import { useTl } from "../../hooks";

export interface IFusionButtonProps extends ICustomButtonProps {}

export const FusionButton: React.FunctionComponent<IFusionButtonProps> = ({
  minimal = false,
  onClick,
  loading,
  disabled,
  intent = Intent.PRIMARY
}) => {
  const { t } = useTl();
  return (
    <Button
      icon={IconNames.MERGE_COLUMNS}
      minimal={minimal}
      onClick={onClick}
      text={minimal ? null : t(ETLCodes.Fusion)}
      intent={intent}
      loading={loading}
      disabled={disabled}
      type={onClick ? "button" : "submit"}
    />
  );
};
