import { Classes, Dialog, NonIdealState } from "@blueprintjs/core";
import { startOfToday } from "date-fns";
import { FGDateMaskInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";

import { DashboardAbsenceDto, ETypeDernierAvertissement } from "../../../api";
import { FGWalterSelectInput, SmallFormGenerator } from "../../../components";
import { useCrudApi, useTl } from "../../../hooks";
import { ETLCodes } from "../../../locales";

const StyledDialog = styled(Dialog)`
  width: 500px;
  height: auto;
  background-color: white;
`;

const StyledNonIdealState = styled(NonIdealState)`
  margin-bottom: 1rem;
`;

export interface IDashboardAbsencesVuDialogProps {
  dialogOpen: boolean;
  onClose: (toSave?: DashboardAbsenceDto) => void;
  currentRow: DashboardAbsenceDto;
}

export const DashboardAbsencesVuDialog: React.FunctionComponent<IDashboardAbsencesVuDialogProps> = ({
  currentRow,
  dialogOpen,
  onClose
}) => {
  const { t } = useTl();
  const typesAvertissement = React.useMemo(
    () =>
      Object.values(ETypeDernierAvertissement).map(v => ({
        value: v,
        label: v
      })),
    []
  );

  const { data, saveItem, saving } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () => ({ ...currentRow, dateSuivi: startOfToday() }),
        saveApiFn: d => {
          onClose(d);
          return d;
        }
      }),
      [currentRow, onClose]
    )
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape({
        dateSuivi: Yup.date()
          .nullable()
          // .min(startOfToday(), t(ETLCodes.DateCannotBeInPast))
          .required(t(ETLCodes.Required)),
        typeAvertissement: Yup.string()
          .nullable()
          .required(t(ETLCodes.Required))
      }),
    [t]
  );

  return (
    <StyledDialog title={t(ETLCodes.AvertirConvoquerConfirmTitle)} isOpen={dialogOpen} onClose={() => onClose()}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose()}
          showDeleteButton={false}
          saving={saving}
          minLabelWidth={100}
          preventDefaultSaveOnEnter
        >
          <FieldGroup>
            <StyledNonIdealState description={t(ETLCodes.AvertirConvoquerConfirmMessage)} />
            <FGDateMaskInput name="dateSuivi" label={t(ETLCodes.DateAction)} />
            <FGWalterSelectInput name="typeAvertissement" items={typesAvertissement} label={t(ETLCodes.TypeAction)} />
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
