import { FieldSet } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { CarouselComparer, DoublonsDataCard, FO_FINAL_KEY, useDoublonsStateContext } from "..";
import { FormateurDoublonEditDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { nameof } from "../../../../../utils";

const Container = styled.div`
  height: 70vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export interface IDoublonFormateurAdministratifProps {}

export const DoublonFormateurAdministratif: React.FunctionComponent<IDoublonFormateurAdministratifProps> = props => {
  const { t } = useTl();
  const { data, getSelectedIndex } = useDoublonsStateContext();

  const selectedIndex = React.useMemo(() => getSelectedIndex(`${FO_FINAL_KEY}.idformateur`) ?? 0, [getSelectedIndex]);

  const formateurContainer = React.useCallback(
    (index: number, nb: number) => {
      const formateur: FormateurDoublonEditDto = data?.formateurs[index];
      if (!formateur) return;

      return (
        <FieldSet
          key={formateur.idformateur}
          title={
            <ViewButton
              text={t(ETLCodes.FormateurNb, { nb: nb, id: formateur.idformateur })}
              onClick={() => window.open(`#${ERoutes.formateur}/${formateur.idformateur}/signaletique/view`, "_blank")}
            />
          }
          titlePosition="center"
        >
          <DoublonsDataCard
            fieldPath={`formateurs[${index}].donneesAdministratives.idcivilite`}
            valuePath={`formateurs[${index}].donneesAdministratives.civilite`}
            label={t(ETLCodes.EtatCivil)}
            finalEntityKey={FO_FINAL_KEY}
            entityIdKey={nameof<FormateurDoublonEditDto>("idformateur")}
          />
          <DoublonsDataCard
            fieldPath={`formateurs[${index}].donneesAdministratives.precompte`}
            label={t(ETLCodes.Precompte)}
            finalEntityKey={FO_FINAL_KEY}
            entityIdKey={nameof<FormateurDoublonEditDto>("idformateur")}
          />
          <DoublonsDataCard
            fieldPath={`formateurs[${index}].donneesAdministratives.enfantCharge`}
            label={t(ETLCodes.EnfantACharge)}
            finalEntityKey={FO_FINAL_KEY}
            entityIdKey={nameof<FormateurDoublonEditDto>("idformateur")}
          />
          <DoublonsDataCard
            fieldPath={`formateurs[${index}].donneesAdministratives.conjointCharge`}
            label={t(ETLCodes.ConjointCharge)}
            finalEntityKey={FO_FINAL_KEY}
            entityIdKey={nameof<FormateurDoublonEditDto>("idformateur")}
          />
          <DoublonsDataCard
            fieldPath={`formateurs[${index}].donneesAdministratives.autreCharge`}
            label={t(ETLCodes.AutreACharge)}
            finalEntityKey={FO_FINAL_KEY}
            entityIdKey={nameof<FormateurDoublonEditDto>("idformateur")}
          />
          <DoublonsDataCard
            fieldPath={`formateurs[${index}].donneesAdministratives.enfantHandicape`}
            label={t(ETLCodes.EnfantHandicape)}
            finalEntityKey={FO_FINAL_KEY}
            entityIdKey={nameof<FormateurDoublonEditDto>("idformateur")}
          />
          <DoublonsDataCard
            fieldPath={`formateurs[${index}].donneesAdministratives.autreHandicape`}
            label={t(ETLCodes.AutreHandicape)}
            finalEntityKey={FO_FINAL_KEY}
            entityIdKey={nameof<FormateurDoublonEditDto>("idformateur")}
          />
          <DoublonsDataCard
            fieldPath={`formateurs[${index}].donneesAdministratives.ancienMatricule`}
            label={t(ETLCodes.Precompte)}
            finalEntityKey={FO_FINAL_KEY}
            entityIdKey={nameof<FormateurDoublonEditDto>("idformateur")}
          />
          <DoublonsDataCard
            fieldPath={`formateurs[${index}].donneesAdministratives.datePremierContrat`}
            label={t(ETLCodes.DateEntreeIfapme)}
            finalEntityKey={FO_FINAL_KEY}
            entityIdKey={nameof<FormateurDoublonEditDto>("idformateur")}
          />
          <DoublonsDataCard
            fieldPath={`formateurs[${index}].donneesAdministratives.jure`}
            label={t(ETLCodes.Jure)}
            finalEntityKey={FO_FINAL_KEY}
            entityIdKey={nameof<FormateurDoublonEditDto>("idformateur")}
          />
        </FieldSet>
      );
    },
    [data?.formateurs, t]
  );

  return (
    <Container>
      <CarouselComparer leftElement={formateurContainer(selectedIndex, selectedIndex + 1)}>
        {data?.formateurs?.length > 1 &&
          data?.formateurs
            ?.filter((a, i) => i !== selectedIndex)
            ?.map((a, i) => formateurContainer(i + (i >= selectedIndex ? 1 : 0), i + (i >= selectedIndex ? 2 : 1)))}
      </CarouselComparer>
    </Container>
  );
};
