/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EErrorCode } from './EErrorCode';
import {
    EErrorCodeFromJSON,
    EErrorCodeFromJSONTyped,
    EErrorCodeToJSON,
} from './EErrorCode';
import type { EValidationError } from './EValidationError';
import {
    EValidationErrorFromJSON,
    EValidationErrorFromJSONTyped,
    EValidationErrorToJSON,
} from './EValidationError';

/**
 * 
 * @export
 * @interface ErrorDetails
 */
export interface ErrorDetails {
    /**
     * 
     * @type {number}
     * @memberof ErrorDetails
     */
    statusCode?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorDetails
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ErrorDetails
     */
    stackTrace?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ErrorDetails
     */
    exceptionType?: string | null;
    /**
     * 
     * @type {EErrorCode}
     * @memberof ErrorDetails
     */
    errorCode?: EErrorCode;
    /**
     * 
     * @type {{ [key: string]: Array<EValidationError>; }}
     * @memberof ErrorDetails
     */
    validationErrors?: { [key: string]: Array<EValidationError>; } | null;
}

/**
 * Check if a given object implements the ErrorDetails interface.
 */
export function instanceOfErrorDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ErrorDetailsFromJSON(json: any): ErrorDetails {
    return ErrorDetailsFromJSONTyped(json, false);
}

export function ErrorDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statusCode': !exists(json, 'statusCode') ? undefined : json['statusCode'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'stackTrace': !exists(json, 'stackTrace') ? undefined : json['stackTrace'],
        'exceptionType': !exists(json, 'exceptionType') ? undefined : json['exceptionType'],
        'errorCode': !exists(json, 'errorCode') ? undefined : EErrorCodeFromJSON(json['errorCode']),
        'validationErrors': !exists(json, 'validationErrors') ? undefined : json['validationErrors'],
    };
}

export function ErrorDetailsToJSON(value?: ErrorDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'statusCode': value.statusCode,
        'message': value.message,
        'stackTrace': value.stackTrace,
        'exceptionType': value.exceptionType,
        'errorCode': EErrorCodeToJSON(value.errorCode),
        'validationErrors': value.validationErrors,
    };
}

