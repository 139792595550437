import * as React from "react";

import { useTheme } from "../hooks";

export interface IAppThemeProps {}

export const AppTheme: React.FunctionComponent<IAppThemeProps> = props => {
  const { theme } = useTheme();
  React.useEffect(() => {
    const head = document.head;
    const link = document.createElement("link");

    link.type = "text/css";
    link.rel = "stylesheet";
    switch (theme.siteTheme) {
      case "walter":
        link.href = "./BlueprintWalter.css";
        break;
      case "test-walter":
        link.href = "./BlueprintWalterTest.css";
        break;
      case "hope":
        link.href = "./BlueprintHope.css";
        break;
      case "test-hope":
        link.href = "./BlueprintHopeTest.css";
        break;
      default:
        link.href = "./BlueprintWalter.css";
        break;
    }

    // Insert Blueprint Custom CSS BEFORE App CSS for it to work
    const appStyle = head.querySelector('[data-styled="active"]');
    head.insertBefore(link, appStyle);

    return () => {
      head.removeChild(link);
    };
  }, [theme]);
  return null;
};
