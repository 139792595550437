import * as React from "react";
import { FormateurHoraireGrid } from "./FormateurHoraireGrid";
import { FormateurHoraireIncoherenceGrid } from "./FormateurHoraireIncoherenceGrid";

export interface IFormateurHoraireProps {}

export const FormateurHoraire: React.FunctionComponent<IFormateurHoraireProps> = () => {
  return (
    <>
      <FormateurHoraireGrid />
      <FormateurHoraireIncoherenceGrid />
    </>
  );
};
