import { FGCustomPanel, FGEmpty, FGMaskInput, FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { DirectionTerritorialeApi, FcbDirectionTerritorialeDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  CommuneSelect,
  FGWalterSelectInput,
  LocaliteSelect,
  SmallFormGenerator,
  FGWalterCheckboxInput
} from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

interface IDirectionTerritorialeDetailPageProps {
  idDirection: number;
  editMode: boolean;
}

export const DirectionTerritorialeDetailPage: React.FunctionComponent<IDirectionTerritorialeDetailPageProps> = ({
  idDirection,
  editMode
}) => {
  const { t } = useTl();
  const api = useApiService(DirectionTerritorialeApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idDirection <= 0
        ? FcbDirectionTerritorialeDtoFromJSON({ idDirection: 0 })
        : api.directionTerritorialeGet({ id: +idDirection }),
    saveApiFn: d => api.directionTerritorialeSave({ FcbDirectionTerritorialeDto: d }),
    onSavedRoute: d => `${ERoutes.directionTerritoriale}/${d.iddirectionTerritoriale}/detail/edit`,
    deleteApiFn: d => api.directionTerritorialeDelete({ id: d.iddirectionTerritoriale }),
    onDeletedRoute: () => ERoutes.directionTerritoriale
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      code: Yup.string()
        .required(t(ETLCodes.Required))
        .test("code-not-exists", t(ETLCodes.CodeExistsError), async value => {
          if (value === data?.code) return true;
          const validationError = await api.directionTerritorialeDirectionTerritorialeCodeExists({
            id: idDirection,
            code: value
          });
          return validationError.isValid;
        }),
      nom: Yup.string().required(t(ETLCodes.Required)),
      codePays: Yup.string().required(t(ETLCodes.Required)),
      email: Yup.string().email(t(ETLCodes.InvalidEmail))
    });
  }, [t, idDirection, api, data]);

  const [pays, loadingPays] = useReferential(a => a.referentialGetPays(), false, []);

  const Localite = formik => {
    return <LocaliteSelect codePostal={formik.values.codePostal} codePays={formik.values.codePays} name={"localite"}
      codePostalTextName="codePostal"
      disableIfNoCp={false} />;
  };

  const Commune = formik => {
    return <CommuneSelect codePostal={formik.values.codePostal} codePays={formik.values.codePays} name={"commune"} />;
  };

  return (
    <>
      {data && (
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={editMode}
          validationSchema={FormSchema}
          loading={loading}
          onCancel={() => history.push(ERoutes.directionTerritoriale)}
          onDelete={deleteItem}
          showDeleteButton={+idDirection > 0}
          saving={saving}
          deleting={deleting}
        >
          <FieldGroup columns={[3, 9]}>
            <FGTextInput name="code" maxLength={1} label={t(ETLCodes.Code)} forceCase="upper" />
            <FGEmpty />
          </FieldGroup>
          <FieldGroup columns={[5, 7]}>
            <FGTextInput name="nom" maxLength={100} label={t(ETLCodes.Nom)} />
            <FGEmpty />
          </FieldGroup>
          <FieldGroup columns={2}>
            <FGWalterSelectInput name="codePays" loading={loadingPays} label={t(ETLCodes.Pays)} items={pays} />
            <FGEmpty />
            <FGTextAreaInput name="adresse" maxLength={100} label={t(ETLCodes.Adresse)} />
            <FGEmpty />
          </FieldGroup>
          <FieldGroup columns={[3, 9]}>
            <FGTextInput name="codePostal" maxLength={6} label={t(ETLCodes.CodePostal)} />
          </FieldGroup>
          <FieldGroup columns={2}>
            <FGCustomPanel>{ctx => Localite(ctx.formik)}</FGCustomPanel>
            <FGCustomPanel>{ctx => Commune(ctx.formik)}</FGCustomPanel>
            <FGTextInput name="telephone" maxLength={20} label={t(ETLCodes.Tel)} />
            <FGTextInput name="fax" maxLength={20} label={t(ETLCodes.Fax)} />
            <FGTextInput name="directeur" maxLength={100} label={t(ETLCodes.Directeur)} />
            <FGTextInput name="email" maxLength={100} label={t(ETLCodes.General_Email)} />
            <FGTextInput name="noCompte" maxLength={20} label={t(ETLCodes.NumCompte)} />
            <FGEmpty />
            <FGMaskInput name="fraisDossier" label={t(ETLCodes.FraisDossier)} cleaveOptions={{ numeral: true }} />
            <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
          </FieldGroup>
        </SmallFormGenerator>
      )}
    </>
  );
};
