import { DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";

import {
  ApprenantRepresentantsSearch,
  FcbRepresentantApprenantsliesDto,
  RepresentantApprenantLiesApi
} from "../../../../api";
import { ApprenantTooltip } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface IRepresentantApprenantLiesListPageProps {}

export const RepresentantApprenantLiesListPage: React.FunctionComponent<IRepresentantApprenantLiesListPageProps> = () => {
  const { id } = useParams<{ id: string; state: string }>();
  const idRepresentant = React.useMemo(() => +id, [id]);
  const { t } = useTl();
  const api = useApiService(RepresentantApprenantLiesApi);

  const tableState = useGridState<any>({
    sortKeys: { nom: "ASC" },
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15
  });

  const searchFunction = React.useCallback(
    (sObj?: ApprenantRepresentantsSearch) => {
      sObj.idrepresentant = +idRepresentant;
      return api.representantApprenantLiesBaseSearch({ ApprenantRepresentantsSearch: sObj });
    },
    [api, idRepresentant]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });
  const { totalCount } = tableState;
  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.Identifiant),
        computed: true,
        fieldName: "info",
        render: (value: FcbRepresentantApprenantsliesDto) => <ApprenantTooltip idApprenant={value.idapprenant} />,
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.LienParente),
        fieldName: "lienParente"
      }
    ],
    [t]
  );

  return (
    <FieldSet title={t(ETLCodes.TableResults, { count: totalCount })}>
      <DataTable dateFormat="dd/MM/yyyy" tableState={tableState} loading={loading} columns={columns}></DataTable>
    </FieldSet>
  );
};
