import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { SiegeSocialSuiviItem, SiegeSocialSuiviList } from ".";
import { ERoutes } from "../../../../../AppRouter";

export interface ISiegeSocialSuiviSwitchProps {}

export const SiegeSocialSuiviSwitch: React.FunctionComponent<ISiegeSocialSuiviSwitchProps> = props => {
  const path = React.useMemo(() => `${ERoutes.siegeSocial}/:id/suivi/:state?`, []);
  return (
    <Switch>
      <Route path={`${path}`} component={SiegeSocialSuiviList} exact />
      <Route path={`${path}/:subId?`} component={SiegeSocialSuiviItem} />
    </Switch>
  );
};
