import { useQuery } from "react-query";
import { Configuration, SystemApi } from "../../api";
import { baseUrl } from "../../apiConfig";

export function useFrontendSettings() {
  const { data: frontendSettings, isFetching: loadingFrontendSettings, refetch: refreshFrontendSettings } = useQuery(
    "fe-settings",
    async () => {
      const api = new SystemApi(new Configuration({
        basePath: baseUrl
      }));
      
      return api.systemGeFrontendSettings();
    },
    {
      enabled: true,
      staleTime: Infinity
    }
  );

  return {
    frontendSettings,
    loadingFrontendSettings,
    refreshFrontendSettings
  };
}

