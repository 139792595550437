/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbApprenantPresenceDetailSaveDto
 */
export interface FcbApprenantPresenceDetailSaveDto {
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantPresenceDetailSaveDto
     */
    idInscription?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantPresenceDetailSaveDto
     */
    statut?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantPresenceDetailSaveDto
     */
    idClasseHoraire?: number;
}

/**
 * Check if a given object implements the FcbApprenantPresenceDetailSaveDto interface.
 */
export function instanceOfFcbApprenantPresenceDetailSaveDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbApprenantPresenceDetailSaveDtoFromJSON(json: any): FcbApprenantPresenceDetailSaveDto {
    return FcbApprenantPresenceDetailSaveDtoFromJSONTyped(json, false);
}

export function FcbApprenantPresenceDetailSaveDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbApprenantPresenceDetailSaveDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idInscription': !exists(json, 'idInscription') ? undefined : json['idInscription'],
        'statut': !exists(json, 'statut') ? undefined : json['statut'],
        'idClasseHoraire': !exists(json, 'idClasseHoraire') ? undefined : json['idClasseHoraire'],
    };
}

export function FcbApprenantPresenceDetailSaveDtoToJSON(value?: FcbApprenantPresenceDetailSaveDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idInscription': value.idInscription,
        'statut': value.statut,
        'idClasseHoraire': value.idClasseHoraire,
    };
}

