import { FGEmpty, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { FcbTypeFinancementDtoFromJSON, TypeFinancementApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterCheckboxInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

interface ITypeFinancementDetailPageProps {}

export const TypeFinancementDetailPage: React.FunctionComponent<ITypeFinancementDetailPageProps> = () => {
  const { t } = useTl();
  const api = useApiService(TypeFinancementApi);
  const { id, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const idTypeFinancement = React.useMemo(() => +id, [id]);
  const editMode = React.useMemo(() => state === "edit", [state]);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      libelle: Yup.string().required(t(ETLCodes.Required)),
      code: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idTypeFinancement <= 0
        ? FcbTypeFinancementDtoFromJSON({ idTypeFinancement: 0 })
        : api.typeFinancementGet({ id: +idTypeFinancement }),
    saveApiFn: d => api.typeFinancementSave({ FcbTypeFinancementDto: d }),
    onSavedRoute: d => `${ERoutes.typeFinancement}/${d.idfinancementType}/detail/${state}`,
    deleteApiFn: d => api.typeFinancementDelete({ id: d.idfinancementType }),
    onDeletedRoute: () => ERoutes.typeFinancement
  });

  return (
    data && (
      <>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={editMode}
          validationSchema={FormSchema}
          loading={loading}
          onCancel={() => history.push(ERoutes.typeFinancement)}
          onDelete={deleteItem}
          showDeleteButton={+idTypeFinancement > 0}
          saving={saving}
          deleting={deleting}
        >
          <FieldGroup columns={2}>
            <FGTextInput name="code" maxLength={50} label={t(ETLCodes.Code)} forceCase="upper" />
            <FGTextInput name="libelle" maxLength={50} label={t(ETLCodes.Libelle)} />
            <FGEmpty />
            <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
          </FieldGroup>
        </SmallFormGenerator>
      </>
    )
  );
};
