import { Tab, Tabs, Text } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";

import { ApprenantBulletinDetailList } from ".";
import { BulletinApprenantRecaputilatifApi } from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import { useApiService, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

export interface IApprenantBulletinTabsProps {}

const StyledText = styled(Text)`
  align-self: center;
  font-weight: bold;
`;

export const ApprenantBulletinTabs: React.FunctionComponent<IApprenantBulletinTabsProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id, anneeTab, coursTypeTab } = useParams<{
    id: string;
    anneeTab: string;
    coursTypeTab: string;
  }>();
  const idApprenant = React.useMemo(() => +id, [id]);
  const api = useApiService(BulletinApprenantRecaputilatifApi);

  const [cours] = useApiEffect(async () => {
    return await api.bulletinApprenantRecaputilatifGetListTypeCoursInscription({
      id: idApprenant,
      anneeScolaire: anneeTab
    });
  }, [idApprenant, anneeTab]);

  const [coursType, setCoursType] = React.useState("");

  React.useEffect(() => {
    if (coursTypeTab || cours) setCoursType(coursTypeTab ?? cours[0]);
  }, [cours, coursType, coursTypeTab]);

  return (
    cours &&
    cours.length > 0 && (
      <Tabs
        id="TabsApprenantResultatType"
        onChange={newTabId => {
          history.push(`${ERoutes.apprenant}/${idApprenant}/resultats/${anneeTab}/${newTabId}`);
        }}
        selectedTabId={coursType}
        renderActiveTabPanelOnly
        large
      >
        <StyledText>{t(ETLCodes.CourseType)} : </StyledText>
        {cours.map(c => {
          return (
            <Tab
              id={c}
              title={c}
              panel={<ApprenantBulletinDetailList idApprenant={idApprenant} anneeScolaire={anneeTab} idTypeCours={c} />}
              panelClassName="full-width-panel"
            />
          );
        })}
      </Tabs>
    )
  );
};
