/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AcquisIfapmeEditDto
 */
export interface AcquisIfapmeEditDto {
    /**
     * 
     * @type {number}
     * @memberof AcquisIfapmeEditDto
     */
    idtitreDiplomeCertificationIfapme?: number;
    /**
     * 
     * @type {string}
     * @memberof AcquisIfapmeEditDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AcquisIfapmeEditDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the AcquisIfapmeEditDto interface.
 */
export function instanceOfAcquisIfapmeEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AcquisIfapmeEditDtoFromJSON(json: any): AcquisIfapmeEditDto {
    return AcquisIfapmeEditDtoFromJSONTyped(json, false);
}

export function AcquisIfapmeEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcquisIfapmeEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idtitreDiplomeCertificationIfapme': !exists(json, 'idtitreDiplomeCertificationIfapme') ? undefined : json['idtitreDiplomeCertificationIfapme'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function AcquisIfapmeEditDtoToJSON(value?: AcquisIfapmeEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idtitreDiplomeCertificationIfapme': value.idtitreDiplomeCertificationIfapme,
        'description': value.description,
        'actif': value.actif,
    };
}

