import * as React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { useAbortableApiServiceFactory, useApiService } from "../../../../../hooks/useApiService";
import { ETLCodes } from "../../../../../locales";
import { FcbSecteurGridDto, SecteurApi, SecteurSearch, EBooleanSearchTypes } from "../../../../../api";

interface ISecteurMetierListPageProps {}

const Container = styled.div`
  display: flex;
`;

export const SecteurMetierListPage: React.FunctionComponent<ISecteurMetierListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(SecteurApi);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbSecteurGridDto) => (
          <Container>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.secteurMetier}/${row.codesecteur}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.secteurMetier}/${row.codesecteur}/detail/edit`)}
            />
          </Container>
        )
      },
      {
        header: () => t(ETLCodes.CodeSecteur),
        fieldName: "codesecteur",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "description"
      }
    ],
    [history, t]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.secteurMetier}/-1/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(() => api.secteurGetSearchCriterias({ includeListsValues: true }), [api]);

  const apiFactory = useAbortableApiServiceFactory(SecteurApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: SecteurSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.secteurBaseSearch({ SecteurSearch: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      sortKeys={{ codesecteur: "ASC" }}
      columns={columns}
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      addFunc={addItemFunction}
      breadCrumbs={[{ text: t(ETLCodes.SecteursMetiers) }]}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
    />
  );
};
