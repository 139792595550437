/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbExportGapDto,
  FcbRechercheExportGapDtoPaginatedResults,
  FilterCriteriaInfo,
  RechercheExportGapSearch,
  SelectItem,
} from '../models/index';
import {
    FcbExportGapDtoFromJSON,
    FcbExportGapDtoToJSON,
    FcbRechercheExportGapDtoPaginatedResultsFromJSON,
    FcbRechercheExportGapDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    RechercheExportGapSearchFromJSON,
    RechercheExportGapSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface ExportGapBaseSearchRequest {
    RechercheExportGapSearch?: RechercheExportGapSearch;
}

export interface ExportGapDeleteRequest {
    id?: number;
}

export interface ExportGapGetRequest {
    id?: number;
}

export interface ExportGapGetDisplayNameRequest {
    id?: number;
}

export interface ExportGapGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ExportGapGetSelectItemsRequest {
    searchField: string;
}

export interface ExportGapSaveRequest {
    FcbExportGapDto?: FcbExportGapDto;
}

/**
 * 
 */
export class ExportGapApi extends runtime.BaseAPI {

    /**
     */
    async exportGapBaseSearchRaw(requestParameters: ExportGapBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRechercheExportGapDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportGap/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RechercheExportGapSearchToJSON(requestParameters.RechercheExportGapSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRechercheExportGapDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async exportGapBaseSearch(requestParameters: ExportGapBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRechercheExportGapDtoPaginatedResults> {
        const response = await this.exportGapBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportGapDeleteRaw(requestParameters: ExportGapDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportGap`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async exportGapDelete(requestParameters: ExportGapDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.exportGapDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportGapGetRaw(requestParameters: ExportGapGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbExportGapDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportGap`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbExportGapDtoFromJSON(jsonValue));
    }

    /**
     */
    async exportGapGet(requestParameters: ExportGapGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbExportGapDto> {
        const response = await this.exportGapGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportGapGetDisplayNameRaw(requestParameters: ExportGapGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportGap/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async exportGapGetDisplayName(requestParameters: ExportGapGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.exportGapGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportGapGetSearchCriteriasRaw(requestParameters: ExportGapGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportGap/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async exportGapGetSearchCriterias(requestParameters: ExportGapGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.exportGapGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportGapGetSelectItemsRaw(requestParameters: ExportGapGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling exportGapGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportGap/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async exportGapGetSelectItems(requestParameters: ExportGapGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.exportGapGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportGapSaveRaw(requestParameters: ExportGapSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbExportGapDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportGap`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbExportGapDtoToJSON(requestParameters.FcbExportGapDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbExportGapDtoFromJSON(jsonValue));
    }

    /**
     */
    async exportGapSave(requestParameters: ExportGapSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbExportGapDto> {
        const response = await this.exportGapSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
