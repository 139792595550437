import { Intent } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { EContentHidingMode, FcbSuiviApprenantGridDto, SuiviApi, SuiviSearchDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { AddButton, EditButton, ViewButton } from "../../../../../components";
import { useAuth } from "../../../../../contexts";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

interface IApprenantSuiviListPageProps {
  apprenantId: number;
}

export const ApprenantSuiviListPage: React.FunctionComponent<IApprenantSuiviListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const apprenantId = React.useMemo(() => +id, [id]);
  const api = useApiService(SuiviApi);
  const path = React.useMemo(() => `${ERoutes.apprenant}/${apprenantId}/suiviApprenant`, [apprenantId]);
  const tableState = useGridState<any>({
    sortKeys: { dateSuivi: "DESC" },
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: SuiviSearchDto) => {
      sObj.idapprenant = apprenantId;
      return api.suiviSearchSuiviHopeByApprenant({ SuiviSearchDto: sObj });
    },
    [api, apprenantId]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const user = useAuth().user;

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbSuiviApprenantGridDto) =>
          ((row.fromHope && user.ifapmeSide.includes("Hope")) ||
            (!row.fromHope && user.ifapmeSide.includes("Walter"))) && (
            <ButtonContainer>
              <ViewButton minimal={true} onClick={() => history.push(`${path}/${row.idsuiviApprenant}/view`)} />

              <EditButton
                disabled={row.contentHidingMode !== EContentHidingMode.ALL && row.creationUser !== user.iduser}
                minimal={true}
                onClick={() => history.push(`${path}/${row.idsuiviApprenant}/edit`)}
              />
            </ButtonContainer>
          )
      },
      {
        header: () => t(ETLCodes.Date),
        fieldName: "dateSuivi"
      },
      {
        header: () => t(ETLCodes.ContenuCachePour),
        fieldName: "contentHidingMode",
        render: (row: FcbSuiviApprenantGridDto) => {
          switch (row.contentHidingMode) {
            case EContentHidingMode.NONE:
              return t(ETLCodes.Personne);
            case EContentHidingMode.OTHERS:
              return t(ETLCodes.Services);
            case EContentHidingMode.ALL:
              return t(ETLCodes.Tous);
          }
        }
      },
      {
        header: () => t(ETLCodes.Utilisateur),
        fieldName: "utilisateur"
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      },
      {
        header: () => t(ETLCodes.Lieu),
        fieldName: "lieu"
      },
      {
        header: () => t(ETLCodes.Classe),
        fieldName: "classe"
      },
      {
        header: () => t(ETLCodes.Categorie),
        fieldName: "types",
        render: (row: FcbSuiviApprenantGridDto) => (
          <>{row.types.length > 0 ? row.types.map(type => type).join(", ") : ""}</>
        )
      }
    ],
    [history, path, t, user.iduser, user.ifapmeSide]
  );

  return (
    <>
      <FieldSet
        title={t(ETLCodes.TableResults, { count: totalCount })}
        rightElement={
          <AddButton
            onClick={e => {
              history.push(`${path}/0/edit`);
            }}
            text={t(ETLCodes.General_Add)}
            intent={Intent.PRIMARY}
          />
        }
      >
        <DataTable
          dateFormat="dd/MM/yyyy"
          tableState={tableState}
          loading={loading}
          columns={columns}
          filterMode="OnEnter"
        ></DataTable>
      </FieldSet>
    </>
  );
};
