import * as React from "react";
import { useTl, useApiService } from "../../../../hooks";
import { useHistory } from "react-router";
import { ButtonContainer, IDataTableColumn } from "nsitools-react";
import { ViewButton, EditButton, SearchTablePage } from "../../../../components";
import { ERoutes } from "../../../../AppRouter";
import { ETLCodes } from "../../../../locales";
import { FcbRechercheExportGapDto, ExportGapApi, RechercheExportGapSearch } from "../../../../api";
import { Checkbox } from "@blueprintjs/core";

export interface IExportationGapListPageProps {}

export const ExportationGapListPage: React.FunctionComponent<IExportationGapListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ExportGapApi);
  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbRechercheExportGapDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.exportationGap}/${row.idexport}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.exportationGap}/${row.idexport}/detail/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Numero),
        fieldName: "idexport"
      },
      {
        header: () => t(ETLCodes.Date),
        fieldName: "date"
      },
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "centre"
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      },
      {
        header: () => t(ETLCodes.Valide),
        fieldName: "valide",
        autofitcontent: true,
        alignment: "center",
        render: (row: FcbRechercheExportGapDto) => <Checkbox disabled checked={row.valide} />
      }
    ],
    [history, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.exportationGap}/0/detail/edit`);
  }, [history]);

  return (
    <SearchTablePage
      getCriteriasFunction={() => api.exportGapGetSearchCriterias({ includeListsValues: true })}
      searchFunction={(sObj?: RechercheExportGapSearch) => api.exportGapBaseSearch({ RechercheExportGapSearch: sObj })}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.ExportationsGAP), route: ERoutes.exportationGap }]}
      addFunc={onAddItem}
      sortKeys={{ idexport: "ASC" }}
    />
  );
};
