import { Classes, IconName, Intent, MaybeElement } from "@blueprintjs/core";
import { ToolTipButton } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { useTheme } from "../../hooks";
import { IThemeData } from "../../theme";
import { ICustomButtonProps } from "./ICustomButtonProps";

const StyledButton = styled(ToolTipButton)<{ theme: IThemeData }>`
  color: ${props => props.theme.primaryColor} !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  min-height: 0 !important;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    box-shadow: none !important;
  }

  &:focus {
    outline: none !important;
  }

  & .${Classes.ICON} {
    color: ${props => props.theme.primaryColor} !important;
  }
`;

export interface ILinkButtonProps extends Omit<ICustomButtonProps, "minimal" | "intent"> {
  icon?: IconName | MaybeElement;
  rightIcon?: IconName | MaybeElement;
  className?: string;
  tooltip?: string;
}

export const LinkButton: React.FunctionComponent<ILinkButtonProps> = ({
  onClick,
  loading,
  disabled,
  text,
  icon,
  rightIcon,
  className,
  tooltip
}) => {
  const { theme } = useTheme();
  return (
    <StyledButton
      theme={theme}
      icon={icon}
      tooltip={tooltip}
      minimal={false}
      onClick={onClick}
      text={text}
      intent={Intent.NONE}
      loading={loading}
      disabled={disabled}
      rightIcon={rightIcon}
      className={className}
    />
  );
};
