/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbAcquisScolaireGridDto
 */
export interface FcbAcquisScolaireGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbAcquisScolaireGridDto
     */
    idacquis?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbAcquisScolaireGridDto
     */
    idresultat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbAcquisScolaireGridDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbAcquisScolaireGridDto
     */
    certificat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbAcquisScolaireGridDto
     */
    annee?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbAcquisScolaireGridDto
     */
    acquis?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbAcquisScolaireGridDto
     */
    resultat?: string | null;
}

/**
 * Check if a given object implements the FcbAcquisScolaireGridDto interface.
 */
export function instanceOfFcbAcquisScolaireGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbAcquisScolaireGridDtoFromJSON(json: any): FcbAcquisScolaireGridDto {
    return FcbAcquisScolaireGridDtoFromJSONTyped(json, false);
}

export function FcbAcquisScolaireGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbAcquisScolaireGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idacquis': !exists(json, 'idacquis') ? undefined : json['idacquis'],
        'idresultat': !exists(json, 'idresultat') ? undefined : json['idresultat'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'certificat': !exists(json, 'certificat') ? undefined : json['certificat'],
        'annee': !exists(json, 'annee') ? undefined : json['annee'],
        'acquis': !exists(json, 'acquis') ? undefined : json['acquis'],
        'resultat': !exists(json, 'resultat') ? undefined : json['resultat'],
    };
}

export function FcbAcquisScolaireGridDtoToJSON(value?: FcbAcquisScolaireGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idacquis': value.idacquis,
        'idresultat': value.idresultat,
        'idapprenant': value.idapprenant,
        'certificat': value.certificat,
        'annee': value.annee,
        'acquis': value.acquis,
        'resultat': value.resultat,
    };
}

