/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChefEntrepriseEditDto
 */
export interface ChefEntrepriseEditDto {
    /**
     * 
     * @type {number}
     * @memberof ChefEntrepriseEditDto
     */
    idchefEntreprise?: number;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseEditDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseEditDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseEditDto
     */
    codesexe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseEditDto
     */
    nationalite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseEditDto
     */
    codePaysNaissance?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ChefEntrepriseEditDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseEditDto
     */
    registreNational?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseEditDto
     */
    numeroIdentification?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ChefEntrepriseEditDto
     */
    idpersonne?: number | null;
}

/**
 * Check if a given object implements the ChefEntrepriseEditDto interface.
 */
export function instanceOfChefEntrepriseEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChefEntrepriseEditDtoFromJSON(json: any): ChefEntrepriseEditDto {
    return ChefEntrepriseEditDtoFromJSONTyped(json, false);
}

export function ChefEntrepriseEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChefEntrepriseEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idchefEntreprise': !exists(json, 'idchefEntreprise') ? undefined : json['idchefEntreprise'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'codesexe': !exists(json, 'codesexe') ? undefined : json['codesexe'],
        'nationalite': !exists(json, 'nationalite') ? undefined : json['nationalite'],
        'codePaysNaissance': !exists(json, 'codePaysNaissance') ? undefined : json['codePaysNaissance'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'registreNational': !exists(json, 'registreNational') ? undefined : json['registreNational'],
        'numeroIdentification': !exists(json, 'numeroIdentification') ? undefined : json['numeroIdentification'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
    };
}

export function ChefEntrepriseEditDtoToJSON(value?: ChefEntrepriseEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idchefEntreprise': value.idchefEntreprise,
        'nom': value.nom,
        'prenom': value.prenom,
        'codesexe': value.codesexe,
        'nationalite': value.nationalite,
        'codePaysNaissance': value.codePaysNaissance,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'registreNational': value.registreNational,
        'numeroIdentification': value.numeroIdentification,
        'idpersonne': value.idpersonne,
    };
}

