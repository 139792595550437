import { Button, Callout } from "@blueprintjs/core";
import { FieldSet } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import {
  AP_FINAL_KEY,
  CarouselComparer,
  DoublonsDataCard,
  DoublonsMultipleDataCard,
  useDoublonsStateContext
} from "..";
import { ApprenantDoublonEditDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { nameof } from "../../../../../utils";

const Container = styled.div`
  height: 70vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export interface IDoublonApprenantSignaletiqueProps {}

export const DoublonApprenantSignaletique: React.FunctionComponent<IDoublonApprenantSignaletiqueProps> = props => {
  const { t } = useTl();
  const { data, selectData, getSelectedIndex } = useDoublonsStateContext();

  const selectedIndex = React.useMemo(() => getSelectedIndex(`${AP_FINAL_KEY}.idapprenant`) ?? 0, [getSelectedIndex]);

  const nbPri = React.useMemo(() => data?.apprenants?.filter(a => !!a.idpri)?.length, [data?.apprenants]);
  const nbPriContratsEnCours = React.useMemo(() => data?.apprenants?.filter(a => !!a.idpricontratEnCours)?.length, [
    data?.apprenants
  ]);
  const priFromApprenant = React.useMemo(() => nbPri === 0 || nbPri === 1 || nbPriContratsEnCours === 0, [
    nbPri,
    nbPriContratsEnCours
  ]);

  const nbAri = React.useMemo(() => data?.apprenants?.filter(a => !!a.idari)?.length, [data?.apprenants]);
  const nbAriContratsEnCours = React.useMemo(() => data?.apprenants?.filter(a => !!a.idaricontratEnCours)?.length, [
    data?.apprenants
  ]);
  const ariFromApprenant = React.useMemo(() => nbAri === 0 || nbAri === 1 || nbAriContratsEnCours === 0, [
    nbAri,
    nbAriContratsEnCours
  ]);

  const apprenantContainer = React.useCallback(
    (index: number, nb: number) => {
      const apprenant: ApprenantDoublonEditDto = data?.apprenants[index];
      if (!apprenant) return;

      return (
        <FieldSet
          key={apprenant.idapprenant}
          title={
            <TitleContainer>
              {index !== selectedIndex ? (
                <Button
                  text={t(ETLCodes.Conserver)}
                  icon={"arrow-left"}
                  onClick={() => selectData(`${AP_FINAL_KEY}.idapprenant`, index)}
                />
              ) : (
                <div />
              )}
              <ViewButton
                text={t(ETLCodes.ApprenantNb, { nb: nb, id: apprenant.idapprenant })}
                onClick={() =>
                  window.open(`#${ERoutes.apprenant}/${apprenant.idapprenant}/signaletique/view`, "_blank")
                }
              />
              <div />
            </TitleContainer>
          }
          titlePosition="center"
        >
          <DoublonsDataCard
            extValue={
              <InformationContainer>
                <div>{t(ETLCodes.NbInscriptions, { nb: apprenant.nbInscriptions })}</div>
                <div>{t(ETLCodes.NbContrats, { nb: apprenant.nbContrats })}</div>
                <div>{t(ETLCodes.NbSuivis, { nb: apprenant.nbSuivis })}</div>
                <div>{t(ETLCodes.NbAbsences, { nb: apprenant.nbAbsences })}</div>
                <div>{t(ETLCodes.NbDocuments, { nb: apprenant.nbDocuments })}</div>
              </InformationContainer>
            }
            label={t(ETLCodes.Informations)}
            interactive={false}
          />
          <DoublonsDataCard
            fieldPath={`apprenants[${index}].azureEmail`}
            label={t(ETLCodes.EmailIfapme)}
            finalEntityKey={AP_FINAL_KEY}
            entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
            interactive={false}
            forceSelected={index === selectedIndex}
          />
          <DoublonsDataCard
            fieldPath={priFromApprenant ? `apprenants[${index}].idpri` : `apprenants[${index}].idpricontratEnCours`}
            valuePath={priFromApprenant ? `apprenants[${index}].pri` : `apprenants[${index}].pricontratEnCours`}
            label={t(ETLCodes.PersonneReferenceIfapme)}
            finalEntityKey={AP_FINAL_KEY}
            entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
            interactive={nbPri > 1 && (nbPriContratsEnCours === 0 || nbPriContratsEnCours > 1)}
            forceSelected={
              (nbPri <= 1 || nbPriContratsEnCours === 1) &&
              (priFromApprenant ? !!apprenant.idpri : !!apprenant.idpricontratEnCours)
            }
          />
          <DoublonsDataCard
            fieldPath={ariFromApprenant ? `apprenants[${index}].idari` : `apprenants[${index}].idaricontratEnCours`}
            valuePath={ariFromApprenant ? `apprenants[${index}].ari` : `apprenants[${index}].aricontratEnCours`}
            label={t(ETLCodes.ResponsableAdministratif)}
            finalEntityKey={AP_FINAL_KEY}
            entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
            interactive={nbAri > 1 && (nbAriContratsEnCours === 0 || nbAriContratsEnCours > 1)}
            forceSelected={
              (nbAri <= 1 || nbAriContratsEnCours === 1) &&
              (ariFromApprenant ? !!apprenant.idari : !!apprenant.idaricontratEnCours)
            }
          />
          <DoublonsDataCard
            fieldPath={`apprenants[${index}].idcop`}
            valuePath={`apprenants[${index}].cop`}
            label={t(ETLCodes.Cop)}
            finalEntityKey={AP_FINAL_KEY}
            entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
          />
          <DoublonsDataCard
            fieldPath={`apprenants[${index}].bankAccountBeneficiaryId`}
            valuePath={`apprenants[${index}].bankAccountBeneficiaryName`}
            label={t(ETLCodes.BankAccountBeneficiary)}
            finalEntityKey={AP_FINAL_KEY}
            entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
          />
          <DoublonsDataCard
            fieldPath={`apprenants[${index}].permisB`}
            label={t(ETLCodes.PermisVoitureB)}
            finalEntityKey={AP_FINAL_KEY}
            entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
          />
          <DoublonsDataCard
            fieldPath={`apprenants[${index}].permisAm`}
            label={t(ETLCodes.PermisCyclomoteurAM)}
            finalEntityKey={AP_FINAL_KEY}
            entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
          />
          <DoublonsMultipleDataCard
            labelPredicate={() => t(ETLCodes.Remarque)}
            fieldPath={`apprenants[${index}].remarques`}
            finalEntityKey={AP_FINAL_KEY}
          >
            {(r: string) => <>{r}</>}
          </DoublonsMultipleDataCard>
          <DoublonsMultipleDataCard
            labelPredicate={() => t(ETLCodes.RemarqueService)}
            fieldPath={`apprenants[${index}].remarquesService`}
            finalEntityKey={AP_FINAL_KEY}
          >
            {(r: string) => <>{r}</>}
          </DoublonsMultipleDataCard>
          <DoublonsMultipleDataCard
            labelPredicate={() => t(ETLCodes.Interne)}
            fieldPath={`apprenants[${index}].internes`}
            finalEntityKey={AP_FINAL_KEY}
          >
            {(r: string) => <>{r}</>}
          </DoublonsMultipleDataCard>
          <DoublonsDataCard
            fieldPath={`apprenants[${index}].telephoneApprenant.idtelephone`}
            valuePath={`apprenants[${index}].telephoneApprenant.numero`}
            label={t(ETLCodes.Telephone)}
            finalEntityKey={AP_FINAL_KEY}
            entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
          />
          <DoublonsDataCard
            fieldPath={`apprenants[${index}].gsmApprenant.idtelephone`}
            valuePath={`apprenants[${index}].gsmApprenant.numero`}
            label={t(ETLCodes.Gsm)}
            finalEntityKey={AP_FINAL_KEY}
            entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
          />
          <DoublonsDataCard
            fieldPath={`apprenants[${index}].emailApprenant.idemail`}
            valuePath={`apprenants[${index}].emailApprenant.adresseEmail`}
            label={t(ETLCodes.Email)}
            finalEntityKey={AP_FINAL_KEY}
            entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
          />
        </FieldSet>
      );
    },
    [
      ariFromApprenant,
      data?.apprenants,
      nbAri,
      nbAriContratsEnCours,
      nbPri,
      nbPriContratsEnCours,
      priFromApprenant,
      selectData,
      selectedIndex,
      t
    ]
  );

  return (
    <Container>
      {(nbPri === 1 || nbPriContratsEnCours === 1) && (
        <Callout
          intent="warning"
          title={nbPri === 1 ? t(ETLCodes.PRIApprenantSelectionne) : t(ETLCodes.PRIContratSelectionne)}
          style={{ marginBottom: "1rem" }}
          icon="info-sign"
        />
      )}
      {(nbAri === 1 || nbAriContratsEnCours === 1) && (
        <Callout
          intent="warning"
          title={nbPri === 1 ? t(ETLCodes.ARIApprenantSelectionne) : t(ETLCodes.ARIContratSelectionne)}
          style={{ marginBottom: "1rem" }}
          icon="info-sign"
        />
      )}
      <CarouselComparer leftElement={apprenantContainer(selectedIndex, selectedIndex + 1)}>
        {data?.apprenants?.length > 1 &&
          data?.apprenants
            ?.filter((a, i) => i !== selectedIndex)
            ?.map((a, i) => apprenantContainer(i + (i >= selectedIndex ? 1 : 0), i + (i >= selectedIndex ? 2 : 1)))}
      </CarouselComparer>
    </Container>
  );
};
