import * as React from "react";
import { Route, Switch } from "react-router";

import { ApprenantInscriptionDetail } from "./ApprenantInscriptionDetail";
import { ApprenantInscriptionSearch } from "./ApprenantInscriptionSearch";
import { ERoutes } from "../../../../../AppRouter";

interface IApprenantInscriptionProps {}

export const ApprenantInscription: React.FunctionComponent<IApprenantInscriptionProps> = () => {
  return (
    <Switch>
      <Route path={`${ERoutes.apprenant}/:id/:tab/:state?`} component={ApprenantInscriptionSearch} exact />
      <Route
        path={`${ERoutes.apprenant}/:id/:tab/:inscriptionId/:tabInscription/:stateInscription?`}
        component={ApprenantInscriptionDetail}
      />
    </Switch>
  );
};
