import { Classes, Intent, Menu, MenuItem, Popover, Switch } from "@blueprintjs/core";
import { ButtonContainer, IDataTableColumn, showError, ToolTipButton } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { DashboardDossiersRefusSortie } from ".";
import {
  ContratApi,
  DashboardApi,
  DashboardDossierDto,
  DashboardDossierDtoFromJSON,
  DashboardDossierSearch,
  EIfapmeSide,
  EOrigineDossier,
  ETargetDossier
} from "../../../api";
import { ERoutes } from "../../../AppRouter";
import { DeleteButton, LinkButton, SearchTablePage, ViewButton } from "../../../components";
import { useDialog, useEventsContext } from "../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTheme, useTl } from "../../../hooks";
import { ETLCodes } from "../../../locales";

const StyledSwitch = styled(Switch)`
  & .${Classes.SWITCH} {
    &:hover {
      input:checked ~ .${Classes.CONTROL_INDICATOR} {
        background: ${props => props.color};
      }
    }
  }
`;

const PopoverContent = styled.div`
  padding: 0.5rem;
`;

export interface IDashboardDossierSortieProps {}

export const DashboardDossierSortie: React.FunctionComponent<IDashboardDossierSortieProps> = props => {
  const { t } = useTl();
  const { push } = useHistory();
  const { ifapmeSide } = useTheme();
  const api = useApiService(DashboardApi);
  const lastAbortController = React.useRef<AbortController>();

  const isHope = React.useMemo(() => ifapmeSide === "hope", [ifapmeSide]);
  const { theme } = useTheme();
  const apiFactory = useAbortableApiServiceFactory(DashboardApi);

  const [displayHidden, setDisplayHidden] = React.useState(false);
  const searchFunction = React.useCallback(
    (sObj?: DashboardDossierSearch) => {
      sObj.target = isHope ? ETargetDossier.Services : ETargetDossier.Centres;
      sObj.hideMasquees = !displayHidden;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.dashboardSearchDossiersSortie({ DashboardDossierSearch: sObj });
    },
    [apiFactory, displayHidden, isHope]
  );

  const [rowLoading, setRowLoading] = React.useState<DashboardDossierDto>(null);

  const { showDialogPromise } = useDialog();
  const { dispatchEvent } = useEventsContext();
  const cApi = useApiService(ContratApi);
  const marquerSortie = React.useCallback(
    async (row: DashboardDossierDto) => {
      setRowLoading(row);
      const canSortirApprenant = await cApi.contratCanSortirApprenant({
        idApprenant: row.idconcerne,
        ifapmeSide: isHope ? EIfapmeSide.Hope : EIfapmeSide.Walter
      });
      if (!canSortirApprenant?.value) {
        setRowLoading(null);
        showError(t(isHope ? ETLCodes.SortieImpossible : ETLCodes.SortieImpossibleWalter));
        return;
      }

      const res = await showDialogPromise({
        title: t(ETLCodes.MarquerSortieConfirmTitle),
        message: t(isHope ? ETLCodes.MarquerSortieHopeConfirmMessage : ETLCodes.MarquerSortieWalterConfirmMessage)
      });
      if (res === "yes") {
        await api.dashboardMarquerSortieApprenant({
          DashboardDossierDto: row
        });
        dispatchEvent("SEARCH_TABLE_REFRESH");
        dispatchEvent("DASHBOARD_COUNTS_REFRESH_Dossiers");
      }
      setRowLoading(null);
    },
    [api, cApi, dispatchEvent, isHope, showDialogPromise, t]
  );

  const [refusSortie, setRefusSortie] = React.useState<DashboardDossierDto>(null);

  const refuserSortie = React.useCallback(
    async (row: DashboardDossierDto) => {
      setRefusSortie(
        DashboardDossierDtoFromJSON({ ...row, target: isHope ? ETargetDossier.Services : ETargetDossier.Centres })
      );
    },
    [isHope]
  );

  const masquer = React.useCallback(
    async (row: DashboardDossierDto) => {
      const res = await showDialogPromise({
        title: t(ETLCodes.MasquerLigneConfirmTitle),
        message: t(ETLCodes.MasquerLigneConfirmMessage)
      });
      if (res === "yes") {
        setRowLoading(row);

        await api.dashboardMasquerSortie({
          idstatutApprenant: row.id
        });
        setRowLoading(null);
        dispatchEvent("SEARCH_TABLE_REFRESH");
        dispatchEvent("DASHBOARD_COUNTS_REFRESH_Dossiers");
      }
    },
    [api, dispatchEvent, showDialogPromise, t]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: DashboardDossierDto) => (
          <ButtonContainer>
            {!row.masquee && (
              <ToolTipButton
                tooltipPosition="bottom"
                tooltip={t(ETLCodes.MasquerLigne)}
                minimal
                icon="cross"
                onClick={() => masquer(row)}
                loading={!!rowLoading && rowLoading.id === row.id}
                disabled={!!rowLoading && rowLoading.id !== row.id}
              />
            )}
            {(isHope && row.origine === EOrigineDossier.Hope) || (!isHope && row.origine === EOrigineDossier.Walter) ? (
              <ToolTipButton
                tooltipPosition="bottom"
                minimal
                icon="tick"
                onClick={() => {
                  marquerSortie(row);
                }}
                loading={!!rowLoading && rowLoading.id === row.id}
                disabled={!!rowLoading && rowLoading.id !== row.id}
                tooltip={t(ETLCodes.ConfirmerSortie)}
              />
            ) : (
              <>
                {isHope ? (
                  <Popover
                    position="left"
                    content={
                      <PopoverContent>
                        <Menu>
                          <MenuItem
                            text={t(ETLCodes.VoirContratsApprenant)}
                            intent={Intent.PRIMARY}
                            onClick={() => push(`${ERoutes.apprenant}/${row.idconcerne}/contrats/edit`)}
                          />
                          <MenuItem
                            text={t(ETLCodes.VoirParcoursFormation)}
                            intent={Intent.PRIMARY}
                            onClick={() => push(`${ERoutes.apprenant}/${row.idconcerne}/parcoursFormation/edit`)}
                          />
                        </Menu>
                      </PopoverContent>
                    }
                  >
                    <ToolTipButton
                      tooltipPosition="bottom"
                      tooltip={t(ETLCodes.ConfirmerSortie)}
                      minimal
                      intent="success"
                      icon="thumbs-up"
                    />
                  </Popover>
                ) : (
                  <ToolTipButton
                    tooltipPosition="bottom"
                    tooltip={t(ETLCodes.VoirInscriptionsApprenant)}
                    minimal
                    intent="success"
                    icon="thumbs-up"
                    onClick={() => push(`${ERoutes.apprenant}/${row.idconcerne}/inscription/edit`)}
                  />
                )}
                <DeleteButton
                  tooltipText={t(ETLCodes.RefuserSortie)}
                  icon="thumbs-down"
                  onDelete={() => refuserSortie(row)}
                  loading={!!rowLoading && rowLoading.id === row.id}
                  disabled={!!rowLoading && rowLoading.id !== row.id}
                />
              </>
            )}
            <ViewButton
              minimal={true}
              onClick={() =>
                window.open(
                  `#${ERoutes.apprenant}/${row.idconcerne}/${isHope ? "parcoursFormation" : "inscription"}/view`,
                  "_blank"
                )
              }
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Concerne),
        fieldName: "concerne",
        render: (row: DashboardDossierDto) => (
          <LinkButton
            text={row.concerne}
            onClick={() => window.open(`#${ERoutes.apprenant}/${row.idconcerne}/signaletique/view`)}
          />
        )
      },
      {
        header: () => t(ETLCodes.Informations),
        fieldName: "information"
      },
      {
        header: () => t(ETLCodes.MetierStade),
        fieldName: "metierStade"
      },
      {
        header: () => t(ETLCodes.PersonneReferenceIfapme),
        fieldName: "currentPri"
      }
    ],
    [t, rowLoading, isHope, masquer, marquerSortie, push, refuserSortie]
  );

  const getCriteria = React.useCallback(async () => {
    return await api.dashboardGetDossierCriterias();
  }, [api]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <SearchTablePage
        title={ETLCodes.DashboardDossierType_SortieApprenant}
        searchStateInitialSearch={false}
        withCard={false}
        columns={columns}
        getCriteriasFunction={getCriteria}
        searchFunction={searchFunction}
        onAbort={onAbort}
        sortKeys={{ concerne: "ASC" }}
        rightElement={
          <StyledSwitch
            color={theme.primaryColor}
            label={t(ETLCodes.AfficherMasquees)}
            checked={displayHidden}
            onChange={e => {
              setDisplayHidden(e.currentTarget.checked);
            }}
          ></StyledSwitch>
        }
      />
      {!!refusSortie && (
        <DashboardDossiersRefusSortie
          currentRefus={refusSortie}
          dialogOpen={!!refusSortie}
          onClose={(toSave: DashboardDossierDto) => {
            setRefusSortie(null);
            if (!!toSave) {
              dispatchEvent("SEARCH_TABLE_REFRESH");
              dispatchEvent("DASHBOARD_COUNTS_REFRESH_Dossiers");
            }
          }}
        />
      )}
    </>
  );
};
