/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FcbResultatTypeBulletinSession2GridDtoPaginatedResults } from './FcbResultatTypeBulletinSession2GridDtoPaginatedResults';
import {
    FcbResultatTypeBulletinSession2GridDtoPaginatedResultsFromJSON,
    FcbResultatTypeBulletinSession2GridDtoPaginatedResultsFromJSONTyped,
    FcbResultatTypeBulletinSession2GridDtoPaginatedResultsToJSON,
} from './FcbResultatTypeBulletinSession2GridDtoPaginatedResults';
import type { FcbResultatTypeGridDtoPaginatedResults } from './FcbResultatTypeGridDtoPaginatedResults';
import {
    FcbResultatTypeGridDtoPaginatedResultsFromJSON,
    FcbResultatTypeGridDtoPaginatedResultsFromJSONTyped,
    FcbResultatTypeGridDtoPaginatedResultsToJSON,
} from './FcbResultatTypeGridDtoPaginatedResults';

/**
 * 
 * @export
 * @interface FcbBulletinApprenantTypeDetailDto
 */
export interface FcbBulletinApprenantTypeDetailDto {
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    userEncodage?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    totalTypeCours?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    readonly totalHeuresPresence?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    readonly totalHeuresAbsences?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    readonly totalHeuresAbsencesJustifiees?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    readonly totalPointsSess1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    readonly totalPointsDispenseSess1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    readonly totalPointsApresDeliberationSess1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    readonly totalPointsSess2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    readonly totalPointsDispenseSess2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    readonly totalPointsApresDeliberationSess2?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    isSecondeSession?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    codeClasse?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    codeClasse2Sess?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    classeName?: string | null;
    /**
     * 
     * @type {FcbResultatTypeGridDtoPaginatedResults}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    resultatsSession1?: FcbResultatTypeGridDtoPaginatedResults;
    /**
     * 
     * @type {FcbResultatTypeBulletinSession2GridDtoPaginatedResults}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    resultatsSession2?: FcbResultatTypeBulletinSession2GridDtoPaginatedResults;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    sentToQueue?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    idbulletinApprenantType?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    idtypeCours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    totalCalcule?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    totalDelibe?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    total1sess?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    max1sess?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    delibe1sess?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    totalCalcule2sess?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    totalDelibe2sess?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    total2sess?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    max2sess?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    delibe2sess?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    creationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    max1sessSansDispense?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    modificationUserName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    iddeliberationType?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    iddeliberationType2sess?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    remarqueDelibe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    remarqueDelibe2sess?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    idcentre?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    dateRupture?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    dateAbandon?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    dateDeliberation?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    totalPresence?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    pourcentagePresence?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    pourcentageAbsence?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    pourcentageAbsenceJustifiee?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    totalPercent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    totalAbsence?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    totalAbsenceJustifiee?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    abandon?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    dateDeliberation2sess?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    idmetier?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    degre?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    dispense?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    changementCentre?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    view2Sess?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    renvoi?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    isVisibleBulletin?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    validationDirection1sessBool?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    validationDirection2sessBool?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    validationDirection1sess?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbBulletinApprenantTypeDetailDto
     */
    validationDirection2sess?: Date | null;
}

/**
 * Check if a given object implements the FcbBulletinApprenantTypeDetailDto interface.
 */
export function instanceOfFcbBulletinApprenantTypeDetailDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbBulletinApprenantTypeDetailDtoFromJSON(json: any): FcbBulletinApprenantTypeDetailDto {
    return FcbBulletinApprenantTypeDetailDtoFromJSONTyped(json, false);
}

export function FcbBulletinApprenantTypeDetailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbBulletinApprenantTypeDetailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userEncodage': !exists(json, 'userEncodage') ? undefined : json['userEncodage'],
        'totalTypeCours': !exists(json, 'totalTypeCours') ? undefined : json['totalTypeCours'],
        'totalHeuresPresence': !exists(json, 'totalHeuresPresence') ? undefined : json['totalHeuresPresence'],
        'totalHeuresAbsences': !exists(json, 'totalHeuresAbsences') ? undefined : json['totalHeuresAbsences'],
        'totalHeuresAbsencesJustifiees': !exists(json, 'totalHeuresAbsencesJustifiees') ? undefined : json['totalHeuresAbsencesJustifiees'],
        'totalPointsSess1': !exists(json, 'totalPointsSess1') ? undefined : json['totalPointsSess1'],
        'totalPointsDispenseSess1': !exists(json, 'totalPointsDispenseSess1') ? undefined : json['totalPointsDispenseSess1'],
        'totalPointsApresDeliberationSess1': !exists(json, 'totalPointsApresDeliberationSess1') ? undefined : json['totalPointsApresDeliberationSess1'],
        'totalPointsSess2': !exists(json, 'totalPointsSess2') ? undefined : json['totalPointsSess2'],
        'totalPointsDispenseSess2': !exists(json, 'totalPointsDispenseSess2') ? undefined : json['totalPointsDispenseSess2'],
        'totalPointsApresDeliberationSess2': !exists(json, 'totalPointsApresDeliberationSess2') ? undefined : json['totalPointsApresDeliberationSess2'],
        'isSecondeSession': !exists(json, 'isSecondeSession') ? undefined : json['isSecondeSession'],
        'codeClasse': !exists(json, 'codeClasse') ? undefined : json['codeClasse'],
        'codeClasse2Sess': !exists(json, 'codeClasse2Sess') ? undefined : json['codeClasse2Sess'],
        'classeName': !exists(json, 'classeName') ? undefined : json['classeName'],
        'resultatsSession1': !exists(json, 'resultatsSession1') ? undefined : FcbResultatTypeGridDtoPaginatedResultsFromJSON(json['resultatsSession1']),
        'resultatsSession2': !exists(json, 'resultatsSession2') ? undefined : FcbResultatTypeBulletinSession2GridDtoPaginatedResultsFromJSON(json['resultatsSession2']),
        'sentToQueue': !exists(json, 'sentToQueue') ? undefined : json['sentToQueue'],
        'idbulletinApprenantType': !exists(json, 'idbulletinApprenantType') ? undefined : json['idbulletinApprenantType'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idtypeCours': !exists(json, 'idtypeCours') ? undefined : json['idtypeCours'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'totalCalcule': !exists(json, 'totalCalcule') ? undefined : json['totalCalcule'],
        'totalDelibe': !exists(json, 'totalDelibe') ? undefined : json['totalDelibe'],
        'total1sess': !exists(json, 'total1sess') ? undefined : json['total1sess'],
        'max1sess': !exists(json, 'max1sess') ? undefined : json['max1sess'],
        'delibe1sess': !exists(json, 'delibe1sess') ? undefined : json['delibe1sess'],
        'totalCalcule2sess': !exists(json, 'totalCalcule2sess') ? undefined : json['totalCalcule2sess'],
        'totalDelibe2sess': !exists(json, 'totalDelibe2sess') ? undefined : json['totalDelibe2sess'],
        'total2sess': !exists(json, 'total2sess') ? undefined : json['total2sess'],
        'max2sess': !exists(json, 'max2sess') ? undefined : json['max2sess'],
        'delibe2sess': !exists(json, 'delibe2sess') ? undefined : json['delibe2sess'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'max1sessSansDispense': !exists(json, 'max1sessSansDispense') ? undefined : json['max1sessSansDispense'],
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationUserName': !exists(json, 'modificationUserName') ? undefined : json['modificationUserName'],
        'iddeliberationType': !exists(json, 'iddeliberationType') ? undefined : json['iddeliberationType'],
        'iddeliberationType2sess': !exists(json, 'iddeliberationType2sess') ? undefined : json['iddeliberationType2sess'],
        'remarqueDelibe': !exists(json, 'remarqueDelibe') ? undefined : json['remarqueDelibe'],
        'remarqueDelibe2sess': !exists(json, 'remarqueDelibe2sess') ? undefined : json['remarqueDelibe2sess'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'dateRupture': !exists(json, 'dateRupture') ? undefined : (json['dateRupture'] === null ? null : new Date(json['dateRupture'])),
        'dateAbandon': !exists(json, 'dateAbandon') ? undefined : (json['dateAbandon'] === null ? null : new Date(json['dateAbandon'])),
        'dateDeliberation': !exists(json, 'dateDeliberation') ? undefined : (json['dateDeliberation'] === null ? null : new Date(json['dateDeliberation'])),
        'totalPresence': !exists(json, 'totalPresence') ? undefined : json['totalPresence'],
        'pourcentagePresence': !exists(json, 'pourcentagePresence') ? undefined : json['pourcentagePresence'],
        'pourcentageAbsence': !exists(json, 'pourcentageAbsence') ? undefined : json['pourcentageAbsence'],
        'pourcentageAbsenceJustifiee': !exists(json, 'pourcentageAbsenceJustifiee') ? undefined : json['pourcentageAbsenceJustifiee'],
        'totalPercent': !exists(json, 'totalPercent') ? undefined : json['totalPercent'],
        'totalAbsence': !exists(json, 'totalAbsence') ? undefined : json['totalAbsence'],
        'totalAbsenceJustifiee': !exists(json, 'totalAbsenceJustifiee') ? undefined : json['totalAbsenceJustifiee'],
        'abandon': !exists(json, 'abandon') ? undefined : json['abandon'],
        'dateDeliberation2sess': !exists(json, 'dateDeliberation2sess') ? undefined : (json['dateDeliberation2sess'] === null ? null : new Date(json['dateDeliberation2sess'])),
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'degre': !exists(json, 'degre') ? undefined : json['degre'],
        'dispense': !exists(json, 'dispense') ? undefined : json['dispense'],
        'changementCentre': !exists(json, 'changementCentre') ? undefined : json['changementCentre'],
        'view2Sess': !exists(json, 'view2Sess') ? undefined : json['view2Sess'],
        'renvoi': !exists(json, 'renvoi') ? undefined : json['renvoi'],
        'isVisibleBulletin': !exists(json, 'isVisibleBulletin') ? undefined : json['isVisibleBulletin'],
        'validationDirection1sessBool': !exists(json, 'validationDirection1sessBool') ? undefined : json['validationDirection1sessBool'],
        'validationDirection2sessBool': !exists(json, 'validationDirection2sessBool') ? undefined : json['validationDirection2sessBool'],
        'validationDirection1sess': !exists(json, 'validationDirection1sess') ? undefined : (json['validationDirection1sess'] === null ? null : new Date(json['validationDirection1sess'])),
        'validationDirection2sess': !exists(json, 'validationDirection2sess') ? undefined : (json['validationDirection2sess'] === null ? null : new Date(json['validationDirection2sess'])),
    };
}

export function FcbBulletinApprenantTypeDetailDtoToJSON(value?: FcbBulletinApprenantTypeDetailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userEncodage': value.userEncodage,
        'totalTypeCours': value.totalTypeCours,
        'isSecondeSession': value.isSecondeSession,
        'codeClasse': value.codeClasse,
        'codeClasse2Sess': value.codeClasse2Sess,
        'classeName': value.classeName,
        'resultatsSession1': FcbResultatTypeGridDtoPaginatedResultsToJSON(value.resultatsSession1),
        'resultatsSession2': FcbResultatTypeBulletinSession2GridDtoPaginatedResultsToJSON(value.resultatsSession2),
        'sentToQueue': value.sentToQueue,
        'idbulletinApprenantType': value.idbulletinApprenantType,
        'idapprenant': value.idapprenant,
        'idtypeCours': value.idtypeCours,
        'anneeScolaire': value.anneeScolaire,
        'totalCalcule': value.totalCalcule,
        'totalDelibe': value.totalDelibe,
        'total1sess': value.total1sess,
        'max1sess': value.max1sess,
        'delibe1sess': value.delibe1sess,
        'totalCalcule2sess': value.totalCalcule2sess,
        'totalDelibe2sess': value.totalDelibe2sess,
        'total2sess': value.total2sess,
        'max2sess': value.max2sess,
        'delibe2sess': value.delibe2sess,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'max1sessSansDispense': value.max1sessSansDispense,
        'modificationUser': value.modificationUser,
        'modificationUserName': value.modificationUserName,
        'iddeliberationType': value.iddeliberationType,
        'iddeliberationType2sess': value.iddeliberationType2sess,
        'remarqueDelibe': value.remarqueDelibe,
        'remarqueDelibe2sess': value.remarqueDelibe2sess,
        'idcentre': value.idcentre,
        'dateRupture': value.dateRupture === undefined ? undefined : (value.dateRupture === null ? null : value.dateRupture.toISOString()),
        'dateAbandon': value.dateAbandon === undefined ? undefined : (value.dateAbandon === null ? null : value.dateAbandon.toISOString()),
        'dateDeliberation': value.dateDeliberation === undefined ? undefined : (value.dateDeliberation === null ? null : value.dateDeliberation.toISOString()),
        'totalPresence': value.totalPresence,
        'pourcentagePresence': value.pourcentagePresence,
        'pourcentageAbsence': value.pourcentageAbsence,
        'pourcentageAbsenceJustifiee': value.pourcentageAbsenceJustifiee,
        'totalPercent': value.totalPercent,
        'totalAbsence': value.totalAbsence,
        'totalAbsenceJustifiee': value.totalAbsenceJustifiee,
        'abandon': value.abandon,
        'dateDeliberation2sess': value.dateDeliberation2sess === undefined ? undefined : (value.dateDeliberation2sess === null ? null : value.dateDeliberation2sess.toISOString()),
        'idmetier': value.idmetier,
        'degre': value.degre,
        'dispense': value.dispense,
        'changementCentre': value.changementCentre,
        'view2Sess': value.view2Sess,
        'renvoi': value.renvoi,
        'isVisibleBulletin': value.isVisibleBulletin,
        'validationDirection1sessBool': value.validationDirection1sessBool,
        'validationDirection2sessBool': value.validationDirection2sessBool,
        'validationDirection1sess': value.validationDirection1sess === undefined ? undefined : (value.validationDirection1sess === null ? null : value.validationDirection1sess.toISOString()),
        'validationDirection2sess': value.validationDirection2sess === undefined ? undefined : (value.validationDirection2sess === null ? null : value.validationDirection2sess.toISOString()),
    };
}

