/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbRechercheRemplacementDtoPaginatedResults,
  FcbRemplacementDto,
  FilterCriteriaInfo,
  RechercheRemplacementSearch,
  SelectItem,
} from '../models/index';
import {
    FcbRechercheRemplacementDtoPaginatedResultsFromJSON,
    FcbRechercheRemplacementDtoPaginatedResultsToJSON,
    FcbRemplacementDtoFromJSON,
    FcbRemplacementDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    RechercheRemplacementSearchFromJSON,
    RechercheRemplacementSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface RemplacementBaseSearchRequest {
    RechercheRemplacementSearch?: RechercheRemplacementSearch;
}

export interface RemplacementDeleteRequest {
    id?: number;
}

export interface RemplacementGetRequest {
    id?: number;
}

export interface RemplacementGetDisplayNameRequest {
    id?: number;
}

export interface RemplacementGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface RemplacementGetSelectItemsRequest {
    searchField: string;
}

export interface RemplacementSaveRequest {
    FcbRemplacementDto?: FcbRemplacementDto;
}

/**
 * 
 */
export class RemplacementApi extends runtime.BaseAPI {

    /**
     */
    async remplacementBaseSearchRaw(requestParameters: RemplacementBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRechercheRemplacementDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Remplacement/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RechercheRemplacementSearchToJSON(requestParameters.RechercheRemplacementSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRechercheRemplacementDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async remplacementBaseSearch(requestParameters: RemplacementBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRechercheRemplacementDtoPaginatedResults> {
        const response = await this.remplacementBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async remplacementDeleteRaw(requestParameters: RemplacementDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Remplacement`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async remplacementDelete(requestParameters: RemplacementDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.remplacementDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async remplacementGetRaw(requestParameters: RemplacementGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRemplacementDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Remplacement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRemplacementDtoFromJSON(jsonValue));
    }

    /**
     */
    async remplacementGet(requestParameters: RemplacementGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRemplacementDto> {
        const response = await this.remplacementGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async remplacementGetDisplayNameRaw(requestParameters: RemplacementGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Remplacement/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async remplacementGetDisplayName(requestParameters: RemplacementGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.remplacementGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async remplacementGetSearchCriteriasRaw(requestParameters: RemplacementGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Remplacement/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async remplacementGetSearchCriterias(requestParameters: RemplacementGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.remplacementGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async remplacementGetSelectItemsRaw(requestParameters: RemplacementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling remplacementGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Remplacement/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async remplacementGetSelectItems(requestParameters: RemplacementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.remplacementGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async remplacementSaveRaw(requestParameters: RemplacementSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRemplacementDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Remplacement`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbRemplacementDtoToJSON(requestParameters.FcbRemplacementDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRemplacementDtoFromJSON(jsonValue));
    }

    /**
     */
    async remplacementSave(requestParameters: RemplacementSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRemplacementDto> {
        const response = await this.remplacementSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
