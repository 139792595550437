/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FcbClauseDto } from './FcbClauseDto';
import {
    FcbClauseDtoFromJSON,
    FcbClauseDtoFromJSONTyped,
    FcbClauseDtoToJSON,
} from './FcbClauseDto';
import type { FcbConseillerPedagogiqueDto } from './FcbConseillerPedagogiqueDto';
import {
    FcbConseillerPedagogiqueDtoFromJSON,
    FcbConseillerPedagogiqueDtoFromJSONTyped,
    FcbConseillerPedagogiqueDtoToJSON,
} from './FcbConseillerPedagogiqueDto';
import type { FcbPlanLocalisationDatumDto } from './FcbPlanLocalisationDatumDto';
import {
    FcbPlanLocalisationDatumDtoFromJSON,
    FcbPlanLocalisationDatumDtoFromJSONTyped,
    FcbPlanLocalisationDatumDtoToJSON,
} from './FcbPlanLocalisationDatumDto';

/**
 * 
 * @export
 * @interface FcbMetierDetailDto
 */
export interface FcbMetierDetailDto {
    /**
     * 
     * @type {Array<FcbClauseDto>}
     * @memberof FcbMetierDetailDto
     */
    clauses?: Array<FcbClauseDto> | null;
    /**
     * 
     * @type {Array<FcbConseillerPedagogiqueDto>}
     * @memberof FcbMetierDetailDto
     */
    conseillers?: Array<FcbConseillerPedagogiqueDto> | null;
    /**
     * 
     * @type {Array<FcbPlanLocalisationDatumDto>}
     * @memberof FcbMetierDetailDto
     */
    planLocalisationDatum?: Array<FcbPlanLocalisationDatumDto> | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMetierDetailDto
     */
    idmetier?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbMetierDetailDto
     */
    idstade?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbMetierDetailDto
     */
    codesecteur?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMetierDetailDto
     */
    idsecteurCrea?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbMetierDetailDto
     */
    code?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbMetierDetailDto
     */
    integre?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbMetierDetailDto
     */
    masculin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbMetierDetailDto
     */
    feminin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbMetierDetailDto
     */
    comissionParitaire?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbMetierDetailDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbMetierDetailDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbMetierDetailDto
     */
    pratique?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMetierDetailDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbMetierDetailDto
     */
    creationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof FcbMetierDetailDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbMetierDetailDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbMetierDetailDto
     */
    idmetierGuid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbMetierDetailDto
     */
    degreFinal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbMetierDetailDto
     */
    degreFinalAdditionnel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbMetierDetailDto
     */
    ifapmeSide?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FcbMetierDetailDto
     */
    commissionParitaires?: Array<number> | null;
}

/**
 * Check if a given object implements the FcbMetierDetailDto interface.
 */
export function instanceOfFcbMetierDetailDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbMetierDetailDtoFromJSON(json: any): FcbMetierDetailDto {
    return FcbMetierDetailDtoFromJSONTyped(json, false);
}

export function FcbMetierDetailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbMetierDetailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clauses': !exists(json, 'clauses') ? undefined : (json['clauses'] === null ? null : (json['clauses'] as Array<any>).map(FcbClauseDtoFromJSON)),
        'conseillers': !exists(json, 'conseillers') ? undefined : (json['conseillers'] === null ? null : (json['conseillers'] as Array<any>).map(FcbConseillerPedagogiqueDtoFromJSON)),
        'planLocalisationDatum': !exists(json, 'planLocalisationDatum') ? undefined : (json['planLocalisationDatum'] === null ? null : (json['planLocalisationDatum'] as Array<any>).map(FcbPlanLocalisationDatumDtoFromJSON)),
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'idstade': !exists(json, 'idstade') ? undefined : json['idstade'],
        'codesecteur': !exists(json, 'codesecteur') ? undefined : json['codesecteur'],
        'idsecteurCrea': !exists(json, 'idsecteurCrea') ? undefined : json['idsecteurCrea'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'integre': !exists(json, 'integre') ? undefined : json['integre'],
        'masculin': !exists(json, 'masculin') ? undefined : json['masculin'],
        'feminin': !exists(json, 'feminin') ? undefined : json['feminin'],
        'comissionParitaire': !exists(json, 'comissionParitaire') ? undefined : json['comissionParitaire'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'pratique': !exists(json, 'pratique') ? undefined : json['pratique'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'idmetierGuid': !exists(json, 'idmetierGuid') ? undefined : json['idmetierGuid'],
        'degreFinal': !exists(json, 'degreFinal') ? undefined : json['degreFinal'],
        'degreFinalAdditionnel': !exists(json, 'degreFinalAdditionnel') ? undefined : json['degreFinalAdditionnel'],
        'ifapmeSide': !exists(json, 'ifapmeSide') ? undefined : json['ifapmeSide'],
        'commissionParitaires': !exists(json, 'commissionParitaires') ? undefined : json['commissionParitaires'],
    };
}

export function FcbMetierDetailDtoToJSON(value?: FcbMetierDetailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clauses': value.clauses === undefined ? undefined : (value.clauses === null ? null : (value.clauses as Array<any>).map(FcbClauseDtoToJSON)),
        'conseillers': value.conseillers === undefined ? undefined : (value.conseillers === null ? null : (value.conseillers as Array<any>).map(FcbConseillerPedagogiqueDtoToJSON)),
        'planLocalisationDatum': value.planLocalisationDatum === undefined ? undefined : (value.planLocalisationDatum === null ? null : (value.planLocalisationDatum as Array<any>).map(FcbPlanLocalisationDatumDtoToJSON)),
        'idmetier': value.idmetier,
        'idstade': value.idstade,
        'codesecteur': value.codesecteur,
        'idsecteurCrea': value.idsecteurCrea,
        'code': value.code,
        'integre': value.integre,
        'masculin': value.masculin,
        'feminin': value.feminin,
        'comissionParitaire': value.comissionParitaire,
        'actif': value.actif,
        'remarque': value.remarque,
        'pratique': value.pratique,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'idmetierGuid': value.idmetierGuid,
        'degreFinal': value.degreFinal,
        'degreFinalAdditionnel': value.degreFinalAdditionnel,
        'ifapmeSide': value.ifapmeSide,
        'commissionParitaires': value.commissionParitaires,
    };
}

