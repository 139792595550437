/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbMetierConseillersLiesDto,
  FcbMetierConseillersLiesGridDtoPaginatedResults,
  FilterCriteriaInfo,
  MetierConseillersLiesSearch,
  SelectItem,
} from '../models/index';
import {
    FcbMetierConseillersLiesDtoFromJSON,
    FcbMetierConseillersLiesDtoToJSON,
    FcbMetierConseillersLiesGridDtoPaginatedResultsFromJSON,
    FcbMetierConseillersLiesGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    MetierConseillersLiesSearchFromJSON,
    MetierConseillersLiesSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface MetierConseillersLiesBaseSearchRequest {
    MetierConseillersLiesSearch?: MetierConseillersLiesSearch;
}

export interface MetierConseillersLiesGetConseillerMetierRequest {
    id?: number;
}

export interface MetierConseillersLiesGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface MetierConseillersLiesGetSelectItemsRequest {
    searchField: string;
}

export interface MetierConseillersLiesSaveConseillerMetierRequest {
    FcbMetierConseillersLiesDto?: FcbMetierConseillersLiesDto;
}

/**
 * 
 */
export class MetierConseillersLiesApi extends runtime.BaseAPI {

    /**
     */
    async metierConseillersLiesBaseSearchRaw(requestParameters: MetierConseillersLiesBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbMetierConseillersLiesGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/MetierConseillersLies/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MetierConseillersLiesSearchToJSON(requestParameters.MetierConseillersLiesSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbMetierConseillersLiesGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async metierConseillersLiesBaseSearch(requestParameters: MetierConseillersLiesBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbMetierConseillersLiesGridDtoPaginatedResults> {
        const response = await this.metierConseillersLiesBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async metierConseillersLiesGetConseillerMetierRaw(requestParameters: MetierConseillersLiesGetConseillerMetierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbMetierConseillersLiesDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/MetierConseillersLies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbMetierConseillersLiesDtoFromJSON(jsonValue));
    }

    /**
     */
    async metierConseillersLiesGetConseillerMetier(requestParameters: MetierConseillersLiesGetConseillerMetierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbMetierConseillersLiesDto> {
        const response = await this.metierConseillersLiesGetConseillerMetierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async metierConseillersLiesGetSearchCriteriasRaw(requestParameters: MetierConseillersLiesGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/MetierConseillersLies/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async metierConseillersLiesGetSearchCriterias(requestParameters: MetierConseillersLiesGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.metierConseillersLiesGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async metierConseillersLiesGetSelectItemsRaw(requestParameters: MetierConseillersLiesGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling metierConseillersLiesGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/MetierConseillersLies/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async metierConseillersLiesGetSelectItems(requestParameters: MetierConseillersLiesGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.metierConseillersLiesGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async metierConseillersLiesSaveConseillerMetierRaw(requestParameters: MetierConseillersLiesSaveConseillerMetierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbMetierConseillersLiesDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/MetierConseillersLies/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbMetierConseillersLiesDtoToJSON(requestParameters.FcbMetierConseillersLiesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbMetierConseillersLiesDtoFromJSON(jsonValue));
    }

    /**
     */
    async metierConseillersLiesSaveConseillerMetier(requestParameters: MetierConseillersLiesSaveConseillerMetierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbMetierConseillersLiesDto> {
        const response = await this.metierConseillersLiesSaveConseillerMetierRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
