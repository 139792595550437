import { Classes, Dialog } from "@blueprintjs/core";
import { FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { ApprenantDocumentDialogMode } from ".";

import { ApprenantFichierApi, ApprenantFichierDto, ETypeApprenantFichier } from "../../../../../api";
import { FGWalterDateMaskInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

const StyledDialog = styled(Dialog)`
  width: 600px;
  height: auto;
  background-color: white;
`;

export interface IApprenantDocumentEditDialogProps {
  idapprenantFichier: number;
  idapprenant: number;
  onClose: (refresh?: boolean) => void;
  excludedTypes: ETypeApprenantFichier[];
  mode: ApprenantDocumentDialogMode;
}

export const ApprenantDocumentEditDialog: React.FunctionComponent<IApprenantDocumentEditDialogProps> = ({
  idapprenantFichier,
  onClose,
  excludedTypes,
  mode,
  idapprenant
}) => {
  const { t } = useTl();
  const api = useApiService(ApprenantFichierApi);

  const { data, loading, saveItem, saving, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: async () => {
          const af = await api.apprenantFichierGetApprenantFichier({ id: idapprenantFichier });
          af.fileName = af.fileName ?? t(ETLCodes.DocumentPhysique);
          return af;
        },
        saveApiFn: async (d: ApprenantFichierDto) => {
          return api.apprenantFichierSaveApprenantFichier({ ApprenantFichierDto: d });
        },
        onSaved: () => onClose(true)
      }),
      [api, idapprenantFichier, onClose, t]
    )
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape(
        {
          idtypeApprenantFichier: mode === "AUTRES"
            ? Yup.number().nullable()
            : Yup.number()
                .nullable()
                .required(t(ETLCodes.Required)),
          dateDebut: Yup.date()
            .nullable()
            .when("dateFin", (value, schema) =>
              !!value ? schema.max(Yup.ref("dateFin"), t(ETLCodes.DateDebutGreaterThanDateFin)) : schema
            ),
          dateFin: Yup.date()
            .nullable()
            .when("dateFin", (value, schema) =>
              !!value ? schema.min(Yup.ref("dateDebut"), t(ETLCodes.DateFinGreaterThanDateDebut)) : schema
            )
        },
        [["dateFin", "dateFin"]]
      ),
    [mode, t]
  );

  const [typesApprenantFichier, tLoading] = useReferential(a =>
    a.referentialGetTypesApprenantFichier({ ETypeApprenantFichier: excludedTypes })
  );
  const [annees, aLoading] = useReferential(a => a.referentialGetAnneeScolaire(), false, []);
  const [inscriptions, iLoading] = useReferential(
    a => a.referentialGetInscriptionsApprenant({ idApprenant: idapprenant }),
    true,
    []
  );

  const fields = React.useMemo(() => {
    let fields = <></>;
    switch (mode) {
      case "REGLEMENTAIRES":
        fields = (
          <FGWalterSelectInput label={t(ETLCodes.Annee)} name="anneeScolaire" items={annees} loading={aLoading} />
        );
        break;
      case "AVIS_ATTEST_PF":
        fields = (
          <FGWalterSelectInput
            name="idinscription"
            label={t(ETLCodes.Inscription)}
            loading={iLoading}
            items={inscriptions}
          />
        );
        break;
      case "AUTRES":
        fields = (
          <>
            <FGTextInput label={t(ETLCodes.Nom)} maxLength={150} name="nom" />
            <FGWalterDateMaskInput name="dateDebut" label={t(ETLCodes.DateDebut)} />
            <FGWalterDateMaskInput name="dateFin" label={t(ETLCodes.DateFin)} />
            <FGTextAreaInput label={t(ETLCodes.Description)} name="description" />
          </>
        );
        break;
    }
    return fields;
  }, [aLoading, annees, iLoading, inscriptions, mode, t]);

  return (
    <StyledDialog title={t(ETLCodes.UploadFichier)} isOpen={!!idapprenantFichier} onClose={() => onClose(false)}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose(false)}
          showDeleteButton={false}
          saving={saving}
          loading={loading}
          validationErrors={validationErrors}
        >
          <FieldGroup>
            <FGTextInput name="fileName" label={t(ETLCodes.Fichier)} readonly />
            <FGWalterSelectInput
              name="idtypeApprenantFichier"
              label={t(ETLCodes.Type)}
              items={typesApprenantFichier}
              loading={tLoading}
              readonly={mode === "REGLEMENTAIRES"}
            />
            {fields}
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
