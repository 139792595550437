import { FGEmpty, FGTextAreaInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { AgrementApi, FcbAgrementDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

export interface IApprenantValidationCEDetailPageProps {}

export const ApprenantValidationCEDetailPage: React.FunctionComponent<IApprenantValidationCEDetailPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(AgrementApi);
  const { id, idValidation, state } = useParams<{ id: string; idValidation: string; state: string }>();
  const idApprenant = React.useMemo(() => +id, [id]);
  const IdValidation = React.useMemo(() => +idValidation, [idValidation]);

  const [formation, formationLoading] = useReferential(
    a => a.referentialGetFormationApprenant({ idApprenant: idApprenant }),
    true,
    []
  );
  const [decision, decisionLoading] = useReferential(a => a.referentialGetDecision(), true, []);

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      IdValidation <= 0
        ? FcbAgrementDtoFromJSON({
            idagrement: 0,
            idapprenant: idApprenant,
            c8: false,
            derogation: false,
            dateDecision: new Date()
          })
        : api.agrementGet({ id: IdValidation }),
    saveApiFn: d => api.agrementSave({ FcbAgrementDto: d }),
    onSavedRoute: d => `${ERoutes.apprenant}/${idApprenant}/validationce/${d.idagrement}/edit`,
    deleteApiFn: d => api.agrementDelete({ id: d.idagrement }),
    onDeletedRoute: d => `${ERoutes.apprenant}/${idApprenant}/validationce`
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      iddecision: Yup.number().required(t(ETLCodes.Required)),
      idformation: Yup.number().required(t(ETLCodes.Required)),
      dateDecision: Yup.date().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={state === "edit"}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(`${ERoutes.apprenant}/${idApprenant}/validationce`)}
      onDelete={deleteItem}
      showDeleteButton={IdValidation > 0}
      saving={saving}
      deleting={deleting}
      formatDate="dd-MM-yyyy"
      minLabelWidth={200}
    >
      <FieldGroup columns={2} fieldsetProps={{ title: t(ETLCodes.Edition), collapsable: false }}>
        <FGWalterSelectInput
          label={t(ETLCodes.Formation)}
          name="idformation"
          items={formation}
          loading={formationLoading}
        />
        <FGWalterDateMaskInput label={t(ETLCodes.Date)} name="dateDecision" />
        <FGWalterSelectInput
          label={t(ETLCodes.Decision)}
          name="iddecision"
          items={decision}
          loading={decisionLoading}
        />
        <FGWalterCheckboxInput label={t(ETLCodes.Derogation)} name="derogation" />
        <FGEmpty />
        <FGWalterCheckboxInput label={t(ETLCodes.C8)} name="c8" />
      </FieldGroup>
      <FieldGroup columns={1}>
        <FGTextAreaInput label={t(ETLCodes.Remarque)} name="remarque" />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
