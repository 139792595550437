/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailAdressesDto
 */
export interface EmailAdressesDto {
    /**
     * 
     * @type {string}
     * @memberof EmailAdressesDto
     */
    azureEmails?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailAdressesDto
     */
    emails?: string | null;
}

/**
 * Check if a given object implements the EmailAdressesDto interface.
 */
export function instanceOfEmailAdressesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmailAdressesDtoFromJSON(json: any): EmailAdressesDto {
    return EmailAdressesDtoFromJSONTyped(json, false);
}

export function EmailAdressesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailAdressesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'azureEmails': !exists(json, 'azureEmails') ? undefined : json['azureEmails'],
        'emails': !exists(json, 'emails') ? undefined : json['emails'],
    };
}

export function EmailAdressesDtoToJSON(value?: EmailAdressesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'azureEmails': value.azureEmails,
        'emails': value.emails,
    };
}

