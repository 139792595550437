/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EOrigineDossier } from './EOrigineDossier';
import {
    EOrigineDossierFromJSON,
    EOrigineDossierFromJSONTyped,
    EOrigineDossierToJSON,
} from './EOrigineDossier';
import type { ETargetDossier } from './ETargetDossier';
import {
    ETargetDossierFromJSON,
    ETargetDossierFromJSONTyped,
    ETargetDossierToJSON,
} from './ETargetDossier';

/**
 * 
 * @export
 * @interface DashboardDossierDto
 */
export interface DashboardDossierDto {
    /**
     * 
     * @type {number}
     * @memberof DashboardDossierDto
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardDossierDto
     */
    idconcerne?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardDossierDto
     */
    concerne?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardDossierDto
     */
    information?: string | null;
    /**
     * 
     * @type {EOrigineDossier}
     * @memberof DashboardDossierDto
     */
    origine?: EOrigineDossier;
    /**
     * 
     * @type {ETargetDossier}
     * @memberof DashboardDossierDto
     */
    target?: ETargetDossier;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardDossierDto
     */
    masquee?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DashboardDossierDto
     */
    metierStade?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardDossierDto
     */
    currentPri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardDossierDto
     */
    remarqueRefus?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DashboardDossierDto
     */
    dateSortie?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DashboardDossierDto
     */
    dateReception?: Date | null;
}

/**
 * Check if a given object implements the DashboardDossierDto interface.
 */
export function instanceOfDashboardDossierDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DashboardDossierDtoFromJSON(json: any): DashboardDossierDto {
    return DashboardDossierDtoFromJSONTyped(json, false);
}

export function DashboardDossierDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardDossierDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idconcerne': !exists(json, 'idconcerne') ? undefined : json['idconcerne'],
        'concerne': !exists(json, 'concerne') ? undefined : json['concerne'],
        'information': !exists(json, 'information') ? undefined : json['information'],
        'origine': !exists(json, 'origine') ? undefined : EOrigineDossierFromJSON(json['origine']),
        'target': !exists(json, 'target') ? undefined : ETargetDossierFromJSON(json['target']),
        'masquee': !exists(json, 'masquee') ? undefined : json['masquee'],
        'metierStade': !exists(json, 'metierStade') ? undefined : json['metierStade'],
        'currentPri': !exists(json, 'currentPri') ? undefined : json['currentPri'],
        'remarqueRefus': !exists(json, 'remarqueRefus') ? undefined : json['remarqueRefus'],
        'dateSortie': !exists(json, 'dateSortie') ? undefined : (json['dateSortie'] === null ? null : new Date(json['dateSortie'])),
        'dateReception': !exists(json, 'dateReception') ? undefined : (json['dateReception'] === null ? null : new Date(json['dateReception'])),
    };
}

export function DashboardDossierDtoToJSON(value?: DashboardDossierDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'idconcerne': value.idconcerne,
        'concerne': value.concerne,
        'information': value.information,
        'origine': EOrigineDossierToJSON(value.origine),
        'target': ETargetDossierToJSON(value.target),
        'masquee': value.masquee,
        'metierStade': value.metierStade,
        'currentPri': value.currentPri,
        'remarqueRefus': value.remarqueRefus,
        'dateSortie': value.dateSortie === undefined ? undefined : (value.dateSortie === null ? null : value.dateSortie.toISOString()),
        'dateReception': value.dateReception === undefined ? undefined : (value.dateReception === null ? null : value.dateReception.toISOString()),
    };
}

