import { NonIdealState } from "@blueprintjs/core";
import * as React from "react";
import { useTl } from "../hooks";
import { ETLCodes } from "../locales";

export interface IStillInDevProps {}

export const StillInDev: React.FunctionComponent<IStillInDevProps> = props => {
  const { t } = useTl();
  return <NonIdealState icon="build" title={t(ETLCodes.StillToDev)} />;
};
