/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbVisiteFormateurDto,
  FcbVisiteFormateurGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
  VisiteFormateurSearch,
} from '../models/index';
import {
    FcbVisiteFormateurDtoFromJSON,
    FcbVisiteFormateurDtoToJSON,
    FcbVisiteFormateurGridDtoPaginatedResultsFromJSON,
    FcbVisiteFormateurGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    VisiteFormateurSearchFromJSON,
    VisiteFormateurSearchToJSON,
} from '../models/index';

export interface VisiteFormateurBaseSearchRequest {
    VisiteFormateurSearch?: VisiteFormateurSearch;
}

export interface VisiteFormateurGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface VisiteFormateurGetSelectItemsRequest {
    searchField: string;
}

export interface VisiteFormateurGetVisiteFormateurRequest {
    idVisite?: number;
}

export interface VisiteFormateurSaveFormateursLiesRequest {
    FcbVisiteFormateurDto?: FcbVisiteFormateurDto;
}

/**
 * 
 */
export class VisiteFormateurApi extends runtime.BaseAPI {

    /**
     */
    async visiteFormateurBaseSearchRaw(requestParameters: VisiteFormateurBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbVisiteFormateurGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/VisiteFormateur/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VisiteFormateurSearchToJSON(requestParameters.VisiteFormateurSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbVisiteFormateurGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async visiteFormateurBaseSearch(requestParameters: VisiteFormateurBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbVisiteFormateurGridDtoPaginatedResults> {
        const response = await this.visiteFormateurBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async visiteFormateurGetSearchCriteriasRaw(requestParameters: VisiteFormateurGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/VisiteFormateur/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async visiteFormateurGetSearchCriterias(requestParameters: VisiteFormateurGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.visiteFormateurGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async visiteFormateurGetSelectItemsRaw(requestParameters: VisiteFormateurGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling visiteFormateurGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/VisiteFormateur/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async visiteFormateurGetSelectItems(requestParameters: VisiteFormateurGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.visiteFormateurGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async visiteFormateurGetVisiteFormateurRaw(requestParameters: VisiteFormateurGetVisiteFormateurRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbVisiteFormateurDto>> {
        const queryParameters: any = {};

        if (requestParameters.idVisite !== undefined) {
            queryParameters['idVisite'] = requestParameters.idVisite;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/VisiteFormateur`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbVisiteFormateurDtoFromJSON(jsonValue));
    }

    /**
     */
    async visiteFormateurGetVisiteFormateur(requestParameters: VisiteFormateurGetVisiteFormateurRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbVisiteFormateurDto> {
        const response = await this.visiteFormateurGetVisiteFormateurRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async visiteFormateurSaveFormateursLiesRaw(requestParameters: VisiteFormateurSaveFormateursLiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbVisiteFormateurDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/VisiteFormateur/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbVisiteFormateurDtoToJSON(requestParameters.FcbVisiteFormateurDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbVisiteFormateurDtoFromJSON(jsonValue));
    }

    /**
     */
    async visiteFormateurSaveFormateursLies(requestParameters: VisiteFormateurSaveFormateursLiesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbVisiteFormateurDto> {
        const response = await this.visiteFormateurSaveFormateursLiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
