import { ButtonContainer, IDataTableColumn } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import { PersonneApi, PersonneGridDto, PersonneSearchDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, UnordoredListColumn, ViewButton } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IPersonneListPageProps {}

export const PersonneListPage: React.FunctionComponent<IPersonneListPageProps> = props => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(PersonneApi);
  const lastSearchObject = React.useRef<PersonneSearchDto>();

  const apiFactory = useAbortableApiServiceFactory(PersonneApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (nextSearch?: PersonneSearchDto) => {
      if (JSON.stringify(nextSearch) !== JSON.stringify(lastSearchObject?.current)) {
        lastSearchObject.current = nextSearch;
      }
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.personneBaseSearch({ PersonneSearchDto: nextSearch });
    },
    [apiFactory]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: PersonneGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.personne}/${row.idpersonne}/signaletique/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.personne}/${row.idpersonne}/signaletique/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.SecondPrenom),
        fieldName: "prenom2"
      },
      {
        header: () => t(ETLCodes.Genre),
        fieldName: "codesexe"
      },
      {
        header: () => t(ETLCodes.Nationalite),
        fieldName: "nationalite"
      },
      {
        header: () => t(ETLCodes.RegistreNational),
        fieldName: "registreNational"
      },
      {
        header: () => t(ETLCodes.DateNaissance),
        fieldName: "dateNaissance"
      },
      {
        header: () => t(ETLCodes.DateDeces),
        fieldName: "dateDeces"
      },
      {
        header: () => t(ETLCodes.Forme),
        fieldName: "forme",
        render: (row: PersonneGridDto) => (
          <UnordoredListColumn
            values={[
              { value: row.isApprenant, label: t(ETLCodes.Apprenant) },
              { value: row.isFormateur, label: t(ETLCodes.Formateur) },
              { value: row.isRepresentant, label: t(ETLCodes.Representant) },
              { value: row.isTuteur, label: t(ETLCodes.Tuteur) },
              { value: row.isContact, label: t(ETLCodes.Contact) },
              { value: row.isChefEntreprise, label: t(ETLCodes.ChefEntreprise) },
              { value: row.isUser, label: t(ETLCodes.Collaborateur) },
              { value: row.isDelegueTutelle, label: t(ETLCodes.Referent) },
              { value: row.isConseillePedagogique, label: t(ETLCodes.ConseillerPedagogique) }
            ]}
          />
        )
      }
    ],
    [history, t]
  );

  const getCriteria = React.useCallback(() => api.personneGetSearchCriterias({ includeListsValues: true }), [api]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteria}
      searchFunction={search}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.Personnes), route: ERoutes.personne }]}
      sortKeys={{ nom: "ASC" }}
      searchStateInitialSearch={false}
    />
  );
};
