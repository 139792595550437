/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbEnveloppeTypeFinancementDto
 */
export interface FcbEnveloppeTypeFinancementDto {
    /**
     * 
     * @type {number}
     * @memberof FcbEnveloppeTypeFinancementDto
     */
    idenveloppeTypeFinancement?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbEnveloppeTypeFinancementDto
     */
    idanneeScolaire?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbEnveloppeTypeFinancementDto
     */
    idfinancementType?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbEnveloppeTypeFinancementDto
     */
    heureS1?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbEnveloppeTypeFinancementDto
     */
    heureS2?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbEnveloppeTypeFinancementDto
     */
    identite?: number | null;
}

/**
 * Check if a given object implements the FcbEnveloppeTypeFinancementDto interface.
 */
export function instanceOfFcbEnveloppeTypeFinancementDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbEnveloppeTypeFinancementDtoFromJSON(json: any): FcbEnveloppeTypeFinancementDto {
    return FcbEnveloppeTypeFinancementDtoFromJSONTyped(json, false);
}

export function FcbEnveloppeTypeFinancementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbEnveloppeTypeFinancementDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idenveloppeTypeFinancement': !exists(json, 'idenveloppeTypeFinancement') ? undefined : json['idenveloppeTypeFinancement'],
        'idanneeScolaire': !exists(json, 'idanneeScolaire') ? undefined : json['idanneeScolaire'],
        'idfinancementType': !exists(json, 'idfinancementType') ? undefined : json['idfinancementType'],
        'heureS1': !exists(json, 'heureS1') ? undefined : json['heureS1'],
        'heureS2': !exists(json, 'heureS2') ? undefined : json['heureS2'],
        'identite': !exists(json, 'identite') ? undefined : json['identite'],
    };
}

export function FcbEnveloppeTypeFinancementDtoToJSON(value?: FcbEnveloppeTypeFinancementDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idenveloppeTypeFinancement': value.idenveloppeTypeFinancement,
        'idanneeScolaire': value.idanneeScolaire,
        'idfinancementType': value.idfinancementType,
        'heureS1': value.heureS1,
        'heureS2': value.heureS2,
        'identite': value.identite,
    };
}

