import { IDataTableColumn, useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ConseillerPedagogiqueApi, MetierConseillersLiesApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { WSelector } from "../../../../../components/selector/WSelector";
import { useDialog } from "../../../../../contexts";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IMetierConseillerLiesSelectorProps {}

export const MetierConseillerLiesSelector: React.FunctionComponent<IMetierConseillerLiesSelectorProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const cApi = useApiService(ConseillerPedagogiqueApi);
  const mApi = useApiService(MetierConseillersLiesApi);
  const { id } = useParams<{ id: string }>();
  const dialogContext = useDialog();

  const [data, loading] = useApiEffect(() => mApi.metierConseillersLiesGetConseillerMetier({ id: +id }));

  const columns = React.useMemo<IDataTableColumn[]>(() => {
    return [
      {
        fieldName: "idconseillerPedagogique",
        header: () => t(ETLCodes.Code),
        autoFitContent: true
      },
      {
        fieldName: "nom",
        header: () => t(ETLCodes.Nom)
      },
      {
        fieldName: "prenom",
        header: () => t(ETLCodes.Prenom)
      },
      {
        fieldName: "telephone",
        header: () => t(ETLCodes.Tel)
      },
      {
        fieldName: "gsm",
        header: () => t(ETLCodes.Gsm)
      },
      {
        fieldName: "fax",
        header: () => t(ETLCodes.Fax)
      }
    ];
  }, [t]);

  return (
    !loading && (
      <WSelector
        linkedIds={data.idsConseillers}
        columns={columns}
        idField="idconseillerPedagogique"
        tlDataPrefix="ConseillersSearchCriterias"
        title={t(ETLCodes.ConseillersLies)}
        searchIdsFunc={sObj => cApi.conseillerPedagogiqueSearchConseillerIds({ ConseillerPedagogiqueSearch: sObj })}
        getCriteriasFunction={() => cApi.conseillerPedagogiqueGetSearchCriterias({ includeListsValues: true })}
        searchFunction={sObj => cApi.conseillerPedagogiqueBaseSearch({ ConseillerPedagogiqueSearch: sObj })}
        onSave={async newIds => {
          await mApi.metierConseillersLiesSaveConseillerMetier({
            FcbMetierConseillersLiesDto: { idMetiers: +id, idsConseillers: newIds }
          });

          history.push(`${ERoutes.metier}/${id}/conseillerslies`);
        }}
        onCancel={() => history.push(`${ERoutes.metier}/${id}/conseillerslies`)}
        overrideListValues={{ Idstade: i => i.displayValue }}
        dialogContext={dialogContext}
        sortKeys={{ nom: "ASC" }}
      />
    )
  );
};
