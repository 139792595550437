/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRechercheClasseHoraireRemplacementDefinitiveDto
 */
export interface FcbRechercheClasseHoraireRemplacementDefinitiveDto {
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseHoraireRemplacementDefinitiveDto
     */
    classe?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseHoraireRemplacementDefinitiveDto
     */
    idClasseMatiere?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseHoraireRemplacementDefinitiveDto
     */
    idRemplacement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseHoraireRemplacementDefinitiveDto
     */
    idClasse?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseHoraireRemplacementDefinitiveDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseHoraireRemplacementDefinitiveDto
     */
    remplacant?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseHoraireRemplacementDefinitiveDto
     */
    idRemplacant?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseHoraireRemplacementDefinitiveDto
     */
    contrat?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseHoraireRemplacementDefinitiveDto
     */
    idFormateurLocalisationContrat?: number | null;
}

/**
 * Check if a given object implements the FcbRechercheClasseHoraireRemplacementDefinitiveDto interface.
 */
export function instanceOfFcbRechercheClasseHoraireRemplacementDefinitiveDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRechercheClasseHoraireRemplacementDefinitiveDtoFromJSON(json: any): FcbRechercheClasseHoraireRemplacementDefinitiveDto {
    return FcbRechercheClasseHoraireRemplacementDefinitiveDtoFromJSONTyped(json, false);
}

export function FcbRechercheClasseHoraireRemplacementDefinitiveDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRechercheClasseHoraireRemplacementDefinitiveDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'classe': !exists(json, 'classe') ? undefined : json['classe'],
        'idClasseMatiere': !exists(json, 'idClasseMatiere') ? undefined : json['idClasseMatiere'],
        'idRemplacement': !exists(json, 'idRemplacement') ? undefined : json['idRemplacement'],
        'idClasse': !exists(json, 'idClasse') ? undefined : json['idClasse'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'remplacant': !exists(json, 'remplacant') ? undefined : json['remplacant'],
        'idRemplacant': !exists(json, 'idRemplacant') ? undefined : json['idRemplacant'],
        'contrat': !exists(json, 'contrat') ? undefined : json['contrat'],
        'idFormateurLocalisationContrat': !exists(json, 'idFormateurLocalisationContrat') ? undefined : json['idFormateurLocalisationContrat'],
    };
}

export function FcbRechercheClasseHoraireRemplacementDefinitiveDtoToJSON(value?: FcbRechercheClasseHoraireRemplacementDefinitiveDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'classe': value.classe,
        'idClasseMatiere': value.idClasseMatiere,
        'idRemplacement': value.idRemplacement,
        'idClasse': value.idClasse,
        'description': value.description,
        'remplacant': value.remplacant,
        'idRemplacant': value.idRemplacant,
        'contrat': value.contrat,
        'idFormateurLocalisationContrat': value.idFormateurLocalisationContrat,
    };
}

