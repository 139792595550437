/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SiegeSocialChefEntrepriseGridDto
 */
export interface SiegeSocialChefEntrepriseGridDto {
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialChefEntrepriseGridDto
     */
    idchefEntrepriseSiege?: number;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialChefEntrepriseGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialChefEntrepriseGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SiegeSocialChefEntrepriseGridDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialChefEntrepriseGridDto
     */
    fonction?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialChefEntrepriseGridDto
     */
    idsiegeSocial?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SiegeSocialChefEntrepriseGridDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof SiegeSocialChefEntrepriseGridDto
     */
    sortieEntreprise?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof SiegeSocialChefEntrepriseGridDto
     */
    chefEntreprisePrincipal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialChefEntrepriseGridDto
     */
    remarqueSiege?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialChefEntrepriseGridDto
     */
    telephone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialChefEntrepriseGridDto
     */
    gsm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialChefEntrepriseGridDto
     */
    email?: string | null;
}

/**
 * Check if a given object implements the SiegeSocialChefEntrepriseGridDto interface.
 */
export function instanceOfSiegeSocialChefEntrepriseGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SiegeSocialChefEntrepriseGridDtoFromJSON(json: any): SiegeSocialChefEntrepriseGridDto {
    return SiegeSocialChefEntrepriseGridDtoFromJSONTyped(json, false);
}

export function SiegeSocialChefEntrepriseGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiegeSocialChefEntrepriseGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idchefEntrepriseSiege': !exists(json, 'idchefEntrepriseSiege') ? undefined : json['idchefEntrepriseSiege'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'fonction': !exists(json, 'fonction') ? undefined : json['fonction'],
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'sortieEntreprise': !exists(json, 'sortieEntreprise') ? undefined : (json['sortieEntreprise'] === null ? null : new Date(json['sortieEntreprise'])),
        'chefEntreprisePrincipal': !exists(json, 'chefEntreprisePrincipal') ? undefined : json['chefEntreprisePrincipal'],
        'remarqueSiege': !exists(json, 'remarqueSiege') ? undefined : json['remarqueSiege'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'gsm': !exists(json, 'gsm') ? undefined : json['gsm'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function SiegeSocialChefEntrepriseGridDtoToJSON(value?: SiegeSocialChefEntrepriseGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idchefEntrepriseSiege': value.idchefEntrepriseSiege,
        'nom': value.nom,
        'prenom': value.prenom,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'fonction': value.fonction,
        'idsiegeSocial': value.idsiegeSocial,
        'actif': value.actif,
        'sortieEntreprise': value.sortieEntreprise === undefined ? undefined : (value.sortieEntreprise === null ? null : value.sortieEntreprise.toISOString()),
        'chefEntreprisePrincipal': value.chefEntreprisePrincipal,
        'remarqueSiege': value.remarqueSiege,
        'telephone': value.telephone,
        'gsm': value.gsm,
        'email': value.email,
    };
}

