/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbFormateurLocalisationContratDetailDto,
  FcbFormateurLocalisationContratGridDtoPaginatedResults,
  FilterCriteriaInfo,
  FormateurLocalisationContratSearch,
  SelectItem,
} from '../models/index';
import {
    FcbFormateurLocalisationContratDetailDtoFromJSON,
    FcbFormateurLocalisationContratDetailDtoToJSON,
    FcbFormateurLocalisationContratGridDtoPaginatedResultsFromJSON,
    FcbFormateurLocalisationContratGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    FormateurLocalisationContratSearchFromJSON,
    FormateurLocalisationContratSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface FormateurLocalisationContratBaseSearchRequest {
    FormateurLocalisationContratSearch?: FormateurLocalisationContratSearch;
}

export interface FormateurLocalisationContratGetRequest {
    id?: number;
}

export interface FormateurLocalisationContratGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface FormateurLocalisationContratGetSelectItemsRequest {
    searchField: string;
}

export interface FormateurLocalisationContratSaveRequest {
    FcbFormateurLocalisationContratDetailDto?: FcbFormateurLocalisationContratDetailDto;
}

/**
 * 
 */
export class FormateurLocalisationContratApi extends runtime.BaseAPI {

    /**
     */
    async formateurLocalisationContratBaseSearchRaw(requestParameters: FormateurLocalisationContratBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurLocalisationContratGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurLocalisationContrat/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormateurLocalisationContratSearchToJSON(requestParameters.FormateurLocalisationContratSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurLocalisationContratGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async formateurLocalisationContratBaseSearch(requestParameters: FormateurLocalisationContratBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurLocalisationContratGridDtoPaginatedResults> {
        const response = await this.formateurLocalisationContratBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurLocalisationContratGetRaw(requestParameters: FormateurLocalisationContratGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurLocalisationContratDetailDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurLocalisationContrat`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurLocalisationContratDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurLocalisationContratGet(requestParameters: FormateurLocalisationContratGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurLocalisationContratDetailDto> {
        const response = await this.formateurLocalisationContratGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurLocalisationContratGetSearchCriteriasRaw(requestParameters: FormateurLocalisationContratGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurLocalisationContrat/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async formateurLocalisationContratGetSearchCriterias(requestParameters: FormateurLocalisationContratGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.formateurLocalisationContratGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurLocalisationContratGetSelectItemsRaw(requestParameters: FormateurLocalisationContratGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling formateurLocalisationContratGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurLocalisationContrat/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async formateurLocalisationContratGetSelectItems(requestParameters: FormateurLocalisationContratGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.formateurLocalisationContratGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurLocalisationContratSaveRaw(requestParameters: FormateurLocalisationContratSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurLocalisationContratDetailDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurLocalisationContrat`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbFormateurLocalisationContratDetailDtoToJSON(requestParameters.FcbFormateurLocalisationContratDetailDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurLocalisationContratDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurLocalisationContratSave(requestParameters: FormateurLocalisationContratSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurLocalisationContratDetailDto> {
        const response = await this.formateurLocalisationContratSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
