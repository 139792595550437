import * as React from "react";
import { useFGContext } from "nsitools-react";
import { Prompt } from "react-router";
import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";

interface IFGRouteDirtyCheckerProps {
  message?: string;
  /**
   * var used to signal that the confirm changes prompt should not be rendered when deleting an element
   */
  isDelete?: boolean;
  isViewMode?: boolean;
  enableDirtyCheck?: boolean;
}

export const FGRouteDirtyChecker: React.FunctionComponent<IFGRouteDirtyCheckerProps> = props => {
  const { isDelete, isViewMode, enableDirtyCheck } = props;
  const { formik } = useFGContext();
  const { t } = useTl();
  return (
    <Prompt
      when={formik.dirty && !isDelete && !isViewMode && enableDirtyCheck}
      message={t(ETLCodes.FormDirtyRouteChangeMessage)}
    ></Prompt>
  );
};
