import { IDataTableColumn, useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { FormateurMetierAgrementApi, MetierApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { WSelector } from "../../../../../components/selector/WSelector";
import { useDialog } from "../../../../../contexts";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IFormateurAgrementMetiersSelectorProps {}

export const FormateurAgrementMetiersSelector: React.FunctionComponent<IFormateurAgrementMetiersSelectorProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const mApi = useApiService(MetierApi);
  const cmApi = useApiService(FormateurMetierAgrementApi);
  const { id, idAgrement } = useParams<{ id: string; idAgrement: string }>();
  const dialogContext = useDialog();

  const [data, loading] = useApiEffect(() =>
    cmApi.formateurMetierAgrementGetMetiersAgrement({ idformateurAgrement: +idAgrement })
  );

  const columns = React.useMemo<IDataTableColumn[]>(() => {
    return [
      {
        fieldName: "code",
        header: () => t(ETLCodes.Code)
      },
      {
        fieldName: "stade",
        header: () => t(ETLCodes.Stade)
      },
      {
        fieldName: "masculin",
        header: () => t(ETLCodes.Metier)
      }
    ];
  }, [t]);

  return (
    !loading && (
      <WSelector
        linkedIds={data.idsMetier}
        columns={columns}
        idField="idmetier"
        tlDataPrefix="MetierSearchCriterias"
        title={t(ETLCodes.MetiersLies)}
        searchIdsFunc={sObj => mApi.metierSearchMetierIds({ MetierSearch: sObj })}
        getCriteriasFunction={() => mApi.metierGetSearchCriterias({ includeListsValues: true })}
        searchFunction={sObj => mApi.metierBaseSearch({ MetierSearch: sObj })}
        onSave={async newIds => {
          await cmApi.formateurMetierAgrementSaveMetierAgrement({
            FcbFormateurMetierAgrementLiesDto: { idFormateurAgrement: +idAgrement, idsMetier: newIds }
          });
          history.push(`${ERoutes.formateur}/${id}/agrement/${idAgrement}/metiers`);
        }}
        onCancel={() => history.push(`${ERoutes.formateur}/${id}/agrement/${idAgrement}/metiers`)}
        overrideListValues={{ Idstade: i => i.displayValue }}
        dialogContext={dialogContext}
        sortKeys={{ code: "ASC" }}
      />
    )
  );
};
