/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ETypeDeliberation {
    Bulletin1Session = 'Bulletin1Session',
    Bulletin2Session = 'Bulletin2Session',
    Matiere = 'Matiere'
}


export function ETypeDeliberationFromJSON(json: any): ETypeDeliberation {
    return ETypeDeliberationFromJSONTyped(json, false);
}

export function ETypeDeliberationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ETypeDeliberation {
    return json as ETypeDeliberation;
}

export function ETypeDeliberationToJSON(value?: ETypeDeliberation | null): any {
    return value as any;
}

