import { Colors } from '@blueprintjs/core';
import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from 'nsitools-react';
import * as React from "react";
import { useParams } from 'react-router';
import { css } from 'styled-components';
import { ContactApi, ContactSiegeSocialGridDto, ContactSiegeSocialSearchDto } from '../../../../../api';
import { ERoutes } from '../../../../../AppRouter';
import { ColumnMask, EditButton, ViewButton } from '../../../../../components';
import { useApiService, useTl } from '../../../../../hooks';
import { ETLCodes } from '../../../../../locales';

export interface IContactSiegeSociauxProps { }

export const ContactSiegeSociaux: React.FunctionComponent<IContactSiegeSociauxProps> = props => {
  const { t } = useTl();
  const { id } = useParams<{ id: string }>();
  const api = useApiService(ContactApi);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { denominationSociale: "ASC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: ContactSiegeSocialSearchDto) => {
      sObj.idcontact = +id;
      return api.contactSearchContactSiegeSocial({ ContactSiegeSocialSearchDto: sObj });
    },
    [api, id]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const onViewClick = React.useCallback((dto: ContactSiegeSocialGridDto) => {
    window.open(
      `#${ERoutes.siegeSocial}/${dto.idsiegeSocial}/detail/view`,
      "_blank"
    );
  }, []);

  const onEditClick = React.useCallback((dto: ContactSiegeSocialGridDto) => {
    window.open(
      `#${ERoutes.siegeSocial}/${dto.idsiegeSocial}/detail/edit`,
      "_blank"
    );
  }, []);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ContactSiegeSocialGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => onViewClick(row)} />
            <EditButton minimal={true} onClick={() => onEditClick(row)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "denominationSociale"
      },
      {
        header: () => t(ETLCodes.Gsm),
        fieldName: "gsm"
      },
      {
        header: () => t(ETLCodes.Telephone),
        fieldName: "telephone"
      },
      {
        header: () => t(ETLCodes.Patron),
        fieldName: "nomPatron"
      },
      {
        header: () => t(ETLCodes.Email),
        fieldName: "email"
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      },
      {
        header: () => t(ETLCodes.NumeroBce),
        fieldName: "numeroBce",
        render: (row: ContactSiegeSocialGridDto) => (
          <ColumnMask
            value={row.numeroBce}
            cleaveOptions={{
              blocks: [4, 3, 3],
              delimiter: "."
            }}
          />
        )
      }
    ],
    [onEditClick, onViewClick, t]
  );

  return (
    <FieldSet title={t(ETLCodes.TableResults, { count: totalCount })}>
      <DataTable
        dateFormat="dd/MM/yyyy"
        tableState={tableState}
        loading={loading}
        columns={columns}
        filterMode="OnEnter"
        customizeRowStyle={(row: ContactSiegeSocialGridDto) => css`
          & * {
            color: ${!row.actif ? Colors.RED3 + " !important" : null};
          }
        `}
      ></DataTable>
    </FieldSet>
  );
};