/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ETypeDestinataireSms {
    Etudiant = 'Etudiant',
    Formateur = 'Formateur',
    Representant = 'Representant',
    Patron = 'Patron'
}


export function ETypeDestinataireSmsFromJSON(json: any): ETypeDestinataireSms {
    return ETypeDestinataireSmsFromJSONTyped(json, false);
}

export function ETypeDestinataireSmsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ETypeDestinataireSms {
    return json as ETypeDestinataireSms;
}

export function ETypeDestinataireSmsToJSON(value?: ETypeDestinataireSms | null): any {
    return value as any;
}

