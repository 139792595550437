/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbPersonnelTutelleDto
 */
export interface FcbPersonnelTutelleDto {
    /**
     * 
     * @type {number}
     * @memberof FcbPersonnelTutelleDto
     */
    iddelegueTutelle?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbPersonnelTutelleDto
     */
    idserviceTutelle?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbPersonnelTutelleDto
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof FcbPersonnelTutelleDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPersonnelTutelleDto
     */
    codesexe?: string;
    /**
     * 
     * @type {string}
     * @memberof FcbPersonnelTutelleDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPersonnelTutelleDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbPersonnelTutelleDto
     */
    independant?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPersonnelTutelleDto
     */
    registreNational?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPersonnelTutelleDto
     */
    gsm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPersonnelTutelleDto
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbPersonnelTutelleDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbPersonnelTutelleDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbPersonnelTutelleDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbPersonnelTutelleDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPersonnelTutelleDto
     */
    idpersonnelTutelleGuid?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbPersonnelTutelleDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbPersonnelTutelleDto interface.
 */
export function instanceOfFcbPersonnelTutelleDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbPersonnelTutelleDtoFromJSON(json: any): FcbPersonnelTutelleDto {
    return FcbPersonnelTutelleDtoFromJSONTyped(json, false);
}

export function FcbPersonnelTutelleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbPersonnelTutelleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iddelegueTutelle': !exists(json, 'iddelegueTutelle') ? undefined : json['iddelegueTutelle'],
        'idserviceTutelle': !exists(json, 'idserviceTutelle') ? undefined : json['idserviceTutelle'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'codesexe': !exists(json, 'codesexe') ? undefined : json['codesexe'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'independant': !exists(json, 'independant') ? undefined : json['independant'],
        'registreNational': !exists(json, 'registreNational') ? undefined : json['registreNational'],
        'gsm': !exists(json, 'gsm') ? undefined : json['gsm'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'idpersonnelTutelleGuid': !exists(json, 'idpersonnelTutelleGuid') ? undefined : json['idpersonnelTutelleGuid'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbPersonnelTutelleDtoToJSON(value?: FcbPersonnelTutelleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iddelegueTutelle': value.iddelegueTutelle,
        'idserviceTutelle': value.idserviceTutelle,
        'type': value.type,
        'code': value.code,
        'codesexe': value.codesexe,
        'nom': value.nom,
        'prenom': value.prenom,
        'independant': value.independant,
        'registreNational': value.registreNational,
        'gsm': value.gsm,
        'email': value.email,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'idpersonnelTutelleGuid': value.idpersonnelTutelleGuid,
        'actif': value.actif,
    };
}

