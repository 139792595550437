/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InstitutionApprenantLiesGridDto
 */
export interface InstitutionApprenantLiesGridDto {
    /**
     * 
     * @type {number}
     * @memberof InstitutionApprenantLiesGridDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {string}
     * @memberof InstitutionApprenantLiesGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionApprenantLiesGridDto
     */
    prenom?: string | null;
}

/**
 * Check if a given object implements the InstitutionApprenantLiesGridDto interface.
 */
export function instanceOfInstitutionApprenantLiesGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InstitutionApprenantLiesGridDtoFromJSON(json: any): InstitutionApprenantLiesGridDto {
    return InstitutionApprenantLiesGridDtoFromJSONTyped(json, false);
}

export function InstitutionApprenantLiesGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstitutionApprenantLiesGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
    };
}

export function InstitutionApprenantLiesGridDtoToJSON(value?: InstitutionApprenantLiesGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idapprenant': value.idapprenant,
        'nom': value.nom,
        'prenom': value.prenom,
    };
}

