/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbClasseMatiereCutGridDto
 */
export interface FcbClasseMatiereCutGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereCutGridDto
     */
    idclasseMatiere?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereCutGridDto
     */
    idclasse?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseMatiereCutGridDto
     */
    idmatiere?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereCutGridDto
     */
    idreferentiel?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseMatiereCutGridDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseMatiereCutGridDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereCutGridDto
     */
    section?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereCutGridDto
     */
    nbHeures?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereCutGridDto
     */
    travail?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereCutGridDto
     */
    examen?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereCutGridDto
     */
    reussite?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereCutGridDto
     */
    idformateur?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereCutGridDto
     */
    idcontrat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereCutGridDto
     */
    idlocal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereCutGridDto
     */
    niveau?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseMatiereCutGridDto
     */
    matiereParent?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseMatiereCutGridDto
     */
    uaa?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseMatiereCutGridDto
     */
    isVisibleBulletin?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseMatiereCutGridDto
     */
    sommative?: boolean | null;
}

/**
 * Check if a given object implements the FcbClasseMatiereCutGridDto interface.
 */
export function instanceOfFcbClasseMatiereCutGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbClasseMatiereCutGridDtoFromJSON(json: any): FcbClasseMatiereCutGridDto {
    return FcbClasseMatiereCutGridDtoFromJSONTyped(json, false);
}

export function FcbClasseMatiereCutGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbClasseMatiereCutGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idclasseMatiere': !exists(json, 'idclasseMatiere') ? undefined : json['idclasseMatiere'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'idmatiere': !exists(json, 'idmatiere') ? undefined : json['idmatiere'],
        'idreferentiel': !exists(json, 'idreferentiel') ? undefined : json['idreferentiel'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'section': !exists(json, 'section') ? undefined : json['section'],
        'nbHeures': !exists(json, 'nbHeures') ? undefined : json['nbHeures'],
        'travail': !exists(json, 'travail') ? undefined : json['travail'],
        'examen': !exists(json, 'examen') ? undefined : json['examen'],
        'reussite': !exists(json, 'reussite') ? undefined : json['reussite'],
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'idcontrat': !exists(json, 'idcontrat') ? undefined : json['idcontrat'],
        'idlocal': !exists(json, 'idlocal') ? undefined : json['idlocal'],
        'niveau': !exists(json, 'niveau') ? undefined : json['niveau'],
        'matiereParent': !exists(json, 'matiereParent') ? undefined : json['matiereParent'],
        'uaa': !exists(json, 'uaa') ? undefined : json['uaa'],
        'isVisibleBulletin': !exists(json, 'isVisibleBulletin') ? undefined : json['isVisibleBulletin'],
        'sommative': !exists(json, 'sommative') ? undefined : json['sommative'],
    };
}

export function FcbClasseMatiereCutGridDtoToJSON(value?: FcbClasseMatiereCutGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idclasseMatiere': value.idclasseMatiere,
        'idclasse': value.idclasse,
        'idmatiere': value.idmatiere,
        'idreferentiel': value.idreferentiel,
        'code': value.code,
        'description': value.description,
        'section': value.section,
        'nbHeures': value.nbHeures,
        'travail': value.travail,
        'examen': value.examen,
        'reussite': value.reussite,
        'idformateur': value.idformateur,
        'idcontrat': value.idcontrat,
        'idlocal': value.idlocal,
        'niveau': value.niveau,
        'matiereParent': value.matiereParent,
        'uaa': value.uaa,
        'isVisibleBulletin': value.isVisibleBulletin,
        'sommative': value.sommative,
    };
}

