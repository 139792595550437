import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";

import {
  ChefEntrepriseApi,
  ChefEntrepriseSiegeSocialGridDto,
  ChefEntrepriseSiegeSocialSearch
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { ColumnMask, EditButton, ViewButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface ICESiegesSociauxProps { }

export const CESiegesSociaux: React.FunctionComponent<ICESiegesSociauxProps> = props => {
  const { t } = useTl();
  const { id } = useParams<{ id: string }>();
  const api = useApiService(ChefEntrepriseApi);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { nom: "ASC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: ChefEntrepriseSiegeSocialSearch) => {
      sObj.idChefEntreprise = +id;
      return api.chefEntrepriseSearchChefEntrepriseSiegeSocial({ ChefEntrepriseSiegeSocialSearch: sObj });
    },
    [api, id]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const onViewClick = React.useCallback((dto: ChefEntrepriseSiegeSocialGridDto) => {
    window.open(`#${ERoutes.siegeSocial}/${dto.idsiegeSocial}/detail/view`, "_blank");
  }, []);

  const onEditClick = React.useCallback((dto: ChefEntrepriseSiegeSocialGridDto) => {
    window.open(`#${ERoutes.siegeSocial}/${dto.idsiegeSocial}/detail/edit`, "_blank");
  }, []);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ChefEntrepriseSiegeSocialGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => onViewClick(row)} />
            <EditButton minimal={true} onClick={() => onEditClick(row)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.DenominationSiegeSocial),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Email),
        fieldName: "email"
      },
      {
        header: () => t(ETLCodes.Telephone),
        fieldName: "telephone"
      },
      {
        header: () => t(ETLCodes.NumeroBce),
        fieldName: "numeroBce",
        render: (row: ChefEntrepriseSiegeSocialGridDto) => (
          <ColumnMask
            value={row.numeroBce}
            cleaveOptions={{
              blocks: [4, 3, 3],
              delimiter: "."
            }}
          />
        )
      }
    ],
    [onEditClick, onViewClick, t]
  );

  return (
    <FieldSet title={t(ETLCodes.TableResults, { count: totalCount })}>
      <DataTable
        dateFormat="dd/MM/yyyy"
        tableState={tableState}
        loading={loading}
        columns={columns}
        filterMode="OnEnter"
      ></DataTable>
    </FieldSet>
  );
};
