/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbRechercheExportGapRemplacementDtoPaginatedResults,
  FilterCriteriaInfo,
  RechercheExportGapSearch,
  SelectItem,
} from '../models/index';
import {
    FcbRechercheExportGapRemplacementDtoPaginatedResultsFromJSON,
    FcbRechercheExportGapRemplacementDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    RechercheExportGapSearchFromJSON,
    RechercheExportGapSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface ExportGapRemplacementBaseSearchRequest {
    RechercheExportGapSearch?: RechercheExportGapSearch;
}

export interface ExportGapRemplacementGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ExportGapRemplacementGetSelectItemsRequest {
    searchField: string;
}

/**
 * 
 */
export class ExportGapRemplacementApi extends runtime.BaseAPI {

    /**
     */
    async exportGapRemplacementBaseSearchRaw(requestParameters: ExportGapRemplacementBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRechercheExportGapRemplacementDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportGapRemplacement/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RechercheExportGapSearchToJSON(requestParameters.RechercheExportGapSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRechercheExportGapRemplacementDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async exportGapRemplacementBaseSearch(requestParameters: ExportGapRemplacementBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRechercheExportGapRemplacementDtoPaginatedResults> {
        const response = await this.exportGapRemplacementBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportGapRemplacementGetSearchCriteriasRaw(requestParameters: ExportGapRemplacementGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportGapRemplacement/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async exportGapRemplacementGetSearchCriterias(requestParameters: ExportGapRemplacementGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.exportGapRemplacementGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportGapRemplacementGetSelectItemsRaw(requestParameters: ExportGapRemplacementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling exportGapRemplacementGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportGapRemplacement/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async exportGapRemplacementGetSelectItems(requestParameters: ExportGapRemplacementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.exportGapRemplacementGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
