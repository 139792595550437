/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FilterCriteriaInfo,
  GenerateMultipleRapportHopeCommand,
  GenerateRapportHopeCommand,
  GetRapportHopeForViewQuery,
  RapportHopeAccesPermissionDto,
  RapportHopeDownloadDto,
  RapportHopeEditDto,
  RapportHopeForViewDto,
  RapportHopeGetIdsrapportContrat200Response,
  RapportHopeGridDtoPaginatedResults,
  RapportHopeModulePermissionDto,
  RapportHopePermissionsDto,
  RapportHopeSearchDto,
  RapportHopeServicePermissionDto,
  ReferentialItemDto,
} from '../models/index';
import {
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    GenerateMultipleRapportHopeCommandFromJSON,
    GenerateMultipleRapportHopeCommandToJSON,
    GenerateRapportHopeCommandFromJSON,
    GenerateRapportHopeCommandToJSON,
    GetRapportHopeForViewQueryFromJSON,
    GetRapportHopeForViewQueryToJSON,
    RapportHopeAccesPermissionDtoFromJSON,
    RapportHopeAccesPermissionDtoToJSON,
    RapportHopeDownloadDtoFromJSON,
    RapportHopeDownloadDtoToJSON,
    RapportHopeEditDtoFromJSON,
    RapportHopeEditDtoToJSON,
    RapportHopeForViewDtoFromJSON,
    RapportHopeForViewDtoToJSON,
    RapportHopeGetIdsrapportContrat200ResponseFromJSON,
    RapportHopeGetIdsrapportContrat200ResponseToJSON,
    RapportHopeGridDtoPaginatedResultsFromJSON,
    RapportHopeGridDtoPaginatedResultsToJSON,
    RapportHopeModulePermissionDtoFromJSON,
    RapportHopeModulePermissionDtoToJSON,
    RapportHopePermissionsDtoFromJSON,
    RapportHopePermissionsDtoToJSON,
    RapportHopeSearchDtoFromJSON,
    RapportHopeSearchDtoToJSON,
    RapportHopeServicePermissionDtoFromJSON,
    RapportHopeServicePermissionDtoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface RapportHopeBaseSearchRequest {
    RapportHopeSearchDto?: RapportHopeSearchDto;
}

export interface RapportHopeDeleteRapportHopeRequest {
    idrapportHope?: number;
}

export interface RapportHopeGenerateRequest {
    GenerateRapportHopeCommand?: GenerateRapportHopeCommand;
}

export interface RapportHopeGenerateMultipleRequest {
    GenerateMultipleRapportHopeCommand?: GenerateMultipleRapportHopeCommand;
}

export interface RapportHopeGetDisplayNameRequest {
    idrapportHope?: number;
}

export interface RapportHopeGetForViewRequest {
    GetRapportHopeForViewQuery?: GetRapportHopeForViewQuery;
}

export interface RapportHopeGetPermissionsRequest {
    idrapportHope?: number;
}

export interface RapportHopeGetRapportHopeRequest {
    idrapportHope?: number;
}

export interface RapportHopeGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface RapportHopeGetSelectItemsRequest {
    searchField: string;
}

export interface RapportHopeSaveAccesPermissionsRequest {
    RapportHopeAccesPermissionDto?: RapportHopeAccesPermissionDto;
}

export interface RapportHopeSaveModulePermissionsRequest {
    RapportHopeModulePermissionDto?: RapportHopeModulePermissionDto;
}

export interface RapportHopeSaveRapportHopeRequest {
    RapportHopeEditDto?: RapportHopeEditDto;
}

export interface RapportHopeSaveServicePermissionsRequest {
    RapportHopeServicePermissionDto?: RapportHopeServicePermissionDto;
}

/**
 * 
 */
export class RapportHopeApi extends runtime.BaseAPI {

    /**
     */
    async rapportHopeBaseSearchRaw(requestParameters: RapportHopeBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RapportHopeGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RapportHope/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RapportHopeSearchDtoToJSON(requestParameters.RapportHopeSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RapportHopeGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async rapportHopeBaseSearch(requestParameters: RapportHopeBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RapportHopeGridDtoPaginatedResults> {
        const response = await this.rapportHopeBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rapportHopeDeleteRapportHopeRaw(requestParameters: RapportHopeDeleteRapportHopeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idrapportHope !== undefined) {
            queryParameters['idrapportHope'] = requestParameters.idrapportHope;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RapportHope`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async rapportHopeDeleteRapportHope(requestParameters: RapportHopeDeleteRapportHopeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.rapportHopeDeleteRapportHopeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async rapportHopeGenerateRaw(requestParameters: RapportHopeGenerateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RapportHopeDownloadDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RapportHope/Generate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GenerateRapportHopeCommandToJSON(requestParameters.GenerateRapportHopeCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RapportHopeDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async rapportHopeGenerate(requestParameters: RapportHopeGenerateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RapportHopeDownloadDto> {
        const response = await this.rapportHopeGenerateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rapportHopeGenerateMultipleRaw(requestParameters: RapportHopeGenerateMultipleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RapportHopeDownloadDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RapportHope/GenerateMultiple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GenerateMultipleRapportHopeCommandToJSON(requestParameters.GenerateMultipleRapportHopeCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RapportHopeDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async rapportHopeGenerateMultiple(requestParameters: RapportHopeGenerateMultipleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RapportHopeDownloadDto> {
        const response = await this.rapportHopeGenerateMultipleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rapportHopeGetDisplayNameRaw(requestParameters: RapportHopeGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.idrapportHope !== undefined) {
            queryParameters['idrapportHope'] = requestParameters.idrapportHope;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RapportHope/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async rapportHopeGetDisplayName(requestParameters: RapportHopeGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.rapportHopeGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rapportHopeGetForViewRaw(requestParameters: RapportHopeGetForViewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RapportHopeForViewDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RapportHope/GetForView`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetRapportHopeForViewQueryToJSON(requestParameters.GetRapportHopeForViewQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RapportHopeForViewDtoFromJSON(jsonValue));
    }

    /**
     */
    async rapportHopeGetForView(requestParameters: RapportHopeGetForViewRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RapportHopeForViewDto> {
        const response = await this.rapportHopeGetForViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rapportHopeGetIdrapportFicheTutelleRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RapportHope/GetIdrapportFicheTutelle`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async rapportHopeGetIdrapportFicheTutelle(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.rapportHopeGetIdrapportFicheTutelleRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async rapportHopeGetIdrapportLieuFormationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RapportHope/GetIdrapportLieuFormation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async rapportHopeGetIdrapportLieuFormation(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.rapportHopeGetIdrapportLieuFormationRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async rapportHopeGetIdrapportSiegeSocialRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RapportHope/GetIdrapportSiegeSocial`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async rapportHopeGetIdrapportSiegeSocial(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.rapportHopeGetIdrapportSiegeSocialRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async rapportHopeGetIdsrapportContratRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RapportHopeGetIdsrapportContrat200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RapportHope/GetIdsrapportContrat`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RapportHopeGetIdsrapportContrat200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async rapportHopeGetIdsrapportContrat(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RapportHopeGetIdsrapportContrat200Response> {
        const response = await this.rapportHopeGetIdsrapportContratRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async rapportHopeGetPermissionsRaw(requestParameters: RapportHopeGetPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RapportHopePermissionsDto>> {
        const queryParameters: any = {};

        if (requestParameters.idrapportHope !== undefined) {
            queryParameters['idrapportHope'] = requestParameters.idrapportHope;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RapportHope/GetPermissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RapportHopePermissionsDtoFromJSON(jsonValue));
    }

    /**
     */
    async rapportHopeGetPermissions(requestParameters: RapportHopeGetPermissionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RapportHopePermissionsDto> {
        const response = await this.rapportHopeGetPermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rapportHopeGetRapportHopeRaw(requestParameters: RapportHopeGetRapportHopeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RapportHopeEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.idrapportHope !== undefined) {
            queryParameters['idrapportHope'] = requestParameters.idrapportHope;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RapportHope`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RapportHopeEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async rapportHopeGetRapportHope(requestParameters: RapportHopeGetRapportHopeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RapportHopeEditDto> {
        const response = await this.rapportHopeGetRapportHopeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rapportHopeGetSearchCriteriasRaw(requestParameters: RapportHopeGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RapportHope/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async rapportHopeGetSearchCriterias(requestParameters: RapportHopeGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.rapportHopeGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rapportHopeGetSelectItemsRaw(requestParameters: RapportHopeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling rapportHopeGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RapportHope/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async rapportHopeGetSelectItems(requestParameters: RapportHopeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.rapportHopeGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rapportHopeSaveAccesPermissionsRaw(requestParameters: RapportHopeSaveAccesPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RapportHopeAccesPermissionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RapportHope/SaveAccesPermissions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RapportHopeAccesPermissionDtoToJSON(requestParameters.RapportHopeAccesPermissionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RapportHopeAccesPermissionDtoFromJSON(jsonValue));
    }

    /**
     */
    async rapportHopeSaveAccesPermissions(requestParameters: RapportHopeSaveAccesPermissionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RapportHopeAccesPermissionDto> {
        const response = await this.rapportHopeSaveAccesPermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rapportHopeSaveModulePermissionsRaw(requestParameters: RapportHopeSaveModulePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RapportHopeModulePermissionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RapportHope/SaveModulePermissions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RapportHopeModulePermissionDtoToJSON(requestParameters.RapportHopeModulePermissionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RapportHopeModulePermissionDtoFromJSON(jsonValue));
    }

    /**
     */
    async rapportHopeSaveModulePermissions(requestParameters: RapportHopeSaveModulePermissionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RapportHopeModulePermissionDto> {
        const response = await this.rapportHopeSaveModulePermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rapportHopeSaveRapportHopeRaw(requestParameters: RapportHopeSaveRapportHopeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RapportHopeEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RapportHope`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RapportHopeEditDtoToJSON(requestParameters.RapportHopeEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RapportHopeEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async rapportHopeSaveRapportHope(requestParameters: RapportHopeSaveRapportHopeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RapportHopeEditDto> {
        const response = await this.rapportHopeSaveRapportHopeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rapportHopeSaveServicePermissionsRaw(requestParameters: RapportHopeSaveServicePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RapportHopeServicePermissionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RapportHope/SaveServicePermissions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RapportHopeServicePermissionDtoToJSON(requestParameters.RapportHopeServicePermissionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RapportHopeServicePermissionDtoFromJSON(jsonValue));
    }

    /**
     */
    async rapportHopeSaveServicePermissions(requestParameters: RapportHopeSaveServicePermissionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RapportHopeServicePermissionDto> {
        const response = await this.rapportHopeSaveServicePermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
