/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailDto } from './EmailDto';
import {
    EmailDtoFromJSON,
    EmailDtoFromJSONTyped,
    EmailDtoToJSON,
} from './EmailDto';
import type { TelephoneDto } from './TelephoneDto';
import {
    TelephoneDtoFromJSON,
    TelephoneDtoFromJSONTyped,
    TelephoneDtoToJSON,
} from './TelephoneDto';

/**
 * 
 * @export
 * @interface SiegeSocialEditDto
 */
export interface SiegeSocialEditDto {
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialEditDto
     */
    idsiegeSocial?: number;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialEditDto
     */
    denominationSociale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialEditDto
     */
    numeroBce?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialEditDto
     */
    numeroOnss?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialEditDto
     */
    idtypeEntreprise?: number;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialEditDto
     */
    idformeJuridique?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialEditDto
     */
    idtaille?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SiegeSocialEditDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialEditDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialEditDto
     */
    idchefEntreprise?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialEditDto
     */
    idpersonne?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialEditDto
     */
    fonction?: string | null;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof SiegeSocialEditDto
     */
    gsm?: TelephoneDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof SiegeSocialEditDto
     */
    telephone?: TelephoneDto;
    /**
     * 
     * @type {EmailDto}
     * @memberof SiegeSocialEditDto
     */
    email?: EmailDto;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialEditDto
     */
    iban?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialEditDto
     */
    bic?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialEditDto
     */
    idcontactBeneficiaire?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SiegeSocialEditDto
     */
    sendToAdress?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialEditDto
     */
    adresse?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialEditDto
     */
    idcodePostal?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialEditDto
     */
    codePostalText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialEditDto
     */
    boite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialEditDto
     */
    localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialEditDto
     */
    commune?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialEditDto
     */
    pays?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SiegeSocialEditDto
     */
    idsCommissionParitaire?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof SiegeSocialEditDto
     */
    isContactCE?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialEditDto
     */
    contact?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialEditDto
     */
    contactFonction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialEditDto
     */
    contactTel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialEditDto
     */
    contactGsm?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SiegeSocialEditDto
     */
    hasNoAssurances?: boolean;
}

/**
 * Check if a given object implements the SiegeSocialEditDto interface.
 */
export function instanceOfSiegeSocialEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SiegeSocialEditDtoFromJSON(json: any): SiegeSocialEditDto {
    return SiegeSocialEditDtoFromJSONTyped(json, false);
}

export function SiegeSocialEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiegeSocialEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'denominationSociale': !exists(json, 'denominationSociale') ? undefined : json['denominationSociale'],
        'numeroBce': !exists(json, 'numeroBce') ? undefined : json['numeroBce'],
        'numeroOnss': !exists(json, 'numeroOnss') ? undefined : json['numeroOnss'],
        'idtypeEntreprise': !exists(json, 'idtypeEntreprise') ? undefined : json['idtypeEntreprise'],
        'idformeJuridique': !exists(json, 'idformeJuridique') ? undefined : json['idformeJuridique'],
        'idtaille': !exists(json, 'idtaille') ? undefined : json['idtaille'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'idchefEntreprise': !exists(json, 'idchefEntreprise') ? undefined : json['idchefEntreprise'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'fonction': !exists(json, 'fonction') ? undefined : json['fonction'],
        'gsm': !exists(json, 'gsm') ? undefined : TelephoneDtoFromJSON(json['gsm']),
        'telephone': !exists(json, 'telephone') ? undefined : TelephoneDtoFromJSON(json['telephone']),
        'email': !exists(json, 'email') ? undefined : EmailDtoFromJSON(json['email']),
        'iban': !exists(json, 'iban') ? undefined : json['iban'],
        'bic': !exists(json, 'bic') ? undefined : json['bic'],
        'idcontactBeneficiaire': !exists(json, 'idcontactBeneficiaire') ? undefined : json['idcontactBeneficiaire'],
        'sendToAdress': !exists(json, 'sendToAdress') ? undefined : json['sendToAdress'],
        'adresse': !exists(json, 'adresse') ? undefined : json['adresse'],
        'idcodePostal': !exists(json, 'idcodePostal') ? undefined : json['idcodePostal'],
        'codePostalText': !exists(json, 'codePostalText') ? undefined : json['codePostalText'],
        'boite': !exists(json, 'boite') ? undefined : json['boite'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'commune': !exists(json, 'commune') ? undefined : json['commune'],
        'pays': !exists(json, 'pays') ? undefined : json['pays'],
        'idsCommissionParitaire': !exists(json, 'idsCommissionParitaire') ? undefined : json['idsCommissionParitaire'],
        'isContactCE': !exists(json, 'isContactCE') ? undefined : json['isContactCE'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'contactFonction': !exists(json, 'contactFonction') ? undefined : json['contactFonction'],
        'contactTel': !exists(json, 'contactTel') ? undefined : json['contactTel'],
        'contactGsm': !exists(json, 'contactGsm') ? undefined : json['contactGsm'],
        'hasNoAssurances': !exists(json, 'hasNoAssurances') ? undefined : json['hasNoAssurances'],
    };
}

export function SiegeSocialEditDtoToJSON(value?: SiegeSocialEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsiegeSocial': value.idsiegeSocial,
        'denominationSociale': value.denominationSociale,
        'numeroBce': value.numeroBce,
        'numeroOnss': value.numeroOnss,
        'idtypeEntreprise': value.idtypeEntreprise,
        'idformeJuridique': value.idformeJuridique,
        'idtaille': value.idtaille,
        'actif': value.actif,
        'remarque': value.remarque,
        'idchefEntreprise': value.idchefEntreprise,
        'idpersonne': value.idpersonne,
        'fonction': value.fonction,
        'gsm': TelephoneDtoToJSON(value.gsm),
        'telephone': TelephoneDtoToJSON(value.telephone),
        'email': EmailDtoToJSON(value.email),
        'iban': value.iban,
        'bic': value.bic,
        'idcontactBeneficiaire': value.idcontactBeneficiaire,
        'sendToAdress': value.sendToAdress,
        'adresse': value.adresse,
        'idcodePostal': value.idcodePostal,
        'codePostalText': value.codePostalText,
        'boite': value.boite,
        'localite': value.localite,
        'commune': value.commune,
        'pays': value.pays,
        'idsCommissionParitaire': value.idsCommissionParitaire,
        'isContactCE': value.isContactCE,
        'contact': value.contact,
        'contactFonction': value.contactFonction,
        'contactTel': value.contactTel,
        'contactGsm': value.contactGsm,
        'hasNoAssurances': value.hasNoAssurances,
    };
}

