/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbMatiereReferentielGridDto
 */
export interface FcbMatiereReferentielGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbMatiereReferentielGridDto
     */
    idmatiere?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielGridDto
     */
    idreferentiel?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbMatiereReferentielGridDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielGridDto
     */
    travail?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielGridDto
     */
    nbheures?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielGridDto
     */
    pcReussite?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielGridDto
     */
    examen?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielGridDto
     */
    categorie?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielGridDto
     */
    idprojetFinancement?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbMatiereReferentielGridDto
     */
    projetFinancement?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielGridDto
     */
    idmatiereReferentiel?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FcbMatiereReferentielGridDto
     */
    isLinkedToClasse?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FcbMatiereReferentielGridDto
     */
    degre?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielGridDto
     */
    niveau?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbMatiereReferentielGridDto
     */
    matiereParent?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbMatiereReferentielGridDto
     */
    uaa?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbMatiereReferentielGridDto
     */
    isVisibleBulletin?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbMatiereReferentielGridDto
     */
    sommative?: boolean | null;
}

/**
 * Check if a given object implements the FcbMatiereReferentielGridDto interface.
 */
export function instanceOfFcbMatiereReferentielGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbMatiereReferentielGridDtoFromJSON(json: any): FcbMatiereReferentielGridDto {
    return FcbMatiereReferentielGridDtoFromJSONTyped(json, false);
}

export function FcbMatiereReferentielGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbMatiereReferentielGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idmatiere': !exists(json, 'idmatiere') ? undefined : json['idmatiere'],
        'idreferentiel': !exists(json, 'idreferentiel') ? undefined : json['idreferentiel'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'travail': !exists(json, 'travail') ? undefined : json['travail'],
        'nbheures': !exists(json, 'nbheures') ? undefined : json['nbheures'],
        'pcReussite': !exists(json, 'pcReussite') ? undefined : json['pcReussite'],
        'examen': !exists(json, 'examen') ? undefined : json['examen'],
        'categorie': !exists(json, 'categorie') ? undefined : json['categorie'],
        'idprojetFinancement': !exists(json, 'idprojetFinancement') ? undefined : json['idprojetFinancement'],
        'projetFinancement': !exists(json, 'projetFinancement') ? undefined : json['projetFinancement'],
        'idmatiereReferentiel': !exists(json, 'idmatiereReferentiel') ? undefined : json['idmatiereReferentiel'],
        'isLinkedToClasse': !exists(json, 'isLinkedToClasse') ? undefined : json['isLinkedToClasse'],
        'degre': !exists(json, 'degre') ? undefined : json['degre'],
        'niveau': !exists(json, 'niveau') ? undefined : json['niveau'],
        'matiereParent': !exists(json, 'matiereParent') ? undefined : json['matiereParent'],
        'uaa': !exists(json, 'uaa') ? undefined : json['uaa'],
        'isVisibleBulletin': !exists(json, 'isVisibleBulletin') ? undefined : json['isVisibleBulletin'],
        'sommative': !exists(json, 'sommative') ? undefined : json['sommative'],
    };
}

export function FcbMatiereReferentielGridDtoToJSON(value?: FcbMatiereReferentielGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idmatiere': value.idmatiere,
        'idreferentiel': value.idreferentiel,
        'description': value.description,
        'travail': value.travail,
        'nbheures': value.nbheures,
        'pcReussite': value.pcReussite,
        'examen': value.examen,
        'categorie': value.categorie,
        'idprojetFinancement': value.idprojetFinancement,
        'projetFinancement': value.projetFinancement,
        'idmatiereReferentiel': value.idmatiereReferentiel,
        'isLinkedToClasse': value.isLinkedToClasse,
        'degre': value.degre,
        'niveau': value.niveau,
        'matiereParent': value.matiereParent,
        'uaa': value.uaa,
        'isVisibleBulletin': value.isVisibleBulletin,
        'sommative': value.sommative,
    };
}

