/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbFeuillePresenceGridDto
 */
export interface FcbFeuillePresenceGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbFeuillePresenceGridDto
     */
    particulariteCours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFeuillePresenceGridDto
     */
    anneeAcademique?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFeuillePresenceGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFeuillePresenceGridDto
     */
    masculin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFeuillePresenceGridDto
     */
    codeClasse?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFeuillePresenceGridDto
     */
    idclasse?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFeuillePresenceGridDto
     */
    isuser?: number;
}

/**
 * Check if a given object implements the FcbFeuillePresenceGridDto interface.
 */
export function instanceOfFcbFeuillePresenceGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbFeuillePresenceGridDtoFromJSON(json: any): FcbFeuillePresenceGridDto {
    return FcbFeuillePresenceGridDtoFromJSONTyped(json, false);
}

export function FcbFeuillePresenceGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbFeuillePresenceGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'particulariteCours': !exists(json, 'particulariteCours') ? undefined : json['particulariteCours'],
        'anneeAcademique': !exists(json, 'anneeAcademique') ? undefined : json['anneeAcademique'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'masculin': !exists(json, 'masculin') ? undefined : json['masculin'],
        'codeClasse': !exists(json, 'codeClasse') ? undefined : json['codeClasse'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'isuser': !exists(json, 'isuser') ? undefined : json['isuser'],
    };
}

export function FcbFeuillePresenceGridDtoToJSON(value?: FcbFeuillePresenceGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'particulariteCours': value.particulariteCours,
        'anneeAcademique': value.anneeAcademique,
        'nom': value.nom,
        'masculin': value.masculin,
        'codeClasse': value.codeClasse,
        'idclasse': value.idclasse,
        'isuser': value.isuser,
    };
}

