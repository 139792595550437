import printJS from "print-js";

export function downloadPdf({
  data,
  downloadFileName,
}: {
  data: Uint8Array;
  downloadFileName: string;
}) {
  const blob = new Blob([data], { type: "application/pdf" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.setAttribute("style", "display:none;");
  a.href = url;
  a.download = downloadFileName;
  a.click();
  window.URL.revokeObjectURL(url);
  document.removeChild(a);
}

export function printPdf({
  data,
  onLoadingStart,
  onLoadingEnd,
}: {
  data: Uint8Array;
  onLoadingStart?: () => void;
  onLoadingEnd?: () => void;
}) {
  const blob = new Blob([data], { type: "application/pdf" });
  const url = window.URL.createObjectURL(blob);
  printJS({
    printable: url,
    type: "pdf",
    onLoadingStart,
    onLoadingEnd,
  });
}
