import { Classes, Dialog } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";
import { SmallFormGenerator } from "../../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";
import * as Yup from "yup";
import {
  LieuFormationAgrementApi,
  LieuFormationAgrementVisiteMultipleDto,
  LieuFormationAgrementVisiteMultipleDtoFromJSON
} from "../../../../../../api";
import { LieuFormationAgrementVisite } from "..";
import { endOfToday } from "date-fns";

const StyledDialog = styled(Dialog)`
  width: 500px;
  height: auto;
  background-color: white;
`;

export interface ILieuFormationAgrementMultipleVisiteDialogProps {
  dialogOpen: boolean;
  onClose: (refresh: boolean) => void;
  idslieuFormationAgrement: number[];
}

export const LieuFormationAgrementMultipleVisiteDialog: React.FunctionComponent<ILieuFormationAgrementMultipleVisiteDialogProps> = ({
  dialogOpen,
  onClose,
  idslieuFormationAgrement
}) => {
  const { t } = useTl();
  const api = useApiService(LieuFormationAgrementApi);

  const { data, loading, saveItem, saving, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () => {
          return LieuFormationAgrementVisiteMultipleDtoFromJSON({
            idslieuFormationAgrement: idslieuFormationAgrement
          });
        },
        saveApiFn: async (d: LieuFormationAgrementVisiteMultipleDto) => {
          return api.lieuFormationAgrementVisiteMultiple({ LieuFormationAgrementVisiteMultipleDto: d });
        },
        onSaved: () => onClose(true)
      }),
      [api, idslieuFormationAgrement, onClose]
    )
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape({
        dateVisite: Yup.date()
          .nullable()
          .max(endOfToday(), t(ETLCodes.DateCannotBeInFurture))
          .required(t(ETLCodes.Required))
      }),
    [t]
  );

  return (
    <StyledDialog title={t(ETLCodes.Visite)} isOpen={dialogOpen} onClose={() => onClose(false)}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose(false)}
          showDeleteButton={false}
          saving={saving}
          loading={loading}
          validationErrors={validationErrors}
        >
          <LieuFormationAgrementVisite />
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
