import * as React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { ClauseApi, ClauseSearch, FcbClauseGridDto, EBooleanSearchTypes } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { useAbortableApiServiceFactory, useApiService } from "../../../../../hooks/useApiService";
import { ETLCodes } from "../../../../../locales";

const Container = styled.div`
  display: flex;
`;

interface IClauseListPageProps {}

export const ClauseListPage: React.FunctionComponent<IClauseListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ClauseApi);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbClauseGridDto) => (
          <Container>
            <ViewButton minimal={true} onClick={() => history.push(`${ERoutes.clause}/${row.idclause}/detail/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${ERoutes.clause}/${row.idclause}/detail/edit`)} />
          </Container>
        )
      },
      {
        header: () => t(ETLCodes.ClauseListPageTitle),
        fieldName: "title"
      },
      {
        header: () => t(ETLCodes.ClauseListPageClause),
        fieldName: "description"
      },
      {
        header: () => t(ETLCodes.ClauseListPageStade),
        fieldName: "stade"
      }
    ],
    [history, t]
  );

  const addItemFunction = React.useCallback(() => history.push(`${ERoutes.clause}/0/detail/edit`), [history]);

  const getCriteriasFn = React.useCallback(() => api.clauseGetSearchCriterias({ includeListsValues: true }), [api]);

  const apiFactory = useAbortableApiServiceFactory(ClauseApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: ClauseSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.clauseBaseSearch({ ClauseSearch: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      columns={columns}
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      addFunc={addItemFunction}
      breadCrumbs={[{ text: t(ETLCodes.ClausesParticulieres), route: ERoutes.clause }]}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
    />
  );
};
