import { Button, Colors, Icon, Intent, Menu, MenuItem, Popover, Tooltip } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";

import { ContratRuptureDialog, ContratSuspensionDetailDialog } from "../../..";
import {
  ContratApi,
  ContratGridDto,
  ContratSearchDto,
  ContratSuspensionDto,
  ContratSuspensionDtoFromJSON,
  EStatutContrat,
  ETypeContrat,
  RapportHopeApi
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { AddButton, CircleColumn, CustomBulletList, EditButton, ViewButton } from "../../../../../components";
import { useApiService, useTabMessage, useTheme, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { computeContratStatutColor } from "../../../../../utils/contratStatutColor";
import { Guid } from "../../../../../utils/guid";

const LegendHeaderContainer = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

const PopoverContent = styled.div`
  padding: 0.5rem;
`;

export interface IApprenantContratListProps {}

export const ApprenantContratList: React.FunctionComponent<IApprenantContratListProps> = props => {
  const { t, tUnsafe } = useTl();
  const { theme, ifapmeSide } = useTheme();
  const isHope = React.useMemo(() => ifapmeSide === "hope", [ifapmeSide]);
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const idapprenant = React.useMemo(() => +id, [id]);
  const [currentSuspension, setCurrentSuspension] = React.useState<ContratSuspensionDto>(null);
  const [currentContratId, setCurrentContratId] = React.useState(0);
  const api = useApiService(ContratApi);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: ContratSearchDto) => {
      sObj.idapprenant = idapprenant;
      return api.contratBaseSearch({ ContratSearchDto: sObj });
    },
    [api, idapprenant]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const onAddSuspensionClick = React.useCallback(idcontrat => {
    setCurrentSuspension(ContratSuspensionDtoFromJSON({ idcontrat, idcontratSuspension: 0 }));
  }, []);

  const onRuptureClick = React.useCallback(idcontrat => {
    setCurrentContratId(idcontrat);
  }, []);

  const identifier = React.useMemo(() => Guid.newGuid(), []);
  const { openTabThenSendMessage } = useTabMessage(identifier);

  const rapportApi = useApiService(RapportHopeApi);
  const fetchIdsrapportContrat = React.useCallback(() => {
    return rapportApi.rapportHopeGetIdsrapportContrat();
  }, [rapportApi]);
  const { data: idsrapportContrat } = useQuery("idsrapportContrat", fetchIdsrapportContrat);
  const printContrat = React.useCallback(
    (idcontrat: number, typeContrat: ETypeContrat) => {
      const idrapportContrat = idsrapportContrat?.[typeContrat];
      openTabThenSendMessage(`/#${ERoutes.SSRSReports}/${idrapportContrat}`, [idcontrat]);
    },
    [idsrapportContrat, openTabThenSendMessage]
  );

  const buttons = React.useMemo(
    () => (
      <Popover
        position="left"
        content={
          <PopoverContent>
            {isHope ? (
              <Menu>
                <MenuItem
                  text={t(ETLCodes.ContratAlternanceCC)}
                  intent={Intent.PRIMARY}
                  onClick={() => push(`${ERoutes.contrat}/-1/detail/CC/edit?idapprenant=${idapprenant}`)}
                />
                <MenuItem
                  text={t(ETLCodes.ConventionStageCS)}
                  intent={Intent.PRIMARY}
                  onClick={() => push(`${ERoutes.contrat}/-1/detail/CS/edit?idapprenant=${idapprenant}`)}
                />
                <MenuItem
                  text={t(ETLCodes.StagePratiqueProPP)}
                  intent={Intent.PRIMARY}
                  onClick={() => push(`${ERoutes.contrat}/-1/detail/PP/edit?idapprenant=${idapprenant}`)}
                />
                <MenuItem
                  text={t(ETLCodes.ConventionFALTCF)}
                  intent={Intent.PRIMARY}
                  onClick={() => push(`${ERoutes.contrat}/-1/detail/CF/edit?idapprenant=${idapprenant}`)}
                />
                <MenuItem
                  text={t(ETLCodes.StageDecouverteMetierDM)}
                  intent={Intent.PRIMARY}
                  onClick={() => push(`${ERoutes.contrat}/-1/detail/DM/edit?idapprenant=${idapprenant}`)}
                />
              </Menu>
            ) : (
              <Menu>
                <MenuItem
                  text={t(ETLCodes.StageObservationSO)}
                  intent={Intent.PRIMARY}
                  onClick={() => push(`${ERoutes.contrat}/-1/detail/SO/edit?idapprenant=${idapprenant}`)}
                />
                <MenuItem
                  text={t(ETLCodes.ContratAlternanceCommunCCE)}
                  intent={Intent.PRIMARY}
                  onClick={() => push(`${ERoutes.contrat}/-1/detail/CCE/edit?idapprenant=${idapprenant}`)}
                />
                <MenuItem
                  text={t(ETLCodes.ConventionStageExterneCSE)}
                  intent={Intent.PRIMARY}
                  onClick={() => push(`${ERoutes.contrat}/-1/detail/CSE/edit?idapprenant=${idapprenant}`)}
                />
              </Menu>
            )}
          </PopoverContent>
        }
      >
        <AddButton text={t(ETLCodes.AddContrat)} intent={Intent.PRIMARY} />
      </Popover>
    ),
    [idapprenant, isHope, push, t]
  );

  const onEditSuspension = React.useCallback(
    async (row: ContratGridDto) => {
      const suspension = await api.contratGetSuspension({
        idcontratSuspension: row.idEditableCurrentSuspension
      });
      setCurrentSuspension(suspension);
    },
    [api]
  );

  const hopeContratTypes = React.useMemo(
    () => [
      ETypeContrat.CC,
      ETypeContrat.CA,
      ETypeContrat.CS,
      ETypeContrat.CF,
      ETypeContrat.DM,
      ETypeContrat.JI,
      ETypeContrat.PP
    ],
    []
  );

  const walterContratTypes = React.useMemo(() => [ETypeContrat.SO, ETypeContrat.CCE, ETypeContrat.CSE], []);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ContratGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => window.open(`#${ERoutes.contrat}/${row.idcontrat}/detail/${row.type}/view`)}
            />
            {(isHope && hopeContratTypes.includes(row.type)) || (!isHope && walterContratTypes.includes(row.type)) ? (
              <>
                <EditButton
                  minimal={true}
                  onClick={() => window.open(`#${ERoutes.contrat}/${row.idcontrat}/detail/${row.type}/edit`)}
                />
              </>
            ) : null}
            {isHope && (
              <Popover
                position="bottom"
                content={
                  <PopoverContent>
                    <Menu>
                      {isHope && hopeContratTypes.includes(row.type) && (
                        <MenuItem
                          icon={IconNames.STOP}
                          text={t(ETLCodes.Rupture)}
                          intent={Intent.PRIMARY}
                          onClick={() => onRuptureClick(row.idcontrat)}
                          disabled={[EStatutContrat.Rompu, EStatutContrat.SansSuite].includes(row.statut)}
                        />
                      )}
                      {((isHope && hopeContratTypes.includes(row.type)) ||
                        (!isHope && [ETypeContrat.SO].includes(row.type))) && (
                        <MenuItem
                          icon={IconNames.PAUSE}
                          text={t(row.idEditableCurrentSuspension ? ETLCodes.ModifierSuspension : ETLCodes.Suspension)}
                          intent={Intent.PRIMARY}
                          onClick={() =>
                            row.idEditableCurrentSuspension
                              ? onEditSuspension(row)
                              : onAddSuspensionClick(row.idcontrat)
                          }
                          disabled={[EStatutContrat.Rompu, EStatutContrat.SansSuite].includes(row.statut)}
                        />
                      )}
                      {isHope && hopeContratTypes.includes(row.type) && (
                        <MenuItem
                          icon={IconNames.DOCUMENT}
                          text={t(ETLCodes.Avenant)}
                          intent={Intent.PRIMARY}
                          onClick={() => {}}
                          disabled
                        />
                      )}
                      {!!idsrapportContrat?.[row.type] && (
                        <MenuItem
                          icon={IconNames.PRINT}
                          text={t(ETLCodes.Impression)}
                          intent={Intent.PRIMARY}
                          onClick={() => printContrat(row.idcontrat, row.type)}
                        />
                      )}
                    </Menu>
                  </PopoverContent>
                }
              >
                <Button icon={IconNames.CHEVRON_DOWN} minimal={true}></Button>
              </Popover>
            )}
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.NomMetier),
        fieldName: "nomMetier"
      },
      {
        computed: true,
        alignment: "center",
        autoFitContent: true,
        header: () => (
          <LegendHeaderContainer>
            <span>{t(ETLCodes.Statut)}</span>
            <Tooltip
              content={
                <CustomBulletList
                  title={t(ETLCodes.Statut)}
                  items={[
                    {
                      text: t(ETLCodes.EnCours),
                      color: theme.sucessColor
                    },
                    {
                      text: t(ETLCodes.Rompu),
                      color: theme.dangerColor
                    },
                    {
                      text: t(ETLCodes.Suspendu),
                      color: theme.warningColor
                    },
                    {
                      text: t(ETLCodes.JamaisDeContrat),
                      color: Colors.BLACK
                    },
                    {
                      text: t(ETLCodes.Termine),
                      color: Colors.VIOLET4
                    },
                    {
                      text: t(ETLCodes.SansSuite),
                      hideBullet: true
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip>
          </LegendHeaderContainer>
        ),
        fieldName: "statut",
        render: (row: ContratGridDto) => (
          <CircleColumn
            color={computeContratStatutColor(row.statut, theme)}
            tooltip={tUnsafe(`StatutContrat_${row.statut}`)}
          />
        )
      },
      {
        header: () => t(ETLCodes.DateDebut),
        fieldName: "dateDebut"
      },
      {
        header: () => t(ETLCodes.DateFin),
        fieldName: "dateFin"
      },
      {
        header: () => t(ETLCodes.DateRupture),
        fieldName: "rompuLe"
      },
      {
        header: () => t(ETLCodes.LieuFormation),
        fieldName: "lastLieuFormation"
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code"
      }
    ],
    [
      hopeContratTypes,
      idsrapportContrat,
      isHope,
      onAddSuspensionClick,
      onEditSuspension,
      onRuptureClick,
      printContrat,
      t,
      tUnsafe,
      theme,
      walterContratTypes
    ]
  );

  const onClose = React.useCallback(
    (toSave: ContratSuspensionDto) => {
      if (!!toSave) {
        search();
      }
      setCurrentSuspension(null);
    },
    [search]
  );

  return (
    <>
      <FieldSet title={t(ETLCodes.TableResults, { count: totalCount })} rightElement={buttons}>
        <DataTable
          dateFormat="dd/MM/yyyy"
          tableState={tableState}
          loading={loading}
          columns={columns}
          filterMode="OnEnter"
        ></DataTable>
      </FieldSet>
      {!!currentSuspension && (
        <ContratSuspensionDetailDialog
          dialogOpen={!!currentSuspension}
          currentSuspension={currentSuspension}
          onClose={onClose}
        />
      )}
      {!!currentContratId && (
        <ContratRuptureDialog
          dialogOpen={!!currentContratId && currentContratId > 0}
          currentContratId={currentContratId}
          onClose={(refresh: boolean) => {
            setCurrentContratId(0);
            if (refresh) search();
          }}
        />
      )}
    </>
  );
};
