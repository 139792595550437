import { Classes, Dialog } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";

import { useTl } from "../../hooks";
import { useReferential } from "../../hooks/useReferential";
import { ETLCodes } from "../../locales";
import { FGWalterSelectInput } from "../formGenerator";
import { SmallFormGenerator } from "../SmallFormGenerator";

const StyledDialog = styled(Dialog)`
  width: 600px;
  height: auto;
  background-color: white;

  & .${Classes.RADIO} {
    align-self: center;
    min-width: 125px;
  }
`;

export interface IEditStatutEntreeApprenantDialogProps {
  currentId: number;
  idapprenant: number;
  isDialogOpen: boolean;
  onClose: (idstatutApprenant?: number) => void;
}

export const EditStatutEntreeApprenantDialog: React.FunctionComponent<IEditStatutEntreeApprenantDialogProps> = ({
  isDialogOpen,
  onClose,
  currentId,
  idapprenant
}) => {
  const { t } = useTl();
  const [statutsApprenant, sLoading] = useReferential(a => a.referentialGetStatutsProspect({ idapprenant }), true, []);

  const FormSchema = React.useMemo(() => {
    let schema = {
      idstatutEntree: Yup.number()
        .nullable()
        .required(t(ETLCodes.Required))
    };

    return Yup.object().shape(schema);
  }, [t]);

  return (
    <StyledDialog title={t(ETLCodes.StatutApprenant)} isOpen={isDialogOpen} onClose={() => onClose()}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={{ idstatutEntree: currentId }}
          onSubmit={d => onClose(d.idstatutEntree)}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose()}
          showDeleteButton={false}
          forceEnableSave
          enableDirtyCheck={false}
        >
          <FGWalterSelectInput
            name="idstatutEntree"
            label={t(ETLCodes.Statut)}
            items={statutsApprenant}
            loading={sLoading}
          />
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
