import * as React from "react";
import produce from "immer";

export type EventTypes =
  | "CLASSE_HORAIRE_CALENDRIER_ITEM_SAVED"
  | "CLASSE_HORAIRE_SAVED"
  | "CLASSE_HORAIRE_ACTIONS"
  | "CLASSE_SAVED"
  | "CLASSE_HORAIRE_REMARQUE_SAVED"
  | "CLASSE_HORAIRE_EDIT"
  | "SEARCH_TABLE_REFRESH"
  | "CLASSE_COHERENCE_REFRESH"
  | "DELIBERATION_ANNEE_CHANGED"
  | "DELIBERATION_APPRENANT_CHANGED"
  | "DELIBERATION_SAVED"
  | "DELIBERATION_REFRESH"
  | "REFERENTIAL_REFRESH"
  | "REFRESH_NB_TABS"
  | "DASHBOARD_COUNTS_REFRESH_Inscriptions"
  | "DASHBOARD_COUNTS_REFRESH_Suivis"
  | "DASHBOARD_COUNTS_REFRESH_SuivisSL"
  | "DASHBOARD_COUNTS_REFRESH_Absence"
  | "DASHBOARD_COUNTS_REFRESH_Dossiers"
  | "DASHBOARD_COUNTS_REFRESH_PratiquePro"
  | "REFRESH_APPRENANT_SORTIR"
  | "RESET_DROPZONE_FILES";
interface IEventsContext {
  dispatchEvent: (event: EventTypes, data?: any) => void;
  subscribeToEvent: (event: EventTypes, callback: CallBackType) => void;
  unsubscribeEvent: (event: EventTypes, callback: CallBackType) => void;
}
const EventsContext = React.createContext<IEventsContext>(null);

interface IEventsProviderProps {}

type CallBackType = (data: any) => void;

export const EventsProvider: React.FunctionComponent<IEventsProviderProps> = ({ children }) => {
  const [events, setEvents] = React.useState<{ [event: string]: Array<CallBackType> }>({});
  const dispatchEvent = React.useCallback(
    (event: EventTypes, data?: any) => {
      if (!events[event]) return;
      for (let e of events[event]) {
        e(data);
      }
    },
    [events]
  );

  const subscribeToEvent = React.useCallback((event: EventTypes, callback: CallBackType) => {
    setEvents(prev =>
      produce(prev, draft => {
        if (!draft[event]) {
          draft[event] = [];
        }
        draft[event].push(callback);
      })
    );
  }, []);

  const unsubscribeEvent = React.useCallback((event: EventTypes, callback: CallBackType) => {
    setEvents(prev =>
      produce(prev, draft => {
        if (draft[event]) {
          draft[event] = draft[event].filter(cb => cb !== callback);
        }
      })
    );
  }, []);

  return (
    <EventsContext.Provider value={{ dispatchEvent, subscribeToEvent, unsubscribeEvent }}>
      {children}
    </EventsContext.Provider>
  );
};

export const useEventsContext = () => React.useContext(EventsContext);

// export const useEventSubscribe = (event: string, callback: (data: any) => void) => {
//   const { subscribeToEvent, unsubscribeEvent } = useEventsContext();

//   React.useEffect(() => {
//     subscribeToEvent(event, callback);
//     return () => unsubscribeEvent(event, callback);
//   }, []);
// };
