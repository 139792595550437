/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRechercheClasseMiseAJourMasseGridDto
 */
export interface FcbRechercheClasseMiseAJourMasseGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseMiseAJourMasseGridDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseMiseAJourMasseGridDto
     */
    nomClasse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseMiseAJourMasseGridDto
     */
    anneeAcademique?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseMiseAJourMasseGridDto
     */
    lieucours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseMiseAJourMasseGridDto
     */
    typeCours?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRechercheClasseMiseAJourMasseGridDto
     */
    dateCours?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseMiseAJourMasseGridDto
     */
    genre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseMiseAJourMasseGridDto
     */
    particulariteCours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseMiseAJourMasseGridDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseMiseAJourMasseGridDto
     */
    idclasse?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseMiseAJourMasseGridDto
     */
    idcentre?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseMiseAJourMasseGridDto
     */
    idlieu?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseMiseAJourMasseGridDto
     */
    idsecteur?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseMiseAJourMasseGridDto
     */
    idmetier?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseMiseAJourMasseGridDto
     */
    iddegre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseMiseAJourMasseGridDto
     */
    typeProjet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseMiseAJourMasseGridDto
     */
    statutLocalisationAgrement?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseMiseAJourMasseGridDto
     */
    dateValidation?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRechercheClasseMiseAJourMasseGridDto
     */
    premierCours?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseMiseAJourMasseGridDto
     */
    conseiller?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseMiseAJourMasseGridDto
     */
    iduser?: number | null;
}

/**
 * Check if a given object implements the FcbRechercheClasseMiseAJourMasseGridDto interface.
 */
export function instanceOfFcbRechercheClasseMiseAJourMasseGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRechercheClasseMiseAJourMasseGridDtoFromJSON(json: any): FcbRechercheClasseMiseAJourMasseGridDto {
    return FcbRechercheClasseMiseAJourMasseGridDtoFromJSONTyped(json, false);
}

export function FcbRechercheClasseMiseAJourMasseGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRechercheClasseMiseAJourMasseGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'nomClasse': !exists(json, 'nomClasse') ? undefined : json['nomClasse'],
        'anneeAcademique': !exists(json, 'anneeAcademique') ? undefined : json['anneeAcademique'],
        'lieucours': !exists(json, 'lieucours') ? undefined : json['lieucours'],
        'typeCours': !exists(json, 'typeCours') ? undefined : json['typeCours'],
        'dateCours': !exists(json, 'dateCours') ? undefined : (json['dateCours'] === null ? null : new Date(json['dateCours'])),
        'genre': !exists(json, 'genre') ? undefined : json['genre'],
        'particulariteCours': !exists(json, 'particulariteCours') ? undefined : json['particulariteCours'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'idlieu': !exists(json, 'idlieu') ? undefined : json['idlieu'],
        'idsecteur': !exists(json, 'idsecteur') ? undefined : json['idsecteur'],
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'iddegre': !exists(json, 'iddegre') ? undefined : json['iddegre'],
        'typeProjet': !exists(json, 'typeProjet') ? undefined : json['typeProjet'],
        'statutLocalisationAgrement': !exists(json, 'statutLocalisationAgrement') ? undefined : json['statutLocalisationAgrement'],
        'dateValidation': !exists(json, 'dateValidation') ? undefined : json['dateValidation'],
        'premierCours': !exists(json, 'premierCours') ? undefined : (json['premierCours'] === null ? null : new Date(json['premierCours'])),
        'conseiller': !exists(json, 'conseiller') ? undefined : json['conseiller'],
        'iduser': !exists(json, 'iduser') ? undefined : json['iduser'],
    };
}

export function FcbRechercheClasseMiseAJourMasseGridDtoToJSON(value?: FcbRechercheClasseMiseAJourMasseGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'nomClasse': value.nomClasse,
        'anneeAcademique': value.anneeAcademique,
        'lieucours': value.lieucours,
        'typeCours': value.typeCours,
        'dateCours': value.dateCours === undefined ? undefined : (value.dateCours === null ? null : value.dateCours.toISOString()),
        'genre': value.genre,
        'particulariteCours': value.particulariteCours,
        'remarque': value.remarque,
        'idclasse': value.idclasse,
        'idcentre': value.idcentre,
        'idlieu': value.idlieu,
        'idsecteur': value.idsecteur,
        'idmetier': value.idmetier,
        'iddegre': value.iddegre,
        'typeProjet': value.typeProjet,
        'statutLocalisationAgrement': value.statutLocalisationAgrement,
        'dateValidation': value.dateValidation,
        'premierCours': value.premierCours === undefined ? undefined : (value.premierCours === null ? null : value.premierCours.toISOString()),
        'conseiller': value.conseiller,
        'iduser': value.iduser,
    };
}

