/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbFormateurGridDto
 */
export interface FcbFormateurGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurGridDto
     */
    idformateur?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurGridDto
     */
    telProf?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurGridDto
     */
    telephone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurGridDto
     */
    gsm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurGridDto
     */
    gsmProf?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurGridDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurGridDto
     */
    emailProf?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFormateurGridDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFormateurGridDto
     */
    giveClasse?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFormateurGridDto
     */
    jure?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbFormateurGridDto
     */
    localiteCentre?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbFormateurGridDto
     */
    contrat?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurGridDto
     */
    readonly computedLocaliteCentre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurGridDto
     */
    readonly computedContrat?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurGridDto
     */
    dateDeces?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurGridDto
     */
    searchNom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurGridDto
     */
    searchPrenom?: string | null;
}

/**
 * Check if a given object implements the FcbFormateurGridDto interface.
 */
export function instanceOfFcbFormateurGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbFormateurGridDtoFromJSON(json: any): FcbFormateurGridDto {
    return FcbFormateurGridDtoFromJSONTyped(json, false);
}

export function FcbFormateurGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbFormateurGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'telProf': !exists(json, 'telProf') ? undefined : json['telProf'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'gsm': !exists(json, 'gsm') ? undefined : json['gsm'],
        'gsmProf': !exists(json, 'gsmProf') ? undefined : json['gsmProf'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'emailProf': !exists(json, 'emailProf') ? undefined : json['emailProf'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'giveClasse': !exists(json, 'giveClasse') ? undefined : json['giveClasse'],
        'jure': !exists(json, 'jure') ? undefined : json['jure'],
        'localiteCentre': !exists(json, 'localiteCentre') ? undefined : json['localiteCentre'],
        'contrat': !exists(json, 'contrat') ? undefined : json['contrat'],
        'computedLocaliteCentre': !exists(json, 'computedLocaliteCentre') ? undefined : json['computedLocaliteCentre'],
        'computedContrat': !exists(json, 'computedContrat') ? undefined : json['computedContrat'],
        'dateDeces': !exists(json, 'dateDeces') ? undefined : (json['dateDeces'] === null ? null : new Date(json['dateDeces'])),
        'searchNom': !exists(json, 'searchNom') ? undefined : json['searchNom'],
        'searchPrenom': !exists(json, 'searchPrenom') ? undefined : json['searchPrenom'],
    };
}

export function FcbFormateurGridDtoToJSON(value?: FcbFormateurGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idformateur': value.idformateur,
        'nom': value.nom,
        'prenom': value.prenom,
        'telProf': value.telProf,
        'telephone': value.telephone,
        'gsm': value.gsm,
        'gsmProf': value.gsmProf,
        'email': value.email,
        'emailProf': value.emailProf,
        'actif': value.actif,
        'giveClasse': value.giveClasse,
        'jure': value.jure,
        'localiteCentre': value.localiteCentre,
        'contrat': value.contrat,
        'dateDeces': value.dateDeces === undefined ? undefined : (value.dateDeces === null ? null : value.dateDeces.toISOString()),
        'searchNom': value.searchNom,
        'searchPrenom': value.searchPrenom,
    };
}

