import * as React from "react";
import { Route, Switch } from "react-router";

import { ERoutes } from "../../../../../../AppRouter";
import { ApprenantBulletinTabs } from "./ApprenantBulletinTabs";

export interface IApprenantBulletinSwitchProps {}

export const ApprenantBulletinSwitch: React.FunctionComponent<IApprenantBulletinSwitchProps> = () => {
  return (
    <Switch>
      <Route path={`${ERoutes.apprenant}/:id/:tab/:anneeTab/:coursTypeTab?`} component={ApprenantBulletinTabs} />
    </Switch>
  );
};
