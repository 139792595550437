import { Button } from "@blueprintjs/core";
import { FieldSet } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { FO_FINAL_KEY, CarouselComparer, DoublonsDataCard, useDoublonsStateContext } from "..";
import { FormateurDoublonEditDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { nameof } from "../../../../../utils";

const Container = styled.div`
  height: 70vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export interface IDoublonFormateurSignaletiqueProps {}

export const DoublonFormateurSignaletique: React.FunctionComponent<IDoublonFormateurSignaletiqueProps> = props => {
  const { t } = useTl();
  const { data, selectData, getSelectedIndex } = useDoublonsStateContext();

  const selectedIndex = React.useMemo(() => getSelectedIndex(`${FO_FINAL_KEY}.idformateur`) ?? 0, [getSelectedIndex]);
  const formateurContainer = React.useCallback(
    (index: number, nb: number) => {
      const formateur: FormateurDoublonEditDto = data?.formateurs[index];
      if (!formateur) return;

      return (
        <FieldSet
          key={formateur.idformateur}
          title={
            <TitleContainer>
              {index !== selectedIndex ? (
                <Button
                  text={t(ETLCodes.Conserver)}
                  icon={"arrow-left"}
                  onClick={() => selectData(`${FO_FINAL_KEY}.idformateur`, index)}
                />
              ) : (
                <div />
              )}
              <ViewButton
                text={t(ETLCodes.FormateurNb, { nb: nb, id: formateur.idformateur })}
                onClick={() =>
                  window.open(`#${ERoutes.formateur}/${formateur.idformateur}/signaletique/view`, "_blank")
                }
              />
              <div />
            </TitleContainer>
          }
          titlePosition="center"
        >
          <DoublonsDataCard
            extValue={
              <InformationContainer>
                <div>{t(ETLCodes.NbLocalisations, { nb: formateur.nbLocalisations })}</div>
                <div>{t(ETLCodes.NbAgrements, { nb: formateur.nbAgrements })}</div>
                <div>{t(ETLCodes.NbSuivis, { nb: formateur.nbSuivis })}</div>
                <div>{t(ETLCodes.NbDoublonRemplacements, { nb: formateur.nbRemplacements })}</div>
              </InformationContainer>
            }
            label={t(ETLCodes.Informations)}
            interactive={false}
          />
          <DoublonsDataCard
            fieldPath={`formateurs[${index}].azureEmail`}
            label={t(ETLCodes.EmailIfapme)}
            finalEntityKey={FO_FINAL_KEY}
            entityIdKey={nameof<FormateurDoublonEditDto>("idformateur")}
            interactive={false}
            forceSelected={index === selectedIndex}
          />
          <DoublonsDataCard
            fieldPath={`formateurs[${index}].telephoneFormateur.idtelephone`}
            valuePath={`formateurs[${index}].telephoneFormateur.numero`}
            label={t(ETLCodes.Telephone)}
            finalEntityKey={FO_FINAL_KEY}
            entityIdKey={nameof<FormateurDoublonEditDto>("idformateur")}
          />
          <DoublonsDataCard
            fieldPath={`formateurs[${index}].gsmFormateur.idtelephone`}
            valuePath={`formateurs[${index}].gsmFormateur.numero`}
            label={t(ETLCodes.Gsm)}
            finalEntityKey={FO_FINAL_KEY}
            entityIdKey={nameof<FormateurDoublonEditDto>("idformateur")}
          />
          <DoublonsDataCard
            fieldPath={`formateurs[${index}].emailFormateur.idemail`}
            valuePath={`formateurs[${index}].emailFormateur.adresseEmail`}
            label={t(ETLCodes.Email)}
            finalEntityKey={FO_FINAL_KEY}
            entityIdKey={nameof<FormateurDoublonEditDto>("idformateur")}
          />
        </FieldSet>
      );
    },
    [data?.formateurs, selectData, selectedIndex, t]
  );

  return (
    <Container>
      <CarouselComparer leftElement={formateurContainer(selectedIndex, selectedIndex + 1)}>
        {data?.formateurs?.length > 1 &&
          data?.formateurs
            ?.filter((a, i) => i !== selectedIndex)
            ?.map((a, i) => formateurContainer(i + (i >= selectedIndex ? 1 : 0), i + (i >= selectedIndex ? 2 : 1)))}
      </CarouselComparer>
    </Container>
  );
};
