import { ButtonContainer, IDataTableColumn } from "nsitools-react";
import * as React from "react";

import { DoublonsApi, DoublonsGridDto, DoublonsSearchDto } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { EditButton, SearchTablePage } from "../../../../components";
import { useAuth } from "../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface IDoublonsListPageProps {}

export const DoublonsListPage: React.FunctionComponent<IDoublonsListPageProps> = props => {
  const { t } = useTl();
  const api = useApiService(DoublonsApi);
  const { hasPermission } = useAuth();

  const apiFactory = useAbortableApiServiceFactory(DoublonsApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (nextSearch?: DoublonsSearchDto) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.doublonsBaseSearch({ DoublonsSearchDto: nextSearch });
    },
    [apiFactory]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: DoublonsGridDto) => (
          <ButtonContainer>
            <EditButton
              minimal={true}
              onClick={() => window.open(`#${ERoutes.doublon}/fusion/${row.idpersonne1}-${row.idpersonne2}`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Personne1),
        fieldName: "idpersonne1",
        disableSorting: true,
        render: (row: DoublonsGridDto) => `${row.nom1} ${row.prenom1}`
      },
      {
        header: () => t(ETLCodes.Personne2),
        fieldName: "idpersonne2",
        disableSorting: true,
        render: (row: DoublonsGridDto) => `${row.nom2} ${row.prenom2}`
      },
      {
        header: () => t(ETLCodes.DateNaissance1),
        fieldName: "dateNaissance1",
        disableSorting: true
      },
      {
        header: () => t(ETLCodes.DateNaissance2),
        fieldName: "dateNaissance2",
        disableSorting: true
      },
      {
        header: () => t(ETLCodes.RegistreNational1),
        fieldName: "registreNational1",
        disableSorting: true
      },
      {
        header: () => t(ETLCodes.RegistreNational2),
        fieldName: "registreNational2",
        disableSorting: true
      },
      {
        header: () => t(ETLCodes.Apprenant),
        fieldName: "doublonApprenant",
        disableSorting: true
      },
      {
        header: () => t(ETLCodes.Representant),
        fieldName: "doublonRepresentant",
        disableSorting: true
      },
      {
        header: () => t(ETLCodes.Formateur),
        fieldName: "doublonFormateur",
        disableSorting: true
      },
      {
        header: () => t(ETLCodes.ChefEntreprise),
        fieldName: "doublonChefEntreprise",
        disableSorting: true
      },
      {
        header: () => t(ETLCodes.Tuteur),
        fieldName: "doublonTuteur",
        disableSorting: true
      },
      {
        header: () => t(ETLCodes.Contact),
        fieldName: "doublonContact",
        disableSorting: true
      }
    ],
    [t]
  );

  const getCriteria = React.useCallback(async () => {
    let criterias = await api.doublonsGetSearchCriterias({ includeListsValues: true });
    return criterias;
  }, [api]);

  const onNew = React.useCallback(() => {
    window.open(`#${ERoutes.doublon}/new`);
  }, []);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteria}
      searchFunction={search}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.Doublons), route: ERoutes.doublon }]}
      keyFieldName="idpersonne1"
      sortKeys={{
        nom1: "ASC",
        nom2: "ASC"
      }}
      searchStateInitialSearch={false}
      enableFilter={false}
      addFunc={hasPermission("DOUBLON", "RW", "NEW") && onNew}
    />
  );
};
