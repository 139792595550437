import { FieldGroup, useFGContext } from "nsitools-react";
import * as React from "react";

import { EStatutContrat, ETypeContrat } from "../../../../../../api";
import { FGHalfDaysInput, FGWalterDateMaskInput, FGWalterSelectInput } from "../../../../../../components";
import { useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

export interface IContratDecouverteMetierPanelProps {
  typeContrat: ETypeContrat;
  readonly?: boolean;
}

export const ContratDecouverteMetierPanel: React.FunctionComponent<IContratDecouverteMetierPanelProps> = ({
  typeContrat,
  readonly = false
}) => {
  const { t } = useTl();
  const { formik } = useFGContext();

  const [stades, sLoading] = useReferential(
    a => a.referentialGetStadeContratDM({ currentId: formik?.values?.idstade ?? 0 }),
    false,
    [formik?.values?.idstade]
  );
  const [metiers, mLoading] = useReferential(
    a =>
      a.referentialMetierByStadeForContrat({
        idStade: +(formik?.values?.idstade ?? 0),
        currentId: +(formik?.values?.idmetier ?? 0)
      }),
    false,
    [formik?.values?.idstade, formik?.values?.idmetier]
  );

  const [lieuxSignature, lsLoading] = useReferential(a => a.referentialGetLieuxSignature());

  const maximumReached = React.useMemo(
    () =>
      !!formik?.values?.decouverteMetierDto &&
      Object.entries(formik?.values?.decouverteMetierDto).filter(
        ([key, value]) => (key.startsWith("am") || key.startsWith("pm")) && value === true
      ).length === 10,
    [formik?.values?.decouverteMetierDto]
  );

  const softRO = React.useMemo(() => [EStatutContrat.Rompu].includes(formik?.values?.statut), [formik?.values?.statut]);

  return (
    <>
      <FieldGroup
        columns={2}
        collapsable
        fieldsetProps={{
          title: t(ETLCodes.Detail)
        }}
      >
        <FieldGroup>
          <FGWalterSelectInput
            name="idstade"
            label={t(ETLCodes.Stade)}
            items={stades}
            loading={sLoading}
            readonly={softRO || readonly}
          />
          <FGWalterSelectInput
            name="idmetier"
            label={t(ETLCodes.Metier)}
            items={metiers}
            loading={mLoading}
            readonly={formik?.values?.idcontrat > 0 || softRO || readonly}
          />
        </FieldGroup>
      </FieldGroup>
      <FieldGroup
        columns={2}
        collapsable
        fieldsetProps={{
          title: t(ETLCodes.LieuxDates)
        }}
      >
        <FieldGroup>
          <FGWalterDateMaskInput name="dateDebut" label={t(ETLCodes.DateDebut)} readonly={softRO || readonly} />
          <FGWalterDateMaskInput name="dateFin" label={t(ETLCodes.DateFin)} readonly={softRO || readonly} />
          <FGWalterDateMaskInput name="dateSignature" label={t(ETLCodes.DateSignature)} readonly={softRO || readonly} />
          <FGWalterSelectInput
            name="idlieuSignature"
            label={t(ETLCodes.LieuSignature)}
            items={lieuxSignature}
            loading={lsLoading}
            readonly={softRO || readonly}
          />
          <FGWalterDateMaskInput
            name="decouverteMetierDto.dateTransmission"
            label={t(ETLCodes.DateTransmission)}
            readonly={softRO || readonly}
          />
        </FieldGroup>
      </FieldGroup>
      <FieldGroup
        columns={3}
        collapsable
        fieldsetProps={{
          title: t(ETLCodes.DetailsPrestations)
        }}
      >
        <FieldGroup>
          <FGHalfDaysInput
            label={t(ETLCodes.Jour1)}
            amName="decouverteMetierDto.am1"
            pmName="decouverteMetierDto.pm1"
            amDisabled={value => maximumReached && !value}
            pmDisabled={value => maximumReached && !value}
            readonly={softRO || readonly}
          />
          <FGHalfDaysInput
            label={t(ETLCodes.Jour2)}
            amName="decouverteMetierDto.am2"
            pmName="decouverteMetierDto.pm2"
            amDisabled={value => maximumReached && !value}
            pmDisabled={value => maximumReached && !value}
            readonly={softRO || readonly}
          />
          <FGHalfDaysInput
            label={t(ETLCodes.Jour3)}
            amName="decouverteMetierDto.am3"
            pmName="decouverteMetierDto.pm3"
            amDisabled={value => maximumReached && !value}
            pmDisabled={value => maximumReached && !value}
            readonly={softRO || readonly}
          />
          <FGHalfDaysInput
            label={t(ETLCodes.Jour4)}
            amName="decouverteMetierDto.am4"
            pmName="decouverteMetierDto.pm4"
            amDisabled={value => maximumReached && !value}
            pmDisabled={value => maximumReached && !value}
            readonly={softRO || readonly}
          />
          <FGHalfDaysInput
            label={t(ETLCodes.Jour5)}
            amName="decouverteMetierDto.am5"
            pmName="decouverteMetierDto.pm5"
            amDisabled={value => maximumReached && !value}
            pmDisabled={value => maximumReached && !value}
            readonly={softRO || readonly}
          />
        </FieldGroup>
        <FieldGroup>
          <FGHalfDaysInput
            label={t(ETLCodes.Jour6)}
            amName="decouverteMetierDto.am6"
            pmName="decouverteMetierDto.pm6"
            amDisabled={value => maximumReached && !value}
            pmDisabled={value => maximumReached && !value}
            readonly={softRO || readonly}
          />
          <FGHalfDaysInput
            label={t(ETLCodes.Jour7)}
            amName="decouverteMetierDto.am7"
            pmName="decouverteMetierDto.pm7"
            amDisabled={value => maximumReached && !value}
            pmDisabled={value => maximumReached && !value}
            readonly={softRO || readonly}
          />
          <FGHalfDaysInput
            label={t(ETLCodes.Jour8)}
            amName="decouverteMetierDto.am8"
            pmName="decouverteMetierDto.pm8"
            amDisabled={value => maximumReached && !value}
            pmDisabled={value => maximumReached && !value}
            readonly={softRO || readonly}
          />
          <FGHalfDaysInput
            label={t(ETLCodes.Jour9)}
            amName="decouverteMetierDto.am9"
            pmName="decouverteMetierDto.pm9"
            amDisabled={value => maximumReached && !value}
            pmDisabled={value => maximumReached && !value}
            readonly={softRO || readonly}
          />
          <FGHalfDaysInput
            label={t(ETLCodes.Jour10)}
            amName="decouverteMetierDto.am10"
            pmName="decouverteMetierDto.pm10"
            amDisabled={value => maximumReached && !value}
            pmDisabled={value => maximumReached && !value}
            readonly={softRO || readonly}
          />
        </FieldGroup>
      </FieldGroup>
    </>
  );
};
