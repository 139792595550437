import { useSessionStorageState } from "ahooks";
import * as React from "react";

type CriteriaRouteType = {
  [route: string]: any;
};

interface ISearchCriteriaContext {
  setCriterias: (route: string, criteria: object) => void;
  criterias: CriteriaRouteType;
  clearCriterias: () => void;
}
const SearchCriteriaContext = React.createContext<ISearchCriteriaContext>(null);

const SESSION_STORAGE_KEY = "SearchCriterias";

export const SearchCriteriaProvider: React.FunctionComponent = ({ children }) => {
  const [routeCriteria, setRouteCriteria] = useSessionStorageState<CriteriaRouteType>(SESSION_STORAGE_KEY, {});

  const setCriterias = React.useCallback(
    (route: string, sObj: any) => {
      const origString = JSON.stringify(routeCriteria[route]);
      const objString = JSON.stringify(sObj);
      if (origString !== objString) {
        setRouteCriteria(c => ({
          ...c,
          [route]: sObj
        }));
      }
    },
    [routeCriteria, setRouteCriteria]
  );

  const clearCriterias = React.useCallback(() => {
    setRouteCriteria({});
  }, [setRouteCriteria]);

  return (
    <SearchCriteriaContext.Provider value={{ criterias: routeCriteria, setCriterias, clearCriterias }}>
      {children}
    </SearchCriteriaContext.Provider>
  );
};

export const useSearchCriteriaContext = () => React.useContext(SearchCriteriaContext);
