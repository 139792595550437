/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IAggregateResult } from './IAggregateResult';
import {
    IAggregateResultFromJSON,
    IAggregateResultFromJSONTyped,
    IAggregateResultToJSON,
} from './IAggregateResult';
import type { ParcoursFormationGridDto } from './ParcoursFormationGridDto';
import {
    ParcoursFormationGridDtoFromJSON,
    ParcoursFormationGridDtoFromJSONTyped,
    ParcoursFormationGridDtoToJSON,
} from './ParcoursFormationGridDto';

/**
 * 
 * @export
 * @interface ParcoursFormationGridDtoPaginatedResults
 */
export interface ParcoursFormationGridDtoPaginatedResults {
    /**
     * 
     * @type {number}
     * @memberof ParcoursFormationGridDtoPaginatedResults
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<ParcoursFormationGridDto>}
     * @memberof ParcoursFormationGridDtoPaginatedResults
     */
    results?: Array<ParcoursFormationGridDto> | null;
    /**
     * 
     * @type {Array<IAggregateResult>}
     * @memberof ParcoursFormationGridDtoPaginatedResults
     */
    aggregateResults?: Array<IAggregateResult> | null;
}

/**
 * Check if a given object implements the ParcoursFormationGridDtoPaginatedResults interface.
 */
export function instanceOfParcoursFormationGridDtoPaginatedResults(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ParcoursFormationGridDtoPaginatedResultsFromJSON(json: any): ParcoursFormationGridDtoPaginatedResults {
    return ParcoursFormationGridDtoPaginatedResultsFromJSONTyped(json, false);
}

export function ParcoursFormationGridDtoPaginatedResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParcoursFormationGridDtoPaginatedResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'results': !exists(json, 'results') ? undefined : (json['results'] === null ? null : (json['results'] as Array<any>).map(ParcoursFormationGridDtoFromJSON)),
        'aggregateResults': !exists(json, 'aggregateResults') ? undefined : (json['aggregateResults'] === null ? null : (json['aggregateResults'] as Array<any>).map(IAggregateResultFromJSON)),
    };
}

export function ParcoursFormationGridDtoPaginatedResultsToJSON(value?: ParcoursFormationGridDtoPaginatedResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'results': value.results === undefined ? undefined : (value.results === null ? null : (value.results as Array<any>).map(ParcoursFormationGridDtoToJSON)),
        'aggregateResults': value.aggregateResults === undefined ? undefined : (value.aggregateResults === null ? null : (value.aggregateResults as Array<any>).map(IAggregateResultToJSON)),
    };
}

