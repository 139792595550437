import { Button, Colors, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useSet } from "ahooks";
import {
  ButtonsBloc,
  DataTable,
  FGCustomPanel,
  FGTextInput,
  FieldGroup,
  FieldSet,
  InlineButtonContainer,
  useGridState,
  useSearchApi
} from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import styled, { css } from "styled-components";

import { AddButton, SelectButton, SmallFormGenerator } from "../..";
import {
  InstitutionSelectorApi,
  InstitutionSelectorGridDto,
  InstitutionSelectorSearch,
  InstitutionSelectorSearchFromJSON
} from "../../../api";
import { ERoutes } from "../../../AppRouter";
import { useApiService, useTabMessage, useTheme, useTl } from "../../../hooks";
import { ETLCodes } from "../../../locales";
import { Guid } from "../../../utils/guid";

const Container = styled.div`
  margin: 1rem 1rem 0 1rem;
`;

export interface IInstitutionSelectorProps {
  excludedInstitutionIds?: number[];
  onInstitutionSelected: (selectedInstitutionIds?: number[]) => void;
  rightElement?: any;
  allowMultipleSelection?: boolean;
  preSelectedIds?: number[];
}

export const InstitutionSelector: React.FunctionComponent<IInstitutionSelectorProps> = ({
  excludedInstitutionIds = [],
  onInstitutionSelected,
  rightElement,
  allowMultipleSelection = false,
  preSelectedIds = []
}) => {
  const { t } = useTl();
  const history = useHistory();
  const { theme } = useTheme();
  const [selectedInstitutionIds, { add: addInstitutionId, remove: removeInstitutionId }] = useSet<number>(
    preSelectedIds
  );
  const api = useApiService(InstitutionSelectorApi);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [10, 15],
    pageSize: 10,
    sortKeys: { denomination: "ASC" }
  });

  const initialData = React.useMemo(() => {
    return InstitutionSelectorSearchFromJSON({
      selectedInstitutionIds,
      excludedInstitutionIds,
      forceSkip: 0,
      forceTake: 10
    });
  }, [excludedInstitutionIds, selectedInstitutionIds]);

  const { totalCount } = tableState;
  const searchFunction = React.useCallback(
    (sObj?: InstitutionSelectorSearch) => {
      let nextSObj = { ...sObj };
      Object.keys(sObj)
        .filter(k => !["forceSkip", "forceTake", "filter", "sortKey", "aggregateKeys", "parameters"].includes(k))
        .forEach(k => {
          nextSObj[k] = !!sObj[k]?.value ? sObj[k] : undefined;
        });
      return api.institutionSelectorBaseSearch({
        InstitutionSelectorSearch: {
          ...nextSObj,
          selectedInstitutionIds: Array.from(selectedInstitutionIds),
          excludedInstitutionIds
        }
      });
    },
    [api, excludedInstitutionIds, selectedInstitutionIds]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const selectInstitution = React.useCallback(
    (id: number) => {
      if (allowMultipleSelection) {
        selectedInstitutionIds.has(id) ? removeInstitutionId(id) : addInstitutionId(id);
      } else {
        onInstitutionSelected([id]);
      }
    },
    [addInstitutionId, allowMultipleSelection, onInstitutionSelected, removeInstitutionId, selectedInstitutionIds]
  );

  const onRowClick = React.useCallback(
    (item: InstitutionSelectorGridDto) => {
      selectInstitution(item.idinstitution);
    },
    [selectInstitution]
  );

  const identifier = React.useMemo(() => Guid.newGuid(), []);
  const { messageValue, clearMessageValue } = useTabMessage(identifier);
  const add = React.useCallback(() => {
    window.open(`#${ERoutes.institution}/0/detail/edit?cfs=${identifier}`, "_blank");
  }, [identifier]);

  React.useEffect(() => {
    if (messageValue) {
      search();
      selectInstitution(messageValue);
      clearMessageValue();
    }
  }, [clearMessageValue, messageValue, search, selectInstitution]);

  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.Denomination),
        fieldName: "denomination"
      },
      {
        header: () => t(ETLCodes.Adresse),
        fieldName: "adresse"
      },
      {
        header: () => t(ETLCodes.CodePostal),
        fieldName: "codePostal"
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      },
      {
        header: () => t(ETLCodes.Gsm),
        fieldName: "gsm"
      },
      {
        header: () => t(ETLCodes.Telephone),
        fieldName: "telephone"
      },
      {
        header: () => t(ETLCodes.Email),
        fieldName: "email"
      }
    ],
    [t]
  );

  const customizeRowStyleCallback = React.useCallback(
    (item: InstitutionSelectorGridDto) => {
      const isSelected = selectedInstitutionIds.has(item.idinstitution);

      return css`
        & * {
          color: ${isSelected ? Colors.WHITE : Colors.BLACK} !important;
        }

        & & > td {
          background-color: ${isSelected ? theme.primaryColor : "initial"} !important;
        }

        background-color: ${isSelected ? theme.primaryColor : "transparent"} !important;
      `;
    },
    [selectedInstitutionIds, theme.primaryColor]
  );

  const onInstitutionSelect = React.useCallback(() => {
    onInstitutionSelected(Array.from(selectedInstitutionIds));
  }, [onInstitutionSelected, selectedInstitutionIds]);

  const finalRightElements = React.useMemo(() => {
    return allowMultipleSelection ? (
      <>
        <SelectButton onClick={onInstitutionSelect}></SelectButton>
        {rightElement}
      </>
    ) : (
      rightElement
    );
  }, [allowMultipleSelection, onInstitutionSelect, rightElement]);

  const tableTitle = React.useMemo(
    () =>
      `${t(ETLCodes.TableResults, { count: totalCount })}${
        selectedInstitutionIds.size > 0
          ? " - " + selectedInstitutionIds.size + " " + t(ETLCodes.SelectedInstitutions)
          : ""
      }`,
    [selectedInstitutionIds.size, t, totalCount]
  );

  return (
    <Container>
      <SmallFormGenerator
        enableDirtyCheck={false}
        initialValues={initialData}
        onSubmit={c => search(c)}
        editMode
        hideButtons={true}
        onCancel={() => history.push(`${ERoutes.institution}`)}
      >
        <FieldGroup columns={2}>
          <FieldGroup>
            <FGTextInput name="denomination.value" label={t(ETLCodes.Denomination)} maxLength={100} />
            <FGTextInput name="telephone.value" label={t(ETLCodes.Tel)} maxLength={20} />
            <FGTextInput name="gsm.value" label={t(ETLCodes.Gsm)} maxLength={20} />
            <FGTextInput name="email.value" label={t(ETLCodes.General_Email)} maxLength={100} />
          </FieldGroup>
          <FieldGroup>
            <FGTextInput name="adresse.value" label={t(ETLCodes.Adresse)} maxLength={100} />
            <FGTextInput name="codePostal.value" label={t(ETLCodes.CodePostal)} maxLength={6} />
            <FGTextInput name="localite.value" label={t(ETLCodes.Localite)} maxLength={50} />
          </FieldGroup>
        </FieldGroup>
        <FieldGroup>
          <FGCustomPanel>
            {ctx => (
              <InlineButtonContainer>
                <ButtonsBloc></ButtonsBloc>
                <ButtonsBloc>
                  <Button icon={IconNames.SEARCH} type="submit" text={t(ETLCodes.Search)} intent={Intent.PRIMARY} />
                  <AddButton onClick={add} text={t(ETLCodes.General_Add)} intent={Intent.PRIMARY} minimal={false} />
                </ButtonsBloc>
              </InlineButtonContainer>
            )}
          </FGCustomPanel>
        </FieldGroup>
        <FGCustomPanel>
          {ctx => (
            <FieldSet title={tableTitle} rightElement={finalRightElements}>
              <DataTable
                dateFormat="dd-MM-yyyy"
                tableState={tableState}
                loading={loading}
                columns={columns}
                onRowClick={onRowClick}
                customizeRowStyle={customizeRowStyleCallback}
                htmlTableOptions={{ striped: true, interactive: true, bordered: true }}
              />
            </FieldSet>
          )}
        </FGCustomPanel>
      </SmallFormGenerator>
    </Container>
  );
};
