/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbMetierClauseLiesGridDtoPaginatedResults,
  FcbMetierClausesLieesDto,
  FilterCriteriaInfo,
  MetierClauseLieeSearch,
  SelectItem,
} from '../models/index';
import {
    FcbMetierClauseLiesGridDtoPaginatedResultsFromJSON,
    FcbMetierClauseLiesGridDtoPaginatedResultsToJSON,
    FcbMetierClausesLieesDtoFromJSON,
    FcbMetierClausesLieesDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    MetierClauseLieeSearchFromJSON,
    MetierClauseLieeSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface MetierClausesLieesBaseSearchRequest {
    MetierClauseLieeSearch?: MetierClauseLieeSearch;
}

export interface MetierClausesLieesGetClauseMetierRequest {
    idMetier?: number;
}

export interface MetierClausesLieesGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface MetierClausesLieesGetSelectItemsRequest {
    searchField: string;
}

export interface MetierClausesLieesSaveClauseMetierRequest {
    FcbMetierClausesLieesDto?: FcbMetierClausesLieesDto;
}

/**
 * 
 */
export class MetierClausesLieesApi extends runtime.BaseAPI {

    /**
     */
    async metierClausesLieesBaseSearchRaw(requestParameters: MetierClausesLieesBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbMetierClauseLiesGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/MetierClausesLiees/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MetierClauseLieeSearchToJSON(requestParameters.MetierClauseLieeSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbMetierClauseLiesGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async metierClausesLieesBaseSearch(requestParameters: MetierClausesLieesBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbMetierClauseLiesGridDtoPaginatedResults> {
        const response = await this.metierClausesLieesBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async metierClausesLieesGetClauseMetierRaw(requestParameters: MetierClausesLieesGetClauseMetierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbMetierClausesLieesDto>> {
        const queryParameters: any = {};

        if (requestParameters.idMetier !== undefined) {
            queryParameters['idMetier'] = requestParameters.idMetier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/MetierClausesLiees`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbMetierClausesLieesDtoFromJSON(jsonValue));
    }

    /**
     */
    async metierClausesLieesGetClauseMetier(requestParameters: MetierClausesLieesGetClauseMetierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbMetierClausesLieesDto> {
        const response = await this.metierClausesLieesGetClauseMetierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async metierClausesLieesGetSearchCriteriasRaw(requestParameters: MetierClausesLieesGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/MetierClausesLiees/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async metierClausesLieesGetSearchCriterias(requestParameters: MetierClausesLieesGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.metierClausesLieesGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async metierClausesLieesGetSelectItemsRaw(requestParameters: MetierClausesLieesGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling metierClausesLieesGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/MetierClausesLiees/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async metierClausesLieesGetSelectItems(requestParameters: MetierClausesLieesGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.metierClausesLieesGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async metierClausesLieesSaveClauseMetierRaw(requestParameters: MetierClausesLieesSaveClauseMetierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbMetierClausesLieesDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/MetierClausesLiees/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbMetierClausesLieesDtoToJSON(requestParameters.FcbMetierClausesLieesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbMetierClausesLieesDtoFromJSON(jsonValue));
    }

    /**
     */
    async metierClausesLieesSaveClauseMetier(requestParameters: MetierClausesLieesSaveClauseMetierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbMetierClausesLieesDto> {
        const response = await this.metierClausesLieesSaveClauseMetierRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
