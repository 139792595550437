/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EListSearchType } from './EListSearchType';
import {
    EListSearchTypeFromJSON,
    EListSearchTypeFromJSONTyped,
    EListSearchTypeToJSON,
} from './EListSearchType';
import type { EListSearchTypeEStatutContratCombinableCriteria } from './EListSearchTypeEStatutContratCombinableCriteria';
import {
    EListSearchTypeEStatutContratCombinableCriteriaFromJSON,
    EListSearchTypeEStatutContratCombinableCriteriaFromJSONTyped,
    EListSearchTypeEStatutContratCombinableCriteriaToJSON,
} from './EListSearchTypeEStatutContratCombinableCriteria';
import type { EStatutContrat } from './EStatutContrat';
import {
    EStatutContratFromJSON,
    EStatutContratFromJSONTyped,
    EStatutContratToJSON,
} from './EStatutContrat';

/**
 * 
 * @export
 * @interface EStatutContratListSearch
 */
export interface EStatutContratListSearch {
    /**
     * 
     * @type {Array<EListSearchTypeEStatutContratCombinableCriteria>}
     * @memberof EStatutContratListSearch
     */
    criterias?: Array<EListSearchTypeEStatutContratCombinableCriteria> | null;
    /**
     * 
     * @type {EListSearchType}
     * @memberof EStatutContratListSearch
     */
    searchMode?: EListSearchType;
    /**
     * 
     * @type {EStatutContrat}
     * @memberof EStatutContratListSearch
     */
    value?: EStatutContrat;
    /**
     * 
     * @type {EStatutContrat}
     * @memberof EStatutContratListSearch
     */
    secondaryValue?: EStatutContrat;
    /**
     * 
     * @type {Array<string>}
     * @memberof EStatutContratListSearch
     */
    readonly availableSearchTypes?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof EStatutContratListSearch
     */
    defaultSearchType?: string | null;
}

/**
 * Check if a given object implements the EStatutContratListSearch interface.
 */
export function instanceOfEStatutContratListSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EStatutContratListSearchFromJSON(json: any): EStatutContratListSearch {
    return EStatutContratListSearchFromJSONTyped(json, false);
}

export function EStatutContratListSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): EStatutContratListSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'criterias': !exists(json, 'criterias') ? undefined : (json['criterias'] === null ? null : (json['criterias'] as Array<any>).map(EListSearchTypeEStatutContratCombinableCriteriaFromJSON)),
        'searchMode': !exists(json, 'searchMode') ? undefined : EListSearchTypeFromJSON(json['searchMode']),
        'value': !exists(json, 'value') ? undefined : EStatutContratFromJSON(json['value']),
        'secondaryValue': !exists(json, 'secondaryValue') ? undefined : EStatutContratFromJSON(json['secondaryValue']),
        'availableSearchTypes': !exists(json, 'availableSearchTypes') ? undefined : json['availableSearchTypes'],
        'defaultSearchType': !exists(json, 'defaultSearchType') ? undefined : json['defaultSearchType'],
    };
}

export function EStatutContratListSearchToJSON(value?: EStatutContratListSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'criterias': value.criterias === undefined ? undefined : (value.criterias === null ? null : (value.criterias as Array<any>).map(EListSearchTypeEStatutContratCombinableCriteriaToJSON)),
        'searchMode': EListSearchTypeToJSON(value.searchMode),
        'value': EStatutContratToJSON(value.value),
        'secondaryValue': EStatutContratToJSON(value.secondaryValue),
        'defaultSearchType': value.defaultSearchType,
    };
}

