import * as React from "react";
import { Switch, Route } from "react-router";
import { ERoutes } from "../../../../../../AppRouter";
import { ChangementClasseList } from "./ChangementClasseList";
import { ChangementClasseDetail } from "./ChangementClasseDetail";

export interface IChangementClasseSwitchProps {}

export const ChangementClasseSwitch: React.FunctionComponent<IChangementClasseSwitchProps> = () => {
  return (
    <Switch>
      <Route
        path={`${ERoutes.apprenant}/:id/:tab/:inscriptionId/:tabInscription`}
        component={ChangementClasseList}
        exact
      />
      <Route
        path={`${ERoutes.apprenant}/:id/:tab/:inscriptionId/:tabInscription/:changementClasseId/:stateChangementClasse?`}
        component={ChangementClasseDetail}
      />
    </Switch>
  );
};
