import { Intent } from "@blueprintjs/core";
import { isAfter, isSameDay } from "date-fns";
import { isBefore } from "date-fns/esm";
import {
  ButtonContainer,
  DataTable,
  FieldSet,
  IDataTableColumn,
  showSuccess,
  useGridState,
  useSearchApi
} from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";

import { ContratSuspensionDetailDialog } from ".";
import {
  ContratApi,
  ContratSuspensionApi,
  ContratSuspensionDto,
  ContratSuspensionDtoFromJSON,
  ContratSuspensionSearchDto,
  EStatutContrat,
  ETypeContrat
} from "../../../../../api";
import { AddButton, DeleteButton, EditButton } from "../../../../../components";
import { useDialog } from "../../../../../contexts";
import { useApiService, useManageError, useTheme, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IContratSuspensionsPageProps {}

export const ContratSuspensionsPage: React.FunctionComponent<IContratSuspensionsPageProps> = props => {
  const { t } = useTl();
  const { ifapmeSide } = useTheme();
  const { id, type } = useParams<{ id: string; type: string; state: string }>();
  const idcontrat = React.useMemo(() => +id, [id]);
  const typeContrat = React.useMemo(() => type as ETypeContrat, [type]);
  const [currentSuspension, setCurrentSuspension] = React.useState<ContratSuspensionDto>(null);
  const [deleteLoading, setDeleteLoading] = React.useState(null);
  const api = useApiService(ContratSuspensionApi);
  const contratApi = useApiService(ContratApi);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { dateDebut: "DESC" }
  });

  const { totalCount, data } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: ContratSuspensionSearchDto) => {
      sObj.idcontrat = idcontrat;
      return api.contratSuspensionBaseSearch({ ContratSuspensionSearchDto: sObj });
    },
    [api, idcontrat]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const onEditClick = React.useCallback((dto: ContratSuspensionDto) => {
    setCurrentSuspension(dto);
  }, []);

  const { showDialogPromise } = useDialog();
  const { manageError } = useManageError();
  const onDeleteClick = React.useCallback(
    async (dto: ContratSuspensionDto) => {
      const result = await showDialogPromise({
        title: t(ETLCodes.DeleteDialogTitle),
        message: t(ETLCodes.DeleteConfirmationMessage)
      });

      if (result !== "yes") return;

      try {
        setDeleteLoading(dto.idcontratSuspension);
        await api.contratSuspensionDeleteContratSuspension({ idcontratSuspension: dto.idcontratSuspension });
        showSuccess(t(ETLCodes.SuspensionContratSupprimee));
      } catch (e) {
        manageError(e);
      } finally {
        search();
        setDeleteLoading(null);
      }
    },
    [api, manageError, search, showDialogPromise, t]
  );

  const fetchStatut = React.useCallback(async () => {
    if (!idcontrat) return null;
    return await contratApi.contratGetStatut({ idcontrat });
  }, [contratApi, idcontrat]);
  const { data: statut, isFetching: statutLoading } = useQuery(["contrat-statut", idcontrat], fetchStatut);
  const disabled = React.useMemo(() => !statut || [EStatutContrat.Rompu, EStatutContrat.SansSuite].includes(statut), [
    statut
  ]);

  const mode = React.useMemo(
    () =>
      [
        ETypeContrat.CC,
        ETypeContrat.CA,
        ETypeContrat.CS,
        ETypeContrat.CF,
        ETypeContrat.DM,
        ETypeContrat.JI,
        ETypeContrat.PP
      ].includes(typeContrat)
        ? "hope"
        : "walter",
    [typeContrat]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ContratSuspensionDto) => (
          <ButtonContainer>
            <EditButton
              minimal={true}
              onClick={() => onEditClick(row)}
              disabled={!!deleteLoading || disabled || ifapmeSide !== mode}
            />
            <DeleteButton
              minimal={true}
              onDelete={() => onDeleteClick(row)}
              loading={row.idcontratSuspension === deleteLoading}
              disabled={
                (!!deleteLoading && row.idcontratSuspension !== deleteLoading) || disabled || ifapmeSide !== mode
              }
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.DateDebut),
        fieldName: "dateDebut"
      },
      {
        header: () => t(ETLCodes.DateFin),
        fieldName: "dateFin"
      },
      {
        header: () => t(ETLCodes.Motif),
        fieldName: "motif"
      },
      {
        header: () => t(ETLCodes.Remarque),
        fieldName: "remarque"
      }
    ],
    [deleteLoading, disabled, ifapmeSide, mode, onDeleteClick, onEditClick, t]
  );

  const onAddClick = React.useCallback(() => {
    setCurrentSuspension(ContratSuspensionDtoFromJSON({ idcontrat, idcontratSuspension: 0 }));
  }, [idcontrat]);

  const onClose = React.useCallback(
    (toSave: ContratSuspensionDto) => {
      if (!!toSave) {
        search();
      }
      setCurrentSuspension(null);
    },
    [search]
  );

  const alreadySuspendedText = React.useMemo(
    () =>
      data &&
      data.some(
        s =>
          (isBefore(s.dateDebut, new Date()) || isSameDay(s.dateDebut, new Date())) &&
          (isSameDay(s.dateFin, new Date()) || isAfter(s.dateFin, new Date()))
      )
        ? t(ETLCodes.ContratAlreadySuspended)
        : null,
    [data, t]
  );

  const soonSuspendedText = React.useMemo(
    () => (data && data.some(s => isAfter(s.dateDebut, new Date())) ? t(ETLCodes.ContratSoonSuspended) : null),
    [data, t]
  );

  return (
    <>
      <FieldSet
        title={t(ETLCodes.TableResults, { count: totalCount })}
        rightElement={
          !disabled &&
          ifapmeSide === mode && (
            <AddButton
              tooltipText={alreadySuspendedText ?? soonSuspendedText}
              loading={statutLoading}
              onClick={!alreadySuspendedText && !soonSuspendedText ? onAddClick : null}
              text={t(ETLCodes.General_Add)}
              intent={Intent.PRIMARY}
            />
          )
        }
      >
        <DataTable
          dateFormat="dd/MM/yyyy"
          tableState={tableState}
          loading={loading || statutLoading}
          columns={columns}
          filterMode="OnEnter"
        ></DataTable>
      </FieldSet>
      {!!currentSuspension && (
        <ContratSuspensionDetailDialog
          dialogOpen={!!currentSuspension}
          currentSuspension={currentSuspension}
          onClose={onClose}
        />
      )}
    </>
  );
};
