/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { TextSearch } from './TextSearch';
import {
    TextSearchFromJSON,
    TextSearchFromJSONTyped,
    TextSearchToJSON,
} from './TextSearch';

/**
 * 
 * @export
 * @interface InstitutionSelectorSearch
 */
export interface InstitutionSelectorSearch {
    /**
     * 
     * @type {TextSearch}
     * @memberof InstitutionSelectorSearch
     */
    denomination?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof InstitutionSelectorSearch
     */
    adresse?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof InstitutionSelectorSearch
     */
    codePostal?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof InstitutionSelectorSearch
     */
    localite?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof InstitutionSelectorSearch
     */
    gsm?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof InstitutionSelectorSearch
     */
    telephone?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof InstitutionSelectorSearch
     */
    email?: TextSearch;
    /**
     * 
     * @type {Array<number>}
     * @memberof InstitutionSelectorSearch
     */
    selectedInstitutionIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof InstitutionSelectorSearch
     */
    excludedInstitutionIds?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InstitutionSelectorSearch
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof InstitutionSelectorSearch
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof InstitutionSelectorSearch
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstitutionSelectorSearch
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof InstitutionSelectorSearch
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionSelectorSearch
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the InstitutionSelectorSearch interface.
 */
export function instanceOfInstitutionSelectorSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InstitutionSelectorSearchFromJSON(json: any): InstitutionSelectorSearch {
    return InstitutionSelectorSearchFromJSONTyped(json, false);
}

export function InstitutionSelectorSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstitutionSelectorSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'denomination': !exists(json, 'denomination') ? undefined : TextSearchFromJSON(json['denomination']),
        'adresse': !exists(json, 'adresse') ? undefined : TextSearchFromJSON(json['adresse']),
        'codePostal': !exists(json, 'codePostal') ? undefined : TextSearchFromJSON(json['codePostal']),
        'localite': !exists(json, 'localite') ? undefined : TextSearchFromJSON(json['localite']),
        'gsm': !exists(json, 'gsm') ? undefined : TextSearchFromJSON(json['gsm']),
        'telephone': !exists(json, 'telephone') ? undefined : TextSearchFromJSON(json['telephone']),
        'email': !exists(json, 'email') ? undefined : TextSearchFromJSON(json['email']),
        'selectedInstitutionIds': !exists(json, 'selectedInstitutionIds') ? undefined : json['selectedInstitutionIds'],
        'excludedInstitutionIds': !exists(json, 'excludedInstitutionIds') ? undefined : json['excludedInstitutionIds'],
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function InstitutionSelectorSearchToJSON(value?: InstitutionSelectorSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'denomination': TextSearchToJSON(value.denomination),
        'adresse': TextSearchToJSON(value.adresse),
        'codePostal': TextSearchToJSON(value.codePostal),
        'localite': TextSearchToJSON(value.localite),
        'gsm': TextSearchToJSON(value.gsm),
        'telephone': TextSearchToJSON(value.telephone),
        'email': TextSearchToJSON(value.email),
        'selectedInstitutionIds': value.selectedInstitutionIds,
        'excludedInstitutionIds': value.excludedInstitutionIds,
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

