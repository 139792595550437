/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbExportGridDto
 */
export interface FcbExportGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbExportGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbExportGridDto
     */
    idexport?: number;
}

/**
 * Check if a given object implements the FcbExportGridDto interface.
 */
export function instanceOfFcbExportGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbExportGridDtoFromJSON(json: any): FcbExportGridDto {
    return FcbExportGridDtoFromJSONTyped(json, false);
}

export function FcbExportGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbExportGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'idexport': !exists(json, 'idexport') ? undefined : json['idexport'],
    };
}

export function FcbExportGridDtoToJSON(value?: FcbExportGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nom': value.nom,
        'idexport': value.idexport,
    };
}

