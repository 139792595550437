import { Classes, Dialog } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";

import { ServiceTutelleUserLieApi, ServiceUserLieEditDto, ServiceUserLieEditDtoFromJSON } from "../../../../../api";
import { FGWalterDateMaskInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

const StyledDialog = styled(Dialog)`
  width: 600px;
  height: auto;
  background-color: white;
`;

export interface IServiceTutelleUserLieDialogProps {
  initialData: ServiceUserLieEditDto;
  dialogOpen: boolean;
  onClose: (refresh?: boolean) => void;
}

export const ServiceTutelleUserLieDialog: React.FunctionComponent<IServiceTutelleUserLieDialogProps> = ({
  initialData,
  dialogOpen,
  onClose
}) => {
  const { t } = useTl();
  const api = useApiService(ServiceTutelleUserLieApi);

  const [users, loadingUsers] = useReferential(
    a => a.referentialGetCalcsForService({ idService: initialData?.idServiceTutelle }),
    false,
    [initialData?.idServiceTutelle]
  );

  const { data, saveItem, saving, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () => {
          return ServiceUserLieEditDtoFromJSON({
            idServiceTutelle: initialData?.idServiceTutelle,
            idUser: initialData?.idUser,
            calcFrom: initialData?.calcFrom,
            calcTo: initialData?.calcTo
          });
        },
        saveApiFn: async (d: ServiceUserLieEditDto) => {
          return await api.serviceTutelleUserLieSaveServiceTutelleUserLie({ ServiceUserLieEditDto: d });
        },
        onSaved: () => {
          onClose(true);
        },
        getDeps: [initialData]
      }),
      [api, initialData, onClose]
    )
  );

  const formSchema = React.useMemo(() => {
    return Yup.object().shape(
      {
        idUser: Yup.string()
          .nullable()
          .required(t(ETLCodes.Required)),
        calcFrom: Yup.date()
          .nullable()
          .when("calcTo", (value, schema) =>
            !!value ? schema.max(Yup.ref("calcTo"), t(ETLCodes.DateDebutGreaterThanDateFin)) : schema
          ),
        calcTo: Yup.date()
          .nullable()
          .when("calcFrom", (value, schema) =>
            !!value ? schema.min(Yup.ref("calcFrom"), t(ETLCodes.DateFinGreaterThanDateDebut)) : schema
          )
      },
      [["calcTo", "calcFrom"]]
    );
  }, [t]);

  const isUserDisabled = React.useMemo(() => {
    return !!data?.idUser;
  }, [data?.idUser]);

  return (
    <StyledDialog title={t(ETLCodes.CALCRSA)} isOpen={dialogOpen} onClose={() => onClose()}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          saving={saving}
          editMode
          validationSchema={formSchema}
          validationErrors={validationErrors}
        >
          <FGWalterSelectInput
            name="idUser"
            label={t(ETLCodes.Collaborateur)}
            items={users}
            loading={loadingUsers}
            disabled={isUserDisabled}
          />
          <FGWalterDateMaskInput name="calcFrom" label={t(ETLCodes.DateDebut)} />
          <FGWalterDateMaskInput name="calcTo" label={t(ETLCodes.DateFin)} />
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
