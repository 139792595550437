import { ButtonContainer } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";

import { AgrementLocalisationApi, FcbRechercheAgrementLocalisationDto } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface IAgrementClasseListPageProps {}

export const AgrementClasseListPage: React.FunctionComponent<IAgrementClasseListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(AgrementLocalisationApi);
  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbRechercheAgrementLocalisationDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.agrementClasse}/${row.idplanAgrement}/detail/view`)}
            />
            <EditButton
              minimal={true}
              disabled={row.statutLocalisationAgrement === "Validé"}
              onClick={() => history.push(`${ERoutes.agrementClasse}/${row.idplanAgrement}/detail/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Annee),
        fieldName: "anneeAcademique"
      },
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "centre"
      },
      {
        header: () => t(ETLCodes.DateValidation),
        fieldName: "dateValidation"
      },
      {
        header: () => t(ETLCodes.Statut),
        fieldName: "statutLocalisationAgrement"
      }
    ],
    [history, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.agrementClasse}/0/detail/edit`);
  }, [history]);

  const apiFactory = useAbortableApiServiceFactory(AgrementLocalisationApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(() => {
    const { api: abortableApi, abortController } = apiFactory();
    lastAbortController.current = abortController;
    return abortableApi.agrementLocalisationGetSearchCriterias({ includeListsValues: true });
  }, [apiFactory]);

  const getCriteriasFn = React.useCallback(
    sObj => api.agrementLocalisationBaseSearch({ RechercheAgrementLocalisationSearch: sObj }),
    [api]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      searchStateInitialSearch={false}
      getCriteriasFunction={searchFn}
      searchFunction={getCriteriasFn}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.AgrementClasses), route: ERoutes.agrementClasse }]}
      addFunc={onAddItem}
      sortKeys={{ anneeAcademique: "DESC" }}
    />
  );
};
