import { Page } from "react-pdf";
import React, { RefObject, useEffect, useRef } from "react";
import styled from "styled-components";
import { PDFDocumentProxy } from "pdfjs-dist/types/display/api";
import { Colors } from "@blueprintjs/core";

export type PdfViewerPagesProps = {
  pdf: PDFDocumentProxy;
  displayAll: boolean;
  pageNumber: number;
  height: number;
  rotate: number;
  containerRef?: RefObject<HTMLDivElement>;
};

const PagesContainer = styled.div`
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    max-width: 100%;
    max-height: 100%;
    border: ${Colors.GRAY4} solid 1px;
    scrollbar-width: thin;
    overflow-y: auto;
    overflow-x: auto;
  }
`;

const AllPages = ({ pdf, height, rotate }: Pick<PdfViewerPagesProps, "pdf" | "height" | "rotate">) => {
  return (
    <>
      {Array.from(new Array(pdf.numPages), (el, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          height={height}
          rotate={rotate}
        />
      ))}
    </>
  );
};

export default ({ containerRef, pdf, displayAll, pageNumber, height, rotate }: PdfViewerPagesProps) => {
  const divRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (divRef.current && displayAll) {
      const pageElement = divRef.current.querySelector(`*:nth-child(${pageNumber})`);
      if (pageElement) pageElement.scrollIntoView();
    }
  }, [displayAll, pageNumber]);
  return (
    <PagesContainer ref={containerRef}>
      <div ref={divRef}>
        {pdf && displayAll && <AllPages pdf={pdf} height={height} rotate={rotate} />}
        {pdf && !displayAll && (
          <Page
            key={`page_${pageNumber}`}
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            height={height}
            rotate={rotate}
          ></Page>
        )}
      </div>
    </PagesContainer>
  );
};
