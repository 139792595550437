/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbRechercheClasseHoraireRemplacementDefinitiveDtoPaginatedResults,
  FcbRemplacementDefinitifDto,
  FilterCriteriaInfo,
  RechercheClasseHoraireRemplacementDefinitiveSearch,
  SelectItem,
} from '../models/index';
import {
    FcbRechercheClasseHoraireRemplacementDefinitiveDtoPaginatedResultsFromJSON,
    FcbRechercheClasseHoraireRemplacementDefinitiveDtoPaginatedResultsToJSON,
    FcbRemplacementDefinitifDtoFromJSON,
    FcbRemplacementDefinitifDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    RechercheClasseHoraireRemplacementDefinitiveSearchFromJSON,
    RechercheClasseHoraireRemplacementDefinitiveSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface RemplacementDefinitiveBaseSearchRequest {
    RechercheClasseHoraireRemplacementDefinitiveSearch?: RechercheClasseHoraireRemplacementDefinitiveSearch;
}

export interface RemplacementDefinitiveGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface RemplacementDefinitiveGetSelectItemsRequest {
    searchField: string;
}

export interface RemplacementDefinitiveSaveRequest {
    FcbRemplacementDefinitifDto?: FcbRemplacementDefinitifDto;
}

/**
 * 
 */
export class RemplacementDefinitiveApi extends runtime.BaseAPI {

    /**
     */
    async remplacementDefinitiveBaseSearchRaw(requestParameters: RemplacementDefinitiveBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRechercheClasseHoraireRemplacementDefinitiveDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RemplacementDefinitive/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RechercheClasseHoraireRemplacementDefinitiveSearchToJSON(requestParameters.RechercheClasseHoraireRemplacementDefinitiveSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRechercheClasseHoraireRemplacementDefinitiveDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async remplacementDefinitiveBaseSearch(requestParameters: RemplacementDefinitiveBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRechercheClasseHoraireRemplacementDefinitiveDtoPaginatedResults> {
        const response = await this.remplacementDefinitiveBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async remplacementDefinitiveGetSearchCriteriasRaw(requestParameters: RemplacementDefinitiveGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RemplacementDefinitive/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async remplacementDefinitiveGetSearchCriterias(requestParameters: RemplacementDefinitiveGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.remplacementDefinitiveGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async remplacementDefinitiveGetSelectItemsRaw(requestParameters: RemplacementDefinitiveGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling remplacementDefinitiveGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RemplacementDefinitive/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async remplacementDefinitiveGetSelectItems(requestParameters: RemplacementDefinitiveGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.remplacementDefinitiveGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async remplacementDefinitiveSaveRaw(requestParameters: RemplacementDefinitiveSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RemplacementDefinitive`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbRemplacementDefinitifDtoToJSON(requestParameters.FcbRemplacementDefinitifDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async remplacementDefinitiveSave(requestParameters: RemplacementDefinitiveSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.remplacementDefinitiveSaveRaw(requestParameters, initOverrides);
    }

}
