import { FormateurApi, VisiteFormateurApi, FcbFormateurGridDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { WSelector } from "../../../../../components/selector/WSelector";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { useDialog } from "../../../../../contexts";
import { Checkbox } from "@blueprintjs/core";
import { useHistory } from "react-router";
import { useApiEffect, IDataTableColumn, showError, showSuccess } from "nsitools-react";
import React from "react";

export interface IVisiteFormateurLieSelectorProps {
  idVisite: number;
}

export const VisiteFormateurLieSelector: React.FunctionComponent<IVisiteFormateurLieSelectorProps> = ({ idVisite }) => {
  const { t } = useTl();
  const history = useHistory();
  const formateurApi = useApiService(FormateurApi);
  const visiteFormateurApi = useApiService(VisiteFormateurApi);
  const dialogContext = useDialog();

  const [data, loading] = useApiEffect(
    () => visiteFormateurApi.visiteFormateurGetVisiteFormateur({ idVisite: idVisite }),
    []
  );

  const columns = React.useMemo<IDataTableColumn[]>(() => {
    return [
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.Actif),
        fieldName: "actif",
        autoFitContent: true,
        alignment: "center",
        render: (value: FcbFormateurGridDto) => <Checkbox checked={value.actif} disabled />
      },
      {
        header: () => t(ETLCodes.LocaliteCentre),
        fieldName: "localiteCentre"
      },
      {
        header: () => t(ETLCodes.Contrat),
        fieldName: "contrat"
      }
    ];
  }, [t]);

  return (
    !loading && (
      <WSelector
        linkedIds={data.idformateurs}
        columns={columns}
        idField="idformateur"
        tlDataPrefix=""
        title={t(ETLCodes.FormateurLie)}
        searchIdsFunc={sObj => formateurApi.formateurSearchFormateurIds({ FormateurSearch: sObj })}
        getCriteriasFunction={() => formateurApi.formateurGetSearchCriterias({ includeListsValues: true })}
        searchFunction={sObj => formateurApi.formateurBaseSearch({ FormateurSearch: sObj })}
        onSave={async newIds => {
          try {
            await visiteFormateurApi.visiteFormateurSaveFormateursLies({
              FcbVisiteFormateurDto: { idvisite: +idVisite, idformateurs: newIds }
            });
            showSuccess(t(ETLCodes.General_SavedSuccessMessage));
            history.push(`${ERoutes.visiteExterieure}/${idVisite}/formateurLie`);
          } catch (e) {
            showError(t(ETLCodes.General_SaveErrorMessage));
            throw e;
          }
        }}
        onCancel={() => history.push(`${ERoutes.visiteExterieure}/${idVisite}/formateurLie`)}
        overrideListValues={{ Idstade: i => i.displayValue }}
        dialogContext={dialogContext}
        sortKeys={{ nom: "ASC" }}
      />
    )
  );
};
