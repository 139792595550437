/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EStatutDemande {
    AE = 'AE',
    P = 'P',
    I = 'I',
    E = 'E',
    EV = 'EV',
    EA = 'EA',
    EC = 'EC',
    CC = 'CC',
    ER = 'ER',
    MS = 'MS',
    SU = 'SU',
    RC = 'RC',
    DS = 'DS',
    V = 'V',
    CE = 'CE'
}


export function EStatutDemandeFromJSON(json: any): EStatutDemande {
    return EStatutDemandeFromJSONTyped(json, false);
}

export function EStatutDemandeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EStatutDemande {
    return json as EStatutDemande;
}

export function EStatutDemandeToJSON(value?: EStatutDemande | null): any {
    return value as any;
}

