import * as React from "react";
import { ApprenantAbsenceStatistiquePage } from "./ApprenantAbsenceStatistiquePage";
import { ApprenantDateAbsenceListPage } from "./ApprenantDateAbsenceListPage";

export interface IApprenantAbsenceListPageProps {}

export const ApprenantAbsenceListPage: React.FunctionComponent<IApprenantAbsenceListPageProps> = () => {
  return (
    <>
      <ApprenantAbsenceStatistiquePage></ApprenantAbsenceStatistiquePage>
      <ApprenantDateAbsenceListPage></ApprenantDateAbsenceListPage>
    </>
  );
};
