import { useDebounce, useFGContext } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import { Tooltip } from "@blueprintjs/core";

import { FGWalterSelectInput } from ".";
import { useTl } from "../hooks";
import { useReferential } from "../hooks/useReferential";
import { ETLCodes } from "../locales";
import { getIn } from "formik";

interface ICommuneSelectProps {
  codePays?: string;
  codePostal: string;
  name: string;
  enableAutoSelect?: boolean;
  labelStyles?: React.CSSProperties;
  disabled?: boolean;
  readonly?: boolean;
}

const StyledTooltip = styled(Tooltip)`
  display: flex;
  & > * {
    flex: 1;
  }
`;

export const CommuneSelect: React.FunctionComponent<ICommuneSelectProps> = ({
  codePays = "BE",
  codePostal,
  name,
  enableAutoSelect = true,
  disabled,
  readonly
}) => {
  const { t } = useTl();

  const debouncedCodePays = useDebounce(codePays, 500);
  const debouncedCodePostal = useDebounce(codePostal, 500);
  const [data, loading] = useReferential(
    a => a.referentialGetCommuneFromCodePostal({ codePays: debouncedCodePays, codePostal: debouncedCodePostal }),
    false,
    [debouncedCodePostal]
  );
  const { formik } = useFGContext();

  React.useEffect(() => {
    if (
      !!codePostal &&
      enableAutoSelect &&
      data?.length === 1 &&
      getIn(formik?.values, name) !== data[0].value &&
      !!formik.touched[name]
    ) {
      formik.setFieldValue(name, data[0].value);
    }
  }, [codePostal, data, enableAutoSelect, formik, name]);

  return (
    <StyledTooltip content={t(ETLCodes.SelectPostalCodeFirst)} position="right" disabled={!!codePostal}>
      <FGWalterSelectInput
        name={name}
        label={t(ETLCodes.Commune)}
        loading={loading}
        items={data}
        disabled={!codePostal || disabled}
        autoSelectIfOne
        readonly={readonly}
      />
    </StyledTooltip>
  );
};
