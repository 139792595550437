/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { TextSearch } from './TextSearch';
import {
    TextSearchFromJSON,
    TextSearchFromJSONTyped,
    TextSearchToJSON,
} from './TextSearch';
import type { UnaccentTextSearch } from './UnaccentTextSearch';
import {
    UnaccentTextSearchFromJSON,
    UnaccentTextSearchFromJSONTyped,
    UnaccentTextSearchToJSON,
} from './UnaccentTextSearch';

/**
 * 
 * @export
 * @interface SiegeSocialSelectorSearch
 */
export interface SiegeSocialSelectorSearch {
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof SiegeSocialSelectorSearch
     */
    denominationSociale?: UnaccentTextSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof SiegeSocialSelectorSearch
     */
    nomFormation?: UnaccentTextSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof SiegeSocialSelectorSearch
     */
    enseigne?: UnaccentTextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof SiegeSocialSelectorSearch
     */
    numeroBce?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof SiegeSocialSelectorSearch
     */
    localite?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof SiegeSocialSelectorSearch
     */
    gsm?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof SiegeSocialSelectorSearch
     */
    telephone?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof SiegeSocialSelectorSearch
     */
    email?: TextSearch;
    /**
     * 
     * @type {Array<number>}
     * @memberof SiegeSocialSelectorSearch
     */
    selectedSiegeSocialIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SiegeSocialSelectorSearch
     */
    excludedSiegeSocialIds?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SiegeSocialSelectorSearch
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof SiegeSocialSelectorSearch
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialSelectorSearch
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialSelectorSearch
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof SiegeSocialSelectorSearch
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialSelectorSearch
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the SiegeSocialSelectorSearch interface.
 */
export function instanceOfSiegeSocialSelectorSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SiegeSocialSelectorSearchFromJSON(json: any): SiegeSocialSelectorSearch {
    return SiegeSocialSelectorSearchFromJSONTyped(json, false);
}

export function SiegeSocialSelectorSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiegeSocialSelectorSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'denominationSociale': !exists(json, 'denominationSociale') ? undefined : UnaccentTextSearchFromJSON(json['denominationSociale']),
        'nomFormation': !exists(json, 'nomFormation') ? undefined : UnaccentTextSearchFromJSON(json['nomFormation']),
        'enseigne': !exists(json, 'enseigne') ? undefined : UnaccentTextSearchFromJSON(json['enseigne']),
        'numeroBce': !exists(json, 'numeroBce') ? undefined : TextSearchFromJSON(json['numeroBce']),
        'localite': !exists(json, 'localite') ? undefined : TextSearchFromJSON(json['localite']),
        'gsm': !exists(json, 'gsm') ? undefined : TextSearchFromJSON(json['gsm']),
        'telephone': !exists(json, 'telephone') ? undefined : TextSearchFromJSON(json['telephone']),
        'email': !exists(json, 'email') ? undefined : TextSearchFromJSON(json['email']),
        'selectedSiegeSocialIds': !exists(json, 'selectedSiegeSocialIds') ? undefined : json['selectedSiegeSocialIds'],
        'excludedSiegeSocialIds': !exists(json, 'excludedSiegeSocialIds') ? undefined : json['excludedSiegeSocialIds'],
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function SiegeSocialSelectorSearchToJSON(value?: SiegeSocialSelectorSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'denominationSociale': UnaccentTextSearchToJSON(value.denominationSociale),
        'nomFormation': UnaccentTextSearchToJSON(value.nomFormation),
        'enseigne': UnaccentTextSearchToJSON(value.enseigne),
        'numeroBce': TextSearchToJSON(value.numeroBce),
        'localite': TextSearchToJSON(value.localite),
        'gsm': TextSearchToJSON(value.gsm),
        'telephone': TextSearchToJSON(value.telephone),
        'email': TextSearchToJSON(value.email),
        'selectedSiegeSocialIds': value.selectedSiegeSocialIds,
        'excludedSiegeSocialIds': value.excludedSiegeSocialIds,
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

