/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FilterCriteriaInfo,
  ReferentialItemDto,
  SiegeLieuSuiviEditDto,
  SiegeLieuSuiviGridDtoPaginatedResults,
  SiegeLieuSuiviSearchBase,
} from '../models/index';
import {
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
    SiegeLieuSuiviEditDtoFromJSON,
    SiegeLieuSuiviEditDtoToJSON,
    SiegeLieuSuiviGridDtoPaginatedResultsFromJSON,
    SiegeLieuSuiviGridDtoPaginatedResultsToJSON,
    SiegeLieuSuiviSearchBaseFromJSON,
    SiegeLieuSuiviSearchBaseToJSON,
} from '../models/index';

export interface SiegeSocialSuiviBaseSearchRequest {
    SiegeLieuSuiviSearchBase?: SiegeLieuSuiviSearchBase;
}

export interface SiegeSocialSuiviDeleteSiegeLieuSuiviRequest {
    id?: number;
}

export interface SiegeSocialSuiviGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface SiegeSocialSuiviGetSelectItemsRequest {
    searchField: string;
}

export interface SiegeSocialSuiviGetSuiviHopeRequest {
    id?: number;
}

export interface SiegeSocialSuiviSaveSuiviHopeRequest {
    SiegeLieuSuiviEditDto?: SiegeLieuSuiviEditDto;
}

/**
 * 
 */
export class SiegeSocialSuiviApi extends runtime.BaseAPI {

    /**
     */
    async siegeSocialSuiviBaseSearchRaw(requestParameters: SiegeSocialSuiviBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SiegeLieuSuiviGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocialSuivi/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SiegeLieuSuiviSearchBaseToJSON(requestParameters.SiegeLieuSuiviSearchBase),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiegeLieuSuiviGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async siegeSocialSuiviBaseSearch(requestParameters: SiegeSocialSuiviBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SiegeLieuSuiviGridDtoPaginatedResults> {
        const response = await this.siegeSocialSuiviBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialSuiviDeleteSiegeLieuSuiviRaw(requestParameters: SiegeSocialSuiviDeleteSiegeLieuSuiviRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocialSuivi/DeleteSiegeLieuSuivi`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async siegeSocialSuiviDeleteSiegeLieuSuivi(requestParameters: SiegeSocialSuiviDeleteSiegeLieuSuiviRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.siegeSocialSuiviDeleteSiegeLieuSuiviRaw(requestParameters, initOverrides);
    }

    /**
     */
    async siegeSocialSuiviGetSearchCriteriasRaw(requestParameters: SiegeSocialSuiviGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocialSuivi/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async siegeSocialSuiviGetSearchCriterias(requestParameters: SiegeSocialSuiviGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.siegeSocialSuiviGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialSuiviGetSelectItemsRaw(requestParameters: SiegeSocialSuiviGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling siegeSocialSuiviGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocialSuivi/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async siegeSocialSuiviGetSelectItems(requestParameters: SiegeSocialSuiviGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.siegeSocialSuiviGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialSuiviGetSuiviHopeRaw(requestParameters: SiegeSocialSuiviGetSuiviHopeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SiegeLieuSuiviEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocialSuivi/GetSiegeLieuSuivi`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiegeLieuSuiviEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async siegeSocialSuiviGetSuiviHope(requestParameters: SiegeSocialSuiviGetSuiviHopeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SiegeLieuSuiviEditDto> {
        const response = await this.siegeSocialSuiviGetSuiviHopeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialSuiviSaveSuiviHopeRaw(requestParameters: SiegeSocialSuiviSaveSuiviHopeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SiegeLieuSuiviEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocialSuivi/SaveSiegeLieuSuivi`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SiegeLieuSuiviEditDtoToJSON(requestParameters.SiegeLieuSuiviEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiegeLieuSuiviEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async siegeSocialSuiviSaveSuiviHope(requestParameters: SiegeSocialSuiviSaveSuiviHopeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SiegeLieuSuiviEditDto> {
        const response = await this.siegeSocialSuiviSaveSuiviHopeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
