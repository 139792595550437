/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClasseHoraireJourColumnsDto
 */
export interface ClasseHoraireJourColumnsDto {
    /**
     * 
     * @type {number}
     * @memberof ClasseHoraireJourColumnsDto
     */
    idclasseHoraire?: number;
    /**
     * 
     * @type {number}
     * @memberof ClasseHoraireJourColumnsDto
     */
    numero?: number;
    /**
     * 
     * @type {string}
     * @memberof ClasseHoraireJourColumnsDto
     */
    codeClassMatiere?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClasseHoraireJourColumnsDto
     */
    heureDebut?: string | null;
}

/**
 * Check if a given object implements the ClasseHoraireJourColumnsDto interface.
 */
export function instanceOfClasseHoraireJourColumnsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClasseHoraireJourColumnsDtoFromJSON(json: any): ClasseHoraireJourColumnsDto {
    return ClasseHoraireJourColumnsDtoFromJSONTyped(json, false);
}

export function ClasseHoraireJourColumnsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClasseHoraireJourColumnsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idclasseHoraire': !exists(json, 'idclasseHoraire') ? undefined : json['idclasseHoraire'],
        'numero': !exists(json, 'numero') ? undefined : json['numero'],
        'codeClassMatiere': !exists(json, 'codeClassMatiere') ? undefined : json['codeClassMatiere'],
        'heureDebut': !exists(json, 'heureDebut') ? undefined : json['heureDebut'],
    };
}

export function ClasseHoraireJourColumnsDtoToJSON(value?: ClasseHoraireJourColumnsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idclasseHoraire': value.idclasseHoraire,
        'numero': value.numero,
        'codeClassMatiere': value.codeClassMatiere,
        'heureDebut': value.heureDebut,
    };
}

