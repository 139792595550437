import { Classes, Colors, Icon, Switch } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { ButtonContainer, IDataTableColumn, ToolTipButton } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { DashboardApi, DashboardPratiqueProDto, DashboardPratiqueProSearch, EStatutContrat } from "../../../api";
import { ERoutes } from "../../../AppRouter";
import { CircleColumn, CustomBulletList, LinkButton, SearchTablePage, ViewButton } from "../../../components";
import { useAuth, useDialog, useEventsContext } from "../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTheme, useTl } from "../../../hooks";
import { ETLCodes } from "../../../locales";
import { computeContratStatutColor } from "../../../utils/contratStatutColor";

const LegendHeaderContainer = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

const StyledSwitch = styled(Switch)<{ color: string }>`
  & .${Classes.SWITCH} {
    &:hover {
      input:checked ~ .${Classes.CONTROL_INDICATOR} {
        background: ${props => props.color};
      }
    }
  }
`;

export interface IDashboardPratiqueProProps {}

export const DashboardPratiquePro: React.FunctionComponent<IDashboardPratiqueProProps> = props => {
  const { t, tUnsafe } = useTl();
  const { user } = useAuth();
  const { theme } = useTheme();
  const api = useApiService(DashboardApi);

  const [displayHidden, setDisplayHidden] = React.useState(false);

  const apiFactory = useAbortableApiServiceFactory(DashboardApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFunction = React.useCallback(
    (sObj?: DashboardPratiqueProSearch) => {
      sObj.hideMasquees = !displayHidden;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.dashboardSearchPratiquePro({ DashboardPratiqueProSearch: sObj });
    },
    [apiFactory, displayHidden]
  );

  const { dispatchEvent } = useEventsContext();
  const [rowLoading, setRowLoading] = React.useState(null);

  const { showDialogPromise } = useDialog();
  const masquer = React.useCallback(
    async (idcontrat: number, statut: string) => {
      const res = await showDialogPromise({
        title: t(ETLCodes.MasquerLigneConfirmTitle),
        message: t(ETLCodes.MasquerLigneConfirmMessage)
      });
      if (res === "yes") {
        setRowLoading(idcontrat);
        await api.dashboardMasquerPratiquePro({ idcontrat, statut });
        setRowLoading(null);
        dispatchEvent("SEARCH_TABLE_REFRESH");
        dispatchEvent("DASHBOARD_COUNTS_REFRESH_PratiquePro");
      }
    },
    [api, dispatchEvent, showDialogPromise, t]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: DashboardPratiqueProDto) => (
          <ButtonContainer>
            {(!row.idsuserMasquage || !row.idsuserMasquage.includes(`|${user.iduser}|`)) && (
              <ToolTipButton
                tooltipPosition="bottom"
                tooltip={t(ETLCodes.MasquerLigne)}
                minimal
                icon="cross"
                onClick={() => masquer(row.idcontrat, row.statut)}
                loading={rowLoading === row.idcontrat}
                disabled={!!rowLoading && rowLoading !== row.idcontrat}
              />
            )}
            <ViewButton
              onClick={() => window.open(`#${ERoutes.contrat}/${row.idcontrat}/detail/${row.typeCode}/view`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code"
      },
      {
        computed: true,
        alignment: "center",
        autoFitContent: true,
        header: () => (
          <LegendHeaderContainer>
            <span>{t(ETLCodes.Statut)}</span>
            <Tooltip2
              content={
                <CustomBulletList
                  title={t(ETLCodes.Statut)}
                  items={[
                    {
                      text: t(ETLCodes.EnCours),
                      color: theme.sucessColor
                    },
                    {
                      text: t(ETLCodes.Rompu),
                      color: theme.dangerColor
                    },
                    {
                      text: t(ETLCodes.Suspendu),
                      color: theme.warningColor
                    },
                    {
                      text: t(ETLCodes.Termine),
                      color: Colors.VIOLET4
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip2>
          </LegendHeaderContainer>
        ),
        fieldName: "statut",
        render: (row: DashboardPratiqueProDto) => (
          <CircleColumn
            color={computeContratStatutColor(row.statut as EStatutContrat, theme)}
            tooltip={tUnsafe(`StatutContrat_${row.statut}`)}
          />
        )
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "type"
      },
      {
        header: () => t(ETLCodes.Apprenant),
        fieldName: "apprenant",
        render: (row: DashboardPratiqueProDto) => (
          <LinkButton
            text={row.apprenant}
            onClick={() => window.open(`#${ERoutes.apprenant}/${row.idapprenant}/signaletique/view`)}
          />
        )
      },
      {
        header: () => t(ETLCodes.Periode),
        fieldName: "periode"
      },
      {
        header: () => t(ETLCodes.Referent),
        fieldName: "referent"
      }
    ],
    [masquer, rowLoading, t, tUnsafe, theme, user.iduser]
  );

  const getCriteria = React.useCallback(async () => {
    return await api.dashboardGetPratiqueProCriterias();
  }, [api]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      searchStateInitialSearch={false}
      withCard={false}
      columns={columns}
      getCriteriasFunction={getCriteria}
      searchFunction={searchFunction}
      onAbort={onAbort}
      rightElement={
        <StyledSwitch
          color={theme.primaryColor}
          label={t(ETLCodes.AfficherMasquees)}
          checked={displayHidden}
          onChange={e => {
            setDisplayHidden(e.currentTarget.checked);
          }}
        ></StyledSwitch>
      }
    />
  );
};
