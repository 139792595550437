import { FGListen, FGTextAreaInput, useFGContext } from "nsitools-react";
import * as React from "react";
import { FichierApi } from "../../../../../../api";

import { FGWalterCheckboxInput, FGWalterDateMaskInput, FGWalterFileInput } from "../../../../../../components";
import { useApiService, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

export interface ILieuFormationAgrementVisiteProps {}

export const LieuFormationAgrementVisite: React.FunctionComponent<ILieuFormationAgrementVisiteProps> = props => {
  const { t } = useTl();
  const { formik } = useFGContext();

  const fapi = useApiService(FichierApi);
  const downloadFn = React.useCallback(
    async () => await fapi.fichierDownload({ id: formik.values.idfichierCompteRendu }),
    [fapi, formik?.values?.idfichierCompteRendu]
  );

  return (
    <>
      <FGListen
        field="fichierCompteRendus"
        onChanged={(value, formik) => {
          if (formik.dirty) {
            formik.setFieldValue("idfichierCompteRendu", null);
          }
        }}
      />
      <FGWalterDateMaskInput name="dateVisite" label={t(ETLCodes.DateVisite)} />
      <FGWalterCheckboxInput name="pratiqueSimulee" label={t(ETLCodes.PratiqueSimulee)} />
      <FGTextAreaInput name="compteRendu" label={t(ETLCodes.CompteRendu)} />
      <FGWalterFileInput
        name="fichierCompteRendu"
        label={t(ETLCodes.DocumentCompteRendu)}
        downloadFn={formik.values?.idfichierCompteRendu && downloadFn}
      />
    </>
  );
};
