/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReferentialItemDto
 */
export interface ReferentialItemDto {
    /**
     * 
     * @type {string}
     * @memberof ReferentialItemDto
     */
    keyValue?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReferentialItemDto
     */
    disabled?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReferentialItemDto
     */
    champLibre?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReferentialItemDto
     */
    idValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReferentialItemDto
     */
    displayValue?: string | null;
}

/**
 * Check if a given object implements the ReferentialItemDto interface.
 */
export function instanceOfReferentialItemDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReferentialItemDtoFromJSON(json: any): ReferentialItemDto {
    return ReferentialItemDtoFromJSONTyped(json, false);
}

export function ReferentialItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferentialItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'keyValue': !exists(json, 'keyValue') ? undefined : json['keyValue'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
        'champLibre': !exists(json, 'champLibre') ? undefined : json['champLibre'],
        'idValue': !exists(json, 'idValue') ? undefined : json['idValue'],
        'displayValue': !exists(json, 'displayValue') ? undefined : json['displayValue'],
    };
}

export function ReferentialItemDtoToJSON(value?: ReferentialItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'keyValue': value.keyValue,
        'disabled': value.disabled,
        'champLibre': value.champLibre,
        'idValue': value.idValue,
        'displayValue': value.displayValue,
    };
}

