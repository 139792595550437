/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdresseDto } from './AdresseDto';
import {
    AdresseDtoFromJSON,
    AdresseDtoFromJSONTyped,
    AdresseDtoToJSON,
} from './AdresseDto';

/**
 * 
 * @export
 * @interface AdresseReferentialResultInfoDto
 */
export interface AdresseReferentialResultInfoDto {
    /**
     * 
     * @type {Array<AdresseDto>}
     * @memberof AdresseReferentialResultInfoDto
     */
    list?: Array<AdresseDto> | null;
}

/**
 * Check if a given object implements the AdresseReferentialResultInfoDto interface.
 */
export function instanceOfAdresseReferentialResultInfoDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdresseReferentialResultInfoDtoFromJSON(json: any): AdresseReferentialResultInfoDto {
    return AdresseReferentialResultInfoDtoFromJSONTyped(json, false);
}

export function AdresseReferentialResultInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdresseReferentialResultInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': !exists(json, 'list') ? undefined : (json['list'] === null ? null : (json['list'] as Array<any>).map(AdresseDtoFromJSON)),
    };
}

export function AdresseReferentialResultInfoDtoToJSON(value?: AdresseReferentialResultInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': value.list === undefined ? undefined : (value.list === null ? null : (value.list as Array<any>).map(AdresseDtoToJSON)),
    };
}

