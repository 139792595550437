/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbAgrementGridDto
 */
export interface FcbAgrementGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbAgrementGridDto
     */
    formation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbAgrementGridDto
     */
    decision?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbAgrementGridDto
     */
    idagrement?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbAgrementGridDto
     */
    iddecision?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbAgrementGridDto
     */
    idformation?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FcbAgrementGridDto
     */
    derogation?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbAgrementGridDto
     */
    c8?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbAgrementGridDto
     */
    dateDecision?: Date | null;
}

/**
 * Check if a given object implements the FcbAgrementGridDto interface.
 */
export function instanceOfFcbAgrementGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbAgrementGridDtoFromJSON(json: any): FcbAgrementGridDto {
    return FcbAgrementGridDtoFromJSONTyped(json, false);
}

export function FcbAgrementGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbAgrementGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'formation': !exists(json, 'formation') ? undefined : json['formation'],
        'decision': !exists(json, 'decision') ? undefined : json['decision'],
        'idagrement': !exists(json, 'idagrement') ? undefined : json['idagrement'],
        'iddecision': !exists(json, 'iddecision') ? undefined : json['iddecision'],
        'idformation': !exists(json, 'idformation') ? undefined : json['idformation'],
        'derogation': !exists(json, 'derogation') ? undefined : json['derogation'],
        'c8': !exists(json, 'c8') ? undefined : json['c8'],
        'dateDecision': !exists(json, 'dateDecision') ? undefined : (json['dateDecision'] === null ? null : new Date(json['dateDecision'])),
    };
}

export function FcbAgrementGridDtoToJSON(value?: FcbAgrementGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'formation': value.formation,
        'decision': value.decision,
        'idagrement': value.idagrement,
        'iddecision': value.iddecision,
        'idformation': value.idformation,
        'derogation': value.derogation,
        'c8': value.c8,
        'dateDecision': value.dateDecision === undefined ? undefined : (value.dateDecision === null ? null : value.dateDecision.toISOString()),
    };
}

