import { Button, Colors, Intent, Menu, MenuItem, Popover } from "@blueprintjs/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileSignature } from "@fortawesome/free-solid-svg-icons";
import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router";
import styled, { css } from "styled-components";

import { TuteurApi, TuteurLieuGridDto, TuteurSearchDto } from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import { AddButton, BooleanColumn, EditButton, ViewButton } from "../../../../../../components";
import { useApiService, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

const PopoverContent = styled.div`
  padding: 0.5rem;
`;

export interface ILieuFormationTuteurListPageProps {}

export const LieuFormationTuteurListPage: React.FunctionComponent<ILieuFormationTuteurListPageProps> = props => {
  const { t } = useTl();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const LieuFormationId = React.useMemo(() => +id, [id]);
  const api = useApiService(TuteurApi);
  const { search } = useLocation();
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { personne: "ASC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: TuteurSearchDto) => {
      sObj.idlieuformation = LieuFormationId;
      return api.tuteurSearchTuteursLieu({ TuteurSearchDto: sObj });
    },
    [api, LieuFormationId]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const onViewClick = React.useCallback(
    (dto: TuteurLieuGridDto) => {
      history.push(`${ERoutes.lieuFormation}/${id}${ERoutes.tuteur}/view/${dto.idtuteurLieu}${search}`);
    },
    [history, id, search]
  );

  const onEditClick = React.useCallback(
    (dto: TuteurLieuGridDto) => {
      history.push(`${ERoutes.lieuFormation}/${id}${ERoutes.tuteur}/edit/${dto.idtuteurLieu}${search}`);
    },
    [history, id, search]
  );

  const createContrat = React.useCallback(
    (dto: TuteurLieuGridDto, type: "CC" | "CS" | "PP" | "CF" | "DM") => {
      history.push(
        `${ERoutes.contrat}/-1/detail/${type}/edit?idlieuFormation=${dto.idlieuFormation}&idtuteur=${dto.idtuteur}`
      );
    },
    [history]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: TuteurLieuGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => onViewClick(row)} />
            <EditButton minimal={true} onClick={() => onEditClick(row)} />
            <Popover
              position="bottom-left"
              content={
                <PopoverContent>
                  <Menu>
                    {!row.spp && (
                      <MenuItem
                        text={t(ETLCodes.ContratAlternanceCC)}
                        intent={Intent.PRIMARY}
                        onClick={() => createContrat(row, "CC")}
                      />
                    )}
                    {!row.spp && (
                      <MenuItem
                        text={t(ETLCodes.ConventionStageCS)}
                        intent={Intent.PRIMARY}
                        onClick={() => createContrat(row, "CS")}
                      />
                    )}
                    <MenuItem
                      text={t(ETLCodes.StagePratiqueProPP)}
                      intent={Intent.PRIMARY}
                      onClick={() => createContrat(row, "PP")}
                    />
                    {!row.spp && (
                      <MenuItem
                        text={t(ETLCodes.ConventionFALTCF)}
                        intent={Intent.PRIMARY}
                        onClick={() => createContrat(row, "CF")}
                      />
                    )}
                    <MenuItem
                      text={t(ETLCodes.StageDecouverteMetierDM)}
                      intent={Intent.PRIMARY}
                      onClick={() => createContrat(row, "DM")}
                    />
                  </Menu>
                </PopoverContent>
              }
            >
              <Button minimal icon={<FontAwesomeIcon icon={faFileSignature} color="#5F6B7C" />} />
            </Popover>
          </ButtonContainer>
        )
      },
      {
        autoFitContent: true,
        alignment: "center",
        header: () => t(ETLCodes.Contrats),
        fieldName: "c"
      },
      {
        header: () => t(ETLCodes.Personne),
        fieldName: "personne"
      },
      {
        header: () => t(ETLCodes.DateCv),
        fieldName: "dateCv"
      },
      {
        header: () => t(ETLCodes.CapaciteFormative),
        fieldName: "capaciteFormative",
        alignment: "center",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.AnneesExperience),
        fieldName: "anneesExperience",
        alignment: "center",
        autoFitContent: true,
        render: (row: TuteurLieuGridDto) => <BooleanColumn value={row.anneesExperience} />
      },
      {
        header: () => t(ETLCodes.Tutorat),
        fieldName: "tutorat",
        alignment: "center",
        autoFitContent: true,
        render: (row: TuteurLieuGridDto) => <BooleanColumn value={row.tutorat} />
      },
      {
        header: () => t(ETLCodes.Vdc),
        fieldName: "vdc",
        alignment: "center",
        autoFitContent: true,
        render: (row: TuteurLieuGridDto) => <BooleanColumn value={row.vdc} />
      },
      {
        header: () => t(ETLCodes.Derogation),
        fieldName: "derogation",
        alignment: "center",
        autoFitContent: true,
        render: (row: TuteurLieuGridDto) => <BooleanColumn value={row.derogation} />
      },
      {
        header: () => t(ETLCodes.ResponsableDerogation),
        fieldName: "responsableDerogation"
      },
      {
        header: () => t(ETLCodes.Derogation),
        fieldName: "dateDerogation"
      }
    ],
    [createContrat, onEditClick, onViewClick, t]
  );

  const onAddClick = React.useCallback(() => {
    history.push(`${ERoutes.lieuFormation}/${id}${ERoutes.tuteur}/checkDoublons${search}`);
  }, [history, id, search]);

  return (
    <FieldSet
      title={t(ETLCodes.TableResults, { count: totalCount })}
      rightElement={<AddButton onClick={onAddClick} text={t(ETLCodes.General_Add)} intent={Intent.PRIMARY} />}
    >
      <DataTable
        dateFormat="dd/MM/yyyy"
        tableState={tableState}
        loading={loading}
        columns={columns}
        filterMode="OnEnter"
        customizeRowStyle={(row: TuteurLieuGridDto) => css`
          & * {
            color: ${!row.actif ? Colors.RED3 + " !important" : null};
          }
        `}
      ></DataTable>
    </FieldSet>
  );
};
