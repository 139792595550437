/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EListSearchType } from './EListSearchType';
import {
    EListSearchTypeFromJSON,
    EListSearchTypeFromJSONTyped,
    EListSearchTypeToJSON,
} from './EListSearchType';
import type { ESuiviAction } from './ESuiviAction';
import {
    ESuiviActionFromJSON,
    ESuiviActionFromJSONTyped,
    ESuiviActionToJSON,
} from './ESuiviAction';

/**
 * 
 * @export
 * @interface EListSearchTypeESuiviActionCombinableCriteria
 */
export interface EListSearchTypeESuiviActionCombinableCriteria {
    /**
     * 
     * @type {EListSearchType}
     * @memberof EListSearchTypeESuiviActionCombinableCriteria
     */
    searchMode?: EListSearchType;
    /**
     * 
     * @type {ESuiviAction}
     * @memberof EListSearchTypeESuiviActionCombinableCriteria
     */
    value?: ESuiviAction;
    /**
     * 
     * @type {ESuiviAction}
     * @memberof EListSearchTypeESuiviActionCombinableCriteria
     */
    secondaryValue?: ESuiviAction;
}

/**
 * Check if a given object implements the EListSearchTypeESuiviActionCombinableCriteria interface.
 */
export function instanceOfEListSearchTypeESuiviActionCombinableCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EListSearchTypeESuiviActionCombinableCriteriaFromJSON(json: any): EListSearchTypeESuiviActionCombinableCriteria {
    return EListSearchTypeESuiviActionCombinableCriteriaFromJSONTyped(json, false);
}

export function EListSearchTypeESuiviActionCombinableCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): EListSearchTypeESuiviActionCombinableCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchMode': !exists(json, 'searchMode') ? undefined : EListSearchTypeFromJSON(json['searchMode']),
        'value': !exists(json, 'value') ? undefined : ESuiviActionFromJSON(json['value']),
        'secondaryValue': !exists(json, 'secondaryValue') ? undefined : ESuiviActionFromJSON(json['secondaryValue']),
    };
}

export function EListSearchTypeESuiviActionCombinableCriteriaToJSON(value?: EListSearchTypeESuiviActionCombinableCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchMode': EListSearchTypeToJSON(value.searchMode),
        'value': ESuiviActionToJSON(value.value),
        'secondaryValue': ESuiviActionToJSON(value.secondaryValue),
    };
}

