import React from "react";
import { useApiService, useSystemInfo, useTl } from "../../../../hooks";
import { UserDto, UsersApi } from "../../../../api";
import { useAuth, useDialog } from "../../../../contexts";
import { Button } from "@blueprintjs/core";
import { useHistory } from "react-router";
import { ERoutes } from "../../../../AppRouter";
import { ETLCodes } from "../../../../locales";

export interface ImpersonateButtonProps {
  user?: UserDto;
}

export const ImpersonateButton: React.FC<ImpersonateButtonProps> = ({ user }) => {
  const userApi = useApiService(UsersApi);
  const { reloadUser, user: userProfile } = useAuth();
  const history = useHistory();
  const { t } = useTl();
  const { ifapmeSide } = useSystemInfo();
  const { showDialogPromise } = useDialog();

  const [isLoading, setIsLoading] = React.useState(false);
  const onClick = React.useCallback(async () => {
    const res = await showDialogPromise({
      message: t(ETLCodes.AreYouSureYouWantToImpersonateThisUser, { userName: user.prenom + " " + user.nom }),
      title: t(ETLCodes.Impersonate)
    });
    if (res !== "yes") return;

    setIsLoading(true);
    await userApi.usersImpersonate({ userId: user.iduser });
    await reloadUser();
    setIsLoading(false);
    history.replace(ERoutes.home);
  }, [history, reloadUser, showDialogPromise, t, userApi, user]);

  const canImpersonate = React.useMemo(() => {
    return userProfile.iduser !== user.iduser && user.ifapmeSide?.toUpperCase().includes(ifapmeSide?.toUpperCase());
  }, [ifapmeSide, user.iduser, user.ifapmeSide, userProfile.iduser]);

  if (!user || !canImpersonate) return null;

  return (
    <Button
      icon={"user"}
      onClick={onClick}
      loading={isLoading}
      disabled={isLoading}
      text={t(ETLCodes.Impersonate)}
      intent={"primary"}
    />
  );
};

export default ImpersonateButton;
