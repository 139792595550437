/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormateurDoublonAdministratifDto
 */
export interface FormateurDoublonAdministratifDto {
    /**
     * 
     * @type {number}
     * @memberof FormateurDoublonAdministratifDto
     */
    idcivilite?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurDoublonAdministratifDto
     */
    civilite?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FormateurDoublonAdministratifDto
     */
    precompte?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FormateurDoublonAdministratifDto
     */
    enfantCharge?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormateurDoublonAdministratifDto
     */
    conjointCharge?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FormateurDoublonAdministratifDto
     */
    autreCharge?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FormateurDoublonAdministratifDto
     */
    enfantHandicape?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FormateurDoublonAdministratifDto
     */
    autreHandicape?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormateurDoublonAdministratifDto
     */
    handicape?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurDoublonAdministratifDto
     */
    ancienMatricule?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FormateurDoublonAdministratifDto
     */
    datePremierContrat?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormateurDoublonAdministratifDto
     */
    jure?: boolean | null;
}

/**
 * Check if a given object implements the FormateurDoublonAdministratifDto interface.
 */
export function instanceOfFormateurDoublonAdministratifDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FormateurDoublonAdministratifDtoFromJSON(json: any): FormateurDoublonAdministratifDto {
    return FormateurDoublonAdministratifDtoFromJSONTyped(json, false);
}

export function FormateurDoublonAdministratifDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormateurDoublonAdministratifDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcivilite': !exists(json, 'idcivilite') ? undefined : json['idcivilite'],
        'civilite': !exists(json, 'civilite') ? undefined : json['civilite'],
        'precompte': !exists(json, 'precompte') ? undefined : json['precompte'],
        'enfantCharge': !exists(json, 'enfantCharge') ? undefined : json['enfantCharge'],
        'conjointCharge': !exists(json, 'conjointCharge') ? undefined : json['conjointCharge'],
        'autreCharge': !exists(json, 'autreCharge') ? undefined : json['autreCharge'],
        'enfantHandicape': !exists(json, 'enfantHandicape') ? undefined : json['enfantHandicape'],
        'autreHandicape': !exists(json, 'autreHandicape') ? undefined : json['autreHandicape'],
        'handicape': !exists(json, 'handicape') ? undefined : json['handicape'],
        'ancienMatricule': !exists(json, 'ancienMatricule') ? undefined : json['ancienMatricule'],
        'datePremierContrat': !exists(json, 'datePremierContrat') ? undefined : (json['datePremierContrat'] === null ? null : new Date(json['datePremierContrat'])),
        'jure': !exists(json, 'jure') ? undefined : json['jure'],
    };
}

export function FormateurDoublonAdministratifDtoToJSON(value?: FormateurDoublonAdministratifDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcivilite': value.idcivilite,
        'civilite': value.civilite,
        'precompte': value.precompte,
        'enfantCharge': value.enfantCharge,
        'conjointCharge': value.conjointCharge,
        'autreCharge': value.autreCharge,
        'enfantHandicape': value.enfantHandicape,
        'autreHandicape': value.autreHandicape,
        'handicape': value.handicape,
        'ancienMatricule': value.ancienMatricule,
        'datePremierContrat': value.datePremierContrat === undefined ? undefined : (value.datePremierContrat === null ? null : value.datePremierContrat.toISOString()),
        'jure': value.jure,
    };
}

