import * as React from "react";
import { useHistory } from "react-router";

import { FcbCentreGridDto, CentreApi, CentreSearch, EBooleanSearchTypes } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, NumberShower, ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { useAbortableApiServiceFactory, useApiService } from "../../../../../hooks/useApiService";
import { ETLCodes } from "../../../../../locales";
import { IDataTableColumn } from "nsitools-react";
import styled from "styled-components";

interface ICentreListPageProps {}

const ButtonContainer = styled.div`
  display: flex;
`;

export const CentreListPage: React.FunctionComponent<ICentreListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(CentreApi);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbCentreGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => history.push(`${ERoutes.centre}/${row.idcentre}/detail/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${ERoutes.centre}/${row.idcentre}/detail/edit`)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Adresse),
        fieldName: "adresse",
        render: (row: FcbCentreGridDto) =>
          `${row.adresse ?? ""} ${row.codePostal ?? ""} ${row.localite ?? ""} (${row.commune ?? ""})`
      },
      {
        header: () => t(ETLCodes.Tel),
        fieldName: "telephone",
        width: 200,
        render: (row: FcbCentreGridDto) => (
          <>
            <NumberShower ETLCode={ETLCodes.App} text={row.telApp} />
            <NumberShower ETLCode={ETLCodes.CE} text={row.telCe} />
            <NumberShower ETLCode={ETLCodes.FCO} text={row.telFco} />
            <NumberShower ETLCode={ETLCodes.Central} text={row.telCentral} />
            <NumberShower ETLCode={ETLCodes.Autre} text={row.telAutre} />
          </>
        )
      },
      {
        header: () => t(ETLCodes.Fax),
        fieldName: "fax",
        width: 200,
        render: (row: FcbCentreGridDto) => (
          <>
            <NumberShower ETLCode={ETLCodes.App} text={row.faxApp} />
            <NumberShower ETLCode={ETLCodes.CE} text={row.faxCe} />
            <NumberShower ETLCode={ETLCodes.FCO} text={row.faxFo} />
            <NumberShower ETLCode={ETLCodes.Autre} text={row.faxAutre} />
          </>
        )
      },
      {
        header: () => t(ETLCodes.Directeur),
        fieldName: "directeur"
      }
    ],
    [history, t]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.centre}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(() => api.centreGetSearchCriterias({ includeListsValues: true }), [api]);

  const apiFactory = useAbortableApiServiceFactory(CentreApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: CentreSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.centreBaseSearch({ CentreSearch: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      columns={columns}
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      addFunc={addItemFunction}
      breadCrumbs={[{ text: t(ETLCodes.Centres), route: ERoutes.centre }]}
      sortKeys={{ nom: "ASC" }}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
    />
  );
};
