import { Tab } from "@blueprintjs/core";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { RapportHopeDetailPage, RapportHopePermissionsPage } from ".";
import { RapportHopeApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IRapportHopeItemPageProps {}

export const RapportHopeItemPage: React.FunctionComponent<IRapportHopeItemPageProps> = props => {
  const { t } = useTl();
  const { id, tab, state } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const api = useApiService(RapportHopeApi);
  const fetchDisplayName = React.useCallback(async () => {
    if (+id <= 0) return null;
    return await api.rapportHopeGetDisplayName({ idrapportHope: +id });
  }, [api, id]);
  const { data: displayName } = useQuery(["rapport-hope-display-name", id], fetchDisplayName);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.Rapports), route: ERoutes.rapports },
        { text: +id > 0 ? displayName : t(ETLCodes.New) }
      ]}
    >
      <ProtectedTabs
        id="TabsExports"
        onChange={newTabId => history.push(`${ERoutes.rapports}/${id}/${newTabId}/${state}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.rapports} />
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<RapportHopeDetailPage idrapportHope={+id} editMode={state === "edit"} />}
        />
        <Tab
          id="permission"
          title={t(ETLCodes.Permissions)}
          panel={<RapportHopePermissionsPage idrapportHope={+id} />}
          disabled={+id === 0}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
