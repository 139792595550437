import { Classes, Switch } from "@blueprintjs/core";
import { format, parse } from "date-fns";
import { getIn } from "formik";
import { FGCustomInput, useFGContext } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { FGWalterDateMaskInput, FGWalterSelectInput } from "../../../../../components";
import { useTheme, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import { toCleanTimeZone } from "../../../../../utils";

const Container = styled.div<{ color: string }>`
  display: flex;
  & .date-mask {
    margin-right: 10px !important;
  }

  & .${Classes.SWITCH} {
    &:hover {
      input:checked ~ .${Classes.CONTROL_INDICATOR} {
        background: ${props => props.color};
      }
    }
  }
`;

export interface IInscriptionDateConvocationSelectorProps {}

export const InscriptionDateConvocationSelector: React.FunctionComponent<IInscriptionDateConvocationSelectorProps> = props => {
  const { t } = useTl();
  const { theme } = useTheme();
  const { formik } = useFGContext();

  const idclasse = React.useMemo(() => formik.values?.idclasse ?? 0, [formik.values?.idclasse]);
  const dateConvocation = React.useMemo(() => getIn(formik.values, "dateConvocation"), [formik.values]);
  const dateConvocationString = React.useMemo(() => getIn(formik.values, "dateConvocationString"), [formik.values]);
  const idinscription = React.useMemo(() => getIn(formik.values, "idinscription"), [formik.values]);

  const [inscriptionDatesConvocation, idcLoading] = useReferential(
    a =>
      a.referentialGetInscriptionDatesConvocation({
        GetInscriptionDatesConvocationRequestDto: {
          idClasse: idclasse,
          dateInscription: formik.values?.dateInscription
        }
      }),
    false,
    [idclasse, formik.values?.dateInscription]
  );

  React.useEffect(() => {
    if (inscriptionDatesConvocation?.length > 0 && idinscription <= 0) {
      let date = parse(inscriptionDatesConvocation[0].label, "dd-MM-yyyy", new Date());
      date = toCleanTimeZone(date);

      if (
        !!date &&
        !!dateConvocation &&
        !dateConvocationString &&
        (!formik.touched.dateConvocation || format(dateConvocation, "dd-MM-yyyy") !== format(date, "dd-MM-yyyy"))
      ) {
        formik.setFieldValue("dateConvocation", date);
        formik.setFieldTouched("dateConvocation", true);
      }
    }
  }, [dateConvocation, dateConvocationString, formik, idinscription, inscriptionDatesConvocation]);

  const isCustom = React.useMemo(() => !!dateConvocation, [dateConvocation]);

  return (
    <FGCustomInput label={t(ETLCodes.DateConvocation)}>
      {ctx => (
        <Container color={theme.primaryColor}>
          <FGWalterDateMaskInput
            className="date-mask"
            name="dateConvocation"
            noLabel
            disabled={idclasse <= 0 || !isCustom}
          />
          <Switch
            checked={!isCustom}
            onChange={e => {
              formik.setFieldValue(
                "dateConvocation",
                !e.currentTarget.checked ? ctx.formik?.values?.dateInscription : null
              );
              formik.setFieldValue("dateConvocationString", null);
            }}
            disabled={idclasse <= 0}
          />
          <FGWalterSelectInput
            name="dateConvocationString"
            items={inscriptionDatesConvocation}
            loading={idcLoading}
            noLabel
            disabled={idclasse <= 0 || isCustom}
          />
        </Container>
      )}
    </FGCustomInput>
  );
};
