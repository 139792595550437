import { Classes, Colors, Icon, Switch } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { ButtonContainer, IDataTableColumn, ToolTipButton } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { DashboardDemandesFormationRefuserDialog } from ".";
import {
  ChangeStatutMultipleDto,
  DashboardApi,
  DashboardDemandeFormationGridDto,
  DashboardDemandeFormationSearch,
  EStatutDemande
} from "../../../api";
import { ERoutes } from "../../../AppRouter";
import {
  BooleanColumn,
  CircleColumn,
  CustomBulletList,
  DeleteButton,
  LinkButton,
  SearchTablePage
} from "../../../components";
import { useEventsContext } from "../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTheme, useTl } from "../../../hooks";
import { ETLCodes } from "../../../locales";

const LegendHeaderContainer = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

const StyledSwitch = styled(Switch)<{ color: string }>`
  & .${Classes.SWITCH} {
    &:hover {
      input:checked ~ .${Classes.CONTROL_INDICATOR} {
        background: ${props => props.color};
      }
    }
  }
`;

export interface IDashboardDemandesFormationProps {}

export const DashboardDemandesFormation: React.FunctionComponent<IDashboardDemandesFormationProps> = props => {
  const { t } = useTl();
  const { theme } = useTheme();
  const api = useApiService(DashboardApi);

  const [displayHidden, setDisplayHidden] = React.useState(false);

  const apiFactory = useAbortableApiServiceFactory(DashboardApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFunction = React.useCallback(
    (sObj?: DashboardDemandeFormationSearch) => {
      sObj.hideMasquees = !displayHidden;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.dashboardSearchDemandeFormations({ DashboardDemandeFormationSearch: sObj });
    },
    [apiFactory, displayHidden]
  );

  const defineColor = React.useCallback((codeStatut: EStatutDemande) => {
    switch (codeStatut) {
      case EStatutDemande.I:
      case EStatutDemande.V:
        return Colors.GREEN3;
      case EStatutDemande.EA:
        return Colors.RED3;
      case EStatutDemande.EV:
        return Colors.ORANGE3;
      case EStatutDemande.E:
        return Colors.GOLD5;
      case EStatutDemande.P:
        return Colors.BLUE3;
      case EStatutDemande.AE:
        return Colors.GRAY1;
      case EStatutDemande.EC:
        return Colors.GRAY3;
      case EStatutDemande.CC:
        return Colors.RED3;
      case EStatutDemande.CE:
        return Colors.RED3;
      case EStatutDemande.ER:
        return Colors.RED3;
      case EStatutDemande.MS:
        return Colors.VIOLET4;
      case EStatutDemande.DS:
        return Colors.RED3;
      case EStatutDemande.SU:
        return Colors.RED3;
      case EStatutDemande.RC:
        return Colors.RED3;
      default:
        return Colors.GRAY3;
    }
  }, []);

  const { dispatchEvent } = useEventsContext();
  const [rowLoading, setRowLoading] = React.useState(null);
  const [currentRefus, setCurrentRefus] = React.useState<ChangeStatutMultipleDto>();
  const onClose = React.useCallback(
    async (toSave: ChangeStatutMultipleDto) => {
      if (!!toSave) {
        setRowLoading(toSave.demandes[0]);
        await api.dashboardChangeStatutMultipleDemandeFormationCrea({
          ChangeStatutMultipleDto: toSave
        });
        setRowLoading(null);
        dispatchEvent("SEARCH_TABLE_REFRESH");
        dispatchEvent("DASHBOARD_COUNTS_REFRESH_Inscriptions");
      }
      setCurrentRefus(null);
    },
    [api, dispatchEvent]
  );

  const refuserDemande = React.useCallback(async (iddemande: number) => {
    setCurrentRefus({
      codeStatut: EStatutDemande.RC,
      demandes: [iddemande]
    });
  }, []);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: DashboardDemandeFormationGridDto) => (
          <ButtonContainer>
            {row.codeStatut === EStatutDemande.E && (
              <ToolTipButton
                tooltipPosition="bottom"
                tooltip={t(ETLCodes.ApprouverEtCreerInscription)}
                minimal
                intent={row.isProspect ? "primary" : "success"}
                icon={row.isProspect ? "add" : "thumbs-up"}
                onClick={() =>
                  window.open(
                    `#${ERoutes.apprenant}/${row.idapprenant}/signaletique/edit?iddemandeFormationCrea=${row.iddemandeFormationCrea}`
                  )
                }
              />
            )}
            {row.codeStatut === EStatutDemande.E && (
              <DeleteButton
                tooltipText={t(ETLCodes.RefuserInscription)}
                icon="thumbs-down"
                onDelete={() => refuserDemande(row.iddemandeFormationCrea)}
                loading={rowLoading === row.iddemandeFormationCrea}
                disabled={!!rowLoading && rowLoading !== row.iddemandeFormationCrea}
              />
            )}
          </ButtonContainer>
        )
      },
      {
        computed: true,
        autoFitContent: true,
        alignment: "center",
        header: () => (
          <LegendHeaderContainer>
            <Tooltip2
              content={
                <CustomBulletList
                  title={t(ETLCodes.StatutDemande)}
                  items={[
                    {
                      text: t(ETLCodes.Validee),
                      color: Colors.GREEN3
                    },
                    {
                      text: t(ETLCodes.Refuse),
                      color: Colors.RED3
                    },
                    {
                      text: t(ETLCodes.Supprime),
                      color: Colors.RED3
                    },
                    {
                      text: t(ETLCodes.Envoyee),
                      color: Colors.GOLD5
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip2>
          </LegendHeaderContainer>
        ),
        fieldName: "statut",
        render: (row: DashboardDemandeFormationGridDto) => (
          <CircleColumn color={defineColor(row.codeStatut)} tooltip={row.statut} />
        )
      },
      {
        header: () => t(ETLCodes.Prospect),
        fieldName: "apprenant",
        render: (row: DashboardDemandeFormationGridDto) => (
          <LinkButton
            text={row.apprenant}
            onClick={() =>
              window.open(
                `#${row.isProspect ? ERoutes.prospect : ERoutes.apprenant}/${row.idapprenant}/signaletique/view`
              )
            }
          />
        )
      },
      {
        header: () => t(ETLCodes.Annee),
        fieldName: "anneeAcademique"
      },
      {
        header: () => t(ETLCodes.PremierSemestre),
        fieldName: "premierSemestre",
        render: (row: DashboardDemandeFormationGridDto) => <BooleanColumn value={row.premierSemestre} />
      },
      {
        header: () => t(ETLCodes.Metier),
        fieldName: "metier"
      },
      {
        header: () => t(ETLCodes.Module),
        fieldName: "module"
      },
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "centre"
      }
    ],
    [defineColor, refuserDemande, rowLoading, t]
  );

  const getCriteria = React.useCallback(async () => {
    return await api.dashboardGetDemandeFormationCriterias();
  }, [api]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <SearchTablePage
        searchStateInitialSearch={false}
        withCard={false}
        columns={columns}
        getCriteriasFunction={getCriteria}
        searchFunction={searchFunction}
        onAbort={onAbort}
        sortKeys={{ modificationDate: "DESC" }}
        rightElement={
          <StyledSwitch
            color={theme.primaryColor}
            label={t(ETLCodes.AfficherMasquees)}
            checked={displayHidden}
            onChange={e => {
              setDisplayHidden(e.currentTarget.checked);
            }}
          ></StyledSwitch>
        }
      />
      {currentRefus && (
        <DashboardDemandesFormationRefuserDialog
          currentRefus={currentRefus}
          dialogOpen={!!currentRefus}
          onClose={onClose}
        />
      )}
    </>
  );
};
