import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";

import { SiegeSocialAssuranceDialog } from ".";
import { AssuranceApi, AssuranceGridDto, AssuranceSearchDto } from "../../../../../api";
import { AddButton, DeleteButton, EditButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface ISiegeSocialAssuranceListProps {}

export const SiegeSocialAssuranceList: React.FunctionComponent<ISiegeSocialAssuranceListProps> = props => {
  const { t } = useTl();
  const { id } = useParams<{ id: string }>();
  const idsiegeSocial = React.useMemo(() => +id, [id]);
  const api = useApiService(AssuranceApi);
  const [currentAssuranceId, setCurrentAssuranceId] = React.useState(null);
  const [deletingId, setDeletingId] = React.useState(null);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { compagnieAssurance: "ASC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: AssuranceSearchDto) => {
      sObj.idsiegeSocial = idsiegeSocial;
      return api.assuranceBaseSearch({ AssuranceSearchDto: sObj });
    },
    [api, idsiegeSocial]
  );

  const { loading, search } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const onEditClick = React.useCallback((dto: AssuranceGridDto) => {
    setCurrentAssuranceId(dto.idsiegeSocialCompagnieAssurance);
  }, []);

  const onDeleteClick = React.useCallback(
    async (dto: AssuranceGridDto) => {
      setDeletingId(dto.idsiegeSocialCompagnieAssurance);
      await api.assuranceDeleteAssurance({ id: dto.idsiegeSocialCompagnieAssurance });
      setDeletingId(null);
      search();
    },
    [api, search]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: AssuranceGridDto) => (
          <ButtonContainer>
            <DeleteButton
              minimal={true}
              onDelete={() => onDeleteClick(row)}
              loading={row.idsiegeSocialCompagnieAssurance === deletingId}
            />
            <EditButton minimal={true} onClick={() => onEditClick(row)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.CompagnieAssurance),
        fieldName: "compagnieAssurance"
      },
      {
        header: () => t(ETLCodes.NumeroPolice),
        fieldName: "numeroPolice"
      }
    ],
    [deletingId, onDeleteClick, onEditClick, t]
  );

  const onAddClick = React.useCallback(() => {
    setCurrentAssuranceId(-1);
  }, []);

  const onClose = React.useCallback(() => {
    setCurrentAssuranceId(null);
    search();
  }, [search]);

  return (
    <>
      <FieldSet
        title={t(ETLCodes.TableResults, { count: totalCount })}
        rightElement={<AddButton onClick={onAddClick} text={t(ETLCodes.General_Add)} intent="primary" />}
      >
        <DataTable
          dateFormat="dd/MM/yyyy"
          tableState={tableState}
          loading={loading}
          columns={columns}
          filterMode="OnEnter"
        />
      </FieldSet>
      {!!currentAssuranceId && (
        <SiegeSocialAssuranceDialog
          currentAssuranceId={currentAssuranceId}
          idsiegeSocial={idsiegeSocial}
          onClose={onClose}
        />
      )}
    </>
  );
};
