/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbPlanLocalisationDto
 */
export interface FcbPlanLocalisationDto {
    /**
     * 
     * @type {number}
     * @memberof FcbPlanLocalisationDto
     */
    idPlan?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbPlanLocalisationDto
     */
    idCentre?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbPlanLocalisationDto
     */
    anneeAcademique?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbPlanLocalisationDto
     */
    dateValidation?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPlanLocalisationDto
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPlanLocalisationDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbPlanLocalisationDto
     */
    idStatut?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbPlanLocalisationDto
     */
    bloque?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbPlanLocalisationDto
     */
    displayButton?: boolean | null;
}

/**
 * Check if a given object implements the FcbPlanLocalisationDto interface.
 */
export function instanceOfFcbPlanLocalisationDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbPlanLocalisationDtoFromJSON(json: any): FcbPlanLocalisationDto {
    return FcbPlanLocalisationDtoFromJSONTyped(json, false);
}

export function FcbPlanLocalisationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbPlanLocalisationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idPlan': !exists(json, 'idPlan') ? undefined : json['idPlan'],
        'idCentre': !exists(json, 'idCentre') ? undefined : json['idCentre'],
        'anneeAcademique': !exists(json, 'anneeAcademique') ? undefined : json['anneeAcademique'],
        'dateValidation': !exists(json, 'dateValidation') ? undefined : (json['dateValidation'] === null ? null : new Date(json['dateValidation'])),
        'url': !exists(json, 'url') ? undefined : json['url'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'idStatut': !exists(json, 'idStatut') ? undefined : json['idStatut'],
        'bloque': !exists(json, 'bloque') ? undefined : json['bloque'],
        'displayButton': !exists(json, 'displayButton') ? undefined : json['displayButton'],
    };
}

export function FcbPlanLocalisationDtoToJSON(value?: FcbPlanLocalisationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idPlan': value.idPlan,
        'idCentre': value.idCentre,
        'anneeAcademique': value.anneeAcademique,
        'dateValidation': value.dateValidation === undefined ? undefined : (value.dateValidation === null ? null : value.dateValidation.toISOString()),
        'url': value.url,
        'remarque': value.remarque,
        'idStatut': value.idStatut,
        'bloque': value.bloque,
        'displayButton': value.displayButton,
    };
}

