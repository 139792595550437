import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";

import { useAuth } from "../../contexts";
import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales/ETLCodes";
import { ICustomButtonProps } from "./ICustomButtonProps";

interface ICreateAzureButtonProps extends ICustomButtonProps {}

export const CreateAzureButton: React.FunctionComponent<ICreateAzureButtonProps> = ({
  minimal = true,
  onClick,
  loading,
  disabled,
  intent = Intent.PRIMARY,
  text
}) => {
  const { t } = useTl();
  const { getCurrentPermission } = useAuth();
  const hasRights = React.useMemo(() => getCurrentPermission()?.permission?.includes("RW"), [getCurrentPermission]);

  return hasRights ? (
    <Button
      icon={IconNames.ADD}
      minimal={minimal}
      onClick={onClick}
      text={text || (minimal ? null : t(ETLCodes.CreateAzure))}
      intent={intent}
      loading={loading}
      disabled={disabled}
    />
  ) : (
    <></>
  );
};
