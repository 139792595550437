import { Dialog } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";
import { IRepresentantSelectorProps, RepresentantSelector } from ".";
import { useTl } from "../../../hooks";
import { ETLCodes } from "../../../locales";

const StyledDialog = styled(Dialog)`
  width: 1100px;
  height: auto;
  background-color: white;
`;

export interface IRepresentantSelectorDialogProps extends IRepresentantSelectorProps {
  onClosed: (selectedRepresentantIds?: number[]) => void;
  selectorDialogOpen: boolean;
}

export const RepresentantSelectorDialog: React.FunctionComponent<IRepresentantSelectorDialogProps> = ({
  onClosed,
  selectorDialogOpen,
  ...otherProps
}) => {
  const { t } = useTl();

  return (
    <StyledDialog
      title={t(ETLCodes.SearchRepresentants)}
      isOpen={selectorDialogOpen}
      enforceFocus={false}
      onClose={() => onClosed(null)}
    >
      <RepresentantSelector {...otherProps} />
    </StyledDialog>
  );
};
