/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbFormateurDesideratumDto,
  FcbFormateurDesideratumGridDtoPaginatedResults,
  FilterCriteriaInfo,
  FormateurDesideratumSearch,
  SelectItem,
} from '../models/index';
import {
    FcbFormateurDesideratumDtoFromJSON,
    FcbFormateurDesideratumDtoToJSON,
    FcbFormateurDesideratumGridDtoPaginatedResultsFromJSON,
    FcbFormateurDesideratumGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    FormateurDesideratumSearchFromJSON,
    FormateurDesideratumSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface FormateurDesideratumBaseSearchRequest {
    FormateurDesideratumSearch?: FormateurDesideratumSearch;
}

export interface FormateurDesideratumDeleteRequest {
    id?: number;
}

export interface FormateurDesideratumGetRequest {
    id?: number;
}

export interface FormateurDesideratumGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface FormateurDesideratumGetSelectItemsRequest {
    searchField: string;
}

export interface FormateurDesideratumSaveRequest {
    FcbFormateurDesideratumDto?: FcbFormateurDesideratumDto;
}

/**
 * 
 */
export class FormateurDesideratumApi extends runtime.BaseAPI {

    /**
     */
    async formateurDesideratumBaseSearchRaw(requestParameters: FormateurDesideratumBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurDesideratumGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurDesideratum/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormateurDesideratumSearchToJSON(requestParameters.FormateurDesideratumSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurDesideratumGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async formateurDesideratumBaseSearch(requestParameters: FormateurDesideratumBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurDesideratumGridDtoPaginatedResults> {
        const response = await this.formateurDesideratumBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurDesideratumDeleteRaw(requestParameters: FormateurDesideratumDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurDesideratum`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async formateurDesideratumDelete(requestParameters: FormateurDesideratumDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.formateurDesideratumDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurDesideratumGetRaw(requestParameters: FormateurDesideratumGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurDesideratumDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurDesideratum`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurDesideratumDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurDesideratumGet(requestParameters: FormateurDesideratumGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurDesideratumDto> {
        const response = await this.formateurDesideratumGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurDesideratumGetSearchCriteriasRaw(requestParameters: FormateurDesideratumGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurDesideratum/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async formateurDesideratumGetSearchCriterias(requestParameters: FormateurDesideratumGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.formateurDesideratumGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurDesideratumGetSelectItemsRaw(requestParameters: FormateurDesideratumGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling formateurDesideratumGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurDesideratum/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async formateurDesideratumGetSelectItems(requestParameters: FormateurDesideratumGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.formateurDesideratumGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurDesideratumSaveRaw(requestParameters: FormateurDesideratumSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurDesideratumDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurDesideratum`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbFormateurDesideratumDtoToJSON(requestParameters.FcbFormateurDesideratumDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurDesideratumDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurDesideratumSave(requestParameters: FormateurDesideratumSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurDesideratumDto> {
        const response = await this.formateurDesideratumSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
