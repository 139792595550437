import { FGCustomPanel, FGEmpty, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { FcbLocalDtoFromJSON, LocalApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterSelectInput, SmallFormGenerator, FGWalterCheckboxInput } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import { LieuSelect } from "./LieuSelect";

interface ILocalDetailPageProps {
  idLocal: number;
  editMode: boolean;
}

export const LocalDetailPage: React.FunctionComponent<ILocalDetailPageProps> = ({ idLocal, editMode }) => {
  const { t } = useTl();
  const api = useApiService(LocalApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () => (+idLocal <= 0 ? FcbLocalDtoFromJSON({ idLocal: 0 }) : api.localGet({ id: +idLocal })),
    saveApiFn: d => api.localSave({ FcbLocalDto: d }),
    onSavedRoute: d => `${ERoutes.local}/${d.idlocal}/detail/edit`,
    deleteApiFn: d => api.localDelete({ id: d.idlocal }),
    onDeletedRoute: () => ERoutes.local
  });

  const [centre, cLoading] = useReferential(cApi => cApi.referentialGetCentre(), true, []);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      code: Yup.string().required(t(ETLCodes.Required)),
      description: Yup.string().required(t(ETLCodes.Required)),
      idcentre: Yup.number().required(t(ETLCodes.Required))
    });
  }, [t]);

  const Lieu = React.useCallback((idCentre: number) => <LieuSelect idCentre={idCentre} />, []);

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={editMode}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(ERoutes.local)}
        onDelete={deleteItem}
        showDeleteButton={+idLocal > 0}
        saving={saving}
        deleting={deleting}
      >
        <FieldGroup columns={2}>
          <FGTextInput name="code" label={t(ETLCodes.Code)} forceCase="upper" maxLength={4} />
          <FGEmpty />
          <FGTextInput name="description" label={t(ETLCodes.Libelle)} maxLength={50} />
          <FGEmpty />
          <FGWalterSelectInput name="idcentre" label={t(ETLCodes.Centre)} loading={cLoading} items={centre} />
          <FGEmpty />
          <FGCustomPanel>{ctx => Lieu(ctx.formik.values.idcentre)}</FGCustomPanel>
          <FGEmpty />
          <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
