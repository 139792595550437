import { FGMaskInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { AnneeScolaireApi, FcbAnneeScolaireDetailDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterCheckboxInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { useQuery } from "react-query";

export interface IAnneeScolaireDetailPageProps {
  editMode: boolean;
  idAnnee: string;
}

export const AnneeScolaireDetailPage: React.FunctionComponent<IAnneeScolaireDetailPageProps> = ({
  editMode,
  idAnnee
}) => {
  const { t } = useTl();
  const api = useApiService(AnneeScolaireApi);
  const history = useHistory();

  const fetchIsDeletable = React.useCallback(async () => {
    if (!idAnnee) return;
    const res = await api.anneeScolaireIsDeletable({
      id: idAnnee
    });

    return res.value;
  }, [idAnnee, api]);

  const { data: isDeletable } = useQuery(["anneeScolaire-is-deletable", idAnnee], fetchIsDeletable, {
    enabled: true
  });

  const { data, loading, saveItem, saving, deleteItem, deleting } = useCrudApi({
    getApiFn: () =>
      idAnnee === "0"
        ? FcbAnneeScolaireDetailDtoFromJSON({ idanneeScolaire: 0 })
        : api.anneeScolaireGet({ id: idAnnee }),
    saveApiFn: d => api.anneeScolaireSave({ FcbAnneeScolaireDetailDto: d }),
    onSavedRoute: d => `${ERoutes.anneeScolaire}/${d.idanneeScolaire}/detail/edit`,
    deleteApiFn: d => api.anneeScolaireDelete({ id: d.idanneeScolaire }),
    onDeletedRoute: () => `${ERoutes.anneeScolaire}/`
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      anneeDebut: Yup.number()
        .required(t(ETLCodes.Required))
        .test("is-unique", t(ETLCodes.ErrorAnneeScolaireAlreadyExists), async function(value: any) {
          if (this.parent.idanneeScolaire) return true;

          const validationError = await api.anneeScolaireExists({
            anneeDebut: "" + value,
            anneeFin: this.parent.anneeFin
          });
          return validationError.isValid;
        }),
      anneeFin: Yup.number()
        .required(t(ETLCodes.Required))
        .test("is-valid", t(ETLCodes.DateFinTooLowError), async function(value) {
          return value > this.parent.anneeDebut;
        })
        .test("is-unique", t(ETLCodes.ErrorAnneeScolaireAlreadyExists), async function(value: any) {
          if (this.parent.idanneeScolaire) return true;
          const validationError = await api.anneeScolaireExists({
            anneeDebut: this.parent.anneeDebut,
            anneeFin: "" + value
          });
          return validationError.isValid;
        })
    });
  }, [api, t]);

  return (
    <SmallFormGenerator
      editable={false}
      initialValues={data}
      onSubmit={saveItem}
      editMode={editMode}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.anneeScolaire)}
      onDelete={deleteItem}
      deleting={deleting}
      showDeleteButton={idAnnee !== "0"}
      deleteBtnDisabled={!isDeletable}
      saving={saving}
      specificRights={{ module: "ANNEESCOLAIRE", tab: "DETAIL", rights: "RW" }}
    >
      <FieldGroup columns={2}>
        <FGMaskInput
          name="anneeDebut"
          label={t(ETLCodes.AnneeDebut)}
          cleaveOptions={{ blocks: [4], numeralThousandsGroupStyle: "wan", numericOnly: true }}
          readonly={idAnnee !== "0"}
          onChange={(value, formik) => {
            if (!value) {
              formik.setFieldValue("anneeFin", null);
            } else {
              formik.setFieldValue("anneeFin", 1 + parseInt(value, 10));
            }
          }}
        />
        <FGMaskInput
          name="anneeFin"
          label={t(ETLCodes.AnneeFin)}
          cleaveOptions={{ blocks: [4], numeralThousandsGroupStyle: "wan", numericOnly: true }}
          readonly
        />
        <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
