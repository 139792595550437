/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AcquisProfessionnelSearch,
  FcbAcquisProfessionnelDto,
  FcbAcquisProfessionnelGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    AcquisProfessionnelSearchFromJSON,
    AcquisProfessionnelSearchToJSON,
    FcbAcquisProfessionnelDtoFromJSON,
    FcbAcquisProfessionnelDtoToJSON,
    FcbAcquisProfessionnelGridDtoPaginatedResultsFromJSON,
    FcbAcquisProfessionnelGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface AcquisProfessionnelBaseSearchRequest {
    AcquisProfessionnelSearch?: AcquisProfessionnelSearch;
}

export interface AcquisProfessionnelDeleteRequest {
    id?: number;
}

export interface AcquisProfessionnelExistsRequest {
    entreprise?: string;
}

export interface AcquisProfessionnelGetRequest {
    id?: number;
}

export interface AcquisProfessionnelGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface AcquisProfessionnelGetSelectItemsRequest {
    searchField: string;
}

export interface AcquisProfessionnelSaveRequest {
    FcbAcquisProfessionnelDto?: FcbAcquisProfessionnelDto;
}

/**
 * 
 */
export class AcquisProfessionnelApi extends runtime.BaseAPI {

    /**
     */
    async acquisProfessionnelBaseSearchRaw(requestParameters: AcquisProfessionnelBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbAcquisProfessionnelGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisProfessionnel/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AcquisProfessionnelSearchToJSON(requestParameters.AcquisProfessionnelSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbAcquisProfessionnelGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async acquisProfessionnelBaseSearch(requestParameters: AcquisProfessionnelBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbAcquisProfessionnelGridDtoPaginatedResults> {
        const response = await this.acquisProfessionnelBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisProfessionnelDeleteRaw(requestParameters: AcquisProfessionnelDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisProfessionnel`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async acquisProfessionnelDelete(requestParameters: AcquisProfessionnelDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.acquisProfessionnelDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisProfessionnelExistsRaw(requestParameters: AcquisProfessionnelExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.entreprise !== undefined) {
            queryParameters['entreprise'] = requestParameters.entreprise;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisProfessionnel/Exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async acquisProfessionnelExists(requestParameters: AcquisProfessionnelExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.acquisProfessionnelExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisProfessionnelGetRaw(requestParameters: AcquisProfessionnelGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbAcquisProfessionnelDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisProfessionnel/get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbAcquisProfessionnelDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisProfessionnelGet(requestParameters: AcquisProfessionnelGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbAcquisProfessionnelDto> {
        const response = await this.acquisProfessionnelGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisProfessionnelGetSearchCriteriasRaw(requestParameters: AcquisProfessionnelGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisProfessionnel/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async acquisProfessionnelGetSearchCriterias(requestParameters: AcquisProfessionnelGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.acquisProfessionnelGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisProfessionnelGetSelectItemsRaw(requestParameters: AcquisProfessionnelGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling acquisProfessionnelGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisProfessionnel/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async acquisProfessionnelGetSelectItems(requestParameters: AcquisProfessionnelGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.acquisProfessionnelGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisProfessionnelSaveRaw(requestParameters: AcquisProfessionnelSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbAcquisProfessionnelDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisProfessionnel/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbAcquisProfessionnelDtoToJSON(requestParameters.FcbAcquisProfessionnelDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbAcquisProfessionnelDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisProfessionnelSave(requestParameters: AcquisProfessionnelSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbAcquisProfessionnelDto> {
        const response = await this.acquisProfessionnelSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
