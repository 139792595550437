import { Button, Checkbox, Intent, Menu, MenuItem, Popover, Spinner } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ButtonContainer, showError, showSuccess } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";

import { InscriptionSuspensionDetailDialog } from "..";
import {
  ETextSearchType,
  FcbInscriptionGridDto,
  InscriptionAbandonDto,
  InscriptionAbandonDtoFromJSON,
  InscriptionApi,
  InscriptionSearch,
  InscriptionSuspensionApi,
  InscriptionSuspensionDto,
  InscriptionSuspensionMultipleDto,
  InscriptionSuspensionMultipleDtoFromJSON
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { AddButton, EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useAuth, useEventsContext, useGlobalData } from "../../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useManageError, useTheme, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { InscriptionAbandonDetailDialog } from "./InscriptionAbandonDetailDialog";
import { InscriptionSuspensionMultipleDialog } from "./InscriptionSuspensionMultipleDialog";

const PopoverContent = styled.div`
  padding: 0.5rem;
`;

export interface IApprenantInscriptionSearchProps {}

export const ApprenantInscriptionSearch: React.FunctionComponent<IApprenantInscriptionSearchProps> = () => {
  const { t } = useTl();
  const { user } = useAuth();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { currentAnneeScolaire } = useGlobalData();
  const apprenantId = React.useMemo(() => +id, [id]);
  const api = useApiService(InscriptionApi);
  const sApi = useApiService(InscriptionSuspensionApi);
  const path = React.useMemo(() => `${ERoutes.apprenant}/${apprenantId}/inscription`, [apprenantId]);
  const { ifapmeSide } = useTheme();
  const isHope = React.useMemo(() => ifapmeSide === "hope", [ifapmeSide]);
  const { dispatchEvent } = useEventsContext();
  const [currentSuspension, setCurrentSuspension] = React.useState<InscriptionSuspensionMultipleDto>(null);
  const [currentEditSuspension, setCurrentEditSuspension] = React.useState<InscriptionSuspensionDto>(null);
  const [currentEndSuspension, setCurrentEndSuspension] = React.useState<InscriptionSuspensionMultipleDto>(null);
  const [currentAbandon, setCurrentAbandon] = React.useState<InscriptionAbandonDto>(null);
  const [selectedIDs, setSelectedIDs] = React.useState<number[]>([]);
  const lastSearchObject = React.useRef<InscriptionSearch>();
  const [selectAllLoading, setSelectAllLoading] = React.useState(false);
  const [nbReturnedIds, setNbReturnedIds] = React.useState(0);

  const onAddSuspensionClick = React.useCallback(idsinscription => {
    setCurrentSuspension(InscriptionSuspensionMultipleDtoFromJSON({ idsinscription: idsinscription }));
  }, []);

  const onEndSuspensionClick = React.useCallback(idsinscription => {
    setCurrentEndSuspension(
      InscriptionSuspensionMultipleDtoFromJSON({ idsinscription: idsinscription, endMode: true })
    );
  }, []);

  const onClose = React.useCallback(
    (toSave: InscriptionSuspensionMultipleDto) => {
      if (!!toSave) {
        dispatchEvent("SEARCH_TABLE_REFRESH");
      }
      setCurrentSuspension(null);
      setCurrentEndSuspension(null);
    },
    [dispatchEvent]
  );

  const [actionsLoading, setActionsLoading] = React.useState(false);
  const onEditSuspensionClick = React.useCallback(
    async (row: FcbInscriptionGridDto) => {
      setActionsLoading(true);
      try {
        const suspension = await sApi.inscriptionSuspensionGetByInscriptionId({ idinscription: row.idinscription });
        setCurrentEditSuspension(suspension);
      } finally {
        setActionsLoading(false);
      }
    },
    [sApi]
  );

  const onCloseEdit = React.useCallback(
    (toSave: InscriptionSuspensionDto) => {
      if (!!toSave) {
        dispatchEvent("SEARCH_TABLE_REFRESH");
      }
      setCurrentEditSuspension(null);
    },
    [dispatchEvent]
  );

  const onAbandonClick = React.useCallback((idsinscription: number[]) => {
    setCurrentAbandon(InscriptionAbandonDtoFromJSON({ idsinscription: idsinscription }));
  }, []);

  const onCloseAbandon = React.useCallback(
    (toSave: InscriptionAbandonDto) => {
      if (!!toSave) {
        dispatchEvent("SEARCH_TABLE_REFRESH");
      }
      setCurrentAbandon(null);
    },
    [dispatchEvent]
  );

  const { manageError } = useManageError();
  const onTerminerClick = React.useCallback(
    async (idsinscription: number[]) => {
      try {
        await api.inscriptionTerminer({ request_body: idsinscription });
        showSuccess(t(ETLCodes.InscriptionTermineeAvecSucces));
        dispatchEvent("SEARCH_TABLE_REFRESH");
      } catch (e) {
        manageError(e);
      }
    },
    [api, dispatchEvent, manageError, t]
  );

  const selectAll = React.useCallback(() => {
    setSelectAllLoading(true);
    api
      .inscriptionSearchIds({
        InscriptionSearch: {
          ...lastSearchObject.current,
          statuts: ["En cours", "Suspendue"],
          forceSkip: 0,
          forceTake: 999999
        }
      })
      .then(allIds => {
        if (allIds.length === 0) showError(t(ETLCodes.AucuneInscriptionElligiblesAuxActionsMultiples));
        if (allIds.length === selectedIDs.length) {
          setSelectedIDs([]);
          setNbReturnedIds(0);
        } else {
          setSelectedIDs(allIds);
          setNbReturnedIds(allIds.length);
        }
        setSelectAllLoading(false);
      });
  }, [api, selectedIDs.length, t]);

  const toggleSelection = React.useCallback(
    (item: FcbInscriptionGridDto) => {
      if (!selectedIDs.includes(item.idinscription)) {
        setSelectedIDs(ids => [...ids, item.idinscription]);
      } else {
        setSelectedIDs(ids => ids.filter(id => id !== item.idinscription));
      }
    },
    [selectedIDs]
  );

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        header: () =>
          selectAllLoading ? (
            <Spinner size={20} />
          ) : (
            <Checkbox checked={nbReturnedIds > 0 && nbReturnedIds === selectedIDs.length} onChange={selectAll} />
          ),
        render: (item: FcbInscriptionGridDto) => (
          <Checkbox
            checked={selectedIDs.includes(item.idinscription)}
            onChange={e => {
              toggleSelection(item);
            }}
          />
        )
      },
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbInscriptionGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => history.push(`${path}/${row.idinscription}/detail/view`)} />
            {!isHope &&
              (!user.idsSocieteExterne?.length ||
                user.idsSocieteExterne.some(idsocieteExterne => row.idssocieteExterne.includes(idsocieteExterne))) && (
                <EditButton minimal={true} onClick={() => history.push(`${path}/${row.idinscription}/detail/edit`)} />
              )}
            {!isHope &&
              (!user.idsSocieteExterne?.length ||
                user.idsSocieteExterne.some(idsocieteExterne => row.idssocieteExterne.includes(idsocieteExterne))) &&
              (user?.hopeMainLevelName === "CENTRE - Global" || user?.isAdmin) && (
                <Popover
                  position="bottom"
                  content={
                    <PopoverContent>
                      <Menu>
                        <MenuItem
                          icon={IconNames.STOP}
                          text={t(ETLCodes.MettreEntreAbandon)}
                          intent={Intent.PRIMARY}
                          onClick={() => onAbandonClick([row.idinscription])}
                          disabled={!["En cours", "Suspendue"].includes(row.statut)}
                        />
                        {row.statut === "En cours" && (
                          <MenuItem
                            icon={IconNames.PAUSE}
                            text={t(ETLCodes.Suspension)}
                            intent={Intent.PRIMARY}
                            onClick={() => onAddSuspensionClick([row.idinscription])}
                          />
                        )}
                        {row.statut === "Suspendue" && (
                          <MenuItem
                            icon={IconNames.PAUSE}
                            text={t(ETLCodes.ModifierSuspension)}
                            intent={Intent.PRIMARY}
                            onClick={() => onEditSuspensionClick(row)}
                          />
                        )}
                        <MenuItem
                          icon={IconNames.TICK_CIRCLE}
                          text={t(ETLCodes.Terminer)}
                          intent={Intent.PRIMARY}
                          onClick={() => onTerminerClick([row.idinscription])}
                          disabled={!["En cours", "Suspendue"].includes(row.statut)}
                        />
                      </Menu>
                    </PopoverContent>
                  }
                >
                  <Button icon={IconNames.CHEVRON_DOWN} minimal={true} loading={actionsLoading}></Button>
                </Popover>
              )}
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "codeFormation",
        computed: true
      },
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "anneeScolaire",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.DateInscription),
        fieldName: "dateInscription",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Statut),
        fieldName: "statut"
      },
      {
        header: () => t(ETLCodes.Contrat),
        fieldName: "contrat"
      },
      {
        header: () => t(ETLCodes.CodeClasse),
        fieldName: "codeClasse"
      },
      {
        header: () => t(ETLCodes.NomClasse),
        fieldName: "nomClasse"
      },
      {
        header: () => t(ETLCodes.Inscription),
        fieldName: "codeInscription"
      }
    ],
    [
      actionsLoading,
      history,
      isHope,
      nbReturnedIds,
      onAbandonClick,
      onAddSuspensionClick,
      onEditSuspensionClick,
      onTerminerClick,
      path,
      selectAll,
      selectAllLoading,
      selectedIDs,
      t,
      toggleSelection,
      user?.hopeMainLevelName,
      user.idsSocieteExterne,
      user?.isAdmin
    ]
  );

  const onAddItem = React.useCallback(() => {
    if (isHope) return null;
    history.push(`${path}/0/detail/edit`);
  }, [history, isHope, path]);

  const getCriteriasFn = React.useCallback(() => api.inscriptionGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const apiFactory = useAbortableApiServiceFactory(InscriptionApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: InscriptionSearch) => {
      sObj.idapprenant = apprenantId;
      if (JSON.stringify(sObj) !== JSON.stringify(lastSearchObject.current)) {
        lastSearchObject.current = sObj;
      }
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.inscriptionBaseSearch({ InscriptionSearch: sObj });
    },
    [apiFactory, apprenantId]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  const buttons = React.useMemo(
    () => (
      <>
        <Popover
          position="bottom"
          content={
            <PopoverContent>
              <Menu>
                <MenuItem
                  icon={IconNames.STOP}
                  text={t(ETLCodes.MettreEntreAbandon)}
                  intent={Intent.PRIMARY}
                  onClick={() => onAbandonClick(selectedIDs)}
                />
                <MenuItem
                  icon={IconNames.PAUSE}
                  text={t(ETLCodes.Suspension)}
                  intent={Intent.PRIMARY}
                  onClick={() => onAddSuspensionClick(selectedIDs)}
                />
                <MenuItem
                  icon={IconNames.PLAY}
                  text={t(ETLCodes.CloturerSuspension)}
                  intent={Intent.PRIMARY}
                  onClick={() => onEndSuspensionClick(selectedIDs)}
                />
                <MenuItem
                  icon={IconNames.TICK_CIRCLE}
                  text={t(ETLCodes.Terminer)}
                  intent={Intent.PRIMARY}
                  onClick={() => onTerminerClick(selectedIDs)}
                />
              </Menu>
            </PopoverContent>
          }
        >
          <Button
            icon={IconNames.CHEVRON_DOWN}
            minimal={true}
            text={t(ETLCodes.ActionPourLaSelection)}
            intent={Intent.PRIMARY}
          ></Button>
        </Popover>
        <AddButton onClick={onAddItem} text={t(ETLCodes.General_Add)} intent={Intent.PRIMARY} />
      </>
    ),
    [onAbandonClick, onAddItem, onAddSuspensionClick, onEndSuspensionClick, onTerminerClick, selectedIDs, t]
  );

  return (
    <>
      <SearchTablePage
        columns={columns}
        getCriteriasFunction={getCriteriasFn}
        searchFunction={searchFn}
        onAbort={onAbort}
        sortKeys={{ anneeScolaire: "DESC" }}
        title={ETLCodes.Inscriptions}
        defaultCriterias={[
          {
            criteria: "AnneeScolaire",
            searchMode: ETextSearchType.Equals,
            value: currentAnneeScolaire?.idanneeScolaire
          }
        ]}
        withCard={false}
        rightElement={!isHope && buttons}
        searchStateInitialSearch={false}
      />
      {!!currentSuspension && (
        <InscriptionSuspensionMultipleDialog
          dialogOpen={!!currentSuspension}
          currentSuspension={currentSuspension}
          onClose={onClose}
        />
      )}
      {!!currentEndSuspension && (
        <InscriptionSuspensionMultipleDialog
          dialogOpen={!!currentEndSuspension}
          currentSuspension={currentEndSuspension}
          onClose={onClose}
          endMode
        />
      )}
      {!!currentEditSuspension && (
        <InscriptionSuspensionDetailDialog
          dialogOpen={!!currentEditSuspension}
          currentSuspension={currentEditSuspension}
          onClose={onCloseEdit}
        />
      )}
      {!!currentAbandon && (
        <InscriptionAbandonDetailDialog
          dialogOpen={!!currentAbandon}
          currentAbandon={currentAbandon}
          onClose={onCloseAbandon}
        />
      )}
    </>
  );
};
