/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EStatutContrat } from './EStatutContrat';
import {
    EStatutContratFromJSON,
    EStatutContratFromJSONTyped,
    EStatutContratToJSON,
} from './EStatutContrat';
import type { ETypeContrat } from './ETypeContrat';
import {
    ETypeContratFromJSON,
    ETypeContratFromJSONTyped,
    ETypeContratToJSON,
} from './ETypeContrat';

/**
 * 
 * @export
 * @interface ContratLieuxFormationGridDto
 */
export interface ContratLieuxFormationGridDto {
    /**
     * 
     * @type {number}
     * @memberof ContratLieuxFormationGridDto
     */
    idlieuFormation?: number;
    /**
     * 
     * @type {number}
     * @memberof ContratLieuxFormationGridDto
     */
    idsiegeSocial?: number;
    /**
     * 
     * @type {string}
     * @memberof ContratLieuxFormationGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {EStatutContrat}
     * @memberof ContratLieuxFormationGridDto
     */
    statut?: EStatutContrat;
    /**
     * 
     * @type {string}
     * @memberof ContratLieuxFormationGridDto
     */
    telephone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratLieuxFormationGridDto
     */
    gsm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratLieuxFormationGridDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratLieuxFormationGridDto
     */
    localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratLieuxFormationGridDto
     */
    enseigne?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratLieuxFormationGridDto
     */
    denomination?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratLieuxFormationGridDto
     */
    service?: string | null;
    /**
     * 
     * @type {ETypeContrat}
     * @memberof ContratLieuxFormationGridDto
     */
    type?: ETypeContrat;
    /**
     * 
     * @type {string}
     * @memberof ContratLieuxFormationGridDto
     */
    metier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratLieuxFormationGridDto
     */
    stade?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratLieuxFormationGridDto
     */
    idcontrat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratLieuxFormationGridDto
     */
    code?: string | null;
}

/**
 * Check if a given object implements the ContratLieuxFormationGridDto interface.
 */
export function instanceOfContratLieuxFormationGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContratLieuxFormationGridDtoFromJSON(json: any): ContratLieuxFormationGridDto {
    return ContratLieuxFormationGridDtoFromJSONTyped(json, false);
}

export function ContratLieuxFormationGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContratLieuxFormationGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'statut': !exists(json, 'statut') ? undefined : EStatutContratFromJSON(json['statut']),
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'gsm': !exists(json, 'gsm') ? undefined : json['gsm'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'enseigne': !exists(json, 'enseigne') ? undefined : json['enseigne'],
        'denomination': !exists(json, 'denomination') ? undefined : json['denomination'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'type': !exists(json, 'type') ? undefined : ETypeContratFromJSON(json['type']),
        'metier': !exists(json, 'metier') ? undefined : json['metier'],
        'stade': !exists(json, 'stade') ? undefined : json['stade'],
        'idcontrat': !exists(json, 'idcontrat') ? undefined : json['idcontrat'],
        'code': !exists(json, 'code') ? undefined : json['code'],
    };
}

export function ContratLieuxFormationGridDtoToJSON(value?: ContratLieuxFormationGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idlieuFormation': value.idlieuFormation,
        'idsiegeSocial': value.idsiegeSocial,
        'nom': value.nom,
        'statut': EStatutContratToJSON(value.statut),
        'telephone': value.telephone,
        'gsm': value.gsm,
        'email': value.email,
        'localite': value.localite,
        'enseigne': value.enseigne,
        'denomination': value.denomination,
        'service': value.service,
        'type': ETypeContratToJSON(value.type),
        'metier': value.metier,
        'stade': value.stade,
        'idcontrat': value.idcontrat,
        'code': value.code,
    };
}

