import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";

import { EcoleDetailPage } from "..";
import { EcoleApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

interface IEcoleItemPageProps {}

export const EcoleItemPage: React.FunctionComponent<IEcoleItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const ecoleApi = useApiService(EcoleApi);
  const [displayName] = useApiEffect(() => ecoleApi.ecoleGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.Ecoles), route: ERoutes.ecole },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsEcole"
        onChange={newTabId => history.push(`${ERoutes.ecole}/${id}/${newTabId}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.ecole}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<EcoleDetailPage idEcole={+id} editMode={state === "edit"} />}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
