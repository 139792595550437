/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApprenantRepresentantHopeGridDtoPaginatedResults,
  ApprenantRepresentantInstitutionEditDto,
  ApprenantRepresentantPersonneEditDto,
  ApprenantRepresentantSearchDto,
  BooleanDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
} from '../models/index';
import {
    ApprenantRepresentantHopeGridDtoPaginatedResultsFromJSON,
    ApprenantRepresentantHopeGridDtoPaginatedResultsToJSON,
    ApprenantRepresentantInstitutionEditDtoFromJSON,
    ApprenantRepresentantInstitutionEditDtoToJSON,
    ApprenantRepresentantPersonneEditDtoFromJSON,
    ApprenantRepresentantPersonneEditDtoToJSON,
    ApprenantRepresentantSearchDtoFromJSON,
    ApprenantRepresentantSearchDtoToJSON,
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface ApprenantRepresentantHopeAlreadyHasEmergencyContactQueryRequest {
    idapprenant?: number;
    idapprenantRepresentant?: number;
    idapprenantInstitution?: number;
}

export interface ApprenantRepresentantHopeBaseSearchRequest {
    ApprenantRepresentantSearchDto?: ApprenantRepresentantSearchDto;
}

export interface ApprenantRepresentantHopeDeleteApprenantRepresentantInstitutionRequest {
    idapprenantInstitution?: number;
    idapprenant?: number;
}

export interface ApprenantRepresentantHopeDeleteApprenantRepresentantPersonneRequest {
    idapprenantRepresentant?: number;
    idapprenant?: number;
}

export interface ApprenantRepresentantHopeGetApprenantRepresentantInstitutionRequest {
    idapprenantInstitution?: number;
    idapprenant?: number;
}

export interface ApprenantRepresentantHopeGetApprenantRepresentantPersonneRequest {
    idapprenant?: number;
    idapprenantRepresentant?: number;
}

export interface ApprenantRepresentantHopeGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ApprenantRepresentantHopeGetSelectItemsRequest {
    searchField: string;
}

export interface ApprenantRepresentantHopeSaveApprenantRepresentantInstitutionRequest {
    ApprenantRepresentantInstitutionEditDto?: ApprenantRepresentantInstitutionEditDto;
}

export interface ApprenantRepresentantHopeSaveApprenantRepresentantPersonneRequest {
    ApprenantRepresentantPersonneEditDto?: ApprenantRepresentantPersonneEditDto;
}

/**
 * 
 */
export class ApprenantRepresentantHopeApi extends runtime.BaseAPI {

    /**
     */
    async apprenantRepresentantHopeAlreadyHasEmergencyContactQueryRaw(requestParameters: ApprenantRepresentantHopeAlreadyHasEmergencyContactQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        if (requestParameters.idapprenantRepresentant !== undefined) {
            queryParameters['idapprenantRepresentant'] = requestParameters.idapprenantRepresentant;
        }

        if (requestParameters.idapprenantInstitution !== undefined) {
            queryParameters['idapprenantInstitution'] = requestParameters.idapprenantInstitution;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantRepresentantHope/AlreadyHasEmergencyContactQuery`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantRepresentantHopeAlreadyHasEmergencyContactQuery(requestParameters: ApprenantRepresentantHopeAlreadyHasEmergencyContactQueryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.apprenantRepresentantHopeAlreadyHasEmergencyContactQueryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantRepresentantHopeBaseSearchRaw(requestParameters: ApprenantRepresentantHopeBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApprenantRepresentantHopeGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantRepresentantHope/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApprenantRepresentantSearchDtoToJSON(requestParameters.ApprenantRepresentantSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApprenantRepresentantHopeGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async apprenantRepresentantHopeBaseSearch(requestParameters: ApprenantRepresentantHopeBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApprenantRepresentantHopeGridDtoPaginatedResults> {
        const response = await this.apprenantRepresentantHopeBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantRepresentantHopeDeleteApprenantRepresentantInstitutionRaw(requestParameters: ApprenantRepresentantHopeDeleteApprenantRepresentantInstitutionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idapprenantInstitution !== undefined) {
            queryParameters['idapprenantInstitution'] = requestParameters.idapprenantInstitution;
        }

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantRepresentantHope/DeleteApprenantRepresentantInstitution`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apprenantRepresentantHopeDeleteApprenantRepresentantInstitution(requestParameters: ApprenantRepresentantHopeDeleteApprenantRepresentantInstitutionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apprenantRepresentantHopeDeleteApprenantRepresentantInstitutionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apprenantRepresentantHopeDeleteApprenantRepresentantPersonneRaw(requestParameters: ApprenantRepresentantHopeDeleteApprenantRepresentantPersonneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idapprenantRepresentant !== undefined) {
            queryParameters['idapprenantRepresentant'] = requestParameters.idapprenantRepresentant;
        }

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantRepresentantHope/DeleteApprenantRepresentantPersonne`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apprenantRepresentantHopeDeleteApprenantRepresentantPersonne(requestParameters: ApprenantRepresentantHopeDeleteApprenantRepresentantPersonneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apprenantRepresentantHopeDeleteApprenantRepresentantPersonneRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apprenantRepresentantHopeGetApprenantRepresentantInstitutionRaw(requestParameters: ApprenantRepresentantHopeGetApprenantRepresentantInstitutionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApprenantRepresentantInstitutionEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.idapprenantInstitution !== undefined) {
            queryParameters['idapprenantInstitution'] = requestParameters.idapprenantInstitution;
        }

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantRepresentantHope/GetApprenantRepresentantInstitution`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApprenantRepresentantInstitutionEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantRepresentantHopeGetApprenantRepresentantInstitution(requestParameters: ApprenantRepresentantHopeGetApprenantRepresentantInstitutionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApprenantRepresentantInstitutionEditDto> {
        const response = await this.apprenantRepresentantHopeGetApprenantRepresentantInstitutionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantRepresentantHopeGetApprenantRepresentantPersonneRaw(requestParameters: ApprenantRepresentantHopeGetApprenantRepresentantPersonneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApprenantRepresentantPersonneEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        if (requestParameters.idapprenantRepresentant !== undefined) {
            queryParameters['idapprenantRepresentant'] = requestParameters.idapprenantRepresentant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantRepresentantHope/GetApprenantRepresentantPersonne`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApprenantRepresentantPersonneEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantRepresentantHopeGetApprenantRepresentantPersonne(requestParameters: ApprenantRepresentantHopeGetApprenantRepresentantPersonneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApprenantRepresentantPersonneEditDto> {
        const response = await this.apprenantRepresentantHopeGetApprenantRepresentantPersonneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantRepresentantHopeGetSearchCriteriasRaw(requestParameters: ApprenantRepresentantHopeGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantRepresentantHope/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async apprenantRepresentantHopeGetSearchCriterias(requestParameters: ApprenantRepresentantHopeGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.apprenantRepresentantHopeGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantRepresentantHopeGetSelectItemsRaw(requestParameters: ApprenantRepresentantHopeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling apprenantRepresentantHopeGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantRepresentantHope/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async apprenantRepresentantHopeGetSelectItems(requestParameters: ApprenantRepresentantHopeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.apprenantRepresentantHopeGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantRepresentantHopeSaveApprenantRepresentantInstitutionRaw(requestParameters: ApprenantRepresentantHopeSaveApprenantRepresentantInstitutionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApprenantRepresentantInstitutionEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantRepresentantHope/SaveApprenantRepresentantInstitution`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApprenantRepresentantInstitutionEditDtoToJSON(requestParameters.ApprenantRepresentantInstitutionEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApprenantRepresentantInstitutionEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantRepresentantHopeSaveApprenantRepresentantInstitution(requestParameters: ApprenantRepresentantHopeSaveApprenantRepresentantInstitutionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApprenantRepresentantInstitutionEditDto> {
        const response = await this.apprenantRepresentantHopeSaveApprenantRepresentantInstitutionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantRepresentantHopeSaveApprenantRepresentantPersonneRaw(requestParameters: ApprenantRepresentantHopeSaveApprenantRepresentantPersonneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApprenantRepresentantPersonneEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantRepresentantHope/SaveApprenantRepresentantPersonne`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApprenantRepresentantPersonneEditDtoToJSON(requestParameters.ApprenantRepresentantPersonneEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApprenantRepresentantPersonneEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantRepresentantHopeSaveApprenantRepresentantPersonne(requestParameters: ApprenantRepresentantHopeSaveApprenantRepresentantPersonneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApprenantRepresentantPersonneEditDto> {
        const response = await this.apprenantRepresentantHopeSaveApprenantRepresentantPersonneRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
