/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EListSearchType } from './EListSearchType';
import {
    EListSearchTypeFromJSON,
    EListSearchTypeFromJSONTyped,
    EListSearchTypeToJSON,
} from './EListSearchType';
import type { EPrintingQueueStatus } from './EPrintingQueueStatus';
import {
    EPrintingQueueStatusFromJSON,
    EPrintingQueueStatusFromJSONTyped,
    EPrintingQueueStatusToJSON,
} from './EPrintingQueueStatus';

/**
 * 
 * @export
 * @interface EListSearchTypeEPrintingQueueStatusCombinableCriteria
 */
export interface EListSearchTypeEPrintingQueueStatusCombinableCriteria {
    /**
     * 
     * @type {EListSearchType}
     * @memberof EListSearchTypeEPrintingQueueStatusCombinableCriteria
     */
    searchMode?: EListSearchType;
    /**
     * 
     * @type {EPrintingQueueStatus}
     * @memberof EListSearchTypeEPrintingQueueStatusCombinableCriteria
     */
    value?: EPrintingQueueStatus;
    /**
     * 
     * @type {EPrintingQueueStatus}
     * @memberof EListSearchTypeEPrintingQueueStatusCombinableCriteria
     */
    secondaryValue?: EPrintingQueueStatus;
}

/**
 * Check if a given object implements the EListSearchTypeEPrintingQueueStatusCombinableCriteria interface.
 */
export function instanceOfEListSearchTypeEPrintingQueueStatusCombinableCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EListSearchTypeEPrintingQueueStatusCombinableCriteriaFromJSON(json: any): EListSearchTypeEPrintingQueueStatusCombinableCriteria {
    return EListSearchTypeEPrintingQueueStatusCombinableCriteriaFromJSONTyped(json, false);
}

export function EListSearchTypeEPrintingQueueStatusCombinableCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): EListSearchTypeEPrintingQueueStatusCombinableCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchMode': !exists(json, 'searchMode') ? undefined : EListSearchTypeFromJSON(json['searchMode']),
        'value': !exists(json, 'value') ? undefined : EPrintingQueueStatusFromJSON(json['value']),
        'secondaryValue': !exists(json, 'secondaryValue') ? undefined : EPrintingQueueStatusFromJSON(json['secondaryValue']),
    };
}

export function EListSearchTypeEPrintingQueueStatusCombinableCriteriaToJSON(value?: EListSearchTypeEPrintingQueueStatusCombinableCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchMode': EListSearchTypeToJSON(value.searchMode),
        'value': EPrintingQueueStatusToJSON(value.value),
        'secondaryValue': EPrintingQueueStatusToJSON(value.secondaryValue),
    };
}

