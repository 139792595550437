/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LieuFormationFichierGridDto
 */
export interface LieuFormationFichierGridDto {
    /**
     * 
     * @type {number}
     * @memberof LieuFormationFichierGridDto
     */
    idlieuFormationFichier?: number;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationFichierGridDto
     */
    idtypeLieuFormationFichier?: number;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationFichierGridDto
     */
    typeLieuFormationFichier?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationFichierGridDto
     */
    idfichier?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationFichierGridDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationFichierGridDto
     */
    uploadUser?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationFichierGridDto
     */
    creationUserName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationFichierGridDto
     */
    uploadDate?: Date | null;
}

/**
 * Check if a given object implements the LieuFormationFichierGridDto interface.
 */
export function instanceOfLieuFormationFichierGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LieuFormationFichierGridDtoFromJSON(json: any): LieuFormationFichierGridDto {
    return LieuFormationFichierGridDtoFromJSONTyped(json, false);
}

export function LieuFormationFichierGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LieuFormationFichierGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idlieuFormationFichier': !exists(json, 'idlieuFormationFichier') ? undefined : json['idlieuFormationFichier'],
        'idtypeLieuFormationFichier': !exists(json, 'idtypeLieuFormationFichier') ? undefined : json['idtypeLieuFormationFichier'],
        'typeLieuFormationFichier': !exists(json, 'typeLieuFormationFichier') ? undefined : json['typeLieuFormationFichier'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'uploadUser': !exists(json, 'uploadUser') ? undefined : json['uploadUser'],
        'creationUserName': !exists(json, 'creationUserName') ? undefined : json['creationUserName'],
        'uploadDate': !exists(json, 'uploadDate') ? undefined : (json['uploadDate'] === null ? null : new Date(json['uploadDate'])),
    };
}

export function LieuFormationFichierGridDtoToJSON(value?: LieuFormationFichierGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idlieuFormationFichier': value.idlieuFormationFichier,
        'idtypeLieuFormationFichier': value.idtypeLieuFormationFichier,
        'typeLieuFormationFichier': value.typeLieuFormationFichier,
        'idfichier': value.idfichier,
        'fileName': value.fileName,
        'uploadUser': value.uploadUser,
        'creationUserName': value.creationUserName,
        'uploadDate': value.uploadDate === undefined ? undefined : (value.uploadDate === null ? null : value.uploadDate.toISOString()),
    };
}

