import { FGTextInput, FieldGroup, useFGContext } from "nsitools-react";
import * as React from "react";

import { HeuresHebdomadairesSelector } from "..";
import { EStatutContrat, ETypeContrat, SiegeSocialEditDto } from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import { FGWalterDateMaskInput, FGWalterSelectInput, WarningText } from "../../../../../../components";
import { FGEuroMaskInput } from "../../../../../../components/formGenerator/FGEuroMaskInput";
import { useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";
import { nameof } from "../../../../../../utils";

export interface IContratFaltPanelProps {
  typeContrat: ETypeContrat;
  idsiegeSocial: number;
}

export const ContratFaltPanel: React.FunctionComponent<IContratFaltPanelProps> = ({ typeContrat, idsiegeSocial }) => {
  const { t } = useTl();
  const { formik } = useFGContext();

  const idstade = React.useMemo(() => formik?.values?.idstade ?? 0, [formik?.values?.idstade]);
  const [metiers, mLoading] = useReferential(
    a => a.referentialMetierByStadeForContrat({ idStade: +idstade, currentId: +(formik?.values?.idmetier ?? 0) }),
    false,
    [idstade, formik?.values?.idmetier]
  );

  const [commissionsParitaires, cpLoading] = useReferential(
    a =>
      a.referentialGetCommissionsParitaires({
        idmetier: 0,
        idsiegeSocial,
        currentId: formik.values?.idcommissionParitaire ?? 0
      }),
    false,
    [idsiegeSocial, formik.values?.idcommissionParitaire]
  );

  const [lieuxSignature, lsLoading] = useReferential(a => a.referentialGetLieuxSignature());

  const softRO = React.useMemo(() => [EStatutContrat.Rompu].includes(formik?.values?.statut), [formik?.values?.statut]);
  return (
    <>
      <FieldGroup
        columns={2}
        collapsable
        fieldsetProps={{
          title: t(ETLCodes.Detail)
        }}
      >
        <FieldGroup>
          <FGTextInput name="stade" label={t(ETLCodes.Stade)} readonly />
          <FGWalterSelectInput
            name="idmetier"
            label={t(ETLCodes.Metier)}
            items={metiers}
            loading={mLoading}
            readonly={formik?.values?.idcontrat > 0 || softRO}
          />
          <FGWalterSelectInput
            name="idcommissionParitaire"
            label={t(ETLCodes.CommissionParitaire)}
            items={commissionsParitaires}
            loading={cpLoading}
            readonly={softRO}
            helperText={ctx =>
              !!idsiegeSocial &&
              commissionsParitaires?.length === 0 && <WarningText text={t(ETLCodes.AucuneCpDansCeSiegeSocial)} />
            }
            itemCreateUrl={`${ERoutes.siegeSocial}/${idsiegeSocial}/detail/edit`}
            itemCreateParams={`&fieldName=${nameof<SiegeSocialEditDto>("idsCommissionParitaire")}`}
            autoSelectIfOne
          />
          <HeuresHebdomadairesSelector softRO={softRO} />
          <FGWalterDateMaskInput
            name="contratFaltDto.dateTransmission"
            label={t(ETLCodes.DateTransmission)}
            readonly={softRO}
          />
          <FGTextInput
            type="number"
            name="contratFaltDto.pcFormationEmployeur"
            label={t(ETLCodes.PcFormationEmployeur)}
            readonly={softRO}
          />
          <FGTextInput
            type="number"
            name="contratFaltDto.pcFormationOperateur"
            label={t(ETLCodes.PcFormationOperateur)}
            readonly={softRO}
          />
          <FGEuroMaskInput
            name="contratFaltDto.interventionFinanciere"
            label={t(ETLCodes.InterventionFinanciere)}
            readonly={softRO}
          />
        </FieldGroup>
      </FieldGroup>
      <FieldGroup
        columns={2}
        collapsable
        fieldsetProps={{
          title: t(ETLCodes.LieuxDates)
        }}
      >
        <FieldGroup>
          <FGWalterDateMaskInput name="dateDebut" label={t(ETLCodes.DateDebut)} readonly={softRO} />
          <FGWalterDateMaskInput name="dateFin" label={t(ETLCodes.DateFin)} readonly={softRO} />
          <FGWalterDateMaskInput name="dateSignature" label={t(ETLCodes.DateSignature)} readonly={softRO} />
          <FGWalterSelectInput
            name="idlieuSignature"
            label={t(ETLCodes.LieuSignature)}
            items={lieuxSignature}
            loading={lsLoading}
            readonly={softRO}
          />
        </FieldGroup>
      </FieldGroup>
    </>
  );
};
