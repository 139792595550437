/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApprenantHopeSearch,
  ApprenantSearch,
  AriReassignmentDto,
  BooleanDto,
  CopReassignmentDto,
  EmailAdressesDto,
  FcbApprenantDto,
  FcbRechercheApprenantViewDtoPaginatedResults,
  FilterCriteriaInfo,
  GetApprenantAvisResultatsIdsfichierDto,
  PassportDriveDto,
  PriReassignmentDto,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    ApprenantHopeSearchFromJSON,
    ApprenantHopeSearchToJSON,
    ApprenantSearchFromJSON,
    ApprenantSearchToJSON,
    AriReassignmentDtoFromJSON,
    AriReassignmentDtoToJSON,
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    CopReassignmentDtoFromJSON,
    CopReassignmentDtoToJSON,
    EmailAdressesDtoFromJSON,
    EmailAdressesDtoToJSON,
    FcbApprenantDtoFromJSON,
    FcbApprenantDtoToJSON,
    FcbRechercheApprenantViewDtoPaginatedResultsFromJSON,
    FcbRechercheApprenantViewDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    GetApprenantAvisResultatsIdsfichierDtoFromJSON,
    GetApprenantAvisResultatsIdsfichierDtoToJSON,
    PassportDriveDtoFromJSON,
    PassportDriveDtoToJSON,
    PriReassignmentDtoFromJSON,
    PriReassignmentDtoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface ApprenantBaseSearchRequest {
    ApprenantSearch?: ApprenantSearch;
}

export interface ApprenantCheckDoublonsNameRequest {
    FcbApprenantDto?: FcbApprenantDto;
}

export interface ApprenantCheckDoublonsNationalNumberRequest {
    FcbApprenantDto?: FcbApprenantDto;
}

export interface ApprenantDeleteRequest {
    id?: number;
}

export interface ApprenantDeleteAcquisRequest {
    id?: number;
}

export interface ApprenantDeletePassportDriveRequest {
    idapprenant?: number;
}

export interface ApprenantGetRequest {
    id?: number;
    picture?: boolean;
    iddemandeFormationCrea?: number;
}

export interface ApprenantGetAlreadySelectedInstituttionIdsRequest {
    idapprenant?: number;
}

export interface ApprenantGetAlreadySelectedRepresentantIdsRequest {
    idapprenant?: number;
}

export interface ApprenantGetAnneesScolairesInscritesRequest {
    IdApprenant?: number;
}

export interface ApprenantGetAriReassignmentRequest {
    request_body?: Array<number>;
}

export interface ApprenantGetAvisResultatsIdsfichierRequest {
    GetApprenantAvisResultatsIdsfichierDto?: GetApprenantAvisResultatsIdsfichierDto;
}

export interface ApprenantGetCopReassignmentRequest {
    request_body?: Array<number>;
}

export interface ApprenantGetDerniereAnneeRequest {
    IdApprenant?: number;
}

export interface ApprenantGetDisplayNameRequest {
    id?: number;
}

export interface ApprenantGetEmailsRequest {
    request_body?: Array<number>;
}

export interface ApprenantGetHopeSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ApprenantGetIdPersonneRequest {
    id?: number;
}

export interface ApprenantGetPassportDriveRequest {
    idapprenant?: number;
}

export interface ApprenantGetPhotoRequest {
    id?: number;
}

export interface ApprenantGetPhotoByBulletinRequest {
    id?: number;
}

export interface ApprenantGetPriReassignmentRequest {
    request_body?: Array<number>;
}

export interface ApprenantGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ApprenantGetSelectItemsRequest {
    searchField: string;
}

export interface ApprenantHasContratActifRequest {
    request_body?: Array<number>;
}

export interface ApprenantHasParcoursFormationRequest {
    id?: number;
}

export interface ApprenantIsProspectRequest {
    id?: number;
}

export interface ApprenantMailSentRequest {
    IdApprenant?: number;
}

export interface ApprenantSaveRequest {
    FcbApprenantDto?: FcbApprenantDto;
}

export interface ApprenantSaveAriReassignmentRequest {
    AriReassignmentDto?: AriReassignmentDto;
}

export interface ApprenantSaveCopReassignmentRequest {
    CopReassignmentDto?: CopReassignmentDto;
}

export interface ApprenantSavePassportDriveRequest {
    PassportDriveDto?: PassportDriveDto;
}

export interface ApprenantSavePriReassignmentRequest {
    PriReassignmentDto?: PriReassignmentDto;
}

export interface ApprenantSearchApprenantIdsRequest {
    ApprenantSearch?: ApprenantSearch;
}

export interface ApprenantSearchHopeRequest {
    ApprenantHopeSearch?: ApprenantHopeSearch;
}

export interface ApprenantSearchHopeApprenantIdsRequest {
    ApprenantHopeSearch?: ApprenantHopeSearch;
}

export interface ApprenantSocieteExterneUnauthorizedRequest {
    idApprenant?: number;
}

/**
 * 
 */
export class ApprenantApi extends runtime.BaseAPI {

    /**
     */
    async apprenantBaseSearchRaw(requestParameters: ApprenantBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRechercheApprenantViewDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApprenantSearchToJSON(requestParameters.ApprenantSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRechercheApprenantViewDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async apprenantBaseSearch(requestParameters: ApprenantBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRechercheApprenantViewDtoPaginatedResults> {
        const response = await this.apprenantBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantCheckDoublonsNameRaw(requestParameters: ApprenantCheckDoublonsNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/CheckDoublonsName`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbApprenantDtoToJSON(requestParameters.FcbApprenantDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async apprenantCheckDoublonsName(requestParameters: ApprenantCheckDoublonsNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.apprenantCheckDoublonsNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantCheckDoublonsNationalNumberRaw(requestParameters: ApprenantCheckDoublonsNationalNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/CheckDoublonsNationalNumber`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbApprenantDtoToJSON(requestParameters.FcbApprenantDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async apprenantCheckDoublonsNationalNumber(requestParameters: ApprenantCheckDoublonsNationalNumberRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.apprenantCheckDoublonsNationalNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantDeleteRaw(requestParameters: ApprenantDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apprenantDelete(requestParameters: ApprenantDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apprenantDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantDeleteAcquisRaw(requestParameters: ApprenantDeleteAcquisRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbApprenantDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/DeleteAcquis`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbApprenantDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantDeleteAcquis(requestParameters: ApprenantDeleteAcquisRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbApprenantDto> {
        const response = await this.apprenantDeleteAcquisRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantDeletePassportDriveRaw(requestParameters: ApprenantDeletePassportDriveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/DeletePassportDrive`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apprenantDeletePassportDrive(requestParameters: ApprenantDeletePassportDriveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apprenantDeletePassportDriveRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apprenantGetRaw(requestParameters: ApprenantGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbApprenantDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.picture !== undefined) {
            queryParameters['picture'] = requestParameters.picture;
        }

        if (requestParameters.iddemandeFormationCrea !== undefined) {
            queryParameters['iddemandeFormationCrea'] = requestParameters.iddemandeFormationCrea;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbApprenantDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantGet(requestParameters: ApprenantGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbApprenantDto> {
        const response = await this.apprenantGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantGetAlreadySelectedInstituttionIdsRaw(requestParameters: ApprenantGetAlreadySelectedInstituttionIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/GetAlreadySelectedInstituttionIds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apprenantGetAlreadySelectedInstituttionIds(requestParameters: ApprenantGetAlreadySelectedInstituttionIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.apprenantGetAlreadySelectedInstituttionIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantGetAlreadySelectedRepresentantIdsRaw(requestParameters: ApprenantGetAlreadySelectedRepresentantIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/GetAlreadySelectedRepresentantIds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apprenantGetAlreadySelectedRepresentantIds(requestParameters: ApprenantGetAlreadySelectedRepresentantIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.apprenantGetAlreadySelectedRepresentantIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantGetAnneesScolairesInscritesRaw(requestParameters: ApprenantGetAnneesScolairesInscritesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        if (requestParameters.IdApprenant !== undefined) {
            queryParameters['IdApprenant'] = requestParameters.IdApprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/GetAnneesScolairesInscrites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apprenantGetAnneesScolairesInscrites(requestParameters: ApprenantGetAnneesScolairesInscritesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.apprenantGetAnneesScolairesInscritesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantGetAriReassignmentRaw(requestParameters: ApprenantGetAriReassignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AriReassignmentDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/GetAriReassignment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request_body,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AriReassignmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantGetAriReassignment(requestParameters: ApprenantGetAriReassignmentRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AriReassignmentDto> {
        const response = await this.apprenantGetAriReassignmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantGetAvisResultatsIdsfichierRaw(requestParameters: ApprenantGetAvisResultatsIdsfichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/GetAvisResultatsIdsfichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetApprenantAvisResultatsIdsfichierDtoToJSON(requestParameters.GetApprenantAvisResultatsIdsfichierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apprenantGetAvisResultatsIdsfichier(requestParameters: ApprenantGetAvisResultatsIdsfichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.apprenantGetAvisResultatsIdsfichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantGetCopReassignmentRaw(requestParameters: ApprenantGetCopReassignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CopReassignmentDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/GetCopReassignment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request_body,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CopReassignmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantGetCopReassignment(requestParameters: ApprenantGetCopReassignmentRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CopReassignmentDto> {
        const response = await this.apprenantGetCopReassignmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantGetDerniereAnneeRaw(requestParameters: ApprenantGetDerniereAnneeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.IdApprenant !== undefined) {
            queryParameters['IdApprenant'] = requestParameters.IdApprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/GetDerniereAnnee`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apprenantGetDerniereAnnee(requestParameters: ApprenantGetDerniereAnneeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apprenantGetDerniereAnneeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantGetDisplayNameRaw(requestParameters: ApprenantGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apprenantGetDisplayName(requestParameters: ApprenantGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apprenantGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantGetEmailsRaw(requestParameters: ApprenantGetEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailAdressesDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/GetEmails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request_body,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailAdressesDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantGetEmails(requestParameters: ApprenantGetEmailsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailAdressesDto> {
        const response = await this.apprenantGetEmailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantGetHopeSearchCriteriasRaw(requestParameters: ApprenantGetHopeSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/hopeCriterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async apprenantGetHopeSearchCriterias(requestParameters: ApprenantGetHopeSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.apprenantGetHopeSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantGetIdPersonneRaw(requestParameters: ApprenantGetIdPersonneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/GetIdPersonne`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apprenantGetIdPersonne(requestParameters: ApprenantGetIdPersonneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.apprenantGetIdPersonneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantGetPassportDriveRaw(requestParameters: ApprenantGetPassportDriveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PassportDriveDto>> {
        const queryParameters: any = {};

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/GetPassportDrive`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PassportDriveDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantGetPassportDrive(requestParameters: ApprenantGetPassportDriveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PassportDriveDto> {
        const response = await this.apprenantGetPassportDriveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantGetPhotoRaw(requestParameters: ApprenantGetPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/photo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apprenantGetPhoto(requestParameters: ApprenantGetPhotoRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apprenantGetPhotoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantGetPhotoByBulletinRaw(requestParameters: ApprenantGetPhotoByBulletinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/GetPhotoByBulletin`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apprenantGetPhotoByBulletin(requestParameters: ApprenantGetPhotoByBulletinRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.apprenantGetPhotoByBulletinRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantGetPriReassignmentRaw(requestParameters: ApprenantGetPriReassignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PriReassignmentDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/GetPriReassignment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request_body,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PriReassignmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantGetPriReassignment(requestParameters: ApprenantGetPriReassignmentRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PriReassignmentDto> {
        const response = await this.apprenantGetPriReassignmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantGetSearchCriteriasRaw(requestParameters: ApprenantGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async apprenantGetSearchCriterias(requestParameters: ApprenantGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.apprenantGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantGetSelectItemsRaw(requestParameters: ApprenantGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling apprenantGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async apprenantGetSelectItems(requestParameters: ApprenantGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.apprenantGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantHasContratActifRaw(requestParameters: ApprenantHasContratActifRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/HasContratActif`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request_body,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantHasContratActif(requestParameters: ApprenantHasContratActifRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.apprenantHasContratActifRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantHasParcoursFormationRaw(requestParameters: ApprenantHasParcoursFormationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/HasParcoursFormation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantHasParcoursFormation(requestParameters: ApprenantHasParcoursFormationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.apprenantHasParcoursFormationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantIsProspectRaw(requestParameters: ApprenantIsProspectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/IsProspect`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantIsProspect(requestParameters: ApprenantIsProspectRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.apprenantIsProspectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantMailSentRaw(requestParameters: ApprenantMailSentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.IdApprenant !== undefined) {
            queryParameters['IdApprenant'] = requestParameters.IdApprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/MailSent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apprenantMailSent(requestParameters: ApprenantMailSentRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apprenantMailSentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apprenantSaveRaw(requestParameters: ApprenantSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbApprenantDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbApprenantDtoToJSON(requestParameters.FcbApprenantDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbApprenantDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantSave(requestParameters: ApprenantSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbApprenantDto> {
        const response = await this.apprenantSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantSaveAriReassignmentRaw(requestParameters: ApprenantSaveAriReassignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AriReassignmentDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/SaveAriReassignment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AriReassignmentDtoToJSON(requestParameters.AriReassignmentDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AriReassignmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantSaveAriReassignment(requestParameters: ApprenantSaveAriReassignmentRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AriReassignmentDto> {
        const response = await this.apprenantSaveAriReassignmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantSaveCopReassignmentRaw(requestParameters: ApprenantSaveCopReassignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CopReassignmentDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/SaveCopReassignment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CopReassignmentDtoToJSON(requestParameters.CopReassignmentDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CopReassignmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantSaveCopReassignment(requestParameters: ApprenantSaveCopReassignmentRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CopReassignmentDto> {
        const response = await this.apprenantSaveCopReassignmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantSavePassportDriveRaw(requestParameters: ApprenantSavePassportDriveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PassportDriveDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/SavePassportDrive`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PassportDriveDtoToJSON(requestParameters.PassportDriveDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PassportDriveDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantSavePassportDrive(requestParameters: ApprenantSavePassportDriveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PassportDriveDto> {
        const response = await this.apprenantSavePassportDriveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantSavePriReassignmentRaw(requestParameters: ApprenantSavePriReassignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PriReassignmentDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/SavePriReassignment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PriReassignmentDtoToJSON(requestParameters.PriReassignmentDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PriReassignmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantSavePriReassignment(requestParameters: ApprenantSavePriReassignmentRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PriReassignmentDto> {
        const response = await this.apprenantSavePriReassignmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantSearchApprenantIdsRaw(requestParameters: ApprenantSearchApprenantIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/SearchApprenantIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApprenantSearchToJSON(requestParameters.ApprenantSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apprenantSearchApprenantIds(requestParameters: ApprenantSearchApprenantIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.apprenantSearchApprenantIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantSearchHopeRaw(requestParameters: ApprenantSearchHopeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRechercheApprenantViewDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/SearchHope`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApprenantHopeSearchToJSON(requestParameters.ApprenantHopeSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRechercheApprenantViewDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async apprenantSearchHope(requestParameters: ApprenantSearchHopeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRechercheApprenantViewDtoPaginatedResults> {
        const response = await this.apprenantSearchHopeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantSearchHopeApprenantIdsRaw(requestParameters: ApprenantSearchHopeApprenantIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/SearchHopeApprenantIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApprenantHopeSearchToJSON(requestParameters.ApprenantHopeSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apprenantSearchHopeApprenantIds(requestParameters: ApprenantSearchHopeApprenantIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.apprenantSearchHopeApprenantIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantSocieteExterneUnauthorizedRaw(requestParameters: ApprenantSocieteExterneUnauthorizedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idApprenant !== undefined) {
            queryParameters['idApprenant'] = requestParameters.idApprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Apprenant/SocieteExterneUnauthorized`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantSocieteExterneUnauthorized(requestParameters: ApprenantSocieteExterneUnauthorizedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.apprenantSocieteExterneUnauthorizedRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
