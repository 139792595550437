/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CentreDemandeRequestDto
 */
export interface CentreDemandeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CentreDemandeRequestDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CentreDemandeRequestDto
     */
    idmetier?: number;
    /**
     * 
     * @type {number}
     * @memberof CentreDemandeRequestDto
     */
    iddegre?: number;
    /**
     * 
     * @type {number}
     * @memberof CentreDemandeRequestDto
     */
    idtypeCours?: number;
}

/**
 * Check if a given object implements the CentreDemandeRequestDto interface.
 */
export function instanceOfCentreDemandeRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CentreDemandeRequestDtoFromJSON(json: any): CentreDemandeRequestDto {
    return CentreDemandeRequestDtoFromJSONTyped(json, false);
}

export function CentreDemandeRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CentreDemandeRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'iddegre': !exists(json, 'iddegre') ? undefined : json['iddegre'],
        'idtypeCours': !exists(json, 'idtypeCours') ? undefined : json['idtypeCours'],
    };
}

export function CentreDemandeRequestDtoToJSON(value?: CentreDemandeRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'anneeScolaire': value.anneeScolaire,
        'idmetier': value.idmetier,
        'iddegre': value.iddegre,
        'idtypeCours': value.idtypeCours,
    };
}

