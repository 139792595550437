import * as signalR from "@microsoft/signalr";
import { showInfo } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router";

import { useGlobalData } from ".";
import { CarteIdentiteApi, ETypeCarteIdentiteUser } from "../api";
import { ERoutes } from "../AppRouter";
import { useApiService, useTl } from "../hooks";
import { ETLCodes } from "../locales";
import { useAuth } from "./AuthContext";

interface IBeIdCardContext {
  card?: CardData;
  connected: boolean;
  foundRowsData: {
    nationalNb: string;
    nom: string;
    prenom: string;
    dateNaissance: string;
  };
  singleFoundUser: { id: number; idpersonne: number; type: ETypeCarteIdentiteUser };
  loading: boolean;
  clearCardData: () => void;
  newVersionAvailable: boolean;
}

const BeIdCardContext = React.createContext<IBeIdCardContext>(null);

interface IBeIdCardProviderProps {}

export type CardData = {
  firstName: string;
  surname: string;
  firstNames: string;
  nationalNumber: string;
  nationality: string;
  dateOfBirth: string;
  dateOfBirthParsed: string;
  gender: string;
  addressStreetAndNumber: string;
  addressZip: string;
  addressMunicipality: string;
  photoB64: string;
  locationAndBirthDate: string;
  fullName: string;
  cardNumber: string;
  beginValidityDate: string;
  endValidityDate: string;
  validityDates: string;
  locationOfBirth: string;
};

// const beidToast = Toaster.create({ position: Position.TOP_RIGHT });

const connection = new signalR.HubConnectionBuilder()
  .withUrl("http://localhost:20050/carddata")
  .withAutomaticReconnect()
  .build();

export const BeIdCardProvider: React.FunctionComponent<IBeIdCardProviderProps> = ({ children }) => {
  const { t } = useTl();
  const [cardData, setCardData] = React.useState<CardData>();
  const [loading, setLoading] = React.useState(false);
  const [connected, setConnected] = React.useState(false);
  const [foundRowsData, setFoundRowsData] = React.useState<{
    nationalNb: string;
    nom: string;
    prenom: string;
    dateNaissance: string;
  }>(null);
  const [singleFoundUser, setSingleFoundUser] = React.useState<{
    id: number;
    idpersonne: number;
    type: ETypeCarteIdentiteUser;
  }>(null);
  const carteIdentiteApi = useApiService(CarteIdentiteApi);
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { user } = useAuth();
  const { beIDVersion } = useGlobalData();

  const clearCardData = React.useCallback(() => {
    setCardData(null);
    setSingleFoundUser(null);
    setFoundRowsData(null);
  }, []);

  const isPageNotRedirectable = React.useMemo(() => {
    return (
      pathname.startsWith(ERoutes.apprenant + "/") ||
      pathname.startsWith(ERoutes.representant + "/") ||
      pathname.startsWith(ERoutes.formateur + "/") ||
      pathname.startsWith(ERoutes.tuteur + "/") ||
      pathname.startsWith(ERoutes.chefEntreprise + "/")
    );
  }, [pathname]);

  const onNewCard = React.useCallback(
    async (data: CardData) => {
      if (!pathname.startsWith("/export") && !pathname.startsWith("/report") && user) {
        if (!data) {
          setCardData(null);
          return;
        }
        const fixedDateOfBirth = data.dateOfBirthParsed.endsWith("Z")
          ? data.dateOfBirthParsed
          : `${data.dateOfBirthParsed}Z`;
        setCardData({ ...data, dateOfBirthParsed: fixedDateOfBirth });
        showInfo(t(ETLCodes.RechercheUtilisateurEnCours));
        try {
          setLoading(true);
          const result = await carteIdentiteApi.carteIdentiteCheckExisting({
            nationalNb: data.nationalNumber,
            nom: data.surname,
            prenom: data.firstName,
            dateNaissance: new Date(fixedDateOfBirth)
          });
          if (result.existingIds && result.existingIds.length > 0) {
            setFoundRowsData({
              nationalNb: data.nationalNumber,
              nom: data.surname,
              prenom: data.firstName,
              dateNaissance: fixedDateOfBirth
            });
            let ficheRoute = null;
            switch (result.typeUser) {
              case ETypeCarteIdentiteUser.Apprenant:
                ficheRoute = { route: ERoutes.apprenant, tab: "signaletique" };
                break;
              case ETypeCarteIdentiteUser.Representant:
                ficheRoute = { route: ERoutes.representant, tab: "detail" };
                break;
              case ETypeCarteIdentiteUser.Formateur:
                ficheRoute = { route: ERoutes.formateur, tab: "signaletique" };
                break;
              case ETypeCarteIdentiteUser.Tuteur:
                ficheRoute = { route: ERoutes.tuteur, tab: "detail" };
                break;
              case ETypeCarteIdentiteUser.ChefEntreprise:
                ficheRoute = { route: ERoutes.chefEntreprise, tab: "detail" };
                break;
            }
            const isSocieteExterneAdd = pathname.startsWith(ERoutes.apprenant) && pathname.endsWith("/societeExterne");
            if (result.existingIds.length === 1) {
              setSingleFoundUser({
                id: result.existingIds[0],
                idpersonne: result.existingPersonneIds[0],
                type: result.typeUser
              });
              if (!isSocieteExterneAdd) {
                if (!isPageNotRedirectable) {
                  history.push(`${ficheRoute.route}/${result.existingIds[0]}/${ficheRoute.tab}/edit`);
                }
              }
            } else {
              if (!isPageNotRedirectable) {
                history.push(`${ficheRoute.route}/0/${ficheRoute.tab}/checkDoublons`);
              }
            }
          } else {
            setSingleFoundUser(null);
            setFoundRowsData(null);
            showInfo(t(ETLCodes.AucuneUtilisateurTrouvé));
          }
        } catch (error) {
          console.error("An error has occured while checking card user existance:", error);
        } finally {
          setLoading(false);
        }
      }
    },
    [carteIdentiteApi, history, isPageNotRedirectable, pathname, t, user]
  );

  React.useEffect(() => {
    connection.onreconnected(() => setConnected(true));
    connection.onreconnecting(() => setConnected(false));
    connection
      .start()
      .then(() => setConnected(true))
      .catch(error => console.error(error));
  }, []);

  React.useEffect(() => {
    connection.on("NewCardData", onNewCard);
    return () => {
      connection.off("NewCardData");
    };
  }, [onNewCard]);

  const fetchInstalledVersion = React.useCallback(async () => {
    try {
      const response = await window.fetch("http://localhost:20050/api/system/");
      return response.status === 200 ? (await response.json())?.applicationVersion?.substr(0, 5) : null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }, []);
  const { data: installedVersion } = useQuery("beid-version", fetchInstalledVersion, { cacheTime: 0 });

  const newVersionAvailable = React.useMemo(() => {
    if (!installedVersion || !beIDVersion) return false;
    return (
      +beIDVersion.split(".")[0] > +installedVersion.split(".")[0] ||
      (+beIDVersion.split(".")[0] === +installedVersion.split(".")[0] &&
        +beIDVersion.split(".")[1] > +installedVersion.split(".")[1]) ||
      (+beIDVersion.split(".")[0] === +installedVersion.split(".")[0] &&
        +beIDVersion.split(".")[1] === +installedVersion.split(".")[1] &&
        +beIDVersion.split(".")[2] > +installedVersion.split(".")[2])
    );
  }, [beIDVersion, installedVersion]);

  return (
    <BeIdCardContext.Provider
      value={{
        card: cardData,
        connected,
        foundRowsData: foundRowsData,
        singleFoundUser,
        loading,
        clearCardData,
        newVersionAvailable
      }}
    >
      {children}
    </BeIdCardContext.Provider>
  );
};

export const useBeIdCardContext = () => React.useContext(BeIdCardContext);
