import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { DegreDetailPage } from "../../..";
import { DegreApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

interface IDegreItemPageProps {}

export const DegreItemPage: React.FunctionComponent<IDegreItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const degreApi = useApiService(DegreApi);
  const [displayName] = useApiEffect(() => degreApi.degreGetDisplayName({ id: id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.Degres), route: ERoutes.degre },
        { text: id === "0" ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsCentre"
        onChange={newTabId => history.push(`${ERoutes.degre}/${id}/${newTabId}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.degre}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<DegreDetailPage editMode={state === "edit"} idDegre={id} />}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
