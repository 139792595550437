/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbClasseRemarqueGridDto
 */
export interface FcbClasseRemarqueGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbClasseRemarqueGridDto
     */
    idRemarque?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseRemarqueGridDto
     */
    idClasse?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseRemarqueGridDto
     */
    user?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseRemarqueGridDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbClasseRemarqueGridDto
     */
    dateRemarque?: Date | null;
}

/**
 * Check if a given object implements the FcbClasseRemarqueGridDto interface.
 */
export function instanceOfFcbClasseRemarqueGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbClasseRemarqueGridDtoFromJSON(json: any): FcbClasseRemarqueGridDto {
    return FcbClasseRemarqueGridDtoFromJSONTyped(json, false);
}

export function FcbClasseRemarqueGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbClasseRemarqueGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idRemarque': !exists(json, 'idRemarque') ? undefined : json['idRemarque'],
        'idClasse': !exists(json, 'idClasse') ? undefined : json['idClasse'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'dateRemarque': !exists(json, 'dateRemarque') ? undefined : (json['dateRemarque'] === null ? null : new Date(json['dateRemarque'])),
    };
}

export function FcbClasseRemarqueGridDtoToJSON(value?: FcbClasseRemarqueGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idRemarque': value.idRemarque,
        'idClasse': value.idClasse,
        'user': value.user,
        'remarque': value.remarque,
        'dateRemarque': value.dateRemarque === undefined ? undefined : (value.dateRemarque === null ? null : value.dateRemarque.toISOString()),
    };
}

