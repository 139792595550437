/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbPaiementGridDto
 */
export interface FcbPaiementGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbPaiementGridDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbPaiementGridDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbPaiementGridDto
     */
    datePaiement?: Date;
    /**
     * 
     * @type {number}
     * @memberof FcbPaiementGridDto
     */
    idmode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPaiementGridDto
     */
    modePaiement?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbPaiementGridDto
     */
    montantPaye?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbPaiementGridDto
     */
    idcentre?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPaiementGridDto
     */
    nomCentre?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbPaiementGridDto
     */
    idPaiement?: number;
}

/**
 * Check if a given object implements the FcbPaiementGridDto interface.
 */
export function instanceOfFcbPaiementGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbPaiementGridDtoFromJSON(json: any): FcbPaiementGridDto {
    return FcbPaiementGridDtoFromJSONTyped(json, false);
}

export function FcbPaiementGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbPaiementGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'datePaiement': !exists(json, 'datePaiement') ? undefined : (new Date(json['datePaiement'])),
        'idmode': !exists(json, 'idmode') ? undefined : json['idmode'],
        'modePaiement': !exists(json, 'modePaiement') ? undefined : json['modePaiement'],
        'montantPaye': !exists(json, 'montantPaye') ? undefined : json['montantPaye'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'nomCentre': !exists(json, 'nomCentre') ? undefined : json['nomCentre'],
        'idPaiement': !exists(json, 'idPaiement') ? undefined : json['idPaiement'],
    };
}

export function FcbPaiementGridDtoToJSON(value?: FcbPaiementGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idapprenant': value.idapprenant,
        'anneeScolaire': value.anneeScolaire,
        'datePaiement': value.datePaiement === undefined ? undefined : (value.datePaiement.toISOString()),
        'idmode': value.idmode,
        'modePaiement': value.modePaiement,
        'montantPaye': value.montantPaye,
        'idcentre': value.idcentre,
        'nomCentre': value.nomCentre,
        'idPaiement': value.idPaiement,
    };
}

