import { FGEmpty, FGTextInput, FieldGroup, FieldSet } from "nsitools-react";
import * as React from "react";

import { CentreApi, FcbCentreDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { CentreResponsableAdjointList } from "..";
import { useReferential } from "../../../../../hooks/useReferential";

export interface ICentreResponsableProps {
  idCentre: number;
  editMode: boolean;
}

export const CentreResponsable: React.FunctionComponent<ICentreResponsableProps> = ({ idCentre, editMode }) => {
  const { t } = useTl();
  const api = useApiService(CentreApi);

  const { data, loading, saveItem, saving } = useCrudApi({
    getApiFn: () => (+idCentre <= 0 ? FcbCentreDtoFromJSON({ idCentre: 0 }) : api.centreGet({ id: +idCentre })),
    saveApiFn: d => api.centreSave({ FcbCentreDto: d }),
    onSavedRoute: d => `${ERoutes.centre}/${d.idcentre}/responsable/edit`,
    deleteApiFn: d => api.centreDelete({ id: d.idcentre }),
    onDeletedRoute: () => ERoutes.centre
  });

  const [users, usersLoading] = useReferential(a => a.referentialGetCentreGlobalUsers(), false, []);

  return (
    data && (
      <>
        <FieldSet title={t(ETLCodes.Responsables)}>
          <SmallFormGenerator
            initialValues={data}
            onSubmit={saveItem}
            editMode={editMode}
            loading={loading}
            saving={saving}
          >
            <FieldGroup columns={2}>
              <FGTextInput name="president" maxLength={50} label={t(ETLCodes.President)} />
              <FGEmpty />
              <FGWalterSelectInput
                name="iddirecteur"
                label={t(ETLCodes.Directeur)}
                items={users}
                loading={usersLoading}
              />
            </FieldGroup>
          </SmallFormGenerator>
        </FieldSet>
        <CentreResponsableAdjointList idCentre={idCentre} />
      </>
    )
  );
};
