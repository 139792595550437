/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EOrigineSuivi } from './EOrigineSuivi';
import {
    EOrigineSuiviFromJSON,
    EOrigineSuiviFromJSONTyped,
    EOrigineSuiviToJSON,
} from './EOrigineSuivi';

/**
 * 
 * @export
 * @interface SuiviGridDto
 */
export interface SuiviGridDto {
    /**
     * 
     * @type {number}
     * @memberof SuiviGridDto
     */
    idsuivi?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviGridDto
     */
    idservice?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviGridDto
     */
    service?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviGridDto
     */
    centre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviGridDto
     */
    userService?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviGridDto
     */
    idencodeur?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviGridDto
     */
    encodeur?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviGridDto
     */
    idrealisateurSuivi?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviGridDto
     */
    realisateurSuivi?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SuiviGridDto
     */
    fromHope?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof SuiviGridDto
     */
    dateSuivi?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviGridDto
     */
    idapprenant?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviGridDto
     */
    nomApprenant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviGridDto
     */
    prenomApprenant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviGridDto
     */
    apprenant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviGridDto
     */
    readonly apprenantFullName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviGridDto
     */
    idtypeSuivi?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviGridDto
     */
    typeSuivi?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviGridDto
     */
    idsousTypeSuivi?: number;
    /**
     * 
     * @type {string}
     * @memberof SuiviGridDto
     */
    sousTypeSuivi?: string | null;
    /**
     * 
     * @type {EOrigineSuivi}
     * @memberof SuiviGridDto
     */
    origineSuivi?: EOrigineSuivi;
    /**
     * 
     * @type {string}
     * @memberof SuiviGridDto
     */
    hiddenContent?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SuiviGridDto
     */
    actionDateEcheance?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof SuiviGridDto
     */
    actionDateRealisation?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviGridDto
     */
    realisateurAction?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviGridDto
     */
    idcentre?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SuiviGridDto
     */
    masquee?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SuiviGridDto
     */
    fromCrea?: boolean;
}

/**
 * Check if a given object implements the SuiviGridDto interface.
 */
export function instanceOfSuiviGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SuiviGridDtoFromJSON(json: any): SuiviGridDto {
    return SuiviGridDtoFromJSONTyped(json, false);
}

export function SuiviGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuiviGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsuivi': !exists(json, 'idsuivi') ? undefined : json['idsuivi'],
        'idservice': !exists(json, 'idservice') ? undefined : json['idservice'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'centre': !exists(json, 'centre') ? undefined : json['centre'],
        'userService': !exists(json, 'userService') ? undefined : json['userService'],
        'idencodeur': !exists(json, 'idencodeur') ? undefined : json['idencodeur'],
        'encodeur': !exists(json, 'encodeur') ? undefined : json['encodeur'],
        'idrealisateurSuivi': !exists(json, 'idrealisateurSuivi') ? undefined : json['idrealisateurSuivi'],
        'realisateurSuivi': !exists(json, 'realisateurSuivi') ? undefined : json['realisateurSuivi'],
        'fromHope': !exists(json, 'fromHope') ? undefined : json['fromHope'],
        'dateSuivi': !exists(json, 'dateSuivi') ? undefined : (json['dateSuivi'] === null ? null : new Date(json['dateSuivi'])),
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'nomApprenant': !exists(json, 'nomApprenant') ? undefined : json['nomApprenant'],
        'prenomApprenant': !exists(json, 'prenomApprenant') ? undefined : json['prenomApprenant'],
        'apprenant': !exists(json, 'apprenant') ? undefined : json['apprenant'],
        'apprenantFullName': !exists(json, 'apprenantFullName') ? undefined : json['apprenantFullName'],
        'idtypeSuivi': !exists(json, 'idtypeSuivi') ? undefined : json['idtypeSuivi'],
        'typeSuivi': !exists(json, 'typeSuivi') ? undefined : json['typeSuivi'],
        'idsousTypeSuivi': !exists(json, 'idsousTypeSuivi') ? undefined : json['idsousTypeSuivi'],
        'sousTypeSuivi': !exists(json, 'sousTypeSuivi') ? undefined : json['sousTypeSuivi'],
        'origineSuivi': !exists(json, 'origineSuivi') ? undefined : EOrigineSuiviFromJSON(json['origineSuivi']),
        'hiddenContent': !exists(json, 'hiddenContent') ? undefined : json['hiddenContent'],
        'actionDateEcheance': !exists(json, 'actionDateEcheance') ? undefined : (json['actionDateEcheance'] === null ? null : new Date(json['actionDateEcheance'])),
        'actionDateRealisation': !exists(json, 'actionDateRealisation') ? undefined : (json['actionDateRealisation'] === null ? null : new Date(json['actionDateRealisation'])),
        'realisateurAction': !exists(json, 'realisateurAction') ? undefined : json['realisateurAction'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'masquee': !exists(json, 'masquee') ? undefined : json['masquee'],
        'fromCrea': !exists(json, 'fromCrea') ? undefined : json['fromCrea'],
    };
}

export function SuiviGridDtoToJSON(value?: SuiviGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsuivi': value.idsuivi,
        'idservice': value.idservice,
        'service': value.service,
        'centre': value.centre,
        'userService': value.userService,
        'idencodeur': value.idencodeur,
        'encodeur': value.encodeur,
        'idrealisateurSuivi': value.idrealisateurSuivi,
        'realisateurSuivi': value.realisateurSuivi,
        'fromHope': value.fromHope,
        'dateSuivi': value.dateSuivi === undefined ? undefined : (value.dateSuivi === null ? null : value.dateSuivi.toISOString()),
        'idapprenant': value.idapprenant,
        'nomApprenant': value.nomApprenant,
        'prenomApprenant': value.prenomApprenant,
        'apprenant': value.apprenant,
        'idtypeSuivi': value.idtypeSuivi,
        'typeSuivi': value.typeSuivi,
        'idsousTypeSuivi': value.idsousTypeSuivi,
        'sousTypeSuivi': value.sousTypeSuivi,
        'origineSuivi': EOrigineSuiviToJSON(value.origineSuivi),
        'hiddenContent': value.hiddenContent,
        'actionDateEcheance': value.actionDateEcheance === undefined ? undefined : (value.actionDateEcheance === null ? null : value.actionDateEcheance.toISOString()),
        'actionDateRealisation': value.actionDateRealisation === undefined ? undefined : (value.actionDateRealisation === null ? null : value.actionDateRealisation.toISOString()),
        'realisateurAction': value.realisateurAction,
        'idcentre': value.idcentre,
        'masquee': value.masquee,
        'fromCrea': value.fromCrea,
    };
}

