import * as React from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import styled from "styled-components";
import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import moment from "moment";
import assets from "../../assets";

interface ICardAttributeProps {
  label: string;
  value: string;
}

export interface IToolTipContentProps {
  isLight: boolean;
  firstName: string;
  lastName: string;
  birthDate?: Date;
  sex?: string;
  nationality?: string;
  photo?: string;
  interne?: string;
}

const LabelAttrt = styled.div`
  font-weight: bold;
  font-style: italic;
  font-size: 0.8em;
  color: white;
`;
const ValueAttr = styled.div`
  color: white;
  word-wrap: break-word;
  max-width: 70%;
`;

const CardAttributeContainer = styled.div`
  min-width: 160px;
  margin-bottom: 0.2em;
  color: black;
`;

const PhotoImage = styled.img`
  object-fit: fill;
  width: 100px;
  border-radius: 10px;
  border: 1px solid lightgray;
  max-width: 120px;
  max-height: 160px;
`;

const CardAttribute: React.FC<ICardAttributeProps> = ({ label, value }) => {
  return (
    <CardAttributeContainer>
      <LabelAttrt>{label}</LabelAttrt>
      <ValueAttr>{value}</ValueAttr>
    </CardAttributeContainer>
  );
};

export const ToolTipContent: React.FunctionComponent<IToolTipContentProps> = ({
  firstName,
  lastName,
  birthDate,
  sex,
  nationality,
  photo,
  interne,
  isLight
}) => {
  const { t } = useTl();
  return isLight ? (
    <Grid
      fluid
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "10px",
        paddingBottom: "10px"
      }}
    >
      <Row>
        <Col>
          <Row>
            <Col>
              <CardAttribute label={t(ETLCodes.Nom)} value={lastName}></CardAttribute>
            </Col>
            <Col>
              <CardAttribute label={t(ETLCodes.FirstNames)} value={firstName}></CardAttribute>
            </Col>
          </Row>
        </Col>
      </Row>
    </Grid>
  ) : (
    <Grid
      fluid
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "10px",
        paddingBottom: "10px"
      }}
    >
      <Row>
        <Col>
          <Row>
            <Col>
              <CardAttribute label={t(ETLCodes.Nom)} value={lastName}></CardAttribute>
            </Col>
            <Col>
              <CardAttribute label={t(ETLCodes.FirstNames)} value={firstName}></CardAttribute>
            </Col>
          </Row>
          <Row>
            <Col>
              <CardAttribute
                label={t(ETLCodes.DateNaissance)}
                value={moment(birthDate).format("DD-MM-YYYY")}
              ></CardAttribute>
            </Col>
          </Row>
          <Row>
            <Col>
              <CardAttribute label={t(ETLCodes.Genre)} value={sex}></CardAttribute>
            </Col>
            <Col>
              <CardAttribute label={t(ETLCodes.Nationalite)} value={nationality}></CardAttribute>
            </Col>
          </Row>
          <Row>
            <Col>
              <CardAttribute label={t(ETLCodes.Interne)} value={interne} />
            </Col>
          </Row>
        </Col>

        <Col>
          {photo ? <PhotoImage src={`data:image/jpg;base64,${photo}`} /> : <PhotoImage src={assets.images.userlogo} />}
        </Col>
      </Row>
    </Grid>
  );
};
