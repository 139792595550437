import { ITabProps, Tab } from "@blueprintjs/core";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { DashboardDossiersTabTitle, DashboardInscriptions, DashboardSuivisSiegeLieu, DashboardTabTitle } from ".";
import { EDashboard } from "../../api";
import { ERoutes } from "../../AppRouter";
import { PageBase, ProtectedTabs } from "../../components";
import { useAuth } from "../../contexts";
import { useTheme, useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import { DashboardAbsences } from "./absences";
import { DashboardDossiers } from "./dossiers";
import { DashboardPratiquePro } from "./pratiquepro";
import { DashboardSuivis } from "./suivis";
import { DashboardDemandesFormation } from "./demandesFormation";

export interface IDashboardPageProps {}

export const DashboardPage: React.FunctionComponent<IDashboardPageProps> = props => {
  const { t } = useTl();
  const { tab } = useParams<{ tab: string }>();
  const history = useHistory();
  const { user } = useAuth();
  const { ifapmeSide } = useTheme();
  const isHope = React.useMemo(() => ifapmeSide === "hope", [ifapmeSide]);

  const onTabChange = React.useCallback(
    newTabId => {
      history.push(`${ERoutes.dashboard}/${newTabId}`);
    },
    [history]
  );

  const hopeTabs = React.useMemo<ITabProps[]>(
    () => [
      {
        id: "inscriptions",
        title: <DashboardTabTitle title={t(ETLCodes.Inscriptions)} dashboard={EDashboard.Inscriptions} />,
        panel: <DashboardInscriptions />
      },
      {
        id: "absences",
        title: <DashboardTabTitle title={t(ETLCodes.Absences)} dashboard={EDashboard.Absence} />,
        panel: <DashboardAbsences />
      },
      {
        id: "suivis",
        title: <DashboardTabTitle title={t(ETLCodes.Suivis)} dashboard={EDashboard.Suivis} />,
        panel: <DashboardSuivis />
      },
      {
        id: "suivis_siege_lieu",
        title: <DashboardTabTitle title={t(ETLCodes.SuivisSiegesLieux)} dashboard={EDashboard.SuivisSL} />,
        panel: <DashboardSuivisSiegeLieu />
      },
      {
        id: "dossiers",
        title: <DashboardDossiersTabTitle />,
        panel: <DashboardDossiers />
      }
    ],
    [t]
  );

  const walterTabs = React.useMemo<ITabProps[]>(
    () => [
      {
        id: "inscriptions",
        title: <DashboardTabTitle title={t(ETLCodes.Inscriptions)} dashboard={EDashboard.Inscriptions} />,
        panel: <DashboardInscriptions />
      },
      {
        id: "suivis",
        title: <DashboardTabTitle title={t(ETLCodes.Suivis)} dashboard={EDashboard.Suivis} />,
        panel: <DashboardSuivis />
      },
      {
        id: "dossiers",
        title: <DashboardDossiersTabTitle />,
        panel: <DashboardDossiers />
      },
      {
        id: "pratiquepro",
        title: <DashboardTabTitle title={t(ETLCodes.PratiqueProfessionnelle)} dashboard={EDashboard.PratiquePro} />,
        panel: <DashboardPratiquePro />
      }
    ],
    [t]
  );

  const walterCreaTabs = React.useMemo(
    () => [
      {
        id: "demandesCrea",
        title: (
          <DashboardTabTitle title={t(ETLCodes.DemandeFormationCrea)} dashboard={EDashboard.DemandesFormationCrea} />
        ),
        panel: <DashboardDemandesFormation />
      },
      {
        id: "suivis",
        title: <DashboardTabTitle title={t(ETLCodes.Suivis)} dashboard={EDashboard.Suivis} />,
        panel: <DashboardSuivis />
      },
      {
        id: "absences",
        title: <DashboardTabTitle title={t(ETLCodes.Absences)} dashboard={EDashboard.Absence} />,
        panel: <DashboardAbsences />
      }
    ],
    [t]
  );

  const isCrea = React.useMemo(() => user.hopeMainLevelName === "Conseiller CREA", [user.hopeMainLevelName]);

  return (
    <PageBase breadCrumbs={[{ text: t(ETLCodes.MonTableauDeBord) }]}>
      <ProtectedTabs
        id="TabsDashboard"
        onChange={onTabChange}
        selectedTabId={tab}
        renderActiveTabPanelOnly
        animate={false}
      >
        {(isHope ? hopeTabs : isCrea ? walterCreaTabs : walterTabs).map(t => (
          <Tab {...t} />
        ))}
      </ProtectedTabs>
    </PageBase>
  );
};
