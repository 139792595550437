/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RapportHopeServicePermissionDto
 */
export interface RapportHopeServicePermissionDto {
    /**
     * 
     * @type {boolean}
     * @memberof RapportHopeServicePermissionDto
     */
    selected?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RapportHopeServicePermissionDto
     */
    idrapportHope?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RapportHopeServicePermissionDto
     */
    idservice?: number;
    /**
     * 
     * @type {string}
     * @memberof RapportHopeServicePermissionDto
     */
    nom?: string | null;
}

/**
 * Check if a given object implements the RapportHopeServicePermissionDto interface.
 */
export function instanceOfRapportHopeServicePermissionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RapportHopeServicePermissionDtoFromJSON(json: any): RapportHopeServicePermissionDto {
    return RapportHopeServicePermissionDtoFromJSONTyped(json, false);
}

export function RapportHopeServicePermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RapportHopeServicePermissionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selected': !exists(json, 'selected') ? undefined : json['selected'],
        'idrapportHope': !exists(json, 'idrapportHope') ? undefined : json['idrapportHope'],
        'idservice': !exists(json, 'idservice') ? undefined : json['idservice'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
    };
}

export function RapportHopeServicePermissionDtoToJSON(value?: RapportHopeServicePermissionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selected': value.selected,
        'idrapportHope': value.idrapportHope,
        'idservice': value.idservice,
        'nom': value.nom,
    };
}

