import { ButtonContainer, IDataTableColumn } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";

import {
  FcbRechercheClasseHoraireRemplacementSimultaneDto,
  RechercheClasseHoraireRemplacementSimultaneSearch
} from "../../../../../api";
import { RemplacementSimultaneApi } from "../../../../../api/apis/RemplacementSimultaneApi";
import { EditButton, SearchTablePage } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { IRemplacement } from "./RemplacementInterfaces";
import { RemplacementSimultaneDetailPage } from "./RemplacementSimultaneDetailPage";
import { useEventsContext } from "../../../../../contexts";

export interface IRemplacementSimultaneTablePageProps {}

export const RemplacementSimultaneTablePage: React.FunctionComponent<IRemplacementSimultaneTablePageProps> = () => {
  const { id } = useParams<{ id: string; tab: string; state: string }>();
  const { t } = useTl();
  const api = useApiService(RemplacementSimultaneApi);

  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [modalData, setModalData] = React.useState<IRemplacement>({});

  const editClick = React.useCallback((row: FcbRechercheClasseHoraireRemplacementSimultaneDto) => {
    setModalData({
      idremplacant: row.idRemplacant,
      idformateurLocalisationContrat: row.idFormateurLocalisationContrat,
      idremplacement: row.idRemplacement,
      idclasseMatiere: row.idClasseMatiere,
      idcentre: row.idcentre
    });
    setModalVisible(true);
  }, []);

  const columns = React.useMemo<Array<IDataTableColumn>>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbRechercheClasseHoraireRemplacementSimultaneDto) => (
          <ButtonContainer>
            <EditButton minimal={true} onClick={() => editClick(row)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Classe),
        fieldName: "classe"
      },
      {
        header: () => t(ETLCodes.Matiere),
        fieldName: "description"
      },
      {
        header: () => t(ETLCodes.Remplacant),
        fieldName: "remplacant"
      },
      {
        header: () => t(ETLCodes.Contrat),
        fieldName: "contrat"
      }
    ],
    [editClick, t]
  );

  const apiFactory = useAbortableApiServiceFactory(RemplacementSimultaneApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFnCB = React.useCallback(
    (sObj?: RechercheClasseHoraireRemplacementSimultaneSearch) => {
      sObj.idRemplacement = +id;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.remplacementSimultaneBaseSearch({ RechercheClasseHoraireRemplacementSimultaneSearch: sObj });
    },
    [apiFactory, id]
  );

  const criteriaFnCB = React.useCallback(
    () => api.remplacementSimultaneGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  const { dispatchEvent } = useEventsContext();

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <SearchTablePage
        getCriteriasFunction={criteriaFnCB}
        searchFunction={searchFnCB}
        onAbort={onAbort}
        sortKeys={{ classe: "ASC" }}
        columns={columns}
        withCard={false}
      />
      {modalVisible ? (
        <RemplacementSimultaneDetailPage
          isOpen={modalVisible}
          onSave={() => {
            setModalData({});
            setModalVisible(false);
            dispatchEvent("SEARCH_TABLE_REFRESH");
          }}
          onCancel={() => {
            setModalData({});
            setModalVisible(false);
          }}
          data={modalData}
        />
      ) : null}
    </>
  );
};
