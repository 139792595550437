import { DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";

import { ExportGapPrestationApi, RechercheExportGapSearch } from "../../../../api";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface IExportationGapPrestationListPageProps {
  idExport: number;
}

export const ExportationGapPrestationListPage: React.FunctionComponent<IExportationGapPrestationListPageProps> = ({
  idExport
}) => {
  const { t } = useTl();
  const api = useApiService(ExportGapPrestationApi);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { jour: "DESC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: RechercheExportGapSearch) => {
      sObj.idexport = idExport;
      return api.exportGapPrestationBaseSearch({ RechercheExportGapSearch: sObj });
    },
    [api, idExport]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.Date),
        fieldName: "jour"
      },
      {
        header: () => t(ETLCodes.CodeClasse),
        fieldName: "codeClasse"
      },
      {
        header: () => t(ETLCodes.Classe),
        fieldName: "classe"
      },
      {
        header: () => t(ETLCodes.Formateur),
        fieldName: "formateur"
      },
      {
        header: () => t(ETLCodes.Matricule),
        fieldName: "matricule"
      },
      {
        header: () => t(ETLCodes.NbHeure),
        fieldName: "count"
      }
    ],
    [t]
  );

  return (
    <>
      {idExport > 0 && (
        <FieldSet title={t(ETLCodes.TableResults, { count: totalCount })}>
          <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
        </FieldSet>
      )}
    </>
  );
};
