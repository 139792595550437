/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FcbResultatTypeEvaluationGridDtoPaginatedResults } from './FcbResultatTypeEvaluationGridDtoPaginatedResults';
import {
    FcbResultatTypeEvaluationGridDtoPaginatedResultsFromJSON,
    FcbResultatTypeEvaluationGridDtoPaginatedResultsFromJSONTyped,
    FcbResultatTypeEvaluationGridDtoPaginatedResultsToJSON,
} from './FcbResultatTypeEvaluationGridDtoPaginatedResults';

/**
 * 
 * @export
 * @interface FcbResultatTypeGridDto
 */
export interface FcbResultatTypeGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeGridDto
     */
    matiere?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeGridDto
     */
    codematiere?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeGridDto
     */
    idmatiere?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeGridDto
     */
    formateur?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeGridDto
     */
    tj?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeGridDto
     */
    exa?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeGridDto
     */
    total?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeGridDto
     */
    idclasse?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeGridDto
     */
    categorie?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeGridDto
     */
    idbulletininscriptionmatiere?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeGridDto
     */
    idDeliberationType?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeGridDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeGridDto
     */
    idclassematiere?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeGridDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeGridDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeGridDto
     */
    idinscription?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeGridDto
     */
    bulletintotal?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeGridDto
     */
    idreferentiel?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FcbResultatTypeGridDto
     */
    remediationconseil?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbResultatTypeGridDto
     */
    remediationobligatoire?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof FcbResultatTypeGridDto
     */
    date2sess?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeGridDto
     */
    changeclasse?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeGridDto
     */
    totalmax?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeGridDto
     */
    totaldenommax?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeGridDto
     */
    iddeliberationType?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeGridDto
     */
    iddeliberationType2sess?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeGridDto
     */
    reussite?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbResultatTypeGridDto
     */
    dispenseTotale?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbResultatTypeGridDto
     */
    readonly emptyEval?: boolean;
    /**
     * 
     * @type {FcbResultatTypeEvaluationGridDtoPaginatedResults}
     * @memberof FcbResultatTypeGridDto
     */
    evaluations?: FcbResultatTypeEvaluationGridDtoPaginatedResults;
}

/**
 * Check if a given object implements the FcbResultatTypeGridDto interface.
 */
export function instanceOfFcbResultatTypeGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbResultatTypeGridDtoFromJSON(json: any): FcbResultatTypeGridDto {
    return FcbResultatTypeGridDtoFromJSONTyped(json, false);
}

export function FcbResultatTypeGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbResultatTypeGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'matiere': !exists(json, 'matiere') ? undefined : json['matiere'],
        'codematiere': !exists(json, 'codematiere') ? undefined : json['codematiere'],
        'idmatiere': !exists(json, 'idmatiere') ? undefined : json['idmatiere'],
        'formateur': !exists(json, 'formateur') ? undefined : json['formateur'],
        'tj': !exists(json, 'tj') ? undefined : json['tj'],
        'exa': !exists(json, 'exa') ? undefined : json['exa'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'categorie': !exists(json, 'categorie') ? undefined : json['categorie'],
        'idbulletininscriptionmatiere': !exists(json, 'idbulletininscriptionmatiere') ? undefined : json['idbulletininscriptionmatiere'],
        'idDeliberationType': !exists(json, 'idDeliberationType') ? undefined : json['idDeliberationType'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'idclassematiere': !exists(json, 'idclassematiere') ? undefined : json['idclassematiere'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'idinscription': !exists(json, 'idinscription') ? undefined : json['idinscription'],
        'bulletintotal': !exists(json, 'bulletintotal') ? undefined : json['bulletintotal'],
        'idreferentiel': !exists(json, 'idreferentiel') ? undefined : json['idreferentiel'],
        'remediationconseil': !exists(json, 'remediationconseil') ? undefined : json['remediationconseil'],
        'remediationobligatoire': !exists(json, 'remediationobligatoire') ? undefined : json['remediationobligatoire'],
        'date2sess': !exists(json, 'date2sess') ? undefined : (json['date2sess'] === null ? null : new Date(json['date2sess'])),
        'changeclasse': !exists(json, 'changeclasse') ? undefined : json['changeclasse'],
        'totalmax': !exists(json, 'totalmax') ? undefined : json['totalmax'],
        'totaldenommax': !exists(json, 'totaldenommax') ? undefined : json['totaldenommax'],
        'iddeliberationType': !exists(json, 'iddeliberationType') ? undefined : json['iddeliberationType'],
        'iddeliberationType2sess': !exists(json, 'iddeliberationType2sess') ? undefined : json['iddeliberationType2sess'],
        'reussite': !exists(json, 'reussite') ? undefined : json['reussite'],
        'dispenseTotale': !exists(json, 'dispenseTotale') ? undefined : json['dispenseTotale'],
        'emptyEval': !exists(json, 'emptyEval') ? undefined : json['emptyEval'],
        'evaluations': !exists(json, 'evaluations') ? undefined : FcbResultatTypeEvaluationGridDtoPaginatedResultsFromJSON(json['evaluations']),
    };
}

export function FcbResultatTypeGridDtoToJSON(value?: FcbResultatTypeGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'matiere': value.matiere,
        'codematiere': value.codematiere,
        'idmatiere': value.idmatiere,
        'formateur': value.formateur,
        'tj': value.tj,
        'exa': value.exa,
        'total': value.total,
        'idclasse': value.idclasse,
        'categorie': value.categorie,
        'idbulletininscriptionmatiere': value.idbulletininscriptionmatiere,
        'idDeliberationType': value.idDeliberationType,
        'libelle': value.libelle,
        'idclassematiere': value.idclassematiere,
        'idapprenant': value.idapprenant,
        'description': value.description,
        'idinscription': value.idinscription,
        'bulletintotal': value.bulletintotal,
        'idreferentiel': value.idreferentiel,
        'remediationconseil': value.remediationconseil,
        'remediationobligatoire': value.remediationobligatoire,
        'date2sess': value.date2sess === undefined ? undefined : (value.date2sess === null ? null : value.date2sess.toISOString()),
        'changeclasse': value.changeclasse,
        'totalmax': value.totalmax,
        'totaldenommax': value.totaldenommax,
        'iddeliberationType': value.iddeliberationType,
        'iddeliberationType2sess': value.iddeliberationType2sess,
        'reussite': value.reussite,
        'dispenseTotale': value.dispenseTotale,
        'evaluations': FcbResultatTypeEvaluationGridDtoPaginatedResultsToJSON(value.evaluations),
    };
}

