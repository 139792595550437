/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { DateSearch } from './DateSearch';
import {
    DateSearchFromJSON,
    DateSearchFromJSONTyped,
    DateSearchToJSON,
} from './DateSearch';
import type { TextSearch } from './TextSearch';
import {
    TextSearchFromJSON,
    TextSearchFromJSONTyped,
    TextSearchToJSON,
} from './TextSearch';

/**
 * 
 * @export
 * @interface RechercheDateAbsenceApprenantSearch
 */
export interface RechercheDateAbsenceApprenantSearch {
    /**
     * 
     * @type {number}
     * @memberof RechercheDateAbsenceApprenantSearch
     */
    idApprenant?: number;
    /**
     * 
     * @type {TextSearch}
     * @memberof RechercheDateAbsenceApprenantSearch
     */
    anneeScolaire?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof RechercheDateAbsenceApprenantSearch
     */
    classe?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof RechercheDateAbsenceApprenantSearch
     */
    codeclasse?: TextSearch;
    /**
     * 
     * @type {DateSearch}
     * @memberof RechercheDateAbsenceApprenantSearch
     */
    orderjour?: DateSearch;
    /**
     * 
     * @type {Array<string>}
     * @memberof RechercheDateAbsenceApprenantSearch
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof RechercheDateAbsenceApprenantSearch
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof RechercheDateAbsenceApprenantSearch
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RechercheDateAbsenceApprenantSearch
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof RechercheDateAbsenceApprenantSearch
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof RechercheDateAbsenceApprenantSearch
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the RechercheDateAbsenceApprenantSearch interface.
 */
export function instanceOfRechercheDateAbsenceApprenantSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RechercheDateAbsenceApprenantSearchFromJSON(json: any): RechercheDateAbsenceApprenantSearch {
    return RechercheDateAbsenceApprenantSearchFromJSONTyped(json, false);
}

export function RechercheDateAbsenceApprenantSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): RechercheDateAbsenceApprenantSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idApprenant': !exists(json, 'idApprenant') ? undefined : json['idApprenant'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : TextSearchFromJSON(json['anneeScolaire']),
        'classe': !exists(json, 'classe') ? undefined : TextSearchFromJSON(json['classe']),
        'codeclasse': !exists(json, 'codeclasse') ? undefined : TextSearchFromJSON(json['codeclasse']),
        'orderjour': !exists(json, 'orderjour') ? undefined : DateSearchFromJSON(json['orderjour']),
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function RechercheDateAbsenceApprenantSearchToJSON(value?: RechercheDateAbsenceApprenantSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idApprenant': value.idApprenant,
        'anneeScolaire': TextSearchToJSON(value.anneeScolaire),
        'classe': TextSearchToJSON(value.classe),
        'codeclasse': TextSearchToJSON(value.codeclasse),
        'orderjour': DateSearchToJSON(value.orderjour),
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

