import { Tab, Tabs } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";

import { CentreGlobalDetailPage } from "..";
import { EntiteCentreApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

interface ICentreGlobalItemPageProps {}

export const CentreGlobalItemPage: React.FunctionComponent<ICentreGlobalItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const idCentreGlobal = React.useMemo(() => +id, [id]);
  const history = useHistory();

  const centreGlobalApi = useApiService(EntiteCentreApi);

  const [displayName] = useApiEffect(() => centreGlobalApi.entiteCentreGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.CentreGlobal), route: ERoutes.centreGlobal },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <Tabs
        id="TabsCentreGlobal"
        onChange={newTabId => history.push(`${ERoutes.centreGlobal}/${id}/${newTabId}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.centreGlobal}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<CentreGlobalDetailPage idCentreGlobal={idCentreGlobal} editMode={state === "edit"} />}
        />
      </Tabs>
    </PageBase>
  );
};
