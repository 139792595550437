import { Classes, Colors, Menu, MenuItem } from "@blueprintjs/core";
import { Loading } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import styled from "styled-components";

import { ExportSearchFromJSON, ExportUtilitaireApi, FcbExportGridDto } from "../api";
import { ERoutes } from "../AppRouter";
import { useGlobalData } from "../contexts";
import { useApiService, useTheme, useTl } from "../hooks";
import { ETLCodes } from "../locales";

const StyledContainer = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 250px;
  height: auto;
  background-color: ${props => props.backgroundColor};
  & .${Classes.MENU} {
    padding: 0 !important;
  }
  width: initial !important;
  color: ${Colors.WHITE};
  overflow: auto;
  max-height: 92.3vh;
`;

const TitleContainer = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${props => props.backgroundColor};
  align-items: center;
`;

export interface IExportMenuProps {
  toggleExportMenu?: (open: boolean) => void;
}

export const ExportMenu: React.FunctionComponent<IExportMenuProps> = ({ toggleExportMenu }) => {
  const { t } = useTl();
  const exportApi = useApiService(ExportUtilitaireApi);
  const { currentModuleId } = useGlobalData();
  const { theme } = useTheme();

  const location = useLocation();
  const { pathname } = location;
  const [lastRoute, setLastRoute] = React.useState<string>();

  React.useEffect(() => {
    const splitted = pathname.split("/");
    if (!(splitted?.length > 1)) return;

    const currentRoute = splitted[1];
    if (currentRoute !== lastRoute && lastRoute) {
      toggleExportMenu(false);
    }
    setLastRoute(currentRoute);
  }, [lastRoute, pathname, toggleExportMenu]);

  const { data, isFetching: loading } = useQuery(
    ["exports-walter", currentModuleId],
    React.useCallback(() => {
      if (!currentModuleId) return;
      return exportApi.exportUtilitaireBaseSearch({
        ExportSearch: ExportSearchFromJSON({
          idModule: currentModuleId
        })
      });
    }, [currentModuleId, exportApi])
  );

  const onMenuClick = React.useCallback((dto: FcbExportGridDto) => {
    window.open(`/#${ERoutes.export}/${dto.idexport}`, "_blank");
  }, []);

  return (
    <StyledContainer className={Classes.DARK} backgroundColor={theme.menuBackgroundColor}>
      <Loading isLoading={loading}>
        <>
          <TitleContainer backgroundColor={theme.menuBackgroundColor}>
            <div
              className={Classes.DRAWER_HEADER}
              style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}
            >
              <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div>{t(ETLCodes.ExportExcel)}</div>
              </div>
            </div>
          </TitleContainer>
        </>
        <Menu>
          {data?.results.map(d => (
            <MenuItem key={d.idexport} text={d.nom} onClick={() => onMenuClick(d)}></MenuItem>
          ))}
        </Menu>
      </Loading>
    </StyledContainer>
  );
};
