/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FcbApprenantPresenceDetailSaveDto } from './FcbApprenantPresenceDetailSaveDto';
import {
    FcbApprenantPresenceDetailSaveDtoFromJSON,
    FcbApprenantPresenceDetailSaveDtoFromJSONTyped,
    FcbApprenantPresenceDetailSaveDtoToJSON,
} from './FcbApprenantPresenceDetailSaveDto';
import type { FcbPresenceFormateurDto } from './FcbPresenceFormateurDto';
import {
    FcbPresenceFormateurDtoFromJSON,
    FcbPresenceFormateurDtoFromJSONTyped,
    FcbPresenceFormateurDtoToJSON,
} from './FcbPresenceFormateurDto';

/**
 * 
 * @export
 * @interface FeuillePresenceSaveDto
 */
export interface FeuillePresenceSaveDto {
    /**
     * 
     * @type {Array<FcbApprenantPresenceDetailSaveDto>}
     * @memberof FeuillePresenceSaveDto
     */
    apprenantPresences?: Array<FcbApprenantPresenceDetailSaveDto> | null;
    /**
     * 
     * @type {Array<FcbPresenceFormateurDto>}
     * @memberof FeuillePresenceSaveDto
     */
    formateurPresences?: Array<FcbPresenceFormateurDto> | null;
}

/**
 * Check if a given object implements the FeuillePresenceSaveDto interface.
 */
export function instanceOfFeuillePresenceSaveDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FeuillePresenceSaveDtoFromJSON(json: any): FeuillePresenceSaveDto {
    return FeuillePresenceSaveDtoFromJSONTyped(json, false);
}

export function FeuillePresenceSaveDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeuillePresenceSaveDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apprenantPresences': !exists(json, 'apprenantPresences') ? undefined : (json['apprenantPresences'] === null ? null : (json['apprenantPresences'] as Array<any>).map(FcbApprenantPresenceDetailSaveDtoFromJSON)),
        'formateurPresences': !exists(json, 'formateurPresences') ? undefined : (json['formateurPresences'] === null ? null : (json['formateurPresences'] as Array<any>).map(FcbPresenceFormateurDtoFromJSON)),
    };
}

export function FeuillePresenceSaveDtoToJSON(value?: FeuillePresenceSaveDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apprenantPresences': value.apprenantPresences === undefined ? undefined : (value.apprenantPresences === null ? null : (value.apprenantPresences as Array<any>).map(FcbApprenantPresenceDetailSaveDtoToJSON)),
        'formateurPresences': value.formateurPresences === undefined ? undefined : (value.formateurPresences === null ? null : (value.formateurPresences as Array<any>).map(FcbPresenceFormateurDtoToJSON)),
    };
}

