import { FGCustomPanel, FGListen, FGMultiSuggestInput, FGTextAreaInput, useFGContext } from "nsitools-react";
import * as React from "react";

import { FileDownloadDto } from "../../../../../../api";
import { FGWalterDateMaskInput, FGWalterFileInput, FGWalterSelectInput } from "../../../../../../components";
import { useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

export interface ILieuFormationAgrementDecisionProps {
  downloadFn?: () => Promise<FileDownloadDto>;
  multiple?: boolean;
  decisionRO?: boolean;
}

export const LieuFormationAgrementDecision: React.FunctionComponent<ILieuFormationAgrementDecisionProps> = ({
  downloadFn,
  multiple,
  decisionRO
}) => {
  const { t } = useTl();
  const { formik } = useFGContext();
  const [decisions, dLoading, , rawDecisions] = useReferential(
    a => a.referentialGetLieuFormationAgrementDecisions(),
    true
  );
  const [motifs, moLoading] = useReferential(a => a.referentialGetMotifsNonAgrement(), true);

  return (
    <>
      <FGListen
        field="fichierEnvoiD3"
        onChanged={(value, formik) => {
          if (formik.dirty) {
            formik.setFieldValue("idfichierEnvoiD3", null);
          }
        }}
      />
      <FGWalterSelectInput
        name="idlieuFormationAgrementDecision"
        label={t(ETLCodes.Decision)}
        items={decisions}
        loading={dLoading}
        readonly={decisionRO}
      />
      <FGCustomPanel>
        {ctx => (
          <>
            <FGWalterDateMaskInput
              name="dateDecision"
              label={t(ETLCodes.DateDecision)}
              requiredMark={!!ctx.formik?.values?.idlieuFormationAgrementDecision}
            />
            <FGMultiSuggestInput
              name="motifsNonAgrement"
              label={t(ETLCodes.MotifsNonAgrement)}
              items={motifs}
              loading={moLoading}
              disabled={
                !ctx.formik.values?.idlieuFormationAgrementDecision ||
                +(rawDecisions?.find(d => d.keyValue === "NON_AGREE")?.idValue ?? "0") !==
                  ctx.formik.values?.idlieuFormationAgrementDecision
              }
            />
          </>
        )}
      </FGCustomPanel>
      <FGTextAreaInput name="remarqueDecision" label={t(ETLCodes.Remarque)} />
      <FGWalterDateMaskInput name="dateEnvoiD3" label={t(ETLCodes.DateEnvoiD3)} />
      <FGWalterFileInput
        name="fichierEnvoiD3"
        label={t(ETLCodes.Document)}
        downloadFn={formik.values?.idfichierEnvoiD3 && downloadFn}
        visible={!multiple}
      />
    </>
  );
};
