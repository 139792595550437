/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModuleCreaGridDto
 */
export interface ModuleCreaGridDto {
    /**
     * 
     * @type {number}
     * @memberof ModuleCreaGridDto
     */
    idmoduleCrea?: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleCreaGridDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModuleCreaGridDto
     */
    code?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ModuleCreaGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the ModuleCreaGridDto interface.
 */
export function instanceOfModuleCreaGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModuleCreaGridDtoFromJSON(json: any): ModuleCreaGridDto {
    return ModuleCreaGridDtoFromJSONTyped(json, false);
}

export function ModuleCreaGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModuleCreaGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idmoduleCrea': !exists(json, 'idmoduleCrea') ? undefined : json['idmoduleCrea'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function ModuleCreaGridDtoToJSON(value?: ModuleCreaGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idmoduleCrea': value.idmoduleCrea,
        'libelle': value.libelle,
        'code': value.code,
        'actif': value.actif,
    };
}

