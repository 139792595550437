import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";

import { DirectionTerritorialeApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { DirectionTerritorialeDetailPage } from "./DirectionTerritorialeDetailPage";

interface IDirectionTerritorialeItemPageProps {}

export const DirectionTerritorialeItemPage: React.FunctionComponent<IDirectionTerritorialeItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const idDirectionTerritoriale = React.useMemo(() => +id, [id]);
  const history = useHistory();

  const directionTerritorialeApi = useApiService(DirectionTerritorialeApi);
  const [displayName] = useApiEffect(() => directionTerritorialeApi.directionTerritorialeGetDisplayName({ id: +id }), [
    id
  ]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.DirectionsTerritoriales), route: ERoutes.directionTerritoriale },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsDirectionTerritoriale"
        onChange={newTabId => history.push(`${ERoutes.directionTerritoriale}/${id}/${newTabId}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.directionTerritoriale}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<DirectionTerritorialeDetailPage idDirection={idDirectionTerritoriale} editMode={state === "edit"} />}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
