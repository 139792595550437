import * as React from "react";
import { useHistory } from "react-router";

import { MetierApi, FcbMetierGridDto, MetierSearch, EBooleanSearchTypes } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, ViewButton, SearchTablePage } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import styled from "styled-components";
import { Checkbox } from "@blueprintjs/core";
import { IDataTableColumn } from "nsitools-react";

interface IMetierListPageProps {}

const Container = styled.div`
  display: flex;
`;
export const MetierListPage: React.FunctionComponent<IMetierListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(MetierApi);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbMetierGridDto) => (
          <Container>
            <ViewButton minimal={true} onClick={() => history.push(`${ERoutes.metier}/${row.idmetier}/detail/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${ERoutes.metier}/${row.idmetier}/detail/edit`)} />
          </Container>
        )
      },
      {
        header: () => t(ETLCodes.MetierListPageStade),
        fieldName: "stade"
      },
      {
        header: () => t(ETLCodes.MetierListPageIndice),
        fieldName: "code",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.MetierListPageMetier),
        fieldName: "masculin"
      },
      {
        header: () => t(ETLCodes.MetierListPageIntegre),
        fieldName: "integre",
        alignment: "center",
        render: (row: FcbMetierGridDto) => <Checkbox checked={row.integre} disabled />
      }
    ],
    [history, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.metier}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(() => api.metierGetSearchCriterias({ includeListsValues: true }), [api]);

  const apiFactory = useAbortableApiServiceFactory(MetierApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: MetierSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.metierBaseSearch({ MetierSearch: sObj });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.Metiers), route: ERoutes.metier }]}
      addFunc={onAddItem}
      sortKeys={{ stade: "ASC" }}
      criteriasTlPrefix="MetierSearchCriterias"
      overrideListValues={{ Idstade: i => i.displayValue }}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
    ></SearchTablePage>
  );
};
