import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import { CentreHoraireList, CentreHoraireDetails } from "..";

interface ICentreHoraireItemProps {}

export const CentreHoraireItem: React.FunctionComponent<ICentreHoraireItemProps> = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={CentreHoraireList} exact />
      <Route path={`${match.path}/:plageId/:state`} component={CentreHoraireDetails} />
    </Switch>
  );
};
