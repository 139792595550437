/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DirectionTerritorialeSearch,
  FcbDirectionTerritorialeDto,
  FcbDirectionTerritorialeGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    DirectionTerritorialeSearchFromJSON,
    DirectionTerritorialeSearchToJSON,
    FcbDirectionTerritorialeDtoFromJSON,
    FcbDirectionTerritorialeDtoToJSON,
    FcbDirectionTerritorialeGridDtoPaginatedResultsFromJSON,
    FcbDirectionTerritorialeGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface DirectionTerritorialeBaseSearchRequest {
    DirectionTerritorialeSearch?: DirectionTerritorialeSearch;
}

export interface DirectionTerritorialeDeleteRequest {
    id?: number;
}

export interface DirectionTerritorialeDirectionTerritorialeCodeExistsRequest {
    id?: number;
    code?: string;
}

export interface DirectionTerritorialeGetRequest {
    id?: number;
}

export interface DirectionTerritorialeGetDisplayNameRequest {
    id?: number;
}

export interface DirectionTerritorialeGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface DirectionTerritorialeGetSelectItemsRequest {
    searchField: string;
}

export interface DirectionTerritorialeSaveRequest {
    FcbDirectionTerritorialeDto?: FcbDirectionTerritorialeDto;
}

/**
 * 
 */
export class DirectionTerritorialeApi extends runtime.BaseAPI {

    /**
     */
    async directionTerritorialeBaseSearchRaw(requestParameters: DirectionTerritorialeBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbDirectionTerritorialeGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DirectionTerritoriale/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DirectionTerritorialeSearchToJSON(requestParameters.DirectionTerritorialeSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbDirectionTerritorialeGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async directionTerritorialeBaseSearch(requestParameters: DirectionTerritorialeBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbDirectionTerritorialeGridDtoPaginatedResults> {
        const response = await this.directionTerritorialeBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async directionTerritorialeDeleteRaw(requestParameters: DirectionTerritorialeDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DirectionTerritoriale`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async directionTerritorialeDelete(requestParameters: DirectionTerritorialeDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.directionTerritorialeDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async directionTerritorialeDirectionTerritorialeCodeExistsRaw(requestParameters: DirectionTerritorialeDirectionTerritorialeCodeExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DirectionTerritoriale/nomExists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async directionTerritorialeDirectionTerritorialeCodeExists(requestParameters: DirectionTerritorialeDirectionTerritorialeCodeExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.directionTerritorialeDirectionTerritorialeCodeExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async directionTerritorialeGetRaw(requestParameters: DirectionTerritorialeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbDirectionTerritorialeDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DirectionTerritoriale`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbDirectionTerritorialeDtoFromJSON(jsonValue));
    }

    /**
     */
    async directionTerritorialeGet(requestParameters: DirectionTerritorialeGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbDirectionTerritorialeDto> {
        const response = await this.directionTerritorialeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async directionTerritorialeGetDisplayNameRaw(requestParameters: DirectionTerritorialeGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DirectionTerritoriale/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async directionTerritorialeGetDisplayName(requestParameters: DirectionTerritorialeGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.directionTerritorialeGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async directionTerritorialeGetSearchCriteriasRaw(requestParameters: DirectionTerritorialeGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DirectionTerritoriale/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async directionTerritorialeGetSearchCriterias(requestParameters: DirectionTerritorialeGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.directionTerritorialeGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async directionTerritorialeGetSelectItemsRaw(requestParameters: DirectionTerritorialeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling directionTerritorialeGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DirectionTerritoriale/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async directionTerritorialeGetSelectItems(requestParameters: DirectionTerritorialeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.directionTerritorialeGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async directionTerritorialeSaveRaw(requestParameters: DirectionTerritorialeSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbDirectionTerritorialeDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DirectionTerritoriale`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbDirectionTerritorialeDtoToJSON(requestParameters.FcbDirectionTerritorialeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbDirectionTerritorialeDtoFromJSON(jsonValue));
    }

    /**
     */
    async directionTerritorialeSave(requestParameters: DirectionTerritorialeSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbDirectionTerritorialeDto> {
        const response = await this.directionTerritorialeSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
