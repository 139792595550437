/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ERapportHopeParameterType {
    Boolean = 'Boolean',
    DateTime = 'DateTime',
    Integer = 'Integer',
    Float = 'Float',
    String = 'String'
}


export function ERapportHopeParameterTypeFromJSON(json: any): ERapportHopeParameterType {
    return ERapportHopeParameterTypeFromJSONTyped(json, false);
}

export function ERapportHopeParameterTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ERapportHopeParameterType {
    return json as ERapportHopeParameterType;
}

export function ERapportHopeParameterTypeToJSON(value?: ERapportHopeParameterType | null): any {
    return value as any;
}

