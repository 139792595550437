/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApprenantAzureSearch,
  FcbApprenantAzureGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    ApprenantAzureSearchFromJSON,
    ApprenantAzureSearchToJSON,
    FcbApprenantAzureGridDtoPaginatedResultsFromJSON,
    FcbApprenantAzureGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface ApprenantAzureBaseSearchRequest {
    ApprenantAzureSearch?: ApprenantAzureSearch;
}

export interface ApprenantAzureGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ApprenantAzureGetSelectItemsRequest {
    searchField: string;
}

/**
 * 
 */
export class ApprenantAzureApi extends runtime.BaseAPI {

    /**
     */
    async apprenantAzureBaseSearchRaw(requestParameters: ApprenantAzureBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbApprenantAzureGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantAzure/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApprenantAzureSearchToJSON(requestParameters.ApprenantAzureSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbApprenantAzureGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async apprenantAzureBaseSearch(requestParameters: ApprenantAzureBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbApprenantAzureGridDtoPaginatedResults> {
        const response = await this.apprenantAzureBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantAzureGetSearchCriteriasRaw(requestParameters: ApprenantAzureGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantAzure/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async apprenantAzureGetSearchCriterias(requestParameters: ApprenantAzureGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.apprenantAzureGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantAzureGetSelectItemsRaw(requestParameters: ApprenantAzureGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling apprenantAzureGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantAzure/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async apprenantAzureGetSelectItems(requestParameters: ApprenantAzureGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.apprenantAzureGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
