import * as React from "react";
import { Route, Switch } from "react-router";

import { ERoutes } from "../../../../AppRouter";
import { DeliberationItemPage } from "./DeliberationItemPage";

export interface IDeliberationSwitchProps {}

export const DeliberationSwitch: React.FunctionComponent<IDeliberationSwitchProps> = () => {
  return (
    <Switch>
      <Route
        path={`${ERoutes.deliberationType}/:id/:tab/:idApprenant/:annee/:coursTypeTab?`}
        component={DeliberationItemPage}
      />
    </Switch>
  );
};
