/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  FcbJustificatifAbsenceGridDtoPaginatedResults,
  FcbJustificatifsAbsenceDetailsDto,
  FilterCriteriaInfo,
  JustificatifAbsenceSearch,
  SelectItem,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FcbJustificatifAbsenceGridDtoPaginatedResultsFromJSON,
    FcbJustificatifAbsenceGridDtoPaginatedResultsToJSON,
    FcbJustificatifsAbsenceDetailsDtoFromJSON,
    FcbJustificatifsAbsenceDetailsDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    JustificatifAbsenceSearchFromJSON,
    JustificatifAbsenceSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface JustificatifAbsenceBaseSearchRequest {
    JustificatifAbsenceSearch?: JustificatifAbsenceSearch;
}

export interface JustificatifAbsenceDeleteRequest {
    id?: number;
}

export interface JustificatifAbsenceGetRequest {
    id?: number;
}

export interface JustificatifAbsenceGetLegaliteRequest {
    id?: number;
}

export interface JustificatifAbsenceGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface JustificatifAbsenceGetSelectItemsRequest {
    searchField: string;
}

export interface JustificatifAbsenceSaveRequest {
    FcbJustificatifsAbsenceDetailsDto?: FcbJustificatifsAbsenceDetailsDto;
}

/**
 * 
 */
export class JustificatifAbsenceApi extends runtime.BaseAPI {

    /**
     */
    async justificatifAbsenceBaseSearchRaw(requestParameters: JustificatifAbsenceBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbJustificatifAbsenceGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/JustificatifAbsence/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JustificatifAbsenceSearchToJSON(requestParameters.JustificatifAbsenceSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbJustificatifAbsenceGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async justificatifAbsenceBaseSearch(requestParameters: JustificatifAbsenceBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbJustificatifAbsenceGridDtoPaginatedResults> {
        const response = await this.justificatifAbsenceBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async justificatifAbsenceDeleteRaw(requestParameters: JustificatifAbsenceDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/JustificatifAbsence`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async justificatifAbsenceDelete(requestParameters: JustificatifAbsenceDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.justificatifAbsenceDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async justificatifAbsenceGetRaw(requestParameters: JustificatifAbsenceGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbJustificatifsAbsenceDetailsDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/JustificatifAbsence`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbJustificatifsAbsenceDetailsDtoFromJSON(jsonValue));
    }

    /**
     */
    async justificatifAbsenceGet(requestParameters: JustificatifAbsenceGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbJustificatifsAbsenceDetailsDto> {
        const response = await this.justificatifAbsenceGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async justificatifAbsenceGetLegaliteRaw(requestParameters: JustificatifAbsenceGetLegaliteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/JustificatifAbsence/legalite`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async justificatifAbsenceGetLegalite(requestParameters: JustificatifAbsenceGetLegaliteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.justificatifAbsenceGetLegaliteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async justificatifAbsenceGetSearchCriteriasRaw(requestParameters: JustificatifAbsenceGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/JustificatifAbsence/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async justificatifAbsenceGetSearchCriterias(requestParameters: JustificatifAbsenceGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.justificatifAbsenceGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async justificatifAbsenceGetSelectItemsRaw(requestParameters: JustificatifAbsenceGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling justificatifAbsenceGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/JustificatifAbsence/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async justificatifAbsenceGetSelectItems(requestParameters: JustificatifAbsenceGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.justificatifAbsenceGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async justificatifAbsenceSaveRaw(requestParameters: JustificatifAbsenceSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbJustificatifsAbsenceDetailsDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/JustificatifAbsence`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbJustificatifsAbsenceDetailsDtoToJSON(requestParameters.FcbJustificatifsAbsenceDetailsDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbJustificatifsAbsenceDetailsDtoFromJSON(jsonValue));
    }

    /**
     */
    async justificatifAbsenceSave(requestParameters: JustificatifAbsenceSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbJustificatifsAbsenceDetailsDto> {
        const response = await this.justificatifAbsenceSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
