import { parse } from "date-fns";
import { FieldGroup, HighLightedText, Loading, useFGFormik } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";

import { FeuillePresenceApi } from "../../../../api";
import { FGWalterSelectInput, SmallFormGenerator } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { toCleanTimeZone } from "../../../../utils";
import { FeuillePresenceDetailTable } from "./FeuillePresenceDetailTable";

export interface IFeuillePresenceDetailPageProps {}

type FeuillePresenceJour = { jour: string };

export const FeuillePresenceDetailPage: React.FunctionComponent<IFeuillePresenceDetailPageProps> = () => {
  const { id, date: dateParam } = useParams<{ id: string; tab: string; state: string; date: string }>();
  const api = useApiService(FeuillePresenceApi);
  const { t } = useTl();

  const fetchDatesByIdClasse = React.useCallback(async () => {
    if (!id || +id === 0) return null;

    return await api.feuillePresenceGetDatesForClasse({ idClasse: +id });
  }, [api, id]);

  const { data: datesByIdClasse, isLoading: datesByIdClasseLoading, refetch } = useQuery(
    ["feuille-presence-idClasse", +id],
    fetchDatesByIdClasse,
    { enabled: true }
  );

  const firstDateIncomplete = React.useMemo(() => {
    if (!!dateParam) {
      return dateParam;
    }

    if (!datesByIdClasse || datesByIdClasse?.length === 0) {
      return null;
    }

    const incompleteDates = datesByIdClasse?.filter(d => d.presenceComplete === false);
    if (incompleteDates.length > 0) {
      return incompleteDates[0].jour;
    }

    return datesByIdClasse[datesByIdClasse.length - 1].jour;
  }, [dateParam, datesByIdClasse]);

  const rowColor = React.useCallback(item => {
    const color = item.inTheFuture ? "#CD4246" : item.presenceComplete ? "#32A467" : "#EC9A3C";
    return color;
  }, []);

  const initialValues = React.useMemo(() => {
    return {
      jour: firstDateIncomplete
    } as FeuillePresenceJour;
  }, [firstDateIncomplete]);

  const formik = useFGFormik<FeuillePresenceJour>({
    initialValues,
    onSubmit: () => {}
  });

  const idClasse = React.useMemo(() => +id, [id]);
  const dateClasse = React.useMemo(() => toCleanTimeZone(parse(formik?.values?.jour, "dd-MM-yyyy", new Date())), [
    formik?.values?.jour
  ]);

  return (
    <>
      <Loading isLoading={datesByIdClasseLoading}>
        <SmallFormGenerator
          formik={formik}
          loading={datesByIdClasseLoading}
          editMode={true}
          hideButtons={true}
          enableDirtyCheck={false}
        >
          <FieldGroup columns={2}>
            <FGWalterSelectInput
              label={t(ETLCodes.Date)}
              items={datesByIdClasse}
              name="jour"
              loading={datesByIdClasseLoading}
              valueField={"jour"}
              displayField={"jour"}
              renderItem={(item, query) => (
                <div style={{ color: rowColor(item) }}>
                  <HighLightedText query={query} text={item?.jour} />
                </div>
              )}
            />
          </FieldGroup>
        </SmallFormGenerator>
      </Loading>
      {formik?.values?.jour && (
        <FeuillePresenceDetailTable idClasse={idClasse} dateClasse={dateClasse} refetchDates={refetch} />
      )}
    </>
  );
};
