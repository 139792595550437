import { getIn } from "formik";
import { FGMaskInput, IFGMaskInputProps, useFGContext } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { CopyButton } from "..";

const RightElementContainer = styled.div`
  display: flex;
  align-items: center;
`;

export interface FGCopyTextInputProps extends Omit<IFGMaskInputProps, "cleaveOptions"> {
  onCopy?: (value: string) => string;
  copyOnlyDigits?: boolean;
}

const FGCopyTextInput: React.FC<FGCopyTextInputProps> = ({ onCopy, copyOnlyDigits, rightElement, ...props }) => {
  const { formik } = useFGContext();
  const currentValue = React.useMemo(() => getIn(formik?.values, props.name), [formik?.values, props.name]);

  const onCopyButtonClick = React.useCallback(async () => {
    let nextValue = currentValue;
    if (onCopy) {
      nextValue = onCopy(currentValue);
    } else if (copyOnlyDigits) {
      nextValue = currentValue.replace(/[^\d+]+/g, "");
    }

    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      await navigator.clipboard.writeText(nextValue);
    } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = nextValue;
      // make the textarea out of viewport because it must be visible
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      await new Promise((res, rej) => {
        document.execCommand("copy") ? res(nextValue) : rej();
        textArea.remove();
      });
    }
  }, [currentValue, onCopy, copyOnlyDigits]);

  const finalRightElement = React.useMemo(
    () => (
      <RightElementContainer>
        {rightElement}
        {!!currentValue && <CopyButton onClick={onCopyButtonClick} byPassRights />}
      </RightElementContainer>
    ),
    [currentValue, onCopyButtonClick, rightElement]
  );

  return (
    <FGMaskInput
      cleaveOptions={{
        numericOnly: true
      }}
      {...props}
      rightElement={finalRightElement}
    />
  );
};

export default FGCopyTextInput;
