/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { DateSearch } from './DateSearch';
import {
    DateSearchFromJSON,
    DateSearchFromJSONTyped,
    DateSearchToJSON,
} from './DateSearch';
import type { TextSearch } from './TextSearch';
import {
    TextSearchFromJSON,
    TextSearchFromJSONTyped,
    TextSearchToJSON,
} from './TextSearch';

/**
 * 
 * @export
 * @interface TuteurSelectorSearch
 */
export interface TuteurSelectorSearch {
    /**
     * 
     * @type {TextSearch}
     * @memberof TuteurSelectorSearch
     */
    nom?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof TuteurSelectorSearch
     */
    prenom?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof TuteurSelectorSearch
     */
    registreNational?: TextSearch;
    /**
     * 
     * @type {DateSearch}
     * @memberof TuteurSelectorSearch
     */
    dateNaissance?: DateSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof TuteurSelectorSearch
     */
    telephone?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof TuteurSelectorSearch
     */
    gsm?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof TuteurSelectorSearch
     */
    email?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof TuteurSelectorSearch
     */
    localite?: TextSearch;
    /**
     * 
     * @type {Array<number>}
     * @memberof TuteurSelectorSearch
     */
    selectedTuteurIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof TuteurSelectorSearch
     */
    excludedTuteurIds?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof TuteurSelectorSearch
     */
    idlieuFormation?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TuteurSelectorSearch
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof TuteurSelectorSearch
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof TuteurSelectorSearch
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TuteurSelectorSearch
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof TuteurSelectorSearch
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof TuteurSelectorSearch
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the TuteurSelectorSearch interface.
 */
export function instanceOfTuteurSelectorSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TuteurSelectorSearchFromJSON(json: any): TuteurSelectorSearch {
    return TuteurSelectorSearchFromJSONTyped(json, false);
}

export function TuteurSelectorSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): TuteurSelectorSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nom': !exists(json, 'nom') ? undefined : TextSearchFromJSON(json['nom']),
        'prenom': !exists(json, 'prenom') ? undefined : TextSearchFromJSON(json['prenom']),
        'registreNational': !exists(json, 'registreNational') ? undefined : TextSearchFromJSON(json['registreNational']),
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : DateSearchFromJSON(json['dateNaissance']),
        'telephone': !exists(json, 'telephone') ? undefined : TextSearchFromJSON(json['telephone']),
        'gsm': !exists(json, 'gsm') ? undefined : TextSearchFromJSON(json['gsm']),
        'email': !exists(json, 'email') ? undefined : TextSearchFromJSON(json['email']),
        'localite': !exists(json, 'localite') ? undefined : TextSearchFromJSON(json['localite']),
        'selectedTuteurIds': !exists(json, 'selectedTuteurIds') ? undefined : json['selectedTuteurIds'],
        'excludedTuteurIds': !exists(json, 'excludedTuteurIds') ? undefined : json['excludedTuteurIds'],
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function TuteurSelectorSearchToJSON(value?: TuteurSelectorSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nom': TextSearchToJSON(value.nom),
        'prenom': TextSearchToJSON(value.prenom),
        'registreNational': TextSearchToJSON(value.registreNational),
        'dateNaissance': DateSearchToJSON(value.dateNaissance),
        'telephone': TextSearchToJSON(value.telephone),
        'gsm': TextSearchToJSON(value.gsm),
        'email': TextSearchToJSON(value.email),
        'localite': TextSearchToJSON(value.localite),
        'selectedTuteurIds': value.selectedTuteurIds,
        'excludedTuteurIds': value.excludedTuteurIds,
        'idlieuFormation': value.idlieuFormation,
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

