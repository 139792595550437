/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbContratFormateurGridDto
 */
export interface FcbContratFormateurGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbContratFormateurGridDto
     */
    idcontratFormateur?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbContratFormateurGridDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbContratFormateurGridDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbContratFormateurGridDto
     */
    idgenre?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbContratFormateurGridDto
     */
    nbheureMin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbContratFormateurGridDto
     */
    nbheureMax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbContratFormateurGridDto
     */
    nbheureJury?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbContratFormateurGridDto
     */
    type?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbContratFormateurGridDto
     */
    typeDisplay?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbContratFormateurGridDto
     */
    genre?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbContratFormateurGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbContratFormateurGridDto interface.
 */
export function instanceOfFcbContratFormateurGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbContratFormateurGridDtoFromJSON(json: any): FcbContratFormateurGridDto {
    return FcbContratFormateurGridDtoFromJSONTyped(json, false);
}

export function FcbContratFormateurGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbContratFormateurGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontratFormateur': !exists(json, 'idcontratFormateur') ? undefined : json['idcontratFormateur'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'idgenre': !exists(json, 'idgenre') ? undefined : json['idgenre'],
        'nbheureMin': !exists(json, 'nbheureMin') ? undefined : json['nbheureMin'],
        'nbheureMax': !exists(json, 'nbheureMax') ? undefined : json['nbheureMax'],
        'nbheureJury': !exists(json, 'nbheureJury') ? undefined : json['nbheureJury'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'typeDisplay': !exists(json, 'typeDisplay') ? undefined : json['typeDisplay'],
        'genre': !exists(json, 'genre') ? undefined : json['genre'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbContratFormateurGridDtoToJSON(value?: FcbContratFormateurGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontratFormateur': value.idcontratFormateur,
        'code': value.code,
        'libelle': value.libelle,
        'idgenre': value.idgenre,
        'nbheureMin': value.nbheureMin,
        'nbheureMax': value.nbheureMax,
        'nbheureJury': value.nbheureJury,
        'type': value.type,
        'typeDisplay': value.typeDisplay,
        'genre': value.genre,
        'actif': value.actif,
    };
}

