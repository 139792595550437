import { Card, Tooltip } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";

import { ContratApi, ContratAvenantGridDto, ContratAvenantSearchDto, ContratGridDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { AddButton, ViewButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface ILieuFormationAvenantsGridProps {
  contrat: ContratGridDto;
}

export const LieuFormationAvenantsGrid: React.FunctionComponent<ILieuFormationAvenantsGridProps> = ({ contrat }) => {
  const { t } = useTl();
  const api = useApiService(ContratApi);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15
  });

  const searchFunction = React.useCallback(
    (sObj?: ContratAvenantSearchDto) => {
      sObj.idcontrat = contrat.idcontrat;
      sObj.showDeleted = false;
      return api.contratSearchAvenants({ ContratAvenantSearchDto: sObj });
    },
    [api, contrat.idcontrat]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const onViewClick = React.useCallback(
    (dto: ContratAvenantGridDto) => {
      window.open(`#${ERoutes.contrat}/${contrat.idcontrat}/avenants/${contrat.type}/view/${dto.idavenant}`);
    },
    [contrat.idcontrat, contrat.type]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ContratAvenantGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => onViewClick(row)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Types),
        autoFitContent: true,
        computed: true,
        fieldName: "types",
        render: (row: ContratAvenantGridDto) => {
          if (row.types?.length > 0) {
            return (
              <span style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                {row.types.length > 0 && row.types[0]}
                {row.types.length > 1 && (
                  <span>
                    &nbsp;
                    <Tooltip
                      content={
                        <div>
                          {row.types.map((type, i) => (
                            <div key={i}>{type}</div>
                          ))}
                        </div>
                      }
                      position="right"
                    >
                      <div style={{ cursor: "pointer" }}>...</div>
                    </Tooltip>
                  </span>
                )}
              </span>
            );
          } else {
            return <div></div>;
          }
        }
      },
      {
        header: () => t(ETLCodes.Numero),
        fieldName: "numero"
      },
      {
        header: () => t(ETLCodes.DatePriseEnCours),
        fieldName: "datePriseEnCours"
      },
      {
        header: () => t(ETLCodes.DateSignature),
        fieldName: "dateSignature"
      },
      {
        header: () => t(ETLCodes.LieuSignature),
        fieldName: "lieuSignature"
      }
    ],
    [onViewClick, t]
  );

  const onAddAvenant = React.useCallback(
    (idcontrat?: number) => {
      window.open(`#${ERoutes.contrat}/${idcontrat}/avenants/${contrat.type}/view/-1`);
    },
    [contrat.type]
  );

  const buttons = React.useMemo(
    () => (
      <AddButton
        text={t(ETLCodes.General_Add)}
        onClick={() => {
          onAddAvenant(contrat.idcontrat);
        }}
      />
    ),
    [contrat.idcontrat, onAddAvenant, t]
  );

  return (
    <Card>
      <FieldSet rightElement={buttons}>
        <DataTable
          dateFormat="dd-MM-yyyy"
          tableState={tableState}
          columns={columns}
          renderNoData={<></>}
          loading={loading}
        />
      </FieldSet>
    </Card>
  );
};
