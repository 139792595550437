import * as React from "react";
import { matchPath, useLocation, useParams } from "react-router";
import { SiegeSocialChefEntreprise, SiegeSocialChefEntrepriseDoublons, SiegeSocialChefEntrepriseListPage } from ".";
import { ChefsEntrepriseSiegeDoublonSearchDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";

export interface ISiegeSocialChefEntrepriseSwitchProps {}

export const SiegeSocialChefEntrepriseSwitch: React.FunctionComponent<ISiegeSocialChefEntrepriseSwitchProps> = props => {
  const [sObj, setSObj] = React.useState(ChefsEntrepriseSiegeDoublonSearchDtoFromJSON({}));
  const { pathname } = useLocation();
  const { id } = useParams<{ id: string; tab: string; state: string }>();
  if (
    matchPath(pathname, {
      path: `${ERoutes.siegeSocial}/${id}/chefsEntreprise/:state/:subId`
    })
  ) {
    return <SiegeSocialChefEntreprise baseData={sObj} />;
  } else if (
    matchPath(pathname, {
      path: `${ERoutes.siegeSocial}/${id}/chefsEntreprise/checkDoublons`
    })
  ) {
    return <SiegeSocialChefEntrepriseDoublons setBaseData={setSObj} />;
  } else {
    return <SiegeSocialChefEntrepriseListPage />;
  }
};
