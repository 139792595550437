import { FGCheckboxInput, FGTextAreaInput, FieldGroup, FieldSet } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";
import * as Yup from "yup";

import {
  DemandeFormationCreaApi,
  DemandeFormationEditDto,
  DemandeFormationEditDtoFromJSON
} from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import { FGWalterSelectInput, SmallFormGenerator } from "../../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

export interface IDemandeFormationUpdateProps {}

export const DemandeFormationUpdate: React.FunctionComponent<IDemandeFormationUpdateProps> = props => {
  const { t } = useTl();
  const api = useApiService(DemandeFormationCreaApi);
  const { id, state, idDemande } = useParams<{
    id: string;
    state: string;
    idDemande: string;
  }>();

  const { data, loading, saveItem, saving } = useCrudApi<DemandeFormationEditDto>(
    React.useMemo(
      () => ({
        getApiFn: () =>
          +idDemande > 0
            ? api.demandeFormationCreaGetDemandeFormation({ idDemande: +idDemande })
            : DemandeFormationEditDtoFromJSON({ idstatutDemande: 2, premierSemestre: false }),
        saveApiFn: d => api.demandeFormationCreaSave({ DemandeFormationEditDto: d }),
        onSavedRoute: d => `${ERoutes.prospect}/${id}/crea/edit`
      }),
      [api, id, idDemande]
    )
  );

  const [metiers, meLoading] = useReferential(a => a.referentialGetMetierCrea());
  const [modules, moLoading] = useReferential(a => a.referentialGetModuleCrea());
  const [annees, anLoading] = useReferential(a => a.referentialGetAnneeScolaire());
  const [centres, ceLoading] = useReferential(a => a.referentialGetCentres());

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape({
        idmetier: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        idstade: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        idmodule: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        idannee: Yup.string()
          .nullable()
          .required(t(ETLCodes.Required)),
        premierSemestre: Yup.boolean()
          .nullable()
          .required(t(ETLCodes.Required)),
        idcentre: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required))
      }),
    [t]
  );

  return (
    <div style={{ marginBottom: "0.5rem" }}>
      <FieldSet title={t(ETLCodes.DemandeFormationCrea)}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode
          saving={saving}
          loading={loading}
          formatDate="dd-MM-yyyy"
          minLabelWidth={250}
          validationSchema={FormSchema}
        >
          <FieldGroup>
            <FGWalterSelectInput
              name="idmetier"
              label={t(ETLCodes.Metier)}
              items={metiers}
              loading={meLoading}
              disabled
              autoSelectIfOne
            />
            <FGWalterSelectInput name="idmodule" label={t(ETLCodes.Module)} items={modules} loading={moLoading} />
            <FGWalterSelectInput
              name="idannee"
              label={t(ETLCodes.AnneeAcademique)}
              items={annees}
              loading={anLoading}
            />
            <FGCheckboxInput name="premierSemestre" label={t(ETLCodes.PremierSemestreWithDates)} />
            <FGWalterSelectInput name="idcentre" label={t(ETLCodes.Centre)} items={centres} loading={ceLoading} />
            <FGTextAreaInput
              name="remarqueInvalidation"
              label={t(ETLCodes.RemarqueInvalidation)}
              readonly
              visible={ctx => !!ctx.formik?.values?.remarqueInvalidation}
            />
          </FieldGroup>
        </SmallFormGenerator>
      </FieldSet>
    </div>
  );
};
