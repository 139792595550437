/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbFormateurAgrementDto,
  FcbFormateurAgrementGridDtoPaginatedResults,
  FilterCriteriaInfo,
  FormateurAgrementSearch,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    FcbFormateurAgrementDtoFromJSON,
    FcbFormateurAgrementDtoToJSON,
    FcbFormateurAgrementGridDtoPaginatedResultsFromJSON,
    FcbFormateurAgrementGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    FormateurAgrementSearchFromJSON,
    FormateurAgrementSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface FormateurAgrementBaseSearchRequest {
    FormateurAgrementSearch?: FormateurAgrementSearch;
}

export interface FormateurAgrementCheckDateDemandeRequest {
    dateDemande?: Date;
    dateDebut?: Date;
}

export interface FormateurAgrementDeleteRequest {
    id?: number;
}

export interface FormateurAgrementGetRequest {
    id?: number;
}

export interface FormateurAgrementGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface FormateurAgrementGetSelectItemsRequest {
    searchField: string;
}

export interface FormateurAgrementSaveRequest {
    FcbFormateurAgrementDto?: FcbFormateurAgrementDto;
}

/**
 * 
 */
export class FormateurAgrementApi extends runtime.BaseAPI {

    /**
     */
    async formateurAgrementBaseSearchRaw(requestParameters: FormateurAgrementBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurAgrementGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurAgrement/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormateurAgrementSearchToJSON(requestParameters.FormateurAgrementSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurAgrementGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async formateurAgrementBaseSearch(requestParameters: FormateurAgrementBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurAgrementGridDtoPaginatedResults> {
        const response = await this.formateurAgrementBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurAgrementCheckDateDemandeRaw(requestParameters: FormateurAgrementCheckDateDemandeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.dateDemande !== undefined) {
            queryParameters['dateDemande'] = (requestParameters.dateDemande as any).toISOString();
        }

        if (requestParameters.dateDebut !== undefined) {
            queryParameters['dateDebut'] = (requestParameters.dateDebut as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurAgrement/checkDateDemande`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async formateurAgrementCheckDateDemande(requestParameters: FormateurAgrementCheckDateDemandeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.formateurAgrementCheckDateDemandeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurAgrementDeleteRaw(requestParameters: FormateurAgrementDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurAgrement`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async formateurAgrementDelete(requestParameters: FormateurAgrementDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.formateurAgrementDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurAgrementGetRaw(requestParameters: FormateurAgrementGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurAgrementDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurAgrement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurAgrementDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurAgrementGet(requestParameters: FormateurAgrementGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurAgrementDto> {
        const response = await this.formateurAgrementGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurAgrementGetSearchCriteriasRaw(requestParameters: FormateurAgrementGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurAgrement/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async formateurAgrementGetSearchCriterias(requestParameters: FormateurAgrementGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.formateurAgrementGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurAgrementGetSelectItemsRaw(requestParameters: FormateurAgrementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling formateurAgrementGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurAgrement/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async formateurAgrementGetSelectItems(requestParameters: FormateurAgrementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.formateurAgrementGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurAgrementSaveRaw(requestParameters: FormateurAgrementSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurAgrementDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurAgrement`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbFormateurAgrementDtoToJSON(requestParameters.FcbFormateurAgrementDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurAgrementDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurAgrementSave(requestParameters: FormateurAgrementSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurAgrementDto> {
        const response = await this.formateurAgrementSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
