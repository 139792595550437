import { Callout, NonIdealState } from "@blueprintjs/core";
import { isAfter } from "date-fns";
import { FieldSet } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { AP_FINAL_KEY, CarouselComparer, DoublonsDataCard, useDoublonsStateContext } from "..";
import { ApprenantDoublonEditDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { nameof } from "../../../../../utils";

const Container = styled.div`
  height: 70vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export interface IDoublonApprenantPassportDriveProps {}

export const DoublonApprenantPassportDrive: React.FunctionComponent<IDoublonApprenantPassportDriveProps> = props => {
  const { t } = useTl();
  const { data, getSelectedIndex } = useDoublonsStateContext();

  const selectedIndex = React.useMemo(() => getSelectedIndex(`${AP_FINAL_KEY}.idapprenant`) ?? 0, [getSelectedIndex]);

  const apprenantContainer = React.useCallback(
    (index: number, nb: number) => {
      const apprenant: ApprenantDoublonEditDto = data?.apprenants[index];
      if (!apprenant) return;

      const isMostRecent =
        !!apprenant.passportDrive &&
        data?.apprenants
          ?.filter(a => a.idapprenant !== apprenant.idapprenant)
          ?.every(
            a => !a.passportDrive || isAfter(apprenant.passportDrive.modificationDate, a.passportDrive.modificationDate)
          );

      return (
        <FieldSet
          key={apprenant.idapprenant}
          title={
            <ViewButton
              text={t(ETLCodes.ApprenantNb, { nb: nb, id: apprenant.idapprenant })}
              onClick={() => window.open(`#${ERoutes.apprenant}/${apprenant.idapprenant}/signaletique/view`, "_blank")}
            />
          }
          titlePosition="center"
        >
          {!!apprenant.passportDrive ? (
            <>
              <DoublonsDataCard
                fieldPath={`apprenants[${index}].passportDrive.permis`}
                label={t(ETLCodes.Permis)}
                interactive={false}
                displaySelectedValue={false}
                forceSelected={isMostRecent}
                entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
              />
              <DoublonsDataCard
                fieldPath={`apprenants[${index}].passportDrive.idautoecole`}
                label={t(ETLCodes.AutoEcole)}
                interactive={false}
                extValue={<>{apprenant.passportDrive.autoecole}</>}
                displaySelectedValue={false}
                forceSelected={isMostRecent}
                entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
              />
              <DoublonsDataCard
                fieldPath={`apprenants[${index}].passportDrive.remiseCheque`}
                label={t(ETLCodes.RemiseCheque)}
                interactive={false}
                displaySelectedValue={false}
                forceSelected={isMostRecent}
                entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
              />
              <DoublonsDataCard
                fieldPath={`apprenants[${index}].passportDrive.inscriptionEffective`}
                label={t(ETLCodes.InscriptionEffective)}
                interactive={false}
                displaySelectedValue={false}
                forceSelected={isMostRecent}
                entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
              />
              <DoublonsDataCard
                fieldPath={`apprenants[${index}].passportDrive.datePermisTheorique`}
                label={t(ETLCodes.DatePermisTheorique)}
                interactive={false}
                displaySelectedValue={false}
                forceSelected={isMostRecent}
                entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
              />
              <DoublonsDataCard
                fieldPath={`apprenants[${index}].passportDrive.resultatPermisTheorique`}
                label={t(ETLCodes.ResultatPermisTheorique)}
                interactive={false}
                displaySelectedValue={false}
                forceSelected={isMostRecent}
                entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
              />
              <DoublonsDataCard
                fieldPath={`apprenants[${index}].passportDrive.datePermisPratique`}
                label={t(ETLCodes.DatePermisPratique)}
                interactive={false}
                displaySelectedValue={false}
                forceSelected={isMostRecent}
                entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
              />
              <DoublonsDataCard
                fieldPath={`apprenants[${index}].passportDrive.idresultatPermisPratique`}
                label={t(ETLCodes.ResultatPermisPratique)}
                interactive={false}
                displaySelectedValue={false}
                forceSelected={isMostRecent}
                entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
              />
              <DoublonsDataCard
                fieldPath={`apprenants[${index}].passportDrive.demandeNouveauCheque`}
                label={t(ETLCodes.DemandeNouveauCheque)}
                interactive={false}
                displaySelectedValue={false}
                forceSelected={isMostRecent}
                entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
              />
              <DoublonsDataCard
                fieldPath={`apprenants[${index}].passportDrive.pasEmailPromotionnel`}
                label={t(ETLCodes.PasEmailPromotionnel)}
                interactive={false}
                displaySelectedValue={false}
                forceSelected={isMostRecent}
                entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
              />
              <DoublonsDataCard
                fieldPath={`apprenants[${index}].passportDrive.consentementCollecteDonnees`}
                label={t(ETLCodes.ConsentementCollecteDonnees)}
                interactive={false}
                displaySelectedValue={false}
                forceSelected={isMostRecent}
                entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
              />
              <DoublonsDataCard
                fieldPath={`apprenants[${index}].passportDrive.chequeReceptionForem`}
                label={t(ETLCodes.ChequeReceptionForem)}
                interactive={false}
                displaySelectedValue={false}
                forceSelected={isMostRecent}
                entityIdKey={nameof<ApprenantDoublonEditDto>("idapprenant")}
              />
            </>
          ) : (
            <NonIdealState title={t(ETLCodes.PasDePassportDrive)} />
          )}
        </FieldSet>
      );
    },
    [data?.apprenants, t]
  );

  return (
    <Container>
      <Callout
        intent="warning"
        title={t(ETLCodes.DoublonApprenantPassportDriveAuto)}
        style={{ marginBottom: "1rem" }}
        icon="info-sign"
      />
      <CarouselComparer leftElement={apprenantContainer(selectedIndex, selectedIndex + 1)}>
        {data?.apprenants?.length > 1 &&
          data?.apprenants
            ?.filter((a, i) => i !== selectedIndex)
            ?.map((a, i) => apprenantContainer(i + (i >= selectedIndex ? 1 : 0), i + (i >= selectedIndex ? 2 : 1)))}
      </CarouselComparer>
    </Container>
  );
};
