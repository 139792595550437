import { Checkbox } from '@blueprintjs/core';
import { DataTable, FieldSet, IDataTableColumn, useGridState } from 'nsitools-react';
import * as React from "react";
import { RapportHopeApi, RapportHopeModulePermissionDto } from "../../../../../../api";
import { useApiService, useTl } from '../../../../../../hooks';
import { ETLCodes } from '../../../../../../locales';

export interface IRapportHopeModulePermissionsGridProps {
  idrapportHope: number;
  modules: RapportHopeModulePermissionDto[];
  loading: boolean;
}

export const RapportHopeModulePermissionsGrid: React.FunctionComponent<IRapportHopeModulePermissionsGridProps> = ({idrapportHope, loading, modules}) => {
  const { t } = useTl();
  const api = useApiService(RapportHopeApi);

  const tableState = useGridState<RapportHopeModulePermissionDto>({
    serverMode: false,
    enablePagination: false,
    enableFilter: false
  });

  const { setData } = tableState;

  React.useEffect(() => {
    setData(modules);
  }, [modules, setData]);

  const toggleSelection = React.useCallback(
    (row: RapportHopeModulePermissionDto) => {
      row.selected = !row.selected;
      row.idrapportHope = idrapportHope;
      let nextData = [...modules];
      setData(nextData);
      api.rapportHopeSaveModulePermissions({ RapportHopeModulePermissionDto: row });
    },
    [api, idrapportHope, modules, setData]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        render: (item: RapportHopeModulePermissionDto) => (
          <Checkbox
            checked={item.selected}
            onChange={e => {
              toggleSelection(item);
            }}
          />
        )
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "nom"
      }
    ],
    [t, toggleSelection]
  );

  return (
    <FieldSet title={t(ETLCodes.Modules)} collapsable defaultCollapsed>
      <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
    </FieldSet>
  );
};
