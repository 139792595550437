/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConseillerPedagogiqueMetierLiesSearch,
  FcbConseillerMetiersDto,
  FcbConseillerPedagogiqueMetiersLiesGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    ConseillerPedagogiqueMetierLiesSearchFromJSON,
    ConseillerPedagogiqueMetierLiesSearchToJSON,
    FcbConseillerMetiersDtoFromJSON,
    FcbConseillerMetiersDtoToJSON,
    FcbConseillerPedagogiqueMetiersLiesGridDtoPaginatedResultsFromJSON,
    FcbConseillerPedagogiqueMetiersLiesGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface ConseillerPedagogiqueMetiersLiesBaseSearchRequest {
    ConseillerPedagogiqueMetierLiesSearch?: ConseillerPedagogiqueMetierLiesSearch;
}

export interface ConseillerPedagogiqueMetiersLiesGetConseillerMetierRequest {
    codeConseiller?: string;
}

export interface ConseillerPedagogiqueMetiersLiesGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ConseillerPedagogiqueMetiersLiesGetSelectItemsRequest {
    searchField: string;
}

export interface ConseillerPedagogiqueMetiersLiesSaveRequest {
    FcbConseillerMetiersDto?: FcbConseillerMetiersDto;
}

/**
 * 
 */
export class ConseillerPedagogiqueMetiersLiesApi extends runtime.BaseAPI {

    /**
     */
    async conseillerPedagogiqueMetiersLiesBaseSearchRaw(requestParameters: ConseillerPedagogiqueMetiersLiesBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbConseillerPedagogiqueMetiersLiesGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ConseillerPedagogiqueMetiersLies/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConseillerPedagogiqueMetierLiesSearchToJSON(requestParameters.ConseillerPedagogiqueMetierLiesSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbConseillerPedagogiqueMetiersLiesGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async conseillerPedagogiqueMetiersLiesBaseSearch(requestParameters: ConseillerPedagogiqueMetiersLiesBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbConseillerPedagogiqueMetiersLiesGridDtoPaginatedResults> {
        const response = await this.conseillerPedagogiqueMetiersLiesBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async conseillerPedagogiqueMetiersLiesGetConseillerMetierRaw(requestParameters: ConseillerPedagogiqueMetiersLiesGetConseillerMetierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbConseillerMetiersDto>> {
        const queryParameters: any = {};

        if (requestParameters.codeConseiller !== undefined) {
            queryParameters['codeConseiller'] = requestParameters.codeConseiller;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ConseillerPedagogiqueMetiersLies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbConseillerMetiersDtoFromJSON(jsonValue));
    }

    /**
     */
    async conseillerPedagogiqueMetiersLiesGetConseillerMetier(requestParameters: ConseillerPedagogiqueMetiersLiesGetConseillerMetierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbConseillerMetiersDto> {
        const response = await this.conseillerPedagogiqueMetiersLiesGetConseillerMetierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async conseillerPedagogiqueMetiersLiesGetSearchCriteriasRaw(requestParameters: ConseillerPedagogiqueMetiersLiesGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ConseillerPedagogiqueMetiersLies/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async conseillerPedagogiqueMetiersLiesGetSearchCriterias(requestParameters: ConseillerPedagogiqueMetiersLiesGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.conseillerPedagogiqueMetiersLiesGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async conseillerPedagogiqueMetiersLiesGetSelectItemsRaw(requestParameters: ConseillerPedagogiqueMetiersLiesGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling conseillerPedagogiqueMetiersLiesGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ConseillerPedagogiqueMetiersLies/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async conseillerPedagogiqueMetiersLiesGetSelectItems(requestParameters: ConseillerPedagogiqueMetiersLiesGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.conseillerPedagogiqueMetiersLiesGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async conseillerPedagogiqueMetiersLiesSaveRaw(requestParameters: ConseillerPedagogiqueMetiersLiesSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbConseillerMetiersDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ConseillerPedagogiqueMetiersLies/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbConseillerMetiersDtoToJSON(requestParameters.FcbConseillerMetiersDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbConseillerMetiersDtoFromJSON(jsonValue));
    }

    /**
     */
    async conseillerPedagogiqueMetiersLiesSave(requestParameters: ConseillerPedagogiqueMetiersLiesSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbConseillerMetiersDto> {
        const response = await this.conseillerPedagogiqueMetiersLiesSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
