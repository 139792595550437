/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CopReassignmentDto
 */
export interface CopReassignmentDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof CopReassignmentDto
     */
    cops?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CopReassignmentDto
     */
    idsApprenant?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof CopReassignmentDto
     */
    newIdCop?: number | null;
}

/**
 * Check if a given object implements the CopReassignmentDto interface.
 */
export function instanceOfCopReassignmentDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CopReassignmentDtoFromJSON(json: any): CopReassignmentDto {
    return CopReassignmentDtoFromJSONTyped(json, false);
}

export function CopReassignmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopReassignmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cops': !exists(json, 'cops') ? undefined : json['cops'],
        'idsApprenant': !exists(json, 'idsApprenant') ? undefined : json['idsApprenant'],
        'newIdCop': !exists(json, 'newIdCop') ? undefined : json['newIdCop'],
    };
}

export function CopReassignmentDtoToJSON(value?: CopReassignmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cops': value.cops,
        'idsApprenant': value.idsApprenant,
        'newIdCop': value.newIdCop,
    };
}

