import { DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";

import { SecteurMetierLiesSearch, SecteurMetierMetierLiesApi } from "../../../../../api";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface ISecteurMetierMetierLiesListPageProps {}

export const SecteurMetierMetierLiesListPage: React.FunctionComponent<ISecteurMetierMetierLiesListPageProps> = () => {
  const { t } = useTl();
  const api = useApiService(SecteurMetierMetierLiesApi);

  const { id } = useParams<{ id: string; state: string }>();

  const tableState = useGridState<any>({
    sortKeys: { code: "ASC" },
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15
  });

  const searchFunction = React.useCallback(
    (sObj?: SecteurMetierLiesSearch) => {
      sObj.codesecteur = id;
      return api.secteurMetierMetierLiesBaseSearch({ SecteurMetierLiesSearch: sObj });
    },
    [api, id]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });
  const { totalCount } = tableState;
  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code"
      },
      {
        header: () => t(ETLCodes.Stade),
        fieldName: "stade"
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "masculin"
      }
    ],
    [t]
  );

  return (
    <FieldSet title={t(ETLCodes.TableResults, { count: totalCount })}>
      <DataTable dateFormat="dd/MM/yyyy" tableState={tableState} loading={loading} columns={columns}></DataTable>
    </FieldSet>
  );
};
