import * as React from "react";
import { Switch, Route } from "react-router";
import { ERoutes } from "../../../../../AppRouter";
import { ClasseMatiereItemPage } from "./ClasseMatiereItemPage";
import { ClasseMatiereListPage } from "./ClasseMatiereListPage";
import { ClasseMatiereCutPage } from "./section/ClasseMatiereCutPage";
import { ClasseMatiereAssignMultiplePage } from "./assignMultiple/ClasseMatiereAssignMultiplePage";

export interface IClasseMatiereSwitchProps {}

export const ClasseMatiereSwitch: React.FunctionComponent<IClasseMatiereSwitchProps> = () => {
  return (
    <Switch>
      <Route path={`${ERoutes.classe}/:id/:tab/:state`} component={ClasseMatiereListPage} exact />
      <Route path={`${ERoutes.classe}/:id/:tab/:state/multiple`} component={ClasseMatiereAssignMultiplePage} exact />
      <Route path={`${ERoutes.classe}/:id/:tab/:idMatiere/cut`} exact component={ClasseMatiereCutPage} />
      <Route path={`${ERoutes.classe}/:id/:tab/:idMatiere/:state`} component={ClasseMatiereItemPage} />
    </Switch>
  );
};
