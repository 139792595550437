/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FcbExportAccesPermissionDto } from './FcbExportAccesPermissionDto';
import {
    FcbExportAccesPermissionDtoFromJSON,
    FcbExportAccesPermissionDtoFromJSONTyped,
    FcbExportAccesPermissionDtoToJSON,
} from './FcbExportAccesPermissionDto';
import type { FcbExportCentrePermissionDto } from './FcbExportCentrePermissionDto';
import {
    FcbExportCentrePermissionDtoFromJSON,
    FcbExportCentrePermissionDtoFromJSONTyped,
    FcbExportCentrePermissionDtoToJSON,
} from './FcbExportCentrePermissionDto';
import type { FcbExportModulePermissionDto } from './FcbExportModulePermissionDto';
import {
    FcbExportModulePermissionDtoFromJSON,
    FcbExportModulePermissionDtoFromJSONTyped,
    FcbExportModulePermissionDtoToJSON,
} from './FcbExportModulePermissionDto';

/**
 * 
 * @export
 * @interface FcbExportPermissionsDto
 */
export interface FcbExportPermissionsDto {
    /**
     * 
     * @type {Array<FcbExportCentrePermissionDto>}
     * @memberof FcbExportPermissionsDto
     */
    centres?: Array<FcbExportCentrePermissionDto> | null;
    /**
     * 
     * @type {Array<FcbExportAccesPermissionDto>}
     * @memberof FcbExportPermissionsDto
     */
    acces?: Array<FcbExportAccesPermissionDto> | null;
    /**
     * 
     * @type {Array<FcbExportModulePermissionDto>}
     * @memberof FcbExportPermissionsDto
     */
    modules?: Array<FcbExportModulePermissionDto> | null;
}

/**
 * Check if a given object implements the FcbExportPermissionsDto interface.
 */
export function instanceOfFcbExportPermissionsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbExportPermissionsDtoFromJSON(json: any): FcbExportPermissionsDto {
    return FcbExportPermissionsDtoFromJSONTyped(json, false);
}

export function FcbExportPermissionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbExportPermissionsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'centres': !exists(json, 'centres') ? undefined : (json['centres'] === null ? null : (json['centres'] as Array<any>).map(FcbExportCentrePermissionDtoFromJSON)),
        'acces': !exists(json, 'acces') ? undefined : (json['acces'] === null ? null : (json['acces'] as Array<any>).map(FcbExportAccesPermissionDtoFromJSON)),
        'modules': !exists(json, 'modules') ? undefined : (json['modules'] === null ? null : (json['modules'] as Array<any>).map(FcbExportModulePermissionDtoFromJSON)),
    };
}

export function FcbExportPermissionsDtoToJSON(value?: FcbExportPermissionsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'centres': value.centres === undefined ? undefined : (value.centres === null ? null : (value.centres as Array<any>).map(FcbExportCentrePermissionDtoToJSON)),
        'acces': value.acces === undefined ? undefined : (value.acces === null ? null : (value.acces as Array<any>).map(FcbExportAccesPermissionDtoToJSON)),
        'modules': value.modules === undefined ? undefined : (value.modules === null ? null : (value.modules as Array<any>).map(FcbExportModulePermissionDtoToJSON)),
    };
}

