/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HealthCheckEntryViewModel } from './HealthCheckEntryViewModel';
import {
    HealthCheckEntryViewModelFromJSON,
    HealthCheckEntryViewModelFromJSONTyped,
    HealthCheckEntryViewModelToJSON,
} from './HealthCheckEntryViewModel';

/**
 * 
 * @export
 * @interface HealthCheckViewModel
 */
export interface HealthCheckViewModel {
    /**
     * 
     * @type {string}
     * @memberof HealthCheckViewModel
     */
    globalStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HealthCheckViewModel
     */
    checkTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HealthCheckViewModel
     */
    worstExecutionTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HealthCheckViewModel
     */
    totalExecutionTime?: string | null;
    /**
     * 
     * @type {Array<HealthCheckEntryViewModel>}
     * @memberof HealthCheckViewModel
     */
    entries?: Array<HealthCheckEntryViewModel> | null;
}

/**
 * Check if a given object implements the HealthCheckViewModel interface.
 */
export function instanceOfHealthCheckViewModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HealthCheckViewModelFromJSON(json: any): HealthCheckViewModel {
    return HealthCheckViewModelFromJSONTyped(json, false);
}

export function HealthCheckViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthCheckViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'globalStatus': !exists(json, 'globalStatus') ? undefined : json['globalStatus'],
        'checkTime': !exists(json, 'checkTime') ? undefined : json['checkTime'],
        'worstExecutionTime': !exists(json, 'worstExecutionTime') ? undefined : json['worstExecutionTime'],
        'totalExecutionTime': !exists(json, 'totalExecutionTime') ? undefined : json['totalExecutionTime'],
        'entries': !exists(json, 'entries') ? undefined : (json['entries'] === null ? null : (json['entries'] as Array<any>).map(HealthCheckEntryViewModelFromJSON)),
    };
}

export function HealthCheckViewModelToJSON(value?: HealthCheckViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'globalStatus': value.globalStatus,
        'checkTime': value.checkTime,
        'worstExecutionTime': value.worstExecutionTime,
        'totalExecutionTime': value.totalExecutionTime,
        'entries': value.entries === undefined ? undefined : (value.entries === null ? null : (value.entries as Array<any>).map(HealthCheckEntryViewModelToJSON)),
    };
}

