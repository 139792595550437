import { addMinutes, format, parseJSON } from "date-fns";
import { DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";

import { ApprenantHistoryApi, ApprenantHistorySearch, FcbApprenantHistoryGridDto } from "../../../../../api";
import { ERoutes } from '../../../../../AppRouter';
import { LinkButton } from '../../../../../components';
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IApprenantHistoryProps { }
export const ApprenantHistory: React.FunctionComponent<IApprenantHistoryProps> = () => {
  const { t, tUnsafe } = useTl();
  const { id } = useParams<{ id: string }>();
  const apprenantId = React.useMemo(() => +id, [id]);
  const api = useApiService(ApprenantHistoryApi);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { actionDate: "DESC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: ApprenantHistorySearch) => {
      sObj.idapprenant = apprenantId;
      return api.apprenantHistoryBaseSearch({ ApprenantHistorySearch: sObj });
    },
    [api, apprenantId]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });
  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.Utilisateur),
        fieldName: "userName",
        render: (row: FcbApprenantHistoryGridDto) => <LinkButton text={row.userName} onClick={() => window.open(`#${ERoutes.personne}/${row.idpersonne}/signaletique/view`, '_blank')} />
      },
      {
        header: () => t(ETLCodes.Action),
        fieldName: "action",
        render: (row: FcbApprenantHistoryGridDto) => tUnsafe(`Apprenant_${row.actionType}_${row.action}`)
      },
      {
        header: () => t(ETLCodes.Date),
        fieldName: "actionDate"
      },
      {
        header: () => t(ETLCodes.UserType),
        fieldName: "ifapmeSide",
        render: (row: FcbApprenantHistoryGridDto) =>
          `${row.ifapmeSide.includes("Walter") ? tUnsafe("UserType_Centre") : ""}${row.ifapmeSide.length === 2 ? " / " : ""
          }${row.ifapmeSide.includes("Hope") ? tUnsafe("UserType_Service") : ""}`
      }
    ],
    [t, tUnsafe]
  );

  const formatDate = React.useCallback((date: Date) => {
    return format(parseJSON(addMinutes(date, date.getTimezoneOffset())), "dd/MM/yyyy HH:mm");
  }, []);

  return (
    <>
      <FieldSet title={t(ETLCodes.TableResults, { count: totalCount })}>
        <DataTable
          dateFormat="dd/MM/yyyy HH:mm"
          formatDate={formatDate}
          tableState={tableState}
          loading={loading}
          columns={columns}
        ></DataTable>
      </FieldSet>
    </>
  );
};
