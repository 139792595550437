import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { TypeFinancementApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { EnveloppeTFSearch } from "./enveloppeTF/EnveloppeTFSearch";
import { TypeFinancementDetailPage } from "./TypefinancementDetailPage";

interface ITypeFinancementItemPageProps {}

export const TypeFinancementItemPage: React.FunctionComponent<ITypeFinancementItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const typeFinancementApi = useApiService(TypeFinancementApi);

  const [displayName] = useApiEffect(() => typeFinancementApi.typeFinancementGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.TypesFinancement), route: ERoutes.typeFinancement },
        { text: id === "0" ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsTypeFinancement"
        onChange={newTabId => history.push(`${ERoutes.typeFinancement}/${id}/${newTabId}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.typeFinancement} />
        <Tab id="detail" title={t(ETLCodes.Detail)} panel={<TypeFinancementDetailPage />} />
        <Tab id="enveloppe" title={t(ETLCodes.Enveloppe)} panel={<EnveloppeTFSearch />} disabled={id === "0"} />
      </ProtectedTabs>
    </PageBase>
  );
};
