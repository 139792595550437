import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";
import { addMinutes, format, parseJSON } from "date-fns";
import { FormikProps } from "formik";
import {
  ButtonContainer,
  DataTable,
  FGCustomInput,
  FGCustomPanel,
  FGListen,
  FGTextAreaInput,
  FieldGroup,
  IDataTableColumn,
  showError,
  useGridState
} from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import {
  DashboardApi,
  DemandeInscriptionFichierGridDto,
  EIfapmeSide,
  ELevelName,
  EStatutDemande,
  ParcoursFormationApi
} from "../../../../../../api";
import {
  DemandeInscriptionEditDto,
  DemandeInscriptionEditDtoFromJSON
} from "../../../../../../api/models/DemandeInscriptionEditDto";
import { ERoutes } from "../../../../../../AppRouter";
import {
  AddButton,
  DeleteButton,
  DownloadButton,
  ErrorText,
  FGWalterSelectInput,
  SmallFormGenerator,
  WarningText
} from "../../../../../../components";
import { useAuth, useDialog } from "../../../../../../contexts";
import { useApiService, useCrudApi, useTheme, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";
import { exportFile } from "../../../../../../utils";
import { CentresDemandeSelect } from "./CentresDemandeSelect";
import { DemandeInscriptionDocumentUploadDialog } from "./DemandeInscriptionDocumentUploadDialog";

const StyledDialog = styled(Dialog)`
  width: 600px;
  height: auto;
  background-color: white;
`;

const ErrorTextContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export interface IDemandeInscriptionSaveProps {}

export const DemandeInscriptionSave: React.FunctionComponent<IDemandeInscriptionSaveProps> = () => {
  const { t } = useTl();
  const api = useApiService(ParcoursFormationApi);
  const dashboardApi = useApiService(DashboardApi);
  const history = useHistory();
  const [invalidationDialogOpen, setInvalidationDialogOpen] = React.useState(false);

  const { id, state, idParcoursFormation, idDemande } = useParams<{
    id: string;
    state: string;
    idParcoursFormation: string;
    idDemande: string;
  }>();

  const getStatutDemandeCodeIdFunc = React.useCallback(async () => {
    return await api.parcoursFormationGetStatutDemandeCodeId();
  }, [api]);

  const { data: statutDemandeCode } = useQuery("StatutDemandeCode", getStatutDemandeCodeIdFunc);

  const idParcours = React.useMemo(() => (+idParcoursFormation > 0 ? +idParcoursFormation : 0), [idParcoursFormation]);
  const editMode = React.useMemo(() => state === "edit", [state]);

  const fetchParcours = React.useCallback(async () => {
    if (idParcours > 0) {
      return api.parcoursFormationGetParcoursFormation({ idApprenant: +id, idParcoursFormation: idParcours });
    } else return api.parcoursFormationGetParcoursFormation({ idApprenant: +id });
  }, [api, id, idParcours]);

  const { data: parcours } = useQuery(["parcoursFormation", id, idParcoursFormation], fetchParcours);

  const formikInnerRef = React.useRef<FormikProps<DemandeInscriptionEditDto>>();

  const { data, loading, saveItem, saving, deleteItem, deleting, refresh: refreshData, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () =>
          +idDemande <= 0
            ? DemandeInscriptionEditDtoFromJSON({ iddemandeInscription: 0, idparcoursFormation: +idParcoursFormation })
            : api.parcoursFormationGetDemandeInscription({ idDemande: +idDemande }),
        saveApiFn: (d: DemandeInscriptionEditDto) => {
          if (
            (d.changeStatut === EStatutDemande.E || d.changeStatut === EStatutDemande.EV) &&
            (!formikInnerRef.current?.values.idcentre ||
              !formikInnerRef.current?.values.iddegre ||
              !formikInnerRef.current?.values.idmetier ||
              !formikInnerRef.current?.values.idservice ||
              !formikInnerRef.current?.values.idstade ||
              !formikInnerRef.current?.values.idtypeCours)
          ) {
            showError(t(ETLCodes.VeuillezRemplirTousLesChamps));
            return;
          }
          return api.parcoursFormationSaveDemandeInscription({ DemandeInscriptionEditDto: d });
        },
        onSavedRoute: () => `${ERoutes.apprenant}/${id}/parcoursFormation/${state}`,
        deleteApiFn: d => api.parcoursFormationCancelDemandeInscription({ idDemande: +idDemande }),
        onDeletedRoute: () => `${ERoutes.apprenant}/${id}/parcoursFormation/${state}`
      }),
      [api, id, idDemande, idParcoursFormation, state, t]
    )
  );

  const [categoriesStatutSocial] = useReferential(a => a.referentialGetCategoriesStatutSocial());
  const [idstatutSocial, setIdstatutSocial] = React.useState(0);
  const [statutsSocial] = useReferential(a =>
    a.referentialGetSousStatutsByCategorie({ currentIdstatutSocial: idstatutSocial })
  );
  const [services, sLoading] = useReferential(a => a.referentialGetServicesByIdApprenant({ idApprenant: +id }));

  const [metiers, mLoading, , rawMetiers] = useReferential(a => a.referentialGetMetier());
  const [annees, aLoading] = useReferential(a => a.referentialGetAnneeScolaire());
  const { ifapmeSide } = useTheme();
  const [typeCours, tcLoading] = useReferential(
    a => a.referentialGetTypeCoursId({ ifapmeSide: ifapmeSide === "hope" ? EIfapmeSide.Hope : EIfapmeSide.Walter }),
    false,
    [ifapmeSide]
  );
  const [idmetier, setIdmetier] = React.useState(0);
  const [stades, stLoading, , rawStades] = useReferential(a => a.referentialGetStadeByMetier({ idmetier }), false, [
    idmetier
  ]);
  const idStade = React.useMemo(() => +(rawMetiers?.find(m => +m.idValue === +idmetier)?.keyValue ?? 0), [
    idmetier,
    rawMetiers
  ]);
  const [degres, dLoading, , rawDegres] = useReferential(
    a => a.referentialDegreIdsByStade({ idStade: idStade }),
    true,
    [idStade]
  );
  const filteredDegres = React.useMemo(() => {
    const filteredRawDegres = (rawDegres ?? [])
      .filter(s =>
        rawStades?.find(s => +s.idValue === +idStade)?.keyValue === "AP"
          ? ["00", "01", "02", "03"].includes(s.displayValue.substr(0, 2))
          : true
      )
      .map(d => +d.idValue);
    return degres?.filter(d => filteredRawDegres.includes(+d.value));
  }, [degres, idStade, rawDegres, rawStades]);

  const categorie = React.useMemo(
    () => categoriesStatutSocial?.find(c => +c.value === parcours?.idStatutSocialEntree)?.label,
    [categoriesStatutSocial, parcours]
  );
  const statut = React.useMemo(() => statutsSocial?.find(c => +c.value === parcours?.idSousStatutEntree)?.label, [
    parcours,
    statutsSocial
  ]);

  const editable = React.useMemo(() => (!!data ? data.editable : false), [data]);
  const [actionGlobaleLoading, setActionGlobaleLoading] = React.useState(false);
  const ChangeStatut = React.useCallback(
    async (codeStatut: string, validate: boolean) => {
      if (
        validate &&
        (!formikInnerRef.current?.values.idcentre ||
          !formikInnerRef.current?.values.iddegre ||
          !formikInnerRef.current?.values.idmetier ||
          !formikInnerRef.current?.values.idservice ||
          !formikInnerRef.current?.values.idstade ||
          !formikInnerRef.current?.values.idtypeCours)
      ) {
        showError(t(ETLCodes.VeuillezRemplirTousLesChamps));
        return;
      }
      setActionGlobaleLoading(true);
      await api.parcoursFormationChangeStatutMultipleDemande({
        ChangeStatutMultipleDto: {
          codeStatut,
          demandes: [+idDemande]
        }
      });
      setActionGlobaleLoading(false);
      history.push(`${ERoutes.apprenant}/${id}/parcoursFormation/${state}`);
    },
    [api, history, id, idDemande, state, t]
  );

  const onClose = React.useCallback(
    async (remarque: string = null) => {
      setInvalidationDialogOpen(false);
      setActionGlobaleLoading(true);
      await dashboardApi.dashboardInvaliderDemandeInscription({
        DemandeInscriptionInvalidationDto: {
          iddemandeInscription: data?.iddemandeInscription,
          remarqueInvalidation: remarque
        }
      });
      setActionGlobaleLoading(false);
      history.push(`${ERoutes.apprenant}/${id}/parcoursFormation/${state}`);
    },
    [dashboardApi, data?.iddemandeInscription, history, id, state]
  );

  const { user } = useAuth();
  const userCanValidate = React.useMemo(
    () =>
      user?.iduser !== data?.creationUser &&
      ((data?.priCanValidate && user?.iduser === data?.idpriApprenant) ||
        user?.delegationSignature ||
        user?.hopeMainLevelName === ELevelName.CALC),
    [
      data?.creationUser,
      data?.idpriApprenant,
      data?.priCanValidate,
      user?.delegationSignature,
      user?.hopeMainLevelName,
      user?.iduser
    ]
  );

  const SendButtons = React.useCallback(() => {
    if (!!parcours?.idstatutSortie) {
      return <></>;
    }
    const isApprentissage = rawStades?.find(s => +s.idValue === +idStade)?.keyValue === "AP";
    return (
      <>
        {[EStatutDemande.EV].includes(
          statutDemandeCode?.find(sdc => +sdc.idstatutDemande === +data?.idstatutDemande)?.code
        ) &&
          userCanValidate && (
            <>
              <Button
                style={{ marginLeft: "0.5rem" }}
                intent={Intent.DANGER}
                text={t(ETLCodes.Invalider)}
                icon="ban-circle"
                onClick={() => {
                  setInvalidationDialogOpen(true);
                }}
                loading={actionGlobaleLoading}
              />
              <Button
                style={{ marginLeft: "0.5rem" }}
                intent={Intent.SUCCESS}
                text={t(ETLCodes.Valider)}
                icon="tick-circle"
                onClick={async () => {
                  ChangeStatut(EStatutDemande.E, true);
                }}
                loading={actionGlobaleLoading}
              />
            </>
          )}
        {!data?.centreExterieur &&
          [EStatutDemande.EC, EStatutDemande.AE, EStatutDemande.P].includes(
            statutDemandeCode?.find(sdc => +sdc.idstatutDemande === +data?.idstatutDemande)?.code
          ) && (
            <Button
              style={{ marginLeft: "0.5rem" }}
              intent={Intent.PRIMARY}
              text={t(ETLCodes.EnvoyerEnValidation)}
              onClick={async () => {
                if (formikInnerRef.current?.isValid)
                  formikInnerRef.current?.setFieldValue("changeStatut", EStatutDemande.EV);
                formikInnerRef.current?.submitForm();
              }}
              loading={actionGlobaleLoading}
            />
          )}
        {[EStatutDemande.AE, EStatutDemande.P].includes(
          statutDemandeCode?.find(sdc => +sdc.idstatutDemande === +data?.idstatutDemande)?.code
        ) &&
          !data?.centreExterieur &&
          isApprentissage && (
            <Button
              style={{ marginLeft: "0.5rem" }}
              intent={Intent.PRIMARY}
              text={t(ETLCodes.EnvoyerAuxCentres)}
              onClick={() => {
                if (formikInnerRef.current?.isValid)
                  formikInnerRef.current?.setFieldValue("changeStatut", EStatutDemande.E);
                formikInnerRef.current?.submitForm();
              }}
              loading={actionGlobaleLoading}
            />
          )}
      </>
    );
  }, [
    ChangeStatut,
    actionGlobaleLoading,
    data?.centreExterieur,
    data?.idstatutDemande,
    idStade,
    parcours?.idstatutSortie,
    rawStades,
    statutDemandeCode,
    t,
    userCanValidate
  ]);

  const onCancelFunc = React.useCallback(() => {
    history.push(`${ERoutes.apprenant}/${id}/parcoursFormation/${state}`);
  }, [history, id, state]);

  const codeStatut = React.useMemo(
    () => statutDemandeCode?.find(sdc => sdc.idstatutDemande === data?.idstatutDemande)?.code,
    [data?.idstatutDemande, statutDemandeCode]
  );

  const tableState = useGridState<any>({
    serverMode: false,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { uploadDate: "DESC", fileName: "ASC" }
  });

  const { setData } = tableState;

  React.useEffect(() => {
    if (data?.fichiers) {
      setData(data.fichiers);
    }
  }, [data?.fichiers, setData]);

  const [uploadDialoOpen, setUploadDialoOpen] = React.useState(false);
  const [rowLoading, setRowLoading] = React.useState(null);

  const { showDialogPromise } = useDialog();
  const deleteFichier = React.useCallback(
    async (iddemandeInscriptionFichier: number) => {
      const result = await showDialogPromise({
        message: t(ETLCodes.DeleteConfirmationMessage)
      });

      if (result === "yes") {
        try {
          setRowLoading(iddemandeInscriptionFichier);
          await api.parcoursFormationDeleteDemandeInscriptionFichier({
            id: iddemandeInscriptionFichier
          });
          refreshData();
        } catch {
          showError(t(ETLCodes.ErrorWhileDeletingFile));
        } finally {
          setRowLoading(null);
        }
      }
    },
    [api, refreshData, showDialogPromise, t]
  );

  const downloadFichier = React.useCallback(
    async (iddemandeInscriptionFichier: number) => {
      try {
        setRowLoading(iddemandeInscriptionFichier);
        const file = await api.parcoursFormationDownloadDemandeInscriptionFichier({
          id: iddemandeInscriptionFichier
        });
        await exportFile(file);
      } catch {
        showError(t(ETLCodes.ErrorWhileDownloadingFile));
      } finally {
        setRowLoading(null);
      }
    },
    [api, t]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: DemandeInscriptionFichierGridDto) => (
          <ButtonContainer>
            <DownloadButton
              minimal={true}
              onClick={() => downloadFichier(row.iddemandeInscriptionFichier)}
              loading={rowLoading === row.iddemandeInscriptionFichier}
              disabled={!!rowLoading && rowLoading !== row.iddemandeInscriptionFichier}
            />
            <DeleteButton
              minimal={true}
              onDelete={() => deleteFichier(row.iddemandeInscriptionFichier)}
              loading={rowLoading === row.iddemandeInscriptionFichier}
              disabled={!!rowLoading && rowLoading !== row.iddemandeInscriptionFichier}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.NomFichier),
        fieldName: "fileName"
      },
      {
        header: () => t(ETLCodes.DateUpload),
        fieldName: "uploadDate"
      },
      {
        header: () => t(ETLCodes.UtilisateurUpload),
        fieldName: "creationUserName"
      }
    ],
    [deleteFichier, downloadFichier, rowLoading, t]
  );

  const formatDate = React.useCallback((date: Date) => {
    return format(parseJSON(addMinutes(date, date.getTimezoneOffset())), "dd/MM/yyyy HH:mm");
  }, []);

  const onCloseFichier = React.useCallback(
    (refresh?: boolean) => {
      setUploadDialoOpen(false);
      if (refresh) {
        refreshData();
      }
    },
    [refreshData]
  );

  const onAdd = React.useCallback(() => {
    setUploadDialoOpen(true);
  }, []);

  const clearCentre = React.useCallback((formik: FormikProps<DemandeInscriptionEditDto>) => {
    if (!!formik.values?.idcentre) {
      formik.setFieldValue("idcentre", null);
    }
  }, []);

  return (
    <>
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        loading={loading}
        saving={saving}
        formatDate="dd-MM-yyyy"
        minLabelWidth={200}
        editMode={editMode}
        editable={editable}
        onDelete={deleteItem}
        deleting={deleting}
        deleteBtnDisabled={[EStatutDemande.EA, EStatutDemande.SU, EStatutDemande.ER].includes(codeStatut)}
        onCancel={onCancelFunc}
        additionalRightButtons={() => <SendButtons />}
        watchChanges={{
          idStatut: setIdstatutSocial,
          idmetier: setIdmetier
        }}
        validationErrors={validationErrors}
        formikInnerRef={formikInnerRef}
      >
        <FieldGroup>
          <FieldGroup fieldsetProps={{ title: t(ETLCodes.DetailParcours) }} columns={2}>
            <FieldGroup>
              <FGCustomInput label={t(ETLCodes.DateEntree)}>
                {() => (
                  <div style={{ marginTop: 6 }}>
                    {!!parcours?.dateEntree ? format(parcours?.dateEntree, "dd-MM-yyyy") : ""}
                  </div>
                )}
              </FGCustomInput>
              <FGCustomInput label={t(ETLCodes.Statut)}>
                {() => <div style={{ marginTop: 6 }}>{categorie}</div>}
              </FGCustomInput>
              <FGCustomInput label={t(ETLCodes.SousStatut)}>
                {() => <div style={{ marginTop: 6 }}>{statut}</div>}
              </FGCustomInput>
            </FieldGroup>
            <FieldGroup>
              <FGWalterSelectInput
                label={t(ETLCodes.Service)}
                name="idservice"
                items={services}
                loading={sLoading}
                autoSelectIfOne
                disabled={services?.length <= 1}
                readonly={services?.length === 1}
                helperText={!services?.length && <WarningText text={t(ETLCodes.AucunServicePourCetApprenant)} />}
              />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup fieldsetProps={{ title: t(ETLCodes.DemandeInscription) }} columns={2}>
            <FieldGroup>
              <FGListen field="idmetier" onChanged={(_, formik) => formik.touched.idmetier && clearCentre(formik)} />
              <FGListen
                field="anneeAcademique"
                onChanged={(_, formik) => formik.touched.anneeAcademique && clearCentre(formik)}
              />
              <FGListen
                field="idtypeCours"
                onChanged={(_, formik) => formik.touched.idtypeCours && clearCentre(formik)}
              />
              <FGListen field="iddegre" onChanged={(_, formik) => formik.touched.iddegre && clearCentre(formik)} />
              <FGWalterSelectInput label={t(ETLCodes.Metier)} name="idmetier" items={metiers} loading={mLoading} />
              <FGWalterSelectInput label={t(ETLCodes.Annee)} name="anneeAcademique" items={annees} loading={aLoading} />
              <FGWalterSelectInput
                label={t(ETLCodes.TypeCours)}
                name="idtypeCours"
                items={typeCours}
                loading={tcLoading}
              />
              <FGWalterSelectInput label={t(ETLCodes.Degre)} name="iddegre" items={filteredDegres} loading={dLoading} />
              <FGWalterSelectInput
                label={t(ETLCodes.Stade)}
                name="idstade"
                items={stades}
                loading={stLoading}
                autoSelectIfOne
              />
              <FGCustomPanel>
                {ctx => <CentresDemandeSelect name="idcentre" values={ctx?.formik?.values} />}
              </FGCustomPanel>
              {codeStatut === EStatutDemande.EC && (
                <FGTextAreaInput label={t(ETLCodes.RemarqueInvalidation)} name="remarqueInvalidation" readonly />
              )}
            </FieldGroup>
            <FieldGroup>
              <FGCustomPanel>
                {ctx =>
                  !!ctx.formik.values?.remarqueRefus && (
                    <FGTextAreaInput label={t(ETLCodes.RemarqueRefusCentre)} name="remarqueRefus" readonly />
                  )
                }
              </FGCustomPanel>
            </FieldGroup>
          </FieldGroup>
          {validationErrors["dto"]?.length > 0 && (
            <ErrorTextContainer>
              <ErrorText text={validationErrors["dto"][0]} />
            </ErrorTextContainer>
          )}
        </FieldGroup>
        <FieldGroup
          visible={+idDemande > 0}
          fieldsetProps={{
            title: t(ETLCodes.Documents),
            rightElement: (
              <AddButton
                onClick={e => {
                  e.stopPropagation();
                  onAdd();
                }}
                text={t(ETLCodes.General_Add)}
                intent={Intent.PRIMARY}
              />
            )
          }}
        >
          <DataTable
            dateFormat="dd/MM/yyyy HH:mm"
            formatDate={formatDate}
            tableState={tableState}
            loading={loading}
            columns={columns}
          ></DataTable>
          {uploadDialoOpen && (
            <DemandeInscriptionDocumentUploadDialog
              onClose={onCloseFichier}
              dialogOpen={uploadDialoOpen}
              iddemandeInscription={+idDemande}
            />
          )}
        </FieldGroup>
      </SmallFormGenerator>
      <StyledDialog title={t(ETLCodes.InvaliderDemande)} isOpen={invalidationDialogOpen} onClose={() => onClose()}>
        <div className={Classes.DIALOG_BODY}>
          <SmallFormGenerator
            initialValues={{ remarqueInvalidation: data?.remarqueInvalidation }}
            onSubmit={d => onClose(d.remarqueInvalidation)}
            editMode={true}
            onCancel={onClose}
            showDeleteButton={false}
            enableDirtyCheck={false}
          >
            <FieldGroup>
              <FGTextAreaInput name="remarqueInvalidation" label={t(ETLCodes.Remarque)} />
            </FieldGroup>
          </SmallFormGenerator>
        </div>
      </StyledDialog>
    </>
  );
};
