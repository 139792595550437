import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ConseillerPedagogiqueApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { ConseillerPedagogiqueDetailPage } from "./ConseillerPedagogiqueDetailPage";
import { ConseillerPedagogiqueMetiersLiesSwitch } from "./ConseillerPedagogiqueMetiersLiesSwitch";

interface IConseillerPedagogiqueItemPageProps {}

export const ConseillerPedagogiqueItemPage: React.FunctionComponent<IConseillerPedagogiqueItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const api = useApiService(ConseillerPedagogiqueApi);
  const [displayName] = useApiEffect(() => api.conseillerPedagogiqueGetDisplayName({ id: id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.ConseillersPedagogiques), route: ERoutes.conseillerPedagogique },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsDirectionTerritoriale"
        onChange={newTabId => history.push(`${ERoutes.conseillerPedagogique}/${id}/${newTabId}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.conseillerPedagogique}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<ConseillerPedagogiqueDetailPage idConseillerPedagogique={id} editMode={state === "edit"} />}
        />
        <Tab
          id="metiersLies"
          title={t(ETLCodes.MetiersLies)}
          panel={<ConseillerPedagogiqueMetiersLiesSwitch />}
          disabled={+id <= 0}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
