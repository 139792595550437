/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbDispenseDetailDto
 */
export interface FcbDispenseDetailDto {
    /**
     * 
     * @type {boolean}
     * @memberof FcbDispenseDetailDto
     */
    applyToAll?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof FcbDispenseDetailDto
     */
    classeMatiereIds?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDispenseDetailDto
     */
    idinscription?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbDispenseDetailDto
     */
    idclasse?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbDispenseDetailDto
     */
    idmatiere?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbDispenseDetailDto
     */
    dateDemande?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbDispenseDetailDto
     */
    dateAcceptation?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDispenseDetailDto
     */
    idclasseMatiere?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbDispenseDetailDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbDispenseDetailDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDispenseDetailDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbDispenseDetailDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDispenseDetailDto
     */
    idtypeDispense?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDispenseDetailDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDispenseDetailDto
     */
    dispenseId?: number;
}

/**
 * Check if a given object implements the FcbDispenseDetailDto interface.
 */
export function instanceOfFcbDispenseDetailDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbDispenseDetailDtoFromJSON(json: any): FcbDispenseDetailDto {
    return FcbDispenseDetailDtoFromJSONTyped(json, false);
}

export function FcbDispenseDetailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbDispenseDetailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applyToAll': !exists(json, 'applyToAll') ? undefined : json['applyToAll'],
        'classeMatiereIds': !exists(json, 'classeMatiereIds') ? undefined : json['classeMatiereIds'],
        'idinscription': !exists(json, 'idinscription') ? undefined : json['idinscription'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'idmatiere': !exists(json, 'idmatiere') ? undefined : json['idmatiere'],
        'dateDemande': !exists(json, 'dateDemande') ? undefined : (json['dateDemande'] === null ? null : new Date(json['dateDemande'])),
        'dateAcceptation': !exists(json, 'dateAcceptation') ? undefined : (json['dateAcceptation'] === null ? null : new Date(json['dateAcceptation'])),
        'idclasseMatiere': !exists(json, 'idclasseMatiere') ? undefined : json['idclasseMatiere'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'idtypeDispense': !exists(json, 'idtypeDispense') ? undefined : json['idtypeDispense'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'dispenseId': !exists(json, 'dispenseId') ? undefined : json['dispenseId'],
    };
}

export function FcbDispenseDetailDtoToJSON(value?: FcbDispenseDetailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applyToAll': value.applyToAll,
        'classeMatiereIds': value.classeMatiereIds,
        'idinscription': value.idinscription,
        'idclasse': value.idclasse,
        'idmatiere': value.idmatiere,
        'dateDemande': value.dateDemande === undefined ? undefined : (value.dateDemande === null ? null : value.dateDemande.toISOString()),
        'dateAcceptation': value.dateAcceptation === undefined ? undefined : (value.dateAcceptation === null ? null : value.dateAcceptation.toISOString()),
        'idclasseMatiere': value.idclasseMatiere,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'idtypeDispense': value.idtypeDispense,
        'libelle': value.libelle,
        'dispenseId': value.dispenseId,
    };
}

