import { Intent } from "@blueprintjs/core";
import { isAfter, isBefore, isSameDay } from "date-fns";
import {
  ButtonContainer,
  DataTable,
  FieldSet,
  IDataTableColumn,
  showSuccess,
  useGridState,
  useSearchApi
} from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { InscriptionSuspensionDetailDialog } from ".";
import {
  InscriptionApi,
  InscriptionSuspensionApi,
  InscriptionSuspensionDto,
  InscriptionSuspensionDtoFromJSON,
  InscriptionSuspensionSearchDto
} from "../../../../../../api";
import { AddButton, DeleteButton, EditButton } from "../../../../../../components";
import { useDialog } from "../../../../../../contexts";
import { useApiService, useManageError, useTheme, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

export interface IInscriptionSuspensionListProps {}

export const InscriptionSuspensionList: React.FunctionComponent<IInscriptionSuspensionListProps> = props => {
  const { t } = useTl();
  const { ifapmeSide } = useTheme();
  const { inscriptionId } = useParams<{ inscriptionId: string; state: string }>();
  const isHope = React.useMemo(() => ifapmeSide === "hope", [ifapmeSide]);
  const idinscription = React.useMemo(() => +inscriptionId, [inscriptionId]);
  const [currentSuspension, setCurrentSuspension] = React.useState<InscriptionSuspensionDto>(null);
  const [deleteLoading, setDeleteLoading] = React.useState(null);
  const api = useApiService(InscriptionSuspensionApi);
  const inscriptionApi = useApiService(InscriptionApi);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { dateDebut: "DESC" }
  });

  const { totalCount, data } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: InscriptionSuspensionSearchDto) => {
      sObj.idinscription = idinscription;
      return api.inscriptionSuspensionBaseSearch({ InscriptionSuspensionSearchDto: sObj });
    },
    [api, idinscription]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const onEditClick = React.useCallback((dto: InscriptionSuspensionDto) => {
    setCurrentSuspension(dto);
  }, []);

  const { showDialogPromise } = useDialog();
  const { manageError } = useManageError();
  const onDeleteClick = React.useCallback(
    async (dto: InscriptionSuspensionDto) => {
      const result = await showDialogPromise({
        title: t(ETLCodes.DeleteDialogTitle),
        message: t(ETLCodes.DeleteConfirmationMessage)
      });

      if (result !== "yes") return;

      try {
        setDeleteLoading(dto.idinscriptionSuspension);
        await api.inscriptionSuspensionDeleteInscriptionSuspension({
          idinscriptionSuspension: dto.idinscriptionSuspension
        });
        showSuccess(t(ETLCodes.SuspensionInscriptionSupprimee));
      } catch (e) {
        manageError(e);
      } finally {
        search();
        setDeleteLoading(null);
      }
    },
    [api, manageError, search, showDialogPromise, t]
  );

  const fetchStatut = React.useCallback(async () => {
    if (!idinscription) return null;
    return await inscriptionApi.inscriptionGetStatut({ id: idinscription });
  }, [inscriptionApi, idinscription]);
  const { data: statut, isFetching: statutLoading } = useQuery(["inscription-statut", idinscription], fetchStatut);
  const disabled = React.useMemo(() => !statut || !["En cours", "Suspendue"].includes(statut), [statut]);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: InscriptionSuspensionDto) => (
          <ButtonContainer>
            <EditButton
              minimal={true}
              onClick={() => onEditClick(row)}
              disabled={!!deleteLoading || disabled || isHope}
            />
            <DeleteButton
              minimal={true}
              onDelete={() => onDeleteClick(row)}
              loading={row.idinscriptionSuspension === deleteLoading}
              disabled={(!!deleteLoading && row.idinscriptionSuspension !== deleteLoading) || disabled || isHope}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.DateDebut),
        fieldName: "dateDebut"
      },
      {
        header: () => t(ETLCodes.DateFin),
        fieldName: "dateFin"
      },
      {
        header: () => t(ETLCodes.Remarque),
        fieldName: "remarque"
      }
    ],
    [deleteLoading, disabled, isHope, onDeleteClick, onEditClick, t]
  );

  const onAddClick = React.useCallback(() => {
    setCurrentSuspension(InscriptionSuspensionDtoFromJSON({ idinscription, idinscriptionSuspension: 0 }));
  }, [idinscription]);

  const onClose = React.useCallback(
    (toSave: InscriptionSuspensionDto) => {
      if (!!toSave) {
        search();
      }
      setCurrentSuspension(null);
    },
    [search]
  );

  const alreadySuspendedText = React.useMemo(
    () =>
      data &&
      data.some(
        s =>
          (isBefore(s.dateDebut, new Date()) || isSameDay(s.dateDebut, new Date())) &&
          (isSameDay(s.dateFin, new Date()) || isAfter(s.dateFin, new Date()))
      )
        ? t(ETLCodes.InscriptionAlreadySuspended)
        : null,
    [data, t]
  );

  const soonSuspendedText = React.useMemo(
    () => (data && data.some(s => isAfter(s.dateDebut, new Date())) ? t(ETLCodes.InscriptionSoonSuspended) : null),
    [data, t]
  );

  return (
    <>
      <FieldSet
        title={t(ETLCodes.TableResults, { count: totalCount })}
        rightElement={
          !disabled &&
          !isHope && (
            <AddButton
              tooltipText={alreadySuspendedText ?? soonSuspendedText}
              loading={statutLoading}
              onClick={!alreadySuspendedText && !soonSuspendedText ? onAddClick : null}
              text={t(ETLCodes.General_Add)}
              intent={Intent.PRIMARY}
            />
          )
        }
      >
        <DataTable
          dateFormat="dd/MM/yyyy"
          tableState={tableState}
          loading={loading || statutLoading}
          columns={columns}
          filterMode="OnEnter"
        ></DataTable>
      </FieldSet>
      {!!currentSuspension && (
        <InscriptionSuspensionDetailDialog
          dialogOpen={!!currentSuspension}
          currentSuspension={currentSuspension}
          onClose={onClose}
        />
      )}
    </>
  );
};
