export const versions = {
  version: "1.0.183",
  revision: "9c4f179a8",
  branch: "HEAD",
  gitInfo: {
    dirty: true,
    raw: "v1.0.183-4627-g9c4f179a8-dirty",
    hash: "g9c4f179a8",
    distance: 4627,
    tag: "v1.0.183",
    semver: {
      options: { loose: false, includePrerelease: false },
      loose: false,
      raw: "v1.0.183",
      major: 1,
      minor: 0,
      patch: 183,
      prerelease: [],
      build: [],
      version: "1.0.183"
    },
    suffix: "4627-g9c4f179a8-dirty",
    semverString: "1.0.183+4627.g9c4f179a8.dirty"
  }
};
