import { chunk } from "lodash";
import { FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";

import { FcbVisiteDtoFromJSON, VisiteApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { FGWalterCheckboxInput, SmallFormGenerator } from "../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";

export interface IVisiteHoraireProps {
  idVisite: number;
}

export const VisiteHoraire: React.FunctionComponent<IVisiteHoraireProps> = ({ idVisite }) => {
  const [horaires, loadingHoraires] = useReferential(a => a.referentialGetPlageHoraireVisite(), false, []);
  const { tUnsafe } = useTl();
  const api = useApiService(VisiteApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () => (idVisite <= 0 ? FcbVisiteDtoFromJSON({}) : api.visiteGet({ id: idVisite })),
    saveApiFn: d => api.visiteSave({ FcbVisiteDto: d }),
    onSavedRoute: d => `${ERoutes.visiteExterieure}/${d.idvisite}/horaire/edit`,
    deleteApiFn: d => {},
    onDeletedRoute: () => `${ERoutes.visiteExterieure}/${idVisite}/information/edit`
  });

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={true}
      loading={loading}
      onCancel={() => history.push(ERoutes.diplome)}
      onDelete={deleteItem}
      showDeleteButton={false}
      saving={saving}
      deleting={deleting}
    >
      <FieldGroup columns={3}>
        {data &&
          !loadingHoraires &&
          chunk(horaires, 4).map(options => (
            <FieldGroup>
              {options.map(option => (
                <FGWalterCheckboxInput label={tUnsafe(option.label)} name={`${option.value}`} />
              ))}
            </FieldGroup>
          ))}
      </FieldGroup>
    </SmallFormGenerator>
  );
};
