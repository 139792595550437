/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ContactDoublonGridDtoPaginatedResults,
  ContactDoublonSearchDto,
  ContactEditDto,
  ContactGridDtoPaginatedResults,
  ContactLieuDoublonGridDtoPaginatedResults,
  ContactLieuDoublonSearch,
  ContactLieuEditDto,
  ContactLieuFormationGridDtoPaginatedResults,
  ContactLieuFormationSearchDto,
  ContactLieuGridDtoPaginatedResults,
  ContactLieuSearch,
  ContactSearch,
  ContactSiegeDoublonGridDtoPaginatedResults,
  ContactSiegeDoublonSearch,
  ContactSiegeEditDto,
  ContactSiegeGridDtoPaginatedResults,
  ContactSiegeSearch,
  ContactSiegeSocialGridDtoPaginatedResults,
  ContactSiegeSocialSearchDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
} from '../models/index';
import {
    ContactDoublonGridDtoPaginatedResultsFromJSON,
    ContactDoublonGridDtoPaginatedResultsToJSON,
    ContactDoublonSearchDtoFromJSON,
    ContactDoublonSearchDtoToJSON,
    ContactEditDtoFromJSON,
    ContactEditDtoToJSON,
    ContactGridDtoPaginatedResultsFromJSON,
    ContactGridDtoPaginatedResultsToJSON,
    ContactLieuDoublonGridDtoPaginatedResultsFromJSON,
    ContactLieuDoublonGridDtoPaginatedResultsToJSON,
    ContactLieuDoublonSearchFromJSON,
    ContactLieuDoublonSearchToJSON,
    ContactLieuEditDtoFromJSON,
    ContactLieuEditDtoToJSON,
    ContactLieuFormationGridDtoPaginatedResultsFromJSON,
    ContactLieuFormationGridDtoPaginatedResultsToJSON,
    ContactLieuFormationSearchDtoFromJSON,
    ContactLieuFormationSearchDtoToJSON,
    ContactLieuGridDtoPaginatedResultsFromJSON,
    ContactLieuGridDtoPaginatedResultsToJSON,
    ContactLieuSearchFromJSON,
    ContactLieuSearchToJSON,
    ContactSearchFromJSON,
    ContactSearchToJSON,
    ContactSiegeDoublonGridDtoPaginatedResultsFromJSON,
    ContactSiegeDoublonGridDtoPaginatedResultsToJSON,
    ContactSiegeDoublonSearchFromJSON,
    ContactSiegeDoublonSearchToJSON,
    ContactSiegeEditDtoFromJSON,
    ContactSiegeEditDtoToJSON,
    ContactSiegeGridDtoPaginatedResultsFromJSON,
    ContactSiegeGridDtoPaginatedResultsToJSON,
    ContactSiegeSearchFromJSON,
    ContactSiegeSearchToJSON,
    ContactSiegeSocialGridDtoPaginatedResultsFromJSON,
    ContactSiegeSocialGridDtoPaginatedResultsToJSON,
    ContactSiegeSocialSearchDtoFromJSON,
    ContactSiegeSocialSearchDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface ContactBaseSearchRequest {
    ContactSearch?: ContactSearch;
}

export interface ContactDeleteContactLieuRequest {
    idcontactLieu?: number;
}

export interface ContactDeleteContactSiegeRequest {
    idcontactSiege?: number;
}

export interface ContactGetContactRequest {
    idcontact?: number;
}

export interface ContactGetContactLieuRequest {
    idcontactLieu?: number;
}

export interface ContactGetContactSiegeRequest {
    idcontactSiege?: number;
}

export interface ContactGetDisplayNameRequest {
    id?: number;
}

export interface ContactGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ContactGetSelectItemsRequest {
    searchField: string;
}

export interface ContactSaveContactRequest {
    ContactEditDto?: ContactEditDto;
}

export interface ContactSaveContactLieuRequest {
    ContactLieuEditDto?: ContactLieuEditDto;
}

export interface ContactSaveContactSiegeRequest {
    ContactSiegeEditDto?: ContactSiegeEditDto;
}

export interface ContactSearchContactLieuRequest {
    ContactLieuSearch?: ContactLieuSearch;
}

export interface ContactSearchContactLieuFormationRequest {
    ContactLieuFormationSearchDto?: ContactLieuFormationSearchDto;
}

export interface ContactSearchContactSiegeRequest {
    ContactSiegeSearch?: ContactSiegeSearch;
}

export interface ContactSearchContactSiegeSocialRequest {
    ContactSiegeSocialSearchDto?: ContactSiegeSocialSearchDto;
}

export interface ContactSearchDoublonsRequest {
    ContactDoublonSearchDto?: ContactDoublonSearchDto;
}

export interface ContactSearchDoublonsLieuRequest {
    ContactLieuDoublonSearch?: ContactLieuDoublonSearch;
}

export interface ContactSearchDoublonsSiegeRequest {
    ContactSiegeDoublonSearch?: ContactSiegeDoublonSearch;
}

/**
 * 
 */
export class ContactApi extends runtime.BaseAPI {

    /**
     */
    async contactBaseSearchRaw(requestParameters: ContactBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContactGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contact/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactSearchToJSON(requestParameters.ContactSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async contactBaseSearch(requestParameters: ContactBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContactGridDtoPaginatedResults> {
        const response = await this.contactBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contactDeleteContactLieuRaw(requestParameters: ContactDeleteContactLieuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idcontactLieu !== undefined) {
            queryParameters['idcontactLieu'] = requestParameters.idcontactLieu;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contact/DeleteContactLieu`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async contactDeleteContactLieu(requestParameters: ContactDeleteContactLieuRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.contactDeleteContactLieuRaw(requestParameters, initOverrides);
    }

    /**
     */
    async contactDeleteContactSiegeRaw(requestParameters: ContactDeleteContactSiegeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idcontactSiege !== undefined) {
            queryParameters['idcontactSiege'] = requestParameters.idcontactSiege;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contact/DeleteContactSiege`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async contactDeleteContactSiege(requestParameters: ContactDeleteContactSiegeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.contactDeleteContactSiegeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async contactGetContactRaw(requestParameters: ContactGetContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContactEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.idcontact !== undefined) {
            queryParameters['idcontact'] = requestParameters.idcontact;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contact`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async contactGetContact(requestParameters: ContactGetContactRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContactEditDto> {
        const response = await this.contactGetContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contactGetContactLieuRaw(requestParameters: ContactGetContactLieuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContactLieuEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.idcontactLieu !== undefined) {
            queryParameters['idcontactLieu'] = requestParameters.idcontactLieu;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contact/GetContactLieu`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactLieuEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async contactGetContactLieu(requestParameters: ContactGetContactLieuRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContactLieuEditDto> {
        const response = await this.contactGetContactLieuRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contactGetContactSiegeRaw(requestParameters: ContactGetContactSiegeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContactSiegeEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.idcontactSiege !== undefined) {
            queryParameters['idcontactSiege'] = requestParameters.idcontactSiege;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contact/GetContactSiege`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactSiegeEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async contactGetContactSiege(requestParameters: ContactGetContactSiegeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContactSiegeEditDto> {
        const response = await this.contactGetContactSiegeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contactGetDisplayNameRaw(requestParameters: ContactGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contact/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async contactGetDisplayName(requestParameters: ContactGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.contactGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contactGetSearchCriteriasRaw(requestParameters: ContactGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contact/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async contactGetSearchCriterias(requestParameters: ContactGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.contactGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contactGetSelectItemsRaw(requestParameters: ContactGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling contactGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contact/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async contactGetSelectItems(requestParameters: ContactGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.contactGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contactSaveContactRaw(requestParameters: ContactSaveContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContactEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contact`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactEditDtoToJSON(requestParameters.ContactEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async contactSaveContact(requestParameters: ContactSaveContactRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContactEditDto> {
        const response = await this.contactSaveContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contactSaveContactLieuRaw(requestParameters: ContactSaveContactLieuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContactLieuEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contact/SaveContactLieu`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactLieuEditDtoToJSON(requestParameters.ContactLieuEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactLieuEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async contactSaveContactLieu(requestParameters: ContactSaveContactLieuRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContactLieuEditDto> {
        const response = await this.contactSaveContactLieuRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contactSaveContactSiegeRaw(requestParameters: ContactSaveContactSiegeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContactSiegeEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contact/SaveContactSiege`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactSiegeEditDtoToJSON(requestParameters.ContactSiegeEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactSiegeEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async contactSaveContactSiege(requestParameters: ContactSaveContactSiegeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContactSiegeEditDto> {
        const response = await this.contactSaveContactSiegeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contactSearchContactLieuRaw(requestParameters: ContactSearchContactLieuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContactLieuGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contact/SearchContactLieu`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactLieuSearchToJSON(requestParameters.ContactLieuSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactLieuGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async contactSearchContactLieu(requestParameters: ContactSearchContactLieuRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContactLieuGridDtoPaginatedResults> {
        const response = await this.contactSearchContactLieuRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contactSearchContactLieuFormationRaw(requestParameters: ContactSearchContactLieuFormationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContactLieuFormationGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contact/SearchContactLieuFormation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactLieuFormationSearchDtoToJSON(requestParameters.ContactLieuFormationSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactLieuFormationGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async contactSearchContactLieuFormation(requestParameters: ContactSearchContactLieuFormationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContactLieuFormationGridDtoPaginatedResults> {
        const response = await this.contactSearchContactLieuFormationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contactSearchContactSiegeRaw(requestParameters: ContactSearchContactSiegeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContactSiegeGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contact/SearchContactSiege`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactSiegeSearchToJSON(requestParameters.ContactSiegeSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactSiegeGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async contactSearchContactSiege(requestParameters: ContactSearchContactSiegeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContactSiegeGridDtoPaginatedResults> {
        const response = await this.contactSearchContactSiegeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contactSearchContactSiegeSocialRaw(requestParameters: ContactSearchContactSiegeSocialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContactSiegeSocialGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contact/SearchContactSiegeSocial`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactSiegeSocialSearchDtoToJSON(requestParameters.ContactSiegeSocialSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactSiegeSocialGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async contactSearchContactSiegeSocial(requestParameters: ContactSearchContactSiegeSocialRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContactSiegeSocialGridDtoPaginatedResults> {
        const response = await this.contactSearchContactSiegeSocialRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contactSearchDoublonsRaw(requestParameters: ContactSearchDoublonsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContactDoublonGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contact/SearchContactDoublon`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactDoublonSearchDtoToJSON(requestParameters.ContactDoublonSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactDoublonGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async contactSearchDoublons(requestParameters: ContactSearchDoublonsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContactDoublonGridDtoPaginatedResults> {
        const response = await this.contactSearchDoublonsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contactSearchDoublonsLieuRaw(requestParameters: ContactSearchDoublonsLieuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContactLieuDoublonGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contact/SearchContactDoublonLieu`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactLieuDoublonSearchToJSON(requestParameters.ContactLieuDoublonSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactLieuDoublonGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async contactSearchDoublonsLieu(requestParameters: ContactSearchDoublonsLieuRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContactLieuDoublonGridDtoPaginatedResults> {
        const response = await this.contactSearchDoublonsLieuRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contactSearchDoublonsSiegeRaw(requestParameters: ContactSearchDoublonsSiegeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContactSiegeDoublonGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contact/SearchContactDoublonSiege`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactSiegeDoublonSearchToJSON(requestParameters.ContactSiegeDoublonSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactSiegeDoublonGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async contactSearchDoublonsSiege(requestParameters: ContactSearchDoublonsSiegeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContactSiegeDoublonGridDtoPaginatedResults> {
        const response = await this.contactSearchDoublonsSiegeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
