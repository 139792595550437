import { Intent } from "@blueprintjs/core";
import { DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";

import {
  ServiceTutelleUserLieApi,
  ServiceUserLieEditDto,
  ServiceUserLieGridDto,
  ServiceUserLieSearch
} from "../../../../../api";
import { AddButton, BooleanColumn, EditButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { ServiceTutelleUserLieDialog } from "./ServiceTutelleUserLieDialog";

interface IServiceTutelleUserLiePageProps {}

export const ServiceTutelleUserLiePage: React.FunctionComponent<IServiceTutelleUserLiePageProps> = () => {
  const { t } = useTl();
  const api = useApiService(ServiceTutelleUserLieApi);
  const { id } = useParams<{ id: string; tab: string; state: string }>();

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { nom: "ASC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: ServiceUserLieSearch) => {
      sObj.idService = +id;
      return api.serviceTutelleUserLieBaseSearch({ ServiceUserLieSearch: sObj });
    },
    [api, id]
  );

  const { loading, search } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogInitalData, setDialogInitialData] = React.useState<ServiceUserLieEditDto>({});
  const onEditClick = React.useCallback(
    (d?: ServiceUserLieGridDto) => {
      if (d) {
        setDialogInitialData({ idUser: d.idUser, idServiceTutelle: +id, calcFrom: d.calcFrom, calcTo: d.calcTo });
      } else {
        setDialogInitialData({ idServiceTutelle: +id });
      }

      setDialogOpen(true);
    },
    [id]
  );

  const onCloseDialog = React.useCallback(
    (refresh: boolean) => {
      setDialogOpen(false);
      setDialogInitialData({});
      if (refresh === true) {
        search();
      }
    },
    [search]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ServiceUserLieGridDto) => <EditButton minimal={true} onClick={() => onEditClick(row)} />
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.Login),
        fieldName: "login"
      },
      {
        header: () => t(ETLCodes.Profil),
        fieldName: "level"
      },
      {
        header: () => t(ETLCodes.Actif),
        fieldName: "actif",
        alignment: "center",
        render: (row: ServiceUserLieGridDto) => <BooleanColumn value={row.actif} />
      },
      {
        header: () => t(ETLCodes.Debut),
        fieldName: "calcFrom"
      },
      {
        header: () => t(ETLCodes.Fin),
        fieldName: "calcTo"
      }
    ],
    [onEditClick, t]
  );

  return (
    <FieldSet
      title={t(ETLCodes.TableResults, { count: totalCount })}
      rightElement={
        <AddButton
          onClick={() => onEditClick()}
          text={t(ETLCodes.General_Add)}
          intent={Intent.PRIMARY}
          minimal={false}
        />
      }
    >
      <DataTable
        dateFormat="dd-MM-yyyy"
        tableState={tableState}
        loading={loading}
        columns={columns}
        filterMode="OnEnter"
      />
      {dialogOpen && (
        <ServiceTutelleUserLieDialog dialogOpen={dialogOpen} initialData={dialogInitalData} onClose={onCloseDialog} />
      )}
    </FieldSet>
  );
};
