/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbUniteActiviteDto,
  FcbUniteActiviteDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
  UniteActiviteSearch,
} from '../models/index';
import {
    FcbUniteActiviteDtoFromJSON,
    FcbUniteActiviteDtoToJSON,
    FcbUniteActiviteDtoPaginatedResultsFromJSON,
    FcbUniteActiviteDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    UniteActiviteSearchFromJSON,
    UniteActiviteSearchToJSON,
} from '../models/index';

export interface UniteActiviteBaseSearchRequest {
    UniteActiviteSearch?: UniteActiviteSearch;
}

export interface UniteActiviteDeleteRequest {
    id?: number;
}

export interface UniteActiviteGetRequest {
    id?: number;
}

export interface UniteActiviteGetDisplayNameRequest {
    id?: number;
}

export interface UniteActiviteGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface UniteActiviteGetSelectItemsRequest {
    searchField: string;
}

export interface UniteActiviteSaveRequest {
    FcbUniteActiviteDto?: FcbUniteActiviteDto;
}

/**
 * 
 */
export class UniteActiviteApi extends runtime.BaseAPI {

    /**
     */
    async uniteActiviteBaseSearchRaw(requestParameters: UniteActiviteBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbUniteActiviteDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/UniteActivite/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UniteActiviteSearchToJSON(requestParameters.UniteActiviteSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbUniteActiviteDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async uniteActiviteBaseSearch(requestParameters: UniteActiviteBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbUniteActiviteDtoPaginatedResults> {
        const response = await this.uniteActiviteBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uniteActiviteDeleteRaw(requestParameters: UniteActiviteDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/UniteActivite`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async uniteActiviteDelete(requestParameters: UniteActiviteDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.uniteActiviteDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uniteActiviteGetRaw(requestParameters: UniteActiviteGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbUniteActiviteDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/UniteActivite`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbUniteActiviteDtoFromJSON(jsonValue));
    }

    /**
     */
    async uniteActiviteGet(requestParameters: UniteActiviteGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbUniteActiviteDto> {
        const response = await this.uniteActiviteGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uniteActiviteGetDisplayNameRaw(requestParameters: UniteActiviteGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/UniteActivite/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async uniteActiviteGetDisplayName(requestParameters: UniteActiviteGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.uniteActiviteGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uniteActiviteGetSearchCriteriasRaw(requestParameters: UniteActiviteGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/UniteActivite/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async uniteActiviteGetSearchCriterias(requestParameters: UniteActiviteGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.uniteActiviteGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uniteActiviteGetSelectItemsRaw(requestParameters: UniteActiviteGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling uniteActiviteGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/UniteActivite/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async uniteActiviteGetSelectItems(requestParameters: UniteActiviteGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.uniteActiviteGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uniteActiviteSaveRaw(requestParameters: UniteActiviteSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbUniteActiviteDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/UniteActivite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbUniteActiviteDtoToJSON(requestParameters.FcbUniteActiviteDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbUniteActiviteDtoFromJSON(jsonValue));
    }

    /**
     */
    async uniteActiviteSave(requestParameters: UniteActiviteSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbUniteActiviteDto> {
        const response = await this.uniteActiviteSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
