import { Classes, Colors, Icon, InputGroup } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import styled from "styled-components";

export interface ITableCheckboxEditorProps {
  initialValue: boolean;
  onValueChanged: (value: boolean) => void;
  readonly?: boolean;
}

const ViewInput = styled(InputGroup)<{ colorcheck: string }>`
  display: flex;
  justify-content: center;
  width: 30px;
  & .${Classes.ICON} {
    color: ${p => p.colorcheck};
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

export const TableCheckboxEditor: React.FunctionComponent<ITableCheckboxEditorProps> = ({
  initialValue,
  onValueChanged,
  readonly = false
}) => {
  const [currentValue, setCurrentValue] = React.useState<boolean>();

  React.useEffect(() => {
    setCurrentValue(initialValue);
  }, [initialValue]);

  const onChange = React.useCallback(
    async (e: any) => {
      const editedValue = !currentValue;
      if (editedValue !== currentValue) {
        setCurrentValue(editedValue);
        onValueChanged(editedValue);
      }
    },
    [currentValue, onValueChanged]
  );

  return (
    <Container>
      {readonly ? (
        <ViewInput
          large={false}
          leftIcon={currentValue ? IconNames.TICK : IconNames.CROSS}
          readOnly={true}
          style={{ boxShadow: "none", backgroundColor: "transparent" }}
          colorcheck={currentValue ? Colors.GREEN5 : Colors.RED5}
        />
      ) : (
        <StyledIcon icon={currentValue ? "tick-circle" : "circle"} onClick={onChange} />
      )}
    </Container>
  );
};
