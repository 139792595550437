import { FGCheckboxInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { ClasseApi, FcbCopyClasseDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterDateMaskInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface ICopieClassePageProps {
  idClasse: number;
  state: string;
}

export const CopieClassePage: React.FunctionComponent<ICopieClassePageProps> = ({ idClasse, state }) => {
  const { t } = useTl();
  const api = useApiService(ClasseApi);
  const history = useHistory();
  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({});
  }, []);

  const { data, saving, saveItem } = useCrudApi({
    getApiFn: () => {
      return FcbCopyClasseDtoFromJSON({ idClasse: idClasse });
    },
    saveApiFn: async d => api.classeCopyClasseAsync({ FcbCopyClasseDto: d }),
    onSavedRoute: c => `${ERoutes.classe}/${c.idClasse}/detail/${state}`,
    deleteApiFn: () => {},
    onDeletedRoute: () => ERoutes.classe
  });

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={true}
      validationSchema={FormSchema}
      loading={false}
      onCancel={() => history.push(ERoutes.classe)}
      showDeleteButton={false}
      saving={saving}
    >
      <FieldGroup>
        <FGWalterDateMaskInput name="dateCours" label={t(ETLCodes.PremierCours)} />
        <FGTextInput name="nomClasse" label={t(ETLCodes.NomNouvelleClasse)} maxLength={50} />
        <FGCheckboxInput name="withContract" label={t(ETLCodes.CopierContratFormateur)} />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
