import { FGListen, FGNumberInput, FieldGroup, FieldSet } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { FcbFinancementClasseDtoFromJSON, FinancementClasseApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterCheckboxInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

export interface IClasseAgrementLocalisationFinanceDetailPageProps {}

export const ClasseAgrementLocalisationFinanceDetailPage: React.FunctionComponent<IClasseAgrementLocalisationFinanceDetailPageProps> = () => {
  const { t } = useTl();
  const { id, idFinancement, state } = useParams<{ id: string; idFinancement: string; state: string }>();
  const api = useApiService(FinancementClasseApi);
  const history = useHistory();
  const path = React.useMemo(() => `${ERoutes.classe}/${+id}/agrement`, [id]);
  const [typeProjetRequest, setTypeProjetRequest] = React.useState({ id: 0 });
  const [ProjetFinancement, pfLoading] = useReferential(a => a.referentialGetProjetFinancement(), true, []);
  const [ProjetFinancementById, pfidLoading] = useReferential(
    a => a.referentialGetProjetFinancementById(typeProjetRequest),
    true,
    [typeProjetRequest]
  );
  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idprojetFinancement: Yup.number().required(t(ETLCodes.Required))
    });
  }, [t]);

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idFinancement <= 0
        ? FcbFinancementClasseDtoFromJSON({ idclasse: +id })
        : api.financementClasseGet({ id: +idFinancement }),
    saveApiFn: d => api.financementClasseSave({ FcbFinancementClasseDto: d }),
    onSavedRoute: d => `${path}/${d.idfinancementClasse}/${state}`,
    deleteApiFn: d => api.financementClasseDelete({ id: d.idfinancementClasse }),
    onDeletedRoute: () => path
  });

  const localisationBloquee = React.useMemo(() => data?.localisationBloquee, [data]);
  const agrementBloque = React.useMemo(() => data?.agrementBloquee, [data]);
  const existingItem = React.useMemo(() => +id > 0, [id]);

  return (
    <FieldSet title={t(ETLCodes.Financement)}>
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        showColons
        editMode={state === "edit"}
        minLabelWidth={180}
        labelAlignment="right"
        formatDate="dd/MM/yyyy"
        validationSchema={FormSchema}
        inline
        boldLabel
        loading={loading}
        onCancel={() => history.push(`${ERoutes.classe}/${+id}/agrement/edit`)}
        onDelete={deleteItem}
        showDeleteButton={+idFinancement > 0}
        saving={saving}
        deleting={deleting}
      >
        <FGListen
          field="idprojetFinancement"
          onChanged={(value, formik) => {
            setTypeProjetRequest({
              id: value
            });
          }}
        />
        <FieldGroup columns={2}>
          <FGWalterSelectInput
            name="idprojetFinancement"
            label={t(ETLCodes.ProjetFinancement)}
            items={ProjetFinancement}
            loading={pfLoading}
          />
          <FGWalterSelectInput
            name="idprojetCofinancement"
            label={t(ETLCodes.ProjetCoFinancement)}
            items={ProjetFinancementById}
            loading={pfidLoading}
          />
          <FGNumberInput
            name="heureS1"
            label={t(ETLCodes.S1Loc)}
            readonly={(localisationBloquee || agrementBloque) && existingItem}
          />
          <FGNumberInput
            name="heureS2"
            label={t(ETLCodes.S2Loc)}
            readonly={(localisationBloquee || agrementBloque) && existingItem}
          />
          <FGNumberInput name="totalLoc" label={t(ETLCodes.TotalLoc)} readonly helperText="" />
          <FGWalterCheckboxInput name="localise" label={t(ETLCodes.Localise)} disabled={localisationBloquee} />
          <FGNumberInput name="heureS1Agrement" label={t(ETLCodes.S1Agr)} />
          <FGNumberInput name="heureS2Agrement" label={t(ETLCodes.S2Agr)} />
          <FGNumberInput name="totalAgrement" label={t(ETLCodes.TotalAgr)} readonly />
          <FGWalterCheckboxInput name="agree" label={t(ETLCodes.Agree)} disabled={agrementBloque} />
          <FGNumberInput name="reelHoursS1" label={t(ETLCodes.HReelleS1)} readonly />
          <FGNumberInput name="reelHoursS2" label={t(ETLCodes.HReelleS2)} readonly />
          <FGNumberInput name="reelTotalAgrement" label={t(ETLCodes.TotalHReel)} readonly />
        </FieldGroup>
      </SmallFormGenerator>
    </FieldSet>
  );
};
