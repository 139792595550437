import { Loading, useApiEffect } from "nsitools-react";
import * as React from "react";

import { DeliberationBulletinDetail } from ".";
import { BulletinApprenantTypeApi } from "../../../../../api";
import { useEventsContext } from "../../../../../contexts";
import { useApiService } from "../../../../../hooks";

export interface IDeliberationBulletinDetailListProps {
  idApprenant: number;
  idTypeCours: string;
  anneeScolaire: string;
}

export const DELIBERATION_SAVED = "DELIBERATION_SAVED";

export const DeliberationBulletinDetailList: React.FunctionComponent<IDeliberationBulletinDetailListProps> = ({
  idApprenant,
  anneeScolaire,
  idTypeCours
}) => {
  const api = useApiService(BulletinApprenantTypeApi);
  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();

  const getBulletinType = React.useCallback(() => {
    return api.bulletinApprenantTypeGetBulletinType({
      idApprenant: idApprenant,
      anneeScolaire: anneeScolaire,
      idTypeCours: idTypeCours
    });
  }, [anneeScolaire, api, idApprenant, idTypeCours]);

  const [data, loading, , refresh] = useApiEffect(getBulletinType, [idApprenant, anneeScolaire, idTypeCours]);

  React.useEffect(() => {
    subscribeToEvent("DELIBERATION_REFRESH", refresh);
    return () => {
      unsubscribeEvent("DELIBERATION_REFRESH", refresh);
    };
  }, [refresh, subscribeToEvent, unsubscribeEvent]);

  return (
    <Loading isLoading={loading}>
      {data &&
        data.map(d => (
          <DeliberationBulletinDetail
            bulletin={d}
            key={`${d.idbulletinApprenantType}_${d.isSecondeSession ? "2" : "1"}`}
          ></DeliberationBulletinDetail>
        ))}
    </Loading>
  );
};
