import { Intent } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { FcbFormateurSuiviGridDto, FormateurSuiviApi, FormateurSuiviSearch } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { AddButton, EditButton, ViewButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IFormateurSuiviListPageProps {}

export const FormateurSuiviListPage: React.FunctionComponent<IFormateurSuiviListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(FormateurSuiviApi);
  const { id } = useParams<{ id: string }>();
  const validateUrl = React.useCallback((url: string) => {
    if (url.includes("http")) {
      return url;
    }
    return `http://${url}`;
  }, []);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [10],
    pageSize: 10,
    sortKeys: { conseiller: "ASC" }
  });

  const searchFunction = React.useCallback(
    (sObj?: FormateurSuiviSearch) => {
      sObj.idFormateur = +id;
      return api.formateurSuiviBaseSearch({ FormateurSuiviSearch: sObj });
    },
    [api, id]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbFormateurSuiviGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.formateur}/${id}/suivi/${row.idsuivi}/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.formateur}/${id}/suivi/${row.idsuivi}/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Conseiller),
        fieldName: "conseiller"
      },
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "centre"
      },
      {
        header: () => t(ETLCodes.Date),
        fieldName: "date"
      },
      {
        header: () => t(ETLCodes.Avis),
        fieldName: "avis"
      },
      {
        header: () => t(ETLCodes.Remarque),
        fieldName: "remarque"
      },
      {
        header: () => t(ETLCodes.URL),
        fieldName: "url",
        render: (row: FcbFormateurSuiviGridDto) =>
          row.url && (
            <a target="_blank" rel="noopener noreferrer" href={validateUrl(row.url)}>
              {t(ETLCodes.Voir)}
            </a>
          )
      }
    ],
    [history, id, t, validateUrl]
  );
  return (
    <FieldSet
      title={t(ETLCodes.Suivi)}
      rightElement={
        <AddButton
          onClick={e => {
            e.stopPropagation();
            history.push(`${ERoutes.formateur}/${id}/suivi/0/edit`);
          }}
          text={t(ETLCodes.General_Add)}
          intent={Intent.PRIMARY}
        />
      }
    >
      <DataTable
        dateFormat="dd-MM-yyyy"
        tableState={tableState}
        loading={loading}
        columns={columns}
        filterMode="OnEnter"
      />
    </FieldSet>
  );
};
