/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FcbLieuxCoursGridDto } from './FcbLieuxCoursGridDto';
import {
    FcbLieuxCoursGridDtoFromJSON,
    FcbLieuxCoursGridDtoFromJSONTyped,
    FcbLieuxCoursGridDtoToJSON,
} from './FcbLieuxCoursGridDto';
import type { IAggregateResult } from './IAggregateResult';
import {
    IAggregateResultFromJSON,
    IAggregateResultFromJSONTyped,
    IAggregateResultToJSON,
} from './IAggregateResult';

/**
 * 
 * @export
 * @interface FcbLieuxCoursGridDtoPaginatedResults
 */
export interface FcbLieuxCoursGridDtoPaginatedResults {
    /**
     * 
     * @type {number}
     * @memberof FcbLieuxCoursGridDtoPaginatedResults
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<FcbLieuxCoursGridDto>}
     * @memberof FcbLieuxCoursGridDtoPaginatedResults
     */
    results?: Array<FcbLieuxCoursGridDto> | null;
    /**
     * 
     * @type {Array<IAggregateResult>}
     * @memberof FcbLieuxCoursGridDtoPaginatedResults
     */
    aggregateResults?: Array<IAggregateResult> | null;
}

/**
 * Check if a given object implements the FcbLieuxCoursGridDtoPaginatedResults interface.
 */
export function instanceOfFcbLieuxCoursGridDtoPaginatedResults(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbLieuxCoursGridDtoPaginatedResultsFromJSON(json: any): FcbLieuxCoursGridDtoPaginatedResults {
    return FcbLieuxCoursGridDtoPaginatedResultsFromJSONTyped(json, false);
}

export function FcbLieuxCoursGridDtoPaginatedResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbLieuxCoursGridDtoPaginatedResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'results': !exists(json, 'results') ? undefined : (json['results'] === null ? null : (json['results'] as Array<any>).map(FcbLieuxCoursGridDtoFromJSON)),
        'aggregateResults': !exists(json, 'aggregateResults') ? undefined : (json['aggregateResults'] === null ? null : (json['aggregateResults'] as Array<any>).map(IAggregateResultFromJSON)),
    };
}

export function FcbLieuxCoursGridDtoPaginatedResultsToJSON(value?: FcbLieuxCoursGridDtoPaginatedResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'results': value.results === undefined ? undefined : (value.results === null ? null : (value.results as Array<any>).map(FcbLieuxCoursGridDtoToJSON)),
        'aggregateResults': value.aggregateResults === undefined ? undefined : (value.aggregateResults === null ? null : (value.aggregateResults as Array<any>).map(IAggregateResultToJSON)),
    };
}

