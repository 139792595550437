import * as React from "react";

import { ApprenantBulletingRecapitulatifList } from "./ApprenantBulletinRecapitulatifList";
import { ApprenantBulletinSwitch } from "./bulletins";

interface IApprenantResultatTypeProps {
  apprenantId: number;
}

export const ApprenantResultatType: React.FunctionComponent<IApprenantResultatTypeProps> = ({ apprenantId }) => {
  return (
    <>
      <ApprenantBulletingRecapitulatifList />
      <ApprenantBulletinSwitch />
    </>
  );
};
