/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FcbLocalGridDto } from './FcbLocalGridDto';
import {
    FcbLocalGridDtoFromJSON,
    FcbLocalGridDtoFromJSONTyped,
    FcbLocalGridDtoToJSON,
} from './FcbLocalGridDto';
import type { IAggregateResult } from './IAggregateResult';
import {
    IAggregateResultFromJSON,
    IAggregateResultFromJSONTyped,
    IAggregateResultToJSON,
} from './IAggregateResult';

/**
 * 
 * @export
 * @interface FcbLocalGridDtoPaginatedResults
 */
export interface FcbLocalGridDtoPaginatedResults {
    /**
     * 
     * @type {number}
     * @memberof FcbLocalGridDtoPaginatedResults
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<FcbLocalGridDto>}
     * @memberof FcbLocalGridDtoPaginatedResults
     */
    results?: Array<FcbLocalGridDto> | null;
    /**
     * 
     * @type {Array<IAggregateResult>}
     * @memberof FcbLocalGridDtoPaginatedResults
     */
    aggregateResults?: Array<IAggregateResult> | null;
}

/**
 * Check if a given object implements the FcbLocalGridDtoPaginatedResults interface.
 */
export function instanceOfFcbLocalGridDtoPaginatedResults(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbLocalGridDtoPaginatedResultsFromJSON(json: any): FcbLocalGridDtoPaginatedResults {
    return FcbLocalGridDtoPaginatedResultsFromJSONTyped(json, false);
}

export function FcbLocalGridDtoPaginatedResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbLocalGridDtoPaginatedResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'results': !exists(json, 'results') ? undefined : (json['results'] === null ? null : (json['results'] as Array<any>).map(FcbLocalGridDtoFromJSON)),
        'aggregateResults': !exists(json, 'aggregateResults') ? undefined : (json['aggregateResults'] === null ? null : (json['aggregateResults'] as Array<any>).map(IAggregateResultFromJSON)),
    };
}

export function FcbLocalGridDtoPaginatedResultsToJSON(value?: FcbLocalGridDtoPaginatedResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'results': value.results === undefined ? undefined : (value.results === null ? null : (value.results as Array<any>).map(FcbLocalGridDtoToJSON)),
        'aggregateResults': value.aggregateResults === undefined ? undefined : (value.aggregateResults === null ? null : (value.aggregateResults as Array<any>).map(IAggregateResultToJSON)),
    };
}

