import { Checkbox, NonIdealState, Spinner } from "@blueprintjs/core";
import { FieldGroup, FieldSet, IDataTableColumn } from "nsitools-react";
import * as React from "react";

import {
  ClasseMiseAJourMasseApi,
  ClasseMiseAJourMasseSearch,
  FcbClasseMiseAJourDtoFromJSON,
  FcbRechercheClasseMiseAJourMasseGridDto
} from "../../../api";
import { ERoutes } from "../../../AppRouter";
import {
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  PageBase,
  SearchTablePage,
  SmallFormGenerator
} from "../../../components";
import { useApiService, useCrudApi, useTl } from "../../../hooks";
import { useReferential } from "../../../hooks/useReferential";
import { ETLCodes } from "../../../locales";

export interface IClasseMiseAJourPageProps {}

export const ClasseMiseAJourPage: React.FunctionComponent<IClasseMiseAJourPageProps> = () => {
  const { t } = useTl();
  const api = useApiService(ClasseMiseAJourMasseApi);

  const [allIds, setAllIds] = React.useState<number[]>([]);
  const [selectedIds, setSelectedIds] = React.useState<number[]>([]);
  const [searched, setSearched] = React.useState(false);
  const [idsLoading, setIdsLoading] = React.useState(false);

  const lastSearchObject = React.useRef<ClasseMiseAJourMasseSearch>();

  const hasKey = React.useCallback(
    (key: number) => {
      return selectedIds.includes(key);
    },
    [selectedIds]
  );

  const toggleSelection = React.useCallback(
    (id: number) => {
      if (!selectedIds.includes(id)) {
        setSelectedIds(ids => [...ids, id]);
      } else {
        setSelectedIds(ids => ids.filter(i => i !== id));
      }
    },
    [selectedIds]
  );

  const allSelected = React.useMemo(() => allIds.every(id => selectedIds.indexOf(id) >= 0) && allIds.length > 0, [
    allIds,
    selectedIds
  ]);

  const toggleSelectAll = React.useCallback(() => {
    setIdsLoading(true);
    api
      .classeMiseAJourMasseSearchClasseIds({
        ClasseMiseAJourMasseSearch: { ...lastSearchObject.current, forceSkip: 0, forceTake: 9999999 }
      })
      .then(ids => {
        setAllIds(ids);
        if (allSelected) {
          setSelectedIds([]);
        } else {
          setSelectedIds(ids);
        }
        setIdsLoading(false);
      });
  }, [allSelected, api]);

  const [statutAgrement, saLoading] = useReferential(
    a => a.referentialGetStatutLocalisationAgrement({ isLocalisation: false }),
    true,
    []
  );

  const { data, saving, saveItem } = useCrudApi({
    getApiFn: () => {
      return FcbClasseMiseAJourDtoFromJSON({ idClasses: [] });
    },
    saveApiFn: d => {
      d.idClasses = selectedIds;
      return api.classeMiseAJourMasseSave({ FcbClasseMiseAJourDto: d });
    },
    onSavedRoute: () => `${ERoutes.classeMiseAJourMasse}`
  });

  const searchFunc = React.useCallback(
    (nextSearch?: ClasseMiseAJourMasseSearch) => {
      setSearched(true);

      if (JSON.stringify(nextSearch) !== JSON.stringify(lastSearchObject.current)) {
        lastSearchObject.current = nextSearch;
      }
      return api.classeMiseAJourMasseBaseSearch({ ClasseMiseAJourMasseSearch: nextSearch });
    },
    [api]
  );

  const CheckboxHeader = React.useCallback(
    () => (idsLoading ? <Spinner size={20} /> : <Checkbox checked={allSelected} onClick={toggleSelectAll} />),
    [allSelected, idsLoading, toggleSelectAll]
  );

  const columns = React.useMemo<IDataTableColumn[]>(() => {
    return [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        header: () => <CheckboxHeader />,
        render: (item: FcbRechercheClasseMiseAJourMasseGridDto) => (
          <Checkbox
            checked={hasKey(item.idclasse)}
            onClick={() => {
              toggleSelection(item.idclasse);
            }}
          />
        )
      },
      {
        fieldName: "code",
        header: () => t(ETLCodes.Code)
      },
      {
        fieldName: "nomClasse",
        header: () => t(ETLCodes.NomClasse)
      },
      {
        fieldName: "anneeAcademique",
        header: () => t(ETLCodes.AnneeScolaire)
      },
      {
        fieldName: "lieucours",
        header: () => t(ETLCodes.LieuxDeCours)
      },
      {
        fieldName: "statutLocalisationAgrement",
        header: () => t(ETLCodes.StatutAgrement),
        autoFitContent: true
      },
      {
        fieldName: "dateValidation",
        header: () => t(ETLCodes.DateAgrement)
      }
      // {
      //   fieldName: "dateCours",
      //   header: () => t(ETLCodes.PremierCours)
      // },
      // {
      //   fieldName: "idconseillerAgrement",
      //   header: () => t(ETLCodes.Conseiller)
      // }
    ];
  }, [CheckboxHeader, hasKey, t, toggleSelection]);

  const getCriteria = React.useCallback(async () => {
    const criterias = api.classeMiseAJourMasseGetSearchCriterias({ includeListsValues: true });
    return criterias;
  }, [api]);

  return (
    <PageBase breadCrumbs={[{ text: t(ETLCodes.ClassesMiseAJour), route: ERoutes.classeMiseAJourMasse }]} withCard>
      <FieldSet title={t(ETLCodes.ClassesMiseAJour)}>
        {data && (
          <SmallFormGenerator
            initialValues={data}
            onSubmit={saveItem}
            showColons
            editMode
            saving={saving}
            formatDate="dd-MM-yyyy"
          >
            <FieldGroup columns={3}>
              <FGWalterCheckboxInput name="bloque" label={t(ETLCodes.ResultatsBloques)} />
              <FGWalterSelectInput
                name="idstatutAgrement"
                label={t(ETLCodes.StatutAgrement)}
                items={statutAgrement}
                loading={saLoading}
              />
              <FGWalterDateMaskInput name="dateAgrement" label={t(ETLCodes.DateAgrement)} />
            </FieldGroup>
          </SmallFormGenerator>
        )}
        <SearchTablePage
          withCard={false}
          columns={columns}
          title={ETLCodes.Classes}
          getCriteriasFunction={getCriteria}
          searchFunction={searchFunc}
          sortKeys={{ code: "ASC" }}
          defaultCriterias={[]}
          initialSearch={false}
          searchStateInitialSearch={false}
          renderNoData={
            <NonIdealState
              icon="search"
              title={t(searched ? ETLCodes.GeneralNoData : ETLCodes.VeuillezEffectuerUneRecherche)}
            />
          }
        />
      </FieldSet>
    </PageBase>
  );
};
