import * as React from "react";
import { useParams } from "react-router-dom";

import { DemandeInscriptionCreate } from "./DemandeInscriptionCreate";
import { DemandeInscriptionSave } from "./DemandeInscriptionSave";

export interface IDemandeInscriptionProps {}

export const DemandeInscription: React.FunctionComponent<IDemandeInscriptionProps> = () => {
  const { idDemande } = useParams<{
    id: string;
    state: string;
    idParcoursFormation: string;
    idDemande: string;
  }>();

  return <>{+idDemande > 0 ? <DemandeInscriptionSave /> : <DemandeInscriptionCreate />}</>;
};
