import { parseISO } from "date-fns";
import { showError } from "nsitools-react";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { SuiviApprenantForm } from "..";
import {
  DashboardAbsenceDtoFromJSON,
  DashboardApi,
  ETypeDernierAvertissement,
  SuiviApi,
  SuiviHopeEditDto
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { useAuth, useDialog } from "../../../../../contexts";
import { useApiService, useCrudApi, useTabMessage, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import { exportFile } from "../../../../../utils";
import { Guid } from "../../../../../utils/guid";

export interface SuiviApprenantDetailPageProps {}

export const SuiviApprenantDetailPage: React.FunctionComponent<SuiviApprenantDetailPageProps> = props => {
  const { t } = useTl();
  const api = useApiService(SuiviApi);
  const dashboardApi = useApiService(DashboardApi);
  const { user } = useAuth();
  const history = useHistory();
  const { id, state, suiviId } = useParams<{
    id: string;
    tab: string;
    state: string;
    suiviId: string;
  }>();
  const editMode = React.useMemo(() => state === "edit", [state]);
  const { search } = useLocation();
  const cfs = React.useMemo(() => new URLSearchParams(search).get("cfs"), [search]);
  const { messageValue, clearMessageValue } = useTabMessage(cfs);
  const [absencesRow, setAbsencesRow] = React.useState<any>();
  React.useEffect(() => {
    if (!!messageValue) {
      setAbsencesRow(messageValue);
      clearMessageValue();
    }
  }, [clearMessageValue, messageValue]);

  const [, , , modes] = useReferential(a => a.referentialGetModes(), true, []);

  const identifier = React.useMemo(() => Guid.newGuid(), []);
  const { openTabThenSendMessage } = useTabMessage(identifier);
  const { data, loading, saveItem, saving, deleteItem, deleting, validationErrors, refresh } = useCrudApi<
    SuiviHopeEditDto
  >(
    React.useMemo(
      () => ({
        getApiFn: async () => {
          const suivi = await api.suiviGetSuiviHope({
            id: +suiviId,
            idapprenant: absencesRow?.idapprenant ?? +id,
            typeAvertissement: absencesRow?.typeAvertissement
          });
          if (+suiviId <= 0) {
            if (!!absencesRow) {
              suivi.dateSuivi = parseISO(absencesRow.dateSuivi);
            } else {
              suivi.idsuivi = +suiviId;
              suivi.iduserService = user.iduser;
              suivi.encodeur = user.nom + " " + user.prenom;
              suivi.dateSuivi = new Date();
            }
          }
          return suivi;
        },
        closeCfs: false,
        saveApiFn: d => api.suiviSaveSuiviHope({ SuiviHopeEditDto: d }),
        onSaved:
          !!absencesRow &&
          (async d => {
            const mode = modes.find(m => +m.idValue === d.idmode)?.keyValue;
            if (mode === "MAIL" && absencesRow.typeAvertissement === ETypeDernierAvertissement.Convocation)
              absencesRow.typeAvertissement = ETypeDernierAvertissement.Avertissement;

            if (mode === "COURRIER" && absencesRow.typeAvertissement === ETypeDernierAvertissement.Avertissement)
              absencesRow.typeAvertissement = ETypeDernierAvertissement.Convocation;

            var res = await dashboardApi.dashboardMarquerAbsenceVue({
              DashboardAbsenceDto: DashboardAbsenceDtoFromJSON(absencesRow)
            });
            if (absencesRow.typeAvertissement === ETypeDernierAvertissement.Avertissement) {
              const el = document.createElement("a");
              el.setAttribute("href", `mailto:${res.emailIfapme}?cc=${res.emailPerso}`);
              el.click();
              el.remove();
            } else {
              openTabThenSendMessage(`/#${ERoutes.SSRSReports}/${res.idrapportHope}`, [res.idapprenant]);
            }
            history.push(`${ERoutes.apprenant}/${id}/suiviApprenant/${d.idsuivi}/${state}/hope`);
          }),
        onSavedRoute:
          !absencesRow &&
          (d => {
            clearMessageValue();
            return `${ERoutes.apprenant}/${id}/suiviApprenant/${d.idsuivi}/${state}/hope`;
          }),
        getDeps: [suiviId, absencesRow],
        deleteApiFn: d => api.suiviDeleteSuiviHope({ id: d.idsuivi }),
        onDeletedRoute: () => `${ERoutes.apprenant}/${id}/suiviApprenant/${state}`
      }),
      [
        absencesRow,
        suiviId,
        api,
        id,
        user.iduser,
        user.nom,
        user.prenom,
        modes,
        dashboardApi,
        history,
        state,
        openTabThenSendMessage,
        clearMessageValue
      ]
    )
  );

  const onCancel = React.useCallback(() => history.push(`${ERoutes.apprenant}/${id}/suiviApprenant/${state}/hope`), [
    history,
    id,
    state
  ]);

  const [rowLoading, setRowLoading] = React.useState(null);
  const { showDialogPromise } = useDialog();
  const deleteFichier = React.useCallback(
    async (idsuiviFichier: number) => {
      const result = await showDialogPromise({
        message: t(ETLCodes.DeleteConfirmationMessage)
      });

      if (result === "yes") {
        try {
          setRowLoading(idsuiviFichier);
          await api.suiviDeleteSuiviFichier({
            id: idsuiviFichier
          });
          refresh();
        } catch {
          showError(t(ETLCodes.ErrorWhileDeletingFile));
        } finally {
          setRowLoading(null);
        }
      }
    },
    [api, refresh, showDialogPromise, t]
  );

  const downloadFichier = React.useCallback(
    async (idsuiviFichier: number) => {
      try {
        setRowLoading(idsuiviFichier);
        const file = await api.suiviDownloadSuiviFichier({
          id: idsuiviFichier
        });
        await exportFile(file);
      } catch {
        showError(t(ETLCodes.ErrorWhileDownloadingFile));
      } finally {
        setRowLoading(null);
      }
    },
    [api, t]
  );

  return (
    <SuiviApprenantForm
      data={data}
      suiviId={+suiviId}
      saveItem={saveItem}
      loading={loading}
      saving={saving}
      editMode={editMode && !data?.fromSmile}
      deleteItem={deleteItem}
      deleting={deleting}
      onCancel={onCancel}
      validationErrors={validationErrors}
      fieldsetTitle={t(ETLCodes.Suivi)}
      deleteFichier={deleteFichier}
      downloadFichier={downloadFichier}
      rowLoading={rowLoading}
      refreshData={refresh}
    />
  );
};
