/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { DateSearch } from './DateSearch';
import {
    DateSearchFromJSON,
    DateSearchFromJSONTyped,
    DateSearchToJSON,
} from './DateSearch';
import type { TextSearch } from './TextSearch';
import {
    TextSearchFromJSON,
    TextSearchFromJSONTyped,
    TextSearchToJSON,
} from './TextSearch';

/**
 * 
 * @export
 * @interface JustificatifAbsenceSearch
 */
export interface JustificatifAbsenceSearch {
    /**
     * 
     * @type {number}
     * @memberof JustificatifAbsenceSearch
     */
    idApprenant?: number;
    /**
     * 
     * @type {TextSearch}
     * @memberof JustificatifAbsenceSearch
     */
    remarque?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof JustificatifAbsenceSearch
     */
    anneeScolaire?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof JustificatifAbsenceSearch
     */
    genre?: TextSearch;
    /**
     * 
     * @type {DateSearch}
     * @memberof JustificatifAbsenceSearch
     */
    dateDebut?: DateSearch;
    /**
     * 
     * @type {Array<string>}
     * @memberof JustificatifAbsenceSearch
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof JustificatifAbsenceSearch
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof JustificatifAbsenceSearch
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof JustificatifAbsenceSearch
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof JustificatifAbsenceSearch
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof JustificatifAbsenceSearch
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the JustificatifAbsenceSearch interface.
 */
export function instanceOfJustificatifAbsenceSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JustificatifAbsenceSearchFromJSON(json: any): JustificatifAbsenceSearch {
    return JustificatifAbsenceSearchFromJSONTyped(json, false);
}

export function JustificatifAbsenceSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): JustificatifAbsenceSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idApprenant': !exists(json, 'idApprenant') ? undefined : json['idApprenant'],
        'remarque': !exists(json, 'remarque') ? undefined : TextSearchFromJSON(json['remarque']),
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : TextSearchFromJSON(json['anneeScolaire']),
        'genre': !exists(json, 'genre') ? undefined : TextSearchFromJSON(json['genre']),
        'dateDebut': !exists(json, 'dateDebut') ? undefined : DateSearchFromJSON(json['dateDebut']),
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function JustificatifAbsenceSearchToJSON(value?: JustificatifAbsenceSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idApprenant': value.idApprenant,
        'remarque': TextSearchToJSON(value.remarque),
        'anneeScolaire': TextSearchToJSON(value.anneeScolaire),
        'genre': TextSearchToJSON(value.genre),
        'dateDebut': DateSearchToJSON(value.dateDebut),
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

