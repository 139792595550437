/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AcquisScolaireGridDtoPaginatedResults,
  AcquisScolaireSearchDto,
  AnneeFormationSuivieEditDto,
  AnneeFormationSuivieFichierDto,
  AnneeFormationSuivieFichierUploadDto,
  AnneeFormationSuivieIfapmeEditDto,
  AnneeFormationSuivieIfapmeFichierDto,
  AnneeFormationSuivieIfapmeFichierUploadDto,
  DiplomeCertificationEditDto,
  DiplomeCertificationFichierDto,
  DiplomeCertificationFichierUploadDto,
  DiplomeCertificationIfapmeEditDto,
  DiplomeCertificationIfapmeFichierDto,
  DiplomeCertificationIfapmeFichierUploadDto,
  FileDownloadDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
} from '../models/index';
import {
    AcquisScolaireGridDtoPaginatedResultsFromJSON,
    AcquisScolaireGridDtoPaginatedResultsToJSON,
    AcquisScolaireSearchDtoFromJSON,
    AcquisScolaireSearchDtoToJSON,
    AnneeFormationSuivieEditDtoFromJSON,
    AnneeFormationSuivieEditDtoToJSON,
    AnneeFormationSuivieFichierDtoFromJSON,
    AnneeFormationSuivieFichierDtoToJSON,
    AnneeFormationSuivieFichierUploadDtoFromJSON,
    AnneeFormationSuivieFichierUploadDtoToJSON,
    AnneeFormationSuivieIfapmeEditDtoFromJSON,
    AnneeFormationSuivieIfapmeEditDtoToJSON,
    AnneeFormationSuivieIfapmeFichierDtoFromJSON,
    AnneeFormationSuivieIfapmeFichierDtoToJSON,
    AnneeFormationSuivieIfapmeFichierUploadDtoFromJSON,
    AnneeFormationSuivieIfapmeFichierUploadDtoToJSON,
    DiplomeCertificationEditDtoFromJSON,
    DiplomeCertificationEditDtoToJSON,
    DiplomeCertificationFichierDtoFromJSON,
    DiplomeCertificationFichierDtoToJSON,
    DiplomeCertificationFichierUploadDtoFromJSON,
    DiplomeCertificationFichierUploadDtoToJSON,
    DiplomeCertificationIfapmeEditDtoFromJSON,
    DiplomeCertificationIfapmeEditDtoToJSON,
    DiplomeCertificationIfapmeFichierDtoFromJSON,
    DiplomeCertificationIfapmeFichierDtoToJSON,
    DiplomeCertificationIfapmeFichierUploadDtoFromJSON,
    DiplomeCertificationIfapmeFichierUploadDtoToJSON,
    FileDownloadDtoFromJSON,
    FileDownloadDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface AcquisScolaireHopeBaseSearchRequest {
    AcquisScolaireSearchDto?: AcquisScolaireSearchDto;
}

export interface AcquisScolaireHopeDeleteAnneeFormationSuivieRequest {
    id?: number;
}

export interface AcquisScolaireHopeDeleteAnneeFormationSuivieFichierRequest {
    id?: number;
}

export interface AcquisScolaireHopeDeleteAnneeFormationSuivieIfapmeRequest {
    id?: number;
}

export interface AcquisScolaireHopeDeleteAnneeFormationSuivieIfapmeFichierRequest {
    id?: number;
}

export interface AcquisScolaireHopeDeleteDiplomeCertificationRequest {
    id?: number;
}

export interface AcquisScolaireHopeDeleteDiplomeCertificationFichierRequest {
    id?: number;
}

export interface AcquisScolaireHopeDeleteDiplomeCertificationIfapmeRequest {
    id?: number;
}

export interface AcquisScolaireHopeDeleteDiplomeCertificationIfapmeFichierRequest {
    id?: number;
}

export interface AcquisScolaireHopeDownloadAnneeFormationSuivieFichierRequest {
    id?: number;
}

export interface AcquisScolaireHopeDownloadAnneeFormationSuivieIfapmeFichierRequest {
    id?: number;
}

export interface AcquisScolaireHopeDownloadDiplomeCertificationFichierRequest {
    id?: number;
}

export interface AcquisScolaireHopeDownloadDiplomeCertificationIfapmeFichierRequest {
    id?: number;
}

export interface AcquisScolaireHopeGetAnneeFormationSuivieRequest {
    id?: number;
}

export interface AcquisScolaireHopeGetAnneeFormationSuivieFichierRequest {
    id?: number;
}

export interface AcquisScolaireHopeGetAnneeFormationSuivieIfapmeRequest {
    id?: number;
}

export interface AcquisScolaireHopeGetAnneeFormationSuivieIfapmeFichierRequest {
    id?: number;
}

export interface AcquisScolaireHopeGetDiplomeCertificationRequest {
    id?: number;
}

export interface AcquisScolaireHopeGetDiplomeCertificationFichierRequest {
    id?: number;
}

export interface AcquisScolaireHopeGetDiplomeCertificationIfapmeRequest {
    id?: number;
}

export interface AcquisScolaireHopeGetDiplomeCertificationIfapmeFichierRequest {
    id?: number;
}

export interface AcquisScolaireHopeGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface AcquisScolaireHopeGetSelectItemsRequest {
    searchField: string;
}

export interface AcquisScolaireHopeSaveAnneeFormationSuivieRequest {
    AnneeFormationSuivieEditDto?: AnneeFormationSuivieEditDto;
}

export interface AcquisScolaireHopeSaveAnneeFormationSuivieFichierRequest {
    AnneeFormationSuivieFichierDto?: AnneeFormationSuivieFichierDto;
}

export interface AcquisScolaireHopeSaveAnneeFormationSuivieIfapmeRequest {
    AnneeFormationSuivieIfapmeEditDto?: AnneeFormationSuivieIfapmeEditDto;
}

export interface AcquisScolaireHopeSaveAnneeFormationSuivieIfapmeFichierRequest {
    AnneeFormationSuivieIfapmeFichierDto?: AnneeFormationSuivieIfapmeFichierDto;
}

export interface AcquisScolaireHopeSaveDiplomeCertificationRequest {
    DiplomeCertificationEditDto?: DiplomeCertificationEditDto;
}

export interface AcquisScolaireHopeSaveDiplomeCertificationFichierRequest {
    DiplomeCertificationFichierDto?: DiplomeCertificationFichierDto;
}

export interface AcquisScolaireHopeSaveDiplomeCertificationIfapmeFichierRequest {
    DiplomeCertificationIfapmeFichierDto?: DiplomeCertificationIfapmeFichierDto;
}

export interface AcquisScolaireHopeSaveDiplomeCertificationIfapmeeRequest {
    DiplomeCertificationIfapmeEditDto?: DiplomeCertificationIfapmeEditDto;
}

export interface AcquisScolaireHopeUploadAnneeFormationSuivieFichierRequest {
    AnneeFormationSuivieFichierUploadDto?: AnneeFormationSuivieFichierUploadDto;
}

export interface AcquisScolaireHopeUploadAnneeFormationSuivieIfapmeFichierRequest {
    AnneeFormationSuivieIfapmeFichierUploadDto?: AnneeFormationSuivieIfapmeFichierUploadDto;
}

export interface AcquisScolaireHopeUploadDiplomeCertificationFichierRequest {
    DiplomeCertificationFichierUploadDto?: DiplomeCertificationFichierUploadDto;
}

export interface AcquisScolaireHopeUploadDiplomeCertificationIfapmeFichierRequest {
    DiplomeCertificationIfapmeFichierUploadDto?: DiplomeCertificationIfapmeFichierUploadDto;
}

/**
 * 
 */
export class AcquisScolaireHopeApi extends runtime.BaseAPI {

    /**
     */
    async acquisScolaireHopeBaseSearchRaw(requestParameters: AcquisScolaireHopeBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AcquisScolaireGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AcquisScolaireSearchDtoToJSON(requestParameters.AcquisScolaireSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AcquisScolaireGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeBaseSearch(requestParameters: AcquisScolaireHopeBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AcquisScolaireGridDtoPaginatedResults> {
        const response = await this.acquisScolaireHopeBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeDeleteAnneeFormationSuivieRaw(requestParameters: AcquisScolaireHopeDeleteAnneeFormationSuivieRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/DeleteAnneeFormationSuivie`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async acquisScolaireHopeDeleteAnneeFormationSuivie(requestParameters: AcquisScolaireHopeDeleteAnneeFormationSuivieRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acquisScolaireHopeDeleteAnneeFormationSuivieRaw(requestParameters, initOverrides);
    }

    /**
     */
    async acquisScolaireHopeDeleteAnneeFormationSuivieFichierRaw(requestParameters: AcquisScolaireHopeDeleteAnneeFormationSuivieFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/DeleteAnneeFormationSuivieFichier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async acquisScolaireHopeDeleteAnneeFormationSuivieFichier(requestParameters: AcquisScolaireHopeDeleteAnneeFormationSuivieFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acquisScolaireHopeDeleteAnneeFormationSuivieFichierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async acquisScolaireHopeDeleteAnneeFormationSuivieIfapmeRaw(requestParameters: AcquisScolaireHopeDeleteAnneeFormationSuivieIfapmeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/DeleteAnneeFormationSuivieIfapme`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async acquisScolaireHopeDeleteAnneeFormationSuivieIfapme(requestParameters: AcquisScolaireHopeDeleteAnneeFormationSuivieIfapmeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acquisScolaireHopeDeleteAnneeFormationSuivieIfapmeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async acquisScolaireHopeDeleteAnneeFormationSuivieIfapmeFichierRaw(requestParameters: AcquisScolaireHopeDeleteAnneeFormationSuivieIfapmeFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/DeleteAnneeFormationSuivieIfapmeFichier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async acquisScolaireHopeDeleteAnneeFormationSuivieIfapmeFichier(requestParameters: AcquisScolaireHopeDeleteAnneeFormationSuivieIfapmeFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acquisScolaireHopeDeleteAnneeFormationSuivieIfapmeFichierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async acquisScolaireHopeDeleteDiplomeCertificationRaw(requestParameters: AcquisScolaireHopeDeleteDiplomeCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/DeleteDiplomeCertification`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async acquisScolaireHopeDeleteDiplomeCertification(requestParameters: AcquisScolaireHopeDeleteDiplomeCertificationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acquisScolaireHopeDeleteDiplomeCertificationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async acquisScolaireHopeDeleteDiplomeCertificationFichierRaw(requestParameters: AcquisScolaireHopeDeleteDiplomeCertificationFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/DeleteDiplomeCertificationFichier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async acquisScolaireHopeDeleteDiplomeCertificationFichier(requestParameters: AcquisScolaireHopeDeleteDiplomeCertificationFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acquisScolaireHopeDeleteDiplomeCertificationFichierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async acquisScolaireHopeDeleteDiplomeCertificationIfapmeRaw(requestParameters: AcquisScolaireHopeDeleteDiplomeCertificationIfapmeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/DeleteDiplomeCertificationIfapme`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async acquisScolaireHopeDeleteDiplomeCertificationIfapme(requestParameters: AcquisScolaireHopeDeleteDiplomeCertificationIfapmeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acquisScolaireHopeDeleteDiplomeCertificationIfapmeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async acquisScolaireHopeDeleteDiplomeCertificationIfapmeFichierRaw(requestParameters: AcquisScolaireHopeDeleteDiplomeCertificationIfapmeFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/DeleteDiplomeCertificationIfapmeFichier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async acquisScolaireHopeDeleteDiplomeCertificationIfapmeFichier(requestParameters: AcquisScolaireHopeDeleteDiplomeCertificationIfapmeFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acquisScolaireHopeDeleteDiplomeCertificationIfapmeFichierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async acquisScolaireHopeDownloadAnneeFormationSuivieFichierRaw(requestParameters: AcquisScolaireHopeDownloadAnneeFormationSuivieFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/DownloadAnneeFormationSuivieFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeDownloadAnneeFormationSuivieFichier(requestParameters: AcquisScolaireHopeDownloadAnneeFormationSuivieFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.acquisScolaireHopeDownloadAnneeFormationSuivieFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeDownloadAnneeFormationSuivieIfapmeFichierRaw(requestParameters: AcquisScolaireHopeDownloadAnneeFormationSuivieIfapmeFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/DownloadAnneeFormationSuivieIfapmeFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeDownloadAnneeFormationSuivieIfapmeFichier(requestParameters: AcquisScolaireHopeDownloadAnneeFormationSuivieIfapmeFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.acquisScolaireHopeDownloadAnneeFormationSuivieIfapmeFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeDownloadDiplomeCertificationFichierRaw(requestParameters: AcquisScolaireHopeDownloadDiplomeCertificationFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/DownloadDiplomeCertificationFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeDownloadDiplomeCertificationFichier(requestParameters: AcquisScolaireHopeDownloadDiplomeCertificationFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.acquisScolaireHopeDownloadDiplomeCertificationFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeDownloadDiplomeCertificationIfapmeFichierRaw(requestParameters: AcquisScolaireHopeDownloadDiplomeCertificationIfapmeFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/DownloadDiplomeCertificationIfapmeFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeDownloadDiplomeCertificationIfapmeFichier(requestParameters: AcquisScolaireHopeDownloadDiplomeCertificationIfapmeFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.acquisScolaireHopeDownloadDiplomeCertificationIfapmeFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeGetAnneeFormationSuivieRaw(requestParameters: AcquisScolaireHopeGetAnneeFormationSuivieRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnneeFormationSuivieEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/GetAnneeFormationSuivie`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnneeFormationSuivieEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeGetAnneeFormationSuivie(requestParameters: AcquisScolaireHopeGetAnneeFormationSuivieRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnneeFormationSuivieEditDto> {
        const response = await this.acquisScolaireHopeGetAnneeFormationSuivieRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeGetAnneeFormationSuivieFichierRaw(requestParameters: AcquisScolaireHopeGetAnneeFormationSuivieFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnneeFormationSuivieFichierDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/GetAnneeFormationSuivieFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnneeFormationSuivieFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeGetAnneeFormationSuivieFichier(requestParameters: AcquisScolaireHopeGetAnneeFormationSuivieFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnneeFormationSuivieFichierDto> {
        const response = await this.acquisScolaireHopeGetAnneeFormationSuivieFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeGetAnneeFormationSuivieIfapmeRaw(requestParameters: AcquisScolaireHopeGetAnneeFormationSuivieIfapmeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnneeFormationSuivieIfapmeEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/GetAnneeFormationSuivieIfapme`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnneeFormationSuivieIfapmeEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeGetAnneeFormationSuivieIfapme(requestParameters: AcquisScolaireHopeGetAnneeFormationSuivieIfapmeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnneeFormationSuivieIfapmeEditDto> {
        const response = await this.acquisScolaireHopeGetAnneeFormationSuivieIfapmeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeGetAnneeFormationSuivieIfapmeFichierRaw(requestParameters: AcquisScolaireHopeGetAnneeFormationSuivieIfapmeFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnneeFormationSuivieIfapmeFichierDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/GetAnneeFormationSuivieIfapmeFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnneeFormationSuivieIfapmeFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeGetAnneeFormationSuivieIfapmeFichier(requestParameters: AcquisScolaireHopeGetAnneeFormationSuivieIfapmeFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnneeFormationSuivieIfapmeFichierDto> {
        const response = await this.acquisScolaireHopeGetAnneeFormationSuivieIfapmeFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeGetDiplomeCertificationRaw(requestParameters: AcquisScolaireHopeGetDiplomeCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiplomeCertificationEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/GetDiplomeCertification`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiplomeCertificationEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeGetDiplomeCertification(requestParameters: AcquisScolaireHopeGetDiplomeCertificationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiplomeCertificationEditDto> {
        const response = await this.acquisScolaireHopeGetDiplomeCertificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeGetDiplomeCertificationFichierRaw(requestParameters: AcquisScolaireHopeGetDiplomeCertificationFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiplomeCertificationFichierDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/GetDiplomeCertificationFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiplomeCertificationFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeGetDiplomeCertificationFichier(requestParameters: AcquisScolaireHopeGetDiplomeCertificationFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiplomeCertificationFichierDto> {
        const response = await this.acquisScolaireHopeGetDiplomeCertificationFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeGetDiplomeCertificationIfapmeRaw(requestParameters: AcquisScolaireHopeGetDiplomeCertificationIfapmeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiplomeCertificationIfapmeEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/GetDiplomeCertificationIfapme`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiplomeCertificationIfapmeEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeGetDiplomeCertificationIfapme(requestParameters: AcquisScolaireHopeGetDiplomeCertificationIfapmeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiplomeCertificationIfapmeEditDto> {
        const response = await this.acquisScolaireHopeGetDiplomeCertificationIfapmeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeGetDiplomeCertificationIfapmeFichierRaw(requestParameters: AcquisScolaireHopeGetDiplomeCertificationIfapmeFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiplomeCertificationIfapmeFichierDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/GetDiplomeCertificationIfapmeFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiplomeCertificationIfapmeFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeGetDiplomeCertificationIfapmeFichier(requestParameters: AcquisScolaireHopeGetDiplomeCertificationIfapmeFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiplomeCertificationIfapmeFichierDto> {
        const response = await this.acquisScolaireHopeGetDiplomeCertificationIfapmeFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeGetSearchCriteriasRaw(requestParameters: AcquisScolaireHopeGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async acquisScolaireHopeGetSearchCriterias(requestParameters: AcquisScolaireHopeGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.acquisScolaireHopeGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeGetSelectItemsRaw(requestParameters: AcquisScolaireHopeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling acquisScolaireHopeGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async acquisScolaireHopeGetSelectItems(requestParameters: AcquisScolaireHopeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.acquisScolaireHopeGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeSaveAnneeFormationSuivieRaw(requestParameters: AcquisScolaireHopeSaveAnneeFormationSuivieRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnneeFormationSuivieEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/SaveAnneeFormationSuivie`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnneeFormationSuivieEditDtoToJSON(requestParameters.AnneeFormationSuivieEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnneeFormationSuivieEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeSaveAnneeFormationSuivie(requestParameters: AcquisScolaireHopeSaveAnneeFormationSuivieRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnneeFormationSuivieEditDto> {
        const response = await this.acquisScolaireHopeSaveAnneeFormationSuivieRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeSaveAnneeFormationSuivieFichierRaw(requestParameters: AcquisScolaireHopeSaveAnneeFormationSuivieFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnneeFormationSuivieFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/SaveAnneeFormationSuivieFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnneeFormationSuivieFichierDtoToJSON(requestParameters.AnneeFormationSuivieFichierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnneeFormationSuivieFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeSaveAnneeFormationSuivieFichier(requestParameters: AcquisScolaireHopeSaveAnneeFormationSuivieFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnneeFormationSuivieFichierDto> {
        const response = await this.acquisScolaireHopeSaveAnneeFormationSuivieFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeSaveAnneeFormationSuivieIfapmeRaw(requestParameters: AcquisScolaireHopeSaveAnneeFormationSuivieIfapmeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnneeFormationSuivieIfapmeEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/SaveAnneeFormationSuivieIfapme`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnneeFormationSuivieIfapmeEditDtoToJSON(requestParameters.AnneeFormationSuivieIfapmeEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnneeFormationSuivieIfapmeEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeSaveAnneeFormationSuivieIfapme(requestParameters: AcquisScolaireHopeSaveAnneeFormationSuivieIfapmeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnneeFormationSuivieIfapmeEditDto> {
        const response = await this.acquisScolaireHopeSaveAnneeFormationSuivieIfapmeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeSaveAnneeFormationSuivieIfapmeFichierRaw(requestParameters: AcquisScolaireHopeSaveAnneeFormationSuivieIfapmeFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnneeFormationSuivieIfapmeFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/SaveAnneeFormationSuivieIfapmeFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnneeFormationSuivieIfapmeFichierDtoToJSON(requestParameters.AnneeFormationSuivieIfapmeFichierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnneeFormationSuivieIfapmeFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeSaveAnneeFormationSuivieIfapmeFichier(requestParameters: AcquisScolaireHopeSaveAnneeFormationSuivieIfapmeFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnneeFormationSuivieIfapmeFichierDto> {
        const response = await this.acquisScolaireHopeSaveAnneeFormationSuivieIfapmeFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeSaveDiplomeCertificationRaw(requestParameters: AcquisScolaireHopeSaveDiplomeCertificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiplomeCertificationEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/SaveDiplomeCertification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DiplomeCertificationEditDtoToJSON(requestParameters.DiplomeCertificationEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiplomeCertificationEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeSaveDiplomeCertification(requestParameters: AcquisScolaireHopeSaveDiplomeCertificationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiplomeCertificationEditDto> {
        const response = await this.acquisScolaireHopeSaveDiplomeCertificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeSaveDiplomeCertificationFichierRaw(requestParameters: AcquisScolaireHopeSaveDiplomeCertificationFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiplomeCertificationFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/SaveDiplomeCertificationFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DiplomeCertificationFichierDtoToJSON(requestParameters.DiplomeCertificationFichierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiplomeCertificationFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeSaveDiplomeCertificationFichier(requestParameters: AcquisScolaireHopeSaveDiplomeCertificationFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiplomeCertificationFichierDto> {
        const response = await this.acquisScolaireHopeSaveDiplomeCertificationFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeSaveDiplomeCertificationIfapmeFichierRaw(requestParameters: AcquisScolaireHopeSaveDiplomeCertificationIfapmeFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiplomeCertificationIfapmeFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/SaveDiplomeCertificationIfapmeFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DiplomeCertificationIfapmeFichierDtoToJSON(requestParameters.DiplomeCertificationIfapmeFichierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiplomeCertificationIfapmeFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeSaveDiplomeCertificationIfapmeFichier(requestParameters: AcquisScolaireHopeSaveDiplomeCertificationIfapmeFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiplomeCertificationIfapmeFichierDto> {
        const response = await this.acquisScolaireHopeSaveDiplomeCertificationIfapmeFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeSaveDiplomeCertificationIfapmeeRaw(requestParameters: AcquisScolaireHopeSaveDiplomeCertificationIfapmeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiplomeCertificationIfapmeEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/SaveDiplomeCertificationIfapme`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DiplomeCertificationIfapmeEditDtoToJSON(requestParameters.DiplomeCertificationIfapmeEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiplomeCertificationIfapmeEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeSaveDiplomeCertificationIfapmee(requestParameters: AcquisScolaireHopeSaveDiplomeCertificationIfapmeeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiplomeCertificationIfapmeEditDto> {
        const response = await this.acquisScolaireHopeSaveDiplomeCertificationIfapmeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeUploadAnneeFormationSuivieFichierRaw(requestParameters: AcquisScolaireHopeUploadAnneeFormationSuivieFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnneeFormationSuivieFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/UploadAnneeFormationSuivieFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnneeFormationSuivieFichierUploadDtoToJSON(requestParameters.AnneeFormationSuivieFichierUploadDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnneeFormationSuivieFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeUploadAnneeFormationSuivieFichier(requestParameters: AcquisScolaireHopeUploadAnneeFormationSuivieFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnneeFormationSuivieFichierDto> {
        const response = await this.acquisScolaireHopeUploadAnneeFormationSuivieFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeUploadAnneeFormationSuivieIfapmeFichierRaw(requestParameters: AcquisScolaireHopeUploadAnneeFormationSuivieIfapmeFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnneeFormationSuivieIfapmeFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/UploadAnneeFormationSuivieIfapmeFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnneeFormationSuivieIfapmeFichierUploadDtoToJSON(requestParameters.AnneeFormationSuivieIfapmeFichierUploadDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnneeFormationSuivieIfapmeFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeUploadAnneeFormationSuivieIfapmeFichier(requestParameters: AcquisScolaireHopeUploadAnneeFormationSuivieIfapmeFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnneeFormationSuivieIfapmeFichierDto> {
        const response = await this.acquisScolaireHopeUploadAnneeFormationSuivieIfapmeFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeUploadDiplomeCertificationFichierRaw(requestParameters: AcquisScolaireHopeUploadDiplomeCertificationFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiplomeCertificationFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/UploadDiplomeCertificationFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DiplomeCertificationFichierUploadDtoToJSON(requestParameters.DiplomeCertificationFichierUploadDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiplomeCertificationFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeUploadDiplomeCertificationFichier(requestParameters: AcquisScolaireHopeUploadDiplomeCertificationFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiplomeCertificationFichierDto> {
        const response = await this.acquisScolaireHopeUploadDiplomeCertificationFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisScolaireHopeUploadDiplomeCertificationIfapmeFichierRaw(requestParameters: AcquisScolaireHopeUploadDiplomeCertificationIfapmeFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiplomeCertificationIfapmeFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisScolaireHope/UploadDiplomeCertificationIfapmeFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DiplomeCertificationIfapmeFichierUploadDtoToJSON(requestParameters.DiplomeCertificationIfapmeFichierUploadDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiplomeCertificationIfapmeFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisScolaireHopeUploadDiplomeCertificationIfapmeFichier(requestParameters: AcquisScolaireHopeUploadDiplomeCertificationIfapmeFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiplomeCertificationIfapmeFichierDto> {
        const response = await this.acquisScolaireHopeUploadDiplomeCertificationIfapmeFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
