import * as React from "react";
import { useTl, useApiService, useAbortableApiServiceFactory } from "../../../../hooks";
import { useHistory } from "react-router";
import { ButtonContainer, IDataTableColumn } from "nsitools-react";
import { ViewButton, EditButton, SearchTablePage } from "../../../../components";
import { ERoutes } from "../../../../AppRouter";
import { ETLCodes } from "../../../../locales";
import { VisiteApi, FcbVisiteGridDto, VisiteSearch, ETextSearchType } from "../../../../api";
import { Checkbox } from "@blueprintjs/core";
import { useGlobalData } from "../../../../contexts";

export interface IVisiteExterieureListPageProps {}

export const VisiteExterieureListPage: React.FunctionComponent<IVisiteExterieureListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(VisiteApi);
  const { currentAnneeScolaire } = useGlobalData();
  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbVisiteGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.visiteExterieure}/${row.idvisite}/information/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.visiteExterieure}/${row.idvisite}/information/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Date),
        fieldName: "datevisiteDebut"
      },
      {
        header: () => t(ETLCodes.Duree),
        fieldName: "duree"
      },
      {
        header: () => t(ETLCodes.Contact),
        fieldName: "contact"
      },
      {
        header: () => t(ETLCodes.Accord),
        fieldName: "accord",
        alignment: "center",
        render: (value: FcbVisiteGridDto) => <Checkbox checked={value.accord === "oui"} disabled />
      },
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "nom"
      }
    ],
    [history, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.visiteExterieure}/0/information/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(() => api.visiteGetSearchCriterias({ includeListsValues: true }), [api]);

  const apiFactory = useAbortableApiServiceFactory(VisiteApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: VisiteSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.visiteBaseSearch({ VisiteSearch: sObj });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.VisiteExterieure), route: ERoutes.visiteExterieure }]}
      addFunc={onAddItem}
      sortKeys={{ datevisiteDebut: "DESC" }}
      searchStateInitialSearch={false}
      defaultCriterias={[
        {
          criteria: "AnneeAcademique",
          searchMode: ETextSearchType.Equals,
          value: currentAnneeScolaire?.idanneeScolaire
        }
      ]}
    />
  );
};
