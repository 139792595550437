/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbFormateurDesideratumDto
 */
export interface FcbFormateurDesideratumDto {
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDesideratumDto
     */
    iddesiderata?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDesideratumDto
     */
    idcentre?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDesideratumDto
     */
    idformateur?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDesideratumDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDesideratumDto
     */
    desiderata?: string | null;
}

/**
 * Check if a given object implements the FcbFormateurDesideratumDto interface.
 */
export function instanceOfFcbFormateurDesideratumDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbFormateurDesideratumDtoFromJSON(json: any): FcbFormateurDesideratumDto {
    return FcbFormateurDesideratumDtoFromJSONTyped(json, false);
}

export function FcbFormateurDesideratumDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbFormateurDesideratumDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iddesiderata': !exists(json, 'iddesiderata') ? undefined : json['iddesiderata'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'desiderata': !exists(json, 'desiderata') ? undefined : json['desiderata'],
    };
}

export function FcbFormateurDesideratumDtoToJSON(value?: FcbFormateurDesideratumDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iddesiderata': value.iddesiderata,
        'idcentre': value.idcentre,
        'idformateur': value.idformateur,
        'anneeScolaire': value.anneeScolaire,
        'desiderata': value.desiderata,
    };
}

