/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SiegeSocialGridDto
 */
export interface SiegeSocialGridDto {
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialGridDto
     */
    idsiegeSocial?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SiegeSocialGridDto
     */
    readonly formationCanHaveApprenants?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialGridDto
     */
    nbContrats?: number;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialGridDto
     */
    denominationSociale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialGridDto
     */
    gsm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialGridDto
     */
    telephone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialGridDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialGridDto
     */
    localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialGridDto
     */
    commune?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialGridDto
     */
    numeroBce?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SiegeSocialGridDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialGridDto
     */
    chefEntreprisePrincipal?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SiegeSocialGridDto
     */
    hasLieuxFormations?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialGridDto
     */
    capaciteFormative?: number | null;
}

/**
 * Check if a given object implements the SiegeSocialGridDto interface.
 */
export function instanceOfSiegeSocialGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SiegeSocialGridDtoFromJSON(json: any): SiegeSocialGridDto {
    return SiegeSocialGridDtoFromJSONTyped(json, false);
}

export function SiegeSocialGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiegeSocialGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'formationCanHaveApprenants': !exists(json, 'formationCanHaveApprenants') ? undefined : json['formationCanHaveApprenants'],
        'nbContrats': !exists(json, 'nbContrats') ? undefined : json['nbContrats'],
        'denominationSociale': !exists(json, 'denominationSociale') ? undefined : json['denominationSociale'],
        'gsm': !exists(json, 'gsm') ? undefined : json['gsm'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'commune': !exists(json, 'commune') ? undefined : json['commune'],
        'numeroBce': !exists(json, 'numeroBce') ? undefined : json['numeroBce'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'chefEntreprisePrincipal': !exists(json, 'chefEntreprisePrincipal') ? undefined : json['chefEntreprisePrincipal'],
        'hasLieuxFormations': !exists(json, 'hasLieuxFormations') ? undefined : json['hasLieuxFormations'],
        'capaciteFormative': !exists(json, 'capaciteFormative') ? undefined : json['capaciteFormative'],
    };
}

export function SiegeSocialGridDtoToJSON(value?: SiegeSocialGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsiegeSocial': value.idsiegeSocial,
        'nbContrats': value.nbContrats,
        'denominationSociale': value.denominationSociale,
        'gsm': value.gsm,
        'telephone': value.telephone,
        'email': value.email,
        'localite': value.localite,
        'commune': value.commune,
        'numeroBce': value.numeroBce,
        'actif': value.actif,
        'chefEntreprisePrincipal': value.chefEntreprisePrincipal,
        'hasLieuxFormations': value.hasLieuxFormations,
        'capaciteFormative': value.capaciteFormative,
    };
}

