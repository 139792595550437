import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";

import { FormateurAgrementSwitch, FormateurAzure, FormateurClasseSearchPage, FormateurHoraire } from ".";
import { AcquisSwitch } from "../..";
import { FormateurApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { ActiviteProfessionelleSwitch } from "./activiteProfessionelles/ActiviteProfessionelleSwitch";
import { FormateurAdministratifItemPage } from "./administratif/FormateurAdministratifItemPage";
import { FormateurLocalisationSwitch } from "./localisation/FormateurLocalisationSwitch";
import { FormateurPreferenceHoraireSwitch } from "./preferenceHoraire/FormateurPreferenceHoraireSwitch";
import { FormateurPrestations } from "./prestations/FormateurPresations";
import { FormateurRemplacementListPage } from "./remplacement/FormateurRemplacementListPage";
import { FormateurSignaletiqueSwitch } from "./signaletique/FormateurSignaletiqueSwitch";
import { FormateurSMS } from "./sms/FormateurSMS";
import { FormateurSuiviSwitch } from "./suivi/FormateurSuiviSwitch";

export interface IFormateurItemPageProps {}

export const FormateurItemPage: React.FunctionComponent<IFormateurItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab } = useParams<{ id: string; tab: string; state: string }>();
  const idFormateur = React.useMemo(() => +id, [id]);
  const history = useHistory();

  const api = useApiService(FormateurApi);

  const [displayName] = useApiEffect(() => api.formateurGetDisplayName({ id: +id }), [id]);

  const fetchIdPersonne = React.useCallback(() => {
    return api.formateurGetIdPersonne({ id: +id });
  }, [api, id]);
  const { data: idpersonne } = useQuery(["idpersonne-apprenant", id], fetchIdPersonne);

  return (
    <PageBase breadCrumbs={[{ text: t(ETLCodes.Formateurs), route: ERoutes.formateur }, { text: displayName }]}>
      <ProtectedTabs
        id="TabsApprenant"
        onChange={newTabId => history.push(`${ERoutes.formateur}/${id}/${newTabId}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.formateur}></BackButton>
        <Tab id="signaletique" title={t(ETLCodes.Signaletique)} panel={<FormateurSignaletiqueSwitch />} />
        <Tab
          id="Localisation"
          title={t(ETLCodes.Localisation)}
          panel={<FormateurLocalisationSwitch />}
          disabled={idFormateur <= 0}
        />
        <Tab
          id="administratif"
          title={t(ETLCodes.Administratif)}
          panel={<FormateurAdministratifItemPage formateurId={idFormateur} />}
          disabled={idFormateur <= 0}
        />
        <Tab
          id="activitesProfessionnelles"
          title={t(ETLCodes.ActivitesProfessionelles)}
          panel={<ActiviteProfessionelleSwitch />}
          disabled={idFormateur <= 0}
        />
        <Tab
          id="agrement"
          title={t(ETLCodes.Agrements)}
          panel={<FormateurAgrementSwitch />}
          disabled={idFormateur <= 0}
        />
        <Tab
          id="acquis"
          title={t(ETLCodes.Acquis)}
          panel={<AcquisSwitch baseRoute={ERoutes.formateur} idpersonne={idpersonne} />}
          disabled={idFormateur <= 0}
        />
        <Tab id="suivi" title={t(ETLCodes.Suivi)} panel={<FormateurSuiviSwitch />} disabled={idFormateur <= 0} />
        <Tab
          id="classe"
          title={t(ETLCodes.Classes)}
          panel={<FormateurClasseSearchPage formateurId={idFormateur} />}
          disabled={idFormateur <= 0}
        />
        <Tab
          id="preferencesHoraire"
          title={t(ETLCodes.PreferencesHoraires)}
          panel={<FormateurPreferenceHoraireSwitch />}
          disabled={idFormateur <= 0}
        />
        <Tab id="horaires" title={t(ETLCodes.Horaires)} panel={<FormateurHoraire />} />
        <Tab
          id="remplacements"
          title={t(ETLCodes.Remplacements)}
          panel={<FormateurRemplacementListPage />}
          disabled={idFormateur <= 0}
        />
        <Tab
          id="prestations"
          title={t(ETLCodes.Prestations)}
          panel={<FormateurPrestations formateurId={idFormateur} />}
          disabled={idFormateur <= 0}
        />
        <Tab
          id="sms"
          title={t(ETLCodes.SMS)}
          panel={<FormateurSMS formateurId={idFormateur} />}
          disabled={idFormateur <= 0}
        />
        <Tab id="Azure" title={t(ETLCodes.Azure)} panel={<FormateurAzure />} disabled={idFormateur <= 0} />
      </ProtectedTabs>
    </PageBase>
  );
};
