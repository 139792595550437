/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExperienceProfessionnelleFichierGridDto } from './ExperienceProfessionnelleFichierGridDto';
import {
    ExperienceProfessionnelleFichierGridDtoFromJSON,
    ExperienceProfessionnelleFichierGridDtoFromJSONTyped,
    ExperienceProfessionnelleFichierGridDtoToJSON,
} from './ExperienceProfessionnelleFichierGridDto';

/**
 * 
 * @export
 * @interface ExperienceProfessionnelleEditDto
 */
export interface ExperienceProfessionnelleEditDto {
    /**
     * 
     * @type {number}
     * @memberof ExperienceProfessionnelleEditDto
     */
    idexperienceProfessionnelle?: number;
    /**
     * 
     * @type {number}
     * @memberof ExperienceProfessionnelleEditDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {string}
     * @memberof ExperienceProfessionnelleEditDto
     */
    fonction?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ExperienceProfessionnelleEditDto
     */
    dateDeDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ExperienceProfessionnelleEditDto
     */
    dateDeFin?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ExperienceProfessionnelleEditDto
     */
    entreprise?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExperienceProfessionnelleEditDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExperienceProfessionnelleEditDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {Array<ExperienceProfessionnelleFichierGridDto>}
     * @memberof ExperienceProfessionnelleEditDto
     */
    fichiers?: Array<ExperienceProfessionnelleFichierGridDto> | null;
}

/**
 * Check if a given object implements the ExperienceProfessionnelleEditDto interface.
 */
export function instanceOfExperienceProfessionnelleEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExperienceProfessionnelleEditDtoFromJSON(json: any): ExperienceProfessionnelleEditDto {
    return ExperienceProfessionnelleEditDtoFromJSONTyped(json, false);
}

export function ExperienceProfessionnelleEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExperienceProfessionnelleEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idexperienceProfessionnelle': !exists(json, 'idexperienceProfessionnelle') ? undefined : json['idexperienceProfessionnelle'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'fonction': !exists(json, 'fonction') ? undefined : json['fonction'],
        'dateDeDebut': !exists(json, 'dateDeDebut') ? undefined : (json['dateDeDebut'] === null ? null : new Date(json['dateDeDebut'])),
        'dateDeFin': !exists(json, 'dateDeFin') ? undefined : (json['dateDeFin'] === null ? null : new Date(json['dateDeFin'])),
        'entreprise': !exists(json, 'entreprise') ? undefined : json['entreprise'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'fichiers': !exists(json, 'fichiers') ? undefined : (json['fichiers'] === null ? null : (json['fichiers'] as Array<any>).map(ExperienceProfessionnelleFichierGridDtoFromJSON)),
    };
}

export function ExperienceProfessionnelleEditDtoToJSON(value?: ExperienceProfessionnelleEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idexperienceProfessionnelle': value.idexperienceProfessionnelle,
        'idpersonne': value.idpersonne,
        'fonction': value.fonction,
        'dateDeDebut': value.dateDeDebut === undefined ? undefined : (value.dateDeDebut === null ? null : value.dateDeDebut.toISOString()),
        'dateDeFin': value.dateDeFin === undefined ? undefined : (value.dateDeFin === null ? null : value.dateDeFin.toISOString()),
        'entreprise': value.entreprise,
        'description': value.description,
        'remarque': value.remarque,
        'fichiers': value.fichiers === undefined ? undefined : (value.fichiers === null ? null : (value.fichiers as Array<any>).map(ExperienceProfessionnelleFichierGridDtoToJSON)),
    };
}

