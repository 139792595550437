/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LieuFormationAgrementOffreDto
 */
export interface LieuFormationAgrementOffreDto {
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementOffreDto
     */
    idlieuFormationAgrementOffre?: number;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementOffreDto
     */
    idsiegeSocial?: number;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementOffreDto
     */
    idlieuFormation?: number;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementOffreDto
     */
    idlieuFormationAgrement?: number;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationAgrementOffreDto
     */
    lieuFormationAgrementNom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationAgrementOffreDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationAgrementOffreDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the LieuFormationAgrementOffreDto interface.
 */
export function instanceOfLieuFormationAgrementOffreDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LieuFormationAgrementOffreDtoFromJSON(json: any): LieuFormationAgrementOffreDto {
    return LieuFormationAgrementOffreDtoFromJSONTyped(json, false);
}

export function LieuFormationAgrementOffreDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LieuFormationAgrementOffreDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idlieuFormationAgrementOffre': !exists(json, 'idlieuFormationAgrementOffre') ? undefined : json['idlieuFormationAgrementOffre'],
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'idlieuFormationAgrement': !exists(json, 'idlieuFormationAgrement') ? undefined : json['idlieuFormationAgrement'],
        'lieuFormationAgrementNom': !exists(json, 'lieuFormationAgrementNom') ? undefined : json['lieuFormationAgrementNom'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function LieuFormationAgrementOffreDtoToJSON(value?: LieuFormationAgrementOffreDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idlieuFormationAgrementOffre': value.idlieuFormationAgrementOffre,
        'idsiegeSocial': value.idsiegeSocial,
        'idlieuFormation': value.idlieuFormation,
        'idlieuFormationAgrement': value.idlieuFormationAgrement,
        'lieuFormationAgrementNom': value.lieuFormationAgrementNom,
        'remarque': value.remarque,
        'actif': value.actif,
    };
}

