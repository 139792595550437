import { Tab, Tabs } from "@blueprintjs/core";
import * as React from "react";
import { DoublonChefEntrepriseSiegesSociaux } from ".";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IDoublonChefEntreprisePageProps {}

export const DoublonChefEntreprisePage: React.FunctionComponent<IDoublonChefEntreprisePageProps> = props => {
  const { t } = useTl();
  const [tab, setTab] = React.useState("siegesSociaux");

  return (
    <Tabs
      id="TabsDoublonChefEntreprise"
      onChange={newTabId => setTab(newTabId + "")}
      selectedTabId={tab}
      renderActiveTabPanelOnly
      vertical
    >
      <Tab id="siegesSociaux" title={t(ETLCodes.SiegesSociaux)} panel={<DoublonChefEntrepriseSiegesSociaux />} />
    </Tabs>
  );
};
