/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClasseJourDto
 */
export interface ClasseJourDto {
    /**
     * 
     * @type {string}
     * @memberof ClasseJourDto
     */
    jour?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClasseJourDto
     */
    presenceComplete?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClasseJourDto
     */
    inTheFuture?: boolean;
}

/**
 * Check if a given object implements the ClasseJourDto interface.
 */
export function instanceOfClasseJourDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClasseJourDtoFromJSON(json: any): ClasseJourDto {
    return ClasseJourDtoFromJSONTyped(json, false);
}

export function ClasseJourDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClasseJourDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jour': !exists(json, 'jour') ? undefined : json['jour'],
        'presenceComplete': !exists(json, 'presenceComplete') ? undefined : json['presenceComplete'],
        'inTheFuture': !exists(json, 'inTheFuture') ? undefined : json['inTheFuture'],
    };
}

export function ClasseJourDtoToJSON(value?: ClasseJourDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jour': value.jour,
        'presenceComplete': value.presenceComplete,
        'inTheFuture': value.inTheFuture,
    };
}

