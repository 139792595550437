import * as React from "react";
import styled from "styled-components";
import {} from "@blueprintjs/core";
import { ETLCodes } from "../locales";
import { format, addMinutes } from "date-fns";
import { useTl } from "../hooks";

export interface IModifedByDataObject {
  modificationUser?: string | undefined;
  modificationDate?: Date;
  creationDate?: Date;
}

export interface ModifiedByProps {
  data?: IModifedByDataObject;
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: center;
  flex: 1;
  margin-bottom: 1rem;

  & > span {
    font-style: italic;
  }
`;

export const ModifiedBy: React.FunctionComponent<ModifiedByProps> = ({ data }) => {
  const { t } = useTl();
  if (data?.modificationUser && data?.modificationDate) {
    return (
      <Container>
        <span>
          {t(ETLCodes.ModifiedOn)}&nbsp;
          {format(addMinutes(data?.modificationDate, data?.modificationDate.getTimezoneOffset()), "dd/MM/yyyy")}
          &nbsp;{t(ETLCodes.At)}&nbsp;
          {format(addMinutes(data?.modificationDate, data?.modificationDate.getTimezoneOffset()), "HH:mm")}
          &nbsp;{t(ETLCodes.By)}&nbsp;{data?.modificationUser}
        </span>
      </Container>
    );
  }
  return null;
};
