/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { BooleanSearch } from './BooleanSearch';
import {
    BooleanSearchFromJSON,
    BooleanSearchFromJSONTyped,
    BooleanSearchToJSON,
} from './BooleanSearch';
import type { TextSearch } from './TextSearch';
import {
    TextSearchFromJSON,
    TextSearchFromJSONTyped,
    TextSearchToJSON,
} from './TextSearch';

/**
 * 
 * @export
 * @interface RechercheexportSearch
 */
export interface RechercheexportSearch {
    /**
     * 
     * @type {TextSearch}
     * @memberof RechercheexportSearch
     */
    nom?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof RechercheexportSearch
     */
    type?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof RechercheexportSearch
     */
    description?: TextSearch;
    /**
     * 
     * @type {BooleanSearch}
     * @memberof RechercheexportSearch
     */
    actifComputed?: BooleanSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof RechercheexportSearch
     */
    db?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof RechercheexportSearch
     */
    module?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof RechercheexportSearch
     */
    centre?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof RechercheexportSearch
     */
    acces?: TextSearch;
    /**
     * 
     * @type {Array<string>}
     * @memberof RechercheexportSearch
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof RechercheexportSearch
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof RechercheexportSearch
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RechercheexportSearch
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof RechercheexportSearch
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof RechercheexportSearch
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the RechercheexportSearch interface.
 */
export function instanceOfRechercheexportSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RechercheexportSearchFromJSON(json: any): RechercheexportSearch {
    return RechercheexportSearchFromJSONTyped(json, false);
}

export function RechercheexportSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): RechercheexportSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nom': !exists(json, 'nom') ? undefined : TextSearchFromJSON(json['nom']),
        'type': !exists(json, 'type') ? undefined : TextSearchFromJSON(json['type']),
        'description': !exists(json, 'description') ? undefined : TextSearchFromJSON(json['description']),
        'actifComputed': !exists(json, 'actifComputed') ? undefined : BooleanSearchFromJSON(json['actifComputed']),
        'db': !exists(json, 'db') ? undefined : TextSearchFromJSON(json['db']),
        'module': !exists(json, 'module') ? undefined : TextSearchFromJSON(json['module']),
        'centre': !exists(json, 'centre') ? undefined : TextSearchFromJSON(json['centre']),
        'acces': !exists(json, 'acces') ? undefined : TextSearchFromJSON(json['acces']),
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function RechercheexportSearchToJSON(value?: RechercheexportSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nom': TextSearchToJSON(value.nom),
        'type': TextSearchToJSON(value.type),
        'description': TextSearchToJSON(value.description),
        'actifComputed': BooleanSearchToJSON(value.actifComputed),
        'db': TextSearchToJSON(value.db),
        'module': TextSearchToJSON(value.module),
        'centre': TextSearchToJSON(value.centre),
        'acces': TextSearchToJSON(value.acces),
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

