import * as signalR from "@microsoft/signalr";
import orderBy from "lodash/orderBy";
import { showError, showInfo } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";

import { useAuth } from ".";
import {
  EPrintingQueueStatus,
  FichierApi,
  PrintingQueueApi,
  PrintingQueueDto,
  SelectedPrintingsDtoFromJSON
} from "../api";
import { useApiService, useTl } from "../hooks";
import { ETLCodes } from "../locales";
import { b64toBlob, exportFile, nameof } from "../utils";

interface IPrintingQueueContext {
  connected: boolean;
  queuePrintings: PrintingQueueDto[];
  isLoading: boolean;
  cancelQueue: (idprintingQueue: number) => Promise<void>;
  rowCancelling: number;
  deleteFromQueue: (idprintingQueue: number) => Promise<void>;
  rowDeleting: number;
  refreshQueue: () => void;
  isQueueForcedOpen: boolean;
  setIsQueueForcedOpen: (value: boolean) => void;
  rowDownloading: number;
  downloadFile: (idprintingQueue: number, idfichier: number, toDelete: boolean) => Promise<void>;
  massDownloading: boolean;
  massDownload: (idsfichier: number[]) => Promise<void>;
  rowRetrying: number;
  retryPrinting: (idprintingQueue: number) => Promise<void>;
  rowShowing: number;
  showPrinting: (idprintingQueue: number) => Promise<void>;
  showHidden: boolean;
  setShowHidden: (value: boolean | ((prev: boolean) => boolean)) => void;
  massMask: (selectedIds: number[]) => Promise<void>;
  massDelete: (selectedIds: number[]) => Promise<void>;
  massCancel: (selectedIds: number[]) => Promise<void>;
  removePreviousPrintings: () => Promise<void>;
  hasError: boolean;
  setHasError: (value: boolean) => void;
}
const PrintingQueueContext = React.createContext<IPrintingQueueContext>(null);

interface IPrintingQueueProviderProps {}

const hostUrl = window.location.href.split("/");
const baseUrl = hostUrl[2]?.startsWith("localhost:") ? "https://localhost:5001" : hostUrl[0] + "//" + hostUrl[2];

export const PrintingQueueProvider: React.FunctionComponent<IPrintingQueueProviderProps> = ({ children }) => {
  const { t } = useTl();
  const { user, getAccessToken } = useAuth();
  const api = useApiService(PrintingQueueApi);
  const fApi = useApiService(FichierApi);
  const [isQueueOpen, setIsQueueOpen] = React.useState(false);
  const [showHidden, setShowHidden] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  const formatAccessToken = React.useCallback(async () => {
    const accessToken = await getAccessToken();
    if (!accessToken) return null;

    return accessToken.split("Bearer ")[1];
  }, [getAccessToken]);

  const connection = React.useMemo(
    () =>
      new signalR.HubConnectionBuilder()
        .withUrl(baseUrl + "/printingQueue", {
          accessTokenFactory: formatAccessToken,
          skipNegotiation: !user
        })
        .withAutomaticReconnect()
        .build(),
    [formatAccessToken, user]
  );

  const [connected, setConnected] = React.useState(false);
  const [queuePrintings, setQueuePrintings] = React.useState<PrintingQueueDto[]>([]);

  const { data, isFetching, refetch } = useQuery(
    ["queue-printings", user?.iduser, showHidden],
    async () => await api.printingQueueGetPrintingQueue({ showHidden }),
    { enabled: !!user?.iduser }
  );
  React.useEffect(() => {
    if (!!data) {
      setQueuePrintings(data);
    }
  }, [data]);

  const onPrintingStatusChange = React.useCallback(
    async (idprintingQueue: number, status: string, idfichier: number) => {
      setQueuePrintings(prev => {
        const modified = prev.map(p =>
          p.idprintingQueue === idprintingQueue
            ? { ...p, status: EPrintingQueueStatus[status], idfichier: idfichier }
            : p
        );

        if (!hasError) setHasError(modified.some(d => d.status === EPrintingQueueStatus.ERROR));
        return orderBy(
          modified,
          [
            item => {
              switch (item.status) {
                case EPrintingQueueStatus.GENERATING:
                  return 1;
                case EPrintingQueueStatus.DONE:
                  return 2;
                case EPrintingQueueStatus.ERROR:
                  return 3;
                case EPrintingQueueStatus.CANCELLED:
                  return 4;
                case EPrintingQueueStatus.WAITING:
                  return 5;
              }
            },
            nameof<PrintingQueueDto>("creationDate"),
            nameof<PrintingQueueDto>("idprintingQueue")
          ],
          ["asc", "asc", "asc"]
        );
      });
    },
    [hasError]
  );

  React.useEffect(() => {
    connection.onreconnected(() => setConnected(true));
    connection.onreconnecting(() => setConnected(false));
    connection
      .start()
      .then(() => setConnected(true))
      .catch(error => console.error(error));
  }, [connection]);

  React.useEffect(() => {
    connection.on("PrintingStatusChanged", onPrintingStatusChange);
    return () => {
      connection.off("PrintingStatusChanged");
    };
  }, [connection, onPrintingStatusChange]);

  const [rowCancelling, setRowCancelling] = React.useState(null);
  const cancelQueue = React.useCallback(
    async (idprintingQueue: number) => {
      try {
        setRowCancelling(idprintingQueue);
        await api.printingQueueCancelPrinting({ idprintingQueue: idprintingQueue });
      } catch (e) {
        showError(t(ETLCodes.ErrorWhileCancellingPrinting));
        console.error(e);
      } finally {
        setRowCancelling(null);
      }
    },
    [api, t]
  );

  const [rowDeleting, setRowDeleting] = React.useState(null);
  const deleteFromQueue = React.useCallback(
    async (idprintingQueue: number) => {
      try {
        setRowDeleting(idprintingQueue);
        await api.printingQueueDeletePrinting({ idprintingQueue: idprintingQueue });
      } catch (e) {
        showError(t(ETLCodes.ErrorWhileDeletingPrinting));
        console.error(e);
      } finally {
        setRowDeleting(null);
      }
    },
    [api, t]
  );

  const [rowDownloading, setRowDownloading] = React.useState(null);
  const downloadFile = React.useCallback(
    async (idprintingQueue: number, idfichier: number, toDelete: boolean) => {
      try {
        setRowDownloading(idprintingQueue);
        const file = await fApi.fichierDownload({ id: idfichier });
        await exportFile(file);
        await fApi.fichierDelete({ id: idfichier });
      } catch (e) {
        showError(t(ETLCodes.ErrorWhileDownloadingPrinting));
        console.error(e);
      } finally {
        setRowDownloading(null);
      }
    },
    [fApi, t]
  );

  const [massDownloading, setMassDownloading] = React.useState(null);
  const massDownload = React.useCallback(
    async (idsfichier: number[]) => {
      if (idsfichier.length <= 0) {
        showError(t(ETLCodes.SelectAtLeastOne));
        return;
      }

      const finalIds = idsfichier.filter(id => !!id);
      try {
        setMassDownloading(true);
        const zip = await fApi.fichierMassDownloadPdf({
          MassDownloadRequestDto: { idsfichier: finalIds }
        });
        if (!zip) {
          showInfo(t(ETLCodes.AucunFichierATelecharger));
          return;
        }
        await fApi.fichierMassDelete({ MassDeleteFichierRequestDto: { idsfichier: finalIds } });
        saveAs(b64toBlob(zip), zip.fileName);
      } catch (e) {
        showError(t(ETLCodes.ErrorWhileDownloadingPrinting));
        console.error(e);
      } finally {
        setMassDownloading(false);
      }
    },
    [fApi, t]
  );

  const [rowRetrying, setRowRetrying] = React.useState(null);
  const retryPrinting = React.useCallback(
    async idprintingQueue => {
      try {
        setRowRetrying(idprintingQueue);
        await api.printingQueueRetryPrinting({ idprintingQueue: idprintingQueue });
      } catch (e) {
        showError(t(ETLCodes.ErrorWhileDeletingPrinting));
        console.error(e);
      } finally {
        setRowRetrying(null);
      }
    },
    [api, t]
  );

  const [rowShowing, setRowShowing] = React.useState(null);
  const showPrinting = React.useCallback(
    async idprintingQueue => {
      try {
        setRowShowing(idprintingQueue);
        await api.printingQueueShowPrinting({ idprintingQueue: idprintingQueue });
      } catch (e) {
        showError(t(ETLCodes.ErrorWhileShowingRow));
        console.error(e);
      } finally {
        setRowShowing(null);
      }
    },
    [api, t]
  );

  const massMask = React.useCallback(
    async (selectedIds: number[]) => {
      if (selectedIds.length <= 0) {
        showError(t(ETLCodes.SelectAtLeastOne));
        return;
      }

      try {
        setMassDownloading(true);
        await api.printingQueueMaskSelected({
          SelectedPrintingsDto: SelectedPrintingsDtoFromJSON({
            selectedIds: selectedIds
          })
        });
        refetch();
      } catch (e) {
        showError(t(ETLCodes.ErrorWhileMaskingprintings));
        console.error(e);
      } finally {
        setMassDownloading(false);
      }
    },
    [api, refetch, t]
  );

  const massDelete = React.useCallback(
    async (selectedIds: number[]) => {
      if (selectedIds.length <= 0) {
        showError(t(ETLCodes.SelectAtLeastOne));
        return;
      }

      try {
        setMassDownloading(true);
        await api.printingQueueDeleteSelected({
          SelectedPrintingsDto: SelectedPrintingsDtoFromJSON({
            selectedIds: selectedIds
          })
        });
        refetch();
      } catch (e) {
        showError(t(ETLCodes.ErrorWhileDeletingPrintings));
        console.error(e);
      } finally {
        setMassDownloading(false);
      }
    },
    [api, refetch, t]
  );

  const massCancel = React.useCallback(
    async (selectedIds: number[]) => {
      if (selectedIds.length <= 0) {
        showError(t(ETLCodes.SelectAtLeastOne));
        return;
      }

      try {
        setMassDownloading(true);
        await api.printingQueueCancelSelected({
          SelectedPrintingsDto: SelectedPrintingsDtoFromJSON({
            selectedIds: selectedIds
          })
        });
        refetch();
      } catch (e) {
        showError(t(ETLCodes.ErrorWhileCancellingPrintings));
        console.error(e);
      } finally {
        setMassDownloading(false);
      }
    },
    [api, refetch, t]
  );

  const removePreviousPrintings = React.useCallback(async () => {
    const selectedIds = queuePrintings.map(qp => qp.idprintingQueue);
    try {
      setMassDownloading(true);
      await api.printingQueueCancelSelected({
        SelectedPrintingsDto: SelectedPrintingsDtoFromJSON({
          selectedIds: selectedIds
        })
      });
      await api.printingQueueDeleteSelected({
        SelectedPrintingsDto: SelectedPrintingsDtoFromJSON({
          selectedIds: selectedIds
        })
      });
      await api.printingQueueMaskSelected({
        SelectedPrintingsDto: SelectedPrintingsDtoFromJSON({
          selectedIds: selectedIds
        })
      });
      refetch();
    } catch (e) {
      showError(t(ETLCodes.ErrorWhileCancellingPrintings));
      console.error(e);
    } finally {
      setMassDownloading(false);
    }
  }, [api, queuePrintings, refetch, t]);

  return (
    <PrintingQueueContext.Provider
      value={{
        connected,
        queuePrintings,
        isLoading: isFetching,
        cancelQueue,
        rowCancelling,
        deleteFromQueue,
        rowDeleting,
        refreshQueue: refetch,
        isQueueForcedOpen: isQueueOpen,
        setIsQueueForcedOpen: setIsQueueOpen,
        downloadFile,
        rowDownloading,
        massDownload,
        massDownloading,
        retryPrinting,
        rowRetrying,
        rowShowing,
        showPrinting,
        setShowHidden,
        showHidden,
        massMask,
        massDelete,
        massCancel,
        removePreviousPrintings,
        hasError,
        setHasError
      }}
    >
      {children}
    </PrintingQueueContext.Provider>
  );
};

export const usePrintingQueueContext = () => React.useContext(PrintingQueueContext);
