/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbReportAccesPermissionDto
 */
export interface FcbReportAccesPermissionDto {
    /**
     * 
     * @type {boolean}
     * @memberof FcbReportAccesPermissionDto
     */
    selected?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbReportAccesPermissionDto
     */
    idimpression?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbReportAccesPermissionDto
     */
    idlevel?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbReportAccesPermissionDto
     */
    description?: string | null;
}

/**
 * Check if a given object implements the FcbReportAccesPermissionDto interface.
 */
export function instanceOfFcbReportAccesPermissionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbReportAccesPermissionDtoFromJSON(json: any): FcbReportAccesPermissionDto {
    return FcbReportAccesPermissionDtoFromJSONTyped(json, false);
}

export function FcbReportAccesPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbReportAccesPermissionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selected': !exists(json, 'selected') ? undefined : json['selected'],
        'idimpression': !exists(json, 'idimpression') ? undefined : json['idimpression'],
        'idlevel': !exists(json, 'idlevel') ? undefined : json['idlevel'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function FcbReportAccesPermissionDtoToJSON(value?: FcbReportAccesPermissionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selected': value.selected,
        'idimpression': value.idimpression,
        'idlevel': value.idlevel,
        'description': value.description,
    };
}

