import * as React from "react";
import { FcbReportModulePermissionDto, ReportApi } from "../../../../../api";
import { useTl, useApiService } from "../../../../../hooks";
import { useGridState, IDataTableColumn, FieldSet, DataTable } from "nsitools-react";
import { Checkbox } from "@blueprintjs/core";
import { ETLCodes } from "../../../../../locales";

export interface IImpressionModulePermissionsGridProps {
  idImpression: number;
  modules: FcbReportModulePermissionDto[];
  loading: boolean;
}

export const ImpressionModulePermissionsGrid: React.FunctionComponent<IImpressionModulePermissionsGridProps> = ({
  idImpression,
  modules = [],
  loading
}) => {
  const { t } = useTl();
  const api = useApiService(ReportApi);

  const tableState = useGridState<FcbReportModulePermissionDto>({
    serverMode: false,
    enablePagination: false,
    enableFilter: false
  });

  const { setData } = tableState;

  React.useEffect(() => {
    setData(modules);
  }, [modules, setData]);

  const toggleSelection = React.useCallback(
    (row: FcbReportModulePermissionDto) => {
      row.selected = !row.selected;
      row.idimpression = idImpression;
      let nextData = [...modules];
      setData(nextData);
      api.reportSaveModulePermissions({ FcbReportModulePermissionDto: row });
    },
    [api, idImpression, modules, setData]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        render: (item: FcbReportModulePermissionDto) => (
          <Checkbox
            checked={item.selected}
            onChange={e => {
              toggleSelection(item);
            }}
          />
        )
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "nom"
      }
    ],
    [t, toggleSelection]
  );

  return (
    <FieldSet title={t(ETLCodes.Modules)} collapsable defaultCollapsed>
      <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
    </FieldSet>
  );
};
