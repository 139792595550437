import * as React from "react";
import { IconNames } from "@blueprintjs/icons";
import { ETLCodes } from "../../locales/ETLCodes";
import { useTl } from "../../hooks";
import { Intent, Button } from "@blueprintjs/core";
import { ICustomButtonProps } from "./ICustomButtonProps";
import { useAuth } from "../../contexts";

interface ISaveButtonProps extends ICustomButtonProps {
  specificRights?: { module: string; tab?: string; rights?: "R" | "RW" | "RWD" };
}

export const SaveButton: React.FunctionComponent<ISaveButtonProps> = ({
  minimal = true,
  onClick,
  loading,
  disabled,
  intent = Intent.PRIMARY,
  text,
  specificRights
}) => {
  const { t } = useTl();
  const { getCurrentPermission, hasPermission } = useAuth();
  const hasRights = React.useMemo(
    () =>
      !!specificRights
        ? hasPermission(specificRights.module, specificRights.rights ?? "R", specificRights.tab)
        : getCurrentPermission()?.permission?.includes("RW"),
    [getCurrentPermission, hasPermission, specificRights]
  );

  return hasRights ? (
    <Button
      icon={IconNames.FLOPPY_DISK}
      minimal={minimal}
      onClick={onClick}
      text={minimal ? null : text ?? t(ETLCodes.General_Save)}
      intent={intent}
      loading={loading}
      disabled={disabled}
      type={onClick ? "button" : "submit"}
    />
  ) : null;
};
