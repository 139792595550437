import * as React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import {
  EBooleanSearchTypes,
  FcbProjetFinancementGridDto,
  ProjetFinancementApi,
  ProjetFinancementSearch
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

interface IProjetFinancementListPageProps {}

const Container = styled.div`
  display: flex;
`;

export const ProjetFinancementListPage: React.FunctionComponent<IProjetFinancementListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ProjetFinancementApi);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbProjetFinancementGridDto) => (
          <Container>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.projetFinancement}/${row.idprojetFinancement}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.projetFinancement}/${row.idprojetFinancement}/detail/edit`)}
            />
          </Container>
        )
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code"
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "libelle"
      }
    ],
    [history, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.projetFinancement}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(
    () => api.projetFinancementGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  const apiFactory = useAbortableApiServiceFactory(ProjetFinancementApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: ProjetFinancementSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.projetFinancementBaseSearch({ ProjetFinancementSearch: sObj });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.ProjetsFinancement), route: ERoutes.projetFinancement }]}
      addFunc={onAddItem}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
    ></SearchTablePage>
  );
};
