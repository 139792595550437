import * as React from "react";
import { useTl, useApiService } from "../../../../../hooks";
import { useHistory, useParams } from "react-router";

import { useApiEffect, IDataTableColumn } from "nsitools-react";
import { ETLCodes } from "../../../../../locales";
import { WSelector } from "../../../../../components/selector/WSelector";
import { ERoutes } from "../../../../../AppRouter";
import { ClauseMetierLiesApi, MetierApi } from "../../../../../api";
import { useDialog } from "../../../../../contexts/DialogContext";

export interface IClauseMetierLiesSelectorProps {}

export const ClauseMetierLiesSelector: React.FunctionComponent<IClauseMetierLiesSelectorProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const mApi = useApiService(MetierApi);
  const cmApi = useApiService(ClauseMetierLiesApi);
  const { id } = useParams<{ id: string }>();
  const dialogContext = useDialog();

  const [data, loading] = useApiEffect(() => cmApi.clauseMetierLiesGetClauseMetier({ idClause: +id }));

  const columns = React.useMemo<IDataTableColumn[]>(() => {
    return [
      {
        fieldName: "code",
        header: () => t(ETLCodes.Code)
      },
      {
        fieldName: "stade",
        header: () => t(ETLCodes.Stade)
      },
      {
        fieldName: "masculin",
        header: () => t(ETLCodes.Metier)
      }
    ];
  }, [t]);

  return (
    !loading && (
      <WSelector
        linkedIds={data.idMetiers}
        columns={columns}
        idField="idmetier"
        tlDataPrefix="MetierSearchCriterias"
        title={t(ETLCodes.MetiersLies)}
        searchIdsFunc={sObj => mApi.metierSearchMetierIds({ MetierSearch: sObj })}
        getCriteriasFunction={() => mApi.metierGetSearchCriterias({ includeListsValues: true })}
        searchFunction={sObj => mApi.metierBaseSearch({ MetierSearch: sObj })}
        onSave={async newIds => {
          await cmApi.clauseMetierLiesSave({
            FcbClauseMetiersLiesDto: { idClause: +id, idMetiers: newIds }
          });

          history.push(`${ERoutes.clause}/${id}/MetierLies/edit`);
        }}
        onCancel={() => history.push(`${ERoutes.clause}/${id}/MetierLies/edit`)}
        overrideListValues={{ Idstade: i => i.displayValue }}
        dialogContext={dialogContext}
        sortKeys={{ masculin: "ASC" }}
      />
    )
  );
};
