/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailDto } from './EmailDto';
import {
    EmailDtoFromJSON,
    EmailDtoFromJSONTyped,
    EmailDtoToJSON,
} from './EmailDto';
import type { TelephoneDto } from './TelephoneDto';
import {
    TelephoneDtoFromJSON,
    TelephoneDtoFromJSONTyped,
    TelephoneDtoToJSON,
} from './TelephoneDto';

/**
 * 
 * @export
 * @interface ChefEntrepriseSiegeEditDto
 */
export interface ChefEntrepriseSiegeEditDto {
    /**
     * 
     * @type {number}
     * @memberof ChefEntrepriseSiegeEditDto
     */
    idchefEntreprise?: number;
    /**
     * 
     * @type {number}
     * @memberof ChefEntrepriseSiegeEditDto
     */
    idchefEntrepriseSiege?: number;
    /**
     * 
     * @type {number}
     * @memberof ChefEntrepriseSiegeEditDto
     */
    idsiegeSocial?: number;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseSiegeEditDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseSiegeEditDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseSiegeEditDto
     */
    codesexe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseSiegeEditDto
     */
    nationalite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseSiegeEditDto
     */
    codePaysNaissance?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ChefEntrepriseSiegeEditDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseSiegeEditDto
     */
    registreNational?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseSiegeEditDto
     */
    numeroIdentification?: string | null;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof ChefEntrepriseSiegeEditDto
     */
    telephone?: TelephoneDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof ChefEntrepriseSiegeEditDto
     */
    gsm?: TelephoneDto;
    /**
     * 
     * @type {EmailDto}
     * @memberof ChefEntrepriseSiegeEditDto
     */
    email?: EmailDto;
    /**
     * 
     * @type {boolean}
     * @memberof ChefEntrepriseSiegeEditDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof ChefEntrepriseSiegeEditDto
     */
    sortieEntreprise?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChefEntrepriseSiegeEditDto
     */
    chefEntreprisePrincipal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseSiegeEditDto
     */
    remarqueSiege?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseSiegeEditDto
     */
    fonction?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ChefEntrepriseSiegeEditDto
     */
    idpersonne?: number | null;
}

/**
 * Check if a given object implements the ChefEntrepriseSiegeEditDto interface.
 */
export function instanceOfChefEntrepriseSiegeEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChefEntrepriseSiegeEditDtoFromJSON(json: any): ChefEntrepriseSiegeEditDto {
    return ChefEntrepriseSiegeEditDtoFromJSONTyped(json, false);
}

export function ChefEntrepriseSiegeEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChefEntrepriseSiegeEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idchefEntreprise': !exists(json, 'idchefEntreprise') ? undefined : json['idchefEntreprise'],
        'idchefEntrepriseSiege': !exists(json, 'idchefEntrepriseSiege') ? undefined : json['idchefEntrepriseSiege'],
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'codesexe': !exists(json, 'codesexe') ? undefined : json['codesexe'],
        'nationalite': !exists(json, 'nationalite') ? undefined : json['nationalite'],
        'codePaysNaissance': !exists(json, 'codePaysNaissance') ? undefined : json['codePaysNaissance'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'registreNational': !exists(json, 'registreNational') ? undefined : json['registreNational'],
        'numeroIdentification': !exists(json, 'numeroIdentification') ? undefined : json['numeroIdentification'],
        'telephone': !exists(json, 'telephone') ? undefined : TelephoneDtoFromJSON(json['telephone']),
        'gsm': !exists(json, 'gsm') ? undefined : TelephoneDtoFromJSON(json['gsm']),
        'email': !exists(json, 'email') ? undefined : EmailDtoFromJSON(json['email']),
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'sortieEntreprise': !exists(json, 'sortieEntreprise') ? undefined : (json['sortieEntreprise'] === null ? null : new Date(json['sortieEntreprise'])),
        'chefEntreprisePrincipal': !exists(json, 'chefEntreprisePrincipal') ? undefined : json['chefEntreprisePrincipal'],
        'remarqueSiege': !exists(json, 'remarqueSiege') ? undefined : json['remarqueSiege'],
        'fonction': !exists(json, 'fonction') ? undefined : json['fonction'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
    };
}

export function ChefEntrepriseSiegeEditDtoToJSON(value?: ChefEntrepriseSiegeEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idchefEntreprise': value.idchefEntreprise,
        'idchefEntrepriseSiege': value.idchefEntrepriseSiege,
        'idsiegeSocial': value.idsiegeSocial,
        'nom': value.nom,
        'prenom': value.prenom,
        'codesexe': value.codesexe,
        'nationalite': value.nationalite,
        'codePaysNaissance': value.codePaysNaissance,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'registreNational': value.registreNational,
        'numeroIdentification': value.numeroIdentification,
        'telephone': TelephoneDtoToJSON(value.telephone),
        'gsm': TelephoneDtoToJSON(value.gsm),
        'email': EmailDtoToJSON(value.email),
        'actif': value.actif,
        'sortieEntreprise': value.sortieEntreprise === undefined ? undefined : (value.sortieEntreprise === null ? null : value.sortieEntreprise.toISOString()),
        'chefEntreprisePrincipal': value.chefEntreprisePrincipal,
        'remarqueSiege': value.remarqueSiege,
        'fonction': value.fonction,
        'idpersonne': value.idpersonne,
    };
}

