import { Colors, Intent } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import { css } from "styled-components";

import {
  ApprenantRepresentantHopeApi,
  ApprenantRepresentantHopeGridDto,
  ApprenantRepresentantSearchDto
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { AddButton, BooleanColumn, EditButton, ViewButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IApprenantRepresentantsSearchPageProps {}

export const ApprenantRepresentantsSearchPage: React.FunctionComponent<IApprenantRepresentantsSearchPageProps> = () => {
  const { t, tUnsafe } = useTl();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const apprenantId = React.useMemo(() => +id, [id]);
  const api = useApiService(ApprenantRepresentantHopeApi);
  const path = React.useMemo(() => `${ERoutes.apprenant}/${apprenantId}/representants`, [apprenantId]);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { nom: "ASC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: ApprenantRepresentantSearchDto) => {
      sObj.idapprenant = apprenantId;
      return api.apprenantRepresentantHopeBaseSearch({ ApprenantRepresentantSearchDto: sObj });
    },
    [api, apprenantId]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });
  const getRouteForRepresentant = React.useCallback((type: string) => {
    switch (type) {
      case "Personne":
        return ERoutes.representant;
      case "Institution":
        return ERoutes.institution;
      case "PersonneContact":
        return ERoutes.representant;
    }
  }, []);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ApprenantRepresentantHopeGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() =>
                history.push(`${path}/${row.type === "Personne" ? "Personne" : "Institution"}/${row.id}/view`)
              }
            />
            <EditButton
              minimal={true}
              onClick={() =>
                history.push(`${path}/${row.type === "Personne" ? "Personne" : "Institution"}/${row.id}/edit`)
              }
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "type",
        render: (row: ApprenantRepresentantHopeGridDto) => tUnsafe("Representant_" + row.type)
      },
      {
        header: () => t(ETLCodes.Representant),
        fieldName: "nom",
        render: (row: ApprenantRepresentantHopeGridDto) => (
          <a
            href={`#${getRouteForRepresentant(row.type)}/${row.idrepresentant}/detail/edit`}
            target="_blank"
            rel="noreferrer"
          >
            {`${row.nom}`}
          </a>
        )
      },
      {
        header: () => t(ETLCodes.LienParente),
        fieldName: "lien"
      },
      {
        header: () => t(ETLCodes.EmergencyContact),
        alignment: "center",
        autoFitContent: true,
        fieldName: "contactUrgence",
        render: (row: ApprenantRepresentantHopeGridDto) => <BooleanColumn value={row.contactUrgence} />
      }
    ],
    [history, path, t, tUnsafe, getRouteForRepresentant]
  );

  return (
    <>
      <FieldSet
        title={t(ETLCodes.TableResults, { count: totalCount })}
        rightElement={
          <AddButton
            onClick={e => {
              e.stopPropagation();
              history.push(`${path}/0/edit`);
            }}
            text={t(ETLCodes.General_Add)}
            intent={Intent.PRIMARY}
          />
        }
      >
        <DataTable
          dateFormat="dd/MM/yyyy"
          tableState={tableState}
          loading={loading}
          columns={columns}
          customizeRowStyle={(row: ApprenantRepresentantHopeGridDto) => css`
            & * {
              color: ${row.representantLegal ? Colors.GREEN3 + " !important" : null};
            }
          `}
          filterMode="OnEnter"
        ></DataTable>
      </FieldSet>
    </>
  );
};
