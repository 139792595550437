/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbExportGapDto
 */
export interface FcbExportGapDto {
    /**
     * 
     * @type {number}
     * @memberof FcbExportGapDto
     */
    idexport?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbExportGapDto
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof FcbExportGapDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbExportGapDto
     */
    valide?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FcbExportGapDto
     */
    idcentre?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbExportGapDto
     */
    dateExport?: Date | null;
}

/**
 * Check if a given object implements the FcbExportGapDto interface.
 */
export function instanceOfFcbExportGapDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbExportGapDtoFromJSON(json: any): FcbExportGapDto {
    return FcbExportGapDtoFromJSONTyped(json, false);
}

export function FcbExportGapDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbExportGapDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idexport': !exists(json, 'idexport') ? undefined : json['idexport'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'valide': !exists(json, 'valide') ? undefined : json['valide'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'dateExport': !exists(json, 'dateExport') ? undefined : (json['dateExport'] === null ? null : new Date(json['dateExport'])),
    };
}

export function FcbExportGapDtoToJSON(value?: FcbExportGapDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idexport': value.idexport,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'description': value.description,
        'valide': value.valide,
        'idcentre': value.idcentre,
        'dateExport': value.dateExport === undefined ? undefined : (value.dateExport === null ? null : value.dateExport.toISOString()),
    };
}

