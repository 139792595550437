/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileUploadDto } from './FileUploadDto';
import {
    FileUploadDtoFromJSON,
    FileUploadDtoFromJSONTyped,
    FileUploadDtoToJSON,
} from './FileUploadDto';

/**
 * 
 * @export
 * @interface FcbFormateurProfessionDto
 */
export interface FcbFormateurProfessionDto {
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurProfessionDto
     */
    idformateur?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurProfessionDto
     */
    idprofession?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurProfessionDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurProfessionDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurProfessionDto
     */
    idfichier?: number | null;
    /**
     * 
     * @type {FileUploadDto}
     * @memberof FcbFormateurProfessionDto
     */
    fichier?: FileUploadDto;
}

/**
 * Check if a given object implements the FcbFormateurProfessionDto interface.
 */
export function instanceOfFcbFormateurProfessionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbFormateurProfessionDtoFromJSON(json: any): FcbFormateurProfessionDto {
    return FcbFormateurProfessionDtoFromJSONTyped(json, false);
}

export function FcbFormateurProfessionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbFormateurProfessionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'idprofession': !exists(json, 'idprofession') ? undefined : json['idprofession'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'fichier': !exists(json, 'fichier') ? undefined : FileUploadDtoFromJSON(json['fichier']),
    };
}

export function FcbFormateurProfessionDtoToJSON(value?: FcbFormateurProfessionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idformateur': value.idformateur,
        'idprofession': value.idprofession,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'libelle': value.libelle,
        'idfichier': value.idfichier,
        'fichier': FileUploadDtoToJSON(value.fichier),
    };
}

