/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IAggregateResult } from './IAggregateResult';
import {
    IAggregateResultFromJSON,
    IAggregateResultFromJSONTyped,
    IAggregateResultToJSON,
} from './IAggregateResult';
import type { SuiviGridDto } from './SuiviGridDto';
import {
    SuiviGridDtoFromJSON,
    SuiviGridDtoFromJSONTyped,
    SuiviGridDtoToJSON,
} from './SuiviGridDto';

/**
 * 
 * @export
 * @interface SuiviGridDtoPaginatedResults
 */
export interface SuiviGridDtoPaginatedResults {
    /**
     * 
     * @type {number}
     * @memberof SuiviGridDtoPaginatedResults
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<SuiviGridDto>}
     * @memberof SuiviGridDtoPaginatedResults
     */
    results?: Array<SuiviGridDto> | null;
    /**
     * 
     * @type {Array<IAggregateResult>}
     * @memberof SuiviGridDtoPaginatedResults
     */
    aggregateResults?: Array<IAggregateResult> | null;
}

/**
 * Check if a given object implements the SuiviGridDtoPaginatedResults interface.
 */
export function instanceOfSuiviGridDtoPaginatedResults(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SuiviGridDtoPaginatedResultsFromJSON(json: any): SuiviGridDtoPaginatedResults {
    return SuiviGridDtoPaginatedResultsFromJSONTyped(json, false);
}

export function SuiviGridDtoPaginatedResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuiviGridDtoPaginatedResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'results': !exists(json, 'results') ? undefined : (json['results'] === null ? null : (json['results'] as Array<any>).map(SuiviGridDtoFromJSON)),
        'aggregateResults': !exists(json, 'aggregateResults') ? undefined : (json['aggregateResults'] === null ? null : (json['aggregateResults'] as Array<any>).map(IAggregateResultFromJSON)),
    };
}

export function SuiviGridDtoPaginatedResultsToJSON(value?: SuiviGridDtoPaginatedResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'results': value.results === undefined ? undefined : (value.results === null ? null : (value.results as Array<any>).map(SuiviGridDtoToJSON)),
        'aggregateResults': value.aggregateResults === undefined ? undefined : (value.aggregateResults === null ? null : (value.aggregateResults as Array<any>).map(IAggregateResultToJSON)),
    };
}

