import * as React from "react";

import { IBreadcrumbProps } from "@blueprintjs/core";

interface IBreadcumbContext {
  breadcrumbs: IBreadcrumbProps[];
  setBaseBreadcrumb: (breadCrumb: IBreadcrumbProps[]) => void;
  setDetailsBreadcrumb: (breadCrum: IBreadcrumbProps[]) => void;
  changeLastBreadcrumbDisplayedText: (text: string) => void;
}

export const BreadcrumbContext = React.createContext<IBreadcumbContext>(null);

export const BreadcrumbProvider: React.FunctionComponent = ({ children }) => {
  const [baseBreadcrumb, setBaseBreadcrumb] = React.useState<IBreadcrumbProps[]>([]);
  const [detailsBreadcrumb, setDetailsBreadcrumb] = React.useState<IBreadcrumbProps[]>([]);

  const breadcrumbs = React.useMemo(() => [...baseBreadcrumb, ...detailsBreadcrumb], [
    baseBreadcrumb,
    detailsBreadcrumb
  ]);

  const changeLastBreadcrumbDisplayedText = React.useCallback((text: string) => {
    setBaseBreadcrumb(prev => {
      if (prev.length > 0) {
        let next = [...prev.reverse()];
        next[0].text = text;
        next = next.reverse();
        return [...next];
      } else {
        return prev;
      }
    });
  }, []);

  return (
    <BreadcrumbContext.Provider
      value={{ breadcrumbs, setBaseBreadcrumb, setDetailsBreadcrumb, changeLastBreadcrumbDisplayedText }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
};

export const useBreadcrumbs = () => React.useContext(BreadcrumbContext);
