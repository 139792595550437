import { Checkbox, Intent } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { AgrementApi, AgrementSearch, FcbAgrementGridDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { AddButton, EditButton, ViewButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IApprenantValidationCEListPageProps {}
export const ApprenantValidationCEListPage: React.FunctionComponent<IApprenantValidationCEListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const apprenantId = React.useMemo(() => +id, [id]);
  const api = useApiService(AgrementApi);
  const path = React.useMemo(() => `${ERoutes.apprenant}/${apprenantId}/validationce`, [apprenantId]);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { dateDecision: "DESC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: AgrementSearch) => {
      sObj.idApprenant = apprenantId;
      return api.agrementBaseSearch({ AgrementSearch: sObj });
    },
    [api, apprenantId]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });
  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        sortKeys: { formation: "ASC" },
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbAgrementGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => history.push(`${path}/${row.idagrement}/view`)} />

            <EditButton minimal={true} onClick={() => history.push(`${path}/${row.idagrement}/edit`)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Formation),
        fieldName: "formation"
      },
      {
        header: () => t(ETLCodes.Date),
        fieldName: "dateDecision"
      },
      {
        header: () => t(ETLCodes.Decision),
        fieldName: "decision"
      },
      {
        autoFitContent: true,
        header: () => t(ETLCodes.Derogation),
        fieldName: "derogation",
        alignment: "center",
        render: (row: FcbAgrementGridDto) => <Checkbox checked={row.derogation} disabled />
      },
      {
        autoFitContent: true,
        header: () => t(ETLCodes.C8),
        fieldName: "c8",
        alignment: "center",
        render: (row: FcbAgrementGridDto) => <Checkbox checked={row.c8} disabled />
      }
    ],
    [history, path, t]
  );
  return (
    <>
      <FieldSet
        title={t(ETLCodes.TableResults, { count: totalCount })}
        rightElement={
          <AddButton
            onClick={e => {
              e.stopPropagation();
              history.push(`${path}/0/edit`);
            }}
            text={t(ETLCodes.General_Add)}
            intent={Intent.PRIMARY}
          />
        }
      >
        <DataTable dateFormat="dd/MM/yyyy" tableState={tableState} loading={loading} columns={columns}></DataTable>
      </FieldSet>
    </>
  );
};
