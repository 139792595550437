/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EStatutContrat } from './EStatutContrat';
import {
    EStatutContratFromJSON,
    EStatutContratFromJSONTyped,
    EStatutContratToJSON,
} from './EStatutContrat';
import type { ETypeContrat } from './ETypeContrat';
import {
    ETypeContratFromJSON,
    ETypeContratFromJSONTyped,
    ETypeContratToJSON,
} from './ETypeContrat';

/**
 * 
 * @export
 * @interface ApprenantContratDto
 */
export interface ApprenantContratDto {
    /**
     * 
     * @type {number}
     * @memberof ApprenantContratDto
     */
    idcontrat?: number;
    /**
     * 
     * @type {string}
     * @memberof ApprenantContratDto
     */
    service?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantContratDto
     */
    metier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantContratDto
     */
    stade?: string | null;
    /**
     * 
     * @type {ETypeContrat}
     * @memberof ApprenantContratDto
     */
    type?: ETypeContrat;
    /**
     * 
     * @type {EStatutContrat}
     * @memberof ApprenantContratDto
     */
    statut?: EStatutContrat;
    /**
     * 
     * @type {number}
     * @memberof ApprenantContratDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {Date}
     * @memberof ApprenantContratDto
     */
    marqueSansSuiteLe?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ApprenantContratDto
     */
    rompuLe?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ApprenantContratDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ApprenantContratDto
     */
    dateFin?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ApprenantContratDto
     */
    debutDerniereSuspension?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ApprenantContratDto
     */
    finDerniereSuspension?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantContratDto
     */
    code?: string | null;
}

/**
 * Check if a given object implements the ApprenantContratDto interface.
 */
export function instanceOfApprenantContratDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApprenantContratDtoFromJSON(json: any): ApprenantContratDto {
    return ApprenantContratDtoFromJSONTyped(json, false);
}

export function ApprenantContratDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApprenantContratDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontrat': !exists(json, 'idcontrat') ? undefined : json['idcontrat'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'metier': !exists(json, 'metier') ? undefined : json['metier'],
        'stade': !exists(json, 'stade') ? undefined : json['stade'],
        'type': !exists(json, 'type') ? undefined : ETypeContratFromJSON(json['type']),
        'statut': !exists(json, 'statut') ? undefined : EStatutContratFromJSON(json['statut']),
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'marqueSansSuiteLe': !exists(json, 'marqueSansSuiteLe') ? undefined : (json['marqueSansSuiteLe'] === null ? null : new Date(json['marqueSansSuiteLe'])),
        'rompuLe': !exists(json, 'rompuLe') ? undefined : (json['rompuLe'] === null ? null : new Date(json['rompuLe'])),
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
        'debutDerniereSuspension': !exists(json, 'debutDerniereSuspension') ? undefined : (json['debutDerniereSuspension'] === null ? null : new Date(json['debutDerniereSuspension'])),
        'finDerniereSuspension': !exists(json, 'finDerniereSuspension') ? undefined : (json['finDerniereSuspension'] === null ? null : new Date(json['finDerniereSuspension'])),
        'code': !exists(json, 'code') ? undefined : json['code'],
    };
}

export function ApprenantContratDtoToJSON(value?: ApprenantContratDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontrat': value.idcontrat,
        'service': value.service,
        'metier': value.metier,
        'stade': value.stade,
        'type': ETypeContratToJSON(value.type),
        'statut': EStatutContratToJSON(value.statut),
        'idapprenant': value.idapprenant,
        'marqueSansSuiteLe': value.marqueSansSuiteLe === undefined ? undefined : (value.marqueSansSuiteLe === null ? null : value.marqueSansSuiteLe.toISOString()),
        'rompuLe': value.rompuLe === undefined ? undefined : (value.rompuLe === null ? null : value.rompuLe.toISOString()),
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
        'debutDerniereSuspension': value.debutDerniereSuspension === undefined ? undefined : (value.debutDerniereSuspension === null ? null : value.debutDerniereSuspension.toISOString()),
        'finDerniereSuspension': value.finDerniereSuspension === undefined ? undefined : (value.finDerniereSuspension === null ? null : value.finDerniereSuspension.toISOString()),
        'code': value.code,
    };
}

