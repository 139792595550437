import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import { ApprenantRepresentantGlobal } from "./ApprenantRepresentantGlobal";
import { ApprenantRepresentantInstitutionDetail } from "./ApprenantRepresentantInstitutionDetail";
import { ApprenantRepresentantPersonneDetail } from "./ApprenantRepresentantPersonneDetail";
import { ApprenantRepresentantsSearchPage } from "./ApprenantRepresentantsSearchPage";

interface IApprenantRepresentantsProps {
  apprenantId: number;
}

export const ApprenantRepresentants: React.FunctionComponent<IApprenantRepresentantsProps> = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={ApprenantRepresentantsSearchPage} exact />
      {/* <Route path={`${match.path}/:representantId/:state`} component={ApprenantRepresentantsDetail} /> */}
      <Route path={`${match.path}/personne/:subId/:state`} component={ApprenantRepresentantPersonneDetail} />
      <Route path={`${match.path}/institution/:subId/:state`} component={ApprenantRepresentantInstitutionDetail} />
      <Route path={`${match.path}/:subId/:state`} component={ApprenantRepresentantGlobal} />
    </Switch>
  );
};
