/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ContratFichierDto,
  ContratFichierGridDtoPaginatedResults,
  ContratFichierSearchDto,
  ContratFichierUploadDto,
  FileDownloadDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
} from '../models/index';
import {
    ContratFichierDtoFromJSON,
    ContratFichierDtoToJSON,
    ContratFichierGridDtoPaginatedResultsFromJSON,
    ContratFichierGridDtoPaginatedResultsToJSON,
    ContratFichierSearchDtoFromJSON,
    ContratFichierSearchDtoToJSON,
    ContratFichierUploadDtoFromJSON,
    ContratFichierUploadDtoToJSON,
    FileDownloadDtoFromJSON,
    FileDownloadDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface ContratFichierBaseSearchRequest {
    ContratFichierSearchDto?: ContratFichierSearchDto;
}

export interface ContratFichierDeleteContratFichierRequest {
    id?: number;
}

export interface ContratFichierDownloadContratFichierRequest {
    id?: number;
}

export interface ContratFichierGetContratFichierRequest {
    id?: number;
}

export interface ContratFichierGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ContratFichierGetSelectItemsRequest {
    searchField: string;
}

export interface ContratFichierSaveContratFichierRequest {
    ContratFichierDto?: ContratFichierDto;
}

export interface ContratFichierUploadContratFichierRequest {
    ContratFichierUploadDto?: ContratFichierUploadDto;
}

/**
 * 
 */
export class ContratFichierApi extends runtime.BaseAPI {

    /**
     */
    async contratFichierBaseSearchRaw(requestParameters: ContratFichierBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratFichierGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ContratFichier/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContratFichierSearchDtoToJSON(requestParameters.ContratFichierSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratFichierGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async contratFichierBaseSearch(requestParameters: ContratFichierBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratFichierGridDtoPaginatedResults> {
        const response = await this.contratFichierBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratFichierDeleteContratFichierRaw(requestParameters: ContratFichierDeleteContratFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ContratFichier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async contratFichierDeleteContratFichier(requestParameters: ContratFichierDeleteContratFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.contratFichierDeleteContratFichierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async contratFichierDownloadContratFichierRaw(requestParameters: ContratFichierDownloadContratFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ContratFichier/Download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async contratFichierDownloadContratFichier(requestParameters: ContratFichierDownloadContratFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.contratFichierDownloadContratFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratFichierGetContratFichierRaw(requestParameters: ContratFichierGetContratFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratFichierDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ContratFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async contratFichierGetContratFichier(requestParameters: ContratFichierGetContratFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratFichierDto> {
        const response = await this.contratFichierGetContratFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratFichierGetSearchCriteriasRaw(requestParameters: ContratFichierGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ContratFichier/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async contratFichierGetSearchCriterias(requestParameters: ContratFichierGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.contratFichierGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratFichierGetSelectItemsRaw(requestParameters: ContratFichierGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling contratFichierGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ContratFichier/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async contratFichierGetSelectItems(requestParameters: ContratFichierGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.contratFichierGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratFichierSaveContratFichierRaw(requestParameters: ContratFichierSaveContratFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ContratFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContratFichierDtoToJSON(requestParameters.ContratFichierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async contratFichierSaveContratFichier(requestParameters: ContratFichierSaveContratFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratFichierDto> {
        const response = await this.contratFichierSaveContratFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratFichierUploadContratFichierRaw(requestParameters: ContratFichierUploadContratFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ContratFichier/Upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContratFichierUploadDtoToJSON(requestParameters.ContratFichierUploadDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async contratFichierUploadContratFichier(requestParameters: ContratFichierUploadContratFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratFichierDto> {
        const response = await this.contratFichierUploadContratFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
