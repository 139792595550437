import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { LieuFormationSuiviItem, LieuFormationSuiviList } from ".";
import { ERoutes } from "../../../../../AppRouter";

export interface ILieuFormationSuiviSwitchProps {}

export const LieuFormationSuiviSwitch: React.FunctionComponent<ILieuFormationSuiviSwitchProps> = props => {
  const path = React.useMemo(() => `${ERoutes.lieuFormation}/:id/suivi/:state?`, []);
  return (
    <Switch>
      <Route path={`${path}`} component={LieuFormationSuiviList} exact />
      <Route path={`${path}/:subId?`} component={LieuFormationSuiviItem} />
    </Switch>
  );
};
