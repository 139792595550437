import { Classes, Dialog } from "@blueprintjs/core";
import { FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { ApprenantDocumentDialogMode } from ".";

import {
  ApprenantFichierApi,
  ApprenantFichierUploadDto,
  ApprenantFichierUploadDtoFromJSON,
  ETypeApprenantFichier
} from "../../../../../api";
import {
  FGWalterDateMaskInput,
  FGWalterFileInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

const StyledDialog = styled(Dialog)`
  width: 600px;
  height: auto;
  background-color: white;
`;

export interface IApprenantDocumentUploadDialogProps {
  idapprenant: number;
  dialogOpen: boolean;
  onClose: (refresh?: boolean) => void;
  excludedTypes: ETypeApprenantFichier[];
  mode: ApprenantDocumentDialogMode;
  typeApprenantFichier: string;
}

export const ApprenantDocumentUploadDialog: React.FunctionComponent<IApprenantDocumentUploadDialogProps> = ({
  idapprenant,
  dialogOpen,
  onClose,
  excludedTypes,
  mode,
  typeApprenantFichier
}) => {
  const { t } = useTl();
  const api = useApiService(ApprenantFichierApi);

  const { data, loading, saveItem, saving, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () => {
          return ApprenantFichierUploadDtoFromJSON({ idapprenant });
        },
        saveApiFn: async (d: ApprenantFichierUploadDto) => {
          return api.apprenantFichierUploadApprenantFichier({ ApprenantFichierUploadDto: d });
        },
        onSaved: () => onClose(true)
      }),
      [api, idapprenant, onClose]
    )
  );

  const [typesApprenantFichier, tLoading, , rawTypesApprenantFichier] = useReferential(a =>
    a.referentialGetTypesApprenantFichier({ ETypeApprenantFichier: finalExcludedTypes })
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape(
        {
          idtypeApprenantFichier: Yup.number()
            .nullable()
            .required(t(ETLCodes.Required)),
          dateDebut: Yup.date()
            .nullable()
            .when("dateFin", (value, schema) =>
              !!value ? schema.max(Yup.ref("dateFin"), t(ETLCodes.DateDebutGreaterThanDateFin)) : schema
            ),
          dateFin: Yup.date()
            .nullable()
            .when("dateDebut", (value, schema) =>
              !!value ? schema.min(Yup.ref("dateDebut"), t(ETLCodes.DateFinGreaterThanDateDebut)) : schema
            ),
          file: Yup.object()
            .nullable()
            .shape({
              fileName:
                mode === "REGLEMENTAIRES"
                  ? Yup.string().nullable()
                  : Yup.string()
                      .nullable()
                      .required(t(ETLCodes.Required)),
              contentB64:
                mode === "REGLEMENTAIRES"
                  ? Yup.string().nullable()
                  : Yup.string()
                      .nullable()
                      .required(t(ETLCodes.Required))
            }),
          idinscription: Yup.number()
            .nullable()
            .when("idtypeApprenantFichier", (value, schema) => {
              if (!value) return schema;
              const typeCode = rawTypesApprenantFichier.find(t => +t.idValue === +value)?.keyValue;
              return typeCode === ETypeApprenantFichier.AVIS_RESULTATS ||
                typeCode === ETypeApprenantFichier.AVIS_RESULTATS_1ERE_SESSION ||
                typeCode === ETypeApprenantFichier.AVIS_RESULTATS_2EME_SESSION
                ? schema.required(t(ETLCodes.Required))
                : schema;
            })
        },
        [
          ["dateFin", "dateDebut"],
          ["idinscription", "idtypeApprenantFichier"]
        ]
      ),
    [mode, rawTypesApprenantFichier, t]
  );

  const finalExcludedTypes = React.useMemo(
    () =>
      !!typeApprenantFichier
        ? Object.values(ETypeApprenantFichier).filter(t => t !== typeApprenantFichier)
        : excludedTypes,
    [excludedTypes, typeApprenantFichier]
  );
  const [annees, aLoading] = useReferential(a => a.referentialGetAnneeScolaire(), false, []);
  const [inscriptions, iLoading] = useReferential(
    a => a.referentialGetInscriptionsApprenant({ idApprenant: idapprenant }),
    true,
    []
  );

  const fields = React.useMemo(() => {
    let fields = <></>;
    switch (mode) {
      case "REGLEMENTAIRES":
        fields = (
          <FGWalterSelectInput label={t(ETLCodes.Annee)} name="anneeScolaire" items={annees} loading={aLoading} />
        );
        break;
      case "AVIS_ATTEST_PF":
        fields = (
          <FGWalterSelectInput
            name="idinscription"
            label={t(ETLCodes.Inscription)}
            loading={iLoading}
            items={inscriptions}
          />
        );
        break;
      case "AUTRES":
        fields = (
          <>
            <FGTextInput label={t(ETLCodes.Nom)} maxLength={150} name="nom" />
            <FGWalterDateMaskInput name="dateDebut" label={t(ETLCodes.DateDebut)} />
            <FGWalterDateMaskInput name="dateFin" label={t(ETLCodes.DateFin)} />
            <FGTextAreaInput label={t(ETLCodes.Description)} name="description" />
          </>
        );
        break;
    }
    return fields;
  }, [aLoading, annees, iLoading, inscriptions, mode, t]);

  return (
    <StyledDialog title={t(ETLCodes.UploadFichier)} isOpen={dialogOpen} onClose={() => onClose(false)}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose(false)}
          showDeleteButton={false}
          saving={saving}
          loading={loading}
          validationErrors={validationErrors}
        >
          <FieldGroup>
            <FGWalterFileInput name="file" label={t(ETLCodes.Fichier)} requiredMark={mode !== "REGLEMENTAIRES"} />
            <FGWalterSelectInput
              name="idtypeApprenantFichier"
              label={t(ETLCodes.Type)}
              items={typesApprenantFichier}
              loading={tLoading}
              autoSelectIfOne
              readonly={mode === "REGLEMENTAIRES"}
            />
            {fields}
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
