import { Button, Colors, Icon, Intent, Menu, MenuItem, Popover, Tooltip } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";

import { ContratRuptureDialog, ContratSuspensionDetailDialog, LieuFormationAvenantsGrid } from "../../..";
import {
  ContratApi,
  ContratGridDto,
  ContratSearchDto,
  ContratSuspensionDto,
  ContratSuspensionDtoFromJSON,
  EStatutContrat,
  ETypeContrat,
  LieuFormationApi,
  RapportHopeApi
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { AddButton, CircleColumn, CustomBulletList, EditButton, ViewButton } from "../../../../../components";
import { useApiService, useTabMessage, useTheme, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { computeContratStatutColor } from "../../../../../utils/contratStatutColor";
import { Guid } from "../../../../../utils/guid";

const PopoverContent = styled.div`
  padding: 0.5rem;
`;

const LegendHeaderContainer = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

export interface ILieuFormationContratsProps {}

export const LieuFormationContrats: React.FunctionComponent<ILieuFormationContratsProps> = props => {
  const { t, tUnsafe } = useTl();
  const { theme, ifapmeSide } = useTheme();
  const isHope = React.useMemo(() => ifapmeSide === "hope", [ifapmeSide]);
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const idlieuFormation = React.useMemo(() => +id, [id]);
  const [currentSuspension, setCurrentSuspension] = React.useState<ContratSuspensionDto>(null);
  const [currentContratId, setCurrentContratId] = React.useState(0);
  const api = useApiService(ContratApi);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: ContratSearchDto) => {
      sObj.idlieuFormation = idlieuFormation;
      return api.contratBaseSearch({ ContratSearchDto: sObj });
    },
    [api, idlieuFormation]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const lfApi = useApiService(LieuFormationApi);
  const fetchIsActif = React.useCallback(async () => {
    if (!idlieuFormation || idlieuFormation <= 0) return true;
    return (await lfApi.lieuFormationIsActif({ id: idlieuFormation }))?.value;
  }, [idlieuFormation, lfApi]);
  const { data: isActif, isFetching: isActifLoading } = useQuery(["lf-is-actif", idlieuFormation], fetchIsActif);

  const onAddSuspensionClick = React.useCallback(idcontrat => {
    setCurrentSuspension(ContratSuspensionDtoFromJSON({ idcontrat, idcontratSuspension: 0 }));
  }, []);

  const onRuptureClick = React.useCallback(idcontrat => {
    setCurrentContratId(idcontrat);
  }, []);

  const buttons = React.useMemo(
    () =>
      (isActif || isActifLoading) && (
        <Popover
          position="left"
          content={
            <PopoverContent>
              <Menu>
                <MenuItem
                  text={t(ETLCodes.ContratAlternanceCC)}
                  intent={Intent.PRIMARY}
                  onClick={() => push(`${ERoutes.contrat}/-1/detail/CC/edit?idlieuFormation=${idlieuFormation}`)}
                />
                <MenuItem
                  text={t(ETLCodes.ConventionStageCS)}
                  intent={Intent.PRIMARY}
                  onClick={() => push(`${ERoutes.contrat}/-1/detail/CS/edit?idlieuFormation=${idlieuFormation}`)}
                />
                <MenuItem
                  text={t(ETLCodes.StagePratiqueProPP)}
                  intent={Intent.PRIMARY}
                  onClick={() => push(`${ERoutes.contrat}/-1/detail/PP/edit?idlieuFormation=${idlieuFormation}`)}
                />
                <MenuItem
                  text={t(ETLCodes.ConventionFALTCF)}
                  intent={Intent.PRIMARY}
                  onClick={() => push(`${ERoutes.contrat}/-1/detail/CF/edit?idlieuFormation=${idlieuFormation}`)}
                />
                <MenuItem
                  text={t(ETLCodes.StageDecouverteMetierDM)}
                  intent={Intent.PRIMARY}
                  onClick={() => push(`${ERoutes.contrat}/-1/detail/DM/edit?idlieuFormation=${idlieuFormation}`)}
                />
              </Menu>
            </PopoverContent>
          }
        >
          <AddButton text={t(ETLCodes.AddContrat)} intent={Intent.PRIMARY} loading={isActifLoading} />
        </Popover>
      ),
    [idlieuFormation, isActif, isActifLoading, push, t]
  );

  const identifier = React.useMemo(() => Guid.newGuid(), []);
  const { openTabThenSendMessage } = useTabMessage(identifier);

  const rapportApi = useApiService(RapportHopeApi);
  const fetchIdsrapportContrat = React.useCallback(() => {
    return rapportApi.rapportHopeGetIdsrapportContrat();
  }, [rapportApi]);
  const { data: idsrapportContrat } = useQuery("idsrapportContrat", fetchIdsrapportContrat);
  const printContrat = React.useCallback(
    (idcontrat: number, typeContrat: ETypeContrat) => {
      const idrapportContrat = idsrapportContrat?.[typeContrat];
      openTabThenSendMessage(`/#${ERoutes.SSRSReports}/${idrapportContrat}`, [idcontrat]);
    },
    [idsrapportContrat, openTabThenSendMessage]
  );

  const hopeContratTypes = React.useMemo(
    () => [
      ETypeContrat.CC,
      ETypeContrat.CA,
      ETypeContrat.CS,
      ETypeContrat.CF,
      ETypeContrat.DM,
      ETypeContrat.JI,
      ETypeContrat.PP
    ],
    []
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        autoFitContent: true,
        computed: true,
        fieldName: "_toggle",
        render: (item: ContratGridDto, _, { subPanelOpened, toggleSubPanel }) =>
          item.hasAvenants && (
            <Button
              minimal
              icon={subPanelOpened ? "chevron-down" : "chevron-right"}
              onClick={() => toggleSubPanel()}
            ></Button>
          )
      },
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ContratGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => window.open(`#${ERoutes.contrat}/${row.idcontrat}/detail/${row.type}/view`)}
            />
            {isHope && hopeContratTypes.includes(row.type) ? (
              <EditButton
                minimal={true}
                onClick={() => window.open(`#${ERoutes.contrat}/${row.idcontrat}/detail/${row.type}/edit`)}
              />
            ) : null}
            <Popover
              position="bottom"
              content={
                <PopoverContent>
                  <Menu>
                    {isHope && hopeContratTypes.includes(row.type) ? (
                      <>
                        <MenuItem
                          icon={IconNames.STOP}
                          text={t(ETLCodes.Rupture)}
                          intent={Intent.PRIMARY}
                          onClick={() => onRuptureClick(row.idcontrat)}
                          disabled={[EStatutContrat.Rompu, EStatutContrat.SansSuite].includes(row.statut)}
                        />
                        <MenuItem
                          icon={IconNames.PAUSE}
                          text={t(ETLCodes.Suspension)}
                          intent={Intent.PRIMARY}
                          onClick={() => onAddSuspensionClick(row.idcontrat)}
                          disabled={[EStatutContrat.Rompu, EStatutContrat.SansSuite].includes(row.statut)}
                        />
                        <MenuItem
                          icon={IconNames.DOCUMENT}
                          text={t(ETLCodes.Avenant)}
                          intent={Intent.PRIMARY}
                          onClick={() => {}}
                          disabled
                        />
                        {!!idsrapportContrat?.[row.type] && (
                          <MenuItem
                            icon={IconNames.PRINT}
                            text={t(ETLCodes.Impression)}
                            intent={Intent.PRIMARY}
                            onClick={() => printContrat(row.idcontrat, row.type)}
                          />
                        )}
                      </>
                    ) : null}
                  </Menu>
                </PopoverContent>
              }
            >
              <Button icon={IconNames.CHEVRON_DOWN} minimal={true}></Button>
            </Popover>
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Apprenant),
        fieldName: "apprenant"
      },
      {
        computed: true,
        alignment: "center",
        autoFitContent: true,
        header: () => (
          <LegendHeaderContainer>
            <span>{t(ETLCodes.Statut)}</span>
            <Tooltip
              content={
                <CustomBulletList
                  title={t(ETLCodes.Statut)}
                  items={[
                    {
                      text: t(ETLCodes.EnCours),
                      color: theme.sucessColor
                    },
                    {
                      text: t(ETLCodes.Rompu),
                      color: theme.dangerColor
                    },
                    {
                      text: t(ETLCodes.Suspendu),
                      color: theme.warningColor
                    },
                    {
                      text: t(ETLCodes.JamaisDeContrat),
                      color: Colors.BLACK
                    },
                    {
                      text: t(ETLCodes.Termine),
                      color: Colors.VIOLET4
                    },
                    {
                      text: t(ETLCodes.SansSuite),
                      hideBullet: true
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip>
          </LegendHeaderContainer>
        ),
        fieldName: "statut",
        render: (row: ContratGridDto) => (
          <CircleColumn
            color={computeContratStatutColor(row.statut, theme)}
            tooltip={tUnsafe(`StatutContrat_${row.statut}`)}
          />
        )
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code"
      },
      {
        header: () => t(ETLCodes.Service),
        fieldName: "serviceNom"
      },
      {
        header: () => t(ETLCodes.NomMetier),
        fieldName: "nomMetier"
      },
      {
        header: () => t(ETLCodes.Tuteur),
        fieldName: "tuteurPrincipal"
      },
      {
        header: () => t(ETLCodes.DateDebut),
        fieldName: "dateDebut"
      },
      {
        header: () => t(ETLCodes.DateFin),
        fieldName: "dateFin"
      },
      {
        header: () => t(ETLCodes.DateRupture),
        fieldName: "rompuLe"
      }
    ],
    [hopeContratTypes, idsrapportContrat, isHope, onAddSuspensionClick, onRuptureClick, printContrat, t, tUnsafe, theme]
  );

  const onClose = React.useCallback(
    (toSave: ContratSuspensionDto) => {
      if (!!toSave) {
        search();
      }
      setCurrentSuspension(null);
    },
    [search]
  );

  return (
    <>
      <FieldSet title={t(ETLCodes.TableResults, { count: totalCount })} rightElement={buttons}>
        <DataTable
          dateFormat="dd/MM/yyyy"
          tableState={tableState}
          loading={loading}
          columns={columns}
          filterMode="OnEnter"
          keyFieldName="idcontrat"
          onOpenSubComponent={(item: ContratGridDto) => (
            <LieuFormationAvenantsGrid contrat={item}></LieuFormationAvenantsGrid>
          )}
        ></DataTable>
      </FieldSet>
      {!!currentSuspension && (
        <ContratSuspensionDetailDialog
          dialogOpen={!!currentSuspension}
          currentSuspension={currentSuspension}
          onClose={onClose}
        />
      )}
      {!!currentContratId && (
        <ContratRuptureDialog
          dialogOpen={!!currentContratId && currentContratId > 0}
          currentContratId={currentContratId}
          onClose={(refresh: boolean) => {
            setCurrentContratId(0);
            if (refresh) search();
          }}
        />
      )}
    </>
  );
};
