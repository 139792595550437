import { Button, Intent } from "@blueprintjs/core";
import { ButtonContainer } from "nsitools-react";
import * as React from "react";

import { AddParcoursFormationDialog } from "./AddParcoursFormationDialog";

export interface IAddParcoursFormationButtonProps {
  idApprenant: number;
  idCategorieStatut?: number;
  idStatut?: number;
  iddureeInoccupation?: number;
  dateEntree?: Date;
  minDateEntree?: Date;
  onClose?: (refresh: boolean) => void;
  loading?: boolean;
  title: string;
}

export const AddParcoursFormationButton: React.FunctionComponent<IAddParcoursFormationButtonProps> = ({
  idApprenant,
  idCategorieStatut,
  idStatut,
  dateEntree,
  onClose = (refresh: boolean) => {},
  loading,
  title,
  minDateEntree,
  iddureeInoccupation
}) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  return (
    <>
      <ButtonContainer>
        <Button
          intent={Intent.PRIMARY}
          text={title}
          onClick={() => {
            setIsDialogOpen(true);
          }}
          disabled={loading}
        />
      </ButtonContainer>

      <AddParcoursFormationDialog
        title={title}
        idApprenant={idApprenant}
        idCategorieStatut={idCategorieStatut}
        idStatut={idStatut}
        iddureeInoccupation={iddureeInoccupation}
        dateEntree={dateEntree}
        minDateEntree={minDateEntree}
        isDialogOpen={isDialogOpen}
        onClose={(refresh: boolean) => {
          setIsDialogOpen(false);
          onClose(refresh);
        }}
      />
    </>
  );
};
