import * as React from "react";
import { Route, Switch } from "react-router";

import { UtilisateurServicesLiesPage } from "./UtilisateurServicessLiesPage";
import { UtilisateurServicesLiesSelector } from "./UtilisateurServicesLiesSelector";
import { ERoutes } from "../../../../../AppRouter";

export interface IUtilisateurServicesLiesSwitchProps {}

export const UtilisateurServicesLiesSwitch: React.FunctionComponent<IUtilisateurServicesLiesSwitchProps> = () => {
  return (
    <Switch>
      <Route path={`${ERoutes.users}/:id/:tab/:state`} component={UtilisateurServicesLiesPage} exact />
      <Route path={`${ERoutes.users}/:id/:tab/:state/selector`} component={UtilisateurServicesLiesSelector} />
    </Switch>
  );
};
