import { FGEmpty, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { DegreApi, FcbDegreDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterCheckboxInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

interface IDegreDetailPageProps {
  editMode: boolean;
  idDegre: string;
}

export const DegreDetailPage: React.FunctionComponent<IDegreDetailPageProps> = ({ editMode, idDegre }) => {
  const { t } = useTl();
  const api = useApiService(DegreApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () => (idDegre === "0" ? FcbDegreDtoFromJSON({ actif: true }) : api.degreGet({ id: idDegre })),
    saveApiFn: d => api.degreSave({ FcbDegreDto: d }),
    onSavedRoute: d => `${ERoutes.degre}/${d.idDegre}/detail/edit`,
    deleteApiFn: d => api.degreDelete({ id: d.idDegre }),
    onDeletedRoute: () => ERoutes.degre
  });

  const [stades, refLoading] = useReferential(a => a.referentialGetStade(), true, []);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idDegre: Yup.string()
        .required(t(ETLCodes.Required))
        .test("code-not-exists", t(ETLCodes.CodeExistsError), async value => {
          if (value === idDegre) return true;
          const validationError = await api.degreCodeDegreExists({ code: value });
          return validationError.isValid;
        }),
      idStade: Yup.string().required(t(ETLCodes.Required)),
      libelle: Yup.string().required(t(ETLCodes.Required))
    });
  }, [api, idDegre, t]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={editMode}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.degre)}
      onDelete={deleteItem}
      showDeleteButton={idDegre !== "0"}
      saving={saving}
      deleting={deleting}
    >
      <FieldGroup columns={2}>
        <FGTextInput name="idDegre" label={t(ETLCodes.Code)} maxLength={2} />
        <FGTextInput name="libelle" maxLength={50} label={t(ETLCodes.Libelle)} />
        <FGWalterSelectInput name="idStade" label={t(ETLCodes.Stade)} loading={refLoading} items={stades} />
        <FGEmpty />
        <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
