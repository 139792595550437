/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApprenantFichierGridDto } from './ApprenantFichierGridDto';
import {
    ApprenantFichierGridDtoFromJSON,
    ApprenantFichierGridDtoFromJSONTyped,
    ApprenantFichierGridDtoToJSON,
} from './ApprenantFichierGridDto';
import type { ApprenantRepresentantHopeGridDto } from './ApprenantRepresentantHopeGridDto';
import {
    ApprenantRepresentantHopeGridDtoFromJSON,
    ApprenantRepresentantHopeGridDtoFromJSONTyped,
    ApprenantRepresentantHopeGridDtoToJSON,
} from './ApprenantRepresentantHopeGridDto';
import type { EmailDto } from './EmailDto';
import {
    EmailDtoFromJSON,
    EmailDtoFromJSONTyped,
    EmailDtoToJSON,
} from './EmailDto';
import type { ParcoursFormationGridDto } from './ParcoursFormationGridDto';
import {
    ParcoursFormationGridDtoFromJSON,
    ParcoursFormationGridDtoFromJSONTyped,
    ParcoursFormationGridDtoToJSON,
} from './ParcoursFormationGridDto';
import type { PassportDriveDto } from './PassportDriveDto';
import {
    PassportDriveDtoFromJSON,
    PassportDriveDtoFromJSONTyped,
    PassportDriveDtoToJSON,
} from './PassportDriveDto';
import type { StatutApprenantGridDto } from './StatutApprenantGridDto';
import {
    StatutApprenantGridDtoFromJSON,
    StatutApprenantGridDtoFromJSONTyped,
    StatutApprenantGridDtoToJSON,
} from './StatutApprenantGridDto';
import type { TelephoneDto } from './TelephoneDto';
import {
    TelephoneDtoFromJSON,
    TelephoneDtoFromJSONTyped,
    TelephoneDtoToJSON,
} from './TelephoneDto';

/**
 * 
 * @export
 * @interface ApprenantDoublonEditDto
 */
export interface ApprenantDoublonEditDto {
    /**
     * 
     * @type {number}
     * @memberof ApprenantDoublonEditDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof ApprenantDoublonEditDto
     */
    nbInscriptions?: number;
    /**
     * 
     * @type {number}
     * @memberof ApprenantDoublonEditDto
     */
    nbContrats?: number;
    /**
     * 
     * @type {number}
     * @memberof ApprenantDoublonEditDto
     */
    nbSuivis?: number;
    /**
     * 
     * @type {number}
     * @memberof ApprenantDoublonEditDto
     */
    nbAbsences?: number;
    /**
     * 
     * @type {number}
     * @memberof ApprenantDoublonEditDto
     */
    nbDocuments?: number;
    /**
     * 
     * @type {string}
     * @memberof ApprenantDoublonEditDto
     */
    azureEmail?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ApprenantDoublonEditDto
     */
    azureCreatedOn?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ApprenantDoublonEditDto
     */
    bankAccountBeneficiaryId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantDoublonEditDto
     */
    bankAccountBeneficiaryName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApprenantDoublonEditDto
     */
    permisB?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApprenantDoublonEditDto
     */
    permisAm?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApprenantDoublonEditDto
     */
    remarques?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApprenantDoublonEditDto
     */
    remarquesService?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApprenantDoublonEditDto
     */
    internes?: Array<string> | null;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof ApprenantDoublonEditDto
     */
    telephoneApprenant?: TelephoneDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof ApprenantDoublonEditDto
     */
    gsmApprenant?: TelephoneDto;
    /**
     * 
     * @type {EmailDto}
     * @memberof ApprenantDoublonEditDto
     */
    emailApprenant?: EmailDto;
    /**
     * 
     * @type {number}
     * @memberof ApprenantDoublonEditDto
     */
    idpri?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantDoublonEditDto
     */
    pri?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApprenantDoublonEditDto
     */
    idari?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantDoublonEditDto
     */
    ari?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApprenantDoublonEditDto
     */
    idcop?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantDoublonEditDto
     */
    cop?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApprenantDoublonEditDto
     */
    idpricontratEnCours?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantDoublonEditDto
     */
    pricontratEnCours?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApprenantDoublonEditDto
     */
    idaricontratEnCours?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantDoublonEditDto
     */
    aricontratEnCours?: string | null;
    /**
     * 
     * @type {Array<ApprenantRepresentantHopeGridDto>}
     * @memberof ApprenantDoublonEditDto
     */
    representants?: Array<ApprenantRepresentantHopeGridDto> | null;
    /**
     * 
     * @type {ParcoursFormationGridDto}
     * @memberof ApprenantDoublonEditDto
     */
    currentParcoursFormation?: ParcoursFormationGridDto;
    /**
     * 
     * @type {StatutApprenantGridDto}
     * @memberof ApprenantDoublonEditDto
     */
    currentStatutApprenant?: StatutApprenantGridDto;
    /**
     * 
     * @type {PassportDriveDto}
     * @memberof ApprenantDoublonEditDto
     */
    passportDrive?: PassportDriveDto;
    /**
     * 
     * @type {Array<ApprenantFichierGridDto>}
     * @memberof ApprenantDoublonEditDto
     */
    documentsReglementaires?: Array<ApprenantFichierGridDto> | null;
}

/**
 * Check if a given object implements the ApprenantDoublonEditDto interface.
 */
export function instanceOfApprenantDoublonEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApprenantDoublonEditDtoFromJSON(json: any): ApprenantDoublonEditDto {
    return ApprenantDoublonEditDtoFromJSONTyped(json, false);
}

export function ApprenantDoublonEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApprenantDoublonEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'nbInscriptions': !exists(json, 'nbInscriptions') ? undefined : json['nbInscriptions'],
        'nbContrats': !exists(json, 'nbContrats') ? undefined : json['nbContrats'],
        'nbSuivis': !exists(json, 'nbSuivis') ? undefined : json['nbSuivis'],
        'nbAbsences': !exists(json, 'nbAbsences') ? undefined : json['nbAbsences'],
        'nbDocuments': !exists(json, 'nbDocuments') ? undefined : json['nbDocuments'],
        'azureEmail': !exists(json, 'azureEmail') ? undefined : json['azureEmail'],
        'azureCreatedOn': !exists(json, 'azureCreatedOn') ? undefined : (json['azureCreatedOn'] === null ? null : new Date(json['azureCreatedOn'])),
        'bankAccountBeneficiaryId': !exists(json, 'bankAccountBeneficiaryId') ? undefined : json['bankAccountBeneficiaryId'],
        'bankAccountBeneficiaryName': !exists(json, 'bankAccountBeneficiaryName') ? undefined : json['bankAccountBeneficiaryName'],
        'permisB': !exists(json, 'permisB') ? undefined : json['permisB'],
        'permisAm': !exists(json, 'permisAm') ? undefined : json['permisAm'],
        'remarques': !exists(json, 'remarques') ? undefined : json['remarques'],
        'remarquesService': !exists(json, 'remarquesService') ? undefined : json['remarquesService'],
        'internes': !exists(json, 'internes') ? undefined : json['internes'],
        'telephoneApprenant': !exists(json, 'telephoneApprenant') ? undefined : TelephoneDtoFromJSON(json['telephoneApprenant']),
        'gsmApprenant': !exists(json, 'gsmApprenant') ? undefined : TelephoneDtoFromJSON(json['gsmApprenant']),
        'emailApprenant': !exists(json, 'emailApprenant') ? undefined : EmailDtoFromJSON(json['emailApprenant']),
        'idpri': !exists(json, 'idpri') ? undefined : json['idpri'],
        'pri': !exists(json, 'pri') ? undefined : json['pri'],
        'idari': !exists(json, 'idari') ? undefined : json['idari'],
        'ari': !exists(json, 'ari') ? undefined : json['ari'],
        'idcop': !exists(json, 'idcop') ? undefined : json['idcop'],
        'cop': !exists(json, 'cop') ? undefined : json['cop'],
        'idpricontratEnCours': !exists(json, 'idpricontratEnCours') ? undefined : json['idpricontratEnCours'],
        'pricontratEnCours': !exists(json, 'pricontratEnCours') ? undefined : json['pricontratEnCours'],
        'idaricontratEnCours': !exists(json, 'idaricontratEnCours') ? undefined : json['idaricontratEnCours'],
        'aricontratEnCours': !exists(json, 'aricontratEnCours') ? undefined : json['aricontratEnCours'],
        'representants': !exists(json, 'representants') ? undefined : (json['representants'] === null ? null : (json['representants'] as Array<any>).map(ApprenantRepresentantHopeGridDtoFromJSON)),
        'currentParcoursFormation': !exists(json, 'currentParcoursFormation') ? undefined : ParcoursFormationGridDtoFromJSON(json['currentParcoursFormation']),
        'currentStatutApprenant': !exists(json, 'currentStatutApprenant') ? undefined : StatutApprenantGridDtoFromJSON(json['currentStatutApprenant']),
        'passportDrive': !exists(json, 'passportDrive') ? undefined : PassportDriveDtoFromJSON(json['passportDrive']),
        'documentsReglementaires': !exists(json, 'documentsReglementaires') ? undefined : (json['documentsReglementaires'] === null ? null : (json['documentsReglementaires'] as Array<any>).map(ApprenantFichierGridDtoFromJSON)),
    };
}

export function ApprenantDoublonEditDtoToJSON(value?: ApprenantDoublonEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idapprenant': value.idapprenant,
        'nbInscriptions': value.nbInscriptions,
        'nbContrats': value.nbContrats,
        'nbSuivis': value.nbSuivis,
        'nbAbsences': value.nbAbsences,
        'nbDocuments': value.nbDocuments,
        'azureEmail': value.azureEmail,
        'azureCreatedOn': value.azureCreatedOn === undefined ? undefined : (value.azureCreatedOn === null ? null : value.azureCreatedOn.toISOString()),
        'bankAccountBeneficiaryId': value.bankAccountBeneficiaryId,
        'bankAccountBeneficiaryName': value.bankAccountBeneficiaryName,
        'permisB': value.permisB,
        'permisAm': value.permisAm,
        'remarques': value.remarques,
        'remarquesService': value.remarquesService,
        'internes': value.internes,
        'telephoneApprenant': TelephoneDtoToJSON(value.telephoneApprenant),
        'gsmApprenant': TelephoneDtoToJSON(value.gsmApprenant),
        'emailApprenant': EmailDtoToJSON(value.emailApprenant),
        'idpri': value.idpri,
        'pri': value.pri,
        'idari': value.idari,
        'ari': value.ari,
        'idcop': value.idcop,
        'cop': value.cop,
        'idpricontratEnCours': value.idpricontratEnCours,
        'pricontratEnCours': value.pricontratEnCours,
        'idaricontratEnCours': value.idaricontratEnCours,
        'aricontratEnCours': value.aricontratEnCours,
        'representants': value.representants === undefined ? undefined : (value.representants === null ? null : (value.representants as Array<any>).map(ApprenantRepresentantHopeGridDtoToJSON)),
        'currentParcoursFormation': ParcoursFormationGridDtoToJSON(value.currentParcoursFormation),
        'currentStatutApprenant': StatutApprenantGridDtoToJSON(value.currentStatutApprenant),
        'passportDrive': PassportDriveDtoToJSON(value.passportDrive),
        'documentsReglementaires': value.documentsReglementaires === undefined ? undefined : (value.documentsReglementaires === null ? null : (value.documentsReglementaires as Array<any>).map(ApprenantFichierGridDtoToJSON)),
    };
}

