import { Button, Classes, Colors, Dialog, Intent } from "@blueprintjs/core";
import { ButtonsBloc, FGCustomPanel, FGDateInput, FGListen, InlineButtonContainer, useApiEffect } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";

import { EIfapmeSide, FeuillePresenceApi } from "../../../../api";
import { FGWalterCheckboxInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../components";
import { useApiService, useManageError, useTheme, useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";
import { ETLCodes } from "../../../../locales";
import { exportFile } from "../../../../utils/b64download";

const StyledInlineButtonContainer = styled(InlineButtonContainer)`
  justify-content: flex-end;
`;

export interface IFeuillePresencePrintDialogProps {
  shown: boolean;
  onClose: () => void;
  idClasse?: number;
}

export const FeuillePresencePrintDialog: React.FunctionComponent<IFeuillePresencePrintDialogProps> = ({
  shown,
  onClose,
  idClasse
}) => {
  const { t } = useTl();
  const api = useApiService(FeuillePresenceApi);
  const [datesEditable, setDatesEditable] = React.useState(true);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const { manageError } = useManageError();

  const periodes = React.useMemo(
    () => [
      {
        value: 0,
        label: t(ETLCodes.Personnalisee)
      },
      {
        value: 1,
        label: t(ETLCodes.Semestre1)
      },
      {
        value: 2,
        label: t(ETLCodes.Semestre2)
      }
    ],
    [t]
  );
  const { ifapmeSide } = useTheme();
  const [typeCours, tcLoading] = useReferential(
    a => a.referentialGetTypeCours({ ifapmeSide: ifapmeSide === "hope" ? EIfapmeSide.Hope : EIfapmeSide.Walter }),
    false,
    [ifapmeSide]
  );

  const [data, loading] = useApiEffect(
    React.useCallback(() => {
      return api.feuillePresenceGetPrint({ idClasse });
    }, [api, idClasse])
  );

  const exportFeuille = React.useCallback(
    async values => {
      setSaveLoading(true);
      try {
        const res = await api.feuillePresenceExportAsync({ FeuillePresencePrintRequestDto: values });
        exportFile(res);
        setSaveLoading(false);
      } catch (e) {
        manageError(e);
        setSaveLoading(false);
      }
    },
    [api, manageError]
  );

  const [datesClasse, datesClasseLoading] = useApiEffect(
    React.useCallback(() => {
      return api.feuillePresenceGetMaxDates({ idClasse });
    }, [api, idClasse])
  );

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      periodeImpression: Yup.number().required(t(ETLCodes.Required)),
      dateDebut: Yup.date().required(t(ETLCodes.Required)),
      dateFin: Yup.date().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    <Dialog
      isOpen={shown}
      isCloseButtonShown={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={false}
      title={t(ETLCodes.ImpressionsLitePresence)}
      onClose={() => onClose()}
      style={{ paddingBottom: 0, width: 800, backgroundColor: Colors.WHITE }}
      enforceFocus={false}
    >
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          autoRequiredMark
          onSubmit={exportFeuille}
          initialValues={data}
          validationSchema={FormSchema}
          loading={loading && datesClasseLoading}
          hideButtons={true}
          minLabelWidth={295}
        >
          <FGListen
            field="periodeImpression"
            onChanged={async (value, formik) => {
              if (value === 0) {
                setDatesEditable(true);
              } else {
                setDatesEditable(false);
              }
              const dates = await api.feuillePresenceGetDates({ idClasse, periodeImpression: value });
              formik.setFieldValue("dateDebut", dates.dateDebut);
              formik.setFieldValue("dateFin", dates.dateFin);
            }}
          />
          <FGWalterSelectInput
            label={t(ETLCodes.PeriodeImpression)}
            items={periodes}
            name="periodeImpression"
            backgroundColor={Colors.WHITE}
          />
          <FGDateInput
            name="dateDebut"
            label={t(ETLCodes.DateDebut)}
            disabled={!datesEditable}
            minDate={datesClasse?.dateDebut}
            maxDate={datesClasse?.dateFin}
          />
          <FGDateInput
            name="dateFin"
            label={t(ETLCodes.DateFin)}
            disabled={!datesEditable}
            minDate={datesClasse?.dateDebut}
            maxDate={datesClasse?.dateFin}
          />
          <FGWalterSelectInput
            label={t(ETLCodes.TypeCoursAutreClasseAReferencer)}
            items={typeCours}
            loading={tcLoading}
            name="typeCours"
            backgroundColor={Colors.WHITE}
          />
          <FGWalterCheckboxInput name="checkCalc" label={t(ETLCodes.CalculTotalGrillePresence)} />
          <FGWalterCheckboxInput name="checkStat" label={t(ETLCodes.StatAgrementSubvention)} />
          <FGCustomPanel>
            {ctx => (
              <>
                <StyledInlineButtonContainer>
                  <ButtonsBloc>
                    <Button
                      text={t(ETLCodes.Imprimer)}
                      intent={Intent.PRIMARY}
                      loading={loading || saveLoading}
                      type="submit"
                    />
                  </ButtonsBloc>
                </StyledInlineButtonContainer>
              </>
            )}
          </FGCustomPanel>
        </SmallFormGenerator>
      </div>
    </Dialog>
  );
};
