/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbFormateurClasseGridDto
 */
export interface FcbFormateurClasseGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurClasseGridDto
     */
    idformateur?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurClasseGridDto
     */
    idclasse?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurClasseGridDto
     */
    codeClasse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurClasseGridDto
     */
    nomClasse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurClasseGridDto
     */
    codeMatiere?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurClasseGridDto
     */
    nomMatiere?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurClasseGridDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurClasseGridDto
     */
    dateFin?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurClasseGridDto
     */
    centre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurClasseGridDto
     */
    anneeScolaire?: string | null;
}

/**
 * Check if a given object implements the FcbFormateurClasseGridDto interface.
 */
export function instanceOfFcbFormateurClasseGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbFormateurClasseGridDtoFromJSON(json: any): FcbFormateurClasseGridDto {
    return FcbFormateurClasseGridDtoFromJSONTyped(json, false);
}

export function FcbFormateurClasseGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbFormateurClasseGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'codeClasse': !exists(json, 'codeClasse') ? undefined : json['codeClasse'],
        'nomClasse': !exists(json, 'nomClasse') ? undefined : json['nomClasse'],
        'codeMatiere': !exists(json, 'codeMatiere') ? undefined : json['codeMatiere'],
        'nomMatiere': !exists(json, 'nomMatiere') ? undefined : json['nomMatiere'],
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
        'centre': !exists(json, 'centre') ? undefined : json['centre'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
    };
}

export function FcbFormateurClasseGridDtoToJSON(value?: FcbFormateurClasseGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idformateur': value.idformateur,
        'idclasse': value.idclasse,
        'codeClasse': value.codeClasse,
        'nomClasse': value.nomClasse,
        'codeMatiere': value.codeMatiere,
        'nomMatiere': value.nomMatiere,
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
        'centre': value.centre,
        'anneeScolaire': value.anneeScolaire,
    };
}

