import { Colors } from "@blueprintjs/colors";
import { IDataTableColumn, showError } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import { css } from "styled-components";

import { DoublonsApi, DoublonsPersonneSearchDto, PersonneGridDto } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { SearchTablePage, SelectButton, UnordoredListColumn } from "../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTheme, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface IDoublonsNewPageProps {}

export const DoublonsNewPage: React.FunctionComponent<IDoublonsNewPageProps> = props => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(DoublonsApi);

  const [selectedIds, setSelectedIds] = React.useState<{ idpersonne1: number; idpersonne2: number }>({
    idpersonne1: null,
    idpersonne2: null
  });

  const apiFactory = useAbortableApiServiceFactory(DoublonsApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (nextSearch?: DoublonsPersonneSearchDto) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.doublonsSearchPersonne({ DoublonsPersonneSearchDto: nextSearch });
    },
    [apiFactory]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.Prenom2),
        fieldName: "prenom2"
      },
      {
        header: () => t(ETLCodes.Genre),
        fieldName: "codesexe"
      },
      {
        header: () => t(ETLCodes.Nationalite),
        fieldName: "nationalite"
      },
      {
        header: () => t(ETLCodes.RegistreNational),
        fieldName: "registreNational"
      },
      {
        header: () => t(ETLCodes.DateNaissance),
        fieldName: "dateNaissance"
      },
      {
        header: () => t(ETLCodes.DateDeces),
        fieldName: "dateDeces"
      },
      {
        header: () => t(ETLCodes.Forme),
        fieldName: "forme",
        render: (row: PersonneGridDto) => (
          <UnordoredListColumn
            values={[
              { value: row.isApprenant, label: t(ETLCodes.Apprenant) },
              { value: row.isFormateur, label: t(ETLCodes.Formateur) },
              { value: row.isRepresentant, label: t(ETLCodes.Representant) },
              { value: row.isTuteur, label: t(ETLCodes.Tuteur) },
              { value: row.isContact, label: t(ETLCodes.Contact) },
              { value: row.isChefEntreprise, label: t(ETLCodes.ChefEntreprise) },
              { value: row.isUser, label: t(ETLCodes.Collaborateur) },
              { value: row.isDelegueTutelle, label: t(ETLCodes.Referent) },
              { value: row.isConseillePedagogique, label: t(ETLCodes.ConseillerPedagogique) }
            ]}
          />
        )
      }
    ],
    [t]
  );

  const getCriteria = React.useCallback(async () => {
    let criterias = await api.doublonsGetSearchPersonneCriterias();
    return criterias;
  }, [api]);

  const onRowClick = React.useCallback(
    (row: PersonneGridDto) => {
      if (row.isConseillePedagogique || row.isDelegueTutelle || row.isUser) {
        showError(t(ETLCodes.RoleNonAutorise));
        return;
      }

      if (selectedIds.idpersonne1 === row.idpersonne) {
        setSelectedIds(prev => ({ ...prev, idpersonne1: null }));
        return;
      }

      if (selectedIds.idpersonne2 === row.idpersonne) {
        setSelectedIds(prev => ({ ...prev, idpersonne2: null }));
        return;
      }

      if (!!selectedIds.idpersonne1 && !!selectedIds.idpersonne2) {
        showError(t(ETLCodes.VeuillezDeselectionnerAutreLigne));
        return;
      }

      setSelectedIds(prev =>
        !prev.idpersonne1 ? { ...prev, idpersonne1: row.idpersonne } : { ...prev, idpersonne2: row.idpersonne }
      );
    },
    [selectedIds, t]
  );

  const { theme } = useTheme();
  const customizeRowStyleCallback = React.useCallback(
    (item: PersonneGridDto) => {
      const isSelected = selectedIds.idpersonne1 === item.idpersonne || selectedIds.idpersonne2 === item.idpersonne;

      return css`
        & * {
          color: ${isSelected ? Colors.WHITE : Colors.BLACK} !important;
        }

        & & > td {
          background-color: ${isSelected ? theme.primaryColor : "initial"} !important;
        }

        background-color: ${isSelected ? theme.primaryColor : "transparent"} !important;
      `;
    },
    [selectedIds.idpersonne1, selectedIds.idpersonne2, theme.primaryColor]
  );

  const rightElement = React.useMemo(
    () => (
      <SelectButton
        disabled={!selectedIds.idpersonne1 || !selectedIds.idpersonne2}
        onClick={() => history.push(`${ERoutes.doublon}/fusion/${selectedIds.idpersonne1}-${selectedIds.idpersonne2}`)}
      />
    ),
    [history, selectedIds.idpersonne1, selectedIds.idpersonne2]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteria}
      searchFunction={search}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.Doublons), route: ERoutes.doublon }, { text: t(ETLCodes.NouveauDoublon) }]}
      keyFieldName="idpersonne"
      sortKeys={{
        nom: "ASC",
        prenom: "ASC"
      }}
      searchStateInitialSearch={false}
      enableFilter={false}
      onRowClick={onRowClick}
      customizeRowStyle={customizeRowStyleCallback}
      rightElement={rightElement}
    />
  );
};
