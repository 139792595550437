import { Button, Radio, RadioGroup } from "@blueprintjs/core";
import { add, endOfDay, isAfter } from "date-fns";
import { FGCustomInput, FGCustomPanel, FGTextAreaInput, FGTextInput, FieldGroup, useFGFormik } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";

import {
  ContratApi,
  DashboardApi,
  EContentHidingMode,
  EIfapmeSide,
  ELevelName,
  EStatutContrat,
  ETypeContrat,
  LieuFormationApi,
  MetierApi,
  SuiviApi,
  SuiviHopeEditDto,
  SuiviHopeEditDtoFromJSON
} from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import {
  FGReadonlyHtmlViewer,
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  LinkButton,
  SmallFormGenerator,
  ViewButton
} from "../../../../components";
import { useAuth, useDialog } from "../../../../contexts";
import { useApiService, useCrudApi, useTabMessage, useTheme, useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";
import { ETLCodes } from "../../../../locales";
import { Guid } from "../../../../utils/guid";
import { SuiviActionDialog } from "../../apprenant/item/suiviApprenant/suiviAction/SuiviActionDialog";

const StatutContainer = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  > span {
    font-weight: bold;
    color: ${props => props.color};
    font-size: 14pt;
  }
`;

const RadioReadonlyContainer = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
`;

export interface SuiviDetailProps {
  idsuivi: string;
  editMode: boolean;
}

export const SuiviDetail: React.FunctionComponent<SuiviDetailProps> = ({ idsuivi, editMode }) => {
  const { t } = useTl();
  const api = useApiService(SuiviApi);
  const metierApi = useApiService(MetierApi);
  const lieuFormationApi = useApiService(LieuFormationApi);
  const contratApi = useApiService(ContratApi);
  const history = useHistory();
  const { search } = useLocation();
  const cfs = React.useMemo(() => new URLSearchParams(search).get("cfs"), [search]);
  const { messageValue, clearMessageValue } = useTabMessage(cfs);
  const identifier = React.useMemo(() => Guid.newGuid(), []);
  const { sendMessage } = useTabMessage(identifier);
  const { user } = useAuth();
  const { ifapmeSide, theme } = useTheme();
  const isHope = React.useMemo(() => ifapmeSide === "hope", [ifapmeSide]);
  const [lieurencontres, lrLoading] = useReferential(a => a.referentialGetLieuRencontres(), true, []);
  const [modes, mLoading] = useReferential(a => a.referentialGetModes(), true, []);
  const [dialogOpen, setDialogOpen] = React.useState(messageValue === "FromDashboard");

  const isCop = React.useMemo(() => user?.hopeMainLevelName === ELevelName.COP, [user?.hopeMainLevelName]);
  const isCalc = React.useMemo(() => user?.hopeMainLevelName === ELevelName.CALC, [user?.hopeMainLevelName]);

  const { data, saving, saveItem, deleting, deleteItem, loading } = useCrudApi<SuiviHopeEditDto>(
    React.useMemo(
      () => ({
        getApiFn: () =>
          +idsuivi <= 0
            ? SuiviHopeEditDtoFromJSON({ iduserService: user.iduser, contentHidingMode: EContentHidingMode.NONE })
            : api.suiviGetSuiviHope({ id: +idsuivi, idapprenant: 0 }),
        saveApiFn: values => api.suiviSaveSuiviHope({ SuiviHopeEditDto: values }),
        deleteApiFn: values => api.suiviDeleteSuiviHope({ id: values.idsuivi }),
        onDeletedRoute: () => ERoutes.suivi,
        onSaved: d => sendMessage(d.idsuivi, true),
        onSavedRoute: d => `${ERoutes.suivi}/${d.idsuivi}/detail/edit`
      }),
      [api, idsuivi, sendMessage, user.iduser]
    )
  );

  React.useEffect(() => {
    if (!!messageValue && messageValue === "FromDashboard" && !!data && !loading) {
      setDialogOpen(true);
      clearMessageValue();
    }
  }, [clearMessageValue, data, loading, messageValue]);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      dateSuivi: Yup.date()
        .required(t(ETLCodes.Required))
        .max(endOfDay(add(new Date(), { years: 1 })), t(ETLCodes.DateCannotBeGreaterThan1YearInFurture)),
      idapprenant: Yup.number().required(t(ETLCodes.Required)),
      idservice: Yup.number().required(t(ETLCodes.Required)),
      iduserService: Yup.number().required(t(ETLCodes.Required)),
      idtypeSuivi: Yup.number().required(t(ETLCodes.Required)),
      idsousTypeSuivi: Yup.number().required(t(ETLCodes.Required))
    });
  }, [t]);

  const formik = useFGFormik<SuiviHopeEditDto>({
    initialValues: data,
    onSubmit: saveItem,
    validationSchema: FormSchema
  });

  const [sousTypeSuivis, stsLoading, , rawSousTypeSuivis] = useReferential(
    a =>
      a.referentialGetSousTypeSuivis({
        idtypeSuivi: formik?.values?.idtypeSuivi,
        idsousTypeSuivi: data?.idsousTypeSuivi
      }),
    true,
    [formik?.values?.idtypeSuivi, data?.idsousTypeSuivi]
  );
  const [typeSuivis, tsLoading] = useReferential(
    a => a.referentialGetTypeSuivis({ side: EIfapmeSide.Hope, idtypeSuivi: data?.idtypeSuivi }),
    true,
    [data?.idtypeSuivi]
  );
  const [decisionCommissionTutelle, dctLoading] = useReferential(
    a => a.referentialGetDecisionCommissionTutelles({ id: data?.iddecisionCommissionTutelle }),
    true,
    [data?.iddecisionCommissionTutelle]
  );
  const [serviceUsers, suLoading] = useReferential(
    a =>
      a.referentialGetServiceUsers({
        request_body: !!formik?.values?.idservice ? [formik?.values?.idservice] : [],
        currentId: formik?.values?.iduserService
      }),
    true,
    [formik?.values?.idservice, formik?.values?.iduserService]
  );

  const [services, sLoading] = useReferential(
    a =>
      a.referentialGetServiceTutelle({
        userFilter: data?.creationUser === user.iduser,
        currentId: formik?.values?.idservice
      }),
    true,
    [data?.creationUser, user?.iduser, formik?.values?.idservice]
  );
  const [metiers, meLoading] = useReferential(a => a.referentialGetMetier(), true, []);
  const [apprenantContrats, acLoading, , rawContrats] = useReferential(
    a =>
      a.referentialGetApprenantContrats({
        idApprenant: formik?.values?.idapprenant,
        ETypeContrat: [
          ETypeContrat.CC,
          ETypeContrat.CA,
          ETypeContrat.CS,
          ETypeContrat.CF,
          ETypeContrat.DM,
          ETypeContrat.JI,
          ETypeContrat.PP
        ]
      }),
    true,
    [formik?.values?.idapprenant]
  );

  React.useEffect(() => {
    if (rawContrats?.length > 0 && !!formik?.values && !formik?.values?.idcontrat && +idsuivi <= 0) {
      let c = rawContrats.find(c => c.keyValue === EStatutContrat.EnCours);
      if (!c) {
        const maxId = Math.max.apply(rawContrats.map(c => +c.idValue));
        c = rawContrats.find(c => +c.idValue === maxId);
      }
      if (c) formik.setFieldValue("idcontrat", c.idValue);
    }
  }, [formik, idsuivi, rawContrats]);

  const fetchContratInfos = React.useCallback(async () => {
    if (!formik?.values?.idcontrat) return null;
    return await contratApi.contratGetContratInfosForSuivi({ idcontrat: formik.values.idcontrat });
  }, [contratApi, formik?.values?.idcontrat]);
  const { data: contratInfos } = useQuery(["suivi-contrat-info", formik?.values?.idcontrat], fetchContratInfos);

  const fetchMetierDisplayName = React.useCallback(async () => {
    if (!contratInfos?.idmetier) return "";
    return await metierApi.metierGetDisplayName({ id: contratInfos?.idmetier });
  }, [contratInfos?.idmetier, metierApi]);
  const { data: metierName, isFetching: mnLoading } = useQuery(
    ["metier-display-name", contratInfos?.idmetier],
    fetchMetierDisplayName
  );

  const fetchLfDisplayName = React.useCallback(async () => {
    if (!contratInfos?.idlieuFormation) return "";
    return await lieuFormationApi.lieuFormationGetDisplayName({ id: contratInfos?.idlieuFormation });
  }, [contratInfos?.idlieuFormation, lieuFormationApi]);
  const { data: lfName, isFetching: lfLoading } = useQuery(
    ["lieu-formation-display-name", contratInfos?.idlieuFormation],
    fetchLfDisplayName
  );

  React.useEffect(() => {
    if (formik?.values?.action && !formik?.initialValues?.action) {
      setDialogOpen(true);
    }
  }, [formik?.initialValues?.action, formik?.values?.action]);

  React.useEffect(() => {
    if (!formik?.values?.action && !!formik?.values?.suiviAction) {
      formik.setFieldValue("suiviAction", null);
    }
  }, [formik]);

  const dashboardApi = useApiService(DashboardApi);
  const [loadingMarquage, setLoadingMarquage] = React.useState(false);
  const { showDialogPromise } = useDialog();
  const marquerCommeFait = React.useCallback(
    async (idsuivi: number) => {
      const res = await showDialogPromise({
        title: t(ETLCodes.MarquerSuiviCommeFaitConfirmTitle),
        message: t(ETLCodes.MarquerSuiviCommeFaitConfirmMessage)
      });
      if (res === "yes") {
        setLoadingMarquage(true);
        await dashboardApi.dashboardMarquerSuiviFait({
          idsuivi
        });
        setLoadingMarquage(false);
        history.push(ERoutes.suivi);
      }
    },
    [dashboardApi, history, showDialogPromise, t]
  );

  const statutColor = React.useMemo(
    () =>
      !!formik?.values?.suiviAction?.dateRealisation
        ? theme.sucessColor
        : isAfter(formik?.values?.suiviAction?.dateEcheance, new Date())
        ? theme.warningColor
        : theme.dangerColor,
    [
      formik?.values?.suiviAction?.dateEcheance,
      formik?.values?.suiviAction?.dateRealisation,
      theme.dangerColor,
      theme.sucessColor,
      theme.warningColor
    ]
  );

  const statutText = React.useMemo(
    () =>
      t(
        !!formik?.values?.suiviAction?.dateRealisation
          ? ETLCodes.ActionRealisee
          : isAfter(formik?.values?.suiviAction?.dateEcheance, new Date())
          ? ETLCodes.ActionNonRealisee
          : ETLCodes.DateEcheanceDepassee
      ),
    [formik?.values?.suiviAction?.dateEcheance, formik?.values?.suiviAction?.dateRealisation, t]
  );

  const actionIsRealisee = React.useMemo(() => !!formik?.values?.suiviAction?.dateRealisation, [
    formik?.values?.suiviAction?.dateRealisation
  ]);

  const getROHidingMode = React.useCallback(
    (contentHidingMode: EContentHidingMode) => {
      switch (contentHidingMode) {
        case EContentHidingMode.NONE:
          return t(ETLCodes.Personne);
        case EContentHidingMode.OTHERS:
          return t(ETLCodes.Centres);
        case EContentHidingMode.ALL:
          return t(ETLCodes.TousSaufCopCalc);
      }
    },
    [t]
  );

  const contenuVisible = React.useCallback(
    ctx =>
      ctx?.formik?.values?.contentHidingMode === EContentHidingMode.NONE ||
      ctx?.formik?.values?.creationUser === user.iduser ||
      (isHope && ctx?.formik?.values?.contentHidingMode === EContentHidingMode.OTHERS) ||
      isCop ||
      isCalc,
    [isCalc, isCop, isHope, user.iduser]
  );

  return (
    <SmallFormGenerator
      formik={formik}
      loading={loading}
      saving={saving}
      editMode={editMode}
      onDelete={deleteItem}
      deleting={deleting}
      showDeleteButton={+idsuivi > 0 && !actionIsRealisee}
      onCancel={() => history.push(ERoutes.suivi)}
      minLabelWidth={150}
      editable={
        (data?.creationUser === user.iduser || data?.iduserService === user.iduser || +idsuivi <= 0) && !data?.fromSmile
      }
      additionalRightButtons={ctx =>
        user?.iduser === ctx?.formik?.values?.suiviAction?.idcollaborateurService &&
        +idsuivi > 0 && (
          <Button
            text={t(ETLCodes.MarquerCommeFait)}
            intent="success"
            icon="tick"
            onClick={() => marquerCommeFait(ctx?.formik?.values?.idsuivi)}
            loading={loadingMarquage}
          />
        )
      }
    >
      {!!formik?.values?.suiviAction && (
        <StatutContainer color={statutColor}>
          <span>{statutText}</span>
        </StatutContainer>
      )}
      <FieldGroup
        columns={2}
        fieldsetProps={{
          title: t(ETLCodes.SuiviApprenant)
        }}
      >
        <FieldGroup>
          <FGWalterSelectInput
            name="idservice"
            label={t(ETLCodes.Service)}
            items={services}
            loading={sLoading}
            readonly={actionIsRealisee}
            autoSelectIfOne
          />
          <FGWalterSelectInput
            name="iduserService"
            label={t(ETLCodes.RealisateurSuivi)}
            items={serviceUsers}
            loading={suLoading}
            readonly={actionIsRealisee}
          />
          <FGTextInput readonly name="encodeur" label={t(ETLCodes.Encodeur)} visible={+idsuivi > 0} />
          <FGWalterDateMaskInput name="dateSuivi" label={t(ETLCodes.DateSuivi)} readonly={actionIsRealisee} />
          <FGCustomPanel>
            {ctx => (
              <FGTextInput
                name="apprenant"
                label={t(ETLCodes.Apprenant)}
                readonly
                rightElement={
                  <ViewButton
                    onClick={() =>
                      window.open(
                        `#${ERoutes.apprenant}/${ctx?.formik?.values?.idapprenant}/signaletique/view`,
                        "_blank"
                      )
                    }
                  />
                }
              />
            )}
          </FGCustomPanel>
          <FGWalterSelectInput
            name="idcontrat"
            label={t(ETLCodes.Contrat)}
            items={apprenantContrats}
            loading={acLoading}
            disabled={!formik?.values?.idapprenant}
            readonly={actionIsRealisee}
          />
          {!!formik?.values?.idmetier ? (
            <FGWalterSelectInput
              name="idmetier"
              label={t(ETLCodes.Metier)}
              items={metiers}
              loading={meLoading}
              readonly
            />
          ) : (
            <FGTextInput
              name="metier"
              label={t(ETLCodes.Metier)}
              readonly
              formatReadOnlyValue={() => metierName}
              loading={mnLoading}
            />
          )}
          <FGTextInput
            name="lieuFormation"
            label={t(ETLCodes.LieuFormation)}
            readonly
            formatReadOnlyValue={() => lfName}
            loading={lfLoading}
          />
          <FGWalterSelectInput
            name="idtypeSuivi"
            label={t(ETLCodes.TypeSuivi)}
            items={typeSuivis}
            loading={tsLoading}
            readonly={actionIsRealisee}
          />
          <FGCustomPanel>
            {ctx => (
              <FGWalterSelectInput
                name="idsousTypeSuivi"
                label={t(ETLCodes.SousTypeSuivi)}
                items={sousTypeSuivis}
                loading={stsLoading}
                disabled={!ctx?.formik?.values?.idtypeSuivi}
                readonly={actionIsRealisee}
              />
            )}
          </FGCustomPanel>
          <FGWalterSelectInput
            name="iddecisionCommissionTutelle"
            label={t(ETLCodes.DecisionCommissionTutelle)}
            items={decisionCommissionTutelle}
            loading={dctLoading}
            readonly={actionIsRealisee}
            visible={
              ctx =>
                !!ctx?.formik?.values?.idsousTypeSuivi &&
                !!rawSousTypeSuivis &&
                +ctx?.formik?.values?.idsousTypeSuivi ===
                  +rawSousTypeSuivis.find(s => s.keyValue === "TUTELLE_25")?.idValue // Commission de tutelle
            }
          />
          <FGWalterSelectInput name="idmode" label={t(ETLCodes.Mode)} items={modes} loading={mLoading} />
          <FGWalterSelectInput
            name="idlieuRencontre"
            label={t(ETLCodes.LieuRencontre)}
            items={lieurencontres}
            loading={lrLoading}
          />
          <FGCustomPanel>
            {ctx => (
              <>
                <FGCustomInput label={t(ETLCodes.ContenuCachePour)}>
                  {ctx =>
                    ctx?.editMode ? (
                      <RadioGroup
                        selectedValue={ctx?.formik?.values?.contentHidingMode}
                        inline
                        onChange={e => ctx.formik.setFieldValue("contentHidingMode", e.currentTarget.value)}
                      >
                        <Radio label={t(ETLCodes.Personne)} value={EContentHidingMode.NONE}></Radio>
                        <Radio label={t(ETLCodes.Centres)} value={EContentHidingMode.OTHERS}></Radio>
                        <Radio
                          label={t(ETLCodes.TousSaufCopCalc)}
                          value={EContentHidingMode.ALL}
                          disabled={!isCop}
                        ></Radio>
                      </RadioGroup>
                    ) : (
                      <RadioReadonlyContainer>
                        {getROHidingMode(ctx?.formik?.values?.contentHidingMode)}
                      </RadioReadonlyContainer>
                    )
                  }
                </FGCustomInput>
                <FGWalterCheckboxInput
                  label={t(ETLCodes.Action)}
                  name="action"
                  readonly={actionIsRealisee}
                  helperText={
                    ctx?.formik?.values?.action &&
                    contenuVisible(ctx) && (
                      <LinkButton
                        text={ctx?.editMode && !actionIsRealisee ? t(ETLCodes.EditAction) : t(ETLCodes.ViewAction)}
                        onClick={() => {
                          setDialogOpen(true);
                        }}
                      />
                    )
                  }
                />
                <SuiviActionDialog
                  dto={ctx?.formik?.values?.suiviAction}
                  isOpen={dialogOpen}
                  onClose={toSave => {
                    setDialogOpen(false);
                    if (toSave) {
                      ctx?.formik.setFieldValue("suiviAction", toSave);
                    } else if (!ctx?.formik?.values?.suiviAction) {
                      ctx.formik.setFieldValue("action", false);
                    }
                  }}
                  enveloppeId={0}
                  editMode={ctx?.editMode && !actionIsRealisee && !data?.fromSmile}
                  editable={
                    (data?.creationUser === user.iduser || data?.iduserService === user.iduser || +idsuivi <= 0) &&
                    !actionIsRealisee &&
                    !data?.fromSmile
                  }
                />
              </>
            )}
          </FGCustomPanel>
        </FieldGroup>
        <FGCustomPanel>
          {ctx =>
            ctx.formik?.values?.fromSmile ? (
              <FGReadonlyHtmlViewer name="contenu" label={t(ETLCodes.Contenu)} />
            ) : (
              <FGTextAreaInput
                name="contenu"
                growVertically
                rows={22}
                label={t(ETLCodes.Contenu)}
                visible={contenuVisible(ctx?.formik?.values)}
              />
            )
          }
        </FGCustomPanel>
      </FieldGroup>
    </SmallFormGenerator>
  );
};
