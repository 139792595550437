/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRecherchePlanAgrementClasseDto
 */
export interface FcbRecherchePlanAgrementClasseDto {
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    idClasse?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    idStatutAgrement?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    codeConseillerAgrement?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    codeMetier?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    idMetier?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    idDegre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    typeCours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    specificite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    genre?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    idReferentiel?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    codeReferentiel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    financement?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    ppb?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    nbApprenantAgrement?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    dateCours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    remarqueCentreAgrement?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    gestionInterneAgrement?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    remarqueConseillerAgrement?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    idPlanAgrement?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    dateLocalisation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    statutLocalisationAgrement?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanAgrementClasseDto
     */
    coherent?: string | null;
}

/**
 * Check if a given object implements the FcbRecherchePlanAgrementClasseDto interface.
 */
export function instanceOfFcbRecherchePlanAgrementClasseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRecherchePlanAgrementClasseDtoFromJSON(json: any): FcbRecherchePlanAgrementClasseDto {
    return FcbRecherchePlanAgrementClasseDtoFromJSONTyped(json, false);
}

export function FcbRecherchePlanAgrementClasseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRecherchePlanAgrementClasseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idClasse': !exists(json, 'idClasse') ? undefined : json['idClasse'],
        'idStatutAgrement': !exists(json, 'idStatutAgrement') ? undefined : json['idStatutAgrement'],
        'codeConseillerAgrement': !exists(json, 'codeConseillerAgrement') ? undefined : json['codeConseillerAgrement'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'codeMetier': !exists(json, 'codeMetier') ? undefined : json['codeMetier'],
        'idMetier': !exists(json, 'idMetier') ? undefined : json['idMetier'],
        'idDegre': !exists(json, 'idDegre') ? undefined : json['idDegre'],
        'typeCours': !exists(json, 'typeCours') ? undefined : json['typeCours'],
        'specificite': !exists(json, 'specificite') ? undefined : json['specificite'],
        'genre': !exists(json, 'genre') ? undefined : json['genre'],
        'idReferentiel': !exists(json, 'idReferentiel') ? undefined : json['idReferentiel'],
        'codeReferentiel': !exists(json, 'codeReferentiel') ? undefined : json['codeReferentiel'],
        'financement': !exists(json, 'financement') ? undefined : json['financement'],
        'ppb': !exists(json, 'ppb') ? undefined : json['ppb'],
        'nbApprenantAgrement': !exists(json, 'nbApprenantAgrement') ? undefined : json['nbApprenantAgrement'],
        'dateCours': !exists(json, 'dateCours') ? undefined : json['dateCours'],
        'remarqueCentreAgrement': !exists(json, 'remarqueCentreAgrement') ? undefined : json['remarqueCentreAgrement'],
        'gestionInterneAgrement': !exists(json, 'gestionInterneAgrement') ? undefined : json['gestionInterneAgrement'],
        'remarqueConseillerAgrement': !exists(json, 'remarqueConseillerAgrement') ? undefined : json['remarqueConseillerAgrement'],
        'idPlanAgrement': !exists(json, 'idPlanAgrement') ? undefined : json['idPlanAgrement'],
        'dateLocalisation': !exists(json, 'dateLocalisation') ? undefined : json['dateLocalisation'],
        'statutLocalisationAgrement': !exists(json, 'statutLocalisationAgrement') ? undefined : json['statutLocalisationAgrement'],
        'coherent': !exists(json, 'coherent') ? undefined : json['coherent'],
    };
}

export function FcbRecherchePlanAgrementClasseDtoToJSON(value?: FcbRecherchePlanAgrementClasseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idClasse': value.idClasse,
        'idStatutAgrement': value.idStatutAgrement,
        'codeConseillerAgrement': value.codeConseillerAgrement,
        'nom': value.nom,
        'codeMetier': value.codeMetier,
        'idMetier': value.idMetier,
        'idDegre': value.idDegre,
        'typeCours': value.typeCours,
        'specificite': value.specificite,
        'genre': value.genre,
        'idReferentiel': value.idReferentiel,
        'codeReferentiel': value.codeReferentiel,
        'financement': value.financement,
        'ppb': value.ppb,
        'nbApprenantAgrement': value.nbApprenantAgrement,
        'dateCours': value.dateCours,
        'remarqueCentreAgrement': value.remarqueCentreAgrement,
        'gestionInterneAgrement': value.gestionInterneAgrement,
        'remarqueConseillerAgrement': value.remarqueConseillerAgrement,
        'idPlanAgrement': value.idPlanAgrement,
        'dateLocalisation': value.dateLocalisation,
        'statutLocalisationAgrement': value.statutLocalisationAgrement,
        'coherent': value.coherent,
    };
}

