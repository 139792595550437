/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileUploadDto } from './FileUploadDto';
import {
    FileUploadDtoFromJSON,
    FileUploadDtoFromJSONTyped,
    FileUploadDtoToJSON,
} from './FileUploadDto';

/**
 * 
 * @export
 * @interface FcbFormateurAgrementDto
 */
export interface FcbFormateurAgrementDto {
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurAgrementDto
     */
    idformateurAgrement?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurAgrementDto
     */
    idcentre?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurAgrementDto
     */
    idconseillerPedagogique?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurAgrementDto
     */
    matiereDemandee?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurAgrementDto
     */
    matiereAccordee?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurAgrementDto
     */
    dateDemande?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurAgrementDto
     */
    datedebutAgrement?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurAgrementDto
     */
    datefinAgrement?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurAgrementDto
     */
    avis?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurAgrementDto
     */
    avisRetrait?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurAgrementDto
     */
    idetatAgrement?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurAgrementDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurAgrementDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurAgrementDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurAgrementDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurAgrementDto
     */
    idformateur?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurAgrementDto
     */
    idfichier?: number | null;
    /**
     * 
     * @type {FileUploadDto}
     * @memberof FcbFormateurAgrementDto
     */
    fichier?: FileUploadDto;
}

/**
 * Check if a given object implements the FcbFormateurAgrementDto interface.
 */
export function instanceOfFcbFormateurAgrementDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbFormateurAgrementDtoFromJSON(json: any): FcbFormateurAgrementDto {
    return FcbFormateurAgrementDtoFromJSONTyped(json, false);
}

export function FcbFormateurAgrementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbFormateurAgrementDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idformateurAgrement': !exists(json, 'idformateurAgrement') ? undefined : json['idformateurAgrement'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'idconseillerPedagogique': !exists(json, 'idconseillerPedagogique') ? undefined : json['idconseillerPedagogique'],
        'matiereDemandee': !exists(json, 'matiereDemandee') ? undefined : json['matiereDemandee'],
        'matiereAccordee': !exists(json, 'matiereAccordee') ? undefined : json['matiereAccordee'],
        'dateDemande': !exists(json, 'dateDemande') ? undefined : (json['dateDemande'] === null ? null : new Date(json['dateDemande'])),
        'datedebutAgrement': !exists(json, 'datedebutAgrement') ? undefined : (json['datedebutAgrement'] === null ? null : new Date(json['datedebutAgrement'])),
        'datefinAgrement': !exists(json, 'datefinAgrement') ? undefined : (json['datefinAgrement'] === null ? null : new Date(json['datefinAgrement'])),
        'avis': !exists(json, 'avis') ? undefined : json['avis'],
        'avisRetrait': !exists(json, 'avisRetrait') ? undefined : json['avisRetrait'],
        'idetatAgrement': !exists(json, 'idetatAgrement') ? undefined : json['idetatAgrement'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'fichier': !exists(json, 'fichier') ? undefined : FileUploadDtoFromJSON(json['fichier']),
    };
}

export function FcbFormateurAgrementDtoToJSON(value?: FcbFormateurAgrementDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idformateurAgrement': value.idformateurAgrement,
        'idcentre': value.idcentre,
        'idconseillerPedagogique': value.idconseillerPedagogique,
        'matiereDemandee': value.matiereDemandee,
        'matiereAccordee': value.matiereAccordee,
        'dateDemande': value.dateDemande === undefined ? undefined : (value.dateDemande === null ? null : value.dateDemande.toISOString()),
        'datedebutAgrement': value.datedebutAgrement === undefined ? undefined : (value.datedebutAgrement === null ? null : value.datedebutAgrement.toISOString()),
        'datefinAgrement': value.datefinAgrement === undefined ? undefined : (value.datefinAgrement === null ? null : value.datefinAgrement.toISOString()),
        'avis': value.avis,
        'avisRetrait': value.avisRetrait,
        'idetatAgrement': value.idetatAgrement,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'idformateur': value.idformateur,
        'idfichier': value.idfichier,
        'fichier': FileUploadDtoToJSON(value.fichier),
    };
}

