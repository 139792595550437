/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DemandeFormationEditDto } from './DemandeFormationEditDto';
import {
    DemandeFormationEditDtoFromJSON,
    DemandeFormationEditDtoFromJSONTyped,
    DemandeFormationEditDtoToJSON,
} from './DemandeFormationEditDto';

/**
 * 
 * @export
 * @interface DemandeFormationCreateDto
 */
export interface DemandeFormationCreateDto {
    /**
     * 
     * @type {number}
     * @memberof DemandeFormationCreateDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {Array<DemandeFormationEditDto>}
     * @memberof DemandeFormationCreateDto
     */
    demandes?: Array<DemandeFormationEditDto> | null;
}

/**
 * Check if a given object implements the DemandeFormationCreateDto interface.
 */
export function instanceOfDemandeFormationCreateDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DemandeFormationCreateDtoFromJSON(json: any): DemandeFormationCreateDto {
    return DemandeFormationCreateDtoFromJSONTyped(json, false);
}

export function DemandeFormationCreateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DemandeFormationCreateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'demandes': !exists(json, 'demandes') ? undefined : (json['demandes'] === null ? null : (json['demandes'] as Array<any>).map(DemandeFormationEditDtoFromJSON)),
    };
}

export function DemandeFormationCreateDtoToJSON(value?: DemandeFormationCreateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idapprenant': value.idapprenant,
        'demandes': value.demandes === undefined ? undefined : (value.demandes === null ? null : (value.demandes as Array<any>).map(DemandeFormationEditDtoToJSON)),
    };
}

