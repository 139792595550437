/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EPrintingQueueStatus } from './EPrintingQueueStatus';
import {
    EPrintingQueueStatusFromJSON,
    EPrintingQueueStatusFromJSONTyped,
    EPrintingQueueStatusToJSON,
} from './EPrintingQueueStatus';
import type { EPrintingType } from './EPrintingType';
import {
    EPrintingTypeFromJSON,
    EPrintingTypeFromJSONTyped,
    EPrintingTypeToJSON,
} from './EPrintingType';

/**
 * 
 * @export
 * @interface PrintingQueueDto
 */
export interface PrintingQueueDto {
    /**
     * 
     * @type {number}
     * @memberof PrintingQueueDto
     */
    idprintingQueue?: number;
    /**
     * 
     * @type {EPrintingQueueStatus}
     * @memberof PrintingQueueDto
     */
    status?: EPrintingQueueStatus;
    /**
     * 
     * @type {number}
     * @memberof PrintingQueueDto
     */
    idprinting?: number;
    /**
     * 
     * @type {string}
     * @memberof PrintingQueueDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PrintingQueueDto
     */
    displayName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PrintingQueueDto
     */
    idfichier?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PrintingQueueDto
     */
    idreportLog?: number | null;
    /**
     * 
     * @type {EPrintingType}
     * @memberof PrintingQueueDto
     */
    printingType?: EPrintingType;
    /**
     * 
     * @type {Date}
     * @memberof PrintingQueueDto
     */
    creationDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PrintingQueueDto
     */
    hidden?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PrintingQueueDto
     */
    temporary?: boolean;
}

/**
 * Check if a given object implements the PrintingQueueDto interface.
 */
export function instanceOfPrintingQueueDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PrintingQueueDtoFromJSON(json: any): PrintingQueueDto {
    return PrintingQueueDtoFromJSONTyped(json, false);
}

export function PrintingQueueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrintingQueueDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idprintingQueue': !exists(json, 'idprintingQueue') ? undefined : json['idprintingQueue'],
        'status': !exists(json, 'status') ? undefined : EPrintingQueueStatusFromJSON(json['status']),
        'idprinting': !exists(json, 'idprinting') ? undefined : json['idprinting'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'idreportLog': !exists(json, 'idreportLog') ? undefined : json['idreportLog'],
        'printingType': !exists(json, 'printingType') ? undefined : EPrintingTypeFromJSON(json['printingType']),
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'hidden': !exists(json, 'hidden') ? undefined : json['hidden'],
        'temporary': !exists(json, 'temporary') ? undefined : json['temporary'],
    };
}

export function PrintingQueueDtoToJSON(value?: PrintingQueueDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idprintingQueue': value.idprintingQueue,
        'status': EPrintingQueueStatusToJSON(value.status),
        'idprinting': value.idprinting,
        'name': value.name,
        'displayName': value.displayName,
        'idfichier': value.idfichier,
        'idreportLog': value.idreportLog,
        'printingType': EPrintingTypeToJSON(value.printingType),
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'hidden': value.hidden,
        'temporary': value.temporary,
    };
}

