import { FGSuggestTextInput, IFGSuggestTextInputProps } from "nsitools-react";
import * as React from "react";
import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";

export interface IFGWalterSuggestTextInputProps extends IFGSuggestTextInputProps {}

export const FGWalterSuggestTextInput: React.FunctionComponent<IFGWalterSuggestTextInputProps> = props => {
  const { t } = useTl();
  return <FGSuggestTextInput placeholder={t(ETLCodes.Search)} preventSubmitOnEnter {...props} />;
};
