import * as React from "react";
import { useHistory } from "react-router";
import { ModuleCreaApi, ModuleCreaEditDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import * as Yup from "yup";
import { ETLCodes } from "../../../../../locales";
import { FGWalterCheckboxInput, SmallFormGenerator } from "../../../../../components";
import { FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";

export interface IModuleCreaDetailPageProps {
  editMode: boolean;
  idmoduleCrea: number;
}

export const ModuleCreaDetailPage: React.FunctionComponent<IModuleCreaDetailPageProps> = ({
  idmoduleCrea,
  editMode
}) => {
  const { t } = useTl();
  const api = useApiService(ModuleCreaApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idmoduleCrea <= 0
        ? ModuleCreaEditDtoFromJSON({ idmoduleCrea: 0, actif: true })
        : api.moduleCreaGetModuleCrea({ id: +idmoduleCrea }),
    saveApiFn: d => api.moduleCreaSaveModuleCrea({ ModuleCreaEditDto: d }),
    onSavedRoute: d => `${ERoutes.moduleCrea}/${d.idmoduleCrea}/detail/edit`,
    deleteApiFn: d => api.moduleCreaDeleteModuleCrea({ id: d.idmoduleCrea }),
    onDeletedRoute: () => ERoutes.moduleCrea
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      code: Yup.string().required(t(ETLCodes.Required)),
      libelle: Yup.string().required(t(ETLCodes.Required)),
      actif: Yup.boolean().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={editMode}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(ERoutes.moduleCrea)}
        onDelete={deleteItem}
        showDeleteButton={+idmoduleCrea > 0}
        saving={saving}
        deleting={deleting}
      >
        <FieldGroup columns={2}>
          <FieldGroup>
            <FGTextInput name="code" label={t(ETLCodes.Code)} maxLength={25} />
            <FGTextAreaInput name="libelle" label={t(ETLCodes.Libelle)} maxLength={500} />
            <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
          </FieldGroup>
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};

export default ModuleCreaDetailPage;
