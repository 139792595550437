/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbSmsUtilitaireGridDto
 */
export interface FcbSmsUtilitaireGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbSmsUtilitaireGridDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbSmsUtilitaireGridDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbSmsUtilitaireGridDto
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbSmsUtilitaireGridDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbSmsUtilitaireGridDto
     */
    dateEnvoi?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbSmsUtilitaireGridDto
     */
    etat?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbSmsUtilitaireGridDto
     */
    nbredestinataire?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbSmsUtilitaireGridDto
     */
    nbreenvoye?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbSmsUtilitaireGridDto
     */
    nbreerreur?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbSmsUtilitaireGridDto
     */
    message?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbSmsUtilitaireGridDto
     */
    idcentre?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbSmsUtilitaireGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbSmsUtilitaireGridDto
     */
    idlot?: number | null;
}

/**
 * Check if a given object implements the FcbSmsUtilitaireGridDto interface.
 */
export function instanceOfFcbSmsUtilitaireGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbSmsUtilitaireGridDtoFromJSON(json: any): FcbSmsUtilitaireGridDto {
    return FcbSmsUtilitaireGridDtoFromJSONTyped(json, false);
}

export function FcbSmsUtilitaireGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbSmsUtilitaireGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'dateEnvoi': !exists(json, 'dateEnvoi') ? undefined : (json['dateEnvoi'] === null ? null : new Date(json['dateEnvoi'])),
        'etat': !exists(json, 'etat') ? undefined : json['etat'],
        'nbredestinataire': !exists(json, 'nbredestinataire') ? undefined : json['nbredestinataire'],
        'nbreenvoye': !exists(json, 'nbreenvoye') ? undefined : json['nbreenvoye'],
        'nbreerreur': !exists(json, 'nbreerreur') ? undefined : json['nbreerreur'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'idlot': !exists(json, 'idlot') ? undefined : json['idlot'],
    };
}

export function FcbSmsUtilitaireGridDtoToJSON(value?: FcbSmsUtilitaireGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'type': value.type,
        'description': value.description,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'dateEnvoi': value.dateEnvoi === undefined ? undefined : (value.dateEnvoi === null ? null : value.dateEnvoi.toISOString()),
        'etat': value.etat,
        'nbredestinataire': value.nbredestinataire,
        'nbreenvoye': value.nbreenvoye,
        'nbreerreur': value.nbreerreur,
        'message': value.message,
        'idcentre': value.idcentre,
        'nom': value.nom,
        'idlot': value.idlot,
    };
}

