import { Card, Colors } from "@blueprintjs/core";
import { DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";
import { css } from "styled-components";

import { ClasseHoraireApi, ClasseHoraireMatiereSearch, FcbClasseHoraireMatiereGridDto } from "../../../../../api";
import { useEventsContext } from "../../../../../contexts";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IClasseHoraireMatiereListProps {}

export const ClasseHoraireMatiereList: React.FunctionComponent<IClasseHoraireMatiereListProps> = () => {
  const { id } = useParams<{ id: string; tab: string; state: string }>();

  const { t } = useTl();
  const api = useApiService(ClasseHoraireApi);

  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: false,
    enableFilter: false,
    availablePageSizes: [9999],
    pageSize: 9999,
    sortKeys: { code: "ASC" }
  });

  const searchFunction = React.useCallback(
    (sObj?: ClasseHoraireMatiereSearch) => {
      sObj.idClasse = +id;
      return api.classeHoraireGetHoraireMatieres({ ClasseHoraireMatiereSearch: sObj });
    },
    [api, id]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code"
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "description"
      },
      {
        header: () => t(ETLCodes.Formateur),
        fieldName: "formateur"
      },
      {
        header: () => t(ETLCodes.NbHeure),
        fieldName: "nbHeures"
      },
      {
        header: () => t(ETLCodes.NbHeureReelle),
        fieldName: "nbHeuresReelles"
      }
    ],
    [t]
  );

  React.useEffect(() => {
    subscribeToEvent("CLASSE_HORAIRE_SAVED", search);
    subscribeToEvent("CLASSE_HORAIRE_ACTIONS", search);
    subscribeToEvent("CLASSE_HORAIRE_CALENDRIER_ITEM_SAVED", search);
    return () => {
      unsubscribeEvent("CLASSE_HORAIRE_SAVED", search);
      unsubscribeEvent("CLASSE_HORAIRE_ACTIONS", search);
      subscribeToEvent("CLASSE_HORAIRE_CALENDRIER_ITEM_SAVED", search);
    };
  }, [search, subscribeToEvent, unsubscribeEvent]);

  const getRowStyles = React.useCallback((item: FcbClasseHoraireMatiereGridDto) => {
    const differentHours = item.nbHeures !== item.nbHeuresReelles;
    return css`
      & * {
        color: ${differentHours ? Colors.RED3 + " !important" : null};
      }
    `;
  }, []);

  return (
    <>
      {+id > 0 && (
        <Card>
          <FieldSet title={t(ETLCodes.Matieres)}>
            <DataTable
              dateFormat="dd-MM-yyyy"
              tableState={tableState}
              loading={loading}
              columns={columns}
              customizeRowStyle={getRowStyles}
            />
          </FieldSet>
        </Card>
      )}
    </>
  );
};
