/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ResultErrorLevel {
    None = 'None',
    Info = 'Info',
    Warning = 'Warning',
    NotFound = 'NotFound',
    Error = 'Error'
}


export function ResultErrorLevelFromJSON(json: any): ResultErrorLevel {
    return ResultErrorLevelFromJSONTyped(json, false);
}

export function ResultErrorLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResultErrorLevel {
    return json as ResultErrorLevel;
}

export function ResultErrorLevelToJSON(value?: ResultErrorLevel | null): any {
    return value as any;
}

