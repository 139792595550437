/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChefEntrepriseSelectorGridDto
 */
export interface ChefEntrepriseSelectorGridDto {
    /**
     * 
     * @type {number}
     * @memberof ChefEntrepriseSelectorGridDto
     */
    idchefEntreprise?: number;
    /**
     * 
     * @type {number}
     * @memberof ChefEntrepriseSelectorGridDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseSelectorGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseSelectorGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseSelectorGridDto
     */
    nationalite?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ChefEntrepriseSelectorGridDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseSelectorGridDto
     */
    numeroNational?: string | null;
}

/**
 * Check if a given object implements the ChefEntrepriseSelectorGridDto interface.
 */
export function instanceOfChefEntrepriseSelectorGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChefEntrepriseSelectorGridDtoFromJSON(json: any): ChefEntrepriseSelectorGridDto {
    return ChefEntrepriseSelectorGridDtoFromJSONTyped(json, false);
}

export function ChefEntrepriseSelectorGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChefEntrepriseSelectorGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idchefEntreprise': !exists(json, 'idchefEntreprise') ? undefined : json['idchefEntreprise'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'nationalite': !exists(json, 'nationalite') ? undefined : json['nationalite'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'numeroNational': !exists(json, 'numeroNational') ? undefined : json['numeroNational'],
    };
}

export function ChefEntrepriseSelectorGridDtoToJSON(value?: ChefEntrepriseSelectorGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idchefEntreprise': value.idchefEntreprise,
        'idpersonne': value.idpersonne,
        'nom': value.nom,
        'prenom': value.prenom,
        'nationalite': value.nationalite,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'numeroNational': value.numeroNational,
    };
}

