/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SiegeSocialFichierDto
 */
export interface SiegeSocialFichierDto {
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialFichierDto
     */
    idsiegeSocialFichier?: number;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialFichierDto
     */
    idsiegeSocial?: number;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialFichierDto
     */
    idfichier?: number;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialFichierDto
     */
    idtypeSiegeSocialFichier?: number;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialFichierDto
     */
    fileName?: string | null;
}

/**
 * Check if a given object implements the SiegeSocialFichierDto interface.
 */
export function instanceOfSiegeSocialFichierDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SiegeSocialFichierDtoFromJSON(json: any): SiegeSocialFichierDto {
    return SiegeSocialFichierDtoFromJSONTyped(json, false);
}

export function SiegeSocialFichierDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiegeSocialFichierDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsiegeSocialFichier': !exists(json, 'idsiegeSocialFichier') ? undefined : json['idsiegeSocialFichier'],
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'idtypeSiegeSocialFichier': !exists(json, 'idtypeSiegeSocialFichier') ? undefined : json['idtypeSiegeSocialFichier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
    };
}

export function SiegeSocialFichierDtoToJSON(value?: SiegeSocialFichierDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsiegeSocialFichier': value.idsiegeSocialFichier,
        'idsiegeSocial': value.idsiegeSocial,
        'idfichier': value.idfichier,
        'idtypeSiegeSocialFichier': value.idtypeSiegeSocialFichier,
        'fileName': value.fileName,
    };
}

