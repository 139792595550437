import { Tag } from "@blueprintjs/core";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { DashboardApi, EDashboard, EIfapmeSide } from "../../api";
import { LoadingDots } from "../../components";
import { useEventsContext } from "../../contexts";
import { useApiService, useTheme, useTl } from "../../hooks";
import { ETLCodes } from "../../locales";

const TabTitleContainer = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 5px;
  }
`;

export interface IDashboardDossiersTabTitleProps {}

export const DashboardDossiersTabTitle: React.FunctionComponent<IDashboardDossiersTabTitleProps> = props => {
  const api = useApiService(DashboardApi);
  const { ifapmeSide } = useTheme();
  const { t } = useTl();

  const fetchCountSorties = React.useCallback(async () => {
    return await api.dashboardGetCounts({
      ifapmeSide: ifapmeSide === "walter" ? EIfapmeSide.Walter : EIfapmeSide.Hope,
      dashboard: EDashboard.DossiersSorties
    });
  }, [api, ifapmeSide]);
  const { refetch: refetchCountSorties, isFetching: loadingSorties, data: nbToDisplaySorties } = useQuery(
    ["dashboard-count-sorties"],
    fetchCountSorties,
    {
      retry: false
    }
  );

  const fetchCountPostFormation = React.useCallback(async () => {
    return await api.dashboardGetCounts({
      ifapmeSide: ifapmeSide === "walter" ? EIfapmeSide.Walter : EIfapmeSide.Hope,
      dashboard: EDashboard.DossiersPostFormation
    });
  }, [api, ifapmeSide]);
  const {
    refetch: refetchCountPostFormation,
    isFetching: loadingPostFormation,
    data: nbToDisplayPostFormation
  } = useQuery(["dashboard-count-post-formation"], fetchCountPostFormation, {
    retry: false
  });

  const fetchCountFinAgrement = React.useCallback(async () => {
    return await api.dashboardGetCounts({
      ifapmeSide: ifapmeSide === "walter" ? EIfapmeSide.Walter : EIfapmeSide.Hope,
      dashboard: EDashboard.DossiersFinAgrement
    });
  }, [api, ifapmeSide]);
  const { refetch: refetchCountFinAgrement, isFetching: loadingFinAgrement, data: nbToDisplayFinAgrement } = useQuery(
    ["dashboard-count-fin-agrement"],
    fetchCountFinAgrement,
    {
      retry: false
    }
  );

  const refetchCounts = React.useCallback(async () => {
    await refetchCountSorties();
    await refetchCountPostFormation();
    await refetchCountFinAgrement();
  }, [refetchCountFinAgrement, refetchCountPostFormation, refetchCountSorties]);

  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();
  React.useEffect(() => {
    subscribeToEvent("DASHBOARD_COUNTS_REFRESH_Dossiers" as any, refetchCounts);
    return () => {
      unsubscribeEvent("DASHBOARD_COUNTS_REFRESH_Dossiers" as any, refetchCounts);
    };
  }, [refetchCounts, subscribeToEvent, unsubscribeEvent]);

  const loading = React.useMemo(() => loadingSorties || loadingPostFormation || loadingFinAgrement, [
    loadingFinAgrement,
    loadingPostFormation,
    loadingSorties
  ]);

  const nbToDisplay = React.useMemo(() => {
    return +(nbToDisplaySorties ?? 0) + +(nbToDisplayPostFormation ?? 0) + +(nbToDisplayFinAgrement ?? 0);
  }, [nbToDisplayFinAgrement, nbToDisplayPostFormation, nbToDisplaySorties]);

  return (
    <TabTitleContainer>
      <span>{t(ETLCodes.Dossiers)}</span>
      {loading ? (
        <Tag round intent="primary">
          <LoadingDots colorScheme="white" size={3} />
        </Tag>
      ) : (
        nbToDisplay > 0 && (
          <Tag round intent="primary">
            {nbToDisplay}
          </Tag>
        )
      )}
    </TabTitleContainer>
  );
};
