/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TelephoneDto
 */
export interface TelephoneDto {
    /**
     * 
     * @type {number}
     * @memberof TelephoneDto
     */
    idtelephone?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TelephoneDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {string}
     * @memberof TelephoneDto
     */
    numero?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TelephoneDto
     */
    idtypeTelephone?: number;
    /**
     * 
     * @type {string}
     * @memberof TelephoneDto
     */
    typeTelephone?: string | null;
}

/**
 * Check if a given object implements the TelephoneDto interface.
 */
export function instanceOfTelephoneDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TelephoneDtoFromJSON(json: any): TelephoneDto {
    return TelephoneDtoFromJSONTyped(json, false);
}

export function TelephoneDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelephoneDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idtelephone': !exists(json, 'idtelephone') ? undefined : json['idtelephone'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'numero': !exists(json, 'numero') ? undefined : json['numero'],
        'idtypeTelephone': !exists(json, 'idtypeTelephone') ? undefined : json['idtypeTelephone'],
        'typeTelephone': !exists(json, 'typeTelephone') ? undefined : json['typeTelephone'],
    };
}

export function TelephoneDtoToJSON(value?: TelephoneDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idtelephone': value.idtelephone,
        'idpersonne': value.idpersonne,
        'numero': value.numero,
        'idtypeTelephone': value.idtypeTelephone,
        'typeTelephone': value.typeTelephone,
    };
}

