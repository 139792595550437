/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbDegreGridDto
 */
export interface FcbDegreGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbDegreGridDto
     */
    idDegre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDegreGridDto
     */
    stade?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDegreGridDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbDegreGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbDegreGridDto interface.
 */
export function instanceOfFcbDegreGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbDegreGridDtoFromJSON(json: any): FcbDegreGridDto {
    return FcbDegreGridDtoFromJSONTyped(json, false);
}

export function FcbDegreGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbDegreGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idDegre': !exists(json, 'idDegre') ? undefined : json['idDegre'],
        'stade': !exists(json, 'stade') ? undefined : json['stade'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbDegreGridDtoToJSON(value?: FcbDegreGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idDegre': value.idDegre,
        'stade': value.stade,
        'libelle': value.libelle,
        'actif': value.actif,
    };
}

