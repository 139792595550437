import { FGEmpty, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { FcbProfessionFormateurDtoFromJSON, ProfessionFormateurApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterCheckboxInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

interface IProfessionFormateurDetailPageProps {
  editMode: boolean;
  idProfession: number;
}

export const ProfessionFormateurDetailPage: React.FunctionComponent<IProfessionFormateurDetailPageProps> = ({
  editMode,
  idProfession
}) => {
  const { t } = useTl();
  const api = useApiService(ProfessionFormateurApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idProfession <= 0
        ? FcbProfessionFormateurDtoFromJSON({ idProfession: 0 })
        : api.professionFormateurGet({ id: +idProfession }),
    saveApiFn: d => api.professionFormateurSave({ FcbProfessionFormateurDto: d }),
    onSavedRoute: d => `${ERoutes.professionFormateur}/${d.idprofession}/detail/edit`,
    deleteApiFn: d => api.professionFormateurDelete({ id: d.idprofession }),
    onDeletedRoute: () => ERoutes.professionFormateur
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      libelle: Yup.string()
        .required(t(ETLCodes.Required))
        .max(50)
        .test("code-not-exists", t(ETLCodes.LibelleExistsError), async value => {
          if (value === data?.libelle) return true;
          const validationError = await api.professionFormateurLibelleProfessionFormateurExists({ libelle: value });
          return validationError.isValid;
        })
    });
  }, [api, data, t]);

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={editMode}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(ERoutes.professionFormateur)}
        onDelete={deleteItem}
        showDeleteButton={+idProfession > 0}
        saving={saving}
        deleting={deleting}
      >
        <FieldGroup columns={2}>
          <FGTextInput name="libelle" label={t(ETLCodes.Libelle)} maxLength={50} />
          <FGEmpty />
          <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
