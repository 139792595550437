/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SocieteExterneGridDto
 */
export interface SocieteExterneGridDto {
    /**
     * 
     * @type {number}
     * @memberof SocieteExterneGridDto
     */
    idsocieteExterne?: number;
    /**
     * 
     * @type {string}
     * @memberof SocieteExterneGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SocieteExterneGridDto
     */
    codesMetiers?: Array<string> | null;
}

/**
 * Check if a given object implements the SocieteExterneGridDto interface.
 */
export function instanceOfSocieteExterneGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SocieteExterneGridDtoFromJSON(json: any): SocieteExterneGridDto {
    return SocieteExterneGridDtoFromJSONTyped(json, false);
}

export function SocieteExterneGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocieteExterneGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsocieteExterne': !exists(json, 'idsocieteExterne') ? undefined : json['idsocieteExterne'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'codesMetiers': !exists(json, 'codesMetiers') ? undefined : json['codesMetiers'],
    };
}

export function SocieteExterneGridDtoToJSON(value?: SocieteExterneGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsocieteExterne': value.idsocieteExterne,
        'nom': value.nom,
        'codesMetiers': value.codesMetiers,
    };
}

