/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CentreUserDto } from './CentreUserDto';
import {
    CentreUserDtoFromJSON,
    CentreUserDtoFromJSONTyped,
    CentreUserDtoToJSON,
} from './CentreUserDto';
import type { EmailDto } from './EmailDto';
import {
    EmailDtoFromJSON,
    EmailDtoFromJSONTyped,
    EmailDtoToJSON,
} from './EmailDto';
import type { TelephoneDto } from './TelephoneDto';
import {
    TelephoneDtoFromJSON,
    TelephoneDtoFromJSONTyped,
    TelephoneDtoToJSON,
} from './TelephoneDto';

/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    iduser?: number;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    idpersonne?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    codesexe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    login?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    azureEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    pwd?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    idlevel?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    connecte?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    ifapmeSide?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    delegationSignature?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    idtype?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    idformateur?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    idconseillerPedagogique?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    iddirectionTerritoriale?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    centres?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    lastConnectionTime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    ntusername?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    lastAdConnectionTime?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    forceAdConnection?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    validationDirection?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    iddepartement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    idposte?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    isFormateur?: boolean;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof UserDto
     */
    telephone?: TelephoneDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof UserDto
     */
    gsm?: TelephoneDto;
    /**
     * 
     * @type {EmailDto}
     * @memberof UserDto
     */
    email?: EmailDto;
    /**
     * 
     * @type {Array<CentreUserDto>}
     * @memberof UserDto
     */
    centreUsers?: Array<CentreUserDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserDto
     */
    idsSocieteExterne?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    passwordChange?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    pwdconfirm?: string | null;
}

/**
 * Check if a given object implements the UserDto interface.
 */
export function instanceOfUserDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserDtoFromJSON(json: any): UserDto {
    return UserDtoFromJSONTyped(json, false);
}

export function UserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iduser': !exists(json, 'iduser') ? undefined : json['iduser'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'codesexe': !exists(json, 'codesexe') ? undefined : json['codesexe'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'login': !exists(json, 'login') ? undefined : json['login'],
        'azureEmail': !exists(json, 'azureEmail') ? undefined : json['azureEmail'],
        'pwd': !exists(json, 'pwd') ? undefined : json['pwd'],
        'idlevel': !exists(json, 'idlevel') ? undefined : json['idlevel'],
        'connecte': !exists(json, 'connecte') ? undefined : json['connecte'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'ifapmeSide': !exists(json, 'ifapmeSide') ? undefined : json['ifapmeSide'],
        'delegationSignature': !exists(json, 'delegationSignature') ? undefined : json['delegationSignature'],
        'idtype': !exists(json, 'idtype') ? undefined : json['idtype'],
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'idconseillerPedagogique': !exists(json, 'idconseillerPedagogique') ? undefined : json['idconseillerPedagogique'],
        'iddirectionTerritoriale': !exists(json, 'iddirectionTerritoriale') ? undefined : json['iddirectionTerritoriale'],
        'centres': !exists(json, 'centres') ? undefined : json['centres'],
        'lastConnectionTime': !exists(json, 'lastConnectionTime') ? undefined : (json['lastConnectionTime'] === null ? null : new Date(json['lastConnectionTime'])),
        'ntusername': !exists(json, 'ntusername') ? undefined : json['ntusername'],
        'lastAdConnectionTime': !exists(json, 'lastAdConnectionTime') ? undefined : (json['lastAdConnectionTime'] === null ? null : new Date(json['lastAdConnectionTime'])),
        'forceAdConnection': !exists(json, 'forceAdConnection') ? undefined : json['forceAdConnection'],
        'validationDirection': !exists(json, 'validationDirection') ? undefined : json['validationDirection'],
        'iddepartement': !exists(json, 'iddepartement') ? undefined : json['iddepartement'],
        'idposte': !exists(json, 'idposte') ? undefined : json['idposte'],
        'isFormateur': !exists(json, 'isFormateur') ? undefined : json['isFormateur'],
        'telephone': !exists(json, 'telephone') ? undefined : TelephoneDtoFromJSON(json['telephone']),
        'gsm': !exists(json, 'gsm') ? undefined : TelephoneDtoFromJSON(json['gsm']),
        'email': !exists(json, 'email') ? undefined : EmailDtoFromJSON(json['email']),
        'centreUsers': !exists(json, 'centreUsers') ? undefined : (json['centreUsers'] === null ? null : (json['centreUsers'] as Array<any>).map(CentreUserDtoFromJSON)),
        'idsSocieteExterne': !exists(json, 'idsSocieteExterne') ? undefined : json['idsSocieteExterne'],
        'passwordChange': !exists(json, 'passwordChange') ? undefined : json['passwordChange'],
        'pwdconfirm': !exists(json, 'pwdconfirm') ? undefined : json['pwdconfirm'],
    };
}

export function UserDtoToJSON(value?: UserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iduser': value.iduser,
        'idpersonne': value.idpersonne,
        'codesexe': value.codesexe,
        'nom': value.nom,
        'prenom': value.prenom,
        'login': value.login,
        'azureEmail': value.azureEmail,
        'pwd': value.pwd,
        'idlevel': value.idlevel,
        'connecte': value.connecte,
        'actif': value.actif,
        'ifapmeSide': value.ifapmeSide,
        'delegationSignature': value.delegationSignature,
        'idtype': value.idtype,
        'idformateur': value.idformateur,
        'idconseillerPedagogique': value.idconseillerPedagogique,
        'iddirectionTerritoriale': value.iddirectionTerritoriale,
        'centres': value.centres,
        'lastConnectionTime': value.lastConnectionTime === undefined ? undefined : (value.lastConnectionTime === null ? null : value.lastConnectionTime.toISOString()),
        'ntusername': value.ntusername,
        'lastAdConnectionTime': value.lastAdConnectionTime === undefined ? undefined : (value.lastAdConnectionTime === null ? null : value.lastAdConnectionTime.toISOString()),
        'forceAdConnection': value.forceAdConnection,
        'validationDirection': value.validationDirection,
        'iddepartement': value.iddepartement,
        'idposte': value.idposte,
        'isFormateur': value.isFormateur,
        'telephone': TelephoneDtoToJSON(value.telephone),
        'gsm': TelephoneDtoToJSON(value.gsm),
        'email': EmailDtoToJSON(value.email),
        'centreUsers': value.centreUsers === undefined ? undefined : (value.centreUsers === null ? null : (value.centreUsers as Array<any>).map(CentreUserDtoToJSON)),
        'idsSocieteExterne': value.idsSocieteExterne,
        'passwordChange': value.passwordChange,
        'pwdconfirm': value.pwdconfirm,
    };
}

