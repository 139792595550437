/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbCentreGridDto
 */
export interface FcbCentreGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbCentreGridDto
     */
    idcentre?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreGridDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreGridDto
     */
    adresse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreGridDto
     */
    telApp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreGridDto
     */
    telCe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreGridDto
     */
    telFco?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreGridDto
     */
    telCentral?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreGridDto
     */
    telAutre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreGridDto
     */
    faxApp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreGridDto
     */
    faxCe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreGridDto
     */
    faxFo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreGridDto
     */
    faxAutre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreGridDto
     */
    directeur?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreGridDto
     */
    codePostal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreGridDto
     */
    commune?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreGridDto
     */
    localite?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbCentreGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbCentreGridDto interface.
 */
export function instanceOfFcbCentreGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbCentreGridDtoFromJSON(json: any): FcbCentreGridDto {
    return FcbCentreGridDtoFromJSONTyped(json, false);
}

export function FcbCentreGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbCentreGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'adresse': !exists(json, 'adresse') ? undefined : json['adresse'],
        'telApp': !exists(json, 'telApp') ? undefined : json['telApp'],
        'telCe': !exists(json, 'telCe') ? undefined : json['telCe'],
        'telFco': !exists(json, 'telFco') ? undefined : json['telFco'],
        'telCentral': !exists(json, 'telCentral') ? undefined : json['telCentral'],
        'telAutre': !exists(json, 'telAutre') ? undefined : json['telAutre'],
        'faxApp': !exists(json, 'faxApp') ? undefined : json['faxApp'],
        'faxCe': !exists(json, 'faxCe') ? undefined : json['faxCe'],
        'faxFo': !exists(json, 'faxFo') ? undefined : json['faxFo'],
        'faxAutre': !exists(json, 'faxAutre') ? undefined : json['faxAutre'],
        'directeur': !exists(json, 'directeur') ? undefined : json['directeur'],
        'codePostal': !exists(json, 'codePostal') ? undefined : json['codePostal'],
        'commune': !exists(json, 'commune') ? undefined : json['commune'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbCentreGridDtoToJSON(value?: FcbCentreGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcentre': value.idcentre,
        'code': value.code,
        'nom': value.nom,
        'adresse': value.adresse,
        'telApp': value.telApp,
        'telCe': value.telCe,
        'telFco': value.telFco,
        'telCentral': value.telCentral,
        'telAutre': value.telAutre,
        'faxApp': value.faxApp,
        'faxCe': value.faxCe,
        'faxFo': value.faxFo,
        'faxAutre': value.faxAutre,
        'directeur': value.directeur,
        'codePostal': value.codePostal,
        'commune': value.commune,
        'localite': value.localite,
        'actif': value.actif,
    };
}

