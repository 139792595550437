import * as React from "react";
import { useHistory } from "react-router";
import { FcbEcoleGridDto, EcoleSearch, EcoleApi, EBooleanSearchTypes } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { useAbortableApiServiceFactory, useApiService } from "../../../../../hooks/useApiService";
import { ETLCodes } from "../../../../../locales";
import { ButtonContainer } from "nsitools-react";

interface IEcoleListProps {}

export const EcoleListPage: React.FunctionComponent<IEcoleListProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(EcoleApi);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbEcoleGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => history.push(`${ERoutes.ecole}/${row.idecole}/detail/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${ERoutes.ecole}/${row.idecole}/detail/edit`)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Adresse),
        fieldName: "adresse"
      },
      {
        header: () => t(ETLCodes.CodePostal),
        fieldName: "codePostal",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      },
      {
        header: () => t(ETLCodes.Pays),
        fieldName: "nomPays"
      },
      {
        header: () => t(ETLCodes.Tel),
        fieldName: "telephone"
      },
      {
        header: () => t(ETLCodes.Fax),
        fieldName: "fax"
      }
    ],
    [history, t]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.ecole}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(() => api.ecoleGetSearchCriterias({ includeListsValues: true }), [api]);

  const apiFactory = useAbortableApiServiceFactory(EcoleApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: EcoleSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.ecoleBaseSearch({ EcoleSearch: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      columns={columns}
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      addFunc={addItemFunction}
      breadCrumbs={[{ text: t(ETLCodes.Ecoles), route: ERoutes.ecole }]}
      criteriasTlPrefix="EcoleSearchCriterias"
      sortKeys={{ nom: "ASC" }}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
    />
  );
};
