import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { MetierDetailPage, MetierLocalisation } from ".";
import { MetierApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { MetierClausesLieesSwitch } from "./MetierClausesLieesSwitch";
import { MetierConseillersLiesSwitch } from "./MetierConseillersLiesSwitch";

interface IMetierItemPageProps {}

export const MetierItemPage: React.FunctionComponent<IMetierItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const idMetier = React.useMemo(() => +id, [id]);
  const history = useHistory();

  const metierApi = useApiService(MetierApi);
  const [displayName] = useApiEffect(() => metierApi.metierGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.Metiers), route: ERoutes.metier },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName },
      ]}
    >
      <ProtectedTabs
        id="TabsMetier"
        onChange={(newTabId) => history.push(`${ERoutes.metier}/${id}/${newTabId}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.metier}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<MetierDetailPage editMode={state === "edit"} idMetier={idMetier} />}
        />
        <Tab
          id="clausesliees"
          title={t(ETLCodes.ClausesLiees)}
          panel={<MetierClausesLieesSwitch />}
          disabled={+id <= 0}
        />
        <Tab
          id="conseillerslies"
          title={t(ETLCodes.ConseillersLies)}
          panel={<MetierConseillersLiesSwitch />}
          disabled={+id <= 0}
        />
        <Tab
          id="Localisation"
          title={t(ETLCodes.Localisation)}
          panel={<MetierLocalisation idMetier={+id} />}
          disabled={+id <= 0}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
