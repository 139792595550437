/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ECategorieStatutApprenant {
    PostFormation = 'PostFormation',
    Entree = 'Entree',
    Sortie = 'Sortie',
    SortieAnnulee = 'SortieAnnulee',
    SortieEnCours = 'SortieEnCours'
}


export function ECategorieStatutApprenantFromJSON(json: any): ECategorieStatutApprenant {
    return ECategorieStatutApprenantFromJSONTyped(json, false);
}

export function ECategorieStatutApprenantFromJSONTyped(json: any, ignoreDiscriminator: boolean): ECategorieStatutApprenant {
    return json as ECategorieStatutApprenant;
}

export function ECategorieStatutApprenantToJSON(value?: ECategorieStatutApprenant | null): any {
    return value as any;
}

