import { ToolTipButton } from "nsitools-react";
import * as React from "react";

import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import { ICustomButtonProps } from "./ICustomButtonProps";

interface ISmsProps extends ICustomButtonProps {
  phone: string;
  onClick?: () => any;
}

export const SmsButton: React.FunctionComponent<ISmsProps> = ({ minimal = true, phone, loading, onClick }) => {
  const { t } = useTl();
  return phone ? (
    <ToolTipButton
      tooltip={t(ETLCodes.SendAnSMS)}
      icon="chat"
      minimal={minimal}
      loading={loading}
      text={phone}
      onClick={onClick}
      type="button"
      disabled
    />
  ) : (
    <></>
  );
};
