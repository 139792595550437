import * as React from "react";
import { useApiService } from "../../../../../hooks";
import { ReportApi } from "../../../../../api";
import { useApiEffect } from "nsitools-react";
import { ImpressionAccesPermissionsGrid } from "./ImpressionAccesPermissionsGrid";
import { ImpressionCentrePermissionsGrid } from "./ImpressionCentrePermissionsGrid";
import { ImpressionModulePermissionsGrid } from "./ImpressionModulePermissionsGrid";

export interface IImpressionPermissionsPageProps {
  idImpression: number;
}

export const ImpressionPermissionsPage: React.FunctionComponent<IImpressionPermissionsPageProps> = ({
  idImpression
}) => {
  const api = useApiService(ReportApi);

  const [data, loading] = useApiEffect(
    React.useCallback(() => {
      return api.reportGetPermissions({ id: idImpression });
    }, [api, idImpression])
  );
  return (
    <>
      <ImpressionCentrePermissionsGrid
        idImpression={idImpression}
        centres={data?.centres}
        loading={loading}
      ></ImpressionCentrePermissionsGrid>
      <ImpressionAccesPermissionsGrid
        idImpression={idImpression}
        acces={data?.acces}
        loading={loading}
      ></ImpressionAccesPermissionsGrid>
      <ImpressionModulePermissionsGrid
        idImpression={idImpression}
        modules={data?.modules}
        loading={loading}
      ></ImpressionModulePermissionsGrid>
    </>
  );
};
