import {
  Button,
  Card,
  Checkbox,
  Colors,
  Icon,
  Intent,
  Menu,
  MenuItem,
  Popover,
  Spinner,
  Tooltip
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  ButtonContainer,
  DataTable,
  FieldSet,
  IDataTableColumn,
  showError,
  useGridState,
  useSearchApi
} from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import styled, { css } from "styled-components";

import {
  LieuFormationApi,
  LieuFormationGridDto,
  LieuFormationSearchDto,
  SiegeSocialApi,
  SiegeSocialGridDto
} from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import {
  AddButton,
  CircleColumn,
  CustomBulletList,
  EditButton,
  EmailButton,
  SmsButton,
  ViewButton
} from "../../../../components";
import { useRouteParamsContext } from "../../../../contexts";
import { useApiService, useTheme, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

const PopoverContent = styled.div`
  padding: 0.5rem;
`;

const LegendHeaderContainer = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

export interface ILieuFormationGridProps {
  siegeSocial: SiegeSocialGridDto;
}

export const LieuFormationGrid: React.FunctionComponent<ILieuFormationGridProps> = ({ siegeSocial }) => {
  const { t } = useTl();
  const history = useHistory();
  const { theme } = useTheme();
  const [selectedIDs, setSelectedIDs] = React.useState<number[]>([]);
  const [nbReturnedIds, setNbReturnedIds] = React.useState(0);
  const { pushSmsRecipientIds, pushSmsRecipientType } = useRouteParamsContext();
  const lastSearchObject = React.useRef<LieuFormationSearchDto>();
  const [selectAllLoading, setSelectAllLoading] = React.useState(false);
  const siegeSocialId = React.useMemo(() => +siegeSocial.idsiegeSocial, [siegeSocial.idsiegeSocial]);
  const api = useApiService(LieuFormationApi);
  const sApi = useApiService(SiegeSocialApi);

  const searchFunction = React.useCallback(
    (sObj?: LieuFormationSearchDto) => {
      sObj.idsiegeSocial = siegeSocialId;
      if (JSON.stringify(sObj) !== JSON.stringify(lastSearchObject?.current)) {
        lastSearchObject.current = sObj;
      }
      return api.lieuFormationBaseSearch({ LieuFormationSearchDto: sObj });
    },
    [api, siegeSocialId]
  );

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { nom: "ASC" }
  });

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const onViewClick = React.useCallback(
    (dto: LieuFormationGridDto) => {
      history.push(`${ERoutes.lieuFormation}/${dto.idlieuFormation}/detail/view?idsiegeSocial=${dto.idsiegeSocial}`);
    },
    [history]
  );

  const onEditClick = React.useCallback(
    (dto: LieuFormationGridDto) => {
      history.push(`${ERoutes.lieuFormation}/${dto.idlieuFormation}/detail/edit?idsiegeSocial=${dto.idsiegeSocial}`);
    },
    [history]
  );

  const sendSms = React.useCallback(() => {
    if (selectedIDs.length > 0) {
      pushSmsRecipientIds(selectedIDs);
      // pushSmsRecipientType(ETypeDestinataireSms.SiegeSocial); TODO:
      history.push(`${ERoutes.sms}/0/detail/edit`);
    } else {
      showError(t(ETLCodes.SelectAtLeastOne));
    }
  }, [history, pushSmsRecipientIds, selectedIDs, t]);

  const sendSmsSingle = React.useCallback(
    (id: number) => {
      // pushSmsRecipientIds([id]);
      // pushSmsRecipientType(ETypeDestinataireSms.SiegeSocial); TODO:
      history.push(`${ERoutes.sms}/0/detail/edit`);
    },
    [history]
  );

  const createSuivi = React.useCallback(
    (dto: LieuFormationGridDto) => {
      history.push(`${ERoutes.lieuFormation}/${dto.idlieuFormation}/suivi/edit/-1?idsiegeSocial=${dto.idsiegeSocial}`);
    },
    [history]
  );

  const selectAll = React.useCallback(() => {
    setSelectAllLoading(true);
    api
      .lieuFormationSearchLieuFormationIds({
        LieuFormationSearchDto: { ...lastSearchObject?.current, forceSkip: 0, forceTake: 999999 }
      })
      .then(allIds => {
        if (allIds.length === selectedIDs.length) {
          setSelectedIDs([]);
          setNbReturnedIds(0);
        } else {
          setSelectedIDs(allIds);
          setNbReturnedIds(allIds.length);
        }
        setSelectAllLoading(false);
      });
  }, [api, selectedIDs.length]);

  const toggleSelection = React.useCallback(
    (item: LieuFormationGridDto) => {
      if (!selectedIDs.includes(item.idlieuFormation)) {
        setSelectedIDs(ids => [...ids, item.idlieuFormation]);
      } else {
        setSelectedIDs(ids => ids.filter(id => id !== item.idlieuFormation));
      }
    },
    [selectedIDs]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        header: () =>
          selectAllLoading ? (
            <Spinner size={20} />
          ) : (
            <Checkbox checked={nbReturnedIds > 0 && nbReturnedIds === selectedIDs.length} onChange={selectAll} />
          ),
        render: (item: LieuFormationGridDto) => (
          <Checkbox
            checked={selectedIDs.includes(item.idlieuFormation)}
            onChange={e => {
              toggleSelection(item);
            }}
          />
        )
      },
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: LieuFormationGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => onViewClick(row)} />
            <EditButton minimal={true} onClick={() => onEditClick(row)} />
            <Button icon={IconNames.ALIGN_JUSTIFY} minimal={true} onClick={() => createSuivi(row)} />
          </ButtonContainer>
        )
      },
      {
        autoFitContent: true,
        alignment: "center",
        header: () => (
          <LegendHeaderContainer>
            <span>{t(ETLCodes.A)}</span>
            <Tooltip
              content={
                <CustomBulletList
                  title={t(ETLCodes.AccueilApprenant)}
                  items={[
                    {
                      text: t(ETLCodes.SusceptibledAccueillirUnApprenant),
                      color: theme.sucessColor
                    },
                    {
                      text: t(ETLCodes.PasSusceptibledAccueillirUnApprenant),
                      color: theme.dangerColor
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip>
          </LegendHeaderContainer>
        ),
        fieldName: "formationCanHaveApprenants",
        render: (row: SiegeSocialGridDto) =>
          row?.formationCanHaveApprenants ? (
            <CircleColumn color={theme.sucessColor} />
          ) : (
            <CircleColumn color={theme.dangerColor} />
          )
      },
      {
        autoFitContent: true,
        header: () => t(ETLCodes.C),
        fieldName: "nbContrats"
      },
      {
        header: () => t(ETLCodes.NomLieuFormation),
        fieldName: "nom"
      },
      { header: () => t(ETLCodes.NumeroUe), fieldName: "numeroUe" },
      {
        header: () => t(ETLCodes.Contact),
        fieldName: "contact",
        render: (value: LieuFormationGridDto) => value.contact ?? value.chefEntreprise
      },
      {
        header: () => t(ETLCodes.Telephone),
        fieldName: "telephone"
      },
      {
        header: () => t(ETLCodes.Gsm),
        fieldName: "gsm",
        render: (value: LieuFormationGridDto) => (
          <SmsButton phone={value.gsm} disabled onClick={() => sendSmsSingle(value.idlieuFormation)} />
        )
      },
      {
        header: () => t(ETLCodes.Email),
        fieldName: "email",
        alignment: "center",
        render: (value: LieuFormationGridDto) => <EmailButton email={value.email} />
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      },
      {
        header: () => t(ETLCodes.Enseigne),
        fieldName: "enseigne"
      }
    ],
    [
      createSuivi,
      nbReturnedIds,
      onEditClick,
      onViewClick,
      selectAll,
      selectAllLoading,
      selectedIDs,
      sendSmsSingle,
      t,
      theme.dangerColor,
      theme.sucessColor,
      toggleSelection
    ]
  );

  const sendEmail = React.useCallback(async () => {
    if (selectedIDs.length > 0) {
      const emails = await sApi.siegeSocialGetLieuxFormationEmails({ request_body: selectedIDs });
      if (!emails) {
        showError(t(ETLCodes.NoSelectedSiegeSocialHasAnEmail));
        return;
      }
      const el = document.createElement("a");
      el.setAttribute("href", `mailto:?bcc=${emails}`);
      el.click();
    } else {
      showError(t(ETLCodes.SelectAtLeastOne));
    }
  }, [sApi, selectedIDs, t]);

  const onAddLieuFormation = React.useCallback(
    (idsiegeSocial?: number) => {
      history.push(`${ERoutes.lieuFormation}/-1/detail/edit?idsiegeSocial=${idsiegeSocial}`);
    },
    [history]
  );

  const buttons = React.useMemo(
    () => (
      <>
        <Popover
          position="bottom"
          content={
            <PopoverContent>
              <Menu>
                <MenuItem
                  icon={IconNames.ENVELOPE}
                  text={t(ETLCodes.SendAnEmail)}
                  intent={Intent.PRIMARY}
                  onClick={sendEmail}
                />
                <MenuItem
                  icon={IconNames.CHAT}
                  text={t(ETLCodes.SendAnSMS)}
                  intent={Intent.PRIMARY}
                  onClick={sendSms}
                  disabled
                />
                <MenuItem
                  icon={IconNames.PRINT}
                  text={t(ETLCodes.PrintFicheEntreprise)}
                  intent={Intent.PRIMARY}
                  onClick={() => {}}
                  disabled
                />
              </Menu>
            </PopoverContent>
          }
        >
          <Button
            icon={IconNames.CHEVRON_DOWN}
            text={t(ETLCodes.GlobalActions)}
            minimal={true}
            intent={Intent.PRIMARY}
          ></Button>
        </Popover>
        <AddButton
          text={t(ETLCodes.General_Add)}
          onClick={() => {
            if (!siegeSocial.actif) {
              showError(t(ETLCodes.CannotAddLieuToSiegeSocialInactif));
              return;
            }
            onAddLieuFormation(siegeSocial.idsiegeSocial);
          }}
        />
      </>
    ),
    [onAddLieuFormation, sendEmail, sendSms, siegeSocial.actif, siegeSocial.idsiegeSocial, t]
  );

  return (
    <Card>
      <FieldSet rightElement={buttons}>
        <DataTable
          loading={loading}
          dateFormat="dd-MM-yyyy"
          tableState={tableState}
          columns={columns}
          renderNoData={<></>}
          customizeRowStyle={(row: LieuFormationGridDto) => css`
            & * {
              color: ${!row.actif ? Colors.RED3 + " !important" : null};
            }
          `}
        />
      </FieldSet>
    </Card>
  );
};
