/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbLieuxCoursDto
 */
export interface FcbLieuxCoursDto {
    /**
     * 
     * @type {number}
     * @memberof FcbLieuxCoursDto
     */
    idlieu?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbLieuxCoursDto
     */
    idcentre?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbLieuxCoursDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbLieuxCoursDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbLieuxCoursDto
     */
    codePays?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbLieuxCoursDto
     */
    codePostal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbLieuxCoursDto
     */
    adresse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbLieuxCoursDto
     */
    localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbLieuxCoursDto
     */
    commune?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbLieuxCoursDto
     */
    tel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbLieuxCoursDto
     */
    fax?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbLieuxCoursDto
     */
    creationUser?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbLieuxCoursDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbLieuxCoursDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbLieuxCoursDto
     */
    modificationDate?: Date | null;
}

/**
 * Check if a given object implements the FcbLieuxCoursDto interface.
 */
export function instanceOfFcbLieuxCoursDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbLieuxCoursDtoFromJSON(json: any): FcbLieuxCoursDto {
    return FcbLieuxCoursDtoFromJSONTyped(json, false);
}

export function FcbLieuxCoursDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbLieuxCoursDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idlieu': !exists(json, 'idlieu') ? undefined : json['idlieu'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'codePays': !exists(json, 'codePays') ? undefined : json['codePays'],
        'codePostal': !exists(json, 'codePostal') ? undefined : json['codePostal'],
        'adresse': !exists(json, 'adresse') ? undefined : json['adresse'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'commune': !exists(json, 'commune') ? undefined : json['commune'],
        'tel': !exists(json, 'tel') ? undefined : json['tel'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
    };
}

export function FcbLieuxCoursDtoToJSON(value?: FcbLieuxCoursDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idlieu': value.idlieu,
        'idcentre': value.idcentre,
        'code': value.code,
        'nom': value.nom,
        'codePays': value.codePays,
        'codePostal': value.codePostal,
        'adresse': value.adresse,
        'localite': value.localite,
        'commune': value.commune,
        'tel': value.tel,
        'fax': value.fax,
        'creationUser': value.creationUser,
        'modificationUser': value.modificationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
    };
}

