import * as React from "react";
import styled from "styled-components";

interface ICardAttributeProps {
  label: string;
  value: string | JSX.Element;
}

const LabelAttrt = styled.div`
  font-weight: bold;
  font-style: italic;
  font-size: 0.8em;
  color: black;
`;
const ValueAttr = styled.div`
  color: black;
`;

const CardAttributeContainer = styled.div`
  min-width: 160px;
  margin-bottom: 0.2em;
  color: black;
`;
export const CardAttribute: React.FC<ICardAttributeProps> = ({ label, value }) => {
  return (
    <CardAttributeContainer>
      <LabelAttrt>{label}</LabelAttrt>
      <ValueAttr>{value}</ValueAttr>
    </CardAttributeContainer>
  );
};
