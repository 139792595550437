import { FGEmpty, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { CodePostalApi, FcbCodePostalDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterCheckboxInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

interface ICodePostalDetailPage {
  idCodePostal: string;
  editMode: boolean;
}

export const CodePostalDetailPage: React.FunctionComponent<ICodePostalDetailPage> = ({ idCodePostal, editMode }) => {
  const { t } = useTl();
  const api = useApiService(CodePostalApi);
  const history = useHistory();

  const [pays, loadingPays] = useReferential(a => a.referentialGetPays(), false, []);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      codePostal: Yup.string().required(t(ETLCodes.Required)),
      localite: Yup.string().required(t(ETLCodes.Required)),
      codePays: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idCodePostal <= 0
        ? FcbCodePostalDtoFromJSON({ idCodePostal: 0, actif: true })
        : api.codePostalGet({ id: +idCodePostal }),
    saveApiFn: d => api.codePostalSave({ FcbCodePostalDto: d }),
    onSavedRoute: d => `${ERoutes.codePostal}/${d.idcodePostal}/detail/edit`,
    deleteApiFn: d => api.codePostalDelete({ id: d.idcodePostal }),
    onDeletedRoute: () => ERoutes.codePostal
  });

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        showColons
        editMode={editMode}
        minLabelWidth={180}
        labelAlignment="right"
        formatDate="dd/MM/yyyy"
        validationSchema={FormSchema}
        inline
        boldLabel
        loading={loading}
        onCancel={() => history.push(ERoutes.codePostal)}
        onDelete={deleteItem}
        showDeleteButton={+idCodePostal > 0}
        saving={saving}
        deleting={deleting}
      >
        <FieldGroup columns={2}>
          <FGTextInput name="codePostal" label={t(ETLCodes.CodePostal)} maxLength={6} />
          <FGEmpty />
          <FGTextInput name="localite" label={t(ETLCodes.Localite)} forceCase="upper" />
          <FGTextInput name="commune" label={t(ETLCodes.Commune)} />
          <FGWalterSelectInput name="codePays" label={t(ETLCodes.Pays)} loading={loadingPays} items={pays} />
          <FGEmpty />
          <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
