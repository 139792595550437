/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClauseMetierSearch,
  FcbClauseMetiersLiesDto,
  FcbMetierClauseGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    ClauseMetierSearchFromJSON,
    ClauseMetierSearchToJSON,
    FcbClauseMetiersLiesDtoFromJSON,
    FcbClauseMetiersLiesDtoToJSON,
    FcbMetierClauseGridDtoPaginatedResultsFromJSON,
    FcbMetierClauseGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface ClauseMetierLiesBaseSearchRequest {
    ClauseMetierSearch?: ClauseMetierSearch;
}

export interface ClauseMetierLiesGetClauseMetierRequest {
    idClause?: number;
}

export interface ClauseMetierLiesGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ClauseMetierLiesGetSelectItemsRequest {
    searchField: string;
}

export interface ClauseMetierLiesSaveRequest {
    FcbClauseMetiersLiesDto?: FcbClauseMetiersLiesDto;
}

/**
 * 
 */
export class ClauseMetierLiesApi extends runtime.BaseAPI {

    /**
     */
    async clauseMetierLiesBaseSearchRaw(requestParameters: ClauseMetierLiesBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbMetierClauseGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClauseMetierLies/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClauseMetierSearchToJSON(requestParameters.ClauseMetierSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbMetierClauseGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async clauseMetierLiesBaseSearch(requestParameters: ClauseMetierLiesBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbMetierClauseGridDtoPaginatedResults> {
        const response = await this.clauseMetierLiesBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async clauseMetierLiesGetClauseMetierRaw(requestParameters: ClauseMetierLiesGetClauseMetierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbClauseMetiersLiesDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClause !== undefined) {
            queryParameters['idClause'] = requestParameters.idClause;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClauseMetierLies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbClauseMetiersLiesDtoFromJSON(jsonValue));
    }

    /**
     */
    async clauseMetierLiesGetClauseMetier(requestParameters: ClauseMetierLiesGetClauseMetierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbClauseMetiersLiesDto> {
        const response = await this.clauseMetierLiesGetClauseMetierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async clauseMetierLiesGetSearchCriteriasRaw(requestParameters: ClauseMetierLiesGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClauseMetierLies/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async clauseMetierLiesGetSearchCriterias(requestParameters: ClauseMetierLiesGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.clauseMetierLiesGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async clauseMetierLiesGetSelectItemsRaw(requestParameters: ClauseMetierLiesGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling clauseMetierLiesGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClauseMetierLies/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async clauseMetierLiesGetSelectItems(requestParameters: ClauseMetierLiesGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.clauseMetierLiesGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async clauseMetierLiesSaveRaw(requestParameters: ClauseMetierLiesSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbClauseMetiersLiesDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClauseMetierLies/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbClauseMetiersLiesDtoToJSON(requestParameters.FcbClauseMetiersLiesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbClauseMetiersLiesDtoFromJSON(jsonValue));
    }

    /**
     */
    async clauseMetierLiesSave(requestParameters: ClauseMetierLiesSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbClauseMetiersLiesDto> {
        const response = await this.clauseMetierLiesSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
