import { Button } from "@blueprintjs/core";
import { FieldSet } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { CarouselComparer, DoublonsDataCard, RE_FINAL_KEY, useDoublonsStateContext } from "..";
import { RepresentantDoublonEditDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { nameof } from "../../../../../utils";

const Container = styled.div`
  height: 70vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export interface IDoublonRepresentantSignaletiqueProps {}

export const DoublonRepresentantSignaletique: React.FunctionComponent<IDoublonRepresentantSignaletiqueProps> = props => {
  const { t } = useTl();
  const { data, selectData, getSelectedIndex } = useDoublonsStateContext();

  const selectedIndex = React.useMemo(() => getSelectedIndex(`${RE_FINAL_KEY}.idrepresentant`) ?? 0, [
    getSelectedIndex
  ]);
  const representantContainer = React.useCallback(
    (index: number, nb: number) => {
      const representant: RepresentantDoublonEditDto = data?.representants[index];
      if (!representant) return;

      return (
        <FieldSet
          key={representant.idrepresentant}
          title={
            <TitleContainer>
              {index !== selectedIndex ? (
                <Button
                  text={t(ETLCodes.Conserver)}
                  icon={"arrow-left"}
                  onClick={() => selectData(`${RE_FINAL_KEY}.idrepresentant`, index)}
                />
              ) : (
                <div />
              )}
              <ViewButton
                text={t(ETLCodes.RepresentantNb, { nb: nb, id: representant.idrepresentant })}
                onClick={() =>
                  window.open(`#${ERoutes.representant}/${representant.idrepresentant}/detail/view`, "_blank")
                }
              />
              <div />
            </TitleContainer>
          }
          titlePosition="center"
        >
          <DoublonsDataCard
            fieldPath={`representants[${index}].telephoneRepresentant.idtelephone`}
            valuePath={`representants[${index}].telephoneRepresentant.numero`}
            label={t(ETLCodes.Telephone)}
            finalEntityKey={RE_FINAL_KEY}
            entityIdKey={nameof<RepresentantDoublonEditDto>("idrepresentant")}
          />
          <DoublonsDataCard
            fieldPath={`representants[${index}].telephoneProRepresentant.idtelephone`}
            valuePath={`representants[${index}].telephoneProRepresentant.numero`}
            label={t(ETLCodes.TelephonePro)}
            finalEntityKey={RE_FINAL_KEY}
            entityIdKey={nameof<RepresentantDoublonEditDto>("idrepresentant")}
          />
          <DoublonsDataCard
            fieldPath={`representants[${index}].gsmRepresentant.idtelephone`}
            valuePath={`representants[${index}].gsmRepresentant.numero`}
            label={t(ETLCodes.Gsm)}
            finalEntityKey={RE_FINAL_KEY}
            entityIdKey={nameof<RepresentantDoublonEditDto>("idrepresentant")}
          />
          <DoublonsDataCard
            fieldPath={`representants[${index}].emailRepresentant.idemail`}
            valuePath={`representants[${index}].emailRepresentant.adresseEmail`}
            label={t(ETLCodes.Email)}
            finalEntityKey={RE_FINAL_KEY}
            entityIdKey={nameof<RepresentantDoublonEditDto>("idrepresentant")}
          />
        </FieldSet>
      );
    },
    [data?.representants, selectData, selectedIndex, t]
  );

  return (
    <Container>
      <CarouselComparer leftElement={representantContainer(selectedIndex, selectedIndex + 1)}>
        {data?.representants?.length > 1 &&
          data?.representants
            ?.filter((a, i) => i !== selectedIndex)
            ?.map((a, i) => representantContainer(i + (i >= selectedIndex ? 1 : 0), i + (i >= selectedIndex ? 2 : 1)))}
      </CarouselComparer>
    </Container>
  );
};
