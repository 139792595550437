import * as React from "react";
import { ButtonContainer, IDataTableColumn, showError } from "nsitools-react";
import { useTl, useApiService, useTheme, useAbortableApiServiceFactory } from "../../../../hooks";
import { useHistory } from "react-router";
import { ViewButton, EditButton, SearchTablePage, SmsButton, EmailButton } from "../../../../components";
import { ERoutes } from "../../../../AppRouter";
import { ETLCodes } from "../../../../locales";
import { FcbRepresentantGridDto, RepresentantSearch, RepresentantApi, ETypeDestinataireSms } from "../../../../api";
import { Checkbox, Spinner } from "@blueprintjs/core";
import { useRouteParamsContext } from "../../../../contexts";

export interface IRepresentantListPageProps {}

export const RepresentantListPage: React.FunctionComponent<IRepresentantListPageProps> = () => {
  const { t } = useTl();
  const { ifapmeSide } = useTheme();
  const history = useHistory();
  const api = useApiService(RepresentantApi);
  const [selectedIDs, setSelectedIDs] = React.useState<number[]>([]);
  const lastSearchObject = React.useRef<RepresentantSearch>();
  const { pushSmsRecipientIds, pushSmsRecipientType } = useRouteParamsContext();
  const [selectAllLoading, setSelectAllLoading] = React.useState(false);
  const [nbReturnedIds, setNbReturnedIds] = React.useState(0);

  const apiFactory = useAbortableApiServiceFactory(RepresentantApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (nextSearch?: RepresentantSearch) => {
      if (JSON.stringify(nextSearch) !== JSON.stringify(lastSearchObject?.current)) {
        lastSearchObject.current = nextSearch;
      }
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.representantBaseSearch({ RepresentantSearch: nextSearch });
    },
    [apiFactory]
  );

  const selectAll = React.useCallback(() => {
    setSelectAllLoading(true);
    api
      .representantSearchRepresentantIds({
        RepresentantSearch: { ...lastSearchObject?.current, forceSkip: 0, forceTake: 999999 }
      })
      .then(allIds => {
        if (allIds.length === selectedIDs.length) {
          setSelectedIDs([]);
          setNbReturnedIds(0);
        } else {
          setSelectedIDs(allIds);
          setNbReturnedIds(allIds.length);
        }
        setSelectAllLoading(false);
      });
  }, [api, selectedIDs.length]);

  const toggleSelection = React.useCallback(
    (item: FcbRepresentantGridDto) => {
      if (!selectedIDs.includes(item.idrepresentant)) {
        setSelectedIDs(ids => [...ids, item.idrepresentant]);
      } else {
        setSelectedIDs(ids => ids.filter(id => id !== item.idrepresentant));
      }
    },
    [selectedIDs]
  );

  const sendSms = React.useCallback(() => {
    if (selectedIDs.length > 0) {
      pushSmsRecipientIds(selectedIDs);
      pushSmsRecipientType(ETypeDestinataireSms.Etudiant);
      history.push(`${ERoutes.sms}/0/detail/edit`);
    } else {
      showError(t(ETLCodes.SelectAtLeastOne));
    }
  }, [history, pushSmsRecipientIds, pushSmsRecipientType, selectedIDs, t]);

  const sendSmsSingle = React.useCallback(
    (id: number) => {
      pushSmsRecipientIds([id]);
      pushSmsRecipientType(ETypeDestinataireSms.Etudiant);
      history.push(`${ERoutes.sms}/0/detail/edit`);
    },
    [history, pushSmsRecipientIds, pushSmsRecipientType]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        header: () =>
          selectAllLoading ? (
            <Spinner size={20} />
          ) : (
            <Checkbox checked={nbReturnedIds > 0 && nbReturnedIds === selectedIDs.length} onChange={selectAll} />
          ),
        render: (item: FcbRepresentantGridDto) => (
          <Checkbox
            checked={selectedIDs.includes(item.idrepresentant)}
            onChange={e => {
              toggleSelection(item);
            }}
          />
        )
      },
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbRepresentantGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.representant}/${row.idrepresentant}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.representant}/${row.idrepresentant}/detail/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.Adresse),
        fieldName: "adresse"
      },
      {
        header: () => t(ETLCodes.CodePostal),
        fieldName: "codePostal"
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      },
      {
        header: () => t(ETLCodes.Gsm),
        fieldName: "gsm",
        render: (value: FcbRepresentantGridDto) => (
          <SmsButton
            phone={value.gsm}
            onClick={() => sendSmsSingle(value.idrepresentant)}
            disabled={!!value.dateDeces}
          />
        )
      },
      {
        header: () => t(ETLCodes.Tel),
        fieldName: "telephone"
      },
      {
        header: () => t(ETLCodes.TelPro),
        fieldName: "telephonePro"
      },
      {
        header: () => t(ETLCodes.Email),
        fieldName: "email",
        alignment: "center",
        render: (value: FcbRepresentantGridDto) => <EmailButton email={value.email} disabled={!!value.dateDeces} />
      }
    ],
    [history, nbReturnedIds, selectAll, selectAllLoading, selectedIDs, sendSmsSingle, t, toggleSelection]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.representant}/0/detail/checkDoublons`);
  }, [history]);

  const getCriteria = React.useCallback(() => api.representantGetSearchCriterias({ includeListsValues: true }), [api]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteria}
      searchFunction={search}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.Representants), route: ERoutes.representant }]}
      addFunc={ifapmeSide === "walter" && onAddItem}
      sortKeys={{ nom: "ASC" }}
      searchStateInitialSearch={false}
      buttons={[
        {
          icon: "chat",
          text: t(ETLCodes.SendAnSMSToSelected),
          onClick: sendSms,
          disabled: true
        }
      ]}
    />
  );
};
