/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RapportHopeParameterDataDto } from './RapportHopeParameterDataDto';
import {
    RapportHopeParameterDataDtoFromJSON,
    RapportHopeParameterDataDtoFromJSONTyped,
    RapportHopeParameterDataDtoToJSON,
} from './RapportHopeParameterDataDto';

/**
 * 
 * @export
 * @interface GenerateRapportHopeCommand
 */
export interface GenerateRapportHopeCommand {
    /**
     * 
     * @type {string}
     * @memberof GenerateRapportHopeCommand
     */
    reportName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GenerateRapportHopeCommand
     */
    format?: string | null;
    /**
     * 
     * @type {Array<RapportHopeParameterDataDto>}
     * @memberof GenerateRapportHopeCommand
     */
    parameters?: Array<RapportHopeParameterDataDto> | null;
}

/**
 * Check if a given object implements the GenerateRapportHopeCommand interface.
 */
export function instanceOfGenerateRapportHopeCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GenerateRapportHopeCommandFromJSON(json: any): GenerateRapportHopeCommand {
    return GenerateRapportHopeCommandFromJSONTyped(json, false);
}

export function GenerateRapportHopeCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateRapportHopeCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reportName': !exists(json, 'reportName') ? undefined : json['reportName'],
        'format': !exists(json, 'format') ? undefined : json['format'],
        'parameters': !exists(json, 'parameters') ? undefined : (json['parameters'] === null ? null : (json['parameters'] as Array<any>).map(RapportHopeParameterDataDtoFromJSON)),
    };
}

export function GenerateRapportHopeCommandToJSON(value?: GenerateRapportHopeCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reportName': value.reportName,
        'format': value.format,
        'parameters': value.parameters === undefined ? undefined : (value.parameters === null ? null : (value.parameters as Array<any>).map(RapportHopeParameterDataDtoToJSON)),
    };
}

