/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FilterCriteriaInfo,
  ReferentialItemDto,
  SocieteExterneEditDto,
  SocieteExterneGridDtoPaginatedResults,
  SocieteExterneSearchDto,
} from '../models/index';
import {
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
    SocieteExterneEditDtoFromJSON,
    SocieteExterneEditDtoToJSON,
    SocieteExterneGridDtoPaginatedResultsFromJSON,
    SocieteExterneGridDtoPaginatedResultsToJSON,
    SocieteExterneSearchDtoFromJSON,
    SocieteExterneSearchDtoToJSON,
} from '../models/index';

export interface SocieteExterneAddApprenantForUserRequest {
    idapprenant?: number;
}

export interface SocieteExterneBaseSearchRequest {
    SocieteExterneSearchDto?: SocieteExterneSearchDto;
}

export interface SocieteExterneDeleteSocieteExterneRequest {
    id?: number;
}

export interface SocieteExterneGetDisplayNameRequest {
    id?: number;
}

export interface SocieteExterneGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface SocieteExterneGetSelectItemsRequest {
    searchField: string;
}

export interface SocieteExterneGetSocieteExterneRequest {
    id?: number;
}

export interface SocieteExterneSaveSocieteExterneRequest {
    SocieteExterneEditDto?: SocieteExterneEditDto;
}

/**
 * 
 */
export class SocieteExterneApi extends runtime.BaseAPI {

    /**
     */
    async societeExterneAddApprenantForUserRaw(requestParameters: SocieteExterneAddApprenantForUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SocieteExterne/AddApprenantForUser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async societeExterneAddApprenantForUser(requestParameters: SocieteExterneAddApprenantForUserRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.societeExterneAddApprenantForUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async societeExterneBaseSearchRaw(requestParameters: SocieteExterneBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SocieteExterneGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SocieteExterne/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SocieteExterneSearchDtoToJSON(requestParameters.SocieteExterneSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SocieteExterneGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async societeExterneBaseSearch(requestParameters: SocieteExterneBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SocieteExterneGridDtoPaginatedResults> {
        const response = await this.societeExterneBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async societeExterneDeleteSocieteExterneRaw(requestParameters: SocieteExterneDeleteSocieteExterneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SocieteExterne`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async societeExterneDeleteSocieteExterne(requestParameters: SocieteExterneDeleteSocieteExterneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.societeExterneDeleteSocieteExterneRaw(requestParameters, initOverrides);
    }

    /**
     */
    async societeExterneGetDisplayNameRaw(requestParameters: SocieteExterneGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SocieteExterne/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async societeExterneGetDisplayName(requestParameters: SocieteExterneGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.societeExterneGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async societeExterneGetSearchCriteriasRaw(requestParameters: SocieteExterneGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SocieteExterne/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async societeExterneGetSearchCriterias(requestParameters: SocieteExterneGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.societeExterneGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async societeExterneGetSelectItemsRaw(requestParameters: SocieteExterneGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling societeExterneGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SocieteExterne/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async societeExterneGetSelectItems(requestParameters: SocieteExterneGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.societeExterneGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async societeExterneGetSocieteExterneRaw(requestParameters: SocieteExterneGetSocieteExterneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SocieteExterneEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SocieteExterne`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SocieteExterneEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async societeExterneGetSocieteExterne(requestParameters: SocieteExterneGetSocieteExterneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SocieteExterneEditDto> {
        const response = await this.societeExterneGetSocieteExterneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async societeExterneSaveSocieteExterneRaw(requestParameters: SocieteExterneSaveSocieteExterneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SocieteExterneEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SocieteExterne`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SocieteExterneEditDtoToJSON(requestParameters.SocieteExterneEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SocieteExterneEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async societeExterneSaveSocieteExterne(requestParameters: SocieteExterneSaveSocieteExterneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SocieteExterneEditDto> {
        const response = await this.societeExterneSaveSocieteExterneRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
