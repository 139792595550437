/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactGridDto
 */
export interface ContactGridDto {
    /**
     * 
     * @type {number}
     * @memberof ContactGridDto
     */
    idcontact?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ContactGridDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ContactGridDto
     */
    registreNational?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactGridDto
     */
    lastSiegeSocial?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactGridDto
     */
    lastLieuFormation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactGridDto
     */
    gsm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactGridDto
     */
    telephone?: string | null;
}

/**
 * Check if a given object implements the ContactGridDto interface.
 */
export function instanceOfContactGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContactGridDtoFromJSON(json: any): ContactGridDto {
    return ContactGridDtoFromJSONTyped(json, false);
}

export function ContactGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontact': !exists(json, 'idcontact') ? undefined : json['idcontact'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'registreNational': !exists(json, 'registreNational') ? undefined : json['registreNational'],
        'lastSiegeSocial': !exists(json, 'lastSiegeSocial') ? undefined : json['lastSiegeSocial'],
        'lastLieuFormation': !exists(json, 'lastLieuFormation') ? undefined : json['lastLieuFormation'],
        'gsm': !exists(json, 'gsm') ? undefined : json['gsm'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
    };
}

export function ContactGridDtoToJSON(value?: ContactGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontact': value.idcontact,
        'nom': value.nom,
        'prenom': value.prenom,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'registreNational': value.registreNational,
        'lastSiegeSocial': value.lastSiegeSocial,
        'lastLieuFormation': value.lastLieuFormation,
        'gsm': value.gsm,
        'telephone': value.telephone,
    };
}

