import { Classes, Colors, Icon, Radio, RadioGroup, Switch } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { ButtonContainer, IDataTableColumn, ToolTipButton } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { DashboardInscriptionRefuserDialog } from ".";
import {
  ChangeStatutMultipleDto,
  DashboardApi,
  DashboardInscriptionGridDto,
  DashboardInscriptionSearch,
  EIfapmeSide,
  EOrigineInscription,
  EStatutDemande,
  ParcoursFormationApi
} from "../../../api";
import { ERoutes } from "../../../AppRouter";
import {
  CircleColumn,
  CustomBulletList,
  DeleteButton,
  LinkButton,
  SearchTablePage,
  ViewButton
} from "../../../components";
import { useDialog, useEventsContext } from "../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTheme, useTl } from "../../../hooks";
import { ETLCodes } from "../../../locales";

const LegendHeaderContainer = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

const StyledSwitch = styled(Switch)<{ color: string }>`
  & .${Classes.SWITCH} {
    &:hover {
      input:checked ~ .${Classes.CONTROL_INDICATOR} {
        background: ${props => props.color};
      }
    }
  }
`;

export interface IDashboardInscriptionsProps {}

export const DashboardInscriptions: React.FunctionComponent<IDashboardInscriptionsProps> = props => {
  const { t } = useTl();
  const { ifapmeSide, theme } = useTheme();
  const ifapmeSideOrigin = React.useMemo(
    () => (ifapmeSide === "walter" ? EOrigineInscription.Walter : EOrigineInscription.Hope),
    [ifapmeSide]
  );
  const api = useApiService(DashboardApi);
  const pfApi = useApiService(ParcoursFormationApi);

  const [myDashboard, setMyDashboard] = React.useState(true);
  const [displayHidden, setDisplayHidden] = React.useState(false);

  const apiFactory = useAbortableApiServiceFactory(DashboardApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFunction = React.useCallback(
    (sObj?: DashboardInscriptionSearch) => {
      sObj.ifapmeSide = ifapmeSide === "walter" ? EIfapmeSide.Walter : EIfapmeSide.Hope;
      sObj.onlyUserDashboard = myDashboard;
      sObj.hideMasquees = !displayHidden;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.dashboardSearchInscriptions({ DashboardInscriptionSearch: sObj });
    },
    [apiFactory, displayHidden, ifapmeSide, myDashboard]
  );

  const defineColor = React.useCallback((codeStatut: EStatutDemande) => {
    switch (codeStatut) {
      case EStatutDemande.I:
      case EStatutDemande.V:
        return Colors.GREEN3;
      case EStatutDemande.EA:
        return Colors.RED3;
      case EStatutDemande.EV:
        return Colors.ORANGE3;
      case EStatutDemande.E:
        return Colors.GOLD5;
      case EStatutDemande.P:
        return Colors.BLUE3;
      case EStatutDemande.AE:
        return Colors.GRAY1;
      case EStatutDemande.EC:
        return Colors.GRAY3;
      case EStatutDemande.CC:
        return Colors.RED3;
      case EStatutDemande.CE:
        return Colors.RED3;
      case EStatutDemande.ER:
        return Colors.RED3;
      case EStatutDemande.MS:
        return Colors.VIOLET4;
      case EStatutDemande.DS:
        return Colors.RED3;
      case EStatutDemande.SU:
        return Colors.RED3;
      case EStatutDemande.RC:
        return Colors.RED3;
      default:
        return Colors.GRAY3;
    }
  }, []);

  const { dispatchEvent } = useEventsContext();
  const [rowLoading, setRowLoading] = React.useState(null);
  const [currentRefus, setCurrentRefus] = React.useState<ChangeStatutMultipleDto>();
  const onClose = React.useCallback(
    async (toSave: ChangeStatutMultipleDto) => {
      if (!!toSave) {
        setRowLoading(toSave.demandes[0]);
        await pfApi.parcoursFormationChangeStatutMultipleDemande({
          ChangeStatutMultipleDto: toSave
        });
        setRowLoading(null);
        dispatchEvent("SEARCH_TABLE_REFRESH");
        dispatchEvent("DASHBOARD_COUNTS_REFRESH_Inscriptions");
      }
      setCurrentRefus(null);
    },
    [dispatchEvent, pfApi]
  );

  const { showDialogPromise } = useDialog();
  const refuserDemande = React.useCallback(async (iddemande: number) => {
    setCurrentRefus({
      codeStatut: EStatutDemande.RC,
      demandes: [iddemande]
    });
  }, []);
  const masquerDemande = React.useCallback(
    async (iddemande: number) => {
      const res = await showDialogPromise({
        title: t(ETLCodes.MasquerDemandeConfirmTitle),
        message: t(ETLCodes.MasquerDemandeConfirmMessage)
      });
      if (res === "yes") {
        setRowLoading(iddemande);
        await api.dashboardMasquerDemandeInscriptions({ iddemande });
        setRowLoading(null);
        dispatchEvent("SEARCH_TABLE_REFRESH");
        dispatchEvent("DASHBOARD_COUNTS_REFRESH_Inscriptions");
      }
    },
    [api, dispatchEvent, showDialogPromise, t]
  );

  const isHope = React.useMemo(() => ifapmeSide === "hope", [ifapmeSide]);
  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: DashboardInscriptionGridDto) => (
          <ButtonContainer>
            {ifapmeSide === "walter" && row.codeStatut === EStatutDemande.DS && (
              <ToolTipButton
                tooltipPosition="bottom"
                tooltip={t(ETLCodes.VoirInscription)}
                minimal
                icon="share"
                onClick={() =>
                  window.open(`#${ERoutes.apprenant}/${row.idapprenant}/inscription/${row.idinscription}/detail/edit`)
                }
              />
            )}
            {!row.masquee &&
              ![EStatutDemande.E, EStatutDemande.EV, EStatutDemande.EC, EStatutDemande.P, EStatutDemande.AE].includes(
                row.codeStatut
              ) && (
                <ToolTipButton
                  tooltipPosition="bottom"
                  tooltip={t(ETLCodes.MasquerDemande)}
                  minimal
                  icon="cross"
                  onClick={() => masquerDemande(row.iddemandeInscription)}
                  loading={rowLoading === row.iddemandeInscription}
                  disabled={!!rowLoading && rowLoading !== row.iddemandeInscription}
                />
              )}
            {ifapmeSide === "hope" ? (
              <ViewButton
                onClick={() =>
                  window.open(
                    `#${ERoutes.apprenant}/${row.idapprenant}/parcoursFormation/${
                      row.codeStatut === EStatutDemande.EC ? "edit" : "view"
                    }/${row.idparcoursFormation}/demandeInscription/${row.iddemandeInscription}`
                  )
                }
              />
            ) : (
              row.codeStatut === EStatutDemande.E && (
                <ToolTipButton
                  tooltipPosition="bottom"
                  tooltip={t(ETLCodes.ApprouverEtCreerInscription)}
                  minimal
                  intent="success"
                  icon="thumbs-up"
                  onClick={() =>
                    window.open(
                      `#${ERoutes.apprenant}/${row.idapprenant}/inscription/-1/detail/edit?iddemandeInscription=${row.iddemandeInscription}`
                    )
                  }
                />
              )
            )}
            {ifapmeSide === "walter" && row.codeStatut === EStatutDemande.E && (
              <DeleteButton
                tooltipText={t(ETLCodes.RefuserInscription)}
                icon="thumbs-down"
                onDelete={() => refuserDemande(row.iddemandeInscription)}
                loading={rowLoading === row.iddemandeInscription}
                disabled={!!rowLoading && rowLoading !== row.iddemandeInscription}
              />
            )}
          </ButtonContainer>
        )
      },
      {
        computed: true,
        autoFitContent: true,
        alignment: "center",
        header: () => (
          <LegendHeaderContainer>
            <Tooltip2
              content={
                <CustomBulletList
                  title={t(ETLCodes.StatutDemande)}
                  items={
                    ifapmeSide === "hope"
                      ? [
                          {
                            text: t(ETLCodes.Inscrit),
                            color: Colors.GREEN3
                          },
                          {
                            text: t(ETLCodes.Validee),
                            color: Colors.GREEN3
                          },
                          {
                            text: t(ETLCodes.EnAbandon),
                            color: Colors.RED3
                          },
                          {
                            text: t(ETLCodes.RefuseCentres),
                            color: Colors.RED3
                          },
                          {
                            text: t(ETLCodes.ChangementClasse),
                            color: Colors.RED3
                          },
                          {
                            text: t(ETLCodes.ChangementCentre),
                            color: Colors.RED3
                          },
                          {
                            text: t(ETLCodes.EnRenvoi),
                            color: Colors.RED3
                          },
                          {
                            text: t(ETLCodes.MisEnSortie),
                            color: Colors.RED3
                          },
                          {
                            text: t(ETLCodes.DemandeSuppression),
                            color: Colors.RED3
                          },
                          {
                            text: t(ETLCodes.Supprime),
                            color: Colors.RED3
                          },
                          {
                            text: t(ETLCodes.EnValidation),
                            color: Colors.ORANGE3
                          },
                          {
                            text: t(ETLCodes.Envoyee),
                            color: Colors.GOLD5
                          },
                          {
                            text: t(ETLCodes.BrouillonPret),
                            color: Colors.BLUE3
                          },
                          {
                            text: t(ETLCodes.Brouillon),
                            color: Colors.GRAY1
                          },
                          {
                            text: t(ETLCodes.EnCorrection),
                            color: Colors.GRAY3
                          }
                        ]
                      : [
                          {
                            text: t(ETLCodes.DemandeInscription),
                            color: Colors.GOLD5
                          },
                          {
                            text: t(ETLCodes.DemandeSuppression),
                            color: Colors.RED3
                          },
                          {
                            text: t(ETLCodes.MisEnSortie),
                            color: Colors.VIOLET4
                          }
                        ]
                  }
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip2>
          </LegendHeaderContainer>
        ),
        fieldName: "statut",
        render: (row: DashboardInscriptionGridDto) => (
          <CircleColumn color={defineColor(row.codeStatut)} tooltip={row.statut} />
        )
      },
      {
        header: () => t(ETLCodes.Apprenant),
        fieldName: "apprenant",
        render: (row: DashboardInscriptionGridDto) => (
          <LinkButton
            text={row.apprenant}
            onClick={() => window.open(`#${ERoutes.apprenant}/${row.idapprenant}/signaletique/view`)}
          />
        )
      },
      {
        header: () => t(ETLCodes.Formation),
        fieldName: "formation"
      },
      {
        header: () => t(ETLCodes.Annee),
        fieldName: "anneeAcademique"
      },
      {
        header: () => t(ETLCodes.TypeCours),
        fieldName: "typeCours"
      },
      {
        header: () => t(ETLCodes.Degre),
        fieldName: "degre"
      },
      {
        header: () => t(ETLCodes.Metier),
        fieldName: "metier"
      },
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "centre"
      },
      {
        header: () => t(ETLCodes.Encodeur),
        fieldName: "encodeur"
      },
      {
        header: () => t(ETLCodes.Envoyeur),
        fieldName: "envoyeur"
      },
      {
        header: () => t(ETLCodes.DateEnvoi),
        fieldName: "dateEnvoi",
        hidden: isHope
      },
      {
        header: () => t(ETLCodes.AncienneClasse),
        fieldName: "ancienneClasse"
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "type",
        render: (row: DashboardInscriptionGridDto) =>
          (ifapmeSide === "hope"
            ? row.statut
            : ["E", "MS", "DS"].includes(row.codeStatut)
            ? t(ETLCodes.DemandeInscription)
            : t(ETLCodes.Error)) + (row.origine !== ifapmeSideOrigin ? ` (${row.origine})` : "")
      }
    ],
    [defineColor, ifapmeSide, ifapmeSideOrigin, isHope, masquerDemande, refuserDemande, rowLoading, t]
  );

  const getCriteria = React.useCallback(async () => {
    return await api.dashboardGetInscriptionCriterias();
  }, [api]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      {ifapmeSide === "hope" && (
        <RadioGroup
          selectedValue={myDashboard ? "1" : "0"}
          inline
          onChange={e => setMyDashboard(e.currentTarget.value === "1")}
        >
          <Radio label={t(ETLCodes.MesDemandes)} value={"1"}></Radio>
          <Radio label={t(ETLCodes.DemandesServices)} value={"0"}></Radio>
        </RadioGroup>
      )}
      <SearchTablePage
        searchStateInitialSearch={false}
        withCard={false}
        columns={columns}
        getCriteriasFunction={getCriteria}
        searchFunction={searchFunction}
        onAbort={onAbort}
        sortKeys={{ modificationDate: "DESC" }}
        rightElement={
          <StyledSwitch
            color={theme.primaryColor}
            label={t(ETLCodes.AfficherMasquees)}
            checked={displayHidden}
            onChange={e => {
              setDisplayHidden(e.currentTarget.checked);
            }}
          ></StyledSwitch>
        }
      />
      {currentRefus && (
        <DashboardInscriptionRefuserDialog currentRefus={currentRefus} dialogOpen={!!currentRefus} onClose={onClose} />
      )}
    </>
  );
};
