/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { BooleanSearch } from './BooleanSearch';
import {
    BooleanSearchFromJSON,
    BooleanSearchFromJSONTyped,
    BooleanSearchToJSON,
} from './BooleanSearch';
import type { DateSearch } from './DateSearch';
import {
    DateSearchFromJSON,
    DateSearchFromJSONTyped,
    DateSearchToJSON,
} from './DateSearch';
import type { ESuiviActionListSearch } from './ESuiviActionListSearch';
import {
    ESuiviActionListSearchFromJSON,
    ESuiviActionListSearchFromJSONTyped,
    ESuiviActionListSearchToJSON,
} from './ESuiviActionListSearch';
import type { Int32ListSearch } from './Int32ListSearch';
import {
    Int32ListSearchFromJSON,
    Int32ListSearchFromJSONTyped,
    Int32ListSearchToJSON,
} from './Int32ListSearch';
import type { Int32NullableListSearch } from './Int32NullableListSearch';
import {
    Int32NullableListSearchFromJSON,
    Int32NullableListSearchFromJSONTyped,
    Int32NullableListSearchToJSON,
} from './Int32NullableListSearch';

/**
 * 
 * @export
 * @interface SiegeLieuSuiviSearchGlobal
 */
export interface SiegeLieuSuiviSearchGlobal {
    /**
     * 
     * @type {Int32NullableListSearch}
     * @memberof SiegeLieuSuiviSearchGlobal
     */
    idsiegeSocial?: Int32NullableListSearch;
    /**
     * 
     * @type {Int32NullableListSearch}
     * @memberof SiegeLieuSuiviSearchGlobal
     */
    idlieuFormation?: Int32NullableListSearch;
    /**
     * 
     * @type {BooleanSearch}
     * @memberof SiegeLieuSuiviSearchGlobal
     */
    onlyUserSuivis?: BooleanSearch;
    /**
     * 
     * @type {Int32NullableListSearch}
     * @memberof SiegeLieuSuiviSearchGlobal
     */
    idencodeur?: Int32NullableListSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof SiegeLieuSuiviSearchGlobal
     */
    idservice?: Int32ListSearch;
    /**
     * 
     * @type {ESuiviActionListSearch}
     * @memberof SiegeLieuSuiviSearchGlobal
     */
    suiviAction?: ESuiviActionListSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof SiegeLieuSuiviSearchGlobal
     */
    idrealisateurSuivi?: Int32ListSearch;
    /**
     * 
     * @type {DateSearch}
     * @memberof SiegeLieuSuiviSearchGlobal
     */
    dateSuivi?: DateSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof SiegeLieuSuiviSearchGlobal
     */
    idtypeSuivi?: Int32ListSearch;
    /**
     * 
     * @type {Array<string>}
     * @memberof SiegeLieuSuiviSearchGlobal
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof SiegeLieuSuiviSearchGlobal
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviSearchGlobal
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviSearchGlobal
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof SiegeLieuSuiviSearchGlobal
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeLieuSuiviSearchGlobal
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the SiegeLieuSuiviSearchGlobal interface.
 */
export function instanceOfSiegeLieuSuiviSearchGlobal(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SiegeLieuSuiviSearchGlobalFromJSON(json: any): SiegeLieuSuiviSearchGlobal {
    return SiegeLieuSuiviSearchGlobalFromJSONTyped(json, false);
}

export function SiegeLieuSuiviSearchGlobalFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiegeLieuSuiviSearchGlobal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : Int32NullableListSearchFromJSON(json['idsiegeSocial']),
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : Int32NullableListSearchFromJSON(json['idlieuFormation']),
        'onlyUserSuivis': !exists(json, 'onlyUserSuivis') ? undefined : BooleanSearchFromJSON(json['onlyUserSuivis']),
        'idencodeur': !exists(json, 'idencodeur') ? undefined : Int32NullableListSearchFromJSON(json['idencodeur']),
        'idservice': !exists(json, 'idservice') ? undefined : Int32ListSearchFromJSON(json['idservice']),
        'suiviAction': !exists(json, 'suiviAction') ? undefined : ESuiviActionListSearchFromJSON(json['suiviAction']),
        'idrealisateurSuivi': !exists(json, 'idrealisateurSuivi') ? undefined : Int32ListSearchFromJSON(json['idrealisateurSuivi']),
        'dateSuivi': !exists(json, 'dateSuivi') ? undefined : DateSearchFromJSON(json['dateSuivi']),
        'idtypeSuivi': !exists(json, 'idtypeSuivi') ? undefined : Int32ListSearchFromJSON(json['idtypeSuivi']),
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function SiegeLieuSuiviSearchGlobalToJSON(value?: SiegeLieuSuiviSearchGlobal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsiegeSocial': Int32NullableListSearchToJSON(value.idsiegeSocial),
        'idlieuFormation': Int32NullableListSearchToJSON(value.idlieuFormation),
        'onlyUserSuivis': BooleanSearchToJSON(value.onlyUserSuivis),
        'idencodeur': Int32NullableListSearchToJSON(value.idencodeur),
        'idservice': Int32ListSearchToJSON(value.idservice),
        'suiviAction': ESuiviActionListSearchToJSON(value.suiviAction),
        'idrealisateurSuivi': Int32ListSearchToJSON(value.idrealisateurSuivi),
        'dateSuivi': DateSearchToJSON(value.dateSuivi),
        'idtypeSuivi': Int32ListSearchToJSON(value.idtypeSuivi),
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

