/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TuteurLieuGridDto
 */
export interface TuteurLieuGridDto {
    /**
     * 
     * @type {number}
     * @memberof TuteurLieuGridDto
     */
    idtuteurLieu?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TuteurLieuGridDto
     */
    c?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TuteurLieuGridDto
     */
    idtuteur?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TuteurLieuGridDto
     */
    idlieuFormation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TuteurLieuGridDto
     */
    idpersonne?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TuteurLieuGridDto
     */
    personne?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TuteurLieuGridDto
     */
    capaciteFormative?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof TuteurLieuGridDto
     */
    dateCv?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof TuteurLieuGridDto
     */
    anneesExperience?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TuteurLieuGridDto
     */
    tutorat?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TuteurLieuGridDto
     */
    vdc?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TuteurLieuGridDto
     */
    derogation?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TuteurLieuGridDto
     */
    responsableDerogation?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof TuteurLieuGridDto
     */
    dateDerogation?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof TuteurLieuGridDto
     */
    spp?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TuteurLieuGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the TuteurLieuGridDto interface.
 */
export function instanceOfTuteurLieuGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TuteurLieuGridDtoFromJSON(json: any): TuteurLieuGridDto {
    return TuteurLieuGridDtoFromJSONTyped(json, false);
}

export function TuteurLieuGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TuteurLieuGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idtuteurLieu': !exists(json, 'idtuteurLieu') ? undefined : json['idtuteurLieu'],
        'c': !exists(json, 'c') ? undefined : json['c'],
        'idtuteur': !exists(json, 'idtuteur') ? undefined : json['idtuteur'],
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'personne': !exists(json, 'personne') ? undefined : json['personne'],
        'capaciteFormative': !exists(json, 'capaciteFormative') ? undefined : json['capaciteFormative'],
        'dateCv': !exists(json, 'dateCv') ? undefined : (json['dateCv'] === null ? null : new Date(json['dateCv'])),
        'anneesExperience': !exists(json, 'anneesExperience') ? undefined : json['anneesExperience'],
        'tutorat': !exists(json, 'tutorat') ? undefined : json['tutorat'],
        'vdc': !exists(json, 'vdc') ? undefined : json['vdc'],
        'derogation': !exists(json, 'derogation') ? undefined : json['derogation'],
        'responsableDerogation': !exists(json, 'responsableDerogation') ? undefined : json['responsableDerogation'],
        'dateDerogation': !exists(json, 'dateDerogation') ? undefined : (json['dateDerogation'] === null ? null : new Date(json['dateDerogation'])),
        'spp': !exists(json, 'spp') ? undefined : json['spp'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function TuteurLieuGridDtoToJSON(value?: TuteurLieuGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idtuteurLieu': value.idtuteurLieu,
        'c': value.c,
        'idtuteur': value.idtuteur,
        'idlieuFormation': value.idlieuFormation,
        'idpersonne': value.idpersonne,
        'personne': value.personne,
        'capaciteFormative': value.capaciteFormative,
        'dateCv': value.dateCv === undefined ? undefined : (value.dateCv === null ? null : value.dateCv.toISOString()),
        'anneesExperience': value.anneesExperience,
        'tutorat': value.tutorat,
        'vdc': value.vdc,
        'derogation': value.derogation,
        'responsableDerogation': value.responsableDerogation,
        'dateDerogation': value.dateDerogation === undefined ? undefined : (value.dateDerogation === null ? null : value.dateDerogation.toISOString()),
        'spp': value.spp,
        'actif': value.actif,
    };
}

