import * as React from "react";
import ReactCrop, { Crop } from "react-image-crop";
import { CancelButton, SaveButton } from "./";

import "react-image-crop/dist/ReactCrop.css";
import { ETLCodes } from "../locales";
import { useTl } from "../hooks";
import { InlineButtonContainer, ButtonsBloc } from "nsitools-react";
import { Classes, FileInput } from "@blueprintjs/core";
interface IPhotoModifierProps {
  photo?: string | ArrayBuffer;
  onSave?: (newPhoto: any) => void;
  onCancel?: () => void;
}

export const PhotoModifier: React.FunctionComponent<IPhotoModifierProps> = ({ photo, onSave, onCancel }) => {
  const { t } = useTl();
  const [crop, setCrop] = React.useState<Crop>({
    unit: "px",
    aspect: 120 / 180
  });

  const [src, setSrc] = React.useState<string | ArrayBuffer>(photo ? `data:image/png;base64,${photo}` : null);
  const [croppedImageB64, setCroppedImageB64] = React.useState<string>();
  const [croppedImageUrl, setCroppedImageUrl] = React.useState<any>();
  const [imageRef, setImageRef] = React.useState<HTMLImageElement>();

  const onSelectFile = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setSrc(reader.result);
        setCroppedImageB64(reader.result.toString());
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }, []);

  const getCroppedImg = (image, item, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = item.width;
    canvas.height = item.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        resolve(blob);
      }, "image/jpeg");
    });
  };

  const makeClientCrop = async (item: Crop) => {
    if (imageRef && item.width && item.height) {
      const newCroppedImageUrl = await getCroppedImg(imageRef, crop, "newFile.jpeg");
      setCroppedImageUrl(newCroppedImageUrl);
    }
  };

  React.useEffect(() => {
    if (croppedImageUrl) {
      var reader = new FileReader();
      reader.readAsDataURL(croppedImageUrl);
      reader.onloadend = function() {
        setCroppedImageB64(reader.result.toString());
      };
    }
  }, [croppedImageUrl]);

  const savePhoto = React.useCallback(() => {
    if (croppedImageB64) {
      return onSave(croppedImageB64.split(",")[1]);
    } else {
      return onSave(photo);
    }
  }, [croppedImageB64, onSave, photo]);
  const [fileInputProps] = React.useState({ accept: "image/*" });
  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        {src && (
          <ReactCrop
            src={src.toString()}
            onChange={item => setCrop(item)}
            onImageLoaded={image => setImageRef(image)}
            crop={crop}
            keepSelection={true}
            onComplete={item => {
              makeClientCrop(item);
            }}
          />
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <InlineButtonContainer>
            <ButtonsBloc>
              <FileInput
                onInputChange={onSelectFile}
                inputProps={fileInputProps}
                text={t(ETLCodes.SelectFileText)}
                buttonText={t(ETLCodes.BrowseFileText)}
              />
            </ButtonsBloc>
            <ButtonsBloc>
              <CancelButton minimal={false} onClick={onCancel}></CancelButton>
              <SaveButton minimal={false} onClick={savePhoto}></SaveButton>
            </ButtonsBloc>
          </InlineButtonContainer>
        </div>
      </div>
    </>
  );
};
