/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRemplacementSimultaneDto
 */
export interface FcbRemplacementSimultaneDto {
    /**
     * 
     * @type {number}
     * @memberof FcbRemplacementSimultaneDto
     */
    idremplacement?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbRemplacementSimultaneDto
     */
    idremplacant?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbRemplacementSimultaneDto
     */
    idformateurLocalisationContrat?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbRemplacementSimultaneDto
     */
    idclasseMatiere?: number;
}

/**
 * Check if a given object implements the FcbRemplacementSimultaneDto interface.
 */
export function instanceOfFcbRemplacementSimultaneDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRemplacementSimultaneDtoFromJSON(json: any): FcbRemplacementSimultaneDto {
    return FcbRemplacementSimultaneDtoFromJSONTyped(json, false);
}

export function FcbRemplacementSimultaneDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRemplacementSimultaneDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idremplacement': !exists(json, 'idremplacement') ? undefined : json['idremplacement'],
        'idremplacant': !exists(json, 'idremplacant') ? undefined : json['idremplacant'],
        'idformateurLocalisationContrat': !exists(json, 'idformateurLocalisationContrat') ? undefined : json['idformateurLocalisationContrat'],
        'idclasseMatiere': !exists(json, 'idclasseMatiere') ? undefined : json['idclasseMatiere'],
    };
}

export function FcbRemplacementSimultaneDtoToJSON(value?: FcbRemplacementSimultaneDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idremplacement': value.idremplacement,
        'idremplacant': value.idremplacant,
        'idformateurLocalisationContrat': value.idformateurLocalisationContrat,
        'idclasseMatiere': value.idclasseMatiere,
    };
}

