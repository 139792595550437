/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbFormateurDoublonGridDtoPaginatedResults,
  FilterCriteriaInfo,
  FormateurDoublonSearch,
  SelectItem,
} from '../models/index';
import {
    FcbFormateurDoublonGridDtoPaginatedResultsFromJSON,
    FcbFormateurDoublonGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    FormateurDoublonSearchFromJSON,
    FormateurDoublonSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface FormateurDoublonBaseSearchRequest {
    FormateurDoublonSearch?: FormateurDoublonSearch;
}

export interface FormateurDoublonGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface FormateurDoublonGetSelectItemsRequest {
    searchField: string;
}

/**
 * 
 */
export class FormateurDoublonApi extends runtime.BaseAPI {

    /**
     */
    async formateurDoublonBaseSearchRaw(requestParameters: FormateurDoublonBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurDoublonGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurDoublon/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormateurDoublonSearchToJSON(requestParameters.FormateurDoublonSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurDoublonGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async formateurDoublonBaseSearch(requestParameters: FormateurDoublonBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurDoublonGridDtoPaginatedResults> {
        const response = await this.formateurDoublonBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurDoublonGetSearchCriteriasRaw(requestParameters: FormateurDoublonGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurDoublon/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async formateurDoublonGetSearchCriterias(requestParameters: FormateurDoublonGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.formateurDoublonGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurDoublonGetSelectItemsRaw(requestParameters: FormateurDoublonGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling formateurDoublonGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurDoublon/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async formateurDoublonGetSelectItems(requestParameters: FormateurDoublonGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.formateurDoublonGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
