/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbMetierClausesLieesDto
 */
export interface FcbMetierClausesLieesDto {
    /**
     * 
     * @type {number}
     * @memberof FcbMetierClausesLieesDto
     */
    idMetier?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof FcbMetierClausesLieesDto
     */
    idsClause?: Array<number> | null;
}

/**
 * Check if a given object implements the FcbMetierClausesLieesDto interface.
 */
export function instanceOfFcbMetierClausesLieesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbMetierClausesLieesDtoFromJSON(json: any): FcbMetierClausesLieesDto {
    return FcbMetierClausesLieesDtoFromJSONTyped(json, false);
}

export function FcbMetierClausesLieesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbMetierClausesLieesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idMetier': !exists(json, 'idMetier') ? undefined : json['idMetier'],
        'idsClause': !exists(json, 'idsClause') ? undefined : json['idsClause'],
    };
}

export function FcbMetierClausesLieesDtoToJSON(value?: FcbMetierClausesLieesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idMetier': value.idMetier,
        'idsClause': value.idsClause,
    };
}

