import { Card, Classes, Colors } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";

import { useDoublonsStateContext } from ".";
import { BooleanColumn } from "../../../../components";
import { useTheme, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

const StyledCard = styled(Card)<{ bgColor: string; txtColor: string }>`
  margin: 0 0.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 0.5rem;
  background-color: ${props => props.bgColor};
  color: ${props => props.txtColor};
  transition: all 90ms linear;
`;

const LabelContainer = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  font-weight: bold;
`;

const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  & .value-striked {
    text-decoration: line-through;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SelectedValue = styled.div<{ primaryColor: string }>`
  color: ${props => props.primaryColor};
  font-weight: bold;
  margin-left: 0.5rem;
`;

export interface IDoublonsDataCardProps {
  fieldPath?: string;
  valuePath?: string;
  extValue?: JSX.Element;
  label: string;
  interactive?: boolean;
  forceSelected?: boolean;
  displaySelectedValue?: boolean;
  formatSelectedValue?: (value: any) => JSX.Element;
  finalEntityKey?: string;
  entityIdKey?: string;
}

export const DoublonsDataCard: React.FunctionComponent<IDoublonsDataCardProps> = ({
  fieldPath,
  valuePath,
  label,
  extValue,
  interactive = true,
  forceSelected,
  finalEntityKey,
  entityIdKey,
  displaySelectedValue = true,
  formatSelectedValue
}) => {
  const { t } = useTl();
  const { theme } = useTheme();
  const { getFieldData, isDataSelected, selectData, getSelectedValue, getSelectedIndex } = useDoublonsStateContext();
  const value = React.useMemo(() => extValue ?? getFieldData(valuePath ?? fieldPath), [
    extValue,
    fieldPath,
    getFieldData,
    valuePath
  ]);

  const finalPath = React.useMemo(() => fieldPath && fieldPath.replace(/^[^\]]*\]/gm, finalEntityKey), [
    fieldPath,
    finalEntityKey
  ]);
  const entityIndex = React.useMemo(() => fieldPath && +fieldPath.match(/(?<=\[)[^\]]*(?=\])/gm)[0], [fieldPath]);

  const isSelected = React.useMemo(() => forceSelected || (!!finalPath && isDataSelected(finalPath, entityIndex)), [
    entityIndex,
    finalPath,
    forceSelected,
    isDataSelected
  ]);

  const onSelect = React.useCallback(() => {
    if (!finalPath) return;
    selectData(finalPath, entityIndex);
  }, [entityIndex, finalPath, selectData]);

  const finalValuePath = React.useMemo(
    () => (valuePath ? valuePath.replace(/^[^\]]*\]/gm, finalEntityKey) : finalPath),
    [valuePath, finalEntityKey, finalPath]
  );

  const selectedIndex = React.useMemo(() => getSelectedIndex(`${finalEntityKey}.${entityIdKey}`) ?? 0, [
    entityIdKey,
    finalEntityKey,
    getSelectedIndex
  ]);
  const firstNotSelected = React.useMemo(
    () => displaySelectedValue && !isSelected && entityIndex === selectedIndex && !!(finalValuePath ?? finalPath),
    [displaySelectedValue, entityIndex, finalPath, finalValuePath, isSelected, selectedIndex]
  );

  const getFormattedValue = React.useCallback(
    (val: any, muted?: boolean) => {
      return val === null || val === undefined ? (
        t(ETLCodes.Aucun)
      ) : typeof val === "boolean" ? (
        <BooleanColumn value={val} muted={muted} />
      ) : (
        val
      );
    },
    [t]
  );

  return (
    <StyledCard
      interactive={interactive}
      onClick={interactive ? onSelect : null}
      className={isSelected && "doublon-card-selected"}
      bgColor={isSelected ? `${theme.primaryColor}10` : Colors.WHITE}
      txtColor={isSelected ? `${theme.primaryColor}` : null}
    >
      <LabelContainer>{label}</LabelContainer>
      <ContentContainer>
        <ValueContainer>
          <span className={firstNotSelected && `value-striked ${Classes.TEXT_MUTED}`}>
            {getFormattedValue(value, firstNotSelected)}
          </span>
        </ValueContainer>
        {firstNotSelected && (
          <SelectedValue primaryColor={theme.primaryColor}>
            {!!formatSelectedValue ? (
              formatSelectedValue(getSelectedValue(finalPath, finalEntityKey, finalValuePath))
            ) : (
              <>({getFormattedValue(getSelectedValue(finalPath, finalEntityKey, finalValuePath))})</>
            )}
          </SelectedValue>
        )}
      </ContentContainer>
    </StyledCard>
  );
};
