import * as React from "react";
import { Route, Switch } from "react-router";

import { ERoutes } from "../../../../../AppRouter";
import { ApprenantResultatTypeTabs } from "./ApprenantResultatTypeTabs";

export interface IApprenantResultatTypeSwitchProps {}

export const ApprenantResultatTypeSwitch: React.FunctionComponent<IApprenantResultatTypeSwitchProps> = () => {
  return (
    <Switch>
      <Route path={`${ERoutes.apprenant}/:id/:tab/:anneeTab/:coursTypeTab?`} component={ApprenantResultatTypeTabs} />
    </Switch>
  );
};
