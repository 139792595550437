import * as React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ModuleCreaApi, ModuleCreaGridDto, ModuleCreaSearchDto } from "../../../../../api";
import { BooleanColumn, EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { ERoutes } from "../../../../../AppRouter";
import { ETLCodes } from "../../../../../locales";
import { IDataTableColumn } from "nsitools-react";

export interface IModuleCreaListPageProps {}

const Container = styled.div`
  display: flex;
`;

export const ModuleCreaListPage: React.FunctionComponent<IModuleCreaListPageProps> = props => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ModuleCreaApi);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        alignment: "center",
        autoFitContent: true,
        render: (row: ModuleCreaGridDto) => (
          <Container>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.moduleCrea}/${row.idmoduleCrea}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.moduleCrea}/${row.idmoduleCrea}/detail/edit`)}
            />
          </Container>
        )
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code"
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "libelle"
      },
      {
        header: () => t(ETLCodes.Actif),
        fieldName: "actif",
        alignment: "center",
        autoFitContent: true,
        render: (row: ModuleCreaGridDto) => <BooleanColumn value={row.actif} />
      }
    ],
    [history, t]
  );

  const addItemFunction = React.useCallback(() => history.push(`${ERoutes.moduleCrea}/0/detail/edit`), [history]);

  const getCriteriasFn = React.useCallback(() => api.moduleCreaGetSearchCriterias({ includeListsValues: true }), [api]);

  const apiFactory = useAbortableApiServiceFactory(ModuleCreaApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: ModuleCreaSearchDto) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.moduleCreaBaseSearch({ ModuleCreaSearchDto: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      sortKeys={{ libelle: "ASC" }}
      columns={columns}
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      addFunc={addItemFunction}
      breadCrumbs={[{ text: t(ETLCodes.ModulesCrea), route: ERoutes.moduleCrea }]}
    />
  );
};

export default ModuleCreaListPage;
