import { Button, Tab } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Loading, useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { useLastDeliberationSearchObject } from "..";
import { DeliberationTypeApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { DeliberationTabs } from "./DeliberationTabs";

export interface IDeliberationItemPageProps {}

export const DeliberationItemPage: React.FunctionComponent<IDeliberationItemPageProps> = () => {
  const { t } = useTl();
  const { id, idApprenant, annee, tab, coursTypeTab } = useParams<{
    id: string;
    tab: string;
    idApprenant: string;
    annee: string;
    coursTypeTab: string;
    state: string;
  }>();
  const history = useHistory();

  const api = useApiService(DeliberationTypeApi);

  const [displayName] = useApiEffect(() => api.deliberationTypeGetDisplayName({ id: +id }), [id]);

  const { lastDeliberationTypeSearch: sObj } = useLastDeliberationSearchObject();

  const getIds = React.useCallback(async () => {
    if (!!sObj) {
      if (
        (sObj.hasOwnProperty("metier") && sObj.metier !== void 0) ||
        (sObj.hasOwnProperty("degre") && sObj.degre !== void 0) ||
        (sObj.hasOwnProperty("idclasse") && sObj.idclasse !== void 0)
      ) {
        return await api.deliberationTypeSearchIds({
          DeliberationTypeSearch: { ...sObj, idbulletinApprenantType: +id }
        });
      }
    }

    return [];
  }, [api, id, sObj]);

  const [ids, loading] = useApiEffect(getIds, [getIds]);

  const breadCrumbs = React.useMemo(
    () => [
      { text: t(ETLCodes.DeliberationType), route: ERoutes.deliberationType },
      { text: +id <= 0 ? t(ETLCodes.New) : displayName }
    ],
    [displayName, id, t]
  );

  const previousTabs = React.useMemo(() => {
    let found = -1;
    if (ids) {
      found = ids.findIndex(item => item.idbulletinApprenantType === +id);
    }
    if (found > 0) {
      let newApprenant = ids[found - 1];
      return (
        <Button
          onClick={() =>
            history.push(
              `${ERoutes.deliberationType}/${newApprenant.idbulletinApprenantType}/detail/${newApprenant.idApprenant}/${annee}/${coursTypeTab}`
            )
          }
          text={`${t(ETLCodes.Precedent)}`}
          loading={loading}
          intent="primary"
          minimal={true}
          icon={IconNames.CHEVRON_LEFT}
        />
      );
    }
    return <Loading isLoading={loading} size={20} />;
  }, [annee, coursTypeTab, history, id, ids, loading, t]);

  const nextTabs = React.useMemo(() => {
    let found = -1;
    if (ids) {
      found = ids.findIndex(item => item.idbulletinApprenantType === +id);
    }
    if (found >= 0 && found < ids.length - 1) {
      let newApprenant = ids[found + 1];
      return (
        <Button
          onClick={() =>
            history.push(
              `${ERoutes.deliberationType}/${newApprenant.idbulletinApprenantType}/detail/${newApprenant.idApprenant}/${annee}/${coursTypeTab}`
            )
          }
          text={`${t(ETLCodes.Suivant)}`}
          loading={loading}
          intent="primary"
          minimal={true}
          rightIcon={IconNames.CHEVRON_RIGHT}
        />
      );
    }
    return <Loading isLoading={loading} size={20} />;
  }, [annee, coursTypeTab, history, id, ids, loading, t]);

  return (
    <PageBase breadCrumbs={breadCrumbs}>
      <ProtectedTabs
        id="TabsDeliberationType"
        onChange={newTabId =>
          history.push(`${ERoutes.deliberationType}/${id}/${newTabId}/${idApprenant}/${annee}/${coursTypeTab}`)
        }
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.deliberationType} />
        {previousTabs}
        <Tab id="detail" title={t(ETLCodes.Detail)} panel={<DeliberationTabs />} disabled />
        {nextTabs}
      </ProtectedTabs>
    </PageBase>
  );
};
