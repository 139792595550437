/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EJours } from './EJours';
import {
    EJoursFromJSON,
    EJoursFromJSONTyped,
    EJoursToJSON,
} from './EJours';

/**
 * 
 * @export
 * @interface FcbJustificatifAbsenceGridDto
 */
export interface FcbJustificatifAbsenceGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbJustificatifAbsenceGridDto
     */
    idApprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbJustificatifAbsenceGridDto
     */
    idJustificatif?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbJustificatifAbsenceGridDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbJustificatifAbsenceGridDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbJustificatifAbsenceGridDto
     */
    dateFin?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbJustificatifAbsenceGridDto
     */
    genre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbJustificatifAbsenceGridDto
     */
    type?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbJustificatifAbsenceGridDto
     */
    accepte?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FcbJustificatifAbsenceGridDto
     */
    plages?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbJustificatifAbsenceGridDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbJustificatifAbsenceGridDto
     */
    jour1?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbJustificatifAbsenceGridDto
     */
    jour2?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbJustificatifAbsenceGridDto
     */
    jour3?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbJustificatifAbsenceGridDto
     */
    jour4?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbJustificatifAbsenceGridDto
     */
    jour5?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbJustificatifAbsenceGridDto
     */
    jour6?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbJustificatifAbsenceGridDto
     */
    jour7?: boolean;
    /**
     * 
     * @type {Array<EJours>}
     * @memberof FcbJustificatifAbsenceGridDto
     */
    jours?: Array<EJours> | null;
}

/**
 * Check if a given object implements the FcbJustificatifAbsenceGridDto interface.
 */
export function instanceOfFcbJustificatifAbsenceGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbJustificatifAbsenceGridDtoFromJSON(json: any): FcbJustificatifAbsenceGridDto {
    return FcbJustificatifAbsenceGridDtoFromJSONTyped(json, false);
}

export function FcbJustificatifAbsenceGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbJustificatifAbsenceGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idApprenant': !exists(json, 'idApprenant') ? undefined : json['idApprenant'],
        'idJustificatif': !exists(json, 'idJustificatif') ? undefined : json['idJustificatif'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
        'genre': !exists(json, 'genre') ? undefined : json['genre'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'accepte': !exists(json, 'accepte') ? undefined : json['accepte'],
        'plages': !exists(json, 'plages') ? undefined : json['plages'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'jour1': !exists(json, 'jour1') ? undefined : json['jour1'],
        'jour2': !exists(json, 'jour2') ? undefined : json['jour2'],
        'jour3': !exists(json, 'jour3') ? undefined : json['jour3'],
        'jour4': !exists(json, 'jour4') ? undefined : json['jour4'],
        'jour5': !exists(json, 'jour5') ? undefined : json['jour5'],
        'jour6': !exists(json, 'jour6') ? undefined : json['jour6'],
        'jour7': !exists(json, 'jour7') ? undefined : json['jour7'],
        'jours': !exists(json, 'jours') ? undefined : (json['jours'] === null ? null : (json['jours'] as Array<any>).map(EJoursFromJSON)),
    };
}

export function FcbJustificatifAbsenceGridDtoToJSON(value?: FcbJustificatifAbsenceGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idApprenant': value.idApprenant,
        'idJustificatif': value.idJustificatif,
        'anneeScolaire': value.anneeScolaire,
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
        'genre': value.genre,
        'type': value.type,
        'accepte': value.accepte,
        'plages': value.plages,
        'remarque': value.remarque,
        'jour1': value.jour1,
        'jour2': value.jour2,
        'jour3': value.jour3,
        'jour4': value.jour4,
        'jour5': value.jour5,
        'jour6': value.jour6,
        'jour7': value.jour7,
        'jours': value.jours === undefined ? undefined : (value.jours === null ? null : (value.jours as Array<any>).map(EJoursToJSON)),
    };
}

