import { lowerFirst } from "lodash";
import { showError, ValidationErrorsType } from "nsitools-react";
import * as React from "react";

import { useTl } from ".";
import { ErrorDetails, ResultErrorFromJSON } from "../api";
import { ETLCodes } from "../locales/ETLCodes";

export const useManageError = () => {
  const { t, tUnsafe } = useTl();
  const [saveError, setCurrentError] = React.useState<ErrorDetails>();

  const manageError = React.useCallback(
    (error: any, defaultMessage?: string) => {
      if (!!error.response) {
        error.response
          .clone()
          .json()
          .then((d: ErrorDetails) => {
            setCurrentError(d);
          });
      } else {
        showError(defaultMessage ?? t(ETLCodes.Error));
      }
    },
    [t]
  );

  React.useEffect(() => {
    if (saveError) {
      if (saveError.errorCode) {
        showError(tUnsafe(`BackendError_${saveError.errorCode}`));
      } else if (!saveError.validationErrors) {
        showError(t(ETLCodes.Error));
      }
    }
  }, [saveError, t, tUnsafe]);

  const parseValidationErrors = React.useCallback(
    (error: any, rootKey = ""): ValidationErrorsType => {
      if (!error) {
        return {} as ValidationErrorsType;
      }
      if (Array.isArray(error)) {
        return error.map(ResultErrorFromJSON).reduce((curr, next) => {
          if (next.propertyName && next.errorCode) {
            let propertyName = next["propertyName"];

            if (rootKey !== "" && propertyName.startsWith(rootKey + "."))
              propertyName = propertyName.replace(rootKey + ".", "");
            propertyName = propertyName
              .split(".")
              .map(p => lowerFirst(p))
              .join(".");

            let existingErrors = [];

            if (curr.hasOwnProperty(propertyName)) existingErrors = curr[propertyName];

            curr[propertyName] = [
              ...existingErrors,
              next.errorCode ? tUnsafe("ServerValidationError_" + next.errorCode) : next.errorMessage
            ];
          }
          return curr;
        }, {} as ValidationErrorsType);
      }
      return {} as ValidationErrorsType;
    },
    [tUnsafe]
  );

  return {
    saveError,
    setCurrentError,
    manageError,
    parseValidationErrors
  };
};
