import { Classes, Dialog } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";
import { TuteurLieuFormationGridDto } from "../../../../../api";
import { FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import * as Yup from "yup";
import { FieldGroup } from "nsitools-react";

const StyledDialog = styled(Dialog)`
  width: 600px;
  height: auto;
  background-color: white;
`;

export interface ITuteurLieuFormationSelectDialogProps {
  dialogOpen: boolean;
  lieuxFormation: TuteurLieuFormationGridDto[];
  onClose: (idlieuFormation?: number) => void;
}

export const TuteurLieuFormationSelectDialog: React.FunctionComponent<ITuteurLieuFormationSelectDialogProps> = ({
  lieuxFormation,
  dialogOpen,
  onClose
}) => {
  const { t } = useTl();

  const initialData = React.useMemo(() => ({ idlieuFormation: null }), []);

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape({
        idlieuFormation: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required))
      }),
    [t]
  );

  return (
    <StyledDialog title={t(ETLCodes.ChoisirLieuFormation)} isOpen={dialogOpen} onClose={() => onClose()}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={initialData}
          onSubmit={d => onClose(d.idlieuFormation)}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={onClose}
          showDeleteButton={false}
          enableDirtyCheck={false}
        >
          <FieldGroup>
            <FGWalterSelectInput
              name="idlieuFormation"
              label={t(ETLCodes.LieuFormation)}
              items={lieuxFormation}
              displayField="nom"
              valueField="idlieuFormation"
            />
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
