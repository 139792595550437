/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FilterCriteriaInfo,
  InstitutionApprenantLiesGridDtoPaginatedResults,
  InstitutionApprenantLiesSearchDto,
  InstitutionEditDto,
  InstitutionGridDtoPaginatedResults,
  InstitutionSearchDto,
  ReferentialItemDto,
} from '../models/index';
import {
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    InstitutionApprenantLiesGridDtoPaginatedResultsFromJSON,
    InstitutionApprenantLiesGridDtoPaginatedResultsToJSON,
    InstitutionApprenantLiesSearchDtoFromJSON,
    InstitutionApprenantLiesSearchDtoToJSON,
    InstitutionEditDtoFromJSON,
    InstitutionEditDtoToJSON,
    InstitutionGridDtoPaginatedResultsFromJSON,
    InstitutionGridDtoPaginatedResultsToJSON,
    InstitutionSearchDtoFromJSON,
    InstitutionSearchDtoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface InstitutionBaseSearchRequest {
    InstitutionSearchDto?: InstitutionSearchDto;
}

export interface InstitutionDeleteInstitutionRequest {
    id?: number;
}

export interface InstitutionGetApprenantLiesRequest {
    InstitutionApprenantLiesSearchDto?: InstitutionApprenantLiesSearchDto;
}

export interface InstitutionGetDisplayNameRequest {
    id?: number;
}

export interface InstitutionGetInstitutionRequest {
    id?: number;
}

export interface InstitutionGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface InstitutionGetSelectItemsRequest {
    searchField: string;
}

export interface InstitutionSaveInstitutionRequest {
    InstitutionEditDto?: InstitutionEditDto;
}

/**
 * 
 */
export class InstitutionApi extends runtime.BaseAPI {

    /**
     */
    async institutionBaseSearchRaw(requestParameters: InstitutionBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstitutionGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Institution/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstitutionSearchDtoToJSON(requestParameters.InstitutionSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstitutionGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async institutionBaseSearch(requestParameters: InstitutionBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstitutionGridDtoPaginatedResults> {
        const response = await this.institutionBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async institutionDeleteInstitutionRaw(requestParameters: InstitutionDeleteInstitutionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Institution/DeleteInstitution`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async institutionDeleteInstitution(requestParameters: InstitutionDeleteInstitutionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.institutionDeleteInstitutionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async institutionGetApprenantLiesRaw(requestParameters: InstitutionGetApprenantLiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstitutionApprenantLiesGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Institution/GetApprenantLies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstitutionApprenantLiesSearchDtoToJSON(requestParameters.InstitutionApprenantLiesSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstitutionApprenantLiesGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async institutionGetApprenantLies(requestParameters: InstitutionGetApprenantLiesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstitutionApprenantLiesGridDtoPaginatedResults> {
        const response = await this.institutionGetApprenantLiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async institutionGetDisplayNameRaw(requestParameters: InstitutionGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Institution/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async institutionGetDisplayName(requestParameters: InstitutionGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.institutionGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async institutionGetInstitutionRaw(requestParameters: InstitutionGetInstitutionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstitutionEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Institution`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstitutionEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async institutionGetInstitution(requestParameters: InstitutionGetInstitutionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstitutionEditDto> {
        const response = await this.institutionGetInstitutionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async institutionGetSearchCriteriasRaw(requestParameters: InstitutionGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Institution/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async institutionGetSearchCriterias(requestParameters: InstitutionGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.institutionGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async institutionGetSelectItemsRaw(requestParameters: InstitutionGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling institutionGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Institution/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async institutionGetSelectItems(requestParameters: InstitutionGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.institutionGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async institutionSaveInstitutionRaw(requestParameters: InstitutionSaveInstitutionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstitutionEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Institution`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstitutionEditDtoToJSON(requestParameters.InstitutionEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstitutionEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async institutionSaveInstitution(requestParameters: InstitutionSaveInstitutionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstitutionEditDto> {
        const response = await this.institutionSaveInstitutionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
