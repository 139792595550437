/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbRepresentantDoublonGridDtoPaginatedResults,
  FilterCriteriaInfo,
  RepresentantDoublonSearch,
  SelectItem,
} from '../models/index';
import {
    FcbRepresentantDoublonGridDtoPaginatedResultsFromJSON,
    FcbRepresentantDoublonGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    RepresentantDoublonSearchFromJSON,
    RepresentantDoublonSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface RepresentantDoublonBaseSearchRequest {
    RepresentantDoublonSearch?: RepresentantDoublonSearch;
}

export interface RepresentantDoublonGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface RepresentantDoublonGetSelectItemsRequest {
    searchField: string;
}

/**
 * 
 */
export class RepresentantDoublonApi extends runtime.BaseAPI {

    /**
     */
    async representantDoublonBaseSearchRaw(requestParameters: RepresentantDoublonBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRepresentantDoublonGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RepresentantDoublon/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RepresentantDoublonSearchToJSON(requestParameters.RepresentantDoublonSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRepresentantDoublonGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async representantDoublonBaseSearch(requestParameters: RepresentantDoublonBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRepresentantDoublonGridDtoPaginatedResults> {
        const response = await this.representantDoublonBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async representantDoublonGetSearchCriteriasRaw(requestParameters: RepresentantDoublonGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RepresentantDoublon/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async representantDoublonGetSearchCriterias(requestParameters: RepresentantDoublonGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.representantDoublonGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async representantDoublonGetSelectItemsRaw(requestParameters: RepresentantDoublonGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling representantDoublonGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RepresentantDoublon/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async representantDoublonGetSelectItems(requestParameters: RepresentantDoublonGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.representantDoublonGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
