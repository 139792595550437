/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdmissionGridDtoPaginatedResults,
  AdmissionSearchDto,
  BilanCompetenceEditDto,
  BilanCompetenceFichierDto,
  BilanCompetenceFichierUploadDto,
  DossierAdmissionEditDto,
  DossierAdmissionFichierDto,
  DossierAdmissionFichierUploadDto,
  ExamenEntreeEditDto,
  ExamenEntreeFceEditDto,
  ExamenEntreeFceFichierDto,
  ExamenEntreeFceFichierUploadDto,
  ExamenEntreeFichierDto,
  ExamenEntreeFichierUploadDto,
  FileDownloadDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
} from '../models/index';
import {
    AdmissionGridDtoPaginatedResultsFromJSON,
    AdmissionGridDtoPaginatedResultsToJSON,
    AdmissionSearchDtoFromJSON,
    AdmissionSearchDtoToJSON,
    BilanCompetenceEditDtoFromJSON,
    BilanCompetenceEditDtoToJSON,
    BilanCompetenceFichierDtoFromJSON,
    BilanCompetenceFichierDtoToJSON,
    BilanCompetenceFichierUploadDtoFromJSON,
    BilanCompetenceFichierUploadDtoToJSON,
    DossierAdmissionEditDtoFromJSON,
    DossierAdmissionEditDtoToJSON,
    DossierAdmissionFichierDtoFromJSON,
    DossierAdmissionFichierDtoToJSON,
    DossierAdmissionFichierUploadDtoFromJSON,
    DossierAdmissionFichierUploadDtoToJSON,
    ExamenEntreeEditDtoFromJSON,
    ExamenEntreeEditDtoToJSON,
    ExamenEntreeFceEditDtoFromJSON,
    ExamenEntreeFceEditDtoToJSON,
    ExamenEntreeFceFichierDtoFromJSON,
    ExamenEntreeFceFichierDtoToJSON,
    ExamenEntreeFceFichierUploadDtoFromJSON,
    ExamenEntreeFceFichierUploadDtoToJSON,
    ExamenEntreeFichierDtoFromJSON,
    ExamenEntreeFichierDtoToJSON,
    ExamenEntreeFichierUploadDtoFromJSON,
    ExamenEntreeFichierUploadDtoToJSON,
    FileDownloadDtoFromJSON,
    FileDownloadDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface AcquisAdmissionBaseSearchRequest {
    AdmissionSearchDto?: AdmissionSearchDto;
}

export interface AcquisAdmissionDeleteBilanCompetenceRequest {
    id?: number;
}

export interface AcquisAdmissionDeleteBilanCompetenceFichierRequest {
    id?: number;
}

export interface AcquisAdmissionDeleteDossierAdmissionRequest {
    id?: number;
}

export interface AcquisAdmissionDeleteDossierAdmissionFichierRequest {
    id?: number;
}

export interface AcquisAdmissionDeleteExamenEntreeRequest {
    id?: number;
}

export interface AcquisAdmissionDeleteExamenEntreeFceRequest {
    id?: number;
}

export interface AcquisAdmissionDeleteExamenEntreeFceFichierRequest {
    id?: number;
}

export interface AcquisAdmissionDeleteExamenEntreeFichierRequest {
    id?: number;
}

export interface AcquisAdmissionDownloadBilanCompetenceFichierRequest {
    id?: number;
}

export interface AcquisAdmissionDownloadDossierAdmissionFichierRequest {
    id?: number;
}

export interface AcquisAdmissionDownloadExamenEntreeFceFichierRequest {
    id?: number;
}

export interface AcquisAdmissionDownloadExamenEntreeFichierRequest {
    id?: number;
}

export interface AcquisAdmissionGetBilanCompetenceRequest {
    id?: number;
}

export interface AcquisAdmissionGetBilanCompetenceFichierRequest {
    id?: number;
}

export interface AcquisAdmissionGetDossierAdmissionRequest {
    id?: number;
}

export interface AcquisAdmissionGetDossierAdmissionFichierRequest {
    id?: number;
}

export interface AcquisAdmissionGetExamenEntreeRequest {
    id?: number;
}

export interface AcquisAdmissionGetExamenEntreeFceRequest {
    id?: number;
}

export interface AcquisAdmissionGetExamenEntreeFceFichierRequest {
    id?: number;
}

export interface AcquisAdmissionGetExamenEntreeFichierRequest {
    id?: number;
}

export interface AcquisAdmissionGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface AcquisAdmissionGetSelectItemsRequest {
    searchField: string;
}

export interface AcquisAdmissionSaveBilanCompetenceRequest {
    BilanCompetenceEditDto?: BilanCompetenceEditDto;
}

export interface AcquisAdmissionSaveBilanCompetenceFichierRequest {
    BilanCompetenceFichierDto?: BilanCompetenceFichierDto;
}

export interface AcquisAdmissionSaveDossierAdmissionRequest {
    DossierAdmissionEditDto?: DossierAdmissionEditDto;
}

export interface AcquisAdmissionSaveDossierAdmissionFichierRequest {
    DossierAdmissionFichierDto?: DossierAdmissionFichierDto;
}

export interface AcquisAdmissionSaveExamenEntreeRequest {
    ExamenEntreeEditDto?: ExamenEntreeEditDto;
}

export interface AcquisAdmissionSaveExamenEntreeFceRequest {
    ExamenEntreeFceEditDto?: ExamenEntreeFceEditDto;
}

export interface AcquisAdmissionSaveExamenEntreeFceFichierRequest {
    ExamenEntreeFceFichierDto?: ExamenEntreeFceFichierDto;
}

export interface AcquisAdmissionSaveExamenEntreeFichierRequest {
    ExamenEntreeFichierDto?: ExamenEntreeFichierDto;
}

export interface AcquisAdmissionUploadBilanCompetenceFichierRequest {
    BilanCompetenceFichierUploadDto?: BilanCompetenceFichierUploadDto;
}

export interface AcquisAdmissionUploadDossierAdmissionFichierRequest {
    DossierAdmissionFichierUploadDto?: DossierAdmissionFichierUploadDto;
}

export interface AcquisAdmissionUploadExamenEntreeFceFichierRequest {
    ExamenEntreeFceFichierUploadDto?: ExamenEntreeFceFichierUploadDto;
}

export interface AcquisAdmissionUploadExamenEntreeFichierRequest {
    ExamenEntreeFichierUploadDto?: ExamenEntreeFichierUploadDto;
}

/**
 * 
 */
export class AcquisAdmissionApi extends runtime.BaseAPI {

    /**
     */
    async acquisAdmissionBaseSearchRaw(requestParameters: AcquisAdmissionBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdmissionGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdmissionSearchDtoToJSON(requestParameters.AdmissionSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdmissionGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionBaseSearch(requestParameters: AcquisAdmissionBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdmissionGridDtoPaginatedResults> {
        const response = await this.acquisAdmissionBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionDeleteBilanCompetenceRaw(requestParameters: AcquisAdmissionDeleteBilanCompetenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/DeleteBilanCompetence`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async acquisAdmissionDeleteBilanCompetence(requestParameters: AcquisAdmissionDeleteBilanCompetenceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acquisAdmissionDeleteBilanCompetenceRaw(requestParameters, initOverrides);
    }

    /**
     */
    async acquisAdmissionDeleteBilanCompetenceFichierRaw(requestParameters: AcquisAdmissionDeleteBilanCompetenceFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/DeleteBilanCompetenceFichier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async acquisAdmissionDeleteBilanCompetenceFichier(requestParameters: AcquisAdmissionDeleteBilanCompetenceFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acquisAdmissionDeleteBilanCompetenceFichierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async acquisAdmissionDeleteDossierAdmissionRaw(requestParameters: AcquisAdmissionDeleteDossierAdmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/DeleteDossierAdmission`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async acquisAdmissionDeleteDossierAdmission(requestParameters: AcquisAdmissionDeleteDossierAdmissionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acquisAdmissionDeleteDossierAdmissionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async acquisAdmissionDeleteDossierAdmissionFichierRaw(requestParameters: AcquisAdmissionDeleteDossierAdmissionFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/DeleteDossierAdmissionFichier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async acquisAdmissionDeleteDossierAdmissionFichier(requestParameters: AcquisAdmissionDeleteDossierAdmissionFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acquisAdmissionDeleteDossierAdmissionFichierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async acquisAdmissionDeleteExamenEntreeRaw(requestParameters: AcquisAdmissionDeleteExamenEntreeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/DeleteExamenEntree`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async acquisAdmissionDeleteExamenEntree(requestParameters: AcquisAdmissionDeleteExamenEntreeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acquisAdmissionDeleteExamenEntreeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async acquisAdmissionDeleteExamenEntreeFceRaw(requestParameters: AcquisAdmissionDeleteExamenEntreeFceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/DeleteExamenEntreeFce`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async acquisAdmissionDeleteExamenEntreeFce(requestParameters: AcquisAdmissionDeleteExamenEntreeFceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acquisAdmissionDeleteExamenEntreeFceRaw(requestParameters, initOverrides);
    }

    /**
     */
    async acquisAdmissionDeleteExamenEntreeFceFichierRaw(requestParameters: AcquisAdmissionDeleteExamenEntreeFceFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/DeleteExamenEntreeFceFichier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async acquisAdmissionDeleteExamenEntreeFceFichier(requestParameters: AcquisAdmissionDeleteExamenEntreeFceFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acquisAdmissionDeleteExamenEntreeFceFichierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async acquisAdmissionDeleteExamenEntreeFichierRaw(requestParameters: AcquisAdmissionDeleteExamenEntreeFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/DeleteExamenEntreeFichier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async acquisAdmissionDeleteExamenEntreeFichier(requestParameters: AcquisAdmissionDeleteExamenEntreeFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acquisAdmissionDeleteExamenEntreeFichierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async acquisAdmissionDownloadBilanCompetenceFichierRaw(requestParameters: AcquisAdmissionDownloadBilanCompetenceFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/DownloadBilanCompetenceFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionDownloadBilanCompetenceFichier(requestParameters: AcquisAdmissionDownloadBilanCompetenceFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.acquisAdmissionDownloadBilanCompetenceFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionDownloadDossierAdmissionFichierRaw(requestParameters: AcquisAdmissionDownloadDossierAdmissionFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/DownloadDossierAdmissionFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionDownloadDossierAdmissionFichier(requestParameters: AcquisAdmissionDownloadDossierAdmissionFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.acquisAdmissionDownloadDossierAdmissionFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionDownloadExamenEntreeFceFichierRaw(requestParameters: AcquisAdmissionDownloadExamenEntreeFceFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/DownloadExamenEntreeFceFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionDownloadExamenEntreeFceFichier(requestParameters: AcquisAdmissionDownloadExamenEntreeFceFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.acquisAdmissionDownloadExamenEntreeFceFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionDownloadExamenEntreeFichierRaw(requestParameters: AcquisAdmissionDownloadExamenEntreeFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/DownloadExamenEntreeFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionDownloadExamenEntreeFichier(requestParameters: AcquisAdmissionDownloadExamenEntreeFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.acquisAdmissionDownloadExamenEntreeFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionGetBilanCompetenceRaw(requestParameters: AcquisAdmissionGetBilanCompetenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BilanCompetenceEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/GetBilanCompetence`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BilanCompetenceEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionGetBilanCompetence(requestParameters: AcquisAdmissionGetBilanCompetenceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BilanCompetenceEditDto> {
        const response = await this.acquisAdmissionGetBilanCompetenceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionGetBilanCompetenceFichierRaw(requestParameters: AcquisAdmissionGetBilanCompetenceFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BilanCompetenceFichierDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/GetBilanCompetenceFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BilanCompetenceFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionGetBilanCompetenceFichier(requestParameters: AcquisAdmissionGetBilanCompetenceFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BilanCompetenceFichierDto> {
        const response = await this.acquisAdmissionGetBilanCompetenceFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionGetDossierAdmissionRaw(requestParameters: AcquisAdmissionGetDossierAdmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DossierAdmissionEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/GetDossierAdmission`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DossierAdmissionEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionGetDossierAdmission(requestParameters: AcquisAdmissionGetDossierAdmissionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DossierAdmissionEditDto> {
        const response = await this.acquisAdmissionGetDossierAdmissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionGetDossierAdmissionFichierRaw(requestParameters: AcquisAdmissionGetDossierAdmissionFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DossierAdmissionFichierDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/GetDossierAdmissionFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DossierAdmissionFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionGetDossierAdmissionFichier(requestParameters: AcquisAdmissionGetDossierAdmissionFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DossierAdmissionFichierDto> {
        const response = await this.acquisAdmissionGetDossierAdmissionFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionGetExamenEntreeRaw(requestParameters: AcquisAdmissionGetExamenEntreeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExamenEntreeEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/GetExamenEntree`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExamenEntreeEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionGetExamenEntree(requestParameters: AcquisAdmissionGetExamenEntreeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExamenEntreeEditDto> {
        const response = await this.acquisAdmissionGetExamenEntreeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionGetExamenEntreeFceRaw(requestParameters: AcquisAdmissionGetExamenEntreeFceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExamenEntreeFceEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/GetExamenEntreeFce`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExamenEntreeFceEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionGetExamenEntreeFce(requestParameters: AcquisAdmissionGetExamenEntreeFceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExamenEntreeFceEditDto> {
        const response = await this.acquisAdmissionGetExamenEntreeFceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionGetExamenEntreeFceFichierRaw(requestParameters: AcquisAdmissionGetExamenEntreeFceFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExamenEntreeFceFichierDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/GetExamenEntreeFceFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExamenEntreeFceFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionGetExamenEntreeFceFichier(requestParameters: AcquisAdmissionGetExamenEntreeFceFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExamenEntreeFceFichierDto> {
        const response = await this.acquisAdmissionGetExamenEntreeFceFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionGetExamenEntreeFichierRaw(requestParameters: AcquisAdmissionGetExamenEntreeFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExamenEntreeFichierDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/GetExamenEntreeFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExamenEntreeFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionGetExamenEntreeFichier(requestParameters: AcquisAdmissionGetExamenEntreeFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExamenEntreeFichierDto> {
        const response = await this.acquisAdmissionGetExamenEntreeFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionGetSearchCriteriasRaw(requestParameters: AcquisAdmissionGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async acquisAdmissionGetSearchCriterias(requestParameters: AcquisAdmissionGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.acquisAdmissionGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionGetSelectItemsRaw(requestParameters: AcquisAdmissionGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling acquisAdmissionGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async acquisAdmissionGetSelectItems(requestParameters: AcquisAdmissionGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.acquisAdmissionGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionSaveBilanCompetenceRaw(requestParameters: AcquisAdmissionSaveBilanCompetenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BilanCompetenceEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/SaveBilanCompetence`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BilanCompetenceEditDtoToJSON(requestParameters.BilanCompetenceEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BilanCompetenceEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionSaveBilanCompetence(requestParameters: AcquisAdmissionSaveBilanCompetenceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BilanCompetenceEditDto> {
        const response = await this.acquisAdmissionSaveBilanCompetenceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionSaveBilanCompetenceFichierRaw(requestParameters: AcquisAdmissionSaveBilanCompetenceFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BilanCompetenceFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/SaveBilanCompetenceFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BilanCompetenceFichierDtoToJSON(requestParameters.BilanCompetenceFichierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BilanCompetenceFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionSaveBilanCompetenceFichier(requestParameters: AcquisAdmissionSaveBilanCompetenceFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BilanCompetenceFichierDto> {
        const response = await this.acquisAdmissionSaveBilanCompetenceFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionSaveDossierAdmissionRaw(requestParameters: AcquisAdmissionSaveDossierAdmissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DossierAdmissionEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/SaveDossierAdmission`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DossierAdmissionEditDtoToJSON(requestParameters.DossierAdmissionEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DossierAdmissionEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionSaveDossierAdmission(requestParameters: AcquisAdmissionSaveDossierAdmissionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DossierAdmissionEditDto> {
        const response = await this.acquisAdmissionSaveDossierAdmissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionSaveDossierAdmissionFichierRaw(requestParameters: AcquisAdmissionSaveDossierAdmissionFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DossierAdmissionFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/SaveDossierAdmissionFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DossierAdmissionFichierDtoToJSON(requestParameters.DossierAdmissionFichierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DossierAdmissionFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionSaveDossierAdmissionFichier(requestParameters: AcquisAdmissionSaveDossierAdmissionFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DossierAdmissionFichierDto> {
        const response = await this.acquisAdmissionSaveDossierAdmissionFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionSaveExamenEntreeRaw(requestParameters: AcquisAdmissionSaveExamenEntreeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExamenEntreeEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/SaveExamenEntree`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExamenEntreeEditDtoToJSON(requestParameters.ExamenEntreeEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExamenEntreeEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionSaveExamenEntree(requestParameters: AcquisAdmissionSaveExamenEntreeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExamenEntreeEditDto> {
        const response = await this.acquisAdmissionSaveExamenEntreeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionSaveExamenEntreeFceRaw(requestParameters: AcquisAdmissionSaveExamenEntreeFceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExamenEntreeFceEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/SaveExamenEntreeFce`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExamenEntreeFceEditDtoToJSON(requestParameters.ExamenEntreeFceEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExamenEntreeFceEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionSaveExamenEntreeFce(requestParameters: AcquisAdmissionSaveExamenEntreeFceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExamenEntreeFceEditDto> {
        const response = await this.acquisAdmissionSaveExamenEntreeFceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionSaveExamenEntreeFceFichierRaw(requestParameters: AcquisAdmissionSaveExamenEntreeFceFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExamenEntreeFceFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/SaveExamenEntreeFceFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExamenEntreeFceFichierDtoToJSON(requestParameters.ExamenEntreeFceFichierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExamenEntreeFceFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionSaveExamenEntreeFceFichier(requestParameters: AcquisAdmissionSaveExamenEntreeFceFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExamenEntreeFceFichierDto> {
        const response = await this.acquisAdmissionSaveExamenEntreeFceFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionSaveExamenEntreeFichierRaw(requestParameters: AcquisAdmissionSaveExamenEntreeFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExamenEntreeFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/SaveExamenEntreeFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExamenEntreeFichierDtoToJSON(requestParameters.ExamenEntreeFichierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExamenEntreeFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionSaveExamenEntreeFichier(requestParameters: AcquisAdmissionSaveExamenEntreeFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExamenEntreeFichierDto> {
        const response = await this.acquisAdmissionSaveExamenEntreeFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionUploadBilanCompetenceFichierRaw(requestParameters: AcquisAdmissionUploadBilanCompetenceFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BilanCompetenceFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/UploadBilanCompetenceFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BilanCompetenceFichierUploadDtoToJSON(requestParameters.BilanCompetenceFichierUploadDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BilanCompetenceFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionUploadBilanCompetenceFichier(requestParameters: AcquisAdmissionUploadBilanCompetenceFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BilanCompetenceFichierDto> {
        const response = await this.acquisAdmissionUploadBilanCompetenceFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionUploadDossierAdmissionFichierRaw(requestParameters: AcquisAdmissionUploadDossierAdmissionFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DossierAdmissionFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/UploadDossierAdmissionFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DossierAdmissionFichierUploadDtoToJSON(requestParameters.DossierAdmissionFichierUploadDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DossierAdmissionFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionUploadDossierAdmissionFichier(requestParameters: AcquisAdmissionUploadDossierAdmissionFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DossierAdmissionFichierDto> {
        const response = await this.acquisAdmissionUploadDossierAdmissionFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionUploadExamenEntreeFceFichierRaw(requestParameters: AcquisAdmissionUploadExamenEntreeFceFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExamenEntreeFceFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/UploadExamenEntreeFceFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExamenEntreeFceFichierUploadDtoToJSON(requestParameters.ExamenEntreeFceFichierUploadDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExamenEntreeFceFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionUploadExamenEntreeFceFichier(requestParameters: AcquisAdmissionUploadExamenEntreeFceFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExamenEntreeFceFichierDto> {
        const response = await this.acquisAdmissionUploadExamenEntreeFceFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisAdmissionUploadExamenEntreeFichierRaw(requestParameters: AcquisAdmissionUploadExamenEntreeFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExamenEntreeFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisAdmission/UploadExamenEntreeFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExamenEntreeFichierUploadDtoToJSON(requestParameters.ExamenEntreeFichierUploadDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExamenEntreeFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisAdmissionUploadExamenEntreeFichier(requestParameters: AcquisAdmissionUploadExamenEntreeFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExamenEntreeFichierDto> {
        const response = await this.acquisAdmissionUploadExamenEntreeFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
