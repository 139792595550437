/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbSmsGridDto
 */
export interface FcbSmsGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbSmsGridDto
     */
    message?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbSmsGridDto
     */
    dateEnvoi?: Date;
    /**
     * 
     * @type {string}
     * @memberof FcbSmsGridDto
     */
    statut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbSmsGridDto
     */
    anneeScolaire?: string | null;
}

/**
 * Check if a given object implements the FcbSmsGridDto interface.
 */
export function instanceOfFcbSmsGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbSmsGridDtoFromJSON(json: any): FcbSmsGridDto {
    return FcbSmsGridDtoFromJSONTyped(json, false);
}

export function FcbSmsGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbSmsGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'dateEnvoi': !exists(json, 'dateEnvoi') ? undefined : (new Date(json['dateEnvoi'])),
        'statut': !exists(json, 'statut') ? undefined : json['statut'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
    };
}

export function FcbSmsGridDtoToJSON(value?: FcbSmsGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'dateEnvoi': value.dateEnvoi === undefined ? undefined : (value.dateEnvoi.toISOString()),
        'statut': value.statut,
        'anneeScolaire': value.anneeScolaire,
    };
}

