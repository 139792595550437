import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { ApprenantStatutListPage } from "./ApprenantStatutListPage";

export interface IApprenantStatutProps {}

export const ApprenantStatut: React.FunctionComponent<IApprenantStatutProps> = (props) => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={ApprenantStatutListPage} exact />
    </Switch>
  );
};
