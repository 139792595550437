/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbClasseHoraireMatiereGridDto
 */
export interface FcbClasseHoraireMatiereGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbClasseHoraireMatiereGridDto
     */
    idClasseMatiere?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseHoraireMatiereGridDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseHoraireMatiereGridDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseHoraireMatiereGridDto
     */
    formateur?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseHoraireMatiereGridDto
     */
    nbHeures?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseHoraireMatiereGridDto
     */
    nbHeuresReelles?: number;
}

/**
 * Check if a given object implements the FcbClasseHoraireMatiereGridDto interface.
 */
export function instanceOfFcbClasseHoraireMatiereGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbClasseHoraireMatiereGridDtoFromJSON(json: any): FcbClasseHoraireMatiereGridDto {
    return FcbClasseHoraireMatiereGridDtoFromJSONTyped(json, false);
}

export function FcbClasseHoraireMatiereGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbClasseHoraireMatiereGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idClasseMatiere': !exists(json, 'idClasseMatiere') ? undefined : json['idClasseMatiere'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'formateur': !exists(json, 'formateur') ? undefined : json['formateur'],
        'nbHeures': !exists(json, 'nbHeures') ? undefined : json['nbHeures'],
        'nbHeuresReelles': !exists(json, 'nbHeuresReelles') ? undefined : json['nbHeuresReelles'],
    };
}

export function FcbClasseHoraireMatiereGridDtoToJSON(value?: FcbClasseHoraireMatiereGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idClasseMatiere': value.idClasseMatiere,
        'code': value.code,
        'description': value.description,
        'formateur': value.formateur,
        'nbHeures': value.nbHeures,
        'nbHeuresReelles': value.nbHeuresReelles,
    };
}

