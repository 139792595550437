import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ReferentielApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { ReferentielMatiereItemPage } from "./matiere/ReferentielMatiereItemPage";
import { ReferentielDetailPage } from "./ReferentielDetailPage";

interface IReferentielItemPageProps {}

export const ReferentielItemPage: React.FunctionComponent<IReferentielItemPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id, tab, state = "edit", copyId } = useParams<{ id: string; tab: string; state: string; copyId: string }>();
  const referentielApi = useApiService(ReferentielApi);
  const [displayName] = useApiEffect(() => referentielApi.referentielGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.Referentiels), route: ERoutes.referentiel },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabDetail"
        onChange={newTabId => history.push(`${ERoutes.referentiel}/${id}/${newTabId}`)}
        renderActiveTabPanelOnly
        selectedTabId={tab}
      >
        <BackButton backRoute={ERoutes.referentiel}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<ReferentielDetailPage idReferentiel={+id} editMode={state === "edit"} copyId={+copyId} />}
        />
        <Tab
          id="matiere"
          disabled={+id <= 0}
          title={t(ETLCodes.Matieres)}
          panel={<ReferentielMatiereItemPage></ReferentielMatiereItemPage>}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
