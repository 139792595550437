/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FilterCriteriaInfo,
  ReferentialItemDto,
  ServiceUserLieEditDto,
  ServiceUserLieGridDtoPaginatedResults,
  ServiceUserLieSearch,
} from '../models/index';
import {
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
    ServiceUserLieEditDtoFromJSON,
    ServiceUserLieEditDtoToJSON,
    ServiceUserLieGridDtoPaginatedResultsFromJSON,
    ServiceUserLieGridDtoPaginatedResultsToJSON,
    ServiceUserLieSearchFromJSON,
    ServiceUserLieSearchToJSON,
} from '../models/index';

export interface ServiceTutelleUserLieBaseSearchRequest {
    ServiceUserLieSearch?: ServiceUserLieSearch;
}

export interface ServiceTutelleUserLieGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ServiceTutelleUserLieGetSelectItemsRequest {
    searchField: string;
}

export interface ServiceTutelleUserLieSaveServiceTutelleUserLieRequest {
    ServiceUserLieEditDto?: ServiceUserLieEditDto;
}

/**
 * 
 */
export class ServiceTutelleUserLieApi extends runtime.BaseAPI {

    /**
     */
    async serviceTutelleUserLieBaseSearchRaw(requestParameters: ServiceTutelleUserLieBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceUserLieGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ServiceTutelleUserLie/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceUserLieSearchToJSON(requestParameters.ServiceUserLieSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceUserLieGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async serviceTutelleUserLieBaseSearch(requestParameters: ServiceTutelleUserLieBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceUserLieGridDtoPaginatedResults> {
        const response = await this.serviceTutelleUserLieBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async serviceTutelleUserLieGetSearchCriteriasRaw(requestParameters: ServiceTutelleUserLieGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ServiceTutelleUserLie/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async serviceTutelleUserLieGetSearchCriterias(requestParameters: ServiceTutelleUserLieGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.serviceTutelleUserLieGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async serviceTutelleUserLieGetSelectItemsRaw(requestParameters: ServiceTutelleUserLieGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling serviceTutelleUserLieGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ServiceTutelleUserLie/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async serviceTutelleUserLieGetSelectItems(requestParameters: ServiceTutelleUserLieGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.serviceTutelleUserLieGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async serviceTutelleUserLieSaveServiceTutelleUserLieRaw(requestParameters: ServiceTutelleUserLieSaveServiceTutelleUserLieRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceUserLieEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ServiceTutelleUserLie/SaveServiceTutelleUserLie`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceUserLieEditDtoToJSON(requestParameters.ServiceUserLieEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceUserLieEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async serviceTutelleUserLieSaveServiceTutelleUserLie(requestParameters: ServiceTutelleUserLieSaveServiceTutelleUserLieRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceUserLieEditDto> {
        const response = await this.serviceTutelleUserLieSaveServiceTutelleUserLieRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
