import * as React from "react";
import { useTl, useApiService, useAbortableApiServiceFactory } from "../../../../hooks";
import { useHistory } from "react-router";
import { ButtonContainer } from "nsitools-react";
import { ViewButton, EditButton, SearchTablePage } from "../../../../components";
import { ERoutes } from "../../../../AppRouter";
import { ETLCodes } from "../../../../locales";
import {
  RemplacementApi,
  FcbRechercheRemplacementDto,
  RechercheRemplacementSearch,
  ETextSearchType,
  EListSearchType
} from "../../../../api";
import { useGlobalData } from "../../../../contexts";

export interface IRemplacementListPageProps {}

export const RemplacementListPage: React.FunctionComponent<IRemplacementListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { currentAnneeScolaire } = useGlobalData();
  const api = useApiService(RemplacementApi);
  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbRechercheRemplacementDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.remplacement}/${row.idremplacement}/general/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.remplacement}/${row.idremplacement}/general/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Remplace),
        fieldName: "remplace"
      },
      {
        header: () => t(ETLCodes.DateDebut),
        fieldName: "dateDebut"
      },
      {
        header: () => t(ETLCodes.DateFin),
        fieldName: "dateFin"
      },
      {
        header: () => t(ETLCodes.IdTypeRemplacement),
        fieldName: "typeRemplacement"
      },
      {
        header: () => t(ETLCodes.Legitimite),
        fieldName: "legitimite"
      },
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "anneeAcademique"
      }
    ],
    [history, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.remplacement}/0/general/edit`);
  }, [history]);

  const getCriterias = React.useCallback(() => api.remplacementGetSearchCriterias({ includeListsValues: true }), [api]);

  const apiFactory = useAbortableApiServiceFactory(RemplacementApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: RechercheRemplacementSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.remplacementBaseSearch({ RechercheRemplacementSearch: sObj });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriterias}
      searchStateInitialSearch={false}
      searchFunction={searchFn}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.Remplacements), route: ERoutes.remplacement }]}
      addFunc={onAddItem}
      defaultCriterias={[
        {
          criteria: "AnneeAcademique",
          searchMode: ETextSearchType.Equals,
          value: currentAnneeScolaire?.idanneeScolaire
        },
        {
          criteria: "IdLegitimite",
          searchMode: EListSearchType.Equals,
          value: "2"
        },
        {
          criteria: "IdTypeRemplacement",
          searchMode: EListSearchType.Equals,
          value: "2"
        }
      ]}
      sortKeys={{ remplace: "ASC" }}
    />
  );
};
