/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdresseDto } from './AdresseDto';
import {
    AdresseDtoFromJSON,
    AdresseDtoFromJSONTyped,
    AdresseDtoToJSON,
} from './AdresseDto';
import type { CompteBancaireDto } from './CompteBancaireDto';
import {
    CompteBancaireDtoFromJSON,
    CompteBancaireDtoFromJSONTyped,
    CompteBancaireDtoToJSON,
} from './CompteBancaireDto';
import type { EmailDto } from './EmailDto';
import {
    EmailDtoFromJSON,
    EmailDtoFromJSONTyped,
    EmailDtoToJSON,
} from './EmailDto';
import type { TelephoneDto } from './TelephoneDto';
import {
    TelephoneDtoFromJSON,
    TelephoneDtoFromJSONTyped,
    TelephoneDtoToJSON,
} from './TelephoneDto';

/**
 * 
 * @export
 * @interface PersonneEditDto
 */
export interface PersonneEditDto {
    /**
     * 
     * @type {number}
     * @memberof PersonneEditDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {string}
     * @memberof PersonneEditDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneEditDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneEditDto
     */
    prenom2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneEditDto
     */
    codesexe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneEditDto
     */
    nationalite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneEditDto
     */
    codePaysNaissance?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PersonneEditDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneEditDto
     */
    localiteNaissance?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneEditDto
     */
    communeNaissance?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneEditDto
     */
    registreNational?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneEditDto
     */
    numeroIdentification?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneEditDto
     */
    photo?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PersonneEditDto
     */
    idfichierPhoto?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof PersonneEditDto
     */
    dateDeces?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneEditDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {AdresseDto}
     * @memberof PersonneEditDto
     */
    adresseDomicile?: AdresseDto;
    /**
     * 
     * @type {AdresseDto}
     * @memberof PersonneEditDto
     */
    adresseCourrier?: AdresseDto;
    /**
     * 
     * @type {AdresseDto}
     * @memberof PersonneEditDto
     */
    adresseAutre?: AdresseDto;
    /**
     * 
     * @type {Array<CompteBancaireDto>}
     * @memberof PersonneEditDto
     */
    compteBancaires?: Array<CompteBancaireDto> | null;
    /**
     * 
     * @type {Array<TelephoneDto>}
     * @memberof PersonneEditDto
     */
    telephones?: Array<TelephoneDto> | null;
    /**
     * 
     * @type {Array<EmailDto>}
     * @memberof PersonneEditDto
     */
    emails?: Array<EmailDto> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof PersonneEditDto
     */
    idaddressDoublons?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof PersonneEditDto
     */
    idaddressCourrierDoublons?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof PersonneEditDto
     */
    idaddressAutreDoublons?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof PersonneEditDto
     */
    idapprenant?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonneEditDto
     */
    idformateur?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonneEditDto
     */
    idrepresentant?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonneEditDto
     */
    iduser?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonneEditDto
     */
    idchefEntreprise?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonneEditDto
     */
    idtuteur?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneEditDto
     */
    idconseillerPedagogique?: string | null;
}

/**
 * Check if a given object implements the PersonneEditDto interface.
 */
export function instanceOfPersonneEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PersonneEditDtoFromJSON(json: any): PersonneEditDto {
    return PersonneEditDtoFromJSONTyped(json, false);
}

export function PersonneEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonneEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'prenom2': !exists(json, 'prenom2') ? undefined : json['prenom2'],
        'codesexe': !exists(json, 'codesexe') ? undefined : json['codesexe'],
        'nationalite': !exists(json, 'nationalite') ? undefined : json['nationalite'],
        'codePaysNaissance': !exists(json, 'codePaysNaissance') ? undefined : json['codePaysNaissance'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'localiteNaissance': !exists(json, 'localiteNaissance') ? undefined : json['localiteNaissance'],
        'communeNaissance': !exists(json, 'communeNaissance') ? undefined : json['communeNaissance'],
        'registreNational': !exists(json, 'registreNational') ? undefined : json['registreNational'],
        'numeroIdentification': !exists(json, 'numeroIdentification') ? undefined : json['numeroIdentification'],
        'photo': !exists(json, 'photo') ? undefined : json['photo'],
        'idfichierPhoto': !exists(json, 'idfichierPhoto') ? undefined : json['idfichierPhoto'],
        'dateDeces': !exists(json, 'dateDeces') ? undefined : (json['dateDeces'] === null ? null : new Date(json['dateDeces'])),
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'adresseDomicile': !exists(json, 'adresseDomicile') ? undefined : AdresseDtoFromJSON(json['adresseDomicile']),
        'adresseCourrier': !exists(json, 'adresseCourrier') ? undefined : AdresseDtoFromJSON(json['adresseCourrier']),
        'adresseAutre': !exists(json, 'adresseAutre') ? undefined : AdresseDtoFromJSON(json['adresseAutre']),
        'compteBancaires': !exists(json, 'compteBancaires') ? undefined : (json['compteBancaires'] === null ? null : (json['compteBancaires'] as Array<any>).map(CompteBancaireDtoFromJSON)),
        'telephones': !exists(json, 'telephones') ? undefined : (json['telephones'] === null ? null : (json['telephones'] as Array<any>).map(TelephoneDtoFromJSON)),
        'emails': !exists(json, 'emails') ? undefined : (json['emails'] === null ? null : (json['emails'] as Array<any>).map(EmailDtoFromJSON)),
        'idaddressDoublons': !exists(json, 'idaddressDoublons') ? undefined : json['idaddressDoublons'],
        'idaddressCourrierDoublons': !exists(json, 'idaddressCourrierDoublons') ? undefined : json['idaddressCourrierDoublons'],
        'idaddressAutreDoublons': !exists(json, 'idaddressAutreDoublons') ? undefined : json['idaddressAutreDoublons'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'idrepresentant': !exists(json, 'idrepresentant') ? undefined : json['idrepresentant'],
        'iduser': !exists(json, 'iduser') ? undefined : json['iduser'],
        'idchefEntreprise': !exists(json, 'idchefEntreprise') ? undefined : json['idchefEntreprise'],
        'idtuteur': !exists(json, 'idtuteur') ? undefined : json['idtuteur'],
        'idconseillerPedagogique': !exists(json, 'idconseillerPedagogique') ? undefined : json['idconseillerPedagogique'],
    };
}

export function PersonneEditDtoToJSON(value?: PersonneEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idpersonne': value.idpersonne,
        'nom': value.nom,
        'prenom': value.prenom,
        'prenom2': value.prenom2,
        'codesexe': value.codesexe,
        'nationalite': value.nationalite,
        'codePaysNaissance': value.codePaysNaissance,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'localiteNaissance': value.localiteNaissance,
        'communeNaissance': value.communeNaissance,
        'registreNational': value.registreNational,
        'numeroIdentification': value.numeroIdentification,
        'photo': value.photo,
        'idfichierPhoto': value.idfichierPhoto,
        'dateDeces': value.dateDeces === undefined ? undefined : (value.dateDeces === null ? null : value.dateDeces.toISOString()),
        'remarque': value.remarque,
        'adresseDomicile': AdresseDtoToJSON(value.adresseDomicile),
        'adresseCourrier': AdresseDtoToJSON(value.adresseCourrier),
        'adresseAutre': AdresseDtoToJSON(value.adresseAutre),
        'compteBancaires': value.compteBancaires === undefined ? undefined : (value.compteBancaires === null ? null : (value.compteBancaires as Array<any>).map(CompteBancaireDtoToJSON)),
        'telephones': value.telephones === undefined ? undefined : (value.telephones === null ? null : (value.telephones as Array<any>).map(TelephoneDtoToJSON)),
        'emails': value.emails === undefined ? undefined : (value.emails === null ? null : (value.emails as Array<any>).map(EmailDtoToJSON)),
        'idaddressDoublons': value.idaddressDoublons,
        'idaddressCourrierDoublons': value.idaddressCourrierDoublons,
        'idaddressAutreDoublons': value.idaddressAutreDoublons,
        'idapprenant': value.idapprenant,
        'idformateur': value.idformateur,
        'idrepresentant': value.idrepresentant,
        'iduser': value.iduser,
        'idchefEntreprise': value.idchefEntreprise,
        'idtuteur': value.idtuteur,
        'idconseillerPedagogique': value.idconseillerPedagogique,
    };
}

