/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileUploadDto } from './FileUploadDto';
import {
    FileUploadDtoFromJSON,
    FileUploadDtoFromJSONTyped,
    FileUploadDtoToJSON,
} from './FileUploadDto';

/**
 * 
 * @export
 * @interface ContratSuspensionDto
 */
export interface ContratSuspensionDto {
    /**
     * 
     * @type {number}
     * @memberof ContratSuspensionDto
     */
    idcontratSuspension?: number;
    /**
     * 
     * @type {number}
     * @memberof ContratSuspensionDto
     */
    idcontrat?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratSuspensionDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratSuspensionDto
     */
    dateFin?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ContratSuspensionDto
     */
    idmotifSuspension?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContratSuspensionDto
     */
    motif?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratSuspensionDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContratSuspensionDto
     */
    idfichier?: number | null;
    /**
     * 
     * @type {FileUploadDto}
     * @memberof ContratSuspensionDto
     */
    fichier?: FileUploadDto;
}

/**
 * Check if a given object implements the ContratSuspensionDto interface.
 */
export function instanceOfContratSuspensionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContratSuspensionDtoFromJSON(json: any): ContratSuspensionDto {
    return ContratSuspensionDtoFromJSONTyped(json, false);
}

export function ContratSuspensionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContratSuspensionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontratSuspension': !exists(json, 'idcontratSuspension') ? undefined : json['idcontratSuspension'],
        'idcontrat': !exists(json, 'idcontrat') ? undefined : json['idcontrat'],
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
        'idmotifSuspension': !exists(json, 'idmotifSuspension') ? undefined : json['idmotifSuspension'],
        'motif': !exists(json, 'motif') ? undefined : json['motif'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'fichier': !exists(json, 'fichier') ? undefined : FileUploadDtoFromJSON(json['fichier']),
    };
}

export function ContratSuspensionDtoToJSON(value?: ContratSuspensionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontratSuspension': value.idcontratSuspension,
        'idcontrat': value.idcontrat,
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
        'idmotifSuspension': value.idmotifSuspension,
        'motif': value.motif,
        'remarque': value.remarque,
        'idfichier': value.idfichier,
        'fichier': FileUploadDtoToJSON(value.fichier),
    };
}

