/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbCalendrierDto
 */
export interface FcbCalendrierDto {
    /**
     * 
     * @type {number}
     * @memberof FcbCalendrierDto
     */
    calendrierId?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbCalendrierDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbCalendrierDto
     */
    dateFin?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCalendrierDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbCalendrierDto
     */
    idCentre?: number;
    /**
     * 
     * @type {Array<Date>}
     * @memberof FcbCalendrierDto
     */
    conges?: Array<Date> | null;
}

/**
 * Check if a given object implements the FcbCalendrierDto interface.
 */
export function instanceOfFcbCalendrierDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbCalendrierDtoFromJSON(json: any): FcbCalendrierDto {
    return FcbCalendrierDtoFromJSONTyped(json, false);
}

export function FcbCalendrierDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbCalendrierDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'calendrierId': !exists(json, 'calendrierId') ? undefined : json['calendrierId'],
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'idCentre': !exists(json, 'idCentre') ? undefined : json['idCentre'],
        'conges': !exists(json, 'conges') ? undefined : json['conges'],
    };
}

export function FcbCalendrierDtoToJSON(value?: FcbCalendrierDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'calendrierId': value.calendrierId,
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
        'libelle': value.libelle,
        'idCentre': value.idCentre,
        'conges': value.conges,
    };
}

