import * as React from "react";
import { useParams } from "react-router";
import { SiegeLieuSuivi } from ".";
import { ERoutes } from "../../../../../../AppRouter";

export interface ISiegeSocialSuiviItemProps {}

export const SiegeSocialSuiviItem: React.FunctionComponent<ISiegeSocialSuiviItemProps> = props => {
  const { id, state, subId } = useParams<{ id: string; tab: string; state: string; subId: string }>();
  const idsiegeSocial = React.useMemo(() => +id, [id]);
  const idsuivi = React.useMemo(() => +subId, [subId]);
  const editMode = React.useMemo(() => state === "edit", [state]);

  return (
    <SiegeLieuSuivi
      idsuivi={idsuivi}
      idsiegeSocial={idsiegeSocial}
      editMode={editMode}
      backRoute={`${ERoutes.siegeSocial}/${idsiegeSocial}/suivis/edit`}
    />
  );
};
