/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClassHoraireDto
 */
export interface ClassHoraireDto {
    /**
     * 
     * @type {Date}
     * @memberof ClassHoraireDto
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    nomJour?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage01ClasseHoraireId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage01PlageId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage01HeureDebut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage01MatiereId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage01LocalId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassHoraireDto
     */
    plage01Rattrapage?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    readonly plage01Numero?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage02ClasseHoraireId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage02PlageId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage02HeureDebut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage02MatiereId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage02LocalId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassHoraireDto
     */
    plage02Rattrapage?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    readonly plage02Numero?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage03ClasseHoraireId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage03PlageId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage03HeureDebut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage03MatiereId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage03LocalId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassHoraireDto
     */
    plage03Rattrapage?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    readonly plage03Numero?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage04ClasseHoraireId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage04PlageId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage04HeureDebut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage04MatiereId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage04LocalId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassHoraireDto
     */
    plage04Rattrapage?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    readonly plage04Numero?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage05ClasseHoraireId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage05PlageId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage05HeureDebut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage05MatiereId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage05LocalId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassHoraireDto
     */
    plage05Rattrapage?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    readonly plage05Numero?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage06ClasseHoraireId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage06PlageId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage06HeureDebut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage06MatiereId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage06LocalId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassHoraireDto
     */
    plage06Rattrapage?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    readonly plage06Numero?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage07ClasseHoraireId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage07PlageId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage07HeureDebut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage07MatiereId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage07LocalId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassHoraireDto
     */
    plage07Rattrapage?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    readonly plage07Numero?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage08ClasseHoraireId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage08PlageId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage08HeureDebut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage08MatiereId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage08LocalId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassHoraireDto
     */
    plage08Rattrapage?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    readonly plage08Numero?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage09ClasseHoraireId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage09PlageId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage09HeureDebut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage09MatiereId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage09LocalId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassHoraireDto
     */
    plage09Rattrapage?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    readonly plage09Numero?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage10ClasseHoraireId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage10PlageId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage10HeureDebut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage10MatiereId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage10LocalId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassHoraireDto
     */
    plage10Rattrapage?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    readonly plage10Numero?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage11ClasseHoraireId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage11PlageId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage11HeureDebut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage11MatiereId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage11LocalId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassHoraireDto
     */
    plage11Rattrapage?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    readonly plage11Numero?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage12ClasseHoraireId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage12PlageId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage12HeureDebut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage12MatiereId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage12LocalId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassHoraireDto
     */
    plage12Rattrapage?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    readonly plage12Numero?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage13ClasseHoraireId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage13PlageId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage13HeureDebut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage13MatiereId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage13LocalId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassHoraireDto
     */
    plage13Rattrapage?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    readonly plage13Numero?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage14ClasseHoraireId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    plage14PlageId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage14HeureDebut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage14MatiereId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassHoraireDto
     */
    plage14LocalId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassHoraireDto
     */
    plage14Rattrapage?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ClassHoraireDto
     */
    readonly plage14Numero?: number;
}

/**
 * Check if a given object implements the ClassHoraireDto interface.
 */
export function instanceOfClassHoraireDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClassHoraireDtoFromJSON(json: any): ClassHoraireDto {
    return ClassHoraireDtoFromJSONTyped(json, false);
}

export function ClassHoraireDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassHoraireDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'nomJour': !exists(json, 'nomJour') ? undefined : json['nomJour'],
        'plage01ClasseHoraireId': !exists(json, 'plage01ClasseHoraireId') ? undefined : json['plage01ClasseHoraireId'],
        'plage01PlageId': !exists(json, 'plage01PlageId') ? undefined : json['plage01PlageId'],
        'plage01HeureDebut': !exists(json, 'plage01HeureDebut') ? undefined : json['plage01HeureDebut'],
        'plage01MatiereId': !exists(json, 'plage01MatiereId') ? undefined : json['plage01MatiereId'],
        'plage01LocalId': !exists(json, 'plage01LocalId') ? undefined : json['plage01LocalId'],
        'plage01Rattrapage': !exists(json, 'plage01Rattrapage') ? undefined : json['plage01Rattrapage'],
        'plage01Numero': !exists(json, 'plage01Numero') ? undefined : json['plage01Numero'],
        'plage02ClasseHoraireId': !exists(json, 'plage02ClasseHoraireId') ? undefined : json['plage02ClasseHoraireId'],
        'plage02PlageId': !exists(json, 'plage02PlageId') ? undefined : json['plage02PlageId'],
        'plage02HeureDebut': !exists(json, 'plage02HeureDebut') ? undefined : json['plage02HeureDebut'],
        'plage02MatiereId': !exists(json, 'plage02MatiereId') ? undefined : json['plage02MatiereId'],
        'plage02LocalId': !exists(json, 'plage02LocalId') ? undefined : json['plage02LocalId'],
        'plage02Rattrapage': !exists(json, 'plage02Rattrapage') ? undefined : json['plage02Rattrapage'],
        'plage02Numero': !exists(json, 'plage02Numero') ? undefined : json['plage02Numero'],
        'plage03ClasseHoraireId': !exists(json, 'plage03ClasseHoraireId') ? undefined : json['plage03ClasseHoraireId'],
        'plage03PlageId': !exists(json, 'plage03PlageId') ? undefined : json['plage03PlageId'],
        'plage03HeureDebut': !exists(json, 'plage03HeureDebut') ? undefined : json['plage03HeureDebut'],
        'plage03MatiereId': !exists(json, 'plage03MatiereId') ? undefined : json['plage03MatiereId'],
        'plage03LocalId': !exists(json, 'plage03LocalId') ? undefined : json['plage03LocalId'],
        'plage03Rattrapage': !exists(json, 'plage03Rattrapage') ? undefined : json['plage03Rattrapage'],
        'plage03Numero': !exists(json, 'plage03Numero') ? undefined : json['plage03Numero'],
        'plage04ClasseHoraireId': !exists(json, 'plage04ClasseHoraireId') ? undefined : json['plage04ClasseHoraireId'],
        'plage04PlageId': !exists(json, 'plage04PlageId') ? undefined : json['plage04PlageId'],
        'plage04HeureDebut': !exists(json, 'plage04HeureDebut') ? undefined : json['plage04HeureDebut'],
        'plage04MatiereId': !exists(json, 'plage04MatiereId') ? undefined : json['plage04MatiereId'],
        'plage04LocalId': !exists(json, 'plage04LocalId') ? undefined : json['plage04LocalId'],
        'plage04Rattrapage': !exists(json, 'plage04Rattrapage') ? undefined : json['plage04Rattrapage'],
        'plage04Numero': !exists(json, 'plage04Numero') ? undefined : json['plage04Numero'],
        'plage05ClasseHoraireId': !exists(json, 'plage05ClasseHoraireId') ? undefined : json['plage05ClasseHoraireId'],
        'plage05PlageId': !exists(json, 'plage05PlageId') ? undefined : json['plage05PlageId'],
        'plage05HeureDebut': !exists(json, 'plage05HeureDebut') ? undefined : json['plage05HeureDebut'],
        'plage05MatiereId': !exists(json, 'plage05MatiereId') ? undefined : json['plage05MatiereId'],
        'plage05LocalId': !exists(json, 'plage05LocalId') ? undefined : json['plage05LocalId'],
        'plage05Rattrapage': !exists(json, 'plage05Rattrapage') ? undefined : json['plage05Rattrapage'],
        'plage05Numero': !exists(json, 'plage05Numero') ? undefined : json['plage05Numero'],
        'plage06ClasseHoraireId': !exists(json, 'plage06ClasseHoraireId') ? undefined : json['plage06ClasseHoraireId'],
        'plage06PlageId': !exists(json, 'plage06PlageId') ? undefined : json['plage06PlageId'],
        'plage06HeureDebut': !exists(json, 'plage06HeureDebut') ? undefined : json['plage06HeureDebut'],
        'plage06MatiereId': !exists(json, 'plage06MatiereId') ? undefined : json['plage06MatiereId'],
        'plage06LocalId': !exists(json, 'plage06LocalId') ? undefined : json['plage06LocalId'],
        'plage06Rattrapage': !exists(json, 'plage06Rattrapage') ? undefined : json['plage06Rattrapage'],
        'plage06Numero': !exists(json, 'plage06Numero') ? undefined : json['plage06Numero'],
        'plage07ClasseHoraireId': !exists(json, 'plage07ClasseHoraireId') ? undefined : json['plage07ClasseHoraireId'],
        'plage07PlageId': !exists(json, 'plage07PlageId') ? undefined : json['plage07PlageId'],
        'plage07HeureDebut': !exists(json, 'plage07HeureDebut') ? undefined : json['plage07HeureDebut'],
        'plage07MatiereId': !exists(json, 'plage07MatiereId') ? undefined : json['plage07MatiereId'],
        'plage07LocalId': !exists(json, 'plage07LocalId') ? undefined : json['plage07LocalId'],
        'plage07Rattrapage': !exists(json, 'plage07Rattrapage') ? undefined : json['plage07Rattrapage'],
        'plage07Numero': !exists(json, 'plage07Numero') ? undefined : json['plage07Numero'],
        'plage08ClasseHoraireId': !exists(json, 'plage08ClasseHoraireId') ? undefined : json['plage08ClasseHoraireId'],
        'plage08PlageId': !exists(json, 'plage08PlageId') ? undefined : json['plage08PlageId'],
        'plage08HeureDebut': !exists(json, 'plage08HeureDebut') ? undefined : json['plage08HeureDebut'],
        'plage08MatiereId': !exists(json, 'plage08MatiereId') ? undefined : json['plage08MatiereId'],
        'plage08LocalId': !exists(json, 'plage08LocalId') ? undefined : json['plage08LocalId'],
        'plage08Rattrapage': !exists(json, 'plage08Rattrapage') ? undefined : json['plage08Rattrapage'],
        'plage08Numero': !exists(json, 'plage08Numero') ? undefined : json['plage08Numero'],
        'plage09ClasseHoraireId': !exists(json, 'plage09ClasseHoraireId') ? undefined : json['plage09ClasseHoraireId'],
        'plage09PlageId': !exists(json, 'plage09PlageId') ? undefined : json['plage09PlageId'],
        'plage09HeureDebut': !exists(json, 'plage09HeureDebut') ? undefined : json['plage09HeureDebut'],
        'plage09MatiereId': !exists(json, 'plage09MatiereId') ? undefined : json['plage09MatiereId'],
        'plage09LocalId': !exists(json, 'plage09LocalId') ? undefined : json['plage09LocalId'],
        'plage09Rattrapage': !exists(json, 'plage09Rattrapage') ? undefined : json['plage09Rattrapage'],
        'plage09Numero': !exists(json, 'plage09Numero') ? undefined : json['plage09Numero'],
        'plage10ClasseHoraireId': !exists(json, 'plage10ClasseHoraireId') ? undefined : json['plage10ClasseHoraireId'],
        'plage10PlageId': !exists(json, 'plage10PlageId') ? undefined : json['plage10PlageId'],
        'plage10HeureDebut': !exists(json, 'plage10HeureDebut') ? undefined : json['plage10HeureDebut'],
        'plage10MatiereId': !exists(json, 'plage10MatiereId') ? undefined : json['plage10MatiereId'],
        'plage10LocalId': !exists(json, 'plage10LocalId') ? undefined : json['plage10LocalId'],
        'plage10Rattrapage': !exists(json, 'plage10Rattrapage') ? undefined : json['plage10Rattrapage'],
        'plage10Numero': !exists(json, 'plage10Numero') ? undefined : json['plage10Numero'],
        'plage11ClasseHoraireId': !exists(json, 'plage11ClasseHoraireId') ? undefined : json['plage11ClasseHoraireId'],
        'plage11PlageId': !exists(json, 'plage11PlageId') ? undefined : json['plage11PlageId'],
        'plage11HeureDebut': !exists(json, 'plage11HeureDebut') ? undefined : json['plage11HeureDebut'],
        'plage11MatiereId': !exists(json, 'plage11MatiereId') ? undefined : json['plage11MatiereId'],
        'plage11LocalId': !exists(json, 'plage11LocalId') ? undefined : json['plage11LocalId'],
        'plage11Rattrapage': !exists(json, 'plage11Rattrapage') ? undefined : json['plage11Rattrapage'],
        'plage11Numero': !exists(json, 'plage11Numero') ? undefined : json['plage11Numero'],
        'plage12ClasseHoraireId': !exists(json, 'plage12ClasseHoraireId') ? undefined : json['plage12ClasseHoraireId'],
        'plage12PlageId': !exists(json, 'plage12PlageId') ? undefined : json['plage12PlageId'],
        'plage12HeureDebut': !exists(json, 'plage12HeureDebut') ? undefined : json['plage12HeureDebut'],
        'plage12MatiereId': !exists(json, 'plage12MatiereId') ? undefined : json['plage12MatiereId'],
        'plage12LocalId': !exists(json, 'plage12LocalId') ? undefined : json['plage12LocalId'],
        'plage12Rattrapage': !exists(json, 'plage12Rattrapage') ? undefined : json['plage12Rattrapage'],
        'plage12Numero': !exists(json, 'plage12Numero') ? undefined : json['plage12Numero'],
        'plage13ClasseHoraireId': !exists(json, 'plage13ClasseHoraireId') ? undefined : json['plage13ClasseHoraireId'],
        'plage13PlageId': !exists(json, 'plage13PlageId') ? undefined : json['plage13PlageId'],
        'plage13HeureDebut': !exists(json, 'plage13HeureDebut') ? undefined : json['plage13HeureDebut'],
        'plage13MatiereId': !exists(json, 'plage13MatiereId') ? undefined : json['plage13MatiereId'],
        'plage13LocalId': !exists(json, 'plage13LocalId') ? undefined : json['plage13LocalId'],
        'plage13Rattrapage': !exists(json, 'plage13Rattrapage') ? undefined : json['plage13Rattrapage'],
        'plage13Numero': !exists(json, 'plage13Numero') ? undefined : json['plage13Numero'],
        'plage14ClasseHoraireId': !exists(json, 'plage14ClasseHoraireId') ? undefined : json['plage14ClasseHoraireId'],
        'plage14PlageId': !exists(json, 'plage14PlageId') ? undefined : json['plage14PlageId'],
        'plage14HeureDebut': !exists(json, 'plage14HeureDebut') ? undefined : json['plage14HeureDebut'],
        'plage14MatiereId': !exists(json, 'plage14MatiereId') ? undefined : json['plage14MatiereId'],
        'plage14LocalId': !exists(json, 'plage14LocalId') ? undefined : json['plage14LocalId'],
        'plage14Rattrapage': !exists(json, 'plage14Rattrapage') ? undefined : json['plage14Rattrapage'],
        'plage14Numero': !exists(json, 'plage14Numero') ? undefined : json['plage14Numero'],
    };
}

export function ClassHoraireDtoToJSON(value?: ClassHoraireDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'nomJour': value.nomJour,
        'plage01ClasseHoraireId': value.plage01ClasseHoraireId,
        'plage01PlageId': value.plage01PlageId,
        'plage01HeureDebut': value.plage01HeureDebut,
        'plage01MatiereId': value.plage01MatiereId,
        'plage01LocalId': value.plage01LocalId,
        'plage01Rattrapage': value.plage01Rattrapage,
        'plage02ClasseHoraireId': value.plage02ClasseHoraireId,
        'plage02PlageId': value.plage02PlageId,
        'plage02HeureDebut': value.plage02HeureDebut,
        'plage02MatiereId': value.plage02MatiereId,
        'plage02LocalId': value.plage02LocalId,
        'plage02Rattrapage': value.plage02Rattrapage,
        'plage03ClasseHoraireId': value.plage03ClasseHoraireId,
        'plage03PlageId': value.plage03PlageId,
        'plage03HeureDebut': value.plage03HeureDebut,
        'plage03MatiereId': value.plage03MatiereId,
        'plage03LocalId': value.plage03LocalId,
        'plage03Rattrapage': value.plage03Rattrapage,
        'plage04ClasseHoraireId': value.plage04ClasseHoraireId,
        'plage04PlageId': value.plage04PlageId,
        'plage04HeureDebut': value.plage04HeureDebut,
        'plage04MatiereId': value.plage04MatiereId,
        'plage04LocalId': value.plage04LocalId,
        'plage04Rattrapage': value.plage04Rattrapage,
        'plage05ClasseHoraireId': value.plage05ClasseHoraireId,
        'plage05PlageId': value.plage05PlageId,
        'plage05HeureDebut': value.plage05HeureDebut,
        'plage05MatiereId': value.plage05MatiereId,
        'plage05LocalId': value.plage05LocalId,
        'plage05Rattrapage': value.plage05Rattrapage,
        'plage06ClasseHoraireId': value.plage06ClasseHoraireId,
        'plage06PlageId': value.plage06PlageId,
        'plage06HeureDebut': value.plage06HeureDebut,
        'plage06MatiereId': value.plage06MatiereId,
        'plage06LocalId': value.plage06LocalId,
        'plage06Rattrapage': value.plage06Rattrapage,
        'plage07ClasseHoraireId': value.plage07ClasseHoraireId,
        'plage07PlageId': value.plage07PlageId,
        'plage07HeureDebut': value.plage07HeureDebut,
        'plage07MatiereId': value.plage07MatiereId,
        'plage07LocalId': value.plage07LocalId,
        'plage07Rattrapage': value.plage07Rattrapage,
        'plage08ClasseHoraireId': value.plage08ClasseHoraireId,
        'plage08PlageId': value.plage08PlageId,
        'plage08HeureDebut': value.plage08HeureDebut,
        'plage08MatiereId': value.plage08MatiereId,
        'plage08LocalId': value.plage08LocalId,
        'plage08Rattrapage': value.plage08Rattrapage,
        'plage09ClasseHoraireId': value.plage09ClasseHoraireId,
        'plage09PlageId': value.plage09PlageId,
        'plage09HeureDebut': value.plage09HeureDebut,
        'plage09MatiereId': value.plage09MatiereId,
        'plage09LocalId': value.plage09LocalId,
        'plage09Rattrapage': value.plage09Rattrapage,
        'plage10ClasseHoraireId': value.plage10ClasseHoraireId,
        'plage10PlageId': value.plage10PlageId,
        'plage10HeureDebut': value.plage10HeureDebut,
        'plage10MatiereId': value.plage10MatiereId,
        'plage10LocalId': value.plage10LocalId,
        'plage10Rattrapage': value.plage10Rattrapage,
        'plage11ClasseHoraireId': value.plage11ClasseHoraireId,
        'plage11PlageId': value.plage11PlageId,
        'plage11HeureDebut': value.plage11HeureDebut,
        'plage11MatiereId': value.plage11MatiereId,
        'plage11LocalId': value.plage11LocalId,
        'plage11Rattrapage': value.plage11Rattrapage,
        'plage12ClasseHoraireId': value.plage12ClasseHoraireId,
        'plage12PlageId': value.plage12PlageId,
        'plage12HeureDebut': value.plage12HeureDebut,
        'plage12MatiereId': value.plage12MatiereId,
        'plage12LocalId': value.plage12LocalId,
        'plage12Rattrapage': value.plage12Rattrapage,
        'plage13ClasseHoraireId': value.plage13ClasseHoraireId,
        'plage13PlageId': value.plage13PlageId,
        'plage13HeureDebut': value.plage13HeureDebut,
        'plage13MatiereId': value.plage13MatiereId,
        'plage13LocalId': value.plage13LocalId,
        'plage13Rattrapage': value.plage13Rattrapage,
        'plage14ClasseHoraireId': value.plage14ClasseHoraireId,
        'plage14PlageId': value.plage14PlageId,
        'plage14HeureDebut': value.plage14HeureDebut,
        'plage14MatiereId': value.plage14MatiereId,
        'plage14LocalId': value.plage14LocalId,
        'plage14Rattrapage': value.plage14Rattrapage,
    };
}

