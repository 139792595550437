import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { FormikProps } from "formik";
import { FGListen, FGNumberInput, FGTextInput, FieldGroup, useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";

import { ClasseApi, ClasseMatiereApi } from "../../../../../api";
import { FcbClasseMatiereDtoFromJSON } from "../../../../../api/models/FcbClasseMatiereDto";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterCheckboxInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  margin-right: 0.5rem;
`;

export interface IClasseMatiereItemPageProps {}
export const ClasseMatiereItemPage: React.FunctionComponent<IClasseMatiereItemPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id, tab, idMatiere, state } = useParams<{ id: string; tab: string; idMatiere: string; state: string }>();
  const api = useApiService(ClasseMatiereApi);
  const classeApi = useApiService(ClasseApi);

  const { data, loading, deleteItem, deleting, saveItem, saving, saveError } = useCrudApi({
    getApiFn: () =>
      +idMatiere <= 0
        ? FcbClasseMatiereDtoFromJSON({
            idclasseMatiere: 0,
            idclasse: +id,
            code: "",
            description: "",
            idformateur: null,
            idformateurLocalisationContrat: null,
            niveau: 0
          })
        : api.classeMatiereGet({ id: +idMatiere }),
    saveApiFn: d => {
      setFirstContratChange(true);
      return api.classeMatiereSave({ FcbClasseMatiereDto: d });
    },
    deleteApiFn: d => api.classeMatiereDelete({ id: d.idclasseMatiere }),
    onDeletedRoute: () => `${ERoutes.classe}/${id}/${tab}/${state}`,
    onSavedRoute: d => `${ERoutes.classe}/${d.idclasse}/${tab}/${d.idclasseMatiere}/${state}`
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      code: Yup.string()
        .required(t(ETLCodes.Required))
        .test("is-unique", t(ETLCodes.ErrorCodeAlreadyExistsForClasse), async function(value) {
          const validationError = await api.classeMatiereCheckCodeUnique({
            code: value,
            idClasse: this.parent.idclasse,
            idClasseMatiere: this.parent.idclasseMatiere
          });
          return validationError.isValid;
        }),
      description: Yup.string().required(t(ETLCodes.Required)),
      idformateurLocalisationContrat: Yup.number()
        .nullable()
        .when("idformateur", (value, schema) =>
          value !== null && value !== undefined ? schema.required(t(ETLCodes.Required)) : schema
        ),
      categorie: Yup.number()
        .nullable()
        .typeError(t(ETLCodes.NumberErrorType)),
      section: Yup.number()
        .nullable()
        .typeError(t(ETLCodes.NumberErrorType)),
      niveau: Yup.number()
        .nullable()
        .required(t(ETLCodes.Required)),
      idmatiereParent: Yup.number()
        .nullable()
        .when("niveau", {
          is: val => val > 0,
          then: Yup.number()
            .nullable()
            .required(t(ETLCodes.Required))
        })
    });
  }, [api, t]);

  const [classe] = useApiEffect(() => classeApi.classeGet({ id: +id }), [+id]);
  const [idFormateur, setIdFormateur] = React.useState(data?.idformateur ?? 0);
  const [idformateurLocalisationContrat, setIdformateurLocalisationContrat] = React.useState(
    data?.idformateurLocalisationContrat ?? 0
  );
  const [idCentreLocal, setIdCentreLocal] = React.useState(0);
  const [niveau, setNiveau] = React.useState(0);
  const [special, setSpecial] = React.useState(false);
  const [normal, setNormal] = React.useState(false);
  const [finalErrors, setFinalErrors] = React.useState({});

  React.useEffect(() => {
    if (classe) {
      setIdCentreLocal(classe.idcentre);
      setSpecial(classe.genre === "S");
      setNormal(classe.genre === "N");
    }
  }, [classe]);

  React.useEffect(() => {
    if (saveError?.validationErrors) {
      setFinalErrors(saveError?.validationErrors);
    }
  }, [saveError?.validationErrors]);

  // useWhatChanged([validationErrors], "validationErrorsPage");

  React.useEffect(() => {
    async function validateFormateurAgrement(value: number) {
      if (!value) return true;
      const validationError = await api.classeMatiereCheckFormateurAgrementMetier({
        idFormateur: value,
        idClasse: +id
      });
      return validationError.isValid;
    }

    validateFormateurAgrement(idFormateur).then(c => {
      if (
        !c &&
        (!finalErrors["idformateur"] ||
          !finalErrors["idformateur"].includes(t(ETLCodes.ClasseMatiereSaveFormateurNotAgrement)))
      ) {
        setFinalErrors({ ...finalErrors, idformateur: [t(ETLCodes.ClasseMatiereSaveFormateurNotAgrement)] });
      } else if (
        c &&
        finalErrors["idformateur"] &&
        finalErrors["idformateur"].includes(t(ETLCodes.ClasseMatiereSaveFormateurNotAgrement))
      ) {
        const error = finalErrors["idformateur"].filter(fe => fe !== t(ETLCodes.ClasseMatiereSaveFormateurNotAgrement));
        setFinalErrors({
          ...finalErrors,
          idformateur: error?.length > 0 ? error : undefined
        });
      }
    });
  }, [api, finalErrors, id, idFormateur, t]);

  React.useEffect(() => {
    async function validateFormateurContrat(value) {
      if (!value) return true;
      const validationError = await api.classeMatiereCheckDepassementContrat({
        idFormateurLocalisationContrat: value,
        idClasseMatiere: +idMatiere
      });
      return validationError.isValid;
    }

    validateFormateurContrat(idformateurLocalisationContrat).then(c => {
      if (
        !c &&
        (!finalErrors["idformateurLocalisationContrat"] ||
          !finalErrors["idformateurLocalisationContrat"].includes(t(ETLCodes.ErrorFormateurDepassementContrat)))
      ) {
        setFinalErrors({
          ...finalErrors,
          idformateurLocalisationContrat: [t(ETLCodes.ErrorFormateurDepassementContrat)]
        });
      } else if (
        c &&
        finalErrors["idformateurLocalisationContrat"] &&
        finalErrors["idformateurLocalisationContrat"].includes(t(ETLCodes.ErrorFormateurDepassementContrat))
      ) {
        const error = finalErrors["idformateurLocalisationContrat"].filter(
          fe => fe !== t(ETLCodes.ErrorFormateurDepassementContrat)
        );
        setFinalErrors({
          ...finalErrors,
          idformateurLocalisationContrat: error?.length > 0 ? error : undefined
        });
      }
    });
  }, [api, finalErrors, id, idFormateur, idMatiere, idformateurLocalisationContrat, t]);

  const [formateurs, fLoading] = useReferential(a => a.referentialGetFormateurByIdClasse({ idClasse: +id }), true, [
    id
  ]);

  const [contrats, cLoading] = useReferential(
    a => a.referentialGetFormateurLocalisationContrat({ idformateur: idFormateur, idcentre: idCentreLocal }),
    true,
    [idFormateur, idCentreLocal]
  );
  const [locaux, lLoading] = useReferential(a => a.referentialGetLocalByCentre({ idCentre: idCentreLocal }), true, [
    idCentreLocal
  ]);
  const [typesProjet, tpLoading] = useReferential(
    a => a.referentialGetProjetFinancementByClasse({ idClasse: +id }),
    true,
    [id]
  );
  const [matieresParent, mpLoading] = useReferential(
    a => niveau > 0 && a.referentialGetClasseMatieresByNiveau({ idclasse: +id, niveau }),
    true,
    [id, niveau]
  );

  const isCut = React.useMemo(() => data?.isCut, [data]);

  const [firstContratChange, setFirstContratChange] = React.useState(true);
  const setContrat = React.useCallback((formik?: FormikProps<any>) => {
    formik.setFieldValue("idformateurLocalisationContrat", null);
    formik.setFieldTouched("idformateurLocalisationContrat");
  }, []);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      saving={saving}
      loading={loading}
      deleting={deleting}
      onDelete={deleteItem}
      onCancel={() => history.push(`${ERoutes.classe}/${id}/${tab}/${state}`)}
      editMode={state === "edit"}
      validationSchema={FormSchema}
      validationErrors={finalErrors}
    >
      <FGListen
        field="idformateur"
        onChanged={(value, formik) => {
          if (isNaN(+value)) {
            setIdFormateur(0);
          } else {
            setIdFormateur(+value ?? 0);

            if (value !== data?.idformateur) {
              setFirstContratChange(false);
              setContrat(formik);
            } else if (!firstContratChange) {
              setContrat(formik);
            }
          }
        }}
      />
      <FGListen field="idformateurLocalisationContrat" onChanged={value => setIdformateurLocalisationContrat(+value)} />
      <FGListen field="niveau" onChanged={value => setNiveau(value)} />
      <FieldGroup columns={[4, 8]}>
        <FieldGroup>
          <FGTextInput label={t(ETLCodes.Code)} name="code" readonly={!special} maxLength={10} />
          <FGTextInput label={t(ETLCodes.Libelle)} name="description" readonly={!special} maxLength={150} />
          <FGTextInput label={t(ETLCodes.Section)} name="section" readonly={!special} />
          <FGTextInput label={t(ETLCodes.NbHeure)} name="nbheures" readonly={normal} />
          <FGTextInput label={t(ETLCodes.TJ)} name="travail" readonly={!special && !isCut} />
          <FGTextInput label={t(ETLCodes.Examen)} name="examen" readonly={!special && !isCut} />
          <FGTextInput
            label={t(ETLCodes.Reussite)}
            name="pcReussite"
            readonly={!special}
            rightElement={
              <IconContainer>
                <Icon icon={IconNames.Percentage} />
              </IconContainer>
            }
          />
          <FGWalterSelectInput items={formateurs} loading={fLoading} label={t(ETLCodes.Formateur)} name="idformateur" />
          <FGWalterSelectInput
            items={contrats}
            loading={cLoading}
            label={t(ETLCodes.Contrat)}
            name="idformateurLocalisationContrat"
            requiredMark={idFormateur !== 0}
          />
          <FGWalterSelectInput items={locaux} loading={lLoading} label={t(ETLCodes.Local)} name="idlocal" />
          <FGWalterSelectInput
            items={typesProjet}
            loading={tpLoading}
            label={t(ETLCodes.TypeProjet)}
            name="idprojetFinancement"
          />
          <FGTextInput label={t(ETLCodes.Categorie)} name="categorie" readonly={!special} />
          <FGNumberInput name="niveau" label={t(ETLCodes.Niveau)} min={0} />
          <FGWalterSelectInput
            items={matieresParent}
            loading={mpLoading}
            label={t(ETLCodes.MatiereParente)}
            name="idmatiereParent"
            visible={ctx => ctx?.formik?.values?.niveau > 0}
          />
          <FGWalterCheckboxInput name="uaa" label={t(ETLCodes.UAA)} />
          <FGWalterCheckboxInput name="isVisibleBulletin" label={t(ETLCodes.VisibleBulletin)} />
          <FGWalterCheckboxInput name="sommative" label={t(ETLCodes.Sommative)} />
        </FieldGroup>
      </FieldGroup>
    </SmallFormGenerator>
  );
};
