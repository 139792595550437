import { Classes, Dialog } from "@blueprintjs/core";
import { FGListen, FieldGroup, showError } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";

import {
  FcbRemplacementSimultaneDto,
  FcbRemplacementSimultaneDtoFromJSON,
  ReferentialApi,
  RemplacementSimultaneApi
} from "../../../../../api";
import { FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import { IRemplacement } from "./RemplacementInterfaces";

const DialogStyled = styled(Dialog)`
  width: 900px;
  background: white;
`;
export interface IRemplacementSimultaneDetailPageProps {
  isOpen: boolean;
  onSave: () => void;
  onCancel: () => void;
  data: IRemplacement;
}

export const RemplacementSimultaneDetailPage: React.FunctionComponent<IRemplacementSimultaneDetailPageProps> = ({
  isOpen,
  onSave,
  onCancel,
  data
}) => {
  const { t } = useTl();
  const [saving, setSaving] = React.useState(false);
  const value = React.useMemo(
    () =>
      FcbRemplacementSimultaneDtoFromJSON({
        idremplacement: data.idremplacement,
        idremplacant: data.idremplacant,
        idformateurLocalisationContrat: data.idformateurLocalisationContrat,
        idclasseMatiere: data.idclasseMatiere
      }),
    [data]
  );

  const [remplacant, setRemplacant] = React.useState(data.idremplacant);
  const idCentre = React.useMemo(() => data?.idcentre, [data?.idcentre]);
  const idRemplacement = React.useMemo(() => data?.idremplacement, [data?.idremplacement]);

  const getFormateurFunc = React.useCallback(
    (a: ReferentialApi) =>
      idRemplacement > 0 ? a.referentialGetFormateurByRemplacement({ idRemplacement: idRemplacement }) : null,
    [idRemplacement]
  );

  const [formateur, formateurLoading] = useReferential(getFormateurFunc, true, [getFormateurFunc]);

  const getContratFormateurFunc = React.useCallback(
    (a: ReferentialApi) =>
      remplacant > 0
        ? a.referentialGetFormateurLocalisationContrat({ idformateur: remplacant, idcentre: idCentre })
        : null,
    [idCentre, remplacant]
  );

  const [contratFormateur, cfLoading] = useReferential(getContratFormateurFunc, true, [getContratFormateurFunc]);

  const api = useApiService(RemplacementSimultaneApi);
  const saveItem = React.useCallback(
    async (v: FcbRemplacementSimultaneDto) => {
      try {
        setSaving(true);
        await api.remplacementSimultaneSave({ FcbRemplacementSimultaneDto: v });
        onSave();
      } catch (error) {
        showError(t(ETLCodes.Error));
      }
      setSaving(false);
    },
    [api, onSave, t]
  );

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idformateurLocalisationContrat: Yup.number()
        .nullable()
        .when("idremplacant", (value, schema) => (value ? schema.required(t(ETLCodes.Required)) : schema))
    });
  }, [t]);

  return (
    <DialogStyled isOpen={isOpen} enforceFocus={false}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={value}
          onSubmit={saveItem}
          saving={saving}
          onCancel={onCancel}
          editMode={true}
          validationSchema={FormSchema}
        >
          <FGListen
            field="idremplacant"
            onChanged={(val, formik) => {
              setRemplacant(val);
              if (!val || val !== remplacant) {
                formik.setFieldValue("idformateurLocalisationContrat", null);
              }
            }}
          />
          <FieldGroup>
            <FGWalterSelectInput
              name="idremplacant"
              label={t(ETLCodes.Remplacant)}
              items={formateur}
              loading={formateurLoading}
            />
          </FieldGroup>
          <FieldGroup>
            <FGWalterSelectInput
              name="idformateurLocalisationContrat"
              label={t(ETLCodes.Contrat)}
              items={contratFormateur}
              loading={cfLoading}
            />
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </DialogStyled>
  );
};
