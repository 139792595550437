/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbClasseGridDto
 */
export interface FcbClasseGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbClasseGridDto
     */
    idclasse?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseGridDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseGridDto
     */
    annee?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseGridDto
     */
    lieu?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseGridDto
     */
    horaireCoherent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseGridDto
     */
    referentiel?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseGridDto
     */
    heureS1?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseGridDto
     */
    heureS2?: number;
}

/**
 * Check if a given object implements the FcbClasseGridDto interface.
 */
export function instanceOfFcbClasseGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbClasseGridDtoFromJSON(json: any): FcbClasseGridDto {
    return FcbClasseGridDtoFromJSONTyped(json, false);
}

export function FcbClasseGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbClasseGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'annee': !exists(json, 'annee') ? undefined : json['annee'],
        'lieu': !exists(json, 'lieu') ? undefined : json['lieu'],
        'horaireCoherent': !exists(json, 'horaireCoherent') ? undefined : json['horaireCoherent'],
        'referentiel': !exists(json, 'referentiel') ? undefined : json['referentiel'],
        'heureS1': !exists(json, 'heureS1') ? undefined : json['heureS1'],
        'heureS2': !exists(json, 'heureS2') ? undefined : json['heureS2'],
    };
}

export function FcbClasseGridDtoToJSON(value?: FcbClasseGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idclasse': value.idclasse,
        'code': value.code,
        'nom': value.nom,
        'annee': value.annee,
        'lieu': value.lieu,
        'horaireCoherent': value.horaireCoherent,
        'referentiel': value.referentiel,
        'heureS1': value.heureS1,
        'heureS2': value.heureS2,
    };
}

