import { Button, Checkbox, Colors, Icon, Intent, Menu, MenuItem, Popover, Spinner, Tooltip } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ButtonContainer, IDataTableColumn, showError } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import styled, { css } from "styled-components";

import { LieuFormationGrid } from ".";
import { SiegeSocialApi, SiegeSocialGridDto, SiegeSocialSearchDto } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import {
  AddButton,
  CircleColumn,
  ColumnMask,
  CustomBulletList,
  EditButton,
  EmailButton,
  SearchTablePage,
  SmsButton,
  ViewButton
} from "../../../../components";
import { useRouteParamsContext } from "../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTheme, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

const PopoverContent = styled.div`
  padding: 0.5rem;
`;

const LegendHeaderContainer = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

export interface ISiegeSocialListProps {}

export const SiegeSocialList: React.FunctionComponent<ISiegeSocialListProps> = props => {
  const { t, tUnsafe } = useTl();
  const history = useHistory();
  const api = useApiService(SiegeSocialApi);
  const [selectedIDs, setSelectedIDs] = React.useState<number[]>([]);
  const lastSearchObject = React.useRef<SiegeSocialSearchDto>();
  const { pushSmsRecipientIds, pushSmsRecipientType } = useRouteParamsContext();
  const [selectAllLoading, setSelectAllLoading] = React.useState(false);
  const [nbReturnedIds, setNbReturnedIds] = React.useState(0);
  const { theme } = useTheme();

  const apiFactory = useAbortableApiServiceFactory(SiegeSocialApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (nextSearch?: SiegeSocialSearchDto) => {
      if (JSON.stringify(nextSearch) !== JSON.stringify(lastSearchObject?.current)) {
        lastSearchObject.current = nextSearch;
      }
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.siegeSocialBaseSearch({ SiegeSocialSearchDto: nextSearch });
    },
    [apiFactory]
  );

  const selectAll = React.useCallback(() => {
    setSelectAllLoading(true);
    api
      .siegeSocialSearchSiegeSocialIds({
        SiegeSocialSearchDto: { ...lastSearchObject?.current, forceSkip: 0, forceTake: 0 }
      })
      .then(allIds => {
        if (allIds.length === selectedIDs.length) {
          setSelectedIDs([]);
          setNbReturnedIds(0);
        } else {
          setSelectedIDs(allIds);
          setNbReturnedIds(allIds.length);
        }
        setSelectAllLoading(false);
      });
  }, [api, selectedIDs.length]);

  const toggleSelection = React.useCallback(
    (item: SiegeSocialGridDto) => {
      if (!selectedIDs.includes(item.idsiegeSocial)) {
        setSelectedIDs(ids => [...ids, item.idsiegeSocial]);
      } else {
        setSelectedIDs(ids => ids.filter(id => id !== item.idsiegeSocial));
      }
    },
    [selectedIDs]
  );

  const sendEmail = React.useCallback(async () => {
    if (selectedIDs.length > 0) {
      const emails = await api.siegeSocialGetEmails({ request_body: selectedIDs });
      if (!emails) {
        showError(t(ETLCodes.NoSelectedSiegeSocialHasAnEmail));
        return;
      }
      const el = document.createElement("a");
      el.setAttribute("href", `mailto:?bcc=${emails}`);
      el.click();
    } else {
      showError(t(ETLCodes.SelectAtLeastOne));
    }
  }, [api, selectedIDs, t]);

  const sendEmailToLieux = React.useCallback(async () => {
    if (selectedIDs.length > 0) {
      const emails = await api.siegeSocialGetLieuxFormationEmails({ request_body: selectedIDs });
      if (!emails) {
        showError(t(ETLCodes.NoSelectedLieuxFormationHasAnEmail));
        return;
      }
      const el = document.createElement("a");
      el.setAttribute("href", `mailto:?bcc=${emails}`);
      el.click();
    } else {
      showError(t(ETLCodes.SelectAtLeastOne));
    }
  }, [api, selectedIDs, t]);

  const sendSms = React.useCallback(() => {
    if (selectedIDs.length > 0) {
      pushSmsRecipientIds(selectedIDs);
      // pushSmsRecipientType(ETypeDestinataireSms.SiegeSocial); TODO:
      history.push(`${ERoutes.sms}/0/detail/edit`);
    } else {
      showError(t(ETLCodes.SelectAtLeastOne));
    }
  }, [history, pushSmsRecipientIds, selectedIDs, t]);

  const sendSmsSingle = React.useCallback(
    (id: number) => {
      pushSmsRecipientIds([id]);
      // pushSmsRecipientType(ETypeDestinataireSms.SiegeSocial); TODO:
      history.push(`${ERoutes.sms}/0/detail/edit`);
    },
    [history, pushSmsRecipientIds]
  );

  const onAddSiegeSocial = React.useCallback(() => {
    history.push(`${ERoutes.siegeSocial}/-1/detail/edit`);
  }, [history]);

  const createSuivi = React.useCallback(
    (idsiegeSocial?: number) => {
      history.push(`${ERoutes.siegeSocial}/${idsiegeSocial}/suivi/edit/-1`);
    },
    [history]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        autoFitContent: true,
        computed: true,
        fieldName: "_toggle",
        render: (item: SiegeSocialGridDto, _, { subPanelOpened, toggleSubPanel }) =>
          item.hasLieuxFormations && (
            <Button
              icon={subPanelOpened ? "chevron-down" : "chevron-right"}
              onClick={() => toggleSubPanel()}
              minimal
            ></Button>
          )
      },
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        header: () =>
          selectAllLoading ? (
            <Spinner size={20} />
          ) : (
            <Checkbox checked={nbReturnedIds > 0 && nbReturnedIds === selectedIDs.length} onChange={selectAll} />
          ),
        render: (item: SiegeSocialGridDto) => (
          <Checkbox
            checked={selectedIDs.includes(item.idsiegeSocial)}
            onChange={e => {
              toggleSelection(item);
            }}
          />
        )
      },
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: SiegeSocialGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.siegeSocial}/${row.idsiegeSocial}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.siegeSocial}/${row.idsiegeSocial}/detail/edit`)}
            />
            <Button icon={IconNames.ALIGN_JUSTIFY} minimal={true} onClick={() => createSuivi(row.idsiegeSocial)} />
          </ButtonContainer>
        )
      },
      {
        autoFitContent: true,
        alignment: "center",
        header: () => (
          <LegendHeaderContainer>
            <span>{t(ETLCodes.A)}</span>
            <Tooltip
              content={
                <CustomBulletList
                  title={t(ETLCodes.AccueilApprenant)}
                  items={[
                    {
                      text: t(ETLCodes.SusceptibledAccueillirUnApprenant),
                      color: theme.sucessColor
                    },
                    {
                      text: t(ETLCodes.PasSusceptibledAccueillirUnApprenant),
                      color: theme.dangerColor
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip>
          </LegendHeaderContainer>
        ),
        fieldName: "formationCanHaveApprenants",
        render: (row: SiegeSocialGridDto) =>
          row?.formationCanHaveApprenants ? (
            <CircleColumn color={theme.sucessColor} />
          ) : (
            <CircleColumn color={theme.dangerColor} />
          )
      },
      {
        autoFitContent: true,
        header: () => t(ETLCodes.C),
        fieldName: "nbContrats"
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "denominationSociale"
      },
      {
        header: () => t(ETLCodes.Telephone),
        fieldName: "telephone"
      },
      {
        header: () => t(ETLCodes.Gsm),
        fieldName: "gsm",
        render: (value: SiegeSocialGridDto) => (
          <SmsButton phone={value.gsm} disabled onClick={() => sendSmsSingle(value.idsiegeSocial)} />
        )
      },
      {
        header: () => t(ETLCodes.Patron),
        fieldName: "chefEntreprisePrincipal"
      },
      {
        header: () => t(ETLCodes.Email),
        fieldName: "email",
        alignment: "center",
        render: (value: SiegeSocialGridDto) => <EmailButton email={value.email} />
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      },
      {
        header: () => t(ETLCodes.NumeroBce),
        fieldName: "numeroBce",
        render: (row: SiegeSocialGridDto) => (
          <ColumnMask
            value={row.numeroBce}
            cleaveOptions={{
              blocks: [4, 3, 3],
              delimiter: "."
            }}
          />
        )
      }
    ],
    [
      selectAllLoading,
      nbReturnedIds,
      selectedIDs,
      selectAll,
      toggleSelection,
      history,
      t,
      createSuivi,
      theme.sucessColor,
      theme.dangerColor,
      sendSmsSingle
    ]
  );

  const getCriteria = React.useCallback(async () => {
    let criterias = await api.siegeSocialGetSearchCriterias({ includeListsValues: true });
    criterias = criterias.map(c => {
      if (c.criteria === "StatutContrat") {
        c.listValues.forEach(v => (v.displayValue = tUnsafe("StatutContrat_" + v.displayValue)));
      }
      if (c.criteria === "StatutAgrement") {
        c.listValues.forEach(v => (v.displayValue = tUnsafe("StatutAgrement_" + v.displayValue)));
      }
      return c;
    });
    return criterias;
  }, [api, tUnsafe]);

  const buttons = React.useMemo(
    () => (
      <>
        <Popover
          position="bottom"
          content={
            <PopoverContent>
              <Menu>
                <MenuItem
                  icon={IconNames.ENVELOPE}
                  text={t(ETLCodes.SendAnEmail)}
                  intent={Intent.PRIMARY}
                  onClick={sendEmail}
                />
                <MenuItem
                  icon={IconNames.ENVELOPE}
                  text={t(ETLCodes.SendAnEmailToLieux)}
                  intent={Intent.PRIMARY}
                  onClick={sendEmailToLieux}
                />
                <MenuItem
                  icon={IconNames.CHAT}
                  text={t(ETLCodes.SendAnSMS)}
                  intent={Intent.PRIMARY}
                  onClick={sendSms}
                  disabled
                />
                <MenuItem
                  icon={IconNames.CHAT}
                  text={t(ETLCodes.SendAnSMSToLieux)}
                  intent={Intent.PRIMARY}
                  onClick={() => {}}
                  disabled
                />
                <MenuItem
                  icon={IconNames.PRINT}
                  text={t(ETLCodes.PrintFicheEntreprise)}
                  intent={Intent.PRIMARY}
                  onClick={() => {}}
                  disabled
                />
              </Menu>
            </PopoverContent>
          }
        >
          <Button
            icon={IconNames.CHEVRON_DOWN}
            text={t(ETLCodes.GlobalActions)}
            minimal={true}
            intent={Intent.PRIMARY}
          ></Button>
        </Popover>
        <AddButton
          onClick={e => {
            e.stopPropagation();
            onAddSiegeSocial();
          }}
          text={t(ETLCodes.AddSiegeSocial)}
          intent={Intent.PRIMARY}
        />
      </>
    ),
    [onAddSiegeSocial, sendEmail, sendEmailToLieux, sendSms, t]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteria}
      searchFunction={search}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.SiegeSociauxLieuxFormation), route: ERoutes.siegeSocial }]}
      sortKeys={{ denominationSociale: "ASC" }}
      searchStateInitialSearch={false}
      rightElement={buttons}
      customizeRowStyle={(row: SiegeSocialGridDto) => css`
        & * {
          color: ${!row.actif ? Colors.RED3 + " !important" : null};
        }
      `}
      keyFieldName="idsiegeSocial"
      onOpenSubComponent={(item: SiegeSocialGridDto) => <LieuFormationGrid siegeSocial={item}></LieuFormationGrid>}
    />
  );
};
