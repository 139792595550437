/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbInscriptionGridDto
 */
export interface FcbInscriptionGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionGridDto
     */
    codeFormation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionGridDto
     */
    statut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionGridDto
     */
    contrat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionGridDto
     */
    codeClasse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionGridDto
     */
    nomClasse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionGridDto
     */
    codeInscription?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionGridDto
     */
    idinscription?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionGridDto
     */
    idclasse?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionGridDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbInscriptionGridDto
     */
    dateInscription?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionGridDto
     */
    idapprenant?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FcbInscriptionGridDto
     */
    idssocieteExterne?: Array<number> | null;
}

/**
 * Check if a given object implements the FcbInscriptionGridDto interface.
 */
export function instanceOfFcbInscriptionGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbInscriptionGridDtoFromJSON(json: any): FcbInscriptionGridDto {
    return FcbInscriptionGridDtoFromJSONTyped(json, false);
}

export function FcbInscriptionGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbInscriptionGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'codeFormation': !exists(json, 'codeFormation') ? undefined : json['codeFormation'],
        'statut': !exists(json, 'statut') ? undefined : json['statut'],
        'contrat': !exists(json, 'contrat') ? undefined : json['contrat'],
        'codeClasse': !exists(json, 'codeClasse') ? undefined : json['codeClasse'],
        'nomClasse': !exists(json, 'nomClasse') ? undefined : json['nomClasse'],
        'codeInscription': !exists(json, 'codeInscription') ? undefined : json['codeInscription'],
        'idinscription': !exists(json, 'idinscription') ? undefined : json['idinscription'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'dateInscription': !exists(json, 'dateInscription') ? undefined : (json['dateInscription'] === null ? null : new Date(json['dateInscription'])),
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idssocieteExterne': !exists(json, 'idssocieteExterne') ? undefined : json['idssocieteExterne'],
    };
}

export function FcbInscriptionGridDtoToJSON(value?: FcbInscriptionGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'codeFormation': value.codeFormation,
        'statut': value.statut,
        'contrat': value.contrat,
        'codeClasse': value.codeClasse,
        'nomClasse': value.nomClasse,
        'codeInscription': value.codeInscription,
        'idinscription': value.idinscription,
        'idclasse': value.idclasse,
        'anneeScolaire': value.anneeScolaire,
        'dateInscription': value.dateInscription === undefined ? undefined : (value.dateInscription === null ? null : value.dateInscription.toISOString()),
        'idapprenant': value.idapprenant,
        'idssocieteExterne': value.idssocieteExterne,
    };
}

