import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { UniteActiviteApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { UniteActiviteDetailPage } from "./UniteActiviteDetailPage";

export interface IUniteActiviteItemPageProps {}

export const UniteActiviteItemPage: React.FunctionComponent<IUniteActiviteItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const Api = useApiService(UniteActiviteApi);
  const [displayName] = useApiEffect(() => Api.uniteActiviteGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.UniteActivite), route: ERoutes.uniteActivite },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsUniteActivite"
        onChange={newTabId => history.push(`${ERoutes.uniteActivite}/${id}/${newTabId}/${state}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.uniteActivite}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<UniteActiviteDetailPage idUnite={+id} editMode={state === "edit"} />}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
