import { FGTextInput, FieldGroup, FieldSet, useApiEffect } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import {
  ChangementClasseApi,
  FcbChangementClasseDto,
  FcbChangementClasseDtoFromJSON,
  InscriptionApi
} from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import { FGWalterDateMaskInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../../../components";
import { useDialog } from "../../../../../../contexts";
import { useApiService, useCrudApi, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

export interface IChangementClasseDetailProps {}

export const ChangementClasseDetail: React.FunctionComponent<IChangementClasseDetailProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { showDialogPromise } = useDialog();
  const { id, inscriptionId, changementClasseId, stateChangementClasse = "view" } = useParams<{
    id: string;
    inscriptionId: string;
    tabInscription: string;
    stateInscription: string;
    changementClasseId: string;
    stateChangementClasse: string;
  }>();

  const idApprenant = React.useMemo(() => +id, [id]);
  const idInscription = React.useMemo(() => +inscriptionId, [inscriptionId]);
  const idChangementClasse = React.useMemo(() => +changementClasseId, [changementClasseId]);

  const api = useApiService(ChangementClasseApi);
  const inscriptionApi = useApiService(InscriptionApi);

  const [hasDispense] = useApiEffect(() => inscriptionApi.inscriptionHasDispense({ idInscription }), [idInscription]);

  const deleteChangementClasse = React.useCallback(
    async idCC => {
      const hasEvals = await api.changementClasseCheckHasEvalsToDelete({ idChangementClasse: idCC });
      if (hasEvals) {
        const result = await showDialogPromise({
          message: t(ETLCodes.ChangementClasseHasEvaluationsToDelete),
          title: t(ETLCodes.ChangementClasseHasEvaluationsToDeleteTitle)
        });
        if (result === "yes") {
          await api.changementClasseDelete({ id: idCC });
          return true;
        }
      } else {
        await api.changementClasseDelete({ id: idCC });
        return true;
      }
      return false;
    },
    [api, showDialogPromise, t]
  );

  const saveChangementClasse = React.useCallback(
    async (dto: FcbChangementClasseDto) => {
      if (hasDispense?.value) {
        const result = await showDialogPromise({
          message: t(ETLCodes.InscriptionHasDispensesMessage),
          title: t(ETLCodes.InscriptionHasDispensesTitle)
        });
        if (result === "yes") {
          return api.changementClasseSave({ FcbChangementClasseDto: dto });
        }
      } else {
        return api.changementClasseSave({ FcbChangementClasseDto: dto });
      }
    },
    [api, hasDispense, showDialogPromise, t]
  );

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idChangementClasse <= 0
        ? FcbChangementClasseDtoFromJSON({ idchangementClasse: 0, idinscription: idInscription })
        : api.changementClasseGet({ id: +idChangementClasse }),
    saveApiFn: saveChangementClasse,
    onSavedRoute: d => `${ERoutes.apprenant}/${idApprenant}/inscription/${idInscription}/changement`,
    deleteApiFn: d => deleteChangementClasse(d.idchangementClasse),
    onDeletedRoute: d => `${ERoutes.apprenant}/${idApprenant}/inscription/${idInscription}/changement`
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      classecible: Yup.number().required(t(ETLCodes.Required)),
      datechangement: Yup.mixed().required(t(ETLCodes.Required))
    });
  }, [t]);

  const [classe, cLoading] = useReferential(
    a => a.referentialGetClasseByInscription({ idInscription, isForChangementClasseTable: idChangementClasse > 0 }),
    true,
    [],
    false
  );

  const { data: inscription, isFetching: loadingInscription } = useQuery(
    ["inscription", idInscription],
    async () => await inscriptionApi.inscriptionGet({ id: idInscription })
  );
  const { data: otherInscriptionForClasseExists, isFetching: otherInscriptionForClasseExistsLoading } = useQuery(
    ["other-insc-classse-exists", idInscription, data?.classesource],
    async () =>
      !!idInscription &&
      data?.classesource &&
      (await api.changementClasseOtherInscriptionForClasseExists({
        idinscription: idInscription,
        idclasse: data?.classesource
      }))
  );
  const showDelete = React.useMemo(
    () =>
      +idChangementClasse > 0 && data?.classecible === inscription?.idclasse && !otherInscriptionForClasseExists?.value,
    [data?.classecible, idChangementClasse, inscription?.idclasse, otherInscriptionForClasseExists]
  );

  const editable = React.useMemo(() => +idChangementClasse <= 0, [idChangementClasse]);

  return (
    <FieldSet title={t(ETLCodes.ChangementClasse)}>
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={stateChangementClasse === "edit"}
        validationSchema={FormSchema}
        loading={loading || loadingInscription || otherInscriptionForClasseExistsLoading}
        onCancel={() => history.push(`${ERoutes.apprenant}/${idApprenant}/inscription/${idInscription}/changement`)}
        onDelete={deleteItem}
        showDeleteButton={showDelete}
        saving={saving}
        deleting={deleting}
        formatDate="dd-MM-yyyy"
        minLabelWidth={200}
      >
        <FieldGroup columns={[8, 4]}>
          <FieldGroup>
            {editable ? (
              <FGWalterSelectInput name="classecible" label={t(ETLCodes.Classe)} items={classe} loading={cLoading} />
            ) : (
              <FGTextInput name="classecibleName" label={t(ETLCodes.Classe)} readonly />
            )}
            <FGWalterDateMaskInput name="datechangement" label={t(ETLCodes.DateDemande)} readonly={!editable} />
          </FieldGroup>
        </FieldGroup>
      </SmallFormGenerator>
    </FieldSet>
  );
};
