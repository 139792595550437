import { Button, ButtonGroup, Classes, Dialog, Icon } from "@blueprintjs/core";
import { IDataTableColumn } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { EBooleanSearchTypes, EcoleApi, EcoleSearch, FcbEcoleGridDto } from "../../../../../api";
import { SearchTablePage } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IEcoleSelectModalProps {
  isOpen: boolean;
  onClose: (id?: number) => void;
  alreadySelectedId?: number;
}

const DialogStyled = styled(Dialog)`
  width: 1500px;
  background: white;
`;

export const EcoleSelectModal: React.FunctionComponent<IEcoleSelectModalProps> = ({
  isOpen,
  onClose,
  alreadySelectedId
}) => {
  const { t } = useTl();
  const api = useApiService(EcoleApi);

  const apiFactory = useAbortableApiServiceFactory(EcoleApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    async (nextSearch?: EcoleSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return await abortableApi.ecoleBaseSearch({ EcoleSearch: nextSearch });
    },
    [apiFactory]
  );

  const [selected, setSelected] = React.useState<number>(alreadySelectedId ?? null);

  const toggleSelection = React.useCallback(
    (id: number) => {
      if (selected !== id) {
        setSelected(id);
      } else {
        setSelected(null);
      }
    },
    [selected]
  );

  const onRowClick = React.useCallback(
    (item: FcbEcoleGridDto) => {
      toggleSelection(item.idecole);
    },
    [toggleSelection]
  );

  const columns = React.useMemo<IDataTableColumn[]>(() => {
    return [
      {
        fieldName: "_action",
        autoFitContent: true,
        computed: true,
        alignment: "center",
        render: (item: FcbEcoleGridDto) => (
          <Icon icon={selected === item.idecole ? "tick-circle" : "circle"} style={{ cursor: "pointer" }} />
        )
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Adresse),
        fieldName: "adresse"
      },
      {
        header: () => t(ETLCodes.CodePostal),
        fieldName: "codePostal",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      },
      {
        header: () => t(ETLCodes.Pays),
        fieldName: "nomPays"
      },
      {
        header: () => t(ETLCodes.Tel),
        fieldName: "telephone"
      },
      {
        header: () => t(ETLCodes.Fax),
        fieldName: "fax"
      }
    ];
  }, [selected, t]);

  const onSave = React.useCallback(async () => {
    onClose(selected);
    setSelected(null);
  }, [onClose, selected]);

  const close = React.useCallback(() => {
    onClose(alreadySelectedId);
    setSelected(alreadySelectedId ?? null);
  }, [alreadySelectedId, onClose]);

  const getCriteria = React.useCallback(() => api.ecoleGetSearchCriterias({ includeListsValues: true }), [api]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <DialogStyled
      isOpen={isOpen}
      isCloseButtonShown={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      title={t(ETLCodes.SelectionSchool)}
      onClose={close}
      enforceFocus={false}
    >
      <div className={Classes.DIALOG_BODY}>
        <SearchTablePage
          columns={columns}
          getCriteriasFunction={getCriteria}
          searchFunction={searchFn}
          onAbort={onAbort}
          defaultCriterias={[
            {
              criteria: "Actif",
              searchMode: EBooleanSearchTypes.Equals,
              value: true
            }
          ]}
          sortKeys={{ nom: "ASC" }}
          avoidSavingCriterias={true}
          withCard={false}
          onRowClick={onRowClick}
          criteriasTlPrefix="EcoleSearchCriterias"
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <ButtonGroup minimal large fill>
            <Button text={t(ETLCodes.Cancel)} onClick={close} icon="cross"></Button>
            <div style={{ flex: 1 }}></div>
            <Button
              text={t(ETLCodes.SelectSchool)}
              icon="floppy-disk"
              onClick={onSave}
              intent="primary"
              disabled={selected === null}
            ></Button>
          </ButtonGroup>
        </div>
      </div>
    </DialogStyled>
  );
};
