/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  ChangeStatutMultipleDto,
  DemandeInscriptionCreateDto,
  DemandeInscriptionEditDto,
  DemandeInscriptionFichierDto,
  DemandeInscriptionFichierUploadDto,
  DemandeInscriptionGridDtoPaginatedResults,
  FileDownloadDto,
  FilterCriteriaInfo,
  ParcoursFormationDto,
  ParcoursFormationEditDto,
  ParcoursFormationGridDtoPaginatedResults,
  ParcoursFormationSearch,
  ParcoursInterneSearch,
  ReferentialItemDto,
  StatutDemandeCodeIdDto,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    ChangeStatutMultipleDtoFromJSON,
    ChangeStatutMultipleDtoToJSON,
    DemandeInscriptionCreateDtoFromJSON,
    DemandeInscriptionCreateDtoToJSON,
    DemandeInscriptionEditDtoFromJSON,
    DemandeInscriptionEditDtoToJSON,
    DemandeInscriptionFichierDtoFromJSON,
    DemandeInscriptionFichierDtoToJSON,
    DemandeInscriptionFichierUploadDtoFromJSON,
    DemandeInscriptionFichierUploadDtoToJSON,
    DemandeInscriptionGridDtoPaginatedResultsFromJSON,
    DemandeInscriptionGridDtoPaginatedResultsToJSON,
    FileDownloadDtoFromJSON,
    FileDownloadDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ParcoursFormationDtoFromJSON,
    ParcoursFormationDtoToJSON,
    ParcoursFormationEditDtoFromJSON,
    ParcoursFormationEditDtoToJSON,
    ParcoursFormationGridDtoPaginatedResultsFromJSON,
    ParcoursFormationGridDtoPaginatedResultsToJSON,
    ParcoursFormationSearchFromJSON,
    ParcoursFormationSearchToJSON,
    ParcoursInterneSearchFromJSON,
    ParcoursInterneSearchToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
    StatutDemandeCodeIdDtoFromJSON,
    StatutDemandeCodeIdDtoToJSON,
} from '../models/index';

export interface ParcoursFormationBaseSearchRequest {
    ParcoursFormationSearch?: ParcoursFormationSearch;
}

export interface ParcoursFormationCanAddParcoursRequest {
    idApprenant?: number;
    idParcoursFormation?: number;
}

export interface ParcoursFormationCancelDemandeInscriptionRequest {
    idDemande?: number;
}

export interface ParcoursFormationChangeStatutMultipleDemandeRequest {
    ChangeStatutMultipleDto?: ChangeStatutMultipleDto;
}

export interface ParcoursFormationCreateDemandeInscriptionMultipleRequest {
    DemandeInscriptionCreateDto?: DemandeInscriptionCreateDto;
}

export interface ParcoursFormationDeleteDemandeInscriptionFichierRequest {
    id?: number;
}

export interface ParcoursFormationDownloadDemandeInscriptionFichierRequest {
    id?: number;
}

export interface ParcoursFormationGetDemandeInscriptionRequest {
    idDemande?: number;
}

export interface ParcoursFormationGetDemandeInscriptionFichierRequest {
    id?: number;
}

export interface ParcoursFormationGetParcoursFormationRequest {
    idApprenant?: number;
    idParcoursFormation?: number;
}

export interface ParcoursFormationGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ParcoursFormationGetSelectItemsRequest {
    searchField: string;
}

export interface ParcoursFormationSaveDemandeInscriptionRequest {
    DemandeInscriptionEditDto?: DemandeInscriptionEditDto;
}

export interface ParcoursFormationSaveDemandeInscriptionFichierRequest {
    DemandeInscriptionFichierDto?: DemandeInscriptionFichierDto;
}

export interface ParcoursFormationSaveParcoursFormationRequest {
    ParcoursFormationEditDto?: ParcoursFormationEditDto;
}

export interface ParcoursFormationSearchParcoursInterneRequest {
    ParcoursInterneSearch?: ParcoursInterneSearch;
}

export interface ParcoursFormationUploadDemandeInscriptionFichierRequest {
    DemandeInscriptionFichierUploadDto?: DemandeInscriptionFichierUploadDto;
}

/**
 * 
 */
export class ParcoursFormationApi extends runtime.BaseAPI {

    /**
     */
    async parcoursFormationBaseSearchRaw(requestParameters: ParcoursFormationBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParcoursFormationGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursFormation/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ParcoursFormationSearchToJSON(requestParameters.ParcoursFormationSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParcoursFormationGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async parcoursFormationBaseSearch(requestParameters: ParcoursFormationBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParcoursFormationGridDtoPaginatedResults> {
        const response = await this.parcoursFormationBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async parcoursFormationCanAddParcoursRaw(requestParameters: ParcoursFormationCanAddParcoursRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idApprenant !== undefined) {
            queryParameters['idApprenant'] = requestParameters.idApprenant;
        }

        if (requestParameters.idParcoursFormation !== undefined) {
            queryParameters['idParcoursFormation'] = requestParameters.idParcoursFormation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursFormation/CanAddParcours`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async parcoursFormationCanAddParcours(requestParameters: ParcoursFormationCanAddParcoursRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.parcoursFormationCanAddParcoursRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async parcoursFormationCancelDemandeInscriptionRaw(requestParameters: ParcoursFormationCancelDemandeInscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idDemande !== undefined) {
            queryParameters['idDemande'] = requestParameters.idDemande;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursFormation/CancelDemandeInscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async parcoursFormationCancelDemandeInscription(requestParameters: ParcoursFormationCancelDemandeInscriptionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.parcoursFormationCancelDemandeInscriptionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async parcoursFormationChangeStatutMultipleDemandeRaw(requestParameters: ParcoursFormationChangeStatutMultipleDemandeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursFormation/ChangeStatutMultipleDemande`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeStatutMultipleDtoToJSON(requestParameters.ChangeStatutMultipleDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async parcoursFormationChangeStatutMultipleDemande(requestParameters: ParcoursFormationChangeStatutMultipleDemandeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.parcoursFormationChangeStatutMultipleDemandeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async parcoursFormationCreateDemandeInscriptionMultipleRaw(requestParameters: ParcoursFormationCreateDemandeInscriptionMultipleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DemandeInscriptionCreateDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursFormation/CreateDemandeInscriptionMultiple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DemandeInscriptionCreateDtoToJSON(requestParameters.DemandeInscriptionCreateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DemandeInscriptionCreateDtoFromJSON(jsonValue));
    }

    /**
     */
    async parcoursFormationCreateDemandeInscriptionMultiple(requestParameters: ParcoursFormationCreateDemandeInscriptionMultipleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DemandeInscriptionCreateDto> {
        const response = await this.parcoursFormationCreateDemandeInscriptionMultipleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async parcoursFormationDeleteDemandeInscriptionFichierRaw(requestParameters: ParcoursFormationDeleteDemandeInscriptionFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursFormation/DeleteDemandeInscriptionFichier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async parcoursFormationDeleteDemandeInscriptionFichier(requestParameters: ParcoursFormationDeleteDemandeInscriptionFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.parcoursFormationDeleteDemandeInscriptionFichierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async parcoursFormationDownloadDemandeInscriptionFichierRaw(requestParameters: ParcoursFormationDownloadDemandeInscriptionFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursFormation/DownloadDemandeInscriptionFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async parcoursFormationDownloadDemandeInscriptionFichier(requestParameters: ParcoursFormationDownloadDemandeInscriptionFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.parcoursFormationDownloadDemandeInscriptionFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async parcoursFormationGetDemandeInscriptionRaw(requestParameters: ParcoursFormationGetDemandeInscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DemandeInscriptionEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.idDemande !== undefined) {
            queryParameters['idDemande'] = requestParameters.idDemande;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursFormation/GetDemandeInscription`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DemandeInscriptionEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async parcoursFormationGetDemandeInscription(requestParameters: ParcoursFormationGetDemandeInscriptionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DemandeInscriptionEditDto> {
        const response = await this.parcoursFormationGetDemandeInscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async parcoursFormationGetDemandeInscriptionFichierRaw(requestParameters: ParcoursFormationGetDemandeInscriptionFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DemandeInscriptionFichierDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursFormation/GetDemandeInscriptionFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DemandeInscriptionFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async parcoursFormationGetDemandeInscriptionFichier(requestParameters: ParcoursFormationGetDemandeInscriptionFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DemandeInscriptionFichierDto> {
        const response = await this.parcoursFormationGetDemandeInscriptionFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async parcoursFormationGetParcoursFormationRaw(requestParameters: ParcoursFormationGetParcoursFormationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParcoursFormationDto>> {
        const queryParameters: any = {};

        if (requestParameters.idApprenant !== undefined) {
            queryParameters['idApprenant'] = requestParameters.idApprenant;
        }

        if (requestParameters.idParcoursFormation !== undefined) {
            queryParameters['idParcoursFormation'] = requestParameters.idParcoursFormation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursFormation/GetParcoursFormation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParcoursFormationDtoFromJSON(jsonValue));
    }

    /**
     */
    async parcoursFormationGetParcoursFormation(requestParameters: ParcoursFormationGetParcoursFormationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParcoursFormationDto> {
        const response = await this.parcoursFormationGetParcoursFormationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async parcoursFormationGetSearchCriteriasRaw(requestParameters: ParcoursFormationGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursFormation/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async parcoursFormationGetSearchCriterias(requestParameters: ParcoursFormationGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.parcoursFormationGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async parcoursFormationGetSelectItemsRaw(requestParameters: ParcoursFormationGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling parcoursFormationGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursFormation/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async parcoursFormationGetSelectItems(requestParameters: ParcoursFormationGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.parcoursFormationGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async parcoursFormationGetStatutDemandeCodeIdRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StatutDemandeCodeIdDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursFormation/GetStatutDemandeCodeId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StatutDemandeCodeIdDtoFromJSON));
    }

    /**
     */
    async parcoursFormationGetStatutDemandeCodeId(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StatutDemandeCodeIdDto>> {
        const response = await this.parcoursFormationGetStatutDemandeCodeIdRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async parcoursFormationSaveDemandeInscriptionRaw(requestParameters: ParcoursFormationSaveDemandeInscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DemandeInscriptionEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursFormation/SaveDemandeInscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DemandeInscriptionEditDtoToJSON(requestParameters.DemandeInscriptionEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DemandeInscriptionEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async parcoursFormationSaveDemandeInscription(requestParameters: ParcoursFormationSaveDemandeInscriptionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DemandeInscriptionEditDto> {
        const response = await this.parcoursFormationSaveDemandeInscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async parcoursFormationSaveDemandeInscriptionFichierRaw(requestParameters: ParcoursFormationSaveDemandeInscriptionFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DemandeInscriptionFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursFormation/SaveDemandeInscriptionFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DemandeInscriptionFichierDtoToJSON(requestParameters.DemandeInscriptionFichierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DemandeInscriptionFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async parcoursFormationSaveDemandeInscriptionFichier(requestParameters: ParcoursFormationSaveDemandeInscriptionFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DemandeInscriptionFichierDto> {
        const response = await this.parcoursFormationSaveDemandeInscriptionFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async parcoursFormationSaveParcoursFormationRaw(requestParameters: ParcoursFormationSaveParcoursFormationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParcoursFormationEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursFormation/SaveParcoursFormation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ParcoursFormationEditDtoToJSON(requestParameters.ParcoursFormationEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParcoursFormationEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async parcoursFormationSaveParcoursFormation(requestParameters: ParcoursFormationSaveParcoursFormationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParcoursFormationEditDto> {
        const response = await this.parcoursFormationSaveParcoursFormationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async parcoursFormationSearchParcoursInterneRaw(requestParameters: ParcoursFormationSearchParcoursInterneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DemandeInscriptionGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursFormation/SearchParcoursInterne`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ParcoursInterneSearchToJSON(requestParameters.ParcoursInterneSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DemandeInscriptionGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async parcoursFormationSearchParcoursInterne(requestParameters: ParcoursFormationSearchParcoursInterneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DemandeInscriptionGridDtoPaginatedResults> {
        const response = await this.parcoursFormationSearchParcoursInterneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async parcoursFormationUploadDemandeInscriptionFichierRaw(requestParameters: ParcoursFormationUploadDemandeInscriptionFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DemandeInscriptionFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursFormation/UploadDemandeInscriptionFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DemandeInscriptionFichierUploadDtoToJSON(requestParameters.DemandeInscriptionFichierUploadDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DemandeInscriptionFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async parcoursFormationUploadDemandeInscriptionFichier(requestParameters: ParcoursFormationUploadDemandeInscriptionFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DemandeInscriptionFichierDto> {
        const response = await this.parcoursFormationUploadDemandeInscriptionFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
