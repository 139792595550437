import { FGEmpty, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { FcbTitrePedagogiqueDtoFromJSON, TitrePedagogiqueApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { SmallFormGenerator, FGWalterCheckboxInput } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

interface ITitrePedagogiqueDetailPageProps {
  idTitre: number;
  editMode: boolean;
}

export const TitrePedagogiqueDetailPage: React.FunctionComponent<ITitrePedagogiqueDetailPageProps> = ({
  idTitre,
  editMode
}) => {
  const { t } = useTl();
  const api = useApiService(TitrePedagogiqueApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idTitre <= 0 ? FcbTitrePedagogiqueDtoFromJSON({ idTitre: 0 }) : api.titrePedagogiqueGet({ id: +idTitre }),
    saveApiFn: d => api.titrePedagogiqueSave({ FcbTitrePedagogiqueDto: d }),
    onSavedRoute: d => `${ERoutes.titrePedagogique}/${d.idtitre}/detail/edit`,
    deleteApiFn: d => api.titrePedagogiqueDelete({ id: d.idtitre }),
    onDeletedRoute: () => ERoutes.titrePedagogique
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      libelle: Yup.string()
        .required(t(ETLCodes.Required))
        .max(75)
        .test("libelle-not-exists", t(ETLCodes.CodeExistsError), async value => {
          if (value === data.libelle) return true;
          const validationError = await api.titrePedagogiqueExists({ libelle: value });
          return validationError.isValid;
        })
    });
  }, [t, data, api]);

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={editMode}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(ERoutes.titrePedagogique)}
        onDelete={deleteItem}
        showDeleteButton={+idTitre > 0}
        saving={saving}
        deleting={deleting}
      >
        <FieldGroup columns={2}>
          <FGTextInput name="libelle" label={t(ETLCodes.Libelle)} maxLength={75} />
          <FGEmpty />
          <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
