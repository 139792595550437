/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ETypeDestinataireSms } from './ETypeDestinataireSms';
import {
    ETypeDestinataireSmsFromJSON,
    ETypeDestinataireSmsFromJSONTyped,
    ETypeDestinataireSmsToJSON,
} from './ETypeDestinataireSms';

/**
 * 
 * @export
 * @interface FcbSmsUtilitaireDetailDto
 */
export interface FcbSmsUtilitaireDetailDto {
    /**
     * 
     * @type {string}
     * @memberof FcbSmsUtilitaireDetailDto
     */
    creationUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbSmsUtilitaireDetailDto
     */
    modificationUserName?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FcbSmsUtilitaireDetailDto
     */
    recipientIds?: Array<number> | null;
    /**
     * 
     * @type {ETypeDestinataireSms}
     * @memberof FcbSmsUtilitaireDetailDto
     */
    typeRecipient?: ETypeDestinataireSms;
    /**
     * 
     * @type {number}
     * @memberof FcbSmsUtilitaireDetailDto
     */
    idlot?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbSmsUtilitaireDetailDto
     */
    idcentre?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbSmsUtilitaireDetailDto
     */
    idetat?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbSmsUtilitaireDetailDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbSmsUtilitaireDetailDto
     */
    message?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbSmsUtilitaireDetailDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbSmsUtilitaireDetailDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbSmsUtilitaireDetailDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbSmsUtilitaireDetailDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbSmsUtilitaireDetailDto
     */
    dateEnvoi?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbSmsUtilitaireDetailDto
     */
    idtype?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbSmsUtilitaireDetailDto
     */
    coutsms?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbSmsUtilitaireDetailDto
     */
    nbDestinataires?: number;
}

/**
 * Check if a given object implements the FcbSmsUtilitaireDetailDto interface.
 */
export function instanceOfFcbSmsUtilitaireDetailDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbSmsUtilitaireDetailDtoFromJSON(json: any): FcbSmsUtilitaireDetailDto {
    return FcbSmsUtilitaireDetailDtoFromJSONTyped(json, false);
}

export function FcbSmsUtilitaireDetailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbSmsUtilitaireDetailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creationUserName': !exists(json, 'creationUserName') ? undefined : json['creationUserName'],
        'modificationUserName': !exists(json, 'modificationUserName') ? undefined : json['modificationUserName'],
        'recipientIds': !exists(json, 'recipientIds') ? undefined : json['recipientIds'],
        'typeRecipient': !exists(json, 'typeRecipient') ? undefined : ETypeDestinataireSmsFromJSON(json['typeRecipient']),
        'idlot': !exists(json, 'idlot') ? undefined : json['idlot'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'idetat': !exists(json, 'idetat') ? undefined : json['idetat'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'dateEnvoi': !exists(json, 'dateEnvoi') ? undefined : (json['dateEnvoi'] === null ? null : new Date(json['dateEnvoi'])),
        'idtype': !exists(json, 'idtype') ? undefined : json['idtype'],
        'coutsms': !exists(json, 'coutsms') ? undefined : json['coutsms'],
        'nbDestinataires': !exists(json, 'nbDestinataires') ? undefined : json['nbDestinataires'],
    };
}

export function FcbSmsUtilitaireDetailDtoToJSON(value?: FcbSmsUtilitaireDetailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creationUserName': value.creationUserName,
        'modificationUserName': value.modificationUserName,
        'recipientIds': value.recipientIds,
        'typeRecipient': ETypeDestinataireSmsToJSON(value.typeRecipient),
        'idlot': value.idlot,
        'idcentre': value.idcentre,
        'idetat': value.idetat,
        'description': value.description,
        'message': value.message,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'creationUser': value.creationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'dateEnvoi': value.dateEnvoi === undefined ? undefined : (value.dateEnvoi === null ? null : value.dateEnvoi.toISOString()),
        'idtype': value.idtype,
        'coutsms': value.coutsms,
        'nbDestinataires': value.nbDestinataires,
    };
}

