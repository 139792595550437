/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbHoraireGridDto
 */
export interface FcbHoraireGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbHoraireGridDto
     */
    idClasse?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbHoraireGridDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbHoraireGridDto
     */
    jourClasse?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbHoraireGridDto
     */
    classe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbHoraireGridDto
     */
    nomClasse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbHoraireGridDto
     */
    heureDebut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbHoraireGridDto
     */
    matiere?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbHoraireGridDto
     */
    local?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbHoraireGridDto
     */
    formateur?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbHoraireGridDto
     */
    visite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FcbHoraireGridDto
     */
    matiereCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbHoraireGridDto
     */
    matiereDescription?: string | null;
}

/**
 * Check if a given object implements the FcbHoraireGridDto interface.
 */
export function instanceOfFcbHoraireGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbHoraireGridDtoFromJSON(json: any): FcbHoraireGridDto {
    return FcbHoraireGridDtoFromJSONTyped(json, false);
}

export function FcbHoraireGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbHoraireGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idClasse': !exists(json, 'idClasse') ? undefined : json['idClasse'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'jourClasse': !exists(json, 'jourClasse') ? undefined : (json['jourClasse'] === null ? null : new Date(json['jourClasse'])),
        'classe': !exists(json, 'classe') ? undefined : json['classe'],
        'nomClasse': !exists(json, 'nomClasse') ? undefined : json['nomClasse'],
        'heureDebut': !exists(json, 'heureDebut') ? undefined : json['heureDebut'],
        'matiere': !exists(json, 'matiere') ? undefined : json['matiere'],
        'local': !exists(json, 'local') ? undefined : json['local'],
        'formateur': !exists(json, 'formateur') ? undefined : json['formateur'],
        'visite': !exists(json, 'visite') ? undefined : json['visite'],
        'matiereCode': !exists(json, 'matiereCode') ? undefined : json['matiereCode'],
        'matiereDescription': !exists(json, 'matiereDescription') ? undefined : json['matiereDescription'],
    };
}

export function FcbHoraireGridDtoToJSON(value?: FcbHoraireGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idClasse': value.idClasse,
        'anneeScolaire': value.anneeScolaire,
        'jourClasse': value.jourClasse === undefined ? undefined : (value.jourClasse === null ? null : value.jourClasse.toISOString()),
        'classe': value.classe,
        'nomClasse': value.nomClasse,
        'heureDebut': value.heureDebut,
        'matiere': value.matiere,
        'local': value.local,
        'formateur': value.formateur,
        'visite': value.visite,
        'matiereCode': value.matiereCode,
        'matiereDescription': value.matiereDescription,
    };
}

