/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ETypeCarteIdentiteUser } from './ETypeCarteIdentiteUser';
import {
    ETypeCarteIdentiteUserFromJSON,
    ETypeCarteIdentiteUserFromJSONTyped,
    ETypeCarteIdentiteUserToJSON,
} from './ETypeCarteIdentiteUser';

/**
 * 
 * @export
 * @interface CarteIdentiteExistingUserDto
 */
export interface CarteIdentiteExistingUserDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof CarteIdentiteExistingUserDto
     */
    existingIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CarteIdentiteExistingUserDto
     */
    existingPersonneIds?: Array<number> | null;
    /**
     * 
     * @type {ETypeCarteIdentiteUser}
     * @memberof CarteIdentiteExistingUserDto
     */
    typeUser?: ETypeCarteIdentiteUser;
}

/**
 * Check if a given object implements the CarteIdentiteExistingUserDto interface.
 */
export function instanceOfCarteIdentiteExistingUserDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CarteIdentiteExistingUserDtoFromJSON(json: any): CarteIdentiteExistingUserDto {
    return CarteIdentiteExistingUserDtoFromJSONTyped(json, false);
}

export function CarteIdentiteExistingUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarteIdentiteExistingUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'existingIds': !exists(json, 'existingIds') ? undefined : json['existingIds'],
        'existingPersonneIds': !exists(json, 'existingPersonneIds') ? undefined : json['existingPersonneIds'],
        'typeUser': !exists(json, 'typeUser') ? undefined : ETypeCarteIdentiteUserFromJSON(json['typeUser']),
    };
}

export function CarteIdentiteExistingUserDtoToJSON(value?: CarteIdentiteExistingUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'existingIds': value.existingIds,
        'existingPersonneIds': value.existingPersonneIds,
        'typeUser': ETypeCarteIdentiteUserToJSON(value.typeUser),
    };
}

