import { Intent } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, showError, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import {
  FcbFormateurAgrementGridDto,
  FichierApi,
  FormateurAgrementApi,
  FormateurAgrementSearch
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { AddButton, EditButton, LinkButton, ViewButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { exportFile } from "../../../../../utils";

export interface IFormateurAgrementListProps {}

export const FormateurAgrementList: React.FunctionComponent<IFormateurAgrementListProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(FormateurAgrementApi);
  const { id } = useParams<{ id: string }>();
  const path = React.useMemo(() => `${ERoutes.formateur}/${id}/agrement`, [id]);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { dateDemande: "ASC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: FormateurAgrementSearch) => {
      sObj.idFormateur = +id;
      return api.formateurAgrementBaseSearch({ FormateurAgrementSearch: sObj });
    },
    [api, id]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const fapi = useApiService(FichierApi);
  const downloadFn = React.useCallback(
    async idfichier => {
      try {
        const file = await fapi.fichierDownload({ id: idfichier });
        await exportFile(file);
      } catch (err) {
        showError(t(ETLCodes.FichierNonTrouve));
      }
    },
    [fapi, t]
  );

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbFormateurAgrementGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => history.push(`${path}/${row.idformateurAgrement}/detail/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${path}/${row.idformateurAgrement}/detail/edit`)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.NomConseiller),
        fieldName: "nomConseiller",
        computed: true
      },
      {
        header: () => t(ETLCodes.DateDemande),
        fieldName: "dateDemande",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.MatiereDemandee),
        fieldName: "matiereDemandee",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.CentreDemandeur),
        fieldName: "centreDemandeur"
      },
      {
        header: () => t(ETLCodes.Document),
        fieldName: "fichier",
        render: (row: FcbFormateurAgrementGridDto) =>
          row.fileName &&
          (row.idfichier ? <LinkButton text={row.fileName} onClick={() => downloadFn(row.idfichier)} /> : row.fileName)
      }
    ],
    [downloadFn, history, path, t]
  );

  return (
    <>
      <FieldSet
        title={t(ETLCodes.TableResults, { count: totalCount })}
        rightElement={
          <AddButton
            onClick={e => {
              e.stopPropagation();
              history.push(`${path}/0/detail/edit`);
            }}
            text={t(ETLCodes.General_Add)}
            intent={Intent.PRIMARY}
          />
        }
      >
        <DataTable
          dateFormat="dd/MM/yyyy"
          tableState={tableState}
          loading={loading}
          columns={columns}
          filterMode="OnEnter"
        ></DataTable>
      </FieldSet>
    </>
  );
};
