import { FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { containsWord } from "../../../../utils/containsWord";

import { ExportApi, FcbExportDtoFromJSON } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { FGWalterCheckboxInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";
import { ETLCodes } from "../../../../locales";

export interface IExportDetailPageProps {
  idExport: number;
  editMode: boolean;
}

export const ExportDetailPage: React.FunctionComponent<IExportDetailPageProps> = ({ idExport, editMode }) => {
  const { t } = useTl();
  const api = useApiService(ExportApi);
  const history = useHistory();
  const IsQueryAuthorized = React.useCallback((query: string = "") => {
    return (
      !containsWord(query.toUpperCase(), "DROP") &&
      !containsWord(query.toUpperCase(), "UPDATE") &&
      !containsWord(query.toUpperCase(), "INSERT")
    );
  }, []);

  const [type, tloading] = useReferential(a => a.referentialGetExportType(), true, []);
  const { data, loading, deleteItem, deleting, saveItem, saving, saveError } = useCrudApi({
    getApiFn: () =>
      idExport <= 0 ? FcbExportDtoFromJSON({ idExport: 0, actif: false }) : api.exportGet({ id: idExport }),
    saveApiFn: d => api.exportSave({ FcbExportDto: { ...d, iddb: 1 } }),
    onSavedRoute: d => `${ERoutes.exportation}/${d.idexport}/detail/edit`,
    deleteApiFn: d => api.exportDelete({ id: d.idexport }),
    onDeletedRoute: () => ERoutes.exportation
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      nom: Yup.string().required(t(ETLCodes.Required)),
      idtype: Yup.string().required(t(ETLCodes.Required)),
      actif: Yup.string().required(t(ETLCodes.Required)),
      query: Yup.string()
        .required(t(ETLCodes.Required))
        .test("query-authorized", t(ETLCodes.RequeteNonAutorisee), async value => {
          return IsQueryAuthorized(value);
        })
    });
  }, [IsQueryAuthorized, t]);

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={toSave => {
          saveItem(toSave);
        }}
        editMode={editMode}
        loading={loading}
        validationSchema={FormSchema}
        onCancel={() => history.push(ERoutes.exportation)}
        onDelete={deleteItem}
        showDeleteButton={idExport > 0}
        saving={saving}
        deleting={deleting}
        validationErrors={saveError?.validationErrors}
        enableDirtyCheck={false}
      >
        <FieldGroup columns={1}>
          <FGTextInput name="nom" label={t(ETLCodes.Nom)} maxLength={50} />
          <FGWalterSelectInput name="idtype" label={t(ETLCodes.Type)} items={type} loading={tloading} />
          <FGTextInput name="description" label={t(ETLCodes.Description)} maxLength={150} />
          <FGTextAreaInput name="query" label={t(ETLCodes.Requete)} growVertically={true} />
          <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
