import { Callout, NonIdealState } from "@blueprintjs/core";
import { format } from "date-fns";
import { FieldSet } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { AP_FINAL_KEY, CarouselComparer, DoublonsDataCard, useDoublonsStateContext } from "..";
import { ApprenantDoublonEditDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

const Container = styled.div`
  height: 70vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface IDoublonApprenantParcoursFormationProps {}

export const DoublonApprenantParcoursFormation: React.FunctionComponent<IDoublonApprenantParcoursFormationProps> = props => {
  const { t } = useTl();
  const { data, getSelectedIndex } = useDoublonsStateContext();

  const selectedIndex = React.useMemo(() => getSelectedIndex(`${AP_FINAL_KEY}.idapprenant`) ?? 0, [getSelectedIndex]);

  const apprenantContainer = React.useCallback(
    (index: number, nb: number) => {
      const apprenant: ApprenantDoublonEditDto = data?.apprenants[index];
      if (!apprenant) return;

      return (
        <FieldSet
          key={apprenant.idapprenant}
          title={
            <ViewButton
              text={t(ETLCodes.ApprenantNb, { nb: nb, id: apprenant.idapprenant })}
              onClick={() => window.open(`#${ERoutes.apprenant}/${apprenant.idapprenant}/signaletique/view`, "_blank")}
            />
          }
          titlePosition="center"
        >
          <DoublonsDataCard
            extValue={
              apprenant.currentParcoursFormation ? (
                <InformationContainer>
                  <div>
                    {t(ETLCodes.DateEntree)}: {format(apprenant.currentParcoursFormation.dateEntree, "dd-MM-yyyy")}
                  </div>
                  <div>
                    {t(ETLCodes.CategorieStatut)}: {apprenant.currentParcoursFormation.catStatutEntree}
                  </div>
                  <div>
                    {t(ETLCodes.Statut)}: {apprenant.currentParcoursFormation.statutEntree}
                  </div>
                  {!!apprenant.currentParcoursFormation.dateSortie && (
                    <>
                      <div>
                        {t(ETLCodes.DateSortie)}: {format(apprenant.currentParcoursFormation.dateSortie, "dd-MM-yyyy")}
                      </div>
                      <div>
                        {t(ETLCodes.MotifSortie)}: {apprenant.currentParcoursFormation.motifSortie}
                      </div>
                      <div>
                        {t(ETLCodes.CategorieStatutSortie)}: {apprenant.currentParcoursFormation.catStatutSortie}
                      </div>
                      <div>
                        {t(ETLCodes.StatutSortie)}: {apprenant.currentParcoursFormation.statutSortie}
                      </div>
                    </>
                  )}
                </InformationContainer>
              ) : (
                <NonIdealState title={t(ETLCodes.PasDeParcoursEnCours)} />
              )
            }
            label={`${t(ETLCodes.ParcoursFormationActuel)}${
              !!apprenant.currentParcoursFormation?.modificationDate
                ? " (" + format(apprenant.currentParcoursFormation.modificationDate, "dd-MM-yyyy HH:mm:ss") + ")"
                : ""
            }`}
            interactive={false}
            forceSelected={
              !!apprenant.currentParcoursFormation &&
              data.apprenantFinal.currentParcoursFormation?.idParcoursFormation ===
                apprenant.currentParcoursFormation?.idParcoursFormation
            }
          />
        </FieldSet>
      );
    },
    [data.apprenantFinal.currentParcoursFormation?.idParcoursFormation, data?.apprenants, t]
  );

  return (
    <Container>
      <Callout
        intent="warning"
        title={t(ETLCodes.ParcoursActuelPlusRecentChoisi)}
        style={{ marginBottom: "1rem" }}
        icon="info-sign"
      >
        {t(ETLCodes.DoublonApprenantParcoursCumules)}
      </Callout>
      <CarouselComparer leftElement={apprenantContainer(selectedIndex, selectedIndex + 1)}>
        {data?.apprenants?.length > 1 &&
          data?.apprenants
            ?.filter((a, i) => i !== selectedIndex)
            ?.map((a, i) => apprenantContainer(i + (i >= selectedIndex ? 1 : 0), i + (i >= selectedIndex ? 2 : 1)))}
      </CarouselComparer>
    </Container>
  );
};
