import { Dialog } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";
import { IApprenantSelectorProps, ApprenantSelector } from ".";
import { useTl } from "../../../hooks";
import { ETLCodes } from "../../../locales";

const StyledDialog = styled(Dialog)`
  width: 1100px;
  height: auto;
  background-color: white;
`;

export interface IApprenantSelectorDialogProps extends IApprenantSelectorProps {
  onClosed: (selectedApprenantIds?: number[]) => void;
  selectorDialogOpen: boolean;
  initialSearch?: boolean;
}

export const ApprenantSelectorDialog: React.FunctionComponent<IApprenantSelectorDialogProps> = ({
  onClosed,
  selectorDialogOpen,
  ...otherProps
}) => {
  const { t } = useTl();

  return (
    <StyledDialog
      title={t(ETLCodes.SearchApprenants)}
      isOpen={selectorDialogOpen}
      enforceFocus={false}
      onClose={() => onClosed(null)}
    >
      <ApprenantSelector {...otherProps} />
    </StyledDialog>
  );
};
