/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdresseDto } from './AdresseDto';
import {
    AdresseDtoFromJSON,
    AdresseDtoFromJSONTyped,
    AdresseDtoToJSON,
} from './AdresseDto';

/**
 * 
 * @export
 * @interface FcbRepresentantGridDto
 */
export interface FcbRepresentantGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbRepresentantGridDto
     */
    idrepresentant?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRepresentantGridDto
     */
    dateDeces?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantGridDto
     */
    telephone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantGridDto
     */
    telephonePro?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantGridDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantGridDto
     */
    gsm?: string | null;
    /**
     * 
     * @type {AdresseDto}
     * @memberof FcbRepresentantGridDto
     */
    adresseRepresentant?: AdresseDto;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantGridDto
     */
    readonly adresse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantGridDto
     */
    readonly codePostal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantGridDto
     */
    readonly localite?: string | null;
}

/**
 * Check if a given object implements the FcbRepresentantGridDto interface.
 */
export function instanceOfFcbRepresentantGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRepresentantGridDtoFromJSON(json: any): FcbRepresentantGridDto {
    return FcbRepresentantGridDtoFromJSONTyped(json, false);
}

export function FcbRepresentantGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRepresentantGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idrepresentant': !exists(json, 'idrepresentant') ? undefined : json['idrepresentant'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'dateDeces': !exists(json, 'dateDeces') ? undefined : (json['dateDeces'] === null ? null : new Date(json['dateDeces'])),
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'telephonePro': !exists(json, 'telephonePro') ? undefined : json['telephonePro'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'gsm': !exists(json, 'gsm') ? undefined : json['gsm'],
        'adresseRepresentant': !exists(json, 'adresseRepresentant') ? undefined : AdresseDtoFromJSON(json['adresseRepresentant']),
        'adresse': !exists(json, 'adresse') ? undefined : json['adresse'],
        'codePostal': !exists(json, 'codePostal') ? undefined : json['codePostal'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
    };
}

export function FcbRepresentantGridDtoToJSON(value?: FcbRepresentantGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idrepresentant': value.idrepresentant,
        'nom': value.nom,
        'prenom': value.prenom,
        'dateDeces': value.dateDeces === undefined ? undefined : (value.dateDeces === null ? null : value.dateDeces.toISOString()),
        'telephone': value.telephone,
        'telephonePro': value.telephonePro,
        'email': value.email,
        'gsm': value.gsm,
        'adresseRepresentant': AdresseDtoToJSON(value.adresseRepresentant),
    };
}

