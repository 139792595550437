import { Checkbox, Intent } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";

import {
  EAggregateType,
  FcbFinancementClasseGridDto,
  FinancementClasseApi,
  FinancementClasseSearch
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { AddButton, EditButton, ViewButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IClasseAgrementLocalisationListProps {
  idClasse: number;
  state: string;
}

export const ClasseAgrementLocalisationList: React.FunctionComponent<IClasseAgrementLocalisationListProps> = ({
  idClasse
}) => {
  const { t } = useTl();
  const history = useHistory();
  const financementApi = useApiService(FinancementClasseApi);
  const path = React.useMemo(() => `${ERoutes.classe}/${idClasse}/agrement`, [idClasse]);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [5],
    pageSize: 5,
    sortKeys: { financement: "ASC" }
  });

  const searchFunction = React.useCallback(
    (sObj?: FinancementClasseSearch) => {
      sObj.idClasse = idClasse;
      sObj.aggregateKeys = [
        { key: "HeureS1", aggregateType: EAggregateType.Sum },
        { key: "HeureS2", aggregateType: EAggregateType.Sum }
      ];
      return financementApi.financementClasseBaseSearch({ FinancementClasseSearch: sObj });
    },
    [financementApi, idClasse]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbFinancementClasseGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => history.push(`${path}/${row.idfinancementClasse}/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${path}/${row.idfinancementClasse}/edit`)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Financement),
        fieldName: "financement"
      },
      {
        header: () => t(ETLCodes.PPB),
        fieldName: "ppb"
      },
      {
        header: () => t(ETLCodes.S1Loc),
        fieldName: "heureS1"
      },
      {
        header: () => t(ETLCodes.S2Loc),
        fieldName: "heureS2"
      },
      {
        header: () => t(ETLCodes.TotalLoc),
        fieldName: "totalLoc"
      },
      {
        header: () => t(ETLCodes.Localise),
        fieldName: "localise",
        alignment: "center",
        autoFitContent: true,
        render: (row: FcbFinancementClasseGridDto) => <Checkbox disabled checked={row.localise} />
      },
      {
        header: () => t(ETLCodes.S1Agr),
        fieldName: "heureS1Agrement"
      },
      {
        header: () => t(ETLCodes.S2Agr),
        fieldName: "heureS2Agrement"
      },
      {
        header: () => t(ETLCodes.TotalAgr),
        fieldName: "totalAgrement"
      },
      {
        header: () => t(ETLCodes.Agree),
        fieldName: "agree",
        autoFitContent: true,
        render: (row: FcbFinancementClasseGridDto) => <Checkbox disabled checked={row.agree} />
      },
      {
        header: () => t(ETLCodes.HReelleS1),
        fieldName: "reelHoursS1"
      },
      {
        header: () => t(ETLCodes.HReelleS2),
        fieldName: "reelHoursS2"
      },
      {
        header: () => t(ETLCodes.TotalHReel),
        fieldName: "reelTotalAgrement"
      }
    ],
    [history, path, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${path}/0/edit`);
  }, [history, path]);

  return (
    <>
      {idClasse > 0 && (
        <FieldSet
          title={`${t(ETLCodes.AgrementLocalisation)}`}
          rightElement={
            <>
              <AddButton
                onClick={e => {
                  e.stopPropagation();
                  onAddItem();
                }}
                text={t(ETLCodes.General_Add)}
                intent={Intent.PRIMARY}
              />
            </>
          }
        >
          <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
        </FieldSet>
      )}
    </>
  );
};
