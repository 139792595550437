import { Spinner } from "@blueprintjs/core";
import { IDataTableColumn } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";

import { ClasseApi, VisiteClasseApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { WSelector } from "../../../../../components/selector/WSelector";
import { useDialog } from "../../../../../contexts";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IVisiteClasseLieeSelectorProps {}

export const VisiteClasseLieeSelector: React.FunctionComponent<IVisiteClasseLieeSelectorProps> = props => {
  const { t } = useTl();
  const history = useHistory();
  const classeApi = useApiService(ClasseApi);
  const visiteClasseApi = useApiService(VisiteClasseApi);
  const { id } = useParams<{ id: string; tab: string; state: string }>();
  const idVisite = React.useMemo(() => +id, [id]);
  const dialogContext = useDialog();

  const { data, isFetching } = useQuery(["visite-classes", id], () =>
    visiteClasseApi.visiteClasseGetVisiteClasse({ idVisite: idVisite })
  );

  const columns = React.useMemo<IDataTableColumn[]>(() => {
    return [
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code"
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "annee"
      },
      {
        header: () => t(ETLCodes.Lieu),
        fieldName: "lieu"
      }
    ];
  }, [t]);

  const searchIdsFunc = React.useCallback(sObj => classeApi.classeSearchClasseIds({ ClasseSearch: sObj }), [classeApi]);
  const getCriteriasFunction = React.useCallback(
    () => classeApi.classeGetSearchCriterias({ includeListsValues: true }),
    [classeApi]
  );
  const searchFunction = React.useCallback(sObj => classeApi.classeBaseSearch({ ClasseSearch: sObj }), [classeApi]);
  const onSave = React.useCallback(
    async newIds => {
      await visiteClasseApi.visiteClasseSaveClassesLies({
        FcbVisiteClasseDto: { idVisite: +idVisite, idsClasse: newIds }
      });
      history.push(`${ERoutes.visiteExterieure}/${idVisite}/classeLiee`);
    },
    [history, idVisite, visiteClasseApi]
  );

  return isFetching ? (
    <Spinner />
  ) : (
    <WSelector
      linkedIds={data.idsClasse}
      columns={columns}
      idField="idclasse"
      tlDataPrefix=""
      title={t(ETLCodes.ClasseLiee)}
      searchIdsFunc={searchIdsFunc}
      getCriteriasFunction={getCriteriasFunction}
      searchFunction={searchFunction}
      onSave={onSave}
      onCancel={() => history.push(`${ERoutes.visiteExterieure}/${idVisite}/classeLiee`)}
      overrideListValues={{ Idstade: i => i.displayValue }}
      dialogContext={dialogContext}
      sortKeys={{ code: "ASC" }}
    />
  );
};
