import * as React from "react";
import { useTl, useApiService, useAbortableApiServiceFactory } from "../../../../../../hooks";
import { useHistory, useParams } from "react-router";
import { useGlobalData } from "../../../../../../contexts";
import { ETLCodes } from "../../../../../../locales";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../../components";
import { ERoutes } from "../../../../../../AppRouter";
import {
  AbsenceApprenantApi,
  RechercheDateAbsenceApprenantSearch,
  ETextSearchType,
  FcbRechercheDateAbsenceDto
} from "../../../../../../api";
import { ButtonContainer } from "nsitools-react";

export interface IApprenantDateAbsenceListPageProps {}

export const ApprenantDateAbsenceListPage: React.FunctionComponent<IApprenantDateAbsenceListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { currentAnneeScolaire } = useGlobalData();
  const api = useApiService(AbsenceApprenantApi);
  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbRechercheDateAbsenceDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() =>
                history.push(`${ERoutes.feuillePresence}/${row.idclasse}/detail/view/${row.jour.replaceAll("/", "-")}`)
              }
            />
            <EditButton
              minimal={true}
              onClick={() =>
                history.push(`${ERoutes.feuillePresence}/${row.idclasse}/detail/edit/${row.jour.replaceAll("/", "-")}`)
              }
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "anneeScolaire"
      },
      {
        header: () => t(ETLCodes.Classe),
        fieldName: "codeclasse"
      },
      {
        header: () => t(ETLCodes.NomClasse),
        fieldName: "classe"
      },
      {
        header: () => t(ETLCodes.Date),
        fieldName: "orderjour"
      },
      {
        header: () => t(ETLCodes.Heures),
        fieldName: "heures"
      },
      {
        header: () => t(ETLCodes.Statut),
        fieldName: "statutAbsence"
      }
    ],
    [history, t]
  );

  const getCriterias = React.useCallback(() => api.absenceApprenantGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const apiFactory = useAbortableApiServiceFactory(AbsenceApprenantApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFunction = React.useCallback(
    (sObj?: RechercheDateAbsenceApprenantSearch) => {
      sObj.idApprenant = +id;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.absenceApprenantBaseSearch({ RechercheDateAbsenceApprenantSearch: sObj });
    },
    [apiFactory, id]
  );

  const defaultCriterias = React.useMemo(
    () => [
      {
        criteria: "AnneeScolaire",
        searchMode: ETextSearchType.Contains,
        value: currentAnneeScolaire?.idanneeScolaire
      }
    ],
    [currentAnneeScolaire]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriterias}
      searchFunction={searchFunction}
      onAbort={onAbort}
      title={ETLCodes.DateAbsence}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.Diplomes), route: ERoutes.diplome }]}
      withCard={false}
      defaultCriterias={defaultCriterias}
      searchStateInitialSearch={false}
      avoidSavingCriterias={true}
    />
  );
};
