import { Button, Intent } from "@blueprintjs/core";
import * as React from "react";

import { ClasseHoraireApi } from "../../../../../../api";
import { useApiService, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";
import { useEventsContext } from "../../../../../../contexts";
import { CLASSE_HORAIRE_ACTIONS } from "../ClasseHoraireItemPage";

export interface ICalculHoraireManquantProps {
  idClasse: number;
}

export const CalculHoraireManquant: React.FunctionComponent<ICalculHoraireManquantProps> = ({ idClasse }) => {
  const { t } = useTl();
  const api = useApiService(ClasseHoraireApi);
  const { dispatchEvent } = useEventsContext();
  const [loading, setLoading] = React.useState(false);

  return (
    <>
      <Button
        text={t(ETLCodes.Executer)}
        intent={Intent.PRIMARY}
        onClick={async () => {
          setLoading(true);
          await api.classeHoraireCreateMissingHoraire({ idClasse });
          setLoading(false);
          dispatchEvent(CLASSE_HORAIRE_ACTIONS, true);
        }}
        loading={loading}
      />
    </>
  );
};
