import * as React from "react";
import { IconNames } from "@blueprintjs/icons";
import { ETLCodes } from "../../locales/ETLCodes";
import { useTl } from "../../hooks";
import { ICustomButtonProps } from "./ICustomButtonProps";
import { Intent, Button } from "@blueprintjs/core";

interface INextButtonProps extends ICustomButtonProps {
  className?: string;
}

export const NextButton: React.FunctionComponent<INextButtonProps> = ({
  minimal,
  onClick,
  disabled,
  loading,
  intent = Intent.NONE,
  hideText,
  className
}) => {
  const { t } = useTl();

  return (
    <Button
      rightIcon={IconNames.CHEVRON_RIGHT}
      minimal={minimal}
      onClick={onClick}
      text={hideText ? null : t(ETLCodes.Suivant)}
      intent={intent}
      loading={loading}
      disabled={disabled}
      className={className}
    />
  );
};
