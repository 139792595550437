/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LieuFormationGridDto
 */
export interface LieuFormationGridDto {
    /**
     * 
     * @type {number}
     * @memberof LieuFormationGridDto
     */
    idlieuFormation?: number;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationGridDto
     */
    idsiegeSocial?: number;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationGridDto
     */
    numeroUe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationGridDto
     */
    contact?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationGridDto
     */
    chefEntreprise?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationGridDto
     */
    telephone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationGridDto
     */
    gsm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationGridDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationGridDto
     */
    codePostal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationGridDto
     */
    localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationGridDto
     */
    enseigne?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationGridDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationGridDto
     */
    readonly formationCanHaveApprenants?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationGridDto
     */
    nbContrats?: number;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationGridDto
     */
    capaciteFormative?: number | null;
}

/**
 * Check if a given object implements the LieuFormationGridDto interface.
 */
export function instanceOfLieuFormationGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LieuFormationGridDtoFromJSON(json: any): LieuFormationGridDto {
    return LieuFormationGridDtoFromJSONTyped(json, false);
}

export function LieuFormationGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LieuFormationGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'numeroUe': !exists(json, 'numeroUe') ? undefined : json['numeroUe'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'chefEntreprise': !exists(json, 'chefEntreprise') ? undefined : json['chefEntreprise'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'gsm': !exists(json, 'gsm') ? undefined : json['gsm'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'codePostal': !exists(json, 'codePostal') ? undefined : json['codePostal'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'enseigne': !exists(json, 'enseigne') ? undefined : json['enseigne'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'formationCanHaveApprenants': !exists(json, 'formationCanHaveApprenants') ? undefined : json['formationCanHaveApprenants'],
        'nbContrats': !exists(json, 'nbContrats') ? undefined : json['nbContrats'],
        'capaciteFormative': !exists(json, 'capaciteFormative') ? undefined : json['capaciteFormative'],
    };
}

export function LieuFormationGridDtoToJSON(value?: LieuFormationGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idlieuFormation': value.idlieuFormation,
        'idsiegeSocial': value.idsiegeSocial,
        'nom': value.nom,
        'numeroUe': value.numeroUe,
        'contact': value.contact,
        'chefEntreprise': value.chefEntreprise,
        'telephone': value.telephone,
        'gsm': value.gsm,
        'email': value.email,
        'codePostal': value.codePostal,
        'localite': value.localite,
        'enseigne': value.enseigne,
        'actif': value.actif,
        'nbContrats': value.nbContrats,
        'capaciteFormative': value.capaciteFormative,
    };
}

