import "./index.css";
import * as React from "react";
import * as ReactDom from "react-dom";
import { App } from "./App";
import { AppProvider } from "./AppProvider";
import { pdfjs } from "react-pdf";
import { versions } from "./version";

(window as any).__app_version = versions;
pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.min.js";

ReactDom.render(
  <AppProvider>
    <App></App>
  </AppProvider>,
  document.getElementById("root")
);
