/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PratiqueProfessionnelleDto
 */
export interface PratiqueProfessionnelleDto {
    /**
     * 
     * @type {number}
     * @memberof PratiqueProfessionnelleDto
     */
    idcontratPratiqueProfessionnelle?: number;
    /**
     * 
     * @type {number}
     * @memberof PratiqueProfessionnelleDto
     */
    idcontrat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PratiqueProfessionnelleDto
     */
    iddispenseChomage?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PratiqueProfessionnelleDto
     */
    nbHeuresMax?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PratiqueProfessionnelleDto
     */
    nbHeuresMin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PratiqueProfessionnelleDto
     */
    anneeFormation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PratiqueProfessionnelleDto
     */
    nbHeuresPrevues?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PratiqueProfessionnelleDto
     */
    nbHeuresPrestees?: number | null;
}

/**
 * Check if a given object implements the PratiqueProfessionnelleDto interface.
 */
export function instanceOfPratiqueProfessionnelleDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PratiqueProfessionnelleDtoFromJSON(json: any): PratiqueProfessionnelleDto {
    return PratiqueProfessionnelleDtoFromJSONTyped(json, false);
}

export function PratiqueProfessionnelleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PratiqueProfessionnelleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontratPratiqueProfessionnelle': !exists(json, 'idcontratPratiqueProfessionnelle') ? undefined : json['idcontratPratiqueProfessionnelle'],
        'idcontrat': !exists(json, 'idcontrat') ? undefined : json['idcontrat'],
        'iddispenseChomage': !exists(json, 'iddispenseChomage') ? undefined : json['iddispenseChomage'],
        'nbHeuresMax': !exists(json, 'nbHeuresMax') ? undefined : json['nbHeuresMax'],
        'nbHeuresMin': !exists(json, 'nbHeuresMin') ? undefined : json['nbHeuresMin'],
        'anneeFormation': !exists(json, 'anneeFormation') ? undefined : json['anneeFormation'],
        'nbHeuresPrevues': !exists(json, 'nbHeuresPrevues') ? undefined : json['nbHeuresPrevues'],
        'nbHeuresPrestees': !exists(json, 'nbHeuresPrestees') ? undefined : json['nbHeuresPrestees'],
    };
}

export function PratiqueProfessionnelleDtoToJSON(value?: PratiqueProfessionnelleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontratPratiqueProfessionnelle': value.idcontratPratiqueProfessionnelle,
        'idcontrat': value.idcontrat,
        'iddispenseChomage': value.iddispenseChomage,
        'nbHeuresMax': value.nbHeuresMax,
        'nbHeuresMin': value.nbHeuresMin,
        'anneeFormation': value.anneeFormation,
        'nbHeuresPrevues': value.nbHeuresPrevues,
        'nbHeuresPrestees': value.nbHeuresPrestees,
    };
}

