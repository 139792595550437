/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LieuFormationAgrementDecisionMultipleDto
 */
export interface LieuFormationAgrementDecisionMultipleDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof LieuFormationAgrementDecisionMultipleDto
     */
    idslieuFormationAgrement?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementDecisionMultipleDto
     */
    idlieuFormationAgrementDecision?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementDecisionMultipleDto
     */
    dateDecision?: Date | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof LieuFormationAgrementDecisionMultipleDto
     */
    motifsNonAgrement?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationAgrementDecisionMultipleDto
     */
    remarqueDecision?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementDecisionMultipleDto
     */
    dateEnvoiD3?: Date | null;
}

/**
 * Check if a given object implements the LieuFormationAgrementDecisionMultipleDto interface.
 */
export function instanceOfLieuFormationAgrementDecisionMultipleDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LieuFormationAgrementDecisionMultipleDtoFromJSON(json: any): LieuFormationAgrementDecisionMultipleDto {
    return LieuFormationAgrementDecisionMultipleDtoFromJSONTyped(json, false);
}

export function LieuFormationAgrementDecisionMultipleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LieuFormationAgrementDecisionMultipleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idslieuFormationAgrement': !exists(json, 'idslieuFormationAgrement') ? undefined : json['idslieuFormationAgrement'],
        'idlieuFormationAgrementDecision': !exists(json, 'idlieuFormationAgrementDecision') ? undefined : json['idlieuFormationAgrementDecision'],
        'dateDecision': !exists(json, 'dateDecision') ? undefined : (json['dateDecision'] === null ? null : new Date(json['dateDecision'])),
        'motifsNonAgrement': !exists(json, 'motifsNonAgrement') ? undefined : json['motifsNonAgrement'],
        'remarqueDecision': !exists(json, 'remarqueDecision') ? undefined : json['remarqueDecision'],
        'dateEnvoiD3': !exists(json, 'dateEnvoiD3') ? undefined : (json['dateEnvoiD3'] === null ? null : new Date(json['dateEnvoiD3'])),
    };
}

export function LieuFormationAgrementDecisionMultipleDtoToJSON(value?: LieuFormationAgrementDecisionMultipleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idslieuFormationAgrement': value.idslieuFormationAgrement,
        'idlieuFormationAgrementDecision': value.idlieuFormationAgrementDecision,
        'dateDecision': value.dateDecision === undefined ? undefined : (value.dateDecision === null ? null : value.dateDecision.toISOString()),
        'motifsNonAgrement': value.motifsNonAgrement,
        'remarqueDecision': value.remarqueDecision,
        'dateEnvoiD3': value.dateEnvoiD3 === undefined ? undefined : (value.dateEnvoiD3 === null ? null : value.dateEnvoiD3.toISOString()),
    };
}

