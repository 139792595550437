/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ELieuFormationAgrementStatut {
    PourOffre = 'PourOffre',
    EnInstance = 'EnInstance',
    Provisoire = 'Provisoire',
    Agree = 'Agree',
    NonAgree = 'NonAgree',
    Suspendu = 'Suspendu',
    Retrait = 'Retrait',
    SansSuite = 'SansSuite'
}


export function ELieuFormationAgrementStatutFromJSON(json: any): ELieuFormationAgrementStatut {
    return ELieuFormationAgrementStatutFromJSONTyped(json, false);
}

export function ELieuFormationAgrementStatutFromJSONTyped(json: any, ignoreDiscriminator: boolean): ELieuFormationAgrementStatut {
    return json as ELieuFormationAgrementStatut;
}

export function ELieuFormationAgrementStatutToJSON(value?: ELieuFormationAgrementStatut | null): any {
    return value as any;
}

