import { Classes, Colors, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import groupBy from "lodash/groupBy";
import { Loading } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import {
  FcbRapportGridDto,
  RapportHopeApi,
  RapportHopeGridDto,
  RapportHopeSearchDtoFromJSON,
  RapportSearchFromJSON,
  ReportApi
} from "../api";
import { ERoutes } from "../AppRouter";
import { useGlobalData } from "../contexts";
import { useApiService, useTabMessage, useTheme, useTl } from "../hooks";
import { ETLCodes } from "../locales";
import { nameof } from "../utils";
import { Guid } from "../utils/guid";

const StyledContainer = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 250px;
  height: auto;
  background-color: ${props => props.backgroundColor};
  & .${Classes.MENU} {
    padding: 0 !important;
  }
  width: initial !important;
  color: ${Colors.WHITE};
  overflow: auto;
  max-height: 92.3vh;
`;

const TitleContainer = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${props => props.backgroundColor};
  align-items: center;
`;

export interface IRapportMenuProps {
  toggleRapportMenu?: (open: boolean) => void;
}

export const RapportMenu: React.FunctionComponent<IRapportMenuProps> = ({ toggleRapportMenu }) => {
  const { t } = useTl();
  const reportApi = useApiService(ReportApi);
  const rapportHopeApi = useApiService(RapportHopeApi);
  const { currentModuleId } = useGlobalData();
  const { theme } = useTheme();
  const identifier = React.useMemo(() => Guid.newGuid(), []);
  const { openTabThenSendMessage } = useTabMessage(identifier);

  const { pathname } = useLocation();
  const [lastRoute, setLastRoute] = React.useState(pathname.split("/")[1]);

  const id = React.useMemo(() => (pathname.split("/").length > 2 ? pathname.split("/")[2] : null), [pathname]);

  React.useEffect(() => {
    const splitted = pathname.split("/");
    if (!(splitted.length > 1)) return;

    const currentRoute = splitted[1];
    if (currentRoute !== lastRoute && lastRoute) {
      toggleRapportMenu(false);
    }
    setLastRoute(currentRoute);
  }, [lastRoute, pathname, toggleRapportMenu]);

  const fetchRapportWalter = React.useCallback(() => {
    if (!currentModuleId) return;
    return reportApi.reportBaseSearch({
      RapportSearch: RapportSearchFromJSON({
        moduleId: +currentModuleId,
        isActif: true
      })
    });
  }, [reportApi, currentModuleId]);

  const { data: dataWalter, isFetching: loadingWalter } = useQuery(
    ["rapports-walter", currentModuleId],
    fetchRapportWalter,
    { cacheTime: 0 }
  );

  const fetchRapportHope = React.useCallback(() => {
    if (!currentModuleId || !id) return;
    return rapportHopeApi.rapportHopeBaseSearch({
      RapportHopeSearchDto: RapportHopeSearchDtoFromJSON({
        idmodule: +currentModuleId
      })
    });
  }, [currentModuleId, id, rapportHopeApi]);

  const { data: dataHope, isFetching: loadingHope } = useQuery(
    ["rapports-hope", currentModuleId, id],
    fetchRapportHope,
    {
      cacheTime: 0
    }
  );

  const hopeItems = React.useMemo(
    () =>
      groupBy(
        dataHope?.results
          ?.reduce((acc, element) => {
            if (!element.categorie || element.categorie === "") {
              return [element, ...acc];
            }
            return [...acc, element];
          }, [])
          ?.map(r => ({
            ...r,
            categorie: !r.categorie || r.categorie === "" ? t(ETLCodes.Global) : r.categorie
          })) ?? [],
        nameof<RapportHopeGridDto>("categorie")
      ),
    [dataHope?.results, t]
  );

  const onMenuClickWalter = React.useCallback((dto: FcbRapportGridDto) => {
    window.open(`/#${ERoutes.report}/${dto.rapportId}`, "_blank");
  }, []);

  const onMenuClickHope = React.useCallback(
    (dto: RapportHopeGridDto) => {
      openTabThenSendMessage(`/#${ERoutes.SSRSReports}/${dto.idrapportHope}`, [id]);
    },
    [id, openTabThenSendMessage]
  );

  return (
    <StyledContainer className={Classes.DARK} backgroundColor={theme.menuBackgroundColor}>
      <Loading isLoading={loadingWalter || loadingHope}>
        <>
          {dataWalter?.results?.length > 0 && (
            <TitleContainer backgroundColor={theme.menuBackgroundColor}>
              <div
                className={Classes.DRAWER_HEADER}
                style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}
              >
                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div>{t(ETLCodes.RapportsCentres)}</div>
                </div>
              </div>
            </TitleContainer>
          )}
          <Menu>
            {dataWalter?.results.map(d => (
              <MenuItem key={d.rapportId} text={d.rapportName} onClick={() => onMenuClickWalter(d)} />
            ))}
          </Menu>
          {dataHope?.results?.length > 0 && (
            <TitleContainer backgroundColor={theme.menuBackgroundColor}>
              <div
                className={Classes.DRAWER_HEADER}
                style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}
              >
                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div>{t(ETLCodes.RapportsServices)}</div>
                </div>
              </div>
            </TitleContainer>
          )}
          <Menu>
            {Object.entries(hopeItems).map(([key, d], i) => (
              <>
                <MenuDivider title={key} key={i} />
                {d.map((r: any) => (
                  <MenuItem key={i + "_" + r.idrapportHope} text={r.nom} onClick={() => onMenuClickHope(r)} />
                ))}
              </>
            ))}
          </Menu>
        </>
      </Loading>
    </StyledContainer>
  );
};
