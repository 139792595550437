/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailDto } from './EmailDto';
import {
    EmailDtoFromJSON,
    EmailDtoFromJSONTyped,
    EmailDtoToJSON,
} from './EmailDto';
import type { TelephoneDto } from './TelephoneDto';
import {
    TelephoneDtoFromJSON,
    TelephoneDtoFromJSONTyped,
    TelephoneDtoToJSON,
} from './TelephoneDto';

/**
 * 
 * @export
 * @interface LieuFormationEditDto
 */
export interface LieuFormationEditDto {
    /**
     * 
     * @type {number}
     * @memberof LieuFormationEditDto
     */
    idlieuFormation?: number;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationEditDto
     */
    idsiegeSocial?: number;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationEditDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationEditDto
     */
    numeroUe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationEditDto
     */
    enseigne?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationEditDto
     */
    capaciteFormative?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationEditDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationEditDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationEditDto
     */
    siegeSocialActif?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationEditDto
     */
    encodageProvisoireAdministratif?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationEditDto
     */
    tuteurAlreadyExists?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationEditDto
     */
    creerTuteur?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationEditDto
     */
    idchefEntreprise?: number;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationEditDto
     */
    idpersonne?: number | null;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof LieuFormationEditDto
     */
    gsmChefEntreprise?: TelephoneDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof LieuFormationEditDto
     */
    telephoneChefEntreprise?: TelephoneDto;
    /**
     * 
     * @type {EmailDto}
     * @memberof LieuFormationEditDto
     */
    emailChefEntreprise?: EmailDto;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationEditDto
     */
    sendToAdress?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationEditDto
     */
    adresse?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationEditDto
     */
    idcodePostal?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationEditDto
     */
    codePostalText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationEditDto
     */
    boite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationEditDto
     */
    localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationEditDto
     */
    commune?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationEditDto
     */
    pays?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationEditDto
     */
    capaciteFormativeAtteinte?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationEditDto
     */
    contact?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationEditDto
     */
    contactFonction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationEditDto
     */
    contactTel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationEditDto
     */
    contactGsm?: string | null;
}

/**
 * Check if a given object implements the LieuFormationEditDto interface.
 */
export function instanceOfLieuFormationEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LieuFormationEditDtoFromJSON(json: any): LieuFormationEditDto {
    return LieuFormationEditDtoFromJSONTyped(json, false);
}

export function LieuFormationEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LieuFormationEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'numeroUe': !exists(json, 'numeroUe') ? undefined : json['numeroUe'],
        'enseigne': !exists(json, 'enseigne') ? undefined : json['enseigne'],
        'capaciteFormative': !exists(json, 'capaciteFormative') ? undefined : json['capaciteFormative'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'siegeSocialActif': !exists(json, 'siegeSocialActif') ? undefined : json['siegeSocialActif'],
        'encodageProvisoireAdministratif': !exists(json, 'encodageProvisoireAdministratif') ? undefined : json['encodageProvisoireAdministratif'],
        'tuteurAlreadyExists': !exists(json, 'tuteurAlreadyExists') ? undefined : json['tuteurAlreadyExists'],
        'creerTuteur': !exists(json, 'creerTuteur') ? undefined : json['creerTuteur'],
        'idchefEntreprise': !exists(json, 'idchefEntreprise') ? undefined : json['idchefEntreprise'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'gsmChefEntreprise': !exists(json, 'gsmChefEntreprise') ? undefined : TelephoneDtoFromJSON(json['gsmChefEntreprise']),
        'telephoneChefEntreprise': !exists(json, 'telephoneChefEntreprise') ? undefined : TelephoneDtoFromJSON(json['telephoneChefEntreprise']),
        'emailChefEntreprise': !exists(json, 'emailChefEntreprise') ? undefined : EmailDtoFromJSON(json['emailChefEntreprise']),
        'sendToAdress': !exists(json, 'sendToAdress') ? undefined : json['sendToAdress'],
        'adresse': !exists(json, 'adresse') ? undefined : json['adresse'],
        'idcodePostal': !exists(json, 'idcodePostal') ? undefined : json['idcodePostal'],
        'codePostalText': !exists(json, 'codePostalText') ? undefined : json['codePostalText'],
        'boite': !exists(json, 'boite') ? undefined : json['boite'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'commune': !exists(json, 'commune') ? undefined : json['commune'],
        'pays': !exists(json, 'pays') ? undefined : json['pays'],
        'capaciteFormativeAtteinte': !exists(json, 'capaciteFormativeAtteinte') ? undefined : json['capaciteFormativeAtteinte'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'contactFonction': !exists(json, 'contactFonction') ? undefined : json['contactFonction'],
        'contactTel': !exists(json, 'contactTel') ? undefined : json['contactTel'],
        'contactGsm': !exists(json, 'contactGsm') ? undefined : json['contactGsm'],
    };
}

export function LieuFormationEditDtoToJSON(value?: LieuFormationEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idlieuFormation': value.idlieuFormation,
        'idsiegeSocial': value.idsiegeSocial,
        'nom': value.nom,
        'numeroUe': value.numeroUe,
        'enseigne': value.enseigne,
        'capaciteFormative': value.capaciteFormative,
        'actif': value.actif,
        'remarque': value.remarque,
        'siegeSocialActif': value.siegeSocialActif,
        'encodageProvisoireAdministratif': value.encodageProvisoireAdministratif,
        'tuteurAlreadyExists': value.tuteurAlreadyExists,
        'creerTuteur': value.creerTuteur,
        'idchefEntreprise': value.idchefEntreprise,
        'idpersonne': value.idpersonne,
        'gsmChefEntreprise': TelephoneDtoToJSON(value.gsmChefEntreprise),
        'telephoneChefEntreprise': TelephoneDtoToJSON(value.telephoneChefEntreprise),
        'emailChefEntreprise': EmailDtoToJSON(value.emailChefEntreprise),
        'sendToAdress': value.sendToAdress,
        'adresse': value.adresse,
        'idcodePostal': value.idcodePostal,
        'codePostalText': value.codePostalText,
        'boite': value.boite,
        'localite': value.localite,
        'commune': value.commune,
        'pays': value.pays,
        'capaciteFormativeAtteinte': value.capaciteFormativeAtteinte,
        'contact': value.contact,
        'contactFonction': value.contactFonction,
        'contactTel': value.contactTel,
        'contactGsm': value.contactGsm,
    };
}

