/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbLieuxCoursGridDto
 */
export interface FcbLieuxCoursGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbLieuxCoursGridDto
     */
    idlieu?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbLieuxCoursGridDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbLieuxCoursGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbLieuxCoursGridDto
     */
    codePays?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbLieuxCoursGridDto
     */
    pays?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbLieuxCoursGridDto
     */
    codePostal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbLieuxCoursGridDto
     */
    adresse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbLieuxCoursGridDto
     */
    localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbLieuxCoursGridDto
     */
    commune?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbLieuxCoursGridDto
     */
    tel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbLieuxCoursGridDto
     */
    fax?: string | null;
}

/**
 * Check if a given object implements the FcbLieuxCoursGridDto interface.
 */
export function instanceOfFcbLieuxCoursGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbLieuxCoursGridDtoFromJSON(json: any): FcbLieuxCoursGridDto {
    return FcbLieuxCoursGridDtoFromJSONTyped(json, false);
}

export function FcbLieuxCoursGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbLieuxCoursGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idlieu': !exists(json, 'idlieu') ? undefined : json['idlieu'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'codePays': !exists(json, 'codePays') ? undefined : json['codePays'],
        'pays': !exists(json, 'pays') ? undefined : json['pays'],
        'codePostal': !exists(json, 'codePostal') ? undefined : json['codePostal'],
        'adresse': !exists(json, 'adresse') ? undefined : json['adresse'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'commune': !exists(json, 'commune') ? undefined : json['commune'],
        'tel': !exists(json, 'tel') ? undefined : json['tel'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
    };
}

export function FcbLieuxCoursGridDtoToJSON(value?: FcbLieuxCoursGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idlieu': value.idlieu,
        'code': value.code,
        'nom': value.nom,
        'codePays': value.codePays,
        'pays': value.pays,
        'codePostal': value.codePostal,
        'adresse': value.adresse,
        'localite': value.localite,
        'commune': value.commune,
        'tel': value.tel,
        'fax': value.fax,
    };
}

