/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbPlageHoraireDto,
  FcbPlageHoraireGridDtoPaginatedResults,
  FilterCriteriaInfo,
  PlageHoraireSearch,
  SelectItem,
} from '../models/index';
import {
    FcbPlageHoraireDtoFromJSON,
    FcbPlageHoraireDtoToJSON,
    FcbPlageHoraireGridDtoPaginatedResultsFromJSON,
    FcbPlageHoraireGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    PlageHoraireSearchFromJSON,
    PlageHoraireSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface PlageHoraireBaseSearchRequest {
    PlageHoraireSearch?: PlageHoraireSearch;
}

export interface PlageHoraireDeleteRequest {
    id?: number;
}

export interface PlageHoraireGetRequest {
    id?: number;
    nbPlages?: number;
    idCentre?: number;
}

export interface PlageHoraireGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface PlageHoraireGetSelectItemsRequest {
    searchField: string;
}

export interface PlageHoraireSaveRequest {
    FcbPlageHoraireDto?: FcbPlageHoraireDto;
}

/**
 * 
 */
export class PlageHoraireApi extends runtime.BaseAPI {

    /**
     */
    async plageHoraireBaseSearchRaw(requestParameters: PlageHoraireBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbPlageHoraireGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PlageHoraire/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlageHoraireSearchToJSON(requestParameters.PlageHoraireSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbPlageHoraireGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async plageHoraireBaseSearch(requestParameters: PlageHoraireBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbPlageHoraireGridDtoPaginatedResults> {
        const response = await this.plageHoraireBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async plageHoraireDeleteRaw(requestParameters: PlageHoraireDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PlageHoraire`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async plageHoraireDelete(requestParameters: PlageHoraireDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.plageHoraireDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async plageHoraireGetRaw(requestParameters: PlageHoraireGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbPlageHoraireDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.nbPlages !== undefined) {
            queryParameters['nbPlages'] = requestParameters.nbPlages;
        }

        if (requestParameters.idCentre !== undefined) {
            queryParameters['idCentre'] = requestParameters.idCentre;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PlageHoraire`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbPlageHoraireDtoFromJSON(jsonValue));
    }

    /**
     */
    async plageHoraireGet(requestParameters: PlageHoraireGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbPlageHoraireDto> {
        const response = await this.plageHoraireGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async plageHoraireGetSearchCriteriasRaw(requestParameters: PlageHoraireGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PlageHoraire/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async plageHoraireGetSearchCriterias(requestParameters: PlageHoraireGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.plageHoraireGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async plageHoraireGetSelectItemsRaw(requestParameters: PlageHoraireGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling plageHoraireGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PlageHoraire/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async plageHoraireGetSelectItems(requestParameters: PlageHoraireGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.plageHoraireGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async plageHoraireSaveRaw(requestParameters: PlageHoraireSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbPlageHoraireDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PlageHoraire`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbPlageHoraireDtoToJSON(requestParameters.FcbPlageHoraireDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbPlageHoraireDtoFromJSON(jsonValue));
    }

    /**
     */
    async plageHoraireSave(requestParameters: PlageHoraireSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbPlageHoraireDto> {
        const response = await this.plageHoraireSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
