/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImpersonateUserInfoDto
 */
export interface ImpersonateUserInfoDto {
    /**
     * 
     * @type {string}
     * @memberof ImpersonateUserInfoDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImpersonateUserInfoDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImpersonateUserInfoDto
     */
    azureEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImpersonateUserInfoDto
     */
    userId?: string | null;
}

/**
 * Check if a given object implements the ImpersonateUserInfoDto interface.
 */
export function instanceOfImpersonateUserInfoDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ImpersonateUserInfoDtoFromJSON(json: any): ImpersonateUserInfoDto {
    return ImpersonateUserInfoDtoFromJSONTyped(json, false);
}

export function ImpersonateUserInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImpersonateUserInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'azureEmail': !exists(json, 'azureEmail') ? undefined : json['azureEmail'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function ImpersonateUserInfoDtoToJSON(value?: ImpersonateUserInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nom': value.nom,
        'prenom': value.prenom,
        'azureEmail': value.azureEmail,
        'userId': value.userId,
    };
}

