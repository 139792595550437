import { Checkbox, Icon, Spinner, Tooltip } from "@blueprintjs/core";
import { ButtonContainer, IDataTableColumn } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";
import styled from "styled-components";

import {
  EBooleanSearchTypes,
  ELieuFormationAgrementStatut,
  LieuFormationAgrementApi,
  LieuFormationAgrementSearchDto,
  OffreDemandeApi,
  OffreDemandeGridDto
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { CircleColumn, CustomBulletList, EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useEventsContext } from "../../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTheme, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

const LegendHeaderContainer = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

export interface ILieuFormationOffresProps {}

export const LieuFormationOffres: React.FunctionComponent<ILieuFormationOffresProps> = props => {
  const { t, tUnsafe } = useTl();
  const { theme } = useTheme();
  const api = useApiService(OffreDemandeApi);
  const { id } = useParams<{ id: string; tab: string; state: string }>();

  const apiFactory = useAbortableApiServiceFactory(OffreDemandeApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFunction = React.useCallback(
    (sObj?: LieuFormationAgrementSearchDto) => {
      sObj.idlieuFormation = +id;
      sObj.excludedStatuts = [ELieuFormationAgrementStatut.Suspendu, ELieuFormationAgrementStatut.Retrait];
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.offreDemandeBaseSearch({ OffreDemandeSearchDto: sObj });
    },
    [apiFactory, id]
  );

  const computeType = React.useCallback(
    (row: OffreDemandeGridDto) => {
      if (row.agrementPartielA && row.agrementPartielB) return t(ETLCodes.PartielAB);
      if (row.agrementPartielA) return t(ETLCodes.PartielA);
      if (row.agrementPartielB) return t(ETLCodes.PartielB);
      return t(ETLCodes.Complet);
    },
    [t]
  );

  const [updateOffreLoading, setUpdateOffreLoading] = React.useState(0);
  const { dispatchEvent } = useEventsContext();
  const lfAgrementApi = useApiService(LieuFormationAgrementApi);
  const updateOffre = React.useCallback(
    async (row: OffreDemandeGridDto) => {
      setUpdateOffreLoading(row.idlieuFormationAgrement);
      await lfAgrementApi.lieuFormationAgrementUpdateOffre({
        idlieuFormationAgrement: row.idlieuFormationAgrement,
        newValue: !row.offreActive
      });
      dispatchEvent("SEARCH_TABLE_REFRESH");
      setUpdateOffreLoading(0);
    },
    [dispatchEvent, lfAgrementApi]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: OffreDemandeGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => window.open(`#${ERoutes.offre}/${row.idlieuFormationAgrementOffre}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => window.open(`#${ERoutes.offre}/${row.idlieuFormationAgrementOffre}/detail/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.LieuFormation),
        fieldName: "nomLieuFormation"
      },
      {
        header: () => t(ETLCodes.Enseigne),
        fieldName: "enseigneLieuFormation"
      },
      {
        header: () => t(ETLCodes.Active),
        fieldName: "offreActive",
        autoFitContent: true,
        alignment: "center",
        render: (row: OffreDemandeGridDto) =>
          updateOffreLoading === row.idlieuFormationAgrement ? (
            <Spinner size={20} />
          ) : (
            <Checkbox
              checked={row.offreActive}
              onChange={async e => {
                await updateOffre(row);
              }}
              disabled={updateOffreLoading > 0}
            />
          )
      },
      {
        header: () => (
          <LegendHeaderContainer>
            <span>{t(ETLCodes.C)}</span>
            <Tooltip
              content={
                <CustomBulletList
                  title={t(ETLCodes.Legende)}
                  items={[
                    {
                      text: t(ETLCodes.CapaciteNonAtteinte),
                      color: theme.sucessColor
                    },
                    {
                      text: t(ETLCodes.CapaciteAtteinte),
                      color: theme.dangerColor
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip>
          </LegendHeaderContainer>
        ),
        fieldName: "capaciteAtteinte",
        autoFitContent: true,
        render: (row: OffreDemandeGridDto) => (
          <CircleColumn color={row.capaciteAtteinte ? theme.dangerColor : theme.sucessColor} />
        )
      },
      {
        header: () => t(ETLCodes.CodeMetier),
        fieldName: "codeMetier"
      },
      {
        header: () => t(ETLCodes.NomMetier),
        fieldName: "nomMetier"
      },
      {
        header: () => t(ETLCodes.Stade),
        fieldName: "nomStade"
      },
      {
        header: () => t(ETLCodes.Statut),
        fieldName: "statut",
        render: row => tUnsafe(`StatutAgrement_${row.statut}`)
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "type",
        render: (row: OffreDemandeGridDto) => computeType(row)
      },
      {
        header: () => t(ETLCodes.DateDecision),
        fieldName: "dateDecision"
      }
    ],
    [computeType, t, tUnsafe, theme.dangerColor, theme.sucessColor, updateOffre, updateOffreLoading]
  );

  const getCriteria = React.useCallback(() => api.offreDemandeGetSearchCriterias({ includeListsValues: true }), [api]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      withCard={false}
      getCriteriasFunction={getCriteria}
      searchFunction={searchFunction}
      onAbort={onAbort}
      columns={columns}
      defaultCriterias={[
        {
          criteria: "OffreActive",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
      breadCrumbs={[{ text: t(ETLCodes.Offres) }]}
      sortKeys={{ codeMetier: "ASC" }}
      keyFieldName="idlieuFormationAgrement"
      searchStateInitialSearch={false}
    />
  );
};
