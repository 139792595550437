import { Card, Spinner } from "@blueprintjs/core";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { AdresseDto, PaysApi } from "../../../api";
import { useApiService } from "../../../hooks";

const StyledCard = styled(Card)`
  padding: 10px !important;
`;

export interface IAdresseInfosProps {
  data: AdresseDto;
  withCard?: boolean;
}

export const AdresseInfos: React.FunctionComponent<IAdresseInfosProps> = ({ data, withCard = false }) => {
  const api = useApiService(PaysApi);
  const fetchPays = React.useCallback(async () => {
    if (!data?.pays) return null;
    return await api.paysGetDisplayName({ id: data.pays });
  }, [api, data?.pays]);
  const { data: pays, isFetching: pLoading } = useQuery(["pays-display-name", data?.pays], fetchPays);

  const content = React.useMemo(
    () => (
      <>
        {data.adresse}
        <br />
        {data.localite && (
          <>
            {data.localite}
            <br />
          </>
        )}
        {pLoading ? <Spinner size={16} /> : pays ?? data.pays}
        {data.typeAdresse && (
          <>
            <br />
            {data.typeAdresse}
          </>
        )}
      </>
    ),
    [data.adresse, data.localite, data.pays, data.typeAdresse, pLoading, pays]
  );

  return withCard ? <StyledCard>{content}</StyledCard> : <div>{content}</div>;
};
