/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRecherchePlanLocalisationDto
 */
export interface FcbRecherchePlanLocalisationDto {
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchePlanLocalisationDto
     */
    idplan?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanLocalisationDto
     */
    anneeAcademique?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanLocalisationDto
     */
    centre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanLocalisationDto
     */
    dateValidation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanLocalisationDto
     */
    statutLocalisationAgrement?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchePlanLocalisationDto
     */
    iduser?: number | null;
}

/**
 * Check if a given object implements the FcbRecherchePlanLocalisationDto interface.
 */
export function instanceOfFcbRecherchePlanLocalisationDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRecherchePlanLocalisationDtoFromJSON(json: any): FcbRecherchePlanLocalisationDto {
    return FcbRecherchePlanLocalisationDtoFromJSONTyped(json, false);
}

export function FcbRecherchePlanLocalisationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRecherchePlanLocalisationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idplan': !exists(json, 'idplan') ? undefined : json['idplan'],
        'anneeAcademique': !exists(json, 'anneeAcademique') ? undefined : json['anneeAcademique'],
        'centre': !exists(json, 'centre') ? undefined : json['centre'],
        'dateValidation': !exists(json, 'dateValidation') ? undefined : json['dateValidation'],
        'statutLocalisationAgrement': !exists(json, 'statutLocalisationAgrement') ? undefined : json['statutLocalisationAgrement'],
        'iduser': !exists(json, 'iduser') ? undefined : json['iduser'],
    };
}

export function FcbRecherchePlanLocalisationDtoToJSON(value?: FcbRecherchePlanLocalisationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idplan': value.idplan,
        'anneeAcademique': value.anneeAcademique,
        'centre': value.centre,
        'dateValidation': value.dateValidation,
        'statutLocalisationAgrement': value.statutLocalisationAgrement,
        'iduser': value.iduser,
    };
}

