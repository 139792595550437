import { Button, Card } from "@blueprintjs/core";
import {
  ButtonContainer,
  ButtonsBloc,
  FGCustomPanel,
  FGTextAreaInput,
  FGTextInput,
  FieldGroup,
  IDataTableColumn,
  InlineButtonContainer
} from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import { css } from "styled-components";
import * as Yup from "yup";

import {
  ClasseApi,
  EIfapmeSide,
  FcbPlanLocalisationDtoFromJSON,
  FcbRecherchePlanLocalisationClasseDto,
  PlanLocalisationApi,
  PlanLocalisationClasseApi,
  PlanLocalisationClasseSearch
} from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import {
  CancelButton,
  DeleteButton,
  EditButton,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  SaveButton,
  SearchTablePage,
  SmallFormGenerator,
  TableCheckboxEditor,
  TableGlobalDateEditor,
  TableGlobalSelectEditor,
  TableGlobalTextEditor
} from "../../../../components";
import { useAuth, useDialog, useEventsContext } from "../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useCrudApi, useTheme, useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";
import { ETLCodes } from "../../../../locales";

export interface ILocalisationClasseDetailPageProps {}

export const LocalisationClasseDetailPage: React.FunctionComponent<ILocalisationClasseDetailPageProps> = () => {
  const { t } = useTl();
  const { id, state = "view" } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();
  const api = useApiService(PlanLocalisationApi);
  const plClasseApi = useApiService(PlanLocalisationClasseApi);
  const classeApi = useApiService(ClasseApi);
  const { getCurrentPermission, user } = useAuth();
  const canDelete = React.useMemo(() => getCurrentPermission()?.permission === "RWD", [getCurrentPermission]);
  const canLock = React.useMemo(() => user?.idlevel === 4 || user?.idlevel === 21 || user?.idlevel === 2, [user]);
  const { showDialog } = useDialog();

  const { dispatchEvent } = useEventsContext();

  const { data, loading, deleteItem, deleting, saveItem, saving, refresh } = useCrudApi({
    getApiFn: () => (+id <= 0 ? FcbPlanLocalisationDtoFromJSON({ idPlan: 0 }) : api.planLocalisationGet({ id: +id })),
    saveApiFn: d => api.planLocalisationSave({ FcbPlanLocalisationDto: d }),
    deleteApiFn: d => api.planLocalisationDelete({ id: d.idPlan }),
    onDeletedRoute: () => ERoutes.localisationClasse,
    onSavedRoute: d => `${ERoutes.localisationClasse}/${d.idPlan}/detail/view`
  });

  const readonly = React.useMemo(() => state === "view" || data?.idStatut === 3, [data?.idStatut, state]);

  const [annee, aLoading] = useReferential(a => a.referentialGetAnneeScolaire(), false, [], true);
  const [centre, cLoading] = useReferential(a => a.referentialGetCentre(), true, [], true);
  const [statut, sLoading] = useReferential(
    a => a.referentialGetStatutLocalisationAgrement({ isLocalisation: true }),
    true,
    [],
    true
  );
  const [conseillerPeda, cpLoading] = useReferential(a => a.referentialGetConseillersPedagogiquesCodes(), false, []);
  const [genre, gLoading] = useReferential(a => a.referentialGetGenreClasseCodes(), false, [], true);
  const [degre, dLoading] = useReferential(a => a.referentialGetDegreCodes(), false, [], true);
  const [metier, mLoading] = useReferential(a => a.referentialGetMetierCodes(), true, [], true);
  const { ifapmeSide } = useTheme();
  const [typeCours, tcLoading] = useReferential(
    a => a.referentialGetTypeCoursCodes({ ifapmeSide: ifapmeSide === "hope" ? EIfapmeSide.Hope : EIfapmeSide.Walter }),
    false,
    [ifapmeSide]
  );
  const [particularite, pLoading] = useReferential(a => a.referentialGetSpecificiteCoursCodes(), false, [], true);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idcentre: Yup.number().required(t(ETLCodes.Required)),
      anneeAcademique: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  const isValidated = React.useMemo(() => +data?.idStatut === +statut?.find(s => s.label === "Validé")?.value, [
    data,
    statut
  ]);

  const saveRow = React.useCallback(
    async (row: FcbRecherchePlanLocalisationClasseDto) => {
      await plClasseApi.planLocalisationClasseSave({ FcbRecherchePlanLocalisationClasseDto: row });
    },
    [plClasseApi]
  );

  const deleteClasse = React.useCallback(
    async (row: FcbRecherchePlanLocalisationClasseDto) => {
      showDialog({
        message: t(ETLCodes.DeleteConfirmationMessage),
        title: t(ETLCodes.DeleteDialogTitle),
        onConfirmed: async () => {
          await classeApi.classeDelete({ id: row.idClasse });
          dispatchEvent("SEARCH_TABLE_REFRESH");
        }
      });
    },
    [classeApi, dispatchEvent, showDialog, t]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbRecherchePlanLocalisationClasseDto) => (
          <ButtonContainer>
            {!readonly && <DeleteButton minimal={true} onDelete={() => deleteClasse(row)} />}
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.classe}/${row.idClasse}/agrement/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => "",
        fieldName: "idStatutLocalisation",
        alignment: "center",
        customizeCellStyle: () =>
          css({
            padding: "2px !important",
            width: "30px",
            verticalAlign: "middle !important"
          }),
        render: (row: FcbRecherchePlanLocalisationClasseDto) => (
          <TableCheckboxEditor
            initialValue={row.idStatutLocalisation}
            onValueChanged={value => saveRow({ ...row, idStatutLocalisation: value })}
            readonly={readonly}
          />
        )
      },
      {
        header: () => t(ETLCodes.Conseiller),
        customizeCellStyle: () =>
          css({
            padding: "2px !important",
            width: "100px"
          }),
        fieldName: "codeConseiller",
        render: (row: FcbRecherchePlanLocalisationClasseDto) => (
          <TableGlobalSelectEditor
            initialValue={row.codeConseiller}
            onValueChanged={value => saveRow({ ...row, codeConseiller: value?.toString() })}
            items={conseillerPeda}
            loading={cpLoading}
            readonly={readonly}
          ></TableGlobalSelectEditor>
        )
      },
      {
        header: () => t(ETLCodes.Classe),
        customizeCellStyle: () =>
          css({
            padding: "2px !important",
            width: "350px"
          }),
        fieldName: "nom",
        render: (row: FcbRecherchePlanLocalisationClasseDto) => (
          <TableGlobalTextEditor
            initialTextValue={row.nom}
            onValueChanged={value => saveRow({ ...row, nom: value })}
            textEditorHeight={20}
            maxLength={50}
            readonly={readonly}
          ></TableGlobalTextEditor>
        )
      },
      {
        header: () => t(ETLCodes.Metier),
        customizeCellStyle: () =>
          css({
            padding: "2px !important",
            width: "150px"
          }),
        fieldName: "codeMetier",
        autoFitContent: true,
        render: (row: FcbRecherchePlanLocalisationClasseDto) => (
          <TableGlobalSelectEditor
            initialValue={row.idMetier}
            onValueChanged={value => saveRow({ ...row, idMetier: value ? +value : null, idReferentiel: null })}
            items={metier}
            loading={mLoading}
            readonly={readonly}
          ></TableGlobalSelectEditor>
        )
      },
      {
        header: () => t(ETLCodes.Degre),
        customizeCellStyle: () =>
          css({
            padding: "2px !important",
            width: "50px"
          }),
        fieldName: "idDegre",
        render: (row: FcbRecherchePlanLocalisationClasseDto) => (
          <TableGlobalSelectEditor
            initialValue={row.idDegre}
            onValueChanged={value =>
              saveRow({ ...row, idDegre: value?.toString(), idMetier: null, idReferentiel: null })
            }
            items={degre}
            loading={dLoading}
            readonly={readonly}
          ></TableGlobalSelectEditor>
        )
      },
      {
        header: () => t(ETLCodes.Type),
        customizeCellStyle: () =>
          css({
            padding: "2px !important",
            width: "50px"
          }),
        fieldName: "typeCours",
        render: (row: FcbRecherchePlanLocalisationClasseDto) => (
          <TableGlobalSelectEditor
            initialValue={row.typeCours}
            onValueChanged={value => saveRow({ ...row, typeCours: value?.toString() })}
            items={typeCours}
            loading={tcLoading}
            readonly={readonly}
          ></TableGlobalSelectEditor>
        )
      },
      {
        header: () => t(ETLCodes.Spec),
        customizeCellStyle: () =>
          css({
            padding: "2px !important",
            width: "50px"
          }),
        fieldName: "specificite",
        render: (row: FcbRecherchePlanLocalisationClasseDto) => (
          <TableGlobalSelectEditor
            initialValue={row.specificite}
            onValueChanged={value => saveRow({ ...row, specificite: value?.toString() })}
            items={particularite}
            loading={pLoading}
            readonly={readonly}
          ></TableGlobalSelectEditor>
        )
      },
      {
        header: () => t(ETLCodes.Genre),
        customizeCellStyle: () =>
          css({
            padding: "2px !important",
            width: "50px"
          }),
        fieldName: "genre",
        render: (row: FcbRecherchePlanLocalisationClasseDto) => (
          <TableGlobalSelectEditor
            initialValue={row.genre}
            onValueChanged={value => saveRow({ ...row, genre: value?.toString() })}
            items={genre}
            loading={gLoading}
            readonly={readonly}
          ></TableGlobalSelectEditor>
        )
      },
      {
        header: () => t(ETLCodes.Referentiel),
        customizeCellStyle: () =>
          css({
            padding: "2px !important"
          }),
        fieldName: "codeReferentiel"
      },
      {
        header: () => t(ETLCodes.Financement),
        fieldName: "financement",
        customizeCellStyle: () =>
          css`
            white-space: pre;
          `
      },
      {
        header: () => t(ETLCodes.PPB),
        fieldName: "ppb"
      },
      {
        header: () => t(ETLCodes.Nb),
        customizeCellStyle: () =>
          css({
            padding: "2px !important"
          }),
        fieldName: "nbApprenantLocalisation",
        render: (row: FcbRecherchePlanLocalisationClasseDto) => (
          <TableGlobalTextEditor
            initialTextValue={row.nbApprenantLocalisation?.toString()}
            onValueChanged={value => saveRow({ ...row, nbApprenantLocalisation: +value })}
            textEditorHeight={20}
            numeric={true}
            readonly={readonly}
            maxWidth="30px"
          ></TableGlobalTextEditor>
        )
      },
      {
        header: () => t(ETLCodes.PremierCours),
        customizeCellStyle: () =>
          css({
            padding: "2px !important",
            width: "100px"
          }),
        fieldName: "dateCours",
        render: (row: FcbRecherchePlanLocalisationClasseDto) => (
          <TableGlobalDateEditor
            initialTextValue={row.dateCours?.toString()}
            onValueChanged={value => saveRow({ ...row, dateCours: value })}
            textEditorHeight={20}
            readonly={readonly}
          ></TableGlobalDateEditor>
        )
      },
      {
        header: () => t(ETLCodes.RemarqueCentre),
        customizeCellStyle: () =>
          css({
            padding: "2px !important"
          }),
        fieldName: "remarqueCentre",
        render: (row: FcbRecherchePlanLocalisationClasseDto) => (
          <TableGlobalTextEditor
            initialTextValue={row.remarqueCentre}
            onValueChanged={value => saveRow({ ...row, remarqueCentre: value })}
            textEditorHeight={20}
            readonly={readonly}
          ></TableGlobalTextEditor>
        )
      },
      {
        header: () => t(ETLCodes.GestionInterne),
        customizeCellStyle: () =>
          css({
            padding: "2px !important"
          }),
        fieldName: "gestionInterne",
        render: (row: FcbRecherchePlanLocalisationClasseDto) => (
          <TableGlobalTextEditor
            initialTextValue={row.gestionInterne}
            onValueChanged={value => saveRow({ ...row, gestionInterne: value })}
            textEditorHeight={20}
            readonly={readonly}
          ></TableGlobalTextEditor>
        )
      },
      {
        header: () => t(ETLCodes.RemarqueConseiller),
        customizeCellStyle: () =>
          css({
            padding: "2px !important"
          }),
        fieldName: "remarqueConseiller",
        render: (row: FcbRecherchePlanLocalisationClasseDto) => (
          <TableGlobalTextEditor
            initialTextValue={row.remarqueConseiller}
            onValueChanged={value => saveRow({ ...row, remarqueConseiller: value })}
            textEditorHeight={20}
            readonly={readonly}
          ></TableGlobalTextEditor>
        )
      }
    ],
    [
      conseillerPeda,
      cpLoading,
      dLoading,
      degre,
      deleteClasse,
      gLoading,
      genre,
      history,
      mLoading,
      metier,
      pLoading,
      particularite,
      readonly,
      saveRow,
      t,
      tcLoading,
      typeCours
    ]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.classe}/0/detail/edit`);
  }, [history]);

  const [editMode, setEditMode] = React.useState<boolean>(state === "edit");

  const ButtonsMemo = React.useMemo(() => {
    if (data?.idStatut === 1) {
      return (
        <Button
          text={t(ETLCodes.ReadyToValidate)}
          onClick={async () => {
            await api.planLocalisationToValidatePlan({ idPlan: +id });
            refresh();
          }}
        />
      );
    } else if (data?.idStatut === 2 && canLock) {
      return (
        <Button
          text={t(ETLCodes.Validate)}
          onClick={async () => {
            await api.planLocalisationValidatePlan({ idPlan: +id });
            refresh();
          }}
          disabled={!data?.dateValidation}
        />
      );
    } else if (data?.idStatut === 3 && canLock && data?.bloque) {
      return (
        <Button
          text={t(ETLCodes.Unlock)}
          onClick={async () => {
            await api.planLocalisationUnlockClasse({ idPlan: +id });
            refresh();
          }}
        />
      );
    } else if (data?.idStatut === 3 && canLock && !data?.bloque) {
      return (
        <Button
          text={t(ETLCodes.Lock)}
          onClick={async () => {
            await api.planLocalisationLockClasse({ idPlan: +id });
            refresh();
          }}
        />
      );
    } else {
      return <></>;
    }
  }, [api, canLock, data, id, refresh, t]);

  const getCriterias = React.useCallback(
    () => plClasseApi.planLocalisationClasseGetSearchCriterias({ includeListsValues: true }),
    [plClasseApi]
  );

  const apiFactory = useAbortableApiServiceFactory(PlanLocalisationClasseApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (sObj?: PlanLocalisationClasseSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.planLocalisationClasseBaseSearch({
        PlanLocalisationClasseSearch: { idPlanLocalisation: +id, ...sObj }
      });
    },
    [apiFactory, id]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <div style={{ padding: "0.5em" }}>
        <Card>
          <SmallFormGenerator
            initialValues={data}
            onSubmit={saveItem}
            saving={saving}
            deleting={deleting}
            onDelete={deleteItem}
            loading={loading}
            editMode={editMode}
            onCancel={() => history.push(`${ERoutes.localisationClasse}`)}
            validationSchema={FormSchema}
            hideButtons={true}
          >
            <FieldGroup columns={3}>
              <FGWalterSelectInput
                label={t(ETLCodes.AnneeScolaire)}
                items={annee}
                loading={aLoading}
                name="anneeAcademique"
                readonly={+id > 0}
              />
              <FGWalterSelectInput
                label={t(ETLCodes.Centre)}
                items={centre}
                loading={cLoading}
                name="idCentre"
                readonly={+id > 0}
              />
              <FGWalterDateMaskInput
                label={t(ETLCodes.DateLocalisation)}
                name="dateValidation"
                readonly={data?.idStatut !== 2}
              />
            </FieldGroup>
            <FieldGroup columns={[4, 8]}>
              <FGWalterSelectInput
                label={t(ETLCodes.Statut)}
                items={statut}
                loading={sLoading}
                name="idStatut"
                readonly
              />
              <FGTextInput label={t(ETLCodes.URL)} readonly name="url" maxLength={200} />
            </FieldGroup>
            <FieldGroup>
              <FGTextAreaInput label={t(ETLCodes.Remarque)} name="remarque" />
            </FieldGroup>
            <FGCustomPanel>
              {ctx => (
                <InlineButtonContainer>
                  {canDelete ? (
                    <ButtonsBloc>
                      <DeleteButton minimal={false} loading={deleting} onDelete={deleteItem} />
                    </ButtonsBloc>
                  ) : (
                    <></>
                  )}
                  <ButtonsBloc>
                    <CancelButton minimal={false} onClick={() => history.push(`${ERoutes.localisationClasse}`)} />
                    {ButtonsMemo}
                    {editMode ? (
                      <SaveButton
                        minimal={false}
                        loading={saving}
                        disabled={!ctx.formik.dirty}
                        onClick={() => saveItem(ctx.formik.values)}
                      />
                    ) : (
                      <EditButton minimal={false} onClick={() => setEditMode(true)} disabled={isValidated}></EditButton>
                    )}
                  </ButtonsBloc>
                </InlineButtonContainer>
              )}
            </FGCustomPanel>
          </SmallFormGenerator>
        </Card>
      </div>
      <SearchTablePage
        getCriteriasFunction={getCriterias}
        searchFunction={search}
        onAbort={onAbort}
        columns={columns}
        addFunc={isValidated ? null : onAddItem}
        breadCrumbs={[{ text: t(ETLCodes.LocalisationsClasses), route: ERoutes.localisationClasse }]}
        sortKeys={{ idClasse: "ASC" }}
      />
    </>
  );
};
