/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbEcoleDto
 */
export interface FcbEcoleDto {
    /**
     * 
     * @type {number}
     * @memberof FcbEcoleDto
     */
    idecole?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbEcoleDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEcoleDto
     */
    adresse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEcoleDto
     */
    localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEcoleDto
     */
    codePostal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEcoleDto
     */
    commune?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEcoleDto
     */
    codePays?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEcoleDto
     */
    telephone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEcoleDto
     */
    fax?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEcoleDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEcoleDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbEcoleDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbEcoleDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbEcoleDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbEcoleDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEcoleDto
     */
    idecoleGuid?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbEcoleDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbEcoleDto interface.
 */
export function instanceOfFcbEcoleDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbEcoleDtoFromJSON(json: any): FcbEcoleDto {
    return FcbEcoleDtoFromJSONTyped(json, false);
}

export function FcbEcoleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbEcoleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idecole': !exists(json, 'idecole') ? undefined : json['idecole'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'adresse': !exists(json, 'adresse') ? undefined : json['adresse'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'codePostal': !exists(json, 'codePostal') ? undefined : json['codePostal'],
        'commune': !exists(json, 'commune') ? undefined : json['commune'],
        'codePays': !exists(json, 'codePays') ? undefined : json['codePays'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'idecoleGuid': !exists(json, 'idecoleGuid') ? undefined : json['idecoleGuid'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbEcoleDtoToJSON(value?: FcbEcoleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idecole': value.idecole,
        'nom': value.nom,
        'adresse': value.adresse,
        'localite': value.localite,
        'codePostal': value.codePostal,
        'commune': value.commune,
        'codePays': value.codePays,
        'telephone': value.telephone,
        'fax': value.fax,
        'email': value.email,
        'remarque': value.remarque,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'idecoleGuid': value.idecoleGuid,
        'actif': value.actif,
    };
}

