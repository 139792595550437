import { ButtonContainer } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";

import { CodePostalApi, CodePostalSearch, EBooleanSearchTypes, FcbCodePostalGridDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { useAbortableApiServiceFactory, useApiService } from "../../../../../hooks/useApiService";
import { ETLCodes } from "../../../../../locales";

interface ICodePostalListPageProps {}

export const CodePostalListPage: React.FunctionComponent<ICodePostalListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(CodePostalApi);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbCodePostalGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.codePostal}/${row.idcodePostal}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.codePostal}/${row.idcodePostal}/detail/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.CodePostal),
        fieldName: "codePostal",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Commune),
        fieldName: "commune"
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      },
      {
        header: () => t(ETLCodes.Pays),
        fieldName: "pays"
      }
    ],
    [history, t]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.codePostal}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(() => api.codePostalGetSearchCriterias({ includeListsValues: true }), [api]);

  const apiFactory = useAbortableApiServiceFactory(CodePostalApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: CodePostalSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.codePostalBaseSearch({ CodePostalSearch: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      columns={columns}
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
      addFunc={addItemFunction}
      breadCrumbs={[{ text: t(ETLCodes.CodesPostaux) }]}
      sortKeys={{ codePostal: "ASC" }}
    />
  );
};
