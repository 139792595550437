/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  FcbInscriptionDetailDto,
  FcbInscriptionGridDtoPaginatedResults,
  FileDownloadDto,
  FilterCriteriaInfo,
  InscriptionAbandonDto,
  InscriptionFichierDto,
  InscriptionFichierUploadDto,
  InscriptionSearch,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FcbInscriptionDetailDtoFromJSON,
    FcbInscriptionDetailDtoToJSON,
    FcbInscriptionGridDtoPaginatedResultsFromJSON,
    FcbInscriptionGridDtoPaginatedResultsToJSON,
    FileDownloadDtoFromJSON,
    FileDownloadDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    InscriptionAbandonDtoFromJSON,
    InscriptionAbandonDtoToJSON,
    InscriptionFichierDtoFromJSON,
    InscriptionFichierDtoToJSON,
    InscriptionFichierUploadDtoFromJSON,
    InscriptionFichierUploadDtoToJSON,
    InscriptionSearchFromJSON,
    InscriptionSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface InscriptionAbandonRequest {
    InscriptionAbandonDto?: InscriptionAbandonDto;
}

export interface InscriptionBaseSearchRequest {
    InscriptionSearch?: InscriptionSearch;
}

export interface InscriptionCanEditInscriptionRequest {
    idInscription?: number;
    idClasse?: number;
}

export interface InscriptionCheckSoftDeleteRequest {
    id?: number;
}

export interface InscriptionCheckStadesRequest {
    idFormation?: number;
    idClasse?: number;
}

export interface InscriptionComputeDegreFinalRequest {
    idFormation?: number;
    idDegre?: string;
}

export interface InscriptionDeleteRequest {
    id?: number;
}

export interface InscriptionDeleteFichierRequest {
    id?: number;
}

export interface InscriptionDownloadFichierRequest {
    id?: number;
}

export interface InscriptionGetRequest {
    id?: number;
    idapprenant?: number;
    iddemandeInscription?: number;
    iddemandeFormationCrea?: number;
}

export interface InscriptionGetFichierRequest {
    id?: number;
}

export interface InscriptionGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface InscriptionGetSelectItemsRequest {
    searchField: string;
}

export interface InscriptionGetStatutRequest {
    id?: number;
}

export interface InscriptionHasDispenseRequest {
    idInscription?: number;
}

export interface InscriptionSaveRequest {
    FcbInscriptionDetailDto?: FcbInscriptionDetailDto;
}

export interface InscriptionSaveFichierRequest {
    InscriptionFichierDto?: InscriptionFichierDto;
}

export interface InscriptionSearchIdsRequest {
    InscriptionSearch?: InscriptionSearch;
}

export interface InscriptionSoftDeleteRequest {
    id?: number;
}

export interface InscriptionSuspendueByClasseRequest {
    idClasse?: number;
    idApprenant?: number;
}

export interface InscriptionTerminerRequest {
    request_body?: Array<number>;
}

export interface InscriptionUploadFichierRequest {
    InscriptionFichierUploadDto?: InscriptionFichierUploadDto;
}

export interface InscriptionUserSocieteExterneCanEditRequest {
    idInscription?: number;
}

/**
 * 
 */
export class InscriptionApi extends runtime.BaseAPI {

    /**
     */
    async inscriptionAbandonRaw(requestParameters: InscriptionAbandonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription/Abandon`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InscriptionAbandonDtoToJSON(requestParameters.InscriptionAbandonDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async inscriptionAbandon(requestParameters: InscriptionAbandonRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.inscriptionAbandonRaw(requestParameters, initOverrides);
    }

    /**
     */
    async inscriptionBaseSearchRaw(requestParameters: InscriptionBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbInscriptionGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InscriptionSearchToJSON(requestParameters.InscriptionSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbInscriptionGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async inscriptionBaseSearch(requestParameters: InscriptionBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbInscriptionGridDtoPaginatedResults> {
        const response = await this.inscriptionBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionCanEditInscriptionRaw(requestParameters: InscriptionCanEditInscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idInscription !== undefined) {
            queryParameters['idInscription'] = requestParameters.idInscription;
        }

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription/canEditInscription`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async inscriptionCanEditInscription(requestParameters: InscriptionCanEditInscriptionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.inscriptionCanEditInscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionCheckSoftDeleteRaw(requestParameters: InscriptionCheckSoftDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription/CheckSoftDelete`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async inscriptionCheckSoftDelete(requestParameters: InscriptionCheckSoftDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.inscriptionCheckSoftDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionCheckStadesRaw(requestParameters: InscriptionCheckStadesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.idFormation !== undefined) {
            queryParameters['idFormation'] = requestParameters.idFormation;
        }

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription/CheckStades`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async inscriptionCheckStades(requestParameters: InscriptionCheckStadesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.inscriptionCheckStadesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionComputeDegreFinalRaw(requestParameters: InscriptionComputeDegreFinalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idFormation !== undefined) {
            queryParameters['idFormation'] = requestParameters.idFormation;
        }

        if (requestParameters.idDegre !== undefined) {
            queryParameters['idDegre'] = requestParameters.idDegre;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription/ComputeDegreFinal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async inscriptionComputeDegreFinal(requestParameters: InscriptionComputeDegreFinalRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.inscriptionComputeDegreFinalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionDeleteRaw(requestParameters: InscriptionDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async inscriptionDelete(requestParameters: InscriptionDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.inscriptionDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionDeleteFichierRaw(requestParameters: InscriptionDeleteFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription/DeleteFichier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async inscriptionDeleteFichier(requestParameters: InscriptionDeleteFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.inscriptionDeleteFichierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async inscriptionDownloadFichierRaw(requestParameters: InscriptionDownloadFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription/DownloadFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async inscriptionDownloadFichier(requestParameters: InscriptionDownloadFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.inscriptionDownloadFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionGetRaw(requestParameters: InscriptionGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbInscriptionDetailDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        if (requestParameters.iddemandeInscription !== undefined) {
            queryParameters['iddemandeInscription'] = requestParameters.iddemandeInscription;
        }

        if (requestParameters.iddemandeFormationCrea !== undefined) {
            queryParameters['iddemandeFormationCrea'] = requestParameters.iddemandeFormationCrea;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbInscriptionDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async inscriptionGet(requestParameters: InscriptionGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbInscriptionDetailDto> {
        const response = await this.inscriptionGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionGetFichierRaw(requestParameters: InscriptionGetFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InscriptionFichierDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription/GetFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InscriptionFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async inscriptionGetFichier(requestParameters: InscriptionGetFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InscriptionFichierDto> {
        const response = await this.inscriptionGetFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionGetSearchCriteriasRaw(requestParameters: InscriptionGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async inscriptionGetSearchCriterias(requestParameters: InscriptionGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.inscriptionGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionGetSelectItemsRaw(requestParameters: InscriptionGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling inscriptionGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async inscriptionGetSelectItems(requestParameters: InscriptionGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.inscriptionGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionGetStatutRaw(requestParameters: InscriptionGetStatutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription/GetStatut`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async inscriptionGetStatut(requestParameters: InscriptionGetStatutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.inscriptionGetStatutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionHasDispenseRaw(requestParameters: InscriptionHasDispenseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idInscription !== undefined) {
            queryParameters['idInscription'] = requestParameters.idInscription;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription/HasDispense`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async inscriptionHasDispense(requestParameters: InscriptionHasDispenseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.inscriptionHasDispenseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionSaveRaw(requestParameters: InscriptionSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbInscriptionDetailDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbInscriptionDetailDtoToJSON(requestParameters.FcbInscriptionDetailDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbInscriptionDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async inscriptionSave(requestParameters: InscriptionSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbInscriptionDetailDto> {
        const response = await this.inscriptionSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionSaveFichierRaw(requestParameters: InscriptionSaveFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InscriptionFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription/SaveFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InscriptionFichierDtoToJSON(requestParameters.InscriptionFichierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InscriptionFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async inscriptionSaveFichier(requestParameters: InscriptionSaveFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InscriptionFichierDto> {
        const response = await this.inscriptionSaveFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionSearchIdsRaw(requestParameters: InscriptionSearchIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription/SearchIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InscriptionSearchToJSON(requestParameters.InscriptionSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async inscriptionSearchIds(requestParameters: InscriptionSearchIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.inscriptionSearchIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionSoftDeleteRaw(requestParameters: InscriptionSoftDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription/SoftDelete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async inscriptionSoftDelete(requestParameters: InscriptionSoftDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.inscriptionSoftDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionSuspendueByClasseRaw(requestParameters: InscriptionSuspendueByClasseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        if (requestParameters.idApprenant !== undefined) {
            queryParameters['idApprenant'] = requestParameters.idApprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription/SuspendueByClasse`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async inscriptionSuspendueByClasse(requestParameters: InscriptionSuspendueByClasseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.inscriptionSuspendueByClasseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionTerminerRaw(requestParameters: InscriptionTerminerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription/Terminer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request_body,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async inscriptionTerminer(requestParameters: InscriptionTerminerRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.inscriptionTerminerRaw(requestParameters, initOverrides);
    }

    /**
     */
    async inscriptionUploadFichierRaw(requestParameters: InscriptionUploadFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InscriptionFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription/UploadFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InscriptionFichierUploadDtoToJSON(requestParameters.InscriptionFichierUploadDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InscriptionFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async inscriptionUploadFichier(requestParameters: InscriptionUploadFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InscriptionFichierDto> {
        const response = await this.inscriptionUploadFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionUserSocieteExterneCanEditRaw(requestParameters: InscriptionUserSocieteExterneCanEditRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idInscription !== undefined) {
            queryParameters['idInscription'] = requestParameters.idInscription;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Inscription/UserSocieteExterneCanEdit`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async inscriptionUserSocieteExterneCanEdit(requestParameters: InscriptionUserSocieteExterneCanEditRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.inscriptionUserSocieteExterneCanEditRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
