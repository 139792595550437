/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbCopyClasseDto
 */
export interface FcbCopyClasseDto {
    /**
     * 
     * @type {number}
     * @memberof FcbCopyClasseDto
     */
    idClasse?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbCopyClasseDto
     */
    dateCours?: Date;
    /**
     * 
     * @type {string}
     * @memberof FcbCopyClasseDto
     */
    nomClasse?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbCopyClasseDto
     */
    idUser?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FcbCopyClasseDto
     */
    withContract?: boolean;
}

/**
 * Check if a given object implements the FcbCopyClasseDto interface.
 */
export function instanceOfFcbCopyClasseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbCopyClasseDtoFromJSON(json: any): FcbCopyClasseDto {
    return FcbCopyClasseDtoFromJSONTyped(json, false);
}

export function FcbCopyClasseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbCopyClasseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idClasse': !exists(json, 'idClasse') ? undefined : json['idClasse'],
        'dateCours': !exists(json, 'dateCours') ? undefined : (new Date(json['dateCours'])),
        'nomClasse': !exists(json, 'nomClasse') ? undefined : json['nomClasse'],
        'idUser': !exists(json, 'idUser') ? undefined : json['idUser'],
        'withContract': !exists(json, 'withContract') ? undefined : json['withContract'],
    };
}

export function FcbCopyClasseDtoToJSON(value?: FcbCopyClasseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idClasse': value.idClasse,
        'dateCours': value.dateCours === undefined ? undefined : (value.dateCours.toISOString()),
        'nomClasse': value.nomClasse,
        'idUser': value.idUser,
        'withContract': value.withContract,
    };
}

