/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BulletinDeliberationNavigationDto,
  DeliberationTypeSearch,
  DeliberationValidateIdsDto,
  FcbBulletinApprenantDto,
  FcbBulletinApprenantTypeDetailDto,
  FcbRechercheApprenantDeliberationTypeDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    BulletinDeliberationNavigationDtoFromJSON,
    BulletinDeliberationNavigationDtoToJSON,
    DeliberationTypeSearchFromJSON,
    DeliberationTypeSearchToJSON,
    DeliberationValidateIdsDtoFromJSON,
    DeliberationValidateIdsDtoToJSON,
    FcbBulletinApprenantDtoFromJSON,
    FcbBulletinApprenantDtoToJSON,
    FcbBulletinApprenantTypeDetailDtoFromJSON,
    FcbBulletinApprenantTypeDetailDtoToJSON,
    FcbRechercheApprenantDeliberationTypeDtoPaginatedResultsFromJSON,
    FcbRechercheApprenantDeliberationTypeDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface DeliberationTypeBaseSearchRequest {
    DeliberationTypeSearch?: DeliberationTypeSearch;
}

export interface DeliberationTypeDeleteRequest {
    id?: number;
}

export interface DeliberationTypeGetRequest {
    id?: number;
}

export interface DeliberationTypeGetAnneesInscriptionsRequest {
    id?: number;
}

export interface DeliberationTypeGetAvisResultatsIdsFichierRequest {
    sess2?: boolean;
    DeliberationTypeSearch?: DeliberationTypeSearch;
}

export interface DeliberationTypeGetDisplayNameRequest {
    id?: number;
}

export interface DeliberationTypeGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface DeliberationTypeGetSelectItemsRequest {
    searchField: string;
}

export interface DeliberationTypeInvalidateBulletinsRequest {
    DeliberationValidateIdsDto?: DeliberationValidateIdsDto;
}

export interface DeliberationTypeSaveRequest {
    FcbBulletinApprenantDto?: FcbBulletinApprenantDto;
}

export interface DeliberationTypeSearchDeliberationIdsRequest {
    DeliberationTypeSearch?: DeliberationTypeSearch;
}

export interface DeliberationTypeSearchIdsRequest {
    DeliberationTypeSearch?: DeliberationTypeSearch;
}

export interface DeliberationTypeSendToPrintingQueueRequest {
    generate2Sess?: boolean;
    validate?: boolean;
    DeliberationTypeSearch?: DeliberationTypeSearch;
}

export interface DeliberationTypeValidateBulletinsRequest {
    DeliberationValidateIdsDto?: DeliberationValidateIdsDto;
}

/**
 * 
 */
export class DeliberationTypeApi extends runtime.BaseAPI {

    /**
     */
    async deliberationTypeBaseSearchRaw(requestParameters: DeliberationTypeBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRechercheApprenantDeliberationTypeDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DeliberationType/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeliberationTypeSearchToJSON(requestParameters.DeliberationTypeSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRechercheApprenantDeliberationTypeDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async deliberationTypeBaseSearch(requestParameters: DeliberationTypeBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRechercheApprenantDeliberationTypeDtoPaginatedResults> {
        const response = await this.deliberationTypeBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deliberationTypeDeleteRaw(requestParameters: DeliberationTypeDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DeliberationType`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deliberationTypeDelete(requestParameters: DeliberationTypeDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.deliberationTypeDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deliberationTypeGetRaw(requestParameters: DeliberationTypeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbBulletinApprenantTypeDetailDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DeliberationType`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbBulletinApprenantTypeDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async deliberationTypeGet(requestParameters: DeliberationTypeGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbBulletinApprenantTypeDetailDto> {
        const response = await this.deliberationTypeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deliberationTypeGetAnneesInscriptionsRaw(requestParameters: DeliberationTypeGetAnneesInscriptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DeliberationType/GetAnneesInscriptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async deliberationTypeGetAnneesInscriptions(requestParameters: DeliberationTypeGetAnneesInscriptionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.deliberationTypeGetAnneesInscriptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deliberationTypeGetAvisResultatsIdsFichierRaw(requestParameters: DeliberationTypeGetAvisResultatsIdsFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        if (requestParameters.sess2 !== undefined) {
            queryParameters['sess2'] = requestParameters.sess2;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DeliberationType/GetAvisResultatsIdsFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeliberationTypeSearchToJSON(requestParameters.DeliberationTypeSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async deliberationTypeGetAvisResultatsIdsFichier(requestParameters: DeliberationTypeGetAvisResultatsIdsFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.deliberationTypeGetAvisResultatsIdsFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deliberationTypeGetDisplayNameRaw(requestParameters: DeliberationTypeGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DeliberationType/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deliberationTypeGetDisplayName(requestParameters: DeliberationTypeGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deliberationTypeGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deliberationTypeGetSearchCriteriasRaw(requestParameters: DeliberationTypeGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DeliberationType/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async deliberationTypeGetSearchCriterias(requestParameters: DeliberationTypeGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.deliberationTypeGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deliberationTypeGetSelectItemsRaw(requestParameters: DeliberationTypeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling deliberationTypeGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DeliberationType/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async deliberationTypeGetSelectItems(requestParameters: DeliberationTypeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.deliberationTypeGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deliberationTypeInvalidateBulletinsRaw(requestParameters: DeliberationTypeInvalidateBulletinsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DeliberationType/InvalidateBulletins`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeliberationValidateIdsDtoToJSON(requestParameters.DeliberationValidateIdsDto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deliberationTypeInvalidateBulletins(requestParameters: DeliberationTypeInvalidateBulletinsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.deliberationTypeInvalidateBulletinsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deliberationTypeSaveRaw(requestParameters: DeliberationTypeSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbBulletinApprenantDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DeliberationType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbBulletinApprenantDtoToJSON(requestParameters.FcbBulletinApprenantDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbBulletinApprenantDtoFromJSON(jsonValue));
    }

    /**
     */
    async deliberationTypeSave(requestParameters: DeliberationTypeSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbBulletinApprenantDto> {
        const response = await this.deliberationTypeSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deliberationTypeSearchDeliberationIdsRaw(requestParameters: DeliberationTypeSearchDeliberationIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeliberationValidateIdsDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DeliberationType/SearchDeliberationIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeliberationTypeSearchToJSON(requestParameters.DeliberationTypeSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeliberationValidateIdsDtoFromJSON(jsonValue));
    }

    /**
     */
    async deliberationTypeSearchDeliberationIds(requestParameters: DeliberationTypeSearchDeliberationIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeliberationValidateIdsDto> {
        const response = await this.deliberationTypeSearchDeliberationIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deliberationTypeSearchIdsRaw(requestParameters: DeliberationTypeSearchIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BulletinDeliberationNavigationDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DeliberationType/searchIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeliberationTypeSearchToJSON(requestParameters.DeliberationTypeSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BulletinDeliberationNavigationDtoFromJSON));
    }

    /**
     */
    async deliberationTypeSearchIds(requestParameters: DeliberationTypeSearchIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BulletinDeliberationNavigationDto>> {
        const response = await this.deliberationTypeSearchIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deliberationTypeSendToPrintingQueueRaw(requestParameters: DeliberationTypeSendToPrintingQueueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.generate2Sess !== undefined) {
            queryParameters['generate2Sess'] = requestParameters.generate2Sess;
        }

        if (requestParameters.validate !== undefined) {
            queryParameters['validate'] = requestParameters.validate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DeliberationType/SendToPrintingQueue`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeliberationTypeSearchToJSON(requestParameters.DeliberationTypeSearch),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deliberationTypeSendToPrintingQueue(requestParameters: DeliberationTypeSendToPrintingQueueRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deliberationTypeSendToPrintingQueueRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deliberationTypeValidateBulletinsRaw(requestParameters: DeliberationTypeValidateBulletinsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DeliberationType/ValidateBulletins`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeliberationValidateIdsDtoToJSON(requestParameters.DeliberationValidateIdsDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deliberationTypeValidateBulletins(requestParameters: DeliberationTypeValidateBulletinsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deliberationTypeValidateBulletinsRaw(requestParameters, initOverrides);
    }

}
