import { Dialog } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";
import { IInstitutionSelectorProps, InstitutionSelector } from ".";
import { useTl } from "../../../hooks";
import { ETLCodes } from "../../../locales";

const StyledDialog = styled(Dialog)`
  width: 1100px;
  height: auto;
  background-color: white;
`;

export interface IInstitutionSelectorDialogProps extends IInstitutionSelectorProps {
  onClosed: (selectedInstitutionIds?: number[]) => void;
  selectorDialogOpen: boolean;
}

export const InstitutionSelectorDialog: React.FunctionComponent<IInstitutionSelectorDialogProps> = ({
  onClosed,
  selectorDialogOpen,
  ...otherProps
}) => {
  const { t } = useTl();

  return (
    <StyledDialog
      title={t(ETLCodes.SearchInstitutions)}
      isOpen={selectorDialogOpen}
      enforceFocus={false}
      onClose={() => onClosed(null)}
    >
      <InstitutionSelector {...otherProps} />
    </StyledDialog>
  );
};
