/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParcoursFormationDto
 */
export interface ParcoursFormationDto {
    /**
     * 
     * @type {number}
     * @memberof ParcoursFormationDto
     */
    idparcoursFormation?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcoursFormationDto
     */
    idapprenant?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParcoursFormationDto
     */
    idstatutEntree?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ParcoursFormationDto
     */
    dateEntree?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ParcoursFormationDto
     */
    lastDateSortie?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ParcoursFormationDto
     */
    dateEntreeReseau?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ParcoursFormationDto
     */
    idStatutSocialEntree?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParcoursFormationDto
     */
    idSousStatutEntree?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParcoursFormationDto
     */
    idstatutSortie?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ParcoursFormationDto
     */
    dateSortie?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ParcoursFormationDto
     */
    idStatutSocialSortie?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParcoursFormationDto
     */
    idSousStatutSortie?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParcoursFormationDto
     */
    idtypeSortie?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParcoursFormationDto
     */
    idMotifSortie?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ParcoursFormationDto
     */
    isSortieEnCours?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParcoursFormationDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof ParcoursFormationDto
     */
    creationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ParcoursFormationDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ParcoursFormationDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ParcoursFormationDto
     */
    iddureeInoccupation?: number | null;
}

/**
 * Check if a given object implements the ParcoursFormationDto interface.
 */
export function instanceOfParcoursFormationDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ParcoursFormationDtoFromJSON(json: any): ParcoursFormationDto {
    return ParcoursFormationDtoFromJSONTyped(json, false);
}

export function ParcoursFormationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParcoursFormationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idparcoursFormation': !exists(json, 'idparcoursFormation') ? undefined : json['idparcoursFormation'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idstatutEntree': !exists(json, 'idstatutEntree') ? undefined : json['idstatutEntree'],
        'dateEntree': !exists(json, 'dateEntree') ? undefined : (json['dateEntree'] === null ? null : new Date(json['dateEntree'])),
        'lastDateSortie': !exists(json, 'lastDateSortie') ? undefined : (json['lastDateSortie'] === null ? null : new Date(json['lastDateSortie'])),
        'dateEntreeReseau': !exists(json, 'dateEntreeReseau') ? undefined : (json['dateEntreeReseau'] === null ? null : new Date(json['dateEntreeReseau'])),
        'idStatutSocialEntree': !exists(json, 'idStatutSocialEntree') ? undefined : json['idStatutSocialEntree'],
        'idSousStatutEntree': !exists(json, 'idSousStatutEntree') ? undefined : json['idSousStatutEntree'],
        'idstatutSortie': !exists(json, 'idstatutSortie') ? undefined : json['idstatutSortie'],
        'dateSortie': !exists(json, 'dateSortie') ? undefined : (json['dateSortie'] === null ? null : new Date(json['dateSortie'])),
        'idStatutSocialSortie': !exists(json, 'idStatutSocialSortie') ? undefined : json['idStatutSocialSortie'],
        'idSousStatutSortie': !exists(json, 'idSousStatutSortie') ? undefined : json['idSousStatutSortie'],
        'idtypeSortie': !exists(json, 'idtypeSortie') ? undefined : json['idtypeSortie'],
        'idMotifSortie': !exists(json, 'idMotifSortie') ? undefined : json['idMotifSortie'],
        'isSortieEnCours': !exists(json, 'isSortieEnCours') ? undefined : json['isSortieEnCours'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'iddureeInoccupation': !exists(json, 'iddureeInoccupation') ? undefined : json['iddureeInoccupation'],
    };
}

export function ParcoursFormationDtoToJSON(value?: ParcoursFormationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idparcoursFormation': value.idparcoursFormation,
        'idapprenant': value.idapprenant,
        'idstatutEntree': value.idstatutEntree,
        'dateEntree': value.dateEntree === undefined ? undefined : (value.dateEntree === null ? null : value.dateEntree.toISOString()),
        'lastDateSortie': value.lastDateSortie === undefined ? undefined : (value.lastDateSortie === null ? null : value.lastDateSortie.toISOString()),
        'dateEntreeReseau': value.dateEntreeReseau === undefined ? undefined : (value.dateEntreeReseau === null ? null : value.dateEntreeReseau.toISOString()),
        'idStatutSocialEntree': value.idStatutSocialEntree,
        'idSousStatutEntree': value.idSousStatutEntree,
        'idstatutSortie': value.idstatutSortie,
        'dateSortie': value.dateSortie === undefined ? undefined : (value.dateSortie === null ? null : value.dateSortie.toISOString()),
        'idStatutSocialSortie': value.idStatutSocialSortie,
        'idSousStatutSortie': value.idSousStatutSortie,
        'idtypeSortie': value.idtypeSortie,
        'idMotifSortie': value.idMotifSortie,
        'isSortieEnCours': value.isSortieEnCours,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'iddureeInoccupation': value.iddureeInoccupation,
    };
}

