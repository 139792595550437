/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LastSiegeSocialDto } from './LastSiegeSocialDto';
import {
    LastSiegeSocialDtoFromJSON,
    LastSiegeSocialDtoFromJSONTyped,
    LastSiegeSocialDtoToJSON,
} from './LastSiegeSocialDto';

/**
 * 
 * @export
 * @interface ChefEntrepriseGridDto
 */
export interface ChefEntrepriseGridDto {
    /**
     * 
     * @type {number}
     * @memberof ChefEntrepriseGridDto
     */
    idchefEntreprise?: number;
    /**
     * 
     * @type {number}
     * @memberof ChefEntrepriseGridDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {LastSiegeSocialDto}
     * @memberof ChefEntrepriseGridDto
     */
    lastSiegeSocial?: LastSiegeSocialDto;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseGridDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseGridDto
     */
    gsm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseGridDto
     */
    telephone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChefEntrepriseGridDto
     */
    registreNational?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ChefEntrepriseGridDto
     */
    dateNaissance?: Date | null;
}

/**
 * Check if a given object implements the ChefEntrepriseGridDto interface.
 */
export function instanceOfChefEntrepriseGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChefEntrepriseGridDtoFromJSON(json: any): ChefEntrepriseGridDto {
    return ChefEntrepriseGridDtoFromJSONTyped(json, false);
}

export function ChefEntrepriseGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChefEntrepriseGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idchefEntreprise': !exists(json, 'idchefEntreprise') ? undefined : json['idchefEntreprise'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'lastSiegeSocial': !exists(json, 'lastSiegeSocial') ? undefined : LastSiegeSocialDtoFromJSON(json['lastSiegeSocial']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'gsm': !exists(json, 'gsm') ? undefined : json['gsm'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'registreNational': !exists(json, 'registreNational') ? undefined : json['registreNational'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
    };
}

export function ChefEntrepriseGridDtoToJSON(value?: ChefEntrepriseGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idchefEntreprise': value.idchefEntreprise,
        'idpersonne': value.idpersonne,
        'nom': value.nom,
        'prenom': value.prenom,
        'lastSiegeSocial': LastSiegeSocialDtoToJSON(value.lastSiegeSocial),
        'email': value.email,
        'gsm': value.gsm,
        'telephone': value.telephone,
        'registreNational': value.registreNational,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
    };
}

