import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { AnneeReussieApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { DerniereAnneeReussieDetailPage } from "./DerniereAnneeReussieDetailPage";

interface IDerniereAnneeReussieItemPageProps {}

export const DerniereAnneeReussieItemPage: React.FunctionComponent<IDerniereAnneeReussieItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const idAnnee = React.useMemo(() => +id, [id]);
  const history = useHistory();

  const anneeReussieApi = useApiService(AnneeReussieApi);
  const [displayName] = useApiEffect(() => anneeReussieApi.anneeReussieGetDisplayName({ id: idAnnee }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.DernieresAnneesReussies), route: ERoutes.anneeReussie },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="Tabs"
        onChange={newTabId => history.push(`${ERoutes.anneeReussie}/${id}/${newTabId}/${state}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.anneeReussie}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<DerniereAnneeReussieDetailPage idAnnee={idAnnee} editMode={state === "edit"} />}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
