import { Intent } from "@blueprintjs/core";
import { addMinutes, format, parseJSON } from "date-fns";
import {
  ButtonContainer,
  DataTable,
  FGTextAreaInput,
  FGTextInput,
  FieldGroup,
  IDataTableColumn,
  IFGContext,
  showError,
  showSuccess,
  useGridState
} from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";

import { ExperienceProfessionnelleDocumentEditDialog, ExperienceProfessionnelleDocumentUploadDialog } from ".";
import {
  ExperienceProfessionnelleApi,
  ExperienceProfessionnelleEditDtoFromJSON,
  ExperienceProfessionnelleFichierGridDto
} from "../../../../../../api";
import {
  AddButton,
  DeleteButton,
  DownloadButton,
  EditButton,
  FGWalterDateMaskInput,
  SaveButton,
  SmallFormGenerator
} from "../../../../../../components";
import { useDialog } from "../../../../../../contexts";
import { useApiService, useCrudApi, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";
import { exportFile } from "../../../../../../utils";

export interface IExperienceProfessionnelleDetailProps {
  idpersonne: number;
  listRoute: string;
}

export const ExperienceProfessionnelleDetail: React.FunctionComponent<IExperienceProfessionnelleDetailProps> = ({
  idpersonne,
  listRoute
}) => {
  const { t } = useTl();
  const api = useApiService(ExperienceProfessionnelleApi);
  const history = useHistory();
  const { state, experienceProId } = useParams<{
    id: string;
    tab: string;
    state: string;
    experienceProId: string;
  }>();
  const editMode = React.useMemo(() => state === "edit", [state]);

  const saveFn = React.useCallback(
    d => api.experienceProfessionnelleSaveExperienceProfessionnelle({ ExperienceProfessionnelleEditDto: d }),
    [api]
  );

  const { data, loading, saveItem, saving, deleteItem, deleting, validationErrors, refresh: refreshData } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: async () => {
          return +experienceProId <= 0
            ? ExperienceProfessionnelleEditDtoFromJSON({
                idexperienceProfessionnelle: +experienceProId,
                idpersonne: idpersonne
              })
            : api.experienceProfessionnelleGetExperienceProfessionnelle({ id: +experienceProId });
        },
        saveApiFn: saveFn,
        onSavedRoute: d => listRoute,
        getDeps: [experienceProId],
        deleteApiFn: d =>
          api.experienceProfessionnelleDeleteExperienceProfessionnelle({ id: d.idexperienceProfessionnelle }),
        onDeletedRoute: () => listRoute
      }),
      [saveFn, experienceProId, idpersonne, api, listRoute]
    )
  );
  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      fonction: Yup.string().required(t(ETLCodes.Required)),
      dateDeDebut: Yup.date().typeError(t(ETLCodes.DateInvalide)),
      dateDeFin: Yup.date().typeError(t(ETLCodes.DateInvalide))
    });
  }, [t]);

  const additionalRightButtons = React.useCallback(
    (ctx: IFGContext<any>) =>
      +experienceProId <= 0 && (
        <SaveButton
          onClick={async () => {
            await saveFn(ctx.formik.values);
            showSuccess(t(ETLCodes.SaveSuccess));
            ctx.formik.resetForm();
          }}
          text={t(ETLCodes.SauverEtNouveau)}
          minimal={false}
          loading={saving}
          disabled={!ctx.formik.dirty}
        />
      ),
    [experienceProId, saveFn, saving, t]
  );

  const tableState = useGridState<any>({
    serverMode: false,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { uploadDate: "DESC", fileName: "ASC" }
  });

  const { setData } = tableState;

  React.useEffect(() => {
    if (data?.fichiers) {
      setData(data.fichiers);
    }
  }, [data?.fichiers, setData]);

  const [currentExperienceProfessionnelleFichierId, setCurrentExperienceProfessionnelleFichierId] = React.useState(
    null
  );
  const [uploadDialoOpen, setUploadDialoOpen] = React.useState(false);
  const [rowLoading, setRowLoading] = React.useState(null);

  const { showDialogPromise } = useDialog();
  const deleteFichier = React.useCallback(
    async (idexperienceProfessionnelleFichier: number) => {
      const result = await showDialogPromise({
        message: t(ETLCodes.DeleteConfirmationMessage)
      });

      if (result === "yes") {
        try {
          setRowLoading(idexperienceProfessionnelleFichier);
          await api.experienceProfessionnelleDeleteExperienceProfessionnelleFichier({
            id: idexperienceProfessionnelleFichier
          });
          refreshData();
        } catch {
          showError(t(ETLCodes.ErrorWhileDeletingFile));
        } finally {
          setRowLoading(null);
        }
      }
    },
    [api, refreshData, showDialogPromise, t]
  );

  const downloadFichier = React.useCallback(
    async (idexperienceProfessionnelleFichier: number) => {
      try {
        setRowLoading(idexperienceProfessionnelleFichier);
        const file = await api.experienceProfessionnelleDownloadExperienceProfessionnelleFichier({
          id: idexperienceProfessionnelleFichier
        });
        await exportFile(file);
      } catch {
        showError(t(ETLCodes.ErrorWhileDownloadingFile));
      } finally {
        setRowLoading(null);
      }
    },
    [api, t]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ExperienceProfessionnelleFichierGridDto) => (
          <ButtonContainer>
            <EditButton
              minimal={true}
              onClick={() => setCurrentExperienceProfessionnelleFichierId(row.idexperienceProfessionnelleFichier)}
              loading={rowLoading === row.idexperienceProfessionnelleFichier}
              disabled={!!rowLoading && rowLoading !== row.idexperienceProfessionnelleFichier}
            />
            <DownloadButton
              minimal={true}
              onClick={() => downloadFichier(row.idexperienceProfessionnelleFichier)}
              loading={rowLoading === row.idexperienceProfessionnelleFichier}
              disabled={!!rowLoading && rowLoading !== row.idexperienceProfessionnelleFichier}
            />
            <DeleteButton
              minimal={true}
              onDelete={() => deleteFichier(row.idexperienceProfessionnelleFichier)}
              loading={rowLoading === row.idexperienceProfessionnelleFichier}
              disabled={!!rowLoading && rowLoading !== row.idexperienceProfessionnelleFichier}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.NomFichier),
        fieldName: "fileName"
      },
      {
        header: () => t(ETLCodes.DateUpload),
        fieldName: "uploadDate"
      },
      {
        header: () => t(ETLCodes.UtilisateurUpload),
        fieldName: "creationUserName"
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "typeAcquisFichier"
      }
    ],
    [deleteFichier, downloadFichier, rowLoading, t]
  );

  const formatDate = React.useCallback((date: Date) => {
    return format(parseJSON(addMinutes(date, date.getTimezoneOffset())), "dd/MM/yyyy HH:mm");
  }, []);

  const onClose = React.useCallback(
    (refresh?: boolean) => {
      setCurrentExperienceProfessionnelleFichierId(null);
      setUploadDialoOpen(false);
      if (refresh) {
        refreshData();
      }
    },
    [refreshData]
  );

  const onAdd = React.useCallback(() => {
    setUploadDialoOpen(true);
  }, []);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      showColons
      editMode={editMode}
      minLabelWidth={180}
      labelAlignment="right"
      formatDate="dd/MM/yyyy"
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(listRoute)}
      saving={saving}
      additionalRightButtons={additionalRightButtons}
      showDeleteButton={+experienceProId > 0}
      onDelete={deleteItem}
      deleting={deleting}
      validationErrors={validationErrors}
    >
      <FieldGroup columns={2}>
        <FieldGroup
          fieldsetProps={{
            title: t(ETLCodes.ExperienceProfessionnelle)
          }}
        >
          <FGTextInput name="fonction" label={t(ETLCodes.Fonction)} />
          <FGWalterDateMaskInput name="dateDeDebut" label={t(ETLCodes.DateDebut)} />
          <FGWalterDateMaskInput name="dateDeFin" label={t(ETLCodes.DateFin)} />
          <FGTextInput name="entreprise" label={t(ETLCodes.Entreprise)} />
          <FGTextAreaInput name="description" label={t(ETLCodes.Description)} />
          <FGTextAreaInput name="remarque" label={t(ETLCodes.Remarque)} />
        </FieldGroup>
      </FieldGroup>
      <FieldGroup
        visible={+experienceProId > 0}
        fieldsetProps={{
          title: t(ETLCodes.Documents),
          rightElement: (
            <AddButton
              onClick={e => {
                e.stopPropagation();
                onAdd();
              }}
              text={t(ETLCodes.General_Add)}
              intent={Intent.PRIMARY}
            />
          )
        }}
      >
        <DataTable
          dateFormat="dd/MM/yyyy HH:mm"
          formatDate={formatDate}
          tableState={tableState}
          loading={loading}
          columns={columns}
        ></DataTable>
        {uploadDialoOpen && (
          <ExperienceProfessionnelleDocumentUploadDialog
            onClose={onClose}
            dialogOpen={uploadDialoOpen}
            idexperienceProfessionnelle={+experienceProId}
          />
        )}
        {!!currentExperienceProfessionnelleFichierId && (
          <ExperienceProfessionnelleDocumentEditDialog
            onClose={onClose}
            idexperienceProfessionnelleFichier={currentExperienceProfessionnelleFichierId}
          />
        )}
      </FieldGroup>
    </SmallFormGenerator>
  );
};
