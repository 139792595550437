/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRechercheExportGapRemplacementDto
 */
export interface FcbRechercheExportGapRemplacementDto {
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheExportGapRemplacementDto
     */
    idexport?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRechercheExportGapRemplacementDto
     */
    jour?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheExportGapRemplacementDto
     */
    matricule?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheExportGapRemplacementDto
     */
    codeClasse?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheExportGapRemplacementDto
     */
    idremplacement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheExportGapRemplacementDto
     */
    idremplacant?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheExportGapRemplacementDto
     */
    count?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheExportGapRemplacementDto
     */
    signe?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheExportGapRemplacementDto
     */
    maxcontrat?: number | null;
}

/**
 * Check if a given object implements the FcbRechercheExportGapRemplacementDto interface.
 */
export function instanceOfFcbRechercheExportGapRemplacementDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRechercheExportGapRemplacementDtoFromJSON(json: any): FcbRechercheExportGapRemplacementDto {
    return FcbRechercheExportGapRemplacementDtoFromJSONTyped(json, false);
}

export function FcbRechercheExportGapRemplacementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRechercheExportGapRemplacementDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idexport': !exists(json, 'idexport') ? undefined : json['idexport'],
        'jour': !exists(json, 'jour') ? undefined : (json['jour'] === null ? null : new Date(json['jour'])),
        'matricule': !exists(json, 'matricule') ? undefined : json['matricule'],
        'codeClasse': !exists(json, 'codeClasse') ? undefined : json['codeClasse'],
        'idremplacement': !exists(json, 'idremplacement') ? undefined : json['idremplacement'],
        'idremplacant': !exists(json, 'idremplacant') ? undefined : json['idremplacant'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'signe': !exists(json, 'signe') ? undefined : json['signe'],
        'maxcontrat': !exists(json, 'maxcontrat') ? undefined : json['maxcontrat'],
    };
}

export function FcbRechercheExportGapRemplacementDtoToJSON(value?: FcbRechercheExportGapRemplacementDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idexport': value.idexport,
        'jour': value.jour === undefined ? undefined : (value.jour === null ? null : value.jour.toISOString()),
        'matricule': value.matricule,
        'codeClasse': value.codeClasse,
        'idremplacement': value.idremplacement,
        'idremplacant': value.idremplacant,
        'count': value.count,
        'signe': value.signe,
        'maxcontrat': value.maxcontrat,
    };
}

