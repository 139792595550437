/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbPlanLocalisationDatumDto
 */
export interface FcbPlanLocalisationDatumDto {
    /**
     * 
     * @type {number}
     * @memberof FcbPlanLocalisationDatumDto
     */
    idplanData?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbPlanLocalisationDatumDto
     */
    financementType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPlanLocalisationDatumDto
     */
    financementProjet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPlanLocalisationDatumDto
     */
    typeCours?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbPlanLocalisationDatumDto
     */
    idreferentiel?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbPlanLocalisationDatumDto
     */
    idplan?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbPlanLocalisationDatumDto
     */
    iddegre?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbPlanLocalisationDatumDto
     */
    idmetier?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbPlanLocalisationDatumDto
     */
    idgenreClasse?: string;
    /**
     * 
     * @type {string}
     * @memberof FcbPlanLocalisationDatumDto
     */
    specificiteCours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPlanLocalisationDatumDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbPlanLocalisationDatumDto
     */
    datePremierCours?: Date;
    /**
     * 
     * @type {number}
     * @memberof FcbPlanLocalisationDatumDto
     */
    nbheuresSemestre1?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbPlanLocalisationDatumDto
     */
    nbheuresSemestre2?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbPlanLocalisationDatumDto
     */
    nbapprenants?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPlanLocalisationDatumDto
     */
    remarqueCentre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPlanLocalisationDatumDto
     */
    remarqueConseiller?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPlanLocalisationDatumDto
     */
    idconseillerPedagogique?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbPlanLocalisationDatumDto
     */
    idclasse?: number | null;
}

/**
 * Check if a given object implements the FcbPlanLocalisationDatumDto interface.
 */
export function instanceOfFcbPlanLocalisationDatumDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbPlanLocalisationDatumDtoFromJSON(json: any): FcbPlanLocalisationDatumDto {
    return FcbPlanLocalisationDatumDtoFromJSONTyped(json, false);
}

export function FcbPlanLocalisationDatumDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbPlanLocalisationDatumDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idplanData': !exists(json, 'idplanData') ? undefined : json['idplanData'],
        'financementType': !exists(json, 'financementType') ? undefined : json['financementType'],
        'financementProjet': !exists(json, 'financementProjet') ? undefined : json['financementProjet'],
        'typeCours': !exists(json, 'typeCours') ? undefined : json['typeCours'],
        'idreferentiel': !exists(json, 'idreferentiel') ? undefined : json['idreferentiel'],
        'idplan': !exists(json, 'idplan') ? undefined : json['idplan'],
        'iddegre': !exists(json, 'iddegre') ? undefined : json['iddegre'],
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'idgenreClasse': !exists(json, 'idgenreClasse') ? undefined : json['idgenreClasse'],
        'specificiteCours': !exists(json, 'specificiteCours') ? undefined : json['specificiteCours'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'datePremierCours': !exists(json, 'datePremierCours') ? undefined : (new Date(json['datePremierCours'])),
        'nbheuresSemestre1': !exists(json, 'nbheuresSemestre1') ? undefined : json['nbheuresSemestre1'],
        'nbheuresSemestre2': !exists(json, 'nbheuresSemestre2') ? undefined : json['nbheuresSemestre2'],
        'nbapprenants': !exists(json, 'nbapprenants') ? undefined : json['nbapprenants'],
        'remarqueCentre': !exists(json, 'remarqueCentre') ? undefined : json['remarqueCentre'],
        'remarqueConseiller': !exists(json, 'remarqueConseiller') ? undefined : json['remarqueConseiller'],
        'idconseillerPedagogique': !exists(json, 'idconseillerPedagogique') ? undefined : json['idconseillerPedagogique'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
    };
}

export function FcbPlanLocalisationDatumDtoToJSON(value?: FcbPlanLocalisationDatumDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idplanData': value.idplanData,
        'financementType': value.financementType,
        'financementProjet': value.financementProjet,
        'typeCours': value.typeCours,
        'idreferentiel': value.idreferentiel,
        'idplan': value.idplan,
        'iddegre': value.iddegre,
        'idmetier': value.idmetier,
        'idgenreClasse': value.idgenreClasse,
        'specificiteCours': value.specificiteCours,
        'libelle': value.libelle,
        'datePremierCours': value.datePremierCours === undefined ? undefined : (value.datePremierCours.toISOString()),
        'nbheuresSemestre1': value.nbheuresSemestre1,
        'nbheuresSemestre2': value.nbheuresSemestre2,
        'nbapprenants': value.nbapprenants,
        'remarqueCentre': value.remarqueCentre,
        'remarqueConseiller': value.remarqueConseiller,
        'idconseillerPedagogique': value.idconseillerPedagogique,
        'idclasse': value.idclasse,
    };
}

