/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiplomeCertificationIfapmeFichierGridDto } from './DiplomeCertificationIfapmeFichierGridDto';
import {
    DiplomeCertificationIfapmeFichierGridDtoFromJSON,
    DiplomeCertificationIfapmeFichierGridDtoFromJSONTyped,
    DiplomeCertificationIfapmeFichierGridDtoToJSON,
} from './DiplomeCertificationIfapmeFichierGridDto';

/**
 * 
 * @export
 * @interface DiplomeCertificationIfapmeEditDto
 */
export interface DiplomeCertificationIfapmeEditDto {
    /**
     * 
     * @type {number}
     * @memberof DiplomeCertificationIfapmeEditDto
     */
    iddiplomeCertificationIfapme?: number;
    /**
     * 
     * @type {number}
     * @memberof DiplomeCertificationIfapmeEditDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {string}
     * @memberof DiplomeCertificationIfapmeEditDto
     */
    anneeDeFormation?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DiplomeCertificationIfapmeEditDto
     */
    acquis?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof DiplomeCertificationIfapmeEditDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DiplomeCertificationIfapmeEditDto
     */
    typeAcquis?: string | null;
    /**
     * 
     * @type {Array<DiplomeCertificationIfapmeFichierGridDto>}
     * @memberof DiplomeCertificationIfapmeEditDto
     */
    fichiers?: Array<DiplomeCertificationIfapmeFichierGridDto> | null;
}

/**
 * Check if a given object implements the DiplomeCertificationIfapmeEditDto interface.
 */
export function instanceOfDiplomeCertificationIfapmeEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DiplomeCertificationIfapmeEditDtoFromJSON(json: any): DiplomeCertificationIfapmeEditDto {
    return DiplomeCertificationIfapmeEditDtoFromJSONTyped(json, false);
}

export function DiplomeCertificationIfapmeEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiplomeCertificationIfapmeEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iddiplomeCertificationIfapme': !exists(json, 'iddiplomeCertificationIfapme') ? undefined : json['iddiplomeCertificationIfapme'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'anneeDeFormation': !exists(json, 'anneeDeFormation') ? undefined : json['anneeDeFormation'],
        'acquis': !exists(json, 'acquis') ? undefined : json['acquis'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'typeAcquis': !exists(json, 'typeAcquis') ? undefined : json['typeAcquis'],
        'fichiers': !exists(json, 'fichiers') ? undefined : (json['fichiers'] === null ? null : (json['fichiers'] as Array<any>).map(DiplomeCertificationIfapmeFichierGridDtoFromJSON)),
    };
}

export function DiplomeCertificationIfapmeEditDtoToJSON(value?: DiplomeCertificationIfapmeEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iddiplomeCertificationIfapme': value.iddiplomeCertificationIfapme,
        'idpersonne': value.idpersonne,
        'anneeDeFormation': value.anneeDeFormation,
        'acquis': value.acquis,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'typeAcquis': value.typeAcquis,
        'fichiers': value.fichiers === undefined ? undefined : (value.fichiers === null ? null : (value.fichiers as Array<any>).map(DiplomeCertificationIfapmeFichierGridDtoToJSON)),
    };
}

