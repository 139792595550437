import { Button, ButtonGroup, Classes, Dialog, Icon, Tooltip } from "@blueprintjs/core";
import { IDataTableColumn, IDefaultCriteriaValue } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { FcbInscriptionMasseGridDto, InscriptionMasseApi, InscriptionMasseSearch } from "../../../../api";
import { SearchTablePage, UnordoredListColumn } from "../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

const DialogStyled = styled(Dialog)`
  width: 1500px;
  background: white;
`;

export interface IInscriptionMasseAddModalProps {
  isOpen: boolean;
  onClose: (idsapprenant?: number[]) => void;
  codeMetier: string;
  anneeScolaire: string;
  idclasse: number;
  idcentre: number;
  /**
   * Ids of the already registered apprenants to not fetch them
   */
  registeredIds: number[];
  /**
   * Elements that has been already selected
   */
  alreadySelected: number[];
}

export const InscriptionMasseAddModal: React.FunctionComponent<IInscriptionMasseAddModalProps> = ({
  isOpen,
  onClose,
  codeMetier,
  idclasse,
  idcentre,
  anneeScolaire,
  registeredIds,
  alreadySelected
}) => {
  const { t } = useTl();
  const inscriptionApi = useApiService(InscriptionMasseApi);
  const apiFactory = useAbortableApiServiceFactory(InscriptionMasseApi);
  const lastAbortController = React.useRef<AbortController>();

  const [totalCount, setTotalCount] = React.useState(0);
  const [data, setData] = React.useState<number[]>();
  // TODO: Selection not working
  const searchFn = React.useCallback(
    async (nextSearch?: InscriptionMasseSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      var res = await abortableApi.inscriptionMasseSearchInscriptionIds({
        InscriptionMasseSearch: {
          ...nextSearch,
          idcentre,
          idclasseSelected: idclasse,
          availableIds: registeredIds,
          forceSkip: 0,
          forceTake: 99999
        }
      });

      setData(res);
      setTotalCount(res.length);

      return await abortableApi.inscriptionMasseSearchApprenantNotInClasse({
        InscriptionMasseSearch: {
          ...nextSearch,
          idcentre,
          idclasseSelected: idclasse,
          availableIds: registeredIds
        }
      });
    },
    [apiFactory, idcentre, idclasse, registeredIds]
  );

  const [selected, setSelected] = React.useState<number[]>(alreadySelected ?? []);

  const toggleSelection = React.useCallback(
    (idapprenant: number) => {
      if (!selected.includes(idapprenant)) {
        setSelected(apprenants => [...apprenants, idapprenant]);
      } else {
        setSelected(apprenants => apprenants.filter(i => i !== idapprenant));
      }
    },
    [selected]
  );

  const allChecked = React.useMemo(() => totalCount > 0 && selected.length === totalCount, [
    selected.length,
    totalCount
  ]);
  const toggleSelectAll = React.useCallback(() => {
    if (data.length === selected.length) {
      setSelected([]);
    } else {
      setSelected(data);
    }
  }, [data, selected.length]);

  const onRowClick = React.useCallback(
    (item: FcbInscriptionMasseGridDto) => {
      toggleSelection(item.idapprenant);
    },
    [toggleSelection]
  );

  const columns = React.useMemo<IDataTableColumn[]>(() => {
    return [
      {
        fieldName: "_action",
        autoFitContent: true,
        computed: true,
        header: () => (
          <Tooltip content={allChecked ? t(ETLCodes.UnSelectAll) : t(ETLCodes.SelectAll)}>
            <Icon
              icon={allChecked ? "tick-circle" : "circle"}
              style={{ cursor: "pointer" }}
              onClick={toggleSelectAll}
            />
          </Tooltip>
        ),
        alignment: "center",
        render: (item: FcbInscriptionMasseGridDto) => (
          <Icon
            icon={selected.includes(item.idapprenant) ? "tick-circle" : "circle"}
            style={{ cursor: "pointer" }}
          ></Icon>
        )
      },
      {
        fieldName: "idapprenant",
        header: () => t(ETLCodes.Identifiant)
      },
      {
        fieldName: "nom",
        header: () => t(ETLCodes.Nom),
        render: row => row.nom + " " + row.prenom
      },
      {
        fieldName: "dateNaissance",
        header: () => t(ETLCodes.DateNaissance)
      },
      {
        fieldName: "degre",
        header: () => t(ETLCodes.Degre),
        render: (row: FcbInscriptionMasseGridDto) => (
          <UnordoredListColumn values={row.degres?.map(d => ({ label: d, value: true }))} noBullets />
        )
      },
      {
        fieldName: "masculin",
        header: () => t(ETLCodes.Formation),
        render: (row: FcbInscriptionMasseGridDto) => (
          <UnordoredListColumn values={row.masculins?.map(d => ({ label: d, value: true }))} noBullets />
        )
      },
      {
        fieldName: "codeMetier",
        header: () => t(ETLCodes.CodeMetier),
        render: (row: FcbInscriptionMasseGridDto) => (
          <UnordoredListColumn values={row.codesMetier?.map(d => ({ label: d, value: true }))} noBullets />
        )
      },
      {
        fieldName: "idclasse",
        header: () => t(ETLCodes.Idclasse_Table),
        render: (row: FcbInscriptionMasseGridDto) => (
          <UnordoredListColumn values={row.idsclasse?.map(d => ({ label: d + "", value: true }))} noBullets />
        )
      },
      {
        fieldName: "classe",
        header: () => t(ETLCodes.Classe),
        render: (row: FcbInscriptionMasseGridDto) => (
          <UnordoredListColumn values={row.classes?.map(d => ({ label: d, value: true }))} noBullets />
        )
      },
      {
        fieldName: "anneeScolaire",
        header: () => t(ETLCodes.AnneeScolaire),
        render: (row: FcbInscriptionMasseGridDto) => (
          <UnordoredListColumn values={row.anneesScolaires?.map(d => ({ label: d, value: true }))} noBullets />
        )
      },
      {
        fieldName: "deliberation",
        header: () => t(ETLCodes.Deliberation),
        render: (row: FcbInscriptionMasseGridDto) => (
          <UnordoredListColumn values={row.deliberations?.map(d => ({ label: d, value: true }))} noBullets />
        )
      },
      {
        fieldName: "deliberation2sess",
        header: () => t(ETLCodes.Deliberation2sess),
        render: (row: FcbInscriptionMasseGridDto) => (
          <UnordoredListColumn values={row.deliberations2sess?.map(d => ({ label: d, value: true }))} noBullets />
        )
      }
    ];
  }, [allChecked, selected, t, toggleSelectAll]);

  const onSave = React.useCallback(async () => {
    onClose(selected);
    setSelected([]);
  }, [onClose, selected]);

  const close = React.useCallback(() => {
    onClose(undefined);
    setSelected(alreadySelected ?? []);
  }, [alreadySelected, onClose]);

  const getCriteria = React.useCallback(
    () => inscriptionApi.inscriptionMasseGetSearchCriterias({ includeListsValues: true }),
    [inscriptionApi]
  );

  const defaultCriterias = React.useMemo<IDefaultCriteriaValue[]>(
    () => [
      {
        criteria: "AnneesScolaires",
        searchMode: "Contains",
        value: anneeScolaire
      },
      {
        criteria: "CodesMetier",
        searchMode: "Contains",
        value: codeMetier
      }
    ],
    [anneeScolaire, codeMetier]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <DialogStyled
      isOpen={isOpen}
      isCloseButtonShown={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      title={t(ETLCodes.SelectionApprenants)}
      onClose={close}
      enforceFocus={false}
    >
      <div className={Classes.DIALOG_BODY}>
        <SearchTablePage
          columns={columns}
          getCriteriasFunction={getCriteria}
          searchFunction={searchFn}
          defaultCriterias={defaultCriterias}
          sortKeys={{ nom: "ASC" }}
          avoidSavingCriterias={true}
          withCard={false}
          onRowClick={onRowClick}
          criteriasTlPrefix="InscriptionMasse"
          onAbort={onAbort}
          enableFilter={true}
        />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <ButtonGroup minimal large fill>
            <Button text={t(ETLCodes.Cancel)} onClick={close} icon="cross"></Button>
            <div style={{ flex: 1 }}></div>
            <Button
              text={t(ETLCodes.AddApprenants, { count: selected.length })}
              icon="floppy-disk"
              onClick={onSave}
              intent="primary"
              disabled={selected?.length === 0}
            ></Button>
          </ButtonGroup>
        </div>
      </div>
    </DialogStyled>
  );
};
