import * as React from "react";
import { matchPath, useLocation, useParams } from "react-router-dom";

import { LieuFormationContact, LieuFormationContactDoublons, LieuFormationContactListPage } from ".";
import { ContactLieuDoublonSearchFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";

export interface ILieuFormationContactSwitchProps { }

export const LieuFormationContactSwitch: React.FunctionComponent<ILieuFormationContactSwitchProps> = () => {
  const [sObj, setSObj] = React.useState(ContactLieuDoublonSearchFromJSON({}));
  const { pathname } = useLocation();
  const { id } = useParams<{ id: string; tab: string; state: string }>();
  if (
    matchPath(pathname, {
      path: `${ERoutes.lieuFormation}/${id}${ERoutes.contacts}/:state/:subId`
    })
  ) {
    return <LieuFormationContact baseData={sObj} />;
  } else if (
    matchPath(pathname, {
      path: `${ERoutes.lieuFormation}/${id}${ERoutes.contacts}/checkDoublons`
    })
  ) {
    return <LieuFormationContactDoublons setBaseData={setSObj} />;
  } else {
    return <LieuFormationContactListPage />;
  }
};
