import * as React from "react";
import { FormateurSuiviApi, FormateurSuiviFichierDto } from "../../../../../api";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import * as Yup from "yup";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import styled from "styled-components";
import { Classes, Dialog } from "@blueprintjs/core";
import { FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { FGTextInput, FieldGroup } from "nsitools-react";

const StyledDialog = styled(Dialog)`
  width: 600px;
  height: auto;
  background-color: white;
`;

export interface IFormateurSuiviDocumentEditDialogProps {
  idformateurSuiviFichier: number;
  onClose: (refresh?: boolean) => void;
}

export const FormateurSuiviDocumentEditDialog: React.FunctionComponent<IFormateurSuiviDocumentEditDialogProps> = ({
  idformateurSuiviFichier,
  onClose
}) => {
  const { t } = useTl();
  const api = useApiService(FormateurSuiviApi);

  const { data, loading, saveItem, saving, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () => {
          return api.formateurSuiviGetFormateurSuiviFichier({
            id: idformateurSuiviFichier
          });
        },
        saveApiFn: async (d: FormateurSuiviFichierDto) => {
          return api.formateurSuiviSaveFormateurSuiviFichier({
            FormateurSuiviFichierDto: d
          });
        },
        onSaved: () => onClose(true)
      }),
      [api, idformateurSuiviFichier, onClose]
    )
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape({
        idtypeSuiviFichier: Yup.number().nullable()
      }),
    []
  );

  const [typesSuiviFichier, tLoading] = useReferential(a => a.referentialGetTypesSuiviFichier());

  return (
    <StyledDialog title={t(ETLCodes.UploadFichier)} isOpen={!!idformateurSuiviFichier} onClose={() => onClose(false)}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose(false)}
          showDeleteButton={false}
          saving={saving}
          loading={loading}
          validationErrors={validationErrors}
        >
          <FieldGroup>
            <FGTextInput name="fileName" label={t(ETLCodes.Fichier)} readonly />
            <FGWalterSelectInput
              name="idtypeSuiviFichier"
              label={t(ETLCodes.Type)}
              items={typesSuiviFichier}
              loading={tLoading}
            />
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
