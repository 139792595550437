import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import "normalize.css/normalize.css";

import { Callout, Classes, Colors, Drawer, Position, Spinner } from "@blueprintjs/core";
import { useLocalStorageState } from "ahooks";
import { ErrorBoundary } from "nsitools-react";
import { darken } from "polished";
import * as React from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import styled from "styled-components";

import { AppNavigation } from "./app-navigation";
import { AppRouter } from "./AppRouter";
import {
  AppTheme,
  DialogRoot,
  ExportMenu,
  FooterIfapme,
  Header,
  NavigationMenu,
  RapportMenu,
  StartupDialog
} from "./components";
import { useGlobalData } from "./contexts";
import { useAuth } from "./contexts/AuthContext";
import { useTheme } from "./hooks";
import { UnauthorizedPage } from "./pages";
import { IThemeData } from "./theme";
import { isAfter, isSameDay } from "date-fns";

interface IAppProps {}

const AppContainer = styled.div<{ isDark: boolean; theme: IThemeData }>`
  display: flex;
  flex: 1;
  height: -webkit-fill-available;
  width: 100%;
  flex-direction: column;
  background-color: ${props => (props.isDark ? Colors.DARK_GRAY2 : Colors.LIGHT_GRAY3)};

  & ::selection {
    color: ${({ theme }) => theme.highlightColor} !important;
  }

  & .${Classes.MENU} {
    background-color: ${({ theme }) => theme.menuBackgroundColor} !important;
  }

  & .${Classes.CALLOUT} {
    & > .${Classes.ICON} {
      height: 20px;
      display: flex;
      align-items: center;
    }

    &.${Classes.INTENT_PRIMARY} {
      color: ${({ theme }) => darken(0.1, theme.primaryColor)} !important;
    }

    &.${Classes.INTENT_SUCCESS} {
      color: ${({ theme }) => darken(0.1, theme.sucessColor)} !important;
    }

    &.${Classes.INTENT_WARNING} {
      color: ${({ theme }) => darken(0.1, theme.warningColor)} !important;
    }

    &.${Classes.INTENT_DANGER} {
      color: ${({ theme }) => darken(0.1, theme.dangerColor)} !important;
    }
  }

  & .app-drawer {
    & .${Classes.DRAWER_BODY} {
      background-color: ${({ theme }) => theme.menuBackgroundColor} !important;
    }

    & .${Classes.DRAWER_FOOTER} {
      background-color: ${({ theme }) => theme.menuBackgroundColor} !important;
    }
  }

  & .${Classes.NAVBAR} {
    background-color: ${props => props.theme.menuBackgroundColor} !important;
  }

  & .${Classes.TAG}.${Classes.MINIMAL} {
    &.${Classes.INTENT_PRIMARY} {
      color: ${props => props.theme.primaryColor} !important;

      & .${Classes.TAG_REMOVE} > .${Classes.ICON} {
        color: ${props => props.theme.primaryColor} !important;
      }
    }

    &.${Classes.INTENT_DANGER} {
      color: ${props => props.theme.dangerColor} !important;

      & .${Classes.TAG_REMOVE} > .${Classes.ICON} {
        color: ${props => props.theme.dangerColor} !important;
      }
    }

    &.${Classes.INTENT_SUCCESS} {
      color: ${props => props.theme.successColor} !important;

      & .${Classes.TAG_REMOVE} > .${Classes.ICON} {
        color: ${props => props.theme.successColor} !important;
      }
    }

    &.${Classes.INTENT_WARNING} {
      color: ${props => props.theme.warningColor} !important;

      & .${Classes.TAG_REMOVE} > .${Classes.ICON} {
        color: ${props => props.theme.warningColor} !important;
      }
    }
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 100px;
  flex: 1;
`;

const RouterContainer = styled.div<{ withWarning: number }>`
  height: ${props => (props.withWarning ? "84vh" : "89vh")} !important;
  flex: 1;
  width: 0;
`;

export const App: React.FunctionComponent<IAppProps> = () => {
  const [menuFixed, setMenuFixed] = useLocalStorageState<boolean>("menuFixed", { defaultValue: true });
  const { theme, ifapmeSide } = useTheme();
  const {
    enableNextFeatures,
    applicationWideWarning,
    loading,
    walterStartupPopupText,
    hopeStartupPopupText,
    startupMessageEndDate
  } = useGlobalData();

  const [drawerVisible, setDrawerVisible] = React.useState(false);
  const [exportMenuVisible, setExportMenuVisible] = React.useState(false);
  const [reportMenuVisible, setReportMenuVisible] = React.useState(false);
  const [darkMode, setDarkMode] = React.useState(false);
  const [showStartupDialog, setShowStartupDialog] = React.useState(false);
  const { user, userPermissions, isLoading: authLoading, azureAdAccount, authenticationFailed } = useAuth();

  const finalMenuFixed = React.useMemo(() => menuFixed && !exportMenuVisible && !reportMenuVisible, [
    exportMenuVisible,
    menuFixed,
    reportMenuVisible
  ]);

  const onToggleFixed = React.useCallback(() => {
    if (reportMenuVisible || exportMenuVisible) return;

    if (!menuFixed) {
      setDrawerVisible(false);
    }
    setMenuFixed(!menuFixed);
  }, [exportMenuVisible, menuFixed, reportMenuVisible, setMenuFixed]);

  const navigationItems = React.useMemo(
    () =>
      AppNavigation(userPermissions?.map(p => p.module.toUpperCase()) ?? [], ifapmeSide, enableNextFeatures).filter(
        n => !!n
      ),
    [enableNextFeatures, ifapmeSide, userPermissions]
  );

  const hasStartupText = React.useMemo(
    () => (!!walterStartupPopupText && ifapmeSide === "walter") || (!!hopeStartupPopupText && ifapmeSide === "hope"),
    [hopeStartupPopupText, ifapmeSide, walterStartupPopupText]
  );

  React.useEffect(() => {
    if (
      !authenticationFailed &&
      !authLoading &&
      !!user &&
      user.showStartupPopup &&
      hasStartupText &&
      (isAfter(startupMessageEndDate, new Date()) || isSameDay(startupMessageEndDate, new Date()))
    ) {
      setShowStartupDialog(true);
    }
  }, [
    authLoading,
    authenticationFailed,
    hasStartupText,
    hopeStartupPopupText,
    ifapmeSide,
    startupMessageEndDate,
    user,
    walterStartupPopupText
  ]);

  if (authenticationFailed) {
    return (
      <AppContainer isDark={darkMode} className={darkMode ? Classes.DARK : ""} theme={theme}>
        {!!applicationWideWarning && <Callout intent="warning" title={applicationWideWarning} icon="warning-sign" />}
        <UnauthorizedPage />
      </AppContainer>
    );
  }

  if (authLoading || !user) {
    return (
      <CenterContainer>
        <Spinner></Spinner>
      </CenterContainer>
    );
  }
  return (
    <AppContainer isDark={darkMode} className={darkMode ? Classes.DARK : ""} theme={theme}>
      <AppTheme />
      <ErrorBoundary>
        {!!applicationWideWarning && <Callout intent="warning" title={applicationWideWarning} icon="warning-sign" />}
        {!user && !azureAdAccount ? (
          <CenterContainer>
            <Spinner></Spinner>
          </CenterContainer>
        ) : (
          <>
            <Header
              showMenuIcon={!finalMenuFixed}
              toggleLeftMenu={() => {
                setDrawerVisible(s => !s);
              }}
              toggleExportMenu={() => {
                setExportMenuVisible(s => !s);
                setReportMenuVisible(false);
              }}
              toggleReportMenu={() => {
                setReportMenuVisible(s => !s);
                setExportMenuVisible(false);
              }}
              isDark={darkMode}
              onDarkSwitchChange={() => setDarkMode(!darkMode)}
            />
            {loading ? (
              <CenterContainer>
                <Spinner></Spinner>
              </CenterContainer>
            ) : (
              <ContentContainer className="page">
                <Drawer
                  hasBackdrop={true}
                  className={Classes.DARK}
                  isOpen={drawerVisible}
                  position={Position.LEFT}
                  size={350}
                  onClose={() => setDrawerVisible(false)}
                  canEscapeKeyClose={true}
                  canOutsideClickClose={true}
                  usePortal={false}
                >
                  <div className={Classes.DRAWER_BODY}>
                    <NavigationMenu
                      collapseOtherOnSelection={true}
                      maxAutoCollapseLevel={0}
                      navigationMenu={navigationItems}
                      isFixed={finalMenuFixed}
                      changeFixed={onToggleFixed}
                      onNavigation={() => setDrawerVisible(false)}
                    />
                  </div>
                  <div className={Classes.DRAWER_FOOTER} />
                </Drawer>

                {finalMenuFixed && (
                  <NavigationMenu
                    navigationMenu={navigationItems}
                    collapseOtherOnSelection={true}
                    maxAutoCollapseLevel={0}
                    isFixed={finalMenuFixed}
                    changeFixed={() => setMenuFixed(!menuFixed)}
                  />
                )}
                <RouterContainer withWarning={!!applicationWideWarning ? 1 : 0}>
                  <AppRouter />
                </RouterContainer>
                {exportMenuVisible && !reportMenuVisible && (
                  <ExportMenu
                    toggleExportMenu={() => {
                      setExportMenuVisible(s => !s);
                      setReportMenuVisible(false);
                    }}
                  />
                )}
                {reportMenuVisible && !exportMenuVisible && (
                  <RapportMenu
                    toggleRapportMenu={() => {
                      setReportMenuVisible(s => !s);
                      setExportMenuVisible(false);
                    }}
                  />
                )}
              </ContentContainer>
            )}
            <FooterIfapme />
          </>
        )}
        <DialogRoot></DialogRoot>
      </ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
      {user.showStartupPopup && hasStartupText && (
        <StartupDialog isDialogOpen={showStartupDialog} onClosed={() => setShowStartupDialog(false)} />
      )}
    </AppContainer>
  );
};
