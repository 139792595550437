import { Divider } from "@blueprintjs/core";
import { DialogResult, FGListen, FGTextAreaInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { FcbRemplacementDto, FcbRemplacementDtoFromJSON, FormateurApi, RemplacementApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { ErrorText, FGWalterDateMaskInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";
import { ETLCodes } from "../../../../locales";
import { useDialog, useGlobalData, useRouteParamsContext } from "../../../../contexts";
import { useQuery } from "react-query";

export interface IRemplacementGeneralPageProps {}

export const RemplacementGeneralPage: React.FunctionComponent<IRemplacementGeneralPageProps> = () => {
  const { t } = useTl();
  const { id, state } = useParams<{ id: string; tab: string; state: string }>();
  const api = useApiService(RemplacementApi);
  const formateurApi = useApiService(FormateurApi);
  const history = useHistory();
  const { currentAnneeScolaire } = useGlobalData();
  const { showDialogPromise } = useDialog();
  const [idCentre, setIdCentre] = React.useState<number>(0);
  const [idRemplace, setIdRemplace] = React.useState<number>(0);
  const [idRemplacant, setIdRemplacant] = React.useState<number>(0);
  const [centre, centreLoading] = useReferential(a => a.referentialGetCentreByUser({ idCentre: idCentre }), true, [
    idCentre
  ]);
  const [formateur, formateurLoading] = useReferential(
    a => (idCentre !== 0 ? a.referentialGetFormateurByCentre({ idCentre }) : null),
    true,
    [idCentre]
  );
  const [typeRemplacement, trLoading] = useReferential(a => a.referentialGetTypeRemplacement(), true, []);
  const [legitimite, legitimiteLoading] = useReferential(a => a.referentialGetRemplacementLegitimite(), true, []);

  const { popCancelRoute } = useRouteParamsContext();
  // Check if not undefined to not fetch on first page load
  const [contratFormateur, cf] = useReferential(
    a => (idRemplacant !== 0 ? a.referentialGetFormateurLocalisationContrat({ idformateur: idRemplacant }) : null),
    true,
    [idRemplacant]
  );

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idformateurLocalisationContrat: Yup.number().required(t(ETLCodes.Required)),
      idtypeRemplacement: Yup.number().required(t(ETLCodes.Required)),
      idremplace: Yup.number().required(t(ETLCodes.Required)),
      idcentre: Yup.number().required(t(ETLCodes.Required)),
      idremplacant: Yup.number().required(t(ETLCodes.Required)),
      idremplacementLegitimite: Yup.number().required(t(ETLCodes.Required)),
      dateDebut: Yup.date()
        .required(t(ETLCodes.Required))
        .min(new Date(2000, 1, 1), t(ETLCodes.DateCannotBePastOf, { minDate: "01/01/2000" })),
      dateFin: Yup.date()
        .required(t(ETLCodes.Required))
        .min(new Date(2000, 1, 1), t(ETLCodes.DateCannotBePastOf, { minDate: "01/01/2000" }))
    });
  }, [t]);

  const remplacants = React.useMemo(() => formateur?.filter(f => f.value !== idRemplace), [formateur, idRemplace]);
  const remplaces = React.useMemo(() => formateur?.filter(f => f.value !== idRemplacant), [formateur, idRemplacant]);

  const saveRemplacement = React.useCallback(
    async (dto: FcbRemplacementDto) => {
      let res: DialogResult = "no";

      if (dto.dateFin > currentAnneeScolaire.dateFin) {
        res = await showDialogPromise({
          message: t(ETLCodes.RemplacementOverSchoolYear)
        });
      } else {
        res = "yes";
      }

      if (res === "yes") {
        return api.remplacementSave({ FcbRemplacementDto: dto });
      }
    },
    [api, currentAnneeScolaire.dateFin, showDialogPromise, t]
  );

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () => (+id <= 0 ? FcbRemplacementDtoFromJSON({ idRemplacement: 0 }) : api.remplacementGet({ id: +id })),
    saveApiFn: saveRemplacement,
    onSavedRoute: d => `${ERoutes.remplacement}/${d.idremplacement}/general/${state}`,
    deleteApiFn: d => api.remplacementDelete({ id: d.idremplacement }),
    onDeletedRoute: () => ERoutes.remplacement
  });

  const cancel = React.useCallback(() => history.push(popCancelRoute() ?? ERoutes.remplacement), [
    history,
    popCancelRoute
  ]);

  const { data: isDeceased, isFetching: isDeceasedLoading } = useQuery(
    ["formateur-deceased", idRemplacant],
    async () => {
      if (!idRemplacant) return false;
      const val = await formateurApi.formateurIsDeceased({ idFormateur: idRemplacant });
      return val?.value;
    }
  );

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={state === "edit"}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={cancel}
      onDelete={deleteItem}
      showDeleteButton={+id > 0}
      disableSaveButton={isDeceased}
      saving={saving || isDeceasedLoading}
      deleting={deleting}
    >
      <FGListen field="idremplacant" onChanged={value => setIdRemplacant(value)} />
      <FGListen field="idremplace" onChanged={value => setIdRemplace(value)} />
      <FGListen field="idcentre" onChanged={value => setIdCentre(value)} />
      <FieldGroup>
        <FGWalterSelectInput name="idcentre" label={t(ETLCodes.Centre)} items={centre} loading={centreLoading} />
        <FGWalterSelectInput
          name="idremplace"
          label={t(ETLCodes.Remplace)}
          items={remplaces}
          loading={formateurLoading}
        />
        <Divider />
        <FGWalterSelectInput
          name="idremplacant"
          label={t(ETLCodes.Remplacant)}
          items={remplacants}
          loading={formateurLoading}
        />
        <FGWalterSelectInput
          name="idformateurLocalisationContrat"
          label={t(ETLCodes.Contrat)}
          items={contratFormateur}
          loading={cf}
        />
      </FieldGroup>
      <FieldGroup columns={2}>
        <FGWalterSelectInput
          name="idtypeRemplacement"
          label={t(ETLCodes.Remplacement)}
          items={typeRemplacement}
          loading={trLoading}
        />
        <FGWalterDateMaskInput name="dateDebut" label={t(ETLCodes.DateDebut)} minDate={new Date(2000, 1, 1)} />
        <FGWalterSelectInput
          name="idremplacementLegitimite"
          label={t(ETLCodes.Legitimite)}
          items={legitimite}
          loading={legitimiteLoading}
        />
        <FGWalterDateMaskInput name="dateFin" label={t(ETLCodes.DateFin)} minDate={new Date(2000, 1, 1)} />
      </FieldGroup>
      <Divider />
      <FieldGroup>
        <FGTextAreaInput name="raison" label={t(ETLCodes.Raison)} />
        <FGTextAreaInput name="remarque" label={t(ETLCodes.Remarque)} />
      </FieldGroup>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {isDeceased && <ErrorText text={t(ETLCodes.RemplacantDecede)} />}
      </div>
    </SmallFormGenerator>
  );
};
