import * as React from "react";
import { useTl, useApiService, useAbortableApiServiceFactory } from "../../../../hooks";
import { SmsSuiviApi, SmsSuiviSearch } from "../../../../api";
import { SearchTablePage } from "../../../../components";
import { ETLCodes } from "../../../../locales";
import { ERoutes } from "../../../../AppRouter";
import { IDataTableColumn } from "nsitools-react";

export interface ISmsSuiviPageProps {
  idLot: number;
}

export const SmsSuiviPage: React.FunctionComponent<ISmsSuiviPageProps> = ({ idLot }) => {
  const { t } = useTl();
  const api = useApiService(SmsSuiviApi);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.DateNaissance),
        fieldName: "dateNaissance"
      },
      {
        header: () => t(ETLCodes.Numero),
        fieldName: "numero"
      },
      {
        header: () => t(ETLCodes.Status),
        fieldName: "statut"
      }
    ],
    [t]
  );

  const getCriteriasFn = React.useCallback(() => api.smsSuiviGetSearchCriterias({ includeListsValues: true }), [api]);

  const apiFactory = useAbortableApiServiceFactory(SmsSuiviApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: SmsSuiviSearch) => {
      sObj.idlot = idLot;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.smsSuiviBaseSearch({ SmsSuiviSearch: sObj });
    },
    [apiFactory, idLot]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.SuiviSms), route: ERoutes.sms }]}
      sortKeys={{ nom: "ASC" }}
      withCard={false}
    />
  );
};
