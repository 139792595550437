/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbFormateurDiplomeDto,
  FcbFormateurDiplomeGridDtoPaginatedResults,
  FilterCriteriaInfo,
  FormateurDiplomeSearch,
  SelectItem,
} from '../models/index';
import {
    FcbFormateurDiplomeDtoFromJSON,
    FcbFormateurDiplomeDtoToJSON,
    FcbFormateurDiplomeGridDtoPaginatedResultsFromJSON,
    FcbFormateurDiplomeGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    FormateurDiplomeSearchFromJSON,
    FormateurDiplomeSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface FormateurDiplomeBaseSearchRequest {
    FormateurDiplomeSearch?: FormateurDiplomeSearch;
}

export interface FormateurDiplomeDeleteRequest {
    id?: number;
}

export interface FormateurDiplomeGetRequest {
    id?: number;
}

export interface FormateurDiplomeGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface FormateurDiplomeGetSelectItemsRequest {
    searchField: string;
}

export interface FormateurDiplomeSaveRequest {
    FcbFormateurDiplomeDto?: FcbFormateurDiplomeDto;
}

/**
 * 
 */
export class FormateurDiplomeApi extends runtime.BaseAPI {

    /**
     */
    async formateurDiplomeBaseSearchRaw(requestParameters: FormateurDiplomeBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurDiplomeGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurDiplome/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormateurDiplomeSearchToJSON(requestParameters.FormateurDiplomeSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurDiplomeGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async formateurDiplomeBaseSearch(requestParameters: FormateurDiplomeBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurDiplomeGridDtoPaginatedResults> {
        const response = await this.formateurDiplomeBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurDiplomeDeleteRaw(requestParameters: FormateurDiplomeDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurDiplome`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async formateurDiplomeDelete(requestParameters: FormateurDiplomeDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.formateurDiplomeDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurDiplomeGetRaw(requestParameters: FormateurDiplomeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurDiplomeDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurDiplome`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurDiplomeDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurDiplomeGet(requestParameters: FormateurDiplomeGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurDiplomeDto> {
        const response = await this.formateurDiplomeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurDiplomeGetSearchCriteriasRaw(requestParameters: FormateurDiplomeGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurDiplome/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async formateurDiplomeGetSearchCriterias(requestParameters: FormateurDiplomeGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.formateurDiplomeGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurDiplomeGetSelectItemsRaw(requestParameters: FormateurDiplomeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling formateurDiplomeGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurDiplome/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async formateurDiplomeGetSelectItems(requestParameters: FormateurDiplomeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.formateurDiplomeGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurDiplomeSaveRaw(requestParameters: FormateurDiplomeSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurDiplomeDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurDiplome`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbFormateurDiplomeDtoToJSON(requestParameters.FcbFormateurDiplomeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurDiplomeDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurDiplomeSave(requestParameters: FormateurDiplomeSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurDiplomeDto> {
        const response = await this.formateurDiplomeSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
