import { Intent } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import {
  ExperienceProfessionnelleApi,
  ExperienceProfessionnelleGridDto,
  ExperienceProfessionnelleSearchDto
} from "../../../../../../api";
import { AddButton, EditButton, ViewButton } from "../../../../../../components";
import { useApiService, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

export interface IExperienceProfessionnelleListProps {
  idpersonne: number;
  listRoute: string;
}

export const ExperienceProfessionnelleList: React.FunctionComponent<IExperienceProfessionnelleListProps> = ({
  idpersonne,
  listRoute
}) => {
  const { t } = useTl();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const api = useApiService(ExperienceProfessionnelleApi);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [5],
    pageSize: 5
  });

  const searchFunc = React.useCallback(
    (sObj?: ExperienceProfessionnelleSearchDto) => {
      sObj.idpersonne = idpersonne;
      return api.experienceProfessionnelleBaseSearch({ ExperienceProfessionnelleSearchDto: sObj });
    },
    [api, idpersonne]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction: searchFunc,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ExperienceProfessionnelleGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() =>
                history.push(`${listRoute}/experienceProfessionnelle/${row.idexperienceProfessionnelle}/view`)
              }
            />
            <EditButton
              minimal={true}
              onClick={() =>
                history.push(`${listRoute}/experienceProfessionnelle/${row.idexperienceProfessionnelle}/edit`)
              }
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Fonction),
        fieldName: "fonction"
      },
      {
        header: () => t(ETLCodes.DateDebut),
        fieldName: "dateDeDebut"
      },
      {
        header: () => t(ETLCodes.DateFin),
        fieldName: "dateDeFin"
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      }
    ],
    [history, listRoute, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${listRoute}/experienceProfessionnelle/0/edit`);
  }, [history, listRoute]);

  return (
    <>
      {+id > 0 && (
        <FieldSet
          title={`${t(ETLCodes.ExperienceProfessionnelle)}`}
          rightElement={
            <>
              <AddButton
                onClick={e => {
                  e.stopPropagation();
                  onAddItem();
                }}
                text={t(ETLCodes.NewExperiencePro)}
                intent={Intent.PRIMARY}
              />
            </>
          }
        >
          <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
        </FieldSet>
      )}
    </>
  );
};
