/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorDetails,
  ExposedTypes,
  FrontendSettings,
  ModuleDto,
  SystemInfoDto,
} from '../models/index';
import {
    ErrorDetailsFromJSON,
    ErrorDetailsToJSON,
    ExposedTypesFromJSON,
    ExposedTypesToJSON,
    FrontendSettingsFromJSON,
    FrontendSettingsToJSON,
    ModuleDtoFromJSON,
    ModuleDtoToJSON,
    SystemInfoDtoFromJSON,
    SystemInfoDtoToJSON,
} from '../models/index';

/**
 * 
 */
export class SystemApi extends runtime.BaseAPI {

    /**
     */
    async systemCheckExceptionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ErrorDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/System/fail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ErrorDetailsFromJSON(jsonValue));
    }

    /**
     */
    async systemCheckException(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ErrorDetails> {
        const response = await this.systemCheckExceptionRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async systemForceUpdateVersionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/System/ForceUpdateVersion`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async systemForceUpdateVersion(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.systemForceUpdateVersionRaw(initOverrides);
    }

    /**
     */
    async systemGeFrontendSettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FrontendSettings>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/System/fe-settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FrontendSettingsFromJSON(jsonValue));
    }

    /**
     */
    async systemGeFrontendSettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FrontendSettings> {
        const response = await this.systemGeFrontendSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async systemGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SystemInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/System`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SystemInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async systemGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SystemInfoDto> {
        const response = await this.systemGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async systemGetExposedTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExposedTypes>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/System/exposed-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExposedTypesFromJSON(jsonValue));
    }

    /**
     */
    async systemGetExposedTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExposedTypes> {
        const response = await this.systemGetExposedTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async systemGetModulesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ModuleDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/System/modules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ModuleDtoFromJSON));
    }

    /**
     */
    async systemGetModules(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ModuleDto>> {
        const response = await this.systemGetModulesRaw(initOverrides);
        return await response.value();
    }

}
