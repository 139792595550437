/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EOrigineInscription } from './EOrigineInscription';
import {
    EOrigineInscriptionFromJSON,
    EOrigineInscriptionFromJSONTyped,
    EOrigineInscriptionToJSON,
} from './EOrigineInscription';
import type { EStatutDemande } from './EStatutDemande';
import {
    EStatutDemandeFromJSON,
    EStatutDemandeFromJSONTyped,
    EStatutDemandeToJSON,
} from './EStatutDemande';

/**
 * 
 * @export
 * @interface DashboardInscriptionGridDto
 */
export interface DashboardInscriptionGridDto {
    /**
     * 
     * @type {number}
     * @memberof DashboardInscriptionGridDto
     */
    iddemandeInscription?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardInscriptionGridDto
     */
    idparcoursFormation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardInscriptionGridDto
     */
    idinscription?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardInscriptionGridDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardInscriptionGridDto
     */
    apprenant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardInscriptionGridDto
     */
    formation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardInscriptionGridDto
     */
    anneeAcademique?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardInscriptionGridDto
     */
    typeCours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardInscriptionGridDto
     */
    degre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardInscriptionGridDto
     */
    metier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardInscriptionGridDto
     */
    centre?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardInscriptionGridDto
     */
    idstatut?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardInscriptionGridDto
     */
    statut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardInscriptionGridDto
     */
    type?: string | null;
    /**
     * 
     * @type {EStatutDemande}
     * @memberof DashboardInscriptionGridDto
     */
    codeStatut?: EStatutDemande;
    /**
     * 
     * @type {Date}
     * @memberof DashboardInscriptionGridDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {EOrigineInscription}
     * @memberof DashboardInscriptionGridDto
     */
    origine?: EOrigineInscription;
    /**
     * 
     * @type {number}
     * @memberof DashboardInscriptionGridDto
     */
    idserviceTutelle?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardInscriptionGridDto
     */
    masquee?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DashboardInscriptionGridDto
     */
    encodeur?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardInscriptionGridDto
     */
    envoyeur?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardInscriptionGridDto
     */
    ancienneClasse?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardInscriptionGridDto
     */
    idenvoyeur?: number | null;
}

/**
 * Check if a given object implements the DashboardInscriptionGridDto interface.
 */
export function instanceOfDashboardInscriptionGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DashboardInscriptionGridDtoFromJSON(json: any): DashboardInscriptionGridDto {
    return DashboardInscriptionGridDtoFromJSONTyped(json, false);
}

export function DashboardInscriptionGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardInscriptionGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iddemandeInscription': !exists(json, 'iddemandeInscription') ? undefined : json['iddemandeInscription'],
        'idparcoursFormation': !exists(json, 'idparcoursFormation') ? undefined : json['idparcoursFormation'],
        'idinscription': !exists(json, 'idinscription') ? undefined : json['idinscription'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'apprenant': !exists(json, 'apprenant') ? undefined : json['apprenant'],
        'formation': !exists(json, 'formation') ? undefined : json['formation'],
        'anneeAcademique': !exists(json, 'anneeAcademique') ? undefined : json['anneeAcademique'],
        'typeCours': !exists(json, 'typeCours') ? undefined : json['typeCours'],
        'degre': !exists(json, 'degre') ? undefined : json['degre'],
        'metier': !exists(json, 'metier') ? undefined : json['metier'],
        'centre': !exists(json, 'centre') ? undefined : json['centre'],
        'idstatut': !exists(json, 'idstatut') ? undefined : json['idstatut'],
        'statut': !exists(json, 'statut') ? undefined : json['statut'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'codeStatut': !exists(json, 'codeStatut') ? undefined : EStatutDemandeFromJSON(json['codeStatut']),
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'origine': !exists(json, 'origine') ? undefined : EOrigineInscriptionFromJSON(json['origine']),
        'idserviceTutelle': !exists(json, 'idserviceTutelle') ? undefined : json['idserviceTutelle'],
        'masquee': !exists(json, 'masquee') ? undefined : json['masquee'],
        'encodeur': !exists(json, 'encodeur') ? undefined : json['encodeur'],
        'envoyeur': !exists(json, 'envoyeur') ? undefined : json['envoyeur'],
        'ancienneClasse': !exists(json, 'ancienneClasse') ? undefined : json['ancienneClasse'],
        'idenvoyeur': !exists(json, 'idenvoyeur') ? undefined : json['idenvoyeur'],
    };
}

export function DashboardInscriptionGridDtoToJSON(value?: DashboardInscriptionGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iddemandeInscription': value.iddemandeInscription,
        'idparcoursFormation': value.idparcoursFormation,
        'idinscription': value.idinscription,
        'idapprenant': value.idapprenant,
        'apprenant': value.apprenant,
        'formation': value.formation,
        'anneeAcademique': value.anneeAcademique,
        'typeCours': value.typeCours,
        'degre': value.degre,
        'metier': value.metier,
        'centre': value.centre,
        'idstatut': value.idstatut,
        'statut': value.statut,
        'type': value.type,
        'codeStatut': EStatutDemandeToJSON(value.codeStatut),
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'origine': EOrigineInscriptionToJSON(value.origine),
        'idserviceTutelle': value.idserviceTutelle,
        'masquee': value.masquee,
        'encodeur': value.encodeur,
        'envoyeur': value.envoyeur,
        'ancienneClasse': value.ancienneClasse,
        'idenvoyeur': value.idenvoyeur,
    };
}

