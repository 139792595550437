import { FGMaskInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { FcbUniteActiviteDtoFromJSON, UniteActiviteApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { FGWalterSelectInput, SmallFormGenerator } from "../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";
import { ETLCodes } from "../../../../locales";

export interface IUniteActiviteDetailPageProps {
  idUnite: number;
  editMode: boolean;
}

export const UniteActiviteDetailPage: React.FunctionComponent<IUniteActiviteDetailPageProps> = ({
  idUnite,
  editMode
}) => {
  const { t } = useTl();
  const api = useApiService(UniteActiviteApi);
  const history = useHistory();
  const [annees, aLoading] = useReferential(a => a.referentialGetAnneeScolaire(), false, []);
  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      anneeAcademique: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idUnite <= 0 ? FcbUniteActiviteDtoFromJSON({ idUnite: 0 }) : api.uniteActiviteGet({ id: +idUnite }),
    saveApiFn: d => api.uniteActiviteSave({ FcbUniteActiviteDto: d }),
    onSavedRoute: d => `${ERoutes.uniteActivite}/${d.idUnite}/detail/edit`,
    deleteApiFn: d => api.uniteActiviteDelete({ id: d.idUnite }),
    onDeletedRoute: () => ERoutes.uniteActivite
  });

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={editMode}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(ERoutes.uniteActivite)}
        onDelete={deleteItem}
        showDeleteButton={+idUnite > 0}
        saving={saving}
        deleting={deleting}
      >
        <FieldGroup>
          <FGWalterSelectInput
            name="anneeAcademique"
            label={t(ETLCodes.AnneeScolaire)}
            items={annees}
            loading={aLoading}
          />
          <FGMaskInput
            label={t(ETLCodes.AAPS1)}
            name="aaS1"
            cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
          />
          <FGMaskInput
            label={t(ETLCodes.AAPS2)}
            name="aaS2"
            cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
          />
          <FGMaskInput
            label={t(ETLCodes.BAPS1)}
            name="abS1"
            cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
          />
          <FGMaskInput
            label={t(ETLCodes.BAPS2)}
            name="abS2"
            cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
          />
          <FGMaskInput
            label={t(ETLCodes.IAPS1)}
            name="aiS1"
            cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
          />
          <FGMaskInput
            label={t(ETLCodes.IAPS2)}
            name="aiS2"
            cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
          />
          <FGMaskInput
            label={t(ETLCodes.ACES1)}
            name="ceaS1"
            cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
          />
          <FGMaskInput
            label={t(ETLCodes.ACES2)}
            name="ceaS2"
            cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
          />
          <FGMaskInput
            label={t(ETLCodes.BCES1)}
            name="cebS1"
            cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
          />
          <FGMaskInput
            label={t(ETLCodes.BCES2)}
            name="cebS2"
            cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
          />
          <FGMaskInput
            label={t(ETLCodes.ICES1)}
            name="ceiS1"
            cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
          />
          <FGMaskInput
            label={t(ETLCodes.IECS2)}
            name="ceiS2"
            cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
          />
          <FGMaskInput
            label={t(ETLCodes.FAGS1)}
            name="fagS1"
            cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
          />
          <FGMaskInput
            label={t(ETLCodes.FAGS2)}
            name="fagS2"
            cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
          />
          <FGMaskInput
            label={t(ETLCodes.IndiceRecyclageS1)}
            name="recyclageS1"
            cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
          />
          <FGMaskInput
            label={t(ETLCodes.IndiceRecyclageS2)}
            name="recyclageS2"
            cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
          />
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
