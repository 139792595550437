import { Checkbox } from "@blueprintjs/core";
import { IDataTableColumn, showInfo, showSuccess } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { ClasseApi, ClasseSearch, ETextSearchType, FcbClasseGridDto, ReportApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { CopyButton, EditButton, PrintButton, SearchTablePage, ViewButton } from "../../../../components";
import { useGlobalData, usePrintingQueueContext } from "../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useManageError, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface IClasseListPageProps {}

const Container = styled.div`
  display: flex;
`;

export const ClasseListPage: React.FunctionComponent<IClasseListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ClasseApi);
  const reportApi = useApiService(ReportApi);
  const { refreshQueue, setIsQueueForcedOpen } = usePrintingQueueContext();
  const { currentAnneeScolaire } = useGlobalData();
  const { manageError } = useManageError();

  const [printLoadingId, setPrintLoadingId] = React.useState(null);

  const print = React.useCallback(
    async (idClasse: number) => {
      setPrintLoadingId(idClasse);

      try {
        showInfo(t(ETLCodes.AddingReportToGenerationQueue));
        await reportApi.reportSendToQueue({
          reportName: "horaire_classe.rpt",
          ReportParametersDto: {
            parameters: [
              {
                dataType: "NumberParameter",
                name: "idclasse",
                value: idClasse.toString()
              }
            ]
          }
        });
        refreshQueue();
        setIsQueueForcedOpen(true);
        showSuccess(t(ETLCodes.ReportAddedToQueue));
      } catch (e) {
        console.error(e);
        manageError(e, t(ETLCodes.ErrorWhileAddingReportToQueue));
      } finally {
        setPrintLoadingId(null);
      }
    },
    [manageError, refreshQueue, reportApi, setIsQueueForcedOpen, t]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbClasseGridDto) => (
          <Container>
            <PrintButton
              minimal={true}
              onClick={() => print(row.idclasse)}
              loading={printLoadingId && printLoadingId === row.idclasse}
            />
            <CopyButton minimal={true} onClick={() => history.push(`${ERoutes.classe}/${row.idclasse}/copie/edit/`)} />
            <ViewButton minimal={true} onClick={() => history.push(`${ERoutes.classe}/${row.idclasse}/detail/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${ERoutes.classe}/${row.idclasse}/detail/edit`)} />
          </Container>
        )
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code"
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "annee"
      },
      {
        header: () => t(ETLCodes.Lieu),
        fieldName: "lieu"
      },
      {
        header: () => t(ETLCodes.CoherenceHoraire),
        fieldName: "horaireCoherent",
        alignment: "center",
        render: (row: FcbClasseGridDto) => <Checkbox checked={row.horaireCoherent} onClick={() => {}} disabled />
      },
      {
        header: () => t(ETLCodes.Referentiel),
        fieldName: "referentiel"
      },
      {
        header: () => t(ETLCodes.HeureS1),
        fieldName: "heureS1"
      },
      {
        header: () => t(ETLCodes.HeureS2),
        fieldName: "heureS2"
      }
    ],
    [history, print, printLoadingId, t]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.classe}/0/detail/edit`);
  }, [history]);

  const getCriterias = React.useCallback(() => api.classeGetSearchCriterias({ includeListsValues: true }), [api]);
  const apiFactory = useAbortableApiServiceFactory(ClasseApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (nextSearch?: ClasseSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.classeBaseSearch({ ClasseSearch: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      columns={columns}
      getCriteriasFunction={getCriterias}
      searchFunction={search}
      onAbort={onAbort}
      addFunc={addItemFunction}
      searchStateInitialSearch={false}
      sortKeys={{ code: "ASC" }}
      breadCrumbs={[{ text: t(ETLCodes.Classes) }]}
      defaultCriterias={[
        {
          criteria: "Annee",
          searchMode: ETextSearchType.Equals,
          value: currentAnneeScolaire?.idanneeScolaire
        }
      ]}
    />
  );
};
