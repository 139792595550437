/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AnneeReussieSearch,
  FcbAnneeReussieDto,
  FcbAnneeReussieDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    AnneeReussieSearchFromJSON,
    AnneeReussieSearchToJSON,
    FcbAnneeReussieDtoFromJSON,
    FcbAnneeReussieDtoToJSON,
    FcbAnneeReussieDtoPaginatedResultsFromJSON,
    FcbAnneeReussieDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface AnneeReussieBaseSearchRequest {
    AnneeReussieSearch?: AnneeReussieSearch;
}

export interface AnneeReussieDeleteRequest {
    id?: number;
}

export interface AnneeReussieGetRequest {
    id?: number;
}

export interface AnneeReussieGetDisplayNameRequest {
    id?: number;
}

export interface AnneeReussieGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface AnneeReussieGetSelectItemsRequest {
    searchField: string;
}

export interface AnneeReussieSaveRequest {
    FcbAnneeReussieDto?: FcbAnneeReussieDto;
}

/**
 * 
 */
export class AnneeReussieApi extends runtime.BaseAPI {

    /**
     */
    async anneeReussieBaseSearchRaw(requestParameters: AnneeReussieBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbAnneeReussieDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AnneeReussie/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnneeReussieSearchToJSON(requestParameters.AnneeReussieSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbAnneeReussieDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async anneeReussieBaseSearch(requestParameters: AnneeReussieBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbAnneeReussieDtoPaginatedResults> {
        const response = await this.anneeReussieBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async anneeReussieDeleteRaw(requestParameters: AnneeReussieDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AnneeReussie`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async anneeReussieDelete(requestParameters: AnneeReussieDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.anneeReussieDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async anneeReussieGetRaw(requestParameters: AnneeReussieGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbAnneeReussieDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AnneeReussie`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbAnneeReussieDtoFromJSON(jsonValue));
    }

    /**
     */
    async anneeReussieGet(requestParameters: AnneeReussieGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbAnneeReussieDto> {
        const response = await this.anneeReussieGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async anneeReussieGetDisplayNameRaw(requestParameters: AnneeReussieGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AnneeReussie/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async anneeReussieGetDisplayName(requestParameters: AnneeReussieGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.anneeReussieGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async anneeReussieGetSearchCriteriasRaw(requestParameters: AnneeReussieGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AnneeReussie/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async anneeReussieGetSearchCriterias(requestParameters: AnneeReussieGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.anneeReussieGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async anneeReussieGetSelectItemsRaw(requestParameters: AnneeReussieGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling anneeReussieGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AnneeReussie/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async anneeReussieGetSelectItems(requestParameters: AnneeReussieGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.anneeReussieGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async anneeReussieSaveRaw(requestParameters: AnneeReussieSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbAnneeReussieDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AnneeReussie`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbAnneeReussieDtoToJSON(requestParameters.FcbAnneeReussieDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbAnneeReussieDtoFromJSON(jsonValue));
    }

    /**
     */
    async anneeReussieSave(requestParameters: AnneeReussieSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbAnneeReussieDto> {
        const response = await this.anneeReussieSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
