import { Classes, ControlGroup, Dialog, NonIdealState, Radio, RadioGroup } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";
import {
  PersonneApi,
  TelephoneDto,
  TelephoneDtoFromJSON,
  TelephoneReplaceDto,
  TelephoneReplaceDtoFromJSON
} from "../../../../../../../api";
import { DeleteButton, FGWalterSelectInput, SmallFormGenerator } from "../../../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../../../hooks";
import { ETLCodes } from "../../../../../../../locales";
import * as Yup from "yup";
import { phoneRegex } from "../../../../../../../utils/phoneRegex";
import { FGCustomPanel, FGTextInput } from "nsitools-react";
import { useReferential } from "../../../../../../../hooks/useReferential";

const StyledDialog = styled(Dialog)`
  width: 600px;
  height: auto;
  background-color: white;

  & .${Classes.RADIO} {
    align-self: center;
    min-width: 125px;
  }
`;

const StyledNonIdealState = styled(NonIdealState)`
  margin-bottom: 1rem;
`;

const StyledControlGroup = styled(ControlGroup)`
  & > .numero {
    margin-left: 0.5rem !important;
  }
`;

export interface ITelephoneReplaceDialogProps {
  dialogOpen: boolean;
  onClose: (replaced?: TelephoneReplaceDto) => void;
  idpersonne: number;
  currentTel: TelephoneDto;
}

export const TelephoneReplaceDialog: React.FunctionComponent<ITelephoneReplaceDialogProps> = ({
  dialogOpen,
  onClose,
  idpersonne,
  currentTel
}) => {
  const { t } = useTl();
  const api = useApiService(PersonneApi);

  const { data, loading, saveItem, saving } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () =>
          TelephoneReplaceDtoFromJSON({
            idpersonne,
            oldIdtelephone: currentTel?.idtelephone,
            isNew: false
          }),
        saveApiFn: d => api.personneReplacePhone({ TelephoneReplaceDto: d }),
        onSaved: d => onClose(d)
      }),
      [api, currentTel?.idtelephone, idpersonne, onClose]
    )
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape({
        oldIdtelephone: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        newIdtelephone: Yup.number().nullable(),
        numero: Yup.string()
          .nullable()
          .when("isNew", {
            is: true,
            then: Yup.string()
              .required(t(ETLCodes.Required))
              .test("tel-valid", t(ETLCodes.ErrorPhoneRegex), function(value) {
                if (!!this.parent.newIdtelephone || !value) return true;
                return !!value.match(phoneRegex);
              })
          }),
        idtypeTelephone: Yup.number()
          .nullable()
          .when("isNew", {
            is: true,
            then: Yup.number().required(t(ETLCodes.Required))
          })
      }),
    [t]
  );

  const [typeTels, ttLoading] = useReferential(a => a.referentialGetTypesTelephone());
  const [fullPersonneTelephones, personneTelephonesLoading] = useReferential(
    a => a.referentialGetAllTelephonesPersonne({ idpersonne }),
    false,
    [idpersonne]
  );
  const personneTelephones = React.useMemo(
    () => fullPersonneTelephones?.filter(t => +t.value !== currentTel.idtelephone),
    [currentTel.idtelephone, fullPersonneTelephones]
  );

  const deleteTel = React.useCallback(
    async (telId: number) => {
      await api.personneDeleteTel({ idtelephone: telId });
      onClose(TelephoneDtoFromJSON({ oldIdtelephone: telId }));
    },
    [api, onClose]
  );

  return (
    <StyledDialog title={t(ETLCodes.TelephoneLinked)} isOpen={dialogOpen} onClose={() => onClose()}>
      <div className={Classes.DIALOG_BODY}>
        <StyledNonIdealState
          icon="warning-sign"
          description={t(ETLCodes.TelephoneUsedWarning, { numero: currentTel.numero })}
        />
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          loading={loading}
          onCancel={() => onClose()}
          showDeleteButton={false}
          saving={saving}
        >
          <FGCustomPanel>
            {ctx => (
              <RadioGroup
                selectedValue={ctx?.formik?.values?.isNew ? "1" : "0"}
                onChange={e => {
                  if (e.currentTarget.value === "1") {
                    ctx.formik.setFieldValue("isNew", true);
                    ctx.formik.setFieldValue("newIdtelephone", null);
                  } else {
                    ctx.formik.setFieldValue("isNew", false);
                    ctx.formik.setFieldValue("numero", null);
                    ctx.formik.setFieldValue("idtypeTelephone", null);
                  }
                }}
              >
                <Radio value="0" label={t(ETLCodes.ReplaceBy)}></Radio>
                <FGWalterSelectInput
                  noLabel
                  name="newIdtelephone"
                  items={personneTelephones}
                  loading={personneTelephonesLoading}
                  disabled={ctx?.formik?.values?.isNew}
                />
                <br />
                <Radio value="1" label={t(ETLCodes.New)}></Radio>
                <StyledControlGroup>
                  <FGWalterSelectInput
                    name="idtypeTelephone"
                    noLabel
                    items={typeTels}
                    loading={ttLoading}
                    disabled={!ctx?.formik?.values?.isNew}
                  />
                  <FGTextInput
                    className="numero"
                    name="numero"
                    noLabel
                    type="tel"
                    maxLength={20}
                    disabled={!ctx?.formik?.values?.isNew}
                  />
                </StyledControlGroup>
                <br />
                <DeleteButton
                  text={t(ETLCodes.SupprimerLiens)}
                  onDelete={() => deleteTel(ctx?.formik?.values?.oldIdtelephone)}
                  minimal={false}
                />
              </RadioGroup>
            )}
          </FGCustomPanel>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
