import { Intent } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { FcbPlageHoraireGridDto, PlageHoraireApi, PlageHoraireSearch } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { AddButton, EditButton, ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { useApiService } from "../../../../../hooks/useApiService";
import { ETLCodes } from "../../../../../locales";

export interface ICentreHoraireListProps {}

export const CentreHoraireList: React.FunctionComponent<ICentreHoraireListProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const api = useApiService(PlageHoraireApi);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { description: "ASC" }
  });

  const searchFunction = React.useCallback(
    (sObj?: PlageHoraireSearch) => {
      sObj.idcentre = +id;
      return api.plageHoraireBaseSearch({ PlageHoraireSearch: sObj });
    },
    [api, id]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const { totalCount } = tableState;

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbPlageHoraireGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.centre}/${+id}/horaire/${row.idplage}/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.centre}/${+id}/horaire/${row.idplage}/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      }
    ],
    [history, id, t]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.centre}/${+id}/horaire/0/edit`);
  }, [history, id]);

  return (
    <FieldSet
      title={t(ETLCodes.TableResults, { count: totalCount })}
      rightElement={
        addItemFunction && (
          <AddButton
            onClick={e => {
              e.stopPropagation();
              addItemFunction();
            }}
            text={t(ETLCodes.General_Add)}
            intent={Intent.PRIMARY}
          />
        )
      }
    >
      <DataTable
        dateFormat="dd-MM-yyyy"
        tableState={tableState}
        loading={loading}
        columns={columns}
        filterMode="OnEnter"
      />
    </FieldSet>
  );
};
