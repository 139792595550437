import { Classes, Dialog } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";
import {
  LieuFormationAgrementApi,
  LieuFormationAgrementDemandeSectorielleMultipleDto,
  LieuFormationAgrementDemandeSectorielleMultipleDtoFromJSON
} from "../../../../../../api";
import { useApiService, useCrudApi, useTl } from "../../../../../../hooks";
import * as Yup from "yup";
import { ETLCodes } from "../../../../../../locales";
import { SmallFormGenerator } from "../../../../../../components";
import { FieldGroup } from "nsitools-react";
import { LieuFormationAgrementDemandeSectorielle } from "..";
import { endOfToday } from "date-fns";

const StyledDialog = styled(Dialog)`
  width: 700px;
  height: auto;
  background-color: white;
`;

export interface ILieuFormationAgrementDemandeSectorielleDialogProps {
  idslieuFormationAgrement: number[];
  dialogOpen: boolean;
  onClose: (refresh: boolean) => void;
}

export const LieuFormationAgremantDemandeSectorielleDialog: React.FunctionComponent<ILieuFormationAgrementDemandeSectorielleDialogProps> = ({
  idslieuFormationAgrement = [],
  dialogOpen,
  onClose
}) => {
  const { t } = useTl();
  const api = useApiService(LieuFormationAgrementApi);

  const { data, loading, saveItem, saving, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () => {
          return LieuFormationAgrementDemandeSectorielleMultipleDtoFromJSON({
            idslieuFormationAgrement: idslieuFormationAgrement
          });
        },
        saveApiFn: async (d: LieuFormationAgrementDemandeSectorielleMultipleDto) => {
          return api.lieuFormationAgrementDemandeSectorielleMultiple({
            LieuFormationAgrementDemandeSectorielleMultipleDto: d
          });
        },
        onSaved: () => onClose(true)
      }),
      [api, idslieuFormationAgrement, onClose]
    )
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape({
        idagrementAvis: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        dateEnvoiD1: Yup.date()
          .nullable()
          .max(endOfToday(), t(ETLCodes.DateCannotBeInFurture)),
        dateReceptionAvis: Yup.date()
          .nullable()
          .max(endOfToday(), t(ETLCodes.DateCannotBeInFurture))
      }),
    [t]
  );

  return (
    <StyledDialog title={t(ETLCodes.DemandeSectorielle)} isOpen={dialogOpen} onClose={() => onClose(false)}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose(false)}
          showDeleteButton={false}
          saving={saving}
          loading={loading}
          validationErrors={validationErrors}
        >
          <FieldGroup>
            <LieuFormationAgrementDemandeSectorielle />
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
