import * as React from "react";
import { Route, Switch } from "react-router";

import { ERoutes } from "../../../../../AppRouter";
import { MetierClausesLiees } from "./MetierClausesLiees";
import { MetierClausesLieesSelector } from "./MetierClausesLieesSelector";

export interface IMetierClausesLieesSwitchProps {}

export const MetierClausesLieesSwitch: React.FunctionComponent<IMetierClausesLieesSwitchProps> = () => {
  return (
    <Switch>
      <Route path={`${ERoutes.metier}/:id/:tab`} component={MetierClausesLiees} exact />
      <Route path={`${ERoutes.metier}/:id/:tab/selector`} component={MetierClausesLieesSelector} />
    </Switch>
  );
};
