import { FGCustomPanel, FGListen, FGNumberInput, FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";

import { FcbSmsUtilitaireDetailDtoFromJSON, SmsUtilitaireApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { FGWalterDateMaskInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../components";
import { FGEuroMaskInput } from "../../../../components/formGenerator/FGEuroMaskInput";
import { useRouteParamsContext } from "../../../../contexts";
import { useApiService, useCrudApi, useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";
import { ETLCodes } from "../../../../locales";

const SignatureMessageContainer = styled.span`
  & > div {
    display: inline-block;
    min-width: 180px;
    margin-right: 10px;
  }
`;

export interface ISmsDetailPageProps {
  idLot: number;
  editMode: boolean;
}

export const SmsDetailPage: React.FunctionComponent<ISmsDetailPageProps> = ({ idLot, editMode }) => {
  const { t } = useTl();
  const api = useApiService(SmsUtilitaireApi);
  const history = useHistory();
  const [idCentre, setIdCentre] = React.useState<number>(0);
  const { popSmsRecipientIds, popSmsRecipientType } = useRouteParamsContext();
  const [extractedRecipientIds] = React.useState(() => popSmsRecipientIds());
  const [extractedRecipientType] = React.useState(() => popSmsRecipientType());
  const [type, tLoading] = useReferential(a => a.referentialGetTypeLotSms(), true, []);
  const [centre, cLoading] = useReferential(a => a.referentialGetCentreByUser({ idCentre: idCentre }), true, [
    idCentre
  ]);
  const [etat, eLoading] = useReferential(a => a.referentialGetEtatLotSms(), true, []);
  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      description: Yup.string().required(t(ETLCodes.Required)),
      idtype: Yup.number().required(t(ETLCodes.Required)),
      idcentre: Yup.number().required(t(ETLCodes.Required))
    });
  }, [t]);

  const { data, loading, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idLot <= 0
        ? FcbSmsUtilitaireDetailDtoFromJSON({ idLot: 0, idetat: 1, nbDestinataires: extractedRecipientIds.length })
        : api.smsUtilitaireGet({ id: +idLot }),
    saveApiFn: d =>
      api.smsUtilitaireSave({
        FcbSmsUtilitaireDetailDto: { ...d, recipientIds: extractedRecipientIds, typeRecipient: extractedRecipientType }
      }),
    onSavedRoute: d => `${ERoutes.sms}/${d.idlot}/detail/edit`,
    deleteApiFn: d => {},
    onDeletedRoute: () => ERoutes.sms
  });

  return (
    <>
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={editMode}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(ERoutes.sms)}
        onDelete={() => {}}
        showDeleteButton={false}
        saving={saving}
        deleting={false}
        hideButtons={extractedRecipientIds.length === 0 || !extractedRecipientType}
      >
        <FGListen field="idcentre" onChanged={value => setIdCentre(value)} />
        <FieldGroup>
          <FGTextInput name="description" label={t(ETLCodes.Libelle)} maxLength={150} />
          <FGWalterSelectInput name="idtype" label={t(ETLCodes.Type)} items={type} loading={tLoading} />
          <FGWalterSelectInput name="idcentre" label={t(ETLCodes.Centre)} items={centre} loading={cLoading} />
          <FGTextAreaInput name="message" label={t(ETLCodes.Message)} maxLength={120} />
          <FGCustomPanel>
            {ctx => (
              <SignatureMessageContainer>
                <div></div>
                {t(ETLCodes.AddedToMessageAutomatically)}: {t(ETLCodes.MerciDeNePasRepondre)}
              </SignatureMessageContainer>
            )}
          </FGCustomPanel>
          <FGWalterSelectInput name="idetat" label={t(ETLCodes.Statut)} items={etat} loading={eLoading} readonly />
          <FGNumberInput name="nbDestinataires" label={t(ETLCodes.NbrDestinataire)} readonly />
          {idLot > 0 && (
            <FieldGroup columns={[4, 3]}>
              <FGEuroMaskInput name="coutsms" label={t(ETLCodes.CoutSms)} readonly />
              <FGTextInput label={t(ETLCodes.CreatedBy)} name="creationUserName" readonly />
              <FGWalterDateMaskInput
                label={t(ETLCodes.CreatedOn)}
                name="creationDate"
                readonly
                showPlaceholder={false}
              />
              <FGTextInput label={t(ETLCodes.ModifiedBy)} name="modificationUserName" readonly />
              <FGWalterDateMaskInput
                label={t(ETLCodes.ModifiedOn)}
                name="modificationDate"
                readonly
                showPlaceholder={false}
              />
              {!!data?.dateEnvoi && (
                <FGWalterDateMaskInput label={t(ETLCodes.EnvoyeLe)} name="dateEnvoi" readonly showPlaceholder={false} />
              )}
            </FieldGroup>
          )}
        </FieldGroup>
      </SmallFormGenerator>
    </>
  );
};
