import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";

import { ServiceTutelleDetailPage } from "..";
import { ServiceTutelleApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { ServiceTutelleLocaliteLieeSwitch } from "./ServiceTutelleLocaliteLieeSwitch";
import { ServiceTutelleUserLiePage } from "./ServiceTutelleUserLiePage";

interface IServiceTutelleItemPageProps {}

export const ServiceTutelleItemPage: React.FunctionComponent<IServiceTutelleItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const serviceTutelleApi = useApiService(ServiceTutelleApi);
  const [displayName] = useApiEffect(() => serviceTutelleApi.serviceTutelleGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.ServicesAlternance), route: ERoutes.serviceTutelle },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsServiceTutelle"
        onChange={newTabId => history.push(`${ERoutes.serviceTutelle}/${id}/${newTabId}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.serviceTutelle}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<ServiceTutelleDetailPage idServiceTutelle={+id} editMode={state === "edit"} />}
        />
        <Tab
          id="Localites"
          title={t(ETLCodes.Localites)}
          panel={<ServiceTutelleLocaliteLieeSwitch />}
          disabled={+id <= 0}
        />
        <Tab id="calc" title={t(ETLCodes.CALCRSA)} panel={<ServiceTutelleUserLiePage />} disabled={+id <= 0} />
      </ProtectedTabs>
    </PageBase>
  );
};
