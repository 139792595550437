import { FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { FcbProjetFinancementDtoFromJSON, ProjetFinancementApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterCheckboxInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

interface IProjetFinancementDetailPageProps {}

export const ProjetFinancementDetailPage: React.FunctionComponent<IProjetFinancementDetailPageProps> = () => {
  const { t } = useTl();
  const api = useApiService(ProjetFinancementApi);
  const history = useHistory();
  const { id, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();

  const idProjetFinancement = React.useMemo(() => +id, [id]);
  const editMode = React.useMemo(() => state === "edit", [state]);

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idProjetFinancement <= 0
        ? FcbProjetFinancementDtoFromJSON({ idProjetFinancement: 0 })
        : api.projetFinancementGet({ id: +idProjetFinancement }),
    saveApiFn: d => api.projetFinancementSave({ FcbProjetFinancementDto: d }),
    onSavedRoute: d => `${ERoutes.projetFinancement}/${d.idprojetFinancement}/detail/${state}`,
    deleteApiFn: d => api.projetFinancementDelete({ id: d.idprojetFinancement }),
    onDeletedRoute: () => ERoutes.projetFinancement
  });

  const [idFinancementType, setIdFinacementType] = React.useState(0);

  React.useEffect(() => {
    if (data) {
      setIdFinacementType(data.idfinancementType);
    }
  }, [data]);

  const [typeFinancement, fLoading] = useReferential(
    a => a.referentialGetTypeFinancement({ currentId: idFinancementType }),
    true,
    [idFinancementType]
  );

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      libelle: Yup.string().required(t(ETLCodes.Required)),
      code: Yup.string().required(t(ETLCodes.Required)),
      idfinancementType: Yup.number().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    data && (
      <>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={editMode}
          validationSchema={FormSchema}
          loading={loading}
          onCancel={() => history.push(ERoutes.projetFinancement)}
          onDelete={deleteItem}
          showDeleteButton={+idProjetFinancement > 0}
          saving={saving}
          deleting={deleting}
        >
          <FieldGroup columns={2}>
            <FGTextInput name="code" label={t(ETLCodes.Code)} maxLength={50} />
            <FGTextInput name="libelle" label={t(ETLCodes.Libelle)} maxLength={50} />
            <FGWalterSelectInput
              name="idfinancementType"
              label={t(ETLCodes.TypesFinancement)}
              items={typeFinancement}
              loading={fLoading}
            />
            <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
          </FieldGroup>
        </SmallFormGenerator>
      </>
    )
  );
};
