import { ConfigurationParameters, EIfapmeSide } from "./api";

export let baseUrl = "~";

if (baseUrl === "~") {
  const hostUrl = window.location.href.split("/");
  baseUrl = hostUrl[0] + "//" + hostUrl[2];
}

export const apiConfiguration = (getAccessToken?: () => Promise<string>, side?: EIfapmeSide) => {
  const configuration: ConfigurationParameters = {
    basePath: baseUrl,
    accessToken: getAccessToken,
    headers: side
      ? {
          "ifapme-side": side
        }
      : {}
  };
  return configuration;
};

export const setApiUrl = (u: string) => {
  // eslint-disable-next-line no-console
  console.log("Set Api url with " + u);
  baseUrl = u;
};
