import { FGCustomPanel, FGListen, FGTextAreaInput, useFGContext } from "nsitools-react";
import * as React from "react";

import { FichierApi } from "../../../../../../api";
import { FGWalterDateMaskInput, FGWalterFileInput, FGWalterSelectInput } from "../../../../../../components";
import { useApiService, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

export interface ILieuFormationAgrementDemandeSectorielleProps {
  disabled?: boolean;
}

export const LieuFormationAgrementDemandeSectorielle: React.FunctionComponent<ILieuFormationAgrementDemandeSectorielleProps> = ({
  disabled
}) => {
  const { t } = useTl();
  const { formik } = useFGContext();
  const [avis, aLoading] = useReferential(a => a.referentialGetLieuFormationAgrementAvis(), true);

  const fapi = useApiService(FichierApi);
  const downloadFn = React.useCallback(
    async () => await fapi.fichierDownload({ id: formik.values.idfichierDemandeSectorielle }),
    [fapi, formik?.values?.idfichierDemandeSectorielle]
  );

  return (
    <FGCustomPanel>
      {ctx => (
        <>
          <FGListen
            field="fichierDemandeSectorielle"
            onChanged={(value, formik) => {
              if (formik.dirty) {
                formik.setFieldValue("idfichierDemandeSectorielle", null);
              }
            }}
          />
          <FGWalterDateMaskInput name="dateEnvoiD1" label={t(ETLCodes.DateEnvoiD1)} disabled={disabled} />
          <FGWalterDateMaskInput name="dateReceptionAvis" label={t(ETLCodes.DateReceptionAvis)} disabled={disabled} />
          <FGWalterSelectInput
            name="idagrementAvis"
            label={t(ETLCodes.Avis)}
            items={avis}
            loading={aLoading}
            disabled={disabled}
          />
          <FGTextAreaInput name="remarqueSectorielle" label={t(ETLCodes.Remarque)} disabled={disabled} />
          <FGWalterFileInput
            name="fichierDemandeSectorielle"
            label={t(ETLCodes.Document)}
            downloadFn={formik.values?.idfichierDemandeSectorielle && downloadFn}
            disabled={disabled}
          />
        </>
      )}
    </FGCustomPanel>
  );
};
