import { Button, ButtonGroup, Divider } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { add, format, isValid } from "date-fns";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import {
  DropDownButton,
  FGCustomPanel,
  FGMultiSuggestInput,
  FGNumberInput,
  FGTextInput,
  FieldGroup,
  showError,
  useDebounce,
  useFGFormik
} from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";

import {
  FGReadOnlyInput,
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  GenericProgressLoading,
  LoadingDots,
  PageBase,
  PdfViewer,
  SmallFormGenerator
} from ".";
import {
  ERapportHopeParameterType,
  ERapportHopeType,
  FileDownloadDto,
  RapportHopeApi,
  RapportHopeForViewDto,
  RapportHopeParameterDataDto,
  RapportHopeParameterDto
} from "../api";
import { useApiService, useTabMessage, useTl } from "../hooks";
import { useReferential } from "../hooks/useReferential";
import { ETLCodes } from "../locales";
import { b64toBlob, exportFile } from "../utils";
import { usePrevious } from "ahooks";
import isEqual from "lodash/isEqual";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoadingContainer = styled.div`
  padding: 0.4rem 0 0 0.6rem;
`;

const PDFContainer = styled.div``;

export interface IRapportHopeViewerProps {}

function parseEventualDate(value: string): any {
  if (!!value) {
    const date = new Date(value);
    if (isValid(date)) {
      return date;
    }
  }
  return value;
}

export const RapportHopeViewer: React.FunctionComponent<IRapportHopeViewerProps> = props => {
  const { t } = useTl();
  const api = useApiService(RapportHopeApi);
  const { search } = useLocation();
  const { id } = useParams<{ id: string }>();
  const idrapportHope = React.useMemo(() => +id, [id]);
  const cfs = React.useMemo(() => new URLSearchParams(search).get("cfs"), [search]);
  const { messageValue: entityIds } = useTabMessage(cfs);
  const [generating, setGenerating] = React.useState(false);
  const [generatedReport, setGeneratedReport] = React.useState<FileDownloadDto>(null);
  const file = React.useMemo(() => (generatedReport ? b64toBlob(generatedReport) : null), [generatedReport]);
  const [currentEntityIndex, setCurrentEntityIndex] = React.useState(null);
  const [updatedFormData, setUpdatedFormData] = React.useState<RapportHopeForViewDto>(null);

  const [initialValues, setInitialValues] = React.useState<any>(null);

  const { data: initialFormData, isFetching } = useQuery(
    ["rapport-hope-view", idrapportHope],
    () =>
      api.rapportHopeGetForView({
        GetRapportHopeForViewQuery: {
          idrapportHope,
          currentParameterValues: []
        }
      }),
    {
      enabled: !!idrapportHope,
      retry: false
    }
  );

  React.useEffect(() => {
    if (initialFormData && !initialValues) {
      let newData: any = {};

      initialFormData.parameters.forEach(p => {
        if (p.name === "userId" || p.name === "entityId" || p.name === "anepasutiliser") return;

        const isDateField = p.type === ERapportHopeParameterType.DateTime;
        if (p.isMultiValue) {
          newData[p.name] = p.defaultValues?.map(v => (isDateField ? parseEventualDate(v) : v));
        } else {
          if (!!p.defaultValues && p.defaultValues.length > 0) {
            const v = p.defaultValues.at(0);
            newData[p.name] = isDateField ? parseEventualDate(v) : v;
          }
        }
      });

      setInitialValues(newData);
    }
  }, [initialFormData, initialValues]);

  const [firstLoading, setFirstLoading] = React.useState(false);
  React.useEffect(() => {
    if (!firstLoading && !!initialFormData && !isFetching) {
      setFirstLoading(true);
    }
  }, [initialFormData, firstLoading, isFetching]);

  const FormSchema = React.useMemo(() => {
    const shape: { [key: string]: any } = {};
    if (initialFormData && initialFormData.parameters) {
      for (let i = 0; i < initialFormData.parameters.length; i++) {
        if (
          initialFormData.parameters[i].name === "userId" ||
          initialFormData.parameters[i].name === "entityId" ||
          initialFormData.parameters[i].name === "anepasutiliser"
        )
          continue;

        let type = null;
        switch (initialFormData.parameters[i].type) {
          case ERapportHopeParameterType.Integer:
          case ERapportHopeParameterType.Float:
            type = initialFormData.parameters[i].isMultiValue ? Yup.array().of(Yup.number()) : Yup.number();
            break;
          case ERapportHopeParameterType.String:
            type = initialFormData.parameters[i].isMultiValue ? Yup.array().of(Yup.string()) : Yup.string();
            break;
          case ERapportHopeParameterType.DateTime:
            type = Yup.date();
            break;
          case ERapportHopeParameterType.Boolean:
            type = Yup.boolean();
            break;
        }

        if (!!type) {
          shape[initialFormData.parameters[i].name] =
            initialFormData.parameters[i]?.isOptional || initialFormData.parameters[i]?.isNullable
              ? type.nullable()
              : type.nullable().required(t(ETLCodes.Required));
        }
      }
    }
    return Yup.object().shape(shape);
  }, [initialFormData, t]);

  const formDataParameters = React.useMemo(
    () => updatedFormData?.parameters || initialFormData?.parameters || ([] as RapportHopeParameterDto[]),
    [initialFormData, updatedFormData]
  );
  const buildParameters = React.useCallback(
    (values: any, entityId?: string) => {
      if (!!!initialFormData?.parameters || !!!values) return [];
      return initialFormData?.parameters?.map(p => {
        if (p.name === "entityId" && entityId) {
          return {
            name: p.name,
            value: entityId
          };
        }

        let valueFromObject = values[p.name];
        let isMultiValue = Array.isArray(valueFromObject);

        let finalValues = isMultiValue ? valueFromObject : null;
        let finalValue = isMultiValue ? null : valueFromObject;

        if (p.type === ERapportHopeParameterType.DateTime) {
          let dtFormatReport = "yyyy-MM-dd";

          // Convert to date representation
          if (isMultiValue) {
            finalValues = valueFromObject
              .filter(d => !!d && isValid(new Date(d)))
              .map(v => {
                const date = new Date(v);
                return format(date, dtFormatReport);
              });
          } else if (!!valueFromObject) {
            const date = new Date(valueFromObject);
            finalValue = isValid(date) ? format(date, dtFormatReport) : null;
          }
        }

        if (p.isOptional && !p.isNullable) {
          finalValues = isMultiValue ? finalValues?.map(d => (d === null || d === undefined ? "" : d)) : null;
          finalValue = isMultiValue ? null : finalValue ?? "";
        } else if (!p.isOptional && p.isNullable) {
          finalValues = isMultiValue ? finalValues?.map(d => (d === null || d === undefined ? "" : d)) : null;
          finalValue = isMultiValue ? null : finalValue ?? null;
        }

        return {
          name: p.name,
          value: finalValue,
          values: finalValues
        } as RapportHopeParameterDataDto;
      });
    },
    [initialFormData]
  );

  const generateRapport = React.useCallback(
    async (formValues: any, fileFormat: string, download: boolean = true) => {
      try {
        const jszip = new JSZip();

        setGenerating(true);

        let toDownload: FileDownloadDto = null;

        if (entityIds?.length > 1 && download) {
          for (let i = 0; i < entityIds.length; i++) {
            setCurrentEntityIndex(i + 1);
            const entityId = entityIds[i];
            const parametersToSend = buildParameters(formValues, entityId);

            const currentReport = await api.rapportHopeGenerate({
              GenerateRapportHopeCommand: {
                format: fileFormat,
                parameters: parametersToSend,
                reportName: initialFormData.path
              }
            });

            const filename = `${currentReport.fileName}_${entityId}.${currentReport.extension}`;
            jszip.file(filename, b64toBlob({ base64Content: currentReport.contentBase64 }), { binary: true });
          }
          jszip.generateAsync({ type: "blob" }).then(function(content) {
            saveAs(content, `Rapports_${initialFormData.nom}.zip`);
          });
        } else if (entityIds?.length === 1) {
          const parametersToSend = buildParameters(formValues, entityIds[0]);

          const report = await api.rapportHopeGenerate({
            GenerateRapportHopeCommand: {
              format: fileFormat,
              parameters: parametersToSend,
              reportName: initialFormData.path
            }
          });
          toDownload = {
            base64Content: report.contentBase64,
            fileName: report.fileName + "." + report.extension
          };
        } else if (!download && entityIds?.length > 1) {
          const parametersToSend = buildParameters(formValues, entityIds[0]);
          const report = await api.rapportHopeGenerateMultiple({
            GenerateMultipleRapportHopeCommand: {
              format: fileFormat,
              reportName: initialFormData.path,
              entityIds,
              parameters: parametersToSend
            }
          });
          const currentDate =
            initialFormData?.type === ERapportHopeType.EXPORT ? "_" + format(new Date(), "yyyy-MM-dd_HH-mm-ss") : "";
          toDownload = {
            base64Content: report.contentBase64,
            fileName: report.fileName + currentDate + "." + report.extension
          };
        } else {
          const parametersToSend = buildParameters(formValues);
          const report = await api.rapportHopeGenerate({
            GenerateRapportHopeCommand: {
              format: fileFormat,
              reportName: initialFormData.path,
              parameters: parametersToSend
            }
          });
          const currentDate =
            initialFormData?.type === ERapportHopeType.EXPORT ? "_" + format(new Date(), "yyyy-MM-dd_HH-mm-ss") : "";
          toDownload = {
            base64Content: report.contentBase64,
            fileName: report.fileName + currentDate + "." + report.extension
          };
        }

        if (fileFormat === "PDF" && !!toDownload && !download) {
          setGeneratedReport(toDownload);
        }
        if (download && !!toDownload) {
          exportFile(toDownload);
        }
      } catch (e) {
        console.error(e);
        showError(t(ETLCodes.ErrorWhileExporting));
      } finally {
        setGenerating(false);
        setCurrentEntityIndex(null);
      }
    },
    [api, buildParameters, entityIds, initialFormData, t]
  );
  const formik = useFGFormik<RapportHopeForViewDto>({
    initialValues: initialValues ?? {},
    validationSchema: FormSchema,
    onSubmit: d => generateRapport(d, "PDF", false)
  });

  const [formats, fLoading] = useReferential(a => a.referentialGetSSRSReportFormats(), false);

  const [firstTime, setFirstTime] = React.useState(true);
  React.useEffect(() => {
    if (
      initialFormData?.parameters?.filter(
        p => p.name !== "entityId" && p.name !== "userId" && p.name !== "anepasutiliser"
      )?.length === 0 &&
      firstTime
    ) {
      formik.submitForm();
      setFirstTime(false);
    }
  }, [initialFormData?.parameters, formik, file, firstTime]);

  const [isUpdatingParameters, setIsUpdatingParameters] = React.useState(false);
  const refreshParametersData = React.useCallback(
    async (parameters: RapportHopeParameterDataDto[]) => {
      setIsUpdatingParameters(true);
      try {
        const data = await api.rapportHopeGetForView({
          GetRapportHopeForViewQuery: {
            idrapportHope,
            currentParameterValues: parameters
          }
        });
        setUpdatedFormData(data);
      } catch (error) {
      } finally {
        setIsUpdatingParameters(false);
      }
    },
    [api, idrapportHope]
  );

  const debouncedFormikValues = useDebounce(formik.values, 1500);
  const previousValues = usePrevious(debouncedFormikValues);

  React.useEffect(() => {
    const sourceStringified = JSON.stringify(debouncedFormikValues);
    const destStringified = JSON.stringify(previousValues);
    if (debouncedFormikValues && sourceStringified !== destStringified && !isFetching) {
      let newValues = buildParameters(debouncedFormikValues);

      if (entityIds?.length > 0) {
        newValues = newValues.filter(v => v.name !== "entityId");
        newValues.push({
          name: "entityId",
          value: entityIds[0]
        });
      }
      refreshParametersData(newValues);
    }
  }, [
    api,
    buildParameters,
    debouncedFormikValues,
    entityIds,
    idrapportHope,
    isFetching,
    previousValues,
    refreshParametersData
  ]);

  const hasDependencies = React.useCallback(
    (dependencies: string[]) => {
      if (!dependencies || dependencies.length === 0 || !formik.values) return false;

      let hasEmptyDeps = false;
      for (let dep of dependencies.filter(d => d !== "entityId" && d !== "userId" && d !== "anepasutiliser")) {
        const dependencyParameter = formDataParameters.find(d => d.name === dep);

        const val = formik.values?.hasOwnProperty(dep) ? formik.values[dep] : null;
        const hasAValidValue = !!val && (!Array.isArray(val) || val.length > 0);
        if (
          hasAValidValue ||
          dependencyParameter.isOptional ||
          (!dependencyParameter.isOptional && dependencyParameter.isNullable)
        ) {
          hasEmptyDeps = false;
        } else {
          hasEmptyDeps = true;
          break;
        }
      }

      return hasEmptyDeps;
    },
    [formik.values, formDataParameters]
  );

  const [prevFormDataParameters, setPrevFormDataParameters] = React.useState<RapportHopeParameterDto[]>();
  React.useEffect(() => {
    if (!!formDataParameters && !isEqual(formDataParameters, prevFormDataParameters)) {
      setPrevFormDataParameters(formDataParameters);
      formDataParameters.forEach(p => {
        if (p.name === "userId" || p.name === "entityId" || p.name === "anepasutiliser" || formik.touched[p.name])
          return;

        const isDateField = p.type === ERapportHopeParameterType.DateTime;
        if (p.isMultiValue) {
          formik.setFieldValue(
            p.name,
            p.defaultValues?.map(v => (isDateField ? parseEventualDate(v) : v))
          );
        } else {
          if (!!p.defaultValues && p.defaultValues.length > 0) {
            const v = p.defaultValues.at(0);
            formik.setFieldValue(p.name, isDateField ? parseEventualDate(v) : v);
          }
        }
      });
    }
  }, [formDataParameters, formik, prevFormDataParameters]);

  return (
    <PageBase breadCrumbs={[{ text: t(ETLCodes.Rapport) }]}>
      <Container>
        <SmallFormGenerator
          formik={formik}
          loading={isFetching && !firstLoading}
          editMode={true}
          disabled={isFetching}
          showDeleteButton={false}
          saving={false}
          deleting={false}
          hideButtons={true}
          validationSchema={FormSchema}
          minLabelWidth={400}
          inline={true}
          showColons={false}
        >
          <FieldGroup
            columns={1}
            fieldsetProps={{
              title: isFetching ? <LoadingDots colorScheme="primary" size={5} /> : initialFormData?.nom
            }}
          >
            {formDataParameters
              .filter(p => p.name !== "entityId" && p.name !== "userId" && p.name !== "anepasutiliser" && !!p.label)
              .map((value, index) => {
                const type = value.type;
                const validValues = value.validValues;
                const isDisabled = hasDependencies(value.dependencies);
                const isSelectLoading = isUpdatingParameters && !formik.values?.[value.name];
                const labelStyle = value.isOptional || value.isNullable ? {} : { color: "red" };

                if (!!validValues) {
                  if (value.isMultiValue) {
                    return (
                      <FGMultiSuggestInput
                        key={value.name}
                        name={value.name}
                        label={value.label}
                        items={validValues}
                        labelStyles={labelStyle}
                        disabled={isDisabled}
                        allowSelectAll={true}
                      />
                    );
                  } else {
                    if (isSelectLoading) {
                      return (
                        <FGReadOnlyInput
                          key={value.name}
                          name={value.name}
                          label={value.label}
                          labelStyles={labelStyle}
                          disabled
                          leftElement={
                            <LoadingContainer>
                              <LoadingDots size={5} />
                            </LoadingContainer>
                          }
                        />
                      );
                    }
                    return (
                      <FGWalterSelectInput
                        key={value.name}
                        name={value.name}
                        label={value.label}
                        labelStyles={labelStyle}
                        items={validValues}
                        disabled={isDisabled}
                      />
                    );
                  }
                }

                if (type === ERapportHopeParameterType.Integer || type === ERapportHopeParameterType.Float) {
                  return (
                    <FGNumberInput
                      key={value.name}
                      name={value.name}
                      label={value.label}
                      labelStyles={labelStyle}
                      disabled={isDisabled}
                    />
                  );
                }
                if (type === ERapportHopeParameterType.String) {
                  return (
                    <FGTextInput
                      key={value.name}
                      name={value.name}
                      label={value.label}
                      labelStyles={labelStyle}
                      disabled={isDisabled}
                    />
                  );
                }
                if (type === ERapportHopeParameterType.DateTime) {
                  return (
                    <FGWalterDateMaskInput
                      key={value.name}
                      name={value.name}
                      label={value.label}
                      labelStyles={labelStyle}
                      disabled={isDisabled}
                      maxDate={add(new Date(), { years: 20 })}
                    />
                  );
                }
                if (type === ERapportHopeParameterType.Boolean) {
                  return (
                    <FGWalterCheckboxInput
                      key={value.name}
                      name={value.name}
                      label={value.label}
                      labelStyles={labelStyle}
                      disabled={isDisabled}
                    />
                  );
                }
                return <></>;
              })}
          </FieldGroup>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <FGCustomPanel>
              {ctx => (
                <ButtonGroup>
                  <Button
                    minimal={false}
                    intent="primary"
                    loading={generating}
                    icon={IconNames.REFRESH}
                    text={t(ETLCodes.Generer)}
                    type="submit"
                  />

                  <DropDownButton
                    text={t(ETLCodes.General_Download)}
                    loading={generating || fLoading}
                    rightIcon={"chevron-down"}
                    minimal
                    intent="primary"
                    outlined
                    popoverProps={{
                      position: "bottom-right"
                    }}
                    items={
                      formats?.map(f => ({
                        text: f.label,
                        intent: "primary",
                        onClick: () => generateRapport(ctx.formik.values, f.value as string)
                      })) ?? []
                    }
                  />
                </ButtonGroup>
              )}
            </FGCustomPanel>
          </div>
        </SmallFormGenerator>
        {!!file && (
          <PDFContainer>
            <Divider />
            <PdfViewer toolbarOptions={{ withoutDownload: true }} displayAll={false} file={file}></PdfViewer>
          </PDFContainer>
        )}
        {!!generating && currentEntityIndex !== null && entityIds?.length > 1 && (
          <GenericProgressLoading maxValue={entityIds?.length} currentValue={currentEntityIndex} loading={generating} />
        )}
      </Container>
    </PageBase>
  );
};
