/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FcbRecherchestatPresenceApprenantDto } from './FcbRecherchestatPresenceApprenantDto';
import {
    FcbRecherchestatPresenceApprenantDtoFromJSON,
    FcbRecherchestatPresenceApprenantDtoFromJSONTyped,
    FcbRecherchestatPresenceApprenantDtoToJSON,
} from './FcbRecherchestatPresenceApprenantDto';
import type { IAggregateResult } from './IAggregateResult';
import {
    IAggregateResultFromJSON,
    IAggregateResultFromJSONTyped,
    IAggregateResultToJSON,
} from './IAggregateResult';

/**
 * 
 * @export
 * @interface FcbRecherchestatPresenceApprenantDtoPaginatedResults
 */
export interface FcbRecherchestatPresenceApprenantDtoPaginatedResults {
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchestatPresenceApprenantDtoPaginatedResults
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<FcbRecherchestatPresenceApprenantDto>}
     * @memberof FcbRecherchestatPresenceApprenantDtoPaginatedResults
     */
    results?: Array<FcbRecherchestatPresenceApprenantDto> | null;
    /**
     * 
     * @type {Array<IAggregateResult>}
     * @memberof FcbRecherchestatPresenceApprenantDtoPaginatedResults
     */
    aggregateResults?: Array<IAggregateResult> | null;
}

/**
 * Check if a given object implements the FcbRecherchestatPresenceApprenantDtoPaginatedResults interface.
 */
export function instanceOfFcbRecherchestatPresenceApprenantDtoPaginatedResults(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRecherchestatPresenceApprenantDtoPaginatedResultsFromJSON(json: any): FcbRecherchestatPresenceApprenantDtoPaginatedResults {
    return FcbRecherchestatPresenceApprenantDtoPaginatedResultsFromJSONTyped(json, false);
}

export function FcbRecherchestatPresenceApprenantDtoPaginatedResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRecherchestatPresenceApprenantDtoPaginatedResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'results': !exists(json, 'results') ? undefined : (json['results'] === null ? null : (json['results'] as Array<any>).map(FcbRecherchestatPresenceApprenantDtoFromJSON)),
        'aggregateResults': !exists(json, 'aggregateResults') ? undefined : (json['aggregateResults'] === null ? null : (json['aggregateResults'] as Array<any>).map(IAggregateResultFromJSON)),
    };
}

export function FcbRecherchestatPresenceApprenantDtoPaginatedResultsToJSON(value?: FcbRecherchestatPresenceApprenantDtoPaginatedResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'results': value.results === undefined ? undefined : (value.results === null ? null : (value.results as Array<any>).map(FcbRecherchestatPresenceApprenantDtoToJSON)),
        'aggregateResults': value.aggregateResults === undefined ? undefined : (value.aggregateResults === null ? null : (value.aggregateResults as Array<any>).map(IAggregateResultToJSON)),
    };
}

