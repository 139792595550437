import { Divider } from "@blueprintjs/core";
import * as React from "react";
import { AcquisAdmissionList } from "./acquisAdmission/AcquisAdmissionList";
import { AcquisScolaireList } from "./acquisScolaire/AcquisScolaireList";
import { ExperienceProfessionnelleList } from "./experienceProfessionnelle/ExperienceProfessionnelleList";

export interface IAcquisGlobalProps {
  listRoute: string;
  idpersonne: number;
}

export const AcquisGlobal: React.FunctionComponent<IAcquisGlobalProps> = ({ listRoute, idpersonne }) => {
  return (
    <>
      <AcquisScolaireList idpersonne={idpersonne} listRoute={listRoute} />
      <Divider />
      <ExperienceProfessionnelleList idpersonne={idpersonne} listRoute={listRoute} />
      <Divider />
      <AcquisAdmissionList idpersonne={idpersonne} listRoute={listRoute} />
    </>
  );
};
