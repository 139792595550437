import * as React from "react";
import { FieldSet, DataTable, useGridState, IDataTableColumn } from "nsitools-react";
import { ETLCodes } from "../../../../../locales";
import { useTl, useApiService } from "../../../../../hooks";
import { FcbExportModulePermissionDto, ExportApi } from "../../../../../api";
import { Checkbox } from "@blueprintjs/core";

export interface IExportModulePermissionsGridProps {
  idExport: number;
  modules: FcbExportModulePermissionDto[];
  loading: boolean;
}

export const ExportModulePermissionsGrid: React.FunctionComponent<IExportModulePermissionsGridProps> = ({
  idExport,
  modules = [],
  loading
}) => {
  const { t } = useTl();
  const api = useApiService(ExportApi);

  const [loadingCheckboxes, setLoadingCheckboxes] = React.useState(false);

  const tableState = useGridState<FcbExportModulePermissionDto>({
    serverMode: false,
    enablePagination: false,
    enableFilter: false
  });

  const { setData } = tableState;

  React.useEffect(() => {
    setData(modules);
  }, [modules, setData]);

  const toggleSelection = React.useCallback(
    async (row: FcbExportModulePermissionDto) => {
      setLoadingCheckboxes(true);
      row.selected = !row.selected;
      row.idexport = idExport;
      let nextData = [...modules];
      setData(nextData);
      try {
        await api.exportSaveModulePermissions({ FcbExportModulePermissionDto: row });
        setLoadingCheckboxes(false);
      } catch (e) {
        setLoadingCheckboxes(false);
        throw e;
      }
    },
    [api, idExport, modules, setData]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        render: (item: FcbExportModulePermissionDto) => (
          <Checkbox
            checked={item.selected}
            onChange={e => {
              toggleSelection(item);
            }}
            disabled={loadingCheckboxes}
          />
        )
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "nom"
      }
    ],
    [loadingCheckboxes, t, toggleSelection]
  );

  return (
    <FieldSet title={t(ETLCodes.Modules)} collapsable defaultCollapsed>
      <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
    </FieldSet>
  );
};
