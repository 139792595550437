import * as React from "react";
import { useRouteMatch, Switch, Route } from "react-router";

import { ApprenantAcquisProfessionnelDetail } from "./ApprenantAcquisProfessionnelDetail";
import { ApprenantAcquisProfessionnelSearch } from "./ApprenantAcquisProfessionnelSearch";

interface IApprenantAcquisProfessionnelProps {
  apprenantId: number;
}

export const ApprenantAcquisProfessionnel: React.FunctionComponent<IApprenantAcquisProfessionnelProps> = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={ApprenantAcquisProfessionnelSearch} exact />
      <Route path={`${match.path}/:acquisProfessionnelId/:state`} component={ApprenantAcquisProfessionnelDetail} />
    </Switch>
  );
};
