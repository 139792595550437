/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ETypeDernierAvertissement {
    Avertissement = 'Avertissement',
    Convocation = 'Convocation'
}


export function ETypeDernierAvertissementFromJSON(json: any): ETypeDernierAvertissement {
    return ETypeDernierAvertissementFromJSONTyped(json, false);
}

export function ETypeDernierAvertissementFromJSONTyped(json: any, ignoreDiscriminator: boolean): ETypeDernierAvertissement {
    return json as ETypeDernierAvertissement;
}

export function ETypeDernierAvertissementToJSON(value?: ETypeDernierAvertissement | null): any {
    return value as any;
}

