import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";

import { CentreLieuxCoursItem } from "..";
import { CentreApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { CentreDetailTab } from "./CentreDetailsTab";
import { CentreHoraireItem } from "./CentreHoraireItem";
import { CentreResponsable } from "./CentreResponsable";

interface ICentreItemPageProps { }

export const CentreItemPage: React.FunctionComponent<ICentreItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const idCentre = React.useMemo(() => +id, [id]);
  const history = useHistory();

  const centreApi = useApiService(CentreApi);
  const [displayName] = useApiEffect(() => centreApi.centreGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.Centres), route: ERoutes.centre },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsCentre"
        onChange={newTabId => history.push(`${ERoutes.centre}/${id}/${newTabId}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.centre}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<CentreDetailTab idCentre={idCentre} editMode={state === "edit"} />}
        />
        <Tab
          id="responsable"
          title={t(ETLCodes.Responsable)}
          panel={<CentreResponsable idCentre={idCentre} editMode={state === "edit"} />}
          disabled={+id <= 0}
        />
        <Tab id="lieuxCours" title={t(ETLCodes.LieuxDeCours)} panel={<CentreLieuxCoursItem />} disabled={+id <= 0} />
        <Tab id="horaire" title={t(ETLCodes.Horaire)} panel={<CentreHoraireItem />} disabled={+id <= 0} />
      </ProtectedTabs>
    </PageBase>
  );
};
