import { Classes, Dialog } from "@blueprintjs/core";
import { FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";

import { ContratFichierApi, ContratFichierDto } from "../../../../../api";
import { FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

const StyledDialog = styled(Dialog)`
  width: 600px;
  height: auto;
  background-color: white;
`;

export interface IContratDocumentEditDialogProps {
  idcontratFichier: number;
  onClose: (refresh?: boolean) => void;
}

export const ContratDocumentEditDialog: React.FunctionComponent<IContratDocumentEditDialogProps> = ({
  idcontratFichier,
  onClose
}) => {
  const { t } = useTl();
  const api = useApiService(ContratFichierApi);

  const { data, loading, saveItem, saving, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () => {
          return api.contratFichierGetContratFichier({ id: idcontratFichier });
        },
        saveApiFn: async (d: ContratFichierDto) => {
          return api.contratFichierSaveContratFichier({ ContratFichierDto: d });
        },
        onSaved: () => onClose(true)
      }),
      [api, idcontratFichier, onClose]
    )
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape({
        idtypeContratFichier: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required))
      }),
    [t]
  );

  const [typesContratFichier, tLoading] = useReferential(a => a.referentialGetTypesContratFichier());

  return (
    <StyledDialog title={t(ETLCodes.UploadFichier)} isOpen={!!idcontratFichier} onClose={() => onClose(false)}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose(false)}
          showDeleteButton={false}
          saving={saving}
          loading={loading}
          validationErrors={validationErrors}
        >
          <FieldGroup>
            <FGTextInput name="fileName" label={t(ETLCodes.Fichier)} readonly />
            <FGWalterSelectInput
              name="idtypeContratFichier"
              label={t(ETLCodes.Type)}
              items={typesContratFichier}
              loading={tLoading}
            />
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
