/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbServiceTutelleDto,
  FcbServiceTutelleGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
  ServiceTutelleSearch,
  ValidationError,
} from '../models/index';
import {
    FcbServiceTutelleDtoFromJSON,
    FcbServiceTutelleDtoToJSON,
    FcbServiceTutelleGridDtoPaginatedResultsFromJSON,
    FcbServiceTutelleGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ServiceTutelleSearchFromJSON,
    ServiceTutelleSearchToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface ServiceTutelleBaseSearchRequest {
    ServiceTutelleSearch?: ServiceTutelleSearch;
}

export interface ServiceTutelleCodeExistsRequest {
    code?: string;
}

export interface ServiceTutelleDeleteRequest {
    id?: number;
}

export interface ServiceTutelleGetRequest {
    id?: number;
}

export interface ServiceTutelleGetDisplayNameRequest {
    id?: number;
}

export interface ServiceTutelleGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ServiceTutelleGetSelectItemsRequest {
    searchField: string;
}

export interface ServiceTutelleSaveRequest {
    FcbServiceTutelleDto?: FcbServiceTutelleDto;
}

export interface ServiceTutelleSearchServiceTutelleIdsRequest {
    ServiceTutelleSearch?: ServiceTutelleSearch;
}

/**
 * 
 */
export class ServiceTutelleApi extends runtime.BaseAPI {

    /**
     */
    async serviceTutelleBaseSearchRaw(requestParameters: ServiceTutelleBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbServiceTutelleGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ServiceTutelle/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceTutelleSearchToJSON(requestParameters.ServiceTutelleSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbServiceTutelleGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async serviceTutelleBaseSearch(requestParameters: ServiceTutelleBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbServiceTutelleGridDtoPaginatedResults> {
        const response = await this.serviceTutelleBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async serviceTutelleCodeExistsRaw(requestParameters: ServiceTutelleCodeExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ServiceTutelle/codeExists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async serviceTutelleCodeExists(requestParameters: ServiceTutelleCodeExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.serviceTutelleCodeExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async serviceTutelleDeleteRaw(requestParameters: ServiceTutelleDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ServiceTutelle`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async serviceTutelleDelete(requestParameters: ServiceTutelleDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.serviceTutelleDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async serviceTutelleGetRaw(requestParameters: ServiceTutelleGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbServiceTutelleDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ServiceTutelle`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbServiceTutelleDtoFromJSON(jsonValue));
    }

    /**
     */
    async serviceTutelleGet(requestParameters: ServiceTutelleGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbServiceTutelleDto> {
        const response = await this.serviceTutelleGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async serviceTutelleGetCodePostalsForUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ServiceTutelle/GetCodePostalsForUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async serviceTutelleGetCodePostalsForUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.serviceTutelleGetCodePostalsForUserRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async serviceTutelleGetDisplayNameRaw(requestParameters: ServiceTutelleGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ServiceTutelle/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async serviceTutelleGetDisplayName(requestParameters: ServiceTutelleGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.serviceTutelleGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async serviceTutelleGetSearchCriteriasRaw(requestParameters: ServiceTutelleGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ServiceTutelle/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async serviceTutelleGetSearchCriterias(requestParameters: ServiceTutelleGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.serviceTutelleGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async serviceTutelleGetSelectItemsRaw(requestParameters: ServiceTutelleGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling serviceTutelleGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ServiceTutelle/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async serviceTutelleGetSelectItems(requestParameters: ServiceTutelleGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.serviceTutelleGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async serviceTutelleSaveRaw(requestParameters: ServiceTutelleSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbServiceTutelleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ServiceTutelle`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbServiceTutelleDtoToJSON(requestParameters.FcbServiceTutelleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbServiceTutelleDtoFromJSON(jsonValue));
    }

    /**
     */
    async serviceTutelleSave(requestParameters: ServiceTutelleSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbServiceTutelleDto> {
        const response = await this.serviceTutelleSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async serviceTutelleSearchServiceTutelleIdsRaw(requestParameters: ServiceTutelleSearchServiceTutelleIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ServiceTutelle/SearchServiceTutelleIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceTutelleSearchToJSON(requestParameters.ServiceTutelleSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async serviceTutelleSearchServiceTutelleIds(requestParameters: ServiceTutelleSearchServiceTutelleIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.serviceTutelleSearchServiceTutelleIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
