import { Divider } from "@blueprintjs/core";
import { FGCustomPanel, FGEmpty, FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { CentreApi, FcbCentreDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  CommuneSelect,
  FGWalterSelectInput,
  SmallFormGenerator,
  FGWalterCheckboxInput
} from "../../../../../components";
import { LocaliteSelect } from "../../../../../components/LocaliteSelect";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

interface ICentreDetailTabProps {
  idCentre: number;
  editMode: boolean;
}

export const CentreDetailTab: React.FunctionComponent<ICentreDetailTabProps> = ({ idCentre, editMode }) => {
  const { t } = useTl();
  const api = useApiService(CentreApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () => (+idCentre <= 0 ? FcbCentreDtoFromJSON({ idCentre: 0 }) : api.centreGet({ id: +idCentre })),
    saveApiFn: d => api.centreSave({ FcbCentreDto: d }),
    onSavedRoute: d => `${ERoutes.centre}/${d.idcentre}/detail/edit`,
    deleteApiFn: d => api.centreDelete({ id: d.idcentre }),
    onDeletedRoute: () => ERoutes.centre
  });

  const [pays, loadingPays] = useReferential(a => a.referentialGetPays(), false, []);
  const [centresGlobal, loadingCentreGlobal] = useReferential(a => a.referentialGetCentreGlobal(), true, []);
  const [directionTerritoriale, loadingDirectionTerritoriale] = useReferential(
    a => a.referentialGetDirectionTerritoriale(),
    true,
    []
  );

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      codePays: Yup.string().required(t(ETLCodes.Required)),
      code: Yup.string()
        .required(t(ETLCodes.Required))
        .test("code-not-exists", t(ETLCodes.CentreDetailPage_CodeExistsError), async value => {
          if (value === data.code) return true;
          const validationError = await api.centreCodeExists({ code: value });
          return validationError.isValid;
        }),
      nom: Yup.string().required(t(ETLCodes.Required)),
      email: Yup.string().email(t(ETLCodes.InvalidEmail)),
      iddirectionTerritoriale: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t, api, data]);

  const Localite = formik => {
    return (
      <LocaliteSelect
        codePostal={formik.values.codePostal}
        codePays={formik.values.codePays}
        name={"localite"}
        codePostalTextName="codePostal"
        disableIfNoCp={false}
      />
    );
  };

  const Commune = formik => {
    return <CommuneSelect codePostal={formik.values.codePostal} codePays={formik.values.codePays} name={"commune"} />;
  };

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={editMode}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.centre)}
      onDelete={deleteItem}
      showDeleteButton={+idCentre > 0}
      saving={saving}
      deleting={deleting}
    >
      <FieldGroup columns={[3, 9]}>
        <FGTextInput name="code" maxLength={3} label={t(ETLCodes.Code)} forceCase="upper" />
        <FGEmpty />
      </FieldGroup>
      <FieldGroup columns={2}>
        <FGTextInput name="nom" maxLength={100} label={t(ETLCodes.Nom)} />
        <FGWalterSelectInput
          name="identite"
          label={t(ETLCodes.CentreGlobal)}
          items={centresGlobal}
          loading={loadingCentreGlobal}
        />
      </FieldGroup>
      <Divider />
      <FieldGroup columns={2}>
        <FGWalterSelectInput name="codePays" label={t(ETLCodes.Pays)} items={pays} loading={loadingPays} />
        <FGEmpty />
        <FGTextAreaInput name="adresse" maxLength={50} label={t(ETLCodes.Adresse)} />
        <FGEmpty />
      </FieldGroup>
      <FieldGroup columns={[3, 9]}>
        <FGTextInput name="codePostal" maxLength={6} label={t(ETLCodes.CodePostal)} />
        <FGEmpty />
      </FieldGroup>
      <FieldGroup columns={2}>
        <FGCustomPanel>{ctx => Localite(ctx.formik)}</FGCustomPanel>
        <FGCustomPanel>{ctx => Commune(ctx.formik)}</FGCustomPanel>
      </FieldGroup>
      <FieldGroup columns={3}>
        <FGTextInput name="telApp" maxLength={20} label={t(ETLCodes.TelApp)} />
        <FGTextInput name="telCE" maxLength={20} label={t(ETLCodes.TelCE)} />
        <FGTextInput name="telCFO" maxLength={20} label={t(ETLCodes.TelFCO)} />
        <FGTextInput name="telCentral" maxLength={20} label={t(ETLCodes.TelCentral)} />
        <FGTextInput name="telAutre" maxLength={20} label={t(ETLCodes.TelAutre)} />
        <FGEmpty />
        <FGTextInput name="faxApp" maxLength={20} label={t(ETLCodes.FaxApp)} />
        <FGTextInput name="faxCe" maxLength={20} label={t(ETLCodes.FaxCE)} />
        <FGTextInput name="faxFco" maxLength={20} label={t(ETLCodes.FaxFCO)} />
        <FGTextInput name="faxAutre" maxLength={20} label={t(ETLCodes.FaxAutre)} />
        <FGEmpty />
        <FGEmpty />
      </FieldGroup>
      <FieldGroup columns={[5, 7]}>
        <FGTextInput name="email" maxLength={50} label={t(ETLCodes.General_Email)} />
        <FGEmpty />
      </FieldGroup>
      <Divider />
      <FieldGroup columns={[5, 7]}>
        <FGTextInput name="website" maxLength={50} label={t(ETLCodes.Website)} />
        <FGEmpty />
        <FGTextInput name="url" maxLength={50} label={t(ETLCodes.PlanAcces)} />
        <FGEmpty />
        <FGWalterSelectInput
          name="iddirectionTerritoriale"
          label={t(ETLCodes.DirectionsTerritoriales)}
          items={directionTerritoriale}
          loading={loadingDirectionTerritoriale}
        />
        <FGEmpty />
      </FieldGroup>
      <Divider />
      <FieldGroup>
        <FGWalterCheckboxInput name="exterieur" label={t(ETLCodes.Exterieur)} />
        <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
