import { ButtonContainer, IDataTableColumn } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import { TypeBulletinApi, TypeBulletinGridDto, TypeBulletinSearchDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface ITypeBulletinListPageProps {}

export const TypeBulletinListPage: React.FunctionComponent<ITypeBulletinListPageProps> = props => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(TypeBulletinApi);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: TypeBulletinGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.typeBulletin}/${row.idtypeBulletin}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.typeBulletin}/${row.idtypeBulletin}/detail/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "libelle"
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code"
      }
    ],
    [history, t]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.typeBulletin}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(() => api.typeBulletinGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const apiFactory = useAbortableApiServiceFactory(TypeBulletinApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: TypeBulletinSearchDto) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.typeBulletinBaseSearch({ TypeBulletinSearchDto: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      columns={columns}
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      addFunc={addItemFunction}
      breadCrumbs={[{ text: t(ETLCodes.TypesBulletin), route: ERoutes.typeBulletin }]}
      sortKeys={{ libelle: "ASC" }}
      searchStateInitialSearch={false}
    />
  );
};

export default TypeBulletinListPage;
