import * as React from "react";

export type UnordoredListValue = {
  label: string;
  value: boolean;
};

export interface IUnordoredListColumnProps {
  values: UnordoredListValue[];
  noBullets?: boolean;
}

export const UnordoredListColumn: React.FunctionComponent<IUnordoredListColumnProps> = ({ values = [], noBullets }) => {
  return noBullets ? (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {values
        .filter(v => v.value)
        .map(v => (
          <span>{v.label}</span>
        ))}
    </div>
  ) : (
    <ul>
      {values
        .filter(v => v.value)
        .map(v => (
          <li>{v.label}</li>
        ))}
    </ul>
  );
};
