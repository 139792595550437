import { ERoutes } from "../../../../../AppRouter";
import { VisiteFormateurLieListPage } from "./VisiteFormateurLieListPage";
import { VisiteFormateurLieSelector } from "./VisiteFormateurLieSelector";
import React from "react";
import { Route, Switch } from "react-router";

export interface IVisiteFormateurLieSwitchProps {
  idVisite: number;
}

export const VisiteFormateurLieSwitch: React.FunctionComponent<IVisiteFormateurLieSwitchProps> = ({ idVisite }) => {
  return (
    <Switch>
      <Route
        path={`${ERoutes.visiteExterieure}/:id/:tab`}
        component={() => <VisiteFormateurLieListPage idVisite={idVisite} />}
        exact
      />
      <Route
        path={`${ERoutes.visiteExterieure}/:id/:tab/selector`}
        component={() => <VisiteFormateurLieSelector idVisite={idVisite} />}
      />
    </Switch>
  );
};
