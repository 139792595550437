import * as React from "react";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ClasseMatiereListEditor } from "./ClasseMatiereListEditor";

export interface IClasseMatiereContratListEditorProps {
  initialValue: number | string;
  onValueChanged: (value: number | string) => void;
  idMatiere: number;
  idFormateur?: number;
}

export const ClasseMatiereContratListEditor: React.FunctionComponent<IClasseMatiereContratListEditorProps> = ({
  initialValue,
  onValueChanged,
  idMatiere,
  idFormateur
}) => {
  const [contrats, cloading] = useReferential(
    a => a.referentialContratsByMatiere({ idClasseMatiere: +idMatiere, idFormateur: idFormateur }),
    true,
    [idFormateur]
  );

  return (
    <ClasseMatiereListEditor
      initialValue={initialValue}
      onValueChanged={value => onValueChanged(value)}
      items={contrats}
      loading={cloading}
    ></ClasseMatiereListEditor>
  );
};
