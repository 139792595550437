/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbExportGapStatsGridDtoPaginatedResults,
  FilterCriteriaInfo,
  RechercheExportGapSearch,
  SelectItem,
} from '../models/index';
import {
    FcbExportGapStatsGridDtoPaginatedResultsFromJSON,
    FcbExportGapStatsGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    RechercheExportGapSearchFromJSON,
    RechercheExportGapSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface ExportGapStatsVacataireBaseSearchRequest {
    RechercheExportGapSearch?: RechercheExportGapSearch;
}

export interface ExportGapStatsVacataireGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ExportGapStatsVacataireGetSelectItemsRequest {
    searchField: string;
}

/**
 * 
 */
export class ExportGapStatsVacataireApi extends runtime.BaseAPI {

    /**
     */
    async exportGapStatsVacataireBaseSearchRaw(requestParameters: ExportGapStatsVacataireBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbExportGapStatsGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportGapStatsVacataire/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RechercheExportGapSearchToJSON(requestParameters.RechercheExportGapSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbExportGapStatsGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async exportGapStatsVacataireBaseSearch(requestParameters: ExportGapStatsVacataireBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbExportGapStatsGridDtoPaginatedResults> {
        const response = await this.exportGapStatsVacataireBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportGapStatsVacataireGetSearchCriteriasRaw(requestParameters: ExportGapStatsVacataireGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportGapStatsVacataire/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async exportGapStatsVacataireGetSearchCriterias(requestParameters: ExportGapStatsVacataireGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.exportGapStatsVacataireGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportGapStatsVacataireGetSelectItemsRaw(requestParameters: ExportGapStatsVacataireGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling exportGapStatsVacataireGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportGapStatsVacataire/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async exportGapStatsVacataireGetSelectItems(requestParameters: ExportGapStatsVacataireGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.exportGapStatsVacataireGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
