/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReferentialItemDto } from './ReferentialItemDto';
import {
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoFromJSONTyped,
    ReferentialItemDtoToJSON,
} from './ReferentialItemDto';
import type { TuteurDoublonLieuFormationDto } from './TuteurDoublonLieuFormationDto';
import {
    TuteurDoublonLieuFormationDtoFromJSON,
    TuteurDoublonLieuFormationDtoFromJSONTyped,
    TuteurDoublonLieuFormationDtoToJSON,
} from './TuteurDoublonLieuFormationDto';

/**
 * 
 * @export
 * @interface TuteurDoublonEditDto
 */
export interface TuteurDoublonEditDto {
    /**
     * 
     * @type {number}
     * @memberof TuteurDoublonEditDto
     */
    idtuteur?: number;
    /**
     * 
     * @type {number}
     * @memberof TuteurDoublonEditDto
     */
    nbContrats?: number;
    /**
     * 
     * @type {Array<ReferentialItemDto>}
     * @memberof TuteurDoublonEditDto
     */
    idsmetier?: Array<ReferentialItemDto> | null;
    /**
     * 
     * @type {Array<TuteurDoublonLieuFormationDto>}
     * @memberof TuteurDoublonEditDto
     */
    lieuxFormation?: Array<TuteurDoublonLieuFormationDto> | null;
}

/**
 * Check if a given object implements the TuteurDoublonEditDto interface.
 */
export function instanceOfTuteurDoublonEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TuteurDoublonEditDtoFromJSON(json: any): TuteurDoublonEditDto {
    return TuteurDoublonEditDtoFromJSONTyped(json, false);
}

export function TuteurDoublonEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TuteurDoublonEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idtuteur': !exists(json, 'idtuteur') ? undefined : json['idtuteur'],
        'nbContrats': !exists(json, 'nbContrats') ? undefined : json['nbContrats'],
        'idsmetier': !exists(json, 'idsmetier') ? undefined : (json['idsmetier'] === null ? null : (json['idsmetier'] as Array<any>).map(ReferentialItemDtoFromJSON)),
        'lieuxFormation': !exists(json, 'lieuxFormation') ? undefined : (json['lieuxFormation'] === null ? null : (json['lieuxFormation'] as Array<any>).map(TuteurDoublonLieuFormationDtoFromJSON)),
    };
}

export function TuteurDoublonEditDtoToJSON(value?: TuteurDoublonEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idtuteur': value.idtuteur,
        'nbContrats': value.nbContrats,
        'idsmetier': value.idsmetier === undefined ? undefined : (value.idsmetier === null ? null : (value.idsmetier as Array<any>).map(ReferentialItemDtoToJSON)),
        'lieuxFormation': value.lieuxFormation === undefined ? undefined : (value.lieuxFormation === null ? null : (value.lieuxFormation as Array<any>).map(TuteurDoublonLieuFormationDtoToJSON)),
    };
}

