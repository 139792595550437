import { Tab, Tabs } from "@blueprintjs/core";
import * as React from "react";
import { DoublonTuteurLieuxFormation, DoublonTuteurSignaletique } from ".";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IDoublonTuteurPageProps {}

export const DoublonTuteurPage: React.FunctionComponent<IDoublonTuteurPageProps> = props => {
  const { t } = useTl();
  const [tab, setTab] = React.useState("signaletique");

  return (
    <Tabs
      id="TabsDoublonTuteur"
      onChange={newTabId => setTab(newTabId + "")}
      selectedTabId={tab}
      renderActiveTabPanelOnly
      vertical
    >
      <Tab id="signaletique" title={t(ETLCodes.Signaletique)} panel={<DoublonTuteurSignaletique />} />
      <Tab id="lieuxFormation" title={t(ETLCodes.LieuxFormations)} panel={<DoublonTuteurLieuxFormation />} />
    </Tabs>
  );
};
