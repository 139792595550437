import { Checkbox } from "@blueprintjs/core";
import { getIn } from "formik";
import { FGCustomInput, IFGCheckboxInputProps, useFGContext } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";

const Container = styled.div`
  display: flex;
  gap: 1rem;
`;

export interface IFGHalfDaysInputProps extends Omit<IFGCheckboxInputProps, "children" | "name"> {
  amLabel?: string;
  pmLabel?: string;
  amName: string;
  pmName: string;
  amDisabled?: (value) => boolean;
  pmDisabled?: (value) => boolean;
}

export const FGHalfDaysInput: React.FunctionComponent<IFGHalfDaysInputProps> = ({
  label,
  readonly,
  amLabel,
  pmLabel,
  amName,
  pmName,
  amDisabled = () => false,
  pmDisabled = () => false,
  disabled,
  ...fgCustomInputProps
}) => {
  const { t } = useTl();
  const { formik, disabled: globalDisabled } = useFGContext();

  return (
    <FGCustomInput label={label} readonly={readonly} {...fgCustomInputProps}>
      {ctx => (
        <Container>
          <Checkbox
            {...fgCustomInputProps}
            name={amName}
            label={amLabel ?? t(ETLCodes.Matin)}
            checked={getIn(formik.values, amName) || false}
            disabled={amDisabled(getIn(formik.values, amName)) || disabled || globalDisabled}
            onChange={e => {
              formik.setFieldValue(amName, e.currentTarget.checked);
            }}
          ></Checkbox>
          <Checkbox
            {...fgCustomInputProps}
            name={pmName}
            label={pmLabel ?? t(ETLCodes.ApresMidi)}
            checked={getIn(formik.values, pmName) || false}
            disabled={pmDisabled(getIn(formik.values, pmName)) || disabled || globalDisabled}
            onChange={e => {
              formik.setFieldValue(pmName, e.currentTarget.checked);
            }}
          ></Checkbox>
        </Container>
      )}
    </FGCustomInput>
  );
};
