import { ButtonContainer, IDataTableColumn } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import { css } from "styled-components";

import { ImpressionApi, RechercheImpressionSearch } from "../../../../api";
import { FcbRechercheImpressionDto } from "../../../../api/models/FcbRechercheImpressionDto";
import { ERoutes } from "../../../../AppRouter";
import { BooleanColumn, EditButton, SearchTablePage, ViewButton } from "../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface IImpressionListPageProps {}

export const ImpressionListPage: React.FunctionComponent<IImpressionListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ImpressionApi);
  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbRechercheImpressionDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.impressions}/${row.idimpression}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.impressions}/${row.idimpression}/detail/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      },
      {
        header: () => t(ETLCodes.Actif),
        fieldName: "actif",
        alignment: "center",
        render: (row: FcbRechercheImpressionDto) => <BooleanColumn value={row.actif === "oui"} />
      },
      {
        header: () => t(ETLCodes.Rapport),
        fieldName: "rapport"
      },
      {
        header: () => t(ETLCodes.Modules),
        fieldName: "module"
      },
      {
        header: () => t(ETLCodes.Acces),
        fieldName: "acces",
        customizeCellStyle: () =>
          css`
            white-space: pre;
          `
      },
      {
        header: () => t(ETLCodes.Centres),
        fieldName: "centre",
        customizeCellStyle: () =>
          css`
            white-space: pre;
          `
      }
    ],
    [history, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.impressions}/0/detail/edit`);
  }, [history]);

  const apiFactory = useAbortableApiServiceFactory(ImpressionApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: RechercheImpressionSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.impressionBaseSearch({ RechercheImpressionSearch: sObj });
    },
    [apiFactory]
  );
  const criteriasFn = React.useCallback(() => api.impressionGetSearchCriterias({ includeListsValues: true }), [api]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={criteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.Impressions), route: ERoutes.impressions }]}
      addFunc={onAddItem}
      sortKeys={{ nom: "ASC" }}
      searchStateInitialSearch={false}
    />
  );
};
