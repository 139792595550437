/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormateurHoraireGridDto
 */
export interface FormateurHoraireGridDto {
    /**
     * 
     * @type {number}
     * @memberof FormateurHoraireGridDto
     */
    idformateur?: number;
    /**
     * 
     * @type {number}
     * @memberof FormateurHoraireGridDto
     */
    idclasseHoraire?: number;
    /**
     * 
     * @type {string}
     * @memberof FormateurHoraireGridDto
     */
    annee?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FormateurHoraireGridDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurHoraireGridDto
     */
    classe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurHoraireGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurHoraireGridDto
     */
    heureDebut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurHoraireGridDto
     */
    matiere?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurHoraireGridDto
     */
    local?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormateurHoraireGridDto
     */
    remplacement?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FormateurHoraireGridDto
     */
    visite?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FormateurHoraireGridDto
     */
    idclasse?: number;
    /**
     * 
     * @type {number}
     * @memberof FormateurHoraireGridDto
     */
    iduser?: number;
}

/**
 * Check if a given object implements the FormateurHoraireGridDto interface.
 */
export function instanceOfFormateurHoraireGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FormateurHoraireGridDtoFromJSON(json: any): FormateurHoraireGridDto {
    return FormateurHoraireGridDtoFromJSONTyped(json, false);
}

export function FormateurHoraireGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormateurHoraireGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'idclasseHoraire': !exists(json, 'idclasseHoraire') ? undefined : json['idclasseHoraire'],
        'annee': !exists(json, 'annee') ? undefined : json['annee'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'classe': !exists(json, 'classe') ? undefined : json['classe'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'heureDebut': !exists(json, 'heureDebut') ? undefined : json['heureDebut'],
        'matiere': !exists(json, 'matiere') ? undefined : json['matiere'],
        'local': !exists(json, 'local') ? undefined : json['local'],
        'remplacement': !exists(json, 'remplacement') ? undefined : json['remplacement'],
        'visite': !exists(json, 'visite') ? undefined : json['visite'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'iduser': !exists(json, 'iduser') ? undefined : json['iduser'],
    };
}

export function FormateurHoraireGridDtoToJSON(value?: FormateurHoraireGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idformateur': value.idformateur,
        'idclasseHoraire': value.idclasseHoraire,
        'annee': value.annee,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'classe': value.classe,
        'nom': value.nom,
        'heureDebut': value.heureDebut,
        'matiere': value.matiere,
        'local': value.local,
        'remplacement': value.remplacement,
        'visite': value.visite,
        'idclasse': value.idclasse,
        'iduser': value.iduser,
    };
}

