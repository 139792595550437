/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbTitrePedagogiqueDto,
  FcbTitrePedagogiqueDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
  TitrePedagogiqueSearch,
  ValidationError,
} from '../models/index';
import {
    FcbTitrePedagogiqueDtoFromJSON,
    FcbTitrePedagogiqueDtoToJSON,
    FcbTitrePedagogiqueDtoPaginatedResultsFromJSON,
    FcbTitrePedagogiqueDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    TitrePedagogiqueSearchFromJSON,
    TitrePedagogiqueSearchToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface TitrePedagogiqueBaseSearchRequest {
    TitrePedagogiqueSearch?: TitrePedagogiqueSearch;
}

export interface TitrePedagogiqueDeleteRequest {
    id?: number;
}

export interface TitrePedagogiqueExistsRequest {
    libelle?: string;
}

export interface TitrePedagogiqueGetRequest {
    id?: number;
}

export interface TitrePedagogiqueGetDisplayNameRequest {
    id?: number;
}

export interface TitrePedagogiqueGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface TitrePedagogiqueGetSelectItemsRequest {
    searchField: string;
}

export interface TitrePedagogiqueSaveRequest {
    FcbTitrePedagogiqueDto?: FcbTitrePedagogiqueDto;
}

/**
 * 
 */
export class TitrePedagogiqueApi extends runtime.BaseAPI {

    /**
     */
    async titrePedagogiqueBaseSearchRaw(requestParameters: TitrePedagogiqueBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbTitrePedagogiqueDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TitrePedagogique/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TitrePedagogiqueSearchToJSON(requestParameters.TitrePedagogiqueSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbTitrePedagogiqueDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async titrePedagogiqueBaseSearch(requestParameters: TitrePedagogiqueBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbTitrePedagogiqueDtoPaginatedResults> {
        const response = await this.titrePedagogiqueBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async titrePedagogiqueDeleteRaw(requestParameters: TitrePedagogiqueDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TitrePedagogique`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async titrePedagogiqueDelete(requestParameters: TitrePedagogiqueDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.titrePedagogiqueDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async titrePedagogiqueExistsRaw(requestParameters: TitrePedagogiqueExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.libelle !== undefined) {
            queryParameters['libelle'] = requestParameters.libelle;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TitrePedagogique/Exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async titrePedagogiqueExists(requestParameters: TitrePedagogiqueExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.titrePedagogiqueExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async titrePedagogiqueGetRaw(requestParameters: TitrePedagogiqueGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbTitrePedagogiqueDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TitrePedagogique`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbTitrePedagogiqueDtoFromJSON(jsonValue));
    }

    /**
     */
    async titrePedagogiqueGet(requestParameters: TitrePedagogiqueGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbTitrePedagogiqueDto> {
        const response = await this.titrePedagogiqueGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async titrePedagogiqueGetDisplayNameRaw(requestParameters: TitrePedagogiqueGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TitrePedagogique/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async titrePedagogiqueGetDisplayName(requestParameters: TitrePedagogiqueGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.titrePedagogiqueGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async titrePedagogiqueGetSearchCriteriasRaw(requestParameters: TitrePedagogiqueGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TitrePedagogique/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async titrePedagogiqueGetSearchCriterias(requestParameters: TitrePedagogiqueGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.titrePedagogiqueGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async titrePedagogiqueGetSelectItemsRaw(requestParameters: TitrePedagogiqueGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling titrePedagogiqueGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TitrePedagogique/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async titrePedagogiqueGetSelectItems(requestParameters: TitrePedagogiqueGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.titrePedagogiqueGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async titrePedagogiqueSaveRaw(requestParameters: TitrePedagogiqueSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbTitrePedagogiqueDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TitrePedagogique`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbTitrePedagogiqueDtoToJSON(requestParameters.FcbTitrePedagogiqueDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbTitrePedagogiqueDtoFromJSON(jsonValue));
    }

    /**
     */
    async titrePedagogiqueSave(requestParameters: TitrePedagogiqueSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbTitrePedagogiqueDto> {
        const response = await this.titrePedagogiqueSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
