/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRechercheDateAbsenceDto
 */
export interface FcbRechercheDateAbsenceDto {
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheDateAbsenceDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheDateAbsenceDto
     */
    classe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheDateAbsenceDto
     */
    codeclasse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheDateAbsenceDto
     */
    jour?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheDateAbsenceDto
     */
    heures?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheDateAbsenceDto
     */
    statutAbsence?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheDateAbsenceDto
     */
    idapprenant?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheDateAbsenceDto
     */
    idclasse?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRechercheDateAbsenceDto
     */
    orderjour?: Date | null;
}

/**
 * Check if a given object implements the FcbRechercheDateAbsenceDto interface.
 */
export function instanceOfFcbRechercheDateAbsenceDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRechercheDateAbsenceDtoFromJSON(json: any): FcbRechercheDateAbsenceDto {
    return FcbRechercheDateAbsenceDtoFromJSONTyped(json, false);
}

export function FcbRechercheDateAbsenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRechercheDateAbsenceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'classe': !exists(json, 'classe') ? undefined : json['classe'],
        'codeclasse': !exists(json, 'codeclasse') ? undefined : json['codeclasse'],
        'jour': !exists(json, 'jour') ? undefined : json['jour'],
        'heures': !exists(json, 'heures') ? undefined : json['heures'],
        'statutAbsence': !exists(json, 'statutAbsence') ? undefined : json['statutAbsence'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'orderjour': !exists(json, 'orderjour') ? undefined : (json['orderjour'] === null ? null : new Date(json['orderjour'])),
    };
}

export function FcbRechercheDateAbsenceDtoToJSON(value?: FcbRechercheDateAbsenceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'anneeScolaire': value.anneeScolaire,
        'classe': value.classe,
        'codeclasse': value.codeclasse,
        'jour': value.jour,
        'heures': value.heures,
        'statutAbsence': value.statutAbsence,
        'idapprenant': value.idapprenant,
        'idclasse': value.idclasse,
        'orderjour': value.orderjour === undefined ? undefined : (value.orderjour === null ? null : value.orderjour.toISOString()),
    };
}

