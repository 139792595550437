import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import styled from "styled-components";

import { ICustomButtonProps } from "./ICustomButtonProps";

const StyledButton = styled(Button)`
  &:focus {
    outline: none;
  }

  & *:focus {
    outline: none;
  }
`;

interface IFavoriteButtonProps extends Omit<ICustomButtonProps, "intent" | "minimal" | "text"> {
  selected: boolean;
}

export const FavoriteButton: React.FunctionComponent<IFavoriteButtonProps> = ({
  onClick,
  disabled,
  loading,
  selected
}) => {
  return (
    <StyledButton
      disabled={disabled}
      minimal
      onClick={onClick}
      intent={Intent.WARNING}
      icon={selected ? IconNames.STAR : IconNames.STAR_EMPTY}
      loading={loading}
    />
  );
};
