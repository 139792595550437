import { Button, Colors, HTMLSelect, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useRequest } from "ahooks";
import {
  ButtonContainer,
  ButtonsBloc,
  DataTable,
  FieldSet,
  InlineButtonContainer,
  Loading,
  showError,
  showSuccess,
  useGridState
} from "nsitools-react";
import * as React from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import { useHistory, useParams } from "react-router";

import {
  FcbApprenantPresenceDetailDto,
  FcbApprenantPresenceDetailSaveDto,
  FcbPresenceFormateurDto,
  FeuillePresenceApi,
  FeuillePresenceSaveDto,
  FeuillePresenceSaveDtoFromJSON
} from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { CancelButton, SaveButton, TableTextEditor, ViewButton } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { Tooltip2 } from "@blueprintjs/popover2";

export interface IFeuillePresenceDetailTableProps {
  idClasse?: number;
  dateClasse?: Date;
  refetchDates?: () => void;
}

const ValidCodePresence = ["X", "O", "R", "N"];
const ValidCodePresenceFormateur = ["@", "X", "O", "R", "N"];

type ColumnStatusType = { [key: number]: string };

export const FeuillePresenceDetailTable: React.FunctionComponent<IFeuillePresenceDetailTableProps> = ({
  idClasse,
  dateClasse,
  refetchDates
}) => {
  const { state } = useParams<{ id: string; tab: string; state: string; date: string }>();
  const api = useApiService(FeuillePresenceApi);
  const { t } = useTl();
  const history = useHistory();
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [columnStatusLoading, setColumnStatusLoading] = React.useState(false);

  const tableState = useGridState<FcbApprenantPresenceDetailDto>({
    serverMode: false,
    enablePagination: false,
    enableFilter: false
  });

  const { data: feuillePresenceData, loading, run, refresh } = useRequest(
    () => api.feuillePresenceGetFeuillePresenceInfo({ idClasse, dateClasse }),
    {
      refreshDeps: [idClasse, dateClasse],
      manual: true
    }
  );

  // const { data: columns = [], loading: tableColumnsLoading, run: runColumns } = useRequest(
  //   () => api.feuillePresenceGetHoraireClasseJourColumns({ idClasse, dateClasse }),
  //   {
  //     refreshDeps: [idClasse, dateClasse],
  //     manual: true
  //   }
  // );

  // const { data: tableData = [], loading: dataLoading, run, refresh } = useRequest(
  //   () => api.feuillePresenceGetApprenantList({ idClasse, dateClasse }),
  //   {
  //     refreshDeps: [idClasse, dateClasse],
  //     manual: true
  //   }
  // );

  const tableData = React.useMemo(() => feuillePresenceData?.apprenantPresences, [
    feuillePresenceData?.apprenantPresences
  ]);

  const columns = React.useMemo(() => feuillePresenceData?.columnsInfo, [feuillePresenceData?.columnsInfo]);

  const { data, setData } = tableState;
  const [formateurData, setFormateurData] = React.useState<FcbPresenceFormateurDto[]>([]);

  React.useEffect(() => {
    if (feuillePresenceData?.formateurPresences) {
      setFormateurData(feuillePresenceData?.formateurPresences);
    }
  }, [feuillePresenceData?.formateurPresences]);

  React.useEffect(() => {
    if (dateClasse) {
      run();
    }
  }, [dateClasse, run]);

  React.useEffect(() => {
    if (tableData && columns.length > 0) {
      setData(tableData);
    }
  }, [tableData, setData, columns]);

  const [status, setStatus] = React.useState<ColumnStatusType>({});
  const editable = React.useMemo(() => {
    return state === "edit" && dateClasse <= new Date();
  }, [dateClasse, state]);

  React.useEffect(() => {
    if (columns) {
      setStatus(() => columns.map(d => d.idclasseHoraire).reduce((prv, curr) => ({ ...prv, [curr]: "X" }), {}));
    }
  }, [columns]);

  const save = React.useCallback(async () => {
    setSaveLoading(true);

    const listApprenants: FcbApprenantPresenceDetailSaveDto[] = [];
    data.forEach((row: FcbApprenantPresenceDetailDto) => {
      const subRows: FcbApprenantPresenceDetailSaveDto[] = columns.map(c => ({
        idInscription: row.idInscription,
        idClasseHoraire: c.idclasseHoraire,
        statut: row.statutClasseHoraire[c.idclasseHoraire]
      }));
      listApprenants.push(...subRows);
    });

    const saveDto: FeuillePresenceSaveDto = FeuillePresenceSaveDtoFromJSON({
      apprenantPresences: listApprenants,
      formateurPresences: formateurData
    });

    try {
      await api.feuillePresenceSave({ FeuillePresenceSaveDto: saveDto });
      setSaveLoading(false);
      showSuccess(t(ETLCodes.General_SavedSuccessMessage));
      refresh();
      refetchDates();
    } catch (e) {
      setSaveLoading(false);
      showError(t(ETLCodes.General_SaveErrorMessage));
    }
  }, [api, columns, data, formateurData, refetchDates, refresh, t]);

  const handleColumnStatusChange = React.useCallback(
    (value, idClasseHoraire) => {
      const newStatuses = { ...status };
      newStatuses[idClasseHoraire] = value;
      setStatus(newStatuses);
    },
    [status]
  );

  const setFormateurStatut = React.useCallback(
    (value: string, idClasseHoraire: number, idFormateur: number) => {
      const newData = [...formateurData];
      newData.find(f => f.idclasseHoraire === idClasseHoraire && f.idformateur === idFormateur).statut = value;
      setFormateurData(newData);
    },
    [formateurData]
  );

  const setCellStatut = React.useCallback(
    (value, row: FcbApprenantPresenceDetailDto, idClasseHoraire: number) => {
      row.statutClasseHoraire[idClasseHoraire] = value;
      const rowIndex = data.findIndex(d => d.idInscription === row.idInscription);
      data[rowIndex] = { ...row, statutClasseHoraire: row.statutClasseHoraire };

      setData([...data]);
    },
    [data, setData]
  );

  const applyStatusToColumn = React.useCallback(
    async (idClasseHoraire: number) => {
      setColumnStatusLoading(true);
      data
        .filter(row => row.statutClasseHoraire[idClasseHoraire] === "@")
        .forEach(row => {
          setCellStatut(status[idClasseHoraire], row, idClasseHoraire);
        });
      setData([...data]);
      setColumnStatusLoading(false);
    },
    [data, setCellStatut, setData, status]
  );

  const applyToNextColumn = React.useCallback(
    (idClasseHoraire: number) => {
      const indexOfCurrentIdClassHoraire = columns.map(d => d.idclasseHoraire).indexOf(idClasseHoraire);
      const nextIdClassHoraire = columns[indexOfCurrentIdClassHoraire + 1]?.idclasseHoraire;

      setColumnStatusLoading(true);
      data
        .filter(
          row =>
            row.statutClasseHoraire[nextIdClassHoraire] === "@" &&
            ValidCodePresence.includes(row.statutClasseHoraire[idClasseHoraire])
        )
        .forEach(row => {
          setCellStatut(row.statutClasseHoraire[idClasseHoraire], row, nextIdClassHoraire);
        });
      setData([...data]);
      setColumnStatusLoading(false);
    },
    [columns, data, setCellStatut, setData]
  );

  const getFormateurDisplay = React.useCallback(
    (idclasseHoraire: number, principal: boolean) => {
      return formateurData?.find(f => f.idclasseHoraire === idclasseHoraire && f.principal === principal)?.formateur;
    },

    [formateurData]
  );

  const getFormateurPresenceValue = React.useCallback(
    (idclasseHoraire: number, principal: boolean) => {
      return formateurData?.find(f => f.idclasseHoraire === idclasseHoraire && f.principal === principal)?.statut;
    },
    [formateurData]
  );

  const columnsToTableColumns = React.useMemo(() => {
    if (columns) {
      return columns?.map((currentColumn, index) => {
        return {
          disableSorting: true,
          header: () => (
            <Grid fluid>
              <Row>
                <Col xs={6}>
                  <Tooltip2 content={getFormateurDisplay(currentColumn.idclasseHoraire, true)}>
                    <Icon icon={IconNames.USER} style={{ marginBottom: "0.5rem" }} />
                  </Tooltip2>
                </Col>
                <Col xs={6}>
                  <Tooltip2 content={getFormateurDisplay(currentColumn.idclasseHoraire, false)}>
                    <Icon
                      icon={IconNames.USER}
                      style={{ marginBottom: "0.5rem" }}
                      color={
                        !!formateurData?.find(f => f.idclasseHoraire === currentColumn.idclasseHoraire && !f.principal)
                          ? Colors.WHITE
                          : Colors.LIGHT_GRAY5
                      }
                    />
                  </Tooltip2>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <HTMLSelect
                    style={{ marginBottom: "0.5rem" }}
                    options={ValidCodePresenceFormateur}
                    value={getFormateurPresenceValue(currentColumn.idclasseHoraire, true)}
                    onChange={e => {
                      setFormateurStatut(
                        e.target.value,
                        currentColumn.idclasseHoraire,
                        formateurData?.find(f => f.idclasseHoraire === currentColumn.idclasseHoraire && f.principal)
                          ?.idformateur
                      );
                    }}
                    onClick={e => e.stopPropagation()}
                    disabled={
                      !editable ||
                      (feuillePresenceData?.idUserFormateur !== null &&
                        feuillePresenceData?.idUserFormateur !==
                          formateurData?.find(f => f.idclasseHoraire === currentColumn.idclasseHoraire && !f.principal)
                            ?.idformateur)
                    }
                  />
                </Col>
                <Col xs={6}>
                  <HTMLSelect
                    style={{ marginBottom: "0.5rem" }}
                    options={ValidCodePresenceFormateur}
                    value={getFormateurPresenceValue(currentColumn.idclasseHoraire, false)}
                    onChange={e => {
                      setFormateurStatut(
                        e.target.value,
                        currentColumn.idclasseHoraire,
                        formateurData?.find(f => f.idclasseHoraire === currentColumn.idclasseHoraire && !f.principal)
                          ?.idformateur
                      );
                    }}
                    onClick={e => e.stopPropagation()}
                    disabled={
                      !editable ||
                      !formateurData?.find(f => f.idclasseHoraire === currentColumn.idclasseHoraire && !f.principal) ||
                      (feuillePresenceData?.idUserFormateur !== null &&
                        feuillePresenceData?.idUserFormateur !==
                          formateurData?.find(f => f.idclasseHoraire === currentColumn.idclasseHoraire && !f.principal)
                            ?.idformateur)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>{`${currentColumn.heureDebut} - ${currentColumn.codeClassMatiere || ""}`}</Col>
              </Row>
              <Row>
                <Col xs={7}>
                  <HTMLSelect
                    options={ValidCodePresence}
                    value={status ? status[currentColumn.idclasseHoraire] : "X"}
                    onChange={e => {
                      handleColumnStatusChange(e.target.value, currentColumn.idclasseHoraire);
                    }}
                    onClick={e => e.stopPropagation()}
                    disabled={!editable}
                  />
                </Col>
                <Col xs={3}>
                  <Button
                    loading={columnStatusLoading}
                    icon={IconNames.CHEVRON_DOWN}
                    onClick={e => {
                      e.stopPropagation();
                      applyStatusToColumn(currentColumn.idclasseHoraire);
                    }}
                    disabled={!editable}
                  />
                </Col>
                <Col xs={2}>
                  {
                    <Button
                      loading={columnStatusLoading}
                      icon={IconNames.CHEVRON_RIGHT}
                      onClick={e => {
                        e.stopPropagation();
                        applyToNextColumn(currentColumn.idclasseHoraire);
                      }}
                      disabled={!editable || index + 1 === columns?.length}
                    />
                  }
                </Col>
              </Row>
            </Grid>
          ),
          fieldName: `statut_${index}`,
          render: (row: FcbApprenantPresenceDetailDto) => (
            <TableTextEditor
              initialTextValue={row.statutClasseHoraire[currentColumn.idclasseHoraire]}
              maxLength={2}
              onValueChanged={(value: string) => setCellStatut(value, row, currentColumn.idclasseHoraire)}
              authorizedValues={ValidCodePresence}
              tabIndex={data.findIndex(d => d.idInscription === row.idInscription) + 1 + (index + 1) * data?.length}
              editable={editable}
            ></TableTextEditor>
          )
        };
      });
    } else return [];
  }, [
    applyStatusToColumn,
    applyToNextColumn,
    columnStatusLoading,
    columns,
    data,
    editable,
    feuillePresenceData?.idUserFormateur,
    formateurData,
    getFormateurDisplay,
    getFormateurPresenceValue,
    handleColumnStatusChange,
    setCellStatut,
    setFormateurStatut,
    status
  ]);

  const columnsMemo = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbApprenantPresenceDetailDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.apprenant}/${row.idApprenant}/justificatifs`)}
              tabIndex={
                data.findIndex(d => d.idInscription === row.idInscription) +
                1 +
                ((columns?.length ?? 0) + 1) * data?.length
              }
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Contrat),
        fieldName: "contrat",
        disableSorting: true
      },
      {
        header: () => t(ETLCodes.Apprenant),
        fieldName: "apprenant",
        disableSorting: true
      },
      ...columnsToTableColumns
    ],
    [columns?.length, columnsToTableColumns, data, history, t]
  );

  const NbApprenantTitle = React.useCallback(() => {
    return <>{data.length + " " + t(ETLCodes.Apprenants)}</>;
  }, [data.length, t]);

  return (
    <Loading isLoading={loading}>
      <div style={{ marginBottom: "1rem" }}>
        {idClasse > 0 && (
          <FieldSet title={t(ETLCodes.Presence)} rightElement={NbApprenantTitle()}>
            <DataTable
              dateFormat="dd-MM-yyyy"
              tableState={tableState}
              loading={loading}
              columns={columnsMemo}
              keyFieldName="idInscription"
            />
            <InlineButtonContainer>
              <ButtonsBloc />
              <ButtonsBloc>
                <CancelButton minimal={false} onClick={() => history.push(ERoutes.feuillePresence)} />
                {editable ? (
                  <SaveButton minimal={false} onClick={() => save()} loading={saveLoading} />
                ) : (
                  editable && <div />
                )}
              </ButtonsBloc>
            </InlineButtonContainer>
          </FieldSet>
        )}
      </div>
    </Loading>
  );
};
