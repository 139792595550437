/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbGestionDroitsGridDto
 */
export interface FcbGestionDroitsGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbGestionDroitsGridDto
     */
    module?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbGestionDroitsGridDto
     */
    tab?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbGestionDroitsGridDto
     */
    idaction?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbGestionDroitsGridDto
     */
    idaccesLevel?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbGestionDroitsGridDto
     */
    permissions?: string | null;
}

/**
 * Check if a given object implements the FcbGestionDroitsGridDto interface.
 */
export function instanceOfFcbGestionDroitsGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbGestionDroitsGridDtoFromJSON(json: any): FcbGestionDroitsGridDto {
    return FcbGestionDroitsGridDtoFromJSONTyped(json, false);
}

export function FcbGestionDroitsGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbGestionDroitsGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'module': !exists(json, 'module') ? undefined : json['module'],
        'tab': !exists(json, 'tab') ? undefined : json['tab'],
        'idaction': !exists(json, 'idaction') ? undefined : json['idaction'],
        'idaccesLevel': !exists(json, 'idaccesLevel') ? undefined : json['idaccesLevel'],
        'permissions': !exists(json, 'permissions') ? undefined : json['permissions'],
    };
}

export function FcbGestionDroitsGridDtoToJSON(value?: FcbGestionDroitsGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'module': value.module,
        'tab': value.tab,
        'idaction': value.idaction,
        'idaccesLevel': value.idaccesLevel,
        'permissions': value.permissions,
    };
}

