import { Intent } from "@blueprintjs/core";
import { addMinutes, format, parseJSON } from "date-fns";
import {
  ButtonContainer,
  DataTable,
  FGListen,
  FGTextAreaInput,
  FieldGroup,
  IDataTableColumn,
  IFGContext,
  showError,
  showSuccess,
  useGridState
} from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { AnneeFormationSuivieIfapmeDocumentEditDialog, AnneeFormationSuivieIfapmeDocumentUploadDialog } from ".";
import {
  AcquisScolaireHopeApi,
  AnneeFormationSuivieIfapmeEditDtoFromJSON,
  AnneeFormationSuivieIfapmeFichierGridDto,
  EIfapmeSide
} from "../../../../../../api";
import {
  AddButton,
  DeleteButton,
  DownloadButton,
  EditButton,
  FGWalterSelectInput,
  SaveButton,
  SmallFormGenerator
} from "../../../../../../components";
import { useDialog } from "../../../../../../contexts";
import { useApiService, useCrudApi, useTheme, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";
import { exportFile } from "../../../../../../utils";

export interface IAnneeFormationSuivieIfapmeDetailProps {
  onTypeAcquisChange?: (typeAcquis: string) => void;
  idpersonne: number;
  listRoute: string;
}

export const AnneeFormationSuivieIfapmeDetail: React.FunctionComponent<IAnneeFormationSuivieIfapmeDetailProps> = ({
  onTypeAcquisChange,
  idpersonne,
  listRoute
}) => {
  const { t } = useTl();
  const api = useApiService(AcquisScolaireHopeApi);
  const [typeAcquis, taLoading] = useReferential(a => a.referentialGetAcquisScolaireTypes(), false, []);
  const [centres, cLoading] = useReferential(a => a.referentialGetCentre(), true, []);
  const [degres, dLoading] = useReferential(a => a.referentialGetDegre(), false, []);
  const [metiers, mLoading] = useReferential(a => a.referentialGetMetier(), true, []);
  const [resultats, rLoading] = useReferential(a => a.referentialGetResultatValides(), true, []);
  const [stades, sLoading] = useReferential(a => a.referentialGetStade(), true, []);
  const { ifapmeSide } = useTheme();
  const [typeCours, tcLoading] = useReferential(
    a => a.referentialGetTypeCours({ ifapmeSide: ifapmeSide === "hope" ? EIfapmeSide.Hope : EIfapmeSide.Walter }),
    false,
    [ifapmeSide]
  );

  const [anneeFormations, afLoading] = useReferential(a => a.referentialGetAnneeScolaire(), false, []);
  const history = useHistory();
  const { state, acquisId } = useParams<{
    id: string;
    tab: string;
    state: string;
    acquisId: string;
  }>();
  const editMode = React.useMemo(() => state === "edit", [state]);

  const saveFn = React.useCallback(
    d => api.acquisScolaireHopeSaveAnneeFormationSuivieIfapme({ AnneeFormationSuivieIfapmeEditDto: d }),
    [api]
  );

  const { data, loading, saveItem, saving, deleteItem, deleting, validationErrors, refresh: refreshData } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: async () => {
          return +acquisId <= 0
            ? AnneeFormationSuivieIfapmeEditDtoFromJSON({
                idanneeFormationSuivieIfapme: +acquisId,
                idpersonne: idpersonne,
                typeAcquis: "AnneeFormationSuivie"
              })
            : api.acquisScolaireHopeGetAnneeFormationSuivieIfapme({ id: +acquisId });
        },
        saveApiFn: saveFn,
        onSavedRoute: d => listRoute,
        getDeps: [acquisId],
        deleteApiFn: d =>
          api.acquisScolaireHopeDeleteAnneeFormationSuivieIfapme({ id: d.idanneeFormationSuivieIfapme }),
        onDeletedRoute: () => listRoute
      }),
      [saveFn, acquisId, idpersonne, api, listRoute]
    )
  );
  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      typeAcquis: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  const additionalRightButtons = React.useCallback(
    (ctx: IFGContext<any>) =>
      +acquisId <= 0 && (
        <SaveButton
          onClick={async () => {
            await saveFn(ctx.formik.values);
            showSuccess(t(ETLCodes.SaveSuccess));
            ctx.formik.resetForm();
          }}
          text={t(ETLCodes.SauverEtNouveau)}
          minimal={false}
          loading={saving}
          disabled={!ctx.formik.dirty}
        />
      ),
    [acquisId, saveFn, saving, t]
  );

  const tableState = useGridState<any>({
    serverMode: false,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { uploadDate: "DESC", fileName: "ASC" }
  });

  const { setData } = tableState;

  React.useEffect(() => {
    if (data?.fichiers) {
      setData(data.fichiers);
    }
  }, [data?.fichiers, setData]);

  const [currentAnneeFormationSuivieIfapmeFichierId, setCurrentAnneeFormationSuivieIfapmeFichierId] = React.useState(
    null
  );
  const [uploadDialoOpen, setUploadDialoOpen] = React.useState(false);
  const [rowLoading, setRowLoading] = React.useState(null);

  const { showDialogPromise } = useDialog();
  const deleteFichier = React.useCallback(
    async (idanneeFormationSuivieIfapmeFichier: number) => {
      const result = await showDialogPromise({
        message: t(ETLCodes.DeleteConfirmationMessage)
      });

      if (result === "yes") {
        try {
          setRowLoading(idanneeFormationSuivieIfapmeFichier);
          await api.acquisScolaireHopeDeleteAnneeFormationSuivieFichier({
            id: idanneeFormationSuivieIfapmeFichier
          });
          refreshData();
        } catch {
          showError(t(ETLCodes.ErrorWhileDeletingFile));
        } finally {
          setRowLoading(null);
        }
      }
    },
    [api, refreshData, showDialogPromise, t]
  );

  const downloadFichier = React.useCallback(
    async (idanneeFormationSuivieIfapmeFichier: number) => {
      try {
        setRowLoading(idanneeFormationSuivieIfapmeFichier);
        const file = await api.acquisScolaireHopeDownloadAnneeFormationSuivieFichier({
          id: idanneeFormationSuivieIfapmeFichier
        });
        await exportFile(file);
      } catch {
        showError(t(ETLCodes.ErrorWhileDownloadingFile));
      } finally {
        setRowLoading(null);
      }
    },
    [api, t]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: AnneeFormationSuivieIfapmeFichierGridDto) => (
          <ButtonContainer>
            <EditButton
              minimal={true}
              onClick={() => setCurrentAnneeFormationSuivieIfapmeFichierId(row.idanneeFormationSuivieIfapmeFichier)}
              loading={rowLoading === row.idanneeFormationSuivieIfapmeFichier}
              disabled={!!rowLoading && rowLoading !== row.idanneeFormationSuivieIfapmeFichier}
            />
            <DownloadButton
              minimal={true}
              onClick={() => downloadFichier(row.idanneeFormationSuivieIfapmeFichier)}
              loading={rowLoading === row.idanneeFormationSuivieIfapmeFichier}
              disabled={!!rowLoading && rowLoading !== row.idanneeFormationSuivieIfapmeFichier}
            />
            <DeleteButton
              minimal={true}
              onDelete={() => deleteFichier(row.idanneeFormationSuivieIfapmeFichier)}
              loading={rowLoading === row.idanneeFormationSuivieIfapmeFichier}
              disabled={!!rowLoading && rowLoading !== row.idanneeFormationSuivieIfapmeFichier}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.NomFichier),
        fieldName: "fileName"
      },
      {
        header: () => t(ETLCodes.DateUpload),
        fieldName: "uploadDate"
      },
      {
        header: () => t(ETLCodes.UtilisateurUpload),
        fieldName: "creationUserName"
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "typeAcquisFichier"
      }
    ],
    [deleteFichier, downloadFichier, rowLoading, t]
  );

  const formatDate = React.useCallback((date: Date) => {
    return format(parseJSON(addMinutes(date, date.getTimezoneOffset())), "dd/MM/yyyy HH:mm");
  }, []);

  const onClose = React.useCallback(
    (refresh?: boolean) => {
      setCurrentAnneeFormationSuivieIfapmeFichierId(null);
      setUploadDialoOpen(false);
      if (refresh) {
        refreshData();
      }
    },
    [refreshData]
  );

  const onAdd = React.useCallback(() => {
    setUploadDialoOpen(true);
  }, []);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      showColons
      editMode={editMode}
      minLabelWidth={180}
      labelAlignment="right"
      formatDate="dd/MM/yyyy"
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(listRoute)}
      saving={saving}
      showDeleteButton={+acquisId > 0}
      onDelete={deleteItem}
      deleting={deleting}
      validationErrors={validationErrors}
      additionalRightButtons={additionalRightButtons}
    >
      <FieldGroup columns={2}>
        <FieldGroup
          fieldsetProps={{
            title: t(ETLCodes.AnneeFormationSuivieIfapme)
          }}
        >
          <FGListen field="typeAcquis" onChanged={onTypeAcquisChange}></FGListen>
          <FGWalterSelectInput
            label={t(ETLCodes.Type)}
            name="typeAcquis"
            items={typeAcquis}
            loading={taLoading}
            readonly={+(acquisId ?? 0) > 0}
          />
          <FGWalterSelectInput
            label={t(ETLCodes.Annee)}
            name="idanneeDeFormation"
            items={anneeFormations}
            loading={afLoading}
          />
          <FGWalterSelectInput label={t(ETLCodes.Stade)} name="idstade" items={stades} loading={sLoading} />
          <FGWalterSelectInput
            label={t(ETLCodes.TypeCours)}
            name="idtypeDeCours"
            items={typeCours}
            loading={tcLoading}
          />
          <FGWalterSelectInput label={t(ETLCodes.Degre)} name="iddegre" items={degres} loading={dLoading} />
          <FGWalterSelectInput label={t(ETLCodes.Metier)} name="idmetier" items={metiers} loading={mLoading} />
          <FGWalterSelectInput label={t(ETLCodes.Centre)} name="idcentre" items={centres} loading={cLoading} />
          <FGWalterSelectInput
            label={t(ETLCodes.ResultatValide)}
            name="idresultatValide"
            items={resultats}
            loading={rLoading}
          />
          <FGTextAreaInput name="remarque" label={t(ETLCodes.Remarque)} />
        </FieldGroup>
      </FieldGroup>
      <FieldGroup
        visible={+acquisId > 0}
        fieldsetProps={{
          title: t(ETLCodes.Documents),
          rightElement: (
            <AddButton
              onClick={e => {
                e.stopPropagation();
                onAdd();
              }}
              text={t(ETLCodes.General_Add)}
              intent={Intent.PRIMARY}
            />
          )
        }}
      >
        <DataTable
          dateFormat="dd/MM/yyyy HH:mm"
          formatDate={formatDate}
          tableState={tableState}
          loading={loading}
          columns={columns}
        ></DataTable>
        {uploadDialoOpen && (
          <AnneeFormationSuivieIfapmeDocumentUploadDialog
            onClose={onClose}
            dialogOpen={uploadDialoOpen}
            idanneeFormationSuivieIfapme={+acquisId}
          />
        )}
        {!!currentAnneeFormationSuivieIfapmeFichierId && (
          <AnneeFormationSuivieIfapmeDocumentEditDialog
            onClose={onClose}
            idanneeFormationSuivieIfapmeFichier={currentAnneeFormationSuivieIfapmeFichierId}
          />
        )}
      </FieldGroup>
    </SmallFormGenerator>
  );
};
