/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { BooleanSearch } from './BooleanSearch';
import {
    BooleanSearchFromJSON,
    BooleanSearchFromJSONTyped,
    BooleanSearchToJSON,
} from './BooleanSearch';
import type { FilterTextSearch } from './FilterTextSearch';
import {
    FilterTextSearchFromJSON,
    FilterTextSearchFromJSONTyped,
    FilterTextSearchToJSON,
} from './FilterTextSearch';
import type { NumericSearch } from './NumericSearch';
import {
    NumericSearchFromJSON,
    NumericSearchFromJSONTyped,
    NumericSearchToJSON,
} from './NumericSearch';
import type { TextSearch } from './TextSearch';
import {
    TextSearchFromJSON,
    TextSearchFromJSONTyped,
    TextSearchToJSON,
} from './TextSearch';

/**
 * 
 * @export
 * @interface ProspectSearch
 */
export interface ProspectSearch {
    /**
     * 
     * @type {TextSearch}
     * @memberof ProspectSearch
     */
    nomSa?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof ProspectSearch
     */
    prenomSa?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof ProspectSearch
     */
    anneeScolaireCriteria?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof ProspectSearch
     */
    telephone?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof ProspectSearch
     */
    gsm?: TextSearch;
    /**
     * 
     * @type {FilterTextSearch}
     * @memberof ProspectSearch
     */
    classeCriteria?: FilterTextSearch;
    /**
     * 
     * @type {NumericSearch}
     * @memberof ProspectSearch
     */
    idapprenant?: NumericSearch;
    /**
     * 
     * @type {BooleanSearch}
     * @memberof ProspectSearch
     */
    isCrea?: BooleanSearch;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProspectSearch
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof ProspectSearch
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof ProspectSearch
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProspectSearch
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof ProspectSearch
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof ProspectSearch
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the ProspectSearch interface.
 */
export function instanceOfProspectSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProspectSearchFromJSON(json: any): ProspectSearch {
    return ProspectSearchFromJSONTyped(json, false);
}

export function ProspectSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProspectSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nomSa': !exists(json, 'nomSa') ? undefined : TextSearchFromJSON(json['nomSa']),
        'prenomSa': !exists(json, 'prenomSa') ? undefined : TextSearchFromJSON(json['prenomSa']),
        'anneeScolaireCriteria': !exists(json, 'anneeScolaireCriteria') ? undefined : TextSearchFromJSON(json['anneeScolaireCriteria']),
        'telephone': !exists(json, 'telephone') ? undefined : TextSearchFromJSON(json['telephone']),
        'gsm': !exists(json, 'gsm') ? undefined : TextSearchFromJSON(json['gsm']),
        'classeCriteria': !exists(json, 'classeCriteria') ? undefined : FilterTextSearchFromJSON(json['classeCriteria']),
        'idapprenant': !exists(json, 'idapprenant') ? undefined : NumericSearchFromJSON(json['idapprenant']),
        'isCrea': !exists(json, 'isCrea') ? undefined : BooleanSearchFromJSON(json['isCrea']),
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function ProspectSearchToJSON(value?: ProspectSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nomSa': TextSearchToJSON(value.nomSa),
        'prenomSa': TextSearchToJSON(value.prenomSa),
        'anneeScolaireCriteria': TextSearchToJSON(value.anneeScolaireCriteria),
        'telephone': TextSearchToJSON(value.telephone),
        'gsm': TextSearchToJSON(value.gsm),
        'classeCriteria': FilterTextSearchToJSON(value.classeCriteria),
        'idapprenant': NumericSearchToJSON(value.idapprenant),
        'isCrea': BooleanSearchToJSON(value.isCrea),
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

