import { Intent } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { AcquisProfessionnelApi, AcquisProfessionnelSearch, FcbAcquisProfessionnelGridDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { AddButton, EditButton, ViewButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IApprenantAcquisProfessionnelSearchProps {}

export const ApprenantAcquisProfessionnelSearch: React.FunctionComponent<IApprenantAcquisProfessionnelSearchProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const apprenantId = React.useMemo(() => +id, [id]);
  const api = useApiService(AcquisProfessionnelApi);
  const path = React.useMemo(() => `${ERoutes.apprenant}/${apprenantId}/acquisprofessionnel`, [apprenantId]);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { dateDebut: "DESC" }
  });

  const { totalCount } = tableState;

  const searchFunc = React.useCallback(
    (sObj?: AcquisProfessionnelSearch) => {
      sObj.idapprenant = apprenantId;
      return api.acquisProfessionnelBaseSearch({ AcquisProfessionnelSearch: sObj });
    },
    [api, apprenantId]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction: searchFunc,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbAcquisProfessionnelGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => history.push(`${path}/${row.idacquisProfessionnel}/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${path}/${row.idacquisProfessionnel}/edit`)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Entreprise),
        fieldName: "entreprise"
      },
      {
        header: () => t(ETLCodes.Adresse),
        fieldName: "adresse"
      },
      {
        header: () => t(ETLCodes.CodePostal),
        fieldName: "codePostal"
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      },
      {
        header: () => t(ETLCodes.DateDebut),
        fieldName: "dateDebut"
      },
      {
        header: () => t(ETLCodes.DateFin),
        fieldName: "dateFin"
      }
    ],
    [history, path, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${path}/0/edit`);
  }, [history, path]);

  return (
    <>
      {apprenantId > 0 && (
        <FieldSet
          title={t(ETLCodes.TableResults, { count: totalCount })}
          rightElement={
            <>
              <AddButton
                onClick={e => {
                  e.stopPropagation();
                  onAddItem();
                }}
                text={t(ETLCodes.General_Add)}
                intent={Intent.PRIMARY}
              />
            </>
          }
        >
          <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
        </FieldSet>
      )}
    </>
  );
};
