/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbMetierGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SecteurMetierLiesSearch,
  SelectItem,
} from '../models/index';
import {
    FcbMetierGridDtoPaginatedResultsFromJSON,
    FcbMetierGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SecteurMetierLiesSearchFromJSON,
    SecteurMetierLiesSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface SecteurMetierMetierLiesBaseSearchRequest {
    SecteurMetierLiesSearch?: SecteurMetierLiesSearch;
}

export interface SecteurMetierMetierLiesGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface SecteurMetierMetierLiesGetSelectItemsRequest {
    searchField: string;
}

/**
 * 
 */
export class SecteurMetierMetierLiesApi extends runtime.BaseAPI {

    /**
     */
    async secteurMetierMetierLiesBaseSearchRaw(requestParameters: SecteurMetierMetierLiesBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbMetierGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SecteurMetierLiesSearchToJSON(requestParameters.SecteurMetierLiesSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbMetierGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async secteurMetierMetierLiesBaseSearch(requestParameters: SecteurMetierMetierLiesBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbMetierGridDtoPaginatedResults> {
        const response = await this.secteurMetierMetierLiesBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async secteurMetierMetierLiesGetSearchCriteriasRaw(requestParameters: SecteurMetierMetierLiesGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async secteurMetierMetierLiesGetSearchCriterias(requestParameters: SecteurMetierMetierLiesGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.secteurMetierMetierLiesGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async secteurMetierMetierLiesGetSelectItemsRaw(requestParameters: SecteurMetierMetierLiesGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling secteurMetierMetierLiesGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async secteurMetierMetierLiesGetSelectItems(requestParameters: SecteurMetierMetierLiesGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.secteurMetierMetierLiesGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
