import { Loading, useApiEffect } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";

import {
  ClasseHoraireActions,
  ClasseHoraireCalendrierItem,
  ClasseHoraireItemPage,
  ClasseHoraireMatiereList,
  ClasseHoraireRemarque
} from ".";
import { ClasseHoraireApi } from "../../../../../api";
import { useApiService } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { useEventsContext } from "../../../../../contexts";
import { useQuery } from "react-query";

export interface IClasseHorairePageProps {}

export const ClasseHorairePage: React.FunctionComponent<IClasseHorairePageProps> = () => {
  const { id, state } = useParams<{ id: string; tab: string; state: string }>();
  const [classeMatiere, cmLoading] = useReferential(
    a => a.referentialGetClasseMatiereByClasse({ idClasse: +id }),
    true,
    [id]
  );
  const api = useApiService(ClasseHoraireApi);
  const [local, lLoading] = useReferential(a => a.referentialGetLocalByClasse({ idClasse: +id }), true, [id]);
  const [data, loading] = useApiEffect(() => api.classeHoraireIsHoraireLinkedToGap({ idClasse: +id }));
  const [editMode, setEditMode] = React.useState(state === "edit");
  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();

  React.useEffect(() => {
    const cb = d => setEditMode(d);
    subscribeToEvent("CLASSE_HORAIRE_EDIT", cb);
    return () => unsubscribeEvent("CLASSE_HORAIRE_EDIT", cb);
  }, [subscribeToEvent, unsubscribeEvent]);

  const fetchCanSave = React.useCallback(() => api.classeHoraireCanSave({ idClasse: +id }), [api, id]);
  const { data: canSave } = useQuery(["can-save-horaire", id], fetchCanSave);

  return (
    <Loading isLoading={loading}>
      {
        <>
          <div style={{ marginBottom: "1rem" }}>
            <ClasseHoraireCalendrierItem readOnly={data?.value} state={editMode} />
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <ClasseHoraireMatiereList />
          </div>
          <div style={{ marginBottom: "1rem" }}>
            <ClasseHoraireRemarque state={editMode} />
          </div>
          {!cmLoading && !lLoading && (
            <>
              <div style={{ marginBottom: "1rem" }}>
                <ClasseHoraireItemPage
                  state={editMode}
                  id={+id}
                  local={[{ value: "-", label: "-" }, ...local]}
                  classeMatiere={[{ value: "-", label: "-" }, ...classeMatiere]}
                  canSave={canSave?.value}
                />
              </div>
              <ClasseHoraireActions idClasse={+id} canSave={canSave?.value} />
            </>
          )}
        </>
      }
    </Loading>
  );
};
