import { Divider, Intent } from "@blueprintjs/core";
import {
  ButtonContainer,
  ButtonsBloc,
  DataTable,
  FGCustomPanel,
  FGNumberInput,
  FieldGroup,
  IDataTableColumn,
  useApiEffect,
  useGridState
} from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import styled, { css } from "styled-components";

import { ClasseMatiereApi, FcbClasseMatiereCutGridDto } from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import {
  AddButton,
  BooleanColumn,
  CancelButton,
  DeleteButton,
  SaveButton,
  SmallFormGenerator,
  StyledError
} from "../../../../../../components";
import { useDialog } from "../../../../../../contexts";
import { useApiService, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";
import { ClasseMatiereContratListEditor } from "./ClasseMatiereContratListEditor";
import { ClasseMatiereListEditor } from "./ClasseMatiereListEditor";
import { ClasseMatiereTextEditor } from "./ClasseMatiereTextEditor";

const FlexBox = styled.div`
  flex: 1;
`;

export interface IClasseMatiereCutPageProps {}

export const ClasseMatiereCutPage: React.FunctionComponent<IClasseMatiereCutPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ClasseMatiereApi);
  const dialogContext = useDialog();
  const { id, idMatiere, tab, state } = useParams<{ id: string; idMatiere: string; tab: string; state: string }>();
  const [arrayLength, setArrayLength] = React.useState(0);
  const [initialRow, setInitialRow] = React.useState(null);
  const [nbHeureError, setNbHeureError] = React.useState(false);
  const [tjError, setTjError] = React.useState(false);
  const [examenError, setExamenError] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [currentData, setCurrentData] = React.useState<FcbClasseMatiereCutGridDto[]>(null);

  const [data, loading] = useApiEffect(() => api.classeMatiereGetCut({ idClasseMatiere: +idMatiere }), [idMatiere]);
  const [formateurs, floading] = useReferential(a => a.referentialGetFormateurByIdClasse({ idClasse: +id }), true);
  const [locals, lloading] = useReferential(a => a.referentialLocalsByMatiere({ idClasseMatiere: +idMatiere }), true);

  const tableState = useGridState({
    serverMode: false,
    enablePagination: false,
    enableFilter: false,
    pageSize: 25
  });
  const { setData } = tableState;

  const computeSummary = React.useCallback((key: string, cutData: FcbClasseMatiereCutGridDto[]) => {
    return {
      key,
      value: cutData.map(c => c[key]).reduce((a, b) => (a ?? 0) + (b ?? 0), 0),
      text: null
    };
  }, []);

  const setErrors = React.useCallback(() => {
    if (!data) {
      setNbHeureError(false);
      setTjError(false);
      setExamenError(false);
    } else {
      const nbHeures = computeSummary("nbHeures", currentData);
      setNbHeureError((+data?.totalNbHeures ?? 0) !== (+nbHeures.value ?? 0));
      const tj = computeSummary("travail", currentData);
      setTjError((+data?.totalPoints ?? 0) !== (+tj.value ?? 0));
      const examen = computeSummary("examen", currentData);
      setExamenError((+data?.totalExamen ?? 0) !== (+examen.value ?? 0));
    }
  }, [computeSummary, currentData, data]);

  React.useEffect(() => {
    if (data?.cutData) {
      setCurrentData(data.cutData);
    }
  }, [data]);

  React.useEffect(() => {
    if (currentData) {
      const dataToSet = [...currentData];
      setData(dataToSet, null, [
        {
          key: "section",
          value: t(ETLCodes.Total) + " :",
          text: null
        },
        computeSummary("nbHeures", dataToSet),
        computeSummary("travail", dataToSet),
        computeSummary("examen", dataToSet)
      ]);
      setArrayLength(dataToSet.length);
      setInitialRow(dataToSet[0]);
      setErrors();
    }
  }, [computeSummary, currentData, setData, setErrors, t]);

  const onSave = React.useCallback(() => {
    dialogContext.showDialog({
      message: t(ETLCodes.SectionConfirmMessage),
      onConfirmed: async () => {
        try {
          setSaving(true);
          await api.classeMatiereSaveCut({ FcbClasseMatiereCutDto: { ...data, cutData: currentData } });
          setSaving(false);
          history.push(`${ERoutes.classe}/${id}/${tab}/${state}`);
        } catch (e) {
          setSaving(false);
        }
      }
    });
  }, [api, currentData, data, dialogContext, history, id, state, t, tab]);

  const deleteSection = React.useCallback(
    (idx: number) => {
      const dataToSet = [...currentData];
      dataToSet?.splice(idx, 1);
      setCurrentData(dataToSet);
    },
    [currentData]
  );

  const showDeleteBtn = React.useMemo(() => arrayLength > 1, [arrayLength]);

  const onValueChanged = React.useCallback(
    (value: any, key: string, row: FcbClasseMatiereCutGridDto, index: number, isNumber: boolean = false) => {
      const dataToSet = [...currentData];
      dataToSet[index][key] = value ? (isNumber ? +value : "" + value) : null;
      setCurrentData(dataToSet);
    },
    [currentData]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code"
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "description",
        customizeCellStyle: () =>
          css({
            padding: "2px !important"
          }),
        render: (row: FcbClasseMatiereCutGridDto, idx: number) => (
          <ClasseMatiereTextEditor
            maxLength={150}
            initialTextValue={row.description}
            onValueChanged={value => onValueChanged(value, "description", row, idx)}
          ></ClasseMatiereTextEditor>
        )
      },
      {
        header: () => t(ETLCodes.Niveau),
        fieldName: "niveau",
        render: (row: FcbClasseMatiereCutGridDto) =>
          row.niveau && row.niveau + (row.niveau > 0 && !!row.matiereParent ? ` (${row.matiereParent})` : "")
      },
      {
        header: () => t(ETLCodes.Section),
        fieldName: "section"
      },
      {
        header: () => t(ETLCodes.Heures),
        fieldName: "nbHeures",
        customizeCellStyle: () =>
          css({
            padding: "2px !important",
            width: "150px"
          }),
        render: (row: FcbClasseMatiereCutGridDto, idx: number) => (
          <ClasseMatiereTextEditor
            initialTextValue={row.nbHeures?.toString()}
            onValueChanged={value => onValueChanged(value, "nbHeures", row, idx, true)}
            textEditorHeight={20}
          ></ClasseMatiereTextEditor>
        )
      },
      {
        header: () => t(ETLCodes.TJ),
        fieldName: "travail",
        customizeCellStyle: () =>
          css({
            padding: "2px !important",
            width: "150px"
          }),
        render: (row: FcbClasseMatiereCutGridDto, idx: number) => (
          <ClasseMatiereTextEditor
            initialTextValue={row.travail?.toString()}
            onValueChanged={value => onValueChanged(value, "travail", row, idx, true)}
            textEditorHeight={20}
          ></ClasseMatiereTextEditor>
        )
      },
      {
        header: () => t(ETLCodes.Examen),
        fieldName: "examen",
        customizeCellStyle: () =>
          css({
            padding: "2px !important",
            width: "150px"
          }),
        render: (row: FcbClasseMatiereCutGridDto, idx: number) => (
          <ClasseMatiereTextEditor
            initialTextValue={row.examen?.toString()}
            onValueChanged={value => onValueChanged(value, "examen", row, idx, true)}
            textEditorHeight={20}
          ></ClasseMatiereTextEditor>
        )
      },
      {
        header: () => t(ETLCodes.Reussite),
        fieldName: "reussite",
        customizeCellStyle: () =>
          css({
            padding: "2px !important",
            width: "150px"
          }),
        render: (row: FcbClasseMatiereCutGridDto, idx: number) => (
          <ClasseMatiereTextEditor
            initialTextValue={row.reussite?.toString()}
            onValueChanged={value => onValueChanged(value, "reussite", row, idx)}
            textEditorHeight={20}
          ></ClasseMatiereTextEditor>
        )
      },
      {
        header: () => t(ETLCodes.Formateur),
        fieldName: "idformateur",
        customizeCellStyle: () =>
          css({
            padding: "2px !important",
            width: "205px"
          }),
        render: (row: FcbClasseMatiereCutGridDto, idx: number) => (
          <ClasseMatiereListEditor
            initialValue={row.idformateur}
            onValueChanged={value => onValueChanged(value, "idformateur", row, idx)}
            items={formateurs}
            loading={floading}
          ></ClasseMatiereListEditor>
        )
      },
      {
        header: () => t(ETLCodes.Contrat),
        fieldName: "idcontrat",
        customizeCellStyle: () =>
          css({
            padding: "2px !important",
            width: "205px"
          }),
        render: (row: FcbClasseMatiereCutGridDto, idx: number) => (
          <ClasseMatiereContratListEditor
            initialValue={row.idcontrat}
            onValueChanged={value => onValueChanged(value, "idcontrat", row, idx)}
            idMatiere={+idMatiere}
            idFormateur={row.idformateur}
          ></ClasseMatiereContratListEditor>
        )
      },
      {
        header: () => t(ETLCodes.Local),
        fieldName: "idlocal",
        customizeCellStyle: () =>
          css({
            padding: "2px !important",
            width: "205px"
          }),
        render: (row: FcbClasseMatiereCutGridDto, idx: number) => (
          <ClasseMatiereListEditor
            initialValue={row.idlocal}
            onValueChanged={value => onValueChanged(value, "idlocal", row, idx)}
            items={locals}
            loading={lloading}
          ></ClasseMatiereListEditor>
        )
      },
      {
        header: () => t(ETLCodes.UAA),
        fieldName: "uaa",
        alignment: "center",
        autoFitContent: true,
        render: (row: FcbClasseMatiereCutGridDto) => <BooleanColumn value={row.uaa} />
      },
      {
        header: () => t(ETLCodes.VisibleBulletin),
        fieldName: "isVisibleBulletin",
        alignment: "center",
        autoFitContent: true,
        render: (row: FcbClasseMatiereCutGridDto) => <BooleanColumn value={row.isVisibleBulletin} />
      },
      {
        header: () => t(ETLCodes.Sommative),
        fieldName: "sommative",
        alignment: "center",
        autoFitContent: true,
        render: (row: FcbClasseMatiereCutGridDto) => <BooleanColumn value={row.sommative} />
      },
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbClasseMatiereCutGridDto, idx: number) => (
          <ButtonContainer>
            <DeleteButton minimal={true} disabled={!showDeleteBtn} onDelete={() => deleteSection(idx)} />
          </ButtonContainer>
        )
      }
    ],
    [deleteSection, floading, formateurs, idMatiere, lloading, locals, onValueChanged, showDeleteBtn, t]
  );

  const onAddSection = React.useCallback(() => {
    const dataToSet = [...currentData];
    const sumSection = computeSummary("section", dataToSet).value;
    const maxSection = Math.max(...dataToSet.map(c => c.section));
    const full = maxSection === 1 || (maxSection * (maxSection + 1)) / 2 === sumSection;
    let i = maxSection + 1;

    if (!full) {
      for (i = 1; i <= 9; i++) {
        if (dataToSet[i - 1].section > i) {
          break;
        }
      }
    }

    const row: FcbClasseMatiereCutGridDto = {
      idclasseMatiere: 0,
      idclasse: initialRow.idclasse,
      idmatiere: initialRow.idmatiere,
      idreferentiel: initialRow.idreferentiel,
      description: initialRow.description,
      section: i,
      code: initialRow.code?.length < 10 ? initialRow.code + i : initialRow.code,
      examen: null,
      idcontrat: 0,
      idformateur: 0,
      idlocal: 0,
      nbHeures: null,
      reussite: initialRow.reussite,
      travail: null
    };
    dataToSet.splice(i - 1, 0, row);
    setCurrentData(dataToSet);
  }, [computeSummary, currentData, initialRow]);

  return (
    <>
      <SmallFormGenerator initialValues={data} loading={loading} editMode={false} onSubmit={null} hideButtons={true}>
        <FieldGroup>
          <FGNumberInput name="totalNbHeures" label={t(ETLCodes.TotalNbHeures)} />
          <FGNumberInput name="totalPoints" label={t(ETLCodes.TotalHeures)} />
          <FGNumberInput name="totalExamen" label={t(ETLCodes.TotalExamen)} />
        </FieldGroup>
      </SmallFormGenerator>
      <Divider />
      <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
      <FGCustomPanel>
        {ctx => (
          <>
            <StyledError show={nbHeureError} message={t(ETLCodes.TotalNbHeuresDiff)}></StyledError>
            <StyledError show={tjError} message={t(ETLCodes.TotalTjDiff)}></StyledError>
            <StyledError show={examenError} message={t(ETLCodes.TotalExamenDiff)}></StyledError>
          </>
        )}
      </FGCustomPanel>
      <ButtonsBloc style={{ display: "flex" }}>
        <CancelButton
          minimal={false}
          onClick={() => history.push(`${ERoutes.classe}/${id}/${tab}/${state}`)}
        ></CancelButton>
        <FlexBox></FlexBox>
        <AddButton
          disabled={currentData?.length >= 9}
          text={t(ETLCodes.AddSection)}
          minimal={false}
          intent={Intent.NONE}
          onClick={onAddSection}
        ></AddButton>
        <SaveButton
          disabled={nbHeureError || tjError || examenError}
          minimal={false}
          onClick={onSave}
          loading={saving}
        ></SaveButton>
      </ButtonsBloc>
    </>
  );
};
