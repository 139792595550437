/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbFormationGridDto
 */
export interface FcbFormationGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbFormationGridDto
     */
    codeFormation?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormationGridDto
     */
    idformation?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormationGridDto
     */
    idstatutStage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormationGridDto
     */
    idstade?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormationGridDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormationGridDto
     */
    idmetier?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormationGridDto
     */
    idtypeEnseignement?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormationGridDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormationGridDto
     */
    idstatutSocial?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormationGridDto
     */
    dateForem?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormationGridDto
     */
    dureeInoccupation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormationGridDto
     */
    resultat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormationGridDto
     */
    contrat?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormationGridDto
     */
    debutContrat?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormationGridDto
     */
    finContrat?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormationGridDto
     */
    dateRupture?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormationGridDto
     */
    etatContrat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormationGridDto
     */
    diplomeUtile?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFormationGridDto
     */
    diplome?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormationGridDto
     */
    resultatApp?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormationGridDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormationGridDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormationGridDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormationGridDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormationGridDto
     */
    modificationContratDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormationGridDto
     */
    modificationContratUser?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormationGridDto
     */
    nomDelegue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormationGridDto
     */
    prenomDelegue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormationGridDto
     */
    nomSiege?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormationGridDto
     */
    nomPatron?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormationGridDto
     */
    prenomPatron?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormationGridDto
     */
    adresseLieu?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormationGridDto
     */
    cpLieu?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormationGridDto
     */
    localiteLieu?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormationGridDto
     */
    telephoneLieu?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormationGridDto
     */
    faxLieu?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFormationGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbFormationGridDto interface.
 */
export function instanceOfFcbFormationGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbFormationGridDtoFromJSON(json: any): FcbFormationGridDto {
    return FcbFormationGridDtoFromJSONTyped(json, false);
}

export function FcbFormationGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbFormationGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'codeFormation': !exists(json, 'codeFormation') ? undefined : json['codeFormation'],
        'idformation': !exists(json, 'idformation') ? undefined : json['idformation'],
        'idstatutStage': !exists(json, 'idstatutStage') ? undefined : json['idstatutStage'],
        'idstade': !exists(json, 'idstade') ? undefined : json['idstade'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'idtypeEnseignement': !exists(json, 'idtypeEnseignement') ? undefined : json['idtypeEnseignement'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'idstatutSocial': !exists(json, 'idstatutSocial') ? undefined : json['idstatutSocial'],
        'dateForem': !exists(json, 'dateForem') ? undefined : (json['dateForem'] === null ? null : new Date(json['dateForem'])),
        'dureeInoccupation': !exists(json, 'dureeInoccupation') ? undefined : json['dureeInoccupation'],
        'resultat': !exists(json, 'resultat') ? undefined : json['resultat'],
        'contrat': !exists(json, 'contrat') ? undefined : json['contrat'],
        'debutContrat': !exists(json, 'debutContrat') ? undefined : (json['debutContrat'] === null ? null : new Date(json['debutContrat'])),
        'finContrat': !exists(json, 'finContrat') ? undefined : (json['finContrat'] === null ? null : new Date(json['finContrat'])),
        'dateRupture': !exists(json, 'dateRupture') ? undefined : (json['dateRupture'] === null ? null : new Date(json['dateRupture'])),
        'etatContrat': !exists(json, 'etatContrat') ? undefined : json['etatContrat'],
        'diplomeUtile': !exists(json, 'diplomeUtile') ? undefined : json['diplomeUtile'],
        'diplome': !exists(json, 'diplome') ? undefined : json['diplome'],
        'resultatApp': !exists(json, 'resultatApp') ? undefined : json['resultatApp'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'modificationContratDate': !exists(json, 'modificationContratDate') ? undefined : (json['modificationContratDate'] === null ? null : new Date(json['modificationContratDate'])),
        'modificationContratUser': !exists(json, 'modificationContratUser') ? undefined : json['modificationContratUser'],
        'nomDelegue': !exists(json, 'nomDelegue') ? undefined : json['nomDelegue'],
        'prenomDelegue': !exists(json, 'prenomDelegue') ? undefined : json['prenomDelegue'],
        'nomSiege': !exists(json, 'nomSiege') ? undefined : json['nomSiege'],
        'nomPatron': !exists(json, 'nomPatron') ? undefined : json['nomPatron'],
        'prenomPatron': !exists(json, 'prenomPatron') ? undefined : json['prenomPatron'],
        'adresseLieu': !exists(json, 'adresseLieu') ? undefined : json['adresseLieu'],
        'cpLieu': !exists(json, 'cpLieu') ? undefined : json['cpLieu'],
        'localiteLieu': !exists(json, 'localiteLieu') ? undefined : json['localiteLieu'],
        'telephoneLieu': !exists(json, 'telephoneLieu') ? undefined : json['telephoneLieu'],
        'faxLieu': !exists(json, 'faxLieu') ? undefined : json['faxLieu'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbFormationGridDtoToJSON(value?: FcbFormationGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'codeFormation': value.codeFormation,
        'idformation': value.idformation,
        'idstatutStage': value.idstatutStage,
        'idstade': value.idstade,
        'idapprenant': value.idapprenant,
        'idmetier': value.idmetier,
        'idtypeEnseignement': value.idtypeEnseignement,
        'remarque': value.remarque,
        'idstatutSocial': value.idstatutSocial,
        'dateForem': value.dateForem === undefined ? undefined : (value.dateForem === null ? null : value.dateForem.toISOString()),
        'dureeInoccupation': value.dureeInoccupation,
        'resultat': value.resultat,
        'contrat': value.contrat,
        'debutContrat': value.debutContrat === undefined ? undefined : (value.debutContrat === null ? null : value.debutContrat.toISOString()),
        'finContrat': value.finContrat === undefined ? undefined : (value.finContrat === null ? null : value.finContrat.toISOString()),
        'dateRupture': value.dateRupture === undefined ? undefined : (value.dateRupture === null ? null : value.dateRupture.toISOString()),
        'etatContrat': value.etatContrat,
        'diplomeUtile': value.diplomeUtile,
        'diplome': value.diplome,
        'resultatApp': value.resultatApp,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'modificationContratDate': value.modificationContratDate === undefined ? undefined : (value.modificationContratDate === null ? null : value.modificationContratDate.toISOString()),
        'modificationContratUser': value.modificationContratUser,
        'nomDelegue': value.nomDelegue,
        'prenomDelegue': value.prenomDelegue,
        'nomSiege': value.nomSiege,
        'nomPatron': value.nomPatron,
        'prenomPatron': value.prenomPatron,
        'adresseLieu': value.adresseLieu,
        'cpLieu': value.cpLieu,
        'localiteLieu': value.localiteLieu,
        'telephoneLieu': value.telephoneLieu,
        'faxLieu': value.faxLieu,
        'actif': value.actif,
    };
}

