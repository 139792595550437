import * as React from "react";
import { useQuery } from "react-query";
import { DoublonEditDto, DoublonsApi } from "../../../../api";
import { useApiService, useManageError } from "../../../../hooks";
import set from "lodash/set";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import { format, isDate } from "date-fns";
import mapValues from "lodash/mapValues";
import { AP_FINAL_KEY, CE_FINAL_KEY, CO_FINAL_KEY, FO_FINAL_KEY, RE_FINAL_KEY, TU_FINAL_KEY } from ".";

interface IDoublonsStateContext {
  data: DoublonEditDto;
  setData: (values: DoublonEditDto) => void;
  getFieldData: (path: string) => any;
  setFieldData: (path: string, value: any) => void;
  isDataSelected: (path: string, currentId: number) => boolean;
  getSelectedValue: (path: string, finalKey: string, valuePath?: string) => any;
  getSelectedIndex: (finalPath: string) => number;
  selectData: (path: string, currentIndex: number) => void;
  selectDataMultiple: (finalPath: string, item: any, subFieldKey: string, subFieldDefaultValue?: any) => void;
  isDataMultipleSelected: (finalPath: string, item: any, subFieldKey?: string) => boolean;
  loading: boolean;
  buildFinals: () => DoublonEditDto;
}
const DoublonsStateContext = React.createContext<IDoublonsStateContext>(null);

interface IDoublonsStateProviderProps {
  idpersonne1: number;
  idpersonne2: number;
}

const mapValuesIterateeSelectionSchema = (d, val) => {
  if (d === null || d === undefined) return val;

  if (typeof d === "object" && !isDate(d)) return mapValues(d, di => mapValuesIterateeSelectionSchema(di, val));

  if (Array.isArray(d)) return [];

  return val;
};

export const DoublonsStateProvider: React.FunctionComponent<IDoublonsStateProviderProps> = ({
  children,
  idpersonne1,
  idpersonne2
}) => {
  const api = useApiService(DoublonsApi);
  const { manageError } = useManageError();
  const fetchDoublon = React.useCallback(() => {
    return api.doublonsGetDoublon({ idpersonne1, idpersonne2 });
  }, [api, idpersonne1, idpersonne2]);
  const { data, isFetching, isFetched, error } = useQuery(["doublon-personne", idpersonne1, idpersonne2], fetchDoublon);

  React.useEffect(() => {
    if (error) {
      manageError(error);
    }
  }, [error, manageError]);

  const [formData, setFormData] = React.useState<DoublonEditDto>(null);
  React.useEffect(() => {
    if (!!data && isFetched) {
      setFormData(data);
    }
  }, [data, isFetched]);

  const setFieldData = React.useCallback(
    (path: string, value: any) => {
      let newData = { ...formData };
      set(newData, path, value);
      setFormData(newData);
    },
    [formData]
  );

  const getFieldData = React.useCallback(
    (path: string) => {
      var value = get(formData, path);
      return isDate(value) ? format(value, "dd-MM-yyyy") : value;
    },
    [formData]
  );

  const [selectionSchema, setSelectionSchema] = React.useState(null);
  React.useEffect(() => {
    if (!!formData && isFetched && !selectionSchema) {
      const initialSchema = {
        apprenantFinal:
          formData.apprenantFinal && mapValues(formData.apprenantFinal, d => mapValuesIterateeSelectionSchema(d, 0)),
        chefEntrepriseFinal:
          formData.chefEntrepriseFinal &&
          mapValues(formData.chefEntrepriseFinal, d => mapValuesIterateeSelectionSchema(d, 0)),
        contactFinal:
          formData.contactFinal && mapValues(formData.contactFinal, d => mapValuesIterateeSelectionSchema(d, 0)),
        formateurFinal:
          formData.formateurFinal && mapValues(formData.formateurFinal, d => mapValuesIterateeSelectionSchema(d, 0)),
        representantFinal:
          formData.representantFinal &&
          mapValues(formData.representantFinal, d => mapValuesIterateeSelectionSchema(d, 0)),
        tuteurFinal:
          formData.tuteurFinal && mapValues(formData.tuteurFinal, d => mapValuesIterateeSelectionSchema(d, 0)),
        personneFinal:
          formData.personneFinal && mapValues(formData.personneFinal, d => mapValuesIterateeSelectionSchema(d, 0))
      };
      setSelectionSchema(initialSchema);
    }
  }, [formData, isFetched, selectionSchema]);

  const getSelectedIndex = React.useCallback(
    (finalPath: string) => {
      let selectedId = get(selectionSchema, finalPath);
      if (selectedId === undefined) {
        const parentPath = finalPath.split(".");
        parentPath.splice(parentPath.length - 1, 1);
        selectedId = get(selectionSchema, parentPath.join("."));
      }
      return selectedId;
    },
    [selectionSchema]
  );

  const isDataSelected = React.useCallback(
    (path: string, index: number) => {
      const selectedId = getSelectedIndex(path);
      return selectedId === index;
    },
    [getSelectedIndex]
  );

  const selectData = React.useCallback(
    (path: string, index: number) => {
      const selectedId = get(selectionSchema, path);
      if (selectedId !== index) {
        const newSchema = { ...selectionSchema };
        set(newSchema, path, index);
        setSelectionSchema(newSchema);
      }
    },
    [selectionSchema]
  );

  const mapValuesIterateeFinals = React.useCallback((d: any, entities: any[], selected: any, path: string) => {
    if (Array.isArray(d)) return d;

    if (typeof d === "object" && !isDate(d))
      return mapValues(d, (di, key) => mapValuesIterateeFinals(di, entities, selected[key], `${path}.${key}`));

    const selectedIndex = typeof selected === "object" ? +selected[Object.keys(selected)[0]] : +selected;
    const entity = entities[selectedIndex];
    return get(entity, path);
  }, []);
  const getSelectedValue = React.useCallback(
    (path: string, finalKey: string, valuePath?: string) => {
      if (!formData) return null;
      const selectedId = get(selectionSchema, path);
      let entities;
      switch (finalKey) {
        case AP_FINAL_KEY:
          entities = formData.apprenants;
          break;
        case CE_FINAL_KEY:
          entities = formData.chefEntreprises;
          break;
        case CO_FINAL_KEY:
          entities = formData.contacts;
          break;
        case FO_FINAL_KEY:
          entities = formData.formateurs;
          break;
        case RE_FINAL_KEY:
          entities = formData.representants;
          break;
        case TU_FINAL_KEY:
          entities = formData.tuteurs;
          break;
        default:
          entities = formData.personnes;
          break;
      }
      const entity = entities[selectedId];
      const value = get(entity, (valuePath ?? path).split(`${finalKey}.`)[1]);
      return isDate(value) ? format(value, "dd-MM-yyyy") : value;
    },
    [formData, selectionSchema]
  );

  const buildFinals = React.useCallback(() => {
    let newData = {
      ...formData,
      apprenantFinal:
        formData.apprenantFinal &&
        mapValues(formData.apprenantFinal, (d, key) =>
          mapValuesIterateeFinals(d, formData.apprenants, selectionSchema?.apprenantFinal[key], key)
        ),
      chefEntrepriseFinal:
        formData.chefEntrepriseFinal &&
        mapValues(formData.chefEntrepriseFinal, (d, key) =>
          mapValuesIterateeFinals(d, formData.chefEntreprises, selectionSchema?.chefEntrepriseFinal[key], key)
        ),
      contactFinal:
        formData.contactFinal &&
        mapValues(formData.contactFinal, (d, key) =>
          mapValuesIterateeFinals(d, formData.contacts, selectionSchema?.contactFinal[key], key)
        ),
      formateurFinal:
        formData.formateurFinal &&
        mapValues(formData.formateurFinal, (d, key) =>
          mapValuesIterateeFinals(d, formData.formateurs, selectionSchema?.formateurFinal[key], key)
        ),
      representantFinal:
        formData.representantFinal &&
        mapValues(formData.representantFinal, (d, key) =>
          mapValuesIterateeFinals(d, formData.representants, selectionSchema?.representantFinal[key], key)
        ),
      tuteurFinal:
        formData.tuteurFinal &&
        mapValues(formData.tuteurFinal, (d, key) =>
          mapValuesIterateeFinals(d, formData.tuteurs, selectionSchema?.tuteurFinal[key], key)
        ),
      personneFinal:
        formData.personneFinal &&
        mapValues(formData.personneFinal, (d, key) =>
          mapValuesIterateeFinals(d, formData.personnes, selectionSchema?.personneFinal[key], key)
        )
    };

    const selectedTels = [
      getSelectedValue(`${AP_FINAL_KEY}.telephoneApprenant.idtelephone`, AP_FINAL_KEY),
      getSelectedValue(`${AP_FINAL_KEY}.gsmApprenant.idtelephone`, AP_FINAL_KEY),
      getSelectedValue(`${RE_FINAL_KEY}.telephoneRepresentant.idtelephone`, RE_FINAL_KEY),
      getSelectedValue(`${RE_FINAL_KEY}.gsmRepresentant.idtelephone`, RE_FINAL_KEY),
      getSelectedValue(`${FO_FINAL_KEY}.telephoneFormateur.idtelephone`, FO_FINAL_KEY),
      getSelectedValue(`${FO_FINAL_KEY}.donneesProfessionnelles.telephoneProfFormateur.idtelephone`, FO_FINAL_KEY),
      getSelectedValue(`${FO_FINAL_KEY}.gsmFormateur.idtelephone`, FO_FINAL_KEY),
      getSelectedValue(`${FO_FINAL_KEY}.donneesProfessionnelles.gsmProfFormateur.idtelephone`, FO_FINAL_KEY)
    ];

    selectedTels.forEach(id => {
      if (!id || newData.personneFinal.telephones.some(t => t.idtelephone === id)) return;

      let tel =
        newData.apprenants.find(a => a.telephoneApprenant?.idtelephone === id)?.telephoneApprenant ??
        newData.apprenants.find(a => a.gsmApprenant?.idtelephone === id)?.gsmApprenant ??
        newData.representants.find(a => a.telephoneRepresentant?.idtelephone === id)?.telephoneRepresentant ??
        newData.representants.find(a => a.gsmRepresentant?.idtelephone === id)?.gsmRepresentant ??
        newData.formateurs.find(a => a.telephoneFormateur?.idtelephone === id)?.telephoneFormateur ??
        newData.formateurs.find(a => a.donneesProfessionnelles.telephoneProfFormateur?.idtelephone === id)
          ?.donneesProfessionnelles?.telephoneProfFormateur ??
        newData.formateurs.find(a => a.gsmFormateur?.idtelephone === id)?.gsmFormateur ??
        newData.formateurs.find(a => a.donneesProfessionnelles.gsmProfFormateur?.idtelephone === id)
          ?.donneesProfessionnelles?.gsmProfFormateur;
      newData.personneFinal.telephones.push(tel);
    });

    const telsToAdd = [
      ...(data?.chefEntreprises?.flatMap(c => c.siegesSociaux?.map(s => s.telephone)) ?? []),
      ...(data?.chefEntreprises?.flatMap(c => c.siegesSociaux?.map(s => s.gsm)) ?? []),
      ...(data?.contacts?.flatMap(c => c.siegesSociaux?.map(s => s.telephone)) ?? []),
      ...(data?.contacts?.flatMap(c => c.siegesSociaux?.map(s => s.gsm)) ?? []),
      ...(data?.contacts?.flatMap(c => c.lieuxFormation?.map(s => s.telephone)) ?? []),
      ...(data?.contacts?.flatMap(c => c.lieuxFormation?.map(s => s.gsm)) ?? [])
    ];
    newData.personneFinal.telephones.push(...telsToAdd);

    const selectedEmails = [
      getSelectedValue(`${AP_FINAL_KEY}.emailApprenant.idemail`, AP_FINAL_KEY),
      getSelectedValue(`${RE_FINAL_KEY}.emailRepresentant.idemail`, RE_FINAL_KEY),
      getSelectedValue(`${FO_FINAL_KEY}.emailFormateur.idemail`, FO_FINAL_KEY),
      getSelectedValue(`${FO_FINAL_KEY}.donneesProfessionnelles.emailProfFormateur.idemail`, FO_FINAL_KEY)
    ];

    selectedEmails.forEach(id => {
      if (!id || newData.personneFinal.emails.some(t => t.idemail === id)) return;

      const email =
        newData.apprenants.find(a => a.emailApprenant?.idemail === id)?.emailApprenant ??
        newData.representants.find(a => a.emailRepresentant?.idemail === id)?.emailRepresentant ??
        newData.formateurs.find(a => a.emailFormateur?.idemail === id)?.emailFormateur ??
        newData.formateurs.find(a => a.donneesProfessionnelles?.emailProfFormateur?.idemail === id)
          ?.donneesProfessionnelles?.emailProfFormateur;
      newData.personneFinal.emails.push(email);
    });

    const emailsToAdd = [
      ...(data?.chefEntreprises?.flatMap(c => c.siegesSociaux?.map(s => s.email)) ?? []),
      ...(data?.contacts?.flatMap(c => c.siegesSociaux?.map(s => s.email)) ?? []),
      ...(data?.contacts?.flatMap(c => c.lieuxFormation?.map(s => s.email)) ?? [])
    ];
    newData.personneFinal.emails.push(...emailsToAdd);

    return newData;
  }, [
    data?.chefEntreprises,
    data?.contacts,
    formData,
    getSelectedValue,
    mapValuesIterateeFinals,
    selectionSchema?.apprenantFinal,
    selectionSchema?.chefEntrepriseFinal,
    selectionSchema?.contactFinal,
    selectionSchema?.formateurFinal,
    selectionSchema?.personneFinal,
    selectionSchema?.representantFinal,
    selectionSchema?.tuteurFinal
  ]);

  const isDataMultipleSelected = React.useCallback(
    (finalPath: string, item: any) => {
      const finalItems: any[] = get(formData, finalPath) ?? [];
      return finalItems.some(i => isEqual(i, item));
    },
    [formData]
  );

  const selectDataMultiple = React.useCallback(
    (finalPath: string, item: any, subFieldKey?: string, subFieldDefaultValue?: any) => {
      const finalItems: any[] = get(formData, finalPath) ?? [];
      let newItems = [...finalItems];
      if (subFieldDefaultValue !== undefined) {
        newItems = newItems.map(i => ({ ...i, [subFieldKey]: subFieldDefaultValue }));
      }
      if (finalItems.every(i => !isEqual(omit(i, [subFieldKey]), omit(item, [subFieldKey])))) {
        newItems.push(item);
        setFieldData(finalPath, newItems);
      } else {
        const foundIndex = finalItems.findIndex(i => isEqual(omit(i, [subFieldKey]), omit(item, [subFieldKey])));
        if (!!subFieldKey) {
          newItems[foundIndex][subFieldKey] = item[subFieldKey];
        } else {
          newItems.splice(foundIndex, 1);
        }
        setFieldData(finalPath, newItems);
      }
    },
    [formData, setFieldData]
  );

  return (
    <DoublonsStateContext.Provider
      value={{
        data: formData,
        setData: setFormData,
        setFieldData,
        getFieldData,
        loading: isFetching,
        isDataSelected,
        getSelectedValue,
        getSelectedIndex,
        selectData,
        selectDataMultiple,
        isDataMultipleSelected,
        buildFinals
      }}
    >
      {children}
    </DoublonsStateContext.Provider>
  );
};

export const useDoublonsStateContext = () => React.useContext(DoublonsStateContext);
