/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompteBancaireDto
 */
export interface CompteBancaireDto {
    /**
     * 
     * @type {number}
     * @memberof CompteBancaireDto
     */
    idcompteBancaire?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompteBancaireDto
     */
    idpersonne?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompteBancaireDto
     */
    iban?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompteBancaireDto
     */
    bic?: string | null;
}

/**
 * Check if a given object implements the CompteBancaireDto interface.
 */
export function instanceOfCompteBancaireDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CompteBancaireDtoFromJSON(json: any): CompteBancaireDto {
    return CompteBancaireDtoFromJSONTyped(json, false);
}

export function CompteBancaireDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompteBancaireDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcompteBancaire': !exists(json, 'idcompteBancaire') ? undefined : json['idcompteBancaire'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'iban': !exists(json, 'iban') ? undefined : json['iban'],
        'bic': !exists(json, 'bic') ? undefined : json['bic'],
    };
}

export function CompteBancaireDtoToJSON(value?: CompteBancaireDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcompteBancaire': value.idcompteBancaire,
        'idpersonne': value.idpersonne,
        'iban': value.iban,
        'bic': value.bic,
    };
}

