/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbConseillerPedagogiqueMetiersLiesGridDto
 */
export interface FcbConseillerPedagogiqueMetiersLiesGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbConseillerPedagogiqueMetiersLiesGridDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbConseillerPedagogiqueMetiersLiesGridDto
     */
    stade?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbConseillerPedagogiqueMetiersLiesGridDto
     */
    metier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbConseillerPedagogiqueMetiersLiesGridDto
     */
    idmetier?: string | null;
}

/**
 * Check if a given object implements the FcbConseillerPedagogiqueMetiersLiesGridDto interface.
 */
export function instanceOfFcbConseillerPedagogiqueMetiersLiesGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbConseillerPedagogiqueMetiersLiesGridDtoFromJSON(json: any): FcbConseillerPedagogiqueMetiersLiesGridDto {
    return FcbConseillerPedagogiqueMetiersLiesGridDtoFromJSONTyped(json, false);
}

export function FcbConseillerPedagogiqueMetiersLiesGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbConseillerPedagogiqueMetiersLiesGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'stade': !exists(json, 'stade') ? undefined : json['stade'],
        'metier': !exists(json, 'metier') ? undefined : json['metier'],
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
    };
}

export function FcbConseillerPedagogiqueMetiersLiesGridDtoToJSON(value?: FcbConseillerPedagogiqueMetiersLiesGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'stade': value.stade,
        'metier': value.metier,
        'idmetier': value.idmetier,
    };
}

