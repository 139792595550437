import { Button, ButtonProps, Intent } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";

interface IConfirmableIconButtonProps extends ButtonProps {
  onHoverMode?: boolean;
}

const ButtonContainer = styled.div`
  & > .no-padding {
    padding: 0px !important;
  }

  & *:focus {
    outline: none !important;
  }
`;

export const ConfirmableIconButton: React.FunctionComponent<IConfirmableIconButtonProps> = ({
  minimal = true,
  onClick,
  disabled,
  loading,
  intent = Intent.NONE,
  icon,
  className
}) => {
  const [toConfirm, setToConfirm] = React.useState(false);

  return (
    <ButtonContainer className={className}>
      {toConfirm && (
        <>
          <Button
            className="no-padding"
            small
            icon="tick"
            minimal={true}
            onClick={e => {
              e.stopPropagation();
              setToConfirm(false);
              if (!!onClick) onClick(e);
            }}
            intent="success"
          />
          <Button
            className="no-padding"
            small
            icon="cross"
            minimal={true}
            onClick={e => {
              e.stopPropagation();
              setToConfirm(false);
            }}
            intent="danger"
          />
        </>
      )}
      <Button
        className="no-padding"
        small
        icon={icon}
        minimal={minimal}
        onClick={e => {
          e.stopPropagation();
          setToConfirm(true);
        }}
        intent={intent}
        loading={loading}
        disabled={disabled}
      />
    </ButtonContainer>
  );
};
