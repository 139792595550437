/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdresseDto } from './AdresseDto';
import {
    AdresseDtoFromJSON,
    AdresseDtoFromJSONTyped,
    AdresseDtoToJSON,
} from './AdresseDto';
import type { EmailDto } from './EmailDto';
import {
    EmailDtoFromJSON,
    EmailDtoFromJSONTyped,
    EmailDtoToJSON,
} from './EmailDto';
import type { TelephoneDto } from './TelephoneDto';
import {
    TelephoneDtoFromJSON,
    TelephoneDtoFromJSONTyped,
    TelephoneDtoToJSON,
} from './TelephoneDto';

/**
 * 
 * @export
 * @interface FcbRepresentantDto
 */
export interface FcbRepresentantDto {
    /**
     * 
     * @type {number}
     * @memberof FcbRepresentantDto
     */
    idrepresentant?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantDto
     */
    nationalite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantDto
     */
    numeroNational?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantDto
     */
    numeroIdentification?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantDto
     */
    adresseNaissance?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRepresentantDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof FcbRepresentantDto
     */
    telephoneRepresentant?: TelephoneDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof FcbRepresentantDto
     */
    telephoneProRepresentant?: TelephoneDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof FcbRepresentantDto
     */
    gsmRepresentant?: TelephoneDto;
    /**
     * 
     * @type {EmailDto}
     * @memberof FcbRepresentantDto
     */
    emailRepresentant?: EmailDto;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantDto
     */
    codesexe?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRepresentantDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbRepresentantDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRepresentantDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRepresentantDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantDto
     */
    idrepresentantGuid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantDto
     */
    searchNom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantDto
     */
    searchPrenom?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRepresentantDto
     */
    idpersonne?: number | null;
    /**
     * 
     * @type {AdresseDto}
     * @memberof FcbRepresentantDto
     */
    adresseRepresentant?: AdresseDto;
    /**
     * 
     * @type {boolean}
     * @memberof FcbRepresentantDto
     */
    hasAddressDoublons?: boolean;
}

/**
 * Check if a given object implements the FcbRepresentantDto interface.
 */
export function instanceOfFcbRepresentantDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRepresentantDtoFromJSON(json: any): FcbRepresentantDto {
    return FcbRepresentantDtoFromJSONTyped(json, false);
}

export function FcbRepresentantDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRepresentantDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idrepresentant': !exists(json, 'idrepresentant') ? undefined : json['idrepresentant'],
        'nationalite': !exists(json, 'nationalite') ? undefined : json['nationalite'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'numeroNational': !exists(json, 'numeroNational') ? undefined : json['numeroNational'],
        'numeroIdentification': !exists(json, 'numeroIdentification') ? undefined : json['numeroIdentification'],
        'adresseNaissance': !exists(json, 'adresseNaissance') ? undefined : json['adresseNaissance'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'telephoneRepresentant': !exists(json, 'telephoneRepresentant') ? undefined : TelephoneDtoFromJSON(json['telephoneRepresentant']),
        'telephoneProRepresentant': !exists(json, 'telephoneProRepresentant') ? undefined : TelephoneDtoFromJSON(json['telephoneProRepresentant']),
        'gsmRepresentant': !exists(json, 'gsmRepresentant') ? undefined : TelephoneDtoFromJSON(json['gsmRepresentant']),
        'emailRepresentant': !exists(json, 'emailRepresentant') ? undefined : EmailDtoFromJSON(json['emailRepresentant']),
        'codesexe': !exists(json, 'codesexe') ? undefined : json['codesexe'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'idrepresentantGuid': !exists(json, 'idrepresentantGuid') ? undefined : json['idrepresentantGuid'],
        'searchNom': !exists(json, 'searchNom') ? undefined : json['searchNom'],
        'searchPrenom': !exists(json, 'searchPrenom') ? undefined : json['searchPrenom'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'adresseRepresentant': !exists(json, 'adresseRepresentant') ? undefined : AdresseDtoFromJSON(json['adresseRepresentant']),
        'hasAddressDoublons': !exists(json, 'hasAddressDoublons') ? undefined : json['hasAddressDoublons'],
    };
}

export function FcbRepresentantDtoToJSON(value?: FcbRepresentantDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idrepresentant': value.idrepresentant,
        'nationalite': value.nationalite,
        'nom': value.nom,
        'prenom': value.prenom,
        'numeroNational': value.numeroNational,
        'numeroIdentification': value.numeroIdentification,
        'adresseNaissance': value.adresseNaissance,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'telephoneRepresentant': TelephoneDtoToJSON(value.telephoneRepresentant),
        'telephoneProRepresentant': TelephoneDtoToJSON(value.telephoneProRepresentant),
        'gsmRepresentant': TelephoneDtoToJSON(value.gsmRepresentant),
        'emailRepresentant': EmailDtoToJSON(value.emailRepresentant),
        'codesexe': value.codesexe,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'idrepresentantGuid': value.idrepresentantGuid,
        'searchNom': value.searchNom,
        'searchPrenom': value.searchPrenom,
        'idpersonne': value.idpersonne,
        'adresseRepresentant': AdresseDtoToJSON(value.adresseRepresentant),
        'hasAddressDoublons': value.hasAddressDoublons,
    };
}

