/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRechercheExportGapPrestationDto
 */
export interface FcbRechercheExportGapPrestationDto {
    /**
     * 
     * @type {Date}
     * @memberof FcbRechercheExportGapPrestationDto
     */
    jour?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheExportGapPrestationDto
     */
    codeClasse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheExportGapPrestationDto
     */
    classe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheExportGapPrestationDto
     */
    formateur?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheExportGapPrestationDto
     */
    matricule?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheExportGapPrestationDto
     */
    ancienMatricule?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheExportGapPrestationDto
     */
    count?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheExportGapPrestationDto
     */
    idformateur?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheExportGapPrestationDto
     */
    idexport?: number | null;
}

/**
 * Check if a given object implements the FcbRechercheExportGapPrestationDto interface.
 */
export function instanceOfFcbRechercheExportGapPrestationDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRechercheExportGapPrestationDtoFromJSON(json: any): FcbRechercheExportGapPrestationDto {
    return FcbRechercheExportGapPrestationDtoFromJSONTyped(json, false);
}

export function FcbRechercheExportGapPrestationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRechercheExportGapPrestationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jour': !exists(json, 'jour') ? undefined : (json['jour'] === null ? null : new Date(json['jour'])),
        'codeClasse': !exists(json, 'codeClasse') ? undefined : json['codeClasse'],
        'classe': !exists(json, 'classe') ? undefined : json['classe'],
        'formateur': !exists(json, 'formateur') ? undefined : json['formateur'],
        'matricule': !exists(json, 'matricule') ? undefined : json['matricule'],
        'ancienMatricule': !exists(json, 'ancienMatricule') ? undefined : json['ancienMatricule'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'idexport': !exists(json, 'idexport') ? undefined : json['idexport'],
    };
}

export function FcbRechercheExportGapPrestationDtoToJSON(value?: FcbRechercheExportGapPrestationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jour': value.jour === undefined ? undefined : (value.jour === null ? null : value.jour.toISOString()),
        'codeClasse': value.codeClasse,
        'classe': value.classe,
        'formateur': value.formateur,
        'matricule': value.matricule,
        'ancienMatricule': value.ancienMatricule,
        'count': value.count,
        'idformateur': value.idformateur,
        'idexport': value.idexport,
    };
}

