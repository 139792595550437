/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChefEntrepriseEditDto,
  ChefEntrepriseGridDtoPaginatedResults,
  ChefEntrepriseSearchDto,
  ChefEntrepriseSiegeSocialGridDtoPaginatedResults,
  ChefEntrepriseSiegeSocialSearch,
  ChefsEntrepriseDoublonGridDtoPaginatedResults,
  ChefsEntrepriseDoublonSearchDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
} from '../models/index';
import {
    ChefEntrepriseEditDtoFromJSON,
    ChefEntrepriseEditDtoToJSON,
    ChefEntrepriseGridDtoPaginatedResultsFromJSON,
    ChefEntrepriseGridDtoPaginatedResultsToJSON,
    ChefEntrepriseSearchDtoFromJSON,
    ChefEntrepriseSearchDtoToJSON,
    ChefEntrepriseSiegeSocialGridDtoPaginatedResultsFromJSON,
    ChefEntrepriseSiegeSocialGridDtoPaginatedResultsToJSON,
    ChefEntrepriseSiegeSocialSearchFromJSON,
    ChefEntrepriseSiegeSocialSearchToJSON,
    ChefsEntrepriseDoublonGridDtoPaginatedResultsFromJSON,
    ChefsEntrepriseDoublonGridDtoPaginatedResultsToJSON,
    ChefsEntrepriseDoublonSearchDtoFromJSON,
    ChefsEntrepriseDoublonSearchDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface ChefEntrepriseBaseSearchRequest {
    ChefEntrepriseSearchDto?: ChefEntrepriseSearchDto;
}

export interface ChefEntrepriseGetChefEntrepriseRequest {
    id?: number;
}

export interface ChefEntrepriseGetDisplayNameRequest {
    id?: number;
}

export interface ChefEntrepriseGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ChefEntrepriseGetSelectItemsRequest {
    searchField: string;
}

export interface ChefEntrepriseSaveChefEntrepriseRequest {
    ChefEntrepriseEditDto?: ChefEntrepriseEditDto;
}

export interface ChefEntrepriseSearchChefEntrepriseIdsRequest {
    ChefEntrepriseSearchDto?: ChefEntrepriseSearchDto;
}

export interface ChefEntrepriseSearchChefEntrepriseSiegeSocialRequest {
    ChefEntrepriseSiegeSocialSearch?: ChefEntrepriseSiegeSocialSearch;
}

export interface ChefEntrepriseSearchDoublonsChefsEntrepriseRequest {
    ChefsEntrepriseDoublonSearchDto?: ChefsEntrepriseDoublonSearchDto;
}

/**
 * 
 */
export class ChefEntrepriseApi extends runtime.BaseAPI {

    /**
     */
    async chefEntrepriseBaseSearchRaw(requestParameters: ChefEntrepriseBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChefEntrepriseGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ChefEntreprise/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChefEntrepriseSearchDtoToJSON(requestParameters.ChefEntrepriseSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChefEntrepriseGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async chefEntrepriseBaseSearch(requestParameters: ChefEntrepriseBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChefEntrepriseGridDtoPaginatedResults> {
        const response = await this.chefEntrepriseBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async chefEntrepriseGetChefEntrepriseRaw(requestParameters: ChefEntrepriseGetChefEntrepriseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChefEntrepriseEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ChefEntreprise`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChefEntrepriseEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async chefEntrepriseGetChefEntreprise(requestParameters: ChefEntrepriseGetChefEntrepriseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChefEntrepriseEditDto> {
        const response = await this.chefEntrepriseGetChefEntrepriseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async chefEntrepriseGetDisplayNameRaw(requestParameters: ChefEntrepriseGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ChefEntreprise/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async chefEntrepriseGetDisplayName(requestParameters: ChefEntrepriseGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.chefEntrepriseGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async chefEntrepriseGetSearchCriteriasRaw(requestParameters: ChefEntrepriseGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ChefEntreprise/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async chefEntrepriseGetSearchCriterias(requestParameters: ChefEntrepriseGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.chefEntrepriseGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async chefEntrepriseGetSelectItemsRaw(requestParameters: ChefEntrepriseGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling chefEntrepriseGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ChefEntreprise/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async chefEntrepriseGetSelectItems(requestParameters: ChefEntrepriseGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.chefEntrepriseGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async chefEntrepriseSaveChefEntrepriseRaw(requestParameters: ChefEntrepriseSaveChefEntrepriseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChefEntrepriseEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ChefEntreprise`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChefEntrepriseEditDtoToJSON(requestParameters.ChefEntrepriseEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChefEntrepriseEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async chefEntrepriseSaveChefEntreprise(requestParameters: ChefEntrepriseSaveChefEntrepriseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChefEntrepriseEditDto> {
        const response = await this.chefEntrepriseSaveChefEntrepriseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async chefEntrepriseSearchChefEntrepriseIdsRaw(requestParameters: ChefEntrepriseSearchChefEntrepriseIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ChefEntreprise/SearchChefEntrepriseIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChefEntrepriseSearchDtoToJSON(requestParameters.ChefEntrepriseSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async chefEntrepriseSearchChefEntrepriseIds(requestParameters: ChefEntrepriseSearchChefEntrepriseIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.chefEntrepriseSearchChefEntrepriseIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async chefEntrepriseSearchChefEntrepriseSiegeSocialRaw(requestParameters: ChefEntrepriseSearchChefEntrepriseSiegeSocialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChefEntrepriseSiegeSocialGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ChefEntreprise/SearchChefEntrepriseSiegeSocial`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChefEntrepriseSiegeSocialSearchToJSON(requestParameters.ChefEntrepriseSiegeSocialSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChefEntrepriseSiegeSocialGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async chefEntrepriseSearchChefEntrepriseSiegeSocial(requestParameters: ChefEntrepriseSearchChefEntrepriseSiegeSocialRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChefEntrepriseSiegeSocialGridDtoPaginatedResults> {
        const response = await this.chefEntrepriseSearchChefEntrepriseSiegeSocialRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async chefEntrepriseSearchDoublonsChefsEntrepriseRaw(requestParameters: ChefEntrepriseSearchDoublonsChefsEntrepriseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChefsEntrepriseDoublonGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ChefEntreprise/SearchDoublons`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChefsEntrepriseDoublonSearchDtoToJSON(requestParameters.ChefsEntrepriseDoublonSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChefsEntrepriseDoublonGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async chefEntrepriseSearchDoublonsChefsEntreprise(requestParameters: ChefEntrepriseSearchDoublonsChefsEntrepriseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChefsEntrepriseDoublonGridDtoPaginatedResults> {
        const response = await this.chefEntrepriseSearchDoublonsChefsEntrepriseRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
