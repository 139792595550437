/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ENumericSearchType } from './ENumericSearchType';
import {
    ENumericSearchTypeFromJSON,
    ENumericSearchTypeFromJSONTyped,
    ENumericSearchTypeToJSON,
} from './ENumericSearchType';
import type { ENumericSearchTypeInt64NullableCombinableCriteria } from './ENumericSearchTypeInt64NullableCombinableCriteria';
import {
    ENumericSearchTypeInt64NullableCombinableCriteriaFromJSON,
    ENumericSearchTypeInt64NullableCombinableCriteriaFromJSONTyped,
    ENumericSearchTypeInt64NullableCombinableCriteriaToJSON,
} from './ENumericSearchTypeInt64NullableCombinableCriteria';

/**
 * 
 * @export
 * @interface NumericSearch
 */
export interface NumericSearch {
    /**
     * 
     * @type {Array<ENumericSearchTypeInt64NullableCombinableCriteria>}
     * @memberof NumericSearch
     */
    criterias?: Array<ENumericSearchTypeInt64NullableCombinableCriteria> | null;
    /**
     * 
     * @type {ENumericSearchType}
     * @memberof NumericSearch
     */
    searchMode?: ENumericSearchType;
    /**
     * 
     * @type {number}
     * @memberof NumericSearch
     * @deprecated
     */
    value?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NumericSearch
     * @deprecated
     */
    secondaryValue?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof NumericSearch
     */
    readonly availableSearchTypes?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof NumericSearch
     */
    defaultSearchType?: string | null;
}

/**
 * Check if a given object implements the NumericSearch interface.
 */
export function instanceOfNumericSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NumericSearchFromJSON(json: any): NumericSearch {
    return NumericSearchFromJSONTyped(json, false);
}

export function NumericSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): NumericSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'criterias': !exists(json, 'criterias') ? undefined : (json['criterias'] === null ? null : (json['criterias'] as Array<any>).map(ENumericSearchTypeInt64NullableCombinableCriteriaFromJSON)),
        'searchMode': !exists(json, 'searchMode') ? undefined : ENumericSearchTypeFromJSON(json['searchMode']),
        'value': !exists(json, 'value') ? undefined : json['value'],
        'secondaryValue': !exists(json, 'secondaryValue') ? undefined : json['secondaryValue'],
        'availableSearchTypes': !exists(json, 'availableSearchTypes') ? undefined : json['availableSearchTypes'],
        'defaultSearchType': !exists(json, 'defaultSearchType') ? undefined : json['defaultSearchType'],
    };
}

export function NumericSearchToJSON(value?: NumericSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'criterias': value.criterias === undefined ? undefined : (value.criterias === null ? null : (value.criterias as Array<any>).map(ENumericSearchTypeInt64NullableCombinableCriteriaToJSON)),
        'searchMode': ENumericSearchTypeToJSON(value.searchMode),
        'value': value.value,
        'secondaryValue': value.secondaryValue,
        'defaultSearchType': value.defaultSearchType,
    };
}

