/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdresseReferentialResultInfoDto,
  CentreDemandeRequestDto,
  EIfapmeSide,
  ELevelName,
  EPermis,
  ETypeApprenantFichier,
  ETypeContrat,
  ETypeDeliberation,
  GetInscriptionDatesConvocationRequestDto,
  ReferentialResultInfoDto,
} from '../models/index';
import {
    AdresseReferentialResultInfoDtoFromJSON,
    AdresseReferentialResultInfoDtoToJSON,
    CentreDemandeRequestDtoFromJSON,
    CentreDemandeRequestDtoToJSON,
    EIfapmeSideFromJSON,
    EIfapmeSideToJSON,
    ELevelNameFromJSON,
    ELevelNameToJSON,
    EPermisFromJSON,
    EPermisToJSON,
    ETypeApprenantFichierFromJSON,
    ETypeApprenantFichierToJSON,
    ETypeContratFromJSON,
    ETypeContratToJSON,
    ETypeDeliberationFromJSON,
    ETypeDeliberationToJSON,
    GetInscriptionDatesConvocationRequestDtoFromJSON,
    GetInscriptionDatesConvocationRequestDtoToJSON,
    ReferentialResultInfoDtoFromJSON,
    ReferentialResultInfoDtoToJSON,
} from '../models/index';

export interface ReferentialContratsByMatiereRequest {
    idClasseMatiere?: number;
    idFormateur?: number;
}

export interface ReferentialDegreIdsByStadeRequest {
    idStade?: number;
}

export interface ReferentialGetAdressesPersonneRequest {
    idpersonne?: number;
}

export interface ReferentialGetAllTelephonesPersonneRequest {
    idpersonne?: number;
}

export interface ReferentialGetAnneesPrestationsRequest {
    idFormateur?: number;
}

export interface ReferentialGetApprenantContratsRequest {
    idApprenant?: number;
    ETypeContrat?: Array<ETypeContrat>;
}

export interface ReferentialGetAutoecolesRequest {
    permisCode?: EPermis;
}

export interface ReferentialGetCalcsForServiceRequest {
    idService?: number;
}

export interface ReferentialGetCalendriersRequest {
    idClasse?: number;
}

export interface ReferentialGetCentreByApprenantRequest {
    idApprenant?: number;
}

export interface ReferentialGetCentreByUserRequest {
    idCentre?: number;
}

export interface ReferentialGetCentreGlobalUsersRequest {
    currentIduser?: number;
}

export interface ReferentialGetCentreUsersRequest {
    request_body?: Array<number>;
}

export interface ReferentialGetCentresDemandeRequest {
    CentreDemandeRequestDto?: CentreDemandeRequestDto;
}

export interface ReferentialGetClasseRequest {
    idCentre?: number;
    anneeScolaire?: string;
    typeCours?: string;
    idMetier?: number;
}

export interface ReferentialGetClasseApprenantRequest {
    idApprenant?: number;
}

export interface ReferentialGetClasseApprenantCurrentYearRequest {
    idApprenant?: number;
    idCentre?: number;
}

export interface ReferentialGetClasseByInscriptionRequest {
    idInscription?: number;
    isForChangementClasseTable?: boolean;
}

export interface ReferentialGetClasseForContratRequest {
    idapprenant?: number;
}

export interface ReferentialGetClasseForEvaluationRequest {
    idEvaluation?: number;
}

export interface ReferentialGetClasseForInscriptionRequest {
    idCentre?: number;
    anneeScolaire?: string;
    typeCours?: string;
    idApprenant?: number;
    idClasse?: number;
    isARE?: boolean;
    isSB?: boolean;
    specificiteCours?: string;
}

export interface ReferentialGetClasseJoursRequest {
    idClasse?: number;
}

export interface ReferentialGetClasseMatiereRequest {
    idInscription?: number;
}

export interface ReferentialGetClasseMatiereByClasseRequest {
    idClasse?: number;
}

export interface ReferentialGetClasseMatiereByClasseAndTypeEvalRequest {
    idClasse?: number;
    idTypeEval?: number;
}

export interface ReferentialGetClasseMatieresByNiveauRequest {
    idclasse?: number;
    niveau?: number;
}

export interface ReferentialGetCodePostauxByPaysRequest {
    codePays?: string;
}

export interface ReferentialGetCommissionsParitairesRequest {
    idmetier?: number;
    idsiegeSocial?: number;
    currentId?: number;
}

export interface ReferentialGetCommuneFromCodePostalRequest {
    codePays?: string;
    codePostal?: string;
}

export interface ReferentialGetComptesBancairesPersonneRequest {
    idpersonne?: number;
}

export interface ReferentialGetContactSiegeSocialRequest {
    idSiegeSocial?: number;
}

export interface ReferentialGetDateForEvaluationRequest {
    idClasse?: number;
}

export interface ReferentialGetDecisionCommissionTutellesRequest {
    id?: number;
}

export interface ReferentialGetDeliberationTypesRequest {
    typeDeliberation?: ETypeDeliberation;
}

export interface ReferentialGetDureesContratRequest {
    typeContrat?: ETypeContrat;
    idstade?: number;
}

export interface ReferentialGetDureesContratByMetierRequest {
    typeContrat?: ETypeContrat;
    idmetier?: number;
    currentId?: number;
}

export interface ReferentialGetEmailsPersonneRequest {
    idpersonne?: number;
}

export interface ReferentialGetFormateurByCentreRequest {
    idCentre?: number;
}

export interface ReferentialGetFormateurByIdClasseRequest {
    idClasse?: number;
}

export interface ReferentialGetFormateurByIdClasseOrIdCentreRequest {
    idClasse?: number;
    idCentre?: number;
}

export interface ReferentialGetFormateurByRemplacementRequest {
    idRemplacement?: number;
}

export interface ReferentialGetFormateurLocalisationContratRequest {
    idformateur?: number;
    idcentre?: number;
}

export interface ReferentialGetFormationApprenantRequest {
    idApprenant?: number;
}

export interface ReferentialGetGsmsPersonneRequest {
    idpersonne?: number;
}

export interface ReferentialGetHeuresHebdomadairesRequest {
    idcommissionParitaire?: number;
}

export interface ReferentialGetHoraireClasseRequest {
    idClasse?: number;
}

export interface ReferentialGetInscriptionDatesConvocationRequest {
    GetInscriptionDatesConvocationRequestDto?: GetInscriptionDatesConvocationRequestDto;
}

export interface ReferentialGetInscriptionsApprenantRequest {
    idCentre?: number;
    anneeScolaire?: string;
    idApprenant?: number;
}

export interface ReferentialGetLearnerRepresentativesRequest {
    learnerId?: number;
}

export interface ReferentialGetLieuRequest {
    idCentre?: number;
}

export interface ReferentialGetLocalByCentreRequest {
    idCentre?: number;
}

export interface ReferentialGetLocalByClasseRequest {
    idClasse?: number;
}

export interface ReferentialGetLocaliteFromCodePostalRequest {
    codePays?: string;
    codePostal?: string;
}

export interface ReferentialGetLocalitesSuggestRequest {
    pays?: string;
}

export interface ReferentialGetMetierByDegreRequest {
    idDegre?: string;
}

export interface ReferentialGetMetierByStadeRequest {
    idStade?: number;
}

export interface ReferentialGetMotifsRuptureJeuneRequest {
    selectedId?: number;
}

export interface ReferentialGetMotifsRupturePatronRequest {
    selectedId?: number;
}

export interface ReferentialGetMotifsSortieRequest {
    idtypeSortie?: number;
}

export interface ReferentialGetNationaliteRequest {
    codeSexe?: string;
}

export interface ReferentialGetNotorieteSousTypeRequest {
    idNotorieteType?: number;
}

export interface ReferentialGetPlagesRequest {
    idClasse?: number;
}

export interface ReferentialGetProjetFinancementByClasseRequest {
    idClasse?: number;
}

export interface ReferentialGetProjetFinancementByIdRequest {
    id?: number;
}

export interface ReferentialGetReferentielByDegreAndMetierRequest {
    idDegre?: string;
    idMetier?: number;
}

export interface ReferentialGetReferentielMatieresByNiveauRequest {
    idreferentiel?: number;
    niveau?: number;
}

export interface ReferentialGetReportByModuleIdRequest {
    id?: number;
}

export interface ReferentialGetSameCentreUsersRequest {
    currentId?: number;
}

export interface ReferentialGetSecteurByIdsecteurCreaRequest {
    idsecteurCrea?: number;
}

export interface ReferentialGetSecteurHomogeneByDegreRequest {
    idDegre?: string;
    idClasse?: number;
}

export interface ReferentialGetServiceTutelleRequest {
    userFilter?: boolean;
    ifapmeSide?: EIfapmeSide;
    currentId?: number;
}

export interface ReferentialGetServiceUsersRequest {
    currentId?: number;
    request_body?: Array<number>;
}

export interface ReferentialGetServiceUsersByRolesRequest {
    idService?: number;
    ELevelName?: Array<ELevelName>;
}

export interface ReferentialGetServicesByIdApprenantRequest {
    idApprenant?: number;
    ifapmeSide?: EIfapmeSide;
}

export interface ReferentialGetSiegeLieuTypesSuiviRequest {
    currentId?: number;
}

export interface ReferentialGetSiegeSociauxRequest {
    idsiegeSocial?: number;
}

export interface ReferentialGetSocietesExternesByMetierRequest {
    idmetier?: number;
}

export interface ReferentialGetSousStatutsByCategorieRequest {
    idCategorie?: number;
    currentIdstatutSocial?: number;
}

export interface ReferentialGetSousTypeSuivisRequest {
    idtypeSuivi?: number;
    idsousTypeSuivi?: number;
}

export interface ReferentialGetSpecificiteCoursRequest {
    code?: string;
}

export interface ReferentialGetStadeByMetierRequest {
    idmetier?: number;
}

export interface ReferentialGetStadeContratDMRequest {
    currentId?: number;
}

export interface ReferentialGetStatutLocalisationAgrementRequest {
    isLocalisation?: boolean;
}

export interface ReferentialGetStatutSocialRequest {
    currentIdstatutSocial?: number;
}

export interface ReferentialGetStatutsProspectRequest {
    idapprenant?: number;
}

export interface ReferentialGetTelephonesPersonneRequest {
    idpersonne?: number;
}

export interface ReferentialGetTitreDiplomeCertificationsRequest {
    currentId?: number;
}

export interface ReferentialGetTitreDiplomeCertificationsIfapmeRequest {
    currentId?: number;
}

export interface ReferentialGetTypeCoursRequest {
    ifapmeSide?: EIfapmeSide;
}

export interface ReferentialGetTypeCoursCodesRequest {
    ifapmeSide?: EIfapmeSide;
}

export interface ReferentialGetTypeCoursIdRequest {
    ifapmeSide?: EIfapmeSide;
}

export interface ReferentialGetTypeEvaluationByClasseRequest {
    idEvaluation?: number;
    idClasse?: number;
    idClasseMatiere?: number;
}

export interface ReferentialGetTypeFinancementRequest {
    currentId?: number;
}

export interface ReferentialGetTypeSuivisRequest {
    side?: EIfapmeSide;
    idtypeSuivi?: number;
}

export interface ReferentialGetTypesApprenantFichierRequest {
    ETypeApprenantFichier?: Array<ETypeApprenantFichier>;
}

export interface ReferentialGetTypesSortieRequest {
    currentId?: number;
}

export interface ReferentialGetUsersByRolesRequest {
    currentIduser?: number;
    ELevelName?: Array<ELevelName>;
}

export interface ReferentialGetUsersByRolesAndApprenantRequest {
    idapprenant?: number;
    ELevelName?: Array<ELevelName>;
}

export interface ReferentialLocalsByMatiereRequest {
    idClasseMatiere?: number;
}

export interface ReferentialMetierByStadeForApprenantRequest {
    idStade?: number;
    idApprenant?: number;
}

export interface ReferentialMetierByStadeForContratRequest {
    idStade?: number;
    currentId?: number;
}

export interface ReferentialMetierNotAPRequest {
    currentId?: number;
}

/**
 * 
 */
export class ReferentialApi extends runtime.BaseAPI {

    /**
     */
    async referentialContratsByMatiereRaw(requestParameters: ReferentialContratsByMatiereRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasseMatiere !== undefined) {
            queryParameters['idClasseMatiere'] = requestParameters.idClasseMatiere;
        }

        if (requestParameters.idFormateur !== undefined) {
            queryParameters['idFormateur'] = requestParameters.idFormateur;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/ContratsByMatiere`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialContratsByMatiere(requestParameters: ReferentialContratsByMatiereRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialContratsByMatiereRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialDegreIdsByStadeRaw(requestParameters: ReferentialDegreIdsByStadeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idStade !== undefined) {
            queryParameters['idStade'] = requestParameters.idStade;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/DegreIdsByStade`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialDegreIdsByStade(requestParameters: ReferentialDegreIdsByStadeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialDegreIdsByStadeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetAccesLevelListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetAccesLevelList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetAccesLevelList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetAccesLevelListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetAccessLevelsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/AccessLevels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetAccessLevels(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetAccessLevelsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetAccessLevelsTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/TypeLevels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetAccessLevelsTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetAccessLevelsTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetAcquisAdmissionTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetAcquisAdmissionTypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetAcquisAdmissionTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetAcquisAdmissionTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetAcquisCommentairesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetAcquisCommentaires`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetAcquisCommentaires(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetAcquisCommentairesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetAcquisScolaireTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetAcquisScolaireTypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetAcquisScolaireTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetAcquisScolaireTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetAdressesPersonneRaw(requestParameters: ReferentialGetAdressesPersonneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdresseReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idpersonne !== undefined) {
            queryParameters['idpersonne'] = requestParameters.idpersonne;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetAdressesPersonne`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdresseReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetAdressesPersonne(requestParameters: ReferentialGetAdressesPersonneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdresseReferentialResultInfoDto> {
        const response = await this.referentialGetAdressesPersonneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetAllClasseRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/AllClasse`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetAllClasse(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetAllClasseRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetAllNationaliteRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/AllNationalite`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetAllNationalite(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetAllNationaliteRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetAllTelephonesPersonneRaw(requestParameters: ReferentialGetAllTelephonesPersonneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idpersonne !== undefined) {
            queryParameters['idpersonne'] = requestParameters.idpersonne;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetAllTelephonesPersonne`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetAllTelephonesPersonne(requestParameters: ReferentialGetAllTelephonesPersonneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetAllTelephonesPersonneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetAmbassadeurCreaRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetAmbassadeurCrea`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetAmbassadeurCrea(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetAmbassadeurCreaRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetAnneeFormationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetAnneeFormations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetAnneeFormations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetAnneeFormationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetAnneeReussieRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/AnneeReussie`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetAnneeReussie(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetAnneeReussieRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetAnneeScolaireRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/AnneeScolaire`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetAnneeScolaire(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetAnneeScolaireRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetAnneesPrestationsRaw(requestParameters: ReferentialGetAnneesPrestationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idFormateur !== undefined) {
            queryParameters['idFormateur'] = requestParameters.idFormateur;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/anneesPrestations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetAnneesPrestations(requestParameters: ReferentialGetAnneesPrestationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetAnneesPrestationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetApprenantContratsRaw(requestParameters: ReferentialGetApprenantContratsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idApprenant !== undefined) {
            queryParameters['idApprenant'] = requestParameters.idApprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/ApprenantContrats`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.ETypeContrat.map(ETypeContratToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetApprenantContrats(requestParameters: ReferentialGetApprenantContratsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetApprenantContratsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetAutoecolesRaw(requestParameters: ReferentialGetAutoecolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.permisCode !== undefined) {
            queryParameters['permisCode'] = requestParameters.permisCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetAutoecoles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetAutoecoles(requestParameters: ReferentialGetAutoecolesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetAutoecolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetAvisFormateurSuiviRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/AvisFormateurSuivi`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetAvisFormateurSuivi(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetAvisFormateurSuiviRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetBothAccessLevelsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/BothAccessLevels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetBothAccessLevels(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetBothAccessLevelsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetCalcsForServiceRaw(requestParameters: ReferentialGetCalcsForServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idService !== undefined) {
            queryParameters['idService'] = requestParameters.idService;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetCalcsForService`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetCalcsForService(requestParameters: ReferentialGetCalcsForServiceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetCalcsForServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetCalendriersRaw(requestParameters: ReferentialGetCalendriersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Calendrier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetCalendriers(requestParameters: ReferentialGetCalendriersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetCalendriersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetCategorieSpecifiqueCreaRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetCategorieSpecifiqueCrea`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetCategorieSpecifiqueCrea(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetCategorieSpecifiqueCreaRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetCategoriesRapportRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetCategoriesRapport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async referentialGetCategoriesRapport(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.referentialGetCategoriesRapportRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetCategoriesStatutSocialRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetCategorieStatutSocial`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetCategoriesStatutSocial(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetCategoriesStatutSocialRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetCentreRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Centre`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetCentre(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetCentreRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetCentreByApprenantRaw(requestParameters: ReferentialGetCentreByApprenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idApprenant !== undefined) {
            queryParameters['idApprenant'] = requestParameters.idApprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/CentreByApprenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetCentreByApprenant(requestParameters: ReferentialGetCentreByApprenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetCentreByApprenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetCentreByUserRaw(requestParameters: ReferentialGetCentreByUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idCentre !== undefined) {
            queryParameters['idCentre'] = requestParameters.idCentre;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/CentreByUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetCentreByUser(requestParameters: ReferentialGetCentreByUserRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetCentreByUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetCentreGlobalRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/CentreGlobal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetCentreGlobal(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetCentreGlobalRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetCentreGlobalUsersRaw(requestParameters: ReferentialGetCentreGlobalUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.currentIduser !== undefined) {
            queryParameters['currentIduser'] = requestParameters.currentIduser;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetCentreGlobalUsers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetCentreGlobalUsers(requestParameters: ReferentialGetCentreGlobalUsersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetCentreGlobalUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetCentreUsersRaw(requestParameters: ReferentialGetCentreUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetCentreUsers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request_body,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetCentreUsers(requestParameters: ReferentialGetCentreUsersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetCentreUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetCentresRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Centres`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetCentres(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetCentresRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetCentresDemandeRaw(requestParameters: ReferentialGetCentresDemandeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/CentresDemande`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CentreDemandeRequestDtoToJSON(requestParameters.CentreDemandeRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetCentresDemande(requestParameters: ReferentialGetCentresDemandeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetCentresDemandeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetCertificationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetCertifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetCertifications(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetCertificationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetCivilitesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Civilites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetCivilites(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetCivilitesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetClasseRaw(requestParameters: ReferentialGetClasseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idCentre !== undefined) {
            queryParameters['idCentre'] = requestParameters.idCentre;
        }

        if (requestParameters.anneeScolaire !== undefined) {
            queryParameters['anneeScolaire'] = requestParameters.anneeScolaire;
        }

        if (requestParameters.typeCours !== undefined) {
            queryParameters['typeCours'] = requestParameters.typeCours;
        }

        if (requestParameters.idMetier !== undefined) {
            queryParameters['idMetier'] = requestParameters.idMetier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Classe`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetClasse(requestParameters: ReferentialGetClasseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetClasseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetClasseApprenantRaw(requestParameters: ReferentialGetClasseApprenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idApprenant !== undefined) {
            queryParameters['idApprenant'] = requestParameters.idApprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/ClasseApprenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetClasseApprenant(requestParameters: ReferentialGetClasseApprenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetClasseApprenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetClasseApprenantCurrentYearRaw(requestParameters: ReferentialGetClasseApprenantCurrentYearRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idApprenant !== undefined) {
            queryParameters['idApprenant'] = requestParameters.idApprenant;
        }

        if (requestParameters.idCentre !== undefined) {
            queryParameters['idCentre'] = requestParameters.idCentre;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/ClasseApprenantCurrentYear`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetClasseApprenantCurrentYear(requestParameters: ReferentialGetClasseApprenantCurrentYearRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetClasseApprenantCurrentYearRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetClasseByInscriptionRaw(requestParameters: ReferentialGetClasseByInscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idInscription !== undefined) {
            queryParameters['idInscription'] = requestParameters.idInscription;
        }

        if (requestParameters.isForChangementClasseTable !== undefined) {
            queryParameters['isForChangementClasseTable'] = requestParameters.isForChangementClasseTable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/ClasseByInscription`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetClasseByInscription(requestParameters: ReferentialGetClasseByInscriptionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetClasseByInscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetClasseForContratRaw(requestParameters: ReferentialGetClasseForContratRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetClasseForContrat`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetClasseForContrat(requestParameters: ReferentialGetClasseForContratRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetClasseForContratRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetClasseForEvaluationRaw(requestParameters: ReferentialGetClasseForEvaluationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idEvaluation !== undefined) {
            queryParameters['idEvaluation'] = requestParameters.idEvaluation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/ClasseForEvaluation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetClasseForEvaluation(requestParameters: ReferentialGetClasseForEvaluationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetClasseForEvaluationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetClasseForInscriptionRaw(requestParameters: ReferentialGetClasseForInscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idCentre !== undefined) {
            queryParameters['idCentre'] = requestParameters.idCentre;
        }

        if (requestParameters.anneeScolaire !== undefined) {
            queryParameters['anneeScolaire'] = requestParameters.anneeScolaire;
        }

        if (requestParameters.typeCours !== undefined) {
            queryParameters['typeCours'] = requestParameters.typeCours;
        }

        if (requestParameters.idApprenant !== undefined) {
            queryParameters['idApprenant'] = requestParameters.idApprenant;
        }

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        if (requestParameters.isARE !== undefined) {
            queryParameters['isARE'] = requestParameters.isARE;
        }

        if (requestParameters.isSB !== undefined) {
            queryParameters['isSB'] = requestParameters.isSB;
        }

        if (requestParameters.specificiteCours !== undefined) {
            queryParameters['specificiteCours'] = requestParameters.specificiteCours;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/ClasseForInscription`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetClasseForInscription(requestParameters: ReferentialGetClasseForInscriptionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetClasseForInscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetClasseJoursRaw(requestParameters: ReferentialGetClasseJoursRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/ClasseJours`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetClasseJours(requestParameters: ReferentialGetClasseJoursRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetClasseJoursRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetClasseMatiereRaw(requestParameters: ReferentialGetClasseMatiereRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idInscription !== undefined) {
            queryParameters['idInscription'] = requestParameters.idInscription;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/ClasseMatiere`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetClasseMatiere(requestParameters: ReferentialGetClasseMatiereRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetClasseMatiereRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetClasseMatiereByClasseRaw(requestParameters: ReferentialGetClasseMatiereByClasseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/ClasseMatiereByClasse`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetClasseMatiereByClasse(requestParameters: ReferentialGetClasseMatiereByClasseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetClasseMatiereByClasseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetClasseMatiereByClasseAndTypeEvalRaw(requestParameters: ReferentialGetClasseMatiereByClasseAndTypeEvalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        if (requestParameters.idTypeEval !== undefined) {
            queryParameters['idTypeEval'] = requestParameters.idTypeEval;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/ClasseMatiereByClasseAndTypeEval`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetClasseMatiereByClasseAndTypeEval(requestParameters: ReferentialGetClasseMatiereByClasseAndTypeEvalRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetClasseMatiereByClasseAndTypeEvalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetClasseMatieresByNiveauRaw(requestParameters: ReferentialGetClasseMatieresByNiveauRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idclasse !== undefined) {
            queryParameters['idclasse'] = requestParameters.idclasse;
        }

        if (requestParameters.niveau !== undefined) {
            queryParameters['niveau'] = requestParameters.niveau;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetClasseMatieresByNiveau`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetClasseMatieresByNiveau(requestParameters: ReferentialGetClasseMatieresByNiveauRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetClasseMatieresByNiveauRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetClausesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetClauses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetClauses(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetClausesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetClausesContratRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetClausesContrat`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetClausesContrat(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetClausesContratRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetCodePostauxRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetCodePostaux`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetCodePostaux(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetCodePostauxRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetCodePostauxByPaysRaw(requestParameters: ReferentialGetCodePostauxByPaysRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.codePays !== undefined) {
            queryParameters['codePays'] = requestParameters.codePays;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetCodePostauxByPays`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetCodePostauxByPays(requestParameters: ReferentialGetCodePostauxByPaysRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetCodePostauxByPaysRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetCodePostauxWithLocaliteRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetCodePostauxWithLocalite`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetCodePostauxWithLocalite(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetCodePostauxWithLocaliteRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetCommissionsParitairesRaw(requestParameters: ReferentialGetCommissionsParitairesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idmetier !== undefined) {
            queryParameters['idmetier'] = requestParameters.idmetier;
        }

        if (requestParameters.idsiegeSocial !== undefined) {
            queryParameters['idsiegeSocial'] = requestParameters.idsiegeSocial;
        }

        if (requestParameters.currentId !== undefined) {
            queryParameters['currentId'] = requestParameters.currentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetCommissionsParitaires`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetCommissionsParitaires(requestParameters: ReferentialGetCommissionsParitairesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetCommissionsParitairesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetCommuneFromCodePostalRaw(requestParameters: ReferentialGetCommuneFromCodePostalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.codePays !== undefined) {
            queryParameters['codePays'] = requestParameters.codePays;
        }

        if (requestParameters.codePostal !== undefined) {
            queryParameters['codePostal'] = requestParameters.codePostal;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/CommuneFromCodePostal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetCommuneFromCodePostal(requestParameters: ReferentialGetCommuneFromCodePostalRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetCommuneFromCodePostalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetCompagniesAssuranceRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/CompagniesAssurance`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetCompagniesAssurance(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetCompagniesAssuranceRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetComptesBancairesPersonneRaw(requestParameters: ReferentialGetComptesBancairesPersonneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idpersonne !== undefined) {
            queryParameters['idpersonne'] = requestParameters.idpersonne;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetComptesBancairesPersonne`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetComptesBancairesPersonne(requestParameters: ReferentialGetComptesBancairesPersonneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetComptesBancairesPersonneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetConseillersPedagogiquesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Conseillers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetConseillersPedagogiques(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetConseillersPedagogiquesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetConseillersPedagogiquesCodesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/ConseillersCodes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetConseillersPedagogiquesCodes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetConseillersPedagogiquesCodesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetContactSiegeSocialRaw(requestParameters: ReferentialGetContactSiegeSocialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idSiegeSocial !== undefined) {
            queryParameters['idSiegeSocial'] = requestParameters.idSiegeSocial;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetContactSiegeSocial`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetContactSiegeSocial(requestParameters: ReferentialGetContactSiegeSocialRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetContactSiegeSocialRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetContratFormateurRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/ContratFormateur`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetContratFormateur(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetContratFormateurRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetContratInitiateursRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetContratInitiateurs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetContratInitiateurs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetContratInitiateursRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetDataBasesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/DataBases`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetDataBases(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetDataBasesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetDateForEvaluationRaw(requestParameters: ReferentialGetDateForEvaluationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/DateForEvaluation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetDateForEvaluation(requestParameters: ReferentialGetDateForEvaluationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetDateForEvaluationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetDecisionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Decision`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetDecision(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetDecisionRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetDecisionCommissionTutellesRaw(requestParameters: ReferentialGetDecisionCommissionTutellesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetDecisionCommissionTutelles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetDecisionCommissionTutelles(requestParameters: ReferentialGetDecisionCommissionTutellesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetDecisionCommissionTutellesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetDecisionDossierAdmissionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetDecisionDossierAdmissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetDecisionDossierAdmissions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetDecisionDossierAdmissionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetDegreRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Degre`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetDegre(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetDegreRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetDegreCodesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/DegreCodes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetDegreCodes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetDegreCodesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetDegreIdsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/DegreIds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetDegreIds(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetDegreIdsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetDelegueRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Delegue`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetDelegue(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetDelegueRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetDeliberationTypesRaw(requestParameters: ReferentialGetDeliberationTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.typeDeliberation !== undefined) {
            queryParameters['typeDeliberation'] = requestParameters.typeDeliberation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/getDeliberations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetDeliberationTypes(requestParameters: ReferentialGetDeliberationTypesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetDeliberationTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetDemandeTypeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/DemandeType`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetDemandeType(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetDemandeTypeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetDepartementsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetDepartements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetDepartements(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetDepartementsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetDiplomeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Diplome`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetDiplome(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetDiplomeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetDirectionTerritorialeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/DirectionTerritoriale`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetDirectionTerritoriale(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetDirectionTerritorialeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetDispensesChomageRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetDispensesChomage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetDispensesChomage(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetDispensesChomageRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetDureeInoccupationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetDureeInoccupation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetDureeInoccupation(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetDureeInoccupationRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetDureesContratRaw(requestParameters: ReferentialGetDureesContratRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.typeContrat !== undefined) {
            queryParameters['typeContrat'] = requestParameters.typeContrat;
        }

        if (requestParameters.idstade !== undefined) {
            queryParameters['idstade'] = requestParameters.idstade;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetDureesContrat`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetDureesContrat(requestParameters: ReferentialGetDureesContratRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetDureesContratRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetDureesContratByMetierRaw(requestParameters: ReferentialGetDureesContratByMetierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.typeContrat !== undefined) {
            queryParameters['typeContrat'] = requestParameters.typeContrat;
        }

        if (requestParameters.idmetier !== undefined) {
            queryParameters['idmetier'] = requestParameters.idmetier;
        }

        if (requestParameters.currentId !== undefined) {
            queryParameters['currentId'] = requestParameters.currentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetDureesContratByMetier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetDureesContratByMetier(requestParameters: ReferentialGetDureesContratByMetierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetDureesContratByMetierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetEcoleRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Ecole`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetEcole(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetEcoleRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetEmailsPersonneRaw(requestParameters: ReferentialGetEmailsPersonneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idpersonne !== undefined) {
            queryParameters['idpersonne'] = requestParameters.idpersonne;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetEmailsPersonne`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetEmailsPersonne(requestParameters: ReferentialGetEmailsPersonneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetEmailsPersonneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetEntreprisesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Entreprises`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetEntreprises(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetEntreprisesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetEtatLotSmsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/EtatLotSms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetEtatLotSms(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetEtatLotSmsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetEtatsAgrementRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetEtatsAgrement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetEtatsAgrement(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetEtatsAgrementRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetExportTypeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/ExportType`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetExportType(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetExportTypeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetFonctionsChefsEntrepriseRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetFonctionsChefsEntreprise`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async referentialGetFonctionsChefsEntreprise(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.referentialGetFonctionsChefsEntrepriseRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetFonctionsContactsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetFonctionsContacts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async referentialGetFonctionsContacts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.referentialGetFonctionsContactsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetFonctionsContactsLieuRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetFonctionsContactsLieu`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async referentialGetFonctionsContactsLieu(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.referentialGetFonctionsContactsLieuRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetFonctionsTuteursRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetFonctionsTuteurs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async referentialGetFonctionsTuteurs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.referentialGetFonctionsTuteursRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetFormateurRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Formateur`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetFormateur(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetFormateurRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetFormateurByCentreRaw(requestParameters: ReferentialGetFormateurByCentreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idCentre !== undefined) {
            queryParameters['idCentre'] = requestParameters.idCentre;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/FormateurByCentre`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetFormateurByCentre(requestParameters: ReferentialGetFormateurByCentreRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetFormateurByCentreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetFormateurByIdClasseRaw(requestParameters: ReferentialGetFormateurByIdClasseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/FormateurByIdClasse`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetFormateurByIdClasse(requestParameters: ReferentialGetFormateurByIdClasseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetFormateurByIdClasseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetFormateurByIdClasseOrIdCentreRaw(requestParameters: ReferentialGetFormateurByIdClasseOrIdCentreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        if (requestParameters.idCentre !== undefined) {
            queryParameters['idCentre'] = requestParameters.idCentre;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/FormateurByIdClasseOrIdCentre`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetFormateurByIdClasseOrIdCentre(requestParameters: ReferentialGetFormateurByIdClasseOrIdCentreRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetFormateurByIdClasseOrIdCentreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetFormateurByRemplacementRaw(requestParameters: ReferentialGetFormateurByRemplacementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idRemplacement !== undefined) {
            queryParameters['idRemplacement'] = requestParameters.idRemplacement;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/FormateurByRemplacement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetFormateurByRemplacement(requestParameters: ReferentialGetFormateurByRemplacementRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetFormateurByRemplacementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetFormateurLocalisationContratRaw(requestParameters: ReferentialGetFormateurLocalisationContratRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idformateur !== undefined) {
            queryParameters['idformateur'] = requestParameters.idformateur;
        }

        if (requestParameters.idcentre !== undefined) {
            queryParameters['idcentre'] = requestParameters.idcentre;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/FormateurLocalisationContrat`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetFormateurLocalisationContrat(requestParameters: ReferentialGetFormateurLocalisationContratRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetFormateurLocalisationContratRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetFormationApprenantRaw(requestParameters: ReferentialGetFormationApprenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idApprenant !== undefined) {
            queryParameters['idApprenant'] = requestParameters.idApprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/FormationApprenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetFormationApprenant(requestParameters: ReferentialGetFormationApprenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetFormationApprenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetFormeFormationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetFormeFormations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetFormeFormations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetFormeFormationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetFormesJuridiquesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetFormesJuridiques`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetFormesJuridiques(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetFormesJuridiquesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetGenreClasseRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GenreClasse`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetGenreClasse(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetGenreClasseRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetGenreClasseCodesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GenreClasseCodes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetGenreClasseCodes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetGenreClasseCodesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetGenreContratRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GenreContrat`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetGenreContrat(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetGenreContratRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetGenreJustificatifsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GenreJustificatifs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetGenreJustificatifs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetGenreJustificatifsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetGradeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/getGrade`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetGrade(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetGradeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetGroupByCentreQuotaRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/getGroupByCentreCentreQuota`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetGroupByCentreQuota(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetGroupByCentreQuotaRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetGsmsPersonneRaw(requestParameters: ReferentialGetGsmsPersonneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idpersonne !== undefined) {
            queryParameters['idpersonne'] = requestParameters.idpersonne;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetGsmsPersonne`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetGsmsPersonne(requestParameters: ReferentialGetGsmsPersonneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetGsmsPersonneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetHeuresHebdomadairesRaw(requestParameters: ReferentialGetHeuresHebdomadairesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idcommissionParitaire !== undefined) {
            queryParameters['idcommissionParitaire'] = requestParameters.idcommissionParitaire;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetHeuresHebdomadaires`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetHeuresHebdomadaires(requestParameters: ReferentialGetHeuresHebdomadairesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetHeuresHebdomadairesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetHopeAccessLevelsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/HopeAccessLevels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetHopeAccessLevels(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetHopeAccessLevelsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetHopeUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetHopeUsers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetHopeUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetHopeUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetHoraireClasseRaw(requestParameters: ReferentialGetHoraireClasseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/HoraireClasse`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetHoraireClasse(requestParameters: ReferentialGetHoraireClasseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetHoraireClasseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetInscriptionDatesConvocationRaw(requestParameters: ReferentialGetInscriptionDatesConvocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/InscriptionDatesConvocation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetInscriptionDatesConvocationRequestDtoToJSON(requestParameters.GetInscriptionDatesConvocationRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetInscriptionDatesConvocation(requestParameters: ReferentialGetInscriptionDatesConvocationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetInscriptionDatesConvocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetInscriptionsApprenantRaw(requestParameters: ReferentialGetInscriptionsApprenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idCentre !== undefined) {
            queryParameters['idCentre'] = requestParameters.idCentre;
        }

        if (requestParameters.anneeScolaire !== undefined) {
            queryParameters['anneeScolaire'] = requestParameters.anneeScolaire;
        }

        if (requestParameters.idApprenant !== undefined) {
            queryParameters['idApprenant'] = requestParameters.idApprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/InscriptionsApprenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetInscriptionsApprenant(requestParameters: ReferentialGetInscriptionsApprenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetInscriptionsApprenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetJustificatifsPlagesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/PlageJustificatifs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetJustificatifsPlages(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetJustificatifsPlagesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetLanguesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Langues`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetLangues(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetLanguesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetLearnerRepresentativesRaw(requestParameters: ReferentialGetLearnerRepresentativesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.learnerId !== undefined) {
            queryParameters['learnerId'] = requestParameters.learnerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetLearnerRepresentatives`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetLearnerRepresentatives(requestParameters: ReferentialGetLearnerRepresentativesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetLearnerRepresentativesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetLieuRaw(requestParameters: ReferentialGetLieuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idCentre !== undefined) {
            queryParameters['idCentre'] = requestParameters.idCentre;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Lieu`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetLieu(requestParameters: ReferentialGetLieuRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetLieuRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetLieuFormationAgrementAvisRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetLieuFormationAgrementAvis`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetLieuFormationAgrementAvis(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetLieuFormationAgrementAvisRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetLieuFormationAgrementDecisionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetLieuFormationAgrementDecisions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetLieuFormationAgrementDecisions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetLieuFormationAgrementDecisionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetLieuRencontresRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetLieuRencontres`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetLieuRencontres(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetLieuRencontresRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetLieuSuiviRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/LieuSuivi`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetLieuSuivi(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetLieuSuiviRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetLieuxSignatureRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetLieuxSignature`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetLieuxSignature(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetLieuxSignatureRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetLocalByCentreRaw(requestParameters: ReferentialGetLocalByCentreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idCentre !== undefined) {
            queryParameters['idCentre'] = requestParameters.idCentre;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/LocalByCentre`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetLocalByCentre(requestParameters: ReferentialGetLocalByCentreRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetLocalByCentreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetLocalByClasseRaw(requestParameters: ReferentialGetLocalByClasseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/LocalByClasse`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetLocalByClasse(requestParameters: ReferentialGetLocalByClasseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetLocalByClasseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetLocaliteFromCodePostalRaw(requestParameters: ReferentialGetLocaliteFromCodePostalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.codePays !== undefined) {
            queryParameters['codePays'] = requestParameters.codePays;
        }

        if (requestParameters.codePostal !== undefined) {
            queryParameters['codePostal'] = requestParameters.codePostal;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/LocaliteFromCodePostal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetLocaliteFromCodePostal(requestParameters: ReferentialGetLocaliteFromCodePostalRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetLocaliteFromCodePostalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetLocalitesSuggestRaw(requestParameters: ReferentialGetLocalitesSuggestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.pays !== undefined) {
            queryParameters['pays'] = requestParameters.pays;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetLocalitesSuggest`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetLocalitesSuggest(requestParameters: ReferentialGetLocalitesSuggestRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetLocalitesSuggestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetMetierRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Metier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetMetier(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetMetierRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetMetierByDegreRaw(requestParameters: ReferentialGetMetierByDegreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idDegre !== undefined) {
            queryParameters['idDegre'] = requestParameters.idDegre;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/MetierByDegre`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetMetierByDegre(requestParameters: ReferentialGetMetierByDegreRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetMetierByDegreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetMetierByStadeRaw(requestParameters: ReferentialGetMetierByStadeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idStade !== undefined) {
            queryParameters['idStade'] = requestParameters.idStade;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/MetierByStade`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetMetierByStade(requestParameters: ReferentialGetMetierByStadeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetMetierByStadeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetMetierCodesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/MetierCodes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetMetierCodes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetMetierCodesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetMetierCreaRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetMetierCrea`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetMetierCrea(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetMetierCreaRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetModesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetModes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetModes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetModesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetModesPaiementRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/ModesPaiement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetModesPaiement(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetModesPaiementRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetModuleCreaRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetModuleCrea`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetModuleCrea(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetModuleCreaRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetMoisRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Mois`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetMois(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetMoisRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetMotifsNonAgrementRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetMotifsNonAgrement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetMotifsNonAgrement(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetMotifsNonAgrementRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetMotifsRetraitRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetMotifsRetrait`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetMotifsRetrait(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetMotifsRetraitRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetMotifsRuptureJeuneRaw(requestParameters: ReferentialGetMotifsRuptureJeuneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.selectedId !== undefined) {
            queryParameters['selectedId'] = requestParameters.selectedId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetMotifsRuptureJeune`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetMotifsRuptureJeune(requestParameters: ReferentialGetMotifsRuptureJeuneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetMotifsRuptureJeuneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetMotifsRupturePatronRaw(requestParameters: ReferentialGetMotifsRupturePatronRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.selectedId !== undefined) {
            queryParameters['selectedId'] = requestParameters.selectedId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetMotifsRupturePatron`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetMotifsRupturePatron(requestParameters: ReferentialGetMotifsRupturePatronRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetMotifsRupturePatronRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetMotifsSortieRaw(requestParameters: ReferentialGetMotifsSortieRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idtypeSortie !== undefined) {
            queryParameters['idtypeSortie'] = requestParameters.idtypeSortie;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetMotifSortie`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetMotifsSortie(requestParameters: ReferentialGetMotifsSortieRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetMotifsSortieRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetMotifsSuspensionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetMotifsSuspension`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetMotifsSuspension(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetMotifsSuspensionRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetNationaliteRaw(requestParameters: ReferentialGetNationaliteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.codeSexe !== undefined) {
            queryParameters['codeSexe'] = requestParameters.codeSexe;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Nationalite`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetNationalite(requestParameters: ReferentialGetNationaliteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetNationaliteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetNiveauFormationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetNiveauFormations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetNiveauFormations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetNiveauFormationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetNotorieteSousTypeRaw(requestParameters: ReferentialGetNotorieteSousTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idNotorieteType !== undefined) {
            queryParameters['idNotorieteType'] = requestParameters.idNotorieteType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetNotorieteSousType`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetNotorieteSousType(requestParameters: ReferentialGetNotorieteSousTypeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetNotorieteSousTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetNotorieteTypeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetNotorieteType`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetNotorieteType(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetNotorieteTypeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetOperateursFormationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/OperateursFormation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetOperateursFormation(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetOperateursFormationRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetPaysRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Pays`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetPays(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetPaysRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetPermisRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetPermis`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetPermis(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetPermisRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetPersonnesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetPersonnes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetPersonnes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetPersonnesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetPlageHoraireVisiteRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/PlageHoraireVisite`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetPlageHoraireVisite(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetPlageHoraireVisiteRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetPlagesRaw(requestParameters: ReferentialGetPlagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Plage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetPlages(requestParameters: ReferentialGetPlagesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetPlagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetPostesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetPostes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetPostes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetPostesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetProfessionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Professions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetProfessions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetProfessionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetProjetFinancementRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/ProjetFinancement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetProjetFinancement(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetProjetFinancementRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetProjetFinancementByClasseRaw(requestParameters: ReferentialGetProjetFinancementByClasseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/projetFinancementByClasse`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetProjetFinancementByClasse(requestParameters: ReferentialGetProjetFinancementByClasseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetProjetFinancementByClasseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetProjetFinancementByIdRaw(requestParameters: ReferentialGetProjetFinancementByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/projetFinancementById`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetProjetFinancementById(requestParameters: ReferentialGetProjetFinancementByIdRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetProjetFinancementByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetRapportHopeTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetRapportHopeTypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetRapportHopeTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetRapportHopeTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetReferentielRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Referentiel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetReferentiel(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetReferentielRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetReferentielByDegreAndMetierRaw(requestParameters: ReferentialGetReferentielByDegreAndMetierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idDegre !== undefined) {
            queryParameters['idDegre'] = requestParameters.idDegre;
        }

        if (requestParameters.idMetier !== undefined) {
            queryParameters['idMetier'] = requestParameters.idMetier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/ReferentielByDegreAndMetier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetReferentielByDegreAndMetier(requestParameters: ReferentialGetReferentielByDegreAndMetierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetReferentielByDegreAndMetierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetReferentielMatieresByNiveauRaw(requestParameters: ReferentialGetReferentielMatieresByNiveauRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idreferentiel !== undefined) {
            queryParameters['idreferentiel'] = requestParameters.idreferentiel;
        }

        if (requestParameters.niveau !== undefined) {
            queryParameters['niveau'] = requestParameters.niveau;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetReferentielMatieresByNiveau`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetReferentielMatieresByNiveau(requestParameters: ReferentialGetReferentielMatieresByNiveauRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetReferentielMatieresByNiveauRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetRemplacementLegitimiteRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/RemplacementLegitimite`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetRemplacementLegitimite(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetRemplacementLegitimiteRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetReportByModuleIdRaw(requestParameters: ReferentialGetReportByModuleIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetReportByModuleId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetReportByModuleId(requestParameters: ReferentialGetReportByModuleIdRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetReportByModuleIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetRepresentantLiensRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetRepresentantLiens`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetRepresentantLiens(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetRepresentantLiensRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetRepresentantTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetRepresentantTypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetRepresentantTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetRepresentantTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetRepresentantsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Representants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetRepresentants(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetRepresentantsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetResultatAcquisRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetResultatAcquis`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetResultatAcquis(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetResultatAcquisRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetResultatDossierAdmissionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetResultatDossierAdmissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetResultatDossierAdmissions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetResultatDossierAdmissionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetResultatExamenEntreeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetResultatExamenEntree`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetResultatExamenEntree(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetResultatExamenEntreeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetResultatExamenEntreeFceRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetResultatExamenEntreeFce`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetResultatExamenEntreeFce(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetResultatExamenEntreeFceRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetResultatFormationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetResultatFormations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetResultatFormations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetResultatFormationsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetResultatPermisRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetResultatPermis`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetResultatPermis(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetResultatPermisRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetResultatValidesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetResultatValides`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetResultatValides(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetResultatValidesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetSSRSReportFormatsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetSSRSReportFormats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetSSRSReportFormats(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetSSRSReportFormatsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetSameCentreUsersRaw(requestParameters: ReferentialGetSameCentreUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.currentId !== undefined) {
            queryParameters['currentId'] = requestParameters.currentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetSameCentreUsers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetSameCentreUsers(requestParameters: ReferentialGetSameCentreUsersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetSameCentreUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetSecteurRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Secteur`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetSecteur(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetSecteurRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetSecteurByIdsecteurCreaRaw(requestParameters: ReferentialGetSecteurByIdsecteurCreaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idsecteurCrea !== undefined) {
            queryParameters['idsecteurCrea'] = requestParameters.idsecteurCrea;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/SecteurByIdsecteurCrea`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetSecteurByIdsecteurCrea(requestParameters: ReferentialGetSecteurByIdsecteurCreaRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetSecteurByIdsecteurCreaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetSecteurCreaRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetSecteurCrea`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetSecteurCrea(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetSecteurCreaRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetSecteurHomogeneByDegreRaw(requestParameters: ReferentialGetSecteurHomogeneByDegreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idDegre !== undefined) {
            queryParameters['idDegre'] = requestParameters.idDegre;
        }

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/SecteurHomogeneByDegre`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetSecteurHomogeneByDegre(requestParameters: ReferentialGetSecteurHomogeneByDegreRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetSecteurHomogeneByDegreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetServiceTutelleRaw(requestParameters: ReferentialGetServiceTutelleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.userFilter !== undefined) {
            queryParameters['userFilter'] = requestParameters.userFilter;
        }

        if (requestParameters.ifapmeSide !== undefined) {
            queryParameters['ifapmeSide'] = requestParameters.ifapmeSide;
        }

        if (requestParameters.currentId !== undefined) {
            queryParameters['currentId'] = requestParameters.currentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/ServiceTutelle`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetServiceTutelle(requestParameters: ReferentialGetServiceTutelleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetServiceTutelleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetServiceUsersRaw(requestParameters: ReferentialGetServiceUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.currentId !== undefined) {
            queryParameters['currentId'] = requestParameters.currentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetServiceUsers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request_body,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetServiceUsers(requestParameters: ReferentialGetServiceUsersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetServiceUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetServiceUsersByRolesRaw(requestParameters: ReferentialGetServiceUsersByRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idService !== undefined) {
            queryParameters['idService'] = requestParameters.idService;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetServiceUsersByRoles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.ELevelName.map(ELevelNameToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetServiceUsersByRoles(requestParameters: ReferentialGetServiceUsersByRolesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetServiceUsersByRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetServicesByIdApprenantRaw(requestParameters: ReferentialGetServicesByIdApprenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idApprenant !== undefined) {
            queryParameters['idApprenant'] = requestParameters.idApprenant;
        }

        if (requestParameters.ifapmeSide !== undefined) {
            queryParameters['ifapmeSide'] = requestParameters.ifapmeSide;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetServicesByIdApprenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetServicesByIdApprenant(requestParameters: ReferentialGetServicesByIdApprenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetServicesByIdApprenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetSexeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Sexe`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetSexe(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetSexeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetSiegeLieuTypesSuiviRaw(requestParameters: ReferentialGetSiegeLieuTypesSuiviRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.currentId !== undefined) {
            queryParameters['currentId'] = requestParameters.currentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetSiegeLieuTypesSuivi`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetSiegeLieuTypesSuivi(requestParameters: ReferentialGetSiegeLieuTypesSuiviRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetSiegeLieuTypesSuiviRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetSiegeSociauxRaw(requestParameters: ReferentialGetSiegeSociauxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idsiegeSocial !== undefined) {
            queryParameters['idsiegeSocial'] = requestParameters.idsiegeSocial;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetSiegeSociaux`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetSiegeSociaux(requestParameters: ReferentialGetSiegeSociauxRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetSiegeSociauxRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetSocietesExternesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetSocietesExternes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetSocietesExternes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetSocietesExternesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetSocietesExternesByMetierRaw(requestParameters: ReferentialGetSocietesExternesByMetierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idmetier !== undefined) {
            queryParameters['idmetier'] = requestParameters.idmetier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetSocietesExternesByMetier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetSocietesExternesByMetier(requestParameters: ReferentialGetSocietesExternesByMetierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetSocietesExternesByMetierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetSousStatutsByCategorieRaw(requestParameters: ReferentialGetSousStatutsByCategorieRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idCategorie !== undefined) {
            queryParameters['idCategorie'] = requestParameters.idCategorie;
        }

        if (requestParameters.currentIdstatutSocial !== undefined) {
            queryParameters['currentIdstatutSocial'] = requestParameters.currentIdstatutSocial;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetSousStatutsByCategorie`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetSousStatutsByCategorie(requestParameters: ReferentialGetSousStatutsByCategorieRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetSousStatutsByCategorieRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetSousTypeSuivisRaw(requestParameters: ReferentialGetSousTypeSuivisRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idtypeSuivi !== undefined) {
            queryParameters['idtypeSuivi'] = requestParameters.idtypeSuivi;
        }

        if (requestParameters.idsousTypeSuivi !== undefined) {
            queryParameters['idsousTypeSuivi'] = requestParameters.idsousTypeSuivi;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetSousTypeSuivis`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetSousTypeSuivis(requestParameters: ReferentialGetSousTypeSuivisRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetSousTypeSuivisRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetSpecificiteCoursRaw(requestParameters: ReferentialGetSpecificiteCoursRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/SpecificiteCours`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetSpecificiteCours(requestParameters: ReferentialGetSpecificiteCoursRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetSpecificiteCoursRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetSpecificiteCoursCodesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/SpecificiteCoursCodes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetSpecificiteCoursCodes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetSpecificiteCoursCodesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetStadeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Stade`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetStade(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetStadeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetStadeByMetierRaw(requestParameters: ReferentialGetStadeByMetierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idmetier !== undefined) {
            queryParameters['idmetier'] = requestParameters.idmetier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/StadeByMetier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetStadeByMetier(requestParameters: ReferentialGetStadeByMetierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetStadeByMetierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetStadeContratDMRaw(requestParameters: ReferentialGetStadeContratDMRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.currentId !== undefined) {
            queryParameters['currentId'] = requestParameters.currentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/StadeContratDM`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetStadeContratDM(requestParameters: ReferentialGetStadeContratDMRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetStadeContratDMRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetStadeCreaRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetStadeCrea`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetStadeCrea(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetStadeCreaRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetStatutContratWalterRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetStatutContratWalter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetStatutContratWalter(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetStatutContratWalterRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetStatutCreaRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetStatutCrea`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetStatutCrea(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetStatutCreaRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetStatutInscriptionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/StatutInscription`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetStatutInscription(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetStatutInscriptionRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetStatutLocalisationAgrementRaw(requestParameters: ReferentialGetStatutLocalisationAgrementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.isLocalisation !== undefined) {
            queryParameters['isLocalisation'] = requestParameters.isLocalisation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/statutLocalisationAgrement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetStatutLocalisationAgrement(requestParameters: ReferentialGetStatutLocalisationAgrementRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetStatutLocalisationAgrementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetStatutSocialRaw(requestParameters: ReferentialGetStatutSocialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.currentIdstatutSocial !== undefined) {
            queryParameters['currentIdstatutSocial'] = requestParameters.currentIdstatutSocial;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/StatutSocial`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetStatutSocial(requestParameters: ReferentialGetStatutSocialRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetStatutSocialRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetStatutStageRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/StatutStage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetStatutStage(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetStatutStageRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetStatutsProspectRaw(requestParameters: ReferentialGetStatutsProspectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetStatutsProspect`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetStatutsProspect(requestParameters: ReferentialGetStatutsProspectRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetStatutsProspectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTaillesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetTailles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTailles(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTaillesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTelephonesPersonneRaw(requestParameters: ReferentialGetTelephonesPersonneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idpersonne !== undefined) {
            queryParameters['idpersonne'] = requestParameters.idpersonne;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetTelephonesPersonne`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTelephonesPersonne(requestParameters: ReferentialGetTelephonesPersonneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTelephonesPersonneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTitreDiplomeCertificationsRaw(requestParameters: ReferentialGetTitreDiplomeCertificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.currentId !== undefined) {
            queryParameters['currentId'] = requestParameters.currentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetTitreDiplomeCertifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTitreDiplomeCertifications(requestParameters: ReferentialGetTitreDiplomeCertificationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTitreDiplomeCertificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTitreDiplomeCertificationsIfapmeRaw(requestParameters: ReferentialGetTitreDiplomeCertificationsIfapmeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.currentId !== undefined) {
            queryParameters['currentId'] = requestParameters.currentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetTitreDiplomeCertificationsIfapme`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTitreDiplomeCertificationsIfapme(requestParameters: ReferentialGetTitreDiplomeCertificationsIfapmeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTitreDiplomeCertificationsIfapmeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTitresPedagogiquesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/TitresPedagogiques`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTitresPedagogiques(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTitresPedagogiquesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypeContratFormateurRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/Type`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypeContratFormateur(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypeContratFormateurRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypeCoursRaw(requestParameters: ReferentialGetTypeCoursRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.ifapmeSide !== undefined) {
            queryParameters['ifapmeSide'] = requestParameters.ifapmeSide;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/TypeCours`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypeCours(requestParameters: ReferentialGetTypeCoursRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypeCoursRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypeCoursCodesRaw(requestParameters: ReferentialGetTypeCoursCodesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.ifapmeSide !== undefined) {
            queryParameters['ifapmeSide'] = requestParameters.ifapmeSide;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/TypeCoursCodes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypeCoursCodes(requestParameters: ReferentialGetTypeCoursCodesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypeCoursCodesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypeCoursIdRaw(requestParameters: ReferentialGetTypeCoursIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.ifapmeSide !== undefined) {
            queryParameters['ifapmeSide'] = requestParameters.ifapmeSide;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/TypeCoursIds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypeCoursId(requestParameters: ReferentialGetTypeCoursIdRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypeCoursIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypeDispenseRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/TypeDispense`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypeDispense(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypeDispenseRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypeEnseignementRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/TypeEnseignement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypeEnseignement(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypeEnseignementRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypeEvaluationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/TypeEvaluation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypeEvaluation(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypeEvaluationRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypeEvaluationByClasseRaw(requestParameters: ReferentialGetTypeEvaluationByClasseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idEvaluation !== undefined) {
            queryParameters['idEvaluation'] = requestParameters.idEvaluation;
        }

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        if (requestParameters.idClasseMatiere !== undefined) {
            queryParameters['idClasseMatiere'] = requestParameters.idClasseMatiere;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/TypeEvaluationByClasse`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypeEvaluationByClasse(requestParameters: ReferentialGetTypeEvaluationByClasseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypeEvaluationByClasseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypeFinancementRaw(requestParameters: ReferentialGetTypeFinancementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.currentId !== undefined) {
            queryParameters['currentId'] = requestParameters.currentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/TypeFinancement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypeFinancement(requestParameters: ReferentialGetTypeFinancementRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypeFinancementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypeJustificatifsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/TypeJustificatifs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypeJustificatifs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypeJustificatifsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypeLotSmsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/TypeLotSms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypeLotSms(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypeLotSmsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypeRemplacementRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/TypeRemplacement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypeRemplacement(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypeRemplacementRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypeReussiteRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/TypeReussite`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypeReussite(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypeReussiteRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypeSuiviRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/TypeSuivi`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypeSuivi(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypeSuiviRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypeSuiviActionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetTypeSuiviActions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypeSuiviActions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypeSuiviActionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypeSuivisRaw(requestParameters: ReferentialGetTypeSuivisRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.side !== undefined) {
            queryParameters['side'] = requestParameters.side;
        }

        if (requestParameters.idtypeSuivi !== undefined) {
            queryParameters['idtypeSuivi'] = requestParameters.idtypeSuivi;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetTypeSuivis`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypeSuivis(requestParameters: ReferentialGetTypeSuivisRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypeSuivisRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypesAcquisFichierRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetTypesAcquisFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypesAcquisFichier(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypesAcquisFichierRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypesAdresseRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetTypesAdresse`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypesAdresse(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypesAdresseRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypesApprenantFichierRaw(requestParameters: ReferentialGetTypesApprenantFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetTypesApprenantFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.ETypeApprenantFichier.map(ETypeApprenantFichierToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypesApprenantFichier(requestParameters: ReferentialGetTypesApprenantFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypesApprenantFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypesAvenantRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetTypesAvenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypesAvenant(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypesAvenantRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypesBulletinRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetTypesBulletin`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypesBulletin(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypesBulletinRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypesContratFichierRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetTypesContratFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypesContratFichier(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypesContratFichierRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypesDiplomeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/TypesDiplome`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypesDiplome(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypesDiplomeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypesEmailRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetTypesEmail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypesEmail(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypesEmailRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypesEntrepriseRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetTypesEntreprise`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypesEntreprise(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypesEntrepriseRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypesLieuFormationFichierRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetTypesLieuFormationFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypesLieuFormationFichier(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypesLieuFormationFichierRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypesSiegeSocialFichierRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetTypesSiegeSocialFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypesSiegeSocialFichier(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypesSiegeSocialFichierRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypesSortieRaw(requestParameters: ReferentialGetTypesSortieRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.currentId !== undefined) {
            queryParameters['currentId'] = requestParameters.currentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetTypesSortie`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypesSortie(requestParameters: ReferentialGetTypesSortieRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypesSortieRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypesSuiviFichierRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetTypesSuiviFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypesSuiviFichier(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypesSuiviFichierRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetTypesTelephoneRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetTypesTelephone`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetTypesTelephone(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetTypesTelephoneRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetUsersByRolesRaw(requestParameters: ReferentialGetUsersByRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.currentIduser !== undefined) {
            queryParameters['currentIduser'] = requestParameters.currentIduser;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetUsersByRoles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.ELevelName.map(ELevelNameToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetUsersByRoles(requestParameters: ReferentialGetUsersByRolesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetUsersByRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetUsersByRolesAndApprenantRaw(requestParameters: ReferentialGetUsersByRolesAndApprenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/GetUsersByRolesAndApprenant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.ELevelName.map(ELevelNameToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetUsersByRolesAndApprenant(requestParameters: ReferentialGetUsersByRolesAndApprenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetUsersByRolesAndApprenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialGetWalterAccessLevelsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/WalterAccessLevels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialGetWalterAccessLevels(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialGetWalterAccessLevelsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialLocalsByMatiereRaw(requestParameters: ReferentialLocalsByMatiereRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasseMatiere !== undefined) {
            queryParameters['idClasseMatiere'] = requestParameters.idClasseMatiere;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/LocalsByMatiere`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialLocalsByMatiere(requestParameters: ReferentialLocalsByMatiereRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialLocalsByMatiereRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialMetierByStadeForApprenantRaw(requestParameters: ReferentialMetierByStadeForApprenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idStade !== undefined) {
            queryParameters['idStade'] = requestParameters.idStade;
        }

        if (requestParameters.idApprenant !== undefined) {
            queryParameters['idApprenant'] = requestParameters.idApprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/MetierByStadeForApprenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialMetierByStadeForApprenant(requestParameters: ReferentialMetierByStadeForApprenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialMetierByStadeForApprenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialMetierByStadeForContratRaw(requestParameters: ReferentialMetierByStadeForContratRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idStade !== undefined) {
            queryParameters['idStade'] = requestParameters.idStade;
        }

        if (requestParameters.currentId !== undefined) {
            queryParameters['currentId'] = requestParameters.currentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/MetierByStadeForContrat`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialMetierByStadeForContrat(requestParameters: ReferentialMetierByStadeForContratRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialMetierByStadeForContratRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentialMetierNotAPRaw(requestParameters: ReferentialMetierNotAPRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReferentialResultInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.currentId !== undefined) {
            queryParameters['currentId'] = requestParameters.currentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referential/MetierNotAP`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferentialResultInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentialMetierNotAP(requestParameters: ReferentialMetierNotAPRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReferentialResultInfoDto> {
        const response = await this.referentialMetierNotAPRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
