/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbFormateurMetierAgrementLiesDto,
  FcbMetierAgrementGridDtoPaginatedResults,
  FilterCriteriaInfo,
  FormateursMetiersAgrementSearch,
  SelectItem,
} from '../models/index';
import {
    FcbFormateurMetierAgrementLiesDtoFromJSON,
    FcbFormateurMetierAgrementLiesDtoToJSON,
    FcbMetierAgrementGridDtoPaginatedResultsFromJSON,
    FcbMetierAgrementGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    FormateursMetiersAgrementSearchFromJSON,
    FormateursMetiersAgrementSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface FormateurMetierAgrementBaseSearchRequest {
    FormateursMetiersAgrementSearch?: FormateursMetiersAgrementSearch;
}

export interface FormateurMetierAgrementGetMetiersAgrementRequest {
    idformateurAgrement?: number;
}

export interface FormateurMetierAgrementGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface FormateurMetierAgrementGetSelectItemsRequest {
    searchField: string;
}

export interface FormateurMetierAgrementSaveMetierAgrementRequest {
    FcbFormateurMetierAgrementLiesDto?: FcbFormateurMetierAgrementLiesDto;
}

/**
 * 
 */
export class FormateurMetierAgrementApi extends runtime.BaseAPI {

    /**
     */
    async formateurMetierAgrementBaseSearchRaw(requestParameters: FormateurMetierAgrementBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbMetierAgrementGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurMetierAgrement/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormateursMetiersAgrementSearchToJSON(requestParameters.FormateursMetiersAgrementSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbMetierAgrementGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async formateurMetierAgrementBaseSearch(requestParameters: FormateurMetierAgrementBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbMetierAgrementGridDtoPaginatedResults> {
        const response = await this.formateurMetierAgrementBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurMetierAgrementGetMetiersAgrementRaw(requestParameters: FormateurMetierAgrementGetMetiersAgrementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurMetierAgrementLiesDto>> {
        const queryParameters: any = {};

        if (requestParameters.idformateurAgrement !== undefined) {
            queryParameters['idformateurAgrement'] = requestParameters.idformateurAgrement;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurMetierAgrement/GetMetierAgrement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurMetierAgrementLiesDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurMetierAgrementGetMetiersAgrement(requestParameters: FormateurMetierAgrementGetMetiersAgrementRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurMetierAgrementLiesDto> {
        const response = await this.formateurMetierAgrementGetMetiersAgrementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurMetierAgrementGetSearchCriteriasRaw(requestParameters: FormateurMetierAgrementGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurMetierAgrement/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async formateurMetierAgrementGetSearchCriterias(requestParameters: FormateurMetierAgrementGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.formateurMetierAgrementGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurMetierAgrementGetSelectItemsRaw(requestParameters: FormateurMetierAgrementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling formateurMetierAgrementGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurMetierAgrement/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async formateurMetierAgrementGetSelectItems(requestParameters: FormateurMetierAgrementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.formateurMetierAgrementGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurMetierAgrementSaveMetierAgrementRaw(requestParameters: FormateurMetierAgrementSaveMetierAgrementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurMetierAgrementLiesDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurMetierAgrement/SaveMetierAgrement`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbFormateurMetierAgrementLiesDtoToJSON(requestParameters.FcbFormateurMetierAgrementLiesDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurMetierAgrementLiesDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurMetierAgrementSaveMetierAgrement(requestParameters: FormateurMetierAgrementSaveMetierAgrementRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurMetierAgrementLiesDto> {
        const response = await this.formateurMetierAgrementSaveMetierAgrementRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
