import { Callout } from "@blueprintjs/core";
import { format } from "date-fns";
import { FieldSet } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { CarouselComparer, DoublonsMultipleDataCard, TU_FINAL_KEY, useDoublonsStateContext } from "..";
import { TuteurDoublonEditDto, TuteurDoublonLieuFormationDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BooleanColumn, ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

const Container = styled.div`
  height: 70vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface IDoublonTuteurLieuxFormationProps {}

export const DoublonTuteurLieuxFormation: React.FunctionComponent<IDoublonTuteurLieuxFormationProps> = props => {
  const { t } = useTl();
  const { data, getSelectedIndex } = useDoublonsStateContext();

  const selectedIndex = React.useMemo(() => getSelectedIndex(`${TU_FINAL_KEY}.idtuteur`) ?? 0, [getSelectedIndex]);

  const tuteurContainer = React.useCallback(
    (index: number, nb: number) => {
      const tuteur: TuteurDoublonEditDto = data?.tuteurs[index];
      if (!tuteur) return;

      return (
        <FieldSet
          key={tuteur.idtuteur}
          title={
            <ViewButton
              text={t(ETLCodes.TuteurNb, { nb: nb, id: tuteur.idtuteur })}
              onClick={() => window.open(`#${ERoutes.tuteur}/${tuteur.idtuteur}/detail/view`, "_blank")}
            />
          }
          titlePosition="center"
        >
          <DoublonsMultipleDataCard
            labelPredicate={(l: TuteurDoublonLieuFormationDto) => l.nom}
            fieldPath={`tuteurs[${index}].lieuxFormation`}
            interactive={() => false}
            forceSelected={() => false}
            finalEntityKey={TU_FINAL_KEY}
          >
            {(l: TuteurDoublonLieuFormationDto) => (
              <InformationContainer key={l.idtuteurLieu}>
                <div>
                  {t(ETLCodes.Enseigne)}: {l.enseigne}
                </div>
                <div>
                  {t(ETLCodes.Fonction)}: {l.fonction}
                </div>
                <div>
                  {t(ETLCodes.CapaciteFormative)}: {l.capaciteFormative}
                </div>
                <div>
                  {t(ETLCodes.TuteurPrincipal)}: <BooleanColumn value={l.contactPrincipal} />
                </div>
                <div>
                  {t(ETLCodes.SortieEntreprise)}: {l.sortieEntreprise && format(l.sortieEntreprise, "dd-MM-yyyy")}
                </div>
              </InformationContainer>
            )}
          </DoublonsMultipleDataCard>
        </FieldSet>
      );
    },
    [data?.tuteurs, t]
  );

  return (
    <Container>
      <Callout
        intent="warning"
        title={t(ETLCodes.DoublonTuteurLieuFormationCumules)}
        style={{ marginBottom: "1rem" }}
        icon="info-sign"
      />
      <CarouselComparer leftElement={tuteurContainer(selectedIndex, selectedIndex + 1)}>
        {data?.tuteurs?.length > 1 &&
          data?.tuteurs
            ?.filter((a, i) => i !== selectedIndex)
            ?.map((a, i) => tuteurContainer(i + (i >= selectedIndex ? 1 : 0), i + (i >= selectedIndex ? 2 : 1)))}
      </CarouselComparer>
    </Container>
  );
};
