/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbInscriptionMasseDto,
  FcbInscriptionMasseGridDtoPaginatedResults,
  FilterCriteriaInfo,
  InscriptionMasseSearch,
  SelectItem,
} from '../models/index';
import {
    FcbInscriptionMasseDtoFromJSON,
    FcbInscriptionMasseDtoToJSON,
    FcbInscriptionMasseGridDtoPaginatedResultsFromJSON,
    FcbInscriptionMasseGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    InscriptionMasseSearchFromJSON,
    InscriptionMasseSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface InscriptionMasseBaseSearchRequest {
    InscriptionMasseSearch?: InscriptionMasseSearch;
}

export interface InscriptionMasseGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface InscriptionMasseGetSelectItemsRequest {
    searchField: string;
}

export interface InscriptionMasseSaveOneByOneRequest {
    FcbInscriptionMasseDto?: FcbInscriptionMasseDto;
}

export interface InscriptionMasseSearchApprenantNotInClasseRequest {
    InscriptionMasseSearch?: InscriptionMasseSearch;
}

export interface InscriptionMasseSearchInscriptionIdsRequest {
    InscriptionMasseSearch?: InscriptionMasseSearch;
}

/**
 * 
 */
export class InscriptionMasseApi extends runtime.BaseAPI {

    /**
     */
    async inscriptionMasseBaseSearchRaw(requestParameters: InscriptionMasseBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbInscriptionMasseGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/InscriptionMasse/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InscriptionMasseSearchToJSON(requestParameters.InscriptionMasseSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbInscriptionMasseGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async inscriptionMasseBaseSearch(requestParameters: InscriptionMasseBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbInscriptionMasseGridDtoPaginatedResults> {
        const response = await this.inscriptionMasseBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionMasseGetBaseDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbInscriptionMasseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/InscriptionMasse/GetBaseData`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbInscriptionMasseDtoFromJSON(jsonValue));
    }

    /**
     */
    async inscriptionMasseGetBaseData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbInscriptionMasseDto> {
        const response = await this.inscriptionMasseGetBaseDataRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionMasseGetSearchCriteriasRaw(requestParameters: InscriptionMasseGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/InscriptionMasse/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async inscriptionMasseGetSearchCriterias(requestParameters: InscriptionMasseGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.inscriptionMasseGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionMasseGetSelectItemsRaw(requestParameters: InscriptionMasseGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling inscriptionMasseGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/InscriptionMasse/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async inscriptionMasseGetSelectItems(requestParameters: InscriptionMasseGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.inscriptionMasseGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionMasseSaveOneByOneRaw(requestParameters: InscriptionMasseSaveOneByOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbInscriptionMasseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/InscriptionMasse/SaveOneByOne`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbInscriptionMasseDtoToJSON(requestParameters.FcbInscriptionMasseDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbInscriptionMasseDtoFromJSON(jsonValue));
    }

    /**
     */
    async inscriptionMasseSaveOneByOne(requestParameters: InscriptionMasseSaveOneByOneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbInscriptionMasseDto> {
        const response = await this.inscriptionMasseSaveOneByOneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionMasseSearchApprenantNotInClasseRaw(requestParameters: InscriptionMasseSearchApprenantNotInClasseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbInscriptionMasseGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/InscriptionMasse/SearchApprenantNotInClasse`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InscriptionMasseSearchToJSON(requestParameters.InscriptionMasseSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbInscriptionMasseGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async inscriptionMasseSearchApprenantNotInClasse(requestParameters: InscriptionMasseSearchApprenantNotInClasseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbInscriptionMasseGridDtoPaginatedResults> {
        const response = await this.inscriptionMasseSearchApprenantNotInClasseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionMasseSearchInscriptionIdsRaw(requestParameters: InscriptionMasseSearchInscriptionIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/InscriptionMasse/SearchInscriptionIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InscriptionMasseSearchToJSON(requestParameters.InscriptionMasseSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async inscriptionMasseSearchInscriptionIds(requestParameters: InscriptionMasseSearchInscriptionIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.inscriptionMasseSearchInscriptionIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
