import { Classes, Switch } from "@blueprintjs/core";
import { format } from "date-fns";
import { ButtonContainer, IDataTableColumn, ToolTipButton } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { DashboardAbsencesVuDialog } from ".";
import { DashboardAbsenceDto, DashboardAbsenceSearch, DashboardApi } from "../../../api";
import { ERoutes } from "../../../AppRouter";
import { LinkButton, SearchTablePage, ViewButton } from "../../../components";
import { useAuth, useDialog, useEventsContext } from "../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTabMessage, useTheme, useTl } from "../../../hooks";
import { ETLCodes } from "../../../locales";
import { Guid } from "../../../utils/guid";

const StyledSwitch = styled(Switch)<{ color: string }>`
  & .${Classes.SWITCH} {
    &:hover {
      input:checked ~ .${Classes.CONTROL_INDICATOR} {
        background: ${props => props.color};
      }
    }
  }
`;

export interface IDashboardAbsencesProps {}

export const DashboardAbsences: React.FunctionComponent<IDashboardAbsencesProps> = props => {
  const { t } = useTl();
  const { user } = useAuth();
  const api = useApiService(DashboardApi);
  const apiFactory = useAbortableApiServiceFactory(DashboardApi);
  const lastAbortController = React.useRef<AbortController>();
  const [displayHidden, setDisplayHidden] = React.useState(false);
  const { theme } = useTheme();

  const searchFunction = React.useCallback(
    (sObj?: DashboardAbsenceSearch) => {
      sObj.hideMasquees = !displayHidden;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.dashboardSearchAbsences({ DashboardAbsenceSearch: sObj });
    },
    [apiFactory, displayHidden]
  );

  const isCrea = React.useMemo(() => user.hopeMainLevelName === "Conseiller CREA", [user.hopeMainLevelName]);

  const [rowLoading, setRowLoading] = React.useState<DashboardAbsenceDto>(null);

  const { showDialogPromise } = useDialog();
  const { dispatchEvent } = useEventsContext();

  const [currentSuiviData, setCurrentSuiviData] = React.useState<DashboardAbsenceDto>(null);
  const identifier = React.useMemo(() => Guid.newGuid(), []);

  const { openTabThenSendMessage } = useTabMessage(identifier);

  const marquerCommeVuOpenDialog = React.useCallback((row: DashboardAbsenceDto) => {
    setCurrentSuiviData(row);
  }, []);

  const marquerCommeVu = React.useCallback(
    async (dto: DashboardAbsenceDto) => {
      setCurrentSuiviData(null);
      if (!dto) {
        return;
      }

      openTabThenSendMessage(`#${ERoutes.apprenant}/${dto.idapprenant}/suiviApprenant/0/edit/hope`, dto);
    },
    [openTabThenSendMessage]
  );

  const masquer = React.useCallback(
    async (row: DashboardAbsenceDto) => {
      const res = await showDialogPromise({
        title: t(ETLCodes.MarquerAbsenceCommeVueConfirmTitle),
        message: t(ETLCodes.MarquerAbsenceCommeVueConfirmMessage)
      });
      if (res === "yes") {
        setRowLoading(row);
        await api.dashboardMasquerAbsence({ idapprenant: row.idapprenant, anneeScolaire: row.anneeScolaire });
        setRowLoading(null);
        dispatchEvent("SEARCH_TABLE_REFRESH");
        dispatchEvent("DASHBOARD_COUNTS_REFRESH_Absence");
      }
    },
    [api, dispatchEvent, showDialogPromise, t]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: DashboardAbsenceDto) => (
          <ButtonContainer>
            {!isCrea && (
              <ToolTipButton
                minimal
                icon="tick"
                onClick={() => marquerCommeVuOpenDialog(row)}
                tooltipPosition="bottom"
                loading={
                  !!rowLoading &&
                  rowLoading.idapprenant === row.idapprenant &&
                  rowLoading.anneeScolaire === row.anneeScolaire
                }
                disabled={
                  !!rowLoading &&
                  (rowLoading.idapprenant !== row.idapprenant || rowLoading.anneeScolaire !== row.anneeScolaire)
                }
                tooltip={t(ETLCodes.AvertirConvoquer)}
              />
            )}
            {(!row.idsuserMasquage || !row.idsuserMasquage.includes(`|${user.iduser}|`)) && (
              <ToolTipButton
                tooltipPosition="bottom"
                tooltip={t(ETLCodes.MarquerCommeVu)}
                minimal
                icon="cross"
                onClick={() => masquer(row)}
                loading={
                  !!rowLoading &&
                  rowLoading.idapprenant === row.idapprenant &&
                  rowLoading.anneeScolaire === row.anneeScolaire
                }
                disabled={
                  !!rowLoading &&
                  (rowLoading.idapprenant !== row.idapprenant || rowLoading.anneeScolaire !== row.anneeScolaire)
                }
              />
            )}
            <ViewButton
              minimal={true}
              onClick={() => window.open(`#${ERoutes.apprenant}/${row.idapprenant}/Absence/view`, "_blank")}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Apprenant),
        fieldName: "apprenant",
        render: (row: DashboardAbsenceDto) => (
          <LinkButton
            text={row.apprenant}
            onClick={() => window.open(`#${ERoutes.apprenant}/${row.idapprenant}/signaletique/view`)}
          />
        )
      },
      {
        header: () => t(ETLCodes.MetierStade),
        fieldName: "metierStade"
      },
      {
        header: () => t(ETLCodes.AbsencesInjustifiees),
        fieldName: "absencesInjustifiees"
      },
      {
        header: () => t(ETLCodes.DeltaDernierAvertissement),
        fieldName: "deltaDernierAvertissement"
      },
      {
        header: () => t(ETLCodes.DernierAvertissement),
        fieldName: "dernierAvertissement",
        render: (row: DashboardAbsenceDto) =>
          row.dernierAvertissement &&
          `${format(row.dernierAvertissement, "dd/MM/yyyy")} - ${row.typeDernierAvertissement}`
      },
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "anneeScolaire"
      },
      {
        header: () => t(ETLCodes.EmailsRepresentants),
        fieldName: "emailsRepresentantsLegaux"
      },
      {
        header: () => t(ETLCodes.EmailsContacts),
        fieldName: "emailsContactsPrincipaux"
      }
    ],
    [isCrea, marquerCommeVuOpenDialog, masquer, rowLoading, t, user.iduser]
  );

  const getCriteria = React.useCallback(async () => {
    return await api.dashboardGetAbsencesCriterias();
  }, [api]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <SearchTablePage
        searchStateInitialSearch={false}
        withCard={false}
        columns={columns}
        getCriteriasFunction={getCriteria}
        searchFunction={searchFunction}
        onAbort={onAbort}
        sortKeys={{ apprenant: "ASC" }}
        rightElement={
          <StyledSwitch
            color={theme.primaryColor}
            label={t(ETLCodes.AfficherMasquees)}
            checked={displayHidden}
            onChange={e => {
              setDisplayHidden(e.currentTarget.checked);
            }}
          ></StyledSwitch>
        }
      />
      {currentSuiviData && (
        <DashboardAbsencesVuDialog
          currentRow={currentSuiviData}
          dialogOpen={!!currentSuiviData}
          onClose={marquerCommeVu}
        />
      )}
    </>
  );
};
