import { Classes, Dialog } from "@blueprintjs/core";
import { FieldGroup, showInfo } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";

import {
  ApprenantApi,
  FichierApi,
  GetApprenantAvisResultatsIdsfichierDto,
  GetApprenantAvisResultatsIdsfichierDtoFromJSON
} from "../../../../api";
import { FGWalterSelectInput, SmallFormGenerator } from "../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";
import { ETLCodes } from "../../../../locales";
import { b64toBlob } from "../../../../utils";

const StyledDialog = styled(Dialog)`
  width: 700px;
  height: auto;
  background-color: white;
`;

export interface IDownloadAvisValidesDialogProps {
  idsApprenant: number[];
  dialogOpen: boolean;
  onClose: () => void;
  is2sess: boolean;
}

export const DownloadAvisValidesDialog: React.FunctionComponent<IDownloadAvisValidesDialogProps> = ({
  dialogOpen,
  idsApprenant,
  is2sess,
  onClose
}) => {
  const { t } = useTl();
  const api = useApiService(ApprenantApi);
  const fApi = useApiService(FichierApi);

  const saveFn = React.useCallback(
    async (d: GetApprenantAvisResultatsIdsfichierDto) => {
      const idsfichier = await api.apprenantGetAvisResultatsIdsfichier({
        GetApprenantAvisResultatsIdsfichierDto: d
      });
      if (idsfichier.length === 0) {
        showInfo(t(ETLCodes.AucunFichierATelecharger));
        return;
      }
      const zip = await fApi.fichierMassDownloadPdf({
        MassDownloadRequestDto: { idsfichier: idsfichier }
      });
      if (!zip) {
        showInfo(t(ETLCodes.AucunFichierATelecharger));
        return;
      }
      saveAs(b64toBlob(zip), zip.fileName);

      return d;
    },
    [api, fApi, t]
  );

  const { data, loading, saveItem, saving, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () => {
          return GetApprenantAvisResultatsIdsfichierDtoFromJSON({
            idsApprenant,
            is2Sess: is2sess
          });
        },
        saveApiFn: saveFn,
        onSaved: () => onClose()
      }),
      [idsApprenant, is2sess, onClose, saveFn]
    )
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape({
        anneeScolaire: Yup.string()
          .nullable()
          .required(t(ETLCodes.Required))
      }),
    [t]
  );

  const [anneesScolaires, anneesScolairesLoading] = useReferential(a => a.referentialGetAnneeScolaire());
  const finalAnneesScolaires = React.useMemo(
    () => anneesScolaires?.filter(a => +(a.value + "").split("-")[0] >= 2023),
    [anneesScolaires]
  );

  return (
    <StyledDialog
      title={t(is2sess ? ETLCodes.DownloadAvisResultats2S : ETLCodes.DownloadAvisResultats1S)}
      isOpen={dialogOpen}
      onClose={() => onClose()}
    >
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose()}
          showDeleteButton={false}
          saving={saving}
          loading={loading}
          validationErrors={validationErrors}
        >
          <FieldGroup>
            <FGWalterSelectInput
              name="anneeScolaire"
              label={t(ETLCodes.AnneeScolaire)}
              items={finalAnneesScolaires}
              loading={anneesScolairesLoading}
            />
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};

export default DownloadAvisValidesDialog;
