/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbSoldeGridDto
 */
export interface FcbSoldeGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbSoldeGridDto
     */
    nomCentre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbSoldeGridDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbSoldeGridDto
     */
    totalMontantDu?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbSoldeGridDto
     */
    totalMontantPaye?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbSoldeGridDto
     */
    totalMinerval?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbSoldeGridDto
     */
    idcentre?: number;
}

/**
 * Check if a given object implements the FcbSoldeGridDto interface.
 */
export function instanceOfFcbSoldeGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbSoldeGridDtoFromJSON(json: any): FcbSoldeGridDto {
    return FcbSoldeGridDtoFromJSONTyped(json, false);
}

export function FcbSoldeGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbSoldeGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nomCentre': !exists(json, 'nomCentre') ? undefined : json['nomCentre'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'totalMontantDu': !exists(json, 'totalMontantDu') ? undefined : json['totalMontantDu'],
        'totalMontantPaye': !exists(json, 'totalMontantPaye') ? undefined : json['totalMontantPaye'],
        'totalMinerval': !exists(json, 'totalMinerval') ? undefined : json['totalMinerval'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
    };
}

export function FcbSoldeGridDtoToJSON(value?: FcbSoldeGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nomCentre': value.nomCentre,
        'anneeScolaire': value.anneeScolaire,
        'totalMontantDu': value.totalMontantDu,
        'totalMontantPaye': value.totalMontantPaye,
        'totalMinerval': value.totalMinerval,
        'idcentre': value.idcentre,
    };
}

