import { Intent } from "@blueprintjs/core";
import { DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { FcbFormationGridDto, FormationApi, FormationSearch } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { AddButton, EditButton, ViewButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

export interface IApprenantFormationSearchProps {}

export const ApprenantFormationSearch: React.FunctionComponent<IApprenantFormationSearchProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const apprenantId = React.useMemo(() => +id, [id]);
  const api = useApiService(FormationApi);
  const path = React.useMemo(() => `${ERoutes.apprenant}/${apprenantId}/formation`, [apprenantId]);

  const [metier] = useReferential(a => a.referentialGetMetier(), true, [], false);
  const [stade] = useReferential(a => a.referentialGetStade(), true, [], false);
  const [statutStage] = useReferential(a => a.referentialGetStatutStage(), true, [], false);
  const [statutSocial] = useReferential(a => a.referentialGetStatutSocial({}), true, [], false);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { idmetier: "ASC" }
  });

  const { totalCount } = tableState;

  const searchFunc = React.useCallback(
    (sObj?: FormationSearch) => {
      sObj.idapprenant = apprenantId;
      return api.formationBaseSearch({ FormationSearch: sObj });
    },
    [api, apprenantId]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction: searchFunc,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbFormationGridDto) => (
          <div style={{ display: "flex" }}>
            <ViewButton minimal={true} onClick={() => history.push(`${path}/${row.idformation}/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${path}/${row.idformation}/edit`)} />
          </div>
        )
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "codeFormation",
        computed: true
      },
      {
        header: () => t(ETLCodes.Metier),
        fieldName: "idmetier",
        render: (row: FcbFormationGridDto) => <div>{metier?.find(m => m.value === row.idmetier)?.label}</div>
      },
      {
        header: () => t(ETLCodes.Stade),
        fieldName: "idstade",
        render: (row: FcbFormationGridDto) => <div>{stade?.find(m => m.value === row.idstade)?.label}</div>
      },
      {
        header: () => t(ETLCodes.StatutStage),
        fieldName: "idstatutStage",
        render: (row: FcbFormationGridDto) => <div>{statutStage?.find(m => m.value === row.idstatutStage)?.label}</div>
      },
      {
        header: () => t(ETLCodes.StatutSocial),
        fieldName: "idstatutSocial",
        render: (row: FcbFormationGridDto) => (
          <div>{statutSocial?.find(m => m.value === row.idstatutSocial)?.label}</div>
        )
      }
    ],
    [history, metier, path, stade, statutSocial, statutStage, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${path}/0/edit`);
  }, [history, path]);

  return (
    <>
      {apprenantId > 0 && (
        <FieldSet
          title={t(ETLCodes.TableResults, { count: totalCount })}
          rightElement={
            <>
              <AddButton
                onClick={e => {
                  e.stopPropagation();
                  onAddItem();
                }}
                text={t(ETLCodes.General_Add)}
                intent={Intent.PRIMARY}
              />
            </>
          }
        >
          <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
        </FieldSet>
      )}
    </>
  );
};
