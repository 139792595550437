/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClasseMiseAJourMasseSearch,
  FcbClasseMiseAJourDto,
  FcbRechercheClasseMiseAJourMasseGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    ClasseMiseAJourMasseSearchFromJSON,
    ClasseMiseAJourMasseSearchToJSON,
    FcbClasseMiseAJourDtoFromJSON,
    FcbClasseMiseAJourDtoToJSON,
    FcbRechercheClasseMiseAJourMasseGridDtoPaginatedResultsFromJSON,
    FcbRechercheClasseMiseAJourMasseGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface ClasseMiseAJourMasseBaseSearchRequest {
    ClasseMiseAJourMasseSearch?: ClasseMiseAJourMasseSearch;
}

export interface ClasseMiseAJourMasseGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ClasseMiseAJourMasseGetSelectItemsRequest {
    searchField: string;
}

export interface ClasseMiseAJourMasseSaveRequest {
    FcbClasseMiseAJourDto?: FcbClasseMiseAJourDto;
}

export interface ClasseMiseAJourMasseSearchClasseIdsRequest {
    ClasseMiseAJourMasseSearch?: ClasseMiseAJourMasseSearch;
}

/**
 * 
 */
export class ClasseMiseAJourMasseApi extends runtime.BaseAPI {

    /**
     */
    async classeMiseAJourMasseBaseSearchRaw(requestParameters: ClasseMiseAJourMasseBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRechercheClasseMiseAJourMasseGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMiseAJourMasse/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClasseMiseAJourMasseSearchToJSON(requestParameters.ClasseMiseAJourMasseSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRechercheClasseMiseAJourMasseGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async classeMiseAJourMasseBaseSearch(requestParameters: ClasseMiseAJourMasseBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRechercheClasseMiseAJourMasseGridDtoPaginatedResults> {
        const response = await this.classeMiseAJourMasseBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeMiseAJourMasseGetSearchCriteriasRaw(requestParameters: ClasseMiseAJourMasseGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMiseAJourMasse/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async classeMiseAJourMasseGetSearchCriterias(requestParameters: ClasseMiseAJourMasseGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.classeMiseAJourMasseGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeMiseAJourMasseGetSelectItemsRaw(requestParameters: ClasseMiseAJourMasseGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling classeMiseAJourMasseGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMiseAJourMasse/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async classeMiseAJourMasseGetSelectItems(requestParameters: ClasseMiseAJourMasseGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.classeMiseAJourMasseGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeMiseAJourMasseSaveRaw(requestParameters: ClasseMiseAJourMasseSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbClasseMiseAJourDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMiseAJourMasse`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbClasseMiseAJourDtoToJSON(requestParameters.FcbClasseMiseAJourDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbClasseMiseAJourDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeMiseAJourMasseSave(requestParameters: ClasseMiseAJourMasseSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbClasseMiseAJourDto> {
        const response = await this.classeMiseAJourMasseSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeMiseAJourMasseSearchClasseIdsRaw(requestParameters: ClasseMiseAJourMasseSearchClasseIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMiseAJourMasse/SearchClasseIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClasseMiseAJourMasseSearchToJSON(requestParameters.ClasseMiseAJourMasseSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async classeMiseAJourMasseSearchClasseIds(requestParameters: ClasseMiseAJourMasseSearchClasseIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.classeMiseAJourMasseSearchClasseIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
