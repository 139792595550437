/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModuleDto
 */
export interface ModuleDto {
    /**
     * 
     * @type {number}
     * @memberof ModuleDto
     */
    idmodule?: number;
    /**
     * 
     * @type {string}
     * @memberof ModuleDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModuleDto
     */
    modulename?: string | null;
}

/**
 * Check if a given object implements the ModuleDto interface.
 */
export function instanceOfModuleDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModuleDtoFromJSON(json: any): ModuleDto {
    return ModuleDtoFromJSONTyped(json, false);
}

export function ModuleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModuleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idmodule': !exists(json, 'idmodule') ? undefined : json['idmodule'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'modulename': !exists(json, 'modulename') ? undefined : json['modulename'],
    };
}

export function ModuleDtoToJSON(value?: ModuleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idmodule': value.idmodule,
        'nom': value.nom,
        'modulename': value.modulename,
    };
}

