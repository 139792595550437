import * as React from "react";
import { matchPath, useLocation, useParams } from 'react-router';
import { ContratAvenantsItem, ContratAvenantsList } from '.';
import { ERoutes } from '../../../../../AppRouter';

export interface IContratAvenantsSwitchProps { }

export const ContratAvenantsSwitch: React.FunctionComponent<IContratAvenantsSwitchProps> = props => {
  const { pathname } = useLocation();
  const { id } = useParams<{ id: string; tab: string; state: string }>();
  if (
    matchPath(pathname, {
      path: `${ERoutes.contrat}/${id}/avenants/:type/:state/:subId`
    })
  ) {
    return <ContratAvenantsItem />;
  } else {
    return <ContratAvenantsList />;
  }
};