import { Tab, Tabs } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ApprenantApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { useEventsContext } from "../../../../contexts";
import { useApiService } from "../../../../hooks";
import { Deliberation } from "./Deliberation";

export interface IDeliberationTabsProps {}

export const DeliberationTabs: React.FunctionComponent<IDeliberationTabsProps> = () => {
  const history = useHistory();
  const { id, idApprenant, annee, tab, coursTypeTab } = useParams<{
    id: string;
    tab: string;
    idApprenant: string;
    annee: string;
    coursTypeTab: string;
    state: string;
  }>();
  const apprenantId = React.useMemo(() => +idApprenant, [idApprenant]);
  const api = useApiService(ApprenantApi);
  const { dispatchEvent } = useEventsContext();

  const [annees] = useApiEffect(() => api.apprenantGetAnneesScolairesInscrites({ IdApprenant: apprenantId }), [
    apprenantId
  ]);

  return (
    annees && (
      <Tabs
        id="TabsDeliberation"
        onChange={newTabId => {
          history.push(`${ERoutes.deliberationType}/${id}/${tab}/${apprenantId}/${newTabId}/${coursTypeTab}`);
          dispatchEvent("DELIBERATION_ANNEE_CHANGED");
        }}
        selectedTabId={annee}
        renderActiveTabPanelOnly
        vertical
      >
        {annees.map(a => {
          return <Tab key={a} id={a} title={a} panel={<Deliberation />} panelClassName="full-width-panel" />;
        })}
      </Tabs>
    )
  );
};
