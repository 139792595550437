/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ETextSearchType } from './ETextSearchType';
import {
    ETextSearchTypeFromJSON,
    ETextSearchTypeFromJSONTyped,
    ETextSearchTypeToJSON,
} from './ETextSearchType';
import type { ETextSearchTypeStringCombinableCriteria } from './ETextSearchTypeStringCombinableCriteria';
import {
    ETextSearchTypeStringCombinableCriteriaFromJSON,
    ETextSearchTypeStringCombinableCriteriaFromJSONTyped,
    ETextSearchTypeStringCombinableCriteriaToJSON,
} from './ETextSearchTypeStringCombinableCriteria';

/**
 * 
 * @export
 * @interface UnaccentTextSearch
 */
export interface UnaccentTextSearch {
    /**
     * 
     * @type {Array<ETextSearchTypeStringCombinableCriteria>}
     * @memberof UnaccentTextSearch
     */
    criterias?: Array<ETextSearchTypeStringCombinableCriteria> | null;
    /**
     * 
     * @type {ETextSearchType}
     * @memberof UnaccentTextSearch
     */
    searchMode?: ETextSearchType;
    /**
     * 
     * @type {string}
     * @memberof UnaccentTextSearch
     * @deprecated
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UnaccentTextSearch
     * @deprecated
     */
    secondaryValue?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UnaccentTextSearch
     */
    readonly availableSearchTypes?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UnaccentTextSearch
     */
    defaultSearchType?: string | null;
}

/**
 * Check if a given object implements the UnaccentTextSearch interface.
 */
export function instanceOfUnaccentTextSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UnaccentTextSearchFromJSON(json: any): UnaccentTextSearch {
    return UnaccentTextSearchFromJSONTyped(json, false);
}

export function UnaccentTextSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnaccentTextSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'criterias': !exists(json, 'criterias') ? undefined : (json['criterias'] === null ? null : (json['criterias'] as Array<any>).map(ETextSearchTypeStringCombinableCriteriaFromJSON)),
        'searchMode': !exists(json, 'searchMode') ? undefined : ETextSearchTypeFromJSON(json['searchMode']),
        'value': !exists(json, 'value') ? undefined : json['value'],
        'secondaryValue': !exists(json, 'secondaryValue') ? undefined : json['secondaryValue'],
        'availableSearchTypes': !exists(json, 'availableSearchTypes') ? undefined : json['availableSearchTypes'],
        'defaultSearchType': !exists(json, 'defaultSearchType') ? undefined : json['defaultSearchType'],
    };
}

export function UnaccentTextSearchToJSON(value?: UnaccentTextSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'criterias': value.criterias === undefined ? undefined : (value.criterias === null ? null : (value.criterias as Array<any>).map(ETextSearchTypeStringCombinableCriteriaToJSON)),
        'searchMode': ETextSearchTypeToJSON(value.searchMode),
        'value': value.value,
        'secondaryValue': value.secondaryValue,
        'defaultSearchType': value.defaultSearchType,
    };
}

