import { Colors, Dialog } from "@blueprintjs/core";
import { FGMaskInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { EnveloppeTypeFinancementApi, FcbEnveloppeTypeFinancementDtoFromJSON } from "../../../../../../api";
import { FGWalterSelectInput, SmallFormGenerator } from "../../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

export interface IEnveloppeTFDialogProps {
  isOpen: boolean;
  onClose: () => void;
  enveloppeId: number;
  editMode: boolean;
}

export const EnveloppeTFDialog: React.FunctionComponent<IEnveloppeTFDialogProps> = ({
  isOpen,
  onClose,
  enveloppeId,
  editMode
}) => {
  const api = useApiService(EnveloppeTypeFinancementApi);
  const { t } = useTl();
  const { id } = useParams<{ id: string }>();

  const tfId = React.useMemo(() => +id, [id]);
  const [annee, aLoading] = useReferential(a => a.referentialGetAnneeScolaire(), false, []);
  const [centres, cLoading] = useReferential(a => a.referentialGetCentreGlobal(), true, []);

  const getFn = React.useCallback(
    () =>
      +enveloppeId <= 0
        ? FcbEnveloppeTypeFinancementDtoFromJSON({
            idenveloppeTypeFinancement: enveloppeId,
            idfinancementType: tfId,
            heureS1: 0,
            heureS2: 0
          })
        : api.enveloppeTypeFinancementGet({ id: enveloppeId }),
    [api, enveloppeId, tfId]
  );

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: getFn,
    saveApiFn: d => api.enveloppeTypeFinancementSave({ FcbEnveloppeTypeFinancementDto: d }),
    deleteApiFn: d => api.enveloppeTypeFinancementDelete({ id: d.idenveloppeTypeFinancement })
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idanneeScolaire: Yup.string().required(t(ETLCodes.Required)),
      identite: Yup.string()
        .required(t(ETLCodes.Required))
        .notOneOf(["-1", "0"], t(ETLCodes.Required))
    });
  }, [t]);

  return (
    <Dialog
      isOpen={isOpen}
      isCloseButtonShown={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={false}
      title={t(ETLCodes.EnveloppeTypeFinancement)}
      onClose={() => onClose()}
      style={{ padding: "0.5rem", width: 800, backgroundColor: Colors.WHITE }}
      enforceFocus={false}
    >
      <div style={{ marginTop: "0.5rem" }} />
      <SmallFormGenerator
        initialValues={data}
        fill
        saving={saving}
        onDelete={deleteItem}
        deleting={deleting}
        onSubmit={saveItem}
        showColons
        editMode={editMode}
        minLabelWidth={180}
        labelAlignment="right"
        formatDate="dd/MM/yyyy"
        validationSchema={FormSchema}
        inline
        boldLabel
        loading={loading}
      >
        <FieldGroup>
          <FGWalterSelectInput name="idanneeScolaire" loading={aLoading} label={t(ETLCodes.Annee)} items={annee} />
          <FGWalterSelectInput name="identite" loading={cLoading} items={centres} label={t(ETLCodes.CentreGlobal)} />
          <FGMaskInput name="heureS1" label={t(ETLCodes.HeureS1)} cleaveOptions={{ numericOnly: true }} />
          <FGMaskInput name="heureS2" label={t(ETLCodes.HeureS2)} cleaveOptions={{ numericOnly: true }} />
        </FieldGroup>
      </SmallFormGenerator>
    </Dialog>
  );
};
