/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileUploadDto } from './FileUploadDto';
import {
    FileUploadDtoFromJSON,
    FileUploadDtoFromJSONTyped,
    FileUploadDtoToJSON,
} from './FileUploadDto';

/**
 * 
 * @export
 * @interface FcbFormateurDiplomeDto
 */
export interface FcbFormateurDiplomeDto {
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDiplomeDto
     */
    iddiplome?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDiplomeDto
     */
    idformateur?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDiplomeDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDiplomeDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurDiplomeDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDiplomeDto
     */
    url?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDiplomeDto
     */
    idfichier?: number | null;
    /**
     * 
     * @type {FileUploadDto}
     * @memberof FcbFormateurDiplomeDto
     */
    fichier?: FileUploadDto;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDiplomeDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurDiplomeDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDiplomeDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurDiplomeDto
     */
    modificationDate?: Date | null;
}

/**
 * Check if a given object implements the FcbFormateurDiplomeDto interface.
 */
export function instanceOfFcbFormateurDiplomeDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbFormateurDiplomeDtoFromJSON(json: any): FcbFormateurDiplomeDto {
    return FcbFormateurDiplomeDtoFromJSONTyped(json, false);
}

export function FcbFormateurDiplomeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbFormateurDiplomeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iddiplome': !exists(json, 'iddiplome') ? undefined : json['iddiplome'],
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'url': !exists(json, 'url') ? undefined : json['url'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'fichier': !exists(json, 'fichier') ? undefined : FileUploadDtoFromJSON(json['fichier']),
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
    };
}

export function FcbFormateurDiplomeDtoToJSON(value?: FcbFormateurDiplomeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iddiplome': value.iddiplome,
        'idformateur': value.idformateur,
        'type': value.type,
        'libelle': value.libelle,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'url': value.url,
        'idfichier': value.idfichier,
        'fichier': FileUploadDtoToJSON(value.fichier),
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
    };
}

