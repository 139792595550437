/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbFormateurProfessionDto,
  FcbFormateurProfessionGridDtoPaginatedResults,
  FilterCriteriaInfo,
  FormateurProfessionSearch,
  SelectItem,
} from '../models/index';
import {
    FcbFormateurProfessionDtoFromJSON,
    FcbFormateurProfessionDtoToJSON,
    FcbFormateurProfessionGridDtoPaginatedResultsFromJSON,
    FcbFormateurProfessionGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    FormateurProfessionSearchFromJSON,
    FormateurProfessionSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface FormateurProfessionBaseSearchRequest {
    FormateurProfessionSearch?: FormateurProfessionSearch;
}

export interface FormateurProfessionDeleteRequest {
    idFormateur?: number;
    idProfession?: number;
}

export interface FormateurProfessionGetRequest {
    idFormateur?: number;
    idProfession?: number;
}

export interface FormateurProfessionGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface FormateurProfessionGetSelectItemsRequest {
    searchField: string;
}

export interface FormateurProfessionSaveRequest {
    FcbFormateurProfessionDto?: FcbFormateurProfessionDto;
}

/**
 * 
 */
export class FormateurProfessionApi extends runtime.BaseAPI {

    /**
     */
    async formateurProfessionBaseSearchRaw(requestParameters: FormateurProfessionBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurProfessionGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurProfession/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormateurProfessionSearchToJSON(requestParameters.FormateurProfessionSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurProfessionGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async formateurProfessionBaseSearch(requestParameters: FormateurProfessionBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurProfessionGridDtoPaginatedResults> {
        const response = await this.formateurProfessionBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurProfessionDeleteRaw(requestParameters: FormateurProfessionDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.idFormateur !== undefined) {
            queryParameters['idFormateur'] = requestParameters.idFormateur;
        }

        if (requestParameters.idProfession !== undefined) {
            queryParameters['idProfession'] = requestParameters.idProfession;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurProfession`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async formateurProfessionDelete(requestParameters: FormateurProfessionDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.formateurProfessionDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurProfessionGetRaw(requestParameters: FormateurProfessionGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurProfessionDto>> {
        const queryParameters: any = {};

        if (requestParameters.idFormateur !== undefined) {
            queryParameters['idFormateur'] = requestParameters.idFormateur;
        }

        if (requestParameters.idProfession !== undefined) {
            queryParameters['idProfession'] = requestParameters.idProfession;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurProfession`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurProfessionDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurProfessionGet(requestParameters: FormateurProfessionGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurProfessionDto> {
        const response = await this.formateurProfessionGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurProfessionGetSearchCriteriasRaw(requestParameters: FormateurProfessionGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurProfession/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async formateurProfessionGetSearchCriterias(requestParameters: FormateurProfessionGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.formateurProfessionGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurProfessionGetSelectItemsRaw(requestParameters: FormateurProfessionGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling formateurProfessionGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurProfession/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async formateurProfessionGetSelectItems(requestParameters: FormateurProfessionGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.formateurProfessionGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurProfessionSaveRaw(requestParameters: FormateurProfessionSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurProfessionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurProfession`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbFormateurProfessionDtoToJSON(requestParameters.FcbFormateurProfessionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurProfessionDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurProfessionSave(requestParameters: FormateurProfessionSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurProfessionDto> {
        const response = await this.formateurProfessionSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
