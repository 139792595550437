import { FGEmpty, FGMaskInput, FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { FcbFormationDtoFromJSON, FormationApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

export interface IApprenantFormationDetailProps {}

export const ApprenantFormationDetail: React.FunctionComponent<IApprenantFormationDetailProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(FormationApi);
  const { id, formationId, state } = useParams<{ id: string; formationId: string; state: "edit" | "view" }>();
  const idApprenant = React.useMemo(() => +id, [id]);
  const editMode = React.useMemo(() => state === "edit", [state]);
  const idFormation = React.useMemo(() => +formationId, [formationId]);
  const [idStade, setIdStade] = React.useState(0);

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idFormation <= 0
        ? FcbFormationDtoFromJSON({ idFormation: 0, idapprenant: idApprenant })
        : api.formationGet({ id: +idFormation }),
    saveApiFn: d => api.formationSave({ FcbFormationDto: d }),
    onSavedRoute: d => `${ERoutes.apprenant}/${idApprenant}/formation/${d.idformation}`,
    deleteApiFn: d => api.formationDelete({ id: d.idformation }),
    onDeletedRoute: d => `${ERoutes.apprenant}/${idApprenant}/formation`
  });

  const [stade, sLoading] = useReferential(a => a.referentialGetStade(), true, []);
  const [metier, mLoading] = useReferential(a => a.referentialGetMetierByStade({ idStade }), true, [idStade]);
  const [statutStage, stLoading] = useReferential(a => a.referentialGetStatutStage(), true, []);
  const [idstatutSocial, setIdstatutSocial] = React.useState(null);
  const [statutSocial, soLoading] = useReferential(
    a => a.referentialGetStatutSocial({ currentIdstatutSocial: idstatutSocial }),
    true,
    []
  );
  const [enseignement, eLoading] = useReferential(a => a.referentialGetTypeEnseignement(), true, []);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idstade: Yup.number().required(t(ETLCodes.Required)),
      idmetier: Yup.number().required(t(ETLCodes.Required)),
      idstatutSocial: Yup.number().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={editMode}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(`${ERoutes.apprenant}/${idApprenant}/formation`)}
      onDelete={deleteItem}
      showDeleteButton={+idFormation > 0}
      saving={saving}
      deleting={deleting}
      formatDate="dd-MM-yyyy"
      minLabelWidth={200}
      watchChanges={{
        idstade: value => setIdStade(value),
        idstatutSocial: value => setIdstatutSocial(value)
      }}
    >
      <FieldGroup columns={2} fieldsetProps={{ title: t(ETLCodes.Edition), collapsable: false }}>
        <FGWalterSelectInput label={t(ETLCodes.Stade)} name="idstade" items={stade} loading={sLoading} />
        <FGWalterSelectInput
          label={t(ETLCodes.StatutStage)}
          name="idstatutStage"
          items={statutStage}
          loading={stLoading}
        />
      </FieldGroup>
      <FieldGroup>
        <FGWalterSelectInput label={t(ETLCodes.Metier)} name="idmetier" items={metier} loading={mLoading} />
        <FGWalterSelectInput
          label={t(ETLCodes.StatutSocial)}
          name="idstatutSocial"
          items={statutSocial}
          loading={soLoading}
          readonly
        />
        <FGWalterSelectInput
          label={t(ETLCodes.EnseignementSuivi)}
          name="idtypeEnseignement"
          items={enseignement}
          loading={eLoading}
        />
      </FieldGroup>
      <FieldGroup columns={2}>
        <FGWalterDateMaskInput label={t(ETLCodes.DateFOREM)} name="dateForem" />
        <FGTextInput label={t(ETLCodes.PeriodeInactivite)} name="dureeInoccupation" />
        <FGWalterCheckboxInput label={t(ETLCodes.DiplomeRemis)} name="diplome" />
        <FGTextInput label={t(ETLCodes.DernierDiplome)} name="diplomeUtile" maxLength={50} />
        <FGTextInput label={t(ETLCodes.IDContrat)} name="contrat" maxLength={50} />
        <FGTextInput label={t(ETLCodes.EtatContrat)} name="etatContrat" maxLength={20} />
        <FGWalterDateMaskInput label={t(ETLCodes.DebutContrat)} name="debutContrat" />
        <FGWalterDateMaskInput label={t(ETLCodes.FinContrat)} name="finContrat" />
        <FGWalterDateMaskInput label={t(ETLCodes.RuptureContrat)} name="dateRupture" />
        <FGEmpty />
        <FGTextInput label={t(ETLCodes.NomDelegue)} name="nomDelegue" maxLength={255} />
        <FGTextInput label={t(ETLCodes.PrenomDelegue)} name="prenomDelegue" maxLength={255} />
        <FGTextInput label={t(ETLCodes.Societe)} name="nomSiege" maxLength={255} />
        <FGEmpty />
        <FGTextInput label={t(ETLCodes.NomPatron)} name="nomPatron" maxLength={255} />
        <FGTextInput label={t(ETLCodes.PrenomPatron)} name="prenomPatron" maxLength={255} />
        <FGTextAreaInput label={t(ETLCodes.Adresse)} name="adresseLieu" maxLength={255} />
        <FieldGroup>
          <FGMaskInput
            label={t(ETLCodes.CodePostal)}
            name="cpLieu"
            cleaveOptions={{ numeralPositiveOnly: true, numericOnly: true }}
          />
          <FGTextInput label={t(ETLCodes.Localite)} name="localiteLieu" maxLength={255} />
        </FieldGroup>
        <FGTextInput label={t(ETLCodes.Tel)} name="telephoneLieu" maxLength={255} />
        <FGTextInput label={t(ETLCodes.Fax)} name="faxLieu" maxLength={255} />
        <FGTextAreaInput label={t(ETLCodes.ResultatFinAPPObtenu)} name="resultatApp" />
        <FGTextAreaInput label={t(ETLCodes.Remarque)} name="remarque" />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
