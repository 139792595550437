/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DisplayNameDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
  SiegeSocialSelectorGridDtoPaginatedResults,
  SiegeSocialSelectorSearch,
} from '../models/index';
import {
    DisplayNameDtoFromJSON,
    DisplayNameDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
    SiegeSocialSelectorGridDtoPaginatedResultsFromJSON,
    SiegeSocialSelectorGridDtoPaginatedResultsToJSON,
    SiegeSocialSelectorSearchFromJSON,
    SiegeSocialSelectorSearchToJSON,
} from '../models/index';

export interface SiegeSocialSelectorBaseSearchRequest {
    SiegeSocialSelectorSearch?: SiegeSocialSelectorSearch;
}

export interface SiegeSocialSelectorGetDisplayNamesRequest {
    request_body?: Array<number>;
}

export interface SiegeSocialSelectorGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface SiegeSocialSelectorGetSelectItemsRequest {
    searchField: string;
}

/**
 * 
 */
export class SiegeSocialSelectorApi extends runtime.BaseAPI {

    /**
     */
    async siegeSocialSelectorBaseSearchRaw(requestParameters: SiegeSocialSelectorBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SiegeSocialSelectorGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocialSelector/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SiegeSocialSelectorSearchToJSON(requestParameters.SiegeSocialSelectorSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiegeSocialSelectorGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async siegeSocialSelectorBaseSearch(requestParameters: SiegeSocialSelectorBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SiegeSocialSelectorGridDtoPaginatedResults> {
        const response = await this.siegeSocialSelectorBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialSelectorGetDisplayNamesRaw(requestParameters: SiegeSocialSelectorGetDisplayNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DisplayNameDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocialSelector/GetDisplayNames`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request_body,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DisplayNameDtoFromJSON));
    }

    /**
     */
    async siegeSocialSelectorGetDisplayNames(requestParameters: SiegeSocialSelectorGetDisplayNamesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DisplayNameDto>> {
        const response = await this.siegeSocialSelectorGetDisplayNamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialSelectorGetSearchCriteriasRaw(requestParameters: SiegeSocialSelectorGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocialSelector/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async siegeSocialSelectorGetSearchCriterias(requestParameters: SiegeSocialSelectorGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.siegeSocialSelectorGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialSelectorGetSelectItemsRaw(requestParameters: SiegeSocialSelectorGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling siegeSocialSelectorGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocialSelector/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async siegeSocialSelectorGetSelectItems(requestParameters: SiegeSocialSelectorGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.siegeSocialSelectorGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
