import { ITabsProps, Tabs } from "@blueprintjs/core";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router";

import { useAuth } from "../../contexts";

export interface IProtectedTabsProps extends ITabsProps {}

export const ProtectedTabs: React.FunctionComponent<IProtectedTabsProps> = ({ ...props }) => {
  const { matchTab } = useAuth();
  const { pathname } = useLocation();
  const { tab: tabString } = useParams<{ tab: string }>();
  const history = useHistory();

  const filteredTabs = React.useCallback<any>(
    (tabs: any = props.children) => {
      if (Array.isArray(tabs)) {
        return tabs.filter((d: any) => {
          if (Array.isArray(d)) return filteredTabs(d);
          if (d?.type?.displayName === "Blueprint4.Tab") {
            return matchTab(d.props.id.toString());
          } else {
            return true;
          }
        });
      }
      return props.children;
    },
    [matchTab, props.children]
  );

  React.useEffect(() => {
    if (filteredTabs() && Array.isArray(filteredTabs()) && filteredTabs().length > 0) {
      let tab = null;

      if (!filteredTabs().some(d => (d.props?.id?.toUpperCase() ?? "") === tabString?.toUpperCase())) {
        for (let d of filteredTabs()) {
          if (d?.type?.displayName === "Blueprint4.Tab" && !tab) {
            tab = d;
          }
        }
      }

      if (tab && tabString.toUpperCase() !== tab.props.id.toUpperCase()) {
        let url = pathname.charAt(pathname.length - 1) !== "/" ? pathname + "/" : pathname;
        url = url.replace(`/${tabString}/`, `/${tab.props.id}/`);
        url = url.substr(0, url.indexOf(`/${tab.props.id}/`) + `/${tab.props.id}/`.length);
        history.push(url);
      }
    }
  }, [filteredTabs, history, pathname, tabString]);

  return <Tabs {...props} children={filteredTabs()} />;
};
