/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbFormateurPrestationsContratGridDto
 */
export interface FcbFormateurPrestationsContratGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurPrestationsContratGridDto
     */
    idFormateur?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurPrestationsContratGridDto
     */
    idFormateurLocalisationContrat?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurPrestationsContratGridDto
     */
    nbHeuresCours?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurPrestationsContratGridDto
     */
    nbHeuresAPayer?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurPrestationsContratGridDto
     */
    nbHeuresRemplaceesPayees?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurPrestationsContratGridDto
     */
    nbHeuresRemplaceesNonPayees?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurPrestationsContratGridDto
     */
    nbRemplacants?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurPrestationsContratGridDto
     */
    annee?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurPrestationsContratGridDto
     */
    mois?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurPrestationsContratGridDto
     */
    contrat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurPrestationsContratGridDto
     */
    centre?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurPrestationsContratGridDto
     */
    moisorder?: number | null;
}

/**
 * Check if a given object implements the FcbFormateurPrestationsContratGridDto interface.
 */
export function instanceOfFcbFormateurPrestationsContratGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbFormateurPrestationsContratGridDtoFromJSON(json: any): FcbFormateurPrestationsContratGridDto {
    return FcbFormateurPrestationsContratGridDtoFromJSONTyped(json, false);
}

export function FcbFormateurPrestationsContratGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbFormateurPrestationsContratGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idFormateur': !exists(json, 'idFormateur') ? undefined : json['idFormateur'],
        'idFormateurLocalisationContrat': !exists(json, 'idFormateurLocalisationContrat') ? undefined : json['idFormateurLocalisationContrat'],
        'nbHeuresCours': !exists(json, 'nbHeuresCours') ? undefined : json['nbHeuresCours'],
        'nbHeuresAPayer': !exists(json, 'nbHeuresAPayer') ? undefined : json['nbHeuresAPayer'],
        'nbHeuresRemplaceesPayees': !exists(json, 'nbHeuresRemplaceesPayees') ? undefined : json['nbHeuresRemplaceesPayees'],
        'nbHeuresRemplaceesNonPayees': !exists(json, 'nbHeuresRemplaceesNonPayees') ? undefined : json['nbHeuresRemplaceesNonPayees'],
        'nbRemplacants': !exists(json, 'nbRemplacants') ? undefined : json['nbRemplacants'],
        'annee': !exists(json, 'annee') ? undefined : json['annee'],
        'mois': !exists(json, 'mois') ? undefined : json['mois'],
        'contrat': !exists(json, 'contrat') ? undefined : json['contrat'],
        'centre': !exists(json, 'centre') ? undefined : json['centre'],
        'moisorder': !exists(json, 'moisorder') ? undefined : json['moisorder'],
    };
}

export function FcbFormateurPrestationsContratGridDtoToJSON(value?: FcbFormateurPrestationsContratGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idFormateur': value.idFormateur,
        'idFormateurLocalisationContrat': value.idFormateurLocalisationContrat,
        'nbHeuresCours': value.nbHeuresCours,
        'nbHeuresAPayer': value.nbHeuresAPayer,
        'nbHeuresRemplaceesPayees': value.nbHeuresRemplaceesPayees,
        'nbHeuresRemplaceesNonPayees': value.nbHeuresRemplaceesNonPayees,
        'nbRemplacants': value.nbRemplacants,
        'annee': value.annee,
        'mois': value.mois,
        'contrat': value.contrat,
        'centre': value.centre,
        'moisorder': value.moisorder,
    };
}

