/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbDemandeApprenantDto
 */
export interface FcbDemandeApprenantDto {
    /**
     * 
     * @type {number}
     * @memberof FcbDemandeApprenantDto
     */
    iddemande?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbDemandeApprenantDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDemandeApprenantDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDemandeApprenantDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbDemandeApprenantDto
     */
    idtype?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbDemandeApprenantDto
     */
    idcentre?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDemandeApprenantDto
     */
    iddelegueTutelle?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDemandeApprenantDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbDemandeApprenantDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDemandeApprenantDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbDemandeApprenantDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDemandeApprenantDto
     */
    idmetier?: number | null;
}

/**
 * Check if a given object implements the FcbDemandeApprenantDto interface.
 */
export function instanceOfFcbDemandeApprenantDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbDemandeApprenantDtoFromJSON(json: any): FcbDemandeApprenantDto {
    return FcbDemandeApprenantDtoFromJSONTyped(json, false);
}

export function FcbDemandeApprenantDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbDemandeApprenantDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iddemande': !exists(json, 'iddemande') ? undefined : json['iddemande'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idtype': !exists(json, 'idtype') ? undefined : json['idtype'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'iddelegueTutelle': !exists(json, 'iddelegueTutelle') ? undefined : json['iddelegueTutelle'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
    };
}

export function FcbDemandeApprenantDtoToJSON(value?: FcbDemandeApprenantDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iddemande': value.iddemande,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'description': value.description,
        'idapprenant': value.idapprenant,
        'idtype': value.idtype,
        'idcentre': value.idcentre,
        'iddelegueTutelle': value.iddelegueTutelle,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'idmetier': value.idmetier,
    };
}

