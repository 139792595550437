import { Intent } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { FcbLieuxCoursGridDto, LieuxCoursApi, LieuxCoursSearch } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { AddButton, EditButton, ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { useApiService } from "../../../../../hooks/useApiService";
import { ETLCodes } from "../../../../../locales";

export interface ICentreLieuxCoursListProps {}

export const CentreLieuxCoursList: React.FunctionComponent<ICentreLieuxCoursListProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const api = useApiService(LieuxCoursApi);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { code: "ASC" }
  });

  const searchFunction = React.useCallback(
    (sObj?: LieuxCoursSearch) => {
      sObj.idcentre = +id;
      return api.lieuxCoursBaseSearch({ LieuxCoursSearch: sObj });
    },
    [api, id]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const { totalCount } = tableState;

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbLieuxCoursGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.centre}/${+id}/lieuxCours/${row.idlieu}/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.centre}/${+id}/lieuxCours/${row.idlieu}/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Adresse),
        fieldName: "adresse"
      },
      {
        header: () => t(ETLCodes.CodePostal),
        fieldName: "codePostal",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      },
      {
        header: () => t(ETLCodes.Commune),
        fieldName: "commune"
      },
      {
        header: () => t(ETLCodes.Pays),
        fieldName: "codePays",
        render: (row: FcbLieuxCoursGridDto) => row.pays
      },
      {
        header: () => t(ETLCodes.Tel),
        fieldName: "tel"
      },
      {
        header: () => t(ETLCodes.Fax),
        fieldName: "fax"
      }
    ],
    [history, id, t]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.centre}/${+id}/lieuxCours/0/edit`);
  }, [history, id]);

  return (
    <FieldSet
      title={t(ETLCodes.TableResults, { count: totalCount })}
      rightElement={
        addItemFunction && (
          <AddButton
            onClick={e => {
              e.stopPropagation();
              addItemFunction();
            }}
            text={t(ETLCodes.General_Add)}
            intent={Intent.PRIMARY}
          />
        )
      }
    >
      <DataTable
        dateFormat="dd-MM-yyyy"
        tableState={tableState}
        loading={loading}
        columns={columns}
        filterMode="OnEnter"
      />
    </FieldSet>
  );
};
