import * as React from "react";
import { useHistory } from "react-router";
import { RapportHopeApi, RapportHopeEditDtoFromJSON, ReferentialApi } from "../../../../../../api";
import { useApiService, useCrudApi, useTl } from "../../../../../../hooks";
import * as Yup from "yup";
import { ETLCodes } from "../../../../../../locales";
import { ERoutes } from "../../../../../../AppRouter";
import {
  FGWalterCheckboxInput,
  FGWalterSelectInput,
  FGWalterSuggestTextInput,
  SmallFormGenerator
} from "../../../../../../components";
import { FGTextInput, FieldGroup } from "nsitools-react";
import { useReferential } from "../../../../../../hooks/useReferential";
import { useQuery } from "react-query";

export interface IRapportHopeDetailPageProps {
  idrapportHope: number;
  editMode: boolean;
}

export const RapportHopeDetailPage: React.FunctionComponent<IRapportHopeDetailPageProps> = ({
  idrapportHope,
  editMode
}) => {
  const { t } = useTl();
  const api = useApiService(RapportHopeApi);
  const refApi = useApiService(ReferentialApi);
  const history = useHistory();

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      nom: Yup.string().required(t(ETLCodes.Required)),
      path: Yup.string().required(t(ETLCodes.Required)),
      idrapportHopeType: Yup.number()
        .nullable()
        .required(t(ETLCodes.Required))
    });
  }, [t]);

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () =>
          +idrapportHope <= 0
            ? RapportHopeEditDtoFromJSON({ idrapportHope: 0, actif: true })
            : api.rapportHopeGetRapportHope({ idrapportHope: +idrapportHope }),
        saveApiFn: d => api.rapportHopeSaveRapportHope({ RapportHopeEditDto: d }),
        onSavedRoute: d => `${ERoutes.rapports}/${d.idrapportHope}/detail/edit`,
        deleteApiFn: d => api.rapportHopeDeleteRapportHope({ idrapportHope: d.idrapportHope }),
        onDeletedRoute: () => ERoutes.rapports
      }),
      [api, idrapportHope]
    )
  );

  const [types, tLoading] = useReferential(a => a.referentialGetRapportHopeTypes());

  const fetchExistingCategories = React.useCallback(() => {
    return refApi.referentialGetCategoriesRapport();
  }, [refApi]);
  const { data: categories, isFetching: cLoading } = useQuery(
    "existing-categories-rapport-hope",
    fetchExistingCategories,
    {
      cacheTime: 0
    }
  );

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={editMode}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.rapports)}
      onDelete={deleteItem}
      showDeleteButton={+idrapportHope > 0}
      saving={saving}
      deleting={deleting}
    >
      <FieldGroup columns={2}>
        <FieldGroup>
          <FGTextInput name="nom" label={t(ETLCodes.Nom)} maxLength={150} />
          <FGTextInput name="description" label={t(ETLCodes.Description)} maxLength={150} />
          <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
          <FGTextInput name="path" label={t(ETLCodes.Rapport)} maxLength={250} />
          <FGWalterSelectInput name="idrapportHopeType" label={t(ETLCodes.Type)} items={types} loading={tLoading} />
          <FGWalterSuggestTextInput
            name="categorie"
            label={t(ETLCodes.Categorie)}
            items={categories}
            loading={cLoading}
          />
        </FieldGroup>
      </FieldGroup>
    </SmallFormGenerator>
  );
};
