import { Classes, Dialog, Intent, Radio, RadioGroup } from "@blueprintjs/core";
import { endOfToday, isAfter, isBefore, isSameDay } from "date-fns";
import { FormikProps } from "formik";
import {
  ButtonContainer,
  DataTable,
  FGCustomInput,
  FGCustomPanel,
  FGMultiSuggestInput,
  FGNumberInput,
  FGTextAreaInput,
  FGTextInput,
  FieldGroup,
  IDataTableColumn,
  IFGContext,
  useGridState
} from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";

import {
  LieuFormationAgrementDecision,
  LieuFormationAgrementDemandeSectorielle,
  LieuFormationAgrementSuspensionDetailDialog,
  LieuFormationAgrementVisiteDialog
} from ".";
import {
  ELevelName,
  ELieuFormationAgrementStatut,
  FichierApi,
  LieuFormationAgrementApi,
  LieuFormationAgrementEditDto,
  LieuFormationAgrementEditDtoFromJSON,
  LieuFormationAgrementOffreDtoFromJSON,
  LieuFormationAgrementSuspensionDto,
  LieuFormationAgrementSuspensionDtoFromJSON,
  LieuFormationAgrementVisiteDto,
  LieuFormationAgrementVisiteDtoFromJSON,
  LieuFormationApi
} from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import {
  AddButton,
  BooleanColumn,
  DeleteButton,
  EditButton,
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterFileInput,
  FGWalterSelectInput,
  LinkButton,
  SaveButton,
  SmallFormGenerator,
  ViewButton,
  WarningText
} from "../../../../../../components";
import { useDialog } from "../../../../../../contexts";
import { useApiService, useCrudApi, useTheme, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";
import { exportFile } from "../../../../../../utils";

const StyledDialog = styled(Dialog)`
  width: 600px;
  height: auto;
  background-color: white;
`;

const StatutContainer = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  > span {
    font-weight: bold;
    color: ${props => props.color};
    font-size: 14pt;
  }
`;

const RadioReadonlyContainer = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
`;

export interface ILieuFormationAgrementItemProps {}

export const LieuFormationAgrementItem: React.FunctionComponent<ILieuFormationAgrementItemProps> = props => {
  const { t, tUnsafe } = useTl();
  const { theme } = useTheme();
  const history = useHistory();
  const api = useApiService(LieuFormationAgrementApi);
  const lieuFormationApi = useApiService(LieuFormationApi);
  const [, , , stades] = useReferential(a => a.referentialGetStade(), true);
  const [metiers, mLoading, , rawMetiers] = useReferential(a => a.referentialGetMetier(), true);
  const [motifsRetrait, mrLoading] = useReferential(a => a.referentialGetMotifsRetrait(), true);
  const [currentSuspension, setCurrentSuspension] = React.useState<LieuFormationAgrementSuspensionDto>(null);
  const formikRef = React.useRef<FormikProps<LieuFormationAgrementEditDto>>();
  const innerIdIndex = React.useRef(-1);
  const { search } = useLocation();

  const idsiegeSocial = React.useMemo(() => new URLSearchParams(search).get("idsiegeSocial"), [search]);
  const duplicateId = React.useMemo(() => new URLSearchParams(search).get("duplicateId"), [search]);

  const [users, usersLoading] = useReferential(a =>
    a.referentialGetUsersByRoles({ ELevelName: [ELevelName.RI, ELevelName.CALC], currentIduser: 0 })
  );

  const { id, state, subId: idlieuFormationAgrement } = useParams<{ id: string; state: string; subId: string }>();
  const { data: displayName, isLoading: displayNameLoading } = useQuery(["lieu-formation-display-name", id], () =>
    lieuFormationApi.lieuFormationGetDisplayName({ id: +id })
  );

  const { showDialogPromise } = useDialog();
  const [remarqueOffreDialogOpen, setRemarqueOffreDialogOpen] = React.useState(false);

  const saveFn = React.useCallback(
    async (d: LieuFormationAgrementEditDto) => {
      return api.lieuFormationAgrementSaveLieuFormationAgrement({ LieuFormationAgrementEditDto: d });
    },
    [api]
  );

  const onSavedMode = React.useRef<"duplicate" | "new" | "none">("none");
  const emptyFormValues = React.useMemo(
    () =>
      LieuFormationAgrementEditDtoFromJSON({
        offre: LieuFormationAgrementOffreDtoFromJSON({ actif: false }),
        dossierComplet: false,
        idlieuFormationAgrement: 0,
        idlieuFormation: +id,
        statut: null
      }),
    [id]
  );
  const onSaved = React.useCallback(() => {
    switch (onSavedMode.current) {
      case "none":
        return history.push(`${ERoutes.lieuFormation}/${+id}/agrements/${state}?idsiegeSocial=${idsiegeSocial}`);
      case "new":
        formikRef.current.resetForm({ values: emptyFormValues });
        break;
      case "duplicate":
        formikRef.current.setValues({
          ...formikRef.current.values,
          offre: LieuFormationAgrementOffreDtoFromJSON({ actif: false }),
          idlieuFormationAgrementDecision: null,
          dateDecision: null,
          idlieuFormationAgrement: 0,
          idmetier: null,
          capaciteFormative: 0
        });
        break;
    }

    onSavedMode.current = "none";
  }, [emptyFormValues, history, id, idsiegeSocial, state]);

  const { data, loading, saveItem, saving, deleteItem, deleting, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: async () => {
          let duplicateValues: LieuFormationAgrementEditDto = {};
          if (duplicateId) {
            duplicateValues = await api.lieuFormationAgrementGetLieuFormationAgrement({
              idlieuFormationAgrement: +duplicateId
            });
          }
          const values =
            +idlieuFormationAgrement <= 0
              ? LieuFormationAgrementEditDtoFromJSON({
                  ...duplicateValues,
                  visites: duplicateValues?.visites?.map((v, idx) => ({
                    ...v,
                    idlieuFormationAgrementVisite: (idx + 1) * -1
                  })),
                  suspensions: duplicateValues?.suspensions?.map((s, idx) => ({
                    ...s,
                    idlieuFormationAgrementSuspension: (idx + 1) * -1
                  })),
                  dossierComplet: false,
                  offre: LieuFormationAgrementOffreDtoFromJSON({ actif: false }),
                  idlieuFormationAgrementDecision: null,
                  dateDecision: null,
                  idlieuFormationAgrement: 0,
                  idlieuFormation: +id,
                  actif: true
                })
              : await api.lieuFormationAgrementGetLieuFormationAgrement({
                  idlieuFormationAgrement: +idlieuFormationAgrement
                });

          return values;
        },
        saveApiFn: saveFn,
        onSaved,
        deleteApiFn: (d: LieuFormationAgrementEditDto) =>
          api.lieuFormationAgrementDeleteLieuFormationAgrement({ idlieuFormationAgrement: d.idlieuFormationAgrement }),
        onDeletedRoute: () => `${ERoutes.lieuFormation}/${+id}/agrements/${state}?idsiegeSocial=${idsiegeSocial}`,
        childArraysToSanitize: [
          { name: "suspensions", idKey: "idlieuFormationAgrementSuspension" },
          { name: "visites", idKey: "idlieuFormationAgrementVisite" }
        ],
        serverValidationRootKey: "Dto"
      }),
      [api, duplicateId, id, idlieuFormationAgrement, idsiegeSocial, onSaved, saveFn, state]
    )
  );

  const save = React.useCallback(
    async (d: LieuFormationAgrementEditDto) => {
      let res = "yes";
      if (!!d.datePriseEffetRetrait) {
        res = await showDialogPromise({
          title: t(ETLCodes.AgrementRetraitWarningTitle),
          message: t(ETLCodes.AgrementRetraitWarningMessage)
        });
      }

      var hasContratsEnCours = await lieuFormationApi.lieuFormationHasContratsEnCours({
        idlieuFormation: data?.idlieuFormation
      });
      if (
        d.suspensions?.some(s => s.idlieuFormationAgrementSuspension <= 0) &&
        d.statut !== ELieuFormationAgrementStatut.Suspendu &&
        hasContratsEnCours.value
      ) {
        res = await showDialogPromise({
          title: t(ETLCodes.AgrementSuspensionWarningTitle),
          message: t(ETLCodes.AgrementSuspensionWarningMessage)
        });
      }

      if (res === "no") return;

      await saveItem(d);
    },
    [data?.idlieuFormation, lieuFormationApi, saveItem, showDialogPromise, t]
  );

  const preCreate = React.useCallback(
    async (d: LieuFormationAgrementEditDto) => {
      const res = await showDialogPromise({
        title: t(ETLCodes.LieuFormationAgrementOffreTitle),
        message: t(ETLCodes.LieuFormationAgrementOffreMessage)
      });
      if (res === "yes") {
        setRemarqueOffreDialogOpen(true);
        return;
      }

      await save(d);
    },
    [save, showDialogPromise, t]
  );

  const mainSave = React.useCallback(
    async (values: LieuFormationAgrementEditDto) => {
      await (+idlieuFormationAgrement <= 0 ? preCreate(values) : save(values));
    },
    [idlieuFormationAgrement, preCreate, save]
  );

  const tableState = useGridState<any>({
    serverMode: false,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { dateDebut: "DESC" }
  });

  const { setData } = tableState;
  React.useEffect(() => {
    if (!!data?.suspensions) {
      setData(data.suspensions);
    }
  }, [data?.suspensions, setData]);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape(
      {
        idlieuFormation: Yup.number().required(t(ETLCodes.Required)),
        idmetier: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        dateDecision: Yup.date()
          .nullable()
          .max(endOfToday(), t(ETLCodes.DateCannotBeInFurture))
          .when("idlieuFormationAgrementDecision", (value, schema) =>
            !!value ? schema.required(t(ETLCodes.Required)) : schema
          ),
        dateReception: Yup.date()
          .nullable()
          .max(endOfToday(), t(ETLCodes.DateCannotBeInFurture)),
        dateEnvoiD1: Yup.date()
          .nullable()
          .max(endOfToday(), t(ETLCodes.DateCannotBeInFurture)),
        dateReceptionAvis: Yup.date()
          .nullable()
          .max(endOfToday(), t(ETLCodes.DateCannotBeInFurture)),
        dateVisite: Yup.date()
          .nullable()
          .max(endOfToday(), t(ETLCodes.DateCannotBeInFurture)),
        dateEnvoiD3: Yup.date()
          .nullable()
          .max(endOfToday(), t(ETLCodes.DateCannotBeInFurture)),
        dateRetrait: Yup.date()
          .nullable()
          .max(endOfToday(), t(ETLCodes.DateCannotBeInFurture)),
        datePriseEffetRetrait: Yup.date()
          .nullable()
          .max(endOfToday(), t(ETLCodes.DateCannotBeInFurture))
          .when("dateRetrait", (value, schema) => {
            return !!value ? schema.required(t(ETLCodes.Required)) : schema;
          })
          .when("retraitIsIfapme", (value, schema) => {
            return !!value ? schema.required(t(ETLCodes.Required)) : schema;
          })
          .when("idsMotifRetrait", (value, schema) => {
            return !!value && value?.length > 0 ? schema.required(t(ETLCodes.Required)) : schema;
          })
          .when("remarqueRetrait", (value, schema) => {
            return !!value ? schema.required(t(ETLCodes.Required)) : schema;
          })
      },
      [
        ["datePriseEffetRetrait", "dateRetrait"],
        ["datePriseEffetRetrait", "retraitIsIfapme"],
        ["datePriseEffetRetrait", "idsMotifRetrait"],
        ["datePriseEffetRetrait", "remarqueRetrait"]
      ]
    );
  }, [t]);
  const onDeleteClick = React.useCallback(
    async (dto: LieuFormationAgrementSuspensionDto) => {
      let nextValues = [...formikRef?.current?.values?.suspensions];
      nextValues.splice(nextValues.indexOf(dto), 1);
      formikRef?.current.setFieldValue(`suspensions`, nextValues);
      formikRef?.current.setFieldTouched(`suspensions`, true);
      setData(nextValues);
    },
    [setData]
  );

  const onEditClick = React.useCallback((dto: LieuFormationAgrementSuspensionDto) => {
    setCurrentSuspension(dto);
  }, []);

  const onClose = React.useCallback(
    (toSave: LieuFormationAgrementSuspensionDto, formik: FormikProps<LieuFormationAgrementEditDto>) => {
      if (!!toSave) {
        let nextValues = [...(formik?.values?.suspensions ?? [])];
        let found = nextValues.find(
          s => s.idlieuFormationAgrementSuspension === toSave.idlieuFormationAgrementSuspension
        );
        if (!!found) {
          found.dateDebut = toSave.dateDebut;
          found.dateFin = toSave.dateFin;
          found.fichierSuspension = toSave.fichierSuspension;
          found.idfichierSuspension = !!toSave.fichierSuspension ? toSave.idfichierSuspension : null;
        } else {
          nextValues.push(toSave);
        }
        formik.setFieldValue("suspensions", nextValues);
        formik.setFieldTouched("suspensions", true);
        setData(nextValues);
      }
      setCurrentSuspension(null);
    },
    [setData]
  );

  const fapi = useApiService(FichierApi);
  const downloadFn = React.useCallback(async idfichier => await fapi.fichierDownload({ id: idfichier }), [fapi]);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: LieuFormationAgrementSuspensionDto) =>
          state === "edit" &&
          data?.actif !== false && (
            <ButtonContainer>
              <EditButton minimal={true} onClick={() => onEditClick(row)} />
              <DeleteButton minimal={true} onDelete={() => onDeleteClick(row)} />
            </ButtonContainer>
          )
      },
      {
        header: () => t(ETLCodes.DateDebut),
        fieldName: "dateDebut"
      },
      {
        header: () => t(ETLCodes.DateFin),
        fieldName: "dateFin"
      },
      {
        header: () => t(ETLCodes.Document),
        fieldName: "fichierSuspension",
        render: (row: LieuFormationAgrementSuspensionDto) =>
          row.fichierSuspension &&
          (row.idfichierSuspension ? (
            <LinkButton text={row.fichierSuspension?.fileName} onClick={() => downloadFn(row.idfichierSuspension)} />
          ) : (
            row.fichierSuspension?.fileName
          ))
      }
    ],
    [data?.actif, downloadFn, onDeleteClick, onEditClick, state, t]
  );

  const onAddClick = React.useCallback(
    e => {
      e.stopPropagation();
      setCurrentSuspension(
        LieuFormationAgrementSuspensionDtoFromJSON({
          idlieuFormationAgrement,
          idlieuFormationAgrementSuspension: innerIdIndex.current--
        })
      );
    },
    [idlieuFormationAgrement]
  );

  const additionalRightButtons = React.useCallback(
    (ctx: IFGContext<any>) =>
      +idlieuFormationAgrement <= 0 && (
        <>
          <SaveButton
            onClick={async () => {
              onSavedMode.current = "new";
              ctx.formik.submitForm();
            }}
            text={t(ETLCodes.SauverEtNouveau)}
            minimal={false}
            loading={saving}
          />
          <SaveButton
            onClick={async () => {
              onSavedMode.current = "duplicate";
              ctx.formik.submitForm();
            }}
            text={t(ETLCodes.SauverEtDupliquer)}
            minimal={false}
            loading={saving}
          />
        </>
      ),
    [idlieuFormationAgrement, saving, t]
  );

  const statutColor = React.useMemo(() => {
    if (!data) return "white";
    switch (data.statut) {
      case ELieuFormationAgrementStatut.EnInstance:
      case ELieuFormationAgrementStatut.Agree:
      case ELieuFormationAgrementStatut.PourOffre:
        return theme.sucessColor;
      case ELieuFormationAgrementStatut.Provisoire:
      case ELieuFormationAgrementStatut.Suspendu:
        return theme.warningColor;
      case ELieuFormationAgrementStatut.NonAgree:
      case ELieuFormationAgrementStatut.Retrait:
        return theme.dangerColor;
    }
  }, [data, theme.dangerColor, theme.sucessColor, theme.warningColor]);

  const onCloseRemarque = React.useCallback(
    async (remarque?: string, ctx?: IFGContext<LieuFormationAgrementEditDto>) => {
      setRemarqueOffreDialogOpen(false);
      await save({ ...ctx?.formik?.values, offre: { ...ctx?.formik?.values?.offre, remarque, actif: true } });
    },
    [save]
  );

  const [idmetier, setIdmetier] = React.useState(null);
  const fetchLieuFormationCapaciteFormative = React.useCallback(async () => {
    if (!idmetier) return null;
    return await lieuFormationApi.lieuFormationGetNbContrats({ id: +id, idmetier });
  }, [id, idmetier, lieuFormationApi]);
  const { data: lfCapaciteFormative } = useQuery(
    ["lf-capacite-formative", id, idmetier],
    fetchLieuFormationCapaciteFormative
  );

  // Visites
  const tableStateVisite = useGridState<any>({
    serverMode: false,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { dateVisite: "DESC" }
  });

  const { setData: setVisiteData } = tableStateVisite;
  React.useEffect(() => {
    if (!!data?.visites) {
      setVisiteData(data.visites);
    }
  }, [data?.visites, setVisiteData]);
  const [currentVisite, setCurrentVisite] = React.useState<LieuFormationAgrementVisiteDto>(null);
  const onAddVisiteClick = React.useCallback(() => {
    setCurrentVisite(
      LieuFormationAgrementVisiteDtoFromJSON({
        idlieuFormationAgrementVisite: innerIdIndex.current--,
        idlieuFormationAgrement
      })
    );
  }, [idlieuFormationAgrement]);

  const onVisiteClose = React.useCallback(
    (toSave: LieuFormationAgrementVisiteDto, formik: FormikProps<LieuFormationAgrementEditDto>) => {
      if (!!toSave) {
        let nextValues = [...(formik?.values?.visites ?? [])];
        let found = nextValues.find(s => s.idlieuFormationAgrementVisite === toSave.idlieuFormationAgrementVisite);
        if (!!found) {
          found.dateVisite = toSave.dateVisite;
          found.pratiqueSimulee = toSave.pratiqueSimulee;
          found.compteRendu = toSave.compteRendu;
          found.fichierCompteRendu = toSave.fichierCompteRendu;
          found.idfichierCompteRendu = !!toSave.fichierCompteRendu ? toSave.idfichierCompteRendu : null;
        } else {
          nextValues.push(toSave);
        }
        formik.setFieldValue("visites", nextValues);
        formik.setFieldTouched("visites", true);
        setVisiteData(nextValues);
      }
      setCurrentVisite(null);
    },
    [setVisiteData]
  );
  const onDeleteVisiteClick = React.useCallback(
    async (dto: LieuFormationAgrementVisiteDto) => {
      let nextValues = [...formikRef?.current?.values?.visites];
      nextValues.splice(nextValues.indexOf(dto), 1);
      formikRef?.current.setFieldValue(`visites`, nextValues);
      formikRef?.current.setFieldTouched(`visites`, true);
      setVisiteData(nextValues);
    },
    [setVisiteData]
  );

  const onEditVisiteClick = React.useCallback((dto: LieuFormationAgrementVisiteDto) => {
    setCurrentVisite(dto);
  }, []);

  const visiteColumns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: LieuFormationAgrementVisiteDto) =>
          state === "edit" &&
          data?.actif !== false && (
            <ButtonContainer>
              <EditButton minimal={true} onClick={() => onEditVisiteClick(row)} />
              <DeleteButton minimal={true} onDelete={() => onDeleteVisiteClick(row)} />
            </ButtonContainer>
          )
      },
      {
        header: () => t(ETLCodes.DateVisite),
        fieldName: "dateVisite"
      },
      {
        header: () => t(ETLCodes.PratiqueSimulee),
        fieldName: "pratiqueSimulee",
        autoFitContent: true,
        alignment: "center",
        render: row => <BooleanColumn value={row.pratiqueSimulee} />
      },
      {
        header: () => t(ETLCodes.CompteRendu),
        fieldName: "compteRendu"
      },
      {
        header: () => t(ETLCodes.Document),
        fieldName: "fichierCompteRendu",
        render: (row: LieuFormationAgrementVisiteDto) =>
          row.fichierCompteRendu &&
          (row.idfichierCompteRendu ? (
            <LinkButton
              text={row.fichierCompteRendu?.fileName}
              onClick={async () => exportFile(await downloadFn(row.idfichierCompteRendu))}
            />
          ) : (
            row.fichierCompteRendu?.fileName
          ))
      }
    ],
    [data?.actif, downloadFn, onDeleteVisiteClick, onEditVisiteClick, state, t]
  );

  const decisionRO = React.useMemo(
    () =>
      !!data?.idlieuFormationAgrementDecision &&
      ![
        ELieuFormationAgrementStatut.Provisoire,
        ELieuFormationAgrementStatut.EnInstance,
        ELieuFormationAgrementStatut.PourOffre
      ].includes(data?.statut),
    [data?.idlieuFormationAgrementDecision, data?.statut]
  );

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={mainSave}
      editMode={state === "edit" && data?.actif !== false}
      editable={data?.actif !== false}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(`${ERoutes.lieuFormation}/${+id}/agrements/${state}?idsiegeSocial=${idsiegeSocial}`)}
      saving={saving}
      validationErrors={validationErrors}
      onDelete={deleteItem}
      deleting={deleting}
      showDeleteButton={+idlieuFormationAgrement > 0}
      formikInnerRef={i => (formikRef.current = i)}
      minLabelWidth={220}
      additionalRightButtons={additionalRightButtons}
      forceEnableSave
      touchOnSubmit
      watchChanges={{
        idmetier: (value, formik) => {
          setIdmetier(value);
          if (+value > 0 && formik.dirty) {
            const idstade = rawMetiers?.find(m => +m.idValue === +value)?.keyValue;
            const code = stades?.find(s => +s.idValue === +idstade)?.keyValue;
            formik.setFieldValue("isApprentissage", code === "AP");
          }
        },
        fichierRetrait: (value, formik) => {
          if (formik.dirty) {
            formik.setFieldValue("idfichierRetrait", null);
          }
        }
      }}
    >
      <FGCustomPanel>
        {ctx => (
          <StatutContainer color={statutColor}>
            <span>
              {!!ctx.formik.values?.statut
                ? tUnsafe(`StatutAgrement_${ctx.formik.values?.statut}`) +
                  (ctx.formik.values?.actif === false ? ` (${t(ETLCodes.Inactif)})` : "")
                : t(ETLCodes.EnCoursCreation)}
            </span>
          </StatutContainer>
        )}
      </FGCustomPanel>
      <FieldGroup columns={2}>
        <FieldGroup
          fieldsetProps={{
            title: t(ETLCodes.GeneralInformation),
            rightElement: !!data?.offre && (
              <ViewButton
                text={t(ETLCodes.VoirOffre)}
                intent="primary"
                onClick={() => history.push(`${ERoutes.offre}/${data.offre.idlieuFormationAgrementOffre}/detail/view`)}
              />
            )
          }}
        >
          <FGTextInput
            label={t(ETLCodes.LieuFormation)}
            name="lieuFormation"
            readonly
            loading={displayNameLoading}
            formatReadOnlyValue={() => displayName}
          />
          <FGWalterDateMaskInput name="dateReception" label={t(ETLCodes.DateReception)} />
          <FGWalterSelectInput name="idmetier" label={t(ETLCodes.MetierStade)} items={metiers} loading={mLoading} />
          <FGNumberInput
            label={t(ETLCodes.CapForm)}
            name="capaciteFormative"
            min={0}
            helperText={ctx =>
              +idlieuFormationAgrement > 0 &&
              lfCapaciteFormative !== null &&
              (ctx?.formik?.values?.capaciteFormative ?? 0) >= +lfCapaciteFormative ? (
                <WarningText
                  text={t(
                    (ctx?.formik?.values?.capaciteFormative ?? 0) > +lfCapaciteFormative &&
                      ETLCodes.CapaciteFormativeLieuFormationAgrementVaEtreDepassee
                  )}
                />
              ) : null
            }
          />
          <FGWalterCheckboxInput name="dossierComplet" label={t(ETLCodes.DossierComplet)} />
          <FGWalterSelectInput
            name="idreferentIfapme"
            label={t(ETLCodes.ReferentIfapme)}
            items={users}
            loading={usersLoading}
          />
          <FGTextAreaInput
            name="offre.remarque"
            label={t(ETLCodes.Remarque)}
            readonly
            visible={ctx => !!ctx?.formik?.values?.offre?.actif}
          />
        </FieldGroup>
        <FieldGroup fieldsetProps={{ title: t(ETLCodes.DemandeSectorielle) }}>
          <FGCustomPanel>
            {ctx => <LieuFormationAgrementDemandeSectorielle disabled={!ctx?.formik?.values?.isApprentissage} />}
          </FGCustomPanel>
        </FieldGroup>
        <FGCustomPanel>
          {(ctx: IFGContext<LieuFormationAgrementEditDto>) => (
            <FieldGroup
              fieldsetProps={{ title: t(ETLCodes.Visites) }}
              rightElement={
                ctx.editMode && (
                  <AddButton onClick={onAddVisiteClick} text={t(ETLCodes.AddVisite)} intent={Intent.PRIMARY} />
                )
              }
            >
              <DataTable
                dateFormat="dd/MM/yyyy"
                tableState={tableStateVisite}
                loading={loading}
                columns={visiteColumns}
                filterMode="OnEnter"
              ></DataTable>
              {!!currentVisite && (
                <LieuFormationAgrementVisiteDialog
                  dialogOpen={!!currentVisite}
                  currentVisite={currentVisite}
                  onClose={toSave => onVisiteClose(toSave, ctx.formik)}
                />
              )}
            </FieldGroup>
          )}
        </FGCustomPanel>
        <FieldGroup fieldsetProps={{ title: t(ETLCodes.DecisionAgrement) }}>
          <FGCustomPanel>
            {ctx => (
              <LieuFormationAgrementDecision
                downloadFn={() => downloadFn(ctx.formik.values?.idfichierEnvoiD3)}
                decisionRO={decisionRO}
              />
            )}
          </FGCustomPanel>
        </FieldGroup>
        <FieldGroup fieldsetProps={{ title: t(ETLCodes.AgrementPartiel) }}>
          <FGWalterCheckboxInput name="agrementPartielA" label={t(ETLCodes.AgrementPartielA)} />
          <FGWalterCheckboxInput name="agrementPartielB" label={t(ETLCodes.AgrementPartielB)} />
        </FieldGroup>
      </FieldGroup>
      <FGCustomPanel>
        {(ctx: IFGContext<LieuFormationAgrementEditDto>) => (
          <FieldGroup
            fieldsetProps={{ title: t(ETLCodes.Suspensions) }}
            collapsable
            collapsed
            rightElement={
              ctx.editMode && (
                <AddButton
                  onClick={onAddClick}
                  text={t(ETLCodes.AddSuspension)}
                  intent={Intent.PRIMARY}
                  disabled={ctx?.formik?.values?.suspensions?.some(
                    s =>
                      (isBefore(s.dateDebut, new Date()) || isSameDay(s.dateDebut, new Date())) &&
                      (!s.dateFin || isAfter(s.dateFin, new Date()))
                  )}
                />
              )
            }
          >
            <DataTable
              dateFormat="dd/MM/yyyy"
              tableState={tableState}
              loading={loading}
              columns={columns}
              filterMode="OnEnter"
            ></DataTable>
            {!!currentSuspension && (
              <LieuFormationAgrementSuspensionDetailDialog
                dialogOpen={!!currentSuspension}
                currentSuspension={currentSuspension}
                onClose={toSave => onClose(toSave, ctx.formik)}
              />
            )}
          </FieldGroup>
        )}
      </FGCustomPanel>
      <FieldGroup fieldsetProps={{ title: t(ETLCodes.Retrait) }} columns={2} collapsable collapsed>
        <FieldGroup>
          <FGWalterDateMaskInput name="datePriseEffetRetrait" label={t(ETLCodes.DatePriseEffet)} />
          <FGWalterDateMaskInput name="dateRetrait" label={t(ETLCodes.DateDecisionRetrait)} />
          <FGCustomInput label={t(ETLCodes.OrganismeResponsableRetrait)}>
            {ctx =>
              ctx?.editMode ? (
                <RadioGroup
                  selectedValue={ctx?.formik?.values?.retraitIsIfapme ? "1" : "0"}
                  inline
                  onChange={e => ctx.formik.setFieldValue("retraitIsIfapme", e.currentTarget.value === "1")}
                >
                  <Radio label={t(ETLCodes.IFAPME)} value="1"></Radio>
                  <Radio label={t(ETLCodes.CEFA)} value="0"></Radio>
                </RadioGroup>
              ) : (
                <RadioReadonlyContainer>
                  {!ctx?.formik?.values?.retraitIsIfapme ? t(ETLCodes.IFAPME) : t(ETLCodes.CEFA)}
                </RadioReadonlyContainer>
              )
            }
          </FGCustomInput>
          <FGMultiSuggestInput
            name="idsMotifRetrait"
            label={t(ETLCodes.MotifsRetraitAgrement)}
            items={motifsRetrait}
            loading={mrLoading}
            requiredMark
          />
          <FGTextAreaInput name="remarqueRetrait" label={t(ETLCodes.Remarque)} />
          <FGCustomPanel>
            {ctx => (
              <FGWalterFileInput
                name="fichierRetrait"
                label={t(ETLCodes.Document)}
                downloadFn={
                  ctx.formik.values?.idfichierRetrait && (() => downloadFn(ctx.formik.values?.idfichierRetrait))
                }
              />
            )}
          </FGCustomPanel>
        </FieldGroup>
      </FieldGroup>
      <FGCustomPanel>
        {(ctx: IFGContext<LieuFormationAgrementEditDto>) => (
          <StyledDialog
            title={t(ETLCodes.LieuFormationAgrementOffreTitle)}
            isOpen={remarqueOffreDialogOpen}
            onClose={() => onCloseRemarque()}
          >
            <div className={Classes.DIALOG_BODY}>
              <SmallFormGenerator
                initialValues={{ remarque: data?.offre?.remarque }}
                onSubmit={d => onCloseRemarque(d.remarque, ctx)}
                editMode={true}
                onCancel={onCloseRemarque}
                showDeleteButton={false}
                enableDirtyCheck={false}
                forceEnableSave
              >
                <FieldGroup>
                  <FGTextAreaInput name="remarque" label={t(ETLCodes.Remarque)} />
                </FieldGroup>
              </SmallFormGenerator>
            </div>
          </StyledDialog>
        )}
      </FGCustomPanel>
    </SmallFormGenerator>
  );
};
