import * as React from "react";

import { useRouteMatch, Switch, Route } from "react-router";
import { ApprenantJustificatifsSearch } from "./ApprenantJustificatifsSearch";
import { ApprenantJustificatifsDetail } from "./ApprenantJustificatifDetail";

interface IApprenantJustificatifsProps {}

export const ApprenantJustificatifs: React.FunctionComponent<IApprenantJustificatifsProps> = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={ApprenantJustificatifsSearch} exact />
      <Route path={`${match.path}/:justificatifId/:state`} component={ApprenantJustificatifsDetail} />
    </Switch>
  );
};
