/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormateurSuiviFichierDto
 */
export interface FormateurSuiviFichierDto {
    /**
     * 
     * @type {number}
     * @memberof FormateurSuiviFichierDto
     */
    idformateurSuiviFichier?: number;
    /**
     * 
     * @type {number}
     * @memberof FormateurSuiviFichierDto
     */
    idformateurSuivi?: number;
    /**
     * 
     * @type {number}
     * @memberof FormateurSuiviFichierDto
     */
    idfichier?: number;
    /**
     * 
     * @type {number}
     * @memberof FormateurSuiviFichierDto
     */
    idtypeSuiviFichier?: number;
    /**
     * 
     * @type {string}
     * @memberof FormateurSuiviFichierDto
     */
    fileName?: string | null;
}

/**
 * Check if a given object implements the FormateurSuiviFichierDto interface.
 */
export function instanceOfFormateurSuiviFichierDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FormateurSuiviFichierDtoFromJSON(json: any): FormateurSuiviFichierDto {
    return FormateurSuiviFichierDtoFromJSONTyped(json, false);
}

export function FormateurSuiviFichierDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormateurSuiviFichierDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idformateurSuiviFichier': !exists(json, 'idformateurSuiviFichier') ? undefined : json['idformateurSuiviFichier'],
        'idformateurSuivi': !exists(json, 'idformateurSuivi') ? undefined : json['idformateurSuivi'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'idtypeSuiviFichier': !exists(json, 'idtypeSuiviFichier') ? undefined : json['idtypeSuiviFichier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
    };
}

export function FormateurSuiviFichierDtoToJSON(value?: FormateurSuiviFichierDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idformateurSuiviFichier': value.idformateurSuiviFichier,
        'idformateurSuivi': value.idformateurSuivi,
        'idfichier': value.idfichier,
        'idtypeSuiviFichier': value.idtypeSuiviFichier,
        'fileName': value.fileName,
    };
}

