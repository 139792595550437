/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BaremeTheoriqueDto,
  BooleanDto,
  ContratApprenantInfosDto,
  ContratAvenantEditDto,
  ContratAvenantGridDtoPaginatedResults,
  ContratAvenantSearchDto,
  ContratClasseInfosDto,
  ContratEditDto,
  ContratGridDtoPaginatedResults,
  ContratHistoryGridDtoPaginatedResults,
  ContratHistorySearchDto,
  ContratInfosForSuiviDto,
  ContratLieuxFormationGridDtoPaginatedResults,
  ContratLieuxFormationSearchDto,
  ContratSearchDto,
  ContratSiegeSocialDto,
  ContratSuspensionDto,
  EIfapmeSide,
  EStatutContrat,
  ETypeContrat,
  FilterCriteriaInfo,
  ReferentialItemDto,
} from '../models/index';
import {
    BaremeTheoriqueDtoFromJSON,
    BaremeTheoriqueDtoToJSON,
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    ContratApprenantInfosDtoFromJSON,
    ContratApprenantInfosDtoToJSON,
    ContratAvenantEditDtoFromJSON,
    ContratAvenantEditDtoToJSON,
    ContratAvenantGridDtoPaginatedResultsFromJSON,
    ContratAvenantGridDtoPaginatedResultsToJSON,
    ContratAvenantSearchDtoFromJSON,
    ContratAvenantSearchDtoToJSON,
    ContratClasseInfosDtoFromJSON,
    ContratClasseInfosDtoToJSON,
    ContratEditDtoFromJSON,
    ContratEditDtoToJSON,
    ContratGridDtoPaginatedResultsFromJSON,
    ContratGridDtoPaginatedResultsToJSON,
    ContratHistoryGridDtoPaginatedResultsFromJSON,
    ContratHistoryGridDtoPaginatedResultsToJSON,
    ContratHistorySearchDtoFromJSON,
    ContratHistorySearchDtoToJSON,
    ContratInfosForSuiviDtoFromJSON,
    ContratInfosForSuiviDtoToJSON,
    ContratLieuxFormationGridDtoPaginatedResultsFromJSON,
    ContratLieuxFormationGridDtoPaginatedResultsToJSON,
    ContratLieuxFormationSearchDtoFromJSON,
    ContratLieuxFormationSearchDtoToJSON,
    ContratSearchDtoFromJSON,
    ContratSearchDtoToJSON,
    ContratSiegeSocialDtoFromJSON,
    ContratSiegeSocialDtoToJSON,
    ContratSuspensionDtoFromJSON,
    ContratSuspensionDtoToJSON,
    EIfapmeSideFromJSON,
    EIfapmeSideToJSON,
    EStatutContratFromJSON,
    EStatutContratToJSON,
    ETypeContratFromJSON,
    ETypeContratToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface ContratBaseSearchRequest {
    ContratSearchDto?: ContratSearchDto;
}

export interface ContratCanSortirApprenantRequest {
    idApprenant?: number;
    ifapmeSide?: EIfapmeSide;
}

export interface ContratDeleteAvenantRequest {
    idavenant?: number;
}

export interface ContratEditSuspensionRequest {
    ContratSuspensionDto?: ContratSuspensionDto;
}

export interface ContratGetApprenantInfosRequest {
    idapprenant?: number;
}

export interface ContratGetBaremesByDureeRequest {
    iddureeContrat?: number;
    oldCs?: boolean;
}

export interface ContratGetClasseInfosRequest {
    idclasse?: number;
    idapprenant?: number;
}

export interface ContratGetContratRequest {
    id?: number;
    typeContrat?: ETypeContrat;
    idapprenant?: number;
}

export interface ContratGetContratAvenantRequest {
    idavenant?: number;
}

export interface ContratGetContratInfosForSuiviRequest {
    idcontrat?: number;
}

export interface ContratGetDisplayNameRequest {
    id?: number;
}

export interface ContratGetMainLieuFormationCPRequest {
    idcontrat?: number;
}

export interface ContratGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ContratGetSelectItemsRequest {
    searchField: string;
}

export interface ContratGetSiegeSocialInfosRequest {
    idsiegeSocial?: number;
}

export interface ContratGetStatutRequest {
    idcontrat?: number;
}

export interface ContratGetSuspensionRequest {
    idcontratSuspension?: number;
}

export interface ContratGetTypeRequest {
    idcontrat?: number;
}

export interface ContratHasOffreActiveRequest {
    idcontrat?: number;
}

export interface ContratRuptureContratRequest {
    ContratEditDto?: ContratEditDto;
}

export interface ContratSaveAvenantsRequest {
    ContratAvenantEditDto?: ContratAvenantEditDto;
}

export interface ContratSaveContratRequest {
    ContratEditDto?: ContratEditDto;
}

export interface ContratSearchAvenantsRequest {
    ContratAvenantSearchDto?: ContratAvenantSearchDto;
}

export interface ContratSearchHistoryRequest {
    ContratHistorySearchDto?: ContratHistorySearchDto;
}

export interface ContratSearchLieuxFormationRequest {
    ContratLieuxFormationSearchDto?: ContratLieuxFormationSearchDto;
}

/**
 * 
 */
export class ContratApi extends runtime.BaseAPI {

    /**
     */
    async contratBaseSearchRaw(requestParameters: ContratBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContratSearchDtoToJSON(requestParameters.ContratSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async contratBaseSearch(requestParameters: ContratBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratGridDtoPaginatedResults> {
        const response = await this.contratBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratCanSortirApprenantRaw(requestParameters: ContratCanSortirApprenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idApprenant !== undefined) {
            queryParameters['idApprenant'] = requestParameters.idApprenant;
        }

        if (requestParameters.ifapmeSide !== undefined) {
            queryParameters['ifapmeSide'] = requestParameters.ifapmeSide;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/CanSortirApprenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async contratCanSortirApprenant(requestParameters: ContratCanSortirApprenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.contratCanSortirApprenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratDeleteAvenantRaw(requestParameters: ContratDeleteAvenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idavenant !== undefined) {
            queryParameters['idavenant'] = requestParameters.idavenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/DeleteAvenant`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async contratDeleteAvenant(requestParameters: ContratDeleteAvenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.contratDeleteAvenantRaw(requestParameters, initOverrides);
    }

    /**
     */
    async contratEditSuspensionRaw(requestParameters: ContratEditSuspensionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratSuspensionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/EditSuspension`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContratSuspensionDtoToJSON(requestParameters.ContratSuspensionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratSuspensionDtoFromJSON(jsonValue));
    }

    /**
     */
    async contratEditSuspension(requestParameters: ContratEditSuspensionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratSuspensionDto> {
        const response = await this.contratEditSuspensionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratGetApprenantInfosRaw(requestParameters: ContratGetApprenantInfosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratApprenantInfosDto>> {
        const queryParameters: any = {};

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/GetApprenantInfos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratApprenantInfosDtoFromJSON(jsonValue));
    }

    /**
     */
    async contratGetApprenantInfos(requestParameters: ContratGetApprenantInfosRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratApprenantInfosDto> {
        const response = await this.contratGetApprenantInfosRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratGetBaremesByDureeRaw(requestParameters: ContratGetBaremesByDureeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BaremeTheoriqueDto>>> {
        const queryParameters: any = {};

        if (requestParameters.iddureeContrat !== undefined) {
            queryParameters['iddureeContrat'] = requestParameters.iddureeContrat;
        }

        if (requestParameters.oldCs !== undefined) {
            queryParameters['oldCs'] = requestParameters.oldCs;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/GetBaremesByDuree`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BaremeTheoriqueDtoFromJSON));
    }

    /**
     */
    async contratGetBaremesByDuree(requestParameters: ContratGetBaremesByDureeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BaremeTheoriqueDto>> {
        const response = await this.contratGetBaremesByDureeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratGetClasseInfosRaw(requestParameters: ContratGetClasseInfosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratClasseInfosDto>> {
        const queryParameters: any = {};

        if (requestParameters.idclasse !== undefined) {
            queryParameters['idclasse'] = requestParameters.idclasse;
        }

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/GetClasseInfos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratClasseInfosDtoFromJSON(jsonValue));
    }

    /**
     */
    async contratGetClasseInfos(requestParameters: ContratGetClasseInfosRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratClasseInfosDto> {
        const response = await this.contratGetClasseInfosRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratGetContratRaw(requestParameters: ContratGetContratRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.typeContrat !== undefined) {
            queryParameters['typeContrat'] = requestParameters.typeContrat;
        }

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async contratGetContrat(requestParameters: ContratGetContratRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratEditDto> {
        const response = await this.contratGetContratRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratGetContratAvenantRaw(requestParameters: ContratGetContratAvenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratAvenantEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.idavenant !== undefined) {
            queryParameters['idavenant'] = requestParameters.idavenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/GetContratAvenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratAvenantEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async contratGetContratAvenant(requestParameters: ContratGetContratAvenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratAvenantEditDto> {
        const response = await this.contratGetContratAvenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratGetContratInfosForSuiviRaw(requestParameters: ContratGetContratInfosForSuiviRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratInfosForSuiviDto>> {
        const queryParameters: any = {};

        if (requestParameters.idcontrat !== undefined) {
            queryParameters['idcontrat'] = requestParameters.idcontrat;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/GetContratInfosForSuivi`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratInfosForSuiviDtoFromJSON(jsonValue));
    }

    /**
     */
    async contratGetContratInfosForSuivi(requestParameters: ContratGetContratInfosForSuiviRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratInfosForSuiviDto> {
        const response = await this.contratGetContratInfosForSuiviRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratGetDisplayNameRaw(requestParameters: ContratGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async contratGetDisplayName(requestParameters: ContratGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.contratGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratGetMainLieuFormationCPRaw(requestParameters: ContratGetMainLieuFormationCPRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idcontrat !== undefined) {
            queryParameters['idcontrat'] = requestParameters.idcontrat;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/GetMainLieuFormationCP`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async contratGetMainLieuFormationCP(requestParameters: ContratGetMainLieuFormationCPRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.contratGetMainLieuFormationCPRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratGetSearchCriteriasRaw(requestParameters: ContratGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async contratGetSearchCriterias(requestParameters: ContratGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.contratGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratGetSelectItemsRaw(requestParameters: ContratGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling contratGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async contratGetSelectItems(requestParameters: ContratGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.contratGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratGetSiegeSocialInfosRaw(requestParameters: ContratGetSiegeSocialInfosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratSiegeSocialDto>> {
        const queryParameters: any = {};

        if (requestParameters.idsiegeSocial !== undefined) {
            queryParameters['idsiegeSocial'] = requestParameters.idsiegeSocial;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/GetSiegeSocialInfos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratSiegeSocialDtoFromJSON(jsonValue));
    }

    /**
     */
    async contratGetSiegeSocialInfos(requestParameters: ContratGetSiegeSocialInfosRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratSiegeSocialDto> {
        const response = await this.contratGetSiegeSocialInfosRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratGetStatutRaw(requestParameters: ContratGetStatutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EStatutContrat>> {
        const queryParameters: any = {};

        if (requestParameters.idcontrat !== undefined) {
            queryParameters['idcontrat'] = requestParameters.idcontrat;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/GetStatut`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EStatutContratFromJSON(jsonValue));
    }

    /**
     */
    async contratGetStatut(requestParameters: ContratGetStatutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EStatutContrat> {
        const response = await this.contratGetStatutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratGetSuspensionRaw(requestParameters: ContratGetSuspensionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratSuspensionDto>> {
        const queryParameters: any = {};

        if (requestParameters.idcontratSuspension !== undefined) {
            queryParameters['idcontratSuspension'] = requestParameters.idcontratSuspension;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/GetSuspension`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratSuspensionDtoFromJSON(jsonValue));
    }

    /**
     */
    async contratGetSuspension(requestParameters: ContratGetSuspensionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratSuspensionDto> {
        const response = await this.contratGetSuspensionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratGetTypeRaw(requestParameters: ContratGetTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ETypeContrat>> {
        const queryParameters: any = {};

        if (requestParameters.idcontrat !== undefined) {
            queryParameters['idcontrat'] = requestParameters.idcontrat;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/GetType`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ETypeContratFromJSON(jsonValue));
    }

    /**
     */
    async contratGetType(requestParameters: ContratGetTypeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ETypeContrat> {
        const response = await this.contratGetTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratHasOffreActiveRaw(requestParameters: ContratHasOffreActiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idcontrat !== undefined) {
            queryParameters['idcontrat'] = requestParameters.idcontrat;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/HasOffreActive`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async contratHasOffreActive(requestParameters: ContratHasOffreActiveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.contratHasOffreActiveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratRuptureContratRaw(requestParameters: ContratRuptureContratRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/Rupture`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContratEditDtoToJSON(requestParameters.ContratEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async contratRuptureContrat(requestParameters: ContratRuptureContratRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratEditDto> {
        const response = await this.contratRuptureContratRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratSaveAvenantsRaw(requestParameters: ContratSaveAvenantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratAvenantEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/SaveAvenants`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContratAvenantEditDtoToJSON(requestParameters.ContratAvenantEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratAvenantEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async contratSaveAvenants(requestParameters: ContratSaveAvenantsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratAvenantEditDto> {
        const response = await this.contratSaveAvenantsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratSaveContratRaw(requestParameters: ContratSaveContratRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContratEditDtoToJSON(requestParameters.ContratEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async contratSaveContrat(requestParameters: ContratSaveContratRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratEditDto> {
        const response = await this.contratSaveContratRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratSearchAvenantsRaw(requestParameters: ContratSearchAvenantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratAvenantGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/SearchAvenants`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContratAvenantSearchDtoToJSON(requestParameters.ContratAvenantSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratAvenantGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async contratSearchAvenants(requestParameters: ContratSearchAvenantsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratAvenantGridDtoPaginatedResults> {
        const response = await this.contratSearchAvenantsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratSearchHistoryRaw(requestParameters: ContratSearchHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratHistoryGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/SearchHistory`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContratHistorySearchDtoToJSON(requestParameters.ContratHistorySearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratHistoryGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async contratSearchHistory(requestParameters: ContratSearchHistoryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratHistoryGridDtoPaginatedResults> {
        const response = await this.contratSearchHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratSearchLieuxFormationRaw(requestParameters: ContratSearchLieuxFormationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratLieuxFormationGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Contrat/SearchLieuxFormation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContratLieuxFormationSearchDtoToJSON(requestParameters.ContratLieuxFormationSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratLieuxFormationGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async contratSearchLieuxFormation(requestParameters: ContratSearchLieuxFormationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratLieuxFormationGridDtoPaginatedResults> {
        const response = await this.contratSearchLieuxFormationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
