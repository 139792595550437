/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdresseDto } from './AdresseDto';
import {
    AdresseDtoFromJSON,
    AdresseDtoFromJSONTyped,
    AdresseDtoToJSON,
} from './AdresseDto';
import type { CompteBancaireDto } from './CompteBancaireDto';
import {
    CompteBancaireDtoFromJSON,
    CompteBancaireDtoFromJSONTyped,
    CompteBancaireDtoToJSON,
} from './CompteBancaireDto';
import type { EmailDto } from './EmailDto';
import {
    EmailDtoFromJSON,
    EmailDtoFromJSONTyped,
    EmailDtoToJSON,
} from './EmailDto';
import type { TelephoneDto } from './TelephoneDto';
import {
    TelephoneDtoFromJSON,
    TelephoneDtoFromJSONTyped,
    TelephoneDtoToJSON,
} from './TelephoneDto';

/**
 * 
 * @export
 * @interface FcbFormateurDto
 */
export interface FcbFormateurDto {
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDto
     */
    idformateur?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDto
     */
    identreprise?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDto
     */
    prenom2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDto
     */
    codesexe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDto
     */
    registreNational?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDto
     */
    numeroIdentification?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDto
     */
    codeNationalite?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDto
     */
    localiteNaissance?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDto
     */
    communeNaissance?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDto
     */
    paysNaissance?: string | null;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof FcbFormateurDto
     */
    telephoneFormateur?: TelephoneDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof FcbFormateurDto
     */
    telephoneProfFormateur?: TelephoneDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof FcbFormateurDto
     */
    gsmFormateur?: TelephoneDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof FcbFormateurDto
     */
    gsmProfFormateur?: TelephoneDto;
    /**
     * 
     * @type {EmailDto}
     * @memberof FcbFormateurDto
     */
    emailFormateur?: EmailDto;
    /**
     * 
     * @type {EmailDto}
     * @memberof FcbFormateurDto
     */
    emailProfFormateur?: EmailDto;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDto
     */
    photo?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDto
     */
    idfichierPhoto?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDto
     */
    idcivilite?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDto
     */
    precompte?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurDto
     */
    dateDeces?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDto
     */
    enfantCharge?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFormateurDto
     */
    conjointCharge?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDto
     */
    autreCharge?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDto
     */
    enfantHandicape?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDto
     */
    autreHandicape?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFormateurDto
     */
    handicape?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDto
     */
    activite?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurDto
     */
    datePremierContrat?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDto
     */
    ancienMatricule?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFormateurDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFormateurDto
     */
    courrierDomicile?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFormateurDto
     */
    courrierAutre?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFormateurDto
     */
    jure?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDto
     */
    numero?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDto
     */
    boite?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDto
     */
    idcodeLangue?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFormateurDto
     */
    frontalier?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDto
     */
    emailProf?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDto
     */
    searchNom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDto
     */
    searchPrenom?: string | null;
    /**
     * 
     * @type {CompteBancaireDto}
     * @memberof FcbFormateurDto
     */
    compteBancaire?: CompteBancaireDto;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDto
     */
    azureUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDto
     */
    azureEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDto
     */
    azureTemporaryPassword?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFormateurDto
     */
    azureActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDto
     */
    idpersonne?: number | null;
    /**
     * 
     * @type {AdresseDto}
     * @memberof FcbFormateurDto
     */
    adresseFormateur?: AdresseDto;
    /**
     * 
     * @type {AdresseDto}
     * @memberof FcbFormateurDto
     */
    adresseDomicileFormateur?: AdresseDto;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFormateurDto
     */
    hasAddressDoublons?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFormateurDto
     */
    hasAddressDomicileDoublons?: boolean;
}

/**
 * Check if a given object implements the FcbFormateurDto interface.
 */
export function instanceOfFcbFormateurDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbFormateurDtoFromJSON(json: any): FcbFormateurDto {
    return FcbFormateurDtoFromJSONTyped(json, false);
}

export function FcbFormateurDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbFormateurDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'identreprise': !exists(json, 'identreprise') ? undefined : json['identreprise'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'prenom2': !exists(json, 'prenom2') ? undefined : json['prenom2'],
        'codesexe': !exists(json, 'codesexe') ? undefined : json['codesexe'],
        'registreNational': !exists(json, 'registreNational') ? undefined : json['registreNational'],
        'numeroIdentification': !exists(json, 'numeroIdentification') ? undefined : json['numeroIdentification'],
        'codeNationalite': !exists(json, 'codeNationalite') ? undefined : json['codeNationalite'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'localiteNaissance': !exists(json, 'localiteNaissance') ? undefined : json['localiteNaissance'],
        'communeNaissance': !exists(json, 'communeNaissance') ? undefined : json['communeNaissance'],
        'paysNaissance': !exists(json, 'paysNaissance') ? undefined : json['paysNaissance'],
        'telephoneFormateur': !exists(json, 'telephoneFormateur') ? undefined : TelephoneDtoFromJSON(json['telephoneFormateur']),
        'telephoneProfFormateur': !exists(json, 'telephoneProfFormateur') ? undefined : TelephoneDtoFromJSON(json['telephoneProfFormateur']),
        'gsmFormateur': !exists(json, 'gsmFormateur') ? undefined : TelephoneDtoFromJSON(json['gsmFormateur']),
        'gsmProfFormateur': !exists(json, 'gsmProfFormateur') ? undefined : TelephoneDtoFromJSON(json['gsmProfFormateur']),
        'emailFormateur': !exists(json, 'emailFormateur') ? undefined : EmailDtoFromJSON(json['emailFormateur']),
        'emailProfFormateur': !exists(json, 'emailProfFormateur') ? undefined : EmailDtoFromJSON(json['emailProfFormateur']),
        'photo': !exists(json, 'photo') ? undefined : json['photo'],
        'idfichierPhoto': !exists(json, 'idfichierPhoto') ? undefined : json['idfichierPhoto'],
        'idcivilite': !exists(json, 'idcivilite') ? undefined : json['idcivilite'],
        'precompte': !exists(json, 'precompte') ? undefined : json['precompte'],
        'dateDeces': !exists(json, 'dateDeces') ? undefined : (json['dateDeces'] === null ? null : new Date(json['dateDeces'])),
        'enfantCharge': !exists(json, 'enfantCharge') ? undefined : json['enfantCharge'],
        'conjointCharge': !exists(json, 'conjointCharge') ? undefined : json['conjointCharge'],
        'autreCharge': !exists(json, 'autreCharge') ? undefined : json['autreCharge'],
        'enfantHandicape': !exists(json, 'enfantHandicape') ? undefined : json['enfantHandicape'],
        'autreHandicape': !exists(json, 'autreHandicape') ? undefined : json['autreHandicape'],
        'handicape': !exists(json, 'handicape') ? undefined : json['handicape'],
        'activite': !exists(json, 'activite') ? undefined : json['activite'],
        'datePremierContrat': !exists(json, 'datePremierContrat') ? undefined : (json['datePremierContrat'] === null ? null : new Date(json['datePremierContrat'])),
        'ancienMatricule': !exists(json, 'ancienMatricule') ? undefined : json['ancienMatricule'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'courrierDomicile': !exists(json, 'courrierDomicile') ? undefined : json['courrierDomicile'],
        'courrierAutre': !exists(json, 'courrierAutre') ? undefined : json['courrierAutre'],
        'jure': !exists(json, 'jure') ? undefined : json['jure'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'numero': !exists(json, 'numero') ? undefined : json['numero'],
        'boite': !exists(json, 'boite') ? undefined : json['boite'],
        'idcodeLangue': !exists(json, 'idcodeLangue') ? undefined : json['idcodeLangue'],
        'frontalier': !exists(json, 'frontalier') ? undefined : json['frontalier'],
        'emailProf': !exists(json, 'emailProf') ? undefined : json['emailProf'],
        'searchNom': !exists(json, 'searchNom') ? undefined : json['searchNom'],
        'searchPrenom': !exists(json, 'searchPrenom') ? undefined : json['searchPrenom'],
        'compteBancaire': !exists(json, 'compteBancaire') ? undefined : CompteBancaireDtoFromJSON(json['compteBancaire']),
        'azureUserId': !exists(json, 'azureUserId') ? undefined : json['azureUserId'],
        'azureEmail': !exists(json, 'azureEmail') ? undefined : json['azureEmail'],
        'azureTemporaryPassword': !exists(json, 'azureTemporaryPassword') ? undefined : json['azureTemporaryPassword'],
        'azureActive': !exists(json, 'azureActive') ? undefined : json['azureActive'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'adresseFormateur': !exists(json, 'adresseFormateur') ? undefined : AdresseDtoFromJSON(json['adresseFormateur']),
        'adresseDomicileFormateur': !exists(json, 'adresseDomicileFormateur') ? undefined : AdresseDtoFromJSON(json['adresseDomicileFormateur']),
        'hasAddressDoublons': !exists(json, 'hasAddressDoublons') ? undefined : json['hasAddressDoublons'],
        'hasAddressDomicileDoublons': !exists(json, 'hasAddressDomicileDoublons') ? undefined : json['hasAddressDomicileDoublons'],
    };
}

export function FcbFormateurDtoToJSON(value?: FcbFormateurDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idformateur': value.idformateur,
        'identreprise': value.identreprise,
        'nom': value.nom,
        'prenom': value.prenom,
        'prenom2': value.prenom2,
        'codesexe': value.codesexe,
        'registreNational': value.registreNational,
        'numeroIdentification': value.numeroIdentification,
        'codeNationalite': value.codeNationalite,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'localiteNaissance': value.localiteNaissance,
        'communeNaissance': value.communeNaissance,
        'paysNaissance': value.paysNaissance,
        'telephoneFormateur': TelephoneDtoToJSON(value.telephoneFormateur),
        'telephoneProfFormateur': TelephoneDtoToJSON(value.telephoneProfFormateur),
        'gsmFormateur': TelephoneDtoToJSON(value.gsmFormateur),
        'gsmProfFormateur': TelephoneDtoToJSON(value.gsmProfFormateur),
        'emailFormateur': EmailDtoToJSON(value.emailFormateur),
        'emailProfFormateur': EmailDtoToJSON(value.emailProfFormateur),
        'photo': value.photo,
        'idfichierPhoto': value.idfichierPhoto,
        'idcivilite': value.idcivilite,
        'precompte': value.precompte,
        'dateDeces': value.dateDeces === undefined ? undefined : (value.dateDeces === null ? null : value.dateDeces.toISOString()),
        'enfantCharge': value.enfantCharge,
        'conjointCharge': value.conjointCharge,
        'autreCharge': value.autreCharge,
        'enfantHandicape': value.enfantHandicape,
        'autreHandicape': value.autreHandicape,
        'handicape': value.handicape,
        'activite': value.activite,
        'datePremierContrat': value.datePremierContrat === undefined ? undefined : (value.datePremierContrat === null ? null : value.datePremierContrat.toISOString()),
        'ancienMatricule': value.ancienMatricule,
        'actif': value.actif,
        'courrierDomicile': value.courrierDomicile,
        'courrierAutre': value.courrierAutre,
        'jure': value.jure,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'numero': value.numero,
        'boite': value.boite,
        'idcodeLangue': value.idcodeLangue,
        'frontalier': value.frontalier,
        'emailProf': value.emailProf,
        'searchNom': value.searchNom,
        'searchPrenom': value.searchPrenom,
        'compteBancaire': CompteBancaireDtoToJSON(value.compteBancaire),
        'azureUserId': value.azureUserId,
        'azureEmail': value.azureEmail,
        'azureTemporaryPassword': value.azureTemporaryPassword,
        'azureActive': value.azureActive,
        'idpersonne': value.idpersonne,
        'adresseFormateur': AdresseDtoToJSON(value.adresseFormateur),
        'adresseDomicileFormateur': AdresseDtoToJSON(value.adresseDomicileFormateur),
        'hasAddressDoublons': value.hasAddressDoublons,
        'hasAddressDomicileDoublons': value.hasAddressDomicileDoublons,
    };
}

