/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRechercheClasseHoraireRemplacementOccasionnelDto
 */
export interface FcbRechercheClasseHoraireRemplacementOccasionnelDto {
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseHoraireRemplacementOccasionnelDto
     */
    classe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseHoraireRemplacementOccasionnelDto
     */
    classeMatiere?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRechercheClasseHoraireRemplacementOccasionnelDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseHoraireRemplacementOccasionnelDto
     */
    heure?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseHoraireRemplacementOccasionnelDto
     */
    remplacant?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseHoraireRemplacementOccasionnelDto
     */
    idremplacant?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseHoraireRemplacementOccasionnelDto
     */
    idremplacement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseHoraireRemplacementOccasionnelDto
     */
    idclasseHoraire?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbRechercheClasseHoraireRemplacementOccasionnelDto
     */
    reporte?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseHoraireRemplacementOccasionnelDto
     */
    idcentre?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseHoraireRemplacementOccasionnelDto
     */
    contrat?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseHoraireRemplacementOccasionnelDto
     */
    idformateurLocalisationContrat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseHoraireRemplacementOccasionnelDto
     */
    iduser?: number | null;
}

/**
 * Check if a given object implements the FcbRechercheClasseHoraireRemplacementOccasionnelDto interface.
 */
export function instanceOfFcbRechercheClasseHoraireRemplacementOccasionnelDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRechercheClasseHoraireRemplacementOccasionnelDtoFromJSON(json: any): FcbRechercheClasseHoraireRemplacementOccasionnelDto {
    return FcbRechercheClasseHoraireRemplacementOccasionnelDtoFromJSONTyped(json, false);
}

export function FcbRechercheClasseHoraireRemplacementOccasionnelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRechercheClasseHoraireRemplacementOccasionnelDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'classe': !exists(json, 'classe') ? undefined : json['classe'],
        'classeMatiere': !exists(json, 'classeMatiere') ? undefined : json['classeMatiere'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'heure': !exists(json, 'heure') ? undefined : json['heure'],
        'remplacant': !exists(json, 'remplacant') ? undefined : json['remplacant'],
        'idremplacant': !exists(json, 'idremplacant') ? undefined : json['idremplacant'],
        'idremplacement': !exists(json, 'idremplacement') ? undefined : json['idremplacement'],
        'idclasseHoraire': !exists(json, 'idclasseHoraire') ? undefined : json['idclasseHoraire'],
        'reporte': !exists(json, 'reporte') ? undefined : json['reporte'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'contrat': !exists(json, 'contrat') ? undefined : json['contrat'],
        'idformateurLocalisationContrat': !exists(json, 'idformateurLocalisationContrat') ? undefined : json['idformateurLocalisationContrat'],
        'iduser': !exists(json, 'iduser') ? undefined : json['iduser'],
    };
}

export function FcbRechercheClasseHoraireRemplacementOccasionnelDtoToJSON(value?: FcbRechercheClasseHoraireRemplacementOccasionnelDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'classe': value.classe,
        'classeMatiere': value.classeMatiere,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'heure': value.heure,
        'remplacant': value.remplacant,
        'idremplacant': value.idremplacant,
        'idremplacement': value.idremplacement,
        'idclasseHoraire': value.idclasseHoraire,
        'reporte': value.reporte,
        'idcentre': value.idcentre,
        'contrat': value.contrat,
        'idformateurLocalisationContrat': value.idformateurLocalisationContrat,
        'iduser': value.iduser,
    };
}

