/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { DateSearch } from './DateSearch';
import {
    DateSearchFromJSON,
    DateSearchFromJSONTyped,
    DateSearchToJSON,
} from './DateSearch';
import type { ETypeApprenantFichier } from './ETypeApprenantFichier';
import {
    ETypeApprenantFichierFromJSON,
    ETypeApprenantFichierFromJSONTyped,
    ETypeApprenantFichierToJSON,
} from './ETypeApprenantFichier';
import type { Int32NullableListSearch } from './Int32NullableListSearch';
import {
    Int32NullableListSearchFromJSON,
    Int32NullableListSearchFromJSONTyped,
    Int32NullableListSearchToJSON,
} from './Int32NullableListSearch';
import type { UnaccentTextSearch } from './UnaccentTextSearch';
import {
    UnaccentTextSearchFromJSON,
    UnaccentTextSearchFromJSONTyped,
    UnaccentTextSearchToJSON,
} from './UnaccentTextSearch';

/**
 * 
 * @export
 * @interface ApprenantFichierSearchDto
 */
export interface ApprenantFichierSearchDto {
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof ApprenantFichierSearchDto
     */
    fileName?: UnaccentTextSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof ApprenantFichierSearchDto
     */
    uploadUser?: UnaccentTextSearch;
    /**
     * 
     * @type {DateSearch}
     * @memberof ApprenantFichierSearchDto
     */
    uploadDate?: DateSearch;
    /**
     * 
     * @type {Int32NullableListSearch}
     * @memberof ApprenantFichierSearchDto
     */
    idtypeApprenantFichier?: Int32NullableListSearch;
    /**
     * 
     * @type {number}
     * @memberof ApprenantFichierSearchDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {Array<ETypeApprenantFichier>}
     * @memberof ApprenantFichierSearchDto
     */
    excludedTypes?: Array<ETypeApprenantFichier> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApprenantFichierSearchDto
     */
    hideDeleted?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApprenantFichierSearchDto
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof ApprenantFichierSearchDto
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof ApprenantFichierSearchDto
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApprenantFichierSearchDto
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof ApprenantFichierSearchDto
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantFichierSearchDto
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the ApprenantFichierSearchDto interface.
 */
export function instanceOfApprenantFichierSearchDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApprenantFichierSearchDtoFromJSON(json: any): ApprenantFichierSearchDto {
    return ApprenantFichierSearchDtoFromJSONTyped(json, false);
}

export function ApprenantFichierSearchDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApprenantFichierSearchDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileName': !exists(json, 'fileName') ? undefined : UnaccentTextSearchFromJSON(json['fileName']),
        'uploadUser': !exists(json, 'uploadUser') ? undefined : UnaccentTextSearchFromJSON(json['uploadUser']),
        'uploadDate': !exists(json, 'uploadDate') ? undefined : DateSearchFromJSON(json['uploadDate']),
        'idtypeApprenantFichier': !exists(json, 'idtypeApprenantFichier') ? undefined : Int32NullableListSearchFromJSON(json['idtypeApprenantFichier']),
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'excludedTypes': !exists(json, 'excludedTypes') ? undefined : (json['excludedTypes'] === null ? null : (json['excludedTypes'] as Array<any>).map(ETypeApprenantFichierFromJSON)),
        'hideDeleted': !exists(json, 'hideDeleted') ? undefined : json['hideDeleted'],
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function ApprenantFichierSearchDtoToJSON(value?: ApprenantFichierSearchDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileName': UnaccentTextSearchToJSON(value.fileName),
        'uploadUser': UnaccentTextSearchToJSON(value.uploadUser),
        'uploadDate': DateSearchToJSON(value.uploadDate),
        'idtypeApprenantFichier': Int32NullableListSearchToJSON(value.idtypeApprenantFichier),
        'idapprenant': value.idapprenant,
        'excludedTypes': value.excludedTypes === undefined ? undefined : (value.excludedTypes === null ? null : (value.excludedTypes as Array<any>).map(ETypeApprenantFichierToJSON)),
        'hideDeleted': value.hideDeleted,
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

