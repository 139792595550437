/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { BooleanSearch } from './BooleanSearch';
import {
    BooleanSearchFromJSON,
    BooleanSearchFromJSONTyped,
    BooleanSearchToJSON,
} from './BooleanSearch';
import type { ELieuFormationAgrementStatut } from './ELieuFormationAgrementStatut';
import {
    ELieuFormationAgrementStatutFromJSON,
    ELieuFormationAgrementStatutFromJSONTyped,
    ELieuFormationAgrementStatutToJSON,
} from './ELieuFormationAgrementStatut';
import type { Int32ListSearch } from './Int32ListSearch';
import {
    Int32ListSearchFromJSON,
    Int32ListSearchFromJSONTyped,
    Int32ListSearchToJSON,
} from './Int32ListSearch';
import type { TextSearch } from './TextSearch';
import {
    TextSearchFromJSON,
    TextSearchFromJSONTyped,
    TextSearchToJSON,
} from './TextSearch';
import type { UnaccentTextSearch } from './UnaccentTextSearch';
import {
    UnaccentTextSearchFromJSON,
    UnaccentTextSearchFromJSONTyped,
    UnaccentTextSearchToJSON,
} from './UnaccentTextSearch';

/**
 * 
 * @export
 * @interface OffreDemandeSearchDto
 */
export interface OffreDemandeSearchDto {
    /**
     * 
     * @type {Array<ELieuFormationAgrementStatut>}
     * @memberof OffreDemandeSearchDto
     */
    excludedStatuts?: Array<ELieuFormationAgrementStatut> | null;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof OffreDemandeSearchDto
     */
    idserviceTutelle?: Int32ListSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof OffreDemandeSearchDto
     */
    idreferentIfapme?: Int32ListSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof OffreDemandeSearchDto
     */
    codeMetier?: TextSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof OffreDemandeSearchDto
     */
    nomMetier?: UnaccentTextSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof OffreDemandeSearchDto
     */
    nomStade?: UnaccentTextSearch;
    /**
     * 
     * @type {BooleanSearch}
     * @memberof OffreDemandeSearchDto
     */
    offreActive?: BooleanSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof OffreDemandeSearchDto
     */
    nomLieuFormation?: UnaccentTextSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof OffreDemandeSearchDto
     */
    enseigneLieuFormation?: UnaccentTextSearch;
    /**
     * 
     * @type {number}
     * @memberof OffreDemandeSearchDto
     */
    idlieuFormation?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OffreDemandeSearchDto
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof OffreDemandeSearchDto
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof OffreDemandeSearchDto
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OffreDemandeSearchDto
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof OffreDemandeSearchDto
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof OffreDemandeSearchDto
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the OffreDemandeSearchDto interface.
 */
export function instanceOfOffreDemandeSearchDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OffreDemandeSearchDtoFromJSON(json: any): OffreDemandeSearchDto {
    return OffreDemandeSearchDtoFromJSONTyped(json, false);
}

export function OffreDemandeSearchDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OffreDemandeSearchDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'excludedStatuts': !exists(json, 'excludedStatuts') ? undefined : (json['excludedStatuts'] === null ? null : (json['excludedStatuts'] as Array<any>).map(ELieuFormationAgrementStatutFromJSON)),
        'idserviceTutelle': !exists(json, 'idserviceTutelle') ? undefined : Int32ListSearchFromJSON(json['idserviceTutelle']),
        'idreferentIfapme': !exists(json, 'idreferentIfapme') ? undefined : Int32ListSearchFromJSON(json['idreferentIfapme']),
        'codeMetier': !exists(json, 'codeMetier') ? undefined : TextSearchFromJSON(json['codeMetier']),
        'nomMetier': !exists(json, 'nomMetier') ? undefined : UnaccentTextSearchFromJSON(json['nomMetier']),
        'nomStade': !exists(json, 'nomStade') ? undefined : UnaccentTextSearchFromJSON(json['nomStade']),
        'offreActive': !exists(json, 'offreActive') ? undefined : BooleanSearchFromJSON(json['offreActive']),
        'nomLieuFormation': !exists(json, 'nomLieuFormation') ? undefined : UnaccentTextSearchFromJSON(json['nomLieuFormation']),
        'enseigneLieuFormation': !exists(json, 'enseigneLieuFormation') ? undefined : UnaccentTextSearchFromJSON(json['enseigneLieuFormation']),
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function OffreDemandeSearchDtoToJSON(value?: OffreDemandeSearchDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'excludedStatuts': value.excludedStatuts === undefined ? undefined : (value.excludedStatuts === null ? null : (value.excludedStatuts as Array<any>).map(ELieuFormationAgrementStatutToJSON)),
        'idserviceTutelle': Int32ListSearchToJSON(value.idserviceTutelle),
        'idreferentIfapme': Int32ListSearchToJSON(value.idreferentIfapme),
        'codeMetier': TextSearchToJSON(value.codeMetier),
        'nomMetier': UnaccentTextSearchToJSON(value.nomMetier),
        'nomStade': UnaccentTextSearchToJSON(value.nomStade),
        'offreActive': BooleanSearchToJSON(value.offreActive),
        'nomLieuFormation': UnaccentTextSearchToJSON(value.nomLieuFormation),
        'enseigneLieuFormation': UnaccentTextSearchToJSON(value.enseigneLieuFormation),
        'idlieuFormation': value.idlieuFormation,
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

