/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ECriteriaType {
    Text = 'Text',
    List = 'List',
    Date = 'Date',
    Boolean = 'Boolean',
    Numeric = 'Numeric',
    Float = 'Float'
}


export function ECriteriaTypeFromJSON(json: any): ECriteriaType {
    return ECriteriaTypeFromJSONTyped(json, false);
}

export function ECriteriaTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ECriteriaType {
    return json as ECriteriaType;
}

export function ECriteriaTypeToJSON(value?: ECriteriaType | null): any {
    return value as any;
}

