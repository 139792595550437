/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbAgrementValidationMasseDto,
  FcbRechercheapprenantValidationcemasseDtoPaginatedResults,
  FilterCriteriaInfo,
  RechercheApprenantValidationCeMasseSearch,
  SelectItem,
} from '../models/index';
import {
    FcbAgrementValidationMasseDtoFromJSON,
    FcbAgrementValidationMasseDtoToJSON,
    FcbRechercheapprenantValidationcemasseDtoPaginatedResultsFromJSON,
    FcbRechercheapprenantValidationcemasseDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    RechercheApprenantValidationCeMasseSearchFromJSON,
    RechercheApprenantValidationCeMasseSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface AgrementMasseValidationBaseSearchRequest {
    RechercheApprenantValidationCeMasseSearch?: RechercheApprenantValidationCeMasseSearch;
}

export interface AgrementMasseValidationGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface AgrementMasseValidationGetSelectItemsRequest {
    searchField: string;
}

export interface AgrementMasseValidationSaveRequest {
    FcbAgrementValidationMasseDto?: FcbAgrementValidationMasseDto;
}

export interface AgrementMasseValidationSearchFunctionIdsRequest {
    RechercheApprenantValidationCeMasseSearch?: RechercheApprenantValidationCeMasseSearch;
}

/**
 * 
 */
export class AgrementMasseValidationApi extends runtime.BaseAPI {

    /**
     */
    async agrementMasseValidationBaseSearchRaw(requestParameters: AgrementMasseValidationBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRechercheapprenantValidationcemasseDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementMasseValidation/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RechercheApprenantValidationCeMasseSearchToJSON(requestParameters.RechercheApprenantValidationCeMasseSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRechercheapprenantValidationcemasseDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async agrementMasseValidationBaseSearch(requestParameters: AgrementMasseValidationBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRechercheapprenantValidationcemasseDtoPaginatedResults> {
        const response = await this.agrementMasseValidationBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementMasseValidationGetSearchCriteriasRaw(requestParameters: AgrementMasseValidationGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementMasseValidation/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async agrementMasseValidationGetSearchCriterias(requestParameters: AgrementMasseValidationGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.agrementMasseValidationGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementMasseValidationGetSelectItemsRaw(requestParameters: AgrementMasseValidationGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling agrementMasseValidationGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementMasseValidation/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async agrementMasseValidationGetSelectItems(requestParameters: AgrementMasseValidationGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.agrementMasseValidationGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementMasseValidationSaveRaw(requestParameters: AgrementMasseValidationSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbAgrementValidationMasseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementMasseValidation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbAgrementValidationMasseDtoToJSON(requestParameters.FcbAgrementValidationMasseDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbAgrementValidationMasseDtoFromJSON(jsonValue));
    }

    /**
     */
    async agrementMasseValidationSave(requestParameters: AgrementMasseValidationSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbAgrementValidationMasseDto> {
        const response = await this.agrementMasseValidationSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementMasseValidationSearchFunctionIdsRaw(requestParameters: AgrementMasseValidationSearchFunctionIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementMasseValidation/SearchAgrementFormationIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RechercheApprenantValidationCeMasseSearchToJSON(requestParameters.RechercheApprenantValidationCeMasseSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async agrementMasseValidationSearchFunctionIds(requestParameters: AgrementMasseValidationSearchFunctionIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.agrementMasseValidationSearchFunctionIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
