import { Classes, Dialog } from "@blueprintjs/core";
import { FGCustomPanel, FGListen, FGTextAreaInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";

import {
  FichierApi,
  LieuFormationAgrementSuspensionDto,
  LieuFormationAgrementSuspensionDtoFromJSON
} from "../../../../../../api";
import {
  FGWalterDateMaskInput,
  FGWalterFileInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../../../components";
import { useApiService, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

const StyledDialog = styled(Dialog)`
  width: 500px;
  height: auto;
  background-color: white;
`;

export interface ILieuFormationAgrementSuspensionDetailDialogProps {
  dialogOpen: boolean;
  onClose: (toSave?: LieuFormationAgrementSuspensionDto) => void;
  currentSuspension?: LieuFormationAgrementSuspensionDto;
  multipleEdit?: boolean;
  showFile?: boolean;
}

export const LieuFormationAgrementSuspensionDetailDialog: React.FunctionComponent<ILieuFormationAgrementSuspensionDetailDialogProps> = ({
  currentSuspension,
  dialogOpen,
  onClose,
  multipleEdit = false,
  showFile = true
}) => {
  const { t } = useTl();

  const [motifs, mLoading] = useReferential(a => a.referentialGetMotifsSuspension());

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape(
        {
          dateDebut: multipleEdit
            ? Yup.date().nullable()
            : Yup.date()
                .nullable()
                .when("dateFin", (value, schema) =>
                  !!value ? schema.max(Yup.ref("dateFin"), t(ETLCodes.DateDebutGreaterThanDateFin)) : schema
                )
                .required(t(ETLCodes.Required)),
          dateFin: Yup.date()
            .nullable()
            .when("dateDebut", (value, schema) =>
              !!value ? schema.min(Yup.ref("dateDebut"), t(ETLCodes.DateFinGreaterThanDateDebut)) : schema
            ),
          idmotifSuspension: multipleEdit
            ? Yup.number().nullable()
            : Yup.number()
                .nullable()
                .required(t(ETLCodes.Required))
        },
        [["dateDebut", "dateFin"]]
      ),
    [multipleEdit, t]
  );

  const fapi = useApiService(FichierApi);
  const downloadFn = React.useCallback(
    async formik => await fapi.fichierDownload({ id: formik.values?.idfichierSuspension }),
    [fapi]
  );

  return (
    <StyledDialog title={t(ETLCodes.SuspensionLieuFormationAgrement)} isOpen={dialogOpen} onClose={() => onClose()}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={currentSuspension ?? LieuFormationAgrementSuspensionDtoFromJSON({})}
          onSubmit={onClose}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose()}
          showDeleteButton={false}
          minLabelWidth={100}
        >
          <FGListen
            field="fichierSuspension"
            onChanged={(value, formik) => {
              if (formik.dirty) {
                formik.setFieldValue("idfichierSuspension", null);
              }
            }}
          />
          <FieldGroup>
            <FGWalterDateMaskInput
              name="dateDebut"
              label={t(ETLCodes.DateDebut)}
              disabled={currentSuspension?.idlieuFormationAgrementSuspension > 0}
              visible={!multipleEdit}
            />
            <FGWalterDateMaskInput name="dateFin" label={t(ETLCodes.DateFin)} />
            <FGWalterSelectInput
              name="idmotifSuspension"
              label={t(ETLCodes.Motif)}
              items={motifs}
              loading={mLoading}
              disabled={currentSuspension?.idlieuFormationAgrementSuspension > 0}
              visible={!multipleEdit}
            />
            <FGTextAreaInput name="remarque" label={t(ETLCodes.Remarque)} />
            <FGCustomPanel>
              {ctx => (
                <FGWalterFileInput
                  name="fichierSuspension"
                  label={t(ETLCodes.Document)}
                  downloadFn={ctx.formik.values?.idfichierSuspension && (() => downloadFn(ctx.formik))}
                  visible={showFile}
                />
              )}
            </FGCustomPanel>
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
