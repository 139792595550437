import { FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { FcbStatutSocialDtoFromJSON, StatutSocialApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { SmallFormGenerator, FGWalterCheckboxInput } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IStatutSocialDetailPageProps {
  idStatut: number;
  editMode: boolean;
}

export const StatutSocialDetailPage: React.FunctionComponent<IStatutSocialDetailPageProps> = ({
  idStatut,
  editMode
}) => {
  const { t } = useTl();
  const api = useApiService(StatutSocialApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idStatut <= 0 ? FcbStatutSocialDtoFromJSON({ idStatut: 0 }) : api.statutSocialGet({ id: +idStatut }),
    saveApiFn: d => api.statutSocialSave({ FcbStatutSocialDto: d }),
    onSavedRoute: d => `${ERoutes.statutSocial}/${d.idstatutSocial}/detail/edit`,
    deleteApiFn: d => api.statutSocialDelete({ id: d.idstatutSocial }),
    onDeletedRoute: () => ERoutes.statutSocial
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      libelle: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={editMode}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.statutSocial)}
      onDelete={deleteItem}
      showDeleteButton={+idStatut > 0}
      saving={saving}
      deleting={deleting}
    >
      <FieldGroup>
        <FGTextInput name="libelle" label={t(ETLCodes.Libelle)} maxLength={50} />
        <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
