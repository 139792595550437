/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbInscriptionMasseDto
 */
export interface FcbInscriptionMasseDto {
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionMasseDto
     */
    idcentre?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionMasseDto
     */
    idclasse?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionMasseDto
     */
    idstatut?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionMasseDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionMasseDto
     */
    degre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionMasseDto
     */
    typecours?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbInscriptionMasseDto
     */
    dateInscription?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbInscriptionMasseDto
     */
    dateConvocation?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionMasseDto
     */
    dateConvocationString?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionMasseDto
     */
    minerval?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbInscriptionMasseDto
     */
    isVisibleBulletin?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionMasseDto
     */
    idstade?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionMasseDto
     */
    idmetier?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionMasseDto
     */
    idstatutSocial?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FcbInscriptionMasseDto
     */
    idspprenant?: Array<number> | null;
}

/**
 * Check if a given object implements the FcbInscriptionMasseDto interface.
 */
export function instanceOfFcbInscriptionMasseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbInscriptionMasseDtoFromJSON(json: any): FcbInscriptionMasseDto {
    return FcbInscriptionMasseDtoFromJSONTyped(json, false);
}

export function FcbInscriptionMasseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbInscriptionMasseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'idstatut': !exists(json, 'idstatut') ? undefined : json['idstatut'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'degre': !exists(json, 'degre') ? undefined : json['degre'],
        'typecours': !exists(json, 'typecours') ? undefined : json['typecours'],
        'dateInscription': !exists(json, 'dateInscription') ? undefined : (json['dateInscription'] === null ? null : new Date(json['dateInscription'])),
        'dateConvocation': !exists(json, 'dateConvocation') ? undefined : (json['dateConvocation'] === null ? null : new Date(json['dateConvocation'])),
        'dateConvocationString': !exists(json, 'dateConvocationString') ? undefined : json['dateConvocationString'],
        'minerval': !exists(json, 'minerval') ? undefined : json['minerval'],
        'isVisibleBulletin': !exists(json, 'isVisibleBulletin') ? undefined : json['isVisibleBulletin'],
        'idstade': !exists(json, 'idstade') ? undefined : json['idstade'],
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'idstatutSocial': !exists(json, 'idstatutSocial') ? undefined : json['idstatutSocial'],
        'idspprenant': !exists(json, 'idspprenant') ? undefined : json['idspprenant'],
    };
}

export function FcbInscriptionMasseDtoToJSON(value?: FcbInscriptionMasseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcentre': value.idcentre,
        'idclasse': value.idclasse,
        'idstatut': value.idstatut,
        'anneeScolaire': value.anneeScolaire,
        'degre': value.degre,
        'typecours': value.typecours,
        'dateInscription': value.dateInscription === undefined ? undefined : (value.dateInscription === null ? null : value.dateInscription.toISOString()),
        'dateConvocation': value.dateConvocation === undefined ? undefined : (value.dateConvocation === null ? null : value.dateConvocation.toISOString()),
        'dateConvocationString': value.dateConvocationString,
        'minerval': value.minerval,
        'isVisibleBulletin': value.isVisibleBulletin,
        'idstade': value.idstade,
        'idmetier': value.idmetier,
        'idstatutSocial': value.idstatutSocial,
        'idspprenant': value.idspprenant,
    };
}

