import { DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";

import { ExportGapFormateurApi, RechercheExportGapSearch } from "../../../../api";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface IExportGapFormateursListProps {
  idExport: number;
}

export const ExportGapFormateursList: React.FunctionComponent<IExportGapFormateursListProps> = ({ idExport }) => {
  const { t } = useTl();
  const api = useApiService(ExportGapFormateurApi);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [5],
    pageSize: 5,
    sortKeys: { Nom: "ASC" }
  });

  const searchFunction = React.useCallback(
    (sObj?: RechercheExportGapSearch) => {
      sObj.idexport = idExport;
      return api.exportGapFormateurBaseSearch({ RechercheExportGapSearch: sObj });
    },
    [api, idExport]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.Matricule),
        fieldName: "matricule"
      },
      {
        header: () => t(ETLCodes.AncienMatricule),
        fieldName: "ancienMatricule"
      },
      {
        header: () => t(ETLCodes.Adresse),
        fieldName: "adresseDomicile"
      }
    ],
    [t]
  );

  return (
    <>
      {idExport > 0 && (
        <FieldSet title={`${t(ETLCodes.Formateurs)}`}>
          <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
        </FieldSet>
      )}
    </>
  );
};
