import { FGCustomPanel, FGListen, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { FcbFormateurProfessionDtoFromJSON, FichierApi, FormateurProfessionApi } from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import {
  FGWalterDateMaskInput,
  FGWalterFileInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

export interface IActiviteProfessionnelleProfessionPageProps {}

export const ActiviteProfessionnelleProfessionPage: React.FunctionComponent<IActiviteProfessionnelleProfessionPageProps> = () => {
  const { id, idProfession, state } = useParams<{ id: string; idProfession: string; state: string }>();
  const { t } = useTl();
  const api = useApiService(FormateurProfessionApi);
  const history = useHistory();

  const [profession, loadingProfession] = useReferential(a => a.referentialGetProfessions(), true, []);
  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      libelle: Yup.string().required(t(ETLCodes.Required)),
      idprofession: Yup.number().required(t(ETLCodes.Required))
    });
  }, [t]);

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idProfession <= 0
        ? FcbFormateurProfessionDtoFromJSON({ idformateur: +id })
        : api.formateurProfessionGet({ idProfession: +idProfession, idFormateur: +id }),
    saveApiFn: d => api.formateurProfessionSave({ FcbFormateurProfessionDto: d }),
    onSavedRoute: d => `${ERoutes.formateur}/${d.idformateur}/activitesProfessionnelles`,
    deleteApiFn: d => api.formateurProfessionDelete({ idProfession: +idProfession, idFormateur: +id }),
    onDeletedRoute: () => `${ERoutes.formateur}/${+id}/activitesProfessionnelles`
  });

  const fapi = useApiService(FichierApi);
  const downloadFn = React.useCallback(async (idfichier: number) => await fapi.fichierDownload({ id: idfichier }), [
    fapi
  ]);

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        showColons
        editMode={state === "edit"}
        minLabelWidth={180}
        labelAlignment="right"
        formatDate="dd/MM/yyyy"
        validationSchema={FormSchema}
        inline
        boldLabel
        loading={loading}
        onCancel={() => history.push(`${ERoutes.formateur}/${+id}/activitesProfessionnelles`)}
        onDelete={deleteItem}
        showDeleteButton={+idProfession > 0}
        saving={saving}
        deleting={deleting}
      >
        <FGListen
          field="fichier"
          onChanged={(value, formik) => {
            if (formik.dirty) {
              formik.setFieldValue("idfichier", null);
            }
          }}
        />
        <FieldGroup columns={1}>
          <FGWalterSelectInput
            name="idprofession"
            label={t(ETLCodes.Profession)}
            items={profession}
            loading={loadingProfession}
          />
          <FGWalterDateMaskInput name="date" label={t(ETLCodes.Date)} />
          <FGTextInput name="libelle" label={t(ETLCodes.Libelle)} maxLength={50} />
          <FGCustomPanel>
            {({ formik }) => (
              <FGWalterFileInput
                name="fichier"
                label={t(ETLCodes.Document)}
                downloadFn={formik.values?.idfichier && (() => downloadFn(formik.values?.idfichier))}
              />
            )}
          </FGCustomPanel>
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
