/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EListSearchType } from './EListSearchType';
import {
    EListSearchTypeFromJSON,
    EListSearchTypeFromJSONTyped,
    EListSearchTypeToJSON,
} from './EListSearchType';
import type { EListSearchTypeESearchStatutInscriptionCombinableCriteria } from './EListSearchTypeESearchStatutInscriptionCombinableCriteria';
import {
    EListSearchTypeESearchStatutInscriptionCombinableCriteriaFromJSON,
    EListSearchTypeESearchStatutInscriptionCombinableCriteriaFromJSONTyped,
    EListSearchTypeESearchStatutInscriptionCombinableCriteriaToJSON,
} from './EListSearchTypeESearchStatutInscriptionCombinableCriteria';
import type { ESearchStatutInscription } from './ESearchStatutInscription';
import {
    ESearchStatutInscriptionFromJSON,
    ESearchStatutInscriptionFromJSONTyped,
    ESearchStatutInscriptionToJSON,
} from './ESearchStatutInscription';

/**
 * 
 * @export
 * @interface ESearchStatutInscriptionListSearch
 */
export interface ESearchStatutInscriptionListSearch {
    /**
     * 
     * @type {Array<EListSearchTypeESearchStatutInscriptionCombinableCriteria>}
     * @memberof ESearchStatutInscriptionListSearch
     */
    criterias?: Array<EListSearchTypeESearchStatutInscriptionCombinableCriteria> | null;
    /**
     * 
     * @type {EListSearchType}
     * @memberof ESearchStatutInscriptionListSearch
     */
    searchMode?: EListSearchType;
    /**
     * 
     * @type {ESearchStatutInscription}
     * @memberof ESearchStatutInscriptionListSearch
     */
    value?: ESearchStatutInscription;
    /**
     * 
     * @type {ESearchStatutInscription}
     * @memberof ESearchStatutInscriptionListSearch
     */
    secondaryValue?: ESearchStatutInscription;
    /**
     * 
     * @type {Array<string>}
     * @memberof ESearchStatutInscriptionListSearch
     */
    readonly availableSearchTypes?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ESearchStatutInscriptionListSearch
     */
    defaultSearchType?: string | null;
}

/**
 * Check if a given object implements the ESearchStatutInscriptionListSearch interface.
 */
export function instanceOfESearchStatutInscriptionListSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ESearchStatutInscriptionListSearchFromJSON(json: any): ESearchStatutInscriptionListSearch {
    return ESearchStatutInscriptionListSearchFromJSONTyped(json, false);
}

export function ESearchStatutInscriptionListSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): ESearchStatutInscriptionListSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'criterias': !exists(json, 'criterias') ? undefined : (json['criterias'] === null ? null : (json['criterias'] as Array<any>).map(EListSearchTypeESearchStatutInscriptionCombinableCriteriaFromJSON)),
        'searchMode': !exists(json, 'searchMode') ? undefined : EListSearchTypeFromJSON(json['searchMode']),
        'value': !exists(json, 'value') ? undefined : ESearchStatutInscriptionFromJSON(json['value']),
        'secondaryValue': !exists(json, 'secondaryValue') ? undefined : ESearchStatutInscriptionFromJSON(json['secondaryValue']),
        'availableSearchTypes': !exists(json, 'availableSearchTypes') ? undefined : json['availableSearchTypes'],
        'defaultSearchType': !exists(json, 'defaultSearchType') ? undefined : json['defaultSearchType'],
    };
}

export function ESearchStatutInscriptionListSearchToJSON(value?: ESearchStatutInscriptionListSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'criterias': value.criterias === undefined ? undefined : (value.criterias === null ? null : (value.criterias as Array<any>).map(EListSearchTypeESearchStatutInscriptionCombinableCriteriaToJSON)),
        'searchMode': EListSearchTypeToJSON(value.searchMode),
        'value': ESearchStatutInscriptionToJSON(value.value),
        'secondaryValue': ESearchStatutInscriptionToJSON(value.secondaryValue),
        'defaultSearchType': value.defaultSearchType,
    };
}

