/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbSecteurHomogeneDto
 */
export interface FcbSecteurHomogeneDto {
    /**
     * 
     * @type {number}
     * @memberof FcbSecteurHomogeneDto
     */
    idsecteur?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbSecteurHomogeneDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbSecteurHomogeneDto
     */
    creationUser?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbSecteurHomogeneDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbSecteurHomogeneDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbSecteurHomogeneDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbSecteurHomogeneDto
     */
    idstade?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FcbSecteurHomogeneDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbSecteurHomogeneDto interface.
 */
export function instanceOfFcbSecteurHomogeneDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbSecteurHomogeneDtoFromJSON(json: any): FcbSecteurHomogeneDto {
    return FcbSecteurHomogeneDtoFromJSONTyped(json, false);
}

export function FcbSecteurHomogeneDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbSecteurHomogeneDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsecteur': !exists(json, 'idsecteur') ? undefined : json['idsecteur'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'idstade': !exists(json, 'idstade') ? undefined : json['idstade'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbSecteurHomogeneDtoToJSON(value?: FcbSecteurHomogeneDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsecteur': value.idsecteur,
        'libelle': value.libelle,
        'creationUser': value.creationUser,
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'idstade': value.idstade,
        'actif': value.actif,
    };
}

