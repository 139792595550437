/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbVisiteClasseDto,
  FcbVisiteClasseGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
  VisiteClasseSearch,
} from '../models/index';
import {
    FcbVisiteClasseDtoFromJSON,
    FcbVisiteClasseDtoToJSON,
    FcbVisiteClasseGridDtoPaginatedResultsFromJSON,
    FcbVisiteClasseGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    VisiteClasseSearchFromJSON,
    VisiteClasseSearchToJSON,
} from '../models/index';

export interface VisiteClasseBaseSearchRequest {
    VisiteClasseSearch?: VisiteClasseSearch;
}

export interface VisiteClasseGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface VisiteClasseGetSelectItemsRequest {
    searchField: string;
}

export interface VisiteClasseGetVisiteClasseRequest {
    idVisite?: number;
}

export interface VisiteClasseSaveClassesLiesRequest {
    FcbVisiteClasseDto?: FcbVisiteClasseDto;
}

/**
 * 
 */
export class VisiteClasseApi extends runtime.BaseAPI {

    /**
     */
    async visiteClasseBaseSearchRaw(requestParameters: VisiteClasseBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbVisiteClasseGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/VisiteClasse/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VisiteClasseSearchToJSON(requestParameters.VisiteClasseSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbVisiteClasseGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async visiteClasseBaseSearch(requestParameters: VisiteClasseBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbVisiteClasseGridDtoPaginatedResults> {
        const response = await this.visiteClasseBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async visiteClasseGetSearchCriteriasRaw(requestParameters: VisiteClasseGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/VisiteClasse/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async visiteClasseGetSearchCriterias(requestParameters: VisiteClasseGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.visiteClasseGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async visiteClasseGetSelectItemsRaw(requestParameters: VisiteClasseGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling visiteClasseGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/VisiteClasse/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async visiteClasseGetSelectItems(requestParameters: VisiteClasseGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.visiteClasseGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async visiteClasseGetVisiteClasseRaw(requestParameters: VisiteClasseGetVisiteClasseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbVisiteClasseDto>> {
        const queryParameters: any = {};

        if (requestParameters.idVisite !== undefined) {
            queryParameters['idVisite'] = requestParameters.idVisite;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/VisiteClasse`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbVisiteClasseDtoFromJSON(jsonValue));
    }

    /**
     */
    async visiteClasseGetVisiteClasse(requestParameters: VisiteClasseGetVisiteClasseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbVisiteClasseDto> {
        const response = await this.visiteClasseGetVisiteClasseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async visiteClasseSaveClassesLiesRaw(requestParameters: VisiteClasseSaveClassesLiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbVisiteClasseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/VisiteClasse/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbVisiteClasseDtoToJSON(requestParameters.FcbVisiteClasseDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbVisiteClasseDtoFromJSON(jsonValue));
    }

    /**
     */
    async visiteClasseSaveClassesLies(requestParameters: VisiteClasseSaveClassesLiesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbVisiteClasseDto> {
        const response = await this.visiteClasseSaveClassesLiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
