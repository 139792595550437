import React from "react";

import { useGlobalData } from "../contexts";
import { getTheme } from "../theme";

// const storageKey = "ifapme-theme";

export function useTheme() {
  // const [devTheme, setDevTheme] = useLocalStorageState<SiteTheme>(storageKey, { defaultValue: "walter" });
  const { walterUrl, isTestEnv } = useGlobalData();

  const ifapmeSide = React.useMemo<"walter" | "hope">(
    () => (walterUrl?.includes(window.location.origin) ? "walter" : "hope"),
    [walterUrl]
  );
  const getConfigValue = React.useCallback(() => {
    // if (window.location.origin.includes("localhost")) {
    //   return devTheme;
    // }

    return ifapmeSide === "walter" && isTestEnv
      ? "test-walter"
      : ifapmeSide === "hope" && isTestEnv
      ? "test-hope"
      : ifapmeSide;
  }, [ifapmeSide, isTestEnv]);

  const devMode = React.useMemo(() => false && window.location.origin.includes("localhost"), []);

  const theme = React.useMemo(() => {
    const themeValue = getConfigValue();
    return getTheme(themeValue);
  }, [getConfigValue]);

  // const toggleDevTheme = React.useCallback(() => {
  //   setDevTheme(devTheme === "walter" ? "hope" : "walter");
  //   window.location.reload();
  // }, [devTheme, setDevTheme]);

  const isHope = React.useMemo(() => ifapmeSide === "hope", [ifapmeSide]);

  return {
    theme,
    devMode,
    // setDevTheme,
    // toggleDevTheme,
    ifapmeSide,
    isHope
  };
}
