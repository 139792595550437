/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SiegeLieuSuiviActionEditDto } from './SiegeLieuSuiviActionEditDto';
import {
    SiegeLieuSuiviActionEditDtoFromJSON,
    SiegeLieuSuiviActionEditDtoFromJSONTyped,
    SiegeLieuSuiviActionEditDtoToJSON,
} from './SiegeLieuSuiviActionEditDto';
import type { SiegeLieuSuiviFichierGridDto } from './SiegeLieuSuiviFichierGridDto';
import {
    SiegeLieuSuiviFichierGridDtoFromJSON,
    SiegeLieuSuiviFichierGridDtoFromJSONTyped,
    SiegeLieuSuiviFichierGridDtoToJSON,
} from './SiegeLieuSuiviFichierGridDto';

/**
 * 
 * @export
 * @interface SiegeLieuSuiviEditDto
 */
export interface SiegeLieuSuiviEditDto {
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviEditDto
     */
    idsiegeSocialLieuFormationSuivi?: number;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviEditDto
     */
    idservice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviEditDto
     */
    iduserService?: number;
    /**
     * 
     * @type {string}
     * @memberof SiegeLieuSuiviEditDto
     */
    userService?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeLieuSuiviEditDto
     */
    encodeur?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SiegeLieuSuiviEditDto
     */
    dateSuivi?: Date;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviEditDto
     */
    idsiegeSocial?: number;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviEditDto
     */
    idlieuFormation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviEditDto
     */
    idtypeSuivi?: number;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviEditDto
     */
    idmode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviEditDto
     */
    idlieuRencontre?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeLieuSuiviEditDto
     */
    contenu?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SiegeLieuSuiviEditDto
     */
    hiddenContent?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviEditDto
     */
    creationUser?: number;
    /**
     * 
     * @type {string}
     * @memberof SiegeLieuSuiviEditDto
     */
    siegeSocial?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeLieuSuiviEditDto
     */
    lieuFormation?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SiegeLieuSuiviEditDto
     */
    action?: boolean;
    /**
     * 
     * @type {SiegeLieuSuiviActionEditDto}
     * @memberof SiegeLieuSuiviEditDto
     */
    suiviAction?: SiegeLieuSuiviActionEditDto;
    /**
     * 
     * @type {Array<SiegeLieuSuiviFichierGridDto>}
     * @memberof SiegeLieuSuiviEditDto
     */
    fichiers?: Array<SiegeLieuSuiviFichierGridDto> | null;
}

/**
 * Check if a given object implements the SiegeLieuSuiviEditDto interface.
 */
export function instanceOfSiegeLieuSuiviEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SiegeLieuSuiviEditDtoFromJSON(json: any): SiegeLieuSuiviEditDto {
    return SiegeLieuSuiviEditDtoFromJSONTyped(json, false);
}

export function SiegeLieuSuiviEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiegeLieuSuiviEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsiegeSocialLieuFormationSuivi': !exists(json, 'idsiegeSocialLieuFormationSuivi') ? undefined : json['idsiegeSocialLieuFormationSuivi'],
        'idservice': !exists(json, 'idservice') ? undefined : json['idservice'],
        'iduserService': !exists(json, 'iduserService') ? undefined : json['iduserService'],
        'userService': !exists(json, 'userService') ? undefined : json['userService'],
        'encodeur': !exists(json, 'encodeur') ? undefined : json['encodeur'],
        'dateSuivi': !exists(json, 'dateSuivi') ? undefined : (new Date(json['dateSuivi'])),
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'idtypeSuivi': !exists(json, 'idtypeSuivi') ? undefined : json['idtypeSuivi'],
        'idmode': !exists(json, 'idmode') ? undefined : json['idmode'],
        'idlieuRencontre': !exists(json, 'idlieuRencontre') ? undefined : json['idlieuRencontre'],
        'contenu': !exists(json, 'contenu') ? undefined : json['contenu'],
        'hiddenContent': !exists(json, 'hiddenContent') ? undefined : json['hiddenContent'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'siegeSocial': !exists(json, 'siegeSocial') ? undefined : json['siegeSocial'],
        'lieuFormation': !exists(json, 'lieuFormation') ? undefined : json['lieuFormation'],
        'action': !exists(json, 'action') ? undefined : json['action'],
        'suiviAction': !exists(json, 'suiviAction') ? undefined : SiegeLieuSuiviActionEditDtoFromJSON(json['suiviAction']),
        'fichiers': !exists(json, 'fichiers') ? undefined : (json['fichiers'] === null ? null : (json['fichiers'] as Array<any>).map(SiegeLieuSuiviFichierGridDtoFromJSON)),
    };
}

export function SiegeLieuSuiviEditDtoToJSON(value?: SiegeLieuSuiviEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsiegeSocialLieuFormationSuivi': value.idsiegeSocialLieuFormationSuivi,
        'idservice': value.idservice,
        'iduserService': value.iduserService,
        'userService': value.userService,
        'encodeur': value.encodeur,
        'dateSuivi': value.dateSuivi === undefined ? undefined : (value.dateSuivi.toISOString()),
        'idsiegeSocial': value.idsiegeSocial,
        'idlieuFormation': value.idlieuFormation,
        'idtypeSuivi': value.idtypeSuivi,
        'idmode': value.idmode,
        'idlieuRencontre': value.idlieuRencontre,
        'contenu': value.contenu,
        'hiddenContent': value.hiddenContent,
        'creationUser': value.creationUser,
        'siegeSocial': value.siegeSocial,
        'lieuFormation': value.lieuFormation,
        'action': value.action,
        'suiviAction': SiegeLieuSuiviActionEditDtoToJSON(value.suiviAction),
        'fichiers': value.fichiers === undefined ? undefined : (value.fichiers === null ? null : (value.fichiers as Array<any>).map(SiegeLieuSuiviFichierGridDtoToJSON)),
    };
}

