import { FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";

import { ERoutes } from "../../../AppRouter";
import { FGWalterSelectInput, PageBase, SmallFormGenerator } from "../../../components";
import { useTl } from "../../../hooks";
import { useReferential } from "../../../hooks/useReferential";
import { ETLCodes } from "../../../locales";

export interface IKitSurviePageProps {}

export const KitSurviePage: React.FunctionComponent<IKitSurviePageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const [centre, centreLoading] = useReferential(a => a.referentialGetCentre(), false, []);
  return (
    <PageBase>
      <SmallFormGenerator
        initialValues={{}}
        onSubmit={() => {}}
        editMode={true}
        loading={false}
        onCancel={() => history.push(ERoutes.kitSurvie)}
        onDelete={() => {}}
        showDeleteButton={false}
        saving={false}
        deleting={false}
        hideButtons={true}
      >
        <FieldGroup>
          <FGWalterSelectInput name="annee" label={t(ETLCodes.Centre)} items={centre} loading={centreLoading} />
        </FieldGroup>
      </SmallFormGenerator>
    </PageBase>
  );
};
