/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { CompteBancaireDto } from './CompteBancaireDto';
import {
    CompteBancaireDtoFromJSON,
    CompteBancaireDtoFromJSONTyped,
    CompteBancaireDtoToJSON,
} from './CompteBancaireDto';

/**
 * 
 * @export
 * @interface FormateurDoublonSearch
 */
export interface FormateurDoublonSearch {
    /**
     * 
     * @type {string}
     * @memberof FormateurDoublonSearch
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurDoublonSearch
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurDoublonSearch
     */
    prenom2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurDoublonSearch
     */
    registreNational?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FormateurDoublonSearch
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurDoublonSearch
     */
    nationalite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurDoublonSearch
     */
    codesexe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurDoublonSearch
     */
    localiteNaissance?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurDoublonSearch
     */
    communeNaissance?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurDoublonSearch
     */
    paysNaissance?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FormateurDoublonSearch
     */
    dateDeces?: Date | null;
    /**
     * 
     * @type {CompteBancaireDto}
     * @memberof FormateurDoublonSearch
     */
    compteBancaire?: CompteBancaireDto;
    /**
     * 
     * @type {number}
     * @memberof FormateurDoublonSearch
     */
    idpersonne?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormateurDoublonSearch
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof FormateurDoublonSearch
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof FormateurDoublonSearch
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FormateurDoublonSearch
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof FormateurDoublonSearch
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurDoublonSearch
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the FormateurDoublonSearch interface.
 */
export function instanceOfFormateurDoublonSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FormateurDoublonSearchFromJSON(json: any): FormateurDoublonSearch {
    return FormateurDoublonSearchFromJSONTyped(json, false);
}

export function FormateurDoublonSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormateurDoublonSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'prenom2': !exists(json, 'prenom2') ? undefined : json['prenom2'],
        'registreNational': !exists(json, 'registreNational') ? undefined : json['registreNational'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'nationalite': !exists(json, 'nationalite') ? undefined : json['nationalite'],
        'codesexe': !exists(json, 'codesexe') ? undefined : json['codesexe'],
        'localiteNaissance': !exists(json, 'localiteNaissance') ? undefined : json['localiteNaissance'],
        'communeNaissance': !exists(json, 'communeNaissance') ? undefined : json['communeNaissance'],
        'paysNaissance': !exists(json, 'paysNaissance') ? undefined : json['paysNaissance'],
        'dateDeces': !exists(json, 'dateDeces') ? undefined : (json['dateDeces'] === null ? null : new Date(json['dateDeces'])),
        'compteBancaire': !exists(json, 'compteBancaire') ? undefined : CompteBancaireDtoFromJSON(json['compteBancaire']),
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function FormateurDoublonSearchToJSON(value?: FormateurDoublonSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nom': value.nom,
        'prenom': value.prenom,
        'prenom2': value.prenom2,
        'registreNational': value.registreNational,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'nationalite': value.nationalite,
        'codesexe': value.codesexe,
        'localiteNaissance': value.localiteNaissance,
        'communeNaissance': value.communeNaissance,
        'paysNaissance': value.paysNaissance,
        'dateDeces': value.dateDeces === undefined ? undefined : (value.dateDeces === null ? null : value.dateDeces.toISOString()),
        'compteBancaire': CompteBancaireDtoToJSON(value.compteBancaire),
        'idpersonne': value.idpersonne,
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

