/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
  StatutApprenantEditDto,
  StatutApprenantGridDtoPaginatedResults,
  StatutApprenantSearch,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
    StatutApprenantEditDtoFromJSON,
    StatutApprenantEditDtoToJSON,
    StatutApprenantGridDtoPaginatedResultsFromJSON,
    StatutApprenantGridDtoPaginatedResultsToJSON,
    StatutApprenantSearchFromJSON,
    StatutApprenantSearchToJSON,
} from '../models/index';

export interface StatutApprenantBaseSearchRequest {
    StatutApprenantSearch?: StatutApprenantSearch;
}

export interface StatutApprenantGetNewStatutPostSortieRequest {
    idapprenant?: number;
}

export interface StatutApprenantGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface StatutApprenantGetSelectItemsRequest {
    searchField: string;
}

export interface StatutApprenantGetStatutApprenantRequest {
    idstatutApprenant?: number;
}

export interface StatutApprenantIsSortiRequest {
    idapprenant?: number;
}

export interface StatutApprenantSaveStatutApprenantRequest {
    StatutApprenantEditDto?: StatutApprenantEditDto;
}

export interface StatutApprenantSetAsMainRequest {
    idstatutApprenant?: number;
    idapprenant?: number;
}

/**
 * 
 */
export class StatutApprenantApi extends runtime.BaseAPI {

    /**
     */
    async statutApprenantBaseSearchRaw(requestParameters: StatutApprenantBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StatutApprenantGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/StatutApprenant/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatutApprenantSearchToJSON(requestParameters.StatutApprenantSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatutApprenantGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async statutApprenantBaseSearch(requestParameters: StatutApprenantBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatutApprenantGridDtoPaginatedResults> {
        const response = await this.statutApprenantBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async statutApprenantGetNewStatutPostSortieRaw(requestParameters: StatutApprenantGetNewStatutPostSortieRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StatutApprenantEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/StatutApprenant/post-sortie`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatutApprenantEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async statutApprenantGetNewStatutPostSortie(requestParameters: StatutApprenantGetNewStatutPostSortieRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatutApprenantEditDto> {
        const response = await this.statutApprenantGetNewStatutPostSortieRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async statutApprenantGetSearchCriteriasRaw(requestParameters: StatutApprenantGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/StatutApprenant/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async statutApprenantGetSearchCriterias(requestParameters: StatutApprenantGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.statutApprenantGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async statutApprenantGetSelectItemsRaw(requestParameters: StatutApprenantGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling statutApprenantGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/StatutApprenant/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async statutApprenantGetSelectItems(requestParameters: StatutApprenantGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.statutApprenantGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async statutApprenantGetStatutApprenantRaw(requestParameters: StatutApprenantGetStatutApprenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StatutApprenantEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.idstatutApprenant !== undefined) {
            queryParameters['idstatutApprenant'] = requestParameters.idstatutApprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/StatutApprenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatutApprenantEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async statutApprenantGetStatutApprenant(requestParameters: StatutApprenantGetStatutApprenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatutApprenantEditDto> {
        const response = await this.statutApprenantGetStatutApprenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async statutApprenantIsSortiRaw(requestParameters: StatutApprenantIsSortiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/StatutApprenant/IsSorti`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async statutApprenantIsSorti(requestParameters: StatutApprenantIsSortiRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.statutApprenantIsSortiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async statutApprenantSaveStatutApprenantRaw(requestParameters: StatutApprenantSaveStatutApprenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StatutApprenantEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/StatutApprenant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatutApprenantEditDtoToJSON(requestParameters.StatutApprenantEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatutApprenantEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async statutApprenantSaveStatutApprenant(requestParameters: StatutApprenantSaveStatutApprenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatutApprenantEditDto> {
        const response = await this.statutApprenantSaveStatutApprenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async statutApprenantSetAsMainRaw(requestParameters: StatutApprenantSetAsMainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idstatutApprenant !== undefined) {
            queryParameters['idstatutApprenant'] = requestParameters.idstatutApprenant;
        }

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/StatutApprenant/SetAsMain`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async statutApprenantSetAsMain(requestParameters: StatutApprenantSetAsMainRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.statutApprenantSetAsMainRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
