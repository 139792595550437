import * as React from "react";
import { ApprenantStatutList } from "./ApprenantStatutList";
import { ApprenantStatutCreaList } from "./ApprenantStatutCreaList";

export interface IApprenantStatutListPageProps {}

export const ApprenantStatutListPage: React.FunctionComponent<IApprenantStatutListPageProps> = props => {
  return (
    <>
      <ApprenantStatutList />
      <ApprenantStatutCreaList />
    </>
  );
};
