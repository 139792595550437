/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbProjetFinancementGridDto
 */
export interface FcbProjetFinancementGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbProjetFinancementGridDto
     */
    idprojetFinancement?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbProjetFinancementGridDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbProjetFinancementGridDto
     */
    idfinancementType?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbProjetFinancementGridDto
     */
    code?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbProjetFinancementGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbProjetFinancementGridDto interface.
 */
export function instanceOfFcbProjetFinancementGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbProjetFinancementGridDtoFromJSON(json: any): FcbProjetFinancementGridDto {
    return FcbProjetFinancementGridDtoFromJSONTyped(json, false);
}

export function FcbProjetFinancementGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbProjetFinancementGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idprojetFinancement': !exists(json, 'idprojetFinancement') ? undefined : json['idprojetFinancement'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'idfinancementType': !exists(json, 'idfinancementType') ? undefined : json['idfinancementType'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbProjetFinancementGridDtoToJSON(value?: FcbProjetFinancementGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idprojetFinancement': value.idprojetFinancement,
        'libelle': value.libelle,
        'idfinancementType': value.idfinancementType,
        'code': value.code,
        'actif': value.actif,
    };
}

