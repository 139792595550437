import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import { DemandeInscription } from "./demandeInscription/DemandeInscription";
import { ParcoursFormationDetailPage } from "./ParcoursFormationDetailPage";

export interface IParcoursFormationProps {}

export const ParcoursFormation: React.FunctionComponent<IParcoursFormationProps> = props => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={ParcoursFormationDetailPage} exact />
      <Route
        path={`${match.path}/:idParcoursFormation/demandeInscription/:idDemande`}
        component={DemandeInscription}
        exact
      />
      <Route path={`${match.path}/:idParcoursFormation`} component={ParcoursFormationDetailPage} exact />
    </Switch>
  );
};
