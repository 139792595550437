/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EntiteCentreSearch,
  FcbEntitecentreDto,
  FcbEntitecentreDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    EntiteCentreSearchFromJSON,
    EntiteCentreSearchToJSON,
    FcbEntitecentreDtoFromJSON,
    FcbEntitecentreDtoToJSON,
    FcbEntitecentreDtoPaginatedResultsFromJSON,
    FcbEntitecentreDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface EntiteCentreBaseSearchRequest {
    EntiteCentreSearch?: EntiteCentreSearch;
}

export interface EntiteCentreDeleteRequest {
    id?: number;
}

export interface EntiteCentreGetRequest {
    id?: number;
}

export interface EntiteCentreGetDisplayNameRequest {
    id?: number;
}

export interface EntiteCentreGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface EntiteCentreGetSelectItemsRequest {
    searchField: string;
}

export interface EntiteCentreSaveRequest {
    FcbEntitecentreDto?: FcbEntitecentreDto;
}

/**
 * 
 */
export class EntiteCentreApi extends runtime.BaseAPI {

    /**
     */
    async entiteCentreBaseSearchRaw(requestParameters: EntiteCentreBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbEntitecentreDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/EntiteCentre/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntiteCentreSearchToJSON(requestParameters.EntiteCentreSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbEntitecentreDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async entiteCentreBaseSearch(requestParameters: EntiteCentreBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbEntitecentreDtoPaginatedResults> {
        const response = await this.entiteCentreBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async entiteCentreDeleteRaw(requestParameters: EntiteCentreDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/EntiteCentre`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async entiteCentreDelete(requestParameters: EntiteCentreDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.entiteCentreDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async entiteCentreGetRaw(requestParameters: EntiteCentreGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbEntitecentreDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/EntiteCentre`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbEntitecentreDtoFromJSON(jsonValue));
    }

    /**
     */
    async entiteCentreGet(requestParameters: EntiteCentreGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbEntitecentreDto> {
        const response = await this.entiteCentreGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async entiteCentreGetDisplayNameRaw(requestParameters: EntiteCentreGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/EntiteCentre/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async entiteCentreGetDisplayName(requestParameters: EntiteCentreGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.entiteCentreGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async entiteCentreGetSearchCriteriasRaw(requestParameters: EntiteCentreGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/EntiteCentre/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async entiteCentreGetSearchCriterias(requestParameters: EntiteCentreGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.entiteCentreGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async entiteCentreGetSelectItemsRaw(requestParameters: EntiteCentreGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling entiteCentreGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/EntiteCentre/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async entiteCentreGetSelectItems(requestParameters: EntiteCentreGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.entiteCentreGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async entiteCentreSaveRaw(requestParameters: EntiteCentreSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbEntitecentreDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/EntiteCentre`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbEntitecentreDtoToJSON(requestParameters.FcbEntitecentreDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbEntitecentreDtoFromJSON(jsonValue));
    }

    /**
     */
    async entiteCentreSave(requestParameters: EntiteCentreSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbEntitecentreDto> {
        const response = await this.entiteCentreSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
