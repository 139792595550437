/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbRapportGridDtoPaginatedResults,
  FcbReportAccesPermissionDto,
  FcbReportCentrePermissionDto,
  FcbReportModulePermissionDto,
  FcbReportPermissionsDto,
  FileDownloadDto,
  FilterCriteriaInfo,
  RapportSearch,
  ReportParametersDto,
  SelectItem,
} from '../models/index';
import {
    FcbRapportGridDtoPaginatedResultsFromJSON,
    FcbRapportGridDtoPaginatedResultsToJSON,
    FcbReportAccesPermissionDtoFromJSON,
    FcbReportAccesPermissionDtoToJSON,
    FcbReportCentrePermissionDtoFromJSON,
    FcbReportCentrePermissionDtoToJSON,
    FcbReportModulePermissionDtoFromJSON,
    FcbReportModulePermissionDtoToJSON,
    FcbReportPermissionsDtoFromJSON,
    FcbReportPermissionsDtoToJSON,
    FileDownloadDtoFromJSON,
    FileDownloadDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    RapportSearchFromJSON,
    RapportSearchToJSON,
    ReportParametersDtoFromJSON,
    ReportParametersDtoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface ReportBaseSearchRequest {
    RapportSearch?: RapportSearch;
}

export interface ReportGetDisplayNameRequest {
    name?: string;
}

export interface ReportGetPermissionsRequest {
    id?: number;
}

export interface ReportGetReportParametersRequest {
    reportName?: string;
}

export interface ReportGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ReportGetSelectItemsRequest {
    searchField: string;
}

export interface ReportGetViewableReportRequest {
    reportName?: string;
    ReportParametersDto?: ReportParametersDto;
}

export interface ReportSaveAccesPermissionsRequest {
    FcbReportAccesPermissionDto?: FcbReportAccesPermissionDto;
}

export interface ReportSaveCentrePermissionsRequest {
    FcbReportCentrePermissionDto?: FcbReportCentrePermissionDto;
}

export interface ReportSaveModulePermissionsRequest {
    FcbReportModulePermissionDto?: FcbReportModulePermissionDto;
}

export interface ReportSendToQueueRequest {
    reportName?: string;
    ReportParametersDto?: ReportParametersDto;
}

/**
 * 
 */
export class ReportApi extends runtime.BaseAPI {

    /**
     */
    async reportBaseSearchRaw(requestParameters: ReportBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRapportGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Report/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RapportSearchToJSON(requestParameters.RapportSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRapportGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async reportBaseSearch(requestParameters: ReportBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRapportGridDtoPaginatedResults> {
        const response = await this.reportBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportGetDisplayNameRaw(requestParameters: ReportGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Report/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async reportGetDisplayName(requestParameters: ReportGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.reportGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportGetPermissionsRaw(requestParameters: ReportGetPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbReportPermissionsDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Report/GetPermissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbReportPermissionsDtoFromJSON(jsonValue));
    }

    /**
     */
    async reportGetPermissions(requestParameters: ReportGetPermissionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbReportPermissionsDto> {
        const response = await this.reportGetPermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportGetReportParametersRaw(requestParameters: ReportGetReportParametersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReportParametersDto>> {
        const queryParameters: any = {};

        if (requestParameters.reportName !== undefined) {
            queryParameters['reportName'] = requestParameters.reportName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Report/GetReportParameters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportParametersDtoFromJSON(jsonValue));
    }

    /**
     */
    async reportGetReportParameters(requestParameters: ReportGetReportParametersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReportParametersDto> {
        const response = await this.reportGetReportParametersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportGetSearchCriteriasRaw(requestParameters: ReportGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Report/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async reportGetSearchCriterias(requestParameters: ReportGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.reportGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportGetSelectItemsRaw(requestParameters: ReportGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling reportGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Report/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async reportGetSelectItems(requestParameters: ReportGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.reportGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportGetViewableReportRaw(requestParameters: ReportGetViewableReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        if (requestParameters.reportName !== undefined) {
            queryParameters['reportName'] = requestParameters.reportName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Report/GetViewableReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportParametersDtoToJSON(requestParameters.ReportParametersDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async reportGetViewableReport(requestParameters: ReportGetViewableReportRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.reportGetViewableReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportSaveAccesPermissionsRaw(requestParameters: ReportSaveAccesPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbReportAccesPermissionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Report/SaveAccesPermissions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbReportAccesPermissionDtoToJSON(requestParameters.FcbReportAccesPermissionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbReportAccesPermissionDtoFromJSON(jsonValue));
    }

    /**
     */
    async reportSaveAccesPermissions(requestParameters: ReportSaveAccesPermissionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbReportAccesPermissionDto> {
        const response = await this.reportSaveAccesPermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportSaveCentrePermissionsRaw(requestParameters: ReportSaveCentrePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbReportCentrePermissionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Report/SaveCentrePermissions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbReportCentrePermissionDtoToJSON(requestParameters.FcbReportCentrePermissionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbReportCentrePermissionDtoFromJSON(jsonValue));
    }

    /**
     */
    async reportSaveCentrePermissions(requestParameters: ReportSaveCentrePermissionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbReportCentrePermissionDto> {
        const response = await this.reportSaveCentrePermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportSaveModulePermissionsRaw(requestParameters: ReportSaveModulePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbReportModulePermissionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Report/SaveModulePermissions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbReportModulePermissionDtoToJSON(requestParameters.FcbReportModulePermissionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbReportModulePermissionDtoFromJSON(jsonValue));
    }

    /**
     */
    async reportSaveModulePermissions(requestParameters: ReportSaveModulePermissionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbReportModulePermissionDto> {
        const response = await this.reportSaveModulePermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportSendToQueueRaw(requestParameters: ReportSendToQueueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.reportName !== undefined) {
            queryParameters['reportName'] = requestParameters.reportName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Report/SendToQueue`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportParametersDtoToJSON(requestParameters.ReportParametersDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async reportSendToQueue(requestParameters: ReportSendToQueueRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reportSendToQueueRaw(requestParameters, initOverrides);
    }

}
