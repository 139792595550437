/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbPersonnelTutelleDto,
  FcbPersonnelTutelleGridDtoPaginatedResults,
  FilterCriteriaInfo,
  PersonnelTutelleSearch,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    FcbPersonnelTutelleDtoFromJSON,
    FcbPersonnelTutelleDtoToJSON,
    FcbPersonnelTutelleGridDtoPaginatedResultsFromJSON,
    FcbPersonnelTutelleGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    PersonnelTutelleSearchFromJSON,
    PersonnelTutelleSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface PersonnelTutelleBaseSearchRequest {
    PersonnelTutelleSearch?: PersonnelTutelleSearch;
}

export interface PersonnelTutelleDeleteRequest {
    id?: number;
}

export interface PersonnelTutelleExistsRequest {
    code?: string;
}

export interface PersonnelTutelleGetRequest {
    id?: number;
}

export interface PersonnelTutelleGetDisplayNameRequest {
    id?: number;
}

export interface PersonnelTutelleGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface PersonnelTutelleGetSelectItemsRequest {
    searchField: string;
}

export interface PersonnelTutelleSaveRequest {
    FcbPersonnelTutelleDto?: FcbPersonnelTutelleDto;
}

/**
 * 
 */
export class PersonnelTutelleApi extends runtime.BaseAPI {

    /**
     */
    async personnelTutelleBaseSearchRaw(requestParameters: PersonnelTutelleBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbPersonnelTutelleGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PersonnelTutelle/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PersonnelTutelleSearchToJSON(requestParameters.PersonnelTutelleSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbPersonnelTutelleGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async personnelTutelleBaseSearch(requestParameters: PersonnelTutelleBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbPersonnelTutelleGridDtoPaginatedResults> {
        const response = await this.personnelTutelleBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personnelTutelleDeleteRaw(requestParameters: PersonnelTutelleDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PersonnelTutelle/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async personnelTutelleDelete(requestParameters: PersonnelTutelleDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.personnelTutelleDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personnelTutelleExistsRaw(requestParameters: PersonnelTutelleExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PersonnelTutelle/Exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async personnelTutelleExists(requestParameters: PersonnelTutelleExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.personnelTutelleExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personnelTutelleGetRaw(requestParameters: PersonnelTutelleGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbPersonnelTutelleDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PersonnelTutelle/get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbPersonnelTutelleDtoFromJSON(jsonValue));
    }

    /**
     */
    async personnelTutelleGet(requestParameters: PersonnelTutelleGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbPersonnelTutelleDto> {
        const response = await this.personnelTutelleGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personnelTutelleGetDisplayNameRaw(requestParameters: PersonnelTutelleGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PersonnelTutelle/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async personnelTutelleGetDisplayName(requestParameters: PersonnelTutelleGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.personnelTutelleGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personnelTutelleGetSearchCriteriasRaw(requestParameters: PersonnelTutelleGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PersonnelTutelle/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async personnelTutelleGetSearchCriterias(requestParameters: PersonnelTutelleGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.personnelTutelleGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personnelTutelleGetSelectItemsRaw(requestParameters: PersonnelTutelleGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling personnelTutelleGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PersonnelTutelle/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async personnelTutelleGetSelectItems(requestParameters: PersonnelTutelleGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.personnelTutelleGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async personnelTutelleSaveRaw(requestParameters: PersonnelTutelleSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbPersonnelTutelleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PersonnelTutelle/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbPersonnelTutelleDtoToJSON(requestParameters.FcbPersonnelTutelleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbPersonnelTutelleDtoFromJSON(jsonValue));
    }

    /**
     */
    async personnelTutelleSave(requestParameters: PersonnelTutelleSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbPersonnelTutelleDto> {
        const response = await this.personnelTutelleSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
