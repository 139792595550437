import { ButtonContainer, FGTextInput, showError, useApiEffect } from "nsitools-react";
import * as React from "react";
import * as Yup from "yup";

import { ExportExcelParameterDto, ExportUtilitaireApi, FieldInfo } from "../../../../api";
import { DownloadButton, SmallFormGenerator } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { exportFile } from "../../../../utils/b64download";

export interface IExportFormProps {
  selected: number;
}

const arrayToObject = (array: FieldInfo[], keyField: string, valueField: string) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item[valueField];
    return obj;
  }, {});

export const ExportForm: React.FunctionComponent<IExportFormProps> = ({ selected }) => {
  const { t } = useTl();
  const exportApi = useApiService(ExportUtilitaireApi);
  const [loading, setLoading] = React.useState(false);

  const [data, dataLoading] = useApiEffect(() => {
    return exportApi.exportUtilitaireGetExportWithParams({ idExport: selected });
  }, [selected]);

  const ExecuteQuery = React.useCallback(
    (c: FieldInfo) => {
      setLoading(true);
      const parameters: ExportExcelParameterDto[] = Object.entries(c).map(([key, value]) => {
        return { key, value };
      });
      exportApi
        .exportUtilitaireExportExcel({
          ExportExcelInfoDto: { parameters: parameters, idExport: selected }
        })
        .then(downloadResult => {
          setLoading(false);
          return exportFile(downloadResult);
        })
        .catch(error => {
          showError(t(ETLCodes.ErrorWhileExporting));
          setLoading(false);
        });
    },
    [exportApi, selected, t]
  );

  const FormSchema = React.useMemo(() => {
    const shape: { [key: string]: any } = {};
    if (data?.parameters) {
      data.parameters.forEach(p => {
        shape[p.name] = p.required ? Yup.string().required(t(ETLCodes.Required)) : Yup.string();
      });
    }
    return Yup.object().shape(shape);
  }, [data, t]);

  const newData = React.useMemo(() => {
    if (data) {
      return arrayToObject(data.parameters, "name", "value");
    } else return {};
  }, [data]);

  return (
    <>
      <SmallFormGenerator
        loading={dataLoading}
        initialValues={newData}
        onSubmit={ExecuteQuery}
        validationSchema={FormSchema}
        editMode={true}
        showDeleteButton={false}
        saving={false}
        deleting={false}
        minLabelWidth={400}
        hideButtons={true}
        disabled={loading}
      >
        {data?.parameters.map(
          c =>
            c.name !== "idUser" && (
              <FGTextInput key={c.name} name={c.name} label={c.name} labelStyles={c.required ? { color: "red" } : {}} />
            )
        )}
        <ButtonContainer>
          <DownloadButton minimal={false} intent={"primary"} loading={loading} />
        </ButtonContainer>
      </SmallFormGenerator>
    </>
  );
};
