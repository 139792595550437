/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbExportGapFormateursGridDto
 */
export interface FcbExportGapFormateursGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbExportGapFormateursGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbExportGapFormateursGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbExportGapFormateursGridDto
     */
    matricule?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbExportGapFormateursGridDto
     */
    ancienMatricule?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbExportGapFormateursGridDto
     */
    adresseDomicile?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbExportGapFormateursGridDto
     */
    idformateur?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbExportGapFormateursGridDto
     */
    idexport?: number | null;
}

/**
 * Check if a given object implements the FcbExportGapFormateursGridDto interface.
 */
export function instanceOfFcbExportGapFormateursGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbExportGapFormateursGridDtoFromJSON(json: any): FcbExportGapFormateursGridDto {
    return FcbExportGapFormateursGridDtoFromJSONTyped(json, false);
}

export function FcbExportGapFormateursGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbExportGapFormateursGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'matricule': !exists(json, 'matricule') ? undefined : json['matricule'],
        'ancienMatricule': !exists(json, 'ancienMatricule') ? undefined : json['ancienMatricule'],
        'adresseDomicile': !exists(json, 'adresseDomicile') ? undefined : json['adresseDomicile'],
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'idexport': !exists(json, 'idexport') ? undefined : json['idexport'],
    };
}

export function FcbExportGapFormateursGridDtoToJSON(value?: FcbExportGapFormateursGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nom': value.nom,
        'prenom': value.prenom,
        'matricule': value.matricule,
        'ancienMatricule': value.ancienMatricule,
        'adresseDomicile': value.adresseDomicile,
        'idformateur': value.idformateur,
        'idexport': value.idexport,
    };
}

