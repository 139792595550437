import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { CommissionParitaireApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { CommissionParitaireDetailPage } from "./CommissionParitaireDetailPage";

interface ICommissionParitaireItemPageProps {}

export const CommissionParitaireItemPage: React.FunctionComponent<ICommissionParitaireItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const idCommissionParitaire = React.useMemo(() => +id, [id]);
  const history = useHistory();

  const commissionparitaireApi = useApiService(CommissionParitaireApi);
  const [displayName] = useApiEffect(
    () => commissionparitaireApi.commissionParitaireGetDisplayName({ id: idCommissionParitaire }),
    [id]
  );

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.CommissionParitaire), route: ERoutes.commissionParitaire },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="Tabs"
        onChange={newTabId => history.push(`${ERoutes.commissionParitaire}/${id}/${newTabId}/${state}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.commissionParitaire} />
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={
            <CommissionParitaireDetailPage editMode={state === "edit"} idCommissionParitaire={idCommissionParitaire} />
          }
        />
      </ProtectedTabs>
    </PageBase>
  );
};
