/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HealthCheckViewModel,
} from '../models/index';
import {
    HealthCheckViewModelFromJSON,
    HealthCheckViewModelToJSON,
} from '../models/index';

/**
 * 
 */
export class HealthCheckApi extends runtime.BaseAPI {

    /**
     */
    async healthCheckGetHealthCheckRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthCheckViewModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/monitoring/_hc`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthCheckViewModelFromJSON(jsonValue));
    }

    /**
     */
    async healthCheckGetHealthCheck(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthCheckViewModel> {
        const response = await this.healthCheckGetHealthCheckRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async healthCheckGetLiveHealthCheckRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthCheckViewModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/monitoring/_hc/live`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthCheckViewModelFromJSON(jsonValue));
    }

    /**
     */
    async healthCheckGetLiveHealthCheck(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthCheckViewModel> {
        const response = await this.healthCheckGetLiveHealthCheckRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async healthCheckGetReadyHealthCheckRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthCheckViewModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/monitoring/_hc/ready`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthCheckViewModelFromJSON(jsonValue));
    }

    /**
     */
    async healthCheckGetReadyHealthCheck(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthCheckViewModel> {
        const response = await this.healthCheckGetReadyHealthCheckRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async healthCheckGetStartupHealthCheckRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthCheckViewModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/monitoring/_hc/startup`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthCheckViewModelFromJSON(jsonValue));
    }

    /**
     */
    async healthCheckGetStartupHealthCheck(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthCheckViewModel> {
        const response = await this.healthCheckGetStartupHealthCheckRaw(initOverrides);
        return await response.value();
    }

}
