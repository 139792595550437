import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ClasseApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { FeuillePresenceDetailPage } from "./FeuillePresenceDetailPage";

export interface IFeuillePresenceItemPageProps {}

export const FeuillePresenceItemPage: React.FunctionComponent<IFeuillePresenceItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string; date: string }>();
  const history = useHistory();

  const api = useApiService(ClasseApi);

  const [displayName] = useApiEffect(() => api.classeGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.FeuillesPresence), route: ERoutes.feuillePresence },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsFeuillePresence"
        onChange={newTabId => history.push(`${ERoutes.feuillePresence}/${id}/${newTabId}/${state}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.feuillePresence}></BackButton>
        <Tab id="detail" title={t(ETLCodes.Detail)} panel={<FeuillePresenceDetailPage />} />
      </ProtectedTabs>
    </PageBase>
  );
};
