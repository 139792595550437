import { Divider } from "@blueprintjs/core";
import { FGEmpty, FGMultiSuggestInput, FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { FcbMetierDtoFromJSON, MetierApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterCheckboxInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import { SecteurSelect } from "../../../../../components/selects/SecteurSelect";

interface IMetierDetailPageProps {
  editMode: boolean;
  idMetier: number;
}

export const MetierDetailPage: React.FunctionComponent<IMetierDetailPageProps> = ({ editMode, idMetier }) => {
  const { t } = useTl();
  const api = useApiService(MetierApi);
  const history = useHistory();
  const { codeSecteur } = useParams<{ codeSecteur: string }>();

  const [degres, loadingDegres] = useReferential(a => a.referentialGetDegre(), false, []);
  const [secteur, loadingSecteur] = useReferential(a => a.referentialGetSecteurCrea(), false, []);
  const [stade, loadingStade] = useReferential(a => a.referentialGetStade(), true, []);
  const [cp, cpLoading] = useReferential(
    r => r.referentialGetCommissionsParitaires({ idmetier: 0, idsiegeSocial: 0, currentId: 0 }),
    true,
    []
  );

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idMetier <= 0
        ? FcbMetierDtoFromJSON({ idMetier: 0, codesecteur: codeSecteur, commissionParitaires: [] })
        : api.metierGet({ id: idMetier }),
    saveApiFn: d => api.metierSave({ FcbMetierDto: d }),
    onSavedRoute: d => `${ERoutes.metier}/${d.idmetier}/detail/edit`,
    deleteApiFn: d => api.metierDelete({ id: d.idmetier }),
    onDeletedRoute: () => ERoutes.metier
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      ifapmeSide: Yup.string().required(t(ETLCodes.Required)),
      code: Yup.string()
        .required(`${t(ETLCodes.Required)}`)
        .test("stade-indice-not-exists", t(ETLCodes.MetierDetailPage_StadeIndiceExistsError), async function(value) {
          if (value === data?.code && this.parent.idstade === data?.idstade) return true;
          const validationError = await api.metierStadeIndiceMetierExists({
            code: this.parent.code,
            idStade: this.parent.idstade
          });
          return validationError.isValid;
        }),
      idstade: Yup.number()
        .required(t(ETLCodes.Required))
        .test("stade-indice-not-exists", t(ETLCodes.MetierDetailPage_StadeIndiceExistsError), async function(value) {
          if (this.parent.code === data?.code && value === data?.idstade) return true;
          const validationError = await api.metierStadeIndiceMetierExists({
            code: this.parent.code,
            idStade: this.parent.idstade
          });
          return validationError.isValid;
        }),
      masculin: Yup.string().required(t(ETLCodes.Required)),
      feminin: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t, data, api]);

  const sides = React.useMemo(
    () => [
      { label: t(ETLCodes.Centres), value: "Walter" },
      { label: t(ETLCodes.Services), value: "Hope" },
      { label: t(ETLCodes.Tous), value: "Hope|Walter" }
    ],
    [t]
  );

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={editMode}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(ERoutes.metier)}
        saving={saving}
        deleting={deleting}
        onDelete={deleteItem}
        showDeleteButton={idMetier >= 0}
      >
        <FieldGroup columns={2}>
          <FGTextInput name="code" maxLength={4} label={t(ETLCodes.MetierDetailpage_Indice)} />
          <FGTextInput name="masculin" maxLength={150} label={t(ETLCodes.MetierDetailpage_Masculin)} />
          <FGWalterSelectInput
            name="idstade"
            label={t(ETLCodes.MetierDetailpage_Stade)}
            loading={loadingStade}
            items={stade}
          />
          <FGTextInput name="feminin" maxLength={150} label={t(ETLCodes.MetierDetailpage_Feminin)} />
        </FieldGroup>
        <Divider />
        <FieldGroup columns={2}>
          <FGWalterSelectInput
            name="degreFinal"
            label={t(ETLCodes.MetierDetailpage_DegreFinal)}
            loading={loadingDegres}
            items={degres}
          />
          <FGWalterCheckboxInput name="integre" label={t(ETLCodes.MetierDetailpage_Integre)} />
          <FGWalterSelectInput
            name="degreFinalAdditionnel"
            label={t(ETLCodes.MetierDetailpage_DegreFinalAdditionnel)}
            loading={loadingDegres}
            items={degres}
          />
          <FGWalterCheckboxInput name="pratique" label={t(ETLCodes.MetierDetailpage_PratiqueSimulee)} />
          <FGWalterSelectInput
            name="idsecteurCrea"
            label={t(ETLCodes.SecteurCrea)}
            loading={loadingSecteur}
            items={secteur}
          />
          <SecteurSelect name="codesecteur" label={t(ETLCodes.SecteursMetiers)} secteurCreaFieldName="idsecteurCrea" />
          <FGMultiSuggestInput
            label={t(ETLCodes.CommissionParitaire)}
            items={cp}
            loading={cpLoading}
            name="commissionParitaires"
          />
          <FGWalterSelectInput label={t(ETLCodes.IfapmeSide)} items={sides} name="ifapmeSide" />
          <FGTextAreaInput name="remarque" label={t(ETLCodes.MetierDetailpage_Remarque)} />
          <FGEmpty />
          <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
