import * as React from "react";
import styled, { keyframes } from "styled-components";
import { useTheme } from "../hooks";

const Container = styled.div<{ size: number }>`
  width: ${({ size }) => size * 4}px;
  padding: ${({ size }) => size + size / 2}px;
`;

const DotFlashingAnimation = keyframes`
  0% {
    opacity: 1;
  }
  
  100% {
    opacity: 0.3;
  }
`;

const FlashingDots = styled.div<{ color: string; size: number }>`
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ size }) => size / 2}px;
  background-color: ${({ color }) => color};
  color: ${({ color }) => color};
  animation: ${DotFlashingAnimation} 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before,
  ::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  &::before {
    left: -${({ size }) => size + size / 2}px;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border-radius: ${({ size }) => size / 2}px;
    background-color: ${({ color }) => color};
    color: ${({ color }) => color};
    animation: ${DotFlashingAnimation} 1s infinite linear alternate;
    animation-delay: 0s;
  }
  &::after {
    left: ${({ size }) => size + size / 2}px;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border-radius: ${({ size }) => size / 2}px;
    background-color: ${({ color }) => color};
    color: ${({ color }) => color};
    animation: ${DotFlashingAnimation} 1s infinite linear alternate;
    animation-delay: 1s;
  }
`;

export interface ILoadingDotsProps {
  colorScheme?: "primary" | "white";
  size?: number;
}

export const LoadingDots: React.FunctionComponent<ILoadingDotsProps> = ({ colorScheme = "primary", size = 10 }) => {
  const { theme } = useTheme();

  const mainColor = React.useMemo(() => {
    switch (colorScheme) {
      case "primary":
        return theme.primaryColor;
      case "white":
        return theme.highlightColor;
    }
  }, [colorScheme, theme.highlightColor, theme.primaryColor]);

  return (
    <Container size={size}>
      <FlashingDots color={mainColor} size={size} />
    </Container>
  );
};
