import { Classes } from "@blueprintjs/core";
import { getIn } from "formik";
import { FGCustomPanel, FGSelectInput, IFGSelectInputProps, useFGContext } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { AddButton } from "..";
import { useEventsContext } from "../../contexts";
import { useTabMessage } from "../../hooks";
import { Guid } from "../../utils/guid";

const StyledFGSelectInput = styled(FGSelectInput)`
  & .rightButtons > .${Classes.BUTTON} {
    min-height: 0 !important;
    height: 20px;
    min-width: 0 !important;
    border-radius: 11px;
  }
`;

export interface IFGWalterSelectInputProps<T> extends IFGSelectInputProps<T> {
  itemCreateUrl?: string;
  itemCreateParams?: string;
  autoSelectIfOne?: boolean;
  emptyIfNotExists?: boolean;
  renderItem?: (T: any, query: string) => React.ReactNode;
}

export function FGWalterSelectInput<T>(props: IFGWalterSelectInputProps<T>) {
  const { dispatchEvent } = useEventsContext();
  const identifier = React.useMemo(() => Guid.newGuid(), []);
  const { messageValue, clearMessageValue } = useTabMessage(identifier);
  const { formik } = useFGContext();
  const [windowOpen, setWindowOpen] = React.useState<Window>();
  const [mustRefresh, setMustRefresh] = React.useState(false);

  const onAddClick = React.useCallback(() => {
    const win = window.open(`#${props.itemCreateUrl}?cfs=${identifier}${props.itemCreateParams ?? ""}`, "_blank");
    setWindowOpen(win);
  }, [identifier, props.itemCreateParams, props.itemCreateUrl]);

  const onWindowClosed = React.useCallback(() => {
    setMustRefresh(true);
  }, []);

  React.useEffect(() => {
    if (windowOpen) {
      windowOpen.addEventListener("beforeunload", onWindowClosed, false);
      return () => windowOpen.removeEventListener("beforeunload", onWindowClosed);
    }
  }, [onWindowClosed, windowOpen]);

  React.useEffect(() => {
    if (messageValue && mustRefresh) {
      dispatchEvent("REFERENTIAL_REFRESH");
      formik.setFieldValue(props.name, messageValue);
      clearMessageValue();
      setMustRefresh(false);
    }
  }, [clearMessageValue, dispatchEvent, formik, messageValue, mustRefresh, props.name]);

  const [hasBeenSet, setHasBeenSet] = React.useState(false);
  React.useEffect(() => {
    if (props.autoSelectIfOne && props.items?.length === 1 && !getIn(formik.initialValues, props.name) && !hasBeenSet) {
      formik.setFieldValue(props.name, props.items[0]["value"]);
      setHasBeenSet(true);
    }
  }, [formik, hasBeenSet, props.autoSelectIfOne, props.items, props.name]);

  // Empty value if it does not exist
  React.useEffect(() => {
    const val = getIn(formik.values, props.name);
    if (props.emptyIfNotExists && !!val && props.items && !props.items.find(i => i["value"] === val)) {
      formik.setFieldValue(props.name, null);
    }
  }, [formik, props.emptyIfNotExists, props.items, props.name]);

  return (
    <FGCustomPanel>
      {ctx => (
        <StyledFGSelectInput
          className="custom-select"
          filterLimit={!props.createNewItemProps ? 4 : null}
          rightElement={
            props.itemCreateUrl &&
            ctx.editMode &&
            !props.readonly &&
            !props.disabled && <AddButton onClick={onAddClick} />
          }
          {...props}
        />
      )}
    </FGCustomPanel>
  );
}
