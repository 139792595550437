/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactLieuGridDto
 */
export interface ContactLieuGridDto {
    /**
     * 
     * @type {number}
     * @memberof ContactLieuGridDto
     */
    idcontactLieu?: number;
    /**
     * 
     * @type {number}
     * @memberof ContactLieuGridDto
     */
    idcontact?: number;
    /**
     * 
     * @type {number}
     * @memberof ContactLieuGridDto
     */
    idlieuFormation?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactLieuGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactLieuGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactLieuGridDto
     */
    dateNaissance?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactLieuGridDto
     */
    fonction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactLieuGridDto
     */
    gsm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactLieuGridDto
     */
    telephone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactLieuGridDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContactLieuGridDto
     */
    principal?: boolean;
}

/**
 * Check if a given object implements the ContactLieuGridDto interface.
 */
export function instanceOfContactLieuGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContactLieuGridDtoFromJSON(json: any): ContactLieuGridDto {
    return ContactLieuGridDtoFromJSONTyped(json, false);
}

export function ContactLieuGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactLieuGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontactLieu': !exists(json, 'idcontactLieu') ? undefined : json['idcontactLieu'],
        'idcontact': !exists(json, 'idcontact') ? undefined : json['idcontact'],
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : json['dateNaissance'],
        'fonction': !exists(json, 'fonction') ? undefined : json['fonction'],
        'gsm': !exists(json, 'gsm') ? undefined : json['gsm'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'principal': !exists(json, 'principal') ? undefined : json['principal'],
    };
}

export function ContactLieuGridDtoToJSON(value?: ContactLieuGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontactLieu': value.idcontactLieu,
        'idcontact': value.idcontact,
        'idlieuFormation': value.idlieuFormation,
        'nom': value.nom,
        'prenom': value.prenom,
        'dateNaissance': value.dateNaissance,
        'fonction': value.fonction,
        'gsm': value.gsm,
        'telephone': value.telephone,
        'email': value.email,
        'principal': value.principal,
    };
}

