/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbExportGapClassesGridDto
 */
export interface FcbExportGapClassesGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbExportGapClassesGridDto
     */
    codeClasse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbExportGapClassesGridDto
     */
    classe?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbExportGapClassesGridDto
     */
    nbheuresSemestre1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbExportGapClassesGridDto
     */
    nbheuresSemestre2?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbExportGapClassesGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbExportGapClassesGridDto
     */
    idclasse?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbExportGapClassesGridDto
     */
    idexport?: number | null;
}

/**
 * Check if a given object implements the FcbExportGapClassesGridDto interface.
 */
export function instanceOfFcbExportGapClassesGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbExportGapClassesGridDtoFromJSON(json: any): FcbExportGapClassesGridDto {
    return FcbExportGapClassesGridDtoFromJSONTyped(json, false);
}

export function FcbExportGapClassesGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbExportGapClassesGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'codeClasse': !exists(json, 'codeClasse') ? undefined : json['codeClasse'],
        'classe': !exists(json, 'classe') ? undefined : json['classe'],
        'nbheuresSemestre1': !exists(json, 'nbheuresSemestre1') ? undefined : json['nbheuresSemestre1'],
        'nbheuresSemestre2': !exists(json, 'nbheuresSemestre2') ? undefined : json['nbheuresSemestre2'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'idexport': !exists(json, 'idexport') ? undefined : json['idexport'],
    };
}

export function FcbExportGapClassesGridDtoToJSON(value?: FcbExportGapClassesGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'codeClasse': value.codeClasse,
        'classe': value.classe,
        'nbheuresSemestre1': value.nbheuresSemestre1,
        'nbheuresSemestre2': value.nbheuresSemestre2,
        'nom': value.nom,
        'idclasse': value.idclasse,
        'idexport': value.idexport,
    };
}

