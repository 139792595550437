import { Card, Classes, FormGroup, InputGroup, Switch } from "@blueprintjs/core";
import { FGTextInput, FieldGroup, FieldSet, Loading, useApiEffect } from "nsitools-react";
import * as React from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { FormateurActifCentreTable } from "..";
import { AzureApi, AzureUserDtoFromJSON, FcbFormateurDtoFromJSON, FormateurApi } from "../../../../../api";
import { CreateAzureButton, SmallFormGenerator } from "../../../../../components";
import { useAuth, useDialog } from "../../../../../contexts";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IFormateurAzureProps {}

const StyledForm = styled.form`
  & .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }

  & .${Classes.FORM_CONTENT} {
    flex: ${1};
  }

  & .${Classes.LABEL} {
    min-width: ${"180px"};
    text-align: ${"right"};
    font-weight: ${"600"};
  }

  & .inlineField .${Classes.LABEL} {
    min-width: 0px;
  }
`;

export const FormateurAzure: React.FunctionComponent<IFormateurAzureProps> = () => {
  const { id: idformateur } = useParams<{ id: string; state: string }>();
  const api = useApiService(FormateurApi);
  const azureApi = useApiService(AzureApi);
  const { t } = useTl();
  const { showDialog } = useDialog();
  const { getCurrentPermission } = useAuth();

  const [aLoading, setALoading] = React.useState(false);
  const [cLoading, setCLoading] = React.useState(false);

  const getData = React.useCallback(() => {
    return +idformateur <= 0
      ? FcbFormateurDtoFromJSON({
          idformateur: 0
        })
      : api.formateurGet({ id: +idformateur });
  }, [api, idformateur]);
  const [data, loading, , refresh] = useApiEffect(getData, [getData, idformateur]);

  const getAzureData = React.useCallback(() => {
    if (data?.azureUserId === null || data?.azureUserId === undefined) {
      return AzureUserDtoFromJSON({});
    } else {
      return azureApi.azureGetAsync({ o365UserId: data?.azureUserId });
    }
  }, [azureApi, data?.azureUserId]);
  const [azureData, azureLoading, , refreshAzure] = useApiEffect(getAzureData, [getAzureData, data?.azureUserId]);

  const onActiveChanged = React.useCallback(
    async value => {
      if (!value) {
        showDialog({
          message: value ? t(ETLCodes.ConfirmAzureActivate) : t(ETLCodes.ConfirmAzureDeactivate),
          title: t(ETLCodes.RouteConfirmDialogTitle),
          onDialogClosed: async res => {
            if (res === "yes" || res === "ok") {
              setALoading(true);
              await azureApi.azureEnableFormateur({
                id: +idformateur,
                o365UserId: data?.azureUserId,
                accountEnabled: value
              });
            }
            setALoading(false);
            refresh();
            refreshAzure();
          }
        });
      } else {
        setALoading(true);
        await azureApi.azureEnableFormateur({
          id: +idformateur,
          o365UserId: data?.azureUserId,
          accountEnabled: value
        });
        setALoading(false);
        refresh();
        refreshAzure();
      }
    },
    [azureApi, data?.azureUserId, idformateur, refresh, refreshAzure, showDialog, t]
  );

  const onCreateFormateur = React.useCallback(async () => {
    setCLoading(true);
    var res = await azureApi.azureCreateFormateur({ FcbFormateurDto: data });
    setCLoading(false);
    refresh();
    refreshAzure();
    return res;
  }, [azureApi, data, refresh, refreshAzure]);

  const canActivate = React.useMemo(() => getCurrentPermission()?.permission === "RWD", [getCurrentPermission]);
  const isActivable = React.useMemo(() => azureData?.o365UserId !== null && azureData?.o365UserId !== undefined, [
    azureData?.o365UserId
  ]);

  const ActiveSwitch = React.useCallback(() => {
    return (
      <Loading isLoading={aLoading} size={20}>
        <FormGroup
          label={
            <>
              {t(ETLCodes.AzureActive)}
              <span>&nbsp;:</span>
            </>
          }
          inline
        >
          <Switch
            large={false}
            disabled={!canActivate || !isActivable}
            checked={data?.azureActive || false}
            onClick={e => onActiveChanged(e.currentTarget.checked)}
          ></Switch>
        </FormGroup>
      </Loading>
    );
  }, [aLoading, canActivate, data?.azureActive, isActivable, onActiveChanged, t]);

  const CentresActifs = React.useCallback(() => <FormateurActifCentreTable codeCentres={azureData?.department} />, [
    azureData?.department
  ]);

  return (
    <>
      <StyledForm>
        <Loading isLoading={loading}>
          <Grid fluid style={{ paddingTop: "10px" }}>
            <Row>
              <Col xs={6}>
                <FormGroup
                  label={
                    <>
                      {t(ETLCodes.EmailIfapme)}
                      <span>&nbsp;:</span>
                    </>
                  }
                  inline
                >
                  <InputGroup
                    large={false}
                    autoComplete="off"
                    value={data?.azureEmail || ""}
                    fill={true}
                    readOnly
                    style={{ boxShadow: "none", backgroundColor: "transparent" }}
                  ></InputGroup>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup
                  label={
                    <>
                      {t(ETLCodes.TemporaryPassword)}
                      <span>&nbsp;:</span>
                    </>
                  }
                  inline
                >
                  <InputGroup
                    large={false}
                    autoComplete="off"
                    value={data?.azureTemporaryPassword || ""}
                    fill={true}
                    readOnly
                    style={{ boxShadow: "none", backgroundColor: "transparent" }}
                  ></InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <ActiveSwitch />
              </Col>
            </Row>
          </Grid>
        </Loading>
      </StyledForm>
      <Card elevation={1} style={{ padding: "0.5em", marginBottom: "0.5em" }}>
        <FieldSet
          title={t(ETLCodes.AzureInformation)}
          rightElement={
            !isActivable ? <CreateAzureButton onClick={onCreateFormateur} loading={cLoading} minimal={false} /> : null
          }
        >
          <SmallFormGenerator
            initialValues={azureData}
            loading={azureLoading}
            hideButtons
            onSubmit={() => {}}
            editable={false}
          >
            <FieldGroup columns={2}>
              <FieldGroup>
                <FGTextInput name="userPrincipalName" label={t(ETLCodes.PrincipalName)} readonly />
                <FGTextInput name="firstName" label={t(ETLCodes.Prenom)} readonly />
                <FGTextInput name="displayName" label={t(ETLCodes.DisplayName)} readonly />
                <FGTextInput name="department" label={t(ETLCodes.Centre)} readonly />
              </FieldGroup>
              <FieldGroup>
                <FGTextInput name="mailNickName" label={t(ETLCodes.Email)} readonly />
                <FGTextInput name="lastName" label={t(ETLCodes.Nom)} readonly />
                <FGTextInput name="jobTitle" label={t(ETLCodes.JobTitle)} readonly />
                <FGTextInput name="identifier" label={t(ETLCodes.Identifiant)} readonly />
              </FieldGroup>
            </FieldGroup>
          </SmallFormGenerator>
        </FieldSet>
      </Card>
      <Card elevation={1} style={{ padding: "0.5em" }}>
        <CentresActifs />
      </Card>
    </>
  );
};
