/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EContentHidingMode } from './EContentHidingMode';
import {
    EContentHidingModeFromJSON,
    EContentHidingModeFromJSONTyped,
    EContentHidingModeToJSON,
} from './EContentHidingMode';
import type { SuiviActionEditDto } from './SuiviActionEditDto';
import {
    SuiviActionEditDtoFromJSON,
    SuiviActionEditDtoFromJSONTyped,
    SuiviActionEditDtoToJSON,
} from './SuiviActionEditDto';
import type { SuiviFichierGridDto } from './SuiviFichierGridDto';
import {
    SuiviFichierGridDtoFromJSON,
    SuiviFichierGridDtoFromJSONTyped,
    SuiviFichierGridDtoToJSON,
} from './SuiviFichierGridDto';

/**
 * 
 * @export
 * @interface SuiviHopeEditDto
 */
export interface SuiviHopeEditDto {
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeEditDto
     */
    idsuivi?: number;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeEditDto
     */
    idservice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeEditDto
     */
    iduserService?: number;
    /**
     * 
     * @type {string}
     * @memberof SuiviHopeEditDto
     */
    userService?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeEditDto
     */
    creationUser?: number;
    /**
     * 
     * @type {string}
     * @memberof SuiviHopeEditDto
     */
    encodeur?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SuiviHopeEditDto
     */
    dateSuivi?: Date;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeEditDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {string}
     * @memberof SuiviHopeEditDto
     */
    apprenant?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeEditDto
     */
    idcontrat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeEditDto
     */
    idtypeSuivi?: number;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeEditDto
     */
    idsousTypeSuivi?: number;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeEditDto
     */
    iddecisionCommissionTutelle?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeEditDto
     */
    idmode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeEditDto
     */
    idlieuRencontre?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviHopeEditDto
     */
    contenu?: string | null;
    /**
     * 
     * @type {EContentHidingMode}
     * @memberof SuiviHopeEditDto
     */
    contentHidingMode?: EContentHidingMode;
    /**
     * 
     * @type {boolean}
     * @memberof SuiviHopeEditDto
     */
    action?: boolean;
    /**
     * 
     * @type {SuiviActionEditDto}
     * @memberof SuiviHopeEditDto
     */
    suiviAction?: SuiviActionEditDto;
    /**
     * 
     * @type {boolean}
     * @memberof SuiviHopeEditDto
     */
    fromSmile?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeEditDto
     */
    idmetier?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeEditDto
     */
    idlieuFormation?: number | null;
    /**
     * 
     * @type {Array<SuiviFichierGridDto>}
     * @memberof SuiviHopeEditDto
     */
    fichiers?: Array<SuiviFichierGridDto> | null;
}

/**
 * Check if a given object implements the SuiviHopeEditDto interface.
 */
export function instanceOfSuiviHopeEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SuiviHopeEditDtoFromJSON(json: any): SuiviHopeEditDto {
    return SuiviHopeEditDtoFromJSONTyped(json, false);
}

export function SuiviHopeEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuiviHopeEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsuivi': !exists(json, 'idsuivi') ? undefined : json['idsuivi'],
        'idservice': !exists(json, 'idservice') ? undefined : json['idservice'],
        'iduserService': !exists(json, 'iduserService') ? undefined : json['iduserService'],
        'userService': !exists(json, 'userService') ? undefined : json['userService'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'encodeur': !exists(json, 'encodeur') ? undefined : json['encodeur'],
        'dateSuivi': !exists(json, 'dateSuivi') ? undefined : (new Date(json['dateSuivi'])),
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'apprenant': !exists(json, 'apprenant') ? undefined : json['apprenant'],
        'idcontrat': !exists(json, 'idcontrat') ? undefined : json['idcontrat'],
        'idtypeSuivi': !exists(json, 'idtypeSuivi') ? undefined : json['idtypeSuivi'],
        'idsousTypeSuivi': !exists(json, 'idsousTypeSuivi') ? undefined : json['idsousTypeSuivi'],
        'iddecisionCommissionTutelle': !exists(json, 'iddecisionCommissionTutelle') ? undefined : json['iddecisionCommissionTutelle'],
        'idmode': !exists(json, 'idmode') ? undefined : json['idmode'],
        'idlieuRencontre': !exists(json, 'idlieuRencontre') ? undefined : json['idlieuRencontre'],
        'contenu': !exists(json, 'contenu') ? undefined : json['contenu'],
        'contentHidingMode': !exists(json, 'contentHidingMode') ? undefined : EContentHidingModeFromJSON(json['contentHidingMode']),
        'action': !exists(json, 'action') ? undefined : json['action'],
        'suiviAction': !exists(json, 'suiviAction') ? undefined : SuiviActionEditDtoFromJSON(json['suiviAction']),
        'fromSmile': !exists(json, 'fromSmile') ? undefined : json['fromSmile'],
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'fichiers': !exists(json, 'fichiers') ? undefined : (json['fichiers'] === null ? null : (json['fichiers'] as Array<any>).map(SuiviFichierGridDtoFromJSON)),
    };
}

export function SuiviHopeEditDtoToJSON(value?: SuiviHopeEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsuivi': value.idsuivi,
        'idservice': value.idservice,
        'iduserService': value.iduserService,
        'userService': value.userService,
        'creationUser': value.creationUser,
        'encodeur': value.encodeur,
        'dateSuivi': value.dateSuivi === undefined ? undefined : (value.dateSuivi.toISOString()),
        'idapprenant': value.idapprenant,
        'apprenant': value.apprenant,
        'idcontrat': value.idcontrat,
        'idtypeSuivi': value.idtypeSuivi,
        'idsousTypeSuivi': value.idsousTypeSuivi,
        'iddecisionCommissionTutelle': value.iddecisionCommissionTutelle,
        'idmode': value.idmode,
        'idlieuRencontre': value.idlieuRencontre,
        'contenu': value.contenu,
        'contentHidingMode': EContentHidingModeToJSON(value.contentHidingMode),
        'action': value.action,
        'suiviAction': SuiviActionEditDtoToJSON(value.suiviAction),
        'fromSmile': value.fromSmile,
        'idmetier': value.idmetier,
        'idlieuFormation': value.idlieuFormation,
        'fichiers': value.fichiers === undefined ? undefined : (value.fichiers === null ? null : (value.fichiers as Array<any>).map(SuiviFichierGridDtoToJSON)),
    };
}

