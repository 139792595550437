import * as React from "react";
import { matchPath, useLocation, useParams } from "react-router";
import { LieuFormationTuteurListPage } from ".";
import { TuteurLieuDoublonSearchDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { LieuFormationTuteur } from "./item/LieuFormationTuteur";
import { LieuFormationTuteurDoublons } from "./item/LieuFormationTuteurDoublons";

export interface ILieuFormationTuteurSwitchProps {}

export const LieuFormationTuteurSwitch: React.FunctionComponent<ILieuFormationTuteurSwitchProps> = props => {
  const [sObj, setSObj] = React.useState(TuteurLieuDoublonSearchDtoFromJSON({}));
  const { pathname } = useLocation();
  const { id } = useParams<{ id: string; tab: string; state: string }>();
  if (
    matchPath(pathname, {
      path: `${ERoutes.lieuFormation}/${id}${ERoutes.tuteur}/:state/:subId`
    })
  ) {
    return <LieuFormationTuteur baseData={sObj} />;
  } else if (
    matchPath(pathname, {
      path: `${ERoutes.lieuFormation}/${id}${ERoutes.tuteur}/checkDoublons`
    })
  ) {
    return <LieuFormationTuteurDoublons setBaseData={setSObj} />;
  } else {
    return <LieuFormationTuteurListPage />;
  }
};
