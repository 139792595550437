import * as React from "react";
import { SearchTablePage } from "../../../../../components";
import { useTl, useApiService, useAbortableApiServiceFactory } from "../../../../../hooks";
import { useParams } from "react-router";
import { ETLCodes } from "../../../../../locales";
import { useGlobalData } from "../../../../../contexts";
import { Checkbox, Button, Intent } from "@blueprintjs/core";
import { FormateurHoraireIncoherenceApi } from "../../../../../api/apis/FormateurHoraireIncoherenceApi";
import {
  FormateurHoraireIncoherenceGridDto,
  FormateurHoraireIncoherenceSearch,
  ETextSearchType,
  EBooleanSearchTypes,
  EListSearchType
} from "../../../../../api";
import styled from "styled-components";
import { showSuccess, showError } from "nsitools-react";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export interface IFormateurHoraireIncoherenceGridProps {}

export const FormateurHoraireIncoherenceGrid: React.FunctionComponent<IFormateurHoraireIncoherenceGridProps> = () => {
  const { t } = useTl();
  const api = useApiService(FormateurHoraireIncoherenceApi);
  const { id } = useParams<{ id: string }>();
  const { currentAnneeScolaire } = useGlobalData();

  const [loading, setLoading] = React.useState(false);

  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.Jour),
        fieldName: "jour"
      },
      {
        header: () => t(ETLCodes.Debut),
        fieldName: "heureDebut"
      },
      {
        header: () => t(ETLCodes.Fin),
        fieldName: "heureFin"
      },
      {
        header: () => t(ETLCodes.Motif),
        fieldName: "motif"
      },
      {
        header: () => t(ETLCodes.TypeIncoherence),
        fieldName: "typeIncoherence"
      },
      {
        header: () => t(ETLCodes.IncoherenceCentre),
        fieldName: "centreUtilisateurFlag",
        render: (row: FormateurHoraireIncoherenceGridDto) => (
          <Checkbox checked={row.centreUtilisateurFlag} onClick={() => {}} disabled />
        )
      }
    ],
    [t]
  );

  const getCriterias = React.useCallback(
    () => api.formateurHoraireIncoherenceGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  const apiFactory = useAbortableApiServiceFactory(FormateurHoraireIncoherenceApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (nextSearch?: FormateurHoraireIncoherenceSearch) => {
      nextSearch.idformateur = +id;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.formateurHoraireIncoherenceBaseSearch({ FormateurHoraireIncoherenceSearch: nextSearch });
    },
    [apiFactory, id]
  );

  const calculCoherence = React.useCallback(async () => {
    setLoading(true);
    const res = await api.formateurHoraireIncoherenceCalculCoherence({ idFormateur: +id });

    if (res.isValid) {
      showSuccess(t(ETLCodes.CalculCoherenceSuccess));
      search({});
    } else {
      showError(t(ETLCodes.CalculCoherenceError));
    }

    setLoading(false);
  }, [api, id, search, t]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <SearchTablePage
        avoidSavingCriterias={true}
        title={ETLCodes.Incoherence}
        columns={columns}
        getCriteriasFunction={getCriterias}
        searchFunction={search}
        onAbort={onAbort}
        withCard={false}
        sortKeys={{ jour: "ASC" }}
        defaultCriterias={[
          {
            criteria: "AnneeAcademique",
            searchMode: ETextSearchType.Equals,
            value: currentAnneeScolaire.description,
            mandatory: true
          },
          {
            criteria: "IdtypeIncoherence",
            searchMode: EListSearchType.Equals,
            value: 1
          },
          {
            criteria: "CentreUtilisateurFlag",
            searchMode: EBooleanSearchTypes.Equals,
            value: true
          }
        ]}
      />
      <Container>
        <Button
          onClick={calculCoherence}
          text={t(ETLCodes.CalculCoherence)}
          intent={Intent.PRIMARY}
          loading={loading}
          type="button"
        />
      </Container>
    </>
  );
};
