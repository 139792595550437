import { Intent } from "@blueprintjs/core";
import { DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ClauseMetierLiesApi, ClauseMetierSearch } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IClauseMetierLiesPageProps {}

export const ClauseMetierLiesPage: React.FunctionComponent<IClauseMetierLiesPageProps> = () => {
  const { id, state } = useParams<{ id: string; state: string }>();
  const idClause = React.useMemo(() => +id, [id]);
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ClauseMetierLiesApi);

  const tableState = useGridState<any>({
    sortKeys: { indice: "ASC" },
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15
  });

  const searchFunction = React.useCallback(
    (sObj?: ClauseMetierSearch) => {
      sObj.idclause = +idClause;
      return api.clauseMetierLiesBaseSearch({ ClauseMetierSearch: sObj });
    },
    [api, idClause]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });
  const { totalCount } = tableState;
  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.Indice),
        fieldName: "indice"
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      }
    ],
    [t]
  );

  return (
    <FieldSet
      title={t(ETLCodes.TableResults, { count: totalCount })}
      rightElement={
        <EditButton
          onClick={() => history.push(`${ERoutes.clause}/${idClause}/MetierLies/${state}/selector`)}
          intent={Intent.PRIMARY}
          text={t(ETLCodes.Modify)}
        />
      }
    >
      <DataTable dateFormat="dd/MM/yyyy" tableState={tableState} loading={loading} columns={columns}></DataTable>
    </FieldSet>
  );
};
