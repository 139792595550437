/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbConseillerMetiersDto
 */
export interface FcbConseillerMetiersDto {
    /**
     * 
     * @type {string}
     * @memberof FcbConseillerMetiersDto
     */
    codeConseiller?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FcbConseillerMetiersDto
     */
    idMetiers?: Array<number> | null;
}

/**
 * Check if a given object implements the FcbConseillerMetiersDto interface.
 */
export function instanceOfFcbConseillerMetiersDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbConseillerMetiersDtoFromJSON(json: any): FcbConseillerMetiersDto {
    return FcbConseillerMetiersDtoFromJSONTyped(json, false);
}

export function FcbConseillerMetiersDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbConseillerMetiersDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'codeConseiller': !exists(json, 'codeConseiller') ? undefined : json['codeConseiller'],
        'idMetiers': !exists(json, 'idMetiers') ? undefined : json['idMetiers'],
    };
}

export function FcbConseillerMetiersDtoToJSON(value?: FcbConseillerMetiersDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'codeConseiller': value.codeConseiller,
        'idMetiers': value.idMetiers,
    };
}

