import { Checkbox } from "@blueprintjs/core";
import { IDataTableColumn } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";

import { EvaluationApi, EvaluationSearch, FcbEvaluationGridDto, ETextSearchType } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../components";
import { useGlobalData } from "../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import styled from "styled-components";

export interface IEvaluationListPageProps {}
const Container = styled.div`
  display: flex;
`;
export const EvaluationListPage: React.FunctionComponent<IEvaluationListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(EvaluationApi);
  const { currentAnneeScolaire } = useGlobalData();

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbEvaluationGridDto) => (
          <Container>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.evaluation}/${row.idevaluation}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.evaluation}/${row.idevaluation}/detail/edit`)}
            />
          </Container>
        )
      },
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "annee"
      },
      {
        header: () => t(ETLCodes.CodeClasse),
        fieldName: "codeClasse"
      },
      {
        header: () => t(ETLCodes.LibelleClasse),
        fieldName: "libelleClasse"
      },
      {
        header: () => t(ETLCodes.CodeMatiere),
        fieldName: "codeMatiere"
      },
      {
        header: () => t(ETLCodes.Formateur),
        fieldName: "formateur"
      },
      {
        header: () => t(ETLCodes.TypeEvaluation),
        fieldName: "type"
      },
      {
        header: () => t(ETLCodes.Date),
        fieldName: "date"
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "libelle"
      },
      {
        header: () => t(ETLCodes.CoteMax),
        fieldName: "coteMax"
      },
      {
        header: () => t(ETLCodes.Valide),
        fieldName: "valide",
        alignment: "center",
        render: (row: FcbEvaluationGridDto) => <Checkbox checked={row.valide} onClick={() => {}} />
      }
    ],
    [history, t]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.evaluation}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(() => api.evaluationGetSearchCriterias({ includeListsValues: true }), [api]);

  const apiFactory = useAbortableApiServiceFactory(EvaluationApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: EvaluationSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.evaluationBaseSearch({ EvaluationSearch: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      columns={columns}
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      addFunc={addItemFunction}
      sortKeys={{ codeMatiere: "ASC" }}
      breadCrumbs={[{ text: t(ETLCodes.Evaluations) }]}
      searchStateInitialSearch={false}
      defaultCriterias={[
        {
          criteria: "Annee",
          searchMode: ETextSearchType.Equals,
          value: currentAnneeScolaire?.idanneeScolaire
        }
      ]}
    />
  );
};
