/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FileDownloadDto
 */
export interface FileDownloadDto {
    /**
     * 
     * @type {string}
     * @memberof FileDownloadDto
     */
    readonly fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileDownloadDto
     */
    readonly base64Content?: string | null;
}

/**
 * Check if a given object implements the FileDownloadDto interface.
 */
export function instanceOfFileDownloadDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FileDownloadDtoFromJSON(json: any): FileDownloadDto {
    return FileDownloadDtoFromJSONTyped(json, false);
}

export function FileDownloadDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileDownloadDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'base64Content': !exists(json, 'base64Content') ? undefined : json['base64Content'],
    };
}

export function FileDownloadDtoToJSON(value?: FileDownloadDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

