/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EFilterSearchType {
    Contains = 'Contains',
    NotContains = 'NotContains'
}


export function EFilterSearchTypeFromJSON(json: any): EFilterSearchType {
    return EFilterSearchTypeFromJSONTyped(json, false);
}

export function EFilterSearchTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EFilterSearchType {
    return json as EFilterSearchType;
}

export function EFilterSearchTypeToJSON(value?: EFilterSearchType | null): any {
    return value as any;
}

