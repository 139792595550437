import { FGListen, FGNumberInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { FcbMatiereReferentielDtoFromJSON, MatiereReferentielApi } from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import { FGWalterCheckboxInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

export interface IReferentielMatiereDetailPageProps {}

export const ReferentielMatiereDetailPage: React.FunctionComponent<IReferentielMatiereDetailPageProps> = () => {
  const { t } = useTl();
  const api = useApiService(MatiereReferentielApi);
  const history = useHistory();
  const { id, matiereId, state } = useParams<{ id: string; matiereId: string; state: string }>();
  const ReferentielId = React.useMemo(() => +id, [id]);
  const editMode = React.useMemo(() => state === "edit", [state]);

  const [projetFinancement, projetFinancementLoading] = useReferential(
    a => a.referentialGetProjetFinancement(),
    true,
    []
  );
  const [niveau, setNiveau] = React.useState(0);
  const [matieresParent, mpLoading] = useReferential(
    a => niveau > 0 && a.referentialGetReferentielMatieresByNiveau({ idreferentiel: +id, niveau }),
    true,
    [id, niveau]
  );

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      matiereId === "0"
        ? FcbMatiereReferentielDtoFromJSON({ idreferentiel: ReferentielId, niveau: 0, isEditable: true })
        : api.matiereReferentielGet({ idReferentiel: ReferentielId, idMatiere: matiereId }),
    saveApiFn: d => api.matiereReferentielSave({ FcbMatiereReferentielDto: d }),
    onSavedRoute: d => `${ERoutes.referentiel}/${d.idreferentiel}/matiere/${d.idmatiere}/edit`,
    deleteApiFn: d => api.matiereReferentielDelete({ idReferentiel: d.idreferentiel, idMatiere: d.idmatiere }),
    onDeletedRoute: d => `${ERoutes.referentiel}/${d.idreferentiel}/matiere`
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idmatiere: Yup.string()
        .required(t(ETLCodes.Required))
        .max(4),
      nbheures: Yup.number().nullable(),
      travail: Yup.number().nullable(),
      examen: Yup.number().nullable(),
      pcReussite: Yup.number().nullable(),
      categorie: Yup.number().nullable(),
      niveau: Yup.number()
        .nullable()
        .required(t(ETLCodes.Required)),
      idmatiereParent: Yup.number()
        .nullable()
        .when("niveau", {
          is: val => val > 0,
          then: Yup.number()
            .nullable()
            .required(t(ETLCodes.Required))
        })
    });
  }, [t]);

  const isEditable = React.useMemo(() => {
    if (data) {
      return data.isEditable;
    }
  }, [data]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      showColons
      editMode={editMode}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(`${ERoutes.referentiel}/${ReferentielId}/matiere`)}
      onDelete={deleteItem}
      showDeleteButton={false} //matiereId !== "0"}
      saving={saving}
      deleting={deleting}
    >
      <FGListen field="niveau" onChanged={value => setNiveau(value)} />
      <FieldGroup columns={1}>
        <FGTextInput name="idmatiere" label={t(ETLCodes.Code)} disabled={!isEditable || matiereId !== "0"} />
        <FGTextInput name="description" label={t(ETLCodes.Description)} />
        <FGTextInput name="nbheures" label={t(ETLCodes.NbHeure)} disabled={!isEditable} />
        <FGTextInput name="travail" label={t(ETLCodes.TJ)} disabled={!isEditable} />
        <FGTextInput name="examen" label={t(ETLCodes.Examen)} disabled={!isEditable} />
        <FGTextInput name="pcReussite" label={t(ETLCodes.Reussite)} disabled={!isEditable} />
        <FGTextInput name="categorie" label={t(ETLCodes.Categorie)} disabled={!isEditable} />
        <FGWalterSelectInput
          name="idprojetFinancement"
          label={t(ETLCodes.TypeProjet)}
          items={projetFinancement}
          loading={projetFinancementLoading}
        />
        <FGNumberInput name="niveau" label={t(ETLCodes.Niveau)} disabled={!isEditable} min={0} />
        <FGWalterSelectInput
          items={matieresParent}
          loading={mpLoading}
          label={t(ETLCodes.MatiereParente)}
          name="idmatiereParent"
          visible={ctx => ctx?.formik?.values?.niveau > 0}
        />
        <FGWalterCheckboxInput name="uaa" label={t(ETLCodes.UAA)} disabled={!isEditable} />
        <FGWalterCheckboxInput name="isVisibleBulletin" label={t(ETLCodes.VisibleBulletin)} disabled={!isEditable} />
        <FGWalterCheckboxInput name="sommative" label={t(ETLCodes.Sommative)} disabled={!isEditable} />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
