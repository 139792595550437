/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbDemandeApprenantGridDto
 */
export interface FcbDemandeApprenantGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbDemandeApprenantGridDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbDemandeApprenantGridDto
     */
    iddemande?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbDemandeApprenantGridDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDemandeApprenantGridDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDemandeApprenantGridDto
     */
    idtype?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbDemandeApprenantGridDto
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDemandeApprenantGridDto
     */
    idcentre?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDemandeApprenantGridDto
     */
    centre?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDemandeApprenantGridDto
     */
    iddelegueTutelle?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDemandeApprenantGridDto
     */
    delegueTutelle?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDemandeApprenantGridDto
     */
    idmetier?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDemandeApprenantGridDto
     */
    metier?: string | null;
}

/**
 * Check if a given object implements the FcbDemandeApprenantGridDto interface.
 */
export function instanceOfFcbDemandeApprenantGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbDemandeApprenantGridDtoFromJSON(json: any): FcbDemandeApprenantGridDto {
    return FcbDemandeApprenantGridDtoFromJSONTyped(json, false);
}

export function FcbDemandeApprenantGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbDemandeApprenantGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'iddemande': !exists(json, 'iddemande') ? undefined : json['iddemande'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'idtype': !exists(json, 'idtype') ? undefined : json['idtype'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'centre': !exists(json, 'centre') ? undefined : json['centre'],
        'iddelegueTutelle': !exists(json, 'iddelegueTutelle') ? undefined : json['iddelegueTutelle'],
        'delegueTutelle': !exists(json, 'delegueTutelle') ? undefined : json['delegueTutelle'],
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'metier': !exists(json, 'metier') ? undefined : json['metier'],
    };
}

export function FcbDemandeApprenantGridDtoToJSON(value?: FcbDemandeApprenantGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idapprenant': value.idapprenant,
        'iddemande': value.iddemande,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'description': value.description,
        'idtype': value.idtype,
        'type': value.type,
        'idcentre': value.idcentre,
        'centre': value.centre,
        'iddelegueTutelle': value.iddelegueTutelle,
        'delegueTutelle': value.delegueTutelle,
        'idmetier': value.idmetier,
        'metier': value.metier,
    };
}

