import { FGTextAreaInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { FcbFormateurDesideratumDtoFromJSON, FormateurDesideratumApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

export interface IFormateurPreferenceHoraireDetailPageProps {}

export const FormateurPreferenceHoraireDetailPage: React.FunctionComponent<IFormateurPreferenceHoraireDetailPageProps> = () => {
  const { id, idPreference, state } = useParams<{ id: string; idPreference: string; state: string }>();
  const { t } = useTl();
  const api = useApiService(FormateurDesideratumApi);
  const history = useHistory();

  const [annee, loadingAnnee] = useReferential(a => a.referentialGetAnneeScolaire(), false, []);
  const [centre, loadingCentre] = useReferential(a => a.referentialGetCentre(), true, []);
  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      anneeScolaire: Yup.string().required(t(ETLCodes.Required)),
      idcentre: Yup.number().required(t(ETLCodes.Required))
    });
  }, [t]);

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idPreference <= 0
        ? FcbFormateurDesideratumDtoFromJSON({ idformateur: +id })
        : api.formateurDesideratumGet({ id: +idPreference }),
    saveApiFn: d => api.formateurDesideratumSave({ FcbFormateurDesideratumDto: d }),
    onSavedRoute: d => `${ERoutes.formateur}/${d.idformateur}/preferencesHoraire`,
    deleteApiFn: d => api.formateurDesideratumDelete({ id: d.iddesiderata }),
    onDeletedRoute: () => `${ERoutes.formateur}/${+id}/preferencesHoraire`
  });

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        showColons
        editMode={state === "edit"}
        minLabelWidth={180}
        labelAlignment="right"
        formatDate="dd/MM/yyyy"
        validationSchema={FormSchema}
        inline
        boldLabel
        loading={loading}
        onCancel={() => history.push(`${ERoutes.formateur}/${+id}/preferencesHoraire`)}
        onDelete={deleteItem}
        showDeleteButton={+idPreference > 0}
        saving={saving}
        deleting={deleting}
      >
        <FieldGroup columns={1}>
          <FGWalterSelectInput
            name="anneeScolaire"
            label={t(ETLCodes.AnneeScolaire)}
            items={annee}
            loading={loadingAnnee}
          />
          <FGWalterSelectInput name="idcentre" label={t(ETLCodes.Centre)} items={centre} loading={loadingCentre} />
          <FGTextAreaInput name="desiderata" label={t(ETLCodes.Desiderata)} />
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
