/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LieuFormationAgrementRetraitMultipleDto
 */
export interface LieuFormationAgrementRetraitMultipleDto {
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementRetraitMultipleDto
     */
    idlieuFormation?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof LieuFormationAgrementRetraitMultipleDto
     */
    idslieuFormationAgrement?: Array<number> | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementRetraitMultipleDto
     */
    datePriseEffetRetrait?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementRetraitMultipleDto
     */
    dateRetrait?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationAgrementRetraitMultipleDto
     */
    remarqueRetrait?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationAgrementRetraitMultipleDto
     */
    retraitIsIfapme?: boolean | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof LieuFormationAgrementRetraitMultipleDto
     */
    idsMotifRetrait?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationAgrementRetraitMultipleDto
     */
    disableLieuFormation?: boolean;
}

/**
 * Check if a given object implements the LieuFormationAgrementRetraitMultipleDto interface.
 */
export function instanceOfLieuFormationAgrementRetraitMultipleDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LieuFormationAgrementRetraitMultipleDtoFromJSON(json: any): LieuFormationAgrementRetraitMultipleDto {
    return LieuFormationAgrementRetraitMultipleDtoFromJSONTyped(json, false);
}

export function LieuFormationAgrementRetraitMultipleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LieuFormationAgrementRetraitMultipleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'idslieuFormationAgrement': !exists(json, 'idslieuFormationAgrement') ? undefined : json['idslieuFormationAgrement'],
        'datePriseEffetRetrait': !exists(json, 'datePriseEffetRetrait') ? undefined : (json['datePriseEffetRetrait'] === null ? null : new Date(json['datePriseEffetRetrait'])),
        'dateRetrait': !exists(json, 'dateRetrait') ? undefined : (json['dateRetrait'] === null ? null : new Date(json['dateRetrait'])),
        'remarqueRetrait': !exists(json, 'remarqueRetrait') ? undefined : json['remarqueRetrait'],
        'retraitIsIfapme': !exists(json, 'retraitIsIfapme') ? undefined : json['retraitIsIfapme'],
        'idsMotifRetrait': !exists(json, 'idsMotifRetrait') ? undefined : json['idsMotifRetrait'],
        'disableLieuFormation': !exists(json, 'disableLieuFormation') ? undefined : json['disableLieuFormation'],
    };
}

export function LieuFormationAgrementRetraitMultipleDtoToJSON(value?: LieuFormationAgrementRetraitMultipleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idlieuFormation': value.idlieuFormation,
        'idslieuFormationAgrement': value.idslieuFormationAgrement,
        'datePriseEffetRetrait': value.datePriseEffetRetrait === undefined ? undefined : (value.datePriseEffetRetrait === null ? null : value.datePriseEffetRetrait.toISOString()),
        'dateRetrait': value.dateRetrait === undefined ? undefined : (value.dateRetrait === null ? null : value.dateRetrait.toISOString()),
        'remarqueRetrait': value.remarqueRetrait,
        'retraitIsIfapme': value.retraitIsIfapme,
        'idsMotifRetrait': value.idsMotifRetrait,
        'disableLieuFormation': value.disableLieuFormation,
    };
}

