/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DateDuringContratDto
 */
export interface DateDuringContratDto {
    /**
     * 
     * @type {Date}
     * @memberof DateDuringContratDto
     */
    dateSortie?: Date;
    /**
     * 
     * @type {number}
     * @memberof DateDuringContratDto
     */
    idtuteurLieu?: number;
}

/**
 * Check if a given object implements the DateDuringContratDto interface.
 */
export function instanceOfDateDuringContratDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DateDuringContratDtoFromJSON(json: any): DateDuringContratDto {
    return DateDuringContratDtoFromJSONTyped(json, false);
}

export function DateDuringContratDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateDuringContratDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateSortie': !exists(json, 'dateSortie') ? undefined : (new Date(json['dateSortie'])),
        'idtuteurLieu': !exists(json, 'idtuteurLieu') ? undefined : json['idtuteurLieu'],
    };
}

export function DateDuringContratDtoToJSON(value?: DateDuringContratDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateSortie': value.dateSortie === undefined ? undefined : (value.dateSortie.toISOString()),
        'idtuteurLieu': value.idtuteurLieu,
    };
}

