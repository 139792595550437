/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRechercheImpressionDto
 */
export interface FcbRechercheImpressionDto {
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheImpressionDto
     */
    idimpression?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheImpressionDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheImpressionDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheImpressionDto
     */
    actif?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheImpressionDto
     */
    rapport?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheImpressionDto
     */
    module?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheImpressionDto
     */
    acces?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheImpressionDto
     */
    centre?: string | null;
}

/**
 * Check if a given object implements the FcbRechercheImpressionDto interface.
 */
export function instanceOfFcbRechercheImpressionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRechercheImpressionDtoFromJSON(json: any): FcbRechercheImpressionDto {
    return FcbRechercheImpressionDtoFromJSONTyped(json, false);
}

export function FcbRechercheImpressionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRechercheImpressionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idimpression': !exists(json, 'idimpression') ? undefined : json['idimpression'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'rapport': !exists(json, 'rapport') ? undefined : json['rapport'],
        'module': !exists(json, 'module') ? undefined : json['module'],
        'acces': !exists(json, 'acces') ? undefined : json['acces'],
        'centre': !exists(json, 'centre') ? undefined : json['centre'],
    };
}

export function FcbRechercheImpressionDtoToJSON(value?: FcbRechercheImpressionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idimpression': value.idimpression,
        'nom': value.nom,
        'description': value.description,
        'actif': value.actif,
        'rapport': value.rapport,
        'module': value.module,
        'acces': value.acces,
        'centre': value.centre,
    };
}

