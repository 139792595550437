/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EPrintingType } from './EPrintingType';
import {
    EPrintingTypeFromJSON,
    EPrintingTypeFromJSONTyped,
    EPrintingTypeToJSON,
} from './EPrintingType';

/**
 * 
 * @export
 * @interface PrintingQueueGridDto
 */
export interface PrintingQueueGridDto {
    /**
     * 
     * @type {number}
     * @memberof PrintingQueueGridDto
     */
    idprintingQueue?: number;
    /**
     * 
     * @type {string}
     * @memberof PrintingQueueGridDto
     */
    status?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PrintingQueueGridDto
     */
    idprinting?: number;
    /**
     * 
     * @type {string}
     * @memberof PrintingQueueGridDto
     */
    nomRapport?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PrintingQueueGridDto
     */
    displayName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PrintingQueueGridDto
     */
    idfichier?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PrintingQueueGridDto
     */
    idreportLog?: number | null;
    /**
     * 
     * @type {EPrintingType}
     * @memberof PrintingQueueGridDto
     */
    printingType?: EPrintingType;
    /**
     * 
     * @type {Date}
     * @memberof PrintingQueueGridDto
     */
    creationDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PrintingQueueGridDto
     */
    hidden?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PrintingQueueGridDto
     */
    temporary?: boolean;
}

/**
 * Check if a given object implements the PrintingQueueGridDto interface.
 */
export function instanceOfPrintingQueueGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PrintingQueueGridDtoFromJSON(json: any): PrintingQueueGridDto {
    return PrintingQueueGridDtoFromJSONTyped(json, false);
}

export function PrintingQueueGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrintingQueueGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idprintingQueue': !exists(json, 'idprintingQueue') ? undefined : json['idprintingQueue'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'idprinting': !exists(json, 'idprinting') ? undefined : json['idprinting'],
        'nomRapport': !exists(json, 'nomRapport') ? undefined : json['nomRapport'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'idreportLog': !exists(json, 'idreportLog') ? undefined : json['idreportLog'],
        'printingType': !exists(json, 'printingType') ? undefined : EPrintingTypeFromJSON(json['printingType']),
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'hidden': !exists(json, 'hidden') ? undefined : json['hidden'],
        'temporary': !exists(json, 'temporary') ? undefined : json['temporary'],
    };
}

export function PrintingQueueGridDtoToJSON(value?: PrintingQueueGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idprintingQueue': value.idprintingQueue,
        'status': value.status,
        'idprinting': value.idprinting,
        'nomRapport': value.nomRapport,
        'displayName': value.displayName,
        'idfichier': value.idfichier,
        'idreportLog': value.idreportLog,
        'printingType': EPrintingTypeToJSON(value.printingType),
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'hidden': value.hidden,
        'temporary': value.temporary,
    };
}

