/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbResultatTypeBulletinSession2GridDto
 */
export interface FcbResultatTypeBulletinSession2GridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeBulletinSession2GridDto
     */
    matiere?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeBulletinSession2GridDto
     */
    idmatiere?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeBulletinSession2GridDto
     */
    codematiere?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeBulletinSession2GridDto
     */
    formateur?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeBulletinSession2GridDto
     */
    exa?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeBulletinSession2GridDto
     */
    total?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeBulletinSession2GridDto
     */
    idclasse?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeBulletinSession2GridDto
     */
    categorie?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeBulletinSession2GridDto
     */
    idbulletinInscriptionMatiere?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeBulletinSession2GridDto
     */
    idDeliberationType?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeBulletinSession2GridDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeBulletinSession2GridDto
     */
    idclasseMatiere?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeBulletinSession2GridDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeBulletinSession2GridDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeBulletinSession2GridDto
     */
    single?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeBulletinSession2GridDto
     */
    idinscription?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeBulletinSession2GridDto
     */
    idreferentiel?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeBulletinSession2GridDto
     */
    bulletinTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeBulletinSession2GridDto
     */
    reussite?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbResultatTypeBulletinSession2GridDto
     */
    dispenseTotale?: boolean;
}

/**
 * Check if a given object implements the FcbResultatTypeBulletinSession2GridDto interface.
 */
export function instanceOfFcbResultatTypeBulletinSession2GridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbResultatTypeBulletinSession2GridDtoFromJSON(json: any): FcbResultatTypeBulletinSession2GridDto {
    return FcbResultatTypeBulletinSession2GridDtoFromJSONTyped(json, false);
}

export function FcbResultatTypeBulletinSession2GridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbResultatTypeBulletinSession2GridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'matiere': !exists(json, 'matiere') ? undefined : json['matiere'],
        'idmatiere': !exists(json, 'idmatiere') ? undefined : json['idmatiere'],
        'codematiere': !exists(json, 'codematiere') ? undefined : json['codematiere'],
        'formateur': !exists(json, 'formateur') ? undefined : json['formateur'],
        'exa': !exists(json, 'exa') ? undefined : json['exa'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'categorie': !exists(json, 'categorie') ? undefined : json['categorie'],
        'idbulletinInscriptionMatiere': !exists(json, 'idbulletinInscriptionMatiere') ? undefined : json['idbulletinInscriptionMatiere'],
        'idDeliberationType': !exists(json, 'idDeliberationType') ? undefined : json['idDeliberationType'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'idclasseMatiere': !exists(json, 'idclasseMatiere') ? undefined : json['idclasseMatiere'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'single': !exists(json, 'single') ? undefined : json['single'],
        'idinscription': !exists(json, 'idinscription') ? undefined : json['idinscription'],
        'idreferentiel': !exists(json, 'idreferentiel') ? undefined : json['idreferentiel'],
        'bulletinTotal': !exists(json, 'bulletinTotal') ? undefined : json['bulletinTotal'],
        'reussite': !exists(json, 'reussite') ? undefined : json['reussite'],
        'dispenseTotale': !exists(json, 'dispenseTotale') ? undefined : json['dispenseTotale'],
    };
}

export function FcbResultatTypeBulletinSession2GridDtoToJSON(value?: FcbResultatTypeBulletinSession2GridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'matiere': value.matiere,
        'idmatiere': value.idmatiere,
        'codematiere': value.codematiere,
        'formateur': value.formateur,
        'exa': value.exa,
        'total': value.total,
        'idclasse': value.idclasse,
        'categorie': value.categorie,
        'idbulletinInscriptionMatiere': value.idbulletinInscriptionMatiere,
        'idDeliberationType': value.idDeliberationType,
        'libelle': value.libelle,
        'idclasseMatiere': value.idclasseMatiere,
        'idapprenant': value.idapprenant,
        'description': value.description,
        'single': value.single,
        'idinscription': value.idinscription,
        'idreferentiel': value.idreferentiel,
        'bulletinTotal': value.bulletinTotal,
        'reussite': value.reussite,
        'dispenseTotale': value.dispenseTotale,
    };
}

