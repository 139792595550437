import { ButtonContainer } from "nsitools-react";
import * as React from "react";

import { FcbPaiementGridDto, PaiementApi, PaiementSearch, ETextSearchType } from "../../../../../api";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { useGlobalData } from "../../../../../contexts";
import { useHistory } from "react-router";
import { ERoutes } from "../../../../../AppRouter";

interface IPaiementsSearchTableProps {
  apprenantId: number;
}

export const PaiementsSearchTable: React.FunctionComponent<IPaiementsSearchTableProps> = ({ apprenantId }) => {
  const { t } = useTl();
  const api = useApiService(PaiementApi);
  const history = useHistory();
  const { currentAnneeScolaire } = useGlobalData();

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbPaiementGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.apprenant}/${apprenantId}/finance/${row.idPaiement}/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.apprenant}/${apprenantId}/finance/${row.idPaiement}/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "anneeScolaire"
      },
      {
        header: () => t(ETLCodes.Date),
        fieldName: "datePaiement"
      },
      {
        header: () => t(ETLCodes.Mode),
        fieldName: "modePaiement"
      },
      {
        header: () => t(ETLCodes.Montant),
        fieldName: "montantPaye",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "nomCentre"
      }
    ],
    [apprenantId, history, t]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.apprenant}/${apprenantId}/finance/0/edit`);
  }, [apprenantId, history]);

  const getCriteriasFn = React.useCallback(() => api.paiementGetSearchCriterias({ includeListsValues: true }), [api]);

  const apiFactory = useAbortableApiServiceFactory(PaiementApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: PaiementSearch) => {
      sObj.idapprenant = apprenantId;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.paiementBaseSearch({ PaiementSearch: sObj });
    },
    [apiFactory, apprenantId]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    apprenantId > 0 && (
      <SearchTablePage
        columns={columns}
        getCriteriasFunction={getCriteriasFn}
        searchFunction={searchFn}
        onAbort={onAbort}
        addFunc={addItemFunction}
        sortKeys={{ anneeScolaire: "DESC" }}
        title={ETLCodes.Paiements}
        defaultCriterias={[
          {
            criteria: "AnneeScolaire",
            searchMode: ETextSearchType.Equals,
            value: currentAnneeScolaire?.idanneeScolaire
          }
        ]}
        withCard={false}
      />
    )
  );
};
