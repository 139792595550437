/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EContentHidingMode {
    NONE = 'NONE',
    OTHERS = 'OTHERS',
    ALL = 'ALL'
}


export function EContentHidingModeFromJSON(json: any): EContentHidingMode {
    return EContentHidingModeFromJSONTyped(json, false);
}

export function EContentHidingModeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EContentHidingMode {
    return json as EContentHidingMode;
}

export function EContentHidingModeToJSON(value?: EContentHidingMode | null): any {
    return value as any;
}

