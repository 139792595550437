/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RapportHopeModulePermissionDto
 */
export interface RapportHopeModulePermissionDto {
    /**
     * 
     * @type {boolean}
     * @memberof RapportHopeModulePermissionDto
     */
    selected?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RapportHopeModulePermissionDto
     */
    idmodule?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportHopeModulePermissionDto
     */
    idrapportHope?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RapportHopeModulePermissionDto
     */
    nom?: string | null;
}

/**
 * Check if a given object implements the RapportHopeModulePermissionDto interface.
 */
export function instanceOfRapportHopeModulePermissionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RapportHopeModulePermissionDtoFromJSON(json: any): RapportHopeModulePermissionDto {
    return RapportHopeModulePermissionDtoFromJSONTyped(json, false);
}

export function RapportHopeModulePermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RapportHopeModulePermissionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selected': !exists(json, 'selected') ? undefined : json['selected'],
        'idmodule': !exists(json, 'idmodule') ? undefined : json['idmodule'],
        'idrapportHope': !exists(json, 'idrapportHope') ? undefined : json['idrapportHope'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
    };
}

export function RapportHopeModulePermissionDtoToJSON(value?: RapportHopeModulePermissionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selected': value.selected,
        'idmodule': value.idmodule,
        'idrapportHope': value.idrapportHope,
        'nom': value.nom,
    };
}

