/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClasseHoraireJourColumnsDto } from './ClasseHoraireJourColumnsDto';
import {
    ClasseHoraireJourColumnsDtoFromJSON,
    ClasseHoraireJourColumnsDtoFromJSONTyped,
    ClasseHoraireJourColumnsDtoToJSON,
} from './ClasseHoraireJourColumnsDto';
import type { FcbApprenantPresenceDetailDto } from './FcbApprenantPresenceDetailDto';
import {
    FcbApprenantPresenceDetailDtoFromJSON,
    FcbApprenantPresenceDetailDtoFromJSONTyped,
    FcbApprenantPresenceDetailDtoToJSON,
} from './FcbApprenantPresenceDetailDto';
import type { FcbPresenceFormateurDto } from './FcbPresenceFormateurDto';
import {
    FcbPresenceFormateurDtoFromJSON,
    FcbPresenceFormateurDtoFromJSONTyped,
    FcbPresenceFormateurDtoToJSON,
} from './FcbPresenceFormateurDto';

/**
 * 
 * @export
 * @interface FeuillePresenceInfoDto
 */
export interface FeuillePresenceInfoDto {
    /**
     * 
     * @type {number}
     * @memberof FeuillePresenceInfoDto
     */
    idUserFormateur?: number | null;
    /**
     * 
     * @type {Array<ClasseHoraireJourColumnsDto>}
     * @memberof FeuillePresenceInfoDto
     */
    columnsInfo?: Array<ClasseHoraireJourColumnsDto> | null;
    /**
     * 
     * @type {Array<FcbApprenantPresenceDetailDto>}
     * @memberof FeuillePresenceInfoDto
     */
    apprenantPresences?: Array<FcbApprenantPresenceDetailDto> | null;
    /**
     * 
     * @type {Array<FcbPresenceFormateurDto>}
     * @memberof FeuillePresenceInfoDto
     */
    formateurPresences?: Array<FcbPresenceFormateurDto> | null;
}

/**
 * Check if a given object implements the FeuillePresenceInfoDto interface.
 */
export function instanceOfFeuillePresenceInfoDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FeuillePresenceInfoDtoFromJSON(json: any): FeuillePresenceInfoDto {
    return FeuillePresenceInfoDtoFromJSONTyped(json, false);
}

export function FeuillePresenceInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeuillePresenceInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idUserFormateur': !exists(json, 'idUserFormateur') ? undefined : json['idUserFormateur'],
        'columnsInfo': !exists(json, 'columnsInfo') ? undefined : (json['columnsInfo'] === null ? null : (json['columnsInfo'] as Array<any>).map(ClasseHoraireJourColumnsDtoFromJSON)),
        'apprenantPresences': !exists(json, 'apprenantPresences') ? undefined : (json['apprenantPresences'] === null ? null : (json['apprenantPresences'] as Array<any>).map(FcbApprenantPresenceDetailDtoFromJSON)),
        'formateurPresences': !exists(json, 'formateurPresences') ? undefined : (json['formateurPresences'] === null ? null : (json['formateurPresences'] as Array<any>).map(FcbPresenceFormateurDtoFromJSON)),
    };
}

export function FeuillePresenceInfoDtoToJSON(value?: FeuillePresenceInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idUserFormateur': value.idUserFormateur,
        'columnsInfo': value.columnsInfo === undefined ? undefined : (value.columnsInfo === null ? null : (value.columnsInfo as Array<any>).map(ClasseHoraireJourColumnsDtoToJSON)),
        'apprenantPresences': value.apprenantPresences === undefined ? undefined : (value.apprenantPresences === null ? null : (value.apprenantPresences as Array<any>).map(FcbApprenantPresenceDetailDtoToJSON)),
        'formateurPresences': value.formateurPresences === undefined ? undefined : (value.formateurPresences === null ? null : (value.formateurPresences as Array<any>).map(FcbPresenceFormateurDtoToJSON)),
    };
}

