import { Classes, Dialog } from "@blueprintjs/core";
import * as React from "react";

import { ContratApi, ContratEditDto, ELevelName, FichierApi } from "../../../../../api";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import * as Yup from "yup";
import styled from "styled-components";
import {
  FGWalterDateMaskInput,
  FGWalterFileInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../../components";
import { FGCustomPanel, FGTextAreaInput, FieldGroup } from "nsitools-react";
import { useReferential } from "../../../../../hooks/useReferential";
import { DialogResult, useDialog } from "../../../../../contexts";

const StyledDialog = styled(Dialog)`
  width: 600px;
  height: auto;
  background-color: white;
`;

export interface IContratRuptureDialogProps {
  dialogOpen: boolean;
  currentContratId: number;
  onClose: (refresh: boolean) => void;
}

export const ContratRuptureDialog: React.FunctionComponent<IContratRuptureDialogProps> = ({
  currentContratId,
  dialogOpen,
  onClose
}) => {
  const { t } = useTl();
  const api = useApiService(ContratApi);
  const { showDialogPromise } = useDialog();

  const { data, loading, saveItem, saving, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () => {
          return api.contratGetContrat({ id: currentContratId });
        },
        saveApiFn: async (d: ContratEditDto) => {
          if (!!d.rompuLe && d.idcontrat > 0) {
            var offreActive = await api.contratHasOffreActive({ idcontrat: d.idcontrat });
            if (offreActive) {
              let result: DialogResult = "yes";
              result = await showDialogPromise({
                message: t(ETLCodes.AgrementsOffreActiveConfirm),
                title: t(ETLCodes.AgrementsOffreActive)
              });

              if (result === "no") {
                d.rompreAgrementsActifs = true;
              }
            }
          }
          return api.contratRuptureContrat({ ContratEditDto: d });
        },
        onSaved: () => onClose(true)
      }),
      [api, currentContratId, onClose, showDialogPromise, t]
    )
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape({
        rompuLe: Yup.date()
          .nullable()
          .required(t(ETLCodes.Required)),
        idcontratInitiateurRupture: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        datePriseActe: Yup.date().nullable()
      }),
    [t]
  );

  const [initiateurs, iLoading] = useReferential(a => a.referentialGetContratInitiateurs());

  const [motifsPatron, mpLoading] = useReferential(a => a.referentialGetMotifsRupturePatron());

  const [motifsJeune, mjLoading] = useReferential(a => a.referentialGetMotifsRuptureJeune());

  const [users, uLoading] = useReferential(a =>
    a.referentialGetUsersByRolesAndApprenant({ ELevelName: [ELevelName.RI, ELevelName.CALC] })
  );

  const fapi = useApiService(FichierApi);
  const downloadFn = React.useCallback(async idfichier => await fapi.fichierDownload({ id: idfichier }), [fapi]);

  return (
    <StyledDialog title={t(ETLCodes.Rupture)} isOpen={dialogOpen} onClose={() => onClose(false)}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose(false)}
          showDeleteButton={false}
          saving={saving}
          loading={loading}
          validationErrors={validationErrors}
          watchChanges={{
            fichierRupture: (value, formik) => {
              if (formik.dirty) {
                formik.setFieldValue("idfichierRupture", null);
              }
            }
          }}
        >
          <FieldGroup>
            <FGWalterDateMaskInput name="rompuLe" label={t(ETLCodes.DateRupture)} />
            <FGWalterSelectInput
              name="idcontratInitiateurRupture"
              label={t(ETLCodes.Initiateur)}
              items={initiateurs}
              loading={iLoading}
            />
            <FGWalterSelectInput
              name="idcontratMotifRupturePatron"
              label={t(ETLCodes.MotifPatron)}
              items={motifsPatron}
              loading={mpLoading}
            />
            <FGWalterSelectInput
              name="idcontratMotifRuptureJeune"
              label={t(ETLCodes.MotifJeune)}
              items={motifsJeune}
              loading={mjLoading}
            />
            <FGWalterDateMaskInput name="datePriseActe" label={t(ETLCodes.DatePriseActe)} />
            <FGWalterSelectInput
              name="idcalcRiActant"
              label={t(ETLCodes.CALCRIActant)}
              items={users}
              loading={uLoading}
            />
            <FGTextAreaInput name="remarqueRupture" label={t(ETLCodes.Remarque)} />
            <FGCustomPanel>
              {ctx => (
                <FGWalterFileInput
                  name="fichierRupture"
                  label={t(ETLCodes.Document)}
                  downloadFn={
                    ctx.formik.values?.fichierRupture && (() => downloadFn(ctx.formik.values?.idfichierRupture))
                  }
                />
              )}
            </FGCustomPanel>
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
