import * as React from "react";
import { useHistory, useParams } from "react-router";

import { FormateurRemplacementApi, RemplacementSearch, FcbRemplacementGridDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { SearchTablePage, ViewButton, EditButton } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { ButtonContainer } from "nsitools-react";
import { useRouteParamsContext } from "../../../../../contexts";

export interface IFormateurRemplacementListPageProps {}

export const FormateurRemplacementListPage: React.FunctionComponent<IFormateurRemplacementListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(FormateurRemplacementApi);
  const { id } = useParams<{ id: string }>();

  const { pushCancelRoute } = useRouteParamsContext();

  const navigate = React.useCallback(
    (idremplacement: number, state: string) => {
      pushCancelRoute(`${ERoutes.formateur}/${+id}/remplacements`);
      history.push(`${ERoutes.remplacement}/${idremplacement}/general/${state}`);
    },
    [history, id, pushCancelRoute]
  );

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbRemplacementGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => navigate(row.idremplacement, "view")} />
            <EditButton minimal={true} onClick={() => navigate(row.idremplacement, "edit")} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.DateDebut),
        fieldName: "dateDebut"
      },
      {
        header: () => t(ETLCodes.DateFin),
        fieldName: "dateFin"
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      },
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "centre"
      },
      {
        header: () => t(ETLCodes.Raison),
        fieldName: "raison"
      },
      {
        header: () => t(ETLCodes.Remarque),
        fieldName: "remarque"
      }
    ],
    [navigate, t]
  );

  const getCriterias = React.useCallback(
    () => api.formateurRemplacementGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  const apiFactory = useAbortableApiServiceFactory(FormateurRemplacementApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (nextSearch?: RemplacementSearch) => {
      nextSearch.idFormateur = +id;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.formateurRemplacementBaseSearch({ RemplacementSearch: nextSearch });
    },
    [apiFactory, id]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      columns={columns}
      getCriteriasFunction={getCriterias}
      searchFunction={search}
      onAbort={onAbort}
      sortKeys={{ dateDebut: "DESC" }}
      withCard={false}
    />
  );
};
