/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EDateSearchType } from './EDateSearchType';
import {
    EDateSearchTypeFromJSON,
    EDateSearchTypeFromJSONTyped,
    EDateSearchTypeToJSON,
} from './EDateSearchType';
import type { EDateSearchTypeDateTimeNullableCombinableCriteria } from './EDateSearchTypeDateTimeNullableCombinableCriteria';
import {
    EDateSearchTypeDateTimeNullableCombinableCriteriaFromJSON,
    EDateSearchTypeDateTimeNullableCombinableCriteriaFromJSONTyped,
    EDateSearchTypeDateTimeNullableCombinableCriteriaToJSON,
} from './EDateSearchTypeDateTimeNullableCombinableCriteria';

/**
 * 
 * @export
 * @interface DateSearch
 */
export interface DateSearch {
    /**
     * 
     * @type {Array<EDateSearchTypeDateTimeNullableCombinableCriteria>}
     * @memberof DateSearch
     */
    criterias?: Array<EDateSearchTypeDateTimeNullableCombinableCriteria> | null;
    /**
     * 
     * @type {EDateSearchType}
     * @memberof DateSearch
     */
    searchMode?: EDateSearchType;
    /**
     * 
     * @type {Date}
     * @memberof DateSearch
     * @deprecated
     */
    value?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DateSearch
     * @deprecated
     */
    secondaryValue?: Date | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DateSearch
     */
    readonly availableSearchTypes?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DateSearch
     */
    defaultSearchType?: string | null;
}

/**
 * Check if a given object implements the DateSearch interface.
 */
export function instanceOfDateSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DateSearchFromJSON(json: any): DateSearch {
    return DateSearchFromJSONTyped(json, false);
}

export function DateSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'criterias': !exists(json, 'criterias') ? undefined : (json['criterias'] === null ? null : (json['criterias'] as Array<any>).map(EDateSearchTypeDateTimeNullableCombinableCriteriaFromJSON)),
        'searchMode': !exists(json, 'searchMode') ? undefined : EDateSearchTypeFromJSON(json['searchMode']),
        'value': !exists(json, 'value') ? undefined : (json['value'] === null ? null : new Date(json['value'])),
        'secondaryValue': !exists(json, 'secondaryValue') ? undefined : (json['secondaryValue'] === null ? null : new Date(json['secondaryValue'])),
        'availableSearchTypes': !exists(json, 'availableSearchTypes') ? undefined : json['availableSearchTypes'],
        'defaultSearchType': !exists(json, 'defaultSearchType') ? undefined : json['defaultSearchType'],
    };
}

export function DateSearchToJSON(value?: DateSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'criterias': value.criterias === undefined ? undefined : (value.criterias === null ? null : (value.criterias as Array<any>).map(EDateSearchTypeDateTimeNullableCombinableCriteriaToJSON)),
        'searchMode': EDateSearchTypeToJSON(value.searchMode),
        'value': value.value === undefined ? undefined : (value.value === null ? null : value.value.toISOString()),
        'secondaryValue': value.secondaryValue === undefined ? undefined : (value.secondaryValue === null ? null : value.secondaryValue.toISOString()),
        'defaultSearchType': value.defaultSearchType,
    };
}

