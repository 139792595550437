/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbExportAccesPermissionDto
 */
export interface FcbExportAccesPermissionDto {
    /**
     * 
     * @type {boolean}
     * @memberof FcbExportAccesPermissionDto
     */
    selected?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbExportAccesPermissionDto
     */
    idexport?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbExportAccesPermissionDto
     */
    idlevel?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbExportAccesPermissionDto
     */
    description?: string | null;
}

/**
 * Check if a given object implements the FcbExportAccesPermissionDto interface.
 */
export function instanceOfFcbExportAccesPermissionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbExportAccesPermissionDtoFromJSON(json: any): FcbExportAccesPermissionDto {
    return FcbExportAccesPermissionDtoFromJSONTyped(json, false);
}

export function FcbExportAccesPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbExportAccesPermissionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selected': !exists(json, 'selected') ? undefined : json['selected'],
        'idexport': !exists(json, 'idexport') ? undefined : json['idexport'],
        'idlevel': !exists(json, 'idlevel') ? undefined : json['idlevel'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function FcbExportAccesPermissionDtoToJSON(value?: FcbExportAccesPermissionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selected': value.selected,
        'idexport': value.idexport,
        'idlevel': value.idlevel,
        'description': value.description,
    };
}

