import { ButtonContainer, IDataTableColumn } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";

import {
  FcbFeuillePresenceGridDto,
  FeuillePresenceApi,
  FeuillePresenceSearch,
  ETextSearchType,
  EBooleanSearchTypes
} from "../../../../api";
import { EditButton, PrintButton, SearchTablePage, ViewButton } from "../../../../components";
import { useGlobalData } from "../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { ERoutes } from "../../../../AppRouter";
import { FeuillePresencePrintDialog } from "./FeuillePresencePrintDialog";

export interface IFeuillePresenceListPageProps {}

export const FeuillePresenceListPage: React.FunctionComponent<IFeuillePresenceListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(FeuillePresenceApi);
  const { currentAnneeScolaire } = useGlobalData();
  const [printDialogOpen, setPrintDialogOpen] = React.useState(false);
  const [currentIdClasse, setCurrentIdClasse] = React.useState(null);

  const openPrintPopup = React.useCallback((idClasse: number) => {
    setPrintDialogOpen(true);
    setCurrentIdClasse(idClasse);
  }, []);

  const onDialogClose = React.useCallback(() => {
    setPrintDialogOpen(false);
    setCurrentIdClasse(null);
  }, []);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbFeuillePresenceGridDto) => (
          <ButtonContainer>
            <PrintButton onClick={() => openPrintPopup(row.idclasse)} />
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.feuillePresence}/${row.idclasse}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.feuillePresence}/${row.idclasse}/detail/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "anneeAcademique",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Metier),
        fieldName: "masculin"
      },
      {
        header: () => t(ETLCodes.CodeClasse),
        fieldName: "codeClasse"
      }
    ],
    [history, openPrintPopup, t]
  );

  const getCriterias = React.useCallback(() => api.feuillePresenceGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const apiFactory = useAbortableApiServiceFactory(FeuillePresenceApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (nextSearch?: FeuillePresenceSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.feuillePresenceBaseSearch({ FeuillePresenceSearch: nextSearch });
    },
    [apiFactory]
  );

  const defaultCriteria = React.useMemo(
    () => [
      {
        criteria: "AnneeAcademique",
        searchMode: ETextSearchType.Contains,
        value: currentAnneeScolaire?.idanneeScolaire
      },
      {
        criteria: "WithHoraire",
        searchMode: EBooleanSearchTypes.Equals,
        value: true
      }
    ],
    [currentAnneeScolaire]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <SearchTablePage
        columns={columns}
        getCriteriasFunction={getCriterias}
        searchFunction={search}
        onAbort={onAbort}
        defaultCriterias={defaultCriteria}
        breadCrumbs={[{ text: t(ETLCodes.FeuillesPresence) }]}
        sortKeys={{ anneeAcademique: "ASC" }}
        searchStateInitialSearch={false}
      />
      {currentIdClasse && (
        <FeuillePresencePrintDialog idClasse={currentIdClasse} shown={printDialogOpen} onClose={onDialogClose} />
      )}
    </>
  );
};
