/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { TextSearch } from './TextSearch';
import {
    TextSearchFromJSON,
    TextSearchFromJSONTyped,
    TextSearchToJSON,
} from './TextSearch';

/**
 * 
 * @export
 * @interface PlanAgrementClasseSearch
 */
export interface PlanAgrementClasseSearch {
    /**
     * 
     * @type {number}
     * @memberof PlanAgrementClasseSearch
     */
    idPlanAgrement?: number;
    /**
     * 
     * @type {TextSearch}
     * @memberof PlanAgrementClasseSearch
     */
    codeMetier?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof PlanAgrementClasseSearch
     */
    codeConseillerAgrement?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof PlanAgrementClasseSearch
     */
    nom?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof PlanAgrementClasseSearch
     */
    gestionInterneAgrement?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof PlanAgrementClasseSearch
     */
    idDegre?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof PlanAgrementClasseSearch
     */
    statutLocalisationAgrement?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof PlanAgrementClasseSearch
     */
    typeCours?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof PlanAgrementClasseSearch
     */
    specificite?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof PlanAgrementClasseSearch
     */
    genre?: TextSearch;
    /**
     * 
     * @type {Array<string>}
     * @memberof PlanAgrementClasseSearch
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof PlanAgrementClasseSearch
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof PlanAgrementClasseSearch
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PlanAgrementClasseSearch
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof PlanAgrementClasseSearch
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof PlanAgrementClasseSearch
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the PlanAgrementClasseSearch interface.
 */
export function instanceOfPlanAgrementClasseSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PlanAgrementClasseSearchFromJSON(json: any): PlanAgrementClasseSearch {
    return PlanAgrementClasseSearchFromJSONTyped(json, false);
}

export function PlanAgrementClasseSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanAgrementClasseSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idPlanAgrement': !exists(json, 'idPlanAgrement') ? undefined : json['idPlanAgrement'],
        'codeMetier': !exists(json, 'codeMetier') ? undefined : TextSearchFromJSON(json['codeMetier']),
        'codeConseillerAgrement': !exists(json, 'codeConseillerAgrement') ? undefined : TextSearchFromJSON(json['codeConseillerAgrement']),
        'nom': !exists(json, 'nom') ? undefined : TextSearchFromJSON(json['nom']),
        'gestionInterneAgrement': !exists(json, 'gestionInterneAgrement') ? undefined : TextSearchFromJSON(json['gestionInterneAgrement']),
        'idDegre': !exists(json, 'idDegre') ? undefined : TextSearchFromJSON(json['idDegre']),
        'statutLocalisationAgrement': !exists(json, 'statutLocalisationAgrement') ? undefined : TextSearchFromJSON(json['statutLocalisationAgrement']),
        'typeCours': !exists(json, 'typeCours') ? undefined : TextSearchFromJSON(json['typeCours']),
        'specificite': !exists(json, 'specificite') ? undefined : TextSearchFromJSON(json['specificite']),
        'genre': !exists(json, 'genre') ? undefined : TextSearchFromJSON(json['genre']),
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function PlanAgrementClasseSearchToJSON(value?: PlanAgrementClasseSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idPlanAgrement': value.idPlanAgrement,
        'codeMetier': TextSearchToJSON(value.codeMetier),
        'codeConseillerAgrement': TextSearchToJSON(value.codeConseillerAgrement),
        'nom': TextSearchToJSON(value.nom),
        'gestionInterneAgrement': TextSearchToJSON(value.gestionInterneAgrement),
        'idDegre': TextSearchToJSON(value.idDegre),
        'statutLocalisationAgrement': TextSearchToJSON(value.statutLocalisationAgrement),
        'typeCours': TextSearchToJSON(value.typeCours),
        'specificite': TextSearchToJSON(value.specificite),
        'genre': TextSearchToJSON(value.genre),
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

