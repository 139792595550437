/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileUploadDto } from './FileUploadDto';
import {
    FileUploadDtoFromJSON,
    FileUploadDtoFromJSONTyped,
    FileUploadDtoToJSON,
} from './FileUploadDto';

/**
 * 
 * @export
 * @interface LieuFormationAgrementVisiteDto
 */
export interface LieuFormationAgrementVisiteDto {
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementVisiteDto
     */
    idlieuFormationAgrementVisite?: number;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementVisiteDto
     */
    idlieuFormationAgrement?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementVisiteDto
     */
    dateVisite?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationAgrementVisiteDto
     */
    pratiqueSimulee?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationAgrementVisiteDto
     */
    compteRendu?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementVisiteDto
     */
    idfichierCompteRendu?: number | null;
    /**
     * 
     * @type {FileUploadDto}
     * @memberof LieuFormationAgrementVisiteDto
     */
    fichierCompteRendu?: FileUploadDto;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementVisiteDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementVisiteDto
     */
    creationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementVisiteDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementVisiteDto
     */
    modificationDate?: Date | null;
}

/**
 * Check if a given object implements the LieuFormationAgrementVisiteDto interface.
 */
export function instanceOfLieuFormationAgrementVisiteDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LieuFormationAgrementVisiteDtoFromJSON(json: any): LieuFormationAgrementVisiteDto {
    return LieuFormationAgrementVisiteDtoFromJSONTyped(json, false);
}

export function LieuFormationAgrementVisiteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LieuFormationAgrementVisiteDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idlieuFormationAgrementVisite': !exists(json, 'idlieuFormationAgrementVisite') ? undefined : json['idlieuFormationAgrementVisite'],
        'idlieuFormationAgrement': !exists(json, 'idlieuFormationAgrement') ? undefined : json['idlieuFormationAgrement'],
        'dateVisite': !exists(json, 'dateVisite') ? undefined : (json['dateVisite'] === null ? null : new Date(json['dateVisite'])),
        'pratiqueSimulee': !exists(json, 'pratiqueSimulee') ? undefined : json['pratiqueSimulee'],
        'compteRendu': !exists(json, 'compteRendu') ? undefined : json['compteRendu'],
        'idfichierCompteRendu': !exists(json, 'idfichierCompteRendu') ? undefined : json['idfichierCompteRendu'],
        'fichierCompteRendu': !exists(json, 'fichierCompteRendu') ? undefined : FileUploadDtoFromJSON(json['fichierCompteRendu']),
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
    };
}

export function LieuFormationAgrementVisiteDtoToJSON(value?: LieuFormationAgrementVisiteDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idlieuFormationAgrementVisite': value.idlieuFormationAgrementVisite,
        'idlieuFormationAgrement': value.idlieuFormationAgrement,
        'dateVisite': value.dateVisite === undefined ? undefined : (value.dateVisite === null ? null : value.dateVisite.toISOString()),
        'pratiqueSimulee': value.pratiqueSimulee,
        'compteRendu': value.compteRendu,
        'idfichierCompteRendu': value.idfichierCompteRendu,
        'fichierCompteRendu': FileUploadDtoToJSON(value.fichierCompteRendu),
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
    };
}

