import { Intent } from "@blueprintjs/core";
import { DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import React from "react";
import { useHistory } from "react-router";

import { VisiteFormateurSearch } from "../../../../../api";
import { VisiteFormateurApi } from "../../../../../api/apis/VisiteFormateurApi";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IVisiteFormateurLieListPageProps {
  idVisite: number;
}

export const VisiteFormateurLieListPage: React.FunctionComponent<IVisiteFormateurLieListPageProps> = ({ idVisite }) => {
  const { t } = useTl();
  const api = useApiService(VisiteFormateurApi);
  const history = useHistory();

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { formateur: "ASC" }
  });
  const { totalCount } = tableState;
  const searchFunction = React.useCallback(
    (sObj?: VisiteFormateurSearch) => {
      sObj.idVisite = idVisite;
      return api.visiteFormateurBaseSearch({ VisiteFormateurSearch: sObj });
    },
    [api, idVisite]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.Formateur),
        fieldName: "formateur"
      }
    ],
    [t]
  );

  return (
    <>
      <FieldSet
        title={t(ETLCodes.TableResults, { count: totalCount })}
        rightElement={
          <EditButton
            onClick={e => {
              e.stopPropagation();
              history.push(`${ERoutes.visiteExterieure}/${idVisite}/formateurLie/selector`);
            }}
            text={t(ETLCodes.Modify)}
            intent={Intent.PRIMARY}
          />
        }
      >
        <DataTable
          dateFormat="dd/MM/yyyy"
          tableState={tableState}
          loading={loading}
          columns={columns}
          filterMode="OnEnter"
        ></DataTable>
      </FieldSet>
    </>
  );
};
