import { IDataTableColumn, ButtonContainer } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";

import {
  RechercheClasseHoraireRemplacementDefinitiveSearch,
  FcbRechercheClasseHoraireRemplacementDefinitiveDto
} from "../../../../../api";
import { RemplacementDefinitiveApi } from "../../../../../api/apis";
import { SearchTablePage, EditButton } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { RemplacementDefinitiveDetailPage } from "./RemplacementDefinitiveDetailPage";
import { IRemplacement } from "./RemplacementInterfaces";
import { useEventsContext } from "../../../../../contexts/EventsContext";

export interface IRemplacementDefinitiveTablePageProps {}

export const RemplacementDefinitiveTablePage: React.FunctionComponent<IRemplacementDefinitiveTablePageProps> = () => {
  const { id } = useParams<{ id: string; tab: string; state: string }>();
  const { t } = useTl();
  const api = useApiService(RemplacementDefinitiveApi);

  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [modalData, setModalData] = React.useState<IRemplacement>({});

  const editClick = React.useCallback((row: FcbRechercheClasseHoraireRemplacementDefinitiveDto) => {
    setModalData({
      idremplacant: row.idRemplacant,
      idformateurLocalisationContrat: row.idFormateurLocalisationContrat,
      idremplacement: row.idRemplacement,
      idclasseMatiere: row.idClasseMatiere
    });
    setModalVisible(true);
  }, []);

  const columns = React.useMemo<Array<IDataTableColumn>>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbRechercheClasseHoraireRemplacementDefinitiveDto) => (
          <>
            <ButtonContainer>
              <EditButton minimal={true} onClick={() => editClick(row)} />
            </ButtonContainer>
          </>
        )
      },
      {
        header: () => t(ETLCodes.Classe),
        fieldName: "classe"
      },
      {
        header: () => t(ETLCodes.Matiere),
        fieldName: "description"
      },
      {
        header: () => t(ETLCodes.Remplacant),
        fieldName: "remplacant"
      },
      {
        header: () => t(ETLCodes.Contrat),
        fieldName: "contrat"
      }
    ],
    [editClick, t]
  );

  const apiFactory = useAbortableApiServiceFactory(RemplacementDefinitiveApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFnCB = React.useCallback(
    (sObj?: RechercheClasseHoraireRemplacementDefinitiveSearch) => {
      sObj.idRemplacement = +id;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.remplacementDefinitiveBaseSearch({
        RechercheClasseHoraireRemplacementDefinitiveSearch: sObj
      });
    },
    [apiFactory, id]
  );

  const criteriaFnCB = React.useCallback(
    () => api.remplacementDefinitiveGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  const { dispatchEvent } = useEventsContext();

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <SearchTablePage
        getCriteriasFunction={criteriaFnCB}
        searchFunction={searchFnCB}
        onAbort={onAbort}
        sortKeys={{ classe: "ASC" }}
        columns={columns}
        withCard={false}
      />
      {modalVisible ? (
        <RemplacementDefinitiveDetailPage
          isOpen={modalVisible}
          onSave={() => {
            setModalData({});
            setModalVisible(false);
            dispatchEvent("SEARCH_TABLE_REFRESH");
          }}
          onCancel={() => {
            setModalData({});
            setModalVisible(false);
          }}
          data={modalData}
        />
      ) : null}
    </>
  );
};
