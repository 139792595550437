/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ELevelName {
    RI = 'RI',
    ARI = 'ARI',
    COP = 'COP',
    CALC = 'CALC'
}


export function ELevelNameFromJSON(json: any): ELevelName {
    return ELevelNameFromJSONTyped(json, false);
}

export function ELevelNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): ELevelName {
    return json as ELevelName;
}

export function ELevelNameToJSON(value?: ELevelName | null): any {
    return value as any;
}

