import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";

import { RemplacementApi } from "../../../../../api";
import { useApiService } from "../../../../../hooks";

import { RemplacementDefinitiveTablePage } from "./RemplacementDefinitiveTablePage";
import { RemplacementOccasionnelTablePage } from "./RemplacementOccasionnelTablePage";
import { RemplacementSimultaneTablePage } from "./RemplacementSimultaneTablePage";

export interface IRemplacementSwitchProps {}

export const RemplacementSwitch: React.FunctionComponent<IRemplacementSwitchProps> = () => {
  const { id } = useParams<{ id: string; tab: string; state: string }>();
  const api = useApiService(RemplacementApi);

  const [data] = useApiEffect(() => api.remplacementGet({ id: +id }), [id]);

  const Remplacement = React.useCallback(() => {
    if (data) {
      if (data.idtypeRemplacement === 1) {
        return <RemplacementDefinitiveTablePage />;
      } else if (data.idtypeRemplacement === 2) {
        return <RemplacementOccasionnelTablePage />;
      } else if (data.idtypeRemplacement === 3) {
        return <RemplacementSimultaneTablePage />;
      }
    }
    return <></>;
  }, [data]);

  return <Remplacement />;
};
