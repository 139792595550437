import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { FcbSmsUtilitaireDetailDtoFromJSON, SmsUtilitaireApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { SmsDetailPage } from "./SmsDetailPage";
import { SmsSuiviPage } from "./SmsSuiviPage";

export interface ISmsItemPageProps {}

export const SmsItemPage: React.FunctionComponent<ISmsItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const api = useApiService(SmsUtilitaireApi);

  const [displayName] = useApiEffect(() => api.smsUtilitaireGetDisplayName({ id: +id }), [id]);
  const [sms] = useApiEffect(async () => {
    return +id <= 0
      ? FcbSmsUtilitaireDetailDtoFromJSON({ idLot: 0, idetat: 1 })
      : await api.smsUtilitaireGet({ id: +id });
  });

  return (
    <PageBase
      breadCrumbs={[{ text: t(ETLCodes.SMS), route: ERoutes.sms }, { text: +id <= 0 ? t(ETLCodes.New) : displayName }]}
    >
      <ProtectedTabs
        id="TabsSmsUtilitaire"
        onChange={newTabId => history.push(`${ERoutes.sms}/${id}/${newTabId}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.sms} />
        <Tab id="detail" title={t(ETLCodes.Detail)} panel={<SmsDetailPage idLot={+id} editMode={state === "edit"} />} />
        <Tab
          id="suivi"
          title={t(ETLCodes.Suivi)}
          panel={<SmsSuiviPage idLot={+id} />}
          disabled={sms?.idtype === 1 || +id <= 0}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
