import * as React from "react";

import { ClasseCoherenceHoraireSearch } from "./ClasseCoherenceHoraireSearch";
import { ClasseCoherenceHoraireSemestreSearch } from "./ClasseCoherenceHoraireSemestreSearch";
import { ClasseIncoherencesSearch } from "./ClasseIncoherencesSearch";
import { Divider } from "@blueprintjs/core";

export interface IClasseCoherenceHorairePageProps {
  classeId: number;
}

export const ClasseCoherenceHorairePage: React.FunctionComponent<IClasseCoherenceHorairePageProps> = ({ classeId }) => {
  return (
    <>
      <ClasseCoherenceHoraireSearch classeId={classeId} />
      <Divider />
      <ClasseCoherenceHoraireSemestreSearch classeId={classeId} />
      <Divider />
      <ClasseIncoherencesSearch classeId={classeId} />
    </>
  );
};
