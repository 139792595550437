/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EBooleanSearchTypes {
    Equals = 'Equals',
    NotEquals = 'NotEquals'
}


export function EBooleanSearchTypesFromJSON(json: any): EBooleanSearchTypes {
    return EBooleanSearchTypesFromJSONTyped(json, false);
}

export function EBooleanSearchTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): EBooleanSearchTypes {
    return json as EBooleanSearchTypes;
}

export function EBooleanSearchTypesToJSON(value?: EBooleanSearchTypes | null): any {
    return value as any;
}

