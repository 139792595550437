/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbBulletinApprenantRecapitulatifGridDto
 */
export interface FcbBulletinApprenantRecapitulatifGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantRecapitulatifGridDto
     */
    idbulletinApprenant?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantRecapitulatifGridDto
     */
    grade?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantRecapitulatifGridDto
     */
    idgrade?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantRecapitulatifGridDto
     */
    idmetier?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantRecapitulatifGridDto
     */
    points?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantRecapitulatifGridDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantRecapitulatifGridDto
     */
    degre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantRecapitulatifGridDto
     */
    metier?: string | null;
    /**
     * 
     * @type {Array<any>}
     * @memberof FcbBulletinApprenantRecapitulatifGridDto
     */
    isVisibleBulletin?: Array<any> | null;
}

/**
 * Check if a given object implements the FcbBulletinApprenantRecapitulatifGridDto interface.
 */
export function instanceOfFcbBulletinApprenantRecapitulatifGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbBulletinApprenantRecapitulatifGridDtoFromJSON(json: any): FcbBulletinApprenantRecapitulatifGridDto {
    return FcbBulletinApprenantRecapitulatifGridDtoFromJSONTyped(json, false);
}

export function FcbBulletinApprenantRecapitulatifGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbBulletinApprenantRecapitulatifGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idbulletinApprenant': !exists(json, 'idbulletinApprenant') ? undefined : json['idbulletinApprenant'],
        'grade': !exists(json, 'grade') ? undefined : json['grade'],
        'idgrade': !exists(json, 'idgrade') ? undefined : json['idgrade'],
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'points': !exists(json, 'points') ? undefined : json['points'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'degre': !exists(json, 'degre') ? undefined : json['degre'],
        'metier': !exists(json, 'metier') ? undefined : json['metier'],
        'isVisibleBulletin': !exists(json, 'isVisibleBulletin') ? undefined : json['isVisibleBulletin'],
    };
}

export function FcbBulletinApprenantRecapitulatifGridDtoToJSON(value?: FcbBulletinApprenantRecapitulatifGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idbulletinApprenant': value.idbulletinApprenant,
        'grade': value.grade,
        'idgrade': value.idgrade,
        'idmetier': value.idmetier,
        'points': value.points,
        'anneeScolaire': value.anneeScolaire,
        'degre': value.degre,
        'metier': value.metier,
        'isVisibleBulletin': value.isVisibleBulletin,
    };
}

