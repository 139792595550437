import { Icon } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { ApprenantApi, StatutApprenantApi, StatutApprenantGridDto, StatutApprenantSearch } from "../../../../../api";
import { AddButton } from "../../../../../components";
import { useEventsContext } from "../../../../../contexts";
import { useApiService, useManageError, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export interface IApprenantStatutCreaListProps {}

export const ApprenantStatutCreaList: React.FunctionComponent<IApprenantStatutCreaListProps> = props => {
  const { t } = useTl();
  const { manageError } = useManageError();
  const { dispatchEvent } = useEventsContext();
  const { id } = useParams<{ id: string }>();
  const apprenantApi = useApiService(ApprenantApi);
  const apprenantId = React.useMemo(() => +id, [id]);
  const [setAsMainLoading, setSetAsMainLoading] = React.useState(false);
  const api = useApiService(StatutApprenantApi);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { dateStatut: "DESC", creationDate: "DESC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: StatutApprenantSearch) => {
      sObj.idApprenant = apprenantId;
      sObj.fromCrea = true;
      return api.statutApprenantBaseSearch({ StatutApprenantSearch: sObj });
    },
    [api, apprenantId]
  );

  const { loading, search } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const fetchIsProspect = React.useCallback(async () => await apprenantApi.apprenantIsProspect({ id: apprenantId }), [
    apprenantApi,
    apprenantId
  ]);
  const { data: isProspect, isFetching: isLoadingIsProspect } = useQuery(
    ["apprenant-is-prospect", apprenantId],
    fetchIsProspect
  );

  const fetchHasParcours = React.useCallback(
    async () => await apprenantApi.apprenantHasParcoursFormation({ id: apprenantId }),
    [apprenantApi, apprenantId]
  );
  const { data: hasParcours, isFetching: isLoadingHasParcours } = useQuery(
    ["apprenant-has-parcours", apprenantId],
    fetchHasParcours
  );

  const setAsMain = React.useCallback(
    async (idstatutApprenant: number) => {
      try {
        setSetAsMainLoading(true);
        await api.statutApprenantSetAsMain({ idstatutApprenant, idapprenant: apprenantId });
        search();
        dispatchEvent("SEARCH_TABLE_REFRESH");
      } catch (e) {
        manageError(e);
      } finally {
        setSetAsMainLoading(false);
      }
    },
    [api, apprenantId, dispatchEvent, manageError, search]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: StatutApprenantGridDto) =>
          !isProspect?.value &&
          !!hasParcours?.value &&
          row.canUseCreaStatut && (
            <ButtonsContainer>
              {row.currentStatutFromCrea ? (
                <Tooltip2 content={t(ETLCodes.StatutPrincipal)}>
                  <Icon icon="tick" intent="success" />
                </Tooltip2>
              ) : (
                <AddButton
                  tooltipText={t(ETLCodes.UtiliserCeStatutCommePrincipal)}
                  minimal={true}
                  onClick={() => setAsMain(row.idStatutApprenant)}
                  loading={isLoadingIsProspect || isLoadingHasParcours || setAsMainLoading}
                />
              )}
            </ButtonsContainer>
          )
      },
      {
        header: () => t(ETLCodes.Date),
        fieldName: "dateStatut"
      },
      {
        header: () => t(ETLCodes.Statut),
        fieldName: "categorieStatutSocial"
      },
      {
        header: () => t(ETLCodes.SousStatut),
        fieldName: "statutSocial"
      },
      {
        header: () => t(ETLCodes.DureeInoccupation),
        fieldName: "dureeInoccupation"
      },
      {
        header: () => t(ETLCodes.StatutCrea),
        fieldName: "statutCrea"
      },
      {
        header: () => t(ETLCodes.StatutSpecifique),
        fieldName: "categorieSpecifiqueCrea"
      },
      {
        header: () => t(ETLCodes.Par),
        fieldName: "creationUser"
      }
    ],
    [isProspect?.value, hasParcours?.value, t, isLoadingIsProspect, isLoadingHasParcours, setAsMainLoading, setAsMain]
  );

  return (
    <FieldSet title={t(ETLCodes.HistoriqueChangementCrea) + ": " + t(ETLCodes.TableResults, { count: totalCount })}>
      <DataTable loading={loading} dateFormat="dd/MM/yyyy" tableState={tableState} columns={columns} />
    </FieldSet>
  );
};

export default ApprenantStatutCreaList;
