/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  ChefEntrepriseSiegeEditDto,
  ChefsEntrepriseSiegeDoublonGridDtoPaginatedResults,
  ChefsEntrepriseSiegeDoublonSearchDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
  SiegeSocialAdresseDto,
  SiegeSocialChefEntrepriseGridDtoPaginatedResults,
  SiegeSocialChefEntrepriseSearchDto,
  SiegeSocialEditDto,
  SiegeSocialGridDtoPaginatedResults,
  SiegeSocialHistoryGridDtoPaginatedResults,
  SiegeSocialHistorySearch,
  SiegeSocialSearchDto,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    ChefEntrepriseSiegeEditDtoFromJSON,
    ChefEntrepriseSiegeEditDtoToJSON,
    ChefsEntrepriseSiegeDoublonGridDtoPaginatedResultsFromJSON,
    ChefsEntrepriseSiegeDoublonGridDtoPaginatedResultsToJSON,
    ChefsEntrepriseSiegeDoublonSearchDtoFromJSON,
    ChefsEntrepriseSiegeDoublonSearchDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
    SiegeSocialAdresseDtoFromJSON,
    SiegeSocialAdresseDtoToJSON,
    SiegeSocialChefEntrepriseGridDtoPaginatedResultsFromJSON,
    SiegeSocialChefEntrepriseGridDtoPaginatedResultsToJSON,
    SiegeSocialChefEntrepriseSearchDtoFromJSON,
    SiegeSocialChefEntrepriseSearchDtoToJSON,
    SiegeSocialEditDtoFromJSON,
    SiegeSocialEditDtoToJSON,
    SiegeSocialGridDtoPaginatedResultsFromJSON,
    SiegeSocialGridDtoPaginatedResultsToJSON,
    SiegeSocialHistoryGridDtoPaginatedResultsFromJSON,
    SiegeSocialHistoryGridDtoPaginatedResultsToJSON,
    SiegeSocialHistorySearchFromJSON,
    SiegeSocialHistorySearchToJSON,
    SiegeSocialSearchDtoFromJSON,
    SiegeSocialSearchDtoToJSON,
} from '../models/index';

export interface SiegeSocialBaseSearchRequest {
    SiegeSocialSearchDto?: SiegeSocialSearchDto;
}

export interface SiegeSocialDeleteChefEntrepriseSiegeRequest {
    IdchefEntrepriseSiege?: number;
}

export interface SiegeSocialGetAdresseDataRequest {
    idsiegeSocial?: number;
}

export interface SiegeSocialGetChefEntrepriseSiegeRequest {
    IdchefEntrepriseSiege?: number;
}

export interface SiegeSocialGetChefsEntrepriseCountRequest {
    idsiegeSocial?: number;
}

export interface SiegeSocialGetDisplayNameRequest {
    id?: number;
}

export interface SiegeSocialGetEmailsRequest {
    request_body?: Array<number>;
}

export interface SiegeSocialGetLieuxFormationEmailsRequest {
    request_body?: Array<number>;
}

export interface SiegeSocialGetMainChefsEntrepriseIdRequest {
    idsiegeSocial?: number;
}

export interface SiegeSocialGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface SiegeSocialGetSelectItemsRequest {
    searchField: string;
}

export interface SiegeSocialGetSelectedChefEntrepriseRequest {
    IdchefEntreprise?: number;
    idsiegeSocial?: number;
}

export interface SiegeSocialGetSiegeSocialRequest {
    id?: number;
}

export interface SiegeSocialIsActifRequest {
    id?: number;
}

export interface SiegeSocialIsBceAlreadyExistRequest {
    bce?: string;
    idsiegeSocial?: number;
}

export interface SiegeSocialSaveChefEntrepriseSiegeRequest {
    ChefEntrepriseSiegeEditDto?: ChefEntrepriseSiegeEditDto;
}

export interface SiegeSocialSaveSiegeSocialRequest {
    SiegeSocialEditDto?: SiegeSocialEditDto;
}

export interface SiegeSocialSearchChefsEntrepriseRequest {
    SiegeSocialChefEntrepriseSearchDto?: SiegeSocialChefEntrepriseSearchDto;
}

export interface SiegeSocialSearchDoublonsChefsEntrepriseSiegeRequest {
    ChefsEntrepriseSiegeDoublonSearchDto?: ChefsEntrepriseSiegeDoublonSearchDto;
}

export interface SiegeSocialSearchHistoryRequest {
    SiegeSocialHistorySearch?: SiegeSocialHistorySearch;
}

export interface SiegeSocialSearchSiegeSocialIdsRequest {
    SiegeSocialSearchDto?: SiegeSocialSearchDto;
}

/**
 * 
 */
export class SiegeSocialApi extends runtime.BaseAPI {

    /**
     */
    async siegeSocialBaseSearchRaw(requestParameters: SiegeSocialBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SiegeSocialGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocial/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SiegeSocialSearchDtoToJSON(requestParameters.SiegeSocialSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiegeSocialGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async siegeSocialBaseSearch(requestParameters: SiegeSocialBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SiegeSocialGridDtoPaginatedResults> {
        const response = await this.siegeSocialBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialDeleteChefEntrepriseSiegeRaw(requestParameters: SiegeSocialDeleteChefEntrepriseSiegeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.IdchefEntrepriseSiege !== undefined) {
            queryParameters['IdchefEntrepriseSiege'] = requestParameters.IdchefEntrepriseSiege;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocial/DeleteChefEntrepriseSiege`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async siegeSocialDeleteChefEntrepriseSiege(requestParameters: SiegeSocialDeleteChefEntrepriseSiegeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.siegeSocialDeleteChefEntrepriseSiegeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async siegeSocialGetAdresseDataRaw(requestParameters: SiegeSocialGetAdresseDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SiegeSocialAdresseDto>> {
        const queryParameters: any = {};

        if (requestParameters.idsiegeSocial !== undefined) {
            queryParameters['idsiegeSocial'] = requestParameters.idsiegeSocial;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocial/GetAdresseData`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiegeSocialAdresseDtoFromJSON(jsonValue));
    }

    /**
     */
    async siegeSocialGetAdresseData(requestParameters: SiegeSocialGetAdresseDataRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SiegeSocialAdresseDto> {
        const response = await this.siegeSocialGetAdresseDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialGetChefEntrepriseSiegeRaw(requestParameters: SiegeSocialGetChefEntrepriseSiegeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChefEntrepriseSiegeEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.IdchefEntrepriseSiege !== undefined) {
            queryParameters['IdchefEntrepriseSiege'] = requestParameters.IdchefEntrepriseSiege;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocial/GetChefEntrepriseSiege`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChefEntrepriseSiegeEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async siegeSocialGetChefEntrepriseSiege(requestParameters: SiegeSocialGetChefEntrepriseSiegeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChefEntrepriseSiegeEditDto> {
        const response = await this.siegeSocialGetChefEntrepriseSiegeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialGetChefsEntrepriseCountRaw(requestParameters: SiegeSocialGetChefsEntrepriseCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idsiegeSocial !== undefined) {
            queryParameters['idsiegeSocial'] = requestParameters.idsiegeSocial;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocial/GetChefsEntrepriseCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async siegeSocialGetChefsEntrepriseCount(requestParameters: SiegeSocialGetChefsEntrepriseCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.siegeSocialGetChefsEntrepriseCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialGetDisplayNameRaw(requestParameters: SiegeSocialGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocial/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async siegeSocialGetDisplayName(requestParameters: SiegeSocialGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.siegeSocialGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialGetEmailsRaw(requestParameters: SiegeSocialGetEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocial/GetEmails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request_body,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async siegeSocialGetEmails(requestParameters: SiegeSocialGetEmailsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.siegeSocialGetEmailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialGetLieuxFormationEmailsRaw(requestParameters: SiegeSocialGetLieuxFormationEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocial/GetLieuxFormationEmails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request_body,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async siegeSocialGetLieuxFormationEmails(requestParameters: SiegeSocialGetLieuxFormationEmailsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.siegeSocialGetLieuxFormationEmailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialGetMainChefsEntrepriseIdRaw(requestParameters: SiegeSocialGetMainChefsEntrepriseIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idsiegeSocial !== undefined) {
            queryParameters['idsiegeSocial'] = requestParameters.idsiegeSocial;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocial/GetMainChefsEntrepriseId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async siegeSocialGetMainChefsEntrepriseId(requestParameters: SiegeSocialGetMainChefsEntrepriseIdRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.siegeSocialGetMainChefsEntrepriseIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialGetSearchCriteriasRaw(requestParameters: SiegeSocialGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocial/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async siegeSocialGetSearchCriterias(requestParameters: SiegeSocialGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.siegeSocialGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialGetSelectItemsRaw(requestParameters: SiegeSocialGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling siegeSocialGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocial/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async siegeSocialGetSelectItems(requestParameters: SiegeSocialGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.siegeSocialGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialGetSelectedChefEntrepriseRaw(requestParameters: SiegeSocialGetSelectedChefEntrepriseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChefEntrepriseSiegeEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.IdchefEntreprise !== undefined) {
            queryParameters['IdchefEntreprise'] = requestParameters.IdchefEntreprise;
        }

        if (requestParameters.idsiegeSocial !== undefined) {
            queryParameters['idsiegeSocial'] = requestParameters.idsiegeSocial;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocial/GetSelectedChefEntreprise`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChefEntrepriseSiegeEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async siegeSocialGetSelectedChefEntreprise(requestParameters: SiegeSocialGetSelectedChefEntrepriseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChefEntrepriseSiegeEditDto> {
        const response = await this.siegeSocialGetSelectedChefEntrepriseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialGetSiegeSocialRaw(requestParameters: SiegeSocialGetSiegeSocialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SiegeSocialEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocial`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiegeSocialEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async siegeSocialGetSiegeSocial(requestParameters: SiegeSocialGetSiegeSocialRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SiegeSocialEditDto> {
        const response = await this.siegeSocialGetSiegeSocialRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialIsActifRaw(requestParameters: SiegeSocialIsActifRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocial/IsActif`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async siegeSocialIsActif(requestParameters: SiegeSocialIsActifRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.siegeSocialIsActifRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialIsBceAlreadyExistRaw(requestParameters: SiegeSocialIsBceAlreadyExistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.bce !== undefined) {
            queryParameters['bce'] = requestParameters.bce;
        }

        if (requestParameters.idsiegeSocial !== undefined) {
            queryParameters['idsiegeSocial'] = requestParameters.idsiegeSocial;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocial/IsBceAlreadyExist`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async siegeSocialIsBceAlreadyExist(requestParameters: SiegeSocialIsBceAlreadyExistRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.siegeSocialIsBceAlreadyExistRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialSaveChefEntrepriseSiegeRaw(requestParameters: SiegeSocialSaveChefEntrepriseSiegeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChefEntrepriseSiegeEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocial/SaveChefEntrepriseSiege`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChefEntrepriseSiegeEditDtoToJSON(requestParameters.ChefEntrepriseSiegeEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChefEntrepriseSiegeEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async siegeSocialSaveChefEntrepriseSiege(requestParameters: SiegeSocialSaveChefEntrepriseSiegeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChefEntrepriseSiegeEditDto> {
        const response = await this.siegeSocialSaveChefEntrepriseSiegeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialSaveSiegeSocialRaw(requestParameters: SiegeSocialSaveSiegeSocialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SiegeSocialEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocial`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SiegeSocialEditDtoToJSON(requestParameters.SiegeSocialEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiegeSocialEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async siegeSocialSaveSiegeSocial(requestParameters: SiegeSocialSaveSiegeSocialRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SiegeSocialEditDto> {
        const response = await this.siegeSocialSaveSiegeSocialRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialSearchChefsEntrepriseRaw(requestParameters: SiegeSocialSearchChefsEntrepriseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SiegeSocialChefEntrepriseGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocial/SearchChefsEntreprise`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SiegeSocialChefEntrepriseSearchDtoToJSON(requestParameters.SiegeSocialChefEntrepriseSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiegeSocialChefEntrepriseGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async siegeSocialSearchChefsEntreprise(requestParameters: SiegeSocialSearchChefsEntrepriseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SiegeSocialChefEntrepriseGridDtoPaginatedResults> {
        const response = await this.siegeSocialSearchChefsEntrepriseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialSearchDoublonsChefsEntrepriseSiegeRaw(requestParameters: SiegeSocialSearchDoublonsChefsEntrepriseSiegeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChefsEntrepriseSiegeDoublonGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocial/SearchDoublons`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChefsEntrepriseSiegeDoublonSearchDtoToJSON(requestParameters.ChefsEntrepriseSiegeDoublonSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChefsEntrepriseSiegeDoublonGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async siegeSocialSearchDoublonsChefsEntrepriseSiege(requestParameters: SiegeSocialSearchDoublonsChefsEntrepriseSiegeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChefsEntrepriseSiegeDoublonGridDtoPaginatedResults> {
        const response = await this.siegeSocialSearchDoublonsChefsEntrepriseSiegeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialSearchHistoryRaw(requestParameters: SiegeSocialSearchHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SiegeSocialHistoryGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocial/SearchHistory`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SiegeSocialHistorySearchToJSON(requestParameters.SiegeSocialHistorySearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiegeSocialHistoryGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async siegeSocialSearchHistory(requestParameters: SiegeSocialSearchHistoryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SiegeSocialHistoryGridDtoPaginatedResults> {
        const response = await this.siegeSocialSearchHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialSearchSiegeSocialIdsRaw(requestParameters: SiegeSocialSearchSiegeSocialIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocial/SearchSiegeSocialIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SiegeSocialSearchDtoToJSON(requestParameters.SiegeSocialSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async siegeSocialSearchSiegeSocialIds(requestParameters: SiegeSocialSearchSiegeSocialIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.siegeSocialSearchSiegeSocialIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
