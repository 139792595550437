/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FileDownloadDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
  SiegeSocialFichierDto,
  SiegeSocialFichierGridDtoPaginatedResults,
  SiegeSocialFichierSearchDto,
  SiegeSocialFichierUploadDto,
} from '../models/index';
import {
    FileDownloadDtoFromJSON,
    FileDownloadDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
    SiegeSocialFichierDtoFromJSON,
    SiegeSocialFichierDtoToJSON,
    SiegeSocialFichierGridDtoPaginatedResultsFromJSON,
    SiegeSocialFichierGridDtoPaginatedResultsToJSON,
    SiegeSocialFichierSearchDtoFromJSON,
    SiegeSocialFichierSearchDtoToJSON,
    SiegeSocialFichierUploadDtoFromJSON,
    SiegeSocialFichierUploadDtoToJSON,
} from '../models/index';

export interface SiegeSocialFichierBaseSearchRequest {
    SiegeSocialFichierSearchDto?: SiegeSocialFichierSearchDto;
}

export interface SiegeSocialFichierDeleteSiegeSocialFichierRequest {
    id?: number;
}

export interface SiegeSocialFichierDownloadSiegeSocialFichierRequest {
    id?: number;
}

export interface SiegeSocialFichierGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface SiegeSocialFichierGetSelectItemsRequest {
    searchField: string;
}

export interface SiegeSocialFichierGetSiegeSocialFichierRequest {
    id?: number;
}

export interface SiegeSocialFichierSaveSiegeSocialFichierRequest {
    SiegeSocialFichierDto?: SiegeSocialFichierDto;
}

export interface SiegeSocialFichierUploadSiegeSocialFichierRequest {
    SiegeSocialFichierUploadDto?: SiegeSocialFichierUploadDto;
}

/**
 * 
 */
export class SiegeSocialFichierApi extends runtime.BaseAPI {

    /**
     */
    async siegeSocialFichierBaseSearchRaw(requestParameters: SiegeSocialFichierBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SiegeSocialFichierGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocialFichier/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SiegeSocialFichierSearchDtoToJSON(requestParameters.SiegeSocialFichierSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiegeSocialFichierGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async siegeSocialFichierBaseSearch(requestParameters: SiegeSocialFichierBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SiegeSocialFichierGridDtoPaginatedResults> {
        const response = await this.siegeSocialFichierBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialFichierDeleteSiegeSocialFichierRaw(requestParameters: SiegeSocialFichierDeleteSiegeSocialFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocialFichier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async siegeSocialFichierDeleteSiegeSocialFichier(requestParameters: SiegeSocialFichierDeleteSiegeSocialFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.siegeSocialFichierDeleteSiegeSocialFichierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async siegeSocialFichierDownloadSiegeSocialFichierRaw(requestParameters: SiegeSocialFichierDownloadSiegeSocialFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocialFichier/Download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async siegeSocialFichierDownloadSiegeSocialFichier(requestParameters: SiegeSocialFichierDownloadSiegeSocialFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.siegeSocialFichierDownloadSiegeSocialFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialFichierGetSearchCriteriasRaw(requestParameters: SiegeSocialFichierGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocialFichier/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async siegeSocialFichierGetSearchCriterias(requestParameters: SiegeSocialFichierGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.siegeSocialFichierGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialFichierGetSelectItemsRaw(requestParameters: SiegeSocialFichierGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling siegeSocialFichierGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocialFichier/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async siegeSocialFichierGetSelectItems(requestParameters: SiegeSocialFichierGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.siegeSocialFichierGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialFichierGetSiegeSocialFichierRaw(requestParameters: SiegeSocialFichierGetSiegeSocialFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SiegeSocialFichierDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocialFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiegeSocialFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async siegeSocialFichierGetSiegeSocialFichier(requestParameters: SiegeSocialFichierGetSiegeSocialFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SiegeSocialFichierDto> {
        const response = await this.siegeSocialFichierGetSiegeSocialFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialFichierSaveSiegeSocialFichierRaw(requestParameters: SiegeSocialFichierSaveSiegeSocialFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SiegeSocialFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocialFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SiegeSocialFichierDtoToJSON(requestParameters.SiegeSocialFichierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiegeSocialFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async siegeSocialFichierSaveSiegeSocialFichier(requestParameters: SiegeSocialFichierSaveSiegeSocialFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SiegeSocialFichierDto> {
        const response = await this.siegeSocialFichierSaveSiegeSocialFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeSocialFichierUploadSiegeSocialFichierRaw(requestParameters: SiegeSocialFichierUploadSiegeSocialFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SiegeSocialFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeSocialFichier/Upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SiegeSocialFichierUploadDtoToJSON(requestParameters.SiegeSocialFichierUploadDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiegeSocialFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async siegeSocialFichierUploadSiegeSocialFichier(requestParameters: SiegeSocialFichierUploadSiegeSocialFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SiegeSocialFichierDto> {
        const response = await this.siegeSocialFichierUploadSiegeSocialFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
