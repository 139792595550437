import { Button, Colors, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useSet } from "ahooks";
import {
  ButtonsBloc,
  DataTable,
  FGCustomPanel,
  FGMaskInput,
  FGTextInput,
  FieldGroup,
  FieldSet,
  InlineButtonContainer,
  useGridState,
  useSearchApi
} from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import styled, { css } from "styled-components";

import { AddButton, SelectButton, SmallFormGenerator } from "../..";
import {
  RepresentantSelectorApi,
  RepresentantSelectorGridDto,
  RepresentantSelectorSearch,
  RepresentantSelectorSearchFromJSON
} from "../../../api";
import { ERoutes } from "../../../AppRouter";
import { useApiService, useTabMessage, useTheme, useTl } from "../../../hooks";
import { useReferential } from "../../../hooks/useReferential";
import { ETLCodes } from "../../../locales";
import { Guid } from "../../../utils/guid";
import { FGWalterDateMaskInput } from "../FGWalterDateMaskInput";
import { FGWalterSelectInput } from "../FGWalterSelectInput";

const Container = styled.div`
  margin: 1rem 1rem 0 1rem;
`;

export interface IRepresentantSelectorProps {
  excludedRepresentantIds?: number[];
  onRepresentantSelected: (selectedRepresentantIds?: number[]) => void;
  rightElement?: any;
  allowMultipleSelection?: boolean;
  preSelectedIds?: number[];
}

export const RepresentantSelector: React.FunctionComponent<IRepresentantSelectorProps> = ({
  excludedRepresentantIds = [],
  onRepresentantSelected,
  rightElement,
  allowMultipleSelection = false,
  preSelectedIds = []
}) => {
  const { t } = useTl();
  const history = useHistory();
  const { theme } = useTheme();
  const [nationalites, nLoading] = useReferential(a => a.referentialGetAllNationalite(), false, []);
  const [selectedRepresentantIds, { add: addRepresentantId, remove: removeRepresentantId }] = useSet<number>(
    preSelectedIds
  );
  const api = useApiService(RepresentantSelectorApi);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [10, 15],
    pageSize: 10,
    sortKeys: { nom: "ASC", prenom: "ASC" }
  });

  const initialData = React.useMemo(() => {
    return RepresentantSelectorSearchFromJSON({
      selectedRepresentantIds,
      excludedRepresentantIds,
      forceSkip: 0,
      forceTake: 10
    });
  }, [excludedRepresentantIds, selectedRepresentantIds]);

  const { totalCount } = tableState;
  const searchFunction = React.useCallback(
    (sObj?: RepresentantSelectorSearch) => {
      let nextSObj = { ...sObj };
      Object.keys(sObj)
        .filter(k => !["forceSkip", "forceTake", "filter", "sortKey", "aggregateKeys", "parameters"].includes(k))
        .forEach(k => {
          nextSObj[k] = !!sObj[k]?.value ? sObj[k] : undefined;
        });
      return api.representantSelectorBaseSearch({
        RepresentantSelectorSearch: {
          ...nextSObj,
          selectedRepresentantIds: Array.from(selectedRepresentantIds),
          excludedRepresentantIds
        }
      });
    },
    [api, excludedRepresentantIds, selectedRepresentantIds]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const selectRepresentant = React.useCallback(
    (id: number) => {
      if (allowMultipleSelection) {
        selectedRepresentantIds.has(id) ? removeRepresentantId(id) : addRepresentantId(id);
      } else {
        onRepresentantSelected([id]);
      }
    },
    [addRepresentantId, allowMultipleSelection, onRepresentantSelected, removeRepresentantId, selectedRepresentantIds]
  );

  const onRowClick = React.useCallback(
    (item: RepresentantSelectorGridDto) => {
      selectRepresentant(item.idrepresentant);
    },
    [selectRepresentant]
  );

  const identifier = React.useMemo(() => Guid.newGuid(), []);
  const { messageValue, clearMessageValue, openTabThenSendMessage } = useTabMessage(identifier);
  const add = React.useCallback(
    values => {
      openTabThenSendMessage(`#${ERoutes.representant}/-1/detail/checkDoublons`, values);
    },
    [openTabThenSendMessage]
  );

  React.useEffect(() => {
    if (messageValue) {
      search();
      selectRepresentant(messageValue);
      clearMessageValue();
    }
  }, [clearMessageValue, messageValue, search, selectRepresentant]);

  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.DateNaissance),
        fieldName: "dateNaissance"
      },
      {
        header: () => t(ETLCodes.NumeroNational),
        fieldName: "numeroNational"
      },
      {
        header: () => t(ETLCodes.Adresse),
        fieldName: "adresse"
      },
      {
        header: () => t(ETLCodes.CodePostal),
        fieldName: "codePostal"
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      },
      {
        header: () => t(ETLCodes.Nationalite),
        fieldName: "nationalite"
      }
    ],
    [t]
  );

  const customizeRowStyleCallback = React.useCallback(
    (item: RepresentantSelectorGridDto) => {
      const isSelected = selectedRepresentantIds.has(item.idrepresentant);

      return css`
        & * {
          color: ${isSelected ? Colors.WHITE : Colors.BLACK} !important;
        }

        & & > td {
          background-color: ${isSelected ? theme.primaryColor : "initial"} !important;
        }

        background-color: ${isSelected ? theme.primaryColor : "transparent"} !important;
      `;
    },
    [selectedRepresentantIds, theme.primaryColor]
  );

  const onRepresentantSelect = React.useCallback(() => {
    onRepresentantSelected(Array.from(selectedRepresentantIds));
  }, [onRepresentantSelected, selectedRepresentantIds]);

  const finalRightElements = React.useMemo(() => {
    return allowMultipleSelection ? (
      <>
        <SelectButton onClick={onRepresentantSelect}></SelectButton>
        {rightElement}
      </>
    ) : (
      rightElement
    );
  }, [allowMultipleSelection, onRepresentantSelect, rightElement]);

  const tableTitle = React.useMemo(
    () =>
      `${t(ETLCodes.TableResults, { count: totalCount })}${
        selectedRepresentantIds.size > 0
          ? " - " + selectedRepresentantIds.size + " " + t(ETLCodes.SelectedRepresentants)
          : ""
      }`,
    [selectedRepresentantIds.size, t, totalCount]
  );

  return (
    <Container>
      <SmallFormGenerator
        enableDirtyCheck={false}
        initialValues={initialData}
        onSubmit={c => search(c)}
        editMode
        hideButtons={true}
        onCancel={() => history.push(`${ERoutes.representant}`)}
      >
        <FieldGroup columns={2}>
          <FGTextInput name="nom.value" label={t(ETLCodes.Nom)} maxLength={50} forceCase="upper" />
          <FGWalterSelectInput
            name="nationalite.value"
            label={t(ETLCodes.Nationalite)}
            items={nationalites}
            loading={nLoading}
          />
          <FGTextInput name="prenom.value" label={t(ETLCodes.Prenom)} maxLength={50} />
          <FGMaskInput
            name="numeroNational.value"
            label={t(ETLCodes.NumeroNational)}
            cleaveOptions={{ delimiters: [".", ".", "-", "."], blocks: [2, 2, 2, 3, 2], numericOnly: true }}
          />
          <FGWalterDateMaskInput name="dateNaissance.value" label={t(ETLCodes.DateNaissance)} />
        </FieldGroup>
        <FieldGroup>
          <FGCustomPanel>
            {ctx => (
              <InlineButtonContainer>
                <ButtonsBloc></ButtonsBloc>
                <ButtonsBloc>
                  <Button icon={IconNames.SEARCH} type="submit" text={t(ETLCodes.Search)} intent={Intent.PRIMARY} />
                  <AddButton
                    onClick={() => add(ctx.formik?.values)}
                    text={t(ETLCodes.General_Add)}
                    intent={Intent.PRIMARY}
                    minimal={false}
                  />
                </ButtonsBloc>
              </InlineButtonContainer>
            )}
          </FGCustomPanel>
        </FieldGroup>
        <FGCustomPanel>
          {ctx => (
            <FieldSet title={tableTitle} rightElement={finalRightElements}>
              <DataTable
                dateFormat="dd-MM-yyyy"
                tableState={tableState}
                loading={loading}
                columns={columns}
                onRowClick={onRowClick}
                customizeRowStyle={customizeRowStyleCallback}
                htmlTableOptions={{ striped: true, interactive: true, bordered: true }}
              />
            </FieldSet>
          )}
        </FGCustomPanel>
      </SmallFormGenerator>
    </Container>
  );
};
