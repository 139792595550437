/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdresseDoublonDto,
  AdresseDoublonGridDtoPaginatedResults,
  AdresseDoublonSearchDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
} from '../models/index';
import {
    AdresseDoublonDtoFromJSON,
    AdresseDoublonDtoToJSON,
    AdresseDoublonGridDtoPaginatedResultsFromJSON,
    AdresseDoublonGridDtoPaginatedResultsToJSON,
    AdresseDoublonSearchDtoFromJSON,
    AdresseDoublonSearchDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface AdresseDoublonBaseSearchRequest {
    AdresseDoublonSearchDto?: AdresseDoublonSearchDto;
}

export interface AdresseDoublonGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface AdresseDoublonGetSelectItemsRequest {
    searchField: string;
}

export interface AdresseDoublonSaveAdresseDoublonRequest {
    AdresseDoublonDto?: AdresseDoublonDto;
}

/**
 * 
 */
export class AdresseDoublonApi extends runtime.BaseAPI {

    /**
     */
    async adresseDoublonBaseSearchRaw(requestParameters: AdresseDoublonBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdresseDoublonGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AdresseDoublon/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdresseDoublonSearchDtoToJSON(requestParameters.AdresseDoublonSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdresseDoublonGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async adresseDoublonBaseSearch(requestParameters: AdresseDoublonBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdresseDoublonGridDtoPaginatedResults> {
        const response = await this.adresseDoublonBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async adresseDoublonGetSearchCriteriasRaw(requestParameters: AdresseDoublonGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AdresseDoublon/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async adresseDoublonGetSearchCriterias(requestParameters: AdresseDoublonGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.adresseDoublonGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async adresseDoublonGetSelectItemsRaw(requestParameters: AdresseDoublonGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling adresseDoublonGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AdresseDoublon/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async adresseDoublonGetSelectItems(requestParameters: AdresseDoublonGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.adresseDoublonGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async adresseDoublonSaveAdresseDoublonRaw(requestParameters: AdresseDoublonSaveAdresseDoublonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdresseDoublonDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AdresseDoublon`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdresseDoublonDtoToJSON(requestParameters.AdresseDoublonDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdresseDoublonDtoFromJSON(jsonValue));
    }

    /**
     */
    async adresseDoublonSaveAdresseDoublon(requestParameters: AdresseDoublonSaveAdresseDoublonRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdresseDoublonDto> {
        const response = await this.adresseDoublonSaveAdresseDoublonRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
