/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetApprenantAvisResultatsIdsfichierDto
 */
export interface GetApprenantAvisResultatsIdsfichierDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof GetApprenantAvisResultatsIdsfichierDto
     */
    idsApprenant?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof GetApprenantAvisResultatsIdsfichierDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetApprenantAvisResultatsIdsfichierDto
     */
    is2Sess?: boolean;
}

/**
 * Check if a given object implements the GetApprenantAvisResultatsIdsfichierDto interface.
 */
export function instanceOfGetApprenantAvisResultatsIdsfichierDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetApprenantAvisResultatsIdsfichierDtoFromJSON(json: any): GetApprenantAvisResultatsIdsfichierDto {
    return GetApprenantAvisResultatsIdsfichierDtoFromJSONTyped(json, false);
}

export function GetApprenantAvisResultatsIdsfichierDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetApprenantAvisResultatsIdsfichierDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsApprenant': !exists(json, 'idsApprenant') ? undefined : json['idsApprenant'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'is2Sess': !exists(json, 'is2Sess') ? undefined : json['is2Sess'],
    };
}

export function GetApprenantAvisResultatsIdsfichierDtoToJSON(value?: GetApprenantAvisResultatsIdsfichierDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsApprenant': value.idsApprenant,
        'anneeScolaire': value.anneeScolaire,
        'is2Sess': value.is2Sess,
    };
}

