import { Classes, Colors, Divider, InputGroup, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  ButtonContainer,
  DataTable,
  FGTextInput,
  FieldGroup,
  FieldSet,
  IDataTableColumn,
  useApiEffect,
  useGridState,
  useSearchApi
} from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";

import { FcbMatiereReferentielGridDto, MatiereReferentielApi, MatiereReferentielSearch } from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import {
  AddButton,
  BooleanColumn,
  DeleteButton,
  EditButton,
  SmallFormGenerator,
  ViewButton
} from "../../../../../../components";
import { useDialog } from "../../../../../../contexts";
import { useApiService, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

const ViewInput = styled(InputGroup)<{ colorcheck: string }>`
  & .${Classes.ICON} {
    color: ${p => p.colorcheck};
    margin-left: 0;
  }
`;

export interface IReferentielMatiereListPageProps {}

export const ReferentielMatiereListPage: React.FunctionComponent<IReferentielMatiereListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { showDialog } = useDialog();
  const { id } = useParams<{ id: string }>();
  const ReferentielId = React.useMemo(() => +id, [id]);
  const api = useApiService(MatiereReferentielApi);
  const path = `${ERoutes.referentiel}/${id}/matiere`;
  const tableState = useGridState<any>({
    sortKeys: { idmatiere: "ASC" },
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: MatiereReferentielSearch) => {
      sObj.idreferentiel = ReferentielId;
      return api.matiereReferentielBaseSearch({ MatiereReferentielSearch: sObj });
    },
    [ReferentielId, api]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const [data, , , refresh] = useApiEffect(
    React.useCallback(() => {
      return api.matiereReferentielGetHours({ idReferentiel: ReferentielId });
    }, [ReferentielId, api])
  );

  const deleteClasse = React.useCallback(
    async (row: FcbMatiereReferentielGridDto) => {
      showDialog({
        message: t(ETLCodes.DeleteConfirmationMessage),
        title: t(ETLCodes.DeleteDialogTitle),
        onConfirmed: async () => {
          await api.matiereReferentielDelete({ idReferentiel: row.idreferentiel, idMatiere: row.idmatiere });
          search(null);
          refresh();
        }
      });
    },
    [api, refresh, search, showDialog, t]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbMatiereReferentielGridDto) => (
          <ButtonContainer>
            {row.isLinkedToClasse ? <></> : <DeleteButton minimal={true} onDelete={() => deleteClasse(row)} />}
            <ViewButton minimal={true} onClick={() => history.push(`${path}/${row.idmatiere}/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${path}/${row.idmatiere}/edit`)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "idmatiere"
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      },
      {
        header: () => t(ETLCodes.Niveau),
        fieldName: "niveau",
        render: (row: FcbMatiereReferentielGridDto) =>
          row.niveau && row.niveau + (row.niveau > 0 && !!row.matiereParent ? ` (${row.matiereParent})` : "")
      },
      {
        header: () => t(ETLCodes.Degre),
        fieldName: "degre"
      },
      {
        header: () => t(ETLCodes.NbHeure),
        fieldName: "nbheures"
      },
      {
        header: () => t(ETLCodes.TJ),
        fieldName: "travail"
      },
      {
        header: () => t(ETLCodes.Examen),
        fieldName: "examen"
      },
      {
        header: () => t(ETLCodes.Categorie),
        fieldName: "categorie"
      },
      {
        header: () => t(ETLCodes.TypeProjet),
        fieldName: "projetFinancement"
      },
      {
        header: () => t(ETLCodes.IsLinkedToClasse),
        fieldName: "email",
        autoFitContent: true,
        alignment: "center",
        render: (row: FcbMatiereReferentielGridDto) => (
          <ViewInput
            large={false}
            leftIcon={row.isLinkedToClasse ? IconNames.TICK : IconNames.CROSS}
            readOnly={true}
            style={{ boxShadow: "none", backgroundColor: "transparent" }}
            colorcheck={row.isLinkedToClasse ? Colors.GREEN5 : Colors.RED5}
          />
        )
      },
      {
        header: () => t(ETLCodes.PcMinReussite),
        fieldName: "pcReussite"
      },
      {
        header: () => t(ETLCodes.UAA),
        fieldName: "uaa",
        alignment: "center",
        autoFitContent: true,
        render: (row: FcbMatiereReferentielGridDto) => <BooleanColumn value={row.uaa} />
      },
      {
        header: () => t(ETLCodes.VisibleBulletin),
        fieldName: "isVisibleBulletin",
        alignment: "center",
        autoFitContent: true,
        render: (row: FcbMatiereReferentielGridDto) => <BooleanColumn value={row.isVisibleBulletin} />
      },
      {
        header: () => t(ETLCodes.Sommative),
        fieldName: "sommative",
        alignment: "center",
        autoFitContent: true,
        render: (row: FcbMatiereReferentielGridDto) => <BooleanColumn value={row.sommative} />
      }
    ],
    [deleteClasse, history, path, t]
  );

  const labelStyle = React.useMemo(() => {
    if (data) {
      return {
        color: data.nbHeures === data.nbHeuresReelles ? Colors.GREEN3 : Colors.RED3
      };
    }
  }, [data]);

  const canAddMatiere = React.useMemo(() => {
    if (data) {
      return data.nbHeures !== data.nbHeuresReelles;
    }
  }, [data]);

  return (
    <>
      <FieldSet
        title={t(ETLCodes.TableResults, { count: totalCount })}
        rightElement={
          canAddMatiere ? (
            <AddButton
              onClick={e => {
                e.stopPropagation();
                history.push(`${path}/0/edit`);
              }}
              text={t(ETLCodes.General_Add)}
              intent={Intent.PRIMARY}
            />
          ) : (
            <></>
          )
        }
      >
        <SmallFormGenerator initialValues={data} onSubmit={() => {}} hideButtons={true} editable={false}>
          <FieldGroup columns={1}>
            <FieldGroup columns={[4, 4, 4]}>
              <FGTextInput name="nbHeures" label={t(ETLCodes.NbHeure)} disabled />
              <FGTextInput name="total" label={t(ETLCodes.TotalPointsTJExam)} disabled />
            </FieldGroup>
            <FieldGroup columns={[4, 4, 4]}>
              <FGTextInput name="nbHeuresReelles" label={t(ETLCodes.NbHeureReelle)} disabled labelStyles={labelStyle} />
            </FieldGroup>
          </FieldGroup>
          <Divider />
        </SmallFormGenerator>
        <DataTable dateFormat="dd/MM/yyyy" tableState={tableState} loading={loading} columns={columns}></DataTable>
      </FieldSet>
    </>
  );
};
