/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BilanCompetenceFichierGridDto } from './BilanCompetenceFichierGridDto';
import {
    BilanCompetenceFichierGridDtoFromJSON,
    BilanCompetenceFichierGridDtoFromJSONTyped,
    BilanCompetenceFichierGridDtoToJSON,
} from './BilanCompetenceFichierGridDto';

/**
 * 
 * @export
 * @interface BilanCompetenceEditDto
 */
export interface BilanCompetenceEditDto {
    /**
     * 
     * @type {number}
     * @memberof BilanCompetenceEditDto
     */
    idbilanCompetence?: number;
    /**
     * 
     * @type {number}
     * @memberof BilanCompetenceEditDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {string}
     * @memberof BilanCompetenceEditDto
     */
    idanneeDeFormation?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof BilanCompetenceEditDto
     */
    date?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof BilanCompetenceEditDto
     */
    pointsMath?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BilanCompetenceEditDto
     */
    pointsFrancais?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BilanCompetenceEditDto
     */
    idexaminateur?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BilanCompetenceEditDto
     */
    idcommentaire?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BilanCompetenceEditDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BilanCompetenceEditDto
     */
    typeAcquis?: string | null;
    /**
     * 
     * @type {Array<BilanCompetenceFichierGridDto>}
     * @memberof BilanCompetenceEditDto
     */
    fichiers?: Array<BilanCompetenceFichierGridDto> | null;
}

/**
 * Check if a given object implements the BilanCompetenceEditDto interface.
 */
export function instanceOfBilanCompetenceEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BilanCompetenceEditDtoFromJSON(json: any): BilanCompetenceEditDto {
    return BilanCompetenceEditDtoFromJSONTyped(json, false);
}

export function BilanCompetenceEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BilanCompetenceEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idbilanCompetence': !exists(json, 'idbilanCompetence') ? undefined : json['idbilanCompetence'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'idanneeDeFormation': !exists(json, 'idanneeDeFormation') ? undefined : json['idanneeDeFormation'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'pointsMath': !exists(json, 'pointsMath') ? undefined : json['pointsMath'],
        'pointsFrancais': !exists(json, 'pointsFrancais') ? undefined : json['pointsFrancais'],
        'idexaminateur': !exists(json, 'idexaminateur') ? undefined : json['idexaminateur'],
        'idcommentaire': !exists(json, 'idcommentaire') ? undefined : json['idcommentaire'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'typeAcquis': !exists(json, 'typeAcquis') ? undefined : json['typeAcquis'],
        'fichiers': !exists(json, 'fichiers') ? undefined : (json['fichiers'] === null ? null : (json['fichiers'] as Array<any>).map(BilanCompetenceFichierGridDtoFromJSON)),
    };
}

export function BilanCompetenceEditDtoToJSON(value?: BilanCompetenceEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idbilanCompetence': value.idbilanCompetence,
        'idpersonne': value.idpersonne,
        'idanneeDeFormation': value.idanneeDeFormation,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'pointsMath': value.pointsMath,
        'pointsFrancais': value.pointsFrancais,
        'idexaminateur': value.idexaminateur,
        'idcommentaire': value.idcommentaire,
        'remarque': value.remarque,
        'typeAcquis': value.typeAcquis,
        'fichiers': value.fichiers === undefined ? undefined : (value.fichiers === null ? null : (value.fichiers as Array<any>).map(BilanCompetenceFichierGridDtoToJSON)),
    };
}

