/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DoublonsPersonneGridDto
 */
export interface DoublonsPersonneGridDto {
    /**
     * 
     * @type {number}
     * @memberof DoublonsPersonneGridDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {string}
     * @memberof DoublonsPersonneGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoublonsPersonneGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DoublonsPersonneGridDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DoublonsPersonneGridDto
     */
    registreNational?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DoublonsPersonneGridDto
     */
    isApprenant?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoublonsPersonneGridDto
     */
    isFormateur?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoublonsPersonneGridDto
     */
    isRepresentant?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoublonsPersonneGridDto
     */
    isUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoublonsPersonneGridDto
     */
    isChefEntreprise?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoublonsPersonneGridDto
     */
    isTuteur?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoublonsPersonneGridDto
     */
    isConseillePedagogique?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DoublonsPersonneGridDto
     */
    isContact?: boolean;
}

/**
 * Check if a given object implements the DoublonsPersonneGridDto interface.
 */
export function instanceOfDoublonsPersonneGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DoublonsPersonneGridDtoFromJSON(json: any): DoublonsPersonneGridDto {
    return DoublonsPersonneGridDtoFromJSONTyped(json, false);
}

export function DoublonsPersonneGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DoublonsPersonneGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'registreNational': !exists(json, 'registreNational') ? undefined : json['registreNational'],
        'isApprenant': !exists(json, 'isApprenant') ? undefined : json['isApprenant'],
        'isFormateur': !exists(json, 'isFormateur') ? undefined : json['isFormateur'],
        'isRepresentant': !exists(json, 'isRepresentant') ? undefined : json['isRepresentant'],
        'isUser': !exists(json, 'isUser') ? undefined : json['isUser'],
        'isChefEntreprise': !exists(json, 'isChefEntreprise') ? undefined : json['isChefEntreprise'],
        'isTuteur': !exists(json, 'isTuteur') ? undefined : json['isTuteur'],
        'isConseillePedagogique': !exists(json, 'isConseillePedagogique') ? undefined : json['isConseillePedagogique'],
        'isContact': !exists(json, 'isContact') ? undefined : json['isContact'],
    };
}

export function DoublonsPersonneGridDtoToJSON(value?: DoublonsPersonneGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idpersonne': value.idpersonne,
        'nom': value.nom,
        'prenom': value.prenom,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'registreNational': value.registreNational,
        'isApprenant': value.isApprenant,
        'isFormateur': value.isFormateur,
        'isRepresentant': value.isRepresentant,
        'isUser': value.isUser,
        'isChefEntreprise': value.isChefEntreprise,
        'isTuteur': value.isTuteur,
        'isConseillePedagogique': value.isConseillePedagogique,
        'isContact': value.isContact,
    };
}

