import * as React from "react";
import { ClasseAgrementLocalisationList } from "./ClasseAgrementLocalisationList";
import { useParams } from "react-router";
import { ClasseAgrementLocalisationFinanceForm } from "./ClasseAgrementLocalisationFinanceForm";

export interface IClasseAgrementLocalisationItemPageProps {}

export const ClasseAgrementLocalisationItemPage: React.FunctionComponent<IClasseAgrementLocalisationItemPageProps> = () => {
  const { id, state } = useParams<{ id: string; state: string }>();
  return (
    <>
      <ClasseAgrementLocalisationList idClasse={+id} state={state} />
      <ClasseAgrementLocalisationFinanceForm />
    </>
  );
};
