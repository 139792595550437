/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExamenEntreeFceFichierGridDto } from './ExamenEntreeFceFichierGridDto';
import {
    ExamenEntreeFceFichierGridDtoFromJSON,
    ExamenEntreeFceFichierGridDtoFromJSONTyped,
    ExamenEntreeFceFichierGridDtoToJSON,
} from './ExamenEntreeFceFichierGridDto';

/**
 * 
 * @export
 * @interface ExamenEntreeFceEditDto
 */
export interface ExamenEntreeFceEditDto {
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeFceEditDto
     */
    idexamenEntreeFce?: number;
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeFceEditDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {string}
     * @memberof ExamenEntreeFceEditDto
     */
    anneeDeFormation?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ExamenEntreeFceEditDto
     */
    date?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeFceEditDto
     */
    pointsMath?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeFceEditDto
     */
    pointsFrancais?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeFceEditDto
     */
    decisionDossierAdmission?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeFceEditDto
     */
    examinateur?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeFceEditDto
     */
    resultatFinal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeFceEditDto
     */
    commentaire?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ExamenEntreeFceEditDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExamenEntreeFceEditDto
     */
    typeAcquis?: string | null;
    /**
     * 
     * @type {Array<ExamenEntreeFceFichierGridDto>}
     * @memberof ExamenEntreeFceEditDto
     */
    fichiers?: Array<ExamenEntreeFceFichierGridDto> | null;
}

/**
 * Check if a given object implements the ExamenEntreeFceEditDto interface.
 */
export function instanceOfExamenEntreeFceEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExamenEntreeFceEditDtoFromJSON(json: any): ExamenEntreeFceEditDto {
    return ExamenEntreeFceEditDtoFromJSONTyped(json, false);
}

export function ExamenEntreeFceEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExamenEntreeFceEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idexamenEntreeFce': !exists(json, 'idexamenEntreeFce') ? undefined : json['idexamenEntreeFce'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'anneeDeFormation': !exists(json, 'anneeDeFormation') ? undefined : json['anneeDeFormation'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'pointsMath': !exists(json, 'pointsMath') ? undefined : json['pointsMath'],
        'pointsFrancais': !exists(json, 'pointsFrancais') ? undefined : json['pointsFrancais'],
        'decisionDossierAdmission': !exists(json, 'decisionDossierAdmission') ? undefined : json['decisionDossierAdmission'],
        'examinateur': !exists(json, 'examinateur') ? undefined : json['examinateur'],
        'resultatFinal': !exists(json, 'resultatFinal') ? undefined : json['resultatFinal'],
        'commentaire': !exists(json, 'commentaire') ? undefined : json['commentaire'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'typeAcquis': !exists(json, 'typeAcquis') ? undefined : json['typeAcquis'],
        'fichiers': !exists(json, 'fichiers') ? undefined : (json['fichiers'] === null ? null : (json['fichiers'] as Array<any>).map(ExamenEntreeFceFichierGridDtoFromJSON)),
    };
}

export function ExamenEntreeFceEditDtoToJSON(value?: ExamenEntreeFceEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idexamenEntreeFce': value.idexamenEntreeFce,
        'idpersonne': value.idpersonne,
        'anneeDeFormation': value.anneeDeFormation,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'pointsMath': value.pointsMath,
        'pointsFrancais': value.pointsFrancais,
        'decisionDossierAdmission': value.decisionDossierAdmission,
        'examinateur': value.examinateur,
        'resultatFinal': value.resultatFinal,
        'commentaire': value.commentaire,
        'remarque': value.remarque,
        'typeAcquis': value.typeAcquis,
        'fichiers': value.fichiers === undefined ? undefined : (value.fichiers === null ? null : (value.fichiers as Array<any>).map(ExamenEntreeFceFichierGridDtoToJSON)),
    };
}

