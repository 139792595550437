/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbTypeEvaluationDto,
  FcbTypeEvaluationDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
  TypeEvaluationSearch,
  ValidationError,
} from '../models/index';
import {
    FcbTypeEvaluationDtoFromJSON,
    FcbTypeEvaluationDtoToJSON,
    FcbTypeEvaluationDtoPaginatedResultsFromJSON,
    FcbTypeEvaluationDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    TypeEvaluationSearchFromJSON,
    TypeEvaluationSearchToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface TypeEvaluationBaseSearchRequest {
    TypeEvaluationSearch?: TypeEvaluationSearch;
}

export interface TypeEvaluationDeleteRequest {
    id?: number;
}

export interface TypeEvaluationExistsRequest {
    libelle?: string;
}

export interface TypeEvaluationGetRequest {
    id?: number;
}

export interface TypeEvaluationGetDisplayNameRequest {
    id?: number;
}

export interface TypeEvaluationGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface TypeEvaluationGetSelectItemsRequest {
    searchField: string;
}

export interface TypeEvaluationSaveRequest {
    FcbTypeEvaluationDto?: FcbTypeEvaluationDto;
}

/**
 * 
 */
export class TypeEvaluationApi extends runtime.BaseAPI {

    /**
     */
    async typeEvaluationBaseSearchRaw(requestParameters: TypeEvaluationBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbTypeEvaluationDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeEvaluation/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TypeEvaluationSearchToJSON(requestParameters.TypeEvaluationSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbTypeEvaluationDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async typeEvaluationBaseSearch(requestParameters: TypeEvaluationBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbTypeEvaluationDtoPaginatedResults> {
        const response = await this.typeEvaluationBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeEvaluationDeleteRaw(requestParameters: TypeEvaluationDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeEvaluation`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async typeEvaluationDelete(requestParameters: TypeEvaluationDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.typeEvaluationDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeEvaluationExistsRaw(requestParameters: TypeEvaluationExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.libelle !== undefined) {
            queryParameters['libelle'] = requestParameters.libelle;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeEvaluation/Exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async typeEvaluationExists(requestParameters: TypeEvaluationExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.typeEvaluationExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeEvaluationGetRaw(requestParameters: TypeEvaluationGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbTypeEvaluationDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeEvaluation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbTypeEvaluationDtoFromJSON(jsonValue));
    }

    /**
     */
    async typeEvaluationGet(requestParameters: TypeEvaluationGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbTypeEvaluationDto> {
        const response = await this.typeEvaluationGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeEvaluationGetDisplayNameRaw(requestParameters: TypeEvaluationGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeEvaluation/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async typeEvaluationGetDisplayName(requestParameters: TypeEvaluationGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.typeEvaluationGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeEvaluationGetSearchCriteriasRaw(requestParameters: TypeEvaluationGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeEvaluation/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async typeEvaluationGetSearchCriterias(requestParameters: TypeEvaluationGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.typeEvaluationGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeEvaluationGetSelectItemsRaw(requestParameters: TypeEvaluationGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling typeEvaluationGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeEvaluation/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async typeEvaluationGetSelectItems(requestParameters: TypeEvaluationGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.typeEvaluationGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeEvaluationSaveRaw(requestParameters: TypeEvaluationSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbTypeEvaluationDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeEvaluation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbTypeEvaluationDtoToJSON(requestParameters.FcbTypeEvaluationDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbTypeEvaluationDtoFromJSON(jsonValue));
    }

    /**
     */
    async typeEvaluationSave(requestParameters: TypeEvaluationSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbTypeEvaluationDto> {
        const response = await this.typeEvaluationSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
