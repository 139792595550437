import * as React from "react";
import { useHistory } from "react-router";

import {
  FcbDirectionTerritorialeGridDto,
  DirectionTerritorialeApi,
  DirectionTerritorialeSearch,
  EBooleanSearchTypes
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { useAbortableApiServiceFactory, useApiService } from "../../../../../hooks/useApiService";
import { ETLCodes } from "../../../../../locales";
import styled from "styled-components";

interface IDirectionTerritorialeListPageProps {}

const Container = styled.div`
  display: flex;
`;

export const DirectionTerritorialeListPage: React.FunctionComponent<IDirectionTerritorialeListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(DirectionTerritorialeApi);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbDirectionTerritorialeGridDto) => (
          <Container>
            <ViewButton
              minimal={true}
              onClick={() =>
                history.push(`${ERoutes.directionTerritoriale}/${row.iddirectionTerritoriale}/detail/view`)
              }
            />
            <EditButton
              minimal={true}
              onClick={() =>
                history.push(`${ERoutes.directionTerritoriale}/${row.iddirectionTerritoriale}/detail/edit`)
              }
            />
          </Container>
        )
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code",
        autoFitContent: true
      }
    ],
    [history, t]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.directionTerritoriale}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(
    () => api.directionTerritorialeGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  const apiFactory = useAbortableApiServiceFactory(DirectionTerritorialeApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: DirectionTerritorialeSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.directionTerritorialeBaseSearch({ DirectionTerritorialeSearch: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      columns={columns}
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      addFunc={addItemFunction}
      breadCrumbs={[{ text: t(ETLCodes.DirectionsTerritoriales), route: ERoutes.directionTerritoriale }]}
      sortKeys={{ nom: "ASC" }}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
    />
  );
};
