import { Classes, Dialog } from "@blueprintjs/core";
import { FGCustomPanel, FGTextAreaInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";

import { ContratSuspensionApi, ContratSuspensionDto, FichierApi } from "../../../../../api";
import {
  FGWalterDateMaskInput,
  FGWalterFileInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

const StyledDialog = styled(Dialog)`
  width: 500px;
  height: auto;
  background-color: white;
`;

export interface IContratSuspensionDetailDialogProps {
  dialogOpen: boolean;
  onClose: (toSave?: ContratSuspensionDto) => void;
  currentSuspension: ContratSuspensionDto;
}

export const ContratSuspensionDetailDialog: React.FunctionComponent<IContratSuspensionDetailDialogProps> = ({
  currentSuspension,
  dialogOpen,
  onClose
}) => {
  const { t } = useTl();
  const api = useApiService(ContratSuspensionApi);

  const { data, saveItem, saving, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () => currentSuspension,
        saveApiFn: d => api.contratSuspensionSaveContratSuspension({ ContratSuspensionDto: d }),
        onSaved: d => onClose(d),
        serverValidationRootKey: "Dto"
      }),
      [api, currentSuspension, onClose]
    )
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape(
        {
          dateDebut: Yup.date()
            .nullable()
            .when("dateFin", (value, schema) =>
              !!value ? schema.max(Yup.ref("dateFin"), t(ETLCodes.DateDebutGreaterThanDateFin)) : schema
            )
            .required(t(ETLCodes.Required)),
          dateFin: Yup.date()
            .nullable()
            .when("dateFin", (value, schema) =>
              !!value ? schema.min(Yup.ref("dateDebut"), t(ETLCodes.DateFinGreaterThanDateDebut)) : schema
            ),
          idmotifSuspension: Yup.number()
            .nullable()
            .required(t(ETLCodes.Required))
        },
        [["dateFin", "dateFin"]]
      ),
    [t]
  );

  const [motifs, mLoading] = useReferential(a => a.referentialGetMotifsSuspension());

  const fapi = useApiService(FichierApi);
  const downloadFn = React.useCallback(async idfichier => await fapi.fichierDownload({ id: idfichier }), [fapi]);

  return (
    <StyledDialog title={t(ETLCodes.SuspensionContrat)} isOpen={dialogOpen} onClose={() => onClose()}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose()}
          showDeleteButton={false}
          saving={saving}
          minLabelWidth={100}
          validationErrors={validationErrors}
          preventDefaultSaveOnEnter
          watchChanges={{
            fichierR: (value, formik) => {
              if (formik.dirty) {
                formik.setFieldValue("idfichier", null);
              }
            }
          }}
        >
          <FieldGroup>
            <FGWalterDateMaskInput name="dateDebut" label={t(ETLCodes.DateDebut)} />
            <FGWalterDateMaskInput name="dateFin" label={t(ETLCodes.DateFin)} />
            <FGWalterSelectInput name="idmotifSuspension" label={t(ETLCodes.Motif)} items={motifs} loading={mLoading} />
            <FGTextAreaInput name="remarque" label={t(ETLCodes.Remarque)} />
            <FGCustomPanel>
              {ctx => (
                <FGWalterFileInput
                  name="fichier"
                  label={t(ETLCodes.Document)}
                  downloadFn={ctx.formik.values?.fichier && (() => downloadFn(ctx.formik.values?.idfichier))}
                />
              )}
            </FGCustomPanel>
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
