/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContratAlternanceDto
 */
export interface ContratAlternanceDto {
    /**
     * 
     * @type {number}
     * @memberof ContratAlternanceDto
     */
    idcontratAlternance?: number;
    /**
     * 
     * @type {number}
     * @memberof ContratAlternanceDto
     */
    idcontrat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratAlternanceDto
     */
    iddispenseChomage?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratAlternanceDto
     */
    datePassageB?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratAlternanceDto
     */
    datePassageC?: Date | null;
}

/**
 * Check if a given object implements the ContratAlternanceDto interface.
 */
export function instanceOfContratAlternanceDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContratAlternanceDtoFromJSON(json: any): ContratAlternanceDto {
    return ContratAlternanceDtoFromJSONTyped(json, false);
}

export function ContratAlternanceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContratAlternanceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontratAlternance': !exists(json, 'idcontratAlternance') ? undefined : json['idcontratAlternance'],
        'idcontrat': !exists(json, 'idcontrat') ? undefined : json['idcontrat'],
        'iddispenseChomage': !exists(json, 'iddispenseChomage') ? undefined : json['iddispenseChomage'],
        'datePassageB': !exists(json, 'datePassageB') ? undefined : (json['datePassageB'] === null ? null : new Date(json['datePassageB'])),
        'datePassageC': !exists(json, 'datePassageC') ? undefined : (json['datePassageC'] === null ? null : new Date(json['datePassageC'])),
    };
}

export function ContratAlternanceDtoToJSON(value?: ContratAlternanceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontratAlternance': value.idcontratAlternance,
        'idcontrat': value.idcontrat,
        'iddispenseChomage': value.iddispenseChomage,
        'datePassageB': value.datePassageB === undefined ? undefined : (value.datePassageB === null ? null : value.datePassageB.toISOString()),
        'datePassageC': value.datePassageC === undefined ? undefined : (value.datePassageC === null ? null : value.datePassageC.toISOString()),
    };
}

