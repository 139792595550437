import { Classes, ControlGroup, Dialog, NonIdealState, Radio, RadioGroup } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";
import {
  EmailDto,
  EmailReplaceDto,
  EmailReplaceDtoFromJSON,
  EmailReplaceDtoToJSON,
  PersonneApi
} from "../../../../../../../api";
import { useApiService, useCrudApi, useTl } from "../../../../../../../hooks";
import * as Yup from "yup";
import { ETLCodes } from "../../../../../../../locales";
import { useReferential } from "../../../../../../../hooks/useReferential";
import { DeleteButton, FGWalterSelectInput, SmallFormGenerator } from "../../../../../../../components";
import { FGCustomPanel, FGTextInput } from "nsitools-react";

const StyledDialog = styled(Dialog)`
  width: 600px;
  height: auto;
  background-color: white;

  & .${Classes.RADIO} {
    align-self: center;
    min-width: 125px;
  }
`;

const StyledNonIdealState = styled(NonIdealState)`
  margin-bottom: 1rem;
`;

const StyledControlGroup = styled(ControlGroup)`
  & > .adresseEmail {
    margin-left: 0.5rem !important;
  }
`;

export interface IEmailReplaceDialogProps {
  dialogOpen: boolean;
  onClose: (replaced?: EmailReplaceDto) => void;
  idpersonne: number;
  currentEmail: EmailDto;
}

export const EmailReplaceDialog: React.FunctionComponent<IEmailReplaceDialogProps> = ({
  dialogOpen,
  onClose,
  idpersonne,
  currentEmail
}) => {
  const { t } = useTl();
  const api = useApiService(PersonneApi);

  const { data, loading, saveItem, saving } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () =>
          EmailReplaceDtoFromJSON({
            idpersonne,
            oldIdemail: currentEmail?.idemail,
            isNew: false
          }),
        saveApiFn: d => api.personneReplaceEmail({ EmailReplaceDto: d }),
        onSaved: d => onClose(d)
      }),
      [api, currentEmail?.idemail, idpersonne, onClose]
    )
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape({
        oldIdemail: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        newIdemail: Yup.number().nullable(),
        adresseEmail: Yup.string()
          .nullable()
          .when("isNew", {
            is: true,
            then: Yup.string()
              .required(t(ETLCodes.Required))
              .email(t(ETLCodes.InvalidEmail))
          }),
        idtypeEmail: Yup.number()
          .nullable()
          .when("isNew", {
            is: true,
            then: Yup.number().required(t(ETLCodes.Required))
          })
      }),
    [t]
  );

  const [typeEmails, teLoading] = useReferential(a => a.referentialGetTypesEmail());
  const [fullPersonneEmails, personneEmailsLoading] = useReferential(
    a => a.referentialGetEmailsPersonne({ idpersonne }),
    false,
    [idpersonne]
  );
  const personneEmails = React.useMemo(() => fullPersonneEmails?.filter(t => +t.value !== currentEmail.idemail), [
    currentEmail.idemail,
    fullPersonneEmails
  ]);

  const deleteEmail = React.useCallback(
    async (emailId: number) => {
      await api.personneDeleteEmail({ idemail: emailId });
      onClose(EmailReplaceDtoToJSON({ oldIdemail: emailId }));
    },
    [api, onClose]
  );

  return (
    <StyledDialog title={t(ETLCodes.EmailLinked)} isOpen={dialogOpen} onClose={() => onClose()}>
      <div className={Classes.DIALOG_BODY}>
        <StyledNonIdealState
          icon="warning-sign"
          description={t(ETLCodes.EmailUsedWarning, { adresseEmail: currentEmail.adresseEmail })}
        />
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          loading={loading}
          onCancel={() => onClose()}
          showDeleteButton={false}
          saving={saving}
        >
          <FGCustomPanel>
            {ctx => (
              <RadioGroup
                selectedValue={ctx?.formik?.values?.isNew ? "1" : "0"}
                onChange={e => {
                  if (e.currentTarget.value === "1") {
                    ctx.formik.setFieldValue("isNew", true);
                    ctx.formik.setFieldValue("newIdemail", null);
                  } else {
                    ctx.formik.setFieldValue("isNew", false);
                    ctx.formik.setFieldValue("adresseEmail", null);
                    ctx.formik.setFieldValue("idtypeEmail", null);
                  }
                }}
              >
                <Radio value="0" label={t(ETLCodes.ReplaceBy)}></Radio>
                <FGWalterSelectInput
                  noLabel
                  name="newIdemail"
                  items={personneEmails}
                  loading={personneEmailsLoading}
                  disabled={ctx?.formik?.values?.isNew}
                />
                <br />
                <Radio value="1" label={t(ETLCodes.New)}></Radio>
                <StyledControlGroup>
                  <FGWalterSelectInput
                    name="idtypeEmail"
                    noLabel
                    items={typeEmails}
                    loading={teLoading}
                    disabled={!ctx?.formik?.values?.isNew}
                  />
                  <FGTextInput
                    className="adresseEmail"
                    name="adresseEmail"
                    noLabel
                    maxLength={100}
                    disabled={!ctx?.formik?.values?.isNew}
                  />
                </StyledControlGroup>
                <br />
                <DeleteButton
                  text={t(ETLCodes.SupprimerLiens)}
                  onDelete={() => deleteEmail(ctx?.formik?.values?.oldIdemail)}
                  minimal={false}
                />
              </RadioGroup>
            )}
          </FGCustomPanel>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
