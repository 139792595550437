import { Checkbox } from "@blueprintjs/core";
import { DataTable, FieldSet, IDataTableColumn, useGridState } from "nsitools-react";
import * as React from "react";
import { RapportHopeApi, RapportHopeServicePermissionDto } from "../../../../../../api";
import { useApiService, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

export interface IRapportHopeServicePermissionsGridProps {
  idrapportHope: number;
  services: RapportHopeServicePermissionDto[];
  loading: boolean;
}

export const RapportHopeServicePermissionsGrid: React.FunctionComponent<IRapportHopeServicePermissionsGridProps> = ({
  idrapportHope,
  loading,
  services
}) => {
  const { t } = useTl();
  const api = useApiService(RapportHopeApi);

  const tableState = useGridState<RapportHopeServicePermissionDto>({
    serverMode: false,
    enablePagination: false,
    enableFilter: false
  });

  const { setData } = tableState;

  React.useEffect(() => {
    setData(services);
  }, [services, setData]);

  const toggleSelection = React.useCallback(
    (row: RapportHopeServicePermissionDto) => {
      row.selected = !row.selected;
      row.idrapportHope = idrapportHope;
      let nextData = [...services];
      setData(nextData);
      api.rapportHopeSaveServicePermissions({ RapportHopeServicePermissionDto: row });
    },
    [api, idrapportHope, services, setData]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        render: (item: RapportHopeServicePermissionDto) => (
          <Checkbox
            checked={item.selected}
            onChange={e => {
              toggleSelection(item);
            }}
          />
        )
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "nom"
      }
    ],
    [t, toggleSelection]
  );

  return (
    <FieldSet title={t(ETLCodes.Services)} collapsable defaultCollapsed>
      <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
    </FieldSet>
  );
};
