import * as React from "react";
import { useHistory } from "react-router";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { useAbortableApiServiceFactory, useApiService } from "../../../../../hooks/useApiService";
import { ETLCodes } from "../../../../../locales";
import {
  SecteurHomogeneApi,
  FcbSecteurHomogeneGridDto,
  SecteurHomogeneSearch,
  EBooleanSearchTypes
} from "../../../../../api";
import styled from "styled-components";
const Container = styled.div`
  display: flex;
`;

interface ISecteurClasseHomogeneListPageProps {}

export const SecteurClasseHomogeneListPage: React.FunctionComponent<ISecteurClasseHomogeneListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(SecteurHomogeneApi);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbSecteurHomogeneGridDto) => (
          <Container>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.secteurClasseHomogene}/${row.idsecteur}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.secteurClasseHomogene}/${row.idsecteur}/detail/edit`)}
            />
          </Container>
        )
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "libelle"
      },
      {
        header: () => t(ETLCodes.Stade),
        fieldName: "stade"
      }
    ],
    [history, t]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.secteurClasseHomogene}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(() => api.secteurHomogeneGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const apiFactory = useAbortableApiServiceFactory(SecteurHomogeneApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: SecteurHomogeneSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.secteurHomogeneBaseSearch({ SecteurHomogeneSearch: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      sortKeys={{ libelle: "ASC" }}
      columns={columns}
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      overrideListValues={{
        Idstade: e => e.displayValue!
      }}
      addFunc={addItemFunction}
      breadCrumbs={[{ text: t(ETLCodes.SecteursClasseHomogene), route: ERoutes.secteurClasseHomogene }]}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
    />
  );
};
