import * as React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { SocieteExterneApi, SocieteExterneGridDto, SocieteExterneSearchDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

const Container = styled.div`
  display: flex;
`;

export interface ISocieteExterneListProps {}

export const SocieteExterneList: React.FunctionComponent<ISocieteExterneListProps> = props => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(SocieteExterneApi);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: SocieteExterneGridDto) => (
          <Container>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.societeExterne}/${row.idsocieteExterne}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.societeExterne}/${row.idsocieteExterne}/detail/edit`)}
            />
          </Container>
        )
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Metiers),
        fieldName: "metiers",
        computed: true,
        render: (row: SocieteExterneGridDto) =>
          row.codesMetiers.length > 0 && (
            <span style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
              {row.codesMetiers.map((metier, i) => (
                <>
                  {metier}
                  {i < row.codesMetiers.length - 1 && ", "}
                </>
              ))}
            </span>
          )
      }
    ],
    [history, t]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.societeExterne}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(() => api.societeExterneGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const apiFactory = useAbortableApiServiceFactory(SocieteExterneApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: SocieteExterneSearchDto) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.societeExterneBaseSearch({ SocieteExterneSearchDto: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      columns={columns}
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      addFunc={addItemFunction}
      breadCrumbs={[{ text: t(ETLCodes.SocietesExternes), route: ERoutes.societeExterne }]}
      sortKeys={{ nom: "ASC" }}
    />
  );
};
