/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FcbEvaluationApprenantGetDto } from './FcbEvaluationApprenantGetDto';
import {
    FcbEvaluationApprenantGetDtoFromJSON,
    FcbEvaluationApprenantGetDtoFromJSONTyped,
    FcbEvaluationApprenantGetDtoToJSON,
} from './FcbEvaluationApprenantGetDto';

/**
 * 
 * @export
 * @interface FcbEvaluationApprenantListGetDto
 */
export interface FcbEvaluationApprenantListGetDto {
    /**
     * 
     * @type {boolean}
     * @memberof FcbEvaluationApprenantListGetDto
     */
    isFromWalter?: boolean;
    /**
     * 
     * @type {Array<FcbEvaluationApprenantGetDto>}
     * @memberof FcbEvaluationApprenantListGetDto
     */
    evaluationApprenantDtos?: Array<FcbEvaluationApprenantGetDto> | null;
}

/**
 * Check if a given object implements the FcbEvaluationApprenantListGetDto interface.
 */
export function instanceOfFcbEvaluationApprenantListGetDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbEvaluationApprenantListGetDtoFromJSON(json: any): FcbEvaluationApprenantListGetDto {
    return FcbEvaluationApprenantListGetDtoFromJSONTyped(json, false);
}

export function FcbEvaluationApprenantListGetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbEvaluationApprenantListGetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isFromWalter': !exists(json, 'isFromWalter') ? undefined : json['isFromWalter'],
        'evaluationApprenantDtos': !exists(json, 'evaluationApprenantDtos') ? undefined : (json['evaluationApprenantDtos'] === null ? null : (json['evaluationApprenantDtos'] as Array<any>).map(FcbEvaluationApprenantGetDtoFromJSON)),
    };
}

export function FcbEvaluationApprenantListGetDtoToJSON(value?: FcbEvaluationApprenantListGetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isFromWalter': value.isFromWalter,
        'evaluationApprenantDtos': value.evaluationApprenantDtos === undefined ? undefined : (value.evaluationApprenantDtos === null ? null : (value.evaluationApprenantDtos as Array<any>).map(FcbEvaluationApprenantGetDtoToJSON)),
    };
}

