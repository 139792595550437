/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConseillerPedagogiqueSearch,
  FcbConseillerPedagogiqueDto,
  FcbConseillerPedagogiqueGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    ConseillerPedagogiqueSearchFromJSON,
    ConseillerPedagogiqueSearchToJSON,
    FcbConseillerPedagogiqueDtoFromJSON,
    FcbConseillerPedagogiqueDtoToJSON,
    FcbConseillerPedagogiqueGridDtoPaginatedResultsFromJSON,
    FcbConseillerPedagogiqueGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface ConseillerPedagogiqueBaseSearchRequest {
    ConseillerPedagogiqueSearch?: ConseillerPedagogiqueSearch;
}

export interface ConseillerPedagogiqueCodeExistsRequest {
    code?: string;
}

export interface ConseillerPedagogiqueDeleteRequest {
    id?: string;
}

export interface ConseillerPedagogiqueGetConseillerPedagogiqueRequest {
    id?: string;
}

export interface ConseillerPedagogiqueGetDisplayNameRequest {
    id?: string;
}

export interface ConseillerPedagogiqueGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ConseillerPedagogiqueGetSelectItemsRequest {
    searchField: string;
}

export interface ConseillerPedagogiqueIsNoNationalUniqueRequest {
    noNational?: string;
}

export interface ConseillerPedagogiqueSaveRequest {
    FcbConseillerPedagogiqueDto?: FcbConseillerPedagogiqueDto;
}

export interface ConseillerPedagogiqueSearchConseillerIdsRequest {
    ConseillerPedagogiqueSearch?: ConseillerPedagogiqueSearch;
}

/**
 * 
 */
export class ConseillerPedagogiqueApi extends runtime.BaseAPI {

    /**
     */
    async conseillerPedagogiqueBaseSearchRaw(requestParameters: ConseillerPedagogiqueBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbConseillerPedagogiqueGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ConseillerPedagogique/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConseillerPedagogiqueSearchToJSON(requestParameters.ConseillerPedagogiqueSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbConseillerPedagogiqueGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async conseillerPedagogiqueBaseSearch(requestParameters: ConseillerPedagogiqueBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbConseillerPedagogiqueGridDtoPaginatedResults> {
        const response = await this.conseillerPedagogiqueBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async conseillerPedagogiqueCodeExistsRaw(requestParameters: ConseillerPedagogiqueCodeExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ConseillerPedagogique/codeExists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async conseillerPedagogiqueCodeExists(requestParameters: ConseillerPedagogiqueCodeExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.conseillerPedagogiqueCodeExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async conseillerPedagogiqueDeleteRaw(requestParameters: ConseillerPedagogiqueDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ConseillerPedagogique/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async conseillerPedagogiqueDelete(requestParameters: ConseillerPedagogiqueDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.conseillerPedagogiqueDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async conseillerPedagogiqueGetConseillerPedagogiqueRaw(requestParameters: ConseillerPedagogiqueGetConseillerPedagogiqueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbConseillerPedagogiqueDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ConseillerPedagogique`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbConseillerPedagogiqueDtoFromJSON(jsonValue));
    }

    /**
     */
    async conseillerPedagogiqueGetConseillerPedagogique(requestParameters: ConseillerPedagogiqueGetConseillerPedagogiqueRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbConseillerPedagogiqueDto> {
        const response = await this.conseillerPedagogiqueGetConseillerPedagogiqueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async conseillerPedagogiqueGetDisplayNameRaw(requestParameters: ConseillerPedagogiqueGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ConseillerPedagogique/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async conseillerPedagogiqueGetDisplayName(requestParameters: ConseillerPedagogiqueGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.conseillerPedagogiqueGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async conseillerPedagogiqueGetSearchCriteriasRaw(requestParameters: ConseillerPedagogiqueGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ConseillerPedagogique/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async conseillerPedagogiqueGetSearchCriterias(requestParameters: ConseillerPedagogiqueGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.conseillerPedagogiqueGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async conseillerPedagogiqueGetSelectItemsRaw(requestParameters: ConseillerPedagogiqueGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling conseillerPedagogiqueGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ConseillerPedagogique/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async conseillerPedagogiqueGetSelectItems(requestParameters: ConseillerPedagogiqueGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.conseillerPedagogiqueGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async conseillerPedagogiqueIsNoNationalUniqueRaw(requestParameters: ConseillerPedagogiqueIsNoNationalUniqueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.noNational !== undefined) {
            queryParameters['noNational'] = requestParameters.noNational;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ConseillerPedagogique/IsNoNationalUnique`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async conseillerPedagogiqueIsNoNationalUnique(requestParameters: ConseillerPedagogiqueIsNoNationalUniqueRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.conseillerPedagogiqueIsNoNationalUniqueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async conseillerPedagogiqueSaveRaw(requestParameters: ConseillerPedagogiqueSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbConseillerPedagogiqueDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ConseillerPedagogique/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbConseillerPedagogiqueDtoToJSON(requestParameters.FcbConseillerPedagogiqueDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbConseillerPedagogiqueDtoFromJSON(jsonValue));
    }

    /**
     */
    async conseillerPedagogiqueSave(requestParameters: ConseillerPedagogiqueSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbConseillerPedagogiqueDto> {
        const response = await this.conseillerPedagogiqueSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async conseillerPedagogiqueSearchConseillerIdsRaw(requestParameters: ConseillerPedagogiqueSearchConseillerIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ConseillerPedagogique/searchConseillerIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConseillerPedagogiqueSearchToJSON(requestParameters.ConseillerPedagogiqueSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async conseillerPedagogiqueSearchConseillerIds(requestParameters: ConseillerPedagogiqueSearchConseillerIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.conseillerPedagogiqueSearchConseillerIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
