import { Intent, Switch, Tooltip } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";

import { ContratApi, ContratAvenantGridDto, ContratAvenantSearchDto, ETypeContrat } from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import { AddButton, EditButton, ViewButton } from "../../../../../../components";
import { useApiService, useTheme, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

const StyledSwitch = styled(Switch)`
  margin-bottom: 0 !important;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export interface IContratAvenantsListProps {}

export const ContratAvenantsList: React.FunctionComponent<IContratAvenantsListProps> = props => {
  const { t } = useTl();
  const { push } = useHistory();
  const { ifapmeSide } = useTheme();
  const { id, type } = useParams<{ id: string; type: string }>();
  const idcontrat = React.useMemo(() => +id, [id]);
  const typeContrat = React.useMemo(() => type as ETypeContrat, [type]);
  const api = useApiService(ContratApi);
  const [showDeleted, setShowDeleted] = React.useState(false);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15
  });

  const mode = React.useMemo(
    () =>
      [
        ETypeContrat.CC,
        ETypeContrat.CA,
        ETypeContrat.CS,
        ETypeContrat.CF,
        ETypeContrat.DM,
        ETypeContrat.JI,
        ETypeContrat.PP
      ].includes(typeContrat)
        ? "hope"
        : "walter",
    [typeContrat]
  );

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: ContratAvenantSearchDto) => {
      sObj.idcontrat = idcontrat;
      sObj.showDeleted = showDeleted;
      return api.contratSearchAvenants({ ContratAvenantSearchDto: sObj });
    },
    [api, idcontrat, showDeleted]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const buttons = React.useMemo(
    () => (
      <ButtonsContainer>
        <StyledSwitch
          label={t(ETLCodes.AfficherSupprimés)}
          checked={showDeleted}
          onChange={e => {
            setShowDeleted(e.currentTarget.checked);
          }}
        />
        {ifapmeSide === mode && (
          <AddButton
            onClick={async e => {
              e.stopPropagation();
              push(`${ERoutes.contrat}/${idcontrat}/avenants/${type}/edit/-1`);
            }}
            text={t(ETLCodes.AddAvenant)}
            intent={Intent.PRIMARY}
          />
        )}
      </ButtonsContainer>
    ),
    [t, showDeleted, ifapmeSide, mode, push, idcontrat, type]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ContratAvenantGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => window.open(`#${ERoutes.contrat}/${idcontrat}/avenants/${type}/view/${row.idavenant}`)}
            />
            <EditButton
              minimal={true}
              onClick={() => window.open(`#${ERoutes.contrat}/${idcontrat}/avenants/${type}/edit/${row.idavenant}`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Types),
        autoFitContent: true,
        computed: true,
        fieldName: "types",
        render: (row: ContratAvenantGridDto) => {
          if (row.types?.length > 0) {
            return (
              <span style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                {row.types.length > 0 && row.types[0]}
                {row.types.length > 1 && (
                  <span>
                    &nbsp;
                    <Tooltip
                      content={
                        <div>
                          {row.types.map((type, i) => (
                            <div key={i}>{type}</div>
                          ))}
                        </div>
                      }
                      position="right"
                    >
                      <div style={{ cursor: "pointer" }}>...</div>
                    </Tooltip>
                  </span>
                )}
              </span>
            );
          } else {
            return <div></div>;
          }
        }
      },
      {
        header: () => t(ETLCodes.Numero),
        fieldName: "numero"
      },
      {
        header: () => t(ETLCodes.DatePriseEnCours),
        fieldName: "datePriseEnCours"
      },
      {
        header: () => t(ETLCodes.DateSignature),
        fieldName: "dateSignature"
      },
      {
        header: () => t(ETLCodes.LieuSignature),
        fieldName: "lieuSignature"
      }
    ],
    [t, idcontrat, type]
  );

  return (
    <FieldSet title={t(ETLCodes.TableResults, { count: totalCount })} rightElement={buttons}>
      <DataTable
        dateFormat="dd/MM/yyyy"
        tableState={tableState}
        loading={loading}
        columns={columns}
        filterMode="OnEnter"
      ></DataTable>
    </FieldSet>
  );
};
