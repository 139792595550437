/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AzureUserDto,
  BooleanDto,
  FcbApprenantDto,
  FcbFormateurDto,
} from '../models/index';
import {
    AzureUserDtoFromJSON,
    AzureUserDtoToJSON,
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FcbApprenantDtoFromJSON,
    FcbApprenantDtoToJSON,
    FcbFormateurDtoFromJSON,
    FcbFormateurDtoToJSON,
} from '../models/index';

export interface AzureCreateApprenantRequest {
    FcbApprenantDto?: FcbApprenantDto;
}

export interface AzureCreateFormateurRequest {
    FcbFormateurDto?: FcbFormateurDto;
}

export interface AzureEnableApprenantRequest {
    id?: number;
    o365UserId?: string;
    accountEnabled?: boolean;
}

export interface AzureEnableFormateurRequest {
    id?: number;
    o365UserId?: string;
    accountEnabled?: boolean;
}

export interface AzureGetAsyncRequest {
    o365UserId?: string;
}

/**
 * 
 */
export class AzureApi extends runtime.BaseAPI {

    /**
     */
    async azureCreateApprenantRaw(requestParameters: AzureCreateApprenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AzureUserDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Azure/CreateApprenant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbApprenantDtoToJSON(requestParameters.FcbApprenantDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AzureUserDtoFromJSON(jsonValue));
    }

    /**
     */
    async azureCreateApprenant(requestParameters: AzureCreateApprenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AzureUserDto> {
        const response = await this.azureCreateApprenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async azureCreateFormateurRaw(requestParameters: AzureCreateFormateurRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AzureUserDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Azure/CreateFormateur`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbFormateurDtoToJSON(requestParameters.FcbFormateurDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AzureUserDtoFromJSON(jsonValue));
    }

    /**
     */
    async azureCreateFormateur(requestParameters: AzureCreateFormateurRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AzureUserDto> {
        const response = await this.azureCreateFormateurRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async azureEnableApprenantRaw(requestParameters: AzureEnableApprenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.o365UserId !== undefined) {
            queryParameters['o365UserId'] = requestParameters.o365UserId;
        }

        if (requestParameters.accountEnabled !== undefined) {
            queryParameters['accountEnabled'] = requestParameters.accountEnabled;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Azure/EnableApprenant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async azureEnableApprenant(requestParameters: AzureEnableApprenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.azureEnableApprenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async azureEnableFormateurRaw(requestParameters: AzureEnableFormateurRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.o365UserId !== undefined) {
            queryParameters['o365UserId'] = requestParameters.o365UserId;
        }

        if (requestParameters.accountEnabled !== undefined) {
            queryParameters['accountEnabled'] = requestParameters.accountEnabled;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Azure/EnableFormateur`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async azureEnableFormateur(requestParameters: AzureEnableFormateurRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.azureEnableFormateurRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async azureGetAsyncRaw(requestParameters: AzureGetAsyncRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AzureUserDto>> {
        const queryParameters: any = {};

        if (requestParameters.o365UserId !== undefined) {
            queryParameters['o365UserId'] = requestParameters.o365UserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Azure`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AzureUserDtoFromJSON(jsonValue));
    }

    /**
     */
    async azureGetAsync(requestParameters: AzureGetAsyncRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AzureUserDto> {
        const response = await this.azureGetAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
