/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbClasseHoraireIncoherenceGridDto
 */
export interface FcbClasseHoraireIncoherenceGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbClasseHoraireIncoherenceGridDto
     */
    idclasse?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbClasseHoraireIncoherenceGridDto
     */
    jour?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseHoraireIncoherenceGridDto
     */
    heureDebut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseHoraireIncoherenceGridDto
     */
    heureFin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseHoraireIncoherenceGridDto
     */
    local?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseHoraireIncoherenceGridDto
     */
    formateur?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseHoraireIncoherenceGridDto
     */
    incoherence?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseHoraireIncoherenceGridDto
     */
    typeIncoherence?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseHoraireIncoherenceGridDto
     */
    idtypeIncoherence?: number | null;
}

/**
 * Check if a given object implements the FcbClasseHoraireIncoherenceGridDto interface.
 */
export function instanceOfFcbClasseHoraireIncoherenceGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbClasseHoraireIncoherenceGridDtoFromJSON(json: any): FcbClasseHoraireIncoherenceGridDto {
    return FcbClasseHoraireIncoherenceGridDtoFromJSONTyped(json, false);
}

export function FcbClasseHoraireIncoherenceGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbClasseHoraireIncoherenceGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'jour': !exists(json, 'jour') ? undefined : (json['jour'] === null ? null : new Date(json['jour'])),
        'heureDebut': !exists(json, 'heureDebut') ? undefined : json['heureDebut'],
        'heureFin': !exists(json, 'heureFin') ? undefined : json['heureFin'],
        'local': !exists(json, 'local') ? undefined : json['local'],
        'formateur': !exists(json, 'formateur') ? undefined : json['formateur'],
        'incoherence': !exists(json, 'incoherence') ? undefined : json['incoherence'],
        'typeIncoherence': !exists(json, 'typeIncoherence') ? undefined : json['typeIncoherence'],
        'idtypeIncoherence': !exists(json, 'idtypeIncoherence') ? undefined : json['idtypeIncoherence'],
    };
}

export function FcbClasseHoraireIncoherenceGridDtoToJSON(value?: FcbClasseHoraireIncoherenceGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idclasse': value.idclasse,
        'jour': value.jour === undefined ? undefined : (value.jour === null ? null : value.jour.toISOString()),
        'heureDebut': value.heureDebut,
        'heureFin': value.heureFin,
        'local': value.local,
        'formateur': value.formateur,
        'incoherence': value.incoherence,
        'typeIncoherence': value.typeIncoherence,
        'idtypeIncoherence': value.idtypeIncoherence,
    };
}

