import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { SpecificiteCoursApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { SpecificiteCoursDetailPage } from "./SpecificiteCoursDetailPage";

interface ISpecificiteCoursItemPageProps {}

export const SpecificiteCoursItemPage: React.FunctionComponent<ISpecificiteCoursItemPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();

  const specificiteCoursApi = useApiService(SpecificiteCoursApi);
  const [displayName] = useApiEffect(() => specificiteCoursApi.specificiteCoursGetDisplayName({ id: id }), [id]);
  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.SpecificiteCours), route: ERoutes.specificiteCours },
        { text: id === "-1" ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabDetail"
        onChange={newTabId => history.push(`${ERoutes.specificiteCours}/${id}/${newTabId}`)}
        renderActiveTabPanelOnly
        selectedTabId={tab}
      >
        <BackButton backRoute={ERoutes.specificiteCours}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<SpecificiteCoursDetailPage codeSpecificite={id} editMode={state === "edit"} />}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
