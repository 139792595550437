import { Button, Colors, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useSet } from "ahooks";
import {
  ButtonsBloc,
  DataTable,
  FGCustomPanel,
  FGTextInput,
  FieldGroup,
  FieldSet,
  InlineButtonContainer,
  useGridState,
  useSearchApi
} from "nsitools-react";
import * as React from "react";
import styled, { css } from "styled-components";

import { AddButton, SelectButton, SmallFormGenerator } from "../..";
import {
  ETextSearchType,
  LieuFormationApi,
  LieuFormationSelectorGridDto,
  LieuFormationSelectorSearch,
  LieuFormationSelectorSearchFromJSON
} from "../../../api";
import { ERoutes } from "../../../AppRouter";
import { useApiService, useTabMessage, useTheme, useTl } from "../../../hooks";
import { ETLCodes } from "../../../locales";
import { Guid } from "../../../utils/guid";

const Container = styled.div`
  margin: 1rem 1rem 0 1rem;
`;

export interface ILieuFormationSelectorProps {
  excludedLieuFormationIds?: number[];
  onLieuFormationSelected: (selectedLieuFormationIds?: number[]) => void;
  rightElement?: any;
  allowMultipleSelection?: boolean;
  preSelectedIds?: number[];
  idsiegeSocial?: number;
  sppMode?: boolean;
}

export const LieuFormationSelector: React.FunctionComponent<ILieuFormationSelectorProps> = ({
  excludedLieuFormationIds,
  onLieuFormationSelected,
  rightElement,
  allowMultipleSelection = false,
  preSelectedIds = [],
  idsiegeSocial,
  sppMode = false
}) => {
  const { t } = useTl();
  const { theme } = useTheme();
  const [selectedLieuFormationIds, { add: addLieuFormationId, remove: removeLieuFormationId }] = useSet<number>(
    preSelectedIds
  );
  const api = useApiService(LieuFormationApi);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [10, 15],
    pageSize: 10,
    sortKeys: { nom: "ASC", enseigne: "ASC" }
  });

  const initialData = React.useMemo(() => {
    return LieuFormationSelectorSearchFromJSON({
      selectedLieuFormationIds,
      excludedLieuFormationIds: excludedLieuFormationIds ?? [],
      idsiegeSocial,
      forceSkip: 0,
      forceTake: 10
    });
  }, [excludedLieuFormationIds, idsiegeSocial, selectedLieuFormationIds]);

  const { totalCount } = tableState;
  const searchFunction = React.useCallback(
    (sObj?: LieuFormationSelectorSearch) => {
      let nextSObj = { ...sObj };
      Object.keys(sObj)
        .filter(k => !["forceSkip", "forceTake", "filter", "sortKey", "aggregateKeys", "parameters"].includes(k))
        .forEach(k => {
          nextSObj[k] = !!sObj[k]?.value ? { ...sObj[k], searchMode: ETextSearchType.Contains } : undefined;
        });
      return api.lieuFormationSearchSelector({
        LieuFormationSelectorSearch: {
          ...nextSObj,
          idsiegeSocial,
          selectedLieuFormationIds: Array.from(selectedLieuFormationIds),
          excludedLieuFormationIds: excludedLieuFormationIds ?? []
        }
      });
    },
    [api, excludedLieuFormationIds, idsiegeSocial, selectedLieuFormationIds]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const selectLieuFormation = React.useCallback(
    (id: number) => {
      if (allowMultipleSelection) {
        selectedLieuFormationIds.has(id) ? removeLieuFormationId(id) : addLieuFormationId(id);
      } else {
        onLieuFormationSelected([id]);
      }
    },
    [
      addLieuFormationId,
      allowMultipleSelection,
      onLieuFormationSelected,
      removeLieuFormationId,
      selectedLieuFormationIds
    ]
  );

  const onRowClick = React.useCallback(
    (item: LieuFormationSelectorGridDto) => {
      selectLieuFormation(item.idlieuFormation);
    },
    [selectLieuFormation]
  );

  const identifier = React.useMemo(() => Guid.newGuid(), []);
  const { messageValue, clearMessageValue } = useTabMessage(identifier);
  const add = React.useCallback(() => {
    window.open(`#${ERoutes.lieuFormation}/0/detail/edit?cfs=${identifier}`, "_blank");
  }, [identifier]);

  React.useEffect(() => {
    if (messageValue) {
      search();
      selectLieuFormation(messageValue);
      clearMessageValue();
    }
  }, [clearMessageValue, messageValue, search, selectLieuFormation]);

  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Contact),
        fieldName: "contact"
      },
      {
        header: () => t(ETLCodes.Telephone),
        fieldName: "telephone"
      },
      {
        header: () => t(ETLCodes.Email),
        fieldName: "email"
      },
      {
        header: () => t(ETLCodes.Enseigne),
        fieldName: "enseigne"
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      }
    ],
    [t]
  );

  const customizeRowStyleCallback = React.useCallback(
    (item: LieuFormationSelectorGridDto) => {
      const isSelected = selectedLieuFormationIds.has(item.idlieuFormation);

      return css`
        & * {
          color: ${isSelected ? Colors.WHITE : Colors.BLACK} !important;
        }

        & & > td {
          background-color: ${isSelected ? theme.primaryColor : "initial"} !important;
        }

        background-color: ${isSelected ? theme.primaryColor : "transparent"} !important;
      `;
    },
    [selectedLieuFormationIds, theme.primaryColor]
  );

  const onLieuFormationSelect = React.useCallback(() => {
    onLieuFormationSelected(Array.from(selectedLieuFormationIds));
  }, [onLieuFormationSelected, selectedLieuFormationIds]);

  const finalRightElements = React.useMemo(() => {
    return allowMultipleSelection ? (
      <>
        <SelectButton onClick={onLieuFormationSelect}></SelectButton>
        {rightElement}
      </>
    ) : (
      rightElement
    );
  }, [allowMultipleSelection, onLieuFormationSelect, rightElement]);

  const tableTitle = React.useMemo(
    () =>
      `${t(ETLCodes.TableResults, { count: totalCount })}${
        selectedLieuFormationIds.size > 0
          ? " - " + selectedLieuFormationIds.size + " " + t(ETLCodes.SelectedLieuxFormation)
          : ""
      }`,
    [selectedLieuFormationIds.size, t, totalCount]
  );

  return (
    <Container>
      <SmallFormGenerator
        enableDirtyCheck={false}
        initialValues={initialData}
        onSubmit={search}
        editMode
        hideButtons={true}
      >
        <FieldGroup columns={2}>
          <FieldGroup>
            <FGTextInput name="nom.value" label={t(ETLCodes.Nom)} maxLength={100} />
            <FGTextInput name="telephone.value" label={t(ETLCodes.Tel)} maxLength={20} />
            <FGTextInput name="email.value" label={t(ETLCodes.General_Email)} maxLength={100} />
          </FieldGroup>
          <FieldGroup>
            <FGTextInput name="contact.value" label={t(ETLCodes.Contact)} maxLength={100} />
            <FGTextInput name="enseigne.value" label={t(ETLCodes.Enseigne)} maxLength={50} />
            <FGTextInput name="localite.value" label={t(ETLCodes.Localite)} maxLength={50} />
          </FieldGroup>
        </FieldGroup>
        <FieldGroup>
          <FGCustomPanel>
            {ctx => (
              <InlineButtonContainer>
                <ButtonsBloc></ButtonsBloc>
                <ButtonsBloc>
                  <Button icon={IconNames.SEARCH} type="submit" text={t(ETLCodes.Search)} intent={Intent.PRIMARY} />
                  <AddButton onClick={add} text={t(ETLCodes.General_Add)} intent={Intent.PRIMARY} minimal={false} />
                </ButtonsBloc>
              </InlineButtonContainer>
            )}
          </FGCustomPanel>
        </FieldGroup>
        <FieldSet title={tableTitle} rightElement={finalRightElements}>
          <DataTable
            dateFormat="dd-MM-yyyy"
            tableState={tableState}
            loading={loading}
            columns={columns}
            onRowClick={onRowClick}
            customizeRowStyle={customizeRowStyleCallback}
            htmlTableOptions={{ striped: true, interactive: true, bordered: true }}
          />
        </FieldSet>
      </SmallFormGenerator>
    </Container>
  );
};
