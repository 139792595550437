/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbChangementClasseDto
 */
export interface FcbChangementClasseDto {
    /**
     * 
     * @type {number}
     * @memberof FcbChangementClasseDto
     */
    idinscription?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbChangementClasseDto
     */
    classesource?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbChangementClasseDto
     */
    classecible?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbChangementClasseDto
     */
    classecibleName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbChangementClasseDto
     */
    datechangement?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbChangementClasseDto
     */
    idchangementClasse?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbChangementClasseDto
     */
    datePrecedenteEntree?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbChangementClasseDto
     */
    isEditable?: boolean;
}

/**
 * Check if a given object implements the FcbChangementClasseDto interface.
 */
export function instanceOfFcbChangementClasseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbChangementClasseDtoFromJSON(json: any): FcbChangementClasseDto {
    return FcbChangementClasseDtoFromJSONTyped(json, false);
}

export function FcbChangementClasseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbChangementClasseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idinscription': !exists(json, 'idinscription') ? undefined : json['idinscription'],
        'classesource': !exists(json, 'classesource') ? undefined : json['classesource'],
        'classecible': !exists(json, 'classecible') ? undefined : json['classecible'],
        'classecibleName': !exists(json, 'classecibleName') ? undefined : json['classecibleName'],
        'datechangement': !exists(json, 'datechangement') ? undefined : (json['datechangement'] === null ? null : new Date(json['datechangement'])),
        'idchangementClasse': !exists(json, 'idchangementClasse') ? undefined : json['idchangementClasse'],
        'datePrecedenteEntree': !exists(json, 'datePrecedenteEntree') ? undefined : (json['datePrecedenteEntree'] === null ? null : new Date(json['datePrecedenteEntree'])),
        'isEditable': !exists(json, 'isEditable') ? undefined : json['isEditable'],
    };
}

export function FcbChangementClasseDtoToJSON(value?: FcbChangementClasseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idinscription': value.idinscription,
        'classesource': value.classesource,
        'classecible': value.classecible,
        'classecibleName': value.classecibleName,
        'datechangement': value.datechangement === undefined ? undefined : (value.datechangement === null ? null : value.datechangement.toISOString()),
        'idchangementClasse': value.idchangementClasse,
        'datePrecedenteEntree': value.datePrecedenteEntree === undefined ? undefined : (value.datePrecedenteEntree === null ? null : value.datePrecedenteEntree.toISOString()),
        'isEditable': value.isEditable,
    };
}

