/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExportExcelParameterDto } from './ExportExcelParameterDto';
import {
    ExportExcelParameterDtoFromJSON,
    ExportExcelParameterDtoFromJSONTyped,
    ExportExcelParameterDtoToJSON,
} from './ExportExcelParameterDto';

/**
 * 
 * @export
 * @interface ExportExcelInfoDto
 */
export interface ExportExcelInfoDto {
    /**
     * 
     * @type {Array<ExportExcelParameterDto>}
     * @memberof ExportExcelInfoDto
     */
    parameters?: Array<ExportExcelParameterDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ExportExcelInfoDto
     */
    idExport?: number;
}

/**
 * Check if a given object implements the ExportExcelInfoDto interface.
 */
export function instanceOfExportExcelInfoDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExportExcelInfoDtoFromJSON(json: any): ExportExcelInfoDto {
    return ExportExcelInfoDtoFromJSONTyped(json, false);
}

export function ExportExcelInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportExcelInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : (json['parameters'] === null ? null : (json['parameters'] as Array<any>).map(ExportExcelParameterDtoFromJSON)),
        'idExport': !exists(json, 'idExport') ? undefined : json['idExport'],
    };
}

export function ExportExcelInfoDtoToJSON(value?: ExportExcelInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': value.parameters === undefined ? undefined : (value.parameters === null ? null : (value.parameters as Array<any>).map(ExportExcelParameterDtoToJSON)),
        'idExport': value.idExport,
    };
}

