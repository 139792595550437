import { FGCustomPanel, FGListen, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { FcbFormateurDiplomeDtoFromJSON, FichierApi, FormateurDiplomeApi } from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import {
  FGWalterDateMaskInput,
  FGWalterFileInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

export interface IActiviteProfessionelleDiplomePageProps {}

export const ActiviteProfessionelleDiplomePage: React.FunctionComponent<IActiviteProfessionelleDiplomePageProps> = () => {
  const { id, idDiplome, state } = useParams<{ id: string; idDiplome: string; state: string }>();
  const { t } = useTl();
  const api = useApiService(FormateurDiplomeApi);
  const history = useHistory();

  const [type, loadingType] = useReferential(a => a.referentialGetTypesDiplome(), false, []);
  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      libelle: Yup.string().required(t(ETLCodes.Required)),
      type: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idDiplome <= 0
        ? FcbFormateurDiplomeDtoFromJSON({ idformateur: +id })
        : api.formateurDiplomeGet({ id: +idDiplome }),
    saveApiFn: d => api.formateurDiplomeSave({ FcbFormateurDiplomeDto: d }),
    onSavedRoute: d => `${ERoutes.formateur}/${d.idformateur}/activitesProfessionnelles`,
    deleteApiFn: d => api.formateurDiplomeDelete({ id: d.iddiplome }),
    onDeletedRoute: () => `${ERoutes.formateur}/${+id}/activitesProfessionnelles`
  });

  const fapi = useApiService(FichierApi);
  const downloadFn = React.useCallback(async (idfichier: number) => await fapi.fichierDownload({ id: idfichier }), [
    fapi
  ]);

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        showColons
        editMode={state === "edit"}
        minLabelWidth={180}
        labelAlignment="right"
        formatDate="dd/MM/yyyy"
        validationSchema={FormSchema}
        inline
        boldLabel
        loading={loading}
        onCancel={() => history.push(`${ERoutes.formateur}/${+id}/activitesProfessionnelles`)}
        onDelete={deleteItem}
        showDeleteButton={+idDiplome > 0}
        saving={saving}
        deleting={deleting}
      >
        <FGListen
          field="fichier"
          onChanged={(value, formik) => {
            if (formik.dirty) {
              formik.setFieldValue("idfichier", null);
            }
          }}
        />
        <FieldGroup columns={1}>
          <FGTextInput name="libelle" label={t(ETLCodes.Libelle)} maxLength={100} />
          <FGWalterDateMaskInput name="date" label={t(ETLCodes.Date)} />
          <FGTextInput name="url" label={t(ETLCodes.URL)} maxLength={100} />
          <FGWalterSelectInput name="type" label={t(ETLCodes.Statut)} items={type} loading={loadingType} />
          <FGCustomPanel>
            {({ formik }) => (
              <FGWalterFileInput
                name="fichier"
                label={t(ETLCodes.Document)}
                downloadFn={formik.values?.idfichier && (() => downloadFn(formik.values?.idfichier))}
              />
            )}
          </FGCustomPanel>
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
