import { FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { FcbTypeEnseignementDtoFromJSON, TypeEnseignementApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { SmallFormGenerator, FGWalterCheckboxInput } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface ITypeEnseignementDetailPageProps {
  editMode: boolean;
  idTypeEnseignement: number;
}

export const TypeEnseignementDetailPage: React.FunctionComponent<ITypeEnseignementDetailPageProps> = ({
  editMode,
  idTypeEnseignement
}) => {
  const { t } = useTl();
  const api = useApiService(TypeEnseignementApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idTypeEnseignement <= 0
        ? FcbTypeEnseignementDtoFromJSON({ idTypeEnseignement: 0 })
        : api.typeEnseignementGet({ id: +idTypeEnseignement }),
    saveApiFn: d => api.typeEnseignementSave({ FcbTypeEnseignementDto: d }),
    onSavedRoute: d => `${ERoutes.typeEnseignement}/${d.idtypeEnseignement}/detail/edit`,
    deleteApiFn: d => api.typeEnseignementDelete({ id: d.idtypeEnseignement }),
    onDeletedRoute: () => ERoutes.typeEnseignement
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      libelle: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={editMode}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.typeEnseignement)}
      onDelete={deleteItem}
      showDeleteButton={+idTypeEnseignement > 0}
      saving={saving}
      deleting={deleting}
    >
      <FieldGroup>
        <FGTextInput name="libelle" label={t(ETLCodes.Libelle)} maxLength={75} />
        <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
