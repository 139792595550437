import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";

import { TypeEnseignementDetailPage } from "..";
import { TypeEnseignementApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

interface ITypeEnseignementItemPageProps {}

export const TypeEnseignementItemPage: React.FunctionComponent<ITypeEnseignementItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const idTypeEnseignement = React.useMemo(() => +id, [id]);
  const history = useHistory();

  const typeEnseignement = useApiService(TypeEnseignementApi);
  const [displayName] = useApiEffect(
    () => typeEnseignement.typeEnseignementGetDisplayName({ id: idTypeEnseignement }),
    [id]
  );

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.TypesEnseignement), route: ERoutes.typeEnseignement },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="Tabs"
        onChange={newTabId => history.push(`${ERoutes.typeEnseignement}/${id}/${newTabId}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.typeEnseignement}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<TypeEnseignementDetailPage idTypeEnseignement={idTypeEnseignement} editMode={state === "edit"} />}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
