/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbReportCentrePermissionDto
 */
export interface FcbReportCentrePermissionDto {
    /**
     * 
     * @type {boolean}
     * @memberof FcbReportCentrePermissionDto
     */
    selected?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbReportCentrePermissionDto
     */
    idimpression?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbReportCentrePermissionDto
     */
    idcentre?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbReportCentrePermissionDto
     */
    nom?: string | null;
}

/**
 * Check if a given object implements the FcbReportCentrePermissionDto interface.
 */
export function instanceOfFcbReportCentrePermissionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbReportCentrePermissionDtoFromJSON(json: any): FcbReportCentrePermissionDto {
    return FcbReportCentrePermissionDtoFromJSONTyped(json, false);
}

export function FcbReportCentrePermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbReportCentrePermissionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selected': !exists(json, 'selected') ? undefined : json['selected'],
        'idimpression': !exists(json, 'idimpression') ? undefined : json['idimpression'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
    };
}

export function FcbReportCentrePermissionDtoToJSON(value?: FcbReportCentrePermissionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selected': value.selected,
        'idimpression': value.idimpression,
        'idcentre': value.idcentre,
        'nom': value.nom,
    };
}

