import { Checkbox, Spinner } from "@blueprintjs/core";
import { ButtonContainer, IDataTableColumn, showError } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";

import { ChefEntrepriseApi, ChefEntrepriseGridDto, ChefEntrepriseSearchDto } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { EditButton, SearchTablePage, SmsButton, ViewButton } from "../../../../components";
import { useRouteParamsContext } from "../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface IChefEntrepriseListPageProps {}

export const ChefEntrepriseListPage: React.FunctionComponent<IChefEntrepriseListPageProps> = props => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ChefEntrepriseApi);
  const [selectedIDs, setSelectedIDs] = React.useState<number[]>([]);
  const lastSearchObject = React.useRef<ChefEntrepriseSearchDto>();
  const { pushSmsRecipientIds, pushSmsRecipientType } = useRouteParamsContext();
  const [selectAllLoading, setSelectAllLoading] = React.useState(false);
  const [nbReturnedIds, setNbReturnedIds] = React.useState(0);

  const apiFactory = useAbortableApiServiceFactory(ChefEntrepriseApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (nextSearch?: ChefEntrepriseSearchDto) => {
      if (JSON.stringify(nextSearch) !== JSON.stringify(lastSearchObject.current)) {
        lastSearchObject.current = nextSearch;
      }
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.chefEntrepriseBaseSearch({ ChefEntrepriseSearchDto: nextSearch });
    },
    [apiFactory]
  );

  const selectAll = React.useCallback(() => {
    setSelectAllLoading(true);
    api
      .chefEntrepriseSearchChefEntrepriseIds({
        ChefEntrepriseSearchDto: { ...lastSearchObject.current, forceSkip: 0, forceTake: 999999 }
      })
      .then(allIds => {
        if (allIds.length === selectedIDs.length) {
          setSelectedIDs([]);
          setNbReturnedIds(0);
        } else {
          setSelectedIDs(allIds);
          setNbReturnedIds(allIds.length);
        }
        setSelectAllLoading(false);
      });
  }, [api, selectedIDs.length]);

  const toggleSelection = React.useCallback(
    (item: ChefEntrepriseGridDto) => {
      if (!selectedIDs.includes(item.idchefEntreprise)) {
        setSelectedIDs(ids => [...ids, item.idchefEntreprise]);
      } else {
        setSelectedIDs(ids => ids.filter(id => id !== item.idchefEntreprise));
      }
    },
    [selectedIDs]
  );

  const sendSms = React.useCallback(() => {
    if (selectedIDs.length > 0) {
      pushSmsRecipientIds(selectedIDs);
      // pushSmsRecipientType(ETypeDestinataireSms.ChefEntreprise);
      history.push(`${ERoutes.sms}/0/detail/edit`);
    } else {
      showError(t(ETLCodes.SelectAtLeastOne));
    }
  }, [history, pushSmsRecipientIds, selectedIDs, t]);

  const sendSmsSingle = React.useCallback(
    (id: number) => {
      pushSmsRecipientIds([id]);
      // pushSmsRecipientType(ETypeDestinataireSms.ChefEntreprise);
      history.push(`${ERoutes.sms}/0/detail/edit`);
    },
    [history, pushSmsRecipientIds]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        header: () =>
          selectAllLoading ? (
            <Spinner size={20} />
          ) : (
            <Checkbox checked={nbReturnedIds > 0 && nbReturnedIds === selectedIDs.length} onChange={selectAll} />
          ),
        render: (item: ChefEntrepriseGridDto) => (
          <Checkbox
            checked={selectedIDs.includes(item.idchefEntreprise)}
            onChange={e => {
              toggleSelection(item);
            }}
          />
        )
      },
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ChefEntrepriseGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.chefEntreprise}/${row.idchefEntreprise}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.chefEntreprise}/${row.idchefEntreprise}/detail/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.DernierSiegeSocial),
        fieldName: "nomLastSiegeSocial",
        render: (row: ChefEntrepriseGridDto) => row.lastSiegeSocial?.denominationSociale
      },
      {
        header: () => t(ETLCodes.FonctionDernierSiegeSocial),
        fieldName: "fonctionLastSiegeSocial",
        render: (row: ChefEntrepriseGridDto) => row.lastSiegeSocial?.fonction
      },
      {
        header: () => t(ETLCodes.Email),
        fieldName: "email"
      },
      {
        header: () => t(ETLCodes.Gsm),
        fieldName: "gsm",
        render: (value: ChefEntrepriseGridDto) => (
          <SmsButton phone={value.gsm} onClick={() => sendSmsSingle(value.idchefEntreprise)} />
        )
      }
    ],
    [history, nbReturnedIds, selectAll, selectAllLoading, selectedIDs, sendSmsSingle, t, toggleSelection]
  );

  const getCriteria = React.useCallback(() => api.chefEntrepriseGetSearchCriterias({ includeListsValues: false }), [
    api
  ]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteria}
      searchFunction={search}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.ChefEntreprise), route: ERoutes.chefEntreprise }]}
      searchStateInitialSearch={false}
      keyFieldName="idchefEntreprise"
      buttons={[
        {
          icon: "chat",
          text: t(ETLCodes.SendAnSMSToSelected),
          onClick: sendSms,
          disabled: true
        }
      ]}
    />
  );
};
