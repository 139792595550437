/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { TextSearch } from './TextSearch';
import {
    TextSearchFromJSON,
    TextSearchFromJSONTyped,
    TextSearchToJSON,
} from './TextSearch';
import type { UnaccentTextSearch } from './UnaccentTextSearch';
import {
    UnaccentTextSearchFromJSON,
    UnaccentTextSearchFromJSONTyped,
    UnaccentTextSearchToJSON,
} from './UnaccentTextSearch';

/**
 * 
 * @export
 * @interface ContactSearch
 */
export interface ContactSearch {
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof ContactSearch
     */
    nom?: UnaccentTextSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof ContactSearch
     */
    prenom?: UnaccentTextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof ContactSearch
     */
    telephone?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof ContactSearch
     */
    gsm?: TextSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof ContactSearch
     */
    siegeSocial?: UnaccentTextSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof ContactSearch
     */
    lieuFormation?: UnaccentTextSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof ContactSearch
     */
    enseigneLieuFormation?: UnaccentTextSearch;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContactSearch
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof ContactSearch
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof ContactSearch
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContactSearch
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof ContactSearch
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSearch
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the ContactSearch interface.
 */
export function instanceOfContactSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContactSearchFromJSON(json: any): ContactSearch {
    return ContactSearchFromJSONTyped(json, false);
}

export function ContactSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nom': !exists(json, 'nom') ? undefined : UnaccentTextSearchFromJSON(json['nom']),
        'prenom': !exists(json, 'prenom') ? undefined : UnaccentTextSearchFromJSON(json['prenom']),
        'telephone': !exists(json, 'telephone') ? undefined : TextSearchFromJSON(json['telephone']),
        'gsm': !exists(json, 'gsm') ? undefined : TextSearchFromJSON(json['gsm']),
        'siegeSocial': !exists(json, 'siegeSocial') ? undefined : UnaccentTextSearchFromJSON(json['siegeSocial']),
        'lieuFormation': !exists(json, 'lieuFormation') ? undefined : UnaccentTextSearchFromJSON(json['lieuFormation']),
        'enseigneLieuFormation': !exists(json, 'enseigneLieuFormation') ? undefined : UnaccentTextSearchFromJSON(json['enseigneLieuFormation']),
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function ContactSearchToJSON(value?: ContactSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nom': UnaccentTextSearchToJSON(value.nom),
        'prenom': UnaccentTextSearchToJSON(value.prenom),
        'telephone': TextSearchToJSON(value.telephone),
        'gsm': TextSearchToJSON(value.gsm),
        'siegeSocial': UnaccentTextSearchToJSON(value.siegeSocial),
        'lieuFormation': UnaccentTextSearchToJSON(value.lieuFormation),
        'enseigneLieuFormation': UnaccentTextSearchToJSON(value.enseigneLieuFormation),
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

