import * as React from "react";
import { Dialog, Classes, Button } from "@blueprintjs/core";
import { useTl } from "../hooks/useTl";
import { ETLCodes } from "../locales/ETLCodes";
import { useDialog, DialogResultButtonType } from "../contexts";

export const DialogRoot: React.FunctionComponent = () => {
  const { dialogData, hideDialog } = useDialog();

  const isShown = React.useMemo(() => {
    return !!dialogData;
  }, [dialogData]);

  const message = dialogData ? dialogData.message : "";
  const title = dialogData ? dialogData.title || "Confirm" : "";

  const { t } = useTl();

  const finalButtons = React.useMemo<DialogResultButtonType[]>(() => {
    if (dialogData && dialogData.buttons) return dialogData.buttons;
    return [
      {
        intent: "danger",
        text: t(ETLCodes.General_No),
        resultType: "no"
      },
      {
        intent: "success",
        text: t(ETLCodes.General_Yes),
        resultType: "yes"
      }
    ];
  }, [dialogData, t]);

  return (
    <Dialog
      isOpen={isShown}
      title={title}
      className={""}
      canOutsideClickClose={true}
      onClose={() => {
        hideDialog("cancel");
      }}
      style={{ zIndex: 99999 }}
      portalClassName="alert-dialog"
      enforceFocus={false}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>{message}</p>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {finalButtons.map((bt, i) => {
            const { resultType, ...btProps } = bt;
            return <Button key={i} {...btProps} onClick={() => hideDialog(resultType)}></Button>;
          })}
        </div>
      </div>
    </Dialog>
  );
};
