/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AgrementSearch,
  FcbAgrementDto,
  FcbAgrementGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    AgrementSearchFromJSON,
    AgrementSearchToJSON,
    FcbAgrementDtoFromJSON,
    FcbAgrementDtoToJSON,
    FcbAgrementGridDtoPaginatedResultsFromJSON,
    FcbAgrementGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface AgrementBaseSearchRequest {
    AgrementSearch?: AgrementSearch;
}

export interface AgrementDeleteRequest {
    id?: number;
}

export interface AgrementGetRequest {
    id?: number;
}

export interface AgrementGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface AgrementGetSelectItemsRequest {
    searchField: string;
}

export interface AgrementSaveRequest {
    FcbAgrementDto?: FcbAgrementDto;
}

/**
 * 
 */
export class AgrementApi extends runtime.BaseAPI {

    /**
     */
    async agrementBaseSearchRaw(requestParameters: AgrementBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbAgrementGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Agrement/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgrementSearchToJSON(requestParameters.AgrementSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbAgrementGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async agrementBaseSearch(requestParameters: AgrementBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbAgrementGridDtoPaginatedResults> {
        const response = await this.agrementBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementDeleteRaw(requestParameters: AgrementDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Agrement`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async agrementDelete(requestParameters: AgrementDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.agrementDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementGetRaw(requestParameters: AgrementGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbAgrementDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Agrement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbAgrementDtoFromJSON(jsonValue));
    }

    /**
     */
    async agrementGet(requestParameters: AgrementGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbAgrementDto> {
        const response = await this.agrementGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementGetSearchCriteriasRaw(requestParameters: AgrementGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Agrement/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async agrementGetSearchCriterias(requestParameters: AgrementGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.agrementGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementGetSelectItemsRaw(requestParameters: AgrementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling agrementGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Agrement/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async agrementGetSelectItems(requestParameters: AgrementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.agrementGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementSaveRaw(requestParameters: AgrementSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbAgrementDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Agrement`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbAgrementDtoToJSON(requestParameters.FcbAgrementDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbAgrementDtoFromJSON(jsonValue));
    }

    /**
     */
    async agrementSave(requestParameters: AgrementSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbAgrementDto> {
        const response = await this.agrementSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
