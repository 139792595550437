import { Colors } from "@blueprintjs/core";
import { format } from "date-fns";
import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { css } from "styled-components";

import {
  StatutApprenantApi,
  StatutApprenantEditDto,
  StatutApprenantEditDtoFromJSON,
  StatutApprenantGridDto,
  StatutApprenantSearch
} from "../../../../../api";
import { AddStatutPostSortieButton, EditButton, EditStatutApprenantDialog } from "../../../../../components";
import { useEventsContext } from "../../../../../contexts";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IApprenantStatutListProps {}

export const ApprenantStatutList: React.FunctionComponent<IApprenantStatutListProps> = props => {
  const { t, tUnsafe } = useTl();
  const { id } = useParams<{ id: string }>();
  const apprenantId = React.useMemo(() => +id, [id]);
  const api = useApiService(StatutApprenantApi);
  const [statutApprenantEditOpen, setStatutApprenantEditOpen] = React.useState<StatutApprenantEditDto>(null);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { dateStatut: "DESC", creationDate: "DESC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: StatutApprenantSearch) => {
      sObj.idApprenant = apprenantId;
      sObj.fromCrea = false;
      return api.statutApprenantBaseSearch({ StatutApprenantSearch: sObj });
    },
    [api, apprenantId]
  );

  const { loading, search } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });
  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();
  React.useEffect(() => {
    subscribeToEvent("SEARCH_TABLE_REFRESH", search);
    return () => unsubscribeEvent("SEARCH_TABLE_REFRESH", search);
  }, [search, subscribeToEvent, unsubscribeEvent]);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: StatutApprenantGridDto) => (
          <ButtonContainer>
            <EditButton
              minimal={true}
              onClick={() =>
                setStatutApprenantEditOpen(
                  StatutApprenantEditDtoFromJSON({
                    idApprenant: row.idApprenant,
                    idStatutApprenant: row.idStatutApprenant
                  })
                )
              }
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Date),
        fieldName: "dateStatut",
        render: (row: StatutApprenantGridDto) =>
          `${format(row.dateStatut, "dd/MM/yyyy")}${row.sortieAnnulee ? " (" + t(ETLCodes.SortieAnnulee) + ")" : ""}
          ${row.postSortie ? " (" + t(ETLCodes.PostFormation) + ")" : ""}`
      },
      {
        header: () => t(ETLCodes.Categorie),
        fieldName: "categorie",
        render: (row: StatutApprenantGridDto) => tUnsafe(`CategorieStatutApprenant_${row.categorie}`)
      },
      {
        header: () => t(ETLCodes.Statut),
        fieldName: "categorieStatutSocial"
      },
      {
        header: () => t(ETLCodes.SousStatut),
        fieldName: "statutSocial"
      },
      {
        header: () => t(ETLCodes.DureeInoccupation),
        fieldName: "dureeInoccupation"
      },
      {
        header: () => t(ETLCodes.TypeSortie),
        fieldName: "typeSortie"
      },
      {
        header: () => t(ETLCodes.MotifSortie),
        fieldName: "motifSortie"
      },
      {
        header: () => t(ETLCodes.Par),
        fieldName: "creationUser"
      },
      {
        header: () => t(ETLCodes.Remarque),
        fieldName: "remarque",
        render: (row: StatutApprenantGridDto) => (row.sortieAnnulee ? row.remarqueRefus : row.remarque)
      }
    ],
    [t, tUnsafe]
  );

  const fetchIsSorti = React.useCallback(() => api.statutApprenantIsSorti({ idapprenant: apprenantId }), [
    api,
    apprenantId
  ]);
  const { data: isSorti } = useQuery([!"apprenant-is-sorti", apprenantId], fetchIsSorti, { cacheTime: 0 });

  const addPostSortieButton = React.useMemo(
    () =>
      isSorti?.value && (
        <AddStatutPostSortieButton
          idApprenant={apprenantId}
          onClosed={refresh => {
            if (refresh) search();
          }}
        />
      ),
    [apprenantId, search, isSorti]
  );

  const onStatutApprenantEditClose = React.useCallback(
    (toRefresh: boolean) => {
      setStatutApprenantEditOpen(null);
      if (toRefresh) {
        search();
      }
    },
    [search]
  );

  return (
    <FieldSet
      title={t(ETLCodes.HistoriqueChangement) + ": " + t(ETLCodes.TableResults, { count: totalCount })}
      rightElement={addPostSortieButton}
    >
      <DataTable
        loading={loading}
        dateFormat="dd/MM/yyyy"
        tableState={tableState}
        columns={columns}
        customizeRowStyle={(row: StatutApprenantGridDto) => css`
          & * {
            color: ${row.sortieAnnulee ? Colors.RED3 + " !important" : null};
          }
        `}
      />
      {!!statutApprenantEditOpen && (
        <EditStatutApprenantDialog
          apprenantData={statutApprenantEditOpen}
          isDialogOpen={!!statutApprenantEditOpen}
          onClose={onStatutApprenantEditClose}
        />
      )}
    </FieldSet>
  );
};

export default ApprenantStatutList;
