/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EnveloppeProjetFinancementSearch,
  FcbEnveloppeProjetFinancementDto,
  FcbEnveloppeProjetFinancementGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    EnveloppeProjetFinancementSearchFromJSON,
    EnveloppeProjetFinancementSearchToJSON,
    FcbEnveloppeProjetFinancementDtoFromJSON,
    FcbEnveloppeProjetFinancementDtoToJSON,
    FcbEnveloppeProjetFinancementGridDtoPaginatedResultsFromJSON,
    FcbEnveloppeProjetFinancementGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface EnveloppeProjetFinancementBaseSearchRequest {
    EnveloppeProjetFinancementSearch?: EnveloppeProjetFinancementSearch;
}

export interface EnveloppeProjetFinancementDeleteRequest {
    id?: number;
}

export interface EnveloppeProjetFinancementGetRequest {
    id?: number;
}

export interface EnveloppeProjetFinancementGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface EnveloppeProjetFinancementGetSelectItemsRequest {
    searchField: string;
}

export interface EnveloppeProjetFinancementSaveRequest {
    FcbEnveloppeProjetFinancementDto?: FcbEnveloppeProjetFinancementDto;
}

/**
 * 
 */
export class EnveloppeProjetFinancementApi extends runtime.BaseAPI {

    /**
     */
    async enveloppeProjetFinancementBaseSearchRaw(requestParameters: EnveloppeProjetFinancementBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbEnveloppeProjetFinancementGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/EnveloppeProjetFinancement/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EnveloppeProjetFinancementSearchToJSON(requestParameters.EnveloppeProjetFinancementSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbEnveloppeProjetFinancementGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async enveloppeProjetFinancementBaseSearch(requestParameters: EnveloppeProjetFinancementBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbEnveloppeProjetFinancementGridDtoPaginatedResults> {
        const response = await this.enveloppeProjetFinancementBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async enveloppeProjetFinancementDeleteRaw(requestParameters: EnveloppeProjetFinancementDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/EnveloppeProjetFinancement`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async enveloppeProjetFinancementDelete(requestParameters: EnveloppeProjetFinancementDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.enveloppeProjetFinancementDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async enveloppeProjetFinancementGetRaw(requestParameters: EnveloppeProjetFinancementGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbEnveloppeProjetFinancementDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/EnveloppeProjetFinancement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbEnveloppeProjetFinancementDtoFromJSON(jsonValue));
    }

    /**
     */
    async enveloppeProjetFinancementGet(requestParameters: EnveloppeProjetFinancementGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbEnveloppeProjetFinancementDto> {
        const response = await this.enveloppeProjetFinancementGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async enveloppeProjetFinancementGetSearchCriteriasRaw(requestParameters: EnveloppeProjetFinancementGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/EnveloppeProjetFinancement/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async enveloppeProjetFinancementGetSearchCriterias(requestParameters: EnveloppeProjetFinancementGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.enveloppeProjetFinancementGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async enveloppeProjetFinancementGetSelectItemsRaw(requestParameters: EnveloppeProjetFinancementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling enveloppeProjetFinancementGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/EnveloppeProjetFinancement/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async enveloppeProjetFinancementGetSelectItems(requestParameters: EnveloppeProjetFinancementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.enveloppeProjetFinancementGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async enveloppeProjetFinancementSaveRaw(requestParameters: EnveloppeProjetFinancementSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbEnveloppeProjetFinancementDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/EnveloppeProjetFinancement`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbEnveloppeProjetFinancementDtoToJSON(requestParameters.FcbEnveloppeProjetFinancementDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbEnveloppeProjetFinancementDtoFromJSON(jsonValue));
    }

    /**
     */
    async enveloppeProjetFinancementSave(requestParameters: EnveloppeProjetFinancementSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbEnveloppeProjetFinancementDto> {
        const response = await this.enveloppeProjetFinancementSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
