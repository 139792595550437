import * as React from "react";
import { useHistory } from "react-router";

import { SuiviApprenantForm } from "../..";
import { SuiviApi, SuiviHopeMultipleEditDtoFromJSON } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { useAuth, useRouteParamsContext } from "../../../../contexts";
import { useApiService, useCrudApi, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface IApprenantMultipleSuiviPageProps {}

export const ApprenantMultipleSuiviPage: React.FunctionComponent<IApprenantMultipleSuiviPageProps> = props => {
  const { t } = useTl();
  const { user } = useAuth();
  const history = useHistory();
  const api = useApiService(SuiviApi);
  const { popSelectedApprenantIds } = useRouteParamsContext();
  const [selectedIds] = React.useState(() => popSelectedApprenantIds());

  const { data, saveItem, saving, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: async () => {
          return SuiviHopeMultipleEditDtoFromJSON({
            idsuivi: 0,
            idsApprenant: selectedIds ?? [],
            iduserService: user.iduser,
            encodeur: user.nom + " " + user.prenom,
            dateSuivi: new Date()
          });
        },
        saveApiFn: d => api.suiviSaveSuiviHopeMultiple({ SuiviHopeMultipleEditDto: d }),
        onSavedRoute: d => ERoutes.apprenant
      }),
      [selectedIds, user.iduser, user.nom, user.prenom, api]
    )
  );

  return (
    <SuiviApprenantForm
      data={data}
      suiviId={-1}
      saveItem={saveItem}
      saving={saving}
      editMode={true}
      onCancel={() => history.push(ERoutes.suivi)}
      validationErrors={validationErrors}
      loading={false}
      fieldsetTitle={t(ETLCodes.CreateSuiviForNbApprenants, { count: selectedIds?.length ?? 0 })}
    />
  );
};
