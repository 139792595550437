import { Breadcrumbs, IBreadcrumbsProps } from "@blueprintjs/core";
import * as React from "react";

import { useTheme } from "../hooks";

interface IAppBreadcrumbProps extends Pick<IBreadcrumbsProps, "items"> {}

export const AppBreadcrumb: React.FunctionComponent<IAppBreadcrumbProps> = ({ items }) => {
  const { theme } = useTheme();

  React.useEffect(() => {
    if (items) {
      document.title =
        items
          .filter(i => !!i.text && typeof i.text === "string")
          .map(i => i.text)
          .join(" > ") +
        " - " +
        theme.headerName;
    } else {
      document.title = theme.headerName;
    }
  }, [items, theme.headerName]);

  return <Breadcrumbs items={items}></Breadcrumbs>;
};
