/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CodePostalSearch,
  FcbCodePostalDto,
  FcbCodePostalGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    CodePostalSearchFromJSON,
    CodePostalSearchToJSON,
    FcbCodePostalDtoFromJSON,
    FcbCodePostalDtoToJSON,
    FcbCodePostalGridDtoPaginatedResultsFromJSON,
    FcbCodePostalGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface CodePostalBaseSearchRequest {
    CodePostalSearch?: CodePostalSearch;
}

export interface CodePostalDeleteRequest {
    id?: number;
}

export interface CodePostalGetRequest {
    id?: number;
}

export interface CodePostalGetDisplayNameRequest {
    id?: number;
}

export interface CodePostalGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface CodePostalGetSelectItemsRequest {
    searchField: string;
}

export interface CodePostalSaveRequest {
    FcbCodePostalDto?: FcbCodePostalDto;
}

export interface CodePostalSearchCodePostalIdsRequest {
    CodePostalSearch?: CodePostalSearch;
}

/**
 * 
 */
export class CodePostalApi extends runtime.BaseAPI {

    /**
     */
    async codePostalBaseSearchRaw(requestParameters: CodePostalBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbCodePostalGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/CodePostal/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CodePostalSearchToJSON(requestParameters.CodePostalSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbCodePostalGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async codePostalBaseSearch(requestParameters: CodePostalBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbCodePostalGridDtoPaginatedResults> {
        const response = await this.codePostalBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async codePostalDeleteRaw(requestParameters: CodePostalDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/CodePostal`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async codePostalDelete(requestParameters: CodePostalDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.codePostalDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async codePostalGetRaw(requestParameters: CodePostalGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbCodePostalDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/CodePostal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbCodePostalDtoFromJSON(jsonValue));
    }

    /**
     */
    async codePostalGet(requestParameters: CodePostalGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbCodePostalDto> {
        const response = await this.codePostalGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async codePostalGetAllRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FcbCodePostalDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/CodePostal/GetAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FcbCodePostalDtoFromJSON));
    }

    /**
     */
    async codePostalGetAll(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FcbCodePostalDto>> {
        const response = await this.codePostalGetAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async codePostalGetDisplayNameRaw(requestParameters: CodePostalGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/CodePostal/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async codePostalGetDisplayName(requestParameters: CodePostalGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.codePostalGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async codePostalGetSearchCriteriasRaw(requestParameters: CodePostalGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/CodePostal/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async codePostalGetSearchCriterias(requestParameters: CodePostalGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.codePostalGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async codePostalGetSelectItemsRaw(requestParameters: CodePostalGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling codePostalGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/CodePostal/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async codePostalGetSelectItems(requestParameters: CodePostalGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.codePostalGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async codePostalSaveRaw(requestParameters: CodePostalSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbCodePostalDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/CodePostal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbCodePostalDtoToJSON(requestParameters.FcbCodePostalDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbCodePostalDtoFromJSON(jsonValue));
    }

    /**
     */
    async codePostalSave(requestParameters: CodePostalSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbCodePostalDto> {
        const response = await this.codePostalSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async codePostalSearchCodePostalIdsRaw(requestParameters: CodePostalSearchCodePostalIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/CodePostal/searchCodePostalIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CodePostalSearchToJSON(requestParameters.CodePostalSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async codePostalSearchCodePostalIds(requestParameters: CodePostalSearchCodePostalIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.codePostalSearchCodePostalIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
