import * as React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";

import { DoublonsStateProvider, DoublonsTabs } from ".";
import { DoublonEditDto, PersonneApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { PageBase } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { nameof } from "../../../../utils";

export const AP_FINAL_KEY = nameof<DoublonEditDto>("apprenantFinal");
export const CE_FINAL_KEY = nameof<DoublonEditDto>("chefEntrepriseFinal");
export const CO_FINAL_KEY = nameof<DoublonEditDto>("contactFinal");
export const FO_FINAL_KEY = nameof<DoublonEditDto>("formateurFinal");
export const RE_FINAL_KEY = nameof<DoublonEditDto>("representantFinal");
export const TU_FINAL_KEY = nameof<DoublonEditDto>("tuteurFinal");
export const PE_FINAL_KEY = nameof<DoublonEditDto>("personneFinal");

export interface IDoublonsItemPageProps {}

export const DoublonsItemPage: React.FunctionComponent<IDoublonsItemPageProps> = props => {
  const { t } = useTl();
  const { id } = useParams<{ id: string }>();
  const idpersonne1 = React.useMemo(() => +id.split("-")[0], [id]);
  const idpersonne2 = React.useMemo(() => +id.split("-")[1], [id]);

  const api = useApiService(PersonneApi);
  const fetchDisplayName1 = React.useCallback(() => {
    return api.personneGetDisplayName({ id: idpersonne1 });
  }, [api, idpersonne1]);
  const { data: displayName1 } = useQuery(["doublons-display-name-1", idpersonne1], fetchDisplayName1);

  const fetchDisplayName2 = React.useCallback(() => {
    return api.personneGetDisplayName({ id: idpersonne2 });
  }, [api, idpersonne2]);
  const { data: displayName2 } = useQuery(["doublons-display-name-2", idpersonne2], fetchDisplayName2);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.Doublons), route: ERoutes.doublon },
        { text: `${displayName1} - ${displayName2}` }
      ]}
      fullHeight
    >
      <DoublonsStateProvider idpersonne1={idpersonne1} idpersonne2={idpersonne2}>
        <DoublonsTabs />
      </DoublonsStateProvider>
    </PageBase>
  );
};
