import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ProfessionFormateurDetailPage } from "..";
import { ProfessionFormateurApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

interface IProfessionFormateurItemPageProps {}

export const ProfessionFormateurItemPage: React.FunctionComponent<IProfessionFormateurItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();

  const professionFormateurApi = useApiService(ProfessionFormateurApi);
  const [displayName] = useApiEffect(() => professionFormateurApi.professionFormateurGetDisplayName({ id: +id }), [id]);
  const history = useHistory();

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.ProfessionsFormateurs), route: ERoutes.professionFormateur },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsProfessionFormateur"
        onChange={newTabId => history.push(`${ERoutes.professionFormateur}/${id}/${newTabId}/${state}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.professionFormateur}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<ProfessionFormateurDetailPage editMode={state === "edit"} idProfession={+id} />}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
