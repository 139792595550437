import { ButtonContainer, FGMaskInput, FGTextInput, showInfo, showSuccess, useApiEffect } from "nsitools-react";
import * as React from "react";
import * as Yup from "yup";

import { ReportApi } from "../../../../api";
import { DownloadButton, FGWalterDateMaskInput, SmallFormGenerator } from "../../../../components";
import { usePrintingQueueContext } from "../../../../contexts";
import { useApiService, useManageError, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface IRapportFormProps {
  selected: string;
}

export const RapportForm: React.FunctionComponent<IRapportFormProps> = ({ selected }) => {
  const api = useApiService(ReportApi);
  const { t } = useTl();
  const { manageError } = useManageError();
  const [loading, setLoading] = React.useState(false);
  const { refreshQueue, setIsQueueForcedOpen } = usePrintingQueueContext();

  const [data, dataLoading] = useApiEffect(() => {
    return api.reportGetReportParameters({ reportName: selected });
  }, [selected]);

  const ExecuteQuery = React.useCallback(
    async c => {
      setLoading(true);
      const parameters: { [name: string]: string } = {};
      Object.entries(c).forEach((value, key) => {
        if (value[0] !== "parameters") {
          parameters[value[0]] = value[1] as string;
        }
      });
      c.parameters.forEach((p, i) => {
        if (parameters[p.name] || parameters[p.name] === "0") {
          c.parameters[i] = { ...c.parameters[i], value: parameters[p.name] };
        } else {
          c.parameters[i] = { ...c.parameters[i], value: null };
        }
      });

      try {
        showInfo(t(ETLCodes.AddingReportToGenerationQueue));
        await api.reportSendToQueue({
          reportName: selected,
          ReportParametersDto: { parameters: c.parameters }
        });
        refreshQueue();
        setIsQueueForcedOpen(true);
        showSuccess(t(ETLCodes.ReportAddedToQueue));
      } catch (e) {
        console.error(e);
        manageError(e, t(ETLCodes.ErrorWhileAddingReportToQueue));
      } finally {
        setLoading(false);
      }
    },
    [api, manageError, refreshQueue, selected, setIsQueueForcedOpen, t]
  );

  const FormSchema = React.useMemo(() => {
    const shape: { [key: string]: any } = {};
    if (data && data.parameters) {
      for (let i = 0; i < data.parameters.length; i++) {
        let type = null;
        switch (data.parameters[i].dataType) {
          case "NumberParameter":
            type = Yup.number();
            break;
          case "StringParameter":
            type = Yup.string();
            break;
          case "DateParameter":
            type = Yup.date();
            break;
        }
        shape[data.parameters[i].name] = data.parameters[i].required ? type.required(t(ETLCodes.Required)) : type;
      }
    }
    return Yup.object().shape(shape);
  }, [data, t]);

  return (
    <SmallFormGenerator
      loading={dataLoading}
      initialValues={data}
      onSubmit={ExecuteQuery}
      editMode={true}
      disabled={loading}
      showDeleteButton={false}
      saving={false}
      deleting={false}
      minLabelWidth={400}
      hideButtons={true}
      validationSchema={FormSchema}
    >
      {data?.parameters.map((value, index) => {
        if (value.dataType === "NumberParameter") {
          return (
            <FGMaskInput
              key={value.name}
              name={value.name}
              label={value.prompt}
              cleaveOptions={{
                numericOnly: true
              }}
              labelStyles={value.required ? { color: "red" } : {}}
            />
          );
        }
        if (value.dataType === "StringParameter") {
          return (
            <FGTextInput
              key={value.name}
              name={value.name}
              label={value.prompt}
              labelStyles={value.required ? { color: "red" } : {}}
            />
          );
        }
        if (value.dataType === "DateParameter") {
          return (
            <FGWalterDateMaskInput
              key={value.name}
              name={value.name}
              label={value.prompt}
              labelStyles={value.required ? { color: "red" } : {}}
            />
          );
        }
        return <></>;
      })}
      <ButtonContainer>
        <DownloadButton minimal={false} intent={"primary"} loading={loading} />
      </ButtonContainer>
    </SmallFormGenerator>
  );
};
