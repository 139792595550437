import { ITabProps, Tab } from "@blueprintjs/core";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router";

import { ProspectApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { AcquisSwitch, ApprenantDocuments, ApprenantHistory, ApprenantSuivi } from "../../apprenant";
import { ApprenantStatut } from "../../apprenant/item/statut/ApprenantStatut";
import { ProspectParcoursCreaSwitch } from "./crea/ProspectParcoursCreaSwitch";
import { ProspectSignaletiqueSwitch } from "./signaletique/ProspectSignaletiqueSwitch";

export interface IProspectItemPageProps {}

export const ProspectItemPage: React.FunctionComponent<IProspectItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state } = useParams<{ id: string; tab: string; state: string }>();
  const idProspect = React.useMemo(() => +id, [id]);
  const history = useHistory();
  const { search } = useLocation();

  const prospectApi = useApiService(ProspectApi);
  const fetchIdPersonne = React.useCallback(() => {
    return prospectApi.prospectGetIdPersonne({ id: +id });
  }, [prospectApi, id]);
  const { data: idpersonne } = useQuery(["idpersonne-apprenant", id], fetchIdPersonne);

  const fetchDisplayName = React.useCallback(() => {
    return prospectApi.prospectGetDisplayName({ id: +id });
  }, [prospectApi, id]);
  const { data: displayName } = useQuery(["displayname-prospect", id], fetchDisplayName);

  const tabs = React.useMemo<ITabProps[]>(
    () => [
      {
        id: "signaletique",
        title: t(ETLCodes.Signaletique),
        panel: <ProspectSignaletiqueSwitch />
      },
      {
        id: "crea",
        title: t(ETLCodes.ParcoursCrea),
        panel: <ProspectParcoursCreaSwitch />,
        disabled: idProspect <= 0
      },
      {
        id: "acquis",
        title: t(ETLCodes.Acquis),
        panel: <AcquisSwitch baseRoute={ERoutes.prospect} idpersonne={idpersonne} />,
        disabled: idProspect <= 0
      },
      {
        id: "suivis",
        title: t(ETLCodes.Suivis),
        panel: <ApprenantSuivi baseRoute={ERoutes.prospect} />,
        disabled: idProspect <= 0
      },
      {
        id: "documents",
        title: t(ETLCodes.Documents),
        panel: <ApprenantDocuments />,
        disabled: idProspect <= 0
      },
      {
        id: "statutMotifs",
        title: t(ETLCodes.StatutsMotifs),
        panel: <ApprenantStatut />,
        disabled: idProspect <= 0
      },
      {
        id: "history",
        title: t(ETLCodes.History),
        panel: <ApprenantHistory />,
        disabled: idProspect <= 0
      }
    ],
    [idProspect, idpersonne, t]
  );

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.PorteursDeProjets), route: ERoutes.prospect },
        { text: +id > 0 ? displayName : t(ETLCodes.New) }
      ]}
    >
      <ProtectedTabs
        id="TabsProspect"
        onChange={newTabId =>
          history.push(
            `${ERoutes.prospect}/${id}/${newTabId}/${state !== "edit" && state !== "view" ? "view" : state}${search}`
          )
        }
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.prospect}></BackButton>
        {tabs.map(t => (
          <Tab {...t} />
        ))}
      </ProtectedTabs>
    </PageBase>
  );
};
