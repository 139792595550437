import * as React from "react";
import { InscriptionSuspensionApi, InscriptionSuspensionMultipleDto } from "../../../../../api";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import * as Yup from "yup";
import { ETLCodes } from "../../../../../locales";
import styled from "styled-components";
import { Classes, Dialog } from "@blueprintjs/core";
import { FGWalterDateMaskInput, SmallFormGenerator } from "../../../../../components";
import { FGTextAreaInput, FieldGroup } from "nsitools-react";

const StyledDialog = styled(Dialog)`
  width: 500px;
  height: auto;
  background-color: white;
`;

export interface IInscriptionSuspensionMultipleDialogProps {
  dialogOpen: boolean;
  onClose: (toSave?: InscriptionSuspensionMultipleDto) => void;
  currentSuspension: InscriptionSuspensionMultipleDto;
  endMode?: boolean;
}

export const InscriptionSuspensionMultipleDialog: React.FunctionComponent<IInscriptionSuspensionMultipleDialogProps> = ({
  currentSuspension,
  dialogOpen,
  onClose,
  endMode = false
}) => {
  const { t } = useTl();
  const api = useApiService(InscriptionSuspensionApi);

  const { data, saveItem, saving, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () => currentSuspension,
        saveApiFn: async d => {
          await api.inscriptionSuspensionSaveInscriptionSuspensionMultiple({ InscriptionSuspensionMultipleDto: d });
          return currentSuspension;
        },
        onSaved: d => onClose(d),
        serverValidationRootKey: "Dto"
      }),
      [api, currentSuspension, onClose]
    )
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape(
        {
          dateDebut: endMode
            ? Yup.date().nullable()
            : Yup.date()
                .nullable()
                .when("dateFin", (value, schema) =>
                  !!value ? schema.max(Yup.ref("dateFin"), t(ETLCodes.DateDebutGreaterThanDateFin)) : schema
                )
                .required(t(ETLCodes.Required)),
          dateFin: endMode
            ? Yup.date()
                .nullable()
                .required(t(ETLCodes.Required))
            : Yup.date()
                .nullable()
                .when("dateFin", (value, schema) =>
                  !!value ? schema.min(Yup.ref("dateDebut"), t(ETLCodes.DateFinGreaterThanDateDebut)) : schema
                )
        },
        [["dateFin", "dateFin"]]
      ),
    [endMode, t]
  );

  return (
    <StyledDialog title={t(ETLCodes.SuspensionInscription)} isOpen={dialogOpen} onClose={() => onClose()}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose()}
          showDeleteButton={false}
          saving={saving}
          minLabelWidth={100}
          validationErrors={validationErrors}
          preventDefaultSaveOnEnter
        >
          <FieldGroup>
            {<FGWalterDateMaskInput name="dateDebut" label={t(ETLCodes.DateDebut)} visible={!endMode} />}
            <FGWalterDateMaskInput name="dateFin" label={t(ETLCodes.DateFin)} />
            <FGTextAreaInput name="remarque" label={t(ETLCodes.Remarque)} />
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
