import { Tab, Tag } from "@blueprintjs/core";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";

import {
  LieuxFormationListPage,
  SiegeSocialAssuranceList,
  SiegeSocialChefEntrepriseSwitch,
  SiegeSocialHistory,
  SiegeSocialSignaletique,
  SiegeSocialSuiviSwitch
} from ".";
import { SiegeSocialApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useEventsContext } from "../../../../contexts";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { SiegeSocialContactSwitch } from "./contacts/SiegeSocialContactSwitch";
import { SiegeSocialDocuments } from "./documents";

const TabTitleContainer = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 5px;
  }
`;

export interface ISiegeSocialItemPageProps {}

export const SiegeSocialItemPage: React.FunctionComponent<ISiegeSocialItemPageProps> = props => {
  const { t } = useTl();
  const { id, tab, state } = useParams<{ id: string; tab: string; state: string }>();
  const idSiegeSocial = React.useMemo(() => +id, [id]);
  const history = useHistory();

  const siegeSocialApi = useApiService(SiegeSocialApi);

  const fetchDisplayName = React.useCallback(() => {
    return siegeSocialApi.siegeSocialGetDisplayName({ id: +id });
  }, [id, siegeSocialApi]);
  const { data: displayName } = useQuery(["siege-social-display-name", id], fetchDisplayName);
  const onTabChange = React.useCallback(
    newTabId => {
      history.push(`${ERoutes.siegeSocial}/${id}/${newTabId}/${state !== "checkDoublons" ? state : "view"}`);
    },
    [history, id, state]
  );

  const fetchNbChefsEntreprise = React.useCallback(() => {
    return siegeSocialApi.siegeSocialGetChefsEntrepriseCount({ idsiegeSocial: idSiegeSocial });
  }, [idSiegeSocial, siegeSocialApi]);
  const { data: nbChefsEntreprise, isFetching: nbChefsEntrepriseLoading, refetch: refetchNbChefsEntreprise } = useQuery(
    ["nb-chefs-entreprise", idSiegeSocial],
    fetchNbChefsEntreprise
  );

  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();
  React.useEffect(() => {
    subscribeToEvent("REFRESH_NB_TABS", refetchNbChefsEntreprise);
    return () => unsubscribeEvent("REFRESH_NB_TABS", refetchNbChefsEntreprise);
  }, [refetchNbChefsEntreprise, subscribeToEvent, unsubscribeEvent]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.SiegeSocial), route: ERoutes.siegeSocial },
        { text: +id > 0 ? displayName : t(ETLCodes.New) }
      ]}
    >
      <ProtectedTabs id="TabsSiegeSocial" onChange={onTabChange} selectedTabId={tab} renderActiveTabPanelOnly>
        <BackButton backRoute={ERoutes.siegeSocial}></BackButton>
        <Tab id="detail" title={t(ETLCodes.Signaletique)} panel={<SiegeSocialSignaletique />} />
        <Tab
          id="chefsEntreprise"
          title={
            <TabTitleContainer>
              <span>{t(ETLCodes.ChefsEntreprise)}</span>
              {!nbChefsEntrepriseLoading && (
                <Tag round intent="primary">
                  {nbChefsEntreprise}
                </Tag>
              )}
            </TabTitleContainer>
          }
          panel={<SiegeSocialChefEntrepriseSwitch />}
          disabled={idSiegeSocial <= 0}
        />
        <Tab
          id="lieuxFormation"
          title={t(ETLCodes.LieuxFormations)}
          panel={<LieuxFormationListPage />}
          disabled={idSiegeSocial <= 0}
        />
        <Tab
          id="contacts"
          title={t(ETLCodes.Contacts)}
          panel={<SiegeSocialContactSwitch />}
          disabled={idSiegeSocial <= 0}
        />
        <Tab id="suivi" title={t(ETLCodes.Suivi)} panel={<SiegeSocialSuiviSwitch />} disabled={idSiegeSocial <= 0} />
        <Tab id="history" title={t(ETLCodes.History)} panel={<SiegeSocialHistory />} disabled={idSiegeSocial <= 0} />
        <Tab
          id="assurances"
          title={t(ETLCodes.Assurances)}
          panel={<SiegeSocialAssuranceList />}
          disabled={idSiegeSocial <= 0}
        />
        <Tab
          id="documents"
          title={t(ETLCodes.Documents)}
          panel={<SiegeSocialDocuments />}
          disabled={idSiegeSocial <= 0}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
