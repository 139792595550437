import { Divider } from "@blueprintjs/core";
import { FGEmpty, FGTextInput, FieldGroup, FGListen, FGCustomPanel } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { FcbFormateurAgrementDtoFromJSON, FichierApi, FormateurAgrementApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  FGWalterDateMaskInput,
  FGWalterFileInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../../components";
import { useAuth } from "../../../../../contexts";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

export interface IFormateurAgrementDetailProps {}

export const FormateurAgrementDetail: React.FunctionComponent<IFormateurAgrementDetailProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(FormateurAgrementApi);
  const { id, idAgrement, stateAgrement } = useParams<{ id: string; idAgrement: string; stateAgrement: string }>();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: async () => {
      const ret =
        +idAgrement <= 0
          ? FcbFormateurAgrementDtoFromJSON({ idformateurAgrement: 0, idformateur: +id })
          : await api.formateurAgrementGet({ id: +idAgrement });
      setStartDate(ret?.datedebutAgrement);
      return ret;
    },
    saveApiFn: d => api.formateurAgrementSave({ FcbFormateurAgrementDto: d }),
    deleteApiFn: d => api.formateurAgrementDelete({ id: d.idformateurAgrement }),
    onDeletedRoute: () => `${ERoutes.formateur}/${id}/agrement/`,
    onSavedRoute: d => `${ERoutes.formateur}/${id}/agrement/${d.idformateurAgrement}/detail/edit`
  });

  const [conseillers, cLoading] = useReferential(a => a.referentialGetConseillersPedagogiques(), false);
  const [centres, ctLoading] = useReferential(a => a.referentialGetCentres(), true);
  const [etats, eLoading] = useReferential(a => a.referentialGetEtatsAgrement());
  const [startDate, setStartDate] = React.useState<Date>();

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idcentre: Yup.number().required(t(ETLCodes.Required)),
      idconseillerPedagogique: Yup.string().required(t(ETLCodes.Required)),
      dateDemande: Yup.date()
        .required(t(ETLCodes.Required))
        .test("is-valid", t(ETLCodes.DateDemandeNotValid), async value => {
          const validationError = await api.formateurAgrementCheckDateDemande({
            dateDebut: startDate,
            dateDemande: value
          });
          return validationError.isValid;
        })
    });
  }, [api, startDate, t]);

  const fapi = useApiService(FichierApi);
  const downloadFn = React.useCallback(async (idfichier: number) => await fapi.fichierDownload({ id: idfichier }), [
    fapi
  ]);

  const { hasPermission } = useAuth();
  const canEditSecondPart = React.useMemo(() => hasPermission("FORMATEUR", "RW", "AGREMENT_SECT2"), [hasPermission]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={stateAgrement === "edit"}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(`${ERoutes.formateur}/${+id}/agrement`)}
      saving={saving}
      onDelete={deleteItem}
      deleting={deleting}
      showDeleteButton={+idAgrement > 0}
    >
      <FGListen
        field="datedebutAgrement"
        onChanged={value => {
          setStartDate(value);
        }}
      ></FGListen>
      <FGListen
        field="fichier"
        onChanged={(value, formik) => {
          if (formik.dirty) {
            formik.setFieldValue("idfichier", null);
          }
        }}
      />
      <FieldGroup>
        <FieldGroup columns={2}>
          <FGWalterSelectInput
            name="idconseillerPedagogique"
            label={t(ETLCodes.NomConseiller)}
            items={conseillers}
            loading={cLoading}
          />
          <FGEmpty />
          <FGTextInput name="matiereDemandee" label={t(ETLCodes.MatiereDemandee)} />
          <FGEmpty />
          <FGWalterDateMaskInput name="dateDemande" label={t(ETLCodes.DateDemande)} />
          <FGEmpty />
          <FGWalterSelectInput
            name="idcentre"
            label={t(ETLCodes.CentreDemandeur)}
            items={centres}
            loading={ctLoading}
          />
          <FGEmpty />
          <FGCustomPanel>
            {({ formik }) => (
              <FGWalterFileInput
                name="fichier"
                label={t(ETLCodes.Document)}
                downloadFn={formik.values?.idfichier && (() => downloadFn(formik.values?.idfichier))}
              />
            )}
          </FGCustomPanel>
        </FieldGroup>
        <Divider />
        <FieldGroup columns={2}>
          <FGTextInput name="matiereAccordee" label={t(ETLCodes.MatiereAccordee)} readonly={!canEditSecondPart} />
          <FGEmpty />
          <FGWalterDateMaskInput name="datedebutAgrement" label={t(ETLCodes.DateDebut)} readonly={!canEditSecondPart} />
          <FGEmpty />
          <FGWalterDateMaskInput name="datefinAgrement" label={t(ETLCodes.DateFin)} readonly={!canEditSecondPart} />
          <FGEmpty />
          <FGWalterSelectInput
            name="idetatAgrement"
            label={t(ETLCodes.Etat)}
            items={etats}
            loading={eLoading}
            readonly={!canEditSecondPart}
          />
          <FGEmpty />
          <FGTextInput name="avis" label={t(ETLCodes.Avis)} maxLength={255} readonly={!canEditSecondPart} />
          <FGEmpty />
          <FGTextInput name="avisRetrait" label={t(ETLCodes.AvisRetrait)} readonly={!canEditSecondPart} />
        </FieldGroup>
      </FieldGroup>
    </SmallFormGenerator>
  );
};
