/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContratLieuFormationTuteurGridDto
 */
export interface ContratLieuFormationTuteurGridDto {
    /**
     * 
     * @type {number}
     * @memberof ContratLieuFormationTuteurGridDto
     */
    idcontratLieuFormationTuteur?: number;
    /**
     * 
     * @type {number}
     * @memberof ContratLieuFormationTuteurGridDto
     */
    idcontratLieuFormation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratLieuFormationTuteurGridDto
     */
    idtuteur?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratLieuFormationTuteurGridDto
     */
    idtuteurLieu?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContratLieuFormationTuteurGridDto
     */
    principal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContratLieuFormationTuteurGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContratLieuFormationTuteurGridDto
     */
    actif?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ContratLieuFormationTuteurGridDto
     */
    nbContrats?: number;
    /**
     * 
     * @type {number}
     * @memberof ContratLieuFormationTuteurGridDto
     */
    capaciteFormative?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ContratLieuFormationTuteurGridDto
     */
    readonly capaciteFormativeAtteinte?: boolean | null;
}

/**
 * Check if a given object implements the ContratLieuFormationTuteurGridDto interface.
 */
export function instanceOfContratLieuFormationTuteurGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContratLieuFormationTuteurGridDtoFromJSON(json: any): ContratLieuFormationTuteurGridDto {
    return ContratLieuFormationTuteurGridDtoFromJSONTyped(json, false);
}

export function ContratLieuFormationTuteurGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContratLieuFormationTuteurGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontratLieuFormationTuteur': !exists(json, 'idcontratLieuFormationTuteur') ? undefined : json['idcontratLieuFormationTuteur'],
        'idcontratLieuFormation': !exists(json, 'idcontratLieuFormation') ? undefined : json['idcontratLieuFormation'],
        'idtuteur': !exists(json, 'idtuteur') ? undefined : json['idtuteur'],
        'idtuteurLieu': !exists(json, 'idtuteurLieu') ? undefined : json['idtuteurLieu'],
        'principal': !exists(json, 'principal') ? undefined : json['principal'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'nbContrats': !exists(json, 'nbContrats') ? undefined : json['nbContrats'],
        'capaciteFormative': !exists(json, 'capaciteFormative') ? undefined : json['capaciteFormative'],
        'capaciteFormativeAtteinte': !exists(json, 'capaciteFormativeAtteinte') ? undefined : json['capaciteFormativeAtteinte'],
    };
}

export function ContratLieuFormationTuteurGridDtoToJSON(value?: ContratLieuFormationTuteurGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontratLieuFormationTuteur': value.idcontratLieuFormationTuteur,
        'idcontratLieuFormation': value.idcontratLieuFormation,
        'idtuteur': value.idtuteur,
        'idtuteurLieu': value.idtuteurLieu,
        'principal': value.principal,
        'nom': value.nom,
        'actif': value.actif,
        'nbContrats': value.nbContrats,
        'capaciteFormative': value.capaciteFormative,
    };
}

