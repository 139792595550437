import { Tab } from '@blueprintjs/core';
import * as React from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { ContactDetailSwitch, ContactLieuxFormation, ContactSiegeSociaux } from '.';

import { ContactApi } from '../../../../api';
import { ERoutes } from '../../../../AppRouter';
import { BackButton, PageBase, ProtectedTabs } from '../../../../components';
import { useApiService, useTl } from '../../../../hooks';
import { ETLCodes } from '../../../../locales';

export interface IContactItemPageProps { }

export const ContactItemPage: React.FunctionComponent<IContactItemPageProps> = props => {
  const { t } = useTl();
  const { id, tab, state } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const api = useApiService(ContactApi);

  const fetchDisplayName = React.useCallback(() => {
    return api.contactGetDisplayName({ id: +id });
  }, [api, id]);
  const { data: displayName } = useQuery(["contact-display-name", id], fetchDisplayName);

  return (
    <PageBase
      breadCrumbs={[{ text: t(ETLCodes.Contacts), route: ERoutes.contacts }, { text: displayName }]}
    >
      <ProtectedTabs
        id="TabsContacts"
        onChange={newTabId => history.push(`${ERoutes.contacts}/${id}/${newTabId}/${state}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.contacts}></BackButton>
        <Tab id="detail" title={t(ETLCodes.Signaletique)} panel={<ContactDetailSwitch />} />
        <Tab id="siegesSociaux" title={t(ETLCodes.SiegesSociaux)} panel={<ContactSiegeSociaux />} />
        <Tab id="lieuxFormation" title={t(ETLCodes.LieuxFormations)} panel={<ContactLieuxFormation />} />
      </ProtectedTabs>
    </PageBase>
  );
};
