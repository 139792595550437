import { Button, Checkbox, Colors, Icon, IconName, Menu, Spinner } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { MenuItem2, Popover2 } from "@blueprintjs/popover2";
import { format } from "date-fns";
import { ButtonContainer, IDataTableColumn, ToolTipButton } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import {
  EBooleanSearchTypes,
  EPrintingQueueStatus,
  PrintingQueueApi,
  PrintingQueueGridDto,
  PrintingQueueSearchDto
} from "../../../../api";
import { DeleteButton, DownloadButton, SearchTablePage } from "../../../../components";
import { useEventsContext, usePrintingQueueContext } from "../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { toCleanUtc } from "../../../../utils";

const PopoverContent = styled.div`
  padding: 0.5rem;
`;

export interface IPrintingHistoryProps {}

export const PrintingHistory: React.FunctionComponent<IPrintingHistoryProps> = props => {
  const { t, tUnsafe } = useTl();
  const api = useApiService(PrintingQueueApi);
  const {
    deleteFromQueue,
    cancelQueue,
    rowCancelling,
    rowDeleting,
    refreshQueue,
    downloadFile,
    rowDownloading,
    massDownload,
    massDownloading,
    rowRetrying,
    showPrinting,
    rowShowing,
    massMask,
    massDelete
  } = usePrintingQueueContext();
  const { dispatchEvent } = useEventsContext();
  const [selectedFichierIds, setSelectedFichierIds] = React.useState([]);
  const [selectedIds, setSelectedIds] = React.useState([]);
  const [selectAllLoading, setSelectAllLoading] = React.useState(false);
  const lastSearchObject = React.useRef<PrintingQueueSearchDto>();
  const [nbReturnedIds, setNbReturnedIds] = React.useState(0);

  const selectAll = React.useCallback(() => {
    setSelectAllLoading(true);
    api
      .printingQueueSearchIds({
        PrintingQueueSearchDto: { ...lastSearchObject?.current, forceSkip: 0, forceTake: 0 }
      })
      .then(allIds => {
        if (allIds.idsprintingQueue.length === selectedIds.length) {
          setSelectedIds([]);
          setNbReturnedIds(0);
        } else {
          setSelectedIds(allIds.idsprintingQueue);
          setNbReturnedIds(allIds.idsprintingQueue.length);
        }
        if (allIds.idsfichier.length === selectedFichierIds.length) {
          setSelectedFichierIds([]);
        } else {
          setSelectedFichierIds(allIds.idsfichier);
        }
        setSelectAllLoading(false);
      });
  }, [api, selectedFichierIds.length, selectedIds.length]);

  const toggleSelection = React.useCallback(
    (item: PrintingQueueGridDto) => {
      if (!selectedIds.includes(item.idprintingQueue)) {
        setSelectedIds(ids => [...ids, item.idprintingQueue]);
      } else {
        setSelectedIds(ids => ids.filter(id => id !== item.idprintingQueue));
      }
      if (!selectedFichierIds.includes(item.idfichier)) {
        setSelectedFichierIds(ids => [...ids, item.idfichier]);
      } else {
        setSelectedFichierIds(ids => ids.filter(id => id !== item.idfichier));
      }
    },
    [selectedFichierIds, selectedIds]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        header: () =>
          selectAllLoading ? (
            <Spinner size={20} />
          ) : (
            <Checkbox checked={nbReturnedIds > 0 && nbReturnedIds === selectedIds.length} onChange={selectAll} />
          ),
        render: (item: PrintingQueueGridDto) => (
          <Checkbox
            checked={selectedIds.includes(item.idprintingQueue)}
            onChange={e => {
              toggleSelection(item);
            }}
          />
        )
      },
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: PrintingQueueGridDto) => (
          <ButtonContainer>
            {row.status === EPrintingQueueStatus.DONE && !!row.idfichier && (
              <DownloadButton
                tooltip={row.temporary ? t(ETLCodes.DownloadingWillDeleteFile) : null}
                minimal
                onClick={async () => {
                  await downloadFile(row.idprintingQueue, row.idfichier, row.temporary);
                  refreshQueue();
                  dispatchEvent("SEARCH_TABLE_REFRESH");
                }}
                loading={
                  rowCancelling === row.idprintingQueue ||
                  rowDeleting === row.idprintingQueue ||
                  rowDownloading === row.idprintingQueue ||
                  rowRetrying === row.idprintingQueue ||
                  rowShowing === row.idprintingQueue
                }
              />
            )}
            {(row.status === EPrintingQueueStatus.ERROR || row.status === EPrintingQueueStatus.WAITING) && (
              <DeleteButton
                minimal
                onDelete={async () => {
                  await deleteFromQueue(row.idprintingQueue);
                  refreshQueue();
                  dispatchEvent("SEARCH_TABLE_REFRESH");
                }}
                loading={
                  rowCancelling === row.idprintingQueue ||
                  rowDeleting === row.idprintingQueue ||
                  rowDownloading === row.idprintingQueue ||
                  rowRetrying === row.idprintingQueue ||
                  rowShowing === row.idprintingQueue
                }
              />
            )}
            {row.status === EPrintingQueueStatus.GENERATING && (
              <Button
                minimal
                small
                loading={
                  rowCancelling === row.idprintingQueue ||
                  rowDeleting === row.idprintingQueue ||
                  rowDownloading === row.idprintingQueue ||
                  rowRetrying === row.idprintingQueue ||
                  rowShowing === row.idprintingQueue
                }
                icon={"cross"}
                intent={"none"}
                onClick={async e => {
                  e.stopPropagation();
                  await cancelQueue(row.idprintingQueue);
                  refreshQueue();
                }}
              />
            )}
            {row.status === EPrintingQueueStatus.DONE && (
              <ToolTipButton
                icon={row.hidden ? "eye-on" : "eye-off"}
                tooltip={row.hidden ? t(ETLCodes.Show) : t(ETLCodes.Hide)}
                minimal
                loading={
                  rowCancelling === row.idprintingQueue ||
                  rowDeleting === row.idprintingQueue ||
                  rowDownloading === row.idprintingQueue ||
                  rowRetrying === row.idprintingQueue ||
                  rowShowing === row.idprintingQueue
                }
                onClick={async () => {
                  if (row.hidden) await showPrinting(row.idprintingQueue);
                  else await deleteFromQueue(row.idprintingQueue);
                  refreshQueue();
                  dispatchEvent("SEARCH_TABLE_REFRESH");
                }}
              />
            )}
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Statut),
        fieldName: "status",
        alignment: "center",
        autoFitContent: true,
        render: (row: PrintingQueueGridDto) => {
          let icon: IconName;
          let color;
          switch (row.status) {
            case "WAITING":
              icon = "time";
              color = Colors.ORANGE3;
              break;
            case "GENERATING":
              icon = "refresh";
              color = Colors.BLUE3;
              break;
            case "DONE":
              icon = "tick-circle";
              color = Colors.GREEN3;
              break;
            case "CANCELLED":
              icon = "remove";
              color = Colors.GRAY3;
              break;
            default:
              icon = "cross-circle";
              color = Colors.RED3;
              break;
          }
          return <Icon icon={icon} color={color} />;
        }
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "displayName"
      },
      {
        header: () => t(ETLCodes.Rapport),
        fieldName: "nomRapport"
      },
      {
        header: () => t(ETLCodes.CreatedOn),
        fieldName: "creationDate",
        render: (row: PrintingQueueGridDto) => format(toCleanUtc(row.creationDate), "dd/MM/yyyy HH:mm:ss")
      }
    ],
    [
      cancelQueue,
      deleteFromQueue,
      dispatchEvent,
      downloadFile,
      nbReturnedIds,
      refreshQueue,
      rowCancelling,
      rowDeleting,
      rowDownloading,
      rowRetrying,
      rowShowing,
      selectAll,
      selectAllLoading,
      selectedIds,
      showPrinting,
      t,
      toggleSelection
    ]
  );

  const apiFactory = useAbortableApiServiceFactory(PrintingQueueApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: PrintingQueueSearchDto) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      if (JSON.stringify(sObj) !== JSON.stringify(lastSearchObject?.current)) {
        lastSearchObject.current = sObj;
      }
      return abortableApi.printingQueueBaseSearch({
        PrintingQueueSearchDto: sObj
      });
    },
    [apiFactory]
  );
  const criteriasFn = React.useCallback(async () => {
    const crit = await api.printingQueueGetSearchCriterias({ includeListsValues: true });
    return crit.map(c => {
      if (c.criteria === "Status") {
        c.listValues.forEach(v => (v.displayValue = tUnsafe("StatutPrinting_" + v.displayValue)));
      }
      return c;
    });
  }, [api, tUnsafe]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  const globalActions = React.useMemo(
    () => (
      <Popover2
        position="bottom-left"
        content={
          <PopoverContent>
            <Menu>
              <MenuItem2
                icon={IconNames.DOWNLOAD}
                text={t(ETLCodes.TelechargerSelection)}
                onClick={async e => {
                  e.stopPropagation();
                  await massDownload(selectedFichierIds);
                }}
              />
              <MenuItem2
                icon={IconNames.Cross}
                text={t(ETLCodes.AnnulerSelection)}
                onClick={async e => {
                  e.stopPropagation();
                  await massDelete(selectedIds);
                  dispatchEvent("SEARCH_TABLE_REFRESH");
                }}
              />
              <MenuItem2
                icon={IconNames.EyeOff}
                text={t(ETLCodes.MasquerSelection)}
                onClick={async e => {
                  e.stopPropagation();
                  await massMask(selectedIds);
                  dispatchEvent("SEARCH_TABLE_REFRESH");
                }}
              />
            </Menu>
          </PopoverContent>
        }
      >
        <Button
          icon={IconNames.CHEVRON_DOWN}
          text={t(ETLCodes.SelectionActions)}
          loading={massDownloading}
          minimal
          intent="primary"
        ></Button>
      </Popover2>
    ),
    [dispatchEvent, massDelete, massDownload, massDownloading, massMask, selectedFichierIds, selectedIds, t]
  );

  return (
    <SearchTablePage
      getCriteriasFunction={criteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.PrintingHistory) }]}
      sortKeys={{ creationDate: "DESC" }}
      rightElement={globalActions}
      defaultCriterias={[
        {
          criteria: "Hidden",
          searchMode: EBooleanSearchTypes.Equals,
          value: false
        }
      ]}
      searchStateInitialSearch={false}
    />
  );
};

export default PrintingHistory;
