import { FGCustomPanel, FieldGroup, FieldSet } from "nsitools-react";
import * as React from "react";
import * as Yup from "yup";

import { FcbGestionDroitsGridDtoFromJSON, GestionDroitsApi } from "../../../api";
import { ERoutes } from "../../../AppRouter";
import { FGWalterSelectInput, PageBase, SmallFormGenerator } from "../../../components";
import { useApiService, useCrudApi, useTl } from "../../../hooks";
import { useReferential } from "../../../hooks/useReferential";
import { ETLCodes } from "../../../locales";
import { GestionDroitsGrid } from "./GestionDroitsGrid";

export interface IGestionDroitsListPageProps {}

export const GestionDroitsListPage: React.FunctionComponent<IGestionDroitsListPageProps> = () => {
  const { t } = useTl();
  const api = useApiService(GestionDroitsApi);
  const [accesLevels, alLoading] = useReferential(a => a.referentialGetAccesLevelList(), true, [], true);

  const { data, saving, saveItem } = useCrudApi({
    getApiFn: () => {
      return FcbGestionDroitsGridDtoFromJSON({
        idaccesLevel: accesLevels && accesLevels.length > 0 ? +accesLevels[0].value : null
      });
    },
    saveApiFn: d => api.gestionDroitsSavePermission({ FcbGestionDroitsGridDto: d }),
    onSavedRoute: () => `${ERoutes.gestionDroits}`,
    deleteApiFn: () => {},
    getDeps: [accesLevels]
  });
  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idaccesLevel: Yup.number().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    <PageBase breadCrumbs={[{ text: t(ETLCodes.GestionDroits), route: ERoutes.gestionDroits }]} withCard>
      <FieldSet title={t(ETLCodes.GestionDroits)}>
        {data && (
          <SmallFormGenerator
            initialValues={data}
            onSubmit={saveItem}
            showColons
            editMode
            validationSchema={FormSchema}
            saving={saving}
            formatDate="dd-MM-yyyy"
            hideButtons
            enableDirtyCheck={false}
          >
            <FieldGroup columns={2}>
              <FGWalterSelectInput
                label={t(ETLCodes.NiveauAcces)}
                items={accesLevels}
                loading={alLoading}
                name="idaccesLevel"
              />
            </FieldGroup>
            <FieldGroup>
              <FGCustomPanel>
                {ctx => {
                  return <GestionDroitsGrid idaccesLevel={ctx.formik.values.idaccesLevel}></GestionDroitsGrid>;
                }}
              </FGCustomPanel>
            </FieldGroup>
          </SmallFormGenerator>
        )}
      </FieldSet>
    </PageBase>
  );
};
