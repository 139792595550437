/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LieuFormationSelectorGridDto
 */
export interface LieuFormationSelectorGridDto {
    /**
     * 
     * @type {number}
     * @memberof LieuFormationSelectorGridDto
     */
    idlieuFormation?: number;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationSelectorGridDto
     */
    idsiegeSocial?: number;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationSelectorGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationSelectorGridDto
     */
    contact?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationSelectorGridDto
     */
    telephone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationSelectorGridDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationSelectorGridDto
     */
    localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationSelectorGridDto
     */
    enseigne?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationSelectorGridDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationSelectorGridDto
     */
    formationCanHaveApprenants?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationSelectorGridDto
     */
    nbContrats?: number;
}

/**
 * Check if a given object implements the LieuFormationSelectorGridDto interface.
 */
export function instanceOfLieuFormationSelectorGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LieuFormationSelectorGridDtoFromJSON(json: any): LieuFormationSelectorGridDto {
    return LieuFormationSelectorGridDtoFromJSONTyped(json, false);
}

export function LieuFormationSelectorGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LieuFormationSelectorGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'enseigne': !exists(json, 'enseigne') ? undefined : json['enseigne'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'formationCanHaveApprenants': !exists(json, 'formationCanHaveApprenants') ? undefined : json['formationCanHaveApprenants'],
        'nbContrats': !exists(json, 'nbContrats') ? undefined : json['nbContrats'],
    };
}

export function LieuFormationSelectorGridDtoToJSON(value?: LieuFormationSelectorGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idlieuFormation': value.idlieuFormation,
        'idsiegeSocial': value.idsiegeSocial,
        'nom': value.nom,
        'contact': value.contact,
        'telephone': value.telephone,
        'email': value.email,
        'localite': value.localite,
        'enseigne': value.enseigne,
        'actif': value.actif,
        'formationCanHaveApprenants': value.formationCanHaveApprenants,
        'nbContrats': value.nbContrats,
    };
}

