import { FGCheckboxInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { ExportGapApi, FcbExportGapDtoFromJSON } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { FGWalterDateMaskInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";
import { ETLCodes } from "../../../../locales";

export interface IExportationGapDetailPageProps {
  idExport: number;
  editMode: boolean;
}

export const ExportationGapDetailPage: React.FunctionComponent<IExportationGapDetailPageProps> = ({
  idExport,
  editMode
}) => {
  const { t } = useTl();
  const api = useApiService(ExportGapApi);
  const history = useHistory();

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idcentre: Yup.number().required(t(ETLCodes.Required))
    });
  }, [t]);
  const [centre, cLoading] = useReferential(a => a.referentialGetCentre(), true, []);
  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () => (idExport <= 0 ? FcbExportGapDtoFromJSON({ idExport: 0 }) : api.exportGapGet({ id: +idExport })),
    saveApiFn: d => api.exportGapSave({ FcbExportGapDto: d }),
    onSavedRoute: d => `${ERoutes.exportationGap}/${d.idexport}/detail/edit`,
    deleteApiFn: d => api.exportGapDelete({ id: d.idexport }),
    onDeletedRoute: () => ERoutes.exportationGap
  });

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={editMode}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.exportationGap)}
      onDelete={deleteItem}
      showDeleteButton={idExport > 0}
      saving={saving}
      deleting={deleting}
    >
      <FieldGroup>
        <FGWalterDateMaskInput name="date" label={t(ETLCodes.Date)} readonly={idExport > 0} />
        <FGWalterSelectInput name="idcentre" label={t(ETLCodes.Centre)} items={centre} loading={cLoading} />
        <FGTextInput name="description" label={t(ETLCodes.Description)} maxLength={250} />
        <FGCheckboxInput name="valide" label={t(ETLCodes.Valide)} />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
