import { Button, Callout } from "@blueprintjs/core";
import { format } from "date-fns";
import { FieldSet } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { CarouselComparer, CE_FINAL_KEY, DoublonsMultipleDataCard, useDoublonsStateContext } from "..";
import { ChefEntrepriseDoublonEditDto, ChefEntrepriseDoublonSiegeSocialDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BooleanColumn, ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

const Container = styled.div`
  height: 70vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export interface IDoublonChefEntrepriseSiegesSociauxProps {}

export const DoublonChefEntrepriseSiegesSociaux: React.FunctionComponent<IDoublonChefEntrepriseSiegesSociauxProps> = props => {
  const { t } = useTl();
  const { data, selectData, getSelectedIndex } = useDoublonsStateContext();

  const selectedIndex = React.useMemo(() => getSelectedIndex(`${CE_FINAL_KEY}.idchefEntreprise`) ?? 0, [
    getSelectedIndex
  ]);
  const chefEntrepriseContainer = React.useCallback(
    (index: number, nb: number) => {
      const chefEntreprise: ChefEntrepriseDoublonEditDto = data?.chefEntreprises[index];
      if (!chefEntreprise) return;

      return (
        <FieldSet
          key={chefEntreprise.idchefEntreprise}
          title={
            <TitleContainer>
              {index !== selectedIndex ? (
                <Button
                  text={t(ETLCodes.Conserver)}
                  icon={"arrow-left"}
                  onClick={() => selectData(`${CE_FINAL_KEY}.idchefEntreprise`, index)}
                />
              ) : (
                <div />
              )}
              <ViewButton
                text={t(ETLCodes.ChefEntrepriseNb, { nb: nb, id: chefEntreprise.idchefEntreprise })}
                onClick={() =>
                  window.open(`#${ERoutes.chefEntreprise}/${chefEntreprise.idchefEntreprise}/detail/view`, "_blank")
                }
              />
              <div />
            </TitleContainer>
          }
          titlePosition="center"
        >
          <DoublonsMultipleDataCard
            labelPredicate={(s: ChefEntrepriseDoublonSiegeSocialDto) => s.denominationSociale}
            fieldPath={`chefEntreprises[${index}].siegesSociaux`}
            interactive={() => false}
            forceSelected={() => false}
            finalEntityKey={CE_FINAL_KEY}
          >
            {(s: ChefEntrepriseDoublonSiegeSocialDto) => (
              <InformationContainer key={s.idchefEntrepriseSiege}>
                <div>
                  {t(ETLCodes.NumeroBce)}: {s.numeroBce}
                </div>
                <div>
                  {t(ETLCodes.Fonction)}: {s.fonction}
                </div>
                <div>
                  {t(ETLCodes.Telephone)}: {s.telephone?.numero}
                </div>
                <div>
                  {t(ETLCodes.Gsm)}: {s.gsm?.numero}
                </div>
                <div>
                  {t(ETLCodes.Email)}: {s.email?.adresseEmail}
                </div>
                <div>
                  {t(ETLCodes.Remarque)}: {s.remarqueSiege}
                </div>
                <div>
                  {t(ETLCodes.ChefEntreprisePrincipal)}: <BooleanColumn value={s.chefEntreprisePrincipal} />
                </div>
                <div>
                  {t(ETLCodes.SortieEntreprise)}: {s.sortieEntreprise && format(s.sortieEntreprise, "dd-MM-yyyy")}
                </div>
              </InformationContainer>
            )}
          </DoublonsMultipleDataCard>
        </FieldSet>
      );
    },
    [data?.chefEntreprises, selectData, selectedIndex, t]
  );

  return (
    <Container>
      <Callout
        intent="warning"
        title={t(ETLCodes.DoublonChefEntrepriseSiegeSocialCumules)}
        style={{ marginBottom: "1rem" }}
        icon="info-sign"
      />
      <CarouselComparer leftElement={chefEntrepriseContainer(selectedIndex, selectedIndex + 1)}>
        {data?.chefEntreprises?.length > 1 &&
          data?.chefEntreprises
            ?.filter((a, i) => i !== selectedIndex)
            ?.map((a, i) =>
              chefEntrepriseContainer(i + (i >= selectedIndex ? 1 : 0), i + (i >= selectedIndex ? 2 : 1))
            )}
      </CarouselComparer>
    </Container>
  );
};
