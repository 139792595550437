import { FieldSet, IDataTableColumn } from "nsitools-react";
import * as React from "react";

import {
  FormateurPrestationContratSearch,
  FormateurPrestationsContratApi,
  EAggregateType,
  FcbFormateurPrestationsContratGridDto,
  ETextSearchType
} from "../../../../../api";
import { SearchTablePage } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { useGlobalData } from "../../../../../contexts/GlobalDataContext";

export interface IFormateurPrestationsContratProps {
  formateurId: number;
}

export const FormateurPrestationsContrat: React.FunctionComponent<IFormateurPrestationsContratProps> = ({
  formateurId
}) => {
  const { t } = useTl();
  const { currentAnneeScolaire } = useGlobalData();
  const api = useApiService(FormateurPrestationsContratApi);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.NbHeuresAPayer),
        fieldName: "nbHeuresAPayer",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.NbHeuresCours),
        fieldName: "nbHeuresCours",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.RemplacementPayes),
        fieldName: "nbHeuresRemplaceesPayees"
      },
      {
        header: () => t(ETLCodes.RemplacementNonPayes),
        fieldName: "nbHeuresRemplaceesNonPayees"
      },
      {
        header: () => t(ETLCodes.Remplacants),
        fieldName: "nbRemplacants"
      },
      {
        header: () => t(ETLCodes.Annee),
        fieldName: "annee"
      },
      {
        header: () => t(ETLCodes.Mois),
        fieldName: "moisorder",
        render: (row: FcbFormateurPrestationsContratGridDto) => <div>{row.mois}</div>
      },
      {
        header: () => t(ETLCodes.Contrat),
        fieldName: "contrat"
      },
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "centre"
      }
    ],
    [t]
  );

  const getCriteriasFn = React.useCallback(
    () => api.formateurPrestationsContratGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  const apiFactory = useAbortableApiServiceFactory(FormateurPrestationsContratApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: FormateurPrestationContratSearch) => {
      nextSearch.aggregateKeys = [
        { key: "NbHeuresAPayer", aggregateType: EAggregateType.Sum },
        { key: "NbHeuresCours", aggregateType: EAggregateType.Sum },
        { key: "NbHeuresRemplaceesPayees", aggregateType: EAggregateType.Sum },
        { key: "NbHeuresRemplaceesNonPayees", aggregateType: EAggregateType.Sum },
        { key: "NbRemplacants", aggregateType: EAggregateType.Sum }
      ];
      nextSearch.idFormateur = formateurId;

      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.formateurPrestationsContratBaseSearch({ FormateurPrestationContratSearch: nextSearch });
    },
    [apiFactory, formateurId]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <FieldSet title={`${t(ETLCodes.PrestationsContrat)}`}>
      <SearchTablePage
        withCard={false}
        columns={columns}
        getCriteriasFunction={getCriteriasFn}
        searchFunction={searchFn}
        onAbort={onAbort}
        defaultCriterias={[
          {
            criteria: "Annee",
            searchMode: ETextSearchType.Equals,
            value: currentAnneeScolaire?.idanneeScolaire
          }
        ]}
        sortKeys={{ annee: "ASC" }}
      />
    </FieldSet>
  );
};
