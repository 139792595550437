import * as React from "react";

import { DemandeInscriptionEditDto } from "../../../../../../api";
import { FGWalterSelectInput, WarningText } from "../../../../../../components";
import { useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

export interface ICentresDemandeSelectProps {
  values: DemandeInscriptionEditDto;
  name: string;
}

export const CentresDemandeSelect: React.FunctionComponent<ICentresDemandeSelectProps> = ({ values, name }) => {
  const { t } = useTl();

  const idmetier = React.useMemo(() => values?.idmetier ?? 0, [values?.idmetier]);
  const iddegre = React.useMemo(() => values?.iddegre ?? 0, [values?.iddegre]);
  const idtypeCours = React.useMemo(() => values?.idtypeCours ?? 0, [values?.idtypeCours]);
  const anneeScolaire = React.useMemo(() => values?.anneeAcademique, [values?.anneeAcademique]);

  const [centres, cLoading] = useReferential(
    a => a.referentialGetCentresDemande({ CentreDemandeRequestDto: { anneeScolaire, idmetier, iddegre, idtypeCours } }),
    true,
    [anneeScolaire, idmetier, iddegre, idtypeCours]
  );

  return (
    <FGWalterSelectInput
      label={t(ETLCodes.Centre)}
      name={name}
      items={centres}
      loading={cLoading}
      disabled={!idmetier || !iddegre || !anneeScolaire || !idtypeCours}
      helperText={
        !!idmetier &&
        !!iddegre &&
        !!anneeScolaire &&
        !!idtypeCours &&
        centres?.length === 0 && <WarningText text={t(ETLCodes.AucunCentrePourLesDonnéesChoisies)} />
      }
    />
  );
};
