import * as React from "react";
import { IconNames } from "@blueprintjs/icons";
import { ETLCodes } from "../../locales/ETLCodes";
import { useTl } from "../../hooks";
import { ICustomButtonProps } from "./ICustomButtonProps";
import { Button, Intent } from "@blueprintjs/core";

interface ISelectAllButtonProps extends ICustomButtonProps {}

export const SelectAllButton: React.FunctionComponent<ISelectAllButtonProps> = ({
  minimal = true,
  onClick,
  loading,
  disabled,
  intent = Intent.NONE,
  text,
  outlined
}) => {
  const { t } = useTl();

  return (
    <Button
      icon={IconNames.Tick}
      minimal={minimal}
      onClick={onClick}
      text={text ?? t(ETLCodes.SelectAll)}
      intent={intent}
      loading={loading}
      disabled={disabled}
      type={onClick ? "button" : "submit"}
      outlined={outlined}
    />
  );
};
