import * as React from "react";
import { IFGCheckboxInputProps, FGCheckboxInput } from "nsitools-react";
import { Colors } from "@blueprintjs/core";

export interface IFGWalterCheckboxInputProps extends IFGCheckboxInputProps {}

export const FGWalterCheckboxInput: React.FunctionComponent<IFGWalterCheckboxInputProps> = ({
  colorTick = Colors.GREEN5,
  colorCross = Colors.RED5,
  ...cProps
}) => {
  return <FGCheckboxInput {...cProps} colorTick={colorTick} colorCross={colorCross} />;
};
