import { Icon, IOptionProps } from "@blueprintjs/core";
import { showError } from "nsitools-react";
import * as React from "react";
import Select from "react-select";

import { BulletinApprenantTypeApi } from "../../api";
import { useApiService, useTl } from "../../hooks";
import { ETLCodes } from "../../locales";

export interface ITableSelectEditorProps {
  initialValue: number;
  idBulletinInscriptionMatiere: number;
  secondeSess: boolean;
  editable?: boolean;
  delibs: {
    props: IOptionProps[];
    loading: boolean;
  };
  disabled?: boolean;
}

const DropdownIndicator: React.FunctionComponent<any> = () => {
  return <Icon icon="double-caret-vertical" style={{ padding: "0 4px", cursor: "pointer" }}></Icon>;
};

export const TableSelectEditor: React.FunctionComponent<ITableSelectEditorProps> = ({
  initialValue,
  idBulletinInscriptionMatiere,
  secondeSess,
  editable = true,
  delibs,
  disabled = false
}) => {
  const { t } = useTl();

  const api = useApiService(BulletinApprenantTypeApi);

  const [currentValue, setCurrentValue] = React.useState<number>();
  const [editedValue, setEditedValue] = React.useState<number>();

  React.useEffect(() => {
    setCurrentValue(initialValue);
    setEditedValue(initialValue);
  }, [initialValue]);

  const [loading, setLoading] = React.useState<boolean>();

  const finalItems = React.useMemo(() => delibs?.props?.map(i => ({ value: i.value || "", label: i.label || " " })), [
    delibs
  ]);

  const finalValue = React.useMemo(() => {
    const value = editedValue;
    const found = finalItems?.find(d => "" + d.value === "" + value);
    return found;
  }, [editedValue, finalItems]);

  const saveValue = React.useCallback(
    async finalVal => {
      if (finalVal !== currentValue) {
        try {
          setLoading(true);
          await api.bulletinApprenantTypeSaveBulletinDeliberationType({
            idDeliberationType: finalVal,
            idBulletinInscriptionMatiere,
            secondeSess
          });
          setCurrentValue(finalVal);
        } catch (Error) {
          showError(`Unable to save`);
          setEditedValue(currentValue);
        }
        setLoading(false);
      }
    },
    [api, currentValue, idBulletinInscriptionMatiere, secondeSess]
  );

  const handleChange = React.useCallback(
    async (value: any, details?: any) => {
      let finalVal = null;
      if (!value?.value || details?.action === "clear") {
        finalVal = undefined;
      } else {
        finalVal = value.value;
      }
      setEditedValue(finalVal);
      await saveValue(finalVal);
    },
    [saveValue]
  );

  return (
    <Select
      isClearable={true}
      styles={{
        control: base => {
          let final = {
            ...base,
            minHeight: "28px"
          };
          return final;
        },
        input: base => ({ ...base, margin: "1px 2px" }),
        clearIndicator: base => ({ ...base, padding: "4px", cursor: "pointer" }),
        menu: base => ({ ...base, zIndex: 9999 }),
        menuPortal: base => ({ ...base, zIndex: 9999 })
      }}
      isLoading={delibs?.loading || loading}
      options={finalItems}
      components={{ DropdownIndicator }}
      onChange={handleChange}
      menuPortalTarget={document.body}
      menuPlacement="auto"
      value={finalValue}
      loadingMessage={() => t(ETLCodes.FGSelectInput_Loading)}
      noOptionsMessage={() => t(ETLCodes.FGSelectInput_NoOptions)}
      placeholder={t(ETLCodes.FGSelectInput_SelectAnItem)}
      isDisabled={!editable || disabled}
    />
  );
};
