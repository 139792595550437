/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbStatutSocialGridDto
 */
export interface FcbStatutSocialGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbStatutSocialGridDto
     */
    idstatutSocial?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbStatutSocialGridDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbStatutSocialGridDto
     */
    idstatutSocialGuid?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbStatutSocialGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbStatutSocialGridDto interface.
 */
export function instanceOfFcbStatutSocialGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbStatutSocialGridDtoFromJSON(json: any): FcbStatutSocialGridDto {
    return FcbStatutSocialGridDtoFromJSONTyped(json, false);
}

export function FcbStatutSocialGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbStatutSocialGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idstatutSocial': !exists(json, 'idstatutSocial') ? undefined : json['idstatutSocial'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'idstatutSocialGuid': !exists(json, 'idstatutSocialGuid') ? undefined : json['idstatutSocialGuid'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbStatutSocialGridDtoToJSON(value?: FcbStatutSocialGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idstatutSocial': value.idstatutSocial,
        'libelle': value.libelle,
        'idstatutSocialGuid': value.idstatutSocialGuid,
        'actif': value.actif,
    };
}

