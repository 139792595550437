import { Classes, Colors, Intent, Radio, RadioGroup } from "@blueprintjs/core";
import { addMinutes, format, parseJSON } from "date-fns";
import { FormikProps } from "formik";
import {
  ButtonContainer,
  DataTable,
  DialogResult,
  FGCustomPanel,
  FGEmpty,
  FGListen,
  FGTextAreaInput,
  FieldGroup,
  IDataTableColumn,
  showError,
  showSuccess,
  useApiEffect,
  useGridState
} from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";

import { InscriptionDateConvocationSelector } from "..";
import {
  ClasseApi,
  EIfapmeSide,
  FcbInscriptionDetailDto,
  InscriptionApi,
  InscriptionFichierGridDto
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  AddButton,
  DeleteButton,
  DownloadButton,
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  SmallFormGenerator,
  StyledError,
  WarningText
} from "../../../../../components";
import { FGEuroMaskInput } from "../../../../../components/formGenerator/FGEuroMaskInput";
import { useAuth, useDialog } from "../../../../../contexts";
import { useApiService, useCrudApi, useManageError, useTheme, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import { exportFile } from "../../../../../utils";
import { InscriptionDocumentUploadDialog } from "./InscriptionDocumentUploadDialog";

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  & > .${Classes.RADIO} {
    flex: 1;
    margin-left: 1rem;
    font-weight: bold;
  }
`;

export interface IInscriptionProps {}

type DegreAndClasseChange = "nothing" | "degreAndClasse" | "onlyDegre" | "onlyClasse";

export const Inscription: React.FunctionComponent<IInscriptionProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { ifapmeSide } = useTheme();
  const api = useApiService(InscriptionApi);
  const classeApi = useApiService(ClasseApi);
  const { id, inscriptionId, stateInscription } = useParams<{
    id: string;
    inscriptionId: string;
    stateInscription: string;
  }>();
  const idApprenant = React.useMemo(() => +id, [id]);
  const idInscription = React.useMemo(() => +inscriptionId, [inscriptionId]);
  const [hasLinkedData, setHasLinkedData] = React.useState(false);
  const [classeChanged, setClasseChanged] = React.useState(false);
  const [degreChanged, setDegreChanged] = React.useState(false);
  const [showStadeError, setShowStadeError] = React.useState(false);
  const [currentIdClasse, setIdClasse] = React.useState(0);
  const [blockEdit, setBlockEdit] = React.useState(false);
  const [hardDeleting, setHardDeleting] = React.useState(false);
  const { showDialogPromise } = useDialog();
  const { manageError } = useManageError();
  const [hasDispense] = useApiEffect(() => api.inscriptionHasDispense({ idInscription }), [idInscription]);
  const formikInnerRef = React.useRef<FormikProps<FcbInscriptionDetailDto>>();

  const { search } = useLocation();
  const iddemandeInscription = React.useMemo(() => +(new URLSearchParams(search).get("iddemandeInscription") ?? 0), [
    search
  ]);
  const iddemandeFormationCrea = React.useMemo(
    () => +(new URLSearchParams(search).get("iddemandeFormationCrea") ?? 0),
    [search]
  );

  const { user } = useAuth();
  const canHardDelete = React.useMemo(() => user.iduser === 2, [user.iduser]);

  const hardDeleteInscription = React.useCallback(async () => {
    const result = await showDialogPromise({
      message: t(ETLCodes.DeleteConfirmationMessage),
      title: t(ETLCodes.DeleteDialogTitle)
    });
    if (result === "yes") {
      try {
        setHardDeleting(true);
        await api.inscriptionDelete({ id: +inscriptionId });
        setTimeout(() => {
          history.push(`${ERoutes.apprenant}/${idApprenant}/inscription`);
        }, 0);
        showSuccess(t(ETLCodes.DeleteSuccess));
      } catch (error) {
        manageError(error);
      }
      setHardDeleting(false);
    }
  }, [api, history, idApprenant, inscriptionId, manageError, showDialogPromise, t]);

  const getHardDeleteFn = React.useMemo(() => (canHardDelete && +inscriptionId > 0 ? hardDeleteInscription : null), [
    canHardDelete,
    hardDeleteInscription,
    inscriptionId
  ]);

  const deleteDialogTitle = React.useMemo(
    () => (hasLinkedData ? t(ETLCodes.DispensesOrChangementsExists) : undefined),
    [hasLinkedData, t]
  );

  const [checkCanDelete, checkCanDeleteLoading] = useApiEffect(
    React.useCallback(() => {
      return api.inscriptionCheckSoftDelete({
        id: +inscriptionId
      });
    }, [api, inscriptionId]),
    [api, inscriptionId]
  );

  const deleteFn = React.useCallback(
    async d => {
      await api.inscriptionSoftDelete({ id: d.idinscription });
    },
    [api]
  );

  const getCanDeleteFn = React.useMemo(() => {
    return checkCanDelete?.value && +inscriptionId > 0 ? deleteFn : null;
  }, [checkCanDelete?.value, inscriptionId, deleteFn]);

  const classeChangedWithDispense = React.useMemo(() => hasDispense?.value && classeChanged, [
    classeChanged,
    hasDispense?.value
  ]);

  const valueDegreAndClasseChange = React.useMemo<DegreAndClasseChange>(
    () =>
      !degreChanged && !classeChangedWithDispense
        ? "nothing"
        : degreChanged && classeChangedWithDispense
        ? "degreAndClasse"
        : degreChanged
        ? "onlyDegre"
        : "onlyClasse",
    [classeChangedWithDispense, degreChanged]
  );

  const saveInscription = React.useCallback(
    async (dto: FcbInscriptionDetailDto) => {
      let res: DialogResult = "no";

      if (idInscription > 0) {
        switch (valueDegreAndClasseChange) {
          case "nothing":
            res = "yes";
            break;
          case "degreAndClasse":
            res = await showDialogPromise({
              message: `${t(ETLCodes.Attention)}
              ${t(ETLCodes.InscriptionDegreModifiedMessage)}
              ${t(ETLCodes.InscriptionHasDispensesMessage)}
              ${t(ETLCodes.ValidationDialogMessage)}`,
              title: t(ETLCodes.InscriptionDegreModifiedAndHasDispenseTitle)
            });
            break;
          case "onlyDegre":
            res = await showDialogPromise({
              message: `${t(ETLCodes.Attention)}
              ${t(ETLCodes.InscriptionDegreModifiedMessage)}
              ${t(ETLCodes.ValidationDialogMessage)}`,
              title: t(ETLCodes.InscriptionDegreModifiedTitle)
            });
            break;
          case "onlyClasse":
            res = await showDialogPromise({
              message: `${t(ETLCodes.Attention)}
              ${t(ETLCodes.InscriptionHasDispensesMessage)}
              ${t(ETLCodes.ValidationDialogMessage)}`,
              title: t(ETLCodes.InscriptionHasDispensesTitle)
            });
            break;
          default:
            break;
        }
      } else {
        res = "yes";
      }

      if (res === "yes") {
        return api.inscriptionSave({ FcbInscriptionDetailDto: dto });
      }
    },
    [api, idInscription, showDialogPromise, t, valueDegreAndClasseChange]
  );
  const [formation, fLoading, refetchFormations] = useReferential(
    a => a.referentialGetFormationApprenant({ idApprenant: idApprenant }),
    true,
    [idApprenant]
  );

  const { data, loading, deleteItem, deleting, saveItem, saving, refresh: refreshData } = useCrudApi<
    FcbInscriptionDetailDto
  >(
    React.useMemo(
      () => ({
        getApiFn: () =>
          api.inscriptionGet({
            id: +inscriptionId,
            idapprenant: idApprenant,
            iddemandeInscription,
            iddemandeFormationCrea
          }),
        saveApiFn: saveInscription,
        onSavedRoute: d => `${ERoutes.apprenant}/${idApprenant}/inscription/${d.idinscription}/detail/edit`,
        onSaved: () => refetchFormations(),
        deleteApiFn: getCanDeleteFn,
        onDeletedRoute: () => `${ERoutes.apprenant}/${idApprenant}/inscription`,
        deleteDialogTitle
      }),
      [
        api,
        deleteDialogTitle,
        getCanDeleteFn,
        idApprenant,
        iddemandeFormationCrea,
        iddemandeInscription,
        inscriptionId,
        refetchFormations,
        saveInscription
      ]
    )
  );

  React.useEffect(() => {
    setHasLinkedData(
      data &&
        (data.nbDispenses > 0 ||
          data.nbChangementsClasse > 0 ||
          data.nbPresStats > 0 ||
          data.nbPresences > 0 ||
          data.nbPaiements > 0 ||
          data.nbReports > 0 ||
          data.nbEvals > 0 ||
          data.nbBulletins > 0)
    );
  }, [data]);

  const [classeRequest, setClassRequest] = React.useState({
    idCentre: 0,
    anneeScolaire: "",
    typeCours: "",
    idApprenant: 0,
    specificiteCours: !!iddemandeFormationCrea ? "CR" : "",
    idClasse: 0,
    isARE: false,
    isSB: false
  });

  React.useEffect(() => {
    if (data && +inscriptionId > 0) {
      setClassRequest({
        idCentre: data.idcentre,
        anneeScolaire: data.anneeScolaire,
        typeCours: data.typecours,
        specificiteCours: !!iddemandeFormationCrea ? "CR" : "",
        idApprenant: idApprenant,
        idClasse: data.idclasse,
        isARE: data.isARE,
        isSB: data.isSB
      });
      setIdClasse(data.idclasse);
      api
        .inscriptionCanEditInscription({ idInscription: +inscriptionId, idClasse: data.idclasse })
        .then(result => setBlockEdit(result.value));

      setDegreChanged(false);
      setClasseChanged(false);
    }
  }, [api, data, idApprenant, iddemandeFormationCrea, inscriptionId]);

  const [annee, aLoading] = useReferential(a => a.referentialGetAnneeScolaire(), false, []);
  const [typeCours, tcLoading] = useReferential(
    a => a.referentialGetTypeCours({ ifapmeSide: ifapmeSide === "hope" ? EIfapmeSide.Hope : EIfapmeSide.Walter }),
    false,
    [ifapmeSide]
  );
  const [degre, dLoading] = useReferential(a => a.referentialGetDegre(), false, []);
  const [centre, cLoading] = useReferential(
    a => a.referentialGetCentreByUser({ idCentre: classeRequest?.idCentre }),
    true,
    [classeRequest?.idCentre]
  );
  const [statutInscription, siLoading] = useReferential(a => a.referentialGetStatutInscription(), true, []);

  const [idStade, setIdStade] = React.useState(0);
  const [stade, sLoading] = useReferential(a => a.referentialGetStade(), true, []);
  const [metier, mLoading] = useReferential(
    a => a.referentialMetierByStadeForApprenant({ idStade, idApprenant }),
    true,
    [idStade]
  );

  const fetchClasseDegreId = React.useCallback(async () => {
    if (!currentIdClasse || currentIdClasse === 0) return null;
    return await classeApi.classeGetDegreId({ classeId: currentIdClasse });
  }, [classeApi, currentIdClasse]);

  const { data: classeIdDegre, isLoading: classeIdDegreLoading } = useQuery(
    ["inscription-degreId", currentIdClasse, id],
    fetchClasseDegreId,
    { enabled: true }
  );

  const fetchClasseTypeCours = React.useCallback(async () => {
    if (!currentIdClasse || currentIdClasse === 0) return null;
    return await classeApi.classeGetTypeCours({ classeId: currentIdClasse });
  }, [classeApi, currentIdClasse]);

  const { data: classeTypeCours, isLoading: classeTypeCoursLoading } = useQuery(
    ["inscription-typeCours", currentIdClasse, id],
    fetchClasseTypeCours,
    { enabled: true }
  );

  React.useEffect(() => {
    if (
      classeIdDegre &&
      formikInnerRef.current?.values?.idclasse !== data?.idclasse &&
      formikInnerRef.current?.values?.degre !== classeIdDegre &&
      !iddemandeInscription
    ) {
      formikInnerRef.current.setFieldValue("degre", classeIdDegre);
    }
  }, [classeIdDegre, data?.idclasse, iddemandeInscription]);

  const [classes, claLoading] = useReferential(a => a.referentialGetClasseForInscription(classeRequest), true, [
    classeRequest
  ]);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape(
      {
        idclasse: Yup.string()
          .required(t(ETLCodes.Required))
          .nullable(),
        anneeScolaire: Yup.string()
          .required(t(ETLCodes.Required))
          .nullable(),
        typecours: Yup.string()
          .required(t(ETLCodes.Required))
          .nullable(),
        degre: Yup.string()
          .required(t(ETLCodes.Required))
          .nullable(),
        idcentre: Yup.number()
          .required(t(ETLCodes.Required))
          .nullable(),
        idformation: Yup.number()
          .nullable()
          .when("formation", (value, schema) => (!value ? schema.required(t(ETLCodes.Required)) : schema)),
        formation: Yup.object()
          .nullable()
          .when("idformation", (value, schema) =>
            !value
              ? schema.shape({
                  idstade: Yup.number()
                    .nullable()
                    .required(t(ETLCodes.Required)),
                  idmetier: Yup.number().required(t(ETLCodes.Required))
                })
              : schema
          ),
        dateConvocation: Yup.date()
          .nullable()
          .when("dateConvocationString", (value, schema) => (!value ? schema.required(t(ETLCodes.Required)) : schema)),
        dateConvocationString: Yup.string()
          .nullable()
          .when("dateConvocation", (value, schema) => (!value ? schema.required(t(ETLCodes.Required)) : schema)),
        dateInscription: Yup.date()
          .nullable()
          .required(t(ETLCodes.Required)),
        dateFinSuspension: Yup.date()
          .nullable()
          .when("idinscriptionSuspendue", (value, schema) =>
            value > 0 ? schema.required(t(ETLCodes.Required)) : schema
          )
      },
      [
        ["idformation", "formation"],
        ["dateConvocation", "dateConvocationString"],
        ["dateFinSuspension", "idinscriptionSuspendue"]
      ]
    );
  }, [t]);

  const SetFinale = React.useCallback(
    async (value: any, formik: FormikProps<FcbInscriptionDetailDto>) => {
      if (formik.values?.idformation && formik.values?.degre) {
        const finale = await (
          await api.inscriptionComputeDegreFinal({
            idFormation: formik.values?.idformation,
            idDegre: formik.values?.degre
          })
        ).value;
        if (value !== data?.finale) {
          formik.setFieldValue("finale", finale);
        }
      }
    },
    [api, data]
  );

  const checkStadeFormationClasse = React.useCallback(
    async (idFormation: number, idClasse: number) => {
      if (idFormation && idClasse) {
        const res = await api.inscriptionCheckStades({ idClasse: idClasse, idFormation: idFormation });
        setShowStadeError(!res.isValid);
      } else {
        setShowStadeError(false);
      }
    },
    [api]
  );

  const cannotDelete = React.useMemo(() => !!checkCanDelete && !checkCanDelete.value, [checkCanDelete]);

  const getDegreHint = React.useCallback(
    ctx =>
      !!ctx?.formik?.values?.degre &&
      classeIdDegre &&
      ctx?.formik?.values?.degre !== classeIdDegre && (
        <span style={{ color: Colors.ORANGE3 }}>{t(ETLCodes.DegreDifferentFromClasse)}</span>
      ),
    [classeIdDegre, t]
  );

  const FetchUserSocieteExterneCanEdit = React.useCallback(() => {
    if (idInscription <= 0) return null;
    return api.inscriptionUserSocieteExterneCanEdit({ idInscription });
  }, [api, idInscription]);
  const { data: userSocieteExterneCanEdit } = useQuery(
    ["user-societe-externe-can-edit", idInscription, user?.iduser],
    FetchUserSocieteExterneCanEdit
  );

  const fetchInscriptionSuspendue = React.useCallback(async () => {
    if (!currentIdClasse || !iddemandeInscription || !data?.isARE) return null;
    return await api.inscriptionSuspendueByClasse({ idApprenant, idClasse: currentIdClasse });
  }, [api, currentIdClasse, data?.isARE, idApprenant, iddemandeInscription]);

  const { data: inscriptionSuspendue } = useQuery(
    ["inscription-suspendue", currentIdClasse, idApprenant],
    fetchInscriptionSuspendue
  );

  React.useEffect(() => {
    if (inscriptionSuspendue > 0) {
      formikInnerRef.current?.setFieldValue("idinscriptionSuspendue", inscriptionSuspendue);
    }
  }, [inscriptionSuspendue]);

  const tableState = useGridState<any>({
    serverMode: false,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { uploadDate: "DESC", fileName: "ASC" }
  });

  const { setData } = tableState;

  React.useEffect(() => {
    if (data?.fichiers) {
      setData(data.fichiers);
    }
  }, [data?.fichiers, setData]);

  const [uploadDialoOpen, setUploadDialoOpen] = React.useState(false);
  const [rowLoading, setRowLoading] = React.useState(null);

  const deleteFichier = React.useCallback(
    async (idinscriptionFichier: number) => {
      const result = await showDialogPromise({
        message: t(ETLCodes.DeleteConfirmationMessage)
      });

      if (result === "yes") {
        try {
          setRowLoading(idinscriptionFichier);
          await api.inscriptionDeleteFichier({
            id: idinscriptionFichier
          });
          refreshData();
        } catch {
          showError(t(ETLCodes.ErrorWhileDeletingFile));
        } finally {
          setRowLoading(null);
        }
      }
    },
    [api, refreshData, showDialogPromise, t]
  );

  const downloadFichier = React.useCallback(
    async (idinscriptionFichier: number) => {
      try {
        setRowLoading(idinscriptionFichier);
        const file = await api.inscriptionDownloadFichier({
          id: idinscriptionFichier
        });
        await exportFile(file);
      } catch {
        showError(t(ETLCodes.ErrorWhileDownloadingFile));
      } finally {
        setRowLoading(null);
      }
    },
    [api, t]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: InscriptionFichierGridDto) => (
          <ButtonContainer>
            <DownloadButton
              minimal={true}
              onClick={() => downloadFichier(row.idinscriptionFichier)}
              loading={rowLoading === row.idinscriptionFichier}
              disabled={!!rowLoading && rowLoading !== row.idinscriptionFichier}
            />
            <DeleteButton
              minimal={true}
              onDelete={() => deleteFichier(row.idinscriptionFichier)}
              loading={rowLoading === row.idinscriptionFichier}
              disabled={!!rowLoading && rowLoading !== row.idinscriptionFichier}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.NomFichier),
        fieldName: "fileName"
      },
      {
        header: () => t(ETLCodes.DateUpload),
        fieldName: "uploadDate"
      },
      {
        header: () => t(ETLCodes.UtilisateurUpload),
        fieldName: "creationUserName"
      }
    ],
    [deleteFichier, downloadFichier, rowLoading, t]
  );

  const formatDate = React.useCallback((date: Date) => {
    return format(parseJSON(addMinutes(date, date.getTimezoneOffset())), "dd/MM/yyyy HH:mm");
  }, []);

  const onClose = React.useCallback(
    (refresh?: boolean) => {
      setUploadDialoOpen(false);
      if (refresh) {
        refreshData();
      }
    },
    [refreshData]
  );

  const onAdd = React.useCallback(() => {
    setUploadDialoOpen(true);
  }, []);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={stateInscription === "edit" && (idInscription <= 0 || userSocieteExterneCanEdit?.value)}
      editable={idInscription <= 0 || userSocieteExterneCanEdit?.value}
      validationSchema={FormSchema}
      loading={loading || checkCanDeleteLoading}
      onCancel={() => history.push(`${ERoutes.apprenant}/${idApprenant}/inscription`)}
      onDelete={deleteItem}
      deleteBtnDisabled={cannotDelete}
      onHardDelete={getHardDeleteFn}
      showDeleteButton={+inscriptionId > 0}
      saving={saving}
      deleting={deleting}
      hardDeleting={hardDeleting}
      formatDate="dd-MM-yyyy"
      minLabelWidth={150}
      formikInnerRef={formikInnerRef}
    >
      <FGListen
        field="anneeScolaire"
        onChanged={(value, formik) =>
          setClassRequest({
            idCentre: formik.values.idcentre,
            anneeScolaire: value,
            typeCours: formik.values.typecours,
            idApprenant,
            specificiteCours: !!iddemandeFormationCrea ? "CR" : "",
            idClasse: data.idclasse,
            isARE: formik.values.isARE,
            isSB: formik.values.isSB
          })
        }
      />
      <FGListen
        field="idcentre"
        onChanged={(value, formik) =>
          setClassRequest({
            idCentre: value,
            anneeScolaire: formik.values.anneeScolaire,
            typeCours: formik.values.typecours,
            idApprenant,
            specificiteCours: !!iddemandeFormationCrea ? "CR" : "",
            idClasse: data.idclasse,
            isARE: formik.values.isARE,
            isSB: formik.values.isSB
          })
        }
      />
      <FGListen
        field="typecours"
        onChanged={(value, formik) =>
          setClassRequest({
            idCentre: formik.values.idcentre,
            anneeScolaire: formik.values.anneeScolaire,
            typeCours: value,
            idApprenant,
            specificiteCours: !!iddemandeFormationCrea ? "CR" : "",
            idClasse: data.idclasse,
            isARE: formik.values.isARE,
            isSB: formik.values.isSB
          })
        }
      />
      <FGListen field="idformation" onChanged={(value, formik) => SetFinale(value, formik)} />
      <FGListen
        field="degre"
        onChanged={(value, formik) => {
          SetFinale(value, formik);
          setDegreChanged(value !== data?.degre);
        }}
      />
      <FGListen
        field="idclasse"
        onChanged={(value, formik) => {
          setIdClasse(value);
          checkStadeFormationClasse(+formik.values.idformation, +value);
          setClasseChanged(value !== data.idclasse);
        }}
      />
      <FGListen
        field="idformation"
        onChanged={(value, formik) => checkStadeFormationClasse(+value, +formik.values.idclasse)}
      />
      <FGListen field="formation.idstade" onChanged={(value, formik) => setIdStade(value)} />
      <FGListen
        field="dateConvocationString"
        onChanged={(value, formik) => {
          value && formik.touched?.dateConvocationString && formik.setFieldValue("dateConvocation", undefined);
        }}
      />
      <FGListen
        field="dateConvocation"
        onChanged={(value, formik) => {
          value && formik.touched?.dateConvocation && formik.setFieldValue("dateConvocationString", undefined);
        }}
      />

      <FieldGroup visible={idInscription <= 0} fieldsetProps={{ title: t(ETLCodes.ChoixFormation) }}>
        <FGCustomPanel>
          {ctx => (
            <>
              <StyledRadioGroup
                selectedValue={!ctx?.formik?.values?.idformation ? "1" : "0"}
                inline
                onChange={e => {
                  if (e.currentTarget.value === "1") {
                    ctx.formik.setFieldValue("idformation", null);
                    ctx.formik.setFieldValue("formation", {});
                  } else {
                    ctx.formik.setFieldValue("idformation", -1);
                    ctx.formik.setFieldValue("formation", null);
                  }
                }}
              >
                <Radio label={t(ETLCodes.NouvelleFormation)} value="1" disabled={!!iddemandeFormationCrea} />
                <Radio label={t(ETLCodes.FormationExistante)} value="0" disabled={!!iddemandeFormationCrea} />
              </StyledRadioGroup>
              <FieldGroup columns={2}>
                <FieldGroup>
                  <FGWalterSelectInput
                    label={t(ETLCodes.Stade)}
                    name="formation.idstade"
                    items={stade}
                    loading={sLoading}
                    requiredMark={!ctx?.formik?.values?.idformation}
                    disabled={!!ctx?.formik?.values?.idformation}
                    readonly={!!iddemandeFormationCrea}
                  />
                  <FGWalterSelectInput
                    label={t(ETLCodes.Metier)}
                    name="formation.idmetier"
                    items={metier}
                    loading={mLoading}
                    requiredMark={!ctx?.formik?.values?.idformation}
                    disabled={!!ctx?.formik?.values?.idformation}
                    readonly={!!iddemandeFormationCrea}
                  />
                </FieldGroup>
                <FieldGroup>
                  <FGWalterSelectInput
                    label={t(ETLCodes.Formation)}
                    name="idformation"
                    items={formation}
                    loading={fLoading}
                    requiredMark={!!ctx?.formik?.values?.idformation}
                    disabled={!ctx?.formik?.values?.idformation}
                    readonly={!!iddemandeFormationCrea}
                  />
                </FieldGroup>
              </FieldGroup>
            </>
          )}
        </FGCustomPanel>
      </FieldGroup>
      <FieldGroup columns={2} fieldsetProps={{ title: t(ETLCodes.DetailsInscription), collapsable: false }}>
        <FieldGroup>
          <FGWalterSelectInput
            label={t(ETLCodes.Annee)}
            name="anneeScolaire"
            items={annee}
            loading={aLoading}
            readonly={blockEdit || cannotDelete || !!iddemandeFormationCrea}
          />
          <FGWalterSelectInput
            label={t(ETLCodes.Centre)}
            name="idcentre"
            items={centre}
            loading={cLoading}
            readonly={blockEdit || cannotDelete || !!iddemandeInscription || !!iddemandeFormationCrea}
          />
          <FGCustomPanel>
            {ctx => (
              <FGWalterSelectInput
                label={t(ETLCodes.TypeCours)}
                name="typecours"
                items={typeCours}
                loading={tcLoading || classeTypeCoursLoading}
                readonly={
                  blockEdit ||
                  cannotDelete ||
                  (!!iddemandeInscription && !data?.typeCoursDemandeSeulement) ||
                  !!iddemandeFormationCrea
                }
                helperText={
                  <>
                    {!!data?.typeCoursDemandeSeulement && (
                      <WarningText
                        text={t(ETLCodes.TypeCoursDemandeSeulement, { tc: data.typeCoursDemandeSeulement })}
                      />
                    )}
                    {!!ctx?.formik?.values?.typecours &&
                      classeTypeCours &&
                      ctx?.formik?.values?.typecours !== classeTypeCours && (
                        <span style={{ color: Colors.ORANGE3 }}>{t(ETLCodes.TypeCoursDifferentFromClasse)}</span>
                      )}
                  </>
                }
              />
            )}
          </FGCustomPanel>
          <FGWalterSelectInput
            label={t(ETLCodes.Classe)}
            name="idclasse"
            items={classes}
            loading={claLoading}
            readonly={blockEdit}
            disabled={+inscriptionId > 0 && cannotDelete}
          />
          <FGWalterSelectInput
            label={t(ETLCodes.Degre)}
            name="degre"
            items={degre}
            loading={dLoading || classeIdDegreLoading}
            helperText={getDegreHint}
            readonly={!!iddemandeInscription || !!iddemandeFormationCrea}
          />
        </FieldGroup>
        <FieldGroup>
          <FGWalterSelectInput
            label={t(ETLCodes.Formation)}
            name="idformation"
            items={formation}
            loading={fLoading}
            requiredMark
            visible={idInscription > 0}
            readonly={!!iddemandeFormationCrea}
          />
          <FGWalterCheckboxInput label={t(ETLCodes.Bulletin)} name="isVisibleBulletin" />
          <FGWalterCheckboxInput label={t(ETLCodes.Finale)} name="finale" readonly />
          <FGWalterDateMaskInput label={t(ETLCodes.ChangeCentre)} name="dateChangeCentre" />
          <FGWalterDateMaskInput label={t(ETLCodes.DateDispense)} name="dateDispense" />
          {inscriptionSuspendue > 0 && (
            <FGWalterDateMaskInput label={t(ETLCodes.DateFinSuspension)} name="dateFinSuspension" />
          )}
        </FieldGroup>
        <FGEmpty />
        <FGWalterDateMaskInput label={t(ETLCodes.DateAbandon)} name="dateAbandon" />
        <FieldGroup>
          <FGWalterSelectInput
            label={t(ETLCodes.Statut)}
            name="idstatut"
            items={statutInscription}
            loading={siLoading}
            readonly
          />
          <FGWalterDateMaskInput label={t(ETLCodes.DateInscription)} name="dateInscription" />
          <FGEuroMaskInput label={t(ETLCodes.Minerval)} name="minerval" />
          <InscriptionDateConvocationSelector />
        </FieldGroup>
        <FieldGroup>
          <FGWalterCheckboxInput label={t(ETLCodes.EleveLibre)} name="libre" />
          <FGWalterCheckboxInput label={`${t(ETLCodes.Inscription)} A`} name="inscriptionGeneral" />
        </FieldGroup>
      </FieldGroup>
      <FieldGroup>
        <FGTextAreaInput label={t(ETLCodes.Remarque)} name="remarque" rows={4} />
      </FieldGroup>
      <FGCustomPanel>
        {ctx => <StyledError show={showStadeError} message={t(ETLCodes.StadeFormationClasseError)}></StyledError>}
      </FGCustomPanel>
      <FieldGroup
        visible={+inscriptionId > 0}
        fieldsetProps={{
          title: t(ETLCodes.Documents),
          rightElement: (
            <AddButton
              onClick={e => {
                e.stopPropagation();
                onAdd();
              }}
              text={t(ETLCodes.General_Add)}
              intent={Intent.PRIMARY}
            />
          )
        }}
      >
        <DataTable
          dateFormat="dd/MM/yyyy HH:mm"
          formatDate={formatDate}
          tableState={tableState}
          loading={loading}
          columns={columns}
        ></DataTable>
        {uploadDialoOpen && (
          <InscriptionDocumentUploadDialog
            onClose={onClose}
            dialogOpen={uploadDialoOpen}
            idinscription={+inscriptionId}
          />
        )}
      </FieldGroup>
    </SmallFormGenerator>
  );
};
