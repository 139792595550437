/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApprenantRepresentantHopeGridDto
 */
export interface ApprenantRepresentantHopeGridDto {
    /**
     * 
     * @type {number}
     * @memberof ApprenantRepresentantHopeGridDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantHopeGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantHopeGridDto
     */
    lien?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantHopeGridDto
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApprenantRepresentantHopeGridDto
     */
    idapprenant?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApprenantRepresentantHopeGridDto
     */
    idrepresentant?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApprenantRepresentantHopeGridDto
     */
    contactUrgence?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApprenantRepresentantHopeGridDto
     */
    representantLegal?: boolean;
}

/**
 * Check if a given object implements the ApprenantRepresentantHopeGridDto interface.
 */
export function instanceOfApprenantRepresentantHopeGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApprenantRepresentantHopeGridDtoFromJSON(json: any): ApprenantRepresentantHopeGridDto {
    return ApprenantRepresentantHopeGridDtoFromJSONTyped(json, false);
}

export function ApprenantRepresentantHopeGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApprenantRepresentantHopeGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'lien': !exists(json, 'lien') ? undefined : json['lien'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idrepresentant': !exists(json, 'idrepresentant') ? undefined : json['idrepresentant'],
        'contactUrgence': !exists(json, 'contactUrgence') ? undefined : json['contactUrgence'],
        'representantLegal': !exists(json, 'representantLegal') ? undefined : json['representantLegal'],
    };
}

export function ApprenantRepresentantHopeGridDtoToJSON(value?: ApprenantRepresentantHopeGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'nom': value.nom,
        'lien': value.lien,
        'type': value.type,
        'idapprenant': value.idapprenant,
        'idrepresentant': value.idrepresentant,
        'contactUrgence': value.contactUrgence,
        'representantLegal': value.representantLegal,
    };
}

