import { Button, IconName, Intent, MaybeElement } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ToolTipButton } from "nsitools-react";
import * as React from "react";

import { useAuth } from "../../contexts";
import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales/ETLCodes";
import { ICustomButtonProps } from "./ICustomButtonProps";

export interface IDeleteButtonProps extends Omit<ICustomButtonProps, "onClick"> {
  onDelete?: () => void;
  tooltipText?: string;
  icon?: IconName | MaybeElement;
  specificRights?: { module: string; tab?: string; rights?: "R" | "RW" | "RWD" };
}

export const DeleteButton: React.FunctionComponent<IDeleteButtonProps> = ({
  minimal = true,
  onDelete,
  loading,
  disabled,
  intent = Intent.DANGER,
  tooltipText,
  text,
  icon,
  specificRights
}) => {
  const { t } = useTl();
  const { getCurrentPermission, hasPermission } = useAuth();

  const hasRights = React.useMemo(
    () =>
      !!specificRights
        ? hasPermission(specificRights.module, specificRights.rights ?? "RWD", specificRights.tab)
        : getCurrentPermission()?.permission === "RWD",
    [getCurrentPermission, hasPermission, specificRights]
  );

  const finalText = React.useMemo(() => (minimal ? null : text ?? t(ETLCodes.General_Delete)), [minimal, t, text]);

  return hasRights ? (
    <>
      {!!tooltipText ? (
        <ToolTipButton
          tooltip={tooltipText}
          icon={icon ?? IconNames.TRASH}
          minimal={minimal}
          onClick={onDelete}
          text={finalText}
          intent={intent}
          loading={loading}
          disabled={disabled}
        ></ToolTipButton>
      ) : (
        <Button
          icon={icon ?? IconNames.TRASH}
          minimal={minimal}
          onClick={onDelete}
          text={finalText}
          intent={intent}
          loading={loading}
          disabled={disabled}
        />
      )}
    </>
  ) : (
    <></>
  );
};
