import { DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";

import { AdjointApi, AdjointSearch } from "../../../../../api";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface ICentreResponsableAdjointListProps {
  idCentre: number;
}

export const CentreResponsableAdjointList: React.FunctionComponent<ICentreResponsableAdjointListProps> = ({
  idCentre
}) => {
  const adApi = useApiService(AdjointApi);
  const { t } = useTl();

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [5],
    pageSize: 5,
    sortKeys: { titre: "ASC" }
  });

  const searchFunction = React.useCallback(
    (sObj?: AdjointSearch) => {
      sObj.idcentre = idCentre;
      return adApi.adjointBaseSearch({ AdjointSearch: sObj });
    },
    [adApi, idCentre]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.Title),
        fieldName: "titre"
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      }
    ],
    [t]
  );
  return (
    <FieldSet title={`${t(ETLCodes.Adjoints)}`}>
      <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
    </FieldSet>
  );
};
