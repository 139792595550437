/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FilterCriteriaInfo,
  OffreDemandeGridDtoPaginatedResults,
  OffreDemandeSearchDto,
  ReferentialItemDto,
} from '../models/index';
import {
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    OffreDemandeGridDtoPaginatedResultsFromJSON,
    OffreDemandeGridDtoPaginatedResultsToJSON,
    OffreDemandeSearchDtoFromJSON,
    OffreDemandeSearchDtoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface OffreDemandeBaseSearchRequest {
    OffreDemandeSearchDto?: OffreDemandeSearchDto;
}

export interface OffreDemandeGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface OffreDemandeGetSelectItemsRequest {
    searchField: string;
}

/**
 * 
 */
export class OffreDemandeApi extends runtime.BaseAPI {

    /**
     */
    async offreDemandeBaseSearchRaw(requestParameters: OffreDemandeBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OffreDemandeGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/OffreDemande/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OffreDemandeSearchDtoToJSON(requestParameters.OffreDemandeSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OffreDemandeGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async offreDemandeBaseSearch(requestParameters: OffreDemandeBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OffreDemandeGridDtoPaginatedResults> {
        const response = await this.offreDemandeBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async offreDemandeGetSearchCriteriasRaw(requestParameters: OffreDemandeGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/OffreDemande/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async offreDemandeGetSearchCriterias(requestParameters: OffreDemandeGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.offreDemandeGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async offreDemandeGetSelectItemsRaw(requestParameters: OffreDemandeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling offreDemandeGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/OffreDemande/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async offreDemandeGetSelectItems(requestParameters: OffreDemandeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.offreDemandeGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
