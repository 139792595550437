/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ECategorieStatutApprenant } from './ECategorieStatutApprenant';
import {
    ECategorieStatutApprenantFromJSON,
    ECategorieStatutApprenantFromJSONTyped,
    ECategorieStatutApprenantToJSON,
} from './ECategorieStatutApprenant';

/**
 * 
 * @export
 * @interface StatutApprenantGridDto
 */
export interface StatutApprenantGridDto {
    /**
     * 
     * @type {number}
     * @memberof StatutApprenantGridDto
     */
    idStatutApprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof StatutApprenantGridDto
     */
    idApprenant?: number;
    /**
     * 
     * @type {ECategorieStatutApprenant}
     * @memberof StatutApprenantGridDto
     */
    categorie?: ECategorieStatutApprenant;
    /**
     * 
     * @type {string}
     * @memberof StatutApprenantGridDto
     */
    categorieStatutSocial?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutApprenantGridDto
     */
    statutSocial?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutApprenantGridDto
     */
    dureeInoccupation?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof StatutApprenantGridDto
     */
    dateStatut?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutApprenantGridDto
     */
    sortieAnnulee?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StatutApprenantGridDto
     */
    typeSortie?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutApprenantGridDto
     */
    motifSortie?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutApprenantGridDto
     */
    creationUser?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutApprenantGridDto
     */
    postSortie?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StatutApprenantGridDto
     */
    remarqueRefus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutApprenantGridDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof StatutApprenantGridDto
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof StatutApprenantGridDto
     */
    statutCrea?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutApprenantGridDto
     */
    categorieSpecifiqueCrea?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutApprenantGridDto
     */
    fromCrea?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutApprenantGridDto
     */
    currentStatutFromCrea?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutApprenantGridDto
     */
    canUseCreaStatut?: boolean;
}

/**
 * Check if a given object implements the StatutApprenantGridDto interface.
 */
export function instanceOfStatutApprenantGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StatutApprenantGridDtoFromJSON(json: any): StatutApprenantGridDto {
    return StatutApprenantGridDtoFromJSONTyped(json, false);
}

export function StatutApprenantGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatutApprenantGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idStatutApprenant': !exists(json, 'idStatutApprenant') ? undefined : json['idStatutApprenant'],
        'idApprenant': !exists(json, 'idApprenant') ? undefined : json['idApprenant'],
        'categorie': !exists(json, 'categorie') ? undefined : ECategorieStatutApprenantFromJSON(json['categorie']),
        'categorieStatutSocial': !exists(json, 'categorieStatutSocial') ? undefined : json['categorieStatutSocial'],
        'statutSocial': !exists(json, 'statutSocial') ? undefined : json['statutSocial'],
        'dureeInoccupation': !exists(json, 'dureeInoccupation') ? undefined : json['dureeInoccupation'],
        'dateStatut': !exists(json, 'dateStatut') ? undefined : (json['dateStatut'] === null ? null : new Date(json['dateStatut'])),
        'sortieAnnulee': !exists(json, 'sortieAnnulee') ? undefined : json['sortieAnnulee'],
        'typeSortie': !exists(json, 'typeSortie') ? undefined : json['typeSortie'],
        'motifSortie': !exists(json, 'motifSortie') ? undefined : json['motifSortie'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'postSortie': !exists(json, 'postSortie') ? undefined : json['postSortie'],
        'remarqueRefus': !exists(json, 'remarqueRefus') ? undefined : json['remarqueRefus'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'statutCrea': !exists(json, 'statutCrea') ? undefined : json['statutCrea'],
        'categorieSpecifiqueCrea': !exists(json, 'categorieSpecifiqueCrea') ? undefined : json['categorieSpecifiqueCrea'],
        'fromCrea': !exists(json, 'fromCrea') ? undefined : json['fromCrea'],
        'currentStatutFromCrea': !exists(json, 'currentStatutFromCrea') ? undefined : json['currentStatutFromCrea'],
        'canUseCreaStatut': !exists(json, 'canUseCreaStatut') ? undefined : json['canUseCreaStatut'],
    };
}

export function StatutApprenantGridDtoToJSON(value?: StatutApprenantGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idStatutApprenant': value.idStatutApprenant,
        'idApprenant': value.idApprenant,
        'categorie': ECategorieStatutApprenantToJSON(value.categorie),
        'categorieStatutSocial': value.categorieStatutSocial,
        'statutSocial': value.statutSocial,
        'dureeInoccupation': value.dureeInoccupation,
        'dateStatut': value.dateStatut === undefined ? undefined : (value.dateStatut === null ? null : value.dateStatut.toISOString()),
        'sortieAnnulee': value.sortieAnnulee,
        'typeSortie': value.typeSortie,
        'motifSortie': value.motifSortie,
        'creationUser': value.creationUser,
        'postSortie': value.postSortie,
        'remarqueRefus': value.remarqueRefus,
        'remarque': value.remarque,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'statutCrea': value.statutCrea,
        'categorieSpecifiqueCrea': value.categorieSpecifiqueCrea,
        'fromCrea': value.fromCrea,
        'currentStatutFromCrea': value.currentStatutFromCrea,
        'canUseCreaStatut': value.canUseCreaStatut,
    };
}

