/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DemandeFormationEditDto
 */
export interface DemandeFormationEditDto {
    /**
     * 
     * @type {number}
     * @memberof DemandeFormationEditDto
     */
    iddemandeFormationCrea?: number;
    /**
     * 
     * @type {number}
     * @memberof DemandeFormationEditDto
     */
    idmetier?: number;
    /**
     * 
     * @type {number}
     * @memberof DemandeFormationEditDto
     */
    idmodule?: number;
    /**
     * 
     * @type {string}
     * @memberof DemandeFormationEditDto
     */
    idannee?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DemandeFormationEditDto
     */
    premierSemestre?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DemandeFormationEditDto
     */
    idstade?: number;
    /**
     * 
     * @type {number}
     * @memberof DemandeFormationEditDto
     */
    idcentre?: number;
    /**
     * 
     * @type {number}
     * @memberof DemandeFormationEditDto
     */
    idparcoursCrea?: number;
    /**
     * 
     * @type {number}
     * @memberof DemandeFormationEditDto
     */
    idstatutDemande?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DemandeFormationEditDto
     */
    editable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DemandeFormationEditDto
     */
    remarqueInvalidation?: string | null;
}

/**
 * Check if a given object implements the DemandeFormationEditDto interface.
 */
export function instanceOfDemandeFormationEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DemandeFormationEditDtoFromJSON(json: any): DemandeFormationEditDto {
    return DemandeFormationEditDtoFromJSONTyped(json, false);
}

export function DemandeFormationEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DemandeFormationEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iddemandeFormationCrea': !exists(json, 'iddemandeFormationCrea') ? undefined : json['iddemandeFormationCrea'],
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'idmodule': !exists(json, 'idmodule') ? undefined : json['idmodule'],
        'idannee': !exists(json, 'idannee') ? undefined : json['idannee'],
        'premierSemestre': !exists(json, 'premierSemestre') ? undefined : json['premierSemestre'],
        'idstade': !exists(json, 'idstade') ? undefined : json['idstade'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'idparcoursCrea': !exists(json, 'idparcoursCrea') ? undefined : json['idparcoursCrea'],
        'idstatutDemande': !exists(json, 'idstatutDemande') ? undefined : json['idstatutDemande'],
        'editable': !exists(json, 'editable') ? undefined : json['editable'],
        'remarqueInvalidation': !exists(json, 'remarqueInvalidation') ? undefined : json['remarqueInvalidation'],
    };
}

export function DemandeFormationEditDtoToJSON(value?: DemandeFormationEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iddemandeFormationCrea': value.iddemandeFormationCrea,
        'idmetier': value.idmetier,
        'idmodule': value.idmodule,
        'idannee': value.idannee,
        'premierSemestre': value.premierSemestre,
        'idstade': value.idstade,
        'idcentre': value.idcentre,
        'idparcoursCrea': value.idparcoursCrea,
        'idstatutDemande': value.idstatutDemande,
        'editable': value.editable,
        'remarqueInvalidation': value.remarqueInvalidation,
    };
}

