import { useGridState } from "nsitools-react";
import React, { useEffect } from "react";

export type baseReturnType = {
  results?: Array<any>;
  totalCount?: number;
  aggregateResults?: Array<any>;
};

export type baseSearchObject = {
  sortKey?: string[] | null;
  filter?: string | null;
  forceSkip?: number | null;
  forceTake?: number | null;
};

export interface IUseSearchApiOptions<TResults, TSearch> {
  tableState: ReturnType<typeof useGridState>;
  searchFunction: (sObj: TSearch) => TResults | Promise<TResults>;
  initialSearch?: boolean;
  debounceFilterMs?: number;
  ignoreSearchWhenFilterChanged?: boolean;
  trimSearchObjectStringValues?: boolean;
  onAbort?: () => void;
}

export function useSearchApi<TSearch extends baseSearchObject, TResults extends baseReturnType>({
  tableState,
  searchFunction,
  initialSearch = true,
  debounceFilterMs = 500,
  ignoreSearchWhenFilterChanged = false,
  trimSearchObjectStringValues = false,
  onAbort
}: IUseSearchApiOptions<TResults, TSearch>) {
  const [searchData, setSearchData] = React.useState<TSearch>();
  const { sortKeysStrings, globalFilter, skip, take, setData } = tableState;
  const [loading, setLoading] = React.useState(false);
  const [gridResults, setGridResults] = React.useState<TResults>();
  const [error, setError] = React.useState<any>();
  const [initialSearchDone, setInitialSearchDone] = React.useState(false);

  const performSearch = React.useCallback(
    async (sObj: TSearch) => {
      try {
        if (!!onAbort) onAbort();

        setLoading(true);
        const data = await searchFunction(sObj);
        setGridResults(data);
        let newData = {
          totalCount: data.totalCount || 0
        };
        setData(data.results!, newData, data.aggregateResults!);
        setLoading(false);
      } catch (error) {
        setError(error);
        if (error.name !== "AbortError" && error.cause?.name !== "AbortError") setLoading(false);
      }
    },
    [onAbort, searchFunction, setData]
  );

  const [waitingExternalSearch, setWaitingExternalSearch] = React.useState(!initialSearch);

  useEffect(() => {
    setSearchData(s => {
      return {
        ...s,
        sortKey: sortKeysStrings,
        forceSkip: skip,
        forceTake: take,
        filter: globalFilter
      };
    });
  }, [skip, sortKeysStrings, take, ignoreSearchWhenFilterChanged, globalFilter]);

  React.useEffect(() => {
    if (searchData && !waitingExternalSearch) {
      performSearch(searchData);
    }
  }, [performSearch, searchData, waitingExternalSearch]);

  const search = React.useCallback(
    (nextSearch?: TSearch) => {
      setSearchData({
        sortKey: sortKeysStrings,
        forceSkip: skip,
        forceTake: take,
        filter: globalFilter,
        ...nextSearch
      });

      setWaitingExternalSearch(false);
    },
    [globalFilter, skip, sortKeysStrings, take]
  );

  useEffect(() => {
    if (initialSearch && !initialSearchDone) {
      search();
      setInitialSearchDone(true);
    }
  }, [search, initialSearch, initialSearchDone]);

  return {
    gridResults,
    loading,
    search,
    searchData,
    error
  };
}
