import * as React from "react";
import styled from "styled-components";
import { NextButton, PreviousButton } from "..";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem 3rem;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const ContentWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;

  & .carousel-left,
  .carousel-right {
    position: absolute;
    z-index: 1;
    top: 1rem;
    transform: translateY(-50%);
  }

  & .carousel-left {
    left: -39px;
  }

  & .carousel-right {
    right: -39px;
  }
`;

const Content = styled.div`
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
  scrollbar-width: none; /* hide scrollbar in Firefox */
  &::-webkit-scrollbar {
    display: none; /* hide scrollbar in webkit browser */
  }

  & > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
  }
`;

export interface ICarouselProps {}

export const Carousel: React.FunctionComponent<ICarouselProps> = ({ children }) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const length = React.useMemo(() => React.Children.count(children), [children]);

  const next = React.useCallback(() => {
    if (currentIndex < length - 1) {
      setCurrentIndex(prevState => prevState + 1);
    }
  }, [currentIndex, length]);

  const previous = React.useCallback(() => {
    if (currentIndex > 0) {
      setCurrentIndex(prevState => prevState - 1);
    }
  }, [currentIndex]);

  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          <PreviousButton
            hideText
            intent="primary"
            className="carousel-left"
            onClick={previous}
            disabled={currentIndex === 0}
          />
          <Content style={{ transform: `translateX(-${currentIndex * 100}%)` }}>{children}</Content>
          <NextButton
            hideText
            intent="primary"
            className="carousel-right"
            onClick={next}
            disabled={currentIndex === length - 1}
          />
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};
