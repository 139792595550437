import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ButtonContainer, DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import {
  FcbFormateurLocalisationContratGridDto,
  FormateurApi,
  FormateurLocalisationContratApi,
  FormateurLocalisationContratSearch
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { AddButton, EditButton, ViewButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IFormateurLocalisationListPageProps {}

export const FormateurLocalisationListPage: React.FunctionComponent<IFormateurLocalisationListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(FormateurLocalisationContratApi);
  const formateurApi = useApiService(FormateurApi);
  const { id } = useParams<{ id: string }>();

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { libelle: "ASC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: FormateurLocalisationContratSearch) => {
      sObj.idformateur = +id;
      return api.formateurLocalisationContratBaseSearch({ FormateurLocalisationContratSearch: sObj });
    },
    [api, id]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbFormateurLocalisationContratGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() =>
                history.push(`${ERoutes.formateur}/${id}/Localisation/${row.idformateurLocalisationContrat}/view`)
              }
            />
            <EditButton
              minimal={true}
              onClick={() =>
                history.push(`${ERoutes.formateur}/${id}/Localisation/${row.idformateurLocalisationContrat}/edit`)
              }
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "libelle"
      },
      {
        header: () => t(ETLCodes.DateDebut),
        fieldName: "dateDebut"
      },
      {
        header: () => t(ETLCodes.DateFin),
        fieldName: "dateFin"
      }
    ],
    [history, id, t]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.formateur}/${id}/Localisation/0/edit`);
  }, [history, id]);

  const [cLoading, setCLoading] = React.useState(false);

  return (
    <>
      <FieldSet
        title={t(ETLCodes.TableResults, { count: totalCount })}
        rightElement={
          <ButtonContainer>
            <Button
              icon={IconNames.REPEAT}
              minimal
              onClick={async () => {
                setCLoading(true);
                const res = await formateurApi.formateurCalculDateActivite({ idFormateur: +id });
                setCLoading(false);
                if (res.value) {
                  search();
                }
              }}
              text={t(ETLCodes.CalculDates)}
              intent={Intent.PRIMARY}
              loading={cLoading}
            />
            <AddButton
              onClick={e => {
                e.stopPropagation();
                addItemFunction();
              }}
              text={t(ETLCodes.General_Add)}
              intent={Intent.PRIMARY}
            />
          </ButtonContainer>
        }
      >
        <DataTable
          dateFormat="dd/MM/yyyy"
          tableState={tableState}
          loading={loading}
          columns={columns}
          filterMode="OnEnter"
        ></DataTable>
      </FieldSet>
    </>
  );
};
