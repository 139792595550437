/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { BooleanSearch } from './BooleanSearch';
import {
    BooleanSearchFromJSON,
    BooleanSearchFromJSONTyped,
    BooleanSearchToJSON,
} from './BooleanSearch';
import type { TextSearch } from './TextSearch';
import {
    TextSearchFromJSON,
    TextSearchFromJSONTyped,
    TextSearchToJSON,
} from './TextSearch';

/**
 * 
 * @export
 * @interface SecteurSearch
 */
export interface SecteurSearch {
    /**
     * 
     * @type {TextSearch}
     * @memberof SecteurSearch
     */
    codesecteur?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof SecteurSearch
     */
    description?: TextSearch;
    /**
     * 
     * @type {BooleanSearch}
     * @memberof SecteurSearch
     */
    actif?: BooleanSearch;
    /**
     * 
     * @type {Array<string>}
     * @memberof SecteurSearch
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof SecteurSearch
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof SecteurSearch
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SecteurSearch
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof SecteurSearch
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SecteurSearch
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the SecteurSearch interface.
 */
export function instanceOfSecteurSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SecteurSearchFromJSON(json: any): SecteurSearch {
    return SecteurSearchFromJSONTyped(json, false);
}

export function SecteurSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecteurSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'codesecteur': !exists(json, 'codesecteur') ? undefined : TextSearchFromJSON(json['codesecteur']),
        'description': !exists(json, 'description') ? undefined : TextSearchFromJSON(json['description']),
        'actif': !exists(json, 'actif') ? undefined : BooleanSearchFromJSON(json['actif']),
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function SecteurSearchToJSON(value?: SecteurSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'codesecteur': TextSearchToJSON(value.codesecteur),
        'description': TextSearchToJSON(value.description),
        'actif': BooleanSearchToJSON(value.actif),
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

