import * as React from "react";
import { SearchTablePage, ViewButton, EditButton } from "../../../../../components";
import { useTl, useApiService, useAbortableApiServiceFactory } from "../../../../../hooks";
import { useHistory, useParams } from "react-router";
import {
  FormateurHoraireApi,
  FormateurHoraireGridDto,
  FormateurHoraireSearch,
  ETextSearchType,
  EDateSearchType
} from "../../../../../api";
import { ETLCodes } from "../../../../../locales";
import { ERoutes } from "../../../../../AppRouter";
import { useGlobalData } from "../../../../../contexts";
import { Checkbox } from "@blueprintjs/core";
import styled from "styled-components";
import { format, startOfToday } from "date-fns";

export interface IFormateurHoraireGridProps {}

const Container = styled.div`
  display: flex;
`;

export const FormateurHoraireGrid: React.FunctionComponent<IFormateurHoraireGridProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(FormateurHoraireApi);
  const { id } = useParams<{ id: string }>();
  const { currentAnneeScolaire } = useGlobalData();

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FormateurHoraireGridDto) => (
          <Container>
            <ViewButton minimal={true} onClick={() => history.push(`${ERoutes.classe}/${row.idclasse}/detail/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${ERoutes.classe}/${row.idclasse}/detail/edit`)} />
          </Container>
        )
      },
      {
        header: () => t(ETLCodes.Annee),
        fieldName: "annee"
      },
      {
        header: () => t(ETLCodes.Date),
        fieldName: "date"
      },
      {
        header: () => t(ETLCodes.Classe),
        fieldName: "classe"
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Heure),
        fieldName: "heureDebut"
      },
      {
        header: () => t(ETLCodes.Matiere),
        fieldName: "matiere"
      },
      {
        header: () => t(ETLCodes.Local),
        fieldName: "local"
      },
      {
        header: () => t(ETLCodes.Remplacement),
        fieldName: "remplacement",
        render: (row: FormateurHoraireGridDto) => <Checkbox checked={row.remplacement} onClick={() => {}} disabled />
      },
      {
        header: () => t(ETLCodes.Visite),
        fieldName: "visite"
      }
    ],
    [history, t]
  );

  const getCriterias = React.useCallback(() => api.formateurHoraireGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const apiFactory = useAbortableApiServiceFactory(FormateurHoraireApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (nextSearch?: FormateurHoraireSearch) => {
      nextSearch.idformateur = +id;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.formateurHoraireBaseSearch({ FormateurHoraireSearch: nextSearch });
    },
    [apiFactory, id]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  const today = React.useMemo(() => startOfToday(), []);

  return (
    <SearchTablePage
      title={ETLCodes.Horaire}
      columns={columns}
      getCriteriasFunction={getCriterias}
      searchFunction={search}
      onAbort={onAbort}
      withCard={false}
      sortKeys={{ date: "ASC", heureDebut: "ASC" }}
      searchStateInitialSearch={false}
      defaultCriterias={[
        {
          criteria: "Annee",
          searchMode: ETextSearchType.Equals,
          value: currentAnneeScolaire.description
        },
        {
          criteria: "Date",
          searchMode: EDateSearchType.GreaterThan,
          value: format(today, "yyyy-MM-dd")
        }
      ]}
    />
  );
};
