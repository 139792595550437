/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EListSearchType } from './EListSearchType';
import {
    EListSearchTypeFromJSON,
    EListSearchTypeFromJSONTyped,
    EListSearchTypeToJSON,
} from './EListSearchType';

/**
 * 
 * @export
 * @interface EListSearchTypeInt32NullableCombinableCriteria
 */
export interface EListSearchTypeInt32NullableCombinableCriteria {
    /**
     * 
     * @type {EListSearchType}
     * @memberof EListSearchTypeInt32NullableCombinableCriteria
     */
    searchMode?: EListSearchType;
    /**
     * 
     * @type {number}
     * @memberof EListSearchTypeInt32NullableCombinableCriteria
     */
    value?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EListSearchTypeInt32NullableCombinableCriteria
     */
    secondaryValue?: number | null;
}

/**
 * Check if a given object implements the EListSearchTypeInt32NullableCombinableCriteria interface.
 */
export function instanceOfEListSearchTypeInt32NullableCombinableCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EListSearchTypeInt32NullableCombinableCriteriaFromJSON(json: any): EListSearchTypeInt32NullableCombinableCriteria {
    return EListSearchTypeInt32NullableCombinableCriteriaFromJSONTyped(json, false);
}

export function EListSearchTypeInt32NullableCombinableCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): EListSearchTypeInt32NullableCombinableCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchMode': !exists(json, 'searchMode') ? undefined : EListSearchTypeFromJSON(json['searchMode']),
        'value': !exists(json, 'value') ? undefined : json['value'],
        'secondaryValue': !exists(json, 'secondaryValue') ? undefined : json['secondaryValue'],
    };
}

export function EListSearchTypeInt32NullableCombinableCriteriaToJSON(value?: EListSearchTypeInt32NullableCombinableCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchMode': EListSearchTypeToJSON(value.searchMode),
        'value': value.value,
        'secondaryValue': value.secondaryValue,
    };
}

