/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbDiplomeGridDto
 */
export interface FcbDiplomeGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbDiplomeGridDto
     */
    iddiplome?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbDiplomeGridDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDiplomeGridDto
     */
    iddiplomeGuid?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbDiplomeGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbDiplomeGridDto interface.
 */
export function instanceOfFcbDiplomeGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbDiplomeGridDtoFromJSON(json: any): FcbDiplomeGridDto {
    return FcbDiplomeGridDtoFromJSONTyped(json, false);
}

export function FcbDiplomeGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbDiplomeGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iddiplome': !exists(json, 'iddiplome') ? undefined : json['iddiplome'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'iddiplomeGuid': !exists(json, 'iddiplomeGuid') ? undefined : json['iddiplomeGuid'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbDiplomeGridDtoToJSON(value?: FcbDiplomeGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iddiplome': value.iddiplome,
        'libelle': value.libelle,
        'iddiplomeGuid': value.iddiplomeGuid,
        'actif': value.actif,
    };
}

