import { FGMaskInput, FGTextAreaInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";

import { ExamenEntreeApi, FcbExamenEntreeDtoFromJSON } from "../../../../../api";
import { FGWalterCheckboxInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IExamenEntreeDetailProps {
  idpersonne: number;
  listRoute: string;
}

export const ExamenEntreeDetail: React.FunctionComponent<IExamenEntreeDetailProps> = ({ idpersonne, listRoute }) => {
  const { t } = useTl();
  const api = useApiService(ExamenEntreeApi);
  const { idExamen, state } = useParams<{ id: string; idExamen: string; state: string }>();
  const idExamenEntree = React.useMemo(() => +idExamen, [idExamen]);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving, validationErrors } = useCrudApi({
    getApiFn: () =>
      +idExamenEntree <= 0
        ? FcbExamenEntreeDtoFromJSON({ idexamenEntree: 0, idpersonne: idpersonne, typeAcquis: "ExamenEntree" })
        : api.examenEntreeGet({ id: +idExamenEntree }),
    saveApiFn: d => api.examenEntreeSave({ FcbExamenEntreeDto: d }),
    onSavedRoute: d => listRoute,
    deleteApiFn: d => api.examenEntreeDelete({ id: d.idexamenEntree }),
    onDeletedRoute: () => listRoute
  });

  const validationSchema = React.useMemo(() => {
    return Yup.object().shape({
      annee: Yup.number().max(9999),
      pointsmath: Yup.number(),
      pointsfrancais: Yup.number(),
      remarque: Yup.string().required(t(ETLCodes.Required)),
      reussite: Yup.boolean()
    });
  }, [t]);

  return (
    <SmallFormGenerator
      initialValues={data}
      validationSchema={validationSchema}
      onSubmit={saveItem}
      editMode={state === "edit"}
      saving={saving}
      loading={loading}
      onDelete={deleteItem}
      deleting={deleting}
      showDeleteButton={idExamenEntree >= 0}
      onCancel={() => history.push(listRoute)}
      validationErrors={validationErrors}
    >
      <FGMaskInput label={t(ETLCodes.AnneeScolaire)} name="annee" cleaveOptions={{ numericOnly: true, blocks: [4] }} />
      <FieldGroup columns={3}>
        <FGMaskInput label={t(ETLCodes.PointsMathematiques)} name="pointsmath" cleaveOptions={{ numeral: true }} />
        <FGMaskInput label={t(ETLCodes.PointsFrancais)} name="pointsfrancais" cleaveOptions={{ numeral: true }} />
        <FGMaskInput label={t(ETLCodes.PointsLogique)} name="pointslogique" cleaveOptions={{ numeral: true }} />
      </FieldGroup>
      <FGTextAreaInput label={t(ETLCodes.Remarque)} name="remarque" />
      <FGWalterCheckboxInput label={t(ETLCodes.Reussite)} name="reussite" />
    </SmallFormGenerator>
  );
};
