import { Button, Checkbox, Intent, Menu, Spinner } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { MenuItem2, Popover2 } from "@blueprintjs/popover2";
import { useSessionStorageState } from "ahooks";
import moment from "moment";
import { ButtonContainer, showError, showInfo, showSuccess } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import styled, { css } from "styled-components";

import {
  DeliberationTypeApi,
  DeliberationTypeSearch,
  DeliberationValidateIdsDto,
  ETextSearchType,
  FcbRechercheApprenantDeliberationTypeDto,
  FichierApi,
  SelectItem,
  UtilisateurApi
} from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../components";
import {
  useAuth,
  useDialog,
  useEventsContext,
  useGlobalData,
  usePrintingQueueContext,
  useRouteParamsContext
} from "../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useManageError, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { b64toBlob } from "../../../../utils";

const PopoverContent = styled.div`
  padding: 0.5rem;
`;

export interface IDeliberationTypeListPageProps {}

export function useLastDeliberationSearchObject() {
  const [lastDeliberationTypeSearch, setlastDeliberationTypeSearch] = useSessionStorageState<DeliberationTypeSearch>(
    "lastDeliberationTypeSearchObject",
    null
  );

  return {
    lastDeliberationTypeSearch,
    setlastDeliberationTypeSearch
  };
}

enum EDeliberationValidateAction {
  Validate = "Validate",
  Invalidate1Sess = "Invalidate1Sess",
  Invalidate2Sess = "Invalidate2Sess"
}

export const DeliberationTypeListPage: React.FunctionComponent<IDeliberationTypeListPageProps> = () => {
  const { t } = useTl();
  const { user } = useAuth();
  const history = useHistory();
  const api = useApiService(DeliberationTypeApi);
  const userApi = useApiService(UtilisateurApi);
  const { currentAnneeScolaire } = useGlobalData();
  const { pushIdbulletinApprenantType } = useRouteParamsContext();
  const { dispatchEvent } = useEventsContext();
  const { showDialogPromise } = useDialog();
  const {
    refreshQueue,
    setIsQueueForcedOpen,
    queuePrintings,
    removePreviousPrintings,
    hasError,
    setHasError
  } = usePrintingQueueContext();
  const { manageError } = useManageError();

  React.useEffect(() => {
    if (hasError) {
      showError(t(ETLCodes.AnErrorOccuredWhilePrintingAr));
      setHasError(false);
    }
  }, [hasError, setHasError, t]);

  const fetchUserCanValidate = React.useCallback(async () => {
    const res = await userApi.utilisateurGetUserValidationDirectionRight();
    return res.value;
  }, [userApi]);

  const { data: hasRightToDevalidate } = useQuery("user-can-validate", fetchUserCanValidate, {
    enabled: true,
    cacheTime: 0
  });

  const [selectedIds, setSelectedIds] = React.useState<DeliberationValidateIdsDto>({
    allIds: [],
    idsToValidate: [],
    ids1SessToInvalidate: [],
    ids2SessToInvalidate: []
  });

  const { lastDeliberationTypeSearch, setlastDeliberationTypeSearch } = useLastDeliberationSearchObject();

  const fetchDeliberationIds = React.useCallback(async () => {
    if (!lastDeliberationTypeSearch?.anneeScolaire) return null;

    return await api.deliberationTypeSearchDeliberationIds({
      DeliberationTypeSearch: { ...lastDeliberationTypeSearch, forceSkip: 0, forceTake: 999999 }
    });
  }, [api, lastDeliberationTypeSearch]);

  const { data: deliberationIds, isLoading: deliberationIdsLoading, refetch } = useQuery(
    ["lastDeliberationTypeSearch", lastDeliberationTypeSearch],
    fetchDeliberationIds
  );

  const toggleSelection = React.useCallback(
    (item: FcbRechercheApprenantDeliberationTypeDto, action: EDeliberationValidateAction) => {
      let newSelectedIds: DeliberationValidateIdsDto = {
        allIds: [],
        idsToValidate: [],
        ids1SessToInvalidate: [],
        ids2SessToInvalidate: []
      };

      switch (action) {
        case EDeliberationValidateAction.Validate:
          if (!selectedIds.idsToValidate.includes(item.idbulletinApprenantType)) {
            newSelectedIds = {
              allIds: selectedIds.allIds,
              ids1SessToInvalidate: selectedIds.ids1SessToInvalidate,
              ids2SessToInvalidate: selectedIds.ids2SessToInvalidate,
              idsToValidate: [...selectedIds.idsToValidate, item.idbulletinApprenantType]
            };
          } else {
            newSelectedIds = {
              allIds: selectedIds.allIds,
              ids1SessToInvalidate: selectedIds.ids1SessToInvalidate,
              ids2SessToInvalidate: selectedIds.ids2SessToInvalidate,
              idsToValidate: selectedIds.idsToValidate.filter(id => id !== item.idbulletinApprenantType)
            };
          }

          break;
        case EDeliberationValidateAction.Invalidate1Sess:
          if (!selectedIds.ids1SessToInvalidate.includes(item.idbulletinApprenantType)) {
            newSelectedIds = {
              allIds: selectedIds.allIds,
              ids1SessToInvalidate: [...selectedIds.ids1SessToInvalidate, item.idbulletinApprenantType],
              ids2SessToInvalidate: selectedIds.ids2SessToInvalidate,
              idsToValidate: selectedIds.idsToValidate
            };
          } else {
            newSelectedIds = {
              allIds: selectedIds.allIds,
              ids1SessToInvalidate: selectedIds.ids1SessToInvalidate.filter(id => id !== item.idbulletinApprenantType),
              ids2SessToInvalidate: selectedIds.ids2SessToInvalidate,
              idsToValidate: selectedIds.idsToValidate
            };
          }

          break;
        case EDeliberationValidateAction.Invalidate2Sess:
          if (!selectedIds.ids2SessToInvalidate.includes(item.idbulletinApprenantType)) {
            newSelectedIds = {
              allIds: selectedIds.allIds,
              ids1SessToInvalidate: selectedIds.ids1SessToInvalidate,
              ids2SessToInvalidate: [...selectedIds.ids2SessToInvalidate, item.idbulletinApprenantType],
              idsToValidate: selectedIds.idsToValidate
            };
          } else {
            newSelectedIds = {
              allIds: selectedIds.allIds,
              ids1SessToInvalidate: selectedIds.ids1SessToInvalidate,
              ids2SessToInvalidate: selectedIds.ids2SessToInvalidate.filter(id => id !== item.idbulletinApprenantType),
              idsToValidate: selectedIds.idsToValidate
            };
          }
          break;
        default:
          break;
      }

      setSelectedIds(newSelectedIds);
    },
    [selectedIds]
  );

  const selectAll = React.useCallback(
    (action: EDeliberationValidateAction) => {
      let newSelectedIds: DeliberationValidateIdsDto = {
        allIds: [],
        idsToValidate: [],
        ids1SessToInvalidate: [],
        ids2SessToInvalidate: []
      };

      switch (action) {
        case EDeliberationValidateAction.Validate:
          if (deliberationIds?.allIds.length === selectedIds.idsToValidate.length) {
            newSelectedIds = {
              allIds: selectedIds.allIds,
              ids1SessToInvalidate: selectedIds.ids1SessToInvalidate,
              ids2SessToInvalidate: selectedIds.ids2SessToInvalidate,
              idsToValidate: []
            };
          } else {
            newSelectedIds = {
              allIds: selectedIds.allIds,
              ids1SessToInvalidate: selectedIds.ids1SessToInvalidate,
              ids2SessToInvalidate: selectedIds.ids2SessToInvalidate,
              idsToValidate: deliberationIds?.allIds
            };
          }
          break;
        case EDeliberationValidateAction.Invalidate1Sess:
          if (deliberationIds?.ids1SessToInvalidate.length === selectedIds.ids1SessToInvalidate.length) {
            newSelectedIds = {
              allIds: selectedIds.allIds,
              ids1SessToInvalidate: [],
              ids2SessToInvalidate: selectedIds.ids2SessToInvalidate,
              idsToValidate: selectedIds.idsToValidate
            };
          } else {
            newSelectedIds = {
              allIds: selectedIds.allIds,
              ids1SessToInvalidate: deliberationIds?.ids1SessToInvalidate,
              ids2SessToInvalidate: selectedIds.ids2SessToInvalidate,
              idsToValidate: selectedIds.idsToValidate
            };
          }
          break;
        case EDeliberationValidateAction.Invalidate2Sess:
          if (deliberationIds?.ids2SessToInvalidate.length === selectedIds.ids2SessToInvalidate.length) {
            newSelectedIds = {
              allIds: selectedIds.allIds,
              ids1SessToInvalidate: selectedIds.ids1SessToInvalidate,
              ids2SessToInvalidate: [],
              idsToValidate: selectedIds.idsToValidate
            };
          } else {
            newSelectedIds = {
              allIds: selectedIds.allIds,
              ids1SessToInvalidate: selectedIds.ids1SessToInvalidate,
              ids2SessToInvalidate: deliberationIds?.ids2SessToInvalidate,
              idsToValidate: selectedIds.idsToValidate
            };
          }
          break;
        default:
          break;
      }

      setSelectedIds(newSelectedIds);
    },
    [deliberationIds, selectedIds]
  );

  const isSelectboxShown = React.useCallback(
    (item: FcbRechercheApprenantDeliberationTypeDto, action: EDeliberationValidateAction) => {
      switch (action) {
        case EDeliberationValidateAction.Validate:
          if (
            !deliberationIds?.ids1SessToInvalidate.includes(item.idbulletinApprenantType) &&
            !deliberationIds?.ids2SessToInvalidate.includes(item.idbulletinApprenantType)
          ) {
            return (
              <Checkbox
                checked={selectedIds.idsToValidate.includes(item.idbulletinApprenantType)}
                onChange={e => {
                  toggleSelection(item, EDeliberationValidateAction.Validate);
                }}
              />
            );
          } else {
            return <></>;
          }
        case EDeliberationValidateAction.Invalidate1Sess:
          if (!!item.validationDirection1sess) {
            return (
              <Checkbox
                label={moment(item.validationDirection1sess)
                  .locale("fr")
                  .utc(true)
                  .format("DD-MM-YYYY")}
                checked={selectedIds.ids1SessToInvalidate.includes(item.idbulletinApprenantType)}
                onChange={e => {
                  toggleSelection(item, EDeliberationValidateAction.Invalidate1Sess);
                }}
                disabled={!!item.validationDirection2sess}
              />
            );
          } else {
            return <></>;
          }
        case EDeliberationValidateAction.Invalidate2Sess:
          if (deliberationIds?.ids2SessToInvalidate.includes(item.idbulletinApprenantType)) {
            return (
              <Checkbox
                label={moment(item.validationDirection2sess)
                  .locale("fr")
                  .utc(true)
                  .format("DD-MM-YYYY")}
                checked={selectedIds.ids2SessToInvalidate.includes(item.idbulletinApprenantType)}
                onChange={e => {
                  toggleSelection(item, EDeliberationValidateAction.Invalidate2Sess);
                }}
              />
            );
          } else {
            return <></>;
          }
      }
    },
    [
      deliberationIds?.ids1SessToInvalidate,
      deliberationIds?.ids2SessToInvalidate,
      selectedIds.ids1SessToInvalidate,
      selectedIds.ids2SessToInvalidate,
      selectedIds.idsToValidate,
      toggleSelection
    ]
  );

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        header: () =>
          deliberationIdsLoading ? (
            <Spinner size={20} />
          ) : (
            <Checkbox
              checked={
                deliberationIds?.allIds.length > 0 &&
                deliberationIds?.allIds.length === selectedIds.idsToValidate.length
              }
              onChange={() => selectAll(EDeliberationValidateAction.Validate)}
            />
          ),
        render: (item: FcbRechercheApprenantDeliberationTypeDto) =>
          isSelectboxShown(item, EDeliberationValidateAction.Validate)
      },
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbRechercheApprenantDeliberationTypeDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => {
                pushIdbulletinApprenantType(row.idbulletinApprenantType);
                history.push(
                  `${ERoutes.deliberationType}/${row.idbulletinApprenantType}/detail/${row.idapprenant}/${row.anneeScolaire}/${row.idtypeCours}/view`
                );
              }}
            />
            <EditButton
              minimal={true}
              onClick={() => {
                pushIdbulletinApprenantType(row.idbulletinApprenantType);
                history.push(
                  `${ERoutes.deliberationType}/${row.idbulletinApprenantType}/detail/${row.idapprenant}/${row.anneeScolaire}/${row.idtypeCours}/edit`
                );
              }}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.DateNaissance),
        fieldName: "dateNaissance"
      },
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "anneeScolaire"
      },
      {
        header: () => t(ETLCodes.TypeCours),
        fieldName: "idtypeCours"
      },
      {
        header: () => t(ETLCodes.Deliberation),
        fieldName: "deliberation",
        customizeCellStyle: () =>
          css`
            white-space: pre;
          `
      },
      {
        header: () =>
          deliberationIdsLoading ? (
            <Spinner size={20} />
          ) : (
            <Checkbox
              label={t(ETLCodes.ValidationDirection1Sess)}
              checked={deliberationIds?.ids1SessToInvalidate.length === selectedIds.ids1SessToInvalidate.length}
              onChange={e => {
                selectAll(EDeliberationValidateAction.Invalidate1Sess);
              }}
            />
          ),
        disableSorting: true,
        fieldName: "validationDirection1sess",
        render: (item: FcbRechercheApprenantDeliberationTypeDto) =>
          isSelectboxShown(item, EDeliberationValidateAction.Invalidate1Sess),
        customizeCellStyle: () =>
          css`
            color: limegreen !important;
          `
      },
      {
        header: () => t(ETLCodes.DeliberationSecondeSessions),
        fieldName: "deliberation2sess",
        customizeCellStyle: () =>
          css`
            white-space: pre;
          `
      },
      {
        header: () =>
          deliberationIds?.ids2SessToInvalidate.length <= 0 ? (
            t(ETLCodes.ValidationDirection2Sess)
          ) : deliberationIdsLoading ? (
            <Spinner size={20} />
          ) : (
            <Checkbox
              label={t(ETLCodes.ValidationDirection2Sess)}
              checked={deliberationIds?.ids2SessToInvalidate.length === selectedIds.ids2SessToInvalidate.length}
              onChange={e => {
                selectAll(EDeliberationValidateAction.Invalidate2Sess);
              }}
            />
          ),
        disableSorting: true,
        fieldName: "validationDirection2sess",
        render: (item: FcbRechercheApprenantDeliberationTypeDto) =>
          isSelectboxShown(item, EDeliberationValidateAction.Invalidate2Sess),
        customizeCellStyle: () =>
          css`
            color: limegreen !important;
          `
      },
      {
        header: () => t(ETLCodes.Metier),
        fieldName: "metier"
      },
      {
        header: () => t(ETLCodes.Degre),
        fieldName: "degre"
      },
      {
        header: () => t(ETLCodes.ClasseIdentifiant),
        fieldName: "idclasse"
      }
    ],
    [
      deliberationIdsLoading,
      deliberationIds?.allIds.length,
      deliberationIds?.ids1SessToInvalidate.length,
      deliberationIds?.ids2SessToInvalidate.length,
      selectedIds.idsToValidate.length,
      selectedIds.ids1SessToInvalidate.length,
      selectedIds.ids2SessToInvalidate.length,
      selectAll,
      isSelectboxShown,
      pushIdbulletinApprenantType,
      history,
      t
    ]
  );

  const apiFactory = useAbortableApiServiceFactory(DeliberationTypeApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFunction = React.useCallback(
    (sObj?: DeliberationTypeSearch) => {
      setlastDeliberationTypeSearch(sObj);
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.deliberationTypeBaseSearch({ DeliberationTypeSearch: sObj });
    },
    [apiFactory, setlastDeliberationTypeSearch]
  );

  const [centres, setCentres] = React.useState<SelectItem[]>([]);
  const getCriteriaFunction = React.useCallback(async () => {
    var res = await api.deliberationTypeGetSearchCriterias({ includeListsValues: true });
    setCentres(res.find(c => c.criteria === "Idcentre")?.listValues);
    return res;
  }, [api]);

  const [isSaving, setIsSaving] = React.useState(false);

  const invalidateBulletins = React.useCallback(async () => {
    try {
      setIsSaving(true);
      var returnValue = await api.deliberationTypeInvalidateBulletins({ DeliberationValidateIdsDto: selectedIds });
      if (returnValue) {
        dispatchEvent("SEARCH_TABLE_REFRESH", lastDeliberationTypeSearch);
        refetch();
      } else {
        showError(t(ETLCodes.MustWaitAfterGenerationBeforeInvalidating));
      }
    } catch (e) {
      showError(t(ETLCodes.ErrorWhileInvalidation));
    } finally {
      setIsSaving(false);
    }
  }, [api, dispatchEvent, lastDeliberationTypeSearch, refetch, selectedIds, t]);

  const [totalCountLoading, setTotalCountLoading] = React.useState(false);
  const triggerBulletinGeneration = React.useCallback(
    async (sess2: boolean, validate: boolean) => {
      if (
        !lastDeliberationTypeSearch?.anneeScolaire ||
        (!lastDeliberationTypeSearch?.idcentre &&
          !lastDeliberationTypeSearch?.idclasse &&
          !lastDeliberationTypeSearch?.degre &&
          !lastDeliberationTypeSearch?.metier &&
          !lastDeliberationTypeSearch?.idtypeCours)
      ) {
        showError(t(ETLCodes.SearchAtLeastAnneeAndCentre));
        return;
      }

      const startYear = lastDeliberationTypeSearch.anneeScolaire?.criterias?.[0]?.value?.split("-")[0];
      if (!startYear || +startYear < 2023) {
        showError(t(ETLCodes.GenerationIndisponibleAvant20232024));
        return;
      }

      let totalCount = 0;

      const addIds = !validate ? [...selectedIds.ids1SessToInvalidate, ...selectedIds.ids2SessToInvalidate] : [];
      const finalSelectedIds = [
        ...selectedIds.idsToValidate.filter(id => !validate || deliberationIds?.idsToValidate.includes(id)),
        ...addIds
      ];
      try {
        setTotalCountLoading(true);
        const sObj: DeliberationTypeSearch = {
          ...lastDeliberationTypeSearch,
          forceTake: 0,
          forceSkip: 0,
          canPrintBulletin: validate ? true : null,
          has2Sess: sess2,
          selectedIds: finalSelectedIds
        };
        const sRes = await api.deliberationTypeBaseSearch({ DeliberationTypeSearch: sObj });
        totalCount = sRes.totalCount;
      } catch (e) {
        console.error(e);
        showError(t(ETLCodes.ErrorWhileAddingBulletinToQueue));
        return;
      } finally {
        setTotalCountLoading(false);
      }

      if (totalCount === 0) {
        showError(t(ETLCodes.NoAvisToGenerate));
        return;
      }

      let messagePart1 = "";
      if (!!lastDeliberationTypeSearch?.idcentre) {
        const centre = centres.find(c => +c.idValue === +lastDeliberationTypeSearch?.idcentre?.criterias?.[0]?.value)
          ?.displayValue;
        messagePart1 = t(ETLCodes.BulletinGenerationWarningNb, { nb: totalCount, centre: centre });
      } else {
        const classe = lastDeliberationTypeSearch?.idclasse?.criterias?.[0]?.value;
        messagePart1 = t(ETLCodes.BulletinGenerationWarningNbClasse, { nb: totalCount, classe: classe });
      }
      const res = await showDialogPromise({
        title: t(ETLCodes.Attention),
        message: `${messagePart1}${
          !!lastDeliberationTypeSearch?.metier
            ? " " +
              t(ETLCodes.BulletinGenerationWarningMetier, {
                metier: lastDeliberationTypeSearch?.metier.criterias?.[0]?.value
              })
            : ""
        }. ${t(ETLCodes.BulletinGenerationWarningQuestion)}`
      });
      if (res === "yes") {
        if (queuePrintings?.length > 0) {
          const res = await showDialogPromise({
            title: t(ETLCodes.AnnulerMasquerGenerationPrecedente),
            message: t(ETLCodes.AnnulerMasquerGenerationPrecedenteMessage)
          });
          if (res === "yes") {
            await removePreviousPrintings();
          }
        }

        try {
          setTotalCountLoading(true);
          showInfo(t(ETLCodes.AddingBulletinToGenerationQueue));
          await api.deliberationTypeSendToPrintingQueue({
            DeliberationTypeSearch: {
              ...lastDeliberationTypeSearch,
              selectedIds: finalSelectedIds,
              has2Sess: sess2,
              canPrintBulletin: validate ? true : null
            },
            generate2Sess: sess2,
            validate
          });
          refreshQueue();
          setIsQueueForcedOpen(true);
          showSuccess(t(ETLCodes.BulletinsAddedToQueue));
          dispatchEvent("SEARCH_TABLE_REFRESH", lastDeliberationTypeSearch);
          refetch();
          setSelectedIds({ ids1SessToInvalidate: [], ids2SessToInvalidate: [], idsToValidate: [] });
        } catch (e) {
          console.error(e);
          manageError(e, t(ETLCodes.ErrorWhileAddingBulletinToQueue));
        } finally {
          setTotalCountLoading(false);
        }
      }
    },
    [
      api,
      centres,
      deliberationIds?.idsToValidate,
      dispatchEvent,
      lastDeliberationTypeSearch,
      manageError,
      queuePrintings?.length,
      refetch,
      refreshQueue,
      removePreviousPrintings,
      selectedIds.ids1SessToInvalidate,
      selectedIds.ids2SessToInvalidate,
      selectedIds.idsToValidate,
      setIsQueueForcedOpen,
      showDialogPromise,
      t
    ]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  const fApi = useApiService(FichierApi);
  const downloadAvisValides = React.useCallback(
    async (sess2: boolean) => {
      const finalSelectedIds = [
        ...selectedIds.idsToValidate,
        ...selectedIds.ids1SessToInvalidate,
        ...selectedIds.ids2SessToInvalidate
      ];
      const sObj: DeliberationTypeSearch = {
        ...lastDeliberationTypeSearch,
        forceTake: 0,
        forceSkip: 0,
        has2Sess: sess2,
        selectedIds: finalSelectedIds
      };
      try {
        setIsSaving(true);
        const idsfichier = await api.deliberationTypeGetAvisResultatsIdsFichier({
          DeliberationTypeSearch: sObj,
          sess2
        });
        if (idsfichier.length === 0) {
          showInfo(t(ETLCodes.AucunFichierATelecharger));
          return;
        }
        const zip = await fApi.fichierMassDownloadPdf({
          MassDownloadRequestDto: { idsfichier: idsfichier }
        });
        if (!zip) {
          showInfo(t(ETLCodes.AucunFichierATelecharger));
          return;
        }
        saveAs(b64toBlob(zip), zip.fileName);
      } catch (e) {
        showError(t(ETLCodes.ErrorWhileDownloadingPrinting));
        console.error(e);
      } finally {
        setIsSaving(false);
      }
    },
    [
      api,
      fApi,
      lastDeliberationTypeSearch,
      selectedIds.ids1SessToInvalidate,
      selectedIds.ids2SessToInvalidate,
      selectedIds.idsToValidate,
      t
    ]
  );

  const globalActions = React.useMemo(
    () => (
      <Popover2
        position="bottom-left"
        content={
          <PopoverContent>
            <Menu>
              {hasRightToDevalidate && (
                <>
                  <MenuItem2
                    icon={IconNames.Tick}
                    text={t(ETLCodes.ValidateAvisResultats1Sess)}
                    intent={Intent.PRIMARY}
                    onClick={() => triggerBulletinGeneration(false, true)}
                  />
                  <MenuItem2
                    icon={IconNames.Tick}
                    text={t(ETLCodes.ValidateAvisResultats2Sess)}
                    intent={Intent.PRIMARY}
                    onClick={() => triggerBulletinGeneration(true, true)}
                  />
                  <MenuItem2
                    icon={IconNames.Cross}
                    text={t(ETLCodes.InvalidateAvisResultats)}
                    intent={Intent.PRIMARY}
                    onClick={invalidateBulletins}
                  />
                </>
              )}
              <MenuItem2
                icon={IconNames.DocumentOpen}
                text={t(ETLCodes.GenererAvisResultats1Sess)}
                intent={Intent.PRIMARY}
                onClick={() => triggerBulletinGeneration(false, false)}
              />
              <MenuItem2
                icon={IconNames.DocumentOpen}
                text={t(ETLCodes.GenererAvisResultats2Sess)}
                intent={Intent.PRIMARY}
                onClick={() => triggerBulletinGeneration(true, false)}
              />
              <MenuItem2
                icon={IconNames.Download}
                text={t(ETLCodes.DownloadAvisResultats1S)}
                intent={Intent.PRIMARY}
                onClick={() => downloadAvisValides(false)}
              />
              <MenuItem2
                icon={IconNames.Download}
                text={t(ETLCodes.DownloadAvisResultats2S)}
                intent={Intent.PRIMARY}
                onClick={() => downloadAvisValides(true)}
              />
            </Menu>
          </PopoverContent>
        }
      >
        <Button
          icon={IconNames.CHEVRON_DOWN}
          text={t(ETLCodes.GlobalActions)}
          minimal={true}
          intent={Intent.PRIMARY}
          loading={totalCountLoading || isSaving}
        ></Button>
      </Popover2>
    ),
    [
      downloadAvisValides,
      hasRightToDevalidate,
      invalidateBulletins,
      isSaving,
      t,
      totalCountLoading,
      triggerBulletinGeneration
    ]
  );

  const defaultCriterias = React.useMemo(
    () => [
      {
        criteria: "AnneeScolaire",
        searchMode: ETextSearchType.Equals,
        value: currentAnneeScolaire?.idanneeScolaire,
        mandatory: true
      },
      {
        criteria: "Idcentre",
        searchMode: ETextSearchType.Equals,
        value: user?.idscentre[0].toString()
      }
    ],
    [currentAnneeScolaire?.idanneeScolaire, user?.idscentre]
  );

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteriaFunction}
      searchFunction={searchFunction}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.DeliberationType), route: ERoutes.deliberationType }]}
      sortKeys={{ nom: "ASC" }}
      defaultCriterias={defaultCriterias}
      searchStateInitialSearch={false}
      rightElement={globalActions}
    />
  );
};
