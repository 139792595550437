/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContactGridDto } from './ContactGridDto';
import {
    ContactGridDtoFromJSON,
    ContactGridDtoFromJSONTyped,
    ContactGridDtoToJSON,
} from './ContactGridDto';
import type { IAggregateResult } from './IAggregateResult';
import {
    IAggregateResultFromJSON,
    IAggregateResultFromJSONTyped,
    IAggregateResultToJSON,
} from './IAggregateResult';

/**
 * 
 * @export
 * @interface ContactGridDtoPaginatedResults
 */
export interface ContactGridDtoPaginatedResults {
    /**
     * 
     * @type {number}
     * @memberof ContactGridDtoPaginatedResults
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<ContactGridDto>}
     * @memberof ContactGridDtoPaginatedResults
     */
    results?: Array<ContactGridDto> | null;
    /**
     * 
     * @type {Array<IAggregateResult>}
     * @memberof ContactGridDtoPaginatedResults
     */
    aggregateResults?: Array<IAggregateResult> | null;
}

/**
 * Check if a given object implements the ContactGridDtoPaginatedResults interface.
 */
export function instanceOfContactGridDtoPaginatedResults(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContactGridDtoPaginatedResultsFromJSON(json: any): ContactGridDtoPaginatedResults {
    return ContactGridDtoPaginatedResultsFromJSONTyped(json, false);
}

export function ContactGridDtoPaginatedResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactGridDtoPaginatedResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'results': !exists(json, 'results') ? undefined : (json['results'] === null ? null : (json['results'] as Array<any>).map(ContactGridDtoFromJSON)),
        'aggregateResults': !exists(json, 'aggregateResults') ? undefined : (json['aggregateResults'] === null ? null : (json['aggregateResults'] as Array<any>).map(IAggregateResultFromJSON)),
    };
}

export function ContactGridDtoPaginatedResultsToJSON(value?: ContactGridDtoPaginatedResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'results': value.results === undefined ? undefined : (value.results === null ? null : (value.results as Array<any>).map(ContactGridDtoToJSON)),
        'aggregateResults': value.aggregateResults === undefined ? undefined : (value.aggregateResults === null ? null : (value.aggregateResults as Array<any>).map(IAggregateResultToJSON)),
    };
}

