/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbClasseCoutMatiereDto
 */
export interface FcbClasseCoutMatiereDto {
    /**
     * 
     * @type {number}
     * @memberof FcbClasseCoutMatiereDto
     */
    coutInitial?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseCoutMatiereDto
     */
    nbrInscrits?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseCoutMatiereDto
     */
    coutGlobal?: number;
}

/**
 * Check if a given object implements the FcbClasseCoutMatiereDto interface.
 */
export function instanceOfFcbClasseCoutMatiereDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbClasseCoutMatiereDtoFromJSON(json: any): FcbClasseCoutMatiereDto {
    return FcbClasseCoutMatiereDtoFromJSONTyped(json, false);
}

export function FcbClasseCoutMatiereDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbClasseCoutMatiereDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'coutInitial': !exists(json, 'coutInitial') ? undefined : json['coutInitial'],
        'nbrInscrits': !exists(json, 'nbrInscrits') ? undefined : json['nbrInscrits'],
        'coutGlobal': !exists(json, 'coutGlobal') ? undefined : json['coutGlobal'],
    };
}

export function FcbClasseCoutMatiereDtoToJSON(value?: FcbClasseCoutMatiereDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'coutInitial': value.coutInitial,
        'nbrInscrits': value.nbrInscrits,
        'coutGlobal': value.coutGlobal,
    };
}

