/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbClasseMatiereDto
 */
export interface FcbClasseMatiereDto {
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereDto
     */
    idclasseMatiere?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereDto
     */
    idclasse?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseMatiereDto
     */
    idmatiere?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereDto
     */
    idreferentiel?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereDto
     */
    idformateur?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereDto
     */
    idformateurLocalisationContrat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereDto
     */
    idlocal?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseMatiereDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseMatiereDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereDto
     */
    section?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereDto
     */
    nbheures?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereDto
     */
    travail?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereDto
     */
    pcReussite?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereDto
     */
    examen?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbClasseMatiereDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbClasseMatiereDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereDto
     */
    categorie?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereDto
     */
    idprojetFinancement?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseMatiereDto
     */
    isfinancementReferentiel?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereDto
     */
    reelHours?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereDto
     */
    agrementHoursS1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereDto
     */
    agrementHoursS2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereDto
     */
    niveau?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseMatiereDto
     */
    uaa?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseMatiereDto
     */
    isVisibleBulletin?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseMatiereDto
     */
    sommative?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereDto
     */
    idmatiereParent?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseMatiereDto
     */
    isCut?: boolean;
}

/**
 * Check if a given object implements the FcbClasseMatiereDto interface.
 */
export function instanceOfFcbClasseMatiereDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbClasseMatiereDtoFromJSON(json: any): FcbClasseMatiereDto {
    return FcbClasseMatiereDtoFromJSONTyped(json, false);
}

export function FcbClasseMatiereDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbClasseMatiereDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idclasseMatiere': !exists(json, 'idclasseMatiere') ? undefined : json['idclasseMatiere'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'idmatiere': !exists(json, 'idmatiere') ? undefined : json['idmatiere'],
        'idreferentiel': !exists(json, 'idreferentiel') ? undefined : json['idreferentiel'],
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'idformateurLocalisationContrat': !exists(json, 'idformateurLocalisationContrat') ? undefined : json['idformateurLocalisationContrat'],
        'idlocal': !exists(json, 'idlocal') ? undefined : json['idlocal'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'section': !exists(json, 'section') ? undefined : json['section'],
        'nbheures': !exists(json, 'nbheures') ? undefined : json['nbheures'],
        'travail': !exists(json, 'travail') ? undefined : json['travail'],
        'pcReussite': !exists(json, 'pcReussite') ? undefined : json['pcReussite'],
        'examen': !exists(json, 'examen') ? undefined : json['examen'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'categorie': !exists(json, 'categorie') ? undefined : json['categorie'],
        'idprojetFinancement': !exists(json, 'idprojetFinancement') ? undefined : json['idprojetFinancement'],
        'isfinancementReferentiel': !exists(json, 'isfinancementReferentiel') ? undefined : json['isfinancementReferentiel'],
        'reelHours': !exists(json, 'reelHours') ? undefined : json['reelHours'],
        'agrementHoursS1': !exists(json, 'agrementHoursS1') ? undefined : json['agrementHoursS1'],
        'agrementHoursS2': !exists(json, 'agrementHoursS2') ? undefined : json['agrementHoursS2'],
        'niveau': !exists(json, 'niveau') ? undefined : json['niveau'],
        'uaa': !exists(json, 'uaa') ? undefined : json['uaa'],
        'isVisibleBulletin': !exists(json, 'isVisibleBulletin') ? undefined : json['isVisibleBulletin'],
        'sommative': !exists(json, 'sommative') ? undefined : json['sommative'],
        'idmatiereParent': !exists(json, 'idmatiereParent') ? undefined : json['idmatiereParent'],
        'isCut': !exists(json, 'isCut') ? undefined : json['isCut'],
    };
}

export function FcbClasseMatiereDtoToJSON(value?: FcbClasseMatiereDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idclasseMatiere': value.idclasseMatiere,
        'idclasse': value.idclasse,
        'idmatiere': value.idmatiere,
        'idreferentiel': value.idreferentiel,
        'idformateur': value.idformateur,
        'idformateurLocalisationContrat': value.idformateurLocalisationContrat,
        'idlocal': value.idlocal,
        'code': value.code,
        'description': value.description,
        'section': value.section,
        'nbheures': value.nbheures,
        'travail': value.travail,
        'pcReussite': value.pcReussite,
        'examen': value.examen,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'categorie': value.categorie,
        'idprojetFinancement': value.idprojetFinancement,
        'isfinancementReferentiel': value.isfinancementReferentiel,
        'reelHours': value.reelHours,
        'agrementHoursS1': value.agrementHoursS1,
        'agrementHoursS2': value.agrementHoursS2,
        'niveau': value.niveau,
        'uaa': value.uaa,
        'isVisibleBulletin': value.isVisibleBulletin,
        'sommative': value.sommative,
        'idmatiereParent': value.idmatiereParent,
        'isCut': value.isCut,
    };
}

