import { Callout } from "@blueprintjs/core";
import { FieldSet } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { AP_FINAL_KEY, CarouselComparer, DoublonsMultipleDataCard, useDoublonsStateContext } from "..";
import { ApprenantDoublonEditDto, ApprenantRepresentantHopeGridDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BooleanColumn, ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

const Container = styled.div`
  height: 70vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface IDoublonApprenantRepresentantsProps {}

export const DoublonApprenantRepresentants: React.FunctionComponent<IDoublonApprenantRepresentantsProps> = props => {
  const { t, tUnsafe } = useTl();
  const { data, getSelectedIndex } = useDoublonsStateContext();

  const selectedIndex = React.useMemo(() => getSelectedIndex(`${AP_FINAL_KEY}.idapprenant`) ?? 0, [getSelectedIndex]);

  const apprenantContainer = React.useCallback(
    (index: number, nb: number) => {
      const apprenant: ApprenantDoublonEditDto = data?.apprenants[index];
      if (!apprenant) return;

      return (
        <FieldSet
          key={apprenant.idapprenant}
          title={
            <ViewButton
              text={t(ETLCodes.ApprenantNb, { nb: nb, id: apprenant.idapprenant })}
              onClick={() => window.open(`#${ERoutes.apprenant}/${apprenant.idapprenant}/signaletique/view`, "_blank")}
            />
          }
          titlePosition="center"
        >
          <DoublonsMultipleDataCard
            labelPredicate={(r: ApprenantRepresentantHopeGridDto) => r.nom}
            fieldPath={`apprenants[${index}].representants`}
            subFieldProps={{
              key: "contactUrgence",
              defaultValue: false,
              valueToSet: true
            }}
            finalEntityKey={AP_FINAL_KEY}
            interactive={(r: ApprenantRepresentantHopeGridDto) => r.type !== "Institution"}
          >
            {(r: ApprenantRepresentantHopeGridDto) => (
              <InformationContainer key={r.idrepresentant}>
                <div>
                  {t(ETLCodes.Type)}: {tUnsafe("Representant_" + r.type)}
                </div>
                <div>
                  {t(ETLCodes.LienParente)}: {r.lien}
                </div>
                <div>
                  {t(ETLCodes.ContactUrgenceApprenant)}: <BooleanColumn value={r.contactUrgence} />
                </div>
              </InformationContainer>
            )}
          </DoublonsMultipleDataCard>
        </FieldSet>
      );
    },
    [data?.apprenants, t, tUnsafe]
  );

  return (
    <Container>
      <Callout
        intent="warning"
        title={t(ETLCodes.VeuillezChoisirContactUrgence)}
        style={{ marginBottom: "1rem" }}
        icon="info-sign"
      >
        {t(ETLCodes.DoublonApprenantRepresentantCumules)}
      </Callout>
      <CarouselComparer leftElement={apprenantContainer(selectedIndex, selectedIndex + 1)}>
        {data?.apprenants?.length > 1 &&
          data?.apprenants
            ?.filter((a, i) => i !== selectedIndex)
            ?.map((a, i) => apprenantContainer(i + (i >= selectedIndex ? 1 : 0), i + (i >= selectedIndex ? 2 : 1)))}
      </CarouselComparer>
    </Container>
  );
};
