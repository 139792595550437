import * as React from "react";
import styled from 'styled-components';
import { ErrorText, LinkButton } from '..';
import { ERoutes } from '../../AppRouter';
import { useTl } from '../../hooks';
import { ETLCodes } from '../../locales';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export interface IAdresseDoublonErrorLinkProps {
  idpersonne: number;
}

export const AdresseDoublonErrorLink: React.FunctionComponent<IAdresseDoublonErrorLinkProps> = ({ idpersonne }) => {
  const { t } = useTl();

  return (<Container>
    <ErrorText text={t(ETLCodes.DoublonAdresseACorriger)} />{'\u00A0'}
    <LinkButton text={t(ETLCodes.CorrigerDansLaFichePersonne)} onClick={() => window.open(`#${ERoutes.personne}/${idpersonne}/signaletique/edit`)} />
  </Container>);
};