import { Tab, Tabs } from "@blueprintjs/core";
import * as React from "react";
import { DoublonRepresentantSignaletique } from ".";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IDoublonRepresentantPageProps {}

export const DoublonRepresentantPage: React.FunctionComponent<IDoublonRepresentantPageProps> = props => {
  const { t } = useTl();
  const [tab, setTab] = React.useState("signaletique");

  return (
    <Tabs
      id="TabsDoublonRepresentant"
      onChange={newTabId => setTab(newTabId + "")}
      selectedTabId={tab}
      renderActiveTabPanelOnly
      vertical
    >
      <Tab id="signaletique" title={t(ETLCodes.Signaletique)} panel={<DoublonRepresentantSignaletique />} />
    </Tabs>
  );
};
