/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbRepresentantDto,
  FcbRepresentantGridDtoPaginatedResults,
  FilterCriteriaInfo,
  RepresentantSearch,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    FcbRepresentantDtoFromJSON,
    FcbRepresentantDtoToJSON,
    FcbRepresentantGridDtoPaginatedResultsFromJSON,
    FcbRepresentantGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    RepresentantSearchFromJSON,
    RepresentantSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface RepresentantBaseSearchRequest {
    RepresentantSearch?: RepresentantSearch;
}

export interface RepresentantCheckDoublonsRequest {
    FcbRepresentantDto?: FcbRepresentantDto;
}

export interface RepresentantDeleteRequest {
    id?: number;
}

export interface RepresentantGetRequest {
    id?: number;
}

export interface RepresentantGetDisplayNameRequest {
    id?: number;
}

export interface RepresentantGetEmergencyContactRequest {
    apprenantId?: number;
}

export interface RepresentantGetIdpersonneRequest {
    idrepresentant?: number;
}

export interface RepresentantGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface RepresentantGetSelectItemsRequest {
    searchField: string;
}

export interface RepresentantSaveRequest {
    FcbRepresentantDto?: FcbRepresentantDto;
}

export interface RepresentantSearchRepresentantIdsRequest {
    RepresentantSearch?: RepresentantSearch;
}

/**
 * 
 */
export class RepresentantApi extends runtime.BaseAPI {

    /**
     */
    async representantBaseSearchRaw(requestParameters: RepresentantBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRepresentantGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Representant/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RepresentantSearchToJSON(requestParameters.RepresentantSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRepresentantGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async representantBaseSearch(requestParameters: RepresentantBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRepresentantGridDtoPaginatedResults> {
        const response = await this.representantBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async representantCheckDoublonsRaw(requestParameters: RepresentantCheckDoublonsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Representant/CheckDoublons`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbRepresentantDtoToJSON(requestParameters.FcbRepresentantDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async representantCheckDoublons(requestParameters: RepresentantCheckDoublonsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.representantCheckDoublonsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async representantDeleteRaw(requestParameters: RepresentantDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Representant`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async representantDelete(requestParameters: RepresentantDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.representantDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async representantGetRaw(requestParameters: RepresentantGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRepresentantDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Representant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRepresentantDtoFromJSON(jsonValue));
    }

    /**
     */
    async representantGet(requestParameters: RepresentantGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRepresentantDto> {
        const response = await this.representantGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async representantGetDisplayNameRaw(requestParameters: RepresentantGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Representant/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async representantGetDisplayName(requestParameters: RepresentantGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.representantGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async representantGetEmergencyContactRaw(requestParameters: RepresentantGetEmergencyContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRepresentantDto>> {
        const queryParameters: any = {};

        if (requestParameters.apprenantId !== undefined) {
            queryParameters['apprenantId'] = requestParameters.apprenantId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Representant/GetEmergencyContact`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRepresentantDtoFromJSON(jsonValue));
    }

    /**
     */
    async representantGetEmergencyContact(requestParameters: RepresentantGetEmergencyContactRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRepresentantDto> {
        const response = await this.representantGetEmergencyContactRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async representantGetIdpersonneRaw(requestParameters: RepresentantGetIdpersonneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idrepresentant !== undefined) {
            queryParameters['idrepresentant'] = requestParameters.idrepresentant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Representant/GetIdpersonne`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async representantGetIdpersonne(requestParameters: RepresentantGetIdpersonneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.representantGetIdpersonneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async representantGetSearchCriteriasRaw(requestParameters: RepresentantGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Representant/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async representantGetSearchCriterias(requestParameters: RepresentantGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.representantGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async representantGetSelectItemsRaw(requestParameters: RepresentantGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling representantGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Representant/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async representantGetSelectItems(requestParameters: RepresentantGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.representantGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async representantSaveRaw(requestParameters: RepresentantSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRepresentantDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Representant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbRepresentantDtoToJSON(requestParameters.FcbRepresentantDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRepresentantDtoFromJSON(jsonValue));
    }

    /**
     */
    async representantSave(requestParameters: RepresentantSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRepresentantDto> {
        const response = await this.representantSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async representantSearchRepresentantIdsRaw(requestParameters: RepresentantSearchRepresentantIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Representant/SearchRepresentantIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RepresentantSearchToJSON(requestParameters.RepresentantSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async representantSearchRepresentantIds(requestParameters: RepresentantSearchRepresentantIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.representantSearchRepresentantIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
