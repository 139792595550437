import { Button } from "@blueprintjs/core";
import { FormikProps } from "formik";
import { ButtonContainer, FGCustomPanel, FGMaskInput, FGTextInput, FieldGroup, FieldSet } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";

import { AcquisScolaireApi, FcbAcquisScolaireDto, FcbAcquisScolaireDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import { EcoleSelectModal } from "./EcoleSelectModal";

export interface IAcquisScolaireDetailProps {}

export const AcquisScolaireDetail: React.FunctionComponent<IAcquisScolaireDetailProps> = () => {
  const { t } = useTl();
  const api = useApiService(AcquisScolaireApi);
  const { id, idAcquis, state } = useParams<{ id: string; idAcquis: string; state: string }>();
  const apprenantId = React.useMemo(() => +id, [id]);
  const idAcquisScolaire = React.useMemo(() => +idAcquis, [idAcquis]);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idAcquisScolaire <= 0
        ? FcbAcquisScolaireDtoFromJSON({ idacquis: 0, idapprenant: apprenantId })
        : api.acquisScolaireGet({ id: +idAcquisScolaire }),
    saveApiFn: d => api.acquisScolaireSave({ FcbAcquisScolaireDto: d }),
    onSavedRoute: d => `${ERoutes.apprenant}/${d.idapprenant}/acquis`,
    deleteApiFn: d => api.acquisScolaireDelete({ id: d.idacquis }),
    onDeletedRoute: () => `${ERoutes.apprenant}/${apprenantId}/acquis`
  });

  const [ecoles, eLoading] = useReferential(a => a.referentialGetEcole(), true, []);
  const [diplomes, dLoading] = useReferential(a => a.referentialGetDiplome(), true, []);
  const [resultat, rLoading] = useReferential(a => a.referentialGetResultatAcquis(), true, []);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      acquis: Yup.string().required(t(ETLCodes.Required)),
      idresultat: Yup.string().required(t(ETLCodes.Required)),
      annee: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  const formikRef = React.useRef<FormikProps<FcbAcquisScolaireDto>>();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const onClose = React.useCallback((selected: number) => {
    if (selected) {
      formikRef.current?.setFieldValue("idecole", selected);
    }
    setShowModal(false);
  }, []);

  const EcoleModal = React.useCallback(
    () => (
      <EcoleSelectModal isOpen={showModal} onClose={onClose} alreadySelectedId={+formikRef.current?.values?.idecole} />
    ),
    [onClose, showModal, formikRef]
  );

  return (
    <FieldSet collapsable={false} title={t(ETLCodes.AcquisScolaire)}>
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={state === "edit"}
        saving={saving}
        loading={loading}
        onDelete={deleteItem}
        validationSchema={FormSchema}
        deleting={deleting}
        showDeleteButton
        formikInnerRef={i => (formikRef.current = i)}
        onCancel={() => history.push(`${ERoutes.apprenant}/${apprenantId}/acquis`)}
      >
        <FieldGroup columns={2}>
          <FGMaskInput
            label={t(ETLCodes.AnneeScolaire)}
            name="annee"
            cleaveOptions={{ numericOnly: true, blocks: [4] }}
          />
          <FGWalterSelectInput label={t(ETLCodes.Resultat)} name="idresultat" items={resultat} loading={rLoading} />
        </FieldGroup>
        <FGTextInput label={t(ETLCodes.Acquis)} name="acquis" maxLength={255} />
        <FGWalterSelectInput label={t(ETLCodes.Diplome)} name="iddiplome" items={diplomes} loading={dLoading} />
        <FieldGroup columns={[11, 1]}>
          <FGWalterSelectInput label={t(ETLCodes.Ecole)} name="idecole" items={ecoles} loading={eLoading} />
          <FGCustomPanel>
            {() => (
              <ButtonContainer>
                <Button
                  intent="primary"
                  icon="new-person"
                  text={t(ETLCodes.SelectionSchool)}
                  onClick={() => setShowModal(true)}
                  title={t(ETLCodes.SelectionSchool)}
                  loading={saving}
                />
              </ButtonContainer>
            )}
          </FGCustomPanel>
        </FieldGroup>
        <FGTextInput label={t(ETLCodes.Remarque)} name="certificat" maxLength={50} />
        <FGCustomPanel>{() => (!(saving || loading) ? <EcoleModal /> : null)}</FGCustomPanel>
      </SmallFormGenerator>
    </FieldSet>
  );
};
