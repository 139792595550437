/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbDispenseGridDto
 */
export interface FcbDispenseGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbDispenseGridDto
     */
    idinscription?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbDispenseGridDto
     */
    classe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDispenseGridDto
     */
    matiere?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbDispenseGridDto
     */
    dateDemande?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbDispenseGridDto
     */
    dateAcceptation?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDispenseGridDto
     */
    idclasseMatiere?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbDispenseGridDto
     */
    idtypeDispense?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDispenseGridDto
     */
    dispenseId?: number;
}

/**
 * Check if a given object implements the FcbDispenseGridDto interface.
 */
export function instanceOfFcbDispenseGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbDispenseGridDtoFromJSON(json: any): FcbDispenseGridDto {
    return FcbDispenseGridDtoFromJSONTyped(json, false);
}

export function FcbDispenseGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbDispenseGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idinscription': !exists(json, 'idinscription') ? undefined : json['idinscription'],
        'classe': !exists(json, 'classe') ? undefined : json['classe'],
        'matiere': !exists(json, 'matiere') ? undefined : json['matiere'],
        'dateDemande': !exists(json, 'dateDemande') ? undefined : (json['dateDemande'] === null ? null : new Date(json['dateDemande'])),
        'dateAcceptation': !exists(json, 'dateAcceptation') ? undefined : (json['dateAcceptation'] === null ? null : new Date(json['dateAcceptation'])),
        'idclasseMatiere': !exists(json, 'idclasseMatiere') ? undefined : json['idclasseMatiere'],
        'idtypeDispense': !exists(json, 'idtypeDispense') ? undefined : json['idtypeDispense'],
        'dispenseId': !exists(json, 'dispenseId') ? undefined : json['dispenseId'],
    };
}

export function FcbDispenseGridDtoToJSON(value?: FcbDispenseGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idinscription': value.idinscription,
        'classe': value.classe,
        'matiere': value.matiere,
        'dateDemande': value.dateDemande === undefined ? undefined : (value.dateDemande === null ? null : value.dateDemande.toISOString()),
        'dateAcceptation': value.dateAcceptation === undefined ? undefined : (value.dateAcceptation === null ? null : value.dateAcceptation.toISOString()),
        'idclasseMatiere': value.idclasseMatiere,
        'idtypeDispense': value.idtypeDispense,
        'dispenseId': value.dispenseId,
    };
}

