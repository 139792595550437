/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  FcbFormateurDetailDto,
  FcbFormateurDto,
  FcbFormateurGridDtoPaginatedResults,
  FilterCriteriaInfo,
  FormateurSearch,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FcbFormateurDetailDtoFromJSON,
    FcbFormateurDetailDtoToJSON,
    FcbFormateurDtoFromJSON,
    FcbFormateurDtoToJSON,
    FcbFormateurGridDtoPaginatedResultsFromJSON,
    FcbFormateurGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    FormateurSearchFromJSON,
    FormateurSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface FormateurBaseSearchRequest {
    FormateurSearch?: FormateurSearch;
}

export interface FormateurCalculDateActiviteRequest {
    idFormateur?: number;
}

export interface FormateurCheckDoublonsRequest {
    FcbFormateurDto?: FcbFormateurDto;
}

export interface FormateurDeleteRequest {
    id?: number;
}

export interface FormateurGetRequest {
    id?: number;
}

export interface FormateurGetBicFromAccountRequest {
    account?: string;
}

export interface FormateurGetDisplayNameRequest {
    id?: number;
}

export interface FormateurGetIdPersonneRequest {
    id?: number;
}

export interface FormateurGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface FormateurGetSelectItemsRequest {
    searchField: string;
}

export interface FormateurIsAzureActiveRequest {
    idFormateur?: number;
}

export interface FormateurIsDeceasedRequest {
    idFormateur?: number;
}

export interface FormateurSaveRequest {
    FcbFormateurDetailDto?: FcbFormateurDetailDto;
}

export interface FormateurSearchFormateurIdsRequest {
    FormateurSearch?: FormateurSearch;
}

/**
 * 
 */
export class FormateurApi extends runtime.BaseAPI {

    /**
     */
    async formateurBaseSearchRaw(requestParameters: FormateurBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Formateur/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormateurSearchToJSON(requestParameters.FormateurSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async formateurBaseSearch(requestParameters: FormateurBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurGridDtoPaginatedResults> {
        const response = await this.formateurBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurCalculDateActiviteRaw(requestParameters: FormateurCalculDateActiviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idFormateur !== undefined) {
            queryParameters['idFormateur'] = requestParameters.idFormateur;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Formateur/CalculDateActivite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurCalculDateActivite(requestParameters: FormateurCalculDateActiviteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.formateurCalculDateActiviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurCheckDoublonsRaw(requestParameters: FormateurCheckDoublonsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Formateur/CheckDoublons`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbFormateurDtoToJSON(requestParameters.FcbFormateurDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async formateurCheckDoublons(requestParameters: FormateurCheckDoublonsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.formateurCheckDoublonsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurDeleteRaw(requestParameters: FormateurDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Formateur`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async formateurDelete(requestParameters: FormateurDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.formateurDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurGetRaw(requestParameters: FormateurGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurDetailDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Formateur`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurGet(requestParameters: FormateurGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurDetailDto> {
        const response = await this.formateurGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurGetBicFromAccountRaw(requestParameters: FormateurGetBicFromAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.account !== undefined) {
            queryParameters['account'] = requestParameters.account;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Formateur/GetBicFromAccount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async formateurGetBicFromAccount(requestParameters: FormateurGetBicFromAccountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.formateurGetBicFromAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurGetDisplayNameRaw(requestParameters: FormateurGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Formateur/displayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async formateurGetDisplayName(requestParameters: FormateurGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.formateurGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurGetIdPersonneRaw(requestParameters: FormateurGetIdPersonneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Formateur/GetIdPersonne`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async formateurGetIdPersonne(requestParameters: FormateurGetIdPersonneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.formateurGetIdPersonneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurGetSearchCriteriasRaw(requestParameters: FormateurGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Formateur/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async formateurGetSearchCriterias(requestParameters: FormateurGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.formateurGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurGetSelectItemsRaw(requestParameters: FormateurGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling formateurGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Formateur/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async formateurGetSelectItems(requestParameters: FormateurGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.formateurGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurIsAzureActiveRaw(requestParameters: FormateurIsAzureActiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idFormateur !== undefined) {
            queryParameters['idFormateur'] = requestParameters.idFormateur;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Formateur/IsAzureActive`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurIsAzureActive(requestParameters: FormateurIsAzureActiveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.formateurIsAzureActiveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurIsDeceasedRaw(requestParameters: FormateurIsDeceasedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idFormateur !== undefined) {
            queryParameters['idFormateur'] = requestParameters.idFormateur;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Formateur/IsDeceased`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurIsDeceased(requestParameters: FormateurIsDeceasedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.formateurIsDeceasedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurSaveRaw(requestParameters: FormateurSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurDetailDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Formateur`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbFormateurDetailDtoToJSON(requestParameters.FcbFormateurDetailDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurSave(requestParameters: FormateurSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurDetailDto> {
        const response = await this.formateurSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurSearchFormateurIdsRaw(requestParameters: FormateurSearchFormateurIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Formateur/searchFormateurIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormateurSearchToJSON(requestParameters.FormateurSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async formateurSearchFormateurIds(requestParameters: FormateurSearchFormateurIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.formateurSearchFormateurIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
