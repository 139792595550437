/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EBooleanSearchTypes } from './EBooleanSearchTypes';
import {
    EBooleanSearchTypesFromJSON,
    EBooleanSearchTypesFromJSONTyped,
    EBooleanSearchTypesToJSON,
} from './EBooleanSearchTypes';
import type { EBooleanSearchTypesBooleanNullableCombinableCriteria } from './EBooleanSearchTypesBooleanNullableCombinableCriteria';
import {
    EBooleanSearchTypesBooleanNullableCombinableCriteriaFromJSON,
    EBooleanSearchTypesBooleanNullableCombinableCriteriaFromJSONTyped,
    EBooleanSearchTypesBooleanNullableCombinableCriteriaToJSON,
} from './EBooleanSearchTypesBooleanNullableCombinableCriteria';

/**
 * 
 * @export
 * @interface BooleanSearch
 */
export interface BooleanSearch {
    /**
     * 
     * @type {Array<EBooleanSearchTypesBooleanNullableCombinableCriteria>}
     * @memberof BooleanSearch
     */
    criterias?: Array<EBooleanSearchTypesBooleanNullableCombinableCriteria> | null;
    /**
     * 
     * @type {EBooleanSearchTypes}
     * @memberof BooleanSearch
     */
    searchMode?: EBooleanSearchTypes;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanSearch
     * @deprecated
     */
    value?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanSearch
     * @deprecated
     */
    secondaryValue?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BooleanSearch
     */
    readonly availableSearchTypes?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof BooleanSearch
     */
    defaultSearchType?: string | null;
}

/**
 * Check if a given object implements the BooleanSearch interface.
 */
export function instanceOfBooleanSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BooleanSearchFromJSON(json: any): BooleanSearch {
    return BooleanSearchFromJSONTyped(json, false);
}

export function BooleanSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): BooleanSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'criterias': !exists(json, 'criterias') ? undefined : (json['criterias'] === null ? null : (json['criterias'] as Array<any>).map(EBooleanSearchTypesBooleanNullableCombinableCriteriaFromJSON)),
        'searchMode': !exists(json, 'searchMode') ? undefined : EBooleanSearchTypesFromJSON(json['searchMode']),
        'value': !exists(json, 'value') ? undefined : json['value'],
        'secondaryValue': !exists(json, 'secondaryValue') ? undefined : json['secondaryValue'],
        'availableSearchTypes': !exists(json, 'availableSearchTypes') ? undefined : json['availableSearchTypes'],
        'defaultSearchType': !exists(json, 'defaultSearchType') ? undefined : json['defaultSearchType'],
    };
}

export function BooleanSearchToJSON(value?: BooleanSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'criterias': value.criterias === undefined ? undefined : (value.criterias === null ? null : (value.criterias as Array<any>).map(EBooleanSearchTypesBooleanNullableCombinableCriteriaToJSON)),
        'searchMode': EBooleanSearchTypesToJSON(value.searchMode),
        'value': value.value,
        'secondaryValue': value.secondaryValue,
        'defaultSearchType': value.defaultSearchType,
    };
}

