/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';

/**
 * 
 * @export
 * @interface ChefsEntrepriseSiegeDoublonSearchDto
 */
export interface ChefsEntrepriseSiegeDoublonSearchDto {
    /**
     * 
     * @type {number}
     * @memberof ChefsEntrepriseSiegeDoublonSearchDto
     */
    idchefEntreprise?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChefsEntrepriseSiegeDoublonSearchDto
     */
    idsiegeSocial?: number;
    /**
     * 
     * @type {string}
     * @memberof ChefsEntrepriseSiegeDoublonSearchDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChefsEntrepriseSiegeDoublonSearchDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChefsEntrepriseSiegeDoublonSearchDto
     */
    registreNational?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ChefsEntrepriseSiegeDoublonSearchDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ChefsEntrepriseSiegeDoublonSearchDto
     */
    nationalite?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ChefsEntrepriseSiegeDoublonSearchDto
     */
    idpersonne?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ChefsEntrepriseSiegeDoublonSearchDto
     */
    selectedIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ChefsEntrepriseSiegeDoublonSearchDto
     */
    availableIds?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChefsEntrepriseSiegeDoublonSearchDto
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof ChefsEntrepriseSiegeDoublonSearchDto
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof ChefsEntrepriseSiegeDoublonSearchDto
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChefsEntrepriseSiegeDoublonSearchDto
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof ChefsEntrepriseSiegeDoublonSearchDto
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof ChefsEntrepriseSiegeDoublonSearchDto
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the ChefsEntrepriseSiegeDoublonSearchDto interface.
 */
export function instanceOfChefsEntrepriseSiegeDoublonSearchDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChefsEntrepriseSiegeDoublonSearchDtoFromJSON(json: any): ChefsEntrepriseSiegeDoublonSearchDto {
    return ChefsEntrepriseSiegeDoublonSearchDtoFromJSONTyped(json, false);
}

export function ChefsEntrepriseSiegeDoublonSearchDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChefsEntrepriseSiegeDoublonSearchDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idchefEntreprise': !exists(json, 'idchefEntreprise') ? undefined : json['idchefEntreprise'],
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'registreNational': !exists(json, 'registreNational') ? undefined : json['registreNational'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'nationalite': !exists(json, 'nationalite') ? undefined : json['nationalite'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'selectedIds': !exists(json, 'selectedIds') ? undefined : json['selectedIds'],
        'availableIds': !exists(json, 'availableIds') ? undefined : json['availableIds'],
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function ChefsEntrepriseSiegeDoublonSearchDtoToJSON(value?: ChefsEntrepriseSiegeDoublonSearchDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idchefEntreprise': value.idchefEntreprise,
        'idsiegeSocial': value.idsiegeSocial,
        'nom': value.nom,
        'prenom': value.prenom,
        'registreNational': value.registreNational,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'nationalite': value.nationalite,
        'idpersonne': value.idpersonne,
        'selectedIds': value.selectedIds,
        'availableIds': value.availableIds,
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

