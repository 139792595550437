import { Checkbox } from "@blueprintjs/core";
import { format, startOfToday } from "date-fns";
import { IDataTableColumn } from "nsitools-react";
import * as React from "react";

import { ClasseHoraireApi, HoraireSearch, ETextSearchType, EDateSearchType } from "../../../../../api";
import { FcbHoraireGridDto } from "../../../../../api/models/FcbHoraireGridDto";
import { SearchTablePage, TimeDisplay } from "../../../../../components";
import { useGlobalData } from "../../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

interface IApprenantHoraireProps {
  apprenantId: number;
}

export const ApprenantHoraire: React.FunctionComponent<IApprenantHoraireProps> = ({ apprenantId }) => {
  const { t } = useTl();

  const api = useApiService(ClasseHoraireApi);
  const { currentAnneeScolaire } = useGlobalData();

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.Annee),
        fieldName: "anneeScolaire"
      },
      {
        header: () => t(ETLCodes.Date),
        fieldName: "jourClasse"
      },
      {
        header: () => t(ETLCodes.Classe),
        fieldName: "classe"
      },
      {
        header: () => t(ETLCodes.NomClasse),
        fieldName: "nomClasse"
      },
      {
        header: () => t(ETLCodes.DateDebut),
        fieldName: "heureDebut",
        render: (item: FcbHoraireGridDto) => <TimeDisplay time={item.heureDebut}></TimeDisplay>
      },
      {
        header: () => t(ETLCodes.Matiere),
        fieldName: "matiere",
        disableSorting: true
      },
      {
        header: () => t(ETLCodes.Local),
        fieldName: "local"
      },
      {
        header: () => t(ETLCodes.Formateur),
        fieldName: "formateur"
      },
      {
        header: () => t(ETLCodes.Visite),
        fieldName: "visite",
        alignment: "center",
        render: (row: FcbHoraireGridDto) => <Checkbox disabled checked={row.visite} />
      }
    ],
    [t]
  );

  const apiFactory = useAbortableApiServiceFactory(ClasseHoraireApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFunc = React.useCallback(
    (sObj?: HoraireSearch) => {
      sObj.idApprenant = apprenantId;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.classeHoraireBaseSearch({ HoraireSearch: sObj });
    },
    [apiFactory, apprenantId]
  );

  const getCriteriasFunc = React.useCallback(() => api.classeHoraireGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const today = React.useMemo(() => startOfToday(), []);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    apprenantId > 0 && (
      <SearchTablePage
        columns={columns}
        getCriteriasFunction={getCriteriasFunc}
        searchFunction={searchFunc}
        onAbort={onAbort}
        sortKeys={{ jourClasse: "ASC", heureDebut: "ASC" }}
        searchStateInitialSearch={false}
        defaultCriterias={[
          {
            criteria: "AnneeScolaire",
            searchMode: ETextSearchType.Contains,
            value: currentAnneeScolaire?.idanneeScolaire
          },
          {
            criteria: "JourClasse",
            searchMode: EDateSearchType.GreaterThan,
            value: format(today, "yyyy-MM-dd")
          }
        ]}
        withCard={false}
      />
    )
  );
};
