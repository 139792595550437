import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ExportGapClassesList, ExportGapFormateursList, ExportGapStatistiqueList } from "..";
import { ExportGapApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { ExportationGapDetailPage } from "./ExportationGapDetailPage";
import { ExportationGapPrestationListPage } from "./ExportationGapPrestationListPage";
import { ExportationGapRemplacementsListPage } from "./ExportationGapRemplacementsListPage";

export interface IExportationGapItemPageProps {}

export const ExportationGapItemPage: React.FunctionComponent<IExportationGapItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const exportationGapApi = useApiService(ExportGapApi);
  const [displayName] = useApiEffect(() => exportationGapApi.exportGapGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.ExportationsGAP), route: ERoutes.exportationGap },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsExportationGap"
        onChange={newTabId => history.push(`${ERoutes.exportationGap}/${id}/${newTabId}/${state}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.exportationGap}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<ExportationGapDetailPage idExport={+id} editMode={state === "edit"} />}
        />
        <Tab
          id="classes"
          title={t(ETLCodes.Classes)}
          panel={<ExportGapClassesList idExport={+id} />}
          disabled={+id <= 0}
        />
        <Tab
          id="formateurs"
          title={t(ETLCodes.Formateurs)}
          panel={<ExportGapFormateursList idExport={+id} />}
          disabled={+id <= 0}
        />
        <Tab
          id="prestations"
          title={t(ETLCodes.Prestations)}
          panel={<ExportationGapPrestationListPage idExport={+id} />}
          disabled={+id <= 0}
        />
        <Tab
          id="remplacements"
          title={t(ETLCodes.Remplacements)}
          panel={<ExportationGapRemplacementsListPage idExport={+id} />}
          disabled={+id <= 0}
        />
        <Tab
          id="statistiques"
          title={t(ETLCodes.Statistiques)}
          panel={<ExportGapStatistiqueList idExport={+id} />}
          disabled={+id <= 0}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
