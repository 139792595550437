/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContactDoublonLieuFormationDto } from './ContactDoublonLieuFormationDto';
import {
    ContactDoublonLieuFormationDtoFromJSON,
    ContactDoublonLieuFormationDtoFromJSONTyped,
    ContactDoublonLieuFormationDtoToJSON,
} from './ContactDoublonLieuFormationDto';
import type { ContactDoublonSiegeSocialDto } from './ContactDoublonSiegeSocialDto';
import {
    ContactDoublonSiegeSocialDtoFromJSON,
    ContactDoublonSiegeSocialDtoFromJSONTyped,
    ContactDoublonSiegeSocialDtoToJSON,
} from './ContactDoublonSiegeSocialDto';

/**
 * 
 * @export
 * @interface ContactDoublonEditDto
 */
export interface ContactDoublonEditDto {
    /**
     * 
     * @type {number}
     * @memberof ContactDoublonEditDto
     */
    idcontact?: number;
    /**
     * 
     * @type {Array<ContactDoublonSiegeSocialDto>}
     * @memberof ContactDoublonEditDto
     */
    siegesSociaux?: Array<ContactDoublonSiegeSocialDto> | null;
    /**
     * 
     * @type {Array<ContactDoublonLieuFormationDto>}
     * @memberof ContactDoublonEditDto
     */
    lieuxFormation?: Array<ContactDoublonLieuFormationDto> | null;
}

/**
 * Check if a given object implements the ContactDoublonEditDto interface.
 */
export function instanceOfContactDoublonEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContactDoublonEditDtoFromJSON(json: any): ContactDoublonEditDto {
    return ContactDoublonEditDtoFromJSONTyped(json, false);
}

export function ContactDoublonEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactDoublonEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontact': !exists(json, 'idcontact') ? undefined : json['idcontact'],
        'siegesSociaux': !exists(json, 'siegesSociaux') ? undefined : (json['siegesSociaux'] === null ? null : (json['siegesSociaux'] as Array<any>).map(ContactDoublonSiegeSocialDtoFromJSON)),
        'lieuxFormation': !exists(json, 'lieuxFormation') ? undefined : (json['lieuxFormation'] === null ? null : (json['lieuxFormation'] as Array<any>).map(ContactDoublonLieuFormationDtoFromJSON)),
    };
}

export function ContactDoublonEditDtoToJSON(value?: ContactDoublonEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontact': value.idcontact,
        'siegesSociaux': value.siegesSociaux === undefined ? undefined : (value.siegesSociaux === null ? null : (value.siegesSociaux as Array<any>).map(ContactDoublonSiegeSocialDtoToJSON)),
        'lieuxFormation': value.lieuxFormation === undefined ? undefined : (value.lieuxFormation === null ? null : (value.lieuxFormation as Array<any>).map(ContactDoublonLieuFormationDtoToJSON)),
    };
}

