/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbDirectionTerritorialeDto
 */
export interface FcbDirectionTerritorialeDto {
    /**
     * 
     * @type {number}
     * @memberof FcbDirectionTerritorialeDto
     */
    iddirectionTerritoriale?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbDirectionTerritorialeDto
     */
    codePays?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDirectionTerritorialeDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDirectionTerritorialeDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDirectionTerritorialeDto
     */
    adresse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDirectionTerritorialeDto
     */
    localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDirectionTerritorialeDto
     */
    commune?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDirectionTerritorialeDto
     */
    codePostal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDirectionTerritorialeDto
     */
    telephone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDirectionTerritorialeDto
     */
    fax?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDirectionTerritorialeDto
     */
    directeur?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDirectionTerritorialeDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDirectionTerritorialeDto
     */
    ouverture?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDirectionTerritorialeDto
     */
    fraisDossier?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDirectionTerritorialeDto
     */
    noCompte?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDirectionTerritorialeDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbDirectionTerritorialeDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDirectionTerritorialeDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbDirectionTerritorialeDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDirectionTerritorialeDto
     */
    iddirectionTerritorialeGuid?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbDirectionTerritorialeDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbDirectionTerritorialeDto interface.
 */
export function instanceOfFcbDirectionTerritorialeDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbDirectionTerritorialeDtoFromJSON(json: any): FcbDirectionTerritorialeDto {
    return FcbDirectionTerritorialeDtoFromJSONTyped(json, false);
}

export function FcbDirectionTerritorialeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbDirectionTerritorialeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iddirectionTerritoriale': !exists(json, 'iddirectionTerritoriale') ? undefined : json['iddirectionTerritoriale'],
        'codePays': !exists(json, 'codePays') ? undefined : json['codePays'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'adresse': !exists(json, 'adresse') ? undefined : json['adresse'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'commune': !exists(json, 'commune') ? undefined : json['commune'],
        'codePostal': !exists(json, 'codePostal') ? undefined : json['codePostal'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'directeur': !exists(json, 'directeur') ? undefined : json['directeur'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'ouverture': !exists(json, 'ouverture') ? undefined : json['ouverture'],
        'fraisDossier': !exists(json, 'fraisDossier') ? undefined : json['fraisDossier'],
        'noCompte': !exists(json, 'noCompte') ? undefined : json['noCompte'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'iddirectionTerritorialeGuid': !exists(json, 'iddirectionTerritorialeGuid') ? undefined : json['iddirectionTerritorialeGuid'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbDirectionTerritorialeDtoToJSON(value?: FcbDirectionTerritorialeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iddirectionTerritoriale': value.iddirectionTerritoriale,
        'codePays': value.codePays,
        'code': value.code,
        'nom': value.nom,
        'adresse': value.adresse,
        'localite': value.localite,
        'commune': value.commune,
        'codePostal': value.codePostal,
        'telephone': value.telephone,
        'fax': value.fax,
        'directeur': value.directeur,
        'email': value.email,
        'ouverture': value.ouverture,
        'fraisDossier': value.fraisDossier,
        'noCompte': value.noCompte,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'iddirectionTerritorialeGuid': value.iddirectionTerritorialeGuid,
        'actif': value.actif,
    };
}

