/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbTitrePedagogiqueDto
 */
export interface FcbTitrePedagogiqueDto {
    /**
     * 
     * @type {number}
     * @memberof FcbTitrePedagogiqueDto
     */
    idtitre?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbTitrePedagogiqueDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbTitrePedagogiqueDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbTitrePedagogiqueDto interface.
 */
export function instanceOfFcbTitrePedagogiqueDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbTitrePedagogiqueDtoFromJSON(json: any): FcbTitrePedagogiqueDto {
    return FcbTitrePedagogiqueDtoFromJSONTyped(json, false);
}

export function FcbTitrePedagogiqueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbTitrePedagogiqueDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idtitre': !exists(json, 'idtitre') ? undefined : json['idtitre'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbTitrePedagogiqueDtoToJSON(value?: FcbTitrePedagogiqueDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idtitre': value.idtitre,
        'libelle': value.libelle,
        'actif': value.actif,
    };
}

