/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MatiereHeureDto
 */
export interface MatiereHeureDto {
    /**
     * 
     * @type {number}
     * @memberof MatiereHeureDto
     */
    idReferentiel?: number;
    /**
     * 
     * @type {number}
     * @memberof MatiereHeureDto
     */
    nbHeures?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MatiereHeureDto
     */
    nbHeuresReelles?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MatiereHeureDto
     */
    total?: number | null;
}

/**
 * Check if a given object implements the MatiereHeureDto interface.
 */
export function instanceOfMatiereHeureDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MatiereHeureDtoFromJSON(json: any): MatiereHeureDto {
    return MatiereHeureDtoFromJSONTyped(json, false);
}

export function MatiereHeureDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MatiereHeureDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idReferentiel': !exists(json, 'idReferentiel') ? undefined : json['idReferentiel'],
        'nbHeures': !exists(json, 'nbHeures') ? undefined : json['nbHeures'],
        'nbHeuresReelles': !exists(json, 'nbHeuresReelles') ? undefined : json['nbHeuresReelles'],
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function MatiereHeureDtoToJSON(value?: MatiereHeureDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idReferentiel': value.idReferentiel,
        'nbHeures': value.nbHeures,
        'nbHeuresReelles': value.nbHeuresReelles,
        'total': value.total,
    };
}

