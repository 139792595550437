import { FGEmpty, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { FcbPaysDtoFromJSON, PaysApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterCheckboxInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

interface IPaysDetailPageProps {
  idPays: string;
  editMode: boolean;
}

export const PaysDetailPage: React.FunctionComponent<IPaysDetailPageProps> = ({ idPays, editMode }) => {
  const { t } = useTl();
  const api = useApiService(PaysApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () => (idPays === "0" ? FcbPaysDtoFromJSON({ idPays: "" }) : api.paysGet({ codePays: idPays })),
    saveApiFn: d => api.paysSave({ FcbPaysDto: d }),
    onSavedRoute: d => `${ERoutes.pays}/${d.codePays}/detail/edit`,
    deleteApiFn: d => api.paysDelete({ codePays: d.codePays }),
    onDeletedRoute: () => ERoutes.pays
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      codePays: Yup.string()
        .required(t(ETLCodes.Required))
        .test("code-not-exists", t(ETLCodes.CodeExistsError), async value => {
          if (value === idPays) return true;
          const validationError = await api.paysCodePaysExists({ codePays: value });
          return validationError.isValid;
        }),
      pays: Yup.string()
        .required(t(ETLCodes.Required))
        .test("description-not-exists", t(ETLCodes.PaysDetailPage_DescriptionExistsError), async value => {
          const validationError = await api.paysPaysNomExists({ codePays: idPays, nomPays: value });
          return validationError.isValid;
        }),
      masculin: Yup.string().required(t(ETLCodes.Required)),
      feminin: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t, idPays, api]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      showColons
      editMode={editMode}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.pays)}
      onDelete={deleteItem}
      showDeleteButton={idPays !== "0"}
      saving={saving}
      deleting={deleting}
    >
      <FieldGroup columns={2}>
        <FGTextInput name="codePays" maxLength={3} label={t(ETLCodes.CodePays)} forceCase="upper" />
        <FGTextInput name="pays" maxLength={50} label={t(ETLCodes.Pays)} />
        <FGTextInput name="masculin" maxLength={50} label={t(ETLCodes.Masculin)} />
        <FGTextInput name="feminin" maxLength={50} label={t(ETLCodes.Feminin)} />
        <FGWalterCheckboxInput name="europe" label={t(ETLCodes.Europe)} />
        <FGEmpty />
        <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
