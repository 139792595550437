import { usePrevious } from "ahooks";
import React from "react";

import { useGlobalData } from "../contexts";

export function useAppVersion() {
  const { fetchingInfo, applicationVersion, walterUrl } = useGlobalData();
  const previousVersion = usePrevious(applicationVersion);

  const appVersion = React.useMemo(() => {
    if (applicationVersion) {
      const appName = walterUrl?.includes(window.location.origin) ? "Walter" : "Hope";
      if (!previousVersion) {
        // eslint-disable-next-line no-console
        console.log(`IFAPME - ${appName}`);
        // eslint-disable-next-line no-console
        console.log(`Version ${applicationVersion}`);
      }
      return `${appName} - v${applicationVersion.split("+")[0]}`;
    } else {
      return "";
    }
  }, [applicationVersion, previousVersion, walterUrl]);

  return {
    versionLoading: fetchingInfo,
    appVersion
  };
}
