import { Tab } from "@blueprintjs/core";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router";
import { ChefEntrepriseDetailSwitch } from ".";
import { ChefEntrepriseApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { CESiegesSociaux } from "./sieges";

export interface IChefEntrepriseItemPageProps {}

export const ChefEntrepriseItemPage: React.FunctionComponent<IChefEntrepriseItemPageProps> = props => {
  const { t } = useTl();
  const { id, tab, state } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();
  const { search } = useLocation();

  const api = useApiService(ChefEntrepriseApi);

  const fetchDisplayName = React.useCallback(() => {
    return api.chefEntrepriseGetDisplayName({ id: +id });
  }, [api, id]);
  const { data: displayName } = useQuery(["chef-entreprise-display-name", id], fetchDisplayName);

  return (
    <PageBase
      breadCrumbs={[{ text: t(ETLCodes.ChefsEntreprise), route: ERoutes.chefEntreprise }, { text: displayName }]}
    >
      <ProtectedTabs
        id="TabsChefsEntreprise"
        onChange={newTabId => history.push(`${ERoutes.chefEntreprise}/${id}/${newTabId}/${state}${search}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.chefEntreprise}></BackButton>
        <Tab id="detail" title={t(ETLCodes.Signaletique)} panel={<ChefEntrepriseDetailSwitch />} />
        <Tab id="siegesociaux" title={t(ETLCodes.SiegesSociaux)} panel={<CESiegesSociaux />} />
      </ProtectedTabs>
    </PageBase>
  );
};
