import { Button, Colors, Intent, Spinner, SpinnerSize } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import format from "date-fns/format";
import {
  ButtonsBloc,
  FGCustomPanel,
  FGCustomReadOnly,
  FGTextInput,
  FieldGroup,
  FieldSet,
  InlineButtonContainer,
  showError,
  showSuccess,
  useGridState,
  useSearchApi
} from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import {
  ApprenantDoublonApi,
  ApprenantDoublonSearch,
  ApprenantDoublonSearchFromJSON,
  FcbApprenantDoublonGridDto,
  SocieteExterneApi
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  AddButton,
  CancelButton,
  CustomStateDisplay,
  FGWalterDateMaskInput,
  SmallFormGenerator
} from "../../../../../components";
import { useAuth, useBeIdCardContext } from "../../../../../contexts";
import { useApiService, useManageError, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

const RoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
`;

const SpinnerContainer = styled.div`
  margin: 2rem;
`;

export interface IApprenantSearchSocieteExterneProps {
  setBaseData: React.Dispatch<React.SetStateAction<ApprenantDoublonSearch>>;
}

export const ApprenantSearchSocieteExterne: React.FunctionComponent<IApprenantSearchSocieteExterneProps> = ({
  setBaseData
}) => {
  const { t } = useTl();
  const history = useHistory();
  const { manageError } = useManageError();
  const api = useApiService(ApprenantDoublonApi);
  const seApi = useApiService(SocieteExterneApi);
  const { singleFoundUser, foundRowsData, loading: loadingCard, card, clearCardData } = useBeIdCardContext();
  const { user } = useAuth();
  const [firstSearch, setFirstSearch] = React.useState(false);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [10],
    pageSize: 10,
    sortKeys: { nom: "ASC" }
  });
  const { totalCount, data } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: ApprenantDoublonSearch) => {
      return api.apprenantDoublonBaseSearch({ ApprenantDoublonSearch: sObj });
    },
    [api]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: false
  });

  const add = React.useCallback(() => {
    history.push(`${ERoutes.apprenant}/0/signaletique/edit`);
  }, [history]);

  const initialData = React.useMemo(() => {
    return ApprenantDoublonSearchFromJSON({});
  }, []);

  const [foundUnlinked, setFoundUnlinked] = React.useState<FcbApprenantDoublonGridDto>(null);
  React.useEffect(() => {
    if (totalCount === 1) {
      setSaving(true);
      const foundApprenant = data[0] as FcbApprenantDoublonGridDto;
      if (foundApprenant.idsSocieteExterne.some(idSe => user?.idsSocieteExterne.includes(idSe))) {
        showSuccess(t(ETLCodes.PersonneTrouvee));
        clearCardData();
        history.push(`${ERoutes.apprenant}/${foundApprenant.idapprenant}/signaletique/edit`);
      }
      setFoundUnlinked(foundApprenant);
      setSaving(false);
    }
  }, [clearCardData, data, history, t, totalCount, user?.idsSocieteExterne]);
  React.useEffect(() => {
    if (!!foundRowsData) {
      search(ApprenantDoublonSearchFromJSON({ registreNational: foundRowsData?.nationalNb, ...foundRowsData }));
    }
  }, [foundRowsData, search]);

  const [saving, setSaving] = React.useState(false);
  const confirm = React.useCallback(async () => {
    try {
      setSaving(true);
      await seApi.societeExterneAddApprenantForUser({ idapprenant: foundUnlinked?.idapprenant });
      setBaseData({ idpersonne: foundUnlinked?.idpersonne });
      history.push(`${ERoutes.apprenant}/${foundUnlinked?.idapprenant}/signaletique/edit`);
    } catch (e) {
      manageError(e);
    } finally {
      setSaving(false);
    }
  }, [foundUnlinked?.idapprenant, foundUnlinked?.idpersonne, history, manageError, seApi, setBaseData]);

  React.useEffect(() => {
    if (!!card) {
      setFirstSearch(true);
    }
  }, [card, loadingCard]);

  return (
    <FieldSet collapsable={false} title={t(ETLCodes.Apprenants)}>
      <SmallFormGenerator
        enableDirtyCheck={false}
        initialValues={initialData}
        disabled={loading}
        saving={saving}
        onSubmit={c => {
          if (c.nom && c.prenom && c.dateNaissance) {
            search(c);
            setFirstSearch(true);
          } else {
            showError(t(ETLCodes.NoCriteriaFilled));
          }
        }}
        editMode
        hideButtons={true}
        onCancel={() => history.push(`${ERoutes.apprenant}`)}
      >
        <FieldGroup visible={!foundRowsData || loadingCard || loading}>
          {loadingCard || loading ? (
            <SpinnerContainer>
              <Spinner size={SpinnerSize.LARGE} className="spinner" />
            </SpinnerContainer>
          ) : (
            <CustomStateDisplay
              title={t(ETLCodes.VeuillezInsererCarteIdentite)}
              description={t(ETLCodes.Ou)}
              icon="id-number"
              iconColor={Colors.DARK_GRAY3}
              iconSize={100}
            />
          )}
        </FieldGroup>
        <FieldGroup columns={3} visible={!foundRowsData}>
          <FGTextInput name="nom" label={t(ETLCodes.Nom)} maxLength={100} forceCase="upper" />
          <FGTextInput name="prenom" label={t(ETLCodes.Prenom)} maxLength={100} />
          <FGWalterDateMaskInput name="dateNaissance" label={t(ETLCodes.DateNaissance)} />
        </FieldGroup>
        <FieldGroup>
          <FGCustomPanel>
            {ctx =>
              !foundRowsData && (
                <InlineButtonContainer>
                  <ButtonsBloc></ButtonsBloc>
                  <ButtonsBloc>
                    <CancelButton minimal={false} onClick={() => history.push(`${ERoutes.apprenant}`)} />
                    <Button icon={IconNames.SEARCH} type="submit" text={t(ETLCodes.Search)} intent={Intent.PRIMARY} />
                  </ButtonsBloc>
                </InlineButtonContainer>
              )
            }
          </FGCustomPanel>
        </FieldGroup>
        {!loading && !!foundUnlinked && (
          <>
            <CustomStateDisplay
              title={t(ETLCodes.PersonneTrouvee)}
              icon="person"
              iconColor={Colors.GREEN3}
              iconSize={100}
            />
            <FieldGroup>
              <FGCustomReadOnly label={t(ETLCodes.Nom)}>
                {() => <RoContainer>{foundUnlinked.nom}</RoContainer>}
              </FGCustomReadOnly>
              <FGCustomReadOnly label={t(ETLCodes.Prenom)}>
                {() => <RoContainer>{foundUnlinked.prenom}</RoContainer>}
              </FGCustomReadOnly>
              <FGCustomReadOnly label={t(ETLCodes.DateNaissance)}>
                {() => <RoContainer>{format(foundUnlinked.dateNaissance, "dd/MM/yyyy")}</RoContainer>}
              </FGCustomReadOnly>
              <FGCustomReadOnly label={t(ETLCodes.RegistreNational)}>
                {() => <RoContainer>{foundUnlinked.registreNational}</RoContainer>}
              </FGCustomReadOnly>
            </FieldGroup>
            <FieldGroup>
              <FGCustomReadOnly label={t(ETLCodes.Nationalite)}>
                {() => <RoContainer>{foundUnlinked.nationalite}</RoContainer>}
              </FGCustomReadOnly>
              <FGCustomReadOnly label={t(ETLCodes.Adresse)}>
                {() => <RoContainer>{foundUnlinked.adresse}</RoContainer>}
              </FGCustomReadOnly>
              <FGCustomReadOnly label={t(ETLCodes.CodePostal)}>
                {() => <RoContainer>{foundUnlinked.codePostal}</RoContainer>}
              </FGCustomReadOnly>
              <FGCustomReadOnly label={t(ETLCodes.Localite)}>
                {() => <RoContainer>{foundUnlinked.localite}</RoContainer>}
              </FGCustomReadOnly>
            </FieldGroup>
            <InlineButtonContainer>
              <ButtonsBloc></ButtonsBloc>
              <ButtonsBloc>
                <CancelButton minimal={false} onClick={() => history.push(`${ERoutes.apprenant}`)} />
                <Button
                  onClick={confirm}
                  text={t(ETLCodes.Confirmer)}
                  intent={Intent.PRIMARY}
                  minimal={false}
                  icon="tick"
                  loading={saving}
                />
              </ButtonsBloc>
            </InlineButtonContainer>
          </>
        )}
        {!loading && !loadingCard && !foundUnlinked && totalCount !== 1 && firstSearch && (
          <>
            <CustomStateDisplay
              title={
                !!singleFoundUser && foundRowsData?.nationalNb
                  ? t(ETLCodes.AucunePersonneTrouveePourNiss, { niss: foundRowsData.nationalNb })
                  : totalCount > 1
                  ? t(ETLCodes.PlusieursPersonnesTrouvees)
                  : t(ETLCodes.AucunePersonneTrouvee)
              }
              description={(!card || totalCount > 1) && t(ETLCodes.VeuillezContacterCentres)}
              icon={totalCount > 1 ? "people" : "person"}
              iconColor={Colors.RED3}
              iconSize={100}
            />
            {!!card && totalCount === 0 && (
              <InlineButtonContainer>
                <ButtonsBloc></ButtonsBloc>
                <ButtonsBloc>
                  <AddButton onClick={add} text={t(ETLCodes.CreerApprenant)} intent={Intent.PRIMARY} minimal={false} />
                </ButtonsBloc>
              </InlineButtonContainer>
            )}
          </>
        )}
      </SmallFormGenerator>
    </FieldSet>
  );
};
