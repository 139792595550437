/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailDto } from './EmailDto';
import {
    EmailDtoFromJSON,
    EmailDtoFromJSONTyped,
    EmailDtoToJSON,
} from './EmailDto';
import type { TelephoneDto } from './TelephoneDto';
import {
    TelephoneDtoFromJSON,
    TelephoneDtoFromJSONTyped,
    TelephoneDtoToJSON,
} from './TelephoneDto';

/**
 * 
 * @export
 * @interface ContactDoublonSiegeSocialDto
 */
export interface ContactDoublonSiegeSocialDto {
    /**
     * 
     * @type {number}
     * @memberof ContactDoublonSiegeSocialDto
     */
    idcontactSiege?: number;
    /**
     * 
     * @type {number}
     * @memberof ContactDoublonSiegeSocialDto
     */
    idcontact?: number;
    /**
     * 
     * @type {number}
     * @memberof ContactDoublonSiegeSocialDto
     */
    idsiegeSocial?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactDoublonSiegeSocialDto
     */
    denominationSociale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDoublonSiegeSocialDto
     */
    numeroBce?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContactDoublonSiegeSocialDto
     */
    principal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactDoublonSiegeSocialDto
     */
    fonction?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ContactDoublonSiegeSocialDto
     */
    sortieEntreprise?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDoublonSiegeSocialDto
     */
    remarqueSiege?: string | null;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof ContactDoublonSiegeSocialDto
     */
    telephone?: TelephoneDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof ContactDoublonSiegeSocialDto
     */
    gsm?: TelephoneDto;
    /**
     * 
     * @type {EmailDto}
     * @memberof ContactDoublonSiegeSocialDto
     */
    email?: EmailDto;
}

/**
 * Check if a given object implements the ContactDoublonSiegeSocialDto interface.
 */
export function instanceOfContactDoublonSiegeSocialDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContactDoublonSiegeSocialDtoFromJSON(json: any): ContactDoublonSiegeSocialDto {
    return ContactDoublonSiegeSocialDtoFromJSONTyped(json, false);
}

export function ContactDoublonSiegeSocialDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactDoublonSiegeSocialDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontactSiege': !exists(json, 'idcontactSiege') ? undefined : json['idcontactSiege'],
        'idcontact': !exists(json, 'idcontact') ? undefined : json['idcontact'],
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'denominationSociale': !exists(json, 'denominationSociale') ? undefined : json['denominationSociale'],
        'numeroBce': !exists(json, 'numeroBce') ? undefined : json['numeroBce'],
        'principal': !exists(json, 'principal') ? undefined : json['principal'],
        'fonction': !exists(json, 'fonction') ? undefined : json['fonction'],
        'sortieEntreprise': !exists(json, 'sortieEntreprise') ? undefined : (json['sortieEntreprise'] === null ? null : new Date(json['sortieEntreprise'])),
        'remarqueSiege': !exists(json, 'remarqueSiege') ? undefined : json['remarqueSiege'],
        'telephone': !exists(json, 'telephone') ? undefined : TelephoneDtoFromJSON(json['telephone']),
        'gsm': !exists(json, 'gsm') ? undefined : TelephoneDtoFromJSON(json['gsm']),
        'email': !exists(json, 'email') ? undefined : EmailDtoFromJSON(json['email']),
    };
}

export function ContactDoublonSiegeSocialDtoToJSON(value?: ContactDoublonSiegeSocialDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontactSiege': value.idcontactSiege,
        'idcontact': value.idcontact,
        'idsiegeSocial': value.idsiegeSocial,
        'denominationSociale': value.denominationSociale,
        'numeroBce': value.numeroBce,
        'principal': value.principal,
        'fonction': value.fonction,
        'sortieEntreprise': value.sortieEntreprise === undefined ? undefined : (value.sortieEntreprise === null ? null : value.sortieEntreprise.toISOString()),
        'remarqueSiege': value.remarqueSiege,
        'telephone': TelephoneDtoToJSON(value.telephone),
        'gsm': TelephoneDtoToJSON(value.gsm),
        'email': EmailDtoToJSON(value.email),
    };
}

