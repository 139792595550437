/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRechercheClasseHoraireRemplacementSimultaneDto
 */
export interface FcbRechercheClasseHoraireRemplacementSimultaneDto {
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseHoraireRemplacementSimultaneDto
     */
    classe?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseHoraireRemplacementSimultaneDto
     */
    idClasseMatiere?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseHoraireRemplacementSimultaneDto
     */
    idRemplacement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseHoraireRemplacementSimultaneDto
     */
    idClasse?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseHoraireRemplacementSimultaneDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseHoraireRemplacementSimultaneDto
     */
    remplacant?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseHoraireRemplacementSimultaneDto
     */
    idRemplacant?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheClasseHoraireRemplacementSimultaneDto
     */
    contrat?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseHoraireRemplacementSimultaneDto
     */
    idcentre?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheClasseHoraireRemplacementSimultaneDto
     */
    idFormateurLocalisationContrat?: number | null;
}

/**
 * Check if a given object implements the FcbRechercheClasseHoraireRemplacementSimultaneDto interface.
 */
export function instanceOfFcbRechercheClasseHoraireRemplacementSimultaneDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRechercheClasseHoraireRemplacementSimultaneDtoFromJSON(json: any): FcbRechercheClasseHoraireRemplacementSimultaneDto {
    return FcbRechercheClasseHoraireRemplacementSimultaneDtoFromJSONTyped(json, false);
}

export function FcbRechercheClasseHoraireRemplacementSimultaneDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRechercheClasseHoraireRemplacementSimultaneDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'classe': !exists(json, 'classe') ? undefined : json['classe'],
        'idClasseMatiere': !exists(json, 'idClasseMatiere') ? undefined : json['idClasseMatiere'],
        'idRemplacement': !exists(json, 'idRemplacement') ? undefined : json['idRemplacement'],
        'idClasse': !exists(json, 'idClasse') ? undefined : json['idClasse'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'remplacant': !exists(json, 'remplacant') ? undefined : json['remplacant'],
        'idRemplacant': !exists(json, 'idRemplacant') ? undefined : json['idRemplacant'],
        'contrat': !exists(json, 'contrat') ? undefined : json['contrat'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'idFormateurLocalisationContrat': !exists(json, 'idFormateurLocalisationContrat') ? undefined : json['idFormateurLocalisationContrat'],
    };
}

export function FcbRechercheClasseHoraireRemplacementSimultaneDtoToJSON(value?: FcbRechercheClasseHoraireRemplacementSimultaneDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'classe': value.classe,
        'idClasseMatiere': value.idClasseMatiere,
        'idRemplacement': value.idRemplacement,
        'idClasse': value.idClasse,
        'description': value.description,
        'remplacant': value.remplacant,
        'idRemplacant': value.idRemplacant,
        'contrat': value.contrat,
        'idcentre': value.idcentre,
        'idFormateurLocalisationContrat': value.idFormateurLocalisationContrat,
    };
}

