/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DecouverteMetierDto
 */
export interface DecouverteMetierDto {
    /**
     * 
     * @type {number}
     * @memberof DecouverteMetierDto
     */
    idcontratDecouverteMetier?: number;
    /**
     * 
     * @type {number}
     * @memberof DecouverteMetierDto
     */
    idcontrat?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof DecouverteMetierDto
     */
    dateTransmission?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof DecouverteMetierDto
     */
    am1?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DecouverteMetierDto
     */
    pm1?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DecouverteMetierDto
     */
    am2?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DecouverteMetierDto
     */
    pm2?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DecouverteMetierDto
     */
    am3?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DecouverteMetierDto
     */
    pm3?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DecouverteMetierDto
     */
    am4?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DecouverteMetierDto
     */
    pm4?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DecouverteMetierDto
     */
    am5?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DecouverteMetierDto
     */
    pm5?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DecouverteMetierDto
     */
    am6?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DecouverteMetierDto
     */
    pm6?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DecouverteMetierDto
     */
    am7?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DecouverteMetierDto
     */
    pm7?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DecouverteMetierDto
     */
    am8?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DecouverteMetierDto
     */
    pm8?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DecouverteMetierDto
     */
    am9?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DecouverteMetierDto
     */
    pm9?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DecouverteMetierDto
     */
    am10?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DecouverteMetierDto
     */
    pm10?: boolean;
}

/**
 * Check if a given object implements the DecouverteMetierDto interface.
 */
export function instanceOfDecouverteMetierDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DecouverteMetierDtoFromJSON(json: any): DecouverteMetierDto {
    return DecouverteMetierDtoFromJSONTyped(json, false);
}

export function DecouverteMetierDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DecouverteMetierDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontratDecouverteMetier': !exists(json, 'idcontratDecouverteMetier') ? undefined : json['idcontratDecouverteMetier'],
        'idcontrat': !exists(json, 'idcontrat') ? undefined : json['idcontrat'],
        'dateTransmission': !exists(json, 'dateTransmission') ? undefined : (json['dateTransmission'] === null ? null : new Date(json['dateTransmission'])),
        'am1': !exists(json, 'am1') ? undefined : json['am1'],
        'pm1': !exists(json, 'pm1') ? undefined : json['pm1'],
        'am2': !exists(json, 'am2') ? undefined : json['am2'],
        'pm2': !exists(json, 'pm2') ? undefined : json['pm2'],
        'am3': !exists(json, 'am3') ? undefined : json['am3'],
        'pm3': !exists(json, 'pm3') ? undefined : json['pm3'],
        'am4': !exists(json, 'am4') ? undefined : json['am4'],
        'pm4': !exists(json, 'pm4') ? undefined : json['pm4'],
        'am5': !exists(json, 'am5') ? undefined : json['am5'],
        'pm5': !exists(json, 'pm5') ? undefined : json['pm5'],
        'am6': !exists(json, 'am6') ? undefined : json['am6'],
        'pm6': !exists(json, 'pm6') ? undefined : json['pm6'],
        'am7': !exists(json, 'am7') ? undefined : json['am7'],
        'pm7': !exists(json, 'pm7') ? undefined : json['pm7'],
        'am8': !exists(json, 'am8') ? undefined : json['am8'],
        'pm8': !exists(json, 'pm8') ? undefined : json['pm8'],
        'am9': !exists(json, 'am9') ? undefined : json['am9'],
        'pm9': !exists(json, 'pm9') ? undefined : json['pm9'],
        'am10': !exists(json, 'am10') ? undefined : json['am10'],
        'pm10': !exists(json, 'pm10') ? undefined : json['pm10'],
    };
}

export function DecouverteMetierDtoToJSON(value?: DecouverteMetierDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontratDecouverteMetier': value.idcontratDecouverteMetier,
        'idcontrat': value.idcontrat,
        'dateTransmission': value.dateTransmission === undefined ? undefined : (value.dateTransmission === null ? null : value.dateTransmission.toISOString()),
        'am1': value.am1,
        'pm1': value.pm1,
        'am2': value.am2,
        'pm2': value.pm2,
        'am3': value.am3,
        'pm3': value.pm3,
        'am4': value.am4,
        'pm4': value.pm4,
        'am5': value.am5,
        'pm5': value.pm5,
        'am6': value.am6,
        'pm6': value.pm6,
        'am7': value.am7,
        'pm7': value.pm7,
        'am8': value.am8,
        'pm8': value.pm8,
        'am9': value.am9,
        'pm9': value.pm9,
        'am10': value.am10,
        'pm10': value.pm10,
    };
}

