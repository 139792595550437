/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DossierAdmissionFichierGridDto } from './DossierAdmissionFichierGridDto';
import {
    DossierAdmissionFichierGridDtoFromJSON,
    DossierAdmissionFichierGridDtoFromJSONTyped,
    DossierAdmissionFichierGridDtoToJSON,
} from './DossierAdmissionFichierGridDto';

/**
 * 
 * @export
 * @interface DossierAdmissionEditDto
 */
export interface DossierAdmissionEditDto {
    /**
     * 
     * @type {number}
     * @memberof DossierAdmissionEditDto
     */
    iddossierAdmission?: number;
    /**
     * 
     * @type {number}
     * @memberof DossierAdmissionEditDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {string}
     * @memberof DossierAdmissionEditDto
     */
    idanneeDeFormation?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DossierAdmissionEditDto
     */
    date?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof DossierAdmissionEditDto
     */
    idresultat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DossierAdmissionEditDto
     */
    idexaminateur?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DossierAdmissionEditDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DossierAdmissionEditDto
     */
    typeAcquis?: string | null;
    /**
     * 
     * @type {Array<DossierAdmissionFichierGridDto>}
     * @memberof DossierAdmissionEditDto
     */
    fichiers?: Array<DossierAdmissionFichierGridDto> | null;
}

/**
 * Check if a given object implements the DossierAdmissionEditDto interface.
 */
export function instanceOfDossierAdmissionEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DossierAdmissionEditDtoFromJSON(json: any): DossierAdmissionEditDto {
    return DossierAdmissionEditDtoFromJSONTyped(json, false);
}

export function DossierAdmissionEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DossierAdmissionEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iddossierAdmission': !exists(json, 'iddossierAdmission') ? undefined : json['iddossierAdmission'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'idanneeDeFormation': !exists(json, 'idanneeDeFormation') ? undefined : json['idanneeDeFormation'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'idresultat': !exists(json, 'idresultat') ? undefined : json['idresultat'],
        'idexaminateur': !exists(json, 'idexaminateur') ? undefined : json['idexaminateur'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'typeAcquis': !exists(json, 'typeAcquis') ? undefined : json['typeAcquis'],
        'fichiers': !exists(json, 'fichiers') ? undefined : (json['fichiers'] === null ? null : (json['fichiers'] as Array<any>).map(DossierAdmissionFichierGridDtoFromJSON)),
    };
}

export function DossierAdmissionEditDtoToJSON(value?: DossierAdmissionEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iddossierAdmission': value.iddossierAdmission,
        'idpersonne': value.idpersonne,
        'idanneeDeFormation': value.idanneeDeFormation,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'idresultat': value.idresultat,
        'idexaminateur': value.idexaminateur,
        'remarque': value.remarque,
        'typeAcquis': value.typeAcquis,
        'fichiers': value.fichiers === undefined ? undefined : (value.fichiers === null ? null : (value.fichiers as Array<any>).map(DossierAdmissionFichierGridDtoToJSON)),
    };
}

