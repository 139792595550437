/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ETextSearchType {
    StartWith = 'StartWith',
    EndWith = 'EndWith',
    Contains = 'Contains',
    NotContains = 'NotContains',
    Equals = 'Equals',
    NotEquals = 'NotEquals'
}


export function ETextSearchTypeFromJSON(json: any): ETextSearchType {
    return ETextSearchTypeFromJSONTyped(json, false);
}

export function ETextSearchTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ETextSearchType {
    return json as ETextSearchType;
}

export function ETextSearchTypeToJSON(value?: ETextSearchType | null): any {
    return value as any;
}

