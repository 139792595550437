import * as React from "react";
import { Route, Switch } from "react-router";

import { ERoutes } from "../../../../../AppRouter";
import { ClauseMetierLiesPage } from "./ClauseMetierLiesPage";
import { ClauseMetierLiesSelector } from "./ClauseMetierLiesSelector";

export interface IClauseMetierLiesSwitchProps {}

export const ClauseMetierLiesSwitch: React.FunctionComponent<IClauseMetierLiesSwitchProps> = () => {
  return (
    <Switch>
      <Route path={`${ERoutes.clause}/:id/:tab/:state`} component={ClauseMetierLiesPage} exact />
      <Route path={`${ERoutes.clause}/:id/:tab/:state/selector`} component={ClauseMetierLiesSelector} />
    </Switch>
  );
};
