/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeuillePresencesPrintDatesDto
 */
export interface FeuillePresencesPrintDatesDto {
    /**
     * 
     * @type {Date}
     * @memberof FeuillePresencesPrintDatesDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FeuillePresencesPrintDatesDto
     */
    dateFin?: Date | null;
}

/**
 * Check if a given object implements the FeuillePresencesPrintDatesDto interface.
 */
export function instanceOfFeuillePresencesPrintDatesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FeuillePresencesPrintDatesDtoFromJSON(json: any): FeuillePresencesPrintDatesDto {
    return FeuillePresencesPrintDatesDtoFromJSONTyped(json, false);
}

export function FeuillePresencesPrintDatesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeuillePresencesPrintDatesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
    };
}

export function FeuillePresencesPrintDatesDtoToJSON(value?: FeuillePresencesPrintDatesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
    };
}

