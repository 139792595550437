/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbEcoleGridDto
 */
export interface FcbEcoleGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbEcoleGridDto
     */
    idecole?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbEcoleGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEcoleGridDto
     */
    adresse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEcoleGridDto
     */
    localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEcoleGridDto
     */
    codePostal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEcoleGridDto
     */
    codePays?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEcoleGridDto
     */
    nomPays?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEcoleGridDto
     */
    telephone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEcoleGridDto
     */
    fax?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbEcoleGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbEcoleGridDto interface.
 */
export function instanceOfFcbEcoleGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbEcoleGridDtoFromJSON(json: any): FcbEcoleGridDto {
    return FcbEcoleGridDtoFromJSONTyped(json, false);
}

export function FcbEcoleGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbEcoleGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idecole': !exists(json, 'idecole') ? undefined : json['idecole'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'adresse': !exists(json, 'adresse') ? undefined : json['adresse'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'codePostal': !exists(json, 'codePostal') ? undefined : json['codePostal'],
        'codePays': !exists(json, 'codePays') ? undefined : json['codePays'],
        'nomPays': !exists(json, 'nomPays') ? undefined : json['nomPays'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbEcoleGridDtoToJSON(value?: FcbEcoleGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idecole': value.idecole,
        'nom': value.nom,
        'adresse': value.adresse,
        'localite': value.localite,
        'codePostal': value.codePostal,
        'codePays': value.codePays,
        'nomPays': value.nomPays,
        'telephone': value.telephone,
        'fax': value.fax,
        'actif': value.actif,
    };
}

