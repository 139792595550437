import { ButtonContainer, DataTable, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";

import {
  FcbFormateurProfessionGridDto,
  FichierApi,
  FormateurProfessionApi,
  FormateurProfessionSearch
} from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import { EditButton, LinkButton, ViewButton } from "../../../../../../components";
import { useApiService, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

export interface IActiviteProfessionnelleProfessionListPageProps {
  idFormateur: number;
}

export const ActiviteProfessionnelleProfessionListPage: React.FunctionComponent<IActiviteProfessionnelleProfessionListPageProps> = ({
  idFormateur
}) => {
  const { t } = useTl();
  const api = useApiService(FormateurProfessionApi);
  const history = useHistory();
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [5],
    pageSize: 5,
    sortKeys: { libelle: "ASC" }
  });

  const searchFunction = React.useCallback(
    (sObj?: FormateurProfessionSearch) => {
      sObj.idformateur = idFormateur;
      return api.formateurProfessionBaseSearch({ FormateurProfessionSearch: sObj });
    },
    [api, idFormateur]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const fapi = useApiService(FichierApi);
  const downloadFn = React.useCallback(async idfichier => await fapi.fichierDownload({ id: idfichier }), [fapi]);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbFormateurProfessionGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() =>
                history.push(
                  `${ERoutes.formateur}/${idFormateur}/activitesProfessionnelles/Profession/${row.idprofession}/view`
                )
              }
            />
            <EditButton
              minimal={true}
              onClick={() =>
                history.push(
                  `${ERoutes.formateur}/${idFormateur}/activitesProfessionnelles/Profession/${row.idprofession}/edit`
                )
              }
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Profession),
        fieldName: "profession"
      },
      {
        header: () => t(ETLCodes.Date),
        fieldName: "date"
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "libelle"
      },
      {
        header: () => t(ETLCodes.Document),
        fieldName: "fichier",
        render: (row: FcbFormateurProfessionGridDto) =>
          row.fileName &&
          (row.idfichier ? <LinkButton text={row.fileName} onClick={() => downloadFn(row.idfichier)} /> : row.fileName)
      }
    ],
    [downloadFn, history, idFormateur, t]
  );

  return (
    <DataTable
      dateFormat="dd-MM-yyyy"
      tableState={tableState}
      loading={loading}
      columns={columns}
      filterMode="OnEnter"
    />
  );
};
