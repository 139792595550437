import * as React from "react";
import { IconNames } from "@blueprintjs/icons";
import { ETLCodes } from "../../locales/ETLCodes";
import { useTl } from "../../hooks";
import { ICustomButtonProps } from "./ICustomButtonProps";
import { Intent, Button } from "@blueprintjs/core";

interface ICancelButtonProps extends ICustomButtonProps {}

export const CancelButton: React.FunctionComponent<ICancelButtonProps> = ({
  minimal = true,
  onClick,
  disabled,
  loading,
  intent = Intent.NONE
}) => {
  const { t } = useTl();

  return (
    <Button
      icon={IconNames.RESET}
      minimal={minimal}
      onClick={onClick}
      text={minimal ? null : t(ETLCodes.Retour)}
      intent={intent}
      loading={loading}
      disabled={disabled}
    />
  );
};
