import * as React from "react";
import { Footer } from "nsitools-react";
import { useAppVersion } from "../hooks";

export interface IFooterIfapmeProps {}

export const FooterIfapme: React.FunctionComponent<IFooterIfapmeProps> = () => {
  const { appVersion } = useAppVersion();

  return <Footer version={appVersion} />;
};
