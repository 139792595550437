/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbLieuxCoursDto,
  FcbLieuxCoursGridDtoPaginatedResults,
  FilterCriteriaInfo,
  LieuxCoursSearch,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    FcbLieuxCoursDtoFromJSON,
    FcbLieuxCoursDtoToJSON,
    FcbLieuxCoursGridDtoPaginatedResultsFromJSON,
    FcbLieuxCoursGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    LieuxCoursSearchFromJSON,
    LieuxCoursSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface LieuxCoursBaseSearchRequest {
    LieuxCoursSearch?: LieuxCoursSearch;
}

export interface LieuxCoursCodeExistsRequest {
    code?: string;
}

export interface LieuxCoursDeleteRequest {
    id?: number;
}

export interface LieuxCoursGetRequest {
    id?: number;
}

export interface LieuxCoursGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface LieuxCoursGetSelectItemsRequest {
    searchField: string;
}

export interface LieuxCoursSaveRequest {
    FcbLieuxCoursDto?: FcbLieuxCoursDto;
}

/**
 * 
 */
export class LieuxCoursApi extends runtime.BaseAPI {

    /**
     */
    async lieuxCoursBaseSearchRaw(requestParameters: LieuxCoursBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbLieuxCoursGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuxCours/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LieuxCoursSearchToJSON(requestParameters.LieuxCoursSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbLieuxCoursGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async lieuxCoursBaseSearch(requestParameters: LieuxCoursBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbLieuxCoursGridDtoPaginatedResults> {
        const response = await this.lieuxCoursBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuxCoursCodeExistsRaw(requestParameters: LieuxCoursCodeExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuxCours/uniqueCode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async lieuxCoursCodeExists(requestParameters: LieuxCoursCodeExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.lieuxCoursCodeExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuxCoursDeleteRaw(requestParameters: LieuxCoursDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuxCours`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async lieuxCoursDelete(requestParameters: LieuxCoursDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.lieuxCoursDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuxCoursGetRaw(requestParameters: LieuxCoursGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbLieuxCoursDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuxCours`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbLieuxCoursDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuxCoursGet(requestParameters: LieuxCoursGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbLieuxCoursDto> {
        const response = await this.lieuxCoursGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuxCoursGetSearchCriteriasRaw(requestParameters: LieuxCoursGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuxCours/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async lieuxCoursGetSearchCriterias(requestParameters: LieuxCoursGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.lieuxCoursGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuxCoursGetSelectItemsRaw(requestParameters: LieuxCoursGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling lieuxCoursGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuxCours/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async lieuxCoursGetSelectItems(requestParameters: LieuxCoursGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.lieuxCoursGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuxCoursSaveRaw(requestParameters: LieuxCoursSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbLieuxCoursDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuxCours`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbLieuxCoursDtoToJSON(requestParameters.FcbLieuxCoursDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbLieuxCoursDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuxCoursSave(requestParameters: LieuxCoursSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbLieuxCoursDto> {
        const response = await this.lieuxCoursSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
