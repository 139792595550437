/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbSmsUtilitaireDetailDto,
  FcbSmsUtilitaireGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
  SmsUtilitaireSearch,
} from '../models/index';
import {
    FcbSmsUtilitaireDetailDtoFromJSON,
    FcbSmsUtilitaireDetailDtoToJSON,
    FcbSmsUtilitaireGridDtoPaginatedResultsFromJSON,
    FcbSmsUtilitaireGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    SmsUtilitaireSearchFromJSON,
    SmsUtilitaireSearchToJSON,
} from '../models/index';

export interface SmsUtilitaireBaseSearchRequest {
    SmsUtilitaireSearch?: SmsUtilitaireSearch;
}

export interface SmsUtilitaireGetRequest {
    id?: number;
}

export interface SmsUtilitaireGetDisplayNameRequest {
    id?: number;
}

export interface SmsUtilitaireGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface SmsUtilitaireGetSelectItemsRequest {
    searchField: string;
}

export interface SmsUtilitaireSaveRequest {
    FcbSmsUtilitaireDetailDto?: FcbSmsUtilitaireDetailDto;
}

/**
 * 
 */
export class SmsUtilitaireApi extends runtime.BaseAPI {

    /**
     */
    async smsUtilitaireBaseSearchRaw(requestParameters: SmsUtilitaireBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbSmsUtilitaireGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SmsUtilitaire/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SmsUtilitaireSearchToJSON(requestParameters.SmsUtilitaireSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbSmsUtilitaireGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async smsUtilitaireBaseSearch(requestParameters: SmsUtilitaireBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbSmsUtilitaireGridDtoPaginatedResults> {
        const response = await this.smsUtilitaireBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async smsUtilitaireGetRaw(requestParameters: SmsUtilitaireGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbSmsUtilitaireDetailDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SmsUtilitaire`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbSmsUtilitaireDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async smsUtilitaireGet(requestParameters: SmsUtilitaireGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbSmsUtilitaireDetailDto> {
        const response = await this.smsUtilitaireGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async smsUtilitaireGetDisplayNameRaw(requestParameters: SmsUtilitaireGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SmsUtilitaire/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async smsUtilitaireGetDisplayName(requestParameters: SmsUtilitaireGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.smsUtilitaireGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async smsUtilitaireGetSearchCriteriasRaw(requestParameters: SmsUtilitaireGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SmsUtilitaire/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async smsUtilitaireGetSearchCriterias(requestParameters: SmsUtilitaireGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.smsUtilitaireGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async smsUtilitaireGetSelectItemsRaw(requestParameters: SmsUtilitaireGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling smsUtilitaireGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SmsUtilitaire/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async smsUtilitaireGetSelectItems(requestParameters: SmsUtilitaireGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.smsUtilitaireGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async smsUtilitaireSaveRaw(requestParameters: SmsUtilitaireSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbSmsUtilitaireDetailDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SmsUtilitaire`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbSmsUtilitaireDetailDtoToJSON(requestParameters.FcbSmsUtilitaireDetailDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbSmsUtilitaireDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async smsUtilitaireSave(requestParameters: SmsUtilitaireSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbSmsUtilitaireDetailDto> {
        const response = await this.smsUtilitaireSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
