/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbTypeFinancementDto,
  FcbTypeFinancementGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
  TypeFinancementSearch,
} from '../models/index';
import {
    FcbTypeFinancementDtoFromJSON,
    FcbTypeFinancementDtoToJSON,
    FcbTypeFinancementGridDtoPaginatedResultsFromJSON,
    FcbTypeFinancementGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    TypeFinancementSearchFromJSON,
    TypeFinancementSearchToJSON,
} from '../models/index';

export interface TypeFinancementBaseSearchRequest {
    TypeFinancementSearch?: TypeFinancementSearch;
}

export interface TypeFinancementDeleteRequest {
    id?: number;
}

export interface TypeFinancementGetRequest {
    id?: number;
}

export interface TypeFinancementGetDisplayNameRequest {
    id?: number;
}

export interface TypeFinancementGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface TypeFinancementGetSelectItemsRequest {
    searchField: string;
}

export interface TypeFinancementSaveRequest {
    FcbTypeFinancementDto?: FcbTypeFinancementDto;
}

/**
 * 
 */
export class TypeFinancementApi extends runtime.BaseAPI {

    /**
     */
    async typeFinancementBaseSearchRaw(requestParameters: TypeFinancementBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbTypeFinancementGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeFinancement/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TypeFinancementSearchToJSON(requestParameters.TypeFinancementSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbTypeFinancementGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async typeFinancementBaseSearch(requestParameters: TypeFinancementBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbTypeFinancementGridDtoPaginatedResults> {
        const response = await this.typeFinancementBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeFinancementDeleteRaw(requestParameters: TypeFinancementDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeFinancement`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async typeFinancementDelete(requestParameters: TypeFinancementDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.typeFinancementDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeFinancementGetRaw(requestParameters: TypeFinancementGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbTypeFinancementDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeFinancement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbTypeFinancementDtoFromJSON(jsonValue));
    }

    /**
     */
    async typeFinancementGet(requestParameters: TypeFinancementGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbTypeFinancementDto> {
        const response = await this.typeFinancementGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeFinancementGetDisplayNameRaw(requestParameters: TypeFinancementGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeFinancement/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async typeFinancementGetDisplayName(requestParameters: TypeFinancementGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.typeFinancementGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeFinancementGetSearchCriteriasRaw(requestParameters: TypeFinancementGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeFinancement/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async typeFinancementGetSearchCriterias(requestParameters: TypeFinancementGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.typeFinancementGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeFinancementGetSelectItemsRaw(requestParameters: TypeFinancementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling typeFinancementGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeFinancement/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async typeFinancementGetSelectItems(requestParameters: TypeFinancementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.typeFinancementGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeFinancementSaveRaw(requestParameters: TypeFinancementSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbTypeFinancementDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeFinancement`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbTypeFinancementDtoToJSON(requestParameters.FcbTypeFinancementDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbTypeFinancementDtoFromJSON(jsonValue));
    }

    /**
     */
    async typeFinancementSave(requestParameters: TypeFinancementSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbTypeFinancementDto> {
        const response = await this.typeFinancementSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
