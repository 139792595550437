/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TypeBulletinGridDto
 */
export interface TypeBulletinGridDto {
    /**
     * 
     * @type {number}
     * @memberof TypeBulletinGridDto
     */
    idtypeBulletin?: number;
    /**
     * 
     * @type {string}
     * @memberof TypeBulletinGridDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TypeBulletinGridDto
     */
    libelle?: string | null;
}

/**
 * Check if a given object implements the TypeBulletinGridDto interface.
 */
export function instanceOfTypeBulletinGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TypeBulletinGridDtoFromJSON(json: any): TypeBulletinGridDto {
    return TypeBulletinGridDtoFromJSONTyped(json, false);
}

export function TypeBulletinGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TypeBulletinGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idtypeBulletin': !exists(json, 'idtypeBulletin') ? undefined : json['idtypeBulletin'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
    };
}

export function TypeBulletinGridDtoToJSON(value?: TypeBulletinGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idtypeBulletin': value.idtypeBulletin,
        'code': value.code,
        'libelle': value.libelle,
    };
}

