import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { RemplacementApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { RemplacementSwitch } from "./RemplacementDetail/RemplacementSwitch";
import { RemplacementGeneralPage } from "./RemplacementGeneralPage";

export interface IRemplacementItemPageProps {}

export const RemplacementItemPage: React.FunctionComponent<IRemplacementItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const api = useApiService(RemplacementApi);

  const [displayName] = useApiEffect(() => api.remplacementGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.Remplacements), route: ERoutes.remplacement },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsRemplacement"
        onChange={newTabId => history.push(`${ERoutes.remplacement}/${id}/${newTabId}/${state}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.remplacement} />
        <Tab id="general" title={t(ETLCodes.General)} panel={<RemplacementGeneralPage />} />
        <Tab id="detail" title={t(ETLCodes.Detail)} panel={<RemplacementSwitch />} disabled={+id <= 0} />
      </ProtectedTabs>
    </PageBase>
  );
};
