/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbMatiereReferentielDto,
  FcbMatiereReferentielGridDtoPaginatedResults,
  FilterCriteriaInfo,
  MatiereHeureDto,
  MatiereReferentielSearch,
  SelectItem,
} from '../models/index';
import {
    FcbMatiereReferentielDtoFromJSON,
    FcbMatiereReferentielDtoToJSON,
    FcbMatiereReferentielGridDtoPaginatedResultsFromJSON,
    FcbMatiereReferentielGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    MatiereHeureDtoFromJSON,
    MatiereHeureDtoToJSON,
    MatiereReferentielSearchFromJSON,
    MatiereReferentielSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface MatiereReferentielBaseSearchRequest {
    MatiereReferentielSearch?: MatiereReferentielSearch;
}

export interface MatiereReferentielDeleteRequest {
    idReferentiel?: number;
    idMatiere?: string;
}

export interface MatiereReferentielGetRequest {
    idReferentiel?: number;
    idMatiere?: string;
}

export interface MatiereReferentielGetHoursRequest {
    idReferentiel?: number;
}

export interface MatiereReferentielGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface MatiereReferentielGetSelectItemsRequest {
    searchField: string;
}

export interface MatiereReferentielSaveRequest {
    FcbMatiereReferentielDto?: FcbMatiereReferentielDto;
}

/**
 * 
 */
export class MatiereReferentielApi extends runtime.BaseAPI {

    /**
     */
    async matiereReferentielBaseSearchRaw(requestParameters: MatiereReferentielBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbMatiereReferentielGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/MatiereReferentiel/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MatiereReferentielSearchToJSON(requestParameters.MatiereReferentielSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbMatiereReferentielGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async matiereReferentielBaseSearch(requestParameters: MatiereReferentielBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbMatiereReferentielGridDtoPaginatedResults> {
        const response = await this.matiereReferentielBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async matiereReferentielDeleteRaw(requestParameters: MatiereReferentielDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.idReferentiel !== undefined) {
            queryParameters['idReferentiel'] = requestParameters.idReferentiel;
        }

        if (requestParameters.idMatiere !== undefined) {
            queryParameters['idMatiere'] = requestParameters.idMatiere;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/MatiereReferentiel`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async matiereReferentielDelete(requestParameters: MatiereReferentielDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.matiereReferentielDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async matiereReferentielGetRaw(requestParameters: MatiereReferentielGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbMatiereReferentielDto>> {
        const queryParameters: any = {};

        if (requestParameters.idReferentiel !== undefined) {
            queryParameters['idReferentiel'] = requestParameters.idReferentiel;
        }

        if (requestParameters.idMatiere !== undefined) {
            queryParameters['idMatiere'] = requestParameters.idMatiere;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/MatiereReferentiel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbMatiereReferentielDtoFromJSON(jsonValue));
    }

    /**
     */
    async matiereReferentielGet(requestParameters: MatiereReferentielGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbMatiereReferentielDto> {
        const response = await this.matiereReferentielGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async matiereReferentielGetHoursRaw(requestParameters: MatiereReferentielGetHoursRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MatiereHeureDto>> {
        const queryParameters: any = {};

        if (requestParameters.idReferentiel !== undefined) {
            queryParameters['idReferentiel'] = requestParameters.idReferentiel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/MatiereReferentiel/GetHours`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MatiereHeureDtoFromJSON(jsonValue));
    }

    /**
     */
    async matiereReferentielGetHours(requestParameters: MatiereReferentielGetHoursRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MatiereHeureDto> {
        const response = await this.matiereReferentielGetHoursRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async matiereReferentielGetSearchCriteriasRaw(requestParameters: MatiereReferentielGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/MatiereReferentiel/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async matiereReferentielGetSearchCriterias(requestParameters: MatiereReferentielGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.matiereReferentielGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async matiereReferentielGetSelectItemsRaw(requestParameters: MatiereReferentielGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling matiereReferentielGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/MatiereReferentiel/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async matiereReferentielGetSelectItems(requestParameters: MatiereReferentielGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.matiereReferentielGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async matiereReferentielSaveRaw(requestParameters: MatiereReferentielSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbMatiereReferentielDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/MatiereReferentiel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbMatiereReferentielDtoToJSON(requestParameters.FcbMatiereReferentielDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbMatiereReferentielDtoFromJSON(jsonValue));
    }

    /**
     */
    async matiereReferentielSave(requestParameters: MatiereReferentielSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbMatiereReferentielDto> {
        const response = await this.matiereReferentielSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
