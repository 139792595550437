/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbClasseRemarqueDto
 */
export interface FcbClasseRemarqueDto {
    /**
     * 
     * @type {number}
     * @memberof FcbClasseRemarqueDto
     */
    idRemarque?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseRemarqueDto
     */
    idClasse?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseRemarqueDto
     */
    idUser?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseRemarqueDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbClasseRemarqueDto
     */
    dateRemarque?: Date | null;
}

/**
 * Check if a given object implements the FcbClasseRemarqueDto interface.
 */
export function instanceOfFcbClasseRemarqueDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbClasseRemarqueDtoFromJSON(json: any): FcbClasseRemarqueDto {
    return FcbClasseRemarqueDtoFromJSONTyped(json, false);
}

export function FcbClasseRemarqueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbClasseRemarqueDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idRemarque': !exists(json, 'idRemarque') ? undefined : json['idRemarque'],
        'idClasse': !exists(json, 'idClasse') ? undefined : json['idClasse'],
        'idUser': !exists(json, 'idUser') ? undefined : json['idUser'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'dateRemarque': !exists(json, 'dateRemarque') ? undefined : (json['dateRemarque'] === null ? null : new Date(json['dateRemarque'])),
    };
}

export function FcbClasseRemarqueDtoToJSON(value?: FcbClasseRemarqueDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idRemarque': value.idRemarque,
        'idClasse': value.idClasse,
        'idUser': value.idUser,
        'remarque': value.remarque,
        'dateRemarque': value.dateRemarque === undefined ? undefined : (value.dateRemarque === null ? null : value.dateRemarque.toISOString()),
    };
}

