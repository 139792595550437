import { darken, lighten } from "polished";

import assets from "./assets";

export interface IThemeData {
  siteTheme: SiteTheme;
  headerLeftColor: string;
  headerRightColor: string;
  menuColor: string;
  menuActiveColor: string;
  menuBackgroundColor: string;
  menuSubColor: string;
  menuSubColorSelected: string;
  menuIconColor: string;
  headerName: string;
  highlightColor: string;
  logoUrl: string;
  primaryColor: string;
  quickAccessSelectedColor: string;
  sucessColor: string;
  dangerColor: string;
  warningColor: string;
}

const walterTestTheme: IThemeData = {
  siteTheme: "test-walter",
  primaryColor: "#7961DB",
  headerLeftColor: "#5642A6",
  headerRightColor: "#5642A6",
  menuColor: "#7961DB",
  menuActiveColor: lighten(0.1, "#7961DB"),
  menuBackgroundColor: "#30404D",
  menuSubColor: lighten(0.1, "#7961DB"),
  menuSubColorSelected: lighten(0.2, "#7961DB"),
  menuIconColor: darken(0.1, "#7961DB"),
  highlightColor: "white",
  headerName: "IFAPME - Walter",
  logoUrl: assets.images.walterTestLogo,
  quickAccessSelectedColor: lighten(0.4, "#7961DB"),
  sucessColor: "#238551",
  dangerColor: "#CD4246",
  warningColor: "#C87619"
};

const walterTheme: IThemeData = {
  siteTheme: "walter",
  primaryColor: "#106BA3",
  headerLeftColor: "#0e5a8a",
  headerRightColor: "#0e5a8a",
  menuColor: "#106ba3",
  menuActiveColor: lighten(0.1, "#106ba3"),
  menuBackgroundColor: "#30404D",
  menuSubColor: lighten(0.1, "#106ba3"),
  menuSubColorSelected: lighten(0.2, "#106ba3"),
  menuIconColor: darken(0.1, "#106ba3"),
  highlightColor: "white",
  headerName: "IFAPME - Walter",
  logoUrl: assets.images.walterLogo,
  quickAccessSelectedColor: lighten(0.4, "#106ba3"),
  sucessColor: "#238551",
  dangerColor: "#CD4246",
  warningColor: "#C87619"
};

const hopeTheme: IThemeData = {
  siteTheme: "hope",
  primaryColor: "#FA1955",
  headerLeftColor: "#7C0041",
  headerRightColor: "#ED174F",
  menuColor: "#7C0041",
  menuActiveColor: lighten(0.1, "#7C0041"),
  menuBackgroundColor: darken(0.1, "#7C0041"),
  menuSubColor: darken(0.1, "#ED174F"),
  menuSubColorSelected: "#ED174F",
  menuIconColor: "white",
  headerName: "IFAPME - Hope",
  highlightColor: "white",
  logoUrl: assets.images.hopeLogo,
  quickAccessSelectedColor: lighten(0.2, "#7C0041"),
  sucessColor: "#2DB338",
  dangerColor: "#E04119",
  warningColor: "#DEA20B"
};

const hopeTestTheme: IThemeData = {
  siteTheme: "test-hope",
  primaryColor: "#29a634",
  headerLeftColor: "#1d7324",
  headerRightColor: "#29a634",
  menuColor: "#1d7324",
  menuActiveColor: lighten(0.1, "#1d7324"),
  menuBackgroundColor: darken(0.15, "#1d7324"),
  menuSubColor: darken(0.1, "#29a634"),
  menuSubColorSelected: "#29a634",
  menuIconColor: "white",
  headerName: "IFAPME - Hope",
  highlightColor: "white",
  logoUrl: assets.images.hopeLogoTest,
  quickAccessSelectedColor: lighten(0.2, "#1d7324"),
  sucessColor: "#2DB338",
  dangerColor: "#E04119",
  warningColor: "#DEA20B"
};

export type SiteTheme = "walter" | "hope" | "test-walter" | "test-hope";

export function getTheme(type: SiteTheme) {
  switch (type) {
    case "walter":
      return walterTheme;
    case "test-walter":
      return walterTestTheme;
    case "hope":
      return hopeTheme;
    case "test-hope":
      return hopeTestTheme;
    default:
      return walterTheme;
  }
}
