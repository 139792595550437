import { FGSuggestTextInput } from 'nsitools-react';
import * as React from 'react';

import { ReferentialItemDto } from '../api';
import { useTl } from '../hooks';
import { ETLCodes } from '../locales';

export interface ICodePostalSuggestProps {
  name: string;
  codesPostaux: ReferentialItemDto[];
  cpLoading: boolean;
  idcp: number;
}

export const CodePostalSuggest: React.FunctionComponent<ICodePostalSuggestProps> = ({ name, codesPostaux, cpLoading }) => {
  const { t } = useTl();
  // NOT USED FOR NOW

  const mappedCps = React.useMemo(() => codesPostaux?.map(c => c.displayValue) ?? [], [codesPostaux]);

  return (<FGSuggestTextInput
    name={name}
    label={t(ETLCodes.CodePostal)}
    maxLength={6}
    items={mappedCps}
    loading={cpLoading}
  />);
};