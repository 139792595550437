/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DashboardDemandeFormationGridDto } from './DashboardDemandeFormationGridDto';
import {
    DashboardDemandeFormationGridDtoFromJSON,
    DashboardDemandeFormationGridDtoFromJSONTyped,
    DashboardDemandeFormationGridDtoToJSON,
} from './DashboardDemandeFormationGridDto';
import type { IAggregateResult } from './IAggregateResult';
import {
    IAggregateResultFromJSON,
    IAggregateResultFromJSONTyped,
    IAggregateResultToJSON,
} from './IAggregateResult';

/**
 * 
 * @export
 * @interface DashboardDemandeFormationGridDtoPaginatedResults
 */
export interface DashboardDemandeFormationGridDtoPaginatedResults {
    /**
     * 
     * @type {number}
     * @memberof DashboardDemandeFormationGridDtoPaginatedResults
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<DashboardDemandeFormationGridDto>}
     * @memberof DashboardDemandeFormationGridDtoPaginatedResults
     */
    results?: Array<DashboardDemandeFormationGridDto> | null;
    /**
     * 
     * @type {Array<IAggregateResult>}
     * @memberof DashboardDemandeFormationGridDtoPaginatedResults
     */
    aggregateResults?: Array<IAggregateResult> | null;
}

/**
 * Check if a given object implements the DashboardDemandeFormationGridDtoPaginatedResults interface.
 */
export function instanceOfDashboardDemandeFormationGridDtoPaginatedResults(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DashboardDemandeFormationGridDtoPaginatedResultsFromJSON(json: any): DashboardDemandeFormationGridDtoPaginatedResults {
    return DashboardDemandeFormationGridDtoPaginatedResultsFromJSONTyped(json, false);
}

export function DashboardDemandeFormationGridDtoPaginatedResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardDemandeFormationGridDtoPaginatedResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'results': !exists(json, 'results') ? undefined : (json['results'] === null ? null : (json['results'] as Array<any>).map(DashboardDemandeFormationGridDtoFromJSON)),
        'aggregateResults': !exists(json, 'aggregateResults') ? undefined : (json['aggregateResults'] === null ? null : (json['aggregateResults'] as Array<any>).map(IAggregateResultFromJSON)),
    };
}

export function DashboardDemandeFormationGridDtoPaginatedResultsToJSON(value?: DashboardDemandeFormationGridDtoPaginatedResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'results': value.results === undefined ? undefined : (value.results === null ? null : (value.results as Array<any>).map(DashboardDemandeFormationGridDtoToJSON)),
        'aggregateResults': value.aggregateResults === undefined ? undefined : (value.aggregateResults === null ? null : (value.aggregateResults as Array<any>).map(IAggregateResultToJSON)),
    };
}

