import { Classes, Dialog } from "@blueprintjs/core";
import { endOfToday } from "date-fns";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";

import { LieuFormationAgrementVisite } from ".";
import { LieuFormationAgrementVisiteDto } from "../../../../../../api";
import { SmallFormGenerator } from "../../../../../../components";
import { useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

const StyledDialog = styled(Dialog)`
  width: 500px;
  height: auto;
  background-color: white;
`;

export interface ILieuFormationAgrementVisiteDialogProps {
  dialogOpen: boolean;
  onClose: (toSave?: LieuFormationAgrementVisiteDto) => void;
  currentVisite: LieuFormationAgrementVisiteDto;
}

export const LieuFormationAgrementVisiteDialog: React.FunctionComponent<ILieuFormationAgrementVisiteDialogProps> = ({
  dialogOpen,
  onClose,
  currentVisite
}) => {
  const { t } = useTl();

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape({
        dateVisite: Yup.date()
          .nullable()
          .max(endOfToday(), t(ETLCodes.DateCannotBeInFurture))
          .required(t(ETLCodes.Required))
      }),
    [t]
  );

  return (
    <StyledDialog title={t(ETLCodes.Visite)} isOpen={dialogOpen} onClose={() => onClose()}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={currentVisite}
          onSubmit={onClose}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose()}
          showDeleteButton={false}
        >
          <LieuFormationAgrementVisite />
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
