import * as React from "react";
import styled from "styled-components";
import { useTl, useApiService, useAbortableApiServiceFactory } from "../../../../../hooks";
import { useHistory } from "react-router";
import { ViewButton, EditButton, SearchTablePage } from "../../../../../components";
import { ERoutes } from "../../../../../AppRouter";
import { ETLCodes } from "../../../../../locales";
import { TypeEvaluationApi, FcbTypeEvaluationDto, TypeEvaluationSearch, EBooleanSearchTypes } from "../../../../../api";

interface ITypeEvaluationListPageProps {}
const Container = styled.div`
  display: flex;
`;
export const TypeEvaluationListPage: React.FunctionComponent<ITypeEvaluationListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(TypeEvaluationApi);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbTypeEvaluationDto) => (
          <Container>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.typeEvaluation}/${row.idevaluationType}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.typeEvaluation}/${row.idevaluationType}/detail/edit`)}
            />
          </Container>
        )
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "libelle"
      }
    ],
    [history, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.typeEvaluation}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(() => api.typeEvaluationGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const apiFactory = useAbortableApiServiceFactory(TypeEvaluationApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: TypeEvaluationSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.typeEvaluationBaseSearch({ TypeEvaluationSearch: sObj });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.TypesEvaluation), route: ERoutes.typeEvaluation }]}
      addFunc={onAddItem}
      sortKeys={{ libelle: "ASC" }}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
    />
  );
};
