import { Button, Callout } from "@blueprintjs/core";
import { FieldSet } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import {
  TU_FINAL_KEY,
  CarouselComparer,
  DoublonsDataCard,
  useDoublonsStateContext,
  DoublonsMultipleDataCard
} from "..";
import { ReferentialItemDto, TuteurDoublonEditDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

const Container = styled.div`
  height: 70vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export interface IDoublonTuteurSignaletiqueProps {}

export const DoublonTuteurSignaletique: React.FunctionComponent<IDoublonTuteurSignaletiqueProps> = props => {
  const { t } = useTl();
  const { data, selectData, getSelectedIndex } = useDoublonsStateContext();

  const selectedIndex = React.useMemo(() => getSelectedIndex(`${TU_FINAL_KEY}.idtuteur`) ?? 0, [getSelectedIndex]);
  const tuteurContainer = React.useCallback(
    (index: number, nb: number) => {
      const tuteur: TuteurDoublonEditDto = data?.tuteurs[index];
      if (!tuteur) return;

      return (
        <FieldSet
          key={tuteur.idtuteur}
          title={
            <TitleContainer>
              {index !== selectedIndex ? (
                <Button
                  text={t(ETLCodes.Conserver)}
                  icon={"arrow-left"}
                  onClick={() => selectData(`${TU_FINAL_KEY}.idtuteur`, index)}
                />
              ) : (
                <div />
              )}
              <ViewButton
                text={t(ETLCodes.TuteurNb, { nb: nb, id: tuteur.idtuteur })}
                onClick={() => window.open(`#${ERoutes.tuteur}/${tuteur.idtuteur}/detail/view`, "_blank")}
              />
              <div />
            </TitleContainer>
          }
          titlePosition="center"
        >
          <DoublonsDataCard
            extValue={
              <InformationContainer>
                <div>{t(ETLCodes.NbContrats, { nb: tuteur.nbContrats })}</div>
              </InformationContainer>
            }
            label={t(ETLCodes.Informations)}
            interactive={false}
          />
          <DoublonsMultipleDataCard
            title={t(ETLCodes.Metiers)}
            fieldPath={`tuteurs[${index}].idsmetier`}
            finalEntityKey={TU_FINAL_KEY}
            interactive={() => false}
            forceSelected={() => false}
          >
            {(t: ReferentialItemDto) => <>{t.displayValue}</>}
          </DoublonsMultipleDataCard>
        </FieldSet>
      );
    },
    [data?.tuteurs, selectData, selectedIndex, t]
  );

  return (
    <Container>
      <Callout
        intent="warning"
        title={t(ETLCodes.DoublonTuteurMetiersContratsCumules)}
        style={{ marginBottom: "1rem" }}
        icon="info-sign"
      />
      <CarouselComparer leftElement={tuteurContainer(selectedIndex, selectedIndex + 1)}>
        {data?.tuteurs?.length > 1 &&
          data?.tuteurs
            ?.filter((a, i) => i !== selectedIndex)
            ?.map((a, i) => tuteurContainer(i + (i >= selectedIndex ? 1 : 0), i + (i >= selectedIndex ? 2 : 1)))}
      </CarouselComparer>
    </Container>
  );
};
