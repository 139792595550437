/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DemandeInscriptionEditDto } from './DemandeInscriptionEditDto';
import {
    DemandeInscriptionEditDtoFromJSON,
    DemandeInscriptionEditDtoFromJSONTyped,
    DemandeInscriptionEditDtoToJSON,
} from './DemandeInscriptionEditDto';
import type { EStatutDemande } from './EStatutDemande';
import {
    EStatutDemandeFromJSON,
    EStatutDemandeFromJSONTyped,
    EStatutDemandeToJSON,
} from './EStatutDemande';

/**
 * 
 * @export
 * @interface DemandeInscriptionCreateDto
 */
export interface DemandeInscriptionCreateDto {
    /**
     * 
     * @type {number}
     * @memberof DemandeInscriptionCreateDto
     */
    idService?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DemandeInscriptionCreateDto
     */
    idParcoursFormation?: number;
    /**
     * 
     * @type {EStatutDemande}
     * @memberof DemandeInscriptionCreateDto
     */
    statutToSend?: EStatutDemande;
    /**
     * 
     * @type {Array<DemandeInscriptionEditDto>}
     * @memberof DemandeInscriptionCreateDto
     */
    demandes?: Array<DemandeInscriptionEditDto> | null;
}

/**
 * Check if a given object implements the DemandeInscriptionCreateDto interface.
 */
export function instanceOfDemandeInscriptionCreateDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DemandeInscriptionCreateDtoFromJSON(json: any): DemandeInscriptionCreateDto {
    return DemandeInscriptionCreateDtoFromJSONTyped(json, false);
}

export function DemandeInscriptionCreateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DemandeInscriptionCreateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idService': !exists(json, 'idService') ? undefined : json['idService'],
        'idParcoursFormation': !exists(json, 'idParcoursFormation') ? undefined : json['idParcoursFormation'],
        'statutToSend': !exists(json, 'statutToSend') ? undefined : EStatutDemandeFromJSON(json['statutToSend']),
        'demandes': !exists(json, 'demandes') ? undefined : (json['demandes'] === null ? null : (json['demandes'] as Array<any>).map(DemandeInscriptionEditDtoFromJSON)),
    };
}

export function DemandeInscriptionCreateDtoToJSON(value?: DemandeInscriptionCreateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idService': value.idService,
        'idParcoursFormation': value.idParcoursFormation,
        'statutToSend': EStatutDemandeToJSON(value.statutToSend),
        'demandes': value.demandes === undefined ? undefined : (value.demandes === null ? null : (value.demandes as Array<any>).map(DemandeInscriptionEditDtoToJSON)),
    };
}

