import * as React from "react";
import { matchPath, useLocation, useParams } from "react-router";
import { LieuFormationAgrementItem, LieuFormationAgrementList } from ".";
import { ERoutes } from "../../../../../AppRouter";

export interface ILieuFormationAgrementSwitchProps {}

export const LieuFormationAgrementSwitch: React.FunctionComponent<ILieuFormationAgrementSwitchProps> = props => {
  const { pathname } = useLocation();
  const { id } = useParams<{ id: string; tab: string; state: string }>();
  if (
    matchPath(pathname, {
      path: `${ERoutes.lieuFormation}/${id}/agrements/:state/:subId`
    })
  ) {
    return <LieuFormationAgrementItem />;
  } else {
    return <LieuFormationAgrementList />;
  }
};
