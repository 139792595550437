/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DeliberationMasseSearch,
  FcbDeliberationMasseDto,
  FcbDeliberationMasseGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    DeliberationMasseSearchFromJSON,
    DeliberationMasseSearchToJSON,
    FcbDeliberationMasseDtoFromJSON,
    FcbDeliberationMasseDtoToJSON,
    FcbDeliberationMasseGridDtoPaginatedResultsFromJSON,
    FcbDeliberationMasseGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface DeliberationMasseBaseSearchRequest {
    DeliberationMasseSearch?: DeliberationMasseSearch;
}

export interface DeliberationMasseGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface DeliberationMasseGetSelectItemsRequest {
    searchField: string;
}

export interface DeliberationMasseSaveRequest {
    FcbDeliberationMasseDto?: FcbDeliberationMasseDto;
}

export interface DeliberationMasseSearchBatIdsRequest {
    DeliberationMasseSearch?: DeliberationMasseSearch;
}

/**
 * 
 */
export class DeliberationMasseApi extends runtime.BaseAPI {

    /**
     */
    async deliberationMasseBaseSearchRaw(requestParameters: DeliberationMasseBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbDeliberationMasseGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DeliberationMasse/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeliberationMasseSearchToJSON(requestParameters.DeliberationMasseSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbDeliberationMasseGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async deliberationMasseBaseSearch(requestParameters: DeliberationMasseBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbDeliberationMasseGridDtoPaginatedResults> {
        const response = await this.deliberationMasseBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deliberationMasseGetSearchCriteriasRaw(requestParameters: DeliberationMasseGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DeliberationMasse/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async deliberationMasseGetSearchCriterias(requestParameters: DeliberationMasseGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.deliberationMasseGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deliberationMasseGetSelectItemsRaw(requestParameters: DeliberationMasseGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling deliberationMasseGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DeliberationMasse/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async deliberationMasseGetSelectItems(requestParameters: DeliberationMasseGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.deliberationMasseGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deliberationMasseSaveRaw(requestParameters: DeliberationMasseSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbDeliberationMasseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DeliberationMasse`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbDeliberationMasseDtoToJSON(requestParameters.FcbDeliberationMasseDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbDeliberationMasseDtoFromJSON(jsonValue));
    }

    /**
     */
    async deliberationMasseSave(requestParameters: DeliberationMasseSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbDeliberationMasseDto> {
        const response = await this.deliberationMasseSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deliberationMasseSearchBatIdsRaw(requestParameters: DeliberationMasseSearchBatIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DeliberationMasse/SearchBatIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeliberationMasseSearchToJSON(requestParameters.DeliberationMasseSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async deliberationMasseSearchBatIds(requestParameters: DeliberationMasseSearchBatIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.deliberationMasseSearchBatIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
