/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbTypeEnseignementDto,
  FcbTypeEnseignementDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
  TypeEnseignementSearch,
} from '../models/index';
import {
    FcbTypeEnseignementDtoFromJSON,
    FcbTypeEnseignementDtoToJSON,
    FcbTypeEnseignementDtoPaginatedResultsFromJSON,
    FcbTypeEnseignementDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    TypeEnseignementSearchFromJSON,
    TypeEnseignementSearchToJSON,
} from '../models/index';

export interface TypeEnseignementBaseSearchRequest {
    TypeEnseignementSearch?: TypeEnseignementSearch;
}

export interface TypeEnseignementDeleteRequest {
    id?: number;
}

export interface TypeEnseignementGetRequest {
    id?: number;
}

export interface TypeEnseignementGetDisplayNameRequest {
    id?: number;
}

export interface TypeEnseignementGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface TypeEnseignementGetSelectItemsRequest {
    searchField: string;
}

export interface TypeEnseignementSaveRequest {
    FcbTypeEnseignementDto?: FcbTypeEnseignementDto;
}

/**
 * 
 */
export class TypeEnseignementApi extends runtime.BaseAPI {

    /**
     */
    async typeEnseignementBaseSearchRaw(requestParameters: TypeEnseignementBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbTypeEnseignementDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeEnseignement/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TypeEnseignementSearchToJSON(requestParameters.TypeEnseignementSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbTypeEnseignementDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async typeEnseignementBaseSearch(requestParameters: TypeEnseignementBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbTypeEnseignementDtoPaginatedResults> {
        const response = await this.typeEnseignementBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeEnseignementDeleteRaw(requestParameters: TypeEnseignementDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeEnseignement`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async typeEnseignementDelete(requestParameters: TypeEnseignementDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.typeEnseignementDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeEnseignementGetRaw(requestParameters: TypeEnseignementGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbTypeEnseignementDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeEnseignement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbTypeEnseignementDtoFromJSON(jsonValue));
    }

    /**
     */
    async typeEnseignementGet(requestParameters: TypeEnseignementGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbTypeEnseignementDto> {
        const response = await this.typeEnseignementGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeEnseignementGetDisplayNameRaw(requestParameters: TypeEnseignementGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeEnseignement/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async typeEnseignementGetDisplayName(requestParameters: TypeEnseignementGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.typeEnseignementGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeEnseignementGetSearchCriteriasRaw(requestParameters: TypeEnseignementGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeEnseignement/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async typeEnseignementGetSearchCriterias(requestParameters: TypeEnseignementGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.typeEnseignementGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeEnseignementGetSelectItemsRaw(requestParameters: TypeEnseignementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling typeEnseignementGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeEnseignement/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async typeEnseignementGetSelectItems(requestParameters: TypeEnseignementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.typeEnseignementGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeEnseignementSaveRaw(requestParameters: TypeEnseignementSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbTypeEnseignementDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeEnseignement`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbTypeEnseignementDtoToJSON(requestParameters.FcbTypeEnseignementDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbTypeEnseignementDtoFromJSON(jsonValue));
    }

    /**
     */
    async typeEnseignementSave(requestParameters: TypeEnseignementSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbTypeEnseignementDto> {
        const response = await this.typeEnseignementSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
