import { Classes, Icon, Menu } from "@blueprintjs/core";
import { ToolTipButton } from "nsitools-react";
import * as React from "react";
import { useLocation } from "react-router";
import styled from "styled-components";

import { useTheme } from "../../hooks";
import { useTl } from "../../hooks/useTl";
import { ETLCodes } from "../../locales/ETLCodes";
import { IThemeData } from "../../theme";
import { CollapseAllContext, OpenedMenuId } from "./CollapseAllContext";
import { Container } from "./Container";
import { INavigationItem } from "./INavigationItem";
import { IMenuItemProps, MenuItem } from "./MenuItem";
import { QuickAccess } from "./QuickAccess";

interface INavigationMenuProps extends Pick<IMenuItemProps, "onNavigation"> {
  navigationMenu: INavigationItem[];
  isFixed: boolean;
  changeFixed: (value: boolean) => void;
  collapseOtherOnSelection?: boolean;
  maxAutoCollapseLevel?: number;
}

const MenuStyled = styled(Menu)<{ theme: IThemeData }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 0.5em;
  background-color: ${({ theme }) => theme.menuBackgroundColor} !important;
`;

const TitleContainer = styled.div<{ theme: IThemeData }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.menuBackgroundColor} !important;
  align-items: center;
`;

export const NavigationMenu: React.FunctionComponent<INavigationMenuProps> = ({
  navigationMenu,
  isFixed,
  changeFixed,
  collapseOtherOnSelection: closeOtherOnSelection,
  maxAutoCollapseLevel,
  onNavigation
}) => {
  const { t } = useTl();
  const [openedMenuId, setMenuOpenedId] = React.useState<OpenedMenuId | null>(null);
  const { pathname } = useLocation();
  const { theme } = useTheme();

  const checkOpened = React.useCallback(
    (navItem: INavigationItem): INavigationItem => {
      let opened = false;
      if (navItem.route && pathname.startsWith(navItem.route)) {
        opened = true;
      }
      const nextNavItems = navItem.items ? navItem.items.map(checkOpened) : [];
      opened = opened || nextNavItems.filter(d => d.defaultOpened).length > 0;
      return {
        ...navItem,
        defaultOpened: opened,
        items: nextNavItems
      };
    },
    [pathname]
  );

  const navigationMenuFinal = React.useMemo(() => {
    // Keep menu original state for root route
    // if (pathname === "/") return navigationMenu;
    return navigationMenu.map(checkOpened);
  }, [checkOpened, navigationMenu]);

  return (
    <Container className={Classes.DARK} theme={theme}>
      <TitleContainer theme={theme}>
        <div className={Classes.DRAWER_HEADER} style={{ width: "100%", display: "flex", flexDirection: "column" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: theme.highlightColor
            }}
          >
            <div>
              {t(ETLCodes.Menu)}&nbsp;
              {/* <ToolTipButton tooltip={t(ETLCodes.QuickAccess)} minimal icon="search"></ToolTipButton> */}
            </div>
            <ToolTipButton
              tooltip={isFixed ? t(ETLCodes.UnFixMenuTooltip) : t(ETLCodes.FixMenuTooltip)}
              icon={<Icon icon={isFixed ? "unpin" : "pin"} color={theme.highlightColor} />}
              minimal={true}
              onClick={() => changeFixed(!isFixed)}
            ></ToolTipButton>
          </div>
        </div>
      </TitleContainer>
      <CollapseAllContext.Provider
        value={{ openedMenuId, setMenuOpenedId: closeOtherOnSelection ? setMenuOpenedId : () => {} }}
      >
        <QuickAccess navigationMenu={navigationMenu} onNavigation={onNavigation}></QuickAccess>
        <MenuStyled large={false} theme={theme}>
          {navigationMenuFinal.map((n, i) => (
            <MenuItem item={n} key={i} level={0} disabled={n.disabled} maxAutoCollapseLevel={maxAutoCollapseLevel} />
          ))}
        </MenuStyled>
      </CollapseAllContext.Provider>
    </Container>
  );
};
