/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  BulletinApprenantRecapitulatifSearch,
  BulletinDetailSearch,
  FcbBulletinApprenantRecapitulatifGridDtoPaginatedResults,
  FcbBulletinApprenantTypeDetailDto,
  FcbBulletinApprenantTypeDto,
  FcbBulletinApprenantTypeSaveDto,
  FcbResultatTypeBulletinSession2GridDtoPaginatedResults,
  FcbResultatTypeGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    BulletinApprenantRecapitulatifSearchFromJSON,
    BulletinApprenantRecapitulatifSearchToJSON,
    BulletinDetailSearchFromJSON,
    BulletinDetailSearchToJSON,
    FcbBulletinApprenantRecapitulatifGridDtoPaginatedResultsFromJSON,
    FcbBulletinApprenantRecapitulatifGridDtoPaginatedResultsToJSON,
    FcbBulletinApprenantTypeDetailDtoFromJSON,
    FcbBulletinApprenantTypeDetailDtoToJSON,
    FcbBulletinApprenantTypeDtoFromJSON,
    FcbBulletinApprenantTypeDtoToJSON,
    FcbBulletinApprenantTypeSaveDtoFromJSON,
    FcbBulletinApprenantTypeSaveDtoToJSON,
    FcbResultatTypeBulletinSession2GridDtoPaginatedResultsFromJSON,
    FcbResultatTypeBulletinSession2GridDtoPaginatedResultsToJSON,
    FcbResultatTypeGridDtoPaginatedResultsFromJSON,
    FcbResultatTypeGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface BulletinApprenantTypeBaseSearchRequest {
    BulletinApprenantRecapitulatifSearch?: BulletinApprenantRecapitulatifSearch;
}

export interface BulletinApprenantTypeBulletinDeliberationSession1SearchRequest {
    BulletinDetailSearch?: BulletinDetailSearch;
}

export interface BulletinApprenantTypeBulletinDeliberationSession2SearchRequest {
    BulletinDetailSearch?: BulletinDetailSearch;
}

export interface BulletinApprenantTypeGetBulletinTypeRequest {
    idApprenant?: number;
    anneeScolaire?: string;
    idTypeCours?: string;
}

export interface BulletinApprenantTypeGetBulletinTypeByIdRequest {
    id?: number;
}

export interface BulletinApprenantTypeGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface BulletinApprenantTypeGetSelectItemsRequest {
    searchField: string;
}

export interface BulletinApprenantTypeRefreshDeliberationAsyncRequest {
    idApprenant?: number;
    anneeScolaire?: string;
}

export interface BulletinApprenantTypeRefreshPresenceDeliberationRequest {
    idApprenant?: number;
    anneeScolaire?: string;
}

export interface BulletinApprenantTypeSaveRequest {
    FcbBulletinApprenantTypeSaveDto?: FcbBulletinApprenantTypeSaveDto;
}

export interface BulletinApprenantTypeSaveBulletinApprenantTypeRequest {
    FcbBulletinApprenantTypeDto?: FcbBulletinApprenantTypeDto;
}

export interface BulletinApprenantTypeSaveBulletinDate2SessRequest {
    date2Sess?: Date;
    idBulletinInscriptionMatiere?: number;
}

export interface BulletinApprenantTypeSaveBulletinDeliberationTypeRequest {
    idDeliberationType?: number;
    idBulletinInscriptionMatiere?: number;
    secondeSess?: boolean;
}

export interface BulletinApprenantTypeSaveBulletinRemediationConseilleeRequest {
    conseille?: boolean;
    idBulletinInscriptionMatiere?: number;
}

export interface BulletinApprenantTypeSaveBulletinRemediationObligatoireRequest {
    obligatoire?: boolean;
    idBulletinInscriptionMatiere?: number;
}

export interface BulletinApprenantTypeSaveBulletinTotalDateRequest {
    date?: Date;
    idbulletinApprenant?: number;
}

export interface BulletinApprenantTypeSaveBulletinTotalGradeRequest {
    idgrade?: number;
    idbulletinApprenant?: number;
}

export interface BulletinApprenantTypeSetBulletinApprenantTypeVisibleRequest {
    FcbBulletinApprenantTypeDetailDto?: FcbBulletinApprenantTypeDetailDto;
}

/**
 * 
 */
export class BulletinApprenantTypeApi extends runtime.BaseAPI {

    /**
     */
    async bulletinApprenantTypeBaseSearchRaw(requestParameters: BulletinApprenantTypeBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbBulletinApprenantRecapitulatifGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantType/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BulletinApprenantRecapitulatifSearchToJSON(requestParameters.BulletinApprenantRecapitulatifSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbBulletinApprenantRecapitulatifGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async bulletinApprenantTypeBaseSearch(requestParameters: BulletinApprenantTypeBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbBulletinApprenantRecapitulatifGridDtoPaginatedResults> {
        const response = await this.bulletinApprenantTypeBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantTypeBulletinDeliberationSession1SearchRaw(requestParameters: BulletinApprenantTypeBulletinDeliberationSession1SearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbResultatTypeGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantType/Deliberation1Search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BulletinDetailSearchToJSON(requestParameters.BulletinDetailSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbResultatTypeGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async bulletinApprenantTypeBulletinDeliberationSession1Search(requestParameters: BulletinApprenantTypeBulletinDeliberationSession1SearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbResultatTypeGridDtoPaginatedResults> {
        const response = await this.bulletinApprenantTypeBulletinDeliberationSession1SearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantTypeBulletinDeliberationSession2SearchRaw(requestParameters: BulletinApprenantTypeBulletinDeliberationSession2SearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbResultatTypeBulletinSession2GridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantType/Deliberation2Search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BulletinDetailSearchToJSON(requestParameters.BulletinDetailSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbResultatTypeBulletinSession2GridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async bulletinApprenantTypeBulletinDeliberationSession2Search(requestParameters: BulletinApprenantTypeBulletinDeliberationSession2SearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbResultatTypeBulletinSession2GridDtoPaginatedResults> {
        const response = await this.bulletinApprenantTypeBulletinDeliberationSession2SearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantTypeGetBulletinTypeRaw(requestParameters: BulletinApprenantTypeGetBulletinTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FcbBulletinApprenantTypeDetailDto>>> {
        const queryParameters: any = {};

        if (requestParameters.idApprenant !== undefined) {
            queryParameters['idApprenant'] = requestParameters.idApprenant;
        }

        if (requestParameters.anneeScolaire !== undefined) {
            queryParameters['anneeScolaire'] = requestParameters.anneeScolaire;
        }

        if (requestParameters.idTypeCours !== undefined) {
            queryParameters['idTypeCours'] = requestParameters.idTypeCours;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantType`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FcbBulletinApprenantTypeDetailDtoFromJSON));
    }

    /**
     */
    async bulletinApprenantTypeGetBulletinType(requestParameters: BulletinApprenantTypeGetBulletinTypeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FcbBulletinApprenantTypeDetailDto>> {
        const response = await this.bulletinApprenantTypeGetBulletinTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantTypeGetBulletinTypeByIdRaw(requestParameters: BulletinApprenantTypeGetBulletinTypeByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbBulletinApprenantTypeDetailDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantType/GetById`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbBulletinApprenantTypeDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async bulletinApprenantTypeGetBulletinTypeById(requestParameters: BulletinApprenantTypeGetBulletinTypeByIdRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbBulletinApprenantTypeDetailDto> {
        const response = await this.bulletinApprenantTypeGetBulletinTypeByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantTypeGetSearchCriteriasRaw(requestParameters: BulletinApprenantTypeGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantType/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async bulletinApprenantTypeGetSearchCriterias(requestParameters: BulletinApprenantTypeGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.bulletinApprenantTypeGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantTypeGetSelectItemsRaw(requestParameters: BulletinApprenantTypeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling bulletinApprenantTypeGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantType/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async bulletinApprenantTypeGetSelectItems(requestParameters: BulletinApprenantTypeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.bulletinApprenantTypeGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantTypeRefreshDeliberationAsyncRaw(requestParameters: BulletinApprenantTypeRefreshDeliberationAsyncRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbBulletinApprenantTypeDetailDto>> {
        const queryParameters: any = {};

        if (requestParameters.idApprenant !== undefined) {
            queryParameters['idApprenant'] = requestParameters.idApprenant;
        }

        if (requestParameters.anneeScolaire !== undefined) {
            queryParameters['anneeScolaire'] = requestParameters.anneeScolaire;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantType/RefreshDeliberation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbBulletinApprenantTypeDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async bulletinApprenantTypeRefreshDeliberationAsync(requestParameters: BulletinApprenantTypeRefreshDeliberationAsyncRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbBulletinApprenantTypeDetailDto> {
        const response = await this.bulletinApprenantTypeRefreshDeliberationAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantTypeRefreshPresenceDeliberationRaw(requestParameters: BulletinApprenantTypeRefreshPresenceDeliberationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbBulletinApprenantTypeDetailDto>> {
        const queryParameters: any = {};

        if (requestParameters.idApprenant !== undefined) {
            queryParameters['idApprenant'] = requestParameters.idApprenant;
        }

        if (requestParameters.anneeScolaire !== undefined) {
            queryParameters['anneeScolaire'] = requestParameters.anneeScolaire;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantType/RefreshPresenceDeliberation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbBulletinApprenantTypeDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async bulletinApprenantTypeRefreshPresenceDeliberation(requestParameters: BulletinApprenantTypeRefreshPresenceDeliberationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbBulletinApprenantTypeDetailDto> {
        const response = await this.bulletinApprenantTypeRefreshPresenceDeliberationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantTypeSaveRaw(requestParameters: BulletinApprenantTypeSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbBulletinApprenantTypeDetailDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbBulletinApprenantTypeSaveDtoToJSON(requestParameters.FcbBulletinApprenantTypeSaveDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbBulletinApprenantTypeDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async bulletinApprenantTypeSave(requestParameters: BulletinApprenantTypeSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbBulletinApprenantTypeDetailDto> {
        const response = await this.bulletinApprenantTypeSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantTypeSaveBulletinApprenantTypeRaw(requestParameters: BulletinApprenantTypeSaveBulletinApprenantTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbBulletinApprenantTypeDetailDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantType/SaveBulletinApprenantType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbBulletinApprenantTypeDtoToJSON(requestParameters.FcbBulletinApprenantTypeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbBulletinApprenantTypeDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async bulletinApprenantTypeSaveBulletinApprenantType(requestParameters: BulletinApprenantTypeSaveBulletinApprenantTypeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbBulletinApprenantTypeDetailDto> {
        const response = await this.bulletinApprenantTypeSaveBulletinApprenantTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantTypeSaveBulletinDate2SessRaw(requestParameters: BulletinApprenantTypeSaveBulletinDate2SessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.date2Sess !== undefined) {
            queryParameters['date2Sess'] = (requestParameters.date2Sess as any).toISOString();
        }

        if (requestParameters.idBulletinInscriptionMatiere !== undefined) {
            queryParameters['idBulletinInscriptionMatiere'] = requestParameters.idBulletinInscriptionMatiere;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantType/SaveBulletinDate2Sess`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async bulletinApprenantTypeSaveBulletinDate2Sess(requestParameters: BulletinApprenantTypeSaveBulletinDate2SessRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.bulletinApprenantTypeSaveBulletinDate2SessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantTypeSaveBulletinDeliberationTypeRaw(requestParameters: BulletinApprenantTypeSaveBulletinDeliberationTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.idDeliberationType !== undefined) {
            queryParameters['idDeliberationType'] = requestParameters.idDeliberationType;
        }

        if (requestParameters.idBulletinInscriptionMatiere !== undefined) {
            queryParameters['idBulletinInscriptionMatiere'] = requestParameters.idBulletinInscriptionMatiere;
        }

        if (requestParameters.secondeSess !== undefined) {
            queryParameters['secondeSess'] = requestParameters.secondeSess;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantType/SaveBulletinDeliberationType`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async bulletinApprenantTypeSaveBulletinDeliberationType(requestParameters: BulletinApprenantTypeSaveBulletinDeliberationTypeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.bulletinApprenantTypeSaveBulletinDeliberationTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantTypeSaveBulletinRemediationConseilleeRaw(requestParameters: BulletinApprenantTypeSaveBulletinRemediationConseilleeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.conseille !== undefined) {
            queryParameters['conseille'] = requestParameters.conseille;
        }

        if (requestParameters.idBulletinInscriptionMatiere !== undefined) {
            queryParameters['idBulletinInscriptionMatiere'] = requestParameters.idBulletinInscriptionMatiere;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantType/SaveBulletinRemediationConseillee`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async bulletinApprenantTypeSaveBulletinRemediationConseillee(requestParameters: BulletinApprenantTypeSaveBulletinRemediationConseilleeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.bulletinApprenantTypeSaveBulletinRemediationConseilleeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantTypeSaveBulletinRemediationObligatoireRaw(requestParameters: BulletinApprenantTypeSaveBulletinRemediationObligatoireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.obligatoire !== undefined) {
            queryParameters['obligatoire'] = requestParameters.obligatoire;
        }

        if (requestParameters.idBulletinInscriptionMatiere !== undefined) {
            queryParameters['idBulletinInscriptionMatiere'] = requestParameters.idBulletinInscriptionMatiere;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantType/SaveBulletinRemediationObligatoire`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async bulletinApprenantTypeSaveBulletinRemediationObligatoire(requestParameters: BulletinApprenantTypeSaveBulletinRemediationObligatoireRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.bulletinApprenantTypeSaveBulletinRemediationObligatoireRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantTypeSaveBulletinTotalDateRaw(requestParameters: BulletinApprenantTypeSaveBulletinTotalDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = (requestParameters.date as any).toISOString();
        }

        if (requestParameters.idbulletinApprenant !== undefined) {
            queryParameters['idbulletinApprenant'] = requestParameters.idbulletinApprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantType/SaveBulletinTotalDate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async bulletinApprenantTypeSaveBulletinTotalDate(requestParameters: BulletinApprenantTypeSaveBulletinTotalDateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.bulletinApprenantTypeSaveBulletinTotalDateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantTypeSaveBulletinTotalGradeRaw(requestParameters: BulletinApprenantTypeSaveBulletinTotalGradeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.idgrade !== undefined) {
            queryParameters['idgrade'] = requestParameters.idgrade;
        }

        if (requestParameters.idbulletinApprenant !== undefined) {
            queryParameters['idbulletinApprenant'] = requestParameters.idbulletinApprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantType/SaveBulletinTotalGrade`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async bulletinApprenantTypeSaveBulletinTotalGrade(requestParameters: BulletinApprenantTypeSaveBulletinTotalGradeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.bulletinApprenantTypeSaveBulletinTotalGradeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantTypeSetBulletinApprenantTypeVisibleRaw(requestParameters: BulletinApprenantTypeSetBulletinApprenantTypeVisibleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantType/SetBulletinApprenantTypeVisible`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbBulletinApprenantTypeDetailDtoToJSON(requestParameters.FcbBulletinApprenantTypeDetailDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async bulletinApprenantTypeSetBulletinApprenantTypeVisible(requestParameters: BulletinApprenantTypeSetBulletinApprenantTypeVisibleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.bulletinApprenantTypeSetBulletinApprenantTypeVisibleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
