/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContratConventionStageDto
 */
export interface ContratConventionStageDto {
    /**
     * 
     * @type {number}
     * @memberof ContratConventionStageDto
     */
    idcontratConventionStage?: number;
    /**
     * 
     * @type {number}
     * @memberof ContratConventionStageDto
     */
    idcontrat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratConventionStageDto
     */
    iddispenseChomage?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratConventionStageDto
     */
    dateValidation?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratConventionStageDto
     */
    dateRemise?: Date | null;
}

/**
 * Check if a given object implements the ContratConventionStageDto interface.
 */
export function instanceOfContratConventionStageDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContratConventionStageDtoFromJSON(json: any): ContratConventionStageDto {
    return ContratConventionStageDtoFromJSONTyped(json, false);
}

export function ContratConventionStageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContratConventionStageDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontratConventionStage': !exists(json, 'idcontratConventionStage') ? undefined : json['idcontratConventionStage'],
        'idcontrat': !exists(json, 'idcontrat') ? undefined : json['idcontrat'],
        'iddispenseChomage': !exists(json, 'iddispenseChomage') ? undefined : json['iddispenseChomage'],
        'dateValidation': !exists(json, 'dateValidation') ? undefined : (json['dateValidation'] === null ? null : new Date(json['dateValidation'])),
        'dateRemise': !exists(json, 'dateRemise') ? undefined : (json['dateRemise'] === null ? null : new Date(json['dateRemise'])),
    };
}

export function ContratConventionStageDtoToJSON(value?: ContratConventionStageDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontratConventionStage': value.idcontratConventionStage,
        'idcontrat': value.idcontrat,
        'iddispenseChomage': value.iddispenseChomage,
        'dateValidation': value.dateValidation === undefined ? undefined : (value.dateValidation === null ? null : value.dateValidation.toISOString()),
        'dateRemise': value.dateRemise === undefined ? undefined : (value.dateRemise === null ? null : value.dateRemise.toISOString()),
    };
}

