import * as React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { useAbortableApiServiceFactory, useApiService } from "../../../../../hooks/useApiService";
import { ETLCodes } from "../../../../../locales";
import {
  ProfessionFormateurApi,
  FcbProfessionFormateurDto,
  ProfessionFormateurSearch,
  EBooleanSearchTypes
} from "../../../../../api";

interface IProfessionFormateurListPageProps {}

const Container = styled.div`
  display: flex;
`;

export const ProfessionFormateurListPage: React.FunctionComponent<IProfessionFormateurListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ProfessionFormateurApi);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbProfessionFormateurDto) => (
          <Container>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.professionFormateur}/${row.idprofession}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.professionFormateur}/${row.idprofession}/detail/edit`)}
            />
          </Container>
        )
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "libelle"
      }
    ],
    [history, t]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.professionFormateur}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(
    () => api.professionFormateurGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  const apiFactory = useAbortableApiServiceFactory(ProfessionFormateurApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: ProfessionFormateurSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.professionFormateurBaseSearch({ ProfessionFormateurSearch: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      columns={columns}
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      addFunc={addItemFunction}
      breadCrumbs={[{ text: t(ETLCodes.ProfessionsFormateurs) }]}
      sortKeys={{ libelle: "ASC" }}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
    />
  );
};
