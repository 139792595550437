/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRechercheApprenantDeliberationTypeDto
 */
export interface FcbRechercheApprenantDeliberationTypeDto {
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    idapprenant?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    idtypeCours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    deliberation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    deliberation2sess?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    idcentre?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    idbulletinApprenant?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    idbulletinApprenantType?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    metier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    degre?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    idclasse?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    idstade?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    validationDirection1sess?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    validationDirection2sess?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    canPrintBulletin?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    idinscription?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    idfichier1s?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    idfichier2s?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbRechercheApprenantDeliberationTypeDto
     */
    has2Sess?: boolean | null;
}

/**
 * Check if a given object implements the FcbRechercheApprenantDeliberationTypeDto interface.
 */
export function instanceOfFcbRechercheApprenantDeliberationTypeDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRechercheApprenantDeliberationTypeDtoFromJSON(json: any): FcbRechercheApprenantDeliberationTypeDto {
    return FcbRechercheApprenantDeliberationTypeDtoFromJSONTyped(json, false);
}

export function FcbRechercheApprenantDeliberationTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRechercheApprenantDeliberationTypeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'idtypeCours': !exists(json, 'idtypeCours') ? undefined : json['idtypeCours'],
        'deliberation': !exists(json, 'deliberation') ? undefined : json['deliberation'],
        'deliberation2sess': !exists(json, 'deliberation2sess') ? undefined : json['deliberation2sess'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'idbulletinApprenant': !exists(json, 'idbulletinApprenant') ? undefined : json['idbulletinApprenant'],
        'idbulletinApprenantType': !exists(json, 'idbulletinApprenantType') ? undefined : json['idbulletinApprenantType'],
        'metier': !exists(json, 'metier') ? undefined : json['metier'],
        'degre': !exists(json, 'degre') ? undefined : json['degre'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'idstade': !exists(json, 'idstade') ? undefined : json['idstade'],
        'validationDirection1sess': !exists(json, 'validationDirection1sess') ? undefined : (json['validationDirection1sess'] === null ? null : new Date(json['validationDirection1sess'])),
        'validationDirection2sess': !exists(json, 'validationDirection2sess') ? undefined : (json['validationDirection2sess'] === null ? null : new Date(json['validationDirection2sess'])),
        'canPrintBulletin': !exists(json, 'canPrintBulletin') ? undefined : json['canPrintBulletin'],
        'idinscription': !exists(json, 'idinscription') ? undefined : json['idinscription'],
        'idfichier1s': !exists(json, 'idfichier1s') ? undefined : json['idfichier1s'],
        'idfichier2s': !exists(json, 'idfichier2s') ? undefined : json['idfichier2s'],
        'has2Sess': !exists(json, 'has2Sess') ? undefined : json['has2Sess'],
    };
}

export function FcbRechercheApprenantDeliberationTypeDtoToJSON(value?: FcbRechercheApprenantDeliberationTypeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nom': value.nom,
        'prenom': value.prenom,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'idapprenant': value.idapprenant,
        'anneeScolaire': value.anneeScolaire,
        'idtypeCours': value.idtypeCours,
        'deliberation': value.deliberation,
        'deliberation2sess': value.deliberation2sess,
        'idcentre': value.idcentre,
        'idbulletinApprenant': value.idbulletinApprenant,
        'idbulletinApprenantType': value.idbulletinApprenantType,
        'metier': value.metier,
        'degre': value.degre,
        'idclasse': value.idclasse,
        'idstade': value.idstade,
        'validationDirection1sess': value.validationDirection1sess === undefined ? undefined : (value.validationDirection1sess === null ? null : value.validationDirection1sess.toISOString()),
        'validationDirection2sess': value.validationDirection2sess === undefined ? undefined : (value.validationDirection2sess === null ? null : value.validationDirection2sess.toISOString()),
        'canPrintBulletin': value.canPrintBulletin,
        'idinscription': value.idinscription,
        'idfichier1s': value.idfichier1s,
        'idfichier2s': value.idfichier2s,
        'has2Sess': value.has2Sess,
    };
}

