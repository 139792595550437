import { Checkbox, Intent } from "@blueprintjs/core";
import { DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import {
  FcbMetierAgrementGridDto,
  FormateurMetierAgrementApi,
  FormateursMetiersAgrementSearch
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IFormateurAgrementMetiersListProps {}

export const FormateurAgrementMetiersList: React.FunctionComponent<IFormateurAgrementMetiersListProps> = () => {
  const { t } = useTl();
  const api = useApiService(FormateurMetierAgrementApi);
  const { id, idAgrement } = useParams<{ id: string; idAgrement: string }>();
  const history = useHistory();

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { code: "ASC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: FormateursMetiersAgrementSearch) => {
      sObj.idformateurAgrement = +idAgrement;
      return api.formateurMetierAgrementBaseSearch({ FormateursMetiersAgrementSearch: sObj });
    },
    [api, idAgrement]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "libelle"
      },
      {
        header: () => t(ETLCodes.Stade),
        fieldName: "stade"
      },
      {
        header: () => t(ETLCodes.Accord),
        fieldName: "accord",
        autoFitContent: true,
        alignment: "center",
        render: (row: FcbMetierAgrementGridDto) => {
          return row.accord != null ? <Checkbox checked={row.accord} /> : "-";
        }
      }
    ],
    [t]
  );

  return (
    <>
      <FieldSet
        title={t(ETLCodes.TableResults, { count: totalCount })}
        rightElement={
          <EditButton
            onClick={() => history.push(`${ERoutes.formateur}/${id}/agrement/${idAgrement}/metiers/selector`)}
            intent={Intent.PRIMARY}
            text={t(ETLCodes.Modify)}
          />
        }
      >
        <DataTable
          dateFormat="dd/MM/yyyy"
          tableState={tableState}
          loading={loading}
          columns={columns}
          filterMode="OnEnter"
        ></DataTable>
      </FieldSet>
    </>
  );
};
