/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EnveloppeTypeFinancementSearch,
  FcbEnveloppeTypeFinancementDto,
  FcbEnveloppeTypeFinancementGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    EnveloppeTypeFinancementSearchFromJSON,
    EnveloppeTypeFinancementSearchToJSON,
    FcbEnveloppeTypeFinancementDtoFromJSON,
    FcbEnveloppeTypeFinancementDtoToJSON,
    FcbEnveloppeTypeFinancementGridDtoPaginatedResultsFromJSON,
    FcbEnveloppeTypeFinancementGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface EnveloppeTypeFinancementBaseSearchRequest {
    EnveloppeTypeFinancementSearch?: EnveloppeTypeFinancementSearch;
}

export interface EnveloppeTypeFinancementDeleteRequest {
    id?: number;
}

export interface EnveloppeTypeFinancementGetRequest {
    id?: number;
}

export interface EnveloppeTypeFinancementGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface EnveloppeTypeFinancementGetSelectItemsRequest {
    searchField: string;
}

export interface EnveloppeTypeFinancementSaveRequest {
    FcbEnveloppeTypeFinancementDto?: FcbEnveloppeTypeFinancementDto;
}

/**
 * 
 */
export class EnveloppeTypeFinancementApi extends runtime.BaseAPI {

    /**
     */
    async enveloppeTypeFinancementBaseSearchRaw(requestParameters: EnveloppeTypeFinancementBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbEnveloppeTypeFinancementGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/EnveloppeTypeFinancement/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EnveloppeTypeFinancementSearchToJSON(requestParameters.EnveloppeTypeFinancementSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbEnveloppeTypeFinancementGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async enveloppeTypeFinancementBaseSearch(requestParameters: EnveloppeTypeFinancementBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbEnveloppeTypeFinancementGridDtoPaginatedResults> {
        const response = await this.enveloppeTypeFinancementBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async enveloppeTypeFinancementDeleteRaw(requestParameters: EnveloppeTypeFinancementDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/EnveloppeTypeFinancement`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async enveloppeTypeFinancementDelete(requestParameters: EnveloppeTypeFinancementDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.enveloppeTypeFinancementDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async enveloppeTypeFinancementGetRaw(requestParameters: EnveloppeTypeFinancementGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbEnveloppeTypeFinancementDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/EnveloppeTypeFinancement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbEnveloppeTypeFinancementDtoFromJSON(jsonValue));
    }

    /**
     */
    async enveloppeTypeFinancementGet(requestParameters: EnveloppeTypeFinancementGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbEnveloppeTypeFinancementDto> {
        const response = await this.enveloppeTypeFinancementGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async enveloppeTypeFinancementGetSearchCriteriasRaw(requestParameters: EnveloppeTypeFinancementGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/EnveloppeTypeFinancement/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async enveloppeTypeFinancementGetSearchCriterias(requestParameters: EnveloppeTypeFinancementGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.enveloppeTypeFinancementGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async enveloppeTypeFinancementGetSelectItemsRaw(requestParameters: EnveloppeTypeFinancementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling enveloppeTypeFinancementGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/EnveloppeTypeFinancement/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async enveloppeTypeFinancementGetSelectItems(requestParameters: EnveloppeTypeFinancementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.enveloppeTypeFinancementGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async enveloppeTypeFinancementSaveRaw(requestParameters: EnveloppeTypeFinancementSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbEnveloppeTypeFinancementDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/EnveloppeTypeFinancement`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbEnveloppeTypeFinancementDtoToJSON(requestParameters.FcbEnveloppeTypeFinancementDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbEnveloppeTypeFinancementDtoFromJSON(jsonValue));
    }

    /**
     */
    async enveloppeTypeFinancementSave(requestParameters: EnveloppeTypeFinancementSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbEnveloppeTypeFinancementDto> {
        const response = await this.enveloppeTypeFinancementSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
