/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRemplacementGridDto
 */
export interface FcbRemplacementGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbRemplacementGridDto
     */
    idremplacement?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbRemplacementGridDto
     */
    idcentre?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbRemplacementGridDto
     */
    centre?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRemplacementGridDto
     */
    idremplace?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbRemplacementGridDto
     */
    idtypeRemplacement?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbRemplacementGridDto
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRemplacementGridDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRemplacementGridDto
     */
    dateFin?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRemplacementGridDto
     */
    raison?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRemplacementGridDto
     */
    remarque?: string | null;
}

/**
 * Check if a given object implements the FcbRemplacementGridDto interface.
 */
export function instanceOfFcbRemplacementGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRemplacementGridDtoFromJSON(json: any): FcbRemplacementGridDto {
    return FcbRemplacementGridDtoFromJSONTyped(json, false);
}

export function FcbRemplacementGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRemplacementGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idremplacement': !exists(json, 'idremplacement') ? undefined : json['idremplacement'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'centre': !exists(json, 'centre') ? undefined : json['centre'],
        'idremplace': !exists(json, 'idremplace') ? undefined : json['idremplace'],
        'idtypeRemplacement': !exists(json, 'idtypeRemplacement') ? undefined : json['idtypeRemplacement'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
        'raison': !exists(json, 'raison') ? undefined : json['raison'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
    };
}

export function FcbRemplacementGridDtoToJSON(value?: FcbRemplacementGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idremplacement': value.idremplacement,
        'idcentre': value.idcentre,
        'centre': value.centre,
        'idremplace': value.idremplace,
        'idtypeRemplacement': value.idtypeRemplacement,
        'description': value.description,
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
        'raison': value.raison,
        'remarque': value.remarque,
    };
}

