import * as React from "react";
import { ProspectSignaletiqueDoublons } from "./ProspectSignaletiqueDoublons";
import { matchPath, useLocation } from "react-router";
import { ApprenantDoublonSearchFromJSON } from "../../../../../api";
import { useTabMessage } from "../../../../../hooks";
import { ERoutes } from "../../../../../AppRouter";
import { ProspectSignaletique } from "./ProspectSignaletique";

export interface IProspectSignaletiqueSwitchProps {}

export const ProspectSignaletiqueSwitch: React.FunctionComponent<IProspectSignaletiqueSwitchProps> = props => {
  const [sObj, setSObj] = React.useState(ApprenantDoublonSearchFromJSON({}));
  const { pathname, search } = useLocation();
  const cfs = React.useMemo(() => new URLSearchParams(search).get("cfs"), [search]);
  const { messageValue, clearMessageValue } = useTabMessage(cfs);

  React.useEffect(() => {
    if (!!messageValue) {
      setSObj(messageValue);
      clearMessageValue();
    }
  }, [clearMessageValue, messageValue]);

  if (
    matchPath(pathname, {
      path: `${ERoutes.prospect}/0/:tab/checkDoublons`
    })
  ) {
    return <ProspectSignaletiqueDoublons setBaseData={setSObj} />;
  } else {
    return <ProspectSignaletique baseData={sObj} />;
  }
};
