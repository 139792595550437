/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaremeTheoriqueDto } from './BaremeTheoriqueDto';
import {
    BaremeTheoriqueDtoFromJSON,
    BaremeTheoriqueDtoFromJSONTyped,
    BaremeTheoriqueDtoToJSON,
} from './BaremeTheoriqueDto';
import type { ContratAlternanceCommunDto } from './ContratAlternanceCommunDto';
import {
    ContratAlternanceCommunDtoFromJSON,
    ContratAlternanceCommunDtoFromJSONTyped,
    ContratAlternanceCommunDtoToJSON,
} from './ContratAlternanceCommunDto';
import type { ContratAlternanceDto } from './ContratAlternanceDto';
import {
    ContratAlternanceDtoFromJSON,
    ContratAlternanceDtoFromJSONTyped,
    ContratAlternanceDtoToJSON,
} from './ContratAlternanceDto';
import type { ContratConventionStageDto } from './ContratConventionStageDto';
import {
    ContratConventionStageDtoFromJSON,
    ContratConventionStageDtoFromJSONTyped,
    ContratConventionStageDtoToJSON,
} from './ContratConventionStageDto';
import type { ContratConventionStageExterneDto } from './ContratConventionStageExterneDto';
import {
    ContratConventionStageExterneDtoFromJSON,
    ContratConventionStageExterneDtoFromJSONTyped,
    ContratConventionStageExterneDtoToJSON,
} from './ContratConventionStageExterneDto';
import type { ContratFaltDto } from './ContratFaltDto';
import {
    ContratFaltDtoFromJSON,
    ContratFaltDtoFromJSONTyped,
    ContratFaltDtoToJSON,
} from './ContratFaltDto';
import type { ContratLieuFormationGridDto } from './ContratLieuFormationGridDto';
import {
    ContratLieuFormationGridDtoFromJSON,
    ContratLieuFormationGridDtoFromJSONTyped,
    ContratLieuFormationGridDtoToJSON,
} from './ContratLieuFormationGridDto';
import type { ContratStageObservationDto } from './ContratStageObservationDto';
import {
    ContratStageObservationDtoFromJSON,
    ContratStageObservationDtoFromJSONTyped,
    ContratStageObservationDtoToJSON,
} from './ContratStageObservationDto';
import type { DecouverteMetierDto } from './DecouverteMetierDto';
import {
    DecouverteMetierDtoFromJSON,
    DecouverteMetierDtoFromJSONTyped,
    DecouverteMetierDtoToJSON,
} from './DecouverteMetierDto';
import type { EStatutContrat } from './EStatutContrat';
import {
    EStatutContratFromJSON,
    EStatutContratFromJSONTyped,
    EStatutContratToJSON,
} from './EStatutContrat';
import type { ETypeContrat } from './ETypeContrat';
import {
    ETypeContratFromJSON,
    ETypeContratFromJSONTyped,
    ETypeContratToJSON,
} from './ETypeContrat';
import type { FileUploadDto } from './FileUploadDto';
import {
    FileUploadDtoFromJSON,
    FileUploadDtoFromJSONTyped,
    FileUploadDtoToJSON,
} from './FileUploadDto';
import type { PratiqueProfessionnelleDto } from './PratiqueProfessionnelleDto';
import {
    PratiqueProfessionnelleDtoFromJSON,
    PratiqueProfessionnelleDtoFromJSONTyped,
    PratiqueProfessionnelleDtoToJSON,
} from './PratiqueProfessionnelleDto';

/**
 * 
 * @export
 * @interface ContratEditDto
 */
export interface ContratEditDto {
    /**
     * 
     * @type {number}
     * @memberof ContratEditDto
     */
    idcontrat?: number;
    /**
     * 
     * @type {number}
     * @memberof ContratEditDto
     */
    idserviceTutelle?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratEditDto
     */
    idclasse?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContratEditDto
     */
    service?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContratEditDto
     */
    iddureeContrat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratEditDto
     */
    idmetier?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratEditDto
     */
    idcommissionParitaire?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratEditDto
     */
    idheureHebdoPossibleCommissionParitaire?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratEditDto
     */
    customHeuresHebdo?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ContratEditDto
     */
    idsclause?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof ContratEditDto
     */
    metier?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContratEditDto
     */
    idstade?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContratEditDto
     */
    stade?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratEditDto
     */
    codeStade?: string | null;
    /**
     * 
     * @type {ETypeContrat}
     * @memberof ContratEditDto
     */
    type?: ETypeContrat;
    /**
     * 
     * @type {EStatutContrat}
     * @memberof ContratEditDto
     */
    statut?: EStatutContrat;
    /**
     * 
     * @type {number}
     * @memberof ContratEditDto
     */
    idapprenant?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratEditDto
     */
    idpri?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratEditDto
     */
    idari?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContratEditDto
     */
    apprenant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratEditDto
     */
    apprenantStatut?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratEditDto
     */
    marqueSansSuiteLe?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratEditDto
     */
    rompuLe?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ContratEditDto
     */
    motifRupturePatron?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratEditDto
     */
    motifRuptureJeune?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContratEditDto
     */
    idfichierRupture?: number | null;
    /**
     * 
     * @type {FileUploadDto}
     * @memberof ContratEditDto
     */
    fichierRupture?: FileUploadDto;
    /**
     * 
     * @type {Date}
     * @memberof ContratEditDto
     */
    datePriseActe?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratEditDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratEditDto
     */
    dateFin?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratEditDto
     */
    debutDerniereSuspension?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratEditDto
     */
    finDerniereSuspension?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratEditDto
     */
    dateSignature?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ContratEditDto
     */
    idlieuSignature?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratEditDto
     */
    idcontratInitiateurRupture?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratEditDto
     */
    idcontratMotifRupturePatron?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratEditDto
     */
    idcontratMotifRuptureJeune?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratEditDto
     */
    idcalcRiActant?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContratEditDto
     */
    remarqueRupture?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContratEditDto
     */
    idariane?: number | null;
    /**
     * 
     * @type {Array<ContratLieuFormationGridDto>}
     * @memberof ContratEditDto
     */
    lieuxFormation?: Array<ContratLieuFormationGridDto> | null;
    /**
     * 
     * @type {Array<BaremeTheoriqueDto>}
     * @memberof ContratEditDto
     */
    baremes?: Array<BaremeTheoriqueDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContratEditDto
     */
    rompreAgrementsActifs?: boolean | null;
    /**
     * 
     * @type {ContratAlternanceDto}
     * @memberof ContratEditDto
     */
    contratAlternanceDto?: ContratAlternanceDto;
    /**
     * 
     * @type {PratiqueProfessionnelleDto}
     * @memberof ContratEditDto
     */
    pratiqueProfessionnelleDto?: PratiqueProfessionnelleDto;
    /**
     * 
     * @type {ContratConventionStageDto}
     * @memberof ContratEditDto
     */
    contratConventionStageDto?: ContratConventionStageDto;
    /**
     * 
     * @type {ContratFaltDto}
     * @memberof ContratEditDto
     */
    contratFaltDto?: ContratFaltDto;
    /**
     * 
     * @type {DecouverteMetierDto}
     * @memberof ContratEditDto
     */
    decouverteMetierDto?: DecouverteMetierDto;
    /**
     * 
     * @type {ContratStageObservationDto}
     * @memberof ContratEditDto
     */
    contratStageObservationDto?: ContratStageObservationDto;
    /**
     * 
     * @type {ContratAlternanceCommunDto}
     * @memberof ContratEditDto
     */
    contratAlternanceCommunDto?: ContratAlternanceCommunDto;
    /**
     * 
     * @type {ContratConventionStageExterneDto}
     * @memberof ContratEditDto
     */
    contratConventionStageExterneDto?: ContratConventionStageExterneDto;
    /**
     * 
     * @type {string}
     * @memberof ContratEditDto
     */
    code?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContratEditDto
     */
    capaciteFormativeAgrement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratEditDto
     */
    nbContratsLf?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContratEditDto
     */
    readonly capaciteFormativeAgrementAtteinte?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContratEditDto
     */
    mustDisableOffre?: boolean;
}

/**
 * Check if a given object implements the ContratEditDto interface.
 */
export function instanceOfContratEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContratEditDtoFromJSON(json: any): ContratEditDto {
    return ContratEditDtoFromJSONTyped(json, false);
}

export function ContratEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContratEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontrat': !exists(json, 'idcontrat') ? undefined : json['idcontrat'],
        'idserviceTutelle': !exists(json, 'idserviceTutelle') ? undefined : json['idserviceTutelle'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'iddureeContrat': !exists(json, 'iddureeContrat') ? undefined : json['iddureeContrat'],
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'idcommissionParitaire': !exists(json, 'idcommissionParitaire') ? undefined : json['idcommissionParitaire'],
        'idheureHebdoPossibleCommissionParitaire': !exists(json, 'idheureHebdoPossibleCommissionParitaire') ? undefined : json['idheureHebdoPossibleCommissionParitaire'],
        'customHeuresHebdo': !exists(json, 'customHeuresHebdo') ? undefined : json['customHeuresHebdo'],
        'idsclause': !exists(json, 'idsclause') ? undefined : json['idsclause'],
        'metier': !exists(json, 'metier') ? undefined : json['metier'],
        'idstade': !exists(json, 'idstade') ? undefined : json['idstade'],
        'stade': !exists(json, 'stade') ? undefined : json['stade'],
        'codeStade': !exists(json, 'codeStade') ? undefined : json['codeStade'],
        'type': !exists(json, 'type') ? undefined : ETypeContratFromJSON(json['type']),
        'statut': !exists(json, 'statut') ? undefined : EStatutContratFromJSON(json['statut']),
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idpri': !exists(json, 'idpri') ? undefined : json['idpri'],
        'idari': !exists(json, 'idari') ? undefined : json['idari'],
        'apprenant': !exists(json, 'apprenant') ? undefined : json['apprenant'],
        'apprenantStatut': !exists(json, 'apprenantStatut') ? undefined : json['apprenantStatut'],
        'marqueSansSuiteLe': !exists(json, 'marqueSansSuiteLe') ? undefined : (json['marqueSansSuiteLe'] === null ? null : new Date(json['marqueSansSuiteLe'])),
        'rompuLe': !exists(json, 'rompuLe') ? undefined : (json['rompuLe'] === null ? null : new Date(json['rompuLe'])),
        'motifRupturePatron': !exists(json, 'motifRupturePatron') ? undefined : json['motifRupturePatron'],
        'motifRuptureJeune': !exists(json, 'motifRuptureJeune') ? undefined : json['motifRuptureJeune'],
        'idfichierRupture': !exists(json, 'idfichierRupture') ? undefined : json['idfichierRupture'],
        'fichierRupture': !exists(json, 'fichierRupture') ? undefined : FileUploadDtoFromJSON(json['fichierRupture']),
        'datePriseActe': !exists(json, 'datePriseActe') ? undefined : (json['datePriseActe'] === null ? null : new Date(json['datePriseActe'])),
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
        'debutDerniereSuspension': !exists(json, 'debutDerniereSuspension') ? undefined : (json['debutDerniereSuspension'] === null ? null : new Date(json['debutDerniereSuspension'])),
        'finDerniereSuspension': !exists(json, 'finDerniereSuspension') ? undefined : (json['finDerniereSuspension'] === null ? null : new Date(json['finDerniereSuspension'])),
        'dateSignature': !exists(json, 'dateSignature') ? undefined : (json['dateSignature'] === null ? null : new Date(json['dateSignature'])),
        'idlieuSignature': !exists(json, 'idlieuSignature') ? undefined : json['idlieuSignature'],
        'idcontratInitiateurRupture': !exists(json, 'idcontratInitiateurRupture') ? undefined : json['idcontratInitiateurRupture'],
        'idcontratMotifRupturePatron': !exists(json, 'idcontratMotifRupturePatron') ? undefined : json['idcontratMotifRupturePatron'],
        'idcontratMotifRuptureJeune': !exists(json, 'idcontratMotifRuptureJeune') ? undefined : json['idcontratMotifRuptureJeune'],
        'idcalcRiActant': !exists(json, 'idcalcRiActant') ? undefined : json['idcalcRiActant'],
        'remarqueRupture': !exists(json, 'remarqueRupture') ? undefined : json['remarqueRupture'],
        'idariane': !exists(json, 'idariane') ? undefined : json['idariane'],
        'lieuxFormation': !exists(json, 'lieuxFormation') ? undefined : (json['lieuxFormation'] === null ? null : (json['lieuxFormation'] as Array<any>).map(ContratLieuFormationGridDtoFromJSON)),
        'baremes': !exists(json, 'baremes') ? undefined : (json['baremes'] === null ? null : (json['baremes'] as Array<any>).map(BaremeTheoriqueDtoFromJSON)),
        'rompreAgrementsActifs': !exists(json, 'rompreAgrementsActifs') ? undefined : json['rompreAgrementsActifs'],
        'contratAlternanceDto': !exists(json, 'contratAlternanceDto') ? undefined : ContratAlternanceDtoFromJSON(json['contratAlternanceDto']),
        'pratiqueProfessionnelleDto': !exists(json, 'pratiqueProfessionnelleDto') ? undefined : PratiqueProfessionnelleDtoFromJSON(json['pratiqueProfessionnelleDto']),
        'contratConventionStageDto': !exists(json, 'contratConventionStageDto') ? undefined : ContratConventionStageDtoFromJSON(json['contratConventionStageDto']),
        'contratFaltDto': !exists(json, 'contratFaltDto') ? undefined : ContratFaltDtoFromJSON(json['contratFaltDto']),
        'decouverteMetierDto': !exists(json, 'decouverteMetierDto') ? undefined : DecouverteMetierDtoFromJSON(json['decouverteMetierDto']),
        'contratStageObservationDto': !exists(json, 'contratStageObservationDto') ? undefined : ContratStageObservationDtoFromJSON(json['contratStageObservationDto']),
        'contratAlternanceCommunDto': !exists(json, 'contratAlternanceCommunDto') ? undefined : ContratAlternanceCommunDtoFromJSON(json['contratAlternanceCommunDto']),
        'contratConventionStageExterneDto': !exists(json, 'contratConventionStageExterneDto') ? undefined : ContratConventionStageExterneDtoFromJSON(json['contratConventionStageExterneDto']),
        'code': !exists(json, 'code') ? undefined : json['code'],
        'capaciteFormativeAgrement': !exists(json, 'capaciteFormativeAgrement') ? undefined : json['capaciteFormativeAgrement'],
        'nbContratsLf': !exists(json, 'nbContratsLf') ? undefined : json['nbContratsLf'],
        'capaciteFormativeAgrementAtteinte': !exists(json, 'capaciteFormativeAgrementAtteinte') ? undefined : json['capaciteFormativeAgrementAtteinte'],
        'mustDisableOffre': !exists(json, 'mustDisableOffre') ? undefined : json['mustDisableOffre'],
    };
}

export function ContratEditDtoToJSON(value?: ContratEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontrat': value.idcontrat,
        'idserviceTutelle': value.idserviceTutelle,
        'idclasse': value.idclasse,
        'service': value.service,
        'iddureeContrat': value.iddureeContrat,
        'idmetier': value.idmetier,
        'idcommissionParitaire': value.idcommissionParitaire,
        'idheureHebdoPossibleCommissionParitaire': value.idheureHebdoPossibleCommissionParitaire,
        'customHeuresHebdo': value.customHeuresHebdo,
        'idsclause': value.idsclause,
        'metier': value.metier,
        'idstade': value.idstade,
        'stade': value.stade,
        'codeStade': value.codeStade,
        'type': ETypeContratToJSON(value.type),
        'statut': EStatutContratToJSON(value.statut),
        'idapprenant': value.idapprenant,
        'idpri': value.idpri,
        'idari': value.idari,
        'apprenant': value.apprenant,
        'apprenantStatut': value.apprenantStatut,
        'marqueSansSuiteLe': value.marqueSansSuiteLe === undefined ? undefined : (value.marqueSansSuiteLe === null ? null : value.marqueSansSuiteLe.toISOString()),
        'rompuLe': value.rompuLe === undefined ? undefined : (value.rompuLe === null ? null : value.rompuLe.toISOString()),
        'motifRupturePatron': value.motifRupturePatron,
        'motifRuptureJeune': value.motifRuptureJeune,
        'idfichierRupture': value.idfichierRupture,
        'fichierRupture': FileUploadDtoToJSON(value.fichierRupture),
        'datePriseActe': value.datePriseActe === undefined ? undefined : (value.datePriseActe === null ? null : value.datePriseActe.toISOString()),
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
        'debutDerniereSuspension': value.debutDerniereSuspension === undefined ? undefined : (value.debutDerniereSuspension === null ? null : value.debutDerniereSuspension.toISOString()),
        'finDerniereSuspension': value.finDerniereSuspension === undefined ? undefined : (value.finDerniereSuspension === null ? null : value.finDerniereSuspension.toISOString()),
        'dateSignature': value.dateSignature === undefined ? undefined : (value.dateSignature === null ? null : value.dateSignature.toISOString()),
        'idlieuSignature': value.idlieuSignature,
        'idcontratInitiateurRupture': value.idcontratInitiateurRupture,
        'idcontratMotifRupturePatron': value.idcontratMotifRupturePatron,
        'idcontratMotifRuptureJeune': value.idcontratMotifRuptureJeune,
        'idcalcRiActant': value.idcalcRiActant,
        'remarqueRupture': value.remarqueRupture,
        'idariane': value.idariane,
        'lieuxFormation': value.lieuxFormation === undefined ? undefined : (value.lieuxFormation === null ? null : (value.lieuxFormation as Array<any>).map(ContratLieuFormationGridDtoToJSON)),
        'baremes': value.baremes === undefined ? undefined : (value.baremes === null ? null : (value.baremes as Array<any>).map(BaremeTheoriqueDtoToJSON)),
        'rompreAgrementsActifs': value.rompreAgrementsActifs,
        'contratAlternanceDto': ContratAlternanceDtoToJSON(value.contratAlternanceDto),
        'pratiqueProfessionnelleDto': PratiqueProfessionnelleDtoToJSON(value.pratiqueProfessionnelleDto),
        'contratConventionStageDto': ContratConventionStageDtoToJSON(value.contratConventionStageDto),
        'contratFaltDto': ContratFaltDtoToJSON(value.contratFaltDto),
        'decouverteMetierDto': DecouverteMetierDtoToJSON(value.decouverteMetierDto),
        'contratStageObservationDto': ContratStageObservationDtoToJSON(value.contratStageObservationDto),
        'contratAlternanceCommunDto': ContratAlternanceCommunDtoToJSON(value.contratAlternanceCommunDto),
        'contratConventionStageExterneDto': ContratConventionStageExterneDtoToJSON(value.contratConventionStageExterneDto),
        'code': value.code,
        'capaciteFormativeAgrement': value.capaciteFormativeAgrement,
        'nbContratsLf': value.nbContratsLf,
        'mustDisableOffre': value.mustDisableOffre,
    };
}

