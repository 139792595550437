import { Intent } from "@blueprintjs/core";
import { DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { UsersServicesLiesApi, UsersServicesLiesSearchDto, UtilisateurApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { useQuery } from "react-query";

export interface IUtilisateurServicesLiesPageProps {}

export const UtilisateurServicesLiesPage: React.FunctionComponent<IUtilisateurServicesLiesPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(UsersServicesLiesApi);
  const { id, state } = useParams<{ id: string; tab: string; state: string }>();

  const uApi = useApiService(UtilisateurApi);

  const fetchIsUserCalc = React.useCallback(async () => {
    if (+id <= 0) return false;
    return (await uApi.utilisateurIsCalc({ iduser: +id }))?.value;
  }, [id, uApi]);

  const { data: isUserCalc, isLoading: calcLoading } = useQuery(["user-is-calc", id], fetchIsUserCalc, {
    enabled: true
  });

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { nom: "ASC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: UsersServicesLiesSearchDto) => {
      sObj.idUser = +id;
      return api.usersServicesLiesBaseSearch({ UsersServicesLiesSearchDto: sObj });
    },
    [api, id]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      }
    ],
    [t]
  );

  return (
    <FieldSet
      title={t(ETLCodes.TableResults, { count: totalCount })}
      rightElement={
        !isUserCalc &&
        !calcLoading && (
          <EditButton
            onClick={() => history.push(`${ERoutes.users}/${id}/services/${state}/selector`)}
            intent={Intent.PRIMARY}
            text={t(ETLCodes.Modify)}
          />
        )
      }
    >
      <DataTable
        dateFormat="dd-MM-yyyy"
        tableState={tableState}
        loading={loading}
        columns={columns}
        filterMode="OnEnter"
      />
    </FieldSet>
  );
};
