import { Classes, Switch } from '@blueprintjs/core';
import { getIn } from 'formik';
import { FGCustomInput, FGTextInput, useFGContext } from 'nsitools-react';
import * as React from "react";
import styled from 'styled-components';
import { FGWalterSelectInput } from '../../../../../components';
import { useTheme, useTl } from '../../../../../hooks';
import { useReferential } from '../../../../../hooks/useReferential';
import { ETLCodes } from '../../../../../locales';

const HeuresHebdoContainer = styled.div<{ color: string }>`
  display: flex;
  & .id-heures {
    margin-right: 10px !important;
  }
  
  & .${Classes.SWITCH}{
    &:hover {
      input:checked ~ .${Classes.CONTROL_INDICATOR} {
      background: ${props => props.color};
      }
    }
  }
`;

export interface IHeuresHebdomadairesSelectorProps {
  softRO: boolean;
}

export const HeuresHebdomadairesSelector: React.FunctionComponent<IHeuresHebdomadairesSelectorProps> = ({ softRO }) => {
  const { t } = useTl();
  const { theme } = useTheme();
  const { formik } = useFGContext();

  const idcommissionParitaire = React.useMemo(() => formik?.values?.idcommissionParitaire ?? 0, [
    formik?.values?.idcommissionParitaire
  ]);

  const [heuresHebdo, hhLoading] = useReferential(
    a => a.referentialGetHeuresHebdomadaires({ idcommissionParitaire }),
    false,
    idcommissionParitaire
  );

  const isCustomHeures = React.useMemo(() => getIn(formik.values, "customHeuresHebdo") !== null && getIn(formik.values, "customHeuresHebdo") !== undefined, [formik?.values]);

  return (
    <FGCustomInput label={t(ETLCodes.HeuresHebdomadaires)}>{ctx => (
      <HeuresHebdoContainer color={theme.primaryColor}>
        <FGWalterSelectInput
          className="id-heures"
          name="idheureHebdoPossibleCommissionParitaire"
          noLabel
          items={heuresHebdo}
          loading={hhLoading}
          disabled={idcommissionParitaire <= 0 || isCustomHeures}
          readonly={softRO}
        />
        <Switch
          checked={isCustomHeures}
          onChange={(e) => {
            formik.setFieldValue("customHeuresHebdo", e.currentTarget.checked ? 0 : null);
            formik.setFieldValue("idheureHebdoPossibleCommissionParitaire", null);
          }}
          readOnly={softRO}
          disabled={idcommissionParitaire <= 0}
        />
        <FGTextInput
          type="number"
          name="customHeuresHebdo"
          noLabel
          disabled={!isCustomHeures}
          readonly={softRO}
        />
      </HeuresHebdoContainer>
    )}</FGCustomInput>);
};