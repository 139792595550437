import { Card, Colors, NonIdealState } from "@blueprintjs/core";
import { FieldSet } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { useDoublonsStateContext } from ".";
import { useTheme, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledCard = styled(Card)<{ bgColor: string; txtColor: string }>`
  margin: 0 0.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 0.5rem;
  background-color: ${props => props.bgColor};
  color: ${props => props.txtColor};
  transition: all 90ms linear;
`;

const LabelContainer = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  font-weight: bold;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export interface IDoublonsMultipleDataCardProps<T> {
  title?: string;
  fieldPath: string;
  interactive?: (item: T) => boolean;
  subFieldProps?: {
    key: string;
    defaultValue: any;
    valueToSet: any;
  };
  finalEntityKey: string;
  children: (item: T) => JSX.Element;
  labelPredicate?: (item: T) => string;
  forceSelected?: (item: T) => boolean;
}

export function DoublonsMultipleDataCard<T>({
  title,
  fieldPath,
  interactive = () => true,
  forceSelected,
  finalEntityKey,
  children,
  labelPredicate = () => "",
  subFieldProps
}: React.PropsWithChildren<IDoublonsMultipleDataCardProps<T>>) {
  const { t } = useTl();
  const { theme } = useTheme();
  const { isDataMultipleSelected, selectDataMultiple, getFieldData } = useDoublonsStateContext();

  const items = React.useMemo<T[]>(() => getFieldData(fieldPath), [fieldPath, getFieldData]);
  const finalPath = React.useMemo(() => fieldPath.replace(/^[^\]]*\]/gm, finalEntityKey), [fieldPath, finalEntityKey]);

  const onSelect = React.useCallback(
    (item: T) => {
      if (!finalPath) return;
      const newItem = !!subFieldProps ? { ...item, [subFieldProps.key]: subFieldProps.valueToSet } : item;
      selectDataMultiple(finalPath, newItem, subFieldProps?.key, subFieldProps?.defaultValue);
    },
    [finalPath, selectDataMultiple, subFieldProps]
  );

  return (
    <Container>
      {!!title && <FieldSet title={title} />}
      {items?.length === 0 ? (
        <NonIdealState title={t(ETLCodes.PasDeDonneesAAfficher)} />
      ) : (
        items.map((item, idx) => {
          const selected = !!forceSelected
            ? forceSelected(item)
            : isDataMultipleSelected(
                finalPath,
                !!subFieldProps ? { ...item, [subFieldProps.key]: subFieldProps.valueToSet } : item
              );
          return (
            <StyledCard
              interactive={interactive(item)}
              onClick={interactive(item) ? () => onSelect(item) : null}
              className={selected && "doublon-card-selected"}
              bgColor={selected ? `${theme.primaryColor}10` : Colors.WHITE}
              txtColor={selected ? `${theme.primaryColor}` : null}
              key={idx}
            >
              {labelPredicate && <LabelContainer>{labelPredicate(item)}</LabelContainer>}
              <ContentContainer>{children(item)}</ContentContainer>
            </StyledCard>
          );
        })
      )}
    </Container>
  );
}
