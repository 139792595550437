import { useRequest } from "ahooks";
import * as React from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router";

import { useAuth } from ".";
import { AnneeScolaireApi, Configuration, FcbAnneeScolaireDto, SystemApi } from "../api";
import { apiConfiguration } from "../apiConfig";
import { ISystemInfo, useSystemInfo } from "../hooks";

interface IGlobalDataContext extends ISystemInfo {
  currentAnneeScolaire?: FcbAnneeScolaireDto;
  loading: boolean;
  nbPlages: number;
  currentModuleId: number;
}

const GlobalDataContext = React.createContext<IGlobalDataContext>(null);

interface IGlobalDataProviderProps {}

export const GlobalDataProvider: React.FunctionComponent<IGlobalDataProviderProps> = ({ children }) => {
  const anneeScolaireApi = new AnneeScolaireApi(new Configuration(apiConfiguration()));

  const { getAccessToken, user } = useAuth();
  const systemApi = React.useMemo(() => new SystemApi(new Configuration(apiConfiguration(getAccessToken))), [
    getAccessToken
  ]);

  const { pathname } = useLocation();
  const moduleName = React.useMemo(() => pathname?.split("/")[1], [pathname]);

  const { data: currentAnneeScolaire, loading: loadingAnneeScolaire } = useRequest(() =>
    anneeScolaireApi.anneeScolaireGetCurrent()
  );

  const fetchModules = React.useCallback(() => {
    if (!!user?.iduser) {
      return systemApi.systemGetModules();
    } else {
      return [];
    }
  }, [systemApi, user?.iduser]);
  const { data: modules, isFetching: mloading } = useQuery(["app-modules", user?.iduser], fetchModules, {
    cacheTime: 0
  });

  const currentModuleId = React.useMemo(
    () =>
      !mloading && modules?.length > 0 && moduleName ? modules.find(m => m.modulename === moduleName)?.idmodule : null,
    [mloading, moduleName, modules]
  );

  const systemInfo = useSystemInfo();
  const globalDataLoading = React.useMemo(() => loadingAnneeScolaire || mloading, [loadingAnneeScolaire, mloading]);

  return (
    <GlobalDataContext.Provider
      value={{
        currentAnneeScolaire: currentAnneeScolaire,
        loading: globalDataLoading,
        nbPlages: 14,
        currentModuleId,
        ...systemInfo
      }}
    >
      {children}
    </GlobalDataContext.Provider>
  );
};

export const useGlobalData = () => React.useContext(GlobalDataContext);
