/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EAggregateType {
    Sum = 'Sum',
    Avg = 'Avg',
    Min = 'Min',
    Max = 'Max'
}


export function EAggregateTypeFromJSON(json: any): EAggregateType {
    return EAggregateTypeFromJSONTyped(json, false);
}

export function EAggregateTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EAggregateType {
    return json as EAggregateType;
}

export function EAggregateTypeToJSON(value?: EAggregateType | null): any {
    return value as any;
}

