/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DiplomeSearch,
  FcbDiplomeDto,
  FcbDiplomeGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    DiplomeSearchFromJSON,
    DiplomeSearchToJSON,
    FcbDiplomeDtoFromJSON,
    FcbDiplomeDtoToJSON,
    FcbDiplomeGridDtoPaginatedResultsFromJSON,
    FcbDiplomeGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface DiplomeBaseSearchRequest {
    DiplomeSearch?: DiplomeSearch;
}

export interface DiplomeDeleteRequest {
    id?: number;
}

export interface DiplomeGetRequest {
    id?: number;
}

export interface DiplomeGetDisplayNameRequest {
    id?: number;
}

export interface DiplomeGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface DiplomeGetSelectItemsRequest {
    searchField: string;
}

export interface DiplomeSaveRequest {
    FcbDiplomeDto?: FcbDiplomeDto;
}

/**
 * 
 */
export class DiplomeApi extends runtime.BaseAPI {

    /**
     */
    async diplomeBaseSearchRaw(requestParameters: DiplomeBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbDiplomeGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Diplome/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DiplomeSearchToJSON(requestParameters.DiplomeSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbDiplomeGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async diplomeBaseSearch(requestParameters: DiplomeBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbDiplomeGridDtoPaginatedResults> {
        const response = await this.diplomeBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async diplomeDeleteRaw(requestParameters: DiplomeDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Diplome`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async diplomeDelete(requestParameters: DiplomeDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.diplomeDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async diplomeGetRaw(requestParameters: DiplomeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbDiplomeDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Diplome`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbDiplomeDtoFromJSON(jsonValue));
    }

    /**
     */
    async diplomeGet(requestParameters: DiplomeGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbDiplomeDto> {
        const response = await this.diplomeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async diplomeGetDisplayNameRaw(requestParameters: DiplomeGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Diplome/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async diplomeGetDisplayName(requestParameters: DiplomeGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.diplomeGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async diplomeGetSearchCriteriasRaw(requestParameters: DiplomeGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Diplome/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async diplomeGetSearchCriterias(requestParameters: DiplomeGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.diplomeGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async diplomeGetSelectItemsRaw(requestParameters: DiplomeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling diplomeGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Diplome/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async diplomeGetSelectItems(requestParameters: DiplomeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.diplomeGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async diplomeSaveRaw(requestParameters: DiplomeSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbDiplomeDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Diplome`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbDiplomeDtoToJSON(requestParameters.FcbDiplomeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbDiplomeDtoFromJSON(jsonValue));
    }

    /**
     */
    async diplomeSave(requestParameters: DiplomeSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbDiplomeDto> {
        const response = await this.diplomeSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
