/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbApprenantDto,
  FcbRechercheApprenantViewDtoPaginatedResults,
  FilterCriteriaInfo,
  ProspectSearch,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    FcbApprenantDtoFromJSON,
    FcbApprenantDtoToJSON,
    FcbRechercheApprenantViewDtoPaginatedResultsFromJSON,
    FcbRechercheApprenantViewDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ProspectSearchFromJSON,
    ProspectSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface ProspectBaseSearchRequest {
    ProspectSearch?: ProspectSearch;
}

export interface ProspectCheckDoublonsNameRequest {
    FcbApprenantDto?: FcbApprenantDto;
}

export interface ProspectCheckDoublonsNationalNumberRequest {
    FcbApprenantDto?: FcbApprenantDto;
}

export interface ProspectDeleteRequest {
    id?: number;
}

export interface ProspectGetRequest {
    id?: number;
    picture?: boolean;
}

export interface ProspectGetDisplayNameRequest {
    id?: number;
}

export interface ProspectGetIdPersonneRequest {
    id?: number;
}

export interface ProspectGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ProspectGetSelectItemsRequest {
    searchField: string;
}

export interface ProspectSaveRequest {
    FcbApprenantDto?: FcbApprenantDto;
}

export interface ProspectSearchApprenantIdsRequest {
    ProspectSearch?: ProspectSearch;
}

/**
 * 
 */
export class ProspectApi extends runtime.BaseAPI {

    /**
     */
    async prospectBaseSearchRaw(requestParameters: ProspectBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRechercheApprenantViewDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Prospect/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProspectSearchToJSON(requestParameters.ProspectSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRechercheApprenantViewDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async prospectBaseSearch(requestParameters: ProspectBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRechercheApprenantViewDtoPaginatedResults> {
        const response = await this.prospectBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async prospectCheckDoublonsNameRaw(requestParameters: ProspectCheckDoublonsNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Prospect/CheckDoublonsName`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbApprenantDtoToJSON(requestParameters.FcbApprenantDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async prospectCheckDoublonsName(requestParameters: ProspectCheckDoublonsNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.prospectCheckDoublonsNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async prospectCheckDoublonsNationalNumberRaw(requestParameters: ProspectCheckDoublonsNationalNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Prospect/CheckDoublonsNationalNumber`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbApprenantDtoToJSON(requestParameters.FcbApprenantDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async prospectCheckDoublonsNationalNumber(requestParameters: ProspectCheckDoublonsNationalNumberRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.prospectCheckDoublonsNationalNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async prospectDeleteRaw(requestParameters: ProspectDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Prospect`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async prospectDelete(requestParameters: ProspectDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.prospectDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async prospectGetRaw(requestParameters: ProspectGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbApprenantDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.picture !== undefined) {
            queryParameters['picture'] = requestParameters.picture;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Prospect`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbApprenantDtoFromJSON(jsonValue));
    }

    /**
     */
    async prospectGet(requestParameters: ProspectGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbApprenantDto> {
        const response = await this.prospectGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async prospectGetDisplayNameRaw(requestParameters: ProspectGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Prospect/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async prospectGetDisplayName(requestParameters: ProspectGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.prospectGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async prospectGetIdPersonneRaw(requestParameters: ProspectGetIdPersonneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Prospect/GetIdPersonne`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async prospectGetIdPersonne(requestParameters: ProspectGetIdPersonneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.prospectGetIdPersonneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async prospectGetSearchCriteriasRaw(requestParameters: ProspectGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Prospect/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async prospectGetSearchCriterias(requestParameters: ProspectGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.prospectGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async prospectGetSelectItemsRaw(requestParameters: ProspectGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling prospectGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Prospect/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async prospectGetSelectItems(requestParameters: ProspectGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.prospectGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async prospectSaveRaw(requestParameters: ProspectSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbApprenantDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Prospect`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbApprenantDtoToJSON(requestParameters.FcbApprenantDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbApprenantDtoFromJSON(jsonValue));
    }

    /**
     */
    async prospectSave(requestParameters: ProspectSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbApprenantDto> {
        const response = await this.prospectSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async prospectSearchApprenantIdsRaw(requestParameters: ProspectSearchApprenantIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Prospect/SearchApprenantIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProspectSearchToJSON(requestParameters.ProspectSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async prospectSearchApprenantIds(requestParameters: ProspectSearchApprenantIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.prospectSearchApprenantIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
