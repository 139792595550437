/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ExamenEntreeSearch,
  FcbExamenEntreeDto,
  FcbExamenEntreeGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    ExamenEntreeSearchFromJSON,
    ExamenEntreeSearchToJSON,
    FcbExamenEntreeDtoFromJSON,
    FcbExamenEntreeDtoToJSON,
    FcbExamenEntreeGridDtoPaginatedResultsFromJSON,
    FcbExamenEntreeGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface ExamenEntreeBaseSearchRequest {
    ExamenEntreeSearch?: ExamenEntreeSearch;
}

export interface ExamenEntreeDeleteRequest {
    id?: number;
}

export interface ExamenEntreeGetRequest {
    id?: number;
}

export interface ExamenEntreeGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ExamenEntreeGetSelectItemsRequest {
    searchField: string;
}

export interface ExamenEntreeSaveRequest {
    FcbExamenEntreeDto?: FcbExamenEntreeDto;
}

/**
 * 
 */
export class ExamenEntreeApi extends runtime.BaseAPI {

    /**
     */
    async examenEntreeBaseSearchRaw(requestParameters: ExamenEntreeBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbExamenEntreeGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExamenEntree/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExamenEntreeSearchToJSON(requestParameters.ExamenEntreeSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbExamenEntreeGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async examenEntreeBaseSearch(requestParameters: ExamenEntreeBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbExamenEntreeGridDtoPaginatedResults> {
        const response = await this.examenEntreeBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async examenEntreeDeleteRaw(requestParameters: ExamenEntreeDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExamenEntree`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async examenEntreeDelete(requestParameters: ExamenEntreeDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.examenEntreeDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async examenEntreeGetRaw(requestParameters: ExamenEntreeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbExamenEntreeDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExamenEntree`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbExamenEntreeDtoFromJSON(jsonValue));
    }

    /**
     */
    async examenEntreeGet(requestParameters: ExamenEntreeGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbExamenEntreeDto> {
        const response = await this.examenEntreeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async examenEntreeGetSearchCriteriasRaw(requestParameters: ExamenEntreeGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExamenEntree/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async examenEntreeGetSearchCriterias(requestParameters: ExamenEntreeGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.examenEntreeGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async examenEntreeGetSelectItemsRaw(requestParameters: ExamenEntreeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling examenEntreeGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExamenEntree/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async examenEntreeGetSelectItems(requestParameters: ExamenEntreeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.examenEntreeGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async examenEntreeSaveRaw(requestParameters: ExamenEntreeSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbExamenEntreeDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExamenEntree`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbExamenEntreeDtoToJSON(requestParameters.FcbExamenEntreeDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbExamenEntreeDtoFromJSON(jsonValue));
    }

    /**
     */
    async examenEntreeSave(requestParameters: ExamenEntreeSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbExamenEntreeDto> {
        const response = await this.examenEntreeSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
