import { Classes, Dialog } from "@blueprintjs/core";
import { Button, ButtonGroup, Divider, Icon, Tooltip } from "@blueprintjs/core/lib/esm/components";
import { useWhatChanged } from "@simbathesailor/use-what-changed";
import { IDataTableColumn } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import {
  AgrementMasseValidationApi,
  FcbRechercheapprenantValidationcemasseDto,
  RechercheApprenantValidationCeMasseSearch
} from "../../../../api";
import { SearchTablePage } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

const DialogStyled = styled(Dialog)`
  width: 1500px;
  background: white;
`;

export interface IValidationMasseAddModalProps {
  isOpen: boolean;
  onClose: (apprenants?: number[]) => void;
  /**
   * Ids of the already registered apprenants to not fetch them
   */
  registeredIds: number[];
  /**
   * Elements that has been already selected
   */
  alreadySelected: number[];
}

export const ValidationMasseAddModal: React.FunctionComponent<IValidationMasseAddModalProps> = ({
  isOpen,
  onClose,
  registeredIds,
  alreadySelected
}) => {
  const { t } = useTl();
  const validationMasseApi = useApiService(AgrementMasseValidationApi);

  const [totalCount, setTotalCount] = React.useState(0);
  const [data, setData] = React.useState<number[]>();

  const searchFn = React.useCallback(
    async (nextSearch?: RechercheApprenantValidationCeMasseSearch) => {
      validationMasseApi
        .agrementMasseValidationSearchFunctionIds({
          RechercheApprenantValidationCeMasseSearch: {
            ...nextSearch,
            availableIds: registeredIds,
            forceSkip: 0,
            forceTake: 99999
          }
        })
        .then(res => {
          setData(res);
          setTotalCount(res.length);
        });

      return await validationMasseApi.agrementMasseValidationBaseSearch({
        RechercheApprenantValidationCeMasseSearch: {
          ...nextSearch,
          availableIds: registeredIds
        }
      });
    },
    [validationMasseApi, registeredIds]
  );

  const [selected, setSelected] = React.useState<number[]>(alreadySelected ?? []);
  const toggleSelection = React.useCallback(
    (idformation: number) => {
      if (!selected.includes(idformation)) {
        setSelected(apprenants => [...apprenants, idformation]);
      } else {
        setSelected(apprenants => apprenants.filter(i => !(i === idformation)));
      }
    },
    [selected]
  );

  const allChecked = React.useMemo(() => totalCount > 0 && selected.length === totalCount, [
    selected.length,
    totalCount
  ]);
  const toggleSelectAll = React.useCallback(() => {
    if (data.length === selected.length) {
      setSelected([]);
    } else {
      setSelected(data);
    }
  }, [data, selected.length]);

  const onRowClick = React.useCallback(
    (id: number) => {
      toggleSelection(id);
    },
    [toggleSelection]
  );

  useWhatChanged([selected], "selected");

  const columns = React.useMemo<IDataTableColumn[]>(() => {
    return [
      {
        fieldName: "_action",
        autoFitContent: true,
        computed: true,
        header: () => (
          <Tooltip content={allChecked ? t(ETLCodes.UnSelectAll) : t(ETLCodes.SelectAll)}>
            <Icon
              icon={allChecked ? "tick-circle" : "circle"}
              style={{ cursor: "pointer" }}
              onClick={toggleSelectAll}
            />
          </Tooltip>
        ),
        alignment: "center",
        render: (item: FcbRechercheapprenantValidationcemasseDto) => (
          <Icon
            icon={selected.includes(item.idformation) ? "tick-circle" : "circle"}
            style={{ cursor: "pointer" }}
          ></Icon>
        )
      },
      {
        fieldName: "nom",
        header: () => t(ETLCodes.Nom)
      },
      {
        fieldName: "prenom",
        header: () => t(ETLCodes.Prenom)
      },
      {
        fieldName: "dateNaissance",
        header: () => t(ETLCodes.DateNaissance)
      },
      {
        fieldName: "masculin",
        header: () => t(ETLCodes.Formation)
      },
      {
        fieldName: "degre",
        header: () => t(ETLCodes.Degre)
      },
      {
        fieldName: "codeclasse",
        header: () => t(ETLCodes.CodeClasse)
      },
      {
        fieldName: "classe",
        header: () => t(ETLCodes.NomClasse)
      },
      {
        fieldName: "agrement",
        header: () => t(ETLCodes.ValidationCE)
      }
    ];
  }, [allChecked, selected, t, toggleSelectAll]);

  const onSave = React.useCallback(async () => {
    onClose(selected);
    setSelected([]);
  }, [onClose, selected]);

  const close = React.useCallback(() => {
    onClose(undefined);
    setSelected(alreadySelected ?? []);
  }, [alreadySelected, onClose]);

  const getCriteria = React.useCallback(
    () => validationMasseApi.agrementMasseValidationGetSearchCriterias({ includeListsValues: true }),
    [validationMasseApi]
  );

  return (
    <DialogStyled
      isOpen={isOpen}
      isCloseButtonShown={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      title={t(ETLCodes.SelectionApprenants)}
      onClose={close}
      enforceFocus={false}
    >
      <div className={Classes.DIALOG_BODY}>
        <SearchTablePage
          columns={columns}
          getCriteriasFunction={getCriteria}
          searchFunction={searchFn}
          sortKeys={{ nom: "ASC" }}
          avoidSavingCriterias={true}
          withCard={false}
          onRowClick={item => onRowClick(item.idformation)}
          criteriasTlPrefix="ValidationMasse"
        />
      </div>
      <Divider />
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <ButtonGroup minimal large fill>
            <Button text={t(ETLCodes.Cancel)} onClick={close} icon="cross"></Button>
            <div style={{ flex: 1 }}></div>
            <Button
              text={t(ETLCodes.AddApprenants, { count: selected.length })}
              icon="floppy-disk"
              onClick={onSave}
              intent="primary"
              disabled={selected?.length === 0}
            ></Button>
          </ButtonGroup>
        </div>
      </div>
    </DialogStyled>
  );
};
