/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailDto } from './EmailDto';
import {
    EmailDtoFromJSON,
    EmailDtoFromJSONTyped,
    EmailDtoToJSON,
} from './EmailDto';
import type { FormateurDoublonAdministratifDto } from './FormateurDoublonAdministratifDto';
import {
    FormateurDoublonAdministratifDtoFromJSON,
    FormateurDoublonAdministratifDtoFromJSONTyped,
    FormateurDoublonAdministratifDtoToJSON,
} from './FormateurDoublonAdministratifDto';
import type { FormateurDoublonProfessionnelDto } from './FormateurDoublonProfessionnelDto';
import {
    FormateurDoublonProfessionnelDtoFromJSON,
    FormateurDoublonProfessionnelDtoFromJSONTyped,
    FormateurDoublonProfessionnelDtoToJSON,
} from './FormateurDoublonProfessionnelDto';
import type { TelephoneDto } from './TelephoneDto';
import {
    TelephoneDtoFromJSON,
    TelephoneDtoFromJSONTyped,
    TelephoneDtoToJSON,
} from './TelephoneDto';

/**
 * 
 * @export
 * @interface FormateurDoublonEditDto
 */
export interface FormateurDoublonEditDto {
    /**
     * 
     * @type {number}
     * @memberof FormateurDoublonEditDto
     */
    idformateur?: number;
    /**
     * 
     * @type {number}
     * @memberof FormateurDoublonEditDto
     */
    nbLocalisations?: number;
    /**
     * 
     * @type {number}
     * @memberof FormateurDoublonEditDto
     */
    nbAgrements?: number;
    /**
     * 
     * @type {number}
     * @memberof FormateurDoublonEditDto
     */
    nbSuivis?: number;
    /**
     * 
     * @type {number}
     * @memberof FormateurDoublonEditDto
     */
    nbRemplacements?: number;
    /**
     * 
     * @type {string}
     * @memberof FormateurDoublonEditDto
     */
    azureEmail?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FormateurDoublonEditDto
     */
    azureCreatedOn?: Date | null;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof FormateurDoublonEditDto
     */
    telephoneFormateur?: TelephoneDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof FormateurDoublonEditDto
     */
    gsmFormateur?: TelephoneDto;
    /**
     * 
     * @type {EmailDto}
     * @memberof FormateurDoublonEditDto
     */
    emailFormateur?: EmailDto;
    /**
     * 
     * @type {FormateurDoublonAdministratifDto}
     * @memberof FormateurDoublonEditDto
     */
    donneesAdministratives?: FormateurDoublonAdministratifDto;
    /**
     * 
     * @type {FormateurDoublonProfessionnelDto}
     * @memberof FormateurDoublonEditDto
     */
    donneesProfessionnelles?: FormateurDoublonProfessionnelDto;
}

/**
 * Check if a given object implements the FormateurDoublonEditDto interface.
 */
export function instanceOfFormateurDoublonEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FormateurDoublonEditDtoFromJSON(json: any): FormateurDoublonEditDto {
    return FormateurDoublonEditDtoFromJSONTyped(json, false);
}

export function FormateurDoublonEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormateurDoublonEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'nbLocalisations': !exists(json, 'nbLocalisations') ? undefined : json['nbLocalisations'],
        'nbAgrements': !exists(json, 'nbAgrements') ? undefined : json['nbAgrements'],
        'nbSuivis': !exists(json, 'nbSuivis') ? undefined : json['nbSuivis'],
        'nbRemplacements': !exists(json, 'nbRemplacements') ? undefined : json['nbRemplacements'],
        'azureEmail': !exists(json, 'azureEmail') ? undefined : json['azureEmail'],
        'azureCreatedOn': !exists(json, 'azureCreatedOn') ? undefined : (json['azureCreatedOn'] === null ? null : new Date(json['azureCreatedOn'])),
        'telephoneFormateur': !exists(json, 'telephoneFormateur') ? undefined : TelephoneDtoFromJSON(json['telephoneFormateur']),
        'gsmFormateur': !exists(json, 'gsmFormateur') ? undefined : TelephoneDtoFromJSON(json['gsmFormateur']),
        'emailFormateur': !exists(json, 'emailFormateur') ? undefined : EmailDtoFromJSON(json['emailFormateur']),
        'donneesAdministratives': !exists(json, 'donneesAdministratives') ? undefined : FormateurDoublonAdministratifDtoFromJSON(json['donneesAdministratives']),
        'donneesProfessionnelles': !exists(json, 'donneesProfessionnelles') ? undefined : FormateurDoublonProfessionnelDtoFromJSON(json['donneesProfessionnelles']),
    };
}

export function FormateurDoublonEditDtoToJSON(value?: FormateurDoublonEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idformateur': value.idformateur,
        'nbLocalisations': value.nbLocalisations,
        'nbAgrements': value.nbAgrements,
        'nbSuivis': value.nbSuivis,
        'nbRemplacements': value.nbRemplacements,
        'azureEmail': value.azureEmail,
        'azureCreatedOn': value.azureCreatedOn === undefined ? undefined : (value.azureCreatedOn === null ? null : value.azureCreatedOn.toISOString()),
        'telephoneFormateur': TelephoneDtoToJSON(value.telephoneFormateur),
        'gsmFormateur': TelephoneDtoToJSON(value.gsmFormateur),
        'emailFormateur': EmailDtoToJSON(value.emailFormateur),
        'donneesAdministratives': FormateurDoublonAdministratifDtoToJSON(value.donneesAdministratives),
        'donneesProfessionnelles': FormateurDoublonProfessionnelDtoToJSON(value.donneesProfessionnelles),
    };
}

