import { Card, Divider, Label } from "@blueprintjs/core";
import { FGEmpty, FGTextAreaInput, FGTextInput, FieldGroup, FieldSet } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { ApprenantBulletinDeliberation2SessGrid, ApprenantBulletinDeliberationGrid } from ".";
import { ETypeDeliberation, FcbBulletinApprenantTypeDetailDto } from "../../../../../../api";
import {
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../../../components";
import { useCrudApi, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

export interface IApprenantBulletinDetailProps {
  bulletin: FcbBulletinApprenantTypeDetailDto;
}
const StyledLabel = styled(Label)`
  font-weight: bold;
`;

export const ApprenantBulletinDetail: React.FunctionComponent<IApprenantBulletinDetailProps> = ({ bulletin }) => {
  const { t } = useTl();

  const { data, loading } = useCrudApi({
    getApiFn: () => bulletin,
    saveApiFn: d => {
      return bulletin;
    },
    deleteApiFn: d => {},
    onDeletedRoute: () => ``
  });

  const [d1Sess, d1Sessloading] = useReferential(
    apiDelib => apiDelib.referentialGetDeliberationTypes({ typeDeliberation: ETypeDeliberation.Bulletin1Session }),
    true
  );
  const [d2Sess, d2Sessloading] = useReferential(
    apiDelib => apiDelib.referentialGetDeliberationTypes({ typeDeliberation: ETypeDeliberation.Bulletin2Session }),
    true
  );
  return (
    data && (
      <>
        <StyledLabel>
          {t(ETLCodes.TotalCourseType)} : {data.totalTypeCours}
        </StyledLabel>
        <Card>
          <FieldSet title={t(ETLCodes.Sess1)}>
            <SmallFormGenerator
              initialValues={data}
              onSubmit={() => {}}
              editMode={false}
              hideButtons={true}
              loading={loading}
              formatDate="dd-MM-yyyy"
              minLabelWidth={200}
            >
              <FieldGroup columns={2} title={t(ETLCodes.BulletinSess1)}>
                <FGWalterDateMaskInput
                  label={t(ETLCodes.DateAbandon)}
                  name="dateAbandon"
                  readonly
                  showPlaceholder={false}
                />
                <FGWalterDateMaskInput
                  label={t(ETLCodes.Rupture)}
                  name="dateRupture"
                  readonly
                  showPlaceholder={false}
                />
                <FGTextInput label={t(ETLCodes.HeuresPresences)} name="totalHeuresPresence" readonly />
                <FGTextInput label={t(ETLCodes.TotalPoints)} name="totalPointsSess1" readonly />
                <FGTextInput label={t(ETLCodes.HeuresAbsences)} name="totalHeuresAbsences" readonly />
                <FGTextInput label={t(ETLCodes.TotalDispense)} name="totalPointsDispenseSess1" readonly />
                <FGTextInput label={t(ETLCodes.HeuresJustifiees)} name="totalHeuresAbsencesJustifiees" readonly />
                <FGTextInput
                  label={t(ETLCodes.TotalApresDeliberation)}
                  name="totalPointsApresDeliberationSess1"
                  readonly
                />
                <FGWalterDateMaskInput label={t(ETLCodes.DateDeliberation)} name="dateDeliberation" />
                <FGEmpty />
                <FGWalterDateMaskInput
                  label={t(ETLCodes.DateEncodage)}
                  name="modificationDate"
                  readonly
                  showPlaceholder={false}
                />
                <FGTextInput label={t(ETLCodes.EncodagePar)} name="userEncodage" readonly />
                <FGEmpty />
                <FGWalterSelectInput
                  name="iddeliberationType"
                  items={d1Sess}
                  loading={d1Sessloading}
                  label={t(ETLCodes.DeliberationActee)}
                />
                <FGWalterCheckboxInput
                  name="validationDirection1sessBool"
                  label={t(ETLCodes.ValidationDirection)}
                  readonly
                />
                <FGWalterDateMaskInput
                  name="validationDirection1sess"
                  label={t(ETLCodes.ValidationDirectionDate)}
                  readonly
                />
              </FieldGroup>
              <FieldGroup>
                <FGTextAreaInput
                  label={t(ETLCodes.CommentaireDeliberation)}
                  name="remarqueDelibe"
                  growVertically={true}
                />
              </FieldGroup>
            </SmallFormGenerator>
            <Divider />
            <ApprenantBulletinDeliberationGrid
              idbulletinApprenantType={data.idbulletinApprenantType}
              execSearch={true}
            />
          </FieldSet>
        </Card>
        <br />
        {data.view2Sess && (
          <Card>
            <FieldSet title={t(ETLCodes.Sess2)}>
              <SmallFormGenerator
                initialValues={data}
                onSubmit={() => {}}
                editMode={false}
                hideButtons={true}
                loading={loading}
                formatDate="dd-MM-yyyy"
                minLabelWidth={200}
              >
                <FieldGroup columns={2} title={t(ETLCodes.BulletinSess2)}>
                  <FGWalterDateMaskInput
                    label={t(ETLCodes.DateAbandon)}
                    name="dateAbandon"
                    readonly
                    showPlaceholder={false}
                  />
                  <FGWalterDateMaskInput
                    label={t(ETLCodes.Rupture)}
                    name="dateRupture"
                    readonly
                    showPlaceholder={false}
                  />
                  <FGTextInput label={t(ETLCodes.HeuresPresences)} name="totalHeuresPresence" readonly />
                  <FGTextInput label={t(ETLCodes.TotalPoints)} name="totalPointsSess2" readonly />
                  <FGTextInput label={t(ETLCodes.HeuresAbsences)} name="totalHeuresAbsences" readonly />
                  <FGTextInput label={t(ETLCodes.TotalDispense)} name="totalPointsDispenseSess2" readonly />
                  <FGTextInput label={t(ETLCodes.HeuresJustifiees)} name="totalHeuresAbsencesJustifiees" readonly />
                  <FGTextInput
                    label={t(ETLCodes.TotalApresDeliberation)}
                    name="totalPointsApresDeliberationSess2"
                    readonly
                  />
                  <FGWalterDateMaskInput
                    label={t(ETLCodes.DateDeliberation)}
                    name="dateDeliberation2sess"
                    showPlaceholder={false}
                  />
                  <FGEmpty />
                  <FGWalterDateMaskInput label={t(ETLCodes.DateEncodage)} name="modificationDate" readonly />
                  <FGTextInput label={t(ETLCodes.EncodagePar)} name="userEncodage" readonly />
                  <FGEmpty />
                  <FGWalterSelectInput
                    items={d2Sess}
                    loading={d2Sessloading}
                    label={t(ETLCodes.DeliberationActee)}
                    name="iddeliberationType2sess"
                  />
                  <FGWalterCheckboxInput
                    name="validationDirection2sessBool"
                    label={t(ETLCodes.ValidationDirection)}
                    readonly
                  />
                  <FGWalterDateMaskInput
                    name="validationDirection2sess"
                    label={t(ETLCodes.ValidationDirectionDate)}
                    readonly
                  />
                </FieldGroup>
                <FieldGroup>
                  <FGTextAreaInput
                    label={t(ETLCodes.CommentaireDeliberation)}
                    name="remarqueDelibe2sess"
                    growVertically={true}
                  />
                </FieldGroup>
              </SmallFormGenerator>
              <Divider />
              <ApprenantBulletinDeliberation2SessGrid
                idbulletinApprenantType={data.idbulletinApprenantType}
                execSearch={true}
              />
            </FieldSet>
          </Card>
        )}
      </>
    )
  );
};
