/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApprenantDoublonEditDto } from './ApprenantDoublonEditDto';
import {
    ApprenantDoublonEditDtoFromJSON,
    ApprenantDoublonEditDtoFromJSONTyped,
    ApprenantDoublonEditDtoToJSON,
} from './ApprenantDoublonEditDto';
import type { ChefEntrepriseDoublonEditDto } from './ChefEntrepriseDoublonEditDto';
import {
    ChefEntrepriseDoublonEditDtoFromJSON,
    ChefEntrepriseDoublonEditDtoFromJSONTyped,
    ChefEntrepriseDoublonEditDtoToJSON,
} from './ChefEntrepriseDoublonEditDto';
import type { ContactDoublonEditDto } from './ContactDoublonEditDto';
import {
    ContactDoublonEditDtoFromJSON,
    ContactDoublonEditDtoFromJSONTyped,
    ContactDoublonEditDtoToJSON,
} from './ContactDoublonEditDto';
import type { FormateurDoublonEditDto } from './FormateurDoublonEditDto';
import {
    FormateurDoublonEditDtoFromJSON,
    FormateurDoublonEditDtoFromJSONTyped,
    FormateurDoublonEditDtoToJSON,
} from './FormateurDoublonEditDto';
import type { PersonneDoublonEditDto } from './PersonneDoublonEditDto';
import {
    PersonneDoublonEditDtoFromJSON,
    PersonneDoublonEditDtoFromJSONTyped,
    PersonneDoublonEditDtoToJSON,
} from './PersonneDoublonEditDto';
import type { RepresentantDoublonEditDto } from './RepresentantDoublonEditDto';
import {
    RepresentantDoublonEditDtoFromJSON,
    RepresentantDoublonEditDtoFromJSONTyped,
    RepresentantDoublonEditDtoToJSON,
} from './RepresentantDoublonEditDto';
import type { TuteurDoublonEditDto } from './TuteurDoublonEditDto';
import {
    TuteurDoublonEditDtoFromJSON,
    TuteurDoublonEditDtoFromJSONTyped,
    TuteurDoublonEditDtoToJSON,
} from './TuteurDoublonEditDto';

/**
 * 
 * @export
 * @interface DoublonEditDto
 */
export interface DoublonEditDto {
    /**
     * 
     * @type {Array<PersonneDoublonEditDto>}
     * @memberof DoublonEditDto
     */
    personnes?: Array<PersonneDoublonEditDto> | null;
    /**
     * 
     * @type {PersonneDoublonEditDto}
     * @memberof DoublonEditDto
     */
    personneFinal?: PersonneDoublonEditDto;
    /**
     * 
     * @type {Array<ApprenantDoublonEditDto>}
     * @memberof DoublonEditDto
     */
    apprenants?: Array<ApprenantDoublonEditDto> | null;
    /**
     * 
     * @type {ApprenantDoublonEditDto}
     * @memberof DoublonEditDto
     */
    apprenantFinal?: ApprenantDoublonEditDto;
    /**
     * 
     * @type {Array<ChefEntrepriseDoublonEditDto>}
     * @memberof DoublonEditDto
     */
    chefEntreprises?: Array<ChefEntrepriseDoublonEditDto> | null;
    /**
     * 
     * @type {ChefEntrepriseDoublonEditDto}
     * @memberof DoublonEditDto
     */
    chefEntrepriseFinal?: ChefEntrepriseDoublonEditDto;
    /**
     * 
     * @type {Array<ContactDoublonEditDto>}
     * @memberof DoublonEditDto
     */
    contacts?: Array<ContactDoublonEditDto> | null;
    /**
     * 
     * @type {ContactDoublonEditDto}
     * @memberof DoublonEditDto
     */
    contactFinal?: ContactDoublonEditDto;
    /**
     * 
     * @type {Array<FormateurDoublonEditDto>}
     * @memberof DoublonEditDto
     */
    formateurs?: Array<FormateurDoublonEditDto> | null;
    /**
     * 
     * @type {FormateurDoublonEditDto}
     * @memberof DoublonEditDto
     */
    formateurFinal?: FormateurDoublonEditDto;
    /**
     * 
     * @type {Array<RepresentantDoublonEditDto>}
     * @memberof DoublonEditDto
     */
    representants?: Array<RepresentantDoublonEditDto> | null;
    /**
     * 
     * @type {RepresentantDoublonEditDto}
     * @memberof DoublonEditDto
     */
    representantFinal?: RepresentantDoublonEditDto;
    /**
     * 
     * @type {Array<TuteurDoublonEditDto>}
     * @memberof DoublonEditDto
     */
    tuteurs?: Array<TuteurDoublonEditDto> | null;
    /**
     * 
     * @type {TuteurDoublonEditDto}
     * @memberof DoublonEditDto
     */
    tuteurFinal?: TuteurDoublonEditDto;
}

/**
 * Check if a given object implements the DoublonEditDto interface.
 */
export function instanceOfDoublonEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DoublonEditDtoFromJSON(json: any): DoublonEditDto {
    return DoublonEditDtoFromJSONTyped(json, false);
}

export function DoublonEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DoublonEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personnes': !exists(json, 'personnes') ? undefined : (json['personnes'] === null ? null : (json['personnes'] as Array<any>).map(PersonneDoublonEditDtoFromJSON)),
        'personneFinal': !exists(json, 'personneFinal') ? undefined : PersonneDoublonEditDtoFromJSON(json['personneFinal']),
        'apprenants': !exists(json, 'apprenants') ? undefined : (json['apprenants'] === null ? null : (json['apprenants'] as Array<any>).map(ApprenantDoublonEditDtoFromJSON)),
        'apprenantFinal': !exists(json, 'apprenantFinal') ? undefined : ApprenantDoublonEditDtoFromJSON(json['apprenantFinal']),
        'chefEntreprises': !exists(json, 'chefEntreprises') ? undefined : (json['chefEntreprises'] === null ? null : (json['chefEntreprises'] as Array<any>).map(ChefEntrepriseDoublonEditDtoFromJSON)),
        'chefEntrepriseFinal': !exists(json, 'chefEntrepriseFinal') ? undefined : ChefEntrepriseDoublonEditDtoFromJSON(json['chefEntrepriseFinal']),
        'contacts': !exists(json, 'contacts') ? undefined : (json['contacts'] === null ? null : (json['contacts'] as Array<any>).map(ContactDoublonEditDtoFromJSON)),
        'contactFinal': !exists(json, 'contactFinal') ? undefined : ContactDoublonEditDtoFromJSON(json['contactFinal']),
        'formateurs': !exists(json, 'formateurs') ? undefined : (json['formateurs'] === null ? null : (json['formateurs'] as Array<any>).map(FormateurDoublonEditDtoFromJSON)),
        'formateurFinal': !exists(json, 'formateurFinal') ? undefined : FormateurDoublonEditDtoFromJSON(json['formateurFinal']),
        'representants': !exists(json, 'representants') ? undefined : (json['representants'] === null ? null : (json['representants'] as Array<any>).map(RepresentantDoublonEditDtoFromJSON)),
        'representantFinal': !exists(json, 'representantFinal') ? undefined : RepresentantDoublonEditDtoFromJSON(json['representantFinal']),
        'tuteurs': !exists(json, 'tuteurs') ? undefined : (json['tuteurs'] === null ? null : (json['tuteurs'] as Array<any>).map(TuteurDoublonEditDtoFromJSON)),
        'tuteurFinal': !exists(json, 'tuteurFinal') ? undefined : TuteurDoublonEditDtoFromJSON(json['tuteurFinal']),
    };
}

export function DoublonEditDtoToJSON(value?: DoublonEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personnes': value.personnes === undefined ? undefined : (value.personnes === null ? null : (value.personnes as Array<any>).map(PersonneDoublonEditDtoToJSON)),
        'personneFinal': PersonneDoublonEditDtoToJSON(value.personneFinal),
        'apprenants': value.apprenants === undefined ? undefined : (value.apprenants === null ? null : (value.apprenants as Array<any>).map(ApprenantDoublonEditDtoToJSON)),
        'apprenantFinal': ApprenantDoublonEditDtoToJSON(value.apprenantFinal),
        'chefEntreprises': value.chefEntreprises === undefined ? undefined : (value.chefEntreprises === null ? null : (value.chefEntreprises as Array<any>).map(ChefEntrepriseDoublonEditDtoToJSON)),
        'chefEntrepriseFinal': ChefEntrepriseDoublonEditDtoToJSON(value.chefEntrepriseFinal),
        'contacts': value.contacts === undefined ? undefined : (value.contacts === null ? null : (value.contacts as Array<any>).map(ContactDoublonEditDtoToJSON)),
        'contactFinal': ContactDoublonEditDtoToJSON(value.contactFinal),
        'formateurs': value.formateurs === undefined ? undefined : (value.formateurs === null ? null : (value.formateurs as Array<any>).map(FormateurDoublonEditDtoToJSON)),
        'formateurFinal': FormateurDoublonEditDtoToJSON(value.formateurFinal),
        'representants': value.representants === undefined ? undefined : (value.representants === null ? null : (value.representants as Array<any>).map(RepresentantDoublonEditDtoToJSON)),
        'representantFinal': RepresentantDoublonEditDtoToJSON(value.representantFinal),
        'tuteurs': value.tuteurs === undefined ? undefined : (value.tuteurs === null ? null : (value.tuteurs as Array<any>).map(TuteurDoublonEditDtoToJSON)),
        'tuteurFinal': TuteurDoublonEditDtoToJSON(value.tuteurFinal),
    };
}

