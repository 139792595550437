/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbProjetFinancementDto,
  FcbProjetFinancementGridDtoPaginatedResults,
  FilterCriteriaInfo,
  ProjetFinancementSearch,
  SelectItem,
} from '../models/index';
import {
    FcbProjetFinancementDtoFromJSON,
    FcbProjetFinancementDtoToJSON,
    FcbProjetFinancementGridDtoPaginatedResultsFromJSON,
    FcbProjetFinancementGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ProjetFinancementSearchFromJSON,
    ProjetFinancementSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface ProjetFinancementBaseSearchRequest {
    ProjetFinancementSearch?: ProjetFinancementSearch;
}

export interface ProjetFinancementDeleteRequest {
    id?: number;
}

export interface ProjetFinancementGetRequest {
    id?: number;
}

export interface ProjetFinancementGetDisplayNameRequest {
    id?: number;
}

export interface ProjetFinancementGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ProjetFinancementGetSelectItemsRequest {
    searchField: string;
}

export interface ProjetFinancementSaveRequest {
    FcbProjetFinancementDto?: FcbProjetFinancementDto;
}

/**
 * 
 */
export class ProjetFinancementApi extends runtime.BaseAPI {

    /**
     */
    async projetFinancementBaseSearchRaw(requestParameters: ProjetFinancementBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbProjetFinancementGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ProjetFinancement/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjetFinancementSearchToJSON(requestParameters.ProjetFinancementSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbProjetFinancementGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async projetFinancementBaseSearch(requestParameters: ProjetFinancementBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbProjetFinancementGridDtoPaginatedResults> {
        const response = await this.projetFinancementBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projetFinancementDeleteRaw(requestParameters: ProjetFinancementDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ProjetFinancement`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async projetFinancementDelete(requestParameters: ProjetFinancementDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.projetFinancementDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projetFinancementGetRaw(requestParameters: ProjetFinancementGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbProjetFinancementDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ProjetFinancement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbProjetFinancementDtoFromJSON(jsonValue));
    }

    /**
     */
    async projetFinancementGet(requestParameters: ProjetFinancementGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbProjetFinancementDto> {
        const response = await this.projetFinancementGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projetFinancementGetDisplayNameRaw(requestParameters: ProjetFinancementGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ProjetFinancement/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async projetFinancementGetDisplayName(requestParameters: ProjetFinancementGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.projetFinancementGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projetFinancementGetSearchCriteriasRaw(requestParameters: ProjetFinancementGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ProjetFinancement/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async projetFinancementGetSearchCriterias(requestParameters: ProjetFinancementGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.projetFinancementGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projetFinancementGetSelectItemsRaw(requestParameters: ProjetFinancementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling projetFinancementGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ProjetFinancement/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async projetFinancementGetSelectItems(requestParameters: ProjetFinancementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.projetFinancementGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projetFinancementSaveRaw(requestParameters: ProjetFinancementSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbProjetFinancementDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ProjetFinancement`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbProjetFinancementDtoToJSON(requestParameters.FcbProjetFinancementDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbProjetFinancementDtoFromJSON(jsonValue));
    }

    /**
     */
    async projetFinancementSave(requestParameters: ProjetFinancementSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbProjetFinancementDto> {
        const response = await this.projetFinancementSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
