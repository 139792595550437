/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TuteurDoublonLieuFormationDto
 */
export interface TuteurDoublonLieuFormationDto {
    /**
     * 
     * @type {number}
     * @memberof TuteurDoublonLieuFormationDto
     */
    idtuteurLieu?: number;
    /**
     * 
     * @type {number}
     * @memberof TuteurDoublonLieuFormationDto
     */
    idtuteur?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TuteurDoublonLieuFormationDto
     */
    idlieuFormation?: number;
    /**
     * 
     * @type {string}
     * @memberof TuteurDoublonLieuFormationDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TuteurDoublonLieuFormationDto
     */
    enseigne?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TuteurDoublonLieuFormationDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TuteurDoublonLieuFormationDto
     */
    fonction?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof TuteurDoublonLieuFormationDto
     */
    sortieEntreprise?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof TuteurDoublonLieuFormationDto
     */
    contactPrincipal?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TuteurDoublonLieuFormationDto
     */
    capaciteFormative?: number | null;
}

/**
 * Check if a given object implements the TuteurDoublonLieuFormationDto interface.
 */
export function instanceOfTuteurDoublonLieuFormationDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TuteurDoublonLieuFormationDtoFromJSON(json: any): TuteurDoublonLieuFormationDto {
    return TuteurDoublonLieuFormationDtoFromJSONTyped(json, false);
}

export function TuteurDoublonLieuFormationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TuteurDoublonLieuFormationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idtuteurLieu': !exists(json, 'idtuteurLieu') ? undefined : json['idtuteurLieu'],
        'idtuteur': !exists(json, 'idtuteur') ? undefined : json['idtuteur'],
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'enseigne': !exists(json, 'enseigne') ? undefined : json['enseigne'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'fonction': !exists(json, 'fonction') ? undefined : json['fonction'],
        'sortieEntreprise': !exists(json, 'sortieEntreprise') ? undefined : (json['sortieEntreprise'] === null ? null : new Date(json['sortieEntreprise'])),
        'contactPrincipal': !exists(json, 'contactPrincipal') ? undefined : json['contactPrincipal'],
        'capaciteFormative': !exists(json, 'capaciteFormative') ? undefined : json['capaciteFormative'],
    };
}

export function TuteurDoublonLieuFormationDtoToJSON(value?: TuteurDoublonLieuFormationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idtuteurLieu': value.idtuteurLieu,
        'idtuteur': value.idtuteur,
        'idlieuFormation': value.idlieuFormation,
        'nom': value.nom,
        'enseigne': value.enseigne,
        'actif': value.actif,
        'fonction': value.fonction,
        'sortieEntreprise': value.sortieEntreprise === undefined ? undefined : (value.sortieEntreprise === null ? null : value.sortieEntreprise.toISOString()),
        'contactPrincipal': value.contactPrincipal,
        'capaciteFormative': value.capaciteFormative,
    };
}

