/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRechercheapprenantValidationcemasseDto
 */
export interface FcbRechercheapprenantValidationcemasseDto {
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheapprenantValidationcemasseDto
     */
    idapprenant?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheapprenantValidationcemasseDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheapprenantValidationcemasseDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheapprenantValidationcemasseDto
     */
    dateNaissance?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheapprenantValidationcemasseDto
     */
    idcentre?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheapprenantValidationcemasseDto
     */
    idformation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheapprenantValidationcemasseDto
     */
    idmetier?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheapprenantValidationcemasseDto
     */
    degre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheapprenantValidationcemasseDto
     */
    masculin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheapprenantValidationcemasseDto
     */
    codeclasse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheapprenantValidationcemasseDto
     */
    classe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheapprenantValidationcemasseDto
     */
    agrement?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheapprenantValidationcemasseDto
     */
    iduser?: number | null;
}

/**
 * Check if a given object implements the FcbRechercheapprenantValidationcemasseDto interface.
 */
export function instanceOfFcbRechercheapprenantValidationcemasseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRechercheapprenantValidationcemasseDtoFromJSON(json: any): FcbRechercheapprenantValidationcemasseDto {
    return FcbRechercheapprenantValidationcemasseDtoFromJSONTyped(json, false);
}

export function FcbRechercheapprenantValidationcemasseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRechercheapprenantValidationcemasseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : json['dateNaissance'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'idformation': !exists(json, 'idformation') ? undefined : json['idformation'],
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'degre': !exists(json, 'degre') ? undefined : json['degre'],
        'masculin': !exists(json, 'masculin') ? undefined : json['masculin'],
        'codeclasse': !exists(json, 'codeclasse') ? undefined : json['codeclasse'],
        'classe': !exists(json, 'classe') ? undefined : json['classe'],
        'agrement': !exists(json, 'agrement') ? undefined : json['agrement'],
        'iduser': !exists(json, 'iduser') ? undefined : json['iduser'],
    };
}

export function FcbRechercheapprenantValidationcemasseDtoToJSON(value?: FcbRechercheapprenantValidationcemasseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idapprenant': value.idapprenant,
        'nom': value.nom,
        'prenom': value.prenom,
        'dateNaissance': value.dateNaissance,
        'idcentre': value.idcentre,
        'idformation': value.idformation,
        'idmetier': value.idmetier,
        'degre': value.degre,
        'masculin': value.masculin,
        'codeclasse': value.codeclasse,
        'classe': value.classe,
        'agrement': value.agrement,
        'iduser': value.iduser,
    };
}

