import * as React from "react";
import { IModifedByDataObject, ModifiedBy } from "../components";

interface IModifiedByContext {
  modifiedByComponent?: React.ReactNode;
  setModifiedByData?: (data: IModifedByDataObject) => void;
}

export const ModifiedByContext = React.createContext<IModifiedByContext>(null);

export const ModifiedByProvider: React.FunctionComponent = ({ children }) => {
  const [modifiedByData, setModifiedByData] =
    React.useState<IModifedByDataObject>();

  return (
    <ModifiedByContext.Provider
      value={{
        modifiedByComponent: <ModifiedBy data={modifiedByData} />,
        setModifiedByData,
      }}
    >
      {children}
    </ModifiedByContext.Provider>
  );
};

export const useModifiedByComponent = () => React.useContext(ModifiedByContext);
