import * as React from "react";

export type OpenedMenuId = {
  level: number;
  id: string;
};

interface ICollapseAllContext {
  openedMenuId?: OpenedMenuId;
  setMenuOpenedId: (id: OpenedMenuId | null) => void;
}

export const CollapseAllContext = React.createContext<ICollapseAllContext>(null);
