import { Radio, RadioGroup } from "@blueprintjs/core";
import { getIn } from "formik";
import { FGCustomInput, FGTextAreaInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";

import { LieuFormationAgrementApi, LieuFormationAgrementOffreDto } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { FGReadOnlyInput, LinkButton, SmallFormGenerator } from "../../../../components";
import { useApiService, useCrudApi, useTheme, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

const InputContainer = styled.div`
  padding: 0.4rem 0 0 0.6rem;
`;

const OffreContainer = styled.div`
  display: flex;
  align-items: center;
`;

export interface IOffreDetailProps {}

export const OffreDetail: React.FunctionComponent<IOffreDetailProps> = props => {
  const { t } = useTl();
  const { theme } = useTheme();
  const history = useHistory();
  const api = useApiService(LieuFormationAgrementApi);

  const { id, state } = useParams<{ id: string; state: string }>();

  const { data, loading, saveItem, saving, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () => {
          return api.lieuFormationAgrementGetOffre({ idlieuFormationAgrementOffre: +id });
        },
        saveApiFn: async (d: LieuFormationAgrementOffreDto) => {
          return api.lieuFormationAgrementSaveOffre({ LieuFormationAgrementOffreDto: d });
        },
        onSavedRoute: d => `${ERoutes.offre}`
      }),
      [api, id]
    )
  );

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      remarque: Yup.string(),
      actif: Yup.boolean().required(t(ETLCodes.Required))
    });
  }, [t]);

  const fetchCapaciteFormativeAtteinte = React.useCallback(() => {
    return api.lieuFormationAgrementCheckCapaciteFormativeAtteinte({ idlieuFormationAgrementOffre: +id });
  }, [api, id]);
  const { data: capaciteFormativeAtteinte } = useQuery(["offre-cap-form", id], fetchCapaciteFormativeAtteinte);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={state === "edit"}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.offre)}
      saving={saving}
      validationErrors={validationErrors}
      showDeleteButton={false}
      minLabelWidth={205}
    >
      <FieldGroup columns={3}>
        <FieldGroup>
          <FGReadOnlyInput
            label={t(ETLCodes.AgrementLieuFormation)}
            name="lieuFormationAgrementNom"
            leftElement={ctx => (
              <InputContainer>
                <LinkButton
                  text={ctx?.formik?.values?.lieuFormationAgrementNom}
                  onClick={() =>
                    window.open(
                      `#${ERoutes.lieuFormation}/${ctx?.formik?.values?.idlieuFormation}/agrements/view/${ctx?.formik?.values?.idlieuFormationAgrement}?idsiegeSocial=${ctx?.formik?.values?.idsiegeSocial}`,
                      "_blank"
                    )
                  }
                />
              </InputContainer>
            )}
          ></FGReadOnlyInput>
          <FGCustomInput name="actif" label={t(ETLCodes.Offre)}>
            {ctx => (
              <OffreContainer>
                <RadioGroup
                  selectedValue={getIn(ctx.formik.values, "actif") ? "1" : "0"}
                  inline
                  onChange={e => ctx.formik.setFieldValue("actif", e.currentTarget.value === "1")}
                  disabled={!ctx?.editMode && capaciteFormativeAtteinte?.value}
                >
                  <Radio style={{ color: theme.sucessColor }} label={t(ETLCodes.Active)} value={"1"}></Radio>
                  <Radio style={{ color: theme.dangerColor }} label={t(ETLCodes.Inactive)} value={"0"}></Radio>
                </RadioGroup>
              </OffreContainer>
            )}
          </FGCustomInput>
          <FGTextAreaInput name="remarque" label={t(ETLCodes.Remarque)} />
        </FieldGroup>
      </FieldGroup>
    </SmallFormGenerator>
  );
};
