/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LieuFormationHistoryGridDto
 */
export interface LieuFormationHistoryGridDto {
    /**
     * 
     * @type {number}
     * @memberof LieuFormationHistoryGridDto
     */
    idlieuFormationHistory?: number;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationHistoryGridDto
     */
    iduser?: number;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationHistoryGridDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationHistoryGridDto
     */
    idlieuFormation?: number;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationHistoryGridDto
     */
    action?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationHistoryGridDto
     */
    actionType?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationHistoryGridDto
     */
    actionDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationHistoryGridDto
     */
    userName?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LieuFormationHistoryGridDto
     */
    ifapmeSide?: Array<string> | null;
}

/**
 * Check if a given object implements the LieuFormationHistoryGridDto interface.
 */
export function instanceOfLieuFormationHistoryGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LieuFormationHistoryGridDtoFromJSON(json: any): LieuFormationHistoryGridDto {
    return LieuFormationHistoryGridDtoFromJSONTyped(json, false);
}

export function LieuFormationHistoryGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LieuFormationHistoryGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idlieuFormationHistory': !exists(json, 'idlieuFormationHistory') ? undefined : json['idlieuFormationHistory'],
        'iduser': !exists(json, 'iduser') ? undefined : json['iduser'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'action': !exists(json, 'action') ? undefined : json['action'],
        'actionType': !exists(json, 'actionType') ? undefined : json['actionType'],
        'actionDate': !exists(json, 'actionDate') ? undefined : (new Date(json['actionDate'])),
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'ifapmeSide': !exists(json, 'ifapmeSide') ? undefined : json['ifapmeSide'],
    };
}

export function LieuFormationHistoryGridDtoToJSON(value?: LieuFormationHistoryGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idlieuFormationHistory': value.idlieuFormationHistory,
        'iduser': value.iduser,
        'idpersonne': value.idpersonne,
        'idlieuFormation': value.idlieuFormation,
        'action': value.action,
        'actionType': value.actionType,
        'actionDate': value.actionDate === undefined ? undefined : (value.actionDate.toISOString()),
        'userName': value.userName,
        'ifapmeSide': value.ifapmeSide,
    };
}

