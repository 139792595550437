import { ITabProps, Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";

import {
  ApprenantAbsence,
  ApprenantAzure,
  ApprenantContratList,
  ApprenantDocuments,
  ApprenantFinance,
  ApprenantHistory,
  ApprenantHoraire,
  ApprenantInscription,
  ApprenantJustificatifs,
  ApprenantLieuFormationList,
  ApprenantPassportDrive,
  ApprenantRepresentants,
  ApprenantSignaletiqueSwitch,
  ApprenantSMS,
  ApprenantValidationCE
} from ".";
import { ApprenantApi, BulletinApprenantRecaputilatifApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useApiService, useTheme, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { ProspectParcoursCreaSwitch } from "../../prospect/item/crea/ProspectParcoursCreaSwitch";
import { AcquisSwitch } from "./acquis/AcquisSwitch";
import { ParcoursFormation } from "./parcoursFormation/ParcoursFormation";
import { ApprenantResultatTypeSwitch } from "./resultatType/ApprenantResultatTypeSwitch";
import { ApprenantStatut } from "./statut/ApprenantStatut";
import { SuiviApprenant } from "./suiviApprenant/SuiviApprenant";

interface IApprenantItemPageProps {}

export const ApprenantItemPage: React.FunctionComponent<IApprenantItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state } = useParams<{ id: string; tab: string; state: string }>();
  const idApprenant = React.useMemo(() => +id, [id]);
  const history = useHistory();
  const { search } = useLocation();
  const { ifapmeSide } = useTheme();
  const isHope = React.useMemo(() => ifapmeSide === "hope", [ifapmeSide]);

  const iddemandeInscription = React.useMemo(() => +(new URLSearchParams(search).get("iddemandeInscription") ?? 0), [
    search
  ]);

  const apprenantApi = useApiService(ApprenantApi);

  const fetchSocieteExterneUnauthorized = React.useCallback(async () => {
    return await apprenantApi.apprenantSocieteExterneUnauthorized({ idApprenant });
  }, [apprenantApi, idApprenant]);
  const { data: societeExterneUnauthorized } = useQuery(
    ["societe-externe-unauthorize-apprenant", idApprenant],
    fetchSocieteExterneUnauthorized,
    { cacheTime: 0 }
  );
  React.useEffect(() => {
    if (societeExterneUnauthorized?.value) {
      history.push(ERoutes.unauthorized);
    }
  }, [history, societeExterneUnauthorized]);

  const bulletinApi = useApiService(BulletinApprenantRecaputilatifApi);
  const fetchIdPersonne = React.useCallback(() => {
    return apprenantApi.apprenantGetIdPersonne({ id: +id });
  }, [apprenantApi, id]);
  const { data: idpersonne } = useQuery(["idpersonne-apprenant", id], fetchIdPersonne);

  const [displayName] = useApiEffect(() => apprenantApi.apprenantGetDisplayName({ id: +id }), [id]);
  const [anneeTab] = useApiEffect(async () => await apprenantApi.apprenantGetDerniereAnnee({ IdApprenant: +id }));
  const [typeCours] = useApiEffect(async () => {
    if (anneeTab != null) {
      return await bulletinApi.bulletinApprenantRecaputilatifGetFirstTypeCoursInscription({
        id: +id,
        anneeScolaire: anneeTab
      });
    }
  }, [anneeTab]);
  const onTabChange = React.useCallback(
    newTabId => {
      if (newTabId === "resultats") {
        history.push(`${ERoutes.apprenant}/${id}/${newTabId}/${anneeTab}/${typeCours}`);
      } else {
        history.push(
          `${ERoutes.apprenant}/${id}/${newTabId}/${state !== "edit" && state !== "view" ? "view" : state}${search}`
        );
      }
    },
    [anneeTab, history, id, search, state, typeCours]
  );

  const hopeTabs = React.useMemo<ITabProps[]>(
    () => [
      { id: "signaletique", title: t(ETLCodes.Signaletique), panel: <ApprenantSignaletiqueSwitch /> },
      {
        id: "representants",
        title: t(ETLCodes.Representants),
        panel: <ApprenantRepresentants apprenantId={idApprenant} />,
        disabled: idApprenant <= 0
      },
      {
        id: "acquis",
        title: t(ETLCodes.Acquis),
        panel: <AcquisSwitch baseRoute={ERoutes.apprenant} idpersonne={idpersonne} />,
        disabled: idApprenant <= 0
      },
      {
        id: "parcoursFormation",
        title: t(ETLCodes.ParcoursFormation),
        panel: <ParcoursFormation />,
        disabled: idApprenant <= 0
      },
      {
        id: "inscription",
        title: t(ETLCodes.Inscriptions),
        panel: <ApprenantInscription />,
        disabled: idApprenant <= 0
      },
      {
        id: "contrats",
        title: t(ETLCodes.Contrats),
        panel: <ApprenantContratList />,
        disabled: idApprenant <= 0
      },
      {
        id: "lieuFormation",
        title: t(ETLCodes.LieuFormation),
        panel: <ApprenantLieuFormationList />,
        disabled: idApprenant <= 0
      },
      {
        id: "suiviApprenant",
        title: t(ETLCodes.Suivi),
        panel: <SuiviApprenant apprenantId={idApprenant} />,
        disabled: idApprenant <= 0
      },
      {
        id: "Horaire",
        title: t(ETLCodes.Horaires),
        panel: <ApprenantHoraire apprenantId={idApprenant} />,
        disabled: idApprenant <= 0
      },
      {
        id: "Absence",
        title: t(ETLCodes.Absences),
        panel: <ApprenantAbsence apprenantId={idApprenant} />,
        disabled: idApprenant <= 0
      },
      {
        id: "justificatifs",
        title: t(ETLCodes.Justificatifs),
        panel: <ApprenantJustificatifs />,
        disabled: idApprenant <= 0
      },
      {
        id: "resultats",
        title: t(ETLCodes.Resultats),
        panel: <ApprenantResultatTypeSwitch />,
        disabled: idApprenant <= 0
      },
      {
        id: "passportDrive",
        title: t(ETLCodes.PassportDrive),
        panel: <ApprenantPassportDrive />,
        disabled: idApprenant <= 0
      },
      {
        id: "documents",
        title: t(ETLCodes.Documents),
        panel: <ApprenantDocuments />,
        disabled: idApprenant <= 0
      },
      {
        id: "statutMotifs",
        title: t(ETLCodes.StatutsMotifs),
        panel: <ApprenantStatut />,
        disabled: idApprenant <= 0
      },
      { id: "history", title: t(ETLCodes.History), panel: <ApprenantHistory />, disabled: idApprenant <= 0 },
      { id: "Azure", title: t(ETLCodes.Azure), panel: <ApprenantAzure />, disabled: idApprenant <= 0 }
    ],
    [idApprenant, idpersonne, t]
  );

  const walterTabs = React.useMemo<ITabProps[]>(
    () => [
      {
        id: "signaletique",
        title: t(ETLCodes.Signaletique),
        panel: <ApprenantSignaletiqueSwitch />,
        disabled: iddemandeInscription > 0
      },
      {
        id: "crea",
        title: t(ETLCodes.ParcoursCrea),
        panel: <ProspectParcoursCreaSwitch />,
        disabled: idApprenant <= 0
      },
      {
        id: "representants",
        title: t(ETLCodes.Representants),
        panel: <ApprenantRepresentants apprenantId={idApprenant} />,
        disabled: idApprenant <= 0 || iddemandeInscription > 0
      },
      {
        id: "acquis",
        title: t(ETLCodes.Acquis),
        panel: <AcquisSwitch baseRoute={ERoutes.apprenant} idpersonne={idpersonne} />,
        disabled: idApprenant <= 0 || iddemandeInscription > 0
      },
      {
        id: "inscription",
        title: t(ETLCodes.Inscriptions),
        panel: <ApprenantInscription />,
        disabled: idApprenant <= 0
      },
      {
        id: "finance",
        title: t(ETLCodes.Finance),
        panel: <ApprenantFinance />,
        disabled: idApprenant <= 0 || iddemandeInscription > 0
      },
      {
        id: "contrats",
        title: t(ETLCodes.Contrats),
        panel: <ApprenantContratList />,
        disabled: idApprenant <= 0 || iddemandeInscription > 0
      },
      {
        id: "lieuFormation",
        title: t(ETLCodes.LieuFormation),
        panel: <ApprenantLieuFormationList />,
        disabled: idApprenant <= 0 || iddemandeInscription > 0
      },
      {
        id: "suiviApprenant",
        title: t(ETLCodes.Suivi),
        panel: <SuiviApprenant apprenantId={idApprenant} />,
        disabled: idApprenant <= 0 || iddemandeInscription > 0
      },
      {
        id: "Horaire",
        title: t(ETLCodes.Horaires),
        panel: <ApprenantHoraire apprenantId={idApprenant} />,
        disabled: idApprenant <= 0 || iddemandeInscription > 0
      },
      {
        id: "resultats",
        title: t(ETLCodes.Resultats),
        panel: <ApprenantResultatTypeSwitch />,
        disabled: idApprenant <= 0 || iddemandeInscription > 0
      },
      {
        id: "documents",
        title: t(ETLCodes.Documents),
        panel: <ApprenantDocuments />,
        disabled: idApprenant <= 0 || iddemandeInscription > 0
      },
      {
        id: "statutMotifs",
        title: t(ETLCodes.StatutsMotifs),
        panel: <ApprenantStatut />,
        disabled: idApprenant <= 0 || iddemandeInscription > 0
      },
      {
        id: "history",
        title: t(ETLCodes.History),
        panel: <ApprenantHistory />,
        disabled: idApprenant <= 0 || iddemandeInscription > 0
      },
      {
        id: "validationce",
        title: t(ETLCodes.ValidationCE),
        panel: <ApprenantValidationCE apprenantId={idApprenant} />,
        disabled: idApprenant <= 0 || iddemandeInscription > 0
      },
      {
        id: "justificatifs",
        title: t(ETLCodes.Justificatifs),
        panel: <ApprenantJustificatifs />,
        disabled: idApprenant <= 0 || iddemandeInscription > 0
      },
      {
        id: "Absence",
        title: t(ETLCodes.Absences),
        panel: <ApprenantAbsence apprenantId={idApprenant} />,
        disabled: idApprenant <= 0 || iddemandeInscription > 0
      },
      {
        id: "SMS",
        title: t(ETLCodes.SMS),
        panel: <ApprenantSMS apprenantId={idApprenant} />,
        disabled: idApprenant <= 0 || iddemandeInscription > 0
      },
      {
        id: "Azure",
        title: t(ETLCodes.Azure),
        panel: <ApprenantAzure />,
        disabled: idApprenant <= 0 || iddemandeInscription > 0
      }
    ],
    [idApprenant, iddemandeInscription, idpersonne, t]
  );

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.Apprenants), route: ERoutes.apprenant },
        { text: +id > 0 ? displayName : t(ETLCodes.New) }
      ]}
    >
      <ProtectedTabs id="TabsApprenant" onChange={onTabChange} selectedTabId={tab} renderActiveTabPanelOnly>
        <BackButton backRoute={ERoutes.apprenant}></BackButton>
        {(isHope ? hopeTabs : walterTabs).map(t => (
          <Tab {...t} />
        ))}
      </ProtectedTabs>
    </PageBase>
  );
};
