import { Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ToolTipButton } from "nsitools-react";
import * as React from "react";

import { ICustomButtonProps } from "./ICustomButtonProps";

interface IEmailProps extends Omit<ICustomButtonProps, "onClick"> {
  email?: string;
  emailBcc?: string;
  afterClick?: () => void;
}

export const EmailButton: React.FunctionComponent<IEmailProps> = ({
  minimal = true,
  email,
  emailBcc,
  loading,
  disabled,
  intent = Intent.NONE,
  afterClick
}) => {
  const onClickMail = React.useCallback(() => {
    const el = document.createElement("a");
    const bccEmail = !!emailBcc ? `?bcc=${emailBcc}` : "";
    el.setAttribute("href", `mailto:${email}${bccEmail}`);
    el.click();
    el.remove();

    if (afterClick) {
      afterClick();
    }
  }, [afterClick, emailBcc, email]);

  return email || emailBcc ? (
    <ToolTipButton
      tooltip={email ?? emailBcc}
      intent={intent}
      disabled={disabled}
      icon={IconNames.ENVELOPE}
      minimal={minimal}
      loading={loading}
      onClick={onClickMail}
    />
  ) : (
    <></>
  );
};
