/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AzureUserDto
 */
export interface AzureUserDto {
    /**
     * 
     * @type {string}
     * @memberof AzureUserDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AzureUserDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AzureUserDto
     */
    displayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AzureUserDto
     */
    userPrincipalName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AzureUserDto
     */
    mailNickName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AzureUserDto
     */
    identifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AzureUserDto
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AzureUserDto
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AzureUserDto
     */
    department?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AzureUserDto
     */
    office?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AzureUserDto
     */
    telephoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AzureUserDto
     */
    mobile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AzureUserDto
     */
    o365UserId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AzureUserDto
     */
    accountEnabled?: boolean;
}

/**
 * Check if a given object implements the AzureUserDto interface.
 */
export function instanceOfAzureUserDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AzureUserDtoFromJSON(json: any): AzureUserDto {
    return AzureUserDtoFromJSONTyped(json, false);
}

export function AzureUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AzureUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'userPrincipalName': !exists(json, 'userPrincipalName') ? undefined : json['userPrincipalName'],
        'mailNickName': !exists(json, 'mailNickName') ? undefined : json['mailNickName'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'jobTitle': !exists(json, 'jobTitle') ? undefined : json['jobTitle'],
        'department': !exists(json, 'department') ? undefined : json['department'],
        'office': !exists(json, 'office') ? undefined : json['office'],
        'telephoneNumber': !exists(json, 'telephoneNumber') ? undefined : json['telephoneNumber'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'o365UserId': !exists(json, 'o365UserId') ? undefined : json['o365UserId'],
        'accountEnabled': !exists(json, 'accountEnabled') ? undefined : json['accountEnabled'],
    };
}

export function AzureUserDtoToJSON(value?: AzureUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'displayName': value.displayName,
        'userPrincipalName': value.userPrincipalName,
        'mailNickName': value.mailNickName,
        'identifier': value.identifier,
        'password': value.password,
        'jobTitle': value.jobTitle,
        'department': value.department,
        'office': value.office,
        'telephoneNumber': value.telephoneNumber,
        'mobile': value.mobile,
        'o365UserId': value.o365UserId,
        'accountEnabled': value.accountEnabled,
    };
}

