import * as React from "react";
import { ToolTipButton } from "nsitools-react";
import { IconNames } from "@blueprintjs/icons";
import { ETLCodes } from "../../locales/ETLCodes";
import { useTl } from "../../hooks";
import { ICustomButtonProps } from "./ICustomButtonProps";
import { Intent } from "@blueprintjs/core";

interface IDownloadButtonProps extends ICustomButtonProps {
  tooltip?: string;
}

export const DownloadButton: React.FunctionComponent<IDownloadButtonProps> = ({
  minimal = true,
  onClick,
  loading,
  disabled,
  intent = Intent.NONE,
  text,
  outlined,
  tooltip = null
}) => {
  const { t } = useTl();

  return (
    <ToolTipButton
      icon={IconNames.DOWNLOAD}
      tooltip={tooltip ?? t(ETLCodes.General_Download)}
      minimal={minimal}
      onClick={onClick}
      text={text ? text : minimal ? null : t(ETLCodes.General_Download)}
      intent={intent}
      loading={loading}
      disabled={disabled}
      type={onClick ? "button" : "submit"}
      outlined={outlined}
    />
  );
};
