/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbClasseMiseAJourDto
 */
export interface FcbClasseMiseAJourDto {
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseMiseAJourDto
     */
    bloque?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMiseAJourDto
     */
    idstatutAgrement?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbClasseMiseAJourDto
     */
    dateAgrement?: Date | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FcbClasseMiseAJourDto
     */
    idClasses?: Array<number> | null;
}

/**
 * Check if a given object implements the FcbClasseMiseAJourDto interface.
 */
export function instanceOfFcbClasseMiseAJourDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbClasseMiseAJourDtoFromJSON(json: any): FcbClasseMiseAJourDto {
    return FcbClasseMiseAJourDtoFromJSONTyped(json, false);
}

export function FcbClasseMiseAJourDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbClasseMiseAJourDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bloque': !exists(json, 'bloque') ? undefined : json['bloque'],
        'idstatutAgrement': !exists(json, 'idstatutAgrement') ? undefined : json['idstatutAgrement'],
        'dateAgrement': !exists(json, 'dateAgrement') ? undefined : (json['dateAgrement'] === null ? null : new Date(json['dateAgrement'])),
        'idClasses': !exists(json, 'idClasses') ? undefined : json['idClasses'],
    };
}

export function FcbClasseMiseAJourDtoToJSON(value?: FcbClasseMiseAJourDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bloque': value.bloque,
        'idstatutAgrement': value.idstatutAgrement,
        'dateAgrement': value.dateAgrement === undefined ? undefined : (value.dateAgrement === null ? null : value.dateAgrement.toISOString()),
        'idClasses': value.idClasses,
    };
}

