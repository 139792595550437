/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AcquisScolaireGridDto
 */
export interface AcquisScolaireGridDto {
    /**
     * 
     * @type {number}
     * @memberof AcquisScolaireGridDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AcquisScolaireGridDto
     */
    titre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcquisScolaireGridDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcquisScolaireGridDto
     */
    operateur?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcquisScolaireGridDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcquisScolaireGridDto
     */
    resultat?: string | null;
}

/**
 * Check if a given object implements the AcquisScolaireGridDto interface.
 */
export function instanceOfAcquisScolaireGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AcquisScolaireGridDtoFromJSON(json: any): AcquisScolaireGridDto {
    return AcquisScolaireGridDtoFromJSONTyped(json, false);
}

export function AcquisScolaireGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcquisScolaireGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'titre': !exists(json, 'titre') ? undefined : json['titre'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'operateur': !exists(json, 'operateur') ? undefined : json['operateur'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'resultat': !exists(json, 'resultat') ? undefined : json['resultat'],
    };
}

export function AcquisScolaireGridDtoToJSON(value?: AcquisScolaireGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'titre': value.titre,
        'type': value.type,
        'operateur': value.operateur,
        'anneeScolaire': value.anneeScolaire,
        'resultat': value.resultat,
    };
}

