/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EStatutDemande } from './EStatutDemande';
import {
    EStatutDemandeFromJSON,
    EStatutDemandeFromJSONTyped,
    EStatutDemandeToJSON,
} from './EStatutDemande';

/**
 * 
 * @export
 * @interface DashboardDemandeFormationGridDto
 */
export interface DashboardDemandeFormationGridDto {
    /**
     * 
     * @type {number}
     * @memberof DashboardDemandeFormationGridDto
     */
    iddemandeFormationCrea?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardDemandeFormationGridDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardDemandeFormationGridDto
     */
    apprenant?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardDemandeFormationGridDto
     */
    idmetier?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardDemandeFormationGridDto
     */
    metier?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardDemandeFormationGridDto
     */
    idmodule?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardDemandeFormationGridDto
     */
    module?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardDemandeFormationGridDto
     */
    idannee?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardDemandeFormationGridDto
     */
    anneeAcademique?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardDemandeFormationGridDto
     */
    premierSemestre?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardDemandeFormationGridDto
     */
    idstade?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardDemandeFormationGridDto
     */
    stade?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardDemandeFormationGridDto
     */
    idcentre?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardDemandeFormationGridDto
     */
    centre?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardDemandeFormationGridDto
     */
    idstatutDemande?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardDemandeFormationGridDto
     */
    statut?: string | null;
    /**
     * 
     * @type {EStatutDemande}
     * @memberof DashboardDemandeFormationGridDto
     */
    codeStatut?: EStatutDemande;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardDemandeFormationGridDto
     */
    masquee?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardDemandeFormationGridDto
     */
    isProspect?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DashboardDemandeFormationGridDto
     */
    creationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof DashboardDemandeFormationGridDto
     */
    modificationDate?: Date | null;
}

/**
 * Check if a given object implements the DashboardDemandeFormationGridDto interface.
 */
export function instanceOfDashboardDemandeFormationGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DashboardDemandeFormationGridDtoFromJSON(json: any): DashboardDemandeFormationGridDto {
    return DashboardDemandeFormationGridDtoFromJSONTyped(json, false);
}

export function DashboardDemandeFormationGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardDemandeFormationGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iddemandeFormationCrea': !exists(json, 'iddemandeFormationCrea') ? undefined : json['iddemandeFormationCrea'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'apprenant': !exists(json, 'apprenant') ? undefined : json['apprenant'],
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'metier': !exists(json, 'metier') ? undefined : json['metier'],
        'idmodule': !exists(json, 'idmodule') ? undefined : json['idmodule'],
        'module': !exists(json, 'module') ? undefined : json['module'],
        'idannee': !exists(json, 'idannee') ? undefined : json['idannee'],
        'anneeAcademique': !exists(json, 'anneeAcademique') ? undefined : json['anneeAcademique'],
        'premierSemestre': !exists(json, 'premierSemestre') ? undefined : json['premierSemestre'],
        'idstade': !exists(json, 'idstade') ? undefined : json['idstade'],
        'stade': !exists(json, 'stade') ? undefined : json['stade'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'centre': !exists(json, 'centre') ? undefined : json['centre'],
        'idstatutDemande': !exists(json, 'idstatutDemande') ? undefined : json['idstatutDemande'],
        'statut': !exists(json, 'statut') ? undefined : json['statut'],
        'codeStatut': !exists(json, 'codeStatut') ? undefined : EStatutDemandeFromJSON(json['codeStatut']),
        'masquee': !exists(json, 'masquee') ? undefined : json['masquee'],
        'isProspect': !exists(json, 'isProspect') ? undefined : json['isProspect'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
    };
}

export function DashboardDemandeFormationGridDtoToJSON(value?: DashboardDemandeFormationGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iddemandeFormationCrea': value.iddemandeFormationCrea,
        'idapprenant': value.idapprenant,
        'apprenant': value.apprenant,
        'idmetier': value.idmetier,
        'metier': value.metier,
        'idmodule': value.idmodule,
        'module': value.module,
        'idannee': value.idannee,
        'anneeAcademique': value.anneeAcademique,
        'premierSemestre': value.premierSemestre,
        'idstade': value.idstade,
        'stade': value.stade,
        'idcentre': value.idcentre,
        'centre': value.centre,
        'idstatutDemande': value.idstatutDemande,
        'statut': value.statut,
        'codeStatut': EStatutDemandeToJSON(value.codeStatut),
        'masquee': value.masquee,
        'isProspect': value.isProspect,
        'creationUser': value.creationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
    };
}

