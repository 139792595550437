/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContratHistoryGridDto
 */
export interface ContratHistoryGridDto {
    /**
     * 
     * @type {number}
     * @memberof ContratHistoryGridDto
     */
    idcontratHistory?: number;
    /**
     * 
     * @type {number}
     * @memberof ContratHistoryGridDto
     */
    iduser?: number;
    /**
     * 
     * @type {number}
     * @memberof ContratHistoryGridDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {number}
     * @memberof ContratHistoryGridDto
     */
    idcontrat?: number;
    /**
     * 
     * @type {string}
     * @memberof ContratHistoryGridDto
     */
    action?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratHistoryGridDto
     */
    actionType?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratHistoryGridDto
     */
    actionDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ContratHistoryGridDto
     */
    userName?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContratHistoryGridDto
     */
    ifapmeSide?: Array<string> | null;
}

/**
 * Check if a given object implements the ContratHistoryGridDto interface.
 */
export function instanceOfContratHistoryGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContratHistoryGridDtoFromJSON(json: any): ContratHistoryGridDto {
    return ContratHistoryGridDtoFromJSONTyped(json, false);
}

export function ContratHistoryGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContratHistoryGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontratHistory': !exists(json, 'idcontratHistory') ? undefined : json['idcontratHistory'],
        'iduser': !exists(json, 'iduser') ? undefined : json['iduser'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'idcontrat': !exists(json, 'idcontrat') ? undefined : json['idcontrat'],
        'action': !exists(json, 'action') ? undefined : json['action'],
        'actionType': !exists(json, 'actionType') ? undefined : json['actionType'],
        'actionDate': !exists(json, 'actionDate') ? undefined : (new Date(json['actionDate'])),
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'ifapmeSide': !exists(json, 'ifapmeSide') ? undefined : json['ifapmeSide'],
    };
}

export function ContratHistoryGridDtoToJSON(value?: ContratHistoryGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontratHistory': value.idcontratHistory,
        'iduser': value.iduser,
        'idpersonne': value.idpersonne,
        'idcontrat': value.idcontrat,
        'action': value.action,
        'actionType': value.actionType,
        'actionDate': value.actionDate === undefined ? undefined : (value.actionDate.toISOString()),
        'userName': value.userName,
        'ifapmeSide': value.ifapmeSide,
    };
}

