/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FichierGridDtoPaginatedResults,
  FichierSearchDto,
  FileDownloadDto,
  FilterCriteriaInfo,
  MassDeleteFichierRequestDto,
  MassDownloadRequestDto,
  ReferentialItemDto,
} from '../models/index';
import {
    FichierGridDtoPaginatedResultsFromJSON,
    FichierGridDtoPaginatedResultsToJSON,
    FichierSearchDtoFromJSON,
    FichierSearchDtoToJSON,
    FileDownloadDtoFromJSON,
    FileDownloadDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    MassDeleteFichierRequestDtoFromJSON,
    MassDeleteFichierRequestDtoToJSON,
    MassDownloadRequestDtoFromJSON,
    MassDownloadRequestDtoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface FichierBaseSearchRequest {
    FichierSearchDto?: FichierSearchDto;
}

export interface FichierDeleteRequest {
    id?: number;
}

export interface FichierDownloadRequest {
    id?: number;
}

export interface FichierGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface FichierGetSelectItemsRequest {
    searchField: string;
}

export interface FichierMassDeleteRequest {
    MassDeleteFichierRequestDto?: MassDeleteFichierRequestDto;
}

export interface FichierMassDownloadRequest {
    MassDownloadRequestDto?: MassDownloadRequestDto;
}

export interface FichierMassDownloadPdfRequest {
    MassDownloadRequestDto?: MassDownloadRequestDto;
}

/**
 * 
 */
export class FichierApi extends runtime.BaseAPI {

    /**
     */
    async fichierBaseSearchRaw(requestParameters: FichierBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FichierGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Fichier/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FichierSearchDtoToJSON(requestParameters.FichierSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FichierGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async fichierBaseSearch(requestParameters: FichierBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FichierGridDtoPaginatedResults> {
        const response = await this.fichierBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fichierDeleteRaw(requestParameters: FichierDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Fichier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async fichierDelete(requestParameters: FichierDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.fichierDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async fichierDownloadRaw(requestParameters: FichierDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Fichier/Download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async fichierDownload(requestParameters: FichierDownloadRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.fichierDownloadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fichierGetSearchCriteriasRaw(requestParameters: FichierGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Fichier/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async fichierGetSearchCriterias(requestParameters: FichierGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.fichierGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fichierGetSelectItemsRaw(requestParameters: FichierGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling fichierGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Fichier/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async fichierGetSelectItems(requestParameters: FichierGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.fichierGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fichierMassDeleteRaw(requestParameters: FichierMassDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Fichier/MassDelete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MassDeleteFichierRequestDtoToJSON(requestParameters.MassDeleteFichierRequestDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async fichierMassDelete(requestParameters: FichierMassDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.fichierMassDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async fichierMassDownloadRaw(requestParameters: FichierMassDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FileDownloadDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Fichier/MassDownload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MassDownloadRequestDtoToJSON(requestParameters.MassDownloadRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FileDownloadDtoFromJSON));
    }

    /**
     */
    async fichierMassDownload(requestParameters: FichierMassDownloadRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FileDownloadDto>> {
        const response = await this.fichierMassDownloadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async fichierMassDownloadPdfRaw(requestParameters: FichierMassDownloadPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Fichier/MassDownloadPdf`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MassDownloadRequestDtoToJSON(requestParameters.MassDownloadRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async fichierMassDownloadPdf(requestParameters: FichierMassDownloadPdfRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.fichierMassDownloadPdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
