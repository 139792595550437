import { FGEmpty, FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";

import { FcbStadeDtoFromJSON, StadeApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { SmallFormGenerator, FGWalterCheckboxInput } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import * as Yup from "yup";

interface IStadeDetailPageProps {
  editMode: boolean;
  idStade: number;
}

export const StadeDetailPage: React.FunctionComponent<IStadeDetailPageProps> = ({ idStade, editMode }) => {
  const { t } = useTl();
  const api = useApiService(StadeApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () => (+idStade <= 0 ? FcbStadeDtoFromJSON({ idStade: 0 }) : api.stadeGet({ id: +idStade })),
    saveApiFn: d => api.stadeSave({ FcbStadeDto: d }),
    onSavedRoute: d => `${ERoutes.stade}/${d.idstade}/detail/edit`,
    deleteApiFn: d => api.stadeDelete({ id: d.idstade }),
    onDeletedRoute: () => ERoutes.stade
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      code: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={editMode}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(ERoutes.stade)}
        onDelete={deleteItem}
        showDeleteButton={+idStade > 0}
        saving={saving}
        deleting={deleting}
      >
        <FieldGroup columns={2}>
          <FGTextInput name="code" label={t(ETLCodes.Code)} maxLength={2} />
          <FGEmpty />
          <FGTextAreaInput name="description" label={t(ETLCodes.Description)} maxLength={50} />
          <FGEmpty />
          <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
          <FGEmpty />
          <FGWalterCheckboxInput name="acquisIfapme" label={t(ETLCodes.AcquisIfapme)} />
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
