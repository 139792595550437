import { Colors, Intent } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import { css } from "styled-components";
import {
  SiegeSocialApi,
  SiegeSocialChefEntrepriseGridDto,
  SiegeSocialChefEntrepriseSearchDto
} from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import { AddButton, BooleanColumn, EditButton, ViewButton } from "../../../../../../components";
import { useApiService, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

export interface ISiegeSocialChefEntrepriseListPageProps {}

export const SiegeSocialChefEntrepriseListPage: React.FunctionComponent<ISiegeSocialChefEntrepriseListPageProps> = props => {
  const { t } = useTl();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const idsiegeSocial = React.useMemo(() => +id, [id]);
  const api = useApiService(SiegeSocialApi);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { nom: "ASC", prenom: "ASC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: SiegeSocialChefEntrepriseSearchDto) => {
      sObj.idsiegeSocial = idsiegeSocial;
      return api.siegeSocialSearchChefsEntreprise({ SiegeSocialChefEntrepriseSearchDto: sObj });
    },
    [api, idsiegeSocial]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const onViewClick = React.useCallback(
    (dto: SiegeSocialChefEntrepriseGridDto) => {
      history.push(`${ERoutes.siegeSocial}/${id}/chefsEntreprise/view/${dto.idchefEntrepriseSiege}`);
    },
    [history, id]
  );

  const onEditClick = React.useCallback(
    (dto: SiegeSocialChefEntrepriseGridDto) => {
      history.push(`${ERoutes.siegeSocial}/${id}/chefsEntreprise/edit/${dto.idchefEntrepriseSiege}`);
    },
    [history, id]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: SiegeSocialChefEntrepriseGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => onViewClick(row)} />
            <EditButton minimal={true} onClick={() => onEditClick(row)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.DateNaissance),
        fieldName: "dateNaissance"
      },
      {
        header: () => t(ETLCodes.Fonction),
        fieldName: "fonction"
      },
      {
        header: () => t(ETLCodes.ChefEntreprisePrincipal),
        fieldName: "chefEntreprisePrincipal",
        alignment: "center",
        autoFitContent: true,
        render: (row: SiegeSocialChefEntrepriseGridDto) => <BooleanColumn value={row.chefEntreprisePrincipal} />
      },
      {
        header: () => t(ETLCodes.Gsm),
        fieldName: "gsm"
      },
      {
        header: () => t(ETLCodes.Telephone),
        fieldName: "telephone"
      },
      {
        header: () => t(ETLCodes.Email),
        fieldName: "email"
      }
    ],
    [onEditClick, onViewClick, t]
  );

  const onAddClick = React.useCallback(() => {
    history.push(`${ERoutes.siegeSocial}/${id}/chefsEntreprise/checkDoublons`);
  }, [history, id]);

  return (
    <FieldSet
      title={t(ETLCodes.TableResults, { count: totalCount })}
      rightElement={<AddButton onClick={onAddClick} text={t(ETLCodes.General_Add)} intent={Intent.PRIMARY} />}
    >
      <DataTable
        dateFormat="dd/MM/yyyy"
        tableState={tableState}
        loading={loading}
        columns={columns}
        filterMode="OnEnter"
        customizeRowStyle={(row: SiegeSocialChefEntrepriseGridDto) => css`
          & * {
            color: ${!row.actif ? Colors.RED3 + " !important" : null};
          }
        `}
      ></DataTable>
    </FieldSet>
  );
};
