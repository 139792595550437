import { FGEmpty, FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { ClauseApi, FcbClauseDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterSelectInput, SmallFormGenerator, FGWalterCheckboxInput } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

interface IClauseDetailPageProps {
  editMode: boolean;
  idClause: number;
}

export const ClauseDetailPage: React.FunctionComponent<IClauseDetailPageProps> = ({ editMode, idClause }) => {
  const { t } = useTl();
  const api = useApiService(ClauseApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () => (+idClause <= 0 ? FcbClauseDtoFromJSON({ idClause: 0 }) : api.clauseGet({ id: +idClause })),
    saveApiFn: d => api.clauseSave({ FcbClauseDto: d }),
    onSavedRoute: d => `${ERoutes.clause}/${d.idclause}/detail/edit`,
    deleteApiFn: d => api.clauseDelete({ id: d.idclause }),
    onDeletedRoute: () => ERoutes.clause
  });

  const [stade, loadingStade] = useReferential(a => a.referentialGetStade(), true, []);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idstade: Yup.number()
        .required(t(ETLCodes.Required))
        .notOneOf([-1, 0], t(ETLCodes.Required)),
      title: Yup.string()
        .required(t(ETLCodes.Required))
        .max(100, t(ETLCodes.MaxCharacterTitle))
    });
  }, [t]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={editMode}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.clause)}
      onDelete={deleteItem}
      showDeleteButton={+idClause > 0}
      saving={saving}
      deleting={deleting}
    >
      <FieldGroup columns={2}>
        <FGWalterSelectInput name="idstade" loading={loadingStade} label={t(ETLCodes.Stade)} items={stade} />
        <FGEmpty />
        <FGTextInput name="title" label={t(ETLCodes.Title)} maxLength={100} forceCase="upper" />
        <FGEmpty />
        <FGTextAreaInput name="description" label={t(ETLCodes.Description)} />
        <FGEmpty />
        <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
