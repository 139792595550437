import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ImpressionApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { ImpressionDetailPage } from "./ImpressionDetailPage";
import { ImpressionPermissionsPage } from "./permissions/ImpressionPermissionsPage";

export interface IImpressionItemPageProps {}

export const ImpressionItemPage: React.FunctionComponent<IImpressionItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const api = useApiService(ImpressionApi);

  const [displayName] = useApiEffect(() => api.impressionGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase breadCrumbs={[{ text: t(ETLCodes.Impressions), route: ERoutes.impressions }, { text: displayName }]}>
      <ProtectedTabs
        id="TabsExport"
        onChange={newTabId => history.push(`${ERoutes.impressions}/${id}/${newTabId}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.impressions} />
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<ImpressionDetailPage idImpression={+id} editMode={state === "edit"} />}
        />
        <Tab
          id="permission"
          title={t(ETLCodes.Permissions)}
          panel={<ImpressionPermissionsPage idImpression={+id}></ImpressionPermissionsPage>}
          disabled={+id === 0}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
