/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbPaiementDto,
  FcbPaiementGridDtoPaginatedResults,
  FilterCriteriaInfo,
  PaiementSearch,
  SelectItem,
} from '../models/index';
import {
    FcbPaiementDtoFromJSON,
    FcbPaiementDtoToJSON,
    FcbPaiementGridDtoPaginatedResultsFromJSON,
    FcbPaiementGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    PaiementSearchFromJSON,
    PaiementSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface PaiementBaseSearchRequest {
    PaiementSearch?: PaiementSearch;
}

export interface PaiementDeletePaiementRequest {
    id?: number;
}

export interface PaiementGetPaiementRequest {
    id?: number;
}

export interface PaiementGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface PaiementGetSelectItemsRequest {
    searchField: string;
}

export interface PaiementSavePaiementRequest {
    FcbPaiementDto?: FcbPaiementDto;
}

/**
 * 
 */
export class PaiementApi extends runtime.BaseAPI {

    /**
     */
    async paiementBaseSearchRaw(requestParameters: PaiementBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbPaiementGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Paiement/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaiementSearchToJSON(requestParameters.PaiementSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbPaiementGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async paiementBaseSearch(requestParameters: PaiementBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbPaiementGridDtoPaginatedResults> {
        const response = await this.paiementBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async paiementDeletePaiementRaw(requestParameters: PaiementDeletePaiementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbPaiementDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Paiement/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbPaiementDtoFromJSON(jsonValue));
    }

    /**
     */
    async paiementDeletePaiement(requestParameters: PaiementDeletePaiementRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbPaiementDto> {
        const response = await this.paiementDeletePaiementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async paiementGetPaiementRaw(requestParameters: PaiementGetPaiementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbPaiementDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Paiement/get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbPaiementDtoFromJSON(jsonValue));
    }

    /**
     */
    async paiementGetPaiement(requestParameters: PaiementGetPaiementRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbPaiementDto> {
        const response = await this.paiementGetPaiementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async paiementGetSearchCriteriasRaw(requestParameters: PaiementGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Paiement/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async paiementGetSearchCriterias(requestParameters: PaiementGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.paiementGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async paiementGetSelectItemsRaw(requestParameters: PaiementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling paiementGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Paiement/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async paiementGetSelectItems(requestParameters: PaiementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.paiementGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async paiementSavePaiementRaw(requestParameters: PaiementSavePaiementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbPaiementDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Paiement/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbPaiementDtoToJSON(requestParameters.FcbPaiementDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbPaiementDtoFromJSON(jsonValue));
    }

    /**
     */
    async paiementSavePaiement(requestParameters: PaiementSavePaiementRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbPaiementDto> {
        const response = await this.paiementSavePaiementRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
