/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApprenantHistorySearch,
  FcbApprenantHistoryGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    ApprenantHistorySearchFromJSON,
    ApprenantHistorySearchToJSON,
    FcbApprenantHistoryGridDtoPaginatedResultsFromJSON,
    FcbApprenantHistoryGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface ApprenantHistoryBaseSearchRequest {
    ApprenantHistorySearch?: ApprenantHistorySearch;
}

export interface ApprenantHistoryGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ApprenantHistoryGetSelectItemsRequest {
    searchField: string;
}

/**
 * 
 */
export class ApprenantHistoryApi extends runtime.BaseAPI {

    /**
     */
    async apprenantHistoryBaseSearchRaw(requestParameters: ApprenantHistoryBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbApprenantHistoryGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantHistory/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApprenantHistorySearchToJSON(requestParameters.ApprenantHistorySearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbApprenantHistoryGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async apprenantHistoryBaseSearch(requestParameters: ApprenantHistoryBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbApprenantHistoryGridDtoPaginatedResults> {
        const response = await this.apprenantHistoryBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantHistoryGetSearchCriteriasRaw(requestParameters: ApprenantHistoryGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantHistory/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async apprenantHistoryGetSearchCriterias(requestParameters: ApprenantHistoryGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.apprenantHistoryGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantHistoryGetSelectItemsRaw(requestParameters: ApprenantHistoryGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling apprenantHistoryGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantHistory/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async apprenantHistoryGetSelectItems(requestParameters: ApprenantHistoryGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.apprenantHistoryGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
