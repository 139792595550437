/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbSuiviApprenantDetailsDto,
  FcbSuiviApprenantGridDtoPaginatedResults,
  FileDownloadDto,
  FilterCriteriaInfo,
  SelectItem,
  SuiviApprenantFichierDto,
  SuiviApprenantFichierUploadDto,
  SuiviApprenantSearch,
} from '../models/index';
import {
    FcbSuiviApprenantDetailsDtoFromJSON,
    FcbSuiviApprenantDetailsDtoToJSON,
    FcbSuiviApprenantGridDtoPaginatedResultsFromJSON,
    FcbSuiviApprenantGridDtoPaginatedResultsToJSON,
    FileDownloadDtoFromJSON,
    FileDownloadDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    SuiviApprenantFichierDtoFromJSON,
    SuiviApprenantFichierDtoToJSON,
    SuiviApprenantFichierUploadDtoFromJSON,
    SuiviApprenantFichierUploadDtoToJSON,
    SuiviApprenantSearchFromJSON,
    SuiviApprenantSearchToJSON,
} from '../models/index';

export interface SuiviApprenantBaseSearchRequest {
    SuiviApprenantSearch?: SuiviApprenantSearch;
}

export interface SuiviApprenantDeleteRequest {
    id?: number;
}

export interface SuiviApprenantDeleteSuiviApprenantFichierRequest {
    id?: number;
}

export interface SuiviApprenantDownloadSuiviApprenantFichierRequest {
    id?: number;
}

export interface SuiviApprenantGetRequest {
    id?: number;
}

export interface SuiviApprenantGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface SuiviApprenantGetSelectItemsRequest {
    searchField: string;
}

export interface SuiviApprenantGetSuiviApprenantFichierRequest {
    id?: number;
}

export interface SuiviApprenantSaveRequest {
    FcbSuiviApprenantDetailsDto?: FcbSuiviApprenantDetailsDto;
}

export interface SuiviApprenantSaveSuiviApprenantFichierRequest {
    SuiviApprenantFichierDto?: SuiviApprenantFichierDto;
}

export interface SuiviApprenantUploadSuiviApprenantFichierRequest {
    SuiviApprenantFichierUploadDto?: SuiviApprenantFichierUploadDto;
}

/**
 * 
 */
export class SuiviApprenantApi extends runtime.BaseAPI {

    /**
     */
    async suiviApprenantBaseSearchRaw(requestParameters: SuiviApprenantBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbSuiviApprenantGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SuiviApprenant/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SuiviApprenantSearchToJSON(requestParameters.SuiviApprenantSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbSuiviApprenantGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async suiviApprenantBaseSearch(requestParameters: SuiviApprenantBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbSuiviApprenantGridDtoPaginatedResults> {
        const response = await this.suiviApprenantBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async suiviApprenantDeleteRaw(requestParameters: SuiviApprenantDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SuiviApprenant`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async suiviApprenantDelete(requestParameters: SuiviApprenantDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.suiviApprenantDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async suiviApprenantDeleteSuiviApprenantFichierRaw(requestParameters: SuiviApprenantDeleteSuiviApprenantFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SuiviApprenant/DeleteSuiviApprenantFichier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async suiviApprenantDeleteSuiviApprenantFichier(requestParameters: SuiviApprenantDeleteSuiviApprenantFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.suiviApprenantDeleteSuiviApprenantFichierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async suiviApprenantDownloadSuiviApprenantFichierRaw(requestParameters: SuiviApprenantDownloadSuiviApprenantFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SuiviApprenant/DownloadFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async suiviApprenantDownloadSuiviApprenantFichier(requestParameters: SuiviApprenantDownloadSuiviApprenantFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.suiviApprenantDownloadSuiviApprenantFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async suiviApprenantGetRaw(requestParameters: SuiviApprenantGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbSuiviApprenantDetailsDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SuiviApprenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbSuiviApprenantDetailsDtoFromJSON(jsonValue));
    }

    /**
     */
    async suiviApprenantGet(requestParameters: SuiviApprenantGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbSuiviApprenantDetailsDto> {
        const response = await this.suiviApprenantGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async suiviApprenantGetSearchCriteriasRaw(requestParameters: SuiviApprenantGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SuiviApprenant/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async suiviApprenantGetSearchCriterias(requestParameters: SuiviApprenantGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.suiviApprenantGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async suiviApprenantGetSelectItemsRaw(requestParameters: SuiviApprenantGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling suiviApprenantGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SuiviApprenant/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async suiviApprenantGetSelectItems(requestParameters: SuiviApprenantGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.suiviApprenantGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async suiviApprenantGetSuiviApprenantFichierRaw(requestParameters: SuiviApprenantGetSuiviApprenantFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuiviApprenantFichierDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SuiviApprenant/GetFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuiviApprenantFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async suiviApprenantGetSuiviApprenantFichier(requestParameters: SuiviApprenantGetSuiviApprenantFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuiviApprenantFichierDto> {
        const response = await this.suiviApprenantGetSuiviApprenantFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async suiviApprenantSaveRaw(requestParameters: SuiviApprenantSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbSuiviApprenantDetailsDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SuiviApprenant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbSuiviApprenantDetailsDtoToJSON(requestParameters.FcbSuiviApprenantDetailsDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbSuiviApprenantDetailsDtoFromJSON(jsonValue));
    }

    /**
     */
    async suiviApprenantSave(requestParameters: SuiviApprenantSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbSuiviApprenantDetailsDto> {
        const response = await this.suiviApprenantSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async suiviApprenantSaveSuiviApprenantFichierRaw(requestParameters: SuiviApprenantSaveSuiviApprenantFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuiviApprenantFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SuiviApprenant/SaveFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SuiviApprenantFichierDtoToJSON(requestParameters.SuiviApprenantFichierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuiviApprenantFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async suiviApprenantSaveSuiviApprenantFichier(requestParameters: SuiviApprenantSaveSuiviApprenantFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuiviApprenantFichierDto> {
        const response = await this.suiviApprenantSaveSuiviApprenantFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async suiviApprenantUploadSuiviApprenantFichierRaw(requestParameters: SuiviApprenantUploadSuiviApprenantFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuiviApprenantFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SuiviApprenant/UploadSuiviApprenantFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SuiviApprenantFichierUploadDtoToJSON(requestParameters.SuiviApprenantFichierUploadDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuiviApprenantFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async suiviApprenantUploadSuiviApprenantFichier(requestParameters: SuiviApprenantUploadSuiviApprenantFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuiviApprenantFichierDto> {
        const response = await this.suiviApprenantUploadSuiviApprenantFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
