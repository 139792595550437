import { DataTable, IDataTableColumn, useGridState } from "nsitools-react";
import * as React from "react";
import { FcbResultatTypeGridDto } from "../../../../../api";
import { ETLCodes } from "../../../../../locales";
import { useTl } from "../../../../../hooks";
import { css } from "styled-components";
import { Colors } from "@blueprintjs/core";

export interface IDeliberationBulletinCaterorySumGridProps {
  deliberation1SessResults: FcbResultatTypeGridDto[];
  loading: boolean;
}

export const DeliberationBulletinCaterorySumGrid: React.FunctionComponent<IDeliberationBulletinCaterorySumGridProps> = ({
  deliberation1SessResults,
  loading
}) => {
  const { t } = useTl();
  const tableState = useGridState<any>({
    serverMode: false,
    enablePagination: false,
    enableFilter: false,
    availablePageSizes: [9999],
    pageSize: 9999,
    sortKeys: { categorie: "ASC" }
  });

  const { setData } = tableState;

  React.useEffect(() => {
    if (deliberation1SessResults) {
      var result = [];
      deliberation1SessResults.reduce((res, value) => {
        if (!res[value.categorie]) {
          res[value.categorie] = { categorie: value.categorie, bulletintotal: 0, totaldenommax: 0 };
          result.push(res[value.categorie]);
        }
        res[value.categorie].bulletintotal += value.bulletintotal;
        res[value.categorie].totaldenommax += value.totaldenommax;
        return res;
      }, {});
      setData(result);
    }
  }, [deliberation1SessResults, setData]);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.Categorie),
        fieldName: "categorie"
      },
      {
        header: () => t(ETLCodes.Total),
        fieldName: "bulletintotal",
        render: (item: FcbResultatTypeGridDto) => `${item.bulletintotal}/${item.totaldenommax}`
      },
      {
        header: () => t(ETLCodes.TotalPercent),
        fieldName: "totalpercent",
        render: (item: FcbResultatTypeGridDto) =>
          `${
            item.totaldenommax > 0
              ? ((item.bulletintotal * 100) / item.totaldenommax).toFixed(2)
              : item.bulletintotal.toFixed(2)
          } %`
      }
    ],
    [t]
  );

  const getRowStyles = React.useCallback((item: FcbResultatTypeGridDto) => {
    const total =
      item.totaldenommax > 0
        ? ((item.bulletintotal * 100) / item.totaldenommax).toFixed(2)
        : item.bulletintotal.toFixed(2);
    return css`
      & * {
        color: ${+total < 50 ? Colors.RED3 + " !important" : null};
      }
    `;
  }, []);

  return (
    <DataTable
      dateFormat="dd-MM-yyyy"
      tableState={tableState}
      loading={loading}
      columns={columns}
      customizeRowStyle={getRowStyles}
    />
  );
};
