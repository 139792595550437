/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbFormateurSuiviDto,
  FcbFormateurSuiviGridDtoPaginatedResults,
  FileDownloadDto,
  FilterCriteriaInfo,
  FormateurSuiviFichierDto,
  FormateurSuiviFichierUploadDto,
  FormateurSuiviSearch,
  SelectItem,
} from '../models/index';
import {
    FcbFormateurSuiviDtoFromJSON,
    FcbFormateurSuiviDtoToJSON,
    FcbFormateurSuiviGridDtoPaginatedResultsFromJSON,
    FcbFormateurSuiviGridDtoPaginatedResultsToJSON,
    FileDownloadDtoFromJSON,
    FileDownloadDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    FormateurSuiviFichierDtoFromJSON,
    FormateurSuiviFichierDtoToJSON,
    FormateurSuiviFichierUploadDtoFromJSON,
    FormateurSuiviFichierUploadDtoToJSON,
    FormateurSuiviSearchFromJSON,
    FormateurSuiviSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface FormateurSuiviBaseSearchRequest {
    FormateurSuiviSearch?: FormateurSuiviSearch;
}

export interface FormateurSuiviDeleteRequest {
    id?: number;
}

export interface FormateurSuiviDeleteFormateurSuiviFichierRequest {
    id?: number;
}

export interface FormateurSuiviDownloadFormateurSuiviFichierRequest {
    id?: number;
}

export interface FormateurSuiviGetRequest {
    id?: number;
}

export interface FormateurSuiviGetFormateurSuiviFichierRequest {
    id?: number;
}

export interface FormateurSuiviGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface FormateurSuiviGetSelectItemsRequest {
    searchField: string;
}

export interface FormateurSuiviSaveRequest {
    FcbFormateurSuiviDto?: FcbFormateurSuiviDto;
}

export interface FormateurSuiviSaveFormateurSuiviFichierRequest {
    FormateurSuiviFichierDto?: FormateurSuiviFichierDto;
}

export interface FormateurSuiviUploadFormateurSuiviFichierRequest {
    FormateurSuiviFichierUploadDto?: FormateurSuiviFichierUploadDto;
}

/**
 * 
 */
export class FormateurSuiviApi extends runtime.BaseAPI {

    /**
     */
    async formateurSuiviBaseSearchRaw(requestParameters: FormateurSuiviBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurSuiviGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurSuivi/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormateurSuiviSearchToJSON(requestParameters.FormateurSuiviSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurSuiviGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async formateurSuiviBaseSearch(requestParameters: FormateurSuiviBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurSuiviGridDtoPaginatedResults> {
        const response = await this.formateurSuiviBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurSuiviDeleteRaw(requestParameters: FormateurSuiviDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurSuivi`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async formateurSuiviDelete(requestParameters: FormateurSuiviDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.formateurSuiviDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurSuiviDeleteFormateurSuiviFichierRaw(requestParameters: FormateurSuiviDeleteFormateurSuiviFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurSuivi/DeleteFormateurSuiviFichier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async formateurSuiviDeleteFormateurSuiviFichier(requestParameters: FormateurSuiviDeleteFormateurSuiviFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.formateurSuiviDeleteFormateurSuiviFichierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async formateurSuiviDownloadFormateurSuiviFichierRaw(requestParameters: FormateurSuiviDownloadFormateurSuiviFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurSuivi/DownloadFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurSuiviDownloadFormateurSuiviFichier(requestParameters: FormateurSuiviDownloadFormateurSuiviFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.formateurSuiviDownloadFormateurSuiviFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurSuiviGetRaw(requestParameters: FormateurSuiviGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurSuiviDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurSuivi`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurSuiviDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurSuiviGet(requestParameters: FormateurSuiviGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurSuiviDto> {
        const response = await this.formateurSuiviGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurSuiviGetFormateurSuiviFichierRaw(requestParameters: FormateurSuiviGetFormateurSuiviFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormateurSuiviFichierDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurSuivi/GetFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormateurSuiviFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurSuiviGetFormateurSuiviFichier(requestParameters: FormateurSuiviGetFormateurSuiviFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormateurSuiviFichierDto> {
        const response = await this.formateurSuiviGetFormateurSuiviFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurSuiviGetSearchCriteriasRaw(requestParameters: FormateurSuiviGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurSuivi/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async formateurSuiviGetSearchCriterias(requestParameters: FormateurSuiviGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.formateurSuiviGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurSuiviGetSelectItemsRaw(requestParameters: FormateurSuiviGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling formateurSuiviGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurSuivi/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async formateurSuiviGetSelectItems(requestParameters: FormateurSuiviGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.formateurSuiviGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurSuiviSaveRaw(requestParameters: FormateurSuiviSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFormateurSuiviDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurSuivi`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbFormateurSuiviDtoToJSON(requestParameters.FcbFormateurSuiviDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFormateurSuiviDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurSuiviSave(requestParameters: FormateurSuiviSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFormateurSuiviDto> {
        const response = await this.formateurSuiviSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurSuiviSaveFormateurSuiviFichierRaw(requestParameters: FormateurSuiviSaveFormateurSuiviFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormateurSuiviFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurSuivi/SaveFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormateurSuiviFichierDtoToJSON(requestParameters.FormateurSuiviFichierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormateurSuiviFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurSuiviSaveFormateurSuiviFichier(requestParameters: FormateurSuiviSaveFormateurSuiviFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormateurSuiviFichierDto> {
        const response = await this.formateurSuiviSaveFormateurSuiviFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurSuiviUploadFormateurSuiviFichierRaw(requestParameters: FormateurSuiviUploadFormateurSuiviFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormateurSuiviFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurSuivi/UploadFormateurSuiviFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormateurSuiviFichierUploadDtoToJSON(requestParameters.FormateurSuiviFichierUploadDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormateurSuiviFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async formateurSuiviUploadFormateurSuiviFichier(requestParameters: FormateurSuiviUploadFormateurSuiviFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormateurSuiviFichierDto> {
        const response = await this.formateurSuiviUploadFormateurSuiviFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
