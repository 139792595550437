import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import { ApprenantValidationCEDetailPage } from "./ApprenantValidationCEDetailPage";
import { ApprenantValidationCEListPage } from "./ApprenantValidationCEListPage";

interface IApprenantValidationCEProps {
  apprenantId: number;
}

export const ApprenantValidationCE: React.FunctionComponent<IApprenantValidationCEProps> = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`} component={ApprenantValidationCEListPage} exact />
      <Route path={`${match.path}/:idValidation/:state`} component={ApprenantValidationCEDetailPage} />
    </Switch>
  );
};
