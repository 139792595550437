/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParcoursFormationGridDto
 */
export interface ParcoursFormationGridDto {
    /**
     * 
     * @type {number}
     * @memberof ParcoursFormationGridDto
     */
    idParcoursFormation?: number;
    /**
     * 
     * @type {Date}
     * @memberof ParcoursFormationGridDto
     */
    dateEntree?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ParcoursFormationGridDto
     */
    statutEntree?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParcoursFormationGridDto
     */
    catStatutEntree?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ParcoursFormationGridDto
     */
    dateSortie?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ParcoursFormationGridDto
     */
    statutSortie?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParcoursFormationGridDto
     */
    catStatutSortie?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParcoursFormationGridDto
     */
    motifSortie?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ParcoursFormationGridDto
     */
    modificationDate?: Date | null;
}

/**
 * Check if a given object implements the ParcoursFormationGridDto interface.
 */
export function instanceOfParcoursFormationGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ParcoursFormationGridDtoFromJSON(json: any): ParcoursFormationGridDto {
    return ParcoursFormationGridDtoFromJSONTyped(json, false);
}

export function ParcoursFormationGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParcoursFormationGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idParcoursFormation': !exists(json, 'idParcoursFormation') ? undefined : json['idParcoursFormation'],
        'dateEntree': !exists(json, 'dateEntree') ? undefined : (json['dateEntree'] === null ? null : new Date(json['dateEntree'])),
        'statutEntree': !exists(json, 'statutEntree') ? undefined : json['statutEntree'],
        'catStatutEntree': !exists(json, 'catStatutEntree') ? undefined : json['catStatutEntree'],
        'dateSortie': !exists(json, 'dateSortie') ? undefined : (json['dateSortie'] === null ? null : new Date(json['dateSortie'])),
        'statutSortie': !exists(json, 'statutSortie') ? undefined : json['statutSortie'],
        'catStatutSortie': !exists(json, 'catStatutSortie') ? undefined : json['catStatutSortie'],
        'motifSortie': !exists(json, 'motifSortie') ? undefined : json['motifSortie'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
    };
}

export function ParcoursFormationGridDtoToJSON(value?: ParcoursFormationGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idParcoursFormation': value.idParcoursFormation,
        'dateEntree': value.dateEntree === undefined ? undefined : (value.dateEntree === null ? null : value.dateEntree.toISOString()),
        'statutEntree': value.statutEntree,
        'catStatutEntree': value.catStatutEntree,
        'dateSortie': value.dateSortie === undefined ? undefined : (value.dateSortie === null ? null : value.dateSortie.toISOString()),
        'statutSortie': value.statutSortie,
        'catStatutSortie': value.catStatutSortie,
        'motifSortie': value.motifSortie,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
    };
}

