/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DashboardAbsencesVuReturnDto
 */
export interface DashboardAbsencesVuReturnDto {
    /**
     * 
     * @type {number}
     * @memberof DashboardAbsencesVuReturnDto
     */
    idapprenant?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardAbsencesVuReturnDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardAbsencesVuReturnDto
     */
    emailIfapme?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardAbsencesVuReturnDto
     */
    emailPerso?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardAbsencesVuReturnDto
     */
    idrapportHope?: number | null;
}

/**
 * Check if a given object implements the DashboardAbsencesVuReturnDto interface.
 */
export function instanceOfDashboardAbsencesVuReturnDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DashboardAbsencesVuReturnDtoFromJSON(json: any): DashboardAbsencesVuReturnDto {
    return DashboardAbsencesVuReturnDtoFromJSONTyped(json, false);
}

export function DashboardAbsencesVuReturnDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardAbsencesVuReturnDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'emailIfapme': !exists(json, 'emailIfapme') ? undefined : json['emailIfapme'],
        'emailPerso': !exists(json, 'emailPerso') ? undefined : json['emailPerso'],
        'idrapportHope': !exists(json, 'idrapportHope') ? undefined : json['idrapportHope'],
    };
}

export function DashboardAbsencesVuReturnDtoToJSON(value?: DashboardAbsencesVuReturnDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idapprenant': value.idapprenant,
        'anneeScolaire': value.anneeScolaire,
        'emailIfapme': value.emailIfapme,
        'emailPerso': value.emailPerso,
        'idrapportHope': value.idrapportHope,
    };
}

