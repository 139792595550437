/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileUploadDto } from './FileUploadDto';
import {
    FileUploadDtoFromJSON,
    FileUploadDtoFromJSONTyped,
    FileUploadDtoToJSON,
} from './FileUploadDto';

/**
 * 
 * @export
 * @interface LieuFormationAgrementDemandeSectorielleMultipleDto
 */
export interface LieuFormationAgrementDemandeSectorielleMultipleDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof LieuFormationAgrementDemandeSectorielleMultipleDto
     */
    idslieuFormationAgrement?: Array<number> | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementDemandeSectorielleMultipleDto
     */
    dateEnvoiD1?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementDemandeSectorielleMultipleDto
     */
    dateReceptionAvis?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementDemandeSectorielleMultipleDto
     */
    idagrementAvis?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationAgrementDemandeSectorielleMultipleDto
     */
    remarqueSectorielle?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementDemandeSectorielleMultipleDto
     */
    idfichierDemandeSectorielle?: number | null;
    /**
     * 
     * @type {FileUploadDto}
     * @memberof LieuFormationAgrementDemandeSectorielleMultipleDto
     */
    fichierDemandeSectorielle?: FileUploadDto;
}

/**
 * Check if a given object implements the LieuFormationAgrementDemandeSectorielleMultipleDto interface.
 */
export function instanceOfLieuFormationAgrementDemandeSectorielleMultipleDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LieuFormationAgrementDemandeSectorielleMultipleDtoFromJSON(json: any): LieuFormationAgrementDemandeSectorielleMultipleDto {
    return LieuFormationAgrementDemandeSectorielleMultipleDtoFromJSONTyped(json, false);
}

export function LieuFormationAgrementDemandeSectorielleMultipleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LieuFormationAgrementDemandeSectorielleMultipleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idslieuFormationAgrement': !exists(json, 'idslieuFormationAgrement') ? undefined : json['idslieuFormationAgrement'],
        'dateEnvoiD1': !exists(json, 'dateEnvoiD1') ? undefined : (json['dateEnvoiD1'] === null ? null : new Date(json['dateEnvoiD1'])),
        'dateReceptionAvis': !exists(json, 'dateReceptionAvis') ? undefined : (json['dateReceptionAvis'] === null ? null : new Date(json['dateReceptionAvis'])),
        'idagrementAvis': !exists(json, 'idagrementAvis') ? undefined : json['idagrementAvis'],
        'remarqueSectorielle': !exists(json, 'remarqueSectorielle') ? undefined : json['remarqueSectorielle'],
        'idfichierDemandeSectorielle': !exists(json, 'idfichierDemandeSectorielle') ? undefined : json['idfichierDemandeSectorielle'],
        'fichierDemandeSectorielle': !exists(json, 'fichierDemandeSectorielle') ? undefined : FileUploadDtoFromJSON(json['fichierDemandeSectorielle']),
    };
}

export function LieuFormationAgrementDemandeSectorielleMultipleDtoToJSON(value?: LieuFormationAgrementDemandeSectorielleMultipleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idslieuFormationAgrement': value.idslieuFormationAgrement,
        'dateEnvoiD1': value.dateEnvoiD1 === undefined ? undefined : (value.dateEnvoiD1 === null ? null : value.dateEnvoiD1.toISOString()),
        'dateReceptionAvis': value.dateReceptionAvis === undefined ? undefined : (value.dateReceptionAvis === null ? null : value.dateReceptionAvis.toISOString()),
        'idagrementAvis': value.idagrementAvis,
        'remarqueSectorielle': value.remarqueSectorielle,
        'idfichierDemandeSectorielle': value.idfichierDemandeSectorielle,
        'fichierDemandeSectorielle': FileUploadDtoToJSON(value.fichierDemandeSectorielle),
    };
}

