import { Icon, Spinner } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  & > .title {
    font-weight: bold;
    color: black;
  }
`;

const Item = styled.div<{ color: string }>`
  color: ${props => props.color};
`;

export interface BulletItem {
  text: string;
  color?: string;
  hideBullet?: boolean;
}

export interface ICustomBulletListProps {
  items: BulletItem[];
  title?: string;
  loading?: boolean;
}

export const CustomBulletList: React.FunctionComponent<ICustomBulletListProps> = ({ items, title, loading }) => {
  return (
    <Container>
      {!!title && <span className="title">{title}</span>}
      {loading ? (
        <Spinner size={20} />
      ) : (
        items.map(i => (
          <Item color={i.color ?? "black"}>
            {!i.hideBullet && <Icon icon="dot" />}
            {i.text}
          </Item>
        ))
      )}
    </Container>
  );
};
