/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ETypeContrat {
    CC = 'CC',
    CA = 'CA',
    CS = 'CS',
    PP = 'PP',
    CF = 'CF',
    DM = 'DM',
    JI = 'JI',
    SO = 'SO',
    CCE = 'CCE',
    CSE = 'CSE'
}


export function ETypeContratFromJSON(json: any): ETypeContrat {
    return ETypeContratFromJSONTyped(json, false);
}

export function ETypeContratFromJSONTyped(json: any, ignoreDiscriminator: boolean): ETypeContrat {
    return json as ETypeContrat;
}

export function ETypeContratToJSON(value?: ETypeContrat | null): any {
    return value as any;
}

