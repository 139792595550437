import { AccountInfo, AuthenticationResult, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { useLocalStorageState, useSessionStorageState } from "ahooks";
import * as React from "react";
import { useHistory, useLocation } from "react-router";

import { Configuration, UserActionDto, UserProfileDto, UsersApi } from "../api";
import { apiConfiguration } from "../apiConfig";
import { ERoutes } from "../AppRouter";
import { useFrontendSettings, useSystemInfo } from "../hooks";

interface IAuthProviderProps {}

interface IAuthContext {
  logout: (reason?: string) => void;
  isLoading: boolean;
  user?: UserProfileDto;
  logoutReason?: string;
  authenticationFailed: boolean;
  matchModule: (module: string) => boolean;
  matchTab: (tab: string) => boolean;
  userPermissions: UserActionDto[];
  getCurrentPermission: () => UserActionDto;
  azureAdAccount: AccountInfo;
  hasPermission: (module: string, permission: "R" | "RW" | "RWD", tab?: string) => boolean;
  getAccessToken: () => Promise<string>;
  reloadUser: () => Promise<void>;
}

export type Permission = {
  module: string;
  tab: string;
  right: "R" | "RW" | "RWD";
};

const AuthContext = React.createContext<IAuthContext>(null);

const AuthProvider: React.FunctionComponent<IAuthProviderProps> = ({ children }) => {
  // Load from localstorage
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [logoutReason, setLogoutReason] = React.useState<string>();
  const [userProfile, setUserProfile] = React.useState<UserProfileDto>(null);
  const userPermissions = React.useMemo(() => userProfile?.actions || null, [userProfile?.actions]);
  const [authenticationFailed, setAuthenticationFailed] = React.useState(false);

  const { pathname } = useLocation();
  const { frontendSettings } = useFrontendSettings();
  const [lastAccountName, setLastAccountName] = useLocalStorageState<string>("account_name");
  const msalInstance = React.useMemo(() => {
    if (frontendSettings) {
      return new PublicClientApplication({
        auth: {
          clientId: frontendSettings.azureClientId,
          authority: frontendSettings.azureAuthority
        },
        cache: {
          cacheLocation: "localStorage", // This configures where your cache will be stored
          storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
        },
        system: {
          loggerOptions: {
            loggerCallback: (logLevel, message) => {
              // eslint-disable-next-line no-console
              console.log("[MSAL]", message);
            },
            piiLoggingEnabled: true,
            logLevel: LogLevel.Warning
          }
        }
      });
    }
  }, [frontendSettings]);

  const [azureAdAccount, setAzureAdAccount] = React.useState<AccountInfo>();
  const [, setRedirectUri] = useSessionStorageState<string>("redirect_uri");

  React.useEffect(() => {
    if (userPermissions && userPermissions.length === 0) {
      history.push(`${ERoutes.unauthorized}`);
    }
  }, [history, userPermissions]);

  const matchModule = React.useCallback(
    (module: string) => {
      if (!userPermissions) return false;
      return userPermissions?.some(p => p.module.toUpperCase() === module.toUpperCase());
    },
    [userPermissions]
  );

  const matchTab = React.useCallback(
    (tab: string) => {
      if (!userPermissions) return false;
      const module = pathname.split("/")[1].toUpperCase();
      return userPermissions?.some(
        p => (!!p.tab || p.tab === "") && p.module.toUpperCase() === module && p.tab.toUpperCase() === tab.toUpperCase()
      );
    },
    [pathname, userPermissions]
  );

  const getCurrentPermission = React.useCallback(() => {
    let permission = null;
    if (!userPermissions) return null;
    const module = pathname.split("/")[1].toUpperCase();
    permission =
      userPermissions?.find(
        p => p.module.toUpperCase() === module.toUpperCase() && (p.tab === "SEARCH" || p.tab === "")
      ) ?? userPermissions?.find(p => p.module.toUpperCase() === module.toUpperCase());
    if (pathname.split("/").length > 4) {
      let tab = null;
      let idFound = false;
      for (let part of pathname.split("/").splice(2)) {
        if (isNaN(+part) && idFound) {
          tab = part.toUpperCase();
          break;
        }
        if (!isNaN(+part) || part.length <= 3) idFound = true;
      }
      permission = userPermissions?.find(
        p => p.tab && p.module?.toUpperCase() === module && p.tab?.toUpperCase() === tab?.toUpperCase()
      );
    }
    return permission;
  }, [pathname, userPermissions]);

  const hasPermission = React.useCallback(
    (module: string, permission: "R" | "RW" | "RWD", tab: string = "") => {
      if (!userPermissions) return false;
      const actions = userPermissions?.find(
        p => p.module.toUpperCase() === module.toUpperCase() && p.tab.toUpperCase() === tab.toUpperCase()
      );
      return !!actions && actions.permission.toUpperCase().includes(permission);
    },
    [userPermissions]
  );

  const loginProcess = React.useCallback(() => {
    const previousPath = location.pathname;
    return msalInstance
      .handleRedirectPromise()
      .then(redirectResponse => {
        if (redirectResponse !== null) {
          setAzureAdAccount(redirectResponse.account);
          setLastAccountName(redirectResponse.account.username);
        } else {
          setRedirectUri(previousPath);
          const account = msalInstance.getAccountByUsername(lastAccountName);
          if (account) {
            console.log("Local account found ...", account);

            setAzureAdAccount(account);
          } else {
            console.log("No account found, login with azure ad ! ");

            void msalInstance.loginRedirect({
              scopes: [frontendSettings?.azureApiScope],
              loginHint: lastAccountName
            });
          }
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, [
    frontendSettings?.azureApiScope,
    lastAccountName,
    location.pathname,
    msalInstance,
    setLastAccountName,
    setRedirectUri
  ]);

  React.useEffect(() => {
    if (msalInstance) {
      if (!azureAdAccount) {
        void loginProcess();
      }
    }
  }, [azureAdAccount, loginProcess, msalInstance]);

  const getAccessToken = React.useCallback(async () => {
    let refreshedToken: AuthenticationResult;
    try {
      if (azureAdAccount) {
        refreshedToken = await msalInstance.acquireTokenSilent({
          account: azureAdAccount,
          scopes: [frontendSettings.azureApiScope]
        });
        return "Bearer " + refreshedToken.accessToken;
      }
    } catch (error) {
      console.error("Unable to get token silently", error);
      await msalInstance.loginRedirect({
        scopes: [frontendSettings?.azureApiScope],
        loginHint: lastAccountName
      });
    }

    return null;
  }, [azureAdAccount, lastAccountName, frontendSettings, msalInstance]);

  const { ifapmeSide } = useSystemInfo();

  const loadUser = React.useCallback(async () => {
    if (!ifapmeSide) return;

    const api = new UsersApi(new Configuration(apiConfiguration(getAccessToken, ifapmeSide)));
    try {
      const userProfile = await api.usersAuthenticate();
      setUserProfile(userProfile);
    } catch (error) {
      console.error(error);
      setAuthenticationFailed(true);
    } finally {
      setIsLoading(false);
    }
  }, [getAccessToken, ifapmeSide]);

  const logout = React.useCallback(
    async (reason?: string) => {
      setLogoutReason(reason);

      const api = new UsersApi(new Configuration(apiConfiguration(getAccessToken, ifapmeSide)));

      if (userProfile?.impersonator) {
        await api.usersLogoutImpersonate();
        await loadUser();
        history.replace(ERoutes.home);
      } else {
        await api.usersLogout();
        await msalInstance.logoutRedirect({
          account: azureAdAccount
        });

        setAzureAdAccount(null);
        setUserProfile(null);
      }
    },
    [azureAdAccount, getAccessToken, history, ifapmeSide, loadUser, msalInstance, userProfile]
  );

  React.useEffect(() => {
    if (azureAdAccount) {
      console.log("Azure ad account ready, getting user profile...");
      void loadUser();
    }
  }, [azureAdAccount, loadUser]);

  return (
    <AuthContext.Provider
      value={{
        logout,
        user: userProfile,
        isLoading,
        logoutReason,
        authenticationFailed,
        matchModule,
        matchTab,
        userPermissions,
        getCurrentPermission,
        hasPermission,
        azureAdAccount,
        getAccessToken,
        reloadUser: loadUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
