import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { StadeApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { StadeDetailPage } from "./StadeDetailPage";

interface IStadeItemPageProps {}

export const StadeItemPage: React.FunctionComponent<IStadeItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const idStade = React.useMemo(() => +id, [id]);
  const history = useHistory();

  const stadeApi = useApiService(StadeApi);
  const [displayName] = useApiEffect(() => stadeApi.stadeGetDisplayName({ id: idStade }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.Stades), route: ERoutes.stade },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="Tabs"
        onChange={newTabId => history.push(`${ERoutes.stade}/${id}/${newTabId}/${state}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.stade} />
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<StadeDetailPage editMode={state === "edit"} idStade={idStade} />}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
