import { FGEmpty, FGNumberInput, FGTextAreaInput, FGTextInput, FieldGroup, FieldSet } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";

import { ClasseApi, FcbClasseDetailDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterDateMaskInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

export interface IClasseAgrementLocalisationFinanceFormProps {}

export const ClasseAgrementLocalisationFinanceForm: React.FunctionComponent<IClasseAgrementLocalisationFinanceFormProps> = () => {
  const { t } = useTl();
  const { id, state } = useParams<{ id: string; state: string }>();
  const api = useApiService(ClasseApi);
  const path = React.useMemo(() => `${ERoutes.classe}/${+id}`, [id]);
  const [conseillerPeda, cpLoading] = useReferential(a => a.referentialGetConseillersPedagogiques(), false, []);
  const [statutLocalisation, slLoading] = useReferential(
    a => a.referentialGetStatutLocalisationAgrement({ isLocalisation: true }),
    false,
    []
  );
  const [statutAgrement, slaLoading] = useReferential(
    a => a.referentialGetStatutLocalisationAgrement({ isLocalisation: false }),
    false,
    []
  );

  const { data, loading, saveItem, saving } = useCrudApi({
    getApiFn: () => (+id <= 0 ? FcbClasseDetailDtoFromJSON({ idclasse: +id }) : api.classeGet({ id: +id })),
    saveApiFn: d => api.classeSave({ FcbClasseDetailDto: d }),
    onSavedRoute: d => `${path}/agrement/${state}`,
    deleteApiFn: () => {},
    onDeletedRoute: () => path
  });

  return (
    data && (
      <FieldSet>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          minLabelWidth={250}
          labelAlignment="right"
          formatDate="dd/MM/yyyy"
          inline
          boldLabel
          loading={loading}
          saving={saving}
        >
          <FieldGroup columns={2}>
            <FGTextInput readonly label={t(ETLCodes.HeureReferentiel)} name="heureReferentiel" />
            <FGEmpty />
            <FGWalterSelectInput
              name="idconseillerLocalisation"
              label={t(ETLCodes.ConseillerPedagogique)}
              items={conseillerPeda}
              loading={cpLoading}
              readonly={data.statutAgrement > 2}
            />
            <FGWalterSelectInput
              name="idconseillerAgrement"
              label={t(ETLCodes.ConseillerPedagogique)}
              items={conseillerPeda}
              loading={cpLoading}
              readonly={data.statutAgrement < 4 || data.statutAgrement === 6 || data.statutAgrement === 10}
            />
            <FGNumberInput
              min={0}
              name="nbapprenantLocalisation"
              label={t(ETLCodes.NombreApprenantPrevu)}
              readonly={data.statutAgrement > 2}
            />
            <FieldGroup columns={2}>
              <FGNumberInput
                min={0}
                name="nbapprenantAgrement"
                label={t(ETLCodes.NombreApprenantReel)}
                readonly={data.statutAgrement < 4 || data.statutAgrement === 6 || data.statutAgrement === 10}
              />
              <FGNumberInput
                name="nbapprenantAgrementCalculated"
                label={t(ETLCodes.NombreApprenantCalcule)}
                formatReadOnlyValue={s => (s ? s : 0)}
                readonly
              />
            </FieldGroup>
            <FGTextAreaInput
              name="remarqueCentre"
              label={t(ETLCodes.RemarqueCentre)}
              readonly={data.statutAgrement > 2}
            />
            <FGTextAreaInput
              name="remarqueCentreAgrement"
              label={t(ETLCodes.RemarqueCentreAgrement)}
              readonly={data.statutAgrement < 4 || data.statutAgrement === 6 || data.statutAgrement === 10}
            />
            <FGTextAreaInput
              name="remarqueConseiller"
              label={t(ETLCodes.RemarqueConseiller)}
              readonly={data.statutAgrement > 2}
            />
            <FGTextAreaInput
              name="remarqueConseillerAgrement"
              label={t(ETLCodes.RemarqueConseillerAgrement)}
              readonly={data.statutAgrement < 4 || data.statutAgrement === 6 || data.statutAgrement === 10}
            />
            <FGTextAreaInput
              name="gestionInterne"
              label={t(ETLCodes.GestionInterne)}
              readonly={data.statutAgrement > 2}
            />
            <FGTextAreaInput
              name="gestionInterneAgrement"
              label={t(ETLCodes.GestionInterneAgrement)}
              readonly={data.statutAgrement < 4 || data.statutAgrement === 6 || data.statutAgrement === 10}
            />
            <FGWalterDateMaskInput name="creationDate" label={t(ETLCodes.DateCreationClasse)} readonly />
            <FGWalterDateMaskInput name="creationDate" label={t(ETLCodes.DateCreationClasse)} readonly />
            <FGWalterDateMaskInput name="dateLocalisation" label={t(ETLCodes.DateLocalisation)} readonly />
            <FGWalterDateMaskInput name="dateAgrement" label={t(ETLCodes.DateAgrement)} readonly />
            <FGWalterSelectInput
              name="idstatutLocalisation"
              label={t(ETLCodes.EtatLocalisation)}
              items={statutLocalisation}
              loading={slLoading}
              readonly
            />
            <FGWalterSelectInput
              name="idstatutAgrement"
              label={t(ETLCodes.EtatAgrement)}
              items={statutAgrement}
              loading={slaLoading}
              readonly={data.statutAgrement < 4 || data.statutAgrement === 6 || data.statutAgrement === 10}
            />
          </FieldGroup>
        </SmallFormGenerator>
      </FieldSet>
    )
  );
};
