import { FGTextInput, FieldGroup, useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ClasseApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { SmallFormGenerator } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IExamenPratiqueItemPageProps {}

export const ExamenPratiqueItemPage: React.FunctionComponent<IExamenPratiqueItemPageProps> = () => {
  const { t } = useTl();
  const { id } = useParams<{ id: string }>();
  const api = useApiService(ClasseApi);
  const [data, loading] = useApiEffect(async () => await api.classeGetCoutClasse({ id: +id }), []);
  const history = useHistory();

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={() => {}}
      hideButtons={true}
      editMode={false}
      minLabelWidth={180}
      labelAlignment="right"
      formatDate="dd/MM/yyyy"
      inline
      boldLabel
      loading={loading}
      onCancel={() => history.push(ERoutes.classe)}
    >
      <FieldGroup columns={1}>
        <FGTextInput name="coutInitial" label={t(ETLCodes.CoutInitial)} />
        <FGTextInput name="nbrInscrits" label={t(ETLCodes.NombreInscrit)} />
        <FGTextInput name="coutGlobal" label={t(ETLCodes.CoutGlobal)} />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
