import { getIn } from "formik";
import { useFGContext } from "nsitools-react";
import * as React from "react";
import { FGWalterSelectInput, IFGWalterSelectInputProps } from "..";
import { useTl } from "../../hooks";
import { useReferential } from "../../hooks/useReferential";
import { ETLCodes } from "../../locales";

export interface IContratMotifRupturePatronSelectProps<T>
  extends Omit<IFGWalterSelectInputProps<T>, "items" | "loading"> {}

export function ContratMotifRupturePatronSelect<T>(props: IContratMotifRupturePatronSelectProps<T>) {
  const { t } = useTl();
  const { formik } = useFGContext();

  const value = React.useMemo(() => formik?.values && getIn(formik?.values, props.name), [formik?.values, props.name]);
  const [motifsPatron, mpLoading] = useReferential(a =>
    a.referentialGetMotifsRupturePatron({ selectedId: value ?? 0 })
  );

  return (
    <FGWalterSelectInput
      name={props.name}
      label={props.label ?? t(ETLCodes.MotifPatron)}
      items={motifsPatron}
      loading={mpLoading}
    />
  );
}

export default ContratMotifRupturePatronSelect;
