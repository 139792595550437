import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ExportApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { PageBase, ProtectedTabs } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { ExportForm } from "./ExportForm";

export interface IExportUtilitaireItemPageProps {}

export const ExportUtilitaireItemPage: React.FunctionComponent<IExportUtilitaireItemPageProps> = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ExportApi);
  const [displayName] = useApiEffect(() => api.exportGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase breadCrumbs={[{ text: t(ETLCodes.ExportExcel) }, { text: displayName }]}>
      <ProtectedTabs
        id="TabsExport"
        onChange={newTabId => history.push(`${ERoutes.export}/${id}`)}
        selectedTabId="detail"
        renderActiveTabPanelOnly
      >
        <Tab id="detail" title={t(ETLCodes.Detail)} panel={<ExportForm selected={+id} />} />
      </ProtectedTabs>
    </PageBase>
  );
};
