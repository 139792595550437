import * as React from "react";
import { SmallFormGenerator, FGWalterDateMaskInput } from "../../../../../../components";
import { useTl, useApiService } from "../../../../../../hooks";
import { ClasseHoraireApi } from "../../../../../../api";
import { FieldGroup, FGEmpty } from "nsitools-react";
import { ETLCodes } from "../../../../../../locales";
import { useEventsContext } from "../../../../../../contexts";
import { CLASSE_HORAIRE_ACTIONS } from "../ClasseHoraireItemPage";

export interface IFermetureClasseProps {
  idClasse: number;
}

export const FermetureClassePage: React.FunctionComponent<IFermetureClasseProps> = ({ idClasse }) => {
  const { t } = useTl();
  const api = useApiService(ClasseHoraireApi);
  const { dispatchEvent } = useEventsContext();
  const [loading, setLoading] = React.useState(false);

  return (
    <>
      <SmallFormGenerator
        initialValues={{ idClasse: idClasse, dateFermeture: new Date() }}
        onSubmit={async d => {
          setLoading(true);
          await api.classeHoraireFermetureClasse({ idClasse: d.idClasse, dateFermeture: d.dateFermeture });
          setLoading(false);
          dispatchEvent(CLASSE_HORAIRE_ACTIONS, true);
        }}
        editMode={true}
        saving={loading}
      >
        <FieldGroup columns={2}>
          <FGWalterDateMaskInput label={t(ETLCodes.DateFermeture)} name="dateFermeture" showPlaceholder={false} />
          <FGEmpty />
        </FieldGroup>
      </SmallFormGenerator>
    </>
  );
};
