/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RapportHopeGetIdsrapportContrat200Response
 */
export interface RapportHopeGetIdsrapportContrat200Response {
    /**
     * 
     * @type {number}
     * @memberof RapportHopeGetIdsrapportContrat200Response
     */
    cC?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportHopeGetIdsrapportContrat200Response
     */
    cA?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportHopeGetIdsrapportContrat200Response
     */
    cS?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportHopeGetIdsrapportContrat200Response
     */
    pP?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportHopeGetIdsrapportContrat200Response
     */
    cF?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportHopeGetIdsrapportContrat200Response
     */
    dM?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportHopeGetIdsrapportContrat200Response
     */
    jI?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportHopeGetIdsrapportContrat200Response
     */
    sO?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportHopeGetIdsrapportContrat200Response
     */
    cCE?: number;
    /**
     * 
     * @type {number}
     * @memberof RapportHopeGetIdsrapportContrat200Response
     */
    cSE?: number;
}

/**
 * Check if a given object implements the RapportHopeGetIdsrapportContrat200Response interface.
 */
export function instanceOfRapportHopeGetIdsrapportContrat200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RapportHopeGetIdsrapportContrat200ResponseFromJSON(json: any): RapportHopeGetIdsrapportContrat200Response {
    return RapportHopeGetIdsrapportContrat200ResponseFromJSONTyped(json, false);
}

export function RapportHopeGetIdsrapportContrat200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RapportHopeGetIdsrapportContrat200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cC': !exists(json, 'CC') ? undefined : json['CC'],
        'cA': !exists(json, 'CA') ? undefined : json['CA'],
        'cS': !exists(json, 'CS') ? undefined : json['CS'],
        'pP': !exists(json, 'PP') ? undefined : json['PP'],
        'cF': !exists(json, 'CF') ? undefined : json['CF'],
        'dM': !exists(json, 'DM') ? undefined : json['DM'],
        'jI': !exists(json, 'JI') ? undefined : json['JI'],
        'sO': !exists(json, 'SO') ? undefined : json['SO'],
        'cCE': !exists(json, 'CCE') ? undefined : json['CCE'],
        'cSE': !exists(json, 'CSE') ? undefined : json['CSE'],
    };
}

export function RapportHopeGetIdsrapportContrat200ResponseToJSON(value?: RapportHopeGetIdsrapportContrat200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'CC': value.cC,
        'CA': value.cA,
        'CS': value.cS,
        'PP': value.pP,
        'CF': value.cF,
        'DM': value.dM,
        'JI': value.jI,
        'SO': value.sO,
        'CCE': value.cCE,
        'CSE': value.cSE,
    };
}

