/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRapportGridDto
 */
export interface FcbRapportGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbRapportGridDto
     */
    rapportId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRapportGridDto
     */
    rapportName?: string | null;
}

/**
 * Check if a given object implements the FcbRapportGridDto interface.
 */
export function instanceOfFcbRapportGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRapportGridDtoFromJSON(json: any): FcbRapportGridDto {
    return FcbRapportGridDtoFromJSONTyped(json, false);
}

export function FcbRapportGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRapportGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rapportId': !exists(json, 'rapportId') ? undefined : json['rapportId'],
        'rapportName': !exists(json, 'rapportName') ? undefined : json['rapportName'],
    };
}

export function FcbRapportGridDtoToJSON(value?: FcbRapportGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rapportId': value.rapportId,
        'rapportName': value.rapportName,
    };
}

