/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ECustomSearchType } from './ECustomSearchType';
import {
    ECustomSearchTypeFromJSON,
    ECustomSearchTypeFromJSONTyped,
    ECustomSearchTypeToJSON,
} from './ECustomSearchType';
import type { ECustomSearchTypeStringCombinableCriteria } from './ECustomSearchTypeStringCombinableCriteria';
import {
    ECustomSearchTypeStringCombinableCriteriaFromJSON,
    ECustomSearchTypeStringCombinableCriteriaFromJSONTyped,
    ECustomSearchTypeStringCombinableCriteriaToJSON,
} from './ECustomSearchTypeStringCombinableCriteria';

/**
 * 
 * @export
 * @interface CustomSearch
 */
export interface CustomSearch {
    /**
     * 
     * @type {Array<ECustomSearchTypeStringCombinableCriteria>}
     * @memberof CustomSearch
     */
    criterias?: Array<ECustomSearchTypeStringCombinableCriteria> | null;
    /**
     * 
     * @type {ECustomSearchType}
     * @memberof CustomSearch
     */
    searchMode?: ECustomSearchType;
    /**
     * 
     * @type {string}
     * @memberof CustomSearch
     * @deprecated
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomSearch
     * @deprecated
     */
    secondaryValue?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomSearch
     */
    readonly availableSearchTypes?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CustomSearch
     */
    defaultSearchType?: string | null;
}

/**
 * Check if a given object implements the CustomSearch interface.
 */
export function instanceOfCustomSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomSearchFromJSON(json: any): CustomSearch {
    return CustomSearchFromJSONTyped(json, false);
}

export function CustomSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'criterias': !exists(json, 'criterias') ? undefined : (json['criterias'] === null ? null : (json['criterias'] as Array<any>).map(ECustomSearchTypeStringCombinableCriteriaFromJSON)),
        'searchMode': !exists(json, 'searchMode') ? undefined : ECustomSearchTypeFromJSON(json['searchMode']),
        'value': !exists(json, 'value') ? undefined : json['value'],
        'secondaryValue': !exists(json, 'secondaryValue') ? undefined : json['secondaryValue'],
        'availableSearchTypes': !exists(json, 'availableSearchTypes') ? undefined : json['availableSearchTypes'],
        'defaultSearchType': !exists(json, 'defaultSearchType') ? undefined : json['defaultSearchType'],
    };
}

export function CustomSearchToJSON(value?: CustomSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'criterias': value.criterias === undefined ? undefined : (value.criterias === null ? null : (value.criterias as Array<any>).map(ECustomSearchTypeStringCombinableCriteriaToJSON)),
        'searchMode': ECustomSearchTypeToJSON(value.searchMode),
        'value': value.value,
        'secondaryValue': value.secondaryValue,
        'defaultSearchType': value.defaultSearchType,
    };
}

