import * as React from "react";

import { useRouteMatch, Switch, Route } from "react-router";
import { ApprenantFinanceDetail } from "./ApprenantFinanceDetail";
import { ApprenantFinancePaiementDetail } from "./ApprenantFinancePaiementDetail";

interface IApprenantFinanceProps {}

export const ApprenantFinance: React.FunctionComponent<IApprenantFinanceProps> = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={ApprenantFinanceDetail} exact />
      <Route path={`${match.path}/:paiementId/:state`} component={ApprenantFinancePaiementDetail} />
    </Switch>
  );
};
