import { Classes, Dialog, Spinner } from "@blueprintjs/core";
import React from "react";

export default ({ visible }: { visible: boolean }) => (
  <Dialog isOpen={visible}>
    <div className={Classes.DIALOG_HEADER}>Preparing print</div>
    <div className={Classes.DIALOG_BODY}>
      <Spinner />
    </div>
  </Dialog>
);
