import { Button, Classes, Dialog, Divider, Icon, Intent, Radio, RadioGroup } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classNames from "classnames";
import Cleave from "cleave.js/react";
import { format } from "date-fns";
import { FormikProps } from "formik";
import * as IBAN from "iban";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import moment from "moment";
import {
  ButtonContainer,
  FGCustomInput,
  FGCustomPanel,
  FGCustomReadOnly,
  FGEmpty,
  FGIBANNumberInput,
  FGListen,
  FGMaskInput,
  FGMultiSuggestInput,
  FGTextAreaInput,
  FGTextInput,
  FieldGroup,
  IFGContext,
  useCleaveOptions,
  useDebouncedCallback,
  usePrevious
} from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";

import {
  AdresseDtoFromJSON,
  ApprenantApi,
  ApprenantDoublonSearch,
  ELevelName,
  EStatutContrat,
  ETypeContrat,
  FcbApprenantDto,
  FcbApprenantDtoFromJSON,
  FormateurApi
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  AdresseDoublonErrorLink,
  CommuneSelect,
  EditButton,
  EditStatutEntreeApprenantDialog,
  ErrorText,
  FGPersonneCodePostal,
  FGReadOnlyInput,
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  LinkButton,
  LocaliteSelect,
  NationaliteSelect,
  PhotoModifier,
  SmallFormGenerator,
  StyledError,
  ViewButton
} from "../../../../../components";
import { ButtonsBloc, InlineButtonContainer } from "../../../../../components/applicationButtons/ButtonContainerStyles";
import FGCopyTextInput from "../../../../../components/formGenerator/FGCopyTextInput";
import { AddParcoursFormationButton } from "../../../../../components/parcoursFormation/AddParcoursFormationButton";
import { SortieReseauButton } from "../../../../../components/parcoursFormation/SortieReseauButton";
import { RepresentantTooltip } from "../../../../../components/personTooltip/RepresentantToolTip";
import { useAuth, useBeIdCardContext, useDialog, useModifiedByComponent } from "../../../../../contexts";
import { useApiService, useCrudApi, useFetchPicture, useTheme, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import { belgianIdValidate } from "../../../../../utils/belgianIdValidate";
import { computeContratStatutColor } from "../../../../../utils/contratStatutColor";
import { phoneRegex } from "../../../../../utils/phoneRegex";

interface IApprenantSignaletiqueProps {
  baseData: ApprenantDoublonSearch;
}

const RoContainer = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  height: 30px;
  color: ${props => props.color};
  font-weight: bold;
  padding: 0 10px;
`;

const EcRoContainer = styled.div<{ color?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: 0 10px;
`;

const DiagonalBarHider = styled.div`
  overflow: hidden;
  position: relative;
`;

const DiagonalBar = styled.div`
  width: 140px;
  background: black;
  height: 15px;
  transform: rotate(-45deg);
  position: absolute;
  top: 25px;
  left: -40px;

  &.hide {
    visibility: hidden;
  }
`;

const StyledIcon = styled(Icon)`
  &.invert {
    filter: invert(100%);
  }
`;

const DialogStyled = styled(Dialog)`
  background: white;
`;

const BeneficiaryReadonlyContainer = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
`;

const CodeContrat = styled.div<{ color: string }>`
  color: ${props => props.color};
  font-weight: bold;
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IbanDisplay = styled(Cleave)`
  box-shadow: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus,
  :focus-visible {
    outline: none;
  }
`;

const MissingDataContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ApprenantSignaletique: React.FunctionComponent<IApprenantSignaletiqueProps> = ({ baseData }) => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ApprenantApi);
  const formateurApi = useApiService(FormateurApi);
  const formikRef = React.useRef<FormikProps<FcbApprenantDto>>();
  const { user } = useAuth();
  const { theme, ifapmeSide } = useTheme();

  const { id: idApprenant, state } = useParams<{ id: string; state: string }>();
  const { card } = useBeIdCardContext();
  const genderMapping = React.useMemo(() => ({ M: "H", F: "F", V: "F", W: "F" }), []);
  const [cardDataDifferent, setCardDataDifferent] = React.useState(false);
  const [mustLoadPicture, setMustLoadPicture] = React.useState(true);
  const { showDialogPromise, showDialog } = useDialog();
  const [deceasedChanged, setDeceasedChanged] = React.useState(false);
  const [idCatStatutSocial, setIdCatStatutSocial] = React.useState(formikRef?.current?.values?.idCategorieStatut);
  const isHope = React.useMemo(() => ifapmeSide === "hope", [ifapmeSide]);
  const [statutApprenantEditOpen, setStatutApprenantEditOpen] = React.useState<number>(null);

  const { search } = useLocation();
  const iddemandeFormationCrea = React.useMemo(
    () => +(new URLSearchParams(search).get("iddemandeFormationCrea") ?? 0),
    [search]
  );

  const fetchIsProspect = React.useCallback(async () => await api.apprenantIsProspect({ id: +idApprenant }), [
    api,
    idApprenant
  ]);
  const { data: isProspect } = useQuery(["apprenant-is-prospect", idApprenant], fetchIsProspect);

  const { data, loading, saveItem, saving, setData, refresh, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: async () => {
          let newData = {};
          if (baseData) {
            newData = { ...baseData };
          }
          if (card) {
            newData = {
              ...newData,
              nom: card?.surname.toUpperCase(),
              prenom: card?.firstName,
              dateNaissance: card?.dateOfBirthParsed,
              registreNational: card?.nationalNumber,
              communeNaissance: card?.locationOfBirth,
              beidnumber: card?.cardNumber,
              photo: card?.photoB64,
              codesexe: card?.gender ? genderMapping[card?.gender] : null,
              adresseApprenant: !!card
                ? AdresseDtoFromJSON({
                    adresse: card?.addressStreetAndNumber,
                    codePostalText: card?.addressZip
                  })
                : null
            };
          }

          return +idApprenant <= 0
            ? FcbApprenantDtoFromJSON({
                ...newData,
                idApprenant: 0,
                courrierPrincipal: true,
                bankAccountBeneficiaryId: null,
                idsSocieteExterne: +idApprenant <= 0 && user.idsSocieteExterne
              })
            : api.apprenantGet({ id: +idApprenant, picture: false, iddemandeFormationCrea });
        },
        saveApiFn: async (d: FcbApprenantDto) => {
          if (d.isDeceased && deceasedChanged) {
            const result = await showDialogPromise({
              message: t(ETLCodes.IsDeceasedConfirmation)
            });
            if (result === "no") {
              d.isDeceased = false;
              d.dateDeces = null;
              return;
            }
          }
          return api.apprenantSave({ FcbApprenantDto: d });
        },
        deleteApiFn: (d: FcbApprenantDto) => api.apprenantDelete({ id: d.idapprenant }),
        onDeletedRoute: () => ERoutes.apprenant,
        onSavedRoute: (d: FcbApprenantDto) =>
          !!iddemandeFormationCrea && isProspect?.value
            ? `${ERoutes.apprenant}/${d.idapprenant}/inscription/-1/detail/edit?iddemandeFormationCrea=${iddemandeFormationCrea}`
            : `${ERoutes.apprenant}/${d.idapprenant}/signaletique/edit`
      }),
      [
        api,
        baseData,
        card,
        deceasedChanged,
        genderMapping,
        idApprenant,
        iddemandeFormationCrea,
        isProspect?.value,
        showDialogPromise,
        t,
        user.idsSocieteExterne
      ]
    )
  );

  const { modifiedByComponent, setModifiedByData } = useModifiedByComponent();
  const [photoDialogOpen, setPhotoDialogOpen] = React.useState<boolean>(false);
  const [sexe, sLoading] = useReferential(a => a.referentialGetSexe());
  const [pays, pLoading] = useReferential(a => a.referentialGetPays());
  const [societesExternes, seLoading] = useReferential(apiC => apiC.referentialGetSocietesExternes(), true, []);
  const [categoriesStatutSocial, cssLoading, , rawCatStatutSocial] = useReferential(a =>
    a.referentialGetCategoriesStatutSocial()
  );
  const [idstatutSocial, setIdstatutSocial] = React.useState(0);
  const [statutsSocial, ssLoading] = useReferential(
    a =>
      a.referentialGetSousStatutsByCategorie({ idCategorie: idCatStatutSocial, currentIdstatutSocial: idstatutSocial }),
    true,
    [idCatStatutSocial]
  );
  const [typesSortie, tLoading] = useReferential(a => a.referentialGetTypesSortie({}));
  const [idtypeSortie, setIdtypeSortie] = React.useState(0);
  const [motifsSortie, msLoading] = useReferential(a => a.referentialGetMotifsSortie({ idtypeSortie }), true, [
    idtypeSortie
  ]);

  const idpersonne = React.useMemo(() => data?.idpersonne ?? 0, [data?.idpersonne]);
  const [personneTelephones, personneTelephonesLoading] = useReferential(
    a => a.referentialGetTelephonesPersonne({ idpersonne }),
    false,
    [idpersonne]
  );
  const [personneGsms, personneGsmsLoading] = useReferential(a => a.referentialGetGsmsPersonne({ idpersonne }), false, [
    idpersonne
  ]);

  const [personneEmails, personneEmailsLoading] = useReferential(
    a => a.referentialGetEmailsPersonne({ idpersonne }),
    false,
    [idpersonne]
  );

  const [personneComptesBancaires, personneComptesBancairesLoading, , rawPersonneComptesBancaires] = useReferential(
    a => a.referentialGetComptesBancairesPersonne({ idpersonne }),
    false,
    [idpersonne]
  );

  const [picture, picLoading] = useFetchPicture(idpersonne, [data]);
  const [representatives, rLoading] = useReferential(r =>
    r.referentialGetLearnerRepresentatives({ learnerId: +idApprenant })
  );
  React.useEffect(() => {
    if (picture && data && !loading && !cardDataDifferent && mustLoadPicture) {
      setMustLoadPicture(false);
      setData({ ...data, photo: picture });
    }
  }, [cardDataDifferent, data, loading, mustLoadPicture, picture, setData]);

  React.useEffect(() => {
    if (data) {
      setModifiedByData({ modificationDate: data.modificationDate, modificationUser: data.modificationUserName });
    }
  }, [data, setModifiedByData]);

  React.useEffect(() => {
    if (data?.securise && user?.iduser !== data?.idPri) {
      showDialog({
        title: t(ETLCodes.ApprenantSecuriseTitle),
        message: t(ETLCodes.ApprenantSecuriseMessage),
        buttons: [
          {
            resultType: "ok",
            intent: "success",
            text: t(ETLCodes.OK)
          }
        ]
      });
    }
  }, [data?.idPri, data?.securise, showDialog, t, user?.iduser]);

  const askDataDifferent = React.useCallback(async () => {
    const result = await showDialogPromise({
      message: t(ETLCodes.ApprenantIdCardIsDifferent)
    });
    if (result === "yes") {
      setCardDataDifferent(true);
    }
  }, [showDialogPromise, t]);

  const isDeceasedConfirm = React.useCallback(async (isDeceased: boolean, formik) => {
    if (!isDeceased && !!formik?.values?.dateDeces) {
      formik.setFieldValue("dateDeces", null);
      return;
    } else if (!formik?.values?.dateDeces) return;
  }, []);

  const canUseCardData = React.useMemo(() => {
    if (+idApprenant > 0) {
      return !data?.registreNational || data?.registreNational === card?.nationalNumber;
    }

    return false;
  }, [card?.nationalNumber, data?.registreNational, idApprenant]);

  React.useEffect(() => {
    if (!picLoading && data && card) {
      const cardObj = FcbApprenantDtoFromJSON({
        nom: card?.surname.toUpperCase(),
        prenom: card?.firstName,
        dateNaissance: card?.dateOfBirthParsed,
        registreNational: card?.nationalNumber,
        communeNaissance: card?.locationOfBirth,
        beidnumber: card?.cardNumber,
        photo: card?.photoB64,
        codesexe: card?.gender ? genderMapping[card?.gender] : null,
        adresseApprenant: AdresseDtoFromJSON({
          adresse: card?.addressStreetAndNumber,
          codePostalText: card?.addressZip
        })
      });

      const propsToPick = [
        "nom",
        "prenom",
        "dateNaissance",
        "registreNational",
        "communeNaissance",
        "beidnumber",
        "codesexe"
      ];
      const cardBirthdate = new Date(card.dateOfBirthParsed);
      const cardBirthdateString = `${cardBirthdate.getDate()}/${cardBirthdate.getMonth() +
        1}/${cardBirthdate.getFullYear()}`;
      const dataBirthdateString = `${data.dateNaissance.getDate()}/${data.dateNaissance.getMonth() +
        1}/${data.dateNaissance.getFullYear()}`;
      if (
        +idApprenant > 0 &&
        (!isEqual(pick(cardObj, propsToPick), pick(data, propsToPick)) || cardBirthdateString !== dataBirthdateString)
      ) {
        if (canUseCardData) {
          askDataDifferent();
        }
      }
    }
  }, [askDataDifferent, canUseCardData, card, data, genderMapping, idApprenant, picLoading]);

  React.useEffect(() => {
    if (cardDataDifferent) {
      setCardDataDifferent(false);
      setMustLoadPicture(false);
      formikRef?.current?.setValues(
        FcbApprenantDtoFromJSON({
          ...data,
          nom: card?.surname.toUpperCase() ?? data.nom,
          prenom: card?.firstName ?? data.prenom,
          dateNaissance: card?.dateOfBirthParsed ?? data.dateNaissance,
          registreNational: card?.nationalNumber ?? data.registreNational,
          communeNaissance: card?.locationOfBirth ?? data.communeNaissance,
          beidnumber: card?.cardNumber ?? data.beidnumber,
          photo: card?.photoB64 ?? picture,
          codesexe: card?.gender ? genderMapping[card?.gender] : data.codesexe,
          adresseApprenant: AdresseDtoFromJSON({
            ...data.adresseApprenant,
            adresse: card?.addressStreetAndNumber ?? data.adresseApprenant?.adresse,
            codePostalText: card?.addressZip ?? data.adresseApprenant?.codePostal
          })
        }),
        true
      );
    }
  }, [
    card?.addressStreetAndNumber,
    card?.addressZip,
    card?.cardNumber,
    card?.dateOfBirthParsed,
    card?.firstName,
    card?.gender,
    card?.locationOfBirth,
    card?.nationalNumber,
    card?.photoB64,
    card?.surname,
    cardDataDifferent,
    data,
    genderMapping,
    picture,
    saveItem,
    setData,
    formikRef
  ]);

  const [hasDoublonsName, setHasDoublonsName] = React.useState(false);

  const checkDoublonsName = React.useCallback(
    async values => {
      const res = await api.apprenantCheckDoublonsName({ FcbApprenantDto: values });
      setHasDoublonsName(!res.isValid);
    },
    [api]
  );
  const debouncedCheckDoublonsName = useDebouncedCallback(checkDoublonsName, 1000);

  const saveApprenant = React.useCallback(
    async d => {
      let result = "yes";
      if (hasDoublonsName) {
        result = await showDialogPromise({
          message: t(ETLCodes.ApprenantNameExistsSave)
        });
      }
      if (result === "yes") {
        await saveItem(d);
      }
    },
    [hasDoublonsName, saveItem, showDialogPromise, t]
  );

  const [hasDoublonsNatNumber, setHasDoublonsNatNumber] = React.useState(false);
  const checkDoublonsNatNumber = React.useCallback(
    async values => {
      const res = await api.apprenantCheckDoublonsNationalNumber({ FcbApprenantDto: values });
      setHasDoublonsNatNumber(
        !res.isValid && (values.registreNational !== data?.registreNational || +idApprenant <= 0)
      );
    },
    [api, data?.registreNational, idApprenant]
  );
  const debouncedCheckDoublonsNatNumber = useDebouncedCallback(checkDoublonsNatNumber, 1000);
  const [codePaysNaissance, setCodePaysNaissance] = React.useState();
  const [localites, lLoading] = useReferential(
    a => a.referentialGetLocalitesSuggest({ pays: codePaysNaissance }),
    false,
    [codePaysNaissance]
  );

  const isRegNatDateNaiss = React.useCallback((ctx: IFGContext<FcbApprenantDto>) => {
    const value = ctx.formik.values.registreNational?.replace(".", "").replace("-", "");
    if (ctx.formik.values.dateNaissance && ctx.formik.values.nationalite === "BE") {
      const parsedDateNaissance = new Date(ctx.formik.values.dateNaissance);
      if (parsedDateNaissance && value) {
        const year = value.slice(0, 2);
        const month = value.slice(2, 4);
        const day = value.slice(4, 6);
        if (
          +year !==
          +parsedDateNaissance
            .getFullYear()
            .toString()
            .substr(2, 2)
        ) {
          return false;
        }
        if (+month !== 0 && +month !== parsedDateNaissance.getMonth() + 1) {
          return false;
        }
        if (+day !== 0 && +day !== parsedDateNaissance.getDate()) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, []);

  const isRegNatGenre = React.useCallback((ctx: IFGContext<FcbApprenantDto>) => {
    const value = ctx.formik.values.registreNational?.replace(".", "")?.replace("-", "");
    if (value && ctx.formik.values.codesexe !== "X" && ctx.formik.values.nationalite === "BE") {
      const code = +value.slice(7, 9) % 2 === 0 ? "F" : "H";
      return ctx.formik.values.codesexe === code;
    }
    return true;
  }, []);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape(
      {
        compteBancaire: Yup.object()
          .nullable()
          .shape({
            iban: Yup.string()
              .nullable()
              .test("iban-valid", t(ETLCodes.InvalidIban), async function(value) {
                if (!value || value.trim() === "" || !!this.parent.idcompteBancaire) return true;
                return IBAN.isValid(value);
              }),
            idcompteBancaire: Yup.number()
              .nullable()
              .test("iban-valid-id", t(ETLCodes.InvalidIban), async function(value) {
                if (!value || !this.parent.iban || this.parent.iban.trim() === "") return true;
                return IBAN.isValid(this.parent.iban);
              })
          }),
        nom: Yup.string()
          .required(t(ETLCodes.Required))
          .max(100, t(ETLCodes.MaxCharacterApprenantNom)),
        prenom: Yup.string()
          .required(t(ETLCodes.Required))
          .max(100, t(ETLCodes.MaxCharacterApprenantPrenom)),
        codesexe: Yup.string().required(t(ETLCodes.Required)),
        dateNaissance: Yup.date()
          .required(t(ETLCodes.Required))
          .min(new Date(new Date().getFullYear() - 100, 1, 1), t(ETLCodes.ApprenantTooOld))
          .max(new Date(new Date().getFullYear() - 12, 1, 1), t(ETLCodes.ApprenantTooYoung)),
        nationalite: Yup.string().required(t(ETLCodes.Required)),
        registreNational: Yup.string()
          .nullable()
          .test("registre-checksum", t(ETLCodes.NotValid), function(value) {
            if (!value || value === "") return true;

            var millenial = false;
            if (this.parent.dateNaissance) {
              const parsedDateNaissance = new Date(this.parent.dateNaissance);
              if (parsedDateNaissance.getFullYear() > 1999) {
                millenial = true;
              }
            }

            return belgianIdValidate(value, millenial);
          })
          .when(["nationalite", "numeroIdentification"], {
            is: (nationalite, numeroIdentification) => nationalite === "BE" || !numeroIdentification,
            then: schema => schema.required(t(ETLCodes.AtLeastOneRegNatFields))
          }),
        numeroIdentification: Yup.string()
          .nullable()
          .when(["nationalite", "registreNational"], {
            is: (nationalite, registreNational) => nationalite !== "BE" && !registreNational,
            then: schema => schema.required(t(ETLCodes.AtLeastOneRegNatFields))
          }),
        telephoneApprenant: Yup.object().test("tel-valid", t(ETLCodes.ErrorPhoneRegex), function(value) {
          if (this.parent.idapprenant > 0 || !value?.numero) return true;
          const numero = value?.numero as string;
          return !!numero.match(phoneRegex);
        }),
        gsmApprenant: Yup.object().test("gsm-valid", t(ETLCodes.ErrorPhoneRegex), function(value) {
          if (this.parent.idapprenant > 0 || !value?.numero) return true;
          const numero = value?.numero as string;
          return !!numero.match(phoneRegex);
        }),
        emailApprenant: Yup.object().when("idapprenant", {
          is: idapprenant => idapprenant <= 0,
          then: Yup.object().shape({
            adresseEmail: Yup.string().email(t(ETLCodes.InvalidEmail))
          })
        }),
        communeNaissance: Yup.string()
          .nullable()
          .test("localite-nationalite", t(ETLCodes.Required), function(value) {
            return !!value || this.parent.nationalite !== "BE";
          }),
        bankAccountBeneficiaryId: Yup.number()
          .nullable()
          .test("bankAccountBeneficiaryId-required", t(ETLCodes.Required), function(value) {
            return !value || value > 0;
          }),
        dateDeces: Yup.string()
          .nullable()
          .test("dateDeces-required", t(ETLCodes.Required), function(value) {
            return this.parent.isDeceased ? !!value : true;
          }),
        courrierPrincipal: Yup.bool().test("courrier-principal-required", t(ETLCodes.AtLeastOneCourrier), function(
          value
        ) {
          return !!value || this.parent.courrierAutre;
        }),
        courrierAutre: Yup.bool().test("courrier-autre-required", t(ETLCodes.AtLeastOneCourrier), function(value) {
          return !!value || this.parent.courrierPrincipal;
        }),
        idCategorieStatut: Yup.number()
          .nullable()
          .test("categorie-entree-required", t(ETLCodes.Required), function(value) {
            if (!!this.parent.idstatutEntree || this.parent.idapprenant > 0) return true;
            return this.parent.dateEntree || this.parent.idStatut ? !!value : true;
          }),
        idStatut: Yup.number()
          .nullable()
          .test("statut-entree-required", t(ETLCodes.Required), function(value) {
            if (!!this.parent.idstatutEntree || this.parent.idapprenant > 0) return true;
            return this.parent.dateEntree || this.parent.idCategorieStatut ? !!value : true;
          })
      },
      [
        ["numeroIdentification", "nationalite"],
        ["numeroIdentification", "registreNational"],
        ["registreNational", "nationalite"],
        ["registreNational", "numeroIdentification"]
      ]
    );
  }, [t]);

  const [bankAccount, setBankAccount] = React.useState<string>(null);
  const queryEnabled = React.useMemo(() => bankAccount?.toUpperCase()?.startsWith("BE") && bankAccount?.length > 8, [
    bankAccount
  ]);
  const fetchBic = React.useCallback(async () => {
    if (queryEnabled) return formateurApi.formateurGetBicFromAccount({ account: bankAccount });
    return "";
  }, [bankAccount, formateurApi, queryEnabled]);

  const { data: bic, isFetching: bicLoading } = useQuery(["bic", idApprenant, bankAccount], fetchBic);

  const previousBic = usePrevious(bic);
  React.useEffect(() => {
    if (bic && previousBic !== bic) {
      formikRef?.current?.setFieldValue("compteBancaire.bic", bic);
    }
  }, [bic, previousBic]);

  const [idPri, setIdPri] = React.useState(0);
  const [users, usersLoading] = useReferential(
    a =>
      a.referentialGetUsersByRoles({
        ELevelName: [ELevelName.RI, ELevelName.ARI, ELevelName.COP],
        currentIduser: idPri
      }),
    true,
    [idPri]
  );

  React.useEffect(() => {
    if (
      !!data &&
      user &&
      !usersLoading &&
      users?.some(u => +u.value === user.iduser) &&
      formikRef.current &&
      data.idPri === null
    ) {
      formikRef.current.setFieldValue("idPri", user.iduser);
    }
  }, [data, user, users, usersLoading]);

  const [idAri, setIdAri] = React.useState(0);
  const [usersAri, usersAriLoading] = useReferential(
    a => a.referentialGetUsersByRoles({ ELevelName: [ELevelName.ARI], currentIduser: idAri }),
    true,
    [idAri]
  );

  const [idCop, setIdCop] = React.useState(0);
  const [usersCop, usersCopLoading] = useReferential(
    a => a.referentialGetUsersByRoles({ ELevelName: [ELevelName.COP], currentIduser: idCop }),
    true,
    [idCop]
  );

  const isUserCop = React.useMemo(() => user && usersCop && usersCop.some(u => +u.value === user.iduser), [
    user,
    usersCop
  ]);

  const isFullCreation = React.useMemo(() => +idApprenant <= 0 && !data?.idpersonne, [data?.idpersonne, idApprenant]);

  const isCalcOrAdmin = React.useMemo(() => user?.hopeMainLevelName === "CALC" || user?.isAdmin, [
    user?.hopeMainLevelName,
    user?.isAdmin
  ]);

  const edit = React.useMemo(() => state === "edit", [state]);
  const ibanCleaveOptions = useCleaveOptions("iban");

  const showDureeInoccupation = React.useMemo(
    () =>
      !!rawCatStatutSocial &&
      +(rawCatStatutSocial.find(c => c.keyValue === "DEM")?.idValue ?? 0) === +idCatStatutSocial,
    [idCatStatutSocial, rawCatStatutSocial]
  );
  const [dureeInoccupations, diLoading] = useReferential(a => a.referentialGetDureeInoccupation(), true, []);

  const onStatutApprenantEditOpen = React.useCallback(async (value: number) => {
    setStatutApprenantEditOpen(value);
  }, []);

  const onStatutApprenantEditClose = React.useCallback(
    async (newId: number) => {
      setStatutApprenantEditOpen(null);
      if (!!newId) {
        formikRef.current?.setFieldValue("idstatutApprenant", newId);
        saveItem(formikRef.current?.values);
      }
    },
    [saveItem]
  );

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={!!iddemandeFormationCrea && isProspect?.value ? onStatutApprenantEditOpen : saveApprenant}
      editMode={edit}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.apprenant)}
      saving={saving}
      formikInnerRef={i => (formikRef.current = i)}
      minLabelWidth={201} // For idPri field label length
      forceEnableSave={Object.values(baseData).some(el => !!el) || !!iddemandeFormationCrea}
      watchChanges={{
        idStatut: setIdstatutSocial,
        idtypeSortie: setIdtypeSortie
      }}
      validationErrors={validationErrors}
    >
      <FGListen field="nom" onChanged={(value, formik) => debouncedCheckDoublonsName(formik?.values)} />
      <FGListen field="prenom" onChanged={(value, formik) => debouncedCheckDoublonsName(formik?.values)} />
      <FGListen field="dateNaissance" onChanged={(value, formik) => debouncedCheckDoublonsName(formik?.values)} />
      <FGListen field="codePaysNaissance" onChanged={value => setCodePaysNaissance(value)} />
      <FGListen
        field="registreNational"
        onChanged={(value, formik) => debouncedCheckDoublonsNatNumber(formik?.values)}
      />

      <FGListen
        field="compteBancaire.iban"
        onChanged={(value, formik) => {
          if (!!value) {
            setBankAccount(value);
          } else if (!!formik.values.compteBancaire?.bic) {
            formik.setFieldValue("compteBancaire.bic", null);
          }
        }}
      />
      <FGListen
        field="compteBancaire.idcompteBancaire"
        debounceTimeInMs={500}
        onChanged={(value, formik) => {
          if (value === null) {
            formik.setFieldValue("compteBancaire.iban", null);
            formik.setFieldValue("compteBancaire.bic", null);
          }
        }}
      />
      <FGListen
        field="isDeceased"
        onChanged={(value, formik) => {
          if (formik?.initialValues?.isDeceased !== value) {
            setDeceasedChanged(true);
          } else {
            setDeceasedChanged(false);
          }
          isDeceasedConfirm(value, formik);
        }}
      />
      <FGListen field="idCategorieStatut" onChanged={value => setIdCatStatutSocial(value)} />
      <FGListen field="idPri" onChanged={value => setIdPri(value)} />
      <FGListen field="idAri" onChanged={value => setIdAri(value)} />
      <FGListen field="idCop" onChanged={value => setIdCop(value)} />
      <TopContainer>
        <FGCustomPanel>
          {ctx =>
            user.iduser === ctx?.formik?.values?.idPri && (
              <Button
                intent={ctx?.formik?.values?.securise ? Intent.SUCCESS : Intent.DANGER}
                text={t(ctx?.formik?.values?.securise ? ETLCodes.ApprenantSecurise : ETLCodes.ApprenantNonSecurise)}
                icon={ctx?.formik?.values?.securise ? "lock" : "unlock"}
                onClick={() => ctx?.formik?.setFieldValue("securise", !ctx?.formik?.values?.securise)}
              />
            )
          }
        </FGCustomPanel>
        {modifiedByComponent}
      </TopContainer>
      <FieldGroup columns={[8, 4]}>
        <FieldGroup columns={2}>
          <FieldGroup>
            <FGTextInput name="nom" label={t(ETLCodes.Nom)} maxLength={50} forceCase="upper" />
            <FGTextInput name="prenom" label={t(ETLCodes.Prenom)} maxLength={100} />
            <FGWalterDateMaskInput name="dateNaissance" label={t(ETLCodes.DateNaissance)} />
            <FGCustomInput label={t(ETLCodes.Age)}>
              {(ctx: IFGContext<FcbApprenantDto>) => (
                <div style={{ marginTop: 6 }}>
                  {t(ETLCodes.XYear, { ageNumber: moment().diff(ctx?.formik?.values?.dateNaissance, "years") })}
                </div>
              )}
            </FGCustomInput>
            <FGWalterSelectInput
              name="codePaysNaissance"
              label={t(ETLCodes.PaysNaissance)}
              items={pays}
              loading={pLoading}
            />
            <FGCustomPanel>
              {ctx => (
                <FGTextInput
                  name="communeNaissance"
                  label={t(ETLCodes.CommuneNaissance)}
                  maxLength={100}
                  requiredMark={ctx?.formik?.values?.nationalite === "BE"}
                />
              )}
            </FGCustomPanel>
            <FGWalterSelectInput
              name="localiteNaissance"
              label={t(ETLCodes.LocaliteNaissance)}
              loading={lLoading}
              items={localites}
            />
          </FieldGroup>
          <FieldGroup>
            <FGWalterSelectInput
              name="codesexe"
              label={t(ETLCodes.Genre)}
              items={sexe}
              loading={sLoading}
              isFilterable={false}
              isCleareable={false}
            />
            <FGWalterCheckboxInput name="isDeceased" label={t(ETLCodes.DeceasedLearner)} />
            <FGCustomPanel>
              {ctx => (
                <FGWalterDateMaskInput
                  name="dateDeces"
                  label={t(ETLCodes.DateDeces)}
                  showPlaceholder={false}
                  disabled={!ctx?.formik?.values?.isDeceased}
                />
              )}
            </FGCustomPanel>
            {isFullCreation ? (
              <FGCopyTextInput
                copyOnlyDigits={true}
                name="gsmApprenant.numero"
                label={t(ETLCodes.Gsm)}
                maxLength={20}
                visible={isFullCreation}
              />
            ) : (
              <FGWalterSelectInput
                name="gsmApprenant.idtelephone"
                label={t(ETLCodes.Gsm)}
                items={personneGsms}
                loading={personneGsmsLoading}
                visible={!isFullCreation}
                itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
                itemCreateParams={`&typeTel=GSM_PERSO`}
                autoSelectIfOne
              />
            )}
            {isFullCreation ? (
              <FGCopyTextInput
                copyOnlyDigits={true}
                name="telephoneApprenant.numero"
                label={t(ETLCodes.Tel)}
                maxLength={20}
                visible={isFullCreation}
              />
            ) : (
              <FGWalterSelectInput
                name="telephoneApprenant.idtelephone"
                label={t(ETLCodes.Tel)}
                items={personneTelephones}
                loading={personneTelephonesLoading}
                visible={!isFullCreation}
                itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
                itemCreateParams={`&typeTel=PERSO`}
                autoSelectIfOne
              />
            )}
            {isFullCreation ? (
              <FGTextInput
                name="emailApprenant.adresseEmail"
                label={t(ETLCodes.General_Email)}
                maxLength={100}
                visible={isFullCreation}
              />
            ) : (
              <FGWalterSelectInput
                name="emailApprenant.idemail"
                label={t(ETLCodes.General_Email)}
                loading={personneEmailsLoading}
                items={personneEmails}
                visible={!isFullCreation}
                itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
                itemCreateParams={`&typeEmail=PERSO`}
                autoSelectIfOne
              />
            )}
            <FGCustomPanel>
              {ctx =>
                !!ctx?.formik?.values?.emergencyContactId && !!ctx?.formik?.values?.emergencyContactPhone ? (
                  <FGCopyTextInput
                    copyOnlyDigits={true}
                    readonly
                    name="emergencyContactPhone"
                    label={t(ETLCodes.EmergencyContact)}
                    rightElement={<RepresentantTooltip idApprenant={data?.idapprenant} />}
                  />
                ) : (
                  <FGCustomReadOnly name="emergencyContactPhone" label={t(ETLCodes.EmergencyContact)}>
                    {val =>
                      !!ctx?.formik?.values?.emergencyContactId && (
                        <EcRoContainer>
                          {!val && t(ETLCodes.PasDeNumero)}
                          <RepresentantTooltip idApprenant={data?.idapprenant} />
                        </EcRoContainer>
                      )
                    }
                  </FGCustomReadOnly>
                )
              }
            </FGCustomPanel>
            <FieldGroup columns={2}>
              <FGWalterCheckboxInput name="permisB" label={t(ETLCodes.PermisVoitureB)} />
              <FGWalterCheckboxInput name="permisAm" label={t(ETLCodes.PermisCyclomoteurAM)} />
            </FieldGroup>
          </FieldGroup>
        </FieldGroup>
        <FieldGroup columns={[3, 5, 4]}>
          <FGEmpty />
          <FGCustomPanel>
            {ctx => (
              <div className={picLoading ? Classes.SKELETON : ""}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {ctx.formik.values.photo ? (
                    <>
                      <DiagonalBarHider>
                        <DiagonalBar className={classNames({ hide: !ctx?.formik?.values?.isDeceased })} />
                        <img
                          src={`data:image/png;base64,${ctx.formik.values.photo}`}
                          alt="Profil"
                          style={{ maxHeight: "9.5rem" }}
                        />
                      </DiagonalBarHider>
                    </>
                  ) : (
                    <StyledIcon
                      iconSize={136.52}
                      icon={IconNames.USER}
                      style={{ marginBottom: "10px" }}
                      className={classNames({ invert: ctx?.formik?.values?.isDeceased })}
                    />
                  )}
                </div>
              </div>
            )}
          </FGCustomPanel>
          <FGEmpty />
          <FGEmpty />
          <FGCustomPanel>
            {ctx => (
              <InlineButtonContainer style={{ marginBottom: "10px" }}>
                <ButtonsBloc />
                <ButtonsBloc>
                  <Button
                    icon={IconNames.CAMERA}
                    text={t(ETLCodes.ModifierPhoto)}
                    onClick={() => setPhotoDialogOpen(true)}
                    disabled={!ctx.editMode}
                  />
                  <Button
                    icon={IconNames.DELETE}
                    minimal={true}
                    intent={Intent.DANGER}
                    onClick={() => ctx.formik.setFieldValue("photo", null)}
                    disabled={!ctx.editMode}
                  />
                </ButtonsBloc>
                <ButtonsBloc />
              </InlineButtonContainer>
            )}
          </FGCustomPanel>
        </FieldGroup>
      </FieldGroup>
      <Divider style={{ marginBottom: "1rem" }} />
      <FieldGroup columns={[4, 4, 2, 2]}>
        <NationaliteSelect name="nationalite" codeSexeFieldName="codesexe" />
        <FGTextInput name="azureEmail" label={t(ETLCodes.EmailIfapme)} readonly />
        <FGEmpty />
        <FGEmpty />
        <FGMaskInput
          name="registreNational"
          label={t(ETLCodes.NumeroNational)}
          cleaveOptions={{ delimiters: [".", ".", "-", "."], blocks: [2, 2, 2, 3, 2] }}
          helperText={ctx => (
            <>
              {isRegNatDateNaiss(ctx) ? null : (
                <span style={{ color: theme.warningColor }}>{t(ETLCodes.DateDeNaissanceNeCorrespondPas)}</span>
              )}
              {isRegNatGenre(ctx) ? null : (
                <span style={{ color: theme.warningColor }}>{t(ETLCodes.GenreNeCorrespondPas)}</span>
              )}
            </>
          )}
        />
        <FGTextInput name="azureTemporaryPassword" label={t(ETLCodes.TemporaryPassword)} readonly />
        <FGEmpty />
        <FGEmpty />
        <FGTextInput
          name="numeroIdentification"
          label={t(ETLCodes.NumeroIdentification)}
          visible={ctx => !!ctx.formik?.values?.nationalite && ctx.formik?.values?.nationalite !== "BE"}
        />
      </FieldGroup>
      <FieldGroup columns={[8, 4]}>
        {user && !user.idsSocieteExterne?.length && (user.hopeMainLevelName === "CENTRE - Global" || user.isAdmin) && (
          <FGMultiSuggestInput
            name="idsSocieteExterne"
            label={t(ETLCodes.SocietesExternes)}
            items={societesExternes}
            loading={seLoading}
          />
        )}
      </FieldGroup>
      <Divider style={{ marginBottom: "1rem" }} />
      <FGCustomPanel>
        {ctx => (
          <FieldGroup columns={[4, 4, 4]}>
            <FieldGroup>
              {ctx.formik.values.hasAdresseDoublons && <AdresseDoublonErrorLink idpersonne={idpersonne} />}
              <FGWalterCheckboxInput
                name="courrierPrincipal"
                label={t(ETLCodes.EnvoiACetteAdresse)}
                disabled={ctx.formik.values.hasAdresseDoublons}
              />
              <FGWalterSelectInput
                name="adresseApprenant.pays"
                label={t(ETLCodes.Pays)}
                items={pays}
                loading={pLoading}
                disabled={ctx.formik.values.hasAdresseDoublons}
              />
              <FGTextAreaInput
                name="adresseApprenant.adresse"
                label={t(ETLCodes.Adresse)}
                disabled={ctx.formik.values.hasAdresseDoublons}
              />
              <FGPersonneCodePostal
                idName="adresseApprenant.idcodePostal"
                textName="adresseApprenant.codePostalText"
                localiteName="adresseApprenant.localite"
                disabled={ctx.formik.values.hasAdresseDoublons}
              />
              <LocaliteSelect
                codePostal={ctx?.formik?.values?.adresseApprenant?.codePostalText}
                codePays={ctx?.formik?.values?.adresseApprenant?.pays}
                name="adresseApprenant.localite"
                codePostalTextName="adresseApprenant.codePostalText"
                disableIfNoCp={false}
                disabled={ctx.formik.values.hasAdresseDoublons}
              />
              <CommuneSelect
                codePostal={ctx?.formik?.values?.adresseApprenant?.codePostalText}
                codePays={ctx?.formik?.values?.adresseApprenant?.pays}
                name="adresseApprenant.commune"
                disabled={ctx.formik.values.hasAdresseDoublons}
              />
            </FieldGroup>
            <FieldGroup>
              {ctx.formik.values.hasAdresseCourrierDoublons && <AdresseDoublonErrorLink idpersonne={idpersonne} />}
              <FGWalterCheckboxInput
                name="courrierAutre"
                label={t(ETLCodes.EnvoiACetteAdresse)}
                disabled={ctx.formik.values.hasAdresseCourrierDoublons}
              />
              <FGWalterSelectInput
                name="adresseCourrierApprenant.pays"
                label={t(ETLCodes.Pays)}
                items={pays}
                loading={pLoading}
                disabled={ctx.formik.values.hasAdresseCourrierDoublons}
              />
              <FGTextAreaInput
                name="adresseCourrierApprenant.adresse"
                label={t(ETLCodes.Adresse)}
                disabled={ctx.formik.values.hasAdresseCourrierDoublons}
              />
              <FGPersonneCodePostal
                idName="adresseCourrierApprenant.idcodePostal"
                textName="adresseCourrierApprenant.codePostalText"
                localiteName="adresseCourrierApprenant.localite"
                disabled={ctx.formik.values.hasAdresseCourrierDoublons}
              />
              <LocaliteSelect
                codePostal={ctx?.formik?.values?.adresseCourrierApprenant?.codePostalText}
                codePays={ctx?.formik?.values?.adresseCourrierApprenant?.pays}
                name="adresseCourrierApprenant.localite"
                codePostalTextName="adresseCourrierApprenant.codePostalText"
                disableIfNoCp={false}
                disabled={ctx.formik.values.hasAdresseCourrierDoublons}
              />
              <CommuneSelect
                codePostal={ctx?.formik?.values?.adresseCourrierApprenant?.codePostalText}
                codePays={ctx?.formik?.values?.adresseCourrierApprenant?.pays}
                name="adresseCourrierApprenant.commune"
                disabled={ctx.formik.values.hasAdresseCourrierDoublons}
              />
            </FieldGroup>
          </FieldGroup>
        )}
      </FGCustomPanel>
      <Divider style={{ marginBottom: "1rem" }} />
      <FieldGroup columns={[4, 4, 4]}>
        <FieldGroup>
          <FGCustomPanel>
            {ctx =>
              (!ctx.formik.values?.idstatutEntree ||
                !!ctx.formik.values?.idstatutSortie ||
                (!!ctx.formik.values?.idstatutEntree &&
                  !ctx.formik.values?.idParcoursFormation &&
                  !isProspect?.value)) &&
              +idApprenant > 0 && (
                <AddParcoursFormationButton
                  idApprenant={+idApprenant}
                  onClose={(toRefresh: boolean) => {
                    ctx.formik.resetForm(ctx.formik.values);
                    if (toRefresh) {
                      if (isHope) {
                        history.push(`${ERoutes.apprenant}/${idApprenant}/parcoursFormation/view`);
                      } else {
                        history.push(`${ERoutes.apprenant}/${idApprenant}/inscription/0/detail/edit`);
                      }
                    }
                  }}
                  loading={loading}
                  idCategorieStatut={ctx.formik.values?.idCategorieStatut}
                  idStatut={ctx.formik.values?.idStatut}
                  iddureeInoccupation={ctx.formik.values?.iddureeInoccupation}
                  dateEntree={
                    !!data?.dateEntreeReseau && !!data?.idstatutEntree
                      ? data?.dateSortie > data?.dateEntreeReseau
                        ? data?.dateSortie
                        : data?.dateEntreeReseau
                      : new Date()
                  }
                  minDateEntree={data?.lastDateSortie}
                  title={isHope ? t(ETLCodes.NouveauParcoursFormation) : t(ETLCodes.NouvelleInscription)}
                />
              )
            }
          </FGCustomPanel>
          <FGCustomPanel>
            {ctx => (
              <>
                <FGWalterDateMaskInput
                  name="dateEntreeReseau"
                  label={t(ETLCodes.DateEntreeReseau)}
                  readonly={+idApprenant > 0}
                />
                <FGReadOnlyInput
                  name="contrat"
                  label={t(ETLCodes.Contrat)}
                  rightElement={
                    <ViewButton
                      minimal
                      disabled={!ctx.formik.values.contrat?.idcontrat}
                      onClick={() =>
                        window.open(
                          `#${ERoutes.contrat}/${ctx.formik.values.contrat?.idcontrat}/detail/${ctx.formik.values.contrat?.type}/view`
                        )
                      }
                    />
                  }
                  leftElement={
                    <CodeContrat color={computeContratStatutColor(ctx.formik.values.contrat?.statut, theme)}>
                      {ctx.formik.values.contrat?.code}
                    </CodeContrat>
                  }
                />
                {!!ctx.formik.values?.idstatutEntree && (
                  <>
                    <FGWalterSelectInput
                      name="idCategorieStatut"
                      label={t(ETLCodes.CategorieStatut)}
                      items={categoriesStatutSocial}
                      loading={cssLoading}
                      readonly={!!ctx.formik.values?.idstatutEntree}
                      rightElement={
                        !!ctx.formik.values.dateEntreeReseau &&
                        !ctx.formik.values.idCategorieStatut && (
                          <MissingDataContainer>
                            <ErrorText text={t(ETLCodes.DonneeManquante)} />
                            <EditButton
                              onClick={() => history.push(`${ERoutes.apprenant}/${idApprenant}/statutMotifs/edit`)}
                            />
                          </MissingDataContainer>
                        )
                      }
                    />
                    <FGWalterSelectInput
                      name="idStatut"
                      label={t(ETLCodes.Statut)}
                      items={statutsSocial}
                      loading={ssLoading}
                      readonly={!!ctx.formik.values?.idstatutEntree}
                      rightElement={
                        !!ctx.formik.values.dateEntreeReseau &&
                        !ctx.formik.values.idStatut && (
                          <MissingDataContainer>
                            <ErrorText text={t(ETLCodes.DonneeManquante)} />
                            <EditButton
                              onClick={() => history.push(`${ERoutes.apprenant}/${idApprenant}/statutMotifs/edit`)}
                            />
                          </MissingDataContainer>
                        )
                      }
                    />
                    {showDureeInoccupation && (
                      <FGWalterSelectInput
                        name="iddureeInoccupation"
                        label={t(ETLCodes.DureeInoccupation)}
                        items={dureeInoccupations}
                        loading={diLoading}
                        readonly
                        rightElement={
                          !!ctx.formik.values.idCategorieStatut &&
                          !!ctx.formik.values.idStatut &&
                          !ctx.formik.values.iddureeInoccupation && (
                            <MissingDataContainer>
                              <ErrorText text={t(ETLCodes.DonneeManquante)} />
                              <EditButton
                                onClick={() => history.push(`${ERoutes.apprenant}/${idApprenant}/statutMotifs/edit`)}
                              />
                            </MissingDataContainer>
                          )
                        }
                      />
                    )}
                  </>
                )}
              </>
            )}
          </FGCustomPanel>
          <FGCustomReadOnly name="nbJoursRechercheEntreprise" label={t(ETLCodes.RechercheEntreprise)}>
            {(val, formik) => (
              <RoContainer
                color={!!val || val === 0 ? (val >= 90 ? theme.dangerColor : theme.warningColor) : theme.sucessColor}
              >
                {t(
                  !val && val !== 0
                    ? ETLCodes.General_No
                    : val === 0
                    ? ETLCodes.DepuisAujourdhui
                    : ETLCodes.DepuisNJours,
                  { nb: val }
                )}
                {!!formik.values.dateDebutRechercheEntreprise &&
                  ` (${format(formik.values.dateDebutRechercheEntreprise, "dd/MM/yyyy")})`}
              </RoContainer>
            )}
          </FGCustomReadOnly>
          <FGCustomPanel>
            {ctx =>
              !!ctx.formik.values?.idstatutEntree &&
              !ctx.formik.values?.idstatutSortie &&
              !!ctx.formik?.values?.idParcoursFormation ? (
                <SortieReseauButton
                  idApprenant={ctx.formik.values?.idapprenant}
                  dateStatut={ctx.formik.values?.dateSortie}
                  idCategorieStatut={ctx.formik.values?.idCategorieStatut}
                  idParcoursFormation={ctx.formik.values?.idParcoursFormation}
                  idStatut={ctx.formik.values?.idStatut}
                  onClose={() => {
                    refresh();
                    setMustLoadPicture(true);
                  }}
                  loading={loading}
                  withConfirm={!!ctx.formik.values?.idParcoursFormation}
                  isSortieEnCours={ctx.formik.values?.isSortieEnCours}
                  iddureeInoccupation={ctx.formik.values?.iddureeInoccupation}
                />
              ) : (
                !!ctx.formik.values?.idstatutSortie && (
                  <>
                    <FGWalterDateMaskInput name="dateSortie" label={t(ETLCodes.DateSortieReseau)} readonly />
                    <FGWalterSelectInput
                      name="idtypeSortie"
                      label={t(ETLCodes.TypeSortie)}
                      items={typesSortie}
                      loading={tLoading}
                      readonly
                      rightElement={
                        !ctx.formik.values.idtypeSortie && (
                          <MissingDataContainer>
                            <ErrorText text={t(ETLCodes.DonneeManquante)} />
                            <EditButton
                              onClick={() => history.push(`${ERoutes.apprenant}/${idApprenant}/statutMotifs/edit`)}
                            />
                          </MissingDataContainer>
                        )
                      }
                    />
                    <FGWalterSelectInput
                      name="idMotifSortie"
                      label={t(ETLCodes.MotifSortieReseau)}
                      items={motifsSortie}
                      loading={msLoading}
                      readonly
                      rightElement={
                        !ctx.formik.values.idMotifSortie && (
                          <MissingDataContainer>
                            <ErrorText text={t(ETLCodes.DonneeManquante)} />
                            <EditButton
                              onClick={() => history.push(`${ERoutes.apprenant}/${idApprenant}/statutMotifs/edit`)}
                            />
                          </MissingDataContainer>
                        )
                      }
                    />
                  </>
                )
              )
            }
          </FGCustomPanel>
        </FieldGroup>
        <FieldGroup>
          <FGWalterSelectInput
            name="idPri"
            label={t(ETLCodes.PersonneReferenceIfapme)}
            items={users}
            loading={usersLoading}
            readonly={
              !isHope ||
              (!!data?.contrat &&
                [EStatutContrat.EnCours, EStatutContrat.Suspendu].includes(data?.contrat?.statut) &&
                [ETypeContrat.CC, ETypeContrat.CS, ETypeContrat.PP, ETypeContrat.DM, ETypeContrat.CF].includes(
                  data?.contrat?.type
                ))
            }
          />
          <FGWalterSelectInput
            name="idAri"
            label={t(ETLCodes.ResponsableAdministratif)}
            items={usersAri}
            loading={usersAriLoading}
            readonly={
              !isHope ||
              (!!data?.contrat &&
                [EStatutContrat.EnCours, EStatutContrat.Suspendu].includes(data?.contrat?.statut) &&
                [ETypeContrat.CC, ETypeContrat.CS, ETypeContrat.PP, ETypeContrat.DM, ETypeContrat.CF].includes(
                  data?.contrat?.type
                ))
            }
          />
          <FGCustomPanel>
            {ctx => (
              <>
                {!!ctx?.formik?.values?.idCop && (isCalcOrAdmin || isUserCop) && (
                  <FGWalterSelectInput
                    name="idCop"
                    label={t(ETLCodes.Cop)}
                    items={usersCop}
                    loading={usersCopLoading}
                    readonly
                    helperText={
                      // TODO: Replace by rightElement when nsitools ready
                      ctx?.formik?.values?.idCop &&
                      isUserCop &&
                      (ctx?.formik?.values?.idCop === user?.iduser ? (
                        <LinkButton
                          icon="small-cross"
                          text={t(ETLCodes.MeDelierApprenant)}
                          onClick={() => ctx?.formik?.setFieldValue("idCop", null)}
                        />
                      ) : (
                        <LinkButton
                          icon="swap-horizontal"
                          text={t(ETLCodes.MAssignerApprenant)}
                          onClick={() => ctx?.formik?.setFieldValue("idCop", user?.iduser)}
                        />
                      ))
                    }
                  />
                )}
                <ButtonContainer>
                  {!ctx?.formik?.values?.idCop && isUserCop && (
                    <Button
                      intent="primary"
                      text={t(ETLCodes.MAssignerApprenant)}
                      icon={"link"}
                      onClick={() => ctx?.formik?.setFieldValue("idCop", user?.iduser)}
                    />
                  )}
                </ButtonContainer>
                {isCalcOrAdmin && !ctx?.formik?.values?.idCop && (
                  <FGCustomInput label={t(ETLCodes.Cop)}>
                    {ctx => (
                      <div style={{ display: "flex", alignItems: "center", height: 30 }}>
                        {t(ETLCodes.PasDeCopAssigné)}
                      </div>
                    )}
                  </FGCustomInput>
                )}
              </>
            )}
          </FGCustomPanel>
          {isFullCreation ? (
            <>
              <FGIBANNumberInput
                name="compteBancaire.iban"
                label={t(ETLCodes.NumeroBanque)}
                readonly={+idApprenant <= 0 && idpersonne >= 0 && !!data?.compteBancaire?.iban}
              />
              <FGTextInput
                name="compteBancaire.bic"
                label={t(ETLCodes.CodeBIC)}
                maxLength={15}
                loading={bicLoading}
                readonly={+idApprenant <= 0 && idpersonne >= 0 && !!data?.compteBancaire?.bic}
              />
            </>
          ) : (
            <FGWalterSelectInput
              name="compteBancaire.idcompteBancaire"
              label={t(ETLCodes.NumeroBanque)}
              loading={personneComptesBancairesLoading}
              items={personneComptesBancaires}
              visible={!isFullCreation}
              itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
              itemCreateParams="&typeCb=MAIN"
              autoSelectIfOne
              renderItem={item => <IbanDisplay options={ibanCleaveOptions} readOnly value={item?.label} />}
              helperText={ctx => {
                var foundBic = rawPersonneComptesBancaires?.find(
                  c => +c.idValue === ctx.formik?.values?.compteBancaire?.idcompteBancaire
                )?.keyValue;
                if (!foundBic) return;
                return `${t(ETLCodes.CodeBIC)}: ${foundBic}`;
              }}
            />
          )}
          <FGCustomInput label={t(ETLCodes.BankAccountBeneficiary)}>
            {ctx =>
              ctx?.editMode ? (
                <RadioGroup
                  selectedValue={!ctx?.formik?.values?.bankAccountBeneficiaryId ? "1" : "0"}
                  inline
                  onChange={e => {
                    if (e.currentTarget.value === "1") {
                      ctx.formik.setFieldValue("bankAccountBeneficiaryId", null);
                    } else {
                      ctx.formik.setFieldValue("bankAccountBeneficiaryId", -1);
                    }
                  }}
                >
                  <Radio label={t(ETLCodes.Apprenant)} value="1"></Radio>
                  <Radio label={t(ETLCodes.Representant)} value="0"></Radio>
                </RadioGroup>
              ) : (
                <BeneficiaryReadonlyContainer>
                  {!ctx?.formik?.values?.bankAccountBeneficiaryId ? t(ETLCodes.Apprenant) : t(ETLCodes.Representant)} (
                  {ctx?.formik?.values?.bankAccountBeneficiaryName})
                </BeneficiaryReadonlyContainer>
              )
            }
          </FGCustomInput>
          <FGCustomPanel>
            {ctx =>
              !!ctx?.formik?.values?.bankAccountBeneficiaryId &&
              ctx?.editMode && (
                <FGWalterSelectInput
                  label={t(ETLCodes.BeneficiaryName)}
                  name="bankAccountBeneficiaryId"
                  items={representatives}
                  loading={rLoading}
                />
              )
            }
          </FGCustomPanel>
        </FieldGroup>
        <FieldGroup />
      </FieldGroup>
      <Divider style={{ marginBottom: "1rem" }} />
      <FieldGroup columns={3}>
        <FGTextAreaInput name="remarque" label={t(ETLCodes.RemarqueCentre)} readonly={isHope} />
        <FGTextAreaInput name="remarqueService" label={t(ETLCodes.RemarqueService)} readonly={!isHope} />
        <FGEmpty />
        <FGTextAreaInput name="interne" label={t(ETLCodes.Interne)} />
        <FGEmpty />
        <FGEmpty />
      </FieldGroup>
      <StyledError show={hasDoublonsName} message={t(ETLCodes.DoublonExistsConfirm)}></StyledError>
      <StyledError show={hasDoublonsNatNumber} message={t(ETLCodes.DoublonsNatNumberExitsConfirm)}></StyledError>
      <FGCustomPanel>
        {ctx => (
          <DialogStyled
            isOpen={photoDialogOpen}
            onClose={() => setPhotoDialogOpen(false)}
            canOutsideClickClose={true}
            canEscapeKeyClose={true}
            title={`Photo ${ctx.formik.values?.nom ?? ""} ${ctx.formik.values?.prenom ?? ""}`}
            style={{ width: "90%", height: "800px" }}
            enforceFocus={false}
          >
            <PhotoModifier
              photo={ctx.formik.values?.photo}
              onSave={(newPhoto: any) => {
                ctx.formik.setFieldValue("photo", newPhoto);
                setPhotoDialogOpen(false);
              }}
              onCancel={() => setPhotoDialogOpen(false)}
            />
          </DialogStyled>
        )}
      </FGCustomPanel>
      {!!statutApprenantEditOpen && (
        <EditStatutEntreeApprenantDialog
          idapprenant={+idApprenant}
          currentId={statutApprenantEditOpen}
          isDialogOpen={!!statutApprenantEditOpen}
          onClose={onStatutApprenantEditClose}
        />
      )}
    </SmallFormGenerator>
  );
};
