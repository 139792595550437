/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserActionDto
 */
export interface UserActionDto {
    /**
     * 
     * @type {string}
     * @memberof UserActionDto
     */
    module?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserActionDto
     */
    tab?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserActionDto
     */
    permission?: string | null;
}

/**
 * Check if a given object implements the UserActionDto interface.
 */
export function instanceOfUserActionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserActionDtoFromJSON(json: any): UserActionDto {
    return UserActionDtoFromJSONTyped(json, false);
}

export function UserActionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserActionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'module': !exists(json, 'module') ? undefined : json['module'],
        'tab': !exists(json, 'tab') ? undefined : json['tab'],
        'permission': !exists(json, 'permission') ? undefined : json['permission'],
    };
}

export function UserActionDtoToJSON(value?: UserActionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'module': value.module,
        'tab': value.tab,
        'permission': value.permission,
    };
}

