/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeuillePresencePrintRequestDto
 */
export interface FeuillePresencePrintRequestDto {
    /**
     * 
     * @type {number}
     * @memberof FeuillePresencePrintRequestDto
     */
    periodeImpression?: number;
    /**
     * 
     * @type {Date}
     * @memberof FeuillePresencePrintRequestDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FeuillePresencePrintRequestDto
     */
    dateFin?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FeuillePresencePrintRequestDto
     */
    typeCours?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FeuillePresencePrintRequestDto
     */
    checkCalc?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeuillePresencePrintRequestDto
     */
    checkStat?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FeuillePresencePrintRequestDto
     */
    idClasse?: number;
}

/**
 * Check if a given object implements the FeuillePresencePrintRequestDto interface.
 */
export function instanceOfFeuillePresencePrintRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FeuillePresencePrintRequestDtoFromJSON(json: any): FeuillePresencePrintRequestDto {
    return FeuillePresencePrintRequestDtoFromJSONTyped(json, false);
}

export function FeuillePresencePrintRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeuillePresencePrintRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'periodeImpression': !exists(json, 'periodeImpression') ? undefined : json['periodeImpression'],
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
        'typeCours': !exists(json, 'typeCours') ? undefined : json['typeCours'],
        'checkCalc': !exists(json, 'checkCalc') ? undefined : json['checkCalc'],
        'checkStat': !exists(json, 'checkStat') ? undefined : json['checkStat'],
        'idClasse': !exists(json, 'idClasse') ? undefined : json['idClasse'],
    };
}

export function FeuillePresencePrintRequestDtoToJSON(value?: FeuillePresencePrintRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'periodeImpression': value.periodeImpression,
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
        'typeCours': value.typeCours,
        'checkCalc': value.checkCalc,
        'checkStat': value.checkStat,
        'idClasse': value.idClasse,
    };
}

