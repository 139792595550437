import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { SecteurClasseHomogeneDetailPage } from "..";
import { SecteurHomogeneApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

interface ISecteurClasseHomogeneItemPageProps {}

export const SecteurClasseHomogeneItemPage: React.FunctionComponent<ISecteurClasseHomogeneItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();
  const secteurClasseHomogeneApi = useApiService(SecteurHomogeneApi);
  const [displayName] = useApiEffect(() => secteurClasseHomogeneApi.secteurHomogeneGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.SecteursClasseHomogene), route: ERoutes.secteurClasseHomogene },
        { text: id === "-1" ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="Tabs"
        onChange={newTabId => history.push(`${ERoutes.secteurClasseHomogene}/${id}/${state}/${newTabId}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.secteurClasseHomogene}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<SecteurClasseHomogeneDetailPage editMode={state === "edit"} idSecteur={+id} />}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
