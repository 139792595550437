/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileUploadDto } from './FileUploadDto';
import {
    FileUploadDtoFromJSON,
    FileUploadDtoFromJSONTyped,
    FileUploadDtoToJSON,
} from './FileUploadDto';

/**
 * 
 * @export
 * @interface ContratAvenantEditDto
 */
export interface ContratAvenantEditDto {
    /**
     * 
     * @type {number}
     * @memberof ContratAvenantEditDto
     */
    idavenant?: number;
    /**
     * 
     * @type {number}
     * @memberof ContratAvenantEditDto
     */
    idcontrat?: number;
    /**
     * 
     * @type {number}
     * @memberof ContratAvenantEditDto
     */
    numero?: number;
    /**
     * 
     * @type {Date}
     * @memberof ContratAvenantEditDto
     */
    datePriseEnCours?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratAvenantEditDto
     */
    dateSignature?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ContratAvenantEditDto
     */
    idcpSignature?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratAvenantEditDto
     */
    dateCommissionTutelle?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ContratAvenantEditDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratAvenantEditDto
     */
    deletionDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ContratAvenantEditDto
     */
    idfichier?: number | null;
    /**
     * 
     * @type {FileUploadDto}
     * @memberof ContratAvenantEditDto
     */
    fichier?: FileUploadDto;
    /**
     * 
     * @type {Array<number>}
     * @memberof ContratAvenantEditDto
     */
    idstypeAvenant?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContratAvenantEditDto
     */
    isContratCS?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContratAvenantEditDto
     */
    decisionInstitut?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ContratAvenantEditDto
     */
    dateDecision?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ContratAvenantEditDto
     */
    idresponsableValidation?: number | null;
}

/**
 * Check if a given object implements the ContratAvenantEditDto interface.
 */
export function instanceOfContratAvenantEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContratAvenantEditDtoFromJSON(json: any): ContratAvenantEditDto {
    return ContratAvenantEditDtoFromJSONTyped(json, false);
}

export function ContratAvenantEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContratAvenantEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idavenant': !exists(json, 'idavenant') ? undefined : json['idavenant'],
        'idcontrat': !exists(json, 'idcontrat') ? undefined : json['idcontrat'],
        'numero': !exists(json, 'numero') ? undefined : json['numero'],
        'datePriseEnCours': !exists(json, 'datePriseEnCours') ? undefined : (json['datePriseEnCours'] === null ? null : new Date(json['datePriseEnCours'])),
        'dateSignature': !exists(json, 'dateSignature') ? undefined : (json['dateSignature'] === null ? null : new Date(json['dateSignature'])),
        'idcpSignature': !exists(json, 'idcpSignature') ? undefined : json['idcpSignature'],
        'dateCommissionTutelle': !exists(json, 'dateCommissionTutelle') ? undefined : (json['dateCommissionTutelle'] === null ? null : new Date(json['dateCommissionTutelle'])),
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'deletionDate': !exists(json, 'deletionDate') ? undefined : (json['deletionDate'] === null ? null : new Date(json['deletionDate'])),
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'fichier': !exists(json, 'fichier') ? undefined : FileUploadDtoFromJSON(json['fichier']),
        'idstypeAvenant': !exists(json, 'idstypeAvenant') ? undefined : json['idstypeAvenant'],
        'isContratCS': !exists(json, 'isContratCS') ? undefined : json['isContratCS'],
        'decisionInstitut': !exists(json, 'decisionInstitut') ? undefined : json['decisionInstitut'],
        'dateDecision': !exists(json, 'dateDecision') ? undefined : (json['dateDecision'] === null ? null : new Date(json['dateDecision'])),
        'idresponsableValidation': !exists(json, 'idresponsableValidation') ? undefined : json['idresponsableValidation'],
    };
}

export function ContratAvenantEditDtoToJSON(value?: ContratAvenantEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idavenant': value.idavenant,
        'idcontrat': value.idcontrat,
        'numero': value.numero,
        'datePriseEnCours': value.datePriseEnCours === undefined ? undefined : (value.datePriseEnCours === null ? null : value.datePriseEnCours.toISOString()),
        'dateSignature': value.dateSignature === undefined ? undefined : (value.dateSignature === null ? null : value.dateSignature.toISOString()),
        'idcpSignature': value.idcpSignature,
        'dateCommissionTutelle': value.dateCommissionTutelle === undefined ? undefined : (value.dateCommissionTutelle === null ? null : value.dateCommissionTutelle.toISOString()),
        'remarque': value.remarque,
        'deletionDate': value.deletionDate === undefined ? undefined : (value.deletionDate === null ? null : value.deletionDate.toISOString()),
        'idfichier': value.idfichier,
        'fichier': FileUploadDtoToJSON(value.fichier),
        'idstypeAvenant': value.idstypeAvenant,
        'isContratCS': value.isContratCS,
        'decisionInstitut': value.decisionInstitut,
        'dateDecision': value.dateDecision === undefined ? undefined : (value.dateDecision === null ? null : value.dateDecision.toISOString()),
        'idresponsableValidation': value.idresponsableValidation,
    };
}

