/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdresseDto } from './AdresseDto';
import {
    AdresseDtoFromJSON,
    AdresseDtoFromJSONTyped,
    AdresseDtoToJSON,
} from './AdresseDto';
import type { CompteBancaireDto } from './CompteBancaireDto';
import {
    CompteBancaireDtoFromJSON,
    CompteBancaireDtoFromJSONTyped,
    CompteBancaireDtoToJSON,
} from './CompteBancaireDto';

/**
 * 
 * @export
 * @interface ContactSiegeDoublonGridDto
 */
export interface ContactSiegeDoublonGridDto {
    /**
     * 
     * @type {number}
     * @memberof ContactSiegeDoublonGridDto
     */
    idcontact?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContactSiegeDoublonGridDto
     */
    idcontactSiege?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContactSiegeDoublonGridDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeDoublonGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeDoublonGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {AdresseDto}
     * @memberof ContactSiegeDoublonGridDto
     */
    adresseDto?: AdresseDto;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeDoublonGridDto
     */
    readonly adresse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeDoublonGridDto
     */
    readonly codePostal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeDoublonGridDto
     */
    readonly localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeDoublonGridDto
     */
    nationalite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeDoublonGridDto
     */
    codePaysNaissance?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ContactSiegeDoublonGridDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeDoublonGridDto
     */
    registreNational?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeDoublonGridDto
     */
    numeroIdentification?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeDoublonGridDto
     */
    codesexe?: string | null;
    /**
     * 
     * @type {CompteBancaireDto}
     * @memberof ContactSiegeDoublonGridDto
     */
    compteBancaire?: CompteBancaireDto;
    /**
     * 
     * @type {boolean}
     * @memberof ContactSiegeDoublonGridDto
     */
    beid?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeDoublonGridDto
     */
    beidnumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeDoublonGridDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ContactSiegeDoublonGridDto
     */
    dateDeces?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeDoublonGridDto
     */
    localiteNaissance?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeDoublonGridDto
     */
    communeNaissance?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContactSiegeDoublonGridDto
     */
    isApprenant?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactSiegeDoublonGridDto
     */
    isFormateur?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactSiegeDoublonGridDto
     */
    isRepresentant?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactSiegeDoublonGridDto
     */
    isTuteur?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactSiegeDoublonGridDto
     */
    isChefEntreprise?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactSiegeDoublonGridDto
     */
    isCollaborateur?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactSiegeDoublonGridDto
     */
    isDelegueTutelle?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactSiegeDoublonGridDto
     */
    isConseillePedagogique?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactSiegeDoublonGridDto
     */
    isContact?: boolean;
}

/**
 * Check if a given object implements the ContactSiegeDoublonGridDto interface.
 */
export function instanceOfContactSiegeDoublonGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContactSiegeDoublonGridDtoFromJSON(json: any): ContactSiegeDoublonGridDto {
    return ContactSiegeDoublonGridDtoFromJSONTyped(json, false);
}

export function ContactSiegeDoublonGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactSiegeDoublonGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontact': !exists(json, 'idcontact') ? undefined : json['idcontact'],
        'idcontactSiege': !exists(json, 'idcontactSiege') ? undefined : json['idcontactSiege'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'adresseDto': !exists(json, 'adresseDto') ? undefined : AdresseDtoFromJSON(json['adresseDto']),
        'adresse': !exists(json, 'adresse') ? undefined : json['adresse'],
        'codePostal': !exists(json, 'codePostal') ? undefined : json['codePostal'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'nationalite': !exists(json, 'nationalite') ? undefined : json['nationalite'],
        'codePaysNaissance': !exists(json, 'codePaysNaissance') ? undefined : json['codePaysNaissance'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'registreNational': !exists(json, 'registreNational') ? undefined : json['registreNational'],
        'numeroIdentification': !exists(json, 'numeroIdentification') ? undefined : json['numeroIdentification'],
        'codesexe': !exists(json, 'codesexe') ? undefined : json['codesexe'],
        'compteBancaire': !exists(json, 'compteBancaire') ? undefined : CompteBancaireDtoFromJSON(json['compteBancaire']),
        'beid': !exists(json, 'beid') ? undefined : json['beid'],
        'beidnumber': !exists(json, 'beidnumber') ? undefined : json['beidnumber'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'dateDeces': !exists(json, 'dateDeces') ? undefined : (json['dateDeces'] === null ? null : new Date(json['dateDeces'])),
        'localiteNaissance': !exists(json, 'localiteNaissance') ? undefined : json['localiteNaissance'],
        'communeNaissance': !exists(json, 'communeNaissance') ? undefined : json['communeNaissance'],
        'isApprenant': !exists(json, 'isApprenant') ? undefined : json['isApprenant'],
        'isFormateur': !exists(json, 'isFormateur') ? undefined : json['isFormateur'],
        'isRepresentant': !exists(json, 'isRepresentant') ? undefined : json['isRepresentant'],
        'isTuteur': !exists(json, 'isTuteur') ? undefined : json['isTuteur'],
        'isChefEntreprise': !exists(json, 'isChefEntreprise') ? undefined : json['isChefEntreprise'],
        'isCollaborateur': !exists(json, 'isCollaborateur') ? undefined : json['isCollaborateur'],
        'isDelegueTutelle': !exists(json, 'isDelegueTutelle') ? undefined : json['isDelegueTutelle'],
        'isConseillePedagogique': !exists(json, 'isConseillePedagogique') ? undefined : json['isConseillePedagogique'],
        'isContact': !exists(json, 'isContact') ? undefined : json['isContact'],
    };
}

export function ContactSiegeDoublonGridDtoToJSON(value?: ContactSiegeDoublonGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontact': value.idcontact,
        'idcontactSiege': value.idcontactSiege,
        'idpersonne': value.idpersonne,
        'nom': value.nom,
        'prenom': value.prenom,
        'adresseDto': AdresseDtoToJSON(value.adresseDto),
        'nationalite': value.nationalite,
        'codePaysNaissance': value.codePaysNaissance,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'registreNational': value.registreNational,
        'numeroIdentification': value.numeroIdentification,
        'codesexe': value.codesexe,
        'compteBancaire': CompteBancaireDtoToJSON(value.compteBancaire),
        'beid': value.beid,
        'beidnumber': value.beidnumber,
        'remarque': value.remarque,
        'dateDeces': value.dateDeces === undefined ? undefined : (value.dateDeces === null ? null : value.dateDeces.toISOString()),
        'localiteNaissance': value.localiteNaissance,
        'communeNaissance': value.communeNaissance,
        'isApprenant': value.isApprenant,
        'isFormateur': value.isFormateur,
        'isRepresentant': value.isRepresentant,
        'isTuteur': value.isTuteur,
        'isChefEntreprise': value.isChefEntreprise,
        'isCollaborateur': value.isCollaborateur,
        'isDelegueTutelle': value.isDelegueTutelle,
        'isConseillePedagogique': value.isConseillePedagogique,
        'isContact': value.isContact,
    };
}

