import * as React from "react";
import { Route, Switch } from "react-router";

import { ERoutes } from "../../../../../AppRouter";
import { DeliberationBulletinTabs } from "./DeliberationBulletinTabs";

export interface IDeliberationBulletinSwitchProps {}

export const DeliberationBulletinSwitch: React.FunctionComponent<IDeliberationBulletinSwitchProps> = () => {
  return (
    <Switch>
      <Route
        path={`${ERoutes.deliberationType}/:id/:tab/:idApprenant/:annee/:coursTypeTab?`}
        component={DeliberationBulletinTabs}
      />
    </Switch>
  );
};
