/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbClasseHoraireCoherenceGridDto
 */
export interface FcbClasseHoraireCoherenceGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbClasseHoraireCoherenceGridDto
     */
    idclasse?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseHoraireCoherenceGridDto
     */
    idclasseMatiere?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseHoraireCoherenceGridDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseHoraireCoherenceGridDto
     */
    code?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseHoraireCoherenceGridDto
     */
    nbheures?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseHoraireCoherenceGridDto
     */
    nbheuresPlanifie?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseHoraireCoherenceGridDto
     */
    difference?: number;
}

/**
 * Check if a given object implements the FcbClasseHoraireCoherenceGridDto interface.
 */
export function instanceOfFcbClasseHoraireCoherenceGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbClasseHoraireCoherenceGridDtoFromJSON(json: any): FcbClasseHoraireCoherenceGridDto {
    return FcbClasseHoraireCoherenceGridDtoFromJSONTyped(json, false);
}

export function FcbClasseHoraireCoherenceGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbClasseHoraireCoherenceGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'idclasseMatiere': !exists(json, 'idclasseMatiere') ? undefined : json['idclasseMatiere'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'nbheures': !exists(json, 'nbheures') ? undefined : json['nbheures'],
        'nbheuresPlanifie': !exists(json, 'nbheuresPlanifie') ? undefined : json['nbheuresPlanifie'],
        'difference': !exists(json, 'difference') ? undefined : json['difference'],
    };
}

export function FcbClasseHoraireCoherenceGridDtoToJSON(value?: FcbClasseHoraireCoherenceGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idclasse': value.idclasse,
        'idclasseMatiere': value.idclasseMatiere,
        'description': value.description,
        'code': value.code,
        'nbheures': value.nbheures,
        'nbheuresPlanifie': value.nbheuresPlanifie,
        'difference': value.difference,
    };
}

