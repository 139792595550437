import {
  FGCustomPanel,
  FGMaskInput,
  FGNumberInput,
  FGTextAreaInput,
  FGTextInput,
  FieldGroup,
  IFGContext,
  showSuccess,
  useFGFormik,
  usePrevious
} from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";

import { LieuFormationAgrementGrid } from ".";
import { LieuFormationApi, LieuFormationEditDto, SiegeSocialApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  ChefEntrepriseSelectorField,
  CommuneSelect,
  FGWalterCheckboxInput,
  FGWalterSelectInput,
  LinkButton,
  LocaliteSelect,
  SaveButton,
  SmallFormGenerator
} from "../../../../../components";
import { useDialog } from "../../../../../contexts";
import { useApiService, useCrudApi, useTheme, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

const StatusContainer = styled.div<{ color: string }>`
  color: ${props => props.color};
  font-weight: bold;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

const LinkContainer = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

export interface ILieuFormationSignaletiqueProps {}

export const LieuFormationSignaletique: React.FunctionComponent<ILieuFormationSignaletiqueProps> = props => {
  const { t } = useTl();
  const api = useApiService(LieuFormationApi);
  const siegeSocialApi = useApiService(SiegeSocialApi);
  const { theme } = useTheme();
  const history = useHistory();
  const { id, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();

  const { search } = useLocation();
  const idsiegeSocial = React.useMemo(() => new URLSearchParams(search).get("idsiegeSocial"), [search]);

  const { showDialogPromise } = useDialog();
  const saveFn = React.useCallback(
    async (d: LieuFormationEditDto) => {
      const toSave = { ...d };
      if (!d.actif) {
        const result = await showDialogPromise({
          message: t(ETLCodes.DisableLieuFormationConfirmMessage),
          title: t(ETLCodes.DisableLieuFormationConfirmTitle)
        });
        if (result === "no") {
          return;
        }
      }
      return await api.lieuFormationSaveLieuFormation({ LieuFormationEditDto: toSave });
    },
    [api, showDialogPromise, t]
  );

  const { data, loading, saveItem, saving, validationErrors } = useCrudApi<LieuFormationEditDto>(
    React.useMemo(
      () => ({
        getApiFn: async () => {
          return api.lieuFormationGetLieuFormation({ id: +id, idsiegeSocial: +idsiegeSocial });
        },
        saveApiFn: saveFn,
        onSavedRoute: d => `${ERoutes.lieuFormation}/${d.idlieuFormation}/detail/edit?idsiegeSocial=${d.idsiegeSocial}`
      }),
      [api, id, idsiegeSocial, saveFn]
    )
  );

  const [siegeSociaux, loadingSiegeSociaux] = useReferential(
    a => a.referentialGetSiegeSociaux({ idsiegeSocial: +(idsiegeSocial ?? 0) }),
    true,
    [idsiegeSocial]
  );

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      nom: Yup.string().required(t(ETLCodes.Required)),
      numeroUe: Yup.string(),
      enseigne: Yup.string().nullable(),
      idsiegeSocial: Yup.number()
        .nullable()
        .required(t(ETLCodes.Required)),
      capaciteFormative: Yup.number().nullable(),
      actif: Yup.bool().required(t(ETLCodes.Required))
    });
  }, [t]);

  const formik = useFGFormik<LieuFormationEditDto>({
    initialValues: data,
    onSubmit: saveItem,
    validationSchema: FormSchema
  });

  const [pays, pLoading] = useReferential(a => a.referentialGetPays());
  const [codesPostaux, , , rawCodesPostaux] = useReferential(a => a.referentialGetCodePostauxWithLocalite());

  const previousIdcodePostal = usePrevious(formik?.values?.idcodePostal);
  React.useEffect(() => {
    if (formik?.values?.localite) {
      const idCp = +rawCodesPostaux?.find(cp => cp.keyValue === formik?.values?.localite)?.idValue;
      if (!idCp || idCp === previousIdcodePostal) {
        return;
      } else {
        formik?.setFieldValue("idcodePostal", idCp);
      }
    } else {
      if (!!formik?.values?.idcodePostal) {
        formik?.setFieldValue("idcodePostal", null);
      } else {
        return;
      }
    }
  }, [codesPostaux, formik, previousIdcodePostal, rawCodesPostaux]);

  const additionalRightButtons = React.useCallback(
    (ctx: IFGContext<any>) =>
      !!data?.idsiegeSocial &&
      +id <= 0 && (
        <SaveButton
          onClick={async () => {
            await saveFn(ctx.formik.values);
            showSuccess(t(ETLCodes.SaveSuccess));
            ctx.formik.resetForm();
          }}
          text={t(ETLCodes.SauverEtNouveau)}
          minimal={false}
          loading={saving}
          disabled={!ctx.formik.dirty}
        />
      ),
    [data?.idsiegeSocial, id, saveFn, saving, t]
  );

  const [idpersonne, setIdpersonne] = React.useState(data?.idpersonne);
  React.useEffect(() => {
    if (data?.idpersonne) {
      setIdpersonne(data?.idpersonne);
    }
  }, [data?.idpersonne]);
  const [personneTelephones, personneTelephonesLoading] = useReferential(
    a => a.referentialGetTelephonesPersonne({ idpersonne }),
    false,
    [idpersonne]
  );
  const [personneGsms, personneGsmsLoading] = useReferential(a => a.referentialGetGsmsPersonne({ idpersonne }), false, [
    idpersonne
  ]);

  const [personneEmails, personneEmailsLoading] = useReferential(
    a => a.referentialGetEmailsPersonne({ idpersonne }),
    false,
    [idpersonne]
  );

  const fetchSiegeSocialAdresse = React.useCallback(async () => {
    if (!idsiegeSocial || +id > 0) return null;
    return await siegeSocialApi.siegeSocialGetAdresseData({ idsiegeSocial: +idsiegeSocial });
  }, [id, idsiegeSocial, siegeSocialApi]);

  const { data: siegeSocialAdresse } = useQuery(["siege-social-adresse", idsiegeSocial], fetchSiegeSocialAdresse);

  React.useEffect(() => {
    if (!!formik?.values && !!siegeSocialAdresse?.adresse && !formik?.values?.adresse) {
      formik.setFieldValue("sendToAdress", true);
      formik.setFieldValue("adresse", siegeSocialAdresse?.adresse);
      formik.setFieldValue("codePostalText", siegeSocialAdresse?.codePostalText);
      formik.setFieldValue("idcodePostal", siegeSocialAdresse?.idcodePostal);
      formik.setFieldValue("commune", siegeSocialAdresse?.commune);
      formik.setFieldValue("localite", siegeSocialAdresse?.localite);
      formik.setFieldValue("pays", siegeSocialAdresse?.pays);
    }
  }, [formik, siegeSocialAdresse]);

  return (
    data && (
      <SmallFormGenerator
        formik={formik}
        editMode={state === "edit"}
        loading={loading}
        onCancel={() => history.push(ERoutes.siegeSocial)}
        showDeleteButton={+id > 0}
        saving={saving}
        minLabelWidth={250}
        additionalRightButtons={additionalRightButtons}
        validationErrors={validationErrors}
      >
        <FieldGroup columns={2}>
          <FieldGroup
            fieldsetProps={{
              title: t(ETLCodes.DonneesDeBase)
            }}
          >
            <FGWalterSelectInput
              name="idsiegeSocial"
              label={t(ETLCodes.SiegeSocial)}
              items={siegeSociaux}
              loading={loadingSiegeSociaux}
              onItemViewClick={value => window.open(`#${ERoutes.siegeSocial}/${value}/detail/edit`, "_blank")}
              renderReadOnlyValue={value =>
                !!value && (
                  <LinkContainer>
                    <LinkButton
                      text={siegeSociaux?.find(s => +s.value === +value)?.label}
                      onClick={() => window.open(`#${ERoutes.siegeSocial}/${value}/detail/edit`, "_blank")}
                    />
                  </LinkContainer>
                )
              }
            />
            <FGTextInput name="nom" label={t(ETLCodes.Nom)} maxLength={50} />
            <FGMaskInput
              name="numeroUe"
              label={t(ETLCodes.NumeroUe)}
              cleaveOptions={{ blocks: [1, 3, 3, 3], delimiter: "." }}
            />
            <FGTextInput name="enseigne" label={t(ETLCodes.Enseigne)} maxLength={50} />
            <FGNumberInput min={0} name="capaciteFormative" label={t(ETLCodes.CapaciteFormative)} />
            <FGCustomPanel>
              {ctx => (
                <FGWalterCheckboxInput
                  disabled={+id > 0 && !ctx?.formik?.values?.siegeSocialActif}
                  name="actif"
                  label={t(ETLCodes.StatutBCE)}
                  renderReadOnlyValue={value => (
                    <StatusContainer color={value ? theme.sucessColor : theme.dangerColor}>
                      {value ? t(ETLCodes.Actif) : t(ETLCodes.Inactif)}
                    </StatusContainer>
                  )}
                />
              )}
            </FGCustomPanel>
            <FGWalterCheckboxInput
              name="encodageProvisoireAdministratif"
              label={t(ETLCodes.EncodageProvisoireAdministratif)}
            />
          </FieldGroup>
          <FieldGroup
            fieldsetProps={{
              title: t(ETLCodes.Contacts)
            }}
          >
            <ChefEntrepriseSelectorField
              label={t(ETLCodes.ChefEntreprise)}
              name="idchefEntreprise"
              readonly
              helperText={ctx => ctx?.formik?.values?.tuteurAlreadyExists && t(ETLCodes.EstAussiTuteur)}
              idsiegeSocial={+idsiegeSocial}
            />
            <FGWalterSelectInput
              name="gsmChefEntreprise.idtelephone"
              label={t(ETLCodes.Gsm)}
              items={personneGsms}
              loading={personneGsmsLoading}
              itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
              itemCreateParams={`&typeTel=GSM_PERSO`}
              readonly
            />
            <FGWalterSelectInput
              name="telephoneChefEntreprise.idtelephone"
              label={t(ETLCodes.Tel)}
              items={personneTelephones}
              loading={personneTelephonesLoading}
              itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
              itemCreateParams={`&typeTel=PERSO`}
              readonly
            />
            <FGWalterSelectInput
              name="emailChefEntreprise.idemail"
              label={t(ETLCodes.General_Email)}
              loading={personneEmailsLoading}
              items={personneEmails}
              itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
              itemCreateParams={`&typeEmail=PERSO`}
              readonly
            />
            <FGTextInput name="contact" label={t(ETLCodes.Contact)} readonly />
            <FGTextInput name="contactFonction" label={t(ETLCodes.ContactFonction)} readonly />
            <FGTextInput name="contactGsm" label={t(ETLCodes.ContactGsm)} readonly />
            <FGTextInput name="contactTel" label={t(ETLCodes.ContactTel)} readonly />
            <FGCustomPanel>
              {ctx => (
                <FGWalterCheckboxInput
                  name="creerTuteur"
                  label={t(ETLCodes.DefinirCommeTuteur)}
                  disabled={!ctx?.formik?.values?.idchefEntreprise}
                  visible={!ctx?.formik?.values?.tuteurAlreadyExists}
                />
              )}
            </FGCustomPanel>
          </FieldGroup>
        </FieldGroup>
        <FieldGroup columns={2}>
          <FieldGroup
            fieldsetProps={{
              title: t(ETLCodes.CoordonneesLieuFormation)
            }}
          >
            <FGWalterCheckboxInput name="sendToAdress" label={t(ETLCodes.SendToAddress)} />
            <FGTextAreaInput labelStyles={{ minWidth: 100 }} name="adresse" label={t(ETLCodes.Adresse)} />
            <FGTextInput
              labelStyles={{ minWidth: 100 }}
              name="codePostalText"
              label={t(ETLCodes.CodePostal)}
              maxLength={6}
              // items={codesPostaux?.map(cp => cp.label)} TODO: SUGGEST WHEN DEBUGGED
              // loading={cpLoading}
            />
            <FGCustomPanel>
              {ctx => (
                <>
                  <LocaliteSelect
                    labelStyles={{ minWidth: 100 }}
                    codePostal={ctx?.formik?.values?.codePostalText}
                    codePays={ctx?.formik?.values?.pays}
                    name="localite"
                    codePostalTextName="codePostalText"
                    disableIfNoCp={false}
                  />
                  <CommuneSelect
                    labelStyles={{ minWidth: 100 }}
                    codePostal={ctx?.formik?.values?.codePostalText}
                    codePays={ctx?.formik?.values?.pays}
                    name="commune"
                  />
                </>
              )}
            </FGCustomPanel>
            <FGWalterSelectInput
              labelStyles={{ minWidth: 100 }}
              name="pays"
              label={t(ETLCodes.Pays)}
              items={pays}
              loading={pLoading}
            />
          </FieldGroup>
          <FieldGroup
            fieldsetProps={{
              title: t(ETLCodes.Remarque)
            }}
          >
            <FGTextAreaInput name="remarque" noLabel />
          </FieldGroup>
        </FieldGroup>
        <LieuFormationAgrementGrid lfActif={formik?.values?.actif} />
      </SmallFormGenerator>
    )
  );
};
