import { Classes, Colors, InputGroup } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { DatePicker } from "@blueprintjs/datetime";
import { FieldSet } from "nsitools-react";
import * as React from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import styled from "styled-components";

import { useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { FormikProps } from "formik";
import moment from "moment";

export interface ICalendrierDatePickerProps {
  edit?: boolean;
  formik?: FormikProps<any>;
}

const MONTHS = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre"
];

const WEEKDAYS_LONG = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];

const WEEKDAYS_SHORT = ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"];

const TickCheck = styled(InputGroup)`
  & .${Classes.ICON} {
    color: ${Colors.GREEN5};
  }
`;
const CrossCheck = styled(InputGroup)`
  & .${Classes.ICON} {
    color: ${Colors.RED5};
  }
`;

interface ICardAttributeProps {
  label: string;
  value: string | JSX.Element;
}

const LabelAttrt = styled.div`
  font-weight: bold;
  font-style: italic;
  font-size: 1em;
  color: black;
`;
const ValueAttr = styled.div`
  color: black;
`;

const CardAttributeContainer = styled.div`
  min-width: 160px;
  margin-bottom: 0.2em;
  color: black;
`;

const DateCardAttribute: React.FC<ICardAttributeProps> = ({ label, value }) => {
  return (
    <CardAttributeContainer>
      <LabelAttrt>{label}</LabelAttrt>
      <ValueAttr>{value}</ValueAttr>
    </CardAttributeContainer>
  );
};

export const CalendrierDatePicker: React.FunctionComponent<ICalendrierDatePickerProps> = ({ formik, edit = false }) => {
  const [selected, setSelected] = React.useState<Date>(null);
  const { t } = useTl();

  const holiday = React.useCallback(
    (day: Date) => {
      return JSON.stringify(
        formik.values.conges.map(c => {
          const d = new Date(c);

          return {
            day: d.getDate(),
            month: d.getMonth(),
            year: d.getFullYear()
          };
        })
      ).includes(JSON.stringify({ day: day.getDate(), month: day.getMonth(), year: day.getFullYear() }));
    },
    [formik]
  );

  const addHoliday = React.useCallback(
    (day: Date) => {
      const newDay = moment(day)
        .utcOffset(0, true)
        .toDate();

      const newHolidays = [...formik.values.conges, newDay].sort();
      formik.setFieldValue("conges", newHolidays);
    },
    [formik]
  );

  const removeHoliday = React.useCallback(
    (day: Date) => {
      const newHolidays = formik.values.conges.filter(c => {
        const d = new Date(c);

        return (
          JSON.stringify({ day: day.getDate(), month: day.getMonth(), year: day.getFullYear() }) !==
          JSON.stringify({ day: d.getDate(), month: d.getMonth(), year: d.getFullYear() })
        );
      });

      formik.setFieldValue("conges", newHolidays);
    },
    [formik]
  );

  const onDaySelected = React.useCallback(
    (selectedDate: Date, isUserChange: boolean) => {
      if (isUserChange) {
        setSelected(selectedDate);
        if (edit) {
          if (holiday(selectedDate)) {
            removeHoliday(selectedDate);
          } else {
            addHoliday(selectedDate);
          }
        }
      }
    },
    [addHoliday, edit, holiday, removeHoliday]
  );

  const holidayStyle = `
  .DayPicker-Day--holiday {
    background-color: ${Colors.RED5};
    color: white;
  }
  .DayPicker-Month {
    min-width: 500px;
    min-height: 350px;
  }
  .DayPicker-Day {
    border-radius: 60px !important;
  }
  `;

  const dateCheckBox = React.useCallback(
    (selectedDate: Date) => {
      if (holiday(selectedDate)) {
        return <TickCheck leftIcon={IconNames.TICK} readOnly={true} style={{ boxShadow: "none" }} />;
      } else {
        return <CrossCheck leftIcon={IconNames.CROSS} readOnly={true} style={{ boxShadow: "none" }} />;
      }
    },
    [holiday]
  );

  const dateToTranslatedString = React.useCallback(
    (day: Date) => {
      const dayOfWeek = day.getDay();
      const month = day.getMonth();
      return `${WEEKDAYS_LONG[dayOfWeek]} ${day.getDate()} ${MONTHS[month]} ${day.getFullYear()} (${
        dayOfWeek === 0 ? t(ETLCodes.Weekend) : t(ETLCodes.Semaine)
      })`;
    },
    [t]
  );

  const CalendarDatePicker = React.useMemo(
    () => (
      <FieldSet title={t(ETLCodes.GestionConges)}>
        <Grid fluid>
          <Row around="xs">
            <Col xs />

            <Col xs>
              <div>
                <style>{holidayStyle}</style>
                <DatePicker
                  initialMonth={new Date(formik.values.dateDebut)}
                  maxDate={new Date(formik.values.dateFin)}
                  minDate={new Date(formik.values.dateDebut)}
                  modifiers={{ holiday }}
                  dayPickerProps={{
                    fromMonth: new Date(formik.values.dateDebut),
                    toMonth: new Date(formik.values.dateFin),
                    firstDayOfWeek: 1,
                    showWeekNumbers: true,
                    locale: "fr",
                    months: MONTHS,
                    weekdaysLong: WEEKDAYS_LONG,
                    weekdaysShort: WEEKDAYS_SHORT,
                    disabledDays: {
                      before: new Date(formik.values.dateDebut),
                      after: new Date(formik.values.dateFin)
                    }
                  }}
                  onChange={onDaySelected}
                  value={null}
                />
              </div>
            </Col>

            <Col xs>
              {selected && (
                <>
                  <Row style={{ marginBottom: "10px", marginLeft: "10px" }}>
                    <DateCardAttribute label={t(ETLCodes.Date)} value={dateToTranslatedString(selected)} />
                  </Row>
                  <Row style={{ marginLeft: "10px" }}>
                    <DateCardAttribute label={t(ETLCodes.Conge)} value={dateCheckBox(selected)} />
                  </Row>
                </>
              )}
            </Col>
            <Col xs />
          </Row>
        </Grid>
      </FieldSet>
    ),
    [
      dateCheckBox,
      dateToTranslatedString,
      formik.values.dateDebut,
      formik.values.dateFin,
      holiday,
      holidayStyle,
      onDaySelected,
      selected,
      t
    ]
  );

  return CalendarDatePicker;
};
