import { FormikProps } from "formik";
import isEqual from "lodash/isEqual";
import { FGCustomPanel, FGMaskInput, FGTextInput, FieldGroup, IFGContext } from "nsitools-react";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router";
import * as Yup from "yup";

import {
  ChefEntrepriseApi,
  ChefEntrepriseEditDto,
  ChefEntrepriseEditDtoFromJSON,
  ChefsEntrepriseDoublonSearchDto
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  NationaliteSelect,
  SmallFormGenerator
} from "../../../../../components";
import { useBeIdCardContext, useDialog } from "../../../../../contexts";
import { useApiService, useCrudApi, useTabMessage, useTheme, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import { belgianIdValidate } from "../../../../../utils/belgianIdValidate";
import pick from "lodash/pick";

export interface IChefEntrepriseDetailProps {
  baseData: ChefsEntrepriseDoublonSearchDto;
}

export const ChefEntrepriseDetail: React.FunctionComponent<IChefEntrepriseDetailProps> = ({ baseData }) => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ChefEntrepriseApi);
  const formikRef = React.useRef<FormikProps<ChefEntrepriseEditDto>>();

  const { search } = useLocation();
  const cfs = React.useMemo(() => new URLSearchParams(search).get("cfs"), [search]);
  const { sendMessage } = useTabMessage(cfs);

  const { id, state } = useParams<{ id: string; state: string }>();
  const { card } = useBeIdCardContext();
  const [cardDataDifferent, setCardDataDifferent] = React.useState(false);
  const { showDialogPromise } = useDialog();
  const { theme } = useTheme();

  const { data, loading, saveItem, saving, validationErrors } = useCrudApi<ChefEntrepriseEditDto>(
    React.useMemo(
      () => ({
        getApiFn: () => {
          let newData = {};
          if (baseData) {
            newData = { ...baseData };
          }
          if (card) {
            newData = {
              ...newData,
              nom: card?.surname.toUpperCase(),
              prenom: card?.firstName,
              dateNaissance: card?.dateOfBirthParsed,
              registreNational: card?.nationalNumber
            };
          }
          return +id <= 0
            ? ChefEntrepriseEditDtoFromJSON({
                ...newData,
                idchefEntreprise: 0,
                actif: true
              })
            : api.chefEntrepriseGetChefEntreprise({ id: +id });
        },
        saveApiFn: async (d: ChefEntrepriseEditDto) => {
          return api.chefEntrepriseSaveChefEntreprise({ ChefEntrepriseEditDto: d });
        },
        onSaved: d => sendMessage(d.idchefEntreprise, true),
        onSavedRoute: d => `${ERoutes.chefEntreprise}/${d.idchefEntreprise}/detail/${state}`
      }),
      [api, baseData, card, id, sendMessage, state]
    )
  );

  const canUseCardData = React.useMemo(() => {
    if (+id > 0) {
      return !data?.registreNational || data?.registreNational === card?.nationalNumber;
    }

    return false;
  }, [card?.nationalNumber, data?.registreNational, id]);

  const askDataDifferent = React.useCallback(async () => {
    const result = await showDialogPromise({
      message: t(ETLCodes.ChefEntrepriseIdCardIsDifferent)
    });
    if (result === "yes") {
      setCardDataDifferent(true);
    }
  }, [showDialogPromise, t]);

  React.useEffect(() => {
    if (data && card) {
      const cardObj = ChefEntrepriseEditDtoFromJSON({
        nom: card?.surname.toUpperCase(),
        prenom: card?.firstName,
        codesexe: card?.gender,
        dateNaissance: card?.dateOfBirthParsed,
        registreNational: card?.nationalNumber
      });

      const propsToPick = ["nom", "prenom", "dateNaissance", "registreNational", "codesexe"];
      const cardBirthdate = new Date(card.dateOfBirthParsed);
      const cardBirthdateString = `${cardBirthdate.getDate()}/${cardBirthdate.getMonth() +
        1}/${cardBirthdate.getFullYear()}`;
      const dataBirthdateString = `${data.dateNaissance.getDate()}/${data.dateNaissance.getMonth() +
        1}/${data.dateNaissance.getFullYear()}`;
      if (
        +id > 0 &&
        (!isEqual(pick(cardObj, propsToPick), pick(data, propsToPick)) || cardBirthdateString !== dataBirthdateString)
      ) {
        if (canUseCardData) {
          askDataDifferent();
        }
      }
    }
  }, [askDataDifferent, canUseCardData, card, data, id]);

  React.useEffect(() => {
    if (cardDataDifferent) {
      setCardDataDifferent(false);
      formikRef?.current?.setValues(
        ChefEntrepriseEditDtoFromJSON({
          ...data,
          nom: card?.surname.toUpperCase() ?? data.nom,
          prenom: card?.firstName ?? data.prenom,
          dateNaissance: card?.dateOfBirthParsed ?? data.dateNaissance,
          codesexe: card?.gender ?? data.codesexe,
          registreNational: card?.nationalNumber ?? data.registreNational
        }),
        true
      );
    }
  }, [
    card?.dateOfBirthParsed,
    card?.firstName,
    card?.gender,
    card?.nationalNumber,
    card?.surname,
    cardDataDifferent,
    data
  ]);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape(
      {
        nom: Yup.string().required(t(ETLCodes.Required)),
        prenom: Yup.string().required(t(ETLCodes.Required)),
        dateNaissance: Yup.date().nullable(),
        registreNational: Yup.string()
          .nullable()
          .test("registre-checksum", t(ETLCodes.NotValid), function(value) {
            if (!value || value === "") return true;

            var millenial = false;
            if (this.parent.dateNaissance) {
              const parsedDateNaissance = new Date(this.parent.dateNaissance);
              if (parsedDateNaissance.getFullYear() > 1999) {
                millenial = true;
              }
            }

            return belgianIdValidate(value, millenial);
          }),
        numeroIdentification: Yup.string().nullable()
      },
      [
        ["numeroIdentification", "nationalite"],
        ["numeroIdentification", "registreNational"],
        ["registreNational", "nationalite"],
        ["registreNational", "numeroIdentification"]
      ]
    );
  }, [t]);

  const isRegNatDateNaiss = React.useCallback((ctx: IFGContext) => {
    const value = ctx.formik.values.registreNational?.replace(".", "").replace("-", "");
    if (ctx.formik.values.dateNaissance && ctx.formik.values.nationalite === "BE") {
      const parsedDateNaissance = new Date(ctx.formik.values.dateNaissance);
      if (parsedDateNaissance && value) {
        const year = value.slice(0, 2);
        const month = value.slice(2, 4);
        const day = value.slice(4, 6);
        if (
          +year !==
          +parsedDateNaissance
            .getFullYear()
            .toString()
            .substr(2, 2)
        ) {
          return false;
        }
        if (+month !== 0 && +month !== parsedDateNaissance.getMonth() + 1) {
          return false;
        }
        if (+day !== 0 && +day !== parsedDateNaissance.getDate()) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, []);

  const isRegNatGenre = React.useCallback((ctx: IFGContext) => {
    const value = ctx.formik.values.registreNational?.replace(".", "")?.replace("-", "");
    if (value && ctx.formik.values.codesexe !== "X" && ctx.formik.values.nationalite === "BE") {
      const code = +value.slice(7, 9) % 2 === 0 ? "F" : "H";
      return ctx.formik.values.codesexe === code;
    }
    return true;
  }, []);
  const [sexe, sLoading] = useReferential(a => a.referentialGetSexe());

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={state === "edit"}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(`${ERoutes.chefEntreprise}`)}
      saving={saving}
      validationErrors={validationErrors}
      showDeleteButton={false}
      forceEnableSave={Object.values(baseData).some(el => !!el)}
    >
      <FieldGroup columns={2}>
        <FieldGroup>
          <FGTextInput name="nom" label={t(ETLCodes.Nom)} maxLength={50} forceCase="upper" />
          <FGTextInput name="prenom" label={t(ETLCodes.Prenom)} maxLength={100} />
          <FGWalterDateMaskInput name="dateNaissance" label={t(ETLCodes.DateNaissance)} />
          <FGWalterSelectInput
            name="codesexe"
            label={t(ETLCodes.Genre)}
            items={sexe}
            loading={sLoading}
            isFilterable={false}
            isCleareable={false}
          />
          <NationaliteSelect name="nationalite" codeSexeFieldName="codesexe" />
          <FGCustomPanel>
            {ctx => (
              <FGMaskInput
                name="registreNational"
                label={t(ETLCodes.NumeroNational)}
                cleaveOptions={{ delimiters: [".", ".", "-", "."], blocks: [2, 2, 2, 3, 2] }}
                helperText={ctx => (
                  <>
                    {isRegNatDateNaiss(ctx) ? null : (
                      <span style={{ color: theme.warningColor }}>{t(ETLCodes.DateDeNaissanceNeCorrespondPas)}</span>
                    )}
                    {isRegNatGenre(ctx) ? null : (
                      <span style={{ color: theme.warningColor }}>{t(ETLCodes.GenreNeCorrespondPas)}</span>
                    )}
                  </>
                )}
                requiredMark={ctx?.formik?.values?.nationalite === "BE"}
              />
            )}
          </FGCustomPanel>
          <FGTextInput
            name="numeroIdentification"
            label={t(ETLCodes.NumeroIdentification)}
            visible={ctx => !!ctx.formik?.values?.nationalite && ctx.formik?.values?.nationalite !== "BE"}
          />
        </FieldGroup>
      </FieldGroup>
    </SmallFormGenerator>
  );
};
