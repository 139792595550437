/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbAgrementValidationMasseDto
 */
export interface FcbAgrementValidationMasseDto {
    /**
     * 
     * @type {Date}
     * @memberof FcbAgrementValidationMasseDto
     */
    dateValidation?: Date;
    /**
     * 
     * @type {number}
     * @memberof FcbAgrementValidationMasseDto
     */
    idDecision?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FcbAgrementValidationMasseDto
     */
    derogation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbAgrementValidationMasseDto
     */
    c8?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FcbAgrementValidationMasseDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FcbAgrementValidationMasseDto
     */
    formationIds?: Array<number> | null;
}

/**
 * Check if a given object implements the FcbAgrementValidationMasseDto interface.
 */
export function instanceOfFcbAgrementValidationMasseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbAgrementValidationMasseDtoFromJSON(json: any): FcbAgrementValidationMasseDto {
    return FcbAgrementValidationMasseDtoFromJSONTyped(json, false);
}

export function FcbAgrementValidationMasseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbAgrementValidationMasseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateValidation': !exists(json, 'dateValidation') ? undefined : (new Date(json['dateValidation'])),
        'idDecision': !exists(json, 'idDecision') ? undefined : json['idDecision'],
        'derogation': !exists(json, 'derogation') ? undefined : json['derogation'],
        'c8': !exists(json, 'c8') ? undefined : json['c8'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'formationIds': !exists(json, 'formationIds') ? undefined : json['formationIds'],
    };
}

export function FcbAgrementValidationMasseDtoToJSON(value?: FcbAgrementValidationMasseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateValidation': value.dateValidation === undefined ? undefined : (value.dateValidation.toISOString()),
        'idDecision': value.idDecision,
        'derogation': value.derogation,
        'c8': value.c8,
        'remarque': value.remarque,
        'formationIds': value.formationIds,
    };
}

