/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FilterCriteriaInfo,
  FormateurHoraireGridDtoPaginatedResults,
  FormateurHoraireSearch,
  SelectItem,
} from '../models/index';
import {
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    FormateurHoraireGridDtoPaginatedResultsFromJSON,
    FormateurHoraireGridDtoPaginatedResultsToJSON,
    FormateurHoraireSearchFromJSON,
    FormateurHoraireSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface FormateurHoraireBaseSearchRequest {
    FormateurHoraireSearch?: FormateurHoraireSearch;
}

export interface FormateurHoraireGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface FormateurHoraireGetSelectItemsRequest {
    searchField: string;
}

/**
 * 
 */
export class FormateurHoraireApi extends runtime.BaseAPI {

    /**
     */
    async formateurHoraireBaseSearchRaw(requestParameters: FormateurHoraireBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormateurHoraireGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurHoraire/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormateurHoraireSearchToJSON(requestParameters.FormateurHoraireSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormateurHoraireGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async formateurHoraireBaseSearch(requestParameters: FormateurHoraireBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormateurHoraireGridDtoPaginatedResults> {
        const response = await this.formateurHoraireBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurHoraireGetSearchCriteriasRaw(requestParameters: FormateurHoraireGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurHoraire/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async formateurHoraireGetSearchCriterias(requestParameters: FormateurHoraireGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.formateurHoraireGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formateurHoraireGetSelectItemsRaw(requestParameters: FormateurHoraireGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling formateurHoraireGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FormateurHoraire/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async formateurHoraireGetSelectItems(requestParameters: FormateurHoraireGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.formateurHoraireGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
