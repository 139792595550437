/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailReplaceDto
 */
export interface EmailReplaceDto {
    /**
     * 
     * @type {number}
     * @memberof EmailReplaceDto
     */
    oldIdemail?: number;
    /**
     * 
     * @type {number}
     * @memberof EmailReplaceDto
     */
    newIdemail?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EmailReplaceDto
     */
    adresseEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EmailReplaceDto
     */
    idtypeEmail?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EmailReplaceDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EmailReplaceDto
     */
    isNew?: boolean;
}

/**
 * Check if a given object implements the EmailReplaceDto interface.
 */
export function instanceOfEmailReplaceDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmailReplaceDtoFromJSON(json: any): EmailReplaceDto {
    return EmailReplaceDtoFromJSONTyped(json, false);
}

export function EmailReplaceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailReplaceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'oldIdemail': !exists(json, 'oldIdemail') ? undefined : json['oldIdemail'],
        'newIdemail': !exists(json, 'newIdemail') ? undefined : json['newIdemail'],
        'adresseEmail': !exists(json, 'adresseEmail') ? undefined : json['adresseEmail'],
        'idtypeEmail': !exists(json, 'idtypeEmail') ? undefined : json['idtypeEmail'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'isNew': !exists(json, 'isNew') ? undefined : json['isNew'],
    };
}

export function EmailReplaceDtoToJSON(value?: EmailReplaceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'oldIdemail': value.oldIdemail,
        'newIdemail': value.newIdemail,
        'adresseEmail': value.adresseEmail,
        'idtypeEmail': value.idtypeEmail,
        'idpersonne': value.idpersonne,
        'isNew': value.isNew,
    };
}

