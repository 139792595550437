import * as React from "react";
import { useTl, useApiService, useAbortableApiServiceFactory } from "../../../../hooks";
import { useHistory } from "react-router";
import { ButtonContainer } from "nsitools-react";
import { ViewButton, EditButton, SearchTablePage } from "../../../../components";
import { ERoutes } from "../../../../AppRouter";
import { ETLCodes } from "../../../../locales";
import { PlanLocalisationApi, FcbRecherchePlanLocalisationDto, PlanLocalisationSearch } from "../../../../api";

export interface ILocalisationClasseListPageProps {}

export const LocalisationClasseListPage: React.FunctionComponent<ILocalisationClasseListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(PlanLocalisationApi);
  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbRecherchePlanLocalisationDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.localisationClasse}/${row.idplan}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.localisationClasse}/${row.idplan}/detail/edit`)}
              disabled={row.statutLocalisationAgrement === "Validé"}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Annee),
        fieldName: "anneeAcademique"
      },
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "centre"
      },
      {
        header: () => t(ETLCodes.DateValidation),
        fieldName: "dateValidation"
      },
      {
        header: () => t(ETLCodes.Statut),
        fieldName: "statutLocalisationAgrement"
      }
    ],
    [history, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.localisationClasse}/0/detail/edit`);
  }, [history]);

  const getSearchCriterias = React.useCallback(
    () => api.planLocalisationGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  const apiFactory = useAbortableApiServiceFactory(PlanLocalisationApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (sObj?: PlanLocalisationSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.planLocalisationBaseSearch({ PlanLocalisationSearch: sObj });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getSearchCriterias}
      searchFunction={search}
      columns={columns}
      onAbort={onAbort}
      breadCrumbs={[{ text: t(ETLCodes.LocalisationsClasses), route: ERoutes.localisationClasse }]}
      addFunc={onAddItem}
      sortKeys={{ anneeAcademique: "DESC" }}
      searchStateInitialSearch={false}
    />
  );
};
