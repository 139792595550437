import { Intent } from "@blueprintjs/core";
import { DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ChangementClasseApi, ChangementClasseSearch, FcbChangementClasseGridDto } from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import { AddButton, EditButton, ViewButton } from "../../../../../../components";
import { useApiService, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

export interface IChangementClasseListProps {}

export const ChangementClasseList: React.FunctionComponent<IChangementClasseListProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id, tab, inscriptionId, tabInscription } = useParams<{
    id: string;
    tab: string;
    state: string;
    inscriptionId: string;
    tabInscription: string;
  }>();
  const apprenantId = React.useMemo(() => +id, [id]);
  const idInscription = React.useMemo(() => +inscriptionId, [inscriptionId]);
  const api = useApiService(ChangementClasseApi);
  const path = React.useMemo(() => `${ERoutes.apprenant}/${apprenantId}/${tab}/${inscriptionId}/${tabInscription}`, [
    apprenantId,
    inscriptionId,
    tab,
    tabInscription
  ]);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: {
      datechangement: "ASC"
    }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: ChangementClasseSearch) => {
      sObj.idinscription = idInscription;
      return api.changementClasseBaseSearch({ ChangementClasseSearch: sObj });
    },
    [api, idInscription]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbChangementClasseGridDto) => (
          <div style={{ display: "flex" }}>
            <ViewButton minimal={true} onClick={() => history.push(`${path}/${row.idchangementClasse}/view`)} />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${path}/${row.idchangementClasse}/edit`)}
              disabled={!row.isEditable}
            />
          </div>
        )
      },
      {
        header: () => t(ETLCodes.ClasseSource),
        fieldName: "classesourceLabel"
      },
      {
        header: () => t(ETLCodes.ClasseCible),
        fieldName: "classecibleLabel"
      },
      {
        header: () => t(ETLCodes.DateChangement),
        fieldName: "datechangement"
      }
    ],
    [history, path, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${path}/0/edit`);
  }, [history, path]);

  return (
    <>
      {apprenantId > 0 && (
        <FieldSet
          title={t(ETLCodes.TableResults, { count: totalCount })}
          rightElement={
            <>
              <AddButton
                onClick={e => {
                  e.stopPropagation();
                  onAddItem();
                }}
                text={t(ETLCodes.General_Add)}
                intent={Intent.PRIMARY}
              />
            </>
          }
        >
          <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
        </FieldSet>
      )}
    </>
  );
};
