/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AcquisIfapmeEditDto,
  AcquisIfapmeGridDtoPaginatedResults,
  AcquisIfapmeSearchDto,
  BooleanDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
} from '../models/index';
import {
    AcquisIfapmeEditDtoFromJSON,
    AcquisIfapmeEditDtoToJSON,
    AcquisIfapmeGridDtoPaginatedResultsFromJSON,
    AcquisIfapmeGridDtoPaginatedResultsToJSON,
    AcquisIfapmeSearchDtoFromJSON,
    AcquisIfapmeSearchDtoToJSON,
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface AcquisIfapmeBaseSearchRequest {
    AcquisIfapmeSearchDto?: AcquisIfapmeSearchDto;
}

export interface AcquisIfapmeDeleteRequest {
    id?: number;
}

export interface AcquisIfapmeGetRequest {
    id?: number;
}

export interface AcquisIfapmeGetDisplayNameRequest {
    id?: number;
}

export interface AcquisIfapmeGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface AcquisIfapmeGetSelectItemsRequest {
    searchField: string;
}

export interface AcquisIfapmeIsAcquisDeletableRequest {
    id?: number;
}

export interface AcquisIfapmeSaveRequest {
    AcquisIfapmeEditDto?: AcquisIfapmeEditDto;
}

/**
 * 
 */
export class AcquisIfapmeApi extends runtime.BaseAPI {

    /**
     */
    async acquisIfapmeBaseSearchRaw(requestParameters: AcquisIfapmeBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AcquisIfapmeGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisIfapme/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AcquisIfapmeSearchDtoToJSON(requestParameters.AcquisIfapmeSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AcquisIfapmeGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async acquisIfapmeBaseSearch(requestParameters: AcquisIfapmeBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AcquisIfapmeGridDtoPaginatedResults> {
        const response = await this.acquisIfapmeBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisIfapmeDeleteRaw(requestParameters: AcquisIfapmeDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisIfapme/DeleteAcquisIfapme`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async acquisIfapmeDelete(requestParameters: AcquisIfapmeDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acquisIfapmeDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async acquisIfapmeGetRaw(requestParameters: AcquisIfapmeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AcquisIfapmeEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisIfapme`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AcquisIfapmeEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisIfapmeGet(requestParameters: AcquisIfapmeGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AcquisIfapmeEditDto> {
        const response = await this.acquisIfapmeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisIfapmeGetDisplayNameRaw(requestParameters: AcquisIfapmeGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisIfapme/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async acquisIfapmeGetDisplayName(requestParameters: AcquisIfapmeGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.acquisIfapmeGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisIfapmeGetSearchCriteriasRaw(requestParameters: AcquisIfapmeGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisIfapme/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async acquisIfapmeGetSearchCriterias(requestParameters: AcquisIfapmeGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.acquisIfapmeGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisIfapmeGetSelectItemsRaw(requestParameters: AcquisIfapmeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling acquisIfapmeGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisIfapme/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async acquisIfapmeGetSelectItems(requestParameters: AcquisIfapmeGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.acquisIfapmeGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisIfapmeIsAcquisDeletableRaw(requestParameters: AcquisIfapmeIsAcquisDeletableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisIfapme/IsAcquisDeletable`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisIfapmeIsAcquisDeletable(requestParameters: AcquisIfapmeIsAcquisDeletableRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.acquisIfapmeIsAcquisDeletableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async acquisIfapmeSaveRaw(requestParameters: AcquisIfapmeSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AcquisIfapmeEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AcquisIfapme`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AcquisIfapmeEditDtoToJSON(requestParameters.AcquisIfapmeEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AcquisIfapmeEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async acquisIfapmeSave(requestParameters: AcquisIfapmeSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AcquisIfapmeEditDto> {
        const response = await this.acquisIfapmeSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
