import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  ButtonContainer,
  ButtonsBloc,
  DataTable,
  FGCustomPanel,
  FGMaskInput,
  FGTextInput,
  FieldGroup,
  FieldSet,
  InlineButtonContainer,
  showError,
  useGridState,
  useSearchApi
} from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import {
  ContactDoublonSearchDto,
  ContactApi,
  ContactDoublonSearchDtoFromJSON,
  ContactDoublonGridDto
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  ViewButton,
  EditButton,
  AddButton,
  UnordoredListColumn,
  SmallFormGenerator,
  FGWalterSelectInput,
  FGWalterDateMaskInput,
  CancelButton
} from "../../../../../components";
import { useBeIdCardContext, useDialog } from "../../../../../contexts";
import { useTl, useApiService } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

export interface IContactDetailDoublonsProps {
  setBaseData: (values: ContactDoublonSearchDto) => void;
}

export const ContactDetailDoublons: React.FunctionComponent<IContactDetailDoublonsProps> = ({ setBaseData }) => {
  const { t } = useTl();
  const dialogContext = useDialog();
  const history = useHistory();
  const [nationalites, nLoading] = useReferential(a => a.referentialGetAllNationalite(), false, []);
  const api = useApiService(ContactApi);
  const { foundRowsData } = useBeIdCardContext();
  const [firstSearch, setFirstSearch] = React.useState(false);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [10],
    pageSize: 10,
    sortKeys: { nom: "ASC", prenom: "ASC" }
  });
  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: ContactDoublonSearchDto) => {
      return api.contactSearchDoublons({ ContactDoublonSearchDto: sObj });
    },
    [api]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: false
  });
  const add = React.useCallback(
    (fromPersonne: boolean) => {
      if (totalCount && totalCount > 0) {
        dialogContext.showDialog({
          message: t(fromPersonne ? ETLCodes.AddFormeConfirmMessage : ETLCodes.AddConfirmMessage, {
            forme: t(ETLCodes.Contact)
          }),
          onConfirmed: async () => {
            history.push(`${ERoutes.contacts}/-1/detail/edit`);
          }
        });
      } else {
        history.push(`${ERoutes.contacts}/-1/detail/edit`);
      }
    },
    [dialogContext, history, t, totalCount]
  );

  const initialData = React.useMemo(() => {
    const onlyRegNatNb =
      !foundRowsData ||
      (!foundRowsData.nom && !foundRowsData.prenom && !foundRowsData.dateNaissance && foundRowsData.nationalNb);
    return ContactDoublonSearchDtoFromJSON({
      nom: foundRowsData?.nom,
      prenom: foundRowsData?.prenom,
      registreNational: onlyRegNatNb ? foundRowsData?.nationalNb : undefined,
      dateNaissance: foundRowsData?.dateNaissance
    });
  }, [foundRowsData]);

  React.useEffect(() => {
    if (foundRowsData) {
      search(initialData);
      setFirstSearch(true);
    }
  }, [foundRowsData, initialData, search]);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ContactDoublonGridDto) => (
          <ButtonContainer>
            {!!row.idcontact ? (
              <>
                <ViewButton
                  minimal={true}
                  onClick={() => history.push(`${ERoutes.contacts}/${row.idcontact}/detail/view`)}
                />
                <EditButton
                  minimal={true}
                  onClick={() => history.push(`$${ERoutes.contacts}/${row.idcontact}/detail/edit`)}
                />
              </>
            ) : (
              <AddButton
                tooltipText={t(ETLCodes.CreerAPartirdePersonne)}
                onClick={() => {
                  setBaseData({ ...row });
                  add(!row.isContact);
                }}
              />
            )}
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.DateNaissance),
        fieldName: "dateNaissance"
      },
      {
        header: () => t(ETLCodes.NumeroNational),
        fieldName: "registreNational"
      },
      {
        header: () => t(ETLCodes.Adresse),
        fieldName: "adresse"
      },
      {
        header: () => t(ETLCodes.CodePostal),
        fieldName: "codePostal"
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      },
      {
        header: () => t(ETLCodes.Nationalite),
        fieldName: "nationalite"
      }
      // {
      //   header: () => t(ETLCodes.Forme),
      //   fieldName: "forme",
      //   render: (row: ContactDoublonGridDto) => (
      //     <UnordoredListColumn
      //       values={[
      //         { value: row.isApprenant, label: t(ETLCodes.Apprenant) },
      //         { value: row.isFormateur, label: t(ETLCodes.Formateur) },
      //         { value: row.isRepresentant, label: t(ETLCodes.Representant) },
      //         { value: row.isContact, label: t(ETLCodes.Contact) },
      //         { value: row.isCollaborateur, label: t(ETLCodes.Collaborateur) },
      //         { value: row.isDelegueTutelle, label: t(ETLCodes.Referent) },
      //         { value: row.isConseillePedagogique, label: t(ETLCodes.ConseillerPedagogique) },
      //         { value: row.isContact, label: t(ETLCodes.Contact) }
      //       ]}
      //     />
      //   )
      // }
    ],
    [add, history, setBaseData, t]
  );

  return (
    <FieldSet collapsable={false} title={t(ETLCodes.Contact)}>
      <SmallFormGenerator
        enableDirtyCheck={false}
        initialValues={initialData}
        onSubmit={c => {
          if ((c.nom && c.prenom) || c.registreNational) {
            search(c);
            setFirstSearch(true);
          } else {
            showError(t(ETLCodes.NoCriteriaFilled));
          }
        }}
        editMode
        hideButtons={true}
        onCancel={() => history.push(`${ERoutes.contacts}`)}
      >
        <FieldGroup columns={2}>
          <FGTextInput name="nom" label={t(ETLCodes.Nom)} maxLength={100} forceCase="upper" />
          <FGWalterSelectInput
            name="nationalite"
            label={t(ETLCodes.Nationalite)}
            items={nationalites}
            loading={nLoading}
          />
          <FGTextInput name="prenom" label={t(ETLCodes.Prenom)} maxLength={100} />
          <FGMaskInput
            name="registreNational"
            label={t(ETLCodes.NumeroNational)}
            cleaveOptions={{ delimiters: [".", ".", "-", "."], blocks: [2, 2, 2, 3, 2], numericOnly: true }}
          />
          <FGWalterDateMaskInput name="dateNaissance" label={t(ETLCodes.DateNaissance)} />
        </FieldGroup>
        <FieldGroup>
          <FGCustomPanel>
            {ctx => (
              <InlineButtonContainer>
                <ButtonsBloc></ButtonsBloc>
                <ButtonsBloc>
                  <CancelButton minimal={false} onClick={() => history.push(`${ERoutes.contacts}`)} />
                  <Button icon={IconNames.SEARCH} type="submit" text={t(ETLCodes.Search)} intent={Intent.PRIMARY} />
                  <AddButton
                    onClick={() => {
                      setBaseData(ctx.formik.values);
                      add(false);
                    }}
                    text={t(ETLCodes.General_Add)}
                    intent={Intent.PRIMARY}
                    minimal={false}
                    disabled={!firstSearch}
                  />
                </ButtonsBloc>
              </InlineButtonContainer>
            )}
          </FGCustomPanel>
        </FieldGroup>
        <FGCustomPanel>
          {ctx => (
            <FieldSet title={t(ETLCodes.TableResults, { count: totalCount })}>
              <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
            </FieldSet>
          )}
        </FGCustomPanel>
      </SmallFormGenerator>
    </FieldSet>
  );
};
