import { ButtonContainer } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";

import { AnneeScolaireApi, AnneeScolaireSearch, EBooleanSearchTypes, FcbAnneeScolaireDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IAnneeScolaireListPageProps {}

export const AnneeScolaireListPage: React.FunctionComponent<IAnneeScolaireListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(AnneeScolaireApi);
  const [lastSearchObject, setLastSearchObject] = React.useState<AnneeScolaireSearch>();

  const apiFactory = useAbortableApiServiceFactory(AnneeScolaireApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (nextSearch?: AnneeScolaireSearch) => {
      if (JSON.stringify(nextSearch) !== JSON.stringify(lastSearchObject)) {
        setLastSearchObject(nextSearch);
      }
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.anneeScolaireBaseSearch({ AnneeScolaireSearch: nextSearch });
    },
    [apiFactory, lastSearchObject]
  );

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbAnneeScolaireDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.anneeScolaire}/${row.idanneeScolaire}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.anneeScolaire}/${row.idanneeScolaire}/detail/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      }
    ],
    [history, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.anneeScolaire}/0/detail/edit`);
  }, [history]);

  const getCriteria = React.useCallback(() => api.anneeScolaireGetSearchCriterias({ includeListsValues: true }), [api]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteria}
      searchFunction={search}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.AnneeScolaire), route: ERoutes.anneeScolaire }]}
      addFunc={onAddItem}
      sortKeys={{ description: "ASC" }}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
    />
  );
};
