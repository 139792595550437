/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceUserLieEditDto
 */
export interface ServiceUserLieEditDto {
    /**
     * 
     * @type {number}
     * @memberof ServiceUserLieEditDto
     */
    idUser?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceUserLieEditDto
     */
    idServiceTutelle?: number;
    /**
     * 
     * @type {Date}
     * @memberof ServiceUserLieEditDto
     */
    calcFrom?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ServiceUserLieEditDto
     */
    calcTo?: Date | null;
}

/**
 * Check if a given object implements the ServiceUserLieEditDto interface.
 */
export function instanceOfServiceUserLieEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServiceUserLieEditDtoFromJSON(json: any): ServiceUserLieEditDto {
    return ServiceUserLieEditDtoFromJSONTyped(json, false);
}

export function ServiceUserLieEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceUserLieEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idUser': !exists(json, 'idUser') ? undefined : json['idUser'],
        'idServiceTutelle': !exists(json, 'idServiceTutelle') ? undefined : json['idServiceTutelle'],
        'calcFrom': !exists(json, 'calcFrom') ? undefined : (json['calcFrom'] === null ? null : new Date(json['calcFrom'])),
        'calcTo': !exists(json, 'calcTo') ? undefined : (json['calcTo'] === null ? null : new Date(json['calcTo'])),
    };
}

export function ServiceUserLieEditDtoToJSON(value?: ServiceUserLieEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idUser': value.idUser,
        'idServiceTutelle': value.idServiceTutelle,
        'calcFrom': value.calcFrom === undefined ? undefined : (value.calcFrom === null ? null : value.calcFrom.toISOString()),
        'calcTo': value.calcTo === undefined ? undefined : (value.calcTo === null ? null : value.calcTo.toISOString()),
    };
}

