import { ButtonContainer } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";

import { EBooleanSearchTypes, FcbReferentielGridDto, ReferentielApi, ReferentielSearch } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { CopyButton, EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

interface IReferentielListPageProps {}

export const ReferentielListPage: React.FunctionComponent<IReferentielListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ReferentielApi);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbReferentielGridDto) => (
          <ButtonContainer>
            <CopyButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.referentiel}/0/detail/edit/${row.idreferentiel}`)}
            />
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.referentiel}/${row.idreferentiel}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.referentiel}/${row.idreferentiel}/detail/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "codereferentiel"
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      },
      {
        header: () => t(ETLCodes.Date),
        fieldName: "date"
      },
      {
        header: () => t(ETLCodes.Degres),
        fieldName: "degre"
      },
      {
        header: () => t(ETLCodes.NbHeure),
        fieldName: "nbheures"
      }
    ],
    [history, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.referentiel}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(() => api.referentielGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const apiFactory = useAbortableApiServiceFactory(ReferentielApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: ReferentielSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.referentielBaseSearch({ ReferentielSearch: sObj });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.Referentiels), route: ERoutes.referentiel }]}
      addFunc={onAddItem}
      sortKeys={{ codereferentiel: "ASC" }}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
    />
  );
};
