/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiplomeCertificationFichierGridDto } from './DiplomeCertificationFichierGridDto';
import {
    DiplomeCertificationFichierGridDtoFromJSON,
    DiplomeCertificationFichierGridDtoFromJSONTyped,
    DiplomeCertificationFichierGridDtoToJSON,
} from './DiplomeCertificationFichierGridDto';

/**
 * 
 * @export
 * @interface DiplomeCertificationEditDto
 */
export interface DiplomeCertificationEditDto {
    /**
     * 
     * @type {number}
     * @memberof DiplomeCertificationEditDto
     */
    iddiplomeCertification?: number;
    /**
     * 
     * @type {number}
     * @memberof DiplomeCertificationEditDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {string}
     * @memberof DiplomeCertificationEditDto
     */
    anneeDeFormation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DiplomeCertificationEditDto
     */
    operateur?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DiplomeCertificationEditDto
     */
    titre?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DiplomeCertificationEditDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DiplomeCertificationEditDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DiplomeCertificationEditDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DiplomeCertificationEditDto
     */
    typeAcquis?: string | null;
    /**
     * 
     * @type {Array<DiplomeCertificationFichierGridDto>}
     * @memberof DiplomeCertificationEditDto
     */
    fichiers?: Array<DiplomeCertificationFichierGridDto> | null;
}

/**
 * Check if a given object implements the DiplomeCertificationEditDto interface.
 */
export function instanceOfDiplomeCertificationEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DiplomeCertificationEditDtoFromJSON(json: any): DiplomeCertificationEditDto {
    return DiplomeCertificationEditDtoFromJSONTyped(json, false);
}

export function DiplomeCertificationEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiplomeCertificationEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iddiplomeCertification': !exists(json, 'iddiplomeCertification') ? undefined : json['iddiplomeCertification'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'anneeDeFormation': !exists(json, 'anneeDeFormation') ? undefined : json['anneeDeFormation'],
        'operateur': !exists(json, 'operateur') ? undefined : json['operateur'],
        'titre': !exists(json, 'titre') ? undefined : json['titre'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'typeAcquis': !exists(json, 'typeAcquis') ? undefined : json['typeAcquis'],
        'fichiers': !exists(json, 'fichiers') ? undefined : (json['fichiers'] === null ? null : (json['fichiers'] as Array<any>).map(DiplomeCertificationFichierGridDtoFromJSON)),
    };
}

export function DiplomeCertificationEditDtoToJSON(value?: DiplomeCertificationEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iddiplomeCertification': value.iddiplomeCertification,
        'idpersonne': value.idpersonne,
        'anneeDeFormation': value.anneeDeFormation,
        'operateur': value.operateur,
        'titre': value.titre,
        'libelle': value.libelle,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'remarque': value.remarque,
        'typeAcquis': value.typeAcquis,
        'fichiers': value.fichiers === undefined ? undefined : (value.fichiers === null ? null : (value.fichiers as Array<any>).map(DiplomeCertificationFichierGridDtoToJSON)),
    };
}

