import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { UtilisateurCentresLiesSwitch, UtilisateurServicesLiesSwitch } from "..";
import { UsersApi, UtilisateurApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { UtilisateurDetailPage } from "./UtilisateurDetailPage";

export interface IUtilisateurItemPageProps {}

export const UtilisateurItemPage: React.FunctionComponent<IUtilisateurItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();
  const api = useApiService(UtilisateurApi);
  const userApi = useApiService(UsersApi);

  const [displayName] = useApiEffect(() => api.utilisateurGetDisplayName({ id: +id }), [id]);
  const [profile] = useApiEffect(() => userApi.usersGetProfile({ userId: +id }), [id]);

  return (
    <PageBase breadCrumbs={[{ text: t(ETLCodes.Collaborateurs), route: ERoutes.users }, { text: displayName }]}>
      <ProtectedTabs
        id="TabsUtilisateurs"
        onChange={newTabId => history.push(`${ERoutes.users}/${id}/${newTabId}/${state}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.users}></BackButton>
        <Tab id="detail" title={t(ETLCodes.Detail)} panel={<UtilisateurDetailPage />} />
        {!!profile && profile.ifapmeSide?.includes("Walter") && (
          <Tab id="centres" title={t(ETLCodes.Centres)} panel={<UtilisateurCentresLiesSwitch />} disabled={+id <= 0} />
        )}
        {!!profile && profile.ifapmeSide?.includes("Hope") && (
          <Tab
            id="services"
            title={t(ETLCodes.ServicesAlternance)}
            panel={<UtilisateurServicesLiesSwitch />}
            disabled={+id <= 0}
          />
        )}
      </ProtectedTabs>
    </PageBase>
  );
};
