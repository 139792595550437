import { ButtonContainer, IDataTableColumn, showError } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";

import { SiegeSocialDocumentEditDialog, SiegeSocialDocumentUploadDialog } from ".";
import { SiegeSocialFichierApi, SiegeSocialFichierGridDto, SiegeSocialFichierSearchDto } from "../../../../../api";
import { DeleteButton, DownloadButton, EditButton, SearchTablePage } from "../../../../../components";
import { useDialog, useEventsContext } from "../../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { exportFile } from "../../../../../utils";

export interface ISiegeSocialDocumentsProps {}

export const SiegeSocialDocuments: React.FunctionComponent<ISiegeSocialDocumentsProps> = props => {
  const { t } = useTl();
  const api = useApiService(SiegeSocialFichierApi);
  const { id } = useParams<{ id: string }>();
  const [currentSiegeSocialFichierId, setCurrentSiegeSocialFichierId] = React.useState(null);
  const [uploadDialoOpen, setUploadDialoOpen] = React.useState(false);
  const [rowLoading, setRowLoading] = React.useState(null);

  const { dispatchEvent } = useEventsContext();
  const { showDialogPromise } = useDialog();
  const deleteFichier = React.useCallback(
    async (idsiegeSocialFichier: number) => {
      const result = await showDialogPromise({
        message: t(ETLCodes.DeleteConfirmationMessage)
      });

      if (result === "yes") {
        try {
          setRowLoading(idsiegeSocialFichier);
          await api.siegeSocialFichierDeleteSiegeSocialFichier({ id: idsiegeSocialFichier });
          dispatchEvent("SEARCH_TABLE_REFRESH");
        } catch {
          showError(t(ETLCodes.ErrorWhileDeletingFile));
        } finally {
          setRowLoading(null);
        }
      }
    },
    [api, dispatchEvent, showDialogPromise, t]
  );

  const downloadFichier = React.useCallback(
    async (idsiegeSocialFichier: number) => {
      try {
        setRowLoading(idsiegeSocialFichier);
        const file = await api.siegeSocialFichierDownloadSiegeSocialFichier({ id: idsiegeSocialFichier });
        await exportFile(file);
      } catch {
        showError(t(ETLCodes.ErrorWhileDownloadingFile));
      } finally {
        setRowLoading(null);
      }
    },
    [api, t]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: SiegeSocialFichierGridDto) => (
          <ButtonContainer>
            <EditButton
              minimal={true}
              onClick={() => setCurrentSiegeSocialFichierId(row.idsiegeSocialFichier)}
              loading={rowLoading === row.idsiegeSocialFichier}
              disabled={!!rowLoading && rowLoading !== row.idsiegeSocialFichier}
            />
            <DownloadButton
              minimal={true}
              onClick={() => downloadFichier(row.idsiegeSocialFichier)}
              loading={rowLoading === row.idsiegeSocialFichier}
              disabled={!!rowLoading && rowLoading !== row.idsiegeSocialFichier}
            />
            <DeleteButton
              minimal={true}
              onDelete={() => deleteFichier(row.idsiegeSocialFichier)}
              loading={rowLoading === row.idsiegeSocialFichier}
              disabled={!!rowLoading && rowLoading !== row.idsiegeSocialFichier}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.NomFichier),
        fieldName: "fileName"
      },
      {
        header: () => t(ETLCodes.DateUpload),
        fieldName: "uploadDate"
      },
      {
        header: () => t(ETLCodes.UtilisateurUpload),
        fieldName: "creationUserName"
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "typeSiegeSocialFichier"
      }
    ],
    [deleteFichier, downloadFichier, rowLoading, t]
  );

  const getSearchCriterias = React.useCallback(
    () => api.siegeSocialFichierGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  const apiFactory = useAbortableApiServiceFactory(SiegeSocialFichierApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (nextSearch?: SiegeSocialFichierSearchDto) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.siegeSocialFichierBaseSearch({
        SiegeSocialFichierSearchDto: { ...nextSearch, idsiegeSocial: +id }
      });
    },
    [apiFactory, id]
  );

  const onClose = React.useCallback(
    (refresh?: boolean) => {
      setCurrentSiegeSocialFichierId(null);
      setUploadDialoOpen(false);
      if (refresh) {
        dispatchEvent("SEARCH_TABLE_REFRESH");
      }
    },
    [dispatchEvent]
  );

  const onAdd = React.useCallback(() => {
    setUploadDialoOpen(true);
  }, []);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <SearchTablePage
        columns={columns}
        withCard={false}
        getCriteriasFunction={getSearchCriterias}
        searchFunction={search}
        onAbort={onAbort}
        sortKeys={{ uploadDate: "DESC", fileName: "ASC" }}
        addFunc={onAdd}
      />
      {uploadDialoOpen && (
        <SiegeSocialDocumentUploadDialog onClose={onClose} dialogOpen={uploadDialoOpen} idsiegeSocial={+id} />
      )}
      {!!currentSiegeSocialFichierId && (
        <SiegeSocialDocumentEditDialog onClose={onClose} idsiegeSocialFichier={currentSiegeSocialFichierId} />
      )}
    </>
  );
};
