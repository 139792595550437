/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InscriptionFichierGridDto } from './InscriptionFichierGridDto';
import {
    InscriptionFichierGridDtoFromJSON,
    InscriptionFichierGridDtoFromJSONTyped,
    InscriptionFichierGridDtoToJSON,
} from './InscriptionFichierGridDto';
import type { InscriptionFormationDto } from './InscriptionFormationDto';
import {
    InscriptionFormationDtoFromJSON,
    InscriptionFormationDtoFromJSONTyped,
    InscriptionFormationDtoToJSON,
} from './InscriptionFormationDto';

/**
 * 
 * @export
 * @interface FcbInscriptionDetailDto
 */
export interface FcbInscriptionDetailDto {
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionDetailDto
     */
    dateConvocationString?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    nbDispenses?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    nbChangementsClasse?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    nbPresStats?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    nbPresences?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    nbPaiements?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    nbReports?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    nbEvals?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    nbBulletins?: number;
    /**
     * 
     * @type {Array<InscriptionFichierGridDto>}
     * @memberof FcbInscriptionDetailDto
     */
    fichiers?: Array<InscriptionFichierGridDto> | null;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    idinscriptionSuspendue?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbInscriptionDetailDto
     */
    dateFinSuspension?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    idinscription?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    idformation?: number | null;
    /**
     * 
     * @type {InscriptionFormationDto}
     * @memberof FcbInscriptionDetailDto
     */
    formation?: InscriptionFormationDto;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    idcentre?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    idclasse?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    idstatut?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionDetailDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionDetailDto
     */
    degre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionDetailDto
     */
    typeCoursDemandeSeulement?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionDetailDto
     */
    typecours?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbInscriptionDetailDto
     */
    dateInscription?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbInscriptionDetailDto
     */
    dateConvocation?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    minerval?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    iddeliberationType?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionDetailDto
     */
    remarqueDelibe?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    iddeliberationType2sess?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionDetailDto
     */
    remarqueDelibe2sess?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbInscriptionDetailDto
     */
    dateDeliberation?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbInscriptionDetailDto
     */
    dateDeliberation2sess?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbInscriptionDetailDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbInscriptionDetailDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    total?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbInscriptionDetailDto
     */
    dateAbandon?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbInscriptionDetailDto
     */
    finale?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbInscriptionDetailDto
     */
    libre?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionDetailDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbInscriptionDetailDto
     */
    dateDispense?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbInscriptionDetailDto
     */
    inscriptionGeneral?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbInscriptionDetailDto
     */
    dateChangeCentre?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    idapprenant?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbInscriptionDetailDto
     */
    lastTypeDegreMetier?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbInscriptionDetailDto
     */
    isVisibleBulletinManuel?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbInscriptionDetailDto
     */
    isVisibleBulletin?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    iddemandeInscription?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbInscriptionDetailDto
     */
    isARE?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbInscriptionDetailDto
     */
    isSB?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionDetailDto
     */
    iddemandeFormationCrea?: number | null;
}

/**
 * Check if a given object implements the FcbInscriptionDetailDto interface.
 */
export function instanceOfFcbInscriptionDetailDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbInscriptionDetailDtoFromJSON(json: any): FcbInscriptionDetailDto {
    return FcbInscriptionDetailDtoFromJSONTyped(json, false);
}

export function FcbInscriptionDetailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbInscriptionDetailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateConvocationString': !exists(json, 'dateConvocationString') ? undefined : json['dateConvocationString'],
        'nbDispenses': !exists(json, 'nbDispenses') ? undefined : json['nbDispenses'],
        'nbChangementsClasse': !exists(json, 'nbChangementsClasse') ? undefined : json['nbChangementsClasse'],
        'nbPresStats': !exists(json, 'nbPresStats') ? undefined : json['nbPresStats'],
        'nbPresences': !exists(json, 'nbPresences') ? undefined : json['nbPresences'],
        'nbPaiements': !exists(json, 'nbPaiements') ? undefined : json['nbPaiements'],
        'nbReports': !exists(json, 'nbReports') ? undefined : json['nbReports'],
        'nbEvals': !exists(json, 'nbEvals') ? undefined : json['nbEvals'],
        'nbBulletins': !exists(json, 'nbBulletins') ? undefined : json['nbBulletins'],
        'fichiers': !exists(json, 'fichiers') ? undefined : (json['fichiers'] === null ? null : (json['fichiers'] as Array<any>).map(InscriptionFichierGridDtoFromJSON)),
        'idinscriptionSuspendue': !exists(json, 'idinscriptionSuspendue') ? undefined : json['idinscriptionSuspendue'],
        'dateFinSuspension': !exists(json, 'dateFinSuspension') ? undefined : (json['dateFinSuspension'] === null ? null : new Date(json['dateFinSuspension'])),
        'idinscription': !exists(json, 'idinscription') ? undefined : json['idinscription'],
        'idformation': !exists(json, 'idformation') ? undefined : json['idformation'],
        'formation': !exists(json, 'formation') ? undefined : InscriptionFormationDtoFromJSON(json['formation']),
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'idstatut': !exists(json, 'idstatut') ? undefined : json['idstatut'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'degre': !exists(json, 'degre') ? undefined : json['degre'],
        'typeCoursDemandeSeulement': !exists(json, 'typeCoursDemandeSeulement') ? undefined : json['typeCoursDemandeSeulement'],
        'typecours': !exists(json, 'typecours') ? undefined : json['typecours'],
        'dateInscription': !exists(json, 'dateInscription') ? undefined : (json['dateInscription'] === null ? null : new Date(json['dateInscription'])),
        'dateConvocation': !exists(json, 'dateConvocation') ? undefined : (json['dateConvocation'] === null ? null : new Date(json['dateConvocation'])),
        'minerval': !exists(json, 'minerval') ? undefined : json['minerval'],
        'iddeliberationType': !exists(json, 'iddeliberationType') ? undefined : json['iddeliberationType'],
        'remarqueDelibe': !exists(json, 'remarqueDelibe') ? undefined : json['remarqueDelibe'],
        'iddeliberationType2sess': !exists(json, 'iddeliberationType2sess') ? undefined : json['iddeliberationType2sess'],
        'remarqueDelibe2sess': !exists(json, 'remarqueDelibe2sess') ? undefined : json['remarqueDelibe2sess'],
        'dateDeliberation': !exists(json, 'dateDeliberation') ? undefined : (json['dateDeliberation'] === null ? null : new Date(json['dateDeliberation'])),
        'dateDeliberation2sess': !exists(json, 'dateDeliberation2sess') ? undefined : (json['dateDeliberation2sess'] === null ? null : new Date(json['dateDeliberation2sess'])),
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'total': !exists(json, 'total') ? undefined : json['total'],
        'dateAbandon': !exists(json, 'dateAbandon') ? undefined : (json['dateAbandon'] === null ? null : new Date(json['dateAbandon'])),
        'finale': !exists(json, 'finale') ? undefined : json['finale'],
        'libre': !exists(json, 'libre') ? undefined : json['libre'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'dateDispense': !exists(json, 'dateDispense') ? undefined : (json['dateDispense'] === null ? null : new Date(json['dateDispense'])),
        'inscriptionGeneral': !exists(json, 'inscriptionGeneral') ? undefined : json['inscriptionGeneral'],
        'dateChangeCentre': !exists(json, 'dateChangeCentre') ? undefined : (json['dateChangeCentre'] === null ? null : new Date(json['dateChangeCentre'])),
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'lastTypeDegreMetier': !exists(json, 'lastTypeDegreMetier') ? undefined : json['lastTypeDegreMetier'],
        'isVisibleBulletinManuel': !exists(json, 'isVisibleBulletinManuel') ? undefined : json['isVisibleBulletinManuel'],
        'isVisibleBulletin': !exists(json, 'isVisibleBulletin') ? undefined : json['isVisibleBulletin'],
        'iddemandeInscription': !exists(json, 'iddemandeInscription') ? undefined : json['iddemandeInscription'],
        'isARE': !exists(json, 'isARE') ? undefined : json['isARE'],
        'isSB': !exists(json, 'isSB') ? undefined : json['isSB'],
        'iddemandeFormationCrea': !exists(json, 'iddemandeFormationCrea') ? undefined : json['iddemandeFormationCrea'],
    };
}

export function FcbInscriptionDetailDtoToJSON(value?: FcbInscriptionDetailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateConvocationString': value.dateConvocationString,
        'nbDispenses': value.nbDispenses,
        'nbChangementsClasse': value.nbChangementsClasse,
        'nbPresStats': value.nbPresStats,
        'nbPresences': value.nbPresences,
        'nbPaiements': value.nbPaiements,
        'nbReports': value.nbReports,
        'nbEvals': value.nbEvals,
        'nbBulletins': value.nbBulletins,
        'fichiers': value.fichiers === undefined ? undefined : (value.fichiers === null ? null : (value.fichiers as Array<any>).map(InscriptionFichierGridDtoToJSON)),
        'idinscriptionSuspendue': value.idinscriptionSuspendue,
        'dateFinSuspension': value.dateFinSuspension === undefined ? undefined : (value.dateFinSuspension === null ? null : value.dateFinSuspension.toISOString()),
        'idinscription': value.idinscription,
        'idformation': value.idformation,
        'formation': InscriptionFormationDtoToJSON(value.formation),
        'idcentre': value.idcentre,
        'idclasse': value.idclasse,
        'idstatut': value.idstatut,
        'anneeScolaire': value.anneeScolaire,
        'degre': value.degre,
        'typeCoursDemandeSeulement': value.typeCoursDemandeSeulement,
        'typecours': value.typecours,
        'dateInscription': value.dateInscription === undefined ? undefined : (value.dateInscription === null ? null : value.dateInscription.toISOString()),
        'dateConvocation': value.dateConvocation === undefined ? undefined : (value.dateConvocation === null ? null : value.dateConvocation.toISOString()),
        'minerval': value.minerval,
        'iddeliberationType': value.iddeliberationType,
        'remarqueDelibe': value.remarqueDelibe,
        'iddeliberationType2sess': value.iddeliberationType2sess,
        'remarqueDelibe2sess': value.remarqueDelibe2sess,
        'dateDeliberation': value.dateDeliberation === undefined ? undefined : (value.dateDeliberation === null ? null : value.dateDeliberation.toISOString()),
        'dateDeliberation2sess': value.dateDeliberation2sess === undefined ? undefined : (value.dateDeliberation2sess === null ? null : value.dateDeliberation2sess.toISOString()),
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'total': value.total,
        'dateAbandon': value.dateAbandon === undefined ? undefined : (value.dateAbandon === null ? null : value.dateAbandon.toISOString()),
        'finale': value.finale,
        'libre': value.libre,
        'remarque': value.remarque,
        'dateDispense': value.dateDispense === undefined ? undefined : (value.dateDispense === null ? null : value.dateDispense.toISOString()),
        'inscriptionGeneral': value.inscriptionGeneral,
        'dateChangeCentre': value.dateChangeCentre === undefined ? undefined : (value.dateChangeCentre === null ? null : value.dateChangeCentre.toISOString()),
        'idapprenant': value.idapprenant,
        'lastTypeDegreMetier': value.lastTypeDegreMetier,
        'isVisibleBulletinManuel': value.isVisibleBulletinManuel,
        'isVisibleBulletin': value.isVisibleBulletin,
        'iddemandeInscription': value.iddemandeInscription,
        'isARE': value.isARE,
        'isSB': value.isSB,
        'iddemandeFormationCrea': value.iddemandeFormationCrea,
    };
}

