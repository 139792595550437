import * as React from "react";
import { ITabProps, Tab, Button } from "@blueprintjs/core";
import { ERoutes } from "../AppRouter";
import { useHistory } from "react-router";

interface IBackTabProps extends Omit<ITabProps, "title"> {
  title: string;
  backRoute: ERoutes | string;
}

export const BackTab: React.FunctionComponent<IBackTabProps> = ({ title, backRoute, ...tabProps }) => {
  const history = useHistory();

  const onButtonClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      history.push(backRoute);
    },
    [backRoute, history]
  );

  return (
    <Tab
      title={
        <div style={{ display: "flex" }}>
          <div style={{ backgroundColor: "white" }}>
            <Button icon="arrow-left" minimal={false} onClick={onButtonClick} intent="primary"></Button>
            &nbsp; &nbsp;
          </div>
          {title}
        </div>
      }
      {...tabProps}
    ></Tab>
  );
};
