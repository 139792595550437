import { IDataTableColumn, useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ConseillerPedagogiqueMetiersLiesApi, MetierApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { WSelector } from "../../../../../components/selector/WSelector";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { useDialog } from "../../../../../contexts";

export interface IConseillerPedagogiqueMetiersLiesSelectorProps {}

export const ConseillerPedagogiqueMetiersLiesSelector: React.FunctionComponent<IConseillerPedagogiqueMetiersLiesSelectorProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const mApi = useApiService(MetierApi);
  const cpApi = useApiService(ConseillerPedagogiqueMetiersLiesApi);
  const { id } = useParams<{ id: string }>();
  const dialogContext = useDialog();

  const [data, loading] = useApiEffect(() =>
    cpApi.conseillerPedagogiqueMetiersLiesGetConseillerMetier({ codeConseiller: id })
  );

  const columns = React.useMemo<IDataTableColumn[]>(() => {
    return [
      {
        fieldName: "code",
        header: () => t(ETLCodes.Code)
      },
      {
        fieldName: "stade",
        header: () => t(ETLCodes.Stade)
      },
      {
        fieldName: "masculin",
        header: () => t(ETLCodes.Metier)
      }
    ];
  }, [t]);

  return (
    !loading && (
      <WSelector
        linkedIds={data.idMetiers}
        columns={columns}
        idField="idmetier"
        tlDataPrefix="MetierSearchCriterias"
        title={t(ETLCodes.MetiersLies)}
        searchIdsFunc={sObj => mApi.metierSearchMetierIds({ MetierSearch: sObj })}
        getCriteriasFunction={() => mApi.metierGetSearchCriterias({ includeListsValues: true })}
        searchFunction={sObj => mApi.metierBaseSearch({ MetierSearch: sObj })}
        onSave={async newIds => {
          await cpApi.conseillerPedagogiqueMetiersLiesSave({
            FcbConseillerMetiersDto: { codeConseiller: id, idMetiers: newIds }
          });

          history.push(`${ERoutes.conseillerPedagogique}/${id}/metiersLies`);
        }}
        onCancel={() => history.push(`${ERoutes.conseillerPedagogique}/${id}/metiersLies`)}
        overrideListValues={{ Idstade: i => i.displayValue }}
        dialogContext={dialogContext}
        sortKeys={{ masculin: "ASC" }}
      />
    )
  );
};
