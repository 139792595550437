import { Colors, Intent } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { css } from "styled-components";

import { ContactApi, ContactLieuGridDto, ContactLieuSearch } from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import { AddButton, EditButton, ViewButton } from "../../../../../../components";
import { useApiService, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

export interface ILieuFormationContactListPageProps { }

export const LieuFormationContactListPage: React.FunctionComponent<ILieuFormationContactListPageProps> = props => {
  const { t } = useTl();
  const history = useHistory();
  const { search: searchQ } = useLocation();
  const { id } = useParams<{ id: string }>();
  const LieuFormationId = React.useMemo(() => +id, [id]);
  const api = useApiService(ContactApi);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { nom: "ASC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: ContactLieuSearch) => {
      sObj.idlieuFormation = LieuFormationId;
      return api.contactSearchContactLieu({ ContactLieuSearch: sObj });
    },
    [api, LieuFormationId]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const onViewClick = React.useCallback(
    (dto: ContactLieuGridDto) => {
      history.push(`${ERoutes.lieuFormation}/${id}${ERoutes.contacts}/view/${dto.idcontactLieu}${searchQ}`);
    },
    [history, id, searchQ]
  );

  const onEditClick = React.useCallback(
    (dto: ContactLieuGridDto) => {
      history.push(`${ERoutes.lieuFormation}/${id}${ERoutes.contacts}/edit/${dto.idcontactLieu}${searchQ}`);
    },
    [history, id, searchQ]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ContactLieuGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => onViewClick(row)} />
            <EditButton minimal={true} onClick={() => onEditClick(row)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.DateNaissance),
        fieldName: "dateNaissance"
      },
      {
        header: () => t(ETLCodes.Fonction),
        fieldName: "fonction"
      },
      {
        header: () => t(ETLCodes.GsmPro),
        fieldName: "gsm"
      },
      {
        header: () => t(ETLCodes.TelephonePro),
        fieldName: "telephone"
      },
      {
        header: () => t(ETLCodes.EmailPro),
        fieldName: "email"
      }
    ],
    [onEditClick, onViewClick, t]
  );

  const onAddClick = React.useCallback(() => {
    history.push(`${ERoutes.lieuFormation}/${id}${ERoutes.contacts}/checkDoublons${searchQ}`);
  }, [history, id, searchQ]);

  return (
    <FieldSet
      title={t(ETLCodes.TableResults, { count: totalCount })}
      rightElement={<AddButton onClick={onAddClick} text={t(ETLCodes.General_Add)} intent={Intent.PRIMARY} />}
    >
      <DataTable
        dateFormat="dd/MM/yyyy"
        tableState={tableState}
        loading={loading}
        columns={columns}
        filterMode="OnEnter"
        customizeRowStyle={(row: ContactLieuGridDto) => css`
          & * {
            color: ${row.principal ? Colors.GREEN3 + " !important" : null};
          }
        `}
      />
    </FieldSet>
  );
};
