/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbSpecificiteCoursDto,
  FcbSpecificiteCoursGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
  SpecificiteCoursSearch,
  ValidationError,
} from '../models/index';
import {
    FcbSpecificiteCoursDtoFromJSON,
    FcbSpecificiteCoursDtoToJSON,
    FcbSpecificiteCoursGridDtoPaginatedResultsFromJSON,
    FcbSpecificiteCoursGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    SpecificiteCoursSearchFromJSON,
    SpecificiteCoursSearchToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface SpecificiteCoursBaseSearchRequest {
    SpecificiteCoursSearch?: SpecificiteCoursSearch;
}

export interface SpecificiteCoursCodeExistsRequest {
    code?: string;
}

export interface SpecificiteCoursDeleteRequest {
    code?: string;
}

export interface SpecificiteCoursGetRequest {
    code?: string;
}

export interface SpecificiteCoursGetDisplayNameRequest {
    id?: string;
}

export interface SpecificiteCoursGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface SpecificiteCoursGetSelectItemsRequest {
    searchField: string;
}

export interface SpecificiteCoursSaveRequest {
    FcbSpecificiteCoursDto?: FcbSpecificiteCoursDto;
}

/**
 * 
 */
export class SpecificiteCoursApi extends runtime.BaseAPI {

    /**
     */
    async specificiteCoursBaseSearchRaw(requestParameters: SpecificiteCoursBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbSpecificiteCoursGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SpecificiteCours/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SpecificiteCoursSearchToJSON(requestParameters.SpecificiteCoursSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbSpecificiteCoursGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async specificiteCoursBaseSearch(requestParameters: SpecificiteCoursBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbSpecificiteCoursGridDtoPaginatedResults> {
        const response = await this.specificiteCoursBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async specificiteCoursCodeExistsRaw(requestParameters: SpecificiteCoursCodeExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SpecificiteCours/Exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async specificiteCoursCodeExists(requestParameters: SpecificiteCoursCodeExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.specificiteCoursCodeExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async specificiteCoursDeleteRaw(requestParameters: SpecificiteCoursDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SpecificiteCours`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async specificiteCoursDelete(requestParameters: SpecificiteCoursDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.specificiteCoursDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async specificiteCoursGetRaw(requestParameters: SpecificiteCoursGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbSpecificiteCoursDto>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SpecificiteCours`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbSpecificiteCoursDtoFromJSON(jsonValue));
    }

    /**
     */
    async specificiteCoursGet(requestParameters: SpecificiteCoursGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbSpecificiteCoursDto> {
        const response = await this.specificiteCoursGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async specificiteCoursGetDisplayNameRaw(requestParameters: SpecificiteCoursGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SpecificiteCours/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async specificiteCoursGetDisplayName(requestParameters: SpecificiteCoursGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.specificiteCoursGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async specificiteCoursGetSearchCriteriasRaw(requestParameters: SpecificiteCoursGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SpecificiteCours/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async specificiteCoursGetSearchCriterias(requestParameters: SpecificiteCoursGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.specificiteCoursGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async specificiteCoursGetSelectItemsRaw(requestParameters: SpecificiteCoursGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling specificiteCoursGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SpecificiteCours/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async specificiteCoursGetSelectItems(requestParameters: SpecificiteCoursGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.specificiteCoursGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async specificiteCoursSaveRaw(requestParameters: SpecificiteCoursSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbSpecificiteCoursDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SpecificiteCours`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbSpecificiteCoursDtoToJSON(requestParameters.FcbSpecificiteCoursDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbSpecificiteCoursDtoFromJSON(jsonValue));
    }

    /**
     */
    async specificiteCoursSave(requestParameters: SpecificiteCoursSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbSpecificiteCoursDto> {
        const response = await this.specificiteCoursSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
