/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbSmsGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
  SmsSearch,
} from '../models/index';
import {
    FcbSmsGridDtoPaginatedResultsFromJSON,
    FcbSmsGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    SmsSearchFromJSON,
    SmsSearchToJSON,
} from '../models/index';

export interface SmsBaseSearchRequest {
    SmsSearch?: SmsSearch;
}

export interface SmsGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface SmsGetSelectItemsRequest {
    searchField: string;
}

/**
 * 
 */
export class SmsApi extends runtime.BaseAPI {

    /**
     */
    async smsBaseSearchRaw(requestParameters: SmsBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbSmsGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Sms/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SmsSearchToJSON(requestParameters.SmsSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbSmsGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async smsBaseSearch(requestParameters: SmsBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbSmsGridDtoPaginatedResults> {
        const response = await this.smsBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async smsGetSearchCriteriasRaw(requestParameters: SmsGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Sms/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async smsGetSearchCriterias(requestParameters: SmsGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.smsGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async smsGetSelectItemsRaw(requestParameters: SmsGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling smsGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Sms/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async smsGetSelectItems(requestParameters: SmsGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.smsGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
