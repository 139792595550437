/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeliberationValidateIdsDto
 */
export interface DeliberationValidateIdsDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof DeliberationValidateIdsDto
     */
    allIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof DeliberationValidateIdsDto
     */
    idsToValidate?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof DeliberationValidateIdsDto
     */
    ids1SessToInvalidate?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof DeliberationValidateIdsDto
     */
    ids2SessToInvalidate?: Array<number> | null;
}

/**
 * Check if a given object implements the DeliberationValidateIdsDto interface.
 */
export function instanceOfDeliberationValidateIdsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeliberationValidateIdsDtoFromJSON(json: any): DeliberationValidateIdsDto {
    return DeliberationValidateIdsDtoFromJSONTyped(json, false);
}

export function DeliberationValidateIdsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliberationValidateIdsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allIds': !exists(json, 'allIds') ? undefined : json['allIds'],
        'idsToValidate': !exists(json, 'idsToValidate') ? undefined : json['idsToValidate'],
        'ids1SessToInvalidate': !exists(json, 'ids1SessToInvalidate') ? undefined : json['ids1SessToInvalidate'],
        'ids2SessToInvalidate': !exists(json, 'ids2SessToInvalidate') ? undefined : json['ids2SessToInvalidate'],
    };
}

export function DeliberationValidateIdsDtoToJSON(value?: DeliberationValidateIdsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allIds': value.allIds,
        'idsToValidate': value.idsToValidate,
        'ids1SessToInvalidate': value.ids1SessToInvalidate,
        'ids2SessToInvalidate': value.ids2SessToInvalidate,
    };
}

