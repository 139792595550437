import { Colors, Icon, Spinner, Tooltip } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";

import { FcbRepresentantDto, RepresentantApi } from "../../api";
import { useApiService } from "../../hooks";
import { ToolTipContent } from "./ToolTipContent";

export interface IRepresentantTooltipProps {
  idApprenant: number;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  & > * + * {
    margin-left: 0.5rem;
  }
`;

export const RepresentantTooltip: React.FunctionComponent<IRepresentantTooltipProps> = ({ idApprenant }) => {
  const api = useApiService(RepresentantApi);
  const [request, setRequest] = React.useState(false);
  const [representant, setRepresentant] = React.useState<FcbRepresentantDto>(null);
  React.useEffect(() => {
    async function getRepresentant() {
      setRepresentant(await api.representantGetEmergencyContact({ apprenantId: idApprenant }));
    }
    if (request) {
      getRepresentant();
      setRequest(false);
    }
  }, [api, idApprenant, request]);

  return (
    <Container>
      <Tooltip
        content={
          !representant ? (
            <Spinner />
          ) : (
            <ToolTipContent
              isLight={true}
              firstName={representant.prenom}
              lastName={representant.nom}
              birthDate={representant.dateNaissance}
              sex={representant.codesexe}
              nationality={representant.adresseRepresentant?.pays}
              photo={null}
              interne={null}
            />
          )
        }
        onOpening={() => {
          setRequest(true);
        }}
      >
        <Icon icon="info-sign" color={Colors.GRAY1} />
      </Tooltip>
    </Container>
  );
};
