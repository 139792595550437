import { ButtonContainer } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";

import { FcbSmsUtilitaireGridDto, SmsUtilitaireApi, SmsUtilitaireSearch } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { SearchTablePage, ViewButton } from "../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface ISmsListPageProps {}

export const SmsListPage: React.FunctionComponent<ISmsListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(SmsUtilitaireApi);
  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbSmsUtilitaireGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => history.push(`${ERoutes.sms}/${row.idlot}/detail/view`)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      },
      {
        header: () => t(ETLCodes.DateCreation),
        fieldName: "creationDate"
      },
      {
        header: () => t(ETLCodes.DateEnvoi),
        fieldName: "dateEnvoi"
      },
      {
        header: () => t(ETLCodes.NbrDestinataire),
        fieldName: "nbredestinataire"
      },
      {
        header: () => t(ETLCodes.NbrEnvoye),
        fieldName: "nbreenvoye"
      },
      {
        header: () => t(ETLCodes.NbrErreur),
        fieldName: "nbreerreur"
      },
      {
        header: () => t(ETLCodes.Identifiant),
        fieldName: "idlot"
      }
    ],
    [history, t]
  );

  const getCriteriasFn = React.useCallback(() => api.smsUtilitaireGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const apiFactory = useAbortableApiServiceFactory(SmsUtilitaireApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: SmsUtilitaireSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.smsUtilitaireBaseSearch({ SmsUtilitaireSearch: sObj });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.SMS), route: ERoutes.sms }]}
      sortKeys={{ description: "ASC" }}
    />
  );
};
