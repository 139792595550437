/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbFinancementClasseDto,
  FcbFinancementClasseGridDtoPaginatedResults,
  FilterCriteriaInfo,
  FinancementClasseSearch,
  SelectItem,
} from '../models/index';
import {
    FcbFinancementClasseDtoFromJSON,
    FcbFinancementClasseDtoToJSON,
    FcbFinancementClasseGridDtoPaginatedResultsFromJSON,
    FcbFinancementClasseGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    FinancementClasseSearchFromJSON,
    FinancementClasseSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface FinancementClasseBaseSearchRequest {
    FinancementClasseSearch?: FinancementClasseSearch;
}

export interface FinancementClasseDeleteRequest {
    id?: number;
}

export interface FinancementClasseGetRequest {
    id?: number;
}

export interface FinancementClasseGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface FinancementClasseGetSelectItemsRequest {
    searchField: string;
}

export interface FinancementClasseSaveRequest {
    FcbFinancementClasseDto?: FcbFinancementClasseDto;
}

/**
 * 
 */
export class FinancementClasseApi extends runtime.BaseAPI {

    /**
     */
    async financementClasseBaseSearchRaw(requestParameters: FinancementClasseBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFinancementClasseGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FinancementClasse/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FinancementClasseSearchToJSON(requestParameters.FinancementClasseSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFinancementClasseGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async financementClasseBaseSearch(requestParameters: FinancementClasseBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFinancementClasseGridDtoPaginatedResults> {
        const response = await this.financementClasseBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async financementClasseDeleteRaw(requestParameters: FinancementClasseDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FinancementClasse`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async financementClasseDelete(requestParameters: FinancementClasseDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.financementClasseDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async financementClasseGetRaw(requestParameters: FinancementClasseGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFinancementClasseDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FinancementClasse`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFinancementClasseDtoFromJSON(jsonValue));
    }

    /**
     */
    async financementClasseGet(requestParameters: FinancementClasseGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFinancementClasseDto> {
        const response = await this.financementClasseGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async financementClasseGetSearchCriteriasRaw(requestParameters: FinancementClasseGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FinancementClasse/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async financementClasseGetSearchCriterias(requestParameters: FinancementClasseGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.financementClasseGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async financementClasseGetSelectItemsRaw(requestParameters: FinancementClasseGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling financementClasseGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FinancementClasse/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async financementClasseGetSelectItems(requestParameters: FinancementClasseGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.financementClasseGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async financementClasseSaveRaw(requestParameters: FinancementClasseSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFinancementClasseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FinancementClasse`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbFinancementClasseDtoToJSON(requestParameters.FcbFinancementClasseDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFinancementClasseDtoFromJSON(jsonValue));
    }

    /**
     */
    async financementClasseSave(requestParameters: FinancementClasseSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFinancementClasseDto> {
        const response = await this.financementClasseSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
