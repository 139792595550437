import { FGCustomPanel, FGEmpty, FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { FcbLieuxCoursDtoFromJSON, LieuxCoursApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { CommuneSelect, FGWalterSelectInput, LocaliteSelect, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

export interface ICentreLieuxCoursDetailProps { }

export const CentreLieuxCoursDetail: React.FunctionComponent<ICentreLieuxCoursDetailProps> = () => {
  const { t } = useTl();
  const api = useApiService(LieuxCoursApi);
  const history = useHistory();
  const { id, lieuId, state } = useParams<{ id: string; lieuId: string; state: string }>();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +lieuId <= 0 ? FcbLieuxCoursDtoFromJSON({ lieuId: 0, idcentre: id }) : api.lieuxCoursGet({ id: +lieuId }),
    saveApiFn: d => api.lieuxCoursSave({ FcbLieuxCoursDto: d }),
    onSavedRoute: d => `${ERoutes.centre}/${d.idcentre}/lieuxCours/${d.idlieu}/edit`,
    deleteApiFn: d => api.lieuxCoursDelete({ id: d.idlieu }),
    onDeletedRoute: () => `${ERoutes.centre}/${id}/lieuxCours/`
  });

  const [pays, loadingPays] = useReferential(a => a.referentialGetPays(), false, [], true);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      nom: Yup.string().required(t(ETLCodes.Required)),
      code: Yup.string()
        .required(t(ETLCodes.Required))
        .test("code-not-exists", t(ETLCodes.CodeExistsError), async value => {
          if (value === data?.code) return true;
          const validationError = await api.lieuxCoursCodeExists({ code: value });
          return validationError.isValid;
        })
    });
  }, [api, data, t]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={state === "edit"}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(`${ERoutes.centre}/${id}/lieuxCours/`)}
      onDelete={deleteItem}
      showDeleteButton={+lieuId > 0}
      saving={saving}
      deleting={deleting}
    >
      <FieldGroup>
        <FGTextInput name="nom" maxLength={100} label={t(ETLCodes.Nom)} />
        <FGEmpty />
        <FGTextInput name="code" maxLength={4} label={t(ETLCodes.Code)} />
        <FGEmpty />
        <FGWalterSelectInput name="codePays" items={pays} loading={loadingPays} label={t(ETLCodes.Pays)} />
        <FGEmpty />
        <FGTextAreaInput name="adresse" maxLength={50} label={t(ETLCodes.Adresse)} />
        <FGEmpty />
        <FGTextInput name="codePostal" maxLength={6} label={t(ETLCodes.CodePostal)} />
        <FGEmpty />
        <FieldGroup columns={2}>
          <FGCustomPanel>
            {ctx => (
              <LocaliteSelect
                codePostal={ctx.formik.values.codePostal}
                codePays={ctx.formik.values.codePays}
                name="localite"
                codePostalTextName="codePostal"
                disableIfNoCp={false}
              />
            )}
          </FGCustomPanel>
          <FGCustomPanel>
            {ctx => (
              <CommuneSelect
                codePostal={ctx.formik.values.codePostal}
                codePays={ctx.formik.values.codePays}
                name="commune"
              />
            )}
          </FGCustomPanel>
          <FGTextInput name="tel" maxLength={20} label={t(ETLCodes.Tel)} />
          <FGTextInput name="fax" maxLength={20} label={t(ETLCodes.Fax)} />
        </FieldGroup>
      </FieldGroup>
    </SmallFormGenerator>
  );
};
