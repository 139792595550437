import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";

import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales/ETLCodes";
import { ICustomButtonProps } from "./ICustomButtonProps";

interface IPrintButtonProps extends ICustomButtonProps {}

export const PrintButton: React.FunctionComponent<IPrintButtonProps> = ({
  minimal = true,
  onClick,
  intent = Intent.NONE,
  loading
}) => {
  const { t } = useTl();

  return (
    <Button
      loading={loading}
      icon={IconNames.PRINT}
      minimal={minimal}
      onClick={onClick}
      text={minimal ? null : t(ETLCodes.Print)}
      intent={intent}
    />
  );
};
