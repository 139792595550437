import { Classes, Dialog, ProgressBar } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";
import { useTl } from "../hooks";
import { ETLCodes } from "../locales";

const StyledDialog = styled(Dialog)`
  width: 500px;
  height: auto;
  background-color: white;
`;

export interface IGenericProgressLoadingProps {
  maxValue: number;
  currentValue: number;
  loading: boolean;
}

export const GenericProgressLoading: React.FunctionComponent<IGenericProgressLoadingProps> = ({
  loading,
  currentValue,
  maxValue
}) => {
  const { t } = useTl();

  const computedValue = React.useMemo(() => currentValue / maxValue, [currentValue, maxValue]);

  return (
    <StyledDialog
      title={t(ETLCodes.LoadingProgress, { currentValue, maxValue })}
      isOpen={loading}
      isCloseButtonShown={false}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
    >
      <div className={Classes.DIALOG_BODY}>
        <ProgressBar intent="primary" value={computedValue} />
      </div>
    </StyledDialog>
  );
};
