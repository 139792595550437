/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContratLieuFormationTuteurGridDto } from './ContratLieuFormationTuteurGridDto';
import {
    ContratLieuFormationTuteurGridDtoFromJSON,
    ContratLieuFormationTuteurGridDtoFromJSONTyped,
    ContratLieuFormationTuteurGridDtoToJSON,
} from './ContratLieuFormationTuteurGridDto';

/**
 * 
 * @export
 * @interface ContratLieuFormationGridDto
 */
export interface ContratLieuFormationGridDto {
    /**
     * 
     * @type {number}
     * @memberof ContratLieuFormationGridDto
     */
    idcontratLieuFormation?: number;
    /**
     * 
     * @type {number}
     * @memberof ContratLieuFormationGridDto
     */
    idcontrat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratLieuFormationGridDto
     */
    idlieuFormation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratLieuFormationGridDto
     */
    idsiegeSocial?: number;
    /**
     * 
     * @type {string}
     * @memberof ContratLieuFormationGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratLieuFormationGridDto
     */
    enseigne?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratLieuFormationGridDto
     */
    numeroUe?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContratLieuFormationGridDto
     */
    principal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContratLieuFormationGridDto
     */
    localite?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContratLieuFormationGridDto
     */
    nbContrats?: number;
    /**
     * 
     * @type {number}
     * @memberof ContratLieuFormationGridDto
     */
    capaciteFormative?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ContratLieuFormationGridDto
     */
    readonly capaciteFormativeAtteinte?: boolean | null;
    /**
     * 
     * @type {Array<ContratLieuFormationTuteurGridDto>}
     * @memberof ContratLieuFormationGridDto
     */
    tuteurs?: Array<ContratLieuFormationTuteurGridDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContratLieuFormationGridDto
     */
    actif?: boolean;
}

/**
 * Check if a given object implements the ContratLieuFormationGridDto interface.
 */
export function instanceOfContratLieuFormationGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContratLieuFormationGridDtoFromJSON(json: any): ContratLieuFormationGridDto {
    return ContratLieuFormationGridDtoFromJSONTyped(json, false);
}

export function ContratLieuFormationGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContratLieuFormationGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontratLieuFormation': !exists(json, 'idcontratLieuFormation') ? undefined : json['idcontratLieuFormation'],
        'idcontrat': !exists(json, 'idcontrat') ? undefined : json['idcontrat'],
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'enseigne': !exists(json, 'enseigne') ? undefined : json['enseigne'],
        'numeroUe': !exists(json, 'numeroUe') ? undefined : json['numeroUe'],
        'principal': !exists(json, 'principal') ? undefined : json['principal'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'nbContrats': !exists(json, 'nbContrats') ? undefined : json['nbContrats'],
        'capaciteFormative': !exists(json, 'capaciteFormative') ? undefined : json['capaciteFormative'],
        'capaciteFormativeAtteinte': !exists(json, 'capaciteFormativeAtteinte') ? undefined : json['capaciteFormativeAtteinte'],
        'tuteurs': !exists(json, 'tuteurs') ? undefined : (json['tuteurs'] === null ? null : (json['tuteurs'] as Array<any>).map(ContratLieuFormationTuteurGridDtoFromJSON)),
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function ContratLieuFormationGridDtoToJSON(value?: ContratLieuFormationGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontratLieuFormation': value.idcontratLieuFormation,
        'idcontrat': value.idcontrat,
        'idlieuFormation': value.idlieuFormation,
        'idsiegeSocial': value.idsiegeSocial,
        'nom': value.nom,
        'enseigne': value.enseigne,
        'numeroUe': value.numeroUe,
        'principal': value.principal,
        'localite': value.localite,
        'nbContrats': value.nbContrats,
        'capaciteFormative': value.capaciteFormative,
        'tuteurs': value.tuteurs === undefined ? undefined : (value.tuteurs === null ? null : (value.tuteurs as Array<any>).map(ContratLieuFormationTuteurGridDtoToJSON)),
        'actif': value.actif,
    };
}

