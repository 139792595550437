/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdresseDto } from './AdresseDto';
import {
    AdresseDtoFromJSON,
    AdresseDtoFromJSONTyped,
    AdresseDtoToJSON,
} from './AdresseDto';
import type { EmailDto } from './EmailDto';
import {
    EmailDtoFromJSON,
    EmailDtoFromJSONTyped,
    EmailDtoToJSON,
} from './EmailDto';
import type { TelephoneDto } from './TelephoneDto';
import {
    TelephoneDtoFromJSON,
    TelephoneDtoFromJSONTyped,
    TelephoneDtoToJSON,
} from './TelephoneDto';

/**
 * 
 * @export
 * @interface FcbConseillerPedagogiqueDto
 */
export interface FcbConseillerPedagogiqueDto {
    /**
     * 
     * @type {string}
     * @memberof FcbConseillerPedagogiqueDto
     */
    idconseillerPedagogique?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbConseillerPedagogiqueDto
     */
    idpersonne?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbConseillerPedagogiqueDto
     */
    codesexe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbConseillerPedagogiqueDto
     */
    noNational?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbConseillerPedagogiqueDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbConseillerPedagogiqueDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {AdresseDto}
     * @memberof FcbConseillerPedagogiqueDto
     */
    adresse?: AdresseDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof FcbConseillerPedagogiqueDto
     */
    telephone?: TelephoneDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof FcbConseillerPedagogiqueDto
     */
    telephonePrive?: TelephoneDto;
    /**
     * 
     * @type {string}
     * @memberof FcbConseillerPedagogiqueDto
     */
    fax?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbConseillerPedagogiqueDto
     */
    faxPrive?: string | null;
    /**
     * 
     * @type {EmailDto}
     * @memberof FcbConseillerPedagogiqueDto
     */
    email?: EmailDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof FcbConseillerPedagogiqueDto
     */
    gsm?: TelephoneDto;
    /**
     * 
     * @type {boolean}
     * @memberof FcbConseillerPedagogiqueDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbConseillerPedagogiqueDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbConseillerPedagogiqueDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbConseillerPedagogiqueDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbConseillerPedagogiqueDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbConseillerPedagogiqueDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbConseillerPedagogiqueDto
     */
    hasAddressDoublons?: boolean;
}

/**
 * Check if a given object implements the FcbConseillerPedagogiqueDto interface.
 */
export function instanceOfFcbConseillerPedagogiqueDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbConseillerPedagogiqueDtoFromJSON(json: any): FcbConseillerPedagogiqueDto {
    return FcbConseillerPedagogiqueDtoFromJSONTyped(json, false);
}

export function FcbConseillerPedagogiqueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbConseillerPedagogiqueDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idconseillerPedagogique': !exists(json, 'idconseillerPedagogique') ? undefined : json['idconseillerPedagogique'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'codesexe': !exists(json, 'codesexe') ? undefined : json['codesexe'],
        'noNational': !exists(json, 'noNational') ? undefined : json['noNational'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'adresse': !exists(json, 'adresse') ? undefined : AdresseDtoFromJSON(json['adresse']),
        'telephone': !exists(json, 'telephone') ? undefined : TelephoneDtoFromJSON(json['telephone']),
        'telephonePrive': !exists(json, 'telephonePrive') ? undefined : TelephoneDtoFromJSON(json['telephonePrive']),
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'faxPrive': !exists(json, 'faxPrive') ? undefined : json['faxPrive'],
        'email': !exists(json, 'email') ? undefined : EmailDtoFromJSON(json['email']),
        'gsm': !exists(json, 'gsm') ? undefined : TelephoneDtoFromJSON(json['gsm']),
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'hasAddressDoublons': !exists(json, 'hasAddressDoublons') ? undefined : json['hasAddressDoublons'],
    };
}

export function FcbConseillerPedagogiqueDtoToJSON(value?: FcbConseillerPedagogiqueDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idconseillerPedagogique': value.idconseillerPedagogique,
        'idpersonne': value.idpersonne,
        'codesexe': value.codesexe,
        'noNational': value.noNational,
        'nom': value.nom,
        'prenom': value.prenom,
        'adresse': AdresseDtoToJSON(value.adresse),
        'telephone': TelephoneDtoToJSON(value.telephone),
        'telephonePrive': TelephoneDtoToJSON(value.telephonePrive),
        'fax': value.fax,
        'faxPrive': value.faxPrive,
        'email': EmailDtoToJSON(value.email),
        'gsm': TelephoneDtoToJSON(value.gsm),
        'actif': value.actif,
        'remarque': value.remarque,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'hasAddressDoublons': value.hasAddressDoublons,
    };
}

