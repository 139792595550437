/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FilterCriteriaInfo,
  InscriptionSuspensionDto,
  InscriptionSuspensionDtoPaginatedResults,
  InscriptionSuspensionMultipleDto,
  InscriptionSuspensionSearchDto,
  ReferentialItemDto,
} from '../models/index';
import {
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    InscriptionSuspensionDtoFromJSON,
    InscriptionSuspensionDtoToJSON,
    InscriptionSuspensionDtoPaginatedResultsFromJSON,
    InscriptionSuspensionDtoPaginatedResultsToJSON,
    InscriptionSuspensionMultipleDtoFromJSON,
    InscriptionSuspensionMultipleDtoToJSON,
    InscriptionSuspensionSearchDtoFromJSON,
    InscriptionSuspensionSearchDtoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface InscriptionSuspensionBaseSearchRequest {
    InscriptionSuspensionSearchDto?: InscriptionSuspensionSearchDto;
}

export interface InscriptionSuspensionCanEditSuspensionsMultipleRequest {
    request_body?: Array<number>;
}

export interface InscriptionSuspensionDeleteInscriptionSuspensionRequest {
    idinscriptionSuspension?: number;
}

export interface InscriptionSuspensionGetByInscriptionIdRequest {
    idinscription?: number;
}

export interface InscriptionSuspensionGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface InscriptionSuspensionGetSelectItemsRequest {
    searchField: string;
}

export interface InscriptionSuspensionSaveInscriptionSuspensionRequest {
    InscriptionSuspensionDto?: InscriptionSuspensionDto;
}

export interface InscriptionSuspensionSaveInscriptionSuspensionMultipleRequest {
    InscriptionSuspensionMultipleDto?: InscriptionSuspensionMultipleDto;
}

/**
 * 
 */
export class InscriptionSuspensionApi extends runtime.BaseAPI {

    /**
     */
    async inscriptionSuspensionBaseSearchRaw(requestParameters: InscriptionSuspensionBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InscriptionSuspensionDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/InscriptionSuspension/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InscriptionSuspensionSearchDtoToJSON(requestParameters.InscriptionSuspensionSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InscriptionSuspensionDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async inscriptionSuspensionBaseSearch(requestParameters: InscriptionSuspensionBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InscriptionSuspensionDtoPaginatedResults> {
        const response = await this.inscriptionSuspensionBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionSuspensionCanEditSuspensionsMultipleRaw(requestParameters: InscriptionSuspensionCanEditSuspensionsMultipleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/InscriptionSuspension/CanEditSuspensionsMultiple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request_body,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async inscriptionSuspensionCanEditSuspensionsMultiple(requestParameters: InscriptionSuspensionCanEditSuspensionsMultipleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.inscriptionSuspensionCanEditSuspensionsMultipleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionSuspensionDeleteInscriptionSuspensionRaw(requestParameters: InscriptionSuspensionDeleteInscriptionSuspensionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idinscriptionSuspension !== undefined) {
            queryParameters['idinscriptionSuspension'] = requestParameters.idinscriptionSuspension;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/InscriptionSuspension`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async inscriptionSuspensionDeleteInscriptionSuspension(requestParameters: InscriptionSuspensionDeleteInscriptionSuspensionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.inscriptionSuspensionDeleteInscriptionSuspensionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async inscriptionSuspensionGetByInscriptionIdRaw(requestParameters: InscriptionSuspensionGetByInscriptionIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InscriptionSuspensionDto>> {
        const queryParameters: any = {};

        if (requestParameters.idinscription !== undefined) {
            queryParameters['idinscription'] = requestParameters.idinscription;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/InscriptionSuspension/GetByInscriptionId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InscriptionSuspensionDtoFromJSON(jsonValue));
    }

    /**
     */
    async inscriptionSuspensionGetByInscriptionId(requestParameters: InscriptionSuspensionGetByInscriptionIdRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InscriptionSuspensionDto> {
        const response = await this.inscriptionSuspensionGetByInscriptionIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionSuspensionGetSearchCriteriasRaw(requestParameters: InscriptionSuspensionGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/InscriptionSuspension/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async inscriptionSuspensionGetSearchCriterias(requestParameters: InscriptionSuspensionGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.inscriptionSuspensionGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionSuspensionGetSelectItemsRaw(requestParameters: InscriptionSuspensionGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling inscriptionSuspensionGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/InscriptionSuspension/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async inscriptionSuspensionGetSelectItems(requestParameters: InscriptionSuspensionGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.inscriptionSuspensionGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionSuspensionSaveInscriptionSuspensionRaw(requestParameters: InscriptionSuspensionSaveInscriptionSuspensionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InscriptionSuspensionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/InscriptionSuspension`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InscriptionSuspensionDtoToJSON(requestParameters.InscriptionSuspensionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InscriptionSuspensionDtoFromJSON(jsonValue));
    }

    /**
     */
    async inscriptionSuspensionSaveInscriptionSuspension(requestParameters: InscriptionSuspensionSaveInscriptionSuspensionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InscriptionSuspensionDto> {
        const response = await this.inscriptionSuspensionSaveInscriptionSuspensionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async inscriptionSuspensionSaveInscriptionSuspensionMultipleRaw(requestParameters: InscriptionSuspensionSaveInscriptionSuspensionMultipleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InscriptionSuspensionMultipleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/InscriptionSuspension/Multiple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InscriptionSuspensionMultipleDtoToJSON(requestParameters.InscriptionSuspensionMultipleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InscriptionSuspensionMultipleDtoFromJSON(jsonValue));
    }

    /**
     */
    async inscriptionSuspensionSaveInscriptionSuspensionMultiple(requestParameters: InscriptionSuspensionSaveInscriptionSuspensionMultipleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InscriptionSuspensionMultipleDto> {
        const response = await this.inscriptionSuspensionSaveInscriptionSuspensionMultipleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
