import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { DataTable, FieldSet, Loading, useApiEffect, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";
import { css } from "styled-components";

import {
  BulletinApprenantRecapitulatifSearch,
  BulletinApprenantRecaputilatifApi,
  FcbBulletinApprenantRecapitulatifGridDto
} from "../../../../../api";
import { TableBASelectGradeEditor } from "../../../../../components";
import { useEventsContext } from "../../../../../contexts";
import { useApiService, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

export interface IApprenantBulletingRecapitulatifListProps {}

export const ApprenantBulletingRecapitulatifList: React.FunctionComponent<IApprenantBulletingRecapitulatifListProps> = () => {
  const { t } = useTl();
  const { id, anneeTab } = useParams<{ id: string; tab: string; anneeTab: string }>();
  const apprenantId = React.useMemo(() => +id, [id]);

  const api = useApiService(BulletinApprenantRecaputilatifApi);
  const [grades, gloading] = useReferential(a => a.referentialGetGrade(), true);
  const { subscribeToEvent, unsubscribeEvent, dispatchEvent } = useEventsContext();

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [5, 10, 25, 100],
    pageSize: 5,
    sortKeys: { anneeScolaire: "ASC" }
  });

  const searchFn = React.useCallback(
    (sObj?: BulletinApprenantRecapitulatifSearch) => {
      sObj.idapprenant = apprenantId;
      return api.bulletinApprenantRecaputilatifBaseSearch({ BulletinApprenantRecapitulatifSearch: sObj });
    },
    [api, apprenantId]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction: searchFn,
    tableState,
    initialSearch: true
  });

  const [data, dLoading] = useApiEffect(
    () => api.bulletinApprenantRecaputilatifGetClasseName({ id: apprenantId, anneeScolaire: anneeTab }),
    [apprenantId, anneeTab]
  );

  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "anneeScolaire"
      },
      {
        header: () => t(ETLCodes.Metier),
        fieldName: "metier"
      },
      {
        header: () => t(ETLCodes.Degre),
        fieldName: "degre"
      },
      {
        header: () => t(ETLCodes.Point),
        fieldName: "points"
      },
      {
        header: () => t(ETLCodes.Grade),
        fieldName: "idgrade",
        customizeCellStyle: () =>
          css`
            width: 250px;
          `,
        render: (row: FcbBulletinApprenantRecapitulatifGridDto) => {
          if (row.idbulletinApprenant > 0) {
            return (
              <TableBASelectGradeEditor
                idBulletinApprenant={row.idbulletinApprenant}
                initialValue={row.idgrade}
                grades={{ props: grades, loading: gloading }}
                editable={false}
              />
            );
          }
        }
      }
    ],
    [gloading, grades, t]
  );

  React.useEffect(() => {
    subscribeToEvent("DELIBERATION_ANNEE_CHANGED", search);
    return () => {
      unsubscribeEvent("DELIBERATION_ANNEE_CHANGED", search);
    };
  }, [search, subscribeToEvent, unsubscribeEvent]);

  const refresh = React.useCallback(() => {
    search();
    dispatchEvent("DELIBERATION_REFRESH");
  }, [dispatchEvent, search]);

  return (
    <Loading isLoading={dLoading}>
      {apprenantId > 0 && (
        <FieldSet
          title={`${data}`}
          rightElement={<Button icon={IconNames.REFRESH} minimal={true} text={t(ETLCodes.Refresh)} onClick={refresh} />}
        >
          <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
        </FieldSet>
      )}
    </Loading>
  );
};
