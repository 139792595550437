/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbPersonnelTutelleGridDto
 */
export interface FcbPersonnelTutelleGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbPersonnelTutelleGridDto
     */
    iddelegueTutelle?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbPersonnelTutelleGridDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPersonnelTutelleGridDto
     */
    codesexe?: string;
    /**
     * 
     * @type {string}
     * @memberof FcbPersonnelTutelleGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPersonnelTutelleGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbPersonnelTutelleGridDto
     */
    independant?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPersonnelTutelleGridDto
     */
    gsm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPersonnelTutelleGridDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbPersonnelTutelleGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbPersonnelTutelleGridDto interface.
 */
export function instanceOfFcbPersonnelTutelleGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbPersonnelTutelleGridDtoFromJSON(json: any): FcbPersonnelTutelleGridDto {
    return FcbPersonnelTutelleGridDtoFromJSONTyped(json, false);
}

export function FcbPersonnelTutelleGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbPersonnelTutelleGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iddelegueTutelle': !exists(json, 'iddelegueTutelle') ? undefined : json['iddelegueTutelle'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'codesexe': !exists(json, 'codesexe') ? undefined : json['codesexe'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'independant': !exists(json, 'independant') ? undefined : json['independant'],
        'gsm': !exists(json, 'gsm') ? undefined : json['gsm'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbPersonnelTutelleGridDtoToJSON(value?: FcbPersonnelTutelleGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iddelegueTutelle': value.iddelegueTutelle,
        'code': value.code,
        'codesexe': value.codesexe,
        'nom': value.nom,
        'prenom': value.prenom,
        'independant': value.independant,
        'gsm': value.gsm,
        'email': value.email,
        'actif': value.actif,
    };
}

