import { Button, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { DataTable, FieldSet, Loading, useApiEffect, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import { useParams } from "react-router";
import { css } from "styled-components";

import {
  ApprenantApi,
  BulletinApprenantRecapitulatifSearch,
  BulletinApprenantRecaputilatifApi,
  FcbBulletinApprenantRecapitulatifGridDto
} from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { TableBASelectGradeEditor } from "../../../../components";
import { useEventsContext } from "../../../../contexts";
import { useApiService, useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";
import { ETLCodes } from "../../../../locales";

export interface IDeliberationRecapitulatifListProps {}

export const DeliberationRecapitulatifList: React.FunctionComponent<IDeliberationRecapitulatifListProps> = () => {
  const { t } = useTl();
  const { idApprenant, annee } = useParams<{
    id: string;
    tab: string;
    idApprenant: string;
    annee: string;
    coursTypeTab: string;
    state: string;
  }>();

  const apprenantId = React.useMemo(() => +idApprenant, [idApprenant]);

  const api = useApiService(BulletinApprenantRecaputilatifApi);
  const apprenantApi = useApiService(ApprenantApi);
  const [grades, gloading] = useReferential(a => a.referentialGetGrade(), true);

  const { dispatchEvent } = useEventsContext();

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [5, 10, 25, 100],
    pageSize: 5,
    sortKeys: { anneeScolaire: "ASC" }
  });

  const searchFunction = React.useCallback(
    (sObj?: BulletinApprenantRecapitulatifSearch) => {
      sObj.idapprenant = apprenantId;
      // sObj.idbulletinApprenantType = +id;
      return api.bulletinApprenantRecaputilatifBaseSearch({ BulletinApprenantRecapitulatifSearch: sObj });
    },
    [api, apprenantId]
  );

  const { loading, search } = useSearchApi<any, any>({
    searchFunction: searchFunction,
    tableState,
    initialSearch: true
  });

  const [data, dLoading] = useApiEffect(
    () => api.bulletinApprenantRecaputilatifGetClasseName({ id: apprenantId, anneeScolaire: annee }),
    [apprenantId, annee]
  );

  const getPhoto = React.useCallback(async () => {
    return await apprenantApi.apprenantGetPhoto({ id: apprenantId });
  }, [apprenantApi, apprenantId]);
  const [photo, pLoading] = useApiEffect(getPhoto, [getPhoto]);

  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "anneeScolaire"
      },
      {
        header: () => t(ETLCodes.Metier),
        fieldName: "metier"
      },
      {
        header: () => t(ETLCodes.Degre),
        fieldName: "degre"
      },
      {
        header: () => t(ETLCodes.Point),
        fieldName: "points"
      },
      {
        header: () => t(ETLCodes.Grade),
        fieldName: "idgrade",
        customizeCellStyle: () =>
          css`
            width: 250px;
          `,
        render: (row: FcbBulletinApprenantRecapitulatifGridDto) => {
          if (row.idbulletinApprenant > 0) {
            return (
              <TableBASelectGradeEditor
                idBulletinApprenant={row.idbulletinApprenant}
                initialValue={row.idgrade}
                grades={{ props: grades, loading: gloading }}
              />
            );
          }
        }
      }
    ],
    [gloading, grades, t]
  );

  const onPhotoClick = React.useCallback(() => {
    window.open(`/#${ERoutes.apprenant}/${apprenantId}/signaletique/view`, "_blank");
  }, [apprenantId]);

  const refresh = React.useCallback(() => {
    search();
    dispatchEvent("DELIBERATION_REFRESH");
  }, [dispatchEvent, search]);

  React.useEffect(() => {
    if (idApprenant) {
      search();
    }
  }, [idApprenant, search]);

  return (
    <Loading isLoading={dLoading}>
      {apprenantId > 0 && (
        <FieldSet
          title={`${data}`}
          rightElement={<Button icon={IconNames.REFRESH} minimal={true} text={t(ETLCodes.Refresh)} onClick={refresh} />}
        >
          <Grid fluid>
            <Row style={{ marginBottom: "1em" }}>
              <Col xs={11}>
                <FieldSet title={`${t(ETLCodes.GrandTotal)}`}>
                  <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
                </FieldSet>
              </Col>
              {pLoading ? (
                <Col xs={1}>
                  <Loading isLoading={loading} size={20} />
                </Col>
              ) : photo === "" ? (
                <Col xs={1}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Icon iconSize={100} icon={IconNames.USER} onClick={() => onPhotoClick()} />
                  </div>
                </Col>
              ) : (
                <Col xs={1}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={`data:image/png;base64,${photo}`}
                      alt="Profil"
                      style={{ maxHeight: "9.5rem" }}
                      onClick={() => onPhotoClick()}
                    />
                  </div>
                </Col>
              )}
            </Row>
          </Grid>
        </FieldSet>
      )}
    </Loading>
  );
};
