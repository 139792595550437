import { AnneeFormationSuivieDetail } from "./AnneeFormationSuivieDetail";
import { DiplomeCertificationDetail } from "./DiplomeCertificationDetail";
import * as React from "react";
export interface IAcquisScolaireGlobalProps {
  idpersonne: number;
  listRoute: string;
}

export const AcquisScolaireGlobal: React.FunctionComponent<IAcquisScolaireGlobalProps> = ({
  idpersonne,
  listRoute
}) => {
  const [typeAcquisForm, SetTypeAcquisForm] = React.useState("DiplomeCertification");
  const changeFormType = React.useMemo(() => {
    switch (typeAcquisForm) {
      case "AnneeFormationSuivie":
        return (
          <AnneeFormationSuivieDetail
            onTypeAcquisChange={SetTypeAcquisForm}
            idpersonne={idpersonne}
            listRoute={listRoute}
          />
        );
      case "DiplomeCertification":
        return (
          <DiplomeCertificationDetail
            onTypeAcquisChange={SetTypeAcquisForm}
            idpersonne={idpersonne}
            listRoute={listRoute}
          />
        );
      default:
        return (
          <AnneeFormationSuivieDetail
            onTypeAcquisChange={SetTypeAcquisForm}
            idpersonne={idpersonne}
            listRoute={listRoute}
          />
        );
    }
  }, [idpersonne, listRoute, typeAcquisForm]);

  return <>{changeFormType}</>;
};
