import { FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { FcbTypeEvaluationDtoFromJSON, TypeEvaluationApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

interface ITypeEvaluationDetailPageProps {
  idTypeEvaluation: number;
  editMode: boolean;
}

export const TypeEvaluationDetailPage: React.FunctionComponent<ITypeEvaluationDetailPageProps> = ({
  idTypeEvaluation,
  editMode
}) => {
  const { t } = useTl();
  const api = useApiService(TypeEvaluationApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idTypeEvaluation <= 0
        ? FcbTypeEvaluationDtoFromJSON({ idTypeEvaluation: 0 })
        : api.typeEvaluationGet({ id: +idTypeEvaluation }),
    saveApiFn: d => api.typeEvaluationSave({ FcbTypeEvaluationDto: d }),
    onSavedRoute: d => `${ERoutes.typeEvaluation}/${d.idevaluationType}/detail/edit`,
    deleteApiFn: d => api.typeEvaluationDelete({ id: d.idevaluationType }),
    onDeletedRoute: () => ERoutes.typeEvaluation
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      libelle: Yup.string()
        .required(t(ETLCodes.Required))
        .max(50)
        .test("libelle-not-exists", t(ETLCodes.CodeExistsError), async value => {
          if (value === data.libelle) return true;
          const validationError = await api.typeEvaluationExists({ libelle: value });
          return validationError.isValid;
        })
    });
  }, [t, api, data]);

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={editMode}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(ERoutes.typeEvaluation)}
        onDelete={deleteItem}
        showDeleteButton={+idTypeEvaluation > 0}
        saving={saving}
        deleting={deleting}
      >
        <FieldGroup columns={2}>
          <FGTextInput name="libelle" label={t(ETLCodes.Libelle)} maxLength={50} />
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
