/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EListSearchType } from './EListSearchType';
import {
    EListSearchTypeFromJSON,
    EListSearchTypeFromJSONTyped,
    EListSearchTypeToJSON,
} from './EListSearchType';
import type { EListSearchTypeEOrigineSuiviCombinableCriteria } from './EListSearchTypeEOrigineSuiviCombinableCriteria';
import {
    EListSearchTypeEOrigineSuiviCombinableCriteriaFromJSON,
    EListSearchTypeEOrigineSuiviCombinableCriteriaFromJSONTyped,
    EListSearchTypeEOrigineSuiviCombinableCriteriaToJSON,
} from './EListSearchTypeEOrigineSuiviCombinableCriteria';
import type { EOrigineSuivi } from './EOrigineSuivi';
import {
    EOrigineSuiviFromJSON,
    EOrigineSuiviFromJSONTyped,
    EOrigineSuiviToJSON,
} from './EOrigineSuivi';

/**
 * 
 * @export
 * @interface EOrigineSuiviListSearch
 */
export interface EOrigineSuiviListSearch {
    /**
     * 
     * @type {Array<EListSearchTypeEOrigineSuiviCombinableCriteria>}
     * @memberof EOrigineSuiviListSearch
     */
    criterias?: Array<EListSearchTypeEOrigineSuiviCombinableCriteria> | null;
    /**
     * 
     * @type {EListSearchType}
     * @memberof EOrigineSuiviListSearch
     */
    searchMode?: EListSearchType;
    /**
     * 
     * @type {EOrigineSuivi}
     * @memberof EOrigineSuiviListSearch
     */
    value?: EOrigineSuivi;
    /**
     * 
     * @type {EOrigineSuivi}
     * @memberof EOrigineSuiviListSearch
     */
    secondaryValue?: EOrigineSuivi;
    /**
     * 
     * @type {Array<string>}
     * @memberof EOrigineSuiviListSearch
     */
    readonly availableSearchTypes?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof EOrigineSuiviListSearch
     */
    defaultSearchType?: string | null;
}

/**
 * Check if a given object implements the EOrigineSuiviListSearch interface.
 */
export function instanceOfEOrigineSuiviListSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EOrigineSuiviListSearchFromJSON(json: any): EOrigineSuiviListSearch {
    return EOrigineSuiviListSearchFromJSONTyped(json, false);
}

export function EOrigineSuiviListSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): EOrigineSuiviListSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'criterias': !exists(json, 'criterias') ? undefined : (json['criterias'] === null ? null : (json['criterias'] as Array<any>).map(EListSearchTypeEOrigineSuiviCombinableCriteriaFromJSON)),
        'searchMode': !exists(json, 'searchMode') ? undefined : EListSearchTypeFromJSON(json['searchMode']),
        'value': !exists(json, 'value') ? undefined : EOrigineSuiviFromJSON(json['value']),
        'secondaryValue': !exists(json, 'secondaryValue') ? undefined : EOrigineSuiviFromJSON(json['secondaryValue']),
        'availableSearchTypes': !exists(json, 'availableSearchTypes') ? undefined : json['availableSearchTypes'],
        'defaultSearchType': !exists(json, 'defaultSearchType') ? undefined : json['defaultSearchType'],
    };
}

export function EOrigineSuiviListSearchToJSON(value?: EOrigineSuiviListSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'criterias': value.criterias === undefined ? undefined : (value.criterias === null ? null : (value.criterias as Array<any>).map(EListSearchTypeEOrigineSuiviCombinableCriteriaToJSON)),
        'searchMode': EListSearchTypeToJSON(value.searchMode),
        'value': EOrigineSuiviToJSON(value.value),
        'secondaryValue': EOrigineSuiviToJSON(value.secondaryValue),
        'defaultSearchType': value.defaultSearchType,
    };
}

