import * as React from "react";
import { matchPath, useLocation } from "react-router";

import { RepresentantDetailDoublons, RepresentantDetailPage } from ".";
import { RepresentantDoublonSearchFromJSON } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { useTabMessage } from "../../../../hooks";

export interface IRepresentantSwitchProps {}

export const RepresentantSwitch: React.FunctionComponent<IRepresentantSwitchProps> = () => {
  const [sObj, setSObj] = React.useState(RepresentantDoublonSearchFromJSON({}));
  const { pathname, search } = useLocation();
  const cfs = React.useMemo(() => new URLSearchParams(search).get("cfs"), [search]);
  const { messageValue, clearMessageValue } = useTabMessage(cfs);

  React.useEffect(() => {
    if (!!messageValue) {
      setSObj(messageValue);
      clearMessageValue();
    }
  }, [clearMessageValue, messageValue]);

  if (
    matchPath(pathname, {
      path: `${ERoutes.representant}/:id/:tab/checkDoublons`
    })
  ) {
    return <RepresentantDetailDoublons setBaseData={setSObj} />;
  } else {
    return <RepresentantDetailPage baseData={sObj} />;
  }
};
