import {
  Button,
  Checkbox,
  Colors,
  Icon,
  Intent,
  Menu,
  MenuItem,
  NonIdealState,
  Popover,
  Spinner,
  Tooltip
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { IDataTableColumn, showError } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import {
  ApprenantApi,
  EBooleanSearchTypes,
  ETypeDestinataireSms,
  FcbRechercheApprenantViewDto,
  ProspectApi,
  ProspectSearch
} from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import {
  AddButton,
  ApprenantTooltip,
  CircleColumn,
  ColumnMask,
  CustomBulletList,
  EditButton,
  EmailButton,
  SearchTablePage,
  SmsButton,
  ViewButton
} from "../../../../components";
import { useRouteParamsContext } from "../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTheme, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { computeContratStatutColor } from "../../../../utils/contratStatutColor";

export interface IProspectListProps {}

const Container = styled.div`
  display: flex;
`;

const PopoverContent = styled.div`
  padding: 0.5rem;
`;

const LegendHeaderContainer = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

export const ProspectList: React.FunctionComponent<IProspectListProps> = () => {
  const { t, tUnsafe } = useTl();
  const history = useHistory();
  const apiFactory = useAbortableApiServiceFactory(ProspectApi);
  const lastAbortController = React.useRef<AbortController>();
  const [selectedIDs, setSelectedIDs] = React.useState<number[]>([]);
  const { pushSmsRecipientIds, pushSmsRecipientType } = useRouteParamsContext();
  const [selectAllLoading, setSelectAllLoading] = React.useState(false);
  const [nbReturnedIds, setNbReturnedIds] = React.useState(0);
  const { theme } = useTheme();

  const lastSearchObject = React.useRef<ProspectSearch>();
  const [searched, setSearched] = React.useState(false);

  const search = React.useCallback(
    (nextSearch?: ProspectSearch) => {
      setSearched(true);
      if (JSON.stringify(nextSearch) !== JSON.stringify(lastSearchObject.current)) {
        lastSearchObject.current = nextSearch;
      }
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.prospectBaseSearch({ ProspectSearch: nextSearch });
    },
    [apiFactory]
  );

  const api = useApiService(ProspectApi);
  const selectAll = React.useCallback(() => {
    setSelectAllLoading(true);
    api
      .prospectSearchApprenantIds({
        ProspectSearch: { ...lastSearchObject.current, forceSkip: 0, forceTake: 999999 }
      })
      .then(allIds => {
        if (allIds.length === selectedIDs.length) {
          setSelectedIDs([]);
          setNbReturnedIds(0);
        } else {
          setSelectedIDs(allIds);
          setNbReturnedIds(allIds.length);
        }
        setSelectAllLoading(false);
      });
  }, [api, selectedIDs.length]);

  const toggleSelection = React.useCallback(
    (item: FcbRechercheApprenantViewDto) => {
      if (!selectedIDs.includes(item.idapprenant)) {
        setSelectedIDs(ids => [...ids, item.idapprenant]);
      } else {
        setSelectedIDs(ids => ids.filter(id => id !== item.idapprenant));
      }
    },
    [selectedIDs]
  );

  const sendSms = React.useCallback(() => {
    if (selectedIDs.length > 0) {
      pushSmsRecipientIds(selectedIDs);
      pushSmsRecipientType(ETypeDestinataireSms.Etudiant);
      history.push(`${ERoutes.sms}/0/detail/edit`);
    } else {
      showError(t(ETLCodes.SelectAtLeastOne));
    }
  }, [history, pushSmsRecipientIds, pushSmsRecipientType, selectedIDs, t]);

  const sendSmsSingle = React.useCallback(
    (id: number) => {
      pushSmsRecipientIds([id]);
      pushSmsRecipientType(ETypeDestinataireSms.Etudiant);
      history.push(`${ERoutes.sms}/0/detail/edit`);
    },
    [history, pushSmsRecipientIds, pushSmsRecipientType]
  );

  const aApi = useApiService(ApprenantApi);
  const mailSent = React.useCallback(
    async (idApprenant: number) => {
      await aApi.apprenantMailSent({ IdApprenant: idApprenant });
    },
    [aApi]
  );

  const sendEmail = React.useCallback(async () => {
    if (selectedIDs.length > 0) {
      const res = await aApi.apprenantGetEmails({ request_body: selectedIDs });
      if (!res) {
        showError(t(ETLCodes.NoSelectedApprenantHasAnEmail));
        return;
      }
      const el = document.createElement("a");
      el.setAttribute("href", `mailto:${res.azureEmails}?bcc=${res.emails}`);
      el.click();
    } else {
      showError(t(ETLCodes.SelectAtLeastOne));
    }
  }, [aApi, selectedIDs, t]);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        header: () =>
          selectAllLoading ? (
            <Spinner size={20} />
          ) : (
            <Checkbox checked={nbReturnedIds > 0 && nbReturnedIds === selectedIDs.length} onChange={selectAll} />
          ),
        render: (item: FcbRechercheApprenantViewDto) => (
          <Checkbox
            checked={selectedIDs.includes(item.idapprenant)}
            onChange={e => {
              toggleSelection(item);
            }}
          />
        )
      },
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbRechercheApprenantViewDto) => (
          <Container>
            <ViewButton
              minimal={true}
              onClick={() =>
                history.push(
                  `${row.isProspect ? ERoutes.prospect : ERoutes.apprenant}/${row.idapprenant}/signaletique/view`
                )
              }
            />
            <EditButton
              minimal={true}
              onClick={() =>
                history.push(
                  `${row.isProspect ? ERoutes.prospect : ERoutes.apprenant}/${row.idapprenant}/signaletique/edit`
                )
              }
            />
            <Popover
              position="bottom"
              content={
                <PopoverContent>
                  <Menu>
                    <MenuItem
                      icon={IconNames.ENVELOPE}
                      text={t(ETLCodes.SendAnEmail)}
                      intent={Intent.PRIMARY}
                      onClick={() => {
                        const el = document.createElement("a");
                        el.setAttribute("href", `mailto:${row.azureEmail}?bcc=${row.email}`);
                        el.click();
                        mailSent(row.idapprenant);
                      }}
                    />
                    <MenuItem
                      icon={IconNames.CHAT}
                      text={t(ETLCodes.SendAnSMS)}
                      intent={Intent.PRIMARY}
                      onClick={() => sendSmsSingle(row.idapprenant)}
                    />
                    <MenuItem
                      icon={IconNames.HEADSET}
                      text={t(ETLCodes.Call)}
                      intent={Intent.PRIMARY}
                      onClick={() => {
                        const el = document.createElement("a");
                        el.setAttribute("href", `tel:${row.gsm.replace(/[^+\d]/gm, "")}`);
                        el.click();
                        el.remove();
                        mailSent(row.idapprenant);
                      }}
                    />
                  </Menu>
                </PopoverContent>
              }
            >
              <Button icon={IconNames.CHEVRON_DOWN} minimal={true}></Button>
            </Popover>
          </Container>
        )
      },
      {
        autoFitContent: true,
        alignment: "center",
        header: () => (
          <LegendHeaderContainer>
            <span>{t(ETLCodes.C)}</span>
            <Tooltip
              content={
                <CustomBulletList
                  title={t(ETLCodes.StatutContrat)}
                  items={[
                    {
                      text: t(ETLCodes.EnCours),
                      color: theme.sucessColor
                    },
                    {
                      text: t(ETLCodes.Rompu),
                      color: theme.dangerColor
                    },
                    {
                      text: t(ETLCodes.Suspendu),
                      color: theme.warningColor
                    },
                    {
                      text: t(ETLCodes.JamaisDeContrat),
                      color: Colors.BLACK
                    },
                    {
                      text: t(ETLCodes.Termine),
                      color: Colors.VIOLET4
                    },
                    {
                      text: t(ETLCodes.SansSuite),
                      hideBullet: true
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip>
          </LegendHeaderContainer>
        ),
        fieldName: "statutContrat",
        render: (row: FcbRechercheApprenantViewDto) => (
          <CircleColumn
            color={computeContratStatutColor(row.statutContrat, theme)}
            tooltip={row.statutContrat ? tUnsafe(`StatutContrat_${row.statutContrat}`) : t(ETLCodes.JamaisDeContrat)}
          />
        )
      },
      {
        autoFitContent: true,
        alignment: "center",
        header: () => (
          <LegendHeaderContainer>
            <span>{t(ETLCodes.I)}</span>
            <Tooltip
              content={
                <CustomBulletList
                  title={t(ETLCodes.StatutInscriptions)}
                  items={[
                    {
                      text: t(ETLCodes.AuMoinsUneInscriptionsEnCours),
                      color: theme.sucessColor
                    },
                    {
                      text: t(ETLCodes.AucuneInscriptionEnCours),
                      color: theme.dangerColor
                    },
                    {
                      text: t(ETLCodes.AuMoinsUneInscriptionsEnAttente),
                      color: theme.warningColor
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip>
          </LegendHeaderContainer>
        ),
        fieldName: "statutInscriptions",
        render: (row: FcbRechercheApprenantViewDto) => (
          <CircleColumn
            color={row.encours ? theme.sucessColor : row.enattente ? theme.warningColor : theme.dangerColor}
          />
        )
      },
      {
        header: () => t(ETLCodes.Identifiant),
        computed: true,
        fieldName: "info",
        render: (value: FcbRechercheApprenantViewDto) => <ApprenantTooltip idApprenant={value.idapprenant} />
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.DateNaissance),
        fieldName: "dateNaissance"
      },
      {
        header: () => t(ETLCodes.Gsm),
        fieldName: "gsm",
        autoFitContent: true,
        render: (value: FcbRechercheApprenantViewDto) => (
          <SmsButton phone={value.gsm} onClick={() => sendSmsSingle(value.idapprenant)} disabled={!!value.dateDeces} />
        )
      },
      {
        header: () => t(ETLCodes.Tel),
        fieldName: "telephone"
      },
      {
        header: () => t(ETLCodes.General_Email),
        fieldName: "email",
        autoFitContent: true,
        alignment: "center",
        render: (value: FcbRechercheApprenantViewDto) => (
          <EmailButton
            email={value.azureEmail}
            emailBcc={value.email}
            afterClick={() => mailSent(value.idapprenant)}
            disabled={!!value.dateDeces}
          />
        )
      },
      {
        header: () => t(ETLCodes.NumeroNational),
        fieldName: "registreNational",
        render: (row: FcbRechercheApprenantViewDto) => (
          <ColumnMask
            value={row.registreNational}
            cleaveOptions={{ delimiters: [".", ".", "-", "."], blocks: [2, 2, 2, 3, 2] }}
          />
        )
      },
      {
        header: () => t(ETLCodes.Inscription),
        autoFitContent: true,
        computed: true,
        fieldName: "distinctsInscriptions",
        render: (value: FcbRechercheApprenantViewDto) => {
          if (value.distinctsInscriptions?.length > 0) {
            return (
              <span style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                {value.anneeScolaire}
                {value.distinctsInscriptions.length > 1 && (
                  <span>
                    &nbsp;
                    <Tooltip
                      content={
                        <div>
                          {value.distinctsInscriptions.map((annee, i) => (
                            <div key={i}>{annee}</div>
                          ))}
                        </div>
                      }
                      position="right"
                    >
                      <div style={{ cursor: "pointer" }}>...</div>
                    </Tooltip>
                  </span>
                )}
              </span>
            );
          } else {
            return <div></div>;
          }
        }
      }
    ],
    [
      history,
      mailSent,
      nbReturnedIds,
      selectAll,
      selectAllLoading,
      selectedIDs,
      sendSmsSingle,
      t,
      tUnsafe,
      theme,
      toggleSelection
    ]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.prospect}/0/signaletique/checkDoublons`);
  }, [history]);

  const getCriteria = React.useCallback(async () => api.prospectGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const buttons = React.useMemo(() => {
    return (
      <>
        <Popover
          position="bottom"
          content={
            <PopoverContent>
              <Menu>
                <MenuItem
                  icon={IconNames.ENVELOPE}
                  text={t(ETLCodes.SendAnEmail)}
                  intent={Intent.PRIMARY}
                  onClick={sendEmail}
                />
                <MenuItem
                  icon={IconNames.CHAT}
                  text={t(ETLCodes.SendAnSMS)}
                  intent={Intent.PRIMARY}
                  onClick={sendSms}
                />
              </Menu>
            </PopoverContent>
          }
        >
          <Button
            icon={IconNames.CHEVRON_DOWN}
            text={t(ETLCodes.GlobalActions)}
            minimal={true}
            intent={Intent.PRIMARY}
          ></Button>
        </Popover>
        <AddButton
          onClick={e => {
            e.stopPropagation();
            addItemFunction();
          }}
          text={t(ETLCodes.General_Add)}
          intent={Intent.PRIMARY}
        />
      </>
    );
  }, [addItemFunction, sendEmail, sendSms, t]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <SearchTablePage
        columns={columns}
        getCriteriasFunction={getCriteria}
        searchFunction={search}
        onAbort={onAbort}
        sortKeys={{ nom: "ASC" }}
        breadCrumbs={[{ text: t(ETLCodes.PorteursDeProjets) }]}
        rightElement={buttons}
        initialSearch={true}
        searchStateInitialSearch={false}
        defaultCriterias={[
          {
            criteria: "IsCrea",
            searchMode: EBooleanSearchTypes.Equals,
            value: true
          }
        ]}
        renderNoData={
          <NonIdealState
            icon="search"
            title={t(searched ? ETLCodes.GeneralNoData : ETLCodes.VeuillezEffectuerUneRecherche)}
          />
        }
      />
    </>
  );
};
