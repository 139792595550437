import { ButtonContainer, DataTable, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";

import { ParcoursFormationApi, ParcoursFormationSearch } from "../../../../../api";
import { ParcoursFormationGridDto } from "../../../../../api/models/ParcoursFormationGridDto";
import { ERoutes } from "../../../../../AppRouter";
import { ViewButton } from "../../../../../components";
import { useEventsContext } from "../../../../../contexts";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IParcoursFormationHistoriqueProps {}

export const ParcoursFormationHistorique: React.FunctionComponent<IParcoursFormationHistoriqueProps> = props => {
  const { t } = useTl();
  const { id, state } = useParams<{ id: string; state: string }>();
  const apprenantId = React.useMemo(() => +id, [id]);
  const history = useHistory();
  const api = useApiService(ParcoursFormationApi);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: false,
    enableFilter: false,
    pageSize: 999999,
    sortKeys: { dateEntree: "DESC" }
  });

  const searchFunction = React.useCallback(
    (sObj?: ParcoursFormationSearch) => {
      sObj.idApprenant = apprenantId;
      return api.parcoursFormationBaseSearch({ ParcoursFormationSearch: sObj });
    },
    [api, apprenantId]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const OnRefreshSearchTablesEvent = React.useCallback(() => {
    search();
  }, [search]);
  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();
  React.useEffect(() => {
    subscribeToEvent("SEARCH_TABLE_REFRESH", OnRefreshSearchTablesEvent);
    return () => unsubscribeEvent("SEARCH_TABLE_REFRESH", OnRefreshSearchTablesEvent);
  }, [OnRefreshSearchTablesEvent, subscribeToEvent, unsubscribeEvent]);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ParcoursFormationGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() =>
                history.push(`${ERoutes.apprenant}/${id}/parcoursFormation/${state}/${row.idParcoursFormation}`)
              }
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.DateEntreeParcours),
        fieldName: "dateEntree"
      },

      {
        header: () => t(ETLCodes.CategorieStatut),
        fieldName: "catStatutEntree"
      },
      {
        header: () => t(ETLCodes.Statut),
        fieldName: "statutEntree"
      },
      {
        header: () => t(ETLCodes.DateSortieReseau),
        fieldName: "dateSortie"
      },
      {
        header: () => t(ETLCodes.CategorieStatut),
        fieldName: "catStatutSortie"
      },
      {
        header: () => t(ETLCodes.Statut),
        fieldName: "statutSortie"
      },
      {
        header: () => t(ETLCodes.MotifSortie),
        fieldName: "motifSortie"
      }
    ],
    [history, id, t, state]
  );

  return (
    <DataTable
      dateFormat="dd/MM/yyyy"
      tableState={tableState}
      loading={loading}
      columns={columns}
      filterMode="OnEnter"
    />
  );
};
