import * as React from "react";
import { useApiService } from "../../../../../hooks";
import { ExportApi } from "../../../../../api";
import { useApiEffect } from "nsitools-react";
import { ExportAccesPermissionsGrid } from "./ExportAccesPermissionsGrid";
import { ExportCentrePermissionsGrid } from "./ExportCentrePermissionsGrid";
import { ExportModulePermissionsGrid } from "./ExportModulePermissionsGrid";

export interface IExportPermissionsPageProps {
  idExport: number;
}

export const ExportPermissionsPage: React.FunctionComponent<IExportPermissionsPageProps> = ({ idExport }) => {
  const api = useApiService(ExportApi);

  const [data, loading] = useApiEffect(
    React.useCallback(() => {
      return api.exportGetPermissions({ id: idExport });
    }, [api, idExport])
  );
  return (
    <>
      <ExportCentrePermissionsGrid
        idExport={idExport}
        centres={data?.centres}
        loading={loading}
      ></ExportCentrePermissionsGrid>
      <ExportAccesPermissionsGrid
        idExport={idExport}
        acces={data?.acces}
        loading={loading}
      ></ExportAccesPermissionsGrid>
      <ExportModulePermissionsGrid
        idExport={idExport}
        modules={data?.modules}
        loading={loading}
      ></ExportModulePermissionsGrid>
    </>
  );
};
