/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRecherchecalendrierClasseDto
 */
export interface FcbRecherchecalendrierClasseDto {
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchecalendrierClasseDto
     */
    idclasse?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchecalendrierClasseDto
     */
    idcentre?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchecalendrierClasseDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchecalendrierClasseDto
     */
    nom?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRecherchecalendrierClasseDto
     */
    jour?: Date | null;
}

/**
 * Check if a given object implements the FcbRecherchecalendrierClasseDto interface.
 */
export function instanceOfFcbRecherchecalendrierClasseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRecherchecalendrierClasseDtoFromJSON(json: any): FcbRecherchecalendrierClasseDto {
    return FcbRecherchecalendrierClasseDtoFromJSONTyped(json, false);
}

export function FcbRecherchecalendrierClasseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRecherchecalendrierClasseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'jour': !exists(json, 'jour') ? undefined : (json['jour'] === null ? null : new Date(json['jour'])),
    };
}

export function FcbRecherchecalendrierClasseDtoToJSON(value?: FcbRecherchecalendrierClasseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idclasse': value.idclasse,
        'idcentre': value.idcentre,
        'code': value.code,
        'nom': value.nom,
        'jour': value.jour === undefined ? undefined : (value.jour === null ? null : value.jour.toISOString()),
    };
}

