/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  CanDisableOffreRequestDto,
  ContratLieuxFormationGridDtoPaginatedResults,
  ContratLieuxFormationSearchDto,
  DisplayNameDto,
  FilterCriteriaInfo,
  LieuFormationCapaciteFormativeDto,
  LieuFormationEditDto,
  LieuFormationGridDtoPaginatedResults,
  LieuFormationHistoryGridDtoPaginatedResults,
  LieuFormationHistorySearch,
  LieuFormationSearchDto,
  LieuFormationSelectorGridDtoPaginatedResults,
  LieuFormationSelectorSearch,
  ReferentialItemDto,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    CanDisableOffreRequestDtoFromJSON,
    CanDisableOffreRequestDtoToJSON,
    ContratLieuxFormationGridDtoPaginatedResultsFromJSON,
    ContratLieuxFormationGridDtoPaginatedResultsToJSON,
    ContratLieuxFormationSearchDtoFromJSON,
    ContratLieuxFormationSearchDtoToJSON,
    DisplayNameDtoFromJSON,
    DisplayNameDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    LieuFormationCapaciteFormativeDtoFromJSON,
    LieuFormationCapaciteFormativeDtoToJSON,
    LieuFormationEditDtoFromJSON,
    LieuFormationEditDtoToJSON,
    LieuFormationGridDtoPaginatedResultsFromJSON,
    LieuFormationGridDtoPaginatedResultsToJSON,
    LieuFormationHistoryGridDtoPaginatedResultsFromJSON,
    LieuFormationHistoryGridDtoPaginatedResultsToJSON,
    LieuFormationHistorySearchFromJSON,
    LieuFormationHistorySearchToJSON,
    LieuFormationSearchDtoFromJSON,
    LieuFormationSearchDtoToJSON,
    LieuFormationSelectorGridDtoPaginatedResultsFromJSON,
    LieuFormationSelectorGridDtoPaginatedResultsToJSON,
    LieuFormationSelectorSearchFromJSON,
    LieuFormationSelectorSearchToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface LieuFormationBaseSearchRequest {
    LieuFormationSearchDto?: LieuFormationSearchDto;
}

export interface LieuFormationCheckCanDisableOffreRequest {
    CanDisableOffreRequestDto?: CanDisableOffreRequestDto;
}

export interface LieuFormationGetCapaciteFormativeRequest {
    id?: number;
}

export interface LieuFormationGetDisplayNameRequest {
    id?: number;
}

export interface LieuFormationGetDisplayNamesRequest {
    request_body?: Array<number>;
}

export interface LieuFormationGetIdSiegeSocialRequest {
    id?: number;
}

export interface LieuFormationGetLieuFormationRequest {
    id?: number;
    idsiegeSocial?: number;
}

export interface LieuFormationGetNbContratsRequest {
    id?: number;
    idmetier?: number;
}

export interface LieuFormationGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface LieuFormationGetSelectItemsRequest {
    searchField: string;
}

export interface LieuFormationHasContratsEnCoursRequest {
    idlieuFormation?: number;
}

export interface LieuFormationIsActifRequest {
    id?: number;
}

export interface LieuFormationSaveLieuFormationRequest {
    LieuFormationEditDto?: LieuFormationEditDto;
}

export interface LieuFormationSearchForApprenantRequest {
    ContratLieuxFormationSearchDto?: ContratLieuxFormationSearchDto;
}

export interface LieuFormationSearchHistoryRequest {
    LieuFormationHistorySearch?: LieuFormationHistorySearch;
}

export interface LieuFormationSearchLieuFormationIdsRequest {
    LieuFormationSearchDto?: LieuFormationSearchDto;
}

export interface LieuFormationSearchSelectorRequest {
    LieuFormationSelectorSearch?: LieuFormationSelectorSearch;
}

/**
 * 
 */
export class LieuFormationApi extends runtime.BaseAPI {

    /**
     */
    async lieuFormationBaseSearchRaw(requestParameters: LieuFormationBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormation/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LieuFormationSearchDtoToJSON(requestParameters.LieuFormationSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationBaseSearch(requestParameters: LieuFormationBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationGridDtoPaginatedResults> {
        const response = await this.lieuFormationBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationCheckCanDisableOffreRaw(requestParameters: LieuFormationCheckCanDisableOffreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormation/CheckCanDisableOffre`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CanDisableOffreRequestDtoToJSON(requestParameters.CanDisableOffreRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationCheckCanDisableOffre(requestParameters: LieuFormationCheckCanDisableOffreRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.lieuFormationCheckCanDisableOffreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationGetCapaciteFormativeRaw(requestParameters: LieuFormationGetCapaciteFormativeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationCapaciteFormativeDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormation/GetCapaciteFormative`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationCapaciteFormativeDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationGetCapaciteFormative(requestParameters: LieuFormationGetCapaciteFormativeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationCapaciteFormativeDto> {
        const response = await this.lieuFormationGetCapaciteFormativeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationGetDisplayNameRaw(requestParameters: LieuFormationGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormation/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async lieuFormationGetDisplayName(requestParameters: LieuFormationGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.lieuFormationGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationGetDisplayNamesRaw(requestParameters: LieuFormationGetDisplayNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DisplayNameDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormation/GetDisplayNames`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request_body,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DisplayNameDtoFromJSON));
    }

    /**
     */
    async lieuFormationGetDisplayNames(requestParameters: LieuFormationGetDisplayNamesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DisplayNameDto>> {
        const response = await this.lieuFormationGetDisplayNamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationGetIdSiegeSocialRaw(requestParameters: LieuFormationGetIdSiegeSocialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormation/GetIdSiegeSocial`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async lieuFormationGetIdSiegeSocial(requestParameters: LieuFormationGetIdSiegeSocialRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.lieuFormationGetIdSiegeSocialRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationGetLieuFormationRaw(requestParameters: LieuFormationGetLieuFormationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.idsiegeSocial !== undefined) {
            queryParameters['idsiegeSocial'] = requestParameters.idsiegeSocial;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationGetLieuFormation(requestParameters: LieuFormationGetLieuFormationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationEditDto> {
        const response = await this.lieuFormationGetLieuFormationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationGetNbContratsRaw(requestParameters: LieuFormationGetNbContratsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.idmetier !== undefined) {
            queryParameters['idmetier'] = requestParameters.idmetier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormation/GetNbContrats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async lieuFormationGetNbContrats(requestParameters: LieuFormationGetNbContratsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.lieuFormationGetNbContratsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationGetSearchCriteriasRaw(requestParameters: LieuFormationGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormation/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async lieuFormationGetSearchCriterias(requestParameters: LieuFormationGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.lieuFormationGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationGetSelectItemsRaw(requestParameters: LieuFormationGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling lieuFormationGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormation/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async lieuFormationGetSelectItems(requestParameters: LieuFormationGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.lieuFormationGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationHasContratsEnCoursRaw(requestParameters: LieuFormationHasContratsEnCoursRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idlieuFormation !== undefined) {
            queryParameters['idlieuFormation'] = requestParameters.idlieuFormation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormation/HasContratsEnCours`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationHasContratsEnCours(requestParameters: LieuFormationHasContratsEnCoursRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.lieuFormationHasContratsEnCoursRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationIsActifRaw(requestParameters: LieuFormationIsActifRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormation/IsActif`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationIsActif(requestParameters: LieuFormationIsActifRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.lieuFormationIsActifRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationSaveLieuFormationRaw(requestParameters: LieuFormationSaveLieuFormationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LieuFormationEditDtoToJSON(requestParameters.LieuFormationEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationSaveLieuFormation(requestParameters: LieuFormationSaveLieuFormationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationEditDto> {
        const response = await this.lieuFormationSaveLieuFormationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationSearchForApprenantRaw(requestParameters: LieuFormationSearchForApprenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratLieuxFormationGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormation/SearchForApprenant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContratLieuxFormationSearchDtoToJSON(requestParameters.ContratLieuxFormationSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratLieuxFormationGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationSearchForApprenant(requestParameters: LieuFormationSearchForApprenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratLieuxFormationGridDtoPaginatedResults> {
        const response = await this.lieuFormationSearchForApprenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationSearchHistoryRaw(requestParameters: LieuFormationSearchHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationHistoryGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormation/SearchHistory`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LieuFormationHistorySearchToJSON(requestParameters.LieuFormationHistorySearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationHistoryGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationSearchHistory(requestParameters: LieuFormationSearchHistoryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationHistoryGridDtoPaginatedResults> {
        const response = await this.lieuFormationSearchHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationSearchLieuFormationIdsRaw(requestParameters: LieuFormationSearchLieuFormationIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormation/SearchLieuFormationIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LieuFormationSearchDtoToJSON(requestParameters.LieuFormationSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async lieuFormationSearchLieuFormationIds(requestParameters: LieuFormationSearchLieuFormationIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.lieuFormationSearchLieuFormationIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationSearchSelectorRaw(requestParameters: LieuFormationSearchSelectorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationSelectorGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormation/SearchSelector`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LieuFormationSelectorSearchToJSON(requestParameters.LieuFormationSelectorSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationSelectorGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationSearchSelector(requestParameters: LieuFormationSearchSelectorRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationSelectorGridDtoPaginatedResults> {
        const response = await this.lieuFormationSearchSelectorRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
