import { FGCustomPanel, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { CalendrierApi, FcbCalendrierDtoFromJSON } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { FGWalterDateMaskInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";
import { ETLCodes } from "../../../../locales";
import { CalendrierDatePicker } from "./CalendrierDatePicker";

export interface ICalendrierDetailPageProps {}

export const CalendrierDetailPage: React.FunctionComponent<ICalendrierDetailPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(CalendrierApi);
  const { id, state } = useParams<{ id: string; state: string }>();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +id <= 0
        ? FcbCalendrierDtoFromJSON({ calendrierId: 0, conges: [], dateDebut: new Date(), dateFin: new Date() })
        : api.calendrierGet({ id: +id }),
    saveApiFn: d => api.calendrierSave({ FcbCalendrierDto: d }),
    deleteApiFn: d => api.calendrierDelete({ id: d.calendrierId }),
    onDeletedRoute: () => `${ERoutes.calendrier}/search`
  });

  const [centre, cLoading] = useReferential(a => a.referentialGetCentre(), true, []);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idCentre: Yup.number()
        .required(t(ETLCodes.Required))
        .notOneOf([-1, 0], t(ETLCodes.Required)),
      libelle: Yup.string().required(t(ETLCodes.Required)),
      dateDebut: Yup.date().required(t(ETLCodes.Required)),
      dateFin: Yup.date().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    <>
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        loading={loading}
        onDelete={deleteItem}
        onCancel={() => history.push(`${ERoutes.calendrier}/search`)}
        deleting={deleting}
        saving={saving}
        validationSchema={FormSchema}
        editMode={state === "edit"}
      >
        <FieldGroup columns={2}>
          <FieldGroup>
            <FGWalterSelectInput items={centre} loading={cLoading} name="idCentre" label={t(ETLCodes.Centre)} />
            <FGTextInput name="libelle" label={t(ETLCodes.Libelle)} maxLength={50} />
            <FGWalterDateMaskInput name="dateDebut" label={t(ETLCodes.DateDebut)} />
            <FGWalterDateMaskInput name="dateFin" label={t(ETLCodes.DateFin)} />
          </FieldGroup>
        </FieldGroup>
        <FieldGroup>
          <FGCustomPanel>
            {ctx => <>{!loading && data && <CalendrierDatePicker formik={ctx.formik} edit={ctx.editMode} />}</>}
          </FGCustomPanel>
        </FieldGroup>
      </SmallFormGenerator>
    </>
  );
};
