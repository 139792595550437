import { IOptionProps } from "@blueprintjs/core";
import * as React from "react";
import SelectVirtualized from "react-select-virtualized";

import { useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

export interface IClasseMatiereListEditorProps {
  initialValue: number | string;
  onValueChanged: (value: number | string) => void;
  items: IOptionProps[];
  loading: boolean;
}

export const ClasseMatiereListEditor: React.FunctionComponent<IClasseMatiereListEditorProps> = ({
  initialValue,
  onValueChanged,
  items,
  loading
}) => {
  const { t } = useTl();
  const [currentValue, setCurrentValue] = React.useState<number | string>();
  const [editedValue, setEditedValue] = React.useState(null);
  const [focused, setFocused] = React.useState(false);

  React.useEffect(() => {
    setCurrentValue(initialValue);
    const found = items?.find(v => +v.value === +initialValue);
    setEditedValue(found);
  }, [initialValue, items]);

  const onBlur = React.useCallback(
    async (e: any) => {
      if (editedValue && +editedValue.value !== +currentValue) {
        setCurrentValue(+editedValue.value);
        onValueChanged(+editedValue.value);
      }
      setFocused(false);
    },
    [currentValue, editedValue, onValueChanged]
  );

  const finalItems = React.useMemo(() => items?.map(i => ({ value: i.value || "", label: i.label || " " })), [items]);

  const computeFocus = React.useCallback(() => {
    if (!focused) {
      setFocused(true);
    }
  }, [focused]);

  const displayedValue = React.useMemo(() => (+editedValue?.value > 0 ? editedValue?.label : t(ETLCodes.NoValue)), [
    editedValue,
    t
  ]);

  return (
    <>
      {focused ? (
        <SelectVirtualized
          styles={{
            control: base => {
              let final = {
                ...base,
                minHeight: "28px",
                minWidth: "200px"
              };
              return final;
            },
            input: base => ({ ...base, margin: "1px 2px" }),
            clearIndicator: base => ({ ...base, padding: "4px", cursor: "pointer" }),
            dropdownIndicator: base => ({ ...base, padding: "4px", cursor: "pointer" }),
            menu: base => ({ ...base, zIndex: 9999 }),
            menuPortal: base => ({ ...base, zIndex: 9999 })
          }}
          isLoading={loading}
          options={finalItems}
          onChange={value => setEditedValue(value)}
          menuPortalTarget={document.body}
          menuPlacement="auto"
          value={editedValue}
          loadingMessage={() => t(ETLCodes.FGSelectInput_Loading)}
          noOptionsMessage={() => t(ETLCodes.FGSelectInput_NoOptions)}
          placeholder={t(ETLCodes.FGSelectInput_SelectAnItem)}
          tabSelectsValue={true}
          onBlur={onBlur}
          autoFocus
          menuIsOpen={true}
        ></SelectVirtualized>
      ) : (
        <div
          style={{
            marginLeft: 9,
            marginTop: 4
          }}
          onClick={() => computeFocus()}
        >
          {displayedValue}
        </div>
      )}
    </>
  );
};
