/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbApprenantPresenceDetailDto
 */
export interface FcbApprenantPresenceDetailDto {
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantPresenceDetailDto
     */
    contrat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantPresenceDetailDto
     */
    apprenant?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantPresenceDetailDto
     */
    idInscription?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantPresenceDetailDto
     */
    idApprenant?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof FcbApprenantPresenceDetailDto
     */
    statutClasseHoraire?: { [key: string]: string; } | null;
}

/**
 * Check if a given object implements the FcbApprenantPresenceDetailDto interface.
 */
export function instanceOfFcbApprenantPresenceDetailDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbApprenantPresenceDetailDtoFromJSON(json: any): FcbApprenantPresenceDetailDto {
    return FcbApprenantPresenceDetailDtoFromJSONTyped(json, false);
}

export function FcbApprenantPresenceDetailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbApprenantPresenceDetailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contrat': !exists(json, 'contrat') ? undefined : json['contrat'],
        'apprenant': !exists(json, 'apprenant') ? undefined : json['apprenant'],
        'idInscription': !exists(json, 'idInscription') ? undefined : json['idInscription'],
        'idApprenant': !exists(json, 'idApprenant') ? undefined : json['idApprenant'],
        'statutClasseHoraire': !exists(json, 'statutClasseHoraire') ? undefined : json['statutClasseHoraire'],
    };
}

export function FcbApprenantPresenceDetailDtoToJSON(value?: FcbApprenantPresenceDetailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contrat': value.contrat,
        'apprenant': value.apprenant,
        'idInscription': value.idInscription,
        'idApprenant': value.idApprenant,
        'statutClasseHoraire': value.statutClasseHoraire,
    };
}

