import { Colors, Icon, Tooltip } from "@blueprintjs/core";
import { ButtonContainer, DataTable, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";

import {
  DemandeFormationCreaApi,
  DemandeFormationGridDto,
  DemandeFormationSearch,
  EStatutDemande,
  ParcoursFormationApi
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BooleanColumn, CircleColumn, CustomBulletList, EditButton, ViewButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IProspectParcoursCreaDemandeListProps {
  editMode: boolean;
}

const LegendHeaderContainer = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

export const ProspectParcoursCreaDemandeList: React.FunctionComponent<IProspectParcoursCreaDemandeListProps> = ({
  editMode
}) => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(DemandeFormationCreaApi);
  const pfApi = useApiService(ParcoursFormationApi);
  const { id } = useParams<{
    id: string;
    state: string;
  }>();

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: false,
    enableFilter: false,
    pageSize: 999999,
    sortKeys: { annee: "DESC" }
  });

  const searchFunc = React.useCallback(
    (sObj?: DemandeFormationSearch) => {
      sObj.idapprenant = +id;
      return api.demandeFormationCreaBaseSearch({ DemandeFormationSearch: sObj });
    },
    [api, id]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction: searchFunc,
    tableState,
    initialSearch: true
  });

  const getStatutDemandeCodeIdFunc = React.useCallback(async () => {
    return await pfApi.parcoursFormationGetStatutDemandeCodeId();
  }, [pfApi]);

  const { data: statutDemandeCode } = useQuery("StatutDemandeCode", getStatutDemandeCodeIdFunc);

  const defineColor = React.useCallback(
    (idstatutDemande: number) => {
      const codeStatut = statutDemandeCode?.find(sdc => sdc.idstatutDemande === idstatutDemande)?.code;

      switch (codeStatut) {
        case EStatutDemande.I:
        case EStatutDemande.V:
          return Colors.GREEN3;
        case EStatutDemande.EA:
          return Colors.RED3;
        case EStatutDemande.EV:
          return Colors.ORANGE3;
        case EStatutDemande.E:
          return Colors.GOLD5;
        case EStatutDemande.P:
          return Colors.BLUE3;
        case EStatutDemande.AE:
          return Colors.GRAY1;
        case EStatutDemande.EC:
          return Colors.GRAY3;
        case EStatutDemande.CC:
          return Colors.RED3;
        case EStatutDemande.CE:
          return Colors.RED3;
        case EStatutDemande.ER:
          return Colors.RED3;
        case EStatutDemande.MS:
          return Colors.VIOLET4;
        case EStatutDemande.DS:
          return Colors.RED3;
        case EStatutDemande.SU:
          return Colors.RED3;
        case EStatutDemande.RC:
          return Colors.RED3;
        default:
          return Colors.GRAY3;
      }
    },
    [statutDemandeCode]
  );

  const defineTextCode = React.useCallback(
    (idstatutDemande: number) => {
      const codeStatut = statutDemandeCode?.find(sdc => sdc.idstatutDemande === idstatutDemande)?.code;

      switch (codeStatut) {
        case EStatutDemande.I:
          return t(ETLCodes.Inscrit);
        case EStatutDemande.V:
          return t(ETLCodes.Validee);
        case EStatutDemande.E:
          return t(ETLCodes.Envoyee);
        case EStatutDemande.EA:
          return t(ETLCodes.EnAbandon);
        case EStatutDemande.EV:
          return t(ETLCodes.EnValidation);
        case EStatutDemande.P:
          return t(ETLCodes.BrouillonPret);
        case EStatutDemande.AE:
          return t(ETLCodes.Brouillon);
        case EStatutDemande.EC:
          return t(ETLCodes.EnCorrection);
        case EStatutDemande.CC:
          return t(ETLCodes.ChangementClasse);
        case EStatutDemande.CE:
          return t(ETLCodes.ChangementCentre);
        case EStatutDemande.ER:
          return t(ETLCodes.EnRenvoi);
        case EStatutDemande.MS:
          return t(ETLCodes.MisEnSortie);
        case EStatutDemande.DS:
          return t(ETLCodes.DemandeSuppression);
        case EStatutDemande.SU:
          return t(ETLCodes.Supprime);
        case EStatutDemande.RC:
          return t(ETLCodes.Refuse);
        default:
          return "";
      }
    },
    [statutDemandeCode, t]
  );

  const BulletList = React.useCallback(() => {
    return (
      <LegendHeaderContainer>
        <Tooltip
          content={
            <CustomBulletList
              title={t(ETLCodes.StatutDemande)}
              items={[
                {
                  text: t(ETLCodes.Validee),
                  color: Colors.GREEN3
                },
                {
                  text: t(ETLCodes.Refuse),
                  color: Colors.RED3
                },
                {
                  text: t(ETLCodes.Envoyee),
                  color: Colors.GOLD5
                }
              ]}
            />
          }
          popoverClassName="bullet-list-popover"
          position="right"
        >
          <Icon icon="info-sign" style={{ cursor: "pointer" }} />
        </Tooltip>
      </LegendHeaderContainer>
    );
  }, [t]);

  //   const [rowLoading, setRowLoading] = React.useState(0);
  //   const changeStatut = React.useCallback(
  //     async (codeStatut: string, iddemande: number) => {
  //       setRowLoading(iddemande);
  //       await dbApi.dashboardChangeStatutMultipleDemandeFormationCrea({
  //         ChangeStatutMultipleDto: {
  //           codeStatut,
  //           demandes: [iddemande]
  //         }
  //       });
  //       setRowLoading(0);
  //     },
  //     [dbApi]
  //   );

  const IsNotEditable = React.useCallback((row: any) => {
    return [EStatutDemande.I, EStatutDemande.V, EStatutDemande.RC, EStatutDemande.SU].includes(row.idstatutDemande);
  }, []);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: DemandeFormationGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => {
                history.push(`${ERoutes.prospect}/${id}/crea/view/demandeFormation/${row.iddemandeFormationCrea}`);
              }}
              //   loading={row.iddemandeFormationCrea === rowLoading}
            />
            <EditButton
              minimal={true}
              onClick={() => {
                history.push(`${ERoutes.prospect}/${id}/crea/edit/demandeFormation/${row.iddemandeFormationCrea}`);
              }}
              disabled={IsNotEditable(row) || !editMode}
              //   loading={row.iddemandeFormationCrea === rowLoading}
            />
            {/* <Popover
                position="bottom"
                content={
                  <PopoverContent>
                    <Menu>
                      {![EStatutDemande.EA, EStatutDemande.SU, EStatutDemande.ER, EStatutDemande.DS].includes(
                        statutDemandeCode?.find(sdc => sdc.idstatutDemande === row.idstatutDemande)?.code
                      ) && (
                        <MenuItem
                          icon={
                            statutDemandeCode?.find(sdc => sdc.idstatutDemande === row.idstatutDemande)?.code ===
                            EStatutDemande.I
                              ? IconNames.Cross
                              : IconNames.Trash
                          }
                          text={t(
                            statutDemandeCode?.find(sdc => sdc.idstatutDemande === row.idstatutDemande)?.code ===
                              EStatutDemande.I
                              ? ETLCodes.DemanderSuppression
                              : ETLCodes.Supprimer
                          )}
                          onClick={() => deleteFunc(row)}
                          intent={Intent.PRIMARY}
                        />
                      )}
                    </Menu>
                  </PopoverContent>
                }
              >
                <Button
                  icon={IconNames.CHEVRON_DOWN}
                  minimal={true}
                  loading={row.iddemandeFormationCrea === deleting || row.iddemandeFormationCrea === rowLoading}
                  disabled={!hasButtons}
                ></Button>
              </Popover> */}
          </ButtonContainer>
        )
      },
      {
        disableSorting: true,
        autoFitContent: true,
        alignment: "center",
        header: () => <BulletList />,
        fieldName: "statutDemande",
        render: (row: DemandeFormationGridDto) =>
          row.idstatutDemande && (
            <div style={{ marginTop: "6px" }}>
              <CircleColumn color={defineColor(row.idstatutDemande)} tooltip={defineTextCode(row.idstatutDemande)} />
            </div>
          )
      },
      {
        header: () => t(ETLCodes.Module),
        fieldName: "module"
      },
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "annee"
      },
      {
        header: () => t(ETLCodes.PremierSemestre),
        fieldName: "premierSemestre",
        render: (row: DemandeFormationGridDto) => <BooleanColumn value={row.premierSemestre} />
      },
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "centre"
      },
      {
        header: () => t(ETLCodes.RemarqueInvalidation),
        fieldName: "remarqueInvalidation"
      }
    ],
    [IsNotEditable, editMode, history, id, BulletList, defineColor, defineTextCode, t]
  );

  return <DataTable dateFormat="dd/MM/yyyy" tableState={tableState} columns={columns} loading={loading} />;
};
