import { Button, Divider, Icon, Tag } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { FormikProps } from "formik";
import moment from "moment";
import {
  FGCustomPanel,
  FGEmpty,
  FGListen,
  FGMultiSuggestInput,
  FGNumberInput,
  FGTextAreaInput,
  FGTextInput,
  FieldGroup,
  showError,
  useApiEffect
} from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";

import { ClasseApi, EIfapmeSide, FcbClasseDetailDtoFromJSON, ReferentielApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterCheckboxInput, FGWalterDateMaskInput, FGWalterSelectInput } from "../../../../../components";
import { FGEuroMaskInput } from "../../../../../components/formGenerator/FGEuroMaskInput";
import { SmallFormGenerator } from "../../../../../components/SmallFormGenerator";
import { useBreadcrumbs } from "../../../../../contexts";
import { useApiService, useCrudApi, useManageError, useTheme, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

const ButtonContainer = styled.div`
  display: flex;
  & * + * {
    margin-left: 1rem;
  }
`;

export interface IClasseDetailProps {}

export const ClasseDetail: React.FunctionComponent<IClasseDetailProps> = () => {
  const { t } = useTl();
  const { id, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();
  const api = useApiService(ClasseApi);
  const referentielApi = useApiService(ReferentielApi);
  const { changeLastBreadcrumbDisplayedText } = useBreadcrumbs();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +id <= 0
        ? FcbClasseDetailDtoFromJSON({ idclasse: 0, uniteActivite: true, principale: true, deliberee: true })
        : api.classeGet({ id: +id }),
    saveApiFn: d => api.classeSave({ FcbClasseDetailDto: d }),
    deleteApiFn: d => api.classeDelete({ id: d.idclasse }),
    onDeletedRoute: () => ERoutes.classe,
    onSavedRoute: d => `${ERoutes.classe}/${d.idclasse}/detail/${state}`
  });

  const { manageError } = useManageError();

  const [centreId, setCentreId] = React.useState(0);
  const [genreValue, setGenreValue] = React.useState("");
  const [idDegre, setIdDegre] = React.useState("");
  const [idMetier, setIdMetier] = React.useState(0);
  const [particulariteCours, setParticulariteCours] = React.useState("");
  const [firstMetierChange, setFirstMetierChange] = React.useState(true);
  const [firstDegreChange, setFirstDegreChange] = React.useState(true);
  const [emailsLoading, setEmailsLoading] = React.useState(false);

  React.useEffect(() => {
    if (data) {
      setIdMetier(data.idmetier);
      setIdDegre(data.iddegre);
      setGenreValue(data.genre);
      setCentreId(data.idcentre);
      setParticulariteCours(data.particulariteCours);
    }
  }, [data]);

  const [referentielLoading, setReferentielLoading] = React.useState(false);

  const [centre, cLoading] = useReferential(a => a.referentialGetCentre(), true, [], true);
  const [lieu, lLoading] = useReferential(a => a.referentialGetLieu({ idCentre: centreId }), true, [centreId]);
  const [annee, aLoading] = useReferential(a => a.referentialGetAnneeScolaire(), false, [], true);
  const [genre, gLoading] = useReferential(a => a.referentialGetGenreClasse(), false, [], true);
  const [degre, dLoading] = useReferential(a => a.referentialGetDegre(), false, [], true);
  const [referentiel, rLoading] = useReferential(
    a => a.referentialGetReferentielByDegreAndMetier({ idDegre, idMetier: idMetier ?? 0 }),
    true,
    [idDegre, idMetier],
    true
  );
  const [particularite, pLoading] = useReferential(
    a => a.referentialGetSpecificiteCours({ code: particulariteCours }),
    false,
    [particulariteCours],
    true
  );
  const { ifapmeSide } = useTheme();
  const [typeCours, tcLoading] = useReferential(
    a => a.referentialGetTypeCours({ ifapmeSide: ifapmeSide === "hope" ? EIfapmeSide.Hope : EIfapmeSide.Walter }),
    false,
    [ifapmeSide]
  );
  const [metier, mLoading] = useReferential(a => a.referentialGetMetierByDegre({ idDegre }), true, [idDegre], true);
  const [secteur, sLoading] = useReferential(
    a => a.referentialGetSecteurHomogeneByDegre({ idDegre }),
    true,
    [idDegre],
    true
  );
  const [typeReussite, trLoading] = useReferential(a => a.referentialGetTypeReussite(), true, [], true);
  const [typeBulletin, loadingTypeBulletin] = useReferential(a => a.referentialGetTypesBulletin(), true, []);
  // const [certifications, loadingCertifications] = useReferential(a => a.referentialGetCertifications(), true, []);
  const [local, loLoading] = useReferential(
    a => a.referentialGetLocalByCentre({ idCentre: centreId }),
    true,
    [centreId],
    true
  );

  const [isReferentialEditable] = useApiEffect(() => api.classeIsReferentielEditable({ id: +id }), [id]);
  const [societesExternes, seLoading] = useReferential(
    apiC => apiC.referentialGetSocietesExternesByMetier({ idmetier: idMetier }),
    true,
    [idMetier]
  );

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idcentre: Yup.number()
        .nullable()
        .required(t(ETLCodes.Required)),
      dateCours: Yup.date().required(t(ETLCodes.Required)),
      nom: Yup.string().required(t(ETLCodes.Required)),
      genre: Yup.string().required(t(ETLCodes.Required)),
      iddegre: Yup.string().required(t(ETLCodes.Required)),
      typeCours: Yup.string().required(t(ETLCodes.Required)),
      anneeAcademique: Yup.string().required(t(ETLCodes.Required)),
      idmetier: Yup.number()
        .nullable()
        .required(t(ETLCodes.Required)),
      idreferentiel: Yup.number()
        .nullable()
        .when("genre", (value, schema) => (value !== "S" ? schema.required(t(ETLCodes.Required)) : schema)),
      particulariteCours: Yup.string()
        .nullable()
        .required(t(ETLCodes.Required))
    });
  }, [t]);

  React.useEffect(() => {
    if (data) {
      changeLastBreadcrumbDisplayedText(data.nom + " - " + data.codeclasse);
    }
  }, [data, changeLastBreadcrumbDisplayedText]);

  const bloquee = React.useMemo(() => data?.bloqueLocalisation || data?.bloqueAgrement, [data]);

  const setReferentiel = React.useCallback((formik?: FormikProps<any>) => {
    formik.setFieldValue("idreferentiel", null);
    formik.setFieldTouched("idreferentiel");
  }, []);

  const setMetier = React.useCallback((formik?: FormikProps<any>) => {
    formik.setFieldValue("idmetier", null);
    formik.setFieldTouched("idmetier");
  }, []);

  const getApprenantEmailsAndMailTo = React.useCallback(async () => {
    setEmailsLoading(true);
    try {
      const res = await api.classeGetApprenantEmails({ idClasse: +id });
      if (!!res) {
        const el = document.createElement("a");
        el.setAttribute("href", `mailto:${res.azureEmails}?bcc=${res.emails}`);
        el.click();
      } else {
        showError(t(ETLCodes.AucunEmailTrouvé));
      }
      setEmailsLoading(false);
    } catch (e) {
      setEmailsLoading(false);
      manageError(e);
    }
  }, [api, id, manageError, t]);

  const getAllEmailsAndMailTo = React.useCallback(async () => {
    setEmailsLoading(true);
    try {
      const res = await api.classeGetAllEmails({ idClasse: +id });
      if (!!res) {
        const el = document.createElement("a");
        el.setAttribute("href", `mailto:${res.azureEmails}?bcc=${res.emails}`);
        el.click();
      } else {
        showError(t(ETLCodes.AucunEmailTrouvé));
      }
      setEmailsLoading(false);
    } catch (e) {
      setEmailsLoading(false);
      manageError(e);
    }
  }, [api, id, manageError, t]);

  const mailButtons = React.useMemo(
    () => (
      <ButtonContainer>
        <Button
          icon={IconNames.ENVELOPE}
          text={t(ETLCodes.CreerMailApprenants)}
          loading={emailsLoading}
          onClick={getApprenantEmailsAndMailTo}
        />
        <Button
          icon={IconNames.ENVELOPE}
          text={t(ETLCodes.CreerMailAll)}
          loading={emailsLoading}
          onClick={getAllEmailsAndMailTo}
        />
      </ButtonContainer>
    ),
    [emailsLoading, getAllEmailsAndMailTo, getApprenantEmailsAndMailTo, t]
  );

  const isDegreMultiple = React.useCallback((iddegre: string) => {
    if (!iddegre) return false;

    return ["00", "08", "10", "11", "12", "14", "15", "16", "17", "18", "19", "20", "28"].includes(iddegre);
  }, []);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      saving={saving}
      deleting={deleting}
      onDelete={deleteItem}
      loading={loading}
      editMode={state === "edit"}
      onCancel={() => history.push(`${ERoutes.classe}`)}
      validationSchema={FormSchema}
      showDeleteButton={!bloquee}
    >
      <FGListen field="idcentre" onChanged={value => setCentreId(value)} />
      <FGListen
        field="idmetier"
        onChanged={(value, formik) => {
          setIdMetier(value);

          if (value !== data?.idmetier) {
            setFirstMetierChange(false);
            setReferentiel(formik);
          } else if (!firstMetierChange) {
            setReferentiel(formik);
          }
        }}
      />
      <FGListen
        field="iddegre"
        onChanged={(value, formik) => {
          setIdDegre(value === "null" ? null : value);

          if (value !== data?.iddegre) {
            setFirstDegreChange(false);
            setMetier(formik);
          } else if (!firstDegreChange) {
            setMetier(formik);
          }
        }}
      />
      <FGListen
        field="genre"
        onChanged={(value, formik) => {
          setGenreValue(value);
          if (value !== data?.genre) {
            formik.setFieldTouched("genre");
          }
          if (value === "S" && +id <= 0) {
            formik.setFieldValue("idreferentiel", null);
          }
        }}
      />
      <FGListen
        field="idreferentiel"
        onChanged={async (value, formik) => {
          setReferentielLoading(true);
          try {
            if (value !== null) {
              const values = await referentielApi.referentielGet({ id: value });
              if (value !== data?.idreferentiel) {
                formik.setFieldValue("pcReussite", values.pcreussite);
                formik.setFieldValue("idtypeReussite", values.idtypeReussite);
                formik.setFieldValue("coutTotal", values.coutmatiere);
              }
            }
            setReferentielLoading(false);
          } catch (e) {
            setReferentielLoading(false);
          }
        }}
      />
      <FGListen
        field="dateCours"
        onChanged={(value, formik) => {
          if (value && annee) {
            const year = moment(value).year();
            const partOfYearToFind = moment(value).month() >= 7 ? 0 : 5;
            const anneeAcademique = annee.find(a => a.value.toString().substr(partOfYearToFind, 4) === year.toString());
            if (value !== data?.dateCours) {
              formik.setFieldValue("anneeAcademique", anneeAcademique.value.toString(), true);
            }
          }
        }}
      />
      <FieldGroup
        columns={2}
        fieldsetProps={{
          rightElement: mailButtons,
          title: t(ETLCodes.DonneesClasse)
        }}
      >
        <FieldGroup>
          <FGWalterSelectInput
            label={t(ETLCodes.CentreFormation)}
            items={centre}
            loading={cLoading}
            name="idcentre"
            disabled={+id > 0}
          />
          <FGWalterDateMaskInput label={t(ETLCodes.PremierCours)} name="dateCours" />
          <FGTextInput label={t(ETLCodes.NomClasse)} name="nom" maxLength={50} />
        </FieldGroup>
        <FieldGroup>
          <FGWalterSelectInput label={t(ETLCodes.LieuFormation)} items={lieu} loading={lLoading} name="idlieu" />
          <FGWalterSelectInput
            label={t(ETLCodes.AnneeScolaire)}
            items={annee}
            loading={aLoading}
            name="anneeAcademique"
          />
          <FGTextInput label={t(ETLCodes.PlanLocalisation)} readonly name="idplanLocalisation" />
        </FieldGroup>
      </FieldGroup>
      <Divider />
      <FieldGroup>
        <FieldGroup columns={2}>
          <FieldGroup>
            <FGWalterSelectInput label={t(ETLCodes.Genre)} items={genre} loading={gLoading} name="genre" />
            <FGWalterSelectInput
              label={t(ETLCodes.Degre)}
              items={degre}
              loading={dLoading}
              name="iddegre"
              disabled={genreValue !== "S" && !isReferentialEditable?.value}
            />
            <FGWalterSelectInput
              label={t(ETLCodes.Referentiel)}
              items={referentiel}
              loading={rLoading}
              name="idreferentiel"
              disabled={genreValue === "S" || !isReferentialEditable?.value}
              requiredMark={genreValue !== "S"}
            />
            <FGWalterSelectInput
              label={t(ETLCodes.Specificite)}
              items={particularite}
              loading={pLoading}
              name="particulariteCours"
            />
            <FGCustomPanel>
              {({ formik }) => (
                <FGWalterSelectInput
                  label={t(ETLCodes.SecteurHomogene)}
                  name="idsecteur"
                  items={secteur}
                  loading={sLoading}
                  disabled={formik.values?.particulariteCours !== "H"}
                />
              )}
            </FGCustomPanel>
          </FieldGroup>
          <FieldGroup>
            <FGWalterSelectInput label={t(ETLCodes.Type)} items={typeCours} loading={tcLoading} name="typeCours" />
            <FGWalterSelectInput
              label={t(ETLCodes.Metier)}
              items={metier}
              loading={mLoading}
              name="idmetier"
              disabled={genreValue !== "S" && !isReferentialEditable?.value}
            />
            <FGMultiSuggestInput
              name="idsSocieteExterne"
              label={t(ETLCodes.SocietesExternes)}
              items={societesExternes}
              loading={seLoading}
              visible={() => societesExternes?.length > 0}
            />
            <FGNumberInput
              label={t(ETLCodes.SeuilReussite)}
              name="pcReussite"
              rightElement={
                <Tag>
                  <Icon icon={IconNames.PERCENTAGE} />
                </Tag>
              }
              max={100}
              disabled={referentielLoading}
            />
            <FGWalterSelectInput
              label={t(ETLCodes.TypeReussite)}
              items={typeReussite}
              loading={trLoading || referentielLoading}
              name="idtypeReussite"
            />
            <FGCustomPanel>
              {ctx => (
                <>
                  <FGWalterSelectInput
                    name="idtypeBulletin"
                    label={t(ETLCodes.TypeBulletin)}
                    items={typeBulletin}
                    loading={loadingTypeBulletin}
                    // readonly={!!ctx?.formik?.values?.idreferentiel || genreValue !== "S"}
                    // readonly={genreValue !== "S"}
                  />
                  <FGWalterCheckboxInput
                    name="e8E9"
                    label={t(ETLCodes.E8E9)}
                    // readonly={!!ctx?.formik?.values?.idreferentiel || genreValue !== "S"}
                    // readonly={genreValue !== "S"}
                  />
                </>
              )}
            </FGCustomPanel>
            {/* <FGWalterSelectInput
              name="idcertification"
              label={t(ETLCodes.Certification)}
              items={certifications}
              loading={loadingCertifications}
              visible={ctx => !ctx?.formik?.values?.idreferentiel && genreValue === "S"}
            /> */}
          </FieldGroup>
        </FieldGroup>
        <FieldGroup columns={3}>
          <FieldGroup>
            <FGWalterCheckboxInput label={t(ETLCodes.ResultatsBloques)} name="resultatLock" />
            <FGWalterCheckboxInput label={t(ETLCodes.UniteActivite)} name="uniteActivite" />
          </FieldGroup>
          <FieldGroup>
            <FGWalterCheckboxInput label={t(ETLCodes.ExamenC)} name="examenc" />
            <FGWalterCheckboxInput label={t(ETLCodes.ClassePrincipale)} name="principale" />
          </FieldGroup>
          <FieldGroup>
            <FGEuroMaskInput label={t(ETLCodes.CoutTotal)} name="coutTotal" disabled={referentielLoading} />
            <FGCustomPanel>
              {ctx => (
                <FGWalterCheckboxInput
                  label={t(ETLCodes.ADeliberer)}
                  name="deliberee"
                  disabled={!ctx?.formik?.values?.idreferentiel && isDegreMultiple(ctx?.formik?.values?.iddegre)}
                />
              )}
            </FGCustomPanel>
          </FieldGroup>
        </FieldGroup>
        <FieldGroup>
          <FGEmpty />
          <FGTextAreaInput label={t(ETLCodes.FraisPOE)} name="fraisPoe" />
          <FGTextAreaInput label={t(ETLCodes.Remarques)} name="remarque" />
        </FieldGroup>
        <Divider />
        <FieldGroup columns={2}>
          <FieldGroup columns={[5, 2, 5]}>
            <FGNumberInput
              min={0}
              max={14}
              name="coursLundi"
              label={t(ETLCodes.Lundi)}
              disabled={!centreId || centreId === 0}
            />
            <FGNumberInput min={0} max={14} name="coursLundiFin" disabled={!centreId || centreId === 0} />
            <FGWalterSelectInput
              items={local}
              loading={loLoading}
              name="idlocalJour1"
              disabled={!centreId || centreId === 0}
            />
          </FieldGroup>
          <FieldGroup columns={[5, 2, 5]}>
            <FGNumberInput
              min={0}
              max={14}
              name="coursMardi"
              label={t(ETLCodes.Mardi)}
              disabled={!centreId || centreId === 0}
            />
            <FGNumberInput min={0} max={14} name="coursMardiFin" disabled={!centreId || centreId === 0} />
            <FGWalterSelectInput
              items={local}
              loading={loLoading}
              name="idlocalJour2"
              disabled={!centreId || centreId === 0}
            />
          </FieldGroup>
          <FieldGroup columns={[5, 2, 5]}>
            <FGNumberInput
              min={0}
              max={14}
              name="coursMercredi"
              label={t(ETLCodes.Mercredi)}
              disabled={!centreId || centreId === 0}
            />
            <FGNumberInput min={0} max={14} name="coursMercrediFin" disabled={!centreId || centreId === 0} />
            <FGWalterSelectInput
              items={local}
              loading={loLoading}
              name="idlocalJour3"
              disabled={!centreId || centreId === 0}
            />
          </FieldGroup>
          <FieldGroup columns={[5, 2, 5]}>
            <FGNumberInput
              min={0}
              max={14}
              name="coursJeudi"
              label={t(ETLCodes.Jeudi)}
              disabled={!centreId || centreId === 0}
            />
            <FGNumberInput min={0} max={14} name="coursJeudiFin" disabled={!centreId || centreId === 0} />
            <FGWalterSelectInput
              items={local}
              loading={loLoading}
              name="idlocalJour4"
              disabled={!centreId || centreId === 0}
            />
          </FieldGroup>
          <FieldGroup columns={[5, 2, 5]}>
            <FGNumberInput
              min={0}
              max={14}
              name="coursVendredi"
              label={t(ETLCodes.Vendredi)}
              disabled={!centreId || centreId === 0}
            />
            <FGNumberInput min={0} max={14} name="coursVendrediFin" disabled={!centreId || centreId === 0} />
            <FGWalterSelectInput
              items={local}
              loading={loLoading}
              name="idlocalJour5"
              disabled={!centreId || centreId === 0}
            />
          </FieldGroup>
          <FieldGroup columns={[5, 2, 5]}>
            <FGNumberInput
              min={0}
              max={14}
              name="coursSamedi"
              label={t(ETLCodes.Samedi)}
              disabled={!centreId || centreId === 0}
            />
            <FGNumberInput min={0} max={14} name="coursSamediFin" disabled={!centreId || centreId === 0} />
            <FGWalterSelectInput
              items={local}
              loading={loLoading}
              name="idlocalJour6"
              disabled={!centreId || centreId === 0}
            />
          </FieldGroup>
          <FieldGroup columns={[5, 2, 5]}>
            <FGNumberInput
              min={0}
              max={14}
              name="coursDimanche"
              label={t(ETLCodes.Dimanche)}
              disabled={!centreId || centreId === 0}
            />
            <FGNumberInput min={0} max={14} name="coursDimancheFin" disabled={!centreId || centreId === 0} />
            <FGWalterSelectInput
              items={local}
              loading={loLoading}
              name="idlocalJour7"
              disabled={!centreId || centreId === 0}
            />
          </FieldGroup>
        </FieldGroup>
      </FieldGroup>
    </SmallFormGenerator>
  );
};
