import { Classes, Colors, Dialog } from "@blueprintjs/core";
import { FGTextAreaInput, FieldGroup } from "nsitools-react";
import * as React from "react";

import { ELevelName, SuiviActionEditDto, SuiviActionEditDtoFromJSON, FichierApi } from "../../../../../../api";
import {
  FGWalterDateMaskInput,
  FGWalterFileInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../../../components";
import { useApiService, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";
import * as Yup from "yup";
import { startOfToday } from "date-fns";

export interface SuiviActionDialogProps {
  dto: SuiviActionEditDto;
  isOpen: boolean;
  onClose: (toSave?: SuiviActionEditDto) => void;
  enveloppeId: number;
  editMode: boolean;
  editable: boolean;
}

export const SuiviActionDialog: React.FunctionComponent<SuiviActionDialogProps> = ({
  isOpen,
  onClose,
  editMode,
  editable,
  dto
}) => {
  const { t } = useTl();
  const [typeAction, taLoading] = useReferential(a => a.referentialGetTypeSuiviActions(), true, []);
  const [collaborateurServices, csLoading] = useReferential(a =>
    a.referentialGetUsersByRoles({
      ELevelName: [ELevelName.RI, ELevelName.ARI, ELevelName.COP, ELevelName.CALC],
      currentIduser: dto?.idcollaborateurService ?? 0
    })
  );

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      dateEcheance: Yup.date()
        .nullable()
        .min(startOfToday(), t(ETLCodes.DateCannotBeInPast))
        .required(t(ETLCodes.Required)),
      idtypeSuiviAction: Yup.number().required(t(ETLCodes.Required)),
      idcollaborateurService: Yup.number().required(t(ETLCodes.Required))
    });
  }, [t]);

  const api = useApiService(FichierApi);
  const downloadFn = React.useCallback(async () => await api.fichierDownload({ id: dto?.idfichier }), [
    api,
    dto?.idfichier
  ]);

  return (
    <Dialog
      isOpen={isOpen}
      isCloseButtonShown={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={false}
      title={t(ETLCodes.Action)}
      onClose={() => onClose()}
      style={{ padding: "0.5rem", width: 800, backgroundColor: Colors.WHITE }}
      enforceFocus={false}
    >
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={dto ?? SuiviActionEditDtoFromJSON({})}
          saving={false}
          deleting={true}
          onSubmit={onClose}
          editMode={editMode}
          formatDate="dd/MM/yyyy"
          loading={false}
          validationSchema={FormSchema}
          editable={editable}
        >
          <FieldGroup>
            <FGWalterDateMaskInput name="dateEcheance" label={t(ETLCodes.DateEcheance)} showCalendar={false} />
            <FGWalterSelectInput
              name="idtypeSuiviAction"
              loading={taLoading}
              items={typeAction}
              label={t(ETLCodes.TypeAction)}
            />
            <FGWalterSelectInput
              name="idcollaborateurService"
              loading={csLoading}
              items={collaborateurServices}
              label={t(ETLCodes.ResponsableAction)}
            />
            <FGTextAreaInput name="remarque" label={t(ETLCodes.RemarqueAction)} />
            <FGWalterFileInput name="fichier" label={t(ETLCodes.Document)} downloadFn={dto?.idfichier && downloadFn} />
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </Dialog>
  );
};
