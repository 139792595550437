import { Intent } from "@blueprintjs/core";
import { DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ServiceTutelleLocaliteApi, ServiceTutelleLocaliteSearch } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IServiceTutelleLocaliteLiePageProps {}

export const ServiceTutelleLocaliteLiePage: React.FunctionComponent<IServiceTutelleLocaliteLiePageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const api = useApiService(ServiceTutelleLocaliteApi);
  const idServiceTutelle = React.useMemo(() => +id, [id]);

  const tableState = useGridState<any>({
    sortKeys: { codePostal: "ASC" },
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15
  });

  const searchFunction = React.useCallback(
    (sObj?: ServiceTutelleLocaliteSearch) => {
      sObj.idserviceTutelle = idServiceTutelle;
      return api.serviceTutelleLocaliteBaseSearch({ ServiceTutelleLocaliteSearch: sObj });
    },
    [api, idServiceTutelle]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });
  const { totalCount } = tableState;
  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.Indice),
        fieldName: "codePostal"
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "localite"
      }
    ],
    [t]
  );

  return (
    <FieldSet
      title={t(ETLCodes.TableResults, { count: totalCount })}
      rightElement={
        <EditButton
          onClick={() => {
            history.push(`${ERoutes.serviceTutelle}/${idServiceTutelle}/Localites/selector`);
          }}
          intent={Intent.PRIMARY}
          text={t(ETLCodes.Modify)}
        />
      }
    >
      <DataTable dateFormat="dd/MM/yyyy" tableState={tableState} loading={loading} columns={columns}></DataTable>
    </FieldSet>
  );
};
