/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbApprenantRepresentantDto
 */
export interface FcbApprenantRepresentantDto {
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantRepresentantDto
     */
    idrepresentant?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantRepresentantDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantRepresentantDto
     */
    lienParente?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbApprenantRepresentantDto
     */
    courrier?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbApprenantRepresentantDto
     */
    isEmergencyContact?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantRepresentantDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbApprenantRepresentantDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantRepresentantDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbApprenantRepresentantDto
     */
    modificationDate?: Date | null;
}

/**
 * Check if a given object implements the FcbApprenantRepresentantDto interface.
 */
export function instanceOfFcbApprenantRepresentantDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbApprenantRepresentantDtoFromJSON(json: any): FcbApprenantRepresentantDto {
    return FcbApprenantRepresentantDtoFromJSONTyped(json, false);
}

export function FcbApprenantRepresentantDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbApprenantRepresentantDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idrepresentant': !exists(json, 'idrepresentant') ? undefined : json['idrepresentant'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'lienParente': !exists(json, 'lienParente') ? undefined : json['lienParente'],
        'courrier': !exists(json, 'courrier') ? undefined : json['courrier'],
        'isEmergencyContact': !exists(json, 'isEmergencyContact') ? undefined : json['isEmergencyContact'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
    };
}

export function FcbApprenantRepresentantDtoToJSON(value?: FcbApprenantRepresentantDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idrepresentant': value.idrepresentant,
        'idapprenant': value.idapprenant,
        'lienParente': value.lienParente,
        'courrier': value.courrier,
        'isEmergencyContact': value.isEmergencyContact,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
    };
}

