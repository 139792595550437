import { Colors, Icon, Intent, Tooltip } from "@blueprintjs/core";
import { useSet } from "ahooks";
import { IDataTableColumn } from "nsitools-react";
import * as React from "react";
import styled, { css } from "styled-components";

import { AddButton, CircleColumn, ColumnMask, CustomBulletList, SearchTablePage, SelectButton } from "../..";
import { SiegeSocialSelectorApi, SiegeSocialSelectorGridDto, SiegeSocialSelectorSearch } from "../../../api";
import { ERoutes } from "../../../AppRouter";
import { useEventsContext } from "../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTabMessage, useTheme, useTl } from "../../../hooks";
import { ETLCodes } from "../../../locales";
import { Guid } from "../../../utils/guid";

const Container = styled.div`
  margin: 1rem 1rem 0 1rem;
`;

const LegendHeaderContainer = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

export interface ISiegeSocialSelectorProps {
  excludedSiegeSocialIds?: number[];
  onSiegeSocialSelected: (selectedSiegeSocialIds?: number[]) => void;
  rightElement?: any;
  allowMultipleSelection?: boolean;
  preSelectedIds?: number[];
  canAdd?: boolean;
}

export const SiegeSocialSelector: React.FunctionComponent<ISiegeSocialSelectorProps> = ({
  excludedSiegeSocialIds = [],
  onSiegeSocialSelected,
  rightElement,
  allowMultipleSelection = false,
  preSelectedIds = [],
  canAdd = true
}) => {
  const { t } = useTl();
  const { theme } = useTheme();
  const [selectedSiegeSocialIds, { add: addSiegeSocialId, remove: removeSiegeSocialId }] = useSet<number>(
    preSelectedIds
  );
  const api = useApiService(SiegeSocialSelectorApi);

  const apiFactory = useAbortableApiServiceFactory(SiegeSocialSelectorApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFunction = React.useCallback(
    (sObj?: SiegeSocialSelectorSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.siegeSocialSelectorBaseSearch({
        SiegeSocialSelectorSearch: {
          ...sObj,
          selectedSiegeSocialIds: Array.from(selectedSiegeSocialIds),
          excludedSiegeSocialIds
        }
      });
    },
    [apiFactory, excludedSiegeSocialIds, selectedSiegeSocialIds]
  );

  const selectSiegeSocial = React.useCallback(
    (id: number) => {
      if (allowMultipleSelection) {
        selectedSiegeSocialIds.has(id) ? removeSiegeSocialId(id) : addSiegeSocialId(id);
      } else {
        onSiegeSocialSelected([id]);
      }
    },
    [addSiegeSocialId, allowMultipleSelection, onSiegeSocialSelected, removeSiegeSocialId, selectedSiegeSocialIds]
  );

  const onRowClick = React.useCallback(
    (item: SiegeSocialSelectorGridDto) => {
      selectSiegeSocial(item.idsiegeSocial);
    },
    [selectSiegeSocial]
  );

  const identifier = React.useMemo(() => Guid.newGuid(), []);
  const { messageValue, clearMessageValue } = useTabMessage(identifier);
  const add = React.useCallback(() => {
    window.open(`#${ERoutes.siegeSocial}/0/detail/edit?cfs=${identifier}`, "_blank");
  }, [identifier]);

  const { dispatchEvent } = useEventsContext();
  React.useEffect(() => {
    if (messageValue) {
      dispatchEvent("SEARCH_TABLE_REFRESH");
      selectSiegeSocial(messageValue);
      clearMessageValue();
    }
  }, [clearMessageValue, dispatchEvent, messageValue, selectSiegeSocial]);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        autoFitContent: true,
        alignment: "center",
        header: () => (
          <LegendHeaderContainer>
            <span>{t(ETLCodes.A)}</span>
            <Tooltip
              content={
                <CustomBulletList
                  title={t(ETLCodes.AccueilApprenant)}
                  items={[
                    {
                      text: t(ETLCodes.SusceptibledAccueillirUnApprenant),
                      color: theme.sucessColor
                    },
                    {
                      text: t(ETLCodes.PasSusceptibledAccueillirUnApprenant),
                      color: theme.dangerColor
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip>
          </LegendHeaderContainer>
        ),
        fieldName: "formationCanHaveApprenants",
        render: (row: SiegeSocialSelectorGridDto) => (
          <CircleColumn color={row?.formationCanHaveApprenants ? theme.sucessColor : theme.dangerColor} />
        )
      },
      {
        autoFitContent: true,
        header: () => t(ETLCodes.C),
        fieldName: "nbContrats"
      },
      {
        header: () => t(ETLCodes.DenominationSociale),
        fieldName: "denominationSociale"
      },
      {
        header: () => t(ETLCodes.Telephone),
        fieldName: "telephone"
      },
      {
        header: () => t(ETLCodes.Gsm),
        fieldName: "gsm"
      },
      {
        header: () => t(ETLCodes.Patron),
        fieldName: "chefEntreprisePrincipal"
      },
      {
        header: () => t(ETLCodes.Email),
        fieldName: "email"
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      },
      {
        header: () => t(ETLCodes.NumeroBce),
        fieldName: "numeroBce",
        render: (row: SiegeSocialSelectorGridDto) => (
          <ColumnMask
            value={row.numeroBce}
            cleaveOptions={{
              blocks: [4, 3, 3],
              delimiter: "."
            }}
          />
        )
      }
    ],
    [t, theme.dangerColor, theme.sucessColor]
  );

  const customizeRowStyleCallback = React.useCallback(
    (item: SiegeSocialSelectorGridDto) => {
      const isSelected = selectedSiegeSocialIds.has(item.idsiegeSocial);

      return css`
        & * {
          color: ${isSelected ? Colors.WHITE : Colors.BLACK} !important;
        }

        & & > td {
          background-color: ${isSelected ? theme.primaryColor : "initial"} !important;
        }

        background-color: ${isSelected ? theme.primaryColor : "transparent"} !important;
      `;
    },
    [selectedSiegeSocialIds, theme.primaryColor]
  );

  const onSiegeSocialSelect = React.useCallback(() => {
    onSiegeSocialSelected(Array.from(selectedSiegeSocialIds));
  }, [onSiegeSocialSelected, selectedSiegeSocialIds]);

  const finalRightElements = React.useMemo(() => {
    return allowMultipleSelection ? (
      <>
        <SelectButton onClick={onSiegeSocialSelect}></SelectButton>
        {rightElement}
      </>
    ) : (
      rightElement
    );
  }, [allowMultipleSelection, onSiegeSocialSelect, rightElement]);

  const getCriteria = React.useCallback(() => api.siegeSocialSelectorGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <Container>
      <SearchTablePage
        withCard={false}
        noTitle
        pageSize={5}
        availablePageSizes={[5]}
        getCriteriasFunction={getCriteria}
        searchFunction={searchFunction}
        onAbort={onAbort}
        columns={columns}
        sortKeys={{ denominationSociale: "ASC" }}
        rightElement={
          <>
            {finalRightElements}
            {canAdd && (
              <AddButton onClick={add} text={t(ETLCodes.General_Add)} intent={Intent.PRIMARY} minimal={false} />
            )}
          </>
        }
        keyFieldName="idsiegeSocial"
        onRowClick={onRowClick}
        customizeRowStyle={customizeRowStyleCallback}
      />
    </Container>
  );
};
