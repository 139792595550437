/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DispenseSearch,
  FcbDispenseDetailDto,
  FcbDispenseGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    DispenseSearchFromJSON,
    DispenseSearchToJSON,
    FcbDispenseDetailDtoFromJSON,
    FcbDispenseDetailDtoToJSON,
    FcbDispenseGridDtoPaginatedResultsFromJSON,
    FcbDispenseGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface DispenseBaseSearchRequest {
    DispenseSearch?: DispenseSearch;
}

export interface DispenseDeleteRequest {
    id?: number;
}

export interface DispenseGetRequest {
    id?: number;
}

export interface DispenseGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface DispenseGetSelectItemsRequest {
    searchField: string;
}

export interface DispenseSaveRequest {
    FcbDispenseDetailDto?: FcbDispenseDetailDto;
}

/**
 * 
 */
export class DispenseApi extends runtime.BaseAPI {

    /**
     */
    async dispenseBaseSearchRaw(requestParameters: DispenseBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbDispenseGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dispense/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DispenseSearchToJSON(requestParameters.DispenseSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbDispenseGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async dispenseBaseSearch(requestParameters: DispenseBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbDispenseGridDtoPaginatedResults> {
        const response = await this.dispenseBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dispenseDeleteRaw(requestParameters: DispenseDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dispense`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async dispenseDelete(requestParameters: DispenseDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.dispenseDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dispenseGetRaw(requestParameters: DispenseGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbDispenseDetailDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dispense`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbDispenseDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async dispenseGet(requestParameters: DispenseGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbDispenseDetailDto> {
        const response = await this.dispenseGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dispenseGetSearchCriteriasRaw(requestParameters: DispenseGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dispense/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async dispenseGetSearchCriterias(requestParameters: DispenseGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.dispenseGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dispenseGetSelectItemsRaw(requestParameters: DispenseGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling dispenseGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dispense/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async dispenseGetSelectItems(requestParameters: DispenseGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.dispenseGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dispenseSaveRaw(requestParameters: DispenseSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbDispenseDetailDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dispense`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbDispenseDetailDtoToJSON(requestParameters.FcbDispenseDetailDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbDispenseDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async dispenseSave(requestParameters: DispenseSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbDispenseDetailDto> {
        const response = await this.dispenseSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
