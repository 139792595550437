/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SuiviApprenantFichierGridDto
 */
export interface SuiviApprenantFichierGridDto {
    /**
     * 
     * @type {number}
     * @memberof SuiviApprenantFichierGridDto
     */
    idsuiviApprenantFichier?: number;
    /**
     * 
     * @type {number}
     * @memberof SuiviApprenantFichierGridDto
     */
    idtypeSuiviFichier?: number;
    /**
     * 
     * @type {string}
     * @memberof SuiviApprenantFichierGridDto
     */
    typeSuiviFichier?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviApprenantFichierGridDto
     */
    idfichier?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviApprenantFichierGridDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviApprenantFichierGridDto
     */
    uploadUser?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviApprenantFichierGridDto
     */
    creationUserName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SuiviApprenantFichierGridDto
     */
    uploadDate?: Date | null;
}

/**
 * Check if a given object implements the SuiviApprenantFichierGridDto interface.
 */
export function instanceOfSuiviApprenantFichierGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SuiviApprenantFichierGridDtoFromJSON(json: any): SuiviApprenantFichierGridDto {
    return SuiviApprenantFichierGridDtoFromJSONTyped(json, false);
}

export function SuiviApprenantFichierGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuiviApprenantFichierGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsuiviApprenantFichier': !exists(json, 'idsuiviApprenantFichier') ? undefined : json['idsuiviApprenantFichier'],
        'idtypeSuiviFichier': !exists(json, 'idtypeSuiviFichier') ? undefined : json['idtypeSuiviFichier'],
        'typeSuiviFichier': !exists(json, 'typeSuiviFichier') ? undefined : json['typeSuiviFichier'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'uploadUser': !exists(json, 'uploadUser') ? undefined : json['uploadUser'],
        'creationUserName': !exists(json, 'creationUserName') ? undefined : json['creationUserName'],
        'uploadDate': !exists(json, 'uploadDate') ? undefined : (json['uploadDate'] === null ? null : new Date(json['uploadDate'])),
    };
}

export function SuiviApprenantFichierGridDtoToJSON(value?: SuiviApprenantFichierGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsuiviApprenantFichier': value.idsuiviApprenantFichier,
        'idtypeSuiviFichier': value.idtypeSuiviFichier,
        'typeSuiviFichier': value.typeSuiviFichier,
        'idfichier': value.idfichier,
        'fileName': value.fileName,
        'uploadUser': value.uploadUser,
        'creationUserName': value.creationUserName,
        'uploadDate': value.uploadDate === undefined ? undefined : (value.uploadDate === null ? null : value.uploadDate.toISOString()),
    };
}

