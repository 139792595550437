/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbProfessionFormateurDto,
  FcbProfessionFormateurDtoPaginatedResults,
  FilterCriteriaInfo,
  ProfessionFormateurSearch,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    FcbProfessionFormateurDtoFromJSON,
    FcbProfessionFormateurDtoToJSON,
    FcbProfessionFormateurDtoPaginatedResultsFromJSON,
    FcbProfessionFormateurDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ProfessionFormateurSearchFromJSON,
    ProfessionFormateurSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface ProfessionFormateurBaseSearchRequest {
    ProfessionFormateurSearch?: ProfessionFormateurSearch;
}

export interface ProfessionFormateurDeleteRequest {
    id?: number;
}

export interface ProfessionFormateurGetRequest {
    id?: number;
}

export interface ProfessionFormateurGetDisplayNameRequest {
    id?: number;
}

export interface ProfessionFormateurGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ProfessionFormateurGetSelectItemsRequest {
    searchField: string;
}

export interface ProfessionFormateurLibelleProfessionFormateurExistsRequest {
    libelle?: string;
}

export interface ProfessionFormateurSaveRequest {
    FcbProfessionFormateurDto?: FcbProfessionFormateurDto;
}

/**
 * 
 */
export class ProfessionFormateurApi extends runtime.BaseAPI {

    /**
     */
    async professionFormateurBaseSearchRaw(requestParameters: ProfessionFormateurBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbProfessionFormateurDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ProfessionFormateur/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProfessionFormateurSearchToJSON(requestParameters.ProfessionFormateurSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbProfessionFormateurDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async professionFormateurBaseSearch(requestParameters: ProfessionFormateurBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbProfessionFormateurDtoPaginatedResults> {
        const response = await this.professionFormateurBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async professionFormateurDeleteRaw(requestParameters: ProfessionFormateurDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ProfessionFormateur`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async professionFormateurDelete(requestParameters: ProfessionFormateurDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.professionFormateurDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async professionFormateurGetRaw(requestParameters: ProfessionFormateurGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbProfessionFormateurDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ProfessionFormateur`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbProfessionFormateurDtoFromJSON(jsonValue));
    }

    /**
     */
    async professionFormateurGet(requestParameters: ProfessionFormateurGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbProfessionFormateurDto> {
        const response = await this.professionFormateurGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async professionFormateurGetDisplayNameRaw(requestParameters: ProfessionFormateurGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ProfessionFormateur/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async professionFormateurGetDisplayName(requestParameters: ProfessionFormateurGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.professionFormateurGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async professionFormateurGetSearchCriteriasRaw(requestParameters: ProfessionFormateurGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ProfessionFormateur/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async professionFormateurGetSearchCriterias(requestParameters: ProfessionFormateurGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.professionFormateurGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async professionFormateurGetSelectItemsRaw(requestParameters: ProfessionFormateurGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling professionFormateurGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ProfessionFormateur/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async professionFormateurGetSelectItems(requestParameters: ProfessionFormateurGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.professionFormateurGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async professionFormateurLibelleProfessionFormateurExistsRaw(requestParameters: ProfessionFormateurLibelleProfessionFormateurExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.libelle !== undefined) {
            queryParameters['libelle'] = requestParameters.libelle;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ProfessionFormateur/libelleExists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async professionFormateurLibelleProfessionFormateurExists(requestParameters: ProfessionFormateurLibelleProfessionFormateurExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.professionFormateurLibelleProfessionFormateurExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async professionFormateurSaveRaw(requestParameters: ProfessionFormateurSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbProfessionFormateurDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ProfessionFormateur`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbProfessionFormateurDtoToJSON(requestParameters.FcbProfessionFormateurDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbProfessionFormateurDtoFromJSON(jsonValue));
    }

    /**
     */
    async professionFormateurSave(requestParameters: ProfessionFormateurSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbProfessionFormateurDto> {
        const response = await this.professionFormateurSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
