/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdresseDto } from './AdresseDto';
import {
    AdresseDtoFromJSON,
    AdresseDtoFromJSONTyped,
    AdresseDtoToJSON,
} from './AdresseDto';

/**
 * 
 * @export
 * @interface ApprenantSelectorGridDto
 */
export interface ApprenantSelectorGridDto {
    /**
     * 
     * @type {number}
     * @memberof ApprenantSelectorGridDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof ApprenantSelectorGridDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {string}
     * @memberof ApprenantSelectorGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantSelectorGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {AdresseDto}
     * @memberof ApprenantSelectorGridDto
     */
    adresseApprenant?: AdresseDto;
    /**
     * 
     * @type {string}
     * @memberof ApprenantSelectorGridDto
     */
    readonly adresse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantSelectorGridDto
     */
    readonly codePostal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantSelectorGridDto
     */
    readonly localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantSelectorGridDto
     */
    nationalite?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ApprenantSelectorGridDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantSelectorGridDto
     */
    registreNational?: string | null;
}

/**
 * Check if a given object implements the ApprenantSelectorGridDto interface.
 */
export function instanceOfApprenantSelectorGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApprenantSelectorGridDtoFromJSON(json: any): ApprenantSelectorGridDto {
    return ApprenantSelectorGridDtoFromJSONTyped(json, false);
}

export function ApprenantSelectorGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApprenantSelectorGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'adresseApprenant': !exists(json, 'adresseApprenant') ? undefined : AdresseDtoFromJSON(json['adresseApprenant']),
        'adresse': !exists(json, 'adresse') ? undefined : json['adresse'],
        'codePostal': !exists(json, 'codePostal') ? undefined : json['codePostal'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'nationalite': !exists(json, 'nationalite') ? undefined : json['nationalite'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'registreNational': !exists(json, 'registreNational') ? undefined : json['registreNational'],
    };
}

export function ApprenantSelectorGridDtoToJSON(value?: ApprenantSelectorGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idapprenant': value.idapprenant,
        'idpersonne': value.idpersonne,
        'nom': value.nom,
        'prenom': value.prenom,
        'adresseApprenant': AdresseDtoToJSON(value.adresseApprenant),
        'nationalite': value.nationalite,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'registreNational': value.registreNational,
    };
}

