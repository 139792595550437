import { Icon, Spinner, Tooltip } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";

import { ToolTipContent } from ".";
import { ApprenantApi, FcbApprenantDto } from "../../api";
import { useApiService } from "../../hooks";

export interface IApprenantTooltipProps {
  idApprenant: number;
  showId?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  & > * + * {
    margin-left: 0.5rem;
  }
`;

export const ApprenantTooltip: React.FunctionComponent<IApprenantTooltipProps> = ({ idApprenant, showId = true }) => {
  const api = useApiService(ApprenantApi);

  const [request, setRequest] = React.useState(false);
  const [apprenant, setApprenant] = React.useState<FcbApprenantDto>(null);
  React.useEffect(() => {
    async function getApprenant() {
      setApprenant(await api.apprenantGet({ id: idApprenant, picture: true }));
    }
    if (request) {
      getApprenant();
      setRequest(false);
    }
  }, [api, idApprenant, request]);

  return (
    <Container>
      <Tooltip
        content={
          !apprenant ? (
            <Spinner />
          ) : (
            <ToolTipContent
              isLight={false}
              firstName={apprenant.prenom}
              lastName={apprenant.nom}
              birthDate={apprenant.dateNaissance}
              sex={apprenant.codesexe}
              nationality={apprenant.nationalite}
              photo={apprenant.photo}
              interne={apprenant.interne}
            />
          )
        }
        onOpening={() => {
          setRequest(true);
        }}
      >
        <Icon icon="info-sign" />
      </Tooltip>
      {showId && <span>{idApprenant}</span>}
    </Container>
  );
};
