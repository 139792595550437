/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbContratFormateurDto
 */
export interface FcbContratFormateurDto {
    /**
     * 
     * @type {number}
     * @memberof FcbContratFormateurDto
     */
    idcontratFormateur?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbContratFormateurDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbContratFormateurDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbContratFormateurDto
     */
    idgenre?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbContratFormateurDto
     */
    nbheureMin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbContratFormateurDto
     */
    nbheureMax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbContratFormateurDto
     */
    nbheureJury?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbContratFormateurDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbContratFormateurDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbContratFormateurDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbContratFormateurDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbContratFormateurDto
     */
    idcontratFormateurGuid?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbContratFormateurDto
     */
    type?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbContratFormateurDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbContratFormateurDto interface.
 */
export function instanceOfFcbContratFormateurDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbContratFormateurDtoFromJSON(json: any): FcbContratFormateurDto {
    return FcbContratFormateurDtoFromJSONTyped(json, false);
}

export function FcbContratFormateurDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbContratFormateurDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontratFormateur': !exists(json, 'idcontratFormateur') ? undefined : json['idcontratFormateur'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'idgenre': !exists(json, 'idgenre') ? undefined : json['idgenre'],
        'nbheureMin': !exists(json, 'nbheureMin') ? undefined : json['nbheureMin'],
        'nbheureMax': !exists(json, 'nbheureMax') ? undefined : json['nbheureMax'],
        'nbheureJury': !exists(json, 'nbheureJury') ? undefined : json['nbheureJury'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'idcontratFormateurGuid': !exists(json, 'idcontratFormateurGuid') ? undefined : json['idcontratFormateurGuid'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbContratFormateurDtoToJSON(value?: FcbContratFormateurDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontratFormateur': value.idcontratFormateur,
        'code': value.code,
        'libelle': value.libelle,
        'idgenre': value.idgenre,
        'nbheureMin': value.nbheureMin,
        'nbheureMax': value.nbheureMax,
        'nbheureJury': value.nbheureJury,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'creationUser': value.creationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'idcontratFormateurGuid': value.idcontratFormateurGuid,
        'type': value.type,
        'actif': value.actif,
    };
}

