import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { ERoutes } from "../../../../../AppRouter";

import { ApprenantSuiviDetailPage } from "./ApprenantSuiviDetailPage";
import { SuiviApprenantDetailPage } from "./SuiviApprenantDetailPage";
import { SuiviApprenantSearchPage } from "./SuiviApprenantSearchPage";

export interface SuiviApprenantProps {
  apprenantId: number;
}

export const SuiviApprenant: React.FunctionComponent<SuiviApprenantProps> = props => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} exact>
        <SuiviApprenantSearchPage baseRoute={ERoutes.apprenant} />
      </Route>
      <Route path={`${match.path}/:suiviId/:state/hope`} component={SuiviApprenantDetailPage} exact />
      <Route path={`${match.path}/:idSuivi/:state/walter`} exact>
        <ApprenantSuiviDetailPage baseRoute={ERoutes.apprenant} />
      </Route>
    </Switch>
  );
};
