/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { DateSearch } from './DateSearch';
import {
    DateSearchFromJSON,
    DateSearchFromJSONTyped,
    DateSearchToJSON,
} from './DateSearch';
import type { TextSearch } from './TextSearch';
import {
    TextSearchFromJSON,
    TextSearchFromJSONTyped,
    TextSearchToJSON,
} from './TextSearch';
import type { UnaccentTextSearch } from './UnaccentTextSearch';
import {
    UnaccentTextSearchFromJSON,
    UnaccentTextSearchFromJSONTyped,
    UnaccentTextSearchToJSON,
} from './UnaccentTextSearch';

/**
 * 
 * @export
 * @interface DoublonsPersonneSearchDto
 */
export interface DoublonsPersonneSearchDto {
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof DoublonsPersonneSearchDto
     */
    nom?: UnaccentTextSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof DoublonsPersonneSearchDto
     */
    prenom?: UnaccentTextSearch;
    /**
     * 
     * @type {DateSearch}
     * @memberof DoublonsPersonneSearchDto
     */
    dateNaissance?: DateSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof DoublonsPersonneSearchDto
     */
    registreNational?: TextSearch;
    /**
     * 
     * @type {Array<string>}
     * @memberof DoublonsPersonneSearchDto
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof DoublonsPersonneSearchDto
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof DoublonsPersonneSearchDto
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DoublonsPersonneSearchDto
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof DoublonsPersonneSearchDto
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof DoublonsPersonneSearchDto
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the DoublonsPersonneSearchDto interface.
 */
export function instanceOfDoublonsPersonneSearchDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DoublonsPersonneSearchDtoFromJSON(json: any): DoublonsPersonneSearchDto {
    return DoublonsPersonneSearchDtoFromJSONTyped(json, false);
}

export function DoublonsPersonneSearchDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DoublonsPersonneSearchDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nom': !exists(json, 'nom') ? undefined : UnaccentTextSearchFromJSON(json['nom']),
        'prenom': !exists(json, 'prenom') ? undefined : UnaccentTextSearchFromJSON(json['prenom']),
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : DateSearchFromJSON(json['dateNaissance']),
        'registreNational': !exists(json, 'registreNational') ? undefined : TextSearchFromJSON(json['registreNational']),
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function DoublonsPersonneSearchDtoToJSON(value?: DoublonsPersonneSearchDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nom': UnaccentTextSearchToJSON(value.nom),
        'prenom': UnaccentTextSearchToJSON(value.prenom),
        'dateNaissance': DateSearchToJSON(value.dateNaissance),
        'registreNational': TextSearchToJSON(value.registreNational),
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

