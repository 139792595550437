/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbForfaitAuditeurDto,
  FcbForfaitAuditeurDtoPaginatedResults,
  FilterCriteriaInfo,
  ForfaitAuditeurSearch,
  SelectItem,
} from '../models/index';
import {
    FcbForfaitAuditeurDtoFromJSON,
    FcbForfaitAuditeurDtoToJSON,
    FcbForfaitAuditeurDtoPaginatedResultsFromJSON,
    FcbForfaitAuditeurDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ForfaitAuditeurSearchFromJSON,
    ForfaitAuditeurSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface ForfaitAuditeurBaseSearchRequest {
    ForfaitAuditeurSearch?: ForfaitAuditeurSearch;
}

export interface ForfaitAuditeurDeleteRequest {
    id?: number;
}

export interface ForfaitAuditeurGetRequest {
    id?: number;
}

export interface ForfaitAuditeurGetDisplayNameRequest {
    id?: number;
}

export interface ForfaitAuditeurGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ForfaitAuditeurGetSelectItemsRequest {
    searchField: string;
}

export interface ForfaitAuditeurSaveRequest {
    FcbForfaitAuditeurDto?: FcbForfaitAuditeurDto;
}

/**
 * 
 */
export class ForfaitAuditeurApi extends runtime.BaseAPI {

    /**
     */
    async forfaitAuditeurBaseSearchRaw(requestParameters: ForfaitAuditeurBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbForfaitAuditeurDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ForfaitAuditeur/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForfaitAuditeurSearchToJSON(requestParameters.ForfaitAuditeurSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbForfaitAuditeurDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async forfaitAuditeurBaseSearch(requestParameters: ForfaitAuditeurBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbForfaitAuditeurDtoPaginatedResults> {
        const response = await this.forfaitAuditeurBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async forfaitAuditeurDeleteRaw(requestParameters: ForfaitAuditeurDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ForfaitAuditeur`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async forfaitAuditeurDelete(requestParameters: ForfaitAuditeurDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.forfaitAuditeurDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async forfaitAuditeurGetRaw(requestParameters: ForfaitAuditeurGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbForfaitAuditeurDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ForfaitAuditeur`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbForfaitAuditeurDtoFromJSON(jsonValue));
    }

    /**
     */
    async forfaitAuditeurGet(requestParameters: ForfaitAuditeurGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbForfaitAuditeurDto> {
        const response = await this.forfaitAuditeurGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async forfaitAuditeurGetDisplayNameRaw(requestParameters: ForfaitAuditeurGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ForfaitAuditeur/displayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async forfaitAuditeurGetDisplayName(requestParameters: ForfaitAuditeurGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.forfaitAuditeurGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async forfaitAuditeurGetSearchCriteriasRaw(requestParameters: ForfaitAuditeurGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ForfaitAuditeur/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async forfaitAuditeurGetSearchCriterias(requestParameters: ForfaitAuditeurGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.forfaitAuditeurGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async forfaitAuditeurGetSelectItemsRaw(requestParameters: ForfaitAuditeurGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling forfaitAuditeurGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ForfaitAuditeur/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async forfaitAuditeurGetSelectItems(requestParameters: ForfaitAuditeurGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.forfaitAuditeurGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async forfaitAuditeurSaveRaw(requestParameters: ForfaitAuditeurSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbForfaitAuditeurDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ForfaitAuditeur`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbForfaitAuditeurDtoToJSON(requestParameters.FcbForfaitAuditeurDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbForfaitAuditeurDtoFromJSON(jsonValue));
    }

    /**
     */
    async forfaitAuditeurSave(requestParameters: ForfaitAuditeurSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbForfaitAuditeurDto> {
        const response = await this.forfaitAuditeurSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
