import { IDataTableColumn } from "nsitools-react";
import * as React from "react";
import { useParams, useHistory } from "react-router";
import styled, { css } from "styled-components";

import { ClasseApprenantApi, ClasseApprenantSearch, FcbClasseApprenantGridDto } from "../../../../../api";
import { SearchTablePage, SmsButton, ApprenantTooltip, ViewButton, EditButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { useAbortableApiServiceFactory, useApiService } from "../../../../../hooks/useApiService";
import { ETLCodes } from "../../../../../locales";
import { ERoutes } from "../../../../../AppRouter";

const Container = styled.div`
  display: flex;
`;

export interface IClasseApprenantListProps {}

export const ClasseApprenantList: React.FunctionComponent<IClasseApprenantListProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ClasseApprenantApi);
  const { id } = useParams<{ id: string }>();

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbClasseApprenantGridDto) => (
          <Container>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.apprenant}/${row.idapprenant}/signaletique/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.apprenant}/${row.idapprenant}/signaletique/edit`)}
            />
          </Container>
        )
      },
      {
        header: () => t(ETLCodes.Identifiant),
        computed: true,
        fieldName: "info",
        customizeCellStyle: () =>
          css`
            min-width: 110px;
          `,
        render: (value: FcbClasseApprenantGridDto) => <ApprenantTooltip idApprenant={value.idapprenant} />,
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.DateNaissance),
        fieldName: "dateNaissance"
      },
      {
        header: () => t(ETLCodes.Inscription),
        fieldName: "codeInscription"
      },
      {
        header: () => t(ETLCodes.Statut),
        fieldName: "statut"
      },
      {
        header: () => t(ETLCodes.Adresse),
        fieldName: "adresse",
        customizeCellStyle: () =>
          css`
            white-space: pre;
          `
      },
      {
        header: () => t(ETLCodes.Gsm),
        fieldName: "gsm",
        render: (value: FcbClasseApprenantGridDto) => <SmsButton phone={value.gsm} />
      },
      {
        header: () => t(ETLCodes.Tel),
        fieldName: "telephone"
      }
    ],
    [history, t]
  );

  const getSearchCriterias = React.useCallback(
    () => api.classeApprenantGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  const apiFactory = useAbortableApiServiceFactory(ClasseApprenantApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (nextSearch?: ClasseApprenantSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.classeApprenantBaseSearch({ ClasseApprenantSearch: { ...nextSearch, idClasse: +id } });
    },
    [apiFactory, id]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      columns={columns}
      withCard={false}
      getCriteriasFunction={getSearchCriterias}
      searchFunction={search}
      onAbort={onAbort}
      sortKeys={{ nom: "ASC" }}
    />
  );
};
