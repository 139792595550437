import * as React from "react";
import { InputGroup } from "@blueprintjs/core";

export interface IClasseMatiereTextEditorProps {
  initialTextValue: string;
  onValueChanged: (value: string) => void;
  textEditorHeight?: string | number;
  maxLength?: number;
}

export const ClasseMatiereTextEditor: React.FunctionComponent<IClasseMatiereTextEditorProps> = ({
  initialTextValue,
  onValueChanged,
  textEditorHeight = "auto",
  maxLength
}) => {
  const [currentValue, setCurrentValue] = React.useState<string>();
  const [editedValue, setEditedValue] = React.useState<string>();
  const [focused, setFocused] = React.useState(false);

  React.useEffect(() => {
    setCurrentValue(initialTextValue);
    setEditedValue(initialTextValue);
  }, [initialTextValue]);

  const onBlur = React.useCallback(
    async (e: any) => {
      if (editedValue !== currentValue) {
        setCurrentValue(editedValue);
        onValueChanged(editedValue);
      }
      setFocused(false);
    },
    [currentValue, editedValue, onValueChanged]
  );

  const computeFocus = React.useCallback(() => {
    if (!focused) {
      setFocused(true);
    }
  }, [focused]);

  return (
    <>
      {focused ? (
        <InputGroup
          autoFocus
          style={{ borderRadius: "none" }}
          value={editedValue}
          onChange={e => setEditedValue(e.target.value)}
          onBlur={onBlur}
          onClick={() => computeFocus()}
          maxLength={maxLength}
        ></InputGroup>
      ) : (
        <div
          style={{
            marginLeft: 9,
            marginTop: 4,
            height: textEditorHeight
          }}
          onClick={() => computeFocus()}
        >
          {editedValue}
        </div>
      )}
    </>
  );
};
