import * as React from "react";
import { useQuery } from "react-query";
import { useApiService } from ".";
import { PersonneApi } from "../api";

export const useFetchPicture = (id: number, deps?: any[]): [any, boolean, () => void] => {
  const api = useApiService(PersonneApi);
  const fetchPhoto = React.useCallback(() => api.personneGetPhoto({ id: id }), [api, id]);
  const { data, isFetching: loading, refetch } = useQuery(["personne-pic", id, ...deps], fetchPhoto);

  const finalData = React.useMemo(() => {
    return data;
  }, [data]);

  return [finalData, loading, refetch];
};
