import { ButtonContainer } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";

import { ETextSearchType, FcbMinervalGridDto, MinervalApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { SearchTablePage, ViewButton } from "../../../../../components";
import { useGlobalData } from "../../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

interface IMinervalSearchTableProps {
  apprenantId: number;
}

export const MinervalSearchTable: React.FunctionComponent<IMinervalSearchTableProps> = ({ apprenantId }) => {
  const { t } = useTl();
  const api = useApiService(MinervalApi);
  const history = useHistory();
  const { currentAnneeScolaire } = useGlobalData();

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbMinervalGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() =>
                history.push(`${ERoutes.apprenant}/${apprenantId}/inscription/${row.idInscription}/detail/edit`)
              }
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "anneeScolaire",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Inscription),
        fieldName: "codeInscription"
      },
      {
        header: () => t(ETLCodes.CodeClasse),
        fieldName: "codeClasse"
      },
      {
        header: () => t(ETLCodes.Classe),
        fieldName: "nomClasse"
      },
      {
        header: () => t(ETLCodes.Minerval),
        fieldName: "minerval"
      }
    ],
    [apprenantId, history, t]
  );

  const apiFactory = useAbortableApiServiceFactory(MinervalApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    sObj => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      sObj.idapprenant = apprenantId;
      return abortableApi.minervalBaseSearch({ MinervalSearch: sObj });
    },
    [apiFactory, apprenantId]
  );

  const getCriteriasFn = React.useCallback(() => {
    return api.minervalGetSearchCriterias({ includeListsValues: true });
  }, [api]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    apprenantId > 0 && (
      <SearchTablePage
        columns={columns}
        getCriteriasFunction={getCriteriasFn}
        searchFunction={searchFn}
        onAbort={onAbort}
        sortKeys={{ anneeScolaire: "DESC" }}
        title={ETLCodes.Minerval}
        defaultCriterias={[
          {
            criteria: "AnneeScolaire",
            searchMode: ETextSearchType.Equals,
            value: currentAnneeScolaire?.idanneeScolaire
          }
        ]}
        withCard={false}
      />
    )
  );
};
