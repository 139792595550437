import { Callout } from "@blueprintjs/core";
import { format } from "date-fns";
import { FieldSet } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { CarouselComparer, CO_FINAL_KEY, DoublonsMultipleDataCard, useDoublonsStateContext } from "..";
import { ContactDoublonEditDto, ContactDoublonLieuFormationDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BooleanColumn, ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

const Container = styled.div`
  height: 70vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface IDoublonContactLieuxFormationProps {}

export const DoublonContactLieuxFormation: React.FunctionComponent<IDoublonContactLieuxFormationProps> = props => {
  const { t } = useTl();
  const { data, getSelectedIndex } = useDoublonsStateContext();

  const selectedIndex = React.useMemo(() => getSelectedIndex(`${CO_FINAL_KEY}.idcontact`) ?? 0, [getSelectedIndex]);

  const contactContainer = React.useCallback(
    (index: number, nb: number) => {
      const contact: ContactDoublonEditDto = data?.contacts[index];
      if (!contact) return;

      return (
        <FieldSet
          key={contact.idcontact}
          title={
            <ViewButton
              text={t(ETLCodes.ContactNb, { nb: nb, id: contact.idcontact })}
              onClick={() => window.open(`#${ERoutes.contacts}/${contact.idcontact}/detail/view`, "_blank")}
            />
          }
          titlePosition="center"
        >
          <DoublonsMultipleDataCard
            labelPredicate={(l: ContactDoublonLieuFormationDto) => l.nom}
            fieldPath={`contacts[${index}].lieuxFormation`}
            interactive={() => false}
            forceSelected={() => false}
            finalEntityKey={CO_FINAL_KEY}
          >
            {(l: ContactDoublonLieuFormationDto) => (
              <InformationContainer key={l.idcontactLieu}>
                <div>
                  {t(ETLCodes.Enseigne)}: {l.enseigne}
                </div>
                <div>
                  {t(ETLCodes.Fonction)}: {l.fonction}
                </div>
                <div>
                  {t(ETLCodes.Telephone)}: {l.telephone?.numero}
                </div>
                <div>
                  {t(ETLCodes.Gsm)}: {l.gsm?.numero}
                </div>
                <div>
                  {t(ETLCodes.Email)}: {l.email?.adresseEmail}
                </div>
                <div>
                  {t(ETLCodes.Remarque)}: {l.remarqueLieu}
                </div>
                <div>
                  {t(ETLCodes.PersonneContactPrincipale)}: <BooleanColumn value={l.principal} />
                </div>
                <div>
                  {t(ETLCodes.SortieEntreprise)}: {l.sortieEntreprise && format(l.sortieEntreprise, "dd-MM-yyyy")}
                </div>
              </InformationContainer>
            )}
          </DoublonsMultipleDataCard>
        </FieldSet>
      );
    },
    [data?.contacts, t]
  );

  return (
    <Container>
      <Callout
        intent="warning"
        title={t(ETLCodes.DoublonContactLieuFormationCumules)}
        style={{ marginBottom: "1rem" }}
        icon="info-sign"
      />
      <CarouselComparer leftElement={contactContainer(selectedIndex, selectedIndex + 1)}>
        {data?.contacts?.length > 1 &&
          data?.contacts
            ?.filter((a, i) => i !== selectedIndex)
            ?.map((a, i) => contactContainer(i + (i >= selectedIndex ? 1 : 0), i + (i >= selectedIndex ? 2 : 1)))}
      </CarouselComparer>
    </Container>
  );
};
