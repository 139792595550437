/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbPlanAgrementDto,
  FcbRechercheAgrementLocalisationDtoPaginatedResults,
  FcbRecherchePlanAgrementClasseDtoPaginatedResults,
  FilterCriteriaInfo,
  PlanAgrementClasseSearch,
  RechercheAgrementLocalisationSearch,
  SelectItem,
} from '../models/index';
import {
    FcbPlanAgrementDtoFromJSON,
    FcbPlanAgrementDtoToJSON,
    FcbRechercheAgrementLocalisationDtoPaginatedResultsFromJSON,
    FcbRechercheAgrementLocalisationDtoPaginatedResultsToJSON,
    FcbRecherchePlanAgrementClasseDtoPaginatedResultsFromJSON,
    FcbRecherchePlanAgrementClasseDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    PlanAgrementClasseSearchFromJSON,
    PlanAgrementClasseSearchToJSON,
    RechercheAgrementLocalisationSearchFromJSON,
    RechercheAgrementLocalisationSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface AgrementLocalisationBaseSearchRequest {
    RechercheAgrementLocalisationSearch?: RechercheAgrementLocalisationSearch;
}

export interface AgrementLocalisationDeleteRequest {
    id?: number;
}

export interface AgrementLocalisationGenerateAgrementRequest {
    idPlan?: number;
}

export interface AgrementLocalisationGetRequest {
    id?: number;
}

export interface AgrementLocalisationGetDisplayNameRequest {
    id?: number;
}

export interface AgrementLocalisationGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface AgrementLocalisationGetSelectItemsRequest {
    searchField: string;
}

export interface AgrementLocalisationLockClasseRequest {
    idPlan?: number;
}

export interface AgrementLocalisationSaveRequest {
    FcbPlanAgrementDto?: FcbPlanAgrementDto;
}

export interface AgrementLocalisationSearchClassesRequest {
    PlanAgrementClasseSearch?: PlanAgrementClasseSearch;
}

export interface AgrementLocalisationToValidatePlanRequest {
    idPlan?: number;
}

export interface AgrementLocalisationUnlockClasseRequest {
    idPlan?: number;
}

export interface AgrementLocalisationValidatePlanRequest {
    idPlan?: number;
}

/**
 * 
 */
export class AgrementLocalisationApi extends runtime.BaseAPI {

    /**
     */
    async agrementLocalisationBaseSearchRaw(requestParameters: AgrementLocalisationBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRechercheAgrementLocalisationDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementLocalisation/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RechercheAgrementLocalisationSearchToJSON(requestParameters.RechercheAgrementLocalisationSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRechercheAgrementLocalisationDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async agrementLocalisationBaseSearch(requestParameters: AgrementLocalisationBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRechercheAgrementLocalisationDtoPaginatedResults> {
        const response = await this.agrementLocalisationBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementLocalisationDeleteRaw(requestParameters: AgrementLocalisationDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementLocalisation`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async agrementLocalisationDelete(requestParameters: AgrementLocalisationDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.agrementLocalisationDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementLocalisationGenerateAgrementRaw(requestParameters: AgrementLocalisationGenerateAgrementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.idPlan !== undefined) {
            queryParameters['idPlan'] = requestParameters.idPlan;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementLocalisation/GenerateAgrement`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async agrementLocalisationGenerateAgrement(requestParameters: AgrementLocalisationGenerateAgrementRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.agrementLocalisationGenerateAgrementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementLocalisationGetRaw(requestParameters: AgrementLocalisationGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbPlanAgrementDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementLocalisation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbPlanAgrementDtoFromJSON(jsonValue));
    }

    /**
     */
    async agrementLocalisationGet(requestParameters: AgrementLocalisationGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbPlanAgrementDto> {
        const response = await this.agrementLocalisationGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementLocalisationGetDisplayNameRaw(requestParameters: AgrementLocalisationGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementLocalisation/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async agrementLocalisationGetDisplayName(requestParameters: AgrementLocalisationGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.agrementLocalisationGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementLocalisationGetSearchCriteriasRaw(requestParameters: AgrementLocalisationGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementLocalisation/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async agrementLocalisationGetSearchCriterias(requestParameters: AgrementLocalisationGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.agrementLocalisationGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementLocalisationGetSelectItemsRaw(requestParameters: AgrementLocalisationGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling agrementLocalisationGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementLocalisation/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async agrementLocalisationGetSelectItems(requestParameters: AgrementLocalisationGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.agrementLocalisationGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementLocalisationLockClasseRaw(requestParameters: AgrementLocalisationLockClasseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.idPlan !== undefined) {
            queryParameters['idPlan'] = requestParameters.idPlan;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementLocalisation/LockClasse`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async agrementLocalisationLockClasse(requestParameters: AgrementLocalisationLockClasseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.agrementLocalisationLockClasseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementLocalisationSaveRaw(requestParameters: AgrementLocalisationSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbPlanAgrementDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementLocalisation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbPlanAgrementDtoToJSON(requestParameters.FcbPlanAgrementDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbPlanAgrementDtoFromJSON(jsonValue));
    }

    /**
     */
    async agrementLocalisationSave(requestParameters: AgrementLocalisationSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbPlanAgrementDto> {
        const response = await this.agrementLocalisationSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementLocalisationSearchClassesRaw(requestParameters: AgrementLocalisationSearchClassesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRecherchePlanAgrementClasseDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementLocalisation/SearchClasses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlanAgrementClasseSearchToJSON(requestParameters.PlanAgrementClasseSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRecherchePlanAgrementClasseDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async agrementLocalisationSearchClasses(requestParameters: AgrementLocalisationSearchClassesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRecherchePlanAgrementClasseDtoPaginatedResults> {
        const response = await this.agrementLocalisationSearchClassesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementLocalisationToValidatePlanRaw(requestParameters: AgrementLocalisationToValidatePlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.idPlan !== undefined) {
            queryParameters['idPlan'] = requestParameters.idPlan;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementLocalisation/ToValidatePlan`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async agrementLocalisationToValidatePlan(requestParameters: AgrementLocalisationToValidatePlanRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.agrementLocalisationToValidatePlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementLocalisationUnlockClasseRaw(requestParameters: AgrementLocalisationUnlockClasseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.idPlan !== undefined) {
            queryParameters['idPlan'] = requestParameters.idPlan;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementLocalisation/UnlockClasse`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async agrementLocalisationUnlockClasse(requestParameters: AgrementLocalisationUnlockClasseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.agrementLocalisationUnlockClasseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementLocalisationValidatePlanRaw(requestParameters: AgrementLocalisationValidatePlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.idPlan !== undefined) {
            queryParameters['idPlan'] = requestParameters.idPlan;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementLocalisation/ValidatePlan`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async agrementLocalisationValidatePlan(requestParameters: AgrementLocalisationValidatePlanRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.agrementLocalisationValidatePlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
