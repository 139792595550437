/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRecherchecalendrierFormateurDto
 */
export interface FcbRecherchecalendrierFormateurDto {
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchecalendrierFormateurDto
     */
    idcentre?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchecalendrierFormateurDto
     */
    idformateur?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchecalendrierFormateurDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchecalendrierFormateurDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchecalendrierFormateurDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchecalendrierFormateurDto
     */
    formateur?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRecherchecalendrierFormateurDto
     */
    jour?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchecalendrierFormateurDto
     */
    heureDebut?: string | null;
}

/**
 * Check if a given object implements the FcbRecherchecalendrierFormateurDto interface.
 */
export function instanceOfFcbRecherchecalendrierFormateurDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRecherchecalendrierFormateurDtoFromJSON(json: any): FcbRecherchecalendrierFormateurDto {
    return FcbRecherchecalendrierFormateurDtoFromJSONTyped(json, false);
}

export function FcbRecherchecalendrierFormateurDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRecherchecalendrierFormateurDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'formateur': !exists(json, 'formateur') ? undefined : json['formateur'],
        'jour': !exists(json, 'jour') ? undefined : (json['jour'] === null ? null : new Date(json['jour'])),
        'heureDebut': !exists(json, 'heureDebut') ? undefined : json['heureDebut'],
    };
}

export function FcbRecherchecalendrierFormateurDtoToJSON(value?: FcbRecherchecalendrierFormateurDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcentre': value.idcentre,
        'idformateur': value.idformateur,
        'nom': value.nom,
        'code': value.code,
        'description': value.description,
        'formateur': value.formateur,
        'jour': value.jour === undefined ? undefined : (value.jour === null ? null : value.jour.toISOString()),
        'heureDebut': value.heureDebut,
    };
}

