/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbFormateurProfessionGridDto
 */
export interface FcbFormateurProfessionGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurProfessionGridDto
     */
    idformateur?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurProfessionGridDto
     */
    idprofession?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurProfessionGridDto
     */
    profession?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurProfessionGridDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurProfessionGridDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFormateurProfessionGridDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurProfessionGridDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurProfessionGridDto
     */
    idfichier?: number | null;
}

/**
 * Check if a given object implements the FcbFormateurProfessionGridDto interface.
 */
export function instanceOfFcbFormateurProfessionGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbFormateurProfessionGridDtoFromJSON(json: any): FcbFormateurProfessionGridDto {
    return FcbFormateurProfessionGridDtoFromJSONTyped(json, false);
}

export function FcbFormateurProfessionGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbFormateurProfessionGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'idprofession': !exists(json, 'idprofession') ? undefined : json['idprofession'],
        'profession': !exists(json, 'profession') ? undefined : json['profession'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
    };
}

export function FcbFormateurProfessionGridDtoToJSON(value?: FcbFormateurProfessionGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idformateur': value.idformateur,
        'idprofession': value.idprofession,
        'profession': value.profession,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'libelle': value.libelle,
        'actif': value.actif,
        'fileName': value.fileName,
        'idfichier': value.idfichier,
    };
}

