/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EContentHidingMode } from './EContentHidingMode';
import {
    EContentHidingModeFromJSON,
    EContentHidingModeFromJSONTyped,
    EContentHidingModeToJSON,
} from './EContentHidingMode';

/**
 * 
 * @export
 * @interface FcbSuiviApprenantGridDto
 */
export interface FcbSuiviApprenantGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbSuiviApprenantGridDto
     */
    idsuiviApprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbSuiviApprenantGridDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbSuiviApprenantGridDto
     */
    idclasse?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbSuiviApprenantGridDto
     */
    classe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbSuiviApprenantGridDto
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbSuiviApprenantGridDto
     */
    dateSuivi?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbSuiviApprenantGridDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbSuiviApprenantGridDto
     */
    types?: Array<string> | null;
    /**
     * 
     * @type {EContentHidingMode}
     * @memberof FcbSuiviApprenantGridDto
     */
    contentHidingMode?: EContentHidingMode;
    /**
     * 
     * @type {number}
     * @memberof FcbSuiviApprenantGridDto
     */
    idlieu?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbSuiviApprenantGridDto
     */
    lieu?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbSuiviApprenantGridDto
     */
    utilisateur?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbSuiviApprenantGridDto
     */
    fromHope?: boolean;
}

/**
 * Check if a given object implements the FcbSuiviApprenantGridDto interface.
 */
export function instanceOfFcbSuiviApprenantGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbSuiviApprenantGridDtoFromJSON(json: any): FcbSuiviApprenantGridDto {
    return FcbSuiviApprenantGridDtoFromJSONTyped(json, false);
}

export function FcbSuiviApprenantGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbSuiviApprenantGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsuiviApprenant': !exists(json, 'idsuiviApprenant') ? undefined : json['idsuiviApprenant'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'classe': !exists(json, 'classe') ? undefined : json['classe'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'dateSuivi': !exists(json, 'dateSuivi') ? undefined : (json['dateSuivi'] === null ? null : new Date(json['dateSuivi'])),
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'types': !exists(json, 'types') ? undefined : json['types'],
        'contentHidingMode': !exists(json, 'contentHidingMode') ? undefined : EContentHidingModeFromJSON(json['contentHidingMode']),
        'idlieu': !exists(json, 'idlieu') ? undefined : json['idlieu'],
        'lieu': !exists(json, 'lieu') ? undefined : json['lieu'],
        'utilisateur': !exists(json, 'utilisateur') ? undefined : json['utilisateur'],
        'fromHope': !exists(json, 'fromHope') ? undefined : json['fromHope'],
    };
}

export function FcbSuiviApprenantGridDtoToJSON(value?: FcbSuiviApprenantGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsuiviApprenant': value.idsuiviApprenant,
        'idapprenant': value.idapprenant,
        'idclasse': value.idclasse,
        'classe': value.classe,
        'description': value.description,
        'dateSuivi': value.dateSuivi === undefined ? undefined : (value.dateSuivi === null ? null : value.dateSuivi.toISOString()),
        'creationUser': value.creationUser,
        'types': value.types,
        'contentHidingMode': EContentHidingModeToJSON(value.contentHidingMode),
        'idlieu': value.idlieu,
        'lieu': value.lieu,
        'utilisateur': value.utilisateur,
        'fromHope': value.fromHope,
    };
}

