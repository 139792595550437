import { Classes, Colors, Icon } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';
import * as React from "react";
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & h4 {
    color: ${Colors.GRAY1};
  }
`;

export interface ICustomStateDisplayProps {
  title?: string;
  description?: string;
  icon?: IconName;
  iconSize?: number;
  iconColor?: string;
}

export const CustomStateDisplay: React.FunctionComponent<ICustomStateDisplayProps> = ({ title, description, icon, iconColor, iconSize }) => {
  return (
    <Container>
      <Content>
        {icon && <Icon icon={icon} color={iconColor} size={iconSize} />}
        {title && <h4 className={Classes.HEADING}>{title}</h4>}
        {description && <div>{description}</div>}
      </Content>
    </Container>
  );
};