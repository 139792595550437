import { DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";

import {
  ClasseHoraireCoherenceSearch,
  ClasseHoraireSemestreApi,
  FcbClasseHoraireCoherenceSemestreGridDto
} from "../../../../../api";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { Colors } from "@blueprintjs/core";
import { css } from "styled-components";
import { useEventsContext } from "../../../../../contexts";

export interface IClasseCoherenceHoraireSemestreSearchProps {
  classeId: number;
}

export const ClasseCoherenceHoraireSemestreSearch: React.FunctionComponent<IClasseCoherenceHoraireSemestreSearchProps> = ({
  classeId
}) => {
  const { t } = useTl();
  const api = useApiService(ClasseHoraireSemestreApi);
  const [lastSobj, setLastSobj] = React.useState(null);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [5, 10],
    pageSize: 5,
    sortKeys: { semestre: "ASC" }
  });
  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();

  const searchFunction = React.useCallback(
    async (sObj?: ClasseHoraireCoherenceSearch) => {
      sObj.idClasse = classeId;
      setLastSobj(sObj);
      return api.classeHoraireSemestreBaseSearch({ ClasseHoraireCoherenceSearch: sObj });
    },
    [api, classeId]
  );

  const { loading, search } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.Semestre),
        fieldName: "semestre"
      },
      {
        header: () => t(ETLCodes.TypeProjet),
        fieldName: "typeProjet"
      },
      {
        header: () => t(ETLCodes.Heures),
        fieldName: "nbheures"
      },
      {
        header: () => t(ETLCodes.Planifie),
        fieldName: "nbheuresPlanifie"
      },
      {
        header: () => t(ETLCodes.Difference),
        fieldName: "difference"
      }
    ],
    [t]
  );

  const rowStyle = React.useCallback((item: FcbClasseHoraireCoherenceSemestreGridDto) => {
    const fontColor = item.difference !== 0 && item.difference !== undefined ? Colors.RED3 : Colors.BLACK;

    return css`
      & * {
        color: ${fontColor} !important;
      }
    `;
  }, []);

  React.useEffect(() => {
    const cb = () => search(lastSobj);
    subscribeToEvent("CLASSE_COHERENCE_REFRESH", cb);
    return () => {
      unsubscribeEvent("CLASSE_COHERENCE_REFRESH", cb);
    };
  }, [lastSobj, search, subscribeToEvent, unsubscribeEvent]);

  return (
    <>
      {classeId > 0 && (
        <FieldSet title={`${t(ETLCodes.CoherenceHoraireSemestre)}`}>
          <DataTable
            dateFormat="dd-MM-yyyy"
            tableState={tableState}
            loading={loading}
            columns={columns}
            customizeRowStyle={rowStyle}
          />
        </FieldSet>
      )}
    </>
  );
};
