import { Classes } from "@blueprintjs/core";
import styled from "styled-components";

import { IThemeData } from "../../theme";

export const Container = styled.div<{ theme: IThemeData }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-width: 250px;
  height: auto;
  background-color: ${({ theme }) => theme.menuBackgroundColor} !important;
  & .${Classes.MENU} {
    padding: 0 !important;
  }
`;
