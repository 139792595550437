/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { DateSearch } from './DateSearch';
import {
    DateSearchFromJSON,
    DateSearchFromJSONTyped,
    DateSearchToJSON,
} from './DateSearch';
import type { DoubleListSearch } from './DoubleListSearch';
import {
    DoubleListSearchFromJSON,
    DoubleListSearchFromJSONTyped,
    DoubleListSearchToJSON,
} from './DoubleListSearch';
import type { EIfapmeSide } from './EIfapmeSide';
import {
    EIfapmeSideFromJSON,
    EIfapmeSideFromJSONTyped,
    EIfapmeSideToJSON,
} from './EIfapmeSide';
import type { EOrigineSuiviListSearch } from './EOrigineSuiviListSearch';
import {
    EOrigineSuiviListSearchFromJSON,
    EOrigineSuiviListSearchFromJSONTyped,
    EOrigineSuiviListSearchToJSON,
} from './EOrigineSuiviListSearch';
import type { Int32ListSearch } from './Int32ListSearch';
import {
    Int32ListSearchFromJSON,
    Int32ListSearchFromJSONTyped,
    Int32ListSearchToJSON,
} from './Int32ListSearch';
import type { NumericSearch } from './NumericSearch';
import {
    NumericSearchFromJSON,
    NumericSearchFromJSONTyped,
    NumericSearchToJSON,
} from './NumericSearch';
import type { UnaccentTextSearch } from './UnaccentTextSearch';
import {
    UnaccentTextSearchFromJSON,
    UnaccentTextSearchFromJSONTyped,
    UnaccentTextSearchToJSON,
} from './UnaccentTextSearch';

/**
 * 
 * @export
 * @interface DashboardSuiviSearch
 */
export interface DashboardSuiviSearch {
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof DashboardSuiviSearch
     */
    nomApprenant?: UnaccentTextSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof DashboardSuiviSearch
     */
    prenomApprenant?: UnaccentTextSearch;
    /**
     * 
     * @type {NumericSearch}
     * @memberof DashboardSuiviSearch
     */
    idapprenant?: NumericSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof DashboardSuiviSearch
     */
    idrealisateurSuivi?: Int32ListSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof DashboardSuiviSearch
     */
    idencodeur?: Int32ListSearch;
    /**
     * 
     * @type {EOrigineSuiviListSearch}
     * @memberof DashboardSuiviSearch
     */
    origineSuivi?: EOrigineSuiviListSearch;
    /**
     * 
     * @type {DateSearch}
     * @memberof DashboardSuiviSearch
     */
    dateSuivi?: DateSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof DashboardSuiviSearch
     */
    idtypeSuivi?: Int32ListSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof DashboardSuiviSearch
     */
    idsousTypeSuivi?: Int32ListSearch;
    /**
     * 
     * @type {DoubleListSearch}
     * @memberof DashboardSuiviSearch
     */
    idservice?: DoubleListSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof DashboardSuiviSearch
     */
    idpri?: Int32ListSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof DashboardSuiviSearch
     */
    idari?: Int32ListSearch;
    /**
     * 
     * @type {EIfapmeSide}
     * @memberof DashboardSuiviSearch
     */
    ifapmeSide?: EIfapmeSide;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardSuiviSearch
     */
    onlyUserDashboard?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardSuiviSearch
     */
    hideMasquees?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardSuiviSearch
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof DashboardSuiviSearch
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardSuiviSearch
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardSuiviSearch
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof DashboardSuiviSearch
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardSuiviSearch
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the DashboardSuiviSearch interface.
 */
export function instanceOfDashboardSuiviSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DashboardSuiviSearchFromJSON(json: any): DashboardSuiviSearch {
    return DashboardSuiviSearchFromJSONTyped(json, false);
}

export function DashboardSuiviSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardSuiviSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nomApprenant': !exists(json, 'nomApprenant') ? undefined : UnaccentTextSearchFromJSON(json['nomApprenant']),
        'prenomApprenant': !exists(json, 'prenomApprenant') ? undefined : UnaccentTextSearchFromJSON(json['prenomApprenant']),
        'idapprenant': !exists(json, 'idapprenant') ? undefined : NumericSearchFromJSON(json['idapprenant']),
        'idrealisateurSuivi': !exists(json, 'idrealisateurSuivi') ? undefined : Int32ListSearchFromJSON(json['idrealisateurSuivi']),
        'idencodeur': !exists(json, 'idencodeur') ? undefined : Int32ListSearchFromJSON(json['idencodeur']),
        'origineSuivi': !exists(json, 'origineSuivi') ? undefined : EOrigineSuiviListSearchFromJSON(json['origineSuivi']),
        'dateSuivi': !exists(json, 'dateSuivi') ? undefined : DateSearchFromJSON(json['dateSuivi']),
        'idtypeSuivi': !exists(json, 'idtypeSuivi') ? undefined : Int32ListSearchFromJSON(json['idtypeSuivi']),
        'idsousTypeSuivi': !exists(json, 'idsousTypeSuivi') ? undefined : Int32ListSearchFromJSON(json['idsousTypeSuivi']),
        'idservice': !exists(json, 'idservice') ? undefined : DoubleListSearchFromJSON(json['idservice']),
        'idpri': !exists(json, 'idpri') ? undefined : Int32ListSearchFromJSON(json['idpri']),
        'idari': !exists(json, 'idari') ? undefined : Int32ListSearchFromJSON(json['idari']),
        'ifapmeSide': !exists(json, 'ifapmeSide') ? undefined : EIfapmeSideFromJSON(json['ifapmeSide']),
        'onlyUserDashboard': !exists(json, 'onlyUserDashboard') ? undefined : json['onlyUserDashboard'],
        'hideMasquees': !exists(json, 'hideMasquees') ? undefined : json['hideMasquees'],
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function DashboardSuiviSearchToJSON(value?: DashboardSuiviSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nomApprenant': UnaccentTextSearchToJSON(value.nomApprenant),
        'prenomApprenant': UnaccentTextSearchToJSON(value.prenomApprenant),
        'idapprenant': NumericSearchToJSON(value.idapprenant),
        'idrealisateurSuivi': Int32ListSearchToJSON(value.idrealisateurSuivi),
        'idencodeur': Int32ListSearchToJSON(value.idencodeur),
        'origineSuivi': EOrigineSuiviListSearchToJSON(value.origineSuivi),
        'dateSuivi': DateSearchToJSON(value.dateSuivi),
        'idtypeSuivi': Int32ListSearchToJSON(value.idtypeSuivi),
        'idsousTypeSuivi': Int32ListSearchToJSON(value.idsousTypeSuivi),
        'idservice': DoubleListSearchToJSON(value.idservice),
        'idpri': Int32ListSearchToJSON(value.idpri),
        'idari': Int32ListSearchToJSON(value.idari),
        'ifapmeSide': EIfapmeSideToJSON(value.ifapmeSide),
        'onlyUserDashboard': value.onlyUserDashboard,
        'hideMasquees': value.hideMasquees,
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

