/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  ChangementClasseSearch,
  FcbChangementClasseDto,
  FcbChangementClasseGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    ChangementClasseSearchFromJSON,
    ChangementClasseSearchToJSON,
    FcbChangementClasseDtoFromJSON,
    FcbChangementClasseDtoToJSON,
    FcbChangementClasseGridDtoPaginatedResultsFromJSON,
    FcbChangementClasseGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface ChangementClasseBaseSearchRequest {
    ChangementClasseSearch?: ChangementClasseSearch;
}

export interface ChangementClasseCheckHasEvalsToDeleteRequest {
    idChangementClasse?: number;
}

export interface ChangementClasseDeleteRequest {
    id?: number;
}

export interface ChangementClasseGetRequest {
    id?: number;
}

export interface ChangementClasseGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ChangementClasseGetSelectItemsRequest {
    searchField: string;
}

export interface ChangementClasseOtherInscriptionForClasseExistsRequest {
    idinscription?: number;
    idclasse?: number;
}

export interface ChangementClasseSaveRequest {
    FcbChangementClasseDto?: FcbChangementClasseDto;
}

/**
 * 
 */
export class ChangementClasseApi extends runtime.BaseAPI {

    /**
     */
    async changementClasseBaseSearchRaw(requestParameters: ChangementClasseBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbChangementClasseGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ChangementClasse/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangementClasseSearchToJSON(requestParameters.ChangementClasseSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbChangementClasseGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async changementClasseBaseSearch(requestParameters: ChangementClasseBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbChangementClasseGridDtoPaginatedResults> {
        const response = await this.changementClasseBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changementClasseCheckHasEvalsToDeleteRaw(requestParameters: ChangementClasseCheckHasEvalsToDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idChangementClasse !== undefined) {
            queryParameters['idChangementClasse'] = requestParameters.idChangementClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ChangementClasse/CheckHasEvalsToDelete`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async changementClasseCheckHasEvalsToDelete(requestParameters: ChangementClasseCheckHasEvalsToDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.changementClasseCheckHasEvalsToDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changementClasseDeleteRaw(requestParameters: ChangementClasseDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ChangementClasse`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async changementClasseDelete(requestParameters: ChangementClasseDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.changementClasseDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changementClasseGetRaw(requestParameters: ChangementClasseGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbChangementClasseDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ChangementClasse`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbChangementClasseDtoFromJSON(jsonValue));
    }

    /**
     */
    async changementClasseGet(requestParameters: ChangementClasseGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbChangementClasseDto> {
        const response = await this.changementClasseGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changementClasseGetSearchCriteriasRaw(requestParameters: ChangementClasseGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ChangementClasse/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async changementClasseGetSearchCriterias(requestParameters: ChangementClasseGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.changementClasseGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changementClasseGetSelectItemsRaw(requestParameters: ChangementClasseGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling changementClasseGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ChangementClasse/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async changementClasseGetSelectItems(requestParameters: ChangementClasseGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.changementClasseGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changementClasseOtherInscriptionForClasseExistsRaw(requestParameters: ChangementClasseOtherInscriptionForClasseExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idinscription !== undefined) {
            queryParameters['idinscription'] = requestParameters.idinscription;
        }

        if (requestParameters.idclasse !== undefined) {
            queryParameters['idclasse'] = requestParameters.idclasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ChangementClasse/OtherInscriptionForClasseExists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async changementClasseOtherInscriptionForClasseExists(requestParameters: ChangementClasseOtherInscriptionForClasseExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.changementClasseOtherInscriptionForClasseExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async changementClasseSaveRaw(requestParameters: ChangementClasseSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbChangementClasseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ChangementClasse`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbChangementClasseDtoToJSON(requestParameters.FcbChangementClasseDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbChangementClasseDtoFromJSON(jsonValue));
    }

    /**
     */
    async changementClasseSave(requestParameters: ChangementClasseSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbChangementClasseDto> {
        const response = await this.changementClasseSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
