/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRecherchestatPresenceApprenantDto
 */
export interface FcbRecherchestatPresenceApprenantDto {
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchestatPresenceApprenantDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchestatPresenceApprenantDto
     */
    idapprenant?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchestatPresenceApprenantDto
     */
    idclasse?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchestatPresenceApprenantDto
     */
    codeclasse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchestatPresenceApprenantDto
     */
    classe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchestatPresenceApprenantDto
     */
    statutInscription?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchestatPresenceApprenantDto
     */
    nbretot?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchestatPresenceApprenantDto
     */
    nbrex?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchestatPresenceApprenantDto
     */
    nbrejdtc?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchestatPresenceApprenantDto
     */
    nbreo?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchestatPresenceApprenantDto
     */
    percentPresence?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRecherchestatPresenceApprenantDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRecherchestatPresenceApprenantDto
     */
    dateFin?: Date | null;
}

/**
 * Check if a given object implements the FcbRecherchestatPresenceApprenantDto interface.
 */
export function instanceOfFcbRecherchestatPresenceApprenantDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRecherchestatPresenceApprenantDtoFromJSON(json: any): FcbRecherchestatPresenceApprenantDto {
    return FcbRecherchestatPresenceApprenantDtoFromJSONTyped(json, false);
}

export function FcbRecherchestatPresenceApprenantDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRecherchestatPresenceApprenantDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'codeclasse': !exists(json, 'codeclasse') ? undefined : json['codeclasse'],
        'classe': !exists(json, 'classe') ? undefined : json['classe'],
        'statutInscription': !exists(json, 'statutInscription') ? undefined : json['statutInscription'],
        'nbretot': !exists(json, 'nbretot') ? undefined : json['nbretot'],
        'nbrex': !exists(json, 'nbrex') ? undefined : json['nbrex'],
        'nbrejdtc': !exists(json, 'nbrejdtc') ? undefined : json['nbrejdtc'],
        'nbreo': !exists(json, 'nbreo') ? undefined : json['nbreo'],
        'percentPresence': !exists(json, 'percentPresence') ? undefined : json['percentPresence'],
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
    };
}

export function FcbRecherchestatPresenceApprenantDtoToJSON(value?: FcbRecherchestatPresenceApprenantDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'anneeScolaire': value.anneeScolaire,
        'idapprenant': value.idapprenant,
        'idclasse': value.idclasse,
        'codeclasse': value.codeclasse,
        'classe': value.classe,
        'statutInscription': value.statutInscription,
        'nbretot': value.nbretot,
        'nbrex': value.nbrex,
        'nbrejdtc': value.nbrejdtc,
        'nbreo': value.nbreo,
        'percentPresence': value.percentPresence,
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
    };
}

