import * as React from "react";
import styled from "styled-components";
import { useTheme } from "../hooks";

const StyledSpan = styled.span<{ color: string }>`
  display: flex;
  color: ${props => props.color};
`;

export interface IWarningTextProps {
  text: string;
}

export const WarningText: React.FunctionComponent<IWarningTextProps> = ({ text }) => {
  const { theme } = useTheme();

  return <StyledSpan color={theme.warningColor}>{text}</StyledSpan>;
};
