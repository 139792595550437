/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { DateSearch } from './DateSearch';
import {
    DateSearchFromJSON,
    DateSearchFromJSONTyped,
    DateSearchToJSON,
} from './DateSearch';
import type { Int32NullableListSearch } from './Int32NullableListSearch';
import {
    Int32NullableListSearchFromJSON,
    Int32NullableListSearchFromJSONTyped,
    Int32NullableListSearchToJSON,
} from './Int32NullableListSearch';
import type { TextSearch } from './TextSearch';
import {
    TextSearchFromJSON,
    TextSearchFromJSONTyped,
    TextSearchToJSON,
} from './TextSearch';

/**
 * 
 * @export
 * @interface RechercheRemplacementSearch
 */
export interface RechercheRemplacementSearch {
    /**
     * 
     * @type {TextSearch}
     * @memberof RechercheRemplacementSearch
     */
    remplace?: TextSearch;
    /**
     * 
     * @type {Int32NullableListSearch}
     * @memberof RechercheRemplacementSearch
     */
    idTypeRemplacement?: Int32NullableListSearch;
    /**
     * 
     * @type {DateSearch}
     * @memberof RechercheRemplacementSearch
     */
    dateDebutSearch?: DateSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof RechercheRemplacementSearch
     */
    anneeAcademique?: TextSearch;
    /**
     * 
     * @type {Int32NullableListSearch}
     * @memberof RechercheRemplacementSearch
     */
    idLegitimite?: Int32NullableListSearch;
    /**
     * 
     * @type {Array<string>}
     * @memberof RechercheRemplacementSearch
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof RechercheRemplacementSearch
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof RechercheRemplacementSearch
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RechercheRemplacementSearch
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof RechercheRemplacementSearch
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof RechercheRemplacementSearch
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the RechercheRemplacementSearch interface.
 */
export function instanceOfRechercheRemplacementSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RechercheRemplacementSearchFromJSON(json: any): RechercheRemplacementSearch {
    return RechercheRemplacementSearchFromJSONTyped(json, false);
}

export function RechercheRemplacementSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): RechercheRemplacementSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'remplace': !exists(json, 'remplace') ? undefined : TextSearchFromJSON(json['remplace']),
        'idTypeRemplacement': !exists(json, 'idTypeRemplacement') ? undefined : Int32NullableListSearchFromJSON(json['idTypeRemplacement']),
        'dateDebutSearch': !exists(json, 'dateDebutSearch') ? undefined : DateSearchFromJSON(json['dateDebutSearch']),
        'anneeAcademique': !exists(json, 'anneeAcademique') ? undefined : TextSearchFromJSON(json['anneeAcademique']),
        'idLegitimite': !exists(json, 'idLegitimite') ? undefined : Int32NullableListSearchFromJSON(json['idLegitimite']),
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function RechercheRemplacementSearchToJSON(value?: RechercheRemplacementSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'remplace': TextSearchToJSON(value.remplace),
        'idTypeRemplacement': Int32NullableListSearchToJSON(value.idTypeRemplacement),
        'dateDebutSearch': DateSearchToJSON(value.dateDebutSearch),
        'anneeAcademique': TextSearchToJSON(value.anneeAcademique),
        'idLegitimite': Int32NullableListSearchToJSON(value.idLegitimite),
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

