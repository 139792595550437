import * as React from "react";
import { FormateurPreferenceHoraireListPage } from "./FormateurPreferenceHoraireListPage";
import { FormateurPreferenceHoraireDetailPage } from "./FormateurPreferenceHoraireDetailPage";
import { ERoutes } from "../../../../../AppRouter";
import { Switch, Route } from "react-router";

export interface IFormateurPreferenceHoraireSwitchProps {}

export const FormateurPreferenceHoraireSwitch: React.FunctionComponent<IFormateurPreferenceHoraireSwitchProps> = () => {
  return (
    <Switch>
      <Route path={`${ERoutes.formateur}/:id/:tab`} component={FormateurPreferenceHoraireListPage} exact />
      <Route
        path={`${ERoutes.formateur}/:id/:tab/:idPreference/:state`}
        component={FormateurPreferenceHoraireDetailPage}
      />
    </Switch>
  );
};
