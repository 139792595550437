import addMinutes from "date-fns/addMinutes";
import format from "date-fns/format";
import parseJSON from "date-fns/parseJSON";
import { DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";

import { PersonneApi, PersonneHistoryGridDto, PersonneHistorySearch } from "../../../../../../api";
import { useApiService, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

export interface IPersonneHistoryProps {}

export const PersonneHistory: React.FunctionComponent<IPersonneHistoryProps> = props => {
  const { t, tUnsafe } = useTl();
  const { id } = useParams<{ id: string }>();
  const personneId = React.useMemo(() => +id, [id]);
  const api = useApiService(PersonneApi);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { actionDate: "DESC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: PersonneHistorySearch) => {
      sObj.idpersonne = personneId;
      return api.personneSearchHistory({ PersonneHistorySearch: sObj });
    },
    [api, personneId]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });
  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.Utilisateur),
        fieldName: "userName"
      },
      {
        header: () => t(ETLCodes.Action),
        fieldName: "action",
        render: (row: PersonneHistoryGridDto) => tUnsafe(`Personne_${row.actionType}_${row.action}`)
      },
      {
        header: () => t(ETLCodes.Date),
        fieldName: "actionDate"
      },
      {
        header: () => t(ETLCodes.UserType),
        fieldName: "ifapmeSide",
        render: (row: PersonneHistoryGridDto) =>
          `${row.ifapmeSide.includes("Walter") ? tUnsafe("UserType_Centre") : ""}${
            row.ifapmeSide.length === 2 ? " / " : ""
          }${row.ifapmeSide.includes("Hope") ? tUnsafe("UserType_Service") : ""}`
      }
    ],
    [t, tUnsafe]
  );

  const formatDate = React.useCallback((date: Date) => {
    return format(parseJSON(addMinutes(date, date.getTimezoneOffset())), "dd/MM/yyyy HH:mm");
  }, []);

  return (
    <>
      <FieldSet title={t(ETLCodes.TableResults, { count: totalCount })}>
        <DataTable
          dateFormat="dd/MM/yyyy HH:mm"
          formatDate={formatDate}
          tableState={tableState}
          loading={loading}
          columns={columns}
        ></DataTable>
      </FieldSet>
    </>
  );
};
