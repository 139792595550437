/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EListSearchType } from './EListSearchType';
import {
    EListSearchTypeFromJSON,
    EListSearchTypeFromJSONTyped,
    EListSearchTypeToJSON,
} from './EListSearchType';
import type { ESearchStatutInscription } from './ESearchStatutInscription';
import {
    ESearchStatutInscriptionFromJSON,
    ESearchStatutInscriptionFromJSONTyped,
    ESearchStatutInscriptionToJSON,
} from './ESearchStatutInscription';

/**
 * 
 * @export
 * @interface EListSearchTypeESearchStatutInscriptionCombinableCriteria
 */
export interface EListSearchTypeESearchStatutInscriptionCombinableCriteria {
    /**
     * 
     * @type {EListSearchType}
     * @memberof EListSearchTypeESearchStatutInscriptionCombinableCriteria
     */
    searchMode?: EListSearchType;
    /**
     * 
     * @type {ESearchStatutInscription}
     * @memberof EListSearchTypeESearchStatutInscriptionCombinableCriteria
     */
    value?: ESearchStatutInscription;
    /**
     * 
     * @type {ESearchStatutInscription}
     * @memberof EListSearchTypeESearchStatutInscriptionCombinableCriteria
     */
    secondaryValue?: ESearchStatutInscription;
}

/**
 * Check if a given object implements the EListSearchTypeESearchStatutInscriptionCombinableCriteria interface.
 */
export function instanceOfEListSearchTypeESearchStatutInscriptionCombinableCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EListSearchTypeESearchStatutInscriptionCombinableCriteriaFromJSON(json: any): EListSearchTypeESearchStatutInscriptionCombinableCriteria {
    return EListSearchTypeESearchStatutInscriptionCombinableCriteriaFromJSONTyped(json, false);
}

export function EListSearchTypeESearchStatutInscriptionCombinableCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): EListSearchTypeESearchStatutInscriptionCombinableCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchMode': !exists(json, 'searchMode') ? undefined : EListSearchTypeFromJSON(json['searchMode']),
        'value': !exists(json, 'value') ? undefined : ESearchStatutInscriptionFromJSON(json['value']),
        'secondaryValue': !exists(json, 'secondaryValue') ? undefined : ESearchStatutInscriptionFromJSON(json['secondaryValue']),
    };
}

export function EListSearchTypeESearchStatutInscriptionCombinableCriteriaToJSON(value?: EListSearchTypeESearchStatutInscriptionCombinableCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchMode': EListSearchTypeToJSON(value.searchMode),
        'value': ESearchStatutInscriptionToJSON(value.value),
        'secondaryValue': ESearchStatutInscriptionToJSON(value.secondaryValue),
    };
}

