/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExamenEntreeFichierDto
 */
export interface ExamenEntreeFichierDto {
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeFichierDto
     */
    idexamenEntreeFichier?: number;
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeFichierDto
     */
    idexamenEntree?: number;
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeFichierDto
     */
    idfichier?: number;
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeFichierDto
     */
    idtypeAcquisFichier?: number;
    /**
     * 
     * @type {string}
     * @memberof ExamenEntreeFichierDto
     */
    fileName?: string | null;
}

/**
 * Check if a given object implements the ExamenEntreeFichierDto interface.
 */
export function instanceOfExamenEntreeFichierDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExamenEntreeFichierDtoFromJSON(json: any): ExamenEntreeFichierDto {
    return ExamenEntreeFichierDtoFromJSONTyped(json, false);
}

export function ExamenEntreeFichierDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExamenEntreeFichierDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idexamenEntreeFichier': !exists(json, 'idexamenEntreeFichier') ? undefined : json['idexamenEntreeFichier'],
        'idexamenEntree': !exists(json, 'idexamenEntree') ? undefined : json['idexamenEntree'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'idtypeAcquisFichier': !exists(json, 'idtypeAcquisFichier') ? undefined : json['idtypeAcquisFichier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
    };
}

export function ExamenEntreeFichierDtoToJSON(value?: ExamenEntreeFichierDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idexamenEntreeFichier': value.idexamenEntreeFichier,
        'idexamenEntree': value.idexamenEntree,
        'idfichier': value.idfichier,
        'idtypeAcquisFichier': value.idtypeAcquisFichier,
        'fileName': value.fileName,
    };
}

