/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbExportGapStatsGridDto
 */
export interface FcbExportGapStatsGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbExportGapStatsGridDto
     */
    idexport?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbExportGapStatsGridDto
     */
    heureCpdateCe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbExportGapStatsGridDto
     */
    plusRemplacantCe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbExportGapStatsGridDto
     */
    plusRemplaceCe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbExportGapStatsGridDto
     */
    moinsCrdateCe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbExportGapStatsGridDto
     */
    heureCpdateApp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbExportGapStatsGridDto
     */
    plusRemplacantApp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbExportGapStatsGridDto
     */
    plusRemplaceApp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbExportGapStatsGridDto
     */
    moinsCrdateApp?: string | null;
}

/**
 * Check if a given object implements the FcbExportGapStatsGridDto interface.
 */
export function instanceOfFcbExportGapStatsGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbExportGapStatsGridDtoFromJSON(json: any): FcbExportGapStatsGridDto {
    return FcbExportGapStatsGridDtoFromJSONTyped(json, false);
}

export function FcbExportGapStatsGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbExportGapStatsGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idexport': !exists(json, 'idexport') ? undefined : json['idexport'],
        'heureCpdateCe': !exists(json, 'heureCpdateCe') ? undefined : json['heureCpdateCe'],
        'plusRemplacantCe': !exists(json, 'plusRemplacantCe') ? undefined : json['plusRemplacantCe'],
        'plusRemplaceCe': !exists(json, 'plusRemplaceCe') ? undefined : json['plusRemplaceCe'],
        'moinsCrdateCe': !exists(json, 'moinsCrdateCe') ? undefined : json['moinsCrdateCe'],
        'heureCpdateApp': !exists(json, 'heureCpdateApp') ? undefined : json['heureCpdateApp'],
        'plusRemplacantApp': !exists(json, 'plusRemplacantApp') ? undefined : json['plusRemplacantApp'],
        'plusRemplaceApp': !exists(json, 'plusRemplaceApp') ? undefined : json['plusRemplaceApp'],
        'moinsCrdateApp': !exists(json, 'moinsCrdateApp') ? undefined : json['moinsCrdateApp'],
    };
}

export function FcbExportGapStatsGridDtoToJSON(value?: FcbExportGapStatsGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idexport': value.idexport,
        'heureCpdateCe': value.heureCpdateCe,
        'plusRemplacantCe': value.plusRemplacantCe,
        'plusRemplaceCe': value.plusRemplaceCe,
        'moinsCrdateCe': value.moinsCrdateCe,
        'heureCpdateApp': value.heureCpdateApp,
        'plusRemplacantApp': value.plusRemplacantApp,
        'plusRemplaceApp': value.plusRemplaceApp,
        'moinsCrdateApp': value.moinsCrdateApp,
    };
}

