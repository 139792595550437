import { Button, Intent } from "@blueprintjs/core";
import { FGCustomPanel, FGListen, FGMultiSuggestInput, FGTextInput, FieldGroup, FieldSet } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { FcbParcoursCreaDetailDto, ParcoursCreaApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterDateMaskInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { useAuth } from "../../../../../contexts";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import { bceNumberValidate } from "../../../../../utils/bceNumberValidate";
import { ProspectParcoursCreaDemandeList } from "./ProspectParcoursCreaDemandeList";

export interface IProspectParcoursCreaProps {}

export const ProspectParcoursCrea: React.FunctionComponent<IProspectParcoursCreaProps> = () => {
  const { t } = useTl();
  const { user } = useAuth();
  const history = useHistory();
  const api = useApiService(ParcoursCreaApi);
  const { id, state } = useParams<{
    id: string;
    state: string;
  }>();

  const [onLoad, setOnLoad] = React.useState(true);
  const [idNotorieteType, setIdNotorieteType] = React.useState();
  const [idNotorieteSousType, setIdNotorieteSousType] = React.useState();

  const [statutsCrea, scLoading] = useReferential(a => a.referentialGetStatutCrea());
  const [categoriesSpecifiques, csLoading] = useReferential(a => a.referentialGetCategorieSpecifiqueCrea());
  const [secteursCrea, secLoading] = useReferential(a => a.referentialGetSecteurCrea());
  const [notorieteTypes, ntLoading, , rawNotorieteTypes] = useReferential(a => a.referentialGetNotorieteType());
  const [notorieteSousTypes, nstLoading, , rawNotorieteSousTypes] = useReferential(
    a => a.referentialGetNotorieteSousType({ idNotorieteType: +(idNotorieteType ?? 0) }),
    true,
    [idNotorieteType]
  );
  const [ambassadeurs, aLoading] = useReferential(a => a.referentialGetAmbassadeurCrea(), true);
  const [centres, cLoading] = useReferential(a => a.referentialGetCentres());

  const { data, loading, saveItem, saving } = useCrudApi<FcbParcoursCreaDetailDto>(
    React.useMemo(
      () => ({
        getApiFn: () => api.parcoursCreaGetByIdapprenant({ id: +id }),
        saveApiFn: d => api.parcoursCreaSave({ FcbParcoursCreaDetailDto: d }),
        onSavedRoute: d => `${ERoutes.prospect}/${d.idapprenant}/crea/edit`,
        onSaved: () => setOnLoad(true),
        deleteApiFn: d => api.parcoursCreaDelete({ id: d.idparcoursCrea }),
        onDeletedRoute: d => `${ERoutes.prospect}/${d.idapprenant}/detail/edit`
      }),
      [api, id]
    )
  );

  const IsSousTypeVisible = React.useMemo(() => notorieteSousTypes?.length > 0, [notorieteSousTypes]);
  const IsTypeCentre = React.useMemo(
    () => !!idNotorieteType && rawNotorieteTypes?.find(n => +n.idValue === +idNotorieteType)?.keyValue === "CEI",
    [idNotorieteType, rawNotorieteTypes]
  );
  const IsChampLibreVisible = React.useMemo(() => {
    const notoCL = !!idNotorieteType && rawNotorieteTypes?.find(n => +n.idValue === +idNotorieteType)?.champLibre;
    const stCL =
      !!idNotorieteSousType && rawNotorieteSousTypes?.find(n => +n.idValue === +idNotorieteSousType)?.champLibre;

    return (notoCL || stCL) && !IsTypeCentre;
  }, [IsTypeCentre, idNotorieteSousType, idNotorieteType, rawNotorieteSousTypes, rawNotorieteTypes]);

  const AddDemandeFunc = React.useCallback(
    () => history.push(`${ERoutes.prospect}/${id}/crea/edit/demandeFormation/0`),
    [history, id]
  );

  const isCrea = React.useMemo(() => user.hopeMainLevelName === "Conseiller CREA" || user.isAdmin, [
    user.hopeMainLevelName,
    user.isAdmin
  ]);

  const demandeButtons = React.useMemo(
    () =>
      state === "edit" &&
      isCrea && (
        <>
          <Button
            style={{ marginLeft: "0.5rem" }}
            intent={Intent.PRIMARY}
            text={t(ETLCodes.AddDemandeFormation)}
            onClick={() => {
              AddDemandeFunc();
            }}
            loading={loading}
          />
        </>
      ),
    [AddDemandeFunc, isCrea, loading, state, t]
  );

  const formSchema = React.useMemo(
    () =>
      Yup.object().shape(
        {
          idnotorieteType: Yup.number().required(t(ETLCodes.Required)),
          numeroEntreprise: Yup.string()
            .nullable()
            .test("bce-validity", t(ETLCodes.InvalidNumberEntreprise), async function(value) {
              return !value || bceNumberValidate(value.toString());
            }),
          datePrevisionnelle: Yup.date()
            .nullable()
            .when("dateLancement", (value, schema) =>
              !!value
                ? schema.max(Yup.ref("dateLancement"), t(ETLCodes.DatePrevisonnelleGreaterThanDateLancement))
                : schema
            ),
          dateLancement: Yup.date()
            .nullable()
            .when("datePrevisionnelle", (value, schema) =>
              !!value
                ? schema.min(Yup.ref("datePrevisionnelle"), t(ETLCodes.DateLancementGreaterThanDatePrevisionnelle))
                : schema
            )
        },
        [["datePrevisionnelle", "dateLancement"]]
      ),
    [t]
  );

  const canEditStatutCrea = React.useMemo(
    () => history.location.pathname.includes(ERoutes.apprenant) && data?.idparcoursCrea <= 0,
    [data?.idparcoursCrea, history.location.pathname]
  );

  return (
    <>
      <div style={{ marginBottom: "0.5rem" }}>
        <FieldSet title={t(ETLCodes.ParcoursCrea)}>
          <SmallFormGenerator
            enableDirtyCheck={false}
            initialValues={data}
            editMode={state === "edit" && isCrea}
            editable={state === "edit" && isCrea}
            onSubmit={saveItem}
            saving={saving}
            loading={loading}
            formatDate="dd-MM-yyyy"
            minLabelWidth={220}
            validationSchema={formSchema}
          >
            <FGListen
              field={"idnotorieteType"}
              onChanged={(value: any, formik) => {
                setIdNotorieteType(value);
                setIdNotorieteSousType(null);
                if (onLoad === false) {
                  formik.setFieldValue("idnotorieteSousType", null);
                  formik.setFieldValue("notorieteChampLibre", null);

                  if (rawNotorieteTypes?.find(n => +n.idValue === +value)?.keyValue !== "CEI") {
                    formik.setFieldValue("idcentre", null);
                  }
                } else {
                  setOnLoad(false);
                }
              }}
            />
            <FGListen
              field={"idnotorieteSousType"}
              onChanged={(value: any) => {
                setIdNotorieteSousType(value);
              }}
            />
            <FieldGroup>
              <FieldGroup columns={3}>
                <FGWalterSelectInput
                  name="idstatutCrea"
                  label={t(ETLCodes.StatutCrea)}
                  items={statutsCrea}
                  loading={scLoading}
                  readonly={!canEditStatutCrea}
                />
                <FGWalterSelectInput
                  name="idsecteurCrea"
                  label={t(ETLCodes.ProjetEntreprise)}
                  items={secteursCrea}
                  loading={secLoading}
                />
                <FGWalterSelectInput
                  name="idcategorieSpecifiqueCrea"
                  label={t(ETLCodes.StatutSpecifique)}
                  items={categoriesSpecifiques}
                  loading={csLoading}
                  readonly={!canEditStatutCrea}
                />
                <FGWalterDateMaskInput name="datePrevisionnelle" label={t(ETLCodes.DatePrevisionnelleLancement)} />
                <FGWalterDateMaskInput name="dateLancement" label={t(ETLCodes.DateLancement)} />
                <FGCustomPanel>
                  {ctx =>
                    !!ctx.formik?.values?.dateLancement && (
                      <FGTextInput name="numeroEntreprise" label={t(ETLCodes.NumeroEntreprise)} />
                    )
                  }
                </FGCustomPanel>
              </FieldGroup>
              <FieldGroup>
                <FGMultiSuggestInput
                  name="ambassadeursCrea"
                  label={t(ETLCodes.AmbassadeursCrea)}
                  items={ambassadeurs}
                  loading={aLoading}
                />
              </FieldGroup>
              <FieldGroup columns={3}>
                <FGWalterSelectInput
                  name="idnotorieteType"
                  label={t(ETLCodes.NotorieteIFAPME)}
                  items={notorieteTypes}
                  loading={ntLoading}
                />
                {IsTypeCentre ? (
                  <FGWalterSelectInput name="idcentre" items={centres} loading={cLoading} />
                ) : (
                  IsSousTypeVisible && (
                    <FGWalterSelectInput name="idnotorieteSousType" items={notorieteSousTypes} loading={nstLoading} />
                  )
                )}
                <FGTextInput name="notorieteChampLibre" visible={IsChampLibreVisible} />
              </FieldGroup>
            </FieldGroup>
          </SmallFormGenerator>
        </FieldSet>
      </div>
      {!!data?.idparcoursCrea && (
        <div style={{ marginBottom: "0.5rem" }}>
          <FieldSet title={t(ETLCodes.DemandeFormationCrea)} rightElement={demandeButtons}>
            <ProspectParcoursCreaDemandeList editMode={state === "edit" && isCrea} />
          </FieldSet>
        </div>
      )}
    </>
  );
};
