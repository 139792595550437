/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  DateDuringContratDto,
  DisplayNameDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
  TuteurEditDto,
  TuteurGridDtoPaginatedResults,
  TuteurHistoryGridDtoPaginatedResults,
  TuteurHistorySearch,
  TuteurLieuDoublonGridDtoPaginatedResults,
  TuteurLieuDoublonSearchDto,
  TuteurLieuFormationGridDtoPaginatedResults,
  TuteurLieuFormationSearchDto,
  TuteurLieuGridDtoPaginatedResults,
  TuteurSearchDto,
  TuteurSelectorGridDtoPaginatedResults,
  TuteurSelectorSearch,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    DateDuringContratDtoFromJSON,
    DateDuringContratDtoToJSON,
    DisplayNameDtoFromJSON,
    DisplayNameDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
    TuteurEditDtoFromJSON,
    TuteurEditDtoToJSON,
    TuteurGridDtoPaginatedResultsFromJSON,
    TuteurGridDtoPaginatedResultsToJSON,
    TuteurHistoryGridDtoPaginatedResultsFromJSON,
    TuteurHistoryGridDtoPaginatedResultsToJSON,
    TuteurHistorySearchFromJSON,
    TuteurHistorySearchToJSON,
    TuteurLieuDoublonGridDtoPaginatedResultsFromJSON,
    TuteurLieuDoublonGridDtoPaginatedResultsToJSON,
    TuteurLieuDoublonSearchDtoFromJSON,
    TuteurLieuDoublonSearchDtoToJSON,
    TuteurLieuFormationGridDtoPaginatedResultsFromJSON,
    TuteurLieuFormationGridDtoPaginatedResultsToJSON,
    TuteurLieuFormationSearchDtoFromJSON,
    TuteurLieuFormationSearchDtoToJSON,
    TuteurLieuGridDtoPaginatedResultsFromJSON,
    TuteurLieuGridDtoPaginatedResultsToJSON,
    TuteurSearchDtoFromJSON,
    TuteurSearchDtoToJSON,
    TuteurSelectorGridDtoPaginatedResultsFromJSON,
    TuteurSelectorGridDtoPaginatedResultsToJSON,
    TuteurSelectorSearchFromJSON,
    TuteurSelectorSearchToJSON,
} from '../models/index';

export interface TuteurBaseSearchRequest {
    TuteurSearchDto?: TuteurSearchDto;
}

export interface TuteurDeleteTuteurLieuRequest {
    IdtuteurLieu?: number;
}

export interface TuteurGetDisplayNameRequest {
    id?: number;
}

export interface TuteurGetDisplayNamesRequest {
    request_body?: Array<number>;
}

export interface TuteurGetIdPersonneRequest {
    idtuteur?: number;
}

export interface TuteurGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface TuteurGetSelectItemsRequest {
    searchField: string;
}

export interface TuteurGetTuteurRequest {
    idtuteur?: number;
}

export interface TuteurGetTuteurLieuRequest {
    IdtuteurLieu?: number;
}

export interface TuteurGetTuteurLieuByIdsRequest {
    idtuteur?: number;
    idlieuFormation?: number;
}

export interface TuteurIsActifLieuRequest {
    idtuteur?: number;
    idlieuFormation?: number;
}

export interface TuteurIsCapaciteForfmativeAtteinteRequest {
    idtuteur?: number;
    idlieuFormation?: number;
}

export interface TuteurIsDateDuringContratRequest {
    DateDuringContratDto?: DateDuringContratDto;
}

export interface TuteurIsSppRequest {
    idtuteur?: number;
}

export interface TuteurSaveTuteurRequest {
    TuteurEditDto?: TuteurEditDto;
}

export interface TuteurSaveTuteurLieuRequest {
    TuteurEditDto?: TuteurEditDto;
}

export interface TuteurSearchDoublonsLieuRequest {
    TuteurLieuDoublonSearchDto?: TuteurLieuDoublonSearchDto;
}

export interface TuteurSearchHistoryRequest {
    TuteurHistorySearch?: TuteurHistorySearch;
}

export interface TuteurSearchSelectorRequest {
    TuteurSelectorSearch?: TuteurSelectorSearch;
}

export interface TuteurSearchTuteurIdsRequest {
    TuteurSearchDto?: TuteurSearchDto;
}

export interface TuteurSearchTuteurLieuFormationRequest {
    TuteurLieuFormationSearchDto?: TuteurLieuFormationSearchDto;
}

export interface TuteurSearchTuteursLieuRequest {
    TuteurSearchDto?: TuteurSearchDto;
}

/**
 * 
 */
export class TuteurApi extends runtime.BaseAPI {

    /**
     */
    async tuteurBaseSearchRaw(requestParameters: TuteurBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TuteurGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TuteurSearchDtoToJSON(requestParameters.TuteurSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TuteurGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async tuteurBaseSearch(requestParameters: TuteurBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TuteurGridDtoPaginatedResults> {
        const response = await this.tuteurBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tuteurDeleteTuteurLieuRaw(requestParameters: TuteurDeleteTuteurLieuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.IdtuteurLieu !== undefined) {
            queryParameters['IdtuteurLieu'] = requestParameters.IdtuteurLieu;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur/DeleteTuteurLieu`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async tuteurDeleteTuteurLieu(requestParameters: TuteurDeleteTuteurLieuRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.tuteurDeleteTuteurLieuRaw(requestParameters, initOverrides);
    }

    /**
     */
    async tuteurGetDisplayNameRaw(requestParameters: TuteurGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async tuteurGetDisplayName(requestParameters: TuteurGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.tuteurGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tuteurGetDisplayNamesRaw(requestParameters: TuteurGetDisplayNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DisplayNameDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur/GetDisplayNames`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request_body,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DisplayNameDtoFromJSON));
    }

    /**
     */
    async tuteurGetDisplayNames(requestParameters: TuteurGetDisplayNamesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DisplayNameDto>> {
        const response = await this.tuteurGetDisplayNamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tuteurGetIdPersonneRaw(requestParameters: TuteurGetIdPersonneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.idtuteur !== undefined) {
            queryParameters['idtuteur'] = requestParameters.idtuteur;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur/GetIdPersonne`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async tuteurGetIdPersonne(requestParameters: TuteurGetIdPersonneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.tuteurGetIdPersonneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tuteurGetSearchCriteriasRaw(requestParameters: TuteurGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async tuteurGetSearchCriterias(requestParameters: TuteurGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.tuteurGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tuteurGetSelectItemsRaw(requestParameters: TuteurGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling tuteurGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async tuteurGetSelectItems(requestParameters: TuteurGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.tuteurGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tuteurGetTuteurRaw(requestParameters: TuteurGetTuteurRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TuteurEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.idtuteur !== undefined) {
            queryParameters['idtuteur'] = requestParameters.idtuteur;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TuteurEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async tuteurGetTuteur(requestParameters: TuteurGetTuteurRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TuteurEditDto> {
        const response = await this.tuteurGetTuteurRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tuteurGetTuteurLieuRaw(requestParameters: TuteurGetTuteurLieuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TuteurEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.IdtuteurLieu !== undefined) {
            queryParameters['IdtuteurLieu'] = requestParameters.IdtuteurLieu;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur/GetTuteurLieu`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TuteurEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async tuteurGetTuteurLieu(requestParameters: TuteurGetTuteurLieuRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TuteurEditDto> {
        const response = await this.tuteurGetTuteurLieuRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tuteurGetTuteurLieuByIdsRaw(requestParameters: TuteurGetTuteurLieuByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TuteurEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.idtuteur !== undefined) {
            queryParameters['idtuteur'] = requestParameters.idtuteur;
        }

        if (requestParameters.idlieuFormation !== undefined) {
            queryParameters['idlieuFormation'] = requestParameters.idlieuFormation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur/GetTuteurLieuByIds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TuteurEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async tuteurGetTuteurLieuByIds(requestParameters: TuteurGetTuteurLieuByIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TuteurEditDto> {
        const response = await this.tuteurGetTuteurLieuByIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tuteurIsActifLieuRaw(requestParameters: TuteurIsActifLieuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idtuteur !== undefined) {
            queryParameters['idtuteur'] = requestParameters.idtuteur;
        }

        if (requestParameters.idlieuFormation !== undefined) {
            queryParameters['idlieuFormation'] = requestParameters.idlieuFormation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur/IsActifLieu`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async tuteurIsActifLieu(requestParameters: TuteurIsActifLieuRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.tuteurIsActifLieuRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tuteurIsCapaciteForfmativeAtteinteRaw(requestParameters: TuteurIsCapaciteForfmativeAtteinteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idtuteur !== undefined) {
            queryParameters['idtuteur'] = requestParameters.idtuteur;
        }

        if (requestParameters.idlieuFormation !== undefined) {
            queryParameters['idlieuFormation'] = requestParameters.idlieuFormation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur/IsCapaciteForfmativeAtteinte`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async tuteurIsCapaciteForfmativeAtteinte(requestParameters: TuteurIsCapaciteForfmativeAtteinteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.tuteurIsCapaciteForfmativeAtteinteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tuteurIsDateDuringContratRaw(requestParameters: TuteurIsDateDuringContratRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur/IsDateDuringContrat`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DateDuringContratDtoToJSON(requestParameters.DateDuringContratDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async tuteurIsDateDuringContrat(requestParameters: TuteurIsDateDuringContratRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.tuteurIsDateDuringContratRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tuteurIsSppRaw(requestParameters: TuteurIsSppRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idtuteur !== undefined) {
            queryParameters['idtuteur'] = requestParameters.idtuteur;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur/IsSpp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async tuteurIsSpp(requestParameters: TuteurIsSppRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.tuteurIsSppRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tuteurSaveTuteurRaw(requestParameters: TuteurSaveTuteurRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TuteurEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TuteurEditDtoToJSON(requestParameters.TuteurEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TuteurEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async tuteurSaveTuteur(requestParameters: TuteurSaveTuteurRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TuteurEditDto> {
        const response = await this.tuteurSaveTuteurRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tuteurSaveTuteurLieuRaw(requestParameters: TuteurSaveTuteurLieuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TuteurEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur/SaveTuteurLieu`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TuteurEditDtoToJSON(requestParameters.TuteurEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TuteurEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async tuteurSaveTuteurLieu(requestParameters: TuteurSaveTuteurLieuRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TuteurEditDto> {
        const response = await this.tuteurSaveTuteurLieuRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tuteurSearchDoublonsLieuRaw(requestParameters: TuteurSearchDoublonsLieuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TuteurLieuDoublonGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur/SearchDoublonsLieu`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TuteurLieuDoublonSearchDtoToJSON(requestParameters.TuteurLieuDoublonSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TuteurLieuDoublonGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async tuteurSearchDoublonsLieu(requestParameters: TuteurSearchDoublonsLieuRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TuteurLieuDoublonGridDtoPaginatedResults> {
        const response = await this.tuteurSearchDoublonsLieuRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tuteurSearchHistoryRaw(requestParameters: TuteurSearchHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TuteurHistoryGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur/SearchHistory`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TuteurHistorySearchToJSON(requestParameters.TuteurHistorySearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TuteurHistoryGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async tuteurSearchHistory(requestParameters: TuteurSearchHistoryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TuteurHistoryGridDtoPaginatedResults> {
        const response = await this.tuteurSearchHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tuteurSearchSelectorRaw(requestParameters: TuteurSearchSelectorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TuteurSelectorGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur/SearchSelector`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TuteurSelectorSearchToJSON(requestParameters.TuteurSelectorSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TuteurSelectorGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async tuteurSearchSelector(requestParameters: TuteurSearchSelectorRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TuteurSelectorGridDtoPaginatedResults> {
        const response = await this.tuteurSearchSelectorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tuteurSearchTuteurIdsRaw(requestParameters: TuteurSearchTuteurIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur/SearchTuteurIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TuteurSearchDtoToJSON(requestParameters.TuteurSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async tuteurSearchTuteurIds(requestParameters: TuteurSearchTuteurIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.tuteurSearchTuteurIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tuteurSearchTuteurLieuFormationRaw(requestParameters: TuteurSearchTuteurLieuFormationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TuteurLieuFormationGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur/SearchTuteurLieuFormation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TuteurLieuFormationSearchDtoToJSON(requestParameters.TuteurLieuFormationSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TuteurLieuFormationGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async tuteurSearchTuteurLieuFormation(requestParameters: TuteurSearchTuteurLieuFormationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TuteurLieuFormationGridDtoPaginatedResults> {
        const response = await this.tuteurSearchTuteurLieuFormationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tuteurSearchTuteursLieuRaw(requestParameters: TuteurSearchTuteursLieuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TuteurLieuGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Tuteur/SearchTuteursLieu`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TuteurSearchDtoToJSON(requestParameters.TuteurSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TuteurLieuGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async tuteurSearchTuteursLieu(requestParameters: TuteurSearchTuteursLieuRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TuteurLieuGridDtoPaginatedResults> {
        const response = await this.tuteurSearchTuteursLieuRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
