/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbTypeEnseignementDto
 */
export interface FcbTypeEnseignementDto {
    /**
     * 
     * @type {number}
     * @memberof FcbTypeEnseignementDto
     */
    idtypeEnseignement?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbTypeEnseignementDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbTypeEnseignementDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbTypeEnseignementDto interface.
 */
export function instanceOfFcbTypeEnseignementDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbTypeEnseignementDtoFromJSON(json: any): FcbTypeEnseignementDto {
    return FcbTypeEnseignementDtoFromJSONTyped(json, false);
}

export function FcbTypeEnseignementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbTypeEnseignementDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idtypeEnseignement': !exists(json, 'idtypeEnseignement') ? undefined : json['idtypeEnseignement'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbTypeEnseignementDtoToJSON(value?: FcbTypeEnseignementDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idtypeEnseignement': value.idtypeEnseignement,
        'libelle': value.libelle,
        'actif': value.actif,
    };
}

