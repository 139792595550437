/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRecherchecalendrierVisiteDto
 */
export interface FcbRecherchecalendrierVisiteDto {
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchecalendrierVisiteDto
     */
    idvisite?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchecalendrierVisiteDto
     */
    iduser?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchecalendrierVisiteDto
     */
    idcentre?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchecalendrierVisiteDto
     */
    visite?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRecherchecalendrierVisiteDto
     */
    datevisiteDebut?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchecalendrierVisiteDto
     */
    datevisiteFin?: string | null;
}

/**
 * Check if a given object implements the FcbRecherchecalendrierVisiteDto interface.
 */
export function instanceOfFcbRecherchecalendrierVisiteDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRecherchecalendrierVisiteDtoFromJSON(json: any): FcbRecherchecalendrierVisiteDto {
    return FcbRecherchecalendrierVisiteDtoFromJSONTyped(json, false);
}

export function FcbRecherchecalendrierVisiteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRecherchecalendrierVisiteDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idvisite': !exists(json, 'idvisite') ? undefined : json['idvisite'],
        'iduser': !exists(json, 'iduser') ? undefined : json['iduser'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'visite': !exists(json, 'visite') ? undefined : json['visite'],
        'datevisiteDebut': !exists(json, 'datevisiteDebut') ? undefined : (json['datevisiteDebut'] === null ? null : new Date(json['datevisiteDebut'])),
        'datevisiteFin': !exists(json, 'datevisiteFin') ? undefined : json['datevisiteFin'],
    };
}

export function FcbRecherchecalendrierVisiteDtoToJSON(value?: FcbRecherchecalendrierVisiteDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idvisite': value.idvisite,
        'iduser': value.iduser,
        'idcentre': value.idcentre,
        'visite': value.visite,
        'datevisiteDebut': value.datevisiteDebut === undefined ? undefined : (value.datevisiteDebut === null ? null : value.datevisiteDebut.toISOString()),
        'datevisiteFin': value.datevisiteFin,
    };
}

