/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbExamenEntreeGridDto
 */
export interface FcbExamenEntreeGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbExamenEntreeGridDto
     */
    idexamenEntree?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbExamenEntreeGridDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbExamenEntreeGridDto
     */
    pointsmath?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbExamenEntreeGridDto
     */
    pointsfrancais?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbExamenEntreeGridDto
     */
    pointslogique?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbExamenEntreeGridDto
     */
    annee?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbExamenEntreeGridDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbExamenEntreeGridDto
     */
    reussite?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbExamenEntreeGridDto
     */
    dateExamen?: Date | null;
}

/**
 * Check if a given object implements the FcbExamenEntreeGridDto interface.
 */
export function instanceOfFcbExamenEntreeGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbExamenEntreeGridDtoFromJSON(json: any): FcbExamenEntreeGridDto {
    return FcbExamenEntreeGridDtoFromJSONTyped(json, false);
}

export function FcbExamenEntreeGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbExamenEntreeGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idexamenEntree': !exists(json, 'idexamenEntree') ? undefined : json['idexamenEntree'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'pointsmath': !exists(json, 'pointsmath') ? undefined : json['pointsmath'],
        'pointsfrancais': !exists(json, 'pointsfrancais') ? undefined : json['pointsfrancais'],
        'pointslogique': !exists(json, 'pointslogique') ? undefined : json['pointslogique'],
        'annee': !exists(json, 'annee') ? undefined : json['annee'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'reussite': !exists(json, 'reussite') ? undefined : json['reussite'],
        'dateExamen': !exists(json, 'dateExamen') ? undefined : (json['dateExamen'] === null ? null : new Date(json['dateExamen'])),
    };
}

export function FcbExamenEntreeGridDtoToJSON(value?: FcbExamenEntreeGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idexamenEntree': value.idexamenEntree,
        'idapprenant': value.idapprenant,
        'pointsmath': value.pointsmath,
        'pointsfrancais': value.pointsfrancais,
        'pointslogique': value.pointslogique,
        'annee': value.annee,
        'remarque': value.remarque,
        'reussite': value.reussite,
        'dateExamen': value.dateExamen === undefined ? undefined : (value.dateExamen === null ? null : value.dateExamen.toISOString()),
    };
}

