import { Button, Divider } from "@blueprintjs/core";
import { FGMaskInput, FGNumberInput, FGTextAreaInput, FGTextInput, FieldGroup, useFGContext } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";

import { ContratApi, ContratEditDto } from "../../../../../../api";
import {
  AddButton,
  ChefEntrepriseSelectorField,
  CommuneSelect,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  LocaliteSelect,
  SiegeSocialSelectorField
} from "../../../../../../components";
import FGCopyTextInput from "../../../../../../components/formGenerator/FGCopyTextInput";
import { useApiService, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

export interface IStageObservationPanelProps {}

export const StageObservationPanel: React.FunctionComponent<IStageObservationPanelProps> = props => {
  const { t } = useTl();
  const { formik } = useFGContext<ContratEditDto>();
  const [siegeSocialCreateMode, setSiegeSocialCreateMode] = React.useState(false);
  const [chefEntrepriseCreateMode, setChefEntrepriseCreateMode] = React.useState(false);

  const [typesEntreprise, loadingTypesEntreprise, , rawTypesEntreprise] = useReferential(
    a => a.referentialGetTypesEntreprise(),
    true,
    []
  );
  const [formesJuridiques, loadingFormesJuridiques] = useReferential(a => a.referentialGetFormesJuridiques(), true, []);
  const [pays, pLoading] = useReferential(a => a.referentialGetPays());

  const showFormeJuridique = React.useCallback(
    () =>
      rawTypesEntreprise?.find(
        te => +te.idValue === +formik.values?.contratStageObservationDto?.siegeSocial?.idtypeEntreprise
      )?.keyValue === "MORALE",
    [formik.values?.contratStageObservationDto?.siegeSocial?.idtypeEntreprise, rawTypesEntreprise]
  );

  const anneesFormation = React.useMemo(
    () => [
      { value: 1, label: "1" },
      { value: 2, label: "2" },
      { value: 3, label: "3" }
    ],
    []
  );

  const api = useApiService(ContratApi);
  const fetchSiegeSocial = React.useCallback(() => {
    if (!formik.values?.contratStageObservationDto?.idsiegeSocial) return null;
    return api.contratGetSiegeSocialInfos({ idsiegeSocial: formik.values?.contratStageObservationDto?.idsiegeSocial });
  }, [api, formik.values?.contratStageObservationDto?.idsiegeSocial]);
  const { data: siegeSocial } = useQuery(
    ["siege-social-contrat", formik.values?.contratStageObservationDto?.idsiegeSocial],
    fetchSiegeSocial
  );

  React.useEffect(() => {
    if (
      !!siegeSocial &&
      siegeSocial?.idsiegeSocial !== formik.values?.contratStageObservationDto?.siegeSocial?.idsiegeSocial
    ) {
      formik.setFieldValue("contratStageObservationDto.siegeSocial", siegeSocial);
    }
  }, [formik, siegeSocial]);

  React.useEffect(() => {
    if (!formik.dirty) {
      setSiegeSocialCreateMode(false);
      setChefEntrepriseCreateMode(false);
    }
  }, [formik.dirty]);

  return (
    <>
      <FieldGroup columns={2}>
        <FieldGroup>
          <FGWalterDateMaskInput name="dateDebut" label={t(ETLCodes.DateDebut)} />
          <FGWalterDateMaskInput name="dateFin" label={t(ETLCodes.DateFin)} />
        </FieldGroup>
      </FieldGroup>
      <FieldGroup
        columns={2}
        collapsable
        fieldsetProps={{
          title: t(ETLCodes.SiegeSocial)
        }}
      >
        <FieldGroup>
          <SiegeSocialSelectorField
            label={t(ETLCodes.SiegeSocial)}
            name="contratStageObservationDto.idsiegeSocial"
            canAdd={false}
            visible={!siegeSocialCreateMode}
            rightElement={
              <AddButton
                minimal
                onClick={() => {
                  setSiegeSocialCreateMode(true);
                  formik.setFieldValue("contratStageObservationDto.idsiegeSocial", undefined);
                  formik.setFieldValue("contratStageObservationDto.siegeSocial", undefined);
                }}
              />
            }
          />
          <FGTextInput
            name="contratStageObservationDto.siegeSocial.denominationSociale"
            label={t(ETLCodes.DenominationSociale)}
            maxLength={50}
            visible={siegeSocialCreateMode}
            rightElement={
              <Button
                minimal
                intent="primary"
                icon="undo"
                onClick={() => {
                  setSiegeSocialCreateMode(false);
                  formik.setFieldValue("contratStageObservationDto.siegeSocial", undefined);
                }}
              />
            }
          />
          <FGMaskInput
            name="contratStageObservationDto.siegeSocial.numeroBce"
            label={t(ETLCodes.NumeroBce)}
            cleaveOptions={{
              blocks: [4, 3, 3],
              delimiter: "."
            }}
            readonly={!siegeSocialCreateMode}
          />
          <FGWalterSelectInput
            name="contratStageObservationDto.siegeSocial.idtypeEntreprise"
            label={t(ETLCodes.TypeEntreprise)}
            items={typesEntreprise}
            loading={loadingTypesEntreprise}
            readonly={!siegeSocialCreateMode}
          />
          <FGWalterSelectInput
            name="contratStageObservationDto.siegeSocial.idformeJuridique"
            label={t(ETLCodes.FormeJuridique)}
            items={formesJuridiques}
            loading={loadingFormesJuridiques}
            visible={showFormeJuridique}
            readonly={!siegeSocialCreateMode}
          />
          <FGTextAreaInput
            labelStyles={{ minWidth: 100 }}
            name="contratStageObservationDto.siegeSocial.adresse"
            label={t(ETLCodes.Adresse)}
            readonly={!siegeSocialCreateMode}
          />
          <FGTextInput
            labelStyles={{ minWidth: 100 }}
            name="contratStageObservationDto.siegeSocial.codePostalText"
            label={t(ETLCodes.CodePostal)}
            maxLength={6}
            readonly={!siegeSocialCreateMode}
          />
          <LocaliteSelect
            labelStyles={{ minWidth: 100 }}
            codePostal={formik?.values?.contratStageObservationDto?.siegeSocial?.codePostalText}
            codePays={formik?.values?.contratStageObservationDto?.siegeSocial?.pays}
            name="contratStageObservationDto.siegeSocial.localite"
            codePostalTextName="contratStageObservationDto.siegeSocial.codePostalText"
            disableIfNoCp={false}
            readonly={!siegeSocialCreateMode}
          />
          <CommuneSelect
            labelStyles={{ minWidth: 100 }}
            codePostal={formik?.values?.contratStageObservationDto?.siegeSocial?.codePostalText}
            codePays={formik?.values?.contratStageObservationDto?.siegeSocial?.pays}
            name="contratStageObservationDto.siegeSocial.commune"
            readonly={!siegeSocialCreateMode}
          />
          <FGWalterSelectInput
            labelStyles={{ minWidth: 100 }}
            name="contratStageObservationDto.siegeSocial.pays"
            label={t(ETLCodes.Pays)}
            items={pays}
            loading={pLoading}
            readonly={!siegeSocialCreateMode}
          />
          <ChefEntrepriseSelectorField
            label={t(ETLCodes.ChefEntreprise)}
            name="contratStageObservationDto.idchefEntreprise"
            idsiegeSocial={formik?.values?.contratStageObservationDto?.idsiegeSocial}
            filterOnSiegeSocial
            canAdd={false}
            visible={!chefEntrepriseCreateMode}
            rightElement={
              <AddButton
                minimal
                onClick={() => {
                  setChefEntrepriseCreateMode(true);
                  formik.setFieldValue("contratStageObservationDto.idchefEntreprise", undefined);
                  formik.setFieldValue("contratStageObservationDto.chefEntreprise", undefined);
                }}
              />
            }
          />
          <FGTextInput
            name="contratStageObservationDto.chefEntreprise.nom"
            label={t(ETLCodes.NomChefEntreprise)}
            maxLength={50}
            forceCase="upper"
            visible={chefEntrepriseCreateMode}
            rightElement={
              <Button
                minimal
                intent="primary"
                icon="undo"
                onClick={() => {
                  setChefEntrepriseCreateMode(false);
                  formik.setFieldValue("contratStageObservationDto.chefEntreprise", null);
                }}
              />
            }
          />
          <FGTextInput
            name="contratStageObservationDto.chefEntreprise.prenom"
            label={t(ETLCodes.PrenomChefEntreprise)}
            maxLength={100}
            visible={chefEntrepriseCreateMode}
          />
          <FGTextInput name="contratStageObservationDto.fonction" label={t(ETLCodes.Fonction)} />
          <Divider />
          <FGTextInput name="contratStageObservationDto.maitreStage" label={t(ETLCodes.MaitreStage)} />
          <FGTextInput name="contratStageObservationDto.fonctionMaitreStage" label={t(ETLCodes.Fonction)} />
          <FGNumberInput name="contratStageObservationDto.dureeExpPro" label={t(ETLCodes.DureeExperiencePro)} />
          <FGNumberInput name="contratStageObservationDto.age" label={t(ETLCodes.Age)} />
          <FGCopyTextInput
            copyOnlyDigits={true}
            name="contratStageObservationDto.numero"
            label={t(ETLCodes.GsmTel)}
            maxLength={50}
          />
        </FieldGroup>
      </FieldGroup>
      <FieldGroup
        columns={2}
        collapsable
        fieldsetProps={{
          title: t(ETLCodes.DonneesJoursHeures)
        }}
      >
        <FieldGroup>
          <FGNumberInput name="contratStageObservationDto.nbMaxHeures" label={t(ETLCodes.NbMaxHeures)} />
          <FGWalterSelectInput
            name="contratStageObservationDto.anneeFormation"
            label={t(ETLCodes.AnneeFormation)}
            items={anneesFormation}
          />
          <FGNumberInput name="contratStageObservationDto.nbMinHeures" label={t(ETLCodes.NbMinHeures)} />
          <FGNumberInput name="contratStageObservationDto.nbHeuresPrevues" label={t(ETLCodes.NbHeuresPrevues)} />
          <FGNumberInput name="contratStageObservationDto.nbHeuresPrestees" label={t(ETLCodes.NbHeuresPrestees)} />
        </FieldGroup>
      </FieldGroup>
    </>
  );
};
