/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbCentreDto
 */
export interface FcbCentreDto {
    /**
     * 
     * @type {number}
     * @memberof FcbCentreDto
     */
    idcentre?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    codePays?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    code?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbCentreDto
     */
    iddirectionTerritoriale?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    adresse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    codePostal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    commune?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    telApp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    telCe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    telFco?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    telCentral?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    telAutre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    faxApp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    faxCe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    faxFo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    faxAutre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    website?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    president?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    directeur?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbCentreDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbCentreDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbCentreDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbCentreDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    idcentreGuid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCentreDto
     */
    signatureSms?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbCentreDto
     */
    identite?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbCentreDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbCentreDto
     */
    exterieur?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbCentreDto
     */
    iddirecteur?: number | null;
}

/**
 * Check if a given object implements the FcbCentreDto interface.
 */
export function instanceOfFcbCentreDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbCentreDtoFromJSON(json: any): FcbCentreDto {
    return FcbCentreDtoFromJSONTyped(json, false);
}

export function FcbCentreDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbCentreDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'codePays': !exists(json, 'codePays') ? undefined : json['codePays'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'iddirectionTerritoriale': !exists(json, 'iddirectionTerritoriale') ? undefined : json['iddirectionTerritoriale'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'adresse': !exists(json, 'adresse') ? undefined : json['adresse'],
        'codePostal': !exists(json, 'codePostal') ? undefined : json['codePostal'],
        'commune': !exists(json, 'commune') ? undefined : json['commune'],
        'telApp': !exists(json, 'telApp') ? undefined : json['telApp'],
        'telCe': !exists(json, 'telCe') ? undefined : json['telCe'],
        'telFco': !exists(json, 'telFco') ? undefined : json['telFco'],
        'telCentral': !exists(json, 'telCentral') ? undefined : json['telCentral'],
        'telAutre': !exists(json, 'telAutre') ? undefined : json['telAutre'],
        'faxApp': !exists(json, 'faxApp') ? undefined : json['faxApp'],
        'faxCe': !exists(json, 'faxCe') ? undefined : json['faxCe'],
        'faxFo': !exists(json, 'faxFo') ? undefined : json['faxFo'],
        'faxAutre': !exists(json, 'faxAutre') ? undefined : json['faxAutre'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'president': !exists(json, 'president') ? undefined : json['president'],
        'directeur': !exists(json, 'directeur') ? undefined : json['directeur'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'idcentreGuid': !exists(json, 'idcentreGuid') ? undefined : json['idcentreGuid'],
        'signatureSms': !exists(json, 'signatureSms') ? undefined : json['signatureSms'],
        'identite': !exists(json, 'identite') ? undefined : json['identite'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'exterieur': !exists(json, 'exterieur') ? undefined : json['exterieur'],
        'iddirecteur': !exists(json, 'iddirecteur') ? undefined : json['iddirecteur'],
    };
}

export function FcbCentreDtoToJSON(value?: FcbCentreDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcentre': value.idcentre,
        'codePays': value.codePays,
        'code': value.code,
        'iddirectionTerritoriale': value.iddirectionTerritoriale,
        'localite': value.localite,
        'nom': value.nom,
        'adresse': value.adresse,
        'codePostal': value.codePostal,
        'commune': value.commune,
        'telApp': value.telApp,
        'telCe': value.telCe,
        'telFco': value.telFco,
        'telCentral': value.telCentral,
        'telAutre': value.telAutre,
        'faxApp': value.faxApp,
        'faxCe': value.faxCe,
        'faxFo': value.faxFo,
        'faxAutre': value.faxAutre,
        'email': value.email,
        'website': value.website,
        'url': value.url,
        'president': value.president,
        'directeur': value.directeur,
        'creationUser': value.creationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'idcentreGuid': value.idcentreGuid,
        'signatureSms': value.signatureSms,
        'identite': value.identite,
        'actif': value.actif,
        'exterieur': value.exterieur,
        'iddirecteur': value.iddirecteur,
    };
}

