import * as React from "react";
import { useHistory } from "react-router";

import { LocalApi, LocalSearch, FcbLocalGridDto, EBooleanSearchTypes } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { ButtonContainer } from "nsitools-react";

interface ILocalListPageProps {}

export const LocalListPage: React.FunctionComponent<ILocalListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(LocalApi);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbLocalGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => history.push(`${ERoutes.local}/${row.idlocal}/detail/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${ERoutes.local}/${row.idlocal}/detail/edit`)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code"
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      },
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "centre"
      },
      {
        header: () => t(ETLCodes.Lieu),
        fieldName: "lieu"
      }
    ],
    [history, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.local}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(() => api.localGetSearchCriterias({ includeListsValues: true }), [api]);

  const apiFactory = useAbortableApiServiceFactory(LocalApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: LocalSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.localBaseSearch({ LocalSearch: sObj });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.Locaux), route: ERoutes.local }]}
      addFunc={onAddItem}
      sortKeys={{ code: "ASC" }}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
    ></SearchTablePage>
  );
};
