import { Checkbox } from "@blueprintjs/core";
import { DataTable, FieldSet, IDataTableColumn, useGridState } from "nsitools-react";
import * as React from "react";
import { RapportHopeAccesPermissionDto, RapportHopeApi } from "../../../../../../api";
import { useApiService, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

export interface IRapportHopeAccesPermissionsGridProps {
  idrapportHope: number;
  acces: RapportHopeAccesPermissionDto[];
  loading: boolean;
}

export const RapportHopeAccesPermissionsGrid: React.FunctionComponent<IRapportHopeAccesPermissionsGridProps> = ({
  acces,
  idrapportHope,
  loading
}) => {
  const { t } = useTl();
  const api = useApiService(RapportHopeApi);

  const tableState = useGridState<RapportHopeAccesPermissionDto>({
    serverMode: false,
    enablePagination: false,
    enableFilter: false
  });

  const { setData } = tableState;

  React.useEffect(() => {
    setData(acces);
  }, [acces, setData]);

  const toggleSelection = React.useCallback(
    (row: RapportHopeAccesPermissionDto) => {
      row.selected = !row.selected;
      row.idrapportHope = idrapportHope;
      let nextData = [...acces];
      setData(nextData);
      api.rapportHopeSaveAccesPermissions({ RapportHopeAccesPermissionDto: row });
    },
    [acces, api, idrapportHope, setData]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        render: (item: RapportHopeAccesPermissionDto) => (
          <Checkbox
            checked={item.selected}
            onChange={e => {
              toggleSelection(item);
            }}
          />
        )
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      }
    ],
    [t, toggleSelection]
  );

  return (
    <FieldSet title={t(ETLCodes.Acces)} collapsable defaultCollapsed>
      <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
    </FieldSet>
  );
};
