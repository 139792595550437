/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SystemInfoDto
 */
export interface SystemInfoDto {
    /**
     * 
     * @type {string}
     * @memberof SystemInfoDto
     */
    applicationVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SystemInfoDto
     */
    beIDVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SystemInfoDto
     */
    environment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SystemInfoDto
     */
    database?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SystemInfoDto
     */
    walterUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SystemInfoDto
     */
    hopeUrl?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SystemInfoDto
     */
    isTestEnv?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemInfoDto
     */
    enableNextFeatures?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SystemInfoDto
     */
    ssrsReportServerUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SystemInfoDto
     */
    applicationWideWarning?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SystemInfoDto
     */
    manuelUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SystemInfoDto
     */
    priseEnMainUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SystemInfoDto
     */
    arWalterManuelUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SystemInfoDto
     */
    arHopeManuelUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SystemInfoDto
     */
    printingQueueWalterManuelUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SystemInfoDto
     */
    walterStartupPopupText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SystemInfoDto
     */
    hopeStartupPopupText?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SystemInfoDto
     */
    startupMessageEndDate?: Date;
}

/**
 * Check if a given object implements the SystemInfoDto interface.
 */
export function instanceOfSystemInfoDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SystemInfoDtoFromJSON(json: any): SystemInfoDto {
    return SystemInfoDtoFromJSONTyped(json, false);
}

export function SystemInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationVersion': !exists(json, 'applicationVersion') ? undefined : json['applicationVersion'],
        'beIDVersion': !exists(json, 'beIDVersion') ? undefined : json['beIDVersion'],
        'environment': !exists(json, 'environment') ? undefined : json['environment'],
        'database': !exists(json, 'database') ? undefined : json['database'],
        'walterUrl': !exists(json, 'walterUrl') ? undefined : json['walterUrl'],
        'hopeUrl': !exists(json, 'hopeUrl') ? undefined : json['hopeUrl'],
        'isTestEnv': !exists(json, 'isTestEnv') ? undefined : json['isTestEnv'],
        'enableNextFeatures': !exists(json, 'enableNextFeatures') ? undefined : json['enableNextFeatures'],
        'ssrsReportServerUrl': !exists(json, 'ssrsReportServerUrl') ? undefined : json['ssrsReportServerUrl'],
        'applicationWideWarning': !exists(json, 'applicationWideWarning') ? undefined : json['applicationWideWarning'],
        'manuelUrl': !exists(json, 'manuelUrl') ? undefined : json['manuelUrl'],
        'priseEnMainUrl': !exists(json, 'priseEnMainUrl') ? undefined : json['priseEnMainUrl'],
        'arWalterManuelUrl': !exists(json, 'arWalterManuelUrl') ? undefined : json['arWalterManuelUrl'],
        'arHopeManuelUrl': !exists(json, 'arHopeManuelUrl') ? undefined : json['arHopeManuelUrl'],
        'printingQueueWalterManuelUrl': !exists(json, 'printingQueueWalterManuelUrl') ? undefined : json['printingQueueWalterManuelUrl'],
        'walterStartupPopupText': !exists(json, 'walterStartupPopupText') ? undefined : json['walterStartupPopupText'],
        'hopeStartupPopupText': !exists(json, 'hopeStartupPopupText') ? undefined : json['hopeStartupPopupText'],
        'startupMessageEndDate': !exists(json, 'startupMessageEndDate') ? undefined : (new Date(json['startupMessageEndDate'])),
    };
}

export function SystemInfoDtoToJSON(value?: SystemInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationVersion': value.applicationVersion,
        'beIDVersion': value.beIDVersion,
        'environment': value.environment,
        'database': value.database,
        'walterUrl': value.walterUrl,
        'hopeUrl': value.hopeUrl,
        'isTestEnv': value.isTestEnv,
        'enableNextFeatures': value.enableNextFeatures,
        'ssrsReportServerUrl': value.ssrsReportServerUrl,
        'applicationWideWarning': value.applicationWideWarning,
        'manuelUrl': value.manuelUrl,
        'priseEnMainUrl': value.priseEnMainUrl,
        'arWalterManuelUrl': value.arWalterManuelUrl,
        'arHopeManuelUrl': value.arHopeManuelUrl,
        'printingQueueWalterManuelUrl': value.printingQueueWalterManuelUrl,
        'walterStartupPopupText': value.walterStartupPopupText,
        'hopeStartupPopupText': value.hopeStartupPopupText,
        'startupMessageEndDate': value.startupMessageEndDate === undefined ? undefined : (value.startupMessageEndDate.toISOString()),
    };
}

