import { FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { FcbVisiteDtoFromJSON, VisiteApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import {
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";
import { ETLCodes } from "../../../../locales";

export interface IVisiteInformationPageProps {
  idVisite: number;
  editMode: boolean;
}

export const VisiteInformationPage: React.FunctionComponent<IVisiteInformationPageProps> = ({ idVisite, editMode }) => {
  const { t } = useTl();
  const api = useApiService(VisiteApi);
  const history = useHistory();

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idcentre: Yup.number().required(t(ETLCodes.Required)),
      datevisiteDebut: Yup.date().required(t(ETLCodes.Required))
    });
  }, [t]);

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () => (idVisite <= 0 ? FcbVisiteDtoFromJSON({}) : api.visiteGet({ id: idVisite })),
        saveApiFn: d => api.visiteSave({ FcbVisiteDto: d }),
        onSavedRoute: d => `${ERoutes.visiteExterieure}/${d.idvisite}/information/edit`,
        deleteApiFn: d => api.visiteDelete({ id: d.idvisite }),
        onDeletedRoute: () => ERoutes.visiteExterieure
      }),
      [api, idVisite]
    )
  );
  const [centres, cLoading] = useReferential(a => a.referentialGetCentre(), true, []);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={editMode}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.visiteExterieure)}
      onDelete={deleteItem}
      showDeleteButton={idVisite > 0}
      saving={saving}
      deleting={deleting}
      autoRequiredMark
    >
      <FieldGroup columns={2}>
        <FGWalterSelectInput name="idcentre" label={t(ETLCodes.Centre)} items={centres} loading={cLoading} />
        <FGWalterDateMaskInput name="datevisiteDebut" label={t(ETLCodes.DateVisiteDebut)} />
        <FGWalterDateMaskInput name="datevisiteFin" label={t(ETLCodes.DateVisiteFin)} />
        <FGTextInput name="duree" label={t(ETLCodes.Duree)} maxLength={50} />
        <FGTextInput name="lieu" label={t(ETLCodes.Lieu)} maxLength={100} />
        <FGTextAreaInput name="raison" label={t(ETLCodes.Raison)} />
        <FGTextInput name="contact" label={t(ETLCodes.Contact)} maxLength={100} />
        <FGTextInput name="coordonnee" label={t(ETLCodes.Coordonnee)} maxLength={20} />
        <FGWalterCheckboxInput name="accord" label={t(ETLCodes.AccordCentre)} />
        <FGWalterDateMaskInput name="dateDemande" label={t(ETLCodes.DateDemande)} />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
