/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbApprenantRepresentantGridDto
 */
export interface FcbApprenantRepresentantGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantRepresentantGridDto
     */
    idrepresentant?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantRepresentantGridDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbApprenantRepresentantGridDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantRepresentantGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantRepresentantGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantRepresentantGridDto
     */
    lienParente?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbApprenantRepresentantGridDto
     */
    courrier?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbApprenantRepresentantGridDto
     */
    isEmergencyContact?: boolean;
}

/**
 * Check if a given object implements the FcbApprenantRepresentantGridDto interface.
 */
export function instanceOfFcbApprenantRepresentantGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbApprenantRepresentantGridDtoFromJSON(json: any): FcbApprenantRepresentantGridDto {
    return FcbApprenantRepresentantGridDtoFromJSONTyped(json, false);
}

export function FcbApprenantRepresentantGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbApprenantRepresentantGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idrepresentant': !exists(json, 'idrepresentant') ? undefined : json['idrepresentant'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'lienParente': !exists(json, 'lienParente') ? undefined : json['lienParente'],
        'courrier': !exists(json, 'courrier') ? undefined : json['courrier'],
        'isEmergencyContact': !exists(json, 'isEmergencyContact') ? undefined : json['isEmergencyContact'],
    };
}

export function FcbApprenantRepresentantGridDtoToJSON(value?: FcbApprenantRepresentantGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idrepresentant': value.idrepresentant,
        'idapprenant': value.idapprenant,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'nom': value.nom,
        'prenom': value.prenom,
        'lienParente': value.lienParente,
        'courrier': value.courrier,
        'isEmergencyContact': value.isEmergencyContact,
    };
}

