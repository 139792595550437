import { Button, Intent } from "@blueprintjs/core";
import { ButtonContainer } from "nsitools-react";
import * as React from "react";
import { AddStatutPostSortieDialog } from ".";
import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";

export interface IAddStatutPostSortieButtonProps {
  idApprenant: number;
  onClosed: (refresh: boolean) => void;
}

export const AddStatutPostSortieButton: React.FunctionComponent<IAddStatutPostSortieButtonProps> = ({
  idApprenant,
  onClosed
}) => {
  const { t } = useTl();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  return (
    <>
      <ButtonContainer>
        <Button
          intent={Intent.PRIMARY}
          text={t(ETLCodes.NouveauStatutPostFormation)}
          onClick={() => {
            setIsDialogOpen(true);
          }}
        />
      </ButtonContainer>

      <AddStatutPostSortieDialog
        idApprenant={idApprenant}
        isDialogOpen={isDialogOpen}
        onClosed={(refresh: boolean) => {
          setIsDialogOpen(false);
          onClosed(refresh);
        }}
      />
    </>
  );
};
