/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbAcquisProfessionnelDto
 */
export interface FcbAcquisProfessionnelDto {
    /**
     * 
     * @type {number}
     * @memberof FcbAcquisProfessionnelDto
     */
    idacquisProfessionnel?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbAcquisProfessionnelDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbAcquisProfessionnelDto
     */
    entreprise?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbAcquisProfessionnelDto
     */
    adresse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbAcquisProfessionnelDto
     */
    codePostal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbAcquisProfessionnelDto
     */
    localite?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbAcquisProfessionnelDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbAcquisProfessionnelDto
     */
    dateFin?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbAcquisProfessionnelDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbAcquisProfessionnelDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbAcquisProfessionnelDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbAcquisProfessionnelDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbAcquisProfessionnelDto
     */
    modificationDate?: Date | null;
}

/**
 * Check if a given object implements the FcbAcquisProfessionnelDto interface.
 */
export function instanceOfFcbAcquisProfessionnelDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbAcquisProfessionnelDtoFromJSON(json: any): FcbAcquisProfessionnelDto {
    return FcbAcquisProfessionnelDtoFromJSONTyped(json, false);
}

export function FcbAcquisProfessionnelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbAcquisProfessionnelDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idacquisProfessionnel': !exists(json, 'idacquisProfessionnel') ? undefined : json['idacquisProfessionnel'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'entreprise': !exists(json, 'entreprise') ? undefined : json['entreprise'],
        'adresse': !exists(json, 'adresse') ? undefined : json['adresse'],
        'codePostal': !exists(json, 'codePostal') ? undefined : json['codePostal'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
    };
}

export function FcbAcquisProfessionnelDtoToJSON(value?: FcbAcquisProfessionnelDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idacquisProfessionnel': value.idacquisProfessionnel,
        'idapprenant': value.idapprenant,
        'entreprise': value.entreprise,
        'adresse': value.adresse,
        'codePostal': value.codePostal,
        'localite': value.localite,
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
        'libelle': value.libelle,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
    };
}

