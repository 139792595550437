/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbAcquisScolaireDto
 */
export interface FcbAcquisScolaireDto {
    /**
     * 
     * @type {number}
     * @memberof FcbAcquisScolaireDto
     */
    idacquis?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbAcquisScolaireDto
     */
    idresultat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbAcquisScolaireDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbAcquisScolaireDto
     */
    certificat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbAcquisScolaireDto
     */
    annee?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbAcquisScolaireDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbAcquisScolaireDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbAcquisScolaireDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbAcquisScolaireDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbAcquisScolaireDto
     */
    acquis?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbAcquisScolaireDto
     */
    idecole?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbAcquisScolaireDto
     */
    iddiplome?: number | null;
}

/**
 * Check if a given object implements the FcbAcquisScolaireDto interface.
 */
export function instanceOfFcbAcquisScolaireDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbAcquisScolaireDtoFromJSON(json: any): FcbAcquisScolaireDto {
    return FcbAcquisScolaireDtoFromJSONTyped(json, false);
}

export function FcbAcquisScolaireDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbAcquisScolaireDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idacquis': !exists(json, 'idacquis') ? undefined : json['idacquis'],
        'idresultat': !exists(json, 'idresultat') ? undefined : json['idresultat'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'certificat': !exists(json, 'certificat') ? undefined : json['certificat'],
        'annee': !exists(json, 'annee') ? undefined : json['annee'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'acquis': !exists(json, 'acquis') ? undefined : json['acquis'],
        'idecole': !exists(json, 'idecole') ? undefined : json['idecole'],
        'iddiplome': !exists(json, 'iddiplome') ? undefined : json['iddiplome'],
    };
}

export function FcbAcquisScolaireDtoToJSON(value?: FcbAcquisScolaireDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idacquis': value.idacquis,
        'idresultat': value.idresultat,
        'idapprenant': value.idapprenant,
        'certificat': value.certificat,
        'annee': value.annee,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'acquis': value.acquis,
        'idecole': value.idecole,
        'iddiplome': value.iddiplome,
    };
}

