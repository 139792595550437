/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SiegeSocialAdresseDto
 */
export interface SiegeSocialAdresseDto {
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialAdresseDto
     */
    adresse?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialAdresseDto
     */
    idcodePostal?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialAdresseDto
     */
    codePostalText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialAdresseDto
     */
    boite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialAdresseDto
     */
    localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialAdresseDto
     */
    commune?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialAdresseDto
     */
    pays?: string | null;
}

/**
 * Check if a given object implements the SiegeSocialAdresseDto interface.
 */
export function instanceOfSiegeSocialAdresseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SiegeSocialAdresseDtoFromJSON(json: any): SiegeSocialAdresseDto {
    return SiegeSocialAdresseDtoFromJSONTyped(json, false);
}

export function SiegeSocialAdresseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiegeSocialAdresseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adresse': !exists(json, 'adresse') ? undefined : json['adresse'],
        'idcodePostal': !exists(json, 'idcodePostal') ? undefined : json['idcodePostal'],
        'codePostalText': !exists(json, 'codePostalText') ? undefined : json['codePostalText'],
        'boite': !exists(json, 'boite') ? undefined : json['boite'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'commune': !exists(json, 'commune') ? undefined : json['commune'],
        'pays': !exists(json, 'pays') ? undefined : json['pays'],
    };
}

export function SiegeSocialAdresseDtoToJSON(value?: SiegeSocialAdresseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adresse': value.adresse,
        'idcodePostal': value.idcodePostal,
        'codePostalText': value.codePostalText,
        'boite': value.boite,
        'localite': value.localite,
        'commune': value.commune,
        'pays': value.pays,
    };
}

