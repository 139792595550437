/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbParcoursCreaDetailDto,
  StatutApprenantDto,
} from '../models/index';
import {
    FcbParcoursCreaDetailDtoFromJSON,
    FcbParcoursCreaDetailDtoToJSON,
    StatutApprenantDtoFromJSON,
    StatutApprenantDtoToJSON,
} from '../models/index';

export interface ParcoursCreaDeleteRequest {
    id?: number;
}

export interface ParcoursCreaGetRequest {
    id?: number;
}

export interface ParcoursCreaGetByIdapprenantRequest {
    id?: number;
}

export interface ParcoursCreaGetStatutEntreeRequest {
    iddemandeFormationCrea?: number;
}

export interface ParcoursCreaSaveRequest {
    FcbParcoursCreaDetailDto?: FcbParcoursCreaDetailDto;
}

/**
 * 
 */
export class ParcoursCreaApi extends runtime.BaseAPI {

    /**
     */
    async parcoursCreaDeleteRaw(requestParameters: ParcoursCreaDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursCrea`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async parcoursCreaDelete(requestParameters: ParcoursCreaDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.parcoursCreaDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async parcoursCreaGetRaw(requestParameters: ParcoursCreaGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbParcoursCreaDetailDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursCrea`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbParcoursCreaDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async parcoursCreaGet(requestParameters: ParcoursCreaGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbParcoursCreaDetailDto> {
        const response = await this.parcoursCreaGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async parcoursCreaGetByIdapprenantRaw(requestParameters: ParcoursCreaGetByIdapprenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbParcoursCreaDetailDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursCrea/GetByIdapprenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbParcoursCreaDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async parcoursCreaGetByIdapprenant(requestParameters: ParcoursCreaGetByIdapprenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbParcoursCreaDetailDto> {
        const response = await this.parcoursCreaGetByIdapprenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async parcoursCreaGetStatutEntreeRaw(requestParameters: ParcoursCreaGetStatutEntreeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StatutApprenantDto>> {
        const queryParameters: any = {};

        if (requestParameters.iddemandeFormationCrea !== undefined) {
            queryParameters['iddemandeFormationCrea'] = requestParameters.iddemandeFormationCrea;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursCrea/statut-entree`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatutApprenantDtoFromJSON(jsonValue));
    }

    /**
     */
    async parcoursCreaGetStatutEntree(requestParameters: ParcoursCreaGetStatutEntreeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatutApprenantDto> {
        const response = await this.parcoursCreaGetStatutEntreeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async parcoursCreaSaveRaw(requestParameters: ParcoursCreaSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbParcoursCreaDetailDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ParcoursCrea`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbParcoursCreaDetailDtoToJSON(requestParameters.FcbParcoursCreaDetailDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbParcoursCreaDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async parcoursCreaSave(requestParameters: ParcoursCreaSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbParcoursCreaDetailDto> {
        const response = await this.parcoursCreaSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
