import { FGCustomPanel, FGEmpty, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory, useLocation } from "react-router";
import { InstitutionApi, InstitutionEditDtoFromJSON } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { SmallFormGenerator, CommuneSelect, LocaliteSelect } from "../../../../components";
import { useTl, useApiService, useCrudApi, useTabMessage } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import * as Yup from "yup";

export interface IInstitutionDetailProps {
  idinstitution: string;
  editMode: boolean;
}
export const InstitutionDetail: React.FunctionComponent<IInstitutionDetailProps> = ({ idinstitution, editMode }) => {
  const { t } = useTl();
  const api = useApiService(InstitutionApi);
  const history = useHistory();

  const { search } = useLocation();
  const cfs = React.useMemo(() => new URLSearchParams(search).get("cfs"), [search]);
  const { sendMessage } = useTabMessage(cfs);

  const { data, saving, saveItem, deleting, deleteItem, loading, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () =>
          +idinstitution <= 0 ? InstitutionEditDtoFromJSON({}) : api.institutionGetInstitution({ id: +idinstitution }),
        saveApiFn: values => api.institutionSaveInstitution({ InstitutionEditDto: values }),
        deleteApiFn: values => api.institutionDeleteInstitution({ id: values.idinstitution }),
        onDeletedRoute: () => ERoutes.institution,
        onSaved: d => sendMessage(d.idinstitution, true),
        onSavedRoute: d => `${ERoutes.institution}/${d.idinstitution}/detail/edit`
      }),
      [api, idinstitution, sendMessage]
    )
  );

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      denomination: Yup.string().required(t(ETLCodes.Required)),
      telephone: Yup.string().test("telephone-regex", t(ETLCodes.ErrorPhoneRegex), function(value) {
        if (value) {
          const regex = /^[+\d/\-.]*$/gm;
          const match = value.match(regex);
          if (!match) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }),
      telephonePrive: Yup.string().test("telephone-prive-regex", t(ETLCodes.ErrorPhoneRegex), function(value) {
        if (value) {
          const regex = /^[+\d/\-.]*$/gm;
          const match = value.match(regex);
          if (!match) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }),
      gsm: Yup.string().test("gsm-regex", t(ETLCodes.ErrorPhoneRegex), function(value) {
        if (value) {
          const regex = /^[+\d/\-.]*$/gm;
          const match = value.match(regex);
          if (!match) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      })
    });
  }, [t]);

  return (
    <SmallFormGenerator
      loading={loading}
      initialValues={data}
      onSubmit={saveItem}
      saving={saving}
      editMode={editMode}
      validationSchema={FormSchema}
      onDelete={deleteItem}
      deleting={deleting}
      showDeleteButton={+idinstitution >= 0}
      onCancel={() => history.push(ERoutes.institution)}
      validationErrors={validationErrors}
    >
      <FieldGroup columns={2}>
        <FGTextInput name="denomination" label={t(ETLCodes.Denomination)} maxLength={100} />
        <FGEmpty />

        <FGTextInput name="adresse" label={t(ETLCodes.Adresse)} maxLength={100} />
        <FGEmpty />

        <FGTextInput name="codePostal" label={t(ETLCodes.CodePostal)} maxLength={6} />
        <FGEmpty />
        <FGCustomPanel>
          {ctx => (
            <LocaliteSelect
              codePostal={ctx.formik.values.codePostal}
              name="localite"
              codePostalTextName="codePostal"
              disableIfNoCp={false}
            />
          )}
        </FGCustomPanel>
        <FGEmpty />
        <FGCustomPanel>
          {ctx => (
            <CommuneSelect
              codePostal={ctx.formik.values.codePostal}
              name="commune"
              enableAutoSelect={ctx.formik.initialValues.codePostal !== ctx.formik.values.codePostal}
            />
          )}
        </FGCustomPanel>
        <FGEmpty />
        <FGTextInput name="telephone" label={t(ETLCodes.Tel)} maxLength={20} />
        <FGEmpty />
        <FGTextInput name="gsm" label={t(ETLCodes.Gsm)} maxLength={20} />
        <FGEmpty />
        <FGTextInput name="email" label={t(ETLCodes.General_Email)} maxLength={100} />
        <FGEmpty />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
