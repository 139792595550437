/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbReferentielGridDto
 */
export interface FcbReferentielGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbReferentielGridDto
     */
    idreferentiel?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbReferentielGridDto
     */
    iddegre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbReferentielGridDto
     */
    degre?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbReferentielGridDto
     */
    date?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbReferentielGridDto
     */
    nbheures?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbReferentielGridDto
     */
    codereferentiel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbReferentielGridDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbReferentielGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbReferentielGridDto interface.
 */
export function instanceOfFcbReferentielGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbReferentielGridDtoFromJSON(json: any): FcbReferentielGridDto {
    return FcbReferentielGridDtoFromJSONTyped(json, false);
}

export function FcbReferentielGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbReferentielGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idreferentiel': !exists(json, 'idreferentiel') ? undefined : json['idreferentiel'],
        'iddegre': !exists(json, 'iddegre') ? undefined : json['iddegre'],
        'degre': !exists(json, 'degre') ? undefined : json['degre'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'nbheures': !exists(json, 'nbheures') ? undefined : json['nbheures'],
        'codereferentiel': !exists(json, 'codereferentiel') ? undefined : json['codereferentiel'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbReferentielGridDtoToJSON(value?: FcbReferentielGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idreferentiel': value.idreferentiel,
        'iddegre': value.iddegre,
        'degre': value.degre,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'nbheures': value.nbheures,
        'codereferentiel': value.codereferentiel,
        'description': value.description,
        'actif': value.actif,
    };
}

