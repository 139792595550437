import * as React from "react";
import { ApprenantApi, ELevelName, PriReassignmentDto } from "../../../../api";
import { useApiService, useCrudApi, useTl } from "../../../../hooks";
import * as Yup from "yup";
import styled from "styled-components";
import { Classes, Dialog } from "@blueprintjs/core";
import { CustomBulletList, FGWalterSelectInput, SmallFormGenerator } from "../../../../components";
import { ETLCodes } from "../../../../locales";
import { FGCustomPanel, FieldGroup } from "nsitools-react";
import { useReferential } from "../../../../hooks/useReferential";

const StyledDialog = styled(Dialog)`
  width: 700px;
  height: auto;
  background-color: white;
`;

export interface IPriReassignmentDialogProps {
  idsApprenant: number[];
  dialogOpen: boolean;
  onClose: () => void;
}

export const PriReassignmentDialog: React.FunctionComponent<IPriReassignmentDialogProps> = ({
  idsApprenant = [],
  dialogOpen,
  onClose
}) => {
  const { t } = useTl();
  const api = useApiService(ApprenantApi);

  const { data, loading, saveItem, saving, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () => {
          return api.apprenantGetPriReassignment({ request_body: idsApprenant });
        },
        saveApiFn: async (d: PriReassignmentDto) => {
          return api.apprenantSavePriReassignment({ PriReassignmentDto: d });
        },
        onSaved: () => onClose()
      }),
      [api, idsApprenant, onClose]
    )
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape({
        newIdPri: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required))
      }),
    [t]
  );

  const [usersPri, usersPriLoading] = useReferential(a =>
    a.referentialGetUsersByRoles({ ELevelName: [ELevelName.RI], currentIduser: 0 })
  );

  return (
    <StyledDialog title={t(ETLCodes.ReassignationPri)} isOpen={dialogOpen} onClose={() => onClose()}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose()}
          showDeleteButton={false}
          saving={saving}
          loading={loading}
          validationErrors={validationErrors}
        >
          <FieldGroup>
            <FGCustomPanel>
              {ctx => (
                <CustomBulletList
                  title={t(ETLCodes.PRIsARemplacer)}
                  loading={loading}
                  items={
                    ctx?.formik?.values?.pris?.length > 0
                      ? ctx?.formik?.values?.pris?.map(c => ({ text: c }))
                      : [{ text: t(ETLCodes.Aucun) }]
                  }
                />
              )}
            </FGCustomPanel>
            <FGWalterSelectInput
              name="newIdPri"
              label={t(ETLCodes.PriRemplacant)}
              items={usersPri}
              loading={usersPriLoading}
            />
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
