/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RapportHopeAccesPermissionDto
 */
export interface RapportHopeAccesPermissionDto {
    /**
     * 
     * @type {boolean}
     * @memberof RapportHopeAccesPermissionDto
     */
    selected?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RapportHopeAccesPermissionDto
     */
    idrapportHope?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RapportHopeAccesPermissionDto
     */
    idlevel?: number;
    /**
     * 
     * @type {string}
     * @memberof RapportHopeAccesPermissionDto
     */
    description?: string | null;
}

/**
 * Check if a given object implements the RapportHopeAccesPermissionDto interface.
 */
export function instanceOfRapportHopeAccesPermissionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RapportHopeAccesPermissionDtoFromJSON(json: any): RapportHopeAccesPermissionDto {
    return RapportHopeAccesPermissionDtoFromJSONTyped(json, false);
}

export function RapportHopeAccesPermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RapportHopeAccesPermissionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selected': !exists(json, 'selected') ? undefined : json['selected'],
        'idrapportHope': !exists(json, 'idrapportHope') ? undefined : json['idrapportHope'],
        'idlevel': !exists(json, 'idlevel') ? undefined : json['idlevel'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function RapportHopeAccesPermissionDtoToJSON(value?: RapportHopeAccesPermissionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selected': value.selected,
        'idrapportHope': value.idrapportHope,
        'idlevel': value.idlevel,
        'description': value.description,
    };
}

