/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { BooleanSearch } from './BooleanSearch';
import {
    BooleanSearchFromJSON,
    BooleanSearchFromJSONTyped,
    BooleanSearchToJSON,
} from './BooleanSearch';
import type { ELieuFormationAgrementStatut } from './ELieuFormationAgrementStatut';
import {
    ELieuFormationAgrementStatutFromJSON,
    ELieuFormationAgrementStatutFromJSONTyped,
    ELieuFormationAgrementStatutToJSON,
} from './ELieuFormationAgrementStatut';
import type { TextSearch } from './TextSearch';
import {
    TextSearchFromJSON,
    TextSearchFromJSONTyped,
    TextSearchToJSON,
} from './TextSearch';

/**
 * 
 * @export
 * @interface LieuFormationAgrementSearchDto
 */
export interface LieuFormationAgrementSearchDto {
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementSearchDto
     */
    idlieuFormation?: number | null;
    /**
     * 
     * @type {Array<ELieuFormationAgrementStatut>}
     * @memberof LieuFormationAgrementSearchDto
     */
    excludedStatuts?: Array<ELieuFormationAgrementStatut> | null;
    /**
     * 
     * @type {TextSearch}
     * @memberof LieuFormationAgrementSearchDto
     */
    codeMetier?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof LieuFormationAgrementSearchDto
     */
    nomMetier?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof LieuFormationAgrementSearchDto
     */
    nomStade?: TextSearch;
    /**
     * 
     * @type {BooleanSearch}
     * @memberof LieuFormationAgrementSearchDto
     */
    offreActive?: BooleanSearch;
    /**
     * 
     * @type {BooleanSearch}
     * @memberof LieuFormationAgrementSearchDto
     */
    capaciteAtteinte?: BooleanSearch;
    /**
     * 
     * @type {BooleanSearch}
     * @memberof LieuFormationAgrementSearchDto
     */
    actif?: BooleanSearch;
    /**
     * 
     * @type {Array<string>}
     * @memberof LieuFormationAgrementSearchDto
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof LieuFormationAgrementSearchDto
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementSearchDto
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementSearchDto
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof LieuFormationAgrementSearchDto
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationAgrementSearchDto
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the LieuFormationAgrementSearchDto interface.
 */
export function instanceOfLieuFormationAgrementSearchDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LieuFormationAgrementSearchDtoFromJSON(json: any): LieuFormationAgrementSearchDto {
    return LieuFormationAgrementSearchDtoFromJSONTyped(json, false);
}

export function LieuFormationAgrementSearchDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LieuFormationAgrementSearchDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'excludedStatuts': !exists(json, 'excludedStatuts') ? undefined : (json['excludedStatuts'] === null ? null : (json['excludedStatuts'] as Array<any>).map(ELieuFormationAgrementStatutFromJSON)),
        'codeMetier': !exists(json, 'codeMetier') ? undefined : TextSearchFromJSON(json['codeMetier']),
        'nomMetier': !exists(json, 'nomMetier') ? undefined : TextSearchFromJSON(json['nomMetier']),
        'nomStade': !exists(json, 'nomStade') ? undefined : TextSearchFromJSON(json['nomStade']),
        'offreActive': !exists(json, 'offreActive') ? undefined : BooleanSearchFromJSON(json['offreActive']),
        'capaciteAtteinte': !exists(json, 'capaciteAtteinte') ? undefined : BooleanSearchFromJSON(json['capaciteAtteinte']),
        'actif': !exists(json, 'actif') ? undefined : BooleanSearchFromJSON(json['actif']),
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function LieuFormationAgrementSearchDtoToJSON(value?: LieuFormationAgrementSearchDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idlieuFormation': value.idlieuFormation,
        'excludedStatuts': value.excludedStatuts === undefined ? undefined : (value.excludedStatuts === null ? null : (value.excludedStatuts as Array<any>).map(ELieuFormationAgrementStatutToJSON)),
        'codeMetier': TextSearchToJSON(value.codeMetier),
        'nomMetier': TextSearchToJSON(value.nomMetier),
        'nomStade': TextSearchToJSON(value.nomStade),
        'offreActive': BooleanSearchToJSON(value.offreActive),
        'capaciteAtteinte': BooleanSearchToJSON(value.capaciteAtteinte),
        'actif': BooleanSearchToJSON(value.actif),
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

