import { ButtonContainer, IDataTableColumn } from "nsitools-react";
import * as React from "react";

import { ERapportHopeType, RapportHopeApi, RapportHopeGridDto, RapportHopeSearchDto } from "../../../api";
import { ERoutes } from "../../../AppRouter";
import { BooleanColumn, PrintButton, SearchTablePage } from "../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../hooks";
import { ETLCodes } from "../../../locales";

export interface IExportHopeListPageProps {}

export const ExportHopeListPage: React.FunctionComponent<IExportHopeListPageProps> = props => {
  const { t } = useTl();
  const api = useApiService(RapportHopeApi);
  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: RapportHopeGridDto) => (
          <ButtonContainer>
            <PrintButton
              minimal
              onClick={() => window.open(`#${ERoutes.SSRSReports}/${row.idrapportHope}`, "_blank")}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Categorie),
        fieldName: "categorie"
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      },
      {
        header: () => t(ETLCodes.Actif),
        fieldName: "actif",
        alignment: "center",
        render: (row: RapportHopeGridDto) => <BooleanColumn value={row.actif} />
      }
    ],
    [t]
  );

  const apiFactory = useAbortableApiServiceFactory(RapportHopeApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: RapportHopeSearchDto) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.rapportHopeBaseSearch({ RapportHopeSearchDto: { ...sObj, type: ERapportHopeType.EXPORT } });
    },
    [apiFactory]
  );
  const criteriasFn = React.useCallback(async () => {
    var criterias = await api.rapportHopeGetSearchCriterias({ includeListsValues: true });
    return criterias.filter(c => c.criteria !== "Actif" && c.criteria !== "IdrapportHopeType" && c.criteria !== "Path");
  }, [api]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={criteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.Exports), route: ERoutes.exports }]}
      sortKeys={{ nom: "ASC" }}
      searchStateInitialSearch={false}
    />
  );
};
