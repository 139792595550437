import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import { DemandeFormation } from "./demandeFormation/DemandeFormation";
import { ProspectParcoursCrea } from "./ProspectParcoursCrea";

export interface IProspectParcoursCreaSwitchProps {}

export const ProspectParcoursCreaSwitch: React.FunctionComponent<IProspectParcoursCreaSwitchProps> = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={ProspectParcoursCrea} exact />
      <Route path={`${match.path}/demandeFormation/:idDemande`} component={DemandeFormation} exact />
    </Switch>
  );
};
