import { Card, IBreadcrumbProps } from "@blueprintjs/core";
import { ErrorBoundary, Loading } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { ERoutes } from "../AppRouter";
import { useBreadcrumbs } from "../contexts";

export interface ICustomBreadcrumbProps extends Omit<IBreadcrumbProps, "href"> {
  route?: string | ERoutes;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  align-self: stretch;
  padding: 0.5em;
  overflow: auto;
`;

interface IPageBase {
  breadCrumbs?: ICustomBreadcrumbProps[];
  isLoading?: boolean;
  withCard?: boolean;
  fullHeight?: boolean;
}
export const PageBase: React.FunctionComponent<IPageBase> = ({
  breadCrumbs,
  children,
  isLoading = false,
  withCard = true,
  fullHeight = false
}) => {
  const { setBaseBreadcrumb, setDetailsBreadcrumb } = useBreadcrumbs();

  const history = useHistory();

  React.useEffect(() => {
    setDetailsBreadcrumb([]);
  }, [setDetailsBreadcrumb]);

  React.useEffect(() => {
    if (breadCrumbs) {
      const nextBr: IBreadcrumbProps[] = breadCrumbs.map(br =>
        br.route
          ? {
              ...br,
              onClick: () => {
                if (br.route) {
                  history.push(br.route);
                }
              }
            }
          : { ...br }
      );

      setBaseBreadcrumb(nextBr);
    }
  }, [breadCrumbs, history, setBaseBreadcrumb]);

  const Wrapper = React.useMemo(() => {
    if (withCard)
      return (props: any) => (
        <Card
          elevation={1}
          style={{
            padding: "0.5em",
            height: fullHeight && "100%"
          }}
        >
          {props.children}
        </Card>
      );
    else return (props: any) => <>{props.children}</>;
  }, [fullHeight, withCard]);

  return (
    <Container>
      <Wrapper>
        <ErrorBoundary>
          <Loading isLoading={isLoading}>{children}</Loading>
        </ErrorBoundary>
      </Wrapper>
    </Container>
  );
};
