/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbDiplomeDto
 */
export interface FcbDiplomeDto {
    /**
     * 
     * @type {number}
     * @memberof FcbDiplomeDto
     */
    iddiplome?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbDiplomeDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDiplomeDto
     */
    iddiplomeGuid?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbDiplomeDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbDiplomeDto interface.
 */
export function instanceOfFcbDiplomeDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbDiplomeDtoFromJSON(json: any): FcbDiplomeDto {
    return FcbDiplomeDtoFromJSONTyped(json, false);
}

export function FcbDiplomeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbDiplomeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iddiplome': !exists(json, 'iddiplome') ? undefined : json['iddiplome'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'iddiplomeGuid': !exists(json, 'iddiplomeGuid') ? undefined : json['iddiplomeGuid'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbDiplomeDtoToJSON(value?: FcbDiplomeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iddiplome': value.iddiplome,
        'libelle': value.libelle,
        'iddiplomeGuid': value.iddiplomeGuid,
        'actif': value.actif,
    };
}

