/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ECriteriaType } from './ECriteriaType';
import {
    ECriteriaTypeFromJSON,
    ECriteriaTypeFromJSONTyped,
    ECriteriaTypeToJSON,
} from './ECriteriaType';
import type { SelectItem } from './SelectItem';
import {
    SelectItemFromJSON,
    SelectItemFromJSONTyped,
    SelectItemToJSON,
} from './SelectItem';

/**
 * 
 * @export
 * @interface FilterCriteriaInfo
 */
export interface FilterCriteriaInfo {
    /**
     * 
     * @type {string}
     * @memberof FilterCriteriaInfo
     */
    criteria?: string | null;
    /**
     * 
     * @type {ECriteriaType}
     * @memberof FilterCriteriaInfo
     */
    type?: ECriteriaType;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterCriteriaInfo
     */
    searchModes?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof FilterCriteriaInfo
     */
    defaultSearchMode?: string | null;
    /**
     * 
     * @type {Array<SelectItem>}
     * @memberof FilterCriteriaInfo
     */
    listValues?: Array<SelectItem> | null;
    /**
     * 
     * @type {boolean}
     * @memberof FilterCriteriaInfo
     */
    isMultipleOr?: boolean;
}

/**
 * Check if a given object implements the FilterCriteriaInfo interface.
 */
export function instanceOfFilterCriteriaInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FilterCriteriaInfoFromJSON(json: any): FilterCriteriaInfo {
    return FilterCriteriaInfoFromJSONTyped(json, false);
}

export function FilterCriteriaInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterCriteriaInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'criteria': !exists(json, 'criteria') ? undefined : json['criteria'],
        'type': !exists(json, 'type') ? undefined : ECriteriaTypeFromJSON(json['type']),
        'searchModes': !exists(json, 'searchModes') ? undefined : json['searchModes'],
        'defaultSearchMode': !exists(json, 'defaultSearchMode') ? undefined : json['defaultSearchMode'],
        'listValues': !exists(json, 'listValues') ? undefined : (json['listValues'] === null ? null : (json['listValues'] as Array<any>).map(SelectItemFromJSON)),
        'isMultipleOr': !exists(json, 'isMultipleOr') ? undefined : json['isMultipleOr'],
    };
}

export function FilterCriteriaInfoToJSON(value?: FilterCriteriaInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'criteria': value.criteria,
        'type': ECriteriaTypeToJSON(value.type),
        'searchModes': value.searchModes,
        'defaultSearchMode': value.defaultSearchMode,
        'listValues': value.listValues === undefined ? undefined : (value.listValues === null ? null : (value.listValues as Array<any>).map(SelectItemToJSON)),
        'isMultipleOr': value.isMultipleOr,
    };
}

