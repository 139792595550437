import * as React from "react";
import { Route, Switch } from "react-router";
import { InscriptionSuspensionList } from ".";
import { ERoutes } from "../../../../../../AppRouter";

export interface IInscriptionSuspensionSwitchProps {}

export const InscriptionSuspensionSwitch: React.FunctionComponent<IInscriptionSuspensionSwitchProps> = props => {
  return (
    <Switch>
      <Route
        path={`${ERoutes.apprenant}/:id/:tab/:inscriptionId/:tabInscription`}
        component={InscriptionSuspensionList}
        exact
      />
    </Switch>
  );
};
