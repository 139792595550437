/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EListSearchType } from './EListSearchType';
import {
    EListSearchTypeFromJSON,
    EListSearchTypeFromJSONTyped,
    EListSearchTypeToJSON,
} from './EListSearchType';
import type { EListSearchTypeEPrintingQueueStatusCombinableCriteria } from './EListSearchTypeEPrintingQueueStatusCombinableCriteria';
import {
    EListSearchTypeEPrintingQueueStatusCombinableCriteriaFromJSON,
    EListSearchTypeEPrintingQueueStatusCombinableCriteriaFromJSONTyped,
    EListSearchTypeEPrintingQueueStatusCombinableCriteriaToJSON,
} from './EListSearchTypeEPrintingQueueStatusCombinableCriteria';
import type { EPrintingQueueStatus } from './EPrintingQueueStatus';
import {
    EPrintingQueueStatusFromJSON,
    EPrintingQueueStatusFromJSONTyped,
    EPrintingQueueStatusToJSON,
} from './EPrintingQueueStatus';

/**
 * 
 * @export
 * @interface EPrintingQueueStatusListSearch
 */
export interface EPrintingQueueStatusListSearch {
    /**
     * 
     * @type {Array<EListSearchTypeEPrintingQueueStatusCombinableCriteria>}
     * @memberof EPrintingQueueStatusListSearch
     */
    criterias?: Array<EListSearchTypeEPrintingQueueStatusCombinableCriteria> | null;
    /**
     * 
     * @type {EListSearchType}
     * @memberof EPrintingQueueStatusListSearch
     */
    searchMode?: EListSearchType;
    /**
     * 
     * @type {EPrintingQueueStatus}
     * @memberof EPrintingQueueStatusListSearch
     */
    value?: EPrintingQueueStatus;
    /**
     * 
     * @type {EPrintingQueueStatus}
     * @memberof EPrintingQueueStatusListSearch
     */
    secondaryValue?: EPrintingQueueStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof EPrintingQueueStatusListSearch
     */
    readonly availableSearchTypes?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof EPrintingQueueStatusListSearch
     */
    defaultSearchType?: string | null;
}

/**
 * Check if a given object implements the EPrintingQueueStatusListSearch interface.
 */
export function instanceOfEPrintingQueueStatusListSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EPrintingQueueStatusListSearchFromJSON(json: any): EPrintingQueueStatusListSearch {
    return EPrintingQueueStatusListSearchFromJSONTyped(json, false);
}

export function EPrintingQueueStatusListSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): EPrintingQueueStatusListSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'criterias': !exists(json, 'criterias') ? undefined : (json['criterias'] === null ? null : (json['criterias'] as Array<any>).map(EListSearchTypeEPrintingQueueStatusCombinableCriteriaFromJSON)),
        'searchMode': !exists(json, 'searchMode') ? undefined : EListSearchTypeFromJSON(json['searchMode']),
        'value': !exists(json, 'value') ? undefined : EPrintingQueueStatusFromJSON(json['value']),
        'secondaryValue': !exists(json, 'secondaryValue') ? undefined : EPrintingQueueStatusFromJSON(json['secondaryValue']),
        'availableSearchTypes': !exists(json, 'availableSearchTypes') ? undefined : json['availableSearchTypes'],
        'defaultSearchType': !exists(json, 'defaultSearchType') ? undefined : json['defaultSearchType'],
    };
}

export function EPrintingQueueStatusListSearchToJSON(value?: EPrintingQueueStatusListSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'criterias': value.criterias === undefined ? undefined : (value.criterias === null ? null : (value.criterias as Array<any>).map(EListSearchTypeEPrintingQueueStatusCombinableCriteriaToJSON)),
        'searchMode': EListSearchTypeToJSON(value.searchMode),
        'value': EPrintingQueueStatusToJSON(value.value),
        'secondaryValue': EPrintingQueueStatusToJSON(value.secondaryValue),
        'defaultSearchType': value.defaultSearchType,
    };
}

