/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LieuFormationAgrementSuspensionMultipleDto
 */
export interface LieuFormationAgrementSuspensionMultipleDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof LieuFormationAgrementSuspensionMultipleDto
     */
    idslieuFormationAgrement?: Array<number> | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementSuspensionMultipleDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementSuspensionMultipleDto
     */
    dateFin?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementSuspensionMultipleDto
     */
    idmotifSuspension?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationAgrementSuspensionMultipleDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof LieuFormationAgrementSuspensionMultipleDto
     */
    idslieuFormationAgrementSuspension?: Array<number> | null;
}

/**
 * Check if a given object implements the LieuFormationAgrementSuspensionMultipleDto interface.
 */
export function instanceOfLieuFormationAgrementSuspensionMultipleDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LieuFormationAgrementSuspensionMultipleDtoFromJSON(json: any): LieuFormationAgrementSuspensionMultipleDto {
    return LieuFormationAgrementSuspensionMultipleDtoFromJSONTyped(json, false);
}

export function LieuFormationAgrementSuspensionMultipleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LieuFormationAgrementSuspensionMultipleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idslieuFormationAgrement': !exists(json, 'idslieuFormationAgrement') ? undefined : json['idslieuFormationAgrement'],
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
        'idmotifSuspension': !exists(json, 'idmotifSuspension') ? undefined : json['idmotifSuspension'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'idslieuFormationAgrementSuspension': !exists(json, 'idslieuFormationAgrementSuspension') ? undefined : json['idslieuFormationAgrementSuspension'],
    };
}

export function LieuFormationAgrementSuspensionMultipleDtoToJSON(value?: LieuFormationAgrementSuspensionMultipleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idslieuFormationAgrement': value.idslieuFormationAgrement,
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
        'idmotifSuspension': value.idmotifSuspension,
        'remarque': value.remarque,
        'idslieuFormationAgrementSuspension': value.idslieuFormationAgrementSuspension,
    };
}

