import styled from "styled-components";

export const InlineButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-end;
  justify-content: space-between;
`;

export const ButtonsBloc = styled.div`
  display: flex;
  margin-top: 0.5em;
  & > * + * {
    margin-left: 0.5em;
  }
`;
