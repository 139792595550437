/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbFormateurDesideratumGridDto
 */
export interface FcbFormateurDesideratumGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDesideratumGridDto
     */
    iddesiderata?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDesideratumGridDto
     */
    idcentre?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDesideratumGridDto
     */
    centre?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurDesideratumGridDto
     */
    idformateur?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDesideratumGridDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurDesideratumGridDto
     */
    desiderata?: string | null;
}

/**
 * Check if a given object implements the FcbFormateurDesideratumGridDto interface.
 */
export function instanceOfFcbFormateurDesideratumGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbFormateurDesideratumGridDtoFromJSON(json: any): FcbFormateurDesideratumGridDto {
    return FcbFormateurDesideratumGridDtoFromJSONTyped(json, false);
}

export function FcbFormateurDesideratumGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbFormateurDesideratumGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iddesiderata': !exists(json, 'iddesiderata') ? undefined : json['iddesiderata'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'centre': !exists(json, 'centre') ? undefined : json['centre'],
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'desiderata': !exists(json, 'desiderata') ? undefined : json['desiderata'],
    };
}

export function FcbFormateurDesideratumGridDtoToJSON(value?: FcbFormateurDesideratumGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iddesiderata': value.iddesiderata,
        'idcentre': value.idcentre,
        'centre': value.centre,
        'idformateur': value.idformateur,
        'anneeScolaire': value.anneeScolaire,
        'desiderata': value.desiderata,
    };
}

