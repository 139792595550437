/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChefEntrepriseDoublonSiegeSocialDto } from './ChefEntrepriseDoublonSiegeSocialDto';
import {
    ChefEntrepriseDoublonSiegeSocialDtoFromJSON,
    ChefEntrepriseDoublonSiegeSocialDtoFromJSONTyped,
    ChefEntrepriseDoublonSiegeSocialDtoToJSON,
} from './ChefEntrepriseDoublonSiegeSocialDto';

/**
 * 
 * @export
 * @interface ChefEntrepriseDoublonEditDto
 */
export interface ChefEntrepriseDoublonEditDto {
    /**
     * 
     * @type {number}
     * @memberof ChefEntrepriseDoublonEditDto
     */
    idchefEntreprise?: number;
    /**
     * 
     * @type {Array<ChefEntrepriseDoublonSiegeSocialDto>}
     * @memberof ChefEntrepriseDoublonEditDto
     */
    siegesSociaux?: Array<ChefEntrepriseDoublonSiegeSocialDto> | null;
}

/**
 * Check if a given object implements the ChefEntrepriseDoublonEditDto interface.
 */
export function instanceOfChefEntrepriseDoublonEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChefEntrepriseDoublonEditDtoFromJSON(json: any): ChefEntrepriseDoublonEditDto {
    return ChefEntrepriseDoublonEditDtoFromJSONTyped(json, false);
}

export function ChefEntrepriseDoublonEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChefEntrepriseDoublonEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idchefEntreprise': !exists(json, 'idchefEntreprise') ? undefined : json['idchefEntreprise'],
        'siegesSociaux': !exists(json, 'siegesSociaux') ? undefined : (json['siegesSociaux'] === null ? null : (json['siegesSociaux'] as Array<any>).map(ChefEntrepriseDoublonSiegeSocialDtoFromJSON)),
    };
}

export function ChefEntrepriseDoublonEditDtoToJSON(value?: ChefEntrepriseDoublonEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idchefEntreprise': value.idchefEntreprise,
        'siegesSociaux': value.siegesSociaux === undefined ? undefined : (value.siegesSociaux === null ? null : (value.siegesSociaux as Array<any>).map(ChefEntrepriseDoublonSiegeSocialDtoToJSON)),
    };
}

