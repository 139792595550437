/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BaremeTheoriqueDto
 */
export interface BaremeTheoriqueDto {
    /**
     * 
     * @type {number}
     * @memberof BaremeTheoriqueDto
     */
    idcontratBaremeTheorique?: number;
    /**
     * 
     * @type {number}
     * @memberof BaremeTheoriqueDto
     */
    idcontrat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BaremeTheoriqueDto
     */
    idbaremeTheorique?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BaremeTheoriqueDto
     */
    remunReelle?: number;
    /**
     * 
     * @type {number}
     * @memberof BaremeTheoriqueDto
     */
    remunTheorique?: number;
    /**
     * 
     * @type {string}
     * @memberof BaremeTheoriqueDto
     */
    libelle?: string | null;
}

/**
 * Check if a given object implements the BaremeTheoriqueDto interface.
 */
export function instanceOfBaremeTheoriqueDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BaremeTheoriqueDtoFromJSON(json: any): BaremeTheoriqueDto {
    return BaremeTheoriqueDtoFromJSONTyped(json, false);
}

export function BaremeTheoriqueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaremeTheoriqueDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontratBaremeTheorique': !exists(json, 'idcontratBaremeTheorique') ? undefined : json['idcontratBaremeTheorique'],
        'idcontrat': !exists(json, 'idcontrat') ? undefined : json['idcontrat'],
        'idbaremeTheorique': !exists(json, 'idbaremeTheorique') ? undefined : json['idbaremeTheorique'],
        'remunReelle': !exists(json, 'remunReelle') ? undefined : json['remunReelle'],
        'remunTheorique': !exists(json, 'remunTheorique') ? undefined : json['remunTheorique'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
    };
}

export function BaremeTheoriqueDtoToJSON(value?: BaremeTheoriqueDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontratBaremeTheorique': value.idcontratBaremeTheorique,
        'idcontrat': value.idcontrat,
        'idbaremeTheorique': value.idbaremeTheorique,
        'remunReelle': value.remunReelle,
        'remunTheorique': value.remunTheorique,
        'libelle': value.libelle,
    };
}

