import { Colors } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";
import { css } from "styled-components";

import { TuteurApi, TuteurLieuFormationGridDto, TuteurLieuFormationSearchDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, ViewButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface ITuteurLieuxFormationProps {}

export const TuteurLieuxFormation: React.FunctionComponent<ITuteurLieuxFormationProps> = props => {
  const { t } = useTl();
  const { id } = useParams<{ id: string }>();
  const api = useApiService(TuteurApi);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { nom: "ASC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: TuteurLieuFormationSearchDto) => {
      sObj.idtuteur = +id;
      return api.tuteurSearchTuteurLieuFormation({ TuteurLieuFormationSearchDto: sObj });
    },
    [api, id]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const onViewClick = React.useCallback((dto: TuteurLieuFormationGridDto) => {
    window.open(
      `#${ERoutes.lieuFormation}/${dto.idlieuFormation}/detail/view?idsiegeSocial=${dto.idsiegeSocial}`,
      "_blank"
    );
  }, []);

  const onEditClick = React.useCallback((dto: TuteurLieuFormationGridDto) => {
    window.open(
      `#${ERoutes.lieuFormation}/${dto.idlieuFormation}/detail/edit?idsiegeSocial=${dto.idsiegeSocial}`,
      "_blank"
    );
  }, []);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: TuteurLieuFormationGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => onViewClick(row)} />
            <EditButton minimal={true} onClick={() => onEditClick(row)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.NomLieuFormation),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Enseigne),
        fieldName: "enseigne"
      },
      {
        header: () => t(ETLCodes.Email),
        fieldName: "email"
      },
      {
        header: () => t(ETLCodes.Telephone),
        fieldName: "telephone"
      },
      {
        header: () => t(ETLCodes.NumeroUe),
        fieldName: "numeroUe"
      }
    ],
    [onEditClick, onViewClick, t]
  );

  return (
    <FieldSet title={t(ETLCodes.TableResults, { count: totalCount })}>
      <DataTable
        dateFormat="dd/MM/yyyy"
        tableState={tableState}
        loading={loading}
        columns={columns}
        filterMode="OnEnter"
        customizeRowStyle={(row: TuteurLieuFormationGridDto) => css`
          & * {
            color: ${!row.actif ? Colors.RED3 + " !important" : null};
          }
        `}
      ></DataTable>
    </FieldSet>
  );
};
