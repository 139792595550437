import { Classes, Dialog } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";
import { InscriptionAbandonDto, InscriptionApi } from "../../../../../api";
import * as Yup from "yup";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { FGWalterDateMaskInput, SmallFormGenerator } from "../../../../../components";
import { FieldGroup } from "nsitools-react";

const StyledDialog = styled(Dialog)`
  width: 500px;
  height: auto;
  background-color: white;
`;

export interface IInscriptionAbandonDetailDialogProps {
  dialogOpen: boolean;
  onClose: (toSave?: InscriptionAbandonDto) => void;
  currentAbandon: InscriptionAbandonDto;
}

export const InscriptionAbandonDetailDialog: React.FunctionComponent<IInscriptionAbandonDetailDialogProps> = ({
  currentAbandon,
  dialogOpen,
  onClose
}) => {
  const { t } = useTl();
  const api = useApiService(InscriptionApi);

  const { data, saveItem, saving, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () => currentAbandon,
        saveApiFn: async d => {
          await api.inscriptionAbandon({ InscriptionAbandonDto: d });
          return d;
        },
        onSaved: d => onClose(d),
        serverValidationRootKey: "Dto"
      }),
      [api, currentAbandon, onClose]
    )
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape({
        dateAbandon: Yup.date()
          .nullable()
          .required(t(ETLCodes.Required))
      }),
    [t]
  );

  return (
    <StyledDialog title={t(ETLCodes.AbandonInscription)} isOpen={dialogOpen} onClose={() => onClose()}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose()}
          showDeleteButton={false}
          saving={saving}
          minLabelWidth={100}
          validationErrors={validationErrors}
          preventDefaultSaveOnEnter
        >
          <FieldGroup>
            <FGWalterDateMaskInput name="dateAbandon" label={t(ETLCodes.DateAbandon)} />
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
