import * as React from "react";
import styled from "styled-components";
import { Tooltip } from "@blueprintjs/core";
import { useDebounce, useFGContext, usePrevious } from "nsitools-react";

import { useTl } from "../hooks";
import { ETLCodes } from "../locales";
import { FGWalterSelectInput } from ".";
import { useReferential } from "../hooks/useReferential";
import { getIn } from "formik";

interface ILocaliteSelectProps {
  codePostalTextName?: string;
  codePays?: string;
  codePostal: string;
  name: string;
  labelStyles?: React.CSSProperties;
  disableIfNoCp?: boolean;
  disabled?: boolean;
  readonly?: boolean;
}

const StyledTooltip = styled(Tooltip)`
  display: flex;
  & > * {
    flex: 1;
  }
`;

export const LocaliteSelect: React.FunctionComponent<ILocaliteSelectProps> = ({
  codePostalTextName,
  codePays = "BE",
  codePostal,
  name,
  disableIfNoCp = true,
  disabled,
  readonly
}) => {
  const { t } = useTl();
  const { formik } = useFGContext();

  const debouncedCodePays = useDebounce(codePays, 500);
  const debouncedCodePostal = useDebounce(codePostal, 500);
  const [data, loading, , rawLocalites] = useReferential(
    a =>
      !debouncedCodePostal
        ? a.referentialGetLocalitesSuggest({ pays: debouncedCodePays })
        : a.referentialGetLocaliteFromCodePostal({ codePays: debouncedCodePays, codePostal: debouncedCodePostal }),
    false,
    [debouncedCodePostal]
  );

  const value = React.useMemo(() => getIn(formik.values, name), [formik.values, name]);
  const previousLocalite = usePrevious(value);
  React.useEffect(() => {
    if (!!codePostalTextName && !!value && value !== previousLocalite) {
      const cpText = rawLocalites?.find(l => l.idValue === value)?.keyValue;
      if (!!cpText) {
        formik.setFieldValue(codePostalTextName, cpText);
      }
    }
  }, [codePostalTextName, formik, previousLocalite, rawLocalites, value]);

  return (
    <StyledTooltip
      content={t(ETLCodes.SelectPostalCodeFirst)}
      position="right"
      disabled={!!codePostal || !disableIfNoCp}
    >
      <FGWalterSelectInput
        name={name}
        label={t(ETLCodes.Localite)}
        loading={loading}
        items={data}
        disabled={(disableIfNoCp && !codePostal) || disabled}
        autoSelectIfOne
        readonly={readonly}
      />
    </StyledTooltip>
  );
};
