import { ButtonContainer, IDataTableColumn } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import { SiegeLieuSuiviGridDto, SiegeLieuSuiviSearchBase, SiegeSocialSuiviApi } from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import { BooleanColumn, EditButton, LinkButton, SearchTablePage, ViewButton } from "../../../../../../components";
import { useAuth } from "../../../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

export interface ISiegeSocialSuiviListProps {}

export const SiegeSocialSuiviList: React.FunctionComponent<ISiegeSocialSuiviListProps> = props => {
  const { t } = useTl();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const idsiegeSocial = React.useMemo(() => +id, [id]);
  const api = useApiService(SiegeSocialSuiviApi);
  const { user } = useAuth();

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: SiegeLieuSuiviGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() =>
                history.push(
                  `${ERoutes.siegeSocial}/${idsiegeSocial}/suivi/view/${row.idsiegeSocialLieuFormationSuivi}`
                )
              }
            />

            {row.idrealisateurSuivi === user.iduser && (
              <EditButton
                minimal={true}
                onClick={() =>
                  history.push(
                    `${ERoutes.siegeSocial}/${idsiegeSocial}/suivi/edit/${row.idsiegeSocialLieuFormationSuivi}`
                  )
                }
              />
            )}
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.DateSuivi),
        fieldName: "dateSuivi"
      },
      {
        header: () => t(ETLCodes.RealisateurSuivi),
        fieldName: "realisateurSuivi",
        render: (row: SiegeLieuSuiviGridDto) => (
          <LinkButton
            text={row.realisateurSuivi}
            onClick={() => window.open(`#${ERoutes.users}/${row.idrealisateurSuivi}/detail/view`, "_blank")}
          />
        )
      },
      {
        header: () => t(ETLCodes.Encodeur),
        fieldName: "encodeur",
        render: (row: SiegeLieuSuiviGridDto) => (
          <LinkButton
            text={row.encodeur}
            onClick={() => window.open(`#${ERoutes.users}/${row.idencodeur}/detail/view`, "_blank")}
          />
        )
      },
      {
        header: () => t(ETLCodes.TypeSuivi),
        fieldName: "typeSuivi"
      },
      {
        header: () => t(ETLCodes.ContenuCache),
        fieldName: "hiddenContent",
        alignment: "center",
        render: (row: SiegeLieuSuiviGridDto) => <BooleanColumn value={row.hiddenContent} />
      }
    ],
    [history, idsiegeSocial, t, user.iduser]
  );

  const getCriteriasFunc = React.useCallback(
    () => api.siegeSocialSuiviGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  const apiFactory = useAbortableApiServiceFactory(SiegeSocialSuiviApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFunc = React.useCallback(
    (nextSearch?: SiegeLieuSuiviSearchBase) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.siegeSocialSuiviBaseSearch({ SiegeLieuSuiviSearchBase: { ...nextSearch, idsiegeSocial } });
    },
    [apiFactory, idsiegeSocial]
  );

  const onAddSuivi = React.useCallback(() => {
    history.push(`${ERoutes.siegeSocial}/${idsiegeSocial}/suivi/edit/-1`);
  }, [history, idsiegeSocial]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      withCard={false}
      columns={columns}
      getCriteriasFunction={getCriteriasFunc}
      searchFunction={searchFunc}
      onAbort={onAbort}
      sortKeys={{ dateSuivi: "DESC", encodeur: "ASC" }}
      addFunc={onAddSuivi}
    />
  );
};
