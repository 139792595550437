import * as React from "react";
import { useHistory } from "react-router";
import { TypeBulletinApi, TypeBulletinEditDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import * as Yup from "yup";
import { ETLCodes } from "../../../../../locales";
import { SmallFormGenerator } from "../../../../../components";
import { FGTextInput, FieldGroup } from "nsitools-react";

export interface ITypeBulletinDetailsPanelProps {
  idtypeBulletin: number;
  editMode: boolean;
}

export const TypeBulletinDetailsPanel: React.FunctionComponent<ITypeBulletinDetailsPanelProps> = ({
  editMode,
  idtypeBulletin
}) => {
  const { t } = useTl();
  const api = useApiService(TypeBulletinApi);
  const history = useHistory();

  const { data, saving, saveItem, deleting, deleteItem, loading, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () =>
          idtypeBulletin <= 0
            ? TypeBulletinEditDtoFromJSON({})
            : api.typeBulletinGetTypeBulletin({ id: idtypeBulletin }),
        saveApiFn: values => api.typeBulletinSaveTypeBulletin({ TypeBulletinEditDto: values }),
        deleteApiFn: values => api.typeBulletinDeleteTypeBulletin({ id: values.idtypeBulletin }),
        onDeletedRoute: () => ERoutes.typeBulletin,
        onSavedRoute: d => `${ERoutes.typeBulletin}/${d.idtypeBulletin}/detail/edit`
      }),
      [api, idtypeBulletin]
    )
  );

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      libelle: Yup.string().required(t(ETLCodes.Required)),
      code: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    <SmallFormGenerator
      loading={loading}
      initialValues={data}
      onSubmit={saveItem}
      saving={saving}
      editMode={editMode}
      validationSchema={FormSchema}
      onDelete={deleteItem}
      deleting={deleting}
      showDeleteButton={idtypeBulletin >= 0}
      onCancel={() => history.push(ERoutes.typeBulletin)}
      validationErrors={validationErrors}
    >
      <FieldGroup>
        <FGTextInput name="libelle" label={t(ETLCodes.Libelle)} />
        <FGTextInput name="code" label={t(ETLCodes.Code)} forceCase="upper" />
      </FieldGroup>
    </SmallFormGenerator>
  );
};

export default TypeBulletinDetailsPanel;
