export function bceNumberValidate(bce: string) {
  //if (!id) return true;
  //   id = id.replace(".", "");
  //   id = id.replace("BE", "");
  //if (id.length < 10) return false;
  try {
    let numberToValidate = bce.slice(0, 8);
    const validator = bce.slice(8);
    return 97 - (+numberToValidate % 97) === +validator;
  } catch (Error) {
    return false;
  }
}
