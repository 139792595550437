import { Alignment, Button, Classes, Collapse, Colors } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classnames from "classnames";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { useTheme, useTl } from "../../hooks";
import { IThemeData } from "../../theme";
import { CollapseAllContext } from "./CollapseAllContext";
import { Container } from "./Container";
import { INavigationItem } from "./INavigationItem";

const ButtonStyled = styled(Button)<{ theme: IThemeData }>`
  margin: 0em;
  &.level_0 {
    background-color: ${props => props.theme.menuColor || Colors.BLUE3} !important;
    border-radius: 0;
    padding: 0.5em !important;
    &:hover {
      background-color: ${props => props.theme.menuSubColor || Colors.BLUE3} !important;
      color: ${props => props.theme.highlightColor || "black"} !important;
    }
  }
  &.level_1.has-children {
    /* border: 1px solid white; */
    margin-top: 1px;
    margin-bottom: 1px;
    background-color: ${props => props.theme.menuSubColor} !important;
    border-radius: 0;
    &:hover {
      background-color: ${props => props.theme.menuSubColorSelected || Colors.BLUE3} !important;
      color: ${props => props.theme.highlightColor || "black"} !important;
    }
  }

  & .${Classes.ICON} {
    color: ${props => props.theme.menuIconColor} !important;
  }

  &.is-active {
    font-weight: bold;
    text-decoration: blink !important;
    border-bottom: 3px solid;
    border-color: ${props => props.theme.menuActiveColor || Colors.BLUE3} !important;
    background-color: ${props => props.theme.menuActiveColor || Colors.BLUE3} !important;
    color: white !important;
  }
`;

export interface IMenuItemProps {
  item: INavigationItem;
  level: number;
  disabled?: boolean;
  maxAutoCollapseLevel?: number;
  onNavigation?: () => void;
}

export const MenuItem: React.FunctionComponent<IMenuItemProps> = ({
  item,
  level,
  disabled,
  maxAutoCollapseLevel = 999,
  onNavigation
}) => {
  const { pathname } = useLocation();
  const { theme } = useTheme();
  const isActive = React.useMemo(
    () =>
      item.route &&
      (pathname === item.route ||
        pathname.startsWith(item.route + "/") ||
        item.otherAuthorizedRoutes?.some(r => pathname === r || pathname.startsWith(r + "/"))),
    [item, pathname]
  );

  const [isExpanded, setIsExpanded] = React.useState(!!item.defaultOpened);
  const history = useHistory();
  const { t } = useTl();
  const id = React.useMemo(() => uuidv4(), []);
  const { openedMenuId, setMenuOpenedId } = React.useContext(CollapseAllContext);

  React.useEffect(() => {
    const hasItemWithCurrentRoute = (itm: INavigationItem) => {
      if (itm.route && pathname.includes(itm.route)) {
        return true;
      }
      if (itm.items) {
        return itm.items.filter(i => hasItemWithCurrentRoute(i)).length > 0;
      }
      return false;
    };

    if (hasItemWithCurrentRoute(item)) {
      setIsExpanded(true);
      setMenuOpenedId({ level, id });
    }
  }, [id, item, level, pathname, setMenuOpenedId]);

  React.useEffect(() => {
    if (openedMenuId?.level === level && openedMenuId?.id !== id && level <= maxAutoCollapseLevel) {
      setIsExpanded(false);
    }
  }, [id, level, maxAutoCollapseLevel, openedMenuId]);

  const hasChildren = React.useMemo(() => {
    return item.items && item.items.length > 0;
  }, [item]);

  const collapseIcon = React.useMemo(() => {
    if (hasChildren) {
      return isExpanded ? IconNames.CHEVRON_RIGHT : IconNames.CHEVRON_DOWN;
    } else {
      return IconNames.BLANK;
    }
  }, [hasChildren, isExpanded]);

  const onClick = React.useCallback(() => {
    if (hasChildren) {
      if (!isExpanded) {
        setMenuOpenedId({ level, id });
      }
      setIsExpanded(e => !e);
    } else if (item.route) {
      history.push(item.route);
      if (onNavigation) {
        onNavigation();
      }
    }
  }, [hasChildren, item, isExpanded, setMenuOpenedId, level, id, history, onNavigation]);

  return (
    <>
      {item.startGroupTitle && <div style={{ color: "red" }}>{item.startGroupTitle}</div>}
      <ButtonStyled
        theme={theme}
        text={t(item.name)}
        icon={item.icon}
        rightIcon={collapseIcon}
        onClick={() => onClick()}
        style={{ paddingLeft: `${level * 1 + 0.1}em` }}
        minimal={true}
        className={classnames([`level_${level}`, { "has-children": hasChildren, "is-active": isActive }])}
        alignText={Alignment.LEFT}
        small={true}
        disabled={disabled}
      />
      <Collapse isOpen={isExpanded}>
        <Container theme={theme}>
          {item.items &&
            item.items!.map((itm, index) => (
              <MenuItem
                key={index}
                item={itm}
                level={level + 1}
                disabled={itm.disabled}
                maxAutoCollapseLevel={maxAutoCollapseLevel}
                onNavigation={onNavigation}
              />
            ))}
        </Container>
      </Collapse>
    </>
  );
};
