import { format } from "date-fns";
import { FieldSet } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import {
  CarouselComparer,
  DoublonsDataCard,
  DoublonsMultipleDataCard,
  FO_FINAL_KEY,
  useDoublonsStateContext
} from "..";
import {
  FcbFormateurDiplomeDto,
  FcbFormateurProfessionDto,
  FcbFormateurTitreDto,
  FormateurDoublonEditDto
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { nameof } from "../../../../../utils";

const Container = styled.div`
  height: 70vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface IDoublonFormateurProfessionnelProps {}

export const DoublonFormateurProfessionnel: React.FunctionComponent<IDoublonFormateurProfessionnelProps> = props => {
  const { t } = useTl();
  const { data, getSelectedIndex } = useDoublonsStateContext();

  const selectedIndex = React.useMemo(() => getSelectedIndex(`${FO_FINAL_KEY}.idformateur`) ?? 0, [getSelectedIndex]);

  const formateurContainer = React.useCallback(
    (index: number, nb: number) => {
      const formateur: FormateurDoublonEditDto = data?.formateurs[index];
      if (!formateur) return;

      return (
        <FieldSet
          key={formateur.idformateur}
          title={
            <ViewButton
              text={t(ETLCodes.FormateurNb, { nb: nb, id: formateur.idformateur })}
              onClick={() => window.open(`#${ERoutes.formateur}/${formateur.idformateur}/signaletique/view`, "_blank")}
            />
          }
          titlePosition="center"
        >
          <DoublonsDataCard
            fieldPath={`representants[${index}].donneesProfessionnelles.telephoneProfFormateur.idtelephone`}
            valuePath={`representants[${index}].donneesProfessionnelles.telephoneProfFormateur.numero`}
            label={t(ETLCodes.TelephonePro)}
            finalEntityKey={FO_FINAL_KEY}
            entityIdKey={nameof<FormateurDoublonEditDto>("idformateur")}
          />
          <DoublonsDataCard
            fieldPath={`representants[${index}].donneesProfessionnelles.gsmProfFormateur.idtelephone`}
            valuePath={`representants[${index}].donneesProfessionnelles.gsmProfFormateur.numero`}
            label={t(ETLCodes.GsmPro)}
            finalEntityKey={FO_FINAL_KEY}
            entityIdKey={nameof<FormateurDoublonEditDto>("idformateur")}
          />
          <DoublonsDataCard
            fieldPath={`representants[${index}].donneesProfessionnelles.emailProfFormateur.idemail`}
            valuePath={`representants[${index}].donneesProfessionnelles.emailProfFormateur.adresseEmail`}
            label={t(ETLCodes.EmailPro)}
            finalEntityKey={FO_FINAL_KEY}
            entityIdKey={nameof<FormateurDoublonEditDto>("idformateur")}
          />
          <DoublonsDataCard
            fieldPath={`formateurs[${index}].donneesProfessionnelles.activite`}
            label={t(ETLCodes.Activite)}
            finalEntityKey={FO_FINAL_KEY}
            entityIdKey={nameof<FormateurDoublonEditDto>("idformateur")}
          />
          <DoublonsDataCard
            fieldPath={`formateurs[${index}].donneesProfessionnelles.identreprise`}
            valuePath={`formateurs[${index}].donneesProfessionnelles.entreprise`}
            label={t(ETLCodes.Entreprise)}
            finalEntityKey={FO_FINAL_KEY}
            entityIdKey={nameof<FormateurDoublonEditDto>("idformateur")}
          />
          <DoublonsMultipleDataCard
            title={t(ETLCodes.Professions)}
            labelPredicate={(f: FcbFormateurProfessionDto) => f.libelle}
            fieldPath={`formateurs[${index}].donneesProfessionnelles.professions`}
            finalEntityKey={FO_FINAL_KEY}
            interactive={() => false}
            forceSelected={() => false}
          >
            {(f: FcbFormateurProfessionDto) => (
              <InformationContainer key={f.idprofession}>
                {t(ETLCodes.Date)}: {f.date && format(f.date, "dd-MM-yyyy")}
              </InformationContainer>
            )}
          </DoublonsMultipleDataCard>
          <DoublonsMultipleDataCard
            title={t(ETLCodes.Titres)}
            labelPredicate={(f: FcbFormateurTitreDto) => f.libelle}
            fieldPath={`formateurs[${index}].donneesProfessionnelles.titres`}
            finalEntityKey={FO_FINAL_KEY}
            interactive={() => false}
            forceSelected={() => false}
          >
            {(f: FcbFormateurTitreDto) => (
              <InformationContainer key={f.idtitreFormateur}>
                {t(ETLCodes.Date)}: {f.date && format(f.date, "dd-MM-yyyy")}
              </InformationContainer>
            )}
          </DoublonsMultipleDataCard>
          <DoublonsMultipleDataCard
            title={t(ETLCodes.Diplomes)}
            labelPredicate={(f: FcbFormateurDiplomeDto) => f.libelle}
            fieldPath={`formateurs[${index}].donneesProfessionnelles.diplomes`}
            finalEntityKey={FO_FINAL_KEY}
            interactive={() => false}
            forceSelected={() => false}
          >
            {(f: FcbFormateurDiplomeDto) => (
              <InformationContainer key={f.iddiplome}>
                <div>
                  {t(ETLCodes.Date)}: {f.date && format(f.date, "dd-MM-yyyy")}
                </div>
                <div>
                  {t(ETLCodes.Type)}: {f.type}
                </div>
                <div>
                  {t(ETLCodes.URL)}: {f.url}
                </div>
              </InformationContainer>
            )}
          </DoublonsMultipleDataCard>
        </FieldSet>
      );
    },
    [data?.formateurs, t]
  );

  return (
    <Container>
      <CarouselComparer leftElement={formateurContainer(selectedIndex, selectedIndex + 1)}>
        {data?.formateurs
          ?.filter((a, i) => i !== selectedIndex)
          ?.map((a, i) => formateurContainer(i + (i >= selectedIndex ? 1 : 0), i + (i >= selectedIndex ? 2 : 1)))}
      </CarouselComparer>
    </Container>
  );
};
