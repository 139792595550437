/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailDto } from './EmailDto';
import {
    EmailDtoFromJSON,
    EmailDtoFromJSONTyped,
    EmailDtoToJSON,
} from './EmailDto';
import type { TelephoneDto } from './TelephoneDto';
import {
    TelephoneDtoFromJSON,
    TelephoneDtoFromJSONTyped,
    TelephoneDtoToJSON,
} from './TelephoneDto';

/**
 * 
 * @export
 * @interface TuteurGridDto
 */
export interface TuteurGridDto {
    /**
     * 
     * @type {number}
     * @memberof TuteurGridDto
     */
    idtuteur?: number;
    /**
     * 
     * @type {number}
     * @memberof TuteurGridDto
     */
    idpersonne?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TuteurGridDto
     */
    c?: number;
    /**
     * 
     * @type {number}
     * @memberof TuteurGridDto
     */
    cf?: number;
    /**
     * 
     * @type {string}
     * @memberof TuteurGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TuteurGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TuteurGridDto
     */
    lastLieuFormationId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TuteurGridDto
     */
    lastLieuFormationNom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TuteurGridDto
     */
    lastLieuFormationEnseigne?: string | null;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof TuteurGridDto
     */
    telephone?: TelephoneDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof TuteurGridDto
     */
    gsm?: TelephoneDto;
    /**
     * 
     * @type {EmailDto}
     * @memberof TuteurGridDto
     */
    email?: EmailDto;
    /**
     * 
     * @type {string}
     * @memberof TuteurGridDto
     */
    localite?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof TuteurGridDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof TuteurGridDto
     */
    localiteNaissance?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TuteurGridDto
     */
    actif?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TuteurGridDto
     */
    registreNational?: string | null;
}

/**
 * Check if a given object implements the TuteurGridDto interface.
 */
export function instanceOfTuteurGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TuteurGridDtoFromJSON(json: any): TuteurGridDto {
    return TuteurGridDtoFromJSONTyped(json, false);
}

export function TuteurGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TuteurGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idtuteur': !exists(json, 'idtuteur') ? undefined : json['idtuteur'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'c': !exists(json, 'c') ? undefined : json['c'],
        'cf': !exists(json, 'cf') ? undefined : json['cf'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'lastLieuFormationId': !exists(json, 'lastLieuFormationId') ? undefined : json['lastLieuFormationId'],
        'lastLieuFormationNom': !exists(json, 'lastLieuFormationNom') ? undefined : json['lastLieuFormationNom'],
        'lastLieuFormationEnseigne': !exists(json, 'lastLieuFormationEnseigne') ? undefined : json['lastLieuFormationEnseigne'],
        'telephone': !exists(json, 'telephone') ? undefined : TelephoneDtoFromJSON(json['telephone']),
        'gsm': !exists(json, 'gsm') ? undefined : TelephoneDtoFromJSON(json['gsm']),
        'email': !exists(json, 'email') ? undefined : EmailDtoFromJSON(json['email']),
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'localiteNaissance': !exists(json, 'localiteNaissance') ? undefined : json['localiteNaissance'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'registreNational': !exists(json, 'registreNational') ? undefined : json['registreNational'],
    };
}

export function TuteurGridDtoToJSON(value?: TuteurGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idtuteur': value.idtuteur,
        'idpersonne': value.idpersonne,
        'c': value.c,
        'cf': value.cf,
        'nom': value.nom,
        'prenom': value.prenom,
        'lastLieuFormationId': value.lastLieuFormationId,
        'lastLieuFormationNom': value.lastLieuFormationNom,
        'lastLieuFormationEnseigne': value.lastLieuFormationEnseigne,
        'telephone': TelephoneDtoToJSON(value.telephone),
        'gsm': TelephoneDtoToJSON(value.gsm),
        'email': EmailDtoToJSON(value.email),
        'localite': value.localite,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'localiteNaissance': value.localiteNaissance,
        'actif': value.actif,
        'registreNational': value.registreNational,
    };
}

