/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailDto } from './EmailDto';
import {
    EmailDtoFromJSON,
    EmailDtoFromJSONTyped,
    EmailDtoToJSON,
} from './EmailDto';
import type { TelephoneDto } from './TelephoneDto';
import {
    TelephoneDtoFromJSON,
    TelephoneDtoFromJSONTyped,
    TelephoneDtoToJSON,
} from './TelephoneDto';

/**
 * 
 * @export
 * @interface ContactSiegeEditDto
 */
export interface ContactSiegeEditDto {
    /**
     * 
     * @type {number}
     * @memberof ContactSiegeEditDto
     */
    idcontactSiege?: number;
    /**
     * 
     * @type {number}
     * @memberof ContactSiegeEditDto
     */
    idsiegeSocial?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeEditDto
     */
    remarqueSiege?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContactSiegeEditDto
     */
    idcontact?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ContactSiegeEditDto
     */
    principal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeEditDto
     */
    fonction?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ContactSiegeEditDto
     */
    sortieEntreprise?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ContactSiegeEditDto
     */
    idpersonne?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeEditDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeEditDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeEditDto
     */
    codesexe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeEditDto
     */
    nationalite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeEditDto
     */
    codePaysNaissance?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ContactSiegeEditDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeEditDto
     */
    registreNational?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeEditDto
     */
    numeroIdentification?: string | null;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof ContactSiegeEditDto
     */
    telephone?: TelephoneDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof ContactSiegeEditDto
     */
    gsm?: TelephoneDto;
    /**
     * 
     * @type {EmailDto}
     * @memberof ContactSiegeEditDto
     */
    email?: EmailDto;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeEditDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSiegeEditDto
     */
    photo?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContactSiegeEditDto
     */
    idfichierPhoto?: number | null;
}

/**
 * Check if a given object implements the ContactSiegeEditDto interface.
 */
export function instanceOfContactSiegeEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContactSiegeEditDtoFromJSON(json: any): ContactSiegeEditDto {
    return ContactSiegeEditDtoFromJSONTyped(json, false);
}

export function ContactSiegeEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactSiegeEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontactSiege': !exists(json, 'idcontactSiege') ? undefined : json['idcontactSiege'],
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'remarqueSiege': !exists(json, 'remarqueSiege') ? undefined : json['remarqueSiege'],
        'idcontact': !exists(json, 'idcontact') ? undefined : json['idcontact'],
        'principal': !exists(json, 'principal') ? undefined : json['principal'],
        'fonction': !exists(json, 'fonction') ? undefined : json['fonction'],
        'sortieEntreprise': !exists(json, 'sortieEntreprise') ? undefined : (json['sortieEntreprise'] === null ? null : new Date(json['sortieEntreprise'])),
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'codesexe': !exists(json, 'codesexe') ? undefined : json['codesexe'],
        'nationalite': !exists(json, 'nationalite') ? undefined : json['nationalite'],
        'codePaysNaissance': !exists(json, 'codePaysNaissance') ? undefined : json['codePaysNaissance'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'registreNational': !exists(json, 'registreNational') ? undefined : json['registreNational'],
        'numeroIdentification': !exists(json, 'numeroIdentification') ? undefined : json['numeroIdentification'],
        'telephone': !exists(json, 'telephone') ? undefined : TelephoneDtoFromJSON(json['telephone']),
        'gsm': !exists(json, 'gsm') ? undefined : TelephoneDtoFromJSON(json['gsm']),
        'email': !exists(json, 'email') ? undefined : EmailDtoFromJSON(json['email']),
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'photo': !exists(json, 'photo') ? undefined : json['photo'],
        'idfichierPhoto': !exists(json, 'idfichierPhoto') ? undefined : json['idfichierPhoto'],
    };
}

export function ContactSiegeEditDtoToJSON(value?: ContactSiegeEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontactSiege': value.idcontactSiege,
        'idsiegeSocial': value.idsiegeSocial,
        'remarqueSiege': value.remarqueSiege,
        'idcontact': value.idcontact,
        'principal': value.principal,
        'fonction': value.fonction,
        'sortieEntreprise': value.sortieEntreprise === undefined ? undefined : (value.sortieEntreprise === null ? null : value.sortieEntreprise.toISOString()),
        'idpersonne': value.idpersonne,
        'nom': value.nom,
        'prenom': value.prenom,
        'codesexe': value.codesexe,
        'nationalite': value.nationalite,
        'codePaysNaissance': value.codePaysNaissance,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'registreNational': value.registreNational,
        'numeroIdentification': value.numeroIdentification,
        'telephone': TelephoneDtoToJSON(value.telephone),
        'gsm': TelephoneDtoToJSON(value.gsm),
        'email': EmailDtoToJSON(value.email),
        'remarque': value.remarque,
        'photo': value.photo,
        'idfichierPhoto': value.idfichierPhoto,
    };
}

