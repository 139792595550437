import { Button, ButtonGroup, Colors, Spinner, Tag } from "@blueprintjs/core";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { IFGCustomInputProps, useFGContext } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";

import { RepresentantSelectorDialog } from ".";
import { ClearButton, FGReadOnlyInput } from "../..";
import { RepresentantSelectorApi } from "../../../api";
import { ERoutes } from "../../../AppRouter";
import { useAuth } from "../../../contexts";
import { useApiService } from "../../../hooks";

const InputContainer = styled.div`
  padding: 0.4rem 0 0 0.6rem;
`;

const StyledTag = styled(Tag)`
  & a {
    color: ${Colors.WHITE};
  }
`;

const NamesContainer = styled.div`
  > * + * {
    margin-left: 5px;
  }
`;

export interface IRepresentantSelectorFieldProps extends Omit<IFGCustomInputProps, "children"> {
  parentRepresentantIds?: number[];
  disabled?: boolean;
  allowMultipleSelection?: boolean;
  ref?: React.Ref<HTMLDivElement>;
}

export const RepresentantSelectorField: React.FunctionComponent<IRepresentantSelectorFieldProps> = ({
  name,
  parentRepresentantIds = [],
  label = null,
  disabled = false,
  className,
  allowMultipleSelection = false,
  ref,
  ...fgCustomInputProps
}) => {
  const { hasPermission } = useAuth();
  const ctx = useFGContext();
  const api = useApiService(RepresentantSelectorApi);

  const hasValue = React.useMemo(
    () =>
      (allowMultipleSelection && !isEmpty(ctx?.formik?.values?.[name])) ||
      (!allowMultipleSelection && !!ctx?.formik?.values?.[name]),
    [allowMultipleSelection, ctx?.formik?.values, name]
  );
  const [selectorDialogOpen, setSelectorDialogOpen] = React.useState(false);
  const [currentSelectedRepresentantIds, setSelectedRepresentantIds] = React.useState<number[]>([]);

  const { data: displayNames, isFetching: loadingDisplayNames } = useQuery(
    ["representant-display-names", currentSelectedRepresentantIds],
    async () => api.representantSelectorGetDisplayNames({ request_body: currentSelectedRepresentantIds })
  );

  const clearSelectedRepresentant = React.useCallback(() => {
    ctx?.formik?.setFieldValue(name, allowMultipleSelection ? [] : null, true);
    setSelectedRepresentantIds([]);
  }, [allowMultipleSelection, ctx?.formik, name]);

  React.useEffect(() => {
    if (ctx?.formik?.values?.[name]) {
      const value = ctx?.formik?.values?.[name];
      setSelectedRepresentantIds(isArray(value) ? value : [value]);
    } else {
      setSelectedRepresentantIds([]);
    }
  }, [ctx?.formik?.values, name]);

  const onRepresentantsSelected = React.useCallback(
    (selectedRepresentantIds?: number[]) => {
      if (selectedRepresentantIds?.length > 0) {
        ctx?.formik?.setFieldTouched(name);
        ctx?.formik?.setFieldValue(
          name,
          allowMultipleSelection ? selectedRepresentantIds : selectedRepresentantIds[0],
          true
        );
        setSelectedRepresentantIds(selectedRepresentantIds);
      }
    },
    [allowMultipleSelection, ctx?.formik, name]
  );

  const selectorProps = React.useMemo(
    () => ({
      excludedRepresentantIds: parentRepresentantIds,
      allowMultipleSelection,
      preSelectedIds: currentSelectedRepresentantIds
    }),
    [allowMultipleSelection, parentRepresentantIds, currentSelectedRepresentantIds]
  );

  const openRepresentantSelector = React.useCallback(() => {
    setSelectorDialogOpen(true);
  }, []);

  const getEditRoute = React.useCallback(
    (id: number) =>
      `#${ERoutes.representant}/${id}/detail/${hasPermission("REPRESENTANT", "RW", "DETAIL") ? "edit" : "view"}`,
    [hasPermission]
  );

  const removeRepresentantId = React.useCallback(
    (representantId: number) => {
      const newRepresentantIds = [...currentSelectedRepresentantIds];
      newRepresentantIds.splice(currentSelectedRepresentantIds.indexOf(representantId), 1);
      ctx?.formik?.setFieldTouched(name);
      ctx?.formik?.setFieldValue(name, newRepresentantIds, true);
      setSelectedRepresentantIds(newRepresentantIds);
    },
    [ctx?.formik, currentSelectedRepresentantIds, name]
  );

  const getRepresentantLink = React.useCallback(
    (id: number, name: string, tags: boolean) => {
      const link = hasPermission("REPRESENTANT", "R", "DETAIL") ? (
        <a href={getEditRoute(id)} target="_blank" rel="noreferrer" tabIndex={-1} key={id}>
          {name}
        </a>
      ) : (
        name
      );
      return tags ? (
        <StyledTag key={id} onRemove={() => removeRepresentantId(id)}>
          {link}
        </StyledTag>
      ) : (
        link
      );
    },
    [getEditRoute, hasPermission, removeRepresentantId]
  );

  const onClosed = React.useCallback(
    (selectedRepresentantIds?: number[]) => {
      onRepresentantsSelected(selectedRepresentantIds);
      setSelectorDialogOpen(false);
    },
    [onRepresentantsSelected]
  );

  return (
    <>
      <FGReadOnlyInput
        name={name}
        label={label}
        leftElement={
          <InputContainer>
            {loadingDisplayNames ? (
              <Spinner size={16} />
            ) : (
              <NamesContainer>
                {displayNames.map(dn => getRepresentantLink(dn.id, dn.text, allowMultipleSelection))}
              </NamesContainer>
            )}
          </InputContainer>
        }
        rightElement={
          <ButtonGroup>
            {hasValue && <ClearButton onClick={clearSelectedRepresentant} tabIndex={-1} disabled={disabled} />}
            <Button icon="people" minimal={true} onClick={openRepresentantSelector} disabled={disabled} tabIndex={-1} />
          </ButtonGroup>
        }
        {...fgCustomInputProps}
      />
      <RepresentantSelectorDialog
        onClosed={onClosed}
        onRepresentantSelected={onClosed}
        selectorDialogOpen={selectorDialogOpen}
        {...selectorProps}
      />
    </>
  );
};
