import * as React from "react";
import { Redirect } from "react-router";
import { ERoutes } from "../../AppRouter";
import { useAuth } from "../../contexts";

export interface IProtectedRedirectProps {}

export const ProtectedRedirect: React.FunctionComponent<IProtectedRedirectProps> = () => {
  const { userPermissions } = useAuth();
  const hasAnyPermission = React.useMemo(() => userPermissions?.length > 0, [userPermissions]);
  return <>{hasAnyPermission ? <Redirect to={ERoutes.apprenant} /> : <Redirect to={ERoutes.unauthorized} />}</>;
};
