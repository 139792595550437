import { Checkbox } from "@blueprintjs/core";
import * as React from "react";
import { useHistory } from "react-router";

import { FcbPaysGridDto, PaysApi, PaysSearch, EBooleanSearchTypes } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { useAbortableApiServiceFactory, useApiService } from "../../../../../hooks/useApiService";
import { ETLCodes } from "../../../../../locales";
import { ButtonContainer } from "nsitools-react";
import styled from "styled-components";

interface IPaysListPageProps {}

const CenteredCheckbox = styled(Checkbox)`
  text-align: center;
`;

export const PaysListPage: React.FunctionComponent<IPaysListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(PaysApi);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbPaysGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => history.push(`${ERoutes.pays}/${row.codePays}/detail/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${ERoutes.pays}/${row.codePays}/detail/edit`)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.PaysListPageCodePays),
        fieldName: "codePays",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.PaysListPagePays),
        fieldName: "pays"
      },
      {
        header: () => t(ETLCodes.PaysListPageMasculin),
        fieldName: "masculin"
      },
      {
        header: () => t(ETLCodes.PaysListPageFeminin),
        fieldName: "feminin"
      },
      {
        header: () => t(ETLCodes.PaysListPageEurope),
        fieldName: "europe",
        autoFitContent: true,
        render: (row: FcbPaysGridDto) => <CenteredCheckbox checked={row.europe} disabled />
      }
    ],
    [history, t]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.pays}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(() => api.paysGetSearchCriterias({ includeListsValues: true }), [api]);

  const apiFactory = useAbortableApiServiceFactory(PaysApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: PaysSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.paysBaseSearch({ PaysSearch: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      columns={columns}
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      addFunc={addItemFunction}
      breadCrumbs={[{ text: t(ETLCodes.Pays), route: ERoutes.pays }]}
      sortKeys={{ pays: "ASC" }}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
    />
  );
};
