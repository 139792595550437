import * as React from "react";
import { Route, Switch } from "react-router";
import { ERoutes } from "../../../../../AppRouter";

import { UtilisateurCentresLiesPage } from "./UtilisateurCentresLiesPage";
import { UtilisateurCentresLiesSelector } from "./UtilisateurCentresLiesSelector";

export interface IUtilisateurCentresLiesSwitchProps {}

export const UtilisateurCentresLiesSwitch: React.FunctionComponent<IUtilisateurCentresLiesSwitchProps> = () => {
  return (
    <Switch>
      <Route path={`${ERoutes.users}/:id/:tab/:state`} component={UtilisateurCentresLiesPage} exact />
      <Route path={`${ERoutes.users}/:id/:tab/:state/selector`} component={UtilisateurCentresLiesSelector} />
    </Switch>
  );
};
