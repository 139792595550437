import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import {
  ApprenantApi,
  ApprenantRepresentantHopeApi,
  ApprenantRepresentantPersonneEditDto,
  ApprenantRepresentantPersonneEditDtoFromJSON,
  RepresentantApi
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import * as Yup from "yup";
import { ETLCodes } from "../../../../../locales";
import { useReferential } from "../../../../../hooks/useReferential";
import {
  AdresseInfos,
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  RepresentantSelectorField,
  SmallFormGenerator
} from "../../../../../components";
import { FGCustomInput, FGCustomPanel, FGListen, FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import { IOptionProps } from "@blueprintjs/core";
import { FormikProps } from "formik";
import { useQuery } from "react-query";

export interface IApprenantRepresentantPersonneDetailProps {
  onTypeRepresentantChange: (typeAcquis: string) => void;
}

export const ApprenantRepresentantPersonneDetail: React.FunctionComponent<IApprenantRepresentantPersonneDetailProps> = ({
  onTypeRepresentantChange
}) => {
  const { t, tUnsafe } = useTl();
  const history = useHistory();
  const [typeRepresentant, trLoading] = useReferential(a => a.referentialGetRepresentantTypes(), false, []);
  const mappedTypeRepresentant = React.useMemo(
    () => typeRepresentant?.map(l => ({ ...l, label: tUnsafe(l.label) } as IOptionProps)) ?? [],
    [typeRepresentant, tUnsafe]
  );
  const [lienRepresentant, lrLoading, , rawLiensParente] = useReferential(
    a => a.referentialGetRepresentantLiens(),
    true,
    []
  );

  const formikInnerRef = React.useRef<FormikProps<ApprenantRepresentantPersonneEditDto>>();

  const { id, state, subId } = useParams<{
    id: string;
    tab: string;
    state: string;
    subId: string;
  }>();
  const editMode = React.useMemo(() => state === "edit", [state]);
  const api = useApiService(ApprenantRepresentantHopeApi);
  const representantApi = useApiService(RepresentantApi);
  const apprenantApi = useApiService(ApprenantApi);
  const { data, loading, saveItem, saving, deleteItem, deleting, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: async () => {
          return +subId <= 0
            ? ApprenantRepresentantPersonneEditDtoFromJSON({
                idapprenant: +id,
                type: "Personne"
              })
            : api.apprenantRepresentantHopeGetApprenantRepresentantPersonne({
                idapprenantRepresentant: +subId,
                idapprenant: +id
              });
        },
        saveApiFn: d =>
          api.apprenantRepresentantHopeSaveApprenantRepresentantPersonne({ ApprenantRepresentantPersonneEditDto: d }),
        onSavedRoute: d => `${ERoutes.apprenant}/${id}/representants`,
        getDeps: [subId],
        deleteApiFn: d =>
          api.apprenantRepresentantHopeDeleteApprenantRepresentantPersonne({
            idapprenantRepresentant: +subId,
            idapprenant: +id
          }),
        onDeletedRoute: () => `${ERoutes.apprenant}/${id}/representants`
      }),
      [subId, id, api]
    )
  );
  const [previousIdrepresentant, setPreviousIdrepresentant] = React.useState<number>();
  const [representantIdFromSelect, setRepresentantIdFromSelect] = React.useState<number>();
  React.useEffect(() => {
    if (data?.idrepresentant) {
      setPreviousIdrepresentant(data?.idrepresentant);
      setRepresentantIdFromSelect(data?.idrepresentant);
    }
  }, [data?.idrepresentant]);
  const fetchRepresentantId = React.useCallback(async () => {
    if (!representantIdFromSelect || representantIdFromSelect === 0) return null;
    return await representantApi.representantGet({ id: representantIdFromSelect });
  }, [representantApi, representantIdFromSelect]);

  const { data: representantPersonne, isLoading: representantPersonneLoading } = useQuery(
    ["representant-for-select", representantIdFromSelect],
    fetchRepresentantId,
    { enabled: true }
  );
  React.useEffect(() => {
    if (
      representantIdFromSelect !== 0 &&
      representantIdFromSelect !== previousIdrepresentant &&
      !!representantPersonne &&
      !loading
    ) {
      formikInnerRef.current?.setFieldValue("dateNaissance", representantPersonne?.dateNaissance);
      formikInnerRef.current?.setFieldValue("nationalite", representantPersonne?.nationalite);
      formikInnerRef.current?.setFieldValue("numeroNational", representantPersonne?.numeroNational);
      formikInnerRef.current?.setFieldValue("adresseNaissance", representantPersonne?.adresseNaissance);
      formikInnerRef.current?.setFieldValue("adresseRepresentant", representantPersonne?.adresseRepresentant);
      formikInnerRef.current?.setFieldValue("idgsm", representantPersonne?.gsmRepresentant?.idtelephone);
      formikInnerRef.current?.setFieldValue("idtelephone", representantPersonne?.telephoneRepresentant?.idtelephone);
      formikInnerRef.current?.setFieldValue("idemail", representantPersonne?.emailRepresentant?.idemail);
      setPreviousIdrepresentant(representantIdFromSelect);
    }
  }, [loading, previousIdrepresentant, representantIdFromSelect, representantPersonne]);

  const { data: alreadyContact } = useQuery(
    ["alreadyContact-check"],
    () => {
      return api.apprenantRepresentantHopeAlreadyHasEmergencyContactQuery({
        idapprenant: +id,
        idapprenantRepresentant: +subId
      });
    },
    { enabled: true }
  );

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape(
      {
        type: Yup.string().required(t(ETLCodes.Required)),
        idrepresentant: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        idgsm: Yup.number()
          .nullable()
          .when(["idtelephone", "idemail"], {
            is: (idtelephone, idemail) => !idtelephone && !idemail,
            then: Yup.number()
              .nullable()
              .required(t(ETLCodes.AtLeastOneContactField))
          }),
        idtelephone: Yup.number()
          .nullable()
          .when(["idemail", "idgsm"], {
            is: (idgsm, idemail) => !idgsm && !idemail,
            then: Yup.number()
              .nullable()
              .required(t(ETLCodes.AtLeastOneContactField))
          }),
        idemail: Yup.number()
          .nullable()
          .when(["idtelephone", "idgsm"], {
            is: (idgsm, idtelephone) => !idgsm && !idtelephone,
            then: Yup.number()
              .nullable()
              .required(t(ETLCodes.AtLeastOneContactField))
          })
      },
      [
        ["idgsm", "idtelephone"],
        ["idgsm", "idemail"],
        ["idtelephone", "idemail"]
      ]
    );
  }, [t]);

  const fetchIdPersonne = React.useCallback(async () => {
    if (!+(representantIdFromSelect ?? 0)) return 0;

    return await representantApi.representantGetIdpersonne({ idrepresentant: representantIdFromSelect });
  }, [representantApi, representantIdFromSelect]);
  const { data: idpersonne } = useQuery(
    ["apprenant-representant-idpersonne", representantIdFromSelect],
    fetchIdPersonne
  );

  const [personneTelephones, personneTelephonesLoading] = useReferential(
    a => a.referentialGetTelephonesPersonne({ idpersonne }),
    false,
    [idpersonne]
  );

  const [personneGsms, personneGsmsLoading] = useReferential(a => a.referentialGetGsmsPersonne({ idpersonne }), false, [
    idpersonne
  ]);
  const [personneEmails, personneEmailsLoading] = useReferential(
    a => a.referentialGetEmailsPersonne({ idpersonne }),
    false,
    [idpersonne]
  );

  const fetchAlreadySelectedRepresentantIds = React.useCallback(async () => {
    if (+id <= 0) return [];

    return await apprenantApi.apprenantGetAlreadySelectedRepresentantIds({ idapprenant: +id });
  }, [apprenantApi, id]);
  const { data: alreadySelectedRepresentantIds } = useQuery(
    ["apprenant-alreadyselected-representants", id],
    fetchAlreadySelectedRepresentantIds
  );
  const [nationalites, nLoading] = useReferential(a => a.referentialGetAllNationalite(), false, []);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      showColons
      editMode={editMode}
      minLabelWidth={180}
      labelAlignment="right"
      formatDate="dd/MM/yyyy"
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(`${ERoutes.apprenant}/${id}/representants`)}
      saving={saving}
      showDeleteButton={+subId > 0}
      onDelete={deleteItem}
      deleting={deleting}
      formikInnerRef={formikInnerRef}
      validationErrors={validationErrors}
    >
      <FieldGroup columns={2}>
        <FieldGroup
          fieldsetProps={{
            title: t(ETLCodes.Representant)
          }}
        >
          <FGWalterSelectInput
            label={t(ETLCodes.Type)}
            name="type"
            items={mappedTypeRepresentant}
            loading={trLoading}
            readonly={+(subId ?? 0) > 0}
            isCleareable={false}
          />
          <FGListen field="type" onChanged={onTypeRepresentantChange}></FGListen>
          <FGListen field="idrepresentant" onChanged={value => setRepresentantIdFromSelect(value)}></FGListen>
          <FGListen
            field="idlienParente"
            onChanged={(value, formik) => {
              if (!!value) {
                const lp = rawLiensParente?.find(l => +l.idValue === +value);
                if (lp?.keyValue === "TuteurLegal") {
                  formik.setFieldValue("representantLegal", true);
                }
              }
            }}
          ></FGListen>
          <RepresentantSelectorField
            label={t(ETLCodes.Representant)}
            name="idrepresentant"
            parentRepresentantIds={alreadySelectedRepresentantIds ?? []}
          />
          <FGWalterDateMaskInput name="dateNaissance" label={t(ETLCodes.DateNaissance)} readonly />
          <FGWalterSelectInput
            name="nationalite"
            label={t(ETLCodes.Nationalite)}
            readonly
            items={nationalites}
            loading={nLoading}
          />
          <FGTextInput name="numeroNational" label={t(ETLCodes.NumeroNational)} readonly />
          <FGTextInput name="adresseNaissance" label={t(ETLCodes.LocaliteNaissance)} readonly />
          <FGCustomInput label={t(ETLCodes.Adresse)}>
            {ctx =>
              ctx?.formik?.values?.adresseRepresentant && (
                <AdresseInfos withCard data={ctx?.formik?.values?.adresseRepresentant} />
              )
            }
          </FGCustomInput>
          <FGCustomPanel>
            {ctx => (
              <>
                <FGWalterSelectInput
                  name="idgsm"
                  label={t(ETLCodes.Gsm)}
                  items={personneGsms}
                  loading={personneGsmsLoading}
                  disabled={!ctx.formik.values?.idrepresentant}
                  autoSelectIfOne={+subId <= 0}
                  itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
                  itemCreateParams={`&typeTel=GSM_PERSO`}
                />
                <FGWalterSelectInput
                  name="idtelephone"
                  label={t(ETLCodes.Tel)}
                  items={personneTelephones}
                  loading={personneTelephonesLoading}
                  disabled={!ctx.formik.values?.idrepresentant}
                  autoSelectIfOne={+subId <= 0}
                  itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
                  itemCreateParams={`&typeTel=PERSO`}
                />
                <FGWalterSelectInput
                  name="idemail"
                  label={t(ETLCodes.General_Email)}
                  loading={personneEmailsLoading}
                  disabled={!ctx.formik.values?.idrepresentant}
                  items={personneEmails}
                  autoSelectIfOne={+subId <= 0}
                  itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
                  itemCreateParams={`&typeEmail=PERSO`}
                />
              </>
            )}
          </FGCustomPanel>

          <FGCustomPanel>
            {ctx => (
              <>
                <FGWalterSelectInput
                  label={t(ETLCodes.Lien)}
                  name="idlienParente"
                  items={lienRepresentant}
                  loading={lrLoading || representantPersonneLoading}
                  disabled={!ctx.formik.values?.idrepresentant}
                />
                <FGTextInput
                  name="autreLien"
                  label={t(ETLCodes.Autre)}
                  visible={ctx => {
                    const lp = rawLiensParente?.find(l => +l.idValue === +(ctx?.formik?.values?.idlienParente ?? 0));
                    return lp?.keyValue === "AutreLien" || lp?.keyValue === "AutreLienFamiliale";
                  }}
                />
                <FGWalterCheckboxInput
                  label={t(ETLCodes.EmergencyContact)}
                  name="isEmergencyContact"
                  disabled={
                    (ctx?.formik?.values?.alreadyHasEmergencyContact || alreadyContact?.value) &&
                    !ctx?.formik?.values?.isEmergencyContact
                  }
                  helperText={
                    (ctx?.formik?.values?.alreadyHasEmergencyContact || alreadyContact?.value) &&
                    !ctx?.formik?.values?.isEmergencyContact &&
                    t(ETLCodes.AlreadyHasEmergencyContact)
                  }
                />

                <FGWalterCheckboxInput
                  label={t(ETLCodes.RepresentantLegal)}
                  name="representantLegal"
                  disabled={
                    rawLiensParente?.find(l => +l.idValue === +(ctx?.formik?.values?.idlienParente ?? 0))?.keyValue ===
                    "TuteurLegal"
                  }
                />
              </>
            )}
          </FGCustomPanel>
          <FGWalterCheckboxInput label={t(ETLCodes.EnvoiCourrier)} name="courrier" />
          <FGTextAreaInput name="remarque" label={t(ETLCodes.Remarque)} />
        </FieldGroup>
      </FieldGroup>
    </SmallFormGenerator>
  );
};
