/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { Int32ListSearch } from './Int32ListSearch';
import {
    Int32ListSearchFromJSON,
    Int32ListSearchFromJSONTyped,
    Int32ListSearchToJSON,
} from './Int32ListSearch';
import type { Int32NullableListSearch } from './Int32NullableListSearch';
import {
    Int32NullableListSearchFromJSON,
    Int32NullableListSearchFromJSONTyped,
    Int32NullableListSearchToJSON,
} from './Int32NullableListSearch';

/**
 * 
 * @export
 * @interface DashboardSuiviSiegeLieuSearch
 */
export interface DashboardSuiviSiegeLieuSearch {
    /**
     * 
     * @type {Int32NullableListSearch}
     * @memberof DashboardSuiviSiegeLieuSearch
     */
    idsiegeSocial?: Int32NullableListSearch;
    /**
     * 
     * @type {Int32NullableListSearch}
     * @memberof DashboardSuiviSiegeLieuSearch
     */
    idlieuFormation?: Int32NullableListSearch;
    /**
     * 
     * @type {Int32NullableListSearch}
     * @memberof DashboardSuiviSiegeLieuSearch
     */
    idencodeur?: Int32NullableListSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof DashboardSuiviSiegeLieuSearch
     */
    idservice?: Int32ListSearch;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardSuiviSiegeLieuSearch
     */
    onlyUserDashboard?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardSuiviSiegeLieuSearch
     */
    hideMasquees?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardSuiviSiegeLieuSearch
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof DashboardSuiviSiegeLieuSearch
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardSuiviSiegeLieuSearch
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardSuiviSiegeLieuSearch
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof DashboardSuiviSiegeLieuSearch
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardSuiviSiegeLieuSearch
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the DashboardSuiviSiegeLieuSearch interface.
 */
export function instanceOfDashboardSuiviSiegeLieuSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DashboardSuiviSiegeLieuSearchFromJSON(json: any): DashboardSuiviSiegeLieuSearch {
    return DashboardSuiviSiegeLieuSearchFromJSONTyped(json, false);
}

export function DashboardSuiviSiegeLieuSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardSuiviSiegeLieuSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : Int32NullableListSearchFromJSON(json['idsiegeSocial']),
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : Int32NullableListSearchFromJSON(json['idlieuFormation']),
        'idencodeur': !exists(json, 'idencodeur') ? undefined : Int32NullableListSearchFromJSON(json['idencodeur']),
        'idservice': !exists(json, 'idservice') ? undefined : Int32ListSearchFromJSON(json['idservice']),
        'onlyUserDashboard': !exists(json, 'onlyUserDashboard') ? undefined : json['onlyUserDashboard'],
        'hideMasquees': !exists(json, 'hideMasquees') ? undefined : json['hideMasquees'],
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function DashboardSuiviSiegeLieuSearchToJSON(value?: DashboardSuiviSiegeLieuSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsiegeSocial': Int32NullableListSearchToJSON(value.idsiegeSocial),
        'idlieuFormation': Int32NullableListSearchToJSON(value.idlieuFormation),
        'idencodeur': Int32NullableListSearchToJSON(value.idencodeur),
        'idservice': Int32ListSearchToJSON(value.idservice),
        'onlyUserDashboard': value.onlyUserDashboard,
        'hideMasquees': value.hideMasquees,
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

