import { Checkbox } from "@blueprintjs/core";
import { ButtonContainer, IDataTableColumn } from "nsitools-react";
import * as React from "react";

import { FcbUserGridDto, UserSearch, UtilisateurApi, EBooleanSearchTypes } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../components";
import { useTl } from "../../../../hooks";
import { useAbortableApiServiceFactory, useApiService } from "../../../../hooks/useApiService";
import { ETLCodes } from "../../../../locales";
import { useHistory } from "react-router";

export interface IUtilisateurListPageProps {}

export const UtilisateurListPage: React.FunctionComponent<IUtilisateurListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(UtilisateurApi);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbUserGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => history.push(`${ERoutes.users}/${row.iduser}/detail/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${ERoutes.users}/${row.iduser}/detail/edit`)} />
          </ButtonContainer>
        )
      },
      { header: () => t(ETLCodes.IdUser), fieldName: "iduser" },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.Identifiant),
        fieldName: "login"
      },
      {
        header: () => t(ETLCodes.Connecte),
        fieldName: "connecte",
        alignment: "center",
        render: (row: FcbUserGridDto) => <Checkbox checked={row.connecte} />
      }
    ],
    [history, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.users}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(() => api.utilisateurGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const apiFactory = useAbortableApiServiceFactory(UtilisateurApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: UserSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.utilisateurBaseSearch({ UserSearch: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      columns={columns}
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
      addFunc={onAddItem}
      breadCrumbs={[{ text: t(ETLCodes.Collaborateurs), route: ERoutes.users }]}
      sortKeys={{ nom: "ASC" }}
      searchStateInitialSearch={false}
    />
  );
};
