/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LieuFormationFichierDto
 */
export interface LieuFormationFichierDto {
    /**
     * 
     * @type {number}
     * @memberof LieuFormationFichierDto
     */
    idlieuFormationFichier?: number;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationFichierDto
     */
    idlieuFormation?: number;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationFichierDto
     */
    idfichier?: number;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationFichierDto
     */
    idtypeLieuFormationFichier?: number;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationFichierDto
     */
    fileName?: string | null;
}

/**
 * Check if a given object implements the LieuFormationFichierDto interface.
 */
export function instanceOfLieuFormationFichierDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LieuFormationFichierDtoFromJSON(json: any): LieuFormationFichierDto {
    return LieuFormationFichierDtoFromJSONTyped(json, false);
}

export function LieuFormationFichierDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LieuFormationFichierDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idlieuFormationFichier': !exists(json, 'idlieuFormationFichier') ? undefined : json['idlieuFormationFichier'],
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'idtypeLieuFormationFichier': !exists(json, 'idtypeLieuFormationFichier') ? undefined : json['idtypeLieuFormationFichier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
    };
}

export function LieuFormationFichierDtoToJSON(value?: LieuFormationFichierDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idlieuFormationFichier': value.idlieuFormationFichier,
        'idlieuFormation': value.idlieuFormation,
        'idfichier': value.idfichier,
        'idtypeLieuFormationFichier': value.idtypeLieuFormationFichier,
        'fileName': value.fileName,
    };
}

