/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AnneeFormationSuivieIfapmeFichierGridDto } from './AnneeFormationSuivieIfapmeFichierGridDto';
import {
    AnneeFormationSuivieIfapmeFichierGridDtoFromJSON,
    AnneeFormationSuivieIfapmeFichierGridDtoFromJSONTyped,
    AnneeFormationSuivieIfapmeFichierGridDtoToJSON,
} from './AnneeFormationSuivieIfapmeFichierGridDto';

/**
 * 
 * @export
 * @interface AnneeFormationSuivieIfapmeEditDto
 */
export interface AnneeFormationSuivieIfapmeEditDto {
    /**
     * 
     * @type {number}
     * @memberof AnneeFormationSuivieIfapmeEditDto
     */
    idanneeFormationSuivieIfapme?: number;
    /**
     * 
     * @type {number}
     * @memberof AnneeFormationSuivieIfapmeEditDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {string}
     * @memberof AnneeFormationSuivieIfapmeEditDto
     */
    idanneeDeFormation?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnneeFormationSuivieIfapmeEditDto
     */
    idstade?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnneeFormationSuivieIfapmeEditDto
     */
    idtypeDeCours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnneeFormationSuivieIfapmeEditDto
     */
    iddegre?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnneeFormationSuivieIfapmeEditDto
     */
    idmetier?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AnneeFormationSuivieIfapmeEditDto
     */
    idcentre?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AnneeFormationSuivieIfapmeEditDto
     */
    idresultatValide?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnneeFormationSuivieIfapmeEditDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnneeFormationSuivieIfapmeEditDto
     */
    typeAcquis?: string | null;
    /**
     * 
     * @type {Array<AnneeFormationSuivieIfapmeFichierGridDto>}
     * @memberof AnneeFormationSuivieIfapmeEditDto
     */
    fichiers?: Array<AnneeFormationSuivieIfapmeFichierGridDto> | null;
}

/**
 * Check if a given object implements the AnneeFormationSuivieIfapmeEditDto interface.
 */
export function instanceOfAnneeFormationSuivieIfapmeEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AnneeFormationSuivieIfapmeEditDtoFromJSON(json: any): AnneeFormationSuivieIfapmeEditDto {
    return AnneeFormationSuivieIfapmeEditDtoFromJSONTyped(json, false);
}

export function AnneeFormationSuivieIfapmeEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnneeFormationSuivieIfapmeEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idanneeFormationSuivieIfapme': !exists(json, 'idanneeFormationSuivieIfapme') ? undefined : json['idanneeFormationSuivieIfapme'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'idanneeDeFormation': !exists(json, 'idanneeDeFormation') ? undefined : json['idanneeDeFormation'],
        'idstade': !exists(json, 'idstade') ? undefined : json['idstade'],
        'idtypeDeCours': !exists(json, 'idtypeDeCours') ? undefined : json['idtypeDeCours'],
        'iddegre': !exists(json, 'iddegre') ? undefined : json['iddegre'],
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'idresultatValide': !exists(json, 'idresultatValide') ? undefined : json['idresultatValide'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'typeAcquis': !exists(json, 'typeAcquis') ? undefined : json['typeAcquis'],
        'fichiers': !exists(json, 'fichiers') ? undefined : (json['fichiers'] === null ? null : (json['fichiers'] as Array<any>).map(AnneeFormationSuivieIfapmeFichierGridDtoFromJSON)),
    };
}

export function AnneeFormationSuivieIfapmeEditDtoToJSON(value?: AnneeFormationSuivieIfapmeEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idanneeFormationSuivieIfapme': value.idanneeFormationSuivieIfapme,
        'idpersonne': value.idpersonne,
        'idanneeDeFormation': value.idanneeDeFormation,
        'idstade': value.idstade,
        'idtypeDeCours': value.idtypeDeCours,
        'iddegre': value.iddegre,
        'idmetier': value.idmetier,
        'idcentre': value.idcentre,
        'idresultatValide': value.idresultatValide,
        'remarque': value.remarque,
        'typeAcquis': value.typeAcquis,
        'fichiers': value.fichiers === undefined ? undefined : (value.fichiers === null ? null : (value.fichiers as Array<any>).map(AnneeFormationSuivieIfapmeFichierGridDtoToJSON)),
    };
}

