import { Checkbox, Colors, Intent, Spinner } from "@blueprintjs/core";
import { ButtonContainer, IDataTableColumn, showError } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import { css } from "styled-components";

import { EBooleanSearchTypes, TuteurApi, TuteurGridDto, TuteurSearchDto } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { AddButton, EditButton, SearchTablePage, SmsButton, ViewButton } from "../../../../components";
import { useRouteParamsContext } from "../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface ITuteurListPageProps {}

export const TuteurListPage: React.FunctionComponent<ITuteurListPageProps> = props => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(TuteurApi);
  const [selectedIDs, setSelectedIDs] = React.useState<number[]>([]);
  const lastSearchObject = React.useRef<TuteurSearchDto>();
  const { pushSmsRecipientIds, pushSmsRecipientType } = useRouteParamsContext();
  const [selectAllLoading, setSelectAllLoading] = React.useState(false);
  const [nbReturnedIds, setNbReturnedIds] = React.useState(0);

  const apiFactory = useAbortableApiServiceFactory(TuteurApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (nextSearch?: TuteurSearchDto) => {
      if (JSON.stringify(nextSearch) !== JSON.stringify(lastSearchObject?.current)) {
        lastSearchObject.current = nextSearch;
      }
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.tuteurBaseSearch({ TuteurSearchDto: nextSearch });
    },
    [apiFactory]
  );

  const onAddTuteur = React.useCallback(() => {
    history.push(`${ERoutes.tuteur}/-1/detail/checkDoublons`);
  }, [history]);

  const selectAll = React.useCallback(() => {
    setSelectAllLoading(true);
    api
      .tuteurSearchTuteurIds({
        TuteurSearchDto: { ...lastSearchObject?.current, forceSkip: 0, forceTake: 999999 }
      })
      .then(allIds => {
        if (allIds.length === selectedIDs.length) {
          setSelectedIDs([]);
          setNbReturnedIds(0);
        } else {
          setSelectedIDs(allIds);
          setNbReturnedIds(allIds.length);
        }
        setSelectAllLoading(false);
      });
  }, [api, selectedIDs.length]);

  const toggleSelection = React.useCallback(
    (item: TuteurGridDto) => {
      if (!selectedIDs.includes(item.idtuteur)) {
        setSelectedIDs(ids => [...ids, item.idtuteur]);
      } else {
        setSelectedIDs(ids => ids.filter(id => id !== item.idtuteur));
      }
    },
    [selectedIDs]
  );

  const sendSms = React.useCallback(() => {
    if (selectedIDs.length > 0) {
      pushSmsRecipientIds(selectedIDs);
      // pushSmsRecipientType(ETypeDestinataireSms.Tuteur);
      history.push(`${ERoutes.sms}/0/detail/edit`);
    } else {
      showError(t(ETLCodes.SelectAtLeastOne));
    }
  }, [history, pushSmsRecipientIds, selectedIDs, t]);

  const sendSmsSingle = React.useCallback(
    (id: number) => {
      pushSmsRecipientIds([id]);
      // pushSmsRecipientType(ETypeDestinataireSms.Tuteur);
      history.push(`${ERoutes.sms}/0/detail/edit`);
    },
    [history, pushSmsRecipientIds]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        header: () =>
          selectAllLoading ? (
            <Spinner size={20} />
          ) : (
            <Checkbox checked={nbReturnedIds > 0 && nbReturnedIds === selectedIDs.length} onChange={selectAll} />
          ),
        render: (item: TuteurGridDto) => (
          <Checkbox
            checked={selectedIDs.includes(item.idtuteur)}
            onChange={e => {
              toggleSelection(item);
            }}
          />
        )
      },
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: TuteurGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => history.push(`${ERoutes.tuteur}/${row.idtuteur}/detail/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${ERoutes.tuteur}/${row.idtuteur}/detail/edit`)} />
          </ButtonContainer>
        )
      },
      {
        autoFitContent: true,
        header: () => t(ETLCodes.C),
        fieldName: "c"
      },
      {
        autoFitContent: true,
        header: () => t(ETLCodes.CF),
        fieldName: "cf"
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.DernierLieuFormation),
        fieldName: "lastLieuFormationNom",
        computed: true
      },
      {
        header: () => t(ETLCodes.EnseigneDernierLieuFormation),
        fieldName: "lastLieuFormationEnseigne",
        computed: true
      },
      {
        header: () => t(ETLCodes.Gsm),
        fieldName: "gsm.numero",
        computed: true,
        render: (value: TuteurGridDto) => (
          <SmsButton phone={value.gsm?.numero} onClick={() => sendSmsSingle(value.idtuteur)} />
        )
      },
      {
        header: () => t(ETLCodes.Email),
        fieldName: "email.adresseEmail",
        computed: true
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      },
      {
        header: () => t(ETLCodes.DateNaissance),
        fieldName: "dateNaissance"
      },
      {
        header: () => t(ETLCodes.LocaliteNaissance),
        fieldName: "localitenaissance"
      }
    ],
    [history, nbReturnedIds, selectAll, selectAllLoading, selectedIDs, sendSmsSingle, t, toggleSelection]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  const getCriteria = React.useCallback(() => api.tuteurGetSearchCriterias({ includeListsValues: true }), [api]);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteria}
      searchFunction={search}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.Tuteurs), route: ERoutes.tuteur }]}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
      searchStateInitialSearch={false}
      rightElement={
        <AddButton
          onClick={e => {
            e.stopPropagation();
            onAddTuteur();
          }}
          text={t(ETLCodes.General_Add)}
          intent={Intent.PRIMARY}
        />
      }
      keyFieldName="idtuteur"
      buttons={[
        {
          icon: "chat",
          text: t(ETLCodes.SendAnSMSToSelected),
          onClick: sendSms,
          disabled: true
        }
      ]}
      customizeRowStyle={(row: TuteurGridDto) => css`
        & * {
          color: ${!row.actif ? Colors.RED3 + " !important" : null};
        }
      `}
    />
  );
};
