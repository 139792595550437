/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClasseRemarqueSearch,
  FcbClasseRemarqueDto,
  FcbClasseRemarqueGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    ClasseRemarqueSearchFromJSON,
    ClasseRemarqueSearchToJSON,
    FcbClasseRemarqueDtoFromJSON,
    FcbClasseRemarqueDtoToJSON,
    FcbClasseRemarqueGridDtoPaginatedResultsFromJSON,
    FcbClasseRemarqueGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface ClasseRemarqueBaseSearchRequest {
    ClasseRemarqueSearch?: ClasseRemarqueSearch;
}

export interface ClasseRemarqueGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ClasseRemarqueGetSelectItemsRequest {
    searchField: string;
}

export interface ClasseRemarqueSaveRequest {
    FcbClasseRemarqueDto?: FcbClasseRemarqueDto;
}

/**
 * 
 */
export class ClasseRemarqueApi extends runtime.BaseAPI {

    /**
     */
    async classeRemarqueBaseSearchRaw(requestParameters: ClasseRemarqueBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbClasseRemarqueGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseRemarque/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClasseRemarqueSearchToJSON(requestParameters.ClasseRemarqueSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbClasseRemarqueGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async classeRemarqueBaseSearch(requestParameters: ClasseRemarqueBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbClasseRemarqueGridDtoPaginatedResults> {
        const response = await this.classeRemarqueBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeRemarqueGetSearchCriteriasRaw(requestParameters: ClasseRemarqueGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseRemarque/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async classeRemarqueGetSearchCriterias(requestParameters: ClasseRemarqueGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.classeRemarqueGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeRemarqueGetSelectItemsRaw(requestParameters: ClasseRemarqueGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling classeRemarqueGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseRemarque/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async classeRemarqueGetSelectItems(requestParameters: ClasseRemarqueGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.classeRemarqueGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeRemarqueSaveRaw(requestParameters: ClasseRemarqueSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbClasseRemarqueDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseRemarque/Save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbClasseRemarqueDtoToJSON(requestParameters.FcbClasseRemarqueDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbClasseRemarqueDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeRemarqueSave(requestParameters: ClasseRemarqueSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbClasseRemarqueDto> {
        const response = await this.classeRemarqueSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
