import { FGEmpty, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { FcbSecteurHomogeneDtoFromJSON, SecteurHomogeneApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterSelectInput, SmallFormGenerator, FGWalterCheckboxInput } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

interface ISecteurClasseHomogeneDetailPageProps {
  editMode: boolean;
  idSecteur: number;
}

export const SecteurClasseHomogeneDetailPage: React.FunctionComponent<ISecteurClasseHomogeneDetailPageProps> = ({
  editMode,
  idSecteur
}) => {
  const { t } = useTl();
  const api = useApiService(SecteurHomogeneApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idSecteur <= 0
        ? FcbSecteurHomogeneDtoFromJSON({ idSecteur: 0, actif: true })
        : api.secteurHomogeneGet({ id: +idSecteur }),
    saveApiFn: d => api.secteurHomogeneSave({ FcbSecteurHomogeneDto: d }),
    onSavedRoute: d => `${ERoutes.secteurClasseHomogene}/${d.idsecteur}/detail/edit`,
    deleteApiFn: d => api.secteurHomogeneDelete({ id: d.idsecteur }),
    onDeletedRoute: () => ERoutes.secteurClasseHomogene
  });

  const [stade, sLoading] = useReferential(a => a.referentialGetStade(), true, []);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      libelle: Yup.string()
        .required(t(ETLCodes.Required))
        .test("code-not-exists", t(ETLCodes.SecteurClasseHomogeneDetailPage_StadeLibelleExistsError), async function(
          value
        ) {
          if (value === data?.libelle && this.parent.idstade === data?.idstade) return true;
          const validationError = await api.secteurHomogeneStadeLibelleSecteurHomogeneExists({
            libelle: value,
            idStade: this.parent.idstade
          });
          return validationError.isValid;
        }),
      idstade: Yup.number()
        .notOneOf([0, -1], t(ETLCodes.Required))
        .required(t(ETLCodes.Required))
        .test("code-not-exists", t(ETLCodes.SecteurClasseHomogeneDetailPage_StadeLibelleExistsError), async function(
          value
        ) {
          if (this.parent.libelle === data?.libelle && value === data?.idstade) return true;
          const validationError = await api.secteurHomogeneStadeLibelleSecteurHomogeneExists({
            libelle: this.parent.libelle,
            idStade: value
          });
          return validationError.isValid;
        })
    });
  }, [api, data, t]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={editMode}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.secteurClasseHomogene)}
      onDelete={deleteItem}
      showDeleteButton={+idSecteur > 0}
      saving={saving}
      deleting={deleting}
    >
      <FieldGroup columns={2}>
        <FGTextInput name="libelle" maxLength={100} label={t(ETLCodes.Libelle)} />
        <FGEmpty />
        <FGWalterSelectInput name="idstade" label={t(ETLCodes.Stade)} loading={sLoading} items={stade} />
        <FGEmpty />
        <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
