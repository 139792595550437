/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileUploadDto } from './FileUploadDto';
import {
    FileUploadDtoFromJSON,
    FileUploadDtoFromJSONTyped,
    FileUploadDtoToJSON,
} from './FileUploadDto';

/**
 * 
 * @export
 * @interface LieuFormationAgrementSuspensionDto
 */
export interface LieuFormationAgrementSuspensionDto {
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementSuspensionDto
     */
    idlieuFormationAgrementSuspension?: number;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementSuspensionDto
     */
    idlieuFormationAgrement?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementSuspensionDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementSuspensionDto
     */
    dateFin?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementSuspensionDto
     */
    idmotifSuspension?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationAgrementSuspensionDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementSuspensionDto
     */
    idfichierSuspension?: number | null;
    /**
     * 
     * @type {FileUploadDto}
     * @memberof LieuFormationAgrementSuspensionDto
     */
    fichierSuspension?: FileUploadDto;
}

/**
 * Check if a given object implements the LieuFormationAgrementSuspensionDto interface.
 */
export function instanceOfLieuFormationAgrementSuspensionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LieuFormationAgrementSuspensionDtoFromJSON(json: any): LieuFormationAgrementSuspensionDto {
    return LieuFormationAgrementSuspensionDtoFromJSONTyped(json, false);
}

export function LieuFormationAgrementSuspensionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LieuFormationAgrementSuspensionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idlieuFormationAgrementSuspension': !exists(json, 'idlieuFormationAgrementSuspension') ? undefined : json['idlieuFormationAgrementSuspension'],
        'idlieuFormationAgrement': !exists(json, 'idlieuFormationAgrement') ? undefined : json['idlieuFormationAgrement'],
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
        'idmotifSuspension': !exists(json, 'idmotifSuspension') ? undefined : json['idmotifSuspension'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'idfichierSuspension': !exists(json, 'idfichierSuspension') ? undefined : json['idfichierSuspension'],
        'fichierSuspension': !exists(json, 'fichierSuspension') ? undefined : FileUploadDtoFromJSON(json['fichierSuspension']),
    };
}

export function LieuFormationAgrementSuspensionDtoToJSON(value?: LieuFormationAgrementSuspensionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idlieuFormationAgrementSuspension': value.idlieuFormationAgrementSuspension,
        'idlieuFormationAgrement': value.idlieuFormationAgrement,
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
        'idmotifSuspension': value.idmotifSuspension,
        'remarque': value.remarque,
        'idfichierSuspension': value.idfichierSuspension,
        'fichierSuspension': FileUploadDtoToJSON(value.fichierSuspension),
    };
}

