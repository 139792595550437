import * as React from "react";

import { DashboardDossierFinAgrement, DashboardDossierPostFormation, DashboardDossierSortie } from ".";
import { useTheme } from "../../../hooks";

export interface IDashboardDossiersProps {}

export const DashboardDossiers: React.FunctionComponent<IDashboardDossiersProps> = props => {
  const { ifapmeSide } = useTheme();
  const isHope = React.useMemo(() => ifapmeSide === "hope", [ifapmeSide]);
  return (
    <>
      <DashboardDossierSortie />
      {isHope && <DashboardDossierPostFormation />}
      {isHope && <DashboardDossierFinAgrement />}
    </>
  );
};
