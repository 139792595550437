/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IAggregateResult } from './IAggregateResult';
import {
    IAggregateResultFromJSON,
    IAggregateResultFromJSONTyped,
    IAggregateResultToJSON,
} from './IAggregateResult';
import type { TuteurLieuDoublonGridDto } from './TuteurLieuDoublonGridDto';
import {
    TuteurLieuDoublonGridDtoFromJSON,
    TuteurLieuDoublonGridDtoFromJSONTyped,
    TuteurLieuDoublonGridDtoToJSON,
} from './TuteurLieuDoublonGridDto';

/**
 * 
 * @export
 * @interface TuteurLieuDoublonGridDtoPaginatedResults
 */
export interface TuteurLieuDoublonGridDtoPaginatedResults {
    /**
     * 
     * @type {number}
     * @memberof TuteurLieuDoublonGridDtoPaginatedResults
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<TuteurLieuDoublonGridDto>}
     * @memberof TuteurLieuDoublonGridDtoPaginatedResults
     */
    results?: Array<TuteurLieuDoublonGridDto> | null;
    /**
     * 
     * @type {Array<IAggregateResult>}
     * @memberof TuteurLieuDoublonGridDtoPaginatedResults
     */
    aggregateResults?: Array<IAggregateResult> | null;
}

/**
 * Check if a given object implements the TuteurLieuDoublonGridDtoPaginatedResults interface.
 */
export function instanceOfTuteurLieuDoublonGridDtoPaginatedResults(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TuteurLieuDoublonGridDtoPaginatedResultsFromJSON(json: any): TuteurLieuDoublonGridDtoPaginatedResults {
    return TuteurLieuDoublonGridDtoPaginatedResultsFromJSONTyped(json, false);
}

export function TuteurLieuDoublonGridDtoPaginatedResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TuteurLieuDoublonGridDtoPaginatedResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'results': !exists(json, 'results') ? undefined : (json['results'] === null ? null : (json['results'] as Array<any>).map(TuteurLieuDoublonGridDtoFromJSON)),
        'aggregateResults': !exists(json, 'aggregateResults') ? undefined : (json['aggregateResults'] === null ? null : (json['aggregateResults'] as Array<any>).map(IAggregateResultFromJSON)),
    };
}

export function TuteurLieuDoublonGridDtoPaginatedResultsToJSON(value?: TuteurLieuDoublonGridDtoPaginatedResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'results': value.results === undefined ? undefined : (value.results === null ? null : (value.results as Array<any>).map(TuteurLieuDoublonGridDtoToJSON)),
        'aggregateResults': value.aggregateResults === undefined ? undefined : (value.aggregateResults === null ? null : (value.aggregateResults as Array<any>).map(IAggregateResultToJSON)),
    };
}

