/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EStatutContrat } from './EStatutContrat';
import {
    EStatutContratFromJSON,
    EStatutContratFromJSONTyped,
    EStatutContratToJSON,
} from './EStatutContrat';
import type { ETypeContrat } from './ETypeContrat';
import {
    ETypeContratFromJSON,
    ETypeContratFromJSONTyped,
    ETypeContratToJSON,
} from './ETypeContrat';

/**
 * 
 * @export
 * @interface ContratGridDto
 */
export interface ContratGridDto {
    /**
     * 
     * @type {number}
     * @memberof ContratGridDto
     */
    idcontrat?: number | null;
    /**
     * 
     * @type {EStatutContrat}
     * @memberof ContratGridDto
     */
    statut?: EStatutContrat;
    /**
     * 
     * @type {string}
     * @memberof ContratGridDto
     */
    service?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratGridDto
     */
    serviceNom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratGridDto
     */
    codeMetier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratGridDto
     */
    nomMetier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratGridDto
     */
    stade?: string | null;
    /**
     * 
     * @type {ETypeContrat}
     * @memberof ContratGridDto
     */
    type?: ETypeContrat;
    /**
     * 
     * @type {number}
     * @memberof ContratGridDto
     */
    idapprenant?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContratGridDto
     */
    apprenant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratGridDto
     */
    lieuPrincipal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratGridDto
     */
    tuteurPrincipal?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratGridDto
     */
    marqueSansSuiteLe?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratGridDto
     */
    rompuLe?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratGridDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratGridDto
     */
    dateFin?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratGridDto
     */
    debutDerniereSuspension?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratGridDto
     */
    finDerniereSuspension?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ContratGridDto
     */
    lastLieuFormation?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContratGridDto
     */
    idEditableCurrentSuspension?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContratGridDto
     */
    code?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContratGridDto
     */
    idpri?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratGridDto
     */
    idari?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratGridDto
     */
    idlieuFormation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratGridDto
     */
    idtuteur?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContratGridDto
     */
    hasAvenants?: boolean | null;
}

/**
 * Check if a given object implements the ContratGridDto interface.
 */
export function instanceOfContratGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContratGridDtoFromJSON(json: any): ContratGridDto {
    return ContratGridDtoFromJSONTyped(json, false);
}

export function ContratGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContratGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontrat': !exists(json, 'idcontrat') ? undefined : json['idcontrat'],
        'statut': !exists(json, 'statut') ? undefined : EStatutContratFromJSON(json['statut']),
        'service': !exists(json, 'service') ? undefined : json['service'],
        'serviceNom': !exists(json, 'serviceNom') ? undefined : json['serviceNom'],
        'codeMetier': !exists(json, 'codeMetier') ? undefined : json['codeMetier'],
        'nomMetier': !exists(json, 'nomMetier') ? undefined : json['nomMetier'],
        'stade': !exists(json, 'stade') ? undefined : json['stade'],
        'type': !exists(json, 'type') ? undefined : ETypeContratFromJSON(json['type']),
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'apprenant': !exists(json, 'apprenant') ? undefined : json['apprenant'],
        'lieuPrincipal': !exists(json, 'lieuPrincipal') ? undefined : json['lieuPrincipal'],
        'tuteurPrincipal': !exists(json, 'tuteurPrincipal') ? undefined : json['tuteurPrincipal'],
        'marqueSansSuiteLe': !exists(json, 'marqueSansSuiteLe') ? undefined : (json['marqueSansSuiteLe'] === null ? null : new Date(json['marqueSansSuiteLe'])),
        'rompuLe': !exists(json, 'rompuLe') ? undefined : (json['rompuLe'] === null ? null : new Date(json['rompuLe'])),
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
        'debutDerniereSuspension': !exists(json, 'debutDerniereSuspension') ? undefined : (json['debutDerniereSuspension'] === null ? null : new Date(json['debutDerniereSuspension'])),
        'finDerniereSuspension': !exists(json, 'finDerniereSuspension') ? undefined : (json['finDerniereSuspension'] === null ? null : new Date(json['finDerniereSuspension'])),
        'lastLieuFormation': !exists(json, 'lastLieuFormation') ? undefined : json['lastLieuFormation'],
        'idEditableCurrentSuspension': !exists(json, 'idEditableCurrentSuspension') ? undefined : json['idEditableCurrentSuspension'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'idpri': !exists(json, 'idpri') ? undefined : json['idpri'],
        'idari': !exists(json, 'idari') ? undefined : json['idari'],
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'idtuteur': !exists(json, 'idtuteur') ? undefined : json['idtuteur'],
        'hasAvenants': !exists(json, 'hasAvenants') ? undefined : json['hasAvenants'],
    };
}

export function ContratGridDtoToJSON(value?: ContratGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontrat': value.idcontrat,
        'statut': EStatutContratToJSON(value.statut),
        'service': value.service,
        'serviceNom': value.serviceNom,
        'codeMetier': value.codeMetier,
        'nomMetier': value.nomMetier,
        'stade': value.stade,
        'type': ETypeContratToJSON(value.type),
        'idapprenant': value.idapprenant,
        'apprenant': value.apprenant,
        'lieuPrincipal': value.lieuPrincipal,
        'tuteurPrincipal': value.tuteurPrincipal,
        'marqueSansSuiteLe': value.marqueSansSuiteLe === undefined ? undefined : (value.marqueSansSuiteLe === null ? null : value.marqueSansSuiteLe.toISOString()),
        'rompuLe': value.rompuLe === undefined ? undefined : (value.rompuLe === null ? null : value.rompuLe.toISOString()),
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
        'debutDerniereSuspension': value.debutDerniereSuspension === undefined ? undefined : (value.debutDerniereSuspension === null ? null : value.debutDerniereSuspension.toISOString()),
        'finDerniereSuspension': value.finDerniereSuspension === undefined ? undefined : (value.finDerniereSuspension === null ? null : value.finDerniereSuspension.toISOString()),
        'lastLieuFormation': value.lastLieuFormation,
        'idEditableCurrentSuspension': value.idEditableCurrentSuspension,
        'code': value.code,
        'idpri': value.idpri,
        'idari': value.idari,
        'idlieuFormation': value.idlieuFormation,
        'idtuteur': value.idtuteur,
        'hasAvenants': value.hasAvenants,
    };
}

