import { Loading } from "nsitools-react";
import * as React from "react";
import { Redirect, Route, RouteProps, Switch, useHistory } from "react-router-dom";

import { FinAppNavigationItemFromRoute, GetFirstRoute } from "./app-navigation";
import { RapportHopeViewer, StillInDev } from "./components";
import { useAuth, useGlobalData } from "./contexts";
import { useTheme } from "./hooks";
import {
  AgrementClasseItemPage,
  AgrementClasseListPage,
  AnneeScolaireItemPage,
  AnneeScolaireListPage,
  ApprenantItemPage,
  ApprenantListPage,
  BeidReaderPage,
  CalendrierItemPage,
  CalendrierListPage,
  CentreGlobalItemPage,
  CentreGlobalListPage,
  CentreItemPage,
  CentreListPage,
  ChefEntrepriseItemPage,
  ChefEntrepriseListPage,
  ClasseItemPage,
  ClasseListPage,
  ClasseMiseAJourPage,
  ClauseItemPage,
  ClauseListPage,
  CodePostalItemPage,
  CodePostalListPage,
  ConseillerPedagogiqueItemPage,
  ConseillerPedagogiqueListPage,
  ContactItemPage,
  ContactListPage,
  ContratFormateurItemPage,
  ContratFormateurListPage,
  ContratItemPage,
  ContratListPage,
  DashboardPage,
  DegreItemPage,
  DegreListPage,
  DelegueTutelleItemPage,
  DelegueTutelleListPage,
  DeliberationItemPage,
  DeliberationTypeListPage,
  DerniereAnneeReussieItemPage,
  DerniereAnneeReussieListPage,
  DiplomeItemPage,
  DiplomeListPage,
  DirectionTerritorialeItemPage,
  DirectionTerritorialeListPage,
  DoublonsItemPage,
  DoublonsListPage,
  DoublonsNewPage,
  EcoleItemPage,
  EcoleListPage,
  EvaluationItemPage,
  EvaluationListPage,
  ExportHopeListPage,
  ExportItemPage,
  ExportListPage,
  ExportUtilitaireItemPage,
  FeuillePresenceItemPage,
  FeuillePresenceListPage,
  GestionDroitsListPage,
  ImpressionItemPage,
  ImpressionListPage,
  InscriptionMasseItemPage,
  InstitutionItemPage,
  InstitutionSearchPage,
  KitSurviePage,
  LieuFormationItemPage,
  LocalisationClasseItemPage,
  LocalisationClasseListPage,
  LocalItemPage,
  LocalListPage,
  MetierItemPage,
  MetierListPage,
  ModuleCreaItemPage,
  ModuleCreaListPage,
  PaysItemPage,
  PaysListPage,
  PersonneItemPage,
  PersonneListPage,
  PrintingHistory,
  ProfessionFormateurItemPage,
  ProfessionFormateurListPage,
  ProjetFinancementItemPage,
  ProjetFinancementListPage,
  ProspectItemPage,
  ProspectList,
  PublipostageListPage,
  RapportHopeItemPage,
  RapportHopeListPage,
  RapportItemPage,
  ReferentielItemPage,
  ReferentielListPage,
  RemplacementItemPage,
  RemplacementListPage,
  RepresentantItemPage,
  RepresentantListPage,
  SecteurClasseHomogeneItemPage,
  SecteurClasseHomogeneListPage,
  SecteurMetierItemPage,
  SecteurMetierListPage,
  ServiceTutelleItemPage,
  ServiceTutelleListPage,
  SiegeSocialItemPage,
  SiegeSocialList,
  SocieteExterneItem,
  SocieteExterneList,
  StadeItemPage,
  StadeListPage,
  StatutSocialItemPage,
  StatutSocialListPage,
  SuiviItemPage,
  SuiviSearchPage,
  SuiviSiegeLieuSearchPage,
  TitrePedagogiqueItemPage,
  TitrePedagogiqueListPage,
  TuteurItemPage,
  TuteurListPage,
  TypeBulletinItemPage,
  TypeBulletinListPage,
  TypeCoursItemPage,
  TypeCoursListPage,
  TypeEnseignementItemPage,
  TypeEnseignementListPage,
  TypeFinancementItemPage,
  TypeFinancementListPage,
  UnauthorizedPage,
  UtilisateurItemPage,
  UtilisateurListPage,
  ValidationMasseItemPage,
  VisiteExterieureItemPage,
  VisiteExterieureListPage
} from "./pages";
import { DeliberationMasseItemPage } from "./pages/cours/deliberationMasse";
import { FormateurItemPage, FormateurListPage } from "./pages/cours/formateur";
import { OffreItemPage, OffreListPage } from "./pages/cours/offre";
import { AcquisIfapmeItemPage } from "./pages/donnees/FO/acquisIfapme/item/AcquisIfapmeItemPage";
import { AcquisIfapmeListPage } from "./pages/donnees/FO/acquisIfapme/list/AcquisIfapmeListPage";
import { SpecificiteCoursItemPage, SpecificiteCoursListPage } from "./pages/donnees/FO/specificiteCours";
import { TypeEvaluationItemPage, TypeEvaluationListPage } from "./pages/donnees/FO/typeEvaluation";
import {
  ExportationGapItemPage,
  ExportationGapListPage,
  UniteActiviteItemPage,
  UniteActiviteListPage
} from "./pages/gestion";
import { ForfaitAuditeurList, ForfaitAuditeurPage } from "./pages/gestion/forfaitAuditeur";
import { SmsItemPage, SmsListPage } from "./pages/utilitaire/sms";
import { CommissionParitaireItemPage, CommissionParitaireListPage } from "./pages/donnees/FO/commissionParitaire";

export enum ERoutes {
  test = "/_test",
  dashboard = "/dashboard",
  anneeReussie = "/anneeReussie",
  apprenant = "/apprenant",
  centre = "/centres",
  centreGlobal = "/centreglobal",
  clause = "/clause",
  codePostal = "/codepostal",
  conseillerPedagogique = "/conseillerPedagogique",
  contratFormateur = "/contratFormateur",
  degre = "/degre",
  delegueTutelle = "/delegueTutelle",
  diplome = "/diplome",
  directionTerritoriale = "/directionTerritoriale",
  ecole = "/ecole",
  home = "/home",
  local = "/local",
  metier = "/metier",
  pays = "/pays",
  professionFormateur = "/professionFormateur",
  projetFinancement = "/projetFinancement",
  referentiel = "/referentiel",
  secteurClasseHomogene = "/secteurClasseHomogene",
  secteurMetier = "/secteurMetier",
  serviceTutelle = "/serviceTutelle",
  specificiteCours = "/specificiteCours",
  stade = "/stade",
  statutSocial = "/statutSocial",
  titrePedagogique = "/titrePedagogique",
  typeCours = "/typeCours",
  typeEnseignement = "/typeEnseignement",
  typeEvaluation = "/typeEvaluation",
  typeFinancement = "/typeFinancement",
  representant = "/representant",
  formateur = "/formateur",
  classe = "/classe",
  inscriptionMasse = "/inscriptionMasse",
  anneeScolaire = "/anneeScolaire",
  doublon = "/doublon",
  classeMiseAJourMasse = "/masseMiseAJourClasse",
  deliberationMasse = "/deliberationMasse",
  visiteExterieure = "/visiteExterieure",
  users = "/users",
  exportation = "/exportation",
  impressions = "/impression",
  evaluation = "/evaluation",
  agrementClasse = "/agrementClasse",
  localisationClasse = "/LocalisationClasse",
  remplacement = "/remplacement",
  deliberationType = "/typeDeliberation",
  feuillePresence = "/feuillePresence",
  kitSurvie = "/kitSurvie",
  exportationGap = "/exportationGap",
  forfaitAuditeur = "/forfaitAuditeur",
  calendrier = "/calendrier",
  uniteActivite = "/uniteActivite",
  downloadBeid = "/downloadBeid",
  beidReader = "/beidReader",
  sms = "/sms",
  export = "/export",
  report = "/report",
  validationMasse = "/validationMasse",
  gestionDroits = "/gestionDroits",
  siegeSocial = "/siegeSocial",
  lieuFormation = "/lieuFormation",
  unauthorized = "/unauthorized",
  contrat = "/contrat",
  offreDemande = "/offreDemande",
  institution = "/institution",
  personne = "/personne",
  suivi = "/suivi",
  tuteur = "/tuteur",
  chefEntreprise = "/chefEntreprise",
  contacts = "/contacts",
  offre = "/offre",
  suiviSiegeLieu = "/suiviSiegeLieu",
  societeExterne = "/societeExterne",
  exports = "/exports",
  publipostage = "/publipostage",
  rapports = "/rapports",
  testSSRSReports = "/test_ssrs_reports",
  SSRSReports = "/ssrsReports",
  acquisIfapme = "/acquisIfapme",
  UserSelection = "/userSelection",
  typeBulletin = "/typeBulletin",
  commissionParitaire = "/commissionParitaire",
  printingHistory = "/printingHistory",
  prospect = "/prospect",
  moduleCrea = "/moduleCrea"
}

interface IProtectedRouteProps extends RouteProps {
  module?: string;
}

export const ProtectedRoute: React.FC<IProtectedRouteProps> = ({ module, ...routeProps }) => {
  const { matchModule, matchTab, user } = useAuth();
  const { devMode, ifapmeSide } = useTheme();
  const { push } = useHistory();
  const { enableNextFeatures } = useGlobalData();

  const userAllowed = React.useMemo(
    () =>
      user &&
      (devMode ||
        (user.ifapmeSide?.includes("Hope") && ifapmeSide === "hope") ||
        (user.ifapmeSide?.includes("Walter") && ifapmeSide === "walter")),
    [devMode, ifapmeSide, user]
  );

  const currentpathRouteModule = React.useMemo(() => {
    if (module) {
      return module;
    } else {
      const found = FinAppNavigationItemFromRoute(routeProps.path as string, ifapmeSide, enableNextFeatures);
      return found?.route?.split("/")[1];
    }
  }, [enableNextFeatures, ifapmeSide, module, routeProps.path]);

  const isRoot = React.useMemo(() => Object.values(ERoutes).some(r => r + "" === routeProps.path), [routeProps.path]);

  const activeModule = React.useMemo(
    () =>
      !!currentpathRouteModule &&
      matchModule(currentpathRouteModule) &&
      (!isRoot || matchTab("search") || matchTab("") || matchTab("DETAIL")),
    [currentpathRouteModule, isRoot, matchModule, matchTab]
  );

  React.useEffect(() => {
    if ((!activeModule || !userAllowed) && !!user?.ifapmeSide) {
      push(ERoutes.unauthorized);
    }
  }, [activeModule, push, routeProps.path, user?.ifapmeSide, userAllowed]);

  if (activeModule && userAllowed) {
    return <Route {...routeProps}></Route>;
  } else {
    return null;
  }
};

export const AppRouter = () => {
  const { userPermissions, user, hasPermission } = useAuth();
  const { devMode, ifapmeSide } = useTheme();
  const { push } = useHistory();
  const { enableNextFeatures, isFetchedAfterMount } = useGlobalData();

  const userAllowed = React.useMemo(
    () =>
      user &&
      (devMode ||
        (user.ifapmeSide?.includes("Hope") && ifapmeSide === "hope") ||
        (user.ifapmeSide?.includes("Walter") && ifapmeSide === "walter")),
    [devMode, ifapmeSide, user]
  );
  const hasAnyPermission = React.useMemo(() => userPermissions?.length > 0, [userPermissions]);

  const defaultRoute = React.useMemo(() => {
    if (hasAnyPermission && userAllowed) {
      const perm = userPermissions?.find(p => p.module === "DASHBOARD");
      return !!perm
        ? `${ERoutes.dashboard}/${perm.tab.toLowerCase()}`
        : user.azureEmail?.endsWith("@formateur.ifapme.be")
        ? ERoutes.evaluation
        : GetFirstRoute(
            userPermissions.filter(p => p.tab === "" || p.tab === "SEARCH")?.map(p => p.module.toUpperCase()),
            ifapmeSide,
            enableNextFeatures
          );
    } else {
      return ERoutes.unauthorized;
    }
  }, [enableNextFeatures, hasAnyPermission, ifapmeSide, user.azureEmail, userAllowed, userPermissions]);

  React.useEffect(() => {
    if (defaultRoute === ERoutes.unauthorized) {
      push(defaultRoute);
    }
  }, [defaultRoute, push]);

  if (!userPermissions && !isFetchedAfterMount) {
    return <>{<Loading />}</>;
  } else {
    return (
      <>
        {
          <Switch>
            <ProtectedRoute path={`${ERoutes.dashboard}/:tab`} component={DashboardPage} exact />
            <ProtectedRoute
              path={`${ERoutes.anneeReussie}/:id/:tab/:state?`}
              component={DerniereAnneeReussieItemPage}
              exact
            />
            <ProtectedRoute path={`${ERoutes.anneeReussie}`} component={DerniereAnneeReussieListPage} exact />
            <ProtectedRoute path={`${ERoutes.apprenant}/:id/:tab/:state?`} component={ApprenantItemPage} />
            <ProtectedRoute path={`${ERoutes.apprenant}`} component={ApprenantListPage} exact />
            <ProtectedRoute path={`${ERoutes.calendrier}`} component={CalendrierListPage} exact />
            <ProtectedRoute path={`${ERoutes.calendrier}/:tab`} component={CalendrierListPage} exact />
            <ProtectedRoute path={`${ERoutes.calendrier}/:id/:tab/:state?`} component={CalendrierItemPage} />
            <ProtectedRoute path={`${ERoutes.centre}/:id/:tab/:state?`} component={CentreItemPage} />
            <ProtectedRoute path={`${ERoutes.centre}`} component={CentreListPage} exact />
            <ProtectedRoute path={`${ERoutes.centreGlobal}/:id/:tab/:state?`} component={CentreGlobalItemPage} />
            <ProtectedRoute path={`${ERoutes.centreGlobal}`} component={CentreGlobalListPage} exact />
            <ProtectedRoute path={`${ERoutes.clause}/:id/:tab/:state?`} component={ClauseItemPage} />
            <ProtectedRoute path={`${ERoutes.clause}`} component={ClauseListPage} exact />
            <ProtectedRoute path={`${ERoutes.classe}/:id/:tab/:state?`} component={ClasseItemPage} />
            <ProtectedRoute path={`${ERoutes.classe}`} component={ClasseListPage} exact />
            <ProtectedRoute path={`${ERoutes.codePostal}/:id/:tab/:state?`} component={CodePostalItemPage} />
            <ProtectedRoute path={`${ERoutes.codePostal}`} component={CodePostalListPage} exact />

            <ProtectedRoute
              path={`${ERoutes.conseillerPedagogique}/:id/:tab/:state?`}
              component={ConseillerPedagogiqueItemPage}
            />
            <ProtectedRoute path={`${ERoutes.conseillerPedagogique}`} component={ConseillerPedagogiqueListPage} exact />
            <ProtectedRoute
              path={`${ERoutes.contratFormateur}/:id/:tab/:state?`}
              component={ContratFormateurItemPage}
            />
            <ProtectedRoute path={`${ERoutes.contratFormateur}/`} component={ContratFormateurListPage} exact />
            <ProtectedRoute path={`${ERoutes.degre}/:id/:tab/:state?`} component={DegreItemPage} />
            <ProtectedRoute path={`${ERoutes.degre}/`} component={DegreListPage} exact />
            <ProtectedRoute path={`${ERoutes.delegueTutelle}/:id/:tab/:state?`} component={DelegueTutelleItemPage} />
            <ProtectedRoute path={`${ERoutes.delegueTutelle}`} component={DelegueTutelleListPage} exact />
            <ProtectedRoute path={`${ERoutes.diplome}/:id/:tab/:state?`} component={DiplomeItemPage} />
            <ProtectedRoute path={`${ERoutes.diplome}`} component={DiplomeListPage} exact />
            <ProtectedRoute
              path={`${ERoutes.directionTerritoriale}/:id/:tab/:state?`}
              component={DirectionTerritorialeItemPage}
            />
            <ProtectedRoute path={`${ERoutes.directionTerritoriale}`} component={DirectionTerritorialeListPage} exact />
            <ProtectedRoute path={`${ERoutes.ecole}/:id/:tab/:state?`} component={EcoleItemPage} />
            <ProtectedRoute path={`${ERoutes.ecole}/`} component={EcoleListPage} exact />
            <ProtectedRoute
              path={`${ERoutes.evaluation}/:id/:tab/:state?/:duplicateId?`}
              component={EvaluationItemPage}
            />
            <ProtectedRoute path={`${ERoutes.evaluation}/`} component={EvaluationListPage} exact />
            <ProtectedRoute path={`${ERoutes.local}/:id/:tab/:state?`} component={LocalItemPage} exact />
            <ProtectedRoute path={`${ERoutes.local}/`} component={LocalListPage} />
            <ProtectedRoute path={`${ERoutes.local}/`} component={LocalListPage} exact />
            <ProtectedRoute path={`${ERoutes.metier}/:id/:tab/:state?/:codeSecteur?`} component={MetierItemPage} />
            <ProtectedRoute path={`${ERoutes.metier}`} component={MetierListPage} exact />
            <ProtectedRoute path={`${ERoutes.pays}/:id/:tab/:state?`} component={PaysItemPage} />
            <ProtectedRoute path={`${ERoutes.pays}`} component={PaysListPage} exact />
            <ProtectedRoute
              path={`${ERoutes.professionFormateur}/:id/:tab/:state?`}
              component={ProfessionFormateurItemPage}
            />
            <ProtectedRoute path={`${ERoutes.professionFormateur}`} component={ProfessionFormateurListPage} exact />
            <ProtectedRoute
              path={`${ERoutes.projetFinancement}/:id/:tab/:state?`}
              component={ProjetFinancementItemPage}
            />
            <ProtectedRoute path={`${ERoutes.projetFinancement}`} component={ProjetFinancementListPage} />
            <ProtectedRoute path={`${ERoutes.referentiel}/:id/:tab/:state?/:copyId?`} component={ReferentielItemPage} />
            <ProtectedRoute path={`${ERoutes.referentiel}`} component={ReferentielListPage} />
            <ProtectedRoute
              path={`${ERoutes.secteurClasseHomogene}/:id/:tab/:state?`}
              component={SecteurClasseHomogeneItemPage}
            />
            <ProtectedRoute path={`${ERoutes.secteurClasseHomogene}`} component={SecteurClasseHomogeneListPage} exact />
            <ProtectedRoute path={`${ERoutes.secteurMetier}/:id/:tab/:state?`} component={SecteurMetierItemPage} />
            <ProtectedRoute path={`${ERoutes.secteurMetier}`} component={SecteurMetierListPage} exact />
            <ProtectedRoute path={`${ERoutes.serviceTutelle}/:id/:tab/:state?`} component={ServiceTutelleItemPage} />
            <ProtectedRoute path={`${ERoutes.serviceTutelle}`} component={ServiceTutelleListPage} exact />
            {ifapmeSide === "walter" && (
              <ProtectedRoute path={`${ERoutes.societeExterne}/:id/:tab/:state?`} component={SocieteExterneItem} />
            )}
            {ifapmeSide === "walter" && (
              <ProtectedRoute path={`${ERoutes.societeExterne}`} component={SocieteExterneList} exact />
            )}
            <ProtectedRoute
              path={`${ERoutes.specificiteCours}/:id/:tab/:state?`}
              component={SpecificiteCoursItemPage}
            />
            <ProtectedRoute path={`${ERoutes.specificiteCours}`} component={SpecificiteCoursListPage} />
            <ProtectedRoute path={`${ERoutes.stade}/:id/:tab/:state?`} component={StadeItemPage} />
            <ProtectedRoute path={`${ERoutes.stade}`} component={StadeListPage} exact />
            <ProtectedRoute path={`${ERoutes.statutSocial}/:id/:tab/:state?`} component={StatutSocialItemPage} />
            <ProtectedRoute path={`${ERoutes.statutSocial}`} component={StatutSocialListPage} exact />
            <ProtectedRoute
              path={`${ERoutes.titrePedagogique}/:id/:tab/:state?`}
              component={TitrePedagogiqueItemPage}
            />
            <ProtectedRoute path={`${ERoutes.titrePedagogique}`} component={TitrePedagogiqueListPage} />
            <ProtectedRoute path={`${ERoutes.typeCours}/:id/:tab/:state?`} component={TypeCoursItemPage} />
            <ProtectedRoute path={`${ERoutes.typeCours}`} component={TypeCoursListPage} exact />
            <ProtectedRoute
              path={`${ERoutes.typeEnseignement}/:id/:tab/:state?`}
              component={TypeEnseignementItemPage}
            />
            <ProtectedRoute path={`${ERoutes.typeEnseignement}`} component={TypeEnseignementListPage} exact />
            <ProtectedRoute path={`${ERoutes.typeEvaluation}/:id/:tab/:state?`} component={TypeEvaluationItemPage} />
            <ProtectedRoute path={`${ERoutes.typeEvaluation}`} component={TypeEvaluationListPage} />
            <ProtectedRoute path={`${ERoutes.typeFinancement}/:id/:tab/:state?`} component={TypeFinancementItemPage} />
            <ProtectedRoute path={`${ERoutes.typeFinancement}`} component={TypeFinancementListPage} exact />
            <ProtectedRoute path={`${ERoutes.representant}/:id/:tab/:state?`} component={RepresentantItemPage} />
            <ProtectedRoute path={`${ERoutes.representant}`} component={RepresentantListPage} exact />
            <ProtectedRoute path={`${ERoutes.inscriptionMasse}`} component={InscriptionMasseItemPage} exact />
            <ProtectedRoute path={`${ERoutes.formateur}/:id/:tab/:state?`} component={FormateurItemPage} />
            <ProtectedRoute path={`${ERoutes.formateur}`} component={FormateurListPage} exact />
            <ProtectedRoute path={`${ERoutes.anneeScolaire}/:id/:tab/:state?`} component={AnneeScolaireItemPage} />
            <ProtectedRoute path={`${ERoutes.anneeScolaire}`} component={AnneeScolaireListPage} exact />
            <ProtectedRoute path={`${ERoutes.doublon}`} component={DoublonsListPage} exact />
            {hasPermission("DOUBLON", "RW", "NEW") && (
              <ProtectedRoute path={`${ERoutes.doublon}/new`} component={DoublonsNewPage} exact />
            )}
            <ProtectedRoute path={`${ERoutes.doublon}/fusion/:id`} component={DoublonsItemPage} />
            <ProtectedRoute path={`${ERoutes.classeMiseAJourMasse}`} component={ClasseMiseAJourPage} />
            <ProtectedRoute path={`${ERoutes.deliberationMasse}`} component={DeliberationMasseItemPage} exact />
            <ProtectedRoute path={`${ERoutes.visiteExterieure}`} component={VisiteExterieureListPage} exact />
            <ProtectedRoute
              path={`${ERoutes.visiteExterieure}/:id/:tab/:state?/:subTab?`}
              component={VisiteExterieureItemPage}
            />
            <ProtectedRoute path={`${ERoutes.users}`} component={UtilisateurListPage} exact />
            <ProtectedRoute path={`${ERoutes.users}/:id/:tab/:state?`} component={UtilisateurItemPage} />
            <ProtectedRoute path={`${ERoutes.exportation}`} component={ExportListPage} exact />
            <ProtectedRoute path={`${ERoutes.exportation}/:id/:tab/:state?`} component={ExportItemPage} />
            <ProtectedRoute path={`${ERoutes.impressions}`} component={ImpressionListPage} exact />
            <ProtectedRoute path={`${ERoutes.impressions}/:id/:tab/:state?`} component={ImpressionItemPage} />
            <ProtectedRoute path={`${ERoutes.rapports}`} component={RapportHopeListPage} exact />
            <ProtectedRoute path={`${ERoutes.rapports}/:id/:tab/:state?`} component={RapportHopeItemPage} />
            <ProtectedRoute path={`${ERoutes.agrementClasse}`} component={AgrementClasseListPage} exact />
            <ProtectedRoute path={`${ERoutes.agrementClasse}/:id/:tab/:state?`} component={AgrementClasseItemPage} />
            <ProtectedRoute path={`${ERoutes.localisationClasse}`} component={LocalisationClasseListPage} exact />
            <ProtectedRoute
              path={`${ERoutes.localisationClasse}/:id/:tab/:state?`}
              component={LocalisationClasseItemPage}
            />
            <ProtectedRoute path={`${ERoutes.feuillePresence}`} component={FeuillePresenceListPage} exact />
            <ProtectedRoute
              path={`${ERoutes.feuillePresence}/:id/:tab/:state?/:date?`}
              component={FeuillePresenceItemPage}
              exact
            />
            <ProtectedRoute path={`${ERoutes.remplacement}`} component={RemplacementListPage} exact />
            <ProtectedRoute path={`${ERoutes.remplacement}/:id/:tab/:state?`} component={RemplacementItemPage} />
            <ProtectedRoute path={`${ERoutes.deliberationType}`} component={DeliberationTypeListPage} exact />
            <ProtectedRoute
              path={`${ERoutes.deliberationType}/:id/:tab/:idApprenant/:annee/:coursTypeTab?`}
              component={DeliberationItemPage}
            />
            <ProtectedRoute path={`${ERoutes.forfaitAuditeur}`} component={ForfaitAuditeurList} exact />
            <ProtectedRoute path={`${ERoutes.forfaitAuditeur}/:id/:tab/:state?`} component={ForfaitAuditeurPage} />
            <ProtectedRoute path={`${ERoutes.uniteActivite}`} component={UniteActiviteListPage} exact />
            <ProtectedRoute
              path={`${ERoutes.uniteActivite}/:id/:tab/:state?`}
              component={UniteActiviteItemPage}
              exact
            />
            <ProtectedRoute path={`${ERoutes.kitSurvie}`} component={KitSurviePage} exact />
            <ProtectedRoute path={`${ERoutes.exportationGap}`} component={ExportationGapListPage} exact />
            <ProtectedRoute
              path={`${ERoutes.exportationGap}/:id/:tab/:state?`}
              component={ExportationGapItemPage}
              exact
            />
            <ProtectedRoute path={`${ERoutes.beidReader}`} component={BeidReaderPage} exact />
            <ProtectedRoute path={`${ERoutes.tuteur}`} component={TuteurListPage} exact />
            <ProtectedRoute path={`${ERoutes.tuteur}/:id/:tab/:state?`} component={TuteurItemPage} />
            <ProtectedRoute path={`${ERoutes.chefEntreprise}`} component={ChefEntrepriseListPage} exact />
            <ProtectedRoute
              path={`${ERoutes.chefEntreprise}/:id/:tab/:state?`}
              component={ChefEntrepriseItemPage}
              exact
            />
            <ProtectedRoute path={`${ERoutes.siegeSocial}`} component={SiegeSocialList} exact />
            <ProtectedRoute
              path={`${ERoutes.siegeSocial}/:id/:tab/:state?/:subId?`}
              component={SiegeSocialItemPage}
              exact
            />
            <ProtectedRoute
              path={`${ERoutes.lieuFormation}/:id/:tab/:state?/:subId?`}
              component={LieuFormationItemPage}
              exact
            />
            <ProtectedRoute path={`${ERoutes.contrat}`} component={ContratListPage} exact />
            <ProtectedRoute
              path={`${ERoutes.contrat}/:id/:tab/:type/:state?/:subId?`}
              component={ContratItemPage}
              exact
            />
            <ProtectedRoute path={`${ERoutes.offreDemande}`} component={StillInDev} exact />
            <ProtectedRoute path={`${ERoutes.offreDemande}/:id/:tab/:state?`} component={StillInDev} exact />
            <ProtectedRoute path={`${ERoutes.sms}`} component={SmsListPage} exact />
            <ProtectedRoute path={`${ERoutes.sms}/:id/:tab/:state?`} component={SmsItemPage} exact />
            <ProtectedRoute path={`${ERoutes.gestionDroits}`} component={GestionDroitsListPage} exact />
            <ProtectedRoute path={`${ERoutes.institution}`} component={InstitutionSearchPage} exact />
            <ProtectedRoute path={`${ERoutes.institution}/:id/:tab/:state?`} component={InstitutionItemPage} exact />
            <ProtectedRoute path={`${ERoutes.personne}`} component={PersonneListPage} exact />
            <ProtectedRoute path={`${ERoutes.personne}/:id/:tab/:state?`} component={PersonneItemPage} exact />
            <ProtectedRoute path={`${ERoutes.contacts}`} component={ContactListPage} exact />
            <ProtectedRoute path={`${ERoutes.contacts}/:id/:tab/:state?`} component={ContactItemPage} exact />
            <ProtectedRoute path={`${ERoutes.suivi}`} component={SuiviSearchPage} exact />
            <ProtectedRoute path={`${ERoutes.suivi}/:id/:tab/:state?`} component={SuiviItemPage} />
            <ProtectedRoute path={`${ERoutes.suiviSiegeLieu}`} component={SuiviSiegeLieuSearchPage} exact />
            <ProtectedRoute path={`${ERoutes.offre}`} component={OffreListPage} exact />
            <ProtectedRoute path={`${ERoutes.offre}/:id/:tab/:state?`} component={OffreItemPage} exact />
            <ProtectedRoute path={`${ERoutes.typeBulletin}`} component={TypeBulletinListPage} exact />
            <ProtectedRoute path={`${ERoutes.typeBulletin}/:id/:tab/:state?`} component={TypeBulletinItemPage} exact />
            <ProtectedRoute path={`${ERoutes.publipostage}`} component={PublipostageListPage} exact />
            <ProtectedRoute path={`${ERoutes.exports}`} component={ExportHopeListPage} exact />
            <Route path={`${ERoutes.SSRSReports}/:id`} component={RapportHopeViewer} exact />
            <Route path={`${ERoutes.export}/:id`} component={ExportUtilitaireItemPage} exact />
            <ProtectedRoute path={`${ERoutes.validationMasse}`} component={ValidationMasseItemPage} exact />
            <Route path={`${ERoutes.report}/:name`} component={RapportItemPage} exact />
            <ProtectedRoute path={`${ERoutes.acquisIfapme}/:id/:tab/:state?`} component={AcquisIfapmeItemPage} />
            <ProtectedRoute path={`${ERoutes.acquisIfapme}`} component={AcquisIfapmeListPage} exact />
            <ProtectedRoute
              path={`${ERoutes.commissionParitaire}/:id/:tab/:state?`}
              component={CommissionParitaireItemPage}
            />
            <ProtectedRoute path={`${ERoutes.commissionParitaire}`} component={CommissionParitaireListPage} exact />
            <ProtectedRoute path={`${ERoutes.moduleCrea}/:id/:tab/:state?`} component={ModuleCreaItemPage} />
            <ProtectedRoute path={`${ERoutes.moduleCrea}`} component={ModuleCreaListPage} exact />
            <Route path={`${ERoutes.printingHistory}`} component={PrintingHistory} exact />
            <ProtectedRoute path={`${ERoutes.prospect}/:id/:tab/:state?`} component={ProspectItemPage} />
            <ProtectedRoute path={`${ERoutes.prospect}`} component={ProspectList} exact />
            <Route path={`${ERoutes.unauthorized}`} component={UnauthorizedPage} exact />
            <Redirect to={defaultRoute} />
          </Switch>
        }
      </>
    );
  }
};
