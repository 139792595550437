/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DemandeApprenantSearch,
  FcbDemandeApprenantDto,
  FcbDemandeApprenantGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    DemandeApprenantSearchFromJSON,
    DemandeApprenantSearchToJSON,
    FcbDemandeApprenantDtoFromJSON,
    FcbDemandeApprenantDtoToJSON,
    FcbDemandeApprenantGridDtoPaginatedResultsFromJSON,
    FcbDemandeApprenantGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface DemandeApprenantBaseSearchRequest {
    DemandeApprenantSearch?: DemandeApprenantSearch;
}

export interface DemandeApprenantDeleteRequest {
    id?: number;
}

export interface DemandeApprenantGetRequest {
    id?: number;
}

export interface DemandeApprenantGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface DemandeApprenantGetSelectItemsRequest {
    searchField: string;
}

export interface DemandeApprenantSaveRequest {
    FcbDemandeApprenantDto?: FcbDemandeApprenantDto;
}

/**
 * 
 */
export class DemandeApprenantApi extends runtime.BaseAPI {

    /**
     */
    async demandeApprenantBaseSearchRaw(requestParameters: DemandeApprenantBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbDemandeApprenantGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DemandeApprenant/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DemandeApprenantSearchToJSON(requestParameters.DemandeApprenantSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbDemandeApprenantGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async demandeApprenantBaseSearch(requestParameters: DemandeApprenantBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbDemandeApprenantGridDtoPaginatedResults> {
        const response = await this.demandeApprenantBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async demandeApprenantDeleteRaw(requestParameters: DemandeApprenantDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DemandeApprenant`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async demandeApprenantDelete(requestParameters: DemandeApprenantDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.demandeApprenantDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async demandeApprenantGetRaw(requestParameters: DemandeApprenantGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbDemandeApprenantDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DemandeApprenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbDemandeApprenantDtoFromJSON(jsonValue));
    }

    /**
     */
    async demandeApprenantGet(requestParameters: DemandeApprenantGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbDemandeApprenantDto> {
        const response = await this.demandeApprenantGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async demandeApprenantGetSearchCriteriasRaw(requestParameters: DemandeApprenantGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DemandeApprenant/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async demandeApprenantGetSearchCriterias(requestParameters: DemandeApprenantGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.demandeApprenantGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async demandeApprenantGetSelectItemsRaw(requestParameters: DemandeApprenantGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling demandeApprenantGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DemandeApprenant/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async demandeApprenantGetSelectItems(requestParameters: DemandeApprenantGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.demandeApprenantGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async demandeApprenantSaveRaw(requestParameters: DemandeApprenantSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbDemandeApprenantDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DemandeApprenant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbDemandeApprenantDtoToJSON(requestParameters.FcbDemandeApprenantDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbDemandeApprenantDtoFromJSON(jsonValue));
    }

    /**
     */
    async demandeApprenantSave(requestParameters: DemandeApprenantSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbDemandeApprenantDto> {
        const response = await this.demandeApprenantSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
