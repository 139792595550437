import { add } from "date-fns";
import { FGDateMaskInput, IFGDateMaskInputProps } from "nsitools-react";
import * as React from "react";

export interface IFGWalterDateMaskInputProps extends IFGDateMaskInputProps {}

export const FGWalterDateMaskInput: React.FunctionComponent<IFGWalterDateMaskInputProps> = ({
  minDate,
  maxDate,
  ...dmProps
}) => {
  const defaultMaxDate = React.useMemo(() => add(new Date(), { years: 20 }), []);
  const defaultMinDate = React.useMemo(() => new Date(1900, 1, 1), []);
  return (
    <FGDateMaskInput
      {...dmProps}
      showCalendar
      showPlaceholder={false}
      minDate={minDate ?? defaultMinDate}
      maxDate={maxDate ?? defaultMaxDate}
    />
  );
};
