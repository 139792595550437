/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbServiceTutelleCodePostalDto,
  FcbServiceTutelleLocaliteGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
  ServiceTutelleLocaliteSearch,
} from '../models/index';
import {
    FcbServiceTutelleCodePostalDtoFromJSON,
    FcbServiceTutelleCodePostalDtoToJSON,
    FcbServiceTutelleLocaliteGridDtoPaginatedResultsFromJSON,
    FcbServiceTutelleLocaliteGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ServiceTutelleLocaliteSearchFromJSON,
    ServiceTutelleLocaliteSearchToJSON,
} from '../models/index';

export interface ServiceTutelleLocaliteBaseSearchRequest {
    ServiceTutelleLocaliteSearch?: ServiceTutelleLocaliteSearch;
}

export interface ServiceTutelleLocaliteGetCodePostalServiceTutelleRequest {
    idServiceTutelle?: number;
}

export interface ServiceTutelleLocaliteGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ServiceTutelleLocaliteGetSelectItemsRequest {
    searchField: string;
}

export interface ServiceTutelleLocaliteSaveRequest {
    FcbServiceTutelleCodePostalDto?: FcbServiceTutelleCodePostalDto;
}

/**
 * 
 */
export class ServiceTutelleLocaliteApi extends runtime.BaseAPI {

    /**
     */
    async serviceTutelleLocaliteBaseSearchRaw(requestParameters: ServiceTutelleLocaliteBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbServiceTutelleLocaliteGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ServiceTutelleLocalite/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceTutelleLocaliteSearchToJSON(requestParameters.ServiceTutelleLocaliteSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbServiceTutelleLocaliteGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async serviceTutelleLocaliteBaseSearch(requestParameters: ServiceTutelleLocaliteBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbServiceTutelleLocaliteGridDtoPaginatedResults> {
        const response = await this.serviceTutelleLocaliteBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async serviceTutelleLocaliteGetCodePostalServiceTutelleRaw(requestParameters: ServiceTutelleLocaliteGetCodePostalServiceTutelleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbServiceTutelleCodePostalDto>> {
        const queryParameters: any = {};

        if (requestParameters.idServiceTutelle !== undefined) {
            queryParameters['idServiceTutelle'] = requestParameters.idServiceTutelle;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ServiceTutelleLocalite`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbServiceTutelleCodePostalDtoFromJSON(jsonValue));
    }

    /**
     */
    async serviceTutelleLocaliteGetCodePostalServiceTutelle(requestParameters: ServiceTutelleLocaliteGetCodePostalServiceTutelleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbServiceTutelleCodePostalDto> {
        const response = await this.serviceTutelleLocaliteGetCodePostalServiceTutelleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async serviceTutelleLocaliteGetSearchCriteriasRaw(requestParameters: ServiceTutelleLocaliteGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ServiceTutelleLocalite/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async serviceTutelleLocaliteGetSearchCriterias(requestParameters: ServiceTutelleLocaliteGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.serviceTutelleLocaliteGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async serviceTutelleLocaliteGetSelectItemsRaw(requestParameters: ServiceTutelleLocaliteGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling serviceTutelleLocaliteGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ServiceTutelleLocalite/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async serviceTutelleLocaliteGetSelectItems(requestParameters: ServiceTutelleLocaliteGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.serviceTutelleLocaliteGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async serviceTutelleLocaliteSaveRaw(requestParameters: ServiceTutelleLocaliteSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbServiceTutelleCodePostalDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ServiceTutelleLocalite/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbServiceTutelleCodePostalDtoToJSON(requestParameters.FcbServiceTutelleCodePostalDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbServiceTutelleCodePostalDtoFromJSON(jsonValue));
    }

    /**
     */
    async serviceTutelleLocaliteSave(requestParameters: ServiceTutelleLocaliteSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbServiceTutelleCodePostalDto> {
        const response = await this.serviceTutelleLocaliteSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
