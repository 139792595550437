/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailDto } from './EmailDto';
import {
    EmailDtoFromJSON,
    EmailDtoFromJSONTyped,
    EmailDtoToJSON,
} from './EmailDto';
import type { FcbFormateurDiplomeDto } from './FcbFormateurDiplomeDto';
import {
    FcbFormateurDiplomeDtoFromJSON,
    FcbFormateurDiplomeDtoFromJSONTyped,
    FcbFormateurDiplomeDtoToJSON,
} from './FcbFormateurDiplomeDto';
import type { FcbFormateurProfessionDto } from './FcbFormateurProfessionDto';
import {
    FcbFormateurProfessionDtoFromJSON,
    FcbFormateurProfessionDtoFromJSONTyped,
    FcbFormateurProfessionDtoToJSON,
} from './FcbFormateurProfessionDto';
import type { FcbFormateurTitreDto } from './FcbFormateurTitreDto';
import {
    FcbFormateurTitreDtoFromJSON,
    FcbFormateurTitreDtoFromJSONTyped,
    FcbFormateurTitreDtoToJSON,
} from './FcbFormateurTitreDto';
import type { TelephoneDto } from './TelephoneDto';
import {
    TelephoneDtoFromJSON,
    TelephoneDtoFromJSONTyped,
    TelephoneDtoToJSON,
} from './TelephoneDto';

/**
 * 
 * @export
 * @interface FormateurDoublonProfessionnelDto
 */
export interface FormateurDoublonProfessionnelDto {
    /**
     * 
     * @type {TelephoneDto}
     * @memberof FormateurDoublonProfessionnelDto
     */
    telephoneProfFormateur?: TelephoneDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof FormateurDoublonProfessionnelDto
     */
    gsmProfFormateur?: TelephoneDto;
    /**
     * 
     * @type {EmailDto}
     * @memberof FormateurDoublonProfessionnelDto
     */
    emailProfFormateur?: EmailDto;
    /**
     * 
     * @type {string}
     * @memberof FormateurDoublonProfessionnelDto
     */
    activite?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FormateurDoublonProfessionnelDto
     */
    identreprise?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurDoublonProfessionnelDto
     */
    entreprise?: string | null;
    /**
     * 
     * @type {Array<FcbFormateurProfessionDto>}
     * @memberof FormateurDoublonProfessionnelDto
     */
    professions?: Array<FcbFormateurProfessionDto> | null;
    /**
     * 
     * @type {Array<FcbFormateurTitreDto>}
     * @memberof FormateurDoublonProfessionnelDto
     */
    titres?: Array<FcbFormateurTitreDto> | null;
    /**
     * 
     * @type {Array<FcbFormateurDiplomeDto>}
     * @memberof FormateurDoublonProfessionnelDto
     */
    diplomes?: Array<FcbFormateurDiplomeDto> | null;
}

/**
 * Check if a given object implements the FormateurDoublonProfessionnelDto interface.
 */
export function instanceOfFormateurDoublonProfessionnelDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FormateurDoublonProfessionnelDtoFromJSON(json: any): FormateurDoublonProfessionnelDto {
    return FormateurDoublonProfessionnelDtoFromJSONTyped(json, false);
}

export function FormateurDoublonProfessionnelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormateurDoublonProfessionnelDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'telephoneProfFormateur': !exists(json, 'telephoneProfFormateur') ? undefined : TelephoneDtoFromJSON(json['telephoneProfFormateur']),
        'gsmProfFormateur': !exists(json, 'gsmProfFormateur') ? undefined : TelephoneDtoFromJSON(json['gsmProfFormateur']),
        'emailProfFormateur': !exists(json, 'emailProfFormateur') ? undefined : EmailDtoFromJSON(json['emailProfFormateur']),
        'activite': !exists(json, 'activite') ? undefined : json['activite'],
        'identreprise': !exists(json, 'identreprise') ? undefined : json['identreprise'],
        'entreprise': !exists(json, 'entreprise') ? undefined : json['entreprise'],
        'professions': !exists(json, 'professions') ? undefined : (json['professions'] === null ? null : (json['professions'] as Array<any>).map(FcbFormateurProfessionDtoFromJSON)),
        'titres': !exists(json, 'titres') ? undefined : (json['titres'] === null ? null : (json['titres'] as Array<any>).map(FcbFormateurTitreDtoFromJSON)),
        'diplomes': !exists(json, 'diplomes') ? undefined : (json['diplomes'] === null ? null : (json['diplomes'] as Array<any>).map(FcbFormateurDiplomeDtoFromJSON)),
    };
}

export function FormateurDoublonProfessionnelDtoToJSON(value?: FormateurDoublonProfessionnelDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'telephoneProfFormateur': TelephoneDtoToJSON(value.telephoneProfFormateur),
        'gsmProfFormateur': TelephoneDtoToJSON(value.gsmProfFormateur),
        'emailProfFormateur': EmailDtoToJSON(value.emailProfFormateur),
        'activite': value.activite,
        'identreprise': value.identreprise,
        'entreprise': value.entreprise,
        'professions': value.professions === undefined ? undefined : (value.professions === null ? null : (value.professions as Array<any>).map(FcbFormateurProfessionDtoToJSON)),
        'titres': value.titres === undefined ? undefined : (value.titres === null ? null : (value.titres as Array<any>).map(FcbFormateurTitreDtoToJSON)),
        'diplomes': value.diplomes === undefined ? undefined : (value.diplomes === null ? null : (value.diplomes as Array<any>).map(FcbFormateurDiplomeDtoToJSON)),
    };
}

