/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ERapportHopeParameterType } from './ERapportHopeParameterType';
import {
    ERapportHopeParameterTypeFromJSON,
    ERapportHopeParameterTypeFromJSONTyped,
    ERapportHopeParameterTypeToJSON,
} from './ERapportHopeParameterType';
import type { RapportHopeParameterValuesDto } from './RapportHopeParameterValuesDto';
import {
    RapportHopeParameterValuesDtoFromJSON,
    RapportHopeParameterValuesDtoFromJSONTyped,
    RapportHopeParameterValuesDtoToJSON,
} from './RapportHopeParameterValuesDto';

/**
 * 
 * @export
 * @interface RapportHopeParameterDto
 */
export interface RapportHopeParameterDto {
    /**
     * 
     * @type {string}
     * @memberof RapportHopeParameterDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RapportHopeParameterDto
     */
    label?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RapportHopeParameterDto
     */
    defaultValues?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RapportHopeParameterDto
     */
    value?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RapportHopeParameterDto
     */
    multiValues?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RapportHopeParameterDto
     */
    dependencies?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof RapportHopeParameterDto
     */
    isNullable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RapportHopeParameterDto
     */
    isMultiValue?: boolean;
    /**
     * 
     * @type {ERapportHopeParameterType}
     * @memberof RapportHopeParameterDto
     */
    type?: ERapportHopeParameterType;
    /**
     * 
     * @type {Array<RapportHopeParameterValuesDto>}
     * @memberof RapportHopeParameterDto
     */
    validValues?: Array<RapportHopeParameterValuesDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof RapportHopeParameterDto
     */
    isOptional?: boolean;
}

/**
 * Check if a given object implements the RapportHopeParameterDto interface.
 */
export function instanceOfRapportHopeParameterDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RapportHopeParameterDtoFromJSON(json: any): RapportHopeParameterDto {
    return RapportHopeParameterDtoFromJSONTyped(json, false);
}

export function RapportHopeParameterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RapportHopeParameterDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'defaultValues': !exists(json, 'defaultValues') ? undefined : json['defaultValues'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'multiValues': !exists(json, 'multiValues') ? undefined : json['multiValues'],
        'dependencies': !exists(json, 'dependencies') ? undefined : json['dependencies'],
        'isNullable': !exists(json, 'isNullable') ? undefined : json['isNullable'],
        'isMultiValue': !exists(json, 'isMultiValue') ? undefined : json['isMultiValue'],
        'type': !exists(json, 'type') ? undefined : ERapportHopeParameterTypeFromJSON(json['type']),
        'validValues': !exists(json, 'validValues') ? undefined : (json['validValues'] === null ? null : (json['validValues'] as Array<any>).map(RapportHopeParameterValuesDtoFromJSON)),
        'isOptional': !exists(json, 'isOptional') ? undefined : json['isOptional'],
    };
}

export function RapportHopeParameterDtoToJSON(value?: RapportHopeParameterDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'label': value.label,
        'defaultValues': value.defaultValues,
        'value': value.value,
        'multiValues': value.multiValues,
        'dependencies': value.dependencies,
        'isNullable': value.isNullable,
        'isMultiValue': value.isMultiValue,
        'type': ERapportHopeParameterTypeToJSON(value.type),
        'validValues': value.validValues === undefined ? undefined : (value.validValues === null ? null : (value.validValues as Array<any>).map(RapportHopeParameterValuesDtoToJSON)),
        'isOptional': value.isOptional,
    };
}

