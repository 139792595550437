import { ButtonContainer, IDataTableColumn } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import { EBooleanSearchTypes, RapportHopeApi, RapportHopeGridDto, RapportHopeSearchDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BooleanColumn, EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IRapportHopeListPageProps {}

export const RapportHopeListPage: React.FunctionComponent<IRapportHopeListPageProps> = props => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(RapportHopeApi);
  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: RapportHopeGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.rapports}/${row.idrapportHope}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.rapports}/${row.idrapportHope}/detail/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      },
      {
        header: () => t(ETLCodes.Actif),
        fieldName: "actif",
        alignment: "center",
        render: (row: RapportHopeGridDto) => <BooleanColumn value={row.actif} />
      },
      {
        header: () => t(ETLCodes.Rapport),
        fieldName: "path"
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "rapportHopeType"
      },
      {
        header: () => t(ETLCodes.Categorie),
        fieldName: "categorie"
      }
    ],
    [history, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.rapports}/-1/detail/edit`);
  }, [history]);

  const apiFactory = useAbortableApiServiceFactory(RapportHopeApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: RapportHopeSearchDto) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.rapportHopeBaseSearch({ RapportHopeSearchDto: sObj });
    },
    [apiFactory]
  );
  const criteriasFn = React.useCallback(() => api.rapportHopeGetSearchCriterias({ includeListsValues: true }), [api]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={criteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.Rapports), route: ERoutes.rapports }]}
      addFunc={onAddItem}
      sortKeys={{ nom: "ASC" }}
      searchStateInitialSearch={false}
      defaultCriterias={[{ criteria: "Actif", searchMode: EBooleanSearchTypes.Equals, value: true }]}
    />
  );
};
