/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbMetierClauseLiesGridDto
 */
export interface FcbMetierClauseLiesGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbMetierClauseLiesGridDto
     */
    titre?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMetierClauseLiesGridDto
     */
    idclause?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbMetierClauseLiesGridDto
     */
    description?: string | null;
}

/**
 * Check if a given object implements the FcbMetierClauseLiesGridDto interface.
 */
export function instanceOfFcbMetierClauseLiesGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbMetierClauseLiesGridDtoFromJSON(json: any): FcbMetierClauseLiesGridDto {
    return FcbMetierClauseLiesGridDtoFromJSONTyped(json, false);
}

export function FcbMetierClauseLiesGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbMetierClauseLiesGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'titre': !exists(json, 'titre') ? undefined : json['titre'],
        'idclause': !exists(json, 'idclause') ? undefined : json['idclause'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function FcbMetierClauseLiesGridDtoToJSON(value?: FcbMetierClauseLiesGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'titre': value.titre,
        'idclause': value.idclause,
        'description': value.description,
    };
}

