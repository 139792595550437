import * as React from "react";
import { useTl, useApiService, useAbortableApiServiceFactory } from "../../../../hooks";
import { useHistory } from "react-router";
import { FcbUniteActiviteDto, UniteActiviteApi, UniteActiviteSearch } from "../../../../api";
import { ButtonContainer } from "nsitools-react";
import { ViewButton, EditButton, SearchTablePage } from "../../../../components";
import { ERoutes } from "../../../../AppRouter";
import { ETLCodes } from "../../../../locales";

export interface IUniteActiviteListPageProps {}

export const UniteActiviteListPage: React.FunctionComponent<IUniteActiviteListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(UniteActiviteApi);
  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbUniteActiviteDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.uniteActivite}/${row.idUnite}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.uniteActivite}/${row.idUnite}/detail/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "anneeAcademique"
      },
      {
        header: () => t(ETLCodes.AAPS1),
        fieldName: "aaS1"
      },
      {
        header: () => t(ETLCodes.AAPS2),
        fieldName: "aaS2"
      },
      {
        header: () => t(ETLCodes.BAPS1),
        fieldName: "abS1"
      },
      {
        header: () => t(ETLCodes.BAPS2),
        fieldName: "abS2"
      },
      {
        header: () => t(ETLCodes.IAPS1),
        fieldName: "aiS1"
      },
      {
        header: () => t(ETLCodes.IAPS2),
        fieldName: "aiS2"
      },
      {
        header: () => t(ETLCodes.ACES1),
        fieldName: "ceaS1"
      },
      {
        header: () => t(ETLCodes.ACES2),
        fieldName: "ceaS2"
      },
      {
        header: () => t(ETLCodes.BCES1),
        fieldName: "cebS1"
      },
      {
        header: () => t(ETLCodes.BCES2),
        fieldName: "cebS2"
      },
      {
        header: () => t(ETLCodes.ICES1),
        fieldName: "ceiS1"
      },
      {
        header: () => t(ETLCodes.IECS2),
        fieldName: "ceiS2"
      },
      {
        header: () => t(ETLCodes.FAGS1),
        fieldName: "fagS1"
      },
      {
        header: () => t(ETLCodes.FAGS2),
        fieldName: "fagS2"
      },
      {
        header: () => t(ETLCodes.IndiceRecyclageS1),
        fieldName: "recyclageS1"
      },
      {
        header: () => t(ETLCodes.IndiceRecyclageS2),
        fieldName: "recyclageS2"
      }
    ],
    [history, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.uniteActivite}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(() => api.uniteActiviteGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const apiFactory = useAbortableApiServiceFactory(UniteActiviteApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: UniteActiviteSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.uniteActiviteBaseSearch({ UniteActiviteSearch: sObj });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.UniteActivite), route: ERoutes.uniteActivite }]}
      addFunc={onAddItem}
      sortKeys={{ anneeAcademique: "DESC" }}
    />
  );
};
