import { Button, Intent } from "@blueprintjs/core";
import * as React from "react";

import { ClasseHoraireApi } from "../../../../../../api";
import { useApiService, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";
import { useDialog, useEventsContext } from "../../../../../../contexts";
import { CLASSE_HORAIRE_ACTIONS } from "../ClasseHoraireItemPage";

export interface IDeleteHoraireProps {
  idClasse: number;
}

export const DeleteHorairePage: React.FunctionComponent<IDeleteHoraireProps> = ({ idClasse }) => {
  const { t } = useTl();
  const api = useApiService(ClasseHoraireApi);
  const { dispatchEvent } = useEventsContext();
  const { showDialog } = useDialog();
  const [loading, setLoading] = React.useState(false);

  return (
    <>
      <Button
        text={t(ETLCodes.Executer)}
        intent={Intent.DANGER}
        onClick={async () => {
          showDialog({
            message: t(ETLCodes.DeleteHoraireConfirmationMessage),
            title: t(ETLCodes.DeleteHoraireDialogTitle),
            onConfirmed: async () => {
              setLoading(true);
              await api.classeHoraireDeleteHoraire({ idClasse });
              dispatchEvent("SEARCH_TABLE_REFRESH");
              setLoading(false);
              dispatchEvent(CLASSE_HORAIRE_ACTIONS, true);
            }
          });
        }}
        loading={loading}
      />
    </>
  );
};
