/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbPaysDto,
  FcbPaysGridDtoPaginatedResults,
  FilterCriteriaInfo,
  PaysSearch,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    FcbPaysDtoFromJSON,
    FcbPaysDtoToJSON,
    FcbPaysGridDtoPaginatedResultsFromJSON,
    FcbPaysGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    PaysSearchFromJSON,
    PaysSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface PaysBaseSearchRequest {
    PaysSearch?: PaysSearch;
}

export interface PaysCodePaysExistsRequest {
    codePays?: string;
}

export interface PaysDeleteRequest {
    codePays?: string;
}

export interface PaysGetRequest {
    codePays?: string;
}

export interface PaysGetDisplayNameRequest {
    id?: string;
}

export interface PaysGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface PaysGetSelectItemsRequest {
    searchField: string;
}

export interface PaysPaysNomExistsRequest {
    codePays?: string;
    nomPays?: string;
}

export interface PaysSaveRequest {
    FcbPaysDto?: FcbPaysDto;
}

/**
 * 
 */
export class PaysApi extends runtime.BaseAPI {

    /**
     */
    async paysBaseSearchRaw(requestParameters: PaysBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbPaysGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Pays/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaysSearchToJSON(requestParameters.PaysSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbPaysGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async paysBaseSearch(requestParameters: PaysBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbPaysGridDtoPaginatedResults> {
        const response = await this.paysBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async paysCodePaysExistsRaw(requestParameters: PaysCodePaysExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.codePays !== undefined) {
            queryParameters['codePays'] = requestParameters.codePays;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Pays/codeExists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async paysCodePaysExists(requestParameters: PaysCodePaysExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.paysCodePaysExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async paysDeleteRaw(requestParameters: PaysDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.codePays !== undefined) {
            queryParameters['codePays'] = requestParameters.codePays;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Pays`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async paysDelete(requestParameters: PaysDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.paysDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async paysGetRaw(requestParameters: PaysGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbPaysDto>> {
        const queryParameters: any = {};

        if (requestParameters.codePays !== undefined) {
            queryParameters['codePays'] = requestParameters.codePays;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Pays`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbPaysDtoFromJSON(jsonValue));
    }

    /**
     */
    async paysGet(requestParameters: PaysGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbPaysDto> {
        const response = await this.paysGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async paysGetDisplayNameRaw(requestParameters: PaysGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Pays/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async paysGetDisplayName(requestParameters: PaysGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.paysGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async paysGetSearchCriteriasRaw(requestParameters: PaysGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Pays/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async paysGetSearchCriterias(requestParameters: PaysGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.paysGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async paysGetSelectItemsRaw(requestParameters: PaysGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling paysGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Pays/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async paysGetSelectItems(requestParameters: PaysGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.paysGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async paysPaysNomExistsRaw(requestParameters: PaysPaysNomExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.codePays !== undefined) {
            queryParameters['codePays'] = requestParameters.codePays;
        }

        if (requestParameters.nomPays !== undefined) {
            queryParameters['nomPays'] = requestParameters.nomPays;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Pays/nomExists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async paysPaysNomExists(requestParameters: PaysPaysNomExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.paysPaysNomExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async paysSaveRaw(requestParameters: PaysSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbPaysDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Pays`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbPaysDtoToJSON(requestParameters.FcbPaysDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbPaysDtoFromJSON(jsonValue));
    }

    /**
     */
    async paysSave(requestParameters: PaysSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbPaysDto> {
        const response = await this.paysSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
