import { Intent } from "@blueprintjs/core";
import { FGTextAreaInput, FieldGroup, FieldSet } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { FcbFormateurDetailDtoFromJSON, FormateurApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  AddButton,
  FGWalterSelectInput,
  FGWalterSuggestTextInput,
  SmallFormGenerator
} from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import {
  ActiviteProfessionelleDiplomeListPage,
  ActiviteProfessionnelleProfessionListPage,
  ActiviteProfessionnelleTitreListPage
} from "./list";

export interface IActiviteProfessionnellesListPageProps {}

export const ActiviteProfessionnellesListPage: React.FunctionComponent<IActiviteProfessionnellesListPageProps> = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTl();
  const forApi = useApiService(FormateurApi);
  const history = useHistory();
  const path = React.useMemo(() => `${ERoutes.formateur}/${id}/activitesProfessionnelles`, [id]);
  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () => (+id <= 0 ? FcbFormateurDetailDtoFromJSON({ idFormateur: +id }) : forApi.formateurGet({ id: +id })),
    saveApiFn: d => forApi.formateurSave({ FcbFormateurDetailDto: d }),
    onSavedRoute: d => `${ERoutes.formateur}/${d.idformateur}/activitesProfessionnelles`,
    deleteApiFn: () => {}
  });

  const [entreprise, eLoading] = useReferential(a => a.referentialGetEntreprises(), true, []);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      emailProf: Yup.string().email(t(ETLCodes.InvalidEmail))
    });
  }, [t]);
  const idpersonne = React.useMemo(() => data?.idpersonne ?? 0, [data?.idpersonne]);
  const [personneTelephones, personneTelephonesLoading, , rawPersonneTelephones] = useReferential(
    a => a.referentialGetTelephonesPersonne({ idpersonne }),
    false,
    [idpersonne]
  );
  const personneTelephonesPro = React.useMemo(
    () =>
      personneTelephones?.filter(t => rawPersonneTelephones?.find(rt => +rt.idValue === +t.value)?.keyValue === "PRO"),
    [personneTelephones, rawPersonneTelephones]
  );
  const personneTelephonesPerso = React.useMemo(
    () =>
      personneTelephones?.filter(
        t => rawPersonneTelephones?.find(rt => +rt.idValue === +t.value)?.keyValue === "PERSO"
      ),
    [personneTelephones, rawPersonneTelephones]
  );
  const [personneGsms, personneGsmsLoading, , rawPersonneGsms] = useReferential(
    a => a.referentialGetGsmsPersonne({ idpersonne }),
    false,
    [idpersonne]
  );
  const personneGsmsPerso = React.useMemo(
    () => personneGsms?.filter(t => rawPersonneGsms?.find(rt => +rt.idValue === +t.value)?.keyValue === "GSM_PERSO"),
    [personneGsms, rawPersonneGsms]
  );
  const personneGsmsPro = React.useMemo(
    () => personneGsms?.filter(t => rawPersonneGsms?.find(rt => +rt.idValue === +t.value)?.keyValue === "GSM_PRO"),
    [personneGsms, rawPersonneGsms]
  );
  const [personneEmails, personneEmailsLoading, , rawPersonneEmails] = useReferential(
    a => a.referentialGetEmailsPersonne({ idpersonne }),
    false,
    [idpersonne]
  );
  const personneEmailsPro = React.useMemo(
    () => personneEmails?.filter(t => rawPersonneEmails?.find(rt => +rt.idValue === +t.value)?.keyValue === "PRO"),
    [personneEmails, rawPersonneEmails]
  );

  return (
    <>
      <FieldSet title={t(ETLCodes.GeneralInformation)}>
        {data && (
          <SmallFormGenerator
            initialValues={data}
            onSubmit={saveItem}
            editMode={true}
            validationSchema={FormSchema}
            loading={loading}
            onCancel={() => history.push(`${ERoutes.formateur}/${id}/signaletique`)}
            onDelete={deleteItem}
            saving={saving}
            deleting={deleting}
          >
            <FieldGroup columns={2}>
              <FGWalterSelectInput
                name="telephoneProfFormateur.idtelephone"
                label={t(ETLCodes.TelPro)}
                items={personneTelephonesPro}
                loading={personneTelephonesLoading}
                itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
                itemCreateParams={`&typeTel=PRO`}
                autoSelectIfOne
              />
              <FGWalterSelectInput
                name="telephoneFormateur.idtelephone"
                label={t(ETLCodes.Tel)}
                items={personneTelephonesPerso}
                loading={personneTelephonesLoading}
                itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
                itemCreateParams={`&typeTel=PERSO`}
                autoSelectIfOne
              />
              <FGWalterSelectInput
                name="gsmProfFormateur.idtelephone"
                label={t(ETLCodes.GsmPro)}
                items={personneGsmsPro}
                loading={personneGsmsLoading}
                itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
                itemCreateParams={`&typeTel=GSM_PRO`}
                autoSelectIfOne
              />
              <FGWalterSelectInput
                name="gsmFormateur.idtelephone"
                label={t(ETLCodes.Gsm)}
                items={personneGsmsPerso}
                loading={personneGsmsLoading}
                itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
                itemCreateParams={`&typeTel=GSM_PERSO`}
                autoSelectIfOne
              />
              <FGWalterSelectInput
                name="emailProfFormateur.idemail"
                label={t(ETLCodes.EmailPro)}
                loading={personneEmailsLoading}
                items={personneEmailsPro}
                itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
                itemCreateParams={`&typeEmail=PRO`}
                autoSelectIfOne
              />
              <FGWalterSuggestTextInput
                displayNoResult={false}
                name="entreprise"
                label={t(ETLCodes.Entreprise)}
                items={!eLoading && entreprise.map(e => e.label)}
                loading={eLoading}
              />
              <FGTextAreaInput name="activite" label={t(ETLCodes.Activite)} />
            </FieldGroup>
          </SmallFormGenerator>
        )}
      </FieldSet>
      <FieldSet
        collapsable
        title={t(ETLCodes.Profession)}
        rightElement={
          <AddButton
            onClick={e => {
              e.stopPropagation();
              history.push(`${path}/Profession/0/edit`);
            }}
            text={t(ETLCodes.General_Add)}
            intent={Intent.PRIMARY}
          />
        }
      >
        <ActiviteProfessionnelleProfessionListPage idFormateur={+id} />
      </FieldSet>
      <FieldSet
        collapsable
        title={t(ETLCodes.Titres)}
        rightElement={
          <AddButton
            onClick={e => {
              e.stopPropagation();
              history.push(`${path}/Titre/0/edit`);
            }}
            text={t(ETLCodes.General_Add)}
            intent={Intent.PRIMARY}
          />
        }
      >
        <ActiviteProfessionnelleTitreListPage idFormateur={+id} />
      </FieldSet>
      <FieldSet
        collapsable
        title={t(ETLCodes.Diplomes)}
        rightElement={
          <AddButton
            onClick={e => {
              e.stopPropagation();
              history.push(`${path}/Diplome/0/edit`);
            }}
            text={t(ETLCodes.General_Add)}
            intent={Intent.PRIMARY}
          />
        }
      >
        <ActiviteProfessionelleDiplomeListPage idFormateur={+id} />
      </FieldSet>
    </>
  );
};
