/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbReportModulePermissionDto
 */
export interface FcbReportModulePermissionDto {
    /**
     * 
     * @type {boolean}
     * @memberof FcbReportModulePermissionDto
     */
    selected?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbReportModulePermissionDto
     */
    idmodule?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbReportModulePermissionDto
     */
    idimpression?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbReportModulePermissionDto
     */
    nom?: string | null;
}

/**
 * Check if a given object implements the FcbReportModulePermissionDto interface.
 */
export function instanceOfFcbReportModulePermissionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbReportModulePermissionDtoFromJSON(json: any): FcbReportModulePermissionDto {
    return FcbReportModulePermissionDtoFromJSONTyped(json, false);
}

export function FcbReportModulePermissionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbReportModulePermissionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'selected': !exists(json, 'selected') ? undefined : json['selected'],
        'idmodule': !exists(json, 'idmodule') ? undefined : json['idmodule'],
        'idimpression': !exists(json, 'idimpression') ? undefined : json['idimpression'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
    };
}

export function FcbReportModulePermissionDtoToJSON(value?: FcbReportModulePermissionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'selected': value.selected,
        'idmodule': value.idmodule,
        'idimpression': value.idimpression,
        'nom': value.nom,
    };
}

