import { Button, Classes, Dialog, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classNames from "classnames";
import { FormikProps } from "formik";
import isEqual from "lodash/isEqual";
import {
  ButtonsBloc,
  FGCustomInput,
  FGCustomPanel,
  FGEmpty,
  FGMaskInput,
  FGMultiSuggestInput,
  FGTextAreaInput,
  FGTextInput,
  FieldGroup,
  IFGContext,
  InlineButtonContainer
} from "nsitools-react";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";

import {
  FichierApi,
  TuteurApi,
  TuteurEditDto,
  TuteurEditDtoFromJSON,
  TuteurLieuDoublonSearchDto
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  CustomBulletList,
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterFileInput,
  FGWalterSelectInput,
  NationaliteSelect,
  PhotoModifier,
  SmallFormGenerator
} from "../../../../../components";
import { useBeIdCardContext, useDialog } from "../../../../../contexts";
import { useApiService, useCrudApi, useFetchPicture, useTabMessage, useTheme, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import { belgianIdValidate } from "../../../../../utils/belgianIdValidate";
import pick from "lodash/pick";

const DialogStyled = styled(Dialog)`
  background: white;
`;

export interface ITuteurDetailProps {
  baseData: TuteurLieuDoublonSearchDto;
}

export const TuteurDetail: React.FunctionComponent<ITuteurDetailProps> = ({ baseData }) => {
  const { t } = useTl();
  const { theme } = useTheme();
  const history = useHistory();
  const api = useApiService(TuteurApi);
  const formikRef = React.useRef<FormikProps<TuteurEditDto>>();
  const [metiers, mLoading] = useReferential(a => a.referentialGetMetier(), true);

  const { id, state } = useParams<{ id: string; state: string }>();
  const { card } = useBeIdCardContext();
  const [mustLoadPicture, setMustLoadPicture] = React.useState(true);
  const [cardDataDifferent, setCardDataDifferent] = React.useState(false);
  const [photoDialogOpen, setPhotoDialogOpen] = React.useState<boolean>(false);
  const { showDialogPromise } = useDialog();
  const { search } = useLocation();
  const cfs = React.useMemo(() => new URLSearchParams(search).get("cfs"), [search]);
  const { sendMessage } = useTabMessage(cfs);
  const [sexe, sLoading] = useReferential(a => a.referentialGetSexe());

  const { data, setData, loading, saveItem, saving, validationErrors } = useCrudApi<TuteurEditDto>(
    React.useMemo(
      () => ({
        getApiFn: () => {
          let newData = {};
          if (baseData) {
            newData = { ...baseData };
          }
          if (card) {
            newData = {
              ...newData,
              nom: card?.surname.toUpperCase(),
              prenom: card?.firstName,
              photo: card?.photoB64,
              dateNaissance: card?.dateOfBirthParsed,
              registreNational: card?.nationalNumber
            };
          }
          return +id <= 0
            ? TuteurEditDtoFromJSON({
                ...newData,
                idtuteur: 0,
                actif: true
              })
            : api.tuteurGetTuteur({ idtuteur: +id });
        },
        saveApiFn: async (d: TuteurEditDto) => {
          return api.tuteurSaveTuteur({ TuteurEditDto: d });
        },
        onSaved: d => {
          sendMessage(d.idtuteur, true);
          setMustLoadPicture(true);
        },
        onSavedRoute: d => `${ERoutes.tuteur}/${d.idtuteur}/detail/${state}`
      }),
      [api, baseData, card, id, sendMessage, state]
    )
  );

  const [picture, picLoading] = useFetchPicture(data?.idpersonne, [data]);
  React.useEffect(() => {
    if (picture && data && !cardDataDifferent && mustLoadPicture) {
      setMustLoadPicture(false);
      setData({ ...data, photo: picture });
    }
  }, [cardDataDifferent, data, mustLoadPicture, picture, setData]);

  const [hopeUsers, huLoading] = useReferential(a => a.referentialGetHopeUsers(), false, []);
  const askDataDifferent = React.useCallback(async () => {
    const result = await showDialogPromise({
      message: t(ETLCodes.TuteurIdCardIsDifferent)
    });
    if (result === "yes") {
      setCardDataDifferent(true);
    }
  }, [showDialogPromise, t]);

  const canUseCardData = React.useMemo(() => {
    if (+id > 0) {
      return !data?.registreNational || data?.registreNational === card?.nationalNumber;
    }

    return false;
  }, [card?.nationalNumber, data?.registreNational, id]);

  React.useEffect(() => {
    if (!picLoading && data && card) {
      const cardObj = TuteurEditDtoFromJSON({
        nom: card?.surname.toUpperCase(),
        prenom: card?.firstName,
        photo: card?.photoB64,
        dateNaissance: card?.dateOfBirthParsed,
        registreNational: card?.nationalNumber
      });

      const propsToPick = ["nom", "prenom", "dateNaissance", "registreNational"];
      const cardBirthdate = new Date(card.dateOfBirthParsed);
      const cardBirthdateString = `${cardBirthdate.getDate()}/${cardBirthdate.getMonth() +
        1}/${cardBirthdate.getFullYear()}`;
      const dataBirthdateString = `${data.dateNaissance.getDate()}/${data.dateNaissance.getMonth() +
        1}/${data.dateNaissance.getFullYear()}`;
      if (
        +id > 0 &&
        (!isEqual(pick(cardObj, propsToPick), pick(data, propsToPick)) || cardBirthdateString !== dataBirthdateString)
      ) {
        if (canUseCardData) {
          askDataDifferent();
        }
      }
    }
  }, [askDataDifferent, canUseCardData, card, data, id, picLoading]);

  React.useEffect(() => {
    if (cardDataDifferent) {
      setCardDataDifferent(false);
      setMustLoadPicture(false);
      formikRef?.current?.setValues(
        TuteurEditDtoFromJSON({
          ...data,
          nom: card?.surname.toUpperCase() ?? data.nom,
          prenom: card?.firstName ?? data.prenom,
          photo: card?.photoB64 ?? picture,
          dateNaissance: card?.dateOfBirthParsed ?? data.dateNaissance,
          registreNational: card?.nationalNumber ?? data.registreNational
        }),
        true
      );
    }
  }, [
    card?.dateOfBirthParsed,
    card?.firstName,
    card?.nationalNumber,
    card?.photoB64,
    card?.surname,
    cardDataDifferent,
    data,
    picture
  ]);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape(
      {
        nom: Yup.string().required(t(ETLCodes.Required)),
        prenom: Yup.string().required(t(ETLCodes.Required)),
        codesexe: Yup.string().required(t(ETLCodes.Required)),
        dateNaissance: Yup.date()
          .nullable()
          .required(t(ETLCodes.Required)),
        nationalite: Yup.string().required(t(ETLCodes.Required)),
        registreNational: Yup.string()
          .nullable()
          .test("registre-checksum", t(ETLCodes.NotValid), function(value) {
            if (!value || value === "") return true;

            var millenial = false;
            if (this.parent.dateNaissance) {
              const parsedDateNaissance = new Date(this.parent.dateNaissance);
              if (parsedDateNaissance.getFullYear() > 1999) {
                millenial = true;
              }
            }

            return belgianIdValidate(value, millenial);
          })
          .when(["nationalite", "numeroIdentification"], {
            is: (nationalite, numeroIdentification) => nationalite === "BE" || !numeroIdentification,
            then: schema => schema.required(t(ETLCodes.AtLeastOneRegNatFields))
          }),
        numeroIdentification: Yup.string()
          .nullable()
          .when(["nationalite", "registreNational"], {
            is: (nationalite, registreNational) => nationalite !== "BE" && !registreNational,
            then: schema => schema.required(t(ETLCodes.AtLeastOneRegNatFields))
          })
      },
      [
        ["numeroIdentification", "nationalite"],
        ["numeroIdentification", "registreNational"],
        ["registreNational", "nationalite"],
        ["registreNational", "numeroIdentification"]
      ]
    );
  }, [t]);

  const isRegNatDateNaiss = React.useCallback((ctx: IFGContext) => {
    const value = ctx.formik.values.registreNational?.replace(".", "").replace("-", "");
    if (ctx.formik.values.dateNaissance && ctx.formik.values.nationalite === "BE") {
      const parsedDateNaissance = new Date(ctx.formik.values.dateNaissance);
      if (parsedDateNaissance && value) {
        const year = value.slice(0, 2);
        const month = value.slice(2, 4);
        const day = value.slice(4, 6);
        if (
          +year !==
          +parsedDateNaissance
            .getFullYear()
            .toString()
            .substr(2, 2)
        ) {
          return false;
        }
        if (+month !== 0 && +month !== parsedDateNaissance.getMonth() + 1) {
          return false;
        }
        if (+day !== 0 && +day !== parsedDateNaissance.getDate()) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, []);

  const isRegNatGenre = React.useCallback((ctx: IFGContext) => {
    const value = ctx.formik.values.registreNational?.replace(".", "")?.replace("-", "");
    if (value && ctx.formik.values.codesexe !== "X" && ctx.formik.values.nationalite === "BE") {
      const code = +value.slice(7, 9) % 2 === 0 ? "F" : "H";
      return ctx.formik.values.codesexe === code;
    }
    return true;
  }, []);

  const fapi = useApiService(FichierApi);
  const downloadFn = React.useCallback(async idfichier => await fapi.fichierDownload({ id: idfichier }), [fapi]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={state === "edit"}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.tuteur)}
      saving={saving}
      validationErrors={validationErrors}
      showDeleteButton={false}
      forceEnableSave={Object.values(baseData).some(el => !!el)}
      watchChanges={{
        fichierCv: (value, formik) => {
          if (formik.dirty) {
            formik.setFieldValue("idfichierCv", null);
          }
        },
        fichierDerogation: (value, formik) => {
          if (formik.dirty) {
            formik.setFieldValue("idfichierDerogation", null);
          }
        }
      }}
    >
      <FieldGroup columns={3}>
        <FieldGroup>
          <FGTextInput name="nom" label={t(ETLCodes.Nom)} maxLength={50} forceCase="upper" />
          <FGTextInput name="prenom" label={t(ETLCodes.Prenom)} maxLength={100} />
          <FGWalterSelectInput
            name="codesexe"
            label={t(ETLCodes.Genre)}
            items={sexe}
            loading={sLoading}
            isFilterable={false}
            isCleareable={false}
          />
          <FGWalterDateMaskInput name="dateNaissance" label={t(ETLCodes.DateNaissance)} />
          <NationaliteSelect name="nationalite" codeSexeFieldName="codesexe" />
          <FGMaskInput
            name="registreNational"
            label={t(ETLCodes.NumeroNational)}
            cleaveOptions={{ delimiters: [".", ".", "-", "."], blocks: [2, 2, 2, 3, 2] }}
            helperText={ctx => (
              <>
                {isRegNatDateNaiss(ctx) ? null : (
                  <span style={{ color: theme.warningColor }}>{t(ETLCodes.DateDeNaissanceNeCorrespondPas)}</span>
                )}
                {isRegNatGenre(ctx) ? null : (
                  <span style={{ color: theme.warningColor }}>{t(ETLCodes.GenreNeCorrespondPas)}</span>
                )}
              </>
            )}
          />
          <FGTextInput
            name="numeroIdentification"
            label={t(ETLCodes.NumeroIdentification)}
            visible={ctx => !!ctx.formik?.values?.nationalite && ctx.formik?.values?.nationalite !== "BE"}
          />
          <FGTextAreaInput name="remarque" label={t(ETLCodes.Remarque)} />
        </FieldGroup>
        <FieldGroup>
          <FGWalterDateMaskInput name="dateCv" label={t(ETLCodes.DateCv)} />
          <FGCustomPanel>
            {ctx => (
              <>
                <FGWalterFileInput
                  name="fichierCv"
                  label={t(ETLCodes.Cv)}
                  downloadFn={ctx.formik.values?.fichierCv && (() => downloadFn(ctx.formik.values?.idfichierCv))}
                />
                {ctx.editMode ? (
                  <FGMultiSuggestInput
                    label={t(ETLCodes.Metiers)}
                    name="idmetiers"
                    items={metiers}
                    loading={mLoading}
                    isFilterable={true}
                  />
                ) : (
                  <FGCustomInput name="idmetiers" label={t(ETLCodes.Metiers)}>
                    {() => (
                      <CustomBulletList
                        items={
                          metiers
                            ?.filter(m => ctx?.formik?.values?.idmetiers?.includes(m.value))
                            ?.map(m => ({ text: m.label })) ?? []
                        }
                      />
                    )}
                  </FGCustomInput>
                )}
              </>
            )}
          </FGCustomPanel>
          <FGWalterCheckboxInput name="anneesExperience" label={t(ETLCodes.AnneesExperience)} />
          <FGWalterCheckboxInput name="tutorat" label={t(ETLCodes.Tutorat)} />
          <FGWalterCheckboxInput name="vdc" label={t(ETLCodes.Vdc)} />
          <FGWalterCheckboxInput name="derogation" label={t(ETLCodes.Derogation)} />
          <FGCustomPanel>
            {ctx => (
              <>
                <FGWalterSelectInput
                  name="idresponsableDerogation"
                  label={t(ETLCodes.ResponsableDerogation)}
                  items={hopeUsers}
                  loading={huLoading}
                  disabled={!ctx?.formik?.values?.derogation}
                />
                <FGWalterDateMaskInput
                  name="dateDerogation"
                  label={t(ETLCodes.DateDerogation)}
                  disabled={!ctx?.formik?.values?.derogation}
                />
              </>
            )}
          </FGCustomPanel>
          <FGCustomPanel>
            {ctx => (
              <FGWalterFileInput
                name="fichierDerogation"
                label={t(ETLCodes.Document)}
                downloadFn={
                  ctx.formik.values?.fichierDerogation && (() => downloadFn(ctx.formik.values?.idfichierDerogation))
                }
              />
            )}
          </FGCustomPanel>
          <FGWalterCheckboxInput name="tuteurActif" label={t(ETLCodes.Actif)} readonly />
        </FieldGroup>
        <FieldGroup columns={[3, 5, 4]}>
          <FGEmpty />
          <FGCustomPanel>
            {ctx => (
              <div className={picLoading ? Classes.SKELETON : ""}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {ctx.formik.values.photo ? (
                    <>
                      <img
                        src={`data:image/png;base64,${ctx.formik.values.photo}`}
                        alt="Profil"
                        style={{ maxHeight: "9.5rem" }}
                      />
                    </>
                  ) : (
                    <Icon
                      iconSize={136.52}
                      icon={IconNames.USER}
                      style={{ marginBottom: "10px" }}
                      className={classNames({ invert: ctx?.formik?.values?.isDeceased })}
                    />
                  )}
                </div>
              </div>
            )}
          </FGCustomPanel>
          <FGEmpty />
          <FGEmpty />
          <FGCustomPanel>
            {ctx => (
              <InlineButtonContainer style={{ marginBottom: "10px" }}>
                <ButtonsBloc />
                <ButtonsBloc>
                  <Button
                    icon={IconNames.CAMERA}
                    text={t(ETLCodes.ModifierPhoto)}
                    onClick={() => setPhotoDialogOpen(true)}
                    disabled={!ctx.editMode}
                  />
                  <Button
                    icon={IconNames.DELETE}
                    minimal={true}
                    intent={Intent.DANGER}
                    onClick={() => ctx.formik.setFieldValue("photo", null)}
                    disabled={!ctx.editMode}
                  />
                </ButtonsBloc>
                <ButtonsBloc />
              </InlineButtonContainer>
            )}
          </FGCustomPanel>
        </FieldGroup>
      </FieldGroup>
      <FGCustomPanel>
        {ctx => (
          <DialogStyled
            isOpen={photoDialogOpen}
            onClose={() => setPhotoDialogOpen(false)}
            canOutsideClickClose={true}
            canEscapeKeyClose={true}
            title={`Photo ${ctx.formik.values?.nom ?? ""} ${ctx.formik.values?.prenom ?? ""}`}
            style={{ width: "90%", height: "800px" }}
            enforceFocus={false}
          >
            <PhotoModifier
              photo={ctx.formik.values?.photo}
              onSave={(newPhoto: any) => {
                ctx.formik.setFieldValue("photo", newPhoto);
                setPhotoDialogOpen(false);
              }}
              onCancel={() => setPhotoDialogOpen(false)}
            />
          </DialogStyled>
        )}
      </FGCustomPanel>
    </SmallFormGenerator>
  );
};
