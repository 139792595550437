/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ETypeCarteIdentiteUser {
    Apprenant = 'Apprenant',
    Formateur = 'Formateur',
    Representant = 'Representant',
    Tuteur = 'Tuteur',
    ChefEntreprise = 'ChefEntreprise'
}


export function ETypeCarteIdentiteUserFromJSON(json: any): ETypeCarteIdentiteUser {
    return ETypeCarteIdentiteUserFromJSONTyped(json, false);
}

export function ETypeCarteIdentiteUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ETypeCarteIdentiteUser {
    return json as ETypeCarteIdentiteUser;
}

export function ETypeCarteIdentiteUserToJSON(value?: ETypeCarteIdentiteUser | null): any {
    return value as any;
}

