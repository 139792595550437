/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbInscriptionMasseGridDto
 */
export interface FcbInscriptionMasseGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionMasseGridDto
     */
    idapprenant?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionMasseGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionMasseGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbInscriptionMasseGridDto
     */
    dateNaissance?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbInscriptionMasseGridDto
     */
    idscentre?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbInscriptionMasseGridDto
     */
    inscriptions?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbInscriptionMasseGridDto
     */
    idsinscription?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbInscriptionMasseGridDto
     */
    idsmetier?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbInscriptionMasseGridDto
     */
    degres?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbInscriptionMasseGridDto
     */
    masculins?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbInscriptionMasseGridDto
     */
    codesMetier?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbInscriptionMasseGridDto
     */
    classes?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbInscriptionMasseGridDto
     */
    stades?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbInscriptionMasseGridDto
     */
    statuts?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbInscriptionMasseGridDto
     */
    idsclasse?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbInscriptionMasseGridDto
     */
    anneesScolaires?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbInscriptionMasseGridDto
     */
    typescours?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbInscriptionMasseGridDto
     */
    idsformation?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbInscriptionMasseGridDto
     */
    deliberations?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbInscriptionMasseGridDto
     */
    deliberations2sess?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof FcbInscriptionMasseGridDto
     */
    iduser?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbInscriptionMasseGridDto
     */
    existing?: boolean;
}

/**
 * Check if a given object implements the FcbInscriptionMasseGridDto interface.
 */
export function instanceOfFcbInscriptionMasseGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbInscriptionMasseGridDtoFromJSON(json: any): FcbInscriptionMasseGridDto {
    return FcbInscriptionMasseGridDtoFromJSONTyped(json, false);
}

export function FcbInscriptionMasseGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbInscriptionMasseGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : json['dateNaissance'],
        'idscentre': !exists(json, 'idscentre') ? undefined : json['idscentre'],
        'inscriptions': !exists(json, 'inscriptions') ? undefined : json['inscriptions'],
        'idsinscription': !exists(json, 'idsinscription') ? undefined : json['idsinscription'],
        'idsmetier': !exists(json, 'idsmetier') ? undefined : json['idsmetier'],
        'degres': !exists(json, 'degres') ? undefined : json['degres'],
        'masculins': !exists(json, 'masculins') ? undefined : json['masculins'],
        'codesMetier': !exists(json, 'codesMetier') ? undefined : json['codesMetier'],
        'classes': !exists(json, 'classes') ? undefined : json['classes'],
        'stades': !exists(json, 'stades') ? undefined : json['stades'],
        'statuts': !exists(json, 'statuts') ? undefined : json['statuts'],
        'idsclasse': !exists(json, 'idsclasse') ? undefined : json['idsclasse'],
        'anneesScolaires': !exists(json, 'anneesScolaires') ? undefined : json['anneesScolaires'],
        'typescours': !exists(json, 'typescours') ? undefined : json['typescours'],
        'idsformation': !exists(json, 'idsformation') ? undefined : json['idsformation'],
        'deliberations': !exists(json, 'deliberations') ? undefined : json['deliberations'],
        'deliberations2sess': !exists(json, 'deliberations2sess') ? undefined : json['deliberations2sess'],
        'iduser': !exists(json, 'iduser') ? undefined : json['iduser'],
        'existing': !exists(json, 'existing') ? undefined : json['existing'],
    };
}

export function FcbInscriptionMasseGridDtoToJSON(value?: FcbInscriptionMasseGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idapprenant': value.idapprenant,
        'nom': value.nom,
        'prenom': value.prenom,
        'dateNaissance': value.dateNaissance,
        'idscentre': value.idscentre,
        'inscriptions': value.inscriptions,
        'idsinscription': value.idsinscription,
        'idsmetier': value.idsmetier,
        'degres': value.degres,
        'masculins': value.masculins,
        'codesMetier': value.codesMetier,
        'classes': value.classes,
        'stades': value.stades,
        'statuts': value.statuts,
        'idsclasse': value.idsclasse,
        'anneesScolaires': value.anneesScolaires,
        'typescours': value.typescours,
        'idsformation': value.idsformation,
        'deliberations': value.deliberations,
        'deliberations2sess': value.deliberations2sess,
        'iduser': value.iduser,
        'existing': value.existing,
    };
}

