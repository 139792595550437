import { FGEmpty, FGMaskInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { FcbForfaitAuditeurDtoFromJSON, ForfaitAuditeurApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { FGWalterSelectInput, SmallFormGenerator } from "../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";
import { ETLCodes } from "../../../../locales";

export interface IForfaitAuditeurDetailProps {
  idForfait: number;
  editMode: boolean;
}

export const ForfaitAuditeurDetail: React.FunctionComponent<IForfaitAuditeurDetailProps> = ({
  idForfait,
  editMode
}) => {
  const { t } = useTl();
  const api = useApiService(ForfaitAuditeurApi);
  const history = useHistory();

  const { data, loading, saveItem, saving, deleteItem, deleting } = useCrudApi({
    getApiFn: () =>
      idForfait <= 0
        ? FcbForfaitAuditeurDtoFromJSON({
            idForfait: 0
          })
        : api.forfaitAuditeurGet({ id: idForfait }),
    saveApiFn: d => api.forfaitAuditeurSave({ FcbForfaitAuditeurDto: d }),
    deleteApiFn: d => api.forfaitAuditeurDelete({ id: d.idForfait }),
    onDeletedRoute: () => ERoutes.forfaitAuditeur,
    onSavedRoute: d => `${ERoutes.forfaitAuditeur}/${d.idForfait}/detail/edit`
  });

  const [annees, aloading] = useReferential(apiL => apiL.referentialGetAnneeScolaire(), false);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      anneeAcademique: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={editMode}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.forfaitAuditeur)}
      saving={saving}
      onDelete={deleteItem}
      deleting={deleting}
      showDeleteButton={idForfait > 0}
    >
      <FieldGroup columns={2}>
        <FGWalterSelectInput
          name="anneeAcademique"
          label={t(ETLCodes.AnneeScolaire)}
          items={annees}
          loading={aloading}
        />
        <FGEmpty />
        <FGMaskInput
          label={t(ETLCodes.AaS1)}
          name="aaS1"
          cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
        />
        <FGMaskInput
          label={t(ETLCodes.AaS2)}
          name="aaS2"
          cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
        />
        <FGMaskInput
          label={t(ETLCodes.BAPS1)}
          name="abS1"
          cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
        />
        <FGMaskInput
          label={t(ETLCodes.BAPS2)}
          name="abS2"
          cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
        />
        <FGMaskInput
          label={t(ETLCodes.IAPS1)}
          name="aiS1"
          cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
        />
        <FGMaskInput
          label={t(ETLCodes.IAPS2)}
          name="aiS2"
          cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
        />
        <FGMaskInput
          label={t(ETLCodes.ACES1)}
          name="ceaS1"
          cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
        />
        <FGMaskInput
          label={t(ETLCodes.ACES2)}
          name="ceaS2"
          cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
        />
        <FGMaskInput
          label={t(ETLCodes.BCES1)}
          name="cebS1"
          cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
        />
        <FGMaskInput
          label={t(ETLCodes.BCES2)}
          name="cebS2"
          cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
        />
        <FGMaskInput
          label={t(ETLCodes.ICES1)}
          name="ceiS1"
          cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
        />
        <FGMaskInput
          label={t(ETLCodes.IECS2)}
          name="ceiS2"
          cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
        />
        <FGMaskInput
          label={t(ETLCodes.FAGS1)}
          name="fagS1"
          cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
        />
        <FGMaskInput
          label={t(ETLCodes.FAGS2)}
          name="fagS2"
          cleaveOptions={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
        />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
