/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceGridDto
 */
export interface ServiceGridDto {
    /**
     * 
     * @type {number}
     * @memberof ServiceGridDto
     */
    idservice?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceGridDto
     */
    nom?: string | null;
}

/**
 * Check if a given object implements the ServiceGridDto interface.
 */
export function instanceOfServiceGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServiceGridDtoFromJSON(json: any): ServiceGridDto {
    return ServiceGridDtoFromJSONTyped(json, false);
}

export function ServiceGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idservice': !exists(json, 'idservice') ? undefined : json['idservice'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
    };
}

export function ServiceGridDtoToJSON(value?: ServiceGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idservice': value.idservice,
        'nom': value.nom,
    };
}

