/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EListSearchType } from './EListSearchType';
import {
    EListSearchTypeFromJSON,
    EListSearchTypeFromJSONTyped,
    EListSearchTypeToJSON,
} from './EListSearchType';
import type { EListSearchTypeInt32CombinableCriteria } from './EListSearchTypeInt32CombinableCriteria';
import {
    EListSearchTypeInt32CombinableCriteriaFromJSON,
    EListSearchTypeInt32CombinableCriteriaFromJSONTyped,
    EListSearchTypeInt32CombinableCriteriaToJSON,
} from './EListSearchTypeInt32CombinableCriteria';

/**
 * 
 * @export
 * @interface Int32ListSearch
 */
export interface Int32ListSearch {
    /**
     * 
     * @type {Array<EListSearchTypeInt32CombinableCriteria>}
     * @memberof Int32ListSearch
     */
    criterias?: Array<EListSearchTypeInt32CombinableCriteria> | null;
    /**
     * 
     * @type {EListSearchType}
     * @memberof Int32ListSearch
     */
    searchMode?: EListSearchType;
    /**
     * 
     * @type {number}
     * @memberof Int32ListSearch
     * @deprecated
     */
    value?: number;
    /**
     * 
     * @type {number}
     * @memberof Int32ListSearch
     * @deprecated
     */
    secondaryValue?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Int32ListSearch
     */
    readonly availableSearchTypes?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Int32ListSearch
     */
    defaultSearchType?: string | null;
}

/**
 * Check if a given object implements the Int32ListSearch interface.
 */
export function instanceOfInt32ListSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function Int32ListSearchFromJSON(json: any): Int32ListSearch {
    return Int32ListSearchFromJSONTyped(json, false);
}

export function Int32ListSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): Int32ListSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'criterias': !exists(json, 'criterias') ? undefined : (json['criterias'] === null ? null : (json['criterias'] as Array<any>).map(EListSearchTypeInt32CombinableCriteriaFromJSON)),
        'searchMode': !exists(json, 'searchMode') ? undefined : EListSearchTypeFromJSON(json['searchMode']),
        'value': !exists(json, 'value') ? undefined : json['value'],
        'secondaryValue': !exists(json, 'secondaryValue') ? undefined : json['secondaryValue'],
        'availableSearchTypes': !exists(json, 'availableSearchTypes') ? undefined : json['availableSearchTypes'],
        'defaultSearchType': !exists(json, 'defaultSearchType') ? undefined : json['defaultSearchType'],
    };
}

export function Int32ListSearchToJSON(value?: Int32ListSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'criterias': value.criterias === undefined ? undefined : (value.criterias === null ? null : (value.criterias as Array<any>).map(EListSearchTypeInt32CombinableCriteriaToJSON)),
        'searchMode': EListSearchTypeToJSON(value.searchMode),
        'value': value.value,
        'secondaryValue': value.secondaryValue,
        'defaultSearchType': value.defaultSearchType,
    };
}

