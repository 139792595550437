import * as React from "react";
import { useParams } from "react-router";

import {
  ApprenantStatistiquePresenceApi,
  EListSearchType,
  RechercheStatPresenceApprenantSearch
} from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import { SearchTablePage } from "../../../../../../components";
import { useGlobalData } from "../../../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

export interface IApprenantAbsenceStatistiquePageProps {}

export const ApprenantAbsenceStatistiquePage: React.FunctionComponent<IApprenantAbsenceStatistiquePageProps> = () => {
  const { t } = useTl();
  const { id } = useParams<{ id: string }>();
  const { currentAnneeScolaire } = useGlobalData();
  const api = useApiService(ApprenantStatistiquePresenceApi);
  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "anneeScolaire"
      },
      {
        header: () => t(ETLCodes.Classe),
        fieldName: "codeclasse"
      },
      {
        header: () => t(ETLCodes.NomClasse),
        fieldName: "classe"
      },
      {
        header: () => t(ETLCodes.StatutInscription),
        fieldName: "statutInscription"
      },
      {
        header: () => t(ETLCodes.Heures),
        fieldName: "nbretot"
      },
      {
        header: () => t(ETLCodes.PresencesX),
        fieldName: "nbrex"
      },
      {
        header: () => t(ETLCodes.JDTCR),
        fieldName: "nbrejdtc"
      },
      {
        header: () => t(ETLCodes.AbsencesOEVA),
        fieldName: "nbreo"
      },
      {
        header: () => t(ETLCodes.PourcentagePresence),
        fieldName: "percentPresence"
      }
    ],
    [t]
  );

  const getCriterias = React.useCallback(
    () => api.apprenantStatistiquePresenceGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  const apiFactory = useAbortableApiServiceFactory(ApprenantStatistiquePresenceApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFunction = React.useCallback(
    (sObj?: RechercheStatPresenceApprenantSearch) => {
      sObj.idApprenant = +id;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.apprenantStatistiquePresenceBaseSearch({ RechercheStatPresenceApprenantSearch: sObj });
    },
    [apiFactory, id]
  );

  const defaultCriterias = React.useMemo(
    () => [
      {
        criteria: "AnneeScolaire",
        searchMode: EListSearchType.Equals,
        value: currentAnneeScolaire?.idanneeScolaire
      }
    ],
    [currentAnneeScolaire]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriterias}
      searchFunction={searchFunction}
      onAbort={onAbort}
      title={ETLCodes.StatistiquePresenceAbsence}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.Diplomes), route: ERoutes.diplome }]}
      withCard={false}
      defaultCriterias={defaultCriterias}
      searchStateInitialSearch={false}
      avoidSavingCriterias={true}
    />
  );
};
