import { Colors } from "@blueprintjs/core";
import { DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { css } from "styled-components";

import {
  ClasseHoraireCoherenceApi,
  ClasseHoraireCoherenceSearch,
  FcbClasseHoraireCoherenceGridDto
} from "../../../../../api";
import { useEventsContext } from "../../../../../contexts";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IClasseCoherenceHoraireSearchProps {
  classeId: number;
}

export const ClasseCoherenceHoraireSearch: React.FunctionComponent<IClasseCoherenceHoraireSearchProps> = ({
  classeId
}) => {
  const { t } = useTl();
  const [lastSobj, setLastSobj] = React.useState(null);

  const api = useApiService(ClasseHoraireCoherenceApi);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [5, 10],
    pageSize: 5,
    sortKeys: { description: "ASC" }
  });

  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();
  const searchFunction = React.useCallback(
    (sObj?: ClasseHoraireCoherenceSearch) => {
      sObj.idClasse = classeId;
      setLastSobj(sObj);
      return api.classeHoraireCoherenceBaseSearch({ ClasseHoraireCoherenceSearch: sObj });
    },
    [api, classeId]
  );

  const { loading, search } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code"
      },
      {
        header: () => t(ETLCodes.Heures),
        fieldName: "nbheures"
      },
      {
        header: () => t(ETLCodes.Planifie),
        fieldName: "nbheuresPlanifie"
      },
      {
        header: () => t(ETLCodes.Difference),
        fieldName: "difference"
      }
    ],
    [t]
  );

  const rowStyle = React.useCallback((item: FcbClasseHoraireCoherenceGridDto) => {
    const fontColor = item.difference !== 0 && item.difference !== undefined ? Colors.RED3 : Colors.BLACK;

    return css`
      & * {
        color: ${fontColor} !important;
      }
    `;
  }, []);

  React.useEffect(() => {
    const cb = () => search(lastSobj);
    subscribeToEvent("CLASSE_COHERENCE_REFRESH", cb);
    return () => {
      unsubscribeEvent("CLASSE_COHERENCE_REFRESH", cb);
    };
  }, [lastSobj, search, subscribeToEvent, unsubscribeEvent]);

  return (
    <>
      {classeId > 0 && (
        <FieldSet title={`${t(ETLCodes.CoherenceHoraire)}`}>
          <DataTable
            dateFormat="dd-MM-yyyy"
            tableState={tableState}
            loading={loading}
            columns={columns}
            customizeRowStyle={rowStyle}
          />
        </FieldSet>
      )}
    </>
  );
};
