/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EOrigineDossier } from './EOrigineDossier';
import {
    EOrigineDossierFromJSON,
    EOrigineDossierFromJSONTyped,
    EOrigineDossierToJSON,
} from './EOrigineDossier';

/**
 * 
 * @export
 * @interface StatutApprenantEditDto
 */
export interface StatutApprenantEditDto {
    /**
     * 
     * @type {number}
     * @memberof StatutApprenantEditDto
     */
    idApprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof StatutApprenantEditDto
     */
    idStatutApprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof StatutApprenantEditDto
     */
    idParcoursFormation?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof StatutApprenantEditDto
     */
    dateStatut?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof StatutApprenantEditDto
     */
    idCategorieStatut?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StatutApprenantEditDto
     */
    idStatut?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StatutApprenantEditDto
     */
    iddureeInoccupation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StatutApprenantEditDto
     */
    idMotifSortie?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StatutApprenantEditDto
     */
    idtypeSortie?: number | null;
    /**
     * 
     * @type {EOrigineDossier}
     * @memberof StatutApprenantEditDto
     */
    origineSortie?: EOrigineDossier;
    /**
     * 
     * @type {boolean}
     * @memberof StatutApprenantEditDto
     */
    postSortie?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutApprenantEditDto
     */
    suiviTermine?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutApprenantEditDto
     */
    simpleUpdate?: boolean;
}

/**
 * Check if a given object implements the StatutApprenantEditDto interface.
 */
export function instanceOfStatutApprenantEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StatutApprenantEditDtoFromJSON(json: any): StatutApprenantEditDto {
    return StatutApprenantEditDtoFromJSONTyped(json, false);
}

export function StatutApprenantEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatutApprenantEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idApprenant': !exists(json, 'idApprenant') ? undefined : json['idApprenant'],
        'idStatutApprenant': !exists(json, 'idStatutApprenant') ? undefined : json['idStatutApprenant'],
        'idParcoursFormation': !exists(json, 'idParcoursFormation') ? undefined : json['idParcoursFormation'],
        'dateStatut': !exists(json, 'dateStatut') ? undefined : (json['dateStatut'] === null ? null : new Date(json['dateStatut'])),
        'idCategorieStatut': !exists(json, 'idCategorieStatut') ? undefined : json['idCategorieStatut'],
        'idStatut': !exists(json, 'idStatut') ? undefined : json['idStatut'],
        'iddureeInoccupation': !exists(json, 'iddureeInoccupation') ? undefined : json['iddureeInoccupation'],
        'idMotifSortie': !exists(json, 'idMotifSortie') ? undefined : json['idMotifSortie'],
        'idtypeSortie': !exists(json, 'idtypeSortie') ? undefined : json['idtypeSortie'],
        'origineSortie': !exists(json, 'origineSortie') ? undefined : EOrigineDossierFromJSON(json['origineSortie']),
        'postSortie': !exists(json, 'postSortie') ? undefined : json['postSortie'],
        'suiviTermine': !exists(json, 'suiviTermine') ? undefined : json['suiviTermine'],
        'simpleUpdate': !exists(json, 'simpleUpdate') ? undefined : json['simpleUpdate'],
    };
}

export function StatutApprenantEditDtoToJSON(value?: StatutApprenantEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idApprenant': value.idApprenant,
        'idStatutApprenant': value.idStatutApprenant,
        'idParcoursFormation': value.idParcoursFormation,
        'dateStatut': value.dateStatut === undefined ? undefined : (value.dateStatut === null ? null : value.dateStatut.toISOString()),
        'idCategorieStatut': value.idCategorieStatut,
        'idStatut': value.idStatut,
        'iddureeInoccupation': value.iddureeInoccupation,
        'idMotifSortie': value.idMotifSortie,
        'idtypeSortie': value.idtypeSortie,
        'origineSortie': EOrigineDossierToJSON(value.origineSortie),
        'postSortie': value.postSortie,
        'suiviTermine': value.suiviTermine,
        'simpleUpdate': value.simpleUpdate,
    };
}

