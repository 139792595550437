import { IDataTableColumn } from "nsitools-react";
import * as React from "react";

import {
  DeliberationMasseApi,
  DeliberationMasseSearch,
  FcbDeliberationMasseGridDto,
  ETextSearchType
} from "../../../../api";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { SearchTablePage } from "../../../../components";
import { Spinner, Checkbox } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useGlobalData, useAuth } from "../../../../contexts";

export interface IDeliberationMasseSelectorProps {
  loading: boolean;
  submitIds: (ids: number[]) => void;
}
export const DeliberationMasseSelector: React.FunctionComponent<IDeliberationMasseSelectorProps> = ({
  loading,
  submitIds
}) => {
  const { t } = useTl();
  const api = useApiService(DeliberationMasseApi);
  const [selectedIDs, setSelectedIDs] = React.useState<number[]>([]);
  const lastSearchObject = React.useRef<DeliberationMasseSearch>();
  const [selectAllLoading, setSelectAllLoading] = React.useState(false);
  const [nbReturnedIds, setNbReturnedIds] = React.useState(0);
  const { currentAnneeScolaire } = useGlobalData();
  const { getCurrentPermission } = useAuth();
  const hasRights = React.useMemo(() => getCurrentPermission()?.permission?.includes("RW"), [getCurrentPermission]);

  const apiFactory = useAbortableApiServiceFactory(DeliberationMasseApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (nextSearch?: DeliberationMasseSearch) => {
      if (JSON.stringify(nextSearch) !== JSON.stringify(lastSearchObject.current)) {
        lastSearchObject.current = nextSearch;
      }
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.deliberationMasseBaseSearch({ DeliberationMasseSearch: nextSearch });
    },
    [apiFactory]
  );

  const selectAll = React.useCallback(() => {
    setSelectAllLoading(true);
    api
      .deliberationMasseSearchBatIds({
        DeliberationMasseSearch: { ...lastSearchObject.current, forceSkip: 0, forceTake: 999999 }
      })
      .then(allIds => {
        if (allIds.length === selectedIDs.length) {
          setSelectedIDs([]);
          setNbReturnedIds(0);
        } else {
          setSelectedIDs(allIds);
          setNbReturnedIds(allIds.length);
        }
        setSelectAllLoading(false);
      });
  }, [api, selectedIDs]);

  const toggleSelection = React.useCallback(
    (item: FcbDeliberationMasseGridDto) => {
      if (!selectedIDs.includes(item.idbulletinApprenantType)) {
        setSelectedIDs(ids => [...ids, item.idbulletinApprenantType]);
      } else {
        setSelectedIDs(ids => ids.filter(id => id !== item.idbulletinApprenantType));
      }
    },
    [selectedIDs]
  );

  const columns = React.useMemo<IDataTableColumn[]>(() => {
    return [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        header: () =>
          selectAllLoading ? (
            <Spinner size={20} />
          ) : (
            <Checkbox
              disabled={!hasRights}
              checked={nbReturnedIds > 0 && nbReturnedIds === selectedIDs.length}
              onChange={selectAll}
            />
          ),
        render: (item: FcbDeliberationMasseGridDto) => (
          <Checkbox
            disabled={!hasRights}
            checked={selectedIDs.includes(item.idbulletinApprenantType)}
            onChange={e => {
              toggleSelection(item);
            }}
          />
        )
      },
      {
        fieldName: "idapprenant",
        header: () => t(ETLCodes.Identifiant)
      },
      {
        fieldName: "nom",
        header: () => t(ETLCodes.Nom)
      },
      {
        fieldName: "prenom",
        header: () => t(ETLCodes.Prenom)
      },
      {
        fieldName: "dateNaissance",
        header: () => t(ETLCodes.DateNaissance)
      },
      {
        fieldName: "anneeScolaire",
        header: () => t(ETLCodes.AnneeScolaire)
      },
      {
        fieldName: "degre",
        header: () => t(ETLCodes.Degre)
      },
      {
        fieldName: "metier",
        header: () => t(ETLCodes.Metier)
      },
      {
        fieldName: "idclasse",
        header: () => t(ETLCodes.ClasseIdentifiant)
      }
    ];
  }, [hasRights, nbReturnedIds, selectAll, selectAllLoading, selectedIDs, t, toggleSelection]);

  const getCriteria = React.useCallback(() => api.deliberationMasseGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      withCard={false}
      columns={columns}
      getCriteriasFunction={getCriteria}
      searchFunction={search}
      onAbort={onAbort}
      sortKeys={{ nom: "ASC" }}
      breadCrumbs={[{ text: t(ETLCodes.DeliberationMasse) }]}
      title={ETLCodes.SelectApprenantsAndClickOnSave}
      availablePageSizes={[15, 25, 50, 100, 250, 500]}
      pageSize={100}
      buttons={[
        {
          icon: IconNames.TICK,
          text: t(ETLCodes.Update),
          onClick: () => submitIds(selectedIDs),
          loading: loading
        }
      ]}
      defaultCriterias={[
        {
          criteria: "AnneeScolaire",
          searchMode: ETextSearchType.Equals,
          value: currentAnneeScolaire?.idanneeScolaire,
          mandatory: true
        }
      ]}
    />
  );
};
