import { Checkbox } from "@blueprintjs/core";
import { ButtonContainer, IDataTableColumn } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";

import {
  FcbRechercheClasseHoraireRemplacementOccasionnelDto,
  RechercheClasseHoraireRemplacementOccasionnelSearch
} from "../../../../../api";
import { RemplacementOccasionnelApi } from "../../../../../api/apis/RemplacementOccasionnelApi";
import { EditButton, SearchTablePage } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { IRemplacement } from "./RemplacementInterfaces";
import { RemplacementOccasionnelDetailPage } from "./RemplacementOccasionnelDetailPage";
import { useEventsContext } from "../../../../../contexts";

export interface IRemplacementOccasionnelTablePageProps {}

export const RemplacementOccasionnelTablePage: React.FunctionComponent<IRemplacementOccasionnelTablePageProps> = () => {
  const { id } = useParams<{ id: string; tab: string; state: string }>();
  const { t } = useTl();
  const api = useApiService(RemplacementOccasionnelApi);

  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [modalData, setModalData] = React.useState<IRemplacement>({});

  const editClick = React.useCallback((row: FcbRechercheClasseHoraireRemplacementOccasionnelDto) => {
    setModalData({
      idremplacant: row.idremplacant,
      idformateurLocalisationContrat: row.idformateurLocalisationContrat,
      idremplacement: row.idremplacement,
      idclasseHoraire: row.idclasseHoraire,
      reporte: row.reporte,
      idcentre: row.idcentre
    });
    setModalVisible(true);
  }, []);

  const columns = React.useMemo<Array<IDataTableColumn>>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbRechercheClasseHoraireRemplacementOccasionnelDto) => (
          <ButtonContainer>
            <EditButton minimal={true} onClick={() => editClick(row)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Classe),
        fieldName: "classe"
      },
      {
        header: () => t(ETLCodes.Matiere),
        fieldName: "classeMatiere"
      },
      {
        header: () => t(ETLCodes.Date),
        fieldName: "date"
      },
      {
        header: () => t(ETLCodes.Heures),
        fieldName: "heure"
      },
      {
        header: () => t(ETLCodes.Remplacant),
        fieldName: "remplacant"
      },
      {
        header: () => t(ETLCodes.Reporte),
        fieldName: "reporte",
        alignment: "center",
        render: (row: FcbRechercheClasseHoraireRemplacementOccasionnelDto) => (
          <Checkbox disabled checked={row.reporte} />
        )
      },
      {
        header: () => t(ETLCodes.Contrat),
        fieldName: "contrat"
      }
    ],
    [editClick, t]
  );

  const apiFactory = useAbortableApiServiceFactory(RemplacementOccasionnelApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFnCB = React.useCallback(
    (sObj?: RechercheClasseHoraireRemplacementOccasionnelSearch) => {
      sObj.idRemplacement = +id;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.remplacementOccasionnelBaseSearch({
        RechercheClasseHoraireRemplacementOccasionnelSearch: sObj
      });
    },
    [apiFactory, id]
  );

  const criteriaFnCB = React.useCallback(
    () => api.remplacementOccasionnelGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  const { dispatchEvent } = useEventsContext();

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <SearchTablePage
        getCriteriasFunction={criteriaFnCB}
        searchFunction={searchFnCB}
        onAbort={onAbort}
        sortKeys={{ classe: "ASC" }}
        columns={columns}
        withCard={false}
      />
      {modalVisible ? (
        <RemplacementOccasionnelDetailPage
          isOpen={modalVisible}
          onSave={() => {
            setModalData({});
            setModalVisible(false);
            dispatchEvent("SEARCH_TABLE_REFRESH");
          }}
          onCancel={() => {
            setModalData({});
            setModalVisible(false);
          }}
          data={modalData}
        />
      ) : null}
    </>
  );
};
