/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbDeliberationMasseGridDto
 */
export interface FcbDeliberationMasseGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbDeliberationMasseGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDeliberationMasseGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbDeliberationMasseGridDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDeliberationMasseGridDto
     */
    idapprenant?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDeliberationMasseGridDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDeliberationMasseGridDto
     */
    idtypeCours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDeliberationMasseGridDto
     */
    deliberation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDeliberationMasseGridDto
     */
    deliberation2sess?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDeliberationMasseGridDto
     */
    idbulletinApprenant?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDeliberationMasseGridDto
     */
    idbulletinApprenantType?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDeliberationMasseGridDto
     */
    delibe1sess?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDeliberationMasseGridDto
     */
    degre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDeliberationMasseGridDto
     */
    metier?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbDeliberationMasseGridDto
     */
    idclasse?: number | null;
}

/**
 * Check if a given object implements the FcbDeliberationMasseGridDto interface.
 */
export function instanceOfFcbDeliberationMasseGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbDeliberationMasseGridDtoFromJSON(json: any): FcbDeliberationMasseGridDto {
    return FcbDeliberationMasseGridDtoFromJSONTyped(json, false);
}

export function FcbDeliberationMasseGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbDeliberationMasseGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'idtypeCours': !exists(json, 'idtypeCours') ? undefined : json['idtypeCours'],
        'deliberation': !exists(json, 'deliberation') ? undefined : json['deliberation'],
        'deliberation2sess': !exists(json, 'deliberation2sess') ? undefined : json['deliberation2sess'],
        'idbulletinApprenant': !exists(json, 'idbulletinApprenant') ? undefined : json['idbulletinApprenant'],
        'idbulletinApprenantType': !exists(json, 'idbulletinApprenantType') ? undefined : json['idbulletinApprenantType'],
        'delibe1sess': !exists(json, 'delibe1sess') ? undefined : json['delibe1sess'],
        'degre': !exists(json, 'degre') ? undefined : json['degre'],
        'metier': !exists(json, 'metier') ? undefined : json['metier'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
    };
}

export function FcbDeliberationMasseGridDtoToJSON(value?: FcbDeliberationMasseGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nom': value.nom,
        'prenom': value.prenom,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'idapprenant': value.idapprenant,
        'anneeScolaire': value.anneeScolaire,
        'idtypeCours': value.idtypeCours,
        'deliberation': value.deliberation,
        'deliberation2sess': value.deliberation2sess,
        'idbulletinApprenant': value.idbulletinApprenant,
        'idbulletinApprenantType': value.idbulletinApprenantType,
        'delibe1sess': value.delibe1sess,
        'degre': value.degre,
        'metier': value.metier,
        'idclasse': value.idclasse,
    };
}

