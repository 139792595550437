/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormateurSuiviFichierGridDto } from './FormateurSuiviFichierGridDto';
import {
    FormateurSuiviFichierGridDtoFromJSON,
    FormateurSuiviFichierGridDtoFromJSONTyped,
    FormateurSuiviFichierGridDtoToJSON,
} from './FormateurSuiviFichierGridDto';

/**
 * 
 * @export
 * @interface FcbFormateurSuiviDto
 */
export interface FcbFormateurSuiviDto {
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurSuiviDto
     */
    idsuivi?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurSuiviDto
     */
    idformateur?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurSuiviDto
     */
    idconseillerPedagogique?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurSuiviDto
     */
    idcentre?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurSuiviDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurSuiviDto
     */
    avis?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurSuiviDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurSuiviDto
     */
    url?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurSuiviDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurSuiviDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurSuiviDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurSuiviDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {Array<FormateurSuiviFichierGridDto>}
     * @memberof FcbFormateurSuiviDto
     */
    fichiers?: Array<FormateurSuiviFichierGridDto> | null;
}

/**
 * Check if a given object implements the FcbFormateurSuiviDto interface.
 */
export function instanceOfFcbFormateurSuiviDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbFormateurSuiviDtoFromJSON(json: any): FcbFormateurSuiviDto {
    return FcbFormateurSuiviDtoFromJSONTyped(json, false);
}

export function FcbFormateurSuiviDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbFormateurSuiviDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsuivi': !exists(json, 'idsuivi') ? undefined : json['idsuivi'],
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'idconseillerPedagogique': !exists(json, 'idconseillerPedagogique') ? undefined : json['idconseillerPedagogique'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'avis': !exists(json, 'avis') ? undefined : json['avis'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'fichiers': !exists(json, 'fichiers') ? undefined : (json['fichiers'] === null ? null : (json['fichiers'] as Array<any>).map(FormateurSuiviFichierGridDtoFromJSON)),
    };
}

export function FcbFormateurSuiviDtoToJSON(value?: FcbFormateurSuiviDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsuivi': value.idsuivi,
        'idformateur': value.idformateur,
        'idconseillerPedagogique': value.idconseillerPedagogique,
        'idcentre': value.idcentre,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'avis': value.avis,
        'remarque': value.remarque,
        'url': value.url,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'fichiers': value.fichiers === undefined ? undefined : (value.fichiers === null ? null : (value.fichiers as Array<any>).map(FormateurSuiviFichierGridDtoToJSON)),
    };
}

