/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbDirectionTerritorialeGridDto
 */
export interface FcbDirectionTerritorialeGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbDirectionTerritorialeGridDto
     */
    iddirectionTerritoriale?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbDirectionTerritorialeGridDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbDirectionTerritorialeGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbDirectionTerritorialeGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbDirectionTerritorialeGridDto interface.
 */
export function instanceOfFcbDirectionTerritorialeGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbDirectionTerritorialeGridDtoFromJSON(json: any): FcbDirectionTerritorialeGridDto {
    return FcbDirectionTerritorialeGridDtoFromJSONTyped(json, false);
}

export function FcbDirectionTerritorialeGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbDirectionTerritorialeGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iddirectionTerritoriale': !exists(json, 'iddirectionTerritoriale') ? undefined : json['iddirectionTerritoriale'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbDirectionTerritorialeGridDtoToJSON(value?: FcbDirectionTerritorialeGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iddirectionTerritoriale': value.iddirectionTerritoriale,
        'code': value.code,
        'nom': value.nom,
        'actif': value.actif,
    };
}

