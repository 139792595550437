/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CommissionParitaireEditDto,
  CommissionParitaireGridDtoPaginatedResults,
  CommissionParitaireSearchDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
} from '../models/index';
import {
    CommissionParitaireEditDtoFromJSON,
    CommissionParitaireEditDtoToJSON,
    CommissionParitaireGridDtoPaginatedResultsFromJSON,
    CommissionParitaireGridDtoPaginatedResultsToJSON,
    CommissionParitaireSearchDtoFromJSON,
    CommissionParitaireSearchDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface CommissionParitaireBaseSearchRequest {
    CommissionParitaireSearchDto?: CommissionParitaireSearchDto;
}

export interface CommissionParitaireDeleteCommissionParitaireRequest {
    id?: number;
}

export interface CommissionParitaireGetCommissionParitaireRequest {
    id?: number;
}

export interface CommissionParitaireGetDisplayNameRequest {
    id?: number;
}

export interface CommissionParitaireGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface CommissionParitaireGetSelectItemsRequest {
    searchField: string;
}

export interface CommissionParitaireSaveCommissionParitaireRequest {
    CommissionParitaireEditDto?: CommissionParitaireEditDto;
}

/**
 * 
 */
export class CommissionParitaireApi extends runtime.BaseAPI {

    /**
     */
    async commissionParitaireBaseSearchRaw(requestParameters: CommissionParitaireBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommissionParitaireGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/CommissionParitaire/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommissionParitaireSearchDtoToJSON(requestParameters.CommissionParitaireSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommissionParitaireGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async commissionParitaireBaseSearch(requestParameters: CommissionParitaireBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommissionParitaireGridDtoPaginatedResults> {
        const response = await this.commissionParitaireBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async commissionParitaireDeleteCommissionParitaireRaw(requestParameters: CommissionParitaireDeleteCommissionParitaireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/CommissionParitaire/DeleteCommissionParitaire`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async commissionParitaireDeleteCommissionParitaire(requestParameters: CommissionParitaireDeleteCommissionParitaireRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.commissionParitaireDeleteCommissionParitaireRaw(requestParameters, initOverrides);
    }

    /**
     */
    async commissionParitaireGetCommissionParitaireRaw(requestParameters: CommissionParitaireGetCommissionParitaireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommissionParitaireEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/CommissionParitaire`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommissionParitaireEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async commissionParitaireGetCommissionParitaire(requestParameters: CommissionParitaireGetCommissionParitaireRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommissionParitaireEditDto> {
        const response = await this.commissionParitaireGetCommissionParitaireRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async commissionParitaireGetDisplayNameRaw(requestParameters: CommissionParitaireGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/CommissionParitaire/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async commissionParitaireGetDisplayName(requestParameters: CommissionParitaireGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.commissionParitaireGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async commissionParitaireGetSearchCriteriasRaw(requestParameters: CommissionParitaireGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/CommissionParitaire/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async commissionParitaireGetSearchCriterias(requestParameters: CommissionParitaireGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.commissionParitaireGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async commissionParitaireGetSelectItemsRaw(requestParameters: CommissionParitaireGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling commissionParitaireGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/CommissionParitaire/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async commissionParitaireGetSelectItems(requestParameters: CommissionParitaireGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.commissionParitaireGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async commissionParitaireSaveCommissionParitaireRaw(requestParameters: CommissionParitaireSaveCommissionParitaireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommissionParitaireEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/CommissionParitaire`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommissionParitaireEditDtoToJSON(requestParameters.CommissionParitaireEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommissionParitaireEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async commissionParitaireSaveCommissionParitaire(requestParameters: CommissionParitaireSaveCommissionParitaireRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommissionParitaireEditDto> {
        const response = await this.commissionParitaireSaveCommissionParitaireRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
