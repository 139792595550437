import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { ApprenantAcquisDetail } from "./ApprenantAcquisDetail";
import { ExamenEntreeDetail } from "./ExamenEntreeDetail";
import { AcquisScolaireDetail } from "./AcquisScolaireDetail";
interface IApprenantAcquisProps {}

export const ApprenantAcquis: React.FunctionComponent<IApprenantAcquisProps> = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={ApprenantAcquisDetail} exact />
      <Route path={`${match.path}/examen/:idExamen/:state`} component={ExamenEntreeDetail} />
      <Route path={`${match.path}/scolaire/:idAcquis/:state`} component={AcquisScolaireDetail} />
    </Switch>
  );
};
