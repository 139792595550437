import { Button, Colors, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ButtonContainer, IDataTableColumn, showError } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { css } from "styled-components";

import {
  EBooleanSearchTypes,
  LieuFormationApi,
  LieuFormationGridDto,
  LieuFormationSearchDto,
  SiegeSocialApi
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { AddButton, EditButton, EmailButton, SearchTablePage, SmsButton, ViewButton } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface ILieuxFormationListPageProps {}

export const LieuxFormationListPage: React.FunctionComponent<ILieuxFormationListPageProps> = props => {
  const { t } = useTl();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const siegeSocialId = React.useMemo(() => +id, [id]);
  const api = useApiService(LieuFormationApi);
  const siegeSocialApi = useApiService(SiegeSocialApi);

  const apiFactory = useAbortableApiServiceFactory(LieuFormationApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFunction = React.useCallback(
    (sObj?: LieuFormationSearchDto) => {
      sObj.idsiegeSocial = siegeSocialId;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.lieuFormationBaseSearch({ LieuFormationSearchDto: sObj });
    },
    [apiFactory, siegeSocialId]
  );
  const onViewClick = React.useCallback(
    (dto: LieuFormationGridDto) => {
      history.push(`${ERoutes.lieuFormation}/${dto.idlieuFormation}/detail/view?idsiegeSocial=${dto.idsiegeSocial}`);
    },
    [history]
  );

  const onEditClick = React.useCallback(
    (dto: LieuFormationGridDto) => {
      history.push(`${ERoutes.lieuFormation}/${dto.idlieuFormation}/detail/edit?idsiegeSocial=${dto.idsiegeSocial}`);
    },
    [history]
  );

  const sendSmsSingle = React.useCallback(
    (id: number) => {
      // pushSmsRecipientIds([id]);
      // pushSmsRecipientType(ETypeDestinataireSms.SiegeSocial); TODO:
      history.push(`${ERoutes.sms}/0/detail/edit`);
    },
    [history]
  );

  const { data: isSiegeSocialActif } = useQuery(["siege-social-actif", siegeSocialId], () =>
    siegeSocialApi.siegeSocialIsActif({ id: siegeSocialId })
  );

  const createSuivi = React.useCallback(
    (dto: LieuFormationGridDto) => {
      history.push(`${ERoutes.lieuFormation}/${dto.idlieuFormation}/suivi/edit/-1?idsiegeSocial=${dto.idsiegeSocial}`);
    },
    [history]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: LieuFormationGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => onViewClick(row)} />
            <EditButton minimal={true} onClick={() => onEditClick(row)} />
            <Button icon={IconNames.ALIGN_JUSTIFY} minimal={true} onClick={() => createSuivi(row)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.NomLieuFormation),
        fieldName: "nom"
      },
      { header: () => t(ETLCodes.NumeroUe), fieldName: "numeroUe" },
      {
        header: () => t(ETLCodes.Contact),
        fieldName: "contact"
      },
      {
        header: () => t(ETLCodes.Telephone),
        fieldName: "telephone",
        render: (value: LieuFormationGridDto) => (
          <SmsButton phone={value.telephone} disabled onClick={() => sendSmsSingle(value.idlieuFormation)} />
        )
      },
      {
        header: () => t(ETLCodes.Email),
        fieldName: "email",
        alignment: "center",
        render: (value: LieuFormationGridDto) => <EmailButton email={value.email} />
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      },
      {
        header: () => t(ETLCodes.Enseigne),
        fieldName: "enseigne"
      }
    ],
    [createSuivi, onEditClick, onViewClick, sendSmsSingle, t]
  );

  const onAddClick = React.useCallback(
    e => {
      if (!isSiegeSocialActif) {
        showError(t(ETLCodes.CannotAddLieuToSiegeSocialInactif));
        return;
      }

      e.stopPropagation();
      history.push(`${ERoutes.lieuFormation}/-1/detail/edit?idsiegeSocial=${siegeSocialId}`);
    },
    [history, isSiegeSocialActif, siegeSocialId, t]
  );

  const getCriterias = React.useCallback(() => api.lieuFormationGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const defaultCriterias = React.useMemo(
    () => [
      {
        criteria: "MesLieuxFormation",
        searchMode: EBooleanSearchTypes.Equals,
        value: true
      }
    ],
    []
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      withCard={false}
      getCriteriasFunction={getCriterias}
      searchFunction={searchFunction}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.SiegeSociauxLieuxFormation), route: ERoutes.siegeSocial }]}
      sortKeys={{ nom: "ASC" }}
      rightElement={<AddButton onClick={onAddClick} text={t(ETLCodes.General_Add)} intent={Intent.PRIMARY} />}
      customizeRowStyle={(row: LieuFormationGridDto) => css`
        & * {
          color: ${!row.actif ? Colors.RED3 + " !important" : null};
        }
      `}
      keyFieldName="idlieuFormation"
      defaultCriterias={defaultCriterias}
    />
  );
};
