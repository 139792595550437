import { Colors } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";

import { Carousel } from "../../../../components";

const Container = styled.div`
  display: flex;
  flex: 1;
`;

const LeftPanel = styled.div`
  flex: 1;
  padding: 1rem 3rem;

  .single-panel {
    border-right: solid 1px ${Colors.LIGHT_GRAY3};
  }
`;

const Empty = styled.div`
  flex: 0.5;
`;

export interface ICarouselComparerProps {
  leftElement: JSX.Element;
}

export const CarouselComparer: React.FunctionComponent<ICarouselComparerProps> = ({ leftElement, children }) => {
  return (
    <Container>
      {!children && <Empty />}
      <LeftPanel className={!children && "single-panel"}>{leftElement}</LeftPanel>
      {!!children ? <Carousel>{children}</Carousel> : <Empty />}
    </Container>
  );
};
