import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router";

import { RepresentantApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { RepresentantAppernantLiesSwitch } from "./RepresentantAppernantLiesSwitch";
import { RepresentantSwitch } from "./RepresentantSwitch";

export interface IRepresentantItemPageProps {}

export const RepresentantItemPage: React.FunctionComponent<IRepresentantItemPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id, tab } = useParams<{ id: string; tab: string; state: string }>();
  const { search } = useLocation();
  const representantApi = useApiService(RepresentantApi);
  const [displayName] = useApiEffect(() => representantApi.representantGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.Representants), route: ERoutes.representant },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabDetail"
        onChange={newTabId => history.push(`${ERoutes.representant}/${id}/${newTabId}${search}`)}
        renderActiveTabPanelOnly
        selectedTabId={tab}
      >
        <BackButton backRoute={ERoutes.representant}></BackButton>
        <Tab id="detail" title={t(ETLCodes.Detail)} panel={<RepresentantSwitch />} />
        <Tab
          id="apprenants"
          disabled={+id <= 0}
          title={t(ETLCodes.Apprenants)}
          panel={<RepresentantAppernantLiesSwitch></RepresentantAppernantLiesSwitch>}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
