/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EDashboard {
    Inscriptions = 'Inscriptions',
    Suivis = 'Suivis',
    SuivisSL = 'SuivisSL',
    Absence = 'Absence',
    DossiersPostFormation = 'DossiersPostFormation',
    DossiersSorties = 'DossiersSorties',
    DossiersFinAgrement = 'DossiersFinAgrement',
    PratiquePro = 'PratiquePro',
    DemandesFormationCrea = 'DemandesFormationCrea'
}


export function EDashboardFromJSON(json: any): EDashboard {
    return EDashboardFromJSONTyped(json, false);
}

export function EDashboardFromJSONTyped(json: any, ignoreDiscriminator: boolean): EDashboard {
    return json as EDashboard;
}

export function EDashboardToJSON(value?: EDashboard | null): any {
    return value as any;
}

