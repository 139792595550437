import { usePrevious } from "nsitools-react";
import React from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import { Configuration, EIfapmeSide, SystemApi, SystemInfoDto } from "../api";
import { useSearchCriteriaContext } from "../contexts";
import { apiConfiguration } from "../apiConfig";

export interface ISystemInfo extends SystemInfoDto {
  fetchingInfo: boolean;
  backendNotReacheable: boolean;
  walterUrl: string;
  isFetchedAfterMount: boolean;
  ifapmeSide: EIfapmeSide;
}

export function useSystemInfo() {
  const systemApi = new SystemApi(new Configuration(apiConfiguration()));
  const { data: systemInfo, isFetching: fetchingInfo, error, isFetchedAfterMount } = useQuery(
    "system-info",
    () => systemApi.systemGet(),
    {
      enabled: true,
      refetchInterval: 1000 * 60 * 10
    }
  );
  const searchCriteriaContext = useSearchCriteriaContext();
  const { pathname } = useLocation();

  const walterUrl = React.useMemo(() => systemInfo?.walterUrl, [systemInfo?.walterUrl]);
  const hopeUrl = React.useMemo(() => systemInfo?.hopeUrl, [systemInfo?.hopeUrl]);

  const [mustRefreshFrontend, setMustRefreshFrontend] = React.useState(false);
  const previousPathName = usePrevious(pathname);
  const previousInfo = usePrevious(systemInfo);

  const ifapmeSide = React.useMemo<EIfapmeSide>(() => {
    if (walterUrl?.includes(window.location.origin)) {
      return EIfapmeSide.Walter;
    }
    if (hopeUrl?.includes(window.location.origin)) {
      return EIfapmeSide.Hope;
    }
    return null;
  }, [hopeUrl, walterUrl]);

  React.useEffect(() => {
    if (!fetchingInfo && !!error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, [error, fetchingInfo]);

  const backendNotReacheable = React.useMemo(() => !systemInfo || !!error, [error, systemInfo]);

  React.useEffect(() => {
    if (mustRefreshFrontend && previousPathName !== pathname) {
      // @ts-ignore
      window.location.reload(true);
      searchCriteriaContext?.clearCriterias();
    }
  }, [mustRefreshFrontend, pathname, previousPathName, searchCriteriaContext]);

  React.useEffect(() => {
    if (previousInfo && previousInfo?.applicationVersion !== systemInfo?.applicationVersion) {
      // eslint-disable-next-line no-console
      console.log("Backend version has changed, Walter application will be refreshed soon...");
      setMustRefreshFrontend(true);
    }
  }, [previousInfo, systemInfo?.applicationVersion]);

  return {
    ...systemInfo,
    fetchingInfo,
    backendNotReacheable,
    walterUrl,
    isFetchedAfterMount,
    ifapmeSide
  } as ISystemInfo;
}

