/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExperienceProfessionnelleGridDto
 */
export interface ExperienceProfessionnelleGridDto {
    /**
     * 
     * @type {number}
     * @memberof ExperienceProfessionnelleGridDto
     */
    idexperienceProfessionnelle?: number;
    /**
     * 
     * @type {number}
     * @memberof ExperienceProfessionnelleGridDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {string}
     * @memberof ExperienceProfessionnelleGridDto
     */
    fonction?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ExperienceProfessionnelleGridDto
     */
    dateDeDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ExperienceProfessionnelleGridDto
     */
    dateDeFin?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ExperienceProfessionnelleGridDto
     */
    description?: string | null;
}

/**
 * Check if a given object implements the ExperienceProfessionnelleGridDto interface.
 */
export function instanceOfExperienceProfessionnelleGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExperienceProfessionnelleGridDtoFromJSON(json: any): ExperienceProfessionnelleGridDto {
    return ExperienceProfessionnelleGridDtoFromJSONTyped(json, false);
}

export function ExperienceProfessionnelleGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExperienceProfessionnelleGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idexperienceProfessionnelle': !exists(json, 'idexperienceProfessionnelle') ? undefined : json['idexperienceProfessionnelle'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'fonction': !exists(json, 'fonction') ? undefined : json['fonction'],
        'dateDeDebut': !exists(json, 'dateDeDebut') ? undefined : (json['dateDeDebut'] === null ? null : new Date(json['dateDeDebut'])),
        'dateDeFin': !exists(json, 'dateDeFin') ? undefined : (json['dateDeFin'] === null ? null : new Date(json['dateDeFin'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function ExperienceProfessionnelleGridDtoToJSON(value?: ExperienceProfessionnelleGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idexperienceProfessionnelle': value.idexperienceProfessionnelle,
        'idapprenant': value.idapprenant,
        'fonction': value.fonction,
        'dateDeDebut': value.dateDeDebut === undefined ? undefined : (value.dateDeDebut === null ? null : value.dateDeDebut.toISOString()),
        'dateDeFin': value.dateDeFin === undefined ? undefined : (value.dateDeFin === null ? null : value.dateDeFin.toISOString()),
        'description': value.description,
    };
}

