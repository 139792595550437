import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  ButtonContainer,
  ButtonsBloc,
  DataTable,
  FGCustomPanel,
  FGMaskInput,
  FGTextInput,
  FieldGroup,
  FieldSet,
  InlineButtonContainer,
  showError,
  useGridState,
  useSearchApi
} from "nsitools-react";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router";
import {
  TuteurApi,
  TuteurLieuDoublonGridDto,
  TuteurLieuDoublonSearchDto,
  TuteurLieuDoublonSearchDtoFromJSON
} from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import {
  AddButton,
  CancelButton,
  EditButton,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  SmallFormGenerator,
  UnordoredListColumn,
  ViewButton
} from "../../../../../../components";
import { useBeIdCardContext, useDialog } from "../../../../../../contexts";
import { useApiService, useTabMessage, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

export interface ILieuFormationTuteurDoublonsProps {
  setBaseData: (sObj: TuteurLieuDoublonSearchDto) => void;
}

export const LieuFormationTuteurDoublons: React.FunctionComponent<ILieuFormationTuteurDoublonsProps> = ({
  setBaseData
}) => {
  const { t } = useTl();
  const dialogContext = useDialog();
  const history = useHistory();
  const [nationalites, nLoading] = useReferential(a => a.referentialGetAllNationalite(), false, []);
  const api = useApiService(TuteurApi);
  const { id } = useParams<{ id: string }>();
  const { search: searchQ } = useLocation();
  const { foundRowsData } = useBeIdCardContext();
  const cfs = React.useMemo(() => new URLSearchParams(searchQ).get("cfs"), [searchQ]);
  const { messageValue: baseSObj } = useTabMessage(cfs);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [10],
    pageSize: 10,
    sortKeys: { nom: "ASC" }
  });
  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: TuteurLieuDoublonSearchDto) => {
      sObj.idlieuFormation = +id;
      return api.tuteurSearchDoublonsLieu({ TuteurLieuDoublonSearchDto: sObj });
    },
    [api, id]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: false
  });
  const add = React.useCallback(
    (fromPersonne: boolean) => {
      if (totalCount && totalCount > 0) {
        dialogContext.showDialog({
          message: t(fromPersonne ? ETLCodes.AddFormeConfirmMessage : ETLCodes.AddConfirmMessage, {
            forme: t(ETLCodes.Tuteur)
          }),
          onConfirmed: async () => {
            history.push(`${ERoutes.lieuFormation}/${id}/tuteur/edit/-1${searchQ}`);
          }
        });
      } else {
        history.push(`${ERoutes.lieuFormation}/${id}/tuteur/edit/-1${searchQ}`);
      }
    },
    [dialogContext, history, id, searchQ, t, totalCount]
  );

  const initialData = React.useMemo(() => {
    const onlyRegNatNb =
      !foundRowsData ||
      (!foundRowsData.nom && !foundRowsData.prenom && !foundRowsData.dateNaissance && foundRowsData.nationalNb);
    return TuteurLieuDoublonSearchDtoFromJSON({
      nom: baseSObj?.nom?.value ?? foundRowsData?.nom,
      prenom: baseSObj?.prenom?.value ?? foundRowsData?.prenom,
      registreNational: baseSObj?.registreNational?.value ?? onlyRegNatNb ? foundRowsData?.nationalNb : undefined,
      dateNaissance: baseSObj?.nodateNaissancem?.value ?? foundRowsData?.dateNaissance,
      nationalite: baseSObj?.nationalite?.value
    });
  }, [
    baseSObj?.nationalite?.value,
    baseSObj?.nodateNaissancem?.value,
    baseSObj?.nom?.value,
    baseSObj?.prenom?.value,
    baseSObj?.registreNational?.value,
    foundRowsData
  ]);

  React.useEffect(() => {
    if (foundRowsData || baseSObj) {
      search(initialData);
    }
  }, [baseSObj, foundRowsData, initialData, search]);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: TuteurLieuDoublonGridDto) => (
          <ButtonContainer>
            {row.isTuteur && !!row.idTuteurLieu ? (
              <>
                <ViewButton
                  minimal={true}
                  onClick={() =>
                    history.push(`${ERoutes.lieuFormation}/${id}/tuteur/view/${row.idTuteurLieu}${searchQ}`)
                  }
                />
                <EditButton
                  minimal={true}
                  onClick={() =>
                    history.push(`${ERoutes.lieuFormation}/${id}/tuteur/edit/${row.idTuteurLieu}${searchQ}`)
                  }
                />
              </>
            ) : (
              <AddButton
                tooltipText={t(row.isTuteur ? ETLCodes.CreerAPartirdeTuteur : ETLCodes.CreerAPartirdePersonne)}
                onClick={() => {
                  setBaseData({ ...row });
                  add(!row.isTuteur);
                }}
              />
            )}
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.DateNaissance),
        fieldName: "dateNaissance"
      },
      {
        header: () => t(ETLCodes.NumeroNational),
        fieldName: "registreNational"
      },
      {
        header: () => t(ETLCodes.Adresse),
        fieldName: "adresse"
      },
      {
        header: () => t(ETLCodes.CodePostal),
        fieldName: "codePostal"
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      },
      {
        header: () => t(ETLCodes.Nationalite),
        fieldName: "nationalite"
      }
      // {
      //   header: () => t(ETLCodes.Forme),
      //   fieldName: "forme",
      //   render: (row: TuteurLieuDoublonGridDto) => (
      //     <UnordoredListColumn
      //       values={[
      //         { value: row.isApprenant, label: t(ETLCodes.Apprenant) },
      //         { value: row.isFormateur, label: t(ETLCodes.Formateur) },
      //         { value: row.isRepresentant, label: t(ETLCodes.Representant) },
      //         { value: row.isTuteur, label: t(ETLCodes.Tuteur) },
      //         { value: row.isChefEntreprise, label: t(ETLCodes.ChefEntreprise) },
      //         { value: row.isCollaborateur, label: t(ETLCodes.Collaborateur) },
      //         { value: row.isDelegueTutelle, label: t(ETLCodes.Referent) },
      //         { value: row.isConseillePedagogique, label: t(ETLCodes.ConseillerPedagogique) }
      //       ]}
      //     />
      //   )
      // }
    ],
    [add, history, id, searchQ, setBaseData, t]
  );

  const [firstSearch, setFirstSearch] = React.useState(false);

  return (
    <FieldSet collapsable={false} title={t(ETLCodes.Tuteurs)}>
      <SmallFormGenerator
        enableDirtyCheck={false}
        initialValues={initialData}
        onSubmit={c => {
          if ((c.nom && c.prenom) || c.registreNational) {
            search(c);
            setFirstSearch(true);
          } else {
            showError(t(ETLCodes.NoCriteriaFilled));
          }
        }}
        editMode
        hideButtons={true}
        onCancel={() => history.push(`${ERoutes.lieuFormation}/${id}/tuteur${searchQ}`)}
      >
        <FieldGroup columns={2}>
          <FGTextInput name="nom" label={t(ETLCodes.Nom)} maxLength={100} forceCase="upper" />
          <FGWalterSelectInput
            name="nationalite"
            label={t(ETLCodes.Nationalite)}
            items={nationalites}
            loading={nLoading}
          />
          <FGTextInput name="prenom" label={t(ETLCodes.Prenom)} maxLength={100} />
          <FGMaskInput
            name="registreNational"
            label={t(ETLCodes.NumeroNational)}
            cleaveOptions={{ delimiters: [".", ".", "-", "."], blocks: [2, 2, 2, 3, 2], numericOnly: true }}
          />
          <FGWalterDateMaskInput name="dateNaissance" label={t(ETLCodes.DateNaissance)} />
        </FieldGroup>
        <FieldGroup>
          <FGCustomPanel>
            {ctx => (
              <InlineButtonContainer>
                <ButtonsBloc></ButtonsBloc>
                <ButtonsBloc>
                  <CancelButton
                    minimal={false}
                    onClick={() => history.push(`${ERoutes.lieuFormation}/${id}/tuteur${searchQ}`)}
                  />
                  <Button icon={IconNames.SEARCH} type="submit" text={t(ETLCodes.Search)} intent={Intent.PRIMARY} />
                  <AddButton
                    onClick={() => {
                      setBaseData(ctx.formik.values);
                      add(false);
                    }}
                    text={t(ETLCodes.General_Add)}
                    intent={Intent.PRIMARY}
                    minimal={false}
                    disabled={!firstSearch}
                  />
                </ButtonsBloc>
              </InlineButtonContainer>
            )}
          </FGCustomPanel>
        </FieldGroup>
        <FGCustomPanel>
          {ctx => (
            <FieldSet title={t(ETLCodes.TableResults, { count: totalCount })}>
              <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
            </FieldSet>
          )}
        </FGCustomPanel>
      </SmallFormGenerator>
    </FieldSet>
  );
};
