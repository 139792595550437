import { Intent } from "@blueprintjs/core";
import { addMinutes, format, parseJSON } from "date-fns";
import {
  ButtonContainer,
  DataTable,
  FGTextAreaInput,
  FGTextInput,
  FieldGroup,
  IDataTableColumn,
  showError,
  useGridState
} from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { FormateurSuiviDocumentEditDialog, FormateurSuiviDocumentUploadDialog } from "..";
import {
  FcbFormateurSuiviDto,
  FcbFormateurSuiviDtoFromJSON,
  FormateurSuiviApi,
  FormateurSuiviFichierGridDto
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  AddButton,
  DeleteButton,
  DownloadButton,
  EditButton,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../../components";
import { useDialog } from "../../../../../contexts";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import { exportFile } from "../../../../../utils";

export interface IFormateurSuiviItemPageProps {}

export const FormateurSuiviItemPage: React.FunctionComponent<IFormateurSuiviItemPageProps> = () => {
  const { id, idSuivi, state } = useParams<{ id: string; idSuivi: string; state: string }>();
  const { t } = useTl();
  const api = useApiService(FormateurSuiviApi);
  const history = useHistory();
  const [uploadDialogOpen, setUploadDialogOpen] = React.useState(false);
  const [currentFormateurSuiviFichierId, setCurrentFormateurSuiviFichierId] = React.useState(null);

  const [conseiller, loadingConseiller] = useReferential(a => a.referentialGetConseillersPedagogiques(), false, []);
  const [centre, loadingCentre] = useReferential(a => a.referentialGetCentre(), true, []);
  const [avis, loadingAvis] = useReferential(a => a.referentialGetAvisFormateurSuivi(), false, []);
  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idconseillerPedagogique: Yup.string().required(t(ETLCodes.Required)),
      avis: Yup.string().required(t(ETLCodes.Required)),
      idcentre: Yup.number().required(t(ETLCodes.Required)),
      date: Yup.date().required(t(ETLCodes.Required))
    });
  }, [t]);

  const { data, loading, deleteItem, deleting, saveItem, saving, refresh } = useCrudApi<FcbFormateurSuiviDto>(
    React.useMemo(
      () => ({
        getApiFn: () =>
          +idSuivi <= 0 ? FcbFormateurSuiviDtoFromJSON({ idformateur: +id }) : api.formateurSuiviGet({ id: +idSuivi }),
        saveApiFn: d => api.formateurSuiviSave({ FcbFormateurSuiviDto: d }),
        onSavedRoute: d => `${ERoutes.formateur}/${d.idformateur}/suivi`,
        deleteApiFn: d => api.formateurSuiviDelete({ id: d.idsuivi }),
        onDeletedRoute: () => `${ERoutes.formateur}/${+id}/suivi`
      }),
      [api, id, idSuivi]
    )
  );

  const onAdd = React.useCallback(e => {
    e.stopPropagation();
    setUploadDialogOpen(true);
  }, []);

  const [rowLoading, setRowLoading] = React.useState(null);
  const { showDialogPromise } = useDialog();
  const deleteFichier = React.useCallback(
    async (idsuiviFichier: number) => {
      const result = await showDialogPromise({
        message: t(ETLCodes.DeleteConfirmationMessage)
      });

      if (result === "yes") {
        try {
          setRowLoading(idsuiviFichier);
          await api.formateurSuiviDeleteFormateurSuiviFichier({
            id: idsuiviFichier
          });
          refresh();
        } catch {
          showError(t(ETLCodes.ErrorWhileDeletingFile));
        } finally {
          setRowLoading(null);
        }
      }
    },
    [api, refresh, showDialogPromise, t]
  );

  const downloadFichier = React.useCallback(
    async (idsuiviFichier: number) => {
      try {
        setRowLoading(idsuiviFichier);
        const file = await api.formateurSuiviDownloadFormateurSuiviFichier({
          id: idsuiviFichier
        });
        await exportFile(file);
      } catch {
        showError(t(ETLCodes.ErrorWhileDownloadingFile));
      } finally {
        setRowLoading(null);
      }
    },
    [api, t]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FormateurSuiviFichierGridDto) => (
          <ButtonContainer>
            <EditButton
              minimal={true}
              onClick={() => setCurrentFormateurSuiviFichierId(row.idformateurSuiviFichier)}
              loading={rowLoading === row.idformateurSuiviFichier}
              disabled={!!rowLoading && rowLoading !== row.idformateurSuiviFichier}
            />
            <DownloadButton
              minimal={true}
              onClick={() => downloadFichier(row.idformateurSuiviFichier)}
              loading={rowLoading === row.idformateurSuiviFichier}
              disabled={!!rowLoading && rowLoading !== row.idformateurSuiviFichier}
            />
            <DeleteButton
              minimal={true}
              onDelete={() => deleteFichier(row.idformateurSuiviFichier)}
              loading={rowLoading === row.idformateurSuiviFichier}
              disabled={!!rowLoading && rowLoading !== row.idformateurSuiviFichier}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.NomFichier),
        fieldName: "fileName"
      },
      {
        header: () => t(ETLCodes.DateUpload),
        fieldName: "uploadDate"
      },
      {
        header: () => t(ETLCodes.UtilisateurUpload),
        fieldName: "creationUserName"
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "typeSuiviFichier"
      }
    ],
    [deleteFichier, downloadFichier, rowLoading, t]
  );

  const tableState = useGridState<any>({
    serverMode: false,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { uploadDate: "DESC", fileName: "ASC" }
  });

  const { setData } = tableState;

  React.useEffect(() => {
    if (data?.fichiers) {
      setData(data.fichiers);
    }
  }, [data?.fichiers, setData]);

  const onClose = React.useCallback(
    (toRefresh?: boolean) => {
      setCurrentFormateurSuiviFichierId(null);
      setUploadDialogOpen(false);
      if (toRefresh) {
        refresh();
      }
    },
    [refresh]
  );

  const formatDate = React.useCallback((date: Date) => {
    return format(parseJSON(addMinutes(date, date.getTimezoneOffset())), "dd/MM/yyyy HH:mm");
  }, []);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      showColons
      editMode={state === "edit"}
      minLabelWidth={180}
      labelAlignment="right"
      formatDate="dd/MM/yyyy"
      validationSchema={FormSchema}
      inline
      boldLabel
      loading={loading}
      onCancel={() => history.push(`${ERoutes.formateur}/${+id}/suivi`)}
      onDelete={deleteItem}
      showDeleteButton={+idSuivi > 0}
      saving={saving}
      deleting={deleting}
    >
      <FieldGroup
        columns={2}
        fieldsetProps={{
          title: t(ETLCodes.Informations)
        }}
      >
        <FieldGroup>
          <FGWalterSelectInput
            name="idconseillerPedagogique"
            label={t(ETLCodes.Conseiller)}
            items={conseiller}
            loading={loadingConseiller}
          />
          <FGWalterSelectInput name="idcentre" label={t(ETLCodes.Centre)} items={centre} loading={loadingCentre} />
          <FGWalterDateMaskInput name="date" label={t(ETLCodes.Date)} />
          <FGTextAreaInput name="remarque" label={t(ETLCodes.Remarque)} />
          <FGTextInput name="url" label={t(ETLCodes.URL)} maxLength={100} />
          <FGWalterSelectInput name="avis" label={t(ETLCodes.Avis)} items={avis} loading={loadingAvis} />
        </FieldGroup>
      </FieldGroup>
      <FieldGroup
        visible={+idSuivi > 0}
        fieldsetProps={{
          title: t(ETLCodes.Documents),
          rightElement: <AddButton onClick={onAdd} text={t(ETLCodes.General_Add)} intent={Intent.PRIMARY} />
        }}
      >
        <DataTable formatDate={formatDate} tableState={tableState} loading={loading} columns={columns}></DataTable>
        {uploadDialogOpen && (
          <FormateurSuiviDocumentUploadDialog onClose={onClose} dialogOpen={uploadDialogOpen} idsuivi={+idSuivi} />
        )}
        {!!currentFormateurSuiviFichierId && (
          <FormateurSuiviDocumentEditDialog
            onClose={onClose}
            idformateurSuiviFichier={currentFormateurSuiviFichierId}
          />
        )}
      </FieldGroup>
    </SmallFormGenerator>
  );
};
