/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExamenEntreeFceFichierDto
 */
export interface ExamenEntreeFceFichierDto {
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeFceFichierDto
     */
    idexamenEntreeFceFichier?: number;
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeFceFichierDto
     */
    idexamenEntreeFce?: number;
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeFceFichierDto
     */
    idfichier?: number;
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeFceFichierDto
     */
    idtypeAcquisFichier?: number;
    /**
     * 
     * @type {string}
     * @memberof ExamenEntreeFceFichierDto
     */
    fileName?: string | null;
}

/**
 * Check if a given object implements the ExamenEntreeFceFichierDto interface.
 */
export function instanceOfExamenEntreeFceFichierDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExamenEntreeFceFichierDtoFromJSON(json: any): ExamenEntreeFceFichierDto {
    return ExamenEntreeFceFichierDtoFromJSONTyped(json, false);
}

export function ExamenEntreeFceFichierDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExamenEntreeFceFichierDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idexamenEntreeFceFichier': !exists(json, 'idexamenEntreeFceFichier') ? undefined : json['idexamenEntreeFceFichier'],
        'idexamenEntreeFce': !exists(json, 'idexamenEntreeFce') ? undefined : json['idexamenEntreeFce'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'idtypeAcquisFichier': !exists(json, 'idtypeAcquisFichier') ? undefined : json['idtypeAcquisFichier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
    };
}

export function ExamenEntreeFceFichierDtoToJSON(value?: ExamenEntreeFceFichierDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idexamenEntreeFceFichier': value.idexamenEntreeFceFichier,
        'idexamenEntreeFce': value.idexamenEntreeFce,
        'idfichier': value.idfichier,
        'idtypeAcquisFichier': value.idtypeAcquisFichier,
        'fileName': value.fileName,
    };
}

