import { Tooltip2 } from "@blueprintjs/popover2";
import * as React from "react";
import styled from "styled-components";

const Circle = styled.div<{ bgColor: string }>`
  height: 15px;
  width: 15px;
  background-color: ${props => props.bgColor};
  border-radius: 50%;
  display: inline-block;
  margin: 0 0.5rem;
`;

export interface ICircleColumnProps {
  color: string;
  tooltip?: string | JSX.Element;
}

export const CircleColumn: React.FunctionComponent<ICircleColumnProps> = ({ color, tooltip }) => {
  const circle = React.useMemo(() => <Circle bgColor={color}></Circle>, [color]);
  return !!tooltip ? (
    <Tooltip2 position="bottom" content={tooltip}>
      {circle}
    </Tooltip2>
  ) : (
    circle
  );
};
