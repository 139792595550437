import { Button, Card, Classes, Colors, Dialog, Icon } from "@blueprintjs/core";
import { FGCustomPanel, FieldSet } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";

import { SmallFormGenerator } from "..";
import { AdresseDoublonApi, AdresseDoublonDto, AdresseDoublonSearchDtoFromJSON } from "../../api";
import { useApiService, useManageError, useTheme, useTl } from "../../hooks";
import { ETLCodes } from "../../locales";

const StyledDialog = styled(Dialog)`
  width: 600px;
  height: auto;
  background-color: white;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const StyledCard = styled(Card)<{ backgroundColor: string }>`
  padding: 10px !important;
  background-color: ${props => props.backgroundColor};
  display: flex;
  justify-content: space-between;
`;

export interface IAdresseDoublonDialogProps {
  initialData: AdresseDoublonDto;
  dialogOpen: boolean;
  onClose: (refresh?: boolean) => void;
}

export const AdresseDoublonDialog: React.FunctionComponent<IAdresseDoublonDialogProps> = ({
  initialData,
  dialogOpen,
  onClose
}) => {
  const { t } = useTl();
  const { theme } = useTheme();
  const api = useApiService(AdresseDoublonApi);
  const { manageError } = useManageError();

  const searchFn = React.useCallback(() => {
    return api.adresseDoublonBaseSearch({
      AdresseDoublonSearchDto: AdresseDoublonSearchDtoFromJSON({
        idpersonne: initialData.idpersonne,
        idtypeAdresse: initialData.idtypeAdresse
      })
    });
  }, [api, initialData.idpersonne, initialData.idtypeAdresse]);
  const { data, isFetching } = useQuery(
    ["search-adresse-doublons", initialData.idpersonne, initialData.idtypeAdresse],
    searchFn
  );

  const [saving, setSaving] = React.useState(false);
  const onSave = React.useCallback(
    async (d: AdresseDoublonDto) => {
      setSaving(true);
      try {
        await api.adresseDoublonSaveAdresseDoublon({ AdresseDoublonDto: d });
      } catch (e) {
        manageError(e);
      } finally {
        setSaving(false);
        onClose(true);
      }
    },
    [api, manageError, onClose]
  );

  return (
    <StyledDialog isOpen={dialogOpen} onClose={() => onClose()}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          enableDirtyCheck={false}
          initialValues={initialData}
          onSubmit={onSave}
          loading={isFetching}
          saving={saving}
          editMode
          showDeleteButton={false}
        >
          <FieldSet
            title={t(ETLCodes.VeuillezChoisirAdresseCorrecte)}
            rightElement={<Button minimal onClick={() => onClose()} icon="cross" />}
          >
            <FGCustomPanel>
              {ctx => (
                <CardContainer>
                  {data?.results?.map(a => (
                    <StyledCard
                      interactive={true}
                      onClick={() => ctx.formik.setFieldValue("idadresseToKeep", a.idadresse)}
                      backgroundColor={
                        ctx.formik.values.idadresseToKeep === a.idadresse ? Colors.WHITE : Colors.LIGHT_GRAY3
                      }
                    >
                      <div>
                        {a.adresse}
                        <br />
                        {a.localite && (
                          <>
                            {a.codePostal ?? a.codePostalText} {a.localite}
                            {a.commune ? ` (${a.commune})` : a.commune}
                            <br />
                          </>
                        )}
                        {a.typeAdresse}
                      </div>
                      {ctx.formik.values.idadresseToKeep === a.idadresse ? (
                        <Icon color={theme.sucessColor} icon="tick-circle" />
                      ) : (
                        <Icon icon="circle" />
                      )}
                    </StyledCard>
                  ))}
                </CardContainer>
              )}
            </FGCustomPanel>
          </FieldSet>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
