/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EPrintingType {
    CRYSTAL = 'CRYSTAL',
    SSRS = 'SSRS',
    EXCEL = 'EXCEL'
}


export function EPrintingTypeFromJSON(json: any): EPrintingType {
    return EPrintingTypeFromJSONTyped(json, false);
}

export function EPrintingTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EPrintingType {
    return json as EPrintingType;
}

export function EPrintingTypeToJSON(value?: EPrintingType | null): any {
    return value as any;
}

