/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbClauseGridDto
 */
export interface FcbClauseGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbClauseGridDto
     */
    idclause?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClauseGridDto
     */
    idStade?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbClauseGridDto
     */
    stade?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClauseGridDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClauseGridDto
     */
    title?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClauseGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbClauseGridDto interface.
 */
export function instanceOfFcbClauseGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbClauseGridDtoFromJSON(json: any): FcbClauseGridDto {
    return FcbClauseGridDtoFromJSONTyped(json, false);
}

export function FcbClauseGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbClauseGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idclause': !exists(json, 'idclause') ? undefined : json['idclause'],
        'idStade': !exists(json, 'idStade') ? undefined : json['idStade'],
        'stade': !exists(json, 'stade') ? undefined : json['stade'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbClauseGridDtoToJSON(value?: FcbClauseGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idclause': value.idclause,
        'idStade': value.idStade,
        'stade': value.stade,
        'description': value.description,
        'title': value.title,
        'actif': value.actif,
    };
}

