import { Colors } from '@blueprintjs/core';
import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from 'nsitools-react';
import * as React from "react";
import { useParams } from 'react-router';
import { css } from 'styled-components';
import { ContactApi, ContactLieuFormationGridDto, ContactLieuFormationSearchDto } from '../../../../../api';
import { ERoutes } from '../../../../../AppRouter';
import { EditButton, ViewButton } from '../../../../../components';
import { useApiService, useTl } from '../../../../../hooks';
import { ETLCodes } from '../../../../../locales';

export interface IContactLieuxFormationProps { }

export const ContactLieuxFormation: React.FunctionComponent<IContactLieuxFormationProps> = props => {
  const { t } = useTl();
  const { id } = useParams<{ id: string }>();
  const api = useApiService(ContactApi);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { nom: "ASC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: ContactLieuFormationSearchDto) => {
      sObj.idcontact = +id;
      return api.contactSearchContactLieuFormation({ ContactLieuFormationSearchDto: sObj });
    },
    [api, id]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const onViewClick = React.useCallback((dto: ContactLieuFormationGridDto) => {
    window.open(
      `#${ERoutes.lieuFormation}/${dto.idlieuFormation}/detail/view?idsiegeSocial=${dto.idsiegeSocial}`,
      "_blank"
    );
  }, []);

  const onEditClick = React.useCallback((dto: ContactLieuFormationGridDto) => {
    window.open(
      `#${ERoutes.lieuFormation}/${dto.idlieuFormation}/detail/edit?idsiegeSocial=${dto.idsiegeSocial}`,
      "_blank"
    );
  }, []);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ContactLieuFormationGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => onViewClick(row)} />
            <EditButton minimal={true} onClick={() => onEditClick(row)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.NomLieuFormation),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Enseigne),
        fieldName: "enseigne"
      },
      {
        header: () => t(ETLCodes.Email),
        fieldName: "email"
      },
      {
        header: () => t(ETLCodes.Telephone),
        fieldName: "telephone"
      },
      {
        header: () => t(ETLCodes.NumeroUe),
        fieldName: "numeroUe"
      }
    ],
    [onEditClick, onViewClick, t]
  );

  return (
    <FieldSet title={t(ETLCodes.TableResults, { count: totalCount })}>
      <DataTable
        dateFormat="dd/MM/yyyy"
        tableState={tableState}
        loading={loading}
        columns={columns}
        filterMode="OnEnter"
        customizeRowStyle={(row: ContactLieuFormationGridDto) => css`
          & * {
            color: ${!row.actif ? Colors.RED3 + " !important" : null};
          }
        `}
      ></DataTable>
    </FieldSet>
  );
};