import * as React from "react";
import { useHistory, useParams } from 'react-router';
import { SocieteExterneApi, SocieteExterneEditDtoFromJSON } from '../../../../../api';
import { ERoutes } from '../../../../../AppRouter';
import { useApiService, useCrudApi, useTl } from '../../../../../hooks';
import * as Yup from "yup";
import { ETLCodes } from '../../../../../locales';
import { useReferential } from '../../../../../hooks/useReferential';
import { SmallFormGenerator } from '../../../../../components';
import { FGMultiSuggestInput, FGTextInput, FieldGroup } from 'nsitools-react';

export interface ISocieteExterneDetailPageProps { }

export const SocieteExterneDetailPage: React.FunctionComponent<ISocieteExterneDetailPageProps> = props => {
  const { id: idSocieteExterne, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const { t } = useTl();
  const api = useApiService(SocieteExterneApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idSocieteExterne <= 0
        ? SocieteExterneEditDtoFromJSON({ idSocieteExterne: 0 })
        : api.societeExterneGetSocieteExterne({ id: +idSocieteExterne }),
    saveApiFn: d => api.societeExterneSaveSocieteExterne({ SocieteExterneEditDto: d }),
    onSavedRoute: d => `${ERoutes.societeExterne}/${d.idsocieteExterne}/detail/edit`,
    deleteApiFn: d => api.societeExterneDeleteSocieteExterne({ id: d.idsocieteExterne }),
    onDeletedRoute: () => ERoutes.societeExterne
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      nom: Yup.string().required(t(ETLCodes.Required)),
      idsMetier: Yup.array().of(Yup.number()).required(t(ETLCodes.Required))
    });
  }, [t]);

  const [metiers, mLoading] = useReferential(a => a.referentialGetMetier(), true, []);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={state === "edit"}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.societeExterne)}
      onDelete={deleteItem}
      showDeleteButton={+idSocieteExterne > 0}
      saving={saving}
      deleting={deleting}
    >
      <FieldGroup columns={2}>
        <FieldGroup>
          <FGTextInput name="nom" label={t(ETLCodes.Nom)} maxLength={250} />
          <FGMultiSuggestInput
            label={t(ETLCodes.Metiers)}
            name="idsMetier"
            items={metiers}
            loading={mLoading}
            isFilterable={true}
          />
        </FieldGroup>
      </FieldGroup>
    </SmallFormGenerator>
  );
};
