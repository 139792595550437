import * as React from "react";
import { Switch, Route } from "react-router";
import { ERoutes } from "../../../../../AppRouter";
import { ApprenantAbsenceListPage } from "./list/ApprenantAbsenceListPage";
interface IApprenantAbsenceProps {
  apprenantId: number;
}

export const ApprenantAbsence: React.FunctionComponent<IApprenantAbsenceProps> = () => {
  return (
    <Switch>
      <Route path={`${ERoutes.apprenant}/:id/:tab/:state?`} component={ApprenantAbsenceListPage} exact />
    </Switch>
  );
};
