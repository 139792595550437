import { Button, Classes, Dialog, Intent, ProgressBar } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import styled from "styled-components";
import { useTl } from "../hooks";
import { ETLCodes } from "../locales";

const DialogStyled = styled(Dialog)`
  width: 700px;
  background: white;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`;

export interface IProgressBarDialogProps {
  isOpen: boolean;
  title: string;
  currentValue: number;
  total: number;
  autoClose?: boolean;
  closeFromInside?: () => void;
}

export const ProgressBarDialog: React.FunctionComponent<IProgressBarDialogProps> = ({
  isOpen,
  title,
  currentValue = 0,
  total = 0,
  autoClose = true,
  closeFromInside = () => {}
}) => {
  const { t } = useTl();

  const effectiveValue = React.useMemo(() => currentValue / total, [currentValue, total]);
  const effectiveIndex = React.useMemo(() => (currentValue < total ? currentValue + 1 : total), [currentValue, total]);

  return (
    <DialogStyled isOpen={isOpen} title={title} isCloseButtonShown={false} enforceFocus={false}>
      <div className={Classes.DIALOG_BODY}>
        <TextContainer>
          <div>{`${t(ETLCodes.Apprenant)} ${effectiveIndex} ${t(ETLCodes.Sur)} ${total}`} </div>
          <div>{(effectiveValue * 100).toFixed(1)} %</div>
        </TextContainer>
        <ProgressBar
          intent={Intent.PRIMARY}
          value={effectiveValue}
          animate={currentValue < total}
          stripes={currentValue < total}
        />
      </div>
      {!autoClose && (
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              disabled={effectiveValue < 1}
              icon={IconNames.CROSS}
              minimal={true}
              text={t(ETLCodes.Close)}
              onClick={() => closeFromInside()}
            />
          </div>
        </div>
      )}
    </DialogStyled>
  );
};
