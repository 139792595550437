import { Intent } from "@blueprintjs/core";
import {
  BaseFilterCriteriaInfo,
  BaseSelectItem,
  DataTable,
  FieldSet,
  IDataTableColumn,
  IDataTableProps,
  IDefaultCriteriaValue,
  IFieldSetProps,
  ISearchCriteriaValues,
  SearchPanel,
  SortType,
  useGridState
} from "nsitools-react";
import * as React from "react";
import { useLocation } from "react-router";

import { AddButton, ICustomBreadcrumbProps, PageBase } from "..";
import { useEventsContext } from "../../contexts";
import { useSearchApi, useTl } from "../../hooks";
import { ETLCodes } from "../../locales";

interface ISearchTablePageProps<TResults>
  extends Pick<IDataTableProps<any>, "customizeRowStyle" | "onRowClick">,
    Pick<IFieldSetProps, "rightElement" | "buttons"> {
  searchFunction: (sObj: ISearchCriteriaValues) => TResults | Promise<TResults>;
  getCriteriasFunction: () => BaseFilterCriteriaInfo[] | Promise<BaseFilterCriteriaInfo[]>;
  addFunc?: () => void;
  columns: Array<IDataTableColumn>;
  breadCrumbs?: Array<ICustomBreadcrumbProps>;
  criteriasTlPrefix?: string;
  defaultCriterias?: Array<IDefaultCriteriaValue>;
  withCard?: boolean;
  overrideListValues?: {
    [key: string]: (e: BaseSelectItem) => string;
  };
  sortKeys?: {
    [key: string]: SortType;
  };
  title?: ETLCodes;
  pageSize?: number;
  availablePageSizes?: number[];
  avoidSavingCriterias?: boolean;
  initialSearch?: boolean;
  onOpenSubComponent?: (item: any) => string | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  keyFieldName?: string;
  renderNoData?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  searchStateInitialSearch?: boolean;
  noTitle?: boolean;
  forceLoading?: boolean;
  onAbort?: () => void;
  enableFilter?: boolean;
  totalCount?: (value: number) => void;
}

export function SearchTablePage<TResults>(props: ISearchTablePageProps<TResults>) {
  const {
    searchFunction,
    getCriteriasFunction,
    columns,
    breadCrumbs,
    withCard = true,
    criteriasTlPrefix,
    overrideListValues,
    sortKeys = {},
    defaultCriterias,
    rightElement,
    buttons,
    addFunc,
    customizeRowStyle,
    onRowClick,
    title,
    pageSize = 15,
    availablePageSizes = [15, 25, 50],
    avoidSavingCriterias = false,
    initialSearch = true,
    searchStateInitialSearch = true,
    onOpenSubComponent,
    keyFieldName,
    renderNoData,
    noTitle = false,
    forceLoading = false,
    onAbort,
    enableFilter = true,
    totalCount: sendTotalCount
  } = props;
  const { t, tUnsafe } = useTl();
  const currentSObj = React.useRef<ISearchCriteriaValues>();

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: enableFilter,
    availablePageSizes: availablePageSizes,
    pageSize: pageSize,
    sortKeys: sortKeys
  });

  const { totalCount } = tableState;

  React.useEffect(() => {
    if (!!sendTotalCount && totalCount >= 0) sendTotalCount(totalCount);
  }, [sendTotalCount, totalCount]);

  const [criteriasLoading, setCriteriasLoading] = React.useState(false);

  const location = useLocation();

  const triggerInitialSearch = React.useMemo(
    () => (initialSearch !== undefined ? initialSearch : !!!getCriteriasFunction),
    [getCriteriasFunction, initialSearch]
  );

  // const abortRef = React.useRef(0);
  // const innerSearchFunction = React.useCallback(
  //   async (sObj: ISearchCriteriaValues) => {
  //     if (!!abortController.current && abortRef.current > 0) {
  //       abortController.current.abort();
  //     }
  //     abortRef.current++;
  //     try {
  //       const res = await searchFunction(sObj);
  //       return res;
  //     } finally {
  //       abortRef.current--;
  //     }
  //   },
  //   [abortController, searchFunction]
  // );

  const { search, loading } = useSearchApi({
    searchFunction,
    tableState,
    initialSearch: searchStateInitialSearch && triggerInitialSearch,
    onAbort
  });

  const OnRefreshSearchTablesEvent = React.useCallback(() => {
    search(currentSObj.current);
  }, [search]);

  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();
  React.useEffect(() => {
    subscribeToEvent("SEARCH_TABLE_REFRESH", OnRefreshSearchTablesEvent);
    return () => unsubscribeEvent("SEARCH_TABLE_REFRESH", OnRefreshSearchTablesEvent);
  }, [OnRefreshSearchTablesEvent, subscribeToEvent, unsubscribeEvent]);

  const getCriterias = React.useCallback(async () => {
    if (!getCriteriasFunction) return null;
    setCriteriasLoading(true);
    const crit = await getCriteriasFunction();
    setCriteriasLoading(false);
    return crit;
  }, [getCriteriasFunction]);

  const searchPanel = React.useMemo(
    () => (
      <SearchPanel
        getCriteriasFunc={getCriterias}
        defaultCriterias={defaultCriterias}
        onSearch={(val: ISearchCriteriaValues) => {
          currentSObj.current = val;
          search(val);
        }}
        enableOrOnSameCriteria={false}
        translateFunc={tUnsafe}
        tlDataPrefix={criteriasTlPrefix}
        overrideListValues={overrideListValues}
        criteriaIntent="primary"
        storageMode={avoidSavingCriterias ? "none" : "sessionStorage"}
        storageKey={"search_" + location.pathname}
        dateFormat="dd-MM-yyyy"
        triggerInitialSearch={triggerInitialSearch}
        clearSelectQueryOnClose
      />
    ),
    [
      avoidSavingCriterias,
      criteriasTlPrefix,
      defaultCriterias,
      getCriterias,
      location.pathname,
      overrideListValues,
      search,
      tUnsafe,
      triggerInitialSearch
    ]
  );

  return (
    <PageBase withCard={withCard} breadCrumbs={breadCrumbs}>
      {getCriterias &&
        (noTitle ? (
          searchPanel
        ) : (
          <FieldSet title={title ? t(title) : t(ETLCodes.General_TableCriterias)}>{searchPanel}</FieldSet>
        ))}
      <FieldSet
        title={t(ETLCodes.TableResults, { count: totalCount })}
        rightElement={
          rightElement
            ? rightElement
            : addFunc && (
                <AddButton
                  onClick={e => {
                    e.stopPropagation();
                    addFunc();
                  }}
                  text={t(ETLCodes.General_Add)}
                  intent={Intent.PRIMARY}
                />
              )
        }
        buttons={buttons}
      >
        <DataTable
          dateFormat="dd-MM-yyyy"
          tableState={tableState}
          loading={loading || criteriasLoading || forceLoading}
          columns={columns}
          customizeRowStyle={customizeRowStyle}
          onRowClick={onRowClick}
          filterMode="OnBlurOrEnter"
          onOpenSubComponent={onOpenSubComponent}
          loadingMessage={t(ETLCodes.GeneralLoading)}
          keyFieldName={keyFieldName}
          renderNoData={renderNoData}
        />
      </FieldSet>
    </PageBase>
  );
}
