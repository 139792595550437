import { Callout } from "@blueprintjs/core";
import { format, isAfter } from "date-fns";
import { FieldSet, showError } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { AP_FINAL_KEY, CarouselComparer, DoublonsMultipleDataCard, useDoublonsStateContext } from "..";
import {
  ApprenantDoublonEditDto,
  ApprenantFichierApi,
  ApprenantFichierGridDto,
  ETypeApprenantFichier
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BooleanColumn, LinkButton, ViewButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { exportFile } from "../../../../../utils";

const Container = styled.div`
  height: 70vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface IDoublonApprenantDocumentsProps {}

export const DoublonApprenantDocuments: React.FunctionComponent<IDoublonApprenantDocumentsProps> = props => {
  const { t, tUnsafe } = useTl();
  const { data, getSelectedIndex } = useDoublonsStateContext();

  const selectedIndex = React.useMemo(() => getSelectedIndex(`${AP_FINAL_KEY}.idapprenant`) ?? 0, [getSelectedIndex]);

  const api = useApiService(ApprenantFichierApi);
  const downloadFichier = React.useCallback(
    async (idapprenantFichier: number) => {
      try {
        const file = await api.apprenantFichierDownloadApprenantFichier({ id: idapprenantFichier });
        await exportFile(file);
      } catch {
        showError(t(ETLCodes.ErrorWhileDownloadingFile));
      }
    },
    [api, t]
  );

  const apprenantContainer = React.useCallback(
    (index: number, nb: number) => {
      const apprenant: ApprenantDoublonEditDto = data?.apprenants[index];
      if (!apprenant) return;

      const isMostRecent = (dossierScolaire: ApprenantFichierGridDto) =>
        !!dossierScolaire?.uploadDate &&
        data?.apprenants
          ?.filter(a => a.idapprenant !== apprenant.idapprenant)
          ?.every(
            a =>
              a.documentsReglementaires.every(
                d => d.typeApprenantFichierCode !== ETypeApprenantFichier.DOSSIER_SCOLAIRE || !d.uploadDate
              ) ||
              isAfter(
                dossierScolaire.uploadDate,
                a.documentsReglementaires.find(
                  d => d.typeApprenantFichierCode === ETypeApprenantFichier.DOSSIER_SCOLAIRE
                )?.uploadDate
              )
          );

      return (
        <FieldSet
          key={apprenant.idapprenant}
          title={
            <ViewButton
              text={t(ETLCodes.ApprenantNb, { nb: nb, id: apprenant.idapprenant })}
              onClick={() => window.open(`#${ERoutes.apprenant}/${apprenant.idapprenant}/signaletique/view`, "_blank")}
            />
          }
          titlePosition="center"
        >
          <DoublonsMultipleDataCard
            labelPredicate={(r: ApprenantFichierGridDto) =>
              r.typeApprenantFichier ?? tUnsafe(`TypeApprenantFichier_${r.typeApprenantFichierCode}`)
            }
            fieldPath={`apprenants[${index}].documentsReglementaires`}
            interactive={() => false}
            finalEntityKey={AP_FINAL_KEY}
            forceSelected={(r: ApprenantFichierGridDto) =>
              (r.typeApprenantFichierCode !== ETypeApprenantFichier.DOSSIER_SCOLAIRE && index === 0) || isMostRecent(r)
            }
          >
            {(r: ApprenantFichierGridDto) => (
              <InformationContainer key={r.idapprenantFichier}>
                <div>
                  {t(ETLCodes.Remis)}: <BooleanColumn value={r.remis} />
                </div>
                {r.remis && (
                  <div>
                    {t(ETLCodes.Fichier)}:{" "}
                    {!!r.idfichier ? (
                      <LinkButton
                        text={r.fileName}
                        onClick={() => downloadFichier(r.idapprenantFichier)}
                        rightIcon="download"
                      />
                    ) : (
                      t(ETLCodes.DocumentPhysique)
                    )}
                  </div>
                )}
                {!!r.uploadDate && (
                  <div>
                    {t(ETLCodes.UploadDate)}: {format(r.uploadDate, "dd-MM-yyyy")}
                  </div>
                )}
                {r.remis && (
                  <div>
                    {t(ETLCodes.Annee)}: {r.anneeScolaire}
                  </div>
                )}
                {!!r.creationUserName && (
                  <div>
                    {t(ETLCodes.UtilisateurUpload)}: {r.creationUserName}
                  </div>
                )}
              </InformationContainer>
            )}
          </DoublonsMultipleDataCard>
        </FieldSet>
      );
    },
    [data?.apprenants, downloadFichier, t, tUnsafe]
  );

  return (
    <Container>
      <Callout
        intent="warning"
        title={t(ETLCodes.DoublonsDossierScolairePlusRecentChoisi)}
        style={{ marginBottom: "1rem" }}
        icon="info-sign"
      >
        {t(ETLCodes.DoublonApprenantDocumentsCumules)}
      </Callout>
      <CarouselComparer leftElement={apprenantContainer(selectedIndex, selectedIndex + 1)}>
        {data?.apprenants?.length > 1 &&
          data?.apprenants
            ?.filter((a, i) => i !== selectedIndex)
            ?.map((a, i) => apprenantContainer(i + (i >= selectedIndex ? 1 : 0), i + (i >= selectedIndex ? 2 : 1)))}
      </CarouselComparer>
    </Container>
  );
};
