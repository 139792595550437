import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  ButtonContainer,
  ButtonsBloc,
  DataTable,
  FGCustomPanel,
  FGMaskInput,
  FGTextInput,
  FieldGroup,
  FieldSet,
  InlineButtonContainer,
  useGridState,
  useSearchApi
} from "nsitools-react";
import * as React from "react";
import { useHistory, useLocation } from "react-router";

import {
  FcbRepresentantDoublonGridDto,
  RepresentantDoublonApi,
  RepresentantDoublonSearch,
  RepresentantDoublonSearchFromJSON
} from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import {
  AddButton,
  CancelButton,
  EditButton,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  SmallFormGenerator,
  UnordoredListColumn,
  ViewButton
} from "../../../../components";
import { useBeIdCardContext, useDialog } from "../../../../contexts";
import { useApiService, useTabMessage, useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";
import { ETLCodes } from "../../../../locales";

export interface IRepresentantDetailDoublonsProps {
  setBaseData: React.Dispatch<React.SetStateAction<RepresentantDoublonSearch>>;
}

export const RepresentantDetailDoublons: React.FunctionComponent<IRepresentantDetailDoublonsProps> = ({
  setBaseData
}) => {
  const { t } = useTl();
  const dialogContext = useDialog();
  const history = useHistory();
  const { search: searchQ } = useLocation();
  const [nationalites, nLoading] = useReferential(a => a.referentialGetAllNationalite(), false, []);
  const api = useApiService(RepresentantDoublonApi);
  const { foundRowsData } = useBeIdCardContext();
  const cfs = React.useMemo(() => new URLSearchParams(searchQ).get("cfs"), [searchQ]);
  const { messageValue: baseSObj } = useTabMessage(cfs);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [10],
    pageSize: 10,
    sortKeys: { nom: "ASC" }
  });

  const initialData = React.useMemo(() => {
    const onlyRegNatNb =
      !foundRowsData ||
      (!foundRowsData.nom && !foundRowsData.prenom && !foundRowsData.dateNaissance && foundRowsData.nationalNb);
    return RepresentantDoublonSearchFromJSON({
      nom: baseSObj?.nom?.value ?? foundRowsData?.nom,
      prenom: baseSObj?.prenom?.value ?? foundRowsData?.prenom,
      registreNational: baseSObj?.registreNational?.value ?? (!onlyRegNatNb ? foundRowsData?.nationalNb : undefined),
      dateNaissance: baseSObj?.dateNaissance?.value ?? foundRowsData?.dateNaissance,
      numeroNational: baseSObj?.numeroNational?.value,
      nationalite: baseSObj?.nationalite?.value,
      forceSkip: 0,
      forceTake: 10
    });
  }, [
    baseSObj?.dateNaissance?.value,
    baseSObj?.nationalite?.value,
    baseSObj?.nom?.value,
    baseSObj?.numeroNational?.value,
    baseSObj?.prenom?.value,
    baseSObj?.registreNational?.value,
    foundRowsData
  ]);

  const { totalCount } = tableState;
  const searchFunction = React.useCallback(
    (sObj?: RepresentantDoublonSearch) => {
      return api.representantDoublonBaseSearch({ RepresentantDoublonSearch: sObj });
    },
    [api]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: false
  });

  React.useEffect(() => {
    if (foundRowsData || baseSObj) {
      search(initialData);
    }
  }, [baseSObj, foundRowsData, initialData, search]);
  const add = React.useCallback(() => {
    if (totalCount && totalCount > 0) {
      dialogContext.showDialog({
        message: t(ETLCodes.AddConfirmMessage),
        onConfirmed: async () => {
          history.push(`${ERoutes.representant}/0/detail/edit${searchQ}`);
        }
      });
    } else {
      history.push(`${ERoutes.representant}/0/detail/edit${searchQ}`);
    }
  }, [dialogContext, history, searchQ, t, totalCount]);
  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbRepresentantDoublonGridDto) => (
          <ButtonContainer>
            {row.isRepresentant ? (
              <>
                <ViewButton
                  minimal={true}
                  onClick={() => history.push(`${ERoutes.representant}/${row.idrepresentant}/detail/view`)}
                />
                <EditButton
                  minimal={true}
                  onClick={() => history.push(`${ERoutes.representant}/${row.idrepresentant}/detail/edit`)}
                />
              </>
            ) : (
              <AddButton
                tooltipText={t(ETLCodes.CreerAPartirdePersonne)}
                onClick={() => {
                  setBaseData({ ...row });
                  add();
                }}
              />
            )}
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.DateNaissance),
        fieldName: "dateNaissance"
      },
      {
        header: () => t(ETLCodes.NumeroNational),
        fieldName: "registreNational"
      },
      {
        header: () => t(ETLCodes.Adresse),
        fieldName: "adresse"
      },
      {
        header: () => t(ETLCodes.CodePostal),
        fieldName: "codePostal"
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      },
      {
        header: () => t(ETLCodes.Nationalite),
        fieldName: "nationalite"
      }
      // {
      //   header: () => t(ETLCodes.Forme),
      //   fieldName: "forme",
      //   render: (row: FcbRepresentantDoublonGridDto) => (
      //     <UnordoredListColumn
      //       values={[
      //         { value: row.isApprenant, label: t(ETLCodes.Apprenant) },
      //         { value: row.isFormateur, label: t(ETLCodes.Formateur) },
      //         { value: row.isRepresentant, label: t(ETLCodes.Representant) },
      //         { value: row.isTuteur, label: t(ETLCodes.Tuteur) },
      //         { value: row.isChefEntreprise, label: t(ETLCodes.ChefEntreprise) },
      //         { value: row.isCollaborateur, label: t(ETLCodes.Collaborateur) },
      //         { value: row.isDelegueTutelle, label: t(ETLCodes.Referent) },
      //         { value: row.isConseillePedagogique, label: t(ETLCodes.ConseillerPedagogique) }
      //       ]}
      //     />
      //   )
      // }
    ],
    [add, history, setBaseData, t]
  );
  return (
    <FieldSet collapsable={false} title={t(ETLCodes.Representants)}>
      <SmallFormGenerator
        enableDirtyCheck={false}
        initialValues={initialData}
        onSubmit={c => search(c)}
        editMode
        hideButtons={true}
        onCancel={() => history.push(`${ERoutes.representant}`)}
      >
        <FieldGroup columns={2}>
          <FGTextInput name="nom" label={t(ETLCodes.Nom)} maxLength={50} forceCase="upper" />
          <FGWalterSelectInput
            name="nationalite"
            label={t(ETLCodes.Nationalite)}
            items={nationalites}
            loading={nLoading}
          />
          <FGTextInput name="prenom" label={t(ETLCodes.Prenom)} maxLength={50} />
          <FGMaskInput
            name="numeroNational"
            label={t(ETLCodes.NumeroNational)}
            cleaveOptions={{ delimiters: [".", ".", "-", "."], blocks: [2, 2, 2, 3, 2], numericOnly: true }}
          />
          <FGWalterDateMaskInput name="dateNaissance" label={t(ETLCodes.DateNaissance)} />
        </FieldGroup>
        <FieldGroup>
          <FGCustomPanel>
            {ctx => (
              <InlineButtonContainer>
                <ButtonsBloc></ButtonsBloc>
                <ButtonsBloc>
                  <CancelButton minimal={false} onClick={() => history.push(`${ERoutes.representant}`)} />
                  <Button icon={IconNames.SEARCH} type="submit" text={t(ETLCodes.Search)} intent={Intent.PRIMARY} />
                  <AddButton
                    onClick={() => {
                      setBaseData(ctx.formik.values);
                      add();
                    }}
                    text={t(ETLCodes.General_Add)}
                    intent={Intent.PRIMARY}
                    minimal={false}
                  />
                </ButtonsBloc>
              </InlineButtonContainer>
            )}
          </FGCustomPanel>
        </FieldGroup>
        <FGCustomPanel>
          {ctx => (
            <FieldSet title={t(ETLCodes.TableResults, { count: totalCount })}>
              <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
            </FieldSet>
          )}
        </FGCustomPanel>
      </SmallFormGenerator>
    </FieldSet>
  );
};
