/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TelephoneReplaceDto
 */
export interface TelephoneReplaceDto {
    /**
     * 
     * @type {number}
     * @memberof TelephoneReplaceDto
     */
    oldIdtelephone?: number;
    /**
     * 
     * @type {number}
     * @memberof TelephoneReplaceDto
     */
    newIdtelephone?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TelephoneReplaceDto
     */
    numero?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TelephoneReplaceDto
     */
    idtypeTelephone?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TelephoneReplaceDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TelephoneReplaceDto
     */
    isNew?: boolean;
}

/**
 * Check if a given object implements the TelephoneReplaceDto interface.
 */
export function instanceOfTelephoneReplaceDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TelephoneReplaceDtoFromJSON(json: any): TelephoneReplaceDto {
    return TelephoneReplaceDtoFromJSONTyped(json, false);
}

export function TelephoneReplaceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelephoneReplaceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'oldIdtelephone': !exists(json, 'oldIdtelephone') ? undefined : json['oldIdtelephone'],
        'newIdtelephone': !exists(json, 'newIdtelephone') ? undefined : json['newIdtelephone'],
        'numero': !exists(json, 'numero') ? undefined : json['numero'],
        'idtypeTelephone': !exists(json, 'idtypeTelephone') ? undefined : json['idtypeTelephone'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'isNew': !exists(json, 'isNew') ? undefined : json['isNew'],
    };
}

export function TelephoneReplaceDtoToJSON(value?: TelephoneReplaceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'oldIdtelephone': value.oldIdtelephone,
        'newIdtelephone': value.newIdtelephone,
        'numero': value.numero,
        'idtypeTelephone': value.idtypeTelephone,
        'idpersonne': value.idpersonne,
        'isNew': value.isNew,
    };
}

