/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImpersonateUserInfoDto } from './ImpersonateUserInfoDto';
import {
    ImpersonateUserInfoDtoFromJSON,
    ImpersonateUserInfoDtoFromJSONTyped,
    ImpersonateUserInfoDtoToJSON,
} from './ImpersonateUserInfoDto';
import type { UserActionDto } from './UserActionDto';
import {
    UserActionDtoFromJSON,
    UserActionDtoFromJSONTyped,
    UserActionDtoToJSON,
} from './UserActionDto';

/**
 * 
 * @export
 * @interface UserProfileDto
 */
export interface UserProfileDto {
    /**
     * 
     * @type {number}
     * @memberof UserProfileDto
     */
    iduser?: number;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    login?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    azureEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserProfileDto
     */
    idlevel?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    ifapmeSide?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    hopeMainLevelName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileDto
     */
    delegationSignature?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileDto
     */
    isAdmin?: boolean | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserProfileDto
     */
    idsSocieteExterne?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserProfileDto
     */
    idscentre?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileDto
     */
    showStartupPopup?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    token?: string | null;
    /**
     * 
     * @type {Array<UserActionDto>}
     * @memberof UserProfileDto
     */
    actions?: Array<UserActionDto> | null;
    /**
     * 
     * @type {ImpersonateUserInfoDto}
     * @memberof UserProfileDto
     */
    impersonator?: ImpersonateUserInfoDto;
}

/**
 * Check if a given object implements the UserProfileDto interface.
 */
export function instanceOfUserProfileDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserProfileDtoFromJSON(json: any): UserProfileDto {
    return UserProfileDtoFromJSONTyped(json, false);
}

export function UserProfileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iduser': !exists(json, 'iduser') ? undefined : json['iduser'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'login': !exists(json, 'login') ? undefined : json['login'],
        'azureEmail': !exists(json, 'azureEmail') ? undefined : json['azureEmail'],
        'idlevel': !exists(json, 'idlevel') ? undefined : json['idlevel'],
        'ifapmeSide': !exists(json, 'ifapmeSide') ? undefined : json['ifapmeSide'],
        'hopeMainLevelName': !exists(json, 'hopeMainLevelName') ? undefined : json['hopeMainLevelName'],
        'delegationSignature': !exists(json, 'delegationSignature') ? undefined : json['delegationSignature'],
        'isAdmin': !exists(json, 'isAdmin') ? undefined : json['isAdmin'],
        'idsSocieteExterne': !exists(json, 'idsSocieteExterne') ? undefined : json['idsSocieteExterne'],
        'idscentre': !exists(json, 'idscentre') ? undefined : json['idscentre'],
        'showStartupPopup': !exists(json, 'showStartupPopup') ? undefined : json['showStartupPopup'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'actions': !exists(json, 'actions') ? undefined : (json['actions'] === null ? null : (json['actions'] as Array<any>).map(UserActionDtoFromJSON)),
        'impersonator': !exists(json, 'impersonator') ? undefined : ImpersonateUserInfoDtoFromJSON(json['impersonator']),
    };
}

export function UserProfileDtoToJSON(value?: UserProfileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iduser': value.iduser,
        'nom': value.nom,
        'prenom': value.prenom,
        'login': value.login,
        'azureEmail': value.azureEmail,
        'idlevel': value.idlevel,
        'ifapmeSide': value.ifapmeSide,
        'hopeMainLevelName': value.hopeMainLevelName,
        'delegationSignature': value.delegationSignature,
        'isAdmin': value.isAdmin,
        'idsSocieteExterne': value.idsSocieteExterne,
        'idscentre': value.idscentre,
        'showStartupPopup': value.showStartupPopup,
        'token': value.token,
        'actions': value.actions === undefined ? undefined : (value.actions === null ? null : (value.actions as Array<any>).map(UserActionDtoToJSON)),
        'impersonator': ImpersonateUserInfoDtoToJSON(value.impersonator),
    };
}

