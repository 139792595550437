import { saveAs } from "file-saver";
import { FileDownloadDto } from "../api";
export function exportFile(file: FileDownloadDto) {
  const blob: Blob = b64toBlob(file);
  saveAs(blob, file.fileName);
}

export function b64toBlob(file: FileDownloadDto, sliceSize = 512) {
  const b64Data: string = file.base64Content;
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays);
  return blob;
}
