/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbImpressionDto
 */
export interface FcbImpressionDto {
    /**
     * 
     * @type {number}
     * @memberof FcbImpressionDto
     */
    idimpression?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbImpressionDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbImpressionDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbImpressionDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbImpressionDto
     */
    rapport?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbImpressionDto
     */
    isamsterrapport?: boolean | null;
}

/**
 * Check if a given object implements the FcbImpressionDto interface.
 */
export function instanceOfFcbImpressionDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbImpressionDtoFromJSON(json: any): FcbImpressionDto {
    return FcbImpressionDtoFromJSONTyped(json, false);
}

export function FcbImpressionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbImpressionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idimpression': !exists(json, 'idimpression') ? undefined : json['idimpression'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'rapport': !exists(json, 'rapport') ? undefined : json['rapport'],
        'isamsterrapport': !exists(json, 'isamsterrapport') ? undefined : json['isamsterrapport'],
    };
}

export function FcbImpressionDtoToJSON(value?: FcbImpressionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idimpression': value.idimpression,
        'nom': value.nom,
        'description': value.description,
        'actif': value.actif,
        'rapport': value.rapport,
        'isamsterrapport': value.isamsterrapport,
    };
}

