/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EStatutContrat } from './EStatutContrat';
import {
    EStatutContratFromJSON,
    EStatutContratFromJSONTyped,
    EStatutContratToJSON,
} from './EStatutContrat';

/**
 * 
 * @export
 * @interface FcbRechercheApprenantViewDto
 */
export interface FcbRechercheApprenantViewDto {
    /**
     * 
     * @type {Date}
     * @memberof FcbRechercheApprenantViewDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRechercheApprenantViewDto
     */
    dateDeces?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantViewDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantViewDto
     */
    azureEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantViewDto
     */
    fax?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantViewDto
     */
    gsm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantViewDto
     */
    localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantViewDto
     */
    localiteNaissance?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheApprenantViewDto
     */
    idapprenant?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantViewDto
     */
    interne?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantViewDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantViewDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantViewDto
     */
    nomSa?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantViewDto
     */
    prenomSa?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantViewDto
     */
    telephone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantViewDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbRechercheApprenantViewDto
     */
    isDeceased?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheApprenantViewDto
     */
    registreNational?: string | null;
    /**
     * 
     * @type {EStatutContrat}
     * @memberof FcbRechercheApprenantViewDto
     */
    statutContrat?: EStatutContrat;
    /**
     * 
     * @type {boolean}
     * @memberof FcbRechercheApprenantViewDto
     */
    encours?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbRechercheApprenantViewDto
     */
    enattente?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbRechercheApprenantViewDto
     */
    readonly distinctsInscriptions?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbRechercheApprenantViewDto
     */
    readonly distinctsClasses?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbRechercheApprenantViewDto
     */
    isProspect?: boolean;
}

/**
 * Check if a given object implements the FcbRechercheApprenantViewDto interface.
 */
export function instanceOfFcbRechercheApprenantViewDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRechercheApprenantViewDtoFromJSON(json: any): FcbRechercheApprenantViewDto {
    return FcbRechercheApprenantViewDtoFromJSONTyped(json, false);
}

export function FcbRechercheApprenantViewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRechercheApprenantViewDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'dateDeces': !exists(json, 'dateDeces') ? undefined : (json['dateDeces'] === null ? null : new Date(json['dateDeces'])),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'azureEmail': !exists(json, 'azureEmail') ? undefined : json['azureEmail'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'gsm': !exists(json, 'gsm') ? undefined : json['gsm'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'localiteNaissance': !exists(json, 'localiteNaissance') ? undefined : json['localiteNaissance'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'interne': !exists(json, 'interne') ? undefined : json['interne'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'nomSa': !exists(json, 'nomSa') ? undefined : json['nomSa'],
        'prenomSa': !exists(json, 'prenomSa') ? undefined : json['prenomSa'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'isDeceased': !exists(json, 'isDeceased') ? undefined : json['isDeceased'],
        'registreNational': !exists(json, 'registreNational') ? undefined : json['registreNational'],
        'statutContrat': !exists(json, 'statutContrat') ? undefined : EStatutContratFromJSON(json['statutContrat']),
        'encours': !exists(json, 'encours') ? undefined : json['encours'],
        'enattente': !exists(json, 'enattente') ? undefined : json['enattente'],
        'distinctsInscriptions': !exists(json, 'distinctsInscriptions') ? undefined : json['distinctsInscriptions'],
        'distinctsClasses': !exists(json, 'distinctsClasses') ? undefined : json['distinctsClasses'],
        'isProspect': !exists(json, 'isProspect') ? undefined : json['isProspect'],
    };
}

export function FcbRechercheApprenantViewDtoToJSON(value?: FcbRechercheApprenantViewDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'dateDeces': value.dateDeces === undefined ? undefined : (value.dateDeces === null ? null : value.dateDeces.toISOString()),
        'email': value.email,
        'azureEmail': value.azureEmail,
        'fax': value.fax,
        'gsm': value.gsm,
        'localite': value.localite,
        'localiteNaissance': value.localiteNaissance,
        'idapprenant': value.idapprenant,
        'interne': value.interne,
        'nom': value.nom,
        'prenom': value.prenom,
        'nomSa': value.nomSa,
        'prenomSa': value.prenomSa,
        'telephone': value.telephone,
        'anneeScolaire': value.anneeScolaire,
        'isDeceased': value.isDeceased,
        'registreNational': value.registreNational,
        'statutContrat': EStatutContratToJSON(value.statutContrat),
        'encours': value.encours,
        'enattente': value.enattente,
        'isProspect': value.isProspect,
    };
}

