/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CentreUserDto
 */
export interface CentreUserDto {
    /**
     * 
     * @type {number}
     * @memberof CentreUserDto
     */
    idcentre?: number;
    /**
     * 
     * @type {string}
     * @memberof CentreUserDto
     */
    centre?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CentreUserDto
     */
    iduser?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CentreUserDto
     */
    presenceApp?: boolean;
}

/**
 * Check if a given object implements the CentreUserDto interface.
 */
export function instanceOfCentreUserDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CentreUserDtoFromJSON(json: any): CentreUserDto {
    return CentreUserDtoFromJSONTyped(json, false);
}

export function CentreUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CentreUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'centre': !exists(json, 'centre') ? undefined : json['centre'],
        'iduser': !exists(json, 'iduser') ? undefined : json['iduser'],
        'presenceApp': !exists(json, 'presenceApp') ? undefined : json['presenceApp'],
    };
}

export function CentreUserDtoToJSON(value?: CentreUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcentre': value.idcentre,
        'centre': value.centre,
        'iduser': value.iduser,
        'presenceApp': value.presenceApp,
    };
}

