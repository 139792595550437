import { IOptionProps } from "@blueprintjs/core";
import * as React from "react";
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from "react-query";

import { useApiService } from ".";
import { ReferentialApi, ReferentialItemDto, ReferentialResultInfoDto } from "../api";
import { useEventsContext } from "../contexts";
import { Guid } from "../utils/guid";

export const useReferential = (
  func: (api: ReferentialApi) => Promise<ReferentialResultInfoDto>,
  asInt?: boolean,
  dependencies?: any[],
  nullable?: boolean,
  customApiKey?: string
): [
  IOptionProps[],
  boolean,
  <TPageData>(options?: RefetchOptions & RefetchQueryFilters<TPageData>) => Promise<QueryObserverResult<any, unknown>>,
  ReferentialItemDto[]
] => {
  const api = useApiService(ReferentialApi);
  const apiKey = React.useMemo(() => customApiKey ?? `ref-${Guid.newGuid()}`, [customApiKey]);
  const { data, isFetching, refetch } = useQuery(dependencies ? [apiKey, dependencies] : apiKey, async () => {
    return func(api);
  });

  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();
  React.useEffect(() => {
    subscribeToEvent("REFERENTIAL_REFRESH", refetch);
    return () => {
      unsubscribeEvent("REFERENTIAL_REFRESH", refetch);
    };
  }, [refetch, subscribeToEvent, unsubscribeEvent]);

  const newData = React.useMemo(() => {
    if (data) {
      return data.list?.map(p => {
        return {
          value: p.idValue ? (asInt ? +p.idValue : p.idValue) : null,
          label: p.displayValue,
          disabled: p.disabled ?? false
        };
      }) as IOptionProps[];
    }
  }, [asInt, data]);

  const finalData = React.useMemo(() => {
    // if (newData && nullable) {
    //   newData.unshift({ value: undefined, label: "" } as IOptionProps);
    // }
    return newData;
  }, [newData]);

  const rawData = React.useMemo(() => data?.list, [data]);

  return [finalData, isFetching, refetch, rawData];
};

