/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FilterCriteriaInfo,
  ReferentialItemDto,
  TypeBulletinEditDto,
  TypeBulletinGridDtoPaginatedResults,
  TypeBulletinSearchDto,
} from '../models/index';
import {
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
    TypeBulletinEditDtoFromJSON,
    TypeBulletinEditDtoToJSON,
    TypeBulletinGridDtoPaginatedResultsFromJSON,
    TypeBulletinGridDtoPaginatedResultsToJSON,
    TypeBulletinSearchDtoFromJSON,
    TypeBulletinSearchDtoToJSON,
} from '../models/index';

export interface TypeBulletinBaseSearchRequest {
    TypeBulletinSearchDto?: TypeBulletinSearchDto;
}

export interface TypeBulletinDeleteTypeBulletinRequest {
    id?: number;
}

export interface TypeBulletinGetDisplayNameRequest {
    id?: number;
}

export interface TypeBulletinGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface TypeBulletinGetSelectItemsRequest {
    searchField: string;
}

export interface TypeBulletinGetTypeBulletinRequest {
    id?: number;
}

export interface TypeBulletinSaveTypeBulletinRequest {
    TypeBulletinEditDto?: TypeBulletinEditDto;
}

/**
 * 
 */
export class TypeBulletinApi extends runtime.BaseAPI {

    /**
     */
    async typeBulletinBaseSearchRaw(requestParameters: TypeBulletinBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TypeBulletinGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeBulletin/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TypeBulletinSearchDtoToJSON(requestParameters.TypeBulletinSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeBulletinGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async typeBulletinBaseSearch(requestParameters: TypeBulletinBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TypeBulletinGridDtoPaginatedResults> {
        const response = await this.typeBulletinBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeBulletinDeleteTypeBulletinRaw(requestParameters: TypeBulletinDeleteTypeBulletinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeBulletin`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async typeBulletinDeleteTypeBulletin(requestParameters: TypeBulletinDeleteTypeBulletinRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.typeBulletinDeleteTypeBulletinRaw(requestParameters, initOverrides);
    }

    /**
     */
    async typeBulletinGetDisplayNameRaw(requestParameters: TypeBulletinGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeBulletin/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async typeBulletinGetDisplayName(requestParameters: TypeBulletinGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.typeBulletinGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeBulletinGetSearchCriteriasRaw(requestParameters: TypeBulletinGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeBulletin/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async typeBulletinGetSearchCriterias(requestParameters: TypeBulletinGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.typeBulletinGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeBulletinGetSelectItemsRaw(requestParameters: TypeBulletinGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling typeBulletinGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeBulletin/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async typeBulletinGetSelectItems(requestParameters: TypeBulletinGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.typeBulletinGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeBulletinGetTypeBulletinRaw(requestParameters: TypeBulletinGetTypeBulletinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TypeBulletinEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeBulletin`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeBulletinEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async typeBulletinGetTypeBulletin(requestParameters: TypeBulletinGetTypeBulletinRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TypeBulletinEditDto> {
        const response = await this.typeBulletinGetTypeBulletinRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeBulletinSaveTypeBulletinRaw(requestParameters: TypeBulletinSaveTypeBulletinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TypeBulletinEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeBulletin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TypeBulletinEditDtoToJSON(requestParameters.TypeBulletinEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TypeBulletinEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async typeBulletinSaveTypeBulletin(requestParameters: TypeBulletinSaveTypeBulletinRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TypeBulletinEditDto> {
        const response = await this.typeBulletinSaveTypeBulletinRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
