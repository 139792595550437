import { Button, Intent } from "@blueprintjs/core";
import { ButtonContainer, showError } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { WarningText } from "..";

import { ContratApi, EIfapmeSide } from "../../api";
import { useDialog, useEventsContext } from "../../contexts";
import { useApiService, useTheme, useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import { SortieReseauDialog } from "./SortieReseauDialog";

export interface ISortieReseauButtonProps {
  idApprenant: number;
  dateStatut: Date;
  idCategorieStatut?: number;
  idParcoursFormation?: number;
  idStatut?: number;
  iddureeInoccupation?: number;
  onClose?: () => void;
  loading?: boolean;
  withConfirm?: boolean;
  isSortieEnCours: boolean;
}

export const SortieReseauButton: React.FunctionComponent<ISortieReseauButtonProps> = ({
  idApprenant,
  dateStatut,
  idCategorieStatut,
  idParcoursFormation,
  idStatut,
  onClose = () => {},
  loading,
  withConfirm = false,
  isSortieEnCours = false,
  iddureeInoccupation
}) => {
  const { t } = useTl();
  const { showDialogPromise } = useDialog();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const { ifapmeSide } = useTheme();

  const api = useApiService(ContratApi);
  const fetchCanSortirApprenant = React.useCallback(async () => {
    return await api.contratCanSortirApprenant({
      idApprenant,
      ifapmeSide: ifapmeSide === "hope" ? EIfapmeSide.Hope : EIfapmeSide.Walter
    });
  }, [api, idApprenant, ifapmeSide]);
  const { data: canSortirApprenant, refetch } = useQuery(
    ["apprenant-can-sortir", idApprenant],
    fetchCanSortirApprenant
  );

  const fetchCanDialogAlert = React.useCallback(async () => {
    return await api.contratCanSortirApprenant({
      idApprenant,
      ifapmeSide: ifapmeSide === "hope" ? EIfapmeSide.Walter : EIfapmeSide.Hope
    });
  }, [api, idApprenant, ifapmeSide]);
  const { data: canDialogAlert, refetch: refetchCanDialogAlert } = useQuery(
    ["apprenant-can-dialog-alert", idApprenant],
    fetchCanDialogAlert
  );

  const refresh = React.useCallback(() => {
    refetch();
    refetchCanDialogAlert();
  }, [refetch, refetchCanDialogAlert]);

  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();
  React.useEffect(() => {
    subscribeToEvent("REFRESH_APPRENANT_SORTIR", refresh);
    return () => {
      unsubscribeEvent("REFRESH_APPRENANT_SORTIR", refresh);
    };
  }, [refresh, subscribeToEvent, unsubscribeEvent]);

  const onClickFunc = React.useCallback(async () => {
    if (!canSortirApprenant?.value) {
      showError(t(ifapmeSide === "hope" ? ETLCodes.SortieImpossible : ETLCodes.SortieImpossibleWalter));
      return;
    }
    if (withConfirm) {
      const result = await showDialogPromise({
        message: t(
          canDialogAlert?.value ? ETLCodes.SimpleSortieReseauConfirmMessage : ETLCodes.SortieReseauConfirmMessage
        ),
        title: t(ETLCodes.SortieReseauConfirm)
      });

      if (result === "yes") {
        setIsDialogOpen(true);
      }
    } else {
      setIsDialogOpen(true);
    }
  }, [canDialogAlert?.value, canSortirApprenant?.value, ifapmeSide, showDialogPromise, t, withConfirm]);

  return (
    <>
      <ButtonContainer>
        {!isSortieEnCours ? (
          <Button
            intent={Intent.PRIMARY}
            text={t(ETLCodes.SortieReseau)}
            onClick={() => onClickFunc()}
            disabled={loading}
            icon="log-out"
          />
        ) : (
          <WarningText text={t(ETLCodes.SortieEnCours)} />
        )}
      </ButtonContainer>

      <SortieReseauDialog
        idApprenant={idApprenant}
        dateStatut={dateStatut}
        idCategorieStatut={idCategorieStatut}
        iddureeInoccupation={iddureeInoccupation}
        idParcoursFormation={idParcoursFormation}
        idStatut={idStatut}
        isDialogOpen={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          onClose();
        }}
      />
    </>
  );
};
