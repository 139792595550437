import { ButtonContainer, DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router-dom";

import { CalendrierApi, FcbRecherchecalendrierClasseDto } from "../../../../api";
import { CalendrierGlobalSearch } from "../../../../api/models/CalendrierGlobalSearch";
import { ERoutes } from "../../../../AppRouter";
import { EditButton, ViewButton } from "../../../../components/applicationButtons";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface ICalendrierGlobalClassesSearchProps {
  currentSObj: CalendrierGlobalSearch;
}

export const CalendrierGlobalClassesSearch: React.FunctionComponent<ICalendrierGlobalClassesSearchProps> = ({
  currentSObj
}) => {
  const { t } = useTl();
  const api = useApiService(CalendrierApi);
  const history = useHistory();

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { code: "ASC" }
  });

  const searchFunction = React.useCallback(
    (sObj?: CalendrierGlobalSearch) => {
      return api.calendrierSearchClasses({ CalendrierGlobalSearch: sObj });
    },
    [api]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: false
  });

  React.useEffect(() => {
    if (currentSObj?.idcentre && currentSObj?.jour) {
      search(currentSObj);
    }
  }, [currentSObj, search]);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbRecherchecalendrierClasseDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => history.push(`${ERoutes.classe}/${row.idclasse}/detail/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${ERoutes.classe}/${row.idclasse}/detail/edit`)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.CodeClasse),
        fieldName: "code"
      },
      {
        header: () => t(ETLCodes.NomClasse),
        fieldName: "nom"
      }
    ],
    [history, t]
  );

  return (
    <>
      <FieldSet title={t(ETLCodes.Classes)}>
        <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
      </FieldSet>
    </>
  );
};
