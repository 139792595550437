/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EListSearchType } from './EListSearchType';
import {
    EListSearchTypeFromJSON,
    EListSearchTypeFromJSONTyped,
    EListSearchTypeToJSON,
} from './EListSearchType';
import type { EListSearchTypeDoubleCombinableCriteria } from './EListSearchTypeDoubleCombinableCriteria';
import {
    EListSearchTypeDoubleCombinableCriteriaFromJSON,
    EListSearchTypeDoubleCombinableCriteriaFromJSONTyped,
    EListSearchTypeDoubleCombinableCriteriaToJSON,
} from './EListSearchTypeDoubleCombinableCriteria';

/**
 * 
 * @export
 * @interface DoubleListSearch
 */
export interface DoubleListSearch {
    /**
     * 
     * @type {Array<EListSearchTypeDoubleCombinableCriteria>}
     * @memberof DoubleListSearch
     */
    criterias?: Array<EListSearchTypeDoubleCombinableCriteria> | null;
    /**
     * 
     * @type {EListSearchType}
     * @memberof DoubleListSearch
     */
    searchMode?: EListSearchType;
    /**
     * 
     * @type {number}
     * @memberof DoubleListSearch
     * @deprecated
     */
    value?: number;
    /**
     * 
     * @type {number}
     * @memberof DoubleListSearch
     * @deprecated
     */
    secondaryValue?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DoubleListSearch
     */
    readonly availableSearchTypes?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DoubleListSearch
     */
    defaultSearchType?: string | null;
}

/**
 * Check if a given object implements the DoubleListSearch interface.
 */
export function instanceOfDoubleListSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DoubleListSearchFromJSON(json: any): DoubleListSearch {
    return DoubleListSearchFromJSONTyped(json, false);
}

export function DoubleListSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): DoubleListSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'criterias': !exists(json, 'criterias') ? undefined : (json['criterias'] === null ? null : (json['criterias'] as Array<any>).map(EListSearchTypeDoubleCombinableCriteriaFromJSON)),
        'searchMode': !exists(json, 'searchMode') ? undefined : EListSearchTypeFromJSON(json['searchMode']),
        'value': !exists(json, 'value') ? undefined : json['value'],
        'secondaryValue': !exists(json, 'secondaryValue') ? undefined : json['secondaryValue'],
        'availableSearchTypes': !exists(json, 'availableSearchTypes') ? undefined : json['availableSearchTypes'],
        'defaultSearchType': !exists(json, 'defaultSearchType') ? undefined : json['defaultSearchType'],
    };
}

export function DoubleListSearchToJSON(value?: DoubleListSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'criterias': value.criterias === undefined ? undefined : (value.criterias === null ? null : (value.criterias as Array<any>).map(EListSearchTypeDoubleCombinableCriteriaToJSON)),
        'searchMode': EListSearchTypeToJSON(value.searchMode),
        'value': value.value,
        'secondaryValue': value.secondaryValue,
        'defaultSearchType': value.defaultSearchType,
    };
}

