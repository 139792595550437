/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';

/**
 * 
 * @export
 * @interface MatiereReferentielSearch
 */
export interface MatiereReferentielSearch {
    /**
     * 
     * @type {number}
     * @memberof MatiereReferentielSearch
     */
    idreferentiel?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof MatiereReferentielSearch
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof MatiereReferentielSearch
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof MatiereReferentielSearch
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MatiereReferentielSearch
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof MatiereReferentielSearch
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof MatiereReferentielSearch
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the MatiereReferentielSearch interface.
 */
export function instanceOfMatiereReferentielSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MatiereReferentielSearchFromJSON(json: any): MatiereReferentielSearch {
    return MatiereReferentielSearchFromJSONTyped(json, false);
}

export function MatiereReferentielSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): MatiereReferentielSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idreferentiel': !exists(json, 'idreferentiel') ? undefined : json['idreferentiel'],
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function MatiereReferentielSearchToJSON(value?: MatiereReferentielSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idreferentiel': value.idreferentiel,
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

