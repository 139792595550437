import { FGMultiSuggestInput, FGTextInput, FieldGroup, useFGContext } from "nsitools-react";
import * as React from "react";

import { EStatutContrat, ETypeContrat, SiegeSocialEditDto } from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import {
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  FGWalterSuggestTextInput,
  WarningText
} from "../../../../../../components";
import { useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";
import { nameof } from "../../../../../../utils";

export interface IContratPratiqueProfessionnellePanelProps {
  typeContrat: ETypeContrat;
  idsiegeSocial: number;
}

export const ContratPratiqueProfessionnellePanel: React.FunctionComponent<IContratPratiqueProfessionnellePanelProps> = ({
  typeContrat,
  idsiegeSocial
}) => {
  const { t } = useTl();
  const { formik } = useFGContext();

  const [metiers, mLoading] = useReferential(
    a =>
      a.referentialMetierByStadeForContrat({
        idStade: +(formik?.values?.idstade ?? 0),
        currentId: +(formik?.values?.idmetier ?? 0)
      }),
    false,
    [formik?.values?.idstade, formik?.values?.idmetier]
  );

  const [commissionsParitaires, cpLoading] = useReferential(
    a =>
      a.referentialGetCommissionsParitaires({
        idmetier: 0,
        idsiegeSocial: idsiegeSocial ?? 0,
        currentId: formik.values?.idcommissionParitaire ?? 0
      }),
    false,
    [idsiegeSocial, formik.values?.idcommissionParitaire]
  );

  const [clauses, cLoading] = useReferential(a => a.referentialGetClausesContrat(), true);

  const [lieuxSignature, lsLoading] = useReferential(a => a.referentialGetLieuxSignature());

  const annees = React.useMemo(
    () => [
      { value: 1, label: "1" },
      { value: 2, label: "2" },
      { value: 3, label: "3" },
      { value: 4, label: "4" }
    ],
    []
  );

  const softRO = React.useMemo(() => [EStatutContrat.Rompu].includes(formik?.values?.statut), [formik?.values?.statut]);

  return (
    <>
      <FieldGroup
        columns={2}
        collapsable
        fieldsetProps={{
          title: t(ETLCodes.Detail)
        }}
      >
        <FieldGroup>
          <FGTextInput name="stade" label={t(ETLCodes.Stade)} readonly />
          <FGWalterSelectInput
            name="idmetier"
            label={t(ETLCodes.Metier)}
            items={metiers}
            loading={mLoading}
            readonly={formik?.values?.idcontrat > 0 || softRO}
          />
          <FGWalterSelectInput
            name="idcommissionParitaire"
            label={t(ETLCodes.CommissionParitaire)}
            items={commissionsParitaires}
            loading={cpLoading}
            helperText={ctx =>
              !!idsiegeSocial &&
              commissionsParitaires?.length === 0 && <WarningText text={t(ETLCodes.AucuneCpDansCeSiegeSocial)} />
            }
            itemCreateUrl={`${ERoutes.siegeSocial}/${idsiegeSocial}/detail/edit`}
            itemCreateParams={`&fieldName=${nameof<SiegeSocialEditDto>("idsCommissionParitaire")}`}
            autoSelectIfOne
          />
          <FGMultiSuggestInput
            name="idsclause"
            label={t(ETLCodes.Clauses)}
            items={clauses}
            loading={cLoading}
            displayField="label"
            valueField="value"
          />
        </FieldGroup>
      </FieldGroup>
      <FieldGroup
        columns={2}
        collapsable
        fieldsetProps={{
          title: t(ETLCodes.LieuxDates)
        }}
      >
        <FieldGroup>
          <FGWalterDateMaskInput name="dateDebut" label={t(ETLCodes.DateDebut)} />
          <FGWalterDateMaskInput name="dateFin" label={t(ETLCodes.DateFin)} />
          <FGWalterDateMaskInput name="dateSignature" label={t(ETLCodes.DateSignature)} />
          <FGWalterSelectInput
            name="idlieuSignature"
            label={t(ETLCodes.LieuSignature)}
            items={lieuxSignature}
            loading={lsLoading}
          />
        </FieldGroup>
      </FieldGroup>
      <FieldGroup
        columns={2}
        collapsable
        fieldsetProps={{
          title: t(ETLCodes.DonneesJoursHeures)
        }}
      >
        <FieldGroup>
          <FGWalterSuggestTextInput
            name="pratiqueProfessionnelleDto.nbHeuresMax"
            label={t(ETLCodes.NbMaxHeuresFormationComplete)}
            items={["125", "180", "250", "500", "664"]}
          />
          <FGWalterSelectInput
            name="pratiqueProfessionnelleDto.anneeFormation"
            label={t(ETLCodes.AnneeFormation)}
            items={annees}
          />
          <FGTextInput
            name="pratiqueProfessionnelleDto.nbHeuresMin"
            label={t(ETLCodes.NbMinHeuresAnnee)}
            type="number"
          />
          <FGTextInput
            name="pratiqueProfessionnelleDto.nbHeuresPrevues"
            label={t(ETLCodes.NbHeuresPrevues)}
            type="number"
          />
          <FGTextInput
            name="pratiqueProfessionnelleDto.nbHeuresPrestees"
            label={t(ETLCodes.NbHeuresPrestees)}
            type="number"
          />
        </FieldGroup>
      </FieldGroup>
    </>
  );
};
