import { ButtonContainer, IDataTableColumn, showError } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";

import { LieuFormationDocumentEditDialog, LieuFormationDocumentUploadDialog } from ".";
import {
  LieuFormationFichierApi,
  LieuFormationFichierGridDto,
  LieuFormationFichierSearchDto
} from "../../../../../api";
import { DeleteButton, DownloadButton, EditButton, SearchTablePage } from "../../../../../components";
import { useDialog, useEventsContext } from "../../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { exportFile } from "../../../../../utils";

export interface ILieuFormationDocumentsProps {}

export const LieuFormationDocuments: React.FunctionComponent<ILieuFormationDocumentsProps> = props => {
  const { t } = useTl();
  const api = useApiService(LieuFormationFichierApi);
  const { id } = useParams<{ id: string }>();
  const [currentLieuFormationFichierId, setCurrentLieuFormationFichierId] = React.useState(null);
  const [uploadDialoOpen, setUploadDialoOpen] = React.useState(false);
  const [rowLoading, setRowLoading] = React.useState(null);

  const { dispatchEvent } = useEventsContext();
  const { showDialogPromise } = useDialog();
  const deleteFichier = React.useCallback(
    async (idlieuFormationFichier: number) => {
      const result = await showDialogPromise({
        message: t(ETLCodes.DeleteConfirmationMessage)
      });

      if (result === "yes") {
        try {
          setRowLoading(idlieuFormationFichier);
          await api.lieuFormationFichierDeleteLieuFormationFichier({ id: idlieuFormationFichier });
          dispatchEvent("SEARCH_TABLE_REFRESH");
        } catch {
          showError(t(ETLCodes.ErrorWhileDeletingFile));
        } finally {
          setRowLoading(null);
        }
      }
    },
    [api, dispatchEvent, showDialogPromise, t]
  );

  const downloadFichier = React.useCallback(
    async (idlieuFormationFichier: number) => {
      try {
        setRowLoading(idlieuFormationFichier);
        const file = await api.lieuFormationFichierDownloadLieuFormationFichier({ id: idlieuFormationFichier });
        await exportFile(file);
      } catch {
        showError(t(ETLCodes.ErrorWhileDownloadingFile));
      } finally {
        setRowLoading(null);
      }
    },
    [api, t]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: LieuFormationFichierGridDto) => (
          <ButtonContainer>
            <EditButton
              minimal={true}
              onClick={() => setCurrentLieuFormationFichierId(row.idlieuFormationFichier)}
              loading={rowLoading === row.idlieuFormationFichier}
              disabled={!!rowLoading && rowLoading !== row.idlieuFormationFichier}
            />
            <DownloadButton
              minimal={true}
              onClick={() => downloadFichier(row.idlieuFormationFichier)}
              loading={rowLoading === row.idlieuFormationFichier}
              disabled={!!rowLoading && rowLoading !== row.idlieuFormationFichier}
            />
            <DeleteButton
              minimal={true}
              onDelete={() => deleteFichier(row.idlieuFormationFichier)}
              loading={rowLoading === row.idlieuFormationFichier}
              disabled={!!rowLoading && rowLoading !== row.idlieuFormationFichier}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.NomFichier),
        fieldName: "fileName"
      },
      {
        header: () => t(ETLCodes.DateUpload),
        fieldName: "uploadDate"
      },
      {
        header: () => t(ETLCodes.UtilisateurUpload),
        fieldName: "creationUserName"
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "typeLieuFormationFichier"
      }
    ],
    [deleteFichier, downloadFichier, rowLoading, t]
  );

  const getSearchCriterias = React.useCallback(
    () => api.lieuFormationFichierGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  const apiFactory = useAbortableApiServiceFactory(LieuFormationFichierApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (nextSearch?: LieuFormationFichierSearchDto) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.lieuFormationFichierBaseSearch({
        LieuFormationFichierSearchDto: { ...nextSearch, idlieuFormation: +id }
      });
    },
    [apiFactory, id]
  );

  const onClose = React.useCallback(
    (refresh?: boolean) => {
      setCurrentLieuFormationFichierId(null);
      setUploadDialoOpen(false);
      if (refresh) {
        dispatchEvent("SEARCH_TABLE_REFRESH");
      }
    },
    [dispatchEvent]
  );

  const onAdd = React.useCallback(() => {
    setUploadDialoOpen(true);
  }, []);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <SearchTablePage
        columns={columns}
        withCard={false}
        getCriteriasFunction={getSearchCriterias}
        searchFunction={search}
        onAbort={onAbort}
        sortKeys={{ uploadDate: "DESC", fileName: "ASC" }}
        addFunc={onAdd}
      />
      {uploadDialoOpen && (
        <LieuFormationDocumentUploadDialog onClose={onClose} dialogOpen={uploadDialoOpen} idlieuFormation={+id} />
      )}
      {!!currentLieuFormationFichierId && (
        <LieuFormationDocumentEditDialog onClose={onClose} idlieuFormationFichier={currentLieuFormationFichierId} />
      )}
    </>
  );
};
