import { Button, Classes, Dialog } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";
import { UsersApi } from "../api";
import { useAuth, useGlobalData } from "../contexts";
import { useApiService, useManageError, useTheme, useTl } from "../hooks";
import { ETLCodes } from "../locales";

const StyledDialog = styled(Dialog)`
  width: 600px;
  height: auto;
  background-color: white;
`;

export interface IStartupDialogProps {
  onClosed: () => void;
  isDialogOpen: boolean;
}

export const StartupDialog: React.FunctionComponent<IStartupDialogProps> = ({ isDialogOpen, onClosed }) => {
  const { t } = useTl();
  const { ifapmeSide } = useTheme();
  const { user } = useAuth();
  const { manageError } = useManageError();
  const { walterStartupPopupText, hopeStartupPopupText } = useGlobalData();
  const api = useApiService(UsersApi);

  const [saving, setSaving] = React.useState(false);
  const onAcknowledged = React.useCallback(async () => {
    try {
      setSaving(true);
      await api.usersDisableStartupPopup({ iduser: user.iduser });
    } catch (e) {
      manageError(e);
    } finally {
      setSaving(false);
      onClosed();
    }
  }, [api, manageError, onClosed, user.iduser]);

  const [time, setTime] = React.useState(10);

  React.useEffect(() => {
    let timer = setInterval(() => {
      setTime(time => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);
  }, []);

  const buttonText = React.useMemo(() => t(ETLCodes.Acknowledged) + (time > 0 ? ` (${time})` : ""), [t, time]);

  return (
    <StyledDialog
      title={t(ETLCodes.NouvelleFonctionnalité)}
      isOpen={isDialogOpen}
      isCloseButtonShown={false}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
    >
      <div className={Classes.DIALOG_BODY}>
        {ifapmeSide === "walter" ? walterStartupPopupText : hopeStartupPopupText}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text={buttonText} intent="success" onClick={onAcknowledged} disabled={time > 0} loading={saving} />
        </div>
      </div>
    </StyledDialog>
  );
};

export default StartupDialog;
