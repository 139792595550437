import { FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { FcbImpressionDtoFromJSON, ImpressionApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { FGWalterCheckboxInput, SmallFormGenerator } from "../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface IImpressionDetailPageProps {
  idImpression: number;
  editMode: boolean;
}

export const ImpressionDetailPage: React.FunctionComponent<IImpressionDetailPageProps> = ({
  idImpression,
  editMode
}) => {
  const { t } = useTl();
  const api = useApiService(ImpressionApi);
  const history = useHistory();

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      nom: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idImpression <= 0 ? FcbImpressionDtoFromJSON({ idImpression: 0 }) : api.impressionGet({ id: +idImpression }),
    saveApiFn: d => api.impressionSave({ FcbImpressionDto: d }),
    onSavedRoute: d => `${ERoutes.impressions}/${d.idimpression}/detail/edit`,
    deleteApiFn: d => api.impressionDelete({ id: d.idimpression }),
    onDeletedRoute: () => ERoutes.impressions
  });

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={editMode}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.impressions)}
      onDelete={deleteItem}
      showDeleteButton={+idImpression > 0}
      saving={saving}
      deleting={deleting}
    >
      <FieldGroup>
        <FGTextInput name="nom" label={t(ETLCodes.Nom)} maxLength={50} />
        <FGTextInput name="description" label={t(ETLCodes.Description)} maxLength={50} />
        <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
        <FGTextInput name="rapport" label={t(ETLCodes.Rapport)} maxLength={100} />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
