import { Checkbox } from "@blueprintjs/core";
import * as React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { EBooleanSearchTypes, FcbStadeGridDto, StadeApi, StadeSearch } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

interface IStadeListPageProps {}

const Container = styled.div`
  display: flex;
`;

export const StadeListPage: React.FunctionComponent<IStadeListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(StadeApi);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbStadeGridDto) => (
          <Container>
            <ViewButton minimal={true} onClick={() => history.push(`${ERoutes.stade}/${row.idstade}/detail/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${ERoutes.stade}/${row.idstade}/detail/edit`)} />
          </Container>
        )
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code"
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      },
      {
        header: () => t(ETLCodes.AcquisIfapme),
        fieldName: "acquisIfapme",
        render: (row: FcbStadeGridDto) => <Checkbox checked={row.acquisIfapme} onClick={() => {}} disabled />
      }
    ],
    [history, t]
  );

  const addItemFunction = React.useCallback(() => history.push(`${ERoutes.stade}/0/detail/edit`), [history]);

  const getCriteriasFn = React.useCallback(() => api.stadeGetSearchCriterias({ includeListsValues: true }), [api]);

  const apiFactory = useAbortableApiServiceFactory(StadeApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: StadeSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.stadeBaseSearch({ StadeSearch: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      sortKeys={{ code: "ASC" }}
      columns={columns}
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      addFunc={addItemFunction}
      breadCrumbs={[{ text: t(ETLCodes.Stades), route: ERoutes.stade }]}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
    />
  );
};
