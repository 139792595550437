/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContratFaltDto
 */
export interface ContratFaltDto {
    /**
     * 
     * @type {number}
     * @memberof ContratFaltDto
     */
    idcontratFalt?: number;
    /**
     * 
     * @type {number}
     * @memberof ContratFaltDto
     */
    idcontrat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratFaltDto
     */
    idoperateurFormation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratFaltDto
     */
    pcFormationEmployeur?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratFaltDto
     */
    pcFormationOperateur?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratFaltDto
     */
    interventionFinanciere?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratFaltDto
     */
    dateTransmission?: Date | null;
}

/**
 * Check if a given object implements the ContratFaltDto interface.
 */
export function instanceOfContratFaltDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContratFaltDtoFromJSON(json: any): ContratFaltDto {
    return ContratFaltDtoFromJSONTyped(json, false);
}

export function ContratFaltDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContratFaltDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontratFalt': !exists(json, 'idcontratFalt') ? undefined : json['idcontratFalt'],
        'idcontrat': !exists(json, 'idcontrat') ? undefined : json['idcontrat'],
        'idoperateurFormation': !exists(json, 'idoperateurFormation') ? undefined : json['idoperateurFormation'],
        'pcFormationEmployeur': !exists(json, 'pcFormationEmployeur') ? undefined : json['pcFormationEmployeur'],
        'pcFormationOperateur': !exists(json, 'pcFormationOperateur') ? undefined : json['pcFormationOperateur'],
        'interventionFinanciere': !exists(json, 'interventionFinanciere') ? undefined : json['interventionFinanciere'],
        'dateTransmission': !exists(json, 'dateTransmission') ? undefined : (json['dateTransmission'] === null ? null : new Date(json['dateTransmission'])),
    };
}

export function ContratFaltDtoToJSON(value?: ContratFaltDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontratFalt': value.idcontratFalt,
        'idcontrat': value.idcontrat,
        'idoperateurFormation': value.idoperateurFormation,
        'pcFormationEmployeur': value.pcFormationEmployeur,
        'pcFormationOperateur': value.pcFormationOperateur,
        'interventionFinanciere': value.interventionFinanciere,
        'dateTransmission': value.dateTransmission === undefined ? undefined : (value.dateTransmission === null ? null : value.dateTransmission.toISOString()),
    };
}

