/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbEvaluationApprenantGetDto
 */
export interface FcbEvaluationApprenantGetDto {
    /**
     * 
     * @type {number}
     * @memberof FcbEvaluationApprenantGetDto
     */
    idEvaluationApprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbEvaluationApprenantGetDto
     */
    idEvaluation?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbEvaluationApprenantGetDto
     */
    idInscription?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbEvaluationApprenantGetDto
     */
    typeEvaluation?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEvaluationApprenantGetDto
     */
    typeEvaluationLibelle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEvaluationApprenantGetDto
     */
    apprenant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEvaluationApprenantGetDto
     */
    cote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEvaluationApprenantGetDto
     */
    statut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEvaluationApprenantGetDto
     */
    coteBeforeCalcul?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEvaluationApprenantGetDto
     */
    coteReferentiel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEvaluationApprenantGetDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbEvaluationApprenantGetDto
     */
    remediationConseil?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEvaluationApprenantGetDto
     */
    coteRemediation?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbEvaluationApprenantGetDto
     */
    neutralise?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FcbEvaluationApprenantGetDto
     */
    idApprenant?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbEvaluationApprenantGetDto
     */
    disabled?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEvaluationApprenantGetDto
     */
    readonly presence?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbEvaluationApprenantGetDto
     */
    neutraliseValue?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FcbEvaluationApprenantGetDto
     */
    readonly coteMaxValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEvaluationApprenantGetDto
     */
    coteValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEvaluationApprenantGetDto
     */
    readonly coteReferentielValue?: string | null;
}

/**
 * Check if a given object implements the FcbEvaluationApprenantGetDto interface.
 */
export function instanceOfFcbEvaluationApprenantGetDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbEvaluationApprenantGetDtoFromJSON(json: any): FcbEvaluationApprenantGetDto {
    return FcbEvaluationApprenantGetDtoFromJSONTyped(json, false);
}

export function FcbEvaluationApprenantGetDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbEvaluationApprenantGetDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idEvaluationApprenant': !exists(json, 'idEvaluationApprenant') ? undefined : json['idEvaluationApprenant'],
        'idEvaluation': !exists(json, 'idEvaluation') ? undefined : json['idEvaluation'],
        'idInscription': !exists(json, 'idInscription') ? undefined : json['idInscription'],
        'typeEvaluation': !exists(json, 'typeEvaluation') ? undefined : json['typeEvaluation'],
        'typeEvaluationLibelle': !exists(json, 'typeEvaluationLibelle') ? undefined : json['typeEvaluationLibelle'],
        'apprenant': !exists(json, 'apprenant') ? undefined : json['apprenant'],
        'cote': !exists(json, 'cote') ? undefined : json['cote'],
        'statut': !exists(json, 'statut') ? undefined : json['statut'],
        'coteBeforeCalcul': !exists(json, 'coteBeforeCalcul') ? undefined : json['coteBeforeCalcul'],
        'coteReferentiel': !exists(json, 'coteReferentiel') ? undefined : json['coteReferentiel'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'remediationConseil': !exists(json, 'remediationConseil') ? undefined : json['remediationConseil'],
        'coteRemediation': !exists(json, 'coteRemediation') ? undefined : json['coteRemediation'],
        'neutralise': !exists(json, 'neutralise') ? undefined : json['neutralise'],
        'idApprenant': !exists(json, 'idApprenant') ? undefined : json['idApprenant'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
        'presence': !exists(json, 'presence') ? undefined : json['presence'],
        'neutraliseValue': !exists(json, 'neutraliseValue') ? undefined : json['neutraliseValue'],
        'coteMaxValue': !exists(json, 'coteMaxValue') ? undefined : json['coteMaxValue'],
        'coteValue': !exists(json, 'coteValue') ? undefined : json['coteValue'],
        'coteReferentielValue': !exists(json, 'coteReferentielValue') ? undefined : json['coteReferentielValue'],
    };
}

export function FcbEvaluationApprenantGetDtoToJSON(value?: FcbEvaluationApprenantGetDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idEvaluationApprenant': value.idEvaluationApprenant,
        'idEvaluation': value.idEvaluation,
        'idInscription': value.idInscription,
        'typeEvaluation': value.typeEvaluation,
        'typeEvaluationLibelle': value.typeEvaluationLibelle,
        'apprenant': value.apprenant,
        'cote': value.cote,
        'statut': value.statut,
        'coteBeforeCalcul': value.coteBeforeCalcul,
        'coteReferentiel': value.coteReferentiel,
        'remarque': value.remarque,
        'remediationConseil': value.remediationConseil,
        'coteRemediation': value.coteRemediation,
        'neutralise': value.neutralise,
        'idApprenant': value.idApprenant,
        'disabled': value.disabled,
        'neutraliseValue': value.neutraliseValue,
        'coteValue': value.coteValue,
    };
}

