import { InputGroup } from "@blueprintjs/core";
import { FGCustomInput, IFGContext, IFGCustomInputProps, useFGContext } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

const StyledFGCustomInput = styled(FGCustomInput)`
  caret-color: transparent;
`;

export interface IFGReadOnlyInputProps extends Omit<IFGCustomInputProps, "children"> {
  ref?: React.Ref<HTMLInputElement>;
  leftElement?: JSX.Element | ((ctx: IFGContext<any>) => JSX.Element);
  rightElement?: JSX.Element | ((ctx: IFGContext<any>) => JSX.Element);
  disabled?: boolean;
}

export const FGReadOnlyInput: React.FunctionComponent<IFGReadOnlyInputProps> = ({
  name,
  label = null,
  className,
  ref,
  leftElement,
  rightElement,
  readonly,
  disabled = false,
  ...fgCustomInputProps
}) => {
  const { editMode } = useFGContext();

  const style = React.useMemo(() => {
    if (editMode && !readonly) {
      return {};
    } else {
      return { boxShadow: "none", backgroundColor: "transparent" };
    }
  }, [editMode, readonly]);

  return (
    <StyledFGCustomInput name={name} label={label} readonly={readonly} {...fgCustomInputProps}>
      {ctx => (
        <InputGroup
          tabIndex={0}
          inputRef={ref}
          value=""
          leftElement={leftElement instanceof Function ? leftElement(ctx) : leftElement}
          rightElement={rightElement instanceof Function ? rightElement(ctx) : rightElement}
          style={style}
          readOnly={readonly}
          disabled={disabled}
        />
      )}
    </StyledFGCustomInput>
  );
};
