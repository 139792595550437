import * as React from "react";
import { useHistory } from "react-router";

import {
  ConseillerPedagogiqueApi,
  ConseillerPedagogiqueSearch,
  FcbConseillerPedagogiqueGridDto,
  EBooleanSearchTypes
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, EmailButton, SearchTablePage, ViewButton, SmsButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { useAbortableApiServiceFactory, useApiService } from "../../../../../hooks/useApiService";
import { ETLCodes } from "../../../../../locales";
import { ButtonContainer, IDataTableColumn } from "nsitools-react";

interface IConseillerPedagogiqueListPageProps {}

export const ConseillerPedagogiqueListPage: React.FunctionComponent<IConseillerPedagogiqueListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ConseillerPedagogiqueApi);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbConseillerPedagogiqueGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() =>
                history.push(`${ERoutes.conseillerPedagogique}/${row.idconseillerPedagogique}/detail/view`)
              }
            />
            <EditButton
              minimal={true}
              onClick={() =>
                history.push(`${ERoutes.conseillerPedagogique}/${row.idconseillerPedagogique}/detail/edit`)
              }
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "idconseillerPedagogique",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.Gsm),
        fieldName: "gsm.numero",
        render: (value: FcbConseillerPedagogiqueGridDto) => (
          <SmsButton phone={value.gsm?.numero} disabled={!!value.dateDeces} />
        )
      },
      {
        header: () => t(ETLCodes.Tel),
        fieldName: "telephone.numero"
      },
      {
        header: () => t(ETLCodes.Fax),
        fieldName: "fax"
      },
      {
        header: () => t(ETLCodes.General_Email),
        fieldName: "email.adresseEmail",
        alignment: "center",
        autoFitContent: true,
        render: (row: FcbConseillerPedagogiqueGridDto) => (
          <EmailButton email={row.email?.adresseEmail} disabled={!!row.dateDeces} />
        )
      }
    ],
    [history, t]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.conseillerPedagogique}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(
    () => api.conseillerPedagogiqueGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  const apiFactory = useAbortableApiServiceFactory(ConseillerPedagogiqueApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: ConseillerPedagogiqueSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.conseillerPedagogiqueBaseSearch({ ConseillerPedagogiqueSearch: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      columns={columns}
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      addFunc={addItemFunction}
      breadCrumbs={[{ text: t(ETLCodes.ConseillersPedagogiques), route: ERoutes.conseillerPedagogique }]}
      sortKeys={{ nom: "ASC" }}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
    />
  );
};
