import { createHashHistory } from "history";
import * as React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Router } from "react-router-dom";
import { RecoilRoot } from "recoil";

import {
  AuthProvider,
  BeIdCardProvider,
  BreadcrumbProvider,
  DialogProvider,
  EventsProvider,
  GlobalDataProvider,
  ModifiedByProvider,
  PrintingQueueProvider,
  RouteParamsProvider,
  SearchCriteriaProvider,
  useDialog
} from "./contexts";
import { useTl } from "./hooks";
import { ETLCodes } from "./locales";

const AppWithRouterProvider: React.FunctionComponent = props => {
  const { showDialog } = useDialog();
  const { t } = useTl();
  const hashHistory = React.useMemo(
    () =>
      createHashHistory({
        getUserConfirmation: (message, cb) => {
          showDialog({
            message,
            title: t(ETLCodes.RouteConfirmDialogTitle),
            onDialogClosed: res => {
              if (res === "yes" || res === "ok") {
                cb(true);
              } else {
                cb(false);
              }
            }
          });
        }
      }),
    [showDialog, t]
  );
  return (
    <Router history={hashHistory}>
      <EventsProvider>
        <AuthProvider>
          <BreadcrumbProvider>
            <RouteParamsProvider>
              <SearchCriteriaProvider>
                <GlobalDataProvider>
                  <BeIdCardProvider>
                    <PrintingQueueProvider>
                      <ModifiedByProvider>{props.children}</ModifiedByProvider>
                    </PrintingQueueProvider>
                  </BeIdCardProvider>
                </GlobalDataProvider>
              </SearchCriteriaProvider>
            </RouteParamsProvider>
          </BreadcrumbProvider>
        </AuthProvider>
      </EventsProvider>
    </Router>
  );
};

export const AppProvider: React.FunctionComponent = props => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      },
      mutations: {}
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <DialogProvider>
          <AppWithRouterProvider>{props.children}</AppWithRouterProvider>
        </DialogProvider>
      </RecoilRoot>
    </QueryClientProvider>
  );
};
