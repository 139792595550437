/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdresseDto } from './AdresseDto';
import {
    AdresseDtoFromJSON,
    AdresseDtoFromJSONTyped,
    AdresseDtoToJSON,
} from './AdresseDto';

/**
 * 
 * @export
 * @interface ApprenantRepresentantPersonneEditDto
 */
export interface ApprenantRepresentantPersonneEditDto {
    /**
     * 
     * @type {number}
     * @memberof ApprenantRepresentantPersonneEditDto
     */
    idapprenantRepresentant?: number;
    /**
     * 
     * @type {number}
     * @memberof ApprenantRepresentantPersonneEditDto
     */
    idrepresentant?: number;
    /**
     * 
     * @type {number}
     * @memberof ApprenantRepresentantPersonneEditDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantPersonneEditDto
     */
    codesexe?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApprenantRepresentantPersonneEditDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantPersonneEditDto
     */
    nationalite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantPersonneEditDto
     */
    numeroNational?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantPersonneEditDto
     */
    adresseNaissance?: string | null;
    /**
     * 
     * @type {AdresseDto}
     * @memberof ApprenantRepresentantPersonneEditDto
     */
    adresseRepresentant?: AdresseDto;
    /**
     * 
     * @type {number}
     * @memberof ApprenantRepresentantPersonneEditDto
     */
    idgsm?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApprenantRepresentantPersonneEditDto
     */
    idtelephone?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApprenantRepresentantPersonneEditDto
     */
    idemail?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApprenantRepresentantPersonneEditDto
     */
    idlienParente?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantPersonneEditDto
     */
    autreLien?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApprenantRepresentantPersonneEditDto
     */
    courrier?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApprenantRepresentantPersonneEditDto
     */
    isEmergencyContact?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApprenantRepresentantPersonneEditDto
     */
    alreadyHasEmergencyContact?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApprenantRepresentantPersonneEditDto
     */
    representantLegal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantPersonneEditDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantPersonneEditDto
     */
    type?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApprenantRepresentantPersonneEditDto
     */
    hasAddressDoublons?: boolean;
}

/**
 * Check if a given object implements the ApprenantRepresentantPersonneEditDto interface.
 */
export function instanceOfApprenantRepresentantPersonneEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApprenantRepresentantPersonneEditDtoFromJSON(json: any): ApprenantRepresentantPersonneEditDto {
    return ApprenantRepresentantPersonneEditDtoFromJSONTyped(json, false);
}

export function ApprenantRepresentantPersonneEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApprenantRepresentantPersonneEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idapprenantRepresentant': !exists(json, 'idapprenantRepresentant') ? undefined : json['idapprenantRepresentant'],
        'idrepresentant': !exists(json, 'idrepresentant') ? undefined : json['idrepresentant'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'codesexe': !exists(json, 'codesexe') ? undefined : json['codesexe'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'nationalite': !exists(json, 'nationalite') ? undefined : json['nationalite'],
        'numeroNational': !exists(json, 'numeroNational') ? undefined : json['numeroNational'],
        'adresseNaissance': !exists(json, 'adresseNaissance') ? undefined : json['adresseNaissance'],
        'adresseRepresentant': !exists(json, 'adresseRepresentant') ? undefined : AdresseDtoFromJSON(json['adresseRepresentant']),
        'idgsm': !exists(json, 'idgsm') ? undefined : json['idgsm'],
        'idtelephone': !exists(json, 'idtelephone') ? undefined : json['idtelephone'],
        'idemail': !exists(json, 'idemail') ? undefined : json['idemail'],
        'idlienParente': !exists(json, 'idlienParente') ? undefined : json['idlienParente'],
        'autreLien': !exists(json, 'autreLien') ? undefined : json['autreLien'],
        'courrier': !exists(json, 'courrier') ? undefined : json['courrier'],
        'isEmergencyContact': !exists(json, 'isEmergencyContact') ? undefined : json['isEmergencyContact'],
        'alreadyHasEmergencyContact': !exists(json, 'alreadyHasEmergencyContact') ? undefined : json['alreadyHasEmergencyContact'],
        'representantLegal': !exists(json, 'representantLegal') ? undefined : json['representantLegal'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'hasAddressDoublons': !exists(json, 'hasAddressDoublons') ? undefined : json['hasAddressDoublons'],
    };
}

export function ApprenantRepresentantPersonneEditDtoToJSON(value?: ApprenantRepresentantPersonneEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idapprenantRepresentant': value.idapprenantRepresentant,
        'idrepresentant': value.idrepresentant,
        'idapprenant': value.idapprenant,
        'codesexe': value.codesexe,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'nationalite': value.nationalite,
        'numeroNational': value.numeroNational,
        'adresseNaissance': value.adresseNaissance,
        'adresseRepresentant': AdresseDtoToJSON(value.adresseRepresentant),
        'idgsm': value.idgsm,
        'idtelephone': value.idtelephone,
        'idemail': value.idemail,
        'idlienParente': value.idlienParente,
        'autreLien': value.autreLien,
        'courrier': value.courrier,
        'isEmergencyContact': value.isEmergencyContact,
        'alreadyHasEmergencyContact': value.alreadyHasEmergencyContact,
        'representantLegal': value.representantLegal,
        'remarque': value.remarque,
        'type': value.type,
        'hasAddressDoublons': value.hasAddressDoublons,
    };
}

