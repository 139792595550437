import * as React from "react";
import { matchPath, useLocation } from "react-router";
import { TuteurDetail, TuteurDetailDoublons } from ".";
import { TuteurLieuDoublonSearchDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { useTabMessage } from "../../../../../hooks";

export interface ITuteurDetailSwitchProps {}

export const TuteurDetailSwitch: React.FunctionComponent<ITuteurDetailSwitchProps> = props => {
  const [sObj, setSObj] = React.useState(TuteurLieuDoublonSearchDtoFromJSON({}));
  const { pathname, search } = useLocation();
  const cfs = React.useMemo(() => new URLSearchParams(search).get("cfs"), [search]);
  const { messageValue, clearMessageValue } = useTabMessage(cfs);

  React.useEffect(() => {
    if (!!messageValue) {
      setSObj(messageValue);
      clearMessageValue();
    }
  }, [clearMessageValue, messageValue]);
  if (
    matchPath(pathname, {
      path: `${ERoutes.tuteur}/-1/:tab/checkDoublons`
    })
  ) {
    return <TuteurDetailDoublons setBaseData={setSObj} />;
  } else {
    return <TuteurDetail baseData={sObj} />;
  }
};
