import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";

import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales/ETLCodes";
import { ICustomButtonProps } from "./ICustomButtonProps";

interface ISelectButtonProps extends ICustomButtonProps {}

export const SelectButton: React.FunctionComponent<ISelectButtonProps> = ({
  minimal = true,
  onClick,
  loading,
  disabled,
  intent = Intent.PRIMARY,
  text,
  hideText,
  small
}) => {
  const { t } = useTl();
  return (
    <Button
      icon={IconNames.TICK}
      minimal={minimal}
      onClick={onClick}
      text={!hideText ? text || t(ETLCodes.Select) : null}
      intent={intent}
      loading={loading}
      disabled={disabled}
      small={small}
    />
  );
};
