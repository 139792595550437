/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbClasseApprenantGridDto
 */
export interface FcbClasseApprenantGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbClasseApprenantGridDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseApprenantGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseApprenantGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbClasseApprenantGridDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseApprenantGridDto
     */
    codeInscription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseApprenantGridDto
     */
    statut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseApprenantGridDto
     */
    adresse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseApprenantGridDto
     */
    codePostal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseApprenantGridDto
     */
    localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseApprenantGridDto
     */
    commune?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseApprenantGridDto
     */
    telephone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseApprenantGridDto
     */
    gsm?: string | null;
}

/**
 * Check if a given object implements the FcbClasseApprenantGridDto interface.
 */
export function instanceOfFcbClasseApprenantGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbClasseApprenantGridDtoFromJSON(json: any): FcbClasseApprenantGridDto {
    return FcbClasseApprenantGridDtoFromJSONTyped(json, false);
}

export function FcbClasseApprenantGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbClasseApprenantGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'codeInscription': !exists(json, 'codeInscription') ? undefined : json['codeInscription'],
        'statut': !exists(json, 'statut') ? undefined : json['statut'],
        'adresse': !exists(json, 'adresse') ? undefined : json['adresse'],
        'codePostal': !exists(json, 'codePostal') ? undefined : json['codePostal'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'commune': !exists(json, 'commune') ? undefined : json['commune'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'gsm': !exists(json, 'gsm') ? undefined : json['gsm'],
    };
}

export function FcbClasseApprenantGridDtoToJSON(value?: FcbClasseApprenantGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idapprenant': value.idapprenant,
        'nom': value.nom,
        'prenom': value.prenom,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'codeInscription': value.codeInscription,
        'statut': value.statut,
        'adresse': value.adresse,
        'codePostal': value.codePostal,
        'localite': value.localite,
        'commune': value.commune,
        'telephone': value.telephone,
        'gsm': value.gsm,
    };
}

