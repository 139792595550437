import { Button, Checkbox, Colors, Divider } from "@blueprintjs/core";
import { DataTable, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { css } from "styled-components";

import {
  BulletinApprenantTypeApi,
  BulletinDetailSearch,
  ETypeDeliberation,
  FcbResultatTypeGridDto
} from "../../../../../../api";
import { TableSelectEditor } from "../../../../../../components";
import { useApiService, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";
import {
  DeliberationBulletinCaterorySumGrid,
  DeliberationBulletinEvaluationGrid
} from "../../../../deliberationType/item/bulletins";

export interface IApprenantBulletinDeliberationGridProps {
  idbulletinApprenantType: number;
  execSearch: boolean;
}

export const ApprenantBulletinDeliberationGrid: React.FunctionComponent<IApprenantBulletinDeliberationGridProps> = ({
  idbulletinApprenantType,
  execSearch
}) => {
  const { t } = useTl();

  const api = useApiService(BulletinApprenantTypeApi);
  const [dMatiere, dMatiereLoading] = useReferential(
    a => a.referentialGetDeliberationTypes({ typeDeliberation: ETypeDeliberation.Matiere }),
    true
  );
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: false,
    enableFilter: false,
    availablePageSizes: [9999],
    pageSize: 9999,
    sortKeys: { categorie: "ASC" }
  });

  const searchFunction = React.useCallback(
    (sObj?: BulletinDetailSearch) => {
      sObj.idbulletinApprenantType = idbulletinApprenantType;
      return api.bulletinApprenantTypeBulletinDeliberationSession1Search({ BulletinDetailSearch: sObj });
    },
    [api, idbulletinApprenantType]
  );

  const { loading, search } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  React.useEffect(() => {
    if (execSearch) {
      search();
    }
  }, [execSearch, search]);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "_toggle",
        render: (item: FcbResultatTypeGridDto, _, { subPanelOpened, toggleSubPanel }) => {
          if (item.idclassematiere) {
            return <Button icon={subPanelOpened ? "minus" : "plus"} onClick={() => toggleSubPanel()}></Button>;
          } else {
            return <div />;
          }
        }
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "codematiere"
      },
      {
        header: () => t(ETLCodes.Matiere),
        fieldName: "matiere"
      },
      {
        header: () => t(ETLCodes.Formateur),
        fieldName: "formateur"
      },
      {
        header: () => t(ETLCodes.TJ),
        fieldName: "tj"
      },
      {
        header: () => t(ETLCodes.Examen),
        fieldName: "exa"
      },
      {
        header: () => t(ETLCodes.Categorie),
        fieldName: "categorie"
      },
      {
        header: () => t(ETLCodes.Deliberation),
        fieldName: "iddeliberationType",
        customizeCellStyle: () =>
          css`
            width: 250px;
          `,
        render: (row: FcbResultatTypeGridDto) => {
          if (row.idclassematiere > 0) {
            return (
              <TableSelectEditor
                idBulletinInscriptionMatiere={row.idbulletininscriptionmatiere}
                initialValue={row.iddeliberationType}
                secondeSess={false}
                editable={false}
                delibs={{ props: dMatiere, loading: dMatiereLoading }}
              />
            );
          }
        }
      },
      {
        header: () => t(ETLCodes.RCons),
        fieldName: "remediationconseil",
        autoFitContent: true,
        alignment: "center",
        render: (row: FcbResultatTypeGridDto) => {
          return <Checkbox checked={row.remediationconseil} disabled />;
        }
      },
      {
        header: () => t(ETLCodes.ROb),
        fieldName: "remediationobligatoire",
        autoFitContent: true,
        alignment: "center",
        render: (row: FcbResultatTypeGridDto) => {
          return <Checkbox checked={row.remediationobligatoire} disabled />;
        }
      },
      {
        header: () => t(ETLCodes.DeuxiemeSession),
        fieldName: "date2sess",
        customizeCellStyle: () =>
          css`
            width: 150px;
          `
      },
      {
        header: () => t(ETLCodes.TotalMax),
        fieldName: "totalmax"
      }
    ],
    [dMatiere, dMatiereLoading, t]
  );

  const getRowStyles = React.useCallback((item: FcbResultatTypeGridDto) => {
    if (!item.total) return null;
    const total = item.total.split("/")[0];
    const max = item.total.split("/")[1];
    const reussite = item.reussite ?? 50;
    return css`
      & * {
        color: ${reussite > (+total / +max) * 100 ? Colors.RED3 + " !important" : null};
      }
    `;
  }, []);

  return (
    <>
      {idbulletinApprenantType > 0 && (
        <>
          <DataTable
            dateFormat="dd-MM-yyyy"
            tableState={tableState}
            loading={loading}
            columns={columns}
            customizeRowStyle={getRowStyles}
            keyFieldName="idbulletininscriptionmatiere"
            onOpenSubComponent={(item: FcbResultatTypeGridDto) => (
              <DeliberationBulletinEvaluationGrid data={item.evaluations}></DeliberationBulletinEvaluationGrid>
            )}
          />
          <Divider />
          <DeliberationBulletinCaterorySumGrid deliberation1SessResults={tableState.data} loading={loading} />
        </>
      )}
    </>
  );
};
