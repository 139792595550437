/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileUploadDto } from './FileUploadDto';
import {
    FileUploadDtoFromJSON,
    FileUploadDtoFromJSONTyped,
    FileUploadDtoToJSON,
} from './FileUploadDto';

/**
 * 
 * @export
 * @interface LieuFormationAgrementVisiteMultipleDto
 */
export interface LieuFormationAgrementVisiteMultipleDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof LieuFormationAgrementVisiteMultipleDto
     */
    idslieuFormationAgrement?: Array<number> | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementVisiteMultipleDto
     */
    dateVisite?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationAgrementVisiteMultipleDto
     */
    pratiqueSimulee?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationAgrementVisiteMultipleDto
     */
    compteRendu?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementVisiteMultipleDto
     */
    idfichierCompteRendu?: number | null;
    /**
     * 
     * @type {FileUploadDto}
     * @memberof LieuFormationAgrementVisiteMultipleDto
     */
    fichierCompteRendu?: FileUploadDto;
}

/**
 * Check if a given object implements the LieuFormationAgrementVisiteMultipleDto interface.
 */
export function instanceOfLieuFormationAgrementVisiteMultipleDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LieuFormationAgrementVisiteMultipleDtoFromJSON(json: any): LieuFormationAgrementVisiteMultipleDto {
    return LieuFormationAgrementVisiteMultipleDtoFromJSONTyped(json, false);
}

export function LieuFormationAgrementVisiteMultipleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LieuFormationAgrementVisiteMultipleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idslieuFormationAgrement': !exists(json, 'idslieuFormationAgrement') ? undefined : json['idslieuFormationAgrement'],
        'dateVisite': !exists(json, 'dateVisite') ? undefined : (json['dateVisite'] === null ? null : new Date(json['dateVisite'])),
        'pratiqueSimulee': !exists(json, 'pratiqueSimulee') ? undefined : json['pratiqueSimulee'],
        'compteRendu': !exists(json, 'compteRendu') ? undefined : json['compteRendu'],
        'idfichierCompteRendu': !exists(json, 'idfichierCompteRendu') ? undefined : json['idfichierCompteRendu'],
        'fichierCompteRendu': !exists(json, 'fichierCompteRendu') ? undefined : FileUploadDtoFromJSON(json['fichierCompteRendu']),
    };
}

export function LieuFormationAgrementVisiteMultipleDtoToJSON(value?: LieuFormationAgrementVisiteMultipleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idslieuFormationAgrement': value.idslieuFormationAgrement,
        'dateVisite': value.dateVisite === undefined ? undefined : (value.dateVisite === null ? null : value.dateVisite.toISOString()),
        'pratiqueSimulee': value.pratiqueSimulee,
        'compteRendu': value.compteRendu,
        'idfichierCompteRendu': value.idfichierCompteRendu,
        'fichierCompteRendu': FileUploadDtoToJSON(value.fichierCompteRendu),
    };
}

