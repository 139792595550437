import * as React from "react";
import { matchPath, useLocation, useParams } from "react-router-dom";

import { SiegeSocialContact, SiegeSocialContactDoublons, SiegeSocialContactListPage } from ".";
import { ContactSiegeDoublonSearchFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";

export interface ISiegeSocialContactSwitchProps { }

export const SiegeSocialContactSwitch: React.FunctionComponent<ISiegeSocialContactSwitchProps> = props => {
  const [sObj, setSObj] = React.useState(ContactSiegeDoublonSearchFromJSON({}));
  const { pathname } = useLocation();
  const { id } = useParams<{ id: string; tab: string; state: string }>();
  if (
    matchPath(pathname, {
      path: `${ERoutes.siegeSocial}/${id}${ERoutes.contacts}/:state/:subId`
    })
  ) {
    return <SiegeSocialContact baseData={sObj} />;
  } else if (
    matchPath(pathname, {
      path: `${ERoutes.siegeSocial}/${id}${ERoutes.contacts}/checkDoublons`
    })
  ) {
    return <SiegeSocialContactDoublons setBaseData={setSObj} />;
  } else {
    return <SiegeSocialContactListPage />;
  }
};
