import { FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { AnneeReussieApi, FcbAnneeReussieDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterCheckboxInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IDerniereAnneeReussieDetailPageProps {
  idAnnee: number;
  editMode: boolean;
}

export const DerniereAnneeReussieDetailPage: React.FunctionComponent<IDerniereAnneeReussieDetailPageProps> = ({
  idAnnee,
  editMode
}) => {
  const { t } = useTl();
  const api = useApiService(AnneeReussieApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idAnnee <= 0 ? FcbAnneeReussieDtoFromJSON({ idAnnee: 0 }) : api.anneeReussieGet({ id: +idAnnee }),
    saveApiFn: d => api.anneeReussieSave({ FcbAnneeReussieDto: d }),
    onSavedRoute: d => `${ERoutes.anneeReussie}/${d.idanneeReussie}/detail/edit`,
    deleteApiFn: d => api.anneeReussieDelete({ id: d.idanneeReussie }),
    onDeletedRoute: () => ERoutes.anneeReussie
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      libelle: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={editMode}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.anneeReussie)}
      onDelete={deleteItem}
      showDeleteButton={+idAnnee > 0}
      saving={saving}
      deleting={deleting}
    >
      <FieldGroup>
        <FGTextInput name="libelle" label={t(ETLCodes.Libelle)} maxLength={75} />
        <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
