/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRepresentantApprenantsliesDto
 */
export interface FcbRepresentantApprenantsliesDto {
    /**
     * 
     * @type {number}
     * @memberof FcbRepresentantApprenantsliesDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbRepresentantApprenantsliesDto
     */
    idRepresentant?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantApprenantsliesDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantApprenantsliesDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRepresentantApprenantsliesDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRepresentantApprenantsliesDto
     */
    lienParente?: string | null;
}

/**
 * Check if a given object implements the FcbRepresentantApprenantsliesDto interface.
 */
export function instanceOfFcbRepresentantApprenantsliesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRepresentantApprenantsliesDtoFromJSON(json: any): FcbRepresentantApprenantsliesDto {
    return FcbRepresentantApprenantsliesDtoFromJSONTyped(json, false);
}

export function FcbRepresentantApprenantsliesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRepresentantApprenantsliesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idRepresentant': !exists(json, 'idRepresentant') ? undefined : json['idRepresentant'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'lienParente': !exists(json, 'lienParente') ? undefined : json['lienParente'],
    };
}

export function FcbRepresentantApprenantsliesDtoToJSON(value?: FcbRepresentantApprenantsliesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idapprenant': value.idapprenant,
        'idRepresentant': value.idRepresentant,
        'nom': value.nom,
        'prenom': value.prenom,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'lienParente': value.lienParente,
    };
}

