/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbApprenantAzureGridDto
 */
export interface FcbApprenantAzureGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantAzureGridDto
     */
    idApprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantAzureGridDto
     */
    idCentre?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantAzureGridDto
     */
    centre?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbApprenantAzureGridDto
     */
    azureActive?: boolean;
}

/**
 * Check if a given object implements the FcbApprenantAzureGridDto interface.
 */
export function instanceOfFcbApprenantAzureGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbApprenantAzureGridDtoFromJSON(json: any): FcbApprenantAzureGridDto {
    return FcbApprenantAzureGridDtoFromJSONTyped(json, false);
}

export function FcbApprenantAzureGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbApprenantAzureGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idApprenant': !exists(json, 'idApprenant') ? undefined : json['idApprenant'],
        'idCentre': !exists(json, 'idCentre') ? undefined : json['idCentre'],
        'centre': !exists(json, 'centre') ? undefined : json['centre'],
        'azureActive': !exists(json, 'azureActive') ? undefined : json['azureActive'],
    };
}

export function FcbApprenantAzureGridDtoToJSON(value?: FcbApprenantAzureGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idApprenant': value.idApprenant,
        'idCentre': value.idCentre,
        'centre': value.centre,
        'azureActive': value.azureActive,
    };
}

