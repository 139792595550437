/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbFormateurPrestationsGlobalesGridDto
 */
export interface FcbFormateurPrestationsGlobalesGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurPrestationsGlobalesGridDto
     */
    idformateur?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurPrestationsGlobalesGridDto
     */
    moisorder?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurPrestationsGlobalesGridDto
     */
    nbHeuresCours?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurPrestationsGlobalesGridDto
     */
    nbHeuresAPayer?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurPrestationsGlobalesGridDto
     */
    nbHeuresRemplaceesPayees?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurPrestationsGlobalesGridDto
     */
    nbHeuresRemplaceesNonPayees?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurPrestationsGlobalesGridDto
     */
    nbRemplacants?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurPrestationsGlobalesGridDto
     */
    annee?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurPrestationsGlobalesGridDto
     */
    mois?: string | null;
}

/**
 * Check if a given object implements the FcbFormateurPrestationsGlobalesGridDto interface.
 */
export function instanceOfFcbFormateurPrestationsGlobalesGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbFormateurPrestationsGlobalesGridDtoFromJSON(json: any): FcbFormateurPrestationsGlobalesGridDto {
    return FcbFormateurPrestationsGlobalesGridDtoFromJSONTyped(json, false);
}

export function FcbFormateurPrestationsGlobalesGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbFormateurPrestationsGlobalesGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'moisorder': !exists(json, 'moisorder') ? undefined : json['moisorder'],
        'nbHeuresCours': !exists(json, 'nbHeuresCours') ? undefined : json['nbHeuresCours'],
        'nbHeuresAPayer': !exists(json, 'nbHeuresAPayer') ? undefined : json['nbHeuresAPayer'],
        'nbHeuresRemplaceesPayees': !exists(json, 'nbHeuresRemplaceesPayees') ? undefined : json['nbHeuresRemplaceesPayees'],
        'nbHeuresRemplaceesNonPayees': !exists(json, 'nbHeuresRemplaceesNonPayees') ? undefined : json['nbHeuresRemplaceesNonPayees'],
        'nbRemplacants': !exists(json, 'nbRemplacants') ? undefined : json['nbRemplacants'],
        'annee': !exists(json, 'annee') ? undefined : json['annee'],
        'mois': !exists(json, 'mois') ? undefined : json['mois'],
    };
}

export function FcbFormateurPrestationsGlobalesGridDtoToJSON(value?: FcbFormateurPrestationsGlobalesGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idformateur': value.idformateur,
        'moisorder': value.moisorder,
        'nbHeuresCours': value.nbHeuresCours,
        'nbHeuresAPayer': value.nbHeuresAPayer,
        'nbHeuresRemplaceesPayees': value.nbHeuresRemplaceesPayees,
        'nbHeuresRemplaceesNonPayees': value.nbHeuresRemplaceesNonPayees,
        'nbRemplacants': value.nbRemplacants,
        'annee': value.annee,
        'mois': value.mois,
    };
}

