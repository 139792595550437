import { Card, Dialog, Icon, NonIdealState, Spinner } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useFGContext } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { AdresseInfos } from ".";
import { AddButton } from "../..";
import { AdresseDto, ReferentialApi } from "../../../api";
import { useEventsContext } from "../../../contexts";
import { useApiService, useTheme, useTl } from "../../../hooks";
import { ETLCodes } from "../../../locales";

const StyledDialog = styled(Dialog)`
  width: 670px;
  height: auto;
  background-color: white;
`;

const AdresseListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px 20px 0 20px;
`;

const AdresseContainer = styled(Card)`
  display: flex;
  justify-content: space-between;
  width: 200px;
`;

const StyledNonIdealState = styled(NonIdealState)`
  margin-top: 1rem;
`;

export interface IPersonneAdresseSelectorDialogProps {
  onClosed: () => void;
  onAdresseSelected: (adresse: AdresseDto) => void;
  selectorDialogOpen: boolean;
  idpersonne: number;
}

export const PersonneAdresseSelectorDialog: React.FunctionComponent<IPersonneAdresseSelectorDialogProps> = ({
  onAdresseSelected,
  onClosed,
  selectorDialogOpen,
  idpersonne
}) => {
  const { t } = useTl();
  const { theme } = useTheme();
  const ctx = useFGContext();
  const api = useApiService(ReferentialApi);
  const { data: personneEmails, isFetching: personneEmailsLoading, refetch } = useQuery(
    ["adresses-personnes", idpersonne],
    async () => {
      return api.referentialGetAdressesPersonne({ idpersonne });
    }
  );

  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();
  React.useEffect(() => {
    subscribeToEvent("REFERENTIAL_REFRESH", refetch);
    return () => {
      unsubscribeEvent("REFERENTIAL_REFRESH", refetch);
    };
  }, [refetch, subscribeToEvent, unsubscribeEvent]);

  const selectedIdadresse = React.useMemo(() => ctx?.formik?.values?.idadresse, [ctx?.formik?.values?.idadresse]);

  return (
    <StyledDialog title={t(ETLCodes.SelectAdresse)} isOpen={selectorDialogOpen} onClose={onClosed}>
      {personneEmailsLoading ? (
        <Spinner />
      ) : personneEmails.list.length > 0 ? (
        <AdresseListContainer>
          {personneEmails.list.map(a => (
            <AdresseContainer interactive onClick={() => onAdresseSelected(a)}>
              <AdresseInfos data={a} />
              {a.idadresse === selectedIdadresse && <Icon icon={IconNames.CONFIRM} color={theme.primaryColor} />}
            </AdresseContainer>
          ))}
        </AdresseListContainer>
      ) : (
        <StyledNonIdealState
          title={t(ETLCodes.NoAdresseInPersonne)}
          description={t(ETLCodes.PleaseCreateAdresse)}
          action={<AddButton onClick={() => alert(t(ETLCodes.StillToDev))} text={t(ETLCodes.CreateAdresse)} />}
        />
      )}
    </StyledDialog>
  );
};
