export enum ModulesEnum {
  pays = 1,
  classe = 2,
  centres = 3,
  metier = 5,
  conseillerPedagogique = 6,
  titrePedagogique = 7,
  lieuPermanence = 8,
  directionTerritoriale = 9,
  ecole = 10,
  anneeReussie = 11,
  typeEnseignement = 12,
  statutSocial = 13,
  codepostal = 14,
  users = 15,
  secteurClasseHomogene = 17,
  contratFormateur = 18,
  clause = 19,
  serviceTutelle = 20,
  diplome = 21,
  delegueTutelle = 22,
  representant = 23,
  visiteExterieure = 24,
  professionFormateur = 25,
  secteurMetier = 26,
  typeCours = 27,
  apprenant = 29,
  referentiel = 30,
  remplacement = 31,
  formateur = 32,
  degre = 33,
  specificiteCours = 34,
  calendrier = 35,
  evaluation = 36,
  typeFinancement = 37,
  projetFinancement = 38,
  LocalisationClasse = 39,
  agrementClasse = 40,
  typeEvaluation = 41,
  export = 42,
  gestionDroits = 43,
  local = 44,
  incorporation = 45,
  impression = 46,
  inscription = 47,
  entreprise = 48,
  feuillePresence = 49,
  deliberation = 50,
  exportGap = 51,
  doublon = 52,
  apprenantInscription = 53,
  doublonApprenant = 54,
  champCritique = 55,
  inscriptionMasse = 58,
  validationCE = 59,
  validationMasse = 60,
  sms = 62,
  smsDestinataire = 63,
  exportExcel = 1000,
  centreGlobal = 1002,
  forfaitAuditeur = 1003,
  uniteActivite = 1004,
  apprenantInscriptionIncorporation = 1006,
  beid = 1007,
  masseMiseAJourClasse = 1008,
  apprenantBeid = 1009,
  formateurBeid = 1010,
  deliberationMasse = 1011,
  typeDeliberation = 1012
}
