import { getIn } from "formik";
import { useFGContext } from "nsitools-react";
import * as React from "react";
import { FGWalterSelectInput, IFGWalterSelectInputProps } from "..";
import { useTl } from "../../hooks";
import { useReferential } from "../../hooks/useReferential";
import { ETLCodes } from "../../locales";

export interface IContratMotifRuptureJeuneSelectProps<T>
  extends Omit<IFGWalterSelectInputProps<T>, "items" | "loading"> {}

export function ContratMotifRuptureJeuneSelect<T>(props: IContratMotifRuptureJeuneSelectProps<T>) {
  const { t } = useTl();
  const { formik } = useFGContext();

  const value = React.useMemo(() => formik?.values && getIn(formik?.values, props.name), [formik?.values, props.name]);
  const [motifsJeune, mjLoading] = useReferential(a => a.referentialGetMotifsRuptureJeune({ selectedId: value ?? 0 }));

  return (
    <FGWalterSelectInput
      name={props.name}
      label={props.label ?? t(ETLCodes.MotifJeune)}
      items={motifsJeune}
      loading={mjLoading}
    />
  );
}

export default ContratMotifRuptureJeuneSelect;
