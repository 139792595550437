import * as React from "react";
import { InputGroup, Tooltip } from "@blueprintjs/core";
import { ETLCodes } from "../../locales";
import { useTl } from "../../hooks";
import styled from "styled-components";

export interface ITableTextEditorProps {
  initialTextValue: string;
  onValueChanged: (value: string) => void;
  width?: number;
  inputHeight?: React.ReactText;
  textHeight?: React.ReactText;
  maxLength?: number;
  authorizedValues?: string[];
  tabIndex?: number;
  editable?: boolean;
}

const StyledTooltip = styled(Tooltip)`
  display: flex;
  & > * {
    flex: 1;
  }
`;

export const TableTextEditor: React.FunctionComponent<ITableTextEditorProps> = ({
  initialTextValue,
  onValueChanged,
  width,
  inputHeight,
  textHeight = 30,
  maxLength,
  authorizedValues,
  tabIndex,
  editable
}) => {
  const { t } = useTl();
  const [currentValue, setCurrentValue] = React.useState<string>();
  const [editedValue, setEditedValue] = React.useState<string>();
  const [focused, setFocused] = React.useState(false);
  const [customError, setCustomError] = React.useState(null);

  React.useEffect(() => {
    setCurrentValue(initialTextValue);
    setEditedValue(initialTextValue);
  }, [initialTextValue]);

  const onBlur = React.useCallback(async () => {
    let val = editedValue;
    if (val.startsWith("@") && val.length > 1) {
      val = val.replace("@", "");
      setEditedValue(val);
    }
    if (!authorizedValues || authorizedValues.includes(val) || val === currentValue) {
      if (val !== currentValue) {
        setCurrentValue(val);
        onValueChanged(val);
      }
      setCustomError(null);
      setFocused(false);
    } else if (!authorizedValues.includes(val)) {
      setCustomError(t(ETLCodes.ValueNotPartOfAuthorizedOnes, { values: authorizedValues.join(", ") }));
    }
  }, [authorizedValues, currentValue, editedValue, onValueChanged, t]);

  const computeFocus = React.useCallback(() => {
    if (!focused) {
      setFocused(true);
    }
  }, [focused]);

  return (
    <>
      <StyledTooltip content={customError} position="bottom" isOpen={!!customError}>
        {focused && editable ? (
          <InputGroup
            autoFocus
            style={{ borderRadius: "none" }}
            value={editedValue}
            onChange={e => setEditedValue(e.target.value.toUpperCase())}
            onBlur={onBlur}
            onKeyUp={e => (e.keyCode === 13 ? onBlur() : () => {})}
            width={width}
            height={inputHeight}
            maxLength={maxLength}
            tabIndex={tabIndex}
          ></InputGroup>
        ) : (
          <div
            style={{
              marginLeft: 9,
              marginTop: 4,
              width: width,
              height: textHeight
            }}
            onClick={() => computeFocus()}
            tabIndex={tabIndex}
            onFocus={() => computeFocus()}
          >
            {editedValue}
          </div>
        )}
      </StyledTooltip>
    </>
  );
};
