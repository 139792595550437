/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RapportHopeGridDto
 */
export interface RapportHopeGridDto {
    /**
     * 
     * @type {number}
     * @memberof RapportHopeGridDto
     */
    idrapportHope?: number;
    /**
     * 
     * @type {string}
     * @memberof RapportHopeGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RapportHopeGridDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RapportHopeGridDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof RapportHopeGridDto
     */
    path?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RapportHopeGridDto
     */
    idrapportHopeType?: number;
    /**
     * 
     * @type {string}
     * @memberof RapportHopeGridDto
     */
    rapportHopeType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RapportHopeGridDto
     */
    categorie?: string | null;
}

/**
 * Check if a given object implements the RapportHopeGridDto interface.
 */
export function instanceOfRapportHopeGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RapportHopeGridDtoFromJSON(json: any): RapportHopeGridDto {
    return RapportHopeGridDtoFromJSONTyped(json, false);
}

export function RapportHopeGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RapportHopeGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idrapportHope': !exists(json, 'idrapportHope') ? undefined : json['idrapportHope'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'idrapportHopeType': !exists(json, 'idrapportHopeType') ? undefined : json['idrapportHopeType'],
        'rapportHopeType': !exists(json, 'rapportHopeType') ? undefined : json['rapportHopeType'],
        'categorie': !exists(json, 'categorie') ? undefined : json['categorie'],
    };
}

export function RapportHopeGridDtoToJSON(value?: RapportHopeGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idrapportHope': value.idrapportHope,
        'nom': value.nom,
        'description': value.description,
        'actif': value.actif,
        'path': value.path,
        'idrapportHopeType': value.idrapportHopeType,
        'rapportHopeType': value.rapportHopeType,
        'categorie': value.categorie,
    };
}

