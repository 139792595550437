import { Colors } from "@blueprintjs/core";

import { EStatutContrat } from "../api/models/EStatutContrat";
import { IThemeData } from "../theme";

export const computeContratStatutColor = (statut: EStatutContrat, theme: IThemeData) => {
  if (!statut) return Colors.BLACK;
  switch (statut) {
    case EStatutContrat.EnCours:
      return theme.sucessColor;
    case EStatutContrat.Rompu:
      return theme.dangerColor;
    case EStatutContrat.Suspendu:
      return theme.warningColor;
    case EStatutContrat.Termine:
      return Colors.VIOLET4;
    case EStatutContrat.SansSuite:
      return null;
  }
};
