import { Checkbox, Spinner } from "@blueprintjs/core";
import { ButtonContainer, IDataTableColumn, showError, showInfo, showSuccess } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";

import { ETypeDestinataireSms, FcbFormateurGridDto, FormateurApi, FormateurSearch, ReportApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { EditButton, EmailButton, PrintButton, SearchTablePage, SmsButton, ViewButton } from "../../../../components";
import { usePrintingQueueContext, useRouteParamsContext } from "../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useManageError, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface IFormateurListPageProps {}

export const FormateurListPage: React.FunctionComponent<IFormateurListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(FormateurApi);
  const [selectedIDs, setSelectedIDs] = React.useState<number[]>([]);
  const lastSearchObject = React.useRef<FormateurSearch>();
  const { pushSmsRecipientIds, pushSmsRecipientType } = useRouteParamsContext();
  const [selectAllLoading, setSelectAllLoading] = React.useState(false);
  const [nbReturnedIds, setNbReturnedIds] = React.useState(0);
  const [printLoadingId, setPrintLoadingId] = React.useState(null);
  const reportApi = useApiService(ReportApi);
  const { refreshQueue, setIsQueueForcedOpen } = usePrintingQueueContext();
  const { manageError } = useManageError();

  const apiFactory = useAbortableApiServiceFactory(FormateurApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (nextSearch?: FormateurSearch) => {
      if (JSON.stringify(nextSearch) !== JSON.stringify(lastSearchObject.current)) {
        lastSearchObject.current = nextSearch;
      }
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.formateurBaseSearch({ FormateurSearch: nextSearch });
    },
    [apiFactory]
  );

  const selectAll = React.useCallback(() => {
    setSelectAllLoading(true);
    api
      .formateurSearchFormateurIds({
        FormateurSearch: { ...lastSearchObject.current, forceSkip: 0, forceTake: 999999 }
      })
      .then(allIds => {
        if (allIds.length === selectedIDs.length) {
          setSelectedIDs([]);
          setNbReturnedIds(0);
        } else {
          setSelectedIDs(allIds);
          setNbReturnedIds(allIds.length);
        }
        setSelectAllLoading(false);
      });
  }, [api, selectedIDs.length]);

  const toggleSelection = React.useCallback(
    (item: FcbFormateurGridDto) => {
      if (!selectedIDs.includes(item.idformateur)) {
        setSelectedIDs(ids => [...ids, item.idformateur]);
      } else {
        setSelectedIDs(ids => ids.filter(id => id !== item.idformateur));
      }
    },
    [selectedIDs]
  );

  const sendSms = React.useCallback(() => {
    if (selectedIDs.length > 0) {
      pushSmsRecipientIds(selectedIDs);
      pushSmsRecipientType(ETypeDestinataireSms.Etudiant);
      history.push(`${ERoutes.sms}/0/detail/edit`);
    } else {
      showError(t(ETLCodes.SelectAtLeastOne));
    }
  }, [history, pushSmsRecipientIds, pushSmsRecipientType, selectedIDs, t]);

  const sendSmsSingle = React.useCallback(
    (id: number) => {
      pushSmsRecipientIds([id]);
      pushSmsRecipientType(ETypeDestinataireSms.Etudiant);
      history.push(`${ERoutes.sms}/0/detail/edit`);
    },
    [history, pushSmsRecipientIds, pushSmsRecipientType]
  );

  const print = React.useCallback(
    async (idFormateur: number) => {
      setPrintLoadingId(idFormateur);
      try {
        showInfo(t(ETLCodes.AddingReportToGenerationQueue));
        await reportApi.reportSendToQueue({
          reportName: "horaireformateurmain2jp.rpt",
          ReportParametersDto: {
            parameters: [
              {
                dataType: "NumberParameter",
                name: "idformateur",
                value: idFormateur.toString()
              }
            ]
          }
        });
        refreshQueue();
        setIsQueueForcedOpen(true);
        showSuccess(t(ETLCodes.ReportAddedToQueue));
      } catch (e) {
        console.error(e);
        manageError(e, t(ETLCodes.ErrorWhileAddingReportToQueue));
      } finally {
        setPrintLoadingId(null);
      }
    },
    [manageError, refreshQueue, reportApi, setIsQueueForcedOpen, t]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        header: () =>
          selectAllLoading ? (
            <Spinner size={20} />
          ) : (
            <Checkbox checked={nbReturnedIds > 0 && nbReturnedIds === selectedIDs.length} onChange={selectAll} />
          ),
        render: (item: FcbFormateurGridDto) => (
          <Checkbox
            checked={selectedIDs.includes(item.idformateur)}
            onChange={e => {
              toggleSelection(item);
            }}
          />
        )
      },
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbFormateurGridDto) => (
          <ButtonContainer>
            <PrintButton
              minimal={true}
              onClick={() => print(row.idformateur)}
              loading={printLoadingId && printLoadingId === row.idformateur}
            />
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.formateur}/${row.idformateur}/signaletique/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.formateur}/${row.idformateur}/signaletique/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.Actif),
        fieldName: "actif",
        autoFitContent: true,
        alignment: "center",
        render: (value: FcbFormateurGridDto) => <Checkbox checked={value.actif} disabled />
      },
      {
        header: () => t(ETLCodes.LocaliteCentre),
        fieldName: "localiteCentre",
        disableSorting: true
      },
      {
        header: () => t(ETLCodes.Contrat),
        fieldName: "contrat",
        disableSorting: true
      },
      {
        header: () => t(ETLCodes.Gsm),
        fieldName: "gsm",
        autoFitContent: true,
        render: (value: FcbFormateurGridDto) => (
          <SmsButton phone={value.gsm} onClick={() => sendSmsSingle(value.idformateur)} disabled={!!value.dateDeces} />
        )
      },
      {
        header: () => t(ETLCodes.GsmPro),
        fieldName: "gsmProf",
        autoFitContent: true,
        render: (value: FcbFormateurGridDto) => <SmsButton phone={value.gsmProf} disabled={!!value.dateDeces} />
      },
      {
        header: () => t(ETLCodes.Telephone),
        fieldName: "telephone"
      },
      {
        header: () => t(ETLCodes.TelPro),
        fieldName: "telProf"
      },
      {
        header: () => t(ETLCodes.Email),
        autoFitContent: true,
        alignment: "center",
        fieldName: "email",
        render: (value: FcbFormateurGridDto) => <EmailButton email={value.email} disabled={!!value.dateDeces} />
      },
      {
        header: () => t(ETLCodes.EmailPro),
        autoFitContent: true,
        alignment: "center",
        fieldName: "emailProf",
        render: (value: FcbFormateurGridDto) => <EmailButton email={value.emailProf} disabled={!!value.dateDeces} />
      }
    ],
    [
      history,
      nbReturnedIds,
      print,
      printLoadingId,
      selectAll,
      selectAllLoading,
      selectedIDs,
      sendSmsSingle,
      t,
      toggleSelection
    ]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.formateur}/0/signaletique/doublon`);
  }, [history]);

  const getCriteria = React.useCallback(() => api.formateurGetSearchCriterias({ includeListsValues: true }), [api]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <SearchTablePage
        columns={columns}
        getCriteriasFunction={getCriteria}
        searchFunction={search}
        onAbort={onAbort}
        addFunc={addItemFunction}
        searchStateInitialSearch={false}
        defaultCriterias={[
          {
            criteria: "Actif",
            searchMode: "Equals",
            value: true
          }
        ]}
        breadCrumbs={[{ text: t(ETLCodes.Formateurs) }]}
        sortKeys={{ nom: "ASC" }}
        buttons={[
          {
            icon: "chat",
            text: t(ETLCodes.SendAnSMSToSelected),
            onClick: sendSms,
            disabled: true
          }
        ]}
      />
    </>
  );
};
