/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRemplacementDto
 */
export interface FcbRemplacementDto {
    /**
     * 
     * @type {number}
     * @memberof FcbRemplacementDto
     */
    idremplacement?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbRemplacementDto
     */
    idcentre?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbRemplacementDto
     */
    idremplace?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbRemplacementDto
     */
    idtypeRemplacement?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbRemplacementDto
     */
    idremplacant?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRemplacementDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRemplacementDto
     */
    dateFin?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRemplacementDto
     */
    raison?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRemplacementDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRemplacementDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbRemplacementDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRemplacementDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbRemplacementDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRemplacementDto
     */
    idremplacementLegitimite?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRemplacementDto
     */
    idformateurLocalisationContrat?: number | null;
}

/**
 * Check if a given object implements the FcbRemplacementDto interface.
 */
export function instanceOfFcbRemplacementDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRemplacementDtoFromJSON(json: any): FcbRemplacementDto {
    return FcbRemplacementDtoFromJSONTyped(json, false);
}

export function FcbRemplacementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRemplacementDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idremplacement': !exists(json, 'idremplacement') ? undefined : json['idremplacement'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'idremplace': !exists(json, 'idremplace') ? undefined : json['idremplace'],
        'idtypeRemplacement': !exists(json, 'idtypeRemplacement') ? undefined : json['idtypeRemplacement'],
        'idremplacant': !exists(json, 'idremplacant') ? undefined : json['idremplacant'],
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
        'raison': !exists(json, 'raison') ? undefined : json['raison'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'idremplacementLegitimite': !exists(json, 'idremplacementLegitimite') ? undefined : json['idremplacementLegitimite'],
        'idformateurLocalisationContrat': !exists(json, 'idformateurLocalisationContrat') ? undefined : json['idformateurLocalisationContrat'],
    };
}

export function FcbRemplacementDtoToJSON(value?: FcbRemplacementDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idremplacement': value.idremplacement,
        'idcentre': value.idcentre,
        'idremplace': value.idremplace,
        'idtypeRemplacement': value.idtypeRemplacement,
        'idremplacant': value.idremplacant,
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
        'raison': value.raison,
        'remarque': value.remarque,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'idremplacementLegitimite': value.idremplacementLegitimite,
        'idformateurLocalisationContrat': value.idformateurLocalisationContrat,
    };
}

