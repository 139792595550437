import { FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { DiplomeApi, FcbDiplomeDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { SmallFormGenerator, FGWalterCheckboxInput } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IDiplomeDetailPageProps {
  editMode: boolean;
  idDiplome: number;
}

export const DiplomeDetailPage: React.FunctionComponent<IDiplomeDetailPageProps> = ({ idDiplome, editMode }) => {
  const { t } = useTl();
  const api = useApiService(DiplomeApi);
  const history = useHistory();

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      libelle: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () => (+idDiplome <= 0 ? FcbDiplomeDtoFromJSON({ idDiplome: 0 }) : api.diplomeGet({ id: +idDiplome })),
    saveApiFn: d => api.diplomeSave({ FcbDiplomeDto: d }),
    onSavedRoute: d => `${ERoutes.diplome}/${d.iddiplome}/detail/edit`,
    deleteApiFn: d => api.diplomeDelete({ id: d.iddiplome }),
    onDeletedRoute: () => ERoutes.diplome
  });

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={editMode}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.diplome)}
      onDelete={deleteItem}
      showDeleteButton={+idDiplome > 0}
      saving={saving}
      deleting={deleting}
    >
      <FieldGroup>
        <FGTextInput name="libelle" label={t(ETLCodes.Libelle)} maxLength={75} />
        <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
