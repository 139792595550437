import { Button, Intent } from "@blueprintjs/core";
import { add, addMinutes, endOfDay, format, parseJSON } from "date-fns";
import {
  ButtonContainer,
  DataTable,
  FGCustomPanel,
  FGTextAreaInput,
  FGTextInput,
  FieldGroup,
  IDataTableColumn,
  showError,
  useFGFormik,
  useGridState
} from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router";
import * as Yup from "yup";

import { SiegeLieuSuiviActionDialog, SiegeLieuSuiviDocumentEditDialog, SiegeLieuSuiviDocumentUploadDialog } from ".";
import {
  DashboardApi,
  LieuFormationApi,
  SiegeLieuSuiviEditDto,
  SiegeLieuSuiviEditDtoFromJSON,
  SiegeLieuSuiviFichierApi,
  SiegeLieuSuiviFichierGridDto,
  SiegeSocialApi,
  SiegeSocialSuiviApi
} from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import {
  AddButton,
  DeleteButton,
  DownloadButton,
  EditButton,
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  LinkButton,
  SmallFormGenerator
} from "../../../../../../components";
import { useAuth, useDialog } from "../../../../../../contexts";
import { useApiService, useCrudApi, useTabMessage, useTheme, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";
import { exportFile } from "../../../../../../utils";
import { Guid } from "../../../../../../utils/guid";

export interface ISiegeLieuSuiviProps {
  idsuivi: number;
  idsiegeSocial: number;
  idlieuFormation?: number;
  editMode: boolean;
  backRoute: string;
}

export const SiegeLieuSuivi: React.FunctionComponent<ISiegeLieuSuiviProps> = ({
  editMode,
  idsiegeSocial,
  idsuivi,
  idlieuFormation,
  backRoute
}) => {
  const { t } = useTl();
  const api = useApiService(SiegeSocialSuiviApi);
  const siegeLieuApi = useApiService(SiegeLieuSuiviFichierApi);
  const siegeSocialApi = useApiService(SiegeSocialApi);
  const lieuFormationApi = useApiService(LieuFormationApi);
  const history = useHistory();
  const { search } = useLocation();
  const cfs = React.useMemo(() => new URLSearchParams(search).get("cfs"), [search]);
  const { messageValue, clearMessageValue } = useTabMessage(cfs);
  const identifier = React.useMemo(() => Guid.newGuid(), []);
  const { sendMessage } = useTabMessage(identifier);
  const { user } = useAuth();
  const { ifapmeSide } = useTheme();
  const isHope = React.useMemo(() => ifapmeSide === "hope", [ifapmeSide]);
  const [lieurencontres, lrLoading] = useReferential(a => a.referentialGetLieuRencontres(), true, []);
  const [modes, mLoading] = useReferential(a => a.referentialGetModes(), true, []);
  const [dialogOpen, setDialogOpen] = React.useState(messageValue === "FromDashboard");

  const fetchSDisplayName = React.useCallback(() => {
    return siegeSocialApi.siegeSocialGetDisplayName({ id: idsiegeSocial });
  }, [idsiegeSocial, siegeSocialApi]);
  const { data: sDisplayName } = useQuery(["siege-social-display-name", idsiegeSocial], fetchSDisplayName);

  const fetchLDisplayName = React.useCallback(() => {
    if (!idlieuFormation) return null;
    return lieuFormationApi.lieuFormationGetDisplayName({ id: idlieuFormation });
  }, [idlieuFormation, lieuFormationApi]);
  const { data: lDisplayName } = useQuery(["lieu-formation-display-name", idlieuFormation], fetchLDisplayName);

  const { data, saving, saveItem, deleting, deleteItem, loading, refresh: refreshData } = useCrudApi<
    SiegeLieuSuiviEditDto
  >(
    React.useMemo(
      () => ({
        getApiFn: () =>
          +idsuivi <= 0
            ? SiegeLieuSuiviEditDtoFromJSON({ iduserService: user.iduser, idsiegeSocial, idlieuFormation })
            : api.siegeSocialSuiviGetSuiviHope({ id: +idsuivi }),
        saveApiFn: values => api.siegeSocialSuiviSaveSuiviHope({ SiegeLieuSuiviEditDto: values }),
        deleteApiFn: values => api.siegeSocialSuiviDeleteSiegeLieuSuivi({ id: values.idsiegeSocialLieuFormationSuivi }),
        onDeletedRoute: d =>
          !!idlieuFormation
            ? `${ERoutes.lieuFormation}/${d.idlieuFormation}/suivi/edit?idsiegeSocial=${d.idsiegeSocial}`
            : `${ERoutes.siegeSocial}/${d.idsiegeSocial}/suivi`,
        onSaved: d => sendMessage(d.idsiegeSocialLieuFormationSuivi, true),
        onSavedRoute: d =>
          !!idlieuFormation
            ? `${ERoutes.lieuFormation}/${d.idlieuFormation}/suivi/edit/${d.idsiegeSocialLieuFormationSuivi}?idsiegeSocial=${d.idsiegeSocial}`
            : `${ERoutes.siegeSocial}/${d.idsiegeSocial}/suivi/edit/${d.idsiegeSocialLieuFormationSuivi}`
      }),
      [api, idlieuFormation, idsiegeSocial, idsuivi, sendMessage, user.iduser]
    )
  );

  React.useEffect(() => {
    if (!!messageValue && messageValue === "FromDashboard" && !!data && !loading) {
      setDialogOpen(true);
      clearMessageValue();
    }
  }, [clearMessageValue, data, loading, messageValue]);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      dateSuivi: Yup.date()
        .required(t(ETLCodes.Required))
        .max(endOfDay(add(new Date(), { years: 1 })), t(ETLCodes.DateCannotBeGreaterThan1YearInFurture)),
      idservice: Yup.number().required(t(ETLCodes.Required)),
      iduserService: Yup.number().required(t(ETLCodes.Required)),
      idsiegeSocial: Yup.number().required(t(ETLCodes.Required)),
      idtypeSuivi: Yup.number().required(t(ETLCodes.Required))
    });
  }, [t]);

  const formik = useFGFormik<SiegeLieuSuiviEditDto>({
    initialValues: data,
    onSubmit: saveItem,
    validationSchema: FormSchema
  });
  const [typeSuivis, tsLoading] = useReferential(
    a => a.referentialGetSiegeLieuTypesSuivi({ currentId: formik?.values?.idtypeSuivi }),
    true,
    [formik?.values?.idtypeSuivi]
  );
  const [serviceUsers, suLoading] = useReferential(
    a =>
      a.referentialGetServiceUsers({
        request_body: !!formik?.values?.idservice ? [formik?.values?.idservice] : [],
        currentId: formik?.values?.iduserService
      }),
    true,
    [formik?.values?.idservice, formik?.values?.iduserService]
  );

  const [services, sLoading] = useReferential(
    a =>
      a.referentialGetServiceTutelle({
        userFilter: !data?.creationUser || data?.creationUser === user.iduser,
        currentId: formik?.values?.idservice
      }),
    true,
    [data?.creationUser, user?.iduser, formik?.values?.idservice]
  );

  React.useEffect(() => {
    if (formik?.values?.action && !formik?.initialValues?.action) {
      setDialogOpen(true);
    }
  }, [formik?.initialValues?.action, formik?.values?.action]);

  React.useEffect(() => {
    if (!formik?.values?.action && !!formik?.values?.suiviAction) {
      formik.setFieldValue("suiviAction", null);
    }
  }, [formik]);

  const actionIsRealisee = React.useMemo(() => !!formik?.values?.suiviAction?.dateRealisation, [
    formik?.values?.suiviAction?.dateRealisation
  ]);

  const tableState = useGridState<any>({
    serverMode: false,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { uploadDate: "DESC", fileName: "ASC" }
  });

  const { setData } = tableState;

  React.useEffect(() => {
    if (data?.fichiers) {
      setData(data.fichiers);
    }
  }, [data?.fichiers, setData]);

  const [currentSuiviFichierId, setCurrentSuiviFichierId] = React.useState(null);
  const [uploadDialoOpen, setUploadDialoOpen] = React.useState(false);
  const [rowLoading, setRowLoading] = React.useState(null);
  const { showDialogPromise } = useDialog();
  const deleteFichier = React.useCallback(
    async (idsuiviFichier: number) => {
      const result = await showDialogPromise({
        message: t(ETLCodes.DeleteConfirmationMessage)
      });

      if (result === "yes") {
        try {
          setRowLoading(idsuiviFichier);
          await siegeLieuApi.siegeLieuSuiviFichierDeleteSuiviFichier({
            id: idsuiviFichier
          });
          refreshData();
        } catch {
          showError(t(ETLCodes.ErrorWhileDeletingFile));
        } finally {
          setRowLoading(null);
        }
      }
    },
    [refreshData, showDialogPromise, siegeLieuApi, t]
  );

  const downloadFichier = React.useCallback(
    async (idsuiviFichier: number) => {
      try {
        setRowLoading(idsuiviFichier);
        const file = await siegeLieuApi.siegeLieuSuiviFichierDownloadSuiviFichier({
          id: idsuiviFichier
        });
        await exportFile(file);
      } catch {
        showError(t(ETLCodes.ErrorWhileDownloadingFile));
      } finally {
        setRowLoading(null);
      }
    },
    [siegeLieuApi, t]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: SiegeLieuSuiviFichierGridDto) => (
          <ButtonContainer>
            <EditButton
              minimal={true}
              onClick={() => setCurrentSuiviFichierId(row.idsiegeLieuSuiviFichier)}
              loading={rowLoading === row.idsiegeLieuSuiviFichier}
              disabled={!!rowLoading && rowLoading !== row.idsiegeLieuSuiviFichier}
            />
            <DownloadButton
              minimal={true}
              onClick={() => downloadFichier(row.idsiegeLieuSuiviFichier)}
              loading={rowLoading === row.idsiegeLieuSuiviFichier}
              disabled={!!rowLoading && rowLoading !== row.idsiegeLieuSuiviFichier}
            />
            <DeleteButton
              minimal={true}
              onDelete={() => deleteFichier(row.idsiegeLieuSuiviFichier)}
              loading={rowLoading === row.idsiegeLieuSuiviFichier}
              disabled={!!rowLoading && rowLoading !== row.idsiegeLieuSuiviFichier}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.NomFichier),
        fieldName: "fileName"
      },
      {
        header: () => t(ETLCodes.DateUpload),
        fieldName: "uploadDate"
      },
      {
        header: () => t(ETLCodes.UtilisateurUpload),
        fieldName: "creationUserName"
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "typeSuiviFichier"
      }
    ],
    [deleteFichier, downloadFichier, rowLoading, t]
  );

  const formatDate = React.useCallback((date: Date) => {
    return format(parseJSON(addMinutes(date, date.getTimezoneOffset())), "dd/MM/yyyy HH:mm");
  }, []);

  const onClose = React.useCallback(
    (refresh?: boolean) => {
      setCurrentSuiviFichierId(null);
      setUploadDialoOpen(false);
      if (refresh) {
        refreshData();
      }
    },
    [refreshData]
  );

  const onAdd = React.useCallback(() => {
    setUploadDialoOpen(true);
  }, []);

  const contenuVisible = React.useCallback(
    ctx =>
      !ctx?.formik?.initialValues?.hiddenContent ||
      ctx?.formik?.values?.creationUser === user.iduser ||
      isHope !== user.ifapmeSide.includes("Hope"),
    [isHope, user.iduser, user.ifapmeSide]
  );

  const dashboardApi = useApiService(DashboardApi);
  const [loadingMarquage, setLoadingMarquage] = React.useState(false);
  const marquerCommeFait = React.useCallback(
    async (idsuiviSiegeLieu: number) => {
      const res = await showDialogPromise({
        title: t(ETLCodes.MarquerSuiviCommeFaitConfirmTitle),
        message: t(ETLCodes.MarquerSuiviCommeFaitConfirmMessage)
      });
      if (res === "yes") {
        setLoadingMarquage(true);
        await dashboardApi.dashboardMarquerSuiviSiegeLieuFait({
          idsuiviSiegeLieu
        });
        setLoadingMarquage(false);
        history.push(ERoutes.suiviSiegeLieu);
      }
    },
    [dashboardApi, history, showDialogPromise, t]
  );

  return (
    <SmallFormGenerator
      formik={formik}
      loading={loading}
      saving={saving}
      editMode={editMode}
      onDelete={deleteItem}
      deleting={deleting}
      showDeleteButton={+idsuivi > 0 && !actionIsRealisee}
      onCancel={() => history.push(backRoute)}
      minLabelWidth={150}
      editable={data?.creationUser === user.iduser || data?.iduserService === user.iduser || idsuivi <= 0}
      additionalRightButtons={ctx =>
        user?.iduser === ctx?.formik?.values?.suiviAction?.idcollaborateurService &&
        +idsuivi > 0 && (
          <Button
            text={t(ETLCodes.MarquerCommeFait)}
            intent="success"
            icon="tick"
            onClick={() => marquerCommeFait(ctx?.formik?.values?.idsiegeSocialLieuFormationSuivi)}
            loading={loadingMarquage}
          />
        )
      }
    >
      <FieldGroup columns={2}>
        <FieldGroup>
          <FGWalterSelectInput
            name="idservice"
            label={t(ETLCodes.Service)}
            items={services}
            loading={sLoading}
            autoSelectIfOne
            readonly={actionIsRealisee}
          />
          <FGWalterSelectInput
            name="iduserService"
            label={t(ETLCodes.RealisateurSuivi)}
            items={serviceUsers}
            loading={suLoading}
            readonly={actionIsRealisee}
          />
          <FGTextInput readonly name="encodeur" label={t(ETLCodes.Encodeur)} visible={+idsuivi > 0} />
          <FGWalterDateMaskInput name="dateSuivi" label={t(ETLCodes.DateSuivi)} readonly={actionIsRealisee} />
          <FGTextInput
            readonly
            name="siegeSocial"
            label={t(ETLCodes.SiegeSocial)}
            formatReadOnlyValue={() => sDisplayName}
          />
          {lDisplayName && (
            <FGTextInput
              readonly
              name="lieuFormation"
              label={t(ETLCodes.LieuFormation)}
              formatReadOnlyValue={() => lDisplayName}
            />
          )}
          <FGWalterSelectInput
            name="idtypeSuivi"
            label={t(ETLCodes.TypeSuivi)}
            items={typeSuivis}
            loading={tsLoading}
            readonly={actionIsRealisee}
          />
          <FGWalterSelectInput
            name="idmode"
            label={t(ETLCodes.Mode)}
            items={modes}
            loading={mLoading}
            readonly={actionIsRealisee}
          />
          <FGWalterSelectInput
            name="idlieuRencontre"
            label={t(ETLCodes.LieuRencontre)}
            items={lieurencontres}
            loading={lrLoading}
            readonly={actionIsRealisee}
          />
          <FGCustomPanel>
            {ctx => (
              <>
                <FGWalterCheckboxInput
                  label={t(ETLCodes.HiddenContent)}
                  name="hiddenContent"
                  visible={ctx?.formik?.values?.creationUser === user.iduser || idsuivi <= 0}
                />
                <FGWalterCheckboxInput
                  label={t(ETLCodes.Action)}
                  name="action"
                  readonly={actionIsRealisee}
                  helperText={
                    ctx?.formik?.values?.action &&
                    contenuVisible(ctx) && (
                      <LinkButton
                        text={ctx?.editMode && !actionIsRealisee ? t(ETLCodes.EditAction) : t(ETLCodes.ViewAction)}
                        onClick={() => {
                          setDialogOpen(true);
                        }}
                      />
                    )
                  }
                />
                <SiegeLieuSuiviActionDialog
                  dto={ctx?.formik?.values?.suiviAction}
                  isOpen={dialogOpen}
                  onClose={toSave => {
                    setDialogOpen(false);
                    if (toSave) {
                      ctx?.formik.setFieldValue("suiviAction", toSave);
                    } else if (!ctx?.formik?.values?.suiviAction) {
                      ctx.formik.setFieldValue("action", false);
                    }
                  }}
                  editMode={ctx?.editMode && !actionIsRealisee}
                  editable={
                    (data?.creationUser === user.iduser || data?.iduserService === user.iduser || idsuivi <= 0) &&
                    !actionIsRealisee
                  }
                />
              </>
            )}
          </FGCustomPanel>
        </FieldGroup>
        <FGCustomPanel>
          {ctx => (
            <FGTextAreaInput
              name="contenu"
              label={t(ETLCodes.Contenu)}
              growVertically
              rows={22}
              visible={contenuVisible(ctx)}
            />
          )}
        </FGCustomPanel>
      </FieldGroup>
      <FieldGroup
        visible={idsuivi > 0}
        fieldsetProps={{
          title: t(ETLCodes.Documents),
          rightElement: !actionIsRealisee && (
            <AddButton
              disabled={data?.creationUser !== user.iduser && data?.iduserService !== user.iduser}
              onClick={e => {
                e.stopPropagation();
                onAdd();
              }}
              text={t(ETLCodes.General_Add)}
              intent={Intent.PRIMARY}
            />
          )
        }}
      >
        <DataTable
          dateFormat="dd/MM/yyyy HH:mm"
          formatDate={formatDate}
          tableState={tableState}
          loading={loading}
          columns={columns}
        ></DataTable>
        {uploadDialoOpen && (
          <SiegeLieuSuiviDocumentUploadDialog onClose={onClose} dialogOpen={uploadDialoOpen} idsuivi={idsuivi} />
        )}
        {!!currentSuiviFichierId && (
          <SiegeLieuSuiviDocumentEditDialog onClose={onClose} idsiegeLieuSuiviFichier={currentSuiviFichierId} />
        )}
      </FieldGroup>
    </SmallFormGenerator>
  );
};
