/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EApprenantFichierDeletionReason } from './EApprenantFichierDeletionReason';
import {
    EApprenantFichierDeletionReasonFromJSON,
    EApprenantFichierDeletionReasonFromJSONTyped,
    EApprenantFichierDeletionReasonToJSON,
} from './EApprenantFichierDeletionReason';

/**
 * 
 * @export
 * @interface ApprenantFichierGridDto
 */
export interface ApprenantFichierGridDto {
    /**
     * 
     * @type {number}
     * @memberof ApprenantFichierGridDto
     */
    idapprenantFichier?: number;
    /**
     * 
     * @type {number}
     * @memberof ApprenantFichierGridDto
     */
    idtypeApprenantFichier?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantFichierGridDto
     */
    typeApprenantFichier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantFichierGridDto
     */
    typeApprenantFichierCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantFichierGridDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApprenantFichierGridDto
     */
    idinscription?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantFichierGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ApprenantFichierGridDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ApprenantFichierGridDto
     */
    dateFin?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantFichierGridDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantFichierGridDto
     */
    typeCours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantFichierGridDto
     */
    degre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantFichierGridDto
     */
    metier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantFichierGridDto
     */
    centre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantFichierGridDto
     */
    deliberation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantFichierGridDto
     */
    deliberation2Sess?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApprenantFichierGridDto
     */
    remis?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ApprenantFichierGridDto
     */
    uploadDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ApprenantFichierGridDto
     */
    deletionDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantFichierGridDto
     */
    deletionUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantFichierGridDto
     */
    deletionReason?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApprenantFichierGridDto
     */
    autoCreated?: boolean;
    /**
     * 
     * @type {EApprenantFichierDeletionReason}
     * @memberof ApprenantFichierGridDto
     */
    deletionReasonCode?: EApprenantFichierDeletionReason;
    /**
     * 
     * @type {number}
     * @memberof ApprenantFichierGridDto
     */
    idfichier?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantFichierGridDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApprenantFichierGridDto
     */
    uploadUser?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantFichierGridDto
     */
    creationUserName?: string | null;
}

/**
 * Check if a given object implements the ApprenantFichierGridDto interface.
 */
export function instanceOfApprenantFichierGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApprenantFichierGridDtoFromJSON(json: any): ApprenantFichierGridDto {
    return ApprenantFichierGridDtoFromJSONTyped(json, false);
}

export function ApprenantFichierGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApprenantFichierGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idapprenantFichier': !exists(json, 'idapprenantFichier') ? undefined : json['idapprenantFichier'],
        'idtypeApprenantFichier': !exists(json, 'idtypeApprenantFichier') ? undefined : json['idtypeApprenantFichier'],
        'typeApprenantFichier': !exists(json, 'typeApprenantFichier') ? undefined : json['typeApprenantFichier'],
        'typeApprenantFichierCode': !exists(json, 'typeApprenantFichierCode') ? undefined : json['typeApprenantFichierCode'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'idinscription': !exists(json, 'idinscription') ? undefined : json['idinscription'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'typeCours': !exists(json, 'typeCours') ? undefined : json['typeCours'],
        'degre': !exists(json, 'degre') ? undefined : json['degre'],
        'metier': !exists(json, 'metier') ? undefined : json['metier'],
        'centre': !exists(json, 'centre') ? undefined : json['centre'],
        'deliberation': !exists(json, 'deliberation') ? undefined : json['deliberation'],
        'deliberation2Sess': !exists(json, 'deliberation2Sess') ? undefined : json['deliberation2Sess'],
        'remis': !exists(json, 'remis') ? undefined : json['remis'],
        'uploadDate': !exists(json, 'uploadDate') ? undefined : (json['uploadDate'] === null ? null : new Date(json['uploadDate'])),
        'deletionDate': !exists(json, 'deletionDate') ? undefined : (json['deletionDate'] === null ? null : new Date(json['deletionDate'])),
        'deletionUserName': !exists(json, 'deletionUserName') ? undefined : json['deletionUserName'],
        'deletionReason': !exists(json, 'deletionReason') ? undefined : json['deletionReason'],
        'autoCreated': !exists(json, 'autoCreated') ? undefined : json['autoCreated'],
        'deletionReasonCode': !exists(json, 'deletionReasonCode') ? undefined : EApprenantFichierDeletionReasonFromJSON(json['deletionReasonCode']),
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'uploadUser': !exists(json, 'uploadUser') ? undefined : json['uploadUser'],
        'creationUserName': !exists(json, 'creationUserName') ? undefined : json['creationUserName'],
    };
}

export function ApprenantFichierGridDtoToJSON(value?: ApprenantFichierGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idapprenantFichier': value.idapprenantFichier,
        'idtypeApprenantFichier': value.idtypeApprenantFichier,
        'typeApprenantFichier': value.typeApprenantFichier,
        'typeApprenantFichierCode': value.typeApprenantFichierCode,
        'anneeScolaire': value.anneeScolaire,
        'idinscription': value.idinscription,
        'nom': value.nom,
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
        'description': value.description,
        'typeCours': value.typeCours,
        'degre': value.degre,
        'metier': value.metier,
        'centre': value.centre,
        'deliberation': value.deliberation,
        'deliberation2Sess': value.deliberation2Sess,
        'remis': value.remis,
        'uploadDate': value.uploadDate === undefined ? undefined : (value.uploadDate === null ? null : value.uploadDate.toISOString()),
        'deletionDate': value.deletionDate === undefined ? undefined : (value.deletionDate === null ? null : value.deletionDate.toISOString()),
        'deletionUserName': value.deletionUserName,
        'deletionReason': value.deletionReason,
        'autoCreated': value.autoCreated,
        'deletionReasonCode': EApprenantFichierDeletionReasonToJSON(value.deletionReasonCode),
        'idfichier': value.idfichier,
        'fileName': value.fileName,
        'uploadUser': value.uploadUser,
        'creationUserName': value.creationUserName,
    };
}

