/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FcbFormateurClasseGridDto } from './FcbFormateurClasseGridDto';
import {
    FcbFormateurClasseGridDtoFromJSON,
    FcbFormateurClasseGridDtoFromJSONTyped,
    FcbFormateurClasseGridDtoToJSON,
} from './FcbFormateurClasseGridDto';
import type { IAggregateResult } from './IAggregateResult';
import {
    IAggregateResultFromJSON,
    IAggregateResultFromJSONTyped,
    IAggregateResultToJSON,
} from './IAggregateResult';

/**
 * 
 * @export
 * @interface FcbFormateurClasseGridDtoPaginatedResults
 */
export interface FcbFormateurClasseGridDtoPaginatedResults {
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurClasseGridDtoPaginatedResults
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<FcbFormateurClasseGridDto>}
     * @memberof FcbFormateurClasseGridDtoPaginatedResults
     */
    results?: Array<FcbFormateurClasseGridDto> | null;
    /**
     * 
     * @type {Array<IAggregateResult>}
     * @memberof FcbFormateurClasseGridDtoPaginatedResults
     */
    aggregateResults?: Array<IAggregateResult> | null;
}

/**
 * Check if a given object implements the FcbFormateurClasseGridDtoPaginatedResults interface.
 */
export function instanceOfFcbFormateurClasseGridDtoPaginatedResults(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbFormateurClasseGridDtoPaginatedResultsFromJSON(json: any): FcbFormateurClasseGridDtoPaginatedResults {
    return FcbFormateurClasseGridDtoPaginatedResultsFromJSONTyped(json, false);
}

export function FcbFormateurClasseGridDtoPaginatedResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbFormateurClasseGridDtoPaginatedResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'results': !exists(json, 'results') ? undefined : (json['results'] === null ? null : (json['results'] as Array<any>).map(FcbFormateurClasseGridDtoFromJSON)),
        'aggregateResults': !exists(json, 'aggregateResults') ? undefined : (json['aggregateResults'] === null ? null : (json['aggregateResults'] as Array<any>).map(IAggregateResultFromJSON)),
    };
}

export function FcbFormateurClasseGridDtoPaginatedResultsToJSON(value?: FcbFormateurClasseGridDtoPaginatedResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'results': value.results === undefined ? undefined : (value.results === null ? null : (value.results as Array<any>).map(FcbFormateurClasseGridDtoToJSON)),
        'aggregateResults': value.aggregateResults === undefined ? undefined : (value.aggregateResults === null ? null : (value.aggregateResults as Array<any>).map(IAggregateResultToJSON)),
    };
}

