/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ContratFormateurSearch,
  FcbContratFormateurDto,
  FcbContratFormateurGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    ContratFormateurSearchFromJSON,
    ContratFormateurSearchToJSON,
    FcbContratFormateurDtoFromJSON,
    FcbContratFormateurDtoToJSON,
    FcbContratFormateurGridDtoPaginatedResultsFromJSON,
    FcbContratFormateurGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface ContratFormateurBaseSearchRequest {
    ContratFormateurSearch?: ContratFormateurSearch;
}

export interface ContratFormateurCodeContratFormateurExistsRequest {
    code?: string;
}

export interface ContratFormateurDeleteRequest {
    id?: number;
}

export interface ContratFormateurGetRequest {
    id?: number;
}

export interface ContratFormateurGetDisplayNameRequest {
    id?: number;
}

export interface ContratFormateurGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ContratFormateurGetSelectItemsRequest {
    searchField: string;
}

export interface ContratFormateurSaveRequest {
    FcbContratFormateurDto?: FcbContratFormateurDto;
}

/**
 * 
 */
export class ContratFormateurApi extends runtime.BaseAPI {

    /**
     */
    async contratFormateurBaseSearchRaw(requestParameters: ContratFormateurBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbContratFormateurGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ContratFormateur/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContratFormateurSearchToJSON(requestParameters.ContratFormateurSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbContratFormateurGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async contratFormateurBaseSearch(requestParameters: ContratFormateurBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbContratFormateurGridDtoPaginatedResults> {
        const response = await this.contratFormateurBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratFormateurCodeContratFormateurExistsRaw(requestParameters: ContratFormateurCodeContratFormateurExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ContratFormateur/codeExists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async contratFormateurCodeContratFormateurExists(requestParameters: ContratFormateurCodeContratFormateurExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.contratFormateurCodeContratFormateurExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratFormateurDeleteRaw(requestParameters: ContratFormateurDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ContratFormateur`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async contratFormateurDelete(requestParameters: ContratFormateurDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.contratFormateurDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratFormateurGetRaw(requestParameters: ContratFormateurGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbContratFormateurDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ContratFormateur`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbContratFormateurDtoFromJSON(jsonValue));
    }

    /**
     */
    async contratFormateurGet(requestParameters: ContratFormateurGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbContratFormateurDto> {
        const response = await this.contratFormateurGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratFormateurGetDisplayNameRaw(requestParameters: ContratFormateurGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ContratFormateur/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async contratFormateurGetDisplayName(requestParameters: ContratFormateurGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.contratFormateurGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratFormateurGetSearchCriteriasRaw(requestParameters: ContratFormateurGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ContratFormateur/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async contratFormateurGetSearchCriterias(requestParameters: ContratFormateurGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.contratFormateurGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratFormateurGetSelectItemsRaw(requestParameters: ContratFormateurGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling contratFormateurGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ContratFormateur/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async contratFormateurGetSelectItems(requestParameters: ContratFormateurGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.contratFormateurGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratFormateurSaveRaw(requestParameters: ContratFormateurSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbContratFormateurDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ContratFormateur`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbContratFormateurDtoToJSON(requestParameters.FcbContratFormateurDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbContratFormateurDtoFromJSON(jsonValue));
    }

    /**
     */
    async contratFormateurSave(requestParameters: ContratFormateurSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbContratFormateurDto> {
        const response = await this.contratFormateurSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
