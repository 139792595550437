/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdresseDto } from './AdresseDto';
import {
    AdresseDtoFromJSON,
    AdresseDtoFromJSONTyped,
    AdresseDtoToJSON,
} from './AdresseDto';

/**
 * 
 * @export
 * @interface ContratApprenantInfosDto
 */
export interface ContratApprenantInfosDto {
    /**
     * 
     * @type {number}
     * @memberof ContratApprenantInfosDto
     */
    idpri?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratApprenantInfosDto
     */
    idari?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContratApprenantInfosDto
     */
    apprenantStatut?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ContratApprenantInfosDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {AdresseDto}
     * @memberof ContratApprenantInfosDto
     */
    adresse?: AdresseDto;
}

/**
 * Check if a given object implements the ContratApprenantInfosDto interface.
 */
export function instanceOfContratApprenantInfosDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContratApprenantInfosDtoFromJSON(json: any): ContratApprenantInfosDto {
    return ContratApprenantInfosDtoFromJSONTyped(json, false);
}

export function ContratApprenantInfosDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContratApprenantInfosDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idpri': !exists(json, 'idpri') ? undefined : json['idpri'],
        'idari': !exists(json, 'idari') ? undefined : json['idari'],
        'apprenantStatut': !exists(json, 'apprenantStatut') ? undefined : json['apprenantStatut'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'adresse': !exists(json, 'adresse') ? undefined : AdresseDtoFromJSON(json['adresse']),
    };
}

export function ContratApprenantInfosDtoToJSON(value?: ContratApprenantInfosDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idpri': value.idpri,
        'idari': value.idari,
        'apprenantStatut': value.apprenantStatut,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'adresse': AdresseDtoToJSON(value.adresse),
    };
}

