import * as React from "react";
import { useTl, useApiService } from "../../../../hooks";
import { useParams, useHistory } from "react-router";
import { AgrementLocalisationApi } from "../../../../api";
import { useApiEffect } from "nsitools-react";
import { PageBase, BackButton, ProtectedTabs } from "../../../../components";
import { ETLCodes } from "../../../../locales";
import { ERoutes } from "../../../../AppRouter";
import { Tab } from "@blueprintjs/core";
import { AgrementClasseDetailPage } from "./AgrementClasseDetailPage";

export interface IAgrementClasseItemPageProps {}

export const AgrementClasseItemPage: React.FunctionComponent<IAgrementClasseItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const api = useApiService(AgrementLocalisationApi);

  const [displayName] = useApiEffect(() => api.agrementLocalisationGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.AgrementClasses), route: ERoutes.agrementClasse },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsAgrementClasse"
        onChange={newTabId => history.push(`${ERoutes.agrementClasse}/${id}/${newTabId}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.agrementClasse} />
        <Tab id="detail" title={t(ETLCodes.Detail)} panel={<AgrementClasseDetailPage />} />
      </ProtectedTabs>
    </PageBase>
  );
};
