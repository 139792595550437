import { Card, Checkbox } from "@blueprintjs/core";
import { ButtonContainer, DataTable, IDataTableColumn, useGridState } from "nsitools-react";
import * as React from "react";

import { FcbResultatTypeEvaluationGridDto, FcbResultatTypeEvaluationGridDtoPaginatedResults } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IDeliberationBulletinEvaluationGridProps {
  data: FcbResultatTypeEvaluationGridDtoPaginatedResults;
}

export const DeliberationBulletinEvaluationGrid: React.FunctionComponent<IDeliberationBulletinEvaluationGridProps> = ({
  data
}) => {
  const { t } = useTl();

  const tableState = useGridState<any>({
    serverMode: false,
    enablePagination: false,
    enableFilter: false,
    availablePageSizes: [9999],
    pageSize: 9999,
    sortKeys: { date: "ASC" },
    data: data.results
  });

  const onViewClick = React.useCallback((dto: FcbResultatTypeEvaluationGridDto) => {
    window.open(`/#${ERoutes.evaluation}/${dto.idevaluation}/detail/view`, "_blank");
  }, []);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbResultatTypeEvaluationGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => onViewClick(row)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Date),
        fieldName: "date"
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "libelle"
      },
      {
        header: () => t(ETLCodes.TypeEvaluation),
        fieldName: "typeEvaluation"
      },
      {
        header: () => t(ETLCodes.Cote),
        fieldName: "cote"
      },
      {
        header: () => t(ETLCodes.Remarque),
        fieldName: "remarque"
      },
      {
        header: () => t(ETLCodes.Remediation),
        fieldName: "remediation",
        autoFitContent: true,
        alignment: "center",
        render: (row: FcbResultatTypeEvaluationGridDto) => <Checkbox disabled checked={row.remediation} />
      },
      {
        header: () => t(ETLCodes.CoteRemediation),
        fieldName: "coteRemediation"
      },

      {
        header: () => t(ETLCodes.Presence),
        fieldName: "presence"
      },
      {
        header: () => t(ETLCodes.Neutralise),
        fieldName: "neutralise",
        autoFitContent: true,
        alignment: "center",
        render: (row: FcbResultatTypeEvaluationGridDto) => <Checkbox disabled checked={row.neutralise} />
      }
    ],
    [onViewClick, t]
  );

  return (
    <Card>
      <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} columns={columns} renderNoData={<></>} />
    </Card>
  );
};
