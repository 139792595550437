/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbClasseHoraireCoherenceSemestreGridDto
 */
export interface FcbClasseHoraireCoherenceSemestreGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbClasseHoraireCoherenceSemestreGridDto
     */
    idclasse?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseHoraireCoherenceSemestreGridDto
     */
    semestre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseHoraireCoherenceSemestreGridDto
     */
    typeProjet?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseHoraireCoherenceSemestreGridDto
     */
    nbheures?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseHoraireCoherenceSemestreGridDto
     */
    nbheuresPlanifie?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseHoraireCoherenceSemestreGridDto
     */
    difference?: number | null;
}

/**
 * Check if a given object implements the FcbClasseHoraireCoherenceSemestreGridDto interface.
 */
export function instanceOfFcbClasseHoraireCoherenceSemestreGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbClasseHoraireCoherenceSemestreGridDtoFromJSON(json: any): FcbClasseHoraireCoherenceSemestreGridDto {
    return FcbClasseHoraireCoherenceSemestreGridDtoFromJSONTyped(json, false);
}

export function FcbClasseHoraireCoherenceSemestreGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbClasseHoraireCoherenceSemestreGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'semestre': !exists(json, 'semestre') ? undefined : json['semestre'],
        'typeProjet': !exists(json, 'typeProjet') ? undefined : json['typeProjet'],
        'nbheures': !exists(json, 'nbheures') ? undefined : json['nbheures'],
        'nbheuresPlanifie': !exists(json, 'nbheuresPlanifie') ? undefined : json['nbheuresPlanifie'],
        'difference': !exists(json, 'difference') ? undefined : json['difference'],
    };
}

export function FcbClasseHoraireCoherenceSemestreGridDtoToJSON(value?: FcbClasseHoraireCoherenceSemestreGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idclasse': value.idclasse,
        'semestre': value.semestre,
        'typeProjet': value.typeProjet,
        'nbheures': value.nbheures,
        'nbheuresPlanifie': value.nbheuresPlanifie,
        'difference': value.difference,
    };
}

