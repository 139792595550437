import React from "react";
import { ApprenantRepresentantInstitutionDetail } from "./ApprenantRepresentantInstitutionDetail";
import { ApprenantRepresentantPersonneDetail } from "./ApprenantRepresentantPersonneDetail";

export interface IApprenantRepresentantGlobalProps {}
export const ApprenantRepresentantGlobal: React.FunctionComponent<IApprenantRepresentantGlobalProps> = () => {
  const [typeRepresentantForm, setTypeRepresentantForm] = React.useState("Personne");
  const changeFormType = React.useMemo(() => {
    if (typeRepresentantForm === "Personne")
      return <ApprenantRepresentantPersonneDetail onTypeRepresentantChange={setTypeRepresentantForm} />;
    if (typeRepresentantForm === "Institution")
      return <ApprenantRepresentantInstitutionDetail onTypeRepresentantChange={setTypeRepresentantForm} />;
  }, [typeRepresentantForm]);

  return <>{changeFormType}</>;
};
