/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FileDownloadDto,
  FilterCriteriaInfo,
  LieuFormationFichierDto,
  LieuFormationFichierGridDtoPaginatedResults,
  LieuFormationFichierSearchDto,
  LieuFormationFichierUploadDto,
  ReferentialItemDto,
} from '../models/index';
import {
    FileDownloadDtoFromJSON,
    FileDownloadDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    LieuFormationFichierDtoFromJSON,
    LieuFormationFichierDtoToJSON,
    LieuFormationFichierGridDtoPaginatedResultsFromJSON,
    LieuFormationFichierGridDtoPaginatedResultsToJSON,
    LieuFormationFichierSearchDtoFromJSON,
    LieuFormationFichierSearchDtoToJSON,
    LieuFormationFichierUploadDtoFromJSON,
    LieuFormationFichierUploadDtoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface LieuFormationFichierBaseSearchRequest {
    LieuFormationFichierSearchDto?: LieuFormationFichierSearchDto;
}

export interface LieuFormationFichierDeleteLieuFormationFichierRequest {
    id?: number;
}

export interface LieuFormationFichierDownloadLieuFormationFichierRequest {
    id?: number;
}

export interface LieuFormationFichierGetLieuFormationFichierRequest {
    id?: number;
}

export interface LieuFormationFichierGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface LieuFormationFichierGetSelectItemsRequest {
    searchField: string;
}

export interface LieuFormationFichierSaveLieuFormationFichierRequest {
    LieuFormationFichierDto?: LieuFormationFichierDto;
}

export interface LieuFormationFichierUploadLieuFormationFichierRequest {
    LieuFormationFichierUploadDto?: LieuFormationFichierUploadDto;
}

/**
 * 
 */
export class LieuFormationFichierApi extends runtime.BaseAPI {

    /**
     */
    async lieuFormationFichierBaseSearchRaw(requestParameters: LieuFormationFichierBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationFichierGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationFichier/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LieuFormationFichierSearchDtoToJSON(requestParameters.LieuFormationFichierSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationFichierGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationFichierBaseSearch(requestParameters: LieuFormationFichierBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationFichierGridDtoPaginatedResults> {
        const response = await this.lieuFormationFichierBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationFichierDeleteLieuFormationFichierRaw(requestParameters: LieuFormationFichierDeleteLieuFormationFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationFichier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async lieuFormationFichierDeleteLieuFormationFichier(requestParameters: LieuFormationFichierDeleteLieuFormationFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.lieuFormationFichierDeleteLieuFormationFichierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async lieuFormationFichierDownloadLieuFormationFichierRaw(requestParameters: LieuFormationFichierDownloadLieuFormationFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationFichier/Download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationFichierDownloadLieuFormationFichier(requestParameters: LieuFormationFichierDownloadLieuFormationFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.lieuFormationFichierDownloadLieuFormationFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationFichierGetLieuFormationFichierRaw(requestParameters: LieuFormationFichierGetLieuFormationFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationFichierDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationFichierGetLieuFormationFichier(requestParameters: LieuFormationFichierGetLieuFormationFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationFichierDto> {
        const response = await this.lieuFormationFichierGetLieuFormationFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationFichierGetSearchCriteriasRaw(requestParameters: LieuFormationFichierGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationFichier/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async lieuFormationFichierGetSearchCriterias(requestParameters: LieuFormationFichierGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.lieuFormationFichierGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationFichierGetSelectItemsRaw(requestParameters: LieuFormationFichierGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling lieuFormationFichierGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationFichier/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async lieuFormationFichierGetSelectItems(requestParameters: LieuFormationFichierGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.lieuFormationFichierGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationFichierSaveLieuFormationFichierRaw(requestParameters: LieuFormationFichierSaveLieuFormationFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LieuFormationFichierDtoToJSON(requestParameters.LieuFormationFichierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationFichierSaveLieuFormationFichier(requestParameters: LieuFormationFichierSaveLieuFormationFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationFichierDto> {
        const response = await this.lieuFormationFichierSaveLieuFormationFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationFichierUploadLieuFormationFichierRaw(requestParameters: LieuFormationFichierUploadLieuFormationFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationFichier/Upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LieuFormationFichierUploadDtoToJSON(requestParameters.LieuFormationFichierUploadDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationFichierUploadLieuFormationFichier(requestParameters: LieuFormationFichierUploadLieuFormationFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationFichierDto> {
        const response = await this.lieuFormationFichierUploadLieuFormationFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
