/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbRechercheClasseHoraireRemplacementSimultaneDtoPaginatedResults,
  FcbRemplacementSimultaneDto,
  FilterCriteriaInfo,
  RechercheClasseHoraireRemplacementSimultaneSearch,
  SelectItem,
} from '../models/index';
import {
    FcbRechercheClasseHoraireRemplacementSimultaneDtoPaginatedResultsFromJSON,
    FcbRechercheClasseHoraireRemplacementSimultaneDtoPaginatedResultsToJSON,
    FcbRemplacementSimultaneDtoFromJSON,
    FcbRemplacementSimultaneDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    RechercheClasseHoraireRemplacementSimultaneSearchFromJSON,
    RechercheClasseHoraireRemplacementSimultaneSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface RemplacementSimultaneBaseSearchRequest {
    RechercheClasseHoraireRemplacementSimultaneSearch?: RechercheClasseHoraireRemplacementSimultaneSearch;
}

export interface RemplacementSimultaneGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface RemplacementSimultaneGetSelectItemsRequest {
    searchField: string;
}

export interface RemplacementSimultaneSaveRequest {
    FcbRemplacementSimultaneDto?: FcbRemplacementSimultaneDto;
}

/**
 * 
 */
export class RemplacementSimultaneApi extends runtime.BaseAPI {

    /**
     */
    async remplacementSimultaneBaseSearchRaw(requestParameters: RemplacementSimultaneBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRechercheClasseHoraireRemplacementSimultaneDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RemplacementSimultane/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RechercheClasseHoraireRemplacementSimultaneSearchToJSON(requestParameters.RechercheClasseHoraireRemplacementSimultaneSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRechercheClasseHoraireRemplacementSimultaneDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async remplacementSimultaneBaseSearch(requestParameters: RemplacementSimultaneBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRechercheClasseHoraireRemplacementSimultaneDtoPaginatedResults> {
        const response = await this.remplacementSimultaneBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async remplacementSimultaneGetSearchCriteriasRaw(requestParameters: RemplacementSimultaneGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RemplacementSimultane/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async remplacementSimultaneGetSearchCriterias(requestParameters: RemplacementSimultaneGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.remplacementSimultaneGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async remplacementSimultaneGetSelectItemsRaw(requestParameters: RemplacementSimultaneGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling remplacementSimultaneGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RemplacementSimultane/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async remplacementSimultaneGetSelectItems(requestParameters: RemplacementSimultaneGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.remplacementSimultaneGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async remplacementSimultaneSaveRaw(requestParameters: RemplacementSimultaneSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RemplacementSimultane`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbRemplacementSimultaneDtoToJSON(requestParameters.FcbRemplacementSimultaneDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async remplacementSimultaneSave(requestParameters: RemplacementSimultaneSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.remplacementSimultaneSaveRaw(requestParameters, initOverrides);
    }

}
