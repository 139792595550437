/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbFormateurAgrementGridDto
 */
export interface FcbFormateurAgrementGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurAgrementGridDto
     */
    idformateurAgrement?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurAgrementGridDto
     */
    nomConseiller?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurAgrementGridDto
     */
    dateDemande?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurAgrementGridDto
     */
    matiereDemandee?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurAgrementGridDto
     */
    centreDemandeur?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurAgrementGridDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurAgrementGridDto
     */
    idfichier?: number | null;
}

/**
 * Check if a given object implements the FcbFormateurAgrementGridDto interface.
 */
export function instanceOfFcbFormateurAgrementGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbFormateurAgrementGridDtoFromJSON(json: any): FcbFormateurAgrementGridDto {
    return FcbFormateurAgrementGridDtoFromJSONTyped(json, false);
}

export function FcbFormateurAgrementGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbFormateurAgrementGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idformateurAgrement': !exists(json, 'idformateurAgrement') ? undefined : json['idformateurAgrement'],
        'nomConseiller': !exists(json, 'nomConseiller') ? undefined : json['nomConseiller'],
        'dateDemande': !exists(json, 'dateDemande') ? undefined : (json['dateDemande'] === null ? null : new Date(json['dateDemande'])),
        'matiereDemandee': !exists(json, 'matiereDemandee') ? undefined : json['matiereDemandee'],
        'centreDemandeur': !exists(json, 'centreDemandeur') ? undefined : json['centreDemandeur'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
    };
}

export function FcbFormateurAgrementGridDtoToJSON(value?: FcbFormateurAgrementGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idformateurAgrement': value.idformateurAgrement,
        'nomConseiller': value.nomConseiller,
        'dateDemande': value.dateDemande === undefined ? undefined : (value.dateDemande === null ? null : value.dateDemande.toISOString()),
        'matiereDemandee': value.matiereDemandee,
        'centreDemandeur': value.centreDemandeur,
        'fileName': value.fileName,
        'idfichier': value.idfichier,
    };
}

