/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbSecteurHomogeneGridDto
 */
export interface FcbSecteurHomogeneGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbSecteurHomogeneGridDto
     */
    idsecteur?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbSecteurHomogeneGridDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbSecteurHomogeneGridDto
     */
    idstade?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbSecteurHomogeneGridDto
     */
    stade?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbSecteurHomogeneGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbSecteurHomogeneGridDto interface.
 */
export function instanceOfFcbSecteurHomogeneGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbSecteurHomogeneGridDtoFromJSON(json: any): FcbSecteurHomogeneGridDto {
    return FcbSecteurHomogeneGridDtoFromJSONTyped(json, false);
}

export function FcbSecteurHomogeneGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbSecteurHomogeneGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsecteur': !exists(json, 'idsecteur') ? undefined : json['idsecteur'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'idstade': !exists(json, 'idstade') ? undefined : json['idstade'],
        'stade': !exists(json, 'stade') ? undefined : json['stade'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbSecteurHomogeneGridDtoToJSON(value?: FcbSecteurHomogeneGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsecteur': value.idsecteur,
        'libelle': value.libelle,
        'idstade': value.idstade,
        'stade': value.stade,
        'actif': value.actif,
    };
}

