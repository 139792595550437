/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbExportGapClassesGridDtoPaginatedResults,
  FilterCriteriaInfo,
  RechercheExportGapSearch,
  SelectItem,
} from '../models/index';
import {
    FcbExportGapClassesGridDtoPaginatedResultsFromJSON,
    FcbExportGapClassesGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    RechercheExportGapSearchFromJSON,
    RechercheExportGapSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface ExportGapClassesBaseSearchRequest {
    RechercheExportGapSearch?: RechercheExportGapSearch;
}

export interface ExportGapClassesGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ExportGapClassesGetSelectItemsRequest {
    searchField: string;
}

/**
 * 
 */
export class ExportGapClassesApi extends runtime.BaseAPI {

    /**
     */
    async exportGapClassesBaseSearchRaw(requestParameters: ExportGapClassesBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbExportGapClassesGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportGapClasses/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RechercheExportGapSearchToJSON(requestParameters.RechercheExportGapSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbExportGapClassesGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async exportGapClassesBaseSearch(requestParameters: ExportGapClassesBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbExportGapClassesGridDtoPaginatedResults> {
        const response = await this.exportGapClassesBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportGapClassesGetSearchCriteriasRaw(requestParameters: ExportGapClassesGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportGapClasses/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async exportGapClassesGetSearchCriterias(requestParameters: ExportGapClassesGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.exportGapClassesGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportGapClassesGetSelectItemsRaw(requestParameters: ExportGapClassesGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling exportGapClassesGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportGapClasses/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async exportGapClassesGetSelectItems(requestParameters: ExportGapClassesGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.exportGapClassesGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
