/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbRechercheExportGapPrestationDtoPaginatedResults,
  FilterCriteriaInfo,
  RechercheExportGapSearch,
  SelectItem,
} from '../models/index';
import {
    FcbRechercheExportGapPrestationDtoPaginatedResultsFromJSON,
    FcbRechercheExportGapPrestationDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    RechercheExportGapSearchFromJSON,
    RechercheExportGapSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface ExportGapPrestationBaseSearchRequest {
    RechercheExportGapSearch?: RechercheExportGapSearch;
}

export interface ExportGapPrestationGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ExportGapPrestationGetSelectItemsRequest {
    searchField: string;
}

/**
 * 
 */
export class ExportGapPrestationApi extends runtime.BaseAPI {

    /**
     */
    async exportGapPrestationBaseSearchRaw(requestParameters: ExportGapPrestationBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRechercheExportGapPrestationDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportGapPrestation/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RechercheExportGapSearchToJSON(requestParameters.RechercheExportGapSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRechercheExportGapPrestationDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async exportGapPrestationBaseSearch(requestParameters: ExportGapPrestationBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRechercheExportGapPrestationDtoPaginatedResults> {
        const response = await this.exportGapPrestationBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportGapPrestationGetSearchCriteriasRaw(requestParameters: ExportGapPrestationGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportGapPrestation/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async exportGapPrestationGetSearchCriterias(requestParameters: ExportGapPrestationGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.exportGapPrestationGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportGapPrestationGetSelectItemsRaw(requestParameters: ExportGapPrestationGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling exportGapPrestationGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExportGapPrestation/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async exportGapPrestationGetSelectItems(requestParameters: ExportGapPrestationGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.exportGapPrestationGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
