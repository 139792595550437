/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailDto
 */
export interface EmailDto {
    /**
     * 
     * @type {number}
     * @memberof EmailDto
     */
    idemail?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EmailDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {string}
     * @memberof EmailDto
     */
    adresseEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EmailDto
     */
    idtypeEmail?: number;
    /**
     * 
     * @type {string}
     * @memberof EmailDto
     */
    typeEmail?: string | null;
}

/**
 * Check if a given object implements the EmailDto interface.
 */
export function instanceOfEmailDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmailDtoFromJSON(json: any): EmailDto {
    return EmailDtoFromJSONTyped(json, false);
}

export function EmailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idemail': !exists(json, 'idemail') ? undefined : json['idemail'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'adresseEmail': !exists(json, 'adresseEmail') ? undefined : json['adresseEmail'],
        'idtypeEmail': !exists(json, 'idtypeEmail') ? undefined : json['idtypeEmail'],
        'typeEmail': !exists(json, 'typeEmail') ? undefined : json['typeEmail'],
    };
}

export function EmailDtoToJSON(value?: EmailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idemail': value.idemail,
        'idpersonne': value.idpersonne,
        'adresseEmail': value.adresseEmail,
        'idtypeEmail': value.idtypeEmail,
        'typeEmail': value.typeEmail,
    };
}

