import * as React from "react";

import { SmsApi, SmsSearch, ETextSearchType } from "../../../../../api";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { SearchTablePage } from "../../../../../components";
import { useGlobalData } from "../../../../../contexts";

export interface IFormateurSMSProps {
  formateurId: number;
}

export const FormateurSMS: React.FunctionComponent<IFormateurSMSProps> = ({ formateurId }) => {
  const { t } = useTl();
  const api = useApiService(SmsApi);
  const { currentAnneeScolaire } = useGlobalData();

  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.Message),
        fieldName: "message"
      },
      {
        header: () => t(ETLCodes.DateEnvoi),
        fieldName: "dateEnvoi"
      },
      {
        header: () => t(ETLCodes.Statut),
        fieldName: "status"
      }
    ],
    [t]
  );

  const getCriteriasFn = React.useCallback(() => api.smsGetSearchCriterias({ includeListsValues: true }), [api]);

  const apiFactory = useAbortableApiServiceFactory(SmsApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: SmsSearch) => {
      sObj.idFormateur = formateurId;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.smsBaseSearch({ SmsSearch: sObj });
    },
    [apiFactory, formateurId]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    formateurId > 0 && (
      <SearchTablePage
        columns={columns}
        getCriteriasFunction={getCriteriasFn}
        searchFunction={searchFn}
        onAbort={onAbort}
        sortKeys={{ dateEnvoi: "DESC" }}
        title={ETLCodes.SMS}
        withCard={false}
        defaultCriterias={[
          {
            criteria: "AnneeScolaire",
            searchMode: ETextSearchType.Equals,
            value: currentAnneeScolaire?.idanneeScolaire
          }
        ]}
      />
    )
  );
};
