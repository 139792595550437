import { Intent } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { AcquisScolaireApi, AcquisScolaireSearch, FcbAcquisScolaireGridDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { AddButton, EditButton, ViewButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IAcquisScolaireTableProps {}

export const AcquisScolaireTable: React.FunctionComponent<IAcquisScolaireTableProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const apprenantId = React.useMemo(() => +id, [id]);

  const api = useApiService(AcquisScolaireApi);
  const path = React.useMemo(() => `${ERoutes.apprenant}/${apprenantId}/acquis/scolaire`, [apprenantId]);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [5],
    pageSize: 5,
    sortKeys: { annee: "DESC" }
  });

  const searchFunc = React.useCallback(
    (sObj?: AcquisScolaireSearch) => {
      sObj.idApprenant = apprenantId;
      return api.acquisScolaireBaseSearch({ AcquisScolaireSearch: sObj });
    },
    [api, apprenantId]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction: searchFunc,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbAcquisScolaireGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => history.push(`${path}/${row.idacquis}/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${path}/${row.idacquis}/edit`)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "annee",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Acquis),
        fieldName: "acquis"
      },
      {
        header: () => t(ETLCodes.Resultat),
        fieldName: "resultat"
      },
      {
        header: () => t(ETLCodes.Remarque),
        fieldName: "certificat"
      }
    ],
    [history, path, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${path}/0/edit`);
  }, [history, path]);

  return (
    <>
      {apprenantId > 0 && (
        <FieldSet
          title={`${t(ETLCodes.AcquisScolaire)}`}
          rightElement={
            <>
              <AddButton
                onClick={e => {
                  e.stopPropagation();
                  onAddItem();
                }}
                text={t(ETLCodes.General_Add)}
                intent={Intent.PRIMARY}
              />
            </>
          }
        >
          <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
        </FieldSet>
      )}
    </>
  );
};
