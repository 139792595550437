/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { BooleanSearch } from './BooleanSearch';
import {
    BooleanSearchFromJSON,
    BooleanSearchFromJSONTyped,
    BooleanSearchToJSON,
} from './BooleanSearch';
import type { DateSearch } from './DateSearch';
import {
    DateSearchFromJSON,
    DateSearchFromJSONTyped,
    DateSearchToJSON,
} from './DateSearch';
import type { StringListSearch } from './StringListSearch';
import {
    StringListSearchFromJSON,
    StringListSearchFromJSONTyped,
    StringListSearchToJSON,
} from './StringListSearch';
import type { TextSearch } from './TextSearch';
import {
    TextSearchFromJSON,
    TextSearchFromJSONTyped,
    TextSearchToJSON,
} from './TextSearch';
import type { UnaccentTextSearch } from './UnaccentTextSearch';
import {
    UnaccentTextSearchFromJSON,
    UnaccentTextSearchFromJSONTyped,
    UnaccentTextSearchToJSON,
} from './UnaccentTextSearch';

/**
 * 
 * @export
 * @interface PersonneSearchDto
 */
export interface PersonneSearchDto {
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof PersonneSearchDto
     */
    nom?: UnaccentTextSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof PersonneSearchDto
     */
    prenom?: UnaccentTextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof PersonneSearchDto
     */
    telephone?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof PersonneSearchDto
     */
    gsm?: TextSearch;
    /**
     * 
     * @type {DateSearch}
     * @memberof PersonneSearchDto
     */
    dateNaissance?: DateSearch;
    /**
     * 
     * @type {StringListSearch}
     * @memberof PersonneSearchDto
     */
    nationalite?: StringListSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof PersonneSearchDto
     */
    registreNationalOrEmpty?: TextSearch;
    /**
     * 
     * @type {BooleanSearch}
     * @memberof PersonneSearchDto
     */
    isApprenant?: BooleanSearch;
    /**
     * 
     * @type {BooleanSearch}
     * @memberof PersonneSearchDto
     */
    isFormateur?: BooleanSearch;
    /**
     * 
     * @type {BooleanSearch}
     * @memberof PersonneSearchDto
     */
    isRepresentant?: BooleanSearch;
    /**
     * 
     * @type {BooleanSearch}
     * @memberof PersonneSearchDto
     */
    isUser?: BooleanSearch;
    /**
     * 
     * @type {BooleanSearch}
     * @memberof PersonneSearchDto
     */
    isChefEntreprise?: BooleanSearch;
    /**
     * 
     * @type {BooleanSearch}
     * @memberof PersonneSearchDto
     */
    isTuteur?: BooleanSearch;
    /**
     * 
     * @type {BooleanSearch}
     * @memberof PersonneSearchDto
     */
    isContact?: BooleanSearch;
    /**
     * 
     * @type {Array<string>}
     * @memberof PersonneSearchDto
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof PersonneSearchDto
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof PersonneSearchDto
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PersonneSearchDto
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof PersonneSearchDto
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneSearchDto
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the PersonneSearchDto interface.
 */
export function instanceOfPersonneSearchDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PersonneSearchDtoFromJSON(json: any): PersonneSearchDto {
    return PersonneSearchDtoFromJSONTyped(json, false);
}

export function PersonneSearchDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonneSearchDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nom': !exists(json, 'nom') ? undefined : UnaccentTextSearchFromJSON(json['nom']),
        'prenom': !exists(json, 'prenom') ? undefined : UnaccentTextSearchFromJSON(json['prenom']),
        'telephone': !exists(json, 'telephone') ? undefined : TextSearchFromJSON(json['telephone']),
        'gsm': !exists(json, 'gsm') ? undefined : TextSearchFromJSON(json['gsm']),
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : DateSearchFromJSON(json['dateNaissance']),
        'nationalite': !exists(json, 'nationalite') ? undefined : StringListSearchFromJSON(json['nationalite']),
        'registreNationalOrEmpty': !exists(json, 'registreNationalOrEmpty') ? undefined : TextSearchFromJSON(json['registreNationalOrEmpty']),
        'isApprenant': !exists(json, 'isApprenant') ? undefined : BooleanSearchFromJSON(json['isApprenant']),
        'isFormateur': !exists(json, 'isFormateur') ? undefined : BooleanSearchFromJSON(json['isFormateur']),
        'isRepresentant': !exists(json, 'isRepresentant') ? undefined : BooleanSearchFromJSON(json['isRepresentant']),
        'isUser': !exists(json, 'isUser') ? undefined : BooleanSearchFromJSON(json['isUser']),
        'isChefEntreprise': !exists(json, 'isChefEntreprise') ? undefined : BooleanSearchFromJSON(json['isChefEntreprise']),
        'isTuteur': !exists(json, 'isTuteur') ? undefined : BooleanSearchFromJSON(json['isTuteur']),
        'isContact': !exists(json, 'isContact') ? undefined : BooleanSearchFromJSON(json['isContact']),
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function PersonneSearchDtoToJSON(value?: PersonneSearchDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nom': UnaccentTextSearchToJSON(value.nom),
        'prenom': UnaccentTextSearchToJSON(value.prenom),
        'telephone': TextSearchToJSON(value.telephone),
        'gsm': TextSearchToJSON(value.gsm),
        'dateNaissance': DateSearchToJSON(value.dateNaissance),
        'nationalite': StringListSearchToJSON(value.nationalite),
        'registreNationalOrEmpty': TextSearchToJSON(value.registreNationalOrEmpty),
        'isApprenant': BooleanSearchToJSON(value.isApprenant),
        'isFormateur': BooleanSearchToJSON(value.isFormateur),
        'isRepresentant': BooleanSearchToJSON(value.isRepresentant),
        'isUser': BooleanSearchToJSON(value.isUser),
        'isChefEntreprise': BooleanSearchToJSON(value.isChefEntreprise),
        'isTuteur': BooleanSearchToJSON(value.isTuteur),
        'isContact': BooleanSearchToJSON(value.isContact),
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

