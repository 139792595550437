/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DashboardPratiqueProDto
 */
export interface DashboardPratiqueProDto {
    /**
     * 
     * @type {number}
     * @memberof DashboardPratiqueProDto
     */
    idcontrat?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardPratiqueProDto
     */
    code?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardPratiqueProDto
     */
    idtypeContrat?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardPratiqueProDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardPratiqueProDto
     */
    typeCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardPratiqueProDto
     */
    periode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardPratiqueProDto
     */
    statut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardPratiqueProDto
     */
    referent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardPratiqueProDto
     */
    idsuserMasquage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardPratiqueProDto
     */
    idsuser?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardPratiqueProDto
     */
    idapprenant?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardPratiqueProDto
     */
    apprenant?: string | null;
}

/**
 * Check if a given object implements the DashboardPratiqueProDto interface.
 */
export function instanceOfDashboardPratiqueProDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DashboardPratiqueProDtoFromJSON(json: any): DashboardPratiqueProDto {
    return DashboardPratiqueProDtoFromJSONTyped(json, false);
}

export function DashboardPratiqueProDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardPratiqueProDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontrat': !exists(json, 'idcontrat') ? undefined : json['idcontrat'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'idtypeContrat': !exists(json, 'idtypeContrat') ? undefined : json['idtypeContrat'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'typeCode': !exists(json, 'typeCode') ? undefined : json['typeCode'],
        'periode': !exists(json, 'periode') ? undefined : json['periode'],
        'statut': !exists(json, 'statut') ? undefined : json['statut'],
        'referent': !exists(json, 'referent') ? undefined : json['referent'],
        'idsuserMasquage': !exists(json, 'idsuserMasquage') ? undefined : json['idsuserMasquage'],
        'idsuser': !exists(json, 'idsuser') ? undefined : json['idsuser'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'apprenant': !exists(json, 'apprenant') ? undefined : json['apprenant'],
    };
}

export function DashboardPratiqueProDtoToJSON(value?: DashboardPratiqueProDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontrat': value.idcontrat,
        'code': value.code,
        'idtypeContrat': value.idtypeContrat,
        'type': value.type,
        'typeCode': value.typeCode,
        'periode': value.periode,
        'statut': value.statut,
        'referent': value.referent,
        'idsuserMasquage': value.idsuserMasquage,
        'idsuser': value.idsuser,
        'idapprenant': value.idapprenant,
        'apprenant': value.apprenant,
    };
}

