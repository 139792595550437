import * as React from "react";
import { ToolTipButton } from "nsitools-react";
import { useHistory } from "react-router";
import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import { ERoutes } from "../../AppRouter";
interface IBackButtonProps {
  backRoute: ERoutes | string;
}

export const BackButton: React.FunctionComponent<IBackButtonProps> = ({ backRoute }) => {
  const { t } = useTl();
  const history = useHistory();

  return (
    <ToolTipButton
      tooltip={t(ETLCodes.General_GoBack)}
      icon="arrow-left"
      intent="primary"
      minimal={true}
      onClick={() => history.push(backRoute)}
    ></ToolTipButton>
  );
};
