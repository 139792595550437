/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbReferentielDto
 */
export interface FcbReferentielDto {
    /**
     * 
     * @type {number}
     * @memberof FcbReferentielDto
     */
    idreferentiel?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbReferentielDto
     */
    idmetier?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbReferentielDto
     */
    iddegre?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbReferentielDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbReferentielDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbReferentielDto
     */
    nbheures?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbReferentielDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbReferentielDto
     */
    coutmatiere?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbReferentielDto
     */
    nbcandidat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbReferentielDto
     */
    nbseances?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbReferentielDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbReferentielDto
     */
    test?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FcbReferentielDto
     */
    pcreussite?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbReferentielDto
     */
    codereferentiel?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbReferentielDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbReferentielDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbReferentielDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbReferentielDto
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof FcbReferentielDto
     */
    idreferentielGuid?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbReferentielDto
     */
    idtypeReussite?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbReferentielDto
     */
    pcreport?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbReferentielDto
     */
    heureReelle?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbReferentielDto
     */
    coutglobal?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbReferentielDto
     */
    deliberee?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbReferentielDto
     */
    e8E9?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbReferentielDto
     */
    idtypeBulletin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbReferentielDto
     */
    idcertification?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbReferentielDto
     */
    linkedToClasses?: boolean;
}

/**
 * Check if a given object implements the FcbReferentielDto interface.
 */
export function instanceOfFcbReferentielDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbReferentielDtoFromJSON(json: any): FcbReferentielDto {
    return FcbReferentielDtoFromJSONTyped(json, false);
}

export function FcbReferentielDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbReferentielDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idreferentiel': !exists(json, 'idreferentiel') ? undefined : json['idreferentiel'],
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'iddegre': !exists(json, 'iddegre') ? undefined : json['iddegre'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'nbheures': !exists(json, 'nbheures') ? undefined : json['nbheures'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'coutmatiere': !exists(json, 'coutmatiere') ? undefined : json['coutmatiere'],
        'nbcandidat': !exists(json, 'nbcandidat') ? undefined : json['nbcandidat'],
        'nbseances': !exists(json, 'nbseances') ? undefined : json['nbseances'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'test': !exists(json, 'test') ? undefined : json['test'],
        'pcreussite': !exists(json, 'pcreussite') ? undefined : json['pcreussite'],
        'codereferentiel': !exists(json, 'codereferentiel') ? undefined : json['codereferentiel'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'idreferentielGuid': !exists(json, 'idreferentielGuid') ? undefined : json['idreferentielGuid'],
        'idtypeReussite': !exists(json, 'idtypeReussite') ? undefined : json['idtypeReussite'],
        'pcreport': !exists(json, 'pcreport') ? undefined : json['pcreport'],
        'heureReelle': !exists(json, 'heureReelle') ? undefined : json['heureReelle'],
        'coutglobal': !exists(json, 'coutglobal') ? undefined : json['coutglobal'],
        'deliberee': !exists(json, 'deliberee') ? undefined : json['deliberee'],
        'e8E9': !exists(json, 'e8E9') ? undefined : json['e8E9'],
        'idtypeBulletin': !exists(json, 'idtypeBulletin') ? undefined : json['idtypeBulletin'],
        'idcertification': !exists(json, 'idcertification') ? undefined : json['idcertification'],
        'linkedToClasses': !exists(json, 'linkedToClasses') ? undefined : json['linkedToClasses'],
    };
}

export function FcbReferentielDtoToJSON(value?: FcbReferentielDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idreferentiel': value.idreferentiel,
        'idmetier': value.idmetier,
        'iddegre': value.iddegre,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'description': value.description,
        'nbheures': value.nbheures,
        'remarque': value.remarque,
        'coutmatiere': value.coutmatiere,
        'nbcandidat': value.nbcandidat,
        'nbseances': value.nbseances,
        'actif': value.actif,
        'test': value.test,
        'pcreussite': value.pcreussite,
        'codereferentiel': value.codereferentiel,
        'creationUser': value.creationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'idreferentielGuid': value.idreferentielGuid,
        'idtypeReussite': value.idtypeReussite,
        'pcreport': value.pcreport,
        'heureReelle': value.heureReelle,
        'coutglobal': value.coutglobal,
        'deliberee': value.deliberee,
        'e8E9': value.e8E9,
        'idtypeBulletin': value.idtypeBulletin,
        'idcertification': value.idcertification,
        'linkedToClasses': value.linkedToClasses,
    };
}

