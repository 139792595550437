import { Icon } from "@blueprintjs/core";
import classNames from "classnames";
import { showError } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import { useEventsContext } from "../../contexts";
import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";

export function fileExtension(
  filename: string,
  opts: {
    preserveCase?: boolean;
  } = {}
) {
  if (!filename) return "";
  const ext = (/[^./\\]*$/.exec(filename) || [""])[0];
  return opts.preserveCase ? ext : ext.toLowerCase();
}

export interface DropzoneFile {
  name: string;
  extension: string;
  file: File;
}

const DropZoneContainer = styled.div<{ disabled: boolean }>`
  height: 75px;
  width: 100%;
  background-color: #eaeaea;
  border: 1px dashed rgb(172, 169, 169);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  cursor: ${props => (props.disabled ? "none" : "pointer")};

  &.highlight {
    background-color: rgb(188, 185, 236);
  }
`;

const StyledInput = styled.input`
  display: none;
`;

export interface IDropzoneProps {
  disabled?: boolean;
  onFilesAdded: (files: DropzoneFile[]) => void;
  maxFiles?: number;
  className?: string;
  acceptExtensions?: string[];
}

export const Dropzone: React.FunctionComponent<IDropzoneProps> = ({
  disabled = false,
  onFilesAdded,
  maxFiles = 0,
  className,
  acceptExtensions
}) => {
  const { t } = useTl();
  const fileInputRef = React.useRef<HTMLInputElement>();

  const [highlight, setHighlight] = React.useState(false);

  const openFileDialog = React.useCallback(() => {
    if (disabled) return;
    fileInputRef.current.click();
  }, [disabled]);

  const [fileList, setFileList] = React.useState<FileList>();

  const reset = React.useCallback(() => {
    setFileList(null);
  }, []);

  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();
  React.useEffect(() => {
    subscribeToEvent("RESET_DROPZONE_FILES", reset);
    return () => unsubscribeEvent("RESET_DROPZONE_FILES", reset);
  }, [reset, subscribeToEvent, unsubscribeEvent]);

  const filesArray = React.useMemo<DropzoneFile[]>(() => {
    if (!fileList) return null;
    const array: File[] = [];
    for (let i = 0; i < fileList.length; i++) {
      array.push(fileList.item(i));
    }
    return array.map(f => ({
      file: f,
      name: f.name,
      extension: fileExtension(f.name)
    }));
  }, [fileList]);

  const onInputChange = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      if (disabled) return;
      const files = evt.target.files;
      setFileList(files);
    },
    [disabled]
  );

  const onDragOver = React.useCallback(
    (evt: React.DragEvent<HTMLDivElement>) => {
      evt.preventDefault();
      if (disabled) return;
      setHighlight(true);
    },
    [disabled]
  );

  const onDragLeave = React.useCallback((evt: React.DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    setHighlight(false);
  }, []);

  const onDrop = React.useCallback(
    (evt: React.DragEvent<HTMLDivElement>) => {
      evt.preventDefault();
      if (disabled) return;
      if (maxFiles > 0 && evt.dataTransfer.files.length >= maxFiles) {
        showError(t(ETLCodes.MaxFileSizeReached));
      }
      const files = evt.dataTransfer.files;
      setFileList(files);
      setHighlight(false);
    },
    [disabled, maxFiles, t]
  );

  React.useEffect(() => {
    if (filesArray) {
      onFilesAdded(filesArray);
    }
  }, [filesArray, onFilesAdded]);

  const uploadedFileNames = React.useMemo(() => filesArray?.map(d => d.name), [filesArray]);

  const hasFile = React.useMemo(() => filesArray?.length > 0, [filesArray?.length]);

  const finalAcceptExtensions = React.useMemo(() => acceptExtensions?.join(",") ?? "", [acceptExtensions]);

  return (
    <div className={className}>
      <DropZoneContainer
        onClick={openFileDialog}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        className={classNames({ highlight })}
        disabled={disabled}
      >
        {!hasFile ? (
          <>
            <Icon icon="cloud-upload" iconSize={48} style={{ opacity: "0.5" }} />
            <span>{t(ETLCodes.UploadFichiers)}</span>
          </>
        ) : (
          <>
            {uploadedFileNames?.map((name, i) => (
              <div key={i}>{name}</div>
            ))}
          </>
        )}
        <StyledInput
          ref={fileInputRef}
          className="FileInput"
          type="file"
          onChange={onInputChange}
          accept={finalAcceptExtensions}
        />
      </DropZoneContainer>
    </div>
  );
};
