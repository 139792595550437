import { Classes, Colors, FileInput, Spinner, SpinnerSize } from "@blueprintjs/core";
import { getIn } from "formik";
import { FGCustomInput, IFGCustomInputProps, showError, useFGContext } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { DeleteButton, LinkButton } from "..";
import { FileDownloadDto, FileUploadDtoFromJSON } from "../../api";
import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import { exportFile } from "../../utils";

const StyledFileInput = styled(FileInput)`
  & .${Classes.FILE_UPLOAD_INPUT} {
    color: ${Colors.BLACK} !important;
  }
`;

const FileInputContainer = styled.div`
  display: flex;
`;

const RoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
`;

export interface IFGWalterFileInputProps extends Omit<IFGCustomInputProps, "children"> {
  downloadFn?: () => Promise<FileDownloadDto>;
  validationErrors?: any;
  validationErrorsKey?: string;
  disabled?: boolean;
}

export const FGWalterFileInput: React.FunctionComponent<IFGWalterFileInputProps> = ({
  name,
  downloadFn = null,
  validationErrors,
  validationErrorsKey,
  disabled = false,
  ...customInputProps
}) => {
  const { t } = useTl();
  const { formik, editMode } = useFGContext();

  const [downloading, setdownloading] = React.useState(false);

  const file = React.useMemo(() => {
    const data = getIn(formik?.values, name);
    if (!data) return null;
    if (!("fileName" in data) && !("FileName" in data))
      throw new Error(
        `The value of FGFileInput must be an object containing a property named "fileName" or containing "FileName" (Field: ${name})`
      );
    return data;
  }, [formik?.values, name]);

  const downloadDocument = React.useCallback(async () => {
    try {
      setdownloading(true);
      const file = await downloadFn();
      await exportFile(file);
    } catch (err) {
      console.error("Error: ", err);
      showError(t(ETLCodes.ErrorWhileDownloadingFile));
    }
    setdownloading(false);
  }, [downloadFn, t]);

  const onChange = React.useCallback(
    e => {
      if (e.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          const filePathSplit = e.target.value.split("\\");

          const newFile = FileUploadDtoFromJSON({
            fileName: filePathSplit[filePathSplit.length - 1],
            contentB64: reader.result.toString()
          });

          formik.setFieldTouched(`${name}.fileName`, true);
          formik.setFieldValue(name, newFile, true);
        });
        reader.readAsDataURL(e.target.files[0]);
      } else {
        formik.setFieldTouched(`${name}.fileName`, true);
        formik.setFieldValue(name, null, true);
      }
    },
    [formik, name]
  );

  return (
    <FGCustomInput name={`${name}.fileName`} {...customInputProps}>
      {() =>
        downloading ? (
          <Spinner size={SpinnerSize.SMALL} />
        ) : (
          <FileInputContainer>
            {customInputProps.readonly || !editMode ? (
              <RoContainer>
                {downloadFn && file !== null ? (
                  <LinkButton text={file?.fileName} onClick={downloadDocument} />
                ) : (
                  file?.fileName ?? t(ETLCodes.ChoisissezUnFichier)
                )}
              </RoContainer>
            ) : (
              <StyledFileInput
                text={
                  downloadFn && file !== null ? (
                    <LinkButton text={file?.fileName} onClick={downloadDocument} />
                  ) : (
                    file?.fileName ?? t(ETLCodes.ChoisissezUnFichier)
                  )
                }
                onInputChange={onChange}
                buttonText={t(ETLCodes.Parcourir)}
                fill={true}
                disabled={disabled}
              />
            )}
            {file !== null && !customInputProps.readonly && (
              <DeleteButton
                onDelete={() => {
                  formik.setFieldValue(name, null);
                }}
                icon="cross"
              />
            )}
          </FileInputContainer>
        )
      }
    </FGCustomInput>
  );
};
