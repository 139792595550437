/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EOrigineSuivi {
    Centres = 'Centres',
    Services = 'Services'
}


export function EOrigineSuiviFromJSON(json: any): EOrigineSuivi {
    return EOrigineSuiviFromJSONTyped(json, false);
}

export function EOrigineSuiviFromJSONTyped(json: any, ignoreDiscriminator: boolean): EOrigineSuivi {
    return json as EOrigineSuivi;
}

export function EOrigineSuiviToJSON(value?: EOrigineSuivi | null): any {
    return value as any;
}

