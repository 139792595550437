/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbJustificatifsAbsenceDetailsDto
 */
export interface FcbJustificatifsAbsenceDetailsDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    plages?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    jours?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    idjustificatif?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    idjustificatifGenre?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    idjustificatifType?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    accepte?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    dateRemise?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    dateFin?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    jour1?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    jour2?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    jour3?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    jour4?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    jour5?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    jour6?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    jour7?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbJustificatifsAbsenceDetailsDto
     */
    idcentre?: number | null;
}

/**
 * Check if a given object implements the FcbJustificatifsAbsenceDetailsDto interface.
 */
export function instanceOfFcbJustificatifsAbsenceDetailsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbJustificatifsAbsenceDetailsDtoFromJSON(json: any): FcbJustificatifsAbsenceDetailsDto {
    return FcbJustificatifsAbsenceDetailsDtoFromJSONTyped(json, false);
}

export function FcbJustificatifsAbsenceDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbJustificatifsAbsenceDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'plages': !exists(json, 'plages') ? undefined : json['plages'],
        'jours': !exists(json, 'jours') ? undefined : json['jours'],
        'idjustificatif': !exists(json, 'idjustificatif') ? undefined : json['idjustificatif'],
        'idjustificatifGenre': !exists(json, 'idjustificatifGenre') ? undefined : json['idjustificatifGenre'],
        'idjustificatifType': !exists(json, 'idjustificatifType') ? undefined : json['idjustificatifType'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'accepte': !exists(json, 'accepte') ? undefined : json['accepte'],
        'dateRemise': !exists(json, 'dateRemise') ? undefined : (json['dateRemise'] === null ? null : new Date(json['dateRemise'])),
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
        'jour1': !exists(json, 'jour1') ? undefined : json['jour1'],
        'jour2': !exists(json, 'jour2') ? undefined : json['jour2'],
        'jour3': !exists(json, 'jour3') ? undefined : json['jour3'],
        'jour4': !exists(json, 'jour4') ? undefined : json['jour4'],
        'jour5': !exists(json, 'jour5') ? undefined : json['jour5'],
        'jour6': !exists(json, 'jour6') ? undefined : json['jour6'],
        'jour7': !exists(json, 'jour7') ? undefined : json['jour7'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
    };
}

export function FcbJustificatifsAbsenceDetailsDtoToJSON(value?: FcbJustificatifsAbsenceDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'plages': value.plages,
        'jours': value.jours,
        'idjustificatif': value.idjustificatif,
        'idjustificatifGenre': value.idjustificatifGenre,
        'idjustificatifType': value.idjustificatifType,
        'idapprenant': value.idapprenant,
        'remarque': value.remarque,
        'accepte': value.accepte,
        'dateRemise': value.dateRemise === undefined ? undefined : (value.dateRemise === null ? null : value.dateRemise.toISOString()),
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
        'jour1': value.jour1,
        'jour2': value.jour2,
        'jour3': value.jour3,
        'jour4': value.jour4,
        'jour5': value.jour5,
        'jour6': value.jour6,
        'jour7': value.jour7,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'idcentre': value.idcentre,
    };
}

