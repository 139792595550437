import { Callout, Classes, Radio, RadioGroup } from "@blueprintjs/core";
import IBAN from "iban";
import {
  FGCustomInput,
  FGCustomPanel,
  FGIBANNumberInput,
  FGMaskInput,
  FGMultiSuggestInput,
  FGTextAreaInput,
  FGTextInput,
  FieldGroup,
  useFGFormik,
  usePrevious
} from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";

import {
  CodePostalApi,
  FormateurApi,
  SiegeSocialApi,
  SiegeSocialEditDto,
  SiegeSocialEditDtoFromJSON
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  ChefEntrepriseSelectorField,
  CommuneSelect,
  FGWalterCheckboxInput,
  FGWalterSelectInput,
  LocaliteSelect,
  SmallFormGenerator
} from "../../../../../components";
import { useDialog, useEventsContext } from "../../../../../contexts";
import { useApiService, useCrudApi, useTabMessage, useTheme, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import { bceNumberValidate } from "../../../../../utils/bceNumberValidate";

const StatusContainer = styled.div<{ color: string }>`
  color: ${props => props.color};
  font-weight: bold;
  height: 30px;
  display: flex;
  align-items: center;
`;

const StyledRadioGroup = styled(RadioGroup)`
  & .contact-select {
    display: flex;
    align-items: center;
    & .${Classes.LABEL} {
      min-width: auto;
      font-weight: normal;
    }
  }
`;

export interface ISiegeSocialSignaletiqueProps {}

export const SiegeSocialSignaletique: React.FunctionComponent<ISiegeSocialSignaletiqueProps> = props => {
  const { t } = useTl();
  const api = useApiService(SiegeSocialApi);
  const codePostalApi = useApiService(CodePostalApi);
  const { theme } = useTheme();
  const history = useHistory();
  const { id, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const { dispatchEvent } = useEventsContext();
  const { search } = useLocation();
  const cfs = React.useMemo(() => new URLSearchParams(search).get("cfs"), [search]);
  const { sendMessage } = useTabMessage(cfs);
  const fieldNameToReturn = React.useMemo(() => new URLSearchParams(search).get("fieldName"), [search]);

  const { data, loading, saveItem, saving, validationErrors } = useCrudApi<SiegeSocialEditDto>(
    React.useMemo(
      () => ({
        getApiFn: () =>
          +id <= 0
            ? SiegeSocialEditDtoFromJSON({
                idsiegeSocial: 0,
                actif: true,
                isContactCE: true,
                hasNoAssurances: true
              })
            : api.siegeSocialGetSiegeSocial({ id: +id }),
        saveApiFn: d => api.siegeSocialSaveSiegeSocial({ SiegeSocialEditDto: d }),
        onSavedRoute: d => `${ERoutes.siegeSocial}/${d.idsiegeSocial}/detail/edit`,
        onSaved: d => {
          sendMessage(d[fieldNameToReturn], true);
          dispatchEvent("REFRESH_NB_TABS");
        }
      }),
      [api, dispatchEvent, fieldNameToReturn, id, sendMessage]
    )
  );

  const { showDialogPromise } = useDialog();
  const save = React.useCallback(
    async (values: SiegeSocialEditDto) => {
      let result = "yes";
      if (data?.actif && !values.actif) {
        result = await showDialogPromise({
          message: t(ETLCodes.DesactiverSiegeSocialText),
          title: t(ETLCodes.EtesVousSureDesactiverSiegeSocial)
        });
      }

      if (!data?.actif && values.actif) {
        result = await showDialogPromise({
          message: t(ETLCodes.ReactiverSiegeSocialText),
          title: t(ETLCodes.ReactivationSiegeSocialTitle)
        });
      }

      if (result === "yes") {
        await saveItem(values);
      }
    },
    [data?.actif, saveItem, showDialogPromise, t]
  );

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      denominationSociale: Yup.string().required(t(ETLCodes.Required)),
      idchefEntreprise: Yup.number().nullable(),
      numeroBce: Yup.string()
        .required(t(ETLCodes.Required))
        .test("bce-validity", t(ETLCodes.InvalidBCE), async function(value) {
          return !value || bceNumberValidate(value.toString());
        }),
      iban: Yup.string()
        .nullable()
        .test("iban-valid", t(ETLCodes.InvalidIban), async value => {
          if (!value || value.trim() === "") return true;
          return IBAN.isValid(value);
        }),
      idtypeEntreprise: Yup.number().required(t(ETLCodes.Required)),
      idtaille: Yup.number().required(t(ETLCodes.Required)),
      actif: Yup.bool().required(t(ETLCodes.Required))
    });
  }, [t]);

  const formik = useFGFormik<SiegeSocialEditDto>({
    initialValues: data,
    onSubmit: save,
    validationSchema: FormSchema
  });
  const [previousIdchefEntreprise, setPreviousIdchefEntreprise] = React.useState(data?.idchefEntreprise);
  React.useEffect(() => {
    if (data?.idchefEntreprise) {
      setPreviousIdchefEntreprise(data?.idchefEntreprise);
    }
  }, [data?.idchefEntreprise]);

  const useChefEntrepriseHasContact = React.useMemo(() => data?.isContactCE, [data?.isContactCE]);

  const [typesEntreprise, loadingTypesEntreprise, , rawTypesEntreprise] = useReferential(
    a => a.referentialGetTypesEntreprise(),
    false,
    []
  );
  const [formesJuridiques, loadingFormesJuridiques] = useReferential(
    a => a.referentialGetFormesJuridiques(),
    false,
    []
  );
  const [tailles, loadingTailles] = useReferential(a => a.referentialGetTailles(), false, []);

  const [idpersonne, setIdpersonne] = React.useState(data?.idpersonne);
  React.useEffect(() => {
    if (data?.idpersonne) {
      setIdpersonne(data?.idpersonne);
    }
  }, [data?.idpersonne]);
  const [personneTelephones, personneTelephonesLoading] = useReferential(
    a => a.referentialGetTelephonesPersonne({ idpersonne }),
    false,
    [idpersonne]
  );
  const [personneGsms, personneGsmsLoading] = useReferential(a => a.referentialGetGsmsPersonne({ idpersonne }), false, [
    idpersonne
  ]);
  const [contacts, contactsLoading] = useReferential(
    a => a.referentialGetContactSiegeSocial({ idSiegeSocial: +id }),
    false,
    [idpersonne]
  );

  const [personneEmails, personneEmailsLoading] = useReferential(
    a => a.referentialGetEmailsPersonne({ idpersonne }),
    false,
    [idpersonne]
  );

  const [commissionsParitaires, cpLoading] = useReferential(
    a => a.referentialGetCommissionsParitaires({ idmetier: 0, idsiegeSocial: 0, currentId: 0 }),
    true,
    []
  );

  const fetchChefEntrepriseId = React.useCallback(async () => {
    if (!formik?.values?.idchefEntreprise || formik?.values?.idchefEntreprise === 0) return null;
    return await api.siegeSocialGetSelectedChefEntreprise({
      IdchefEntreprise: formik?.values?.idchefEntreprise,
      idsiegeSocial: +id
    });
  }, [api, id, formik?.values?.idchefEntreprise]);

  const { data: chefEntreprisePersonne } = useQuery(
    ["chefEntreprise-for-select", formik?.values?.idchefEntreprise, id],
    fetchChefEntrepriseId,
    { enabled: true }
  );
  React.useEffect(() => {
    if (
      formik?.values?.idchefEntreprise !== 0 &&
      formik?.values?.idchefEntreprise !== previousIdchefEntreprise &&
      !!chefEntreprisePersonne &&
      !loading
    ) {
      if (useChefEntrepriseHasContact) {
        formik?.setFieldValue("contact", chefEntreprisePersonne?.nom + " " + chefEntreprisePersonne?.prenom ?? null);
        formik?.setFieldValue("contactGsm", chefEntreprisePersonne?.gsm?.numero ?? null);
        formik?.setFieldValue("contactTel", chefEntreprisePersonne?.telephone?.numero ?? null);
      }

      formik?.setFieldValue("gsm", chefEntreprisePersonne?.gsm ?? null);
      formik?.setFieldValue("telephone", chefEntreprisePersonne?.telephone ?? null);
      formik?.setFieldValue("email", chefEntreprisePersonne?.email ?? null);
      formik?.setFieldValue("idpersonne", chefEntreprisePersonne?.idpersonne ?? null);
      setIdpersonne(chefEntreprisePersonne?.idpersonne);
      setPreviousIdchefEntreprise(formik?.values?.idchefEntreprise);
    }
  }, [chefEntreprisePersonne, loading, formik, previousIdchefEntreprise, useChefEntrepriseHasContact]);

  const showFormeJuridique = React.useCallback(
    () => rawTypesEntreprise?.find(te => +te.idValue === +formik.values?.idtypeEntreprise)?.keyValue === "MORALE",
    [formik.values?.idtypeEntreprise, rawTypesEntreprise]
  );

  const [iban, setIban] = React.useState<string>();
  const previousIban = usePrevious(formik?.values?.iban);
  React.useEffect(() => {
    if (formik?.values?.iban !== previousIban) {
      setIban(formik?.values?.iban);
    }
  }, [formik?.values?.iban, previousIban]);
  const formateurApi = useApiService(FormateurApi);
  const queryEnabled = React.useMemo(() => iban?.toUpperCase()?.startsWith("BE") && iban?.length > 8, [iban]);
  const fetchBic = React.useCallback(async () => {
    if (queryEnabled) return formateurApi.formateurGetBicFromAccount({ account: iban });
    return "";
  }, [formateurApi, iban, queryEnabled]);

  const { data: bic, isFetching: bicLoading } = useQuery(["bic-siege", id, iban], fetchBic);

  React.useEffect(() => {
    if (!formik.touched.iban) return;
    if (!iban && !!formik.values.bic) {
      formik.setFieldValue("bic", null);
    }
  }, [formik, iban]);

  const previousBic = usePrevious(bic);
  React.useEffect(() => {
    if (bic && previousBic !== bic) {
      formik?.setFieldValue("bic", bic);
    }
  }, [bic, formik, previousBic]);

  const [pays, pLoading] = useReferential(a => a.referentialGetPays());

  const { data: codePostaux } = useQuery(
    ["all-codes-postaux"],
    React.useCallback(() => {
      return codePostalApi.codePostalGetAll();
    }, [codePostalApi])
  );

  const previousCodePostalText = usePrevious(formik?.values?.codePostalText);
  const previousLocalite = usePrevious(formik?.values?.localite);

  React.useEffect(() => {
    if (
      !formik?.values?.codePostalText ||
      formik?.values?.codePostalText === previousCodePostalText ||
      formik?.values?.codePostalText === data?.codePostalText
    ) {
      return;
    }

    formik.setFieldValue("idcodePostal", null);
    formik.setFieldValue("localite", null);
    formik.setFieldValue("commune", null);
  }, [codePostaux, data?.codePostalText, formik, previousCodePostalText]);

  React.useEffect(() => {
    if (!formik?.values?.localite || formik?.values?.localite === previousLocalite || !codePostaux?.length) return;

    const cpList = codePostaux?.filter(cp => cp.codePostal === formik?.values?.codePostalText) ?? [];
    const foundIdCp =
      cpList.length === 1
        ? cpList[0].idcodePostal
        : !!formik?.values?.localite
        ? cpList.find(cp => cp.localite === formik?.values?.localite)?.idcodePostal
        : cpList.find(cp => cp.codePays === "BE")?.idcodePostal;
    if (+foundIdCp === +formik?.values?.idcodePostal) return;

    formik.setFieldValue("idcodePostal", foundIdCp);
  }, [codePostaux, formik, previousCodePostalText, previousLocalite]);

  const fetchCp = React.useCallback(async () => {
    if (!formik?.values?.idcodePostal) return null;
    return await codePostalApi.codePostalGet({ id: formik.values.idcodePostal });
  }, [codePostalApi, formik]);
  const { data: cp } = useQuery(["code-postal", formik.values?.idcodePostal], fetchCp);

  React.useEffect(() => {
    if (!cp || formik?.values?.pays === cp.codePays || !!formik?.values?.pays) return;
    formik.setFieldValue("pays", cp.codePays);
  }, [cp, formik]);

  React.useEffect(() => {
    if (!!formik?.values?.gsm && !formik?.values?.gsm.idtelephone) {
      formik.setFieldValue("gsm", null);
    }
  }, [formik]);

  React.useEffect(() => {
    if (!!formik?.values?.telephone && !formik?.values?.telephone.idtelephone) {
      formik.setFieldValue("telephone", null);
    }
  }, [formik]);

  React.useEffect(() => {
    if (!!formik?.values?.email && !formik?.values?.email.idemail) {
      formik.setFieldValue("email", null);
    }
  }, [formik]);

  return (
    data && (
      <SmallFormGenerator
        formik={formik}
        editMode={state === "edit"}
        loading={loading}
        onCancel={() => history.push(ERoutes.siegeSocial)}
        showDeleteButton={+id > 0}
        saving={saving}
        minLabelWidth={250}
        validationErrors={validationErrors}
      >
        {formik?.values?.hasNoAssurances && (
          <Callout
            intent="warning"
            title={t(ETLCodes.SiegeSocialNoAssurance)}
            style={{ marginBottom: "1rem" }}
            icon="warning-sign"
          />
        )}
        <FieldGroup columns={2}>
          <FieldGroup
            fieldsetProps={{
              title: t(ETLCodes.DonneesDeBase)
            }}
          >
            <FGTextInput
              name="denominationSociale"
              label={t(ETLCodes.DenominationSociale)}
              maxLength={255}
              requiredMark
            />
            <FGMaskInput
              name="numeroBce"
              label={t(ETLCodes.NumeroBce)}
              cleaveOptions={{
                blocks: [4, 3, 3],
                delimiter: "."
              }}
              requiredMark
            />
            <FGWalterSelectInput
              name="idtypeEntreprise"
              label={t(ETLCodes.TypeEntreprise)}
              items={typesEntreprise}
              loading={loadingTypesEntreprise}
              requiredMark
            />
            <FGWalterSelectInput
              name="idformeJuridique"
              label={t(ETLCodes.FormeJuridique)}
              items={formesJuridiques}
              loading={loadingFormesJuridiques}
              visible={showFormeJuridique}
            />
            <FGWalterSelectInput name="idtaille" label={t(ETLCodes.Taille)} items={tailles} loading={loadingTailles} />
            <FGMaskInput
              name="numeroOnss"
              label={t(ETLCodes.NumeroOnss)}
              cleaveOptions={{ blocks: [3, 7, 2], delimiters: ["/", "-"] }}
            />
            <FGMultiSuggestInput
              name="idsCommissionParitaire"
              label={t(ETLCodes.CommissionsParitaires)}
              items={commissionsParitaires}
              loading={cpLoading}
            />
            <FGIBANNumberInput name="iban" label={t(ETLCodes.NumeroBanque)} />
            <FGTextInput name="bic" label={t(ETLCodes.CodeBIC)} maxLength={15} loading={bicLoading} />
            <FGCustomInput label={t(ETLCodes.BankAccountBeneficiary)}>
              {ctx => (
                <StyledRadioGroup
                  selectedValue={!ctx?.formik?.values?.idcontactBeneficiaire ? "1" : "0"}
                  onChange={e => {
                    if (e.currentTarget.value === "1") {
                      ctx.formik.setFieldValue("idcontactBeneficiaire", null);
                    } else {
                      ctx.formik.setFieldValue("idcontactBeneficiaire", -1);
                    }
                  }}
                >
                  <Radio label={t(ETLCodes.SiegeSocial)} value="1"></Radio>
                  <Radio
                    value="0"
                    className="contact-select"
                    disabled={!contacts || contacts.length <= 0 || !ctx.editMode}
                  >
                    <FGWalterSelectInput
                      label={t(ETLCodes.Contact)}
                      name="idcontactBeneficiaire"
                      items={contacts}
                      loading={contactsLoading}
                      disabled={!contacts || contacts.length <= 0 || !ctx.editMode}
                      placeholder={(!contacts || contacts.length <= 0) && t(ETLCodes.PasDeContacts)}
                    />
                  </Radio>
                </StyledRadioGroup>
              )}
            </FGCustomInput>
            <FGWalterCheckboxInput
              name="actif"
              label={t(ETLCodes.StatutBCE)}
              renderReadOnlyValue={value => (
                <StatusContainer color={value ? theme.sucessColor : theme.dangerColor}>
                  {value ? t(ETLCodes.Actif) : t(ETLCodes.Inactif)}
                </StatusContainer>
              )}
            />
          </FieldGroup>
          <FieldGroup
            fieldsetProps={{
              title: t(ETLCodes.Contacts)
            }}
          >
            <FGCustomPanel>
              {ctx => (
                <>
                  <ChefEntrepriseSelectorField
                    label={t(ETLCodes.ChefEntreprise)}
                    name="idchefEntreprise"
                    idsiegeSocial={+id}
                  />
                  <FGWalterSelectInput
                    name="gsm.idtelephone"
                    label={t(ETLCodes.Gsm)}
                    items={personneGsms}
                    loading={personneGsmsLoading}
                    itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
                    itemCreateParams={`&typeTel=GSM_PERSO`}
                    disabled={!ctx?.formik?.values?.idchefEntreprise}
                    autoSelectIfOne
                  />
                  <FGWalterSelectInput
                    name="telephone.idtelephone"
                    label={t(ETLCodes.Tel)}
                    items={personneTelephones}
                    loading={personneTelephonesLoading}
                    itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
                    itemCreateParams={`&typeTel=PERSO`}
                    disabled={!ctx?.formik?.values?.idchefEntreprise}
                    autoSelectIfOne
                  />
                  <FGWalterSelectInput
                    name="email.idemail"
                    label={t(ETLCodes.General_Email)}
                    loading={personneEmailsLoading}
                    items={personneEmails}
                    itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
                    itemCreateParams={`&typeEmail=PERSO`}
                    disabled={!ctx?.formik?.values?.idchefEntreprise}
                    autoSelectIfOne
                  />
                  <FGTextInput name="contact" label={t(ETLCodes.Contact)} readonly />
                  <FGTextInput name="contactFonction" label={t(ETLCodes.ContactFonction)} readonly />
                  <FGTextInput name="contactGsm" label={t(ETLCodes.ContactGsm)} readonly />
                  <FGTextInput name="contactTel" label={t(ETLCodes.ContactTel)} readonly />
                </>
              )}
            </FGCustomPanel>
          </FieldGroup>
        </FieldGroup>
        <FieldGroup columns={2}>
          <FieldGroup
            fieldsetProps={{
              title: t(ETLCodes.CoordonneesSiege)
            }}
          >
            <FGWalterCheckboxInput name="sendToAdress" label={t(ETLCodes.SendToAddress)} />
            <FGTextAreaInput labelStyles={{ minWidth: 100 }} name="adresse" label={t(ETLCodes.Adresse)} />
            <FGTextInput
              labelStyles={{ minWidth: 100 }}
              name="codePostalText"
              label={t(ETLCodes.CodePostal)}
              maxLength={6}
            />
            <FGCustomPanel>
              {ctx => (
                <>
                  <LocaliteSelect
                    labelStyles={{ minWidth: 100 }}
                    codePostal={ctx?.formik?.values?.codePostalText}
                    codePays={ctx?.formik?.values?.pays}
                    name="localite"
                    codePostalTextName="codePostalText"
                    disableIfNoCp={false}
                  />
                  <CommuneSelect
                    labelStyles={{ minWidth: 100 }}
                    codePostal={ctx?.formik?.values?.codePostalText}
                    codePays={ctx?.formik?.values?.pays}
                    name="commune"
                  />
                </>
              )}
            </FGCustomPanel>
            <FGWalterSelectInput
              labelStyles={{ minWidth: 100 }}
              name="pays"
              label={t(ETLCodes.Pays)}
              items={pays}
              loading={pLoading}
            />
          </FieldGroup>
          <FieldGroup
            fieldsetProps={{
              title: t(ETLCodes.Remarque)
            }}
          >
            <FGTextAreaInput name="remarque" noLabel />
          </FieldGroup>
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
