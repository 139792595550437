/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApprenantFichierDto
 */
export interface ApprenantFichierDto {
    /**
     * 
     * @type {number}
     * @memberof ApprenantFichierDto
     */
    idapprenantFichier?: number;
    /**
     * 
     * @type {number}
     * @memberof ApprenantFichierDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof ApprenantFichierDto
     */
    idfichier?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApprenantFichierDto
     */
    idtypeApprenantFichier?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantFichierDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantFichierDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApprenantFichierDto
     */
    idinscription?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantFichierDto
     */
    nom?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ApprenantFichierDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ApprenantFichierDto
     */
    dateFin?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantFichierDto
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ApprenantFichierDto
     */
    uploadDate?: Date | null;
}

/**
 * Check if a given object implements the ApprenantFichierDto interface.
 */
export function instanceOfApprenantFichierDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApprenantFichierDtoFromJSON(json: any): ApprenantFichierDto {
    return ApprenantFichierDtoFromJSONTyped(json, false);
}

export function ApprenantFichierDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApprenantFichierDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idapprenantFichier': !exists(json, 'idapprenantFichier') ? undefined : json['idapprenantFichier'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'idtypeApprenantFichier': !exists(json, 'idtypeApprenantFichier') ? undefined : json['idtypeApprenantFichier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'idinscription': !exists(json, 'idinscription') ? undefined : json['idinscription'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'uploadDate': !exists(json, 'uploadDate') ? undefined : (json['uploadDate'] === null ? null : new Date(json['uploadDate'])),
    };
}

export function ApprenantFichierDtoToJSON(value?: ApprenantFichierDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idapprenantFichier': value.idapprenantFichier,
        'idapprenant': value.idapprenant,
        'idfichier': value.idfichier,
        'idtypeApprenantFichier': value.idtypeApprenantFichier,
        'fileName': value.fileName,
        'anneeScolaire': value.anneeScolaire,
        'idinscription': value.idinscription,
        'nom': value.nom,
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
        'description': value.description,
        'uploadDate': value.uploadDate === undefined ? undefined : (value.uploadDate === null ? null : value.uploadDate.toISOString()),
    };
}

