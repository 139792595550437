import moment from "moment";
import { IJExcelColumn, IJExcelNestedHeader, JExcel } from "../../../../../../components";
import * as React from "react";

import { useEventsContext } from "../../../../../../contexts";

export interface IJExcelViewProps {
  data: any;
  columns: IJExcelColumn<any>[];
  nestedHeaders: IJExcelNestedHeader[];
}

export const JExcelView: React.FunctionComponent<IJExcelViewProps> = ({ data, columns, nestedHeaders }) => {
  const [jexcelInstance, setJexcelInstance] = React.useState<any>(null);
  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();

  React.useEffect(() => {
    const cb = () => jexcelInstance?.refresh();
    subscribeToEvent("CLASSE_HORAIRE_SAVED", cb);
    subscribeToEvent("CLASSE_HORAIRE_ACTIONS", cb);
    subscribeToEvent("CLASSE_HORAIRE_CALENDRIER_ITEM_SAVED", cb);
    return () => {
      unsubscribeEvent("CLASSE_HORAIRE_SAVED", cb);
      unsubscribeEvent("CLASSE_HORAIRE_ACTIONS", cb);
      unsubscribeEvent("CLASSE_HORAIRE_CALENDRIER_ITEM_SAVED", cb);
    };
  }, [jexcelInstance, subscribeToEvent, unsubscribeEvent]);

  return (
    <div>
      {data && (
        <JExcel
          data={data.classeHoraires.map(ch => {
            return { ...ch, date: moment(ch.date).format("YYYY-MM-DD") };
          })}
          columns={columns}
          nestedHeaders={nestedHeaders}
          setJexcelInstance={setJexcelInstance}
          tableOverflow={true}
          freezeColumns={2}
          tableHeight="800px"
          tableWidth="1500px"
          filters={true}
          pagination={25}
        />
      )}
    </div>
  );
};
