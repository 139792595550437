/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbRechercheDateAbsenceDtoPaginatedResults,
  FilterCriteriaInfo,
  RechercheDateAbsenceApprenantSearch,
  SelectItem,
} from '../models/index';
import {
    FcbRechercheDateAbsenceDtoPaginatedResultsFromJSON,
    FcbRechercheDateAbsenceDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    RechercheDateAbsenceApprenantSearchFromJSON,
    RechercheDateAbsenceApprenantSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface AbsenceApprenantBaseSearchRequest {
    RechercheDateAbsenceApprenantSearch?: RechercheDateAbsenceApprenantSearch;
}

export interface AbsenceApprenantGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface AbsenceApprenantGetSelectItemsRequest {
    searchField: string;
}

/**
 * 
 */
export class AbsenceApprenantApi extends runtime.BaseAPI {

    /**
     */
    async absenceApprenantBaseSearchRaw(requestParameters: AbsenceApprenantBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRechercheDateAbsenceDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AbsenceApprenant/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RechercheDateAbsenceApprenantSearchToJSON(requestParameters.RechercheDateAbsenceApprenantSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRechercheDateAbsenceDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async absenceApprenantBaseSearch(requestParameters: AbsenceApprenantBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRechercheDateAbsenceDtoPaginatedResults> {
        const response = await this.absenceApprenantBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async absenceApprenantGetSearchCriteriasRaw(requestParameters: AbsenceApprenantGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AbsenceApprenant/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async absenceApprenantGetSearchCriterias(requestParameters: AbsenceApprenantGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.absenceApprenantGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async absenceApprenantGetSelectItemsRaw(requestParameters: AbsenceApprenantGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling absenceApprenantGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AbsenceApprenant/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async absenceApprenantGetSelectItems(requestParameters: AbsenceApprenantGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.absenceApprenantGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
