/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EStatutDemande } from './EStatutDemande';
import {
    EStatutDemandeFromJSON,
    EStatutDemandeFromJSONTyped,
    EStatutDemandeToJSON,
} from './EStatutDemande';

/**
 * 
 * @export
 * @interface DemandeInscriptionGridDto
 */
export interface DemandeInscriptionGridDto {
    /**
     * 
     * @type {number}
     * @memberof DemandeInscriptionGridDto
     */
    iddemandeInscription?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DemandeInscriptionGridDto
     */
    idparcoursFormation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DemandeInscriptionGridDto
     */
    idinscription?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeInscriptionGridDto
     */
    typeCours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeInscriptionGridDto
     */
    typeCoursLibelle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeInscriptionGridDto
     */
    degre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeInscriptionGridDto
     */
    codeDegre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeInscriptionGridDto
     */
    metier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeInscriptionGridDto
     */
    stade?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeInscriptionGridDto
     */
    centre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeInscriptionGridDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeInscriptionGridDto
     */
    service?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemandeInscriptionGridDto
     */
    idstatutDemande?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeInscriptionGridDto
     */
    deliberationType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeInscriptionGridDto
     */
    classe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeInscriptionGridDto
     */
    nomClasse?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DemandeInscriptionGridDto
     */
    editable?: boolean | null;
    /**
     * 
     * @type {EStatutDemande}
     * @memberof DemandeInscriptionGridDto
     */
    codeStatut?: EStatutDemande;
    /**
     * 
     * @type {boolean}
     * @memberof DemandeInscriptionGridDto
     */
    centreExterieur?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DemandeInscriptionGridDto
     */
    statutInscription?: string | null;
}

/**
 * Check if a given object implements the DemandeInscriptionGridDto interface.
 */
export function instanceOfDemandeInscriptionGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DemandeInscriptionGridDtoFromJSON(json: any): DemandeInscriptionGridDto {
    return DemandeInscriptionGridDtoFromJSONTyped(json, false);
}

export function DemandeInscriptionGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DemandeInscriptionGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iddemandeInscription': !exists(json, 'iddemandeInscription') ? undefined : json['iddemandeInscription'],
        'idparcoursFormation': !exists(json, 'idparcoursFormation') ? undefined : json['idparcoursFormation'],
        'idinscription': !exists(json, 'idinscription') ? undefined : json['idinscription'],
        'typeCours': !exists(json, 'typeCours') ? undefined : json['typeCours'],
        'typeCoursLibelle': !exists(json, 'typeCoursLibelle') ? undefined : json['typeCoursLibelle'],
        'degre': !exists(json, 'degre') ? undefined : json['degre'],
        'codeDegre': !exists(json, 'codeDegre') ? undefined : json['codeDegre'],
        'metier': !exists(json, 'metier') ? undefined : json['metier'],
        'stade': !exists(json, 'stade') ? undefined : json['stade'],
        'centre': !exists(json, 'centre') ? undefined : json['centre'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'idstatutDemande': !exists(json, 'idstatutDemande') ? undefined : json['idstatutDemande'],
        'deliberationType': !exists(json, 'deliberationType') ? undefined : json['deliberationType'],
        'classe': !exists(json, 'classe') ? undefined : json['classe'],
        'nomClasse': !exists(json, 'nomClasse') ? undefined : json['nomClasse'],
        'editable': !exists(json, 'editable') ? undefined : json['editable'],
        'codeStatut': !exists(json, 'codeStatut') ? undefined : EStatutDemandeFromJSON(json['codeStatut']),
        'centreExterieur': !exists(json, 'centreExterieur') ? undefined : json['centreExterieur'],
        'statutInscription': !exists(json, 'statutInscription') ? undefined : json['statutInscription'],
    };
}

export function DemandeInscriptionGridDtoToJSON(value?: DemandeInscriptionGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iddemandeInscription': value.iddemandeInscription,
        'idparcoursFormation': value.idparcoursFormation,
        'idinscription': value.idinscription,
        'typeCours': value.typeCours,
        'typeCoursLibelle': value.typeCoursLibelle,
        'degre': value.degre,
        'codeDegre': value.codeDegre,
        'metier': value.metier,
        'stade': value.stade,
        'centre': value.centre,
        'anneeScolaire': value.anneeScolaire,
        'service': value.service,
        'idstatutDemande': value.idstatutDemande,
        'deliberationType': value.deliberationType,
        'classe': value.classe,
        'nomClasse': value.nomClasse,
        'editable': value.editable,
        'codeStatut': EStatutDemandeToJSON(value.codeStatut),
        'centreExterieur': value.centreExterieur,
        'statutInscription': value.statutInscription,
    };
}

