import { FGCustomPanel, FGEmpty, FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { ELevelName, FcbServiceTutelleDtoFromJSON, ServiceTutelleApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  CommuneSelect,
  FGWalterSelectInput,
  LocaliteSelect,
  SmallFormGenerator,
  FGWalterCheckboxInput
} from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

interface IServiceTutelleDetailPageProps {
  idServiceTutelle: number;
  editMode: boolean;
}

export const ServiceTutelleDetailPage: React.FunctionComponent<IServiceTutelleDetailPageProps> = ({
  idServiceTutelle,
  editMode
}) => {
  const { t } = useTl();
  const api = useApiService(ServiceTutelleApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idServiceTutelle <= 0
        ? FcbServiceTutelleDtoFromJSON({ idServiceTutelle: 0 })
        : api.serviceTutelleGet({ id: +idServiceTutelle }),
    saveApiFn: d => api.serviceTutelleSave({ FcbServiceTutelleDto: d }),
    onSavedRoute: d => `${ERoutes.serviceTutelle}/${d.idserviceTutelle}/detail/edit`,
    deleteApiFn: d => api.serviceTutelleDelete({ id: d.idserviceTutelle }),
    onDeletedRoute: () => ERoutes.serviceTutelle
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      code: Yup.string().required(t(ETLCodes.Required)),
      nom: Yup.string().required(t(ETLCodes.Required)),
      codePays: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  const Localite = formik => {
    return (
      <LocaliteSelect
        codePostal={formik.values.codePostal}
        codePays={formik.values.codePays}
        name={"localite"}
        codePostalTextName="codePostal"
        disableIfNoCp={false}
      />
    );
  };

  const Commune = formik => {
    return <CommuneSelect codePostal={formik.values.codePostal} codePays={formik.values.codePays} name={"commune"} />;
  };

  const [pays, loadingPays] = useReferential(a => a.referentialGetPays(), false, []);
  const [directionTerritoriale, loadingDirectionTerritoriale] = useReferential(
    a => a.referentialGetDirectionTerritoriale(),
    true,
    []
  );

  const [usersCop, usersCopLoading] = useReferential(a =>
    a.referentialGetServiceUsersByRoles({ ELevelName: [ELevelName.COP], idService: idServiceTutelle })
  );

  return (
    <>
      {data && (
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={editMode}
          validationSchema={FormSchema}
          loading={loading}
          onCancel={() => history.push(ERoutes.serviceTutelle)}
          onDelete={deleteItem}
          showDeleteButton={+idServiceTutelle > 0}
          saving={saving}
          deleting={deleting}
        >
          <FieldGroup columns={[3, 9]}>
            <FGTextInput name="code" maxLength={1} label={t(ETLCodes.Code)} forceCase="upper" />
            <FGEmpty />
          </FieldGroup>
          <FieldGroup columns={2}>
            <FGTextInput name="nom" maxLength={100} label={t(ETLCodes.Nom)} />
            <FGEmpty />
            <FGWalterSelectInput
              name="iddirectionTerritoriale"
              loading={loadingDirectionTerritoriale}
              label={t(ETLCodes.DirectionsTerritoriales)}
              items={directionTerritoriale}
            />
            <FGEmpty />
            <FGWalterSelectInput
              name="idcop"
              loading={usersCopLoading}
              label={t(ETLCodes.CopPrincipal)}
              items={usersCop}
              autoSelectIfOne
              readonly={usersCop?.length === 1}
            />
            <FGEmpty />
            <FGWalterSelectInput name="codePays" loading={loadingPays} label={t(ETLCodes.Pays)} items={pays} />
            <FGEmpty />
            <FGTextAreaInput name="adresse" maxLength={100} label={t(ETLCodes.Adresse)} />
            <FGEmpty />
          </FieldGroup>
          <FieldGroup columns={[3, 9]}>
            <FGTextInput name="codePostal" maxLength={6} label={t(ETLCodes.CodePostal)} />
          </FieldGroup>
          <FieldGroup columns={2}>
            <FGCustomPanel>{ctx => Localite(ctx.formik)}</FGCustomPanel>
            <FGCustomPanel>{ctx => Commune(ctx.formik)}</FGCustomPanel>
            <FGTextInput name="telephone" maxLength={20} label={t(ETLCodes.Tel)} />
            <FGTextInput name="fax" maxLength={20} label={t(ETLCodes.Fax)} />
            <FGEmpty />
            <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
          </FieldGroup>
        </SmallFormGenerator>
      )}
    </>
  );
};
