import * as React from "react";
import { matchPath, useLocation } from 'react-router';
import { ContactDetail, ContactDetailDoublons } from '.';
import { ContactDoublonSearchDtoFromJSON } from '../../../../../api';
import { ERoutes } from '../../../../../AppRouter';

export interface IContactDetailSwitchProps { }

export const ContactDetailSwitch: React.FunctionComponent<IContactDetailSwitchProps> = props => {
  const [sObj, setSObj] = React.useState(ContactDoublonSearchDtoFromJSON({}));
  const { pathname } = useLocation();
  if (
    matchPath(pathname, {
      path: `${ERoutes.contacts}/-1/:tab/checkDoublons`
    })
  ) {
    return <ContactDetailDoublons setBaseData={setSObj} />;
  } else {
    return <ContactDetail baseData={sObj} />;
  }
};