/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IAggregateResult } from './IAggregateResult';
import {
    IAggregateResultFromJSON,
    IAggregateResultFromJSONTyped,
    IAggregateResultToJSON,
} from './IAggregateResult';
import type { LieuFormationFichierGridDto } from './LieuFormationFichierGridDto';
import {
    LieuFormationFichierGridDtoFromJSON,
    LieuFormationFichierGridDtoFromJSONTyped,
    LieuFormationFichierGridDtoToJSON,
} from './LieuFormationFichierGridDto';

/**
 * 
 * @export
 * @interface LieuFormationFichierGridDtoPaginatedResults
 */
export interface LieuFormationFichierGridDtoPaginatedResults {
    /**
     * 
     * @type {number}
     * @memberof LieuFormationFichierGridDtoPaginatedResults
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<LieuFormationFichierGridDto>}
     * @memberof LieuFormationFichierGridDtoPaginatedResults
     */
    results?: Array<LieuFormationFichierGridDto> | null;
    /**
     * 
     * @type {Array<IAggregateResult>}
     * @memberof LieuFormationFichierGridDtoPaginatedResults
     */
    aggregateResults?: Array<IAggregateResult> | null;
}

/**
 * Check if a given object implements the LieuFormationFichierGridDtoPaginatedResults interface.
 */
export function instanceOfLieuFormationFichierGridDtoPaginatedResults(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LieuFormationFichierGridDtoPaginatedResultsFromJSON(json: any): LieuFormationFichierGridDtoPaginatedResults {
    return LieuFormationFichierGridDtoPaginatedResultsFromJSONTyped(json, false);
}

export function LieuFormationFichierGridDtoPaginatedResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LieuFormationFichierGridDtoPaginatedResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'results': !exists(json, 'results') ? undefined : (json['results'] === null ? null : (json['results'] as Array<any>).map(LieuFormationFichierGridDtoFromJSON)),
        'aggregateResults': !exists(json, 'aggregateResults') ? undefined : (json['aggregateResults'] === null ? null : (json['aggregateResults'] as Array<any>).map(IAggregateResultFromJSON)),
    };
}

export function LieuFormationFichierGridDtoPaginatedResultsToJSON(value?: LieuFormationFichierGridDtoPaginatedResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'results': value.results === undefined ? undefined : (value.results === null ? null : (value.results as Array<any>).map(LieuFormationFichierGridDtoToJSON)),
        'aggregateResults': value.aggregateResults === undefined ? undefined : (value.aggregateResults === null ? null : (value.aggregateResults as Array<any>).map(IAggregateResultToJSON)),
    };
}

