import { Classes } from "@blueprintjs/core";
import { isSameDay, startOfDay } from "date-fns";
import { DataTable, FGTextInput, useFGContext, useGridState } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";

import { BaremeTheoriqueDto, ContratApi, ContratEditDto, ETypeContrat } from "../../../../../api";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

const Container = styled.div`
  display: flex;
`;

export interface IContratBaremeListProps {
  readonly?: boolean;
  typeContrat: ETypeContrat;
}

export const ContratBaremeList: React.FunctionComponent<IContratBaremeListProps> = ({
  readonly = false,
  typeContrat
}) => {
  const { t } = useTl();
  const ctx = useFGContext<ContratEditDto>();
  const api = useApiService(ContratApi);

  const tableState = useGridState<any>({
    serverMode: false,
    enablePagination: false,
    enableFilter: false,
    availablePageSizes: [9999],
    pageSize: 9999
  });

  const { setData } = tableState;

  React.useEffect(() => {
    if (!!ctx?.formik?.values?.baremes) {
      setData(ctx?.formik?.values?.baremes);
    }
  }, [ctx?.formik?.values?.baremes, setData]);

  const fetchBaremes = React.useCallback(async () => {
    if (!ctx?.formik?.values?.iddureeContrat || (typeContrat === ETypeContrat.CS && !ctx?.formik?.values?.dateDebut))
      return [];
    const oldCs = typeContrat === ETypeContrat.CS && ctx?.formik?.values?.dateDebut < startOfDay(new Date(2023, 8, 1));
    return await api.contratGetBaremesByDuree({ iddureeContrat: ctx?.formik?.values?.iddureeContrat, oldCs });
  }, [api, ctx?.formik?.values?.dateDebut, ctx?.formik?.values?.iddureeContrat, typeContrat]);
  const { data: baremes, isFetching: bLoading } = useQuery(
    ["bareme-duree", ctx?.formik?.values?.iddureeContrat, ctx?.formik?.values?.dateDebut],
    fetchBaremes
  );

  const [previousDuree, setPreviousDuree] = React.useState<number>(
    ctx?.formik?.values?.iddureeContrat ?? ctx?.formik?.initialValues?.iddureeContrat
  );
  const [previousDebut, setPreviousDebut] = React.useState<Date>(
    ctx?.formik?.values?.dateDebut ?? ctx?.formik?.initialValues?.dateDebut
  );
  React.useEffect(() => {
    if (
      !ctx?.loading &&
      !bLoading &&
      (+(ctx?.formik?.values?.iddureeContrat ?? 0) !== +(previousDuree ?? 0) ||
        (!!ctx?.formik?.values?.dateDebut &&
          !!previousDebut &&
          !isSameDay(ctx?.formik?.values?.dateDebut, previousDebut))) &&
      (typeContrat !== ETypeContrat.CS || !!ctx?.formik?.values?.dateDebut)
    ) {
      const sortedData = baremes.sort((a, b) => {
        if (a.libelle.includes("préparatoire")) return -1;
        if (b.libelle.includes("préparatoire")) return 1;
        return a.libelle < b.libelle ? -1 : 1;
      });
      ctx.formik.setFieldValue("baremes", sortedData);
      ctx.formik.setFieldTouched("baremes");
      setPreviousDuree(ctx?.formik?.values?.iddureeContrat);
      setPreviousDebut(ctx?.formik?.values?.dateDebut);
    }
  }, [
    bLoading,
    baremes,
    ctx.formik,
    ctx.formik?.values?.iddureeContrat,
    ctx?.loading,
    previousDebut,
    previousDuree,
    setData,
    typeContrat
  ]);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "libelle"
      },
      {
        header: () => t(ETLCodes.BaremeTheorique),
        fieldName: "remunTheorique",
        hidden: !!ctx?.formik?.values?.idariane,
        computed: true
      },
      {
        header: () => t(ETLCodes.RemunerationReelle),
        computed: true,
        fieldName: "remunReelle",
        render: (row: BaremeTheoriqueDto, idx: number) => (
          <FGTextInput type="number" noLabel name={`baremes[${idx}].remunReelle`} readonly={readonly} />
        )
      }
    ],
    [ctx?.formik?.values?.idariane, readonly, t]
  );

  return (
    <Container>
      <label className={Classes.LABEL}></label>
      <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} columns={columns} renderNoData={<></>} />
    </Container>
  );
};
