import * as React from "react";
import { FcbReportAccesPermissionDto, ReportApi } from "../../../../../api";
import { useTl, useApiService } from "../../../../../hooks";
import { useGridState, IDataTableColumn, FieldSet, DataTable } from "nsitools-react";
import { Checkbox } from "@blueprintjs/core";
import { ETLCodes } from "../../../../../locales";

export interface IImpressionAccesPermissionsGridProps {
  idImpression: number;
  acces: FcbReportAccesPermissionDto[];
  loading: boolean;
}

export const ImpressionAccesPermissionsGrid: React.FunctionComponent<IImpressionAccesPermissionsGridProps> = ({
  idImpression,
  acces = [],
  loading
}) => {
  const { t } = useTl();
  const api = useApiService(ReportApi);

  const tableState = useGridState<FcbReportAccesPermissionDto>({
    serverMode: false,
    enablePagination: false,
    enableFilter: false
  });

  const { setData } = tableState;

  React.useEffect(() => {
    setData(acces);
  }, [acces, setData]);

  const toggleSelection = React.useCallback(
    (row: FcbReportAccesPermissionDto) => {
      row.selected = !row.selected;
      row.idimpression = idImpression;
      let nextData = [...acces];
      setData(nextData);
      api.reportSaveAccesPermissions({ FcbReportAccesPermissionDto: row });
    },
    [acces, api, idImpression, setData]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        render: (item: FcbReportAccesPermissionDto) => (
          <Checkbox
            checked={item.selected}
            onChange={e => {
              toggleSelection(item);
            }}
          />
        )
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      }
    ],
    [t, toggleSelection]
  );

  return (
    <FieldSet title={t(ETLCodes.NiveauAcces)} collapsable defaultCollapsed>
      <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
    </FieldSet>
  );
};
