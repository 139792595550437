import * as React from "react";
import { useParams } from "react-router";
import { DemandeFormationUpdate } from "./DemandeFormationUpdate";
import { DemandeFormationCreate } from "./DemandeFormationCreate";

export interface IDemandeFormationProps {}

export const DemandeFormation: React.FunctionComponent<IDemandeFormationProps> = () => {
  const { idDemande } = useParams<{
    id: string;
    state: string;
    idDemande: string;
  }>();

  return <>{+idDemande > 0 ? <DemandeFormationUpdate /> : <DemandeFormationCreate />}</>;
};
