/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FilterCriteriaInfo,
  PrintingQueueDto,
  PrintingQueueGridDtoPaginatedResults,
  PrintingQueueIdsDto,
  PrintingQueueSearchDto,
  ReferentialItemDto,
  SelectedPrintingsDto,
} from '../models/index';
import {
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    PrintingQueueDtoFromJSON,
    PrintingQueueDtoToJSON,
    PrintingQueueGridDtoPaginatedResultsFromJSON,
    PrintingQueueGridDtoPaginatedResultsToJSON,
    PrintingQueueIdsDtoFromJSON,
    PrintingQueueIdsDtoToJSON,
    PrintingQueueSearchDtoFromJSON,
    PrintingQueueSearchDtoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
    SelectedPrintingsDtoFromJSON,
    SelectedPrintingsDtoToJSON,
} from '../models/index';

export interface PrintingQueueBaseSearchRequest {
    PrintingQueueSearchDto?: PrintingQueueSearchDto;
}

export interface PrintingQueueCancelPrintingRequest {
    idprintingQueue: number;
}

export interface PrintingQueueCancelSelectedRequest {
    SelectedPrintingsDto?: SelectedPrintingsDto;
}

export interface PrintingQueueDeletePrintingRequest {
    idprintingQueue?: number;
}

export interface PrintingQueueDeleteSelectedRequest {
    SelectedPrintingsDto?: SelectedPrintingsDto;
}

export interface PrintingQueueGetPrintingQueueRequest {
    showHidden?: boolean;
}

export interface PrintingQueueGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface PrintingQueueGetSelectItemsRequest {
    searchField: string;
}

export interface PrintingQueueMaskSelectedRequest {
    SelectedPrintingsDto?: SelectedPrintingsDto;
}

export interface PrintingQueueRetryPrintingRequest {
    idprintingQueue: number;
}

export interface PrintingQueueSearchIdsRequest {
    PrintingQueueSearchDto?: PrintingQueueSearchDto;
}

export interface PrintingQueueShowPrintingRequest {
    idprintingQueue: number;
}

/**
 * 
 */
export class PrintingQueueApi extends runtime.BaseAPI {

    /**
     */
    async printingQueueBaseSearchRaw(requestParameters: PrintingQueueBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PrintingQueueGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PrintingQueue/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PrintingQueueSearchDtoToJSON(requestParameters.PrintingQueueSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrintingQueueGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async printingQueueBaseSearch(requestParameters: PrintingQueueBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PrintingQueueGridDtoPaginatedResults> {
        const response = await this.printingQueueBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async printingQueueCancelPrintingRaw(requestParameters: PrintingQueueCancelPrintingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idprintingQueue === null || requestParameters.idprintingQueue === undefined) {
            throw new runtime.RequiredError('idprintingQueue','Required parameter requestParameters.idprintingQueue was null or undefined when calling printingQueueCancelPrinting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PrintingQueue/{idprintingQueue}/cancel`.replace(`{${"idprintingQueue"}}`, encodeURIComponent(String(requestParameters.idprintingQueue))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async printingQueueCancelPrinting(requestParameters: PrintingQueueCancelPrintingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.printingQueueCancelPrintingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async printingQueueCancelSelectedRaw(requestParameters: PrintingQueueCancelSelectedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PrintingQueue/cancel-selected`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SelectedPrintingsDtoToJSON(requestParameters.SelectedPrintingsDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async printingQueueCancelSelected(requestParameters: PrintingQueueCancelSelectedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.printingQueueCancelSelectedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async printingQueueDeletePrintingRaw(requestParameters: PrintingQueueDeletePrintingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idprintingQueue !== undefined) {
            queryParameters['idprintingQueue'] = requestParameters.idprintingQueue;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PrintingQueue`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async printingQueueDeletePrinting(requestParameters: PrintingQueueDeletePrintingRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.printingQueueDeletePrintingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async printingQueueDeleteSelectedRaw(requestParameters: PrintingQueueDeleteSelectedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PrintingQueue/delete-selected`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SelectedPrintingsDtoToJSON(requestParameters.SelectedPrintingsDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async printingQueueDeleteSelected(requestParameters: PrintingQueueDeleteSelectedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.printingQueueDeleteSelectedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async printingQueueGetPrintingQueueRaw(requestParameters: PrintingQueueGetPrintingQueueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PrintingQueueDto>>> {
        const queryParameters: any = {};

        if (requestParameters.showHidden !== undefined) {
            queryParameters['showHidden'] = requestParameters.showHidden;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PrintingQueue`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PrintingQueueDtoFromJSON));
    }

    /**
     */
    async printingQueueGetPrintingQueue(requestParameters: PrintingQueueGetPrintingQueueRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PrintingQueueDto>> {
        const response = await this.printingQueueGetPrintingQueueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async printingQueueGetSearchCriteriasRaw(requestParameters: PrintingQueueGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PrintingQueue/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async printingQueueGetSearchCriterias(requestParameters: PrintingQueueGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.printingQueueGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async printingQueueGetSelectItemsRaw(requestParameters: PrintingQueueGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling printingQueueGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PrintingQueue/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async printingQueueGetSelectItems(requestParameters: PrintingQueueGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.printingQueueGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async printingQueueMaskSelectedRaw(requestParameters: PrintingQueueMaskSelectedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PrintingQueue/mask-selected`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SelectedPrintingsDtoToJSON(requestParameters.SelectedPrintingsDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async printingQueueMaskSelected(requestParameters: PrintingQueueMaskSelectedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.printingQueueMaskSelectedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async printingQueueRetryPrintingRaw(requestParameters: PrintingQueueRetryPrintingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idprintingQueue === null || requestParameters.idprintingQueue === undefined) {
            throw new runtime.RequiredError('idprintingQueue','Required parameter requestParameters.idprintingQueue was null or undefined when calling printingQueueRetryPrinting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PrintingQueue/{idprintingQueue}/retry`.replace(`{${"idprintingQueue"}}`, encodeURIComponent(String(requestParameters.idprintingQueue))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async printingQueueRetryPrinting(requestParameters: PrintingQueueRetryPrintingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.printingQueueRetryPrintingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async printingQueueSearchIdsRaw(requestParameters: PrintingQueueSearchIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PrintingQueueIdsDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PrintingQueue/SearchIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PrintingQueueSearchDtoToJSON(requestParameters.PrintingQueueSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrintingQueueIdsDtoFromJSON(jsonValue));
    }

    /**
     */
    async printingQueueSearchIds(requestParameters: PrintingQueueSearchIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PrintingQueueIdsDto> {
        const response = await this.printingQueueSearchIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async printingQueueShowPrintingRaw(requestParameters: PrintingQueueShowPrintingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.idprintingQueue === null || requestParameters.idprintingQueue === undefined) {
            throw new runtime.RequiredError('idprintingQueue','Required parameter requestParameters.idprintingQueue was null or undefined when calling printingQueueShowPrinting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/PrintingQueue/{idprintingQueue}/show`.replace(`{${"idprintingQueue"}}`, encodeURIComponent(String(requestParameters.idprintingQueue))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async printingQueueShowPrinting(requestParameters: PrintingQueueShowPrintingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.printingQueueShowPrintingRaw(requestParameters, initOverrides);
    }

}
