import { Card, Divider } from "@blueprintjs/core";
import {
  DataTable,
  FGTextAreaInput,
  FieldGroup,
  FieldSet,
  IDataTableColumn,
  useGridState,
  useSearchApi
} from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";

import {
  ClasseRemarqueApi,
  ClasseRemarqueSearch,
  FcbClasseRemarqueDto,
  FcbClasseRemarqueDtoFromJSON
} from "../../../../../api";
import { SmallFormGenerator } from "../../../../../components";
import { useEventsContext } from "../../../../../contexts";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IClasseHoraireRemarqueProps {
  state?: boolean;
}
export const CLASSE_HORAIRE_REMARQUE_SAVED = "CLASSE_HORAIRE_REMARQUE_SAVED";
export const ClasseHoraireRemarque: React.FunctionComponent<IClasseHoraireRemarqueProps> = ({ state }) => {
  const { id } = useParams<{ id: string; tab: string; state: string }>();
  const { t } = useTl();
  const api = useApiService(ClasseRemarqueApi);

  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: false,
    enableFilter: false,
    availablePageSizes: [20],
    pageSize: 20,
    sortKeys: { idRemarque: "ASC" }
  });

  const searchFunction = React.useCallback(
    (sObj?: ClasseRemarqueSearch) => {
      sObj.idClasse = +id;
      return api.classeRemarqueBaseSearch({ ClasseRemarqueSearch: sObj });
    },
    [api, id]
  );

  const { loading: gridLoading, search } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.Remarque),
        fieldName: "remarque"
      },
      {
        header: () => t(ETLCodes.Utilisateur),
        fieldName: "user"
      },
      {
        header: () => t(ETLCodes.Date),
        fieldName: "dateRemarque"
      }
    ],
    [t]
  );

  const { dispatchEvent } = useEventsContext();

  const { data, loading, saveItem, saving } = useCrudApi({
    getApiFn: () => FcbClasseRemarqueDtoFromJSON({ idClasse: +id }),
    saveApiFn: async (d: FcbClasseRemarqueDto) => {
      const value = await api.classeRemarqueSave({
        FcbClasseRemarqueDto: { ...d, dateRemarque: new Date(), idRemarque: 0 }
      });
      dispatchEvent(CLASSE_HORAIRE_REMARQUE_SAVED, value);
      return value;
    },
    deleteApiFn: d => {}
  });

  React.useEffect(() => {
    subscribeToEvent("CLASSE_HORAIRE_REMARQUE_SAVED", search);
    return () => unsubscribeEvent("CLASSE_HORAIRE_REMARQUE_SAVED", search);
  }, [search, subscribeToEvent, unsubscribeEvent]);

  return (
    <>
      {+id > 0 && (
        <>
          <Card>
            <FieldSet title={t(ETLCodes.Remarques)}>
              <div style={{ marginBottom: "1rem" }}>
                <SmallFormGenerator
                  initialValues={data}
                  saving={saving}
                  loading={loading}
                  onSubmit={saveItem}
                  editMode={state}
                >
                  <FieldGroup>
                    <FGTextAreaInput label={t(ETLCodes.Remarque)} name="remarque" rows={4} />
                  </FieldGroup>
                </SmallFormGenerator>
              </div>
              <Divider />
              <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={gridLoading} columns={columns} />
            </FieldSet>
          </Card>
        </>
      )}
    </>
  );
};
