/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FcbClasseMatiereCutGridDto } from './FcbClasseMatiereCutGridDto';
import {
    FcbClasseMatiereCutGridDtoFromJSON,
    FcbClasseMatiereCutGridDtoFromJSONTyped,
    FcbClasseMatiereCutGridDtoToJSON,
} from './FcbClasseMatiereCutGridDto';

/**
 * 
 * @export
 * @interface FcbClasseMatiereCutDto
 */
export interface FcbClasseMatiereCutDto {
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereCutDto
     */
    idClasseMatiere?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereCutDto
     */
    totalNbHeures?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereCutDto
     */
    totalPoints?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereCutDto
     */
    totalExamen?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereCutDto
     */
    niveau?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseMatiereCutDto
     */
    uaa?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseMatiereCutDto
     */
    isVisibleBulletin?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseMatiereCutDto
     */
    sommative?: boolean | null;
    /**
     * 
     * @type {Array<FcbClasseMatiereCutGridDto>}
     * @memberof FcbClasseMatiereCutDto
     */
    cutData?: Array<FcbClasseMatiereCutGridDto> | null;
}

/**
 * Check if a given object implements the FcbClasseMatiereCutDto interface.
 */
export function instanceOfFcbClasseMatiereCutDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbClasseMatiereCutDtoFromJSON(json: any): FcbClasseMatiereCutDto {
    return FcbClasseMatiereCutDtoFromJSONTyped(json, false);
}

export function FcbClasseMatiereCutDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbClasseMatiereCutDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idClasseMatiere': !exists(json, 'idClasseMatiere') ? undefined : json['idClasseMatiere'],
        'totalNbHeures': !exists(json, 'totalNbHeures') ? undefined : json['totalNbHeures'],
        'totalPoints': !exists(json, 'totalPoints') ? undefined : json['totalPoints'],
        'totalExamen': !exists(json, 'totalExamen') ? undefined : json['totalExamen'],
        'niveau': !exists(json, 'niveau') ? undefined : json['niveau'],
        'uaa': !exists(json, 'uaa') ? undefined : json['uaa'],
        'isVisibleBulletin': !exists(json, 'isVisibleBulletin') ? undefined : json['isVisibleBulletin'],
        'sommative': !exists(json, 'sommative') ? undefined : json['sommative'],
        'cutData': !exists(json, 'cutData') ? undefined : (json['cutData'] === null ? null : (json['cutData'] as Array<any>).map(FcbClasseMatiereCutGridDtoFromJSON)),
    };
}

export function FcbClasseMatiereCutDtoToJSON(value?: FcbClasseMatiereCutDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idClasseMatiere': value.idClasseMatiere,
        'totalNbHeures': value.totalNbHeures,
        'totalPoints': value.totalPoints,
        'totalExamen': value.totalExamen,
        'niveau': value.niveau,
        'uaa': value.uaa,
        'isVisibleBulletin': value.isVisibleBulletin,
        'sommative': value.sommative,
        'cutData': value.cutData === undefined ? undefined : (value.cutData === null ? null : (value.cutData as Array<any>).map(FcbClasseMatiereCutGridDtoToJSON)),
    };
}

