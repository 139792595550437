import React, { useCallback, useRef, useState } from "react";
import { Document } from "react-pdf";
import { PDFDocumentProxy } from "pdfjs-dist/types/display/api";
import styled from "styled-components";
import { Colors } from "@blueprintjs/core";
import { useToggle } from "ahooks";
import { downloadPdf, printPdf } from "./pdf-viewer-utils";
import PdfViewerToolbar from "./PdfViewerToolbar";
import PdfViewerPrintLoadingDialog from "./PdfViewerPrintLoadingDialog";
import PdfViewerThumbnails, { PdfViewerThumbnailsProps } from "./PdfViewerThumbnails";
import PdfViewerPages, { PdfViewerPagesProps } from "./PdfViewerPages";

export type ToolbarFlagsType = {
  disabled?: boolean;
  withoutThumbnail?: boolean;
  withoutZoomIn?: boolean;
  withoutZoomOut?: boolean;
  withoutZoomFit?: boolean;
  withoutRotateRight?: boolean;
  withoutRotateLeft?: boolean;
  withoutDownload?: boolean;
  withoutPrint?: boolean;
  withoutPageNav?: boolean;

  dummy?: boolean;
};

export type PdfViewerProps = Pick<PdfViewerThumbnailsProps, "thumbnailWidth"> &
  Pick<PdfViewerPagesProps, "displayAll"> & {
    displayAll?: boolean;
    downloadFileName?: string;
    file: Uint8Array | Blob;
    toolbarOptions?: ToolbarFlagsType;
    scale?: number;
  };

const ViewerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  flex: 1;
  overflow: hidden;
  background-color: ${Colors.LIGHT_GRAY4};
  padding: 0 1rem;
  align-items: center;
  > * {
    padding: 0.5rem 0.25rem 0 0;
  }
`;

const MainContainer = styled(Document)`
  display: flex;
  flex-direction: row;
  height: 100%;
  flex: 1;
  overflow: hidden;
  width: 100%;
  justify-content: center;
  > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

export const PdfViewer = ({
  displayAll,
  thumbnailWidth,
  downloadFileName = "file.pdf",
  file,
  toolbarOptions = {},
  scale: externeScale = 1.0
}: PdfViewerProps) => {
  const pageRef = useRef<HTMLDivElement>();
  const [pageNumber, setPageNumber] = useState(1);
  const [pdf, setPdf] = useState<PDFDocumentProxy>(undefined);
  const [scale, setScale] = useState(externeScale);
  const [height, setHeight] = useState(794);
  const [rotate, setRotate] = useState(0);
  const [loadingPrint, { set: setLoadingPrint }] = useToggle();
  const [showThumbnails, { toggle: onToggleThumbnails }] = useToggle(!displayAll);

  const onPdfLoaded = useCallback((pdf: PDFDocumentProxy) => {
    setPdf(pdf);
  }, []);

  const onZoomIn = useCallback(() => setScale(scale => scale + 0.1), []);
  const onZoomOut = useCallback(() => setScale(scale => scale - 0.1), []);
  const onRotateRight = useCallback(() => setRotate(rotate => (rotate === 270 ? 0 : rotate + 90)), []);
  const onRotateLeft = useCallback(() => setRotate(rotate => (rotate === 0 ? 270 : rotate - 90)), []);

  const onPrint = useCallback(async () => {
    const data: any = await pdf.getData();
    printPdf({
      data,
      onLoadingStart: () => setLoadingPrint(true),
      onLoadingEnd: () => setLoadingPrint(false)
    });
  }, [pdf, setLoadingPrint]);

  const onDownload = useCallback(async () => {
    const data: any = await pdf.getData();
    downloadPdf({ data, downloadFileName });
  }, [downloadFileName, pdf]);

  const fitToHeight = useCallback(() => {
    if (pageRef.current) {
      setScale(1);
      setHeight(pageRef.current.offsetHeight);
    }
  }, []);

  const canNext = React.useMemo(() => pageNumber < pdf?.numPages, [pageNumber, pdf?.numPages]);
  const onNext = React.useCallback(() => {
    if (!canNext) return;
    setPageNumber(pageNumber + 1);
  }, [canNext, pageNumber]);

  const canPrevious = React.useMemo(() => pageNumber > 1, [pageNumber]);
  const onPrevious = React.useCallback(() => {
    if (!canPrevious) return;
    setPageNumber(pageNumber - 1);
  }, [canPrevious, pageNumber]);

  return (
    <ViewerContainer>
      <PdfViewerPrintLoadingDialog visible={loadingPrint} />
      {!toolbarOptions.disabled && (
        <PdfViewerToolbar
          onToggleThumbnails={toolbarOptions.withoutThumbnail ? undefined : onToggleThumbnails}
          onDownload={toolbarOptions.withoutDownload ? undefined : onDownload}
          onPrint={toolbarOptions.withoutPrint ? undefined : onPrint}
          onRotateRight={toolbarOptions.withoutRotateRight ? undefined : onRotateRight}
          onRotateLeft={toolbarOptions.withoutRotateLeft ? undefined : onRotateLeft}
          onZoomIn={toolbarOptions.withoutZoomIn ? undefined : onZoomIn}
          onZoomOut={toolbarOptions.withoutZoomOut ? undefined : onZoomOut}
          fitToHeight={toolbarOptions.withoutZoomFit ? undefined : fitToHeight}
          onNext={toolbarOptions.withoutPageNav ? undefined : onNext}
          onPrevious={toolbarOptions.withoutPageNav ? undefined : onPrevious}
          canNext={toolbarOptions.withoutPageNav ? false : canNext}
          canPrevious={toolbarOptions.withoutPageNav ? false : canPrevious}
        />
      )}
      <MainContainer file={file} onLoadSuccess={onPdfLoaded}>
        {showThumbnails && pdf && (
          <PdfViewerThumbnails pdf={pdf} thumbnailWidth={thumbnailWidth} setPageNumber={setPageNumber} />
        )}
        <PdfViewerPages
          containerRef={pageRef}
          pdf={pdf}
          pageNumber={pageNumber}
          height={height * scale}
          rotate={rotate}
          displayAll={displayAll}
        />
      </MainContainer>
    </ViewerContainer>
  );
};
