/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FcbRechercheApprenantDeliberationTypeDto } from './FcbRechercheApprenantDeliberationTypeDto';
import {
    FcbRechercheApprenantDeliberationTypeDtoFromJSON,
    FcbRechercheApprenantDeliberationTypeDtoFromJSONTyped,
    FcbRechercheApprenantDeliberationTypeDtoToJSON,
} from './FcbRechercheApprenantDeliberationTypeDto';
import type { IAggregateResult } from './IAggregateResult';
import {
    IAggregateResultFromJSON,
    IAggregateResultFromJSONTyped,
    IAggregateResultToJSON,
} from './IAggregateResult';

/**
 * 
 * @export
 * @interface FcbRechercheApprenantDeliberationTypeDtoPaginatedResults
 */
export interface FcbRechercheApprenantDeliberationTypeDtoPaginatedResults {
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheApprenantDeliberationTypeDtoPaginatedResults
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<FcbRechercheApprenantDeliberationTypeDto>}
     * @memberof FcbRechercheApprenantDeliberationTypeDtoPaginatedResults
     */
    results?: Array<FcbRechercheApprenantDeliberationTypeDto> | null;
    /**
     * 
     * @type {Array<IAggregateResult>}
     * @memberof FcbRechercheApprenantDeliberationTypeDtoPaginatedResults
     */
    aggregateResults?: Array<IAggregateResult> | null;
}

/**
 * Check if a given object implements the FcbRechercheApprenantDeliberationTypeDtoPaginatedResults interface.
 */
export function instanceOfFcbRechercheApprenantDeliberationTypeDtoPaginatedResults(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRechercheApprenantDeliberationTypeDtoPaginatedResultsFromJSON(json: any): FcbRechercheApprenantDeliberationTypeDtoPaginatedResults {
    return FcbRechercheApprenantDeliberationTypeDtoPaginatedResultsFromJSONTyped(json, false);
}

export function FcbRechercheApprenantDeliberationTypeDtoPaginatedResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRechercheApprenantDeliberationTypeDtoPaginatedResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'results': !exists(json, 'results') ? undefined : (json['results'] === null ? null : (json['results'] as Array<any>).map(FcbRechercheApprenantDeliberationTypeDtoFromJSON)),
        'aggregateResults': !exists(json, 'aggregateResults') ? undefined : (json['aggregateResults'] === null ? null : (json['aggregateResults'] as Array<any>).map(IAggregateResultFromJSON)),
    };
}

export function FcbRechercheApprenantDeliberationTypeDtoPaginatedResultsToJSON(value?: FcbRechercheApprenantDeliberationTypeDtoPaginatedResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'results': value.results === undefined ? undefined : (value.results === null ? null : (value.results as Array<any>).map(FcbRechercheApprenantDeliberationTypeDtoToJSON)),
        'aggregateResults': value.aggregateResults === undefined ? undefined : (value.aggregateResults === null ? null : (value.aggregateResults as Array<any>).map(IAggregateResultToJSON)),
    };
}

