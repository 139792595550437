import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { InstitutionApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { InstitutionAppernantLiesListPage } from "./InstitutionApprenantLiesListPage";
import { InstitutionDetail } from "./InstitutionDetail";

interface IInstitutionItemPageProps {}

export const InstitutionItemPage: React.FunctionComponent<IInstitutionItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const api = useApiService(InstitutionApi);
  const [displayName] = useApiEffect(() => api.institutionGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.Institutions), route: ERoutes.institution },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsInstitution"
        onChange={newTabId => history.push(`${ERoutes.institution}/${id}/${newTabId}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.institution}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<InstitutionDetail idinstitution={id} editMode={state === "edit"} />}
        />
        <Tab
          id="apprenants"
          title={t(ETLCodes.Apprenant)}
          panel={<InstitutionAppernantLiesListPage />}
          disabled={+id <= 0}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
