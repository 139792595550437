import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";

import { ProjetFinancementApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { EnveloppePFSearch } from "./enveloppePF/EnveloppePFSearch";
import { ProjetFinancementDetailPage } from "./ProjetFinancementDetailPage";

interface IProjetFinancementItemPageProps {}

export const ProjetFinancementItemPage: React.FunctionComponent<IProjetFinancementItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const projetFinancementApi = useApiService(ProjetFinancementApi);

  const [displayName] = useApiEffect(() => projetFinancementApi.projetFinancementGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.ProjetsFinancement), route: ERoutes.projetFinancement },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsProjetFinancement"
        onChange={newTabId => history.push(`${ERoutes.projetFinancement}/${id}/${newTabId}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.projetFinancement}></BackButton>
        <Tab id="detail" title={t(ETLCodes.Detail)} panel={<ProjetFinancementDetailPage />} />
        <Tab id="enveloppe" title={t(ETLCodes.Enveloppe)} panel={<EnveloppePFSearch />} disabled={id === "0"} />
      </ProtectedTabs>
    </PageBase>
  );
};
