/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbAgrementDto
 */
export interface FcbAgrementDto {
    /**
     * 
     * @type {number}
     * @memberof FcbAgrementDto
     */
    idagrement?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbAgrementDto
     */
    dateDecision?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbAgrementDto
     */
    iddecision?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbAgrementDto
     */
    idformation?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FcbAgrementDto
     */
    derogation?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbAgrementDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbAgrementDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbAgrementDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbAgrementDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbAgrementDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbAgrementDto
     */
    valide?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbAgrementDto
     */
    c8?: boolean | null;
}

/**
 * Check if a given object implements the FcbAgrementDto interface.
 */
export function instanceOfFcbAgrementDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbAgrementDtoFromJSON(json: any): FcbAgrementDto {
    return FcbAgrementDtoFromJSONTyped(json, false);
}

export function FcbAgrementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbAgrementDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idagrement': !exists(json, 'idagrement') ? undefined : json['idagrement'],
        'dateDecision': !exists(json, 'dateDecision') ? undefined : (json['dateDecision'] === null ? null : new Date(json['dateDecision'])),
        'iddecision': !exists(json, 'iddecision') ? undefined : json['iddecision'],
        'idformation': !exists(json, 'idformation') ? undefined : json['idformation'],
        'derogation': !exists(json, 'derogation') ? undefined : json['derogation'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'valide': !exists(json, 'valide') ? undefined : json['valide'],
        'c8': !exists(json, 'c8') ? undefined : json['c8'],
    };
}

export function FcbAgrementDtoToJSON(value?: FcbAgrementDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idagrement': value.idagrement,
        'dateDecision': value.dateDecision === undefined ? undefined : (value.dateDecision === null ? null : value.dateDecision.toISOString()),
        'iddecision': value.iddecision,
        'idformation': value.idformation,
        'derogation': value.derogation,
        'remarque': value.remarque,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'valide': value.valide,
        'c8': value.c8,
    };
}

