/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DoublonsGridDto
 */
export interface DoublonsGridDto {
    /**
     * 
     * @type {number}
     * @memberof DoublonsGridDto
     */
    idpersonne1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DoublonsGridDto
     */
    idpersonne2?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DoublonsGridDto
     */
    nom1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoublonsGridDto
     */
    nom2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoublonsGridDto
     */
    prenom1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoublonsGridDto
     */
    prenom2?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DoublonsGridDto
     */
    dateNaissance1?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DoublonsGridDto
     */
    dateNaissance2?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DoublonsGridDto
     */
    registreNational1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoublonsGridDto
     */
    registreNational2?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DoublonsGridDto
     */
    doublonApprenant?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DoublonsGridDto
     */
    doublonRepresentant?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DoublonsGridDto
     */
    doublonFormateur?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DoublonsGridDto
     */
    doublonChefEntreprise?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DoublonsGridDto
     */
    doublonTuteur?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DoublonsGridDto
     */
    doublonContact?: number | null;
}

/**
 * Check if a given object implements the DoublonsGridDto interface.
 */
export function instanceOfDoublonsGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DoublonsGridDtoFromJSON(json: any): DoublonsGridDto {
    return DoublonsGridDtoFromJSONTyped(json, false);
}

export function DoublonsGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DoublonsGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idpersonne1': !exists(json, 'idpersonne1') ? undefined : json['idpersonne1'],
        'idpersonne2': !exists(json, 'idpersonne2') ? undefined : json['idpersonne2'],
        'nom1': !exists(json, 'nom1') ? undefined : json['nom1'],
        'nom2': !exists(json, 'nom2') ? undefined : json['nom2'],
        'prenom1': !exists(json, 'prenom1') ? undefined : json['prenom1'],
        'prenom2': !exists(json, 'prenom2') ? undefined : json['prenom2'],
        'dateNaissance1': !exists(json, 'dateNaissance1') ? undefined : (json['dateNaissance1'] === null ? null : new Date(json['dateNaissance1'])),
        'dateNaissance2': !exists(json, 'dateNaissance2') ? undefined : (json['dateNaissance2'] === null ? null : new Date(json['dateNaissance2'])),
        'registreNational1': !exists(json, 'registreNational1') ? undefined : json['registreNational1'],
        'registreNational2': !exists(json, 'registreNational2') ? undefined : json['registreNational2'],
        'doublonApprenant': !exists(json, 'doublonApprenant') ? undefined : json['doublonApprenant'],
        'doublonRepresentant': !exists(json, 'doublonRepresentant') ? undefined : json['doublonRepresentant'],
        'doublonFormateur': !exists(json, 'doublonFormateur') ? undefined : json['doublonFormateur'],
        'doublonChefEntreprise': !exists(json, 'doublonChefEntreprise') ? undefined : json['doublonChefEntreprise'],
        'doublonTuteur': !exists(json, 'doublonTuteur') ? undefined : json['doublonTuteur'],
        'doublonContact': !exists(json, 'doublonContact') ? undefined : json['doublonContact'],
    };
}

export function DoublonsGridDtoToJSON(value?: DoublonsGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idpersonne1': value.idpersonne1,
        'idpersonne2': value.idpersonne2,
        'nom1': value.nom1,
        'nom2': value.nom2,
        'prenom1': value.prenom1,
        'prenom2': value.prenom2,
        'dateNaissance1': value.dateNaissance1 === undefined ? undefined : (value.dateNaissance1 === null ? null : value.dateNaissance1.toISOString()),
        'dateNaissance2': value.dateNaissance2 === undefined ? undefined : (value.dateNaissance2 === null ? null : value.dateNaissance2.toISOString()),
        'registreNational1': value.registreNational1,
        'registreNational2': value.registreNational2,
        'doublonApprenant': value.doublonApprenant,
        'doublonRepresentant': value.doublonRepresentant,
        'doublonFormateur': value.doublonFormateur,
        'doublonChefEntreprise': value.doublonChefEntreprise,
        'doublonTuteur': value.doublonTuteur,
        'doublonContact': value.doublonContact,
    };
}

