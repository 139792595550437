import { Button, ButtonGroup, Colors, Spinner, Tag } from "@blueprintjs/core";
import { IFGContext, IFGCustomInputProps, useFGContext } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import { ChefEntrepriseSelectorApi } from "../../../api";
import { useAuth } from "../../../contexts";
import { useApiService } from "../../../hooks";
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";
import { useQuery } from "react-query";
import { ERoutes } from "../../../AppRouter";
import { ChefEntrepriseSelectorDialog, FGReadOnlyInput } from "..";
import { ClearButton } from "../..";
import { getIn } from "formik";

const InputContainer = styled.div`
  padding: 0.4rem 0 0 0.6rem;
`;

const StyledTag = styled(Tag)`
  & a {
    color: ${Colors.WHITE};
  }
`;

const NamesContainer = styled.div`
  > * + * {
    margin-left: 5px;
  }
`;

export interface IChefEntrepriseSelectorFieldProps extends Omit<IFGCustomInputProps, "children"> {
  parentChefEntrepriseIds?: number[];
  disabled?: boolean;
  allowMultipleSelection?: boolean;
  ref?: React.Ref<HTMLDivElement>;
  idsiegeSocial?: number;
  canAdd?: boolean;
  filterOnSiegeSocial?: boolean;
  rightElement?: JSX.Element | ((ctx: IFGContext<any>) => JSX.Element);
}

export const ChefEntrepriseSelectorField: React.FunctionComponent<IChefEntrepriseSelectorFieldProps> = ({
  name,
  parentChefEntrepriseIds = [],
  label = null,
  disabled = false,
  className,
  allowMultipleSelection = false,
  ref,
  readonly,
  idsiegeSocial,
  canAdd = true,
  filterOnSiegeSocial = false,
  rightElement,
  ...fgCustomInputProps
}) => {
  const { hasPermission } = useAuth();
  const ctx = useFGContext();
  const api = useApiService(ChefEntrepriseSelectorApi);

  const hasValue = React.useMemo(
    () =>
      (allowMultipleSelection && !isEmpty(getIn(ctx?.formik?.values, name))) ||
      (!allowMultipleSelection && !!getIn(ctx?.formik?.values, name)),
    [allowMultipleSelection, ctx?.formik?.values, name]
  );
  const [selectorDialogOpen, setSelectorDialogOpen] = React.useState(false);
  const [currentSelectedChefEntrepriseIds, setSelectedChefEntrepriseIds] = React.useState<number[]>([]);

  const { data: displayNames, isFetching: loadingDisplayNames } = useQuery(
    ["chef-entreprise-display-names", currentSelectedChefEntrepriseIds, idsiegeSocial],
    async () =>
      api.chefEntrepriseSelectorGetDisplayNames({ request_body: currentSelectedChefEntrepriseIds, idsiegeSocial })
  );

  const clearSelectedChefEntreprise = React.useCallback(() => {
    ctx?.formik?.setFieldValue(name, allowMultipleSelection ? [] : null, true);
    setSelectedChefEntrepriseIds([]);
  }, [allowMultipleSelection, ctx?.formik, name]);

  React.useEffect(() => {
    if (getIn(ctx?.formik?.values, name)) {
      const value = getIn(ctx?.formik?.values, name);
      setSelectedChefEntrepriseIds(isArray(value) ? value : [value]);
    } else {
      setSelectedChefEntrepriseIds([]);
    }
  }, [ctx?.formik?.values, name]);

  const onChefEntreprisesSelected = React.useCallback(
    (selectedChefEntrepriseIds?: number[]) => {
      if (selectedChefEntrepriseIds?.length > 0) {
        ctx?.formik?.setFieldTouched(name);
        ctx?.formik?.setFieldValue(
          name,
          allowMultipleSelection ? selectedChefEntrepriseIds : selectedChefEntrepriseIds[0],
          true
        );
        setSelectedChefEntrepriseIds(selectedChefEntrepriseIds);
      }
    },
    [allowMultipleSelection, ctx?.formik, name]
  );

  const selectorProps = React.useMemo(
    () => ({
      excludedChefEntrepriseIds: parentChefEntrepriseIds,
      allowMultipleSelection,
      preSelectedIds: currentSelectedChefEntrepriseIds
    }),
    [allowMultipleSelection, parentChefEntrepriseIds, currentSelectedChefEntrepriseIds]
  );

  const openChefEntrepriseSelector = React.useCallback(() => {
    setSelectorDialogOpen(true);
  }, []);

  const getEditRoute = React.useCallback(
    (id: number, idchefEntrepriseSiege: number) =>
      !!idsiegeSocial && idsiegeSocial > 0
        ? `#${ERoutes.siegeSocial}/${idsiegeSocial}/chefsEntreprise/${
            hasPermission("CHEFENTREPRISE", "RW", "DETAIL") ? "edit" : "view"
          }/${idchefEntrepriseSiege}`
        : `#${ERoutes.chefEntreprise}/${id}/detail/${
            hasPermission("CHEFENTREPRISE", "RW", "DETAIL") ? "edit" : "view"
          }`,
    [hasPermission, idsiegeSocial]
  );

  const removeChefEntrepriseId = React.useCallback(
    (chefEntrepriseId: number) => {
      const newChefEntrepriseIds = [...currentSelectedChefEntrepriseIds];
      newChefEntrepriseIds.splice(currentSelectedChefEntrepriseIds.indexOf(chefEntrepriseId), 1);
      ctx?.formik?.setFieldTouched(name);
      ctx?.formik?.setFieldValue(name, newChefEntrepriseIds, true);
      setSelectedChefEntrepriseIds(newChefEntrepriseIds);
    },
    [ctx?.formik, currentSelectedChefEntrepriseIds, name]
  );

  const getChefEntrepriseLink = React.useCallback(
    (id: number, name: string, tags: boolean, idchefEntrepriseSiege: number) => {
      const link = hasPermission("CHEFENTREPRISE", "R", "DETAIL") ? (
        <a href={getEditRoute(id, idchefEntrepriseSiege)} target="_blank" rel="noreferrer" tabIndex={-1} key={id}>
          {name}
        </a>
      ) : (
        name
      );
      return tags ? (
        <StyledTag key={id} onRemove={() => removeChefEntrepriseId(id)}>
          {link}
        </StyledTag>
      ) : (
        link
      );
    },
    [getEditRoute, hasPermission, removeChefEntrepriseId]
  );

  const onClosed = React.useCallback(
    (selectedChefEntrepriseIds?: number[]) => {
      onChefEntreprisesSelected(selectedChefEntrepriseIds);
      setSelectorDialogOpen(false);
    },
    [onChefEntreprisesSelected]
  );

  return (
    <>
      <FGReadOnlyInput
        name={name}
        label={label}
        readonly={readonly}
        leftElement={
          <InputContainer>
            {loadingDisplayNames ? (
              <Spinner size={16} />
            ) : (
              <NamesContainer>
                {displayNames.map(dn =>
                  getChefEntrepriseLink(dn.id, dn.text, allowMultipleSelection, dn.idchefEntrepriseSiege)
                )}
              </NamesContainer>
            )}
          </InputContainer>
        }
        rightElement={
          !readonly &&
          ctx.editMode && (
            <ButtonGroup>
              {hasValue && <ClearButton onClick={clearSelectedChefEntreprise} tabIndex={-1} disabled={disabled} />}
              <Button
                icon="people"
                minimal={true}
                onClick={openChefEntrepriseSelector}
                disabled={disabled}
                tabIndex={-1}
              />
              {rightElement}
            </ButtonGroup>
          )
        }
        {...fgCustomInputProps}
      />
      <ChefEntrepriseSelectorDialog
        onClosed={onClosed}
        onChefEntrepriseSelected={onClosed}
        selectorDialogOpen={selectorDialogOpen}
        idsiegeSocial={idsiegeSocial}
        canAdd={canAdd}
        filterOnSiegeSocial={filterOnSiegeSocial}
        {...selectorProps}
      />
    </>
  );
};
