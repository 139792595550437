/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DisplayNameDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
  RepresentantSelectorGridDtoPaginatedResults,
  RepresentantSelectorSearch,
} from '../models/index';
import {
    DisplayNameDtoFromJSON,
    DisplayNameDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
    RepresentantSelectorGridDtoPaginatedResultsFromJSON,
    RepresentantSelectorGridDtoPaginatedResultsToJSON,
    RepresentantSelectorSearchFromJSON,
    RepresentantSelectorSearchToJSON,
} from '../models/index';

export interface RepresentantSelectorBaseSearchRequest {
    RepresentantSelectorSearch?: RepresentantSelectorSearch;
}

export interface RepresentantSelectorGetDisplayNamesRequest {
    request_body?: Array<number>;
}

export interface RepresentantSelectorGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface RepresentantSelectorGetSelectItemsRequest {
    searchField: string;
}

/**
 * 
 */
export class RepresentantSelectorApi extends runtime.BaseAPI {

    /**
     */
    async representantSelectorBaseSearchRaw(requestParameters: RepresentantSelectorBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RepresentantSelectorGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RepresentantSelector/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RepresentantSelectorSearchToJSON(requestParameters.RepresentantSelectorSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RepresentantSelectorGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async representantSelectorBaseSearch(requestParameters: RepresentantSelectorBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RepresentantSelectorGridDtoPaginatedResults> {
        const response = await this.representantSelectorBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async representantSelectorGetDisplayNamesRaw(requestParameters: RepresentantSelectorGetDisplayNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DisplayNameDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RepresentantSelector/GetDisplayNames`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request_body,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DisplayNameDtoFromJSON));
    }

    /**
     */
    async representantSelectorGetDisplayNames(requestParameters: RepresentantSelectorGetDisplayNamesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DisplayNameDto>> {
        const response = await this.representantSelectorGetDisplayNamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async representantSelectorGetSearchCriteriasRaw(requestParameters: RepresentantSelectorGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RepresentantSelector/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async representantSelectorGetSearchCriterias(requestParameters: RepresentantSelectorGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.representantSelectorGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async representantSelectorGetSelectItemsRaw(requestParameters: RepresentantSelectorGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling representantSelectorGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/RepresentantSelector/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async representantSelectorGetSelectItems(requestParameters: RepresentantSelectorGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.representantSelectorGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
