import * as React from "react";

import { Configuration } from "../api";
import { apiConfiguration } from "../apiConfig";
import { useAuth, useGlobalData } from "../contexts";

export function useApiService<T>(
  type: new (configuration: Configuration) => T,
  abortControllerFn?: () => AbortController
): T {
  const { getAccessToken } = useAuth();
  const { ifapmeSide } = useGlobalData();

  const configuration = React.useMemo(() => {
    if (!ifapmeSide) return;
    const cfg = apiConfiguration(getAccessToken, ifapmeSide);
    cfg.middleware = [
      {
        pre(context) {
          if (!!abortControllerFn) {
            const abortController = abortControllerFn();
            context.init.signal = abortController.signal;
          }
          return Promise.resolve(context);
        },
        post(context) {
          if (context.response.status === 401) {
            //TODO: REDIRECT TO
            // logout("Unauthorized Access");
          }
          return Promise.resolve(context.response);
        }
      }
    ];

    return new Configuration(cfg);
  }, [abortControllerFn, getAccessToken, ifapmeSide]);
  return React.useMemo(() => new type(configuration), [configuration, type]);
}

export function useAbortableApiServiceFactory<T>(type: new (configuration: Configuration) => T) {
  const { logout, getAccessToken } = useAuth();
  const { ifapmeSide } = useGlobalData();
  return React.useCallback(() => {
    if (!ifapmeSide) return;
    const abortController = new AbortController();

    const cfg = apiConfiguration(getAccessToken, ifapmeSide);
    cfg.middleware = [
      {
        pre(context) {
          context.init.signal = abortController.signal;
          return Promise.resolve(context);
        },
        post(context) {
          if (context.response.status === 401) {
            logout("Unauthorized Access");
          }
          return Promise.resolve(context.response);
        }
      }
    ];
    return { api: new type(new Configuration(cfg)), abortController };
  }, [getAccessToken, ifapmeSide, logout, type]);
}

// export function useAbortableApiService<T>(type: new (configuration: Configuration) => T) {
//   const lastAbortController = React.useRef<AbortController>();
//   const currentAbortController = React.useRef<AbortController>();
//   const abortControllerFactory = React.useCallback(() => {
//     const abortController = new AbortController();

//     const controllerToCopy = currentAbortController.current;
//     lastAbortController.current = controllerToCopy;
//     currentAbortController.current = abortController;

//     return abortController;
//   }, []);

//   return { api: useApiService(type, abortControllerFactory), lastAbortControllerRef: lastAbortController };
// }

