import { Button, Intent, Tab, Tabs, Text } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";

import { DeliberationBulletinDetailList } from ".";
import { BulletinApprenantRecaputilatifApi, BulletinApprenantTypeApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { useEventsContext } from "../../../../../contexts";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IDeliberationBulletinTabsProps {}

const StyledText = styled(Text)`
  align-self: center;
  font-weight: bold;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const DeliberationBulletinTabs: React.FunctionComponent<IDeliberationBulletinTabsProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id, idApprenant, annee, coursTypeTab } = useParams<{
    id: string;
    tab: string;
    idApprenant: string;
    annee: string;
    coursTypeTab: string;
    state: string;
  }>();
  const apprenantId = React.useMemo(() => +idApprenant, [idApprenant]);
  const api = useApiService(BulletinApprenantRecaputilatifApi);
  const batApi = useApiService(BulletinApprenantTypeApi);
  const [refreshingBulletin, setRefreshingBulletin] = React.useState(false);

  const { dispatchEvent } = useEventsContext();

  const [cours] = useApiEffect(async () => {
    return await api.bulletinApprenantRecaputilatifGetListTypeCoursInscription({
      id: apprenantId,
      anneeScolaire: annee
    });
  }, [apprenantId, annee]);

  const [coursType, setCoursType] = React.useState("");

  React.useEffect(() => {
    if (cours && coursTypeTab) {
      setCoursType(cours.includes(coursTypeTab) ? coursTypeTab ?? cours[0] : cours[0]);
    } else if (coursTypeTab || cours) {
      setCoursType(coursTypeTab);
    }
  }, [cours, coursType, coursTypeTab]);

  const refreshBulletin = React.useCallback(async () => {
    setRefreshingBulletin(true);
    await batApi.bulletinApprenantTypeRefreshPresenceDeliberation({
      idApprenant: +idApprenant,
      anneeScolaire: annee
    });
    await batApi.bulletinApprenantTypeRefreshDeliberationAsync({
      idApprenant: +idApprenant,
      anneeScolaire: annee
    });
    setRefreshingBulletin(false);
    dispatchEvent("DELIBERATION_REFRESH");
  }, [annee, batApi, dispatchEvent, idApprenant]);

  return (
    cours &&
    cours.length > 0 && (
      <>
        <ButtonContainer>
          <Button
            icon={IconNames.REFRESH}
            onClick={refreshBulletin}
            text={t(ETLCodes.RefreshBulletin)}
            intent={Intent.NONE}
            loading={refreshingBulletin}
          />
        </ButtonContainer>
        <Tabs
          id="TabsApprenantResultatType"
          onChange={newTabId => {
            history.push(`${ERoutes.deliberationType}/${id}/detail/${apprenantId}/${annee}/${newTabId}`);
            dispatchEvent("DELIBERATION_ANNEE_CHANGED");
          }}
          selectedTabId={coursType}
          renderActiveTabPanelOnly
          large
        >
          <StyledText>{t(ETLCodes.CourseType)} : </StyledText>
          {cours.map(c => {
            return (
              <Tab
                key={c}
                id={c}
                title={c}
                panel={
                  <DeliberationBulletinDetailList idApprenant={apprenantId} anneeScolaire={annee} idTypeCours={c} />
                }
                panelClassName="full-width-panel"
              />
            );
          })}
        </Tabs>
      </>
    )
  );
};
