import * as React from "react";

import { ETextSearchType, SoldeApprenantApi, SoldeApprenantSearch } from "../../../../../api";
import { SearchTablePage } from "../../../../../components";
import { useGlobalData } from "../../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

interface ISoldeSearchTableProps {
  apprenantId: number;
}

export const SoldeSearchTable: React.FunctionComponent<ISoldeSearchTableProps> = ({ apprenantId }) => {
  const { t } = useTl();

  const api = useApiService(SoldeApprenantApi);
  const { currentAnneeScolaire } = useGlobalData();

  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "nomCentre"
      },
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "anneeScolaire"
      },
      {
        header: () => t(ETLCodes.TotalMontantDu),
        fieldName: "totalMontantDu"
      },
      {
        header: () => t(ETLCodes.TotalMontantPaye),
        fieldName: "totalMontantPaye"
      },
      {
        header: () => t(ETLCodes.TotalMinerval),
        fieldName: "totalMinerval"
      }
    ],
    [t]
  );

  const getCriteriasFn = React.useCallback(() => api.soldeApprenantGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const apiFactory = useAbortableApiServiceFactory(SoldeApprenantApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: SoldeApprenantSearch) => {
      sObj.idapprenant = apprenantId;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.soldeApprenantBaseSearch({ SoldeApprenantSearch: sObj });
    },
    [apiFactory, apprenantId]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    apprenantId > 0 && (
      <SearchTablePage
        columns={columns}
        getCriteriasFunction={getCriteriasFn}
        searchFunction={searchFn}
        onAbort={onAbort}
        sortKeys={{ anneeScolaire: "DESC" }}
        title={ETLCodes.Soldes}
        defaultCriterias={[
          {
            criteria: "AnneeScolaire",
            searchMode: ETextSearchType.Equals,
            value: currentAnneeScolaire?.idanneeScolaire
          }
        ]}
        withCard={false}
        overrideListValues={{ idcentre: c => c.displayValue }}
      />
    )
  );
};
