import * as React from "react";
import { PageBase } from "../../components";
import { Card, Button, Icon, Colors } from "@blueprintjs/core";
import styled from "styled-components";
import { ETLCodes } from "../../locales";
import { useTheme, useTl } from "../../hooks";
import { useAuth } from "../../contexts";
import { IconNames } from "@blueprintjs/icons";

export interface IUnauthorizedPageProps {}

const MainContainer = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const StyledCard = styled(Card)`
  width: 400px;
  height: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const StyledH2 = styled.h2`
  text-align: center;
`;

export const UnauthorizedPage: React.FunctionComponent<IUnauthorizedPageProps> = () => {
  const { t } = useTl();
  const { user, logout, userPermissions } = useAuth();
  const { devMode, ifapmeSide } = useTheme();
  const hasAnyPermission = React.useMemo(() => userPermissions?.length > 0, [userPermissions]);
  const userAllowed = React.useMemo(
    () =>
      user &&
      (devMode ||
        (user.ifapmeSide?.includes("Hope") && ifapmeSide === "hope") ||
        (user.ifapmeSide?.includes("Walter") && ifapmeSide === "walter")),
    [devMode, ifapmeSide, user]
  );

  const appName = React.useMemo(() => `${ifapmeSide[0].toUpperCase()}${ifapmeSide.slice(1)}`, [ifapmeSide]);

  return (
    <PageBase withCard={false}>
      <MainContainer>
        <StyledCard>
          <Icon icon={IconNames.DELETE} iconSize={40} color={Colors.RED3} />
          <StyledH2>
            {userAllowed
              ? hasAnyPermission
                ? t(ETLCodes.NotAuthorizedForModule)
                : t(ETLCodes.NotAuthorized)
              : t(ETLCodes.NotAllowed)}
          </StyledH2>

          <h3>
            {!userAllowed && t(ETLCodes.UserNotMatchingApp, { appName })}
            {t(ETLCodes.ReferToAdminOrRelogin)}
          </h3>
          <Button text={t(ETLCodes.Logout)} onClick={() => logout()} intent="primary" icon={IconNames.LOG_OUT}></Button>
        </StyledCard>
      </MainContainer>
    </PageBase>
  );
};
