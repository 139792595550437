/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AriReassignmentDto
 */
export interface AriReassignmentDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AriReassignmentDto
     */
    aris?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AriReassignmentDto
     */
    idsApprenant?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof AriReassignmentDto
     */
    newIdAri?: number | null;
}

/**
 * Check if a given object implements the AriReassignmentDto interface.
 */
export function instanceOfAriReassignmentDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AriReassignmentDtoFromJSON(json: any): AriReassignmentDto {
    return AriReassignmentDtoFromJSONTyped(json, false);
}

export function AriReassignmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AriReassignmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aris': !exists(json, 'aris') ? undefined : json['aris'],
        'idsApprenant': !exists(json, 'idsApprenant') ? undefined : json['idsApprenant'],
        'newIdAri': !exists(json, 'newIdAri') ? undefined : json['newIdAri'],
    };
}

export function AriReassignmentDtoToJSON(value?: AriReassignmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aris': value.aris,
        'idsApprenant': value.idsApprenant,
        'newIdAri': value.newIdAri,
    };
}

