import { Tab } from "@blueprintjs/core";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { ModuleCreaDetailPage } from "..";
import { ModuleCreaApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IModuleCreaItemPageProps {}

export const ModuleCreaItemPage: React.FunctionComponent<IModuleCreaItemPageProps> = props => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const idModuleCrea = React.useMemo(() => +id, [id]);
  const history = useHistory();

  const moduleCreaApi = useApiService(ModuleCreaApi);
  const fetchDisplayName = React.useCallback(() => {
    return moduleCreaApi.moduleCreaGetDisplayName({ id: +id });
  }, [moduleCreaApi, id]);
  const { data: displayName } = useQuery(["module-crea-display-name", id], fetchDisplayName);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.ModuleCrea), route: ERoutes.moduleCrea },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="Tabs"
        onChange={newTabId => history.push(`${ERoutes.moduleCrea}/${id}/${newTabId}/${state}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.moduleCrea} />
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<ModuleCreaDetailPage editMode={state === "edit"} idmoduleCrea={idModuleCrea} />}
        />
      </ProtectedTabs>
    </PageBase>
  );
};

export default ModuleCreaItemPage;
