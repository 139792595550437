import * as React from "react";

export interface ITimeDisplayProps {
  time: string;
}

export const TimeDisplay: React.FunctionComponent<ITimeDisplayProps> = ({ time }) => {
  const timeParsed = React.useMemo(() => {
    const parts = time.split(":");
    if (parts.length === 3) {
      return parts[0] + ":" + parts[1];
    } else {
      return time;
    }
  }, [time]);
  return <>{timeParsed}</>;
};
