/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbMetierDetailDto,
  FcbMetierDto,
  FcbMetierGridDtoPaginatedResults,
  FilterCriteriaInfo,
  MetierSearch,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    FcbMetierDetailDtoFromJSON,
    FcbMetierDetailDtoToJSON,
    FcbMetierDtoFromJSON,
    FcbMetierDtoToJSON,
    FcbMetierGridDtoPaginatedResultsFromJSON,
    FcbMetierGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    MetierSearchFromJSON,
    MetierSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface MetierBaseSearchRequest {
    MetierSearch?: MetierSearch;
}

export interface MetierDeleteRequest {
    id?: number;
}

export interface MetierGetRequest {
    id?: number;
}

export interface MetierGetCodeMetierRequest {
    id?: number;
}

export interface MetierGetDisplayNameRequest {
    id?: number;
}

export interface MetierGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface MetierGetSelectItemsRequest {
    searchField: string;
}

export interface MetierSaveRequest {
    FcbMetierDto?: FcbMetierDto;
}

export interface MetierSearchMetierIdsRequest {
    MetierSearch?: MetierSearch;
}

export interface MetierStadeIndiceMetierExistsRequest {
    code?: string;
    idStade?: number;
}

/**
 * 
 */
export class MetierApi extends runtime.BaseAPI {

    /**
     */
    async metierBaseSearchRaw(requestParameters: MetierBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbMetierGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Metier/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MetierSearchToJSON(requestParameters.MetierSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbMetierGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async metierBaseSearch(requestParameters: MetierBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbMetierGridDtoPaginatedResults> {
        const response = await this.metierBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async metierDeleteRaw(requestParameters: MetierDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Metier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async metierDelete(requestParameters: MetierDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.metierDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async metierGetRaw(requestParameters: MetierGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbMetierDetailDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Metier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbMetierDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async metierGet(requestParameters: MetierGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbMetierDetailDto> {
        const response = await this.metierGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async metierGetCodeMetierRaw(requestParameters: MetierGetCodeMetierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Metier/GetCodeMetier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async metierGetCodeMetier(requestParameters: MetierGetCodeMetierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.metierGetCodeMetierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async metierGetDisplayNameRaw(requestParameters: MetierGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Metier/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async metierGetDisplayName(requestParameters: MetierGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.metierGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async metierGetSearchCriteriasRaw(requestParameters: MetierGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Metier/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async metierGetSearchCriterias(requestParameters: MetierGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.metierGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async metierGetSelectItemsRaw(requestParameters: MetierGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling metierGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Metier/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async metierGetSelectItems(requestParameters: MetierGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.metierGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async metierSaveRaw(requestParameters: MetierSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbMetierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Metier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbMetierDtoToJSON(requestParameters.FcbMetierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbMetierDtoFromJSON(jsonValue));
    }

    /**
     */
    async metierSave(requestParameters: MetierSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbMetierDto> {
        const response = await this.metierSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async metierSearchMetierIdsRaw(requestParameters: MetierSearchMetierIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Metier/searchMetierIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MetierSearchToJSON(requestParameters.MetierSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async metierSearchMetierIds(requestParameters: MetierSearchMetierIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.metierSearchMetierIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async metierStadeIndiceMetierExistsRaw(requestParameters: MetierStadeIndiceMetierExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.idStade !== undefined) {
            queryParameters['idStade'] = requestParameters.idStade;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Metier/stadeIndiceExists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async metierStadeIndiceMetierExists(requestParameters: MetierStadeIndiceMetierExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.metierStadeIndiceMetierExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
