/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FileDownloadDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
  SiegeLieuSuiviFichierDto,
  SiegeLieuSuiviFichierGridDtoPaginatedResults,
  SiegeLieuSuiviFichierSearchDto,
  SiegeLieuSuiviFichierUploadDto,
} from '../models/index';
import {
    FileDownloadDtoFromJSON,
    FileDownloadDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
    SiegeLieuSuiviFichierDtoFromJSON,
    SiegeLieuSuiviFichierDtoToJSON,
    SiegeLieuSuiviFichierGridDtoPaginatedResultsFromJSON,
    SiegeLieuSuiviFichierGridDtoPaginatedResultsToJSON,
    SiegeLieuSuiviFichierSearchDtoFromJSON,
    SiegeLieuSuiviFichierSearchDtoToJSON,
    SiegeLieuSuiviFichierUploadDtoFromJSON,
    SiegeLieuSuiviFichierUploadDtoToJSON,
} from '../models/index';

export interface SiegeLieuSuiviFichierBaseSearchRequest {
    SiegeLieuSuiviFichierSearchDto?: SiegeLieuSuiviFichierSearchDto;
}

export interface SiegeLieuSuiviFichierDeleteSuiviFichierRequest {
    id?: number;
}

export interface SiegeLieuSuiviFichierDownloadSuiviFichierRequest {
    id?: number;
}

export interface SiegeLieuSuiviFichierGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface SiegeLieuSuiviFichierGetSelectItemsRequest {
    searchField: string;
}

export interface SiegeLieuSuiviFichierGetSuiviFichierRequest {
    id?: number;
}

export interface SiegeLieuSuiviFichierSaveSuiviFichierRequest {
    SiegeLieuSuiviFichierDto?: SiegeLieuSuiviFichierDto;
}

export interface SiegeLieuSuiviFichierUploadSuiviFichierRequest {
    SiegeLieuSuiviFichierUploadDto?: SiegeLieuSuiviFichierUploadDto;
}

/**
 * 
 */
export class SiegeLieuSuiviFichierApi extends runtime.BaseAPI {

    /**
     */
    async siegeLieuSuiviFichierBaseSearchRaw(requestParameters: SiegeLieuSuiviFichierBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SiegeLieuSuiviFichierGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeLieuSuiviFichier/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SiegeLieuSuiviFichierSearchDtoToJSON(requestParameters.SiegeLieuSuiviFichierSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiegeLieuSuiviFichierGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async siegeLieuSuiviFichierBaseSearch(requestParameters: SiegeLieuSuiviFichierBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SiegeLieuSuiviFichierGridDtoPaginatedResults> {
        const response = await this.siegeLieuSuiviFichierBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeLieuSuiviFichierDeleteSuiviFichierRaw(requestParameters: SiegeLieuSuiviFichierDeleteSuiviFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeLieuSuiviFichier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async siegeLieuSuiviFichierDeleteSuiviFichier(requestParameters: SiegeLieuSuiviFichierDeleteSuiviFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.siegeLieuSuiviFichierDeleteSuiviFichierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async siegeLieuSuiviFichierDownloadSuiviFichierRaw(requestParameters: SiegeLieuSuiviFichierDownloadSuiviFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeLieuSuiviFichier/Download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async siegeLieuSuiviFichierDownloadSuiviFichier(requestParameters: SiegeLieuSuiviFichierDownloadSuiviFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.siegeLieuSuiviFichierDownloadSuiviFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeLieuSuiviFichierGetSearchCriteriasRaw(requestParameters: SiegeLieuSuiviFichierGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeLieuSuiviFichier/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async siegeLieuSuiviFichierGetSearchCriterias(requestParameters: SiegeLieuSuiviFichierGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.siegeLieuSuiviFichierGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeLieuSuiviFichierGetSelectItemsRaw(requestParameters: SiegeLieuSuiviFichierGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling siegeLieuSuiviFichierGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeLieuSuiviFichier/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async siegeLieuSuiviFichierGetSelectItems(requestParameters: SiegeLieuSuiviFichierGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.siegeLieuSuiviFichierGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeLieuSuiviFichierGetSuiviFichierRaw(requestParameters: SiegeLieuSuiviFichierGetSuiviFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SiegeLieuSuiviFichierDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeLieuSuiviFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiegeLieuSuiviFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async siegeLieuSuiviFichierGetSuiviFichier(requestParameters: SiegeLieuSuiviFichierGetSuiviFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SiegeLieuSuiviFichierDto> {
        const response = await this.siegeLieuSuiviFichierGetSuiviFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeLieuSuiviFichierSaveSuiviFichierRaw(requestParameters: SiegeLieuSuiviFichierSaveSuiviFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SiegeLieuSuiviFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeLieuSuiviFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SiegeLieuSuiviFichierDtoToJSON(requestParameters.SiegeLieuSuiviFichierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiegeLieuSuiviFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async siegeLieuSuiviFichierSaveSuiviFichier(requestParameters: SiegeLieuSuiviFichierSaveSuiviFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SiegeLieuSuiviFichierDto> {
        const response = await this.siegeLieuSuiviFichierSaveSuiviFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async siegeLieuSuiviFichierUploadSuiviFichierRaw(requestParameters: SiegeLieuSuiviFichierUploadSuiviFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SiegeLieuSuiviFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SiegeLieuSuiviFichier/Upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SiegeLieuSuiviFichierUploadDtoToJSON(requestParameters.SiegeLieuSuiviFichierUploadDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SiegeLieuSuiviFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async siegeLieuSuiviFichierUploadSuiviFichier(requestParameters: SiegeLieuSuiviFichierUploadSuiviFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SiegeLieuSuiviFichierDto> {
        const response = await this.siegeLieuSuiviFichierUploadSuiviFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
