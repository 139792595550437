import { FGEmpty, FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { FcbSecteurDtoFromJSON, SecteurApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterCheckboxInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { useReferential } from "../../../../../hooks/useReferential";

interface ISecteurMetierDetailPageProps {
  editMode: boolean;
  codeSecteur: string;
}

export const SecteurMetierDetailPage: React.FunctionComponent<ISecteurMetierDetailPageProps> = ({
  editMode,
  codeSecteur
}) => {
  const { t } = useTl();
  const api = useApiService(SecteurApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      codeSecteur === "-1" ? FcbSecteurDtoFromJSON({ actif: true }) : api.secteurGet({ id: codeSecteur }),
    saveApiFn: d => api.secteurSave({ FcbSecteurDto: d }),
    onSavedRoute: d => `${ERoutes.secteurMetier}/${d.codesecteur}/detail/edit`,
    deleteApiFn: d => api.secteurDelete({ id: d.codesecteur }),
    onDeletedRoute: () => ERoutes.secteurMetier
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      codesecteur: Yup.string()
        .required(t(ETLCodes.Required))
        .max(3)
        .test("code-not-exists", t(ETLCodes.CodeExistsError), async value => {
          if (value === data.codesecteur) return true;
          const validationError = await api.secteurCodeExists({ code: value });
          return validationError.isValid;
        }),
      description: Yup.string().required()
    });
  }, [api, data, t]);

  const [secteursCrea, scLoading] = useReferential(a => a.referentialGetSecteurCrea());

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={editMode}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(ERoutes.secteurMetier)}
        onDelete={deleteItem}
        showDeleteButton={codeSecteur !== "0"}
        saving={saving}
        deleting={deleting}
      >
        <FieldGroup columns={2}>
          <FGTextInput name="codesecteur" label={t(ETLCodes.Code)} maxLength={3} />
          <FGEmpty />
          <FGTextAreaInput name="description" label={t(ETLCodes.Description)} maxLength={200} />
          <FGEmpty />
          <FGWalterSelectInput
            name="idsecteurCrea"
            label={t(ETLCodes.SecteurCrea)}
            items={secteursCrea}
            loading={scLoading}
          />
          <FGEmpty />
          <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
