/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRecherchePlanLocalisationClasseDto
 */
export interface FcbRecherchePlanLocalisationClasseDto {
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    idClasse?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    idStatutLocalisation?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    codeConseiller?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    codeMetier?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    idMetier?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    idDegre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    typeCours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    specificite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    genre?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    idReferentiel?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    codeReferentiel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    financement?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    ppb?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    nbApprenantLocalisation?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    dateCours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    remarqueCentre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    gestionInterne?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    remarqueConseiller?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    idPlanLocalisation?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    nomFin?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRecherchePlanLocalisationClasseDto
     */
    idUser?: number | null;
}

/**
 * Check if a given object implements the FcbRecherchePlanLocalisationClasseDto interface.
 */
export function instanceOfFcbRecherchePlanLocalisationClasseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRecherchePlanLocalisationClasseDtoFromJSON(json: any): FcbRecherchePlanLocalisationClasseDto {
    return FcbRecherchePlanLocalisationClasseDtoFromJSONTyped(json, false);
}

export function FcbRecherchePlanLocalisationClasseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRecherchePlanLocalisationClasseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idClasse': !exists(json, 'idClasse') ? undefined : json['idClasse'],
        'idStatutLocalisation': !exists(json, 'idStatutLocalisation') ? undefined : json['idStatutLocalisation'],
        'codeConseiller': !exists(json, 'codeConseiller') ? undefined : json['codeConseiller'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'codeMetier': !exists(json, 'codeMetier') ? undefined : json['codeMetier'],
        'idMetier': !exists(json, 'idMetier') ? undefined : json['idMetier'],
        'idDegre': !exists(json, 'idDegre') ? undefined : json['idDegre'],
        'typeCours': !exists(json, 'typeCours') ? undefined : json['typeCours'],
        'specificite': !exists(json, 'specificite') ? undefined : json['specificite'],
        'genre': !exists(json, 'genre') ? undefined : json['genre'],
        'idReferentiel': !exists(json, 'idReferentiel') ? undefined : json['idReferentiel'],
        'codeReferentiel': !exists(json, 'codeReferentiel') ? undefined : json['codeReferentiel'],
        'financement': !exists(json, 'financement') ? undefined : json['financement'],
        'ppb': !exists(json, 'ppb') ? undefined : json['ppb'],
        'nbApprenantLocalisation': !exists(json, 'nbApprenantLocalisation') ? undefined : json['nbApprenantLocalisation'],
        'dateCours': !exists(json, 'dateCours') ? undefined : json['dateCours'],
        'remarqueCentre': !exists(json, 'remarqueCentre') ? undefined : json['remarqueCentre'],
        'gestionInterne': !exists(json, 'gestionInterne') ? undefined : json['gestionInterne'],
        'remarqueConseiller': !exists(json, 'remarqueConseiller') ? undefined : json['remarqueConseiller'],
        'idPlanLocalisation': !exists(json, 'idPlanLocalisation') ? undefined : json['idPlanLocalisation'],
        'nomFin': !exists(json, 'nomFin') ? undefined : json['nomFin'],
        'idUser': !exists(json, 'idUser') ? undefined : json['idUser'],
    };
}

export function FcbRecherchePlanLocalisationClasseDtoToJSON(value?: FcbRecherchePlanLocalisationClasseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idClasse': value.idClasse,
        'idStatutLocalisation': value.idStatutLocalisation,
        'codeConseiller': value.codeConseiller,
        'nom': value.nom,
        'codeMetier': value.codeMetier,
        'idMetier': value.idMetier,
        'idDegre': value.idDegre,
        'typeCours': value.typeCours,
        'specificite': value.specificite,
        'genre': value.genre,
        'idReferentiel': value.idReferentiel,
        'codeReferentiel': value.codeReferentiel,
        'financement': value.financement,
        'ppb': value.ppb,
        'nbApprenantLocalisation': value.nbApprenantLocalisation,
        'dateCours': value.dateCours,
        'remarqueCentre': value.remarqueCentre,
        'gestionInterne': value.gestionInterne,
        'remarqueConseiller': value.remarqueConseiller,
        'idPlanLocalisation': value.idPlanLocalisation,
        'nomFin': value.nomFin,
        'idUser': value.idUser,
    };
}

