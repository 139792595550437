import * as React from "react";
import { Switch, Route } from "react-router";
import { ERoutes } from "../../../../../AppRouter";
import { VisiteClasseLieeListPage } from "./VisiteClasseLieeListPage";
import { VisiteClasseLieeSelector } from "./VisiteClasseLieeSelector";

export interface IVisiteClasseLieeSwitchProps {}

export const VisiteClasseLieeSwitch: React.FunctionComponent<IVisiteClasseLieeSwitchProps> = props => {
  return (
    <Switch>
      <Route path={`${ERoutes.visiteExterieure}/:id/:tab`} component={() => <VisiteClasseLieeListPage />} exact />
      <Route path={`${ERoutes.visiteExterieure}/:id/:tab/selector`} component={() => <VisiteClasseLieeSelector />} />
    </Switch>
  );
};
