/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { DateSearch } from './DateSearch';
import {
    DateSearchFromJSON,
    DateSearchFromJSONTyped,
    DateSearchToJSON,
} from './DateSearch';
import type { Int32ListSearch } from './Int32ListSearch';
import {
    Int32ListSearchFromJSON,
    Int32ListSearchFromJSONTyped,
    Int32ListSearchToJSON,
} from './Int32ListSearch';

/**
 * 
 * @export
 * @interface ChangementClasseSearch
 */
export interface ChangementClasseSearch {
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof ChangementClasseSearch
     */
    classesource?: Int32ListSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof ChangementClasseSearch
     */
    classecible?: Int32ListSearch;
    /**
     * 
     * @type {DateSearch}
     * @memberof ChangementClasseSearch
     */
    datechangement?: DateSearch;
    /**
     * 
     * @type {number}
     * @memberof ChangementClasseSearch
     */
    idinscription?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChangementClasseSearch
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof ChangementClasseSearch
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof ChangementClasseSearch
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChangementClasseSearch
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof ChangementClasseSearch
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof ChangementClasseSearch
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the ChangementClasseSearch interface.
 */
export function instanceOfChangementClasseSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChangementClasseSearchFromJSON(json: any): ChangementClasseSearch {
    return ChangementClasseSearchFromJSONTyped(json, false);
}

export function ChangementClasseSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangementClasseSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'classesource': !exists(json, 'classesource') ? undefined : Int32ListSearchFromJSON(json['classesource']),
        'classecible': !exists(json, 'classecible') ? undefined : Int32ListSearchFromJSON(json['classecible']),
        'datechangement': !exists(json, 'datechangement') ? undefined : DateSearchFromJSON(json['datechangement']),
        'idinscription': !exists(json, 'idinscription') ? undefined : json['idinscription'],
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function ChangementClasseSearchToJSON(value?: ChangementClasseSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'classesource': Int32ListSearchToJSON(value.classesource),
        'classecible': Int32ListSearchToJSON(value.classecible),
        'datechangement': DateSearchToJSON(value.datechangement),
        'idinscription': value.idinscription,
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

