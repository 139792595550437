import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import { CentreLieuxCoursList } from "./CentreLieuxCoursList";
import { CentreLieuxCoursDetail } from "./CentreLieuxCoursDetails";

interface ICentreLieuxCoursItemProps {}

export const CentreLieuxCoursItem: React.FunctionComponent<ICentreLieuxCoursItemProps> = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={CentreLieuxCoursList} exact />
      <Route path={`${match.path}/:lieuId/:state`} component={CentreLieuxCoursDetail} />
    </Switch>
  );
};
