import { Colors, Icon } from "@blueprintjs/core";
import * as React from "react";
import { useTheme } from "../../hooks";

export interface IBooleanColumnProps {
  value: boolean;
  muted?: boolean;
}

export const BooleanColumn: React.FunctionComponent<IBooleanColumnProps> = ({ value, muted }) => {
  const { theme } = useTheme();
  return value ? (
    <Icon icon="tick" color={muted ? Colors.GRAY1 : theme.sucessColor} />
  ) : (
    <Icon icon="cross" color={-muted ? Colors.GRAY1 : theme.dangerColor} />
  );
};
