/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ContratSuspensionDto,
  ContratSuspensionDtoPaginatedResults,
  ContratSuspensionSearchDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
} from '../models/index';
import {
    ContratSuspensionDtoFromJSON,
    ContratSuspensionDtoToJSON,
    ContratSuspensionDtoPaginatedResultsFromJSON,
    ContratSuspensionDtoPaginatedResultsToJSON,
    ContratSuspensionSearchDtoFromJSON,
    ContratSuspensionSearchDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface ContratSuspensionBaseSearchRequest {
    ContratSuspensionSearchDto?: ContratSuspensionSearchDto;
}

export interface ContratSuspensionDeleteContratSuspensionRequest {
    idcontratSuspension?: number;
}

export interface ContratSuspensionGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ContratSuspensionGetSelectItemsRequest {
    searchField: string;
}

export interface ContratSuspensionSaveContratSuspensionRequest {
    ContratSuspensionDto?: ContratSuspensionDto;
}

/**
 * 
 */
export class ContratSuspensionApi extends runtime.BaseAPI {

    /**
     */
    async contratSuspensionBaseSearchRaw(requestParameters: ContratSuspensionBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratSuspensionDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ContratSuspension/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContratSuspensionSearchDtoToJSON(requestParameters.ContratSuspensionSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratSuspensionDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async contratSuspensionBaseSearch(requestParameters: ContratSuspensionBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratSuspensionDtoPaginatedResults> {
        const response = await this.contratSuspensionBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratSuspensionDeleteContratSuspensionRaw(requestParameters: ContratSuspensionDeleteContratSuspensionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idcontratSuspension !== undefined) {
            queryParameters['idcontratSuspension'] = requestParameters.idcontratSuspension;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ContratSuspension`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async contratSuspensionDeleteContratSuspension(requestParameters: ContratSuspensionDeleteContratSuspensionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.contratSuspensionDeleteContratSuspensionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async contratSuspensionGetSearchCriteriasRaw(requestParameters: ContratSuspensionGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ContratSuspension/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async contratSuspensionGetSearchCriterias(requestParameters: ContratSuspensionGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.contratSuspensionGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratSuspensionGetSelectItemsRaw(requestParameters: ContratSuspensionGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling contratSuspensionGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ContratSuspension/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async contratSuspensionGetSelectItems(requestParameters: ContratSuspensionGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.contratSuspensionGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contratSuspensionSaveContratSuspensionRaw(requestParameters: ContratSuspensionSaveContratSuspensionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContratSuspensionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ContratSuspension`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContratSuspensionDtoToJSON(requestParameters.ContratSuspensionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContratSuspensionDtoFromJSON(jsonValue));
    }

    /**
     */
    async contratSuspensionSaveContratSuspension(requestParameters: ContratSuspensionSaveContratSuspensionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContratSuspensionDto> {
        const response = await this.contratSuspensionSaveContratSuspensionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
