import { Intent } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { DemandeApprenantApi } from "../../../../../api/apis/DemandeApprenantApi";
import { DemandeApprenantSearch } from "../../../../../api/models/DemandeApprenantSearch";
import { FcbDemandeApprenantGridDto } from "../../../../../api/models/FcbDemandeApprenantGridDto";
import { ERoutes } from "../../../../../AppRouter";
import { AddButton, EditButton, ViewButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IApprenantRecherchePatronListPageProps {}

export const ApprenantRecherchePatronListPage: React.FunctionComponent<IApprenantRecherchePatronListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const apprenantId = React.useMemo(() => +id, [id]);
  const api = useApiService(DemandeApprenantApi);
  const path = React.useMemo(() => `${ERoutes.apprenant}/${apprenantId}/recherchepatron`, [apprenantId]);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { date: "DESC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: DemandeApprenantSearch) => {
      sObj.idApprenant = apprenantId;
      return api.demandeApprenantBaseSearch({ DemandeApprenantSearch: sObj });
    },
    [api, apprenantId]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbDemandeApprenantGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => history.push(`${path}/${row.iddemande}/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${path}/${row.iddemande}/edit`)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Metier),
        fieldName: "metier"
      },
      {
        header: () => t(ETLCodes.Date),
        fieldName: "date"
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "type"
      },
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "centre"
      },
      {
        header: () => t(ETLCodes.Delegue),
        fieldName: "delegueTutelle"
      }
    ],
    [history, path, t]
  );

  return (
    <>
      <FieldSet
        title={t(ETLCodes.TableResults, { count: totalCount })}
        rightElement={
          <AddButton
            onClick={e => {
              e.stopPropagation();
              history.push(`${path}/0/edit`);
            }}
            text={t(ETLCodes.General_Add)}
            intent={Intent.PRIMARY}
          />
        }
      >
        <DataTable
          dateFormat="dd/MM/yyyy"
          tableState={tableState}
          loading={loading}
          columns={columns}
          filterMode="OnEnter"
        ></DataTable>
      </FieldSet>
    </>
  );
};
