/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FcbHoraireGridDto } from './FcbHoraireGridDto';
import {
    FcbHoraireGridDtoFromJSON,
    FcbHoraireGridDtoFromJSONTyped,
    FcbHoraireGridDtoToJSON,
} from './FcbHoraireGridDto';
import type { IAggregateResult } from './IAggregateResult';
import {
    IAggregateResultFromJSON,
    IAggregateResultFromJSONTyped,
    IAggregateResultToJSON,
} from './IAggregateResult';

/**
 * 
 * @export
 * @interface FcbHoraireGridDtoPaginatedResults
 */
export interface FcbHoraireGridDtoPaginatedResults {
    /**
     * 
     * @type {number}
     * @memberof FcbHoraireGridDtoPaginatedResults
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<FcbHoraireGridDto>}
     * @memberof FcbHoraireGridDtoPaginatedResults
     */
    results?: Array<FcbHoraireGridDto> | null;
    /**
     * 
     * @type {Array<IAggregateResult>}
     * @memberof FcbHoraireGridDtoPaginatedResults
     */
    aggregateResults?: Array<IAggregateResult> | null;
}

/**
 * Check if a given object implements the FcbHoraireGridDtoPaginatedResults interface.
 */
export function instanceOfFcbHoraireGridDtoPaginatedResults(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbHoraireGridDtoPaginatedResultsFromJSON(json: any): FcbHoraireGridDtoPaginatedResults {
    return FcbHoraireGridDtoPaginatedResultsFromJSONTyped(json, false);
}

export function FcbHoraireGridDtoPaginatedResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbHoraireGridDtoPaginatedResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'results': !exists(json, 'results') ? undefined : (json['results'] === null ? null : (json['results'] as Array<any>).map(FcbHoraireGridDtoFromJSON)),
        'aggregateResults': !exists(json, 'aggregateResults') ? undefined : (json['aggregateResults'] === null ? null : (json['aggregateResults'] as Array<any>).map(IAggregateResultFromJSON)),
    };
}

export function FcbHoraireGridDtoPaginatedResultsToJSON(value?: FcbHoraireGridDtoPaginatedResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'results': value.results === undefined ? undefined : (value.results === null ? null : (value.results as Array<any>).map(FcbHoraireGridDtoToJSON)),
        'aggregateResults': value.aggregateResults === undefined ? undefined : (value.aggregateResults === null ? null : (value.aggregateResults as Array<any>).map(IAggregateResultToJSON)),
    };
}

