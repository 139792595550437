import { startOfDay } from "date-fns";
import { FGMultiSuggestInput, FieldGroup, useFGContext } from "nsitools-react";
import * as React from "react";

import { ContratBaremeList, HeuresHebdomadairesSelector } from "..";
import { EStatutContrat, ETypeContrat, SiegeSocialEditDto } from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import { FGWalterDateMaskInput, FGWalterSelectInput, WarningText } from "../../../../../../components";
import { useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";
import { nameof } from "../../../../../../utils";

export interface IContratConventionStagePanelProps {
  typeContrat: ETypeContrat;
  idsiegeSocial: number;
}

export const ContratConventionStagePanel: React.FunctionComponent<IContratConventionStagePanelProps> = ({
  typeContrat,
  idsiegeSocial
}) => {
  const { t } = useTl();
  const { formik } = useFGContext();

  const [dureesContrat, dccLoading] = useReferential(
    a =>
      a.referentialGetDureesContratByMetier({
        idmetier: +(formik?.values?.idmetier ?? 0),
        typeContrat,
        currentId: +(formik?.values?.iddureeContrat ?? 0)
      }),
    false,
    [typeContrat, formik?.values?.idmetier]
  );

  const [metiers, mLoading] = useReferential(
    a => a.referentialMetierNotAP({ currentId: +(formik?.values?.idmetier ?? 0) }),
    false,
    [formik?.values?.idmetier]
  );

  const [commissionsParitaires, cpLoading] = useReferential(
    a =>
      a.referentialGetCommissionsParitaires({
        idmetier: 0,
        idsiegeSocial: idsiegeSocial ?? 0,
        currentId: formik.values?.idcommissionParitaire ?? 0
      }),
    false,
    [idsiegeSocial, formik.values?.idcommissionParitaire]
  );

  const [clauses, cLoading] = useReferential(a => a.referentialGetClausesContrat(), true);

  const softRO = React.useMemo(() => [EStatutContrat.Rompu].includes(formik?.values?.statut), [formik?.values?.statut]);
  const [lieuxSignature, lsLoading] = useReferential(a => a.referentialGetLieuxSignature());

  return (
    <>
      <FieldGroup
        columns={2}
        collapsable
        fieldsetProps={{
          title: t(ETLCodes.Detail)
        }}
      >
        <FieldGroup>
          <FGWalterSelectInput
            name="idmetier"
            label={t(ETLCodes.Metier)}
            items={metiers}
            loading={mLoading}
            readonly={formik?.values?.idcontrat > 0 || softRO}
          />
          <FGWalterSelectInput
            name="iddureeContrat"
            label={t(ETLCodes.DureeParcours)}
            items={dureesContrat}
            loading={dccLoading}
            readonly={softRO}
          />
          <FGWalterSelectInput
            name="idcommissionParitaire"
            label={t(ETLCodes.CommissionParitaire)}
            items={commissionsParitaires}
            loading={cpLoading}
            readonly={softRO}
            helperText={ctx =>
              !!idsiegeSocial &&
              commissionsParitaires?.length === 0 && <WarningText text={t(ETLCodes.AucuneCpDansCeSiegeSocial)} />
            }
            itemCreateUrl={`${ERoutes.siegeSocial}/${idsiegeSocial}/detail/edit`}
            itemCreateParams={`&fieldName=${nameof<SiegeSocialEditDto>("idsCommissionParitaire")}`}
            autoSelectIfOne
          />
          <HeuresHebdomadairesSelector softRO={softRO} />
          <FGMultiSuggestInput
            name="idsclause"
            label={t(ETLCodes.Clauses)}
            items={clauses}
            loading={cLoading}
            displayField="label"
            valueField="value"
            readonly={softRO}
          />
        </FieldGroup>
      </FieldGroup>
      <FieldGroup
        columns={2}
        collapsable
        fieldsetProps={{
          title: t(ETLCodes.LieuxDates)
        }}
      >
        <FieldGroup>
          <FGWalterDateMaskInput
            name="dateDebut"
            label={t(ETLCodes.DateDebut)}
            readonly={softRO}
            helperText={ctx =>
              typeContrat === ETypeContrat.CS &&
              !!ctx?.formik?.values?.dateDebut &&
              ctx?.formik?.values?.dateDebut < startOfDay(new Date(2023, 8, 1)) && (
                <WarningText text={t(ETLCodes.OldCsBaremes)} />
              )
            }
          />
          <FGWalterDateMaskInput name="dateFin" label={t(ETLCodes.DateFin)} readonly={softRO} />
          <FGWalterDateMaskInput name="dateSignature" label={t(ETLCodes.DateSignature)} readonly={softRO} />
          <FGWalterSelectInput
            name="idlieuSignature"
            label={t(ETLCodes.LieuSignature)}
            items={lieuxSignature}
            loading={lsLoading}
            readonly={softRO}
          />
          <FGWalterDateMaskInput
            name="contratConventionStageDto.dateValidation"
            label={t(ETLCodes.DateValidation)}
            readonly={softRO}
          />
          <FGWalterDateMaskInput
            name="contratConventionStageDto.dateRemise"
            label={t(ETLCodes.DateRemise)}
            readonly={softRO}
          />
          <ContratBaremeList typeContrat={typeContrat} />
        </FieldGroup>
      </FieldGroup>
    </>
  );
};
