/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClasseMatiereSearch,
  FcbClasseMatiereCutDto,
  FcbClasseMatiereDto,
  FcbClasseMatiereGridDtoPaginatedResults,
  FcbClasseMatiereMultipleDto,
  FilterCriteriaInfo,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    ClasseMatiereSearchFromJSON,
    ClasseMatiereSearchToJSON,
    FcbClasseMatiereCutDtoFromJSON,
    FcbClasseMatiereCutDtoToJSON,
    FcbClasseMatiereDtoFromJSON,
    FcbClasseMatiereDtoToJSON,
    FcbClasseMatiereGridDtoPaginatedResultsFromJSON,
    FcbClasseMatiereGridDtoPaginatedResultsToJSON,
    FcbClasseMatiereMultipleDtoFromJSON,
    FcbClasseMatiereMultipleDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface ClasseMatiereBaseSearchRequest {
    ClasseMatiereSearch?: ClasseMatiereSearch;
}

export interface ClasseMatiereCheckCodeUniqueRequest {
    code?: string;
    idClasse?: number;
    idClasseMatiere?: number;
}

export interface ClasseMatiereCheckDepassementContratRequest {
    idFormateurLocalisationContrat?: number;
    idClasseMatiere?: number;
}

export interface ClasseMatiereCheckExamenRequiredRequest {
    idClasseMatiere?: number;
}

export interface ClasseMatiereCheckFormateurAgrementMetierRequest {
    idFormateur?: number;
    idClasse?: number;
}

export interface ClasseMatiereCheckHeuresReferentielRequest {
    idClasse?: number;
    idReferentiel?: number;
}

export interface ClasseMatiereCheckOriginalityRequest {
    idClasse?: number;
    idReferentiel?: number;
}

export interface ClasseMatiereCheckTJRequiredRequest {
    idClasseMatiere?: number;
}

export interface ClasseMatiereDeleteRequest {
    id?: number;
}

export interface ClasseMatiereGetRequest {
    id?: number;
}

export interface ClasseMatiereGetCutRequest {
    idClasseMatiere?: number;
}

export interface ClasseMatiereGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ClasseMatiereGetSelectItemsRequest {
    searchField: string;
}

export interface ClasseMatiereReinitRequest {
    idClasse?: number;
    idReferentiel?: number;
}

export interface ClasseMatiereSaveRequest {
    FcbClasseMatiereDto?: FcbClasseMatiereDto;
}

export interface ClasseMatiereSaveCutRequest {
    FcbClasseMatiereCutDto?: FcbClasseMatiereCutDto;
}

export interface ClasseMatiereSaveMultipleRequest {
    FcbClasseMatiereMultipleDto?: FcbClasseMatiereMultipleDto;
}

/**
 * 
 */
export class ClasseMatiereApi extends runtime.BaseAPI {

    /**
     */
    async classeMatiereBaseSearchRaw(requestParameters: ClasseMatiereBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbClasseMatiereGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMatiere/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClasseMatiereSearchToJSON(requestParameters.ClasseMatiereSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbClasseMatiereGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async classeMatiereBaseSearch(requestParameters: ClasseMatiereBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbClasseMatiereGridDtoPaginatedResults> {
        const response = await this.classeMatiereBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeMatiereCheckCodeUniqueRaw(requestParameters: ClasseMatiereCheckCodeUniqueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        if (requestParameters.idClasseMatiere !== undefined) {
            queryParameters['idClasseMatiere'] = requestParameters.idClasseMatiere;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMatiere/CheckCodeUnique`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async classeMatiereCheckCodeUnique(requestParameters: ClasseMatiereCheckCodeUniqueRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.classeMatiereCheckCodeUniqueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeMatiereCheckDepassementContratRaw(requestParameters: ClasseMatiereCheckDepassementContratRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.idFormateurLocalisationContrat !== undefined) {
            queryParameters['idFormateurLocalisationContrat'] = requestParameters.idFormateurLocalisationContrat;
        }

        if (requestParameters.idClasseMatiere !== undefined) {
            queryParameters['idClasseMatiere'] = requestParameters.idClasseMatiere;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMatiere/CheckDepassementContrat`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async classeMatiereCheckDepassementContrat(requestParameters: ClasseMatiereCheckDepassementContratRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.classeMatiereCheckDepassementContratRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeMatiereCheckExamenRequiredRaw(requestParameters: ClasseMatiereCheckExamenRequiredRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.idClasseMatiere !== undefined) {
            queryParameters['idClasseMatiere'] = requestParameters.idClasseMatiere;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMatiere/CheckExamenRequired`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async classeMatiereCheckExamenRequired(requestParameters: ClasseMatiereCheckExamenRequiredRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.classeMatiereCheckExamenRequiredRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeMatiereCheckFormateurAgrementMetierRaw(requestParameters: ClasseMatiereCheckFormateurAgrementMetierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.idFormateur !== undefined) {
            queryParameters['idFormateur'] = requestParameters.idFormateur;
        }

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMatiere/CheckFormateurAgrementMetier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async classeMatiereCheckFormateurAgrementMetier(requestParameters: ClasseMatiereCheckFormateurAgrementMetierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.classeMatiereCheckFormateurAgrementMetierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeMatiereCheckHeuresReferentielRaw(requestParameters: ClasseMatiereCheckHeuresReferentielRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        if (requestParameters.idReferentiel !== undefined) {
            queryParameters['idReferentiel'] = requestParameters.idReferentiel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMatiere/CheckHeuresReferentiel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async classeMatiereCheckHeuresReferentiel(requestParameters: ClasseMatiereCheckHeuresReferentielRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.classeMatiereCheckHeuresReferentielRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeMatiereCheckOriginalityRaw(requestParameters: ClasseMatiereCheckOriginalityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        if (requestParameters.idReferentiel !== undefined) {
            queryParameters['idReferentiel'] = requestParameters.idReferentiel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMatiere/CheckOriginality`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async classeMatiereCheckOriginality(requestParameters: ClasseMatiereCheckOriginalityRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.classeMatiereCheckOriginalityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeMatiereCheckTJRequiredRaw(requestParameters: ClasseMatiereCheckTJRequiredRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.idClasseMatiere !== undefined) {
            queryParameters['idClasseMatiere'] = requestParameters.idClasseMatiere;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMatiere/CheckTJRequired`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async classeMatiereCheckTJRequired(requestParameters: ClasseMatiereCheckTJRequiredRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.classeMatiereCheckTJRequiredRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeMatiereDeleteRaw(requestParameters: ClasseMatiereDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMatiere`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async classeMatiereDelete(requestParameters: ClasseMatiereDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.classeMatiereDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeMatiereGetRaw(requestParameters: ClasseMatiereGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbClasseMatiereDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMatiere`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbClasseMatiereDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeMatiereGet(requestParameters: ClasseMatiereGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbClasseMatiereDto> {
        const response = await this.classeMatiereGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeMatiereGetCutRaw(requestParameters: ClasseMatiereGetCutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbClasseMatiereCutDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasseMatiere !== undefined) {
            queryParameters['idClasseMatiere'] = requestParameters.idClasseMatiere;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMatiere/GetCut`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbClasseMatiereCutDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeMatiereGetCut(requestParameters: ClasseMatiereGetCutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbClasseMatiereCutDto> {
        const response = await this.classeMatiereGetCutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeMatiereGetSearchCriteriasRaw(requestParameters: ClasseMatiereGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMatiere/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async classeMatiereGetSearchCriterias(requestParameters: ClasseMatiereGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.classeMatiereGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeMatiereGetSelectItemsRaw(requestParameters: ClasseMatiereGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling classeMatiereGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMatiere/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async classeMatiereGetSelectItems(requestParameters: ClasseMatiereGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.classeMatiereGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeMatiereReinitRaw(requestParameters: ClasseMatiereReinitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        if (requestParameters.idReferentiel !== undefined) {
            queryParameters['idReferentiel'] = requestParameters.idReferentiel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMatiere/Reinit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async classeMatiereReinit(requestParameters: ClasseMatiereReinitRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.classeMatiereReinitRaw(requestParameters, initOverrides);
    }

    /**
     */
    async classeMatiereSaveRaw(requestParameters: ClasseMatiereSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbClasseMatiereDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMatiere`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbClasseMatiereDtoToJSON(requestParameters.FcbClasseMatiereDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbClasseMatiereDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeMatiereSave(requestParameters: ClasseMatiereSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbClasseMatiereDto> {
        const response = await this.classeMatiereSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeMatiereSaveCutRaw(requestParameters: ClasseMatiereSaveCutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbClasseMatiereCutDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMatiere/SaveCut`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbClasseMatiereCutDtoToJSON(requestParameters.FcbClasseMatiereCutDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbClasseMatiereCutDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeMatiereSaveCut(requestParameters: ClasseMatiereSaveCutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbClasseMatiereCutDto> {
        const response = await this.classeMatiereSaveCutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeMatiereSaveMultipleRaw(requestParameters: ClasseMatiereSaveMultipleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbClasseMatiereMultipleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseMatiere/SaveMultiple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbClasseMatiereMultipleDtoToJSON(requestParameters.FcbClasseMatiereMultipleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbClasseMatiereMultipleDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeMatiereSaveMultiple(requestParameters: ClasseMatiereSaveMultipleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbClasseMatiereMultipleDto> {
        const response = await this.classeMatiereSaveMultipleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
