import * as React from "react";
import { useLocation, useParams } from "react-router";
import { SiegeLieuSuivi } from "../../../..";
import { ERoutes } from "../../../../../../AppRouter";

export interface ILieuFormationSuiviItemProps { }

export const LieuFormationSuiviItem: React.FunctionComponent<ILieuFormationSuiviItemProps> = props => {
  const { id, state, subId } = useParams<{ id: string; tab: string; state: string; subId: string }>();
  const idlieuFormation = React.useMemo(() => +id, [id]);
  const idsuivi = React.useMemo(() => +subId, [subId]);
  const editMode = React.useMemo(() => state === "edit", [state]);
  const { search } = useLocation();
  const idsiegeSocial = React.useMemo(() => new URLSearchParams(search).get("idsiegeSocial"), [search]);

  return (
    <SiegeLieuSuivi
      idsuivi={idsuivi}
      idsiegeSocial={+idsiegeSocial}
      idlieuFormation={idlieuFormation}
      editMode={editMode}
      backRoute={`${ERoutes.lieuFormation}/${idlieuFormation}/suivis/edit${search}`}
    />
  );
};
