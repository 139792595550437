import * as React from "react";

import { MinervalSearchTable } from "..";
import { PaiementsSearchTable } from "./PaiementsSearchTable";
import { SoldeSearchTable } from "./SoldeSearchTable";
import { Divider } from "@blueprintjs/core";
import { useParams } from "react-router";

interface IApprenantFinanceDetailProps {}

export const ApprenantFinanceDetail: React.FunctionComponent<IApprenantFinanceDetailProps> = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <SoldeSearchTable apprenantId={+id} />
      <Divider />
      <MinervalSearchTable apprenantId={+id} />
      <Divider />
      <PaiementsSearchTable apprenantId={+id} />
    </>
  );
};
