/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbVisiteDto,
  FcbVisiteGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
  VisiteSearch,
} from '../models/index';
import {
    FcbVisiteDtoFromJSON,
    FcbVisiteDtoToJSON,
    FcbVisiteGridDtoPaginatedResultsFromJSON,
    FcbVisiteGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    VisiteSearchFromJSON,
    VisiteSearchToJSON,
} from '../models/index';

export interface VisiteBaseSearchRequest {
    VisiteSearch?: VisiteSearch;
}

export interface VisiteDeleteRequest {
    id?: number;
}

export interface VisiteGetRequest {
    id?: number;
}

export interface VisiteGetDisplayNameRequest {
    id?: number;
}

export interface VisiteGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface VisiteGetSelectItemsRequest {
    searchField: string;
}

export interface VisiteSaveRequest {
    FcbVisiteDto?: FcbVisiteDto;
}

/**
 * 
 */
export class VisiteApi extends runtime.BaseAPI {

    /**
     */
    async visiteBaseSearchRaw(requestParameters: VisiteBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbVisiteGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Visite/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VisiteSearchToJSON(requestParameters.VisiteSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbVisiteGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async visiteBaseSearch(requestParameters: VisiteBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbVisiteGridDtoPaginatedResults> {
        const response = await this.visiteBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async visiteDeleteRaw(requestParameters: VisiteDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Visite`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async visiteDelete(requestParameters: VisiteDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.visiteDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async visiteGetRaw(requestParameters: VisiteGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbVisiteDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Visite`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbVisiteDtoFromJSON(jsonValue));
    }

    /**
     */
    async visiteGet(requestParameters: VisiteGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbVisiteDto> {
        const response = await this.visiteGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async visiteGetDisplayNameRaw(requestParameters: VisiteGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Visite/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async visiteGetDisplayName(requestParameters: VisiteGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.visiteGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async visiteGetSearchCriteriasRaw(requestParameters: VisiteGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Visite/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async visiteGetSearchCriterias(requestParameters: VisiteGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.visiteGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async visiteGetSelectItemsRaw(requestParameters: VisiteGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling visiteGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Visite/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async visiteGetSelectItems(requestParameters: VisiteGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.visiteGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async visiteSaveRaw(requestParameters: VisiteSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbVisiteDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Visite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbVisiteDtoToJSON(requestParameters.FcbVisiteDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbVisiteDtoFromJSON(jsonValue));
    }

    /**
     */
    async visiteSave(requestParameters: VisiteSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbVisiteDto> {
        const response = await this.visiteSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
