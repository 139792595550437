import * as React from "react";
import { useTl, useApiService, useAbortableApiServiceFactory } from "../../../../hooks";
import { useHistory } from "react-router";
import { ViewButton, EditButton, SearchTablePage } from "../../../../components";
import { ERoutes } from "../../../../AppRouter";
import { ETLCodes } from "../../../../locales";
import { ButtonContainer, IDataTableColumn } from "nsitools-react";
import { ExportApi, FcbRechercheexportDto, RechercheexportSearch } from "../../../../api";
import { Checkbox } from "@blueprintjs/core";
import { css } from "styled-components";

export interface IExportListPageProps {}

export const ExportListPage: React.FunctionComponent<IExportListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ExportApi);
  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbRechercheexportDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.exportation}/${row.idexport}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.exportation}/${row.idexport}/detail/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "type"
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      },
      {
        header: () => t(ETLCodes.Actif),
        fieldName: "ActifComputed",
        alignment: "center",
        render: (row: FcbRechercheexportDto) => <Checkbox disabled checked={row.actifComputed} />
      },
      {
        header: () => t(ETLCodes.Module),
        fieldName: "module",
        customizeCellStyle: () =>
          css`
            white-space: pre;
          `
      },
      {
        header: () => t(ETLCodes.Centres),
        fieldName: "centre",
        customizeCellStyle: () =>
          css`
            white-space: pre;
          `
      },
      {
        header: () => t(ETLCodes.Acces),
        fieldName: "acces",
        customizeCellStyle: () =>
          css`
            white-space: pre;
          `
      }
    ],
    [history, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.exportation}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(() => api.exportGetSearchCriterias({ includeListsValues: true }), [api]);

  const apiFactory = useAbortableApiServiceFactory(ExportApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: RechercheexportSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.exportBaseSearch({ RechercheexportSearch: sObj });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.Exportations), route: ERoutes.exportation }]}
      addFunc={onAddItem}
      sortKeys={{ nom: "ASC" }}
    />
  );
};
