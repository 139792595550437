import { Classes, Dialog, NonIdealState } from "@blueprintjs/core";
import { FGTextAreaInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";

import { ChangeStatutMultipleDto, DashboardApi } from "../../../api";
import { SmallFormGenerator } from "../../../components";
import { useApiService, useCrudApi, useTl } from "../../../hooks";
import { ETLCodes } from "../../../locales";

const StyledDialog = styled(Dialog)`
  width: 500px;
  height: auto;
  background-color: white;
`;

const StyledNonIdealState = styled(NonIdealState)`
  margin-bottom: 1rem;
`;

export interface IDashboardDemandesFormationRefuserDialogProps {
  dialogOpen: boolean;
  onClose: (toSave?: ChangeStatutMultipleDto) => void;
  currentRefus: ChangeStatutMultipleDto;
}

export const DashboardDemandesFormationRefuserDialog: React.FunctionComponent<IDashboardDemandesFormationRefuserDialogProps> = ({
  currentRefus,
  dialogOpen,
  onClose
}) => {
  const { t } = useTl();
  const api = useApiService(DashboardApi);

  const { data, saveItem, saving, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () => currentRefus,
        saveApiFn: async d => {
          await api.dashboardChangeStatutMultipleDemandeFormationCrea({ ChangeStatutMultipleDto: d });
          return d;
        },
        onSaved: d => onClose(d),
        serverValidationRootKey: "Dto"
      }),
      [api, currentRefus, onClose]
    )
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape({
        remarqueRefus: Yup.string()
          .nullable()
          .required(t(ETLCodes.Required))
      }),
    [t]
  );

  return (
    <StyledDialog title={t(ETLCodes.RefuserDemandeConfirmTitle)} isOpen={dialogOpen} onClose={() => onClose()}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose()}
          showDeleteButton={false}
          saving={saving}
          minLabelWidth={100}
          validationErrors={validationErrors}
          preventDefaultSaveOnEnter
        >
          <FieldGroup>
            <StyledNonIdealState description={t(ETLCodes.RefuserDemandeConfirmMessage)} />
            <FGTextAreaInput name="remarqueRefus" label={t(ETLCodes.Remarque)} maxLength={250} />
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
