import { ERoutes } from "./AppRouter";
import { INavigationItem } from "./components";
import { ETLCodes } from "./locales/ETLCodes";

export const WalterAppNavigation = (enableNextFeatures: boolean): INavigationItem[] => [
  {
    name: ETLCodes.MonTableauDeBord,
    route: ERoutes.dashboard,
    icon: "dashboard"
  },
  {
    name: ETLCodes.OrganisationDesCours,
    defaultOpened: true,
    icon: "user",
    items: [
      {
        name: ETLCodes.AgrementsClasses,
        route: ERoutes.agrementClasse
      },
      {
        name: ETLCodes.Apprenants,
        route: ERoutes.apprenant,
        otherAuthorizedRoutes: [ERoutes.lieuFormation]
      },
      {
        name: ETLCodes.PorteursDeProjets,
        route: ERoutes.prospect
      },
      {
        name: ETLCodes.Calendriers,
        route: ERoutes.calendrier
      },
      {
        name: ETLCodes.Classes,
        route: ERoutes.classe
      },
      {
        name: ETLCodes.ClassesMiseAJour,
        route: ERoutes.classeMiseAJourMasse
      },
      {
        name: ETLCodes.DeliberationType,
        route: ERoutes.deliberationType
      },
      {
        name: ETLCodes.DeliberationMasse,
        route: ERoutes.deliberationMasse
      },
      {
        name: ETLCodes.Evaluations,
        route: ERoutes.evaluation
      },
      {
        name: ETLCodes.FeuillesPresence,
        route: ERoutes.feuillePresence
      },
      {
        name: ETLCodes.Formateurs,
        route: ERoutes.formateur
      },
      {
        name: ETLCodes.InscriptionsMasse,
        route: ERoutes.inscriptionMasse
      },
      {
        name: ETLCodes.LocalisationsClasses,
        route: ERoutes.localisationClasse
      },
      {
        name: ETLCodes.Representants,
        route: ERoutes.representant
      },
      {
        name: ETLCodes.SuivisApprenants,
        route: ERoutes.suivi
      },
      {
        name: ETLCodes.Contrats,
        route: ERoutes.contrat
      },
      {
        name: ETLCodes.Remplacements,
        route: ERoutes.remplacement
      },
      {
        name: ETLCodes.VisitesExterieures,
        route: ERoutes.visiteExterieure
      },
      {
        name: ETLCodes.ValidationDeMasse,
        route: ERoutes.validationMasse
      }
    ]
  },
  {
    name: ETLCodes.Donnees,
    icon: "database",

    items: [
      {
        name: ETLCodes.RH,
        items: [
          {
            name: ETLCodes.ConseillersPedagogiques,
            route: ERoutes.conseillerPedagogique
          },
          {
            name: ETLCodes.Referents,
            route: ERoutes.delegueTutelle
          }
        ]
      },
      {
        name: ETLCodes.RE,
        items: [
          {
            name: ETLCodes.ClausesParticulieres,
            route: ERoutes.clause
          },
          {
            name: ETLCodes.DernieresAnneesReussies,
            route: ERoutes.anneeReussie
          },
          {
            name: ETLCodes.Diplomes,
            route: ERoutes.diplome
          },
          {
            name: ETLCodes.StatutsSociaux,
            route: ERoutes.statutSocial
          },
          {
            name: ETLCodes.TypesEnseignement,
            route: ERoutes.typeEnseignement
          }
        ]
      },
      {
        name: ETLCodes.FO,
        items: [
          {
            name: ETLCodes.AnneeScolaires,
            route: ERoutes.anneeScolaire
          },
          {
            name: ETLCodes.ContratsFormateurs,
            route: ERoutes.contratFormateur
          },
          {
            name: ETLCodes.Degres,
            route: ERoutes.degre
          },
          {
            name: ETLCodes.Metiers,
            route: ERoutes.metier
          },
          {
            name: ETLCodes.ProfessionsFormateurs,
            route: ERoutes.professionFormateur
          },
          {
            name: ETLCodes.Referentiels,
            route: ERoutes.referentiel
          },
          {
            name: ETLCodes.SecteursMetiers,
            route: ERoutes.secteurMetier
          },
          {
            name: ETLCodes.SecteursClasseHomogene,
            route: ERoutes.secteurClasseHomogene
          },
          {
            name: ETLCodes.SpecificiteCours,
            route: ERoutes.specificiteCours
          },
          {
            name: ETLCodes.Stades,
            route: ERoutes.stade
          },
          {
            name: ETLCodes.TitresPedagogiques,
            route: ERoutes.titrePedagogique
          },
          {
            name: ETLCodes.TypesEvaluation,
            route: ERoutes.typeEvaluation
          },
          {
            name: ETLCodes.TypesCours,
            route: ERoutes.typeCours
          },
          {
            name: ETLCodes.TypesBulletin,
            route: ERoutes.typeBulletin
          },
          {
            name: ETLCodes.ModuleCrea,
            route: ERoutes.moduleCrea
          }
        ]
      },
      {
        name: ETLCodes.DFC,
        items: [
          {
            name: ETLCodes.TypesFinancement,
            route: ERoutes.typeFinancement
          },
          {
            name: ETLCodes.ProjetsFinancement,
            route: ERoutes.projetFinancement
          }
        ]
      },
      {
        name: ETLCodes.Administratif,
        items: [
          {
            name: ETLCodes.Personnes,
            route: ERoutes.personne
          },
          {
            name: ETLCodes.CodesPostaux,
            route: ERoutes.codePostal
          },
          {
            name: ETLCodes.Pays,
            route: ERoutes.pays
          }
        ]
      },
      {
        name: ETLCodes.Institutions,
        items: [
          {
            name: ETLCodes.Institutions,
            route: ERoutes.institution
          },
          {
            name: ETLCodes.CentreGlobal,
            route: ERoutes.centreGlobal
          },
          {
            name: ETLCodes.Centres,
            route: ERoutes.centre
          },
          {
            name: ETLCodes.DirectionsTerritoriales,
            route: ERoutes.directionTerritoriale
          },
          {
            name: ETLCodes.Ecoles,
            route: ERoutes.ecole
          },
          {
            name: ETLCodes.Locaux,
            route: ERoutes.local
          },
          {
            name: ETLCodes.ServicesAlternance,
            route: ERoutes.serviceTutelle
          },
          {
            name: ETLCodes.SocietesExternes,
            route: ERoutes.societeExterne
          }
        ]
      }
    ]
  },
  {
    name: ETLCodes.Utilitaire,
    icon: "wrench",
    items: [
      {
        name: ETLCodes.CarteIdentite,
        route: ERoutes.beidReader
      },
      {
        name: ETLCodes.Doublons,
        route: ERoutes.doublon
      },
      {
        name: ETLCodes.Exportations,
        route: ERoutes.exportation
      },
      {
        name: ETLCodes.SMS,
        route: ERoutes.sms
      },
      {
        name: ETLCodes.GestionDroits,
        route: ERoutes.gestionDroits
      },
      {
        name: ETLCodes.Collaborateurs,
        route: ERoutes.users
      }
    ]
  },
  {
    name: ETLCodes.AllReports,
    icon: "print",
    items: [
      {
        name: ETLCodes.Impressions,
        route: ERoutes.impressions
      }
    ]
  }
];

export const HopeAppNavigation = (enableNextFeatures: boolean): INavigationItem[] => [
  {
    name: ETLCodes.MonTableauDeBord,
    route: ERoutes.dashboard,
    icon: "dashboard"
  },
  {
    name: ETLCodes.OrganisationDeLaFormation,
    defaultOpened: true,
    icon: "user",
    items: [
      {
        name: ETLCodes.Apprenants,
        route: ERoutes.apprenant
      },
      {
        name: ETLCodes.Classes,
        route: ERoutes.classe
      },
      {
        name: ETLCodes.SiegeSociauxLieuxFormation,
        route: ERoutes.siegeSocial,
        otherAuthorizedRoutes: [ERoutes.lieuFormation]
      },
      {
        name: ETLCodes.Offres,
        route: ERoutes.offre
      },
      {
        name: ETLCodes.ChefsEntreprise,
        route: ERoutes.chefEntreprise
      },
      {
        name: ETLCodes.ContactsSiegesLieux,
        route: ERoutes.contacts
      },
      {
        name: ETLCodes.Contrats,
        route: ERoutes.contrat
      },
      {
        name: ETLCodes.Representants,
        route: ERoutes.representant
      },
      {
        name: ETLCodes.SuivisApprenants,
        route: ERoutes.suivi
      },
      {
        name: ETLCodes.SuivisSiegesLieux,
        route: ERoutes.suiviSiegeLieu
      },
      {
        name: ETLCodes.Tuteurs,
        route: ERoutes.tuteur
      }
    ]
  },
  {
    name: ETLCodes.Donnees,
    icon: "database",

    items: [
      {
        name: ETLCodes.RH,
        items: [
          {
            name: ETLCodes.ConseillersPedagogiques,
            route: ERoutes.conseillerPedagogique
          },
          {
            name: ETLCodes.Referents,
            route: ERoutes.delegueTutelle
          }
        ]
      },
      {
        name: ETLCodes.RE,
        items: [
          {
            name: ETLCodes.ClausesParticulieres,
            route: ERoutes.clause
          },
          {
            name: ETLCodes.DernieresAnneesReussies,
            route: ERoutes.anneeReussie
          },
          {
            name: ETLCodes.Diplomes,
            route: ERoutes.diplome
          },
          {
            name: ETLCodes.StatutsSociaux,
            route: ERoutes.statutSocial
          },
          {
            name: ETLCodes.TypesEnseignement,
            route: ERoutes.typeEnseignement
          }
        ]
      },
      {
        name: ETLCodes.FO,
        items: [
          {
            name: ETLCodes.AcquisIfapme,
            route: ERoutes.acquisIfapme
          },
          {
            name: ETLCodes.AnneeScolaires,
            route: ERoutes.anneeScolaire
          },
          {
            name: ETLCodes.CommissionParitaires,
            route: ERoutes.commissionParitaire
          },
          {
            name: ETLCodes.ContratsFormateurs,
            route: ERoutes.contratFormateur
          },
          {
            name: ETLCodes.Degres,
            route: ERoutes.degre
          },
          {
            name: ETLCodes.Metiers,
            route: ERoutes.metier
          },
          {
            name: ETLCodes.ProfessionsFormateurs,
            route: ERoutes.professionFormateur
          },
          {
            name: ETLCodes.Referentiels,
            route: ERoutes.referentiel
          },
          {
            name: ETLCodes.SecteursMetiers,
            route: ERoutes.secteurMetier
          },
          {
            name: ETLCodes.SecteursClasseHomogene,
            route: ERoutes.secteurClasseHomogene
          },
          {
            name: ETLCodes.SpecificiteCours,
            route: ERoutes.specificiteCours
          },
          {
            name: ETLCodes.TitresPedagogiques,
            route: ERoutes.titrePedagogique
          },
          {
            name: ETLCodes.TypesEvaluation,
            route: ERoutes.typeEvaluation
          },
          {
            name: ETLCodes.TypesCours,
            route: ERoutes.typeCours
          },
          {
            name: ETLCodes.TypesBulletin,
            route: ERoutes.typeBulletin
          },
          {
            name: ETLCodes.Stades,
            route: ERoutes.stade
          }
        ]
      },
      {
        name: ETLCodes.DFC,
        items: [
          {
            name: ETLCodes.TypesFinancement,
            route: ERoutes.typeFinancement
          },
          {
            name: ETLCodes.ProjetsFinancement,
            route: ERoutes.projetFinancement
          }
        ]
      },
      {
        name: ETLCodes.Administratif,
        items: [
          {
            name: ETLCodes.Personnes,
            route: ERoutes.personne
          },
          {
            name: ETLCodes.CodesPostaux,
            route: ERoutes.codePostal
          },
          {
            name: ETLCodes.Pays,
            route: ERoutes.pays
          },
          {
            name: ETLCodes.Rapports,
            route: ERoutes.rapports
          }
        ]
      },
      {
        name: ETLCodes.Institutions,
        items: [
          {
            name: ETLCodes.Institutions,
            route: ERoutes.institution
          },
          {
            name: ETLCodes.CentreGlobal,
            route: ERoutes.centreGlobal
          },
          {
            name: ETLCodes.Centres,
            route: ERoutes.centre
          },
          {
            name: ETLCodes.DirectionsTerritoriales,
            route: ERoutes.directionTerritoriale
          },
          {
            name: ETLCodes.Ecoles,
            route: ERoutes.ecole
          },
          {
            name: ETLCodes.Locaux,
            route: ERoutes.local
          },
          {
            name: ETLCodes.ServicesAlternance,
            route: ERoutes.serviceTutelle
          }
        ]
      }
    ]
  },
  {
    name: ETLCodes.Utilitaire,
    icon: "wrench",
    items: [
      {
        name: ETLCodes.CarteIdentite,
        route: ERoutes.beidReader
      },
      {
        name: ETLCodes.Doublons,
        route: ERoutes.doublon
      },
      {
        name: ETLCodes.SMS,
        route: ERoutes.sms
      },
      {
        name: ETLCodes.GestionDroits,
        route: ERoutes.gestionDroits
      },
      {
        name: ETLCodes.Collaborateurs,
        route: ERoutes.users
      },
      {
        name: ETLCodes.Publipostage,
        route: ERoutes.publipostage
      },
      {
        name: ETLCodes.Exports,
        route: ERoutes.exports
      }
    ]
  }
];

const InnerFinAppNavigationItemFromRoute = (pathname: string, navItem: INavigationItem) => {
  if (
    navItem &&
    navItem.route &&
    (pathname.startsWith(navItem.route) || navItem.otherAuthorizedRoutes?.some(r => pathname.startsWith(r)))
  ) {
    return navItem;
  }
  const nextNavItems = navItem.items;
  if (nextNavItems && nextNavItems?.length > 0) {
    for (let i of nextNavItems) {
      const found = InnerFinAppNavigationItemFromRoute(pathname, i);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

export const FinAppNavigationItemFromRoute = (
  pathname: string,
  mode: "walter" | "hope",
  enableNextFeatures: boolean
) => {
  for (let i of mode === "walter" ? WalterAppNavigation(enableNextFeatures) : HopeAppNavigation(enableNextFeatures)) {
    const found = InnerFinAppNavigationItemFromRoute(pathname, i);
    if (found) return found;
  }
  return null;
};

export const AppNavigation = (modules: string[], mode: "walter" | "hope", enableNextFeatures: boolean) => {
  const filtered: INavigationItem[] = [];
  (mode === "walter" ? WalterAppNavigation(enableNextFeatures) : HopeAppNavigation(enableNextFeatures)).forEach(n => {
    const filteredItem = FilterNavItemRoutes(n, modules);
    if (!filteredItem?.items || filteredItem.items.length > 0) {
      filtered.push(filteredItem);
    }
  });
  return filtered;
};

const FilterNavItemRoutes = (item: INavigationItem, modules: string[]): INavigationItem => {
  let filtered: INavigationItem = { ...item };
  if (item.items) {
    filtered.items = [];
    item.items.forEach(n => {
      const filteredItem = FilterNavItemRoutes(n, modules);
      if (filteredItem && (!filteredItem.items || filteredItem.items.length > 0)) {
        filtered.items.push(filteredItem);
      }
    });
  }
  if (filtered.route) {
    filtered =
      modules?.includes(filtered.route.split("/")[1]?.toUpperCase()) ||
      filtered.otherAuthorizedRoutes?.some(r => modules?.includes(r.split("/")[1].toUpperCase()))
        ? filtered
        : null;
  }
  return filtered;
};

export const GetFirstRoute = (
  modules: string[],
  mode: "walter" | "hope",
  enableNextFeatures: boolean,
  item?: INavigationItem
): ERoutes => {
  for (let i of item?.items ?? AppNavigation(modules, mode, enableNextFeatures)) {
    if (!i) continue;

    if (i.items) {
      return GetFirstRoute(modules, mode, enableNextFeatures, i);
    }
    return i.route;
  }
};
