/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbMetiersLocalisationGridDto
 */
export interface FcbMetiersLocalisationGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbMetiersLocalisationGridDto
     */
    centre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbMetiersLocalisationGridDto
     */
    degre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbMetiersLocalisationGridDto
     */
    typeCours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbMetiersLocalisationGridDto
     */
    anneeScolaire?: string | null;
}

/**
 * Check if a given object implements the FcbMetiersLocalisationGridDto interface.
 */
export function instanceOfFcbMetiersLocalisationGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbMetiersLocalisationGridDtoFromJSON(json: any): FcbMetiersLocalisationGridDto {
    return FcbMetiersLocalisationGridDtoFromJSONTyped(json, false);
}

export function FcbMetiersLocalisationGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbMetiersLocalisationGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'centre': !exists(json, 'centre') ? undefined : json['centre'],
        'degre': !exists(json, 'degre') ? undefined : json['degre'],
        'typeCours': !exists(json, 'typeCours') ? undefined : json['typeCours'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
    };
}

export function FcbMetiersLocalisationGridDtoToJSON(value?: FcbMetiersLocalisationGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'centre': value.centre,
        'degre': value.degre,
        'typeCours': value.typeCours,
        'anneeScolaire': value.anneeScolaire,
    };
}

