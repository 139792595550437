/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClasseHoraireIncoherenceSearch,
  FcbClasseHoraireIncoherenceGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    ClasseHoraireIncoherenceSearchFromJSON,
    ClasseHoraireIncoherenceSearchToJSON,
    FcbClasseHoraireIncoherenceGridDtoPaginatedResultsFromJSON,
    FcbClasseHoraireIncoherenceGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface ClasseHoraireIncoherenceBaseSearchRequest {
    ClasseHoraireIncoherenceSearch?: ClasseHoraireIncoherenceSearch;
}

export interface ClasseHoraireIncoherenceCalculCoherenceClasseRequest {
    idClasse?: number;
}

export interface ClasseHoraireIncoherenceGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ClasseHoraireIncoherenceGetSelectItemsRequest {
    searchField: string;
}

/**
 * 
 */
export class ClasseHoraireIncoherenceApi extends runtime.BaseAPI {

    /**
     */
    async classeHoraireIncoherenceBaseSearchRaw(requestParameters: ClasseHoraireIncoherenceBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbClasseHoraireIncoherenceGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseHoraireIncoherence/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClasseHoraireIncoherenceSearchToJSON(requestParameters.ClasseHoraireIncoherenceSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbClasseHoraireIncoherenceGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async classeHoraireIncoherenceBaseSearch(requestParameters: ClasseHoraireIncoherenceBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbClasseHoraireIncoherenceGridDtoPaginatedResults> {
        const response = await this.classeHoraireIncoherenceBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeHoraireIncoherenceCalculCoherenceClasseRaw(requestParameters: ClasseHoraireIncoherenceCalculCoherenceClasseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseHoraireIncoherence/calculCoherence`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async classeHoraireIncoherenceCalculCoherenceClasse(requestParameters: ClasseHoraireIncoherenceCalculCoherenceClasseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.classeHoraireIncoherenceCalculCoherenceClasseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeHoraireIncoherenceGetSearchCriteriasRaw(requestParameters: ClasseHoraireIncoherenceGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseHoraireIncoherence/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async classeHoraireIncoherenceGetSearchCriterias(requestParameters: ClasseHoraireIncoherenceGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.classeHoraireIncoherenceGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeHoraireIncoherenceGetSelectItemsRaw(requestParameters: ClasseHoraireIncoherenceGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling classeHoraireIncoherenceGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ClasseHoraireIncoherence/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async classeHoraireIncoherenceGetSelectItems(requestParameters: ClasseHoraireIncoherenceGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.classeHoraireIncoherenceGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
