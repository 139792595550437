import { Tab } from "@blueprintjs/core";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";

import { ContratAvenantsSwitch, ContratDetailPage, ContratDocuments, ContratHistory, ContratSuspensionsPage } from ".";
import { ContratApi, ETypeContrat } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface IContratItemPageProps {}

export const ContratItemPage: React.FunctionComponent<IContratItemPageProps> = props => {
  const { t } = useTl();
  const { id, tab, type, state } = useParams<{ id: string; tab: string; type: string; state: string }>();
  const typeContrat = React.useMemo(() => type as ETypeContrat, [type]);
  const history = useHistory();

  const api = useApiService(ContratApi);

  const fetchDisplayName = React.useCallback(() => {
    return api.contratGetDisplayName({ id: +id });
  }, [api, id]);
  const { data: displayName } = useQuery(["contrat-display-name", id], fetchDisplayName);

  return (
    <PageBase breadCrumbs={[{ text: t(ETLCodes.Contrat), route: ERoutes.contrat }, { text: displayName }]}>
      <ProtectedTabs
        id="TabsContrats"
        onChange={newTabId => history.push(`${ERoutes.contrat}/${id}/${newTabId}/${type}/${state}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.contrat}></BackButton>
        <Tab id="detail" title={t(ETLCodes.Detail)} panel={<ContratDetailPage />} />
        <Tab id="suspensions" title={t(ETLCodes.Suspensions)} panel={<ContratSuspensionsPage />} disabled={+id <= 0} />
        {![ETypeContrat.DM, ETypeContrat.JI, ETypeContrat.SO, ETypeContrat.CCE, ETypeContrat.CSE].includes(
          typeContrat
        ) && <Tab id="avenants" title={t(ETLCodes.Avenants)} panel={<ContratAvenantsSwitch />} disabled={+id <= 0} />}
        <Tab id="documents" title={t(ETLCodes.Documents)} panel={<ContratDocuments />} disabled={+id <= 0} />
        <Tab id="history" title={t(ETLCodes.Historique)} panel={<ContratHistory />} disabled={+id <= 0} />
      </ProtectedTabs>
    </PageBase>
  );
};
