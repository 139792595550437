/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbFormateurSuiviGridDto
 */
export interface FcbFormateurSuiviGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurSuiviGridDto
     */
    idsuivi?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurSuiviGridDto
     */
    idformateur?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurSuiviGridDto
     */
    idconseillerPedagogique?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurSuiviGridDto
     */
    conseiller?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurSuiviGridDto
     */
    idcentre?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurSuiviGridDto
     */
    centre?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurSuiviGridDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurSuiviGridDto
     */
    avis?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurSuiviGridDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurSuiviGridDto
     */
    url?: string | null;
}

/**
 * Check if a given object implements the FcbFormateurSuiviGridDto interface.
 */
export function instanceOfFcbFormateurSuiviGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbFormateurSuiviGridDtoFromJSON(json: any): FcbFormateurSuiviGridDto {
    return FcbFormateurSuiviGridDtoFromJSONTyped(json, false);
}

export function FcbFormateurSuiviGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbFormateurSuiviGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsuivi': !exists(json, 'idsuivi') ? undefined : json['idsuivi'],
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'idconseillerPedagogique': !exists(json, 'idconseillerPedagogique') ? undefined : json['idconseillerPedagogique'],
        'conseiller': !exists(json, 'conseiller') ? undefined : json['conseiller'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'centre': !exists(json, 'centre') ? undefined : json['centre'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'avis': !exists(json, 'avis') ? undefined : json['avis'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function FcbFormateurSuiviGridDtoToJSON(value?: FcbFormateurSuiviGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsuivi': value.idsuivi,
        'idformateur': value.idformateur,
        'idconseillerPedagogique': value.idconseillerPedagogique,
        'conseiller': value.conseiller,
        'idcentre': value.idcentre,
        'centre': value.centre,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'avis': value.avis,
        'remarque': value.remarque,
        'url': value.url,
    };
}

