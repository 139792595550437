import { Tab, Tabs } from "@blueprintjs/core";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { FormateurAgrementDetail } from "..";
import { ERoutes } from "../../../../../AppRouter";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { FormateurAgrementMetiersList } from "./FormateurAgrementsMetiersList";

export interface IFormateurAgrementTabsProps {}

export const FormateurAgrementTabs: React.FunctionComponent<IFormateurAgrementTabsProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id, idAgrement, tabAgrement, stateAgrement = "view" } = useParams<{
    id: string;
    idAgrement: string;
    tabAgrement: string;
    stateAgrement: string;
  }>();

  return (
    <Tabs
      id="TabsApprenant"
      onChange={newTabId => {
        if (newTabId === "detail") {
          history.push(
            `${ERoutes.formateur}/${id}/agrement/${idAgrement}/${newTabId}/${
              stateAgrement !== "0" ? stateAgrement : "view"
            }`
          );
        } else {
          history.push(`${ERoutes.formateur}/${id}/agrement/${idAgrement}/${newTabId}`);
        }
      }}
      selectedTabId={tabAgrement}
      renderActiveTabPanelOnly
      vertical
    >
      <Tab
        id="detail"
        title={t(ETLCodes.Detail)}
        panel={<FormateurAgrementDetail />}
        panelClassName="full-width-panel"
      />
      <Tab
        id="metiers"
        title={t(ETLCodes.Metiers)}
        panel={<FormateurAgrementMetiersList />}
        panelClassName="full-width-panel"
        disabled={+idAgrement <= 0}
      />
    </Tabs>
  );
};
