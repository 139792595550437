import { Colors, Intent } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import { css } from "styled-components";

import { AcquisScolaireGridDto, AcquisScolaireHopeApi, AcquisScolaireSearchDto } from "../../../../../../api";
import { AddButton, EditButton, ViewButton } from "../../../../../../components";
import { useApiService, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

export interface IAcquisScolaireListProps {
  idpersonne: number;
  listRoute: string;
}

export const AcquisScolaireList: React.FunctionComponent<IAcquisScolaireListProps> = ({ idpersonne, listRoute }) => {
  const { t, tUnsafe } = useTl();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const api = useApiService(AcquisScolaireHopeApi);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [5],
    pageSize: 5
  });

  const searchFunc = React.useCallback(
    (sObj?: AcquisScolaireSearchDto) => {
      sObj.idpersonne = idpersonne;
      return api.acquisScolaireHopeBaseSearch({ AcquisScolaireSearchDto: sObj });
    },
    [api, idpersonne]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction: searchFunc,
    tableState,
    initialSearch: true
  });

  const getTypeAcquisTab = React.useCallback((type: string) => {
    switch (type) {
      case "AnneeFormationSuivieIfapme":
        return "anneeFormationSuivieIfapme";
      case "DiplomeCertificationIfapme":
        return "diplomeCertificationIfapme";
      case "AnneeFormationSuivie":
        return "anneeFormationSuivie";
      case "DiplomeCertification":
        return "diplomeCertification";
    }
  }, []);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: AcquisScolaireGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${listRoute}/${getTypeAcquisTab(row.type)}/${row.id}/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${listRoute}/${getTypeAcquisTab(row.type)}/${row.id}/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Acquis),
        fieldName: "titre"
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "typeText",
        render: (row: AcquisScolaireGridDto) => tUnsafe(row.type)
      },
      {
        header: () => t(ETLCodes.Operateur),
        fieldName: "operateur",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "anneeScolaire"
      },
      {
        header: () => t(ETLCodes.Resultat),
        fieldName: "resultat"
      }
    ],
    [history, listRoute, getTypeAcquisTab, t, tUnsafe]
  );

  const onAddItem = React.useCallback(
    (ifapme: boolean) => {
      if (ifapme) {
        history.push(`${listRoute}/acquisScolaireIfapme/0/edit`);
      } else {
        history.push(`${listRoute}/acquisScolaire/0/edit`);
      }
    },
    [history, listRoute]
  );

  return (
    <>
      {+id > 0 && (
        <FieldSet
          title={`${t(ETLCodes.AcquisScolaire)}`}
          rightElement={
            <>
              <AddButton
                onClick={e => {
                  e.stopPropagation();
                  onAddItem(false);
                }}
                text={t(ETLCodes.NewAcquis)}
                intent={Intent.PRIMARY}
              />
              <AddButton
                onClick={e => {
                  e.stopPropagation();
                  onAddItem(true);
                }}
                text={t(ETLCodes.NewAcquisIfapme)}
                intent={Intent.PRIMARY}
              />
            </>
          }
        >
          <DataTable
            dateFormat="dd-MM-yyyy"
            tableState={tableState}
            loading={loading}
            columns={columns}
            customizeRowStyle={(row: AcquisScolaireGridDto) => css`
              & * {
                color: ${row.type === "AnneeFormationSuivieIfapme" || row.type === "DiplomeCertificationIfapme"
                  ? Colors.BLUE3 + " !important"
                  : null};
              }
            `}
          />
        </FieldSet>
      )}
    </>
  );
};
