import * as React from "react";
import { useHistory } from "react-router";

import { FcbDegreGridDto, DegreSearch, DegreApi, EBooleanSearchTypes } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import styled from "styled-components";

interface IDegreListPageProps {}

const ButtonContainer = styled.div`
  display: flex;
`;

export const DegreListPage: React.FunctionComponent<IDegreListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(DegreApi);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbDegreGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => history.push(`${ERoutes.degre}/${row.idDegre}/detail/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${ERoutes.degre}/${row.idDegre}/detail/edit`)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "idDegre"
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "libelle"
      },
      {
        header: () => t(ETLCodes.Stade),
        fieldName: "stade"
      }
    ],
    [history, t]
  );

  const addItemFunction = React.useCallback(() => history.push(`${ERoutes.degre}/0/detail/edit`), [history]);

  const getCriteriasFn = React.useCallback(() => api.degreGetSearchCriterias({ includeListsValues: true }), [api]);

  const apiFactory = useAbortableApiServiceFactory(DegreApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: DegreSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.degreBaseSearch({ DegreSearch: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <SearchTablePage
        getCriteriasFunction={getCriteriasFn}
        searchFunction={searchFn}
        onAbort={onAbort}
        sortKeys={{ idDegre: "ASC" }}
        columns={columns}
        breadCrumbs={[{ text: t(ETLCodes.Degres), route: ERoutes.degre }]}
        addFunc={addItemFunction}
        defaultCriterias={[
          {
            criteria: "Actif",
            searchMode: EBooleanSearchTypes.Equals,
            value: true
          }
        ]}
      ></SearchTablePage>
    </>
  );
};
