import * as React from "react";
import { Switch, Route } from "react-router";
import { ERoutes } from "../../../../../AppRouter";
import { FormateurLocalisationListPage } from "./FormateurLocalisationListPage";
import { FormateurLocalisationItemPage } from "./FormateurLocalisationItemPage";

export interface IFormateurLocalisationSwitchProps {}

export const FormateurLocalisationSwitch: React.FunctionComponent<IFormateurLocalisationSwitchProps> = () => {
  return (
    <Switch>
      <Route path={`${ERoutes.formateur}/:id/:tab`} component={FormateurLocalisationListPage} exact />
      <Route path={`${ERoutes.formateur}/:id/:tab/:idLocalisation/:state`} component={FormateurLocalisationItemPage} />
    </Switch>
  );
};
