import * as React from "react";
import { ETypeDestinataireSms } from "../api";

interface IRouteParamsContext {
  popSmsRecipientIds: () => number[];
  pushSmsRecipientIds: (ids: number[]) => void;
  popSmsRecipientType: () => ETypeDestinataireSms;
  pushSmsRecipientType: (type: ETypeDestinataireSms) => void;
  popIdbulletinApprenantType: () => number;
  pushIdbulletinApprenantType: (id: number) => void;

  popCancelRoute: () => string;
  pushCancelRoute: (route: string) => void;
  popSelectedApprenantIds: () => number[];
  pushSelectedApprenantIds: (ids: number[]) => void;
}
const RouteParamsContext = React.createContext<IRouteParamsContext>(null);

interface IRouteParamsProviderProps {}

export const RouteParamsProvider: React.FunctionComponent<IRouteParamsProviderProps> = ({ children }) => {
  const [smsRecipientIds, setSmsRecipientIds] = React.useState<number[]>([]);
  const popSmsRecipientIds = React.useCallback(() => {
    const ids = [...smsRecipientIds];
    setSmsRecipientIds([]);
    return ids;
  }, [smsRecipientIds]);

  const [smsRecipientType, setSmsRecipientType] = React.useState<ETypeDestinataireSms>(null);
  const popSmsRecipientType = React.useCallback(() => {
    const type = smsRecipientType;
    setSmsRecipientType(null);
    return type;
  }, [smsRecipientType]);

  const [idbulletinApprenantType, setIdbulletinApprenantType] = React.useState<number>(null);
  const popIdbulletinApprenantType = React.useCallback(() => {
    const id = idbulletinApprenantType;
    setIdbulletinApprenantType(null);
    return id;
  }, [idbulletinApprenantType]);

  const [cancelRoute, setCancelRoute] = React.useState<string>(null);
  const popCancelRoute = React.useCallback(() => {
    const route = cancelRoute;
    setCancelRoute(null);
    return route;
  }, [cancelRoute]);

  const [selectedApprenantIds, setSelectedApprenantIds] = React.useState<number[]>([]);
  const popSelectedApprenantIds = React.useCallback(() => {
    const ids = [...selectedApprenantIds];
    setSelectedApprenantIds([]);
    return ids;
  }, [selectedApprenantIds]);

  return (
    <RouteParamsContext.Provider
      value={{
        popSmsRecipientIds,
        pushSmsRecipientIds: setSmsRecipientIds,
        popSmsRecipientType,
        pushSmsRecipientType: setSmsRecipientType,
        popIdbulletinApprenantType,
        pushIdbulletinApprenantType: setIdbulletinApprenantType,
        popCancelRoute,
        pushCancelRoute: setCancelRoute,
        popSelectedApprenantIds,
        pushSelectedApprenantIds: setSelectedApprenantIds
      }}
    >
      {children}
    </RouteParamsContext.Provider>
  );
};

export const useRouteParamsContext = () => React.useContext(RouteParamsContext);
