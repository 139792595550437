/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EOrigineInscription {
    Walter = 'Walter',
    Hope = 'Hope'
}


export function EOrigineInscriptionFromJSON(json: any): EOrigineInscription {
    return EOrigineInscriptionFromJSONTyped(json, false);
}

export function EOrigineInscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EOrigineInscription {
    return json as EOrigineInscription;
}

export function EOrigineInscriptionToJSON(value?: EOrigineInscription | null): any {
    return value as any;
}

