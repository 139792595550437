/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbBulletinApprenantTypeSaveDto
 */
export interface FcbBulletinApprenantTypeSaveDto {
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeSaveDto
     */
    idbulletinApprenantType?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeSaveDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeSaveDto
     */
    idtypeCours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeSaveDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeSaveDto
     */
    idcentre?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeSaveDto
     */
    idmetier?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeSaveDto
     */
    degre?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeSaveDto
     */
    iddeliberationType?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeSaveDto
     */
    iddeliberationType2sess?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeSaveDto
     */
    remarqueDelibe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeSaveDto
     */
    remarqueDelibe2sess?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbBulletinApprenantTypeSaveDto
     */
    dateDeliberation?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbBulletinApprenantTypeSaveDto
     */
    dateDeliberation2sess?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeSaveDto
     */
    isSecondeSession?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeSaveDto
     */
    view2Sess?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeSaveDto
     */
    validationDirection1sessBool?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeSaveDto
     */
    validationDirection2sessBool?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof FcbBulletinApprenantTypeSaveDto
     */
    validationDirection1sess?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbBulletinApprenantTypeSaveDto
     */
    validationDirection2sess?: Date | null;
}

/**
 * Check if a given object implements the FcbBulletinApprenantTypeSaveDto interface.
 */
export function instanceOfFcbBulletinApprenantTypeSaveDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbBulletinApprenantTypeSaveDtoFromJSON(json: any): FcbBulletinApprenantTypeSaveDto {
    return FcbBulletinApprenantTypeSaveDtoFromJSONTyped(json, false);
}

export function FcbBulletinApprenantTypeSaveDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbBulletinApprenantTypeSaveDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idbulletinApprenantType': !exists(json, 'idbulletinApprenantType') ? undefined : json['idbulletinApprenantType'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idtypeCours': !exists(json, 'idtypeCours') ? undefined : json['idtypeCours'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'degre': !exists(json, 'degre') ? undefined : json['degre'],
        'iddeliberationType': !exists(json, 'iddeliberationType') ? undefined : json['iddeliberationType'],
        'iddeliberationType2sess': !exists(json, 'iddeliberationType2sess') ? undefined : json['iddeliberationType2sess'],
        'remarqueDelibe': !exists(json, 'remarqueDelibe') ? undefined : json['remarqueDelibe'],
        'remarqueDelibe2sess': !exists(json, 'remarqueDelibe2sess') ? undefined : json['remarqueDelibe2sess'],
        'dateDeliberation': !exists(json, 'dateDeliberation') ? undefined : (json['dateDeliberation'] === null ? null : new Date(json['dateDeliberation'])),
        'dateDeliberation2sess': !exists(json, 'dateDeliberation2sess') ? undefined : (json['dateDeliberation2sess'] === null ? null : new Date(json['dateDeliberation2sess'])),
        'isSecondeSession': !exists(json, 'isSecondeSession') ? undefined : json['isSecondeSession'],
        'view2Sess': !exists(json, 'view2Sess') ? undefined : json['view2Sess'],
        'validationDirection1sessBool': !exists(json, 'validationDirection1sessBool') ? undefined : json['validationDirection1sessBool'],
        'validationDirection2sessBool': !exists(json, 'validationDirection2sessBool') ? undefined : json['validationDirection2sessBool'],
        'validationDirection1sess': !exists(json, 'validationDirection1sess') ? undefined : (json['validationDirection1sess'] === null ? null : new Date(json['validationDirection1sess'])),
        'validationDirection2sess': !exists(json, 'validationDirection2sess') ? undefined : (json['validationDirection2sess'] === null ? null : new Date(json['validationDirection2sess'])),
    };
}

export function FcbBulletinApprenantTypeSaveDtoToJSON(value?: FcbBulletinApprenantTypeSaveDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idbulletinApprenantType': value.idbulletinApprenantType,
        'idapprenant': value.idapprenant,
        'idtypeCours': value.idtypeCours,
        'anneeScolaire': value.anneeScolaire,
        'idcentre': value.idcentre,
        'idmetier': value.idmetier,
        'degre': value.degre,
        'iddeliberationType': value.iddeliberationType,
        'iddeliberationType2sess': value.iddeliberationType2sess,
        'remarqueDelibe': value.remarqueDelibe,
        'remarqueDelibe2sess': value.remarqueDelibe2sess,
        'dateDeliberation': value.dateDeliberation === undefined ? undefined : (value.dateDeliberation === null ? null : value.dateDeliberation.toISOString()),
        'dateDeliberation2sess': value.dateDeliberation2sess === undefined ? undefined : (value.dateDeliberation2sess === null ? null : value.dateDeliberation2sess.toISOString()),
        'isSecondeSession': value.isSecondeSession,
        'view2Sess': value.view2Sess,
        'validationDirection1sessBool': value.validationDirection1sessBool,
        'validationDirection2sessBool': value.validationDirection2sessBool,
        'validationDirection1sess': value.validationDirection1sess === undefined ? undefined : (value.validationDirection1sess === null ? null : value.validationDirection1sess.toISOString()),
        'validationDirection2sess': value.validationDirection2sess === undefined ? undefined : (value.validationDirection2sess === null ? null : value.validationDirection2sess.toISOString()),
    };
}

