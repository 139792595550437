import nsiLogo from "./nsi-logo.png";
import nsiLogoWhite from "./nsi_logo_white.png";
import ifapmeLogo from "./IFAPME_logo.png";
import walterLogo from "./IFAPME_WALTER_logo.png";
import walterTestLogo from "./IFAPME_WALTER_logo_test.png";
import hopeLogo from "./IFAPME_HOPE_logo.png";
import hopeLogoTest from "./IFAPME_HOPE_logo_test.png";
import headerImage from "./header_faces.png";
import beidLogo from "./logo-eid.png";
import onbeidLogo from "./logo-on-eid.png";
import nobeidLogo from "./logo-no-eid.png";
import newVersionBeidLogo from "./logo-update-eid.png";
import userlogo from "./user.png";
import excel from "./excel.png";
import rapport from "./newspaper.png";
import printingQueue from "./printing_queue.png";
import printingQueueDone from "./printing_queue_done.png";
import printingQueueError from "./printing_queue_error.png";
import printingQueueLoading from "./printing_queue_loading.png";

const assets = {
  images: {
    nsiLogo,
    nsiLogoWhite,
    ifapmeLogo,
    walterLogo,
    walterTestLogo,
    hopeLogo,
    hopeLogoTest,
    headerImage,
    beidLogo,
    onbeidLogo,
    nobeidLogo,
    newVersionBeidLogo,
    userlogo,
    excel,
    rapport,
    printingQueue,
    printingQueueDone,
    printingQueueError,
    printingQueueLoading
  }
};

export default assets;
