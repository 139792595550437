import { Classes, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import Cleave from "cleave.js/react";
import moment from "moment";
import { showError } from "nsitools-react";
import * as React from "react";

import { BulletinApprenantTypeApi } from "../../api";
import { useApiService } from "../../hooks";

export interface ITableDateEditorTotalProps {
  initialValue?: Date;
  idBulletinApprenant: number;
}

export const TableDateEditorTotal: React.FunctionComponent<ITableDateEditorTotalProps> = ({
  initialValue,
  idBulletinApprenant
}) => {
  const api = useApiService(BulletinApprenantTypeApi);

  const [currentValue, setCurrentValue] = React.useState<string>();
  const [editedValue, setEditedValue] = React.useState<string>();

  React.useEffect(() => {
    setCurrentValue(initialValue ? moment(initialValue).format("DDMMYYYY") : "");
    setEditedValue(initialValue ? moment(initialValue).format("DDMMYYYY") : "");
  }, [initialValue]);

  const onBlur = React.useCallback(async () => {
    if (editedValue !== currentValue) {
      try {
        await api.bulletinApprenantTypeSaveBulletinTotalDate({
          idbulletinApprenant: idBulletinApprenant,
          date: moment(editedValue, "DDMMYYYY")
            .utc(true)
            .toDate()
        });
        setCurrentValue(editedValue);
      } catch (Error) {
        showError(`Unable to save`);
        setEditedValue(currentValue);
      }
    }
  }, [api, currentValue, editedValue, idBulletinApprenant]);

  const input = React.useRef<HTMLInputElement>();

  return (
    <div className={`${Classes.INPUT_GROUP} ${Classes.FILL}`}>
      <Cleave
        onChange={e => setEditedValue(e.target.rawValue)}
        autoComplete="new-password"
        onBlur={onBlur}
        onClick={() => input.current.select()}
        value={editedValue}
        placeholder={""}
        options={{ date: true, delimiter: "/", datePattern: ["d", "m", "Y"] }}
        className={`${Classes.INPUT}`}
        htmlRef={hRef => (input.current = hRef)}
      />
      <Icon icon={IconNames.CALENDAR} />
    </div>
  );
};
