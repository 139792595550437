import * as React from "react";
import { IButtonProps } from "@blueprintjs/core";
import { ETLCodes } from "../locales";
import { useTl } from "../hooks";

export type DialogResult = "yes" | "no" | "ok" | "cancel";
export type DialogResultButtonType = Omit<IButtonProps, "onClick"> & { resultType: DialogResult };
type ConfirmDialogData = {
  title?: string;
  buttons?: Array<DialogResultButtonType>;
  message: string;
  onDialogClosed?: (result: DialogResult) => void;
  onConfirmed?: () => void;
};

export type ConfirmDialogDataPromise = Omit<ConfirmDialogData, "onConfirmed" | "onDialogClosed">;

interface IDialogContext {
  showDialog: (data: ConfirmDialogData) => void;
  showDialogPromise: (data: ConfirmDialogDataPromise) => Promise<DialogResult>;
  hideDialog: (result: DialogResult) => void;
  dialogData: Omit<ConfirmDialogData, "onDialogClosed">;
}

const DialogContext = React.createContext<IDialogContext>(null);

const DialogProvider: React.FunctionComponent = ({ children }) => {
  const { t } = useTl();
  const [dialogData, setDialogData] = React.useState<ConfirmDialogData>(null);

  const showDialog = React.useCallback(
    (data: ConfirmDialogData) => {
      setDialogData({
        title: t(ETLCodes.DemandeConfirmation),
        ...data
      });
    },
    [t]
  );

  const showDialogPromise = React.useCallback(
    (data: ConfirmDialogData) => {
      let res;
      const promise = new Promise<DialogResult>(resolve => {
        res = resolve;
      });
      const { ...other } = data;

      showDialog({
        title: t(ETLCodes.DemandeConfirmation),
        ...other,
        onDialogClosed: (result: DialogResult) => {
          res(result);
        }
      });
      return promise;
    },
    [showDialog, t]
  );

  const hideDialog = React.useCallback(
    (result: DialogResult) => {
      const { onDialogClosed, onConfirmed } = dialogData;
      setDialogData(null);
      if (onDialogClosed) onDialogClosed(result);
      if ((result === "ok" || result === "yes") && onConfirmed) {
        onConfirmed();
      }
    },
    [dialogData]
  );

  return (
    <DialogContext.Provider value={{ showDialog, showDialogPromise, dialogData, hideDialog }}>
      {children}
    </DialogContext.Provider>
  );
};

const useDialog = () => React.useContext(DialogContext);

export { DialogProvider, useDialog };
