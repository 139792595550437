import { HTMLSelect } from "@blueprintjs/core";
import { DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";

import {
  EAggregateType,
  FcbFormateurPrestationsGlobalesGridDto,
  FormateurPrestationsGlobalesApi,
  FormateurPrestationsGlobalesSearch,
} from "../../../../../api";
import { useGlobalData } from "../../../../../contexts/GlobalDataContext";
import { useApiService, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

export interface IFormateurPrestationsGlobalesProps {
  formateurId: number;
}

export const FormateurPrestationsGlobales: React.FunctionComponent<IFormateurPrestationsGlobalesProps> = ({
  formateurId,
}) => {
  const { t } = useTl();
  const { currentAnneeScolaire } = useGlobalData();
  const api = useApiService(FormateurPrestationsGlobalesApi);
  const [annees, aloading] = useReferential(
    (a) => a.referentialGetAnneesPrestations({ idFormateur: formateurId }),
    false,
    [],
    false
  );
  const [currentAnnee, setCurrentAnnee] = React.useState<string>();

  React.useEffect(() => {
    if (annees && !currentAnnee) {
      const isCurrent = annees.find((c) => c.label === currentAnneeScolaire?.idanneeScolaire);
      isCurrent ? setCurrentAnnee(isCurrent.label) : setCurrentAnnee(annees[0]?.value?.toString());
    }
  }, [annees, currentAnnee, currentAnneeScolaire, currentAnneeScolaire.idanneeScolaire]);

  const tableState = useGridState<any>({
    serverMode: true,
    enableFilter: false,
    availablePageSizes: [12],
    pageSize: 12,
    sortKeys: { annee: "ASC", moisorder: "ASC" },
  });

  const searchFunction = React.useCallback(
    (sObj?: FormateurPrestationsGlobalesSearch) => {
      sObj.idformateur = formateurId;
      sObj.annee = currentAnnee;
      sObj.aggregateKeys = [
        { key: "NbHeuresAPayer", aggregateType: EAggregateType.Sum },
        { key: "NbHeuresCours", aggregateType: EAggregateType.Sum },
        { key: "NbHeuresRemplaceesPayees", aggregateType: EAggregateType.Sum },
        { key: "NbHeuresRemplaceesNonPayees", aggregateType: EAggregateType.Sum },
        { key: "NbRemplacants", aggregateType: EAggregateType.Sum },
      ];
      return api.formateurPrestationsGlobalesBaseSearch({ FormateurPrestationsGlobalesSearch: sObj });
    },
    [api, currentAnnee, formateurId]
  );

  const { search, loading } = useSearchApi({
    searchFunction,
    tableState,
    initialSearch: true,
  });

  React.useEffect(() => {
    search({
      annee: currentAnnee,
    });
  }, [currentAnnee, search]);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.NbHeuresAPayer),
        fieldName: "nbHeuresAPayer",
        autoFitContent: true,
      },
      {
        header: () => t(ETLCodes.NbHeuresCours),
        fieldName: "nbHeuresCours",
        autoFitContent: true,
      },
      {
        header: () => t(ETLCodes.RemplacementPayes),
        fieldName: "nbHeuresRemplaceesPayees",
      },
      {
        header: () => t(ETLCodes.RemplacementNonPayes),
        fieldName: "nbHeuresRemplaceesNonPayees",
      },
      {
        header: () => t(ETLCodes.Remplacants),
        fieldName: "nbRemplacants",
      },
      {
        header: () => t(ETLCodes.Mois),
        fieldName: "moisorder",
        render: (row: FcbFormateurPrestationsGlobalesGridDto) => <div>{row.mois}</div>,
      },
    ],
    [t]
  );

  return (
    <>
      {!aloading && (
        <FieldSet title={`${t(ETLCodes.PrestationsGlobales)}`}>
          <HTMLSelect options={annees} onChange={(e) => setCurrentAnnee(e.target.value)} value={currentAnnee} />
          <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
        </FieldSet>
      )}
    </>
  );
};
