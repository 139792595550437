import * as React from "react";
import { useTl, useApiService } from "../../hooks";
import { Icon } from "@blueprintjs/core";
import { BulletinApprenantTypeApi } from "../../api";
import { useReferential } from "../../hooks/useReferential";
import { showError } from "nsitools-react";
import { ETLCodes } from "../../locales";
import Select from "react-select";

export interface ITableSelectEditorTotalProps {
  initialValue: number;
  idBulletinApprenant: number;
}

const DropdownIndicator: React.FunctionComponent<any> = () => {
  return <Icon icon="double-caret-vertical" style={{ padding: "0 4px", cursor: "pointer" }}></Icon>;
};

export const TableSelectEditorTotal: React.FunctionComponent<ITableSelectEditorTotalProps> = ({
  initialValue,
  idBulletinApprenant
}) => {
  const { t } = useTl();

  const api = useApiService(BulletinApprenantTypeApi);
  const [grades, gloading] = useReferential(a => a.referentialGetGrade(), true);

  const [currentValue, setCurrentValue] = React.useState<number>();
  const [editedValue, setEditedValue] = React.useState<number>();

  React.useEffect(() => {
    setCurrentValue(initialValue);
    setEditedValue(initialValue);
  }, [initialValue]);

  const [loading, setLoading] = React.useState<boolean>();
  const onBlur = React.useCallback(
    async (e: any) => {
      if (editedValue !== currentValue) {
        try {
          setLoading(true);
          await api.bulletinApprenantTypeSaveBulletinTotalGrade({
            idgrade: editedValue,
            idbulletinApprenant: idBulletinApprenant
          });
          setCurrentValue(editedValue);
        } catch (Error) {
          showError(`Unable to save`);
          setEditedValue(currentValue);
        }
        setLoading(false);
      }
    },
    [api, currentValue, editedValue, idBulletinApprenant]
  );

  const handleChange = React.useCallback((value: any, details?: any) => {
    if (!value?.value || details?.action === "clear") {
      setEditedValue(undefined);
    } else {
      setEditedValue(value.value);
    }
  }, []);

  const finalItems = React.useMemo(() => grades?.map(i => ({ value: i.value || "", label: i.label || " " })), [grades]);

  const finalValue = React.useMemo(() => {
    const value = editedValue;
    const found = finalItems?.find(d => "" + d.value === "" + value);
    return found;
  }, [editedValue, finalItems]);

  return (
    <Select
      isClearable
      styles={{
        control: base => {
          let final = {
            ...base,
            minHeight: "28px"
          };
          return final;
        },
        input: base => ({ ...base, margin: "1px 2px" }),
        clearIndicator: base => ({ ...base, padding: "4px", cursor: "pointer" }),
        menu: base => ({ ...base, zIndex: 9999 }),
        menuPortal: base => ({ ...base, zIndex: 9999 })
      }}
      isLoading={gloading || loading}
      options={finalItems}
      components={{ DropdownIndicator }}
      onChange={handleChange}
      menuPortalTarget={document.body}
      menuPlacement="auto"
      onBlur={onBlur}
      value={finalValue}
      loadingMessage={() => t(ETLCodes.FGSelectInput_Loading)}
      noOptionsMessage={() => t(ETLCodes.FGSelectInput_NoOptions)}
      placeholder={t(ETLCodes.FGSelectInput_SelectAnItem)}
    />
  );
};
