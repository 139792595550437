import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ClauseApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { ClauseDetailPage } from "./ClauseDetailPage";
import { ClauseMetierLiesSwitch } from "./ClauseMetierLiesSwitch";

interface IClauseItemPageProps {}

export const ClauseItemPage: React.FunctionComponent<IClauseItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const idClause = React.useMemo(() => +id, [id]);
  const history = useHistory();
  const clauseApi = useApiService(ClauseApi);
  const [displayName] = useApiEffect(() => clauseApi.clauseGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.ClausesParticulieres), route: ERoutes.clause },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsClause"
        onChange={newTabId => history.push(`${ERoutes.clause}/${id}/${newTabId}/${state}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.clause} />
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<ClauseDetailPage editMode={state === "edit"} idClause={idClause} />}
        />
        <Tab id="MetierLies" disabled={+id <= 0} title={t(ETLCodes.MetiersLies)} panel={<ClauseMetierLiesSwitch />} />
      </ProtectedTabs>
    </PageBase>
  );
};
