import { ButtonContainer } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";

import { AcquisIfapmeApi, AcquisIfapmeGridDto, AcquisIfapmeSearchDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IAcquisIfapmeListPageProps {}

export const AcquisIfapmeListPage: React.FunctionComponent<IAcquisIfapmeListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(AcquisIfapmeApi);
  const [lastSearchObject, setLastSearchObject] = React.useState<AcquisIfapmeSearchDto>();

  const apiFactory = useAbortableApiServiceFactory(AcquisIfapmeApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (nextSearch?: AcquisIfapmeSearchDto) => {
      if (JSON.stringify(nextSearch) !== JSON.stringify(lastSearchObject)) {
        setLastSearchObject(nextSearch);
      }
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.acquisIfapmeBaseSearch({ AcquisIfapmeSearchDto: nextSearch });
    },
    [apiFactory, lastSearchObject]
  );

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: AcquisIfapmeGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() =>
                history.push(`${ERoutes.acquisIfapme}/${row.idtitreDiplomeCertificationIfapme}/detail/view`)
              }
            />
            <EditButton
              minimal={true}
              onClick={() =>
                history.push(`${ERoutes.acquisIfapme}/${row.idtitreDiplomeCertificationIfapme}/detail/edit`)
              }
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      }
    ],
    [history, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.acquisIfapme}/0/detail/edit`);
  }, [history]);

  const getCriteria = React.useCallback(() => api.acquisIfapmeGetSearchCriterias({ includeListsValues: true }), [api]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteria}
      searchFunction={search}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.AcquisIfapme), route: ERoutes.acquisIfapme }]}
      addFunc={onAddItem}
      sortKeys={{ description: "ASC" }}
    />
  );
};
