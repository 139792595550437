import { Tab } from "@blueprintjs/core";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import { TypeBulletinDetailsPanel } from ".";
import { TypeBulletinApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface ITypeBulletinItemPageProps {}

export const TypeBulletinItemPage: React.FunctionComponent<ITypeBulletinItemPageProps> = props => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const api = useApiService(TypeBulletinApi);

  const { data: displayName } = useQuery(
    ["type-bulletin-display-name", id],
    async () => await api.typeBulletinGetDisplayName({ id: +id })
  );

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.TypesBulletin), route: ERoutes.typeBulletin },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsTypeBulletin"
        onChange={newTabId => history.push(`${ERoutes.typeBulletin}/${id}/${newTabId}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.typeBulletin}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<TypeBulletinDetailsPanel idtypeBulletin={+id} editMode={state === "edit"} />}
        />
      </ProtectedTabs>
    </PageBase>
  );
};

export default TypeBulletinItemPage;
