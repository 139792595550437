/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactLieuFormationGridDto
 */
export interface ContactLieuFormationGridDto {
    /**
     * 
     * @type {number}
     * @memberof ContactLieuFormationGridDto
     */
    idlieuFormation?: number;
    /**
     * 
     * @type {number}
     * @memberof ContactLieuFormationGridDto
     */
    idsiegeSocial?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactLieuFormationGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactLieuFormationGridDto
     */
    telephone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactLieuFormationGridDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactLieuFormationGridDto
     */
    localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactLieuFormationGridDto
     */
    enseigne?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContactLieuFormationGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the ContactLieuFormationGridDto interface.
 */
export function instanceOfContactLieuFormationGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContactLieuFormationGridDtoFromJSON(json: any): ContactLieuFormationGridDto {
    return ContactLieuFormationGridDtoFromJSONTyped(json, false);
}

export function ContactLieuFormationGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactLieuFormationGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'enseigne': !exists(json, 'enseigne') ? undefined : json['enseigne'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function ContactLieuFormationGridDtoToJSON(value?: ContactLieuFormationGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idlieuFormation': value.idlieuFormation,
        'idsiegeSocial': value.idsiegeSocial,
        'nom': value.nom,
        'telephone': value.telephone,
        'email': value.email,
        'localite': value.localite,
        'enseigne': value.enseigne,
        'actif': value.actif,
    };
}

