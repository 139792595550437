/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AnneeFormationSuivieFichierGridDto } from './AnneeFormationSuivieFichierGridDto';
import {
    AnneeFormationSuivieFichierGridDtoFromJSON,
    AnneeFormationSuivieFichierGridDtoFromJSONTyped,
    AnneeFormationSuivieFichierGridDtoToJSON,
} from './AnneeFormationSuivieFichierGridDto';

/**
 * 
 * @export
 * @interface AnneeFormationSuivieEditDto
 */
export interface AnneeFormationSuivieEditDto {
    /**
     * 
     * @type {number}
     * @memberof AnneeFormationSuivieEditDto
     */
    idanneeFormationSuivie?: number;
    /**
     * 
     * @type {number}
     * @memberof AnneeFormationSuivieEditDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {string}
     * @memberof AnneeFormationSuivieEditDto
     */
    idanneeDeFormation?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnneeFormationSuivieEditDto
     */
    idniveau?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AnneeFormationSuivieEditDto
     */
    idannee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AnneeFormationSuivieEditDto
     */
    idforme?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnneeFormationSuivieEditDto
     */
    optionFormation?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnneeFormationSuivieEditDto
     */
    idecole?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AnneeFormationSuivieEditDto
     */
    idresultat?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnneeFormationSuivieEditDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnneeFormationSuivieEditDto
     */
    typeAcquis?: string | null;
    /**
     * 
     * @type {Array<AnneeFormationSuivieFichierGridDto>}
     * @memberof AnneeFormationSuivieEditDto
     */
    fichiers?: Array<AnneeFormationSuivieFichierGridDto> | null;
}

/**
 * Check if a given object implements the AnneeFormationSuivieEditDto interface.
 */
export function instanceOfAnneeFormationSuivieEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AnneeFormationSuivieEditDtoFromJSON(json: any): AnneeFormationSuivieEditDto {
    return AnneeFormationSuivieEditDtoFromJSONTyped(json, false);
}

export function AnneeFormationSuivieEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnneeFormationSuivieEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idanneeFormationSuivie': !exists(json, 'idanneeFormationSuivie') ? undefined : json['idanneeFormationSuivie'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'idanneeDeFormation': !exists(json, 'idanneeDeFormation') ? undefined : json['idanneeDeFormation'],
        'idniveau': !exists(json, 'idniveau') ? undefined : json['idniveau'],
        'idannee': !exists(json, 'idannee') ? undefined : json['idannee'],
        'idforme': !exists(json, 'idforme') ? undefined : json['idforme'],
        'optionFormation': !exists(json, 'optionFormation') ? undefined : json['optionFormation'],
        'idecole': !exists(json, 'idecole') ? undefined : json['idecole'],
        'idresultat': !exists(json, 'idresultat') ? undefined : json['idresultat'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'typeAcquis': !exists(json, 'typeAcquis') ? undefined : json['typeAcquis'],
        'fichiers': !exists(json, 'fichiers') ? undefined : (json['fichiers'] === null ? null : (json['fichiers'] as Array<any>).map(AnneeFormationSuivieFichierGridDtoFromJSON)),
    };
}

export function AnneeFormationSuivieEditDtoToJSON(value?: AnneeFormationSuivieEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idanneeFormationSuivie': value.idanneeFormationSuivie,
        'idpersonne': value.idpersonne,
        'idanneeDeFormation': value.idanneeDeFormation,
        'idniveau': value.idniveau,
        'idannee': value.idannee,
        'idforme': value.idforme,
        'optionFormation': value.optionFormation,
        'idecole': value.idecole,
        'idresultat': value.idresultat,
        'remarque': value.remarque,
        'typeAcquis': value.typeAcquis,
        'fichiers': value.fichiers === undefined ? undefined : (value.fichiers === null ? null : (value.fichiers as Array<any>).map(AnneeFormationSuivieFichierGridDtoToJSON)),
    };
}

