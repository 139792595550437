import { FGEmpty, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { FcbSpecificiteCoursDtoFromJSON, SpecificiteCoursApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterCheckboxInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

interface ISpecificiteCoursDetailPageProps {
  codeSpecificite: string;
  editMode: boolean;
}

export const SpecificiteCoursDetailPage: React.FunctionComponent<ISpecificiteCoursDetailPageProps> = ({
  codeSpecificite,
  editMode
}) => {
  const { t } = useTl();
  const api = useApiService(SpecificiteCoursApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      codeSpecificite === "-1"
        ? FcbSpecificiteCoursDtoFromJSON({})
        : api.specificiteCoursGet({ code: codeSpecificite }),
    saveApiFn: d => api.specificiteCoursSave({ FcbSpecificiteCoursDto: d }),
    onSavedRoute: d => `${ERoutes.specificiteCours}/${d.code}/detail/edit`,
    deleteApiFn: d => api.specificiteCoursDelete({ code: d.code }),
    onDeletedRoute: () => ERoutes.specificiteCours
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      code: Yup.string()
        .required(t(ETLCodes.Required))
        .test("code-not-exists", t(ETLCodes.CodeExistsError), async value => {
          if (value === data.code) return true;
          const validationError = await api.specificiteCoursCodeExists({ code: value });
          return validationError.isValid;
        }),
      libelle: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t, api, data]);

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={editMode}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(ERoutes.specificiteCours)}
        onDelete={deleteItem}
        showDeleteButton={codeSpecificite !== "-1"}
        saving={saving}
        deleting={deleting}
      >
        <FieldGroup columns={2}>
          <FGTextInput name="code" label={t(ETLCodes.Code)} maxLength={2} />
          <FGEmpty />
          <FGTextInput name="libelle" label={t(ETLCodes.Libelle)} maxLength={100} />
          <FGEmpty />
          <FGWalterCheckboxInput name="isCompanySearch" label={t(ETLCodes.IsCompanySearch)} />
          <FGEmpty />
          <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
