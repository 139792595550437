import * as React from "react";
import { FieldSet, DataTable, useGridState, IDataTableColumn } from "nsitools-react";
import { ETLCodes } from "../../../../../locales";
import { useTl, useApiService } from "../../../../../hooks";
import { FcbExportAccesPermissionDto, ExportApi } from "../../../../../api";
import { Checkbox } from "@blueprintjs/core";

export interface IExportAccesPermissionsGridProps {
  idExport: number;
  acces: FcbExportAccesPermissionDto[];
  loading: boolean;
}

export const ExportAccesPermissionsGrid: React.FunctionComponent<IExportAccesPermissionsGridProps> = ({
  idExport,
  acces = [],
  loading
}) => {
  const { t } = useTl();
  const api = useApiService(ExportApi);

  const [loadingCheckboxes, setLoadingCheckboxes] = React.useState(false);

  const tableState = useGridState<FcbExportAccesPermissionDto>({
    serverMode: false,
    enablePagination: false,
    enableFilter: false
  });

  const { setData } = tableState;

  React.useEffect(() => {
    setData(acces);
  }, [acces, setData]);

  const toggleSelection = React.useCallback(
    async (row: FcbExportAccesPermissionDto) => {
      setLoadingCheckboxes(true);
      row.selected = !row.selected;
      row.idexport = idExport;
      let nextData = [...acces];
      setData(nextData);
      try {
        await api.exportSaveAccesPermissions({ FcbExportAccesPermissionDto: row });
        setLoadingCheckboxes(false);
      } catch (e) {
        setLoadingCheckboxes(false);
        throw e;
      }
    },
    [acces, api, idExport, setData]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        render: (item: FcbExportAccesPermissionDto) => (
          <Checkbox
            checked={item.selected}
            onChange={e => {
              toggleSelection(item);
            }}
            disabled={loadingCheckboxes}
          />
        )
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "description"
      }
    ],
    [loadingCheckboxes, t, toggleSelection]
  );

  return (
    <FieldSet title={t(ETLCodes.NiveauAcces)} collapsable defaultCollapsed>
      <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
    </FieldSet>
  );
};
