/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContratConventionStageExterneDto
 */
export interface ContratConventionStageExterneDto {
    /**
     * 
     * @type {number}
     * @memberof ContratConventionStageExterneDto
     */
    idcontratConventionStageExterne?: number;
    /**
     * 
     * @type {number}
     * @memberof ContratConventionStageExterneDto
     */
    idcontrat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratConventionStageExterneDto
     */
    idcontratStatutWalter?: number;
    /**
     * 
     * @type {string}
     * @memberof ContratConventionStageExterneDto
     */
    nomLieu?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratConventionStageExterneDto
     */
    denominationSiege?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratConventionStageExterneDto
     */
    adresse?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContratConventionStageExterneDto
     */
    idcodePostal?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContratConventionStageExterneDto
     */
    codePostalText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratConventionStageExterneDto
     */
    localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratConventionStageExterneDto
     */
    commune?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratConventionStageExterneDto
     */
    chefEntreprise?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratConventionStageExterneDto
     */
    fonction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratConventionStageExterneDto
     */
    numero?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratConventionStageExterneDto
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContratConventionStageExterneDto
     */
    dureeSousContrat?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContratConventionStageExterneDto
     */
    nomReferent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratConventionStageExterneDto
     */
    prenomReferent?: string | null;
}

/**
 * Check if a given object implements the ContratConventionStageExterneDto interface.
 */
export function instanceOfContratConventionStageExterneDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContratConventionStageExterneDtoFromJSON(json: any): ContratConventionStageExterneDto {
    return ContratConventionStageExterneDtoFromJSONTyped(json, false);
}

export function ContratConventionStageExterneDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContratConventionStageExterneDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontratConventionStageExterne': !exists(json, 'idcontratConventionStageExterne') ? undefined : json['idcontratConventionStageExterne'],
        'idcontrat': !exists(json, 'idcontrat') ? undefined : json['idcontrat'],
        'idcontratStatutWalter': !exists(json, 'idcontratStatutWalter') ? undefined : json['idcontratStatutWalter'],
        'nomLieu': !exists(json, 'nomLieu') ? undefined : json['nomLieu'],
        'denominationSiege': !exists(json, 'denominationSiege') ? undefined : json['denominationSiege'],
        'adresse': !exists(json, 'adresse') ? undefined : json['adresse'],
        'idcodePostal': !exists(json, 'idcodePostal') ? undefined : json['idcodePostal'],
        'codePostalText': !exists(json, 'codePostalText') ? undefined : json['codePostalText'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'commune': !exists(json, 'commune') ? undefined : json['commune'],
        'chefEntreprise': !exists(json, 'chefEntreprise') ? undefined : json['chefEntreprise'],
        'fonction': !exists(json, 'fonction') ? undefined : json['fonction'],
        'numero': !exists(json, 'numero') ? undefined : json['numero'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'dureeSousContrat': !exists(json, 'dureeSousContrat') ? undefined : json['dureeSousContrat'],
        'nomReferent': !exists(json, 'nomReferent') ? undefined : json['nomReferent'],
        'prenomReferent': !exists(json, 'prenomReferent') ? undefined : json['prenomReferent'],
    };
}

export function ContratConventionStageExterneDtoToJSON(value?: ContratConventionStageExterneDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontratConventionStageExterne': value.idcontratConventionStageExterne,
        'idcontrat': value.idcontrat,
        'idcontratStatutWalter': value.idcontratStatutWalter,
        'nomLieu': value.nomLieu,
        'denominationSiege': value.denominationSiege,
        'adresse': value.adresse,
        'idcodePostal': value.idcodePostal,
        'codePostalText': value.codePostalText,
        'localite': value.localite,
        'commune': value.commune,
        'chefEntreprise': value.chefEntreprise,
        'fonction': value.fonction,
        'numero': value.numero,
        'email': value.email,
        'dureeSousContrat': value.dureeSousContrat,
        'nomReferent': value.nomReferent,
        'prenomReferent': value.prenomReferent,
    };
}

