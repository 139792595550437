import { Tab } from "@blueprintjs/core";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router";

import { TuteurContrats, TuteurDetailSwitch, TuteurHistory, TuteurLieuxFormation } from ".";
import { TuteurApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useAuth } from "../../../../contexts";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { AcquisSwitch } from "../../apprenant/item/acquis/AcquisSwitch";

export interface ITuteurItemPageProps {}

export const TuteurItemPage: React.FunctionComponent<ITuteurItemPageProps> = props => {
  const { t } = useTl();
  const { id, tab } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();
  const { hasPermission } = useAuth();
  const { search } = useLocation();

  const api = useApiService(TuteurApi);
  const fetchIdPersonne = React.useCallback(() => {
    return api.tuteurGetIdPersonne({ idtuteur: +id });
  }, [api, id]);
  const { data: idpersonne } = useQuery(["idpersonne-tuteur", id], fetchIdPersonne);

  const fetchDisplayName = React.useCallback(() => {
    return api.tuteurGetDisplayName({ id: +id });
  }, [api, id]);
  const { data: displayName } = useQuery(["tuteur-display-name", id], fetchDisplayName);

  const state = React.useMemo(() => (hasPermission("tuteur", "RW", tab) ? "edit" : "view"), [hasPermission, tab]);

  return (
    <PageBase breadCrumbs={[{ text: t(ETLCodes.Tuteurs), route: ERoutes.tuteur }, { text: displayName }]}>
      <ProtectedTabs
        id="TabsTuteurs"
        onChange={newTabId => history.push(`${ERoutes.tuteur}/${id}/${newTabId}/${state}${search}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.tuteur}></BackButton>
        <Tab id="detail" title={t(ETLCodes.Signaletique)} panel={<TuteurDetailSwitch />} />
        <Tab id="lieuxFormation" title={t(ETLCodes.LieuxFormations)} panel={<TuteurLieuxFormation />} />
        <Tab id="contrat" title={t(ETLCodes.Contrats)} panel={<TuteurContrats />} />
        <Tab
          id="acquis"
          title={t(ETLCodes.Acquis)}
          panel={<AcquisSwitch baseRoute={ERoutes.tuteur} idpersonne={idpersonne} />}
        />
        <Tab id="history" title={t(ETLCodes.History)} panel={<TuteurHistory />} />
      </ProtectedTabs>
    </PageBase>
  );
};
