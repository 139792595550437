import * as React from "react";

import { ETextSearchType, MetierLocalisationApi, MetierLocalisationSearch } from "../../../../../api";
import { SearchTablePage } from "../../../../../components";
import { useGlobalData } from "../../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IMetierLocalisationProps {
  idMetier: number;
}

export const MetierLocalisation: React.FunctionComponent<IMetierLocalisationProps> = ({ idMetier }) => {
  const { t } = useTl();
  const api = useApiService(MetierLocalisationApi);
  const { currentAnneeScolaire } = useGlobalData();

  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "centre"
      },
      {
        header: () => t(ETLCodes.Degre),
        fieldName: "degre"
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "typeCours"
      },
      {
        header: () => t(ETLCodes.Annee),
        fieldName: "anneeScolaire"
      }
    ],
    [t]
  );

  const getCriteriasFn = React.useCallback(
    () => api.metierLocalisationGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  const apiFactory = useAbortableApiServiceFactory(MetierLocalisationApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: MetierLocalisationSearch) => {
      sObj.idmetier = idMetier;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.metierLocalisationBaseSearch({ MetierLocalisationSearch: sObj });
    },
    [apiFactory, idMetier]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <SearchTablePage
        getCriteriasFunction={getCriteriasFn}
        searchFunction={searchFn}
        onAbort={onAbort}
        sortKeys={{ anneeScolaire: "ASC" }}
        columns={columns}
        withCard={false}
        defaultCriterias={[
          {
            criteria: "AnneeScolaire",
            searchMode: ETextSearchType.Equals,
            value: currentAnneeScolaire?.idanneeScolaire
          }
        ]}
      ></SearchTablePage>
    </>
  );
};
