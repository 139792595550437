import { ButtonGroup, Classes, Divider, Tab, Tabs } from "@blueprintjs/core";
import { InlineButtonContainer, Loading, showError, showSuccess } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import {
  DoublonApprenantPage,
  DoublonChefEntreprisePage,
  DoublonContactPage,
  DoublonFormateurPage,
  DoublonPersonnePage,
  DoublonRepresentantPage,
  DoublonTuteurPage
} from ".";
import { useDoublonsStateContext } from "../../..";
import { DoublonsApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, FusionButton, HomonymeButton, NextButton, PreviousButton } from "../../../../components";
import { useDialog } from "../../../../contexts";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

interface IStructuredTabs {
  key: string;
  disabled: boolean;
  label: string;
  panel: JSX.Element;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  & .${Classes.TABS}.${Classes.VERTICAL} {
    & .${Classes.TAB_PANEL} {
      width: 100%;
    }

    & > .${Classes.TAB_LIST}>*:not(:last-child) {
      margin-right: 0 !important;
    }
  }
`;

const FirstPanel = styled.div`
  flex: 1;
`;

export interface IDoublonsTabsProps {}

export const DoublonsTabs: React.FunctionComponent<IDoublonsTabsProps> = props => {
  const { t } = useTl();
  const { data, loading, buildFinals } = useDoublonsStateContext();

  const tabsList = React.useMemo<IStructuredTabs[]>(
    () =>
      data && [
        {
          key: "apprenants",
          label: t(ETLCodes.Apprenant),
          disabled: data?.apprenants?.length === 0,
          panel: <DoublonApprenantPage />
        },
        {
          key: "chefsEntreprise",
          label: t(ETLCodes.ChefsEntreprise),
          disabled: data?.chefEntreprises?.length === 0,
          panel: <DoublonChefEntreprisePage />
        },
        {
          key: "contacts",
          label: t(ETLCodes.Contacts),
          disabled: data?.contacts?.length === 0,
          panel: <DoublonContactPage />
        },
        {
          key: "formateurs",
          label: t(ETLCodes.Formateurs),
          disabled: data?.formateurs?.length === 0,
          panel: <DoublonFormateurPage />
        },
        {
          key: "representants",
          label: t(ETLCodes.Representants),
          disabled: data?.representants?.length === 0,
          panel: <DoublonRepresentantPage />
        },
        {
          key: "tuteurs",
          label: t(ETLCodes.Tuteurs),
          disabled: data?.tuteurs?.length === 0,
          panel: <DoublonTuteurPage />
        },
        {
          key: "personnes",
          label: t(ETLCodes.Personnes),
          disabled: false,
          panel: <DoublonPersonnePage />
        }
      ],
    [data, t]
  );

  const displayedTabs = React.useMemo(() => tabsList?.filter(t => !t.disabled) ?? [], [tabsList]);
  const firstTab = React.useMemo(() => displayedTabs[0], [displayedTabs]);
  const lastTab = React.useMemo(() => displayedTabs[displayedTabs.length - 1], [displayedTabs]);

  const [tab, setTab] = React.useState<string>();
  React.useEffect(() => {
    if (!tab && !!firstTab) {
      setTab(firstTab.key);
    }
  }, [firstTab, tab]);

  const nextTab = React.useCallback(() => {
    const currentIndex = displayedTabs.findIndex(t => t.key === tab);
    setTab(displayedTabs[currentIndex + 1].key);
  }, [tab, displayedTabs]);

  const previousTab = React.useCallback(() => {
    const currentIndex = displayedTabs.findIndex(t => t.key === tab);
    setTab(displayedTabs[currentIndex - 1].key);
  }, [tab, displayedTabs]);

  const api = useApiService(DoublonsApi);
  const history = useHistory();
  const { showDialogPromise } = useDialog();
  const [saving, setSaving] = React.useState(false);
  const homonyme = React.useCallback(async () => {
    const res = await showDialogPromise({
      title: t(ETLCodes.HomonymeConfirmTitle),
      message: t(ETLCodes.HomonymeConfirmMessage)
    });
    if (res === "yes") {
      try {
        setSaving(true);
        await api.doublonsHomonyme({ DoublonEditDto: data });
        showSuccess(t(ETLCodes.HomonymeSucess));
        history.push(ERoutes.doublon);
      } catch (e) {
        showError(t(ETLCodes.HomonymeError));
        console.error(e);
      } finally {
        setSaving(false);
      }
    }
  }, [api, data, history, showDialogPromise, t]);

  const merge = React.useCallback(async () => {
    const res = await showDialogPromise({
      title: t(ETLCodes.MergeConfirmTitle),
      message: t(ETLCodes.MergeConfirmMessage)
    });
    if (res === "yes") {
      try {
        setSaving(true);
        const finalData = buildFinals();
        await api.doublonsFusion({ DoublonEditDto: finalData });
        showSuccess(t(ETLCodes.FusionSuccess));
        history.push(ERoutes.doublon);
      } catch (e) {
        showError(t(ETLCodes.FusionError));
        console.error(e);
      } finally {
        setSaving(false);
      }
    }
  }, [api, buildFinals, history, showDialogPromise, t]);

  return (
    <Container>
      <FirstPanel>
        <Loading isLoading={loading || !firstTab}>
          <Tabs
            id="TabsDoublon"
            onChange={newTabId => setTab(newTabId + "")}
            selectedTabId={tab}
            renderActiveTabPanelOnly
          >
            <BackButton backRoute={ERoutes.doublon} />
            {displayedTabs.map(t => (
              <Tab id={t.key} title={t.label} panel={t.panel} disabled={t.disabled} key={t.key} />
            ))}
          </Tabs>
        </Loading>
      </FirstPanel>
      <Divider />
      <InlineButtonContainer style={{ flex: 0 }}>
        {tab !== firstTab?.key ? (
          <PreviousButton intent="primary" onClick={previousTab} loading={loading || saving} />
        ) : (
          <div />
        )}
        <ButtonGroup>
          {!!data?.personnes?.[1] &&
            data?.personnes?.[0]?.registreNational !== data?.personnes?.[1]?.registreNational && (
              <HomonymeButton intent="none" onClick={homonyme} loading={loading || saving} />
            )}
          {tab !== lastTab?.key ? (
            <NextButton intent="primary" onClick={nextTab} loading={loading || saving} />
          ) : (
            <FusionButton onClick={merge} loading={loading || saving} />
          )}
        </ButtonGroup>
      </InlineButtonContainer>
    </Container>
  );
};
