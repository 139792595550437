/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbClasseMatiereGridDto
 */
export interface FcbClasseMatiereGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereGridDto
     */
    idclasseMatiere?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereGridDto
     */
    idclasse?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereGridDto
     */
    idformateur?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseMatiereGridDto
     */
    formateur?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereGridDto
     */
    idformateurLocalisationContrat?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseMatiereGridDto
     */
    contrat?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereGridDto
     */
    idlocal?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseMatiereGridDto
     */
    local?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseMatiereGridDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseMatiereGridDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereGridDto
     */
    nbheures?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereGridDto
     */
    travail?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereGridDto
     */
    examen?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereGridDto
     */
    pcReussite?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereGridDto
     */
    idprojetFinancement?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseMatiereGridDto
     */
    projetFinancement?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseMatiereGridDto
     */
    hasHoraires?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereGridDto
     */
    niveau?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbClasseMatiereGridDto
     */
    matiereParent?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseMatiereGridDto
     */
    uaa?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseMatiereGridDto
     */
    isVisibleBulletin?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbClasseMatiereGridDto
     */
    sommative?: boolean | null;
}

/**
 * Check if a given object implements the FcbClasseMatiereGridDto interface.
 */
export function instanceOfFcbClasseMatiereGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbClasseMatiereGridDtoFromJSON(json: any): FcbClasseMatiereGridDto {
    return FcbClasseMatiereGridDtoFromJSONTyped(json, false);
}

export function FcbClasseMatiereGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbClasseMatiereGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idclasseMatiere': !exists(json, 'idclasseMatiere') ? undefined : json['idclasseMatiere'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'formateur': !exists(json, 'formateur') ? undefined : json['formateur'],
        'idformateurLocalisationContrat': !exists(json, 'idformateurLocalisationContrat') ? undefined : json['idformateurLocalisationContrat'],
        'contrat': !exists(json, 'contrat') ? undefined : json['contrat'],
        'idlocal': !exists(json, 'idlocal') ? undefined : json['idlocal'],
        'local': !exists(json, 'local') ? undefined : json['local'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'nbheures': !exists(json, 'nbheures') ? undefined : json['nbheures'],
        'travail': !exists(json, 'travail') ? undefined : json['travail'],
        'examen': !exists(json, 'examen') ? undefined : json['examen'],
        'pcReussite': !exists(json, 'pcReussite') ? undefined : json['pcReussite'],
        'idprojetFinancement': !exists(json, 'idprojetFinancement') ? undefined : json['idprojetFinancement'],
        'projetFinancement': !exists(json, 'projetFinancement') ? undefined : json['projetFinancement'],
        'hasHoraires': !exists(json, 'hasHoraires') ? undefined : json['hasHoraires'],
        'niveau': !exists(json, 'niveau') ? undefined : json['niveau'],
        'matiereParent': !exists(json, 'matiereParent') ? undefined : json['matiereParent'],
        'uaa': !exists(json, 'uaa') ? undefined : json['uaa'],
        'isVisibleBulletin': !exists(json, 'isVisibleBulletin') ? undefined : json['isVisibleBulletin'],
        'sommative': !exists(json, 'sommative') ? undefined : json['sommative'],
    };
}

export function FcbClasseMatiereGridDtoToJSON(value?: FcbClasseMatiereGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idclasseMatiere': value.idclasseMatiere,
        'idclasse': value.idclasse,
        'idformateur': value.idformateur,
        'formateur': value.formateur,
        'idformateurLocalisationContrat': value.idformateurLocalisationContrat,
        'contrat': value.contrat,
        'idlocal': value.idlocal,
        'local': value.local,
        'code': value.code,
        'description': value.description,
        'nbheures': value.nbheures,
        'travail': value.travail,
        'examen': value.examen,
        'pcReussite': value.pcReussite,
        'idprojetFinancement': value.idprojetFinancement,
        'projetFinancement': value.projetFinancement,
        'hasHoraires': value.hasHoraires,
        'niveau': value.niveau,
        'matiereParent': value.matiereParent,
        'uaa': value.uaa,
        'isVisibleBulletin': value.isVisibleBulletin,
        'sommative': value.sommative,
    };
}

