/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileUploadDto } from './FileUploadDto';
import {
    FileUploadDtoFromJSON,
    FileUploadDtoFromJSONTyped,
    FileUploadDtoToJSON,
} from './FileUploadDto';

/**
 * 
 * @export
 * @interface SuiviApprenantActionEditDto
 */
export interface SuiviApprenantActionEditDto {
    /**
     * 
     * @type {number}
     * @memberof SuiviApprenantActionEditDto
     */
    idsuiviAction?: number;
    /**
     * 
     * @type {number}
     * @memberof SuiviApprenantActionEditDto
     */
    idsuivi?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof SuiviApprenantActionEditDto
     */
    dateEcheance?: Date;
    /**
     * 
     * @type {number}
     * @memberof SuiviApprenantActionEditDto
     */
    idtypeSuiviAction?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviApprenantActionEditDto
     */
    idcollaborateur?: number;
    /**
     * 
     * @type {Date}
     * @memberof SuiviApprenantActionEditDto
     */
    dateRealisation?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviApprenantActionEditDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviApprenantActionEditDto
     */
    idfichier?: number | null;
    /**
     * 
     * @type {FileUploadDto}
     * @memberof SuiviApprenantActionEditDto
     */
    fichier?: FileUploadDto;
}

/**
 * Check if a given object implements the SuiviApprenantActionEditDto interface.
 */
export function instanceOfSuiviApprenantActionEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SuiviApprenantActionEditDtoFromJSON(json: any): SuiviApprenantActionEditDto {
    return SuiviApprenantActionEditDtoFromJSONTyped(json, false);
}

export function SuiviApprenantActionEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuiviApprenantActionEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsuiviAction': !exists(json, 'idsuiviAction') ? undefined : json['idsuiviAction'],
        'idsuivi': !exists(json, 'idsuivi') ? undefined : json['idsuivi'],
        'dateEcheance': !exists(json, 'dateEcheance') ? undefined : (new Date(json['dateEcheance'])),
        'idtypeSuiviAction': !exists(json, 'idtypeSuiviAction') ? undefined : json['idtypeSuiviAction'],
        'idcollaborateur': !exists(json, 'idcollaborateur') ? undefined : json['idcollaborateur'],
        'dateRealisation': !exists(json, 'dateRealisation') ? undefined : (json['dateRealisation'] === null ? null : new Date(json['dateRealisation'])),
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'fichier': !exists(json, 'fichier') ? undefined : FileUploadDtoFromJSON(json['fichier']),
    };
}

export function SuiviApprenantActionEditDtoToJSON(value?: SuiviApprenantActionEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsuiviAction': value.idsuiviAction,
        'idsuivi': value.idsuivi,
        'dateEcheance': value.dateEcheance === undefined ? undefined : (value.dateEcheance.toISOString()),
        'idtypeSuiviAction': value.idtypeSuiviAction,
        'idcollaborateur': value.idcollaborateur,
        'dateRealisation': value.dateRealisation === undefined ? undefined : (value.dateRealisation === null ? null : value.dateRealisation.toISOString()),
        'remarque': value.remarque,
        'idfichier': value.idfichier,
        'fichier': FileUploadDtoToJSON(value.fichier),
    };
}

