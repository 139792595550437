import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { AnneeScolaireDetailPage } from "..";
import { AnneeScolaireApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IAnneeScolaireItemPageProps {}

export const AnneeScolaireItemPage: React.FunctionComponent<IAnneeScolaireItemPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const AnneeApi = useApiService(AnneeScolaireApi);
  const [displayName] = useApiEffect(() => AnneeApi.anneeScolaireGetDisplayName({ id: id }), [id]);
  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.AnneeScolaire), route: ERoutes.anneeScolaire },
        { text: id === "0" ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsAnneeScolaire"
        onChange={newTabId => history.push(`${ERoutes.anneeScolaire}/${id}/${newTabId}/${state}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.anneeScolaire}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<AnneeScolaireDetailPage editMode={state === "edit"} idAnnee={id} />}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
