/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InstitutionSelectorGridDto
 */
export interface InstitutionSelectorGridDto {
    /**
     * 
     * @type {number}
     * @memberof InstitutionSelectorGridDto
     */
    idinstitution?: number;
    /**
     * 
     * @type {string}
     * @memberof InstitutionSelectorGridDto
     */
    denomination?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionSelectorGridDto
     */
    adresse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionSelectorGridDto
     */
    codePostal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionSelectorGridDto
     */
    localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionSelectorGridDto
     */
    gsm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionSelectorGridDto
     */
    telephone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InstitutionSelectorGridDto
     */
    email?: string | null;
}

/**
 * Check if a given object implements the InstitutionSelectorGridDto interface.
 */
export function instanceOfInstitutionSelectorGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InstitutionSelectorGridDtoFromJSON(json: any): InstitutionSelectorGridDto {
    return InstitutionSelectorGridDtoFromJSONTyped(json, false);
}

export function InstitutionSelectorGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstitutionSelectorGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idinstitution': !exists(json, 'idinstitution') ? undefined : json['idinstitution'],
        'denomination': !exists(json, 'denomination') ? undefined : json['denomination'],
        'adresse': !exists(json, 'adresse') ? undefined : json['adresse'],
        'codePostal': !exists(json, 'codePostal') ? undefined : json['codePostal'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'gsm': !exists(json, 'gsm') ? undefined : json['gsm'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function InstitutionSelectorGridDtoToJSON(value?: InstitutionSelectorGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idinstitution': value.idinstitution,
        'denomination': value.denomination,
        'adresse': value.adresse,
        'codePostal': value.codePostal,
        'localite': value.localite,
        'gsm': value.gsm,
        'telephone': value.telephone,
        'email': value.email,
    };
}

