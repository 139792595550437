import * as React from "react";
import { Route, Switch } from "react-router";

import { ERoutes } from "../../../../AppRouter";
import { RepresentantApprenantLiesListPage } from "./RepresentantApprenantLiesListPage";

export interface IRepresentantAppernantLiesSwitchProps {}

export const RepresentantAppernantLiesSwitch: React.FunctionComponent<IRepresentantAppernantLiesSwitchProps> = () => {
  return (
    <Switch>
      <Route path={`${ERoutes.representant}/:id/:tab/`} component={RepresentantApprenantLiesListPage} exact />
    </Switch>
  );
};
