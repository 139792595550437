/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RapportHopeDownloadDto
 */
export interface RapportHopeDownloadDto {
    /**
     * 
     * @type {string}
     * @memberof RapportHopeDownloadDto
     */
    readonly contentBase64?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RapportHopeDownloadDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RapportHopeDownloadDto
     */
    extension?: string | null;
}

/**
 * Check if a given object implements the RapportHopeDownloadDto interface.
 */
export function instanceOfRapportHopeDownloadDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RapportHopeDownloadDtoFromJSON(json: any): RapportHopeDownloadDto {
    return RapportHopeDownloadDtoFromJSONTyped(json, false);
}

export function RapportHopeDownloadDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RapportHopeDownloadDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contentBase64': !exists(json, 'contentBase64') ? undefined : json['contentBase64'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'extension': !exists(json, 'extension') ? undefined : json['extension'],
    };
}

export function RapportHopeDownloadDtoToJSON(value?: RapportHopeDownloadDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileName': value.fileName,
        'extension': value.extension,
    };
}

