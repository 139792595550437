import { FGEmpty, FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { CommissionParitaireApi, CommissionParitaireEditDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

interface ICommissionParitaireDetailPageProps {
  editMode: boolean;
  idCommissionParitaire: number;
}

export const CommissionParitaireDetailPage: React.FunctionComponent<ICommissionParitaireDetailPageProps> = ({
  idCommissionParitaire,
  editMode
}) => {
  const { t } = useTl();
  const api = useApiService(CommissionParitaireApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idCommissionParitaire <= 0
        ? CommissionParitaireEditDtoFromJSON({ idCommissionParitaire: 0 })
        : api.commissionParitaireGetCommissionParitaire({ id: +idCommissionParitaire }),
    saveApiFn: d => api.commissionParitaireSaveCommissionParitaire({ CommissionParitaireEditDto: d }),
    onSavedRoute: d => `${ERoutes.commissionParitaire}/${d.idcommissionParitaire}/detail/edit`
    // deleteApiFn: d => api.commissionParitaireDeleteCommissionParitaire({ id: d.idcommissionParitaire }),
    // onDeletedRoute: () => ERoutes.commissionParitaire
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      code: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={editMode}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(ERoutes.commissionParitaire)}
        // onDelete={deleteItem}
        // showDeleteButton={+idCommissionParitaire > 0}
        saving={saving}
        // deleting={deleting}
      >
        <FieldGroup columns={2}>
          <FGTextInput name="code" label={t(ETLCodes.Code)} maxLength={25} />
          <FGEmpty />
          <FGTextAreaInput name="libelle" label={t(ETLCodes.Libelle)} maxLength={500} />
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
