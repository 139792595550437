/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SiegeSocialHistoryGridDto
 */
export interface SiegeSocialHistoryGridDto {
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialHistoryGridDto
     */
    idsiegeSocialHistory?: number;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialHistoryGridDto
     */
    iduser?: number;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialHistoryGridDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialHistoryGridDto
     */
    idsiegeSocial?: number;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialHistoryGridDto
     */
    action?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialHistoryGridDto
     */
    actionType?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SiegeSocialHistoryGridDto
     */
    actionDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialHistoryGridDto
     */
    userName?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SiegeSocialHistoryGridDto
     */
    ifapmeSide?: Array<string> | null;
}

/**
 * Check if a given object implements the SiegeSocialHistoryGridDto interface.
 */
export function instanceOfSiegeSocialHistoryGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SiegeSocialHistoryGridDtoFromJSON(json: any): SiegeSocialHistoryGridDto {
    return SiegeSocialHistoryGridDtoFromJSONTyped(json, false);
}

export function SiegeSocialHistoryGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiegeSocialHistoryGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsiegeSocialHistory': !exists(json, 'idsiegeSocialHistory') ? undefined : json['idsiegeSocialHistory'],
        'iduser': !exists(json, 'iduser') ? undefined : json['iduser'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'action': !exists(json, 'action') ? undefined : json['action'],
        'actionType': !exists(json, 'actionType') ? undefined : json['actionType'],
        'actionDate': !exists(json, 'actionDate') ? undefined : (new Date(json['actionDate'])),
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'ifapmeSide': !exists(json, 'ifapmeSide') ? undefined : json['ifapmeSide'],
    };
}

export function SiegeSocialHistoryGridDtoToJSON(value?: SiegeSocialHistoryGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsiegeSocialHistory': value.idsiegeSocialHistory,
        'iduser': value.iduser,
        'idpersonne': value.idpersonne,
        'idsiegeSocial': value.idsiegeSocial,
        'action': value.action,
        'actionType': value.actionType,
        'actionDate': value.actionDate === undefined ? undefined : (value.actionDate.toISOString()),
        'userName': value.userName,
        'ifapmeSide': value.ifapmeSide,
    };
}

