import { Button, Colors, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useSet } from "ahooks";
import {
  ButtonsBloc,
  DataTable,
  FGCustomPanel,
  FGMaskInput,
  FGTextInput,
  FieldGroup,
  FieldSet,
  InlineButtonContainer,
  useGridState,
  useSearchApi
} from "nsitools-react";
import * as React from "react";
import styled, { css } from "styled-components";

import { AddButton, FGWalterDateMaskInput, FGWalterSelectInput, SelectButton, SmallFormGenerator } from "../..";
import { TuteurApi, TuteurSelectorGridDto, TuteurSelectorSearch, TuteurSelectorSearchFromJSON } from "../../../api";
import { ERoutes } from "../../../AppRouter";
import { useApiService, useTabMessage, useTheme, useTl } from "../../../hooks";
import { useReferential } from "../../../hooks/useReferential";
import { ETLCodes } from "../../../locales";
import { Guid } from "../../../utils/guid";

const Container = styled.div`
  margin: 1rem 1rem 0 1rem;
`;

export interface ITuteurSelectorProps {
  excludedTuteurIds?: number[];
  onTuteurSelected: (selectedTuteurIds?: number[]) => void;
  rightElement?: any;
  allowMultipleSelection?: boolean;
  preSelectedIds?: number[];
  idlieuFormation?: number;
}

export const TuteurSelector: React.FunctionComponent<ITuteurSelectorProps> = ({
  excludedTuteurIds,
  onTuteurSelected,
  rightElement,
  allowMultipleSelection = false,
  preSelectedIds = [],
  idlieuFormation
}) => {
  const { t } = useTl();
  const { theme } = useTheme();
  const [selectedTuteurIds, { add: addTuteurId, remove: removeTuteurId }] = useSet<number>(preSelectedIds);
  const [nationalites, nLoading] = useReferential(a => a.referentialGetAllNationalite(), false, []);
  const api = useApiService(TuteurApi);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [10, 15],
    pageSize: 10,
    sortKeys: { nom: "ASC", prenom: "ASC" }
  });

  const initialData = React.useMemo(() => {
    return TuteurSelectorSearchFromJSON({
      selectedTuteurIds,
      excludedTuteurIds: excludedTuteurIds ?? [],
      idlieuFormation,
      forceSkip: 0,
      forceTake: 10
    });
  }, [excludedTuteurIds, idlieuFormation, selectedTuteurIds]);

  const { totalCount } = tableState;
  const searchFunction = React.useCallback(
    (sObj?: TuteurSelectorSearch) => {
      let nextSObj = { ...sObj };
      Object.keys(sObj)
        .filter(k => !["forceSkip", "forceTake", "filter", "sortKey", "aggregateKeys", "parameters"].includes(k))
        .forEach(k => {
          nextSObj[k] = !!sObj[k]?.value ? sObj[k] : undefined;
        });
      return api.tuteurSearchSelector({
        TuteurSelectorSearch: {
          ...nextSObj,
          idlieuFormation,
          selectedTuteurIds: Array.from(selectedTuteurIds),
          excludedTuteurIds: excludedTuteurIds ?? []
        }
      });
    },
    [api, excludedTuteurIds, idlieuFormation, selectedTuteurIds]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const selectTuteur = React.useCallback(
    (id: number) => {
      if (allowMultipleSelection) {
        selectedTuteurIds.has(id) ? removeTuteurId(id) : addTuteurId(id);
      } else {
        onTuteurSelected([id]);
      }
    },
    [addTuteurId, allowMultipleSelection, onTuteurSelected, removeTuteurId, selectedTuteurIds]
  );

  const onRowClick = React.useCallback(
    (item: TuteurSelectorGridDto) => {
      selectTuteur(item.idtuteur);
    },
    [selectTuteur]
  );

  const identifier = React.useMemo(() => Guid.newGuid(), []);
  const { messageValue, clearMessageValue, openTabThenSendMessage } = useTabMessage(identifier);
  const add = React.useCallback(
    values => {
      const path = !!idlieuFormation
        ? `${ERoutes.lieuFormation}/${idlieuFormation}/tuteur`
        : `${ERoutes.tuteur}/-1/detail`;
      openTabThenSendMessage(`#${path}/checkDoublons`, values);
    },
    [openTabThenSendMessage, idlieuFormation]
  );

  React.useEffect(() => {
    if (messageValue) {
      search();
      selectTuteur(messageValue);
      clearMessageValue();
    }
  }, [clearMessageValue, messageValue, search, selectTuteur]);

  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.Contact),
        fieldName: "contact"
      },
      {
        header: () => t(ETLCodes.Telephone),
        fieldName: "telephone"
      },
      {
        header: () => t(ETLCodes.Email),
        fieldName: "email"
      },
      {
        header: () => t(ETLCodes.Enseigne),
        fieldName: "enseigne"
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      }
    ],
    [t]
  );

  const customizeRowStyleCallback = React.useCallback(
    (item: TuteurSelectorGridDto) => {
      const isSelected = selectedTuteurIds.has(item.idtuteur);

      return css`
        & * {
          color: ${isSelected ? Colors.WHITE : Colors.BLACK} !important;
        }

        & & > td {
          background-color: ${isSelected ? theme.primaryColor : "initial"} !important;
        }

        background-color: ${isSelected ? theme.primaryColor : "transparent"} !important;
      `;
    },
    [selectedTuteurIds, theme.primaryColor]
  );

  const onTuteurSelect = React.useCallback(() => {
    onTuteurSelected(Array.from(selectedTuteurIds));
  }, [onTuteurSelected, selectedTuteurIds]);

  const finalRightElements = React.useMemo(() => {
    return allowMultipleSelection ? (
      <>
        <SelectButton onClick={onTuteurSelect}></SelectButton>
        {rightElement}
      </>
    ) : (
      rightElement
    );
  }, [allowMultipleSelection, onTuteurSelect, rightElement]);

  const tableTitle = React.useMemo(
    () =>
      `${t(ETLCodes.TableResults, { count: totalCount })}${
        selectedTuteurIds.size > 0 ? " - " + selectedTuteurIds.size + " " + t(ETLCodes.SelectedLieuxFormation) : ""
      }`,
    [selectedTuteurIds.size, t, totalCount]
  );

  return (
    <Container>
      <SmallFormGenerator
        enableDirtyCheck={false}
        initialValues={initialData}
        onSubmit={search}
        editMode
        hideButtons={true}
      >
        <FieldGroup columns={2}>
          <FGTextInput name="nom.value" label={t(ETLCodes.Nom)} maxLength={50} forceCase="upper" />
          <FGWalterSelectInput
            name="nationalite.value"
            label={t(ETLCodes.Nationalite)}
            items={nationalites}
            loading={nLoading}
          />
          <FGTextInput name="prenom.value" label={t(ETLCodes.Prenom)} maxLength={50} />
          <FGMaskInput
            name="registreNational.value"
            label={t(ETLCodes.NumeroNational)}
            cleaveOptions={{ delimiters: [".", ".", "-", "."], blocks: [2, 2, 2, 3, 2], numericOnly: true }}
          />
          <FGWalterDateMaskInput name="dateNaissance.value" label={t(ETLCodes.DateNaissance)} />
        </FieldGroup>
        <FieldGroup>
          <FGCustomPanel>
            {ctx => (
              <InlineButtonContainer>
                <ButtonsBloc></ButtonsBloc>
                <ButtonsBloc>
                  <Button icon={IconNames.SEARCH} type="submit" text={t(ETLCodes.Search)} intent={Intent.PRIMARY} />
                  <AddButton
                    onClick={() => add(ctx.formik?.values)}
                    text={t(ETLCodes.General_Add)}
                    intent={Intent.PRIMARY}
                    minimal={false}
                  />
                </ButtonsBloc>
              </InlineButtonContainer>
            )}
          </FGCustomPanel>
        </FieldGroup>
        <FieldSet title={tableTitle} rightElement={finalRightElements}>
          <DataTable
            dateFormat="dd-MM-yyyy"
            tableState={tableState}
            loading={loading}
            columns={columns}
            onRowClick={onRowClick}
            customizeRowStyle={customizeRowStyleCallback}
            htmlTableOptions={{ striped: true, interactive: true, bordered: true }}
          />
        </FieldSet>
      </SmallFormGenerator>
    </Container>
  );
};
