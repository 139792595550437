import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import { ApprenantFormationDetail } from "./ApprenantFormationDetail";
import { ApprenantFormationSearch } from "./ApprenantFormationSearch";

interface IApprenantFormationProps {}

export const ApprenantFormation: React.FunctionComponent<IApprenantFormationProps> = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} component={ApprenantFormationSearch} exact />
      <Route path={`${match.path}/:formationId/:state`} component={ApprenantFormationDetail} />
    </Switch>
  );
};
