/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BulletinApprenantRecapitulatifSearch,
  FcbBulletinApprenantRecapitulatifGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    BulletinApprenantRecapitulatifSearchFromJSON,
    BulletinApprenantRecapitulatifSearchToJSON,
    FcbBulletinApprenantRecapitulatifGridDtoPaginatedResultsFromJSON,
    FcbBulletinApprenantRecapitulatifGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface BulletinApprenantRecaputilatifBaseSearchRequest {
    BulletinApprenantRecapitulatifSearch?: BulletinApprenantRecapitulatifSearch;
}

export interface BulletinApprenantRecaputilatifGetClasseNameRequest {
    id?: number;
    anneeScolaire?: string;
}

export interface BulletinApprenantRecaputilatifGetFirstTypeCoursInscriptionRequest {
    id?: number;
    anneeScolaire?: string;
}

export interface BulletinApprenantRecaputilatifGetListTypeCoursInscriptionRequest {
    id?: number;
    anneeScolaire?: string;
}

export interface BulletinApprenantRecaputilatifGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface BulletinApprenantRecaputilatifGetSelectItemsRequest {
    searchField: string;
}

/**
 * 
 */
export class BulletinApprenantRecaputilatifApi extends runtime.BaseAPI {

    /**
     */
    async bulletinApprenantRecaputilatifBaseSearchRaw(requestParameters: BulletinApprenantRecaputilatifBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbBulletinApprenantRecapitulatifGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantRecaputilatif/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BulletinApprenantRecapitulatifSearchToJSON(requestParameters.BulletinApprenantRecapitulatifSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbBulletinApprenantRecapitulatifGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async bulletinApprenantRecaputilatifBaseSearch(requestParameters: BulletinApprenantRecaputilatifBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbBulletinApprenantRecapitulatifGridDtoPaginatedResults> {
        const response = await this.bulletinApprenantRecaputilatifBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantRecaputilatifGetClasseNameRaw(requestParameters: BulletinApprenantRecaputilatifGetClasseNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.anneeScolaire !== undefined) {
            queryParameters['anneeScolaire'] = requestParameters.anneeScolaire;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantRecaputilatif/getClasseName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async bulletinApprenantRecaputilatifGetClasseName(requestParameters: BulletinApprenantRecaputilatifGetClasseNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.bulletinApprenantRecaputilatifGetClasseNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantRecaputilatifGetFirstTypeCoursInscriptionRaw(requestParameters: BulletinApprenantRecaputilatifGetFirstTypeCoursInscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.anneeScolaire !== undefined) {
            queryParameters['anneeScolaire'] = requestParameters.anneeScolaire;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantRecaputilatif/getTypeCours`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async bulletinApprenantRecaputilatifGetFirstTypeCoursInscription(requestParameters: BulletinApprenantRecaputilatifGetFirstTypeCoursInscriptionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.bulletinApprenantRecaputilatifGetFirstTypeCoursInscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantRecaputilatifGetListTypeCoursInscriptionRaw(requestParameters: BulletinApprenantRecaputilatifGetListTypeCoursInscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.anneeScolaire !== undefined) {
            queryParameters['anneeScolaire'] = requestParameters.anneeScolaire;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantRecaputilatif/listTypeCours`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async bulletinApprenantRecaputilatifGetListTypeCoursInscription(requestParameters: BulletinApprenantRecaputilatifGetListTypeCoursInscriptionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.bulletinApprenantRecaputilatifGetListTypeCoursInscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantRecaputilatifGetSearchCriteriasRaw(requestParameters: BulletinApprenantRecaputilatifGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantRecaputilatif/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async bulletinApprenantRecaputilatifGetSearchCriterias(requestParameters: BulletinApprenantRecaputilatifGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.bulletinApprenantRecaputilatifGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bulletinApprenantRecaputilatifGetSelectItemsRaw(requestParameters: BulletinApprenantRecaputilatifGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling bulletinApprenantRecaputilatifGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/BulletinApprenantRecaputilatif/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async bulletinApprenantRecaputilatifGetSelectItems(requestParameters: BulletinApprenantRecaputilatifGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.bulletinApprenantRecaputilatifGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
