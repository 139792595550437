import * as React from "react";
import { useReferential } from "../../../../../../hooks/useReferential";
import { useApiService, useCrudApi, useTl } from "../../../../../../hooks";
import {
  DemandeFormationCreaApi,
  DemandeFormationCreateDto,
  DemandeFormationCreateDtoFromJSON,
  DemandeFormationEditDtoFromJSON
} from "../../../../../../api";
import { useHistory, useParams } from "react-router";
import { ETLCodes } from "../../../../../../locales";
import { Button, Card } from "@blueprintjs/core";
import { FGCheckboxInput, FGCustomInput, FGCustomPanel, FieldGroup, FieldSet } from "nsitools-react";
import styled from "styled-components";
import { ErrorText, FGWalterSelectInput, SmallFormGenerator } from "../../../../../../components";
import { FieldArray } from "formik";
import { ERoutes } from "../../../../../../AppRouter";

export interface IDemandeFormationCreateProps {}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
`;

const ErrorTextContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const DemandeFormationCreate: React.FunctionComponent<IDemandeFormationCreateProps> = props => {
  const { t } = useTl();
  const api = useApiService(DemandeFormationCreaApi);
  const history = useHistory();

  const { id, state } = useParams<{
    id: string;
    state: string;
    idDemande: string;
  }>();

  const editMode = React.useMemo(() => state === "edit", [state]);

  const { data, loading, saveItem, saving, validationErrors } = useCrudApi({
    getApiFn: () =>
      DemandeFormationCreateDtoFromJSON({
        idapprenant: +id,
        demandes: [{ premierSemestre: false }]
      }),
    saveApiFn: d => api.demandeFormationCreaCreate({ DemandeFormationCreateDto: d }),
    onSavedRoute: () => `${ERoutes.prospect}/${id}/crea/edit`
  });

  const [metiers, meLoading] = useReferential(a => a.referentialGetMetierCrea());
  const [modules, moLoading] = useReferential(a => a.referentialGetModuleCrea());
  const [annees, anLoading] = useReferential(a => a.referentialGetAnneeScolaire());
  const [centres, ceLoading] = useReferential(a => a.referentialGetCentres());

  const onCancelFunc = React.useCallback(() => {
    history.push(`${ERoutes.prospect}/${id}/crea/${state}`);
  }, [history, id, state]);

  return (
    <FieldSet title={t(ETLCodes.DemandeFormationCrea)}>
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        loading={loading}
        saving={saving}
        formatDate="dd-MM-yyyy"
        minLabelWidth={220}
        editMode={editMode}
        onCancel={onCancelFunc}
        validationErrors={validationErrors}
      >
        <FieldGroup>
          <FGCustomInput>
            {({ formik, validationErrors }) => (
              <FieldArray
                name="demandes"
                render={arrayHelpers => {
                  const data = (formik.values as DemandeFormationCreateDto)?.demandes;
                  return (
                    <FieldGroup>
                      {data?.map((d, index) => {
                        return (
                          <Card title={t(ETLCodes.Demande) + " " + (index + 1)} style={{ margin: "0 1rem 0.5rem" }}>
                            <FieldGroup columns={[8, 4]}>
                              <FieldGroup>
                                <FGWalterSelectInput
                                  name={`demandes.${index}.idmetier`}
                                  label={t(ETLCodes.Metier)}
                                  items={metiers}
                                  loading={meLoading}
                                  disabled
                                  autoSelectIfOne
                                />
                                <FGWalterSelectInput
                                  name={`demandes.${index}.idmodule`}
                                  label={t(ETLCodes.Module)}
                                  items={modules}
                                  loading={moLoading}
                                />
                                <FGWalterSelectInput
                                  name={`demandes.${index}.idannee`}
                                  label={t(ETLCodes.AnneeAcademique)}
                                  items={annees}
                                  loading={anLoading}
                                />
                                <FGCheckboxInput
                                  name={`demandes.${index}.premierSemestre`}
                                  label={t(ETLCodes.PremierSemestreWithDates)}
                                />
                                <FGWalterSelectInput
                                  name={`demandes.${index}.idcentre`}
                                  label={t(ETLCodes.Centre)}
                                  items={centres}
                                  loading={ceLoading}
                                />
                              </FieldGroup>
                              <FieldGroup>
                                <Button
                                  icon="cross"
                                  intent="danger"
                                  outlined
                                  disabled={data.length <= 1}
                                  title={t(ETLCodes.Remove)}
                                  onClick={() => arrayHelpers.remove(index)}
                                  style={{ height: "16px" }}
                                ></Button>
                              </FieldGroup>
                            </FieldGroup>
                            {validationErrors[`demandes[${index}]`]?.length > 0 && (
                              <ErrorTextContainer>
                                <ErrorText text={validationErrors[`demandes[${index}]`][0]} />
                              </ErrorTextContainer>
                            )}
                          </Card>
                        );
                      })}
                      <FGCustomPanel>
                        {ctx => (
                          <ButtonContainer>
                            <Button
                              rightIcon="plus"
                              intent="primary"
                              style={{ fontSize: "12pt" }}
                              minimal
                              text={t(ETLCodes.AddDemandeFormation)}
                              onClick={() => {
                                arrayHelpers.insert(
                                  data?.length ?? 0,
                                  DemandeFormationEditDtoFromJSON({ premierSemestre: false })
                                );
                              }}
                            ></Button>
                          </ButtonContainer>
                        )}
                      </FGCustomPanel>
                    </FieldGroup>
                  );
                }}
              ></FieldArray>
            )}
          </FGCustomInput>
        </FieldGroup>
      </SmallFormGenerator>
    </FieldSet>
  );
};
