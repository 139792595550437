/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DemandeInscriptionFichierGridDto } from './DemandeInscriptionFichierGridDto';
import {
    DemandeInscriptionFichierGridDtoFromJSON,
    DemandeInscriptionFichierGridDtoFromJSONTyped,
    DemandeInscriptionFichierGridDtoToJSON,
} from './DemandeInscriptionFichierGridDto';
import type { EStatutDemande } from './EStatutDemande';
import {
    EStatutDemandeFromJSON,
    EStatutDemandeFromJSONTyped,
    EStatutDemandeToJSON,
} from './EStatutDemande';

/**
 * 
 * @export
 * @interface DemandeInscriptionEditDto
 */
export interface DemandeInscriptionEditDto {
    /**
     * 
     * @type {number}
     * @memberof DemandeInscriptionEditDto
     */
    iddemandeInscription?: number;
    /**
     * 
     * @type {number}
     * @memberof DemandeInscriptionEditDto
     */
    idparcoursFormation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DemandeInscriptionEditDto
     */
    idtypeCours?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeInscriptionEditDto
     */
    anneeAcademique?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DemandeInscriptionEditDto
     */
    idmetier?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DemandeInscriptionEditDto
     */
    idstade?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DemandeInscriptionEditDto
     */
    idcentre?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DemandeInscriptionEditDto
     */
    iddegre?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DemandeInscriptionEditDto
     */
    idservice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DemandeInscriptionEditDto
     */
    idstatutDemande?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeInscriptionEditDto
     */
    remarqueInvalidation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DemandeInscriptionEditDto
     */
    remarqueRefus?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DemandeInscriptionEditDto
     */
    editable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DemandeInscriptionEditDto
     */
    priCanValidate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DemandeInscriptionEditDto
     */
    centreExterieur?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DemandeInscriptionEditDto
     */
    creationUser?: number;
    /**
     * 
     * @type {number}
     * @memberof DemandeInscriptionEditDto
     */
    idpriApprenant?: number | null;
    /**
     * 
     * @type {EStatutDemande}
     * @memberof DemandeInscriptionEditDto
     */
    changeStatut?: EStatutDemande;
    /**
     * 
     * @type {Array<DemandeInscriptionFichierGridDto>}
     * @memberof DemandeInscriptionEditDto
     */
    fichiers?: Array<DemandeInscriptionFichierGridDto> | null;
}

/**
 * Check if a given object implements the DemandeInscriptionEditDto interface.
 */
export function instanceOfDemandeInscriptionEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DemandeInscriptionEditDtoFromJSON(json: any): DemandeInscriptionEditDto {
    return DemandeInscriptionEditDtoFromJSONTyped(json, false);
}

export function DemandeInscriptionEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DemandeInscriptionEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iddemandeInscription': !exists(json, 'iddemandeInscription') ? undefined : json['iddemandeInscription'],
        'idparcoursFormation': !exists(json, 'idparcoursFormation') ? undefined : json['idparcoursFormation'],
        'idtypeCours': !exists(json, 'idtypeCours') ? undefined : json['idtypeCours'],
        'anneeAcademique': !exists(json, 'anneeAcademique') ? undefined : json['anneeAcademique'],
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'idstade': !exists(json, 'idstade') ? undefined : json['idstade'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'iddegre': !exists(json, 'iddegre') ? undefined : json['iddegre'],
        'idservice': !exists(json, 'idservice') ? undefined : json['idservice'],
        'idstatutDemande': !exists(json, 'idstatutDemande') ? undefined : json['idstatutDemande'],
        'remarqueInvalidation': !exists(json, 'remarqueInvalidation') ? undefined : json['remarqueInvalidation'],
        'remarqueRefus': !exists(json, 'remarqueRefus') ? undefined : json['remarqueRefus'],
        'editable': !exists(json, 'editable') ? undefined : json['editable'],
        'priCanValidate': !exists(json, 'priCanValidate') ? undefined : json['priCanValidate'],
        'centreExterieur': !exists(json, 'centreExterieur') ? undefined : json['centreExterieur'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'idpriApprenant': !exists(json, 'idpriApprenant') ? undefined : json['idpriApprenant'],
        'changeStatut': !exists(json, 'changeStatut') ? undefined : EStatutDemandeFromJSON(json['changeStatut']),
        'fichiers': !exists(json, 'fichiers') ? undefined : (json['fichiers'] === null ? null : (json['fichiers'] as Array<any>).map(DemandeInscriptionFichierGridDtoFromJSON)),
    };
}

export function DemandeInscriptionEditDtoToJSON(value?: DemandeInscriptionEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iddemandeInscription': value.iddemandeInscription,
        'idparcoursFormation': value.idparcoursFormation,
        'idtypeCours': value.idtypeCours,
        'anneeAcademique': value.anneeAcademique,
        'idmetier': value.idmetier,
        'idstade': value.idstade,
        'idcentre': value.idcentre,
        'iddegre': value.iddegre,
        'idservice': value.idservice,
        'idstatutDemande': value.idstatutDemande,
        'remarqueInvalidation': value.remarqueInvalidation,
        'remarqueRefus': value.remarqueRefus,
        'editable': value.editable,
        'priCanValidate': value.priCanValidate,
        'centreExterieur': value.centreExterieur,
        'creationUser': value.creationUser,
        'idpriApprenant': value.idpriApprenant,
        'changeStatut': EStatutDemandeToJSON(value.changeStatut),
        'fichiers': value.fichiers === undefined ? undefined : (value.fichiers === null ? null : (value.fichiers as Array<any>).map(DemandeInscriptionFichierGridDtoToJSON)),
    };
}

