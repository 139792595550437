import { Card, IOptionProps } from "@blueprintjs/core";
import moment from "moment";
import { ButtonsBloc, InlineButtonContainer, Loading, showError } from "nsitools-react";
import * as React from "react";

import { ClasseHoraireApi, FcbClasseHorairesDto, FcbClasseHorairesDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { Changes, colName, IJExcelColumn, IJExcelNestedHeader, JExcel, SaveButton } from "../../../../../components";
import { useEventsContext } from "../../../../../contexts";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { getColor } from "../../../../../utils/randomColor";
import { JExcelView } from "./jexcel/JExcelView";

export const CLASSE_HORAIRE_ACTIONS = "CLASSE_HORAIRE_ACTIONS";
export interface IClasseHoraireItemPageProps {
  id: number;
  classeMatiere: IOptionProps[];
  local: IOptionProps[];
  state?: boolean;
  canSave: boolean;
}

export const HEADER_COLSPAN = 3;

export const ClasseHoraireItemPage: React.FunctionComponent<IClasseHoraireItemPageProps> = ({
  id,
  classeMatiere,
  local,
  state,
  canSave
}) => {
  const { t } = useTl();
  const api = useApiService(ClasseHoraireApi);

  const [jexcelInstance, setJexcelInstance] = React.useState<any>(null);
  const { subscribeToEvent, unsubscribeEvent, dispatchEvent } = useEventsContext();

  const { data, loading, saveItem, saving, refresh } = useCrudApi({
    getApiFn: React.useCallback(
      () =>
        +id <= 0
          ? FcbClasseHorairesDtoFromJSON({ classeHoraires: [] })
          : api.classeHoraireGetHoraires({ idClasse: +id }),
      [api, id]
    ),
    saveApiFn: React.useCallback(
      async d => {
        const canSave = await api.classeHoraireSaveCheck({
          FcbClasseHorairesDto: d
        });

        if (!canSave.isValid) {
          showError(t(ETLCodes.CannotEditHoraireBecauseReplacement));
          return null;
        }

        const value = await api.classeHoraireSave({
          FcbClasseHorairesDto: d
        });
        dispatchEvent("CLASSE_HORAIRE_SAVED", value);
        return value;
      },
      [api, dispatchEvent, t]
    )
  });

  const finalData = React.useMemo<FcbClasseHorairesDto>(
    () =>
      state
        ? data
        : {
            classeId: data?.classeId,
            classeHoraires:
              data?.classeHoraires?.filter(
                ch =>
                  (ch.plage01MatiereId && ch.plage01MatiereId !== "-") ||
                  (ch.plage02MatiereId && ch.plage02MatiereId !== "-") ||
                  (ch.plage03MatiereId && ch.plage03MatiereId !== "-") ||
                  (ch.plage04MatiereId && ch.plage04MatiereId !== "-") ||
                  (ch.plage05MatiereId && ch.plage05MatiereId !== "-") ||
                  (ch.plage06MatiereId && ch.plage06MatiereId !== "-") ||
                  (ch.plage07MatiereId && ch.plage07MatiereId !== "-") ||
                  (ch.plage08MatiereId && ch.plage08MatiereId !== "-") ||
                  (ch.plage09MatiereId && ch.plage09MatiereId !== "-") ||
                  (ch.plage10MatiereId && ch.plage10MatiereId !== "-") ||
                  (ch.plage11MatiereId && ch.plage11MatiereId !== "-") ||
                  (ch.plage12MatiereId && ch.plage12MatiereId !== "-") ||
                  (ch.plage13MatiereId && ch.plage13MatiereId !== "-") ||
                  (ch.plage14MatiereId && ch.plage14MatiereId !== "-")
              ) ?? []
          },
    [data, state]
  );

  const dynamicColumns = React.useMemo<string[]>(
    () => [
      finalData?.classeHoraires?.find(ch => ch.plage01ClasseHoraireId) ? "plage01" : null,
      finalData?.classeHoraires?.find(ch => ch.plage02ClasseHoraireId) ? "plage02" : null,
      finalData?.classeHoraires?.find(ch => ch.plage03ClasseHoraireId) ? "plage03" : null,
      finalData?.classeHoraires?.find(ch => ch.plage04ClasseHoraireId) ? "plage04" : null,
      finalData?.classeHoraires?.find(ch => ch.plage05ClasseHoraireId) ? "plage05" : null,
      finalData?.classeHoraires?.find(ch => ch.plage06ClasseHoraireId) ? "plage06" : null,
      finalData?.classeHoraires?.find(ch => ch.plage07ClasseHoraireId) ? "plage07" : null,
      finalData?.classeHoraires?.find(ch => ch.plage08ClasseHoraireId) ? "plage08" : null,
      finalData?.classeHoraires?.find(ch => ch.plage09ClasseHoraireId) ? "plage09" : null,
      finalData?.classeHoraires?.find(ch => ch.plage10ClasseHoraireId) ? "plage10" : null,
      finalData?.classeHoraires?.find(ch => ch.plage11ClasseHoraireId) ? "plage11" : null,
      finalData?.classeHoraires?.find(ch => ch.plage12ClasseHoraireId) ? "plage12" : null,
      finalData?.classeHoraires?.find(ch => ch.plage13ClasseHoraireId) ? "plage13" : null,
      finalData?.classeHoraires?.find(ch => ch.plage14ClasseHoraireId) ? "plage14" : null
    ],
    [finalData]
  );

  const nestedHeaders = React.useMemo<IJExcelNestedHeader[]>(() => {
    var col = [{ title: t(ETLCodes.Journee), colspan: 2 }] as IJExcelNestedHeader[];

    if (dynamicColumns.includes("plage01")) {
      col.push({
        title: finalData?.classeHoraires?.filter(ch => !!ch.plage01HeureDebut)[0]?.plage01HeureDebut,
        colspan: HEADER_COLSPAN
      });
    }

    if (dynamicColumns.includes("plage02")) {
      col.push({
        title: finalData?.classeHoraires?.filter(ch => !!ch.plage02HeureDebut)[0]?.plage02HeureDebut,
        colspan: HEADER_COLSPAN
      });
    }

    if (dynamicColumns.includes("plage03")) {
      col.push({
        title: finalData?.classeHoraires?.filter(ch => !!ch.plage03HeureDebut)[0]?.plage03HeureDebut,
        colspan: HEADER_COLSPAN
      });
    }

    if (dynamicColumns.includes("plage04")) {
      col.push({
        title: finalData?.classeHoraires?.filter(ch => !!ch.plage04HeureDebut)[0]?.plage04HeureDebut,
        colspan: HEADER_COLSPAN
      });
    }

    if (dynamicColumns.includes("plage05")) {
      col.push({
        title: finalData?.classeHoraires?.filter(ch => !!ch.plage05HeureDebut)[0]?.plage05HeureDebut,
        colspan: HEADER_COLSPAN
      });
    }

    if (dynamicColumns.includes("plage06")) {
      col.push({
        title: finalData?.classeHoraires?.filter(ch => !!ch.plage06HeureDebut)[0]?.plage06HeureDebut,
        colspan: HEADER_COLSPAN
      });
    }

    if (dynamicColumns.includes("plage07")) {
      col.push({
        title: finalData?.classeHoraires?.filter(ch => !!ch.plage07HeureDebut)[0]?.plage07HeureDebut,
        colspan: HEADER_COLSPAN
      });
    }

    if (dynamicColumns.includes("plage08")) {
      col.push({
        title: finalData?.classeHoraires?.filter(ch => !!ch.plage08HeureDebut)[0]?.plage08HeureDebut,
        colspan: HEADER_COLSPAN
      });
    }

    if (dynamicColumns.includes("plage09")) {
      col.push({
        title: finalData?.classeHoraires?.filter(ch => !!ch.plage09HeureDebut)[0]?.plage09HeureDebut,
        colspan: HEADER_COLSPAN
      });
    }

    if (dynamicColumns.includes("plage10")) {
      col.push({
        title: finalData?.classeHoraires?.filter(ch => !!ch.plage10HeureDebut)[0]?.plage10HeureDebut,
        colspan: HEADER_COLSPAN
      });
    }

    if (dynamicColumns.includes("plage11")) {
      col.push({
        title: finalData?.classeHoraires?.filter(ch => !!ch.plage11HeureDebut)[0]?.plage11HeureDebut,
        colspan: HEADER_COLSPAN
      });
    }

    if (dynamicColumns.includes("plage12")) {
      col.push({
        title: finalData?.classeHoraires?.filter(ch => !!ch.plage12HeureDebut)[0]?.plage12HeureDebut,
        colspan: HEADER_COLSPAN
      });
    }

    if (dynamicColumns.includes("plage13")) {
      col.push({
        title: finalData?.classeHoraires?.filter(ch => !!ch.plage13HeureDebut)[0]?.plage13HeureDebut,
        colspan: HEADER_COLSPAN
      });
    }

    if (dynamicColumns.includes("plage14")) {
      col.push({
        title: finalData?.classeHoraires?.filter(ch => !!ch.plage14HeureDebut)[0]?.plage14HeureDebut,
        colspan: HEADER_COLSPAN
      });
    }

    return col;
  }, [finalData, dynamicColumns, t]);

  const matiereColor = React.useMemo<{ [value: string]: string }>(() => {
    var mappedColor = { "-": "white" };

    classeMatiere?.forEach(element => {
      if (!mappedColor[element.value]) {
        mappedColor[element.value] = getColor();
      }
    });

    return mappedColor;
  }, [classeMatiere]);

  const columns = React.useMemo<IJExcelColumn<any>[]>(() => {
    var col = [
      {
        type: "text",
        name: "nomJour",
        title: "Jour",
        width: "100",
        readOnly: true,
        dynamicStyle: () => "color: black"
      },
      {
        type: "calendar",
        name: "date",
        title: "Date",
        width: "100",
        readOnly: true,
        options: { format: "dd/MM/yyyy" },
        dynamicStyle: () => "color: black"
      }
    ] as IJExcelColumn<any>[];

    if (dynamicColumns.includes("plage01")) {
      col.push({
        type: "dropdown",
        name: `plage01MatiereId`,
        title: "Matiere",
        source: classeMatiere,
        width: "150",
        dynamicStyle: item => {
          if (matiereColor[item.plage01MatiereId]) {
            return `background-color: ${matiereColor[item.plage01MatiereId]}; color: black;`;
          } else {
            if (item.plage01MatiereId) {
              return `background-color: white; color: black`;
            }
          }
        },
        readOnly: !state
      });
      col.push({
        type: "dropdown",
        name: `plage01LocalId`,
        title: "Local",
        source: local,
        width: "100",
        readOnly: !state
      });
      col.push({
        type: "checkbox",
        name: "plage01Rattrapage",
        title: "Rattrapage",
        width: "50",
        readOnly: !state
      });
    }

    if (dynamicColumns.includes("plage02")) {
      col.push({
        type: "dropdown",
        name: `plage02MatiereId`,
        title: "Matiere",
        source: classeMatiere,
        width: "150",
        dynamicStyle: item => {
          if (matiereColor[item.plage02MatiereId]) {
            return `background-color: ${matiereColor[item.plage02MatiereId]}; color: black;`;
          } else {
            if (item.plage02MatiereId) {
              return `background-color: white; color: black;`;
            }
          }
        },
        readOnly: !state
      });
      col.push({
        type: "dropdown",
        name: `plage02LocalId`,
        title: "Local",
        source: local,
        width: "100",
        readOnly: !state
      });
      col.push({
        type: "checkbox",
        name: "plage02Rattrapage",
        title: "Rattrapage",
        width: "50",
        readOnly: !state
      });
    }

    if (dynamicColumns.includes("plage03")) {
      col.push({
        type: "dropdown",
        name: `plage03MatiereId`,
        title: "Matiere",
        source: classeMatiere,
        width: "150",
        dynamicStyle: item => {
          if (matiereColor[item.plage03MatiereId]) {
            return `background-color: ${matiereColor[item.plage03MatiereId]}; color: black;`;
          } else {
            if (item.plage03MatiereId) {
              return `background-color: white; color: black;`;
            }
          }
        },
        readOnly: !state
      });
      col.push({
        type: "dropdown",
        name: `plage03LocalId`,
        title: "Local",
        source: local,
        width: "100",
        readOnly: !state
      });
      col.push({
        type: "checkbox",
        name: "plage03Rattrapage",
        title: "Rattrapage",
        width: "50",
        readOnly: !state
      });
    }

    if (dynamicColumns.includes("plage04")) {
      col.push({
        type: "dropdown",
        name: `plage04MatiereId`,
        title: "Matiere",
        source: classeMatiere,
        width: "150",
        dynamicStyle: item => {
          if (matiereColor[item.plage04MatiereId]) {
            return `background-color: ${matiereColor[item.plage04MatiereId]}; color: black;`;
          } else {
            if (item.plage04MatiereId) {
              return `background-color: white; color: black;`;
            }
          }
        },
        readOnly: !state
      });
      col.push({
        type: "dropdown",
        name: `plage04LocalId`,
        title: "Local",
        source: local,
        width: "100",
        readOnly: !state
      });
      col.push({
        type: "checkbox",
        name: "plage04Rattrapage",
        title: "Rattrapage",
        width: "50",
        readOnly: !state
      });
    }

    if (dynamicColumns.includes("plage05")) {
      col.push({
        type: "dropdown",
        name: `plage05MatiereId`,
        title: "Matiere",
        source: classeMatiere,
        width: "150",
        dynamicStyle: item => {
          if (matiereColor[item.plage05MatiereId]) {
            return `background-color: ${matiereColor[item.plage05MatiereId]}; color: black;`;
          } else {
            if (item.plage05MatiereId) {
              return `background-color: white; color: black;`;
            }
          }
        },
        readOnly: !state
      });
      col.push({
        type: "dropdown",
        name: `plage05LocalId`,
        title: "Local",
        source: local,
        width: "100",
        readOnly: !state
      });
      col.push({
        type: "checkbox",
        name: "plage05Rattrapage",
        title: "Rattrapage",
        width: "50",
        readOnly: !state
      });
    }

    if (dynamicColumns.includes("plage06")) {
      col.push({
        type: "dropdown",
        name: `plage06MatiereId`,
        title: "Matiere",
        source: classeMatiere,
        width: "150",
        dynamicStyle: item => {
          if (matiereColor[item.plage06MatiereId]) {
            return `background-color: ${matiereColor[item.plage06MatiereId]}; color: black;`;
          } else {
            if (item.plage06MatiereId) {
              return `background-color: white; color: black;`;
            }
          }
        },
        readOnly: !state
      });
      col.push({
        type: "dropdown",
        name: `plage06LocalId`,
        title: "Local",
        source: local,
        width: "100",
        readOnly: !state
      });
      col.push({
        type: "checkbox",
        name: "plage06Rattrapage",
        title: "Rattrapage",
        width: "50",
        readOnly: !state
      });
    }

    if (dynamicColumns.includes("plage07")) {
      col.push({
        type: "dropdown",
        name: `plage07MatiereId`,
        title: "Matiere",
        source: classeMatiere,
        width: "150",
        dynamicStyle: item => {
          if (matiereColor[item.plage07MatiereId]) {
            return `background-color: ${matiereColor[item.plage07MatiereId]}; color: black;`;
          } else {
            if (item.plage07MatiereId) {
              return `background-color: white; color: black;`;
            }
          }
        },
        readOnly: !state
      });
      col.push({
        type: "dropdown",
        name: `plage07LocalId`,
        title: "Local",
        source: local,
        width: "100",
        readOnly: !state
      });
      col.push({
        type: "checkbox",
        name: "plage07Rattrapage",
        title: "Rattrapage",
        width: "50",
        readOnly: !state
      });
    }

    if (dynamicColumns.includes("plage08")) {
      col.push({
        type: "dropdown",
        name: `plage08MatiereId`,
        title: "Matiere",
        source: classeMatiere,
        width: "150",
        dynamicStyle: item => {
          if (matiereColor[item.plage08MatiereId]) {
            return `background-color: ${matiereColor[item.plage08MatiereId]}; color: black;`;
          } else {
            if (item.plage08MatiereId) {
              return `background-color: white; color: black;`;
            }
          }
        },
        readOnly: !state
      });
      col.push({
        type: "dropdown",
        name: `plage08LocalId`,
        title: "Local",
        source: local,
        width: "100",
        readOnly: !state
      });
      col.push({
        type: "checkbox",
        name: "plage08Rattrapage",
        title: "Rattrapage",
        width: "50",
        readOnly: !state
      });
    }

    if (dynamicColumns.includes("plage09")) {
      col.push({
        type: "dropdown",
        name: `plage09MatiereId`,
        title: "Matiere",
        source: classeMatiere,
        width: "150",
        dynamicStyle: item => {
          if (matiereColor[item.plage09MatiereId]) {
            return `background-color: ${matiereColor[item.plage09MatiereId]}; color: black;`;
          } else {
            if (item.plage09MatiereId) {
              return `background-color: white; color: black;`;
            }
          }
        },
        readOnly: !state
      });
      col.push({
        type: "dropdown",
        name: `plage09LocalId`,
        title: "Local",
        source: local,
        width: "100",
        readOnly: !state
      });
      col.push({
        type: "checkbox",
        name: "plage09Rattrapage",
        title: "Rattrapage",
        width: "50",
        readOnly: !state
      });
    }

    if (dynamicColumns.includes("plage10")) {
      col.push({
        type: "dropdown",
        name: `plage10MatiereId`,
        title: "Matiere",
        source: classeMatiere,
        width: "150",
        dynamicStyle: item => {
          if (matiereColor[item.plage10MatiereId]) {
            return `background-color: ${matiereColor[item.plage10MatiereId]}; color: black;`;
          } else {
            if (item.plage10MatiereId) {
              return `background-color: white; color: black;`;
            }
          }
        },
        readOnly: !state
      });
      col.push({
        type: "dropdown",
        name: `plage10LocalId`,
        title: "Local",
        source: local,
        width: "100",
        readOnly: !state
      });
      col.push({
        type: "checkbox",
        name: "plage10Rattrapage",
        title: "Rattrapage",
        width: "50",
        readOnly: !state
      });
    }

    if (dynamicColumns.includes("plage11")) {
      col.push({
        type: "dropdown",
        name: `plage11MatiereId`,
        title: "Matiere",
        source: classeMatiere,
        width: "150",
        dynamicStyle: item => {
          if (matiereColor[item.plage11MatiereId]) {
            return `background-color: ${matiereColor[item.plage11MatiereId]}; color: black;`;
          } else {
            if (item.plage11MatiereId) {
              return `background-color: white; color: black;`;
            }
          }
        },
        readOnly: !state
      });
      col.push({
        type: "dropdown",
        name: `plage11LocalId`,
        title: "Local",
        source: local,
        width: "100",
        readOnly: !state
      });
      col.push({
        type: "checkbox",
        name: "plage11Rattrapage",
        title: "Rattrapage",
        width: "50",
        readOnly: !state
      });
    }

    if (dynamicColumns.includes("plage12")) {
      col.push({
        type: "dropdown",
        name: `plage12MatiereId`,
        title: "Matiere",
        source: classeMatiere,
        width: "150",
        dynamicStyle: item => {
          if (matiereColor[item.plage12MatiereId]) {
            return `background-color: ${matiereColor[item.plage12MatiereId]}; color: black;`;
          } else {
            if (item.plage12MatiereId) {
              return `background-color: white; color: black;`;
            }
          }
        },
        readOnly: !state
      });
      col.push({
        type: "dropdown",
        name: `plage12LocalId`,
        title: "Local",
        source: local,
        width: "100",
        readOnly: !state
      });
      col.push({
        type: "checkbox",
        name: "plage12Rattrapage",
        title: "Rattrapage",
        width: "50",
        readOnly: !state
      });
    }

    if (dynamicColumns.includes("plage13")) {
      col.push({
        type: "dropdown",
        name: `plage13MatiereId`,
        title: "Matiere",
        source: classeMatiere,
        width: "150",
        dynamicStyle: item => {
          if (matiereColor[item.plage13MatiereId]) {
            return `background-color: ${matiereColor[item.plage13MatiereId]}; color: black;`;
          } else {
            if (item.plage13MatiereId) {
              return `background-color: white; color: black;`;
            }
          }
        },
        readOnly: !state
      });
      col.push({
        type: "dropdown",
        name: `plage13LocalId`,
        title: "Local",
        source: local,
        width: "100",
        readOnly: !state
      });
      col.push({
        type: "checkbox",
        name: "plage13Rattrapage",
        title: "Rattrapage",
        width: "50",
        readOnly: !state
      });
    }

    if (dynamicColumns.includes("plage14")) {
      col.push({
        type: "dropdown",
        name: `plage14MatiereId`,
        title: "Matiere",
        source: classeMatiere,
        width: "150",
        dynamicStyle: item => {
          if (matiereColor[item.plage14MatiereId]) {
            return `background-color: ${matiereColor[item.plage14MatiereId]}; color: black;`;
          } else {
            if (item.plage14MatiereId) {
              return `background-color: white; color: black;`;
            }
          }
        },
        readOnly: !state
      });
      col.push({
        type: "dropdown",
        name: `plage14LocalId`,
        title: "Local",
        source: local,
        width: "100",
        readOnly: !state
      });
      col.push({
        type: "checkbox",
        name: "plage14Rattrapage",
        title: "Rattrapage",
        width: "50",
        readOnly: !state
      });
    }

    if (dynamicColumns.includes("plage01")) {
      col.push({ type: "hidden", name: "plage01ClasseHoraireId" });
      col.push({ type: "hidden", name: `plage01HeureDebut` });
    }
    if (dynamicColumns.includes("plage02")) {
      col.push({ type: "hidden", name: "plage02ClasseHoraireId" });
      col.push({ type: "hidden", name: `plage02HeureDebut` });
    }
    if (dynamicColumns.includes("plage03")) {
      col.push({ type: "hidden", name: "plage03ClasseHoraireId" });
      col.push({ type: "hidden", name: `plage03HeureDebut` });
    }
    if (dynamicColumns.includes("plage04")) {
      col.push({ type: "hidden", name: "plage04ClasseHoraireId" });
      col.push({ type: "hidden", name: `plage04HeureDebut` });
    }
    if (dynamicColumns.includes("plage05")) {
      col.push({ type: "hidden", name: "plage05ClasseHoraireId" });
      col.push({ type: "hidden", name: `plage05HeureDebut` });
    }
    if (dynamicColumns.includes("plage06")) {
      col.push({ type: "hidden", name: "plage06ClasseHoraireId" });
      col.push({ type: "hidden", name: `plage06HeureDebut` });
    }
    if (dynamicColumns.includes("plage07")) {
      col.push({ type: "hidden", name: "plage07ClasseHoraireId" });
      col.push({ type: "hidden", name: `plage07HeureDebut` });
    }
    if (dynamicColumns.includes("plage08")) {
      col.push({ type: "hidden", name: "plage08ClasseHoraireId" });
      col.push({ type: "hidden", name: `plage08HeureDebut` });
    }
    if (dynamicColumns.includes("plage09")) {
      col.push({ type: "hidden", name: "plage09ClasseHoraireId" });
      col.push({ type: "hidden", name: `plage09HeureDebut` });
    }
    if (dynamicColumns.includes("plage10")) {
      col.push({ type: "hidden", name: "plage10ClasseHoraireId" });
      col.push({ type: "hidden", name: `plage10HeureDebut` });
    }
    if (dynamicColumns.includes("plage11")) {
      col.push({ type: "hidden", name: "plage11ClasseHoraireId" });
      col.push({ type: "hidden", name: `plage11HeureDebut` });
    }
    if (dynamicColumns.includes("plage12")) {
      col.push({ type: "hidden", name: "plage12ClasseHoraireId" });
      col.push({ type: "hidden", name: `plage12HeureDebut` });
    }
    if (dynamicColumns.includes("plage13")) {
      col.push({ type: "hidden", name: "plage13ClasseHoraireId" });
      col.push({ type: "hidden", name: `plage13HeureDebut` });
    }
    if (dynamicColumns.includes("plage14")) {
      col.push({ type: "hidden", name: "plage14ClasseHoraireId" });
      col.push({ type: "hidden", name: `plage14HeureDebut` });
    }

    return col;
  }, [classeMatiere, dynamicColumns, local, matiereColor, state]);

  const onChange = React.useCallback(
    (change: Changes) => {
      const { row: x, col: y, value } = change;
      if (value) {
        if (columns[+x].dynamicStyle) {
          const mappedStyle = {};
          const fieldName = columns[+x].name;
          const item = {};
          item[fieldName] = value;
          mappedStyle[`${colName(+x)}${+y + 1}`] = columns[+x].dynamicStyle(item);
          jexcelInstance.setStyle(mappedStyle);
        }
      } else {
        jexcelInstance.setValueFromCoords(+x, +y, "-", true);
      }
    },
    [columns, jexcelInstance]
  );

  const updateTable = React.useCallback((instance, cell, col, row, val, label, cellName) => {
    if (+col !== 0 && +col !== 1) {
      if (val === "" || (cell.innerHTML.includes("checkbox") && cell.previousElementSibling.className === "readonly")) {
        cell.className = "readonly";
        cell.style.backgroundColor = "#DCDCDC";
      } else if (val === "-" || cell.innerHTML.includes("checkbox")) {
        cell.className = "";
        cell.style.backgroundColor = "white";
      } else {
        cell.className = "";
      }
    }
  }, []);

  const onSave = React.useCallback(() => {
    const dataFromJExcel = jexcelInstance?.getJson();

    const classeHoraireToSave = dataFromJExcel.filter(d => {
      const c01 = finalData?.classeHoraires.find(ch => ch.plage01ClasseHoraireId === d.plage01ClasseHoraireId);
      const c01Modified = c01
        ? c01.plage01MatiereId !== d.plage01MatiereId ||
          c01.plage01MatiereId !== d.plage01LocalId ||
          c01.plage01Rattrapage !== d.plage01Rattrapage
        : false;

      const c02 = finalData?.classeHoraires.find(ch => ch.plage02ClasseHoraireId === d.plage02ClasseHoraireId);
      const c02Modified = c02
        ? c02.plage02MatiereId !== d.plage02MatiereId ||
          c02.plage02MatiereId !== d.plage02LocalId ||
          c02.plage02Rattrapage !== d.plage02Rattrapage
        : false;

      const c03 = finalData?.classeHoraires.find(ch => ch.plage03ClasseHoraireId === d.plage03ClasseHoraireId);
      const c03Modified = c03
        ? c03.plage03MatiereId !== d.plage03MatiereId ||
          c03.plage03MatiereId !== d.plage03LocalId ||
          c03.plage03Rattrapage !== d.plage03Rattrapage
        : false;

      const c04 = finalData?.classeHoraires.find(ch => ch.plage04ClasseHoraireId === d.plage04ClasseHoraireId);
      const c04Modified = c04
        ? c04.plage04MatiereId !== d.plage04MatiereId ||
          c04.plage04MatiereId !== d.plage04LocalId ||
          c04.plage04Rattrapage !== d.plage04Rattrapage
        : false;

      const c05 = finalData?.classeHoraires.find(ch => ch.plage05ClasseHoraireId === d.plage05ClasseHoraireId);
      const c05Modified = c05
        ? c05.plage05MatiereId !== d.plage05MatiereId ||
          c05.plage05MatiereId !== d.plage05LocalId ||
          c05.plage05Rattrapage !== d.plage05Rattrapage
        : false;

      const c06 = finalData?.classeHoraires.find(ch => ch.plage06ClasseHoraireId === d.plage06ClasseHoraireId);
      const c06Modified = c06
        ? c06.plage06MatiereId !== d.plage06MatiereId ||
          c06.plage06MatiereId !== d.plage06LocalId ||
          c06.plage06Rattrapage !== d.plage06Rattrapage
        : false;

      const c07 = finalData?.classeHoraires.find(ch => ch.plage07ClasseHoraireId === d.plage07ClasseHoraireId);
      const c07Modified = c07
        ? c07.plage07MatiereId !== d.plage07MatiereId ||
          c07.plage07MatiereId !== d.plage07LocalId ||
          c07.plage07Rattrapage !== d.plage07Rattrapage
        : false;

      const c08 = finalData?.classeHoraires.find(ch => ch.plage08ClasseHoraireId === d.plage08ClasseHoraireId);
      const c08Modified = c08
        ? c08.plage08MatiereId !== d.plage08MatiereId ||
          c08.plage08MatiereId !== d.plage08LocalId ||
          c08.plage08Rattrapage !== d.plage08Rattrapage
        : false;

      const c09 = finalData?.classeHoraires.find(ch => ch.plage09ClasseHoraireId === d.plage09ClasseHoraireId);
      const c09Modified = c09
        ? c09.plage09MatiereId !== d.plage09MatiereId ||
          c09.plage09MatiereId !== d.plage09LocalId ||
          c09.plage09Rattrapage !== d.plage09Rattrapage
        : false;

      const c10 = finalData?.classeHoraires.find(ch => ch.plage10ClasseHoraireId === d.plage10ClasseHoraireId);
      const c10Modified = c10
        ? c10.plage10MatiereId !== d.plage10MatiereId ||
          c10.plage10MatiereId !== d.plage10LocalId ||
          c10.plage10Rattrapage !== d.plage10Rattrapage
        : false;

      const c11 = finalData?.classeHoraires.find(ch => ch.plage11ClasseHoraireId === d.plage11ClasseHoraireId);
      const c11Modified = c11
        ? c11.plage11MatiereId !== d.plage11MatiereId ||
          c11.plage11MatiereId !== d.plage11LocalId ||
          c11.plage11Rattrapage !== d.plage11Rattrapage
        : false;

      const c12 = finalData?.classeHoraires.find(ch => ch.plage12ClasseHoraireId === d.plage12ClasseHoraireId);
      const c12Modified = c12
        ? c12.plage12MatiereId !== d.plage12MatiereId ||
          c12.plage12MatiereId !== d.plage12LocalId ||
          c12.plage12Rattrapage !== d.plage12Rattrapage
        : false;

      const c13 = finalData?.classeHoraires.find(ch => ch.plage13ClasseHoraireId === d.plage13ClasseHoraireId);
      const c13Modified = c13
        ? c13.plage13MatiereId !== d.plage13MatiereId ||
          c13.plage13MatiereId !== d.plage13LocalId ||
          c13.plage13Rattrapage !== d.plage13Rattrapage
        : false;

      const c14 = finalData?.classeHoraires.find(ch => ch.plage14ClasseHoraireId === d.plage14ClasseHoraireId);
      const c14Modified = c14
        ? c14.plage14MatiereId !== d.plage14MatiereId ||
          c14.plage14MatiereId !== d.plage14LocalId ||
          c14.plage14Rattrapage !== d.plage14Rattrapage
        : false;

      return (
        c01Modified ||
        c02Modified ||
        c03Modified ||
        c04Modified ||
        c05Modified ||
        c06Modified ||
        c07Modified ||
        c08Modified ||
        c09Modified ||
        c10Modified ||
        c11Modified ||
        c12Modified ||
        c13Modified ||
        c14Modified
      );
    });
    return saveItem(
      FcbClasseHorairesDtoFromJSON({
        classeId: +id,
        classeHoraires: [...classeHoraireToSave]
      })
    );
  }, [finalData, id, jexcelInstance, saveItem]);

  const refreshCB = React.useCallback(() => {
    refresh();
    jexcelInstance?.refresh();
  }, [jexcelInstance, refresh]);

  React.useEffect(() => {
    subscribeToEvent("CLASSE_HORAIRE_SAVED", refreshCB);
    subscribeToEvent("CLASSE_HORAIRE_ACTIONS", refreshCB);
    subscribeToEvent("CLASSE_HORAIRE_EDIT", refresh);
    subscribeToEvent("CLASSE_HORAIRE_CALENDRIER_ITEM_SAVED", refreshCB);
    return () => {
      unsubscribeEvent("CLASSE_HORAIRE_SAVED", refreshCB);
      unsubscribeEvent("CLASSE_HORAIRE_ACTIONS", refreshCB);
      unsubscribeEvent("CLASSE_HORAIRE_EDIT", refresh);
      unsubscribeEvent("CLASSE_HORAIRE_CALENDRIER_ITEM_SAVED", refreshCB);
    };
  }, [refresh, refreshCB, subscribeToEvent, unsubscribeEvent]);

  return (
    <Card>
      <Loading isLoading={loading}>
        {finalData && classeMatiere && local && (
          <>
            {state ? (
              <>
                <div>
                  <JExcel
                    data={finalData.classeHoraires.map(ch => {
                      return { ...ch, date: moment(ch.date).format("YYYY-MM-DD") };
                    })}
                    columns={columns}
                    nestedHeaders={nestedHeaders}
                    setJexcelInstance={setJexcelInstance}
                    onChange={onChange}
                    updateTable={updateTable}
                    tableOverflow={true}
                    tableHeight="800px"
                    tableWidth="100%"
                    freezeColumns={2}
                    filters={true}
                    pagination={25}
                  />
                </div>
                <div>
                  <InlineButtonContainer>
                    <ButtonsBloc>
                      <SaveButton minimal={false} disabled={!state || !canSave} loading={saving} onClick={onSave} />
                    </ButtonsBloc>
                    <ButtonsBloc>
                      <SaveButton minimal={false} disabled={!state || !canSave} loading={saving} onClick={onSave} />
                    </ButtonsBloc>
                  </InlineButtonContainer>
                </div>
              </>
            ) : (
              <JExcelView data={finalData} columns={columns} nestedHeaders={nestedHeaders} />
            )}
          </>
        )}
      </Loading>
    </Card>
  );
};
