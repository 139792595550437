/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FcbPlageHoraireDetailDto } from './FcbPlageHoraireDetailDto';
import {
    FcbPlageHoraireDetailDtoFromJSON,
    FcbPlageHoraireDetailDtoFromJSONTyped,
    FcbPlageHoraireDetailDtoToJSON,
} from './FcbPlageHoraireDetailDto';

/**
 * 
 * @export
 * @interface FcbPlageHoraireDto
 */
export interface FcbPlageHoraireDto {
    /**
     * 
     * @type {number}
     * @memberof FcbPlageHoraireDto
     */
    idplage?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbPlageHoraireDto
     */
    idstade?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbPlageHoraireDto
     */
    idcentre?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbPlageHoraireDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbPlageHoraireDto
     */
    duree?: number | null;
    /**
     * 
     * @type {Array<FcbPlageHoraireDetailDto>}
     * @memberof FcbPlageHoraireDto
     */
    plagesDetails?: Array<FcbPlageHoraireDetailDto> | null;
}

/**
 * Check if a given object implements the FcbPlageHoraireDto interface.
 */
export function instanceOfFcbPlageHoraireDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbPlageHoraireDtoFromJSON(json: any): FcbPlageHoraireDto {
    return FcbPlageHoraireDtoFromJSONTyped(json, false);
}

export function FcbPlageHoraireDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbPlageHoraireDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idplage': !exists(json, 'idplage') ? undefined : json['idplage'],
        'idstade': !exists(json, 'idstade') ? undefined : json['idstade'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'duree': !exists(json, 'duree') ? undefined : json['duree'],
        'plagesDetails': !exists(json, 'plagesDetails') ? undefined : (json['plagesDetails'] === null ? null : (json['plagesDetails'] as Array<any>).map(FcbPlageHoraireDetailDtoFromJSON)),
    };
}

export function FcbPlageHoraireDtoToJSON(value?: FcbPlageHoraireDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idplage': value.idplage,
        'idstade': value.idstade,
        'idcentre': value.idcentre,
        'description': value.description,
        'duree': value.duree,
        'plagesDetails': value.plagesDetails === undefined ? undefined : (value.plagesDetails === null ? null : (value.plagesDetails as Array<any>).map(FcbPlageHoraireDetailDtoToJSON)),
    };
}

