/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbFormateurLocalisationContratGridDto
 */
export interface FcbFormateurLocalisationContratGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurLocalisationContratGridDto
     */
    idformateurLocalisationContrat?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurLocalisationContratGridDto
     */
    idcontratFormateur?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurLocalisationContratGridDto
     */
    idformateurLocalisation?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurLocalisationContratGridDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurLocalisationContratGridDto
     */
    dateDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurLocalisationContratGridDto
     */
    dateFin?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFormateurLocalisationContratGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbFormateurLocalisationContratGridDto interface.
 */
export function instanceOfFcbFormateurLocalisationContratGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbFormateurLocalisationContratGridDtoFromJSON(json: any): FcbFormateurLocalisationContratGridDto {
    return FcbFormateurLocalisationContratGridDtoFromJSONTyped(json, false);
}

export function FcbFormateurLocalisationContratGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbFormateurLocalisationContratGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idformateurLocalisationContrat': !exists(json, 'idformateurLocalisationContrat') ? undefined : json['idformateurLocalisationContrat'],
        'idcontratFormateur': !exists(json, 'idcontratFormateur') ? undefined : json['idcontratFormateur'],
        'idformateurLocalisation': !exists(json, 'idformateurLocalisation') ? undefined : json['idformateurLocalisation'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (json['dateDebut'] === null ? null : new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (json['dateFin'] === null ? null : new Date(json['dateFin'])),
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbFormateurLocalisationContratGridDtoToJSON(value?: FcbFormateurLocalisationContratGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idformateurLocalisationContrat': value.idformateurLocalisationContrat,
        'idcontratFormateur': value.idcontratFormateur,
        'idformateurLocalisation': value.idformateurLocalisation,
        'libelle': value.libelle,
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut === null ? null : value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin === null ? null : value.dateFin.toISOString()),
        'actif': value.actif,
    };
}

