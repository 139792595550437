/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IAggregateResult } from './IAggregateResult';
import {
    IAggregateResultFromJSON,
    IAggregateResultFromJSONTyped,
    IAggregateResultToJSON,
} from './IAggregateResult';
import type { TuteurLieuGridDto } from './TuteurLieuGridDto';
import {
    TuteurLieuGridDtoFromJSON,
    TuteurLieuGridDtoFromJSONTyped,
    TuteurLieuGridDtoToJSON,
} from './TuteurLieuGridDto';

/**
 * 
 * @export
 * @interface TuteurLieuGridDtoPaginatedResults
 */
export interface TuteurLieuGridDtoPaginatedResults {
    /**
     * 
     * @type {number}
     * @memberof TuteurLieuGridDtoPaginatedResults
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<TuteurLieuGridDto>}
     * @memberof TuteurLieuGridDtoPaginatedResults
     */
    results?: Array<TuteurLieuGridDto> | null;
    /**
     * 
     * @type {Array<IAggregateResult>}
     * @memberof TuteurLieuGridDtoPaginatedResults
     */
    aggregateResults?: Array<IAggregateResult> | null;
}

/**
 * Check if a given object implements the TuteurLieuGridDtoPaginatedResults interface.
 */
export function instanceOfTuteurLieuGridDtoPaginatedResults(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TuteurLieuGridDtoPaginatedResultsFromJSON(json: any): TuteurLieuGridDtoPaginatedResults {
    return TuteurLieuGridDtoPaginatedResultsFromJSONTyped(json, false);
}

export function TuteurLieuGridDtoPaginatedResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TuteurLieuGridDtoPaginatedResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'results': !exists(json, 'results') ? undefined : (json['results'] === null ? null : (json['results'] as Array<any>).map(TuteurLieuGridDtoFromJSON)),
        'aggregateResults': !exists(json, 'aggregateResults') ? undefined : (json['aggregateResults'] === null ? null : (json['aggregateResults'] as Array<any>).map(IAggregateResultFromJSON)),
    };
}

export function TuteurLieuGridDtoPaginatedResultsToJSON(value?: TuteurLieuGridDtoPaginatedResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'results': value.results === undefined ? undefined : (value.results === null ? null : (value.results as Array<any>).map(TuteurLieuGridDtoToJSON)),
        'aggregateResults': value.aggregateResults === undefined ? undefined : (value.aggregateResults === null ? null : (value.aggregateResults as Array<any>).map(IAggregateResultToJSON)),
    };
}

