import { Checkbox } from "@blueprintjs/core";
import * as React from "react";

import { FcbJustificatifAbsenceGridDto, JustificatifAbsenceApi, JustificatifAbsenceSearch } from "../../../../../api";
import { SearchTablePage, ViewButton, EditButton } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { useParams, useHistory } from "react-router";
import { IDataTableColumn, ButtonContainer } from "nsitools-react";
import { ERoutes } from "../../../../../AppRouter";

interface IApprenantJustificatifsSearchProps {}

export const ApprenantJustificatifsSearch: React.FunctionComponent<IApprenantJustificatifsSearchProps> = () => {
  const { t } = useTl();
  const { tUnsafe } = useTl();
  const api = useApiService(JustificatifAbsenceApi);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbJustificatifAbsenceGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.apprenant}/${id}/justificatifs/${row.idJustificatif}/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.apprenant}/${id}/justificatifs/${row.idJustificatif}/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.DateDebut),
        fieldName: "dateDebut"
      },
      {
        header: () => t(ETLCodes.DateFin),
        fieldName: "dateFin"
      },
      {
        header: () => t(ETLCodes.Genre),
        fieldName: "genre"
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "type"
      },
      {
        header: () => t(ETLCodes.Accepte),
        fieldName: "accepte",
        autofitcontent: true,
        alignment: "center",
        render: (row: FcbJustificatifAbsenceGridDto) => <Checkbox disabled checked={row.accepte} />
      },
      {
        header: () => t(ETLCodes.Jours),
        fieldName: "jours",
        render: (row: FcbJustificatifAbsenceGridDto) => (
          <>{row.jours.length > 0 ? row.jours.map(jour => tUnsafe(jour)).join(" / ") : ""}</>
        )
      },
      {
        header: () => t(ETLCodes.Plages),
        fieldName: "plages"
      },
      {
        header: () => t(ETLCodes.Remarque),
        fieldName: "remarque"
      }
    ],
    [history, id, t, tUnsafe]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.apprenant}/${+id}/justificatifs/0/edit`);
  }, [history, id]);

  const getCriterias = React.useCallback(
    () => api.justificatifAbsenceGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  const apiFactory = useAbortableApiServiceFactory(JustificatifAbsenceApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFunction = React.useCallback(
    (sObj?: JustificatifAbsenceSearch) => {
      sObj.idApprenant = +id;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.justificatifAbsenceBaseSearch({ JustificatifAbsenceSearch: sObj });
    },
    [apiFactory, id]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    +id > 0 && (
      <SearchTablePage
        columns={columns}
        getCriteriasFunction={getCriterias}
        searchFunction={searchFunction}
        onAbort={onAbort}
        addFunc={addItemFunction}
        sortKeys={{ dateDebut: "DESC" }}
        title={ETLCodes.Justificatifs}
        withCard={false}
      />
    )
  );
};
