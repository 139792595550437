/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EDateSearchType } from './EDateSearchType';
import {
    EDateSearchTypeFromJSON,
    EDateSearchTypeFromJSONTyped,
    EDateSearchTypeToJSON,
} from './EDateSearchType';

/**
 * 
 * @export
 * @interface EDateSearchTypeDateTimeNullableCombinableCriteria
 */
export interface EDateSearchTypeDateTimeNullableCombinableCriteria {
    /**
     * 
     * @type {EDateSearchType}
     * @memberof EDateSearchTypeDateTimeNullableCombinableCriteria
     */
    searchMode?: EDateSearchType;
    /**
     * 
     * @type {Date}
     * @memberof EDateSearchTypeDateTimeNullableCombinableCriteria
     */
    value?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof EDateSearchTypeDateTimeNullableCombinableCriteria
     */
    secondaryValue?: Date | null;
}

/**
 * Check if a given object implements the EDateSearchTypeDateTimeNullableCombinableCriteria interface.
 */
export function instanceOfEDateSearchTypeDateTimeNullableCombinableCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EDateSearchTypeDateTimeNullableCombinableCriteriaFromJSON(json: any): EDateSearchTypeDateTimeNullableCombinableCriteria {
    return EDateSearchTypeDateTimeNullableCombinableCriteriaFromJSONTyped(json, false);
}

export function EDateSearchTypeDateTimeNullableCombinableCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): EDateSearchTypeDateTimeNullableCombinableCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchMode': !exists(json, 'searchMode') ? undefined : EDateSearchTypeFromJSON(json['searchMode']),
        'value': !exists(json, 'value') ? undefined : (json['value'] === null ? null : new Date(json['value'])),
        'secondaryValue': !exists(json, 'secondaryValue') ? undefined : (json['secondaryValue'] === null ? null : new Date(json['secondaryValue'])),
    };
}

export function EDateSearchTypeDateTimeNullableCombinableCriteriaToJSON(value?: EDateSearchTypeDateTimeNullableCombinableCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchMode': EDateSearchTypeToJSON(value.searchMode),
        'value': value.value === undefined ? undefined : (value.value === null ? null : value.value.toISOString()),
        'secondaryValue': value.secondaryValue === undefined ? undefined : (value.secondaryValue === null ? null : value.secondaryValue.toISOString()),
    };
}

