import { FormikProps } from "formik";
import isEqual from "lodash/isEqual";
import { FGMaskInput, FGTextAreaInput, FGTextInput, FieldGroup, IFGContext } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import {
  ContactApi,
  ContactLieuDoublonSearch,
  ContactLieuEditDto,
  ContactLieuEditDtoFromJSON,
  ReferentialApi
} from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import {
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  FGWalterSuggestTextInput,
  NationaliteSelect,
  SmallFormGenerator
} from "../../../../../../components";
import FGCopyTextInput from "../../../../../../components/formGenerator/FGCopyTextInput";
import { useBeIdCardContext, useDialog } from "../../../../../../contexts";
import { useApiService, useCrudApi, useTheme, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";
import { belgianIdValidate } from "../../../../../../utils/belgianIdValidate";
import { phoneRegex } from "../../../../../../utils/phoneRegex";
import pick from "lodash/pick";

export interface ILieuFormationContactProps {
  baseData: ContactLieuDoublonSearch;
}

export const LieuFormationContact: React.FunctionComponent<ILieuFormationContactProps> = ({ baseData }) => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ContactApi);
  const refApi = useApiService(ReferentialApi);
  const formikRef = React.useRef<FormikProps<ContactLieuEditDto>>();

  const { id, state, subId: idcontactLieu } = useParams<{ id: string; state: string; subId: string }>();
  const { card } = useBeIdCardContext();
  const [cardDataDifferent, setCardDataDifferent] = React.useState(false);
  const { showDialogPromise } = useDialog();
  const { theme } = useTheme();

  const { data, loading, saveItem, saving, deleteItem, deleting, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () => {
          let newData = {};
          if (baseData) {
            newData = { ...baseData };
          }
          if (card) {
            newData = {
              ...newData,
              nom: card?.surname.toUpperCase(),
              prenom: card?.firstName,
              dateNaissance: card?.dateOfBirthParsed,
              registreNational: card?.nationalNumber
            };
          }
          return +idcontactLieu <= 0
            ? ContactLieuEditDtoFromJSON({
                ...newData,
                idcontactLieu: 0,
                idlieuFormation: +id,
                actif: true
              })
            : api.contactGetContactLieu({ idcontactLieu: +idcontactLieu });
        },
        saveApiFn: async (d: ContactLieuEditDto) => {
          return api.contactSaveContactLieu({ ContactLieuEditDto: d });
        },
        deleteApiFn: async (d: ContactLieuEditDto) => {
          const result = await showDialogPromise({
            message: t(ETLCodes.DeleteConfirmationMessage)
          });
          if (result === "yes") {
            api.contactDeleteContactLieu({ idcontactLieu: d.idcontactLieu });
          }
        },
        onDeletedRoute: () => `${ERoutes.lieuFormation}/${+id}${ERoutes.contacts}/${state}`,
        onSavedRoute: () => `${ERoutes.lieuFormation}/${+id}${ERoutes.contacts}/${state}`
      }),
      [api, baseData, card, id, idcontactLieu, showDialogPromise, state, t]
    )
  );

  const idpersonne = React.useMemo(() => data?.idpersonne ?? 0, [data?.idpersonne]);
  const [personneTelephones, personneTelephonesLoading] = useReferential(
    a => a.referentialGetTelephonesPersonne({ idpersonne }),
    false,
    [idpersonne]
  );
  const [personneGsms, personneGsmsLoading] = useReferential(a => a.referentialGetGsmsPersonne({ idpersonne }), false, [
    idpersonne
  ]);

  const [personneEmails, personneEmailsLoading] = useReferential(
    a => a.referentialGetEmailsPersonne({ idpersonne }),
    false,
    [idpersonne]
  );

  const fetchExistingFunction = React.useCallback(() => {
    return refApi.referentialGetFonctionsContactsLieu();
  }, [refApi]);
  const { data: existingFonctions, isFetching: efLoading } = useQuery("existing-functions", fetchExistingFunction, {
    cacheTime: 0
  });

  const askDataDifferent = React.useCallback(async () => {
    const result = await showDialogPromise({
      message: t(ETLCodes.TuteurLieuIdCardIsDifferent)
    });
    if (result === "yes") {
      setCardDataDifferent(true);
    }
  }, [showDialogPromise, t]);

  React.useEffect(() => {
    if (data && card) {
      const cardObj = ContactLieuEditDtoFromJSON({
        nom: card?.surname.toUpperCase(),
        prenom: card?.firstName,
        dateNaissance: card?.dateOfBirthParsed,
        registreNational: card?.nationalNumber
      });

      const propsToPick = ["nom", "prenom", "dateNaissance", "registreNational"];
      const cardBirthdate = new Date(card.dateOfBirthParsed);
      const cardBirthdateString = `${cardBirthdate.getDate()}/${cardBirthdate.getMonth() +
        1}/${cardBirthdate.getFullYear()}`;
      const dataBirthdateString = `${data.dateNaissance.getDate()}/${data.dateNaissance.getMonth() +
        1}/${data.dateNaissance.getFullYear()}`;
      if (
        +idcontactLieu > 0 &&
        (!isEqual(pick(cardObj, propsToPick), pick(data, propsToPick)) || cardBirthdateString !== dataBirthdateString)
      ) {
        askDataDifferent();
      }
    }
  }, [askDataDifferent, card, data, idcontactLieu]);

  React.useEffect(() => {
    if (cardDataDifferent) {
      setCardDataDifferent(false);
      formikRef?.current?.setValues(
        ContactLieuEditDtoFromJSON({
          ...data,
          nom: card?.surname.toUpperCase() ?? data.nom,
          prenom: card?.firstName ?? data.prenom,
          dateNaissance: card?.dateOfBirthParsed ?? data.dateNaissance,
          registreNational: card?.nationalNumber ?? data.registreNational
        }),
        true
      );
    }
  }, [card?.dateOfBirthParsed, card?.firstName, card?.nationalNumber, card?.surname, cardDataDifferent, data]);

  const isRegNatDateNaiss = React.useCallback((ctx: IFGContext) => {
    const value = ctx.formik.values.registreNational?.replace(".", "").replace("-", "");
    if (ctx.formik.values.dateNaissance && ctx.formik.values.nationalite === "BE") {
      const parsedDateNaissance = new Date(ctx.formik.values.dateNaissance);
      if (parsedDateNaissance && value) {
        const year = value.slice(0, 2);
        const month = value.slice(2, 4);
        const day = value.slice(4, 6);
        if (
          +year !==
          +parsedDateNaissance
            .getFullYear()
            .toString()
            .substr(2, 2)
        ) {
          return false;
        }
        if (+month !== 0 && +month !== parsedDateNaissance.getMonth() + 1) {
          return false;
        }
        if (+day !== 0 && +day !== parsedDateNaissance.getDate()) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, []);

  const isRegNatGenre = React.useCallback((ctx: IFGContext) => {
    const value = ctx.formik.values.registreNational?.replace(".", "")?.replace("-", "");
    if (value && ctx.formik.values.codesexe !== "X" && ctx.formik.values.nationalite === "BE") {
      const code = +value.slice(7, 9) % 2 === 0 ? "F" : "H";
      return ctx.formik.values.codesexe === code;
    }
    return true;
  }, []);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      nom: Yup.string().required(t(ETLCodes.Required)),
      prenom: Yup.string().required(t(ETLCodes.Required)),
      dateNaissance: Yup.date().nullable(),
      nationalite: Yup.string().required(t(ETLCodes.Required)),
      registreNational: Yup.string()
        .nullable()
        .test("registre-checksum", t(ETLCodes.NotValid), function(value) {
          if (!value || value === "") return true;

          var millenial = false;
          if (this.parent.dateNaissance) {
            const parsedDateNaissance = new Date(this.parent.dateNaissance);
            if (parsedDateNaissance.getFullYear() > 1999) {
              millenial = true;
            }
          }

          return belgianIdValidate(value, millenial);
        }),
      numeroIdentification: Yup.string().nullable(),
      telephone: Yup.object().shape({
        idtelephone: Yup.number()
          .nullable()
          .test("telid-required", t(ETLCodes.AtLeastOneContactField), function(value, context: any) {
            if ((!this.parent.idcontactLieu || this.parent.idcontactLieu <= 0) && !context.from[1].value.idpersonne)
              return true;
            return !!value || !!context.from[1].value.gsm?.idtelephone || !!context.from[1].value.email?.idemail;
          }),
        numero: Yup.string()
          .nullable()
          .test("tel-required", t(ETLCodes.AtLeastOneContactField), function(value, context: any) {
            if (this.parent.idcontactLieu > 0 || !!context.from[1].value.idpersonne) return true;
            return !!value || !!context.from[1].value.gsm?.numero || !!context.from[1].value.email?.adresseEmail;
          })
          .test("tel-valid", t(ETLCodes.ErrorPhoneRegex), function(value, context: any) {
            if (this.parent.idcontactLieu > 0 || !value || !!context.from[1].value.idpersonne) return true;
            const numero = value as string;
            return !!numero.match(phoneRegex);
          })
      }),
      gsm: Yup.object().shape({
        idtelephone: Yup.number()
          .nullable()
          .test("gsmid-required", t(ETLCodes.AtLeastOneContactField), function(value, context: any) {
            if ((!this.parent.idcontactLieu || this.parent.idcontactLieu <= 0) && !context.from[1].value.idpersonne)
              return true;
            return !!value || !!context.from[1].value.telephone?.idtelephone || !!context.from[1].value.email?.idemail;
          }),
        numero: Yup.string()
          .nullable()
          .test("gsm-required", t(ETLCodes.AtLeastOneContactField), function(value, context: any) {
            if (this.parent.idcontactLieu > 0 || !!context.from[1].value.idpersonne) return true;
            return !!value || !!context.from[1].value.telephone?.numero || !!context.from[1].value.email?.adresseEmail;
          })
          .test("gsm-valid", t(ETLCodes.ErrorPhoneRegex), function(value, context: any) {
            if (this.parent.idcontactLieu > 0 || !value || !!context.from[1].value.idpersonne) return true;
            const numero = value as string;
            return !!numero.match(phoneRegex);
          })
      }),
      email: Yup.object().shape({
        idemail: Yup.number()
          .nullable()
          .test("emailid-required", t(ETLCodes.AtLeastOneContactField), function(value, context: any) {
            if ((!this.parent.idcontactLieu || this.parent.idcontactLieu <= 0) && !context.from[1].value.idpersonne)
              return true;
            return (
              !!value || !!context.from[1].value.telephone?.idtelephone || !!context.from[1].value.gsm?.idtelephone
            );
          }),
        adresseEmail: Yup.string()
          .nullable()
          .email(t(ETLCodes.InvalidEmail))
          .test("gsm-required", t(ETLCodes.AtLeastOneContactField), function(value, context: any) {
            if (this.parent.idcontactLieu > 0 || !!context.from[1].value.idpersonne) return true;
            return !!value || !!context.from[1].value.telephone?.numero || !!context.from[1].value.gsm?.numero;
          })
      })
    });
  }, [t]);

  const isFullCreation = React.useMemo(() => +idcontactLieu <= 0 && !data?.idpersonne, [
    data?.idpersonne,
    idcontactLieu
  ]);

  const [sexe, sLoading] = useReferential(a => a.referentialGetSexe());

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={state === "edit"}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(`${ERoutes.lieuFormation}/${id}/contacts/${state}`)}
      saving={saving}
      formikInnerRef={i => (formikRef.current = i)}
      validationErrors={validationErrors}
      onDelete={deleteItem}
      deleting={deleting}
      showDeleteButton={+idcontactLieu > 0}
      forceEnableSave={Object.values(baseData).some(el => !!el)}
    >
      <FieldGroup columns={3}>
        <FieldGroup>
          <FGTextInput name="nom" label={t(ETLCodes.Nom)} maxLength={50} forceCase="upper" />
          <FGTextInput name="prenom" label={t(ETLCodes.Prenom)} maxLength={100} />
          <FGWalterSelectInput name="codesexe" label={t(ETLCodes.Genre)} items={sexe} loading={sLoading} />
          <FGWalterDateMaskInput name="dateNaissance" label={t(ETLCodes.DateNaissance)} />
          <NationaliteSelect name="nationalite" codeSexeFieldName="codesexe" />
          <FGMaskInput
            name="registreNational"
            label={t(ETLCodes.NumeroNational)}
            cleaveOptions={{ delimiters: [".", ".", "-", "."], blocks: [2, 2, 2, 3, 2] }}
            helperText={ctx => (
              <>
                {isRegNatDateNaiss(ctx) ? null : (
                  <span style={{ color: theme.warningColor }}>{t(ETLCodes.DateDeNaissanceNeCorrespondPas)}</span>
                )}
                {isRegNatGenre(ctx) ? null : (
                  <span style={{ color: theme.warningColor }}>{t(ETLCodes.GenreNeCorrespondPas)}</span>
                )}
              </>
            )}
          />
          <FGTextInput
            name="numeroIdentification"
            label={t(ETLCodes.NumeroIdentification)}
            visible={ctx => !!ctx.formik?.values?.nationalite && ctx.formik?.values?.nationalite !== "BE"}
          />
          {isFullCreation ? (
            <FGCopyTextInput copyOnlyDigits={true} name="gsm.numero" label={t(ETLCodes.Gsm)} maxLength={20} />
          ) : (
            <FGWalterSelectInput
              name="gsm.idtelephone"
              label={t(ETLCodes.Gsm)}
              items={personneGsms}
              loading={personneGsmsLoading}
              itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
              itemCreateParams={`&typeTel=GSM_PERSO`}
              autoSelectIfOne
            />
          )}
          {isFullCreation ? (
            <FGCopyTextInput copyOnlyDigits={true} name="telephone.numero" label={t(ETLCodes.Tel)} maxLength={20} />
          ) : (
            <FGWalterSelectInput
              name="telephone.idtelephone"
              label={t(ETLCodes.Tel)}
              items={personneTelephones}
              loading={personneTelephonesLoading}
              itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
              itemCreateParams={`&typeTel=PERSO`}
              autoSelectIfOne
            />
          )}
          {isFullCreation ? (
            <FGTextInput name="email.adresseEmail" label={t(ETLCodes.General_Email)} maxLength={100} />
          ) : (
            <FGWalterSelectInput
              name="email.idemail"
              label={t(ETLCodes.General_Email)}
              loading={personneEmailsLoading}
              items={personneEmails}
              itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
              itemCreateParams={`&typeEmail=PERSO`}
              autoSelectIfOne
            />
          )}
          <FGWalterSuggestTextInput
            name="fonction"
            label={t(ETLCodes.Fonction)}
            items={existingFonctions}
            loading={efLoading}
          />
          <FGWalterDateMaskInput name="sortieEntreprise" label={t(ETLCodes.SortieEntreprise)} />
          <FGWalterCheckboxInput name="principal" label={t(ETLCodes.PersonneContactPrincipale)} />
        </FieldGroup>
        <FieldGroup></FieldGroup>
      </FieldGroup>
      <FGTextAreaInput name="remarqueLieu" label={t(ETLCodes.Remarque)} />
    </SmallFormGenerator>
  );
};
