/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbStatutSocialDto,
  FcbStatutSocialGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
  StatutSocialSearch,
} from '../models/index';
import {
    FcbStatutSocialDtoFromJSON,
    FcbStatutSocialDtoToJSON,
    FcbStatutSocialGridDtoPaginatedResultsFromJSON,
    FcbStatutSocialGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    StatutSocialSearchFromJSON,
    StatutSocialSearchToJSON,
} from '../models/index';

export interface StatutSocialBaseSearchRequest {
    StatutSocialSearch?: StatutSocialSearch;
}

export interface StatutSocialDeleteRequest {
    id?: number;
}

export interface StatutSocialGetRequest {
    id?: number;
}

export interface StatutSocialGetDisplayNameRequest {
    id?: number;
}

export interface StatutSocialGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface StatutSocialGetSelectItemsRequest {
    searchField: string;
}

export interface StatutSocialSaveRequest {
    FcbStatutSocialDto?: FcbStatutSocialDto;
}

/**
 * 
 */
export class StatutSocialApi extends runtime.BaseAPI {

    /**
     */
    async statutSocialBaseSearchRaw(requestParameters: StatutSocialBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbStatutSocialGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/StatutSocial/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatutSocialSearchToJSON(requestParameters.StatutSocialSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbStatutSocialGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async statutSocialBaseSearch(requestParameters: StatutSocialBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbStatutSocialGridDtoPaginatedResults> {
        const response = await this.statutSocialBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async statutSocialDeleteRaw(requestParameters: StatutSocialDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/StatutSocial`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async statutSocialDelete(requestParameters: StatutSocialDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.statutSocialDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async statutSocialGetRaw(requestParameters: StatutSocialGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbStatutSocialDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/StatutSocial`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbStatutSocialDtoFromJSON(jsonValue));
    }

    /**
     */
    async statutSocialGet(requestParameters: StatutSocialGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbStatutSocialDto> {
        const response = await this.statutSocialGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async statutSocialGetDisplayNameRaw(requestParameters: StatutSocialGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/StatutSocial/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async statutSocialGetDisplayName(requestParameters: StatutSocialGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.statutSocialGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async statutSocialGetSearchCriteriasRaw(requestParameters: StatutSocialGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/StatutSocial/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async statutSocialGetSearchCriterias(requestParameters: StatutSocialGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.statutSocialGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async statutSocialGetSelectItemsRaw(requestParameters: StatutSocialGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling statutSocialGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/StatutSocial/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async statutSocialGetSelectItems(requestParameters: StatutSocialGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.statutSocialGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async statutSocialSaveRaw(requestParameters: StatutSocialSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbStatutSocialDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/StatutSocial`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbStatutSocialDtoToJSON(requestParameters.FcbStatutSocialDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbStatutSocialDtoFromJSON(jsonValue));
    }

    /**
     */
    async statutSocialSave(requestParameters: StatutSocialSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbStatutSocialDto> {
        const response = await this.statutSocialSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
