/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbCodePostalGridDto
 */
export interface FcbCodePostalGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbCodePostalGridDto
     */
    idcodePostal?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbCodePostalGridDto
     */
    codePostal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCodePostalGridDto
     */
    pays?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCodePostalGridDto
     */
    codePays?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCodePostalGridDto
     */
    commune?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCodePostalGridDto
     */
    localite?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbCodePostalGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbCodePostalGridDto interface.
 */
export function instanceOfFcbCodePostalGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbCodePostalGridDtoFromJSON(json: any): FcbCodePostalGridDto {
    return FcbCodePostalGridDtoFromJSONTyped(json, false);
}

export function FcbCodePostalGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbCodePostalGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcodePostal': !exists(json, 'idcodePostal') ? undefined : json['idcodePostal'],
        'codePostal': !exists(json, 'codePostal') ? undefined : json['codePostal'],
        'pays': !exists(json, 'pays') ? undefined : json['pays'],
        'codePays': !exists(json, 'codePays') ? undefined : json['codePays'],
        'commune': !exists(json, 'commune') ? undefined : json['commune'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbCodePostalGridDtoToJSON(value?: FcbCodePostalGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcodePostal': value.idcodePostal,
        'codePostal': value.codePostal,
        'pays': value.pays,
        'codePays': value.codePays,
        'commune': value.commune,
        'localite': value.localite,
        'actif': value.actif,
    };
}

