import { Tab, Tabs } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ApprenantApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { useEventsContext } from "../../../../../contexts";
import { useApiService } from "../../../../../hooks";
import { ApprenantResultatType } from "./ApprenantResultatType";

export interface IApprenantResultatTypeTabsProps {}

export const ApprenantResultatTypeTabs: React.FunctionComponent<IApprenantResultatTypeTabsProps> = () => {
  const history = useHistory();
  const { id, anneeTab } = useParams<{
    id: string;
    anneeTab: string;
  }>();
  const idApprenant = React.useMemo(() => +id, [id]);
  const api = useApiService(ApprenantApi);
  const { dispatchEvent } = useEventsContext();

  const [annees] = useApiEffect(() => api.apprenantGetAnneesScolairesInscrites({ IdApprenant: idApprenant }), [
    idApprenant
  ]);

  return (
    annees && (
      <Tabs
        id="TabsApprenantResultatType"
        onChange={newTabId => {
          // setAnneeEnCours(newTabId.toString());
          history.push(`${ERoutes.apprenant}/${idApprenant}/resultats/${newTabId}`);
          dispatchEvent("DELIBERATION_ANNEE_CHANGED");
        }}
        selectedTabId={anneeTab}
        renderActiveTabPanelOnly
        vertical
      >
        {annees.map(a => {
          return (
            <Tab
              id={a}
              title={a}
              panel={<ApprenantResultatType apprenantId={+id} />}
              panelClassName="full-width-panel"
            />
          );
        })}
      </Tabs>
    )
  );
};
