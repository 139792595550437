import * as React from "react";
import { matchPath, useHistory, useLocation } from "react-router";

import { ApprenantSearchSocieteExterne, ApprenantSignaletique } from "..";
import { ApprenantDoublonSearchFromJSON } from "../../../../../api/models/ApprenantDoublonSearch";
import { ERoutes } from "../../../../../AppRouter";
import { useAuth } from "../../../../../contexts";
import { useTabMessage } from "../../../../../hooks";
import { ApprenantSignaletiqueDoublons } from "./ApprenantSignaletiqueDoublons";

export interface IApprenantSignaletiqueSwitchProps {}

export const ApprenantSignaletiqueSwitch: React.FunctionComponent<IApprenantSignaletiqueSwitchProps> = () => {
  const [sObj, setSObj] = React.useState(ApprenantDoublonSearchFromJSON({}));
  const { pathname, search } = useLocation();
  const cfs = React.useMemo(() => new URLSearchParams(search).get("cfs"), [search]);
  const { messageValue, clearMessageValue } = useTabMessage(cfs);

  React.useEffect(() => {
    if (!!messageValue) {
      setSObj(messageValue);
      clearMessageValue();
    }
  }, [clearMessageValue, messageValue]);
  const history = useHistory();

  const { user } = useAuth();
  const isSocieteExterne = React.useMemo(() => user?.idsSocieteExterne?.length > 0, [user?.idsSocieteExterne?.length]);
  React.useEffect(() => {
    if (
      (isSocieteExterne && matchPath(pathname, { path: `${ERoutes.apprenant}/0/:tab/checkDoublons` })) ||
      (!isSocieteExterne && matchPath(pathname, { path: `${ERoutes.apprenant}/0/:tab/societeExterne` }))
    ) {
      history.push(ERoutes.unauthorized);
    }
  }, [history, isSocieteExterne, pathname]);

  if (
    matchPath(pathname, {
      path: `${ERoutes.apprenant}/0/:tab/checkDoublons`
    })
  ) {
    return <ApprenantSignaletiqueDoublons setBaseData={setSObj} />;
  } else if (
    matchPath(pathname, {
      path: `${ERoutes.apprenant}/0/:tab/societeExterne`
    })
  ) {
    return <ApprenantSearchSocieteExterne setBaseData={setSObj} />;
  } else {
    return <ApprenantSignaletique baseData={sObj} />;
  }
};
