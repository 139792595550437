import { Spinner, SpinnerSize, Tab } from "@blueprintjs/core";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router";

import {
  LieuFormationAgrementSwitch,
  LieuFormationContrats,
  LieuFormationHistory,
  LieuFormationOffres,
  LieuFormationSignaletique,
  LieuFormationSuiviSwitch,
  LieuFormationTuteurSwitch
} from ".";
import { LieuFormationApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { LieuFormationContactSwitch } from "./contacts";
import { LieuFormationDocuments } from "./documents";

export interface ILieuFormationItemPageProps {}

export const LieuFormationItemPage: React.FunctionComponent<ILieuFormationItemPageProps> = props => {
  const { t } = useTl();
  const { id, tab, state } = useParams<{ id: string; tab: string; state: string }>();
  const idLieuFormation = React.useMemo(() => +id, [id]);
  const history = useHistory();

  const { search } = useLocation();
  const idsiegeSocial = React.useMemo(() => new URLSearchParams(search).get("idsiegeSocial"), [search]);

  const lieuFormationApi = useApiService(LieuFormationApi);

  const { data: displayName, isLoading: displayNameLoading } = useQuery(["lieu-formation-display-name", id], () =>
    lieuFormationApi.lieuFormationGetDisplayName({ id: +id })
  );
  const onTabChange = React.useCallback(
    newTabId => {
      history.push(
        `${ERoutes.lieuFormation}/${id}/${newTabId}/${state !== "edit" && state !== "view" ? "view" : state}${search}`
      );
    },
    [history, id, search, state]
  );

  const finalDisplayName = React.useMemo(
    () => (displayNameLoading ? <Spinner size={SpinnerSize.SMALL} /> : displayName),
    [displayName, displayNameLoading]
  );

  const backRoute = React.useMemo(
    () =>
      !!idsiegeSocial
        ? `${ERoutes.siegeSocial}/${idsiegeSocial}/lieuxFormation/${
            state !== "edit" && state !== "view" ? "view" : state
          }`
        : ERoutes.siegeSocial,
    [idsiegeSocial, state]
  );

  return (
    <PageBase
      breadCrumbs={[
        {
          text: t(ETLCodes.LieuFormation),
          route: `${ERoutes.siegeSocial}/${idsiegeSocial}/lieuxFormation/${
            state !== "edit" && state !== "view" ? "view" : state
          }`
        },
        { text: +id > 0 ? finalDisplayName : t(ETLCodes.New) }
      ]}
    >
      <ProtectedTabs id="TabsLieuFormation" onChange={onTabChange} selectedTabId={tab} renderActiveTabPanelOnly>
        <BackButton backRoute={backRoute}></BackButton>
        <Tab id="detail" title={t(ETLCodes.Signaletique)} panel={<LieuFormationSignaletique />} />
        <Tab
          id="tuteur"
          title={t(ETLCodes.Tuteurs)}
          panel={<LieuFormationTuteurSwitch />}
          disabled={idLieuFormation <= 0}
        />
        <Tab
          id="contrat"
          title={t(ETLCodes.Contrats)}
          panel={<LieuFormationContrats />}
          disabled={idLieuFormation <= 0}
        />
        <Tab
          id="agrements"
          title={t(ETLCodes.Agrements)}
          panel={<LieuFormationAgrementSwitch />}
          disabled={idLieuFormation <= 0}
        />
        <Tab
          id="suivi"
          title={t(ETLCodes.Suivi)}
          panel={<LieuFormationSuiviSwitch />}
          disabled={idLieuFormation <= 0}
        />
        <Tab
          id="contacts"
          title={t(ETLCodes.Contacts)}
          panel={<LieuFormationContactSwitch />}
          disabled={idLieuFormation <= 0}
        />
        <Tab
          id="history"
          title={t(ETLCodes.History)}
          panel={<LieuFormationHistory />}
          disabled={idLieuFormation <= 0}
        />
        <Tab id="offres" title={t(ETLCodes.Offres)} panel={<LieuFormationOffres />} disabled={idLieuFormation <= 0} />
        <Tab
          id="documents"
          title={t(ETLCodes.Documents)}
          panel={<LieuFormationDocuments />}
          disabled={idLieuFormation <= 0}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
