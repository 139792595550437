import { Classes, Radio, RadioGroup } from "@blueprintjs/core";
import { FieldArray, FormikProps, getIn } from "formik";
import {
  FGCustomInput,
  FGCustomPanel,
  FGEmpty,
  FGListen,
  FGMultiSuggestInput,
  FGTextInput,
  FieldGroup,
  FieldSet,
  IFGContext
} from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";

import { UserDto, UserDtoFromJSON, UtilisateurApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { FGWalterCheckboxInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../components";
import FGCopyTextInput from "../../../../components/formGenerator/FGCopyTextInput";
import { useApiService, useCrudApi, useTheme, useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";
import { ETLCodes } from "../../../../locales";
import { phoneRegex } from "../../../../utils/phoneRegex";
import ImpersonateButton from "./ImpersonateButton";

const StyledList = styled.div`
  margin-top: 1em;

  .${Classes.LABEL} {
    min-width: 350px;
  }
`;
export interface IUtilisateurDetailPageProps {}

export const UtilisateurDetailPage: React.FunctionComponent<IUtilisateurDetailPageProps> = () => {
  const { t } = useTl();
  const { id, state } = useParams<{ id: string; tab: string; state: string }>();
  const idUtilisateur = React.useMemo(() => +id, [id]);
  const { theme } = useTheme();
  const api = useApiService(UtilisateurApi);
  const history = useHistory();
  const { ifapmeSide } = useTheme();

  const [passwordChange, setpasswordChange] = React.useState<boolean>();

  const formikRef = React.useRef<FormikProps<UserDto>>();

  const { data, loading, saveItem, saving, deleteItem, deleting } = useCrudApi<UserDto>(
    React.useMemo(
      () => ({
        getApiFn: () =>
          idUtilisateur <= 0
            ? UserDtoFromJSON({
                iduser: 0,
                ifapmeSide: ifapmeSide === "hope" ? "Hope" : "Walter",
                idtype: null,
                passwordChange: true,
                pwdconfirm: null,
                validationDirection: false,
                actif: true,
                centreUsers: []
              })
            : api.utilisateurGetUser({ idUser: idUtilisateur }),
        saveApiFn: (d: UserDto) => api.utilisateurSave({ UserDto: { ...d, passwordChange: passwordChange } }),
        deleteApiFn: d => api.utilisateurDelete({ id: d.iduser }),
        onDeletedRoute: () => ERoutes.users,
        onSavedRoute: d => `${ERoutes.users}/${d.iduser}/detail/edit`
      }),
      [api, idUtilisateur, ifapmeSide, passwordChange]
    )
  );

  const idpersonne = React.useMemo(() => data?.idpersonne ?? 0, [data?.idpersonne]);
  const [personneTelephones, personneTelephonesLoading] = useReferential(
    a => a.referentialGetTelephonesPersonne({ idpersonne }),
    false,
    [idpersonne]
  );
  const [personneGsms, personneGsmsLoading] = useReferential(a => a.referentialGetGsmsPersonne({ idpersonne }), false, [
    idpersonne
  ]);

  const [personneEmails, personneEmailsLoading] = useReferential(
    a => a.referentialGetEmailsPersonne({ idpersonne }),
    false,
    [idpersonne]
  );

  const [levels, lloading] = useReferential(apiL => apiL.referentialGetBothAccessLevels(), true);
  const [walterLevels, wloading] = useReferential(apiL => apiL.referentialGetWalterAccessLevels(), true);
  const [hopeLevels, hloading] = useReferential(apiL => apiL.referentialGetHopeAccessLevels(), true);
  const [types, tloading] = useReferential(apiT => apiT.referentialGetAccessLevelsTypes(), true);

  const [formateurs, floading] = useReferential(apiC => apiC.referentialGetFormateur(), false, []);
  const [conseillers, cloading] = useReferential(apiC => apiC.referentialGetConseillersPedagogiques(), false, []);
  const [directions, dloading] = useReferential(apiC => apiC.referentialGetDirectionTerritoriale(), true, []);
  const [departements, deLoading] = useReferential(apiC => apiC.referentialGetDepartements(), true, []);
  const [postes, pLoading] = useReferential(apiC => apiC.referentialGetPostes(), true, []);
  const [sexe, sLoading] = useReferential(a => a.referentialGetSexe());
  const [societesExternes, seLoading] = useReferential(apiC => apiC.referentialGetSocietesExternes(), true, []);

  React.useEffect(() => {
    if (!!data) {
      setpasswordChange(data.passwordChange);
    }
  }, [data]);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      nom: Yup.string().required(t(ETLCodes.Required)),
      prenom: Yup.string().required(t(ETLCodes.Required)),
      codesexe: Yup.string().required(t(ETLCodes.Required)),
      telephone: Yup.object().test("tel-valid", t(ETLCodes.ErrorPhoneRegex), function(value) {
        if (this.parent.idapprenant > 0 || !value?.numero) return true;
        const numero = value?.numero as string;
        return !!numero.match(phoneRegex);
      }),
      gsmApprenant: Yup.object().test("gsm-valid", t(ETLCodes.ErrorPhoneRegex), function(value) {
        if (this.parent.idapprenant > 0 || !value?.numero) return true;
        const numero = value?.numero as string;
        return !!numero.match(phoneRegex);
      }),
      email: Yup.object().when("idapprenant", {
        is: idapprenant => idapprenant <= 0,
        then: Yup.object().shape({
          adresseEmail: Yup.string().email(t(ETLCodes.InvalidEmail))
        })
      }),
      login: Yup.string().nullable(),
      azureEmail: Yup.string().email(),
      idlevel: Yup.number()
        .nullable()
        .required(t(ETLCodes.Required)),
      pwd: Yup.string().test("match", t(ETLCodes.PasswordMustMatch), function(value) {
        return !this.parent.pwdconfirm || this.parent.pwdconfirm === value;
      }),
      pwdconfirm: Yup.string().test("match", t(ETLCodes.PasswordMustMatch), function(value) {
        return !this.parent.pwd || this.parent.pwd === value;
      })
    });
  }, [t]);

  const [previousIdtype, setPreviousIdtype] = React.useState(data?.idtype);
  React.useEffect(() => {
    if (data?.idtype) setPreviousIdtype(data?.idtype);
  }, [data?.idtype]);

  const isRiAriCop = React.useCallback(
    idlevel => {
      return (
        !!hopeLevels && hopeLevels.filter(l => ["RI", "ARI", "COP"].includes(l.label)).some(l => +l.value === +idlevel)
      );
    },
    [hopeLevels]
  );

  const formateurIdType = React.useMemo(() => +types?.find(l => l.label === "Formateur")?.value, [types]);

  const getShowTypeWarning = React.useCallback(
    (ctx: IFGContext<UserDto>) => {
      if (!ctx?.formik?.values?.idtype) return;
      if (!!ctx?.formik?.values?.idformateur && +ctx?.formik?.values?.idtype !== formateurIdType) {
        return <span style={{ color: theme.warningColor }}>{t(ETLCodes.UserIsFormateurWarning)}</span>;
      }
      if (!ctx?.formik?.values?.idformateur && +ctx?.formik?.values?.idtype === formateurIdType) {
        return <span style={{ color: theme.warningColor }}>{t(ETLCodes.UserIsNotFormateurWarning)}</span>;
      }
    },
    [formateurIdType, t, theme.warningColor]
  );

  const isFullCreation = React.useMemo(() => idUtilisateur <= 0 && !idpersonne, [idUtilisateur, idpersonne]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={state === "edit"}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(ERoutes.users)}
      saving={saving}
      onDelete={deleteItem}
      deleting={deleting}
      showDeleteButton={idUtilisateur > 0}
      formikInnerRef={f => {
        formikRef.current = f;
      }}
      minLabelWidth={350}
    >
      <FGListen
        field="idlevel"
        onChanged={(value, formik) => {
          if ((!value || value !== formik.initialValues.idlevel) && formik?.touched["idlevel"]) {
            formik.setFieldValue("idsSocieteExterne", []);
          }
        }}
      />
      <FGListen
        field="idtype"
        onChanged={(value, formik) => {
          if (!value && (value ?? null) !== (previousIdtype ?? null)) {
            formik.setFieldValue("idconseillerPedagogique", null);
            formik.setFieldValue("iddirectionTerritoriale", null);
            formik.setFieldValue("idformateur", null);
            setPreviousIdtype(value);
          }
        }}
      />

      <FieldGroup
        fieldsetProps={{ title: t(ETLCodes.Collaborateur) }}
        columns={2}
        rightElement={idUtilisateur && <ImpersonateButton user={data} />}
      >
        <FieldGroup>
          <FGTextInput name="nom" label={t(ETLCodes.Nom)} maxLength={20} forceCase="upper" />
          <FGTextInput name="prenom" label={t(ETLCodes.Prenom)} maxLength={50} />
          <FGWalterSelectInput
            name="codesexe"
            label={t(ETLCodes.Genre)}
            items={sexe}
            loading={sLoading}
            isFilterable={false}
            isCleareable={false}
          />
          {isFullCreation ? (
            <FGCopyTextInput
              copyOnlyDigits={true}
              name="gsm.numero"
              label={t(ETLCodes.Gsm)}
              maxLength={20}
              visible={isFullCreation}
            />
          ) : (
            <FGWalterSelectInput
              name="gsm.idtelephone"
              label={t(ETLCodes.Gsm)}
              items={personneGsms}
              loading={personneGsmsLoading}
              visible={!isFullCreation}
              itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
              itemCreateParams={`&typeTel=GSM_PERSO`}
              autoSelectIfOne
            />
          )}
          {isFullCreation ? (
            <FGCopyTextInput
              copyOnlyDigits={true}
              name="telephone.numero"
              label={t(ETLCodes.Tel)}
              maxLength={20}
              visible={isFullCreation}
            />
          ) : (
            <FGWalterSelectInput
              name="telephone.idtelephone"
              label={t(ETLCodes.Tel)}
              items={personneTelephones}
              loading={personneTelephonesLoading}
              visible={!isFullCreation}
              itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
              itemCreateParams={`&typeTel=PERSO`}
              autoSelectIfOne
            />
          )}
          {isFullCreation ? (
            <FGTextInput
              name="email.adresseEmail"
              label={t(ETLCodes.General_Email)}
              maxLength={100}
              visible={isFullCreation}
            />
          ) : (
            <FGWalterSelectInput
              name="email.idemail"
              label={t(ETLCodes.General_Email)}
              loading={personneEmailsLoading}
              items={personneEmails}
              visible={!isFullCreation}
              itemCreateUrl={`${ERoutes.personne}/${idpersonne}/signaletique/edit`}
              itemCreateParams={`&typeEmail=PERSO`}
              autoSelectIfOne
            />
          )}
          <FGWalterSelectInput
            name="iddepartment"
            label={t(ETLCodes.Departement)}
            items={departements}
            loading={deLoading}
          />
          <FGWalterSelectInput name="idposte" label={t(ETLCodes.Poste)} items={postes} loading={pLoading} />
        </FieldGroup>
        <FGEmpty />
      </FieldGroup>

      <FieldGroup columns={2} fieldsetProps={{ title: t(ETLCodes.AccesApplicatif) }}>
        <FieldGroup>
          <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
          <FGTextInput name="login" label={t(ETLCodes.LoginInput)} maxLength={20} forceCase="lower" />
          <FGTextInput name="azureEmail" label={t(ETLCodes.AzureEmail)} maxLength={100} forceCase="lower" />
          <FGListen
            field="ifapmeSide"
            onChanged={(value, formik) => {
              if (formik.touched?.ifapmeSide) formik.setFieldValue("idlevel", null);
            }}
          />
          <FGCustomInput label={t(ETLCodes.Levels)}>
            {ctx => (
              <RadioGroup
                selectedValue={getIn(ctx.formik.values, "ifapmeSide")}
                inline
                onChange={e => {
                  ctx.formik.setFieldValue("ifapmeSide", e.currentTarget.value);
                  ctx.formik.setFieldTouched("ifapmeSide");
                }}
                disabled={!ctx?.editMode}
              >
                <Radio label={t(ETLCodes.AccessLevelHope)} value={"Hope"}></Radio>
                <Radio label={t(ETLCodes.AccessLevelWalter)} value={"Walter"}></Radio>
                <Radio label={t(ETLCodes.AccessLevelBoth)} value={"Hope|Walter"}></Radio>
              </RadioGroup>
            )}
          </FGCustomInput>
          <FGCustomPanel>
            {ctx => (
              <>
                {ctx?.formik?.values?.ifapmeSide === "Hope|Walter" ? (
                  <FGWalterSelectInput label={t(ETLCodes.Profil)} name="idlevel" items={levels} loading={lloading} />
                ) : ctx?.formik?.values?.ifapmeSide === "Walter" ? (
                  <FGWalterSelectInput
                    label={t(ETLCodes.Profil)}
                    name="idlevel"
                    items={walterLevels}
                    loading={wloading}
                  />
                ) : (
                  <FGWalterSelectInput
                    label={t(ETLCodes.Profil)}
                    name="idlevel"
                    items={hopeLevels}
                    loading={hloading}
                  />
                )}
              </>
            )}
          </FGCustomPanel>
          <FGCustomPanel>
            {ctx => (
              <>
                {ctx?.formik?.values?.ifapmeSide?.includes("Walter") && (
                  <FGMultiSuggestInput
                    name="idsSocieteExterne"
                    label={t(ETLCodes.SocietesExternes)}
                    items={societesExternes}
                    loading={seLoading}
                  />
                )}
              </>
            )}
          </FGCustomPanel>

          <FGWalterCheckboxInput
            name="validationDirection"
            label={t(ETLCodes.ValidationDirection)}
            visible={ctx => ctx?.formik?.values?.ifapmeSide?.includes("Walter")}
          />
          <FGWalterCheckboxInput
            name="delegationSignature"
            label={t(ETLCodes.DelegationSignature)}
            visible={ctx =>
              ctx?.formik?.values?.ifapmeSide?.includes("Hope") && isRiAriCop(ctx?.formik?.values?.idlevel)
            }
          />
        </FieldGroup>
      </FieldGroup>
      <FieldGroup columns={2}>
        <FGWalterSelectInput
          name="idtype"
          items={types}
          loading={tloading}
          label={t(ETLCodes.Type)}
          helperText={getShowTypeWarning}
          visible={ctx => ctx?.formik?.values?.ifapmeSide?.includes("Walter")}
        />
        <FGCustomPanel>
          {ctx => (
            <>
              {ctx?.formik?.values?.idtype === 1 && (
                <FGWalterSelectInput
                  name="idconseillerPedagogique"
                  label={t(ETLCodes.Conseiller)}
                  items={conseillers}
                  loading={cloading}
                />
              )}
              {ctx?.formik?.values?.idtype === 2 && (
                <FGWalterSelectInput
                  name="iddirectionTerritoriale"
                  label={t(ETLCodes.DirectionsTerritoriales)}
                  items={directions}
                  loading={dloading}
                />
              )}
              {ctx?.formik?.values?.idtype === 3 && (
                <FGWalterSelectInput
                  name="idformateur"
                  label={t(ETLCodes.Formateur)}
                  items={formateurs}
                  loading={floading}
                />
              )}
            </>
          )}
        </FGCustomPanel>
      </FieldGroup>
      <FieldGroup>
        <FGCustomPanel>
          {ctx => (
            <>
              {ctx?.formik?.values?.ifapmeSide?.includes("Walter") && (
                <FGCustomInput>
                  {({ formik: values }) => (
                    <FieldArray
                      name="centreUsers"
                      render={() => {
                        const data = (values.values as UserDto).centreUsers;
                        return (
                          <StyledList>
                            <FieldGroup>
                              <FieldSet title={t(ETLCodes.PresenceApp)}>
                                {data?.map((d, index) => (
                                  <FGWalterCheckboxInput label={d.centre} name={`centreUsers.${index}.presenceApp`} />
                                ))}
                              </FieldSet>
                            </FieldGroup>
                          </StyledList>
                        );
                      }}
                    ></FieldArray>
                  )}
                </FGCustomInput>
              )}
            </>
          )}
        </FGCustomPanel>
      </FieldGroup>
    </SmallFormGenerator>
  );
};
