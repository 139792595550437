import { Colors, Icon, Tooltip } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import {
  ContratApi,
  ContratLieuxFormationGridDto,
  ContratLieuxFormationSearchDto,
  ETypeContrat
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { CircleColumn, CustomBulletList, EditButton, ViewButton } from "../../../../../components";
import { useApiService, useTheme, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { computeContratStatutColor } from "../../../../../utils/contratStatutColor";

const LegendHeaderContainer = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

export interface IApprenantLieuFormationListProps {}

export const ApprenantLieuFormationList: React.FunctionComponent<IApprenantLieuFormationListProps> = props => {
  const { t, tUnsafe } = useTl();
  const { theme, ifapmeSide } = useTheme();
  const isHope = React.useMemo(() => ifapmeSide === "hope", [ifapmeSide]);
  const { id } = useParams<{ id: string }>();
  const idapprenant = React.useMemo(() => +id, [id]);
  const api = useApiService(ContratApi);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { nom: "ASC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: ContratLieuxFormationSearchDto) => {
      sObj.idapprenant = idapprenant;
      return api.contratSearchLieuxFormation({ ContratLieuxFormationSearchDto: sObj });
    },
    [api, idapprenant]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const onViewClick = React.useCallback((dto: ContratLieuxFormationGridDto) => {
    window.open(`#${ERoutes.lieuFormation}/${dto.idlieuFormation}/detail/view?idsiegeSocial=${dto.idsiegeSocial}`);
  }, []);

  const onEditClick = React.useCallback((dto: ContratLieuxFormationGridDto) => {
    window.open(`#${ERoutes.lieuFormation}/${dto.idlieuFormation}/detail/edit?idsiegeSocial=${dto.idsiegeSocial}`);
  }, []);

  const hopeContratTypes = React.useMemo(
    () => [
      ETypeContrat.CC,
      ETypeContrat.CA,
      ETypeContrat.CS,
      ETypeContrat.CF,
      ETypeContrat.DM,
      ETypeContrat.JI,
      ETypeContrat.PP
    ],
    []
  );

  const walterContratTypes = React.useMemo(() => [ETypeContrat.SO, ETypeContrat.CCE, ETypeContrat.CSE], []);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ContratLieuxFormationGridDto) =>
          (isHope && hopeContratTypes.includes(row.type)) || (!isHope && walterContratTypes.includes(row.type)) ? (
            <ButtonContainer>
              <ViewButton minimal={true} onClick={() => onViewClick(row)} />
              <EditButton minimal={true} onClick={() => onEditClick(row)} />
            </ButtonContainer>
          ) : null
      },
      {
        header: () => t(ETLCodes.NomLieuFormation),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.CodeContrat),
        fieldName: "code"
      },
      {
        computed: true,
        alignment: "center",
        autoFitContent: true,
        header: () => (
          <LegendHeaderContainer>
            <span>{t(ETLCodes.Statut)}</span>
            <Tooltip
              content={
                <CustomBulletList
                  title={t(ETLCodes.Statut)}
                  items={[
                    {
                      text: t(ETLCodes.EnCours),
                      color: theme.sucessColor
                    },
                    {
                      text: t(ETLCodes.Rompu),
                      color: theme.dangerColor
                    },
                    {
                      text: t(ETLCodes.Suspendu),
                      color: theme.warningColor
                    },
                    {
                      text: t(ETLCodes.JamaisDeContrat),
                      color: Colors.BLACK
                    },
                    {
                      text: t(ETLCodes.Termine),
                      color: Colors.VIOLET4
                    },
                    {
                      text: t(ETLCodes.SansSuite),
                      hideBullet: true
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip>
          </LegendHeaderContainer>
        ),
        fieldName: "statut",
        render: (row: ContratLieuxFormationGridDto) => (
          <CircleColumn
            color={computeContratStatutColor(row.statut, theme)}
            tooltip={tUnsafe(`StatutContrat_${row.statut}`)}
          />
        )
      },
      {
        header: () => t(ETLCodes.Enseigne),
        fieldName: "enseigne"
      },
      {
        header: () => t(ETLCodes.Email),
        fieldName: "email"
      },
      {
        header: () => t(ETLCodes.Telephone),
        fieldName: "telephone"
      },
      {
        header: () => t(ETLCodes.Gsm),
        fieldName: "gsm"
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      },
      {
        header: () => t(ETLCodes.DenominationSiegeSocial),
        fieldName: "denomination"
      }
    ],
    [hopeContratTypes, isHope, onEditClick, onViewClick, t, tUnsafe, theme, walterContratTypes]
  );

  return (
    <FieldSet title={t(ETLCodes.TableResults, { count: totalCount })}>
      <DataTable
        dateFormat="dd/MM/yyyy"
        tableState={tableState}
        loading={loading}
        columns={columns}
        filterMode="OnEnter"
      ></DataTable>
    </FieldSet>
  );
};
