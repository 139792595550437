import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { EvaluationApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { EvaluationDetailPage } from "./EvaluationDetailPage";

export interface IEvaluationItemPageProps {}

export const EvaluationItemPage: React.FunctionComponent<IEvaluationItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const api = useApiService(EvaluationApi);

  const [displayName] = useApiEffect(() => api.evaluationGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.Evaluations), route: ERoutes.evaluation },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsEvaluation"
        onChange={newTabId => history.push(`${ERoutes.evaluation}/${id}/${newTabId}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.evaluation} />
        <Tab id="detail" title={t(ETLCodes.Detail)} panel={<EvaluationDetailPage editMode={state === "edit"} />} />
      </ProtectedTabs>
    </PageBase>
  );
};
