import * as React from "react";
import { ToolTipButton } from "nsitools-react";
import { IconNames } from "@blueprintjs/icons";
import { ETLCodes } from "../../locales/ETLCodes";
import { useTl } from "../../hooks";
import { ICustomButtonProps } from "./ICustomButtonProps";
import { Intent } from "@blueprintjs/core";
import { useAuth } from "../../contexts";

export interface ICutButtonProps extends ICustomButtonProps {}

export const CutButton: React.FunctionComponent<ICutButtonProps> = ({
  minimal = true,
  onClick,
  loading,
  disabled,
  intent = Intent.NONE,
  text
}) => {
  const { t } = useTl();
  const { getCurrentPermission } = useAuth();
  const hasRights = React.useMemo(() => getCurrentPermission()?.permission?.includes("RW"), [getCurrentPermission]);

  return hasRights ? (
    <ToolTipButton
      icon={IconNames.CUT}
      tooltip={text ? null : t(ETLCodes.Cut)}
      minimal={minimal}
      onClick={onClick}
      text={text ? text : minimal ? null : t(ETLCodes.Cut)}
      intent={intent}
      loading={loading}
      disabled={disabled}
    />
  ) : (
    <></>
  );
};
