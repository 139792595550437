import { Spinner, SpinnerSize, Tab } from "@blueprintjs/core";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";

import { VisiteApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { VisiteClasseLieeSwitch } from "./classeLiee/VisiteClasseLieeSwitch";
import { VisiteFormateurLieSwitch } from "./formateurLie_/VisiteFormateurLieSwitch";
import { VisiteHoraire } from "./VisiteHoraire";
import { VisiteInformationPage } from "./VisiteInformationPage";

export interface IVisiteExterieureItemPageProps {}

export const VisiteExterieureItemPage: React.FunctionComponent<IVisiteExterieureItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const visiteApi = useApiService(VisiteApi);
  const { data: displayName, isLoading: displayNameLoading } = useQuery(["visite-display-name", id], () =>
    visiteApi.visiteGetDisplayName({ id: +id })
  );
  const finalDisplayName = React.useMemo(
    () => (displayNameLoading ? <Spinner size={SpinnerSize.SMALL} /> : displayName),
    [displayName, displayNameLoading]
  );

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.VisiteExterieure), route: ERoutes.visiteExterieure },
        { text: +id <= 0 ? t(ETLCodes.New) : finalDisplayName }
      ]}
    >
      <ProtectedTabs
        id="TabsVisiteExterieure"
        onChange={newTabId => history.push(`${ERoutes.visiteExterieure}/${id}/${newTabId}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.visiteExterieure}></BackButton>
        <Tab
          id="information"
          title={t(ETLCodes.Informations)}
          panel={<VisiteInformationPage idVisite={+id} editMode={state === "edit"} />}
        />
        <Tab id="horaire" title={t(ETLCodes.Horaire)} panel={<VisiteHoraire idVisite={+id} />} disabled={+id <= 0} />
        <Tab
          id="formateurLie"
          title={t(ETLCodes.FormateurLie)}
          panel={<VisiteFormateurLieSwitch idVisite={+id} />}
          disabled={+id <= 0}
        />
        <Tab id="classeLiee" title={t(ETLCodes.ClasseLiee)} panel={<VisiteClasseLieeSwitch />} disabled={+id <= 0} />
      </ProtectedTabs>
    </PageBase>
  );
};
