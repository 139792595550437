/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbVisiteDto
 */
export interface FcbVisiteDto {
    /**
     * 
     * @type {number}
     * @memberof FcbVisiteDto
     */
    idvisite?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbVisiteDto
     */
    idcentre?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbVisiteDto
     */
    dateDemande?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbVisiteDto
     */
    datevisiteDebut?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbVisiteDto
     */
    datevisiteFin?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbVisiteDto
     */
    duree?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbVisiteDto
     */
    accord?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbVisiteDto
     */
    contact?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbVisiteDto
     */
    coordonnee?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbVisiteDto
     */
    lieu?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbVisiteDto
     */
    raison?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbVisiteDto
     */
    heure1?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbVisiteDto
     */
    heure2?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbVisiteDto
     */
    heure3?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbVisiteDto
     */
    heure4?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbVisiteDto
     */
    heure5?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbVisiteDto
     */
    heure6?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbVisiteDto
     */
    heure7?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbVisiteDto
     */
    heure8?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbVisiteDto
     */
    heure9?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbVisiteDto
     */
    heure10?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbVisiteDto
     */
    heure11?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbVisiteDto
     */
    heure12?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbVisiteDto
     */
    heure13?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FcbVisiteDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbVisiteDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbVisiteDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbVisiteDto
     */
    modificationDate?: Date | null;
}

/**
 * Check if a given object implements the FcbVisiteDto interface.
 */
export function instanceOfFcbVisiteDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbVisiteDtoFromJSON(json: any): FcbVisiteDto {
    return FcbVisiteDtoFromJSONTyped(json, false);
}

export function FcbVisiteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbVisiteDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idvisite': !exists(json, 'idvisite') ? undefined : json['idvisite'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'dateDemande': !exists(json, 'dateDemande') ? undefined : (json['dateDemande'] === null ? null : new Date(json['dateDemande'])),
        'datevisiteDebut': !exists(json, 'datevisiteDebut') ? undefined : (json['datevisiteDebut'] === null ? null : new Date(json['datevisiteDebut'])),
        'datevisiteFin': !exists(json, 'datevisiteFin') ? undefined : (json['datevisiteFin'] === null ? null : new Date(json['datevisiteFin'])),
        'duree': !exists(json, 'duree') ? undefined : json['duree'],
        'accord': !exists(json, 'accord') ? undefined : json['accord'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'coordonnee': !exists(json, 'coordonnee') ? undefined : json['coordonnee'],
        'lieu': !exists(json, 'lieu') ? undefined : json['lieu'],
        'raison': !exists(json, 'raison') ? undefined : json['raison'],
        'heure1': !exists(json, 'heure1') ? undefined : json['heure1'],
        'heure2': !exists(json, 'heure2') ? undefined : json['heure2'],
        'heure3': !exists(json, 'heure3') ? undefined : json['heure3'],
        'heure4': !exists(json, 'heure4') ? undefined : json['heure4'],
        'heure5': !exists(json, 'heure5') ? undefined : json['heure5'],
        'heure6': !exists(json, 'heure6') ? undefined : json['heure6'],
        'heure7': !exists(json, 'heure7') ? undefined : json['heure7'],
        'heure8': !exists(json, 'heure8') ? undefined : json['heure8'],
        'heure9': !exists(json, 'heure9') ? undefined : json['heure9'],
        'heure10': !exists(json, 'heure10') ? undefined : json['heure10'],
        'heure11': !exists(json, 'heure11') ? undefined : json['heure11'],
        'heure12': !exists(json, 'heure12') ? undefined : json['heure12'],
        'heure13': !exists(json, 'heure13') ? undefined : json['heure13'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
    };
}

export function FcbVisiteDtoToJSON(value?: FcbVisiteDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idvisite': value.idvisite,
        'idcentre': value.idcentre,
        'dateDemande': value.dateDemande === undefined ? undefined : (value.dateDemande === null ? null : value.dateDemande.toISOString()),
        'datevisiteDebut': value.datevisiteDebut === undefined ? undefined : (value.datevisiteDebut === null ? null : value.datevisiteDebut.toISOString()),
        'datevisiteFin': value.datevisiteFin === undefined ? undefined : (value.datevisiteFin === null ? null : value.datevisiteFin.toISOString()),
        'duree': value.duree,
        'accord': value.accord,
        'contact': value.contact,
        'coordonnee': value.coordonnee,
        'lieu': value.lieu,
        'raison': value.raison,
        'heure1': value.heure1,
        'heure2': value.heure2,
        'heure3': value.heure3,
        'heure4': value.heure4,
        'heure5': value.heure5,
        'heure6': value.heure6,
        'heure7': value.heure7,
        'heure8': value.heure8,
        'heure9': value.heure9,
        'heure10': value.heure10,
        'heure11': value.heure11,
        'heure12': value.heure12,
        'heure13': value.heure13,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
    };
}

