import { Button, Colors, Icon, Intent, Menu, MenuItem, Popover, Tooltip } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";

import { ContratRuptureDialog, ContratSuspensionDetailDialog, TuteurLieuFormationSelectDialog } from "../../..";
import {
  ContratApi,
  ContratGridDto,
  ContratSearchDto,
  ContratSuspensionDto,
  ContratSuspensionDtoFromJSON,
  EStatutContrat,
  ETypeContrat,
  RapportHopeApi,
  TuteurApi,
  TuteurLieuFormationSearchDtoFromJSON
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { AddButton, CircleColumn, CustomBulletList, EditButton, ViewButton } from "../../../../../components";
import { useApiService, useTabMessage, useTheme, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { computeContratStatutColor } from "../../../../../utils/contratStatutColor";
import { Guid } from "../../../../../utils/guid";

const PopoverContent = styled.div`
  padding: 0.5rem;
`;

const LegendHeaderContainer = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

export interface ITuteurContratsProps {}

export const TuteurContrats: React.FunctionComponent<ITuteurContratsProps> = props => {
  const { t, tUnsafe } = useTl();
  const { theme } = useTheme();
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const idtuteur = React.useMemo(() => +id, [id]);
  const [currentSuspension, setCurrentSuspension] = React.useState<ContratSuspensionDto>(null);
  const [currentContratId, setCurrentContratId] = React.useState(0);
  const api = useApiService(ContratApi);
  const tuteurApi = useApiService(TuteurApi);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: ContratSearchDto) => {
      sObj.idtuteur = idtuteur;
      return api.contratBaseSearch({ ContratSearchDto: sObj });
    },
    [api, idtuteur]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const onAddSuspensionClick = React.useCallback(idcontrat => {
    setCurrentSuspension(ContratSuspensionDtoFromJSON({ idcontrat, idcontratSuspension: 0 }));
  }, []);

  const onRuptureClick = React.useCallback(idcontrat => {
    setCurrentContratId(idcontrat);
  }, []);

  const fetchLfTuteur = React.useCallback(
    async () =>
      await tuteurApi.tuteurSearchTuteurLieuFormation({
        TuteurLieuFormationSearchDto: TuteurLieuFormationSearchDtoFromJSON({ idtuteur })
      }),
    [idtuteur, tuteurApi]
  );

  const { data: lieuxFormation } = useQuery(["lieux-fromation-tuteur", idtuteur], fetchLfTuteur);

  const [contratType, setContratType] = React.useState(null);

  const onContratClick = React.useCallback(
    (type: string) => {
      let idlieuFormation = 0;
      if (lieuxFormation.results.length === 1) idlieuFormation = lieuxFormation.results[0].idlieuFormation;

      if (lieuxFormation.results.length > 1) setContratType(type);
      else push(`${ERoutes.contrat}/-1/detail/${type}/edit?idlieuFormation=${idlieuFormation}&idtuteur=${idtuteur}`);
    },
    [idtuteur, lieuxFormation?.results, push]
  );

  const fetchIsSpp = React.useCallback(
    async () =>
      await tuteurApi.tuteurIsSpp({
        idtuteur
      }),
    [idtuteur, tuteurApi]
  );

  const { data: isSpp } = useQuery(["tuteur-is-spp", idtuteur], fetchIsSpp);

  const buttons = React.useMemo(
    () => (
      <Popover
        position="left"
        content={
          <PopoverContent>
            <Menu>
              {!isSpp?.value && (
                <MenuItem
                  text={t(ETLCodes.ContratAlternanceCC)}
                  intent={Intent.PRIMARY}
                  onClick={() => onContratClick("CC")}
                />
              )}
              {!isSpp?.value && (
                <MenuItem
                  text={t(ETLCodes.ConventionStageCS)}
                  intent={Intent.PRIMARY}
                  onClick={() => onContratClick("CS")}
                />
              )}
              <MenuItem
                text={t(ETLCodes.StagePratiqueProPP)}
                intent={Intent.PRIMARY}
                onClick={() => onContratClick("PP")}
              />
              {!isSpp?.value && (
                <MenuItem
                  text={t(ETLCodes.ConventionFALTCF)}
                  intent={Intent.PRIMARY}
                  onClick={() => onContratClick("CF")}
                />
              )}
              <MenuItem
                text={t(ETLCodes.StageDecouverteMetierDM)}
                intent={Intent.PRIMARY}
                onClick={() => onContratClick("DM")}
              />
            </Menu>
          </PopoverContent>
        }
      >
        <AddButton text={t(ETLCodes.AddContrat)} intent={Intent.PRIMARY} />
      </Popover>
    ),
    [isSpp?.value, onContratClick, t]
  );

  const identifier = React.useMemo(() => Guid.newGuid(), []);
  const { openTabThenSendMessage } = useTabMessage(identifier);

  const rapportApi = useApiService(RapportHopeApi);
  const fetchIdsrapportContrat = React.useCallback(() => {
    return rapportApi.rapportHopeGetIdsrapportContrat();
  }, [rapportApi]);
  const { data: idsrapportContrat } = useQuery("idsrapportContrat", fetchIdsrapportContrat);
  const printContrat = React.useCallback(
    (idcontrat: number, typeContrat: ETypeContrat) => {
      const idrapportContrat = idsrapportContrat?.[typeContrat];
      openTabThenSendMessage(`/#${ERoutes.SSRSReports}/${idrapportContrat}`, [idcontrat]);
    },
    [idsrapportContrat, openTabThenSendMessage]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ContratGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => window.open(`#${ERoutes.contrat}/${row.idcontrat}/detail/${row.type}/view`)}
            />
            {[
              ETypeContrat.CC,
              ETypeContrat.CA,
              ETypeContrat.CS,
              ETypeContrat.CF,
              ETypeContrat.DM,
              ETypeContrat.JI,
              ETypeContrat.PP
            ].includes(row.type) && (
              <EditButton
                minimal={true}
                onClick={() => window.open(`#${ERoutes.contrat}/${row.idcontrat}/detail/${row.type}/edit`)}
              />
            )}
            <Popover
              position="bottom"
              content={
                <PopoverContent>
                  <Menu>
                    {[
                      ETypeContrat.CC,
                      ETypeContrat.CA,
                      ETypeContrat.CS,
                      ETypeContrat.CF,
                      ETypeContrat.DM,
                      ETypeContrat.JI,
                      ETypeContrat.PP
                    ].includes(row.type) && (
                      <>
                        <MenuItem
                          icon={IconNames.STOP}
                          text={t(ETLCodes.Rupture)}
                          intent={Intent.PRIMARY}
                          onClick={() => onRuptureClick(row.idcontrat)}
                          disabled={[EStatutContrat.Rompu, EStatutContrat.SansSuite].includes(row.statut)}
                        />
                        <MenuItem
                          icon={IconNames.PAUSE}
                          text={t(ETLCodes.Suspension)}
                          intent={Intent.PRIMARY}
                          onClick={() => onAddSuspensionClick(row.idcontrat)}
                          disabled={[EStatutContrat.Rompu, EStatutContrat.SansSuite].includes(row.statut)}
                        />
                        <MenuItem
                          icon={IconNames.DOCUMENT}
                          text={t(ETLCodes.Avenant)}
                          intent={Intent.PRIMARY}
                          onClick={() => {}}
                          disabled
                        />
                      </>
                    )}
                    {!!idsrapportContrat?.[row.type] && (
                      <MenuItem
                        icon={IconNames.PRINT}
                        text={t(ETLCodes.Impression)}
                        intent={Intent.PRIMARY}
                        onClick={() => printContrat(row.idcontrat, row.type)}
                      />
                    )}
                  </Menu>
                </PopoverContent>
              }
            >
              <Button icon={IconNames.CHEVRON_DOWN} minimal={true}></Button>
            </Popover>
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Apprenant),
        fieldName: "apprenant"
      },
      {
        computed: true,
        alignment: "center",
        autoFitContent: true,
        header: () => (
          <LegendHeaderContainer>
            <span>{t(ETLCodes.Statut)}</span>
            <Tooltip
              content={
                <CustomBulletList
                  title={t(ETLCodes.Statut)}
                  items={[
                    {
                      text: t(ETLCodes.EnCours),
                      color: theme.sucessColor
                    },
                    {
                      text: t(ETLCodes.Rompu),
                      color: theme.dangerColor
                    },
                    {
                      text: t(ETLCodes.Suspendu),
                      color: theme.warningColor
                    },
                    {
                      text: t(ETLCodes.JamaisDeContrat),
                      color: Colors.BLACK
                    },
                    {
                      text: t(ETLCodes.Termine),
                      color: Colors.VIOLET4
                    },
                    {
                      text: t(ETLCodes.SansSuite),
                      hideBullet: true
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip>
          </LegendHeaderContainer>
        ),
        fieldName: "statut",
        render: (row: ContratGridDto) => (
          <CircleColumn
            color={computeContratStatutColor(row.statut, theme)}
            tooltip={tUnsafe(`StatutContrat_${row.statut}`)}
          />
        )
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code"
      },
      {
        header: () => t(ETLCodes.Service),
        fieldName: "serviceNom"
      },
      {
        header: () => t(ETLCodes.NomMetier),
        fieldName: "nomMetier"
      },
      {
        header: () => t(ETLCodes.DateDebut),
        fieldName: "dateDebut"
      },
      {
        header: () => t(ETLCodes.DateFin),
        fieldName: "dateFin"
      },
      {
        header: () => t(ETLCodes.DateRupture),
        fieldName: "rompuLe"
      }
    ],
    [idsrapportContrat, onAddSuspensionClick, onRuptureClick, printContrat, t, tUnsafe, theme]
  );

  const onClose = React.useCallback(
    (toSave: ContratSuspensionDto) => {
      if (!!toSave) {
        search();
      }
      setCurrentSuspension(null);
    },
    [search]
  );

  return (
    <>
      <FieldSet title={t(ETLCodes.TableResults, { count: totalCount })} rightElement={buttons}>
        <DataTable
          dateFormat="dd/MM/yyyy"
          tableState={tableState}
          loading={loading}
          columns={columns}
          filterMode="OnEnter"
        ></DataTable>
      </FieldSet>
      {!!currentSuspension && (
        <ContratSuspensionDetailDialog
          dialogOpen={!!currentSuspension}
          currentSuspension={currentSuspension}
          onClose={onClose}
        />
      )}
      {!!currentContratId && (
        <ContratRuptureDialog
          dialogOpen={!!currentContratId && currentContratId > 0}
          currentContratId={currentContratId}
          onClose={(refresh: boolean) => {
            setCurrentContratId(0);
            if (refresh) search();
          }}
        />
      )}
      {!!lieuxFormation && lieuxFormation.results.length > 1 && (
        <TuteurLieuFormationSelectDialog
          dialogOpen={!!lieuxFormation && lieuxFormation.results.length > 1 && !!contratType}
          lieuxFormation={lieuxFormation.results}
          onClose={(idlieuFormation?: number) => {
            if (idlieuFormation) {
              push(
                `${ERoutes.contrat}/-1/detail/${contratType}/edit?idlieuFormation=${idlieuFormation}&idtuteur=${idtuteur}`
              );
            }
            setContratType(null);
          }}
        />
      )}
    </>
  );
};
