import { Button, ButtonGroup, Icon } from "@blueprintjs/core";
import {
  DataTable,
  FieldSet,
  IDataTableColumn,
  InlineSearchCriteriaPanel,
  useGridState,
  useSearchApi
} from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { ValidationMasseAddModal } from "..";
import { AgrementMasseValidationApi, FcbRechercheapprenantValidationcemasseDto } from "../../../../api";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface IValidationMasseTableProps {
  linkedIds?: number[];
  onLinkedIdsChanged?: (linkedIds: number[]) => void;
}

const Container = styled.div``;

export const ValidationMasseTable: React.FunctionComponent<IValidationMasseTableProps> = ({
  linkedIds = [],
  onLinkedIdsChanged
}) => {
  const { t } = useTl();
  const tableState = useGridState({ serverMode: false, sortKeys: { nom: "ASC" } });

  const api = useApiService(AgrementMasseValidationApi);
  const searchFn = React.useCallback(
    sObj =>
      api.agrementMasseValidationBaseSearch({
        RechercheApprenantValidationCeMasseSearch: { ...sObj, selectedIds: linkedIds }
      }),
    [api, linkedIds]
  );

  const { search, loading } = useSearchApi({
    searchFunction: searchFn,
    tableState,
    initialSearch: true
  });

  const { data } = tableState;

  const registeredIds = React.useMemo<number[]>(() => {
    return data?.filter(v => !linkedIds.includes(v.idformation)).map(v => v.idformation) ?? [];
  }, [data, linkedIds]);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        fieldName: "_action",
        autoFitContent: true,
        computed: true,

        alignment: "center",
        render: (item: FcbRechercheapprenantValidationcemasseDto) => (
          <Icon
            icon={linkedIds.includes(item.idformation) ? "tick-circle" : "circle"}
            style={{ color: "#6e6e6e" }}
          ></Icon>
        )
      },
      {
        fieldName: "nom",
        header: () => t(ETLCodes.Nom)
      },
      {
        fieldName: "prenom",
        header: () => t(ETLCodes.Prenom)
      },
      {
        fieldName: "dateNaissance",
        header: () => t(ETLCodes.DateNaissance)
      },
      {
        fieldName: "masculin",
        header: () => t(ETLCodes.Formation)
      },
      {
        fieldName: "degre",
        header: () => t(ETLCodes.Degre)
      },
      {
        fieldName: "codeclasse",
        header: () => t(ETLCodes.CodeClasse)
      },
      {
        fieldName: "classe",
        header: () => t(ETLCodes.NomClasse)
      },
      {
        fieldName: "agrement",
        header: () => t(ETLCodes.ValidationCE)
      }
    ],
    [linkedIds, t]
  );

  const [showModal, setShowModal] = React.useState<boolean>(false);
  const onAddFormation = React.useCallback(() => {
    setShowModal(true);
  }, []);

  const onClose = React.useCallback(
    (saved: number[]) => {
      if (saved) {
        onLinkedIdsChanged(saved);
        // Wait for the update to get dispatched
        setTimeout(() => {
          search();
        }, 0);
      }
      setShowModal(false);
    },
    [search, onLinkedIdsChanged]
  );

  const addButton = React.useMemo<React.ReactElement>(() => {
    return (
      <ButtonGroup>
        <Button
          intent="primary"
          icon="plus"
          text={t(ETLCodes.SelectionApprenants)}
          onClick={onAddFormation}
          title={t(ETLCodes.SelectionApprenants)}
        />
      </ButtonGroup>
    );
  }, [onAddFormation, t]);

  const getCriterias = React.useCallback(
    () => api.agrementMasseValidationGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  return (
    <Container>
      <FieldSet title={t(ETLCodes.General_TableCriterias)}>
        <InlineSearchCriteriaPanel
          tlDataPrefix="ValidationMasse"
          searchFunc={getCriterias}
          onSearch={search}
          triggerInitialSearch={false}
        />
      </FieldSet>
      <FieldSet title={t(ETLCodes.Apprenants)} rightElement={addButton}>
        <DataTable tableState={tableState} columns={columns} loading={loading} />
      </FieldSet>
      {!loading ? (
        <ValidationMasseAddModal
          isOpen={showModal}
          onClose={onClose}
          registeredIds={registeredIds}
          alreadySelected={linkedIds}
        ></ValidationMasseAddModal>
      ) : null}
    </Container>
  );
};
