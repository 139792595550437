/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SuiviSiegeLieuGridDto
 */
export interface SuiviSiegeLieuGridDto {
    /**
     * 
     * @type {number}
     * @memberof SuiviSiegeLieuGridDto
     */
    idsuivi?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviSiegeLieuGridDto
     */
    idservice?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviSiegeLieuGridDto
     */
    service?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviSiegeLieuGridDto
     */
    realisateurSuivi?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviSiegeLieuGridDto
     */
    idencodeur?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviSiegeLieuGridDto
     */
    encodeur?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviSiegeLieuGridDto
     */
    idrealisateurSuivi?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof SuiviSiegeLieuGridDto
     */
    dateSuivi?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviSiegeLieuGridDto
     */
    idsiegeSocial?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviSiegeLieuGridDto
     */
    siegeSocial?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviSiegeLieuGridDto
     */
    idlieuFormation?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviSiegeLieuGridDto
     */
    lieuFormation?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviSiegeLieuGridDto
     */
    idtypeSuivi?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviSiegeLieuGridDto
     */
    typeSuivi?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SuiviSiegeLieuGridDto
     */
    hiddenContent?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof SuiviSiegeLieuGridDto
     */
    actionDateEcheance?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof SuiviSiegeLieuGridDto
     */
    actionDateRealisation?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviSiegeLieuGridDto
     */
    realisateurAction?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviSiegeLieuGridDto
     */
    idsuserMasquage?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SuiviSiegeLieuGridDto
     */
    masquee?: boolean;
}

/**
 * Check if a given object implements the SuiviSiegeLieuGridDto interface.
 */
export function instanceOfSuiviSiegeLieuGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SuiviSiegeLieuGridDtoFromJSON(json: any): SuiviSiegeLieuGridDto {
    return SuiviSiegeLieuGridDtoFromJSONTyped(json, false);
}

export function SuiviSiegeLieuGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuiviSiegeLieuGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsuivi': !exists(json, 'idsuivi') ? undefined : json['idsuivi'],
        'idservice': !exists(json, 'idservice') ? undefined : json['idservice'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'realisateurSuivi': !exists(json, 'realisateurSuivi') ? undefined : json['realisateurSuivi'],
        'idencodeur': !exists(json, 'idencodeur') ? undefined : json['idencodeur'],
        'encodeur': !exists(json, 'encodeur') ? undefined : json['encodeur'],
        'idrealisateurSuivi': !exists(json, 'idrealisateurSuivi') ? undefined : json['idrealisateurSuivi'],
        'dateSuivi': !exists(json, 'dateSuivi') ? undefined : (json['dateSuivi'] === null ? null : new Date(json['dateSuivi'])),
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'siegeSocial': !exists(json, 'siegeSocial') ? undefined : json['siegeSocial'],
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'lieuFormation': !exists(json, 'lieuFormation') ? undefined : json['lieuFormation'],
        'idtypeSuivi': !exists(json, 'idtypeSuivi') ? undefined : json['idtypeSuivi'],
        'typeSuivi': !exists(json, 'typeSuivi') ? undefined : json['typeSuivi'],
        'hiddenContent': !exists(json, 'hiddenContent') ? undefined : json['hiddenContent'],
        'actionDateEcheance': !exists(json, 'actionDateEcheance') ? undefined : (json['actionDateEcheance'] === null ? null : new Date(json['actionDateEcheance'])),
        'actionDateRealisation': !exists(json, 'actionDateRealisation') ? undefined : (json['actionDateRealisation'] === null ? null : new Date(json['actionDateRealisation'])),
        'realisateurAction': !exists(json, 'realisateurAction') ? undefined : json['realisateurAction'],
        'idsuserMasquage': !exists(json, 'idsuserMasquage') ? undefined : json['idsuserMasquage'],
        'masquee': !exists(json, 'masquee') ? undefined : json['masquee'],
    };
}

export function SuiviSiegeLieuGridDtoToJSON(value?: SuiviSiegeLieuGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsuivi': value.idsuivi,
        'idservice': value.idservice,
        'service': value.service,
        'realisateurSuivi': value.realisateurSuivi,
        'idencodeur': value.idencodeur,
        'encodeur': value.encodeur,
        'idrealisateurSuivi': value.idrealisateurSuivi,
        'dateSuivi': value.dateSuivi === undefined ? undefined : (value.dateSuivi === null ? null : value.dateSuivi.toISOString()),
        'idsiegeSocial': value.idsiegeSocial,
        'siegeSocial': value.siegeSocial,
        'idlieuFormation': value.idlieuFormation,
        'lieuFormation': value.lieuFormation,
        'idtypeSuivi': value.idtypeSuivi,
        'typeSuivi': value.typeSuivi,
        'hiddenContent': value.hiddenContent,
        'actionDateEcheance': value.actionDateEcheance === undefined ? undefined : (value.actionDateEcheance === null ? null : value.actionDateEcheance.toISOString()),
        'actionDateRealisation': value.actionDateRealisation === undefined ? undefined : (value.actionDateRealisation === null ? null : value.actionDateRealisation.toISOString()),
        'realisateurAction': value.realisateurAction,
        'idsuserMasquage': value.idsuserMasquage,
        'masquee': value.masquee,
    };
}

