/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbUserGridDto
 */
export interface FcbUserGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbUserGridDto
     */
    iduser?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbUserGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbUserGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbUserGridDto
     */
    login?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbUserGridDto
     */
    connecte?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbUserGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbUserGridDto interface.
 */
export function instanceOfFcbUserGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbUserGridDtoFromJSON(json: any): FcbUserGridDto {
    return FcbUserGridDtoFromJSONTyped(json, false);
}

export function FcbUserGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbUserGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iduser': !exists(json, 'iduser') ? undefined : json['iduser'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'login': !exists(json, 'login') ? undefined : json['login'],
        'connecte': !exists(json, 'connecte') ? undefined : json['connecte'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbUserGridDtoToJSON(value?: FcbUserGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iduser': value.iduser,
        'nom': value.nom,
        'prenom': value.prenom,
        'login': value.login,
        'connecte': value.connecte,
        'actif': value.actif,
    };
}

