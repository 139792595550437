import { Checkbox } from "@blueprintjs/core";
import { ButtonContainer, IDataTableColumn } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";

import {
  EBooleanSearchTypes,
  FcbPersonnelTutelleGridDto,
  PersonnelTutelleApi,
  PersonnelTutelleSearch
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, EmailButton, SearchTablePage, SmsButton, ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { useAbortableApiServiceFactory, useApiService } from "../../../../../hooks/useApiService";
import { ETLCodes } from "../../../../../locales";

interface IDelegueTutelleListPageProps {}

export const DelegueTutelleListPage: React.FunctionComponent<IDelegueTutelleListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(PersonnelTutelleApi);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbPersonnelTutelleGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.delegueTutelle}/${row.iddelegueTutelle}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.delegueTutelle}/${row.iddelegueTutelle}/detail/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.PersonnelTutelleListPageCode),
        fieldName: "code",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.PersonnelTutelleListPageNom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.PersonnelTutelleListPagePrenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.PersonnelTutelleListIndependant),
        fieldName: "independant",
        autoFitContent: true,
        alignment: "center",
        render: (row: FcbPersonnelTutelleGridDto) => <Checkbox checked={row.independant} disabled />
      },
      {
        header: () => t(ETLCodes.PersonnelTutelleListPageGsm),
        fieldName: "gsm",
        render: (value: FcbPersonnelTutelleGridDto) => <SmsButton phone={value.gsm} />
      },

      {
        header: () => t(ETLCodes.PersonnelTutelleListEmail),
        fieldName: "email",
        autoFitContent: true,
        alignment: "center",
        render: (row: FcbPersonnelTutelleGridDto) => <EmailButton minimal={true} email={row.email} />
      }
    ],
    [history, t]
  );

  const addItemFunction = React.useCallback(() => history.push(`${ERoutes.delegueTutelle}/0/detail/edit`), [history]);

  const getCriteriasFn = React.useCallback(() => api.personnelTutelleGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const apiFactory = useAbortableApiServiceFactory(PersonnelTutelleApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: PersonnelTutelleSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.personnelTutelleBaseSearch({ PersonnelTutelleSearch: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      columns={columns}
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      addFunc={addItemFunction}
      breadCrumbs={[{ text: t(ETLCodes.Referents), route: ERoutes.delegueTutelle }]}
      sortKeys={{ nom: "ASC" }}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
    />
  );
};
