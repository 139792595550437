import * as React from "react";
import { useLocation, matchPath } from "react-router";
import { ERoutes } from "../../../../../AppRouter";
import { FormateurSignaletiqueDoublons } from "./FormateurSignaletiqueDoublons";
import { FormateurSignaletique } from "..";
export interface IFormateurSignaletiqueSwitchProps {}

export const FormateurSignaletiqueSwitch: React.FunctionComponent<IFormateurSignaletiqueSwitchProps> = () => {
  const [sObj, setSObj] = React.useState({});
  const { pathname } = useLocation();
  if (
    matchPath(pathname, {
      path: `${ERoutes.formateur}/0/:tab/doublon`
    })
  ) {
    return <FormateurSignaletiqueDoublons setBaseData={setSObj} />;
  } else {
    return <FormateurSignaletique baseData={sObj} />;
  }
};
