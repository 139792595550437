/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbPaysGridDto
 */
export interface FcbPaysGridDto {
    /**
     * 
     * @type {string}
     * @memberof FcbPaysGridDto
     */
    codePays?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPaysGridDto
     */
    pays?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPaysGridDto
     */
    masculin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPaysGridDto
     */
    feminin?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbPaysGridDto
     */
    europe?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FcbPaysGridDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbPaysGridDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbPaysGridDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbPaysGridDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbPaysGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbPaysGridDto interface.
 */
export function instanceOfFcbPaysGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbPaysGridDtoFromJSON(json: any): FcbPaysGridDto {
    return FcbPaysGridDtoFromJSONTyped(json, false);
}

export function FcbPaysGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbPaysGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'codePays': !exists(json, 'codePays') ? undefined : json['codePays'],
        'pays': !exists(json, 'pays') ? undefined : json['pays'],
        'masculin': !exists(json, 'masculin') ? undefined : json['masculin'],
        'feminin': !exists(json, 'feminin') ? undefined : json['feminin'],
        'europe': !exists(json, 'europe') ? undefined : json['europe'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbPaysGridDtoToJSON(value?: FcbPaysGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'codePays': value.codePays,
        'pays': value.pays,
        'masculin': value.masculin,
        'feminin': value.feminin,
        'europe': value.europe,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'actif': value.actif,
    };
}

