/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RapportHopeAccesPermissionDto } from './RapportHopeAccesPermissionDto';
import {
    RapportHopeAccesPermissionDtoFromJSON,
    RapportHopeAccesPermissionDtoFromJSONTyped,
    RapportHopeAccesPermissionDtoToJSON,
} from './RapportHopeAccesPermissionDto';
import type { RapportHopeModulePermissionDto } from './RapportHopeModulePermissionDto';
import {
    RapportHopeModulePermissionDtoFromJSON,
    RapportHopeModulePermissionDtoFromJSONTyped,
    RapportHopeModulePermissionDtoToJSON,
} from './RapportHopeModulePermissionDto';
import type { RapportHopeServicePermissionDto } from './RapportHopeServicePermissionDto';
import {
    RapportHopeServicePermissionDtoFromJSON,
    RapportHopeServicePermissionDtoFromJSONTyped,
    RapportHopeServicePermissionDtoToJSON,
} from './RapportHopeServicePermissionDto';

/**
 * 
 * @export
 * @interface RapportHopePermissionsDto
 */
export interface RapportHopePermissionsDto {
    /**
     * 
     * @type {Array<RapportHopeServicePermissionDto>}
     * @memberof RapportHopePermissionsDto
     */
    services?: Array<RapportHopeServicePermissionDto> | null;
    /**
     * 
     * @type {Array<RapportHopeAccesPermissionDto>}
     * @memberof RapportHopePermissionsDto
     */
    acces?: Array<RapportHopeAccesPermissionDto> | null;
    /**
     * 
     * @type {Array<RapportHopeModulePermissionDto>}
     * @memberof RapportHopePermissionsDto
     */
    modules?: Array<RapportHopeModulePermissionDto> | null;
}

/**
 * Check if a given object implements the RapportHopePermissionsDto interface.
 */
export function instanceOfRapportHopePermissionsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RapportHopePermissionsDtoFromJSON(json: any): RapportHopePermissionsDto {
    return RapportHopePermissionsDtoFromJSONTyped(json, false);
}

export function RapportHopePermissionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RapportHopePermissionsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'services': !exists(json, 'services') ? undefined : (json['services'] === null ? null : (json['services'] as Array<any>).map(RapportHopeServicePermissionDtoFromJSON)),
        'acces': !exists(json, 'acces') ? undefined : (json['acces'] === null ? null : (json['acces'] as Array<any>).map(RapportHopeAccesPermissionDtoFromJSON)),
        'modules': !exists(json, 'modules') ? undefined : (json['modules'] === null ? null : (json['modules'] as Array<any>).map(RapportHopeModulePermissionDtoFromJSON)),
    };
}

export function RapportHopePermissionsDtoToJSON(value?: RapportHopePermissionsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'services': value.services === undefined ? undefined : (value.services === null ? null : (value.services as Array<any>).map(RapportHopeServicePermissionDtoToJSON)),
        'acces': value.acces === undefined ? undefined : (value.acces === null ? null : (value.acces as Array<any>).map(RapportHopeAccesPermissionDtoToJSON)),
        'modules': value.modules === undefined ? undefined : (value.modules === null ? null : (value.modules as Array<any>).map(RapportHopeModulePermissionDtoToJSON)),
    };
}

