/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbMatiereReferentielDto
 */
export interface FcbMatiereReferentielDto {
    /**
     * 
     * @type {string}
     * @memberof FcbMatiereReferentielDto
     */
    idmatiere?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielDto
     */
    idreferentiel?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbMatiereReferentielDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielDto
     */
    nbheures?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielDto
     */
    travail?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielDto
     */
    pcReussite?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielDto
     */
    examen?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbMatiereReferentielDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielDto
     */
    pcreussite1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbMatiereReferentielDto
     */
    creationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbMatiereReferentielDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbMatiereReferentielDto
     */
    idmatiereReferentielGuid?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielDto
     */
    categorie?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielDto
     */
    idprojetFinancement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielDto
     */
    idmatiereReferentiel?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FcbMatiereReferentielDto
     */
    isEditable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielDto
     */
    niveau?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbMatiereReferentielDto
     */
    uaa?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbMatiereReferentielDto
     */
    isVisibleBulletin?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbMatiereReferentielDto
     */
    sommative?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FcbMatiereReferentielDto
     */
    idmatiereParent?: number | null;
}

/**
 * Check if a given object implements the FcbMatiereReferentielDto interface.
 */
export function instanceOfFcbMatiereReferentielDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbMatiereReferentielDtoFromJSON(json: any): FcbMatiereReferentielDto {
    return FcbMatiereReferentielDtoFromJSONTyped(json, false);
}

export function FcbMatiereReferentielDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbMatiereReferentielDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idmatiere': !exists(json, 'idmatiere') ? undefined : json['idmatiere'],
        'idreferentiel': !exists(json, 'idreferentiel') ? undefined : json['idreferentiel'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'nbheures': !exists(json, 'nbheures') ? undefined : json['nbheures'],
        'travail': !exists(json, 'travail') ? undefined : json['travail'],
        'pcReussite': !exists(json, 'pcReussite') ? undefined : json['pcReussite'],
        'examen': !exists(json, 'examen') ? undefined : json['examen'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'pcreussite1': !exists(json, 'pcreussite1') ? undefined : json['pcreussite1'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'idmatiereReferentielGuid': !exists(json, 'idmatiereReferentielGuid') ? undefined : json['idmatiereReferentielGuid'],
        'categorie': !exists(json, 'categorie') ? undefined : json['categorie'],
        'idprojetFinancement': !exists(json, 'idprojetFinancement') ? undefined : json['idprojetFinancement'],
        'idmatiereReferentiel': !exists(json, 'idmatiereReferentiel') ? undefined : json['idmatiereReferentiel'],
        'isEditable': !exists(json, 'isEditable') ? undefined : json['isEditable'],
        'niveau': !exists(json, 'niveau') ? undefined : json['niveau'],
        'uaa': !exists(json, 'uaa') ? undefined : json['uaa'],
        'isVisibleBulletin': !exists(json, 'isVisibleBulletin') ? undefined : json['isVisibleBulletin'],
        'sommative': !exists(json, 'sommative') ? undefined : json['sommative'],
        'idmatiereParent': !exists(json, 'idmatiereParent') ? undefined : json['idmatiereParent'],
    };
}

export function FcbMatiereReferentielDtoToJSON(value?: FcbMatiereReferentielDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idmatiere': value.idmatiere,
        'idreferentiel': value.idreferentiel,
        'description': value.description,
        'nbheures': value.nbheures,
        'travail': value.travail,
        'pcReussite': value.pcReussite,
        'examen': value.examen,
        'remarque': value.remarque,
        'pcreussite1': value.pcreussite1,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'idmatiereReferentielGuid': value.idmatiereReferentielGuid,
        'categorie': value.categorie,
        'idprojetFinancement': value.idprojetFinancement,
        'idmatiereReferentiel': value.idmatiereReferentiel,
        'isEditable': value.isEditable,
        'niveau': value.niveau,
        'uaa': value.uaa,
        'isVisibleBulletin': value.isVisibleBulletin,
        'sommative': value.sommative,
        'idmatiereParent': value.idmatiereParent,
    };
}

