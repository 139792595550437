import { Tab } from '@blueprintjs/core';
import { useApiEffect } from 'nsitools-react';
import * as React from "react";
import { useHistory, useParams } from 'react-router';
import { SocieteExterneDetailPage } from '.';
import { SocieteExterneApi } from '../../../../../api';
import { ERoutes } from '../../../../../AppRouter';
import { BackButton, PageBase, ProtectedTabs } from '../../../../../components';
import { useApiService, useTl } from '../../../../../hooks';
import { ETLCodes } from '../../../../../locales';

export interface ISocieteExterneItemProps { }

export const SocieteExterneItem: React.FunctionComponent<ISocieteExterneItemProps> = props => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const societeExterneApi = useApiService(SocieteExterneApi);
  const [displayName] = useApiEffect(() => societeExterneApi.societeExterneGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.SocieteExterne), route: ERoutes.societeExterne },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsSocieteExterne"
        onChange={newTabId => history.push(`${ERoutes.societeExterne}/${id}/${newTabId}/${state}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.societeExterne}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<SocieteExterneDetailPage />}
        />
      </ProtectedTabs>
    </PageBase>
  );
};