import { Button } from "@blueprintjs/core";
import { FieldSet } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";
import styled from "styled-components";

import {
  CarouselComparer,
  DoublonsDataCard,
  DoublonsMultipleDataCard,
  FO_FINAL_KEY,
  PE_FINAL_KEY,
  RE_FINAL_KEY,
  useDoublonsStateContext
} from "..";
import { AP_FINAL_KEY } from "../../..";
import { CompteBancaireDto, EmailDto, PersonneDoublonEditDto, TelephoneDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { AdresseInfos, ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { nameof } from "../../../../../utils";

const Container = styled.div`
  height: 70vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export interface IDoublonPersonnePageProps {}

export const DoublonPersonnePage: React.FunctionComponent<IDoublonPersonnePageProps> = props => {
  const { t } = useTl();
  const { data, getSelectedValue, selectData, getSelectedIndex, isDataMultipleSelected } = useDoublonsStateContext();

  const selectedIndex = React.useMemo(() => getSelectedIndex(`${PE_FINAL_KEY}.idpersonne`) ?? 0, [getSelectedIndex]);
  const { id } = useParams<{ id: string }>();
  const idpersonne1 = React.useMemo(() => +id.split("-")[0], [id]);
  const idpersonne2 = React.useMemo(() => +id.split("-")[1], [id]);
  const alreadyManaged = React.useMemo(() => idpersonne1 === idpersonne2, [idpersonne1, idpersonne2]);

  const selectedTels = React.useMemo(
    () => [
      getSelectedValue(`${AP_FINAL_KEY}.telephoneApprenant.idtelephone`, AP_FINAL_KEY),
      getSelectedValue(`${AP_FINAL_KEY}.gsmApprenant.idtelephone`, AP_FINAL_KEY),
      getSelectedValue(`${RE_FINAL_KEY}.telephoneRepresentant.idtelephone`, RE_FINAL_KEY),
      getSelectedValue(`${RE_FINAL_KEY}.gsmRepresentant.idtelephone`, RE_FINAL_KEY),
      getSelectedValue(`${FO_FINAL_KEY}.telephoneFormateur.idtelephone`, FO_FINAL_KEY),
      getSelectedValue(`${FO_FINAL_KEY}.telephoneProfFormateur.donneesProfessionnelles.idtelephone`, FO_FINAL_KEY),
      getSelectedValue(`${FO_FINAL_KEY}.gsmFormateur.idtelephone`, FO_FINAL_KEY),
      getSelectedValue(`${FO_FINAL_KEY}.gsmProfFormateur.donneesProfessionnelles.idtelephone`, FO_FINAL_KEY),
      ...(data?.chefEntreprises?.flatMap(c => c.siegesSociaux?.map(s => s.telephone?.idtelephone)) ?? []),
      ...(data?.chefEntreprises?.flatMap(c => c.siegesSociaux?.map(s => s.gsm?.idtelephone)) ?? []),
      ...(data?.contacts?.flatMap(c => c.siegesSociaux?.map(s => s.telephone?.idtelephone)) ?? []),
      ...(data?.contacts?.flatMap(c => c.siegesSociaux?.map(s => s.gsm?.idtelephone)) ?? []),
      ...(data?.contacts?.flatMap(c => c.lieuxFormation?.map(s => s.telephone?.idtelephone)) ?? []),
      ...(data?.contacts?.flatMap(c => c.lieuxFormation?.map(s => s.gsm?.idtelephone)) ?? [])
    ],
    [data?.chefEntreprises, data?.contacts, getSelectedValue]
  );

  const selectedEmails = React.useMemo(
    () => [
      getSelectedValue(`${AP_FINAL_KEY}.emailApprenant.idemail`, AP_FINAL_KEY),
      getSelectedValue(`${RE_FINAL_KEY}.emailRepresentant.idemail`, RE_FINAL_KEY),
      getSelectedValue(`${FO_FINAL_KEY}.emailFormateur.idemail`, FO_FINAL_KEY),
      getSelectedValue(`${FO_FINAL_KEY}.emailProfFormateur.donneesProfessionnelles.idemail`, FO_FINAL_KEY),
      ...(data?.chefEntreprises?.flatMap(c => c.siegesSociaux?.map(s => s.email?.idemail)) ?? []),
      ...(data?.contacts?.flatMap(c => c.siegesSociaux?.map(s => s.email?.idemail)) ?? []),
      ...(data?.contacts?.flatMap(c => c.lieuxFormation?.map(s => s.email?.idemail)) ?? [])
    ],
    [data?.chefEntreprises, data?.contacts, getSelectedValue]
  );

  const personneContainer = React.useCallback(
    (index: number, nb: number) => {
      const personne: PersonneDoublonEditDto = data.personnes[index];
      if (!personne) return;

      return (
        <FieldSet
          key={personne.idpersonne}
          title={
            <TitleContainer>
              {index !== selectedIndex ? (
                <Button
                  text={t(ETLCodes.Conserver)}
                  icon={"arrow-left"}
                  onClick={() => selectData(`${PE_FINAL_KEY}.idpersonne`, index)}
                />
              ) : (
                <div />
              )}
              <ViewButton
                text={
                  alreadyManaged
                    ? t(ETLCodes.VoirPersonne, { id: personne.idpersonne })
                    : t(ETLCodes.PersonneNb, { nb: nb, id: personne.idpersonne })
                }
                onClick={() => window.open(`#${ERoutes.personne}/${personne.idpersonne}/signaletique/view`, "_blank")}
              />
              <div />
            </TitleContainer>
          }
          titlePosition="center"
        >
          <DoublonsDataCard
            extValue={
              <InformationContainer>
                <div>{t(ETLCodes.NbAcquis, { nb: personne.nbAcquis })}</div>
              </InformationContainer>
            }
            label={t(ETLCodes.Informations)}
            interactive={false}
          />
          <DoublonsDataCard
            fieldPath={`personnes[${index}].nom`}
            label={t(ETLCodes.Nom)}
            finalEntityKey={!alreadyManaged && PE_FINAL_KEY}
            interactive={!alreadyManaged}
            displaySelectedValue={!alreadyManaged}
            entityIdKey={nameof<PersonneDoublonEditDto>("idpersonne")}
          />
          <DoublonsDataCard
            fieldPath={`personnes[${index}].prenom`}
            label={t(ETLCodes.Prenom)}
            finalEntityKey={!alreadyManaged && PE_FINAL_KEY}
            interactive={!alreadyManaged}
            displaySelectedValue={!alreadyManaged}
            entityIdKey={nameof<PersonneDoublonEditDto>("idpersonne")}
          />
          <DoublonsDataCard
            fieldPath={`personnes[${index}].prenom2`}
            label={t(ETLCodes.SecondPrenom)}
            finalEntityKey={!alreadyManaged && PE_FINAL_KEY}
            interactive={!alreadyManaged}
            displaySelectedValue={!alreadyManaged}
            entityIdKey={nameof<PersonneDoublonEditDto>("idpersonne")}
          />
          <DoublonsDataCard
            fieldPath={`personnes[${index}].codesexe`}
            valuePath={`personnes[${index}].sexe`}
            label={t(ETLCodes.Genre)}
            finalEntityKey={!alreadyManaged && PE_FINAL_KEY}
            interactive={!alreadyManaged}
            displaySelectedValue={!alreadyManaged}
            entityIdKey={nameof<PersonneDoublonEditDto>("idpersonne")}
          />
          <DoublonsDataCard
            fieldPath={`personnes[${index}].nationalite`}
            label={t(ETLCodes.Nationalite)}
            finalEntityKey={!alreadyManaged && PE_FINAL_KEY}
            interactive={!alreadyManaged}
            displaySelectedValue={!alreadyManaged}
            entityIdKey={nameof<PersonneDoublonEditDto>("idpersonne")}
          />
          <DoublonsDataCard
            fieldPath={`personnes[${index}].codePaysNaissance`}
            valuePath={`personnes[${index}].paysNaissance`}
            label={t(ETLCodes.PaysNaissance)}
            finalEntityKey={!alreadyManaged && PE_FINAL_KEY}
            interactive={!alreadyManaged}
            displaySelectedValue={!alreadyManaged}
            entityIdKey={nameof<PersonneDoublonEditDto>("idpersonne")}
          />
          <DoublonsDataCard
            fieldPath={`personnes[${index}].dateNaissance`}
            label={t(ETLCodes.DateNaissance)}
            finalEntityKey={!alreadyManaged && PE_FINAL_KEY}
            interactive={!alreadyManaged}
            displaySelectedValue={!alreadyManaged}
            entityIdKey={nameof<PersonneDoublonEditDto>("idpersonne")}
          />
          <DoublonsDataCard
            fieldPath={`personnes[${index}].localiteNaissance`}
            label={t(ETLCodes.LocaliteNaissance)}
            finalEntityKey={!alreadyManaged && PE_FINAL_KEY}
            interactive={!alreadyManaged}
            displaySelectedValue={!alreadyManaged}
            entityIdKey={nameof<PersonneDoublonEditDto>("idpersonne")}
          />
          <DoublonsDataCard
            fieldPath={`personnes[${index}].communeNaissance`}
            label={t(ETLCodes.CommuneNaissance)}
            finalEntityKey={!alreadyManaged && PE_FINAL_KEY}
            interactive={!alreadyManaged}
            displaySelectedValue={!alreadyManaged}
            entityIdKey={nameof<PersonneDoublonEditDto>("idpersonne")}
          />
          <DoublonsDataCard
            fieldPath={`personnes[${index}].registreNational`}
            label={t(ETLCodes.RegistreNational)}
            finalEntityKey={!alreadyManaged && PE_FINAL_KEY}
            interactive={!alreadyManaged}
            displaySelectedValue={!alreadyManaged}
            entityIdKey={nameof<PersonneDoublonEditDto>("idpersonne")}
          />
          <DoublonsDataCard
            fieldPath={`personnes[${index}].numeroIdentification`}
            label={t(ETLCodes.NumeroIdentification)}
            finalEntityKey={!alreadyManaged && PE_FINAL_KEY}
            interactive={!alreadyManaged}
            displaySelectedValue={!alreadyManaged}
            entityIdKey={nameof<PersonneDoublonEditDto>("idpersonne")}
          />
          <DoublonsDataCard
            fieldPath={`personnes[${index}].dateDeces`}
            label={t(ETLCodes.DateDeces)}
            finalEntityKey={!alreadyManaged && PE_FINAL_KEY}
            interactive={!alreadyManaged}
            displaySelectedValue={!alreadyManaged}
            entityIdKey={nameof<PersonneDoublonEditDto>("idpersonne")}
          />
          <DoublonsMultipleDataCard
            labelPredicate={() => t(ETLCodes.Remarque)}
            fieldPath={`personnes[${index}].remarques`}
            finalEntityKey={!alreadyManaged && PE_FINAL_KEY}
            interactive={() => !alreadyManaged}
          >
            {(r: string) => <>{r}</>}
          </DoublonsMultipleDataCard>
          <DoublonsDataCard
            fieldPath={`personnes[${index}].adresseDomicile.idadresse`}
            label={t(ETLCodes.AdresseDomicile)}
            finalEntityKey={!alreadyManaged && PE_FINAL_KEY}
            interactive={!alreadyManaged}
            displaySelectedValue={!alreadyManaged}
            valuePath={`personnes[${index}].adresseDomicile`}
            formatSelectedValue={val => (val ? <AdresseInfos data={val} /> : <>{"(" + t(ETLCodes.Aucun) + ")"}</>)}
            entityIdKey={nameof<PersonneDoublonEditDto>("idpersonne")}
            extValue={
              <InformationContainer>
                {personne.adresseDomicile ? <AdresseInfos data={personne.adresseDomicile} /> : t(ETLCodes.Aucun)}
              </InformationContainer>
            }
          />
          <DoublonsDataCard
            fieldPath={`personnes[${index}].adresseCourrier.idadresse`}
            label={t(ETLCodes.AdresseCourrier)}
            finalEntityKey={!alreadyManaged && PE_FINAL_KEY}
            interactive={!alreadyManaged}
            displaySelectedValue={!alreadyManaged}
            valuePath={`personnes[${index}].adresseCourrier`}
            formatSelectedValue={val => (val ? <AdresseInfos data={val} /> : <>{"(" + t(ETLCodes.Aucun) + ")"}</>)}
            entityIdKey={nameof<PersonneDoublonEditDto>("idpersonne")}
            extValue={
              <InformationContainer>
                {personne.adresseCourrier ? <AdresseInfos data={personne.adresseCourrier} /> : t(ETLCodes.Aucun)}
              </InformationContainer>
            }
          />
          <DoublonsDataCard
            fieldPath={`personnes[${index}].adresseAutre.idadresse`}
            label={t(ETLCodes.AdresseAutre)}
            finalEntityKey={!alreadyManaged && PE_FINAL_KEY}
            interactive={!alreadyManaged}
            displaySelectedValue={!alreadyManaged}
            valuePath={`personnes[${index}].adresseAutre`}
            formatSelectedValue={val => (val ? <AdresseInfos data={val} /> : <>{"(" + t(ETLCodes.Aucun) + ")"}</>)}
            entityIdKey={nameof<PersonneDoublonEditDto>("idpersonne")}
            extValue={
              <InformationContainer>
                {personne.adresseAutre ? <AdresseInfos data={personne.adresseAutre} /> : t(ETLCodes.Aucun)}
              </InformationContainer>
            }
          />
          {/* <DoublonsDataCard
            fieldPath={`personnes[${index}].compteBancaire.idcompteBancaire`}
            valuePath={`personnes[${index}].compteBancaire`}
            label={t(ETLCodes.CompteBancaire)}
            finalEntityKey={!alreadyManaged && PE_FINAL_KEY}
            interactive={!alreadyManaged}
            displaySelectedValue={!alreadyManaged}
            formatSelectedValue={val => <>({val ? `${val.iban} - ${val.bic}` : t(ETLCodes.Aucun)})</>}
            entityIdKey={nameof<PersonneDoublonEditDto>("idpersonne")}
            extValue={
              <InformationContainer>
                {personne.compteBancaire ? (
                  <>
                    <div>{personne.compteBancaire.iban}</div>
                    <div>{personne.compteBancaire.bic}</div>
                  </>
                ) : (
                  t(ETLCodes.Aucun)
                )}
              </InformationContainer>
            }
          /> */}
          <DoublonsMultipleDataCard
            title={t(ETLCodes.ComptesBancaires)}
            labelPredicate={(t: CompteBancaireDto) => t.bic}
            fieldPath={`personnes[${index}].comptesBancaires`}
            finalEntityKey={!alreadyManaged && PE_FINAL_KEY}
            interactive={(t: CompteBancaireDto) => false}
            forceSelected={(t: CompteBancaireDto) => true}
          >
            {(t: CompteBancaireDto) => <InformationContainer key={t.idcompteBancaire}>{t.iban}</InformationContainer>}
          </DoublonsMultipleDataCard>
          <DoublonsMultipleDataCard
            title={t(ETLCodes.Telephones)}
            labelPredicate={(t: TelephoneDto) => t.typeTelephone}
            fieldPath={`personnes[${index}].telephones`}
            finalEntityKey={!alreadyManaged && PE_FINAL_KEY}
            interactive={(t: TelephoneDto) => !alreadyManaged && !selectedTels.includes(t.idtelephone)}
            forceSelected={(t: TelephoneDto) =>
              alreadyManaged ||
              selectedTels.includes(t.idtelephone) ||
              isDataMultipleSelected(`${PE_FINAL_KEY}.telephones`, t)
            }
          >
            {(t: TelephoneDto) => <InformationContainer key={t.idtelephone}>{t.numero}</InformationContainer>}
          </DoublonsMultipleDataCard>
          <DoublonsMultipleDataCard
            title={t(ETLCodes.Emails)}
            labelPredicate={(e: EmailDto) => e.typeEmail}
            fieldPath={`personnes[${index}].emails`}
            finalEntityKey={!alreadyManaged && PE_FINAL_KEY}
            interactive={(e: EmailDto) => !alreadyManaged && !selectedEmails.includes(e.idemail)}
            forceSelected={(e: EmailDto) =>
              alreadyManaged ||
              selectedEmails.includes(e.idemail) ||
              isDataMultipleSelected(`${PE_FINAL_KEY}.emails`, e)
            }
          >
            {(e: EmailDto) => <InformationContainer key={e.idemail}>{e.adresseEmail}</InformationContainer>}
          </DoublonsMultipleDataCard>
        </FieldSet>
      );
    },
    [alreadyManaged, data.personnes, isDataMultipleSelected, selectData, selectedEmails, selectedIndex, selectedTels, t]
  );

  return (
    <Container>
      <CarouselComparer leftElement={personneContainer(selectedIndex, selectedIndex + 1)}>
        {data?.personnes?.length > 1 &&
          data?.personnes
            ?.filter((a, i) => i !== selectedIndex)
            ?.map((a, i) => personneContainer(i + (i >= selectedIndex ? 1 : 0), i + (i >= selectedIndex ? 2 : 1)))}
      </CarouselComparer>
    </Container>
  );
};
