/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbBulletinApprenantDto
 */
export interface FcbBulletinApprenantDto {
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantDto
     */
    idbulletinApprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantDto
     */
    idgrade?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantDto
     */
    totalGeneral?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantDto
     */
    iddeliberationType?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantDto
     */
    idmetier?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantDto
     */
    modificationUserName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbBulletinApprenantDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantDto
     */
    degre?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantDto
     */
    idstade?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantDto
     */
    max1sessSansDispense?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantDto
     */
    report?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantDto
     */
    isVisibleBulletin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantDto
     */
    classeName?: string | null;
}

/**
 * Check if a given object implements the FcbBulletinApprenantDto interface.
 */
export function instanceOfFcbBulletinApprenantDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbBulletinApprenantDtoFromJSON(json: any): FcbBulletinApprenantDto {
    return FcbBulletinApprenantDtoFromJSONTyped(json, false);
}

export function FcbBulletinApprenantDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbBulletinApprenantDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idbulletinApprenant': !exists(json, 'idbulletinApprenant') ? undefined : json['idbulletinApprenant'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idgrade': !exists(json, 'idgrade') ? undefined : json['idgrade'],
        'totalGeneral': !exists(json, 'totalGeneral') ? undefined : json['totalGeneral'],
        'iddeliberationType': !exists(json, 'iddeliberationType') ? undefined : json['iddeliberationType'],
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationUserName': !exists(json, 'modificationUserName') ? undefined : json['modificationUserName'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'degre': !exists(json, 'degre') ? undefined : json['degre'],
        'idstade': !exists(json, 'idstade') ? undefined : json['idstade'],
        'max1sessSansDispense': !exists(json, 'max1sessSansDispense') ? undefined : json['max1sessSansDispense'],
        'report': !exists(json, 'report') ? undefined : json['report'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'isVisibleBulletin': !exists(json, 'isVisibleBulletin') ? undefined : json['isVisibleBulletin'],
        'classeName': !exists(json, 'classeName') ? undefined : json['classeName'],
    };
}

export function FcbBulletinApprenantDtoToJSON(value?: FcbBulletinApprenantDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idbulletinApprenant': value.idbulletinApprenant,
        'idapprenant': value.idapprenant,
        'idgrade': value.idgrade,
        'totalGeneral': value.totalGeneral,
        'iddeliberationType': value.iddeliberationType,
        'idmetier': value.idmetier,
        'modificationUser': value.modificationUser,
        'modificationUserName': value.modificationUserName,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'degre': value.degre,
        'idstade': value.idstade,
        'max1sessSansDispense': value.max1sessSansDispense,
        'report': value.report,
        'anneeScolaire': value.anneeScolaire,
        'isVisibleBulletin': value.isVisibleBulletin,
        'classeName': value.classeName,
    };
}

