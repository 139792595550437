import { Tab, Tabs } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ExportApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { ExportDetailPage } from "./ExportDetailPage";
import { ExportPermissionsPage } from "./permissions/ExportPermissionsPage";

export interface IExportItemPageProps {}

export const ExportItemPage: React.FunctionComponent<IExportItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const api = useApiService(ExportApi);

  const [displayName] = useApiEffect(() => api.exportGetDisplayName({ id: +id }), [id]);

  return (
    <PageBase breadCrumbs={[{ text: t(ETLCodes.Exportations), route: ERoutes.exportation }, { text: displayName }]}>
      <Tabs
        id="TabsExport"
        onChange={newTabId => history.push(`${ERoutes.exportation}/${id}/${newTabId}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.exportation} />
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<ExportDetailPage idExport={+id} editMode={state === "edit"} />}
        />
        <Tab
          id="permission"
          title={t(ETLCodes.Permissions)}
          panel={<ExportPermissionsPage idExport={+id}></ExportPermissionsPage>}
          disabled={+id === 0}
        />
      </Tabs>
    </PageBase>
  );
};
