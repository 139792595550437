import { Checkbox } from "@blueprintjs/core";
import {
  DataTable,
  FGCustomPanel,
  FGListen,
  FieldGroup,
  FieldSet,
  IDataTableColumn,
  showError,
  useApiEffect,
  useGridState
} from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import {
  ClasseApi,
  ClasseMatiereApi,
  FcbClasseMatiereGridDto,
  FcbClasseMatiereMultipleDto
} from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import { FGWalterSelectInput, SmallFormGenerator } from "../../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

export interface IClasseMatiereAssignMultiplePageProps {}

export const ClasseMatiereAssignMultiplePage: React.FunctionComponent<IClasseMatiereAssignMultiplePageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id, tab, state } = useParams<{ id: string; tab: string; state: string }>();
  const api = useApiService(ClasseMatiereApi);
  const classeApi = useApiService(ClasseApi);
  const [selectedIDs, setSelectedIDs] = React.useState<number[]>([]);

  const [matieres, mLoading] = useApiEffect(
    () => api.classeMatiereBaseSearch({ ClasseMatiereSearch: { idClasse: +id } }),
    [id]
  );

  const tableState = useGridState({
    serverMode: false,
    enablePagination: false,
    enableFilter: false,
    pageSize: 25
  });

  const { setData } = tableState;

  React.useEffect(() => {
    if (matieres) {
      setData(matieres.results);
    }
  }, [matieres, setData]);

  const [classe] = useApiEffect(() => classeApi.classeGet({ id: +id }), [+id]);

  const { data, loading, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      ({
        idclasse: +id,
        idformateur: null
      } as FcbClasseMatiereMultipleDto),
    saveApiFn: d => {
      if (selectedIDs?.length === 0) {
        showError(t(ETLCodes.NoMatiereSelected));
        return null;
      }
      return api.classeMatiereSaveMultiple({ FcbClasseMatiereMultipleDto: { ...d, idclasseMatiereList: selectedIDs } });
    },
    deleteApiFn: d => {},
    onSavedRoute: d => `${ERoutes.classe}/${d.idclasse}/${tab}/${state}`
  });

  const [idFormateur, setIdFormateur] = React.useState(data?.idformateur ?? 0);
  const [idCentreLocal, setIdCentreLocal] = React.useState(0);

  React.useEffect(() => {
    if (classe) {
      setIdCentreLocal(classe.idcentre);
    }
  }, [classe]);

  const [formateurs, fLoading] = useReferential(a => a.referentialGetFormateurByIdClasse({ idClasse: +id }), true, [
    id
  ]);

  const [contrats, cLoading] = useReferential(
    a => a.referentialGetFormateurLocalisationContrat({ idformateur: idFormateur, idcentre: idCentreLocal }),
    true,
    [idFormateur, idCentreLocal]
  );
  const [typesProjet, tpLoading] = useReferential(
    a => a.referentialGetProjetFinancementByClasse({ idClasse: +id }),
    true,
    [id]
  );

  const toggleSelection = React.useCallback(
    (item: FcbClasseMatiereGridDto) => {
      if (!selectedIDs.includes(item.idclasseMatiere)) {
        setSelectedIDs(ids => [...ids, item.idclasseMatiere]);
      } else {
        setSelectedIDs(ids => ids.filter(id => id !== item.idclasseMatiere));
      }
    },
    [selectedIDs]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        render: (item: FcbClasseMatiereGridDto) => (
          <Checkbox
            checked={selectedIDs.includes(item.idclasseMatiere)}
            onChange={e => {
              toggleSelection(item);
            }}
          />
        )
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code"
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "description"
      },
      {
        header: () => t(ETLCodes.Heures),
        fieldName: "nbheures"
      },
      {
        header: () => t(ETLCodes.TJ),
        fieldName: "travail"
      },
      {
        header: () => t(ETLCodes.Examen),
        fieldName: "examen"
      },
      {
        header: () => t(ETLCodes.Reussite),
        fieldName: "pcReussite"
      },
      {
        header: () => t(ETLCodes.Local),
        fieldName: "local"
      },
      {
        header: () => t(ETLCodes.Formateur),
        fieldName: "formateur"
      },
      {
        header: () => t(ETLCodes.Contrat),
        fieldName: "contrat"
      },
      {
        header: () => t(ETLCodes.TypeProjet),
        fieldName: "projetFinancement"
      }
    ],
    [selectedIDs, t, toggleSelection]
  );

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idformateurLocalisationContrat: Yup.number()
        .nullable()
        .when("idformateur", (value, schema) =>
          value !== null && value !== undefined ? schema.required(t(ETLCodes.Required)) : schema
        )
    });
  }, [t]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      saving={saving}
      loading={loading}
      onCancel={() => history.push(`${ERoutes.classe}/${id}/${tab}/${state}`)}
      editMode={state === "edit"}
      validationSchema={FormSchema}
    >
      <FGListen
        field="idformateur"
        onChanged={(value, formik) => {
          if (isNaN(+value)) {
            setIdFormateur(0);
          } else {
            setIdFormateur(+value ?? 0);
          }
        }}
      />
      <FieldGroup>
        <FGWalterSelectInput items={formateurs} loading={fLoading} label={t(ETLCodes.Formateur)} name="idformateur" />
        <FGWalterSelectInput
          items={contrats}
          loading={cLoading}
          label={t(ETLCodes.Contrat)}
          name="idformateurLocalisationContrat"
          requiredMark={idFormateur !== 0}
        />
        <FGWalterSelectInput
          items={typesProjet}
          loading={tpLoading}
          label={t(ETLCodes.TypeProjet)}
          name="idprojetFinancement"
        />
      </FieldGroup>
      <FGCustomPanel>
        {ctx => (
          <FieldSet title={t(ETLCodes.ClasseMatiere)}>
            <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={mLoading} columns={columns} />
          </FieldSet>
        )}
      </FGCustomPanel>
    </SmallFormGenerator>
  );
};
