/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  ClasseInfosForSuiviDto,
  ClasseSearch,
  EmailAdressesDto,
  EvaluationEditableDto,
  FcbClasseCoutMatiereDto,
  FcbClasseDetailDto,
  FcbClasseGridDtoPaginatedResults,
  FcbCopyClasseDto,
  FilterCriteriaInfo,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    ClasseInfosForSuiviDtoFromJSON,
    ClasseInfosForSuiviDtoToJSON,
    ClasseSearchFromJSON,
    ClasseSearchToJSON,
    EmailAdressesDtoFromJSON,
    EmailAdressesDtoToJSON,
    EvaluationEditableDtoFromJSON,
    EvaluationEditableDtoToJSON,
    FcbClasseCoutMatiereDtoFromJSON,
    FcbClasseCoutMatiereDtoToJSON,
    FcbClasseDetailDtoFromJSON,
    FcbClasseDetailDtoToJSON,
    FcbClasseGridDtoPaginatedResultsFromJSON,
    FcbClasseGridDtoPaginatedResultsToJSON,
    FcbCopyClasseDtoFromJSON,
    FcbCopyClasseDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface ClasseBaseSearchRequest {
    ClasseSearch?: ClasseSearch;
}

export interface ClasseCopyClasseAsyncRequest {
    FcbCopyClasseDto?: FcbCopyClasseDto;
}

export interface ClasseDeleteRequest {
    id?: number;
}

export interface ClasseGetRequest {
    id?: number;
}

export interface ClasseGetAllEmailsRequest {
    idClasse?: number;
}

export interface ClasseGetApprenantEmailsRequest {
    idClasse?: number;
}

export interface ClasseGetClasseGenreRequest {
    id?: number;
}

export interface ClasseGetClasseInfosForSuiviRequest {
    idclasse?: number;
}

export interface ClasseGetCoutClasseRequest {
    id?: number;
}

export interface ClasseGetDegreIdRequest {
    classeId?: number;
}

export interface ClasseGetDisplayNameRequest {
    id?: number;
}

export interface ClasseGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ClasseGetSelectItemsRequest {
    searchField: string;
}

export interface ClasseGetTypeCoursRequest {
    classeId?: number;
}

export interface ClasseIsEvaluationEditableRequest {
    idClasse?: number;
    typeEvaluation?: number;
}

export interface ClasseIsJureRequest {
    id?: number;
}

export interface ClasseIsReferentielEditableRequest {
    id?: number;
}

export interface ClasseIsResultatLockRequest {
    idClasse?: number;
}

export interface ClasseSaveRequest {
    FcbClasseDetailDto?: FcbClasseDetailDto;
}

export interface ClasseSearchClasseIdsRequest {
    ClasseSearch?: ClasseSearch;
}

export interface ClasseSocieteExterneUnauthorizedRequest {
    idClasse?: number;
}

/**
 * 
 */
export class ClasseApi extends runtime.BaseAPI {

    /**
     */
    async classeBaseSearchRaw(requestParameters: ClasseBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbClasseGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Classe/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClasseSearchToJSON(requestParameters.ClasseSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbClasseGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async classeBaseSearch(requestParameters: ClasseBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbClasseGridDtoPaginatedResults> {
        const response = await this.classeBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeCopyClasseAsyncRaw(requestParameters: ClasseCopyClasseAsyncRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbCopyClasseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Classe/CopyClasse`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbCopyClasseDtoToJSON(requestParameters.FcbCopyClasseDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbCopyClasseDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeCopyClasseAsync(requestParameters: ClasseCopyClasseAsyncRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbCopyClasseDto> {
        const response = await this.classeCopyClasseAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeDeleteRaw(requestParameters: ClasseDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Classe`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async classeDelete(requestParameters: ClasseDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.classeDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeGetRaw(requestParameters: ClasseGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbClasseDetailDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Classe`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbClasseDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeGet(requestParameters: ClasseGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbClasseDetailDto> {
        const response = await this.classeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeGetAllEmailsRaw(requestParameters: ClasseGetAllEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailAdressesDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Classe/GetAllEmails`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailAdressesDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeGetAllEmails(requestParameters: ClasseGetAllEmailsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailAdressesDto> {
        const response = await this.classeGetAllEmailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeGetApprenantEmailsRaw(requestParameters: ClasseGetApprenantEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailAdressesDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Classe/GetApprenantEmails`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailAdressesDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeGetApprenantEmails(requestParameters: ClasseGetApprenantEmailsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailAdressesDto> {
        const response = await this.classeGetApprenantEmailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeGetClasseGenreRaw(requestParameters: ClasseGetClasseGenreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Classe/GetClasseGenre`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async classeGetClasseGenre(requestParameters: ClasseGetClasseGenreRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.classeGetClasseGenreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeGetClasseInfosForSuiviRaw(requestParameters: ClasseGetClasseInfosForSuiviRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClasseInfosForSuiviDto>> {
        const queryParameters: any = {};

        if (requestParameters.idclasse !== undefined) {
            queryParameters['idclasse'] = requestParameters.idclasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Classe/GetClasseInfosForSuivi`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClasseInfosForSuiviDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeGetClasseInfosForSuivi(requestParameters: ClasseGetClasseInfosForSuiviRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClasseInfosForSuiviDto> {
        const response = await this.classeGetClasseInfosForSuiviRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeGetCoutClasseRaw(requestParameters: ClasseGetCoutClasseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbClasseCoutMatiereDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Classe/CoutClasse`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbClasseCoutMatiereDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeGetCoutClasse(requestParameters: ClasseGetCoutClasseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbClasseCoutMatiereDto> {
        const response = await this.classeGetCoutClasseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeGetDegreIdRaw(requestParameters: ClasseGetDegreIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.classeId !== undefined) {
            queryParameters['classeId'] = requestParameters.classeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Classe/GetDegreId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async classeGetDegreId(requestParameters: ClasseGetDegreIdRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.classeGetDegreIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeGetDisplayNameRaw(requestParameters: ClasseGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Classe/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async classeGetDisplayName(requestParameters: ClasseGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.classeGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeGetSearchCriteriasRaw(requestParameters: ClasseGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Classe/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async classeGetSearchCriterias(requestParameters: ClasseGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.classeGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeGetSelectItemsRaw(requestParameters: ClasseGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling classeGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Classe/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async classeGetSelectItems(requestParameters: ClasseGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.classeGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeGetTypeCoursRaw(requestParameters: ClasseGetTypeCoursRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.classeId !== undefined) {
            queryParameters['classeId'] = requestParameters.classeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Classe/GetTypeCours`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async classeGetTypeCours(requestParameters: ClasseGetTypeCoursRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.classeGetTypeCoursRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeIsEvaluationEditableRaw(requestParameters: ClasseIsEvaluationEditableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EvaluationEditableDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        if (requestParameters.typeEvaluation !== undefined) {
            queryParameters['typeEvaluation'] = requestParameters.typeEvaluation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Classe/IsEvaluationEditable`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EvaluationEditableDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeIsEvaluationEditable(requestParameters: ClasseIsEvaluationEditableRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EvaluationEditableDto> {
        const response = await this.classeIsEvaluationEditableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeIsJureRaw(requestParameters: ClasseIsJureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Classe/IsJure`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeIsJure(requestParameters: ClasseIsJureRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.classeIsJureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeIsReferentielEditableRaw(requestParameters: ClasseIsReferentielEditableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Classe/IsReferentielEditable`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeIsReferentielEditable(requestParameters: ClasseIsReferentielEditableRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.classeIsReferentielEditableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeIsResultatLockRaw(requestParameters: ClasseIsResultatLockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Classe/IsResultatLock`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async classeIsResultatLock(requestParameters: ClasseIsResultatLockRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.classeIsResultatLockRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeSaveRaw(requestParameters: ClasseSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbClasseDetailDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Classe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbClasseDetailDtoToJSON(requestParameters.FcbClasseDetailDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbClasseDetailDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeSave(requestParameters: ClasseSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbClasseDetailDto> {
        const response = await this.classeSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeSearchClasseIdsRaw(requestParameters: ClasseSearchClasseIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Classe/SearchClasseIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClasseSearchToJSON(requestParameters.ClasseSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async classeSearchClasseIds(requestParameters: ClasseSearchClasseIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.classeSearchClasseIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async classeSocieteExterneUnauthorizedRaw(requestParameters: ClasseSocieteExterneUnauthorizedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Classe/SocieteExterneUnauthorized`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async classeSocieteExterneUnauthorized(requestParameters: ClasseSocieteExterneUnauthorizedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.classeSocieteExterneUnauthorizedRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
