import { HTMLSelect } from "@blueprintjs/core";
import { DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";

import { ClasseJureApi, JureSearch } from "../../../../../api";
import { useApiService, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

export interface IClasseJureItemPageProps {}

export const ClasseJureItemPage: React.FunctionComponent<IClasseJureItemPageProps> = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTl();

  const api = useApiService(ClasseJureApi);
  const [ClasseMatiere, cmLoading] = useReferential(
    a => a.referentialGetClasseMatiereByClasse({ idClasse: +id }),
    true,
    [],
    false
  );
  const [currentCM, setCurrentCm] = React.useState<number>();

  React.useEffect(() => {
    if (ClasseMatiere && !currentCM) {
      setCurrentCm(+ClasseMatiere[0].value);
    }
  }, [ClasseMatiere, currentCM]);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: false,
    enableFilter: false,
    availablePageSizes: [12],
    pageSize: 12,
    sortKeys: { nom: "ASC" }
  });

  const searchFunction = React.useCallback(
    (sObj?: JureSearch) => {
      sObj.idClasseMatiere = currentCM;
      return api.classeJureBaseSearch({ JureSearch: sObj });
    },
    [api, currentCM]
  );

  const { search, loading } = useSearchApi({
    searchFunction,
    tableState,
    initialSearch: true
  });

  React.useEffect(() => {
    search({
      idClasseMatiere: currentCM
    });
  }, [currentCM, search]);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      }
    ],
    [t]
  );

  return (
    <>
      {!cmLoading && (
        <FieldSet title={`${t(ETLCodes.Jures)}`}>
          <HTMLSelect options={ClasseMatiere} onChange={e => setCurrentCm(+e.target.value)} />
          <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
        </FieldSet>
      )}
    </>
  );
};
