/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommissionParitaireEditDto
 */
export interface CommissionParitaireEditDto {
    /**
     * 
     * @type {number}
     * @memberof CommissionParitaireEditDto
     */
    idcommissionParitaire?: number;
    /**
     * 
     * @type {string}
     * @memberof CommissionParitaireEditDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommissionParitaireEditDto
     */
    code?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommissionParitaireEditDto
     */
    idariane?: number | null;
}

/**
 * Check if a given object implements the CommissionParitaireEditDto interface.
 */
export function instanceOfCommissionParitaireEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CommissionParitaireEditDtoFromJSON(json: any): CommissionParitaireEditDto {
    return CommissionParitaireEditDtoFromJSONTyped(json, false);
}

export function CommissionParitaireEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommissionParitaireEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcommissionParitaire': !exists(json, 'idcommissionParitaire') ? undefined : json['idcommissionParitaire'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'idariane': !exists(json, 'idariane') ? undefined : json['idariane'],
    };
}

export function CommissionParitaireEditDtoToJSON(value?: CommissionParitaireEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcommissionParitaire': value.idcommissionParitaire,
        'libelle': value.libelle,
        'code': value.code,
        'idariane': value.idariane,
    };
}

