/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbRechercheexportDto
 */
export interface FcbRechercheexportDto {
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheexportDto
     */
    idexport?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheexportDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheexportDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheexportDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheexportDto
     */
    actif?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbRechercheexportDto
     */
    iddb?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheexportDto
     */
    db?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheexportDto
     */
    module?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheexportDto
     */
    centre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbRechercheexportDto
     */
    acces?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbRechercheexportDto
     */
    readonly actifComputed?: boolean;
}

/**
 * Check if a given object implements the FcbRechercheexportDto interface.
 */
export function instanceOfFcbRechercheexportDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbRechercheexportDtoFromJSON(json: any): FcbRechercheexportDto {
    return FcbRechercheexportDtoFromJSONTyped(json, false);
}

export function FcbRechercheexportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbRechercheexportDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idexport': !exists(json, 'idexport') ? undefined : json['idexport'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'iddb': !exists(json, 'iddb') ? undefined : json['iddb'],
        'db': !exists(json, 'db') ? undefined : json['db'],
        'module': !exists(json, 'module') ? undefined : json['module'],
        'centre': !exists(json, 'centre') ? undefined : json['centre'],
        'acces': !exists(json, 'acces') ? undefined : json['acces'],
        'actifComputed': !exists(json, 'actifComputed') ? undefined : json['actifComputed'],
    };
}

export function FcbRechercheexportDtoToJSON(value?: FcbRechercheexportDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idexport': value.idexport,
        'nom': value.nom,
        'type': value.type,
        'description': value.description,
        'actif': value.actif,
        'iddb': value.iddb,
        'db': value.db,
        'module': value.module,
        'centre': value.centre,
        'acces': value.acces,
    };
}

