/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ERapportHopeType {
    ENTITE = 'ENTITE',
    PUBLI = 'PUBLI',
    EXPORT = 'EXPORT'
}


export function ERapportHopeTypeFromJSON(json: any): ERapportHopeType {
    return ERapportHopeTypeFromJSONTyped(json, false);
}

export function ERapportHopeTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ERapportHopeType {
    return json as ERapportHopeType;
}

export function ERapportHopeTypeToJSON(value?: ERapportHopeType | null): any {
    return value as any;
}

