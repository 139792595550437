/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbResultatTypeEvaluationGridDto
 */
export interface FcbResultatTypeEvaluationGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeEvaluationGridDto
     */
    idclasseMatiere?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbResultatTypeEvaluationGridDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeEvaluationGridDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeEvaluationGridDto
     */
    typeEvaluation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeEvaluationGridDto
     */
    cote?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeEvaluationGridDto
     */
    coteRemediation?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeEvaluationGridDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbResultatTypeEvaluationGridDto
     */
    remediation?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeEvaluationGridDto
     */
    idevaluation?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbResultatTypeEvaluationGridDto
     */
    changeclasse?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbResultatTypeEvaluationGridDto
     */
    presence?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbResultatTypeEvaluationGridDto
     */
    neutralise?: boolean | null;
}

/**
 * Check if a given object implements the FcbResultatTypeEvaluationGridDto interface.
 */
export function instanceOfFcbResultatTypeEvaluationGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbResultatTypeEvaluationGridDtoFromJSON(json: any): FcbResultatTypeEvaluationGridDto {
    return FcbResultatTypeEvaluationGridDtoFromJSONTyped(json, false);
}

export function FcbResultatTypeEvaluationGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbResultatTypeEvaluationGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idclasseMatiere': !exists(json, 'idclasseMatiere') ? undefined : json['idclasseMatiere'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'typeEvaluation': !exists(json, 'typeEvaluation') ? undefined : json['typeEvaluation'],
        'cote': !exists(json, 'cote') ? undefined : json['cote'],
        'coteRemediation': !exists(json, 'coteRemediation') ? undefined : json['coteRemediation'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'remediation': !exists(json, 'remediation') ? undefined : json['remediation'],
        'idevaluation': !exists(json, 'idevaluation') ? undefined : json['idevaluation'],
        'changeclasse': !exists(json, 'changeclasse') ? undefined : json['changeclasse'],
        'presence': !exists(json, 'presence') ? undefined : json['presence'],
        'neutralise': !exists(json, 'neutralise') ? undefined : json['neutralise'],
    };
}

export function FcbResultatTypeEvaluationGridDtoToJSON(value?: FcbResultatTypeEvaluationGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idclasseMatiere': value.idclasseMatiere,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'libelle': value.libelle,
        'typeEvaluation': value.typeEvaluation,
        'cote': value.cote,
        'coteRemediation': value.coteRemediation,
        'remarque': value.remarque,
        'remediation': value.remediation,
        'idevaluation': value.idevaluation,
        'changeclasse': value.changeclasse,
        'presence': value.presence,
        'neutralise': value.neutralise,
    };
}

