/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EContentHidingMode } from './EContentHidingMode';
import {
    EContentHidingModeFromJSON,
    EContentHidingModeFromJSONTyped,
    EContentHidingModeToJSON,
} from './EContentHidingMode';
import type { SuiviActionEditDto } from './SuiviActionEditDto';
import {
    SuiviActionEditDtoFromJSON,
    SuiviActionEditDtoFromJSONTyped,
    SuiviActionEditDtoToJSON,
} from './SuiviActionEditDto';

/**
 * 
 * @export
 * @interface SuiviHopeMultipleEditDto
 */
export interface SuiviHopeMultipleEditDto {
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeMultipleEditDto
     */
    idsuivi?: number;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeMultipleEditDto
     */
    idservice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeMultipleEditDto
     */
    iduserService?: number;
    /**
     * 
     * @type {string}
     * @memberof SuiviHopeMultipleEditDto
     */
    userService?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeMultipleEditDto
     */
    creationUser?: number;
    /**
     * 
     * @type {string}
     * @memberof SuiviHopeMultipleEditDto
     */
    encodeur?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SuiviHopeMultipleEditDto
     */
    dateSuivi?: Date;
    /**
     * 
     * @type {Array<number>}
     * @memberof SuiviHopeMultipleEditDto
     */
    idsApprenant?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeMultipleEditDto
     */
    idcontrat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeMultipleEditDto
     */
    idmetier?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeMultipleEditDto
     */
    idtypeSuivi?: number;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeMultipleEditDto
     */
    idsousTypeSuivi?: number;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeMultipleEditDto
     */
    iddecisionCommissionTutelle?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeMultipleEditDto
     */
    idmode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviHopeMultipleEditDto
     */
    idlieuRencontre?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviHopeMultipleEditDto
     */
    contenu?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SuiviHopeMultipleEditDto
     */
    action?: boolean;
    /**
     * 
     * @type {EContentHidingMode}
     * @memberof SuiviHopeMultipleEditDto
     */
    contentHidingMode?: EContentHidingMode;
    /**
     * 
     * @type {SuiviActionEditDto}
     * @memberof SuiviHopeMultipleEditDto
     */
    suiviAction?: SuiviActionEditDto;
}

/**
 * Check if a given object implements the SuiviHopeMultipleEditDto interface.
 */
export function instanceOfSuiviHopeMultipleEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SuiviHopeMultipleEditDtoFromJSON(json: any): SuiviHopeMultipleEditDto {
    return SuiviHopeMultipleEditDtoFromJSONTyped(json, false);
}

export function SuiviHopeMultipleEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuiviHopeMultipleEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsuivi': !exists(json, 'idsuivi') ? undefined : json['idsuivi'],
        'idservice': !exists(json, 'idservice') ? undefined : json['idservice'],
        'iduserService': !exists(json, 'iduserService') ? undefined : json['iduserService'],
        'userService': !exists(json, 'userService') ? undefined : json['userService'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'encodeur': !exists(json, 'encodeur') ? undefined : json['encodeur'],
        'dateSuivi': !exists(json, 'dateSuivi') ? undefined : (new Date(json['dateSuivi'])),
        'idsApprenant': !exists(json, 'idsApprenant') ? undefined : json['idsApprenant'],
        'idcontrat': !exists(json, 'idcontrat') ? undefined : json['idcontrat'],
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'idtypeSuivi': !exists(json, 'idtypeSuivi') ? undefined : json['idtypeSuivi'],
        'idsousTypeSuivi': !exists(json, 'idsousTypeSuivi') ? undefined : json['idsousTypeSuivi'],
        'iddecisionCommissionTutelle': !exists(json, 'iddecisionCommissionTutelle') ? undefined : json['iddecisionCommissionTutelle'],
        'idmode': !exists(json, 'idmode') ? undefined : json['idmode'],
        'idlieuRencontre': !exists(json, 'idlieuRencontre') ? undefined : json['idlieuRencontre'],
        'contenu': !exists(json, 'contenu') ? undefined : json['contenu'],
        'action': !exists(json, 'action') ? undefined : json['action'],
        'contentHidingMode': !exists(json, 'contentHidingMode') ? undefined : EContentHidingModeFromJSON(json['contentHidingMode']),
        'suiviAction': !exists(json, 'suiviAction') ? undefined : SuiviActionEditDtoFromJSON(json['suiviAction']),
    };
}

export function SuiviHopeMultipleEditDtoToJSON(value?: SuiviHopeMultipleEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsuivi': value.idsuivi,
        'idservice': value.idservice,
        'iduserService': value.iduserService,
        'userService': value.userService,
        'creationUser': value.creationUser,
        'encodeur': value.encodeur,
        'dateSuivi': value.dateSuivi === undefined ? undefined : (value.dateSuivi.toISOString()),
        'idsApprenant': value.idsApprenant,
        'idcontrat': value.idcontrat,
        'idmetier': value.idmetier,
        'idtypeSuivi': value.idtypeSuivi,
        'idsousTypeSuivi': value.idsousTypeSuivi,
        'iddecisionCommissionTutelle': value.iddecisionCommissionTutelle,
        'idmode': value.idmode,
        'idlieuRencontre': value.idlieuRencontre,
        'contenu': value.contenu,
        'action': value.action,
        'contentHidingMode': EContentHidingModeToJSON(value.contentHidingMode),
        'suiviAction': SuiviActionEditDtoToJSON(value.suiviAction),
    };
}

