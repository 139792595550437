import * as React from "react";
import { IFGMultiCheckboxInputProps, FGMultiCheckboxInput } from "nsitools-react";
import { Colors } from "@blueprintjs/core";

export interface IFGWalterMultiCheckboxInputProps extends IFGMultiCheckboxInputProps {}

export const FGWalterMultiCheckboxInput: React.FunctionComponent<IFGWalterMultiCheckboxInputProps> = ({
  colorTick = Colors.GREEN5,
  colorCross = Colors.RED5,
  ...mcProps
}) => {
  return <FGMultiCheckboxInput {...mcProps} colorTick={colorTick} colorCross={colorCross} />;
};
