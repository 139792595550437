import { Tab } from "@blueprintjs/core";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";

import { PersonneHistory, PersonneSignaletique } from ".";
import { PersonneApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IPersonneItemPageProps {}

export const PersonneItemPage: React.FunctionComponent<IPersonneItemPageProps> = props => {
  const { t } = useTl();
  const { id, tab, state } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const personneApi = useApiService(PersonneApi);

  const { data: displayName } = useQuery(["personne-display-name", id], () =>
    personneApi.personneGetDisplayName({ id: +id })
  );
  const onTabChange = React.useCallback(
    newTabId => {
      history.push(`${ERoutes.personne}/${id}/${newTabId}/${state}`);
    },
    [history, id, state]
  );
  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.Personne), route: ERoutes.personne },
        { text: +id > 0 ? displayName : t(ETLCodes.New) }
      ]}
    >
      <ProtectedTabs id="TabsPersonne" onChange={onTabChange} selectedTabId={tab} renderActiveTabPanelOnly>
        <BackButton backRoute={ERoutes.personne}></BackButton>
        <Tab id="signaletique" title={t(ETLCodes.Signaletique)} panel={<PersonneSignaletique />} />
        <Tab id="history" title={t(ETLCodes.History)} panel={<PersonneHistory />} disabled={+id <= 0} />
      </ProtectedTabs>
    </PageBase>
  );
};
