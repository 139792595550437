import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ContratFormateurApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { ContratFormateurDetailPage } from "./ContratFormateurDetailPage";

interface IContratFormateurItemPageProps {}

export const ContratFormateurItemPage: React.FunctionComponent<IContratFormateurItemPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const ContratApi = useApiService(ContratFormateurApi);
  const [displayName] = useApiEffect(() => ContratApi.contratFormateurGetDisplayName({ id: +id }), [id]);
  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.ContratsFormateurs), route: ERoutes.contratFormateur },
        { text: +id <= 0 ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsContratFormateur"
        onChange={newTabId => history.push(`${ERoutes.contratFormateur}/${id}/${newTabId}/${state}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.contratFormateur}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<ContratFormateurDetailPage editMode={state === "edit"} idContrat={+id} />}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
