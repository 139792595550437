/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { BooleanSearch } from './BooleanSearch';
import {
    BooleanSearchFromJSON,
    BooleanSearchFromJSONTyped,
    BooleanSearchToJSON,
} from './BooleanSearch';
import type { DateSearch } from './DateSearch';
import {
    DateSearchFromJSON,
    DateSearchFromJSONTyped,
    DateSearchToJSON,
} from './DateSearch';
import type { DoubleListSearch } from './DoubleListSearch';
import {
    DoubleListSearchFromJSON,
    DoubleListSearchFromJSONTyped,
    DoubleListSearchToJSON,
} from './DoubleListSearch';
import type { EOrigineSuiviListSearch } from './EOrigineSuiviListSearch';
import {
    EOrigineSuiviListSearchFromJSON,
    EOrigineSuiviListSearchFromJSONTyped,
    EOrigineSuiviListSearchToJSON,
} from './EOrigineSuiviListSearch';
import type { ESuiviActionListSearch } from './ESuiviActionListSearch';
import {
    ESuiviActionListSearchFromJSON,
    ESuiviActionListSearchFromJSONTyped,
    ESuiviActionListSearchToJSON,
} from './ESuiviActionListSearch';
import type { Int32ListSearch } from './Int32ListSearch';
import {
    Int32ListSearchFromJSON,
    Int32ListSearchFromJSONTyped,
    Int32ListSearchToJSON,
} from './Int32ListSearch';
import type { NumericSearch } from './NumericSearch';
import {
    NumericSearchFromJSON,
    NumericSearchFromJSONTyped,
    NumericSearchToJSON,
} from './NumericSearch';
import type { UnaccentTextSearch } from './UnaccentTextSearch';
import {
    UnaccentTextSearchFromJSON,
    UnaccentTextSearchFromJSONTyped,
    UnaccentTextSearchToJSON,
} from './UnaccentTextSearch';

/**
 * 
 * @export
 * @interface SuiviGlobalSearchDto
 */
export interface SuiviGlobalSearchDto {
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof SuiviGlobalSearchDto
     */
    nomApprenant?: UnaccentTextSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof SuiviGlobalSearchDto
     */
    prenomApprenant?: UnaccentTextSearch;
    /**
     * 
     * @type {NumericSearch}
     * @memberof SuiviGlobalSearchDto
     */
    idapprenant?: NumericSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof SuiviGlobalSearchDto
     */
    idrealisateurSuivi?: Int32ListSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof SuiviGlobalSearchDto
     */
    idencodeur?: Int32ListSearch;
    /**
     * 
     * @type {EOrigineSuiviListSearch}
     * @memberof SuiviGlobalSearchDto
     */
    origineSuivi?: EOrigineSuiviListSearch;
    /**
     * 
     * @type {DateSearch}
     * @memberof SuiviGlobalSearchDto
     */
    dateSuivi?: DateSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof SuiviGlobalSearchDto
     */
    idtypeSuivi?: Int32ListSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof SuiviGlobalSearchDto
     */
    idsousTypeSuivi?: Int32ListSearch;
    /**
     * 
     * @type {DoubleListSearch}
     * @memberof SuiviGlobalSearchDto
     */
    idservice?: DoubleListSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof SuiviGlobalSearchDto
     */
    idpri?: Int32ListSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof SuiviGlobalSearchDto
     */
    idari?: Int32ListSearch;
    /**
     * 
     * @type {BooleanSearch}
     * @memberof SuiviGlobalSearchDto
     */
    onlyUserSuivis?: BooleanSearch;
    /**
     * 
     * @type {ESuiviActionListSearch}
     * @memberof SuiviGlobalSearchDto
     */
    suiviAction?: ESuiviActionListSearch;
    /**
     * 
     * @type {Array<string>}
     * @memberof SuiviGlobalSearchDto
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof SuiviGlobalSearchDto
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviGlobalSearchDto
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuiviGlobalSearchDto
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof SuiviGlobalSearchDto
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SuiviGlobalSearchDto
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the SuiviGlobalSearchDto interface.
 */
export function instanceOfSuiviGlobalSearchDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SuiviGlobalSearchDtoFromJSON(json: any): SuiviGlobalSearchDto {
    return SuiviGlobalSearchDtoFromJSONTyped(json, false);
}

export function SuiviGlobalSearchDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuiviGlobalSearchDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nomApprenant': !exists(json, 'nomApprenant') ? undefined : UnaccentTextSearchFromJSON(json['nomApprenant']),
        'prenomApprenant': !exists(json, 'prenomApprenant') ? undefined : UnaccentTextSearchFromJSON(json['prenomApprenant']),
        'idapprenant': !exists(json, 'idapprenant') ? undefined : NumericSearchFromJSON(json['idapprenant']),
        'idrealisateurSuivi': !exists(json, 'idrealisateurSuivi') ? undefined : Int32ListSearchFromJSON(json['idrealisateurSuivi']),
        'idencodeur': !exists(json, 'idencodeur') ? undefined : Int32ListSearchFromJSON(json['idencodeur']),
        'origineSuivi': !exists(json, 'origineSuivi') ? undefined : EOrigineSuiviListSearchFromJSON(json['origineSuivi']),
        'dateSuivi': !exists(json, 'dateSuivi') ? undefined : DateSearchFromJSON(json['dateSuivi']),
        'idtypeSuivi': !exists(json, 'idtypeSuivi') ? undefined : Int32ListSearchFromJSON(json['idtypeSuivi']),
        'idsousTypeSuivi': !exists(json, 'idsousTypeSuivi') ? undefined : Int32ListSearchFromJSON(json['idsousTypeSuivi']),
        'idservice': !exists(json, 'idservice') ? undefined : DoubleListSearchFromJSON(json['idservice']),
        'idpri': !exists(json, 'idpri') ? undefined : Int32ListSearchFromJSON(json['idpri']),
        'idari': !exists(json, 'idari') ? undefined : Int32ListSearchFromJSON(json['idari']),
        'onlyUserSuivis': !exists(json, 'onlyUserSuivis') ? undefined : BooleanSearchFromJSON(json['onlyUserSuivis']),
        'suiviAction': !exists(json, 'suiviAction') ? undefined : ESuiviActionListSearchFromJSON(json['suiviAction']),
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function SuiviGlobalSearchDtoToJSON(value?: SuiviGlobalSearchDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nomApprenant': UnaccentTextSearchToJSON(value.nomApprenant),
        'prenomApprenant': UnaccentTextSearchToJSON(value.prenomApprenant),
        'idapprenant': NumericSearchToJSON(value.idapprenant),
        'idrealisateurSuivi': Int32ListSearchToJSON(value.idrealisateurSuivi),
        'idencodeur': Int32ListSearchToJSON(value.idencodeur),
        'origineSuivi': EOrigineSuiviListSearchToJSON(value.origineSuivi),
        'dateSuivi': DateSearchToJSON(value.dateSuivi),
        'idtypeSuivi': Int32ListSearchToJSON(value.idtypeSuivi),
        'idsousTypeSuivi': Int32ListSearchToJSON(value.idsousTypeSuivi),
        'idservice': DoubleListSearchToJSON(value.idservice),
        'idpri': Int32ListSearchToJSON(value.idpri),
        'idari': Int32ListSearchToJSON(value.idari),
        'onlyUserSuivis': BooleanSearchToJSON(value.onlyUserSuivis),
        'suiviAction': ESuiviActionListSearchToJSON(value.suiviAction),
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

