import {
  Button,
  Checkbox,
  Colors,
  Icon,
  Intent,
  Menu,
  MenuItem,
  Popover,
  Spinner,
  SpinnerSize,
  Tooltip
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { faHouseChimneyUser, faBalanceScale } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonContainer, IDataTableColumn, showError } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router";
import styled, { css } from "styled-components";

import {
  LieuFormationAgremantDemandeSectorielleDialog,
  LieuFormationAgrementDecisionDialog,
  LieuFormationAgrementMultipleVisiteDialog,
  LieuFormationAgrementRetraitDialog,
  LieuFormationAgrementSuspensionDetailDialog
} from "..";
import {
  EBooleanSearchTypes,
  LieuFormationAgrementApi,
  LieuFormationAgrementGridDto,
  LieuFormationAgrementSearchDto,
  LieuFormationAgrementSuspensionDto,
  LieuFormationAgrementSuspensionMultipleDtoFromJSON,
  LieuFormationApi
} from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import {
  AddButton,
  BooleanColumn,
  CircleColumn,
  CustomBulletList,
  EditButton,
  SearchTablePage,
  ViewButton
} from "../../../../../../components";
import { useEventsContext } from "../../../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTheme, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

const PopoverContent = styled.div`
  padding: 0.5rem;
`;

const LegendHeaderContainer = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

export interface ILieuFormationAgrementListProps {}

export const LieuFormationAgrementList: React.FunctionComponent<ILieuFormationAgrementListProps> = props => {
  const { t, tUnsafe } = useTl();
  const { theme } = useTheme();
  const { push } = useHistory();
  const { id, state } = useParams<{ id: string; state: string }>();
  const lieuFormationId = React.useMemo(() => +id, [id]);
  const { search } = useLocation();
  const api = useApiService(LieuFormationAgrementApi);
  const [selectAllLoading, setSelectAllLoading] = React.useState(false);
  const [allSelected, setAllSelected] = React.useState(false);
  const [nbReturnedIds, setNbReturnedIds] = React.useState(0);
  const [selectedIDs, setSelectedIDs] = React.useState<number[]>([]);
  const lastSearchObject = React.useRef<LieuFormationAgrementSearchDto>();
  const { dispatchEvent } = useEventsContext();

  const apiFactory = useAbortableApiServiceFactory(LieuFormationAgrementApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFunction = React.useCallback(
    (sObj?: LieuFormationAgrementSearchDto) => {
      sObj.idlieuFormation = lieuFormationId;
      if (JSON.stringify(sObj) !== JSON.stringify(lastSearchObject.current)) {
        lastSearchObject.current = sObj;
      }
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.lieuFormationAgrementBaseSearch({ LieuFormationAgrementSearchDto: sObj });
    },
    [apiFactory, lieuFormationId]
  );

  const selectAll = React.useCallback(() => {
    setSelectAllLoading(true);
    api
      .lieuFormationAgrementSearchIds({
        LieuFormationAgrementSearchDto: { ...lastSearchObject.current, forceSkip: 0, forceTake: 999999 }
      })
      .then(allIds => {
        if (allIds.length === selectedIDs.length) {
          setAllSelected(false);
          setSelectedIDs([]);
          setNbReturnedIds(0);
        } else {
          setAllSelected(true);
          setSelectedIDs(allIds);
          setNbReturnedIds(allIds.length);
        }
        setSelectAllLoading(false);
      });
  }, [api, selectedIDs.length]);

  const toggleSelection = React.useCallback(
    (item: LieuFormationAgrementGridDto) => {
      if (!selectedIDs.includes(item.idlieuFormationAgrement)) {
        setSelectedIDs(ids => [...ids, item.idlieuFormationAgrement]);
      } else {
        setSelectedIDs(ids => ids.filter(id => id !== item.idlieuFormationAgrement));
      }
    },
    [selectedIDs]
  );

  const computeType = React.useCallback(
    (row: LieuFormationAgrementGridDto) => {
      if (row.agrementPartielA && row.agrementPartielB) return t(ETLCodes.PartielAB);
      if (row.agrementPartielA) return t(ETLCodes.PartielA);
      if (row.agrementPartielB) return t(ETLCodes.PartielB);
      return t(ETLCodes.Complet);
    },
    [t]
  );

  const onDuplicateClick = React.useCallback(
    (row: LieuFormationAgrementGridDto) => {
      push(
        `${ERoutes.lieuFormation}/${row.idlieuFormation}/agrements/edit/-1${search}&duplicateId=${row.idlieuFormationAgrement}`
      );
    },
    [push, search]
  );

  const lieuFormationApi = useApiService(LieuFormationApi);

  const [currentLieuFormationAgrementSuspension, setCurrentLieuFormationAgrementSuspension] = React.useState<
    LieuFormationAgrementSuspensionDto
  >(null);
  const onEditSuspension = React.useCallback(
    async (row: LieuFormationAgrementGridDto) => {
      const suspension = await api.lieuFormationAgrementGetSuspension({
        idlieuFormationAgrementSuspension: row.idEditableCurrentSuspension
      });
      setCurrentLieuFormationAgrementSuspension(suspension);
      setSuspensionDialogOpen(true);
    },
    [api]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        header: () =>
          selectAllLoading ? (
            <Spinner size={20} />
          ) : (
            <Checkbox checked={nbReturnedIds > 0 && nbReturnedIds === selectedIDs.length} onChange={selectAll} />
          ),
        render: (item: LieuFormationAgrementGridDto) => (
          <Checkbox
            checked={selectedIDs.includes(item.idlieuFormationAgrement)}
            onChange={e => {
              toggleSelection(item);
            }}
          />
        )
      },
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: LieuFormationAgrementGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() =>
                push(
                  `${ERoutes.lieuFormation}/${row.idlieuFormation}/agrements/view/${row.idlieuFormationAgrement}${search}`
                )
              }
            />
            <EditButton
              minimal={true}
              onClick={() =>
                push(
                  `${ERoutes.lieuFormation}/${row.idlieuFormation}/agrements/edit/${row.idlieuFormationAgrement}${search}`
                )
              }
            />
            <Popover
              position="bottom"
              content={
                <PopoverContent>
                  <Menu>
                    {row.idEditableCurrentSuspension && (
                      <MenuItem
                        icon={IconNames.PAUSE}
                        text={t(ETLCodes.ModifierSuspension)}
                        intent={Intent.PRIMARY}
                        onClick={() => onEditSuspension(row)}
                      />
                    )}
                    <MenuItem
                      icon={IconNames.DUPLICATE}
                      text={t(ETLCodes.Duplicate)}
                      intent={Intent.PRIMARY}
                      onClick={() => onDuplicateClick(row)}
                    />
                  </Menu>
                </PopoverContent>
              }
            >
              <Button icon={IconNames.CHEVRON_DOWN} minimal={true}></Button>
            </Popover>
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Offre),
        fieldName: "offreActive",
        autoFitContent: true,
        alignment: "center",
        render: (row: LieuFormationAgrementGridDto) => <BooleanColumn value={row.offreActive} />
      },
      {
        header: () => (
          <LegendHeaderContainer>
            <span>{t(ETLCodes.C)}</span>
            <Tooltip
              content={
                <CustomBulletList
                  title={t(ETLCodes.Legende)}
                  items={[
                    {
                      text: t(ETLCodes.CapaciteNonAtteinte),
                      color: theme.sucessColor
                    },
                    {
                      text: t(ETLCodes.CapaciteAtteinte),
                      color: theme.dangerColor
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip>
          </LegendHeaderContainer>
        ),
        fieldName: "capaciteAtteinte",
        autoFitContent: true,
        render: (row: LieuFormationAgrementGridDto) => (
          <CircleColumn color={row.capaciteAtteinte ? theme.dangerColor : theme.sucessColor} />
        )
      },
      {
        header: () => t(ETLCodes.CodeMetier),
        fieldName: "codeMetier"
      },
      {
        header: () => t(ETLCodes.NomMetier),
        fieldName: "nomMetier"
      },
      {
        header: () => t(ETLCodes.Stade),
        fieldName: "nomStade"
      },
      {
        header: () => t(ETLCodes.Statut),
        fieldName: "statut",
        render: row => tUnsafe(`StatutAgrement_${row.statut}`)
      },
      {
        header: () => t(ETLCodes.DecisionAgrement),
        fieldName: "decision"
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "type",
        render: (row: LieuFormationAgrementGridDto) => computeType(row)
      },
      {
        header: () => t(ETLCodes.DateDecision),
        fieldName: "dateDecision"
      }
    ],
    [
      computeType,
      nbReturnedIds,
      onDuplicateClick,
      onEditSuspension,
      push,
      search,
      selectAll,
      selectAllLoading,
      selectedIDs,
      t,
      tUnsafe,
      theme.dangerColor,
      theme.sucessColor,
      toggleSelection
    ]
  );

  const getCriteria = React.useCallback(
    () => api.lieuFormationAgrementGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  const { data: displayName, isLoading: displayNameLoading } = useQuery(["lieu-formation-display-name", id], () =>
    lieuFormationApi.lieuFormationGetDisplayName({ id: +id })
  );
  const finalDisplayName = React.useMemo(
    () => (displayNameLoading ? <Spinner size={SpinnerSize.SMALL} /> : displayName),
    [displayName, displayNameLoading]
  );

  const [actionsLoading, setActionsLoading] = React.useState(false);
  const [retraitDialogOpen, setRetraitDialogOpen] = React.useState(false);
  const onRetrait = React.useCallback(async () => {
    if (selectedIDs.length <= 0) {
      showError(t(ETLCodes.SelectAtLeastOne));
      return;
    }
    setActionsLoading(true);
    const existingRetrait = (await api.lieuFormationAgrementCheckStatutRetrait({ request_body: selectedIDs })).value;
    setActionsLoading(false);
    if (existingRetrait) {
      showError(t(ETLCodes.RetraitDejaEffectueSurUnAgrementSelectionne));
      return;
    }

    setRetraitDialogOpen(true);
  }, [api, selectedIDs, t]);

  const onCloseRetrait = React.useCallback(
    (refresh: boolean) => {
      if (refresh) {
        dispatchEvent("SEARCH_TABLE_REFRESH");
      }
      setRetraitDialogOpen(false);
      setAllSelected(false);
    },
    [dispatchEvent]
  );

  const [suspensionDialogOpen, setSuspensionDialogOpen] = React.useState(false);
  const onSuspension = React.useCallback(async () => {
    if (selectedIDs.length <= 0) {
      showError(t(ETLCodes.SelectAtLeastOne));
      return;
    }

    setSuspensionDialogOpen(true);
  }, [selectedIDs, t]);

  const [suspensionEditIds, setSuspensionEditIds] = React.useState([]);
  const onSuspensionEdit = React.useCallback(async () => {
    if (selectedIDs.length <= 0) {
      showError(t(ETLCodes.SelectAtLeastOne));
      return;
    }

    var canEditIds = await api.lieuFormationAgrementCanEditSuspensionsMultiple({ request_body: selectedIDs });
    if (canEditIds.length === 0 || selectedIDs.length !== canEditIds.length) {
      showError(t(ETLCodes.SuspensionsAgrementNonModifiables));
      return;
    }

    setSuspensionEditIds(canEditIds);
  }, [api, selectedIDs, t]);

  const onCloseSuspension = React.useCallback(
    async (toSave: LieuFormationAgrementSuspensionDto) => {
      if (!!toSave) {
        setActionsLoading(true);
        if (toSave.idlieuFormationAgrementSuspension > 0) {
          await api.lieuFormationAgrementEditSuspension({ LieuFormationAgrementSuspensionDto: toSave });
        } else {
          const dto = LieuFormationAgrementSuspensionMultipleDtoFromJSON({
            ...toSave,
            idslieuFormationAgrement: selectedIDs,
            idslieuFormationAgrementSuspension: suspensionEditIds
          });
          await api.lieuFormationAgrementSuspensionMultiple({ LieuFormationAgrementSuspensionMultipleDto: dto });
        }

        dispatchEvent("SEARCH_TABLE_REFRESH");
      }
      setActionsLoading(false);
      setCurrentLieuFormationAgrementSuspension(null);
      setSuspensionDialogOpen(false);
      setSuspensionEditIds([]);
    },
    [api, dispatchEvent, selectedIDs, suspensionEditIds]
  );

  const [visiteDialogOpen, setVisiteDialogOpen] = React.useState(false);
  const onVisite = React.useCallback(async () => {
    if (selectedIDs.length <= 0) {
      showError(t(ETLCodes.SelectAtLeastOne));
      return;
    }
    setVisiteDialogOpen(true);
  }, [selectedIDs, t]);

  const onCloseVisite = React.useCallback(
    (refresh?: boolean) => {
      if (refresh) {
        dispatchEvent("SEARCH_TABLE_REFRESH");
      }
      setVisiteDialogOpen(false);
    },
    [dispatchEvent]
  );

  const [demandeSectorielleDialogOpen, setDemandeSectorielleDialogOpen] = React.useState(false);
  const onDemandeSectorielle = React.useCallback(async () => {
    if (selectedIDs.length <= 0) {
      showError(t(ETLCodes.SelectAtLeastOne));
      return;
    }
    setDemandeSectorielleDialogOpen(true);
  }, [selectedIDs, t]);

  const onCloseDemandeSectorielle = React.useCallback(
    (refresh?: boolean) => {
      if (refresh) {
        dispatchEvent("SEARCH_TABLE_REFRESH");
      }
      setDemandeSectorielleDialogOpen(false);
    },
    [dispatchEvent]
  );

  const [decisionDialogOpen, setDecisionDialogOpen] = React.useState(false);
  const onDecision = React.useCallback(async () => {
    if (selectedIDs.length <= 0) {
      showError(t(ETLCodes.SelectAtLeastOne));
      return;
    }
    setDecisionDialogOpen(true);
  }, [selectedIDs, t]);

  const onCloseDecision = React.useCallback(
    (refresh?: boolean) => {
      if (refresh) {
        dispatchEvent("SEARCH_TABLE_REFRESH");
      }
      setDecisionDialogOpen(false);
    },
    [dispatchEvent]
  );

  const buttons = React.useMemo(
    () => (
      <>
        <Popover
          position="bottom"
          content={
            <PopoverContent>
              <Menu>
                <MenuItem
                  icon={<FontAwesomeIcon icon={faHouseChimneyUser} />}
                  text={t(ETLCodes.Visite)}
                  intent={Intent.PRIMARY}
                  onClick={() => onVisite()}
                />
                <MenuItem
                  icon={IconNames.PROJECTS}
                  text={t(ETLCodes.DemandeSectorielle)}
                  intent={Intent.PRIMARY}
                  onClick={() => onDemandeSectorielle()}
                />
                <MenuItem
                  icon={<FontAwesomeIcon icon={faBalanceScale} />}
                  text={t(ETLCodes.DecisionAgrement)}
                  intent={Intent.PRIMARY}
                  onClick={() => onDecision()}
                />
                <MenuItem
                  icon={IconNames.PAUSE}
                  text={t(ETLCodes.AddSuspensions)}
                  intent={Intent.PRIMARY}
                  onClick={() => onSuspension()}
                />
                <MenuItem
                  icon={IconNames.PAUSE}
                  text={t(ETLCodes.EditSuspensions)}
                  intent={Intent.PRIMARY}
                  onClick={() => onSuspensionEdit()}
                />
                <MenuItem
                  icon={IconNames.CROSS}
                  text={t(ETLCodes.Retrait)}
                  intent={Intent.PRIMARY}
                  onClick={() => onRetrait()}
                />
              </Menu>
            </PopoverContent>
          }
        >
          <Button
            icon={IconNames.CHEVRON_DOWN}
            minimal={true}
            text={t(ETLCodes.ActionPourLaSelection)}
            intent={Intent.PRIMARY}
            loading={actionsLoading}
          ></Button>
        </Popover>
        <AddButton
          onClick={async e => {
            e.stopPropagation();
            push(`${ERoutes.lieuFormation}/${id}/agrements/edit/-1${search}`);
          }}
          text={t(ETLCodes.AddAgrement)}
          intent={Intent.PRIMARY}
          loading={actionsLoading}
        />
      </>
    ),
    [
      actionsLoading,
      id,
      onDecision,
      onDemandeSectorielle,
      onRetrait,
      onSuspension,
      onSuspensionEdit,
      onVisite,
      push,
      search,
      t
    ]
  );

  const defaultCriterias = React.useMemo(
    () => [
      {
        criteria: "Actif",
        searchMode: EBooleanSearchTypes.Equals,
        value: true
      }
    ],
    []
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <SearchTablePage
        withCard={false}
        getCriteriasFunction={getCriteria}
        searchFunction={searchFunction}
        onAbort={onAbort}
        columns={columns}
        breadCrumbs={[
          { text: finalDisplayName, route: `${ERoutes.lieuFormation}/${id}/detail/${state}${search}` },
          { text: t(ETLCodes.LieuFormationAgrements) }
        ]}
        sortKeys={{ codeMetier: "ASC" }}
        rightElement={buttons}
        keyFieldName="idlieuFormationAgrement"
        defaultCriterias={defaultCriterias}
        searchStateInitialSearch={false}
        customizeRowStyle={(row: LieuFormationAgrementGridDto) => css`
          & * {
            color: ${!row.actif ? Colors.RED3 + " !important" : null};
          }
        `}
      />
      {retraitDialogOpen && (
        <LieuFormationAgrementRetraitDialog
          allSelected={allSelected}
          idlieuFormation={lieuFormationId}
          idslieuFormationAgrement={selectedIDs}
          dialogOpen={retraitDialogOpen}
          onClose={onCloseRetrait}
          multiple
        />
      )}
      {(suspensionDialogOpen || suspensionEditIds.length > 0) && (
        <LieuFormationAgrementSuspensionDetailDialog
          dialogOpen={suspensionDialogOpen || suspensionEditIds.length > 0}
          onClose={onCloseSuspension}
          currentSuspension={currentLieuFormationAgrementSuspension}
          showFile={false}
          multipleEdit={selectedIDs?.length > 0 && suspensionEditIds.length > 0}
        />
      )}
      {visiteDialogOpen && (
        <LieuFormationAgrementMultipleVisiteDialog
          dialogOpen={visiteDialogOpen}
          onClose={onCloseVisite}
          idslieuFormationAgrement={selectedIDs}
        />
      )}
      {demandeSectorielleDialogOpen && (
        <LieuFormationAgremantDemandeSectorielleDialog
          dialogOpen={demandeSectorielleDialogOpen}
          onClose={onCloseDemandeSectorielle}
          idslieuFormationAgrement={selectedIDs}
        />
      )}
      {decisionDialogOpen && (
        <LieuFormationAgrementDecisionDialog
          dialogOpen={decisionDialogOpen}
          onClose={onCloseDecision}
          idslieuFormationAgrement={selectedIDs}
        />
      )}
    </>
  );
};
