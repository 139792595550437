import { Card, Intent } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, useFGContext, useGridState } from "nsitools-react";
import * as React from "react";
import { StyledCallout, TitleContainer } from ".";

import {
  ContratEditDto,
  ContratLieuFormationTuteurGridDto,
  ContratLieuFormationTuteurGridDtoFromJSON,
  TuteurApi
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  AddButton,
  DeleteButton,
  ErrorText,
  FavoriteButton,
  LinkButton,
  TuteurSelectorDialog,
  WarningText
} from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IContratLieuFormationTuteursGridProps {
  idlieuFormation: number;
  idsiegeSocial: number;
  lieuPrincipal: boolean;
  readonly: boolean;
}

export const ContratLieuFormationTuteursGrid: React.FunctionComponent<IContratLieuFormationTuteursGridProps> = ({
  idlieuFormation,
  idsiegeSocial,
  lieuPrincipal,
  readonly
}) => {
  const { t } = useTl();
  const ctx = useFGContext<ContratEditDto>();
  const api = useApiService(TuteurApi);
  const [selectorDialogOpen, setSelectorDialogOpen] = React.useState(false);

  const index = React.useMemo(
    () => ctx?.formik?.values?.lieuxFormation?.findIndex(l => l.idlieuFormation === idlieuFormation),
    [ctx?.formik?.values?.lieuxFormation, idlieuFormation]
  );

  const tableState = useGridState<any>({
    serverMode: false,
    enablePagination: false,
    enableFilter: false,
    availablePageSizes: [9999],
    pageSize: 9999,
    sortKeys: { date: "ASC" }
  });

  const { setData } = tableState;

  React.useEffect(() => {
    setData(ctx.formik?.values?.lieuxFormation[index]?.tuteurs ?? []);
  }, [ctx.formik?.values?.lieuxFormation, index, setData]);

  const openTuteurSelector = React.useCallback(e => {
    e.stopPropagation();
    setSelectorDialogOpen(true);
  }, []);

  const removeTuteur = React.useCallback(
    (row: ContratLieuFormationTuteurGridDto) => {
      let nextValues = [...ctx.formik?.values?.lieuxFormation[index].tuteurs];
      nextValues.splice(nextValues.indexOf(row), 1);
      ctx.formik.setFieldValue(`lieuxFormation[${index}].tuteurs`, nextValues);
      ctx.formik.setFieldTouched(`lieuxFormation[${index}].tuteurs`, true);
      setData(nextValues);
    },
    [ctx.formik, index, setData]
  );

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ContratLieuFormationTuteurGridDto) => (
          <ButtonContainer>
            <FavoriteButton
              disabled={!lieuPrincipal || readonly}
              onClick={() => {
                let nextValues = [...ctx.formik?.values?.lieuxFormation[index].tuteurs];
                const principalValue = !row.principal;
                nextValues.forEach(v => {
                  if (v.idtuteur === row.idtuteur) {
                    v.principal = !v.principal;
                  } else if (principalValue) {
                    v.principal = false;
                  }
                });
                ctx.formik.setFieldValue(`lieuxFormation[${index}].tuteurs`, nextValues);
                ctx.formik.setFieldTouched(`lieuxFormation[${index}].tuteurs`, true);
              }}
              selected={row.principal}
            />
            <DeleteButton minimal onDelete={() => removeTuteur(row)} disabled={readonly} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Tuteur),
        fieldName: "nom",
        render: (row: ContratLieuFormationTuteurGridDto) => (
          <>
            <LinkButton text={row.nom} onClick={() =>
              window.open(row.idtuteurLieu ? `#${ERoutes.lieuFormation}/${idlieuFormation}/tuteur/view/${row.idtuteurLieu}?idsiegeSocial=${idsiegeSocial}` : `#${ERoutes.tuteur}/${row.idtuteur}/detail/view`)
            } />
            {!row.actif && <WarningText text={t(ETLCodes.TuteurInactifARemplacer)} />}
            {row.capaciteFormativeAtteinte && <WarningText text={t(ETLCodes.TuteurCapaciteFormativeAtteinte)} />}
            {ctx.validationErrors[`lieuxFormation[${index}].tuteurs`]?.length > 0 && (
              <ErrorText text={ctx.validationErrors[`lieuxFormation[${index}].tuteurs`][0]} />
            )}
          </>
        )
      }
    ],
    [ctx.formik, ctx.validationErrors, idlieuFormation, idsiegeSocial, index, lieuPrincipal, readonly, removeTuteur, t]
  );

  const onClosed = React.useCallback(
    async (selectedTuteurIds?: number[]) => {
      if (!!selectedTuteurIds && selectedTuteurIds.length > 0) {
        const selectedTuteurId = selectedTuteurIds[0];
        let nextValues = [...ctx.formik?.values?.lieuxFormation[index].tuteurs];
        if (nextValues.every(v => v.idtuteur !== selectedTuteurId)) {
          const nom = await api.tuteurGetDisplayName({ id: selectedTuteurId });
          nextValues.push(
            ContratLieuFormationTuteurGridDtoFromJSON({
              nom,
              actif: (await api.tuteurIsActifLieu({ idtuteur: selectedTuteurId, idlieuFormation: +idlieuFormation }))
                .value,
              idtuteur: selectedTuteurId,
              principal: nextValues.length === 0,
              capaciteFormativeAtteinte: (
                await api.tuteurIsCapaciteForfmativeAtteinte({
                  idtuteur: selectedTuteurId,
                  idlieuFormation: +idlieuFormation
                })
              ).value
            })
          );
          ctx.formik.setFieldValue(`lieuxFormation[${index}].tuteurs`, nextValues);
          ctx.formik.setFieldTouched(`lieuxFormation[${index}].tuteurs`, true);
          setData(nextValues);
        }
      }
      setSelectorDialogOpen(false);
    },
    [api, ctx.formik, idlieuFormation, index, setData]
  );

  const tuteursTitle = React.useMemo(
    () => (
      <TitleContainer>
        {ctx.validationErrors?.[`lieuxFormation[${index}].tuteurs`]?.length > 0 && (
          <StyledCallout intent="danger" title={t(ETLCodes.VeuillezVerifierLesTuteursDeCeContrat)} />
        )}
      </TitleContainer>
    ),
    [ctx.validationErrors, index, t]
  );

  return (
    <>
      <Card>
        <FieldSet
          title={tuteursTitle}
          rightElement={
            !readonly && <AddButton onClick={openTuteurSelector} text={t(ETLCodes.AddTuteur)} intent={Intent.PRIMARY} />
          }
        >
          <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} columns={columns} renderNoData={<></>} />
        </FieldSet>
      </Card>
      <TuteurSelectorDialog
        onClosed={onClosed}
        onTuteurSelected={onClosed}
        selectorDialogOpen={selectorDialogOpen}
        idlieuFormation={idlieuFormation}
        excludedTuteurIds={ctx?.formik?.values?.lieuxFormation[index]?.tuteurs?.map(t => t.idtuteur) ?? []}
      />
    </>
  );
};
