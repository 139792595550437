/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdresseDto } from './AdresseDto';
import {
    AdresseDtoFromJSON,
    AdresseDtoFromJSONTyped,
    AdresseDtoToJSON,
} from './AdresseDto';
import type { CompteBancaireDto } from './CompteBancaireDto';
import {
    CompteBancaireDtoFromJSON,
    CompteBancaireDtoFromJSONTyped,
    CompteBancaireDtoToJSON,
} from './CompteBancaireDto';
import type { EmailDto } from './EmailDto';
import {
    EmailDtoFromJSON,
    EmailDtoFromJSONTyped,
    EmailDtoToJSON,
} from './EmailDto';
import type { TelephoneDto } from './TelephoneDto';
import {
    TelephoneDtoFromJSON,
    TelephoneDtoFromJSONTyped,
    TelephoneDtoToJSON,
} from './TelephoneDto';

/**
 * 
 * @export
 * @interface PersonneDoublonEditDto
 */
export interface PersonneDoublonEditDto {
    /**
     * 
     * @type {number}
     * @memberof PersonneDoublonEditDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {string}
     * @memberof PersonneDoublonEditDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneDoublonEditDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneDoublonEditDto
     */
    prenom2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneDoublonEditDto
     */
    codesexe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneDoublonEditDto
     */
    sexe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneDoublonEditDto
     */
    nationalite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneDoublonEditDto
     */
    codePaysNaissance?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneDoublonEditDto
     */
    paysNaissance?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PersonneDoublonEditDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneDoublonEditDto
     */
    localiteNaissance?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneDoublonEditDto
     */
    communeNaissance?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneDoublonEditDto
     */
    registreNational?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneDoublonEditDto
     */
    numeroIdentification?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonneDoublonEditDto
     */
    photo?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PersonneDoublonEditDto
     */
    idfichierPhoto?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof PersonneDoublonEditDto
     */
    dateDeces?: Date | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PersonneDoublonEditDto
     */
    remarques?: Array<string> | null;
    /**
     * 
     * @type {AdresseDto}
     * @memberof PersonneDoublonEditDto
     */
    adresseDomicile?: AdresseDto;
    /**
     * 
     * @type {AdresseDto}
     * @memberof PersonneDoublonEditDto
     */
    adresseCourrier?: AdresseDto;
    /**
     * 
     * @type {AdresseDto}
     * @memberof PersonneDoublonEditDto
     */
    adresseAutre?: AdresseDto;
    /**
     * 
     * @type {Array<CompteBancaireDto>}
     * @memberof PersonneDoublonEditDto
     */
    comptesBancaires?: Array<CompteBancaireDto> | null;
    /**
     * 
     * @type {Array<TelephoneDto>}
     * @memberof PersonneDoublonEditDto
     */
    telephones?: Array<TelephoneDto> | null;
    /**
     * 
     * @type {Array<EmailDto>}
     * @memberof PersonneDoublonEditDto
     */
    emails?: Array<EmailDto> | null;
    /**
     * 
     * @type {number}
     * @memberof PersonneDoublonEditDto
     */
    nbAcquis?: number;
}

/**
 * Check if a given object implements the PersonneDoublonEditDto interface.
 */
export function instanceOfPersonneDoublonEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PersonneDoublonEditDtoFromJSON(json: any): PersonneDoublonEditDto {
    return PersonneDoublonEditDtoFromJSONTyped(json, false);
}

export function PersonneDoublonEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonneDoublonEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'prenom2': !exists(json, 'prenom2') ? undefined : json['prenom2'],
        'codesexe': !exists(json, 'codesexe') ? undefined : json['codesexe'],
        'sexe': !exists(json, 'sexe') ? undefined : json['sexe'],
        'nationalite': !exists(json, 'nationalite') ? undefined : json['nationalite'],
        'codePaysNaissance': !exists(json, 'codePaysNaissance') ? undefined : json['codePaysNaissance'],
        'paysNaissance': !exists(json, 'paysNaissance') ? undefined : json['paysNaissance'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'localiteNaissance': !exists(json, 'localiteNaissance') ? undefined : json['localiteNaissance'],
        'communeNaissance': !exists(json, 'communeNaissance') ? undefined : json['communeNaissance'],
        'registreNational': !exists(json, 'registreNational') ? undefined : json['registreNational'],
        'numeroIdentification': !exists(json, 'numeroIdentification') ? undefined : json['numeroIdentification'],
        'photo': !exists(json, 'photo') ? undefined : json['photo'],
        'idfichierPhoto': !exists(json, 'idfichierPhoto') ? undefined : json['idfichierPhoto'],
        'dateDeces': !exists(json, 'dateDeces') ? undefined : (json['dateDeces'] === null ? null : new Date(json['dateDeces'])),
        'remarques': !exists(json, 'remarques') ? undefined : json['remarques'],
        'adresseDomicile': !exists(json, 'adresseDomicile') ? undefined : AdresseDtoFromJSON(json['adresseDomicile']),
        'adresseCourrier': !exists(json, 'adresseCourrier') ? undefined : AdresseDtoFromJSON(json['adresseCourrier']),
        'adresseAutre': !exists(json, 'adresseAutre') ? undefined : AdresseDtoFromJSON(json['adresseAutre']),
        'comptesBancaires': !exists(json, 'comptesBancaires') ? undefined : (json['comptesBancaires'] === null ? null : (json['comptesBancaires'] as Array<any>).map(CompteBancaireDtoFromJSON)),
        'telephones': !exists(json, 'telephones') ? undefined : (json['telephones'] === null ? null : (json['telephones'] as Array<any>).map(TelephoneDtoFromJSON)),
        'emails': !exists(json, 'emails') ? undefined : (json['emails'] === null ? null : (json['emails'] as Array<any>).map(EmailDtoFromJSON)),
        'nbAcquis': !exists(json, 'nbAcquis') ? undefined : json['nbAcquis'],
    };
}

export function PersonneDoublonEditDtoToJSON(value?: PersonneDoublonEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idpersonne': value.idpersonne,
        'nom': value.nom,
        'prenom': value.prenom,
        'prenom2': value.prenom2,
        'codesexe': value.codesexe,
        'sexe': value.sexe,
        'nationalite': value.nationalite,
        'codePaysNaissance': value.codePaysNaissance,
        'paysNaissance': value.paysNaissance,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'localiteNaissance': value.localiteNaissance,
        'communeNaissance': value.communeNaissance,
        'registreNational': value.registreNational,
        'numeroIdentification': value.numeroIdentification,
        'photo': value.photo,
        'idfichierPhoto': value.idfichierPhoto,
        'dateDeces': value.dateDeces === undefined ? undefined : (value.dateDeces === null ? null : value.dateDeces.toISOString()),
        'remarques': value.remarques,
        'adresseDomicile': AdresseDtoToJSON(value.adresseDomicile),
        'adresseCourrier': AdresseDtoToJSON(value.adresseCourrier),
        'adresseAutre': AdresseDtoToJSON(value.adresseAutre),
        'comptesBancaires': value.comptesBancaires === undefined ? undefined : (value.comptesBancaires === null ? null : (value.comptesBancaires as Array<any>).map(CompteBancaireDtoToJSON)),
        'telephones': value.telephones === undefined ? undefined : (value.telephones === null ? null : (value.telephones as Array<any>).map(TelephoneDtoToJSON)),
        'emails': value.emails === undefined ? undefined : (value.emails === null ? null : (value.emails as Array<any>).map(EmailDtoToJSON)),
        'nbAcquis': value.nbAcquis,
    };
}

