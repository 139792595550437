import { useLocalStorageState } from "ahooks";
import * as React from "react";

export const useTabMessage = (identifier: string) => {
  const [messageValue, setMessageValue] = useLocalStorageState<any>(identifier, { defaultValue: null });
  const [newTabOpened, setNewTabOpened] = React.useState(false);

  const receiveMessage = React.useCallback(
    event => {
      if (event.origin !== window.location.origin || identifier !== event.data?.identifier) return;

      if (event.data.tabReady) {
        setNewTabOpened(event.data.tabReady);
      } else if (event.data.tabClosed) {
        setNewTabOpened(false);
      } else {
        setMessageValue(JSON.parse(event.data.value));
      }
    },
    [identifier, setMessageValue]
  );

  React.useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
    return () => window.removeEventListener("message", receiveMessage);
  }, [receiveMessage]);

  const sendMessage = React.useCallback(
    (value: any, closeTab = false, tab: Window = window.opener) => {
      if (!tab) return;

      tab.postMessage({ identifier, value: JSON.stringify(value) }, window.location.origin);

      if (closeTab) {
        tab.focus();
      }
    },
    [identifier]
  );

  const [messageToSend, setMessageToSend] = React.useState(null);
  const openTabThenSendMessage = React.useCallback(
    (path: string, value: any, params?: { key: string; value: string }[]) => {
      const joinedParams = !!params && params.length > 0 ? params.map(p => `&${p.key}=${p.value}`).join() : "";
      const tab = window.open(`${path}?cfs=${identifier}${joinedParams}`, "_blank");
      setMessageToSend({ tab, value });
    },
    [identifier]
  );

  React.useEffect(() => {
    if (newTabOpened && messageToSend) {
      sendMessage(messageToSend.value, false, messageToSend.tab);
    }
  }, [messageToSend, newTabOpened, sendMessage]);

  // Hook ready
  React.useEffect(() => {
    if (identifier && window.opener) {
      window.opener.postMessage({ identifier, tabReady: true }, window.location.origin);
    }
  }, [identifier, sendMessage]);

  const unreadyTab = React.useCallback(() => {
    if (identifier && window.opener) {
      setMessageValue(undefined);
      window.opener.postMessage({ identifier, tabClosed: true }, window.location.origin);
    }
  }, [identifier, setMessageValue]);

  // Tab closing
  React.useEffect(() => {
    window.addEventListener("beforeunload", unreadyTab, false);
    return () => window.removeEventListener("beforeunload", unreadyTab);
  }, [unreadyTab]);

  return {
    messageValue,
    clearMessageValue: () => setMessageValue(null),
    sendMessage,
    openTabThenSendMessage
  };
};
