/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApprenantRepresentantsSearch,
  FcbApprenantRepresentantDto,
  FcbApprenantRepresentantGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    ApprenantRepresentantsSearchFromJSON,
    ApprenantRepresentantsSearchToJSON,
    FcbApprenantRepresentantDtoFromJSON,
    FcbApprenantRepresentantDtoToJSON,
    FcbApprenantRepresentantGridDtoPaginatedResultsFromJSON,
    FcbApprenantRepresentantGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface ApprenantRepresentantsBaseSearchRequest {
    ApprenantRepresentantsSearch?: ApprenantRepresentantsSearch;
}

export interface ApprenantRepresentantsDeleteRequest {
    FcbApprenantRepresentantDto?: FcbApprenantRepresentantDto;
}

export interface ApprenantRepresentantsExistsRequest {
    idApprenant?: number;
    idRepresentant?: number;
}

export interface ApprenantRepresentantsGetRequest {
    idRepresantant?: number;
    idApprenant?: number;
}

export interface ApprenantRepresentantsGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ApprenantRepresentantsGetSelectItemsRequest {
    searchField: string;
}

export interface ApprenantRepresentantsSaveRequest {
    FcbApprenantRepresentantDto?: FcbApprenantRepresentantDto;
}

/**
 * 
 */
export class ApprenantRepresentantsApi extends runtime.BaseAPI {

    /**
     */
    async apprenantRepresentantsBaseSearchRaw(requestParameters: ApprenantRepresentantsBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbApprenantRepresentantGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantRepresentants/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApprenantRepresentantsSearchToJSON(requestParameters.ApprenantRepresentantsSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbApprenantRepresentantGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async apprenantRepresentantsBaseSearch(requestParameters: ApprenantRepresentantsBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbApprenantRepresentantGridDtoPaginatedResults> {
        const response = await this.apprenantRepresentantsBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantRepresentantsDeleteRaw(requestParameters: ApprenantRepresentantsDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantRepresentants/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: FcbApprenantRepresentantDtoToJSON(requestParameters.FcbApprenantRepresentantDto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async apprenantRepresentantsDelete(requestParameters: ApprenantRepresentantsDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.apprenantRepresentantsDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantRepresentantsExistsRaw(requestParameters: ApprenantRepresentantsExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.idApprenant !== undefined) {
            queryParameters['idApprenant'] = requestParameters.idApprenant;
        }

        if (requestParameters.idRepresentant !== undefined) {
            queryParameters['idRepresentant'] = requestParameters.idRepresentant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantRepresentants/Exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async apprenantRepresentantsExists(requestParameters: ApprenantRepresentantsExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.apprenantRepresentantsExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantRepresentantsGetRaw(requestParameters: ApprenantRepresentantsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbApprenantRepresentantDto>> {
        const queryParameters: any = {};

        if (requestParameters.idRepresantant !== undefined) {
            queryParameters['idRepresantant'] = requestParameters.idRepresantant;
        }

        if (requestParameters.idApprenant !== undefined) {
            queryParameters['idApprenant'] = requestParameters.idApprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantRepresentants/get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbApprenantRepresentantDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantRepresentantsGet(requestParameters: ApprenantRepresentantsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbApprenantRepresentantDto> {
        const response = await this.apprenantRepresentantsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantRepresentantsGetSearchCriteriasRaw(requestParameters: ApprenantRepresentantsGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantRepresentants/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async apprenantRepresentantsGetSearchCriterias(requestParameters: ApprenantRepresentantsGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.apprenantRepresentantsGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantRepresentantsGetSelectItemsRaw(requestParameters: ApprenantRepresentantsGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling apprenantRepresentantsGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantRepresentants/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async apprenantRepresentantsGetSelectItems(requestParameters: ApprenantRepresentantsGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.apprenantRepresentantsGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantRepresentantsSaveRaw(requestParameters: ApprenantRepresentantsSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbApprenantRepresentantDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantRepresentants/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbApprenantRepresentantDtoToJSON(requestParameters.FcbApprenantRepresentantDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbApprenantRepresentantDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantRepresentantsSave(requestParameters: ApprenantRepresentantsSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbApprenantRepresentantDto> {
        const response = await this.apprenantRepresentantsSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
