/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbPaiementDto
 */
export interface FcbPaiementDto {
    /**
     * 
     * @type {number}
     * @memberof FcbPaiementDto
     */
    idpaiement?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbPaiementDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbPaiementDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbPaiementDto
     */
    datePaiement?: Date;
    /**
     * 
     * @type {number}
     * @memberof FcbPaiementDto
     */
    montantDu?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbPaiementDto
     */
    montantPaye?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbPaiementDto
     */
    idmode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbPaiementDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbPaiementDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbPaiementDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbPaiementDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbPaiementDto
     */
    idinscription?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbPaiementDto
     */
    idcentre?: number | null;
}

/**
 * Check if a given object implements the FcbPaiementDto interface.
 */
export function instanceOfFcbPaiementDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbPaiementDtoFromJSON(json: any): FcbPaiementDto {
    return FcbPaiementDtoFromJSONTyped(json, false);
}

export function FcbPaiementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbPaiementDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idpaiement': !exists(json, 'idpaiement') ? undefined : json['idpaiement'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'datePaiement': !exists(json, 'datePaiement') ? undefined : (new Date(json['datePaiement'])),
        'montantDu': !exists(json, 'montantDu') ? undefined : json['montantDu'],
        'montantPaye': !exists(json, 'montantPaye') ? undefined : json['montantPaye'],
        'idmode': !exists(json, 'idmode') ? undefined : json['idmode'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'idinscription': !exists(json, 'idinscription') ? undefined : json['idinscription'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
    };
}

export function FcbPaiementDtoToJSON(value?: FcbPaiementDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idpaiement': value.idpaiement,
        'idapprenant': value.idapprenant,
        'anneeScolaire': value.anneeScolaire,
        'datePaiement': value.datePaiement === undefined ? undefined : (value.datePaiement.toISOString()),
        'montantDu': value.montantDu,
        'montantPaye': value.montantPaye,
        'idmode': value.idmode,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'idinscription': value.idinscription,
        'idcentre': value.idcentre,
    };
}

