import { Tab, Tabs } from "@blueprintjs/core";
import * as React from "react";
import {
  DoublonApprenantDocuments,
  DoublonApprenantParcoursFormation,
  DoublonApprenantPassportDrive,
  DoublonApprenantRepresentants,
  DoublonApprenantSignaletique,
  DoublonApprenantStatutApprenant
} from ".";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IDoublonApprenantPageProps {}

export const DoublonApprenantPage: React.FunctionComponent<IDoublonApprenantPageProps> = props => {
  const { t } = useTl();
  const [tab, setTab] = React.useState("signaletique");

  return (
    <Tabs
      id="TabsDoublonApprenant"
      onChange={newTabId => setTab(newTabId + "")}
      selectedTabId={tab}
      renderActiveTabPanelOnly
      vertical
    >
      <Tab id="signaletique" title={t(ETLCodes.Signaletique)} panel={<DoublonApprenantSignaletique />} />
      <Tab id="representants" title={t(ETLCodes.Representants)} panel={<DoublonApprenantRepresentants />} />
      <Tab id="parcours" title={t(ETLCodes.ParcoursFormation)} panel={<DoublonApprenantParcoursFormation />} />
      <Tab id="statut" title={t(ETLCodes.StatutsMotifs)} panel={<DoublonApprenantStatutApprenant />} />
      <Tab id="passportDrive" title={t(ETLCodes.PassportDrive)} panel={<DoublonApprenantPassportDrive />} />
      <Tab id="documents" title={t(ETLCodes.Documents)} panel={<DoublonApprenantDocuments />} />
    </Tabs>
  );
};
