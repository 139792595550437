/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PassportDriveDto
 */
export interface PassportDriveDto {
    /**
     * 
     * @type {number}
     * @memberof PassportDriveDto
     */
    idpassportDrive?: number;
    /**
     * 
     * @type {number}
     * @memberof PassportDriveDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {string}
     * @memberof PassportDriveDto
     */
    permis?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PassportDriveDto
     */
    idautoecole?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PassportDriveDto
     */
    autoecole?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PassportDriveDto
     */
    remiseCheque?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof PassportDriveDto
     */
    inscriptionEffective?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof PassportDriveDto
     */
    datePermisTheorique?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof PassportDriveDto
     */
    idresultatPermisTheorique?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PassportDriveDto
     */
    resultatPermisTheorique?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PassportDriveDto
     */
    datePermisPratique?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof PassportDriveDto
     */
    idresultatPermisPratique?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PassportDriveDto
     */
    resultatPermisPratique?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PassportDriveDto
     */
    demandeNouveauCheque?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PassportDriveDto
     */
    pasEmailPromotionnel?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PassportDriveDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof PassportDriveDto
     */
    creationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof PassportDriveDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof PassportDriveDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof PassportDriveDto
     */
    consentementCollecteDonnees?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PassportDriveDto
     */
    chequeReceptionForem?: boolean;
}

/**
 * Check if a given object implements the PassportDriveDto interface.
 */
export function instanceOfPassportDriveDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PassportDriveDtoFromJSON(json: any): PassportDriveDto {
    return PassportDriveDtoFromJSONTyped(json, false);
}

export function PassportDriveDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PassportDriveDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idpassportDrive': !exists(json, 'idpassportDrive') ? undefined : json['idpassportDrive'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'permis': !exists(json, 'permis') ? undefined : json['permis'],
        'idautoecole': !exists(json, 'idautoecole') ? undefined : json['idautoecole'],
        'autoecole': !exists(json, 'autoecole') ? undefined : json['autoecole'],
        'remiseCheque': !exists(json, 'remiseCheque') ? undefined : (json['remiseCheque'] === null ? null : new Date(json['remiseCheque'])),
        'inscriptionEffective': !exists(json, 'inscriptionEffective') ? undefined : (json['inscriptionEffective'] === null ? null : new Date(json['inscriptionEffective'])),
        'datePermisTheorique': !exists(json, 'datePermisTheorique') ? undefined : (json['datePermisTheorique'] === null ? null : new Date(json['datePermisTheorique'])),
        'idresultatPermisTheorique': !exists(json, 'idresultatPermisTheorique') ? undefined : json['idresultatPermisTheorique'],
        'resultatPermisTheorique': !exists(json, 'resultatPermisTheorique') ? undefined : json['resultatPermisTheorique'],
        'datePermisPratique': !exists(json, 'datePermisPratique') ? undefined : (json['datePermisPratique'] === null ? null : new Date(json['datePermisPratique'])),
        'idresultatPermisPratique': !exists(json, 'idresultatPermisPratique') ? undefined : json['idresultatPermisPratique'],
        'resultatPermisPratique': !exists(json, 'resultatPermisPratique') ? undefined : json['resultatPermisPratique'],
        'demandeNouveauCheque': !exists(json, 'demandeNouveauCheque') ? undefined : json['demandeNouveauCheque'],
        'pasEmailPromotionnel': !exists(json, 'pasEmailPromotionnel') ? undefined : json['pasEmailPromotionnel'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'consentementCollecteDonnees': !exists(json, 'consentementCollecteDonnees') ? undefined : json['consentementCollecteDonnees'],
        'chequeReceptionForem': !exists(json, 'chequeReceptionForem') ? undefined : json['chequeReceptionForem'],
    };
}

export function PassportDriveDtoToJSON(value?: PassportDriveDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idpassportDrive': value.idpassportDrive,
        'idapprenant': value.idapprenant,
        'permis': value.permis,
        'idautoecole': value.idautoecole,
        'autoecole': value.autoecole,
        'remiseCheque': value.remiseCheque === undefined ? undefined : (value.remiseCheque === null ? null : value.remiseCheque.toISOString()),
        'inscriptionEffective': value.inscriptionEffective === undefined ? undefined : (value.inscriptionEffective === null ? null : value.inscriptionEffective.toISOString()),
        'datePermisTheorique': value.datePermisTheorique === undefined ? undefined : (value.datePermisTheorique === null ? null : value.datePermisTheorique.toISOString()),
        'idresultatPermisTheorique': value.idresultatPermisTheorique,
        'resultatPermisTheorique': value.resultatPermisTheorique,
        'datePermisPratique': value.datePermisPratique === undefined ? undefined : (value.datePermisPratique === null ? null : value.datePermisPratique.toISOString()),
        'idresultatPermisPratique': value.idresultatPermisPratique,
        'resultatPermisPratique': value.resultatPermisPratique,
        'demandeNouveauCheque': value.demandeNouveauCheque,
        'pasEmailPromotionnel': value.pasEmailPromotionnel,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'consentementCollecteDonnees': value.consentementCollecteDonnees,
        'chequeReceptionForem': value.chequeReceptionForem,
    };
}

