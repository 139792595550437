import { DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";

import { ExportGapStatApi, ExportGapStatsVacataireApi, RechercheExportGapSearch } from "../../../../api";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface IExportGapStatistiqueListProps {
  idExport: number;
}

export const ExportGapStatistiqueList: React.FunctionComponent<IExportGapStatistiqueListProps> = ({ idExport }) => {
  const { t } = useTl();
  const apiStat = useApiService(ExportGapStatApi);
  const apiStatVac = useApiService(ExportGapStatsVacataireApi);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [5],
    pageSize: 5,
    sortKeys: { HeureCpdateCe: "ASC" }
  });

  const searchFunctionStat = React.useCallback(
    (sObj?: RechercheExportGapSearch) => {
      sObj.idexport = idExport;
      return apiStat.exportGapStatBaseSearch({ RechercheExportGapSearch: sObj });
    },
    [apiStat, idExport]
  );

  const loadingStat = useSearchApi<any, any>({
    searchFunction: searchFunctionStat,
    tableState,
    initialSearch: true
  });

  const searchFunctionStatVac = React.useCallback(
    (sObj?: RechercheExportGapSearch) => {
      sObj.idexport = idExport;
      return apiStatVac.exportGapStatsVacataireBaseSearch({ RechercheExportGapSearch: sObj });
    },
    [apiStatVac, idExport]
  );

  const loadingStatVac = useSearchApi<any, any>({
    searchFunction: searchFunctionStatVac,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.GrilleCe),
        fieldName: "HeureCpdateCe"
      },
      {
        header: () => t(ETLCodes.RemplacantPlusCE),
        fieldName: "PlusRemplacantCe"
      },
      {
        header: () => t(ETLCodes.RemplacePlusCE),
        fieldName: "PlusRemplaceCe"
      },
      {
        header: () => t(ETLCodes.RempMoinsCE),
        fieldName: "MoinsCrdateCe"
      },
      {
        header: () => t(ETLCodes.Grille),
        fieldName: "HeureCpdateApp"
      },
      {
        header: () => t(ETLCodes.RemplacantPlusApp),
        fieldName: "PlusRemplacantApp"
      },
      {
        header: () => t(ETLCodes.RemplacaPlusApp),
        fieldName: "PlusRemplaceApp"
      },
      {
        header: () => t(ETLCodes.RemplaceMoinsApp),
        fieldName: "MoinsCrdateApp"
      }
    ],
    [t]
  );

  return (
    <>
      {idExport > 0 && (
        <FieldSet title={`${t(ETLCodes.Vacataires)}`}>
          <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loadingStat.loading} columns={columns} />
        </FieldSet>
      )}
      {idExport > 0 && (
        <FieldSet title={`${t(ETLCodes.NonVacataire)}`}>
          <DataTable
            dateFormat="dd-MM-yyyy"
            tableState={tableState}
            loading={loadingStatVac.loading}
            columns={columns}
          />
        </FieldSet>
      )}
    </>
  );
};
