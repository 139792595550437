/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RapportHopeParameterDataDto } from './RapportHopeParameterDataDto';
import {
    RapportHopeParameterDataDtoFromJSON,
    RapportHopeParameterDataDtoFromJSONTyped,
    RapportHopeParameterDataDtoToJSON,
} from './RapportHopeParameterDataDto';

/**
 * 
 * @export
 * @interface GenerateMultipleRapportHopeCommand
 */
export interface GenerateMultipleRapportHopeCommand {
    /**
     * 
     * @type {string}
     * @memberof GenerateMultipleRapportHopeCommand
     */
    reportName?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateMultipleRapportHopeCommand
     */
    entityIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof GenerateMultipleRapportHopeCommand
     */
    format?: string | null;
    /**
     * 
     * @type {Array<RapportHopeParameterDataDto>}
     * @memberof GenerateMultipleRapportHopeCommand
     */
    parameters?: Array<RapportHopeParameterDataDto> | null;
}

/**
 * Check if a given object implements the GenerateMultipleRapportHopeCommand interface.
 */
export function instanceOfGenerateMultipleRapportHopeCommand(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GenerateMultipleRapportHopeCommandFromJSON(json: any): GenerateMultipleRapportHopeCommand {
    return GenerateMultipleRapportHopeCommandFromJSONTyped(json, false);
}

export function GenerateMultipleRapportHopeCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateMultipleRapportHopeCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reportName': !exists(json, 'reportName') ? undefined : json['reportName'],
        'entityIds': !exists(json, 'entityIds') ? undefined : json['entityIds'],
        'format': !exists(json, 'format') ? undefined : json['format'],
        'parameters': !exists(json, 'parameters') ? undefined : (json['parameters'] === null ? null : (json['parameters'] as Array<any>).map(RapportHopeParameterDataDtoFromJSON)),
    };
}

export function GenerateMultipleRapportHopeCommandToJSON(value?: GenerateMultipleRapportHopeCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reportName': value.reportName,
        'entityIds': value.entityIds,
        'format': value.format,
        'parameters': value.parameters === undefined ? undefined : (value.parameters === null ? null : (value.parameters as Array<any>).map(RapportHopeParameterDataDtoToJSON)),
    };
}

