import { Button, ButtonGroup, Colors, Spinner, Tag } from "@blueprintjs/core";
import { getIn } from "formik";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { IFGContext, IFGCustomInputProps, useFGContext } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";

import { SiegeSocialSelectorDialog } from ".";
import { ClearButton } from "../..";
import { SiegeSocialSelectorApi } from "../../../api";
import { ERoutes } from "../../../AppRouter";
import { useAuth } from "../../../contexts";
import { useApiService } from "../../../hooks";
import { FGReadOnlyInput } from "../FGReadOnlyInput";

const InputContainer = styled.div`
  padding: 0.4rem 0 0 0.6rem;
`;

const StyledTag = styled(Tag)`
  & a {
    color: ${Colors.WHITE};
  }
`;

const NamesContainer = styled.div`
  > * + * {
    margin-left: 5px;
  }
`;

export interface ISiegeSocialSelectorFieldProps extends Omit<IFGCustomInputProps, "children"> {
  parentSiegeSocialIds?: number[];
  disabled?: boolean;
  allowMultipleSelection?: boolean;
  ref?: React.Ref<HTMLInputElement>;
  canAdd?: boolean;
  rightElement?: JSX.Element | ((ctx: IFGContext<any>) => JSX.Element);
}

export const SiegeSocialSelectorField: React.FunctionComponent<ISiegeSocialSelectorFieldProps> = ({
  name,
  parentSiegeSocialIds = [],
  label = null,
  disabled = false,
  className,
  allowMultipleSelection = false,
  canAdd = true,
  ref,
  rightElement,
  ...fgCustomInputProps
}) => {
  const { hasPermission } = useAuth();
  const ctx = useFGContext();
  const api = useApiService(SiegeSocialSelectorApi);

  const hasValue = React.useMemo(
    () =>
      (allowMultipleSelection && !isEmpty(getIn(ctx?.formik?.values, name))) ||
      (!allowMultipleSelection && !!getIn(ctx?.formik?.values, name)),
    [allowMultipleSelection, ctx?.formik?.values, name]
  );
  const [selectorDialogOpen, setSelectorDialogOpen] = React.useState(false);
  const [currentSelectedSiegeSocialIds, setSelectedSiegeSocialIds] = React.useState<number[]>([]);

  const { data: displayNames, isFetching: loadingDisplayNames } = useQuery(
    ["siegeSocial-display-names", currentSelectedSiegeSocialIds],
    async () => api.siegeSocialSelectorGetDisplayNames({ request_body: currentSelectedSiegeSocialIds })
  );

  const clearSelectedSiegeSocial = React.useCallback(() => {
    ctx?.formik?.setFieldValue(name, allowMultipleSelection ? [] : null, true);
    setSelectedSiegeSocialIds([]);
  }, [allowMultipleSelection, ctx?.formik, name]);

  React.useEffect(() => {
    const value = getIn(ctx?.formik?.values, name);
    if (value) {
      setSelectedSiegeSocialIds(isArray(value) ? value : [value]);
    } else {
      setSelectedSiegeSocialIds([]);
    }
  }, [ctx?.formik?.values, name]);

  const onSiegeSocialsSelected = React.useCallback(
    (selectedSiegeSocialIds?: number[]) => {
      if (selectedSiegeSocialIds?.length > 0) {
        ctx?.formik?.setFieldTouched(name);
        ctx?.formik?.setFieldValue(
          name,
          allowMultipleSelection ? selectedSiegeSocialIds : selectedSiegeSocialIds[0],
          true
        );
        setSelectedSiegeSocialIds(selectedSiegeSocialIds);
      }
    },
    [allowMultipleSelection, ctx?.formik, name]
  );

  const selectorProps = React.useMemo(
    () => ({
      excludedSiegeSocialIds: parentSiegeSocialIds,
      allowMultipleSelection,
      preSelectedIds: currentSelectedSiegeSocialIds
    }),
    [allowMultipleSelection, parentSiegeSocialIds, currentSelectedSiegeSocialIds]
  );

  const openSiegeSocialSelector = React.useCallback(() => {
    setSelectorDialogOpen(true);
  }, []);

  const getEditRoute = React.useCallback(
    (id: number) =>
      `#${ERoutes.siegeSocial}/${id}/detail/${hasPermission("SIEGESOCIAL", "RW", "DETAIL") ? "edit" : "view"}`,
    [hasPermission]
  );

  const removeSiegeSocialId = React.useCallback(
    (siegeSocialId: number) => {
      const newSiegeSocialIds = [...currentSelectedSiegeSocialIds];
      newSiegeSocialIds.splice(currentSelectedSiegeSocialIds.indexOf(siegeSocialId), 1);
      ctx?.formik?.setFieldTouched(name);
      ctx?.formik?.setFieldValue(name, newSiegeSocialIds, true);
      setSelectedSiegeSocialIds(newSiegeSocialIds);
    },
    [ctx?.formik, currentSelectedSiegeSocialIds, name]
  );

  const getSiegeSocialLink = React.useCallback(
    (id: number, name: string, tags: boolean) => {
      const link = hasPermission("SIEGESOCIAL", "R", "DETAIL") ? (
        <a href={getEditRoute(id)} target="_blank" rel="noreferrer" tabIndex={-1} key={id}>
          {name}
        </a>
      ) : (
        name
      );
      return tags ? (
        <StyledTag key={id} onRemove={() => removeSiegeSocialId(id)}>
          {link}
        </StyledTag>
      ) : (
        link
      );
    },
    [getEditRoute, hasPermission, removeSiegeSocialId]
  );

  const onClosed = React.useCallback(
    (selectedSiegeSocialIds?: number[]) => {
      onSiegeSocialsSelected(selectedSiegeSocialIds);
      setSelectorDialogOpen(false);
    },
    [onSiegeSocialsSelected]
  );

  return (
    <>
      <FGReadOnlyInput
        name={name}
        label={label}
        leftElement={
          <InputContainer>
            {loadingDisplayNames ? (
              <Spinner size={16} />
            ) : (
              <NamesContainer>
                {displayNames.map(dn => getSiegeSocialLink(dn.id, dn.text, allowMultipleSelection))}
              </NamesContainer>
            )}
          </InputContainer>
        }
        rightElement={
          <ButtonGroup>
            {hasValue && <ClearButton onClick={clearSelectedSiegeSocial} tabIndex={-1} disabled={disabled} />}
            <Button icon="people" minimal={true} onClick={openSiegeSocialSelector} disabled={disabled} tabIndex={-1} />
            {rightElement}
          </ButtonGroup>
        }
        {...fgCustomInputProps}
      />
      <SiegeSocialSelectorDialog
        onClosed={onClosed}
        onSiegeSocialSelected={onClosed}
        selectorDialogOpen={selectorDialogOpen}
        canAdd={canAdd}
        {...selectorProps}
      />
    </>
  );
};
