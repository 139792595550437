/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CarteIdentiteExistingUserDto,
} from '../models/index';
import {
    CarteIdentiteExistingUserDtoFromJSON,
    CarteIdentiteExistingUserDtoToJSON,
} from '../models/index';

export interface CarteIdentiteCheckExistingRequest {
    nationalNb?: string;
    nom?: string;
    prenom?: string;
    dateNaissance?: Date;
}

/**
 * 
 */
export class CarteIdentiteApi extends runtime.BaseAPI {

    /**
     */
    async carteIdentiteCheckExistingRaw(requestParameters: CarteIdentiteCheckExistingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CarteIdentiteExistingUserDto>> {
        const queryParameters: any = {};

        if (requestParameters.nationalNb !== undefined) {
            queryParameters['nationalNb'] = requestParameters.nationalNb;
        }

        if (requestParameters.nom !== undefined) {
            queryParameters['nom'] = requestParameters.nom;
        }

        if (requestParameters.prenom !== undefined) {
            queryParameters['prenom'] = requestParameters.prenom;
        }

        if (requestParameters.dateNaissance !== undefined) {
            queryParameters['dateNaissance'] = (requestParameters.dateNaissance as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/CarteIdentite/checkExisting`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CarteIdentiteExistingUserDtoFromJSON(jsonValue));
    }

    /**
     */
    async carteIdentiteCheckExisting(requestParameters: CarteIdentiteCheckExistingRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CarteIdentiteExistingUserDto> {
        const response = await this.carteIdentiteCheckExistingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async carteIdentiteDownloadRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/CarteIdentite/downloadBeid`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async carteIdentiteDownload(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.carteIdentiteDownloadRaw(initOverrides);
    }

}
