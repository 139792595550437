import * as React from "react";
import styled from "styled-components";
import { useTheme } from "../hooks";

const StyledSpan = styled.span<{ color: string, fontSize: number }>`
  display: flex;
  color: ${props => props.color};
  ${props => props.fontSize && ("font-size: " + props.fontSize + "pt !important")};
`;

export interface IErrorTextProps {
  text: string;
  fontSize?: number;
}

export const ErrorText: React.FunctionComponent<IErrorTextProps> = ({ text, fontSize }) => {
  const { theme } = useTheme();

  return <StyledSpan color={theme.dangerColor} fontSize={fontSize}>{text}</StyledSpan>;
};
