import * as React from "react";
import styled from "styled-components";
import { useTl } from "../hooks";
import { ETLCodes } from "../locales";

interface INumberShowerProps {
  ETLCode: ETLCodes;
  text: string;
}

const Container = styled.div`
  display: flex;
`;

const ETLCodeContainer = styled.div`
  font-weight: bold;
`;

export const NumberShower: React.FunctionComponent<INumberShowerProps> = ({ ETLCode, text }) => {
  const { t } = useTl();

  return (
    <Container>
      <ETLCodeContainer>{t(ETLCode)}:&nbsp;&nbsp;</ETLCodeContainer>
      {text}
    </Container>
  );
};
