import { Callout, NonIdealState } from "@blueprintjs/core";
import { format } from "date-fns";
import { FieldSet } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { AP_FINAL_KEY, CarouselComparer, DoublonsDataCard, useDoublonsStateContext } from "..";
import { ApprenantDoublonEditDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BooleanColumn, ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

const Container = styled.div`
  height: 70vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface IDoublonApprenantStatutApprenantProps {}

export const DoublonApprenantStatutApprenant: React.FunctionComponent<IDoublonApprenantStatutApprenantProps> = props => {
  const { t } = useTl();
  const { data, getSelectedIndex } = useDoublonsStateContext();

  const selectedIndex = React.useMemo(() => getSelectedIndex(`${AP_FINAL_KEY}.idapprenant`) ?? 0, [getSelectedIndex]);

  const apprenantContainer = React.useCallback(
    (index: number, nb: number) => {
      const apprenant: ApprenantDoublonEditDto = data?.apprenants[index];
      if (!apprenant) return;

      return (
        <FieldSet
          key={apprenant.idapprenant}
          title={
            <ViewButton
              text={t(ETLCodes.ApprenantNb, { nb: nb, id: apprenant.idapprenant })}
              onClick={() => window.open(`#${ERoutes.apprenant}/${apprenant.idapprenant}/signaletique/view`, "_blank")}
            />
          }
          titlePosition="center"
        >
          <DoublonsDataCard
            fieldPath={`apprenants[${index}].currentStatutApprenant`}
            finalEntityKey={AP_FINAL_KEY}
            displaySelectedValue={false}
            extValue={
              apprenant.currentStatutApprenant ? (
                <InformationContainer>
                  <div>
                    {t(ETLCodes.DateStatut)}: {format(apprenant.currentStatutApprenant.dateStatut, "dd-MM-yyyy")}
                  </div>
                  <div>
                    {t(ETLCodes.CategorieStatut)}: {apprenant.currentStatutApprenant.categorieStatutSocial}
                  </div>
                  <div>
                    {t(ETLCodes.Statut)}: {apprenant.currentStatutApprenant.statutSocial}
                  </div>
                  <div>
                    {t(ETLCodes.PostFormation)}: <BooleanColumn value={apprenant.currentStatutApprenant.postSortie} />
                  </div>
                </InformationContainer>
              ) : (
                <NonIdealState title={t(ETLCodes.PasDeStatutEnCours)} />
              )
            }
            label={`${t(ETLCodes.StatutActuel)}`}
            interactive={!!apprenant.currentStatutApprenant}
          />
        </FieldSet>
      );
    },
    [data?.apprenants, t]
  );

  return (
    <Container>
      <Callout
        intent="warning"
        title={t(ETLCodes.VeuillezChoisirStatutActuel)}
        style={{ marginBottom: "1rem" }}
        icon="info-sign"
      >
        {t(ETLCodes.DoublonApprenantStatutsCumules)}
      </Callout>
      <CarouselComparer leftElement={apprenantContainer(selectedIndex, selectedIndex + 1)}>
        {data?.apprenants?.length > 1 &&
          data?.apprenants
            ?.filter((a, i) => i !== selectedIndex)
            ?.map((a, i) => apprenantContainer(i + (i >= selectedIndex ? 1 : 0), i + (i >= selectedIndex ? 2 : 1)))}
      </CarouselComparer>
    </Container>
  );
};
