/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbServiceTutelleLocaliteGridDto
 */
export interface FcbServiceTutelleLocaliteGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbServiceTutelleLocaliteGridDto
     */
    idcodePostal?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbServiceTutelleLocaliteGridDto
     */
    codePostal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbServiceTutelleLocaliteGridDto
     */
    localite?: string | null;
}

/**
 * Check if a given object implements the FcbServiceTutelleLocaliteGridDto interface.
 */
export function instanceOfFcbServiceTutelleLocaliteGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbServiceTutelleLocaliteGridDtoFromJSON(json: any): FcbServiceTutelleLocaliteGridDto {
    return FcbServiceTutelleLocaliteGridDtoFromJSONTyped(json, false);
}

export function FcbServiceTutelleLocaliteGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbServiceTutelleLocaliteGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcodePostal': !exists(json, 'idcodePostal') ? undefined : json['idcodePostal'],
        'codePostal': !exists(json, 'codePostal') ? undefined : json['codePostal'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
    };
}

export function FcbServiceTutelleLocaliteGridDtoToJSON(value?: FcbServiceTutelleLocaliteGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcodePostal': value.idcodePostal,
        'codePostal': value.codePostal,
        'localite': value.localite,
    };
}

