/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbPresenceFormateurDto
 */
export interface FcbPresenceFormateurDto {
    /**
     * 
     * @type {number}
     * @memberof FcbPresenceFormateurDto
     */
    idclasseHoraire?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbPresenceFormateurDto
     */
    idformateur?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbPresenceFormateurDto
     */
    formateur?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbPresenceFormateurDto
     */
    principal?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbPresenceFormateurDto
     */
    statut?: string | null;
}

/**
 * Check if a given object implements the FcbPresenceFormateurDto interface.
 */
export function instanceOfFcbPresenceFormateurDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbPresenceFormateurDtoFromJSON(json: any): FcbPresenceFormateurDto {
    return FcbPresenceFormateurDtoFromJSONTyped(json, false);
}

export function FcbPresenceFormateurDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbPresenceFormateurDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idclasseHoraire': !exists(json, 'idclasseHoraire') ? undefined : json['idclasseHoraire'],
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'formateur': !exists(json, 'formateur') ? undefined : json['formateur'],
        'principal': !exists(json, 'principal') ? undefined : json['principal'],
        'statut': !exists(json, 'statut') ? undefined : json['statut'],
    };
}

export function FcbPresenceFormateurDtoToJSON(value?: FcbPresenceFormateurDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idclasseHoraire': value.idclasseHoraire,
        'idformateur': value.idformateur,
        'formateur': value.formateur,
        'principal': value.principal,
        'statut': value.statut,
    };
}

