/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SiegeLieuSuiviGridDto
 */
export interface SiegeLieuSuiviGridDto {
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviGridDto
     */
    idsiegeSocialLieuFormationSuivi?: number;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviGridDto
     */
    iduserService?: number;
    /**
     * 
     * @type {Date}
     * @memberof SiegeLieuSuiviGridDto
     */
    dateSuivi?: Date;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviGridDto
     */
    idsiegeSocial?: number;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviGridDto
     */
    idlieuFormation?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeLieuSuiviGridDto
     */
    siegeSocial?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeLieuSuiviGridDto
     */
    lieuFormation?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviGridDto
     */
    idtypeSuivi?: number;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviGridDto
     */
    idmode?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviGridDto
     */
    idlieuRencontre?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeLieuSuiviGridDto
     */
    contenu?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SiegeLieuSuiviGridDto
     */
    hiddenContent?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviGridDto
     */
    idservice?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeLieuSuiviGridDto
     */
    service?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeLieuSuiviGridDto
     */
    centre?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeLieuSuiviGridDto
     */
    userService?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviGridDto
     */
    idencodeur?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeLieuSuiviGridDto
     */
    encodeur?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeLieuSuiviGridDto
     */
    idrealisateurSuivi?: number;
    /**
     * 
     * @type {string}
     * @memberof SiegeLieuSuiviGridDto
     */
    realisateurSuivi?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeLieuSuiviGridDto
     */
    typeSuivi?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SiegeLieuSuiviGridDto
     */
    actionDateEcheance?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof SiegeLieuSuiviGridDto
     */
    actionDateRealisation?: Date | null;
}

/**
 * Check if a given object implements the SiegeLieuSuiviGridDto interface.
 */
export function instanceOfSiegeLieuSuiviGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SiegeLieuSuiviGridDtoFromJSON(json: any): SiegeLieuSuiviGridDto {
    return SiegeLieuSuiviGridDtoFromJSONTyped(json, false);
}

export function SiegeLieuSuiviGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiegeLieuSuiviGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsiegeSocialLieuFormationSuivi': !exists(json, 'idsiegeSocialLieuFormationSuivi') ? undefined : json['idsiegeSocialLieuFormationSuivi'],
        'iduserService': !exists(json, 'iduserService') ? undefined : json['iduserService'],
        'dateSuivi': !exists(json, 'dateSuivi') ? undefined : (new Date(json['dateSuivi'])),
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'siegeSocial': !exists(json, 'siegeSocial') ? undefined : json['siegeSocial'],
        'lieuFormation': !exists(json, 'lieuFormation') ? undefined : json['lieuFormation'],
        'idtypeSuivi': !exists(json, 'idtypeSuivi') ? undefined : json['idtypeSuivi'],
        'idmode': !exists(json, 'idmode') ? undefined : json['idmode'],
        'idlieuRencontre': !exists(json, 'idlieuRencontre') ? undefined : json['idlieuRencontre'],
        'contenu': !exists(json, 'contenu') ? undefined : json['contenu'],
        'hiddenContent': !exists(json, 'hiddenContent') ? undefined : json['hiddenContent'],
        'idservice': !exists(json, 'idservice') ? undefined : json['idservice'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'centre': !exists(json, 'centre') ? undefined : json['centre'],
        'userService': !exists(json, 'userService') ? undefined : json['userService'],
        'idencodeur': !exists(json, 'idencodeur') ? undefined : json['idencodeur'],
        'encodeur': !exists(json, 'encodeur') ? undefined : json['encodeur'],
        'idrealisateurSuivi': !exists(json, 'idrealisateurSuivi') ? undefined : json['idrealisateurSuivi'],
        'realisateurSuivi': !exists(json, 'realisateurSuivi') ? undefined : json['realisateurSuivi'],
        'typeSuivi': !exists(json, 'typeSuivi') ? undefined : json['typeSuivi'],
        'actionDateEcheance': !exists(json, 'actionDateEcheance') ? undefined : (json['actionDateEcheance'] === null ? null : new Date(json['actionDateEcheance'])),
        'actionDateRealisation': !exists(json, 'actionDateRealisation') ? undefined : (json['actionDateRealisation'] === null ? null : new Date(json['actionDateRealisation'])),
    };
}

export function SiegeLieuSuiviGridDtoToJSON(value?: SiegeLieuSuiviGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsiegeSocialLieuFormationSuivi': value.idsiegeSocialLieuFormationSuivi,
        'iduserService': value.iduserService,
        'dateSuivi': value.dateSuivi === undefined ? undefined : (value.dateSuivi.toISOString()),
        'idsiegeSocial': value.idsiegeSocial,
        'idlieuFormation': value.idlieuFormation,
        'siegeSocial': value.siegeSocial,
        'lieuFormation': value.lieuFormation,
        'idtypeSuivi': value.idtypeSuivi,
        'idmode': value.idmode,
        'idlieuRencontre': value.idlieuRencontre,
        'contenu': value.contenu,
        'hiddenContent': value.hiddenContent,
        'idservice': value.idservice,
        'service': value.service,
        'centre': value.centre,
        'userService': value.userService,
        'idencodeur': value.idencodeur,
        'encodeur': value.encodeur,
        'idrealisateurSuivi': value.idrealisateurSuivi,
        'realisateurSuivi': value.realisateurSuivi,
        'typeSuivi': value.typeSuivi,
        'actionDateEcheance': value.actionDateEcheance === undefined ? undefined : (value.actionDateEcheance === null ? null : value.actionDateEcheance.toISOString()),
        'actionDateRealisation': value.actionDateRealisation === undefined ? undefined : (value.actionDateRealisation === null ? null : value.actionDateRealisation.toISOString()),
    };
}

