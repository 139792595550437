/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CalendrierGlobalSearch,
  CalendrierSearch,
  FcbCalendrierDto,
  FcbCalendrierGridDtoPaginatedResults,
  FcbRecherchecalendrierClasseDtoPaginatedResults,
  FcbRecherchecalendrierFormateurDtoPaginatedResults,
  FcbRecherchecalendrierVisiteDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    CalendrierGlobalSearchFromJSON,
    CalendrierGlobalSearchToJSON,
    CalendrierSearchFromJSON,
    CalendrierSearchToJSON,
    FcbCalendrierDtoFromJSON,
    FcbCalendrierDtoToJSON,
    FcbCalendrierGridDtoPaginatedResultsFromJSON,
    FcbCalendrierGridDtoPaginatedResultsToJSON,
    FcbRecherchecalendrierClasseDtoPaginatedResultsFromJSON,
    FcbRecherchecalendrierClasseDtoPaginatedResultsToJSON,
    FcbRecherchecalendrierFormateurDtoPaginatedResultsFromJSON,
    FcbRecherchecalendrierFormateurDtoPaginatedResultsToJSON,
    FcbRecherchecalendrierVisiteDtoPaginatedResultsFromJSON,
    FcbRecherchecalendrierVisiteDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface CalendrierBaseSearchRequest {
    CalendrierSearch?: CalendrierSearch;
}

export interface CalendrierDeleteRequest {
    id?: number;
}

export interface CalendrierGetRequest {
    id?: number;
}

export interface CalendrierGetDisplayNameRequest {
    id?: number;
}

export interface CalendrierGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface CalendrierGetSelectItemsRequest {
    searchField: string;
}

export interface CalendrierSaveRequest {
    FcbCalendrierDto?: FcbCalendrierDto;
}

export interface CalendrierSearchClassesRequest {
    CalendrierGlobalSearch?: CalendrierGlobalSearch;
}

export interface CalendrierSearchFormateursRequest {
    CalendrierGlobalSearch?: CalendrierGlobalSearch;
}

export interface CalendrierSearchVisitesRequest {
    CalendrierGlobalSearch?: CalendrierGlobalSearch;
}

/**
 * 
 */
export class CalendrierApi extends runtime.BaseAPI {

    /**
     */
    async calendrierBaseSearchRaw(requestParameters: CalendrierBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbCalendrierGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Calendrier/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CalendrierSearchToJSON(requestParameters.CalendrierSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbCalendrierGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async calendrierBaseSearch(requestParameters: CalendrierBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbCalendrierGridDtoPaginatedResults> {
        const response = await this.calendrierBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async calendrierDeleteRaw(requestParameters: CalendrierDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Calendrier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async calendrierDelete(requestParameters: CalendrierDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.calendrierDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async calendrierGetRaw(requestParameters: CalendrierGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbCalendrierDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Calendrier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbCalendrierDtoFromJSON(jsonValue));
    }

    /**
     */
    async calendrierGet(requestParameters: CalendrierGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbCalendrierDto> {
        const response = await this.calendrierGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async calendrierGetDisplayNameRaw(requestParameters: CalendrierGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Calendrier/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async calendrierGetDisplayName(requestParameters: CalendrierGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.calendrierGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async calendrierGetSearchCriteriasRaw(requestParameters: CalendrierGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Calendrier/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async calendrierGetSearchCriterias(requestParameters: CalendrierGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.calendrierGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async calendrierGetSelectItemsRaw(requestParameters: CalendrierGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling calendrierGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Calendrier/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async calendrierGetSelectItems(requestParameters: CalendrierGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.calendrierGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async calendrierSaveRaw(requestParameters: CalendrierSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbCalendrierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Calendrier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbCalendrierDtoToJSON(requestParameters.FcbCalendrierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbCalendrierDtoFromJSON(jsonValue));
    }

    /**
     */
    async calendrierSave(requestParameters: CalendrierSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbCalendrierDto> {
        const response = await this.calendrierSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async calendrierSearchClassesRaw(requestParameters: CalendrierSearchClassesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRecherchecalendrierClasseDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Calendrier/SearchClasses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CalendrierGlobalSearchToJSON(requestParameters.CalendrierGlobalSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRecherchecalendrierClasseDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async calendrierSearchClasses(requestParameters: CalendrierSearchClassesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRecherchecalendrierClasseDtoPaginatedResults> {
        const response = await this.calendrierSearchClassesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async calendrierSearchFormateursRaw(requestParameters: CalendrierSearchFormateursRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRecherchecalendrierFormateurDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Calendrier/SearchFormateurs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CalendrierGlobalSearchToJSON(requestParameters.CalendrierGlobalSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRecherchecalendrierFormateurDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async calendrierSearchFormateurs(requestParameters: CalendrierSearchFormateursRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRecherchecalendrierFormateurDtoPaginatedResults> {
        const response = await this.calendrierSearchFormateursRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async calendrierSearchVisitesRaw(requestParameters: CalendrierSearchVisitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRecherchecalendrierVisiteDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Calendrier/SearchVisites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CalendrierGlobalSearchToJSON(requestParameters.CalendrierGlobalSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRecherchecalendrierVisiteDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async calendrierSearchVisites(requestParameters: CalendrierSearchVisitesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRecherchecalendrierVisiteDtoPaginatedResults> {
        const response = await this.calendrierSearchVisitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
