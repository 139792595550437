import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router";

import { ApprenantSuiviDetailPage } from "./ApprenantSuiviDetailPage";
import { SuiviApprenantSearchPage } from "./SuiviApprenantSearchPage";

interface IApprenantSuiviProps {
  baseRoute: string;
}

export const ApprenantSuivi: React.FunctionComponent<IApprenantSuiviProps> = ({ baseRoute }) => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}`} exact>
        <SuiviApprenantSearchPage baseRoute={baseRoute} />
      </Route>
      <Route path={`${match.path}/:idSuivi/:state/walter`} exact>
        <ApprenantSuiviDetailPage baseRoute={baseRoute} />
      </Route>
      <Route path={`${match.path}/:idSuivi/:state`} exact>
        <ApprenantSuiviDetailPage baseRoute={baseRoute} />
      </Route>
    </Switch>
  );
};
