import { Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  ButtonContainer,
  DataTable,
  FieldSet,
  IDataTableColumn,
  useApiEffect,
  useGridState,
  useSearchApi
} from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ClasseApi, ClasseMatiereApi, ClasseMatiereSearch, FcbClasseMatiereGridDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  AddButton,
  BooleanColumn,
  CutButton,
  DeleteButton,
  EditButton,
  StyledError,
  ViewButton
} from "../../../../../components";
import { useAuth, useDialog } from "../../../../../contexts";
import { useApiService, useManageError, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IClasseMatiereListPageProps {}

export const ClasseMatiereListPage: React.FunctionComponent<IClasseMatiereListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ClasseMatiereApi);
  const classeApi = useApiService(ClasseApi);
  const { id } = useParams<{ id: string }>();
  const { showDialog } = useDialog();
  const { manageError } = useManageError();
  const tableState = useGridState({
    serverMode: true,
    enablePagination: false,
    enableFilter: false,
    pageSize: 9999
  });

  const { totalCount } = tableState;
  const [genre] = useApiEffect(() => classeApi.classeGetClasseGenre({ id: +id }), [id]);
  const [showReinitBtn, setShowReinitBtn] = React.useState(false);
  const [reinitLoading, setReinitLoading] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [lastObj, setLastObj] = React.useState(null);
  const { getCurrentPermission } = useAuth();
  const dialogContext = useDialog();

  const searchFunction = React.useCallback(
    (sObj?: ClasseMatiereSearch) => {
      sObj.idClasse = +id;
      setLastObj(sObj);
      return api.classeMatiereBaseSearch({ ClasseMatiereSearch: sObj });
    },
    [api, id]
  );

  const { loading, search } = useSearchApi({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const canDelete = React.useMemo(() => getCurrentPermission()?.permission === "RWD" && genre === "S", [
    genre,
    getCurrentPermission
  ]);

  const deleteClasseMatiere = React.useCallback(
    (idclasseMatiere: number) => {
      dialogContext.showDialog({
        message: t(ETLCodes.DeleteConfirmationMessage),
        onConfirmed: () => {
          setDeleteLoading(true);
          api
            .classeMatiereDelete({ id: idclasseMatiere })
            .then(() => {
              setDeleteLoading(false);
              search(lastObj);
            })
            .catch(e => {
              manageError(e);
              setDeleteLoading(false);
            });
        }
      });
    },
    [api, dialogContext, lastObj, manageError, search, t]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbClasseMatiereGridDto) => (
          <ButtonContainer>
            {// TODO: Check user rights
            "NA".includes(genre) && (
              <CutButton
                minimal={true}
                onClick={() => history.push(`${ERoutes.classe}/${id}/matiere/${row.idclasseMatiere}/cut`)}
              />
            )}
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.classe}/${id}/matiere/${row.idclasseMatiere}/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.classe}/${id}/matiere/${row.idclasseMatiere}/edit`)}
            />
            {canDelete ? (
              <DeleteButton
                tooltipText={row.hasHoraires ? t(ETLCodes.CannotDeleteMatiereWithHoraires) : null}
                minimal={true}
                loading={deleteLoading}
                onDelete={
                  row.hasHoraires
                    ? null
                    : () => {
                        deleteClasseMatiere(row.idclasseMatiere);
                      }
                }
              />
            ) : (
              <></>
            )}
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code"
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "description"
      },
      {
        header: () => t(ETLCodes.Niveau),
        fieldName: "niveau",
        render: (row: FcbClasseMatiereGridDto) =>
          row.niveau && row.niveau + (row.niveau > 0 && !!row.matiereParent ? ` (${row.matiereParent})` : "")
      },
      {
        header: () => t(ETLCodes.Heures),
        fieldName: "nbheures"
      },
      {
        header: () => t(ETLCodes.TJ),
        fieldName: "travail"
      },
      {
        header: () => t(ETLCodes.Examen),
        fieldName: "examen"
      },
      {
        header: () => t(ETLCodes.Reussite),
        fieldName: "pcReussite",
        render: (row: FcbClasseMatiereGridDto) => (!!row.pcReussite ? row.pcReussite + " %" : "")
      },
      {
        header: () => t(ETLCodes.Local),
        fieldName: "local"
      },
      {
        header: () => t(ETLCodes.Formateur),
        fieldName: "formateur"
      },
      {
        header: () => t(ETLCodes.Contrat),
        fieldName: "contrat"
      },
      {
        header: () => t(ETLCodes.TypeProjet),
        fieldName: "projetFinancement"
      },
      {
        header: () => t(ETLCodes.UAA),
        fieldName: "uaa",
        alignment: "center",
        autoFitContent: true,
        render: (row: FcbClasseMatiereGridDto) => <BooleanColumn value={row.uaa} />
      },
      {
        header: () => t(ETLCodes.VisibleBulletin),
        fieldName: "isVisibleBulletin",
        alignment: "center",
        autoFitContent: true,
        render: (row: FcbClasseMatiereGridDto) => <BooleanColumn value={row.isVisibleBulletin} />
      },
      {
        header: () => t(ETLCodes.Sommative),
        fieldName: "sommative",
        alignment: "center",
        autoFitContent: true,
        render: (row: FcbClasseMatiereGridDto) => <BooleanColumn value={row.sommative} />
      }
    ],
    [canDelete, deleteClasseMatiere, deleteLoading, genre, history, id, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.classe}/${id}/matiere/0/edit`);
  }, [history, id]);

  const [classe] = useApiEffect(() => classeApi.classeGet({ id: +id }), [+id]);

  const [differentHours] = useApiEffect(
    () => api.classeMatiereCheckHeuresReferentiel({ idClasse: +id, idReferentiel: classe?.idreferentiel ?? 0 }),
    [id, classe]
  );

  const [original] = useApiEffect(
    () => api.classeMatiereCheckOriginality({ idClasse: +id, idReferentiel: classe?.idreferentiel ?? 0 }),
    [id, classe]
  );

  const [special, setSpecial] = React.useState(false);

  const reinitMatieres = React.useCallback(() => {
    showDialog({
      message: t(ETLCodes.ReinitConfirmMessage),
      onConfirmed: async () => {
        try {
          setReinitLoading(true);
          await api.classeMatiereReinit({ idClasse: +id, idReferentiel: classe?.idreferentiel });
          setReinitLoading(false);
          search(lastObj);
          setShowReinitBtn(false);
        } catch (e) {
          setReinitLoading(false);
        }
      }
    });
  }, [api, classe, id, lastObj, search, showDialog, t]);

  React.useEffect(() => {
    if (classe) {
      setSpecial(classe.genre === "S");
      if (classe.genre !== "S" && original) {
        setShowReinitBtn(!original.isValid);
      }
    }
  }, [original, classe]);

  const getReinitBtn = React.useCallback(() => {
    return showReinitBtn
      ? [
          {
            icon: "reset" as "reset",
            text: t(ETLCodes.ReinitMatieres),
            onClick: reinitMatieres,
            loading: loading || reinitLoading,
            disabled: !classe
          }
        ]
      : [];
  }, [classe, loading, reinitLoading, reinitMatieres, showReinitBtn, t]);

  const openAssignMultiple = React.useCallback(() => {
    history.push(`${ERoutes.classe}/${id}/matiere/edit/multiple`);
  }, [history, id]);

  const buttons = React.useMemo(
    () =>
      [
        ...getReinitBtn(),
        {
          icon: IconNames.ADD_TO_ARTIFACT,
          text: t(ETLCodes.AssignerPlusieursMatieres),
          onClick: openAssignMultiple,
          loading: loading,
          disabled: !classe
        }
      ] as any,
    [classe, getReinitBtn, loading, openAssignMultiple, t]
  );

  return (
    <>
      {+id > 0 && (
        <FieldSet
          title={t(ETLCodes.TableResults, { count: totalCount })}
          rightElement={
            special && (
              <>
                <AddButton
                  onClick={e => {
                    e.stopPropagation();
                    onAddItem();
                  }}
                  text={t(ETLCodes.General_Add)}
                  intent={Intent.PRIMARY}
                />
              </>
            )
          }
          buttons={buttons}
        >
          <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
          <StyledError
            show={totalCount > 0 && !differentHours?.isValid}
            message={t(ETLCodes.NbHoursNotCorresponding)}
          ></StyledError>
        </FieldSet>
      )}
    </>
  );
};
