import { Classes, IconName, MaybeElement } from "@blueprintjs/core";
import { FGCustomPanel, FormGenerator, IFGContext, IFormGeneratorProps, Loading } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { useAuth } from "../contexts";
import { useTl } from "../hooks";
import { ETLCodes } from "../locales";
import {
  CancelButton,
  DeleteButton,
  DuplicateButton,
  EditButton,
  IDeleteButtonProps,
  SaveButton
} from "./applicationButtons";
import { ButtonsBloc, InlineButtonContainer } from "./applicationButtons/ButtonContainerStyles";
import { FGRouteDirtyChecker } from "./formGenerator/FGRouteDirtyChecker";

interface IFormDeleteButtonProps extends Omit<IDeleteButtonProps, "onClick" | "minimal"> {
  icon?: IconName | MaybeElement;
}

interface ISmallFormGeneratorProps<T> extends Omit<IFormGeneratorProps<T>, "children"> {
  loading?: boolean;
  onEdit?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
  deleting?: boolean;
  deleteBtnDisabled?: boolean;
  saving?: boolean;
  showDeleteButton?: boolean;
  editMode?: boolean;
  hideButtons?: boolean;
  cancelEdit?: boolean;
  enableDirtyCheck?: boolean;
  editable?: boolean;
  canCheckRights?: boolean;
  rightSaveButton?: boolean;
  onHardDelete?: () => void;
  hardDeleting?: boolean;
  forceEnableSave?: boolean;
  showDuplicateButton?: boolean;
  onDuplicate?: () => void;
  additionalRightButtons?: (ctx: IFGContext<any>) => JSX.Element;
  deleteButtonProps?: IFormDeleteButtonProps;
  customDeleteButtonProps?: IFormDeleteButtonProps;
  specificRights?: { module: string; tab?: string; rights?: "R" | "RW" | "RWD" };
  disableSaveButton?: boolean;
}

const StyledForm = styled.div`
  & .${Classes.FORM_GROUP} {
    margin: 0 0 2px !important;
  }

  & .${Classes.CONTROL} {
    margin-bottom: 0 !important;
  }
`;

export function SmallFormGenerator<T>(props: React.PropsWithChildren<ISmallFormGeneratorProps<T>>) {
  const {
    editable = true,
    children,
    loading = false,
    showDeleteButton = false,
    deleting,
    onDelete,
    deleteBtnDisabled = false,
    onEdit = () => {},
    onCancel,
    saving,
    editMode,
    minLabelWidth = 180,
    labelAlignment = "right",
    fill = true,
    showColons = true,
    inline = true,
    boldLabel = true,
    hideButtons = false,
    enableDirtyCheck = true,
    cancelEdit = false,
    validationErrors = {},
    canCheckRights = true,
    rightSaveButton = false,
    onHardDelete,
    hardDeleting,
    forceEnableSave = false,
    showDuplicateButton = false,
    onDuplicate,
    additionalRightButtons,
    deleteButtonProps,
    customDeleteButtonProps,
    specificRights,
    disableSaveButton = false,
    ...otherProps
  } = props;
  const [innerEditMode, setInnerEditMode] = React.useState(editMode);

  React.useEffect(() => {
    setInnerEditMode(editMode);
  }, [editMode]);

  const { getCurrentPermission, hasPermission } = useAuth();

  const readOnly = React.useMemo(() => canCheckRights && getCurrentPermission()?.permission === "R", [
    canCheckRights,
    getCurrentPermission
  ]);

  const canDelete = React.useMemo(
    () =>
      !!specificRights
        ? hasPermission(specificRights.module, specificRights.rights ?? "RWD", specificRights.tab)
        : canCheckRights && getCurrentPermission()?.permission === "RWD",
    [canCheckRights, getCurrentPermission, hasPermission, specificRights]
  );

  const { t, tUnsafe } = useTl();

  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isSoftDeleting, setIsHardDeleting] = React.useState(false);

  const editCallback = React.useCallback(() => {
    setInnerEditMode(true);
    if (onEdit) {
      onEdit();
    }
  }, [onEdit]);

  const deleteCallback = React.useCallback(() => {
    setIsDeleting(true);
    if (onDelete) {
      onDelete();
    }
  }, [onDelete]);

  const hardDeleteCallback = React.useCallback(() => {
    setIsHardDeleting(true);
    if (onHardDelete) {
      onHardDelete();
    }
  }, [onHardDelete]);

  return (
    <StyledForm>
      <Loading isLoading={loading}>
        {(props?.initialValues || props.formik?.initialValues) && (
          <FormGenerator
            editMode={innerEditMode && !readOnly}
            minLabelWidth={minLabelWidth}
            labelAlignment={labelAlignment}
            fill={fill}
            inline={inline}
            boldLabel={boldLabel}
            showColons={showColons}
            validationErrors={validationErrors}
            trim={true}
            touchOnSubmit
            translateFn={tUnsafe}
            {...otherProps}
          >
            {enableDirtyCheck && (
              <FGRouteDirtyChecker
                isDelete={isDeleting || isSoftDeleting}
                isViewMode={!innerEditMode}
                enableDirtyCheck={enableDirtyCheck}
              />
            )}
            {children}
            {!hideButtons && (
              <FGCustomPanel>
                {ctx => (
                  <InlineButtonContainer>
                    <ButtonsBloc>
                      {customDeleteButtonProps && (
                        <DeleteButton {...customDeleteButtonProps} minimal={false} specificRights={specificRights} />
                      )}
                      {showDeleteButton && onDelete && innerEditMode && canDelete ? (
                        <DeleteButton
                          {...deleteButtonProps}
                          minimal={false}
                          loading={deleting}
                          onDelete={deleteCallback}
                          disabled={deleteBtnDisabled}
                          specificRights={specificRights}
                        />
                      ) : (
                        <></>
                      )}
                      {onHardDelete && innerEditMode && (
                        <DeleteButton
                          minimal={false}
                          loading={hardDeleting}
                          onDelete={hardDeleteCallback}
                          text={t(ETLCodes.HardDelete)}
                          specificRights={specificRights}
                        />
                      )}
                      {rightSaveButton &&
                        (innerEditMode ? (
                          <SaveButton
                            minimal={false}
                            loading={saving}
                            disabled={!ctx.formik.dirty}
                            specificRights={specificRights}
                          />
                        ) : (
                          editable && !readOnly && <EditButton minimal={false} onClick={editCallback}></EditButton>
                        ))}
                    </ButtonsBloc>
                    <ButtonsBloc>
                      {!onCancel && cancelEdit && innerEditMode && (
                        <CancelButton minimal={false} onClick={() => setInnerEditMode(false)} />
                      )}
                      {onCancel && <CancelButton minimal={false} onClick={onCancel} />}
                      {showDuplicateButton ? (
                        <DuplicateButton minimal={false} onClick={onDuplicate}></DuplicateButton>
                      ) : (
                        <></>
                      )}
                      {innerEditMode ? (
                        <SaveButton
                          minimal={false}
                          loading={saving}
                          disabled={(!ctx.formik.dirty && !forceEnableSave) || disableSaveButton}
                          specificRights={specificRights}
                        />
                      ) : (
                        editable && !readOnly && <EditButton minimal={false} onClick={editCallback}></EditButton>
                      )}
                      {!!additionalRightButtons ? additionalRightButtons(ctx) : null}
                    </ButtonsBloc>
                  </InlineButtonContainer>
                )}
              </FGCustomPanel>
            )}
          </FormGenerator>
        )}
      </Loading>
    </StyledForm>
  );
}
