import { Classes, Dialog } from "@blueprintjs/core";
import { FieldGroup } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";

import { ContratFichierApi, ContratFichierUploadDto, ContratFichierUploadDtoFromJSON } from "../../../../../api";
import { FGWalterFileInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

const StyledDialog = styled(Dialog)`
  width: 600px;
  height: auto;
  background-color: white;
`;

export interface IContratDocumentUploadDialogProps {
  idcontrat: number;
  dialogOpen: boolean;
  onClose: (refresh?: boolean) => void;
}

export const ContratDocumentUploadDialog: React.FunctionComponent<IContratDocumentUploadDialogProps> = ({
  idcontrat,
  dialogOpen,
  onClose
}) => {
  const { t } = useTl();
  const api = useApiService(ContratFichierApi);

  const { data, loading, saveItem, saving, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () => {
          return ContratFichierUploadDtoFromJSON({ idcontrat });
        },
        saveApiFn: async (d: ContratFichierUploadDto) => {
          return api.contratFichierUploadContratFichier({ ContratFichierUploadDto: d });
        },
        onSaved: () => onClose(true)
      }),
      [api, idcontrat, onClose]
    )
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape({
        idtypeContratFichier: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        file: Yup.object()
          .nullable()
          .shape({
            fileName: Yup.string()
              .nullable()
              .required(t(ETLCodes.Required)),
            contentB64: Yup.string()
              .nullable()
              .required(t(ETLCodes.Required))
          })
      }),
    [t]
  );

  const [typesContratFichier, tLoading] = useReferential(a => a.referentialGetTypesContratFichier());

  return (
    <StyledDialog title={t(ETLCodes.UploadFichier)} isOpen={dialogOpen} onClose={() => onClose(false)}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose(false)}
          showDeleteButton={false}
          saving={saving}
          loading={loading}
          validationErrors={validationErrors}
        >
          <FieldGroup>
            <FGWalterFileInput name="file" label={t(ETLCodes.Fichier)} requiredMark />
            <FGWalterSelectInput
              name="idtypeContratFichier"
              label={t(ETLCodes.Type)}
              items={typesContratFichier}
              loading={tLoading}
            />
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
