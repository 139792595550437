/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbExportAccesPermissionDto,
  FcbExportCentrePermissionDto,
  FcbExportDto,
  FcbExportModulePermissionDto,
  FcbExportPermissionsDto,
  FcbRechercheexportDtoPaginatedResults,
  FilterCriteriaInfo,
  RechercheexportSearch,
  SelectItem,
} from '../models/index';
import {
    FcbExportAccesPermissionDtoFromJSON,
    FcbExportAccesPermissionDtoToJSON,
    FcbExportCentrePermissionDtoFromJSON,
    FcbExportCentrePermissionDtoToJSON,
    FcbExportDtoFromJSON,
    FcbExportDtoToJSON,
    FcbExportModulePermissionDtoFromJSON,
    FcbExportModulePermissionDtoToJSON,
    FcbExportPermissionsDtoFromJSON,
    FcbExportPermissionsDtoToJSON,
    FcbRechercheexportDtoPaginatedResultsFromJSON,
    FcbRechercheexportDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    RechercheexportSearchFromJSON,
    RechercheexportSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface ExportBaseSearchRequest {
    RechercheexportSearch?: RechercheexportSearch;
}

export interface ExportDeleteRequest {
    id?: number;
}

export interface ExportGetRequest {
    id?: number;
}

export interface ExportGetDisplayNameRequest {
    id?: number;
}

export interface ExportGetPermissionsRequest {
    id?: number;
}

export interface ExportGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ExportGetSelectItemsRequest {
    searchField: string;
}

export interface ExportSaveRequest {
    FcbExportDto?: FcbExportDto;
}

export interface ExportSaveAccesPermissionsRequest {
    FcbExportAccesPermissionDto?: FcbExportAccesPermissionDto;
}

export interface ExportSaveCentrePermissionsRequest {
    FcbExportCentrePermissionDto?: FcbExportCentrePermissionDto;
}

export interface ExportSaveModulePermissionsRequest {
    FcbExportModulePermissionDto?: FcbExportModulePermissionDto;
}

/**
 * 
 */
export class ExportApi extends runtime.BaseAPI {

    /**
     */
    async exportBaseSearchRaw(requestParameters: ExportBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRechercheexportDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Export/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RechercheexportSearchToJSON(requestParameters.RechercheexportSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRechercheexportDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async exportBaseSearch(requestParameters: ExportBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRechercheexportDtoPaginatedResults> {
        const response = await this.exportBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportDeleteRaw(requestParameters: ExportDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Export`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async exportDelete(requestParameters: ExportDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.exportDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportGetRaw(requestParameters: ExportGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbExportDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbExportDtoFromJSON(jsonValue));
    }

    /**
     */
    async exportGet(requestParameters: ExportGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbExportDto> {
        const response = await this.exportGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportGetDisplayNameRaw(requestParameters: ExportGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Export/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async exportGetDisplayName(requestParameters: ExportGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.exportGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportGetPermissionsRaw(requestParameters: ExportGetPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbExportPermissionsDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Export/GetPermissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbExportPermissionsDtoFromJSON(jsonValue));
    }

    /**
     */
    async exportGetPermissions(requestParameters: ExportGetPermissionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbExportPermissionsDto> {
        const response = await this.exportGetPermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportGetSearchCriteriasRaw(requestParameters: ExportGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Export/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async exportGetSearchCriterias(requestParameters: ExportGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.exportGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportGetSelectItemsRaw(requestParameters: ExportGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling exportGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Export/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async exportGetSelectItems(requestParameters: ExportGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.exportGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportSaveRaw(requestParameters: ExportSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbExportDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbExportDtoToJSON(requestParameters.FcbExportDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbExportDtoFromJSON(jsonValue));
    }

    /**
     */
    async exportSave(requestParameters: ExportSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbExportDto> {
        const response = await this.exportSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportSaveAccesPermissionsRaw(requestParameters: ExportSaveAccesPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbExportAccesPermissionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Export/SaveAccesPermissions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbExportAccesPermissionDtoToJSON(requestParameters.FcbExportAccesPermissionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbExportAccesPermissionDtoFromJSON(jsonValue));
    }

    /**
     */
    async exportSaveAccesPermissions(requestParameters: ExportSaveAccesPermissionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbExportAccesPermissionDto> {
        const response = await this.exportSaveAccesPermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportSaveCentrePermissionsRaw(requestParameters: ExportSaveCentrePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbExportCentrePermissionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Export/SaveCentrePermissions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbExportCentrePermissionDtoToJSON(requestParameters.FcbExportCentrePermissionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbExportCentrePermissionDtoFromJSON(jsonValue));
    }

    /**
     */
    async exportSaveCentrePermissions(requestParameters: ExportSaveCentrePermissionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbExportCentrePermissionDto> {
        const response = await this.exportSaveCentrePermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportSaveModulePermissionsRaw(requestParameters: ExportSaveModulePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbExportModulePermissionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Export/SaveModulePermissions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbExportModulePermissionDtoToJSON(requestParameters.FcbExportModulePermissionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbExportModulePermissionDtoFromJSON(jsonValue));
    }

    /**
     */
    async exportSaveModulePermissions(requestParameters: ExportSaveModulePermissionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbExportModulePermissionDto> {
        const response = await this.exportSaveModulePermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
