import { ButtonContainer, IDataTableColumn } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";

import { InstitutionApi, InstitutionGridDto, InstitutionSearchDto } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface IInstitutionSearchPageProps {}

export const InstitutionSearchPage: React.FunctionComponent<IInstitutionSearchPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(InstitutionApi);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: InstitutionGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.institution}/${row.idinstitution}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.institution}/${row.idinstitution}/detail/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.DenominationSociale),
        fieldName: "denomination",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      }
    ],
    [history, t]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.institution}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(() => api.institutionGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const apiFactory = useAbortableApiServiceFactory(InstitutionApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: InstitutionSearchDto) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.institutionBaseSearch({ InstitutionSearchDto: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      columns={columns}
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      addFunc={addItemFunction}
      breadCrumbs={[{ text: t(ETLCodes.Institutions), route: ERoutes.institution }]}
      sortKeys={{ denomination: "ASC" }}
      searchStateInitialSearch={false}
    />
  );
};
