/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbDeliberationMasseDto
 */
export interface FcbDeliberationMasseDto {
    /**
     * 
     * @type {Date}
     * @memberof FcbDeliberationMasseDto
     */
    dateDeliberation?: Date;
    /**
     * 
     * @type {Array<number>}
     * @memberof FcbDeliberationMasseDto
     */
    idBulletinApprenantTypes?: Array<number> | null;
}

/**
 * Check if a given object implements the FcbDeliberationMasseDto interface.
 */
export function instanceOfFcbDeliberationMasseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbDeliberationMasseDtoFromJSON(json: any): FcbDeliberationMasseDto {
    return FcbDeliberationMasseDtoFromJSONTyped(json, false);
}

export function FcbDeliberationMasseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbDeliberationMasseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateDeliberation': !exists(json, 'dateDeliberation') ? undefined : (new Date(json['dateDeliberation'])),
        'idBulletinApprenantTypes': !exists(json, 'idBulletinApprenantTypes') ? undefined : json['idBulletinApprenantTypes'],
    };
}

export function FcbDeliberationMasseDtoToJSON(value?: FcbDeliberationMasseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dateDeliberation': value.dateDeliberation === undefined ? undefined : (value.dateDeliberation.toISOString()),
        'idBulletinApprenantTypes': value.idBulletinApprenantTypes,
    };
}

