/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceUserLieGridDto
 */
export interface ServiceUserLieGridDto {
    /**
     * 
     * @type {number}
     * @memberof ServiceUserLieGridDto
     */
    idUser?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceUserLieGridDto
     */
    idPersonne?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceUserLieGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceUserLieGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceUserLieGridDto
     */
    login?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceUserLieGridDto
     */
    level?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceUserLieGridDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof ServiceUserLieGridDto
     */
    calcFrom?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ServiceUserLieGridDto
     */
    calcTo?: Date | null;
}

/**
 * Check if a given object implements the ServiceUserLieGridDto interface.
 */
export function instanceOfServiceUserLieGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServiceUserLieGridDtoFromJSON(json: any): ServiceUserLieGridDto {
    return ServiceUserLieGridDtoFromJSONTyped(json, false);
}

export function ServiceUserLieGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceUserLieGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idUser': !exists(json, 'idUser') ? undefined : json['idUser'],
        'idPersonne': !exists(json, 'idPersonne') ? undefined : json['idPersonne'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'login': !exists(json, 'login') ? undefined : json['login'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'calcFrom': !exists(json, 'calcFrom') ? undefined : (json['calcFrom'] === null ? null : new Date(json['calcFrom'])),
        'calcTo': !exists(json, 'calcTo') ? undefined : (json['calcTo'] === null ? null : new Date(json['calcTo'])),
    };
}

export function ServiceUserLieGridDtoToJSON(value?: ServiceUserLieGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idUser': value.idUser,
        'idPersonne': value.idPersonne,
        'nom': value.nom,
        'prenom': value.prenom,
        'login': value.login,
        'level': value.level,
        'actif': value.actif,
        'calcFrom': value.calcFrom === undefined ? undefined : (value.calcFrom === null ? null : value.calcFrom.toISOString()),
        'calcTo': value.calcTo === undefined ? undefined : (value.calcTo === null ? null : value.calcTo.toISOString()),
    };
}

