import * as React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import {
  ContratFormateurApi,
  ContratFormateurSearch,
  FcbContratFormateurGridDto,
  EBooleanSearchTypes
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { useAbortableApiServiceFactory, useApiService } from "../../../../../hooks/useApiService";
import { ETLCodes } from "../../../../../locales";

const Container = styled.div`
  display: flex;
`;

interface IContratFormateurListPageProps {}

export const ContratFormateurListPage: React.FunctionComponent<IContratFormateurListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ContratFormateurApi);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbContratFormateurGridDto) => (
          <Container>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.contratFormateur}/${row.idcontratFormateur}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.contratFormateur}/${row.idcontratFormateur}/detail/edit`)}
            />
          </Container>
        )
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code"
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "libelle"
      },
      {
        header: () => t(ETLCodes.TypeDisplay),
        fieldName: "typeDisplay"
      },
      {
        header: () => t(ETLCodes.Genre),
        fieldName: "genre"
      },
      {
        header: () => t(ETLCodes.NbHeureMin),
        fieldName: "nbheureMin",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.MaxHForm),
        fieldName: "nbheureMax",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.MaxHJury),
        fieldName: "nbheureJury",
        autoFitContent: true
      }
    ],
    [history, t]
  );

  const addItemFunction = React.useCallback(() => history.push(`${ERoutes.contratFormateur}/0/detail/edit`), [history]);

  const getCriteriasFn = React.useCallback(() => api.contratFormateurGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const apiFactory = useAbortableApiServiceFactory(ContratFormateurApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: ContratFormateurSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.contratFormateurBaseSearch({ ContratFormateurSearch: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      columns={columns}
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      sortKeys={{ code: "ASC" }}
      addFunc={addItemFunction}
      breadCrumbs={[{ text: t(ETLCodes.ContratsFormateurs), route: ERoutes.contratFormateur }]}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
    />
  );
};
