/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ETypeDernierAvertissement } from './ETypeDernierAvertissement';
import {
    ETypeDernierAvertissementFromJSON,
    ETypeDernierAvertissementFromJSONTyped,
    ETypeDernierAvertissementToJSON,
} from './ETypeDernierAvertissement';

/**
 * 
 * @export
 * @interface DashboardAbsenceDto
 */
export interface DashboardAbsenceDto {
    /**
     * 
     * @type {number}
     * @memberof DashboardAbsenceDto
     */
    idapprenant?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardAbsenceDto
     */
    apprenant?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardAbsenceDto
     */
    absencesInjustifiees?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardAbsenceDto
     */
    deltaDernierAvertissement?: number | null;
    /**
     * 
     * @type {ETypeDernierAvertissement}
     * @memberof DashboardAbsenceDto
     */
    typeDernierAvertissement?: ETypeDernierAvertissement;
    /**
     * 
     * @type {Date}
     * @memberof DashboardAbsenceDto
     */
    dernierAvertissement?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardAbsenceDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardAbsenceDto
     */
    idpri?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DashboardAbsenceDto
     */
    idari?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardAbsenceDto
     */
    idsuserMasquage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardAbsenceDto
     */
    metierStade?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardAbsenceDto
     */
    emailsRepresentantsLegaux?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardAbsenceDto
     */
    emailsContactsPrincipaux?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DashboardAbsenceDto
     */
    dateAction?: Date | null;
    /**
     * 
     * @type {ETypeDernierAvertissement}
     * @memberof DashboardAbsenceDto
     */
    typeAvertissement?: ETypeDernierAvertissement;
}

/**
 * Check if a given object implements the DashboardAbsenceDto interface.
 */
export function instanceOfDashboardAbsenceDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DashboardAbsenceDtoFromJSON(json: any): DashboardAbsenceDto {
    return DashboardAbsenceDtoFromJSONTyped(json, false);
}

export function DashboardAbsenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardAbsenceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'apprenant': !exists(json, 'apprenant') ? undefined : json['apprenant'],
        'absencesInjustifiees': !exists(json, 'absencesInjustifiees') ? undefined : json['absencesInjustifiees'],
        'deltaDernierAvertissement': !exists(json, 'deltaDernierAvertissement') ? undefined : json['deltaDernierAvertissement'],
        'typeDernierAvertissement': !exists(json, 'typeDernierAvertissement') ? undefined : ETypeDernierAvertissementFromJSON(json['typeDernierAvertissement']),
        'dernierAvertissement': !exists(json, 'dernierAvertissement') ? undefined : (json['dernierAvertissement'] === null ? null : new Date(json['dernierAvertissement'])),
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'idpri': !exists(json, 'idpri') ? undefined : json['idpri'],
        'idari': !exists(json, 'idari') ? undefined : json['idari'],
        'idsuserMasquage': !exists(json, 'idsuserMasquage') ? undefined : json['idsuserMasquage'],
        'metierStade': !exists(json, 'metierStade') ? undefined : json['metierStade'],
        'emailsRepresentantsLegaux': !exists(json, 'emailsRepresentantsLegaux') ? undefined : json['emailsRepresentantsLegaux'],
        'emailsContactsPrincipaux': !exists(json, 'emailsContactsPrincipaux') ? undefined : json['emailsContactsPrincipaux'],
        'dateAction': !exists(json, 'dateAction') ? undefined : (json['dateAction'] === null ? null : new Date(json['dateAction'])),
        'typeAvertissement': !exists(json, 'typeAvertissement') ? undefined : ETypeDernierAvertissementFromJSON(json['typeAvertissement']),
    };
}

export function DashboardAbsenceDtoToJSON(value?: DashboardAbsenceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idapprenant': value.idapprenant,
        'apprenant': value.apprenant,
        'absencesInjustifiees': value.absencesInjustifiees,
        'deltaDernierAvertissement': value.deltaDernierAvertissement,
        'typeDernierAvertissement': ETypeDernierAvertissementToJSON(value.typeDernierAvertissement),
        'dernierAvertissement': value.dernierAvertissement === undefined ? undefined : (value.dernierAvertissement === null ? null : value.dernierAvertissement.toISOString()),
        'anneeScolaire': value.anneeScolaire,
        'idpri': value.idpri,
        'idari': value.idari,
        'idsuserMasquage': value.idsuserMasquage,
        'metierStade': value.metierStade,
        'emailsRepresentantsLegaux': value.emailsRepresentantsLegaux,
        'emailsContactsPrincipaux': value.emailsContactsPrincipaux,
        'dateAction': value.dateAction === undefined ? undefined : (value.dateAction === null ? null : value.dateAction.toISOString()),
        'typeAvertissement': ETypeDernierAvertissementToJSON(value.typeAvertissement),
    };
}

