import * as React from "react";
import { FGMaskInput } from "nsitools-react";
import { IconNames } from "@blueprintjs/icons";
import { Icon } from "@blueprintjs/core";
import styled from "styled-components";

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
`;

export interface IFGEuroMaskInputProps {
  name: string;
  label: string;
  disabled?: boolean;
  readonly?: boolean;
  allowNegative?: boolean;
}

export const FGEuroMaskInput: React.FunctionComponent<IFGEuroMaskInputProps> = ({
  label,
  name,
  disabled,
  readonly,
  allowNegative
}) => {
  return (
    <FGMaskInput
      rightElement={
        <IconContainer>
          <Icon icon={IconNames.EURO} />
        </IconContainer>
      }
      cleaveOptions={{
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
        numericOnly: true,
        numeralPositiveOnly: !allowNegative,
        numeralDecimalScale: 2,
        delimiter: " ",
        stripLeadingZeroes: false
      }}
      name={name}
      label={label}
      disabled={disabled}
      readonly={readonly}
    />
  );
};
