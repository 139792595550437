import * as React from "react";
import { useHistory } from "react-router";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { AnneeReussieApi, FcbAnneeReussieDto, AnneeReussieSearch, EBooleanSearchTypes } from "../../../../../api";
import styled from "styled-components";

interface IDerniereAnneeReussieListPageProps {}
const ButtonContainer = styled.div`
  display: flex;
`;
export const DerniereAnneeReussieListPage: React.FunctionComponent<IDerniereAnneeReussieListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(AnneeReussieApi);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbAnneeReussieDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.anneeReussie}/${row.idanneeReussie}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.anneeReussie}/${row.idanneeReussie}/detail/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "libelle"
      }
    ],
    [history, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.anneeReussie}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(() => api.anneeReussieGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const apiFactory = useAbortableApiServiceFactory(AnneeReussieApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: AnneeReussieSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.anneeReussieBaseSearch({ AnneeReussieSearch: sObj });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.DernieresAnneesReussies), route: ERoutes.anneeReussie }]}
      addFunc={onAddItem}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
    ></SearchTablePage>
  );
};
