import { FGListen, FieldGroup, FieldSet } from "nsitools-react";
import * as React from "react";
import * as Yup from "yup";

import { DeliberationMasseApi, FcbDeliberationMasseDtoFromJSON } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { FGWalterDateMaskInput, PageBase, SmallFormGenerator } from "../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { DeliberationMasseSelector } from "./DeliberationMasseSelector";

export interface IDeliberationMasseItemPageProps {}

export const DeliberationMasseItemPage: React.FunctionComponent<IDeliberationMasseItemPageProps> = () => {
  const { t } = useTl();
  const [dateDeliberation, setDateDeliberation] = React.useState(null);
  const api = useApiService(DeliberationMasseApi);
  const { data, saving, saveItem } = useCrudApi({
    getApiFn: () => {
      return FcbDeliberationMasseDtoFromJSON({});
    },
    saveApiFn: d => api.deliberationMasseSave({ FcbDeliberationMasseDto: d }),
    onSavedRoute: () => `${ERoutes.deliberationMasse}`,
    deleteApiFn: () => {}
  });
  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      dateDeliberation: Yup.date()
        .typeError(t(ETLCodes.DateInvalide))
        .required(t(ETLCodes.Required))
    });
  }, [t]);

  return (
    <PageBase breadCrumbs={[{ text: t(ETLCodes.DeliberationMasse), route: ERoutes.deliberationMasse }]} withCard>
      <FieldSet title={t(ETLCodes.DeliberationMasse)}>
        {data && (
          <SmallFormGenerator
            initialValues={data}
            onSubmit={saveItem}
            showColons
            editMode
            validationSchema={FormSchema}
            saving={saving}
            formatDate="dd-MM-yyyy"
            hideButtons
            canCheckRights={false}
          >
            <FGListen field="dateDeliberation" onChanged={value => setDateDeliberation(value)} />
            <FieldGroup columns={2}>
              <FGWalterDateMaskInput name="dateDeliberation" label={t(ETLCodes.DateDeliberation)} />
            </FieldGroup>
          </SmallFormGenerator>
        )}
        <DeliberationMasseSelector
          loading={saving}
          submitIds={(ids: number[]) =>
            saveItem({
              dateDeliberation: dateDeliberation,
              idBulletinApprenantTypes: ids
            })
          }
        />
      </FieldSet>
    </PageBase>
  );
};
