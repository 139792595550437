import { DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";

import { InstitutionApprenantLiesSearchDto, InstitutionApi, InstitutionApprenantLiesGridDto } from "../../../../api";
import { ApprenantTooltip } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
export interface IInstitutionAppernantLiesListPageProps {}

export const InstitutionAppernantLiesListPage: React.FunctionComponent<IInstitutionAppernantLiesListPageProps> = () => {
  const { id } = useParams<{ id: string; state: string }>();
  const idInstitution = React.useMemo(() => +id, [id]);
  const { t } = useTl();
  const api = useApiService(InstitutionApi);

  const tableState = useGridState<any>({
    sortKeys: { nom: "ASC" },
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15
  });

  const searchFunction = React.useCallback(
    (sObj?: InstitutionApprenantLiesSearchDto) => {
      sObj.idinstitution = +idInstitution;
      return api.institutionGetApprenantLies({ InstitutionApprenantLiesSearchDto: sObj });
    },
    [api, idInstitution]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });
  const { totalCount } = tableState;
  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.Identifiant),
        computed: true,
        fieldName: "info",
        render: (value: InstitutionApprenantLiesGridDto) => <ApprenantTooltip idApprenant={value.idapprenant} />,
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      }
    ],
    [t]
  );

  return (
    <FieldSet title={t(ETLCodes.TableResults, { count: totalCount })}>
      <DataTable dateFormat="dd/MM/yyyy" tableState={tableState} loading={loading} columns={columns}></DataTable>
    </FieldSet>
  );
};
