import * as React from "react";
import renderHTML from "react-render-html";
import { FGCustomReadOnly, IFGCustomReadOnlyProps, useFGContext } from "nsitools-react";
import styled from "styled-components";
import { getIn } from "formik";

const RoContainer = styled.div`
  padding: 0 1rem;
`;

export interface IFGReadonlyHtmlViewerProps
  extends Omit<IFGCustomReadOnlyProps, "readonly" | "disabled" | "maxLength" | "formatReadOnlyValue" | "children"> {}

export const FGReadonlyHtmlViewer: React.FunctionComponent<IFGReadonlyHtmlViewerProps> = props => {
  const { formik } = useFGContext();

  const value = React.useMemo(() => formik?.values && getIn(formik?.values, props.name), [formik?.values, props.name]);

  return <FGCustomReadOnly {...props}>{() => <RoContainer>{renderHTML(value)}</RoContainer>}</FGCustomReadOnly>;
};

export default FGReadonlyHtmlViewer;
