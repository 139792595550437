/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ExperienceProfessionnelleEditDto,
  ExperienceProfessionnelleFichierDto,
  ExperienceProfessionnelleFichierUploadDto,
  ExperienceProfessionnelleGridDtoPaginatedResults,
  ExperienceProfessionnelleSearchDto,
  FileDownloadDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
} from '../models/index';
import {
    ExperienceProfessionnelleEditDtoFromJSON,
    ExperienceProfessionnelleEditDtoToJSON,
    ExperienceProfessionnelleFichierDtoFromJSON,
    ExperienceProfessionnelleFichierDtoToJSON,
    ExperienceProfessionnelleFichierUploadDtoFromJSON,
    ExperienceProfessionnelleFichierUploadDtoToJSON,
    ExperienceProfessionnelleGridDtoPaginatedResultsFromJSON,
    ExperienceProfessionnelleGridDtoPaginatedResultsToJSON,
    ExperienceProfessionnelleSearchDtoFromJSON,
    ExperienceProfessionnelleSearchDtoToJSON,
    FileDownloadDtoFromJSON,
    FileDownloadDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface ExperienceProfessionnelleBaseSearchRequest {
    ExperienceProfessionnelleSearchDto?: ExperienceProfessionnelleSearchDto;
}

export interface ExperienceProfessionnelleDeleteExperienceProfessionnelleRequest {
    id?: number;
}

export interface ExperienceProfessionnelleDeleteExperienceProfessionnelleFichierRequest {
    id?: number;
}

export interface ExperienceProfessionnelleDownloadExperienceProfessionnelleFichierRequest {
    id?: number;
}

export interface ExperienceProfessionnelleGetDisplayNameRequest {
    id?: number;
}

export interface ExperienceProfessionnelleGetExperienceProfessionnelleRequest {
    id?: number;
}

export interface ExperienceProfessionnelleGetExperienceProfessionnelleFichierRequest {
    id?: number;
}

export interface ExperienceProfessionnelleGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ExperienceProfessionnelleGetSelectItemsRequest {
    searchField: string;
}

export interface ExperienceProfessionnelleSaveExperienceProfessionnelleRequest {
    ExperienceProfessionnelleEditDto?: ExperienceProfessionnelleEditDto;
}

export interface ExperienceProfessionnelleSaveExperienceProfessionnelleFichierRequest {
    ExperienceProfessionnelleFichierDto?: ExperienceProfessionnelleFichierDto;
}

export interface ExperienceProfessionnelleUploadExperienceProfessionnelleFichierRequest {
    ExperienceProfessionnelleFichierUploadDto?: ExperienceProfessionnelleFichierUploadDto;
}

/**
 * 
 */
export class ExperienceProfessionnelleApi extends runtime.BaseAPI {

    /**
     */
    async experienceProfessionnelleBaseSearchRaw(requestParameters: ExperienceProfessionnelleBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExperienceProfessionnelleGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExperienceProfessionnelle/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExperienceProfessionnelleSearchDtoToJSON(requestParameters.ExperienceProfessionnelleSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExperienceProfessionnelleGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async experienceProfessionnelleBaseSearch(requestParameters: ExperienceProfessionnelleBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExperienceProfessionnelleGridDtoPaginatedResults> {
        const response = await this.experienceProfessionnelleBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async experienceProfessionnelleDeleteExperienceProfessionnelleRaw(requestParameters: ExperienceProfessionnelleDeleteExperienceProfessionnelleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExperienceProfessionnelle/DeleteExperienceProfessionnelle`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async experienceProfessionnelleDeleteExperienceProfessionnelle(requestParameters: ExperienceProfessionnelleDeleteExperienceProfessionnelleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.experienceProfessionnelleDeleteExperienceProfessionnelleRaw(requestParameters, initOverrides);
    }

    /**
     */
    async experienceProfessionnelleDeleteExperienceProfessionnelleFichierRaw(requestParameters: ExperienceProfessionnelleDeleteExperienceProfessionnelleFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExperienceProfessionnelle/DeleteExperienceProfessionnelleFichier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async experienceProfessionnelleDeleteExperienceProfessionnelleFichier(requestParameters: ExperienceProfessionnelleDeleteExperienceProfessionnelleFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.experienceProfessionnelleDeleteExperienceProfessionnelleFichierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async experienceProfessionnelleDownloadExperienceProfessionnelleFichierRaw(requestParameters: ExperienceProfessionnelleDownloadExperienceProfessionnelleFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExperienceProfessionnelle/DownloadExperienceProfessionnelleFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async experienceProfessionnelleDownloadExperienceProfessionnelleFichier(requestParameters: ExperienceProfessionnelleDownloadExperienceProfessionnelleFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.experienceProfessionnelleDownloadExperienceProfessionnelleFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async experienceProfessionnelleGetDisplayNameRaw(requestParameters: ExperienceProfessionnelleGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExperienceProfessionnelle/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async experienceProfessionnelleGetDisplayName(requestParameters: ExperienceProfessionnelleGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.experienceProfessionnelleGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async experienceProfessionnelleGetExperienceProfessionnelleRaw(requestParameters: ExperienceProfessionnelleGetExperienceProfessionnelleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExperienceProfessionnelleEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExperienceProfessionnelle`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExperienceProfessionnelleEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async experienceProfessionnelleGetExperienceProfessionnelle(requestParameters: ExperienceProfessionnelleGetExperienceProfessionnelleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExperienceProfessionnelleEditDto> {
        const response = await this.experienceProfessionnelleGetExperienceProfessionnelleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async experienceProfessionnelleGetExperienceProfessionnelleFichierRaw(requestParameters: ExperienceProfessionnelleGetExperienceProfessionnelleFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExperienceProfessionnelleFichierDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExperienceProfessionnelle/GetExperienceProfessionnelleFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExperienceProfessionnelleFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async experienceProfessionnelleGetExperienceProfessionnelleFichier(requestParameters: ExperienceProfessionnelleGetExperienceProfessionnelleFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExperienceProfessionnelleFichierDto> {
        const response = await this.experienceProfessionnelleGetExperienceProfessionnelleFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async experienceProfessionnelleGetSearchCriteriasRaw(requestParameters: ExperienceProfessionnelleGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExperienceProfessionnelle/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async experienceProfessionnelleGetSearchCriterias(requestParameters: ExperienceProfessionnelleGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.experienceProfessionnelleGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async experienceProfessionnelleGetSelectItemsRaw(requestParameters: ExperienceProfessionnelleGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling experienceProfessionnelleGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExperienceProfessionnelle/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async experienceProfessionnelleGetSelectItems(requestParameters: ExperienceProfessionnelleGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.experienceProfessionnelleGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async experienceProfessionnelleSaveExperienceProfessionnelleRaw(requestParameters: ExperienceProfessionnelleSaveExperienceProfessionnelleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExperienceProfessionnelleEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExperienceProfessionnelle`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExperienceProfessionnelleEditDtoToJSON(requestParameters.ExperienceProfessionnelleEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExperienceProfessionnelleEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async experienceProfessionnelleSaveExperienceProfessionnelle(requestParameters: ExperienceProfessionnelleSaveExperienceProfessionnelleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExperienceProfessionnelleEditDto> {
        const response = await this.experienceProfessionnelleSaveExperienceProfessionnelleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async experienceProfessionnelleSaveExperienceProfessionnelleFichierRaw(requestParameters: ExperienceProfessionnelleSaveExperienceProfessionnelleFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExperienceProfessionnelleFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExperienceProfessionnelle/SaveExperienceProfessionnelleFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExperienceProfessionnelleFichierDtoToJSON(requestParameters.ExperienceProfessionnelleFichierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExperienceProfessionnelleFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async experienceProfessionnelleSaveExperienceProfessionnelleFichier(requestParameters: ExperienceProfessionnelleSaveExperienceProfessionnelleFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExperienceProfessionnelleFichierDto> {
        const response = await this.experienceProfessionnelleSaveExperienceProfessionnelleFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async experienceProfessionnelleUploadExperienceProfessionnelleFichierRaw(requestParameters: ExperienceProfessionnelleUploadExperienceProfessionnelleFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExperienceProfessionnelleFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ExperienceProfessionnelle/UploadExperienceProfessionnelleFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExperienceProfessionnelleFichierUploadDtoToJSON(requestParameters.ExperienceProfessionnelleFichierUploadDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExperienceProfessionnelleFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async experienceProfessionnelleUploadExperienceProfessionnelleFichier(requestParameters: ExperienceProfessionnelleUploadExperienceProfessionnelleFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExperienceProfessionnelleFichierDto> {
        const response = await this.experienceProfessionnelleUploadExperienceProfessionnelleFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
