/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbSecteurHomogeneDto,
  FcbSecteurHomogeneGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SecteurHomogeneSearch,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    FcbSecteurHomogeneDtoFromJSON,
    FcbSecteurHomogeneDtoToJSON,
    FcbSecteurHomogeneGridDtoPaginatedResultsFromJSON,
    FcbSecteurHomogeneGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SecteurHomogeneSearchFromJSON,
    SecteurHomogeneSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface SecteurHomogeneBaseSearchRequest {
    SecteurHomogeneSearch?: SecteurHomogeneSearch;
}

export interface SecteurHomogeneDeleteRequest {
    id?: number;
}

export interface SecteurHomogeneGetRequest {
    id?: number;
}

export interface SecteurHomogeneGetDisplayNameRequest {
    id?: number;
}

export interface SecteurHomogeneGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface SecteurHomogeneGetSelectItemsRequest {
    searchField: string;
}

export interface SecteurHomogeneSaveRequest {
    FcbSecteurHomogeneDto?: FcbSecteurHomogeneDto;
}

export interface SecteurHomogeneStadeLibelleSecteurHomogeneExistsRequest {
    libelle?: string;
    idStade?: number;
}

/**
 * 
 */
export class SecteurHomogeneApi extends runtime.BaseAPI {

    /**
     */
    async secteurHomogeneBaseSearchRaw(requestParameters: SecteurHomogeneBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbSecteurHomogeneGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SecteurHomogene/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SecteurHomogeneSearchToJSON(requestParameters.SecteurHomogeneSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbSecteurHomogeneGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async secteurHomogeneBaseSearch(requestParameters: SecteurHomogeneBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbSecteurHomogeneGridDtoPaginatedResults> {
        const response = await this.secteurHomogeneBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async secteurHomogeneDeleteRaw(requestParameters: SecteurHomogeneDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SecteurHomogene`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async secteurHomogeneDelete(requestParameters: SecteurHomogeneDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.secteurHomogeneDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async secteurHomogeneGetRaw(requestParameters: SecteurHomogeneGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbSecteurHomogeneDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SecteurHomogene`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbSecteurHomogeneDtoFromJSON(jsonValue));
    }

    /**
     */
    async secteurHomogeneGet(requestParameters: SecteurHomogeneGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbSecteurHomogeneDto> {
        const response = await this.secteurHomogeneGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async secteurHomogeneGetDisplayNameRaw(requestParameters: SecteurHomogeneGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SecteurHomogene/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async secteurHomogeneGetDisplayName(requestParameters: SecteurHomogeneGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.secteurHomogeneGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async secteurHomogeneGetSearchCriteriasRaw(requestParameters: SecteurHomogeneGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SecteurHomogene/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async secteurHomogeneGetSearchCriterias(requestParameters: SecteurHomogeneGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.secteurHomogeneGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async secteurHomogeneGetSelectItemsRaw(requestParameters: SecteurHomogeneGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling secteurHomogeneGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SecteurHomogene/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async secteurHomogeneGetSelectItems(requestParameters: SecteurHomogeneGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.secteurHomogeneGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async secteurHomogeneSaveRaw(requestParameters: SecteurHomogeneSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbSecteurHomogeneDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SecteurHomogene`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbSecteurHomogeneDtoToJSON(requestParameters.FcbSecteurHomogeneDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbSecteurHomogeneDtoFromJSON(jsonValue));
    }

    /**
     */
    async secteurHomogeneSave(requestParameters: SecteurHomogeneSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbSecteurHomogeneDto> {
        const response = await this.secteurHomogeneSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async secteurHomogeneStadeLibelleSecteurHomogeneExistsRaw(requestParameters: SecteurHomogeneStadeLibelleSecteurHomogeneExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.libelle !== undefined) {
            queryParameters['libelle'] = requestParameters.libelle;
        }

        if (requestParameters.idStade !== undefined) {
            queryParameters['idStade'] = requestParameters.idStade;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/SecteurHomogene/stadeLibelleExists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async secteurHomogeneStadeLibelleSecteurHomogeneExists(requestParameters: SecteurHomogeneStadeLibelleSecteurHomogeneExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.secteurHomogeneStadeLibelleSecteurHomogeneExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
