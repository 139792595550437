/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbCalendrierGridDto
 */
export interface FcbCalendrierGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbCalendrierGridDto
     */
    idcalendrier?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCalendrierGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCalendrierGridDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbCalendrierGridDto
     */
    dateDebut?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FcbCalendrierGridDto
     */
    dateFin?: Date;
    /**
     * 
     * @type {number}
     * @memberof FcbCalendrierGridDto
     */
    idcentre?: number | null;
}

/**
 * Check if a given object implements the FcbCalendrierGridDto interface.
 */
export function instanceOfFcbCalendrierGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbCalendrierGridDtoFromJSON(json: any): FcbCalendrierGridDto {
    return FcbCalendrierGridDtoFromJSONTyped(json, false);
}

export function FcbCalendrierGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbCalendrierGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcalendrier': !exists(json, 'idcalendrier') ? undefined : json['idcalendrier'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'dateDebut': !exists(json, 'dateDebut') ? undefined : (new Date(json['dateDebut'])),
        'dateFin': !exists(json, 'dateFin') ? undefined : (new Date(json['dateFin'])),
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
    };
}

export function FcbCalendrierGridDtoToJSON(value?: FcbCalendrierGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcalendrier': value.idcalendrier,
        'nom': value.nom,
        'libelle': value.libelle,
        'dateDebut': value.dateDebut === undefined ? undefined : (value.dateDebut.toISOString()),
        'dateFin': value.dateFin === undefined ? undefined : (value.dateFin.toISOString()),
        'idcentre': value.idcentre,
    };
}

