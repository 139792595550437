import * as React from "react";
import { FcbReportCentrePermissionDto, ReportApi } from "../../../../../api";
import { useTl, useApiService } from "../../../../../hooks";
import { useGridState, IDataTableColumn, FieldSet, DataTable } from "nsitools-react";
import { Checkbox } from "@blueprintjs/core";
import { ETLCodes } from "../../../../../locales";

export interface IImpressionCentrePermissionsGridProps {
  idImpression: number;
  centres: FcbReportCentrePermissionDto[];
  loading: boolean;
}

export const ImpressionCentrePermissionsGrid: React.FunctionComponent<IImpressionCentrePermissionsGridProps> = ({
  idImpression,
  centres = [],
  loading
}) => {
  const { t } = useTl();
  const api = useApiService(ReportApi);

  const tableState = useGridState<FcbReportCentrePermissionDto>({
    serverMode: false,
    enablePagination: false,
    enableFilter: false
  });

  const { setData } = tableState;

  React.useEffect(() => {
    setData(centres);
  }, [centres, setData]);

  const toggleSelection = React.useCallback(
    (row: FcbReportCentrePermissionDto) => {
      row.selected = !row.selected;
      row.idimpression = idImpression;
      let nextData = [...centres];
      setData(nextData);
      api.reportSaveCentrePermissions({ FcbReportCentrePermissionDto: row });
    },
    [api, centres, idImpression, setData]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        render: (item: FcbReportCentrePermissionDto) => (
          <Checkbox
            checked={item.selected}
            onChange={e => {
              toggleSelection(item);
            }}
          />
        )
      },
      {
        header: () => t(ETLCodes.Description),
        fieldName: "nom"
      }
    ],
    [t, toggleSelection]
  );

  return (
    <FieldSet title={t(ETLCodes.Centres)} collapsable defaultCollapsed>
      <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
    </FieldSet>
  );
};
