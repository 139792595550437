/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UsersServicesLiesDto
 */
export interface UsersServicesLiesDto {
    /**
     * 
     * @type {number}
     * @memberof UsersServicesLiesDto
     */
    idUser?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof UsersServicesLiesDto
     */
    idsServiceTutelles?: Array<number> | null;
}

/**
 * Check if a given object implements the UsersServicesLiesDto interface.
 */
export function instanceOfUsersServicesLiesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UsersServicesLiesDtoFromJSON(json: any): UsersServicesLiesDto {
    return UsersServicesLiesDtoFromJSONTyped(json, false);
}

export function UsersServicesLiesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersServicesLiesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idUser': !exists(json, 'idUser') ? undefined : json['idUser'],
        'idsServiceTutelles': !exists(json, 'idsServiceTutelles') ? undefined : json['idsServiceTutelles'],
    };
}

export function UsersServicesLiesDtoToJSON(value?: UsersServicesLiesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idUser': value.idUser,
        'idsServiceTutelles': value.idsServiceTutelles,
    };
}

