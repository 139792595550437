import { Classes, Switch } from "@blueprintjs/core";
import { ButtonContainer, IDataTableColumn, ToolTipButton } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import {
  DashboardApi,
  DashboardDossierDto,
  DashboardDossierSearch,
  ETargetDossier,
  LieuFormationApi
} from "../../../api";
import { ERoutes } from "../../../AppRouter";
import { SearchTablePage, ViewButton } from "../../../components";
import { useDialog, useEventsContext } from "../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTheme, useTl } from "../../../hooks";
import { ETLCodes } from "../../../locales";

const StyledSwitch = styled(Switch)`
  & .${Classes.SWITCH} {
    &:hover {
      input:checked ~ .${Classes.CONTROL_INDICATOR} {
        background: ${props => props.color};
      }
    }
  }
`;

export interface IDashboardDossierFinAgrementProps {}

export const DashboardDossierFinAgrement: React.FunctionComponent<IDashboardDossierFinAgrementProps> = props => {
  const { t } = useTl();
  const { ifapmeSide } = useTheme();
  const api = useApiService(DashboardApi);
  const lfApi = useApiService(LieuFormationApi);
  const lastAbortController = React.useRef<AbortController>();

  const isHope = React.useMemo(() => ifapmeSide === "hope", [ifapmeSide]);
  const { theme } = useTheme();
  const apiFactory = useAbortableApiServiceFactory(DashboardApi);

  const [displayHidden, setDisplayHidden] = React.useState(false);
  const searchFunction = React.useCallback(
    (sObj?: DashboardDossierSearch) => {
      sObj.target = isHope ? ETargetDossier.Services : ETargetDossier.Centres;
      sObj.hideMasquees = !displayHidden;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.dashboardSearchDossiersFinAgrement({ DashboardDossierSearch: sObj });
    },
    [apiFactory, displayHidden, isHope]
  );

  const [rowLoading, setRowLoading] = React.useState<DashboardDossierDto>(null);

  const { showDialogPromise } = useDialog();
  const { dispatchEvent } = useEventsContext();

  const masquer = React.useCallback(
    async (row: DashboardDossierDto) => {
      const res = await showDialogPromise({
        title: t(ETLCodes.MasquerLigneConfirmTitle),
        message: t(ETLCodes.MasquerLigneConfirmMessage)
      });
      if (res === "yes") {
        setRowLoading(row);

        await api.dashboardMasquerDossierAgrement({
          idlieuFormationAgrement: row.id
        });
        setRowLoading(null);
        dispatchEvent("SEARCH_TABLE_REFRESH");
        dispatchEvent("DASHBOARD_COUNTS_REFRESH_Dossiers");
      }
    },
    [api, dispatchEvent, showDialogPromise, t]
  );

  const view = React.useCallback(
    async (row: DashboardDossierDto) => {
      var idsiegeSocial = await lfApi.lieuFormationGetIdSiegeSocial({ id: row.idconcerne });
      window.open(
        `#${ERoutes.lieuFormation}/${row.idconcerne}/agrements/edit/${row.id}?idsiegeSocial=${idsiegeSocial}`,
        "_blank"
      );
    },
    [lfApi]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: DashboardDossierDto) => (
          <ButtonContainer>
            {!row.masquee && (
              <ToolTipButton
                tooltipPosition="bottom"
                tooltip={t(ETLCodes.MasquerLigne)}
                minimal
                icon="cross"
                onClick={() => masquer(row)}
                loading={!!rowLoading && rowLoading.id === row.id}
                disabled={!!rowLoading && rowLoading.id !== row.id}
              />
            )}
            <ViewButton minimal={true} onClick={() => view(row)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Concerne),
        fieldName: "concerne"
      },
      {
        header: () => t(ETLCodes.DateReception),
        fieldName: "dateReception"
      },
      {
        header: () => t(ETLCodes.MetierStade),
        fieldName: "metierStade"
      },
      {
        header: () => t(ETLCodes.PersonneReferenceIfapme),
        fieldName: "currentPri"
      }
    ],
    [t, rowLoading, masquer, view]
  );

  const getCriteria = React.useCallback(async () => {
    return await api.dashboardGetDossierCriterias();
  }, [api]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <SearchTablePage
        title={ETLCodes.DashboardDossierType_FinAgrementProvisoire}
        searchStateInitialSearch={false}
        withCard={false}
        columns={columns}
        getCriteriasFunction={getCriteria}
        searchFunction={searchFunction}
        onAbort={onAbort}
        sortKeys={{ concerne: "ASC" }}
        rightElement={
          <StyledSwitch
            color={theme.primaryColor}
            label={t(ETLCodes.AfficherMasquees)}
            checked={displayHidden}
            onChange={e => {
              setDisplayHidden(e.currentTarget.checked);
            }}
          ></StyledSwitch>
        }
      />
    </>
  );
};
