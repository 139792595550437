import * as React from "react";

import { FGWalterSelectInput } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

interface ILieuSelectProps {
  idCentre: number;
}

export const LieuSelect: React.FunctionComponent<ILieuSelectProps> = ({ idCentre }) => {
  const { t } = useTl();
  const [data, loading] = useReferential(api => api.referentialGetLieu({ idCentre }), true, [idCentre]);

  return <FGWalterSelectInput name="idlieu" label={t(ETLCodes.Lieu)} loading={loading} items={data} />;
};
