import { ControlGroup } from "@blueprintjs/core";
import { FormikProps } from "formik";
import { FGIBANNumberInput, FGListen, FGTextInput } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";

import { FormateurApi, PersonneEditDto } from "../../../../../../api";
import { DeleteButton } from "../../../../../../components";
import { useApiService, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";

export interface IPersonneBankAccountRowProps {
  idpersonne: number;
  index: number;
  formik: FormikProps<PersonneEditDto>;
  editMode: boolean;
  disabled: boolean;
}

export const PersonneBankAccountRow: React.FunctionComponent<IPersonneBankAccountRowProps> = ({
  idpersonne,
  index,
  formik,
  editMode,
  disabled
}) => {
  const { t } = useTl();
  const formateurApi = useApiService(FormateurApi);
  const [bankAccount, setBankAccount] = React.useState<string>(null);
  const queryEnabled = React.useMemo(() => bankAccount?.startsWith("BE") && bankAccount?.length > 8, [bankAccount]);
  const fetchBic = React.useCallback(async () => {
    if (queryEnabled) return formateurApi.formateurGetBicFromAccount({ account: bankAccount });
    return "";
  }, [bankAccount, formateurApi, queryEnabled]);

  const { data: bic, isFetching: bicLoading, refetch } = useQuery(["bic-personne", idpersonne, bankAccount], fetchBic);

  React.useEffect(() => {
    if (bankAccount) {
      refetch();
    }
  }, [bankAccount, refetch]);

  React.useEffect(() => {
    if (bic && bic !== formik.values?.compteBancaires?.[index]?.bic) {
      formik?.setFieldValue(`compteBancaires[${index}].bic`, bic);
    }
  }, [bic, formik, index]);

  const removeCb = React.useCallback(
    async (index: number) => {
      let cbs = [...formik.values.compteBancaires];
      cbs.splice(index, 1);
      formik.setFieldValue("compteBancaires", cbs);
    },
    [formik]
  );

  return (
    <ControlGroup>
      <FGListen
        field={`compteBancaires[${index}].iban`}
        onChanged={(value, formik) => {
          if (!!value) {
            setBankAccount(value);
          } else if (!!formik.values.compteBancaires?.[index]?.bic) {
            formik.setFieldValue(`compteBancaires[${index}].bic`, null);
          }
        }}
      />
      <FGIBANNumberInput name={`compteBancaires[${index}].iban`} label={t(ETLCodes.NumeroBanque)} />
      <FGTextInput
        name={`compteBancaires[${index}].bic`}
        label={t(ETLCodes.CodeBIC)}
        maxLength={15}
        loading={bicLoading}
      />
      {editMode && <DeleteButton onDelete={() => removeCb(index)} disabled={disabled} />}
    </ControlGroup>
  );
};
