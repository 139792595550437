import * as React from "react";

import { DeliberationBulletinSwitch } from "./bulletins";
import { DeliberationRecapitulatifList } from "./DeliberationRecapitulatifList";

interface IDeliberationProps {}

export const Deliberation: React.FunctionComponent<IDeliberationProps> = () => {
  return (
    <>
      <DeliberationRecapitulatifList />
      <DeliberationBulletinSwitch />
    </>
  );
};
