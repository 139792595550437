/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdresseDoublonGridDto
 */
export interface AdresseDoublonGridDto {
    /**
     * 
     * @type {number}
     * @memberof AdresseDoublonGridDto
     */
    idadresse?: number;
    /**
     * 
     * @type {number}
     * @memberof AdresseDoublonGridDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {string}
     * @memberof AdresseDoublonGridDto
     */
    typeAdresse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdresseDoublonGridDto
     */
    adresse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdresseDoublonGridDto
     */
    boite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdresseDoublonGridDto
     */
    codePostalText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdresseDoublonGridDto
     */
    codePostal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdresseDoublonGridDto
     */
    localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdresseDoublonGridDto
     */
    commune?: string | null;
}

/**
 * Check if a given object implements the AdresseDoublonGridDto interface.
 */
export function instanceOfAdresseDoublonGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdresseDoublonGridDtoFromJSON(json: any): AdresseDoublonGridDto {
    return AdresseDoublonGridDtoFromJSONTyped(json, false);
}

export function AdresseDoublonGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdresseDoublonGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idadresse': !exists(json, 'idadresse') ? undefined : json['idadresse'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'typeAdresse': !exists(json, 'typeAdresse') ? undefined : json['typeAdresse'],
        'adresse': !exists(json, 'adresse') ? undefined : json['adresse'],
        'boite': !exists(json, 'boite') ? undefined : json['boite'],
        'codePostalText': !exists(json, 'codePostalText') ? undefined : json['codePostalText'],
        'codePostal': !exists(json, 'codePostal') ? undefined : json['codePostal'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'commune': !exists(json, 'commune') ? undefined : json['commune'],
    };
}

export function AdresseDoublonGridDtoToJSON(value?: AdresseDoublonGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idadresse': value.idadresse,
        'idpersonne': value.idpersonne,
        'typeAdresse': value.typeAdresse,
        'adresse': value.adresse,
        'boite': value.boite,
        'codePostalText': value.codePostalText,
        'codePostal': value.codePostal,
        'localite': value.localite,
        'commune': value.commune,
    };
}

