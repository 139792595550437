import { ButtonContainer } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import {
  FcbFormateurDesideratumGridDto,
  FormateurDesideratumApi,
  FormateurDesideratumSearch
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IFormateurPreferenceHoraireListPageProps {}

export const FormateurPreferenceHoraireListPage: React.FunctionComponent<IFormateurPreferenceHoraireListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(FormateurDesideratumApi);
  const { id } = useParams<{ id: string }>();

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbFormateurDesideratumGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.formateur}/${id}/preferencesHoraire/${row.iddesiderata}/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.formateur}/${id}/preferencesHoraire/${row.iddesiderata}/edit`)}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "centre"
      },
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "anneeScolaire"
      },
      {
        header: () => t(ETLCodes.Desiderata),
        fieldName: "desiderata"
      }
    ],
    [history, id, t]
  );

  const apiFactory = useAbortableApiServiceFactory(FormateurDesideratumApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFunction = React.useCallback(
    (nextSearch: FormateurDesideratumSearch) => {
      nextSearch.idFormateur = +id;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.formateurDesideratumBaseSearch({ FormateurDesideratumSearch: nextSearch });
    },
    [apiFactory, id]
  );

  const getCriteriaFunction = React.useCallback(
    () => api.formateurDesideratumGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.formateur}/${id}/preferencesHoraire/0/edit`);
  }, [history, id]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      withCard={false}
      getCriteriasFunction={getCriteriaFunction}
      searchFunction={searchFunction}
      onAbort={onAbort}
      addFunc={addItemFunction}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.DeliberationType), route: ERoutes.deliberationType }]}
      sortKeys={{ centre: "ASC" }}
      availablePageSizes={[10]}
      pageSize={10}
    />
  );
};
