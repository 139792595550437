import { Button, Colors, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useSet } from "ahooks";
import {
  ButtonsBloc,
  DataTable,
  FGCustomPanel,
  FGMaskInput,
  FGTextInput,
  FieldGroup,
  FieldSet,
  InlineButtonContainer,
  useGridState,
  useSearchApi
} from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import styled, { css } from "styled-components";

import { AddButton, FGWalterDateMaskInput, FGWalterSelectInput, SelectButton, SmallFormGenerator } from "../..";
import {
  ChefEntrepriseSelectorApi,
  ChefEntrepriseSelectorGridDto,
  ChefEntrepriseSelectorSearch,
  ChefEntrepriseSelectorSearchFromJSON
} from "../../../api";
import { ERoutes } from "../../../AppRouter";
import { useApiService, useTabMessage, useTheme, useTl } from "../../../hooks";
import { useReferential } from "../../../hooks/useReferential";
import { ETLCodes } from "../../../locales";
import { Guid } from "../../../utils/guid";

const Container = styled.div`
  margin: 1rem 1rem 0 1rem;
`;

export interface IChefEntrepriseSelectorProps {
  excludedChefEntrepriseIds?: number[];
  onChefEntrepriseSelected: (selectedChefEntrepriseIds?: number[]) => void;
  rightElement?: any;
  allowMultipleSelection?: boolean;
  preSelectedIds?: number[];
  idsiegeSocial?: number;
  filterOnSiegeSocial?: boolean;
  canAdd?: boolean;
}

export const ChefEntrepriseSelector: React.FunctionComponent<IChefEntrepriseSelectorProps> = ({
  excludedChefEntrepriseIds = [],
  onChefEntrepriseSelected,
  rightElement,
  allowMultipleSelection = false,
  preSelectedIds = [],
  idsiegeSocial,
  canAdd = true,
  filterOnSiegeSocial = false
}) => {
  const { t } = useTl();
  const history = useHistory();
  const { theme } = useTheme();
  const [nationalites, nLoading] = useReferential(a => a.referentialGetAllNationalite(), false, []);
  const [selectedChefEntrepriseIds, { add: addChefEntrepriseId, remove: removeChefEntrepriseId }] = useSet<number>(
    preSelectedIds
  );
  const api = useApiService(ChefEntrepriseSelectorApi);
  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [10, 15],
    pageSize: 10,
    sortKeys: { nom: "ASC", prenom: "ASC" }
  });

  const initialData = React.useMemo(() => {
    return ChefEntrepriseSelectorSearchFromJSON({
      selectedChefEntrepriseIds,
      excludedChefEntrepriseIds,
      idsiegeSocial: filterOnSiegeSocial ? idsiegeSocial : undefined,
      forceSkip: 0,
      forceTake: 10
    });
  }, [excludedChefEntrepriseIds, filterOnSiegeSocial, idsiegeSocial, selectedChefEntrepriseIds]);

  const { totalCount } = tableState;
  const searchFunction = React.useCallback(
    (sObj?: ChefEntrepriseSelectorSearch) => {
      let nextSObj = { ...sObj };
      Object.keys(sObj)
        .filter(k => !["forceSkip", "forceTake", "filter", "sortKey", "aggregateKeys", "parameters"].includes(k))
        .forEach(k => {
          nextSObj[k] = !!sObj[k]?.value ? sObj[k] : undefined;
        });
      return api.chefEntrepriseSelectorBaseSearch({
        ChefEntrepriseSelectorSearch: {
          ...nextSObj,
          idsiegeSocial: filterOnSiegeSocial ? idsiegeSocial : undefined,
          selectedChefEntrepriseIds: Array.from(selectedChefEntrepriseIds),
          excludedChefEntrepriseIds
        }
      });
    },
    [api, excludedChefEntrepriseIds, filterOnSiegeSocial, idsiegeSocial, selectedChefEntrepriseIds]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const selectChefEntreprise = React.useCallback(
    (id: number) => {
      if (allowMultipleSelection) {
        selectedChefEntrepriseIds.has(id) ? removeChefEntrepriseId(id) : addChefEntrepriseId(id);
      } else {
        onChefEntrepriseSelected([id]);
      }
    },
    [
      addChefEntrepriseId,
      allowMultipleSelection,
      onChefEntrepriseSelected,
      removeChefEntrepriseId,
      selectedChefEntrepriseIds
    ]
  );

  const onRowClick = React.useCallback(
    (item: ChefEntrepriseSelectorGridDto) => {
      selectChefEntreprise(item.idchefEntreprise);
    },
    [selectChefEntreprise]
  );

  const identifier = React.useMemo(() => Guid.newGuid(), []);
  const { messageValue, clearMessageValue, openTabThenSendMessage } = useTabMessage(identifier);
  const add = React.useCallback(
    values => {
      openTabThenSendMessage(
        idsiegeSocial > 0
          ? `#${ERoutes.siegeSocial}/${idsiegeSocial}/chefsEntreprise/checkDoublons`
          : `#${ERoutes.chefEntreprise}/-1/detail/checkDoublons`,
        values
      );
    },
    [idsiegeSocial, openTabThenSendMessage]
  );

  React.useEffect(() => {
    if (messageValue) {
      search();
      selectChefEntreprise(messageValue);
      clearMessageValue();
    }
  }, [clearMessageValue, messageValue, search, selectChefEntreprise]);

  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.DateNaissance),
        fieldName: "dateNaissance"
      },
      {
        header: () => t(ETLCodes.NumeroNational),
        fieldName: "numeroNational"
      },
      {
        header: () => t(ETLCodes.Adresse),
        fieldName: "adresse"
      },
      {
        header: () => t(ETLCodes.CodePostal),
        fieldName: "codePostal"
      },
      {
        header: () => t(ETLCodes.Localite),
        fieldName: "localite"
      },
      {
        header: () => t(ETLCodes.Nationalite),
        fieldName: "nationalite"
      }
    ],
    [t]
  );

  const customizeRowStyleCallback = React.useCallback(
    (item: ChefEntrepriseSelectorGridDto) => {
      const isSelected = selectedChefEntrepriseIds.has(item.idchefEntreprise);

      return css`
        & * {
          color: ${isSelected ? Colors.WHITE : Colors.BLACK} !important;
        }

        & & > td {
          background-color: ${isSelected ? theme.primaryColor : "initial"} !important;
        }

        background-color: ${isSelected ? theme.primaryColor : "transparent"} !important;
      `;
    },
    [selectedChefEntrepriseIds, theme.primaryColor]
  );

  const onChefEntrepriseSelect = React.useCallback(() => {
    onChefEntrepriseSelected(Array.from(selectedChefEntrepriseIds));
  }, [onChefEntrepriseSelected, selectedChefEntrepriseIds]);

  const finalRightElements = React.useMemo(() => {
    return allowMultipleSelection ? (
      <>
        <SelectButton onClick={onChefEntrepriseSelect}></SelectButton>
        {rightElement}
      </>
    ) : (
      rightElement
    );
  }, [allowMultipleSelection, onChefEntrepriseSelect, rightElement]);

  const tableTitle = React.useMemo(
    () =>
      `${t(ETLCodes.TableResults, { count: totalCount })}${
        selectedChefEntrepriseIds.size > 0
          ? " - " + selectedChefEntrepriseIds.size + " " + t(ETLCodes.SelectedChefEntreprises)
          : ""
      }`,
    [selectedChefEntrepriseIds.size, t, totalCount]
  );

  return (
    <Container>
      <SmallFormGenerator
        enableDirtyCheck={false}
        initialValues={initialData}
        onSubmit={c => search(c)}
        editMode
        hideButtons={true}
        onCancel={() => history.push(`${ERoutes.chefEntreprise}`)}
      >
        <FieldGroup columns={2}>
          <FGTextInput name="nom.value" label={t(ETLCodes.Nom)} maxLength={50} forceCase="upper" />
          <FGWalterSelectInput
            name="nationalite.value"
            label={t(ETLCodes.Nationalite)}
            items={nationalites}
            loading={nLoading}
          />
          <FGTextInput name="prenom.value" label={t(ETLCodes.Prenom)} maxLength={50} />
          <FGMaskInput
            name="registreNational.value"
            label={t(ETLCodes.NumeroNational)}
            cleaveOptions={{ delimiters: [".", ".", "-", "."], blocks: [2, 2, 2, 3, 2], numericOnly: true }}
          />
          <FGWalterDateMaskInput name="dateNaissance.value" label={t(ETLCodes.DateNaissance)} />
        </FieldGroup>
        <FieldGroup>
          <FGCustomPanel>
            {ctx => (
              <InlineButtonContainer>
                <ButtonsBloc></ButtonsBloc>
                <ButtonsBloc>
                  <Button icon={IconNames.SEARCH} type="submit" text={t(ETLCodes.Search)} intent={Intent.PRIMARY} />
                  {canAdd && (
                    <AddButton
                      onClick={() => add(ctx.formik?.values)}
                      text={t(ETLCodes.General_Add)}
                      intent={Intent.PRIMARY}
                      minimal={false}
                    />
                  )}
                </ButtonsBloc>
              </InlineButtonContainer>
            )}
          </FGCustomPanel>
        </FieldGroup>
        <FGCustomPanel>
          {ctx => (
            <FieldSet title={tableTitle} rightElement={finalRightElements}>
              <DataTable
                dateFormat="dd-MM-yyyy"
                tableState={tableState}
                loading={loading}
                columns={columns}
                onRowClick={onRowClick}
                customizeRowStyle={customizeRowStyleCallback}
                htmlTableOptions={{ striped: true, interactive: true, bordered: true }}
              />
            </FieldSet>
          )}
        </FGCustomPanel>
      </SmallFormGenerator>
    </Container>
  );
};
