/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DoublonEditDto,
  DoublonsGridDtoPaginatedResults,
  DoublonsPersonneGridDtoPaginatedResults,
  DoublonsPersonneSearchDto,
  DoublonsSearchDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
} from '../models/index';
import {
    DoublonEditDtoFromJSON,
    DoublonEditDtoToJSON,
    DoublonsGridDtoPaginatedResultsFromJSON,
    DoublonsGridDtoPaginatedResultsToJSON,
    DoublonsPersonneGridDtoPaginatedResultsFromJSON,
    DoublonsPersonneGridDtoPaginatedResultsToJSON,
    DoublonsPersonneSearchDtoFromJSON,
    DoublonsPersonneSearchDtoToJSON,
    DoublonsSearchDtoFromJSON,
    DoublonsSearchDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface DoublonsBaseSearchRequest {
    DoublonsSearchDto?: DoublonsSearchDto;
}

export interface DoublonsFusionRequest {
    DoublonEditDto?: DoublonEditDto;
}

export interface DoublonsGetDoublonRequest {
    idpersonne1?: number;
    idpersonne2?: number;
}

export interface DoublonsGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface DoublonsGetSelectItemsRequest {
    searchField: string;
}

export interface DoublonsHomonymeRequest {
    DoublonEditDto?: DoublonEditDto;
}

export interface DoublonsSearchPersonneRequest {
    DoublonsPersonneSearchDto?: DoublonsPersonneSearchDto;
}

/**
 * 
 */
export class DoublonsApi extends runtime.BaseAPI {

    /**
     */
    async doublonsBaseSearchRaw(requestParameters: DoublonsBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DoublonsGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Doublons/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DoublonsSearchDtoToJSON(requestParameters.DoublonsSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DoublonsGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async doublonsBaseSearch(requestParameters: DoublonsBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DoublonsGridDtoPaginatedResults> {
        const response = await this.doublonsBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async doublonsFusionRaw(requestParameters: DoublonsFusionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Doublons/Fusion`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DoublonEditDtoToJSON(requestParameters.DoublonEditDto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async doublonsFusion(requestParameters: DoublonsFusionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.doublonsFusionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async doublonsGetDoublonRaw(requestParameters: DoublonsGetDoublonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DoublonEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.idpersonne1 !== undefined) {
            queryParameters['idpersonne1'] = requestParameters.idpersonne1;
        }

        if (requestParameters.idpersonne2 !== undefined) {
            queryParameters['idpersonne2'] = requestParameters.idpersonne2;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Doublons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DoublonEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async doublonsGetDoublon(requestParameters: DoublonsGetDoublonRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DoublonEditDto> {
        const response = await this.doublonsGetDoublonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async doublonsGetSearchCriteriasRaw(requestParameters: DoublonsGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Doublons/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async doublonsGetSearchCriterias(requestParameters: DoublonsGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.doublonsGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async doublonsGetSearchPersonneCriteriasRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Doublons/GetSearchPersonneCriterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async doublonsGetSearchPersonneCriterias(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.doublonsGetSearchPersonneCriteriasRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async doublonsGetSelectItemsRaw(requestParameters: DoublonsGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling doublonsGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Doublons/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async doublonsGetSelectItems(requestParameters: DoublonsGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.doublonsGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async doublonsHomonymeRaw(requestParameters: DoublonsHomonymeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Doublons/Homonyme`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DoublonEditDtoToJSON(requestParameters.DoublonEditDto),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async doublonsHomonyme(requestParameters: DoublonsHomonymeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.doublonsHomonymeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async doublonsSearchPersonneRaw(requestParameters: DoublonsSearchPersonneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DoublonsPersonneGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Doublons/SearchPersonne`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DoublonsPersonneSearchDtoToJSON(requestParameters.DoublonsPersonneSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DoublonsPersonneGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async doublonsSearchPersonne(requestParameters: DoublonsSearchPersonneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DoublonsPersonneGridDtoPaginatedResults> {
        const response = await this.doublonsSearchPersonneRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
