/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExperienceProfessionnelleFichierDto
 */
export interface ExperienceProfessionnelleFichierDto {
    /**
     * 
     * @type {number}
     * @memberof ExperienceProfessionnelleFichierDto
     */
    idexperienceProfessionnelleFichier?: number;
    /**
     * 
     * @type {number}
     * @memberof ExperienceProfessionnelleFichierDto
     */
    idexperienceProfessionnelle?: number;
    /**
     * 
     * @type {number}
     * @memberof ExperienceProfessionnelleFichierDto
     */
    idfichier?: number;
    /**
     * 
     * @type {number}
     * @memberof ExperienceProfessionnelleFichierDto
     */
    idtypeAcquisFichier?: number;
    /**
     * 
     * @type {string}
     * @memberof ExperienceProfessionnelleFichierDto
     */
    fileName?: string | null;
}

/**
 * Check if a given object implements the ExperienceProfessionnelleFichierDto interface.
 */
export function instanceOfExperienceProfessionnelleFichierDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExperienceProfessionnelleFichierDtoFromJSON(json: any): ExperienceProfessionnelleFichierDto {
    return ExperienceProfessionnelleFichierDtoFromJSONTyped(json, false);
}

export function ExperienceProfessionnelleFichierDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExperienceProfessionnelleFichierDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idexperienceProfessionnelleFichier': !exists(json, 'idexperienceProfessionnelleFichier') ? undefined : json['idexperienceProfessionnelleFichier'],
        'idexperienceProfessionnelle': !exists(json, 'idexperienceProfessionnelle') ? undefined : json['idexperienceProfessionnelle'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'idtypeAcquisFichier': !exists(json, 'idtypeAcquisFichier') ? undefined : json['idtypeAcquisFichier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
    };
}

export function ExperienceProfessionnelleFichierDtoToJSON(value?: ExperienceProfessionnelleFichierDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idexperienceProfessionnelleFichier': value.idexperienceProfessionnelleFichier,
        'idexperienceProfessionnelle': value.idexperienceProfessionnelle,
        'idfichier': value.idfichier,
        'idtypeAcquisFichier': value.idtypeAcquisFichier,
        'fileName': value.fileName,
    };
}

