/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbImpressionDto,
  FcbRechercheImpressionDtoPaginatedResults,
  FilterCriteriaInfo,
  RechercheImpressionSearch,
  SelectItem,
} from '../models/index';
import {
    FcbImpressionDtoFromJSON,
    FcbImpressionDtoToJSON,
    FcbRechercheImpressionDtoPaginatedResultsFromJSON,
    FcbRechercheImpressionDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    RechercheImpressionSearchFromJSON,
    RechercheImpressionSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface ImpressionBaseSearchRequest {
    RechercheImpressionSearch?: RechercheImpressionSearch;
}

export interface ImpressionDeleteRequest {
    id?: number;
}

export interface ImpressionGetRequest {
    id?: number;
}

export interface ImpressionGetDisplayNameRequest {
    id?: number;
}

export interface ImpressionGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ImpressionGetSelectItemsRequest {
    searchField: string;
}

export interface ImpressionSaveRequest {
    FcbImpressionDto?: FcbImpressionDto;
}

/**
 * 
 */
export class ImpressionApi extends runtime.BaseAPI {

    /**
     */
    async impressionBaseSearchRaw(requestParameters: ImpressionBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRechercheImpressionDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Impression/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RechercheImpressionSearchToJSON(requestParameters.RechercheImpressionSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRechercheImpressionDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async impressionBaseSearch(requestParameters: ImpressionBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRechercheImpressionDtoPaginatedResults> {
        const response = await this.impressionBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async impressionDeleteRaw(requestParameters: ImpressionDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Impression`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async impressionDelete(requestParameters: ImpressionDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.impressionDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async impressionGetRaw(requestParameters: ImpressionGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbImpressionDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Impression`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbImpressionDtoFromJSON(jsonValue));
    }

    /**
     */
    async impressionGet(requestParameters: ImpressionGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbImpressionDto> {
        const response = await this.impressionGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async impressionGetDisplayNameRaw(requestParameters: ImpressionGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Impression/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async impressionGetDisplayName(requestParameters: ImpressionGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.impressionGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async impressionGetSearchCriteriasRaw(requestParameters: ImpressionGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Impression/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async impressionGetSearchCriterias(requestParameters: ImpressionGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.impressionGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async impressionGetSelectItemsRaw(requestParameters: ImpressionGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling impressionGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Impression/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async impressionGetSelectItems(requestParameters: ImpressionGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.impressionGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async impressionSaveRaw(requestParameters: ImpressionSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbImpressionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Impression`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbImpressionDtoToJSON(requestParameters.FcbImpressionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbImpressionDtoFromJSON(jsonValue));
    }

    /**
     */
    async impressionSave(requestParameters: ImpressionSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbImpressionDto> {
        const response = await this.impressionSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
