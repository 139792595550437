import { FGTextAreaInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { DemandeApprenantApi, FcbDemandeApprenantDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterDateMaskInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

export interface IApprenantRecherchePatronDetailPageProps {}

export const ApprenantRecherchePatronDetailPage: React.FunctionComponent<IApprenantRecherchePatronDetailPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(DemandeApprenantApi);
  const { id, idRecherchePatron, state } = useParams<{ id: string; idRecherchePatron: string; state: string }>();
  const idApprenant = React.useMemo(() => +id, [id]);
  const IdRecherchePatron = React.useMemo(() => +idRecherchePatron, [idRecherchePatron]);

  const [metier, metierLoading] = useReferential(a => a.referentialGetMetier(), true, []);
  const [delegue, delegueLoading] = useReferential(a => a.referentialGetDelegue(), true, []);
  const [type, typeLoading] = useReferential(a => a.referentialGetDemandeType(), true, []);
  const [centre, centreLoading] = useReferential(a => a.referentialGetCentre(), true, []);

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      IdRecherchePatron <= 0
        ? FcbDemandeApprenantDtoFromJSON({ iddemande: 0, idapprenant: id })
        : api.demandeApprenantGet({ id: IdRecherchePatron }),
    saveApiFn: d => api.demandeApprenantSave({ FcbDemandeApprenantDto: d }),
    onSavedRoute: d => `${ERoutes.apprenant}/${idApprenant}/recherchepatron/${d.iddemande}/edit`,
    deleteApiFn: d => api.demandeApprenantDelete({ id: d.iddemande }),
    onDeletedRoute: d => `${ERoutes.apprenant}/${idApprenant}/recherchepatron`
  });

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape(
      {
        idtype: Yup.number().required(t(ETLCodes.Required)),
        idcentre: Yup.number()
          .nullable()
          .when("iddelegueTutelle", (value, schema) =>
            value === undefined || value === null
              ? schema
                  .required(t(ETLCodes.Required))
                  .notOneOf([0, -1, null, undefined], t(ETLCodes.CentreOuDelegueRequis))
              : schema
          ),
        iddelegueTutelle: Yup.number()
          .nullable()
          .when("idcentre", (value, schema) =>
            value === undefined || value === null
              ? schema
                  .required(t(ETLCodes.Required))
                  .notOneOf([0, -1, null, undefined], t(ETLCodes.CentreOuDelegueRequis))
              : schema
          ),
        date: Yup.string().required(t(ETLCodes.Required)),
        idmetier: Yup.number().required(t(ETLCodes.Required))
      },
      [["idcentre", "iddelegueTutelle"]]
    );
  }, [t]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={state === "edit"}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(`${ERoutes.apprenant}/${idApprenant}/recherchepatron`)}
      onDelete={deleteItem}
      showDeleteButton={IdRecherchePatron > 0}
      saving={saving}
      deleting={deleting}
      formatDate="dd-MM-yyyy"
      minLabelWidth={200}
    >
      <FieldGroup columns={1} fieldsetProps={{ title: t(ETLCodes.Edition), collapsable: false }}>
        <FGWalterSelectInput label={t(ETLCodes.Metier)} name="idmetier" items={metier} loading={metierLoading} />
        <FGWalterSelectInput
          label={t(ETLCodes.Delegue)}
          name="iddelegueTutelle"
          items={delegue}
          loading={delegueLoading}
        />
        <FGWalterSelectInput label={t(ETLCodes.Centre)} name="idcentre" items={centre} loading={centreLoading} />
        <FGWalterDateMaskInput label={t(ETLCodes.Date)} name="date" />
        <FGWalterSelectInput label={t(ETLCodes.Type)} name="idtype" items={type} loading={typeLoading} />
        <FGTextAreaInput label={t(ETLCodes.Description)} name="description" />
      </FieldGroup>
    </SmallFormGenerator>
  );
};
