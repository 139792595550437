/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ELieuFormationAgrementStatut } from './ELieuFormationAgrementStatut';
import {
    ELieuFormationAgrementStatutFromJSON,
    ELieuFormationAgrementStatutFromJSONTyped,
    ELieuFormationAgrementStatutToJSON,
} from './ELieuFormationAgrementStatut';

/**
 * 
 * @export
 * @interface OffreDemandeGridDto
 */
export interface OffreDemandeGridDto {
    /**
     * 
     * @type {number}
     * @memberof OffreDemandeGridDto
     */
    idlieuFormationAgrement?: number;
    /**
     * 
     * @type {number}
     * @memberof OffreDemandeGridDto
     */
    idlieuFormation?: number;
    /**
     * 
     * @type {number}
     * @memberof OffreDemandeGridDto
     */
    idsiegeSocial?: number;
    /**
     * 
     * @type {string}
     * @memberof OffreDemandeGridDto
     */
    nomLieuFormation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OffreDemandeGridDto
     */
    enseigneLieuFormation?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OffreDemandeGridDto
     */
    offreActive?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof OffreDemandeGridDto
     */
    capaciteFormative?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OffreDemandeGridDto
     */
    nbContrat?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OffreDemandeGridDto
     */
    readonly capaciteAtteinte?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OffreDemandeGridDto
     */
    codeMetier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OffreDemandeGridDto
     */
    nomMetier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OffreDemandeGridDto
     */
    nomStade?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof OffreDemandeGridDto
     */
    dateDecision?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof OffreDemandeGridDto
     */
    agrementPartielA?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OffreDemandeGridDto
     */
    agrementPartielB?: boolean;
    /**
     * 
     * @type {ELieuFormationAgrementStatut}
     * @memberof OffreDemandeGridDto
     */
    statut?: ELieuFormationAgrementStatut;
    /**
     * 
     * @type {number}
     * @memberof OffreDemandeGridDto
     */
    idEditableCurrentSuspension?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OffreDemandeGridDto
     */
    idlieuFormationAgrementOffre?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OffreDemandeGridDto
     */
    idreferentIfapme?: number | null;
}

/**
 * Check if a given object implements the OffreDemandeGridDto interface.
 */
export function instanceOfOffreDemandeGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OffreDemandeGridDtoFromJSON(json: any): OffreDemandeGridDto {
    return OffreDemandeGridDtoFromJSONTyped(json, false);
}

export function OffreDemandeGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OffreDemandeGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idlieuFormationAgrement': !exists(json, 'idlieuFormationAgrement') ? undefined : json['idlieuFormationAgrement'],
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'nomLieuFormation': !exists(json, 'nomLieuFormation') ? undefined : json['nomLieuFormation'],
        'enseigneLieuFormation': !exists(json, 'enseigneLieuFormation') ? undefined : json['enseigneLieuFormation'],
        'offreActive': !exists(json, 'offreActive') ? undefined : json['offreActive'],
        'capaciteFormative': !exists(json, 'capaciteFormative') ? undefined : json['capaciteFormative'],
        'nbContrat': !exists(json, 'nbContrat') ? undefined : json['nbContrat'],
        'capaciteAtteinte': !exists(json, 'capaciteAtteinte') ? undefined : json['capaciteAtteinte'],
        'codeMetier': !exists(json, 'codeMetier') ? undefined : json['codeMetier'],
        'nomMetier': !exists(json, 'nomMetier') ? undefined : json['nomMetier'],
        'nomStade': !exists(json, 'nomStade') ? undefined : json['nomStade'],
        'dateDecision': !exists(json, 'dateDecision') ? undefined : (json['dateDecision'] === null ? null : new Date(json['dateDecision'])),
        'agrementPartielA': !exists(json, 'agrementPartielA') ? undefined : json['agrementPartielA'],
        'agrementPartielB': !exists(json, 'agrementPartielB') ? undefined : json['agrementPartielB'],
        'statut': !exists(json, 'statut') ? undefined : ELieuFormationAgrementStatutFromJSON(json['statut']),
        'idEditableCurrentSuspension': !exists(json, 'idEditableCurrentSuspension') ? undefined : json['idEditableCurrentSuspension'],
        'idlieuFormationAgrementOffre': !exists(json, 'idlieuFormationAgrementOffre') ? undefined : json['idlieuFormationAgrementOffre'],
        'idreferentIfapme': !exists(json, 'idreferentIfapme') ? undefined : json['idreferentIfapme'],
    };
}

export function OffreDemandeGridDtoToJSON(value?: OffreDemandeGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idlieuFormationAgrement': value.idlieuFormationAgrement,
        'idlieuFormation': value.idlieuFormation,
        'idsiegeSocial': value.idsiegeSocial,
        'nomLieuFormation': value.nomLieuFormation,
        'enseigneLieuFormation': value.enseigneLieuFormation,
        'offreActive': value.offreActive,
        'capaciteFormative': value.capaciteFormative,
        'nbContrat': value.nbContrat,
        'codeMetier': value.codeMetier,
        'nomMetier': value.nomMetier,
        'nomStade': value.nomStade,
        'dateDecision': value.dateDecision === undefined ? undefined : (value.dateDecision === null ? null : value.dateDecision.toISOString()),
        'agrementPartielA': value.agrementPartielA,
        'agrementPartielB': value.agrementPartielB,
        'statut': ELieuFormationAgrementStatutToJSON(value.statut),
        'idEditableCurrentSuspension': value.idEditableCurrentSuspension,
        'idlieuFormationAgrementOffre': value.idlieuFormationAgrementOffre,
        'idreferentIfapme': value.idreferentIfapme,
    };
}

