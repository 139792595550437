import { Tag } from "@blueprintjs/core";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { DashboardApi, EDashboard, EIfapmeSide } from "../../api";
import { LoadingDots } from "../../components";
import { useEventsContext } from "../../contexts";
import { useApiService, useTheme } from "../../hooks";

const TabTitleContainer = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 5px;
  }
`;

export interface IDashboardTabTitleProps {
  title: string;
  dashboard: EDashboard;
}

export const DashboardTabTitle: React.FunctionComponent<IDashboardTabTitleProps> = ({ dashboard, title }) => {
  const api = useApiService(DashboardApi);
  const { ifapmeSide } = useTheme();
  const isHope = React.useMemo(() => ifapmeSide === "hope", [ifapmeSide]);

  const fetchCount = React.useCallback(async () => {
    if (
      (isHope &&
        [
          EDashboard.Inscriptions,
          EDashboard.Absence,
          EDashboard.DossiersSorties,
          EDashboard.DossiersPostFormation,
          EDashboard.DossiersFinAgrement,
          EDashboard.Suivis,
          EDashboard.SuivisSL
        ].includes(dashboard)) ||
      (!isHope &&
        [
          EDashboard.Inscriptions,
          EDashboard.Suivis,
          EDashboard.DossiersFinAgrement,
          EDashboard.DossiersPostFormation,
          EDashboard.DossiersSorties,
          EDashboard.PratiquePro
        ].includes(dashboard))
    ) {
      return await api.dashboardGetCounts({
        ifapmeSide: ifapmeSide === "walter" ? EIfapmeSide.Walter : EIfapmeSide.Hope,
        dashboard
      });
    }
  }, [api, dashboard, ifapmeSide, isHope]);
  const { refetch: refetchCounts, isFetching: loading, data: nbToDisplay } = useQuery(
    ["dashboard-counts", dashboard],
    fetchCount,
    {
      retry: false
    }
  );

  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();
  React.useEffect(() => {
    subscribeToEvent(("DASHBOARD_COUNTS_REFRESH_" + dashboard) as any, refetchCounts);
    return () => {
      unsubscribeEvent(("DASHBOARD_COUNTS_REFRESH_" + dashboard) as any, refetchCounts);
    };
  }, [dashboard, refetchCounts, subscribeToEvent, unsubscribeEvent]);

  return (
    <TabTitleContainer>
      <span>{title}</span>
      {loading ? (
        <Tag round intent="primary">
          <LoadingDots colorScheme="white" size={3} />
        </Tag>
      ) : (
        nbToDisplay > 0 && (
          <Tag round intent="primary">
            {nbToDisplay}
          </Tag>
        )
      )}
    </TabTitleContainer>
  );
};
