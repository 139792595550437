/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { BooleanSearch } from './BooleanSearch';
import {
    BooleanSearchFromJSON,
    BooleanSearchFromJSONTyped,
    BooleanSearchToJSON,
} from './BooleanSearch';
import type { StringListSearch } from './StringListSearch';
import {
    StringListSearchFromJSON,
    StringListSearchFromJSONTyped,
    StringListSearchToJSON,
} from './StringListSearch';
import type { TextSearch } from './TextSearch';
import {
    TextSearchFromJSON,
    TextSearchFromJSONTyped,
    TextSearchToJSON,
} from './TextSearch';
import type { UnaccentTextSearch } from './UnaccentTextSearch';
import {
    UnaccentTextSearchFromJSON,
    UnaccentTextSearchFromJSONTyped,
    UnaccentTextSearchToJSON,
} from './UnaccentTextSearch';

/**
 * 
 * @export
 * @interface SiegeSocialSearchDto
 */
export interface SiegeSocialSearchDto {
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof SiegeSocialSearchDto
     */
    denominationSociale?: UnaccentTextSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof SiegeSocialSearchDto
     */
    nomFormation?: UnaccentTextSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof SiegeSocialSearchDto
     */
    enseigne?: UnaccentTextSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof SiegeSocialSearchDto
     */
    chefEntrepriseFullName?: UnaccentTextSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof SiegeSocialSearchDto
     */
    contactFullName?: UnaccentTextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof SiegeSocialSearchDto
     */
    numeroBce?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof SiegeSocialSearchDto
     */
    numeroUe?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof SiegeSocialSearchDto
     */
    telephone?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof SiegeSocialSearchDto
     */
    gsm?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof SiegeSocialSearchDto
     */
    codeMetier?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof SiegeSocialSearchDto
     */
    nomMetier?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof SiegeSocialSearchDto
     */
    service?: TextSearch;
    /**
     * 
     * @type {StringListSearch}
     * @memberof SiegeSocialSearchDto
     */
    stade?: StringListSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof SiegeSocialSearchDto
     */
    codeContrat?: TextSearch;
    /**
     * 
     * @type {StringListSearch}
     * @memberof SiegeSocialSearchDto
     */
    statutContrat?: StringListSearch;
    /**
     * 
     * @type {StringListSearch}
     * @memberof SiegeSocialSearchDto
     */
    statutAgrement?: StringListSearch;
    /**
     * 
     * @type {BooleanSearch}
     * @memberof SiegeSocialSearchDto
     */
    actif?: BooleanSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof SiegeSocialSearchDto
     */
    codePostal?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof SiegeSocialSearchDto
     */
    commune?: TextSearch;
    /**
     * 
     * @type {Array<string>}
     * @memberof SiegeSocialSearchDto
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof SiegeSocialSearchDto
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialSearchDto
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialSearchDto
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof SiegeSocialSearchDto
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialSearchDto
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the SiegeSocialSearchDto interface.
 */
export function instanceOfSiegeSocialSearchDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SiegeSocialSearchDtoFromJSON(json: any): SiegeSocialSearchDto {
    return SiegeSocialSearchDtoFromJSONTyped(json, false);
}

export function SiegeSocialSearchDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiegeSocialSearchDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'denominationSociale': !exists(json, 'denominationSociale') ? undefined : UnaccentTextSearchFromJSON(json['denominationSociale']),
        'nomFormation': !exists(json, 'nomFormation') ? undefined : UnaccentTextSearchFromJSON(json['nomFormation']),
        'enseigne': !exists(json, 'enseigne') ? undefined : UnaccentTextSearchFromJSON(json['enseigne']),
        'chefEntrepriseFullName': !exists(json, 'chefEntrepriseFullName') ? undefined : UnaccentTextSearchFromJSON(json['chefEntrepriseFullName']),
        'contactFullName': !exists(json, 'contactFullName') ? undefined : UnaccentTextSearchFromJSON(json['contactFullName']),
        'numeroBce': !exists(json, 'numeroBce') ? undefined : TextSearchFromJSON(json['numeroBce']),
        'numeroUe': !exists(json, 'numeroUe') ? undefined : TextSearchFromJSON(json['numeroUe']),
        'telephone': !exists(json, 'telephone') ? undefined : TextSearchFromJSON(json['telephone']),
        'gsm': !exists(json, 'gsm') ? undefined : TextSearchFromJSON(json['gsm']),
        'codeMetier': !exists(json, 'codeMetier') ? undefined : TextSearchFromJSON(json['codeMetier']),
        'nomMetier': !exists(json, 'nomMetier') ? undefined : TextSearchFromJSON(json['nomMetier']),
        'service': !exists(json, 'service') ? undefined : TextSearchFromJSON(json['service']),
        'stade': !exists(json, 'stade') ? undefined : StringListSearchFromJSON(json['stade']),
        'codeContrat': !exists(json, 'codeContrat') ? undefined : TextSearchFromJSON(json['codeContrat']),
        'statutContrat': !exists(json, 'statutContrat') ? undefined : StringListSearchFromJSON(json['statutContrat']),
        'statutAgrement': !exists(json, 'statutAgrement') ? undefined : StringListSearchFromJSON(json['statutAgrement']),
        'actif': !exists(json, 'actif') ? undefined : BooleanSearchFromJSON(json['actif']),
        'codePostal': !exists(json, 'codePostal') ? undefined : TextSearchFromJSON(json['codePostal']),
        'commune': !exists(json, 'commune') ? undefined : TextSearchFromJSON(json['commune']),
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function SiegeSocialSearchDtoToJSON(value?: SiegeSocialSearchDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'denominationSociale': UnaccentTextSearchToJSON(value.denominationSociale),
        'nomFormation': UnaccentTextSearchToJSON(value.nomFormation),
        'enseigne': UnaccentTextSearchToJSON(value.enseigne),
        'chefEntrepriseFullName': UnaccentTextSearchToJSON(value.chefEntrepriseFullName),
        'contactFullName': UnaccentTextSearchToJSON(value.contactFullName),
        'numeroBce': TextSearchToJSON(value.numeroBce),
        'numeroUe': TextSearchToJSON(value.numeroUe),
        'telephone': TextSearchToJSON(value.telephone),
        'gsm': TextSearchToJSON(value.gsm),
        'codeMetier': TextSearchToJSON(value.codeMetier),
        'nomMetier': TextSearchToJSON(value.nomMetier),
        'service': TextSearchToJSON(value.service),
        'stade': StringListSearchToJSON(value.stade),
        'codeContrat': TextSearchToJSON(value.codeContrat),
        'statutContrat': StringListSearchToJSON(value.statutContrat),
        'statutAgrement': StringListSearchToJSON(value.statutAgrement),
        'actif': BooleanSearchToJSON(value.actif),
        'codePostal': TextSearchToJSON(value.codePostal),
        'commune': TextSearchToJSON(value.commune),
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

