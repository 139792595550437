import * as React from "react";
import Cleave from "cleave.js/react";
import { Classes, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

export interface ITableGlobalDateEditorProps {
  initialTextValue: string;
  onValueChanged: (value: string) => void;
  textEditorHeight?: string | number;
  readonly?: boolean;
}

export const TableGlobalDateEditor: React.FunctionComponent<ITableGlobalDateEditorProps> = ({
  initialTextValue,
  onValueChanged,
  textEditorHeight = "auto",
  readonly = false
}) => {
  const [currentValue, setCurrentValue] = React.useState<string>();
  const [editedValue, setEditedValue] = React.useState<string>();
  const [focused, setFocused] = React.useState(false);

  React.useEffect(() => {
    setCurrentValue(initialTextValue);
    setEditedValue(initialTextValue);
  }, [initialTextValue]);

  const input = React.useRef<HTMLInputElement>();

  const onBlur = React.useCallback(
    async (e: any) => {
      if (editedValue !== currentValue) {
        setCurrentValue(editedValue);
        onValueChanged(editedValue);
      }
      setFocused(false);
    },
    [currentValue, editedValue, onValueChanged]
  );

  const computeFocus = React.useCallback(() => {
    if (!focused) {
      setFocused(true);
    }
  }, [focused]);

  const cursor = React.useMemo(() => (readonly ? "text" : "pointer"), [readonly]);

  return (
    <>
      {focused && !readonly ? (
        <div className={`${Classes.INPUT_GROUP} ${Classes.FILL}`}>
          <Cleave
            autoFocus
            style={{ borderRadius: "none", cursor }}
            onChange={e => setEditedValue(e.target.value)}
            autoComplete="new-password"
            onBlur={onBlur}
            onClick={computeFocus}
            value={editedValue}
            placeholder={""}
            options={{ date: true, delimiter: "/", datePattern: ["d", "m", "Y"] }}
            className={`${Classes.INPUT}`}
            htmlRef={hRef => (input.current = hRef)}
          />
          <Icon icon={IconNames.CALENDAR} />
        </div>
      ) : (
        <div
          style={{
            marginLeft: 9,
            marginTop: 4,
            height: textEditorHeight,
            cursor
          }}
          onClick={() => computeFocus()}
        >
          {editedValue}
        </div>
      )}
    </>
  );
};
