import { Page } from "react-pdf";
import React from "react";
import { PDFDocumentProxy } from "pdfjs-dist/types/display/api";
import styled from "styled-components";
import { Colors } from "@blueprintjs/core";

export type PdfViewerThumbnailsProps = {
  pdf: PDFDocumentProxy;
  thumbnailWidth?: number;
  setPageNumber: (pageNumber: number) => void;
};

const ThumbnailContainer = styled.div<{ thumbnailWidth: number }>`
  overflow-y: hidden;
  overflow-x: hidden;
  scrollbar-width: thin;
  border: ${Colors.GRAY4} solid 1px;
  min-width: calc(${(props) => props.thumbnailWidth}px + 1.5rem);
  :hover {
    overflow-y: auto;
  }
`;

const ThumbnailPage = styled.div<{ thumbnailWidth: number }>`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0.75rem;
  flex: 1;

  > .page > * {
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  }

  .overlay {
    background-color: rgba(197, 203, 211, 0.5);
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    width: ${(props) => props.thumbnailWidth}px;
    height: calc(100% - (1.5rem));
    display: none;
    border-radius: 0.5rem;
  }

  :hover {
    .overlay {
      display: block;
    }
  }
`;

export default ({
  pdf,
  thumbnailWidth = 125,
  setPageNumber,
}: PdfViewerThumbnailsProps) => (
  <ThumbnailContainer thumbnailWidth={thumbnailWidth}>
    {Array.from(new Array(pdf.numPages), (el, index) => {
      return (
        <ThumbnailPage thumbnailWidth={thumbnailWidth}>
          <Page
            className={"page"}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            width={thumbnailWidth}
          />
          <div
            className={"overlay"}
            onClick={() => setPageNumber(index + 1)}
          ></div>
        </ThumbnailPage>
      );
    })}
  </ThumbnailContainer>
);
