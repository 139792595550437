/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExamenEntreeFichierGridDto } from './ExamenEntreeFichierGridDto';
import {
    ExamenEntreeFichierGridDtoFromJSON,
    ExamenEntreeFichierGridDtoFromJSONTyped,
    ExamenEntreeFichierGridDtoToJSON,
} from './ExamenEntreeFichierGridDto';

/**
 * 
 * @export
 * @interface ExamenEntreeEditDto
 */
export interface ExamenEntreeEditDto {
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeEditDto
     */
    idexamenEntree?: number;
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeEditDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeEditDto
     */
    pointsmath?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeEditDto
     */
    pointsfrancais?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeEditDto
     */
    pointslogique?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ExamenEntreeEditDto
     */
    annee?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExamenEntreeEditDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeEditDto
     */
    resultat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeEditDto
     */
    examinateur?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExamenEntreeEditDto
     */
    commentaire?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ExamenEntreeEditDto
     */
    idanneeDeFormation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExamenEntreeEditDto
     */
    typeAcquis?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ExamenEntreeEditDto
     */
    date?: Date | null;
    /**
     * 
     * @type {Array<ExamenEntreeFichierGridDto>}
     * @memberof ExamenEntreeEditDto
     */
    fichiers?: Array<ExamenEntreeFichierGridDto> | null;
}

/**
 * Check if a given object implements the ExamenEntreeEditDto interface.
 */
export function instanceOfExamenEntreeEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExamenEntreeEditDtoFromJSON(json: any): ExamenEntreeEditDto {
    return ExamenEntreeEditDtoFromJSONTyped(json, false);
}

export function ExamenEntreeEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExamenEntreeEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idexamenEntree': !exists(json, 'idexamenEntree') ? undefined : json['idexamenEntree'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'pointsmath': !exists(json, 'pointsmath') ? undefined : json['pointsmath'],
        'pointsfrancais': !exists(json, 'pointsfrancais') ? undefined : json['pointsfrancais'],
        'pointslogique': !exists(json, 'pointslogique') ? undefined : json['pointslogique'],
        'annee': !exists(json, 'annee') ? undefined : json['annee'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'resultat': !exists(json, 'resultat') ? undefined : json['resultat'],
        'examinateur': !exists(json, 'examinateur') ? undefined : json['examinateur'],
        'commentaire': !exists(json, 'commentaire') ? undefined : json['commentaire'],
        'idanneeDeFormation': !exists(json, 'idanneeDeFormation') ? undefined : json['idanneeDeFormation'],
        'typeAcquis': !exists(json, 'typeAcquis') ? undefined : json['typeAcquis'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'fichiers': !exists(json, 'fichiers') ? undefined : (json['fichiers'] === null ? null : (json['fichiers'] as Array<any>).map(ExamenEntreeFichierGridDtoFromJSON)),
    };
}

export function ExamenEntreeEditDtoToJSON(value?: ExamenEntreeEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idexamenEntree': value.idexamenEntree,
        'idpersonne': value.idpersonne,
        'pointsmath': value.pointsmath,
        'pointsfrancais': value.pointsfrancais,
        'pointslogique': value.pointslogique,
        'annee': value.annee,
        'remarque': value.remarque,
        'resultat': value.resultat,
        'examinateur': value.examinateur,
        'commentaire': value.commentaire,
        'idanneeDeFormation': value.idanneeDeFormation,
        'typeAcquis': value.typeAcquis,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'fichiers': value.fichiers === undefined ? undefined : (value.fichiers === null ? null : (value.fichiers as Array<any>).map(ExamenEntreeFichierGridDtoToJSON)),
    };
}

