import { Button, Card, Intent } from "@blueprintjs/core";
import { getIn } from "formik";
import { FGCustomInput, IFGCustomInputProps, useFGContext } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { AdresseInfos, PersonneAdresseSelectorDialog } from "..";
import { EditButton } from "../..";
import { AdresseDto } from "../../../api";
import { useTl } from "../../../hooks";
import { ETLCodes } from "../../../locales";

const AdresseContainer = styled(Card)`
  display: flex;
  justify-content: center;
`;

export interface IFGPersonneAdresseSelectorProps extends Omit<IFGCustomInputProps, "children"> {
  idpersonne: number;
  disabled?: boolean;
}

export const FGPersonneAdresseSelector: React.FunctionComponent<IFGPersonneAdresseSelectorProps> = ({
  idpersonne,
  name,
  label,
  disabled = false,
  ...fgCustomInputProps
}) => {
  const { t } = useTl();
  const ctx = useFGContext();
  const [adresseSelectorDialogOpen, setAdresseSelectorDialogOpen] = React.useState(false);

  const adressePersonne = React.useMemo(() => getIn(ctx?.formik, name) as AdresseDto, [ctx?.formik, name]);

  const onClosed = React.useCallback(
    (adresse?: AdresseDto) => {
      ctx?.formik?.setFieldValue(name, adresse);
      setAdresseSelectorDialogOpen(false);
    },
    [ctx?.formik, name]
  );

  const onOpen = React.useCallback(() => {
    setAdresseSelectorDialogOpen(true);
  }, []);

  return (
    <>
      <FGCustomInput name={name} label={label} {...fgCustomInputProps}>
        {ctx =>
          adressePersonne ? (
            <AdresseContainer>
              <AdresseInfos data={adressePersonne} />
              <EditButton onClick={onOpen} />
            </AdresseContainer>
          ) : (
            <Button
              minimal
              onClick={onOpen}
              text={t(ETLCodes.SelectAnAdress)}
              intent={Intent.PRIMARY}
              loading={ctx.loading}
              disabled={disabled || ctx.disabled}
            />
          )
        }
      </FGCustomInput>
      <PersonneAdresseSelectorDialog
        onClosed={onClosed}
        onAdresseSelected={onClosed}
        selectorDialogOpen={adresseSelectorDialogOpen}
        idpersonne={idpersonne}
      />
    </>
  );
};
