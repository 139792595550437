import * as React from "react";
import { useQuery } from "react-query";
import {
  RapportHopeAccesPermissionsGrid,
  RapportHopeModulePermissionsGrid,
  RapportHopeServicePermissionsGrid
} from ".";
import { RapportHopeApi } from "../../../../../../api";
import { useApiService } from "../../../../../../hooks";

export interface IRapportHopePermissionsPageProps {
  idrapportHope: number;
}

export const RapportHopePermissionsPage: React.FunctionComponent<IRapportHopePermissionsPageProps> = ({
  idrapportHope
}) => {
  const api = useApiService(RapportHopeApi);

  const fetchPermissions = React.useCallback(() => api.rapportHopeGetPermissions({ idrapportHope }), [
    api,
    idrapportHope
  ]);
  const { data, isFetching: loading } = useQuery(["rapport-hope-permission", idrapportHope], fetchPermissions);

  return (
    <>
      <RapportHopeServicePermissionsGrid idrapportHope={idrapportHope} services={data?.services} loading={loading} />
      <RapportHopeAccesPermissionsGrid idrapportHope={idrapportHope} acces={data?.acces} loading={loading} />
      <RapportHopeModulePermissionsGrid idrapportHope={idrapportHope} modules={data?.modules} loading={loading} />
    </>
  );
};
