/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatutApprenantDto
 */
export interface StatutApprenantDto {
    /**
     * 
     * @type {number}
     * @memberof StatutApprenantDto
     */
    idstatutApprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof StatutApprenantDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof StatutApprenantDto
     */
    idstatutSocial?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof StatutApprenantDto
     */
    dateStatut?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof StatutApprenantDto
     */
    idmotifSortie?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StatutApprenantDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof StatutApprenantDto
     */
    creationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof StatutApprenantDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof StatutApprenantDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof StatutApprenantDto
     */
    idcategorieStatutSocial?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StatutApprenantDto
     */
    idtypeSortie?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StatutApprenantDto
     */
    origineSortie?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatutApprenantDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatutApprenantDto
     */
    postSortie?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatutApprenantDto
     */
    suiviTermine?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StatutApprenantDto
     */
    remarqueRefus?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StatutApprenantDto
     */
    iddureeInoccupation?: number | null;
}

/**
 * Check if a given object implements the StatutApprenantDto interface.
 */
export function instanceOfStatutApprenantDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StatutApprenantDtoFromJSON(json: any): StatutApprenantDto {
    return StatutApprenantDtoFromJSONTyped(json, false);
}

export function StatutApprenantDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatutApprenantDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idstatutApprenant': !exists(json, 'idstatutApprenant') ? undefined : json['idstatutApprenant'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idstatutSocial': !exists(json, 'idstatutSocial') ? undefined : json['idstatutSocial'],
        'dateStatut': !exists(json, 'dateStatut') ? undefined : (json['dateStatut'] === null ? null : new Date(json['dateStatut'])),
        'idmotifSortie': !exists(json, 'idmotifSortie') ? undefined : json['idmotifSortie'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'idcategorieStatutSocial': !exists(json, 'idcategorieStatutSocial') ? undefined : json['idcategorieStatutSocial'],
        'idtypeSortie': !exists(json, 'idtypeSortie') ? undefined : json['idtypeSortie'],
        'origineSortie': !exists(json, 'origineSortie') ? undefined : json['origineSortie'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'postSortie': !exists(json, 'postSortie') ? undefined : json['postSortie'],
        'suiviTermine': !exists(json, 'suiviTermine') ? undefined : json['suiviTermine'],
        'remarqueRefus': !exists(json, 'remarqueRefus') ? undefined : json['remarqueRefus'],
        'iddureeInoccupation': !exists(json, 'iddureeInoccupation') ? undefined : json['iddureeInoccupation'],
    };
}

export function StatutApprenantDtoToJSON(value?: StatutApprenantDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idstatutApprenant': value.idstatutApprenant,
        'idapprenant': value.idapprenant,
        'idstatutSocial': value.idstatutSocial,
        'dateStatut': value.dateStatut === undefined ? undefined : (value.dateStatut === null ? null : value.dateStatut.toISOString()),
        'idmotifSortie': value.idmotifSortie,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'idcategorieStatutSocial': value.idcategorieStatutSocial,
        'idtypeSortie': value.idtypeSortie,
        'origineSortie': value.origineSortie,
        'remarque': value.remarque,
        'postSortie': value.postSortie,
        'suiviTermine': value.suiviTermine,
        'remarqueRefus': value.remarqueRefus,
        'iddureeInoccupation': value.iddureeInoccupation,
    };
}

