/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EJours {
    DimShortName = 'DimShortName',
    LunShortName = 'LunShortName',
    MarShortName = 'MarShortName',
    MerShortName = 'MerShortName',
    JeuShortName = 'JeuShortName',
    VenShortName = 'VenShortName',
    SamShortName = 'SamShortName'
}


export function EJoursFromJSON(json: any): EJours {
    return EJoursFromJSONTyped(json, false);
}

export function EJoursFromJSONTyped(json: any, ignoreDiscriminator: boolean): EJours {
    return json as EJours;
}

export function EJoursToJSON(value?: EJours | null): any {
    return value as any;
}

