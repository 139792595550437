/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssuranceEditDto,
  AssuranceGridDtoPaginatedResults,
  AssuranceSearchDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
} from '../models/index';
import {
    AssuranceEditDtoFromJSON,
    AssuranceEditDtoToJSON,
    AssuranceGridDtoPaginatedResultsFromJSON,
    AssuranceGridDtoPaginatedResultsToJSON,
    AssuranceSearchDtoFromJSON,
    AssuranceSearchDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface AssuranceBaseSearchRequest {
    AssuranceSearchDto?: AssuranceSearchDto;
}

export interface AssuranceDeleteAssuranceRequest {
    id?: number;
}

export interface AssuranceGetAssuranceRequest {
    id?: number;
}

export interface AssuranceGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface AssuranceGetSelectItemsRequest {
    searchField: string;
}

export interface AssuranceSaveAssuranceRequest {
    AssuranceEditDto?: AssuranceEditDto;
}

/**
 * 
 */
export class AssuranceApi extends runtime.BaseAPI {

    /**
     */
    async assuranceBaseSearchRaw(requestParameters: AssuranceBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssuranceGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Assurance/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssuranceSearchDtoToJSON(requestParameters.AssuranceSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssuranceGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async assuranceBaseSearch(requestParameters: AssuranceBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssuranceGridDtoPaginatedResults> {
        const response = await this.assuranceBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async assuranceDeleteAssuranceRaw(requestParameters: AssuranceDeleteAssuranceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Assurance`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async assuranceDeleteAssurance(requestParameters: AssuranceDeleteAssuranceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.assuranceDeleteAssuranceRaw(requestParameters, initOverrides);
    }

    /**
     */
    async assuranceGetAssuranceRaw(requestParameters: AssuranceGetAssuranceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssuranceEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Assurance`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssuranceEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async assuranceGetAssurance(requestParameters: AssuranceGetAssuranceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssuranceEditDto> {
        const response = await this.assuranceGetAssuranceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async assuranceGetSearchCriteriasRaw(requestParameters: AssuranceGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Assurance/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async assuranceGetSearchCriterias(requestParameters: AssuranceGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.assuranceGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async assuranceGetSelectItemsRaw(requestParameters: AssuranceGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling assuranceGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Assurance/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async assuranceGetSelectItems(requestParameters: AssuranceGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.assuranceGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async assuranceSaveAssuranceRaw(requestParameters: AssuranceSaveAssuranceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssuranceEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Assurance`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssuranceEditDtoToJSON(requestParameters.AssuranceEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssuranceEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async assuranceSaveAssurance(requestParameters: AssuranceSaveAssuranceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssuranceEditDto> {
        const response = await this.assuranceSaveAssuranceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
