import * as React from "react";
import { FormateurPrestationsGlobales } from "./FormateurPrestationsGlobales";
import { FormateurPrestationsContrat } from "./FormateurPrestationsContrat";

export interface IFormateurPrestationsProps {
  formateurId: number;
}

export const FormateurPrestations: React.FunctionComponent<IFormateurPrestationsProps> = ({ formateurId }) => {
  return (
    <>
      <FormateurPrestationsGlobales formateurId={formateurId} />
      <FormateurPrestationsContrat formateurId={formateurId} />
    </>
  );
};
