import * as React from "react";
import { Switch, Route } from "react-router";
import { ERoutes } from "../../../../../AppRouter";
import { ClasseAgrementLocalisationItemPage } from "./ClasseAgrementLocalisationItemPage";
import { ClasseAgrementLocalisationFinanceDetailPage } from "./ClasseAgrementLocalisationFinanceDetailPage";

export interface IClasseAgrementLocalisationSwitchProps {}

export const ClasseAgrementLocalisationSwitch: React.FunctionComponent<IClasseAgrementLocalisationSwitchProps> = () => {
  return (
    <Switch>
      <Route path={`${ERoutes.classe}/:id/:tab/:state`} component={ClasseAgrementLocalisationItemPage} exact />
      <Route
        path={`${ERoutes.classe}/:id/:tab/:idFinancement/:state`}
        component={ClasseAgrementLocalisationFinanceDetailPage}
        exact
      />
    </Switch>
  );
};
