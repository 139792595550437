import { Classes, Dialog } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";
import { AssuranceApi, AssuranceEditDto, AssuranceEditDtoFromJSON } from "../../../../../api";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import * as Yup from "yup";
import { ETLCodes } from "../../../../../locales";
import { FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { FGTextInput, FieldGroup } from "nsitools-react";
import { useReferential } from "../../../../../hooks/useReferential";

const StyledDialog = styled(Dialog)`
  width: 600px;
  height: auto;
  background-color: white;
`;

export interface ISiegeSocialAssuranceDialogProps {
  currentAssuranceId: number;
  idsiegeSocial: number;
  onClose: () => void;
}

export const SiegeSocialAssuranceDialog: React.FunctionComponent<ISiegeSocialAssuranceDialogProps> = ({
  currentAssuranceId,
  onClose,
  idsiegeSocial
}) => {
  const { t } = useTl();
  const api = useApiService(AssuranceApi);

  const { data, loading, saveItem, saving, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () => {
          return currentAssuranceId <= 0
            ? AssuranceEditDtoFromJSON({ idsiegeSocial })
            : api.assuranceGetAssurance({ id: currentAssuranceId });
        },
        saveApiFn: async (d: AssuranceEditDto) => {
          return api.assuranceSaveAssurance({ AssuranceEditDto: d });
        },
        onSaved: () => onClose()
      }),
      [api, currentAssuranceId, idsiegeSocial, onClose]
    )
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape({
        idcompagnieAssurance: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required))
      }),
    [t]
  );

  const [compagniesAssurances, caLoading] = useReferential(a => a.referentialGetCompagniesAssurance());

  return (
    <StyledDialog title={t(ETLCodes.Assurance)} isOpen={!!currentAssuranceId} onClose={() => onClose()}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose()}
          showDeleteButton={false}
          saving={saving}
          loading={loading}
          validationErrors={validationErrors}
        >
          <FieldGroup>
            <FGWalterSelectInput
              name="idcompagnieAssurance"
              label={t(ETLCodes.CompagnieAssurance)}
              items={compagniesAssurances}
              loading={caLoading}
            />
            <FGTextInput name="numeroPolice" label={t(ETLCodes.NumeroPolice)} />
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
