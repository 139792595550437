import { Intent } from "@blueprintjs/core";
import { DataTable, FieldSet, IDataTableColumn, useGridState } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { VisiteClasseApi, VisiteClasseSearch } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton } from "../../../../../components";
import { useAbortableApiServiceFactory, useSearchApi, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IVisiteClasseLieeListPageProps {}

export const VisiteClasseLieeListPage: React.FunctionComponent<IVisiteClasseLieeListPageProps> = props => {
  const { t } = useTl();
  const history = useHistory();

  const apiFactory = useAbortableApiServiceFactory(VisiteClasseApi);
  const lastAbortController = React.useRef<AbortController>();
  const { id } = useParams<{ id: string; tab: string; state: string }>();
  const idVisite = React.useMemo(() => +id, [id]);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { nom: "ASC" }
  });
  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    async (sObj?: VisiteClasseSearch) => {
      sObj.idVisite = idVisite;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return await abortableApi.visiteClasseBaseSearch({ VisiteClasseSearch: sObj });
    },
    [apiFactory, idVisite]
  );
  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true,
    onAbort
  });

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.Classe),
        fieldName: "nom"
      }
    ],
    [t]
  );

  return (
    <>
      <FieldSet
        title={t(ETLCodes.TableResults, { count: totalCount })}
        rightElement={
          <EditButton
            onClick={() => history.push(`${ERoutes.visiteExterieure}/${idVisite}/classeLiee/selector`)}
            intent={Intent.PRIMARY}
            text={t(ETLCodes.Modify)}
          />
        }
      >
        <DataTable
          dateFormat="dd/MM/yyyy"
          tableState={tableState}
          loading={loading}
          columns={columns}
          filterMode="OnEnter"
        ></DataTable>
      </FieldSet>
    </>
  );
};
