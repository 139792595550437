import { IOptionProps } from "@blueprintjs/core";
import { differenceInDays } from "date-fns";
import { FGCustomPanel, FGNumberInput, FGTextInput, FieldGroup, useFGContext, usePrevious } from "nsitools-react";
import * as React from "react";
import { ContratEditDto, FichierApi } from "../../../../../../api";
import {
  CommuneSelect,
  FGWalterDateMaskInput,
  FGWalterFileInput,
  FGWalterSelectInput,
  LocaliteSelect
} from "../../../../../../components";
import FGCopyTextInput from "../../../../../../components/formGenerator/FGCopyTextInput";
import { useApiService, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

export interface IContratConventionStageExternePanelProps {
  metiers: IOptionProps[];
  mLoading: boolean;
}

export const ContratConventionStageExternePanel: React.FunctionComponent<IContratConventionStageExternePanelProps> = ({
  metiers,
  mLoading
}) => {
  const { t } = useTl();
  const { formik } = useFGContext<ContratEditDto>();

  const [, , , rawCodePostaux] = useReferential(a => a.referentialGetCodePostaux());
  const [statutsContrat, statutsContratLoading] = useReferential(a => a.referentialGetStatutContratWalter());
  const previousCodePostalText = usePrevious(formik?.values?.contratConventionStageExterneDto?.codePostalText);
  React.useEffect(() => {
    if (
      !formik?.values?.contratConventionStageExterneDto?.codePostalText ||
      formik?.values?.contratConventionStageExterneDto?.codePostalText === previousCodePostalText
    )
      return;
    const cpList =
      rawCodePostaux?.filter(
        cp => cp.displayValue === formik?.values?.contratConventionStageExterneDto?.codePostalText
      ) ?? [];
    const idCp = cpList.length === 1 ? cpList[0].idValue : cpList.find(cp => cp.keyValue === "BE")?.idValue;
    if (+idCp === +formik.values?.contratConventionStageExterneDto?.idcodePostal) return;
    formik.setFieldValue("idcodePostal", idCp);
  }, [formik, previousCodePostalText, rawCodePostaux]);

  React.useEffect(() => {
    if (
      !formik.values?.contratConventionStageExterneDto?.dureeSousContrat &&
      !formik.touched?.contratConventionStageExterneDto?.dureeSousContrat &&
      !!formik.values?.dateDebut &&
      !!formik.values?.dateFin
    ) {
      const computedDuree = differenceInDays(formik.values.dateFin, formik.values.dateDebut);
      if (computedDuree !== formik.values?.contratConventionStageExterneDto?.dureeSousContrat)
        formik.setFieldValue("contratConventionStageExterneDto.dureeSousContrat", computedDuree);
    }
  }, [formik]);

  const fapi = useApiService(FichierApi);
  const downloadFn = React.useCallback(async idfichier => await fapi.fichierDownload({ id: idfichier }), [fapi]);

  return (
    <>
      <FieldGroup
        columns={2}
        collapsable
        fieldsetProps={{
          title: t(ETLCodes.SiegeSocial)
        }}
      >
        <FieldGroup>
          <FGTextInput
            name="contratConventionStageExterneDto.nomLieu"
            label={t(ETLCodes.NomLieuFormation)}
            maxLength={150}
          />
          <FGTextInput
            name="contratConventionStageExterneDto.senominationSiege"
            label={t(ETLCodes.DenominationSiegeSocial)}
            maxLength={150}
          />
          <FGTextInput
            name="contratConventionStageExterneDto.adresse"
            label={t(ETLCodes.Adresse)}
            maxLength={100}
            labelStyles={{ minWidth: 100 }}
          />
          <FGTextInput
            labelStyles={{ minWidth: 100 }}
            name="contratConventionStageExterneDto.codePostalText"
            label={t(ETLCodes.CodePostal)}
            maxLength={6}
          />
          <FGCustomPanel>
            {ctx => (
              <>
                <LocaliteSelect
                  labelStyles={{ minWidth: 100 }}
                  codePostal={ctx?.formik?.values?.contratConventionStageExterneDto?.codePostalText}
                  name="contratConventionStageExterneDto.localite"
                  codePostalTextName="contratConventionStageExterneDto.codePostalText"
                  disableIfNoCp={false}
                />
                <CommuneSelect
                  labelStyles={{ minWidth: 100 }}
                  codePostal={ctx?.formik?.values?.contratConventionStageExterneDto?.codePostalText}
                  name="contratConventionStageExterneDto.commune"
                />
              </>
            )}
          </FGCustomPanel>
        </FieldGroup>
      </FieldGroup>
      <FieldGroup
        columns={2}
        collapsable
        fieldsetProps={{
          title: t(ETLCodes.ChefEntreprise)
        }}
      >
        <FieldGroup>
          <FGTextInput
            name="contratConventionStageExterneDto.chefEntreprise"
            label={t(ETLCodes.ChefEntreprise)}
            maxLength={150}
          />
          <FGTextInput name="contratConventionStageExterneDto.fonction" label={t(ETLCodes.Fonction)} />
          <FGCopyTextInput
            copyOnlyDigits={true}
            name="contratConventionStageExterneDto.numero"
            label={t(ETLCodes.GsmTel)}
            maxLength={50}
          />
          <FGTextInput name="contratConventionStageExterneDto.email" label={t(ETLCodes.Email)} maxLength={150} />
        </FieldGroup>
      </FieldGroup>
      <FieldGroup
        columns={2}
        collapsable
        fieldsetProps={{
          title: t(ETLCodes.DonneesContrat)
        }}
      >
        <FieldGroup>
          <FGWalterSelectInput name="idmetier" label={t(ETLCodes.Metier)} items={metiers} loading={mLoading} />
          <FGWalterSelectInput
            name="contratConventionStageExterneDto.idcontratStatutWalter"
            label={t(ETLCodes.StatutContrat)}
            items={statutsContrat}
            loading={statutsContratLoading}
          />
          <FGWalterDateMaskInput name="dateDebut" label={t(ETLCodes.DateDebut)} />
          <FGWalterDateMaskInput name="dateFin" label={t(ETLCodes.DateFin)} />
          <FGTextInput name="contratConventionStageExterneDto.nomReferent" label={t(ETLCodes.NomReferent)} />
          <FGTextInput name="contratConventionStageExterneDto.prenomReferent" label={t(ETLCodes.PrenomReferent)} />
          <FGWalterDateMaskInput name="rompuLe" label={t(ETLCodes.DateRupture)} />
          <FGCustomPanel>
            {ctx => (
              <FGWalterFileInput
                name="fichierRupture"
                label={t(ETLCodes.DocumentRupture)}
                downloadFn={
                  ctx.formik.values?.fichierRupture && (() => downloadFn(ctx.formik.values?.idfichierRupture))
                }
              />
            )}
          </FGCustomPanel>
          <FGNumberInput
            name="contratConventionStageExterneDto.dureeSousContrat"
            label={t(ETLCodes.DureeSousContrat)}
          />
        </FieldGroup>
      </FieldGroup>
    </>
  );
};
