/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReportParameterDto } from './ReportParameterDto';
import {
    ReportParameterDtoFromJSON,
    ReportParameterDtoFromJSONTyped,
    ReportParameterDtoToJSON,
} from './ReportParameterDto';

/**
 * 
 * @export
 * @interface ReportParametersDto
 */
export interface ReportParametersDto {
    /**
     * 
     * @type {Array<ReportParameterDto>}
     * @memberof ReportParametersDto
     */
    parameters?: Array<ReportParameterDto> | null;
}

/**
 * Check if a given object implements the ReportParametersDto interface.
 */
export function instanceOfReportParametersDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReportParametersDtoFromJSON(json: any): ReportParametersDto {
    return ReportParametersDtoFromJSONTyped(json, false);
}

export function ReportParametersDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportParametersDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameters': !exists(json, 'parameters') ? undefined : (json['parameters'] === null ? null : (json['parameters'] as Array<any>).map(ReportParameterDtoFromJSON)),
    };
}

export function ReportParametersDtoToJSON(value?: ReportParametersDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameters': value.parameters === undefined ? undefined : (value.parameters === null ? null : (value.parameters as Array<any>).map(ReportParameterDtoToJSON)),
    };
}

