import { Colors, Icon, Tooltip } from "@blueprintjs/core";
import { ButtonContainer, IDataTableColumn } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { ContratApi, ContratGridDto, ContratSearchDto, EListSearchType, ETypeContrat } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { CircleColumn, CustomBulletList, EditButton, SearchTablePage, ViewButton } from "../../../../components";
import { useAuth } from "../../../../contexts";
import { useAbortableApiServiceFactory, useApiService, useTheme, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { computeContratStatutColor } from "../../../../utils/contratStatutColor";

const LegendHeaderContainer = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

export interface IContratListPageProps {}

export const ContratListPage: React.FunctionComponent<IContratListPageProps> = props => {
  const { t, tUnsafe } = useTl();
  const { user } = useAuth();
  const history = useHistory();
  const { theme, ifapmeSide } = useTheme();
  const isHope = React.useMemo(() => ifapmeSide === "hope", [ifapmeSide]);
  const api = useApiService(ContratApi);

  const apiFactory = useAbortableApiServiceFactory(ContratApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (nextSearch?: ContratSearchDto) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.contratBaseSearch({ ContratSearchDto: nextSearch });
    },
    [apiFactory]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ContratGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.contrat}/${row.idcontrat}/detail/${row.type}/view`)}
            />
            {(isHope &&
              [
                ETypeContrat.CC,
                ETypeContrat.CA,
                ETypeContrat.CS,
                ETypeContrat.CF,
                ETypeContrat.DM,
                ETypeContrat.JI,
                ETypeContrat.PP
              ].includes(row.type)) ||
            (!isHope && [ETypeContrat.SO, ETypeContrat.CCE, ETypeContrat.CSE].includes(row.type)) ? (
              <EditButton
                minimal={true}
                onClick={() => window.open(`#${ERoutes.contrat}/${row.idcontrat}/detail/${row.type}/edit`)}
              />
            ) : null}
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Apprenant),
        fieldName: "apprenant",
        disableSorting: true
      },
      {
        computed: true,
        alignment: "center",
        autoFitContent: true,
        header: () => (
          <LegendHeaderContainer>
            <span>{t(ETLCodes.Statut)}</span>
            <Tooltip
              content={
                <CustomBulletList
                  title={t(ETLCodes.Statut)}
                  items={[
                    {
                      text: t(ETLCodes.EnCours),
                      color: theme.sucessColor
                    },
                    {
                      text: t(ETLCodes.Rompu),
                      color: theme.dangerColor
                    },
                    {
                      text: t(ETLCodes.Suspendu),
                      color: theme.warningColor
                    },
                    {
                      text: t(ETLCodes.JamaisDeContrat),
                      color: Colors.BLACK
                    },
                    {
                      text: t(ETLCodes.Termine),
                      color: Colors.VIOLET4
                    },
                    {
                      text: t(ETLCodes.SansSuite),
                      hideBullet: true
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip>
          </LegendHeaderContainer>
        ),
        fieldName: "statut",
        render: (row: ContratGridDto) => (
          <CircleColumn
            color={computeContratStatutColor(row.statut, theme)}
            tooltip={tUnsafe(`StatutContrat_${row.statut}`)}
          />
        )
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code",
        disableSorting: true
      },
      {
        header: () => t(ETLCodes.Service),
        fieldName: "serviceNom",
        disableSorting: true
      },
      {
        header: () => t(ETLCodes.Metier),
        fieldName: "codeMetier",
        render: row => `${row.nomMetier} (${row.codeMetier})`,
        disableSorting: true
      },
      {
        header: () => t(ETLCodes.Stade),
        fieldName: "stade",
        disableSorting: true
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "type",
        disableSorting: true
      },
      {
        header: () => t(ETLCodes.LieuFormationPrincipal),
        fieldName: "lieuPrincipal",
        disableSorting: true
      },
      {
        header: () => t(ETLCodes.TuteurPrincipal),
        fieldName: "tuteurPrincipal",
        disableSorting: true
      },
      {
        header: () => t(ETLCodes.DateDebut),
        fieldName: "dateDebut",
        disableSorting: true
      },
      {
        header: () => t(ETLCodes.DateFin),
        fieldName: "dateFin",
        disableSorting: true
      }
    ],
    [history, isHope, t, tUnsafe, theme]
  );

  const getCriteria = React.useCallback(async () => {
    let criterias = await api.contratGetSearchCriterias({ includeListsValues: true });
    criterias = criterias.map(c => {
      if (c.criteria === "Statut") {
        return {
          ...c,
          listValues: c.listValues.map(v => ({ ...v, displayValue: tUnsafe(`StatutContrat_${v.displayValue}`) }))
        };
      }
      return c;
    });
    return criterias;
  }, [api, tUnsafe]);

  const hasDefaultCriteria = React.useMemo(
    () =>
      ["CALC", "RI", "ARI", "CALC", "Gestionnaire administratif", "Société externe"].includes(user?.hopeMainLevelName),
    [user?.hopeMainLevelName]
  );

  const defaultCriteria = React.useMemo(
    () =>
      isHope && hasDefaultCriteria
        ? [
            {
              criteria: "MesContrats",
              searchMode: EListSearchType.Equals,
              value: true
            }
          ]
        : [],
    [hasDefaultCriteria, isHope]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteria}
      searchFunction={search}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.Contrat), route: ERoutes.contrat }]}
      keyFieldName="idcontrat"
      defaultCriterias={defaultCriteria}
      searchStateInitialSearch={false}
      enableFilter={false}
    />
  );
};
