import { Radio } from "@blueprintjs/core";
import { DataTable, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";

import { FcbGestionDroitsGridDto, GestionDroitsApi, GestionDroitsSearch } from "../../../api";
import { useApiService, useTl } from "../../../hooks";
import { ETLCodes } from "../../../locales";

export interface IGestionDroitsGridProps {
  idaccesLevel: number;
}

export const GestionDroitsGrid: React.FunctionComponent<IGestionDroitsGridProps> = ({ idaccesLevel }) => {
  const { t, tUnsafe } = useTl();
  const api = useApiService(GestionDroitsApi);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [5, 15, 25],
    pageSize: 25,
    sortKeys: { module: "ASC" }
  });

  const searchFunction = React.useCallback(
    (sObj?: GestionDroitsSearch) => {
      sObj.idaccesLevel = idaccesLevel;
      return api.gestionDroitsBaseSearch({ GestionDroitsSearch: sObj });
    },
    [api, idaccesLevel]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: false
  });
  const { setData, data } = tableState;

  React.useEffect(() => {
    if (idaccesLevel) {
      search();
    }
  }, [idaccesLevel, search]);

  const changePermission = React.useCallback(
    (value: string, row: FcbGestionDroitsGridDto) => {
      const dataToSet = [...data];
      const index = dataToSet.findIndex(d => d.idaction === row.idaction && d.idaccesLevel === row.idaccesLevel);
      dataToSet[index]["permissions"] = value;
      dataToSet[index]["idaccesLevel"] = idaccesLevel;
      setData(dataToSet);
      api.gestionDroitsSavePermission({ FcbGestionDroitsGridDto: dataToSet[index] });
    },
    [api, data, idaccesLevel, setData]
  );

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        header: () => t(ETLCodes.Module),
        fieldName: "module",
        autoFitContent: true,
        render: (row: FcbGestionDroitsGridDto) => tUnsafe("ActionCode_" + row.module)
      },
      {
        computed: true,
        header: () => t(ETLCodes.Tab),
        fieldName: "tab",
        autoFitContent: true,
        render: (row: FcbGestionDroitsGridDto) => (!!row.tab ? tUnsafe("ActionCode_" + row.tab) : "")
      },
      {
        computed: true,
        header: () => t(ETLCodes.Aucun),
        fieldName: "permissionNone",
        autoFitContent: true,
        alignment: "center" as any,
        render: (row: FcbGestionDroitsGridDto) => (
          <Radio checked={!row.permissions} onChange={e => changePermission(null, row)} />
        )
      },
      {
        computed: true,
        header: () => t(ETLCodes.Lecture),
        fieldName: "permissionR",
        autoFitContent: true,
        alignment: "center" as any,
        render: (row: FcbGestionDroitsGridDto) => (
          <Radio checked={row.permissions === "R"} onChange={e => changePermission("R", row)} />
        )
      },
      {
        computed: true,
        header: () => t(ETLCodes.Lecture_Ecriture),
        fieldName: "permissionRW",
        autoFitContent: true,
        alignment: "center" as any,
        render: (row: FcbGestionDroitsGridDto) => (
          <Radio checked={row.permissions === "RW"} onChange={e => changePermission("RW", row)} />
        )
      },
      {
        computed: true,
        header: () => t(ETLCodes.Lecture_Ecriture_Suppression),
        fieldName: "permissionRWD",
        autoFitContent: true,
        alignment: "center" as any,
        render: (row: FcbGestionDroitsGridDto) => (
          <Radio checked={row.permissions === "RWD"} onChange={e => changePermission("RWD", row)} />
        )
      }
    ],
    [changePermission, t, tUnsafe]
  );

  return (
    <>
      <DataTable
        dateFormat="dd-MM-yyyy"
        tableState={tableState}
        loading={loading}
        columns={columns}
        filterMode="OnEnter"
      />
    </>
  );
};
