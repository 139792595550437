import {
  Button,
  ButtonGroup,
  Checkbox,
  Classes,
  Colors,
  Icon,
  IconName,
  Menu,
  MenuDivider,
  NonIdealState
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { MenuItem2, Popover2 } from "@blueprintjs/popover2";
import { faFileCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { ToolTipButton } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { LinkButton, LoadingDots, SelectAllButton } from ".";
import { EPrintingQueueStatus, PrintingQueueDto } from "../api";
import { ERoutes } from "../AppRouter";
import assets from "../assets";
import { usePrintingQueueContext } from "../contexts";
import { useTl } from "../hooks";
import { ETLCodes } from "../locales";
import { toCleanUtc } from "../utils";

const ButtonsLogo = styled.img`
  height: 30px;
  margin-right: 10px;
  cursor: pointer;
`;

const PopoverContent = styled.div`
  max-height: 510px;
  overflow-y: auto;

  & .no-padding {
    padding: 0px !important;
  }

  & .${Classes.NON_IDEAL_STATE} {
    padding: 1rem;
  }

  & .non-interactive:active {
    background-color: transparent !important;
  }

  & .non-interactive:hover {
    cursor: default !important;
    background-color: transparent !important;
  }

  & .non-interactive:focus {
    outline: none !important;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: white;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
  }

  /* & .show-on-hover {
    visibility: hidden;
  }

  & .non-interactive:hover .show-on-hover {
    visibility: visible;
  } */

  & ${Classes.BUTTON}:focus {
    outline: none !important;
  }
`;

const MenuItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  & .menu-item-title {
    width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
  }

  & > .${Classes.TEXT_MUTED} {
    font-size: 9pt;
  }
`;

const MenuItemTitleContainer = styled.div`
  display: flex;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 5px;
`;

const SwitchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export interface IPrintingQueueProps {}

export const PrintingQueue: React.FunctionComponent<IPrintingQueueProps> = props => {
  const { t } = useTl();
  const {
    queuePrintings,
    deleteFromQueue,
    cancelQueue,
    rowCancelling,
    rowDeleting,
    refreshQueue,
    isQueueForcedOpen,
    setIsQueueForcedOpen,
    downloadFile,
    rowDownloading,
    massDownload,
    massDownloading,
    retryPrinting,
    rowRetrying,
    showPrinting,
    rowShowing,
    massMask,
    massDelete
  } = usePrintingQueueContext();

  const [isOpen, setIsOpen] = React.useState(false);
  React.useEffect(() => {
    if (isQueueForcedOpen && !isOpen) {
      setIsOpen(true);
      setIsQueueForcedOpen(false);
    }
  }, [isOpen, isQueueForcedOpen, setIsQueueForcedOpen]);

  const buttonSrc = React.useMemo(() => {
    if (!queuePrintings?.length) return assets.images.printingQueue;

    if (queuePrintings.some(p => p.status === EPrintingQueueStatus.GENERATING))
      return assets.images.printingQueueLoading;
    if (queuePrintings.some(p => p.status === EPrintingQueueStatus.ERROR)) return assets.images.printingQueueError;
    if (queuePrintings.some(p => p.status === EPrintingQueueStatus.DONE)) return assets.images.printingQueueDone;

    return assets.images.printingQueue;
  }, [queuePrintings]);

  const [selectedFichierIDs, setSelectedFichierIDs] = React.useState([]);
  const [selectedIDs, setSelectedIDs] = React.useState([]);
  const toggleSelection = React.useCallback(
    (item: PrintingQueueDto) => {
      if (!selectedFichierIDs.includes(item.idfichier)) {
        setSelectedFichierIDs([...selectedFichierIDs, item.idfichier]);
      } else {
        setSelectedFichierIDs(ids => ids.filter(id => id !== item.idfichier));
      }
      if (!selectedIDs.includes(item.idprintingQueue)) {
        setSelectedIDs([...selectedFichierIDs, item.idprintingQueue]);
      } else {
        setSelectedIDs(ids => ids.filter(id => id !== item.idprintingQueue));
      }
    },
    [selectedFichierIDs, selectedIDs]
  );

  const selectAll = React.useCallback(() => {
    setSelectedFichierIDs(prev => (prev.length > 0 ? [] : queuePrintings.map(p => p.idfichier)));
    setSelectedIDs(prev => (prev.length > 0 ? [] : queuePrintings.map(p => p.idprintingQueue)));
  }, [queuePrintings]);

  return (
    <Popover2
      inheritDarkTheme={false}
      position="bottom-left"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      content={
        <PopoverContent>
          <Menu>
            <MenuDivider
              title={
                <TitleContainer>
                  <SwitchContainer>
                    <div>
                      <span>{t(ETLCodes.DerniersAvisDeResultatsImprimes)}</span>
                      <LinkButton
                        text={t(ETLCodes.VoirHistorique)}
                        onClick={e => {
                          e.stopPropagation();
                          window.open(`#${ERoutes.printingHistory}`, "_blank");
                        }}
                      />
                    </div>
                  </SwitchContainer>
                  <ButtonGroup fill>
                    <SelectAllButton
                      onClick={() => selectAll()}
                      text={t(selectedFichierIDs.length > 0 ? ETLCodes.DeselectAll : ETLCodes.SelectAll)}
                      outlined
                    />
                    <Popover2
                      position="bottom-left"
                      content={
                        <PopoverContent>
                          <Menu>
                            <MenuItem2
                              icon={IconNames.DOWNLOAD}
                              text={t(ETLCodes.TelechargerSelection)}
                              onClick={async e => {
                                e.stopPropagation();
                                await massDownload(selectedFichierIDs);
                                setSelectedFichierIDs([]);
                                setSelectedIDs([]);
                                refreshQueue();
                              }}
                            />
                            <MenuItem2
                              icon={IconNames.Cross}
                              text={t(ETLCodes.AnnulerSelection)}
                              onClick={async e => {
                                e.stopPropagation();
                                await massDelete(selectedIDs);
                                setSelectedFichierIDs([]);
                                setSelectedIDs([]);
                              }}
                            />
                            <MenuItem2
                              icon={IconNames.EyeOff}
                              text={t(ETLCodes.MasquerSelection)}
                              onClick={async e => {
                                e.stopPropagation();
                                await massMask(selectedIDs);
                                setSelectedFichierIDs([]);
                                setSelectedIDs([]);
                              }}
                            />
                          </Menu>
                        </PopoverContent>
                      }
                    >
                      <Button
                        icon={IconNames.CHEVRON_DOWN}
                        text={t(ETLCodes.SelectionActions)}
                        loading={massDownloading}
                        outlined
                      ></Button>
                    </Popover2>
                  </ButtonGroup>
                </TitleContainer>
              }
            />
            {queuePrintings?.length > 0 ? (
              queuePrintings.map(p => {
                let icon: IconName;
                let color;
                switch (p.status) {
                  case "WAITING":
                    icon = "time";
                    color = Colors.ORANGE3;
                    break;
                  case "GENERATING":
                    icon = "refresh";
                    color = Colors.BLUE3;
                    break;
                  case "DONE":
                    icon = "tick-circle";
                    color = Colors.GREEN3;
                    break;
                  case "CANCELLED":
                    icon = "remove";
                    color = Colors.GRAY3;
                    break;
                  default:
                    icon = "cross-circle";
                    color = Colors.RED3;
                    break;
                }

                let from;
                switch (p.printingType) {
                  case "CRYSTAL":
                    from = t(ETLCodes.RapportWalter);
                    break;
                  case "SSRS":
                    from = t(ETLCodes.RapportHope);
                    break;
                  case "EXCEL":
                    from = t(ETLCodes.ExportWalter);
                    break;
                }

                const date = format(toCleanUtc(p.creationDate), "dd/MM/yyyy HH:mm:ss");

                return (
                  <MenuItem2
                    onClick={e => e.stopPropagation()}
                    icon={<Icon icon={icon} color={color} />}
                    className="non-interactive"
                    text={
                      <MenuItemTextContainer>
                        <MenuItemTitleContainer>
                          <Checkbox
                            checked={selectedIDs.includes(p.idprintingQueue)}
                            onChange={e => {
                              toggleSelection(p);
                            }}
                          />
                          {!!p.idfichier ? (
                            rowDownloading === p.idprintingQueue ? (
                              <LoadingDots colorScheme="primary" size={5} />
                            ) : (
                              <LinkButton
                                className="menu-item-title"
                                text={p.displayName}
                                tooltip={p.temporary && t(ETLCodes.DownloadingWillDeleteFile)}
                                onClick={async e => {
                                  e.stopPropagation();
                                  await downloadFile(p.idprintingQueue, p.idfichier, p.temporary);
                                  refreshQueue();
                                }}
                              />
                            )
                          ) : (
                            <span className="menu-item-title">{p.displayName}</span>
                          )}
                        </MenuItemTitleContainer>
                        <span className={Classes.TEXT_MUTED}>
                          {from} &#x2022; {date}
                        </span>
                      </MenuItemTextContainer>
                    }
                    labelElement={
                      <>
                        {p.status === EPrintingQueueStatus.CANCELLED && (
                          <Button
                            className="no-padding"
                            minimal
                            small
                            loading={
                              rowCancelling === p.idprintingQueue ||
                              rowDeleting === p.idprintingQueue ||
                              rowRetrying === p.idprintingQueue ||
                              rowShowing === p.idprintingQueue
                            }
                            icon="reset"
                            intent="none"
                            onClick={async e => {
                              e.stopPropagation();
                              await retryPrinting(p.idprintingQueue);
                              refreshQueue();
                            }}
                          />
                        )}
                        {(p.status === EPrintingQueueStatus.WAITING ||
                          p.status === EPrintingQueueStatus.CANCELLED ||
                          p.status === EPrintingQueueStatus.ERROR) && (
                          <Button
                            className="no-padding"
                            minimal
                            small
                            loading={
                              rowCancelling === p.idprintingQueue ||
                              rowDeleting === p.idprintingQueue ||
                              rowRetrying === p.idprintingQueue ||
                              rowShowing === p.idprintingQueue
                            }
                            icon={p.status === EPrintingQueueStatus.WAITING ? "cross" : "trash"}
                            intent={p.status === EPrintingQueueStatus.WAITING ? "none" : "danger"}
                            onClick={async e => {
                              e.stopPropagation();
                              await deleteFromQueue(p.idprintingQueue);
                              refreshQueue();
                            }}
                          />
                        )}
                        {p.status === EPrintingQueueStatus.GENERATING && (
                          <Button
                            className="no-padding"
                            minimal
                            small
                            loading={
                              rowCancelling === p.idprintingQueue ||
                              rowDeleting === p.idprintingQueue ||
                              rowRetrying === p.idprintingQueue ||
                              rowShowing === p.idprintingQueue
                            }
                            icon={"cross"}
                            intent={"none"}
                            onClick={async e => {
                              e.stopPropagation();
                              await cancelQueue(p.idprintingQueue);
                              refreshQueue();
                            }}
                          />
                        )}
                        {p.status === EPrintingQueueStatus.DONE && (
                          <ToolTipButton
                            tooltip={p.hidden ? t(ETLCodes.Show) : t(ETLCodes.Hide)}
                            className="no-padding"
                            minimal
                            small
                            loading={
                              rowCancelling === p.idprintingQueue ||
                              rowDeleting === p.idprintingQueue ||
                              rowRetrying === p.idprintingQueue ||
                              rowShowing === p.idprintingQueue
                            }
                            icon={p.hidden ? "eye-on" : "eye-off"}
                            onClick={async e => {
                              e.stopPropagation();
                              if (p.hidden) await showPrinting(p.idprintingQueue);
                              else await deleteFromQueue(p.idprintingQueue);
                              refreshQueue();
                            }}
                          />
                        )}
                      </>
                    }
                  />
                );
              })
            ) : (
              <NonIdealState
                icon={<FontAwesomeIcon icon={faFileCircleXmark} size="sm" />}
                title={t(ETLCodes.NoPrintingsToday)}
              />
            )}
          </Menu>
        </PopoverContent>
      }
    >
      <ButtonsLogo src={buttonSrc} style={{ cursor: "pointer" }} alt="PrintingQueue" onClick={() => setIsOpen(true)} />
    </Popover2>
  );
};

export default PrintingQueue;
