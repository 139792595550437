import { FormikProps } from "formik";
import { FGCustomPanel, FGListen, FGNumberInput, FGTextInput, FieldGroup, FieldSet } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import * as Yup from "yup";

import { ClasseApi, EvaluationApi, FcbEvaluationDto, FcbEvaluationDtoFromJSON } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { FGWalterDateMaskInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";
import { ETLCodes } from "../../../../locales";
import { EvaluationApprenantList } from "./EvaluationApprenantList";

export interface IEvaluationDetailPageProps {
  editMode: boolean;
}

export const EvaluationDetailPage: React.FunctionComponent<IEvaluationDetailPageProps> = ({ editMode }) => {
  const { id, duplicateId } = useParams<{ id: string; state: string; duplicateId: string }>();
  const { t } = useTl();
  const api = useApiService(EvaluationApi);
  const classeApi = useApiService(ClasseApi);
  const history = useHistory();

  const [idClasse, setIdClasse] = React.useState<number>(0);
  const [coteMax, setCoteMax] = React.useState<number>(null);
  const [typeEvaluation, setTypeEvaluation] = React.useState<number>(0);
  const [idMatiere, setIdMatiere] = React.useState<number>(0);
  const [isEditable, setIsEditable] = React.useState(editMode);
  const formikInnerRef = React.useRef<FormikProps<FcbEvaluationDto>>();
  const showNeutraliseCheckbox = React.useMemo(() => typeEvaluation === 5 || typeEvaluation === 6, [typeEvaluation]);

  const fetchEditable = React.useCallback(async () => {
    if (!idClasse || idClasse === 0) return { isDeletable: false, isEditable: true };

    return await classeApi.classeIsEvaluationEditable({ idClasse, typeEvaluation });
  }, [classeApi, idClasse, typeEvaluation]);

  const { data: editable } = useQuery(["evaluation-is-editable", { id, idClasse, typeEvaluation }], fetchEditable);

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi<FcbEvaluationDto>(
    React.useMemo(
      () => ({
        getApiFn: () =>
          +id <= 0
            ? FcbEvaluationDtoFromJSON({ idEvaluation: +id, idClasse: duplicateId })
            : api.evaluationGet({ id: +id }),
        saveApiFn: d => api.evaluationSave({ FcbEvaluationDto: d }),
        onSavedRoute: d => `${ERoutes.evaluation}/${d.idEvaluation}/detail/edit`,
        deleteApiFn: d => api.evaluationDelete({ id: d.idEvaluation }),
        onDeletedRoute: () => `${ERoutes.evaluation}`,
        getDeps: [id]
      }),
      [api, duplicateId, id]
    )
  );

  const [classe, cLoading] = useReferential(a => a.referentialGetClasseForEvaluation({ idEvaluation: +id }), true, [
    id
  ]);
  const [matiere, mLoading] = useReferential(
    a => {
      if (+id > 0) {
        return a.referentialGetClasseMatiereByClasse({ idClasse });
      } else {
        return a.referentialGetClasseMatiereByClasseAndTypeEval({ idClasse, idTypeEval: typeEvaluation });
      }
    },
    true,
    [idClasse, typeEvaluation]
  );
  const [type, tLoading] = useReferential(
    a => {
      if (+id > 0) {
        return a.referentialGetTypeEvaluation();
      } else {
        return a.referentialGetTypeEvaluationByClasse({ idClasse, idClasseMatiere: idMatiere });
      }
    },
    true,
    [idClasse, idMatiere]
  );
  const [date, dLoading] = useReferential(a => a.referentialGetDateForEvaluation({ idClasse }), false, [idClasse]);

  const idSecondeSession = React.useMemo(() => {
    return type?.find(te => te.value === 2)?.value;
  }, [type]);

  const formSchema = React.useMemo(() => {
    return Yup.object().shape({
      typeEvaluation: Yup.number()
        .typeError(t(ETLCodes.Required))
        .required(t(ETLCodes.Required))
        .test("isTypeInList", t(ETLCodes.TypeEvaluationNotInList), async value => {
          return type?.map(ty => +ty.value).includes(+value);
        }),
      idClasse: Yup.number()
        .typeError(t(ETLCodes.Required))
        .required(t(ETLCodes.Required))
        .test("isResultatLock", t(ETLCodes.ErrorCodeClasseResultatLock), async function(value) {
          const validationError = await classeApi.classeIsResultatLock({
            idClasse: +value
          });
          return validationError.isValid;
        })
        .test("isClasseInList", t(ETLCodes.ClasseNotInList), async value => {
          return classe?.map(ty => +ty.value).includes(+value);
        }),
      idClasseMatiere: Yup.number()
        .typeError(t(ETLCodes.Required))
        .required(t(ETLCodes.Required))
        .test("isClasseMatiereInList", t(ETLCodes.ClasseMatiereNotInList), async value => {
          return matiere?.map(ty => +ty.value).includes(+value);
        }),
      date: Yup.string()
        .nullable()
        .required(t(ETLCodes.Required)),
      coteMax: Yup.string()
        .nullable()
        .required(t(ETLCodes.Required))
    });
  }, [classe, classeApi, matiere, t, type]);

  const onDuplicateClick = React.useCallback(() => {
    window.open(`/#${ERoutes.evaluation}/0/detail/edit/${idClasse}`, "_blank");
  }, [idClasse]);

  const showButtons = React.useMemo(() => +id > 0 && isEditable && editable?.isEditable, [editable, id, isEditable]);

  return (
    <>
      <div style={{ marginBottom: "1rem" }}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={isEditable}
          formatDate="dd/MM/yyyy"
          validationSchema={formSchema}
          loading={loading}
          onEdit={() => setIsEditable(true)}
          onCancel={() => history.push(`${ERoutes.evaluation}`)}
          onDelete={deleteItem}
          showDeleteButton={+id > 0 && editable?.isDeletable}
          saving={saving}
          deleting={deleting}
          showDuplicateButton={showButtons}
          onDuplicate={onDuplicateClick}
          formikInnerRef={formikInnerRef}
          disabled={+id > 0 && !editable?.isEditable}
        >
          <FGListen field="idClasse" onChanged={value => setIdClasse(value ?? 0)}></FGListen>
          <FGListen field="coteMax" onChanged={value => setCoteMax(value)}></FGListen>
          <FGListen field="idClasseMatiere" onChanged={value => setIdMatiere(value ?? 0)}></FGListen>
          <FGListen field="typeEvaluation" onChanged={value => setTypeEvaluation(value ?? 0)}></FGListen>
          <FieldGroup columns={2}>
            <FieldGroup>
              <FGWalterSelectInput
                name="idClasse"
                readonly={!editMode || +id > 0}
                label={t(ETLCodes.Classe)}
                items={classe}
                loading={cLoading}
              />
              <FGWalterSelectInput
                readonly={!editMode || +id > 0}
                name="idClasseMatiere"
                label={t(ETLCodes.Matiere)}
                items={matiere}
                loading={mLoading}
                disabled={+id > 0 && !editable?.isEditable}
              />
              <FGWalterSelectInput
                name="typeEvaluation"
                readonly={!editMode || +id > 0}
                label={t(ETLCodes.Type)}
                items={type}
                loading={tLoading}
                disabled={+id > 0 && !editable?.isEditable}
              />
            </FieldGroup>
            <FieldGroup>
              <FGCustomPanel>
                {() =>
                  !editMode || +id > 0 ? (
                    <FGTextInput
                      name="date"
                      label={t(ETLCodes.DateEvaluation)}
                      readonly
                      disabled={+id > 0 && !editable?.isEditable}
                    />
                  ) : typeEvaluation === idSecondeSession ? (
                    <FGWalterDateMaskInput
                      name="date"
                      readonly={!editMode || +id > 0}
                      label={t(ETLCodes.DateEvaluation)}
                      disabled={+id > 0 && !editable?.isEditable}
                    />
                  ) : (
                    <FGWalterSelectInput
                      label={t(ETLCodes.DateEvaluation)}
                      name="date"
                      items={date}
                      loading={dLoading}
                      readonly={!editMode || +id > 0}
                      disabled={+id > 0 && !editable?.isEditable}
                    />
                  )
                }
              </FGCustomPanel>
              <FGTextInput
                name="libelle"
                label={t(ETLCodes.Libelle)}
                maxLength={50}
                readonly={+id > 0 && !editable?.isDeletable}
                disabled={+id > 0 && !editable?.isEditable}
              />
              <FGNumberInput
                name="coteMax"
                label={t(ETLCodes.CoteMaximale)}
                readonly={+id > 0 && !editable?.isDeletable}
                disabled={+id > 0 && !editable?.isEditable}
              />
            </FieldGroup>
          </FieldGroup>
        </SmallFormGenerator>
      </div>
      {+id > 0 && (
        <FieldSet title={t(ETLCodes.Cotes)}>
          <EvaluationApprenantList
            coteMax={coteMax}
            showNeutraliseCheckbox={showNeutraliseCheckbox}
            editable={editable?.isEditable}
          />
        </FieldSet>
      )}
    </>
  );
};
