import * as React from "react";
import { useHistory, useParams } from "react-router";
import { ApprenantApi, EPermis, ReferentialResultInfoDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import * as Yup from "yup";
import { ETLCodes } from "../../../../../locales";
import {
  FGWalterCheckboxInput,
  FGWalterDateMaskInput,
  FGWalterSelectInput,
  SmallFormGenerator
} from "../../../../../components";
import { FieldGroup } from "nsitools-react";
import { useAuth } from "../../../../../contexts";

export interface IApprenantPassportDriveProps {}

export const ApprenantPassportDrive: React.FunctionComponent<IApprenantPassportDriveProps> = props => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ApprenantApi);
  const { user } = useAuth();
  const { id, state } = useParams<{ id: string; state: "edit" | "view" }>();
  const idapprenant = React.useMemo(() => +id, [id]);
  const editMode = React.useMemo(() => state === "edit", [state]);

  const { data, loading, saveItem, saving, deleteItem, deleting } = useCrudApi({
    getApiFn: () => api.apprenantGetPassportDrive({ idapprenant }),
    saveApiFn: d => api.apprenantSavePassportDrive({ PassportDriveDto: d }),
    onSavedRoute: d => `${ERoutes.apprenant}/${idapprenant}/passportDrive/${state}`,
    deleteApiFn: d => api.apprenantDeletePassportDrive({ idapprenant }),
    refreshAfterDelete: true
  });

  const [permis, pLoading] = useReferential(a => a.referentialGetPermis(), false);
  const [permisCode, setPermisCode] = React.useState<EPermis>(EPermis.B);
  const [autoecoles, aeLoading] = useReferential(
    a =>
      !permisCode
        ? Promise.resolve(ReferentialResultInfoDtoFromJSON({ list: [] }))
        : a.referentialGetAutoecoles({ permisCode }),
    true,
    [permisCode]
  );
  const [resultatPermis, rpLoading] = useReferential(a => a.referentialGetResultatPermis(), true);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idautoecole: Yup.number()
        .nullable()
        .when(["permis", "pasEmailPromotionnel"], {
          is: (permis, pasEmailPromotionnel) => !!permis && !pasEmailPromotionnel,
          then: Yup.number()
            .nullable()
            .required(t(ETLCodes.Required))
        })
    });
  }, [t]);

  const isAdmin = React.useMemo(
    () =>
      user?.hopeMainLevelName === "Hope - Administrateur" ||
      user?.hopeMainLevelName === "Administrateur Passeport Drive",
    [user?.hopeMainLevelName]
  );

  const deleteFn = React.useCallback(() => {
    deleteItem();
  }, [deleteItem]);

  return (
    <SmallFormGenerator
      initialValues={data}
      onSubmit={saveItem}
      editMode={editMode}
      validationSchema={FormSchema}
      loading={loading}
      onCancel={() => history.push(`${ERoutes.apprenant}/${idapprenant}/passportDrive/${state}`)}
      saving={saving}
      formatDate="dd-MM-yyyy"
      minLabelWidth={300}
      watchChanges={{
        permis: value => setPermisCode(value as EPermis)
      }}
      showDeleteButton
      onDelete={deleteFn}
      deleting={deleting}
    >
      <FieldGroup columns={2} fieldsetProps={{ title: t(ETLCodes.PassportDrive) }}>
        <FieldGroup>
          <FGWalterSelectInput label={t(ETLCodes.Permis)} name="permis" items={permis} loading={pLoading} />
          <FGWalterSelectInput
            label={t(ETLCodes.AutoEcole)}
            name="idautoecole"
            items={autoecoles}
            loading={aeLoading}
          />
          <FGWalterDateMaskInput name="remiseCheque" label={t(ETLCodes.RemiseCheque)} readonly />
          <FGWalterDateMaskInput name="inscriptionEffective" label={t(ETLCodes.InscriptionEffective)} readonly />
          <FGWalterDateMaskInput name="datePermisTheorique" label={t(ETLCodes.DatePermisTheorique)} readonly />
          <FGWalterSelectInput
            label={t(ETLCodes.ResultatPermisTheorique)}
            readonly
            name="idresultatPermisTheorique"
            items={resultatPermis}
            loading={rpLoading}
          />
          <FGWalterDateMaskInput name="datePermisPratique" label={t(ETLCodes.DatePermisPratique)} readonly />
          <FGWalterSelectInput
            label={t(ETLCodes.ResultatPermisPratique)}
            name="idresultatPermisPratique"
            items={resultatPermis}
            loading={rpLoading}
            readonly
          />
          <FGWalterCheckboxInput name="chequeReceptionForem" label={t(ETLCodes.ChequeReceptionForem)} readonly />
          <FGWalterCheckboxInput
            name="demandeNouveauCheque"
            label={t(ETLCodes.DemandeNouveauCheque)}
            readonly={!isAdmin}
          />
          <FGWalterCheckboxInput name="pasEmailPromotionnel" label={t(ETLCodes.PasEmailPromotionnel)} />
          <FGWalterCheckboxInput name="consentementCollecteDonnees" label={t(ETLCodes.ConsentementCollecteDonnees)} />
        </FieldGroup>
      </FieldGroup>
    </SmallFormGenerator>
  );
};
