import * as React from "react";
import { Switch, Route } from "react-router";
import { ERoutes } from "../../../../../AppRouter";
import { ServiceTutelleLocaliteLiePage } from "./ServiceTutelleLocaliteLiePage";
import { ServiceTutelleLocaliteLieeSelector } from "./ServiceTutelleLocaliteLieeSelector";

export interface IServiceTutelleLocaliteLieeSwitchProps {}

export const ServiceTutelleLocaliteLieeSwitch: React.FunctionComponent<IServiceTutelleLocaliteLieeSwitchProps> = () => {
  return (
    <Switch>
      <Route path={`${ERoutes.serviceTutelle}/:id/:tab`} component={ServiceTutelleLocaliteLiePage} exact />
      <Route path={`${ERoutes.serviceTutelle}/:id/:tab/selector`} component={ServiceTutelleLocaliteLieeSelector} />
    </Switch>
  );
};
