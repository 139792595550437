/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MinDatePresenceDto
 */
export interface MinDatePresenceDto {
    /**
     * 
     * @type {Date}
     * @memberof MinDatePresenceDto
     */
    minDate?: Date | null;
}

/**
 * Check if a given object implements the MinDatePresenceDto interface.
 */
export function instanceOfMinDatePresenceDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MinDatePresenceDtoFromJSON(json: any): MinDatePresenceDto {
    return MinDatePresenceDtoFromJSONTyped(json, false);
}

export function MinDatePresenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MinDatePresenceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'minDate': !exists(json, 'minDate') ? undefined : (json['minDate'] === null ? null : new Date(json['minDate'])),
    };
}

export function MinDatePresenceDtoToJSON(value?: MinDatePresenceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'minDate': value.minDate === undefined ? undefined : (value.minDate === null ? null : value.minDate.toISOString()),
    };
}

