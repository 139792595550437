/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContratFichierDto
 */
export interface ContratFichierDto {
    /**
     * 
     * @type {number}
     * @memberof ContratFichierDto
     */
    idcontratFichier?: number;
    /**
     * 
     * @type {number}
     * @memberof ContratFichierDto
     */
    idcontrat?: number;
    /**
     * 
     * @type {number}
     * @memberof ContratFichierDto
     */
    idfichier?: number;
    /**
     * 
     * @type {number}
     * @memberof ContratFichierDto
     */
    idtypeContratFichier?: number;
    /**
     * 
     * @type {string}
     * @memberof ContratFichierDto
     */
    fileName?: string | null;
}

/**
 * Check if a given object implements the ContratFichierDto interface.
 */
export function instanceOfContratFichierDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContratFichierDtoFromJSON(json: any): ContratFichierDto {
    return ContratFichierDtoFromJSONTyped(json, false);
}

export function ContratFichierDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContratFichierDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontratFichier': !exists(json, 'idcontratFichier') ? undefined : json['idcontratFichier'],
        'idcontrat': !exists(json, 'idcontrat') ? undefined : json['idcontrat'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'idtypeContratFichier': !exists(json, 'idtypeContratFichier') ? undefined : json['idtypeContratFichier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
    };
}

export function ContratFichierDtoToJSON(value?: ContratFichierDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontratFichier': value.idcontratFichier,
        'idcontrat': value.idcontrat,
        'idfichier': value.idfichier,
        'idtypeContratFichier': value.idtypeContratFichier,
        'fileName': value.fileName,
    };
}

