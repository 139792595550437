/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { DateSearch } from './DateSearch';
import {
    DateSearchFromJSON,
    DateSearchFromJSONTyped,
    DateSearchToJSON,
} from './DateSearch';
import type { Int32ListSearch } from './Int32ListSearch';
import {
    Int32ListSearchFromJSON,
    Int32ListSearchFromJSONTyped,
    Int32ListSearchToJSON,
} from './Int32ListSearch';
import type { NumericSearch } from './NumericSearch';
import {
    NumericSearchFromJSON,
    NumericSearchFromJSONTyped,
    NumericSearchToJSON,
} from './NumericSearch';
import type { StringListSearch } from './StringListSearch';
import {
    StringListSearchFromJSON,
    StringListSearchFromJSONTyped,
    StringListSearchToJSON,
} from './StringListSearch';
import type { TextSearch } from './TextSearch';
import {
    TextSearchFromJSON,
    TextSearchFromJSONTyped,
    TextSearchToJSON,
} from './TextSearch';

/**
 * 
 * @export
 * @interface DeliberationTypeSearch
 */
export interface DeliberationTypeSearch {
    /**
     * 
     * @type {TextSearch}
     * @memberof DeliberationTypeSearch
     */
    nom?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof DeliberationTypeSearch
     */
    prenom?: TextSearch;
    /**
     * 
     * @type {DateSearch}
     * @memberof DeliberationTypeSearch
     */
    dateNaissance?: DateSearch;
    /**
     * 
     * @type {StringListSearch}
     * @memberof DeliberationTypeSearch
     */
    anneeScolaire?: StringListSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof DeliberationTypeSearch
     */
    idtypeCours?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof DeliberationTypeSearch
     */
    deliberation?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof DeliberationTypeSearch
     */
    deliberation2sess?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof DeliberationTypeSearch
     */
    metier?: TextSearch;
    /**
     * 
     * @type {StringListSearch}
     * @memberof DeliberationTypeSearch
     */
    degre?: StringListSearch;
    /**
     * 
     * @type {NumericSearch}
     * @memberof DeliberationTypeSearch
     */
    idclasse?: NumericSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof DeliberationTypeSearch
     */
    idstade?: Int32ListSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof DeliberationTypeSearch
     */
    idcentre?: Int32ListSearch;
    /**
     * 
     * @type {boolean}
     * @memberof DeliberationTypeSearch
     */
    canPrintBulletin?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeliberationTypeSearch
     */
    has2Sess?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DeliberationTypeSearch
     */
    idbulletinApprenantType?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof DeliberationTypeSearch
     */
    selectedIds?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliberationTypeSearch
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof DeliberationTypeSearch
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof DeliberationTypeSearch
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeliberationTypeSearch
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof DeliberationTypeSearch
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof DeliberationTypeSearch
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the DeliberationTypeSearch interface.
 */
export function instanceOfDeliberationTypeSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeliberationTypeSearchFromJSON(json: any): DeliberationTypeSearch {
    return DeliberationTypeSearchFromJSONTyped(json, false);
}

export function DeliberationTypeSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliberationTypeSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nom': !exists(json, 'nom') ? undefined : TextSearchFromJSON(json['nom']),
        'prenom': !exists(json, 'prenom') ? undefined : TextSearchFromJSON(json['prenom']),
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : DateSearchFromJSON(json['dateNaissance']),
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : StringListSearchFromJSON(json['anneeScolaire']),
        'idtypeCours': !exists(json, 'idtypeCours') ? undefined : TextSearchFromJSON(json['idtypeCours']),
        'deliberation': !exists(json, 'deliberation') ? undefined : TextSearchFromJSON(json['deliberation']),
        'deliberation2sess': !exists(json, 'deliberation2sess') ? undefined : TextSearchFromJSON(json['deliberation2sess']),
        'metier': !exists(json, 'metier') ? undefined : TextSearchFromJSON(json['metier']),
        'degre': !exists(json, 'degre') ? undefined : StringListSearchFromJSON(json['degre']),
        'idclasse': !exists(json, 'idclasse') ? undefined : NumericSearchFromJSON(json['idclasse']),
        'idstade': !exists(json, 'idstade') ? undefined : Int32ListSearchFromJSON(json['idstade']),
        'idcentre': !exists(json, 'idcentre') ? undefined : Int32ListSearchFromJSON(json['idcentre']),
        'canPrintBulletin': !exists(json, 'canPrintBulletin') ? undefined : json['canPrintBulletin'],
        'has2Sess': !exists(json, 'has2Sess') ? undefined : json['has2Sess'],
        'idbulletinApprenantType': !exists(json, 'idbulletinApprenantType') ? undefined : json['idbulletinApprenantType'],
        'selectedIds': !exists(json, 'selectedIds') ? undefined : json['selectedIds'],
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function DeliberationTypeSearchToJSON(value?: DeliberationTypeSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nom': TextSearchToJSON(value.nom),
        'prenom': TextSearchToJSON(value.prenom),
        'dateNaissance': DateSearchToJSON(value.dateNaissance),
        'anneeScolaire': StringListSearchToJSON(value.anneeScolaire),
        'idtypeCours': TextSearchToJSON(value.idtypeCours),
        'deliberation': TextSearchToJSON(value.deliberation),
        'deliberation2sess': TextSearchToJSON(value.deliberation2sess),
        'metier': TextSearchToJSON(value.metier),
        'degre': StringListSearchToJSON(value.degre),
        'idclasse': NumericSearchToJSON(value.idclasse),
        'idstade': Int32ListSearchToJSON(value.idstade),
        'idcentre': Int32ListSearchToJSON(value.idcentre),
        'canPrintBulletin': value.canPrintBulletin,
        'has2Sess': value.has2Sess,
        'idbulletinApprenantType': value.idbulletinApprenantType,
        'selectedIds': value.selectedIds,
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

