/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { BooleanSearch } from './BooleanSearch';
import {
    BooleanSearchFromJSON,
    BooleanSearchFromJSONTyped,
    BooleanSearchToJSON,
} from './BooleanSearch';
import type { ERapportHopeType } from './ERapportHopeType';
import {
    ERapportHopeTypeFromJSON,
    ERapportHopeTypeFromJSONTyped,
    ERapportHopeTypeToJSON,
} from './ERapportHopeType';
import type { Int32ListSearch } from './Int32ListSearch';
import {
    Int32ListSearchFromJSON,
    Int32ListSearchFromJSONTyped,
    Int32ListSearchToJSON,
} from './Int32ListSearch';
import type { UnaccentTextSearch } from './UnaccentTextSearch';
import {
    UnaccentTextSearchFromJSON,
    UnaccentTextSearchFromJSONTyped,
    UnaccentTextSearchToJSON,
} from './UnaccentTextSearch';

/**
 * 
 * @export
 * @interface RapportHopeSearchDto
 */
export interface RapportHopeSearchDto {
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof RapportHopeSearchDto
     */
    nom?: UnaccentTextSearch;
    /**
     * 
     * @type {BooleanSearch}
     * @memberof RapportHopeSearchDto
     */
    actif?: BooleanSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof RapportHopeSearchDto
     */
    path?: UnaccentTextSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof RapportHopeSearchDto
     */
    idrapportHopeType?: Int32ListSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof RapportHopeSearchDto
     */
    categorie?: UnaccentTextSearch;
    /**
     * 
     * @type {number}
     * @memberof RapportHopeSearchDto
     */
    idmodule?: number | null;
    /**
     * 
     * @type {ERapportHopeType}
     * @memberof RapportHopeSearchDto
     */
    type?: ERapportHopeType;
    /**
     * 
     * @type {Array<string>}
     * @memberof RapportHopeSearchDto
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof RapportHopeSearchDto
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof RapportHopeSearchDto
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RapportHopeSearchDto
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof RapportHopeSearchDto
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof RapportHopeSearchDto
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the RapportHopeSearchDto interface.
 */
export function instanceOfRapportHopeSearchDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RapportHopeSearchDtoFromJSON(json: any): RapportHopeSearchDto {
    return RapportHopeSearchDtoFromJSONTyped(json, false);
}

export function RapportHopeSearchDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RapportHopeSearchDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nom': !exists(json, 'nom') ? undefined : UnaccentTextSearchFromJSON(json['nom']),
        'actif': !exists(json, 'actif') ? undefined : BooleanSearchFromJSON(json['actif']),
        'path': !exists(json, 'path') ? undefined : UnaccentTextSearchFromJSON(json['path']),
        'idrapportHopeType': !exists(json, 'idrapportHopeType') ? undefined : Int32ListSearchFromJSON(json['idrapportHopeType']),
        'categorie': !exists(json, 'categorie') ? undefined : UnaccentTextSearchFromJSON(json['categorie']),
        'idmodule': !exists(json, 'idmodule') ? undefined : json['idmodule'],
        'type': !exists(json, 'type') ? undefined : ERapportHopeTypeFromJSON(json['type']),
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function RapportHopeSearchDtoToJSON(value?: RapportHopeSearchDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nom': UnaccentTextSearchToJSON(value.nom),
        'actif': BooleanSearchToJSON(value.actif),
        'path': UnaccentTextSearchToJSON(value.path),
        'idrapportHopeType': Int32ListSearchToJSON(value.idrapportHopeType),
        'categorie': UnaccentTextSearchToJSON(value.categorie),
        'idmodule': value.idmodule,
        'type': ERapportHopeTypeToJSON(value.type),
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

