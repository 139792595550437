/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EListSearchType } from './EListSearchType';
import {
    EListSearchTypeFromJSON,
    EListSearchTypeFromJSONTyped,
    EListSearchTypeToJSON,
} from './EListSearchType';
import type { EOrigineSuivi } from './EOrigineSuivi';
import {
    EOrigineSuiviFromJSON,
    EOrigineSuiviFromJSONTyped,
    EOrigineSuiviToJSON,
} from './EOrigineSuivi';

/**
 * 
 * @export
 * @interface EListSearchTypeEOrigineSuiviCombinableCriteria
 */
export interface EListSearchTypeEOrigineSuiviCombinableCriteria {
    /**
     * 
     * @type {EListSearchType}
     * @memberof EListSearchTypeEOrigineSuiviCombinableCriteria
     */
    searchMode?: EListSearchType;
    /**
     * 
     * @type {EOrigineSuivi}
     * @memberof EListSearchTypeEOrigineSuiviCombinableCriteria
     */
    value?: EOrigineSuivi;
    /**
     * 
     * @type {EOrigineSuivi}
     * @memberof EListSearchTypeEOrigineSuiviCombinableCriteria
     */
    secondaryValue?: EOrigineSuivi;
}

/**
 * Check if a given object implements the EListSearchTypeEOrigineSuiviCombinableCriteria interface.
 */
export function instanceOfEListSearchTypeEOrigineSuiviCombinableCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EListSearchTypeEOrigineSuiviCombinableCriteriaFromJSON(json: any): EListSearchTypeEOrigineSuiviCombinableCriteria {
    return EListSearchTypeEOrigineSuiviCombinableCriteriaFromJSONTyped(json, false);
}

export function EListSearchTypeEOrigineSuiviCombinableCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): EListSearchTypeEOrigineSuiviCombinableCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchMode': !exists(json, 'searchMode') ? undefined : EListSearchTypeFromJSON(json['searchMode']),
        'value': !exists(json, 'value') ? undefined : EOrigineSuiviFromJSON(json['value']),
        'secondaryValue': !exists(json, 'secondaryValue') ? undefined : EOrigineSuiviFromJSON(json['secondaryValue']),
    };
}

export function EListSearchTypeEOrigineSuiviCombinableCriteriaToJSON(value?: EListSearchTypeEOrigineSuiviCombinableCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchMode': EListSearchTypeToJSON(value.searchMode),
        'value': EOrigineSuiviToJSON(value.value),
        'secondaryValue': EOrigineSuiviToJSON(value.secondaryValue),
    };
}

