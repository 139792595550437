/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EListSearchType } from './EListSearchType';
import {
    EListSearchTypeFromJSON,
    EListSearchTypeFromJSONTyped,
    EListSearchTypeToJSON,
} from './EListSearchType';
import type { EListSearchTypeETypeContratNullableCombinableCriteria } from './EListSearchTypeETypeContratNullableCombinableCriteria';
import {
    EListSearchTypeETypeContratNullableCombinableCriteriaFromJSON,
    EListSearchTypeETypeContratNullableCombinableCriteriaFromJSONTyped,
    EListSearchTypeETypeContratNullableCombinableCriteriaToJSON,
} from './EListSearchTypeETypeContratNullableCombinableCriteria';
import type { ETypeContrat } from './ETypeContrat';
import {
    ETypeContratFromJSON,
    ETypeContratFromJSONTyped,
    ETypeContratToJSON,
} from './ETypeContrat';

/**
 * 
 * @export
 * @interface ETypeContratNullableListSearch
 */
export interface ETypeContratNullableListSearch {
    /**
     * 
     * @type {Array<EListSearchTypeETypeContratNullableCombinableCriteria>}
     * @memberof ETypeContratNullableListSearch
     */
    criterias?: Array<EListSearchTypeETypeContratNullableCombinableCriteria> | null;
    /**
     * 
     * @type {EListSearchType}
     * @memberof ETypeContratNullableListSearch
     */
    searchMode?: EListSearchType;
    /**
     * 
     * @type {ETypeContrat}
     * @memberof ETypeContratNullableListSearch
     */
    value?: ETypeContrat;
    /**
     * 
     * @type {ETypeContrat}
     * @memberof ETypeContratNullableListSearch
     */
    secondaryValue?: ETypeContrat;
    /**
     * 
     * @type {Array<string>}
     * @memberof ETypeContratNullableListSearch
     */
    readonly availableSearchTypes?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ETypeContratNullableListSearch
     */
    defaultSearchType?: string | null;
}

/**
 * Check if a given object implements the ETypeContratNullableListSearch interface.
 */
export function instanceOfETypeContratNullableListSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ETypeContratNullableListSearchFromJSON(json: any): ETypeContratNullableListSearch {
    return ETypeContratNullableListSearchFromJSONTyped(json, false);
}

export function ETypeContratNullableListSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): ETypeContratNullableListSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'criterias': !exists(json, 'criterias') ? undefined : (json['criterias'] === null ? null : (json['criterias'] as Array<any>).map(EListSearchTypeETypeContratNullableCombinableCriteriaFromJSON)),
        'searchMode': !exists(json, 'searchMode') ? undefined : EListSearchTypeFromJSON(json['searchMode']),
        'value': !exists(json, 'value') ? undefined : ETypeContratFromJSON(json['value']),
        'secondaryValue': !exists(json, 'secondaryValue') ? undefined : ETypeContratFromJSON(json['secondaryValue']),
        'availableSearchTypes': !exists(json, 'availableSearchTypes') ? undefined : json['availableSearchTypes'],
        'defaultSearchType': !exists(json, 'defaultSearchType') ? undefined : json['defaultSearchType'],
    };
}

export function ETypeContratNullableListSearchToJSON(value?: ETypeContratNullableListSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'criterias': value.criterias === undefined ? undefined : (value.criterias === null ? null : (value.criterias as Array<any>).map(EListSearchTypeETypeContratNullableCombinableCriteriaToJSON)),
        'searchMode': EListSearchTypeToJSON(value.searchMode),
        'value': ETypeContratToJSON(value.value),
        'secondaryValue': ETypeContratToJSON(value.secondaryValue),
        'defaultSearchType': value.defaultSearchType,
    };
}

