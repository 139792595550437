import { Classes, Dialog } from "@blueprintjs/core";
import { FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";

import { AcquisScolaireHopeApi, AnneeFormationSuivieIfapmeFichierDto } from "../../../../../../api";
import { FGWalterSelectInput, SmallFormGenerator } from "../../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

const StyledDialog = styled(Dialog)`
  width: 600px;
  height: auto;
  background-color: white;
`;

export interface IAnneeFormationSuivieIfapmeDocumentEditDialogProps {
  idanneeFormationSuivieIfapmeFichier: number;
  onClose: (refresh?: boolean) => void;
}

export const AnneeFormationSuivieIfapmeDocumentEditDialog: React.FunctionComponent<IAnneeFormationSuivieIfapmeDocumentEditDialogProps> = ({
  idanneeFormationSuivieIfapmeFichier,
  onClose
}) => {
  const { t } = useTl();
  const api = useApiService(AcquisScolaireHopeApi);

  const { data, loading, saveItem, saving, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () => {
          return api.acquisScolaireHopeGetAnneeFormationSuivieIfapmeFichier({
            id: idanneeFormationSuivieIfapmeFichier
          });
        },
        saveApiFn: async (d: AnneeFormationSuivieIfapmeFichierDto) => {
          return api.acquisScolaireHopeSaveAnneeFormationSuivieIfapmeFichier({
            AnneeFormationSuivieIfapmeFichierDto: d
          });
        },
        onSaved: () => onClose(true)
      }),
      [api, idanneeFormationSuivieIfapmeFichier, onClose]
    )
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape({
        idtypeAcquisFichier: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required))
      }),
    [t]
  );

  const [typesAcquisFichier, tLoading] = useReferential(a => a.referentialGetTypesAcquisFichier());

  return (
    <StyledDialog
      title={t(ETLCodes.UploadFichier)}
      isOpen={!!idanneeFormationSuivieIfapmeFichier}
      onClose={() => onClose(false)}
    >
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose(false)}
          showDeleteButton={false}
          saving={saving}
          loading={loading}
          validationErrors={validationErrors}
        >
          <FieldGroup>
            <FGTextInput name="fileName" label={t(ETLCodes.Fichier)} readonly />
            <FGWalterSelectInput
              name="idtypeAcquisFichier"
              label={t(ETLCodes.Type)}
              items={typesAcquisFichier}
              loading={tLoading}
            />
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
