/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailDto } from './EmailDto';
import {
    EmailDtoFromJSON,
    EmailDtoFromJSONTyped,
    EmailDtoToJSON,
} from './EmailDto';
import type { TelephoneDto } from './TelephoneDto';
import {
    TelephoneDtoFromJSON,
    TelephoneDtoFromJSONTyped,
    TelephoneDtoToJSON,
} from './TelephoneDto';

/**
 * 
 * @export
 * @interface RepresentantDoublonEditDto
 */
export interface RepresentantDoublonEditDto {
    /**
     * 
     * @type {number}
     * @memberof RepresentantDoublonEditDto
     */
    idrepresentant?: number;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof RepresentantDoublonEditDto
     */
    telephoneRepresentant?: TelephoneDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof RepresentantDoublonEditDto
     */
    telephoneProRepresentant?: TelephoneDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof RepresentantDoublonEditDto
     */
    gsmRepresentant?: TelephoneDto;
    /**
     * 
     * @type {EmailDto}
     * @memberof RepresentantDoublonEditDto
     */
    emailRepresentant?: EmailDto;
}

/**
 * Check if a given object implements the RepresentantDoublonEditDto interface.
 */
export function instanceOfRepresentantDoublonEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RepresentantDoublonEditDtoFromJSON(json: any): RepresentantDoublonEditDto {
    return RepresentantDoublonEditDtoFromJSONTyped(json, false);
}

export function RepresentantDoublonEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepresentantDoublonEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idrepresentant': !exists(json, 'idrepresentant') ? undefined : json['idrepresentant'],
        'telephoneRepresentant': !exists(json, 'telephoneRepresentant') ? undefined : TelephoneDtoFromJSON(json['telephoneRepresentant']),
        'telephoneProRepresentant': !exists(json, 'telephoneProRepresentant') ? undefined : TelephoneDtoFromJSON(json['telephoneProRepresentant']),
        'gsmRepresentant': !exists(json, 'gsmRepresentant') ? undefined : TelephoneDtoFromJSON(json['gsmRepresentant']),
        'emailRepresentant': !exists(json, 'emailRepresentant') ? undefined : EmailDtoFromJSON(json['emailRepresentant']),
    };
}

export function RepresentantDoublonEditDtoToJSON(value?: RepresentantDoublonEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idrepresentant': value.idrepresentant,
        'telephoneRepresentant': TelephoneDtoToJSON(value.telephoneRepresentant),
        'telephoneProRepresentant': TelephoneDtoToJSON(value.telephoneProRepresentant),
        'gsmRepresentant': TelephoneDtoToJSON(value.gsmRepresentant),
        'emailRepresentant': EmailDtoToJSON(value.emailRepresentant),
    };
}

