/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ELieuFormationAgrementStatut } from './ELieuFormationAgrementStatut';
import {
    ELieuFormationAgrementStatutFromJSON,
    ELieuFormationAgrementStatutFromJSONTyped,
    ELieuFormationAgrementStatutToJSON,
} from './ELieuFormationAgrementStatut';
import type { FileUploadDto } from './FileUploadDto';
import {
    FileUploadDtoFromJSON,
    FileUploadDtoFromJSONTyped,
    FileUploadDtoToJSON,
} from './FileUploadDto';
import type { LieuFormationAgrementOffreDto } from './LieuFormationAgrementOffreDto';
import {
    LieuFormationAgrementOffreDtoFromJSON,
    LieuFormationAgrementOffreDtoFromJSONTyped,
    LieuFormationAgrementOffreDtoToJSON,
} from './LieuFormationAgrementOffreDto';
import type { LieuFormationAgrementSuspensionDto } from './LieuFormationAgrementSuspensionDto';
import {
    LieuFormationAgrementSuspensionDtoFromJSON,
    LieuFormationAgrementSuspensionDtoFromJSONTyped,
    LieuFormationAgrementSuspensionDtoToJSON,
} from './LieuFormationAgrementSuspensionDto';
import type { LieuFormationAgrementVisiteDto } from './LieuFormationAgrementVisiteDto';
import {
    LieuFormationAgrementVisiteDtoFromJSON,
    LieuFormationAgrementVisiteDtoFromJSONTyped,
    LieuFormationAgrementVisiteDtoToJSON,
} from './LieuFormationAgrementVisiteDto';

/**
 * 
 * @export
 * @interface LieuFormationAgrementEditDto
 */
export interface LieuFormationAgrementEditDto {
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementEditDto
     */
    idlieuFormationAgrement?: number;
    /**
     * 
     * @type {ELieuFormationAgrementStatut}
     * @memberof LieuFormationAgrementEditDto
     */
    statut?: ELieuFormationAgrementStatut;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementEditDto
     */
    nbContrat?: number;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementEditDto
     */
    idlieuFormation?: number;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationAgrementEditDto
     */
    lieuFormation?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementEditDto
     */
    dateReception?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementEditDto
     */
    idmetier?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationAgrementEditDto
     */
    isApprentissage?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementEditDto
     */
    capaciteFormative?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationAgrementEditDto
     */
    dossierComplet?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementEditDto
     */
    idreferentIfapme?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationAgrementEditDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementEditDto
     */
    idlieuFormationAgrementOffre?: number | null;
    /**
     * 
     * @type {LieuFormationAgrementOffreDto}
     * @memberof LieuFormationAgrementEditDto
     */
    offre?: LieuFormationAgrementOffreDto;
    /**
     * 
     * @type {Array<LieuFormationAgrementVisiteDto>}
     * @memberof LieuFormationAgrementEditDto
     */
    visites?: Array<LieuFormationAgrementVisiteDto> | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementEditDto
     */
    dateEnvoiD1?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementEditDto
     */
    dateReceptionAvis?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementEditDto
     */
    idagrementAvis?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationAgrementEditDto
     */
    remarqueSectorielle?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementEditDto
     */
    idfichierDemandeSectorielle?: number | null;
    /**
     * 
     * @type {FileUploadDto}
     * @memberof LieuFormationAgrementEditDto
     */
    fichierDemandeSectorielle?: FileUploadDto;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementEditDto
     */
    idlieuFormationAgrementDecision?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementEditDto
     */
    dateDecision?: Date | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof LieuFormationAgrementEditDto
     */
    motifsNonAgrement?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationAgrementEditDto
     */
    remarqueDecision?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementEditDto
     */
    dateEnvoiD3?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementEditDto
     */
    idfichierEnvoiD3?: number | null;
    /**
     * 
     * @type {FileUploadDto}
     * @memberof LieuFormationAgrementEditDto
     */
    fichierEnvoiD3?: FileUploadDto;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationAgrementEditDto
     */
    agrementPartielA?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationAgrementEditDto
     */
    agrementPartielB?: boolean;
    /**
     * 
     * @type {Array<LieuFormationAgrementSuspensionDto>}
     * @memberof LieuFormationAgrementEditDto
     */
    suspensions?: Array<LieuFormationAgrementSuspensionDto> | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementEditDto
     */
    datePriseEffetRetrait?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementEditDto
     */
    dateRetrait?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationAgrementEditDto
     */
    remarqueRetrait?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationAgrementEditDto
     */
    retraitIsIfapme?: boolean | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof LieuFormationAgrementEditDto
     */
    idsMotifRetrait?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementEditDto
     */
    idfichierRetrait?: number | null;
    /**
     * 
     * @type {FileUploadDto}
     * @memberof LieuFormationAgrementEditDto
     */
    fichierRetrait?: FileUploadDto;
}

/**
 * Check if a given object implements the LieuFormationAgrementEditDto interface.
 */
export function instanceOfLieuFormationAgrementEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LieuFormationAgrementEditDtoFromJSON(json: any): LieuFormationAgrementEditDto {
    return LieuFormationAgrementEditDtoFromJSONTyped(json, false);
}

export function LieuFormationAgrementEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LieuFormationAgrementEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idlieuFormationAgrement': !exists(json, 'idlieuFormationAgrement') ? undefined : json['idlieuFormationAgrement'],
        'statut': !exists(json, 'statut') ? undefined : ELieuFormationAgrementStatutFromJSON(json['statut']),
        'nbContrat': !exists(json, 'nbContrat') ? undefined : json['nbContrat'],
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'lieuFormation': !exists(json, 'lieuFormation') ? undefined : json['lieuFormation'],
        'dateReception': !exists(json, 'dateReception') ? undefined : (json['dateReception'] === null ? null : new Date(json['dateReception'])),
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'isApprentissage': !exists(json, 'isApprentissage') ? undefined : json['isApprentissage'],
        'capaciteFormative': !exists(json, 'capaciteFormative') ? undefined : json['capaciteFormative'],
        'dossierComplet': !exists(json, 'dossierComplet') ? undefined : json['dossierComplet'],
        'idreferentIfapme': !exists(json, 'idreferentIfapme') ? undefined : json['idreferentIfapme'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'idlieuFormationAgrementOffre': !exists(json, 'idlieuFormationAgrementOffre') ? undefined : json['idlieuFormationAgrementOffre'],
        'offre': !exists(json, 'offre') ? undefined : LieuFormationAgrementOffreDtoFromJSON(json['offre']),
        'visites': !exists(json, 'visites') ? undefined : (json['visites'] === null ? null : (json['visites'] as Array<any>).map(LieuFormationAgrementVisiteDtoFromJSON)),
        'dateEnvoiD1': !exists(json, 'dateEnvoiD1') ? undefined : (json['dateEnvoiD1'] === null ? null : new Date(json['dateEnvoiD1'])),
        'dateReceptionAvis': !exists(json, 'dateReceptionAvis') ? undefined : (json['dateReceptionAvis'] === null ? null : new Date(json['dateReceptionAvis'])),
        'idagrementAvis': !exists(json, 'idagrementAvis') ? undefined : json['idagrementAvis'],
        'remarqueSectorielle': !exists(json, 'remarqueSectorielle') ? undefined : json['remarqueSectorielle'],
        'idfichierDemandeSectorielle': !exists(json, 'idfichierDemandeSectorielle') ? undefined : json['idfichierDemandeSectorielle'],
        'fichierDemandeSectorielle': !exists(json, 'fichierDemandeSectorielle') ? undefined : FileUploadDtoFromJSON(json['fichierDemandeSectorielle']),
        'idlieuFormationAgrementDecision': !exists(json, 'idlieuFormationAgrementDecision') ? undefined : json['idlieuFormationAgrementDecision'],
        'dateDecision': !exists(json, 'dateDecision') ? undefined : (json['dateDecision'] === null ? null : new Date(json['dateDecision'])),
        'motifsNonAgrement': !exists(json, 'motifsNonAgrement') ? undefined : json['motifsNonAgrement'],
        'remarqueDecision': !exists(json, 'remarqueDecision') ? undefined : json['remarqueDecision'],
        'dateEnvoiD3': !exists(json, 'dateEnvoiD3') ? undefined : (json['dateEnvoiD3'] === null ? null : new Date(json['dateEnvoiD3'])),
        'idfichierEnvoiD3': !exists(json, 'idfichierEnvoiD3') ? undefined : json['idfichierEnvoiD3'],
        'fichierEnvoiD3': !exists(json, 'fichierEnvoiD3') ? undefined : FileUploadDtoFromJSON(json['fichierEnvoiD3']),
        'agrementPartielA': !exists(json, 'agrementPartielA') ? undefined : json['agrementPartielA'],
        'agrementPartielB': !exists(json, 'agrementPartielB') ? undefined : json['agrementPartielB'],
        'suspensions': !exists(json, 'suspensions') ? undefined : (json['suspensions'] === null ? null : (json['suspensions'] as Array<any>).map(LieuFormationAgrementSuspensionDtoFromJSON)),
        'datePriseEffetRetrait': !exists(json, 'datePriseEffetRetrait') ? undefined : (json['datePriseEffetRetrait'] === null ? null : new Date(json['datePriseEffetRetrait'])),
        'dateRetrait': !exists(json, 'dateRetrait') ? undefined : (json['dateRetrait'] === null ? null : new Date(json['dateRetrait'])),
        'remarqueRetrait': !exists(json, 'remarqueRetrait') ? undefined : json['remarqueRetrait'],
        'retraitIsIfapme': !exists(json, 'retraitIsIfapme') ? undefined : json['retraitIsIfapme'],
        'idsMotifRetrait': !exists(json, 'idsMotifRetrait') ? undefined : json['idsMotifRetrait'],
        'idfichierRetrait': !exists(json, 'idfichierRetrait') ? undefined : json['idfichierRetrait'],
        'fichierRetrait': !exists(json, 'fichierRetrait') ? undefined : FileUploadDtoFromJSON(json['fichierRetrait']),
    };
}

export function LieuFormationAgrementEditDtoToJSON(value?: LieuFormationAgrementEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idlieuFormationAgrement': value.idlieuFormationAgrement,
        'statut': ELieuFormationAgrementStatutToJSON(value.statut),
        'nbContrat': value.nbContrat,
        'idlieuFormation': value.idlieuFormation,
        'lieuFormation': value.lieuFormation,
        'dateReception': value.dateReception === undefined ? undefined : (value.dateReception === null ? null : value.dateReception.toISOString()),
        'idmetier': value.idmetier,
        'isApprentissage': value.isApprentissage,
        'capaciteFormative': value.capaciteFormative,
        'dossierComplet': value.dossierComplet,
        'idreferentIfapme': value.idreferentIfapme,
        'actif': value.actif,
        'idlieuFormationAgrementOffre': value.idlieuFormationAgrementOffre,
        'offre': LieuFormationAgrementOffreDtoToJSON(value.offre),
        'visites': value.visites === undefined ? undefined : (value.visites === null ? null : (value.visites as Array<any>).map(LieuFormationAgrementVisiteDtoToJSON)),
        'dateEnvoiD1': value.dateEnvoiD1 === undefined ? undefined : (value.dateEnvoiD1 === null ? null : value.dateEnvoiD1.toISOString()),
        'dateReceptionAvis': value.dateReceptionAvis === undefined ? undefined : (value.dateReceptionAvis === null ? null : value.dateReceptionAvis.toISOString()),
        'idagrementAvis': value.idagrementAvis,
        'remarqueSectorielle': value.remarqueSectorielle,
        'idfichierDemandeSectorielle': value.idfichierDemandeSectorielle,
        'fichierDemandeSectorielle': FileUploadDtoToJSON(value.fichierDemandeSectorielle),
        'idlieuFormationAgrementDecision': value.idlieuFormationAgrementDecision,
        'dateDecision': value.dateDecision === undefined ? undefined : (value.dateDecision === null ? null : value.dateDecision.toISOString()),
        'motifsNonAgrement': value.motifsNonAgrement,
        'remarqueDecision': value.remarqueDecision,
        'dateEnvoiD3': value.dateEnvoiD3 === undefined ? undefined : (value.dateEnvoiD3 === null ? null : value.dateEnvoiD3.toISOString()),
        'idfichierEnvoiD3': value.idfichierEnvoiD3,
        'fichierEnvoiD3': FileUploadDtoToJSON(value.fichierEnvoiD3),
        'agrementPartielA': value.agrementPartielA,
        'agrementPartielB': value.agrementPartielB,
        'suspensions': value.suspensions === undefined ? undefined : (value.suspensions === null ? null : (value.suspensions as Array<any>).map(LieuFormationAgrementSuspensionDtoToJSON)),
        'datePriseEffetRetrait': value.datePriseEffetRetrait === undefined ? undefined : (value.datePriseEffetRetrait === null ? null : value.datePriseEffetRetrait.toISOString()),
        'dateRetrait': value.dateRetrait === undefined ? undefined : (value.dateRetrait === null ? null : value.dateRetrait.toISOString()),
        'remarqueRetrait': value.remarqueRetrait,
        'retraitIsIfapme': value.retraitIsIfapme,
        'idsMotifRetrait': value.idsMotifRetrait,
        'idfichierRetrait': value.idfichierRetrait,
        'fichierRetrait': FileUploadDtoToJSON(value.fichierRetrait),
    };
}

