/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbClasseMatiereMultipleDto
 */
export interface FcbClasseMatiereMultipleDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof FcbClasseMatiereMultipleDto
     */
    idclasseMatiereList?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereMultipleDto
     */
    idclasse?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereMultipleDto
     */
    idformateur?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereMultipleDto
     */
    idformateurLocalisationContrat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbClasseMatiereMultipleDto
     */
    idprojetFinancement?: number | null;
}

/**
 * Check if a given object implements the FcbClasseMatiereMultipleDto interface.
 */
export function instanceOfFcbClasseMatiereMultipleDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbClasseMatiereMultipleDtoFromJSON(json: any): FcbClasseMatiereMultipleDto {
    return FcbClasseMatiereMultipleDtoFromJSONTyped(json, false);
}

export function FcbClasseMatiereMultipleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbClasseMatiereMultipleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idclasseMatiereList': !exists(json, 'idclasseMatiereList') ? undefined : json['idclasseMatiereList'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'idformateurLocalisationContrat': !exists(json, 'idformateurLocalisationContrat') ? undefined : json['idformateurLocalisationContrat'],
        'idprojetFinancement': !exists(json, 'idprojetFinancement') ? undefined : json['idprojetFinancement'],
    };
}

export function FcbClasseMatiereMultipleDtoToJSON(value?: FcbClasseMatiereMultipleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idclasseMatiereList': value.idclasseMatiereList,
        'idclasse': value.idclasse,
        'idformateur': value.idformateur,
        'idformateurLocalisationContrat': value.idformateurLocalisationContrat,
        'idprojetFinancement': value.idprojetFinancement,
    };
}

