/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  FilterCriteriaInfo,
  LieuFormationAgrementDecisionMultipleDto,
  LieuFormationAgrementDemandeSectorielleMultipleDto,
  LieuFormationAgrementEditDto,
  LieuFormationAgrementGridDtoPaginatedResults,
  LieuFormationAgrementOffreDto,
  LieuFormationAgrementRetraitMultipleDto,
  LieuFormationAgrementSearchDto,
  LieuFormationAgrementSuspensionDto,
  LieuFormationAgrementSuspensionMultipleDto,
  LieuFormationAgrementVisiteMultipleDto,
  ReferentialItemDto,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    LieuFormationAgrementDecisionMultipleDtoFromJSON,
    LieuFormationAgrementDecisionMultipleDtoToJSON,
    LieuFormationAgrementDemandeSectorielleMultipleDtoFromJSON,
    LieuFormationAgrementDemandeSectorielleMultipleDtoToJSON,
    LieuFormationAgrementEditDtoFromJSON,
    LieuFormationAgrementEditDtoToJSON,
    LieuFormationAgrementGridDtoPaginatedResultsFromJSON,
    LieuFormationAgrementGridDtoPaginatedResultsToJSON,
    LieuFormationAgrementOffreDtoFromJSON,
    LieuFormationAgrementOffreDtoToJSON,
    LieuFormationAgrementRetraitMultipleDtoFromJSON,
    LieuFormationAgrementRetraitMultipleDtoToJSON,
    LieuFormationAgrementSearchDtoFromJSON,
    LieuFormationAgrementSearchDtoToJSON,
    LieuFormationAgrementSuspensionDtoFromJSON,
    LieuFormationAgrementSuspensionDtoToJSON,
    LieuFormationAgrementSuspensionMultipleDtoFromJSON,
    LieuFormationAgrementSuspensionMultipleDtoToJSON,
    LieuFormationAgrementVisiteMultipleDtoFromJSON,
    LieuFormationAgrementVisiteMultipleDtoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface LieuFormationAgrementBaseSearchRequest {
    LieuFormationAgrementSearchDto?: LieuFormationAgrementSearchDto;
}

export interface LieuFormationAgrementCanEditSuspensionsMultipleRequest {
    request_body?: Array<number>;
}

export interface LieuFormationAgrementCheckCapaciteFormativeAtteinteRequest {
    idlieuFormationAgrementOffre?: number;
}

export interface LieuFormationAgrementCheckStatutRetraitRequest {
    request_body?: Array<number>;
}

export interface LieuFormationAgrementDecisionMultipleRequest {
    LieuFormationAgrementDecisionMultipleDto?: LieuFormationAgrementDecisionMultipleDto;
}

export interface LieuFormationAgrementDeleteLieuFormationAgrementRequest {
    idlieuFormationAgrement?: number;
}

export interface LieuFormationAgrementDemandeSectorielleMultipleRequest {
    LieuFormationAgrementDemandeSectorielleMultipleDto?: LieuFormationAgrementDemandeSectorielleMultipleDto;
}

export interface LieuFormationAgrementEditSuspensionRequest {
    LieuFormationAgrementSuspensionDto?: LieuFormationAgrementSuspensionDto;
}

export interface LieuFormationAgrementGetLieuFormationAgrementRequest {
    idlieuFormationAgrement?: number;
}

export interface LieuFormationAgrementGetOffreRequest {
    idlieuFormationAgrementOffre?: number;
}

export interface LieuFormationAgrementGetOffreDisplayNameRequest {
    idlieuFormationAgrementOffre?: number;
}

export interface LieuFormationAgrementGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface LieuFormationAgrementGetSelectItemsRequest {
    searchField: string;
}

export interface LieuFormationAgrementGetSuspensionRequest {
    idlieuFormationAgrementSuspension?: number;
}

export interface LieuFormationAgrementRetraitMultipleRequest {
    LieuFormationAgrementRetraitMultipleDto?: LieuFormationAgrementRetraitMultipleDto;
}

export interface LieuFormationAgrementSaveLieuFormationAgrementRequest {
    LieuFormationAgrementEditDto?: LieuFormationAgrementEditDto;
}

export interface LieuFormationAgrementSaveOffreRequest {
    LieuFormationAgrementOffreDto?: LieuFormationAgrementOffreDto;
}

export interface LieuFormationAgrementSearchIdsRequest {
    LieuFormationAgrementSearchDto?: LieuFormationAgrementSearchDto;
}

export interface LieuFormationAgrementSuspensionMultipleRequest {
    LieuFormationAgrementSuspensionMultipleDto?: LieuFormationAgrementSuspensionMultipleDto;
}

export interface LieuFormationAgrementUpdateOffreRequest {
    newValue?: boolean;
    idlieuFormationAgrement?: number;
}

export interface LieuFormationAgrementVisiteMultipleRequest {
    LieuFormationAgrementVisiteMultipleDto?: LieuFormationAgrementVisiteMultipleDto;
}

/**
 * 
 */
export class LieuFormationAgrementApi extends runtime.BaseAPI {

    /**
     */
    async lieuFormationAgrementBaseSearchRaw(requestParameters: LieuFormationAgrementBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationAgrementGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationAgrement/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LieuFormationAgrementSearchDtoToJSON(requestParameters.LieuFormationAgrementSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationAgrementGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationAgrementBaseSearch(requestParameters: LieuFormationAgrementBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationAgrementGridDtoPaginatedResults> {
        const response = await this.lieuFormationAgrementBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationAgrementCanEditSuspensionsMultipleRaw(requestParameters: LieuFormationAgrementCanEditSuspensionsMultipleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationAgrement/CanEditSuspensionsMultiple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request_body,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async lieuFormationAgrementCanEditSuspensionsMultiple(requestParameters: LieuFormationAgrementCanEditSuspensionsMultipleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.lieuFormationAgrementCanEditSuspensionsMultipleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationAgrementCheckCapaciteFormativeAtteinteRaw(requestParameters: LieuFormationAgrementCheckCapaciteFormativeAtteinteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idlieuFormationAgrementOffre !== undefined) {
            queryParameters['idlieuFormationAgrementOffre'] = requestParameters.idlieuFormationAgrementOffre;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationAgrement/CheckCapaciteFormativeAtteinte`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationAgrementCheckCapaciteFormativeAtteinte(requestParameters: LieuFormationAgrementCheckCapaciteFormativeAtteinteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.lieuFormationAgrementCheckCapaciteFormativeAtteinteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationAgrementCheckStatutRetraitRaw(requestParameters: LieuFormationAgrementCheckStatutRetraitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationAgrement/CheckStatutRetrait`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request_body,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationAgrementCheckStatutRetrait(requestParameters: LieuFormationAgrementCheckStatutRetraitRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.lieuFormationAgrementCheckStatutRetraitRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationAgrementDecisionMultipleRaw(requestParameters: LieuFormationAgrementDecisionMultipleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationAgrementDecisionMultipleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationAgrement/DecisionMultiple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LieuFormationAgrementDecisionMultipleDtoToJSON(requestParameters.LieuFormationAgrementDecisionMultipleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationAgrementDecisionMultipleDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationAgrementDecisionMultiple(requestParameters: LieuFormationAgrementDecisionMultipleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationAgrementDecisionMultipleDto> {
        const response = await this.lieuFormationAgrementDecisionMultipleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationAgrementDeleteLieuFormationAgrementRaw(requestParameters: LieuFormationAgrementDeleteLieuFormationAgrementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.idlieuFormationAgrement !== undefined) {
            queryParameters['idlieuFormationAgrement'] = requestParameters.idlieuFormationAgrement;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationAgrement`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async lieuFormationAgrementDeleteLieuFormationAgrement(requestParameters: LieuFormationAgrementDeleteLieuFormationAgrementRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.lieuFormationAgrementDeleteLieuFormationAgrementRaw(requestParameters, initOverrides);
    }

    /**
     */
    async lieuFormationAgrementDemandeSectorielleMultipleRaw(requestParameters: LieuFormationAgrementDemandeSectorielleMultipleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationAgrementDemandeSectorielleMultipleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationAgrement/DemandeSectorielleMultiple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LieuFormationAgrementDemandeSectorielleMultipleDtoToJSON(requestParameters.LieuFormationAgrementDemandeSectorielleMultipleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationAgrementDemandeSectorielleMultipleDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationAgrementDemandeSectorielleMultiple(requestParameters: LieuFormationAgrementDemandeSectorielleMultipleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationAgrementDemandeSectorielleMultipleDto> {
        const response = await this.lieuFormationAgrementDemandeSectorielleMultipleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationAgrementEditSuspensionRaw(requestParameters: LieuFormationAgrementEditSuspensionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationAgrementSuspensionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationAgrement/EditSuspension`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LieuFormationAgrementSuspensionDtoToJSON(requestParameters.LieuFormationAgrementSuspensionDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationAgrementSuspensionDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationAgrementEditSuspension(requestParameters: LieuFormationAgrementEditSuspensionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationAgrementSuspensionDto> {
        const response = await this.lieuFormationAgrementEditSuspensionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationAgrementGetLieuFormationAgrementRaw(requestParameters: LieuFormationAgrementGetLieuFormationAgrementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationAgrementEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.idlieuFormationAgrement !== undefined) {
            queryParameters['idlieuFormationAgrement'] = requestParameters.idlieuFormationAgrement;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationAgrement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationAgrementEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationAgrementGetLieuFormationAgrement(requestParameters: LieuFormationAgrementGetLieuFormationAgrementRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationAgrementEditDto> {
        const response = await this.lieuFormationAgrementGetLieuFormationAgrementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationAgrementGetOffreRaw(requestParameters: LieuFormationAgrementGetOffreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationAgrementOffreDto>> {
        const queryParameters: any = {};

        if (requestParameters.idlieuFormationAgrementOffre !== undefined) {
            queryParameters['idlieuFormationAgrementOffre'] = requestParameters.idlieuFormationAgrementOffre;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationAgrement/GetOffre`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationAgrementOffreDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationAgrementGetOffre(requestParameters: LieuFormationAgrementGetOffreRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationAgrementOffreDto> {
        const response = await this.lieuFormationAgrementGetOffreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationAgrementGetOffreDisplayNameRaw(requestParameters: LieuFormationAgrementGetOffreDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.idlieuFormationAgrementOffre !== undefined) {
            queryParameters['idlieuFormationAgrementOffre'] = requestParameters.idlieuFormationAgrementOffre;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationAgrement/GetOffreDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async lieuFormationAgrementGetOffreDisplayName(requestParameters: LieuFormationAgrementGetOffreDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.lieuFormationAgrementGetOffreDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationAgrementGetSearchCriteriasRaw(requestParameters: LieuFormationAgrementGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationAgrement/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async lieuFormationAgrementGetSearchCriterias(requestParameters: LieuFormationAgrementGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.lieuFormationAgrementGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationAgrementGetSelectItemsRaw(requestParameters: LieuFormationAgrementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling lieuFormationAgrementGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationAgrement/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async lieuFormationAgrementGetSelectItems(requestParameters: LieuFormationAgrementGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.lieuFormationAgrementGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationAgrementGetSuspensionRaw(requestParameters: LieuFormationAgrementGetSuspensionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationAgrementSuspensionDto>> {
        const queryParameters: any = {};

        if (requestParameters.idlieuFormationAgrementSuspension !== undefined) {
            queryParameters['idlieuFormationAgrementSuspension'] = requestParameters.idlieuFormationAgrementSuspension;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationAgrement/GetSuspension`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationAgrementSuspensionDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationAgrementGetSuspension(requestParameters: LieuFormationAgrementGetSuspensionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationAgrementSuspensionDto> {
        const response = await this.lieuFormationAgrementGetSuspensionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationAgrementRetraitMultipleRaw(requestParameters: LieuFormationAgrementRetraitMultipleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationAgrementRetraitMultipleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationAgrement/RetraitMultiple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LieuFormationAgrementRetraitMultipleDtoToJSON(requestParameters.LieuFormationAgrementRetraitMultipleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationAgrementRetraitMultipleDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationAgrementRetraitMultiple(requestParameters: LieuFormationAgrementRetraitMultipleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationAgrementRetraitMultipleDto> {
        const response = await this.lieuFormationAgrementRetraitMultipleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationAgrementSaveLieuFormationAgrementRaw(requestParameters: LieuFormationAgrementSaveLieuFormationAgrementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationAgrementEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationAgrement`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LieuFormationAgrementEditDtoToJSON(requestParameters.LieuFormationAgrementEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationAgrementEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationAgrementSaveLieuFormationAgrement(requestParameters: LieuFormationAgrementSaveLieuFormationAgrementRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationAgrementEditDto> {
        const response = await this.lieuFormationAgrementSaveLieuFormationAgrementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationAgrementSaveOffreRaw(requestParameters: LieuFormationAgrementSaveOffreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationAgrementOffreDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationAgrement/SaveOffre`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LieuFormationAgrementOffreDtoToJSON(requestParameters.LieuFormationAgrementOffreDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationAgrementOffreDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationAgrementSaveOffre(requestParameters: LieuFormationAgrementSaveOffreRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationAgrementOffreDto> {
        const response = await this.lieuFormationAgrementSaveOffreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationAgrementSearchIdsRaw(requestParameters: LieuFormationAgrementSearchIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationAgrement/SearchIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LieuFormationAgrementSearchDtoToJSON(requestParameters.LieuFormationAgrementSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async lieuFormationAgrementSearchIds(requestParameters: LieuFormationAgrementSearchIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.lieuFormationAgrementSearchIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationAgrementSuspensionMultipleRaw(requestParameters: LieuFormationAgrementSuspensionMultipleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationAgrementSuspensionMultipleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationAgrement/SuspensionMultiple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LieuFormationAgrementSuspensionMultipleDtoToJSON(requestParameters.LieuFormationAgrementSuspensionMultipleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationAgrementSuspensionMultipleDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationAgrementSuspensionMultiple(requestParameters: LieuFormationAgrementSuspensionMultipleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationAgrementSuspensionMultipleDto> {
        const response = await this.lieuFormationAgrementSuspensionMultipleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationAgrementUpdateOffreRaw(requestParameters: LieuFormationAgrementUpdateOffreRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.newValue !== undefined) {
            queryParameters['newValue'] = requestParameters.newValue;
        }

        if (requestParameters.idlieuFormationAgrement !== undefined) {
            queryParameters['idlieuFormationAgrement'] = requestParameters.idlieuFormationAgrement;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationAgrement/UpdateOffre`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async lieuFormationAgrementUpdateOffre(requestParameters: LieuFormationAgrementUpdateOffreRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.lieuFormationAgrementUpdateOffreRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async lieuFormationAgrementVisiteMultipleRaw(requestParameters: LieuFormationAgrementVisiteMultipleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieuFormationAgrementVisiteMultipleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/LieuFormationAgrement/VisiteMultiple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LieuFormationAgrementVisiteMultipleDtoToJSON(requestParameters.LieuFormationAgrementVisiteMultipleDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieuFormationAgrementVisiteMultipleDtoFromJSON(jsonValue));
    }

    /**
     */
    async lieuFormationAgrementVisiteMultiple(requestParameters: LieuFormationAgrementVisiteMultipleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieuFormationAgrementVisiteMultipleDto> {
        const response = await this.lieuFormationAgrementVisiteMultipleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
