/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ELieuFormationAgrementStatut } from './ELieuFormationAgrementStatut';
import {
    ELieuFormationAgrementStatutFromJSON,
    ELieuFormationAgrementStatutFromJSONTyped,
    ELieuFormationAgrementStatutToJSON,
} from './ELieuFormationAgrementStatut';

/**
 * 
 * @export
 * @interface LieuFormationAgrementGridDto
 */
export interface LieuFormationAgrementGridDto {
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementGridDto
     */
    idlieuFormationAgrement?: number;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementGridDto
     */
    idlieuFormation?: number;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationAgrementGridDto
     */
    lieuFormationNom?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationAgrementGridDto
     */
    offreActive?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementGridDto
     */
    capaciteFormative?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementGridDto
     */
    nbContrat?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationAgrementGridDto
     */
    readonly capaciteAtteinte?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationAgrementGridDto
     */
    codeMetier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationAgrementGridDto
     */
    nomMetier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationAgrementGridDto
     */
    nomStade?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LieuFormationAgrementGridDto
     */
    decision?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof LieuFormationAgrementGridDto
     */
    dateDecision?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationAgrementGridDto
     */
    agrementPartielA?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationAgrementGridDto
     */
    agrementPartielB?: boolean;
    /**
     * 
     * @type {ELieuFormationAgrementStatut}
     * @memberof LieuFormationAgrementGridDto
     */
    statut?: ELieuFormationAgrementStatut;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementGridDto
     */
    idEditableCurrentSuspension?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LieuFormationAgrementGridDto
     */
    idlieuFormationAgrementOffre?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof LieuFormationAgrementGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the LieuFormationAgrementGridDto interface.
 */
export function instanceOfLieuFormationAgrementGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LieuFormationAgrementGridDtoFromJSON(json: any): LieuFormationAgrementGridDto {
    return LieuFormationAgrementGridDtoFromJSONTyped(json, false);
}

export function LieuFormationAgrementGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LieuFormationAgrementGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idlieuFormationAgrement': !exists(json, 'idlieuFormationAgrement') ? undefined : json['idlieuFormationAgrement'],
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'lieuFormationNom': !exists(json, 'lieuFormationNom') ? undefined : json['lieuFormationNom'],
        'offreActive': !exists(json, 'offreActive') ? undefined : json['offreActive'],
        'capaciteFormative': !exists(json, 'capaciteFormative') ? undefined : json['capaciteFormative'],
        'nbContrat': !exists(json, 'nbContrat') ? undefined : json['nbContrat'],
        'capaciteAtteinte': !exists(json, 'capaciteAtteinte') ? undefined : json['capaciteAtteinte'],
        'codeMetier': !exists(json, 'codeMetier') ? undefined : json['codeMetier'],
        'nomMetier': !exists(json, 'nomMetier') ? undefined : json['nomMetier'],
        'nomStade': !exists(json, 'nomStade') ? undefined : json['nomStade'],
        'decision': !exists(json, 'decision') ? undefined : json['decision'],
        'dateDecision': !exists(json, 'dateDecision') ? undefined : (json['dateDecision'] === null ? null : new Date(json['dateDecision'])),
        'agrementPartielA': !exists(json, 'agrementPartielA') ? undefined : json['agrementPartielA'],
        'agrementPartielB': !exists(json, 'agrementPartielB') ? undefined : json['agrementPartielB'],
        'statut': !exists(json, 'statut') ? undefined : ELieuFormationAgrementStatutFromJSON(json['statut']),
        'idEditableCurrentSuspension': !exists(json, 'idEditableCurrentSuspension') ? undefined : json['idEditableCurrentSuspension'],
        'idlieuFormationAgrementOffre': !exists(json, 'idlieuFormationAgrementOffre') ? undefined : json['idlieuFormationAgrementOffre'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function LieuFormationAgrementGridDtoToJSON(value?: LieuFormationAgrementGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idlieuFormationAgrement': value.idlieuFormationAgrement,
        'idlieuFormation': value.idlieuFormation,
        'lieuFormationNom': value.lieuFormationNom,
        'offreActive': value.offreActive,
        'capaciteFormative': value.capaciteFormative,
        'nbContrat': value.nbContrat,
        'codeMetier': value.codeMetier,
        'nomMetier': value.nomMetier,
        'nomStade': value.nomStade,
        'decision': value.decision,
        'dateDecision': value.dateDecision === undefined ? undefined : (value.dateDecision === null ? null : value.dateDecision.toISOString()),
        'agrementPartielA': value.agrementPartielA,
        'agrementPartielB': value.agrementPartielB,
        'statut': ELieuFormationAgrementStatutToJSON(value.statut),
        'idEditableCurrentSuspension': value.idEditableCurrentSuspension,
        'idlieuFormationAgrementOffre': value.idlieuFormationAgrementOffre,
        'actif': value.actif,
    };
}

