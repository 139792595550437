/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbVisiteGridDto
 */
export interface FcbVisiteGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbVisiteGridDto
     */
    idvisite?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbVisiteGridDto
     */
    idcentre?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbVisiteGridDto
     */
    datevisiteDebut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbVisiteGridDto
     */
    duree?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbVisiteGridDto
     */
    contact?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbVisiteGridDto
     */
    accord?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbVisiteGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbVisiteGridDto
     */
    anneeAcademique?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbVisiteGridDto
     */
    lieu?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbVisiteGridDto
     */
    iduser?: number | null;
}

/**
 * Check if a given object implements the FcbVisiteGridDto interface.
 */
export function instanceOfFcbVisiteGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbVisiteGridDtoFromJSON(json: any): FcbVisiteGridDto {
    return FcbVisiteGridDtoFromJSONTyped(json, false);
}

export function FcbVisiteGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbVisiteGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idvisite': !exists(json, 'idvisite') ? undefined : json['idvisite'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'datevisiteDebut': !exists(json, 'datevisiteDebut') ? undefined : json['datevisiteDebut'],
        'duree': !exists(json, 'duree') ? undefined : json['duree'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'accord': !exists(json, 'accord') ? undefined : json['accord'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'anneeAcademique': !exists(json, 'anneeAcademique') ? undefined : json['anneeAcademique'],
        'lieu': !exists(json, 'lieu') ? undefined : json['lieu'],
        'iduser': !exists(json, 'iduser') ? undefined : json['iduser'],
    };
}

export function FcbVisiteGridDtoToJSON(value?: FcbVisiteGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idvisite': value.idvisite,
        'idcentre': value.idcentre,
        'datevisiteDebut': value.datevisiteDebut,
        'duree': value.duree,
        'contact': value.contact,
        'accord': value.accord,
        'nom': value.nom,
        'anneeAcademique': value.anneeAcademique,
        'lieu': value.lieu,
        'iduser': value.iduser,
    };
}

