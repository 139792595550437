/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdresseDto } from './AdresseDto';
import {
    AdresseDtoFromJSON,
    AdresseDtoFromJSONTyped,
    AdresseDtoToJSON,
} from './AdresseDto';
import type { ApprenantContratDto } from './ApprenantContratDto';
import {
    ApprenantContratDtoFromJSON,
    ApprenantContratDtoFromJSONTyped,
    ApprenantContratDtoToJSON,
} from './ApprenantContratDto';
import type { CompteBancaireDto } from './CompteBancaireDto';
import {
    CompteBancaireDtoFromJSON,
    CompteBancaireDtoFromJSONTyped,
    CompteBancaireDtoToJSON,
} from './CompteBancaireDto';
import type { EmailDto } from './EmailDto';
import {
    EmailDtoFromJSON,
    EmailDtoFromJSONTyped,
    EmailDtoToJSON,
} from './EmailDto';
import type { TelephoneDto } from './TelephoneDto';
import {
    TelephoneDtoFromJSON,
    TelephoneDtoFromJSONTyped,
    TelephoneDtoToJSON,
} from './TelephoneDto';

/**
 * 
 * @export
 * @interface FcbApprenantDto
 */
export interface FcbApprenantDto {
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    idecole?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    idanneeReussie?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    iddiplome?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    codesexe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    nationalite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    codePaysNaissance?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbApprenantDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    localiteNaissance?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    communeNaissance?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    registreNational?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    numeroIdentification?: string | null;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof FcbApprenantDto
     */
    telephoneApprenant?: TelephoneDto;
    /**
     * 
     * @type {TelephoneDto}
     * @memberof FcbApprenantDto
     */
    gsmApprenant?: TelephoneDto;
    /**
     * 
     * @type {EmailDto}
     * @memberof FcbApprenantDto
     */
    emailApprenant?: EmailDto;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    remarqueService?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbApprenantDto
     */
    c2?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbApprenantDto
     */
    forem?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbApprenantDto
     */
    courrierPrincipal?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbApprenantDto
     */
    courrierAutre?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    dossierUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    photo?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    idfichierPhoto?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbApprenantDto
     */
    dateDeces?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbApprenantDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    modificationUserName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbApprenantDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    anneeScolaireReussie?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    interne?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    searchNom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    searchPrenom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    derniereAnneeScolaire?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    centres?: number | null;
    /**
     * 
     * @type {CompteBancaireDto}
     * @memberof FcbApprenantDto
     */
    compteBancaire?: CompteBancaireDto;
    /**
     * 
     * @type {boolean}
     * @memberof FcbApprenantDto
     */
    beid?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    beidnumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbApprenantDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    azureUserId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    azureEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    azureTemporaryPassword?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbApprenantDto
     */
    azureActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    emergencyContactPhone?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    emergencyContactId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    idPri?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    idAri?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    idCop?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    bankAccountBeneficiaryId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbApprenantDto
     */
    bankAccountBeneficiaryName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbApprenantDto
     */
    isDeceased?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    idpersonne?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbApprenantDto
     */
    permisB?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbApprenantDto
     */
    permisAm?: boolean;
    /**
     * 
     * @type {AdresseDto}
     * @memberof FcbApprenantDto
     */
    adresseApprenant?: AdresseDto;
    /**
     * 
     * @type {AdresseDto}
     * @memberof FcbApprenantDto
     */
    adresseCourrierApprenant?: AdresseDto;
    /**
     * 
     * @type {boolean}
     * @memberof FcbApprenantDto
     */
    securise?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbApprenantDto
     */
    hasAdresseDoublons?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbApprenantDto
     */
    hasAdresseCourrierDoublons?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    idParcoursFormation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    idstatutEntree?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbApprenantDto
     */
    lastDateSortie?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbApprenantDto
     */
    dateEntree?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbApprenantDto
     */
    dateEntreeReseau?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    idstatutSortie?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbApprenantDto
     */
    dateSortie?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    idMotifSortie?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    idtypeSortie?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    idCategorieStatut?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    idStatut?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    idstatutCrea?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    idcategorieSpecifiqueCrea?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FcbApprenantDto
     */
    idsSocieteExterne?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbApprenantDto
     */
    isSortieEnCours?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    iddureeInoccupation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    nbJoursRechercheEntreprise?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbApprenantDto
     */
    dateDebutRechercheEntreprise?: Date | null;
    /**
     * 
     * @type {ApprenantContratDto}
     * @memberof FcbApprenantDto
     */
    contrat?: ApprenantContratDto;
    /**
     * 
     * @type {number}
     * @memberof FcbApprenantDto
     */
    iddemandeFormationCrea?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbApprenantDto
     */
    isProspect?: boolean;
}

/**
 * Check if a given object implements the FcbApprenantDto interface.
 */
export function instanceOfFcbApprenantDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbApprenantDtoFromJSON(json: any): FcbApprenantDto {
    return FcbApprenantDtoFromJSONTyped(json, false);
}

export function FcbApprenantDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbApprenantDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idecole': !exists(json, 'idecole') ? undefined : json['idecole'],
        'idanneeReussie': !exists(json, 'idanneeReussie') ? undefined : json['idanneeReussie'],
        'iddiplome': !exists(json, 'iddiplome') ? undefined : json['iddiplome'],
        'codesexe': !exists(json, 'codesexe') ? undefined : json['codesexe'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'nationalite': !exists(json, 'nationalite') ? undefined : json['nationalite'],
        'codePaysNaissance': !exists(json, 'codePaysNaissance') ? undefined : json['codePaysNaissance'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'localiteNaissance': !exists(json, 'localiteNaissance') ? undefined : json['localiteNaissance'],
        'communeNaissance': !exists(json, 'communeNaissance') ? undefined : json['communeNaissance'],
        'registreNational': !exists(json, 'registreNational') ? undefined : json['registreNational'],
        'numeroIdentification': !exists(json, 'numeroIdentification') ? undefined : json['numeroIdentification'],
        'telephoneApprenant': !exists(json, 'telephoneApprenant') ? undefined : TelephoneDtoFromJSON(json['telephoneApprenant']),
        'gsmApprenant': !exists(json, 'gsmApprenant') ? undefined : TelephoneDtoFromJSON(json['gsmApprenant']),
        'emailApprenant': !exists(json, 'emailApprenant') ? undefined : EmailDtoFromJSON(json['emailApprenant']),
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'remarqueService': !exists(json, 'remarqueService') ? undefined : json['remarqueService'],
        'c2': !exists(json, 'c2') ? undefined : json['c2'],
        'forem': !exists(json, 'forem') ? undefined : json['forem'],
        'courrierPrincipal': !exists(json, 'courrierPrincipal') ? undefined : json['courrierPrincipal'],
        'courrierAutre': !exists(json, 'courrierAutre') ? undefined : json['courrierAutre'],
        'dossierUrl': !exists(json, 'dossierUrl') ? undefined : json['dossierUrl'],
        'photo': !exists(json, 'photo') ? undefined : json['photo'],
        'idfichierPhoto': !exists(json, 'idfichierPhoto') ? undefined : json['idfichierPhoto'],
        'dateDeces': !exists(json, 'dateDeces') ? undefined : (json['dateDeces'] === null ? null : new Date(json['dateDeces'])),
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationUserName': !exists(json, 'modificationUserName') ? undefined : json['modificationUserName'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'anneeScolaireReussie': !exists(json, 'anneeScolaireReussie') ? undefined : json['anneeScolaireReussie'],
        'interne': !exists(json, 'interne') ? undefined : json['interne'],
        'searchNom': !exists(json, 'searchNom') ? undefined : json['searchNom'],
        'searchPrenom': !exists(json, 'searchPrenom') ? undefined : json['searchPrenom'],
        'derniereAnneeScolaire': !exists(json, 'derniereAnneeScolaire') ? undefined : json['derniereAnneeScolaire'],
        'centres': !exists(json, 'centres') ? undefined : json['centres'],
        'compteBancaire': !exists(json, 'compteBancaire') ? undefined : CompteBancaireDtoFromJSON(json['compteBancaire']),
        'beid': !exists(json, 'beid') ? undefined : json['beid'],
        'beidnumber': !exists(json, 'beidnumber') ? undefined : json['beidnumber'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'azureUserId': !exists(json, 'azureUserId') ? undefined : json['azureUserId'],
        'azureEmail': !exists(json, 'azureEmail') ? undefined : json['azureEmail'],
        'azureTemporaryPassword': !exists(json, 'azureTemporaryPassword') ? undefined : json['azureTemporaryPassword'],
        'azureActive': !exists(json, 'azureActive') ? undefined : json['azureActive'],
        'emergencyContactPhone': !exists(json, 'emergencyContactPhone') ? undefined : json['emergencyContactPhone'],
        'emergencyContactId': !exists(json, 'emergencyContactId') ? undefined : json['emergencyContactId'],
        'idPri': !exists(json, 'idPri') ? undefined : json['idPri'],
        'idAri': !exists(json, 'idAri') ? undefined : json['idAri'],
        'idCop': !exists(json, 'idCop') ? undefined : json['idCop'],
        'bankAccountBeneficiaryId': !exists(json, 'bankAccountBeneficiaryId') ? undefined : json['bankAccountBeneficiaryId'],
        'bankAccountBeneficiaryName': !exists(json, 'bankAccountBeneficiaryName') ? undefined : json['bankAccountBeneficiaryName'],
        'isDeceased': !exists(json, 'isDeceased') ? undefined : json['isDeceased'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'permisB': !exists(json, 'permisB') ? undefined : json['permisB'],
        'permisAm': !exists(json, 'permisAm') ? undefined : json['permisAm'],
        'adresseApprenant': !exists(json, 'adresseApprenant') ? undefined : AdresseDtoFromJSON(json['adresseApprenant']),
        'adresseCourrierApprenant': !exists(json, 'adresseCourrierApprenant') ? undefined : AdresseDtoFromJSON(json['adresseCourrierApprenant']),
        'securise': !exists(json, 'securise') ? undefined : json['securise'],
        'hasAdresseDoublons': !exists(json, 'hasAdresseDoublons') ? undefined : json['hasAdresseDoublons'],
        'hasAdresseCourrierDoublons': !exists(json, 'hasAdresseCourrierDoublons') ? undefined : json['hasAdresseCourrierDoublons'],
        'idParcoursFormation': !exists(json, 'idParcoursFormation') ? undefined : json['idParcoursFormation'],
        'idstatutEntree': !exists(json, 'idstatutEntree') ? undefined : json['idstatutEntree'],
        'lastDateSortie': !exists(json, 'lastDateSortie') ? undefined : (json['lastDateSortie'] === null ? null : new Date(json['lastDateSortie'])),
        'dateEntree': !exists(json, 'dateEntree') ? undefined : (json['dateEntree'] === null ? null : new Date(json['dateEntree'])),
        'dateEntreeReseau': !exists(json, 'dateEntreeReseau') ? undefined : (json['dateEntreeReseau'] === null ? null : new Date(json['dateEntreeReseau'])),
        'idstatutSortie': !exists(json, 'idstatutSortie') ? undefined : json['idstatutSortie'],
        'dateSortie': !exists(json, 'dateSortie') ? undefined : (json['dateSortie'] === null ? null : new Date(json['dateSortie'])),
        'idMotifSortie': !exists(json, 'idMotifSortie') ? undefined : json['idMotifSortie'],
        'idtypeSortie': !exists(json, 'idtypeSortie') ? undefined : json['idtypeSortie'],
        'idCategorieStatut': !exists(json, 'idCategorieStatut') ? undefined : json['idCategorieStatut'],
        'idStatut': !exists(json, 'idStatut') ? undefined : json['idStatut'],
        'idstatutCrea': !exists(json, 'idstatutCrea') ? undefined : json['idstatutCrea'],
        'idcategorieSpecifiqueCrea': !exists(json, 'idcategorieSpecifiqueCrea') ? undefined : json['idcategorieSpecifiqueCrea'],
        'idsSocieteExterne': !exists(json, 'idsSocieteExterne') ? undefined : json['idsSocieteExterne'],
        'isSortieEnCours': !exists(json, 'isSortieEnCours') ? undefined : json['isSortieEnCours'],
        'iddureeInoccupation': !exists(json, 'iddureeInoccupation') ? undefined : json['iddureeInoccupation'],
        'nbJoursRechercheEntreprise': !exists(json, 'nbJoursRechercheEntreprise') ? undefined : json['nbJoursRechercheEntreprise'],
        'dateDebutRechercheEntreprise': !exists(json, 'dateDebutRechercheEntreprise') ? undefined : (json['dateDebutRechercheEntreprise'] === null ? null : new Date(json['dateDebutRechercheEntreprise'])),
        'contrat': !exists(json, 'contrat') ? undefined : ApprenantContratDtoFromJSON(json['contrat']),
        'iddemandeFormationCrea': !exists(json, 'iddemandeFormationCrea') ? undefined : json['iddemandeFormationCrea'],
        'isProspect': !exists(json, 'isProspect') ? undefined : json['isProspect'],
    };
}

export function FcbApprenantDtoToJSON(value?: FcbApprenantDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idapprenant': value.idapprenant,
        'idecole': value.idecole,
        'idanneeReussie': value.idanneeReussie,
        'iddiplome': value.iddiplome,
        'codesexe': value.codesexe,
        'nom': value.nom,
        'prenom': value.prenom,
        'nationalite': value.nationalite,
        'codePaysNaissance': value.codePaysNaissance,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'localiteNaissance': value.localiteNaissance,
        'communeNaissance': value.communeNaissance,
        'registreNational': value.registreNational,
        'numeroIdentification': value.numeroIdentification,
        'telephoneApprenant': TelephoneDtoToJSON(value.telephoneApprenant),
        'gsmApprenant': TelephoneDtoToJSON(value.gsmApprenant),
        'emailApprenant': EmailDtoToJSON(value.emailApprenant),
        'remarque': value.remarque,
        'remarqueService': value.remarqueService,
        'c2': value.c2,
        'forem': value.forem,
        'courrierPrincipal': value.courrierPrincipal,
        'courrierAutre': value.courrierAutre,
        'dossierUrl': value.dossierUrl,
        'photo': value.photo,
        'idfichierPhoto': value.idfichierPhoto,
        'dateDeces': value.dateDeces === undefined ? undefined : (value.dateDeces === null ? null : value.dateDeces.toISOString()),
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationUserName': value.modificationUserName,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'anneeScolaireReussie': value.anneeScolaireReussie,
        'interne': value.interne,
        'searchNom': value.searchNom,
        'searchPrenom': value.searchPrenom,
        'derniereAnneeScolaire': value.derniereAnneeScolaire,
        'centres': value.centres,
        'compteBancaire': CompteBancaireDtoToJSON(value.compteBancaire),
        'beid': value.beid,
        'beidnumber': value.beidnumber,
        'actif': value.actif,
        'azureUserId': value.azureUserId,
        'azureEmail': value.azureEmail,
        'azureTemporaryPassword': value.azureTemporaryPassword,
        'azureActive': value.azureActive,
        'emergencyContactPhone': value.emergencyContactPhone,
        'emergencyContactId': value.emergencyContactId,
        'idPri': value.idPri,
        'idAri': value.idAri,
        'idCop': value.idCop,
        'bankAccountBeneficiaryId': value.bankAccountBeneficiaryId,
        'bankAccountBeneficiaryName': value.bankAccountBeneficiaryName,
        'isDeceased': value.isDeceased,
        'idpersonne': value.idpersonne,
        'permisB': value.permisB,
        'permisAm': value.permisAm,
        'adresseApprenant': AdresseDtoToJSON(value.adresseApprenant),
        'adresseCourrierApprenant': AdresseDtoToJSON(value.adresseCourrierApprenant),
        'securise': value.securise,
        'hasAdresseDoublons': value.hasAdresseDoublons,
        'hasAdresseCourrierDoublons': value.hasAdresseCourrierDoublons,
        'idParcoursFormation': value.idParcoursFormation,
        'idstatutEntree': value.idstatutEntree,
        'lastDateSortie': value.lastDateSortie === undefined ? undefined : (value.lastDateSortie === null ? null : value.lastDateSortie.toISOString()),
        'dateEntree': value.dateEntree === undefined ? undefined : (value.dateEntree === null ? null : value.dateEntree.toISOString()),
        'dateEntreeReseau': value.dateEntreeReseau === undefined ? undefined : (value.dateEntreeReseau === null ? null : value.dateEntreeReseau.toISOString()),
        'idstatutSortie': value.idstatutSortie,
        'dateSortie': value.dateSortie === undefined ? undefined : (value.dateSortie === null ? null : value.dateSortie.toISOString()),
        'idMotifSortie': value.idMotifSortie,
        'idtypeSortie': value.idtypeSortie,
        'idCategorieStatut': value.idCategorieStatut,
        'idStatut': value.idStatut,
        'idstatutCrea': value.idstatutCrea,
        'idcategorieSpecifiqueCrea': value.idcategorieSpecifiqueCrea,
        'idsSocieteExterne': value.idsSocieteExterne,
        'isSortieEnCours': value.isSortieEnCours,
        'iddureeInoccupation': value.iddureeInoccupation,
        'nbJoursRechercheEntreprise': value.nbJoursRechercheEntreprise,
        'dateDebutRechercheEntreprise': value.dateDebutRechercheEntreprise === undefined ? undefined : (value.dateDebutRechercheEntreprise === null ? null : value.dateDebutRechercheEntreprise.toISOString()),
        'contrat': ApprenantContratDtoToJSON(value.contrat),
        'iddemandeFormationCrea': value.iddemandeFormationCrea,
        'isProspect': value.isProspect,
    };
}

