/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FilterCriteriaInfo,
  ModuleCreaEditDto,
  ModuleCreaGridDtoPaginatedResults,
  ModuleCreaSearchDto,
  ReferentialItemDto,
} from '../models/index';
import {
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ModuleCreaEditDtoFromJSON,
    ModuleCreaEditDtoToJSON,
    ModuleCreaGridDtoPaginatedResultsFromJSON,
    ModuleCreaGridDtoPaginatedResultsToJSON,
    ModuleCreaSearchDtoFromJSON,
    ModuleCreaSearchDtoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface ModuleCreaBaseSearchRequest {
    ModuleCreaSearchDto?: ModuleCreaSearchDto;
}

export interface ModuleCreaDeleteModuleCreaRequest {
    id?: number;
}

export interface ModuleCreaGetDisplayNameRequest {
    id?: number;
}

export interface ModuleCreaGetModuleCreaRequest {
    id?: number;
}

export interface ModuleCreaGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ModuleCreaGetSelectItemsRequest {
    searchField: string;
}

export interface ModuleCreaSaveModuleCreaRequest {
    ModuleCreaEditDto?: ModuleCreaEditDto;
}

/**
 * 
 */
export class ModuleCreaApi extends runtime.BaseAPI {

    /**
     */
    async moduleCreaBaseSearchRaw(requestParameters: ModuleCreaBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModuleCreaGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ModuleCrea/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModuleCreaSearchDtoToJSON(requestParameters.ModuleCreaSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModuleCreaGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async moduleCreaBaseSearch(requestParameters: ModuleCreaBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModuleCreaGridDtoPaginatedResults> {
        const response = await this.moduleCreaBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async moduleCreaDeleteModuleCreaRaw(requestParameters: ModuleCreaDeleteModuleCreaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ModuleCrea`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async moduleCreaDeleteModuleCrea(requestParameters: ModuleCreaDeleteModuleCreaRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.moduleCreaDeleteModuleCreaRaw(requestParameters, initOverrides);
    }

    /**
     */
    async moduleCreaGetDisplayNameRaw(requestParameters: ModuleCreaGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ModuleCrea/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async moduleCreaGetDisplayName(requestParameters: ModuleCreaGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.moduleCreaGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async moduleCreaGetModuleCreaRaw(requestParameters: ModuleCreaGetModuleCreaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModuleCreaEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ModuleCrea`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModuleCreaEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async moduleCreaGetModuleCrea(requestParameters: ModuleCreaGetModuleCreaRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModuleCreaEditDto> {
        const response = await this.moduleCreaGetModuleCreaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async moduleCreaGetSearchCriteriasRaw(requestParameters: ModuleCreaGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ModuleCrea/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async moduleCreaGetSearchCriterias(requestParameters: ModuleCreaGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.moduleCreaGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async moduleCreaGetSelectItemsRaw(requestParameters: ModuleCreaGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling moduleCreaGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ModuleCrea/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async moduleCreaGetSelectItems(requestParameters: ModuleCreaGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.moduleCreaGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async moduleCreaSaveModuleCreaRaw(requestParameters: ModuleCreaSaveModuleCreaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModuleCreaEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ModuleCrea`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModuleCreaEditDtoToJSON(requestParameters.ModuleCreaEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModuleCreaEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async moduleCreaSaveModuleCrea(requestParameters: ModuleCreaSaveModuleCreaRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModuleCreaEditDto> {
        const response = await this.moduleCreaSaveModuleCreaRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
