import { FGCustomPanel, FGEmpty, FGTextAreaInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { EcoleApi, FcbEcoleDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import {
  CommuneSelect,
  FGWalterSelectInput,
  SmallFormGenerator,
  FGWalterCheckboxInput
} from "../../../../../components";
import { LocaliteSelect } from "../../../../../components/LocaliteSelect";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

interface IEcoleDetailPageProps {
  idEcole: number;
  editMode: boolean;
}

export const EcoleDetailPage: React.FunctionComponent<IEcoleDetailPageProps> = ({ idEcole, editMode }) => {
  const { t } = useTl();
  const api = useApiService(EcoleApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () => (+idEcole <= 0 ? FcbEcoleDtoFromJSON({ idEcole: 0 }) : api.ecoleGet({ id: +idEcole })),
    saveApiFn: d => api.ecoleSave({ FcbEcoleDto: d }),
    onSavedRoute: d => `${ERoutes.ecole}/${d.idecole}/detail/edit`,
    deleteApiFn: d => api.ecoleDelete({ id: d.idecole }),
    onDeletedRoute: () => ERoutes.ecole
  });

  const [pays, loadingPays] = useReferential(a => a.referentialGetPays(), false, []);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      codePays: Yup.string().required(t(ETLCodes.Required)),
      nom: Yup.string().required(t(ETLCodes.Required))
    });
  }, [t]);

  const Localite = formik => {
    return <LocaliteSelect codePostal={formik.values.codePostal} codePays={formik.values.codePays} name={"localite"}
      codePostalTextName="codePostal"
      disableIfNoCp={false} />;
  };

  const Commune = formik => {
    return <CommuneSelect codePostal={formik.values.codePostal} codePays={formik.values.codePays} name={"commune"} />;
  };

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={editMode}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(ERoutes.ecole)}
        onDelete={deleteItem}
        showDeleteButton={+idEcole > 0}
        saving={saving}
        deleting={deleting}
      >
        <FieldGroup columns={2}>
          <FGTextInput name="nom" label={t(ETLCodes.Nom)} maxLength={100} />
          <FGEmpty />
          <FGWalterSelectInput name="codePays" label={t(ETLCodes.Pays)} items={pays} loading={loadingPays} />
          <FGEmpty />
          <FGTextInput name="adresse" label={t(ETLCodes.Adresse)} maxLength={100} />
          <FGEmpty />
        </FieldGroup>
        <FieldGroup columns={[3, 9]}>
          <FGTextInput name="codePostal" label={t(ETLCodes.CodePostal)} maxLength={6} />
          <FGEmpty />
        </FieldGroup>
        <FieldGroup columns={2}>
          <FGCustomPanel>{ctx => Localite(ctx.formik)}</FGCustomPanel>
          <FGEmpty />
          <FGCustomPanel>{ctx => Commune(ctx.formik)}</FGCustomPanel>
          <FGEmpty />
          <FGTextInput name="telephone" label={t(ETLCodes.Tel)} maxLength={20} />
          <FGEmpty />
          <FGTextInput name="fax" label={t(ETLCodes.Fax)} maxLength={20} />
          <FGEmpty />
        </FieldGroup>
        <FieldGroup columns={2}>
          <FGTextAreaInput name="remarque" label={t(ETLCodes.Remarque)} maxLength={100} />
          <FGEmpty />
          <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
