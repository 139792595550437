import * as React from "react";
import styled from "styled-components";

const StyledMessage = styled.div`
  display: flex;
  justify-content: flex-end;
  color: red;
  margin-top: 1rem;
`;

export interface IStyledErrorProps {
  show: boolean;
  message: string;
}

export const StyledError: React.FunctionComponent<IStyledErrorProps> = ({ show, message }) => {
  return <>{show && <StyledMessage>{message}</StyledMessage>}</>;
};
