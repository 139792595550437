import { IDataTableColumn } from "nsitools-react";
import * as React from "react";

import { FormateurClasseApi, FormateurClasseSearch, ETextSearchType } from "../../../../../api";
import { SearchTablePage } from "../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { useGlobalData } from "../../../../../contexts";

export interface IFormateurClasseSearchPageProps {
  formateurId: number;
}

export const FormateurClasseSearchPage: React.FunctionComponent<IFormateurClasseSearchPageProps> = ({
  formateurId
}) => {
  const { t } = useTl();
  const api = useApiService(FormateurClasseApi);
  const { currentAnneeScolaire } = useGlobalData();

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.CodeClasse),
        fieldName: "codeClasse",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Classe),
        fieldName: "nomClasse"
      },
      {
        header: () => t(ETLCodes.CodeMatiere),
        fieldName: "codeMatiere",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Matiere),
        fieldName: "nomMatiere"
      },
      {
        header: () => t(ETLCodes.DateDebut),
        fieldName: "dateDebut"
      },
      {
        header: () => t(ETLCodes.DateFin),
        fieldName: "dateFin"
      },
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "centre"
      },
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "anneeScolaire"
      }
    ],
    [t]
  );

  const getCriteriasFn = React.useCallback(() => api.formateurClasseGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const apiFactory = useAbortableApiServiceFactory(FormateurClasseApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: FormateurClasseSearch) => {
      sObj.idformateur = formateurId;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.formateurClasseBaseSearch({ FormateurClasseSearch: sObj });
    },
    [apiFactory, formateurId]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <SearchTablePage
        columns={columns}
        getCriteriasFunction={getCriteriasFn}
        searchFunction={searchFn}
        onAbort={onAbort}
        defaultCriterias={[
          {
            criteria: "AnneeScolaire",
            searchMode: ETextSearchType.Equals,
            value: currentAnneeScolaire?.idanneeScolaire
          }
        ]}
        breadCrumbs={[{ text: t(ETLCodes.Formateurs) }]}
        sortKeys={{ anneeScolaire: "DESC" }}
        withCard={false}
      />
    </>
  );
};
