/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApprenantFichierDto,
  ApprenantFichierGridDtoPaginatedResults,
  ApprenantFichierSearchDto,
  ApprenantFichierUploadDto,
  FileDownloadDto,
  FilterCriteriaInfo,
  ReferentialItemDto,
} from '../models/index';
import {
    ApprenantFichierDtoFromJSON,
    ApprenantFichierDtoToJSON,
    ApprenantFichierGridDtoPaginatedResultsFromJSON,
    ApprenantFichierGridDtoPaginatedResultsToJSON,
    ApprenantFichierSearchDtoFromJSON,
    ApprenantFichierSearchDtoToJSON,
    ApprenantFichierUploadDtoFromJSON,
    ApprenantFichierUploadDtoToJSON,
    FileDownloadDtoFromJSON,
    FileDownloadDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface ApprenantFichierBaseSearchRequest {
    ApprenantFichierSearchDto?: ApprenantFichierSearchDto;
}

export interface ApprenantFichierDeleteApprenantFichierRequest {
    id?: number;
}

export interface ApprenantFichierDeleteAvisResultatRequest {
    id?: number;
}

export interface ApprenantFichierDownloadApprenantFichierRequest {
    id?: number;
}

export interface ApprenantFichierGetApprenantFichierRequest {
    id?: number;
}

export interface ApprenantFichierGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ApprenantFichierGetSelectItemsRequest {
    searchField: string;
}

export interface ApprenantFichierSaveApprenantFichierRequest {
    ApprenantFichierDto?: ApprenantFichierDto;
}

export interface ApprenantFichierSearchDocumentsReglementairesRequest {
    idapprenant?: number;
}

export interface ApprenantFichierUploadApprenantFichierRequest {
    ApprenantFichierUploadDto?: ApprenantFichierUploadDto;
}

/**
 * 
 */
export class ApprenantFichierApi extends runtime.BaseAPI {

    /**
     */
    async apprenantFichierBaseSearchRaw(requestParameters: ApprenantFichierBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApprenantFichierGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantFichier/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApprenantFichierSearchDtoToJSON(requestParameters.ApprenantFichierSearchDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApprenantFichierGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async apprenantFichierBaseSearch(requestParameters: ApprenantFichierBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApprenantFichierGridDtoPaginatedResults> {
        const response = await this.apprenantFichierBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantFichierDeleteApprenantFichierRaw(requestParameters: ApprenantFichierDeleteApprenantFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantFichier`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apprenantFichierDeleteApprenantFichier(requestParameters: ApprenantFichierDeleteApprenantFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apprenantFichierDeleteApprenantFichierRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apprenantFichierDeleteAvisResultatRaw(requestParameters: ApprenantFichierDeleteAvisResultatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantFichier/DeleteAvisResultats`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apprenantFichierDeleteAvisResultat(requestParameters: ApprenantFichierDeleteAvisResultatRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apprenantFichierDeleteAvisResultatRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apprenantFichierDownloadApprenantFichierRaw(requestParameters: ApprenantFichierDownloadApprenantFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantFichier/Download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantFichierDownloadApprenantFichier(requestParameters: ApprenantFichierDownloadApprenantFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.apprenantFichierDownloadApprenantFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantFichierGetApprenantFichierRaw(requestParameters: ApprenantFichierGetApprenantFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApprenantFichierDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantFichier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApprenantFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantFichierGetApprenantFichier(requestParameters: ApprenantFichierGetApprenantFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApprenantFichierDto> {
        const response = await this.apprenantFichierGetApprenantFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantFichierGetSearchCriteriasRaw(requestParameters: ApprenantFichierGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantFichier/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async apprenantFichierGetSearchCriterias(requestParameters: ApprenantFichierGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.apprenantFichierGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantFichierGetSelectItemsRaw(requestParameters: ApprenantFichierGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling apprenantFichierGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantFichier/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async apprenantFichierGetSelectItems(requestParameters: ApprenantFichierGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.apprenantFichierGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantFichierSaveApprenantFichierRaw(requestParameters: ApprenantFichierSaveApprenantFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApprenantFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantFichier`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApprenantFichierDtoToJSON(requestParameters.ApprenantFichierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApprenantFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantFichierSaveApprenantFichier(requestParameters: ApprenantFichierSaveApprenantFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApprenantFichierDto> {
        const response = await this.apprenantFichierSaveApprenantFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantFichierSearchDocumentsReglementairesRaw(requestParameters: ApprenantFichierSearchDocumentsReglementairesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApprenantFichierGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantFichier/SearchDocumentsReglementaires`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApprenantFichierGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async apprenantFichierSearchDocumentsReglementaires(requestParameters: ApprenantFichierSearchDocumentsReglementairesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApprenantFichierGridDtoPaginatedResults> {
        const response = await this.apprenantFichierSearchDocumentsReglementairesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantFichierUploadApprenantFichierRaw(requestParameters: ApprenantFichierUploadApprenantFichierRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApprenantFichierDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantFichier/Upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApprenantFichierUploadDtoToJSON(requestParameters.ApprenantFichierUploadDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApprenantFichierDtoFromJSON(jsonValue));
    }

    /**
     */
    async apprenantFichierUploadApprenantFichier(requestParameters: ApprenantFichierUploadApprenantFichierRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApprenantFichierDto> {
        const response = await this.apprenantFichierUploadApprenantFichierRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
