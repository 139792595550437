/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormateurHoraireIncoherenceGridDto
 */
export interface FormateurHoraireIncoherenceGridDto {
    /**
     * 
     * @type {number}
     * @memberof FormateurHoraireIncoherenceGridDto
     */
    idformateur?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FormateurHoraireIncoherenceGridDto
     */
    jour?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurHoraireIncoherenceGridDto
     */
    heureDebut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurHoraireIncoherenceGridDto
     */
    heureFin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurHoraireIncoherenceGridDto
     */
    local?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurHoraireIncoherenceGridDto
     */
    formateur?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurHoraireIncoherenceGridDto
     */
    motif?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FormateurHoraireIncoherenceGridDto
     */
    idtypeIncoherence?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurHoraireIncoherenceGridDto
     */
    typeIncoherence?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormateurHoraireIncoherenceGridDto
     */
    anneeAcademique?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormateurHoraireIncoherenceGridDto
     */
    centreUtilisateurFlag?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FormateurHoraireIncoherenceGridDto
     */
    iduser?: number | null;
}

/**
 * Check if a given object implements the FormateurHoraireIncoherenceGridDto interface.
 */
export function instanceOfFormateurHoraireIncoherenceGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FormateurHoraireIncoherenceGridDtoFromJSON(json: any): FormateurHoraireIncoherenceGridDto {
    return FormateurHoraireIncoherenceGridDtoFromJSONTyped(json, false);
}

export function FormateurHoraireIncoherenceGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormateurHoraireIncoherenceGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'jour': !exists(json, 'jour') ? undefined : (json['jour'] === null ? null : new Date(json['jour'])),
        'heureDebut': !exists(json, 'heureDebut') ? undefined : json['heureDebut'],
        'heureFin': !exists(json, 'heureFin') ? undefined : json['heureFin'],
        'local': !exists(json, 'local') ? undefined : json['local'],
        'formateur': !exists(json, 'formateur') ? undefined : json['formateur'],
        'motif': !exists(json, 'motif') ? undefined : json['motif'],
        'idtypeIncoherence': !exists(json, 'idtypeIncoherence') ? undefined : json['idtypeIncoherence'],
        'typeIncoherence': !exists(json, 'typeIncoherence') ? undefined : json['typeIncoherence'],
        'anneeAcademique': !exists(json, 'anneeAcademique') ? undefined : json['anneeAcademique'],
        'centreUtilisateurFlag': !exists(json, 'centreUtilisateurFlag') ? undefined : json['centreUtilisateurFlag'],
        'iduser': !exists(json, 'iduser') ? undefined : json['iduser'],
    };
}

export function FormateurHoraireIncoherenceGridDtoToJSON(value?: FormateurHoraireIncoherenceGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idformateur': value.idformateur,
        'jour': value.jour === undefined ? undefined : (value.jour === null ? null : value.jour.toISOString()),
        'heureDebut': value.heureDebut,
        'heureFin': value.heureFin,
        'local': value.local,
        'formateur': value.formateur,
        'motif': value.motif,
        'idtypeIncoherence': value.idtypeIncoherence,
        'typeIncoherence': value.typeIncoherence,
        'anneeAcademique': value.anneeAcademique,
        'centreUtilisateurFlag': value.centreUtilisateurFlag,
        'iduser': value.iduser,
    };
}

