import { Classes, Colors, InputGroup } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router";
import styled from "styled-components";

import { FcbFormateurAzureGridDto, FormateurAzureApi, FormateurAzureSearch } from "../../../../../api";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IFormateurActifCentreTableProps {
  codeCentres?: string;
}

const ViewInput = styled(InputGroup)<{ colorcheck: string }>`
  & .${Classes.ICON} {
    color: ${p => p.colorcheck};
    margin-left: 0;
  }
`;

export const FormateurActifCentreTable: React.FunctionComponent<IFormateurActifCentreTableProps> = ({
  codeCentres
}) => {
  const { t } = useTl();
  const api = useApiService(FormateurAzureApi);
  const { id } = useParams<{ id: string }>();

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { centre: "ASC" }
  });

  const { totalCount } = tableState;

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "centre"
      },
      {
        header: () => t(ETLCodes.Actif),
        fieldName: "actif",
        autoFitContent: true,
        alignment: "center",
        render: (row: FcbFormateurAzureGridDto) => (
          <ViewInput
            large={false}
            leftIcon={row.azureActive ? IconNames.TICK : IconNames.CROSS}
            readOnly={true}
            style={{ boxShadow: "none", backgroundColor: "transparent" }}
            colorcheck={row.azureActive ? Colors.GREEN5 : Colors.RED5}
          />
        )
      }
    ],
    [t]
  );

  const search = React.useCallback(
    (nextSearch?: FormateurAzureSearch) => {
      nextSearch.idFormateur = +id;
      nextSearch.codeCentres = codeCentres;
      return api.formateurAzureBaseSearch({ FormateurAzureSearch: nextSearch });
    },
    [api, codeCentres, id]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction: search,
    tableState,
    initialSearch: true
  });

  return (
    <>
      <FieldSet title={t(ETLCodes.Centres) + " - " + t(ETLCodes.TableResults, { count: totalCount })}>
        <DataTable
          dateFormat="dd/MM/yyyy"
          tableState={tableState}
          loading={loading}
          columns={columns}
          filterMode="OnEnter"
        ></DataTable>
      </FieldSet>
    </>
  );
};
