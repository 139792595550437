import { Button, Divider } from "@blueprintjs/core";
import { FormikProps } from "formik";
import { ButtonContainer, FGCustomPanel, FGMaskInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router-dom";

import { ApprenantApi, FcbApprenantDto, FcbApprenantDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterCheckboxInput, FGWalterSelectInput, SmallFormGenerator } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";
import { AcquisScolaireTable } from "./AcquisScolaireTable";
import { EcoleSelectModal } from "./EcoleSelectModal";
import { ExamenEntreeTable } from "./ExamenEntreeTable";

interface IApprenantAcquisDetailProps {}

export const ApprenantAcquisDetail: React.FunctionComponent<IApprenantAcquisDetailProps> = () => {
  const { t } = useTl();
  const api = useApiService(ApprenantApi);
  const { id } = useParams<{ id: string }>();
  const apprenantId = React.useMemo(() => +id, [id]);

  const [editMode, setEditMode] = React.useState(false);

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +apprenantId <= 0
        ? FcbApprenantDtoFromJSON({ idApprenant: 0 })
        : api.apprenantGet({ id: +apprenantId, picture: false }),
    saveApiFn: d => api.apprenantSave({ FcbApprenantDto: d }),
    deleteApiFn: d => api.apprenantDeleteAcquis({ id: d.idapprenant }),
    onDeletedRoute: () => `${ERoutes.apprenant}/${apprenantId}/acquis`
  });

  const [ecoles, eLoading] = useReferential(a => a.referentialGetEcole(), true, []);
  const [anneesReussies, arLoading] = useReferential(a => a.referentialGetAnneeReussie(), true, []);
  const [diplomes, dLoading] = useReferential(a => a.referentialGetDiplome(), true, []);

  const formikRef = React.useRef<FormikProps<FcbApprenantDto>>();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const onClose = React.useCallback((selected: number) => {
    if (selected) {
      formikRef.current?.setFieldValue("idecole", selected);
    }
    setShowModal(false);
  }, []);

  const EcoleModal = React.useCallback(
    () => (
      <EcoleSelectModal isOpen={showModal} onClose={onClose} alreadySelectedId={+formikRef.current?.values?.idecole} />
    ),
    [onClose, showModal, formikRef]
  );

  return (
    <>
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={editMode}
        onEdit={() => setEditMode(true)}
        saving={saving}
        loading={loading}
        deleting={deleting}
        onDelete={deleteItem}
        showDeleteButton
        cancelEdit
        formikInnerRef={i => (formikRef.current = i)}
      >
        <FieldGroup columns={[11, 1]}>
          <FGWalterSelectInput label={t(ETLCodes.Ecole)} name="idecole" items={ecoles} loading={eLoading} />
          <FGCustomPanel>
            {() => (
              <ButtonContainer>
                <Button
                  intent="primary"
                  icon="new-person"
                  text={t(ETLCodes.SelectionSchool)}
                  onClick={() => setShowModal(true)}
                  title={t(ETLCodes.SelectionSchool)}
                  loading={saving}
                  disabled={!editMode}
                />
              </ButtonContainer>
            )}
          </FGCustomPanel>
        </FieldGroup>
        <FieldGroup columns={2}>
          <FGWalterSelectInput
            label={t(ETLCodes.DernierDiplomeObtenu)}
            name="iddiplome"
            items={diplomes}
            loading={dLoading}
          />
          <FGMaskInput
            label={t(ETLCodes.AnneeScolaire)}
            name="anneeScolaireReussie"
            cleaveOptions={{ numericOnly: true, blocks: [4] }}
          />
          <FGWalterCheckboxInput label={t(ETLCodes.DocumentC2)} name="c2" />
          <FGWalterSelectInput
            label={t(ETLCodes.DerniereAnneeReussie)}
            name="idanneeReussie"
            items={anneesReussies}
            loading={arLoading}
          />
        </FieldGroup>
      </SmallFormGenerator>
      <Divider />
      <ExamenEntreeTable />
      <Divider />
      <AcquisScolaireTable />
      <Divider />
      <FGCustomPanel>{() => (!(saving || loading) ? <EcoleModal /> : null)}</FGCustomPanel>
    </>
  );
};
