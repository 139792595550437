/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbTypeCoursDto,
  FcbTypeCoursGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
  TypeCoursSearch,
  ValidationError,
} from '../models/index';
import {
    FcbTypeCoursDtoFromJSON,
    FcbTypeCoursDtoToJSON,
    FcbTypeCoursGridDtoPaginatedResultsFromJSON,
    FcbTypeCoursGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    TypeCoursSearchFromJSON,
    TypeCoursSearchToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface TypeCoursBaseSearchRequest {
    TypeCoursSearch?: TypeCoursSearch;
}

export interface TypeCoursCodeExistsRequest {
    code?: string;
}

export interface TypeCoursDeleteRequest {
    code?: string;
}

export interface TypeCoursGetRequest {
    code?: string;
}

export interface TypeCoursGetDisplayNameRequest {
    id?: string;
}

export interface TypeCoursGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface TypeCoursGetSelectItemsRequest {
    searchField: string;
}

export interface TypeCoursLibelleExistsRequest {
    code?: string;
    libelle?: string;
}

export interface TypeCoursSaveRequest {
    FcbTypeCoursDto?: FcbTypeCoursDto;
}

/**
 * 
 */
export class TypeCoursApi extends runtime.BaseAPI {

    /**
     */
    async typeCoursBaseSearchRaw(requestParameters: TypeCoursBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbTypeCoursGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeCours/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TypeCoursSearchToJSON(requestParameters.TypeCoursSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbTypeCoursGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async typeCoursBaseSearch(requestParameters: TypeCoursBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbTypeCoursGridDtoPaginatedResults> {
        const response = await this.typeCoursBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeCoursCodeExistsRaw(requestParameters: TypeCoursCodeExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeCours/codeExists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async typeCoursCodeExists(requestParameters: TypeCoursCodeExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.typeCoursCodeExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeCoursDeleteRaw(requestParameters: TypeCoursDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeCours`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async typeCoursDelete(requestParameters: TypeCoursDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.typeCoursDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeCoursGetRaw(requestParameters: TypeCoursGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbTypeCoursDto>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeCours`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbTypeCoursDtoFromJSON(jsonValue));
    }

    /**
     */
    async typeCoursGet(requestParameters: TypeCoursGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbTypeCoursDto> {
        const response = await this.typeCoursGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeCoursGetDisplayNameRaw(requestParameters: TypeCoursGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeCours/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async typeCoursGetDisplayName(requestParameters: TypeCoursGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.typeCoursGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeCoursGetSearchCriteriasRaw(requestParameters: TypeCoursGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeCours/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async typeCoursGetSearchCriterias(requestParameters: TypeCoursGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.typeCoursGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeCoursGetSelectItemsRaw(requestParameters: TypeCoursGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling typeCoursGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeCours/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async typeCoursGetSelectItems(requestParameters: TypeCoursGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.typeCoursGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeCoursLibelleExistsRaw(requestParameters: TypeCoursLibelleExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.libelle !== undefined) {
            queryParameters['libelle'] = requestParameters.libelle;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeCours/libelleExists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async typeCoursLibelleExists(requestParameters: TypeCoursLibelleExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.typeCoursLibelleExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async typeCoursSaveRaw(requestParameters: TypeCoursSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbTypeCoursDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/TypeCours`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbTypeCoursDtoToJSON(requestParameters.FcbTypeCoursDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbTypeCoursDtoFromJSON(jsonValue));
    }

    /**
     */
    async typeCoursSave(requestParameters: TypeCoursSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbTypeCoursDto> {
        const response = await this.typeCoursSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
