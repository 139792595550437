import * as React from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router";

import { AcquisAdmissionGlobal } from "./acquisAdmission/AcquisAdmissionGlobal";
import { BilanCompetenceDetail } from "./acquisAdmission/BilanCompetenceDetail";
import { DossierAdmissionDetail } from "./acquisAdmission/DossierAdmissionDetail";
import { ExamenEntreeFceDetail } from "./acquisAdmission/ExamenEntreeFceDetail";
import { ExamenEntreeHopeDetail } from "./acquisAdmission/ExamenEntreeHopeDetail";
import { AcquisGlobal } from "./AcquisGlobal";
import { AcquisScolaireGlobal } from "./acquisScolaire/AcquisScolaireGlobal";
import { AcquisScolaireGlobalIfapme } from "./acquisScolaire/AcquisScolaireGlobalIfapme";
import { AnneeFormationSuivieDetail } from "./acquisScolaire/AnneeFormationSuivieDetail";
import { AnneeFormationSuivieIfapmeDetail } from "./acquisScolaire/AnneeFormationSuivieIfapmeDetail";
import { DiplomeCertificationDetail } from "./acquisScolaire/DiplomeCertificationDetail";
import { DiplomeCertificationIfapmeDetail } from "./acquisScolaire/DiplomeCertificationIfapmeDetail";
import { ExperienceProfessionnelleDetail } from "./experienceProfessionnelle/ExperienceProfessionnelleDetail";

interface IAcquisSwitchProps {
  baseRoute: string;
  idpersonne: number;
}

export const AcquisSwitch: React.FunctionComponent<IAcquisSwitchProps> = ({ baseRoute, idpersonne }) => {
  const match = useRouteMatch();
  const { id, tab } = useParams<{
    id: string;
    tab: string;
    state: string;
    experienceProId: string;
    acquisId: string;
  }>();

  const listRoute = React.useMemo(() => `${baseRoute}/${id}/${tab}`, [baseRoute, id, tab]);

  return (
    !!idpersonne && (
      <Switch>
        <Route path={`${match.path}`} exact>
          <AcquisGlobal idpersonne={idpersonne} listRoute={listRoute} />
        </Route>
        <Route path={`${baseRoute}/:id/${tab}/experienceProfessionnelle/:experienceProId/:state`}>
          <ExperienceProfessionnelleDetail idpersonne={idpersonne} listRoute={listRoute} />
        </Route>
        <Route path={`${baseRoute}/:id/${tab}/acquisAdmission/:acquisId/:state`}>
          <AcquisAdmissionGlobal idpersonne={idpersonne} listRoute={listRoute} />
        </Route>
        <Route path={`${baseRoute}/:id/${tab}/examenEntree/:acquisId/:state`}>
          <ExamenEntreeHopeDetail idpersonne={idpersonne} listRoute={listRoute} />
        </Route>
        <Route path={`${baseRoute}/:id/${tab}/examenEntreeFce/:acquisId/:state`}>
          <ExamenEntreeFceDetail idpersonne={idpersonne} listRoute={listRoute} />
        </Route>
        <Route path={`${baseRoute}/:id/${tab}/dossierAdmission/:acquisId/:state`}>
          <DossierAdmissionDetail idpersonne={idpersonne} listRoute={listRoute} />
        </Route>
        <Route path={`${baseRoute}/:id/${tab}/bilanCompetence/:acquisId/:state`}>
          <BilanCompetenceDetail idpersonne={idpersonne} listRoute={listRoute} />
        </Route>

        <Route path={`${baseRoute}/:id/${tab}/acquisScolaire/:acquisId/:state`}>
          <AcquisScolaireGlobal idpersonne={idpersonne} listRoute={listRoute} />
        </Route>
        <Route path={`${baseRoute}/:id/${tab}/acquisScolaireIfapme/:acquisId/:state`}>
          <AcquisScolaireGlobalIfapme idpersonne={idpersonne} listRoute={listRoute} />
        </Route>
        <Route path={`${baseRoute}/:id/${tab}/anneeFormationSuivie/:acquisId/:state`}>
          <AnneeFormationSuivieDetail idpersonne={idpersonne} listRoute={listRoute} />
        </Route>
        <Route path={`${baseRoute}/:id/${tab}/anneeFormationSuivieIfapme/:acquisId/:state`}>
          <AnneeFormationSuivieIfapmeDetail idpersonne={idpersonne} listRoute={listRoute} />
        </Route>
        <Route path={`${baseRoute}/:id/${tab}/diplomeCertification/:acquisId/:state`}>
          <DiplomeCertificationDetail idpersonne={idpersonne} listRoute={listRoute} />
        </Route>
        <Route path={`${baseRoute}/:id/${tab}/diplomeCertificationIfapme/:acquisId/:state`}>
          <DiplomeCertificationIfapmeDetail idpersonne={idpersonne} listRoute={listRoute} />
        </Route>
      </Switch>
    )
  );
};
