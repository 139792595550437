/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  FcbReferentielDto,
  FcbReferentielGridDtoPaginatedResults,
  FilterCriteriaInfo,
  ReferentielSearch,
  SaveReferentielDto,
  SelectItem,
  ValidationError,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    FcbReferentielDtoFromJSON,
    FcbReferentielDtoToJSON,
    FcbReferentielGridDtoPaginatedResultsFromJSON,
    FcbReferentielGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentielSearchFromJSON,
    ReferentielSearchToJSON,
    SaveReferentielDtoFromJSON,
    SaveReferentielDtoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
    ValidationErrorFromJSON,
    ValidationErrorToJSON,
} from '../models/index';

export interface ReferentielBaseSearchRequest {
    ReferentielSearch?: ReferentielSearch;
}

export interface ReferentielDeleteRequest {
    id?: number;
}

export interface ReferentielExistsRequest {
    FcbReferentielDto?: FcbReferentielDto;
}

export interface ReferentielGetRequest {
    id?: number;
}

export interface ReferentielGetDisplayNameRequest {
    id?: number;
}

export interface ReferentielGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ReferentielGetSelectItemsRequest {
    searchField: string;
}

export interface ReferentielIsEditableRequest {
    id?: number;
}

export interface ReferentielSaveRequest {
    SaveReferentielDto?: SaveReferentielDto;
}

/**
 * 
 */
export class ReferentielApi extends runtime.BaseAPI {

    /**
     */
    async referentielBaseSearchRaw(requestParameters: ReferentielBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbReferentielGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referentiel/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReferentielSearchToJSON(requestParameters.ReferentielSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbReferentielGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async referentielBaseSearch(requestParameters: ReferentielBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbReferentielGridDtoPaginatedResults> {
        const response = await this.referentielBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentielDeleteRaw(requestParameters: ReferentielDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referentiel`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async referentielDelete(requestParameters: ReferentielDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.referentielDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentielExistsRaw(requestParameters: ReferentielExistsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidationError>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referentiel/Exists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            body: FcbReferentielDtoToJSON(requestParameters.FcbReferentielDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidationErrorFromJSON(jsonValue));
    }

    /**
     */
    async referentielExists(requestParameters: ReferentielExistsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidationError> {
        const response = await this.referentielExistsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentielGetRaw(requestParameters: ReferentielGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbReferentielDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referentiel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbReferentielDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentielGet(requestParameters: ReferentielGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbReferentielDto> {
        const response = await this.referentielGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentielGetDisplayNameRaw(requestParameters: ReferentielGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referentiel/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async referentielGetDisplayName(requestParameters: ReferentielGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.referentielGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentielGetSearchCriteriasRaw(requestParameters: ReferentielGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referentiel/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async referentielGetSearchCriterias(requestParameters: ReferentielGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.referentielGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentielGetSelectItemsRaw(requestParameters: ReferentielGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling referentielGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referentiel/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async referentielGetSelectItems(requestParameters: ReferentielGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.referentielGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentielIsEditableRaw(requestParameters: ReferentielIsEditableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referentiel/IsEditable`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentielIsEditable(requestParameters: ReferentielIsEditableRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.referentielIsEditableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async referentielSaveRaw(requestParameters: ReferentielSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbReferentielDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Referentiel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaveReferentielDtoToJSON(requestParameters.SaveReferentielDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbReferentielDtoFromJSON(jsonValue));
    }

    /**
     */
    async referentielSave(requestParameters: ReferentielSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbReferentielDto> {
        const response = await this.referentielSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
