/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApprenantRepresentantInstitutionEditDto
 */
export interface ApprenantRepresentantInstitutionEditDto {
    /**
     * 
     * @type {number}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    idapprenantInstitution?: number;
    /**
     * 
     * @type {number}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {number}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    idinstitution?: number;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    adresseInstitution?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    codePostalInstitution?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    localiteInstitution?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    communeInstitution?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    gsm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    telephone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    idrepresentant?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    isEmergencyContact?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    alreadyHasEmergencyContact?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    representantLegal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    courrier?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    idgsmPersonne?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    idtelephonePersonne?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    idemailPersonne?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    gsmInstitution?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    telephoneInstitution?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprenantRepresentantInstitutionEditDto
     */
    emailInstitution?: string | null;
}

/**
 * Check if a given object implements the ApprenantRepresentantInstitutionEditDto interface.
 */
export function instanceOfApprenantRepresentantInstitutionEditDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApprenantRepresentantInstitutionEditDtoFromJSON(json: any): ApprenantRepresentantInstitutionEditDto {
    return ApprenantRepresentantInstitutionEditDtoFromJSONTyped(json, false);
}

export function ApprenantRepresentantInstitutionEditDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApprenantRepresentantInstitutionEditDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idapprenantInstitution': !exists(json, 'idapprenantInstitution') ? undefined : json['idapprenantInstitution'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idinstitution': !exists(json, 'idinstitution') ? undefined : json['idinstitution'],
        'adresseInstitution': !exists(json, 'adresseInstitution') ? undefined : json['adresseInstitution'],
        'codePostalInstitution': !exists(json, 'codePostalInstitution') ? undefined : json['codePostalInstitution'],
        'localiteInstitution': !exists(json, 'localiteInstitution') ? undefined : json['localiteInstitution'],
        'communeInstitution': !exists(json, 'communeInstitution') ? undefined : json['communeInstitution'],
        'gsm': !exists(json, 'gsm') ? undefined : json['gsm'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'idrepresentant': !exists(json, 'idrepresentant') ? undefined : json['idrepresentant'],
        'isEmergencyContact': !exists(json, 'isEmergencyContact') ? undefined : json['isEmergencyContact'],
        'alreadyHasEmergencyContact': !exists(json, 'alreadyHasEmergencyContact') ? undefined : json['alreadyHasEmergencyContact'],
        'representantLegal': !exists(json, 'representantLegal') ? undefined : json['representantLegal'],
        'courrier': !exists(json, 'courrier') ? undefined : json['courrier'],
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'idgsmPersonne': !exists(json, 'idgsmPersonne') ? undefined : json['idgsmPersonne'],
        'idtelephonePersonne': !exists(json, 'idtelephonePersonne') ? undefined : json['idtelephonePersonne'],
        'idemailPersonne': !exists(json, 'idemailPersonne') ? undefined : json['idemailPersonne'],
        'gsmInstitution': !exists(json, 'gsmInstitution') ? undefined : json['gsmInstitution'],
        'telephoneInstitution': !exists(json, 'telephoneInstitution') ? undefined : json['telephoneInstitution'],
        'emailInstitution': !exists(json, 'emailInstitution') ? undefined : json['emailInstitution'],
    };
}

export function ApprenantRepresentantInstitutionEditDtoToJSON(value?: ApprenantRepresentantInstitutionEditDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idapprenantInstitution': value.idapprenantInstitution,
        'idapprenant': value.idapprenant,
        'idinstitution': value.idinstitution,
        'adresseInstitution': value.adresseInstitution,
        'codePostalInstitution': value.codePostalInstitution,
        'localiteInstitution': value.localiteInstitution,
        'communeInstitution': value.communeInstitution,
        'gsm': value.gsm,
        'telephone': value.telephone,
        'email': value.email,
        'idrepresentant': value.idrepresentant,
        'isEmergencyContact': value.isEmergencyContact,
        'alreadyHasEmergencyContact': value.alreadyHasEmergencyContact,
        'representantLegal': value.representantLegal,
        'courrier': value.courrier,
        'remarque': value.remarque,
        'type': value.type,
        'idgsmPersonne': value.idgsmPersonne,
        'idtelephonePersonne': value.idtelephonePersonne,
        'idemailPersonne': value.idemailPersonne,
        'gsmInstitution': value.gsmInstitution,
        'telephoneInstitution': value.telephoneInstitution,
        'emailInstitution': value.emailInstitution,
    };
}

