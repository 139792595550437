/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  ChangeStatutMultipleDto,
  DashboardAbsenceDto,
  DashboardAbsenceDtoPaginatedResults,
  DashboardAbsenceSearch,
  DashboardAbsencesVuReturnDto,
  DashboardDemandeFormationGridDtoPaginatedResults,
  DashboardDemandeFormationSearch,
  DashboardDossierDto,
  DashboardDossierDtoPaginatedResults,
  DashboardDossierSearch,
  DashboardInscriptionGridDtoPaginatedResults,
  DashboardInscriptionSearch,
  DashboardPratiqueProDtoPaginatedResults,
  DashboardPratiqueProSearch,
  DashboardSuiviSearch,
  DashboardSuiviSiegeLieuSearch,
  DemandeInscriptionInvalidationDto,
  EDashboard,
  EIfapmeSide,
  FilterCriteriaInfo,
  SuiviGridDtoPaginatedResults,
  SuiviSiegeLieuGridDtoPaginatedResults,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    ChangeStatutMultipleDtoFromJSON,
    ChangeStatutMultipleDtoToJSON,
    DashboardAbsenceDtoFromJSON,
    DashboardAbsenceDtoToJSON,
    DashboardAbsenceDtoPaginatedResultsFromJSON,
    DashboardAbsenceDtoPaginatedResultsToJSON,
    DashboardAbsenceSearchFromJSON,
    DashboardAbsenceSearchToJSON,
    DashboardAbsencesVuReturnDtoFromJSON,
    DashboardAbsencesVuReturnDtoToJSON,
    DashboardDemandeFormationGridDtoPaginatedResultsFromJSON,
    DashboardDemandeFormationGridDtoPaginatedResultsToJSON,
    DashboardDemandeFormationSearchFromJSON,
    DashboardDemandeFormationSearchToJSON,
    DashboardDossierDtoFromJSON,
    DashboardDossierDtoToJSON,
    DashboardDossierDtoPaginatedResultsFromJSON,
    DashboardDossierDtoPaginatedResultsToJSON,
    DashboardDossierSearchFromJSON,
    DashboardDossierSearchToJSON,
    DashboardInscriptionGridDtoPaginatedResultsFromJSON,
    DashboardInscriptionGridDtoPaginatedResultsToJSON,
    DashboardInscriptionSearchFromJSON,
    DashboardInscriptionSearchToJSON,
    DashboardPratiqueProDtoPaginatedResultsFromJSON,
    DashboardPratiqueProDtoPaginatedResultsToJSON,
    DashboardPratiqueProSearchFromJSON,
    DashboardPratiqueProSearchToJSON,
    DashboardSuiviSearchFromJSON,
    DashboardSuiviSearchToJSON,
    DashboardSuiviSiegeLieuSearchFromJSON,
    DashboardSuiviSiegeLieuSearchToJSON,
    DemandeInscriptionInvalidationDtoFromJSON,
    DemandeInscriptionInvalidationDtoToJSON,
    EDashboardFromJSON,
    EDashboardToJSON,
    EIfapmeSideFromJSON,
    EIfapmeSideToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SuiviGridDtoPaginatedResultsFromJSON,
    SuiviGridDtoPaginatedResultsToJSON,
    SuiviSiegeLieuGridDtoPaginatedResultsFromJSON,
    SuiviSiegeLieuGridDtoPaginatedResultsToJSON,
} from '../models/index';

export interface DashboardChangeStatutMultipleDemandeFormationCreaRequest {
    ChangeStatutMultipleDto?: ChangeStatutMultipleDto;
}

export interface DashboardGetCountsRequest {
    ifapmeSide?: EIfapmeSide;
    dashboard?: EDashboard;
}

export interface DashboardInvaliderDemandeFormationsRequest {
    DemandeInscriptionInvalidationDto?: DemandeInscriptionInvalidationDto;
}

export interface DashboardInvaliderDemandeInscriptionRequest {
    DemandeInscriptionInvalidationDto?: DemandeInscriptionInvalidationDto;
}

export interface DashboardMarquerAbsenceVueRequest {
    DashboardAbsenceDto?: DashboardAbsenceDto;
}

export interface DashboardMarquerSortieApprenantRequest {
    DashboardDossierDto?: DashboardDossierDto;
}

export interface DashboardMarquerSuiviFaitRequest {
    idsuivi?: number;
}

export interface DashboardMarquerSuiviSiegeLieuFaitRequest {
    idsuiviSiegeLieu?: number;
}

export interface DashboardMasquerAbsenceRequest {
    idapprenant?: number;
    anneeScolaire?: string;
}

export interface DashboardMasquerDemandeFormationsRequest {
    iddemande?: number;
}

export interface DashboardMasquerDemandeInscriptionsRequest {
    iddemande?: number;
}

export interface DashboardMasquerDossierAgrementRequest {
    idlieuFormationAgrement?: number;
}

export interface DashboardMasquerPratiqueProRequest {
    idcontrat?: number;
    statut?: string;
}

export interface DashboardMasquerSortieRequest {
    idstatutApprenant?: number;
}

export interface DashboardMasquerStatutApprenantRequest {
    idstatutApprenant?: number;
}

export interface DashboardMasquerSuiviRequest {
    idsuivi?: number;
}

export interface DashboardMasquerSuiviSiegeLieuRequest {
    idsuiviSiegeLieu?: number;
}

export interface DashboardRefuserSortieApprenantRequest {
    DashboardDossierDto?: DashboardDossierDto;
}

export interface DashboardSearchAbsencesRequest {
    DashboardAbsenceSearch?: DashboardAbsenceSearch;
}

export interface DashboardSearchDemandeFormationsRequest {
    DashboardDemandeFormationSearch?: DashboardDemandeFormationSearch;
}

export interface DashboardSearchDossiersFinAgrementRequest {
    DashboardDossierSearch?: DashboardDossierSearch;
}

export interface DashboardSearchDossiersPostFormationRequest {
    DashboardDossierSearch?: DashboardDossierSearch;
}

export interface DashboardSearchDossiersSortieRequest {
    DashboardDossierSearch?: DashboardDossierSearch;
}

export interface DashboardSearchInscriptionsRequest {
    DashboardInscriptionSearch?: DashboardInscriptionSearch;
}

export interface DashboardSearchPratiqueProRequest {
    DashboardPratiqueProSearch?: DashboardPratiqueProSearch;
}

export interface DashboardSearchSuivisRequest {
    DashboardSuiviSearch?: DashboardSuiviSearch;
}

export interface DashboardSearchSuivisSiegeLieuRequest {
    DashboardSuiviSiegeLieuSearch?: DashboardSuiviSiegeLieuSearch;
}

/**
 * 
 */
export class DashboardApi extends runtime.BaseAPI {

    /**
     */
    async dashboardChangeStatutMultipleDemandeFormationCreaRaw(requestParameters: DashboardChangeStatutMultipleDemandeFormationCreaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/ChangeStatutMultipleDemandeFormationCrea`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeStatutMultipleDtoToJSON(requestParameters.ChangeStatutMultipleDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async dashboardChangeStatutMultipleDemandeFormationCrea(requestParameters: DashboardChangeStatutMultipleDemandeFormationCreaRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.dashboardChangeStatutMultipleDemandeFormationCreaRaw(requestParameters, initOverrides);
    }

    /**
     */
    async dashboardGetAbsencesCriteriasRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/GetAbsencesCriterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async dashboardGetAbsencesCriterias(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.dashboardGetAbsencesCriteriasRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardGetCountsRaw(requestParameters: DashboardGetCountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.ifapmeSide !== undefined) {
            queryParameters['ifapmeSide'] = requestParameters.ifapmeSide;
        }

        if (requestParameters.dashboard !== undefined) {
            queryParameters['dashboard'] = requestParameters.dashboard;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/GetCounts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async dashboardGetCounts(requestParameters: DashboardGetCountsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.dashboardGetCountsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardGetDemandeFormationCriteriasRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/GetDemandeFormationCriterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async dashboardGetDemandeFormationCriterias(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.dashboardGetDemandeFormationCriteriasRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardGetDossierCriteriasRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/GetDossierCriterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async dashboardGetDossierCriterias(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.dashboardGetDossierCriteriasRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardGetInscriptionCriteriasRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/GetInscriptionCriterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async dashboardGetInscriptionCriterias(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.dashboardGetInscriptionCriteriasRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardGetPratiqueProCriteriasRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/GetPratiqueProCriterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async dashboardGetPratiqueProCriterias(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.dashboardGetPratiqueProCriteriasRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardGetSuiviSiegeLieuCriteriasRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/GetSuiviSiegeLieuCriterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async dashboardGetSuiviSiegeLieuCriterias(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.dashboardGetSuiviSiegeLieuCriteriasRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardGetSuivisCriteriasRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/GetSuivisCriterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async dashboardGetSuivisCriterias(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.dashboardGetSuivisCriteriasRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardInvaliderDemandeFormationsRaw(requestParameters: DashboardInvaliderDemandeFormationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/InvaliderDemandeFormations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DemandeInscriptionInvalidationDtoToJSON(requestParameters.DemandeInscriptionInvalidationDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardInvaliderDemandeFormations(requestParameters: DashboardInvaliderDemandeFormationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.dashboardInvaliderDemandeFormationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardInvaliderDemandeInscriptionRaw(requestParameters: DashboardInvaliderDemandeInscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/InvaliderDemandeInscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DemandeInscriptionInvalidationDtoToJSON(requestParameters.DemandeInscriptionInvalidationDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardInvaliderDemandeInscription(requestParameters: DashboardInvaliderDemandeInscriptionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.dashboardInvaliderDemandeInscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardMarquerAbsenceVueRaw(requestParameters: DashboardMarquerAbsenceVueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardAbsencesVuReturnDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/MarquerAbsenceVue`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardAbsenceDtoToJSON(requestParameters.DashboardAbsenceDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardAbsencesVuReturnDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardMarquerAbsenceVue(requestParameters: DashboardMarquerAbsenceVueRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardAbsencesVuReturnDto> {
        const response = await this.dashboardMarquerAbsenceVueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardMarquerSortieApprenantRaw(requestParameters: DashboardMarquerSortieApprenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/MarquerSortieApprenant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardDossierDtoToJSON(requestParameters.DashboardDossierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardMarquerSortieApprenant(requestParameters: DashboardMarquerSortieApprenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.dashboardMarquerSortieApprenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardMarquerSuiviFaitRaw(requestParameters: DashboardMarquerSuiviFaitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idsuivi !== undefined) {
            queryParameters['idsuivi'] = requestParameters.idsuivi;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/MarquerSuiviFait`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardMarquerSuiviFait(requestParameters: DashboardMarquerSuiviFaitRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.dashboardMarquerSuiviFaitRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardMarquerSuiviSiegeLieuFaitRaw(requestParameters: DashboardMarquerSuiviSiegeLieuFaitRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idsuiviSiegeLieu !== undefined) {
            queryParameters['idsuiviSiegeLieu'] = requestParameters.idsuiviSiegeLieu;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/MarquerSuiviSiegeLieuFait`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardMarquerSuiviSiegeLieuFait(requestParameters: DashboardMarquerSuiviSiegeLieuFaitRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.dashboardMarquerSuiviSiegeLieuFaitRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardMasquerAbsenceRaw(requestParameters: DashboardMasquerAbsenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idapprenant !== undefined) {
            queryParameters['idapprenant'] = requestParameters.idapprenant;
        }

        if (requestParameters.anneeScolaire !== undefined) {
            queryParameters['anneeScolaire'] = requestParameters.anneeScolaire;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/MasquerAbsence`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardMasquerAbsence(requestParameters: DashboardMasquerAbsenceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.dashboardMasquerAbsenceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardMasquerDemandeFormationsRaw(requestParameters: DashboardMasquerDemandeFormationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.iddemande !== undefined) {
            queryParameters['iddemande'] = requestParameters.iddemande;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/MasquerDemandeFormations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardMasquerDemandeFormations(requestParameters: DashboardMasquerDemandeFormationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.dashboardMasquerDemandeFormationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardMasquerDemandeInscriptionsRaw(requestParameters: DashboardMasquerDemandeInscriptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.iddemande !== undefined) {
            queryParameters['iddemande'] = requestParameters.iddemande;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/MasquerDemandeInscriptions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardMasquerDemandeInscriptions(requestParameters: DashboardMasquerDemandeInscriptionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.dashboardMasquerDemandeInscriptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardMasquerDossierAgrementRaw(requestParameters: DashboardMasquerDossierAgrementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idlieuFormationAgrement !== undefined) {
            queryParameters['idlieuFormationAgrement'] = requestParameters.idlieuFormationAgrement;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/MasquerDossierAgrement`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardMasquerDossierAgrement(requestParameters: DashboardMasquerDossierAgrementRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.dashboardMasquerDossierAgrementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardMasquerPratiqueProRaw(requestParameters: DashboardMasquerPratiqueProRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idcontrat !== undefined) {
            queryParameters['idcontrat'] = requestParameters.idcontrat;
        }

        if (requestParameters.statut !== undefined) {
            queryParameters['statut'] = requestParameters.statut;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/MasquerPratiquePro`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardMasquerPratiquePro(requestParameters: DashboardMasquerPratiqueProRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.dashboardMasquerPratiqueProRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardMasquerSortieRaw(requestParameters: DashboardMasquerSortieRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idstatutApprenant !== undefined) {
            queryParameters['idstatutApprenant'] = requestParameters.idstatutApprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/MasquerSortie`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardMasquerSortie(requestParameters: DashboardMasquerSortieRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.dashboardMasquerSortieRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardMasquerStatutApprenantRaw(requestParameters: DashboardMasquerStatutApprenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idstatutApprenant !== undefined) {
            queryParameters['idstatutApprenant'] = requestParameters.idstatutApprenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/MasquerStatutApprenant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardMasquerStatutApprenant(requestParameters: DashboardMasquerStatutApprenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.dashboardMasquerStatutApprenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardMasquerSuiviRaw(requestParameters: DashboardMasquerSuiviRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idsuivi !== undefined) {
            queryParameters['idsuivi'] = requestParameters.idsuivi;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/MasquerSuivi`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardMasquerSuivi(requestParameters: DashboardMasquerSuiviRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.dashboardMasquerSuiviRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardMasquerSuiviSiegeLieuRaw(requestParameters: DashboardMasquerSuiviSiegeLieuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        if (requestParameters.idsuiviSiegeLieu !== undefined) {
            queryParameters['idsuiviSiegeLieu'] = requestParameters.idsuiviSiegeLieu;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/MasquerSuiviSiegeLieu`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardMasquerSuiviSiegeLieu(requestParameters: DashboardMasquerSuiviSiegeLieuRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.dashboardMasquerSuiviSiegeLieuRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardRefuserSortieApprenantRaw(requestParameters: DashboardRefuserSortieApprenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/RefuserSortieApprenant`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardDossierDtoToJSON(requestParameters.DashboardDossierDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async dashboardRefuserSortieApprenant(requestParameters: DashboardRefuserSortieApprenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.dashboardRefuserSortieApprenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardSearchAbsencesRaw(requestParameters: DashboardSearchAbsencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardAbsenceDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/SearchAbsences`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardAbsenceSearchToJSON(requestParameters.DashboardAbsenceSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardAbsenceDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async dashboardSearchAbsences(requestParameters: DashboardSearchAbsencesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardAbsenceDtoPaginatedResults> {
        const response = await this.dashboardSearchAbsencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardSearchDemandeFormationsRaw(requestParameters: DashboardSearchDemandeFormationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardDemandeFormationGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/SearchDemandeFormations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardDemandeFormationSearchToJSON(requestParameters.DashboardDemandeFormationSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardDemandeFormationGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async dashboardSearchDemandeFormations(requestParameters: DashboardSearchDemandeFormationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardDemandeFormationGridDtoPaginatedResults> {
        const response = await this.dashboardSearchDemandeFormationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardSearchDossiersFinAgrementRaw(requestParameters: DashboardSearchDossiersFinAgrementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardDossierDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/SearchDossiersFinAgrement`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardDossierSearchToJSON(requestParameters.DashboardDossierSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardDossierDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async dashboardSearchDossiersFinAgrement(requestParameters: DashboardSearchDossiersFinAgrementRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardDossierDtoPaginatedResults> {
        const response = await this.dashboardSearchDossiersFinAgrementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardSearchDossiersPostFormationRaw(requestParameters: DashboardSearchDossiersPostFormationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardDossierDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/SearchDossiersPostFormation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardDossierSearchToJSON(requestParameters.DashboardDossierSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardDossierDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async dashboardSearchDossiersPostFormation(requestParameters: DashboardSearchDossiersPostFormationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardDossierDtoPaginatedResults> {
        const response = await this.dashboardSearchDossiersPostFormationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardSearchDossiersSortieRaw(requestParameters: DashboardSearchDossiersSortieRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardDossierDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/SearchDossiersSortie`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardDossierSearchToJSON(requestParameters.DashboardDossierSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardDossierDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async dashboardSearchDossiersSortie(requestParameters: DashboardSearchDossiersSortieRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardDossierDtoPaginatedResults> {
        const response = await this.dashboardSearchDossiersSortieRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardSearchInscriptionsRaw(requestParameters: DashboardSearchInscriptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardInscriptionGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/SearchInscriptions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardInscriptionSearchToJSON(requestParameters.DashboardInscriptionSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardInscriptionGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async dashboardSearchInscriptions(requestParameters: DashboardSearchInscriptionsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardInscriptionGridDtoPaginatedResults> {
        const response = await this.dashboardSearchInscriptionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardSearchPratiqueProRaw(requestParameters: DashboardSearchPratiqueProRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardPratiqueProDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/SearchPratiquePro`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardPratiqueProSearchToJSON(requestParameters.DashboardPratiqueProSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardPratiqueProDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async dashboardSearchPratiquePro(requestParameters: DashboardSearchPratiqueProRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardPratiqueProDtoPaginatedResults> {
        const response = await this.dashboardSearchPratiqueProRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardSearchSuivisRaw(requestParameters: DashboardSearchSuivisRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuiviGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/SearchSuivis`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardSuiviSearchToJSON(requestParameters.DashboardSuiviSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuiviGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async dashboardSearchSuivis(requestParameters: DashboardSearchSuivisRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuiviGridDtoPaginatedResults> {
        const response = await this.dashboardSearchSuivisRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dashboardSearchSuivisSiegeLieuRaw(requestParameters: DashboardSearchSuivisSiegeLieuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuiviSiegeLieuGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Dashboard/SearchSuivisSiegeLieu`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardSuiviSiegeLieuSearchToJSON(requestParameters.DashboardSuiviSiegeLieuSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuiviSiegeLieuGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async dashboardSearchSuivisSiegeLieu(requestParameters: DashboardSearchSuivisSiegeLieuRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuiviSiegeLieuGridDtoPaginatedResults> {
        const response = await this.dashboardSearchSuivisSiegeLieuRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
