import { Tab, Tabs } from "@blueprintjs/core";
import { IDataTableColumn } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";

import { CalendrierGlobalPage } from "..";
import { CalendrierApi, CalendrierSearch, FcbCalendrierGridDto } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { EditButton, PageBase, SearchTablePage, ViewButton } from "../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface ICalendrierListPageProps {}
const Container = styled.div`
  display: flex;
`;
export const CalendrierListPage: React.FunctionComponent<ICalendrierListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(CalendrierApi);
  const { tab } = useParams<{ tab: string }>();

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbCalendrierGridDto) => (
          <Container>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.calendrier}/${row.idcalendrier}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.calendrier}/${row.idcalendrier}/detail/edit`)}
            />
          </Container>
        )
      },
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "libelle"
      },
      {
        header: () => t(ETLCodes.DateDebut),
        fieldName: "dateDebut"
      },
      {
        header: () => t(ETLCodes.DateFin),
        fieldName: "dateFin"
      }
    ],
    [history, t]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.calendrier}/0/detail/edit`);
  }, [history]);

  const getSearchCriterias = React.useCallback(() => api.calendrierGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const apiFactory = useAbortableApiServiceFactory(CalendrierApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFunction = React.useCallback(
    (nextSearch?: CalendrierSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.calendrierBaseSearch({ CalendrierSearch: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <PageBase breadCrumbs={[{ text: t(ETLCodes.Calendriers) }]}>
      <Tabs
        id="TabsCalendrierSearch"
        onChange={newTabId => history.push(`${ERoutes.calendrier}/${newTabId}`)}
        selectedTabId={tab ?? "global"}
        renderActiveTabPanelOnly
      >
        <Tab id="global" title={t(ETLCodes.Global)} panel={<CalendrierGlobalPage />} />
        <Tab
          id="search"
          title={t(ETLCodes.CalendriersSearch)}
          panel={
            <SearchTablePage
              columns={columns}
              getCriteriasFunction={getSearchCriterias}
              searchFunction={searchFunction}
              onAbort={onAbort}
              addFunc={addItemFunction}
              sortKeys={{ dateDebut: "DESC" }}
              breadCrumbs={[{ text: t(ETLCodes.Calendriers) }]}
            />
          }
        />
      </Tabs>
    </PageBase>
  );
};
