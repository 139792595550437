import { Intent } from "@blueprintjs/core";
import { DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { UsersCentresLiesApi, UsersCentresLiesSearch } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IUtilisateurCentresLiesPageProps {}

export const UtilisateurCentresLiesPage: React.FunctionComponent<IUtilisateurCentresLiesPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(UsersCentresLiesApi);
  const { id, state } = useParams<{ id: string; tab: string; state: string }>();

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: true,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: { nom: "ASC" }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: UsersCentresLiesSearch) => {
      sObj.idUser = +id;
      return api.usersCentresLiesBaseSearch({ UsersCentresLiesSearch: sObj });
    },
    [api, id]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      }
    ],
    [t]
  );

  return (
    <FieldSet
      title={t(ETLCodes.TableResults, { count: totalCount })}
      rightElement={
        <EditButton
          onClick={() => history.push(`${ERoutes.users}/${id}/centres/${state}/selector`)}
          intent={Intent.PRIMARY}
          text={t(ETLCodes.Modify)}
        />
      }
    >
      <DataTable
        dateFormat="dd-MM-yyyy"
        tableState={tableState}
        loading={loading}
        columns={columns}
        filterMode="OnEnter"
      />
    </FieldSet>
  );
};
