import { DatePicker } from "@blueprintjs/datetime";
import { FGListen } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { CalendrierGlobalSearch } from "../../../../api";
import { FGWalterSelectInput, SmallFormGenerator } from "../../../../components";
import { useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";
import { ETLCodes } from "../../../../locales";
import { CalendrierGlobalClassesSearch } from "./CalendrierGlobalClassesSearch";
import { CalendrierGlobalFormateursSearch } from "./CalendrierGlobalFormateursSearch";
import { CalendrierGlobalVisitesSearch } from "./CalendrierGlobalVisitesSearch";

const MONTHS = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre"
];

const WEEKDAYS_LONG = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];

const WEEKDAYS_SHORT = ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"];

const MainContainer = styled.div`
  display: flex;
`;

const LeftPanel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const RightPanel = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
`;

export interface ICalendrierGlobalPageProps {}

export const CalendrierGlobalPage: React.FunctionComponent<ICalendrierGlobalPageProps> = () => {
  const { t } = useTl();

  const [centres, cLoading] = useReferential(a => a.referentialGetCentre(), true, []);

  const [currentSearchObj, setCurrentSearchObj] = React.useState<CalendrierGlobalSearch>({
    jour: new Date(),
    idcentre: null
  });

  const onDaySelected = React.useCallback(
    (selectedDate: Date, isUserChange: boolean) => {
      if (isUserChange) {
        const timeOffsetInMS = selectedDate.getTimezoneOffset() * 60000;
        selectedDate.setTime(selectedDate.getTime() - timeOffsetInMS);
        setCurrentSearchObj({ ...currentSearchObj, jour: selectedDate });
      }
    },
    [currentSearchObj]
  );

  const maxDate = React.useMemo(() => {
    var d = new Date();
    d.setFullYear(new Date().getFullYear() + 1);
    d.setMonth(11);
    d.setDate(31);

    return d;
  }, []);

  return (
    <MainContainer>
      <LeftPanel>
        <DatePicker
          initialMonth={new Date()}
          dayPickerProps={{
            firstDayOfWeek: 1,
            showWeekNumbers: true,
            locale: "fr",
            months: MONTHS,
            weekdaysLong: WEEKDAYS_LONG,
            weekdaysShort: WEEKDAYS_SHORT
          }}
          onChange={onDaySelected}
          value={currentSearchObj.jour}
          maxDate={maxDate}
        />
      </LeftPanel>
      <RightPanel>
        <SmallFormGenerator
          initialValues={{}}
          onSubmit={() => {}}
          editMode={true}
          hideButtons={true}
          enableDirtyCheck={false}
          canCheckRights={false}
        >
          <FGListen
            field="idcentre"
            onChanged={value => {
              setCurrentSearchObj({ ...currentSearchObj, idcentre: value });
            }}
          />
          <FGWalterSelectInput name="idcentre" label={t(ETLCodes.Centre)} loading={cLoading} items={centres} />
        </SmallFormGenerator>

        <CalendrierGlobalClassesSearch currentSObj={currentSearchObj}></CalendrierGlobalClassesSearch>
        <CalendrierGlobalFormateursSearch currentSObj={currentSearchObj}></CalendrierGlobalFormateursSearch>
        <CalendrierGlobalVisitesSearch currentSObj={currentSearchObj}></CalendrierGlobalVisitesSearch>
      </RightPanel>
    </MainContainer>
  );
};
