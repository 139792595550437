import { Button, ButtonGroup, Icon } from "@blueprintjs/core";
import { usePrevious } from "ahooks";
import { CollapsePanel, DataTable, IDataTableColumn, useGridState } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import {
  FcbInscriptionMasseGridDto,
  FcbInscriptionMasseGridDtoPaginatedResults,
  InscriptionMasseApi
} from "../../../../api";
import { UnordoredListColumn } from "../../../../components";
import { useAbortableApiServiceFactory, useSearchApi, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { InscriptionMasseAddModal } from "./InscriptionMasseAddModal";

const Container = styled.div``;

export interface InscriptionMasseTableProps {
  selected: number[];
  setSelected: (values: number[]) => void;
  idclasse?: number;
  idcentre?: number;
  codeMetier?: string;
  anneeScolaire?: string;
  saving?: boolean;
}

export const InscriptionMasseTable: React.FunctionComponent<InscriptionMasseTableProps> = ({
  selected,
  setSelected,
  idcentre,
  idclasse,
  codeMetier,
  anneeScolaire,
  saving
}) => {
  const { t } = useTl();
  const tableState = useGridState({ serverMode: true, sortKeys: { nom: "ASC" } });

  const apiFactory = useAbortableApiServiceFactory(InscriptionMasseApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(async () => {
    const { api: abortableApi, abortController } = apiFactory();
    lastAbortController.current = abortController;

    return idcentre && idclasse && anneeScolaire
      ? await abortableApi.inscriptionMasseBaseSearch({
          InscriptionMasseSearch: {
            idcentre,
            idclasseSelected: idclasse,
            selectedIds: selected
          }
        })
      : ({ totalCount: 0, aggregateResults: [], results: [] } as FcbInscriptionMasseGridDtoPaginatedResults);
  }, [anneeScolaire, apiFactory, idcentre, idclasse, selected]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);
  const { search, loading } = useSearchApi({
    searchFunction: searchFn,
    tableState,
    initialSearch: false,
    onAbort
  });

  const { data } = tableState;

  const registeredIds = React.useMemo<number[]>(() => {
    return data?.filter(v => !selected.includes(v.idapprenant)).map(v => v.idapprenant) ?? [];
  }, [data, selected]);

  const previousIdcentre = usePrevious(idcentre);
  const previousIdclasse = usePrevious(idclasse);
  const previousAnneeScolaire = usePrevious(anneeScolaire);
  React.useEffect(() => {
    if (idcentre !== previousIdcentre && idclasse !== previousIdclasse && anneeScolaire !== previousAnneeScolaire) {
      search();
    }
  }, [idcentre, idclasse, anneeScolaire, search, previousIdcentre, previousIdclasse, previousAnneeScolaire]);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        fieldName: "_action",
        autoFitContent: true,
        computed: true,

        alignment: "center",
        render: (item: FcbInscriptionMasseGridDto) => (
          <Icon
            icon={selected.includes(item.idapprenant) ? "tick-circle" : "circle"}
            style={{ color: "#6e6e6e" }}
          ></Icon>
        )
      },
      { fieldName: "idapprenant", header: () => t(ETLCodes.Identifiant) },
      {
        fieldName: "nom",
        header: () => t(ETLCodes.Nom)
      },
      {
        fieldName: "prenom",
        header: () => t(ETLCodes.Prenom)
      },
      {
        fieldName: "dateNaissance",
        header: () => t(ETLCodes.DateNaissance)
      },
      {
        fieldName: "codeMetier",
        header: () => t(ETLCodes.CodeMetier),
        render: (row: FcbInscriptionMasseGridDto) => (
          <UnordoredListColumn values={row.codesMetier.map(d => ({ label: d, value: true }))} />
        )
      },
      {
        fieldName: "statut",
        header: () => t(ETLCodes.Status),
        render: (row: FcbInscriptionMasseGridDto) => (
          <UnordoredListColumn values={row.statuts.map(d => ({ label: d, value: true }))} />
        )
      }
    ],
    [selected, t]
  );

  const [showModal, setShowModal] = React.useState<boolean>(false);
  const onAddApprenant = React.useCallback(() => {
    setShowModal(true);
  }, []);

  const onClose = React.useCallback(
    (saved: number[]) => {
      if (saved) {
        setSelected(saved);
        // Wait for the update to get dispatched
        setTimeout(() => {
          search();
        }, 0);
      }
      setShowModal(false);
    },
    [search, setSelected]
  );

  const addButton = React.useMemo<React.ReactElement>(() => {
    return idcentre && idclasse && codeMetier && anneeScolaire ? (
      <ButtonGroup>
        <Button
          intent="primary"
          icon="new-person"
          text={t(ETLCodes.SelectionApprenants)}
          onClick={onAddApprenant}
          title={t(ETLCodes.SelectionApprenants)}
          loading={saving}
          disabled={loading}
        />
      </ButtonGroup>
    ) : null;
  }, [idcentre, idclasse, codeMetier, anneeScolaire, t, onAddApprenant, saving, loading]);

  return (
    <Container>
      <CollapsePanel collapsable={false} title={t(ETLCodes.Apprenants)} rightElement={addButton}>
        <DataTable tableState={tableState} columns={columns} loading={loading} />
      </CollapsePanel>
      {showModal && (
        <InscriptionMasseAddModal
          isOpen={showModal}
          onClose={onClose}
          codeMetier={codeMetier}
          anneeScolaire={anneeScolaire}
          idcentre={idcentre}
          idclasse={idclasse}
          registeredIds={registeredIds}
          alreadySelected={selected}
        ></InscriptionMasseAddModal>
      )}
    </Container>
  );
};
