/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DisplayNameDto,
  FilterCriteriaInfo,
  InstitutionSelectorGridDtoPaginatedResults,
  InstitutionSelectorSearch,
  ReferentialItemDto,
} from '../models/index';
import {
    DisplayNameDtoFromJSON,
    DisplayNameDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    InstitutionSelectorGridDtoPaginatedResultsFromJSON,
    InstitutionSelectorGridDtoPaginatedResultsToJSON,
    InstitutionSelectorSearchFromJSON,
    InstitutionSelectorSearchToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface InstitutionSelectorBaseSearchRequest {
    InstitutionSelectorSearch?: InstitutionSelectorSearch;
}

export interface InstitutionSelectorGetDisplayNamesRequest {
    request_body?: Array<number>;
}

export interface InstitutionSelectorGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface InstitutionSelectorGetSelectItemsRequest {
    searchField: string;
}

/**
 * 
 */
export class InstitutionSelectorApi extends runtime.BaseAPI {

    /**
     */
    async institutionSelectorBaseSearchRaw(requestParameters: InstitutionSelectorBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstitutionSelectorGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/InstitutionSelector/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InstitutionSelectorSearchToJSON(requestParameters.InstitutionSelectorSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstitutionSelectorGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async institutionSelectorBaseSearch(requestParameters: InstitutionSelectorBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstitutionSelectorGridDtoPaginatedResults> {
        const response = await this.institutionSelectorBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async institutionSelectorGetDisplayNamesRaw(requestParameters: InstitutionSelectorGetDisplayNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DisplayNameDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/InstitutionSelector/GetDisplayNames`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request_body,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DisplayNameDtoFromJSON));
    }

    /**
     */
    async institutionSelectorGetDisplayNames(requestParameters: InstitutionSelectorGetDisplayNamesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DisplayNameDto>> {
        const response = await this.institutionSelectorGetDisplayNamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async institutionSelectorGetSearchCriteriasRaw(requestParameters: InstitutionSelectorGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/InstitutionSelector/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async institutionSelectorGetSearchCriterias(requestParameters: InstitutionSelectorGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.institutionSelectorGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async institutionSelectorGetSelectItemsRaw(requestParameters: InstitutionSelectorGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling institutionSelectorGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/InstitutionSelector/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async institutionSelectorGetSelectItems(requestParameters: InstitutionSelectorGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.institutionSelectorGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
