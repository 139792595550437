/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbForfaitAuditeurDto
 */
export interface FcbForfaitAuditeurDto {
    /**
     * 
     * @type {number}
     * @memberof FcbForfaitAuditeurDto
     */
    idForfait?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbForfaitAuditeurDto
     */
    anneeAcademique?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbForfaitAuditeurDto
     */
    aaS1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbForfaitAuditeurDto
     */
    aaS2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbForfaitAuditeurDto
     */
    abS1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbForfaitAuditeurDto
     */
    abS2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbForfaitAuditeurDto
     */
    aiS1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbForfaitAuditeurDto
     */
    aiS2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbForfaitAuditeurDto
     */
    ceaS1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbForfaitAuditeurDto
     */
    ceaS2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbForfaitAuditeurDto
     */
    cebS1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbForfaitAuditeurDto
     */
    cebS2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbForfaitAuditeurDto
     */
    ceiS1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbForfaitAuditeurDto
     */
    ceiS2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbForfaitAuditeurDto
     */
    fagS1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbForfaitAuditeurDto
     */
    fagS2?: number | null;
}

/**
 * Check if a given object implements the FcbForfaitAuditeurDto interface.
 */
export function instanceOfFcbForfaitAuditeurDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbForfaitAuditeurDtoFromJSON(json: any): FcbForfaitAuditeurDto {
    return FcbForfaitAuditeurDtoFromJSONTyped(json, false);
}

export function FcbForfaitAuditeurDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbForfaitAuditeurDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idForfait': !exists(json, 'idForfait') ? undefined : json['idForfait'],
        'anneeAcademique': !exists(json, 'anneeAcademique') ? undefined : json['anneeAcademique'],
        'aaS1': !exists(json, 'aaS1') ? undefined : json['aaS1'],
        'aaS2': !exists(json, 'aaS2') ? undefined : json['aaS2'],
        'abS1': !exists(json, 'abS1') ? undefined : json['abS1'],
        'abS2': !exists(json, 'abS2') ? undefined : json['abS2'],
        'aiS1': !exists(json, 'aiS1') ? undefined : json['aiS1'],
        'aiS2': !exists(json, 'aiS2') ? undefined : json['aiS2'],
        'ceaS1': !exists(json, 'ceaS1') ? undefined : json['ceaS1'],
        'ceaS2': !exists(json, 'ceaS2') ? undefined : json['ceaS2'],
        'cebS1': !exists(json, 'cebS1') ? undefined : json['cebS1'],
        'cebS2': !exists(json, 'cebS2') ? undefined : json['cebS2'],
        'ceiS1': !exists(json, 'ceiS1') ? undefined : json['ceiS1'],
        'ceiS2': !exists(json, 'ceiS2') ? undefined : json['ceiS2'],
        'fagS1': !exists(json, 'fagS1') ? undefined : json['fagS1'],
        'fagS2': !exists(json, 'fagS2') ? undefined : json['fagS2'],
    };
}

export function FcbForfaitAuditeurDtoToJSON(value?: FcbForfaitAuditeurDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idForfait': value.idForfait,
        'anneeAcademique': value.anneeAcademique,
        'aaS1': value.aaS1,
        'aaS2': value.aaS2,
        'abS1': value.abS1,
        'abS2': value.abS2,
        'aiS1': value.aiS1,
        'aiS2': value.aiS2,
        'ceaS1': value.ceaS1,
        'ceaS2': value.ceaS2,
        'cebS1': value.cebS1,
        'cebS2': value.cebS2,
        'ceiS1': value.ceiS1,
        'ceiS2': value.ceiS2,
        'fagS1': value.fagS1,
        'fagS2': value.fagS2,
    };
}

