/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregateKeyInfo } from './AggregateKeyInfo';
import {
    AggregateKeyInfoFromJSON,
    AggregateKeyInfoFromJSONTyped,
    AggregateKeyInfoToJSON,
} from './AggregateKeyInfo';
import type { BooleanSearch } from './BooleanSearch';
import {
    BooleanSearchFromJSON,
    BooleanSearchFromJSONTyped,
    BooleanSearchToJSON,
} from './BooleanSearch';
import type { DateSearch } from './DateSearch';
import {
    DateSearchFromJSON,
    DateSearchFromJSONTyped,
    DateSearchToJSON,
} from './DateSearch';
import type { EStatutContratListSearch } from './EStatutContratListSearch';
import {
    EStatutContratListSearchFromJSON,
    EStatutContratListSearchFromJSONTyped,
    EStatutContratListSearchToJSON,
} from './EStatutContratListSearch';
import type { ETypeContratNullableListSearch } from './ETypeContratNullableListSearch';
import {
    ETypeContratNullableListSearchFromJSON,
    ETypeContratNullableListSearchFromJSONTyped,
    ETypeContratNullableListSearchToJSON,
} from './ETypeContratNullableListSearch';
import type { Int32ListSearch } from './Int32ListSearch';
import {
    Int32ListSearchFromJSON,
    Int32ListSearchFromJSONTyped,
    Int32ListSearchToJSON,
} from './Int32ListSearch';
import type { TextSearch } from './TextSearch';
import {
    TextSearchFromJSON,
    TextSearchFromJSONTyped,
    TextSearchToJSON,
} from './TextSearch';
import type { UnaccentTextSearch } from './UnaccentTextSearch';
import {
    UnaccentTextSearchFromJSON,
    UnaccentTextSearchFromJSONTyped,
    UnaccentTextSearchToJSON,
} from './UnaccentTextSearch';

/**
 * 
 * @export
 * @interface ContratSearchDto
 */
export interface ContratSearchDto {
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof ContratSearchDto
     */
    apprenant?: UnaccentTextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof ContratSearchDto
     */
    code?: TextSearch;
    /**
     * 
     * @type {ETypeContratNullableListSearch}
     * @memberof ContratSearchDto
     */
    type?: ETypeContratNullableListSearch;
    /**
     * 
     * @type {EStatutContratListSearch}
     * @memberof ContratSearchDto
     */
    statut?: EStatutContratListSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof ContratSearchDto
     */
    stade?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof ContratSearchDto
     */
    codeMetier?: TextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof ContratSearchDto
     */
    nomMetier?: TextSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof ContratSearchDto
     */
    lieuPrincipal?: UnaccentTextSearch;
    /**
     * 
     * @type {UnaccentTextSearch}
     * @memberof ContratSearchDto
     */
    tuteurPrincipal?: UnaccentTextSearch;
    /**
     * 
     * @type {TextSearch}
     * @memberof ContratSearchDto
     */
    serviceNom?: TextSearch;
    /**
     * 
     * @type {BooleanSearch}
     * @memberof ContratSearchDto
     */
    mesContrats?: BooleanSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof ContratSearchDto
     */
    idpri?: Int32ListSearch;
    /**
     * 
     * @type {Int32ListSearch}
     * @memberof ContratSearchDto
     */
    idari?: Int32ListSearch;
    /**
     * 
     * @type {DateSearch}
     * @memberof ContratSearchDto
     */
    dateDebut?: DateSearch;
    /**
     * 
     * @type {DateSearch}
     * @memberof ContratSearchDto
     */
    dateFin?: DateSearch;
    /**
     * 
     * @type {number}
     * @memberof ContratSearchDto
     */
    idapprenant?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratSearchDto
     */
    idtuteur?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratSearchDto
     */
    idlieuFormation?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContratSearchDto
     */
    sortKey?: Array<string> | null;
    /**
     * 
     * @type {Array<AggregateKeyInfo>}
     * @memberof ContratSearchDto
     */
    aggregateKeys?: Array<AggregateKeyInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof ContratSearchDto
     */
    forceSkip?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratSearchDto
     */
    forceTake?: number | null;
    /**
     * 
     * @type {any}
     * @memberof ContratSearchDto
     */
    parameters?: any | null;
    /**
     * 
     * @type {string}
     * @memberof ContratSearchDto
     */
    filter?: string | null;
}

/**
 * Check if a given object implements the ContratSearchDto interface.
 */
export function instanceOfContratSearchDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContratSearchDtoFromJSON(json: any): ContratSearchDto {
    return ContratSearchDtoFromJSONTyped(json, false);
}

export function ContratSearchDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContratSearchDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apprenant': !exists(json, 'apprenant') ? undefined : UnaccentTextSearchFromJSON(json['apprenant']),
        'code': !exists(json, 'code') ? undefined : TextSearchFromJSON(json['code']),
        'type': !exists(json, 'type') ? undefined : ETypeContratNullableListSearchFromJSON(json['type']),
        'statut': !exists(json, 'statut') ? undefined : EStatutContratListSearchFromJSON(json['statut']),
        'stade': !exists(json, 'stade') ? undefined : TextSearchFromJSON(json['stade']),
        'codeMetier': !exists(json, 'codeMetier') ? undefined : TextSearchFromJSON(json['codeMetier']),
        'nomMetier': !exists(json, 'nomMetier') ? undefined : TextSearchFromJSON(json['nomMetier']),
        'lieuPrincipal': !exists(json, 'lieuPrincipal') ? undefined : UnaccentTextSearchFromJSON(json['lieuPrincipal']),
        'tuteurPrincipal': !exists(json, 'tuteurPrincipal') ? undefined : UnaccentTextSearchFromJSON(json['tuteurPrincipal']),
        'serviceNom': !exists(json, 'serviceNom') ? undefined : TextSearchFromJSON(json['serviceNom']),
        'mesContrats': !exists(json, 'mesContrats') ? undefined : BooleanSearchFromJSON(json['mesContrats']),
        'idpri': !exists(json, 'idpri') ? undefined : Int32ListSearchFromJSON(json['idpri']),
        'idari': !exists(json, 'idari') ? undefined : Int32ListSearchFromJSON(json['idari']),
        'dateDebut': !exists(json, 'dateDebut') ? undefined : DateSearchFromJSON(json['dateDebut']),
        'dateFin': !exists(json, 'dateFin') ? undefined : DateSearchFromJSON(json['dateFin']),
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idtuteur': !exists(json, 'idtuteur') ? undefined : json['idtuteur'],
        'idlieuFormation': !exists(json, 'idlieuFormation') ? undefined : json['idlieuFormation'],
        'sortKey': !exists(json, 'sortKey') ? undefined : json['sortKey'],
        'aggregateKeys': !exists(json, 'aggregateKeys') ? undefined : (json['aggregateKeys'] === null ? null : (json['aggregateKeys'] as Array<any>).map(AggregateKeyInfoFromJSON)),
        'forceSkip': !exists(json, 'forceSkip') ? undefined : json['forceSkip'],
        'forceTake': !exists(json, 'forceTake') ? undefined : json['forceTake'],
        'parameters': !exists(json, 'parameters') ? undefined : json['parameters'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function ContratSearchDtoToJSON(value?: ContratSearchDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apprenant': UnaccentTextSearchToJSON(value.apprenant),
        'code': TextSearchToJSON(value.code),
        'type': ETypeContratNullableListSearchToJSON(value.type),
        'statut': EStatutContratListSearchToJSON(value.statut),
        'stade': TextSearchToJSON(value.stade),
        'codeMetier': TextSearchToJSON(value.codeMetier),
        'nomMetier': TextSearchToJSON(value.nomMetier),
        'lieuPrincipal': UnaccentTextSearchToJSON(value.lieuPrincipal),
        'tuteurPrincipal': UnaccentTextSearchToJSON(value.tuteurPrincipal),
        'serviceNom': TextSearchToJSON(value.serviceNom),
        'mesContrats': BooleanSearchToJSON(value.mesContrats),
        'idpri': Int32ListSearchToJSON(value.idpri),
        'idari': Int32ListSearchToJSON(value.idari),
        'dateDebut': DateSearchToJSON(value.dateDebut),
        'dateFin': DateSearchToJSON(value.dateFin),
        'idapprenant': value.idapprenant,
        'idtuteur': value.idtuteur,
        'idlieuFormation': value.idlieuFormation,
        'sortKey': value.sortKey,
        'aggregateKeys': value.aggregateKeys === undefined ? undefined : (value.aggregateKeys === null ? null : (value.aggregateKeys as Array<any>).map(AggregateKeyInfoToJSON)),
        'forceSkip': value.forceSkip,
        'forceTake': value.forceTake,
        'parameters': value.parameters,
        'filter': value.filter,
    };
}

