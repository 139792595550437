import { getIn } from "formik";
import { useFGContext } from "nsitools-react";
import * as React from "react";

import { ReferentialItemDto } from "../../api";
import { useTl } from "../../hooks";
import { useReferential } from "../../hooks/useReferential";
import { ETLCodes } from "../../locales";
import { nameof } from "../../utils";
import { FGWalterSelectInput, IFGWalterSelectInputProps } from "../formGenerator";

export interface ISecteurSelectProps
  extends Omit<IFGWalterSelectInputProps<any>, "items" | "loading" | "displayField" | "valueField"> {
  secteurCreaFieldName?: string;
}

export const SecteurSelect: React.FunctionComponent<ISecteurSelectProps> = ({
  secteurCreaFieldName,
  ...fgSelectInputProps
}) => {
  const { t } = useTl();
  const { formik } = useFGContext();

  const idsecteurCrea = React.useMemo(
    () => (!!secteurCreaFieldName ? getIn(formik?.values, secteurCreaFieldName) : null),
    [secteurCreaFieldName, formik?.values]
  );

  const [data, isFetching] = useReferential(a => a.referentialGetSecteurByIdsecteurCrea({ idsecteurCrea }), false, [
    idsecteurCrea
  ]);

  return (
    <FGWalterSelectInput
      label={fgSelectInputProps.label || t(ETLCodes.SecteursMetiers)}
      items={data}
      loading={isFetching}
      {...fgSelectInputProps}
    />
  );
};
