import * as React from "react";
import { Route, Switch } from "react-router";

import { ERoutes } from "../../../../../AppRouter";
import { FormateurAgrementList } from "./FormateurAgrementList";
import { FormateurAgrementTabs } from "./FormateurAgrementTabs";
import { FormateurAgrementMetiersSelector } from "./FormateurAgrementMetierSelector";

export interface IFormateurAgrementSwitchProps {}

export const FormateurAgrementSwitch: React.FunctionComponent<IFormateurAgrementSwitchProps> = () => {
  return (
    <Switch>
      <Route path={`${ERoutes.formateur}/:id/:tab`} component={FormateurAgrementList} exact />
      <Route
        path={`${ERoutes.formateur}/:id/:tab/:idAgrement/:tabAgrement/selector`}
        component={FormateurAgrementMetiersSelector}
      />
      <Route
        path={`${ERoutes.formateur}/:id/:tab/:idAgrement/:tabAgrement/:stateAgrement?`}
        component={FormateurAgrementTabs}
      />
    </Switch>
  );
};
