import { Classes, Dialog } from "@blueprintjs/core";
import { format } from "date-fns";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";

import { EOrigineDossier, StatutApprenantApi, StatutApprenantEditDto, StatutApprenantEditDtoFromJSON } from "../../api";
import { useApiService, useTheme, useTl } from "../../hooks";
import { useReferential } from "../../hooks/useReferential";
import { ETLCodes } from "../../locales";
import { FGWalterDateMaskInput, FGWalterSelectInput } from "../formGenerator";
import { SmallFormGenerator } from "../SmallFormGenerator";

const StyledDialog = styled(Dialog)`
  width: 600px;
  height: auto;
  background-color: white;
`;

export interface ISortieReseauDialogProps {
  idApprenant: number;
  dateStatut: Date;
  idCategorieStatut?: number;
  idParcoursFormation?: number;
  idStatut?: number;
  iddureeInoccupation?: number;
  isDialogOpen: boolean;
  onClose: () => void;
}

export const SortieReseauDialog: React.FunctionComponent<ISortieReseauDialogProps> = ({
  idApprenant,
  dateStatut,
  isDialogOpen,
  onClose,
  idCategorieStatut,
  idParcoursFormation,
  idStatut,
  iddureeInoccupation
}) => {
  const { t } = useTl();
  const { ifapmeSide } = useTheme();
  const api = useApiService(StatutApprenantApi);
  const [idCatStatutSocial, setIdCatStatutSocial] = React.useState(idCategorieStatut);
  const [saving, setSaving] = React.useState(false);

  const [categoriesStatutSocial, cssLoading, , rawCatStatutSocial] = useReferential(a =>
    a.referentialGetCategoriesStatutSocial()
  );
  const [statutsSocial, ssLoading] = useReferential(
    a => a.referentialGetSousStatutsByCategorie({ idCategorie: idCatStatutSocial, currentIdstatutSocial: 0 }),
    true,
    [idCatStatutSocial]
  );
  const [idtypeSortie, setIdtypeSortie] = React.useState(0);
  const [typesSortie, tLoading] = useReferential(a => a.referentialGetTypesSortie({ currentId: idtypeSortie }));
  const [motifsSortie, msLoading] = useReferential(a => a.referentialGetMotifsSortie({ idtypeSortie }), true, [
    idtypeSortie
  ]);

  const saveItem = React.useCallback(
    async (d: StatutApprenantEditDto) => {
      setSaving(true);
      await api.statutApprenantSaveStatutApprenant({ StatutApprenantEditDto: d });
      setSaving(false);

      onClose();
    },
    [api, onClose]
  );

  const data = React.useMemo(
    () =>
      StatutApprenantEditDtoFromJSON({
        idApprenant,
        dateStatut: dateStatut ?? new Date(),
        idCategorieStatut,
        idStatut,
        idParcoursFormation,
        origineSortie: ifapmeSide === "hope" ? EOrigineDossier.Hope : EOrigineDossier.Walter,
        iddureeInoccupation
      }),
    [dateStatut, idApprenant, idCategorieStatut, idParcoursFormation, idStatut, iddureeInoccupation, ifapmeSide]
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape({
        idCategorieStatut: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        idStatut: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        idtypeSortie: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        idMotifSortie: Yup.number()
          .nullable()
          .required(t(ETLCodes.Required)),
        dateStatut: Yup.date()
          .nullable()
          .required(t(ETLCodes.Required))
          .min(
            data?.dateStatut,
            t(ETLCodes.DateCannotBePastOf, { minDate: data?.dateStatut && format(data?.dateStatut, "dd/MM/yyyy") })
          )
      }),
    [data?.dateStatut, t]
  );

  const [dureeInoccupations, diLoading] = useReferential(a => a.referentialGetDureeInoccupation(), true, []);

  const showDureeInoccupation = React.useMemo(
    () =>
      !!rawCatStatutSocial &&
      +(rawCatStatutSocial.find(c => c.keyValue === "DEM")?.idValue ?? 0) === +idCatStatutSocial,
    [idCatStatutSocial, rawCatStatutSocial]
  );

  return (
    <StyledDialog title={t(ETLCodes.SortieReseau)} isOpen={isDialogOpen} onClose={() => onClose()}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose()}
          showDeleteButton={false}
          saving={saving}
          watchChanges={{
            idCategorieStatut: setIdCatStatutSocial,
            idtypeSortie: setIdtypeSortie
          }}
        >
          <FGWalterSelectInput
            name="idCategorieStatut"
            label={t(ETLCodes.CategorieStatut)}
            items={categoriesStatutSocial}
            loading={cssLoading}
          />
          <FGWalterSelectInput
            name="idStatut"
            label={t(ETLCodes.Statut)}
            items={statutsSocial}
            loading={ssLoading}
            emptyIfNotExists
          />
          {showDureeInoccupation && (
            <FGWalterSelectInput
              name="iddureeInoccupation"
              label={t(ETLCodes.DureeInoccupation)}
              items={dureeInoccupations}
              loading={diLoading}
              requiredMark
            />
          )}
          <FGWalterSelectInput
            name="idtypeSortie"
            label={t(ETLCodes.TypeSortie)}
            items={typesSortie}
            loading={tLoading}
          />
          <FGWalterSelectInput
            name="idMotifSortie"
            label={t(ETLCodes.MotifSortie)}
            items={motifsSortie}
            loading={msLoading}
          />
          <FGWalterDateMaskInput name="dateStatut" label={t(ETLCodes.DateStatut)} minDate={data?.dateStatut} />
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
