/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClasseHoraireJourColumnsDto,
  ClasseJourDto,
  FcbApprenantPresenceDetailDto,
  FcbFeuillePresenceGridDtoPaginatedResults,
  FeuillePresenceInfoDto,
  FeuillePresencePrintRequestDto,
  FeuillePresenceSaveDto,
  FeuillePresenceSearch,
  FeuillePresencesPrintDatesDto,
  FileDownloadDto,
  FilterCriteriaInfo,
  MinDatePresenceDto,
  SelectItem,
} from '../models/index';
import {
    ClasseHoraireJourColumnsDtoFromJSON,
    ClasseHoraireJourColumnsDtoToJSON,
    ClasseJourDtoFromJSON,
    ClasseJourDtoToJSON,
    FcbApprenantPresenceDetailDtoFromJSON,
    FcbApprenantPresenceDetailDtoToJSON,
    FcbFeuillePresenceGridDtoPaginatedResultsFromJSON,
    FcbFeuillePresenceGridDtoPaginatedResultsToJSON,
    FeuillePresenceInfoDtoFromJSON,
    FeuillePresenceInfoDtoToJSON,
    FeuillePresencePrintRequestDtoFromJSON,
    FeuillePresencePrintRequestDtoToJSON,
    FeuillePresenceSaveDtoFromJSON,
    FeuillePresenceSaveDtoToJSON,
    FeuillePresenceSearchFromJSON,
    FeuillePresenceSearchToJSON,
    FeuillePresencesPrintDatesDtoFromJSON,
    FeuillePresencesPrintDatesDtoToJSON,
    FileDownloadDtoFromJSON,
    FileDownloadDtoToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    MinDatePresenceDtoFromJSON,
    MinDatePresenceDtoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface FeuillePresenceBaseSearchRequest {
    FeuillePresenceSearch?: FeuillePresenceSearch;
}

export interface FeuillePresenceExportAsyncRequest {
    FeuillePresencePrintRequestDto?: FeuillePresencePrintRequestDto;
}

export interface FeuillePresenceGetApprenantListRequest {
    idClasse?: number;
    dateClasse?: Date;
}

export interface FeuillePresenceGetDatesRequest {
    idClasse?: number;
    periodeImpression?: number;
}

export interface FeuillePresenceGetDatesForClasseRequest {
    idClasse?: number;
}

export interface FeuillePresenceGetFeuillePresenceInfoRequest {
    idClasse?: number;
    dateClasse?: Date;
}

export interface FeuillePresenceGetHoraireClasseJourColumnsRequest {
    idClasse?: number;
    dateClasse?: Date;
}

export interface FeuillePresenceGetMaxDatesRequest {
    idClasse?: number;
}

export interface FeuillePresenceGetMinDatePresenceRequest {
    idClasse?: number;
}

export interface FeuillePresenceGetPrintRequest {
    idClasse?: number;
}

export interface FeuillePresenceGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface FeuillePresenceGetSelectItemsRequest {
    searchField: string;
}

export interface FeuillePresenceSaveRequest {
    FeuillePresenceSaveDto?: FeuillePresenceSaveDto;
}

/**
 * 
 */
export class FeuillePresenceApi extends runtime.BaseAPI {

    /**
     */
    async feuillePresenceBaseSearchRaw(requestParameters: FeuillePresenceBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbFeuillePresenceGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FeuillePresence/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FeuillePresenceSearchToJSON(requestParameters.FeuillePresenceSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbFeuillePresenceGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async feuillePresenceBaseSearch(requestParameters: FeuillePresenceBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbFeuillePresenceGridDtoPaginatedResults> {
        const response = await this.feuillePresenceBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async feuillePresenceExportAsyncRaw(requestParameters: FeuillePresenceExportAsyncRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileDownloadDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FeuillePresence/Export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FeuillePresencePrintRequestDtoToJSON(requestParameters.FeuillePresencePrintRequestDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDownloadDtoFromJSON(jsonValue));
    }

    /**
     */
    async feuillePresenceExportAsync(requestParameters: FeuillePresenceExportAsyncRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileDownloadDto> {
        const response = await this.feuillePresenceExportAsyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async feuillePresenceGetApprenantListRaw(requestParameters: FeuillePresenceGetApprenantListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FcbApprenantPresenceDetailDto>>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        if (requestParameters.dateClasse !== undefined) {
            queryParameters['dateClasse'] = (requestParameters.dateClasse as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FeuillePresence/GetApprenantList`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FcbApprenantPresenceDetailDtoFromJSON));
    }

    /**
     */
    async feuillePresenceGetApprenantList(requestParameters: FeuillePresenceGetApprenantListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FcbApprenantPresenceDetailDto>> {
        const response = await this.feuillePresenceGetApprenantListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async feuillePresenceGetDatesRaw(requestParameters: FeuillePresenceGetDatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FeuillePresencesPrintDatesDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        if (requestParameters.periodeImpression !== undefined) {
            queryParameters['periodeImpression'] = requestParameters.periodeImpression;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FeuillePresence/GetDates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeuillePresencesPrintDatesDtoFromJSON(jsonValue));
    }

    /**
     */
    async feuillePresenceGetDates(requestParameters: FeuillePresenceGetDatesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FeuillePresencesPrintDatesDto> {
        const response = await this.feuillePresenceGetDatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async feuillePresenceGetDatesForClasseRaw(requestParameters: FeuillePresenceGetDatesForClasseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClasseJourDto>>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FeuillePresence/GetDateForClasse`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClasseJourDtoFromJSON));
    }

    /**
     */
    async feuillePresenceGetDatesForClasse(requestParameters: FeuillePresenceGetDatesForClasseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClasseJourDto>> {
        const response = await this.feuillePresenceGetDatesForClasseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async feuillePresenceGetFeuillePresenceInfoRaw(requestParameters: FeuillePresenceGetFeuillePresenceInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FeuillePresenceInfoDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        if (requestParameters.dateClasse !== undefined) {
            queryParameters['dateClasse'] = (requestParameters.dateClasse as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FeuillePresence/GetFeuillePresenceInfo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeuillePresenceInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async feuillePresenceGetFeuillePresenceInfo(requestParameters: FeuillePresenceGetFeuillePresenceInfoRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FeuillePresenceInfoDto> {
        const response = await this.feuillePresenceGetFeuillePresenceInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async feuillePresenceGetHoraireClasseJourColumnsRaw(requestParameters: FeuillePresenceGetHoraireClasseJourColumnsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClasseHoraireJourColumnsDto>>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        if (requestParameters.dateClasse !== undefined) {
            queryParameters['dateClasse'] = (requestParameters.dateClasse as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FeuillePresence/GetHoraireClasseJourColumns`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClasseHoraireJourColumnsDtoFromJSON));
    }

    /**
     */
    async feuillePresenceGetHoraireClasseJourColumns(requestParameters: FeuillePresenceGetHoraireClasseJourColumnsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClasseHoraireJourColumnsDto>> {
        const response = await this.feuillePresenceGetHoraireClasseJourColumnsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async feuillePresenceGetMaxDatesRaw(requestParameters: FeuillePresenceGetMaxDatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FeuillePresencesPrintDatesDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FeuillePresence/GetMaxDates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeuillePresencesPrintDatesDtoFromJSON(jsonValue));
    }

    /**
     */
    async feuillePresenceGetMaxDates(requestParameters: FeuillePresenceGetMaxDatesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FeuillePresencesPrintDatesDto> {
        const response = await this.feuillePresenceGetMaxDatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async feuillePresenceGetMinDatePresenceRaw(requestParameters: FeuillePresenceGetMinDatePresenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MinDatePresenceDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FeuillePresence/GetMinDatePresence`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MinDatePresenceDtoFromJSON(jsonValue));
    }

    /**
     */
    async feuillePresenceGetMinDatePresence(requestParameters: FeuillePresenceGetMinDatePresenceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MinDatePresenceDto> {
        const response = await this.feuillePresenceGetMinDatePresenceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async feuillePresenceGetPrintRaw(requestParameters: FeuillePresenceGetPrintRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FeuillePresencePrintRequestDto>> {
        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FeuillePresence/GetPrint`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeuillePresencePrintRequestDtoFromJSON(jsonValue));
    }

    /**
     */
    async feuillePresenceGetPrint(requestParameters: FeuillePresenceGetPrintRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FeuillePresencePrintRequestDto> {
        const response = await this.feuillePresenceGetPrintRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async feuillePresenceGetSearchCriteriasRaw(requestParameters: FeuillePresenceGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FeuillePresence/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async feuillePresenceGetSearchCriterias(requestParameters: FeuillePresenceGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.feuillePresenceGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async feuillePresenceGetSelectItemsRaw(requestParameters: FeuillePresenceGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling feuillePresenceGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FeuillePresence/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async feuillePresenceGetSelectItems(requestParameters: FeuillePresenceGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.feuillePresenceGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async feuillePresenceSaveRaw(requestParameters: FeuillePresenceSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FeuillePresenceSaveDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/FeuillePresence/Save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FeuillePresenceSaveDtoToJSON(requestParameters.FeuillePresenceSaveDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeuillePresenceSaveDtoFromJSON(jsonValue));
    }

    /**
     */
    async feuillePresenceSave(requestParameters: FeuillePresenceSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FeuillePresenceSaveDto> {
        const response = await this.feuillePresenceSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
