/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BooleanDto,
  EvaluationSearch,
  FcbEvaluationApprenantListGetDto,
  FcbEvaluationDto,
  FcbEvaluationGridDtoPaginatedResults,
  FilterCriteriaInfo,
  SelectItem,
} from '../models/index';
import {
    BooleanDtoFromJSON,
    BooleanDtoToJSON,
    EvaluationSearchFromJSON,
    EvaluationSearchToJSON,
    FcbEvaluationApprenantListGetDtoFromJSON,
    FcbEvaluationApprenantListGetDtoToJSON,
    FcbEvaluationDtoFromJSON,
    FcbEvaluationDtoToJSON,
    FcbEvaluationGridDtoPaginatedResultsFromJSON,
    FcbEvaluationGridDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface EvaluationBaseSearchRequest {
    EvaluationSearch?: EvaluationSearch;
}

export interface EvaluationDeleteRequest {
    id?: number;
}

export interface EvaluationGetRequest {
    id?: number;
}

export interface EvaluationGetApprenantListRequest {
    id: number;
    idClasse?: number;
}

export interface EvaluationGetDisplayNameRequest {
    id?: number;
}

export interface EvaluationGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface EvaluationGetSelectItemsRequest {
    searchField: string;
}

export interface EvaluationIsFromWalterRequest {
    idEvaluation: number;
}

export interface EvaluationSaveRequest {
    FcbEvaluationDto?: FcbEvaluationDto;
}

export interface EvaluationSaveEvaluationDetailsRequest {
    FcbEvaluationApprenantListGetDto?: FcbEvaluationApprenantListGetDto;
}

/**
 * 
 */
export class EvaluationApi extends runtime.BaseAPI {

    /**
     */
    async evaluationBaseSearchRaw(requestParameters: EvaluationBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbEvaluationGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Evaluation/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EvaluationSearchToJSON(requestParameters.EvaluationSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbEvaluationGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async evaluationBaseSearch(requestParameters: EvaluationBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbEvaluationGridDtoPaginatedResults> {
        const response = await this.evaluationBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async evaluationDeleteRaw(requestParameters: EvaluationDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Evaluation`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async evaluationDelete(requestParameters: EvaluationDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.evaluationDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async evaluationGetRaw(requestParameters: EvaluationGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbEvaluationDto>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Evaluation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbEvaluationDtoFromJSON(jsonValue));
    }

    /**
     */
    async evaluationGet(requestParameters: EvaluationGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbEvaluationDto> {
        const response = await this.evaluationGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async evaluationGetApprenantListRaw(requestParameters: EvaluationGetApprenantListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbEvaluationApprenantListGetDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling evaluationGetApprenantList.');
        }

        const queryParameters: any = {};

        if (requestParameters.idClasse !== undefined) {
            queryParameters['idClasse'] = requestParameters.idClasse;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Evaluation/GetApprenantList/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbEvaluationApprenantListGetDtoFromJSON(jsonValue));
    }

    /**
     */
    async evaluationGetApprenantList(requestParameters: EvaluationGetApprenantListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbEvaluationApprenantListGetDto> {
        const response = await this.evaluationGetApprenantListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async evaluationGetDisplayNameRaw(requestParameters: EvaluationGetDisplayNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Evaluation/GetDisplayName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async evaluationGetDisplayName(requestParameters: EvaluationGetDisplayNameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.evaluationGetDisplayNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async evaluationGetSearchCriteriasRaw(requestParameters: EvaluationGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Evaluation/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async evaluationGetSearchCriterias(requestParameters: EvaluationGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.evaluationGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async evaluationGetSelectItemsRaw(requestParameters: EvaluationGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling evaluationGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Evaluation/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async evaluationGetSelectItems(requestParameters: EvaluationGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.evaluationGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async evaluationIsFromWalterRaw(requestParameters: EvaluationIsFromWalterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BooleanDto>> {
        if (requestParameters.idEvaluation === null || requestParameters.idEvaluation === undefined) {
            throw new runtime.RequiredError('idEvaluation','Required parameter requestParameters.idEvaluation was null or undefined when calling evaluationIsFromWalter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Evaluation/IsFromWalter/{idEvaluation}`.replace(`{${"idEvaluation"}}`, encodeURIComponent(String(requestParameters.idEvaluation))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanDtoFromJSON(jsonValue));
    }

    /**
     */
    async evaluationIsFromWalter(requestParameters: EvaluationIsFromWalterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BooleanDto> {
        const response = await this.evaluationIsFromWalterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async evaluationSaveRaw(requestParameters: EvaluationSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbEvaluationDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Evaluation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbEvaluationDtoToJSON(requestParameters.FcbEvaluationDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbEvaluationDtoFromJSON(jsonValue));
    }

    /**
     */
    async evaluationSave(requestParameters: EvaluationSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbEvaluationDto> {
        const response = await this.evaluationSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async evaluationSaveEvaluationDetailsRaw(requestParameters: EvaluationSaveEvaluationDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbEvaluationApprenantListGetDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/Evaluation/SaveEvaluationDetails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbEvaluationApprenantListGetDtoToJSON(requestParameters.FcbEvaluationApprenantListGetDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbEvaluationApprenantListGetDtoFromJSON(jsonValue));
    }

    /**
     */
    async evaluationSaveEvaluationDetails(requestParameters: EvaluationSaveEvaluationDetailsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbEvaluationApprenantListGetDto> {
        const response = await this.evaluationSaveEvaluationDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
