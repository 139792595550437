import * as React from "react";
import { Route, Switch } from "react-router";

import { ERoutes } from "../../../../../../AppRouter";
import { DispenseDetail } from "./DispenseDetail";
import { DispenseList } from "./DispenseList";

export interface IDispenseSwitchProps {}

export const DispenseSwitch: React.FunctionComponent<IDispenseSwitchProps> = () => {
  return (
    <Switch>
      <Route path={`${ERoutes.apprenant}/:id/:tab/:inscriptionId/:tabInscription`} component={DispenseList} exact />
      <Route
        path={`${ERoutes.apprenant}/:id/:tab/:inscriptionId/:tabInscription/:dispenseId/:stateDispense?`}
        component={DispenseDetail}
      />
    </Switch>
  );
};
