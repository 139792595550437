import { Button, Card, Icon, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { FieldSet } from "nsitools-react";
import * as React from "react";
import { Col, Grid, Row } from "react-flexbox-grid";
import { useHistory } from "react-router";
import styled from "styled-components";

import { ERoutes } from "../../AppRouter";
import { CardAttribute, PageBase } from "../../components";
import { useBeIdCardContext } from "../../contexts";
import { useTheme, useTl } from "../../hooks";
import { ETLCodes } from "../../locales";

export interface IBeidReaderPageProps {}

const PhotoImage = styled.img`
  object-fit: fill;
  width: 120px;
  border-radius: 10px;
  border: 1px solid lightgray;
`;

export const BeidReaderPage: React.FunctionComponent<IBeidReaderPageProps> = () => {
  const { t } = useTl();
  const { card, singleFoundUser } = useBeIdCardContext();
  const history = useHistory();
  const { ifapmeSide } = useTheme();
  const isHope = React.useMemo(() => ifapmeSide === "hope", [ifapmeSide]);

  const ColButton = React.useCallback(
    (text: string, onClick: () => void) => (
      <Row>
        <Col xs={12}>
          <Button text={text} style={{ width: "100%" }} intent={Intent.PRIMARY} onClick={onClick} />
        </Col>
      </Row>
    ),
    []
  );

  return (
    <PageBase withCard={true} breadCrumbs={[]}>
      <FieldSet title={t(ETLCodes.BeidReader)}>
        <Grid fluid>
          <Row>
            <Col xs={9}>
              <Card style={{ backgroundColor: "#d9ffd9", padding: "5px" }}>
                <Grid fluid>
                  <Row>
                    <Col xs={9}>
                      <Row>
                        <Col xs={6}>
                          <CardAttribute label={t(ETLCodes.Nom)} value={card?.surname}></CardAttribute>
                        </Col>
                        <Col xs={6}>
                          <CardAttribute label={t(ETLCodes.FirstNames)} value={card?.firstNames}></CardAttribute>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <CardAttribute
                            label={t(ETLCodes.LocationAndBirthDate)}
                            value={card?.locationAndBirthDate}
                          ></CardAttribute>
                        </Col>
                        <Col xs={6}>
                          <CardAttribute label={t(ETLCodes.Genre)} value={card?.gender}></CardAttribute>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <CardAttribute label={t(ETLCodes.Nationalite)} value={card?.nationality}></CardAttribute>
                        </Col>
                        <Col xs={6}>
                          <CardAttribute label={t(ETLCodes.CardNumber)} value={card?.cardNumber}></CardAttribute>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <CardAttribute
                            label={t(ETLCodes.NumeroNational)}
                            value={card?.nationalNumber}
                          ></CardAttribute>
                        </Col>
                        <Col xs={6}>
                          <CardAttribute label={t(ETLCodes.ValidityDates)} value={card?.validityDates}></CardAttribute>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={3}>
                      {card?.photoB64 ? (
                        <PhotoImage src={`data:image/jpg;base64,${card?.photoB64}`} alt="id-card" />
                      ) : (
                        <Icon iconSize={60} icon={IconNames.USER} />
                      )}
                    </Col>
                  </Row>
                </Grid>
              </Card>
            </Col>
            <Col xs={3}>
              {singleFoundUser && (
                <>
                  <Row>
                    <Col xs={12}>
                      <Button
                        text={t(ETLCodes.ConsulterFichePersonne)}
                        style={{ width: "100%" }}
                        onClick={() =>
                          history.push(`${ERoutes.personne}/${singleFoundUser.idpersonne}/signaletique/view`)
                        }
                      />
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "5px" }}>
                    <Col xs={12}>
                      <Button
                        text={t(ETLCodes.MiseAJourFichePersonne)}
                        style={{ width: "100%" }}
                        onClick={() =>
                          history.push(`${ERoutes.personne}/${singleFoundUser.idpersonne}/signaletique/edit`)
                        }
                      />
                    </Col>
                  </Row>
                </>
              )}
              {!singleFoundUser && card && (
                <>
                  {ColButton(t(ETLCodes.CreerApprenant), () =>
                    history.push(`${ERoutes.apprenant}/0/signaletique/edit`)
                  )}
                  {ColButton(t(ETLCodes.CreerRepresentant), () =>
                    history.push(`${ERoutes.representant}/0/detail/edit`)
                  )}
                  {!isHope &&
                    ColButton(t(ETLCodes.CreerFormateur), () =>
                      history.push(`${ERoutes.formateur}/0/signaletique/edit`)
                    )}
                  {isHope && ColButton(t(ETLCodes.CreerTuteur), () => history.push(`${ERoutes.tuteur}/0/detail/edit`))}
                </>
              )}
            </Col>
          </Row>
        </Grid>
      </FieldSet>
    </PageBase>
  );
};
