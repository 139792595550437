import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { SecteurApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { SecteurMetierDetailPage } from "./SecteurMetierDetailPage";
import { SecteurMetierMetierLiesListPage } from "./SecteurMetierMetierLiesListPage";

interface ISecteurMetierItemPageProps {}

export const SecteurMetierItemPage: React.FunctionComponent<ISecteurMetierItemPageProps> = () => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const secteurMetierApi = useApiService(SecteurApi);
  const [displayName] = useApiEffect(() => secteurMetierApi.secteurGetDisplayName({ id: id }), [id]);

  return (
    <PageBase
      breadCrumbs={[
        { text: t(ETLCodes.SecteursMetiers), route: ERoutes.secteurMetier },
        { text: id === "-1" ? t(ETLCodes.New) : displayName }
      ]}
    >
      <ProtectedTabs
        id="TabsProfessionFormateur"
        onChange={newTabId => history.push(`${ERoutes.secteurMetier}/${id}/${newTabId}/edit`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.secteurMetier}></BackButton>
        <Tab
          id="detail"
          title={t(ETLCodes.Detail)}
          panel={<SecteurMetierDetailPage codeSecteur={id} editMode={state === "edit"} />}
        />
        <Tab
          id="MetierLies"
          disabled={id === "-1"}
          title={t(ETLCodes.MetiersLies)}
          panel={<SecteurMetierMetierLiesListPage />}
        />
      </ProtectedTabs>
    </PageBase>
  );
};
