/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbFinancementClasseGridDto
 */
export interface FcbFinancementClasseGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseGridDto
     */
    idfinancementClasse?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseGridDto
     */
    idclasse?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseGridDto
     */
    idprojetFinancement?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFinancementClasseGridDto
     */
    financement?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseGridDto
     */
    heureS1?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseGridDto
     */
    heureS2?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseGridDto
     */
    totalLoc?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFinancementClasseGridDto
     */
    localise?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFinancementClasseGridDto
     */
    agree?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseGridDto
     */
    heureS1Agrement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseGridDto
     */
    heureS2Agrement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseGridDto
     */
    totalAgrement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseGridDto
     */
    reelHoursS1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseGridDto
     */
    reelHoursS2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseGridDto
     */
    reelTotalAgrement?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFinancementClasseGridDto
     */
    ppb?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFinancementClasseGridDto
     */
    localisationBloquee?: boolean | null;
}

/**
 * Check if a given object implements the FcbFinancementClasseGridDto interface.
 */
export function instanceOfFcbFinancementClasseGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbFinancementClasseGridDtoFromJSON(json: any): FcbFinancementClasseGridDto {
    return FcbFinancementClasseGridDtoFromJSONTyped(json, false);
}

export function FcbFinancementClasseGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbFinancementClasseGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idfinancementClasse': !exists(json, 'idfinancementClasse') ? undefined : json['idfinancementClasse'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'idprojetFinancement': !exists(json, 'idprojetFinancement') ? undefined : json['idprojetFinancement'],
        'financement': !exists(json, 'financement') ? undefined : json['financement'],
        'heureS1': !exists(json, 'heureS1') ? undefined : json['heureS1'],
        'heureS2': !exists(json, 'heureS2') ? undefined : json['heureS2'],
        'totalLoc': !exists(json, 'totalLoc') ? undefined : json['totalLoc'],
        'localise': !exists(json, 'localise') ? undefined : json['localise'],
        'agree': !exists(json, 'agree') ? undefined : json['agree'],
        'heureS1Agrement': !exists(json, 'heureS1Agrement') ? undefined : json['heureS1Agrement'],
        'heureS2Agrement': !exists(json, 'heureS2Agrement') ? undefined : json['heureS2Agrement'],
        'totalAgrement': !exists(json, 'totalAgrement') ? undefined : json['totalAgrement'],
        'reelHoursS1': !exists(json, 'reelHoursS1') ? undefined : json['reelHoursS1'],
        'reelHoursS2': !exists(json, 'reelHoursS2') ? undefined : json['reelHoursS2'],
        'reelTotalAgrement': !exists(json, 'reelTotalAgrement') ? undefined : json['reelTotalAgrement'],
        'ppb': !exists(json, 'ppb') ? undefined : json['ppb'],
        'localisationBloquee': !exists(json, 'localisationBloquee') ? undefined : json['localisationBloquee'],
    };
}

export function FcbFinancementClasseGridDtoToJSON(value?: FcbFinancementClasseGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idfinancementClasse': value.idfinancementClasse,
        'idclasse': value.idclasse,
        'idprojetFinancement': value.idprojetFinancement,
        'financement': value.financement,
        'heureS1': value.heureS1,
        'heureS2': value.heureS2,
        'totalLoc': value.totalLoc,
        'localise': value.localise,
        'agree': value.agree,
        'heureS1Agrement': value.heureS1Agrement,
        'heureS2Agrement': value.heureS2Agrement,
        'totalAgrement': value.totalAgrement,
        'reelHoursS1': value.reelHoursS1,
        'reelHoursS2': value.reelHoursS2,
        'reelTotalAgrement': value.reelTotalAgrement,
        'ppb': value.ppb,
        'localisationBloquee': value.localisationBloquee,
    };
}

