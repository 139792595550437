import { FGEmpty, FGMaskInput, FGTextInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";

import { ContratFormateurApi, FcbContratFormateurDtoFromJSON } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { FGWalterSelectInput, SmallFormGenerator, FGWalterCheckboxInput } from "../../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../../hooks";
import { useReferential } from "../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../locales";

interface IContratFormateurDetailPage {
  editMode: boolean;
  idContrat: number;
}

export const ContratFormateurDetailPage: React.FunctionComponent<IContratFormateurDetailPage> = ({
  editMode,
  idContrat
}) => {
  const { t } = useTl();
  const api = useApiService(ContratFormateurApi);
  const history = useHistory();

  const { data, loading, deleteItem, deleting, saveItem, saving } = useCrudApi({
    getApiFn: () =>
      +idContrat <= 0 ? FcbContratFormateurDtoFromJSON({ idContrat: 0 }) : api.contratFormateurGet({ id: +idContrat }),
    saveApiFn: d => api.contratFormateurSave({ FcbContratFormateurDto: d }),
    onSavedRoute: d => `${ERoutes.contratFormateur}/${d.idcontratFormateur}/detail/edit`,
    deleteApiFn: d => api.contratFormateurDelete({ id: d.idcontratFormateur }),
    onDeletedRoute: () => ERoutes.contratFormateur
  });

  const [typeContrat, loadingType] = useReferential(a => a.referentialGetTypeContratFormateur(), true, []);
  const [genreContrat, loadingGenre] = useReferential(a => a.referentialGetGenreContrat(), true, []);

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      code: Yup.string()
        .required(t(ETLCodes.Required))
        .max(20)
        .test("code-not-exists", t(ETLCodes.CodeExistsError), async value => {
          if (value === data?.code) return true;
          const validationError = await api.contratFormateurCodeContratFormateurExists({ code: value });
          return validationError.isValid;
        }),
      idgenre: Yup.number()
        .required(t(ETLCodes.Required))
        .notOneOf([-1, 0], t(ETLCodes.Required)),
      type: Yup.number()
        .required(t(ETLCodes.Required))
        .notOneOf([-1, 0], t(ETLCodes.Required))
    });
  }, [t, data, api]);

  return (
    data && (
      <SmallFormGenerator
        initialValues={data}
        onSubmit={saveItem}
        editMode={editMode}
        validationSchema={FormSchema}
        loading={loading}
        onCancel={() => history.push(ERoutes.contratFormateur)}
        onDelete={deleteItem}
        showDeleteButton={+idContrat > 0}
        saving={saving}
        deleting={deleting}
      >
        <FieldGroup columns={2}>
          <FGTextInput name="code" label={t(ETLCodes.Code)} maxLength={20} forceCase="upper" />
          <FGEmpty />
          <FGTextInput name="libelle" label={t(ETLCodes.Libelle)} maxLength={75} />
          <FGEmpty />
          <FGWalterSelectInput name="type" label={t(ETLCodes.Type)} loading={loadingType} items={typeContrat} />
          <FGEmpty />
          <FGWalterSelectInput name="idgenre" label={t(ETLCodes.Genre)} loading={loadingGenre} items={genreContrat} />
          <FGEmpty />
          <FGMaskInput
            label={t(ETLCodes.NbHeureMin)}
            name="nbheureMin"
            cleaveOptions={{ numericOnly: true, blocks: [10] }}
          />
          <FGEmpty />
          <FGMaskInput
            label={t(ETLCodes.NbHeureMax)}
            name="nbheureMax"
            cleaveOptions={{ numericOnly: true, blocks: [10] }}
          />
          <FGEmpty />
          <FGMaskInput
            label={t(ETLCodes.NbHeureJury)}
            name="nbheureJury"
            cleaveOptions={{ numericOnly: true, blocks: [10] }}
          />
          <FGEmpty />
          <FGWalterCheckboxInput name="actif" label={t(ETLCodes.Actif)} />
        </FieldGroup>
      </SmallFormGenerator>
    )
  );
};
