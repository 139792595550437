import { showSuccess, useApiEffect, ValidationErrorsType } from "nsitools-react";
import * as React from "react";
import { useHistory, useLocation } from "react-router";

import { useTl } from ".";
import { useDialog } from "../contexts";
import { ETLCodes } from "../locales";
import { useManageError } from "./useManageError";

export interface IUseCrudApiOptions<T> {
  getApiFn: () => Promise<T> | T;
  saveApiFn: (data: T) => Promise<T> | T;
  deleteApiFn?: (data: T) => Promise<any> | any;
  onSaved?: (data: T) => void;
  onSavedRoute?: (data: T) => string;
  onDeletedRoute?: (data: T) => string;
  successMessage?: string;
  deletedMessage?: string;
  getDeps?: React.DependencyList;
  deleteConfirmationMessage?: string;
  deleteDialogTitle?: string;
  serverValidationRootKey?: string;
  childArraysToSanitize?: { name: string; idKey: string }[];
  refreshAfterSave?: boolean;
  refreshAfterDelete?: boolean;
  closeCfs?: boolean;
}

export function useCrudApi<T>(options: IUseCrudApiOptions<T>) {
  const {
    getApiFn,
    saveApiFn,
    deleteApiFn,
    onSaved,
    onSavedRoute,
    onDeletedRoute,
    successMessage,
    deletedMessage,
    getDeps,
    deleteConfirmationMessage,
    deleteDialogTitle,
    childArraysToSanitize = [],
    serverValidationRootKey = "Dto",
    refreshAfterSave = false,
    refreshAfterDelete = false,
    closeCfs = true
  } = options;
  const { t } = useTl();
  const { showDialog } = useDialog();

  const history = useHistory();

  const [data, loading, , refresh, setData] = useApiEffect<T>(getApiFn, getDeps);

  const [saving, setSaving] = React.useState(false);

  const finalSuccessMessage = React.useMemo(() => successMessage || t(ETLCodes.SaveSuccess), [successMessage, t]);

  const { saveError, setCurrentError, manageError, parseValidationErrors } = useManageError();

  const { search } = useLocation();
  const isCreateFromSelect = React.useMemo(() => search.includes("cfs="), [search]);

  const saveItem = React.useCallback(
    async (values: T) => {
      try {
        setSaving(true);
        let nextValues = { ...values };
        if (childArraysToSanitize.length > 0) {
          childArraysToSanitize.forEach(k =>
            nextValues[k.name]?.filter(p => p[k.idKey] < 0)?.forEach(p => (p[k.idKey] = 0))
          );
        }
        const saved: T = await saveApiFn(nextValues);
        if (saved) {
          setCurrentError(null);
          showSuccess(finalSuccessMessage);
          if (refreshAfterSave) refresh();
          else setData(saved);
          setTimeout(() => {
            if (onSaved) {
              onSaved(saved);
            }
            if (closeCfs && isCreateFromSelect) {
              window.close();
              return;
            }
            if (onSavedRoute) {
              history.push(onSavedRoute(saved));
            }
          }, 0);
        }
      } catch (error) {
        manageError(error);
      }
      setSaving(false);
    },
    [
      childArraysToSanitize,
      closeCfs,
      finalSuccessMessage,
      history,
      isCreateFromSelect,
      manageError,
      onSaved,
      onSavedRoute,
      refresh,
      refreshAfterSave,
      saveApiFn,
      setCurrentError,
      setData
    ]
  );

  const finalDeleteMessage = React.useMemo(() => deletedMessage || t(ETLCodes.DeleteSuccess), [deletedMessage, t]);

  const [deleting, setDeleting] = React.useState(false);
  const deleteItem = React.useCallback(() => {
    showDialog({
      message: deleteConfirmationMessage || t(ETLCodes.DeleteConfirmationMessage),
      title: deleteDialogTitle || t(ETLCodes.DeleteDialogTitle),
      onConfirmed: async () => {
        try {
          setDeleting(true);
          const deleted = await deleteApiFn(data);
          if (deleted === false) {
            // Check litteraly false, not null or undefined
            setDeleting(false);
            return;
          }
          if (refreshAfterDelete) refresh();
          setTimeout(() => {
            if (onDeletedRoute) {
              history.push(onDeletedRoute(data));
            }
          }, 0);
          showSuccess(finalDeleteMessage);
        } catch (error) {
          manageError(error);
        }
        setDeleting(false);
      }
    });
  }, [
    data,
    deleteApiFn,
    deleteConfirmationMessage,
    deleteDialogTitle,
    finalDeleteMessage,
    history,
    manageError,
    onDeletedRoute,
    refresh,
    refreshAfterDelete,
    showDialog,
    t
  ]);

  const [validationErrors, setValidationErrors] = React.useState<ValidationErrorsType>({});

  React.useEffect(() => {
    if (!!saveError) {
      const err = parseValidationErrors(saveError, serverValidationRootKey);
      setValidationErrors(err);
    } else {
      setValidationErrors({});
    }
  }, [parseValidationErrors, saveError, serverValidationRootKey]);

  return {
    data,
    loading,
    setData,
    saveItem,
    saving,
    setSaving,
    deleteItem,
    deleting,
    refresh,
    saveError,
    validationErrors
  };
}
