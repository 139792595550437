import * as React from "react";
import { ERoutes } from "../../../../../AppRouter";
import { Switch, Route } from "react-router";
import { FormateurSuiviListPage } from "./FormateurSuiviListPage";
import { FormateurSuiviItemPage } from "./FormateurSuiviItemPage";

export interface IFormateurSuiviSwitchProps {}

export const FormateurSuiviSwitch: React.FunctionComponent<IFormateurSuiviSwitchProps> = () => {
  return (
    <Switch>
      <Route path={`${ERoutes.formateur}/:id/:tab`} component={FormateurSuiviListPage} exact />
      <Route path={`${ERoutes.formateur}/:id/:tab/:idSuivi/:state`} component={FormateurSuiviItemPage} />
    </Switch>
  );
};
