/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbBulletinApprenantTypeDto
 */
export interface FcbBulletinApprenantTypeDto {
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDto
     */
    idbulletinApprenantType?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDto
     */
    idapprenant?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDto
     */
    idtypeCours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDto
     */
    anneeScolaire?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDto
     */
    totalCalcule?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDto
     */
    totalDelibe?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDto
     */
    total1sess?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDto
     */
    max1sess?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDto
     */
    delibe1sess?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDto
     */
    totalCalcule2sess?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDto
     */
    totalDelibe2sess?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDto
     */
    total2sess?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDto
     */
    max2sess?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDto
     */
    delibe2sess?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbBulletinApprenantTypeDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbBulletinApprenantTypeDto
     */
    creationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDto
     */
    max1sessSansDispense?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDto
     */
    modificationUserName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDto
     */
    iddeliberationType?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDto
     */
    iddeliberationType2sess?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDto
     */
    remarqueDelibe?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDto
     */
    remarqueDelibe2sess?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDto
     */
    idcentre?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbBulletinApprenantTypeDto
     */
    dateRupture?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbBulletinApprenantTypeDto
     */
    dateAbandon?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbBulletinApprenantTypeDto
     */
    dateDeliberation?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDto
     */
    totalPresence?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDto
     */
    pourcentagePresence?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDto
     */
    pourcentageAbsence?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDto
     */
    pourcentageAbsenceJustifiee?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDto
     */
    totalPercent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDto
     */
    totalAbsence?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDto
     */
    totalAbsenceJustifiee?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeDto
     */
    abandon?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbBulletinApprenantTypeDto
     */
    dateDeliberation2sess?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbBulletinApprenantTypeDto
     */
    idmetier?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FcbBulletinApprenantTypeDto
     */
    degre?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeDto
     */
    dispense?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeDto
     */
    changementCentre?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeDto
     */
    view2Sess?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeDto
     */
    renvoi?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeDto
     */
    isVisibleBulletin?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeDto
     */
    validationDirection1sessBool?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbBulletinApprenantTypeDto
     */
    validationDirection2sessBool?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof FcbBulletinApprenantTypeDto
     */
    validationDirection1sess?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbBulletinApprenantTypeDto
     */
    validationDirection2sess?: Date | null;
}

/**
 * Check if a given object implements the FcbBulletinApprenantTypeDto interface.
 */
export function instanceOfFcbBulletinApprenantTypeDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbBulletinApprenantTypeDtoFromJSON(json: any): FcbBulletinApprenantTypeDto {
    return FcbBulletinApprenantTypeDtoFromJSONTyped(json, false);
}

export function FcbBulletinApprenantTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbBulletinApprenantTypeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idbulletinApprenantType': !exists(json, 'idbulletinApprenantType') ? undefined : json['idbulletinApprenantType'],
        'idapprenant': !exists(json, 'idapprenant') ? undefined : json['idapprenant'],
        'idtypeCours': !exists(json, 'idtypeCours') ? undefined : json['idtypeCours'],
        'anneeScolaire': !exists(json, 'anneeScolaire') ? undefined : json['anneeScolaire'],
        'totalCalcule': !exists(json, 'totalCalcule') ? undefined : json['totalCalcule'],
        'totalDelibe': !exists(json, 'totalDelibe') ? undefined : json['totalDelibe'],
        'total1sess': !exists(json, 'total1sess') ? undefined : json['total1sess'],
        'max1sess': !exists(json, 'max1sess') ? undefined : json['max1sess'],
        'delibe1sess': !exists(json, 'delibe1sess') ? undefined : json['delibe1sess'],
        'totalCalcule2sess': !exists(json, 'totalCalcule2sess') ? undefined : json['totalCalcule2sess'],
        'totalDelibe2sess': !exists(json, 'totalDelibe2sess') ? undefined : json['totalDelibe2sess'],
        'total2sess': !exists(json, 'total2sess') ? undefined : json['total2sess'],
        'max2sess': !exists(json, 'max2sess') ? undefined : json['max2sess'],
        'delibe2sess': !exists(json, 'delibe2sess') ? undefined : json['delibe2sess'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'max1sessSansDispense': !exists(json, 'max1sessSansDispense') ? undefined : json['max1sessSansDispense'],
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationUserName': !exists(json, 'modificationUserName') ? undefined : json['modificationUserName'],
        'iddeliberationType': !exists(json, 'iddeliberationType') ? undefined : json['iddeliberationType'],
        'iddeliberationType2sess': !exists(json, 'iddeliberationType2sess') ? undefined : json['iddeliberationType2sess'],
        'remarqueDelibe': !exists(json, 'remarqueDelibe') ? undefined : json['remarqueDelibe'],
        'remarqueDelibe2sess': !exists(json, 'remarqueDelibe2sess') ? undefined : json['remarqueDelibe2sess'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'dateRupture': !exists(json, 'dateRupture') ? undefined : (json['dateRupture'] === null ? null : new Date(json['dateRupture'])),
        'dateAbandon': !exists(json, 'dateAbandon') ? undefined : (json['dateAbandon'] === null ? null : new Date(json['dateAbandon'])),
        'dateDeliberation': !exists(json, 'dateDeliberation') ? undefined : (json['dateDeliberation'] === null ? null : new Date(json['dateDeliberation'])),
        'totalPresence': !exists(json, 'totalPresence') ? undefined : json['totalPresence'],
        'pourcentagePresence': !exists(json, 'pourcentagePresence') ? undefined : json['pourcentagePresence'],
        'pourcentageAbsence': !exists(json, 'pourcentageAbsence') ? undefined : json['pourcentageAbsence'],
        'pourcentageAbsenceJustifiee': !exists(json, 'pourcentageAbsenceJustifiee') ? undefined : json['pourcentageAbsenceJustifiee'],
        'totalPercent': !exists(json, 'totalPercent') ? undefined : json['totalPercent'],
        'totalAbsence': !exists(json, 'totalAbsence') ? undefined : json['totalAbsence'],
        'totalAbsenceJustifiee': !exists(json, 'totalAbsenceJustifiee') ? undefined : json['totalAbsenceJustifiee'],
        'abandon': !exists(json, 'abandon') ? undefined : json['abandon'],
        'dateDeliberation2sess': !exists(json, 'dateDeliberation2sess') ? undefined : (json['dateDeliberation2sess'] === null ? null : new Date(json['dateDeliberation2sess'])),
        'idmetier': !exists(json, 'idmetier') ? undefined : json['idmetier'],
        'degre': !exists(json, 'degre') ? undefined : json['degre'],
        'dispense': !exists(json, 'dispense') ? undefined : json['dispense'],
        'changementCentre': !exists(json, 'changementCentre') ? undefined : json['changementCentre'],
        'view2Sess': !exists(json, 'view2Sess') ? undefined : json['view2Sess'],
        'renvoi': !exists(json, 'renvoi') ? undefined : json['renvoi'],
        'isVisibleBulletin': !exists(json, 'isVisibleBulletin') ? undefined : json['isVisibleBulletin'],
        'validationDirection1sessBool': !exists(json, 'validationDirection1sessBool') ? undefined : json['validationDirection1sessBool'],
        'validationDirection2sessBool': !exists(json, 'validationDirection2sessBool') ? undefined : json['validationDirection2sessBool'],
        'validationDirection1sess': !exists(json, 'validationDirection1sess') ? undefined : (json['validationDirection1sess'] === null ? null : new Date(json['validationDirection1sess'])),
        'validationDirection2sess': !exists(json, 'validationDirection2sess') ? undefined : (json['validationDirection2sess'] === null ? null : new Date(json['validationDirection2sess'])),
    };
}

export function FcbBulletinApprenantTypeDtoToJSON(value?: FcbBulletinApprenantTypeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idbulletinApprenantType': value.idbulletinApprenantType,
        'idapprenant': value.idapprenant,
        'idtypeCours': value.idtypeCours,
        'anneeScolaire': value.anneeScolaire,
        'totalCalcule': value.totalCalcule,
        'totalDelibe': value.totalDelibe,
        'total1sess': value.total1sess,
        'max1sess': value.max1sess,
        'delibe1sess': value.delibe1sess,
        'totalCalcule2sess': value.totalCalcule2sess,
        'totalDelibe2sess': value.totalDelibe2sess,
        'total2sess': value.total2sess,
        'max2sess': value.max2sess,
        'delibe2sess': value.delibe2sess,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'max1sessSansDispense': value.max1sessSansDispense,
        'modificationUser': value.modificationUser,
        'modificationUserName': value.modificationUserName,
        'iddeliberationType': value.iddeliberationType,
        'iddeliberationType2sess': value.iddeliberationType2sess,
        'remarqueDelibe': value.remarqueDelibe,
        'remarqueDelibe2sess': value.remarqueDelibe2sess,
        'idcentre': value.idcentre,
        'dateRupture': value.dateRupture === undefined ? undefined : (value.dateRupture === null ? null : value.dateRupture.toISOString()),
        'dateAbandon': value.dateAbandon === undefined ? undefined : (value.dateAbandon === null ? null : value.dateAbandon.toISOString()),
        'dateDeliberation': value.dateDeliberation === undefined ? undefined : (value.dateDeliberation === null ? null : value.dateDeliberation.toISOString()),
        'totalPresence': value.totalPresence,
        'pourcentagePresence': value.pourcentagePresence,
        'pourcentageAbsence': value.pourcentageAbsence,
        'pourcentageAbsenceJustifiee': value.pourcentageAbsenceJustifiee,
        'totalPercent': value.totalPercent,
        'totalAbsence': value.totalAbsence,
        'totalAbsenceJustifiee': value.totalAbsenceJustifiee,
        'abandon': value.abandon,
        'dateDeliberation2sess': value.dateDeliberation2sess === undefined ? undefined : (value.dateDeliberation2sess === null ? null : value.dateDeliberation2sess.toISOString()),
        'idmetier': value.idmetier,
        'degre': value.degre,
        'dispense': value.dispense,
        'changementCentre': value.changementCentre,
        'view2Sess': value.view2Sess,
        'renvoi': value.renvoi,
        'isVisibleBulletin': value.isVisibleBulletin,
        'validationDirection1sessBool': value.validationDirection1sessBool,
        'validationDirection2sessBool': value.validationDirection2sessBool,
        'validationDirection1sess': value.validationDirection1sess === undefined ? undefined : (value.validationDirection1sess === null ? null : value.validationDirection1sess.toISOString()),
        'validationDirection2sess': value.validationDirection2sess === undefined ? undefined : (value.validationDirection2sess === null ? null : value.validationDirection2sess.toISOString()),
    };
}

