import { DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";

import { ExportGapClassesApi, RechercheExportGapSearch } from "../../../../api";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface IExportGapClassesListProps {
  idExport: number;
}

export const ExportGapClassesList: React.FunctionComponent<IExportGapClassesListProps> = ({ idExport }) => {
  const { t } = useTl();
  const api = useApiService(ExportGapClassesApi);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [5],
    pageSize: 5,
    sortKeys: { Nom: "ASC" }
  });

  const searchFunction = React.useCallback(
    (sObj?: RechercheExportGapSearch) => {
      sObj.idexport = idExport;
      return api.exportGapClassesBaseSearch({ RechercheExportGapSearch: sObj });
    },
    [api, idExport]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.Classe),
        fieldName: "codeClasse"
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "classe"
      },
      {
        header: () => t(ETLCodes.HeureS1),
        fieldName: "nbheuresSemestre1"
      },
      {
        header: () => t(ETLCodes.HeureS2),
        fieldName: "nbheuresSemestre2"
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      }
    ],
    [t]
  );

  return (
    <>
      {idExport > 0 && (
        <FieldSet title={`${t(ETLCodes.Classes)}`}>
          <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
        </FieldSet>
      )}
    </>
  );
};
