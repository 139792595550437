/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AcquisIfapmeGridDto } from './AcquisIfapmeGridDto';
import {
    AcquisIfapmeGridDtoFromJSON,
    AcquisIfapmeGridDtoFromJSONTyped,
    AcquisIfapmeGridDtoToJSON,
} from './AcquisIfapmeGridDto';
import type { IAggregateResult } from './IAggregateResult';
import {
    IAggregateResultFromJSON,
    IAggregateResultFromJSONTyped,
    IAggregateResultToJSON,
} from './IAggregateResult';

/**
 * 
 * @export
 * @interface AcquisIfapmeGridDtoPaginatedResults
 */
export interface AcquisIfapmeGridDtoPaginatedResults {
    /**
     * 
     * @type {number}
     * @memberof AcquisIfapmeGridDtoPaginatedResults
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<AcquisIfapmeGridDto>}
     * @memberof AcquisIfapmeGridDtoPaginatedResults
     */
    results?: Array<AcquisIfapmeGridDto> | null;
    /**
     * 
     * @type {Array<IAggregateResult>}
     * @memberof AcquisIfapmeGridDtoPaginatedResults
     */
    aggregateResults?: Array<IAggregateResult> | null;
}

/**
 * Check if a given object implements the AcquisIfapmeGridDtoPaginatedResults interface.
 */
export function instanceOfAcquisIfapmeGridDtoPaginatedResults(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AcquisIfapmeGridDtoPaginatedResultsFromJSON(json: any): AcquisIfapmeGridDtoPaginatedResults {
    return AcquisIfapmeGridDtoPaginatedResultsFromJSONTyped(json, false);
}

export function AcquisIfapmeGridDtoPaginatedResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcquisIfapmeGridDtoPaginatedResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'results': !exists(json, 'results') ? undefined : (json['results'] === null ? null : (json['results'] as Array<any>).map(AcquisIfapmeGridDtoFromJSON)),
        'aggregateResults': !exists(json, 'aggregateResults') ? undefined : (json['aggregateResults'] === null ? null : (json['aggregateResults'] as Array<any>).map(IAggregateResultFromJSON)),
    };
}

export function AcquisIfapmeGridDtoPaginatedResultsToJSON(value?: AcquisIfapmeGridDtoPaginatedResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'results': value.results === undefined ? undefined : (value.results === null ? null : (value.results as Array<any>).map(AcquisIfapmeGridDtoToJSON)),
        'aggregateResults': value.aggregateResults === undefined ? undefined : (value.aggregateResults === null ? null : (value.aggregateResults as Array<any>).map(IAggregateResultToJSON)),
    };
}

