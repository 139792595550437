/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContratSuspensionDto } from './ContratSuspensionDto';
import {
    ContratSuspensionDtoFromJSON,
    ContratSuspensionDtoFromJSONTyped,
    ContratSuspensionDtoToJSON,
} from './ContratSuspensionDto';
import type { IAggregateResult } from './IAggregateResult';
import {
    IAggregateResultFromJSON,
    IAggregateResultFromJSONTyped,
    IAggregateResultToJSON,
} from './IAggregateResult';

/**
 * 
 * @export
 * @interface ContratSuspensionDtoPaginatedResults
 */
export interface ContratSuspensionDtoPaginatedResults {
    /**
     * 
     * @type {number}
     * @memberof ContratSuspensionDtoPaginatedResults
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<ContratSuspensionDto>}
     * @memberof ContratSuspensionDtoPaginatedResults
     */
    results?: Array<ContratSuspensionDto> | null;
    /**
     * 
     * @type {Array<IAggregateResult>}
     * @memberof ContratSuspensionDtoPaginatedResults
     */
    aggregateResults?: Array<IAggregateResult> | null;
}

/**
 * Check if a given object implements the ContratSuspensionDtoPaginatedResults interface.
 */
export function instanceOfContratSuspensionDtoPaginatedResults(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContratSuspensionDtoPaginatedResultsFromJSON(json: any): ContratSuspensionDtoPaginatedResults {
    return ContratSuspensionDtoPaginatedResultsFromJSONTyped(json, false);
}

export function ContratSuspensionDtoPaginatedResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContratSuspensionDtoPaginatedResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': !exists(json, 'totalCount') ? undefined : json['totalCount'],
        'results': !exists(json, 'results') ? undefined : (json['results'] === null ? null : (json['results'] as Array<any>).map(ContratSuspensionDtoFromJSON)),
        'aggregateResults': !exists(json, 'aggregateResults') ? undefined : (json['aggregateResults'] === null ? null : (json['aggregateResults'] as Array<any>).map(IAggregateResultFromJSON)),
    };
}

export function ContratSuspensionDtoPaginatedResultsToJSON(value?: ContratSuspensionDtoPaginatedResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'results': value.results === undefined ? undefined : (value.results === null ? null : (value.results as Array<any>).map(ContratSuspensionDtoToJSON)),
        'aggregateResults': value.aggregateResults === undefined ? undefined : (value.aggregateResults === null ? null : (value.aggregateResults as Array<any>).map(IAggregateResultToJSON)),
    };
}

