import * as React from "react";
import { useRouteMatch, Switch, Route } from "react-router";
import { ApprenantRecherchePatronDetailPage } from "./ApprenantRecherchePatronDetailPage";
import { ApprenantRecherchePatronListPage } from "./ApprenantRecherchePatronListPage";

interface IApprenantRecherchePatronProps {
  apprenantId: number;
}

export const ApprenantRecherchePatron: React.FunctionComponent<IApprenantRecherchePatronProps> = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`} component={ApprenantRecherchePatronListPage} exact />
      <Route path={`${match.path}/:idRecherchePatron/:state`} component={ApprenantRecherchePatronDetailPage} />
    </Switch>
  );
};
