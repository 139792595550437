/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DemandeFormationCreateDto,
  DemandeFormationEditDto,
  DemandeFormationGridDtoPaginatedResults,
  DemandeFormationSearch,
  FilterCriteriaInfo,
  ReferentialItemDto,
} from '../models/index';
import {
    DemandeFormationCreateDtoFromJSON,
    DemandeFormationCreateDtoToJSON,
    DemandeFormationEditDtoFromJSON,
    DemandeFormationEditDtoToJSON,
    DemandeFormationGridDtoPaginatedResultsFromJSON,
    DemandeFormationGridDtoPaginatedResultsToJSON,
    DemandeFormationSearchFromJSON,
    DemandeFormationSearchToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    ReferentialItemDtoFromJSON,
    ReferentialItemDtoToJSON,
} from '../models/index';

export interface DemandeFormationCreaBaseSearchRequest {
    DemandeFormationSearch?: DemandeFormationSearch;
}

export interface DemandeFormationCreaCreateRequest {
    DemandeFormationCreateDto?: DemandeFormationCreateDto;
}

export interface DemandeFormationCreaGetDemandeFormationRequest {
    idDemande?: number;
}

export interface DemandeFormationCreaGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface DemandeFormationCreaGetSelectItemsRequest {
    searchField: string;
}

export interface DemandeFormationCreaSaveRequest {
    DemandeFormationEditDto?: DemandeFormationEditDto;
}

/**
 * 
 */
export class DemandeFormationCreaApi extends runtime.BaseAPI {

    /**
     */
    async demandeFormationCreaBaseSearchRaw(requestParameters: DemandeFormationCreaBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DemandeFormationGridDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DemandeFormationCrea/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DemandeFormationSearchToJSON(requestParameters.DemandeFormationSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DemandeFormationGridDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async demandeFormationCreaBaseSearch(requestParameters: DemandeFormationCreaBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DemandeFormationGridDtoPaginatedResults> {
        const response = await this.demandeFormationCreaBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async demandeFormationCreaCreateRaw(requestParameters: DemandeFormationCreaCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DemandeFormationCreateDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DemandeFormationCrea/CreateDemandeFormations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DemandeFormationCreateDtoToJSON(requestParameters.DemandeFormationCreateDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DemandeFormationCreateDtoFromJSON(jsonValue));
    }

    /**
     */
    async demandeFormationCreaCreate(requestParameters: DemandeFormationCreaCreateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DemandeFormationCreateDto> {
        const response = await this.demandeFormationCreaCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async demandeFormationCreaGetDemandeFormationRaw(requestParameters: DemandeFormationCreaGetDemandeFormationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DemandeFormationEditDto>> {
        const queryParameters: any = {};

        if (requestParameters.idDemande !== undefined) {
            queryParameters['idDemande'] = requestParameters.idDemande;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DemandeFormationCrea/GetDemandeFormation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DemandeFormationEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async demandeFormationCreaGetDemandeFormation(requestParameters: DemandeFormationCreaGetDemandeFormationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DemandeFormationEditDto> {
        const response = await this.demandeFormationCreaGetDemandeFormationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async demandeFormationCreaGetSearchCriteriasRaw(requestParameters: DemandeFormationCreaGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DemandeFormationCrea/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async demandeFormationCreaGetSearchCriterias(requestParameters: DemandeFormationCreaGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.demandeFormationCreaGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async demandeFormationCreaGetSelectItemsRaw(requestParameters: DemandeFormationCreaGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ReferentialItemDto>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling demandeFormationCreaGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DemandeFormationCrea/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferentialItemDtoFromJSON));
    }

    /**
     */
    async demandeFormationCreaGetSelectItems(requestParameters: DemandeFormationCreaGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ReferentialItemDto>> {
        const response = await this.demandeFormationCreaGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async demandeFormationCreaSaveRaw(requestParameters: DemandeFormationCreaSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DemandeFormationEditDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/DemandeFormationCrea/SaveDemandeFormation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DemandeFormationEditDtoToJSON(requestParameters.DemandeFormationEditDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DemandeFormationEditDtoFromJSON(jsonValue));
    }

    /**
     */
    async demandeFormationCreaSave(requestParameters: DemandeFormationCreaSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DemandeFormationEditDto> {
        const response = await this.demandeFormationCreaSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
