/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdresseDto } from './AdresseDto';
import {
    AdresseDtoFromJSON,
    AdresseDtoFromJSONTyped,
    AdresseDtoToJSON,
} from './AdresseDto';

/**
 * 
 * @export
 * @interface RepresentantSelectorGridDto
 */
export interface RepresentantSelectorGridDto {
    /**
     * 
     * @type {number}
     * @memberof RepresentantSelectorGridDto
     */
    idrepresentant?: number;
    /**
     * 
     * @type {number}
     * @memberof RepresentantSelectorGridDto
     */
    idpersonne?: number;
    /**
     * 
     * @type {string}
     * @memberof RepresentantSelectorGridDto
     */
    nom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepresentantSelectorGridDto
     */
    prenom?: string | null;
    /**
     * 
     * @type {AdresseDto}
     * @memberof RepresentantSelectorGridDto
     */
    adresseRepresentant?: AdresseDto;
    /**
     * 
     * @type {string}
     * @memberof RepresentantSelectorGridDto
     */
    readonly adresse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepresentantSelectorGridDto
     */
    readonly codePostal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepresentantSelectorGridDto
     */
    readonly localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RepresentantSelectorGridDto
     */
    nationalite?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof RepresentantSelectorGridDto
     */
    dateNaissance?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof RepresentantSelectorGridDto
     */
    numeroNational?: string | null;
}

/**
 * Check if a given object implements the RepresentantSelectorGridDto interface.
 */
export function instanceOfRepresentantSelectorGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RepresentantSelectorGridDtoFromJSON(json: any): RepresentantSelectorGridDto {
    return RepresentantSelectorGridDtoFromJSONTyped(json, false);
}

export function RepresentantSelectorGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepresentantSelectorGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idrepresentant': !exists(json, 'idrepresentant') ? undefined : json['idrepresentant'],
        'idpersonne': !exists(json, 'idpersonne') ? undefined : json['idpersonne'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'prenom': !exists(json, 'prenom') ? undefined : json['prenom'],
        'adresseRepresentant': !exists(json, 'adresseRepresentant') ? undefined : AdresseDtoFromJSON(json['adresseRepresentant']),
        'adresse': !exists(json, 'adresse') ? undefined : json['adresse'],
        'codePostal': !exists(json, 'codePostal') ? undefined : json['codePostal'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'nationalite': !exists(json, 'nationalite') ? undefined : json['nationalite'],
        'dateNaissance': !exists(json, 'dateNaissance') ? undefined : (json['dateNaissance'] === null ? null : new Date(json['dateNaissance'])),
        'numeroNational': !exists(json, 'numeroNational') ? undefined : json['numeroNational'],
    };
}

export function RepresentantSelectorGridDtoToJSON(value?: RepresentantSelectorGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idrepresentant': value.idrepresentant,
        'idpersonne': value.idpersonne,
        'nom': value.nom,
        'prenom': value.prenom,
        'adresseRepresentant': AdresseDtoToJSON(value.adresseRepresentant),
        'nationalite': value.nationalite,
        'dateNaissance': value.dateNaissance === undefined ? undefined : (value.dateNaissance === null ? null : value.dateNaissance.toISOString()),
        'numeroNational': value.numeroNational,
    };
}

