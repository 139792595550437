/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EStatutContrat {
    EnCours = 'EnCours',
    Termine = 'Termine',
    Rompu = 'Rompu',
    Suspendu = 'Suspendu',
    NonDemarre = 'NonDemarre',
    SansSuite = 'SansSuite',
    NON_AGREE = 'NON_AGREE',
    RET = 'RET',
    TRANSFERT_CEFA = 'TRANSFERT_CEFA'
}


export function EStatutContratFromJSON(json: any): EStatutContrat {
    return EStatutContratFromJSONTyped(json, false);
}

export function EStatutContratFromJSONTyped(json: any, ignoreDiscriminator: boolean): EStatutContrat {
    return json as EStatutContrat;
}

export function EStatutContratToJSON(value?: EStatutContrat | null): any {
    return value as any;
}

