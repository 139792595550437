import { Tab, Tabs } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ReportApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { PageBase } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";
import { RapportForm } from "./RapportForm";

export interface IRapportItemPageProps {}

export const RapportItemPage: React.FunctionComponent<IRapportItemPageProps> = () => {
  const { name } = useParams<{ name: string }>();
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ReportApi);
  const [displayName] = useApiEffect(() => api.reportGetDisplayName({ name: name }), [name]);

  return (
    <PageBase breadCrumbs={[{ text: t(ETLCodes.Rapport) }, { text: displayName }]}>
      <Tabs
        id="TabsRapport"
        onChange={() => history.push(`${ERoutes.report}/${name}`)}
        selectedTabId="detail"
        renderActiveTabPanelOnly
      >
        <Tab id="detail" title={t(ETLCodes.Detail)} panel={<RapportForm selected={name} />} />
      </Tabs>
    </PageBase>
  );
};
