import { Button, Checkbox, Colors, Icon, Intent, Menu, MenuItem, Popover, Tooltip } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ButtonContainer, DataTable, IDataTableColumn, showSuccess, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import {
  DemandeInscriptionGridDto,
  EStatutDemande,
  ParcoursFormationApi,
  ParcoursInterneSearch
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { CircleColumn, CustomBulletList, EditButton, ViewButton } from "../../../../../components";
import { useDialog, useEventsContext } from "../../../../../contexts";
import { useApiService, useManageError, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

const LegendHeaderContainer = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

const PopoverContent = styled.div`
  padding: 0.5rem;
`;

export interface IParcoursInterneListProps {
  idParcoursFormation: number;
  hasButtons: boolean;
  isSortieEnCours: boolean;
  refetchFunc: () => void;
  setSelectedDemandeIds?: (value: React.SetStateAction<number[]>) => void;
  setCanSendCentre: (value: boolean) => void;
}

export const ParcoursInterneList: React.FunctionComponent<IParcoursInterneListProps> = ({
  idParcoursFormation,
  hasButtons,
  isSortieEnCours,
  refetchFunc = () => {},
  setSelectedDemandeIds = () => {},
  setCanSendCentre
}) => {
  const { t } = useTl();
  const history = useHistory();
  const { showDialogPromise } = useDialog();
  const { manageError } = useManageError();
  const api = useApiService(ParcoursFormationApi);
  const { id, idParcoursFormation: oldIdParcoursFormation } = useParams<{
    id: string;
    state: string;
    idParcoursFormation: string;
  }>();

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: false,
    enableFilter: false,
    pageSize: 999999,
    sortKeys: { anneeScolaire: "DESC" }
  });

  const { data } = tableState;

  const searchFunc = React.useCallback(
    (sObj?: ParcoursInterneSearch) => {
      sObj.idParcoursFormation = idParcoursFormation;
      return api.parcoursFormationSearchParcoursInterne({ ParcoursInterneSearch: sObj });
    },
    [api, idParcoursFormation]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction: searchFunc,
    tableState,
    initialSearch: true
  });

  const OnRefreshSearchTablesEvent = React.useCallback(() => {
    search();
  }, [search]);
  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();
  React.useEffect(() => {
    subscribeToEvent("SEARCH_TABLE_REFRESH", OnRefreshSearchTablesEvent);
    return () => unsubscribeEvent("SEARCH_TABLE_REFRESH", OnRefreshSearchTablesEvent);
  }, [OnRefreshSearchTablesEvent, subscribeToEvent, unsubscribeEvent]);

  const getStatutDemandeCodeIdFunc = React.useCallback(async () => {
    return await api.parcoursFormationGetStatutDemandeCodeId();
  }, [api]);

  const { data: statutDemandeCode } = useQuery("StatutDemandeCode", getStatutDemandeCodeIdFunc);

  const allIds = React.useMemo(() => {
    return data
      ?.filter(
        d =>
          ![
            EStatutDemande.I,
            EStatutDemande.E,
            EStatutDemande.EA,
            EStatutDemande.CC,
            EStatutDemande.CE,
            EStatutDemande.ER,
            EStatutDemande.MS,
            EStatutDemande.SU
          ].includes(statutDemandeCode?.find(sdc => sdc.idstatutDemande === d.idstatutDemande)?.code) &&
          d.stade === "AP" &&
          !d.centreExterieur
      )
      .map(d => d.iddemandeInscription);
  }, [data, statutDemandeCode]);
  const [selectedIDs, setSelectedIDs] = React.useState<number[]>([]);

  React.useEffect(() => {
    if (data && selectedIDs.length > 0) {
      setCanSendCentre(
        (data as DemandeInscriptionGridDto[])
          .filter(d => selectedIDs.includes(d.iddemandeInscription))
          .every(d => d.stade !== "CE")
      );
    }
  }, [data, selectedIDs, setCanSendCentre]);

  const selectAll = React.useCallback(() => {
    if (allIds.length === selectedIDs.length) {
      setSelectedIDs([]);
      setSelectedDemandeIds([]);
    } else {
      setSelectedIDs(allIds);
      setSelectedDemandeIds(allIds);
    }
  }, [allIds, selectedIDs.length, setSelectedDemandeIds]);

  const toggleSelection = React.useCallback(
    (item: DemandeInscriptionGridDto) => {
      if (!selectedIDs.includes(item.iddemandeInscription)) {
        setSelectedIDs(ids => [...ids, item.iddemandeInscription]);
        setSelectedDemandeIds(ids => [...ids, item.iddemandeInscription]);
      } else {
        setSelectedIDs(ids => ids.filter(id => id !== item.iddemandeInscription));
        setSelectedDemandeIds(ids => ids.filter(id => id !== item.iddemandeInscription));
      }
    },
    [selectedIDs, setSelectedDemandeIds]
  );

  const defineColor = React.useCallback(
    (idstatutDemande: number) => {
      const codeStatut = statutDemandeCode?.find(sdc => sdc.idstatutDemande === idstatutDemande)?.code;

      switch (codeStatut) {
        case EStatutDemande.I:
        case EStatutDemande.V:
          return Colors.GREEN3;
        case EStatutDemande.EA:
          return Colors.RED3;
        case EStatutDemande.EV:
          return Colors.ORANGE3;
        case EStatutDemande.E:
          return Colors.GOLD5;
        case EStatutDemande.P:
          return Colors.BLUE3;
        case EStatutDemande.AE:
          return Colors.GRAY1;
        case EStatutDemande.EC:
          return Colors.GRAY3;
        case EStatutDemande.CC:
          return Colors.RED3;
        case EStatutDemande.CE:
          return Colors.RED3;
        case EStatutDemande.ER:
          return Colors.RED3;
        case EStatutDemande.MS:
          return Colors.VIOLET4;
        case EStatutDemande.DS:
          return Colors.RED3;
        case EStatutDemande.SU:
          return Colors.RED3;
        case EStatutDemande.RC:
          return Colors.RED3;
        default:
          return Colors.GRAY3;
      }
    },
    [statutDemandeCode]
  );

  const defineTextCode = React.useCallback(
    (idstatutDemande: number) => {
      const codeStatut = statutDemandeCode?.find(sdc => sdc.idstatutDemande === idstatutDemande)?.code;

      switch (codeStatut) {
        case EStatutDemande.I:
          return t(ETLCodes.Inscrit);
        case EStatutDemande.V:
          return t(ETLCodes.Validee);
        case EStatutDemande.E:
          return t(ETLCodes.Envoyee);
        case EStatutDemande.EA:
          return t(ETLCodes.EnAbandon);
        case EStatutDemande.EV:
          return t(ETLCodes.EnValidation);
        case EStatutDemande.P:
          return t(ETLCodes.BrouillonPret);
        case EStatutDemande.AE:
          return t(ETLCodes.Brouillon);
        case EStatutDemande.EC:
          return t(ETLCodes.EnCorrection);
        case EStatutDemande.CC:
          return t(ETLCodes.ChangementClasse);
        case EStatutDemande.CE:
          return t(ETLCodes.ChangementCentre);
        case EStatutDemande.ER:
          return t(ETLCodes.EnRenvoi);
        case EStatutDemande.MS:
          return t(ETLCodes.MisEnSortie);
        case EStatutDemande.DS:
          return t(ETLCodes.DemandeSuppression);
        case EStatutDemande.SU:
          return t(ETLCodes.Supprime);
        case EStatutDemande.RC:
          return t(ETLCodes.RefuseCentres);
        default:
          return "";
      }
    },
    [statutDemandeCode, t]
  );

  const BulletList = React.useCallback(() => {
    return (
      <LegendHeaderContainer>
        <Tooltip
          content={
            <CustomBulletList
              title={t(ETLCodes.StatutDemande)}
              items={[
                {
                  text: t(ETLCodes.Inscrit),
                  color: Colors.GREEN3
                },
                {
                  text: t(ETLCodes.Validee),
                  color: Colors.GREEN3
                },
                {
                  text: t(ETLCodes.EnAbandon),
                  color: Colors.RED3
                },
                {
                  text: t(ETLCodes.RefuseCentres),
                  color: Colors.RED3
                },
                {
                  text: t(ETLCodes.ChangementClasse),
                  color: Colors.RED3
                },
                {
                  text: t(ETLCodes.EnRenvoi),
                  color: Colors.RED3
                },
                {
                  text: t(ETLCodes.MisEnSortie),
                  color: Colors.RED3
                },
                {
                  text: t(ETLCodes.Supprime),
                  color: Colors.RED3
                },
                {
                  text: t(ETLCodes.DemandeSuppression),
                  color: Colors.RED3
                },
                {
                  text: t(ETLCodes.EnValidation),
                  color: Colors.ORANGE3
                },
                {
                  text: t(ETLCodes.Envoyee),
                  color: Colors.GOLD5
                },
                {
                  text: t(ETLCodes.BrouillonPret),
                  color: Colors.BLUE3
                },
                {
                  text: t(ETLCodes.Brouillon),
                  color: Colors.GRAY1
                },
                {
                  text: t(ETLCodes.EnCorrection),
                  color: Colors.GRAY3
                }
              ]}
            />
          }
          popoverClassName="bullet-list-popover"
          position="right"
        >
          <Icon icon="info-sign" style={{ cursor: "pointer" }} />
        </Tooltip>
      </LegendHeaderContainer>
    );
  }, [t]);

  const [deleting, setDeleting] = React.useState(0);
  const deleteFunc = React.useCallback(
    async (row: DemandeInscriptionGridDto) => {
      const result = await showDialogPromise({
        message: t(ETLCodes.CancelConfirmationMessage),
        title: t(ETLCodes.CancelDialogTitle)
      });
      if (result === "yes") {
        try {
          setDeleting(row.iddemandeInscription);
          await api.parcoursFormationCancelDemandeInscription({ idDemande: row.iddemandeInscription });
          showSuccess(t(ETLCodes.DeleteSuccess));
        } catch (error) {
          manageError(error);
        }
        setDeleting(0);
        let resultCreate = "no";
        if (!isSortieEnCours)
          resultCreate = await showDialogPromise({
            message: t(ETLCodes.CreateNewDemandeMessage),
            title: t(ETLCodes.CreateNewDemande)
          });
        if (resultCreate === "yes") {
          history.push(`${ERoutes.apprenant}/${id}/parcoursFormation/edit/${idParcoursFormation}/demandeInscription/0`);
        } else {
          refetchFunc();
        }
      }
    },
    [api, history, id, idParcoursFormation, isSortieEnCours, manageError, refetchFunc, showDialogPromise, t]
  );

  const [rowLoading, setRowLoading] = React.useState(0);
  const changeStatut = React.useCallback(
    async (codeStatut: string, iddemande: number) => {
      setRowLoading(iddemande);
      await api.parcoursFormationChangeStatutMultipleDemande({
        ChangeStatutMultipleDto: {
          codeStatut,
          demandes: [iddemande]
        }
      });
      setRowLoading(0);
      refetchFunc();
    },
    [api, refetchFunc]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "checkboxes",
        autoFitContent: true,
        header: () =>
          !oldIdParcoursFormation && hasButtons ? (
            <Checkbox checked={allIds?.length === selectedIDs?.length} onChange={selectAll} />
          ) : (
            <></>
          ),
        render: (item: DemandeInscriptionGridDto) =>
          allIds?.includes(item.iddemandeInscription) &&
          !oldIdParcoursFormation &&
          hasButtons &&
          item.stade === "AP" &&
          !item.centreExterieur ? (
            <div style={{ marginTop: "6px" }}>
              <Checkbox
                checked={selectedIDs?.includes(item.iddemandeInscription)}
                onChange={e => {
                  toggleSelection(item);
                }}
              />
            </div>
          ) : (
            <></>
          )
      },
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: DemandeInscriptionGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() => {
                history.push(
                  `${ERoutes.apprenant}/${id}/parcoursFormation/view/${idParcoursFormation}/demandeInscription/${row.iddemandeInscription}`
                );
              }}
              loading={row.iddemandeInscription === rowLoading}
            />
            <EditButton
              minimal={true}
              onClick={() => {
                history.push(
                  `${ERoutes.apprenant}/${id}/parcoursFormation/edit/${idParcoursFormation}/demandeInscription/${row.iddemandeInscription}`
                );
              }}
              disabled={!row.editable}
              loading={row.iddemandeInscription === rowLoading}
            />
            <Popover
              position="bottom"
              content={
                <PopoverContent>
                  <Menu>
                    {[EStatutDemande.EC, EStatutDemande.P].includes(
                      statutDemandeCode?.find(sdc => sdc.idstatutDemande === row.idstatutDemande)?.code
                    ) && (
                      <MenuItem
                        icon={IconNames.TickCircle}
                        text={t(ETLCodes.DemanderLaValidation)}
                        intent={Intent.PRIMARY}
                        onClick={() => changeStatut(EStatutDemande.EV, row.iddemandeInscription)}
                      />
                    )}
                    {!row.centreExterieur &&
                      row.stade !== "CE" &&
                      [EStatutDemande.P].includes(
                        statutDemandeCode?.find(sdc => sdc.idstatutDemande === row.idstatutDemande)?.code
                      ) && (
                        <MenuItem
                          icon={IconNames.ENVELOPE}
                          text={t(ETLCodes.EnvoyerAuxCentres)}
                          intent={Intent.PRIMARY}
                          onClick={() => changeStatut(EStatutDemande.E, row.iddemandeInscription)}
                        />
                      )}
                    {![EStatutDemande.EA, EStatutDemande.SU, EStatutDemande.ER, EStatutDemande.DS].includes(
                      statutDemandeCode?.find(sdc => sdc.idstatutDemande === row.idstatutDemande)?.code
                    ) && (
                      <MenuItem
                        icon={
                          statutDemandeCode?.find(sdc => sdc.idstatutDemande === row.idstatutDemande)?.code ===
                          EStatutDemande.I
                            ? IconNames.Cross
                            : IconNames.Trash
                        }
                        text={t(
                          statutDemandeCode?.find(sdc => sdc.idstatutDemande === row.idstatutDemande)?.code ===
                            EStatutDemande.I
                            ? ETLCodes.DemanderSuppression
                            : ETLCodes.Supprimer
                        )}
                        onClick={() => deleteFunc(row)}
                        intent={Intent.PRIMARY}
                      />
                    )}
                  </Menu>
                </PopoverContent>
              }
            >
              <Button
                icon={IconNames.CHEVRON_DOWN}
                minimal={true}
                loading={row.iddemandeInscription === deleting || row.iddemandeInscription === rowLoading}
                disabled={!hasButtons}
              ></Button>
            </Popover>
          </ButtonContainer>
        )
      },
      {
        disableSorting: true,
        autoFitContent: true,
        alignment: "center",
        header: () => <BulletList />,
        fieldName: "statutDemande",
        render: (row: DemandeInscriptionGridDto) =>
          row.idstatutDemande && (
            <div style={{ marginTop: "6px" }}>
              <CircleColumn color={defineColor(row.idstatutDemande)} tooltip={defineTextCode(row.idstatutDemande)} />
            </div>
          )
      },
      {
        header: () => t(ETLCodes.Metier),
        fieldName: "metier"
      },
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "anneeScolaire"
      },
      {
        header: () => t(ETLCodes.TypeCours),
        fieldName: "typeCoursLibelle"
      },
      {
        header: () => t(ETLCodes.Degre),
        fieldName: "degre"
      },
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "centre"
      },
      {
        header: () => t(ETLCodes.Classe),
        fieldName: "nomClasse"
      },
      {
        header: () => t(ETLCodes.CodeClasse),
        fieldName: "classe"
      },
      {
        header: () => t(ETLCodes.StatutInscription),
        fieldName: "statutInscription"
      }
    ],
    [
      oldIdParcoursFormation,
      hasButtons,
      allIds,
      selectedIDs,
      selectAll,
      toggleSelection,
      rowLoading,
      t,
      statutDemandeCode,
      deleting,
      history,
      id,
      idParcoursFormation,
      changeStatut,
      deleteFunc,
      BulletList,
      defineColor,
      defineTextCode
    ]
  );

  return <DataTable dateFormat="dd/MM/yyyy" tableState={tableState} columns={columns} loading={loading} />;
};
