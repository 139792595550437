/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ERapportHopeType } from './ERapportHopeType';
import {
    ERapportHopeTypeFromJSON,
    ERapportHopeTypeFromJSONTyped,
    ERapportHopeTypeToJSON,
} from './ERapportHopeType';
import type { RapportHopeParameterDto } from './RapportHopeParameterDto';
import {
    RapportHopeParameterDtoFromJSON,
    RapportHopeParameterDtoFromJSONTyped,
    RapportHopeParameterDtoToJSON,
} from './RapportHopeParameterDto';

/**
 * 
 * @export
 * @interface RapportHopeForViewDto
 */
export interface RapportHopeForViewDto {
    /**
     * 
     * @type {number}
     * @memberof RapportHopeForViewDto
     */
    idrapportHope?: number;
    /**
     * 
     * @type {string}
     * @memberof RapportHopeForViewDto
     */
    nom?: string | null;
    /**
     * 
     * @type {ERapportHopeType}
     * @memberof RapportHopeForViewDto
     */
    type?: ERapportHopeType;
    /**
     * 
     * @type {string}
     * @memberof RapportHopeForViewDto
     */
    path?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RapportHopeForViewDto
     */
    format?: string | null;
    /**
     * 
     * @type {Array<RapportHopeParameterDto>}
     * @memberof RapportHopeForViewDto
     */
    parameters?: Array<RapportHopeParameterDto> | null;
}

/**
 * Check if a given object implements the RapportHopeForViewDto interface.
 */
export function instanceOfRapportHopeForViewDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RapportHopeForViewDtoFromJSON(json: any): RapportHopeForViewDto {
    return RapportHopeForViewDtoFromJSONTyped(json, false);
}

export function RapportHopeForViewDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RapportHopeForViewDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idrapportHope': !exists(json, 'idrapportHope') ? undefined : json['idrapportHope'],
        'nom': !exists(json, 'nom') ? undefined : json['nom'],
        'type': !exists(json, 'type') ? undefined : ERapportHopeTypeFromJSON(json['type']),
        'path': !exists(json, 'path') ? undefined : json['path'],
        'format': !exists(json, 'format') ? undefined : json['format'],
        'parameters': !exists(json, 'parameters') ? undefined : (json['parameters'] === null ? null : (json['parameters'] as Array<any>).map(RapportHopeParameterDtoFromJSON)),
    };
}

export function RapportHopeForViewDtoToJSON(value?: RapportHopeForViewDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idrapportHope': value.idrapportHope,
        'nom': value.nom,
        'type': ERapportHopeTypeToJSON(value.type),
        'path': value.path,
        'format': value.format,
        'parameters': value.parameters === undefined ? undefined : (value.parameters === null ? null : (value.parameters as Array<any>).map(RapportHopeParameterDtoToJSON)),
    };
}

