export function getColor() {
  return (
    "hsla(" +
    360 * Math.random() +
    "," +
    (50 + 50 * Math.random()) +
    "%," +
    (85 + 10 * Math.random()) +
    "%," +
    (70 + 30 * Math.random()) +
    "%)"
  );
}
