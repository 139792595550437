/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbFinancementClasseDto
 */
export interface FcbFinancementClasseDto {
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseDto
     */
    idfinancementClasse?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseDto
     */
    idclasse?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseDto
     */
    idenveloppeProjetFinancement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseDto
     */
    idprojetFinancement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseDto
     */
    idprojetCofinancement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseDto
     */
    heureS1?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseDto
     */
    heureS2?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseDto
     */
    totalLoc?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFinancementClasseDto
     */
    localise?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFinancementClasseDto
     */
    agree?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseDto
     */
    idreferentiel?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseDto
     */
    heureS1Agrement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseDto
     */
    heureS2Agrement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseDto
     */
    totalAgrement?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFinancementClasseDto
     */
    bloqueHeuresAgrement?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseDto
     */
    reelHoursS1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseDto
     */
    reelHoursS2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFinancementClasseDto
     */
    reelTotalAgrement?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFinancementClasseDto
     */
    localisationBloquee?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFinancementClasseDto
     */
    agrementBloquee?: boolean | null;
}

/**
 * Check if a given object implements the FcbFinancementClasseDto interface.
 */
export function instanceOfFcbFinancementClasseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbFinancementClasseDtoFromJSON(json: any): FcbFinancementClasseDto {
    return FcbFinancementClasseDtoFromJSONTyped(json, false);
}

export function FcbFinancementClasseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbFinancementClasseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idfinancementClasse': !exists(json, 'idfinancementClasse') ? undefined : json['idfinancementClasse'],
        'idclasse': !exists(json, 'idclasse') ? undefined : json['idclasse'],
        'idenveloppeProjetFinancement': !exists(json, 'idenveloppeProjetFinancement') ? undefined : json['idenveloppeProjetFinancement'],
        'idprojetFinancement': !exists(json, 'idprojetFinancement') ? undefined : json['idprojetFinancement'],
        'idprojetCofinancement': !exists(json, 'idprojetCofinancement') ? undefined : json['idprojetCofinancement'],
        'heureS1': !exists(json, 'heureS1') ? undefined : json['heureS1'],
        'heureS2': !exists(json, 'heureS2') ? undefined : json['heureS2'],
        'totalLoc': !exists(json, 'totalLoc') ? undefined : json['totalLoc'],
        'localise': !exists(json, 'localise') ? undefined : json['localise'],
        'agree': !exists(json, 'agree') ? undefined : json['agree'],
        'idreferentiel': !exists(json, 'idreferentiel') ? undefined : json['idreferentiel'],
        'heureS1Agrement': !exists(json, 'heureS1Agrement') ? undefined : json['heureS1Agrement'],
        'heureS2Agrement': !exists(json, 'heureS2Agrement') ? undefined : json['heureS2Agrement'],
        'totalAgrement': !exists(json, 'totalAgrement') ? undefined : json['totalAgrement'],
        'bloqueHeuresAgrement': !exists(json, 'bloqueHeuresAgrement') ? undefined : json['bloqueHeuresAgrement'],
        'reelHoursS1': !exists(json, 'reelHoursS1') ? undefined : json['reelHoursS1'],
        'reelHoursS2': !exists(json, 'reelHoursS2') ? undefined : json['reelHoursS2'],
        'reelTotalAgrement': !exists(json, 'reelTotalAgrement') ? undefined : json['reelTotalAgrement'],
        'localisationBloquee': !exists(json, 'localisationBloquee') ? undefined : json['localisationBloquee'],
        'agrementBloquee': !exists(json, 'agrementBloquee') ? undefined : json['agrementBloquee'],
    };
}

export function FcbFinancementClasseDtoToJSON(value?: FcbFinancementClasseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idfinancementClasse': value.idfinancementClasse,
        'idclasse': value.idclasse,
        'idenveloppeProjetFinancement': value.idenveloppeProjetFinancement,
        'idprojetFinancement': value.idprojetFinancement,
        'idprojetCofinancement': value.idprojetCofinancement,
        'heureS1': value.heureS1,
        'heureS2': value.heureS2,
        'totalLoc': value.totalLoc,
        'localise': value.localise,
        'agree': value.agree,
        'idreferentiel': value.idreferentiel,
        'heureS1Agrement': value.heureS1Agrement,
        'heureS2Agrement': value.heureS2Agrement,
        'totalAgrement': value.totalAgrement,
        'bloqueHeuresAgrement': value.bloqueHeuresAgrement,
        'reelHoursS1': value.reelHoursS1,
        'reelHoursS2': value.reelHoursS2,
        'reelTotalAgrement': value.reelTotalAgrement,
        'localisationBloquee': value.localisationBloquee,
        'agrementBloquee': value.agrementBloquee,
    };
}

