import { InputGroup, NumericInput } from "@blueprintjs/core";
import * as React from "react";

export interface ITableGlobalTextEditorProps {
  initialTextValue: string;
  onValueChanged: (value: string) => void;
  textEditorHeight?: string | number;
  maxLength?: number;
  numeric?: boolean;
  readonly?: boolean;
  maxWidth?: string;
}

export const TableGlobalTextEditor: React.FunctionComponent<ITableGlobalTextEditorProps> = ({
  initialTextValue,
  onValueChanged,
  textEditorHeight = "auto",
  maxLength,
  numeric = false,
  readonly = false,
  maxWidth
}) => {
  const [currentValue, setCurrentValue] = React.useState<string>();
  const [editedValue, setEditedValue] = React.useState<string>();
  const [focused, setFocused] = React.useState(false);

  React.useEffect(() => {
    setCurrentValue(initialTextValue);
    setEditedValue(initialTextValue);
  }, [initialTextValue]);

  const onBlur = React.useCallback(
    async (e: any) => {
      if (editedValue !== currentValue) {
        setCurrentValue(editedValue);
        onValueChanged(editedValue);
      }
      setFocused(false);
    },
    [currentValue, editedValue, onValueChanged]
  );

  const computeFocus = React.useCallback(() => {
    if (!focused) {
      setFocused(true);
    }
  }, [focused]);

  const cursor = React.useMemo(() => (readonly ? "text" : "pointer"), [readonly]);

  return (
    <>
      {focused && !readonly ? (
        numeric ? (
          <NumericInput
            autoFocus
            style={{ borderRadius: "none", maxWidth, cursor }}
            value={editedValue}
            onChange={e => setEditedValue(e.target.value)}
            onBlur={onBlur}
            onClick={() => computeFocus()}
          />
        ) : (
          <InputGroup
            autoFocus
            style={{ borderRadius: "none", maxWidth, cursor: "pointer" }}
            value={editedValue}
            onChange={e => setEditedValue(e.target.value)}
            onBlur={onBlur}
            onClick={() => computeFocus()}
            maxLength={maxLength}
          ></InputGroup>
        )
      ) : (
        <div
          style={{
            marginLeft: 9,
            marginTop: 4,
            height: textEditorHeight,
            maxWidth,
            cursor
          }}
          onClick={() => computeFocus()}
        >
          {editedValue}
        </div>
      )}
    </>
  );
};
