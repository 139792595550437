/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbVisiteClasseDto
 */
export interface FcbVisiteClasseDto {
    /**
     * 
     * @type {number}
     * @memberof FcbVisiteClasseDto
     */
    idVisite?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof FcbVisiteClasseDto
     */
    idsClasse?: Array<number> | null;
}

/**
 * Check if a given object implements the FcbVisiteClasseDto interface.
 */
export function instanceOfFcbVisiteClasseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbVisiteClasseDtoFromJSON(json: any): FcbVisiteClasseDto {
    return FcbVisiteClasseDtoFromJSONTyped(json, false);
}

export function FcbVisiteClasseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbVisiteClasseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idVisite': !exists(json, 'idVisite') ? undefined : json['idVisite'],
        'idsClasse': !exists(json, 'idsClasse') ? undefined : json['idsClasse'],
    };
}

export function FcbVisiteClasseDtoToJSON(value?: FcbVisiteClasseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idVisite': value.idVisite,
        'idsClasse': value.idsClasse,
    };
}

