import { ButtonContainer, IDataTableColumn } from "nsitools-react";
import * as React from "react";
import { useHistory } from "react-router";

import { ContactApi, ContactGridDto, ContactSearch } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface IContactListPageProps {}

export const ContactListPage: React.FunctionComponent<IContactListPageProps> = props => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(ContactApi);

  const apiFactory = useAbortableApiServiceFactory(ContactApi);
  const lastAbortController = React.useRef<AbortController>();
  const search = React.useCallback(
    (nextSearch?: ContactSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.contactBaseSearch({ ContactSearch: nextSearch });
    },
    [apiFactory]
  );

  const onViewClick = React.useCallback(
    (dto: ContactGridDto) => {
      history.push(`${ERoutes.contacts}/${dto.idcontact}/detail/view`);
    },
    [history]
  );

  const onEditClick = React.useCallback(
    (dto: ContactGridDto) => {
      history.push(`${ERoutes.contacts}/${dto.idcontact}/detail/edit`);
    },
    [history]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ContactGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => onViewClick(row)} />
            <EditButton minimal={true} onClick={() => onEditClick(row)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "nom"
      },
      {
        header: () => t(ETLCodes.Prenom),
        fieldName: "prenom"
      },
      {
        header: () => t(ETLCodes.DateNaissance),
        fieldName: "dateNaissance"
      },
      {
        header: () => t(ETLCodes.DernierSiegeSocial),
        fieldName: "lastSiegeSocial"
      },
      {
        header: () => t(ETLCodes.DernierLieuFormation),
        fieldName: "lastLieuFormation"
      }
    ],
    [onEditClick, onViewClick, t]
  );

  const getCriteria = React.useCallback(() => api.contactGetSearchCriterias({ includeListsValues: false }), [api]);

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteria}
      searchFunction={search}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.Contact), route: ERoutes.contacts }]}
      keyFieldName="idcontact"
      searchStateInitialSearch={false}
    />
  );
};
