/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContratChefEntrepriseDto } from './ContratChefEntrepriseDto';
import {
    ContratChefEntrepriseDtoFromJSON,
    ContratChefEntrepriseDtoFromJSONTyped,
    ContratChefEntrepriseDtoToJSON,
} from './ContratChefEntrepriseDto';
import type { ContratSiegeSocialDto } from './ContratSiegeSocialDto';
import {
    ContratSiegeSocialDtoFromJSON,
    ContratSiegeSocialDtoFromJSONTyped,
    ContratSiegeSocialDtoToJSON,
} from './ContratSiegeSocialDto';

/**
 * 
 * @export
 * @interface ContratStageObservationDto
 */
export interface ContratStageObservationDto {
    /**
     * 
     * @type {number}
     * @memberof ContratStageObservationDto
     */
    idcontratStageObservation?: number;
    /**
     * 
     * @type {number}
     * @memberof ContratStageObservationDto
     */
    idcontrat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratStageObservationDto
     */
    idsiegeSocial?: number | null;
    /**
     * 
     * @type {ContratSiegeSocialDto}
     * @memberof ContratStageObservationDto
     */
    siegeSocial?: ContratSiegeSocialDto;
    /**
     * 
     * @type {number}
     * @memberof ContratStageObservationDto
     */
    idchefEntreprise?: number | null;
    /**
     * 
     * @type {ContratChefEntrepriseDto}
     * @memberof ContratStageObservationDto
     */
    chefEntreprise?: ContratChefEntrepriseDto;
    /**
     * 
     * @type {string}
     * @memberof ContratStageObservationDto
     */
    fonction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratStageObservationDto
     */
    maitreStage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratStageObservationDto
     */
    fonctionMaitreStage?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContratStageObservationDto
     */
    dureeExpPro?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratStageObservationDto
     */
    age?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContratStageObservationDto
     */
    numero?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContratStageObservationDto
     */
    nbMaxHeures?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratStageObservationDto
     */
    anneeFormation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratStageObservationDto
     */
    nbMinHeures?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratStageObservationDto
     */
    nbHeuresPrevues?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratStageObservationDto
     */
    nbHeuresPrestees?: number | null;
}

/**
 * Check if a given object implements the ContratStageObservationDto interface.
 */
export function instanceOfContratStageObservationDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContratStageObservationDtoFromJSON(json: any): ContratStageObservationDto {
    return ContratStageObservationDtoFromJSONTyped(json, false);
}

export function ContratStageObservationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContratStageObservationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcontratStageObservation': !exists(json, 'idcontratStageObservation') ? undefined : json['idcontratStageObservation'],
        'idcontrat': !exists(json, 'idcontrat') ? undefined : json['idcontrat'],
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'siegeSocial': !exists(json, 'siegeSocial') ? undefined : ContratSiegeSocialDtoFromJSON(json['siegeSocial']),
        'idchefEntreprise': !exists(json, 'idchefEntreprise') ? undefined : json['idchefEntreprise'],
        'chefEntreprise': !exists(json, 'chefEntreprise') ? undefined : ContratChefEntrepriseDtoFromJSON(json['chefEntreprise']),
        'fonction': !exists(json, 'fonction') ? undefined : json['fonction'],
        'maitreStage': !exists(json, 'maitreStage') ? undefined : json['maitreStage'],
        'fonctionMaitreStage': !exists(json, 'fonctionMaitreStage') ? undefined : json['fonctionMaitreStage'],
        'dureeExpPro': !exists(json, 'dureeExpPro') ? undefined : json['dureeExpPro'],
        'age': !exists(json, 'age') ? undefined : json['age'],
        'numero': !exists(json, 'numero') ? undefined : json['numero'],
        'nbMaxHeures': !exists(json, 'nbMaxHeures') ? undefined : json['nbMaxHeures'],
        'anneeFormation': !exists(json, 'anneeFormation') ? undefined : json['anneeFormation'],
        'nbMinHeures': !exists(json, 'nbMinHeures') ? undefined : json['nbMinHeures'],
        'nbHeuresPrevues': !exists(json, 'nbHeuresPrevues') ? undefined : json['nbHeuresPrevues'],
        'nbHeuresPrestees': !exists(json, 'nbHeuresPrestees') ? undefined : json['nbHeuresPrestees'],
    };
}

export function ContratStageObservationDtoToJSON(value?: ContratStageObservationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcontratStageObservation': value.idcontratStageObservation,
        'idcontrat': value.idcontrat,
        'idsiegeSocial': value.idsiegeSocial,
        'siegeSocial': ContratSiegeSocialDtoToJSON(value.siegeSocial),
        'idchefEntreprise': value.idchefEntreprise,
        'chefEntreprise': ContratChefEntrepriseDtoToJSON(value.chefEntreprise),
        'fonction': value.fonction,
        'maitreStage': value.maitreStage,
        'fonctionMaitreStage': value.fonctionMaitreStage,
        'dureeExpPro': value.dureeExpPro,
        'age': value.age,
        'numero': value.numero,
        'nbMaxHeures': value.nbMaxHeures,
        'anneeFormation': value.anneeFormation,
        'nbMinHeures': value.nbMinHeures,
        'nbHeuresPrevues': value.nbHeuresPrevues,
        'nbHeuresPrestees': value.nbHeuresPrestees,
    };
}

