/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FileUploadDto } from './FileUploadDto';
import {
    FileUploadDtoFromJSON,
    FileUploadDtoFromJSONTyped,
    FileUploadDtoToJSON,
} from './FileUploadDto';

/**
 * 
 * @export
 * @interface SiegeSocialFichierUploadDto
 */
export interface SiegeSocialFichierUploadDto {
    /**
     * 
     * @type {FileUploadDto}
     * @memberof SiegeSocialFichierUploadDto
     */
    file?: FileUploadDto;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialFichierUploadDto
     */
    idsiegeSocialFichier?: number;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialFichierUploadDto
     */
    idsiegeSocial?: number;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialFichierUploadDto
     */
    idfichier?: number;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialFichierUploadDto
     */
    idtypeSiegeSocialFichier?: number;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialFichierUploadDto
     */
    fileName?: string | null;
}

/**
 * Check if a given object implements the SiegeSocialFichierUploadDto interface.
 */
export function instanceOfSiegeSocialFichierUploadDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SiegeSocialFichierUploadDtoFromJSON(json: any): SiegeSocialFichierUploadDto {
    return SiegeSocialFichierUploadDtoFromJSONTyped(json, false);
}

export function SiegeSocialFichierUploadDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiegeSocialFichierUploadDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'file': !exists(json, 'file') ? undefined : FileUploadDtoFromJSON(json['file']),
        'idsiegeSocialFichier': !exists(json, 'idsiegeSocialFichier') ? undefined : json['idsiegeSocialFichier'],
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'idtypeSiegeSocialFichier': !exists(json, 'idtypeSiegeSocialFichier') ? undefined : json['idtypeSiegeSocialFichier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
    };
}

export function SiegeSocialFichierUploadDtoToJSON(value?: SiegeSocialFichierUploadDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'file': FileUploadDtoToJSON(value.file),
        'idsiegeSocialFichier': value.idsiegeSocialFichier,
        'idsiegeSocial': value.idsiegeSocial,
        'idfichier': value.idfichier,
        'idtypeSiegeSocialFichier': value.idtypeSiegeSocialFichier,
        'fileName': value.fileName,
    };
}

