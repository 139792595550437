import * as React from "react";
import { Route, Switch } from "react-router";

import { ERoutes } from "../../../../../AppRouter";
import { MetierConseillersLies } from "./MetierConseillersLies";
import { MetierConseillerLiesSelector } from "./MetierConseillerLiesSelector";

export interface IMetierConseillersLiesSwitchProps {}

export const MetierConseillersLiesSwitch: React.FunctionComponent<IMetierConseillersLiesSwitchProps> = () => {
  return (
    <Switch>
      <Route path={`${ERoutes.metier}/:id/:tab`} component={MetierConseillersLies} exact />
      <Route path={`${ERoutes.metier}/:id/:tab/selector`} component={MetierConseillerLiesSelector} />
    </Switch>
  );
};
