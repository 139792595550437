/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbEvaluationGridDto
 */
export interface FcbEvaluationGridDto {
    /**
     * 
     * @type {number}
     * @memberof FcbEvaluationGridDto
     */
    idevaluation?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbEvaluationGridDto
     */
    annee?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEvaluationGridDto
     */
    codeClasse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEvaluationGridDto
     */
    libelleClasse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEvaluationGridDto
     */
    codeMatiere?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEvaluationGridDto
     */
    formateur?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEvaluationGridDto
     */
    type?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbEvaluationGridDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbEvaluationGridDto
     */
    libelle?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbEvaluationGridDto
     */
    coteMax?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbEvaluationGridDto
     */
    valide?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbEvaluationGridDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbEvaluationGridDto interface.
 */
export function instanceOfFcbEvaluationGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbEvaluationGridDtoFromJSON(json: any): FcbEvaluationGridDto {
    return FcbEvaluationGridDtoFromJSONTyped(json, false);
}

export function FcbEvaluationGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbEvaluationGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idevaluation': !exists(json, 'idevaluation') ? undefined : json['idevaluation'],
        'annee': !exists(json, 'annee') ? undefined : json['annee'],
        'codeClasse': !exists(json, 'codeClasse') ? undefined : json['codeClasse'],
        'libelleClasse': !exists(json, 'libelleClasse') ? undefined : json['libelleClasse'],
        'codeMatiere': !exists(json, 'codeMatiere') ? undefined : json['codeMatiere'],
        'formateur': !exists(json, 'formateur') ? undefined : json['formateur'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'libelle': !exists(json, 'libelle') ? undefined : json['libelle'],
        'coteMax': !exists(json, 'coteMax') ? undefined : json['coteMax'],
        'valide': !exists(json, 'valide') ? undefined : json['valide'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbEvaluationGridDtoToJSON(value?: FcbEvaluationGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idevaluation': value.idevaluation,
        'annee': value.annee,
        'codeClasse': value.codeClasse,
        'libelleClasse': value.libelleClasse,
        'codeMatiere': value.codeMatiere,
        'formateur': value.formateur,
        'type': value.type,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'libelle': value.libelle,
        'coteMax': value.coteMax,
        'valide': value.valide,
        'actif': value.actif,
    };
}

