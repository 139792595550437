/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FcbReportAccesPermissionDto } from './FcbReportAccesPermissionDto';
import {
    FcbReportAccesPermissionDtoFromJSON,
    FcbReportAccesPermissionDtoFromJSONTyped,
    FcbReportAccesPermissionDtoToJSON,
} from './FcbReportAccesPermissionDto';
import type { FcbReportCentrePermissionDto } from './FcbReportCentrePermissionDto';
import {
    FcbReportCentrePermissionDtoFromJSON,
    FcbReportCentrePermissionDtoFromJSONTyped,
    FcbReportCentrePermissionDtoToJSON,
} from './FcbReportCentrePermissionDto';
import type { FcbReportModulePermissionDto } from './FcbReportModulePermissionDto';
import {
    FcbReportModulePermissionDtoFromJSON,
    FcbReportModulePermissionDtoFromJSONTyped,
    FcbReportModulePermissionDtoToJSON,
} from './FcbReportModulePermissionDto';

/**
 * 
 * @export
 * @interface FcbReportPermissionsDto
 */
export interface FcbReportPermissionsDto {
    /**
     * 
     * @type {Array<FcbReportCentrePermissionDto>}
     * @memberof FcbReportPermissionsDto
     */
    centres?: Array<FcbReportCentrePermissionDto> | null;
    /**
     * 
     * @type {Array<FcbReportAccesPermissionDto>}
     * @memberof FcbReportPermissionsDto
     */
    acces?: Array<FcbReportAccesPermissionDto> | null;
    /**
     * 
     * @type {Array<FcbReportModulePermissionDto>}
     * @memberof FcbReportPermissionsDto
     */
    modules?: Array<FcbReportModulePermissionDto> | null;
}

/**
 * Check if a given object implements the FcbReportPermissionsDto interface.
 */
export function instanceOfFcbReportPermissionsDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbReportPermissionsDtoFromJSON(json: any): FcbReportPermissionsDto {
    return FcbReportPermissionsDtoFromJSONTyped(json, false);
}

export function FcbReportPermissionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbReportPermissionsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'centres': !exists(json, 'centres') ? undefined : (json['centres'] === null ? null : (json['centres'] as Array<any>).map(FcbReportCentrePermissionDtoFromJSON)),
        'acces': !exists(json, 'acces') ? undefined : (json['acces'] === null ? null : (json['acces'] as Array<any>).map(FcbReportAccesPermissionDtoFromJSON)),
        'modules': !exists(json, 'modules') ? undefined : (json['modules'] === null ? null : (json['modules'] as Array<any>).map(FcbReportModulePermissionDtoFromJSON)),
    };
}

export function FcbReportPermissionsDtoToJSON(value?: FcbReportPermissionsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'centres': value.centres === undefined ? undefined : (value.centres === null ? null : (value.centres as Array<any>).map(FcbReportCentrePermissionDtoToJSON)),
        'acces': value.acces === undefined ? undefined : (value.acces === null ? null : (value.acces as Array<any>).map(FcbReportAccesPermissionDtoToJSON)),
        'modules': value.modules === undefined ? undefined : (value.modules === null ? null : (value.modules as Array<any>).map(FcbReportModulePermissionDtoToJSON)),
    };
}

