/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbStadeDto
 */
export interface FcbStadeDto {
    /**
     * 
     * @type {number}
     * @memberof FcbStadeDto
     */
    idstade?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbStadeDto
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbStadeDto
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbStadeDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbStadeDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbStadeDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbStadeDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbStadeDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbStadeDto
     */
    acquisIfapme?: boolean;
}

/**
 * Check if a given object implements the FcbStadeDto interface.
 */
export function instanceOfFcbStadeDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbStadeDtoFromJSON(json: any): FcbStadeDto {
    return FcbStadeDtoFromJSONTyped(json, false);
}

export function FcbStadeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbStadeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idstade': !exists(json, 'idstade') ? undefined : json['idstade'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'acquisIfapme': !exists(json, 'acquisIfapme') ? undefined : json['acquisIfapme'],
    };
}

export function FcbStadeDtoToJSON(value?: FcbStadeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idstade': value.idstade,
        'code': value.code,
        'description': value.description,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'creationUser': value.creationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'actif': value.actif,
        'acquisIfapme': value.acquisIfapme,
    };
}

