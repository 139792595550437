/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbRecherchestatPresenceApprenantDtoPaginatedResults,
  FilterCriteriaInfo,
  RechercheStatPresenceApprenantSearch,
  SelectItem,
} from '../models/index';
import {
    FcbRecherchestatPresenceApprenantDtoPaginatedResultsFromJSON,
    FcbRecherchestatPresenceApprenantDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    RechercheStatPresenceApprenantSearchFromJSON,
    RechercheStatPresenceApprenantSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface ApprenantStatistiquePresenceBaseSearchRequest {
    RechercheStatPresenceApprenantSearch?: RechercheStatPresenceApprenantSearch;
}

export interface ApprenantStatistiquePresenceGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface ApprenantStatistiquePresenceGetSelectItemsRequest {
    searchField: string;
}

/**
 * 
 */
export class ApprenantStatistiquePresenceApi extends runtime.BaseAPI {

    /**
     */
    async apprenantStatistiquePresenceBaseSearchRaw(requestParameters: ApprenantStatistiquePresenceBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRecherchestatPresenceApprenantDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantStatistiquePresence/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RechercheStatPresenceApprenantSearchToJSON(requestParameters.RechercheStatPresenceApprenantSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRecherchestatPresenceApprenantDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async apprenantStatistiquePresenceBaseSearch(requestParameters: ApprenantStatistiquePresenceBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRecherchestatPresenceApprenantDtoPaginatedResults> {
        const response = await this.apprenantStatistiquePresenceBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantStatistiquePresenceGetSearchCriteriasRaw(requestParameters: ApprenantStatistiquePresenceGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantStatistiquePresence/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async apprenantStatistiquePresenceGetSearchCriterias(requestParameters: ApprenantStatistiquePresenceGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.apprenantStatistiquePresenceGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apprenantStatistiquePresenceGetSelectItemsRaw(requestParameters: ApprenantStatistiquePresenceGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling apprenantStatistiquePresenceGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/ApprenantStatistiquePresence/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async apprenantStatistiquePresenceGetSelectItems(requestParameters: ApprenantStatistiquePresenceGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.apprenantStatistiquePresenceGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
