import * as React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import { ReferentielMatiereListPage } from "./ReferentielMatiereListPage";
import { ReferentielMatiereDetailPage } from "./ReferentielMatiereDetailPage";

export interface IReferentielMatiereItemPageProps {}

export const ReferentielMatiereItemPage: React.FunctionComponent<IReferentielMatiereItemPageProps> = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:matiereId/:state`} component={ReferentielMatiereDetailPage} exact />
      <Route path={`${match.path}`} component={ReferentielMatiereListPage} />
    </Switch>
  );
};
