/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContratSiegeSocialDto
 */
export interface ContratSiegeSocialDto {
    /**
     * 
     * @type {number}
     * @memberof ContratSiegeSocialDto
     */
    idsiegeSocial?: number;
    /**
     * 
     * @type {string}
     * @memberof ContratSiegeSocialDto
     */
    denominationSociale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratSiegeSocialDto
     */
    numeroBce?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContratSiegeSocialDto
     */
    idtypeEntreprise?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContratSiegeSocialDto
     */
    idformeJuridique?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContratSiegeSocialDto
     */
    sendToAdress?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContratSiegeSocialDto
     */
    adresse?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContratSiegeSocialDto
     */
    idcodePostal?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContratSiegeSocialDto
     */
    codePostalText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratSiegeSocialDto
     */
    boite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratSiegeSocialDto
     */
    localite?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratSiegeSocialDto
     */
    commune?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContratSiegeSocialDto
     */
    pays?: string | null;
}

/**
 * Check if a given object implements the ContratSiegeSocialDto interface.
 */
export function instanceOfContratSiegeSocialDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContratSiegeSocialDtoFromJSON(json: any): ContratSiegeSocialDto {
    return ContratSiegeSocialDtoFromJSONTyped(json, false);
}

export function ContratSiegeSocialDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContratSiegeSocialDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsiegeSocial': !exists(json, 'idsiegeSocial') ? undefined : json['idsiegeSocial'],
        'denominationSociale': !exists(json, 'denominationSociale') ? undefined : json['denominationSociale'],
        'numeroBce': !exists(json, 'numeroBce') ? undefined : json['numeroBce'],
        'idtypeEntreprise': !exists(json, 'idtypeEntreprise') ? undefined : json['idtypeEntreprise'],
        'idformeJuridique': !exists(json, 'idformeJuridique') ? undefined : json['idformeJuridique'],
        'sendToAdress': !exists(json, 'sendToAdress') ? undefined : json['sendToAdress'],
        'adresse': !exists(json, 'adresse') ? undefined : json['adresse'],
        'idcodePostal': !exists(json, 'idcodePostal') ? undefined : json['idcodePostal'],
        'codePostalText': !exists(json, 'codePostalText') ? undefined : json['codePostalText'],
        'boite': !exists(json, 'boite') ? undefined : json['boite'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'commune': !exists(json, 'commune') ? undefined : json['commune'],
        'pays': !exists(json, 'pays') ? undefined : json['pays'],
    };
}

export function ContratSiegeSocialDtoToJSON(value?: ContratSiegeSocialDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsiegeSocial': value.idsiegeSocial,
        'denominationSociale': value.denominationSociale,
        'numeroBce': value.numeroBce,
        'idtypeEntreprise': value.idtypeEntreprise,
        'idformeJuridique': value.idformeJuridique,
        'sendToAdress': value.sendToAdress,
        'adresse': value.adresse,
        'idcodePostal': value.idcodePostal,
        'codePostalText': value.codePostalText,
        'boite': value.boite,
        'localite': value.localite,
        'commune': value.commune,
        'pays': value.pays,
    };
}

