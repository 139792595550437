import * as React from "react";
import { IconNames } from "@blueprintjs/icons";
import { ETLCodes } from "../../locales/ETLCodes";
import { useTl } from "../../hooks";
import { Intent, Button } from "@blueprintjs/core";
import { ICustomButtonProps } from "./ICustomButtonProps";

interface ISearchButtonProps extends ICustomButtonProps {}

export const SearchButton: React.FunctionComponent<ISearchButtonProps> = ({
  minimal = true,
  onClick,
  loading,
  disabled,
  intent = Intent.PRIMARY
}) => {
  const { t } = useTl();
  return (
    <Button
      icon={IconNames.SEARCH}
      minimal={minimal}
      onClick={onClick}
      text={minimal ? null : t(ETLCodes.Search)}
      intent={intent}
      loading={loading}
      disabled={disabled}
      type={"button"}
    />
  );
};
