/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EErrorCode {
    GeneralError = 'GeneralError',
    DbEntityReferenceException = 'DbEntityReferenceException',
    EntityExists = 'EntityExists',
    ConstraintViolation = 'ConstraintViolation',
    DataError = 'DataError',
    InvalidQuery = 'InvalidQuery',
    SmsSendError = 'SmsSendError',
    SmsRequestError = 'SmsRequestError',
    DuplicateExist = 'DuplicateExist',
    ClasseMatiereLinkedToCourse = 'ClasseMatiereLinkedToCourse',
    ClasseMatiereLinkedToHoraire = 'ClasseMatiereLinkedToHoraire',
    FormateurLinked = 'FormateurLinked',
    LoginAlreadyExists = 'LoginAlreadyExists',
    CannotDeleteAgrement = 'CannotDeleteAgrement',
    LocalisationLinked = 'LocalisationLinked',
    FeuillePresenceStartDateGreaterThanEndDate = 'FeuillePresenceStartDateGreaterThanEndDate',
    NoCourseForPeriod = 'NoCourseForPeriod',
    NoApprenantForClasse = 'NoApprenantForClasse',
    RemplacementCannotBeUpdated = 'RemplacementCannotBeUpdated',
    RemplacementClasseHoraireLinkedToGap = 'RemplacementClasseHoraireLinkedToGap',
    DateCannotBeInferior = 'DateCannotBeInferior',
    ActiveReportAlreadyExists = 'ActiveReportAlreadyExists',
    CannotModifyChangeClasse = 'CannotModifyChangeClasse',
    InscriptionDoesntHaveClasse = 'InscriptionDoesntHaveClasse',
    NoValidatedLocalisation = 'NoValidatedLocalisation',
    ClasseDoesNotExist = 'ClasseDoesNotExist',
    MetierNotCompatibleWithDegre = 'MetierNotCompatibleWithDegre',
    ClasseLinkedToInscription = 'ClasseLinkedToInscription',
    ClasseLinkedToEvaluation = 'ClasseLinkedToEvaluation',
    ClasseLinkedToPresence = 'ClasseLinkedToPresence',
    ClasseResultatLocked = 'ClasseResultatLocked',
    InscriptionUsedByAnotherTable = 'InscriptionUsedByAnotherTable',
    ClasseMatiereLinkedToDispense = 'ClasseMatiereLinkedToDispense',
    ClasseMatiereLinkedToClasseHoraire = 'ClasseMatiereLinkedToClasseHoraire',
    EntityDoesntExist = 'EntityDoesntExist',
    NoApprenantSelected = 'NoApprenantSelected',
    FormationLinkedToInscription = 'FormationLinkedToInscription',
    DateCoursNotInAnyAnneeScolaire = 'DateCoursNotInAnyAnneeScolaire',
    ClasseHoraireRemplacementCannotBeUpdated = 'ClasseHoraireRemplacementCannotBeUpdated',
    Required = 'Required',
    PersonneTypeEmailAlreadyExists = 'PersonneTypeEmailAlreadyExists',
    PersonneTypeTelephoneAlreadyExists = 'PersonneTypeTelephoneAlreadyExists',
    PersonneTypeAdresseAlreadyExists = 'PersonneTypeAdresseAlreadyExists',
    CannotCreatePersonne = 'CannotCreatePersonne',
    AnotherTuteurIsMain = 'AnotherTuteurIsMain',
    AnotherContactIsMain = 'AnotherContactIsMain',
    SuspensionIntersection = 'SuspensionIntersection',
    MetierNotCorrespondingAgrements = 'MetierNotCorrespondingAgrements',
    MetierNotCorrespondingTuteurs = 'MetierNotCorrespondingTuteurs',
    OnlyOneMainLf = 'OnlyOneMainLf',
    OnlyOneMainTuteur = 'OnlyOneMainTuteur',
    BaremesRequired = 'BaremesRequired',
    TuteursCannotBeSpp = 'TuteursCannotBeSpp',
    RequiredWhereDecisionSelected = 'RequiredWhereDecisionSelected',
    LieuFormationContratsMustCorrespondToDecision = 'LieuFormationContratsMustCorrespondToDecision',
    LieuFormationDoesNotHaveAnyValidAgrements = 'LieuFormationDoesNotHaveAnyValidAgrements',
    DateMustBeWithinRange = 'DateMustBeWithinRange',
    AgrementWithSameMetierAlreadyActive = 'AgrementWithSameMetierAlreadyActive',
    HeureHebdoWithSameValueAlreadyExists = 'HeureHebdoWithSameValueAlreadyExists',
    BceAlreadyExists = 'BceAlreadyExists',
    RegistreNationalAlreadyExists = 'RegistreNationalAlreadyExists',
    AgrementPourOffreMustBeChanged = 'AgrementPourOffreMustBeChanged',
    AnAgrementIsSPPButContratIsNotPP = 'AnAgrementIsSPPButContratIsNotPP',
    CannotDeleteLfAgrementContratsLinked = 'CannotDeleteLfAgrementContratsLinked',
    NumeroIdentificationAlreadyExists = 'NumeroIdentificationAlreadyExists',
    InscriptionDejaCreeAPartirDemandeInscription = 'InscriptionDejaCreeAPartirDemandeInscription',
    ApprenantAlreadyInARE = 'ApprenantAlreadyInARE',
    CannotDisableSiege = 'CannotDisableSiege',
    CannotDisableLieu = 'CannotDisableLieu',
    DemandeAlreadyExists = 'DemandeAlreadyExists',
    NumeroUeAlreadyExists = 'NumeroUeAlreadyExists',
    SiegeIsInactif = 'SiegeIsInactif',
    CannotDeleteLfSuiviFilesLinked = 'CannotDeleteLfSuiviFilesLinked',
    InvalidDatesCombination = 'InvalidDatesCombination',
    CurrentCalcAlreadyExisting = 'CurrentCalcAlreadyExisting',
    UserNotAuthorizedForMerge = 'UserNotAuthorizedForMerge',
    TuteurAlreadyLinkedToLieu = 'TuteurAlreadyLinkedToLieu',
    InscriptionLinkedToAnneeFormationSuivieIfapme = 'InscriptionLinkedToAnneeFormationSuivieIfapme',
    InscriptionLinkedToAnneeFormationSuivie = 'InscriptionLinkedToAnneeFormationSuivie',
    InscriptionLinkedToApprenantFichier = 'InscriptionLinkedToApprenantFichier',
    InscriptionLinkedToBilanCompetence = 'InscriptionLinkedToBilanCompetence',
    InscriptionLinkedToBulletinApprenantType = 'InscriptionLinkedToBulletinApprenantType',
    InscriptionLinkedToDemandeInscription = 'InscriptionLinkedToDemandeInscription',
    InscriptionLinkedToDiplomeCertificationIfapme = 'InscriptionLinkedToDiplomeCertificationIfapme',
    InscriptionLinkedToDiplomeCertification = 'InscriptionLinkedToDiplomeCertification',
    InscriptionLinkedToDossierAdmission = 'InscriptionLinkedToDossierAdmission',
    InscriptionLinkedToEnveloppeProjetFinancement = 'InscriptionLinkedToEnveloppeProjetFinancement',
    InscriptionLinkedToEnveloppeTypeFinancement = 'InscriptionLinkedToEnveloppeTypeFinancement',
    InscriptionLinkedToExamenEntreeFce = 'InscriptionLinkedToExamenEntreeFce',
    InscriptionLinkedToExamenEntree = 'InscriptionLinkedToExamenEntree',
    InscriptionLinkedToForfaitAuditeur = 'InscriptionLinkedToForfaitAuditeur',
    InscriptionLinkedToUniteActivite = 'InscriptionLinkedToUniteActivite',
    CannotDeleteRepresentant = 'CannotDeleteRepresentant',
    ClasseMatiereLinkedToEvaluations = 'ClasseMatiereLinkedToEvaluations',
    UserNotInCentres = 'UserNotInCentres',
    InscriptionVisibleBulletin = 'InscriptionVisibleBulletin',
    NoValidInscriptions = 'NoValidInscriptions',
    AnneeScolaireMustBeBefore2024 = 'AnneeScolaireMustBeBefore2024',
    TypeBulletinMissingInClasse = 'TypeBulletinMissingInClasse',
    CannotEditLinkedToApprenant = 'CannotEditLinkedToApprenant',
    ReportNotFound = 'ReportNotFound',
    ReportAlreadyPrinting = 'ReportAlreadyPrinting'
}


export function EErrorCodeFromJSON(json: any): EErrorCode {
    return EErrorCodeFromJSONTyped(json, false);
}

export function EErrorCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EErrorCode {
    return json as EErrorCode;
}

export function EErrorCodeToJSON(value?: EErrorCode | null): any {
    return value as any;
}

