/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbFormateurLocalisationDto
 */
export interface FcbFormateurLocalisationDto {
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurLocalisationDto
     */
    idformateurLocalisation?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurLocalisationDto
     */
    idformateur?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurLocalisationDto
     */
    idcentre?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurLocalisationDto
     */
    premierContrat?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurLocalisationDto
     */
    debutContrat?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof FcbFormateurLocalisationDto
     */
    remarque?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurLocalisationDto
     */
    km?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFormateurLocalisationDto
     */
    actif?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbFormateurLocalisationDto
     */
    azureActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurLocalisationDto
     */
    creationUser?: number;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurLocalisationDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbFormateurLocalisationDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbFormateurLocalisationDto
     */
    modificationDate?: Date | null;
}

/**
 * Check if a given object implements the FcbFormateurLocalisationDto interface.
 */
export function instanceOfFcbFormateurLocalisationDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbFormateurLocalisationDtoFromJSON(json: any): FcbFormateurLocalisationDto {
    return FcbFormateurLocalisationDtoFromJSONTyped(json, false);
}

export function FcbFormateurLocalisationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbFormateurLocalisationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idformateurLocalisation': !exists(json, 'idformateurLocalisation') ? undefined : json['idformateurLocalisation'],
        'idformateur': !exists(json, 'idformateur') ? undefined : json['idformateur'],
        'idcentre': !exists(json, 'idcentre') ? undefined : json['idcentre'],
        'premierContrat': !exists(json, 'premierContrat') ? undefined : (json['premierContrat'] === null ? null : new Date(json['premierContrat'])),
        'debutContrat': !exists(json, 'debutContrat') ? undefined : (json['debutContrat'] === null ? null : new Date(json['debutContrat'])),
        'remarque': !exists(json, 'remarque') ? undefined : json['remarque'],
        'km': !exists(json, 'km') ? undefined : json['km'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
        'azureActive': !exists(json, 'azureActive') ? undefined : json['azureActive'],
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
    };
}

export function FcbFormateurLocalisationDtoToJSON(value?: FcbFormateurLocalisationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idformateurLocalisation': value.idformateurLocalisation,
        'idformateur': value.idformateur,
        'idcentre': value.idcentre,
        'premierContrat': value.premierContrat === undefined ? undefined : (value.premierContrat === null ? null : value.premierContrat.toISOString()),
        'debutContrat': value.debutContrat === undefined ? undefined : (value.debutContrat === null ? null : value.debutContrat.toISOString()),
        'remarque': value.remarque,
        'km': value.km,
        'actif': value.actif,
        'azureActive': value.azureActive,
        'creationUser': value.creationUser,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'modificationUser': value.modificationUser,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
    };
}

