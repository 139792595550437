/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbEnveloppeProjetFinancementDto
 */
export interface FcbEnveloppeProjetFinancementDto {
    /**
     * 
     * @type {number}
     * @memberof FcbEnveloppeProjetFinancementDto
     */
    idenveloppeProjetFinancement?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbEnveloppeProjetFinancementDto
     */
    idanneeScolaire?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FcbEnveloppeProjetFinancementDto
     */
    idprojetFinancement?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbEnveloppeProjetFinancementDto
     */
    heureS1?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbEnveloppeProjetFinancementDto
     */
    heureS2?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbEnveloppeProjetFinancementDto
     */
    identite?: number | null;
}

/**
 * Check if a given object implements the FcbEnveloppeProjetFinancementDto interface.
 */
export function instanceOfFcbEnveloppeProjetFinancementDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbEnveloppeProjetFinancementDtoFromJSON(json: any): FcbEnveloppeProjetFinancementDto {
    return FcbEnveloppeProjetFinancementDtoFromJSONTyped(json, false);
}

export function FcbEnveloppeProjetFinancementDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbEnveloppeProjetFinancementDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idenveloppeProjetFinancement': !exists(json, 'idenveloppeProjetFinancement') ? undefined : json['idenveloppeProjetFinancement'],
        'idanneeScolaire': !exists(json, 'idanneeScolaire') ? undefined : json['idanneeScolaire'],
        'idprojetFinancement': !exists(json, 'idprojetFinancement') ? undefined : json['idprojetFinancement'],
        'heureS1': !exists(json, 'heureS1') ? undefined : json['heureS1'],
        'heureS2': !exists(json, 'heureS2') ? undefined : json['heureS2'],
        'identite': !exists(json, 'identite') ? undefined : json['identite'],
    };
}

export function FcbEnveloppeProjetFinancementDtoToJSON(value?: FcbEnveloppeProjetFinancementDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idenveloppeProjetFinancement': value.idenveloppeProjetFinancement,
        'idanneeScolaire': value.idanneeScolaire,
        'idprojetFinancement': value.idprojetFinancement,
        'heureS1': value.heureS1,
        'heureS2': value.heureS2,
        'identite': value.identite,
    };
}

