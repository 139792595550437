/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbCodePostalDto
 */
export interface FcbCodePostalDto {
    /**
     * 
     * @type {number}
     * @memberof FcbCodePostalDto
     */
    idcodePostal?: number;
    /**
     * 
     * @type {string}
     * @memberof FcbCodePostalDto
     */
    codePostal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCodePostalDto
     */
    codePays?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCodePostalDto
     */
    commune?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FcbCodePostalDto
     */
    localite?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbCodePostalDto
     */
    modificationDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FcbCodePostalDto
     */
    creationDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FcbCodePostalDto
     */
    creationUser?: number;
    /**
     * 
     * @type {number}
     * @memberof FcbCodePostalDto
     */
    modificationUser?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FcbCodePostalDto
     */
    actif?: boolean | null;
}

/**
 * Check if a given object implements the FcbCodePostalDto interface.
 */
export function instanceOfFcbCodePostalDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbCodePostalDtoFromJSON(json: any): FcbCodePostalDto {
    return FcbCodePostalDtoFromJSONTyped(json, false);
}

export function FcbCodePostalDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbCodePostalDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idcodePostal': !exists(json, 'idcodePostal') ? undefined : json['idcodePostal'],
        'codePostal': !exists(json, 'codePostal') ? undefined : json['codePostal'],
        'codePays': !exists(json, 'codePays') ? undefined : json['codePays'],
        'commune': !exists(json, 'commune') ? undefined : json['commune'],
        'localite': !exists(json, 'localite') ? undefined : json['localite'],
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (json['modificationDate'] === null ? null : new Date(json['modificationDate'])),
        'creationDate': !exists(json, 'creationDate') ? undefined : (json['creationDate'] === null ? null : new Date(json['creationDate'])),
        'creationUser': !exists(json, 'creationUser') ? undefined : json['creationUser'],
        'modificationUser': !exists(json, 'modificationUser') ? undefined : json['modificationUser'],
        'actif': !exists(json, 'actif') ? undefined : json['actif'],
    };
}

export function FcbCodePostalDtoToJSON(value?: FcbCodePostalDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idcodePostal': value.idcodePostal,
        'codePostal': value.codePostal,
        'codePays': value.codePays,
        'commune': value.commune,
        'localite': value.localite,
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate === null ? null : value.modificationDate.toISOString()),
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate === null ? null : value.creationDate.toISOString()),
        'creationUser': value.creationUser,
        'modificationUser': value.modificationUser,
        'actif': value.actif,
    };
}

