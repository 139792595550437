import { Tab } from "@blueprintjs/core";
import { useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import { OffreDetail } from ".";
import { LieuFormationAgrementApi } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { BackButton, PageBase, ProtectedTabs } from "../../../../components";
import { useApiService, useTl } from "../../../../hooks";
import { ETLCodes } from "../../../../locales";

export interface IOffreItemPageProps {}

export const OffreItemPage: React.FunctionComponent<IOffreItemPageProps> = props => {
  const { t } = useTl();
  const { id, tab, state = "edit" } = useParams<{ id: string; tab: string; state: string }>();
  const history = useHistory();

  const api = useApiService(LieuFormationAgrementApi);
  const [displayName] = useApiEffect(
    () => api.lieuFormationAgrementGetOffreDisplayName({ idlieuFormationAgrementOffre: +id }),
    [id]
  );

  return (
    <PageBase breadCrumbs={[{ text: t(ETLCodes.Offres), route: ERoutes.offre }, { text: displayName }]}>
      <ProtectedTabs
        id="TabsOffre"
        onChange={newTabId => history.push(`${ERoutes.offre}/${id}/${newTabId}/${state}`)}
        selectedTabId={tab}
        renderActiveTabPanelOnly
      >
        <BackButton backRoute={ERoutes.offre}></BackButton>
        <Tab id="detail" title={t(ETLCodes.Detail)} panel={<OffreDetail />} />
      </ProtectedTabs>
    </PageBase>
  );
};
