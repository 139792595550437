import { Checkbox, Intent } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ExamenEntreeApi, ExamenEntreeSearch, FcbExamenEntreeGridDto } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { AddButton, EditButton, ViewButton } from "../../../../../components";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IExamenEntreeTableProps {}

export const ExamenEntreeTable: React.FunctionComponent<IExamenEntreeTableProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const apprenantId = React.useMemo(() => +id, [id]);

  const api = useApiService(ExamenEntreeApi);
  const path = React.useMemo(() => `${ERoutes.apprenant}/${apprenantId}/acquis/examen`, [apprenantId]);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [5],
    pageSize: 5,
    sortKeys: { annee: "DESC" }
  });

  const searchFunction = React.useCallback(
    (sObj?: ExamenEntreeSearch) => {
      sObj.idApprenant = apprenantId;
      return api.examenEntreeBaseSearch({ ExamenEntreeSearch: sObj });
    },
    [api, apprenantId]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbExamenEntreeGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => history.push(`${path}/${row.idexamenEntree}/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${path}/${row.idexamenEntree}/edit`)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.AnneeExamen),
        fieldName: "annee",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.PointsMathematiques),
        fieldName: "pointsmath"
      },
      {
        header: () => t(ETLCodes.PointsFrancais),
        fieldName: "pointsfrancais"
      },
      {
        header: () => t(ETLCodes.PointsLogique),
        fieldName: "pointslogique"
      },
      {
        header: () => t(ETLCodes.Reussite),
        fieldName: "reussite",
        autoFitContent: true,
        render: (row: FcbExamenEntreeGridDto) => <Checkbox disabled checked={row.reussite} />
      },
      {
        header: () => t(ETLCodes.Remarque),
        fieldName: "remarque"
      }
    ],
    [history, path, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${path}/0/edit`);
  }, [history, path]);

  return (
    <>
      {apprenantId > 0 && (
        <FieldSet
          title={`${t(ETLCodes.ExamensEntree)}`}
          rightElement={
            <>
              <AddButton
                onClick={e => {
                  e.stopPropagation();
                  onAddItem();
                }}
                text={t(ETLCodes.General_Add)}
                intent={Intent.PRIMARY}
              />
            </>
          }
        >
          <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
        </FieldSet>
      )}
    </>
  );
};
