import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { DataTable, FieldSet, showError, showSuccess, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";

import { ClasseHoraireIncoherenceApi, ClasseHoraireIncoherenceSearch } from "../../../../../api";
import { useEventsContext } from "../../../../../contexts";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IClasseIncoherencesSearchProps {
  classeId: number;
}

export const ClasseIncoherencesSearch: React.FunctionComponent<IClasseIncoherencesSearchProps> = ({ classeId }) => {
  const { t } = useTl();
  const api = useApiService(ClasseHoraireIncoherenceApi);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [5, 10],
    pageSize: 5,
    sortKeys: { Jour: "ASC" }
  });

  const { dispatchEvent } = useEventsContext();
  const searchFunction = React.useCallback(
    (sObj?: ClasseHoraireIncoherenceSearch) => {
      sObj.idClasse = classeId;
      return api.classeHoraireIncoherenceBaseSearch({ ClasseHoraireIncoherenceSearch: sObj });
    },
    [api, classeId]
  );

  const { loading, search } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const columns = React.useMemo(
    () => [
      {
        header: () => t(ETLCodes.Jour),
        fieldName: "jour"
      },
      {
        header: () => t(ETLCodes.Debut),
        fieldName: "heureDebut",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Fin),
        fieldName: "heureFin",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Local),
        fieldName: "local"
      },
      {
        header: () => t(ETLCodes.Formateur),
        fieldName: "formateur"
      },
      {
        header: () => t(ETLCodes.Incoherence),
        fieldName: "incoherence"
      },
      {
        header: () => t(ETLCodes.TypeIncoherence),
        fieldName: "typeIncoherence"
      }
    ],
    [t]
  );

  const [coherenceLoading, setCoherenceLoading] = React.useState(false);

  const onCalculCoherence = React.useCallback(async () => {
    try {
      setCoherenceLoading(true);
      await api.classeHoraireIncoherenceCalculCoherenceClasse({ idClasse: classeId });
      showSuccess(t(ETLCodes.CalculCoherenceSuccess));
      search({ idClasse: classeId });
      dispatchEvent("CLASSE_COHERENCE_REFRESH");
    } catch (error) {
      showError(t(ETLCodes.ErreurCalculIncoherence));
    }
    setCoherenceLoading(false);
  }, [api, classeId, dispatchEvent, search, t]);

  const coherenceButton = React.useMemo(
    () => (
      <Button
        icon={IconNames.RESET}
        minimal={false}
        onClick={onCalculCoherence}
        text={t(ETLCodes.CalculCoherence)}
        intent={Intent.NONE}
        loading={coherenceLoading}
        type={"button"}
      />
    ),
    [coherenceLoading, onCalculCoherence, t]
  );

  if (!classeId) return null;

  return (
    <FieldSet title={t(ETLCodes.Incoherence)} rightElement={coherenceButton}>
      <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
    </FieldSet>
  );
};
