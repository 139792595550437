import { Checkbox } from "@blueprintjs/core";
import * as React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { FcbTypeCoursGridDto, TypeCoursApi, TypeCoursSearch, EBooleanSearchTypes } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { useTl } from "../../../../../hooks";
import { useAbortableApiServiceFactory, useApiService } from "../../../../../hooks/useApiService";
import { ETLCodes } from "../../../../../locales";

const Container = styled.div`
  display: flex;
`;

interface ITypeCoursListPageProps {}

export const TypeCoursListPage: React.FunctionComponent<ITypeCoursListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(TypeCoursApi);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbTypeCoursGridDto) => (
          <Container>
            <ViewButton minimal={true} onClick={() => history.push(`${ERoutes.typeCours}/${row.code}/detail/view`)} />
            <EditButton minimal={true} onClick={() => history.push(`${ERoutes.typeCours}/${row.code}/detail/edit`)} />
          </Container>
        )
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code",
        autoFitContent: true
      },
      {
        header: () => t(ETLCodes.Nom),
        fieldName: "libelle"
      },
      {
        header: () => t(ETLCodes.AcquisIfapme),
        fieldName: "acquisIfapme",
        render: (row: FcbTypeCoursGridDto) => <Checkbox checked={row.acquisIfapme} onClick={() => {}} disabled />
      }
    ],
    [history, t]
  );

  const addItemFunction = React.useCallback(() => {
    history.push(`${ERoutes.typeCours}/0/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(() => api.typeCoursGetSearchCriterias({ includeListsValues: true }), [api]);

  const apiFactory = useAbortableApiServiceFactory(TypeCoursApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (nextSearch?: TypeCoursSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.typeCoursBaseSearch({ TypeCoursSearch: nextSearch });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      sortKeys={{ code: "ASC" }}
      columns={columns}
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      addFunc={addItemFunction}
      breadCrumbs={[{ text: t(ETLCodes.TypesCours), route: ERoutes.typeCours }]}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
    />
  );
};
