import { Divider } from "@blueprintjs/core";
import { FGCheckboxInput, FGTextAreaInput, FieldGroup, FieldSet } from "nsitools-react";
import * as React from "react";
import * as Yup from "yup";

import { ValidationMasseTable } from "..";
import { AgrementMasseValidationApi, FcbAgrementValidationMasseDtoFromJSON } from "../../../../api";
import { ERoutes } from "../../../../AppRouter";
import { FGWalterDateMaskInput, FGWalterSelectInput, PageBase, SmallFormGenerator } from "../../../../components";
import { useApiService, useCrudApi, useTl } from "../../../../hooks";
import { useReferential } from "../../../../hooks/useReferential";
import { ETLCodes } from "../../../../locales";

export interface IValidationMasseItemPageProps {}

export const ValidationMasseItemPage: React.FunctionComponent<IValidationMasseItemPageProps> = () => {
  const { t } = useTl();
  const api = useApiService(AgrementMasseValidationApi);
  const [decision, decisionLoading] = useReferential(a => a.referentialGetDecision(), false, []);
  const { data, saving, saveItem } = useCrudApi(
    React.useMemo(() => {
      return {
        getApiFn: () => {
          return FcbAgrementValidationMasseDtoFromJSON({ idApprenants: [] });
        },
        saveApiFn: d => {
          return api.agrementMasseValidationSave({ FcbAgrementValidationMasseDto: d });
        },
        onSavedRoute: () => `${ERoutes.validationMasse}`,
        deleteApiFn: () => {},
        onDeletedRoute: () => ERoutes.validationMasse
      };
    }, [api])
  );

  const FormSchema = React.useMemo(() => {
    return Yup.object().shape({
      idDecision: Yup.number().required(t(ETLCodes.Required))
    });
  }, [t]);

  const [formationIds, setFormationIds] = React.useState<number[]>([]);

  return (
    <PageBase breadCrumbs={[{ text: t(ETLCodes.ValidationDeMasse), route: ERoutes.validationMasse }]} withCard>
      <FieldSet title={t(ETLCodes.ValidationDeMasse)}>
        {data && (
          <SmallFormGenerator
            initialValues={data}
            onSubmit={values => saveItem({ ...values, formationIds: formationIds })}
            showColons
            editMode
            validationSchema={FormSchema}
            saving={saving}
            formatDate="dd-MM-yyyy"
          >
            <FieldGroup columns={2}>
              <FGWalterDateMaskInput name="dateValidation" label={t(ETLCodes.DateValidation)} />
              <FGWalterSelectInput
                name="idDecision"
                label={t(ETLCodes.Decision)}
                items={decision}
                loading={decisionLoading}
              />
              <FGCheckboxInput name="derogation" label={t(ETLCodes.Derogation)} />
              <FGCheckboxInput name="c8" label={t(ETLCodes.C8)} />
              <FGTextAreaInput name="remarque" label={t(ETLCodes.Remarque)} />
            </FieldGroup>
          </SmallFormGenerator>
        )}
        {/* <ValidationMasseSelector
          linkedIds={formationIds}
          onLinkedIdsChanged={newLinkedIds => setFormationIds(newLinkedIds)}
        /> */}
        <Divider />
        <ValidationMasseTable linkedIds={formationIds} onLinkedIdsChanged={setFormationIds} />
      </FieldSet>
    </PageBase>
  );
};
