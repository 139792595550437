import { Colors } from "@blueprintjs/core";
import { ButtonContainer, DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { css } from "styled-components";

import { ApprenantFichierApi, ApprenantFichierGridDto, ApprenantFichierSearchDto } from "../../../../../api";
import { AddButton, BooleanColumn, DeleteButton, EditButton, LinkButton } from "../../../../../components";
import { useEventsContext } from "../../../../../contexts";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IApprenantDocumentsReglementairesProps {
  idapprenant: number;
  rowLoading: number;
  setCurrentApprenantFichierId: (idapprenantFichier: number) => void;
  deleteFichier: (idapprenantFichier: number) => void;
  downloadFichier: (idapprenantFichier: number) => void;
  onAdd: (typeAppreantFichier: string) => void;
}

export const ApprenantDocumentsReglementaires: React.FunctionComponent<IApprenantDocumentsReglementairesProps> = ({
  idapprenant,
  rowLoading,
  setCurrentApprenantFichierId,
  deleteFichier,
  downloadFichier,
  onAdd
}) => {
  const { t, tUnsafe } = useTl();
  const api = useApiService(ApprenantFichierApi);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: false,
    enableFilter: false
  });

  const searchFunc = React.useCallback(
    (nextSearch?: ApprenantFichierSearchDto) =>
      api.apprenantFichierSearchDocumentsReglementaires({
        idapprenant
      }),
    [api, idapprenant]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction: searchFunc,
    tableState,
    initialSearch: true
  });

  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();
  React.useEffect(() => {
    subscribeToEvent("SEARCH_TABLE_REFRESH", search);
    return () => unsubscribeEvent("SEARCH_TABLE_REFRESH", search);
  }, [search, subscribeToEvent, unsubscribeEvent]);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ApprenantFichierGridDto) => (
          <ButtonContainer>
            {row.remis ? (
              <>
                <EditButton
                  minimal={true}
                  onClick={() => setCurrentApprenantFichierId(row.idapprenantFichier)}
                  loading={rowLoading === row.idapprenantFichier}
                  disabled={!!rowLoading && rowLoading !== row.idapprenantFichier}
                />
                <DeleteButton
                  minimal={true}
                  onDelete={() => deleteFichier(row.idapprenantFichier)}
                  loading={rowLoading === row.idapprenantFichier}
                  disabled={!!rowLoading && rowLoading !== row.idapprenantFichier}
                />
              </>
            ) : (
              <AddButton
                minimal={true}
                onClick={() => onAdd(row.typeApprenantFichierCode)}
                loading={rowLoading === row.idapprenantFichier}
                disabled={!!rowLoading && rowLoading !== row.idapprenantFichier}
              />
            )}
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "typeApprenantFichier",
        render: (row: ApprenantFichierGridDto) =>
          row.typeApprenantFichier ?? tUnsafe(`TypeApprenantFichier_${row.typeApprenantFichierCode}`)
      },
      {
        header: () => t(ETLCodes.Remis),
        fieldName: "remis",
        autoFitContent: true,
        alignment: "center",
        computed: true,
        render: (row: ApprenantFichierGridDto) => <BooleanColumn value={row.remis} />
      },
      {
        header: () => t(ETLCodes.DateUpload),
        fieldName: "uploadDate"
      },
      {
        header: () => t(ETLCodes.Annee),
        fieldName: "anneeScolaire"
      },
      {
        header: () => t(ETLCodes.UtilisateurUpload),
        fieldName: "creationUserName"
      },
      {
        header: () => t(ETLCodes.Document),
        fieldName: "fileName",
        render: (row: ApprenantFichierGridDto) =>
          row.remis &&
          (!!row.idfichier ? (
            <LinkButton
              text={row.fileName}
              loading={rowLoading === row.idapprenantFichier}
              onClick={() => downloadFichier(row.idapprenantFichier)}
              rightIcon="download"
            />
          ) : (
            t(ETLCodes.DocumentPhysique)
          ))
      }
    ],
    [deleteFichier, downloadFichier, onAdd, rowLoading, setCurrentApprenantFichierId, t, tUnsafe]
  );

  return (
    <FieldSet title={`${t(ETLCodes.DocumentsReglementaires)}`}>
      <DataTable
        dateFormat="dd-MM-yyyy"
        tableState={tableState}
        loading={loading}
        columns={columns}
        customizeRowStyle={(row: ApprenantFichierGridDto) => css`
          & * {
            color: ${!row.remis ? Colors.RED3 + " !important" : null};
          }
        `}
      />
    </FieldSet>
  );
};
