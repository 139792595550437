import { ButtonContainer } from "nsitools-react";
import * as React from "react";
import { useParams } from "react-router-dom";

import {
  EnveloppeTypeFinancementApi,
  EnveloppeTypeFinancementSearch,
  FcbEnveloppeTypeFinancementGridDto
} from "../../../../../../api";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../../components";
import { useAbortableApiServiceFactory, useApiService, useTl } from "../../../../../../hooks";
import { ETLCodes } from "../../../../../../locales";
import { EnveloppeTFDialog } from "./EnveloppeTFDialog";

export interface IEnveloppeTFSearchProps {}

export const EnveloppeTFSearch: React.FunctionComponent<IEnveloppeTFSearchProps> = props => {
  const { t } = useTl();
  const { id } = useParams<{ id: string }>();
  const typeFinancementId = React.useMemo(() => +id, [id]);
  const api = useApiService(EnveloppeTypeFinancementApi);

  const [enveloppeIdSelected, setEnveloppeIdSelected] = React.useState(0);
  const [enveloppePopupOpened, setEnveloppePopupOpened] = React.useState(false);
  const [enveloppePopupEdit, setEnveloppePopupEdit] = React.useState(false);

  const enveloppeDialogOpen = React.useCallback(() => {
    setEnveloppePopupOpened(true);
  }, []);

  const enveloppeDialogClose = React.useCallback(() => {
    setEnveloppeIdSelected(0);
    setEnveloppePopupOpened(false);
  }, []);

  const editClick = React.useCallback((row: FcbEnveloppeTypeFinancementGridDto, editMode: boolean) => {
    setEnveloppeIdSelected(row.idenveloppeTypeFinancement);
    setEnveloppePopupEdit(editMode);
    setEnveloppePopupOpened(true);
  }, []);

  const onAddItem = React.useCallback(() => {
    setEnveloppeIdSelected(0);
    setEnveloppePopupEdit(true);
    enveloppeDialogOpen();
  }, [enveloppeDialogOpen]);

  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbEnveloppeTypeFinancementGridDto) => (
          <ButtonContainer>
            <ViewButton minimal={true} onClick={() => editClick(row, false)} />
            <EditButton minimal={true} onClick={() => editClick(row, true)} />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.AnneeScolaire),
        fieldName: "idanneeScolaire"
      },
      {
        header: () => t(ETLCodes.EntiteCentre),
        fieldName: "centre"
      },
      {
        header: () => t(ETLCodes.HeureS1),
        fieldName: "heureS1"
      },
      {
        header: () => t(ETLCodes.HeureS2),
        fieldName: "heureS2"
      }
    ],
    [editClick, t]
  );

  const apiFactory = useAbortableApiServiceFactory(EnveloppeTypeFinancementApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: EnveloppeTypeFinancementSearch) => {
      sObj.idfinancementType = typeFinancementId;
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.enveloppeTypeFinancementBaseSearch({ EnveloppeTypeFinancementSearch: sObj });
    },
    [apiFactory, typeFinancementId]
  );

  const getCriteriasFn = React.useCallback(
    () => api.enveloppeTypeFinancementGetSearchCriterias({ includeListsValues: true }),
    [api]
  );

  const EnvDialog = React.useCallback(
    () => (
      <EnveloppeTFDialog
        isOpen={enveloppePopupOpened}
        enveloppeId={enveloppeIdSelected}
        onClose={enveloppeDialogClose}
        editMode={enveloppePopupEdit}
      />
    ),
    [enveloppeDialogClose, enveloppePopupEdit, enveloppePopupOpened, enveloppeIdSelected]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <>
      <SearchTablePage
        title={ETLCodes.EnveloppeTypeFinancement}
        withCard={false}
        getCriteriasFunction={getCriteriasFn}
        searchFunction={searchFn}
        onAbort={onAbort}
        columns={columns}
        addFunc={onAddItem}
        overrideListValues={{
          IdanneeScolaire: annee => annee.displayValue,
          Identite: centre => centre.displayValue
        }}
      />
      <EnvDialog />
    </>
  );
};
