import { Intent } from "@blueprintjs/core";
import { DataTable, FieldSet, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { DispenseApi, DispenseSearch, FcbDispenseGridDto } from "../../../../../../api";
import { ERoutes } from "../../../../../../AppRouter";
import { AddButton, DeleteButton, ViewButton } from "../../../../../../components";
import { useAuth, useDialog } from "../../../../../../contexts";
import { useApiService, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

export interface IDispenseListProps {}

export const DispenseList: React.FunctionComponent<IDispenseListProps> = () => {
  const dialogContext = useDialog();
  const { t } = useTl();
  const history = useHistory();
  const { id, tab, inscriptionId, tabInscription } = useParams<{
    id: string;
    tab: string;
    state: string;
    inscriptionId: string;
    tabInscription: string;
  }>();
  const { getCurrentPermission } = useAuth();
  const canDelete = React.useMemo(() => getCurrentPermission()?.permission === "RWD", [getCurrentPermission]);

  const apprenantId = React.useMemo(() => +id, [id]);
  const idInscription = React.useMemo(() => +inscriptionId, [inscriptionId]);
  const api = useApiService(DispenseApi);
  const path = React.useMemo(() => `${ERoutes.apprenant}/${apprenantId}/${tab}/${inscriptionId}/${tabInscription}`, [
    apprenantId,
    inscriptionId,
    tab,
    tabInscription
  ]);

  const [typeDispense] = useReferential(a => a.referentialGetTypeDispense(), true, [], false);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [15, 25, 50],
    pageSize: 15,
    sortKeys: {
      matiere: "ASC"
    }
  });

  const { totalCount } = tableState;

  const searchFunction = React.useCallback(
    (sObj?: DispenseSearch) => {
      sObj.idinscription = idInscription;
      return api.dispenseBaseSearch({ DispenseSearch: sObj });
    },
    [api, idInscription]
  );

  const { loading, search } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });

  const deleteFunc = React.useCallback(
    deleteId => {
      dialogContext.showDialog({
        message: t(ETLCodes.DeleteConfirmationMessage),
        onConfirmed: async () => {
          api.dispenseDelete({ id: deleteId }).then(() => {
            search({});
          });
        }
      });
    },
    [api, dialogContext, search, t]
  );
  const columns = React.useMemo(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbDispenseGridDto) => (
          <div style={{ display: "flex" }}>
            <ViewButton minimal={true} onClick={() => history.push(`${path}/${row.dispenseId}/view`)} />
            {canDelete ? (
              <DeleteButton
                minimal={true}
                onDelete={() => {
                  deleteFunc(row.dispenseId);
                }}
              />
            ) : (
              <></>
            )}
          </div>
        )
      },
      {
        header: () => t(ETLCodes.Demande),
        fieldName: "dateDemande"
      },
      {
        header: () => t(ETLCodes.Acceptation),
        fieldName: "dateAcceptation"
      },
      {
        header: () => t(ETLCodes.Matiere),
        fieldName: "matiere"
      },
      {
        header: () => t(ETLCodes.TypeDispense),
        fieldName: "idtypeDispense",
        render: (row: FcbDispenseGridDto) => <div>{typeDispense?.find(m => m.value === row.idtypeDispense)?.label}</div>
      },
      {
        header: () => t(ETLCodes.Classe),
        fieldName: "classe"
      }
    ],
    [canDelete, deleteFunc, history, path, t, typeDispense]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${path}/0/edit`);
  }, [history, path]);

  return (
    <>
      {apprenantId > 0 && (
        <FieldSet
          title={t(ETLCodes.TableResults, { count: totalCount })}
          rightElement={
            <>
              <AddButton
                onClick={e => {
                  e.stopPropagation();
                  onAddItem();
                }}
                text={t(ETLCodes.General_Add)}
                intent={Intent.PRIMARY}
              />
            </>
          }
        >
          <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
        </FieldSet>
      )}
    </>
  );
};
