/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EFilterSearchType } from './EFilterSearchType';
import {
    EFilterSearchTypeFromJSON,
    EFilterSearchTypeFromJSONTyped,
    EFilterSearchTypeToJSON,
} from './EFilterSearchType';
import type { EFilterSearchTypeStringCombinableCriteria } from './EFilterSearchTypeStringCombinableCriteria';
import {
    EFilterSearchTypeStringCombinableCriteriaFromJSON,
    EFilterSearchTypeStringCombinableCriteriaFromJSONTyped,
    EFilterSearchTypeStringCombinableCriteriaToJSON,
} from './EFilterSearchTypeStringCombinableCriteria';

/**
 * 
 * @export
 * @interface FilterTextSearch
 */
export interface FilterTextSearch {
    /**
     * 
     * @type {Array<EFilterSearchTypeStringCombinableCriteria>}
     * @memberof FilterTextSearch
     */
    criterias?: Array<EFilterSearchTypeStringCombinableCriteria> | null;
    /**
     * 
     * @type {EFilterSearchType}
     * @memberof FilterTextSearch
     */
    searchMode?: EFilterSearchType;
    /**
     * 
     * @type {string}
     * @memberof FilterTextSearch
     * @deprecated
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FilterTextSearch
     * @deprecated
     */
    secondaryValue?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterTextSearch
     */
    readonly availableSearchTypes?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof FilterTextSearch
     */
    defaultSearchType?: string | null;
}

/**
 * Check if a given object implements the FilterTextSearch interface.
 */
export function instanceOfFilterTextSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FilterTextSearchFromJSON(json: any): FilterTextSearch {
    return FilterTextSearchFromJSONTyped(json, false);
}

export function FilterTextSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterTextSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'criterias': !exists(json, 'criterias') ? undefined : (json['criterias'] === null ? null : (json['criterias'] as Array<any>).map(EFilterSearchTypeStringCombinableCriteriaFromJSON)),
        'searchMode': !exists(json, 'searchMode') ? undefined : EFilterSearchTypeFromJSON(json['searchMode']),
        'value': !exists(json, 'value') ? undefined : json['value'],
        'secondaryValue': !exists(json, 'secondaryValue') ? undefined : json['secondaryValue'],
        'availableSearchTypes': !exists(json, 'availableSearchTypes') ? undefined : json['availableSearchTypes'],
        'defaultSearchType': !exists(json, 'defaultSearchType') ? undefined : json['defaultSearchType'],
    };
}

export function FilterTextSearchToJSON(value?: FilterTextSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'criterias': value.criterias === undefined ? undefined : (value.criterias === null ? null : (value.criterias as Array<any>).map(EFilterSearchTypeStringCombinableCriteriaToJSON)),
        'searchMode': EFilterSearchTypeToJSON(value.searchMode),
        'value': value.value,
        'secondaryValue': value.secondaryValue,
        'defaultSearchType': value.defaultSearchType,
    };
}

