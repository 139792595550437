import { Classes, Intent, Switch } from "@blueprintjs/core";
import { format } from "date-fns";
import { DataTable, FieldSet, IDataTableColumn, useGridState, useSearchApi } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import {
  ApprenantFichierApi,
  ApprenantFichierGridDto,
  ApprenantFichierSearchDto,
  ETypeApprenantFichier
} from "../../../../../api";
import { AddButton, CircleColumn, DeleteButton, EditButton, LinkButton } from "../../../../../components";
import { useEventsContext } from "../../../../../contexts";
import { useApiService, useTheme, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const StyledSwitch = styled(Switch)`
  &.${Classes.SWITCH} {
    margin-bottom: 0 !important;

    &:hover {
      input:checked ~ .${Classes.CONTROL_INDICATOR} {
        background: ${props => props.color};
      }
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircleTooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface IApprenantAvisAttestPFDocumentsProps {
  idapprenant: number;
  rowLoading: number;
  setCurrentApprenantFichierId: (idapprenantFichier: number) => void;
  deleteFichier: (idapprenantFichier: number) => void;
  deleteAvisResultat: (idapprenantFichier: number) => void;
  downloadFichier: (idapprenantFichier: number) => void;
  onAdd: () => void;
}

export const ApprenantAvisAttestPFDocuments: React.FunctionComponent<IApprenantAvisAttestPFDocumentsProps> = ({
  idapprenant,
  rowLoading,
  setCurrentApprenantFichierId,
  deleteFichier,
  deleteAvisResultat,
  downloadFichier,
  onAdd
}) => {
  const { t } = useTl();
  const { theme, ifapmeSide } = useTheme();
  const api = useApiService(ApprenantFichierApi);
  const [hideDeleted, setHideDeleted] = React.useState(true);

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [5],
    pageSize: 5,
    sortKeys: { uploadDate: "DESC", fileName: "ASC" }
  });

  const searchFunc = React.useCallback(
    (nextSearch?: ApprenantFichierSearchDto) =>
      api.apprenantFichierBaseSearch({
        ApprenantFichierSearchDto: {
          ...nextSearch,
          hideDeleted,
          idapprenant,
          excludedTypes: [
            ETypeApprenantFichier.JUSTIFICATIF,
            ETypeApprenantFichier.DOSSIER_SCOLAIRE_ACCUSE_FIC,
            ETypeApprenantFichier.CERTIFICAT_MEDICAL,
            ETypeApprenantFichier.CARTE_IDENTITE,
            ETypeApprenantFichier.CHARTE_ENGAGEMENT,
            ETypeApprenantFichier.DOSSIER_SCOLAIRE,
            ETypeApprenantFichier.FICHE_FSE,
            ETypeApprenantFichier.ROI_CARNET_PEDA,
            ETypeApprenantFichier.PPD_AUTORISATION_PARENTALE,
            ETypeApprenantFichier.PPD_CONSENTEMENT_COLLECTE,
            ETypeApprenantFichier.AUTRES
          ]
        }
      }),
    [api, hideDeleted, idapprenant]
  );

  const { search, loading } = useSearchApi<any, any>({
    searchFunction: searchFunc,
    tableState,
    initialSearch: true
  });

  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();
  React.useEffect(() => {
    subscribeToEvent("SEARCH_TABLE_REFRESH", search);
    return () => unsubscribeEvent("SEARCH_TABLE_REFRESH", search);
  }, [search, subscribeToEvent, unsubscribeEvent]);

  const deleteRow = React.useCallback(
    (row: ApprenantFichierGridDto) => {
      if (
        row.typeApprenantFichierCode === ETypeApprenantFichier.AVIS_RESULTATS ||
        row.typeApprenantFichierCode === ETypeApprenantFichier.AVIS_RESULTATS_1ERE_SESSION ||
        row.typeApprenantFichierCode === ETypeApprenantFichier.AVIS_RESULTATS_2EME_SESSION
      ) {
        deleteAvisResultat(row.idapprenantFichier);
      } else {
        deleteFichier(row.idapprenantFichier);
      }
    },
    [deleteAvisResultat, deleteFichier]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: ApprenantFichierGridDto) => (
          <ButtonContainer>
            <EditButton
              minimal={true}
              onClick={() => setCurrentApprenantFichierId(row.idapprenantFichier)}
              loading={rowLoading === row.idapprenantFichier}
              disabled={!!rowLoading && rowLoading !== row.idapprenantFichier}
            />
            {!row.deletionDate && !row.autoCreated && (
              <DeleteButton
                minimal={true}
                onDelete={() => deleteRow(row)}
                loading={rowLoading === row.idapprenantFichier}
                disabled={!!rowLoading && rowLoading !== row.idapprenantFichier}
              />
            )}
            <CircleColumn
              color={!!row.deletionDate ? theme.dangerColor : theme.sucessColor}
              tooltip={
                !!row.deletionDate && (
                  <CircleTooltipContainer>
                    <span>
                      {t(ETLCodes.DeletedByOn, {
                        user: row.deletionUserName,
                        date: format(row.deletionDate, "dd/MM/yyyy")
                      })}
                    </span>
                    <span>{t(ETLCodes.RaisonVar, { reason: row.deletionReason })}</span>
                  </CircleTooltipContainer>
                )
              }
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "typeApprenantFichier"
      },
      {
        header: () => t(ETLCodes.Annee),
        fieldName: "anneeScolaire"
      },
      {
        header: () => t(ETLCodes.TypeCours),
        fieldName: "typeCours"
      },
      {
        header: () => t(ETLCodes.Degre),
        fieldName: "degre"
      },
      {
        header: () => t(ETLCodes.Metier),
        fieldName: "metier"
      },
      {
        header: () => t(ETLCodes.Centre),
        fieldName: "centre"
      },
      {
        header: () => t(ETLCodes.Deliberation),
        fieldName: "deliberation",
        render: (row: ApprenantFichierGridDto) =>
          row.typeApprenantFichierCode === ETypeApprenantFichier.AVIS_RESULTATS_2EME_SESSION
            ? row.deliberation2Sess
            : row.deliberation
      },
      {
        header: () => t(ETLCodes.DateUpload),
        fieldName: "uploadDate"
      },
      {
        header: () => t(ETLCodes.UtilisateurUpload),
        fieldName: "creationUserName"
      },
      {
        header: () => t(ETLCodes.Document),
        fieldName: "fileName",
        render: (row: ApprenantFichierGridDto) =>
          !row.deletionDate || ifapmeSide === "walter" ? (
            <LinkButton
              text={row.fileName}
              loading={rowLoading === row.idapprenantFichier}
              onClick={() => downloadFichier(row.idapprenantFichier)}
              rightIcon="download"
            />
          ) : (
            row.fileName
          )
      }
    ],
    [
      deleteRow,
      downloadFichier,
      ifapmeSide,
      rowLoading,
      setCurrentApprenantFichierId,
      t,
      theme.dangerColor,
      theme.sucessColor
    ]
  );

  return (
    <FieldSet
      title={
        <TitleContainer>
          {`${t(ETLCodes.AvisAttestationPF)}`}
          <StyledSwitch
            color={theme.primaryColor}
            label={t(ETLCodes.VoirTout)}
            checked={!hideDeleted}
            onChange={e => {
              setHideDeleted(!e.currentTarget.checked);
            }}
          ></StyledSwitch>
        </TitleContainer>
      }
      rightElement={
        <>
          <AddButton
            onClick={e => {
              e.stopPropagation();
              onAdd();
            }}
            text={t(ETLCodes.General_Add)}
            intent={Intent.PRIMARY}
          />
        </>
      }
    >
      <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
    </FieldSet>
  );
};
