/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FcbRecherchePlanAgrementClasseDto,
  FcbRecherchePlanAgrementClasseDtoPaginatedResults,
  FilterCriteriaInfo,
  PlanAgrementClasseSearch,
  SelectItem,
} from '../models/index';
import {
    FcbRecherchePlanAgrementClasseDtoFromJSON,
    FcbRecherchePlanAgrementClasseDtoToJSON,
    FcbRecherchePlanAgrementClasseDtoPaginatedResultsFromJSON,
    FcbRecherchePlanAgrementClasseDtoPaginatedResultsToJSON,
    FilterCriteriaInfoFromJSON,
    FilterCriteriaInfoToJSON,
    PlanAgrementClasseSearchFromJSON,
    PlanAgrementClasseSearchToJSON,
    SelectItemFromJSON,
    SelectItemToJSON,
} from '../models/index';

export interface AgrementLocalisationClasseBaseSearchRequest {
    PlanAgrementClasseSearch?: PlanAgrementClasseSearch;
}

export interface AgrementLocalisationClasseGetSearchCriteriasRequest {
    includeListsValues?: boolean;
}

export interface AgrementLocalisationClasseGetSelectItemsRequest {
    searchField: string;
}

export interface AgrementLocalisationClasseSaveRequest {
    FcbRecherchePlanAgrementClasseDto?: FcbRecherchePlanAgrementClasseDto;
}

/**
 * 
 */
export class AgrementLocalisationClasseApi extends runtime.BaseAPI {

    /**
     */
    async agrementLocalisationClasseBaseSearchRaw(requestParameters: AgrementLocalisationClasseBaseSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRecherchePlanAgrementClasseDtoPaginatedResults>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementLocalisationClasse/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlanAgrementClasseSearchToJSON(requestParameters.PlanAgrementClasseSearch),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRecherchePlanAgrementClasseDtoPaginatedResultsFromJSON(jsonValue));
    }

    /**
     */
    async agrementLocalisationClasseBaseSearch(requestParameters: AgrementLocalisationClasseBaseSearchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRecherchePlanAgrementClasseDtoPaginatedResults> {
        const response = await this.agrementLocalisationClasseBaseSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementLocalisationClasseGetSearchCriteriasRaw(requestParameters: AgrementLocalisationClasseGetSearchCriteriasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FilterCriteriaInfo>>> {
        const queryParameters: any = {};

        if (requestParameters.includeListsValues !== undefined) {
            queryParameters['includeListsValues'] = requestParameters.includeListsValues;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementLocalisationClasse/criterias`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FilterCriteriaInfoFromJSON));
    }

    /**
     */
    async agrementLocalisationClasseGetSearchCriterias(requestParameters: AgrementLocalisationClasseGetSearchCriteriasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FilterCriteriaInfo>> {
        const response = await this.agrementLocalisationClasseGetSearchCriteriasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementLocalisationClasseGetSelectItemsRaw(requestParameters: AgrementLocalisationClasseGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SelectItem>>> {
        if (requestParameters.searchField === null || requestParameters.searchField === undefined) {
            throw new runtime.RequiredError('searchField','Required parameter requestParameters.searchField was null or undefined when calling agrementLocalisationClasseGetSelectItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementLocalisationClasse/criteriaList/{searchField}`.replace(`{${"searchField"}}`, encodeURIComponent(String(requestParameters.searchField))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SelectItemFromJSON));
    }

    /**
     */
    async agrementLocalisationClasseGetSelectItems(requestParameters: AgrementLocalisationClasseGetSelectItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SelectItem>> {
        const response = await this.agrementLocalisationClasseGetSelectItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async agrementLocalisationClasseSaveRaw(requestParameters: AgrementLocalisationClasseSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FcbRecherchePlanAgrementClasseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2", ["api://9692bd2b-c5bb-44ee-bb80-ed39776806fb/ReadAccess"]);
        }

        const response = await this.request({
            path: `/api/AgrementLocalisationClasse`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FcbRecherchePlanAgrementClasseDtoToJSON(requestParameters.FcbRecherchePlanAgrementClasseDto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FcbRecherchePlanAgrementClasseDtoFromJSON(jsonValue));
    }

    /**
     */
    async agrementLocalisationClasseSave(requestParameters: AgrementLocalisationClasseSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FcbRecherchePlanAgrementClasseDto> {
        const response = await this.agrementLocalisationClasseSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
