import { IDataTableColumn, useApiEffect } from "nsitools-react";
import * as React from "react";
import { useHistory, useParams } from "react-router";

import { ClauseApi, MetierClausesLieesApi } from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { WSelector } from "../../../../../components/selector/WSelector";
import { useDialog } from "../../../../../contexts";
import { useApiService, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

export interface IMetierClausesLieesSelectorProps {}

export const MetierClausesLieesSelector: React.FunctionComponent<IMetierClausesLieesSelectorProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const cApi = useApiService(ClauseApi);
  const mApi = useApiService(MetierClausesLieesApi);
  const { id } = useParams<{ id: string }>();
  const dialogContext = useDialog();

  const [data, loading] = useApiEffect(() => mApi.metierClausesLieesGetClauseMetier({ idMetier: +id }));

  const columns = React.useMemo<IDataTableColumn[]>(() => {
    return [
      {
        fieldName: "title",
        header: () => t(ETLCodes.Title)
      },
      {
        fieldName: "description",
        header: () => t(ETLCodes.Description)
      },
      {
        fieldName: "stade",
        header: () => t(ETLCodes.Stade)
      }
    ];
  }, [t]);

  return (
    !loading && (
      <WSelector
        linkedIds={data.idsClause}
        columns={columns}
        idField="idclause"
        tlDataPrefix="ClauseSearchCriterias"
        title={t(ETLCodes.ClausesLiees)}
        searchIdsFunc={sObj => cApi.clauseSearchClauseIds({ ClauseSearch: sObj })}
        getCriteriasFunction={() => cApi.clauseGetSearchCriterias({ includeListsValues: true })}
        searchFunction={sObj => cApi.clauseBaseSearch({ ClauseSearch: sObj })}
        onSave={async newIds => {
          await mApi.metierClausesLieesSaveClauseMetier({
            FcbMetierClausesLieesDto: { idMetier: +id, idsClause: newIds }
          });

          history.push(`${ERoutes.metier}/${id}/clausesliees`);
        }}
        onCancel={() => history.push(`${ERoutes.metier}/${id}/clausesliees`)}
        overrideListValues={{ Idstade: i => i.displayValue }}
        dialogContext={dialogContext}
        sortKeys={{ title: "ASC" }}
      />
    )
  );
};
