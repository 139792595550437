/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SiegeSocialFichierGridDto
 */
export interface SiegeSocialFichierGridDto {
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialFichierGridDto
     */
    idsiegeSocialFichier?: number;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialFichierGridDto
     */
    idtypeSiegeSocialFichier?: number;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialFichierGridDto
     */
    typeSiegeSocialFichier?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialFichierGridDto
     */
    idfichier?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialFichierGridDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SiegeSocialFichierGridDto
     */
    uploadUser?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SiegeSocialFichierGridDto
     */
    creationUserName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SiegeSocialFichierGridDto
     */
    uploadDate?: Date | null;
}

/**
 * Check if a given object implements the SiegeSocialFichierGridDto interface.
 */
export function instanceOfSiegeSocialFichierGridDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SiegeSocialFichierGridDtoFromJSON(json: any): SiegeSocialFichierGridDto {
    return SiegeSocialFichierGridDtoFromJSONTyped(json, false);
}

export function SiegeSocialFichierGridDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SiegeSocialFichierGridDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idsiegeSocialFichier': !exists(json, 'idsiegeSocialFichier') ? undefined : json['idsiegeSocialFichier'],
        'idtypeSiegeSocialFichier': !exists(json, 'idtypeSiegeSocialFichier') ? undefined : json['idtypeSiegeSocialFichier'],
        'typeSiegeSocialFichier': !exists(json, 'typeSiegeSocialFichier') ? undefined : json['typeSiegeSocialFichier'],
        'idfichier': !exists(json, 'idfichier') ? undefined : json['idfichier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'uploadUser': !exists(json, 'uploadUser') ? undefined : json['uploadUser'],
        'creationUserName': !exists(json, 'creationUserName') ? undefined : json['creationUserName'],
        'uploadDate': !exists(json, 'uploadDate') ? undefined : (json['uploadDate'] === null ? null : new Date(json['uploadDate'])),
    };
}

export function SiegeSocialFichierGridDtoToJSON(value?: SiegeSocialFichierGridDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idsiegeSocialFichier': value.idsiegeSocialFichier,
        'idtypeSiegeSocialFichier': value.idtypeSiegeSocialFichier,
        'typeSiegeSocialFichier': value.typeSiegeSocialFichier,
        'idfichier': value.idfichier,
        'fileName': value.fileName,
        'uploadUser': value.uploadUser,
        'creationUserName': value.creationUserName,
        'uploadDate': value.uploadDate === undefined ? undefined : (value.uploadDate === null ? null : value.uploadDate.toISOString()),
    };
}

