import { Checkbox, Icon, Spinner, Tooltip } from "@blueprintjs/core";
import {
  ButtonContainer,
  DataTable,
  FieldSet,
  IDataTableColumn,
  useFGContext,
  useGridState,
  useSearchApi
} from "nsitools-react";
import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router";
import styled from "styled-components";

import {
  LieuFormationAgrementApi,
  LieuFormationAgrementGridDto,
  LieuFormationAgrementSearchDto
} from "../../../../../api";
import { ERoutes } from "../../../../../AppRouter";
import { CircleColumn, CustomBulletList, EditButton, ViewButton } from "../../../../../components";
import { useEventsContext } from "../../../../../contexts";
import { useApiService, useTheme, useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";

const LegendHeaderContainer = styled.div`
  & > * + * {
    margin-left: 0.5rem;
  }
`;

export interface ILieuFormationAgrementGridProps {
  lfActif: boolean;
}

export const LieuFormationAgrementGrid: React.FunctionComponent<ILieuFormationAgrementGridProps> = ({ lfActif }) => {
  const { t, tUnsafe } = useTl();
  const { theme } = useTheme();
  const { push } = useHistory();
  const { id } = useParams<{ id: string; state: string }>();
  const lieuFormationId = React.useMemo(() => +id, [id]);
  const { search } = useLocation();
  const api = useApiService(LieuFormationAgrementApi);
  const [updateOffreLoading, setUpdateOffreLoading] = React.useState(0);
  const [lastSearchObject, setLastSearchObject] = React.useState<LieuFormationAgrementSearchDto>();
  const { editMode } = useFGContext();

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination: true,
    enableFilter: false,
    availablePageSizes: [5, 10, 25],
    pageSize: 10,
    sortKeys: { dateDecision: "DESC" }
  });

  const searchFunction = React.useCallback(
    (sObj?: LieuFormationAgrementSearchDto) => {
      sObj.idlieuFormation = lieuFormationId;
      if (JSON.stringify(sObj) !== JSON.stringify(lastSearchObject)) {
        setLastSearchObject(sObj);
      }
      return api.lieuFormationAgrementBaseSearch({ LieuFormationAgrementSearchDto: sObj });
    },
    [api, lastSearchObject, lieuFormationId]
  );

  const { loading } = useSearchApi<any, any>({
    searchFunction,
    tableState,
    initialSearch: true
  });
  const { dispatchEvent } = useEventsContext();

  const updateOffre = React.useCallback(
    async (row: LieuFormationAgrementGridDto) => {
      setUpdateOffreLoading(row.idlieuFormationAgrement);
      await api.lieuFormationAgrementUpdateOffre({
        idlieuFormationAgrement: row.idlieuFormationAgrement,
        newValue: !row.offreActive
      });
      dispatchEvent("SEARCH_TABLE_REFRESH");
      setUpdateOffreLoading(0);
    },
    [api, dispatchEvent]
  );

  const computeType = React.useCallback(
    (row: LieuFormationAgrementGridDto) => {
      if (row.agrementPartielA && row.agrementPartielB) return t(ETLCodes.PartielAB);
      if (row.agrementPartielA) return t(ETLCodes.PartielA);
      if (row.agrementPartielB) return t(ETLCodes.PartielB);
      return t(ETLCodes.Complet);
    },
    [t]
  );

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: LieuFormationAgrementGridDto) => (
          <ButtonContainer>
            <ViewButton
              minimal={true}
              onClick={() =>
                push(
                  `${ERoutes.lieuFormation}/${row.idlieuFormation}/agrements/view/${row.idlieuFormationAgrement}${search}`
                )
              }
              disabled={updateOffreLoading > 0}
              specificRights={{
                module: "LIEUFORMATION",
                tab: "AGREMENTS"
              }}
            />
            <EditButton
              minimal={true}
              onClick={() =>
                push(
                  `${ERoutes.lieuFormation}/${row.idlieuFormation}/agrements/edit/${row.idlieuFormationAgrement}${search}`
                )
              }
              disabled={updateOffreLoading > 0}
              specificRights={{
                module: "LIEUFORMATION",
                tab: "AGREMENTS"
              }}
            />
          </ButtonContainer>
        )
      },
      {
        header: () => t(ETLCodes.Offre),
        fieldName: "offreActive",
        autoFitContent: true,
        alignment: "center",
        render: (row: LieuFormationAgrementGridDto) =>
          updateOffreLoading === row.idlieuFormationAgrement ? (
            <Spinner size={20} />
          ) : (
            <Checkbox
              checked={row.offreActive}
              onChange={async e => {
                await updateOffre(row);
              }}
              disabled={updateOffreLoading > 0 || !lfActif || !editMode}
            />
          )
      },
      {
        header: () => (
          <LegendHeaderContainer>
            <span>{t(ETLCodes.C)}</span>
            <Tooltip
              content={
                <CustomBulletList
                  title={t(ETLCodes.Legende)}
                  items={[
                    {
                      text: t(ETLCodes.CapaciteNonAtteinte),
                      color: theme.sucessColor
                    },
                    {
                      text: t(ETLCodes.CapaciteAtteinte),
                      color: theme.dangerColor
                    }
                  ]}
                />
              }
              popoverClassName="bullet-list-popover"
              position="right"
            >
              <Icon icon="info-sign" style={{ cursor: "pointer" }} />
            </Tooltip>
          </LegendHeaderContainer>
        ),
        fieldName: "capaciteAtteinte",
        autoFitContent: true,
        render: (row: LieuFormationAgrementGridDto) => (
          <CircleColumn color={row.capaciteAtteinte ? theme.dangerColor : theme.sucessColor} />
        )
      },
      {
        header: () => t(ETLCodes.CodeMetier),
        fieldName: "codeMetier"
      },
      {
        header: () => t(ETLCodes.NomMetier),
        fieldName: "nomMetier"
      },
      {
        header: () => t(ETLCodes.Stade),
        fieldName: "nomStade"
      },
      {
        header: () => t(ETLCodes.Statut),
        fieldName: "statut",
        render: row => tUnsafe(`StatutAgrement_${row.statut}`)
      },
      {
        header: () => t(ETLCodes.DecisionAgrement),
        fieldName: "decision"
      },
      {
        header: () => t(ETLCodes.Type),
        fieldName: "type",
        render: (row: LieuFormationAgrementGridDto) => computeType(row)
      },
      {
        header: () => t(ETLCodes.DateDecision),
        fieldName: "dateDecision"
      }
    ],
    [
      computeType,
      editMode,
      lfActif,
      push,
      search,
      t,
      tUnsafe,
      theme.dangerColor,
      theme.sucessColor,
      updateOffre,
      updateOffreLoading
    ]
  );

  return (
    <FieldSet title={t(ETLCodes.Agrements)} collapsable>
      <DataTable dateFormat="dd-MM-yyyy" tableState={tableState} loading={loading} columns={columns} />
    </FieldSet>
  );
};
