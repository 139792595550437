import * as React from "react";
import { useTl, useApiService, useAbortableApiServiceFactory } from "../../../../../hooks";
import { useHistory } from "react-router";
import { EditButton, SearchTablePage, ViewButton } from "../../../../../components";
import { ERoutes } from "../../../../../AppRouter";
import { ETLCodes } from "../../../../../locales";
import {
  SpecificiteCoursApi,
  FcbSpecificiteCoursGridDto,
  SpecificiteCoursSearch,
  EBooleanSearchTypes
} from "../../../../../api";
import styled from "styled-components";
import { Checkbox } from "@blueprintjs/core";
import { IDataTableColumn } from "nsitools-react";

interface ISpecificiteCoursListPageProps {}

const Container = styled.div`
  display: flex;
`;

export const SpecificiteCoursListPage: React.FunctionComponent<ISpecificiteCoursListPageProps> = () => {
  const { t } = useTl();
  const history = useHistory();
  const api = useApiService(SpecificiteCoursApi);

  const columns = React.useMemo<IDataTableColumn[]>(
    () => [
      {
        computed: true,
        fieldName: "actions",
        autoFitContent: true,
        render: (row: FcbSpecificiteCoursGridDto) => (
          <Container>
            <ViewButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.specificiteCours}/${row.code}/detail/view`)}
            />
            <EditButton
              minimal={true}
              onClick={() => history.push(`${ERoutes.specificiteCours}/${row.code}/detail/edit`)}
            />
          </Container>
        )
      },
      {
        header: () => t(ETLCodes.Code),
        fieldName: "code"
      },
      {
        header: () => t(ETLCodes.Libelle),
        fieldName: "libelle"
      },
      {
        header: () => t(ETLCodes.IsCompanySearch),
        fieldName: "isCompanySearch",
        autoFitContent: true,
        alignment: "center",
        render: (row: FcbSpecificiteCoursGridDto) => <Checkbox checked={row.isCompanySearch} disabled />
      }
    ],
    [history, t]
  );

  const onAddItem = React.useCallback(() => {
    history.push(`${ERoutes.specificiteCours}/-1/detail/edit`);
  }, [history]);

  const getCriteriasFn = React.useCallback(() => api.specificiteCoursGetSearchCriterias({ includeListsValues: true }), [
    api
  ]);

  const apiFactory = useAbortableApiServiceFactory(SpecificiteCoursApi);
  const lastAbortController = React.useRef<AbortController>();
  const searchFn = React.useCallback(
    (sObj?: SpecificiteCoursSearch) => {
      const { api: abortableApi, abortController } = apiFactory();
      lastAbortController.current = abortController;
      return abortableApi.specificiteCoursBaseSearch({ SpecificiteCoursSearch: sObj });
    },
    [apiFactory]
  );

  const onAbort = React.useCallback(() => lastAbortController.current?.abort(), []);

  return (
    <SearchTablePage
      getCriteriasFunction={getCriteriasFn}
      searchFunction={searchFn}
      onAbort={onAbort}
      columns={columns}
      breadCrumbs={[{ text: t(ETLCodes.SpecificiteCours), route: ERoutes.specificiteCours }]}
      addFunc={onAddItem}
      sortKeys={{ code: "ASC" }}
      defaultCriterias={[
        {
          criteria: "Actif",
          searchMode: EBooleanSearchTypes.Equals,
          value: true
        }
      ]}
    />
  );
};
