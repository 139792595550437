import { Card, HTMLSelect, IOptionProps } from "@blueprintjs/core";
import { FieldSet } from "nsitools-react";
import * as React from "react";
import { Col, Grid, Row } from "react-flexbox-grid";

import { useTl } from "../../../../../hooks";
import { ETLCodes } from "../../../../../locales";
import { AjoutDatePage, CalculHoraireManquant, DeleteHorairePage, FermetureClassePage } from "./actions/";

export interface IClasseHoraireActionsProps {
  idClasse: number;
  canSave: boolean;
}

export enum EHoraireActions {
  Null,
  DeleteHoraire,
  FermetureClasse,
  AjoutDateHoraire,
  CalculHoraireManquant
}

export const ClasseHoraireActions: React.FunctionComponent<IClasseHoraireActionsProps> = ({ idClasse, canSave }) => {
  const { t } = useTl();
  const [data, setData] = React.useState<{ value: EHoraireActions }>({ value: EHoraireActions.Null });

  const EHoraireActionsOptions: IOptionProps[] = [
    {
      value: EHoraireActions.Null,
      label: " "
    },
    { value: EHoraireActions.DeleteHoraire, label: t(ETLCodes.DeleteHoraire) },
    { value: EHoraireActions.FermetureClasse, label: t(ETLCodes.FermetureClasse) },
    { value: EHoraireActions.AjoutDateHoraire, label: t(ETLCodes.AjoutDateHoraire) },
    { value: EHoraireActions.CalculHoraireManquant, label: t(ETLCodes.CalculHoraireManquant) }
  ];

  return (
    <Card>
      <FieldSet title={t(ETLCodes.Actions)}>
        <Grid fluid>
          <Row style={{ marginBottom: "1rem" }}>
            <Col xs={12}>
              <HTMLSelect
                options={EHoraireActionsOptions}
                value={data.value}
                onChange={c => setData({ value: +c.target.value })}
                disabled={!canSave}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {data.value === EHoraireActions.DeleteHoraire && <DeleteHorairePage idClasse={idClasse} />}
              {data.value === EHoraireActions.FermetureClasse && <FermetureClassePage idClasse={idClasse} />}
              {data.value === EHoraireActions.AjoutDateHoraire && <AjoutDatePage idClasse={idClasse} />}
              {data.value === EHoraireActions.CalculHoraireManquant && <CalculHoraireManquant idClasse={idClasse} />}
              {data.value === EHoraireActions.Null && <div />}
            </Col>
          </Row>
        </Grid>
      </FieldSet>
    </Card>
  );
};
