/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EListSearchType } from './EListSearchType';
import {
    EListSearchTypeFromJSON,
    EListSearchTypeFromJSONTyped,
    EListSearchTypeToJSON,
} from './EListSearchType';
import type { EListSearchTypeESuiviActionCombinableCriteria } from './EListSearchTypeESuiviActionCombinableCriteria';
import {
    EListSearchTypeESuiviActionCombinableCriteriaFromJSON,
    EListSearchTypeESuiviActionCombinableCriteriaFromJSONTyped,
    EListSearchTypeESuiviActionCombinableCriteriaToJSON,
} from './EListSearchTypeESuiviActionCombinableCriteria';
import type { ESuiviAction } from './ESuiviAction';
import {
    ESuiviActionFromJSON,
    ESuiviActionFromJSONTyped,
    ESuiviActionToJSON,
} from './ESuiviAction';

/**
 * 
 * @export
 * @interface ESuiviActionListSearch
 */
export interface ESuiviActionListSearch {
    /**
     * 
     * @type {Array<EListSearchTypeESuiviActionCombinableCriteria>}
     * @memberof ESuiviActionListSearch
     */
    criterias?: Array<EListSearchTypeESuiviActionCombinableCriteria> | null;
    /**
     * 
     * @type {EListSearchType}
     * @memberof ESuiviActionListSearch
     */
    searchMode?: EListSearchType;
    /**
     * 
     * @type {ESuiviAction}
     * @memberof ESuiviActionListSearch
     */
    value?: ESuiviAction;
    /**
     * 
     * @type {ESuiviAction}
     * @memberof ESuiviActionListSearch
     */
    secondaryValue?: ESuiviAction;
    /**
     * 
     * @type {Array<string>}
     * @memberof ESuiviActionListSearch
     */
    readonly availableSearchTypes?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ESuiviActionListSearch
     */
    defaultSearchType?: string | null;
}

/**
 * Check if a given object implements the ESuiviActionListSearch interface.
 */
export function instanceOfESuiviActionListSearch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ESuiviActionListSearchFromJSON(json: any): ESuiviActionListSearch {
    return ESuiviActionListSearchFromJSONTyped(json, false);
}

export function ESuiviActionListSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): ESuiviActionListSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'criterias': !exists(json, 'criterias') ? undefined : (json['criterias'] === null ? null : (json['criterias'] as Array<any>).map(EListSearchTypeESuiviActionCombinableCriteriaFromJSON)),
        'searchMode': !exists(json, 'searchMode') ? undefined : EListSearchTypeFromJSON(json['searchMode']),
        'value': !exists(json, 'value') ? undefined : ESuiviActionFromJSON(json['value']),
        'secondaryValue': !exists(json, 'secondaryValue') ? undefined : ESuiviActionFromJSON(json['secondaryValue']),
        'availableSearchTypes': !exists(json, 'availableSearchTypes') ? undefined : json['availableSearchTypes'],
        'defaultSearchType': !exists(json, 'defaultSearchType') ? undefined : json['defaultSearchType'],
    };
}

export function ESuiviActionListSearchToJSON(value?: ESuiviActionListSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'criterias': value.criterias === undefined ? undefined : (value.criterias === null ? null : (value.criterias as Array<any>).map(EListSearchTypeESuiviActionCombinableCriteriaToJSON)),
        'searchMode': EListSearchTypeToJSON(value.searchMode),
        'value': ESuiviActionToJSON(value.value),
        'secondaryValue': ESuiviActionToJSON(value.secondaryValue),
        'defaultSearchType': value.defaultSearchType,
    };
}

