/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FcbMetierConseillersLiesDto
 */
export interface FcbMetierConseillersLiesDto {
    /**
     * 
     * @type {number}
     * @memberof FcbMetierConseillersLiesDto
     */
    idMetiers?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof FcbMetierConseillersLiesDto
     */
    idsConseillers?: Array<string> | null;
}

/**
 * Check if a given object implements the FcbMetierConseillersLiesDto interface.
 */
export function instanceOfFcbMetierConseillersLiesDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FcbMetierConseillersLiesDtoFromJSON(json: any): FcbMetierConseillersLiesDto {
    return FcbMetierConseillersLiesDtoFromJSONTyped(json, false);
}

export function FcbMetierConseillersLiesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FcbMetierConseillersLiesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idMetiers': !exists(json, 'idMetiers') ? undefined : json['idMetiers'],
        'idsConseillers': !exists(json, 'idsConseillers') ? undefined : json['idsConseillers'],
    };
}

export function FcbMetierConseillersLiesDtoToJSON(value?: FcbMetierConseillersLiesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idMetiers': value.idMetiers,
        'idsConseillers': value.idsConseillers,
    };
}

