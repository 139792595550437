/* tslint:disable */
/* eslint-disable */
/**
 * Main API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ENumericSearchType {
    Equals = 'Equals',
    NotEquals = 'NotEquals',
    GreaterThan = 'GreaterThan',
    GreaterThanOrEquals = 'GreaterThanOrEquals',
    LessThan = 'LessThan',
    LessThanOrEquals = 'LessThanOrEquals'
}


export function ENumericSearchTypeFromJSON(json: any): ENumericSearchType {
    return ENumericSearchTypeFromJSONTyped(json, false);
}

export function ENumericSearchTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ENumericSearchType {
    return json as ENumericSearchType;
}

export function ENumericSearchTypeToJSON(value?: ENumericSearchType | null): any {
    return value as any;
}

