import { Classes, MenuItem } from "@blueprintjs/core";
import { ItemPredicate, ItemRenderer, Suggest } from "@blueprintjs/select";
import { orderBy } from "lodash";
import * as React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { INavigationItem } from ".";
import { useTheme, useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import { IThemeData } from "../../theme";

function escapeRegExpChars(text: string) {
  return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
}

function highlightText(text: string, query: string) {
  let lastIndex = 0;
  const words = query
    .split(/\s+/)
    .filter(word => word.length > 0)
    .map(escapeRegExpChars);
  if (words.length === 0) {
    return [text];
  }
  const regexp = new RegExp(words.join("|"), "gi");
  const tokens: React.ReactNode[] = [];
  while (true) {
    const match = regexp.exec(text);
    if (!match) {
      break;
    }
    const length = match[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }
  return tokens;
}

export type FlattenedNavigationItems = {
  label: string;
  route: string;
  id: string;
};

export interface IQuickAccessProps {
  navigationMenu: INavigationItem[];
  onNavigation?: () => void;
}

const NavItemSuggest = Suggest.ofType<FlattenedNavigationItems>();

const MyNavItemSuggest = styled(NavItemSuggest)<{ theme: IThemeData }>`
  & .${Classes.MENU} {
    max-height: 200px !important;
    overflow: auto !important;
    color: white !important;
    & .${Classes.ACTIVE} {
      color: ${props => props.theme.quickAccessSelectedColor} !important;
    }
  }
`;

export const QuickAccess: React.FunctionComponent<IQuickAccessProps> = ({ navigationMenu, onNavigation }) => {
  const { t } = useTl();
  const { theme } = useTheme();
  const history = useHistory();
  const list = React.useMemo(() => {
    const extractItemsFromNavMenu = (navMenu: INavigationItem[], parentLabel = ""): FlattenedNavigationItems[] => {
      return navMenu
        .map(mItem => {
          const menuName = t(mItem.name);
          const items: FlattenedNavigationItems[] = mItem.route
            ? [{ label: parentLabel ? parentLabel + " / " + menuName : menuName, route: mItem.route, id: uuidv4() }]
            : [];

          if (mItem.items) {
            return [...items, ...extractItemsFromNavMenu(mItem.items, menuName)];
          }
          return items;
        })
        .reduce((prev, curr) => [...prev, ...curr], []);
    };

    return orderBy(extractItemsFromNavMenu(navigationMenu), ["label"], ["asc"]);
  }, [navigationMenu, t]);

  const renderInputValue = React.useCallback((i: FlattenedNavigationItems) => i.label, []);
  const areItemsEquals = React.useCallback(
    (a: FlattenedNavigationItems, b: FlattenedNavigationItems) => a.route === b.route,
    []
  );

  const onChange = React.useCallback(
    (i: FlattenedNavigationItems) => {
      history.push(i.route);
      if (onNavigation) {
        onNavigation();
      }
    },
    [history, onNavigation]
  );

  const itemRenderer = React.useCallback<ItemRenderer<FlattenedNavigationItems>>(
    (item: FlattenedNavigationItems, { handleClick, modifiers, query }) => {
      if (!modifiers.matchesPredicate) {
        return null;
      }
      const text = item.label;
      return (
        <MenuItem
          active={modifiers.active}
          disabled={modifiers.disabled}
          onClick={handleClick}
          text={highlightText(text, query)}
          key={item.id}
        ></MenuItem>
      );
    },
    []
  );

  const filterList = React.useCallback<ItemPredicate<FlattenedNavigationItems>>((query, item, _index, exactMatch) => {
    const normalizedTitle = item.label.toLowerCase();
    const normalizedQuery = query.toLowerCase();
    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return normalizedTitle.indexOf(normalizedQuery) >= 0;
    }
  }, []);

  return (
    <MyNavItemSuggest
      theme={theme}
      inputValueRenderer={renderInputValue}
      itemsEqual={areItemsEquals}
      items={list}
      noResults={<MenuItem disabled={true} text="No results." />}
      onItemSelect={onChange}
      itemRenderer={itemRenderer}
      itemPredicate={filterList}
      resetOnQuery={true}
      fill={true}
      selectedItem={null}
      openOnKeyDown={true}
      resetOnSelect={true}
      inputProps={{
        placeholder: t(ETLCodes.QuickAccess),
        leftIcon: "search"
      }}
      popoverProps={{
        minimal: true,
        usePortal: false,
        popoverClassName: "quickaccess"
      }}
    ></MyNavItemSuggest>
  );
};
