import { Classes, Dialog } from "@blueprintjs/core";
import { FGListen } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";

import { StatutApprenantApi, StatutApprenantEditDto } from "../../api";
import { useApiService, useCrudApi, useTl } from "../../hooks";
import { useReferential } from "../../hooks/useReferential";
import { ETLCodes } from "../../locales";
import { FGWalterDateMaskInput, FGWalterSelectInput } from "../formGenerator";
import { SmallFormGenerator } from "../SmallFormGenerator";

const StyledDialog = styled(Dialog)`
  width: 600px;
  height: auto;
  background-color: white;

  & .${Classes.RADIO} {
    align-self: center;
    min-width: 125px;
  }
`;

export interface IEditStatutApprenantDialogProps {
  apprenantData: StatutApprenantEditDto;
  isDialogOpen: boolean;
  onClose: (refresh: boolean) => void;
}

export const EditStatutApprenantDialog: React.FunctionComponent<IEditStatutApprenantDialogProps> = ({
  isDialogOpen,
  onClose = (refresh: boolean) => {},
  apprenantData
}) => {
  const { t } = useTl();
  const api = useApiService(StatutApprenantApi);
  const [idCatStatutSocial, setIdCatStatutSocial] = React.useState(+(apprenantData?.idCategorieStatut ?? 0));

  const [categoriesStatutSocial, cssLoading, , rawCatStatutSocial] = useReferential(a =>
    a.referentialGetCategoriesStatutSocial()
  );
  const [idstatutSocial, setIdstatutSocial] = React.useState(0);
  const [statutsSocial, ssLoading] = useReferential(
    a =>
      a.referentialGetSousStatutsByCategorie({
        idCategorie: idCatStatutSocial ?? 0,
        currentIdstatutSocial: idstatutSocial ?? 0
      }),
    true,
    [idCatStatutSocial]
  );
  const [dureeInoccupations, diLoading] = useReferential(a => a.referentialGetDureeInoccupation(), true, []);
  const [idtypeSortie, setIdtypeSortie] = React.useState(0);
  const [typesSortie, tLoading] = useReferential(a => a.referentialGetTypesSortie({ currentId: idtypeSortie }));
  const [motifsSortie, msLoading] = useReferential(a => a.referentialGetMotifsSortie({ idtypeSortie }), true, [
    idtypeSortie
  ]);

  const showDureeInoccupation = React.useMemo(
    () =>
      !!rawCatStatutSocial &&
      +(rawCatStatutSocial.find(c => c.keyValue === "DEM")?.idValue ?? 0) === +idCatStatutSocial,
    [idCatStatutSocial, rawCatStatutSocial]
  );

  const { data, loading, saveItem, saving } = useCrudApi<StatutApprenantEditDto>(
    React.useMemo(
      () => ({
        getApiFn: async () => {
          let d = await api.statutApprenantGetStatutApprenant({ idstatutApprenant: apprenantData?.idStatutApprenant });
          d.simpleUpdate = true;
          return d;
        },
        saveApiFn: d => api.statutApprenantSaveStatutApprenant({ StatutApprenantEditDto: d }),
        onSaved: () => onClose(true)
      }),
      [api, apprenantData, onClose]
    )
  );

  React.useEffect(() => {
    if (!!data) {
      setIdCatStatutSocial(data.idCategorieStatut);
      setIdstatutSocial(data.idStatut);
      setIdtypeSortie(data.idtypeSortie);
    }
  }, [data]);

  const FormSchema = React.useMemo(() => {
    let schema = {
      idCategorieStatut: Yup.number()
        .nullable()
        .required(t(ETLCodes.Required)),
      idStatut: Yup.number()
        .nullable()
        .required(t(ETLCodes.Required)),
      iddureeInoccupation: Yup.number()
        .nullable()
        .when(["idCategorieStatut"], {
          is: idCategorieStatut =>
            !!rawCatStatutSocial &&
            +(rawCatStatutSocial.find(c => c.keyValue === "DEM")?.idValue ?? 0) === +idCategorieStatut,
          then: Yup.number()
            .nullable()
            .required(t(ETLCodes.Required))
        }),
      dateStatut: Yup.date()
        .nullable()
        .required(t(ETLCodes.Required))
    };

    return Yup.object().shape(schema, [
      ["iddureeInoccupation", "idCategorieStatut"],
      ["idtypeSortie", "idMotifSortie"]
    ]);
  }, [rawCatStatutSocial, t]);

  return (
    <StyledDialog title={t(ETLCodes.StatutApprenant)} isOpen={isDialogOpen} onClose={() => onClose(false)}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={saveItem}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose(false)}
          showDeleteButton={false}
          saving={saving}
          forceEnableSave
          watchChanges={{
            idStatut: setIdstatutSocial,
            idtypeSortie: setIdtypeSortie,
            idCategorieStatut: (value: any, formik) => {
              if (value === idCatStatutSocial) return;
              setIdCatStatutSocial(value);
              formik.setFieldValue("idStatut", null);
              formik.setFieldValue("iddureeInoccupation", null);
            }
          }}
          enableDirtyCheck={false}
          loading={loading}
        >
          <FGWalterSelectInput
            name="idCategorieStatut"
            label={t(ETLCodes.CategorieStatut)}
            items={categoriesStatutSocial}
            loading={cssLoading}
            readonly={!!apprenantData?.idCategorieStatut}
          />
          <FGWalterSelectInput
            name="idStatut"
            label={t(ETLCodes.Statut)}
            items={statutsSocial}
            loading={ssLoading}
            readonly={!!apprenantData?.idStatut}
          />
          {showDureeInoccupation && (
            <FGWalterSelectInput
              name="iddureeInoccupation"
              label={t(ETLCodes.DureeInoccupation)}
              items={dureeInoccupations}
              loading={diLoading}
              requiredMark
            />
          )}
          {(data?.idtypeSortie || data?.idMotifSortie) && (
            <FGWalterSelectInput
              name="idtypeSortie"
              label={t(ETLCodes.TypeSortie)}
              items={typesSortie}
              loading={tLoading}
            />
          )}
          {(data?.idtypeSortie || data?.idMotifSortie) && (
            <FGWalterSelectInput
              name="idMotifSortie"
              label={t(ETLCodes.MotifSortie)}
              items={motifsSortie}
              loading={msLoading}
            />
          )}
          <FGWalterDateMaskInput name="dateStatut" label={t(ETLCodes.DateEntreeParcours)} readonly />
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
