import { Classes, Dialog, Radio, RadioGroup } from "@blueprintjs/core";
import { endOfToday } from "date-fns";
import { FGCustomInput, FGListen, FGMultiSuggestInput, FGTextAreaInput, FieldGroup } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";

import {
  LieuFormationAgrementApi,
  LieuFormationAgrementRetraitMultipleDto,
  LieuFormationAgrementRetraitMultipleDtoFromJSON
} from "../../../../../../api";
import { FGWalterDateMaskInput, FGWalterFileInput, SmallFormGenerator } from "../../../../../../components";
import { useDialog } from "../../../../../../contexts";
import { useApiService, useCrudApi, useTl } from "../../../../../../hooks";
import { useReferential } from "../../../../../../hooks/useReferential";
import { ETLCodes } from "../../../../../../locales";

const StyledDialog = styled(Dialog)`
  width: 700px;
  height: auto;
  background-color: white;
`;

const RadioReadonlyContainer = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
`;

export interface ILieuFormationAgrementRetraitDialogProps {
  idslieuFormationAgrement: number[];
  idlieuFormation: number;
  allSelected?: boolean;
  dialogOpen: boolean;
  onClose: (refresh: boolean) => void;
  multiple: boolean;
}

export const LieuFormationAgrementRetraitDialog: React.FunctionComponent<ILieuFormationAgrementRetraitDialogProps> = ({
  idlieuFormation,
  idslieuFormationAgrement = [],
  allSelected = false,
  dialogOpen,
  onClose,
  multiple
}) => {
  const { t } = useTl();
  const api = useApiService(LieuFormationAgrementApi);

  const { showDialogPromise } = useDialog();
  const { data, loading, saveItem, saving, validationErrors } = useCrudApi(
    React.useMemo(
      () => ({
        getApiFn: () => {
          return LieuFormationAgrementRetraitMultipleDtoFromJSON({
            idlieuFormation,
            idslieuFormationAgrement: idslieuFormationAgrement,
            retraitIsIfapme: true,
            idsMotifRetrait: []
          });
        },
        saveApiFn: async (d: LieuFormationAgrementRetraitMultipleDto) => {
          return api.lieuFormationAgrementRetraitMultiple({ LieuFormationAgrementRetraitMultipleDto: d });
        },
        onSaved: () => onClose(true)
      }),
      [api, idlieuFormation, idslieuFormationAgrement, onClose]
    )
  );

  const save = React.useCallback(
    async (d: LieuFormationAgrementRetraitMultipleDto) => {
      const canSave = await showDialogPromise({
        title: t(ETLCodes.AgrementRetraitWarningTitle),
        message: t(ETLCodes.AgrementRetraitWarningMessage)
      });

      if (canSave === "yes") {
        if (allSelected) {
          const disableLf = await showDialogPromise({
            title: t(ETLCodes.DisableLieuFormationTitle),
            message: t(ETLCodes.DisableLieuFormationMessage)
          });
          d.disableLieuFormation = disableLf === "yes";
        }

        await saveItem(d);
      }
    },
    [allSelected, saveItem, showDialogPromise, t]
  );

  const FormSchema = React.useMemo(
    () =>
      Yup.object().shape({
        datePriseEffetRetrait: Yup.date()
          .nullable()
          .max(endOfToday(), t(ETLCodes.DateCannotBeInFurture))
          .required(t(ETLCodes.Required)),
        dateRetrait: Yup.date()
          .nullable()
          .max(endOfToday(), t(ETLCodes.DateCannotBeInFurture)),
        idsMotifRetrait: Yup.array()
          .of(Yup.number())
          .nullable()
          .required(t(ETLCodes.Required))
      }),
    [t]
  );

  const [motifs, mLoading] = useReferential(a => a.referentialGetMotifsRetrait());

  return (
    <StyledDialog title={t(ETLCodes.Retrait)} isOpen={dialogOpen} onClose={() => onClose(false)}>
      <div className={Classes.DIALOG_BODY}>
        <SmallFormGenerator
          initialValues={data}
          onSubmit={save}
          editMode={true}
          validationSchema={FormSchema}
          onCancel={() => onClose(false)}
          showDeleteButton={false}
          saving={saving}
          loading={loading}
          validationErrors={validationErrors}
          minLabelWidth={220}
        >
          <FGListen
            field="fichierRetrait"
            onChanged={(value, formik) => {
              if (formik.dirty) {
                formik.setFieldValue("idfichierRetrait", null);
              }
            }}
          />
          <FieldGroup>
            <FGWalterDateMaskInput name="datePriseEffetRetrait" label={t(ETLCodes.DatePriseEffet)} />
            <FGWalterDateMaskInput name="dateRetrait" label={t(ETLCodes.DateDecisionRetrait)} />
            <FGCustomInput label={t(ETLCodes.OrganismeResponsableRetrait)}>
              {ctx =>
                ctx?.editMode ? (
                  <RadioGroup
                    selectedValue={ctx?.formik?.values?.retraitIsIfapme ? "1" : "0"}
                    inline
                    onChange={e => ctx.formik.setFieldValue("retraitIsIfapme", e.currentTarget.value === "1")}
                  >
                    <Radio label={t(ETLCodes.IFAPME)} value="1"></Radio>
                    <Radio label={t(ETLCodes.CEFA)} value="0"></Radio>
                  </RadioGroup>
                ) : (
                  <RadioReadonlyContainer>
                    {!ctx?.formik?.values?.retraitIsIfapme ? t(ETLCodes.IFAPME) : t(ETLCodes.CEFA)}
                  </RadioReadonlyContainer>
                )
              }
            </FGCustomInput>
            <FGMultiSuggestInput
              name="idsMotifRetrait"
              label={t(ETLCodes.MotifsRetraitAgrement)}
              items={motifs}
              loading={mLoading}
              requiredMark
            />
            <FGTextAreaInput name="remarqueRetrait" label={t(ETLCodes.Remarque)} />
            <FGWalterFileInput name="fichierRetrait" label={t(ETLCodes.Document)} visible={!multiple} />
          </FieldGroup>
        </SmallFormGenerator>
      </div>
    </StyledDialog>
  );
};
