import * as React from "react";
import { Route, Switch } from "react-router";

import { ERoutes } from "../../../../../AppRouter";
import { ConseillerPedagogiqueMetiersLies } from "./ConseillerPedagogiqueMetiersLies";
import { ConseillerPedagogiqueMetiersLiesSelector } from "./ConseillerPedagogiqueMetiersLiesSelector";

export interface IConseillerPedagogiqueMetiersLiesSwitchProps {}

export const ConseillerPedagogiqueMetiersLiesSwitch: React.FunctionComponent<IConseillerPedagogiqueMetiersLiesSwitchProps> = () => {
  return (
    <Switch>
      <Route path={`${ERoutes.conseillerPedagogique}/:id/:tab`} component={ConseillerPedagogiqueMetiersLies} exact />
      <Route
        path={`${ERoutes.conseillerPedagogique}/:id/:tab/selector`}
        component={ConseillerPedagogiqueMetiersLiesSelector}
      />
    </Switch>
  );
};
